import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AlertifyService } from '../alertify.service';
import { FiltrosService } from '../servicios/filtros.service';
import { PeticionesService } from '../servicios/httpservice';
import * as CryptoJS from 'crypto-js';
import { trigger, state, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-ecommerce-producto',
  templateUrl: './ecommerce-producto.component.html',
  styleUrls: ['./ecommerce-producto.component.css'], 
   animations: [
      trigger('menuAnimation', [
        state('hidden', style({
          transform: 'perspective(600px) rotateY(90deg)', // Empieza doblada
          opacity: 0,
          display: 'none'
        })),
        state('visible', style({
          transform: 'perspective(600px) rotateY(0)', // Plana cuando es visible
          opacity: 1,
          display: 'block'
        })),
        transition('hidden => visible', [
          animate('0.5s ease-out')
        ]),
        transition('visible => hidden', [
          animate('0.5s ease-in') 
        ]),
      ]
     )
    ]
})
export class EcommerceProductoComponent implements OnInit {
  public strConexion:string = "COMERCIAL";
  public strTipo:string="";
  public strAccion:string ="";
  public arrayProductos:any = [];
  private secretKey: string = 'ec0d3li2o2e';
  constructor(private route: ActivatedRoute,public _alertify: AlertifyService, private _filtroServ: FiltrosService, public router: Router, public _serviciosService: PeticionesService, public localSt: LocalStorageService) { 
    
    this.route.queryParams.subscribe(params => {
      this.strTipo = params['strTipo'] == "novedades" ?"NOVEDADES": 
      params['strTipo'] == "ofertas" ?"OFERTAS": 
       params['strTipo'] == "destacados"?"DESTACADOS":
      params['strTipo'] == "liquidacion"?"LIQUIDACIÓN":"";

      this.strAccion = params['strTipo'] == "novedades" || params['strTipo'] == "destacados"  ?"getArtsNuevos":
        params['strTipo'] == "ofertas"||params['strTipo'] == "liquidacion" ?"getArtsDestacados":"";
    });
  }

  ngOnInit(): void {
    this.arrayProductos = [];
    this.fnExtaerProductos();
  }

  addToCart(obj:any) {
    const objString = JSON.stringify(obj); // Convertir el objeto a string
    const encryptedData = this.encryptData(objString);
    this.router.navigate(['/tienda-art'], { 
      queryParams: { data: encryptedData } // Enviar el string como parámetro
    });
  }
  onImageError(event: any) {
    event.target.src = 'assets/Fondos/image.png';
  }
  
  fnExtaerProductos(){
    let objenv = {
        strAccion: this.strAccion,
        strConexion: this.strConexion
      }
      this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
        result => {
       
          this.arrayProductos = result
          if(this.arrayProductos.length>0){
            this.arrayProductos.forEach(item => {
              item.hovered = false;
              let descuento = 100 - ((item.dblPrecioOferta / item.dblPrecioLista) * 100);
              let decimal = descuento - Math.floor(descuento);
              let resultado = decimal > 0.001 ? Math.ceil(descuento) : Math.floor(descuento);
              item.oferta=resultado;


            });
            this.arrayProductos.sort((a, b) => a.dblCantidad - b.dblCantidad);
          } 
         },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    }
     encryptData(data: any): string {
          try {
            const stringifiedData = JSON.stringify(data); // Convertir objeto a string
            const encrypted = CryptoJS.AES.encrypt(stringifiedData, this.secretKey).toString();
            return encodeURIComponent(encrypted); // Codificar para URL
          } catch (error) {
            console.error('Error al encriptar datos:', error);
            return '';
          }
        }
}
