<div class="container">
    <div class="row">
        <div class="col-12 mt-3">
            <h1 class="text-center fw-bold nunito">
                CANCELACIÓN DE <span class="titulo">PEDIDO</span>
            </h1>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="table-responsive mb-0 borderbott bordertop shadow">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="text-center bgtable fs13">Mov</th>
                            <th class="text-center bgtable fs13">MovID</th>
                            <th class="text-center bgtable fs13">Articulo</th>
                            <th class="text-center bgtable fs13">SubCuenta</th>
                            <th class="text-center bgtable fs13">Descripcion</th>
                            <th class="text-center bgtable fs13">Precio</th>
                            <th class="text-center bgtable fs13">Unidad</th>
                            <th class="text-center bgtable fs13">Cantidad</th>
                            <th class="text-center bgtable fs13">Cantidad Pendiente</th>
                       
                            <th class="text-center bgtable fs13">SubTotal</th>
                            <th class="text-center bgtable fs13 text-nowrap">Cantidad Indicada</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tdcenter" *ngFor="let pc of arrayCancelados;let ind = index">
                            <td>{{pc.strMov}}</td>
                            <td>{{pc.strMovID}}</td>
                            <td>{{pc.strArticulo}}</td>
                            <td>{{pc.strSubCuenta}}</td>
                            <td class="fs12">{{pc.strDescripcion}}</td>
                            <td class="text-end">{{pc.dblPrecio | currency}}</td>
                            <td>{{pc.strUnidad}}</td>
                            <td>{{pc.dblCantidad}}</td>
                            <td class=""><strong>{{pc.dblCantidadPendiente}} </strong></td>
                   
                            <td class="text-end">{{pc.dblSubTotal |currency}}</td>
                            <td>
                                <div class="col-12 position-relative">
                                    <input type="number"  [(ngModel)]="pc.cantidadCancelar" class="form-control fs12"> 
                                    <button (click)="cancelacionIndicada(pc)" class="btn btn-primary position-absolute top-50 end-0 translate-middle-y py-0 px-2"><i class="bi bi-check-circle-fill fs14"></i></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 my-3 text-center">
            <button data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn btn-primary" (click)="fnCargarCancelado()">
                <i class="bi bi-send-fill"></i> Completo
            </button>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-0 mx-auto fs-4" id="exampleModalLabel">CANCELAR</h2>
        <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-12 d-flex flex-column">
                <span>Concepto</span>
                <select class="form-select  mb-3" aria-label=".form-select-lg example">
                    <option value="" selected>--Concepto vacio--</option>
                    <option value="{{lc.strConcepto}}" *ngFor="let lc of listaConcepto">
                        {{lc.strConcepto}}
                    </option>
                  </select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex flex-column">
                <span>Observaciones</span>
                <input type="text" [(ngModel)]="inputObservaciones" class="form-control">
            </div>
        </div>
      </div>
      <div class="modal-footer ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary ms-auto" (click)="fnCancelarTodo()">Finalizar</button>
      </div>
    </div>
  </div>
</div>