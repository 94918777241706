 
import { PeticionesService } from '../servicios/httpservice'; 
import {ChangeDetectionStrategy, Component,   OnInit } from '@angular/core';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';

import {MatExpansionModule} from '@angular/material/expansion';



@Component({
  selector: 'app-ecommerce-pedidos-cte',
  templateUrl: './ecommerce-pedidos-cte.component.html',
  styleUrls: ['./ecommerce-pedidos-cte.component.css']
 
  
})
export class EcommercePedidosCteComponent implements OnInit {
 
// Lista de pedidos simulados
public  pedidos = [
  {
    numeroPedido: 1,
    fecha: '2025-01-10',
    total: 1000,
    estatus: 'En proceso',
    detalles: ['Producto 1', 'Producto 2', 'Producto 3']
  },
  {
    numeroPedido: 2,
    fecha: '2025-01-09',
    total: 500,
    estatus: 'Enviado',
    detalles: ['Producto A', 'Producto B']
  }
];
selectedOrder: any = null; // Almacena el pedido seleccionado para mostrar detalles

public userData_tienda:any=[];
public strConexion:string='COMERCIAL'
public arrayPedidos:any=[];
public arrayPedidos_original:any=[]
  constructor(private cartService: EcommerceCarrService,private _httpService: PeticionesService) {
    this.loadCart();
   }

  ngOnInit(): void {
    this.fnGetPedidos();
  }
  loadCart() { 
    this.cartService.getUsr().subscribe(data => {
        this.userData_tienda = data?data:[]; // Actualizamos la variable cuando los datos cambian
        console.log(this.userData_tienda);
    });
  }
 
 
  

  fnGetPedidos(){
 
    this.arrayPedidos = [];
    let articulo = {
      strAccion: "getPedidos_cte", 
      strConexion: this.strConexion,
      intID_Contacto:this.userData_tienda[0] && this.userData_tienda[0].intID_Contacto?this.userData_tienda[0].intID_Contacto:0 
      //intEjercicio:this.intEjercicio,
     // intPeriodo:this.intPeriodo
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => {
       console.log(result)
       this.arrayPedidos_original = result;
        if(result){
         let uniquePedidos=[]
           result.forEach(element => {
            let objB = uniquePedidos.find(o=>o.intID_Pedido ==element.intID_Pedido)
            if(!objB){
              element.detalles = this.arrayPedidos_original.filter(o=>o.intID_Pedido ==element.intID_Pedido)
              uniquePedidos.push(element)
            }
            
          });
          this.arrayPedidos =uniquePedidos;
         console.log(this.arrayPedidos)
        }
      
        
      
     
      },error=>{
        console.log(error.message) 
      });
  }
   // Método para asignar las clases de Bootstrap basadas en el estatus
   getEstatusClass(estatus: string) {
    switch (estatus) {
      case 'PENDIENTE':
        return 'bg-warning'; // Amarillo
      case 'FACTURADO':
        return 'bg-success'; // Verde
      case 'TRANSITO':
        return 'bg-primary'; // Azul
      case 'ENTREGADO':
        return 'bg-info'; // Cian
      default:
        return 'bg-secondary'; // Gris si no se encuentra el estatus
    }
  }

  // Método para asignar los iconos relacionados con el estatus
  getEstatusIcon(estatus: string) {
    switch (estatus) {
      case 'PENDIENTE':
        return 'fas fa-clock'; // Icono de reloj para pendiente
      case 'FACTURADO':
        return 'fas fa-file-invoice'; // Icono de factura para facturado
      case 'TRANSITO':
        return 'fas fa-truck'; // Icono de camión para tránsito
      case 'ENTREGADO':
        return 'fas fa-check-circle'; // Icono de check para entregado
      default:
        return 'fas fa-question-circle'; // Icono de pregunta si no se encuentra el estatus
    }
  }
}
