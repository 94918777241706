<div class="container">

    <div class="row bg-white bordertop borderbott align-items-center pb-2 my-4">
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        
        <div class="col-12 mt-2 pt-1 col-md-4 d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales " [value]="x.strClienteNombreCorto">{{x.strClienteNombreCorto}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        
        <div class="col-12 col-md mt-2 pt-1   d-flex justify-content-center align-items-center" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="getArtsPromocion()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="spinerArts" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="alert alert-danger text-center fw-bold my-3" *ngIf=" !spinerArts && arrayExistentes.length==0" role="alert">
                No se han encontrado datos de pedidos
              </div>
              <div  [hidden]="arrayExistentes.length==0">
                <div class="col-12 d-flex px-2 bg-white justify-content-between align-items-center">
                    <mat-form-field appearance="outline" class="bg-white rounded my-2">
                        <mat-label>Buscar:</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                      </mat-form-field>
                      <span > 
                      
                          <button class="btn btn-outline-success shadow-sm px-2 py-1 me-3" *ngIf=" arrayExistentes.length>0" (click)="exportToExcel_hist1()" (mouseenter)="isHoveredButton1 = true" (mouseleave)="isHoveredButton1 = false">
                            <i class="fa-regular fa-file-excel pe-2"></i>
                           <span class="fs12 fw-bold">Descargar</span> 
                          </button>
                        
                       <span class="fw-bold">Total Articulos:</span>  {{arrayExistentes.length}}
                      </span>

                  </div>
              </div>
              
            <div class="table-responsive mb-0" [hidden]="arrayExistentes.length==0">
                  <div class="mat-elevation-z8">
                    <table  class="table w-100 table-bordered" mat-table [dataSource]="dataSource" matSort>
                       
                        <ng-container matColumnDef="strArticulo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text ps-0 sticky-top"> Artículo </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table fs13 nunito fw-bold ps-0 ">{{row.strArticulo}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">Total</td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strDescripcion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Descripción </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle nunito fs-txt-table txtDescripcion">{{row.strDescripcion}}</td>
                            <td mat-footer-cell *matFooterCellDef></td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblEnero">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Enero </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-light':row.dblEnero==0,'text-primary fw-bold':row.dblEnero>0}">{{row.dblEnero}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalEnero }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblFebrero">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Febrero </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-secondary':row.dblFebrero==0,'text-primary fw-bold':row.dblFebrero>0}">{{row.dblFebrero}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalFebrero }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblMarzo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Marzo </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblMarzo==0,'text-primary fw-bold':row.dblMarzo>0}">{{row.dblMarzo}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalMarzo }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblAbril">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Abril </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblAbril==0,'text-primary fw-bold':row.dblAbril>0}">{{row.dblAbril}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalAbril }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblMayo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Mayo </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblMayo==0,'text-primary fw-bold':row.dblMayo>0}">{{row.dblMayo}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalMayo }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblJunio">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Junio </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblJunio==0,'text-primary fw-bold':row.dblJunio>0}">{{row.dblJunio}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalJunio }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblJulio">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Julio </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-secondary':row.dblJulio==0,'text-primary fw-bold':row.dblJulio>0}" >{{row.dblJulio}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalJulio }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblAgosto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Agosto </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-secondary':row.dblAgosto==0,'text-primary fw-bold':row.dblAgosto>0}" >{{row.dblAgosto}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalAgosto }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblSeptiembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Septiembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblSeptiembre==0,'text-primary fw-bold':row.dblSeptiembre>0}">{{row.dblSeptiembre}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalSeptiembre }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblOctubre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Octubre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-secondary':row.dblOctubre==0,'text-primary fw-bold':row.dblOctubre>0}">{{row.dblOctubre}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalOctubre }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblNoviembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Noviembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblNoviembre==0,'text-primary fw-bold':row.dblNoviembre>0}">{{row.dblNoviembre}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalNoviembre }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblDiciembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Diciembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblDiciembre==0,'text-primary fw-bold':row.dblDiciembre>0}">{{row.dblDiciembre}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ TotalDiciembre }}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="totales">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> TOTAL </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.total==0,'text-dark fw-bold':row.total>0}">{{row.total}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                              {{ getTotalGeneral() }}
                            </td>
                          </ng-container>
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                  
                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                      </tr>
                    </table>
                  </div>
            </div>
            <div class="col-12" [hidden]="arrayExistentes.length==0">
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>


<table id="tableHist_1" class="table table-bordered table-hover d-none">
  <!-- Cabecera -->
  <thead class="sticky-top bg-light">
    <tr>
      <th scope="col" class="text-center">Artículo</th>
      <th scope="col" class="text-center">Descripción</th>
      <th scope="col" class="text-center">Enero</th>
      <th scope="col" class="text-center">Febrero</th>
      <th scope="col" class="text-center">Marzo</th>
      <th scope="col" class="text-center">Abril</th>
      <th scope="col" class="text-center">Mayo</th>
      <th scope="col" class="text-center">Junio</th>
      <th scope="col" class="text-center">Julio</th>
      <th scope="col" class="text-center">Agosto</th>
      <th scope="col" class="text-center">Septiembre</th>
      <th scope="col" class="text-center">Octubre</th>
      <th scope="col" class="text-center">Noviembre</th>
      <th scope="col" class="text-center">Diciembre</th>
      <th scope="col" class="text-center">Total</th>
    </tr>
  </thead>

  <!-- Cuerpo -->
  <tbody>
    <tr *ngFor="let row of arrayExistentes">
      <td class="text-center align-middle">{{ row.strArticulo }}</td>
      <td class="text-center align-middle">{{ row.strDescripcion }}</td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblEnero == 0, 'text-primary fw-bold': row.dblEnero > 0}">
        {{ row.dblEnero }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblFebrero == 0, 'text-primary fw-bold': row.dblFebrero > 0}">
        {{ row.dblFebrero }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblMarzo == 0, 'text-primary fw-bold': row.dblMarzo > 0}">
        {{ row.dblMarzo }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblAbril == 0, 'text-primary fw-bold': row.dblAbril > 0}">
        {{ row.dblAbril }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblMayo == 0, 'text-primary fw-bold': row.dblMayo > 0}">
        {{ row.dblMayo }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblJunio == 0, 'text-primary fw-bold': row.dblJunio > 0}">
        {{ row.dblJunio }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblJulio == 0, 'text-primary fw-bold': row.dblJulio > 0}">
        {{ row.dblJulio }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblAgosto == 0, 'text-primary fw-bold': row.dblAgosto > 0}">
        {{ row.dblAgosto }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblSeptiembre == 0, 'text-primary fw-bold': row.dblSeptiembre > 0}">
        {{ row.dblSeptiembre }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblOctubre == 0, 'text-primary fw-bold': row.dblOctubre > 0}">
        {{ row.dblOctubre }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblNoviembre == 0, 'text-primary fw-bold': row.dblNoviembre > 0}">
        {{ row.dblNoviembre }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblDiciembre == 0, 'text-primary fw-bold': row.dblDiciembre > 0}">
        {{ row.dblDiciembre }}
      </td>
      <td class="text-center align-middle" [ngClass]="{'text-secondary': row.total == 0, 'text-dark fw-bold': row.total > 0}">
        {{ row.total }}
      </td>
    </tr>
  </tbody>

  <!-- Pie -->
  <tfoot class="bg-light">
    <tr>
      <th class="text-center align-middle">Total</th>
      <th></th>
      <th class="text-center align-middle">{{ TotalEnero }}</th>
      <th class="text-center align-middle">{{ TotalFebrero }}</th>
      <th class="text-center align-middle">{{ TotalMarzo }}</th>
      <th class="text-center align-middle">{{ TotalAbril }}</th>
      <th class="text-center align-middle">{{ TotalMayo }}</th>
      <th class="text-center align-middle">{{ TotalJunio }}</th>
      <th class="text-center align-middle">{{ TotalJulio }}</th>
      <th class="text-center align-middle">{{ TotalAgosto }}</th>
      <th class="text-center align-middle">{{ TotalSeptiembre }}</th>
      <th class="text-center align-middle">{{ TotalOctubre }}</th>
      <th class="text-center align-middle">{{ TotalNoviembre }}</th>
      <th class="text-center align-middle">{{ TotalDiciembre }}</th>
      <th class="text-center align-middle">{{ getTotalGeneral() }}</th>
    </tr>
  </tfoot>
</table>

