<div class="container-fluid">
  <div class="row">
    <div class="col-12 px-0">
        <nav aria-label="breadcrumb" class="px-0">
            <ol class="breadcrumb d-flex align-items-center mb-0">
              <li class="breadcrumb-item"><a routerLink="/tienda">Categorias</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{strCategoria}}</li>
            </ol>
        </nav>
    </div>
  </div>
</div>
<div class="container-fluid px-0">
    <div class="col-12 px-0 mb-3 position-relative">
<!--       <label for="" class="position-absolute top-50 start-50 translate-middle fs-4 fw-bold nunito">{{strCategoria}}</label> -->
      <img [src]="'assets/ecommerce/banners/B'+strCategoria+'.jpg'" class="w-100 " alt="" (error)="onImageErrorBanner($event)" (load)="onImageLoad($event)" >
    </div> 
</div>
<div class="container">
 <!--  <div class="col-12">
    <h1 class="text-center">LO MAS BUSCADO</h1>
  </div>
  <div class="col-12 ">
    <div class="row">
      <div class="col-6 col-lg-3 ">
        <img [src]="'assets/mercados/Mercados.jpg'" class="transition1 shadow-sm rounded mb-3 pointer w-100 imgAlt" alt="">
      </div>
      <div class="col-6 col-lg-3 ">
        <img [src]="'assets/mercados/Mercados.jpg'" class="transition1 shadow-sm rounded mb-3 pointer w-100 imgAlt" alt="">
      </div>
      <div class="col-6 col-lg-3 ">
        <img [src]="'assets/mercados/Mercados.jpg'" class="transition1 shadow-sm rounded mb-3 pointer w-100 imgAlt" alt="">
      </div>
      <div class="col-6 col-lg-3 ">
        <img [src]="'assets/mercados/Mercados.jpg'" class="transition1 shadow-sm rounded mb-3 pointer w-100 imgAlt" alt="">
      </div>
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="col-12 text-center">
      <h2 class="fs-4 nunito fw-bold mt-3">{{strCategoria}}</h2>
    </div>
  </div> -->
  <div class="col-12">
    <h1 class="text-center">BUSCA POR CATEGORÍA</h1>
  </div>
  <div class="row">
    <div *ngFor="let sublinea of arraySubCategoria" class="col-4 col-md-4 col-xl-3 d-flex justify-content-center"(click)="onCardClick(strCategoria,sublinea.strLinea )">
     <div class="shadow-sm rounded mb-3 w96 transition1 position-relative">
      <img [src]="sublinea.imageUrl" class="w-100 pointer" alt="{{ sublinea.strLinea }}">
      <div class="col-12 text-center opacity50 rounded-bottom position-absolute bottom-0 start-50 translate-middle-x py-2">
        <span class="nunito mb-0 text-center fw-bold fts" >{{ sublinea.strLinea }}</span>
      </div>
     </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-12 text-center my-4 position-relative">
      <span class="position-absolute top-50 start-0 translate-middle-y">
      </span>
    <!--   <span class="nunito fw-bold h3title mx-auto">Productos</span>   -->
      <span class="position-absolute top-50 end-0 translate-middle-y d-lg-none pointer" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom"  >
        Filtrar 
        <div class="contenedor-animado ms-2">
          <i class="bi bi-chevron-compact-down fs-5"></i>
        </div> 
      </span>
      <span class="position-absolute top-50 end-0 translate-middle-y d-none d-lg-flex pointer" (click)="fnCambiarHover()">
        Filtrar 
        <div class="contenedor-animado ms-2" [@menuAnimation]="hovered ? 'visible' : 'hidden'" >
          <i class="bi bi-chevron-compact-down fs-5"></i>
        </div> 
      </span>
    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="spiner">
  <div class="row justify-content-center  bg-white">
    <div class="col-4">
      <img class="w-100 pointer" [src]="'./assets/loading/op5.gif'" alt="">
    </div>
  </div>
</div>
<div class="container" *ngIf="!spiner">
  <div class="row">
    <div class="col-12">
      <h1 class="text-center">PRODUCTOS DE LA CATEGORIA</h1>
    </div>
  </div>
  <div class="row ">
    <div  [ngClass]="{'col-12':!hovered,'col-10':hovered}">
      <div class="row">
        <ng-container *ngFor="let producto of articulosFiltrados|filtersearch:strBuscar:['strArticulo','strDescripcion1']">
          <div *ngIf="producto.dblPrecioLista>0" class="col-6 col-md-4 col-xl-3 d-flex justify-content-center"  (click)="addToCart(producto)" >
            <div class="w96 transition1 shadow-sm rounded mb-2 px-2 py-2 bg-white">
              <div class="col-12 ">
                <div class="col-12 ovh position-relative">
                  <img class="col-12" style="visibility: hidden;" [src]="'https://www.cleanclean.mx/imagenes/'+producto?.strArticulo+'.JPG'" alt="{{ producto.strArticulo}}" (error)="onImageError($event)">
                  
                  <img     class="col-12 position-absolute top-50 start-50 translate-middle"  
                  [src]="'https://www.cleanclean.mx/imagenes/'+producto?.strArticulo+'.JPG'" alt="{{ producto.strArticulo}}" (error)="onImageError($event)">

                <!--  <img (mouseenter)="producto.hovered  = true"     (mouseleave)="producto.hovered  = false"    [@menuAnimation]="!producto.hovered   ? 'visible' : 'hidden'" class="col-12 position-absolute top-50 start-50 translate-middle"  
                     [src]="'https://www.cleanclean.mx/imagenes/'+producto?.strArticulo+'.JPG'" alt="{{ producto.strArticulo}}" (error)="onImageError($event)">

              <img class="h-100 col-12 position-absolute top-50 start-50 translate-middle"
                [@menuAnimation]="producto.hovered   ? 'visible' : 'hidden'"  [src]="'https://www.cleanclean.mx/imagenes/'+producto?.strArticulo+'-PO-2.JPG'" alt="{{ producto.strArticulo}}" (error)="onImageError($event)"> 
                -->
              
                  <span class="bgOferta position-absolute top-0 end-0 fw-bold nunito fs13 px-1 text-white mt-3" *ngIf="producto.strTipoPedido == 'OFERTA'">
                    OFERTA
                  </span>
                  <span class="bgLiquidacion position-absolute top-0 end-0 fw-bold nunito fs13 px-1 text-white mt-3" *ngIf="producto.strTipoPedido == 'LIQUIDACION'">
                    LIQUIDACIÓN
                  </span>
                </div>
                <div class="d-flex flex-column ">
                  <span class="mb-0 fs13 nunito text-dark fw-bold descripcion-truncada">{{ producto.strDescripcion1 }}</span>
                  <div class="col-12">
                  <!--   <div class="row">
                      <div class="d-flex flex-column col-6">
                        <span class="fs16 fw-bold mb-0 text-start text-dark" [ngClass]="{'fs11 text-decoration-line-through text-dark':producto.dblSaldo>0,'fw-bolder fs13 text-dark':producto.dblSaldo==0}">{{ producto?.dblPrecioLista|currency}}</span>
                      </div>
                      <div class="d-flex flex-column col-6">
                        <span class="fs16 fw-bold mb-0 text-end text-dark "><i class="bi bi-cart4 fs-4 pointer"></i></span>
                      </div>
                    </div> -->
                    <div class="row">
                      <div class="d-flex flex-column col-6 my-2">
                          <h4 class="precio mb-0 text-start" [ngClass]="{'fs11 text-decoration-line-through text-dark':producto.dblPrecioOferta>0,'fw-bolder fs-5 text-dark':producto.dblPrecioOferta==0}">{{ producto?.dblPrecioLista|currency}}</h4>
                          <h4 class="precio fw-bolder mb-0 text-start fs-5 text-dark" *ngIf="producto.dblPrecioOferta>0">{{ producto?.dblPrecioOferta|currency}}</h4>
                      </div>
                      <div class="d-flex flex-column col-6">
                        <span class="fs16 fw-bold mb-0 text-end text-dark "><i class="bi bi-cart4 fs-4 pointer"></i></span>
                      </div>
                    </div>
                  </div>
                 <!--  <div class="col-12 mt-auto d-flex align-items-center mt-auto ">
                    <div class="d-flex flex-column col-6">
                      <h4 class="ref text-start fw-bold fs13 mb-0 text-secondary">
                        REF {{producto?.strArticulo}}
                      </h4>
                    </div>
                  </div> -->



                  <div class="col-12  d-flex align-items-center  ">
                    <div class="d-flex  col-12 justify-content-between">
                      <h4 class="ref text-start fw-bold fs13 mb-0 text-secondary">
                        REF {{producto?.strArticulo}}
                      </h4>
                      <h4 class="fw-bold text-success fs13 mb-0" *ngIf="producto.dblPrecioOferta>0 && producto.strTipoPedido != ''" >
                        Ahorra: {{producto.oferta}}%
                      </h4>
                    </div>
                  </div>




                  <button class="btn btn-outline-secondary fs13 fw-bold" (click)="addToCart(producto)">Compre ahora</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        
      </div>
    </div>
    
    <div class="col-2 border rounded pt-3 d-none d-lg-block" *ngIf="hovered">
      <div class="col-12">
        <label for="">Buscar:</label>
        <input type="text" class="form-control fs13" [(ngModel)]="strBuscar" placeholder="REF,DESCRIPCION,Etcc.">
      </div>
     
      <div class="col-12">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h4 class="fs-5">Precio</h4>
            <button class="btn btn-link text-decoration-none"   (click)="resetFilter()">Borrar</button>
          </div>
        
          <div class="mb-3 d-flex align-items-center">
            <input type="number" class="form-control fs12 px-1" placeholder="de"[(ngModel)]="precioMin" (change)="applyFilter()" />
              <span class="mx-2">-</span>
            <input type="number" class="form-control fs12 px-1" placeholder="hasta" [(ngModel)]="precioMax" (change)="applyFilter()" />
          </div>
          <div class="d-flex justify-content-between align-items-center mb-1">
            <span class="d-flex fs12">{{ precioMin | currency }} <span class="fs10">MXN</span> </span>
            <span class="d-flex fs12">{{ precioMax | currency}} <span class="fs10">MXN</span></span>
          </div>
          <div class="d-flex">
            <input type="range" class="form-range" min="0"
            [max]="precioMid"
            [(ngModel)]="precioMin"
            (input)="applyFilter()"
          />
          <input type="range" class="form-range" min="{{precioMid}}" [max]="inputPrecioMax" [(ngModel)]="precioMax" (input)="applyFilter()" />
          </div>
         
        </div>
        
      </div>
    </div>
  </div>      
</div>

<div class="offcanvas offcanvas-bottom d-lg-none "  style="height: 60vh;" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
  <div class="offcanvas-header py-2">
  
    <button type="button" class="btn-close text-reset " data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body small">
    <div class="col-12">
      <div class="container">
        <label for="">Buscar:</label>
        <input type="text" class="form-control fs13" [(ngModel)]="strBuscar" placeholder="REF,DESCRIPCION,Etcc.">
      </div>
      
    </div>
   
    <div class="col-12 mt-2">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4 class="fs-6 fw-bold mb-0">Precio</h4>
        </div>
      
        <div class="mb-3 d-flex align-items-center">
          <input type="number" class="form-control fs12 px-1" placeholder="de"[(ngModel)]="precioMin" (change)="applyFilter()" />
            <span class="mx-2">-</span>
          <input type="number" class="form-control fs12 px-1" placeholder="hasta" [(ngModel)]="precioMax" (change)="applyFilter()" />
        </div>
        <div class="d-flex justify-content-between align-items-center mb-1">
          <span class="d-flex fs12">{{ precioMin | currency }} <span class="fs10">MXN</span> </span>
          <span class="d-flex fs12">{{ precioMax | currency}} <span class="fs10">MXN</span></span>
        </div>
        <div class="d-flex">
          <input type="range" class="form-range" min="0"
          [max]="precioMid"
          [(ngModel)]="precioMin"
          (input)="applyFilter()"
        />
        <input type="range" class="form-range" min="{{precioMid}}" [max]="inputPrecioMax" [(ngModel)]="precioMax" (input)="applyFilter()" />
        </div>
       
      </div>
      
    </div>
  </div>
</div>