<h2 mat-dialog-title>Información del Consumible</h2>
<mat-dialog-content class="mat-typography text-center">
  <h3>Consumible: <b>{{strClaveConsumible}}</b> - {{strNombreConsumible}}</h3>
    <hr>
       
    <mat-form-field appearance="fill" class="fBco fBco">
        <mat-label>Precio:</mat-label>
        <mat-select [(ngModel)]="dblPrecioConsumible">
            <mat-option *ngFor="let G of arrayListaPreciosMostrar" value="{{G.dblPrecio}}">
                {{G.strPrecioTipo}} - {{G.dblPrecio | currency }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <br><br>

    <mat-form-field appearance="fill" class="matcelln fBco">
        <mat-label>Cantidad Mensual en Cajas: </mat-label>
        <input matInput [(ngModel)]="intCantidadConsumible" min="1">
    </mat-form-field>

    <br><br>
    
    <mat-form-field appearance="fill" class="matcelln fBco">
        <mat-label>Lugar de Instalación: </mat-label>
        <input matInput [(ngModel)]="strLugarInstalacion">
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-danger">Cancelar</button>
  <button mat-button (click)="fnGuardarPreguntas();" class="btn-success" cdkFocusInitial>Guardar</button><!--[mat-dialog-close]="true"-->
</mat-dialog-actions>