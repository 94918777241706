import { Injectable } from '@angular/core';
import {  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor ,HttpHeaders ,HttpClient, HttpErrorResponse  } from '@angular/common/http';

import { map } from 'rxjs/operators'; //.pipe(map(res => res.json()));
import {BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router'; 
import { environment } from '../../environments/environment';
import { catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
 @Injectable()
 export class PeticionesService{
 	public url:string;
	public urlPaginaEcodeli:string;
	public urlModuloVtas:string;
	public urlCompras:string;
	public urlHostGator:string;
	public urlEcodeli: string;
	public urlEcodeliP: string;
	public urlVtex:string; 
	public urlPruebasPhp: string;
	public urlAromia: string;
	public urlAnexoMov:string;
	public urlLogoMov: string;
	public urlTiendaEcodeli:string;
	public urlleads:string;
	public urlAltaOperarios:string
	public urlMP:string;
	public urlPhpAltaClientes:string;
	public urlPhpOrdenCompra:string;
	public rutaApiV2: string;
	public urlAnexoComodato: string;
	public urlAnexoComodatoRev:string;
	public urlEmailEcommerce:string;
	public urlApiCliente:string;
	public urlCP:string;
	/***********variables para mostrar menu */
	private menuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public menu$: Observable<boolean> = this.menuSubject.asObservable();
	public mostrarMenu: boolean = true;
	private sendinblue_key :string = "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";
	/* 
	 * ambientes para probar la api de vtex
	 * vtexcommercestable or vtexcommercebeta
	 */
	 
 	constructor(private _http: HttpClient,router: Router, private localSt:LocalStorageService){
		/* console.log(environment.apiUrl)
		console.log(router.url) */
		this.urlAltaOperarios='https://www.limpiezamexico.mx/ApiRest/APIEncuestas/v1/Encuestas/';
 		this.urlModuloVtas = "https://www.cleanclean.mx/ApiRest/APIModuloVtas/v1/ModuloVtas/";
 		this.url = "https://jsonplaceholder.typicode.com/posts";
		this.urlPaginaEcodeli = "https://www.cleanclean.mx/ApiRest/APIPaginaWeb/v1/PaginaEcodeli/";
		this.urlCompras="https://www.cleanclean.mx/ApiRest/APIComprasComercial/v1/ComprasComercial/";
		this.urlHostGator="https://www.ecodeli.com.mx/";
		this.urlEcodeli="https://www.ecodeli.com/";
		this.urlEcodeliP="https://www.cleanclean.mx/ApiRest/APIEcodeliPremia/v1/EcodeliPremia/"
		this.urlVtex= "https://rubbermaidcedimx.vtexcommercestable.com.br/"
		//this.urlPruebasPhp= "http://localhost/localServer/"
		this.urlPruebasPhp = "https://www.ecodeli.com/email_leads.php";
		this.urlTiendaEcodeli = "https://www.ecodeli.com/api_shopify_ecodeli.php";
		this.urlAnexoMov="https://www.ecodeli.com/anexoSolicitud_Comodato.php";
		this.urlLogoMov = "https://www.ecodeli.com/anexoLogo_Lead.php"
		this.urlAromia = "https://www.ecodeli.com/api_shopify.php";
		this.urlleads='https://www.limpiezamexico.mx/ApiRest/APIPaginaIndustrial/v1/paginaIndustrial/';
		this.mostrarMenu=this.localSt.retrieve('MostrarMenu') ?this.localSt.retrieve('MostrarMenu') :true;
		this.urlMP= "https://www.ecodeli.com/api_mp.php";
		this.urlAnexoComodatoRev= "https://www.ecodeli.com/anexoEvidencia_ComodatoRev.php"
		this.urlAnexoComodato="https://www.ecodeli.com/anexoEvidencia_Comodato.php"
		this.urlPhpAltaClientes = "https://www.ecodeli.com/anexoAltaCliente.php";
		this.urlPhpOrdenCompra = "https://www.ecodeli.com/archivoOrdenCompra.php";
		this.rutaApiV2 = "https://www.cleanclean.mx/ApiRest/ApiEcodeliComercial/v2/PaginaEcodeli/";
		this.urlCP = "https://www.ecodeli.com/api_codigo_postal.php"
		this.urlEmailEcommerce = "http://localhost/envio_correos/enviar_correo.php"
		/* this.urlEmailEcommerce = "https://www.ecodeli.com/enviar_correo.php" */
	}
 /**MOSTRAR OCULTAR MENU**/
 getMostrarMenu(): boolean {
	console.log('entra a menu')
	console.log(this.mostrarMenu)
	this.mostrarMenu=this.localSt.retrieve('MostrarMenu')?this.localSt.retrieve('MostrarMenu'):true;
    return this.mostrarMenu;
  } 
  setMostrarMenu(valor:boolean){
	this.mostrarMenu=valor==false?valor:true;
	this.localSt.store('MostrarMenu',this.mostrarMenu);
	console.log('entra a mostrar menu')
	console.log(this.mostrarMenu)
	this.menuSubject.next(this.mostrarMenu);
  }

 /***********************/
	/** fin prueba **/
	/**servicio para modulo cotizador */
	getCotizador(objModulo:any):Observable<any>{
		return this._http.post(this.urlEcodeliP+'sp_ModVentas_Cotizador',objModulo);
	}
	
 	getPrueba(){ 		
 		return 'Hola Mundo';
 	}
     
	getArticulos():Observable<any>{
		return this._http.get(this.url);
	}

	getModuloVtas(objModulo:any):Observable<any>{
		return this._http.post(this.urlModuloVtas+'sp_ModuloVtas',objModulo);
	}

	getApiCompras(objModulo:any):Observable<any>{
		return this._http.post(this.urlCompras+'sp_ObjMix',objModulo);
	}
	
	getApiComprasGral(objModulo:any, sp:string):Observable<any>{
		return this._http.post(this.urlCompras+sp, objModulo);
	}

	getApiComprasScordCardReportes(objModulo:any):Observable<any>{
		return this._http.post(this.urlCompras+'sp_ScordCardReportes',objModulo);
	}

	getApiComprasScordCardJefe(objModulo:any):Observable<any>{
		return this._http.post(this.urlCompras+'sp_ScordCardVendedor_Jefes',objModulo);
	}
	getApiComprasScordCardVendedor(objModulo:any):Observable<any>{
		return this._http.post(this.urlCompras+'sp_ScordCardVendedor',objModulo);
	}
	getApiComprasScordCard(objModulo:any):Observable<any>{
		console.log(objModulo)
		console.log(this.urlCompras+'sp_ScordCard');
		return this._http.post(this.urlCompras+'sp_ScordCard',objModulo);
	}
	getApiComprasPedidos(objModulo:any):Observable<any>{
		return this._http.post(this.urlEcodeliP+'sp_ModVentas_Pedidos',objModulo);
	}
	
	getVentasMovimientos(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'getVentasMovimientos',objModulo);
	}
	getpagViejitaPlantillas(objModulo:any,accion:string):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+accion,objModulo);
	}
	getVentasMovimientos_p(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'getMovPendientesVendedor',objModulo);
	}
	setReActivarPedido(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'setReActivarPedido',objModulo);
	}
	
	getInfoCabecera(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'getInfoCabecera',objModulo);
	}
	getVentasMovimientosDetalle(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'getVentasMovimientosDetalle',objModulo);
	}
	setPedidosPorAutorizar(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'setPedidosPorAutorizar',objModulo);
	}
	setCambioCondicionPedido(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'setSolicitudCambioCondicionPedido',objModulo); //setCambioCondicionPedido
	}

	getCondicionContado(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'getCondicionContado',objModulo);
	}

	setAdjuntoOrdenCompra(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'setAdjuntoOrdenCompra',objModulo);
	}

	dropPedido(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'dropPedido',objModulo);
	}

	getExistenciasAlm(objModulo:any):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+'getDisponibleXAlmacen',objModulo);
	}
	getComodatos(objModulo:any,sp:string):Observable<any>{
		//console.log(this.urlPaginaEcodeli+sp) ruta anterior
		console.log(this.urlEcodeliP+sp)
		return this._http.post(this.urlEcodeliP+sp,objModulo);
	}
	getApiRetoTork (objModulo:any):Observable<any>{
		return this._http.post(this.urlCompras+'sp_retoTork',objModulo);
	}
	getEncriptado(objModulo:any):Observable<any>{  
		let rutaReal=this.urlHostGator+'cotizadorapp/valuserN.php'
		let rutaAlterna ='https://www.ecodelinegocio.com/enueva/cotizadorApp/valuserN.php'
		return this._http.post(rutaAlterna , JSON.stringify(objModulo)); 
	}

	getEcodeli(objModulo:any,strApi:string):Observable<any>{ 
		return this._http.post(this.urlEcodeliP+strApi,objModulo); 
	}
	insertCotizacion(objModulo:any,strApi:string):Observable<any>{ 
		return this._http.post(this.urlEcodeliP+strApi,objModulo); 
	}
	getObjCorreo(cuerpo:string,asunto:string, archivo:File,destinatario:string,remitente:string):Observable<any>{ 
		const formData = new FormData();
		formData.append('cuerpo', cuerpo);
		formData.append('asunto', asunto);
		formData.append('archivo', archivo);
		formData.append('remitente',remitente);
		formData.append('destinatario',destinatario);
		return this._http.post(this.urlPruebasPhp,formData); 
	}

	getApiComprasComodatos(objModulo:any):Observable<any>{
		return this._http.post(this.urlCompras+'sp_ReporteComododatos',objModulo);
	}
	/*******************CAPTURADOR*** DE** PEDIDOS***********************/
	getListaPrecios(objModulo:any):Observable<any>{
		return this._http.post(this.urlEcodeliP+'sp_PreciosCapturador',objModulo);
	}
	setPedido(objModulo:any):Observable<any>{
		return this._http.post(this.urlEcodeliP+'sp_Set_Pedido',objModulo);
	}

	mostrarCotizaciones(objModulo:any,sp:string):Observable<any>{
		return this._http.post(this.urlEcodeliP+sp,objModulo);
	}
	 
	/*******************VTEX************************************/
	getVtex(strApi:string):Observable<any>{ 
	 
	let headers = new HttpHeaders();
	/*headers = headers.set("Access-Control-Allow-Origin", "*");
    headers = headers.set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
    headers = headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type,     Accept, x-client-key, x-client-token, x-client-secret, Authorization");
	*///headers = headers.set("Origin", "https://www.ecodeli.com");
	headers = headers.set('Content-Type', 'application/json; charset=utf-8');
	headers = headers.set('Accept', 'application/json');
    headers = headers.set('X-VTEX-API-AppKey', 'vtexappkey-rubbermaidcedimx-LGJYZK');
	headers = headers.set('X-VTEX-API-AppToken', 'QOKAMMFYUYXKJFWNKSMOMREWHVPTTEJPSHWXIRNAUOQGBRHWEBLGSQUOGPGVLWPCVVAXMJZFSZGIHEGFILSALQTWWGTLDMWZJKLJQGHDQPEWKTKMIUXSPYWIAZIBOKQZ');

	let url = `${ this.urlVtex +strApi}` //en produccion debe ser urlVtex +strApi

    const requestOptions = { headers: headers };
	
    	return this._http.get( url,requestOptions); //requestOptions
	}
	putVtex(strApi:string, param:any):Observable<any>{ 
		let headers = new HttpHeaders();
		headers = headers.set("Access-Control-Allow-Origin", "*");
		headers = headers.set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
		headers = headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type,     Accept, x-client-key, x-client-token, x-client-secret, Authorization");
	   
		headers = headers.set('Content-Type', 'application/json; charset=utf-8');
		headers = headers.set('Accept', 'application/json');
		headers = headers.set('X-VTEX-API-AppKey', 'vtexappkey-rubbermaidcedimx-LGJYZK');
		headers = headers.set('X-VTEX-API-AppToken', 'QOKAMMFYUYXKJFWNKSMOMREWHVPTTEJPSHWXIRNAUOQGBRHWEBLGSQUOGPGVLWPCVVAXMJZFSZGIHEGFILSALQTWWGTLDMWZJKLJQGHDQPEWKTKMIUXSPYWIAZIBOKQZ');
		let url = `${  strApi}` //en produccion debe ser urlVtex +strApi
	  
		const requestOptions = { headers: headers };
		 
			//let param ={'quantity' :2}
			return this._http.put( url,param,requestOptions); //requestOptions
		}
		getVtex_php(strApi:string):Observable<any>{ 
			
			return this._http.get(this.urlEcodeli+'api_vtex.php' ); 
	
		}
		putVtex_php(strApi:string, param:any):Observable<any>{ 
			console.log(param);
			return this._http.post(this.urlEcodeli+'api_vtex_Put.php',JSON.stringify(param) ); 
	
		}
		getVtexEcodeli(objModulo:any):Observable<any>{ 
			return this._http.post(this.urlCompras+'SP_VTEX',objModulo);
		} 
	 
	/************************FIN VTEX***************************/
	 
	//Metodo para subir archivos PDF
	uploadFilePDF(archivo:any){
		return this._http.post(`${this.urlEcodeli+'/PDF_Comodatos_Prueba/'}subirArchivos.php`, JSON.stringify(archivo));
	}
    subirArchivo(archivo: File): Observable<any> {
		let apiUrl = 'https://www.ecodeli.com/anexoConvenio_Politica.php';
		const formData = new FormData();
		formData.append('archivo', archivo); // 'archivo' es el nombre esperado en PHP
	
		return this._http.post(apiUrl, formData);
	  }
	getAlmacenes(): Observable<any> {
		return this._http.get(this.urlPaginaEcodeli+'getAlmacenes' );
	  }

	getOrders(objModulo:any): Observable<any> {
		return this._http.post(this.urlAromia+'?financial_status=paid',objModulo);
	  }

	  /* --------------Api tienda Ecodeli----------- */
	  getOrdersEcodeli(objModulo:any): Observable<any> {
		return this._http.post(this.urlTiendaEcodeli+'?financial_status=paid',objModulo);
	  }

	  /**--------------API MERCADO PAGO ----------------------------**/
	  setPAgo(form:any): Observable<any> {
		return this._http.post(this.urlMP ,form);
	  }
	  /* ----------------------Comodatos----------------------------- */
getLineas(objModulo:any,strApi:string): Observable<any> {
	return this._http.post(this.urlPaginaEcodeli+strApi,objModulo);
  }

  getLineasComodatos(objModulo:any,strApi:string): Observable<any> {
	return this._http.post(this.urlEcodeliP+strApi,objModulo);
  }

  setAnexMov(objModulo:any):Observable<any>{
    return this._http.post(this.urlAnexoMov,objModulo);
  }
  sp_Leads(objLogin:any):Observable<any>{
    return this._http.post(this.urlleads+'sp_Leads',objLogin);
  }
  sp_Contactame(objLogin:any):Observable<any>{
    return this._http.post(this.urlleads+'sp_Contactame',objLogin);
  }
/* llenar datos con codigo postal */
getAllSATCP(objLogin:any):Observable<any>{
	return this._http.post(this.urlAltaOperarios+'getAllSATCP',objLogin);
  }
  uploadFiles(formData: FormData) {
    return this._http.post<any>(this.urlPhpAltaClientes, formData);
  }
  uploadContrato_Comodato(formData: FormData) {
	let url = "https://www.ecodeli.com/anexoContrato_Comodato.php";
	console.log('envia contrato')
    return this._http.post<any>(url, formData);
  }
  uploadOrdenCompra(formData: FormData) {
    return this._http.post<any>(this.urlPhpOrdenCompra, formData);
  }

  uploadPagare(ruta, formData: FormData) {
    return this._http.post<any>(ruta, formData);
  }

  setAnexMov1(objModulo:any):Observable<any>{
	console.log(objModulo)
    return this._http.post(this.urlLogoMov,objModulo);
  }

  	getClienteDatos(objModulo:any,sp:string):Observable<any>{
		return this._http.post(this.urlPaginaEcodeli+sp,objModulo);
	}
	
	getClienteFacturas(objModulo:any,sp:string):Observable<any>{
		let url_fact = "https://www.cleanclean.mx/ApiRest/ApiEcodeliComercial/v2/PaginaEcodeli/"; //getconsultarFacturas
		return this._http.post(url_fact+sp,objModulo);
	}
  makeFileRequest(url:string, params:Array<string>, files:Array<File>){
	return new Promise((resolve, reject)=>{ 
	  var formData: any =new FormData();
	  var xhr = new XMLHttpRequest();
	 
	  for(var i =0; i <files.length; i++){
		formData.append('uploads[]', files[i], files[i].name);
		xhr.onreadystatechange = function(){
		  if(xhr.readyState==4){
			if(xhr.status == 200){
			  resolve(JSON.parse(xhr.response));
			}else{
			  reject(xhr.response);
			}
		  }
		};
	  }
	  xhr.open("POST", url, true)
	  xhr.send(formData)
	});

  }
  makeFileRequestMinuta(url: string, params: Array<string>, formData: FormData): Promise<any> {
	return new Promise((resolve, reject) => { 
	  const xhr = new XMLHttpRequest();
  
	  // Configurar la petición AJAX
	  xhr.onreadystatechange = function () {
		if (xhr.readyState == 4) {
		  if (xhr.status == 200) {
			resolve(JSON.parse(xhr.response));
		  } else {
			reject(xhr.response);
		  }
		}
	  };
  
	  // Enviar la petición con FormData
	  xhr.open("POST", url, true);
	  xhr.send(formData);
	});
  }
  
  
  detalleArticulo(objModulo:any,sp:string):Observable<any>{
	return this._http.post(this.rutaApiV2+sp,objModulo);
}
 
	setAnexoComotado(objModulo:any):Observable<any>{
    	return this._http.post(this.urlAnexoComodatoRev,objModulo);
	}

  setAnexoComotadoRev(objModulo:any):Observable<any>{
    return this._http.post(this.urlAnexoComodato,objModulo);
  }

  /* ------------------------------Obtener codigos postales------------------------------ */

  getCp(zipCode: string): Observable<any> {
	let url_cp='https://sepomex.icalialabs.com/api/v1/zip_codes?per_page=200'
    return this._http.get<any>(`${url_cp}&zip_code=${zipCode}`).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Error en la API:', error);
    return throwError('Error al obtener datos. Intente de nuevo más tarde.');
  }


  /*------------------------aplazo-------------------api */
  getTokenApz(objModulo:any): Observable<any> {
	let urlAplazo='https://www.ecodeli.com/api_aplazo.php'
	  let  objParam ={ "apiToken":"00aaaafe-76b8-49d0-aee3-88109507fef8",
		"merchantId": "11571" , "MerchantURL":"https://www.ecodeli.com"
		  }

		/**datos de prueba */
		/** 
		 * { "apiToken":"4413ab01-0d48-4ddc-be36-fccca99f3664",
		"merchantId": "2581" }
		   **/
	return this._http.post(urlAplazo,objParam);
  }
  getTokenApz_partner(objModulo:any): Observable<any> {
	let urlAplazo='https://www.ecodeli.com/api_aplazo_partner.php'
	  let  objParam = { "apiToken":"4413ab01-0d48-4ddc-be36-fccca99f3664",
		"merchantId": "2581" }
	  //datos de producción
	  /*{ "apiToken":"00aaaafe-76b8-49d0-aee3-88109507fef8",
		"merchantId": "11571" , "MerchantURL":"https://www.ecodeli.com"
		  }*/

		/**datos de prueba */
		/** 
		 * { "apiToken":"4413ab01-0d48-4ddc-be36-fccca99f3664",
		"merchantId": "2581" }
		   **/
	return this._http.post(urlAplazo,objParam);
  }

  setLoanRequestApz(objModulo:any):Observable<any>{
	let urlAplazo='https://www.ecodeli.com/api_aplazo_loan.php' //https://www.ecodeli.com/
	console.log(urlAplazo)
	console.log(objModulo)
    return this._http.post(urlAplazo,objModulo);
  }
  setLoanRequestApz_partner(objModulo:any):Observable<any>{
	let urlAplazo='https://www.ecodeli.com/api_aplazo_loan_partner.php' //https://www.ecodeli.com/
	console.log(urlAplazo)
	console.log(objModulo)
    return this._http.post(urlAplazo,objModulo);
  }

  sendEmail(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post<any>(this.urlEmailEcommerce, data, { headers });
  }
/*
  sendinblue(to: string,name:string,  content: string): Observable<any> {
	let api_url = "https://api.brevo.com/v3/smtp/email";
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'api-key': this.sendinblue_key,
      'content-type': 'application/json'
    });
    // Cuerpo del correo (puedes personalizarlo más según sea necesario)
    const emailBody = {
      sender: {
        name: "Ecodeli Comercial",
        email: "info@ecodeli.com.mx"
      },
      to: [
        {
          email: to,
          name: name
        }
      ],
      subject: "Confirmación de Pedido",
      htmlContent: content
    };
    return this._http.post(api_url, emailBody, { headers });
  }
*/
  sendinblue(to: string, name: string, content: string): Observable<any> {
	const apiUrl = "https://clean.ecodeli-negocio.com/ApiRest/Api_negocio/send_email_negocioB.php";
	//"https://www.ecodeli.com/send_email_negocio.php";// 'http://tu-servidor.com/send_email_negocio.php'; // URL de tu servidor PHP
	const emailData = {
	  to: to,
	  name: name,
	  content: content
	};
	console.log(emailData)
  
	return this._http.post(apiUrl, emailData);
  }
 }

 
 
 

    
	 