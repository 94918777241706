import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEcommerce'
})
export class FilterEcommercePipe implements PipeTransform {

  transform(items: any  ,  args: string): any {
      let resultPost = items.data;
      if (!args) return items;
    // Verificar si 'items' es un arreglo
 
  
    args = args.toLowerCase();
  
    // Filtrar los elementos
    return resultPost.filter((it) => {
      return it.strEstatus_Pago.toLowerCase().includes(args);
    });
  }
}
