import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { CarroService } from '../servicios/carroservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from "rxjs";
import { CarritoNService } from '../servicios/carrito-n.service';
@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {
  public arrayOpciones: any;
  public intCantidad: number;
  public intExistenciasSucursal: number;
  public intExistencias: number;
  public strArticulo: string;
  public strNombreArticulo: string;
  public dblPrecio: number;
  public objOpcion: any;
  public arrayDetalle: any;
  public intFichaTecnica: number;
  public intHojaSeguridad: number;
  public intNumeroImagenes: number;
  public intSustitutos: number;
  public strDescripcion: string;
  public strEstatus: string;
  public strRespuesta: string;
  public strTipoOpcion: string;
  public arrayImagenes: any;
  public urlImg: string;
  public strSucursal: string;
  public arrayInventarioPorSucursales: any;
  public arrayExistencias: any;
  public art: string;
  public strOpcion: string;

  constructor(private _router: Router, private rutaActiva: ActivatedRoute, private _peticionesService: PeticionesService, private _carro: CarroService, private _carrito: CarritoNService) {//
    this.arrayOpciones = [];
    this.intCantidad = 1;
    this.intExistenciasSucursal = 0;
    this.intExistencias = 0;
    this.strArticulo = "dtcell";
    this.strNombreArticulo = "";
    this.dblPrecio = 0;
    this.arrayDetalle = [];
    this.objOpcion = this.arrayOpciones[0];
    this.intFichaTecnica = 0;
    this.intHojaSeguridad = 0;
    this.intNumeroImagenes = 0;
    this.intSustitutos = 0;
    this.strDescripcion = "";
    this.strEstatus = "";
    this.strRespuesta = "";
    this.strTipoOpcion = "No";
    this.arrayImagenes = [];
    this.strSucursal = "0";
    this.urlImg = "https://www.cleanclean.mx/imagenes/";
    this.arrayInventarioPorSucursales = [];
    this.arrayExistencias = [];
    this.art = "";
    this.strOpcion = "";
  }

  ngOnInit(): void {
    //Obtener el usuario de la url (default al cargar)
    this.strArticulo = this.rutaActiva.snapshot.params.parametro;
    //Obtener el usuario del observable (en cualquier momento cuando cambie despues de cargar)
    this.rutaActiva.params.subscribe(
      (params: Params) => {
        this.strArticulo = params.parametro;
        //console.log(this.strArticulo);
        this.fnGetDetalle();
      }
    );
    //this.fnGetDetalle();
  }


  fnGetDetalle() {
    this._peticionesService.getModuloVtas({
      strAccion: 'getDetalle',
      strArticulo: this.strArticulo,
      strSucursal: this.strSucursal
    }).subscribe(
      result => {
        this.arrayDetalle = result;
        if (this.arrayDetalle.length > 0) {
          this.dblPrecio = this.arrayDetalle[0].dblPrecio;
          this.intExistencias = this.arrayDetalle[0].intExistencias;
          this.intFichaTecnica = this.arrayDetalle[0].intFichaTecnica;
          this.intHojaSeguridad = this.arrayDetalle[0].intHojaSeguridad;
          this.intNumeroImagenes = this.arrayDetalle[0].intNumeroImagenes;
          this.intSustitutos = this.arrayDetalle[0].intSustitutos;
          this.strArticulo = this.arrayDetalle[0].strArticulo;
          this.strDescripcion = this.arrayDetalle[0].strDescripcion;
          this.strEstatus = this.arrayDetalle[0].strEstatus;
          this.strNombreArticulo = this.arrayDetalle[0].strNombreArticulo;
          this.strRespuesta = this.arrayDetalle[0].strRespuesta;
          this.strTipoOpcion = this.arrayDetalle[0].strTipoOpcion;
          this.intExistenciasSucursal = this.arrayDetalle[0].intExistenciasSucursal;
          this.arrayImagenes = [];
          if (this.intNumeroImagenes > 1) {
            for (let i = 2; i <= this.intNumeroImagenes; i++) {
              this.arrayImagenes.push({ 'imagen': 'https://www.cleanclean.mx/imagenes/' + this.strArticulo + '-po-' + i + '.jpg' })
            }
          }
          this.fnOpciones();
        }
        //console.log(this.arrayDetalle);
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  fnOpciones() {
    this._peticionesService.getModuloVtas({
      strAccion: 'getOpciones',
      strArticulo: this.strArticulo
    }).subscribe(
      result => {
        this.arrayOpciones = result;
        this.objOpcion = result.length > 0 ? result[0] : {};
        //console.log(this.arrayOpciones);
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAbrirModal() {
    //console.log("funcion para traer datos de la base de datos");
    //this.strSucursal= this.strSucursal=='0'?'1':this.strSucursal;    
    //Si el articulo tiene opciones, revisar sí ya escogio opcion para mostrar inventario por opcion, de lo contrario mostrar inventario general
    if ((this.strTipoOpcion == 'No') || (this.strTipoOpcion == 'Si' && this.objOpcion.strOpcion == '')) {
      this._peticionesService.getModuloVtas({
        strAccion: 'getInvPorSucursal',
        strArticulo: this.strArticulo
      }).subscribe(
        result => {
          this.arrayInventarioPorSucursales = result;
          this.intExistenciasSucursal = this.arrayInventarioPorSucursales.find((o: any) => o.strSucursal == this.strSucursal).intInventario;
          //console.log(this.arrayInventarioPorSucursales);
          //console.log(this.intExistenciasSucursal);
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else { //Ir a funcion para traer inventario por Opcion
      this._peticionesService.getModuloVtas({
        strAccion: 'getInvPorOpciones',
        strArticulo: this.strArticulo,
        strOpcion: this.objOpcion.strOpcion
      }).subscribe(
        result => {
          this.arrayInventarioPorSucursales = result;
          this.intExistenciasSucursal = this.arrayInventarioPorSucursales.find((o: any) => o.strSucursal == this.strSucursal).intInventario;
          //console.log(this.arrayInventarioPorSucursales);
          //console.log(this.intExistenciasSucursal);
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    }
  }


  fnSeleccionoOpcion() {
    this._peticionesService.getModuloVtas({
      strAccion: 'getExistenciasGrales',
      strArticulo: this.strArticulo,
      strOpcion: this.objOpcion.strOpcion,
      strSucursal: this.strSucursal
    }).subscribe(
      result => {
        this.arrayExistencias = result;
        //console.log(this.arrayExistencias);
        this.intExistencias = this.arrayExistencias[0].intExistencias;
        this.intExistenciasSucursal = this.arrayExistencias[0].intExistenciasSucursal;
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  /*fnSetArticulo(strArticulo:string,intCantidad:number,strNombreArticulo:string,dblPrecio:number,objOpcion:any){
      console.log(strArticulo+' - '+intCantidad+' - '+strNombreArticulo+' - '+dblPrecio+' - '+objOpcion.strOpcion);
      this._carro.getPrueba();
      console.log("add carrito");
      //this._carro.setArticulo({strArticulo: strArticulo,dblCantidad:intCantidad,strNombreArticulo:strNombreArticulo,dblPrecio:dblPrecio,strOpcion:objOpcion.strOpcion,strNombreOp:objOpcion.strNombreOpcion, intExistenciasSucursal: this.intExistenciasSucursal});    
    }*/


  //Vivi
  fnSetArticulo(strArticulo: string, intCantidad: number, strNombreArticulo: string, dblPrecio: number, objOpcion: any) {
    //strArticulo = 'BN';
    //intCantidad=1;
    console.log("carro");
    this._carrito.setArticulo({strArticulo: strArticulo,dblCantidad:intCantidad,strNombreArticulo:strNombreArticulo,dblPrecio:dblPrecio,strOpcion:objOpcion.strOpcion,strNombreOp:objOpcion.strNombreOpcion, intExistenciasSucursal: this.intExistenciasSucursal});
  }
  //Vivi


  fnSetArticuloCot(strArticulo: string, intCantidad: number, strNombreArticulo: string, dblPrecio: number, objOpcion: any) {
    this._carrito.setArtToCotizacion({ strArticulo: strArticulo, dblCantidad: intCantidad, strNombreArticulo: strNombreArticulo, dblPrecio: dblPrecio, strOpcion: objOpcion.strOpcion, strNombreOp: objOpcion.strNombreOpcion, intExistenciasSucursal: this.intExistenciasSucursal });

  }
  fnSetArticuloCot_viejo(strArticulo: string, intCantidad: number, strNombreArticulo: string, dblPrecio: number, objOpcion: any) {
    this._carro.setArtToCotizacion({ strArticulo: strArticulo, dblCantidad: intCantidad, strNombreArticulo: strNombreArticulo, dblPrecio: dblPrecio, strOpcion: objOpcion.strOpcion, strNombreOp: objOpcion.strNombreOpcion, intExistenciasSucursal: this.intExistenciasSucursal });

  }


}
