import { Component, ElementRef, OnInit } from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { HttpClient } from '@angular/common/http';
import { CarroService } from '../servicios/carroservice';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { AlertifyService } from '../alertify.service';
import { DataDBService } from '../servicios/data-db.service';
@Component({
  selector: 'app-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.css']
}) 
export class CotizacionesComponent implements OnInit {
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;

  public arrayCotizaciones:any=[];
  public strConexion:string="";
  public arrayDetalleArts:any=[];

  public boolGerente:boolean=false;
  public boolSucursal:boolean=false; 
  public boolAgente:boolean=false;
  public spinerTab:boolean=false;

  public arrayAgentes:any=[];
  public arrayGerentes:any=[];
  public arraySucursales:any=[];
  public arrayAccesos:any=[];
  public arrayPeriodo:any=[];
  public arrayEjercicio:any=[];
  public spinerMdl:boolean=false;
  public arrayDetPlantillas:any=[];
  public objCte:any;

  public strSucursal:string="";
  public strAgente:string="";
  public strGerente: string="";
  public objCliente:any;

  public tagName:any;

  constructor(private indexedDBService: DataDBService,public _alertify: AlertifyService,private editPl:PlantillafinService,private localSt:LocalStorageService,private carroservice:CarroService, private router: Router,private _httpService:PeticionesService,elem:ElementRef,private _filtroServ :FiltrosService)
  {
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1;
    this.strAgente=this.localSt.retrieve('stragente') ?this.localSt.retrieve('stragente'):'' ;
    this.strSucursal=this.localSt.retrieve('strsucursal') ?this.localSt.retrieve('strsucursal'):'' ; 
    this.tagName=elem.nativeElement.tagName.toLowerCase();

    this.indexedDBService.getItem('arrayResult', (value) => {     
      this.arrayAccesos=value?value:[];
      this.strConexion = this.localSt.retrieve('strConexion');
      if(this.arrayAccesos.length>0){
        var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
        
        if(objAcceso==undefined||objAcceso.length==0){
          this.router.navigate(['/login']);
        }
        else{ 
          this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
  
          this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
          this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
  
  
          this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
  
          this.strAgente = this.localSt.retrieve('strAgente');
          this.strGerente = (!this.boolGerente && this.boolSucursal) ? objAcceso[0].strUsuario  : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
          this.fnGetFiltros();
          this.fnextraercotizaciones();
  
        }
      }
      else { //redireccionar a login
        this.router.navigate(['/login']);
      }
     


     });
   // this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
    
  }

  ngOnInit(): void {
   

  }

  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => { 
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => { 
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => { 
      this.arrayGerentes = nuevoValor;
    }); 
  }
  

  fnextraercotizaciones(){
this.spinerTab =true; 
    let obj={
      strGerente:this.strGerente,
      strSucursal:this.boolSucursal||this.boolAgente? this.strSucursal:"",
      strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""), 
      intEjercicio: this.intEjercicio, 
      intPeriodo: this.intPeriodo,
      strConexion:this.strConexion,
      strAccion:"getCotizaciones_2"
    } 
    this._httpService.getCotizador(obj).subscribe(
      result => {
        this.arrayCotizaciones = result
        if(this.arrayCotizaciones && this.arrayCotizaciones.length>0 ){
          this.arrayCotizaciones.forEach(x => {
            x.isHovered = false;
            x.mostrarDetalle=false;
            x.mostrarEye=false;
          });
        }
        this.spinerTab = false; 
      },
      error => {
        console.error('Error al consumir la API:', error);
        this.spinerTab = false;
        // Puedes manejar el error de alguna manera específica aquí
      });
  }

  fnEliminarCotizacion(item:any){
    let obj={
      strIDMovimientoVenta: item.intIDMovimientoVenta,
      strConexion:this.strConexion
   }
   this._httpService.mostrarCotizaciones(obj,'setEliminarCotizacionNew').subscribe(
      result => { 
        Swal.fire({
        icon: 'success',
        title: 'Correcto',
        text: 'Eliminado correctamente'
        });
        this.fnextraercotizaciones()
     },
     error => {
       console.error('Error al consumir la API:', error);
     });
  }
  fnMostrarDetalleCotizacion(item:any){
    if(item.mostrarDetalle == true){
      item.mostrarDetalle = false
    }
    else{
    let obj={
      strIDMovimientoVenta: item.intIDMovimientoVenta,
      strConexion:this.strConexion
   }
   this._httpService.mostrarCotizaciones(obj,'getDetalleCotizacionesNew').subscribe(
     result => { 
      console.log(result)
       this.arrayDetalleArts=result;
       this.arrayCotizaciones.forEach(x => {
         if(item == x){
          item.mostrarDetalle = true;
         }else{
          x.mostrarDetalle=false;
         }
      });
     },
     error => {
       console.error('Error al consumir la API:', error);
       item.mostrarDetalle = true
     });}
  }

  fechasComoDate(cotizacion): Date {
    const fecha = moment(cotizacion.strFecha, 'MM/DD/YYYY hh:mm:ss');
    // Verificar si la fecha es válida antes de convertirla a Date
    if (fecha.isValid()) {
      return fecha.toDate();
    } else {
      console.warn('Fecha inválida:', cotizacion.strFecha);
      // Puedes devolver null o una fecha por defecto, según tus necesidades
      return cotizacion.strFecha;
    }
  }

  fgCotizador(objVenta:any,opcion=2){

      this.spinerMdl = true;
      this.arrayDetPlantillas =[];
      this.objCte = objVenta;
      let obj={ intIDMovimientoVenta: objVenta.intIDMovimientoVenta,
        strConexion:this.strConexion,
        strAccion:'getDetallePlantilla'
     }
  
     this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Cotizador').subscribe(
       result => {  
        console.log(result)
         this.arrayDetPlantillas = result
         this.objCte.strFamilia = result[0].strFamilia
         this.objCte.strGiro = result[0].strGiro
        
          this.fnSetCarrito(result, objVenta.intIDMovimientoVenta,objVenta,opcion);
          let objPlantilla = {boolActive:true, intIDMovimientoVenta:objVenta.intIDMovimientoVenta}
          this.editPl.setPlantilla(objPlantilla);
           //activamos moda plantilla
           this.carroservice.setModoPlantilla(true);
         this.spinerMdl = false; 
       }, error => {
         console.error('Error al consumir la API:', error);
         this.spinerMdl = false;
         // Puedes manejar el error de alguna manera específica aquí
       });
    
  }

  fnSetCarrito(arrayArtis:any, intIDMovimientoVenta :number, objVenta:any,opcion:number){
    //borramos carrito
    this.carroservice.deleteCarrito(); 

    //preparamos los articulos
    arrayArtis.forEach(element => {
      let precio =  element.dblPrecioSugerido && element.dblPrecioSugerido > 0 ?element.dblPrecioSugerido:element.dblPrecioLista;//opcion ==2?element.dblPrecioLista:element.dblPrecioSugerido;
      let preciop = opcion ==2?element.dblPrecioPlantilla:element.dblPrecioSugerido;

      let objArt = {  boolEditaPlantilla: true, checkbx: true, dblCalificacion: 3,
          dblCantidad: element.dblCantidad, dblCostoPromedio: 0,  dblCostoTeorico: 0,
          dblPrecio: 0, dblPrecio2: 0, dblPrecio3: 0,  dblPrecio4: 0,   dblPrecio10: 0, 
          dblPrecioLista: precio, 
          dblPrecioMinimo: element.dblPrecioMinimo,
           dblPrecioPlantilla: preciop, 
          dblPrecioPolitica: element.dblPrecioPolitica, 
          dblPrecioSugerido: element.dblPrecioSugerido, 
          dblTipoCambio: 0, dblUltimoPrecio: 0, intEjercicio: 0,
          intIDMovimientoVenta: intIDMovimientoVenta, intIDSucursal: 0,
          intPeriodo: 0,  intPrecio: 0, intPrecio2: element.dblPrecio2, intPrecio3: element.dblPrecio3, 
          intPrecio4: element.dblPrecio4,  intPrecioLista: element.dblPrecioLista,
          intPrecioPlantilla: preciop,
           intPrecioPolitica: element.dblPrecioPolitica,
           intTotalEstrellas: 2,
          pagina: 1,  strArticulo: element.strArticulo, strCategoria: element.strCategoria,
          strComentario: "", strDescripcion1: element.strDescripcion1, strLinea: element.strLinea,
          strNombreOpcion: "",  strOpcion:element.strOpcion,  strTipo:element.strTipo,
          strTipoOpcion: element.strTipoOpcion, arrayOpciones:[]
        }
      let mdP =false;
      this.carroservice.setModoPlantilla(mdP);
      this.carroservice.setArticulo( objArt);
    });
    this._alertify.success("Articulo agregado correctamente");
    this.router.navigate(['/carritoresumen']); 
      //agregar cliente
      let alm =objVenta.strSucursal=='0'?'1':objVenta.strSucursal;
    let objCte = {
              ClienteSeleccionado:true,
              strAgente: objVenta.strAgente,
        strNombre :  objVenta.strNombreCliente,
        strCliente:  objVenta.strCliente,
        intIDSucursal:objVenta.intIDSucursal , 
        strAlmacen:objVenta.strAlmacen,
        strClaveUsoCFDI :  objVenta.strClaveUsoCFDI,
        strCondicion :  objVenta.strCondicion,
        strDescripcionUsoCFDI :  objVenta.strDescripcionUsoCFDI,
        strDireccion :   objVenta.strDireccion,
        strInfopago :   objVenta.strInfopago,
        strInfopagoDescripcion:  objVenta.strInfopagoDescripcion,
        strNombreSucursal:"",
        strSucursal:"0",
        strTipo:objVenta.strTipo
            }  
  this.carroservice.setCte(objCte);
   }

}
 