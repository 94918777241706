<div class="container">
  <div class="row">
    <div class="col-12 text-center my-4">
      <h1>Comodatos <span class="title">Programador</span></h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card shadow-sm px-0 mx-3 mb-5 mi-div">
        <div class="card-header text-white">Filtrado</div>
        <div class="card-body" style="background: #ecedee;">
          <div class="col-12 col-lg-3 col-md-6 col-sm-12">
            <div class="col-12 col-md-11">
              <mat-form-field appearance="fill" class="inFiltro shadow-sm ">
                <mat-label>Periodo</mat-label>
                <mat-select [(ngModel)]="intPeriodo">
                  <mat-option  *ngFor="let item of arrayperiodo" class="optionstrans" [value]="item.id">
                    {{ item.strestatus }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>date_range</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6 col-sm-12">
            <div class="col-12 col-md-11 py-4 py-md-0">
              <mat-form-field appearance="fill" class="inFiltro shadow-sm ">
                <mat-label>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                  <mat-option *ngFor="let item of arrayejercicio" class="optionstrans" [value]="item.id" >
                    {{ item.strestatus }}
                  </mat-option>
                </mat-select>
                <!-- <input matInput placeholder="Nombre Cliente"> -->
                <mat-icon matSuffix>event</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6 col-sm-12">
            <div class="col-12 col-md-11 pt-md-4 pt-lg-0">
              <mat-form-field appearance="fill" class="inFiltro shadow-sm ">
                <mat-label>Estatus</mat-label>
                <mat-select [(ngModel)]="strStatus">
                  <mat-option class="mi-div2" *ngFor="let item of arrayEstatus"[value]="item.id">
                    {{ item.strestatus }}
                  </mat-option>
                </mat-select>
                <!-- <input matInput placeholder="Nombre Cliente"> -->
                <mat-icon matSuffix>report</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6 col-sm-12 pt-4 pt-md-0 mb-4">
            <div class="col-12 col-md-11 pt-md-4 pt-lg-0 text-center">
              <button class="btn btn-primary btnfil buttonicon " mat-raised-button (click)="fngetComodatos()" >
                <mat-icon>filter_list</mat-icon>
              </button>
            </div>
          </div>
          <div class="row">
            <hr />
          </div>
          <div class="col-12 col-lg-3 col-md-6 col-sm-12 mt-3">
            <div class="col-12 col-md-11">
              <mat-form-field appearance="fill" class="inFiltro shadow ">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Id Comodato, Cliente, Nombre, Etcc.." #input/>
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="col-12 table-responsive pb-0 mb-0 mat-elevation-z8 mi-div2">
        <div [hidden]="spinnerImg == false" class="text-center h-25">
          <img src="assets/spinner.gif" class="altimg" alt="" />
        </div>
        <div [hidden]="datosImg == false" class="text-center">
          <label for="" class="alert alert-danger w-75 my-5"
            >No se encontraron comodatos!</label
          >
        </div>
        <table [hidden]="spinnerImg == true || datosImg == true" mat-table [dataSource]="dataSource">
          <!-- ID Column -->
          <ng-container matColumnDef="intIDComodato">
            <th mat-header-cell *matHeaderCellDef>ID Comodato</th>
            <td class="border-end border-dark fs-td" mat-cell *matCellDef="let row">
            <b> {{ row.intIDComodato }}</b>
            </td>
          </ng-container>
          <!-- Progress Column -->
          <ng-container matColumnDef="strCliente">
            <th mat-header-cell *matHeaderCellDef class="text-center px-3 ">
              Cliente
            </th>
            <td class="border-end text-center border-dark px-3 fs-td" mat-cell *matCellDef="let row">
           <b>  {{ row.strCliente }}</b>
            </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="strNombre">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Nombre
            </th>
            <td class="border-end text-center border-dark" mat-cell *matCellDef="let row">
              <b>{{ row.strNombre }}</b>
            </td>
          </ng-container>
          <ng-container matColumnDef="strEstatusProgramador">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Estatus
            </th>
            <td class="border-end text-center border-dark" mat-cell *matCellDef="let row">
              <mat-icon *ngIf="row.strEstatusProgramador == 'PROGRAMADO'" style="color: #26c24b">camera_rear</mat-icon>
              <mat-icon *ngIf="row.strEstatusProgramador == 'PORPROGRAMAR'" style="color: #e49e43">camera_rear</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="intCantidad">
            <th mat-header-cell *matHeaderCellDef class="text-center px-3">
              Cantidad
            </th>
            <td class="border-end text-center border-dark px-3" mat-cell *matCellDef="let row">
              <b> {{ row.intCantidad }}</b>
            </td>
          </ng-container>
          <ng-container matColumnDef="accion">
            <th class="" mat-header-cell *matHeaderCellDef  class="text-center px-3">
              Detalles
            </th>
            <td mat-cell *matCellDef="let row" class="px-1 text-center">
              <button class="my-2 buttonicon" mat-mini-fab matTooltip="Primary" color="primary" (click)=" fnEditar(row.intIDComodato,row.strDireccion,row.strCliente,row.strNombre,row.strEstatusProgramador)">
                <mat-icon>border_color</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">
              No se encontro el dato <b>"{{ input.value }}"</b>
            </td>
          </tr>
        </table>
      </div>
      <mat-paginator class="mi-div2 mb-5" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
  </div>
</div>
