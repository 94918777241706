import {  Component, OnInit ,ElementRef, AfterViewInit , ViewChild,ChangeDetectorRef} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonModule, CurrencyPipe} from '@angular/common'; 
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
@Component({
  selector: 'app-objmixagente',
  templateUrl: './objmixagente.component.html',
  styleUrls: ['./objmixagente.component.css']
})

export class ObjmixagenteComponent implements OnInit,AfterViewInit {
  @ViewChild('nameInput') nameInput?:ElementRef;
  public dblObjetivoA:number=0;
 
  public dblObjetivoD:number=0;
  public dblObjetivoG:number=0;
  public arrayObjetivosAux:any=[{strLinea:'ACCESORIOS',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0},
  {strLinea:'BLUE TISSUE',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0},
  {strLinea:'DESPACHADORES ECODELI',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0},
  {strLinea:'KIMBERLY',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0},
  {strLinea:'OTROS',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0},
  {strLinea:'QUIMICOS',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0},
  {strLinea:'RUBBERMAID',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0},
  {strLinea:'TORK',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0},
  {strLinea:'XIBU',Mes1:'',Mes2:'', Mes3:'',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0,dblCObjt:0}
 ];
 public strAgente:string='';
 public arrayHistorico:any=[];
 public arrayAccesos:any=[];
 public tagName:any;
 public btnAutorizar:any=false;
 public btnRechazar:any=false;
 public btnGuardarD:any=true;
 public strEstatus:string='';
 public strEstatusS:string='';
 public strObservaciones:string='';
 
 public strObservacionesS:string='';
 public arrayObjetivosC:any=[];
 public sucursalSelected:any={};
 public dblObjetivoSucursal:any=0;
 public arrayHistSucursal:any=[];
 public dblObjetivoC:any=0;
 public dblObjetivoAS:any=0;
 public dblTotalVenta:number=0;
 public strNombreM1='';
 public strNombreM2='';
 public strNombreM13='';
 public usAmount = 100;
 /*
  1 .- obtener la venta del mes anterior y actual 
  2.-Obtener obtetivo asignado
  3.-poder distribuir sus objetivos por lineas
  */
  constructor(private formBuilder: FormBuilder,private currencyPipe : CurrencyPipe,elem:ElementRef,private _peticionesService: PeticionesService,public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router) { 
    this.tagName=elem.nativeElement.tagName.toLowerCase();
  }

  myForm = this.formBuilder.group({
    deposit: ['', [
      Validators.required, // Validators
      Validators.min(1),
      Validators.max(1000000)
    ]],
  });
  
  ngOnInit(): void {
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }
      else {
        this.strAgente= objAcceso[0].strAgente;
        this.btnGuardarD=objAcceso.find((o:any)=>o.strAccion=='Guardar')?true:false; 
        this.fnGetObjetivo();
      }
    }
  }

  ngAfterViewInit(): void {   
    
  }

  fnGetObjetivo(){
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivoAgentes' ,
      strGerente:this.strAgente
    }).subscribe(result => {
      this.dblObjetivoA=result.length>0?result[0].dblObjetivo:0;
      /*this.nameInput!.nativeElement.value = this.dblObjetivoA;
      let E = new FocusEvent('blur');
      
      console.log(E);
      this.nameInput!.nativeElement.dispatchEvent(E);*/

      this.strObservaciones=result.length>0?result[0].strSucursal:0;
      this.strEstatus=result.length>0?result[0].strEstatus:0;
      this.arrayObjetivosC=result;
    
      this.fnGetHistorial();
    });
  }

  fnGetHistorial(){
    this._peticionesService.getApiCompras({
      strAccion: 'getHistoticoAgt' ,
      strGerente:this.strAgente
    }).subscribe(
      result => { 
          this.arrayHistorico=result;
        this.fnAlgo();
      });
  }

  fnAlgo(){
    //obtener los ultimos 3 meses
    var today = new Date();
    let mes1=today.getMonth()+1; //actual
    let mes2=today.getMonth(); //+1;
    let mes3=today.getMonth()-1;
 
    if (mes1==9){
      this.strNombreM1='Septiembre';
      this.strNombreM2='Agosto';
      this.strNombreM13='Julio';
    }
    else if (mes1==10){
      this.strNombreM1='Octubre';
      this.strNombreM2='Septiembre';
      this.strNombreM13='Agosto';
    }
    this.dblTotalVenta =0;
    this.arrayHistorico.forEach((elemento:any)=> {
      this.dblTotalVenta = this.dblTotalVenta + elemento.dblObjetivo;
    });
    
    this.arrayObjetivosAux.forEach((elemento:any)=> { 
      let x = this.arrayHistorico.find((o:any)=>o.strLinea==elemento.strLinea && o.intPeriodo==mes1);
      
      let x2 = this.arrayHistorico.find((o:any)=>o.strLinea==elemento.strLinea && o.intPeriodo==mes2);
      let x3 = this.arrayHistorico.find((o:any)=>o.strLinea==elemento.strLinea && o.intPeriodo==mes3);
      let y = this.arrayObjetivosC.find((o:any)=>o.strLinea==elemento.strLinea);
      elemento.mes1=x?x.dblObjetivo:0;
      elemento.mes2=x2?x2.dblObjetivo:0;
      elemento.mes3=x3?x3.dblObjetivo:0;
     /* elemento.dblMontoSug=x?x.dblObjetivo:0;
      elemento.dblPsugerido=elemento.dblMontoSug/this.dblTotalVenta ;
      elemento.dblCObjt= this.currencyPipe.transform(this.dblObjetivoA*elemento.dblPsugerido);
      elemento.dblObjetivo=  y?y.dblObjetivo:this.dblObjetivoA*elemento.dblPsugerido;
      
      this.dblObjetivoG=this.dblObjetivoG+elemento.dblObjetivo;*/
 
      console.log(elemento.dblObjetivo);
    });
    this.dblObjetivoD=this.dblObjetivoA-this.dblObjetivoG;
    //this.fnGetObjetivos();
  }

  fnGuardar(){ console.log(this.arrayObjetivosAux);
      this._peticionesService.getApiCompras({
        strAccion: 'setObjetivoAgente' ,
        strGerente:this.strAgente,
        xmlLineas:this.arrayObjetivosAux
      }).subscribe(
        result => {  this._alertify.success('Objetivo Guardado Correctamente'); 
    
    });
  }

  
  fnSumar(event:any,item:any){  
    this.dblObjetivoG=0;
    this.dblObjetivoD=0;
    item.dblObjetivo=parseFloat(event.target.value);
    this.arrayObjetivosAux.forEach((elemento:any)=> { 
      this.dblObjetivoG=this.dblObjetivoG+elemento.dblObjetivo;
    });
    this.dblObjetivoD=this.dblObjetivoA-this.dblObjetivoG;
  }


}
