import { Injectable } from '@angular/core';
import {PeticionesService} from '../servicios/httpservice';
import {BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import * as moment from 'moment'; // Importa Moment
@Injectable({
  providedIn: 'root'
})
export class ArticulosService {
    public strConexion:string;
    public diferenciaHoras:number;
    /****variables para manejar articulos en carrito carrito ******/
    private arrayArts_Subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public arrayArts_$: Observable<any> = this.arrayArts_Subject.asObservable();
    private arrayArts:any=[];

  constructor(private _http:PeticionesService, private localSt:LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
    let fecha_Update = this.localSt.retrieve('Hr_Actualizacion_arts');
    // Obtener la fecha actual
    const fechaActual = moment();
    //obtener la fecha guardada
    const fechaMoment = moment(fecha_Update, 'YYYY-MM-DD HH:mm:ss');
    // Obtener la diferencia en horas entre las dos fechas
    this.diferenciaHoras = fechaActual.diff(fechaMoment, 'minutes');
    console.log(fechaActual );
    console.log(fecha_Update );
    console.log('La diferencia en horas es:', this.diferenciaHoras);
   }

   fnGetExistencias(){

    if(!this.diferenciaHoras || this.diferenciaHoras >120){ //si ya pasaron dos horas, volver a llamar el servicio
      console.log('diferencia fue mayor a  cero')
      this._http.getEcodeli({strAccion:'getDisponible' , strConexion:this.strConexion}, 'sp_ModVentas_TiendaEcodeli_Shopify').subscribe(
        result => {
          this.arrayArts=result;
          this.arrayArts_Subject.next(this.arrayArts);
         console.log(result)
         this.localSt.store('arrayArts',this.arrayArts);
         let fecha= moment().format('YYYY-MM-DD HH:mm:ss');
         this.localSt.store('Hr_Actualizacion_arts',fecha);
        });
      }
      else {
          console.log('aun no pasan las dos horas')
          console.log(this.localSt.retrieve('arrayArts'))
          this.arrayArts=this.localSt.retrieve('arrayArts');
          this.arrayArts_Subject.next(this.arrayArts);
      }
    
   }
}
 