<div class="container"> 
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold titulo text-decoration-underline">Reloj Checador</h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strSSN">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100" *ngIf="banderaTab==1">
                <mat-label>Quincena</mat-label>
                <mat-select [formControl]="ctrlQuincena" >
                    <mat-label>Selecciona una opción</mat-label>
                    <mat-option value="">Ninguna</mat-option>
                    <mat-option value="PRIMERA">PRIMERA</mat-option>
                    <mat-option value="SEGUNDA">SEGUNDA</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100" *ngIf="banderaTab==1">                
                <mat-label>Semana</mat-label>
                <mat-select [formControl]="ctrlSemana" >
                    <mat-label>Selecciona una opción</mat-label>
                    <mat-option value="">Ninguna</mat-option>
                    <mat-option value="1">PRIMERA</mat-option>
                    <mat-option value="2">SEGUNDA</mat-option>
                    <mat-option value="3">TERCERA</mat-option>
                    <mat-option value="4">CUARTA</mat-option>
                    <mat-option value="5">QUINTA</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100" *ngIf="banderaTab!=1">     
                <mat-label>Estatus</mat-label>
                <mat-select [formControl]="ctrlEstatus" (selectionChange)="CambioEstatus()"  >
                    <mat-label>Selecciona una opción</mat-label>
                    <mat-option value="PENDIENTE">PENDIENTE</mat-option>
                    <mat-option value="AUTORIZADO">AUTORIZADO</mat-option>
                    <mat-option value="RECHAZADO">RECHAZADO</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false" (click)="ProcesarReporte()">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i> <!--(click)="fnGetPlantillas()" -->
            </button>

            <!--<button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false" (click)="DetalleModal()">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i> 
            </button>-->
        </div>
    </div>

    <div class="row bg-white bordertop borderbott shadow pb-4 mt-2">
        <mat-card>
            <mat-tab-group (selectedTabChange)="onTabClick($event)" [selectedIndex]="banderaTab-1">
                
                <mat-tab label="RelojChecador">
                    <loader [hidden]="!banderaCargando" ></loader>


                    <div [hidden]="banderaCargando" id="contenedorTabla" style="padding: 1px;">

                        <!-- <div class="row">
                            <div class="col-12" id="alert-reloj"> -->

                        <div class="d-flex gap-2 justify-content-end my-4" style="color: #002f87;" role="alert" [ngStyle]="(screen.isXSmall) ? {fontSize: '13px'}:{fontSize: '14px'}" [ngClass]="(screen.isXSmall) ? 'justify-content-around' : ''">
                            <div class="d-flex align-items-center" [ngClass]="(screen.isXSmall) ? 'flex-column' : 'gap-2'">
                                <strong>Entradas Puntual</strong>
                                <span class="badge badge-success" style="border-radius: 50px;padding: 5px 10px; background: #28a745;">{{Correctas}}</span>
                            </div>

                            <div class="d-flex align-items-center" [ngClass]="(screen.isXSmall) ? 'flex-column' : 'gap-2'">
                                <strong>Retardos</strong>   
                                <span class="badge badge-warning" style="border-radius: 50px;padding: 5px 10px; background: #ffc107;">{{Retardos}}</span>
                            </div>

                            <div class="d-flex align-items-center" [ngClass]="(screen.isXSmall) ? 'flex-column' : 'gap-2'">
                                <strong>Faltas</strong>                         
                                <span class="badge badge-danger" style="border-radius: 50px;padding: 5px 10px; background: #dc3545;">{{Faltas}}</span>
                            </div>
                        </div>

                            <!-- </div>
                        </div> -->

                        <dataTable [data_table]="data_table_reloj" [data_items]="arrayRelojChecador"></dataTable>
                    </div>

                </mat-tab>

                <mat-tab label="Permisos"> 
                    <div class="d-flex justify-content-end my-3">
                        <button type="button" class="btn btn-success" (click)="AbrirModalPermiso()">Nuevo Permiso</button>
                    </div>

                    <div style="padding: 1px;">
                        <loader [hidden]="!banderaCargando" ></loader>
                        <dataTable [hidden]="banderaCargando" [data_table]="data_table_permisos" [data_items]="arrayPermisos"></dataTable>
                    </div>
                </mat-tab>

                <mat-tab label="Vacaciones">  
                    <div class="d-flex justify-content-end my-3">
                        <button type="button" class="btn btn-success" (click)="AbrirModalVacaciones()">Nueva Solicitud Vacacional</button>
                    </div>


                    <div style="padding: 1px;">
                        <loader [hidden]="!banderaCargando" ></loader>
                        <dataTable [hidden]="banderaCargando" [data_table]="data_table_vacaciones" [data_items]="arrayVacaciones"></dataTable>
                    </div>
                </mat-tab>

                <mat-tab label="Indicadores">
                    <div *ngIf="arrayIndicadores!=''" class="mt-3">
                        <loader [hidden]="!banderaCargando" ></loader>
                        
                        <div [hidden]="banderaCargando" id="contenedorTabla">

                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Solicitudes de Prestamos
                                    <span class="badge badge-primary badge-pill azul">{{strPrestamosPendientes}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Solicitudes de Vacaciones
                                    <span class="badge badge-primary badge-pill azul">{{strIDVacacionesPendientes}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Solicitudes de Permisos
                                    <span class="badge badge-primary badge-pill azul">{{strIDPermisosPendientes}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Vacaciones Disponibles
                                    <span class="badge badge-primary badge-pill azul">{{strDiasVacacionesPendientes}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="dblTotalPorPagar>0">
                                    Saldo de prestamo
                                    <span class="badge badge-primary badge-pill azul">{{dblTotalPorPagar | currency}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Aniversario
                                    <span class="badge badge-primary badge-pill azul">{{strFechaAniversario}}</span>
                                </li>
                            </ul>

                        </div>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
</div>

<div class="modal fade" id="contenido" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detalle</h5><button type="button" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body"> 

                <div *ngIf="valDetalle1=='PERMISO'" class="d-flex gap-2 flex-wrap">
                    <div class="w-100 py-1 px-2" style="background-color: rgba(0,0,0,.05);border-radius: 3px;">
                        <strong style="color: #002f87">Goce de Sueldo</strong>
                        <div>{{valDetalle5}}</div>
                    </div>
                    
                    <div class="w-100 py-1 px-2" style="border-radius: 3px;">
                        <strong style="color: #002f87">Motivo</strong>
                        <div>{{valDetalle2}}</div>
                    </div>
                    
                    <div class="w-100 py-1 px-2" style="background-color: rgba(0,0,0,.05);border-radius: 3px;">
                        <strong style="color: #002f87">Horario Solicitado</strong>
                        <div>{{valDetalle3}} - {{valDetalle4}}</div>
                    </div>
                    
                    <div class="w-100 py-1 px-2" style="border-radius: 3px;">
                        <strong style="color: #002f87">Fecha Solicitud</strong>
                        <div>{{valDetalle6}}</div>
                    </div>
                    
                    <div class="w-100 py-1 px-2" style="background-color: rgba(0,0,0,.05);border-radius: 3px;">
                        <strong style="color: #002f87">Autorización</strong>
                        <div>{{valDetalle7}}</div>
                        <div>{{valDetalle8}}</div>
                    </div>
                </div>
        
                <div *ngIf="valDetalle1=='VACACIONES'" class="d-flex gap-2 flex-wrap">
                    
                    <div class="w-100 py-1 px-2" style="background-color: rgba(0,0,0,.05);border-radius: 3px;">
                        <strong style="color: #002f87">Fechas Solicitadas</strong>
                        <div>{{valDetalle2}} - {{valDetalle3}}</div>
                    </div>
        
                    <div class="w-100 py-1 px-2" style="border-radius: 3px;">
                        <strong style="color: #002f87">Días Vacacionales</strong>
                        <div>{{valDetalle4}}</div>
                    </div>
                    
                    <div class="w-100 py-1 px-2" style="background-color: rgba(0,0,0,.05);border-radius: 3px;">
                        <strong style="color: #002f87">Días Solicitados</strong>
                        <div>{{valDetalle5}}</div>
                    </div>
                    
                    <div class="w-100 py-1 px-2" style="border-radius: 3px;">
                        <strong style="color: #002f87">Fecha Autorización</strong>
                        <div>{{valDetalle6}}</div>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!--<button type="button" class="btn btn-primary">Save
                    changes</button>-->
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="contenidoMotivo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Permisos</h5><button type="button" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body"> 

                <mat-form-field appearance="outline" class="w-100 small-mat-form">
                    <mat-label>Motivo</mat-label>
                    <input matInput type="text" [(ngModel)]="motivo" placeholder="Ingresa el motivo." maxlength="200" />
                </mat-form-field>
        
        
                <mat-form-field appearance="outline" class="w-100 small-mat-form" style="margin-top: 10px;" *ngIf="accionstore=='3'">
                    <mat-label>Goce de Sueldo</mat-label>
                    <mat-select [(ngModel)]="goceSueldo" >
                        <mat-option value="SI">SI</mat-option>
                        <mat-option value="NO">NO</mat-option>
                    </mat-select>
                </mat-form-field>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!--<button type="button" class="btn btn-primary">Save
                    changes</button>-->

                <button type="button" class="btn btn-success" (click)="AutorizaMov()">Finalizar</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="contenidoNuevoPermiso" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Nueva solicitud de permiso</h5><button type="button" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body"> 
                <form [formGroup]="permisoForm" class="d-flex gap-2 flex-wrap">

                    <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                        <mat-label for="ctrlfechaRegistro">Fecha Registro</mat-label>
                        <input matInput type="text" formControlName="fecha"  readonly="true">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form" [ngStyle]="screen.isXSmall || screen.isSmall ? {width: '190px'}:{}">
                        <mat-label >Fecha Permiso</mat-label>
                        <input matInput type="date" formControlName="fechaPermiso"  min="{{fecha}}">
                    </mat-form-field>
        
                    <div class="d-flex justify-content-center flex-grow-1 flex-column">
                        <strong style="color: #002f87;text-align: center;">Goce Sueldo</strong>
                        <div class="d-flex gap-2 justify-content-center">
                            <input type="radio" name="goceSueldo" formControlName="goceSueldo" value="SI"> Si
                            <input type="radio" name="goceSueldo" formControlName="goceSueldo" value="NO"> NO
                        </div>
                        
                    </div>
        
                    <div class="flex-grow-1 d-flex gap-2 flex-wrap">
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                            <mat-label for="ctrlfechaRegistro">Motivo Permiso</mat-label>
                            <mat-select formControlName="motivoPermiso" (selectionChange)="CambioMotivo($event)">
                                <mat-option value="{{MotivosPermiso.strMotivoAutorizacion}}" *ngFor="let MotivosPermiso of arrayMotivoPermisos">
                                    {{MotivosPermiso.strMotivoAutorizacion}}
                                </mat-option>
                                <mat-option value='otro'>Otro Motivo</mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form" *ngIf="banderaPermisoOtro">
                            <mat-label>Ingresa el Motivo</mat-label>
                            <input matInput type="text"  placeholder="Ingresa el motivo" formControlName="otroMotivo" maxlength="200"/>
                        </mat-form-field>
                    </div>
        
                    <div class="flex-grow-1 d-flex gap-2 flex-wrap">
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                            <mat-label for="ctrlfechaRegistro">Hora Inicio</mat-label>
                            <input matInput type="time" formControlName="horaInicio">
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                            <mat-label for="ctrlfechaRegistro">Hora Fin</mat-label>
                            <input matInput type="time" formControlName="horaFin">
                        </mat-form-field>
                    </div>
                </form>                

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <!--<button type="button" class="btn btn-primary">Save
                    changes</button>-->
                    <button type="submit" class="btn btn-success" [disabled]="permisoForm.invalid" (click)="save($event)" data-bs-dismiss="modal">Guardar</button> 
                        
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="contenidoNuevoVacaciones" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Nueva solicitud de Vacaciones</h5><button type="button" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body"> 
                <form [formGroup]="vacacionesForm">
            
                    <div class="d-flex gap-2 flex-wrap">
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                            <mat-label for="ctrlfechaRegistro">Fecha Registro:</mat-label>
                            <input matInput type="text" formControlName="fechaRegistro"  readonly="true">
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                            <mat-label for="ctrlfechaRegistro">Dias Vigentes:</mat-label>
                            <input matInput type="number" formControlName="DiasVigentes"  readonly="true">
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                            <mat-label for="ctrlfechaRegistro">Fecha Inicio:</mat-label>
                            <input matInput type="date" formControlName="fechaInicioVacaciones"  min="{{fecha}}" (change)="CambioFecha()">
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                            <mat-label for="ctrlfechaRegistro">Fecha Final:</mat-label>
                            <input matInput type="date" formControlName="fechaFinVacaciones"  min="{{fecha}}" [min]="fecha" (change)="CambioFecha()">
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="flex-grow-1 small-mat-form">
                            <mat-label for="diasSolicitados">Días Solicitados:</mat-label>
                            <input matInput type="text" formControlName="diasSolicitados" readonly="true">
                        </mat-form-field>
                    </div>
        
                    <div class="mt-3">
                        <div class="alert alert-warning m-0" role="alert" *ngIf="banderaDias==0">
                            <i class="fa fa-exclamation-triangle negro mr-2" style="font-size: 18px;" aria-hidden="true"></i> Da clic para CALCULAR los días a solicitar.
                        </div>
                        <div class="alert alert-danger m-0" role="alert" *ngIf="banderaDias==1">
                            <i class="fa fa-window-close negro mr-2" aria-hidden="true"></i> Fechas anteriores o erroneas, Corrige para continuar.
                        </div>
                        <div class="alert alert-danger m-0" role="alert" *ngIf="banderaDias==2">
                            <i class="fa fa-window-close negro mr-2" aria-hidden="true"></i> No cuentas con los días para solicitar las fechas indicadas, corrige para continuar.
                        </div>
                        <div class="alert alert-success m-0" role="alert" *ngIf="banderaDias==3">
                            <i class="fa fa-check-square verde mr-2" aria-hidden="true"></i> Fechas correctas, puedes continuar con tu solicitud.
                        </div>
                        <div class="alert alert-success m-0" role="alert" *ngIf="banderaDias==4">
                            <i class="fa fa-check-square verde mr-2" aria-hidden="true"></i> Enviando Solicitud...
                        </div>
                    </div>
                    
                </form>          

            </div>
            <div class="modal-footer">
                <button type="button" class="btn" mat-raised-button color="primary" style="box-shadow: none;" [disabled]="vacacionesForm.invalid" (click)="calcularVacaciones($event)">Calcular</button>
        <button type="submit" class="btn btn-success" [disabled]="vacacionesForm.invalid || ctrlDiasSolicitados==0 || banderaDias!=3" (click)="saveVacaciones($event)" data-bs-dismiss="modal">Guardar</button> 
                        
            </div>
        </div>
    </div>
</div>