<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="px-0 ">
                <ol class="breadcrumb d-flex align-items-center mb-0">
                    <li class="breadcrumb-item"><a (click)="fnRedirige()">{{strNombre_ruta}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Detalle Contacto</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="list-group col-12 col-md-3 mx-auto">
            <div class="card h-100">
                <div class="card-header fw-bold">
                    Información General
                </div>
                <div class="card-body bg-white">
                    <div class="row">
                        <ol class="list-unstyled">
                            <li class="d-flex flex-column mb-3 text-center">
                                <img src="{{(objContacto.strUrl_Logo == '' || !objContacto.strUrl_Logo ) ? 'assets/detoportunidad/noDisponible.jpg': 'https://www.ecodeli.com/'+objContacto.strUrl_Logo }}" 
                                alt="Logotipo del Lead" width="180px" style="object-fit: cover; border-radius: 15px;" class="m-auto">
                                
                                    <label class="fs-4 mb-2">{{objContacto.strContacto_Nombre}}</label>
                                <span class="m-auto"><span class="badge text-bg-success">cliente</span>| <span
                                        class="fw-bold">0 puntos</span></span>
                            </li>

                            <li class="d-flex mb-3">
                                <span class="me-auto">
                                    <strong class="text-primary">
                                        <i class="bi bi-clipboard mx-1"></i>
                                        Propietario:
                                    </strong>
                                </span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strNombreAgente}}</span>
                            </li>
                            <!-- <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-person-vcard mx-1"></i>Nombre: </span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strContacto_Nombre}}</span>
                            </li> -->
                            <li class="d-flex mb-3">
                                <span class="me-au">
                                    <i class="bi bi-buildings mx-1"></i>Cargo:
                                </span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strContacto_Puesto}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-building mx-1"></i>Empresa:</span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strNombreEmpresa}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-signal mx-1"></i>Estado:</span>
                                <span class="ms-auto text-dark fw-bold">{{objInfo.strContactoPuesto}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-envelope mx-1"></i>Email:</span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strContacto_Email}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-telephone mx-1"></i>Telefono:</span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strContacto_Telefono}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-record-circle mx-1"></i>Origen:</span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strContactoEmail}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-pass mx-1"></i>Tipo:</span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strTipo_Leads}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-browser-chrome mx-1"></i>Pagina Web:</span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strContactoEmail}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-clock-history mx-1"></i>Fecha de Creación:</span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strFechaCreacion}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-calendar3 mx-1"></i>Ultimo Contacto:</span>
                                <span class="ms-auto text-dark fw-bold">{{objContacto.strContactoEmail}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-person-lines-fill mx-1"></i>Descipción:</span>
                                <span class="ms-auto text-dark fw-bold">{{objInfo.strContactoEmail}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-bookmark-check mx-1"></i>Resumen:</span>
                                <span class="ms-auto text-dark fw-bold">{{objInfo.strContactoEmail}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i
                                        class="bi bi-list-columns-reverse mx-1"></i>Observaciones:</span>
                                <span class="ms-auto text-dark fw-bold">{{objInfo.strContactoEmail}}</span>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- Time line -->
        <div class="col-12 col-md-6">
            <div class="mt-3 mt-md-0 col-12 h-100  rounded bg-white shadow-sm px-0">
                <ul class="nav nav-pills mb-3 clsNavBg pt-2" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active custom-inactive fs13 " id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="true">Timeline</button>
                    </li>
                    <li class="nav-item " role="presentation">
                        <button class="nav-link custom-inactive fs13 " id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected="false" (click)="fnllenarArrayTareas()">Documentos</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link custom-inactive fs13" id="pills-contact-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                            aria-selected="false">Control de cambios</button>
                    </li>
                </ul>
                <div class="tab-content px-3" id="pills-tabContent">
                    <div class="tab-pane fade show active py-2" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-12 d-flex align-items-center ">
                                <span class="clsInact pe-2">Registrar</span>
                                <span class="pe-1">
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Correo');">
                                        <i class="bi bi-envelope pe-2"></i>
                                        Correo
                                    </button>
                                </span>
                                <span class="pe-1">
                                    <button class="btn d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Llamada');">
                                        <i class="bi bi-telephone-fill pe-2"></i>
                                        Llamada
                                    </button>
                                </span>
                                <span>
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Visita');">
                                        <span class="material-symbols-outlined fs13">
                                            handshake
                                        </span>
                                        Reunion
                                    </button>
                                </span>
                                <span class="ms-auto col-3 position-relative">
                                    <input type="text" class="form-control ps-4 fs12" placeholder="Filtrar Timeline"
                                        [(ngModel)]="searchInput">
                                    <i
                                        class="bi bi-funnel-fill position-absolute top-50 start-0 translate-middle-y ms-2"></i>
                                </span>
                            </div>
                        </div>
                        <div class="timelinescroll w-100 mt-4">
                            <div class="card" *ngIf="arrayOport.length == 0" style="background: white;">
                                <div class="card-header text-center">
                                    <span class="fw-bold">SIN DATOS</span>
                                </div>
                            </div>
                            <div class="col-12 d-flex"
                                *ngFor="let obj of arrayOport| filtersearch: searchInput: ['strTitulo', 'strNombre_timeline']; let ind = index;"
                                [ngClass]="{'mb-3':ind==(arrayOport.length - 1),'mt-3':ind == 0 }">
                                <div class="col-12 d-flex" data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                    (click)="fnmDLConAct(obj)"
                                    *ngIf="obj.strTipo=='VISITA'||obj.strTipo=='Visita'||obj.strTipo=='CORREO'||obj.strTipo=='Correo'||obj.strTipo=='LLAMADA'||obj.strTipo=='Llamada'||obj.strTipo=='TAREA_UPDATE'||obj.strTipo=='Update_Lead_Tarea'">
                                    <div class="col-2">
                                        <div class="d-flex justify-content-center">
                                            <span class=""
                                                [ngClass]="{'horizontal-line':ind==0,'horizontal-line2':ind>0}"></span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="border border-3 rounded-circle  d-flex justify-content-center "
                                                [ngClass]="{'clsbordeTarea':(obj.strTipo=='VISITA'||obj.strTipo=='Visita'||obj.strTipo=='CORREO'||obj.strTipo=='Correo'||obj.strTipo=='LLAMADA'||obj.strTipo=='Llamada'),'clsbordeUpdate_Lead_Tarea':obj.strTipo=='TAREA_UPDATE'||obj.strTipo=='Update_Lead_Tarea'}">
                                                <i class="bi bi-check-square px-2 py-1"
                                                    [ngClass]="{'clsIconTarea':(obj.strTipo=='VISITA'||obj.strTipo=='Visita'||obj.strTipo=='CORREO'||obj.strTipo=='Correo'||obj.strTipo=='LLAMADA'||obj.strTipo=='Llamada'),'clsIconUpdate_Lead_Tarea':obj.strTipo=='TAREA_UPDATE'||obj.strTipo=='Update_Lead_Tarea'}"></i>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="horizontal-line2"></span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-flex align-items-center">
                                        <span class="fs12 pe-2">
                                            {{obj.strFechaCreacion}}
                                        </span>
                                    </div>
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="ps-3">
                                            <div class="col-12 fs13 fw-bold">
                                                <span> <i class="bi "
                                                        [ngClass]="{' bi-exclamation-triangle text-warning ':obj.strEstatus =='PENDIENTE',' bi-check-circle text-success':obj.strEstatus !='PENDIENTE'}"></i>
                                                    {{obj.strTitulo}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="clsInact fs12 me-2">{{obj.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs12" *ngIf="obj.strTipo!=='Oportunidad'">Fecha:
                                                    {{obj.strFechaCreacion}}</span>
                                                <span class="fs12 me-2" *ngIf="obj.strTipo=='Oportunidad'">Importe:
                                                    {{obj.dblImporte|currency}}</span>
                                                <span class="fs12" *ngIf="obj.strTipo=='Oportunidad'">Fecha
                                                    Cierre: {{obj.strFechaCierre_Estimada}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex"
                                    *ngIf=" !['VISITA','Visita', 'CORREO','Correo', 'LLAMADA','Llamada', 'TAREA_UPDATE','Update_Lead_Tarea'].includes(obj.strTipo) ">
                                    <div class="col-2">
                                        <div class="d-flex justify-content-center">
                                            <span class=""
                                                [ngClass]="{'horizontal-line':ind==0,'horizontal-line2':ind>0}"></span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="border border-3 rounded-circle  d-flex justify-content-center "
                                                [ngClass]="{'clsbordeOport':obj.strTipo=='Oportunidad','clsbordeFamilia':obj.strTipo=='Familia','clsbordeOportunidad_Tarea':obj.strTipo=='Oportunidad_Tarea'}">
                                                <i class="bi px-2 py-1"
                                                    [ngClass]="{'bi-cash clsIconOport': obj.strTipo =='Oportunidad','clsIconFamilia bi-people-fill ':obj.strTipo=='Familia','clsIconOportunidad_Tarea bi-cash-coin':obj.strTipo=='Oportunidad_Tarea'}"></i>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="horizontal-line2"></span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-flex align-items-center">
                                        <span class="fs12 pe-2">
                                            {{obj.strFechaCreacion}}
                                        </span>
                                    </div>
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="ps-3">
                                            <div class="col-12 fs13 fw-bold">
                                                <span><i class="bi bi-lightbulb clsIconOport "></i>
                                                    {{obj.strTitulo}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="clsInact fs12 me-2">{{obj.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs12" *ngIf="obj.strTipo!=='Oportunidad'">Fecha:
                                                    {{obj.strFechaCreacion}}</span>
                                                <span class="fs12 me-2" *ngIf="obj.strTipo=='Oportunidad'">Importe:
                                                    {{obj.dblImporte|currency}}</span>
                                                <span class="fs12" *ngIf="obj.strTipo=='Oportunidad'">Fecha
                                                    Cierre: {{obj.strFechaCierre_Estimada}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade timelinescroll" id="pills-profile" role="tabpanel"
                        aria-labelledby="pills-profile-tab">
                        <div class="row justify-content-center w-100 pb-4">
                            <div class="col-10 col-md-6 d-flex justify-content-center"
                                *ngFor="let item of arrayActividades">
                                <div class="col-11 card mt-2" data-bs-toggle="modal"
                                    data-bs-target="#MConcluirActividades" (click)="fnmDLConAct(item)">
                                    <div class="card-header p-1 fs12">
                                        <div class="row">
                                            <div class="col">
                                                {{item?.strTitulo}}
                                            </div>
                                            <div class="col text-end">
                                                <i matTooltip="{{item.strEstatus}}" class="fs13 fw-bold bi "
                                                    [ngClass]="{'bi-check2-circle text-success':item.strEstatus == 'CONCLUIDO', 'bi-info-circle text-warning':item.strEstatus == 'PENDIENTE'}"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col fs10 d-flex flex-column">
                                                <i matTooltip="{{item.strTipo}}" class="fs13 fw-bold bi "
                                                    [ngClass]="{'text-success':item.strEstatus == 'CONCLUIDO', 'text-warning':item.strEstatus == 'PENDIENTE','bi-telephone':item.strTipo == 'Llamada',' bi-person-walking ':item.strTipo == 'Visita','bi-envelope':item.strTipo == 'Correo' }"></i>
                                                <span class="">{{item?.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col d-flex flex-column fs10">
                                                <span class="ms-auto">Fecha:</span>
                                                <span class="ms-auto">{{transform(item.strFechaCreacion)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    </div>
                </div>
            </div>
        </div>
        <!-- Oportunidades -->
        <div class="col-12 col-md-3">
            <div class="col-12 d-flex align-items-center justify-content-between mb-3">
                <span class="clsInact fw-bold">SUEÑOS</span> <button
                    class="d-flex align-items-center btn btn-primary py-1 px-2 fw-bold" data-bs-toggle="modal"
                    data-bs-target="#nuevaOportunidad" (click)="openModal_oportunidad()"><i class="bi bi-plus"></i>
                    <span class="fs11">Agregar</span></button>
            </div>
            <div class="card" *ngIf="arrayOportunidades.length == 0" style="background: white;">
                <div class="card-header text-center">
                    <span class="fw-bold">SIN DATOS</span>
                </div>
            </div>
            <!--    (click)="enviarDetalleOporturnidad(item.strEstatus)"  -->
            <div class="card mb-2 shadow-sm cursor" *ngFor="let item of arrayOportunidades; let i = index"
                (click)="enviarDetalleOporturnidad(item)">
                <div class="card-header">
                    <span class="fw-bold fs13">{{item.strTitulo}}</span>
                </div>
                <div class="card-body fs13">
                    <div class="row">
                        <div class="col-12 d-flex">
                            <span class="badge bg-primary ms-auto">{{item.strEstatus}}</span>
                        </div>
                        <div class="col-4 d-flex align-items-center">
                            <div class="circle p-2">
                                <span class="text">{{item.strABNombre}}</span><!-- *ngIf="item.strAgente == ''" -->
                            </div>
                        </div>
                        <div class="col-8 text-center row d-flex">
                            <h5 class="fw-bold text-secondary m-auto" style="white-space: initial; font-size: 11px">
                                {{item.strNombreAgente}}
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-12 d-flex fs11">
                            <div class="me-auto">
                                <span>{{item.dblImporte | currency:'':'symbol':'1.2-2' }}</span>
                            </div>
                            <div class="ms-auto">
                                <span><i class="fas fa-calendar-alt"></i>
                                    {{(item.strFechaCierre_Estimada).split(' ')[0]}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal actividades -->
<div class="modal fade" id="MConcluirActividades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <span
                    *ngIf="objAct?.strEstatus == 'CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'">
                </span>
                <h1 class="modal-title fs-5 mb-0" id="exampleModalLabel">
                    Concluye Actividad:
                    <span class="fw-bold">{{objAct?.strTitulo}}</span>
                    para poder avanzar
                </h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-11" >
                        <!-- *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'" -->
                        <div class="row">
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column" >
                                    <span class="titIcon"><i class="bi bi-person-fill-gear clsIconModal "></i> Responsable:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strNombreAgente?objAct.strNombreAgente:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3" >
                                    <span class="titIcon"><i class="bi bi-person-circle clsIconModal"></i> Asignado por: </span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.nombreCrea?objAct.nombreCrea:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora Inicio:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraInicio?objAct.strHoraInicio:'00'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora Fin:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraFin?objAct.strHoraFin:'00'}}</span>
                                </div>
                            </div>
                           
                            <div class="col-12 mt-3">
                                <div class="col-12 d-flex flex-column" >
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i> ASUNTO</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm" [innerHTML]="objAct&&objAct.strOpcion?objAct.strOpcion:'--Seleccione '+objAct?.strTipo+'--'"></span>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3" *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="titIcon"><i class="bi bi-chat-dots-fill clsIconModal"></i>Obervaciones</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm"
                                    [innerHTML]="objAct?.strObservaciones"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-2" *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="float-end titIcon"><i class="clsIconModal bi " [ngClass]="{'bi-hand-thumbs-up-fill':objAct?.strResultado == '1','bi-hand-thumbs-down-fill':objAct?.strResultado == '2','bi-info-circle-fill':objAct?.strResultado == '0'}"></i> Resultado</span>
                                <span *ngIf="objAct?.strResultado == '1'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">EXITOSO</span>
                                <span *ngIf="objAct?.strResultado == '2'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN EXITO</span>
                                    <span *ngIf="objAct?.strResultado == '0'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN RESPUESTA</span>
                            </div>
                           <!--  <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                <span>Titulo</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strTitulo}}</span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                <span class="float-end">Resultado</span>
                                <span *ngIf="objDetActividad.strResultado == '1'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">EXITOSO</span>
                                <span *ngIf="objDetActividad.strResultado == '2'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN EXITO</span>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3">
                                <span>Obervaciones</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm"
                                    [innerHTML]="objDetActividad?.strObservaciones"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                <span>Hora Inicio</span>
                                <span
                                    class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strHoraInicio}}</span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                <span>Hora Fin</span>
                                <span
                                    class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strHoraFin}}</span>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12 h-100 mt-3 mb-2" [ngClass]="{'visually-hidden':objAct?.strEstatus == 'CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'}">
                      
                        <div class="form-group h-100">
                            <textarea [(ngModel)]="contenido" class="custom-summernote h-100 summernote" id="summernote"
                                #summernote></textarea>
                        </div>
                    </div>
                    <div class="col-12 d-flex mt-2" *ngIf="objAct?.strEstatus=='PENDIENTE' ">
                        <select name="accioness" id="acciones" class="form-select m-auto" [(ngModel)]="strResultado"
                            [disabled]="objAct?.strEstatus=='CONCLUIDO'">
                            <option value="">
                                --Selecciona
                                una opción--</option>
                            <option value="EXITOSO">
                                EXITOSO</option>
                            <option value="SIN EXITO">
                                SIN EXITO
                            </option>
                        </select>
                    </div>
                    <div class="col-12 d-flex mt-3">
                        <button type="button" class="btn btncerrarModal" data-bs-dismiss="modal"
                            #btnCerrarAct>{{objAct?.strEstatus=='PENDIENTE'?'Cancelar':'Cerrar'}} </button>
                        <button [disabled]="strResultado == '' || contenido == ''"
                            class="btn btn-primary text-white ms-auto mt-auto" (click)="fnConcluirActividad(objAct)"
                            *ngIf="objAct?.strEstatus=='PENDIENTE'">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal RegistrarActividad -->
<div class="modal fade" id="mdlRegistrar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Registrar {{objModal &&
                    objModal.titulo?objModal.titulo:""}}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-5">
                        <div class="col-12">
                            Asunto
                        </div>
                        <div class="col-12">
                            <input type="text" placeholder="Agregue asunto" class="form-control"
                                [(ngModel)]="mdlNombre">
                        </div>
                    </div>
                    <div class="col-2">
                    </div>
                    <div class="col-5">
                        <div class="col-12">
                            Fecha
                        </div>
                        <div class="col-12">
                            <input type="date" class="form-control" [(ngModel)]="strfecha">
                        </div>
                    </div>

                </div>
                <div class="row my-3">
                    <div class="col-12 col-md-5">
                        <div class="col-12">
                            Hora Inicio
                        </div>
                        <div class="col-12 d-flex">
                            <select class="form-select w-50" *ngIf="time1=='am'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraInicio">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>
                            <select class="form-select w-50" *ngIf="time1=='pm'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraInicio">
                                <option *ngFor="let x of arraypm">
                                    {{x.hora}}
                                </option>
                            </select>
                            <select class="form-select w-50" aria-label="Default select example" [(ngModel)]="time1"
                                (change)="onTimeChange($event,'inicio')">
                                <option *ngFor="let x of arrayTime">
                                    {{x.time}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-md-2">
                    </div>
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <div class="col-12">
                            Hora Fin
                        </div>
                        <div class="col-12 d-flex">
                            <select class="form-select w-50" *ngIf="time2=='am'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraFin">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>
                            <select class="form-select w-50" *ngIf="time2=='pm'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraFin">
                                <option *ngFor="let x of arraypm">
                                    {{x.hora}}
                                </option>
                            </select>
                            <select class="form-select w-50" aria-label="Default select example" [(ngModel)]="time2"
                                (change)="onTimeChange($event,'fin')">
                                <option *ngFor="let x of arrayTime">
                                    {{x.time}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 mt-3 mb-2 d-block">
                        <span>Agente</span> <br>
                        <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                            [(ngModel)]="strAgente">
                            <option value="undefined">--Selecciona un Agente--</option>
                            <option *ngFor="let item of arrayAgentes; let i = index" value="{{item.strAgente}}">
                                {{item.strNombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Comentario
                    </div>
                    <div class="col-12">
                        <textarea class="form-control" name="" id="" [(ngModel)]="mdlComentarios"></textarea>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btncerrarModal" data-bs-dismiss="modal" #btnCerrar>Cancelar</button>
                <button type="button" class="btn bgtitModal" (click)="fnEnviarActividad()">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal nueva Oportunidad -->
<div class="modal fade" id="nuevaOportunidad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Nuevo Sueño</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 mt-2 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Nombre *:</label>
                            <input type="text" class="form-control" [(ngModel)]="strNombreOportunidad">
                        </div>
                    </div>

                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Monto *:</label>
                            <input type="number" class="form-control" [(ngModel)]="dblMonto">
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Fecha Estimada Cierre *:</label>
                            <input type="date" class="form-control" [(ngModel)]="strFechaCierre">
                        </div>
                    </div>
                    <div class="col-12 mb-2 mb-1">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Lineas *:</label>
                            <select class="form-select" [(ngModel)]="strLinea">
                                <option value="">-Selecciona una opcion--</option>
                                <option [value]="linea.strLinea" *ngFor="let linea of arrayLineas2">
                                    {{linea.strLinea}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 mb-3">
                        <mat-form-field class="example-full-width w-100">
                            <span matPrefix> </span>
                            <input type="text" class="px-3" matInput placeholder="Responsable"
                                (keyup)="fnBuscarCte(strResponsable);" name="search"
                                [(ngModel)]="strResponsable"><!-- [(ngModel)]="" -->

                            <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                <mat-icon>search</mat-icon>
                            </button>
                            <mat-hint class="fw-bold">{{nombreResponsable}}</mat-hint>
                        </mat-form-field>
                        <ul *ngIf="arrayAsigAgentes.length > 0" class="list-group mt-1 overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayAsigAgentes"
                                (click)="fnselecCliente(age)">
                                {{age.strNombreAgente}}
                            </li>
                        </ul>
                    </div>

                    <div class="col-12">
                        <mat-form-field class="example-full-width w-100">
                            <span matPrefix> </span>
                            <input type="text" class="px-3" matInput placeholder="Buscar Articulo"
                                (keyup)="funConsultarArt(strBuscarArticulo);" name="search"
                                [(ngModel)]="strBuscarArticulo">
                            <button matSuffix class="btn btn-darkky d-flex align-items-center "><!-- funArticulos -->
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-12 mb-2">
                        <ul *ngIf="arrayArts.length > 0" class="list-group mt-1 overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayArts"
                                (click)="fnselecArticulo(age)">
                                ({{age.strArticulo}}) {{age.strDescripcion}}
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 table-responsive mt-4">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="arrayArticuloAgregados.length == 0">
                                    <td colspan="4" class="text-center">
                                        <span>SIN DATOS</span>
                                    </td>
                                </tr>
                                <tr *ngFor="let art of arrayArticuloAgregados; let i = index">
                                    <td class="text-center fs12 fw-bold">
                                        {{ i + 1 }}
                                    </td>
                                    <td class="fs12">
                                        ({{ art.strArticulo }}) {{ art.strDescripcion }}
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-danger fs-6" (click)="fnEiminarProductos(i)"><i
                                                class="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="button" class="btn btn-primary" (click)="fnInsertOportunidad()"
                    data-bs-dismiss="modal">Guardar</button>
            </div>
        </div>
    </div>
</div>