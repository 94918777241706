<div class="container">
    <div class="row">
        <div class="col-12 mt-3">
            <h1 class="fw-bold nunito text-center">PEDIDOS <span class="titulo">PENDIENTES</span></h1>
        </div>
    </div>
    <div class="row justify-content-center mt-2 d-none d-md-flex" *ngIf="boolAgente">
        <div class="col-12 mb-2">
            <div class="row bg-white bordertop borderbott shadow pb-2 justify-content-center">
                <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
                        <mat-select [(ngModel)]="strGerente" class="">
                            <mat-option value=""> --Todos --</mat-option>
                            <mat-option *ngFor="let x of arrayGerentes"
                                [value]="x.strGerente">{{x.strNombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                    [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                        <mat-select [(ngModel)]="strSucursal" class="">
                            <mat-option value=""> --Todas --</mat-option>
                            <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente"
                                [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                    [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
                        <mat-select [(ngModel)]="strAgente" class="">
                            <mat-option value=""> --Todos --</mat-option>
                            <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
                                [value]="x.strAgente">{{x.strNombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
          
                <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
                    [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }">
                    <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true"
                        (mouseleave)="isHoveredButton = false" (click)="fnGet_All()"> <!-- -->
                        <i class="bi  p-1 fs-4"
                            [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 justify-content-center" *ngIf="spiner">
        <div class="col-12 d-flex justify-content-center" >
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div class="row mt-3 justify-content-center"  *ngIf="!spiner && listaPedidos.length == 0 ">
        <div class="col-12 ">
            <div class="alert alert-danger text-center" role="alert">
                A simple danger alert—check it out!
              </div>
        </div>
    </div>
    

    <div class="row mt-4" [hidden]="spiner || listaPedidos.length == 0 ">
        <div class="col-12 mb-4 shadow bordertop  borderbott px-0">
            <div class="col-12 bgtopTable py-3  bordertop d-flex justify-content-around ">
                <mat-form-field appearance="outline" class="bg-white text-dark rounded ">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilterTareasCte($event)" placeholder="ID,Tarea,etc..." #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="bg-white rounded text-dark">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Mercancia</mat-label>
                    <mat-select [(ngModel)]="strMercancia" class="" (selectionChange)="fnFiltrarMercancia()">
                        <mat-option value=''>Todos</mat-option>
                        <mat-option value='0'>Completa</mat-option>
                        <mat-option value='1'>Incompletos</mat-option>
                        <mat-option value='-1'>Sin Estatus</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mb-0 table-responsive mb-0">
                <div class="mat-elevation-z8 mb-0  shadow rounded">
                    <table mat-table [dataSource]="datosTareasCte" class="table table-hover table-bordered"  matSort #sortTareasCte="matSort">
                        <!-- cliente Column -->
                        <ng-container matColumnDef="strNombre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 text-center align-middle">CLIENTE</th>
                            <td mat-cell *matCellDef="let row" class="px-2  text-start align-middle">
                                <button class="btn btn-link fs13" (click)="desglocePedido(row)">
                                    <span class="fw-bold">({{row.strCliente}})</span>  {{row.strNombre}} 
                                </button>  
                            </td>
                        </ng-container>

                        <!-- pedido Column -->
                        <ng-container matColumnDef="strMovID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 align-middle"> IDPEDIDO </th>
                            <td mat-cell *matCellDef="let row" class="mostDet fw-bold ps-0 fs13 text-start align-middle"> 
                             <span class="ps-2">{{row.strMovID}}</span>   
                            </td>
                        </ng-container>

                        <!-- estatus Column -->
                        <ng-container matColumnDef="strEstatus">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 align-middle"> ESTATUS </th>
                            <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle">
                                <i *ngIf="row.strEstatus == 'PENDIENTE'" class="fa fa-bolt fa-2x text-warning"
                                data-toggle="tooltip" data-placement="top" title="PENDIENTE"></i>
                                <i *ngIf="row.strEstatus == 'SINAFECTAR'" class="fa fa-times-circle fa-1x text-info"
                                data-toggle="tooltip" data-placement="top" title="SIN AFECTAR"></i>
                               
                                <div *ngIf="row.strSituacion !=''" class="p-3 mb-2 bg-primary-subtle text-primary-emphasis"> {{row.strSituacion  }}</div>
                            </td>
                        </ng-container>

                        <!-- SITUACIÓN Column -->
                        <ng-container matColumnDef="strSituacion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  class="fs13 align-middle"> SITUACIÓN </th>
                            <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-start align-middle">
                                {{row.strSituacion}}
                            </td>
                        </ng-container>

                        <!-- fecharequerida Column -->
                        <ng-container matColumnDef="strFechaRequerida">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 text-nowrap align-middle"> FECHA REQUERIDA </th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                {{row.strFechaRequerida}}
                                <span class="fas fa-sync-alt pointer" style="font-size:10px; color:#7C7C7C;"
                                data-bs-toggle="modal" data-bs-target="#exampleModal"   (click)="CambiarFR(row.strID)"></span>
                            </td>
                        </ng-container>

                        <!-- importe Column -->
                        <ng-container matColumnDef="dblImporte">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle">IMPORTE</th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                {{ row.dblImporte | currency}}
                            </td>
                        </ng-container>

                        <!-- semaforo Column -->
                        <ng-container matColumnDef="strDiasDif">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle">SEMAFORO</th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                <span class="badge text-bg-danger" *ngIf="row.strDiasDif < 0" id='step5'> {{row.strDiasDif}}
                                    Dias</span>
                                <span class="badge text-bg-primary" *ngIf="row.strDiasDif >= 0  ">{{row.strDiasDif}}
                                    Dias</span>
                            </td>
                        </ng-container>

                        <!-- cancelar Column -->
                        <ng-container matColumnDef="cancelar">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle"> CANCELAR </th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                <a (click)="cancelarPedido(row)" *ngIf="row.strMovID">
                                    <span class="fa-stack fa-lg" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Cancelar" id='step6'>
                                        <i class="fa fa-cart-plus text-muted fa-stack-1x"></i>
                                        <i class="fa fa-ban fa-stack-2x fa-spin text-danger"></i>
                                    </span>
                                </a>
                                <a (click)="eliminarPedido(row)" *ngIf="!row.strMovID" data-toggle="tooltip"
                                    data-placement="top" title="Eliminar">
                                    <i class="fa fa-trash-o fa-2x text-danger"></i>
                                </a>
                            </td>
                        </ng-container>

                        <!-- mercancia Column -->
                        <ng-container matColumnDef="intEstatusPedido">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle"> MERCANCIA </th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                <span class="badge text-bg-primary" *ngIf="row.intEstatusPedido==0"
                                    id='step5'>COMPLETO</span>
                                <span class="badge text-bg-info" *ngIf="row.intEstatusPedido==-1">SIN ESTATUS</span>
                                <span class="badge text-bg-warning text-white" *ngIf="row.intEstatusPedido>0">
                                    INCOMPLETO
                                </span>
                            </td>
                        </ng-container>
            
                        <tr mat-header-row class="rounded turquesa sticky-top" *matHeaderRowDef="columnsTareasCte"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsTareasCte;" data-bs-toggle="modal" data-bs-target="#MConcluirActividades" ></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="6">No se encontraron datos de: "{{input.value}}"</td>
                        </tr>
                    </table>
                </div>
            </div>
            <mat-paginator class="paginator borderbott" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorTareasCte></mat-paginator>
        </div>
    </div>




<!--     <div class="row mt-4 justify-content-center" *ngIf="!spiner && listaPedidos.length >0">
        <div class="col-12">



            <div class="table-responsive mb-0 shadow bordertop borderbott">
                <table class="table table-hover tooltip-demo table-bordered">
                    <thead class="encabezado">
                        <tr class="turquesa text-center sticky-top">
                            <th class="text-center ">Cliente
                                <span class='glyphicon glyphicon-menu-down' *ngIf="propertyName === 'strCliente'"></span>
                            </th>
                            <th>Nombre
                                <span class='glyphicon glyphicon-menu-down' *ngIf="propertyName === 'strNombre'"></span>
                            </th>
                            <th class="text-center">IDPEDIDO
                                <span class='glyphicon glyphicon-menu-down' *ngIf="propertyName === 'strMovID'"></span>
                            </th>
                            <th class="text-center" (click)="sortBy('-strEstatus')">Estatus
                                <span class='glyphicon glyphicon-menu-down' *ngIf="propertyName === 'strEstatus'"></span>
                            </th>
                            <th class="text-center" (click)="sortBy('-strEstatus')">Situación
                                <span class='glyphicon glyphicon-menu-down'
                                    *ngIf="propertyName === 'strSituacion'"></span>
                            </th>
                            <th class="text-center" (click)="sortBy('strFechaRequerida')">FechaRequerida
                                <span class='glyphicon glyphicon-menu-down'
                                    *ngIf="propertyName === 'strFechaRequerida'"></span>
                            </th>
                            <th class="text-center" (click)="sortBy('dblImporte')">Importe
                                <span class='glyphicon glyphicon-menu-down'
                                    *ngIf="propertyName === 'dblImporte'"></span>
                            </th>
                            <th class="text-center" (click)="sortBy('-strDiasDif')">Semaforo
                                <span class='glyphicon glyphicon-menu-down'
                                    *ngIf="propertyName === 'strDiasDif'"></span>
                            </th>
                            <th class="text-center" (click)="sortBy('-strDiasDif')">Cancelar </th>
                            <th class="text-center" (click)="sortBy('-intEstatusPedido')">Mercancia
                                <span class='glyphicon glyphicon-menu-down'
                                    *ngIf="propertyName === 'intEstatusPedido'"></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="loader">
                            <td colspan="11">
                                <div class="col-12 bg-white">
                                    <mat-spinner class="mx-auto"></mat-spinner>
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let p of listaPedidos">
                            <td class="bordeTable text-center font-bold">{{p.strCliente}}</td>
                            <td class="bordeTable" class="cursor">
                                <a (click)="desglocePedido(p)" id='step4'>{{p.strNombre}}</a>
                            </td>
                            <td class="bordeTable text-center">{{p.strMovID}}</td>
                            <td class="bordeTable text-center">
                                <i *ngIf="p.strEstatus == 'PENDIENTE'" class="fa fa-bolt fa-2x text-warning"
                                    data-toggle="tooltip" data-placement="top" title="PENDIENTE"></i>
                                <i *ngIf="p.strEstatus == 'SINAFECTAR'" class="fa fa-times-circle fa-1x text-info"
                                    data-toggle="tooltip" data-placement="top" title="SIN AFECTAR"></i>
                            </td>
                            <td class="bordeTable text-center">{{p.strSituacion}}</td>
                            <td class="bordeTable text-center">
                                {{p.strFechaRequerida}}

                                <span class="fas fa-sync-alt" style="font-size:10px; color:#7C7C7C;"
                                data-bs-toggle="modal" data-bs-target="#exampleModal"   (click)="CambiarFR(p.strID)"></span>
                            </td>
                            <td class="bordeTable text-center">{{ p.dblImporte | currency}}</td>
                            <td class="bordeTable text-center">
                                <span class="badge text-bg-danger" *ngIf="p.strDiasDif < 0" id='step5'> {{p.strDiasDif}}
                                    Dias</span>
                                <span class="badge text-bg-primary" *ngIf="p.strDiasDif >= 0  ">{{p.strDiasDif}}
                                    Dias</span>
                            </td>
                            <td class="text-center bordeTable">
                                <a (click)="cancelarPedido(p)" *ngIf="p.strMovID">
                                    <span class="fa-stack fa-lg" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Cancelar" id='step6'>
                                        <i class="fa fa-cart-plus text-muted fa-stack-1x"></i>
                                        <i class="fa fa-ban fa-stack-2x fa-spin text-danger"></i>
                                    </span>
                                </a>
                                <a (click)="eliminarPedido(p)" *ngIf="!p.strMovID" data-toggle="tooltip"
                                    data-placement="top" title="Eliminar">
                                    <i class="fa fa-trash-o fa-2x text-danger"></i>
                                </a>
                            </td>
                            <td class="bordeTable text-center">
                                <span class="badge text-bg-primary" *ngIf="p.intEstatusPedido==0"
                                    id='step5'>COMPLETO</span>
                                <span class="badge text-bg-info" *ngIf="p.intEstatusPedido==-1">SIN ESTATUS</span>
                                <span class="badge text-bg-warning text-white" *ngIf="p.intEstatusPedido>0">
                                    INCOMPLETO
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class=" sticky-bottom">
                        <tr class="grisUnidad ">
                            <td colspan="6">Total de Movimientos: {{totalMov}}</td>
                            <td class="text-center">{{totalImporte | currency}}</td>
                            <td colspan="3"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div> -->
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="exampleModalLabel">Cambio de fecha requerida</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-10">
                <input type="date" class="form-control" [(ngModel)]="aFechaRequeridaS">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrar>Cerrar</button>
          <button type="button" class="btn btn-primary ms-auto" (click)="CambiarFechaRequerida()">Cambiar</button>
        </div>
      </div>
    </div>
  </div>