<div class="container">
    <!-- Primer Row: Beneficios rewards-container-->
   
    <div class="row mt-3 py-4 shadow bg-white justify-content-evenly">
      <h2 class="fs-4 text-center fw-bold">DESCUBRE LOS BENEFICIOS DE PERTENECER A LA FAMILIA ECODELI</h2>
       
        <div class="col-12 col-lg-4 text-center benefit shadow-sm card2 rounded pt-3  mt-3 mt-lg-0">
          <i class="fas fa-tags"></i>
          <h3>Descuentos por Monto de Compra mensuales</h3>
          <p> A mayor monto de compra, mayor descuento exclusivo. Disfruta de beneficios progresivos y ahorra más con cada compra.</p>
        </div>
        <div class=" col-12 col-lg-4 text-center benefit shadow-sm card3 rounded pt-3  mt-3 mt-lg-0">
         <!-- <i class="fas fa-gift"></i>-->

          <i class="fas fa-wallet"></i>
          <h3>Monedero Electrónico</h3>
          <p>Por cada compra que realices, acumulas un % de tu total en tu monedero electrónico para futuras compras</p>
        </div>
 
    </div>
    <div class="row justify-content-center mt-3">
        <div class="col-md-8">
          <div class="card nivel-recompensa">
            <div class="card-body text-center">
              <h2 class="titulo-nivel">🎉 ¡Felicidades! 🎉</h2>
              <p class="texto-nivel">
                Has alcanzado el <span class="nivel-destacado">Nivel {{ descuentoAplicable?.strNombre }}</span> 
                gracias a tus compras del mes anterior
                <!--, con un total de  
                <span class="monto-destacado">{{ montoCompra | currency }} </span>MXN.-->
              </p>
              <p class="mensaje-beneficios">
                Sigue comprando y accede a mayores beneficios y descuentos exclusivos.
              </p>
            </div>
          </div>
        </div>
     
      
    </div>
  
    <!-- Segundo Row: Mientras más compras más ganas -->
    <!-- <div class="row mt-4 pt-3 pb-4 mb-4">
      
      <div class="row">
        
          <div class="col-2">
            <h3 class="text-center fw-bold mb-0">Nivel</h3>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <h3 class="text-center fw-bold mb-0">Minimo de compra</h3>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <h3 class="text-center fw-bold mb-0">Descuento</h3>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <h3 class="text-center fw-bold mb-0">% Monedero</h3>
          </div>
        <div class="col"></div>
      </div>
      <div class="row mt-3" *ngFor="let des of arrayDescuentos; let ind = index" >
       
        <div class="col-2">
          <div class="button-container">
            <div class="button-with-arrow">
              <button class="level-btn"   [ngClass]="{
                'nPlata': des.strNombre === 'PLATA',
                'nOro': des.strNombre === 'ORO',
                'nBronce': des.strNombre === 'BRONCE',
                'nPlatino': des.strNombre === 'PLATINO',
                'nDiamante': des.strNombre === 'DIAMANTE',
                'nvip': des.strNombre === 'BLACK',
                'nEsmeral': des.strNombre === 'ESMERALDA',
                'nivel-actual': des.strNombre === strNivelActual  }" [class.nivel-actual]="des.strNombre=== strNivelActual"><span *ngIf="des.strNombre=== strNivelActual">🏅</span>
                Nivel {{des.strNombre}} </button>
                
            </div>
      
          </div>
        </div>
        <div class="col-1 d-flex align-items-center">
          <div class="arrow">→</div>
        </div>
        <div class="col-2">
          <div class="button-container">
            <div class="button-with-arrow d-flex">
              <button class="amount-btn ms-auto"   [ngClass]="{
                'nPlata': des.strNombre === 'PLATA',
                'nOro': des.strNombre === 'ORO',
                'nBronce': des.strNombre === 'BRONCE',
                'nPlatino': des.strNombre === 'PLATINO',
                'nDiamante': des.strNombre === 'DIAMANTE',
                'nvip': des.strNombre === 'BLACK',
                'nEsmeral': des.strNombre === 'ESMERALDA',
                'nivel-actual': des.strNombre === strNivelActual
              }"
               [class.nivel-actual]="des.strNombre=== strNivelActual">{{des.dblMontoMin_Cupon|currency}}</button>
              
            </div>
       
          </div>
        </div>
        <div class="col-1 d-flex align-items-center">
          <div class="arrow">→</div>
        </div>
        <div class="col-2">
          <div class="button-container">
         

            <button class="discount-btn"
                    [ngClass]="{
                      'nPlata': des.strNombre === 'PLATA',
                      'nOro': des.strNombre === 'ORO',
                      'nBronce': des.strNombre === 'BRONCE',
                      'nPlatino': des.strNombre === 'PLATINO',
                      'nDiamante': des.strNombre === 'DIAMANTE',
                      'nvip': des.strNombre === 'BLACK',
                            'nEsmeral': des.strNombre === 'ESMERALDA',
                      'nivel-actual': des.strNombre === strNivelActual
                    }"  [class.nivel-actual]="des.strNombre=== strNivelActual">
              {{ (des.dblDescuentoCantidad * 100) | number:'1.0-0' }} %
            </button>

         
          </div>
        </div>
        <div class="col-1 d-flex align-items-center">
          <div class="arrow"> <i class="bi bi-arrows-expand-vertical"></i></div>
        </div>
       
        <div class="col-2">
          <div class="button-container">
             <button class="discount-btn"
                    [ngClass]="{
                      'nPlata': des.strNombre === 'PLATA',
                      'nOro': des.strNombre === 'ORO',
                      'nBronce': des.strNombre === 'BRONCE',
                      'nPlatino': des.strNombre === 'PLATINO',
                      'nDiamante': des.strNombre === 'DIAMANTE',
                      'nvip': des.strNombre === 'BLACK',
                            'nEsmeral': des.strNombre === 'ESMERALDA',
                      'nivel-actual': des.strNombre === strNivelActual
                    }"  [class.nivel-actual]="des.strNombre=== strNivelActual">
              {{ (des.dblValor_Cupon * 100) | number:'1.0-0' }} %
            </button>

         
          </div>
        </div>
        <div class="col"></div>
      </div>
    </div> -->


   

  
  </div>

  <div class="container-fluid">
    <div class="row mt-5">
      <div class="col-12">
        <h2 class="fs-3 txtEcoBlue text-uppercase">Mientras más compras <span class="txtEcoRed">más ganas</span> </h2>
      </div>
    </div>
    <div class="row d-none d-lg-block">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table " >
            <tbody class="bgbody">
              <tr>
                <td class="my-2 align-middle pb-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center w-100">
                    NIVELES
                  </h1>  
                </td>
                <td class="pb-0 border-0" [ngClass]="{'ps-0':ind==0}" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="col-12 d-flex px-0 bg-white border boder-secondary bortop">
                    <div class="badge1 my-2 mx-auto" [ngClass]="{
                      'lin-plata': des.strNombre === 'PLATA',
                      'lin-oro': des.strNombre === 'ORO',
                      'lin-bronce': des.strNombre === 'BRONCE',
                      'lin-platino': des.strNombre === 'PLATINO',
                      'lin-diamante': des.strNombre === 'DIAMANTE',
                      'lin-dark': des.strNombre === 'BLACK',
                      'lin-esmeralda': des.strNombre === 'ESMERALDA'
                    }">
                      <div class="inner-circle position-relative" [ngClass]="{
                        'bg-plata': des.strNombre === 'PLATA',
                        'bg-oro': des.strNombre === 'ORO',
                        'bg-bronce': des.strNombre === 'BRONCE',
                        'bg-platino': des.strNombre === 'PLATINO',
                        'bg-diamante': des.strNombre === 'DIAMANTE',
                        'bg-dark': des.strNombre === 'BLACK',
                        'bg-esmeralda': des.strNombre === 'ESMERALDA'
                      }">
                        <img src="assets/partner/svg/PLATA.svg" *ngIf="des.strNombre === 'PLATA'" alt="BLACK">
                        <img src="assets/partner/svg/ORO.svg" *ngIf="des.strNombre === 'ORO'" alt="BLACK">
                        <img src="assets/partner/svg/BRONCE.svg" *ngIf="des.strNombre === 'BRONCE'" alt="BLACK">
                        <img src="assets/partner/svg/PLATINO.svg" *ngIf="des.strNombre === 'PLATINO'" alt="BLACK">
                        <img src="assets/partner/svg/DIAMANTE.svg" *ngIf="des.strNombre === 'DIAMANTE'" alt="BLACK">
                        <img src="assets/partner/svg/BLACK.svg" *ngIf="des.strNombre === 'BLACK'" alt="BLACK">
                        <img src="assets/partner/svg/Esmeralda.svg" *ngIf="des.strNombre === 'ESMERALDA'" alt="BLACK">
                        <span class="text-white fwbold position-absolute bottom-0 start-50 translate-middle-x shadtxt">
                          {{des.strNombre}}
                         </span>
                      </div>
                      
                    </div>
            
                  </div>
                </td>
               
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue my-2 text-center w-100">Minimo de compra</h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==0}" class="text-center align-middle py-0 border-0" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                  <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center col-12 ">{{des.dblMontoMin_Cupon|currency}}</span>
                 </div>
                 
                </td>
                
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center">Descuento</h1>
                </td>
                <td [ngClass]="{'ps-0':ind==0}" *ngFor="let des of arrayDescuentos; let ind = index" class="border-0 text-center py-0">
                  <div class="w-100 h-100 bg-white d-flex align-items-center justify-content-center border py-3" style="min-height: 100%;">
                    <span  [ngClass]="{
                      'txt-plata': des.strNombre === 'PLATA',
                      'txt-oro': des.strNombre === 'ORO',
                      'txt-bronce': des.strNombre === 'BRONCE',
                      'txt-platino': des.strNombre === 'PLATINO',
                      'txt-diamante': des.strNombre === 'DIAMANTE',
                      'txt-black': des.strNombre === 'BLACK',
                      'txt-esmeralda': des.strNombre === 'ESMERALDA'
                    }" class="fs-5 fw-bold text-center  w-100"> {{ (des.dblDescuentoCantidad * 100) | number:'1.0-0' }} %</span>
                  
                  </div>
                </td>
                
              </tr>
              <tr>
                <td class="border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center py-0">
                    % Monedero
                  </h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==0}"*ngFor="let des of arrayDescuentos; let ind = index" class="py-0 border-0 align-middle text-center">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center w-100"> {{ (des.dblValor_Cupon * 100) | number:'1.0-0' }} %</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>



    <div class="row d-lg-none">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table " >
            <tbody class="bgbody mb-3">
              <tr>
                <td class="my-2 align-middle pb-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center w-100">
                    NIVELES
                  </h1>  
                </td>
                <td class="pb-0 border-0" [ngClass]="{'ps-0':ind==0, 'd-none':ind>1}" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="col-12 d-flex px-0 bg-white border boder-secondary bortop">
                    <div class="badge1 my-2 mx-auto" [ngClass]="{
                      'lin-plata': des.strNombre === 'PLATA',
                      'lin-oro': des.strNombre === 'ORO',
                      'lin-bronce': des.strNombre === 'BRONCE',
                      'lin-platino': des.strNombre === 'PLATINO',
                      'lin-diamante': des.strNombre === 'DIAMANTE',
                      'lin-dark': des.strNombre === 'BLACK',
                      'lin-esmeralda': des.strNombre === 'ESMERALDA'
                    }">
                      <div class="inner-circle position-relative" [ngClass]="{
                        'bg-plata': des.strNombre === 'PLATA',
                        'bg-oro': des.strNombre === 'ORO',
                        'bg-bronce': des.strNombre === 'BRONCE',
                        'bg-platino': des.strNombre === 'PLATINO',
                        'bg-diamante': des.strNombre === 'DIAMANTE',
                        'bg-dark': des.strNombre === 'BLACK',
                        'bg-esmeralda': des.strNombre === 'ESMERALDA'
                      }">
                        <img src="assets/partner/svg/PLATA.svg" *ngIf="des.strNombre === 'PLATA'" alt="BLACK">
                        <img src="assets/partner/svg/ORO.svg" *ngIf="des.strNombre === 'ORO'" alt="BLACK">
                        <img src="assets/partner/svg/BRONCE.svg" *ngIf="des.strNombre === 'BRONCE'" alt="BLACK">
                        <img src="assets/partner/svg/PLATINO.svg" *ngIf="des.strNombre === 'PLATINO'" alt="BLACK">
                        <img src="assets/partner/svg/DIAMANTE.svg" *ngIf="des.strNombre === 'DIAMANTE'" alt="BLACK">
                        <img src="assets/partner/svg/BLACK.svg" *ngIf="des.strNombre === 'BLACK'" alt="BLACK">
                        <img src="assets/partner/svg/Esmeralda.svg" *ngIf="des.strNombre === 'ESMERALDA'" alt="BLACK">
                        <span class="text-white fwbold position-absolute bottom-0 start-50 translate-middle-x shadtxt">
                          {{des.strNombre}}
                         </span>
                      </div>
                      
                    </div>
            
                  </div>
                </td>
               
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue my-2 text-center w-100">Minimo de compra</h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==0, 'd-none':ind>1}" class="text-center align-middle py-0 border-0" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                  <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center col-12 ">{{des.dblMontoMin_Cupon|currency}}</span>
                 </div>
                 
                </td>
                
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center">Descuento</h1>
                </td>
                <td [ngClass]="{'ps-0':ind==0, 'd-none':ind>1}" *ngFor="let des of arrayDescuentos; let ind = index" class="border-0 text-center py-0">
                  <div class="w-100 h-100 bg-white d-flex align-items-center justify-content-center border py-3" style="min-height: 100%;">
                    <span  [ngClass]="{
                      'txt-plata': des.strNombre === 'PLATA',
                      'txt-oro': des.strNombre === 'ORO',
                      'txt-bronce': des.strNombre === 'BRONCE',
                      'txt-platino': des.strNombre === 'PLATINO',
                      'txt-diamante': des.strNombre === 'DIAMANTE',
                      'txt-black': des.strNombre === 'BLACK',
                      'txt-esmeralda': des.strNombre === 'ESMERALDA'
                    }" class="fs-5 fw-bold text-center  w-100"> {{ (des.dblDescuentoCantidad * 100) | number:'1.0-0' }} %</span>
                  
                  </div>
                </td>
                
              </tr>
              <tr>
                <td class="border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center py-0">
                    % Monedero
                  </h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==0, 'd-none':ind>1}"*ngFor="let des of arrayDescuentos; let ind = index" class="py-0 border-0 align-middle text-center">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center w-100"> {{ (des.dblValor_Cupon * 100) | number:'1.0-0' }} %</span>
                  </div>
                </td>
              </tr>
            </tbody>

            <!-- 2 tobody  -->
            <tbody class="bgbody my-3">
              <tr>
                <td class="my-2 align-middle pb-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center w-100">
                    NIVELES
                  </h1>  
                </td>
                <td class="pb-0 border-0" [ngClass]="{'ps-0':ind==2, 'd-none':ind<2 || ind>3  }" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="col-12 d-flex px-0 bg-white border boder-secondary bortop">
                    <div class="badge1 my-2 mx-auto" [ngClass]="{
                      'lin-plata': des.strNombre === 'PLATA',
                      'lin-oro': des.strNombre === 'ORO',
                      'lin-bronce': des.strNombre === 'BRONCE',
                      'lin-platino': des.strNombre === 'PLATINO',
                      'lin-diamante': des.strNombre === 'DIAMANTE',
                      'lin-dark': des.strNombre === 'BLACK',
                      'lin-esmeralda': des.strNombre === 'ESMERALDA'
                    }">
                      <div class="inner-circle position-relative" [ngClass]="{
                        'bg-plata': des.strNombre === 'PLATA',
                        'bg-oro': des.strNombre === 'ORO',
                        'bg-bronce': des.strNombre === 'BRONCE',
                        'bg-platino': des.strNombre === 'PLATINO',
                        'bg-diamante': des.strNombre === 'DIAMANTE',
                        'bg-dark': des.strNombre === 'BLACK',
                        'bg-esmeralda': des.strNombre === 'ESMERALDA'
                      }">
                        <img src="assets/partner/svg/PLATA.svg" *ngIf="des.strNombre === 'PLATA'" alt="BLACK">
                        <img src="assets/partner/svg/ORO.svg" *ngIf="des.strNombre === 'ORO'" alt="BLACK">
                        <img src="assets/partner/svg/BRONCE.svg" *ngIf="des.strNombre === 'BRONCE'" alt="BLACK">
                        <img src="assets/partner/svg/PLATINO.svg" *ngIf="des.strNombre === 'PLATINO'" alt="BLACK">
                        <img src="assets/partner/svg/DIAMANTE.svg" *ngIf="des.strNombre === 'DIAMANTE'" alt="BLACK">
                        <img src="assets/partner/svg/BLACK.svg" *ngIf="des.strNombre === 'BLACK'" alt="BLACK">
                        <img src="assets/partner/svg/Esmeralda.svg" *ngIf="des.strNombre === 'ESMERALDA'" alt="BLACK">
                        <span class="text-white fwbold position-absolute bottom-0 start-50 translate-middle-x shadtxt">
                          {{des.strNombre}}
                         </span>
                      </div>
                      
                    </div>
            
                  </div>
                </td>
               
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue my-2 text-center w-100">Minimo de compra</h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==2, 'd-none':ind<2 || ind>3 }" class="text-center align-middle py-0 border-0" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                  <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center col-12 ">{{des.dblMontoMin_Cupon|currency}}</span>
                 </div>
                 
                </td>
                
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center">Descuento</h1>
                </td>
                <td [ngClass]="{'ps-0':ind==2,'d-none':ind<2 || ind>3 }" *ngFor="let des of arrayDescuentos; let ind = index" class="border-0 text-center py-0">
                  <div class="w-100 h-100 bg-white d-flex align-items-center justify-content-center border py-3" style="min-height: 100%;">
                    <span  [ngClass]="{
                      'txt-plata': des.strNombre === 'PLATA',
                      'txt-oro': des.strNombre === 'ORO',
                      'txt-bronce': des.strNombre === 'BRONCE',
                      'txt-platino': des.strNombre === 'PLATINO',
                      'txt-diamante': des.strNombre === 'DIAMANTE',
                      'txt-black': des.strNombre === 'BLACK',
                      'txt-esmeralda': des.strNombre === 'ESMERALDA'
                    }" class="fs-5 fw-bold text-center  w-100"> {{ (des.dblDescuentoCantidad * 100) | number:'1.0-0' }} %</span>
                  
                  </div>
                </td>
                
              </tr>
              <tr>
                <td class="border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center py-0">
                    % Monedero
                  </h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==2,  'd-none':ind<2 || ind>3 }"*ngFor="let des of arrayDescuentos; let ind = index" class="py-0 border-0 align-middle text-center">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center w-100"> {{ (des.dblValor_Cupon * 100) | number:'1.0-0' }} %</span>
                  </div>
                </td>
              </tr>
            </tbody>
             <!-- 3tobody  -->
            <tbody class="bgbody my-3">
              <tr>
                <td class="my-2 align-middle pb-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center w-100">
                    NIVELES
                  </h1>  
                </td>
                <td class="pb-0 border-0" [ngClass]="{'ps-0':ind==4,'d-none':ind<4 || ind>5 }" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="col-12 d-flex px-0 bg-white border boder-secondary bortop">
                    <div class="badge1 my-2 mx-auto" [ngClass]="{
                      'lin-plata': des.strNombre === 'PLATA',
                      'lin-oro': des.strNombre === 'ORO',
                      'lin-bronce': des.strNombre === 'BRONCE',
                      'lin-platino': des.strNombre === 'PLATINO',
                      'lin-diamante': des.strNombre === 'DIAMANTE',
                      'lin-dark': des.strNombre === 'BLACK',
                      'lin-esmeralda': des.strNombre === 'ESMERALDA'
                    }">
                      <div class="inner-circle position-relative" [ngClass]="{
                        'bg-plata': des.strNombre === 'PLATA',
                        'bg-oro': des.strNombre === 'ORO',
                        'bg-bronce': des.strNombre === 'BRONCE',
                        'bg-platino': des.strNombre === 'PLATINO',
                        'bg-diamante': des.strNombre === 'DIAMANTE',
                        'bg-dark': des.strNombre === 'BLACK',
                        'bg-esmeralda': des.strNombre === 'ESMERALDA'
                      }">
                        <img src="assets/partner/svg/PLATA.svg" *ngIf="des.strNombre === 'PLATA'" alt="BLACK">
                        <img src="assets/partner/svg/ORO.svg" *ngIf="des.strNombre === 'ORO'" alt="BLACK">
                        <img src="assets/partner/svg/BRONCE.svg" *ngIf="des.strNombre === 'BRONCE'" alt="BLACK">
                        <img src="assets/partner/svg/PLATINO.svg" *ngIf="des.strNombre === 'PLATINO'" alt="BLACK">
                        <img src="assets/partner/svg/DIAMANTE.svg" *ngIf="des.strNombre === 'DIAMANTE'" alt="BLACK">
                        <img src="assets/partner/svg/BLACK.svg" *ngIf="des.strNombre === 'BLACK'" alt="BLACK">
                        <img src="assets/partner/svg/Esmeralda.svg" *ngIf="des.strNombre === 'ESMERALDA'" alt="BLACK">
                        <span class="text-white fwbold position-absolute bottom-0 start-50 translate-middle-x shadtxt">
                          {{des.strNombre}}
                         </span>
                      </div>
                      
                    </div>
            
                  </div>
                </td>
               
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue my-2 text-center w-100">Minimo de compra</h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==4, 'd-none':ind<4 || ind>5}" class="text-center align-middle py-0 border-0" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                  <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center col-12 ">{{des.dblMontoMin_Cupon|currency}}</span>
                 </div>
                 
                </td>
                
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center">Descuento</h1>
                </td>
                <td [ngClass]="{'ps-0':ind==4, 'd-none':ind<4 || ind>5}" *ngFor="let des of arrayDescuentos; let ind = index" class="border-0 text-center py-0">
                  <div class="w-100 h-100 bg-white d-flex align-items-center justify-content-center border py-3" style="min-height: 100%;">
                    <span  [ngClass]="{
                      'txt-plata': des.strNombre === 'PLATA',
                      'txt-oro': des.strNombre === 'ORO',
                      'txt-bronce': des.strNombre === 'BRONCE',
                      'txt-platino': des.strNombre === 'PLATINO',
                      'txt-diamante': des.strNombre === 'DIAMANTE',
                      'txt-black': des.strNombre === 'BLACK',
                      'txt-esmeralda': des.strNombre === 'ESMERALDA'
                    }" class="fs-5 fw-bold text-center  w-100"> {{ (des.dblDescuentoCantidad * 100) | number:'1.0-0' }} %</span>
                  
                  </div>
                </td>
                
              </tr>
              <tr>
                <td class="border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center py-0">
                    % Monedero
                  </h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==4, 'd-none':ind<4 || ind>5}"*ngFor="let des of arrayDescuentos; let ind = index" class="py-0 border-0 align-middle text-center">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center w-100"> {{ (des.dblValor_Cupon * 100) | number:'1.0-0' }} %</span>
                  </div>
                </td>
              </tr>
            </tbody>
              <!-- 4tobody  -->
            <tbody class="bgbody my-3">
              <tr>
                <td class="my-2 align-middle pb-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center w-100">
                    NIVELES
                  </h1>  
                </td>
                <td class="pb-0 border-0" [ngClass]="{'ps-0':ind==6, 'd-none':ind<6 || ind>7}" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="col-12 d-flex px-0 bg-white border boder-secondary bortop">
                    <div class="badge1 my-2 mx-auto" [ngClass]="{
                      'lin-plata': des.strNombre === 'PLATA',
                      'lin-oro': des.strNombre === 'ORO',
                      'lin-bronce': des.strNombre === 'BRONCE',
                      'lin-platino': des.strNombre === 'PLATINO',
                      'lin-diamante': des.strNombre === 'DIAMANTE',
                      'lin-dark': des.strNombre === 'BLACK',
                      'lin-esmeralda': des.strNombre === 'ESMERALDA'
                    }">
                      <div class="inner-circle position-relative" [ngClass]="{
                        'bg-plata': des.strNombre === 'PLATA',
                        'bg-oro': des.strNombre === 'ORO',
                        'bg-bronce': des.strNombre === 'BRONCE',
                        'bg-platino': des.strNombre === 'PLATINO',
                        'bg-diamante': des.strNombre === 'DIAMANTE',
                        'bg-dark': des.strNombre === 'BLACK',
                        'bg-esmeralda': des.strNombre === 'ESMERALDA'
                      }">
                        <img src="assets/partner/svg/PLATA.svg" *ngIf="des.strNombre === 'PLATA'" alt="BLACK">
                        <img src="assets/partner/svg/ORO.svg" *ngIf="des.strNombre === 'ORO'" alt="BLACK">
                        <img src="assets/partner/svg/BRONCE.svg" *ngIf="des.strNombre === 'BRONCE'" alt="BLACK">
                        <img src="assets/partner/svg/PLATINO.svg" *ngIf="des.strNombre === 'PLATINO'" alt="BLACK">
                        <img src="assets/partner/svg/DIAMANTE.svg" *ngIf="des.strNombre === 'DIAMANTE'" alt="BLACK">
                        <img src="assets/partner/svg/BLACK.svg" *ngIf="des.strNombre === 'BLACK'" alt="BLACK">
                        <img src="assets/partner/svg/Esmeralda.svg" *ngIf="des.strNombre === 'ESMERALDA'" alt="BLACK">
                        <span class="text-white fwbold position-absolute bottom-0 start-50 translate-middle-x shadtxt">
                          {{des.strNombre}}
                         </span>
                      </div>
                      
                    </div>
            
                  </div>
                </td>
               
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue my-2 text-center w-100">Minimo de compra</h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==6, 'd-none':ind<6 || ind>7}" class="text-center align-middle py-0 border-0" *ngFor="let des of arrayDescuentos; let ind = index">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                  <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center col-12 ">{{des.dblMontoMin_Cupon|currency}}</span>
                 </div>
                 
                </td>
                
              </tr>
              <tr>
                <td class="py-0 border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center">Descuento</h1>
                </td>
                <td [ngClass]="{'ps-0':ind==6, 'd-none':ind<6 || ind>7}" *ngFor="let des of arrayDescuentos; let ind = index" class="border-0 text-center py-0">
                  <div class="w-100 h-100 bg-white d-flex align-items-center justify-content-center border py-3" style="min-height: 100%;">
                    <span  [ngClass]="{
                      'txt-plata': des.strNombre === 'PLATA',
                      'txt-oro': des.strNombre === 'ORO',
                      'txt-bronce': des.strNombre === 'BRONCE',
                      'txt-platino': des.strNombre === 'PLATINO',
                      'txt-diamante': des.strNombre === 'DIAMANTE',
                      'txt-black': des.strNombre === 'BLACK',
                      'txt-esmeralda': des.strNombre === 'ESMERALDA'
                    }" class="fs-5 fw-bold text-center  w-100"> {{ (des.dblDescuentoCantidad * 100) | number:'1.0-0' }} %</span>
                  
                  </div>
                </td>
                
              </tr>
              <tr>
                <td class="border-0 border-bottom">
                  <h1 class="fs-6 fwbold txtEcoBlue mb-0 text-center py-0">
                    % Monedero
                  </h1>
                </td>
                <td  [ngClass]="{'ps-0':ind==6, 'd-none':ind<6 || ind>7}"*ngFor="let des of arrayDescuentos; let ind = index" class="py-0 border-0 align-middle text-center">
                  <div class="w-100 py-3 h-100 bg-white d-flex align-items-center justify-content-center border" style="min-height: 100%;">
                <span  [ngClass]="{
                    'txt-plata': des.strNombre === 'PLATA',
                    'txt-oro': des.strNombre === 'ORO',
                    'txt-bronce': des.strNombre === 'BRONCE',
                    'txt-platino': des.strNombre === 'PLATINO',
                    'txt-diamante': des.strNombre === 'DIAMANTE',
                    'txt-black': des.strNombre === 'BLACK',
                    'txt-esmeralda': des.strNombre === 'ESMERALDA'
                  }" class="fs-5 fw-bold text-center w-100"> {{ (des.dblValor_Cupon * 100) | number:'1.0-0' }} %</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  