<div class="container">
    <div class="row">
        <div class="col-12 my-3">
            <h1 class="mb-0 fw-bold text-center nunito">
                Venta por Cliente/Linea
            </h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-2 mb-4">
        <div class="col-12 col-md mt-2 pt-1   d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12  col-md mt-2 pt-1 d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md mt-2 pt-1    d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Cliente</mat-label>
                <mat-select [(ngModel)]="strClienteSelect">
                    <mat-option  value="">
                       -- Todos --
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-2 mt-2  d-flex justify-content-center align-items-center">
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="getInfoLineas()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="spinerArts" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="alert alert-danger text-center fw-bold my-3" *ngIf=" !spinerArts && arrayListadoNew.length==0" role="alert">
                No se han encontrado datos de pedidos
              </div>
              <div  [hidden]="arrayListadoNew.length==0 && spinerArts">
                <div class="col-12 d-flex px-2 bg-white justify-content-between align-items-center">
                    <mat-form-field appearance="outline" class="bg-white rounded my-2">
                        <mat-label>Buscar:</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                      </mat-form-field>
                      <span > 
                          <button class="btn btn-outline-success shadow-sm px-2 py-1 me-3" *ngIf=" arrayListadoNew.length>0" (click)="exportToExcel_hist1()" (mouseenter)="isHoveredButton1 = true" (mouseleave)="isHoveredButton1 = false">
                            <i class="fa-regular fa-file-excel pe-2"></i>
                           <span class="fs12 fw-bold">Descargar</span> 
                          </button>
                       <span class="fw-bold">Total Articulos:</span>  {{arrayListadoNew.length}}
                      </span>
                  </div>
              </div>
              
            <div class="table-responsive mb-0" [hidden]="arrayListadoNew.length==0">
                  <div class="mat-elevation-z8">
                    <table  class="table w-100 table-bordered" mat-table [dataSource]="dataSource" matSort>
                       
                        <ng-container matColumnDef="strCliente">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text ps-0 sticky-top"> Cliente </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table fs13 nunito fw-bold ps-0 ">{{row.strCliente}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">Total</td>
                          </ng-container>
                          
                          <ng-container matColumnDef="SucursalCliente">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Sucursal </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle nunito fs-txt-table txtDescripcion">
                               <div>
                                <span class="" *ngIf="row.strIDSucursalCte">
                                    ({{row.strIDSucursalCte}})
                                </span>
                                <span>
                                    {{row.strNombreSucursalCte}}
                                </span>
                               </div>
                                
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                          </ng-container>

                          <ng-container matColumnDef="strLinea">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Linea </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle nunito fs-txt-table txtDescripcion">
                                Accesorios<br><hr>
                                Degrapack<br><hr>
                                Kimberly<br><hr>
                                Quimicos<br><hr>
                                Rubbermaid<br><hr>
                                Tork<br><hr>
                                Blue Tissue
                                
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblEnero">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Enero </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11" [ngClass]="{'text-light':row.dblEnero==0,'text-primary fw-bold':row.dblEnero>0}">
                                <span *ngIf="row.dblEneroaccesorios > 0">
                                    {{ row.dblEneroaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblEnerodegrapack > 0">
                                    {{ row.dblEnerodegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblEnerokimberly > 0">
                                    {{ row.dblEnerokimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblEneroquimicos > 0">
                                    {{ row.dblEneroquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblEnerorubbermaid > 0">
                                    {{ row.dblEnerorubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblEnerotork > 0">
                                    {{ row.dblEnerotork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblEnerobluetissue > 0">
                                    {{ row.dblEnerobluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalEnero==0}">
                              {{ TotalEnero|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblFebrero">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Febrero </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11" [ngClass]="{'text-secondary':row.dblFebrero==0,'text-primary fw-bold':row.dblFebrero>0}">
                                <span *ngIf="row.dblFebreroaccesorios > 0">
                                    {{ row.dblFebreroaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblFebrerodegrapack > 0">
                                    {{ row.dblFebrerodegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblFebrerokimberly > 0">
                                    {{ row.dblFebrerokimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblFebreroquimicos > 0">
                                    {{ row.dblFebreroquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblFebrerorubbermaid > 0">
                                    {{ row.dblFebrerorubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblFebrerotork > 0">
                                    {{ row.dblFebrerotork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblFebrerobluetissue > 0">
                                    {{ row.dblFebrerobluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalFebrero==0}">
                              {{ TotalFebrero|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblMarzo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Marzo </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11"  [ngClass]="{'text-secondary':row.dblMarzo==0,'text-primary fw-bold':row.dblMarzo>0}">
                                <span *ngIf="row.dblMarzoaccesorios > 0">
                                    {{ row.dblMarzoaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMarzodegrapack > 0">
                                    {{ row.dblMarzodegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMarzokimberly > 0">
                                    {{ row.dblMarzokimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMarzoquimicos > 0">
                                    {{ row.dblMarzoquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMarzorubbermaid > 0">
                                    {{ row.dblMarzorubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMarzotork > 0">
                                    {{ row.dblMarzotork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMarzobluetissue > 0">
                                    {{ row.dblMarzobluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalMarzo==0}">
                              {{ TotalMarzo|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblAbril">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Abril </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11"  [ngClass]="{'text-secondary':row.dblAbril==0,'text-primary fw-bold':row.dblAbril>0}">
                                <span *ngIf="row.dblAbrilaccesorios > 0">
                                    {{ row.dblAbrilaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAbrildegrapack > 0">
                                    {{ row.dblAbrildegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAbrilkimberly > 0">
                                    {{ row.dblAbrilkimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAbrilquimicos > 0">
                                    {{ row.dblAbrilquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAbrilrubbermaid > 0">
                                    {{ row.dblAbrilrubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAbriltork > 0">
                                    {{ row.dblAbriltork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAbrilbluetissue > 0">
                                    {{ row.dblAbrilbluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalAbril==0}">
                              {{ TotalAbril|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblMayo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Mayo </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11"  [ngClass]="{'text-secondary':row.dblMayo==0,'text-primary fw-bold':row.dblMayo>0}">
                                <span *ngIf="row.dblMayoaccesorios > 0">
                                    {{ row.dblMayoaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMayodegrapack > 0">
                                    {{ row.dblMayodegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMayokimberly > 0">
                                    {{ row.dblMayokimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMayoquimicos > 0">
                                    {{ row.dblMayoquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMayorubbermaid > 0">
                                    {{ row.dblMayorubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMayotork > 0">
                                    {{ row.dblMayotork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblMayobluetissue > 0">
                                    {{ row.dblMayobluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalMayo==0}">
                              {{ TotalMayo|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblJunio">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Junio </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11"  [ngClass]="{'text-secondary':row.dblJunio==0,'text-primary fw-bold':row.dblJunio>0}">
                                <span *ngIf="row.dblJunioaccesorios > 0">
                                    {{ row.dblJunioaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuniodegrapack > 0">
                                    {{ row.dblJuniodegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuniokimberly > 0">
                                    {{ row.dblJuniokimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJunioquimicos > 0">
                                    {{ row.dblJunioquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuniorubbermaid > 0">
                                    {{ row.dblJuniorubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuniotork > 0">
                                    {{ row.dblJuniotork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuniobluetissue > 0">
                                    {{ row.dblJuniobluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalJunio==0}">
                              {{ TotalJunio|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblJulio">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Julio </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11" [ngClass]="{'text-secondary':row.dblJulio==0,'text-primary fw-bold':row.dblJulio>0}" >
                                <span *ngIf="row.dblJulioaccesorios > 0">
                                    {{ row.dblJulioaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuliodegrapack > 0">
                                    {{ row.dblJuliodegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuliokimberly > 0">
                                    {{ row.dblJuliokimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJulioquimicos > 0">
                                    {{ row.dblJulioquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuliorubbermaid > 0">
                                    {{ row.dblJuliorubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuliotork > 0">
                                    {{ row.dblJuliotork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblJuliobluetissue > 0">
                                    {{ row.dblJuliobluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalJulio==0}">
                              {{ TotalJulio|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblAgosto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Agosto </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11" [ngClass]="{'text-secondary':row.dblAgosto==0,'text-primary fw-bold':row.dblAgosto>0}" >
                                <span *ngIf="row.dblAgostoaccesorios > 0">
                                    {{ row.dblAgostoaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAgostodegrapack > 0">
                                    {{ row.dblAgostodegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAgostokimberly > 0">
                                    {{ row.dblAgostokimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAgostoquimicos > 0">
                                    {{ row.dblAgostoquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAgostorubbermaid > 0">
                                    {{ row.dblAgostorubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAgostotork > 0">
                                    {{ row.dblAgostotork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblAgostobluetissue > 0">
                                    {{ row.dblAgostobluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalAgosto==0}">
                              {{ TotalAgosto|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblSeptiembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Septiembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11"  [ngClass]="{'text-secondary':row.dblSeptiembre==0,'text-primary fw-bold':row.dblSeptiembre>0}">
                                <span *ngIf="row.dblSeptiembreaccesorios > 0">
                                    {{ row.dblSeptiembreaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblSeptiembredegrapack > 0">
                                    {{ row.dblSeptiembredegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblSeptiembrekimberly > 0">
                                    {{ row.dblSeptiembrekimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblSeptiembrequimicos > 0">
                                    {{ row.dblSeptiembrequimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblSeptiembrerubbermaid > 0">
                                    {{ row.dblSeptiembrerubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblSeptiembretork > 0">
                                    {{ row.dblSeptiembretork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblSeptiembrebluetissue > 0">
                                    {{ row.dblSeptiembrebluetissue | currency }}
                                </span>  
                                <br>                                 
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2"  [ngClass]="{'text-secondary':TotalSeptiembre==0}">
                              {{ TotalSeptiembre|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblOctubre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Octubre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11" [ngClass]="{'text-secondary':row.dblOctubre==0,'text-primary fw-bold':row.dblOctubre>0}">
                                <span *ngIf="row.dblOctubreaccesorios > 0">
                                    {{ row.dblOctubreaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblOctubredegrapack > 0">
                                    {{ row.dblOctubredegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblOctubrekimberly > 0">
                                    {{ row.dblOctubrekimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblOctubrequimicos > 0">
                                    {{ row.dblOctubrequimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblOctubrerubbermaid > 0">
                                    {{ row.dblOctubrerubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblOctubretork > 0">
                                    {{ row.dblOctubretork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblOctubrebluetissue > 0">
                                    {{ row.dblOctubrebluetissue | currency }}
                                </span>
                                <br>                                
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalOctubre==0}">
                              {{ TotalOctubre|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblNoviembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Noviembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11"  [ngClass]="{'text-secondary':row.dblNoviembre==0,'text-primary fw-bold':row.dblNoviembre>0}">
                                <span *ngIf="row.dblNoviembreaccesorios > 0">
                                    {{ row.dblNoviembreaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblNoviembredegrapack > 0">
                                    {{ row.dblNoviembredegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblNoviembrekimberly > 0">
                                    {{ row.dblNoviembrekimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblNoviembrequimicos > 0">
                                    {{ row.dblNoviembrequimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblNoviembrerubbermaid > 0">
                                    {{ row.dblNoviembrerubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblNovembretork > 0">
                                    {{ row.dblNovembretork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblNoviembrebluetissue > 0">
                                    {{ row.dblNoviembrebluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2"  [ngClass]="{'text-secondary':TotalNoviembre==0}">
                              {{ TotalNoviembre|currency }}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblDiciembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Diciembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11"  [ngClass]="{'text-secondary':row.dblDiciembre==0,'text-primary fw-bold':row.dblDiciembre>0}">
                                <span *ngIf="row.dblDiciembreaccesorios > 0">
                                    {{ row.dblDiciembreaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblDiciembredegrapack > 0">
                                    {{ row.dblDiciembredegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblDiciembrekimberly > 0">
                                    {{ row.dblDiciembrekimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblDiciembrequimicos > 0">
                                    {{ row.dblDiciembrequimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblDiciembrerubbermaid > 0">
                                    {{ row.dblDiciembrerubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblDiciembretork > 0">
                                    {{ row.dblDiciembretork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblDiciembrebluetissue > 0">
                                    {{ row.dblDiciembrebluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2" [ngClass]="{'text-secondary':TotalDiciembre==0}">
                              {{ TotalDiciembre|currency }}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="totales">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top px-2"> TOTAL </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table px-2"  [ngClass]="{'text-secondary':row.total==0,'text-dark fw-bold':row.total>0}">

                                <span *ngIf="row.dblTotalaccesorios > 0">
                                    {{ row.dblTotalaccesorios | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblTotaldegrapack > 0">
                                    {{ row.dblTotaldegrapack | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblTotalkimberly > 0">
                                    {{ row.dblTotalkimberly | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblTotalquimicos > 0">
                                    {{ row.dblTotalquimicos | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblTotalrubbermaid > 0">
                                    {{ row.dblTotalrubbermaid | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblTotaltork > 0">
                                    {{ row.dblTotaltork | currency }}
                                </span>
                                <br><hr>
                                <span *ngIf="row.dblTotalbluetissue > 0">
                                    {{ row.dblTotalbluetissue | currency }}
                                </span>
                                <br>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold px-2">
                              {{ getTotalGeneral()|currency }}
                            </td>
                          </ng-container>
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                      </tr>
                    </table>
                  </div>
            </div>
            <div class="col-12" [hidden]="arrayListadoNew.length==0">
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>





<table id="tableHist_1" class="table table-bordered table-hover d-none">
    <thead>
      <tr>
        <th>Cliente</th>
        <th>Sucursal</th>
        <th>Linea</th>
        <th>Enero</th>
        <th>Febrero</th>
        <th>Marzo</th>
        <th>Abril</th>
        <th>Mayo</th>
        <th>Junio</th>
        <th>Julio</th>
        <th>Agosto</th>
        <th>Septiembre</th>
        <th>Octubre</th>
        <th>Noviembre</th>
        <th>Diciembre</th>
        <th>TOTAL</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of arrayListadoNew">
        <!-- Accesorios -->
        <tr>
          <td>{{ row.strCliente }}</td>
          <td>
            <span *ngIf="row.strIDSucursalCte">({{ row.strIDSucursalCte }})</span>
            {{ row.strNombreSucursalCte }}
          </td>
          <td>Accesorios</td>
          <td>{{ row.dblEneroaccesorios | currency }}</td>
          <td>{{ row.dblFebreroaccesorios | currency }}</td>
          <td>{{ row.dblMarzoaccesorios | currency }}</td>
          <td>{{ row.dblAbrilaccesorios | currency }}</td>
          <td>{{ row.dblMayoaccesorios | currency }}</td>
          <td>{{ row.dblJunioaccesorios | currency }}</td>
          <td>{{ row.dblJulioaccesorios | currency }}</td>
          <td>{{ row.dblAgostoaccesorios | currency }}</td>
          <td>{{ row.dblSeptiembreaccesorios | currency }}</td>
          <td>{{ row.dblOctubreaccesorios | currency }}</td>
          <td>{{ row.dblNoviembreaccesorios | currency }}</td>
          <td>{{ row.dblDiciembreaccesorios | currency }}</td>
          <td>{{ row.dblTotalaccesorios | currency }}</td>
        </tr>
  
        <!-- Degrapack -->
        <tr>
          <td></td>
          <td></td>
          <td>Degrapack</td>
          <td>{{ row.dblEnerodegrapack | currency }}</td>
          <td>{{ row.dblFebrerodegrapack | currency }}</td>
          <td>{{ row.dblMarzodegrapack | currency }}</td>
          <td>{{ row.dblAbrildegrapack | currency }}</td>
          <td>{{ row.dblMayodegrapack | currency }}</td>
          <td>{{ row.dblJuniodegrapack | currency }}</td>
          <td>{{ row.dblJuliodegrapack | currency }}</td>
          <td>{{ row.dblAgostodegrapack | currency }}</td>
          <td>{{ row.dblSeptiembredegrapack | currency }}</td>
          <td>{{ row.dblOctubredegrapack | currency }}</td>
          <td>{{ row.dblNoviembredegrapack | currency }}</td>
          <td>{{ row.dblDiciembredegrapack | currency }}</td>
          <td>{{ row.dblTotaldegrapack | currency }}</td>
        </tr>
  
        <!-- Kimberly -->
        <tr>
          <td></td>
          <td></td>
          <td>Kimberly</td>
          <td>{{ row.dblEnerokimberly | currency }}</td>
          <td>{{ row.dblFebrerokimberly | currency }}</td>
          <td>{{ row.dblMarzokimberly | currency }}</td>
          <td>{{ row.dblAbrilkimberly | currency }}</td>
          <td>{{ row.dblMayokimberly | currency }}</td>
          <td>{{ row.dblJuniokimberly | currency }}</td>
          <td>{{ row.dblJuliokimberly | currency }}</td>
          <td>{{ row.dblAgostokimberly | currency }}</td>
          <td>{{ row.dblSeptiembrekimberly | currency }}</td>
          <td>{{ row.dblOctubrekimberly | currency }}</td>
          <td>{{ row.dblNoviembrekimberly | currency }}</td>
          <td>{{ row.dblDiciembrekimberly | currency }}</td>
          <td>{{ row.dblTotalkimberly | currency }}</td>
        </tr>
  
        <!-- Quimicos -->
        <tr>
          <td></td>
          <td></td>
          <td>Quimicos</td>
          <td>{{ row.dblEneroquimicos | currency }}</td>
          <td>{{ row.dblFebreroquimicos | currency }}</td>
          <td>{{ row.dblMarzoquimicos | currency }}</td>
          <td>{{ row.dblAbrilquimicos | currency }}</td>
          <td>{{ row.dblMayoquimicos | currency }}</td>
          <td>{{ row.dblJunioquimicos | currency }}</td>
          <td>{{ row.dblJulioquimicos | currency }}</td>
          <td>{{ row.dblAgostoquimicos | currency }}</td>
          <td>{{ row.dblSeptiembrequimicos | currency }}</td>
          <td>{{ row.dblOctubrequimicos | currency }}</td>
          <td>{{ row.dblNoviembrequimicos | currency }}</td>
          <td>{{ row.dblDiciembrequimicos | currency }}</td>
          <td>{{ row.dblTotalquimicos | currency }}</td>
        </tr>
  
        <!-- Rubbermaid -->
        <tr>
          <td></td>
          <td></td>
          <td>Rubbermaid</td>
          <td>{{ row.dblEnerorubbermaid | currency }}</td>
          <td>{{ row.dblFebrerorubbermaid | currency }}</td>
          <td>{{ row.dblMarzorubbermaid | currency }}</td>
          <td>{{ row.dblAbrilrubbermaid | currency }}</td>
          <td>{{ row.dblMayorubbermaid | currency }}</td>
          <td>{{ row.dblJuniorubbermaid | currency }}</td>
          <td>{{ row.dblJuliorubbermaid | currency }}</td>
          <td>{{ row.dblAgostorubbermaid | currency }}</td>
          <td>{{ row.dblSeptiembrerubbermaid | currency }}</td>
          <td>{{ row.dblOctubrerubbermaid | currency }}</td>
          <td>{{ row.dblNoviembrerubbermaid | currency }}</td>
          <td>{{ row.dblDiciembrerubbermaid | currency }}</td>
          <td>{{ row.dblTotalrubbermaid | currency }}</td>
        </tr>
  
        <!-- Tork -->
        <tr>
          <td></td>
          <td></td>
          <td>Tork</td>
          <td>{{ row.dblEnerotork | currency }}</td>
          <td>{{ row.dblFebrerotork | currency }}</td>
          <td>{{ row.dblMarzotork | currency }}</td>
          <td>{{ row.dblAbriltork | currency }}</td>
          <td>{{ row.dblMayotork | currency }}</td>
          <td>{{ row.dblJuniotork | currency }}</td>
          <td>{{ row.dblJuliotork | currency }}</td>
          <td>{{ row.dblAgostotork | currency }}</td>
          <td>{{ row.dblSeptiembretork | currency }}</td>
          <td>{{ row.dblOctubretork | currency }}</td>
          <td>{{ row.dblNoviembretork | currency }}</td>
          <td>{{ row.dblDiciembretork | currency }}</td>
          <td>{{ row.dblTotaltork | currency }}</td>
        </tr>
  
        <!-- Blue Tissue -->
        <tr>
          <td></td>
          <td></td>
          <td>Blue Tissue</td>
          <td>{{ row.dblEnerobluetissue | currency }}</td>
          <td>{{ row.dblFebrerobluetissue | currency }}</td>
          <td>{{ row.dblMarzobluetissue | currency }}</td>
          <td>{{ row.dblAbrilbluetissue | currency }}</td>
          <td>{{ row.dblMayobluetissue | currency }}</td>
          <td>{{ row.dblJuniobluetissue | currency }}</td>
          <td>{{ row.dblJuliobluetissue | currency }}</td>
          <td>{{ row.dblAgostobluetissue | currency }}</td>
          <td>{{ row.dblSeptiembrebluetissue | currency }}</td>
          <td>{{ row.dblOctubrebluetissue | currency }}</td>
          <td>{{ row.dblNoviembrebluetissue | currency }}</td>
          <td>{{ row.dblDiciembrebluetissue | currency }}</td>
          <td>{{ row.dblTotalbluetissue | currency }}</td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="3" class="text-center"><strong>Totales</strong></td>
        <td>{{ TotalEnero | currency }}</td>
        <td>{{ TotalFebrero | currency }}</td>
        <td>{{ TotalMarzo | currency }}</td>
        <td>{{ TotalAbril | currency }}</td>
        <td>{{ TotalMayo | currency }}</td>
        <td>{{ TotalJunio | currency }}</td>
        <td>{{ TotalJulio | currency }}</td>
        <td>{{ TotalAgosto | currency }}</td>
        <td>{{ TotalSeptiembre | currency }}</td>
        <td>{{ TotalOctubre | currency }}</td>
        <td>{{ TotalNoviembre | currency }}</td>
        <td>{{ TotalDiciembre | currency }}</td>
        <td>{{ getTotalGeneral() | currency }}</td>
      </tr>
    </tfoot>
  </table>
  