import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtergrupo'
})
export class FiltergrupoPipe implements PipeTransform {

  transform(value:any, args: any): any {
    const resultPost=[]; 
    for(const post of value){
   
      if(post.strGrupo.indexOf(args) > -1){
        
        resultPost.push(post);
      }
    
    };
    return resultPost;
  }

}
