
<h1 mat-dialog-title class="title">Cliente <span class="title2">{{arrayVentaVsObjetivo[0].strCliente}}</span> - {{strNombre}}</h1>

<mat-dialog-content class="mat-typography">
<h2 class="title">Venta vs Objetivo <span class="title2">últimos 3 meses</span></h2>

<table class="table">
    <thead class="titulo">
        <tr style="background:#e5eff1">
            <th>Objetivo mes 1</th>
            <th>Venta es 1 </th>
            <th>Objetivo mes 2 </th>  
            <th>Venta mes 2</th> 
            <th>Objetivo mes actual</th>  
            <th>Venta mes actual</th>       
        </tr>
    </thead>
    <tbody>
        <tr class="text-center">
            <td>{{arrayVentaVsObjetivo[0].dblObjetivo1 | currency}}</td>  
            <td>{{arrayVentaVsObjetivo[0].dblVenta1 | currency}}</td>   
            <td>{{arrayVentaVsObjetivo[0].dblObjetivo2 | currency}}</td>
            <td>{{arrayVentaVsObjetivo[0].dblVenta2 | currency}}</td>          
            <td class="resalta"><b>{{arrayVentaVsObjetivo[0].dblObjetivo | currency}}</b></td>
            <td class="resalta"><b>{{arrayVentaVsObjetivo[0].dblVenta | currency}}</b></td>
        </tr>
    </tbody>
</table>
<br><br>
<h2 class="title"><span class="title2">Venta por línea</span> del mes actual</h2>
<br>
<table class="table">
    <thead class="titulo">
        <tr style="background:#e5eff1" class="text-center">
            <th>Línea</th>
            <th>Objetivo</th>
            <th>Venta</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of arrayDetalleLineas">
            <td>{{item.strLinea}}</td>
            <td class="text-right resalta">{{item.dblObjetivo | currency}}</td>     
            <td class="text-right resalta">{{item.dblVenta | currency}}</td>
        </tr>
    </tbody>
</table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>