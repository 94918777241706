<div class="container-fluid">
    <div class="row">
        <div class="col-12 pt-3 text-center">
            <h1 class="fw-bold">
                <span class="titulotext">ACTUALIZACION DE DATOS</span>
            </h1>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12 d-flex">
            <div class="card border-0 m-auto p-4">
                <div class="card-body">
                    <div class="row d-flex">
                        <div class="col-10 mb-5 p-3 border-bottom m-auto d-flex justify-content-evenly align-items-center mt-3">
                            <span>No. de Cliente</span> <input type="text" class="form-control w-50"
                                placeholder="Ingresa numero de cliente"> <button class="btn btn-primary">Filtrar</button>
                        </div>
                        <div class="col-4 mb-3">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Fecha Solicitud:</label>
                                <input type="date" class="form-control">
                            </div>
                        </div>
                        <div class="col-4 mb-3">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Nombre Comercial:</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-4 mb-3">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Razon Social:</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-4 mb-3">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">RFC:</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-4 mb-3">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Giro de la Empresa:</label>
                                <select class="form-select">
                                    <option value="0">30 dias </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Regimen Fiscal:</label>
                                <select class="form-select">
                                    <option value="0">30 dias </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 m-auto text-center mb-5">
                            <label class="mb-2 fw-bold">Mandar Factura por Correo:</label>
                            <div class="d-grid gap-2 col-2 mx-auto">
                                <button class="btn btn-outline-secondary" type="button">Agregar</button>
                            </div>
                        </div>
                        <div class="col-6 m-auto text-center mb-5">
                            <label class="mb-2 fw-bold">Documentos Importantes:</label>
                            <div class="d-grid gap-2 col-2 mx-auto">
                                <button class="btn btn-outline-secondary" type="button">Agregar</button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card-footer d-flex">
                    <button class="btn btn-primary ms-auto">CONTINUAR</button>
                </div>
            </div>
        </div>
    </div>
</div>