import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { MatTableDataSource } from '@angular/material/table';
import { PeriodicElement } from '../reportecomodatos/reportecomodatos.component';
import { CarroService } from '../servicios/carroservice';
import { DataDBService } from '../servicios/data-db.service';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import * as html2pdf from 'html2pdf.js';
import { Subject } from 'rxjs';



declare var $: any;
export interface tareasCte {
  origen: string;
  titulo: string;
  responsable: string;
  fechaInicio: string;
  fechaVencimiento: string;
  progreso: string;
  btnRadio: string;
}

export interface data {
  indice: string;
  img: string;
  strArticulo: string;
  strDescripcion: string;
  strUnidad: string;
  dblPrecio: string;
  strTipo: string;
  strLista: string;
}
@Component({
  selector: 'app-detalle-oportunidad',
  templateUrl: './detalle-oportunidad.component.html',
  styleUrls: ['./detalle-oportunidad.component.css']
})
export class DetalleOportunidadComponent implements OnInit { 
  displayedColumnsCotizacioncte: string[] = ['indice', 'img', 'strArticulo', 'strDescripcion', 'strUnidad', 'dblPrecio', 'strTipo', 'strLista'];
  

  @ViewChild('btnCerrar') btnCerrar!: ElementRef;
  @ViewChild('btnCerrarMdlGanado') btnCerrarGanado!: ElementRef;
  @ViewChild('btnCerrarMdlPerdido') btnCerrarMdlPerdido!: ElementRef;
  @ViewChild('btnCerrarAct') btnCerrarAct!: ElementRef;
  public strAgente_Crea: string;
  public arrayOport: any = [];
  public arrayHora: any = []; 
  public intIDLeads: any;
  public strAgente: any;
  public strConexion: string = "";
  public avance: any = 0;
  public arrayOportunidades: any = [];
  public arrayLineas2: any = []
  public strLinea: string = "";
  public arrayAgente_Filter:any=[];
  public arrayAgentes_tareas: any = [];
  private unsubscribe$ = new Subject<void>();
  dataSourceC: MatTableDataSource<data>;
  public dblMontoLineas:number=0;
  public arrayMinuta: any = [];
  public arraySubTareas: any = []
  public objModal: any = {
    titulo: ""
  };
  displayedColumnsCotizacion: string[] = ['#', 'Imagen', 'strArticulo', 'strDescripcion', 'strOpcion', 'strNombreOpcion', 'strUnidad', 'dblCantidad', 'dblPrecioSugerido', 'dblPrecio', 'dblImporte'];
  columnsTareasCte: string[] = ['btnRadio', 'titulo', 'responsable', 'fechaInicio', 'origen'];/* , 'fechaVencimiento' ,'progreso'*/
  public spineract: boolean = false;
  dataSourceCotizaciones: MatTableDataSource<PeriodicElement>
  datosTareasCte: MatTableDataSource<tareasCte>;
  public tagName: any;
  public arrayAceptados: any = []
  public arrayRechazados: any = []
  public strTitulo: string = ''
  public arrayAgentes: any = []
  public boolAgente: boolean = false;
public formMinuta: FormGroup;
  public arrayAnexMdlAdd:any = [];
  public time1: string = "am";
  public strAgenteS: any;
  public arrayAgentesActividades: any = [];
  public time2: string = "am";
  @ViewChild('inputComentarios') inputComentarios!: ElementRef;
  public mdlHoraInicio: string = "07:00";
  public mdlHoraFin: string = "07:30";
  public mdlNombre: string = "";
  public mdlComentarios: string = "";
  public strfecha: Date;
  public arrayTime: any = [{ time: "am" }, { time: "pm" }]
  public strEstatus: string = ""
  public strTipoAnexo: any = ""
  public arrayArticulos: any = [];
  public arrayActividades: any = [];
  public strBuscarAct:string = "";
  public objLeadInfo: any = {};
  public inthov: boolean = false;
  public searchInput: string = "";
  public mdlCerrarFecha: Date;
  public mdlCerrarImporte: number;
  public arraySelect_suc_tareas: any = []
  public mdlCerrarMotivo: string = "";
  public strComentario: string = "";
  public strResultado: string = "";
  public objAct: any;
  public contenido: string;
  public arrayAnexos: any = []
  public arrayAm: any = []
  public arraypm: any = []
  public arrayAccesos: any = []
  public objDetActividad: any;
  public objCliente: any = []
  public strUrlLogo: any = ''
  public arrayCotizaciones: any = []
  public intIDMovimientoVenta: number = 0
  public strEstatusCotizacion: any = ''
  public intIDCotizacion: any
  public strMensaje: any
  public arrayPendientes: any = []
  public ListaAnexos: any = []
  public spiner:boolean=false
  public arrayAnexosUpdate: any = []
  public panelOpenState: boolean = false
  public banderaSelectAll: boolean = false
  public searchText: any = ''
  public numeroSeleccionados: number = 0
  public totalConcluidos: number = 0
  public totalPendientes: number = 0;
  public strNombre_ruta: string = "";
  public strRuta_Origen: string = "";
  public objRuta_Parametros: any = {};
  public objRuta_Anterior: any = {};
  public strBuscarArticulo: any = ''
  public strSucursal: any = ''
  public arrayArts: any = [];
  public intCantidadArticulos: number = 0
  public arrayArticuloAgregados: any = []
  public strDescripcionArticulo: any = ''
  public intPrecioPolitica: number = 0;
  public arrayArtsPolitica:any = [];
  public vartri: boolean = false;
  public objArt: any = {};
  public strEtapa: string = "Por Contactar";
  @ViewChild('headerArtsAsing', { static: true }) headerArtsAsing!: ElementRef
  @ViewChild('pdfArtsPolitica', { static: true }) pdfArtsPolitica!: ElementRef
  @ViewChild('fileModal') fileModal!: ElementRef;
  @ViewChild('btnModalArts') btnModalArts!: ElementRef;
  @ViewChild('timeline') timeline!: ElementRef;
  @ViewChild('timeline1') timeline1!: ElementRef;
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  public arrayEnviarAnexosActividades:any = []
  public arrayAgregarAnexosActividades:any=[]
  public arrayAnexosModal:any = []
  public strBuscarAgente:string;
  public imagenUrl1: string;
  public imagenUrl2: string;
  public imagenUrl3: string;
  public imagenUrl4: string;
  public imagenUrl5: string;
  public lineaAgregar:string=""
  public altoInfoGral: number = 0;
  public spinerArtsPolitica: boolean = false;
  public strNombreOportunidad: string;
  public dblMonto: number;
  public strFechaCierre: string;
  public objResponsable:any;
  public intID_Leads: number;
  public strResponsable: string;
  public intID_linea: number;
  public arrayAsigAgentes:any = [];
  public objEditarActividad:any ={};
  public strfecha_editar:string;
  public intIDActividad: any;
  public objSubtarea: any = []
  public arrayLineasOportunidad:any=[];
  @ViewChild('fileInputActividades') fileInputActividades!: ElementRef;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('sort2') sort2: MatSort;
  @ViewChild('btnCerrarEdit') btnCerrarEdit!: ElementRef;
  @ViewChild('editableDiv') editableDiv!: ElementRef;
  @ViewChild('summernote', { static: false }) summernote: ElementRef;
  @ViewChild('btnCerrarMinuta') btnCerrarMinuta!: ElementRef;

  constructor(private formbuilder: FormBuilder, private paginatorIntl: MatPaginatorIntl, private editPl: PlantillafinService, private indexedDBService: DataDBService, private router: Router, public route: ActivatedRoute, private carroservice: CarroService, private _filtroServ: FiltrosService, elem: ElementRef, public _serviciosService: PeticionesService, public localSt: LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos');
    this.strAgenteS = this.localSt.retrieve('strAgente')
    this.strAgente_Crea = this.localSt.retrieve('strAgente');
     
    this.route.queryParamMap.subscribe(params => {
      if (params.get('idLead')) {
        this.intIDLeads = params.get('idLead');
        this.strEstatus = params.get('sts') 
      } else {
        this.intIDLeads = localStorage.getItem('idLead')
      }
    });

    let hora = 7, minutos = 0, strH = "", strM = "";
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayHora.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 30) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 30
      }
    }
    hora = 7
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayAm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 30) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 30
      }
    }

    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arraypm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 30) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 30
      }
    }

    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
      }
    }
    //inicializamos la ruta de retorno

    this.indexedDBService.getItem('objRuta_Anterior', (value) => {

      this.objRuta_Anterior = value;
      this.strNombre_ruta = value && value.ruta_destino == '/detoport' ? value.nombre : 'Tablero Leads';
      this.strRuta_Origen = value && value.ruta_destino == '/detoport' ? value.ruta_origen : '/tableroLeads';
      this.objRuta_Parametros = value && value.parametros ? value.parametros : {};
    });

    this.fnAddMinuta()
   
  }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => { 
      this.fnGetOport();
      this.fnGetInfoCte();
      this.fnGetFiltros();
      this.fnGetCotizaciones();
      this.fnGetAnexos();
      this.getSelect_Suc_Tareas();
      this.fngetLineas();
      this.fnGetAgentes_tareas();
      this.fnGetMinutas();
      this.dblMontoLineas = 0;
      this.lineaAgregar = "";

    })
 
  }
 
  fnTableArts() {
    this.dataSourceC.paginator = this.paginator2;
    this.dataSourceC.sort = this.sort2;
  }

  fnDelete_Tarea( tarea:any){
    let intID_Actividad =tarea.intID_Actividad;
    Swal.fire({
      title: "¿Estás seguro de eliminar esta Actividad?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Sí, Eliminar",
      denyButtonText: `No, Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
      
        this.fnEliminar_Tarea(intID_Actividad)
      
      } else if (result.isDenied) {
        Swal.fire("No se guardaron los cambios", "", "info");
      }
    });
    
  }
  fnEliminar_Tarea(intID_Actividad:number){
    console.log(intID_Actividad);
    let objParam ={
      strAccion: 'deleteTarea',
      strLinea: this.objLeadInfo?.strLinea,
      intID_Actividad:intID_Actividad,
      strConexion: this.strConexion
    }
    
    this._serviciosService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads').subscribe(
      result => {
        let resultado= result[0];
        if(resultado && resultado.code ==200){
          Swal.fire("Tarea Eliminada Correctamente", "", "success");
        }
        else {
          Swal.fire("Error al eliminar Tarea", "", "warning");
        }
        this.fnllenarArrayTareas();
      },
      error => {
        var error = <any>error;
        Swal.fire("Error al eliminar Tarea", "", "warning");
        console.log(error);
      }
    );
  }
  fnEditar_Actividad (objAct:any){
      this.objEditarActividad={};
      this.objEditarActividad= objAct;
      this.mdlNombre=this.objEditarActividad.strTitulo;
    let strf =this.objEditarActividad.strFechaRequerida;

    // Dividir la cadena en partes
    const [datePart, timePart] = strf.split(' '); // Divide en fecha y hora
    const [month, day, year] = datePart.split('/'); // Divide la parte de fecha en mes, día y año
      
      this.strfecha_editar =  year +'-'+ month+'-'+day;
      let hora1 = this.objEditarActividad.strHoraInicio.slice(0, -3);
      this.mdlHoraInicio= hora1 ;
      let hora2 = this.objEditarActividad.strHoraFin.slice(0, -3);
      this.mdlHoraFin =hora2;
      this.strAgente= this.objEditarActividad.strAgente;
      this.mdlComentarios=this.objEditarActividad.strOpcion;
      this.editableDiv.nativeElement.innerHTML = this.mdlComentarios; 
       
  }
  fnEditarActividad(intID_Actividad:number){
    console.log(intID_Actividad);
    let objParam ={
      strAccion: 'editTarea', 
      strFechaInicioServicio: this.strfecha_editar,
      strComentario: this.mdlComentarios,
      strAgente: this.strAgente,
      intID_Actividad:intID_Actividad,
      strConexion: this.strConexion,
      strTitulo: this.mdlNombre,
      strHoraInicio: this.mdlHoraInicio,
      strHoraFin: this.mdlHoraFin
    }
    
   /* let objEnv = {
      strAccion: 'setActividad_Oportunidad',
      intID_TIPO: this.objModal.titulo == "Correo" ? 1 : this.objModal.titulo == "Llamada" ? 3 : 2,
      strTitulo: this.mdlNombre,
      strAgente: this.strAgente,
      strFechaInicioServicio: this.convertDateToString(this.strfecha),
      strHoraInicio: this.mdlHoraInicio,
      strHoraFin: this.mdlHoraFin,
      strConexion: this.strConexion,
      strComentario: this.mdlComentarios,
      intID_Leads: this.intIDLeads,
      xmlSucursales: filtro,
      strAgente_Crea: this.strAgente_Crea
    }
    */
    this._serviciosService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads').subscribe(
      result => {
        let resultado= result[0];
        if(resultado && resultado.code ==200){
          Swal.fire("Tarea Editada Correctamente", "", "success");
        }
        else {
          Swal.fire("Error al Editar Tarea", "", "warning");
        }
        this.btnCerrarEdit.nativeElement.click();
        this.fnllenarArrayTareas();
      },
      error => {
        var error = <any>error;
        Swal.fire("Error al eliminar Tarea", "", "warning");
        console.log(error);
      }
    );
  }
 /**************inicio oportunidades ************/
 fnselecCliente(objClient: any) {
  this.objResponsable = objClient;
  this.strResponsable = objClient.strNombreAgente;
  this.arrayAsigAgentes = [];
}
fnBuscarCte(cte: string) {
  if (cte.length > 2) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getAgentes_OP',
      strBusqueda: cte,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayAsigAgentes = result

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  } else {
    this.arrayAsigAgentes = [];
  }
}
 fngetLineas() {
  this._serviciosService.mostrarCotizaciones({
    strAccion: 'getLineas',
    strConexion: this.strConexion
  }, 'sp_modVentas_Leads').subscribe(
    result => {
      this.arrayLineas2 = result  

    },
    error => {
      var error = <any>error;
      console.log(error);
    }
  );
}

  enviarDetalleOporturnidad(item: any) {

    //guardamos en el servicio de indexed db la ruta
    let objRuta_Anterior = {
      ruta_origen: '/detoport',
      ruta_destino: '/detoport',
      nombre: 'Lead Familia',
      parametros: { idLead: this.intIDLeads , sts: this.strEstatus },
      ruta_anterior_1: {
        nombre: this.strNombre_ruta,
        ruta_origen: this.strRuta_Origen,
        parametros: {}
      }
    }
    this.indexedDBService.removeItem('objRuta_Anterior');
    this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);
    this.router.navigate(['/detoport'], { queryParams: { idLead: item.intID_Leads_Oportunidad, sts: item.strEstatus } });
  }


  fnEiminarProductos(indice: number) {
    this.arrayArticuloAgregados.splice(indice, 1);
  }
  fnListadoOoprtunidad() {
    this.arrayOportunidades =[];
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getListado_oportunidad_Oportunidad',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayOportunidades = result

      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnInsertOportunidad() {

    let idLinea = this.arrayLineas2.filter((b: any) => b.strLinea == this.strLinea)
    let objenv = {
      strAccion: 'setOportunidad_Leads',
      strTitulo: this.strNombreOportunidad,
      dblImporte: this.dblMonto,
      strFecha_Cierre: this.strFechaCierre,
      strAgente: this.objResponsable.strAgente,//this.strResponsable,
      intID_Leads: this.intID_Leads,
      intID_Linea: (idLinea[0]) ? idLinea[0].intID_linea : '',
      xmlArticulos: this.arrayArticuloAgregados,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objenv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Oportunidad Registrada con exito",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnListadoOoprtunidad()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al Agregar!"
          });
        }

        this.strNombreOportunidad = ''
        this.dblMonto =0
        this.strFechaCierre = ''
        this.intID_linea = 0
        this.strResponsable =''
        this.arrayArts = []
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

    /**************fin oportunidades ************/

  fnselecArticulo(obj: any) {
    this.strBuscarArticulo = obj.strArticulo
    this.strDescripcionArticulo = obj.strDescripcion
    this.arrayArts = [];
    this.fnAgregarArticulo()
  }


  fnRedirige() {
    let objRuta_Anterior = this.objRuta_Anterior.ruta_anterior_1 ? this.objRuta_Anterior.ruta_anterior_1 : {};
    objRuta_Anterior.ruta_destino = this.strRuta_Origen;


    this.indexedDBService.removeItem('objRuta_Anterior');
    this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);

    this.router.navigate([this.strRuta_Origen], { queryParams: this.objRuta_Parametros });
  }
  ngAfterViewInit() {
    this.fnInitSummerNote();
    this.fnAltTimeLine()
    this.fnTableArts();
  }

  fnAltTimeLine() {
    setTimeout(() => {
      this.altoInfoGral = (this.timeline.nativeElement.offsetHeight) + (this.timeline1.nativeElement.offsetHeight);
    }, 2000);

  }
  fnAgregarArticulo() {
    let data = {
      strArticulo: this.strBuscarArticulo,
      strDescripcion: this.strDescripcionArticulo
    }
    this.arrayArticuloAgregados.push(data)
    this.strBuscarArticulo = ''
    this.intCantidadArticulos = 0

  }
  funConsultarArt(art: any) {
    if (art.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getArt_Linea',
        strLinea: this.objLeadInfo?.strLinea,
        strBusqueda: art,
        strCliente: '',
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads').subscribe(
        result => {
          this.arrayArts = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayArts = []
    }

  }

  fnInitSummerNote() {
    //$('.summernote').summernote('destroy');
     // Asegúrate de destruir cualquier instancia anterior de Summernote
     $(this.summernote.nativeElement).summernote('destroy');

    //$('.summernote').summernote({
      $(this.summernote.nativeElement).summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
        onImageUpload: (files) => {
        //  this.adjustImageSizes();
         // this.addImageToEditor(files[0]);
         this.uploadImage(files);

        },
        onInit: () => {
          // Ajustar tamaños de las imágenes al inicializar
        }
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']]
       // ['insert', ['picture']]
      ],
      popover: {
        /* image: [
         ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ]*/
      }
    });

     
  }

    // Método para manejar la subida de imágenes
    uploadImage(files: File[]) {
      const file = files[0];
      const reader = new FileReader();
  
      reader.onloadend = () => {
        // La imagen se ha convertido en base64 y podemos insertarla en el editor
        const imageUrl = reader.result as string;
        $(this.summernote.nativeElement).summernote('insertImage', imageUrl);
      };
  
      if (file) {
        reader.readAsDataURL(file); // Convierte la imagen en base64
      }
      
      // Si necesitas subirla a un servidor, puedes hacerlo con una API de subida de archivos.
      // Aquí te dejo un ejemplo de cómo podrías hacerlo con fetch o HttpClient de Angular.
      /*
      const formData = new FormData();
      formData.append('file', file);
  
      this.http.post('your-upload-api-endpoint', formData).subscribe(response => {
        // Inserta la URL de la imagen que se ha subido al servidor
        $(this.summernote.nativeElement).summernote('insertImage', response.imageUrl);
      });
      */
    }
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
  }
  fnTabletareasCte() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }
  fnGetFiltros() {
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });

  }

  fnEnviarActividad(anexos:any={}) {
    let filtro = this.arraySelect_suc_tareas.filter((b: any) => b.selected == true)
    let xml:any=[]
    if(this.arrayAgentesActividades.length>0){
      this.arrayAgentesActividades.forEach(item => {
        xml.push({strAgente:item.strAgente, strNombreAgente:item.strNombre})
    });
    }

    if (this.strfecha == null || this.strfecha == undefined) {
      Swal.fire("Error", "seleccione una fecha correcta", "error");
    } else {
      let objEnv : { [key: string]: any } =  {
        strAccion: 'setActividad_Oportunidad_Multiple',/* setActividad_Oportunidad */
        intID_TIPO: this.objModal.titulo == "Correo" ? 1 : this.objModal.titulo == "Llamada" ? 3 : 2,
        strTitulo: this.mdlNombre,
        strAgente: "" ,/* this.strAgente */
        strFechaInicioServicio: this.convertDateToString(this.strfecha),   
        strHoraInicio: this.mdlHoraInicio,
        strHoraFin: this.mdlHoraFin,
        strConexion: this.strConexion,
        strComentario: this.mdlComentarios,
        intID_Leads: this.intIDLeads,
        xmlSucursales: filtro,
        xmlArticulos:xml,
        strAgente_Crea: this.strAgente_Crea
      }
      if(anexos && Object.keys(anexos).length>0){
        objEnv.strTipo_CIERRE = anexos.strTipo;
        objEnv.strUrl_Logo = anexos.strRespuesta;
        objEnv.strNombreCorto = anexos.strNombre;
        objEnv.strEstatus = ""
      }
      if(this.objModal.titulo == "Subtarea"){
        objEnv.intID_Actividad = this.objSubtarea.intID_Actividad;
      }
      console.log(objEnv)
      this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
        result => {
          console.log(result)
          Swal.fire(result[0].code == "200" ? 'Guardado' : 'Error', result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          if (result[0].code == "200") {
            this.arrayAgentesActividades =[];
            this.contenido = '';
            this.strResultado = '';
            this.numeroSeleccionados = 0;
            this.fnGetOport();
            if(this.objModal.titulo == "Subtarea"){
              this.fngetSubtareas(this.objSubtarea.intID_Actividad)
            }
            this.btnCerrar.nativeElement.click();
          }
        }, error => {
          var error = <any>error; console.log(error);
        }); 
    }
  }
  fnmDLConAct(Act ) {
    this.intIDActividad = null;
    let idAct = Act.intID_Actividad,obj:any;
    obj = this.arrayActividades.find(x => x.intID_Actividad == idAct);
    /* Act = this.arrayActividades.find(x => x.intID_Actividad == idAct); */
    if(obj){
      this.objAct = obj;
    }else{
      obj = this.arraySubTareas.find(x => x.intID_Actividad == idAct);
      this.objAct = obj;
    }
    this.arrayAgentesActividades = []
    this.strSucursal = obj.strComentario
    this.fnInitSummerNote()
    this.objAct = obj
    let agente = this.arrayAgentes.find(x => x.strAgente == obj.strAgente_Crea)
    this.objAct.nombreCrea = agente.strNombre
    this.strResultado = "";
    this.contenido = "";
    let contenido_atrea = this.objAct.strObservaciones && this.objAct.strObservaciones!=''?this.objAct.strObservaciones:'';
     this.intIDActividad = obj.intID_Actividad
    $('.summernote').summernote('code', contenido_atrea);
    if (obj.strEstatus == "CONCLUIDO" || obj.strEstatus == 'POR AUTORIZAR') {
      this.fnGetDetActi(obj);
    }
    console.log(this.objAct)
    this.fnGetAnexosActividades()
  }
  fnAutorizar(objAct: any) {
    let objEnv = {
      strAccion: 'update_Actividad_Oportunidad_Autorizar',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Autorizado',
            text: result[0].strMensaje,
          });
          this.fnllenarArrayTareas()
          this.btnCerrarAct.nativeElement.click()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result[0].strMensaje,
          });
        }
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }
  fnEnProceso(objAct: any) {
    let objenv = {
      strAccion: 'update_Actividad_Oportunidad_Proceso',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion,
      strComentario: this.contenido
    }
    console.log(objenv) 

    this._serviciosService.mostrarCotizaciones(objenv, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Autorizado',
            text: result[0].strMensaje,
          });
          this.fnllenarArrayTareas()
          this.btnCerrarAct.nativeElement.click()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnRechazar(objAct: any) {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Oportunidad_Rechazar',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Autorizado',
            text: result[0].strMensaje,
          });
          this.fnllenarArrayTareas()
          this.btnCerrarAct.nativeElement.click()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnConcluirActividad(objAct) {
    let processedContent = this.contenido;
    processedContent = processedContent.replace(/<img/g, '<img class="img-fluid"');
    this.contenido = processedContent;
    this.strComentario = this.contenido

    let objectEnviar = {
      strAccion: 'update_Actividad_Oportunidad',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intIDLeads,
      strComentario: this.strComentario,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }
console.log(objectEnviar)
    this._serviciosService.mostrarCotizaciones(objectEnviar, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.contenido = ""
          this.strResultado = ""
          this.objAct = {}
          this.strComentario = ""
          this.fnGetOport();
          this.btnCerrarAct.nativeElement.click();
        } else {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  onMouseEnter() {
    this.inthov = true;
  }

  onMouseLeave() {
    this.inthov = false;
  }
  convertDateToString(dateString: Date): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  fnllenarArrayTareas() {
    this.arrayActividades = []
    this.totalConcluidos = 0
    this.totalPendientes = 0
    this.avance = 0
    this.arrayActividades = this.arrayOport.filter(o => o.strTipo == "Visita" || o.strTipo == "Llamada" || o.strTipo == "Correo"|| o.strTipo == "Tarea")

    this.totalConcluidos = this.arrayActividades.filter((o: any) => o.strEstatus == 'CONCLUIDO').length
    this.totalPendientes = this.arrayActividades.filter((o: any) => o.strEstatus == 'PENDIENTES').length
    this.avance = (this.totalConcluidos / this.arrayActividades.length) * 100

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getActividades_Oportunidad',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion,
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayActividades = result

        if (this.arrayActividades.length > 0) {
          this.arrayActividades.forEach(obj => {
            // Paso 1: Convertir strFechaRequerida a un objeto Date
            let fechaRequerida = new Date(obj.strFechaRequerida);
            // Paso 2: Obtener la fecha actual
            let fechaActual = new Date();
            // Paso 3: Calcular la diferencia en milisegundos
            let diferenciaMilisegundos = fechaRequerida.getTime() - fechaActual.getTime();
            // Paso 4: Convertir la diferencia de milisegundos a días
            let diferenciaDias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
            obj.diasDiferencia = diferenciaDias
          });
        }
        this.datosTareasCte = new MatTableDataSource(this.arrayActividades);
        this.fnTabletareasCte();
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnModalRegistrar(tipo: string) {
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.strBuscarAgente=""
    this.objModal.titulo = tipo
    this.arrayAnexMdlAdd = [];
    this.fileInputActividades.nativeElement.value = "";
    this.arrayAgregarAnexosActividades = [];
     this.inputComentarios.nativeElement.innerHTML = ""
  }
  fncambiarHoraInicio() {
    if (this.time1 == "am") {
      this.mdlHoraInicio = "07:00";
    } else if (this.time1 == "pm") {
      this.mdlHoraInicio = "00:00";
    }
  }

  fncambiarHoraFin() {
    if (this.time2 == "am") {
      this.mdlHoraFin = "07:00";
    } else if (this.time2 == "pm") {
      this.mdlHoraFin = "00:00";
    }
  }

  onTimeChange(event: any, obj: string) {
    if (obj == "fin") {
      this.fncambiarHoraFin()
    } else if (obj == "inicio") {
      this.fncambiarHoraInicio()
    }

    // Puedes agregar la lógica adicional que necesitas aquí
  }
  transform(value: string): string {
    // Asegúrate de que el valor es una cadena y tiene una fecha válida
    if (!value) return value;

    // Extrae solo la fecha
    const datePart = value.split(' ')[0];
    return datePart;
  }

  fnGetOport() {
    this.arrayOport = []
    let objEnv = {
      strAccion: 'get_timeline_oportunidad',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {                                                                                                                                          
        this.arrayOport = result;
        console.log(result)
        this.fnGetArticulos();
        this.fnGetLineasOport();
        this.fnllenarArrayTareas();
      }, error => {
        this.fnGetArticulos();
        this.fnGetLineasOport();
        var error = <any>error; console.log(error);
      }
    );
  }
fnGetLineasOport(){
  this.spiner = true
  setTimeout(() => {
    let objEnv = {
      strAccion: 'getLinea_Oportunidad',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayLineasOportunidad = result
        this.spiner = false
      }, error => {
        var error = <any>error; console.log(error);
        this.spiner = false
      });
  }, 2000);
  

}

  fnGetDetActi(objAct: any) {
    this.objDetActividad = {};
    let objEnv = {
      strAccion: 'getDetalleActividad',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {

        this.objDetActividad = result[0];
        this.objDetActividad.strSucursal = objAct && objAct.strOpcion ? objAct.strOpcion : "";
        this.objAct.strObservaciones =  this.objDetActividad.strObservaciones ==""?"--campo vacio--":  this.objDetActividad.strObservaciones 
        this.objAct.strResultado =this.objDetActividad.strResultado =="" ?"0":this.objDetActividad.strResultado
      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  fnCambiarEstatus(estatus: any) {
    if (this.strEstatus != "GANADO" && this.strEstatus != "PERDIDO") {
      let objEnv = {
        strAccion: 'update_estatus_oportunidad',
        intID_Leads: this.intIDLeads,
        strEstatus: estatus,
        strConexion: this.strConexion
      }
      this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
        result => {
          Swal.fire(result[0].code == "200" ? 'Guardado' : 'Error', result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          if (result[0].code == "200") {
            this.fnGetOport();
            this.strEstatus = estatus

            this.updateURLWithNewStatus(this.strEstatus);
          }
        }, error => {
          var error = <any>error; console.log(error);
        });
    }

  }
  fnGetArticulos() {
    let objEnv = {
      strAccion: 'getArt_Oportunidad',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayArticulos = result

      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnGetInfoCte() {
    let objEnv = {
      strAccion: 'getInfo_detalle_oportunidad',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result.length > 0) {
          this.objLeadInfo = result[0]
          this.strAgente = this.objLeadInfo.strAgente_Asignado
          this.strUrlLogo = (this.objLeadInfo.strUrl_Logo) ? this.objLeadInfo.strUrl_Logo : ''
          this.strTitulo = this.objLeadInfo.strTitulo
          this.fnGetArticulosPolitica()
        } else { console.log('sin info') }

      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnModalCerrar() {
    this.mdlCerrarFecha = null;
    this.mdlCerrarImporte = 0;
    this.mdlCerrarMotivo = "";

  }

  fnSetCerrar(tipo: string) {
    if (this.mdlCerrarFecha == null || this.mdlCerrarFecha == undefined) {
      Swal.fire("Error", "seleccione una fecha correcta", "error");
    } else {
      let objEnviar = {
        strAccion: "SetCierre_Oportunidad",
        strFecha_Cierre: this.convertDateToString(this.mdlCerrarFecha),
        strTipo_CIERRE: tipo,
        strEstatus: tipo,
        strMotivo: this.mdlCerrarMotivo,
        dblImporte: this.mdlCerrarImporte,
        strConexion: this.strConexion,
        intID_Leads: this.intIDLeads
      }

      this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_modVentas_Leads').subscribe(
        result => {

          Swal.fire(result[0].code == "200" ? 'Guardado' : 'Error', result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          if (result[0].code == "200") {
            this.fnGetOport();
            if (tipo == "GANADO") {
            

              this.btnCerrarGanado.nativeElement.click();
            } else if (tipo == "PERDIDO") {
      
              this.btnCerrarMdlPerdido.nativeElement.click();
            }
            this.strEstatus = tipo
            this.updateURLWithNewStatus(tipo)
          }
        }, error => {
          var error = <any>error; console.log(error);
        });
    }
  }

  fnGuardarMinuta(estatus: any) {
    if (this.strEstatus == 'PRESENTACION') {
      estatus = 'RECORRIDO'
    }
    let objEnv = {
      strAccion: 'update_estatus_oportunidad',
      intID_Leads: this.intIDLeads,
      strEstatus: estatus,
      strAgente: this.strAgenteS,
      strObservaciones: this.contenido,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == "200") {
          Swal.fire(result[0].code == "200" ? 'Guardado' : 'Error', result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          this.strEstatus = estatus
          this.contenido = ''
          this.fnGetOport()
          this.updateURLWithNewStatus(estatus)
        }
      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnCotizador() {
    this.carroservice.deleteCarrito();
    this.objCliente = {
      strCliente: this.intIDLeads,
      strNombre: this.strTitulo,
      strDireccion: '', //this.intIDLeads,
      ClienteSeleccionado: true,
      strNombreSucursal: '',
      intIDSucursal: "",
      strTipo: "PROSPECTO",
      strAgente: this.strAgenteS,
      strAlmacen: "",
      strClaveUsoCFDI: "NA",
      strCondicion: "Contado",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: "NA",
      strTipoMovimiento: 'COTIZACION_OPORTUNIDAD',
      strOrigen: '/detoport/',
      strOrigen2: '/detoport' + '?idLead=' + this.intIDLeads + '&sts=' + this.strEstatus, //Servirá para redireccionar al componente origen
      strCteFam: '',
      strEstatus_Cotizacion_Leads: this.strEtapa == 'NEGOCIACION' ? 'POR AUTORIZAR' : 'AUTORIZADO',
      strEtapa_Leads: this.strEtapa

    };

    this.carroservice.setModoPlantilla(false);
    this.carroservice.setCte(this.objCliente);
    this.router.navigate(['/cotizador_Linea/']);



  }

  fnCheckValidaciones(fase: any) {

    if (fase == 'PRESENTACION') {
      if (this.strEstatus !== 'PRESENTACION' && this.strEstatus !== 'RECORRIDO' && this.strEstatus !== 'PROPUESTA ECONOMICA' && this.strEstatus !== 'NEGOCIACION' && this.strEstatus !== 'GANADO' && this.strEstatus !== 'PERDIDO') {
        this.fnInitSummerNote()
        document.getElementById!('botonMinuta').click()

      } else { }
    } else if (fase == 'RECORRIDO') {
      if (this.strEstatus != 'POR CONTACTAR' && this.strEstatus !== 'PROPUESTA ECONOMICA' && this.strEstatus !== 'NEGOCIACION' && this.strEstatus !== 'GANADO' && this.strEstatus !== 'PERDIDO') {
        this.fnInitSummerNote()
        document.getElementById!('botonMinuta').click()

      } else { }
    } else if (fase == 'PROPUESTA ECONOMICA') {

      if (this.strEstatus !== 'NEGOCIACION' && this.strEstatus !== 'GANADO' && this.strEstatus !== 'PERDIDO') {
        this.fnCambiarEstatus(fase) 

       /* if (this.arrayCotizaciones.length > 0) { 
          this.fnCambiarEstatus(fase) 
        
        } else {

          $('#cotizacion').modal('show')
        } */
      } else {

      }
    }
    else if (fase == 'NEGOCIACION') {

      if (this.strEstatus !== 'GANADO' && this.strEstatus !== 'PERDIDO') {
        if (this.arrayPendientes.length > 0) {
          this.strMensaje = 'TIENES UNA COTIZACION CON ESTATUS POR AUTORIZAR ASI NO PODRIAMOS AVANZAR A LA SIGUIENTE FASE'
        } else if (this.arrayAceptados.length > 0) {
          this.strMensaje = 'YA NO ES NECESARIO PASAR A LA FASE NEGOCIACIÓN TIENES UNA COTIZACION ACEPTADA DIRIGETE A CERRAR EL NEGOCIO'
        } else if (this.arrayRechazados.length > 0 && this.arrayAceptados.length == 0) {
          this.strMensaje = 'TIENES UNA COTIZACION CON ESTATUS POR AUTORIZAR ASI NO PODRIAMOS AVANZAR A CIERRE GENERA UNA NUEVA COTIZACION'
        }


        this.arrayAceptados = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'ACEPTADO')
        this.arrayRechazados = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'RECHAZADO')
        if (this.arrayRechazados.length > 0 && this.arrayAceptados.length == 0) {
          this.fnCambiarEstatus(fase)
        } else {
          this.fnCambiarEstatus(fase);
          //$('#ConcluirCotizaciones').modal('show')
        }
      } else { }
    }
  }
  fnGetAgentes_tareas(){
 
    let obj = {
      strAccion: 'getAgentes_Leads', 
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
         this.arrayAgentes_tareas=result
      }, error => {
     
        console.error('Error al consumir la API:', error);
      });
    
  }
  fnGetDetalleCotizacion(item: any) {
    this.intIDMovimientoVenta = item.intIDMovimientoVenta
    let obj = {
      strAccion: 'getCotizaciones_Cte_Det',
      intIDMovimientoVenta: this.intIDMovimientoVenta,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {

        this.dataSourceCotizaciones= new MatTableDataSource(result);
  
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }

  stopPropagation2(event: any) {
    event.stopPropagation();
  }

  fnsetEstatus_Cotizacion(idCotizacion: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setEstatus_Cotizacion',
      intIDMovimientoVenta: idCotizacion,
      strEstatus: this.strEstatusCotizacion,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.intIDCotizacion = ''
          this.strEstatusCotizacion = ''
          this.fnGetCotizaciones()
          // this.fnDetalleLead()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetCotizaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getCotizaciones_Oportunidad',
      strCliente: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Cotizador').subscribe(
      result => {

        this.arrayCotizaciones = result
        this.arrayPendientes = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'POR AUTORIZAR')
        this.arrayAceptados = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'ACEPTADO')
        this.arrayRechazados = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'RECHAZADO')
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  updateURLWithNewStatus(newStatus: string) {
    // Crea una copia de los parámetros actuales de la URL
    let queryParams = { ...this.route.snapshot.queryParams };

    // Actualiza el parámetro 'sts' con el nuevo valor
    queryParams['sts'] = newStatus;

    // Navega a la misma ruta pero con los parámetros actualizados
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // conserva otros parámetros existentes en la URL
    });
  }

  agregarAnexo(event: any) {
    const fecha = new Date();
    const archivo: File = event.target.files[0];

    const tipo = archivo.type.split('/');
    if (tipo[1] == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      tipo[1] = "xlsx";
    }
    const nombreArchivo = this.intIDLeads + '-' + this.strTipoAnexo + ' ' + fecha.getFullYear() + '-' + fecha.getMonth() + '-' + fecha.getDay() + ' ' + fecha.getHours() + ':' + fecha.getMinutes() + ':' + fecha.getSeconds() + '.' + tipo[1];
    const rutaArchivo = 'EvidenciaLeads/' + nombreArchivo;

    const nuevoArchivo = new File([archivo], nombreArchivo, { type: archivo.type });
    this.ListaAnexos.push(nuevoArchivo);

    this.arrayAnexosUpdate.push({
      strTipo: tipo[1],
      strUrl: rutaArchivo,
      strTipoDocumento: this.strTipoAnexo,
      strNombre: nombreArchivo
    });

  }

  SubirAnexos() {
    if (this.ListaAnexos.length > 0) {
      this._serviciosService.makeFileRequest('https://www.ecodeli.com/EvidenciaAnexo_Leads.php', [], this.ListaAnexos).then((result) => {

        Swal.fire({
          icon: 'success',
          title: 'Correcto...',
          text: 'Anexos Guardados',
        });
        this.fnSetAnexo_Oportunidad()
      }, (error) => {
        console.log(error);
      });
    } else {
      //this.fnInsertLead()
      this.fnSetAnexo_Oportunidad()
    }
  }

  fnSetAnexo_Oportunidad() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'SetAnexo_Oportunidad',
      intIdLeads: this.intIDLeads,
      xmlAnexos: this.arrayAnexosUpdate,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        Swal.fire(result[0].code == "200" ? 'Guardado' : 'Error', result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
        if (result[0].code == "200") {
          this.fnGetAnexos()
          this.arrayAnexosUpdate = []
          this.ListaAnexos = []
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnGetAnexos() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getAnexos_Oportunidad',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayAnexos = result
      },
      error => {
        var error = <any>error;
        console.log(error);

      }
    );
  }

  getSelect_Suc_Tareas() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSelect_Suc_Tareas',
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result.length > 0) {
          result.forEach(element => {
            let data = {
              selected: false,
              strNombreEmpresa: element.strNombreEmpresa,
              intID_Leads_Sucursal: element.intID_Leads_Sucursal
            }
            this.arraySelect_suc_tareas.push(data)
          });
        }

      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  sortArray() {
    this.numeroSeleccionados = this.arraySelect_suc_tareas.filter((b: any) => b.selected == true).length
    this.arraySelect_suc_tareas.sort((a, b) => {
      if (a.selected && !b.selected) {
        return -1;
      } else if (!a.selected && b.selected) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  selectAll() {
    if (!this.banderaSelectAll) {
      this.arraySelect_suc_tareas.forEach(element => {
        element.selected = true
      });
      this.banderaSelectAll = true
    } else {
      this.arraySelect_suc_tareas.forEach(element => {
        element.selected = false
      });
      this.banderaSelectAll = false
    }
    this.numeroSeleccionados = this.arraySelect_suc_tareas.filter((b: any) => b.selected == true).length
  }

  fnAgregarArticulos() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setArts_Oportunidad',
      xmlArticulos: this.arrayArticuloAgregados,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Oportunidad Registrada con exito",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetArticulos();
          this.arrayArticuloAgregados = [];
          this.btnModalArts.nativeElement.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al Agregar!"
          });
        }


        this.arrayArts = []

      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnEliminarArt(i) {
    this.arrayArticuloAgregados.splice(i, 1);
  }

  fnAddCar_Cotizacion(item: any, TIPO: string) {
    this.intIDMovimientoVenta = item.intIDMovimientoVenta
    let obj = {
      strAccion: 'getCotizaciones_Cte_Det',
      intIDMovimientoVenta: this.intIDMovimientoVenta,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {

        this.dataSourceCotizaciones = result
        let opcion = TIPO == 'COPIA' ? 2 : 1;
        let opcion2 = TIPO == 'EDITAR' ? true : false;

        let estatus_cotizacion = item.strEstatus ? item.strEstatus : '';
        let Activar_Plantilla = 1;
        this.editPl.setPlantilla({ boolActive: opcion2, intIDMovimientoVenta: this.intIDMovimientoVenta, Activar_Plantilla: Activar_Plantilla, strTipo_Accion_Cotizacion: TIPO });

        // let activarPlantilla = this.strEtapa == 'NEGOCIACION' && TIPO != 'VER' ? true : false; //validacion para que no puedan modificar precios desde la primer cotización
        let activarPlantilla = false;

        this.carroservice.setModoPlantilla(activarPlantilla);

        this.fnSetCarrito(result, this.intIDMovimientoVenta, item, opcion, TIPO)
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }
  fnSetCarrito(arrayArtis: any, intIDMovimientoVenta: number, objVenta: any, opcion: number, TIPO: string) {
    let estatus_cotizacion = objVenta.strEstatus ? objVenta.strEstatus : '';
    let estatus_Cliente = objVenta.strEstatusCliente ? objVenta.strEstatusCliente : '';

    //borramos carrito
    this.carroservice.deleteCarrito();

    //preparamos los articulos
    arrayArtis.forEach(element => {
      let precioSugerido = element.dblPrecioSugerido && element.dblPrecioSugerido > 0 ? element.dblPrecioSugerido : element.dblPrecio;
      let precio = TIPO == 'VER' || TIPO == 'EDITAR' || (TIPO == 'COPIA' && estatus_cotizacion != 'RECHAZADO') ? precioSugerido : element.dblPrecio;
      let preciop = estatus_cotizacion != 'RECHAZADO' ? precioSugerido : 0;
      let objArt = {
        boolEditaPlantilla: opcion == 2 ? true : false,

        checkbx: opcion == 2 ? true : false,
        dblCalificacion: 3,
        dblCantidad: element.dblCantidad,
        dblCostoPromedio: 0, dblCostoTeorico: 0,
        dblPrecio: 0,
        dblPrecio2: element.dblPrecio2,
        dblPrecio3: 0, dblPrecio4: 0, dblPrecio10: 0,
        dblPrecioLista: precio,
        dblPrecioMinimo: 0,

        dblPrecioPlantilla: preciop,
        dblPrecioPolitica: element.dblPrecioPolitica,
        dblPrecioSugerido: preciop,//element.dblPrecioSugerido,
        dblTipoCambio: 0,
        dblUltimoPrecio: 0,
        intEjercicio: 0,
        intIDMovimientoVenta: intIDMovimientoVenta,
        intIDSucursal: 0,
        intPeriodo: 0, intPrecio: 0, intPrecio2: element.dblPrecio2,
        intPrecio3: element.dblPrecio3, intPrecio4: element.dblPrecio4,
        intPrecioLista: element.dblPrecioLista,
        intPrecioPlantilla: preciop,
        intPrecioPolitica: 0, intTotalEstrellas: 2,
        pagina: 1, strArticulo: element.strArticulo, strCategoria: element.strCategoria,
        strComentario: "", strDescripcion1: element.strDescripcion1, strLinea: element.strLinea,
        strNombreOpcion: "",
        strOpcion: element.strOpcion,
        strTipo: 'PROSPECTO',
        strTipoOpcion: element.strTipoOpcion, arrayOpciones: []
      }

      this.carroservice.setArticulo(objArt);
    });

    //agregar cliente
    let alm = objVenta.strSucursal == '0' ? '1' : objVenta.strSucursal;
    //let objNombre_familia = this.arrayFamilias.find(o => o.intID_Familia == this.intID_Familia);
    //let strNombre_familia = objNombre_familia ? objNombre_familia.strFamilia : '';
    let strNombre_familia = this.objLeadInfo ? this.objLeadInfo.strFamilia : '';
    this.objCliente = {
      strCliente: this.intIDLeads + '',
      strNombre: this.objLeadInfo?.strNombreEmpresa,
      strDireccion: 'Morelos ', //this.intIDLeads,
      ClienteSeleccionado: true,
      strNombreSucursal: objVenta.strSucursal,
      intIDSucursal: "",
      strTipo: "PROSPECTO",
      strAgente: this.objLeadInfo.strAgente,
      strAlmacen: alm,
      strClaveUsoCFDI: "NA",
      strCondicion: "Contado",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: "NA",
      strTipoMovimiento: 'COTIZACION_OPORTUNIDAD',

      strOrigen: '/detoport/', //Servirá para redireccionar al componente origen
      strCteFam: strNombre_familia,//agregamos familia 
      strOrigen2: '/detoport?idLead=' + this.intIDLeads,  //Servirá para redireccionar al componente origen
      strEstatus_Cotizacion_Leads: this.strEtapa == 'NEGOCIACION' ? 'POR AUTORIZAR' : 'AUTORIZADO'
    };

    this.carroservice.setCte(this.objCliente);
    //this._alertify.success("Articulo agregado correctamente");
    this.router.navigate(['/carritoresumen']);
  }

  /*
    fnAddCar_Cotizacion(item: any, TIPO: string) {
      this.intIDMovimientoVenta = item.intIDMovimientoVenta
      let obj = {
        strAccion: 'getCotizaciones_Cte_Det',
        intIDMovimientoVenta: this.intIDMovimientoVenta,
        strConexion: this.strConexion
      }
      this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
        result => {
  
          this.dataSourceCotizaciones = result
          let opcion = TIPO == 'COPIA' ? 2 : 1;
          let opcion2 = TIPO == 'EDITAR' ? true : false;
          this.editPl.setPlantilla({ boolActive: opcion2, intIDMovimientoVenta: this.intIDMovimientoVenta, Activar_Plantilla: 2 });
          let activarPlantilla = this.strEtapa == 'NEGOCIACION' ? true : false; //validacion para que no puedan modificar precios desde la primer cotización
          this.carroservice.setModoPlantilla(activarPlantilla);
  
          this.fnSetCarrito(result, this.intIDMovimientoVenta, item, opcion)
        }, error => {
          var error = <any>error; console.log(error);
        }
      );
    }
    fnSetCarrito(arrayArtis: any, intIDMovimientoVenta: number, objVenta: any, opcion: number) {
      //borramos carrito
      this.carroservice.deleteCarrito();
      console.log(arrayArtis)
  
      //preparamos los articulos
      arrayArtis.forEach(element => {
        let precio = element.dblPrecio;
        let preciop = element.dblPrecioSugerido;
        let objArt = {
          boolEditaPlantilla: opcion == 2 ? true : false,
          checkbx: opcion == 2 ? true : false,
          dblCalificacion: 3,
          dblCantidad: element.dblCantidad,
          dblCostoPromedio: 0, dblCostoTeorico: 0,
          dblPrecio: 0,
          dblPrecio2: element.dblPrecio2,
          dblPrecio3: 0, dblPrecio4: 0, dblPrecio10: 0,
          dblPrecioLista: precio,
          dblPrecioMinimo: 0,
  
          dblPrecioPlantilla: preciop,
          dblPrecioPolitica: element.dblPrecioPolitica,
          dblPrecioSugerido: element.dblPrecioSugerido,
          dblTipoCambio: 0,
          dblUltimoPrecio: 0,
          intEjercicio: 0,
          intIDMovimientoVenta: intIDMovimientoVenta,
          intIDSucursal: 0,
          intPeriodo: 0, intPrecio: 0, intPrecio2: element.dblPrecio2,
          intPrecio3: element.dblPrecio3, intPrecio4: element.dblPrecio4,
          intPrecioLista: element.dblPrecioLista,
          intPrecioPlantilla: preciop, intPrecioPolitica: 0, intTotalEstrellas: 2,
          pagina: 1, strArticulo: element.strArticulo, strCategoria: element.strCategoria,
          strComentario: "", strDescripcion1: element.strDescripcion1, strLinea: element.strLinea,
          strNombreOpcion: "",
          strOpcion: element.strOpcion,
          strTipo: 'PROSPECTO',
          strTipoOpcion: element.strTipoOpcion, arrayOpciones: []
  
  
        }
  
        console.log(objArt)
        this.carroservice.setArticulo(objArt);
      });
  
      console.log(objVenta)
      //agregar cliente
      let alm = objVenta.strSucursal == '0' ? '1' : objVenta.strSucursal;
   
      let strNombre_familia = this.objLeadInfo ? this.objLeadInfo.strFamilia : '';
  
      this.objCliente = {
        strCliente: this.intIDLeads + '',
        strNombre: this.objLeadInfo?.strNombreEmpresa,
        strDireccion: 'Morelos ', //this.intIDLeads,
        ClienteSeleccionado: true,
        strNombreSucursal: objVenta.strSucursal,
        intIDSucursal: "",
        strTipo: "PROSPECTO",
        strAgente: this.objLeadInfo.strAgente,
        strAlmacen: alm,
        strClaveUsoCFDI: "NA",
        strCondicion: "Contado",
        strDescripcionUsoCFDI: "",
        strInfopago: "",
        strInfopagoDescripcion: "NA",
        strTipoMovimiento: 'COTIZACION_LEADS',
        strOrigen: '/detoport/', //Servirá para redireccionar al componente origen
        strCteFam: strNombre_familia,//agregamos familia 
        strOrigen2: '/detoport?idLead=' + this.intIDLeads,  //Servirá para redireccionar al componente origen
        strEstatus_Cotizacion_Leads: this.strEtapa == 'NEGOCIACION' ? 'POR AUTORIZAR' : 'AUTORIZADO'
  
      };
  
      console.log(this.objCliente)
      this.carroservice.setCte(this.objCliente);
      //this._alertify.success("Articulo agregado correctamente");
      this.router.navigate(['/carritoresumen']);
    }  */
  // Método para agregar la imagen al editor
  // Función para agregar la imagen al editor
  addImageToEditor(file) {
    // Aquí puedes manejar el proceso de cargar la imagen
    const reader = new FileReader();
    reader.onload = () => {
      $(this.summernote.nativeElement).summernote('insertImage', reader.result);
    };
    reader.readAsDataURL(file);
  }


  adjustImageSizes() {
   
  }
  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }

  applyFilterArt(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceC.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceC.paginator) {
      this.dataSourceC.paginator.firstPage();
    }
  }

  fnAbrirModal(articulo: any) {
    /*     this.fnBuscarCte_Carrito(this.strCliente); */

    //obtener toda la informacion del articulo
    this.intPrecioPolitica = articulo.dblPrecio;
    this.objArt = articulo;
    this.objArt.dblCantidad = 1;
    this.objArt.dblPrecioLista = articulo.dblPrecio;
    if (this.objArt.strTipoOpcion == 'Si') { this.fnExtraerOpciones(articulo) }
    let url = 'https://www.cleanclean.mx/imagenes/'
    /*  console.log('abre modal'); */
    this.imagenUrl1 = url + this.objArt.strArticulo + '.JPG';
    this.imagenUrl2 = url + this.objArt.strArticulo + '-PO-2.JPG';
    this.imagenUrl3 = url + this.objArt.strArticulo + '-PO-3.JPG';
    this.imagenUrl4 = url + this.objArt.strArticulo + '-PO-4.JPG';
    this.imagenUrl5 = url + this.objArt.strArticulo + '-PO-5.JPG';
  }
  //funciones para agregar al carrito
  fnBuscarCte_Carrito(strNumCte: string) {
    //verificar si ya esta dado de alta el cliente actual
    let clienteSesision = this.localSt.retrieve('ObjCliente') ? this.localSt.retrieve('ObjCliente') : {};

    if ((clienteSesision.ClienteSeleccionado && clienteSesision.strCliente != strNumCte) || !clienteSesision.ClienteSeleccionado) {
      //borramos carrito actual 
      this.carroservice.deleteCarrito();

      this.objCliente = {
        strCliente: "",
        strNombre: "",
        strDireccion: "",
        ClienteSeleccionado: false,
        strNombreSucursal: "",
        intIDSucursal: "",
        strTipo: "",
        strAgente: "",
        strAlmacen: "",
        strClaveUsoCFDI: "",
        strCondicion: "",
        strDescripcionUsoCFDI: "",
        strInfopago: "",
        strInfopagoDescripcion: ""
      };

      let cliente = {
        strAccion: "getCte_unique",
        strCliente: strNumCte,
        strGerente: this.localSt.retrieve('strUsuario'), //strUsuario
        strTipoAcceso: this.localSt.retrieve('intIDRolAcceso'),//intIDRolAcceso
        strSucursal: this.localSt.retrieve('strSucursal'),//strSucursal
        strAgente: this.localSt.retrieve('strAgente'), //strAgente
        strConexion: this.strConexion
      };
      /*  console.log(cliente) */
      this._serviciosService.getCotizador(cliente).subscribe(
        result => {

          let arrayClientes = result;
          this.objCliente.strDireccion = arrayClientes[0].strDireccion;
          this.objCliente.strNombre = arrayClientes[0].strNombre;
          this.objCliente.strCliente = arrayClientes[0].strCliente;
          this.objCliente.strNombreSucursal = arrayClientes[0].strNombreSucursal;
          this.objCliente.intIDSucursal = arrayClientes[0].intIDSucursal;
          this.objCliente.strAgente = arrayClientes[0].strAgente;
          this.objCliente.ClienteSeleccionado = true;
          this.objCliente.strTipo = arrayClientes[0].strTipo;
          this.objCliente.strAlmacen = arrayClientes[0].strAlmacen;
          this.objCliente.strClaveUsoCFDI = arrayClientes[0].strClaveUsoCFDI;
          this.objCliente.strCondicion = arrayClientes[0].strCondicion;
          this.objCliente.strDescripcionUsoCFDI = arrayClientes[0].strDescripcionUsoCFDI;
          this.objCliente.strInfopago = arrayClientes[0].strInfopago;
          this.objCliente.strInfopagoDescripcion = arrayClientes[0].strInfopagoDescripcion;
          this.objCliente.strSucursal = arrayClientes[0].strSucursal;

          let sucursales = []

          /*  arrayClientes.forEach(x => {
              if (x.strNombreSucursal != "") {
                sucursales.push(x);
              }
            });
  
            let objPlantilla = { boolActive: false, intIDMovimientoVenta: 0 }
            this.servPlantilla.setPlantilla(objPlantilla);
            
            this.card1[0].rows = 2;
            this.card2[0].rows = 3;
      */     this.carroservice.setCte(this.objCliente);
          this.carroservice.setModoPlantilla(false);
        });
    }
  }
  fnExtraerOpciones(objArt: any) {
    let articulo = {
      strAccion: 'getOpcion',
      strArticuloBusqueda: objArt.strArticulo,
      strConexion: this.strConexion
    };
    this._serviciosService.getCotizador(articulo).subscribe(
      result => {
        this.objArt.arrayOpciones = result;
        this.objArt.strNombreOpcion = this.objArt.arrayOpciones[0].strNombreOpcion ? this.objArt.arrayOpciones[0].strNombreOpcion : '';
        this.objArt.strOpcion = this.objArt.arrayOpciones[0].strOpcion ? this.objArt.arrayOpciones[0].strOpcion : '';

      });
  }
  fnGetArticulosPolitica() {
    this.spinerArtsPolitica = true;
    let obj = {
      strAccion: 'GetArticulos_politica_CTE',
      strCliente: this.objLeadInfo.strCliente,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayArtsPolitica = result;
        
        this.dataSourceC = new MatTableDataSource(result);
        this.fnTableArts();
        this.spinerArtsPolitica = false
      }, error => {
        var error = <any>error; console.log(error);
        this.spinerArtsPolitica = false
      });
  }

  
  onInput(event: Event) {
    const target = event.target as HTMLElement;
    this.mdlComentarios= target.innerHTML; // Guarda el contenido HTML
 /*    console.log( this.mdlComentarios) */
  }
  

  fnBusquedaCte(strBuscarAgente:string){
    let texto=strBuscarAgente.toUpperCase();
        this.arrayAgente_Filter=[];
        for(let post of this.arrayAgentes_tareas){
          let texto2=post.strNombre.toUpperCase();
          if(texto2.indexOf(texto) > -1){
            this.arrayAgente_Filter.push(post);
          }      
        };
  }
  fnSeleccionaAgente (agt:any){
    this.strAgente=agt.strAgente; 
    this.strBuscarAgente="";/* agt.strAgente+'-'+agt.strNombre  */
    this.arrayAgente_Filter=[];
    let findAgente = this.arrayAgentesActividades.find(agtf => agtf.strAgente ==agt.strAgente  );
    if(!findAgente){ 
      this.arrayAgentesActividades.push(agt)
    }else{
      Swal.fire("Error","Agente existente, seleccione otro agente.","error")
    }
   
  }
  fnEliminarAgenteAct (agt:any){
  
    this.arrayAgentesActividades = this.arrayAgentesActividades.filter(item => item !== agt);
  }
  totalImporteLineas(): number {
    let result = this.arrayLineasOportunidad.reduce((sum, item) => sum + item.dblMonto, 0)
  
   /*  this.formOportunidades.patchValue({ intMonto: result }); */
    return  result;
  }
  updateCantidad(event: Event, cet: any): void {
    let inputElement = event.target as HTMLInputElement;
    let valor = parseFloat(inputElement.value);
    if (inputElement.value === 'e'||inputElement.value === '' || isNaN(valor) || valor <= 0) {
      cet.dblMonto = 0;
      inputElement.value = '0'; // Esto asegura que el valor mostrado en el input sea '0'
    } else {
      cet.dblMonto = valor;
    }
    this.totalImporteLineas();
  }



  fnEliminarLinOport(ind:number){
    Swal.fire({
      title: "Estas seguro  de eliminar la linea "+this.arrayLineasOportunidad[ind].strLinea+" ?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this._serviciosService.mostrarCotizaciones({
          strAccion: 'deleteOportunidad_linea',
          intID_Leads: this.intIDLeads,
          intID_Linea:this.arrayLineasOportunidad[ind].intID_Linea,
          strConexion: this.strConexion
        }, 'sp_modVentas_Leads').subscribe(
          result => {
            if (result[0].code == 200) {
              Swal.fire({
                title: "¡Eliminado!",
                icon: "success",
                timer: 2000, // Duración en milisegundos (2 segundos)
                showConfirmButton: false, timerProgressBar: true // Oculta el botón "OK"
              });
              this.ngOnInit();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error al Agregar!"
              });
            }
          }, error => {
            var error = <any>error;
            console.log(error);
          }
        );

      } else if (result.isDenied) {
        Swal.fire({
          title: "Los cambios no se han guardado",
          icon: "info",
          timer: 2000, // Duración en milisegundos (2 segundos)
          showConfirmButton: false, timerProgressBar: true // Oculta el botón "OK"
        });
      }
    });
  }

  fnActualizar(obj:any){
    console.log(obj)

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'upteOportunidad_linea',
      intID_Leads: this.intIDLeads,
      intID_Linea:obj.intID_Linea,
      dblMonto:obj.dblMonto,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Linea Registrada con exito",
            showConfirmButton: false,
            timer: 1500
          });
          this.ngOnInit();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al Agregar!"
          });
        }
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }




  fnAgregarLineaOport(linea:string){
    if(linea!="" && linea!=undefined && linea!=null ){
      let objSelect = this.arrayLineas2.find(lin => lin.strLinea == linea);
      let encontrado = this.arrayLineasOportunidad.find(lin => lin.strLinea == objSelect.strLinea);
      console.log(objSelect)
        if(encontrado){
          Swal.fire("Error","La linea ya existe en","error")
        }else{
          this.arrayLineasOportunidad.push({intID_linea:objSelect.intID_linea,dblMonto:objSelect.dblMonto,strLinea:objSelect.strLinea});
          let xml:any =[]; 
            xml.push({intID_linea:objSelect.intID_linea,dblCantidad:this.dblMontoLineas,strLinea:objSelect.strLinea})
          this._serviciosService.mostrarCotizaciones({
            strAccion: 'setOportunidad_linea',
            xmlArticulos: xml,
            intID_Leads: this.intIDLeads,
            strConexion: this.strConexion
          }, 'sp_modVentas_Leads').subscribe(
            result => {
              if (result[0].code == 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Linea Registrada con exito",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.ngOnInit();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Error al Agregar!"
                });
              }
            }, error => {
              var error = <any>error;
              console.log(error);
            }
          );
        }
      }else{
        Swal.fire("Error","Selecciona una linea","error")
      }
    }

    updateCantidad1(event: Event): void {
      let inputElement = event.target as HTMLInputElement;
      let valor = parseFloat(inputElement.value);
      if (inputElement.value === 'e'||inputElement.value === '' || isNaN(valor) || valor <= 0) {
        this.dblMontoLineas = 0;
        inputElement.value = '0'; // Esto asegura que el valor mostrado en el input sea '0'
      } else {
        this.dblMontoLineas = valor;
      }
      if (inputElement.value.startsWith('0') && inputElement.value.length > 1) {
        inputElement.value = inputElement.value.replace(/^0+/, '');
      }
      this.totalImporteLineas();
    }

    
    fnAdjuntarArchivosActividad(): void {
       
      if (this.fileInputActividades) {
        this.arrayAgregarAnexosActividades =[];
        this.fileInputActividades.nativeElement.value = '';
        this.fileInputActividades.nativeElement.click();
      }
    }
         onFileSelectedActividad(event: any): void {
            let files: FileList = event.target.files;
            if (files.length > 0) {
              for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let newTotalSize =  file.size;
                if (newTotalSize > 10 * 1024 * 1024) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Límite de almacenamiento alcanzado',
                    text: 'No puedes agregar más archivos, el límite es de 10MB.',
                  });
                  return; 
                }
          
                let nombreOriginal = file.name.split('.').slice(0, -1).join('.'); 
                let extension = file.name.split('.').pop(); 
          
                this.arrayAgregarAnexosActividades.push({
                  nombreArchivo: nombreOriginal,
                  extencion: extension,
                  archivo: file
                });
              }
            }
          }
          fnEliminarAnexosAct(): void {
            this.fileInputActividades.nativeElement.value = '';
              this.arrayAgregarAnexosActividades =[];
          }
    
    
          fnAnexosActividad(){
         
            if (this.arrayAgregarAnexosActividades.length > 0) {
            let formData = new FormData();
            this.arrayAgregarAnexosActividades.forEach((o, index) => {
              if (o.archivo instanceof File) {
                formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
              } else {
                console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
              }
            });
              this._serviciosService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
                console.log(result)
                this.arrayEnviarAnexosActividades = []
                let arrayRest = result;
                arrayRest.forEach(obj => {
                  this.arrayEnviarAnexosActividades.push(
                    {
                      strTipo:obj.strTipo,
                      strRespuesta:obj.file,
                      strNombre:obj.strNombre,
                      strAgente:this.strAgente,
                      strEstatus:""
                    });
                });
                console.log(this.arrayEnviarAnexosActividades[0])
                this.fnEnviarActividad(this.arrayEnviarAnexosActividades[0]) 
              }, (error) => {
                console.log(error);
              });
            } else {
              this.fnEnviarActividad() 
            }
          }
    
                onFileInsert(event: any): void {
                  let files: FileList = event.target.files;
                  if (files.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                      const file = files[i];
                      let newTotalSize =  file.size;
                      if (newTotalSize > 10 * 1024 * 1024) {
                        Swal.fire({
                          icon: 'warning',
                          title: 'Límite excedido',
                          text: 'El tamaño total de los archivos no puede superar los 10MB.',
                        });
                        return; 
                      }
                
                      const nombreOriginal = file.name.split('.').slice(0, -1).join('.'); 
                      const extension = file.name.split('.').pop(); 
                
                      this.arrayAnexMdlAdd.push({
                        nombreArchivo: nombreOriginal,
                        extencion: extension,
                        archivo: file
                      });
                
                    }
                  }
                }
          
                fnAgregarArchivo(){
                  let formData = new FormData();
                  this.arrayAnexMdlAdd.forEach((o, index) => {
                    if (o.archivo instanceof File) {
                      formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
                    } else {
                      console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
                    }
                  });
                   
                    this._serviciosService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
                      console.log(result)
                      if(result.length>0){
                        this.arrayEnviarAnexosActividades = []
                        let arrayRest = result;
                        let enviar = {}
                        arrayRest.forEach(obj => {
                          enviar = {
                            strAccion: 'setAnexoActividad',
                            intID_Leads:this.intIDLeads,
                            intID_Actividad:this.intIDActividad,
                            strConexion: this.strConexion,
                            strTipo_CIERRE:obj.strTipo,
                            strUrl_Logo:obj.file,
                            strResultado:obj.strNombre,
                            strAgente_Crea:this.strAgente,
                            strMotivo:""
                          }
                        });
                        console.log(enviar)
                        this.fnGuardarArchivo(enviar);
                      }else{
                        Swal.fire("Error","Error al guardar archivo.","error");
                      }
                    }, (error) => {
                      console.log(error);
                    });
                }
                fnElinimarArchivos(){
                  this.arrayAnexMdlAdd = []
                }
          
                fnGuardarArchivo(obj:any){
                  this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                      result => {
                        console.log(result)
                        if(result[0].code == 200){
                          Swal.fire("Guardado",result[0].strMensaje,"success")
                            this.arrayAnexMdlAdd = [];
                            this.fileModal.nativeElement.value= ""
                            this.arrayAnexosModal = [];
                            this.fnGetAnexosActividades()
                        }else{
                          Swal.fire("Error",result[0].strMensaje,"error")
                        }
                      }, error => {
                        var error = <any>error; console.log(error);
                       // this.spinerBool = false
                      });
                }
                fnAjuntarAnexsModal(): void {
                  if (this.fileModal) {
                    this.arrayAnexMdlAdd = [];
                    this.fileModal.nativeElement.value= ""
                    this.fileModal.nativeElement.click();
                  }
                }
                fnGetAnexosActividades(){
                  this.arrayAnexosModal = [];
                  let obj = {
                    strAccion: 'getActAnexos',
                    intID_Actividad:this.intIDActividad,
                    intID_Leads:this.intIDLeads,
                    strConexion: this.strConexion
                  }
                  
                  this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                      result => {
                      /*   console.log(result) */
                        if(result.length>0){
                          this.arrayAnexosModal = result
                          this.arrayAnexosModal.forEach(obj => {
                            obj.urlImagen = "https://www.ecodeli.com/"+obj.strUrl_Logo
                          });
                      /*     console.log(this.arrayAnexosModal); */
                        }
                       
                      }, error => {
                        var error = <any>error; console.log(error);
                       // this.spinerBool = false
                      });
            }
          
                          fnPdfArtsPolitica(){
                            const Flujoce = this.headerArtsAsing.nativeElement;
                            const options = {
                              margin: [13, 8, 10, 8],
                              filename: 'ArticulosAsinados.pdf',
                              image: { type: 'JPEG', quality: 0.98 },
                              html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
                              jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
                            };
                            const content = this.pdfArtsPolitica.nativeElement;
                            html2pdf()
                              .from(content)
                              .set(options)
                              .toPdf()
                              .get('pdf')
                              .then(pdf => {
                                const totalPages = pdf.internal.getNumberOfPages();
                                for (let i = 1; i <= totalPages; i++) {
                                  pdf.setPage(i);
                                  pdf.setFontSize(12);
                                  pdf.text(Flujoce.innerText, 100, 8); // Ajusta las coordenadas según sea necesario
                                  pdf.setPage(i);
                                  pdf.setFontSize(9);
                                  pdf.text(i.toString(), 5, 205);
                                }
                        
                                pdf.save("ArticulosAsinados.pdf");
                                /*   if(this.flagProspecto){
                                    this.fncerrarmodal()
                                 } */
                              });   
                            }   
    //----------------------------------------------------------------------     
    //---------------------------minuta-------------------------------------
    //----------------------------------------------------------------------  
      deleteMinuta(objMin: any, event: Event): void {
        event.stopPropagation();
    
        Swal.fire({
          title: "¿Estás seguro de eliminar esta minuta?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Sí, Eliminar",
          denyButtonText: `No, Cancelar`
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            try {
              let objEnviar = {
                strConexion: this.strConexion,
                strAccion: 'deleteMinuta',
                intID_Minuta: objMin.intID_Minuta
              } 
              this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_ModVentas_Leads')
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                  result => {
                    console.log(result)
                    let rest = result[0]
                    Swal.fire(rest.code == "200" ? "Eliminada Correctamente" : "Error", rest.strMensaje, rest.code == "200" ? "success" : "error");
                    this.fnGetMinutas();
                  }, error => {
                    var error = <any>error; console.log(error);
                  }
                );
            } catch (error) {
              Swal.fire("Error", error.message, "error")
            }
    
    
          } else if (result.isDenied) {
            Swal.fire({
              title: "No se guardaron los cambios",
              icon: "info",
              timer: 2500,  // Tiempo en milisegundos (3 segundos)
              showConfirmButton: false,  // No mostrar el botón "OK"
              timerProgressBar: true
            });
          }
    
        })
      }
      
        fnGetMinutas() {
          let objMin = {
            intID_Leads: this.intIDLeads,
            strAccion: 'getMinuta_oportunidad',
            strConexion: this.strConexion
          } 
          this._serviciosService.mostrarCotizaciones(objMin, 'sp_ModVentas_Leads')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              result => {
                this.arrayMinuta = result;
                /* console.log(this.arrayMinuta) */
              }, error => {
                var error = <any>error;
                console.log(error);
              }
            );
        }
        fnAddMinuta() {
          this.formMinuta = this.formbuilder.group({
            strTitulo: new FormControl('', [Validators.required]),
            strFecha: new FormControl('', [Validators.required]),
            strHora: new FormControl('', [Validators.required])
          });
        }
      
        fnSetMinuta(objMin: any) {
          try {
            let objEnviar = {
              strConexion: this.strConexion,
              strAccion: 'setMinuta_oportunidad',
              strTitulo: objMin.strTitulo,
              strFechaInicioServicio: objMin.strFecha,
              strHoraInicio: objMin.strHora,
              intID_Leads: this.intIDLeads,
              strAgente: this.strAgente_Crea
            } 
           this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_ModVentas_Leads')
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                result => {
                  let rest = result[0]
                  Swal.fire(rest.code == "200" ? "Guardado Correctamente" : "Error", rest.strMensaje, rest.code == "200" ? "success" : "error");
                  if (rest.code == "200") {
                    this.fnGetMinutas();
                    this.fnGetOport();
                    this.btnCerrarMinuta.nativeElement.click()
                  }
                }, error => {
                  var error = <any>error; console.log(error);
                }
              );
          } catch (error) {
            Swal.fire("Error", error.message, "error")
          }
        }
    
        
  onSubmit() {
    if (this.formMinuta.valid) {
      this.fnSetMinuta(this.formMinuta.value); 
    }
  }
  fnDetalleMinuta(obj: any) { 
    this.router.navigate(['/minuta'], { queryParams: { intID_Minuta: obj.intID_Minuta } });
  }

  fnSeeRow(ind: any, actividad: any) {
    console.log(ind)
    this.fngetSubtareas(actividad.intID_Actividad)
     let valAnt = this.arrayActividades[ind].seeSubAct
    this.arrayActividades.forEach(act => {
      act.seeSubAct = false
    });

    this.arrayActividades[ind].seeSubAct = valAnt == true ? false : true 
  }

   fngetSubtareas(intID_Actividad: any) {
      this.arraySubTareas = [];
      let obj = {
        strAccion:'getSubActividadesOportunidad',
        intID_Actividad:intID_Actividad,
        intID_Leads:this.intIDLeads,
        strConexion: this.strConexion
      };
      console.log(obj)
      this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            console.log(result)
            this.arraySubTareas =  result
           /*  this.arraySubTareas.forEach(item => {
              let objAgente = this.arrayAgentesTodos.find(o => o.strAgente == item.strAgente_Crea)
              item.nombreCrea = objAgente && objAgente.strNombre ? objAgente.strNombre : "";
              item.select = false;
            }); */
          }, error => {
            //this.spinnerTab = false
            console.error('Error al consumir la API:', error);
  
          });
    }

    fnModalRegistrarSubtarea(tipo: string, obj: any) {
  
      this.objSubtarea = obj
      console.log(this.objSubtarea)
      //this.editableDiv2.nativeElement.innerHTML ='';
      this.mdlHoraInicio = "07:00";
      this.mdlHoraFin = "07:00";
      this.mdlNombre = "";
      this.time1 = "am";
      this.time2 = "am";
      this.mdlComentarios = "";
      this.strfecha = null;
      this.objModal.titulo = tipo;
      this.objModal.subTitulo = obj.intID_Leads + '.-' + obj.strTitulo
      this.arrayAgentesActividades = [];
      this.strBuscarAgente = "";
      this.arrayAgregarAnexosActividades = [];
      this.fileInputActividades.nativeElement.value = '';
        this.inputComentarios.nativeElement.innerHTML = ""
    }

    fnCleanSearch(){
      this.strBuscarAct = ""
    }
}




