<div class="container">
  <h2>Pantalla listado</h2>

  <div class="cien">
    <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex">
    </mat-paginator>
  </div>
  <div class="cien">
    <div class="filtros">
      <h2>Filtros:</h2>
      <hr>
      <mat-form-field class="example-form-field">
        <mat-label>Buscar Artículo</mat-label>
        <input matInput type="text" [(ngModel)]="strDescripcion">
        <button *ngIf="strDescripcion" matSuffix mat-icon-button aria-label="Limpiar"
          (click)="strDescripcion=''">x</button>
      </mat-form-field>
      <br>

      <mat-form-field appearance="fill">
        <mat-label>Buscar Línea:</mat-label>
        <mat-select [(ngModel)]="strLinea" name="strLinea">
          <mat-option *ngFor="let array of arrayLinea" [value]="array.strLinea">
            {{array.strLinea}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <br>

      <mat-form-field appearance="fill">
        <mat-label>Buscar Familia:</mat-label>
        <mat-select [(ngModel)]="strFamilia" name="strFamilia">
          <mat-option *ngFor="let array of arrayFamilia" [value]="array.strFamilia">
            {{array.strFamilia}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br>
      
      <mat-form-field appearance="fill">
        <mat-label>Buscar Categoria:</mat-label>
        <mat-select [(ngModel)]="strCategoria" name="strCategoria">
          <mat-option *ngFor="let array of arrayCategoria" [value]="array.strCategoria">
            {{array.strCategoria}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br>

      <mat-form-field appearance="fill">
        <mat-label>Opciones de artículo:</mat-label>
        <mat-select [(ngModel)]="strTipoOpcion" name="strTipoOpcion">
          <mat-option *ngFor="let opc of arrayOpciones" [value]="opc.strTipoOpcion">
            {{opc.strTipoOpcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br>
      <div class="cien text-center">
        <button type="button" class=" botonComprar" (click)="fnGetDetalle()">Filtrar</button>
      </div>
      <br><br>
    </div>
    <div class="cont_collection text-center">
      <div class="cien" *ngIf="cargando">
        <mat-spinner class="mAuto"></mat-spinner>
      </div>
      <div class="collection" *ngIf="!cargando">
        <div class="articulo" *ngFor="let lis of arrayListadoMostrar">
          <a>
            <div class="imagen-contenedor">
              <img class="imagen" src="{{urlImg}}{{lis.strArticulo}}.jpg">
            </div>
            <div class="titulo">
              <div class="nombre col-sm-6"> <label>{{lis.strDescripcion}}</label></div>
              <div class="precio col-sm-6"><label>{{lis.dblPrecioLista | currency}}</label></div>
            </div>
            <div class="sku col-sm-12"> REF {{lis.strArticulo}}</div>
            <div class=" col-sm-12 text-center"><br>
              <button class="botonComprar">Compre Ahora</button>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
