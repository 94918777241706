import {AfterViewInit, Component,ElementRef, ViewChild, OnInit, ChangeDetectorRef} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { FormGroupDirective } from '@angular/forms';
export interface UserData {
  intID_Cupon: string;
  strNombre_Cupon: string;
  strTipo_Cupon: string;
  dblValor_Cupon: string;
  dblMontoMin_Cupon: string;
  dblCantidadMin_Cupon: string;
/*   strMetodoPago_Cupon: string; */
  strCodigo_Cupon: string;
  strFechaInicio_Cupon: string;
  strFechaFin_Cupon: string;
  boolActivo_Cupon: string;
  accion:string;
}
const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/** Constants used to fill up our data base. */

@Component({
  selector: 'app-admin-cupones',
  templateUrl: './admin-cupones.component.html',
  styleUrls: ['./admin-cupones.component.css']
})
export class AdminCuponesComponent implements OnInit {
  displayedColumns: string[] = [
    'intID_Cupon',
    'strNombre_Cupon',
    'strTipo_Cupon',
    'dblValor_Cupon',
    'dblMontoMin_Cupon',
    'dblCantidadMin_Cupon',
/*     'strMetodoPago_Cupon', */
    'strCodigo_Cupon',
    'strFechaInicio_Cupon',
    'strFechaFin_Cupon',
    'boolActivo_Cupon',
    "accion"
  ];
  @ViewChild('btnCerrarMdl') btnCerrarMdl!: ElementRef;
  dataSource: MatTableDataSource<UserData>;
  fechaActual:Date;
  minDate: Date;
  public strConexion: string = "";
  public fechaFin:Date;
  public objCupon:any = {
    strFechaInicio_Cupon:null,
    strFechaFin_Cupon:null,
    dblCantidadMinima:null
  };
  public strFechaInicio_Cupon:any;
  public strFechaFin_Cupon:any;
  public cupCheck:boolean;
  public errorDesc:boolean;
  
  public cuponForm: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('formDirective') private formDirective: FormGroupDirective;
  @ViewChild('btnCerrarEdit') btnCerrarEdit!: ElementRef;
  public arrayDescrip:any =[];
  public arrayMontoFijo:any= [
    {
        "categoria": "Generales",
        "descuentos": [
            "Descuento de $50 MXN en tu próxima compra.",
            "$100 MXN de descuento en toda la tienda este fin de semana.",
            "$200 MXN de descuento al realizar una compra superior a $2,000 MXN.",
            "Descuento de $300 USD en productos electrónicos seleccionados."
        ]
    },
    {
        "categoria": "Nuevos Clientes",
        "descuentos": [
            "$150 MXN de descuento exclusivo para nuevos clientes.",
            "$10 USD de descuento en tu primera compra.",
            "Descuento de $20 para clientes nuevos al registrarse."
        ]
    },
    {
        "categoria": "Compras Recurrentes",
        "descuentos": [
            "$50 MXN de descuento en tu pedido mensual al suscribirte a nuestro servicio.",
            "$5 USD de descuento en tus compras recurrentes.",
            "$100 MXN de descuento cada 3 compras realizadas."
        ]
    },
    {
        "categoria": "Categorías Específicas",
        "descuentos": [
            "$500 MXN de descuento en laptops y accesorios.",
            "$100 MXN de descuento en ropa y calzado.",
            "$300 MXN de descuento en electrodomésticos seleccionados.",
            "$50 USD de descuento en televisores OLED de más de 55 pulgadas."
        ]
    },
    {
        "categoria": "Eventos o Fechas Especiales",
        "descuentos": [
            "$250 MXN de descuento por el Día de las Madres.",
            "$100 USD de descuento por Black Friday.",
            "$150 MXN de descuento en productos navideños.",
            "$50 MXN de descuento en compras por el Día del Niño."
        ]
    },
    {
        "categoria": "Compra Mínima",
        "descuentos": [
            "$200 MXN de descuento en compras mayores a $1,500.",
            "$50 MXN de descuento al gastar $500 en productos seleccionados.",
            "$1,000 MXN de descuento en compras superiores a $10,000.",
            "$20 USD de descuento en órdenes superiores a $100."
        ]
    },
    {
        "categoria": "Productos Específicos",
        "descuentos": [
            "$50 MXN de descuento en la compra de un perfume.",
            "$100 MXN de descuento en la nueva línea de productos.",
            "$200 USD de descuento en la compra de smartphones seleccionados.",
            "$300 MXN de descuento en consolas de videojuegos."
        ]
    },
    {
        "categoria": "Personalizados",
        "descuentos": [
            "$50 MXN de descuento en los productos que tienes en tu carrito.",
            "$200 MXN de descuento en tus marcas favoritas.",
            "$100 MXN de descuento en artículos previamente comprados."
        ]
    },
    {
        "categoria": "Métodos de Pago",
        "descuentos": [
            "$100 MXN de descuento pagando con tarjeta de crédito.",
            "$150 MXN de descuento en compras pagadas con PayPal.",
            "$200 MXN de descuento al usar transferencia bancaria.",
            "$20 USD de descuento con tarjetas de débito participantes."
        ]
    },
    {
        "categoria": "Limitados por Tiempo",
        "descuentos": [
            "$50 MXN de descuento por las próximas 24 horas.",
            "$100 MXN de descuento durante el fin de semana.",
            "$300 MXN de descuento solo este viernes por la noche."
        ]
    },
    {
        "categoria": "Clientes Frecuentes",
        "descuentos": [
            "$200 MXN de descuento al superar 5 compras en el mes.",
            "$50 MXN de descuento adicional por ser cliente VIP.",
            "$100 MXN de descuento en agradecimiento por tu fidelidad."
        ]
    }
]

  public arrayPorcentaje:any = [
    {
        "categoria": "Generales",
        "descuentos": [
            "20% de descuento en toda la tienda este lunes.",
            "15% de descuento acumulable con otras promociones.",
            "10% de descuento en compras realizadas antes de las 12:00 PM.",
            "25% de descuento al suscribirte a nuestro boletín.",
            "30% de descuento en tu primera compra."
        ]
    },
    {
        "categoria": "Montos Mínimos",
        "descuentos": [
            "10% de descuento en compras mayores a $1,000.",
            "20% de descuento en órdenes superiores a $2,500.",
            "15% de descuento en compras mayores a $500, solo hoy."
        ]
    },
    {
        "categoria": "Categorías Específicas",
        "descuentos": [
            "20% de descuento en ropa de temporada.",
            "15% de descuento en calzado deportivo.",
            "25% de descuento en electrónicos seleccionados.",
            "30% de descuento en productos de belleza y cuidado personal."
        ]
    },
    {
        "categoria": "Temporales",
        "descuentos": [
            "10% de descuento solo durante 2 horas.",
            "20% de descuento en compras realizadas el fin de semana.",
            "15% de descuento este viernes por la noche."
        ]
    },
    {
        "categoria": "Eventos o Fechas Especiales",
        "descuentos": [
            "25% de descuento por el Día de las Madres.",
            "30% de descuento por Black Friday.",
            "20% de descuento en productos seleccionados por Navidad.",
            "15% de descuento en compras para el Día del Niño."
        ]
    },
    /* {
        "categoria": "Productos Específicos",
        "descuentos": [
            "10% de descuento en smartphones Samsung.",
            "20% de descuento en televisores OLED de 55 pulgadas.",
            "15% de descuento en consolas de videojuegos.",
            "25% de descuento en laptops y accesorios."
        ]
    }, */
    {
        "categoria": "Compras Recurrentes",
        "descuentos": [
            "10% de descuento en tu próxima compra si compras hoy.",
            "15% de descuento al realizar tu segunda compra en menos de 30 días.",
            "20% de descuento en tu pedido mensual al suscribirte a nuestro servicio de membresía."
        ]
    },
    {
        "categoria": "Clientes Nuevos",
        "descuentos": [
            "20% de descuento al registrarte por primera vez.",
            "15% de descuento en tu primera compra como cliente nuevo.",
            "25% de descuento exclusivo para nuevos usuarios."
        ]
    },
    {
        "categoria": "Compra de Múltiples Productos",
        "descuentos": [
            "10% de descuento al comprar 2 o más artículos.",
            "20% de descuento al adquirir 3 productos de la misma categoría.",
            "15% de descuento al combinar prendas de ropa y accesorios."
        ]
    },
    {
        "categoria": "Clientes Frecuentes",
        "descuentos": [
            "15% de descuento para clientes VIP.",
            "20% de descuento para quienes han realizado más de 5 compras.",
            "10% adicional en descuentos para nuestros miembros más leales."
        ]
    },
    {
        "categoria": "Personalizados",
        "descuentos": [
            "20% de descuento en productos que añadiste a tu carrito pero no compraste.",
            "10% de descuento en tus marcas favoritas.",
            "15% de descuento en las categorías que más compras."
        ]
    },
    {
        "categoria": "Métodos de Pago",
        "descuentos": [
            "10% de descuento al pagar con tarjeta de débito.",
            "15% de descuento en pagos con PayPal.",
            "20% de descuento exclusivo para compras con tarjeta de crédito VISA."
        ]
    }
]

 public arrayCupones:any = []

  constructor(private cdr: ChangeDetectorRef,private dateAdapter: DateAdapter<Date>,private paginatorIntl: MatPaginatorIntl,public localSt: LocalStorageService,private fb: FormBuilder,private _serviciosService:PeticionesService ) {
    // Assign the data to the data source for the table to render
    this.strConexion = this.localSt.retrieve('strConexion');
    this.minDate = new Date();
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
    this.fnIniciarForm();
    this.dateAdapter.setLocale('es-ES');
   }

  ngOnInit(): void {
    this.fnExtraerRegistros();
  
  }
  fnIniciarForm(){
    this.cuponForm = this.fb.group({
      strNombre: ['', [Validators.required, Validators.minLength(3)]], // Nombre
      strTipo: ['', [Validators.required]], // Tipo de descuento
      strValor: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1)]], // Valor (número decimal permitido)
      dblMontoMinimo: [{ value: null, disabled: false }], // Monto mínimo
      intCantidadMinima:  [{ value: null, disabled: false }], // Cantidad mínima
      strMetodoPago: '', // Método de pago
      strCodigoPromocional: ['', [Validators.required, Validators.minLength(3)],], // Código promocional
      strFechaInicio: ['', [Validators.required]], // Fecha inicio
      strFechaFin: ['', [Validators.required]], // Fecha fin
      boolActivo: true, // Activo (boolean)
    });

    this.cuponForm.get('dblMontoMinimo')?.valueChanges.subscribe(value => {
      console.log(value)
      if(value){
        this.cuponForm.get('intCantidadMinima')?.disable({ emitEvent: false });
      }else{
        this.cuponForm.get('intCantidadMinima')?.enable({ emitEvent: false });
      }

    });
    this.cuponForm.get('intCantidadMinima')?.valueChanges.subscribe(value => {
      console.log(value)
      if(value){
        this.cuponForm.get('dblMontoMinimo')?.disable({ emitEvent: false });
      }else{
        this.cuponForm.get('dblMontoMinimo')?.enable({ emitEvent: false });
      }
    });
  }

  ngAfterViewInit(){ 
  }

  fnTable(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fnExtraerRegistros(){
    let objenv = {
      strAccion: 'getCupones',
      strConexion:this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
      result => {
        this.arrayCupones = result
   /*      this.arrayCupones.forEach(item => {
          item.strFechaInicio_Cupon = this.convertirFecha(item.strFechaInicio_Cupon);
          item.strFechaFin_Cupon = this.convertirFecha(item.strFechaFin_Cupon);
        }); */
        console.log(result);
        this.dataSource = new MatTableDataSource(this.arrayCupones);
        this.fnTable();
       }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
   
  }

  onSubmit(): void {
    let f_cupon = this.cuponForm.value
    let findCupon = this.arrayCupones.find(o =>(o.strCodigo_Cupon?.replace(/\s+/g, '').toUpperCase() || '') === 
    (f_cupon.strCodigoPromocional?.replace(/\s+/g, '').toUpperCase() || ''))
    let fechaInicio = new Date(f_cupon.strFechaInicio);
    let fechaFin = new Date(f_cupon.strFechaFin);

      if(!findCupon && fechaFin.getTime() >= fechaInicio.getTime()){
        let objenv = {
          strAccion: 'insertCupon',
          strConexion:this.strConexion,
          strNombre_Cupon:f_cupon.strNombre,
          strTipo_Cupon:f_cupon.strTipo,
          dblValor_Cupon:f_cupon.strValor, 
          dblMontoMin_Cupon:f_cupon.dblMontoMinimo,
          dblCantidadMin_Cupon:f_cupon.intCantidadMinima,
          strCodigo_Cupon:f_cupon.strCodigoPromocional,
          strMetodoPago_Cupon:f_cupon.strMetodoPago,
          strFechaInicio_Cupon:f_cupon.strFechaInicio,
          strFechaFin_Cupon:f_cupon.strFechaFin,
          boolActivo_Cupon:f_cupon.boolActivo?1:0,
        }
      console.log(objenv)
      this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
        result => {
         
          Swal.fire(result[0].code=="200"?"Enviado Correctamente":"Error",result[0].strMensaje,result[0].code=="200"?"success":"error")
          if(result[0].code=="200"){
            this.btnCerrarMdl.nativeElement.click();
            this.fnExtraerRegistros();
            this.resetForm();
          }
         }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
      }else{
        Swal.fire("Error",fechaFin.getTime() < fechaInicio.getTime()?"La fecha fin: "+this.formatDate(f_cupon.strFechaFin)+" debe ser mayor o igual a la fecha inicio: "+this.formatDate(f_cupon.strFechaInicio):"Codigo promocional de cupón existente"+": "+f_cupon.strCodigoPromocional,"error")
         
      }
   
  }

  fncbBox(cupon:any){
    this.objCupon = cupon
    let fechaInicio = new Date(cupon.strFechaInicio_Cupon)
    let fechaFin =  new Date(cupon.strFechaFin_Cupon)

    fechaInicio.setDate(fechaInicio.getDate() + 1);
    fechaFin.setDate(fechaFin.getDate() + 1);

    this.strFechaInicio_Cupon = this.formatDate(fechaInicio);
    this.strFechaFin_Cupon =  this.formatDate(fechaFin);
    this.cupCheck = cupon?.boolActivo_Cupon ;
   
    console.log( this.objCupon)
  }

onFechaInicioChange(event:any,tipo:string): void {
  console.log(tipo)
  if(tipo == "inicio"){
    if (event) {
      let fecha =  new Date (event.value);
      let newdate = this.formatDate(fecha);
      this.strFechaInicio_Cupon = `${newdate}T00:00:00-06:00`;
    }
  }else{
    if (event) {
      let fecha = new Date (event.value);
      let newdate = this.formatDate(fecha);
      this.strFechaFin_Cupon = `${newdate}T00:00:00-06:00`  ;
    }
  }
  
}

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  fnActualizar(cupon:any){
    let objenv = {
      strAccion: 'setCupon',
      strConexion:this.strConexion,
      strFechaInicio_Cupon:this.strFechaInicio_Cupon,
      strFechaFin_Cupon:this.strFechaFin_Cupon,
      boolActivo_Cupon:this.cupCheck,
      dblCantidadMin_Cupon:cupon.dblCantidadMin_Cupon,
      intID_Cupon:cupon.intID_Cupon
    }
    console.log(objenv)
    this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
      result => {
        console.log(result);
        Swal.fire(result[0].code=="200"?"Enviado Correctamente":"Error",result[0].strMensaje,result[0].code=="200"?"success":"error")
        if(result[0].code=="200"){
          this.btnCerrarMdl.nativeElement.click();
          this.fnExtraerRegistros();
        }
       }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  

  convertirFecha(fechaStr: string): Date {
    // Separar la fecha y la hora
    const [fecha, hora] = fechaStr.split(' ');
    // Separar mes, día y año
    const [mes, dia, anio] = fecha.split('/');
    // Construir la cadena en formato ISO 8601 con la zona horaria -06:00
    const fechaISO = `${anio}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}T${hora}-06:00`;
    // Crear el objeto Date
    const fechaObj = new Date(fechaISO);
    return fechaObj;
  }

  toUpperCase(event: Event) {
    const control = this.cuponForm.get('strCodigoPromocional');
    if (control) {
      control.setValue(control.value.toUpperCase(), { emitEvent: false });
    }
  }
  fnResetForm() {
    this.cuponForm.reset({
      strNombre: '',
      strTipo: '',
      strValor: '',
      dblMontoMinimo: null,
      intCantidadMinima: null,
      strMetodoPago: '',
      strCodigoPromocional: '',
      strFechaInicio: '',
      strFechaFin: '',
      boolActivo: false,
    });
      // Limpia los estados de validación de todos los controles
  Object.keys(this.cuponForm.controls).forEach(key => {
    const control = this.cuponForm.get(key);
    if (control) {
      control.markAsPristine(); // Limpia el estado de "sucio"
      control.markAsUntouched(); // Limpia el estado de "tocado"
      control.updateValueAndValidity(); // Recalcula la validez del control
    }
  });
  this.cdr.detectChanges();
  }
  resetForm() {
    this.formDirective.resetForm();
    this.cuponForm.reset();
  }

  fnSetNombre(strNombre1:String){
    console.log(strNombre1)
    this.cuponForm.patchValue({strNombre: strNombre1});
    this.btnCerrarEdit.nativeElement.click()
  }
  fnArrayChange(tipo:String){
    this.arrayDescrip = [];
    this.arrayDescrip = tipo =="Porcentaje"?this.arrayPorcentaje:tipo =="Monto Fijo"?this.arrayMontoFijo:[]
    this.errorDesc = tipo =="Porcentaje"||tipo =="Monto Fijo"?false:true
  }
}
