
<div class="container">
  <div class="row">
    <div class="col-12 text-center">
        <h1 class="my-4 fw-bold   nunito">Pedidos <span class="titulo text-decoration-underline">Ecommerce</span></h1>
    </div>
  </div>

 <!-- <div class="row">
    <div class="col-6">
      <table border="1" style="width: 100%; border-collapse: collapse; text-align: left; margin-bottom: 1rem;font-size: 12px;">
        <thead>
          <tr>
            <th colspan="2" style="text-align: center;">Estatus aplazo</th>
          </tr>
          <tr>
            <th>Estado</th>
            <th>Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong><span class="badge text-bg-warning">New</span></strong></td>
            <td>Solicitud de préstamo es recibida por Aplazo - NO COMPLETADA - Este es el primer estado del préstamo</td>
          </tr>
          <tr>
            <td><strong><span class="badge text-bg-success">Activo</span></strong></td>
            <td>El préstamo está confirmado por Aplazo, lo que implica que el pedido está pagado usando Aplazo y debe ser confirmado en la plataforma del comerciante.</td>
          </tr>
          <tr>
            <td><strong> <span class="badge text-bg-secondary">No Confirmado</span></strong></td>
            <td>El préstamo no está confirmado, lo que significa que no está pagado y no debe ser confirmado en la plataforma del comerciante.</td>
          </tr>
          <tr>
            <td><strong><span class="badge text-bg-danger">Cancelado / Devuelto</span> </strong></td>
            <td>El comerciante solicitó que el préstamo fuera cancelado o reembolsado.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>-->
  <div class="row bg-white bordertop borderbott shadow-sm pb-3">
    <div class="col-12 bgtable py-1 d-flex bordertop">
        <span class="material-symbols-outlined">
            content_paste_search
        </span>
        <span class="fw-bold">
            Filtros
        </span>
    </div>
 
 
    <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" >
        <mat-form-field appearance="outline" class="bg-white rounded w-100">
          <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
          <mat-select [(ngModel)]="intPeriodo" class="" >
           <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
          </mat-select>
        </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center" >
        <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
            <mat-select [(ngModel)]="intEjercicio">
                <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                    {{e.ejercicio}}
                </mat-option> 
            </mat-select>
        </mat-form-field>
    </div>
   

    <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center">
        <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetPedidos()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
            <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
        </button>
    </div>
    
</div>
 <div class="row mt-3">
    <div class="col-12  bg-white py-2 shadow-sm" >
      <mat-form-field appearance="outline" class="bg-white rounded">
          <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Estatus</mat-label>
          <mat-select [(ngModel)]="strEstatus">
            <mat-option  value="">
              --Todos--
          </mat-option> 
              <mat-option *ngFor="let e of arrayEstatus" [value]="e.strEstatus">
                  {{e.strEstatus}}
              </mat-option> 
          </mat-select>
      </mat-form-field>
    </div>
    <div class="table-responsive px-0 mb-0">
      <div class="mat-elevation-z8 px-0 ">
        <table class="table table-bordered table-striped" mat-table [dataSource]="dataSource | filterEcommerce:strEstatus">
      
          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef class="px-2 text-center sticky-top"> ID </th>
            <td mat-cell *matCellDef="let element" class="px-2 align-middle fs13"> <b>{{element.intID_Pedido}} </b></td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef class="text-center sticky-top"> CLIENTE </th>
            <td mat-cell *matCellDef="let element" class="align-middle px-2 fs12"> {{element.strNombre}} {{element.strApellidos}}</td>
          </ng-container>
      
          <!-- Weight Column -->
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef class="text-center sticky-top"> IMPORTE </th>
            <td mat-cell *matCellDef="let element" class="px-2 align-middle fs12 text-end"> {{element.dblImporte | currency}} </td>
          </ng-container>
      
          <!-- Symbol Column -->
          <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef class="text-center sticky-top"> FECHA </th>
            <td mat-cell *matCellDef="let element" class="px-2 align-middle fs12"> {{element.strFecha}} </td>
          </ng-container>

           <!-- Symbol Column -->
           <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef class="text-center sticky-top"> TELEFÓNO </th>
            <td mat-cell *matCellDef="let element" class="px-2 align-middle fs12"> {{element.strTelefono}} </td>
          </ng-container>

           <!-- Symbol Column -->
           <ng-container matColumnDef="correo">
            <th mat-header-cell *matHeaderCellDef class="text-center sticky-top"> CORREO </th>
            <td mat-cell *matCellDef="let element" class="px-2 align-middle fs12"> {{element.strCorreo}} </td>
          </ng-container>
           <!-- Symbol Column -->
           <ng-container matColumnDef="detalle">
            <th mat-header-cell *matHeaderCellDef class="text-center sticky-top"> DETALLE </th>
            <td mat-cell *matCellDef="let element" class="px-2 align-middle align-middle">
              <button type="button" class="btn btn-info  d-flex"  data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnGetDetalle(element.intID_Pedido)">
                <i class="fa-regular fa-file-lines fs14 me-1"></i> <span class="ms-1 fs12">Detalle</span>  
              </button>
            </td>
          </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="eliminar">
              <th mat-header-cell *matHeaderCellDef class="px-2 text-center sticky-top"> ELIMINAR </th>
              <td mat-cell *matCellDef="let element" class="px-2 align-middle text-center">
                <button *ngIf="element.strEstatus_Pago =='PENDIENTE' " type="button" class="btn btn-danger"  (click)="fnEliminarPedido(element.intID_Pedido)">
                  <i class="fa-solid fa-trash-can fs14"></i>  
                </button></td>
            </ng-container>
  

              <!-- Symbol Column -->
              <ng-container matColumnDef="origen_pago" class="custom-width">
                <th mat-header-cell *matHeaderCellDef class="text-center px-2 sticky-top"> MEDIO DE PAGO </th>
                <td mat-cell *matCellDef="let element" class="align-middle text-center align-middle">  
                  <img src="assets/logos/logo-aplazo-color.svg" alt="Aplazo" class="aplazo-icon" *ngIf="element.strTipoPago =='APLAZO'">
                  <img src="assets/logos/mp_icon.jpg" alt="Aplazo" class="aplazo-icon" *ngIf="element.strTipoPago =='MERCADO_PAGO'">
                </td>
              </ng-container>

                <!-- Symbol Column -->
              <ng-container matColumnDef="estatus_pago">
                <th mat-header-cell *matHeaderCellDef class="text-center px-2 sticky-top"> ESTATUS PAGO </th>
                <td mat-cell *matCellDef="let element" class="text-center px-2 align-middle">  
                  <span class="badge text-bg-success est_pago" *ngIf="element.strEstatus_Pago =='PAGADO' "><i class="bi bi-check"></i> {{element.strEstatus_Pago}} </span>
                  <span class="badge text-bg-primary est_pago" *ngIf="element.strEstatus_Pago =='PENDIENTE' "><i class="bi bi-alarm"></i> {{element.strEstatus_Pago}} </span>
                  <span class="badge text-bg-warning est_pago" *ngIf="element.strEstatus_Pago =='PROCESO' "><i class="bi bi-arrow-down-up"></i> {{element.strEstatus_Pago}} </span>
                </td>
              </ng-container>
                   <!-- Symbol Column -->
                   <ng-container matColumnDef="referencia_pago" >
                    <th mat-header-cell *matHeaderCellDef class="text-center px-2 sticky-top"> REFERENCIA PAGO </th>
                    <td mat-cell *matCellDef="let element" class="text-center px-2 align-middle fs12"><b>{{element.strReferencia_Pago}} </b>  </td>
              </ng-container> 
           
              <!-- Symbol Column -->
              <ng-container matColumnDef="idPedidoIntel">
                <th mat-header-cell *matHeaderCellDef class="text-center px-2 sticky-top"> PEDIDO INTELISIS</th>
                <td mat-cell *matCellDef="let element" class="text-center px-2 align-middle"> 
                  <div class="d-flex flex-column py-2">



                    <span class="fs12"> 
                      <b>{{element.strMovID}}</b>
                    </span>
                    <span class="pb-1 fs12" [ngClass]="{'d-none':element.intIDMovimientoVenta == null||element.intIDMovimientoVenta == undefined ||element.intIDMovimientoVenta == ''}">
                      ({{element.intIDMovimientoVenta}}) 
                    </span>
                  
                    <span class="badge"
                      [ngClass]="{
                        'text-bg-danger': element.strEstatus_Intelisis === 'CANCELADO',
                        'text-bg-warning': element.strEstatus_Intelisis === 'PENDIENTE',
                        'text-bg-success': element.strEstatus_Intelisis === 'CONCLUIDO',
                        'text-bg-secondary': element.strEstatus_Intelisis === 'SINAFECTAR'
                      }">
                   
                      {{ element.strEstatus_Intelisis }}
                    </span>




                  </div>
                </td>
              </ng-container> 
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
    <div class="col-12 px-0 shadow-sm">
      <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
    
  </div>
      <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Detalle de Pedido {{objPedido.intID_Pedido}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #btnCerrarModalPedido ></button>
              </div>
              <div class="modal-body scrollable-body">
                <div class="table-fact  compact-table">
                  <h2 class="table-title">Información de Facturación</h2>
                  <table>
                    <tr>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                    </tr>
                    <tr>
                      
                     
                      <td class="tFactura">#Cliente</td>
                      <td colspan="3"> 
                        <div class="input-group mb-3">
                          <input type="text" class="form-control" placeholder="Escribe el número de cliente" aria-label="Escribe el número de cliente" aria-describedby="button-addon2" [(ngModel)]="strBuscarCte">
                          <button class="btn btn-outline-primary" type="button" id="button-addon2"(click)="fnBuscarCliente(strBuscarCte)">Buscar</button>
                        </div>
                        
                      </td>
                    </tr>
                    <tr>
                      <td class="tFactura">Nombre Intelisis</td>
                      <td colspan="3"> <b>{{objCte.strCliente}} </b> {{objCte.strNombreCliente}} </td> 
                    </tr>
                    <tr> 
                      <td class="tFactura">Razón Social</td>
                      <td colspan="3"> {{objPedido.strRazonSocial_f}} </td>
                    </tr>
                    <tr>
                      <td class="tFactura">Nombre</td>
                      <td>{{objPedido.strNombre_f}}  {{objPedido.strApellidos_f}} </td>
                      <td class="tFactura">RFC</td>
                      <td>{{objPedido.strRFC_f}} </td>
                     
                    </tr>
                    <tr>
                      <td class="tFactura">Uso CFDI</td>
                      <td>{{objPedido.strUsoCfdi_f}} </td>
                      <td class="tFactura">Régimen Fiscal </td>
                      <td>{{objPedido.strRegimenFiscal_f}} </td>
                     
                    </tr>
                    <tr>
                      <td class="tFactura">Código Postal</td>
                      <td>{{objPedido.strCodigoPostal_f}}</td>
                      <td class="tFactura">Dirección</td>
                      <td>{{objPedido.strDireccion_f}}, col. {{objPedido.strColonia_f}}.  {{objPedido.strCiudad_f}}. {{objPedido.strEstado_f}}, {{objPedido.strPais_f}}  </td>
                   
                      
                    </tr>
                    <tr>
                      <td class="tFactura">Método de Pago</td>
                      <td>{{objPedido.strMetodoPago_f}} </td>
                      <td class="tFactura">Email</td>
                      <td>{{objPedido.strCorreo}}</td>
                    </tr>
                 
                  </table>
                </div>
              <!--informacion de envio-->
              <div class="table-envio">
              <h2 class="table-title">Información de Envío</h2>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"> </th>
                      <th scope="col"> </th>
                      <th scope="col"> </th>
                      <th scope="col"> </th> 
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="tFactura">Nombre</td>
                      <td>{{objPedido.strNombre}}  {{objPedido.strApellidos}} </td>
                      <td class="tFactura">Teléfono</td>
                      <td>{{objPedido.strTelefono}} </td>
                    
                    </tr>
                    <tr colspan="3">
                      <td class="tFactura" >Dirección</td>
                      <td>{{objPedido.strDireccion}}, col. {{objPedido.strColonia}}. {{objPedido.strCiudad}}, {{objPedido.strEstado}}, {{objPedido.strPais}} </td>
                      <td class="tFactura">Código Postal</td>
                      <td>{{objPedido.strCodigoPostal}} </td>
                    </tr>
                  
                  </tbody>
                </table>
              </div>

            
                <!-- Detalle de artículos -->
                <div class="table-container">
                  <h2 class="table-title">Detalle de Artículos</h2>
                  <table>
                    <tr>
                      <th>Clave</th>
                      <th>Descripción</th>
                      <th>Subcuenta</th>
                      <th>Cantidad</th>
                      <th>Unidad</th>
                      <th>Precio Unitario</th>
                      <th>Subtotal</th>
                    </tr>
                    <tr *ngFor="let art of objPedido.arrayArticulos">
                      <td> <b>{{art.strArticulo}}</b> </td>
                      <td> {{art.strDescripcion1}} </td>
                      <td> {{art.strOpcion}}  {{art.strNombreOpcion}} </td>
                      <td>{{art.dblCantidad}}</td>
                      <td>{{art.strUnidad}}</td>
                      <td> {{art.dblPrecioLista | currency}}</td>
                      <td>{{(art.dblCantidad * art.dblPrecioLista) | currency}}</td>
                    </tr>
                    <tr class="total-row">
                      <td colspan="6" style="text-align: right;">Total</td>
                      <td> {{objPedido.total | currency}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button
                type="button"
                class="btn btn-primary loading-btn"
                (click)="setPedido()"
                [disabled]="isLoading"
                *ngIf="objPedido.intIDMovimientoVenta <= 0 || !objPedido.intIDMovimientoVenta || objPedido.strEstatus_Intelisis == 'CANCELADO'">
                <span *ngIf="!isLoading">Enviar A Intelisis</span>
                <div *ngIf="isLoading" class="loading-spinner"></div>
              </button>
              </div>
            </div>
          </div>
        </div>
 <!--fin--- Modal -->
</div>