<div class="container-fluid py-3 px-5 py-lg-4 bgPage">
    <!-- Informacion del cliente --> 
    <div class="row">
        <div class="col-12">
            <div class=" shadow cardrounded bg-white">
                <div class="bgcards ">
                    <div class="row ">
                        <div class="col-12 "> 
                            <h1 class="mb-0 py-2 ps-4">
                                Información del cliente
                            </h1>
                        </div>
                        <div class="col-12">
                            <hr class="m-0">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row py-3">
                      
                        <div class="col-12 col-md-4 d-flex justify-content-center align-items-center">
                            <mat-form-field class="col-10" appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input [(ngModel)]="objCliente.strNombre" matInput placeholder="{{strUsuario}}" [readonly]="ctePartner && ctePartner.intID != '' && ctePartner.intID != null ">
                                <span matSuffix class="material-symbols-outlined">
                                    badge
                                </span>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4 d-flex justify-content-center align-items-center">
                            <mat-form-field class="col-10 " appearance="outline">
                                <mat-label>Razon Social</mat-label>
                                <input [(ngModel)]="objCliente.strRazonSocial" matInput  [readonly]="ctePartner && ctePartner.intID != '' && ctePartner.intID != null " matInput  >
                                <span matSuffix class="material-symbols-outlined">
                                    account_circle
                                </span>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4 d-flex justify-content-center align-items-center">
                            <mat-form-field class="col-10" appearance="outline">
                                <mat-label>Contacto</mat-label>
                                <input [(ngModel)]="objCliente.strContacto" matInput placeholder="{{strUsuario}}" [readonly]="ctePartner && ctePartner.intID != '' && ctePartner.intID != null ">
                                <span matSuffix class="material-symbols-outlined">
                                    badge
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Termina informacion del cliente -->
    <div class="row pt-4 d-flex justify-content-between align-items-start">
        <!--  card de productos -->
        <div class="col-12 mb-4 mb-lg-0  col-lg-7">
            <div class="shadow cardrounded bg-white ">
                <div class="row bgcards mx-0">
                    <div class="col-12 text-center">
                        <h1 class="mb-0 pt-2 ps-4">
                            Productos
                        </h1>
                    </div>
                    <div class="col-12">
                    </div>
                    <div class="col-12">
                        <hr class="mb-0">
                    </div>
                </div>
                <div class="py-3 cuerpProductos row ">
                    <div *ngFor="let obj of arrayCarritoArts; index as ind;" class="row mt-2 ">
                        <div class="col-12">
                            <div class="row d-flex justify-content-center align-items-center position-relative ">
                                <div class="col-2 d-flex justify-content-center ">
                                    <img [attr.src]="'https://www.cleanclean.mx/imagenes/' + obj.strArticulo + '.JPG'"   class="w-75 mx-auto" alt="">
                                </div>
                                <div class="col-9">
                                    <div class="row">
                                        <div class="col">
                                            <span class="fs-7 fw-bold">
                                                {{obj.strArticulo}}
                                            </span>
                                            <span class="fs-7"> {{obj.strDescripcion1}}</span>
                                        </div>
                                    </div>
                                    <div class="row mt-2 mb-3">
                                        <div class="col-3">
                                            <div class="form-floating mb-3" id="inputCosto" #inputCosto>
                                                <input type="text" class="form-control costo" id="floatingInput"  [value]="obj.dblPrecioLista | currency"    (blur)="formatCurrency(obj)"
                                                (input)="updateValue($event,obj)" >
                                                <label for="floatingInput">Costo</label>
                                              </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-floating position-relative" id="inputPrecioSug" #inputPrecioSug>
                                                <input type="text" id="inputPrecioSugerido" #inputPrecioSugerido class="form-control precio ps-3" id="floatingPassword" [value]="'$'+obj.dblPrecioSugerido " (input)="updateValue_sugerido($event,obj)">
                                                <label for="floatingPassword">Precio</label>
                                               
                                              </div>
                                              

                                        </div>
                                        <div class="col-3">
                                            <div class="form-floating " id="inputGanancia" #inputGanancia> 
                                                <input type="text" class="form-control ganancia" id="floatingPassword" [value]="(obj.dblPrecioSugerido  -obj.dblPrecioLista)* obj.dblCantidad | currency"  >
                                                <label for="floatingPassword">Ganancia</label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="btn-group  mx-3  "  role="group" aria-label="Basic example">
                                                <button type="button"
                                                    [ngClass]="{'text-primary':obj.dblCantidad>1,'text-black-50':obj.dblCantidad<=1}"
                                                    (click)="fnbuttomGroup(obj,'-')"
                                                    class="btn border border-end-0 fw-bolder  py-0 px-2 btn1"><span
                                                        class="fs-3">-</span></button>
                                                <input type="number" value="0" (keydown)="restric($event)" (input)="restrictInput($event, obj)"   type="text" [value]="obj.dblCantidad"
                                                    class="form-control text-center rounded-0 border-start-0 border-end-0 p-0 fs-7">
                                                <button type="button" (click)="fnbuttomGroup(obj,'+')"
                                                    class="btn border  border-start-0 fw-bolder text-primary py-0 px-2 btn1">+</button>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="px-3 d-flex flex-column rounded bg-light shadow-sm ">
                                                <span class="mx-auto fs-7">Precio total</span>
                                                <span class="mx-auto fw-bold fs-5">{{(obj.dblPrecioSugerido *
                                                    obj.dblCantidad)|currency}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3 mt-2 d-flex justify-content-between align-items-center"> 
                                        <div class="col">
                                            <div class="col-12  d-flex align-items-center px-0">
                                                <span class="fw-bolder mb-0 fs-7 me-2"
                                                    [hidden]="obj.strTipoOpcion == 'No'">
                                                    Opcion:
                                                </span>
                                                <div class="col-3 me-2" [hidden]="obj.strTipoOpcion == 'No'">
                                                    <select class="form-select shadow-sm" [(ngModel)]="obj.strOpcion"
                                                        aria-label=".form-select-lg example"   >
                                                        <option *ngFor="let item of obj.arrayOpciones; let i = index;"
                                                            [ngValue]="item.strOpcion">
                                                            <span class="me-2"> <b>
                                                                    {{item.strOpcion}}</b></span>&nbsp;&nbsp;
                                                            <span>
                                                                {{item.strNombreOpcion}}
                                                            </span>
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class=" px-3 border-start border-end">
                                                    <button matTooltip="Eliminar Articulo"
                                                        (click)="fnDeleteCar(ind,obj)"
                                                        class="btn btn-danger fs-7 p-2 shadow-sm d-flex align-items-center">
                                                        <i class="bi bi-trash"></i>
                                                    </button>
                                                </div>
                                                <div class="px-3 me-auto border-end">
                                                   <!-- <button matTooltip="Agregar observación" data-bs-toggle="modal"
                                                        data-bs-target="#modalArticulo" (click)="fnmodal(obj,ind)"
                                                        class="btn btn-primary  fs-7 shadow  p-2 d-flex align-items-center">
                                                        <i class="bi bi-pin"></i>
                                                    </button>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr class="mt-0">
                    </div>
                    <div class="col-12 d-flex justify-content-between">
                        <button (click)="redirectToCarrito();" id="btnContinuarCompra" #btnContinuarCompra
                            class="btn btn-primary mb-3 mx-3 d-flex align-items-center">
                            <span class="material-symbols-outlined">
                                arrow_back_ios_new
                            </span>
                            Continuar comprando
                        </button>
                        <button (click)="fnborrarCarrito();" class="btn btn-danger mb-3 mx-3 d-flex align-items-center">
                            <span class="material-symbols-outlined">
                                delete
                            </span>Vaciar carrito
                        </button>
                    </div>
                </div>
                <div class="row">

                </div>
            </div>
        </div>

        <!--  card de Resumen -->
        <div class="col-12 col-lg-4">
            <div class="cardrounded bg-white shadow">
                <div class="bgcards ">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h1 class="mb-0 pt-2 ps-4">
                                Sub total
                            </h1>
                        </div>
                        <div class="col-12">
                            <hr class="mb-0">
                        </div>
                    </div>
                </div>
                <div class=" mt-4 p-0">
                    <div class="row d-flex justify-content-center">
                        <div class="col-10">
                            <div class="row rounded shadow-sm mb-3 py-2 bg-light d-flex align-items-center">
                                <div class="col-5">
                                    <h3 class="mb-0"> Sub total</h3>
                                </div>
                                <div class="col-7 text-end">
                                    <span class="fw-bold fs-6">
                                        {{fnSubTotal()|currency}}
                                    </span>
                                </div>
                            </div>
                            <div class="row rounded shadow-sm my-2 py-2 bg-light d-flex align-items-center">
                                <div class="col-5">
                                    <h3 *ngIf="strConexion=='COMERCIAL'" class="mb-0">
                                        Iva(16%)
                                    </h3>
                                    <h3 *ngIf="strConexion=='DOMINICANA'" class="mb-0">
                                        ITBIS(18%)
                                    </h3>
                                </div>
                                <div class="col-7 text-end">
                                    <span class="fw-bold fs-6">
                                        {{fnSubiva()|currency}}
                                    </span>
                                </div>
                            </div>
                            <div class="row rounded my-3 py-2 shadow-sm bg-light d-flex align-items-center">
                                <div class="col-4">
                                    <h2 class="mb-0 fw-bolder">
                                        Total:
                                    </h2>
                                </div>
                                <div class="col-8 text-end">
                                    <span class="fw-bold fs-5">
                                        {{fnTotal()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                            *ngIf="strConexion=='DOMINICANA'">DOP</span>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr class="">
                    </div>
                    <div class="col-12 d-flex  flex-column ">
                        <button [disabled]=" fnValida_Precio_sugerido()" #btnPdf id="btnPdf" matTooltip="Generar pdf" (click)="fncreatepdf();"
                            class="mx-auto  col-8 btn btn-secondary mb-3 mx-3 d-flex align-items-center justify-content-center shadow">
                            <i class="bi bi-file-earmark-pdf me-3"></i> Generar Pdf
                        </button>

                        <!--&& !boolModoPlantilla-->
                        <button [disabled]=" fnValida_Precio_sugerido()"      #btnCotizacion id="btnCotizacion"                   
                            matTooltip="Generar cotización" (click)="fnGuardarCotizacion()"
                            class="mx-auto col-8 btn text-white btn-success mb-3 mx-3 d-flex align-items-center justify-content-center shadow">
                            <i class="bi bi-save me-2"></i>    Guardar Cotización  
                        </button>
 

                    </div>
                </div>
            </div>
        </div>
        <!-- fin card de Resumen -->

    </div>

</div>
            
<div  [hidden]="!vartri"> <!--[hidden]="!vartri"-->
    <div class="container " #pdfTableFiniquito>   

        <div class="row  p-1 mb-4 d-flex justify-content-center align-items-stretch">
            <div class="col-8 d-flex flex-column justify-content-between h-100">
                <div class="row">
                    <div class="col">
                        <h4 class="fs-1 mb-0 mt-4">Propuesta Comercial</h4> 
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h3 class="fs-5 mb-0 mt-3">Fecha: {{currentDate}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h3 class="fs-5 mb-0 mt-2">Con atención a: {{objCliente.strRazonSocial}}</h3>
                    </div>
                </div>
            </div>
            <div class="col-4 d-flex  justify-content-center ">
                <img src="{{strUrlLogo}}" class="rounded shadow-sm" alt="" style="width: 60% !important;">
            </div>             
        </div>

        <div class="row  cardrounded bg-whitees shadow" *ngIf="flagProspecto && conp">
            <div class="mx-auto mt-2 text-center col-12 fs-5 fw-bold py-1  ">
                Cliente
                <div class="row d-flex justify-content-center">
                    <div class="col-10">
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row mb-3 d-flex justify-content-between ">
                <div class="col-6 d-flex">
                    <div style="width: 25%;" class="fw-bold fs-7">Con atención a:</div>
                    <div style="width: 75%;" class="fs-8">{{strProstectro}}</div>
                </div>
                <div class="col-6 d-flex">
                    <div style="width: 25%;" class="fw-bold fs-7">Empresa:</div>
                    <div style="width: 75%;" class="fs-8"> {{strEmpresaProspecto}}</div>
                </div>
            </div>
        </div>

        <div class="row  cardrounded bg-whitees shadow" *ngIf="objCliente.ClienteSeleccionado">
            <div class="mx-auto mt-2 text-center col-12 fs-7 fw-bold py-1  ">
               Información del Cliente
               <hr class="mt-1">
               
            </div>
            <div class="row  mb-3  d-flex justify-content-between ">
                <div class="col-6 d-flex">
                    <div style="width: 30%;" class="fw-bold fs-7">Con atención a:</div>
                    <div style="width: 75%;" class="fs-8"> <b>{{objCliente.strCliente}}</b> {{objCliente.strNombre}}</div>
                </div>
                <div class="col-6 d-flex">
                    <div style="width: 25%;" class="fw-bold fs-7">Dirección:</div>
                    <div style="width: 75%;" class="fs-8"> {{objCliente.strDireccion}}</div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <table class="table table-bordered  ">
                    <thead class="bgTablehead text-white">
                        <tr>
                            <th class="text-center fs-7 border-top bgtableHead">Cantidad</th>
                            <th class="text-center fs-7 border-top bgtableHead">Producto</th>
                            <th class="text-center fs-7 border-top bgtableHead">Descripcion</th>
                            <th class="text-center fs-7 text-nowrap border-top bgtableHead">Precio</th>
                            <th class="text-center fs-7 border-top bgtableHead">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  *ngFor="let x of arrayCarritoArts;let ind = index">
                            <td class="text-center align-middle fs-7" [ngClass]="{'bgtablepdf':ind % 2==0}"> {{x.dblCantidad}}</td>
                            <td class="text-center" style="width: 150px;" [ngClass]="{'bgtablepdf':ind % 2==0}">
                                <div>
                                    <!-- <img [src]="x.base64Image"  class="w-75 mx-auto" alt=""> -->
                                    <img [src]="'https://www.ecodeli.com/img/PaginaWeb/' + x.strArticulo + '.JPG'"
                                        class="w-50 mx-auto" alt="">
                                </div>
                            </td>
                            <td class="text-start  fs-8 " [ngClass]="{'bgtablepdf':ind % 2==0}">
                                {{x.strDescripcion1}}. {{x.strDescripcion}}
                                <div class="fw-bold mt-1" *ngIf="x.strTipoOpcion=='Si'">
                                    Opcion:  {{ getNombreOpcion(x) }} <!-- {{x.strNombreOpcion}} -->
                                </div>
                                <div class="fw-bold mt-1 ">
                                    Clave: {{x.strArticulo}}  
                                    
                                </div>
                            </td>
                            <td class="text-end align-middle fs-7 " [ngClass]="{'bgtablepdf':ind % 2==0}">{{x.dblPrecioSugerido|currency}}</td>
                            <td class="text-end align-middle fs-7 fw-bold" [ngClass]="{'bgtablepdf':ind % 2==0}">{{x.dblPrecioSugerido * x.dblCantidad|currency}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-4 avoid-break">
            <div class="col-12 d-flex justify-content-between">
                <div class="ms-auto col-4 texto_p">
                 <!--    PRECIOS SUJETOS A PREVIA AUTORIZACIÓN.
                    ESTE ES UN DOCUMENTO NO OFICIAL.
                    VALIDO POR 1 DÍA A PARTIR DE LA EMISIÓN DE DOCUMENTO. -->
                </div>
                <div class="col-4 ms-auto">
                    <div class="row">
                        <div class="col-6 text-end text-nowrap fs-7 fw-bold">
                            SUB TOTAL:
                        </div>
                        <div class="col-5 text-nowrap text-end fs-7 fw-bold me-auto">
                            {{ fnSubTotal()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                *ngIf="strConexion=='DOMINICANA'">DOP</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-end text-nowrap fs-7 fw-bold">
                            <span *ngIf="strConexion=='COMERCIAL'">IVA 16%:</span><span
                                *ngIf="strConexion=='DOMINICANA'">ITBIS 18%:</span>
                        </div>
                        <div class="col-5 text-nowrap text-end fs-7 fw-bold me-auto">
                            {{ fnSubiva()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                *ngIf="strConexion=='DOMINICANA'">DOP</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-end text-nowrap fs-7 fw-bold">
                            TOTAL:
                        </div>
                        <div class="col-5 text-nowrap text-end fs-7 fw-bold me-auto">
                            {{fnTotal()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                *ngIf="strConexion=='DOMINICANA'">DOP</span>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="row mt-3">
            <div class="mx-auto col-6 ">
                <div class="col-12 text-center">
                    ATENTAMENTE
                </div>
                <div class="pt-3 mx-auto col-9">
                    <hr>
                </div>
                <div class="pt-2 mx-auto text-center col-9">
                    {{data_empresa?.strNombreComercial}}
                </div>
                <div class="pt-1 mx-auto text-center col-9">
                    {{data_empresa?.streMail1}}
                </div>
                
            </div>
        </div>
    </div>
</div>