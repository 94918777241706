<!-- tabla-articulos.component.html -->

<div class="container mt-5">
    
        <h2>Detalle de Solicitud {{intIDComodato}}</h2> 

        <div *ngIf="boolspiner" class=" row table-custom header2 justify-content-evenly ">
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!boolspiner">
    <div   class=" row table-custom header2 justify-content-evenly ">
        
           
                    <div class="col-12 col-md-12   borderbott bordertop  ">
                      <div class="col-12 table-responsive borderbott bordertop">
                        <table class="table ">
                          <thead>
                            <tr>
                                <td colspan="4" class="header3"> 
                                     
                                </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-regular fa-user me-1"></i> Agente Solicitante:
                              </td>
                              <td class="ps-0 fs14 fw-bold ">{{objInfo.strAgente}}</td>
                              <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-regular fa-user me-1"></i> Nombre Agente:
                              </td>
                              <td class="ps-0 fs14 fw-bold "   >{{objInfo.strAgenteNombre}}</td>
                         
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-regular fa-circle-user me-1"></i>Cliente:</td>
                              <td class="ps-0 fs14 fw-bold ">
                                <span class=" colorcte">{{objInfo.strCliente}}</span> {{objInfo.strNombreCliente}}
                              </td>
                           

                              <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-regular fa-circle-user me-1"></i>Familia:</td>
                              <td class="ps-0 fs14 fw-bold ">{{objInfo.strFamilia}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start labeld"> 
                                  <i class="fa-regular fa-calendar me-1"></i>Fecha de Instalación:</td>
                              <td class="ps-0 fs14 fw-bold ">
                                {{objInfo.strFechaInstalacion}}
                              </td>
                              <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-solid fa-id-card me-1"></i>Dirigirse Con:</td>
                              <td class="ps-0 fs14 fw-bold ">
                                {{objInfo.strDirigiraCon}}
                              </td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start labeld">
                                <i class="fa-regular fa-clock me-1"></i> Horario Entrada:</td>
                              <td class="ps-0 fs14 fw-bold ">{{objInfo.strHorarioEntrada}}</td>
                              <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-regular fa-clock me-1"></i> Horario Salida:</td>
                              <td class="ps-0 fs14 fw-bold ">{{objInfo.strHorarioSalida}}</td>
                            </tr>
                             
                            <tr>
                                <td class="fw-bold bg1 text-nowrap text-start labeld">
                                    <i class="fa-solid fa-calendar-week me-1"></i> Días Laborales:</td>
                                <td class="ps-0 fs14 fw-bold ">{{objInfo.strEstado}}</td>
                                <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-regular fa-newspaper me-1"></i> Tipo de Solicitud:</td>
                                <td class="ps-0 fs16 fw-bold ">
                                    <span class="badge text-bg-secondary color1">{{objInfo.strTipoSolicitud}}</span>
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-solid fa-city me-1"></i>Ciudad:</td>
                                <td class="ps-0 fs14 fw-bold ">{{objInfo.strCiudad}}</td>
                                <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-solid fa-location-dot me-1"></i> Dirección:</td>
                                <td class="ps-0 fs14 fw-bold ">{{objInfo.strDomicilio}} -{{objInfo.strSucursal}}</td>
                              </tr>
                              
                              <tr>
                                <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-regular fa-comments me-1"></i> Observaciones:</td>
                                <td class="ps-0 fs14 fw-bold ">{{objInfo.strObservaciones}}</td>
                                <td class="fw-bold bg1 text-nowrap text-start labeld" ><i class="fa-solid fa-scroll me-1"></i> Carta Solicitud:</td>
                                <td class="ps-0  fw-bold  text-primary"> 
                                  <a href="https://www.ecodeli.com/{{objInfo.strAdjuntoSolicitud}}"  target="_blank" download="https://www.ecodeli.com/{{objInfo.strAdjuntoSolicitud}}" title="{{objInfo.strAdjuntoSolicitud}}"><i class="fa-solid fa-file-arrow-down  text-primary" style="font-size: 2em;"></i> </a></td>
                              </tr>
                              <tr>
                                <td class="fw-bold bg1 text-nowrap text-start labeld"><i class="fa-solid fa-signal me-1"></i>Estatus:</td>
                                <td class="ps-0 fs14 fw-bold "> 
                                  
                                  <span class="badge " [ngClass]="{
                                    
                                    'text-bg-danger': objInfo.strEstatus === 'RECHAZADO',
                                    'text-bg-info': objInfo.strEstatus === 'PENDIENTE',
                                    'text-bg-success': objInfo.strEstatus === 'AUTORIZADO'
                                  }">{{objInfo.strEstatus}}</span>
                                </td>
                                <td class="fw-bold bg1 text-nowrap text-start labeld" ><i class="fa-solid fa-scroll me-1"></i> Contrato:</td>
                                <td class="ps-0  fw-bold  text-primary"> 
                                  <a href="https://www.ecodeli.com/PDFContratosComodatos/ContratoComodato_{{intIDComodato}}.pdf"  target="_blank" download="https://www.ecodeli.com/PDFContratosComodatos/ContratoComodato_{{intIDComodato}}.pdf"
                                   title="ContratoComodato_{{intIDComodato}}"><i class="fa-solid fa-file-arrow-down  text-primary" style="font-size: 2em;"></i> </a></td>
                                 </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
           
            
          
         
        
    </div>
    <div class="row">
        <div class="table-responsive mt-4  table-custom">
        <table class="table table-bordered table-hover">
            <thead class="table-custom header-color">
            <tr  >
                <th  class=" header-color">Artículo</th>
                <th class=" header-color">Opción</th>
                <th class=" header-color">Descripción</th>
                <th  class=" header-color"> Unidad</th>
                <th class=" header-color">Precio</th>
                <th  class=" header-color" >Cantidad</th>
                <th class="actions  header-color">Acciones</th>
                <th  class=" header-color" >Existencias</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let art of arrayArticulos">
                <td class="highlight-articulo">{{art.strArticulo}}</td>
                <td><b>{{art.strOpcion}}</b> {{art.strNombreOpcion}}</td>
                <td>{{art.strDescripcion1}}</td>
                <td>{{art.strUnidad}}</td>
                <td class="text-right">{{art.dblPrecio |   currency:'USD':'symbol':'1.2-2'}}</td>
                <td class="highlight-cantidad text-right">{{art.dblCantidadDespachador}}</td>
                <td class="actions">
                <!-- Button trigger modal -->
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnGetDetalleArt(art)">
                        Ver Respuestas
                    </button>
            
                </td>
                <td class="highlight-existencias  text-right"><a data-bs-toggle="modal" data-bs-target="#existenciaModal" (click)="fnGetDisponible(art)"> {{art.dblDisponible | number:'1.2-2'}} </a></td>
            </tr>
            <!-- Agrega más filas aquí -->
            </tbody>
        </table>
        </div>
    </div>   
  
  
    <div class="row" *ngIf="spinnerAut">
      <div class="col-12 d-flex justify-content-center">
        <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
      </div>
    </div>
    <div class="row  justify-content-evenly " *ngIf="objInfo.strEstatus =='PENDIENTE' && boolMostrarBoton">
        <div class="col-6">
          <button *ngIf="boolAutorizar && boolMostrarAceptar" type="button" class="btn btn-success me-2" (click)="fnAutorizar()"><i class="fa-solid fa-circle-check"></i> Aceptar</button>
          <button  *ngIf="boolRechazar && boolMostrarAceptar" type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#rechazadoModal"><i class="fa-solid fa-circle-xmark"></i> Rechazar</button></div>
      
        
    </div>
    <div class="row  justify-content-evenly " *ngIf="objInfo.strEstatus =='RECHAZADO' ">
      <span class="badge text-bg-danger " style="font-size: 2em;">Motivo de Rechazo: {{objInfo.strMotivoRechazo}}</span>
    </div>
    <div class="row  justify-content-evenly " *ngIf="objInfo.strEstatus =='AUTORIZADO' ">
      <button   type="button" class="btn btn-secondary me-2" (click)="fncreatepdf()"><i class="fa-solid fa-circle-check"></i> Regenerar Contrato</button>
    </div>
    

    <!-- =================================================================================================== -->
<!-- =====================================Generar pdf====================================================== -->
<!-- =================================================================================================== -->

      <!--   <button class="btn btn-info " ng-click="fncreatepdf()">Generar PDF</button> -->
      <div   class="d-none"  ><!-- class="d-none" -->
        <div class="container" id="pdfTableFiniquito">
            <div class="row exPdfBordePrincipal p-1 mb-4 d-flex justify-content-center">
                <div class="row exPdfBordePrincipal p-1 ">
                    <div class="col-4 d-flex align-items-center">
                     <img src="img/ecodeli.png" class="w-75" alt="">
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-center">
                        <span class=" mb-0 clstitPDF fs-6">CONTRATO DE COMODATOS</span>
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-center">
                        <div class="row">
                            <div class="col-12 text-center text-dark fs10"><span class="fw-bold ">CÓDIGO:</span> ATC-RC-015</div>
                            <div class="col-12 text-center text-dark fs10"><span class="fw-bold">VERSIÓN:</span> 07</div>
                            <div class="col-12 text-center text-dark fs10"><span class="fw-bold">FECHA DE ÚLTIMA REVISIÓN:</span> 24/01/2024</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-2">
                <div class="col-12">
                    <span class="fs8 text-dark">
                        EL PRESENTE CONTRATO DE COMODATO QUE CELEBRAN POR UNA PARTE ECODELI COMERCIAL S.A. DE C.V. EN LO SUCESIVO "LA COMODANTE", Y POR LA OTRA PARTE:
                        <span class="fs10"><b>{{objComodatoRenovacion.strNombreCliente}}</b>' EL COMODATARIO'</span>, REPRESENTADA EN ESTE ACTO POR: {{datosArrayPDF[0].strContacto| uppercase }} CON DOMICILIO FISCAL: <span class="fs12"> {{datosArrayPDF[0].strDomicilioFiscal| uppercase }} 
                        
                         </span>  y con DOMICILIO DE ENTREGA:  <span class="fs12"> {{datosArrayPDF[0].strDomicilio| uppercase }} </span>. AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS:
                    </span>
                </div>
            </div>
            <div class="row my-1">
                <div class="col-12 text-center bgtit py-2 fw-bold ">
                    DECLARACIONES
                </div>
            </div>
            <div class="row my-1">
                <div class="col-12 text-start py-2 d-flex flex-column txtdark">
                    <span class="fs10">
                        I.- Declara EL COMODANTE por medio de su representante que:
                    </span>
                    <span class="ms-3 fs10">
                        a) Es una sociedad legalmente constituida conforme a la legislación mercantil mexicana.
                    </span>
                    <span class="ms-3 fs10">
                        b) Que su representante cuenta con las facultades necesarias para celebrar el presente contrato, y que no le han sido revocadas ni restringidas de forma alguna.
                    </span>
                    <span class="ms-3 mb-2 fs10">
                        c) Que fabrica y vende una serie de productos que requieren de dispositivos 'Dosificadores' para su consumo, el cual se hace en el inmueble que el COMODATARIO posee o
                        arrienda de tercera persona.
                    </span>
                    <span class="fs10">
                        II.- Declara el COMODATARIO que:
                    </span>
                    <span class="ms-3 fs10">
                        a) Es una persona con personalidad jurádica propia y legalmente facultada para celebrar contratos  </span>
                    <span class="ms-3 fs10">
                        b) Que su representante en este acto cuenta con las facultades necesarias para celebrar el presente contrato.
                        </span>
                    <span class="ms-3 fs10">
                        c) Que adquiere un consumo en el o los inmuebles que ocupa los productos que fabrica o vende de la COMODANTE, para lo cual desea recibir el comodato los dispositivos
                        'Dosificadores' que la COMODANTE ofrece para el suministro al usuario de dichos productos
                    </span>
                    <span class="mt-2 fs10">
                    Ambas partes declaran que no existe dolo, mala fe o cualquier otro vicio que pudiera invalidar parcial o totalmente el presente contrato de acuerdo a las siguientes.
                    </span>
                </div>
            </div>
            <div class="row my-1">
                <div class="col-12 text-center bgtit py-2 fw-bold ">
                    CLAUSULAS
                </div>
                <div class="col-12  text-start ">
                    <ul class="fs10 text-dark">
                        <li class="mb-1 mt-3">
                           <span class="clstitPDF fw-bold">PRIMERA:</span>  LA COMODANTE entregará en comodato al COMODATARIO los 'Dosificadores y/o Despachadores' necesarios para suministrar el producto adquirido de la
                            COMODANTE al usuario de dicho producto, quedando constancia en la Orden de Instalación a entregarse en el momento de la instalación de los mismos.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">SEGUNDA:</span> LA COMODANTE instalará los 'Dosificadores y/o Despachadores' en el o los inmuebles que EL COMODATARIO ocupa, a través de personas designadas por ella
                            o por sus distribuidores.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">TERCERA:</span> El COMODATARIO se obliga a mantener en buen estado los 'Dosificadores y/o Despachadores', responsabilizándose por los daños que sufran para el caso
                              de vandalismo, uso inadecuado de los mismo e incluso robo en cuyos casos cubrirá a LA COMODANTE el valor de los mismos de acuerdo a lo pactado en este contrato dentro de los 10 días naturales siguientes
                               a que se detecte alguno de estos supuestos, exceptuándose el desgaste debido al uso normal y adecuado de los mismos, en este último caso LA COMODANTE reparará o reemplazará los 'Dosificadores y/o Despachadores’ que se encuentren deteriorados por el uso normal.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">CUARTA:</span> El COMODATARIO se obliga expresamente a destinar los ' Dosificadores y/o Despachadores ' exclusivamente para ser usados con el producto adquirido de
                            COMODANTE, siendo el caso contrario causa de rescisión del presente contrato.
                        </li>
                        <li class="mb-1">
                            <span class="clstitPDF fw-bold"> QUINTA:</span> El COMODATARIO se obliga a permitir la inspección de los 'Dosificadores y/o Despachadores' por parte delCOMODANTE, de sus distribuidores o subdistribuidores de éstos, en cualquier momento, durante días y horas hábiles, para verificar su buen estado y con productos de la comodataria.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">SEXTA:</span> La vigencia de este contrato será por 12 (doce) meses forzosos como máximo, una vez terminado dicho plazo el contrato seguirá vigente por tiempo indeterminado y
                            en caso de que alguna de las partes desee dar por terminado una vez concluido los 12 (doce) meses forzosos, dará aviso por escrito a la contraparte con 30 (treinta) días
                            naturales de anticipación.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">SÉPTIMA:</span> Cuando cualquiera de las partes, en los términos de la cláusula anterior o por rescisión, dé por terminado el presente contrato, EL COMODATARIO devolverá a
                            LA COMODANTE
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-10">
                                    <table class="table table-bordered mt-4">
                                        <thead>
                                            <tr class="bgtitable">
                                                <th class="text-center">Cant.</th>
                                                <th class="text-center">Producto</th>
                                                <th class="text-center">Descripción</th>
                                                <th class="text-center text-nowrap">Precio Unitario</th>
                                                <th class="text-center">Importe</th>
                                                <th class="text-center">Consumo Comprometido Mensual</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            <tr *ngFor="let art of datosArrayPDF" >
                                                <td>{{art.dblCantidadDespachador}}</td>
                                                <td>
                                                    <div class=" text-center roundedBordestop bg-main " style="width: 70px;">
                                                        <span class="fs10 fw-bold text-white">{{art.strArticulo}}</span>  
                                                    </div>
                                                    <img src="https://www.ecodeli.com/img/PaginaWeb/{{art.strArticulo}}.JPG" class="bg-white roundedBordesbottom" style="width: 70px;" alt="">
                                                </td>
                                                <td class="align-middle">{{art.strDescripcion1}}</td>
                                                <td>{{art.dblPrecio|   currency:'USD':'symbol':'1.2-2'}}</td>
                                                <td>{{(art.dblCantidadDespachador * art.dblPrecio )|   currency:'USD':'symbol':'1.2-2'}}</td>
                                                <td class="align-middle">
                                                    <div class="fw-bold mb-2">
                                                        <span class="fw-bold">{{art.strConsumible}} </span> <span class="fw-normal">{{art.strNombreConsumible}}</span>
                                                    </div>
                                                    <div class="fw-bold ">
                                                        Precio: <span class="fw-normal">{{art.dblPrecioConsumible|   currency:'USD':'symbol':'1.2-2'}}</span>
                                                    </div>
                                                    <div class="fw-bold mt-2">
                                                        <span class="text-nowrap">Cantidad: </span> <span class="fw-normal">{{art.dblCantidadPrometida}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 mt-3 text-end fs12">
                                    Subtotal: <span class="fw-bold">{{SubtotalPDF|   currency:'USD':'symbol':'1.2-2'}}</span>
                                </div>
                                <div class="col-12 my-1 text-end fs12">
                                    IVA 16%: <span class="fw-bold">{{ivaPDF|   currency:'USD':'symbol':'1.2-2'}}</span>
                                </div>
                                <div class="col-12 mb-2 text-end fs12">
                                    Importe Total: <span class="fw-bold">{{ImportetotalPDF|   currency:'USD':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">OCTAVA:</span>LA COMODANTE retirará los 'Dosificadores y/o Despachadores' a la terminación del presente contrato por su cuenta, y por cuenta del COMODATARIO en caso de
                                rescisión. El COMODATARIO renuncia expresamente en todo caso a cualquier acción en contra de LA COMODANTE por la reparación o resane de las huellas, marcas o
                                vestigios que el retiro de los 'Dosificadores' deje en el inmueble.
                        </li>
                        <li class="mb-1">
                            <span class="clstitPDF fw-bold">NOVENA:</span>Para el caso de que EL COMODATARIO:


                             <div class="col-12 text-start py-2 d-flex flex-column txtdark">
                                <span class="fs10">
                                1).- De por terminado el presente contrato con anterioridad a la vigencia mínima pactada en la cláusula sexta de este contrato;
                                </span>
                                <span class="ms-3 fs10">
                                2).- Deje de adquirir los productos suministrados (consumibles) por LA COMODANTE para ser aplicados con los "Dosificadores y/o Despachadores" durante la citada vigencia mínima, y
                                </span>
                                <span class="ms-3 mb-2 fs10">
                                3).- Desinstale sin aviso y autorización de LA COMODANTE los "Dosificadores y/o Despachadores" dados en comodato durante la citada vigencia mínima.
                                </span>
                            </div>
                            <div class="col-12 my-2">
                                EL COMODATARIO se obliga, a su elección, a pagar a LA COMODANTE el 100% del valor de todos y cada uno de los "Dosificadores y/o Despachadores" que se le hayan otorgado en comodato,
                                o bien, se obliga a adquirir a LA COMODANTE el “consumo comprometido mensual” o “Vta. Prometida” de los consumibles pactada en el recuadro de la cláusula séptima anterior que se 
                                encuentre pendiente de adquirir durante el plazo de la vigencia mínima a LA COMODANTE con base en el precio de lista de esta última vigente a la fecha en que ocurra cualquiera de los 
                                supuestos enumerados con anterioridad; el pago de los "Dosificadores y/o Despachadores" y/o de los consumibles pendientes de adquirirse lo realizará EL COMODATARIO dentro de los 15 
                                días naturales siguientes a que se de cualquiera de los supuestos antes señalados.
                            </div>

                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">DECIMA:</span>Para la interpretación y cumplimiento del presente contrato, las partes se someten a las leyes aplicables, así como a los tribunales 
                             de la ciudad de: León, Guanajuato, renunciando expresamente a cualquier fuero que por razón de su domicilio pudiere corresponderles.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">DECIMA PRIMERA:</span> Estando las partes de acuerdo en el contenido del presente contrato, lo firman el día {{getDate()}} fecha en que EL COMODANTE se compromete a la entrega
                                a EL COMODATARIO 'los Dosificadores' que se listan a continuación en las cantidades que se indican:
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-10">
                                    <table class="table ">
                                        <thead>
                                            <tr class="bgtitable">
                                                <th class="text-center">FIRMA DE AUTORIZACIÓN DEL COMODANTE </th>
                                                <th class="text-center">NOMBRE. FIRMA DEL COMODATARIO Y SELLO DE LA EMPRESA </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="border-end border-start">
                                                    NOMBRE: {{datosArrayPDF[0].strGerente| uppercase }}
                                                </td>
                                                <td rowspan="2" class="align-middle text-center">
                                                    NOMBRE: {{datosArrayPDF[0].strContacto| uppercase }}
                                                    <div class="col-10 mx-auto">
                                                        <hr class="my-1">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                            <td class="border-end border-start">
                                                CARGO: GERENTE DE VENTAS
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>


    <!-- =================================================================================================== -->
<!-- =====================================Generar pdf==reposicion==================================================== -->
<!-- =================================================================================================== -->

    <div    class="d-none" ><!-- class="d-none" -->
      <div class="container" id="pdfTableReposicion">
          <div class="row exPdfBordePrincipal p-1 mb-4 d-flex justify-content-center">
              <div class="row exPdfBordePrincipal p-1 ">
                  <div class="col-4 d-flex align-items-center">
                   <img src="assets/ecodeli1.png" class="w-75" alt="">
                  </div>
                  <div class="col-4 d-flex align-items-center justify-content-center">
                      <span class=" mb-0 clstitPDF fs-6">FORMATO DE REPOSICION DE DESPACHADORES</span>
                  </div>
                  <div class="col-4 d-flex align-items-center justify-content-center">
                      <div class="row">
                          <div class="col-12 text-center text-dark fs10"><span class="fw-bold ">CÓDIGO:</span> ATC-RC-015</div>
                          <div class="col-12 text-center text-dark fs10"><span class="fw-bold">VERSIÓN:</span> 07</div>
                          <div class="col-12 text-center text-dark fs10"><span class="fw-bold">FECHA DE ÚLTIMA REVISIÓN:</span> 24/01/2024</div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-3 mb-2">
            <div class="col-12">
                <table class="table">
                  <tr>
                      <td class="table-cell table-header">Fecha:</td>
                      <td class="table-cell">{{fechaFormateada}}</td>
                  </tr>
                  <tr>
                      <td class="table-cell table-header">Dirección:</td>
                      <td class="table-cell">{{objInfo.strDomicilio}} </td>
                  </tr>
                  <tr>
                      <td class="table-cell table-header">Contacto:</td>
                      <td class="table-cell"> {{datosArrayPDF[0].strContacto| uppercase }}</td>
                  </tr>
                  <tr>
                      <td class="table-cell table-header">Nombre del Vendedor:</td>
                      <td class="table-cell">{{objInfo.strAgenteNombre}}</td>
                  </tr>
                  <tr>
                      <td class="table-cell table-header">ID Comodato Origen:</td>
                      <td class="table-cell">{{objInfo.strMovIDReposicion}}</td>
                  </tr>
              </table>
            </div>
          </div>
         
         
          
          <div class="row my-1">
               
              <div class="col-12  text-start ">
                    <div class="row d-flex justify-content-center my-3">
                              <div class="col-10">
                                  <table class="table table-bordered mt-4">
                                      <thead>
                                          <tr class="bgtitable">
                                              <th class="text-center">Cant.</th>
                                              <th class="text-center">Producto</th>
                                              <th class="text-center">Descripción</th>
                                              <th class="text-center text-nowrap">Precio Unitario</th>
                                              <th class="text-center">Importe</th>
                                             
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let art of datosArrayPDF" >
                                              <td>{{art.dblCantidadDespachador}}</td>
                                              <td>
                                                  <div class=" text-center roundedBordestop bg-main " style="width: 70px;">
                                                      <span class="fs10 fw-bold text-white">{{art.strArticulo}}</span>  
                                                  </div>
                                                  <img src="https://www.ecodeli.com/img/PaginaWeb/{{art.strArticulo}}.JPG" class="bg-white roundedBordesbottom" style="width: 70px;" alt="">
                                              </td>
                                              <td class="align-middle">{{art.strDescripcion1}}</td>
                                              <td>{{art.dblPrecio|   currency:'USD':'symbol':'1.2-2'}}</td>
                                              <td>{{(art.dblCantidadDespachador * art.dblPrecio )|   currency:'USD':'symbol':'1.2-2'}}</td>
                                             
                                          </tr>
                                      </tbody>
                                  </table>
                                  <div class="col-12 mt-3 text-end fs12 ">
                                    Subtotal: <span class="fw-bold">{{SubtotalPDF|   currency:'USD':'symbol':'1.2-2'}}</span>
                                </div>
                                <div class="col-12 my-1 text-end fs12">
                                    IVA 16%: <span class="fw-bold">{{ivaPDF|   currency:'USD':'symbol':'1.2-2'}}</span>
                                </div>
                                <div class="col-12 mb-2 text-end fs12">
                                    Importe Total: <span class="fw-bold">{{ImportetotalPDF|   currency:'USD':'symbol':'1.2-2'}}</span>
                                </div>
                              </div>
                             
                          </div>
                  
                   
                    
                 
                    
                          
                          <div class="row d-flex justify-content-center my-3">
                              <div class="col-10">
                                  <table class="table ">
                                      <thead>
                                          <tr class="bgtitable">
                                              <th class="text-center">FIRMA DE AUTORIZACIÓN DEL COMODANTE </th>
                                              <th class="text-center">NOMBRE. FIRMA DEL COMODATARIO Y SELLO DE LA EMPRESA </th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <td class="border-end border-start">
                                                  NOMBRE: {{datosArrayPDF[0].strGerente| uppercase }}
                                              </td>
                                              <td rowspan="2" class="align-middle text-center">
                                                  NOMBRE: {{datosArrayPDF[0].strContacto| uppercase }}
                                                  <div class="col-10 mx-auto">
                                                      <hr class="my-1">
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                          <td class="border-end border-start">
                                              CARGO: GERENTE DE VENTAS
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      
                 
              </div>
          </div>
      </div>
  </div>
<!-- =================================================================================================== -->
<!-- =====================================Fin pdf======================================================= -->
<!-- =================================================================================================== -->
</div>
  </div>
  <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Respuestas</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="table-responsive">
                <table class="custom-table">
                    <thead>
                      <tr>
                        <!-- Encabezado Principal con clases específicas para cada grupo -->
                        <th colspan="5" class="header-despachador">DESPACHADOR</th>
                        <th colspan="5" class="header-consumible">CONSUMIBLE</th>
                        <th colspan="3" class="header-analisis">ANALISIS</th>
                      </tr>
                      <tr>
                        <th scope="col" class="header-despachador">Clave</th>
                        <th scope="col" class="header-despachador">Opción</th>
                        <th scope="col" class="header-despachador">Cantidad</th>
                        <th scope="col" class="header-despachador">Costo</th>
                        <th scope="col" class="header-despachador">Renta Mensual</th>
                  
                        <th scope="col" class="header-consumible">Clave</th>
                        <th scope="col" class="header-consumible">Opción</th>
                        <th scope="col" class="header-consumible">Cantidad</th>
                        <th scope="col" class="header-consumible">Precio</th>
                        <th scope="col" class="header-consumible">Costo</th>
                  
                        <th scope="col" class="header-analisis">Inv. Total</th>
                        <th scope="col" class="header-analisis">Margen Sobre Renta</th>
                        <th scope="col" class="header-analisis">Tiempo Rep.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" class="data-header">{{objDespachador.strArticulo}}</th>
                        <td class="data-cell">{{objDespachador.strOpcion}} {{objDespachador.strNombreOpcion}}</td>
                        <td class="data-cell">{{objDespachador.dblCantidadDespachador | number:'1.2-2'}}</td>
                        <td class="data-cell">{{objArt_Det.dblCostoComodato |   currency:'USD':'symbol':'1.2-2'}}</td>
                        <td class="data-cell">{{objArt_Det.dblRentaMensual |   currency:'USD':'symbol':'1.2-2'}}</td>
                        <!-- consumible -->
                        <th scope="row" class="data-header">{{objArt_Det.strConsumible}}</th>
                        <td class="data-cell">{{objArt_Det.strOpcion}}  {{objArt_Det.strNombreOpcion}}</td>
                        <td class="data-cell">{{objArt_Det.intCantidad | number:'1.2-2'}}</td>
                        <td class="data-cell">{{objArt_Det.dblPrecio |   currency:'USD':'symbol':'1.2-2'}}</td>
                        <td class="data-cell">{{objArt_Det.dblCostoConsumible |   currency:'USD':'symbol':'1.2-2'}}</td>
                        <!-- analisis -->
                        <td class="data-cell">{{objDespachador.dblCantidadDespachador  * objArt_Det.dblCostoComodato | currency:'USD':'symbol':'1.2-2'}}</td>
                        <td class="data-cell">{{objArt_Det.dblDisponible |   currency:'USD':'symbol':'1.2-2'}}</td>
                        <td class="data-cell">{{ getRoundedValue(((objDespachador.dblCantidadDespachador  * objArt_Det.dblCostoComodato )/objArt_Det.dblDisponible)) }}
                          </td>
                      </tr>
                      <!-- Añadir más filas aquí -->
                    </tbody>
                  </table> 
                  
                  
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="container mt-4">
                <div class="mb-3">
                  <!-- Label para el input -->
                  <label for="cantidadMensual" class="form-label">Cantidad Mensual (Consumible):</label>
                  <!-- Input de texto con clase de Bootstrap -->
                  <input type="number" class="form-control" id="cantidadMensual" placeholder="Ingrese la cantidad mensual" [(ngModel)]="objArt_Det.intCantidad">
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container mt-4">
                <div class="mb-3">
                  <!-- Label para el input -->
                  <label for="cantidadMensual" class="form-label">Precio (Consumible):</label>
                  <!-- Input de texto con clase de Bootstrap -->
                  <input type="number" class="form-control" id="cantidadMensual" placeholder="Ingrese la cantidad mensual" [(ngModel)]="objArt_Det.dblPrecio">
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container mt-4">
                <div class="mb-3">
                   <!-- Label para el input -->
                   <label for="cantidadMensual" class="form-label"> </label>
                  <button type="button" class="btn btn-outline-primary mt-4" (click)="fnActualizarRespuesta(objArt_Det)">
                    Actualizar Respuestas
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          
        </div>
      </div>
    </div>
  </div>
  <!--MODAL RECHAZADO-->
  <!-- Modal -->
<div class="modal fade" id="rechazadoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel"> Rechazar Solicitud de Comodatos</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-floating">
          <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"  [(ngModel)]="strMotivorechazo"></textarea>
          <label for="floatingTextarea">Motivo de Rechazo</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  id="btnModal">Cerrar</button>
        <button type="button" class="btn btn-danger" (click)="fnRechazar()">Rechazar</button>
      </div>
    </div>
  </div>
</div>
<!-- existencia Modal -->
<div class="modal fade" id="existenciaModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Existencias</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Almacén</th>
                <th scope="col">Nombre</th>
                <th scope="col">Disponibles</th>
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let saldo of arraySaldou">
                <th scope="row">{{saldo.strAlmacen}}</th>
                <td>{{saldo.strNombreAlmacen }}</td>
                <td [ngClass]="{ 'bold-text': saldo.dblCantidad > 0 }">
                  {{ saldo.dblCantidad | number:'1.2-2' }}
                </td>
                
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
      
      </div>
    </div>
  </div>
</div>