import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sucursalFilter'
})
export class SucursalFilterPipe implements PipeTransform {

  transform(value:any, args: any): any {
  
    let resultPost=[];
    if(args==''){
      return resultPost = value;
    }
    else {
      for(const post of value){
        if(post.strGerente==args){
          resultPost.push(post);
        }  
      
      };
      return resultPost;   
  } 
    //return null;
  }

}
 