import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cancelar-pedido',
  templateUrl: './cancelar-pedido.component.html',
  styleUrls: ['./cancelar-pedido.component.css']
})
export class CancelarPedidoComponent implements OnInit {
  public strID: any = "";
  public arrayCancelados:any =[];
  public spinner:boolean = false;
  public selectedConcepto:any ="";
  public strAgente:string ="";
  public listaConcepto:any=[];
  public inputObservaciones:any = ""
  public aFechaRequeridaS;
  constructor(private router: Router,private _httpService: PeticionesService, public route: ActivatedRoute, public localSt: LocalStorageService) { 
    this.route.queryParamMap.subscribe(params => {
      this.strID = params.get('strID');
    });
    this.strAgente = this.localSt.retrieve('strUsuario');  
    console.log(this.strID )
  }

  ngOnInit(): void {
    this.fnIniciarCancelados();
  }


  fnIniciarCancelados(){
    this.spinner = true;
    let objEnviar={
      'strID': this.strID
    }
    this._httpService.getpagViejitaPlantillas(objEnviar,'getVentasMovimientosDetalle').subscribe(
      result => {
        this.arrayCancelados= result;
        this.arrayCancelados.forEach(x => {
          x.cantidadCancelar = 0
        });
        console.log(this.arrayCancelados);
        this.spinner = false;
      }, error => {
        console.log(error.message);
        this.spinner = false;
      })
  }

  cancelacionIndicada(obj:any){
    this.spinner = true;
    let  objEnviar = {'strID':this.strID, 'strArticulo': obj.strArticulo, 'strSubCuenta': "",'dblCantidadPendiente':obj.cantidadCancelar};
    console.log(objEnviar)
    this._httpService.getpagViejitaPlantillas(objEnviar,'setPedidoCanceladoIndicada').subscribe(
      result => {
        let rest = result
        console.log(rest)
        Swal.fire({
        icon: "success",
        title: "Cancelado Correctamente.",
        showConfirmButton: false,
        timer: 2000
      });
      this.fnIniciarCancelados()
        this.spinner = false; 
      }, error => {
        Swal.fire({
          icon: "error",
          title: "Error en:",
          text:error.message
        });
        console.log(error.message);
        this.spinner = false;
      })
  }
  
  fnCancelarTodo(){
    let obEnviar={
      'strID': this.strID,
      'strConcepto':this.selectedConcepto,
      'strObservaciones':this.inputObservaciones,
      'strUsuario':this.strAgente
    }
  console.log(obEnviar)
    this._httpService.getpagViejitaPlantillas(obEnviar,'setPedidoCancelado').subscribe(
      result => {
        let rest = result
        console.log(rest)
        Swal.fire({
        icon: "success",
        title: "Cancelado Correctamente.",
        showConfirmButton: false,
        timer: 2000
      });
      this.router.navigate(['/pedidos_pendientes/']);
      }, error => {
        Swal.fire({
          icon: "error",
          title: "Error en:",
          text:error.message
        });
        console.log(error.message);
      
      })
  }
  fnCargarCancelado(){
    this.listaConcepto = [];
    this._httpService.getpagViejitaPlantillas({},'getConcepto').subscribe(
      result => {
        this.inputObservaciones = ""
        this.listaConcepto = result
        this.selectedConcepto =this.listaConcepto && this.listaConcepto[0] &&  this.listaConcepto[0].strConcepto?this.listaConcepto[0].strConcepto:"";
        console.log(result)
      });
  }
  
}
