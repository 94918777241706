import { Component, OnInit } from '@angular/core';
import  * as XLSX from 'xlsx';
import { PeticionesService } from '../servicios/httpservice';
import { filter } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { timer } from 'rxjs';
import { Articulo } from '../interfaces/articulo';
import {LocalStorageService} from 'ngx-webstorage';
import { DataDBService } from '../servicios/data-db.service';

@Component({
  selector: 'app-capturador',
  templateUrl: './capturador.component.html',
  styleUrls: ['./capturador.component.css']
})

export class CapturadorComponent implements OnInit {
  public nameArchivo:string="seleccione Archivo:";
  public excelData:any;
  public arrayListaPrecios:any;
  public arrayArt:any;
  public arrayPreciosArt:any;  
  public bandera:boolean=false;
  public isHovered = -1;
  public arraySucursales:any = []; 
  public arrayAccesos:any;
  public arrayFiltros:any={lstDivicionales:false,lstSucursales:false,lstAgentes:false};
  public strAgente:any; 
  public capturador:Boolean= true;
  public spinner:boolean= false;
  public strConexion:string="";

  constructor(private indexedDBService: DataDBService,private _httpService:PeticionesService,private localSt:LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
  }

  ngOnInit(): void {
   
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
     // this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
      this.strAgente = !this.arrayFiltros.lstAgentes?this.arrayAccesos[0].strAgente :"";
      console.log("Inicio");
    });
  }

  fnextraerPrecios(strCliente:string, strSucursal:string){
    this._httpService.getListaPrecios({
      strAccion: 'get_PreciosClientes',
      strCliente:strCliente,
      strSucursal:strSucursal,
      strConexion:this.strConexion
    }).subscribe(
      result => {
        this.arrayListaPrecios = result; 
        console.log(this.arrayListaPrecios);
        this.fnCompararPrecios();
      },
      error => {
        var error = <any>error; 
        console.log(error);
        this.capturador = true;
        Swal.fire({
          icon: 'error',
          title: 'Uuups...',
          text: 'Error en conexion al extraer precios, intente de nuevo'
        });
      });
  }
  
  Readexcel(file: File){
    console.log(file)
    this.spinner = true;
    let filereader = new FileReader();
    filereader.readAsBinaryString(file);
    filereader.onload = (e) =>{
      try {
      var workBook = XLSX.read(filereader.result,{type:'binary'});
      var sheetNames = workBook.SheetNames;
      this.excelData=   XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]]);
      console.log(this.excelData);
      this.excelData.forEach(e => {
        e.No_Cliente = e.No_Cliente.replace(/\s+/g, '');
      });
      console.log(this.excelData);
      if (this.excelData[0].No_Cliente){
        let cliIguales = 0;

        this.excelData.forEach(e => {
         if(this.excelData[0].No_Cliente != e.No_Cliente ) {
          cliIguales +=1 ;
         }
      });
      if(cliIguales==0){
      
        this.fnExtraersucursales(this.excelData[0].No_Cliente);
        setTimeout(() => {
          this.spinner = false;
        }, 100);
      }else{
        Swal.fire({icon: 'error',title: 'Error',text: 'El cliente no coincide.',});
        this.spinner = false;
        this.fnCambiarstatus("error");
      }
      }else{
        this.spinner = false;
        this.capturador= true;
        this.nameArchivo= '';
        this.excelData= null;
        Swal.fire({icon: 'error',title: 'Error',text: 'El archivo seleccionado no es un archivo excel válido intente de nuevo.',});
      }
      }catch (error) {
        this.capturador= true;
        this.nameArchivo= '';
        this.excelData= null;
          this.spinner = false;
        Swal.fire({icon: 'error',title: 'Error',text: 'El archivo seleccionado no es un archivo excel válido intente de nuevo.',});
      }
    } 
  }

  fnCambiarstatus(num:string){
    if(num == 'boton'){
      Swal.fire({
        title: 'Esta seguro?',
        text: "Se perderan todos los cambios que realizo en esta plantilla",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Leer otro Archivo'
      }).then((result) => {
        if (result.isConfirmed) {
          this.arraySucursales=[];
          this.nameArchivo= '';
          this.excelData= null;
          this.capturador = true;
        }
      })
    }else if(num =='error'){
      this.arraySucursales=[];
          this.nameArchivo= '';
          this.excelData= null;
          this.capturador = true;
    }
  }

  handleDrop(event: DragEvent) {
    this.spinner= true
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    
    if (fileList.length > 0) {
    this.capturador = false;
    console.log('Archivo seleccionado:', fileList[0]);
    this.nameArchivo= fileList[0].name;
    this.Readexcel(fileList[0]);
      }else{
      this.spinner= false;
      }
    }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    // Agregar estilos para resaltar el área de soltar
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    // Eliminar estilos de resaltado cuando se sale del área de soltar
  }

  handleFileInput(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const fileList = fileInput.files;
    // Puedes acceder al archivo seleccionado utilizando fileList[0]
    console.log('Archivo seleccionado:', fileList[0]);
    this.nameArchivo= fileList[0].name;
    this.capturador = false;
    this.Readexcel(fileList[0]);
  }

  fnCompararPrecios(){
    let arrayArticulos = []
    this.arrayArt = [];
    let arrayPrecios = []
    this.excelData.forEach(dato => {   
      if (dato.Sucursal === null || dato.Sucursal === undefined) {
        dato.Sucursal = "";
      }
    });

    this.arraySucursales.forEach(dato => {   
    arrayArticulos = this.excelData.filter(o => dato.strIDSucursal == o.Sucursal );
    arrayArticulos.forEach(b=>{
      b.arrayPrecios = arrayPrecios;
      b.plc = 0;
      let Articulo = this.arrayListaPrecios.filter(o => o.strArticulo == b.Articulo );
      let Opcion = b.Opcion?b.Opcion:'';
    if(Articulo.length==0){
      this.arrayArt.push({strArticulo:b.Articulo, strOpcion:Opcion });
      b.Precio =0;
      b.Precio_Total =0;
      b.plc=0;
    }else{
      this.arrayArt.push({strArticulo:b.Articulo, strOpcion:Opcion });
      b.Precio = Articulo[0].intPrecio;
      b.Precio_Total = b.Precio * b.Cantidad;
      b.plc=1;
    }
  });
 dato.arrayArticulos = arrayArticulos;
});
console.log(this.arrayArt)
 this.fnArtSinLp(this.arrayArt);
 
  }
 fnArtSinLp(arrayArt:any){
  this._httpService.getListaPrecios({
    strAccion: 'get_PreciosArticulos',
    xmlArticulos:arrayArt
  }).subscribe(
    result => {
      this.arrayPreciosArt = result; 
      this.fnInsertarprecios(this.arrayPreciosArt);
    },
    error => {
      var error = <any>error;
      this.capturador = true;
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Uuups...',
        text: 'Error en conexion al extraer articulos sin precio lista, intente de nuevo.'
      });
    }
  );
 }

 

 fnInsertarprecios(arrayPrecios:any){
  this.arraySucursales.forEach(ele => {
    ele.arrayArticulos.forEach(element => {
      let arrayrestprecios = []
      arrayrestprecios = arrayPrecios.filter(o => o.strArticulo == element.Articulo);
      if(element.plc == 0){
        console.log(arrayrestprecios);
        element.arrayPrecios=arrayrestprecios[0];
      }
      let descripcion=arrayPrecios.filter(o => 
        o.strArticulo == element.Articulo
       );
       if(descripcion[0] && descripcion[0].strDescripcion ){
        element.Descripcion=descripcion[0].strDescripcion;
        element.strLinea= arrayrestprecios[0].strLinea;
       }else{
        element.Descripcion="";
        element.strLinea= arrayrestprecios[0].strLinea;
       }
      
    });
  });
  
 }
 fnpreciototal(i:number,articulo:string){
   let sumatotal:number;
   this.arraySucursales.forEach(element => {
     if(element.strIDSucursal== i){
       element.arrayArticulos.forEach(o => {
         if(o.Articulo==articulo){
          sumatotal= o.Precio * o.Cantidad
         o.Precio_Total=sumatotal;
         }
       });
      
     }
   });
 }
 fnsumartotal(sucursal:number):number{
  let total: number = 0;
  this.arraySucursales.forEach(element => {
    if (element.strIDSucursal === sucursal) {
      if (element.arrayArticulos) {
        total = element.arrayArticulos.reduce((acumulador, registro) => acumulador + registro.Precio_Total, 0);
      } else {
        total = 0;
      }
    }
  });
  return total;
 }
 fnsumartotalart(sucursal:number):number{
  let total:number =0;
  this.arraySucursales.forEach(element => {
    if(element.strIDSucursal==sucursal){
      if(element.arrayArticulos){
        total = element.arrayArticulos.reduce((acumulador, registro) => acumulador + registro.Cantidad, 0);
      }
     else{
      total=0;
     }
    }
  });

 return total;
 }
 formatDescripcion(descripcion: string|undefined): string {
  // Agregar el salto de línea en la posición deseada
  if(descripcion){
    const posicionSalto = Math.floor(descripcion.length / 2);
    const descripcionFormateada = `${descripcion.slice(0, posicionSalto)}<br>${descripcion.slice(posicionSalto)}`;
    return descripcionFormateada;
  }
  return "";
}

enviarPedido(intSucursal:string,strSucursal:string,object:any){
  let fechaRequeridaString = object.fechaRequerida
  let fechaRequerida = new Date(fechaRequeridaString);
  let fechaActual = new Date();
  fechaRequerida.setDate(fechaRequerida.getDate() + 1); 
if(fechaRequerida.getTime()>fechaActual.getTime()){
  Swal.fire({
    title: 'Pedido',
    icon:'question',
    showDenyButton: true,
    confirmButtonText: 'Si',
    denyButtonText: 'No',
    html:'¿Desea enviar la orden?',
    customClass: {
    actions: 'my-actions',
    confirmButton: 'order-2',
    denyButton: 'order-3',}
  }).then(async (result) => {
    if (result.isConfirmed){
      let artNoexisten:any = []
      let artConPrecio0=[]
      let bandera = 0;
      let noexisten=0;
      this.arraySucursales.forEach(ko => {
        if(ko.strIDSucursal == intSucursal){
          ko.arrayArticulos.forEach(element => {
            bandera = element.Precio_Total<1?bandera+1:bandera;
            let artNoexiste =  this.arrayListaPrecios.filter(o => o.strArticulo == element.Articulo);
            if(element.Precio_Total==0){
              if(artNoexiste.length<1){
                 artNoexiste =  this.arrayPreciosArt.filter(o => o.strArticulo == element.Articulo);
                if(artNoexiste.length<1){
                  artNoexisten.push(element.Articulo);
                  noexisten += 1 
                }else{
                  artConPrecio0.push(element.Articulo);
                }
            }else{
              artConPrecio0.push(element.Articulo);
            }
          }
        });
        }
      });
      if(bandera==0){
        let xml:any=[];
        let arrayArtsuc = this.arraySucursales.filter(o => o.strIDSucursal == intSucursal);
        arrayArtsuc[0].arrayArticulos.forEach(el => {
          xml.push({
            strArticulo:el.Articulo,
            strDescripcion:el.Articulo,
            strLinea:el.strLinea,
            dblPrecio:parseFloat(el.Precio),
            intCantidad:el.Cantidad,
            strOpcion:el.Opcion,
            strCliente:el.No_Cliente,
            intPrecioT:parseFloat(el.Precio_Total)
          });
        });
        console.log(fechaRequeridaString);
        this.fnInsertarPedido(this.arraySucursales[0].strCliente,strSucursal,xml,intSucursal,object)
      }else{
        if(noexisten>0){
          Swal.fire({
            icon: 'error',
            title: 'Los siguientes Articulos no existen, favor de borrarlos del archivo excel',
            html: '<pre>' + JSON.stringify(artNoexisten, null, 2) + '</pre>',
            confirmButtonText: 'Cerrar'
          });
        }else{
        Swal.fire({
          icon: 'error',
          title: 'Seleccione precio de los siguientes articulos',
          html:'<pre >' + JSON.stringify(artConPrecio0, null, 2) + '</pre>',
        });
      }
      }
    }
    else{
   
      }
    });
}else{
  object.BolFecha = false;
  Swal.fire({
    icon: 'error',
    title: 'Fecha Incorrecta',
    text:'Seleccione una fecha correcta requerida!'
  });
}
}
  fneliminar(i:number,intsucursal:number){
    this.arraySucursales.forEach(element => {
     if(element.strIDSucursal == intsucursal ) {
       element.arrayArticulos.splice(i, 1);
     }
    });

  }
  fnExtraersucursales(strCliente:string){
    this._httpService.getListaPrecios({
      strAccion: 'get_SucursalesCte',
      strCliente:strCliente,
      strConexion:this.strConexion
    }).subscribe(
      result => {
        this.arraySucursales = result; 
        this.arraySucursales.forEach(x => {
          x.fechaRequerida = null;
          x.BolFecha=true;
          x.strOrdenPedido = '';
          x.strEstatus = ""
          x.strMensaje = ""
          x.bolEnviado = false;
          x.spinner = false;
        });
        console.log(this.arraySucursales);
        this.fnextraerPrecios(this.excelData[0].No_Cliente,this.excelData[0].Sucursal);
      },
      error => {
        var error = <any>error;
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Uuups...',
          text: 'Error en conexion al extraer sucursales, intente de nuevo.'
        });
      }
    );
  }
  fnInsertarPedido(strCliente:string,strSucursal:string,arrayArticulos:any, strIDSucursal:string,object:any){
    console.log(strSucursal)
    console.log(strIDSucursal)
    object.spinner = true;
    const fecha: Date = new Date(object.fechaRequerida);
    fecha.setDate(fecha.getDate() + 1); 
    const dia = fecha.getDate().toString().padStart(2, '0'); // Obtiene el día y lo ajusta al formato 'dd'
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Obtiene el mes (se suma 1 porque los meses son base 0) y lo ajusta al formato 'mm'
    const anio = fecha.getFullYear(); 
    let strFechaRequerida=dia+'/'+mes+'/'+anio
    console.log("Esta es la fecha requerida que se esta enviando: "+strFechaRequerida); 
    console.log("Este es el agente enviando: "+this.strAgente); 
    console.log("Este es el cliente al que se esta enviando: "+strCliente); 
    console.log("Esta es a la sucursal que se envia: "+strIDSucursal); 
    
    let pruebas:any; 
     this._httpService.setPedido({
      strAccion: 'setPedido',
      strAgente:this.strAgente,
      strCliente:strCliente,
      strSucursal:strSucursal,
      xmlArticulos:arrayArticulos,
      strCteEnviarA:strIDSucursal,
      strFechaRequerida:strFechaRequerida,
      strOrdenCompra:object.strOrdenPedido,
      strSistema:'CAPTURADOR_PEDIDOS',
       strConexion:'COMERCIAL'
    }).subscribe(
      result => {
        pruebas = result; 
        object.intID = pruebas[0].intID;
        object.strEstatus = pruebas[0].strEstatus;
        object.BolFecha=true;
        object.bolEnviado = true;
        let code =pruebas.length==0?410:pruebas[0]?pruebas[0].code:410;
        let msj =pruebas.length==0?'Ocurrió un error al intentar generar el pedido':pruebas[0]?pruebas[0].strMensaje:'Ocurrió un error al intentar generar el pedido';
        //let icono = code==409?'warning':code==200?'success':'error'; 
        object.strMensaje = msj;
        let titulo = code==409?'Pedido Generada SINAFECTAR':code==200?'Pedido Generada Correctamente':'Error al Intentar Generar Pedido';
        object.spinner = false;
        Swal.fire({
          icon: code==409?'warning':code==200?'success':'error',
          title: titulo,
          text: msj,
          confirmButtonText: 'Cerrar'
        });
        console.log(pruebas);
       // Swal.fire({showConfirmButton: false,title:'Guardada Correctamente!',text: 'Se envio correctamente el pedido', icon:'success',timer: 2500});
      },
      error => {
        var error = <any>error;
        console.log(error);
        object.spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'Uuups...',
          text: 'Error en conexion al insertar pedido'
        });
      }
    );  
  }
  fnDescargarFormato(){
    const rutaArchivo = 'assets/formatoDescargableCapturador.xlsx'
    const link = document.createElement('a');
    link.href = rutaArchivo;
    link.download = 'FormatoCapturador.xlsx';
    link.click();
  }
}
