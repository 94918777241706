import { Component, OnInit ,ElementRef} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-objmixn',
  templateUrl: './objmixn.component.html',
  styleUrls: ['./objmixn.component.css']
})
export class ObjmixnComponent implements OnInit {
  public arrayHistorico:any;
  public arrayObjetivos:any=[{strLinea:'ACCESORIOS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'BLUE TISSUE',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'DESPACHADORES ECODELI',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'KIMBERLY',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'OTROS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'QUIMICOS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'RUBBERMAID',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'TORK',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'XIBU',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0}
];
  public dblTEnero:number=0;
  public dblTFebrero:number=0;
  public dblTMarzo:number=0;
  public dblTAbril:number=0;
  public dblTMayo:number=0;
  public dblTJunio:number=0;
  public dblTJulio:number=0;
  public dblTAgosto:number=0;
  public dblTSeptiembre:number=0;
  public dblTOctubre:number=0;
  public dblTNoviembre:number=0;
  public dblTDiciembre:number=0;
  public intMes:number=0;
  public dblObjetivoNacional:number=0;
  public dblPAccesorios:number=0;
  public dblPBlue:number=0;
  public dblPDespachador:number=0;
  public dblPKimberly:number=0;
  public dblPOtros:number=0;
  public dblPQuimicos:number=0;
  public dblPRubbermaid:number=0;
  public dblPTork:number=0;
  public dblPXibu:number=0;
  public dblTPorcentaje:number=0;
  public arrayAccesos:any=[];
  public tagName:any;
  public btnGuardar:any=false;
  public arrayObjetivoAsignado:any;
  public today:any = new Date();
  constructor(elem:ElementRef,private _peticionesService: PeticionesService,public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router) {
    this.tagName=elem.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {  
    //obtener niveles de acceso
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
    }
    if(objAcceso==undefined){
      this.router.navigate(['/login']);
    }
     else{
       this.btnGuardar=objAcceso.find((o:any)=>o.strAccion=='Guardar')?true:false;
    
       //si es dirección, obtnerhistorico Nacional
       var today = new Date();
       this.intMes=today.getMonth()+1;
       //console.log(this.intMes)
      this.fnGetObjetivoAsginado();
     }
   
  }

  fnGetObjetivoAsginado(){
    let periodo = this.today.getMonth()+2;
    let ejercicio = this.today.getFullYear();
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivoNacional' ,
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
    }).subscribe(
      result => {
        this.arrayObjetivoAsignado=result;
        this.getHistoricoNacional();
      });
    
  }
  getHistoricoNacional(){
    this._peticionesService.getApiCompras({
      strAccion: 'getHistNacional' 
    }).subscribe(
      result => { 
        result.forEach((elemento:any)=> { 
           this.dblTEnero= this.dblTEnero+elemento.dblEnero;
           this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
           this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
           this.dblTAbril= this.dblTAbril+elemento.dblAbril;
           this.dblTMayo= this.dblTMayo+elemento.dblMayo;
           this.dblTJunio= this.dblTJunio+elemento.dblJunio;
           this.dblTJulio= this.dblTJulio+elemento.dblJulio;
           this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
           this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
           this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
           this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
           this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre; 
          
      });
      this.arrayHistorico=result; 
      
      let objAcc =this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='ACCESORIOS'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='ACCESORIOS');
      let objBlue =this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='BLUE TISSUE'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='BLUE TISSUE');
      let objDes =this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='DESPACHADORES ECODELI'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='DESPACHADORES ECODELI');
      let objKim=this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='KIMBERLY'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='KIMBERLY');
      let objOtros =this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='OTROS'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='OTROS');
      let objQuim =this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='QUIMICOS'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='QUIMICOS');
      let objRub =this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='RUBBERMAID'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='RUBBERMAID');
      let objTork =this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='TORK'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='TORK');
      let objXibu =this.arrayObjetivoAsignado.length>0? this.arrayObjetivoAsignado.find((o: { strLinea: string; }) =>o.strLinea=='XIBU'):this.arrayHistorico.find((o: { strLinea: string; }) =>o.strLinea=='XIBU');
  
      var dblAcc =0;
      var dblBlue =0
      var dblDes=0;
      var dblKim=0;
      var dblOtros=0;
      var dblQuim=0;
      var dblRub =0;
      var dblTork=0;
      var dblXibu =0;
      if(this.arrayObjetivoAsignado ==undefined ||this.arrayObjetivoAsignado.length==0){
        if(this.intMes==1){
          this.dblObjetivoNacional=this.dblTEnero;
          dblAcc =objAcc.dblEnero;
          dblBlue=objBlue.dblEnero;
          dblDes=objDes.dblEnero;
          dblKim=objKim.dblEnero;
          dblOtros=objOtros.dblEnero;
          dblQuim=objQuim.dblEnero;
          dblRub =objRub.dblEnero;
          dblTork=objTork.dblEnero;
          dblXibu =objXibu.dblEnero;
        }
        else if(this.intMes==2){
          this.dblObjetivoNacional=this.dblTFebrero;
          dblAcc =objAcc.dblFebrero;
          dblBlue=objBlue.dblFebrero; 
          dblDes=objDes.dblFebrero;
          dblKim=objKim.dblFebrero;
          dblOtros=objOtros.dblFebrero;
          dblQuim=objQuim.dblFebrero;
          dblRub =objRub.dblFebrero;
          dblTork=objTork.dblFebrero;
          dblXibu =objXibu.dblFebrero;
        }
        else if(this.intMes==3){
          this.dblObjetivoNacional=this.dblTMarzo;
          dblAcc =objAcc.dblMarzo;
          dblBlue=objBlue.dblMarzo; 
          dblDes=objDes.dblMarzo;
          dblKim=objKim.dblMarzo;
          dblOtros=objOtros.dblMarzo;
          dblQuim=objQuim.dblMarzo;
          dblRub =objRub.dblMarzo;
          dblTork=objTork.dblMarzo;
          dblXibu =objXibu.dblMarzo;
        }
        else if(this.intMes==4){
          this.dblObjetivoNacional=this.dblTAbril;
          dblAcc =objAcc.dblAbril;
          dblBlue=objBlue.dblAbril; 
          dblDes=objDes.dblAbril;
          dblKim=objKim.dblAbril;
          dblOtros=objOtros.dblAbril;
          dblQuim=objQuim.dblAbril;
          dblRub =objRub.dblAbril;
          dblTork=objTork.dblAbril;
          dblXibu =objXibu.dblAbril;
        }
        else if(this.intMes==5){
          this.dblObjetivoNacional=this.dblTMayo;
          dblAcc =objAcc.dblMayo;
          dblBlue=objBlue.dblMayo; 
          dblDes=objDes.dblMayo;
          dblKim=objKim.dblMayo;
          dblOtros=objOtros.dblMayo;
          dblQuim=objQuim.dblMayo;
          dblRub =objRub.dblMayo;
          dblTork=objTork.dblMayo;
          dblXibu =objXibu.dblMayo;
        }
        else if(this.intMes==6){
          this.dblObjetivoNacional=this.dblTJunio;
          dblAcc =objAcc.dblJunio;
          dblBlue=objBlue.dblJunio;
          dblDes=objDes.dblJunio;
          dblKim=objKim.dblJunio;
          dblOtros=objOtros.dblJunio;
          dblQuim=objQuim.dblJunio;
          dblRub =objRub.dblJunio;
          dblTork=objTork.dblJunio;
          dblXibu =objXibu.dblJunio;
        }
        else if(this.intMes==7){
          this.dblObjetivoNacional=this.dblTJulio;
          dblAcc =objAcc.dblJulio;
          dblBlue=objBlue.dblJulio;
          dblDes=objDes.dblJulio;
          dblKim=objKim.dblJulio;
          dblOtros=objOtros.dblJulio;
          dblQuim=objQuim.dblJulio;
          dblRub =objRub.dblJulio;
          dblTork=objTork.dblJulio;
          dblXibu =objXibu.dblJulio;
        }
        else if(this.intMes==8){
          this.dblObjetivoNacional=this.dblTAgosto;
          dblAcc =objAcc.dblAgosto;
          dblBlue=objBlue.dblAgosto;
          dblDes=objDes.dblAgosto;
          dblKim=objKim.dblAgosto;
          dblOtros=objOtros.dblAgosto;
          dblQuim=objQuim.dblAgosto;
          dblRub =objRub.dblAgosto;
          dblTork=objTork.dblAgosto;
          dblXibu =objXibu.dblAgosto;
        }
        else if(this.intMes==9){
          this.dblObjetivoNacional=this.dblTSeptiembre;
          dblAcc =objAcc.dblSeptiembre;
          dblBlue=objBlue.dblSeptiembre;
          dblDes=objDes.dblSeptiembre;
          dblKim=objKim.dblSeptiembre;
          dblOtros=objOtros.dblSeptiembre;
          dblQuim=objQuim.dblSeptiembre;
          dblRub =objRub.dblSeptiembre;
          dblTork=objTork.dblSeptiembre;
          dblXibu =objXibu.dblSeptiembre;
        }
        else if(this.intMes==10){
          this.dblObjetivoNacional=this.dblTOctubre;
          dblAcc =objAcc.dblOctubre;
          dblBlue=objBlue.dblOctubre;
          dblDes=objDes.dblOctubre;
          dblKim=objKim.dblOctubre;
          dblOtros=objOtros.dblOctubre;
          dblQuim=objQuim.dblOctubre;
          dblRub =objRub.dblOctubre;
          dblTork=objTork.dblOctubre;
          dblXibu =objXibu.dblOctubre;
        }else if(this.intMes==11){
          this.dblObjetivoNacional=this.dblTNoviembre;
          dblAcc =objAcc.dblNoviembre;
          dblBlue=objBlue.dblNoviembre;
          dblDes=objDes.dblNoviembre;
          dblKim=objKim.dblNoviembre;
          dblOtros=objOtros.dblNoviembre;
          dblQuim=objQuim.dblNoviembre;
          dblRub =objRub.dblNoviembre;
          dblTork=objTork.dblNoviembre;
          dblXibu =objXibu.dblNoviembre;
        }else if(this.intMes==12){
          this.dblObjetivoNacional=this.dblTDiciembre;
          dblAcc =objAcc.dblDiciembre;
          dblBlue=objBlue.dblDiciembre;
          dblDes=objDes.dblDiciembre;
          dblKim=objKim.dblDiciembre;
          dblOtros=objOtros.dblDiciembre;
          dblQuim=objQuim.dblDiciembre;
          dblRub =objRub.dblDiciembre;
          dblTork=objTork.dblDiciembre;
          dblXibu =objXibu.dblDiciembre;
        }
    }
    else{
          dblAcc =objAcc.dblObjetivo;
          dblBlue=objBlue.dblObjetivo;
          dblDes=objDes.dblObjetivo;
          dblKim=objKim.dblObjetivo;
          dblOtros=objOtros.dblObjetivo;
          dblQuim=objQuim.dblObjetivo;
          dblRub =objRub.dblObjetivo;
          dblTork=objTork.dblObjetivo;
          dblXibu =objXibu.dblObjetivo;
          this.dblObjetivoNacional=dblAcc+dblBlue+dblDes+dblKim+dblOtros+dblQuim+dblRub+dblTork+dblXibu;
    }
        this.arrayObjetivos[0].dblPsugerido=((dblAcc/this.dblObjetivoNacional)*100).toFixed(2);
        this.arrayObjetivos[1].dblPsugerido=((dblBlue/this.dblObjetivoNacional)*100).toFixed(2);
        this.arrayObjetivos[2].dblPsugerido=((dblDes/ this.dblObjetivoNacional)*100).toFixed(2);
        this.arrayObjetivos[3].dblPsugerido= ((dblKim/this.dblObjetivoNacional)*100).toFixed(2);
        this.arrayObjetivos[4].dblPsugerido=((dblOtros/ this.dblObjetivoNacional)*100).toFixed(2);
        this.arrayObjetivos[5].dblPsugerido= ((dblQuim/this.dblObjetivoNacional)*100).toFixed(2);
        this.arrayObjetivos[6].dblPsugerido=((dblRub/this.dblObjetivoNacional)*100).toFixed(2);
        this.arrayObjetivos[7].dblPsugerido= ((dblTork/this.dblObjetivoNacional)*100).toFixed(2);
        this.arrayObjetivos[8].dblPsugerido=((dblXibu/ this.dblObjetivoNacional)*100).toFixed(2);
       this.changeObjNacional();
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
 
  changeObjNacional( ){ 
    this.dblTPorcentaje=0;
    this.arrayObjetivos.forEach((elemento:any)=> {  
      elemento.dblObjetivo=(((elemento.dblPsugerido)/100)*this.dblObjetivoNacional).toFixed(2);
      console.log(elemento.dblPsugerido);
      this.dblTPorcentaje=this.dblTPorcentaje+parseFloat(elemento.dblPsugerido);
      });
      
      this.dblObjetivoNacional= this.dblObjetivoNacional ;
      //this.dblObjetivoNacional=this.dblObjetivoNacional.toFixed(2)
  }

  fnGuardarObjetivoNacional(){
    this._peticionesService.getApiCompras({
      strAccion: 'setObjetivoNacional' ,
      xmlLineas:this.arrayObjetivos
    }).subscribe(
      result => { 
        this.fnGetObjetivoAsginado();
        this._alertify.success('Objetivos Guardados Correctamente'); 
      });
  }

  updatedObjNacional(event:any){
    let v =event.target.value;
    this.dblObjetivoNacional=v.replace(',','').replace(',','');
    console.log(this.dblObjetivoNacional);
    this.changeObjNacional();
  }

}

