import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-ecommerce-admin-art',
  templateUrl: './ecommerce-admin-art.component.html',
  styleUrls: ['./ecommerce-admin-art.component.css'],
  providers: [CurrencyPipe]
}) 
export class EcommerceAdminArtComponent implements OnInit {
  paginatedItems: any[] = [];
  arrayItemsDestacados : any[] = []; 
  arrayItemsNuevos: any[]= [];
  arrayItemsExclusivos: any[]= [];
  currentPage: number = 1;
  currentPageDest: number = 1;
  itemsPerPage: number = 5;
  itemsPerPageDest: number = 5;
  totalPages: number = 0;
  pagesDestacados:number = 0;
  public arrayFullArtsOfertas:any= [];
  public arrayFullArtsDestacados:any= [];
  public arrayProductosDestacados:any = [];
  public arrayNovedades:any = [];
  public arrayEjercicio:any=[];
  public intEjercicio:number=0;
  public intPeriodo:number; 
  public inputSearch:string="";
  public isHoveredButton: boolean = false;
  public strConexion:string="";
  public spinnerDest:boolean = false;
  public spinnerNuevo:boolean = false;
  public spinnerExclusivo:boolean = false;
  public arrayAgentes:any=[];
  public artsFiltrados:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public strBuscarArt:string
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayAccesos:any=[];
  public arrayBusquedaArts:any=[];
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public arrayLineas:any=[];
  public arrayLineasOfertas:any=[];
  public arrayLineasDest:any=[];
  public arrayPorLineas:any=[];
  public arrayArtsInsertar:any=[];
  public porcentajeDesc:number = 0;
  public strLinea:string = ""
  canScrollLeft = false;
  canScrollRight = true;
  canArrowLeft = false;
  canArrowRight = true;
  @ViewChild('btnCerrarModal') btnCerrarModal!: ElementRef;
  @ViewChild('btnCerrarModalNuevos') btnCerrarModalNuevos!: ElementRef;
  @ViewChild('btnModalExclusi') btnModalExclusi!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('arrowContainer') arrowContainer!: ElementRef;
  @ViewChild('arrowleft') arrowleft!: ElementRef;
  @ViewChild('arrowrigth') arrowrigth!: ElementRef;
  scrollInterval: any;
  public tagName:any;
  public arrayPages:any=[5,10,20,50,100];
  constructor(private currencyPipe: CurrencyPipe,private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
    private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
    this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
    let fecha = new Date();
  
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this._httpService.setMostrarMenu(true);
    this.indexedDBService.getItem('arrayResult', (value) => {
    this.arrayAccesos=value?value:[];

   if (this.arrayAccesos.length > 0) {
     var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
     if (objAcceso == undefined || objAcceso.length == 0) {
       this.router.navigate(['/login']);
     } else {
       this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
       this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
       this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
       this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
       this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
       this.strGerente = (!this.boolGerente && this.boolSucursal) ? objAcceso[0].strUsuario : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
     }
    }
    else {
     this.router.navigate(['/login']);
    }
   });
   }

  ngOnInit(): void {
    this.fnGetLineas();
    this.fnExtaerOfertas();
    this.fnExtraerDestac();
    this.fnBuscarArticulo();
    this.fnextrerExclusivos();
  }

  ngAfterViewInit() {
    const scrollContainerElement = document.querySelector('#scrollContainer');
    if (scrollContainerElement) {
      this.scrollContainer = new ElementRef(scrollContainerElement);
      this.updateScrollButtons();
    }

    const arrowContainerElement = document.querySelector('#arrowContainer');
    if (arrowContainerElement) {
      this.arrowContainer = new ElementRef(arrowContainerElement);
      this.updateArrowButtons();
    }
  }
  fntab(){
    const scrollContainerElement = document.querySelector('#scrollContainer');
    if (scrollContainerElement) {
      this.scrollContainer = new ElementRef(scrollContainerElement);
      this.updateScrollButtons();
    }
  }

  fnarrowtab(){
    const arrowContainerElement = document.querySelector('#arrowContainer');
    if (arrowContainerElement) {
      this.arrowContainer = new ElementRef(arrowContainerElement);
      this.updateArrowButtons();
    }
  }
      onImageError(event: any) {
        event.target.src = 'assets/Fondos/image.png';
      }

      fnBuscarArticulo(){
        let objenv = {
          strAccion: 'getArtAdd',
          strConexion: this.strConexion
        }
        this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
          result => {
            this.arrayBusquedaArts = result;
            this.fnFiltrarArtsAdd();
           },
          error => {
            var error = <any>error;
            console.log(error);
          });
      }


      fnFiltrarArtsAdd(){
        let searchTerm = this.inputSearch ? this.inputSearch.toLowerCase() : '';
      
        if (searchTerm == "" ||searchTerm ==  undefined) {
          this.artsFiltrados = this.arrayBusquedaArts.slice(0, 100);
        } else {
           this.artsFiltrados = this.arrayBusquedaArts.filter(o => {
            let strArticulo = o.strArticulo ? o.strArticulo.toLowerCase() : '';
            let strDescripcion = o.strDescripcion ? o.strDescripcion.toLowerCase() : '';
            return strArticulo.includes(searchTerm) || strDescripcion.includes(searchTerm);}).slice(0, 100);
        }
      }

      fneliminarBusqueda(art:any){
        this.arrayArtsInsertar = this.arrayArtsInsertar.filter(ar => ar != art )
      }

      /*-------Productos destacados-------*/
      fnExtaerOfertas() {
        this.spinnerDest = true
        let objenv = {
            strAccion: 'getArtsOferta',
            strConexion: this.strConexion
          }
          this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
            result => {
              this.arrayFullArtsOfertas = JSON.parse(JSON.stringify(result));
              this.arrayProductosDestacados = result
             if(this.arrayProductosDestacados.length>0){
                this.arrayProductosDestacados.forEach(item => {
                  item.imageUrl='https://www.cleanclean.mx/imagenes/'+item.strArticulo.trim()+'.JPG'
                  item.hovered = false;
                  item.oferta= Math.ceil( 100-((item.dblSaldo/item.dblPrecioLista)*100))
                });
                this.arrayProductosDestacados.sort((a, b) => a.dblCantidad - b.dblCantidad);
                this.currentPageDest = 1
                this.arrayLineasOfertas = this.arrayProductosDestacados.reduce((obj,{strLinea})=>
                 obj.some(x =>x.strLinea === strLinea) ? obj: [...obj, {strLinea}],[])
                this.paginacionOfertas();
             }else{
              this.arrayItemsDestacados = [];
              this.pagesDestacados = 0;
              this.currentPageDest = 1
             }
            
             this.spinnerDest = false
             },
            error => {this.spinnerDest = false
              var error = <any>error;
              console.log(error);
            }
          );
        }

      paginacionOfertas() {
          this.pagesDestacados = Math.ceil(this.arrayProductosDestacados.length / this.itemsPerPageDest);
          const startIndex = (this.currentPageDest - 1) * this.itemsPerPageDest;
          const endIndex = startIndex + this.itemsPerPageDest;
          this.arrayItemsDestacados = this.arrayProductosDestacados.slice(startIndex, endIndex);
      }
    
      changePageDest(page: number){
        if (page >= 1 && page <= this.pagesDestacados) {
          this.currentPageDest =page
          this.paginacionOfertas();
        }
      }

      fnItemsPerPagedest(cant:number){
        this.itemsPerPageDest = cant;
        this.currentPageDest = 1
        this.paginacionOfertas();
      }

      fnEliminarArtsOferta(art:any){
        Swal.fire({
          title: "¿Estas seguro de eliminar este articulo destacado: "+"("+art.strArticulo+")"+art.strDescripcion+"?",
          showDenyButton: true,
          confirmButtonText: "SI",
          denyButtonText: "NO",
          customClass: {
            confirmButton: 'swal-button-spacing', // Clase personalizada para el botón "SI"
            denyButton: 'swal-button-spacing',    // Clase personalizada para el botón "NO"
          }
        }).then((result) => {
          if (result.isConfirmed) {
            let objenv = {
              strAccion: 'deleteDestacado',
              intID_Pedido:art.intID_Pedido,
              strConexion: this.strConexion
            }
        
            this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
              result => {
                  let res = result[0];
                  if(res.code == "200"){
                    Swal.fire({
                      title: "Eliminado",
                      text: "El artículo se eliminó correctamente",
                      icon: "success",
                      timer: 2000, 
                      timerProgressBar: true, 
                      showConfirmButton: false 
                    });
                    this.fnExtaerOfertas();
                  }else{
                    Swal.fire("Error", "Error al eliminar articulo", "error");
                  }
                },error => {
                var error = <any>error;
                console.log(error);
              });
          }
        });
      }
      
      fnInsertarArts(art:any){
        let encontrado = this.arrayArtsInsertar.find(o => o.strArticulo == art.strArticulo)
        let encontradoDestacado = this.arrayProductosDestacados.find(o => o.strArticulo == art.strArticulo)
        if(encontrado || encontradoDestacado){
          Swal.fire("ERROR","el articulo ya esta agregado","error")
        }else{
          let fechaActual = new Date();
          let artNew = {
            ...art,
            dblPrecioOferta: 0,
            boolbg:false,
            strFechaLastCm:fechaActual,
            strTipo:"",
          };
          this.arrayArtsInsertar.push(artNew);
        }
      }

      fnGuardarArtsDest(){
        let xmlArt:any = [];
        let intPrecios:number =0;
        this.arrayArtsInsertar.forEach(art => {
          xmlArt.push({strArticulo:art.strArticulo,strEstatus:art.strEstatus,strFechaCambio:art.strFechaLastCm,dblPrecioOferta:art.dblPrecioOferta,strTipo:art.strTipo,});
        });
        let bandera = 0
        this.arrayArtsInsertar.forEach(art => {
          if((art.dblPrecioOferta==0 && art.strTipo=="")||((art.strTipo=="LIQUIDACION" || art.strTipo=="OFERTA") && art.dblPrecioOferta>0 )){
            bandera = bandera+1
            art.boolbg = false;
            intPrecios = art.strTipo=="LIQUIDACION" || art.strTipo=="OFERTA"?intPrecios +1:intPrecios;
          }else{
            art.boolbg = true;
          }
        });
        if(bandera == this.arrayArtsInsertar.length){
          // Swal.fire("Correcto","ArrayCorrecto","success");
          let objenv = {
            strAccion: 'insertarDestacados',
            xmlArticulos: xmlArt,
            strConexion: this.strConexion
          }
          if(intPrecios==0){
            this.fnGuardar(objenv,"DESTACADO");
          }else{
           let tablaHtml = this.generarTablaHtml(this.arrayArtsInsertar);
            Swal.fire({
              title: "¿Los precios son correctos?",
              html: tablaHtml,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "GUARDAR",
               cancelButtonText: "CANCELAR"
            }).then((result) => {
              if (result.isConfirmed) {
                this.fnGuardar(objenv,"DESTACADO");
              }
            });
          }
        }else{
          Swal.fire("Error","Favor de llenar campos de precio oferta","error")
        }
      }
     /*-------FIN Productos destacados-------*/

        /*-------Productos Nuevos-------*/
        fnExtraerDestac(){
          this.spinnerNuevo = true;
          let objenv = {
              strAccion: 'getArtsDest',
              strConexion: this.strConexion
            }
            this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
              result => {
                this.arrayFullArtsDestacados = JSON.parse(JSON.stringify(result));
                this.arrayNovedades = result
                if(this.arrayNovedades.length>0){
                  this.arrayNovedades.forEach(item => {
                    item.hovered = false;
                    item.oferta= Math.ceil( 100-((item.dblSaldo/item.dblPrecioLista)*100))
                  });
               }
               this.arrayItemsNuevos = this.arrayNovedades
               this.arrayItemsNuevos.forEach(nuev => {
                nuev.imageUrl='https://www.cleanclean.mx/imagenes/'+nuev.strArticulo.trim()+'.JPG'
               });

               this.arrayLineasDest = this.arrayNovedades.reduce((obj,{strLinea})=>
                obj.some(x =>x.strLinea === strLinea) ? obj: [...obj, {strLinea}],[])
               this.arrayNovedades.sort((a, b) => a.dblCantidad - b.dblCantidad);

               this.currentPage =1;
               this.updatePaginDestacados();
               this.spinnerNuevo = false;
               },
              error => {
                this.spinnerNuevo = false;
                var error = <any>error;
                console.log(error);
              }
            );
          }

          fnEliminarArtsNuevos(art:any){
            Swal.fire({
              title: "¿Estas seguro de eliminar este articulo destacado: "+"("+art.strArticulo+")"+art.strDescripcion+"?",
              showDenyButton: true,
              confirmButtonText: "SI",
              denyButtonText: "NO",
              customClass: {
                confirmButton: 'swal-button-spacing', // Clase personalizada para el botón "SI"
                denyButton: 'swal-button-spacing',    // Clase personalizada para el botón "NO"
              }
            }).then((result) => {
              if (result.isConfirmed) {
                let objenv = {
                  strAccion: 'deleteNuevo',
                  intID_Pedido:art.intID_Pedido,
                  strConexion: this.strConexion
                }
 
                this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
                  result => {
                      let res = result[0];
                      if(res.code == "200"){
                        Swal.fire({
                          title: "Eliminado",
                          text: "El artículo se eliminó correctamente",
                          icon: "success",
                          timer: 2000, 
                          timerProgressBar: true, 
                          showConfirmButton: false 
                        });
                        this.fnExtraerDestac();
                      }else{
                        Swal.fire("Error", "Error al eliminar articulo", "error");
                      }
                    },error => {
                    var error = <any>error;
                    console.log(error);
                  });
              }
            });
          }

          fnFiltrarNuevos(){
            let searchTerm = this.inputSearch ? this.inputSearch.toLowerCase() : '';
            if (searchTerm == '') {
              this.artsFiltrados = this.arrayBusquedaArts.slice(0, 100);
            } else {
               this.artsFiltrados = this.arrayBusquedaArts.filter(o =>{  
                let strArticulo = o.strArticulo ? o.strArticulo.toLowerCase() : '';
                let strDescripcion = o.strDescripcion ? o.strDescripcion.toLowerCase() : '';
                return strArticulo.includes(searchTerm) || strDescripcion.includes(searchTerm);}).slice(0, 100);
            }
          }

        updatePaginDestacados() {
          this.totalPages = Math.ceil(this.arrayNovedades.length / this.itemsPerPage);
          const startIndex = (this.currentPage - 1) * this.itemsPerPage;
          const endIndex = startIndex + this.itemsPerPage;
          this.arrayItemsNuevos = this.arrayNovedades.slice(startIndex, endIndex);
        }
      
        changePage(page: number) {
          if (page >= 1 && page <= this.totalPages) {
            this.currentPage = page;
            this.updatePaginDestacados();
          }
        }
  
        fnItemsPerPage(cant:number){
          this.currentPage  = 1
          this.itemsPerPage = cant;
          this.updatePaginDestacados();
        }

        fnGuardarArtsNuevos(){
          let xmlArt:any = [];
          let intPrecios:number = 0
          this.arrayArtsInsertar.forEach(art => {
            xmlArt.push({strArticulo:art.strArticulo,strEstatus:art.strEstatus,strFechaCambio:art.strFechaLastCm,dblPrecioOferta:art.dblPrecioOferta,strTipo:art.strTipo,}) 
          });
          let bandera = 0
          this.arrayArtsInsertar.forEach(art => {
            if((art.dblPrecioOferta==0 && art.strTipo=="")||((art.strTipo=="LIQUIDACION" || art.strTipo=="OFERTA") && art.dblPrecioOferta>0 )){
              bandera = bandera+1
              art.boolbg = false;
              intPrecios = art.strTipo=="LIQUIDACION" || art.strTipo=="OFERTA"?intPrecios +1:intPrecios;
            }else {
              art.boolbg = true;
            }
          });
          if(bandera == this.arrayArtsInsertar.length){
            let objenv = {
              strAccion: 'insertarNuevos',
              xmlArticulos: xmlArt,
              strConexion: this.strConexion
            }
            if(intPrecios==0){
              this.fnGuardar(objenv,"NUEVO");
            }else{
             let tablaHtml = this.generarTablaHtml(this.arrayArtsInsertar);
              Swal.fire({
                title: "¿Los precios son correctos?",
                html: tablaHtml,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "GUARDAR",
                 cancelButtonText: "CANCELAR"
              }).then((result) => {
                if (result.isConfirmed) {
                  this.fnGuardar(objenv,"NUEVO");
                }
              });
            }
            
          }else{
            Swal.fire("Error","Favor de llenar campos de precio oferta","error")
          }
        }
       /*-------FIN Productos Nuevos-------*/
       removeLeadingZeros(event: Event, ind: number): void {
        const inputElement = event.target as HTMLInputElement;
        let originalValue = inputElement.value;
        if (originalValue === '') {
          inputElement.value = '0';
          this.arrayArtsInsertar[ind].dblPrecioOferta = 0;
          return;
        }
        // Eliminar ceros a la izquierda
        const newValue = originalValue.replace(/^0+(?=\d)/, '');

        // Actualizar el valor del input y la propiedad enlazada si ha cambiado
        if (newValue !== originalValue) {
          inputElement.value = newValue;
          this.arrayArtsInsertar[ind].dblPrecioOferta = parseFloat(newValue);
        }
      }
       
      fnLimpiarModal(){
        this.arrayArtsInsertar = [];
        this.inputSearch = "";
        this.fnFiltrarArtsAdd();
        setTimeout(() => {
          const scrollContainerElement = document.querySelector('#scrollContainer');
          if (scrollContainerElement) {
            this.scrollContainer = new ElementRef(scrollContainerElement);
            this.updateScrollButtons();
          }
          const arrowContainerElement = document.querySelector('#arrowContainer');
          if (arrowContainerElement) {
            this.arrowContainer = new ElementRef(arrowContainerElement);
            this.updateArrowButtons();
          }
        }, 500);
      }

      fnModalExclusivos(){
        this.arrayArtsInsertar = [];
        this.inputSearch = "";
        this.fnFiltrarArtsAdd();
    
      }

      fnInsertarArtsNuevos(art:any){
        let encontrado = this.arrayArtsInsertar.find(o => o.strArticulo == art.strArticulo)
        let encontradoNuevo = this.arrayNovedades.find(o => o.strArticulo == art.strArticulo)
        if(encontrado || encontradoNuevo){
          Swal.fire("ERROR","el articulo ya esta agregado","error")
        }else{
          let fechaActual = new Date();
          let artNew = {
            ...art,
            dblPrecioOferta: 0,
            boolbg:false,
            strFechaLastCm:fechaActual,
            strTipo:"",
          };
          this.arrayArtsInsertar.push(artNew);
        }
       
      }

      onChangeTipo(event: Event,obj:any): void {
        let selectedValue = (event.target as HTMLSelectElement).value;
        if(selectedValue == ""){
          obj.dblPrecioOferta = 0;
        }
        
      }
      fnGuardar(obj:any,tipo:string){
        console.log(obj,tipo)
        this._httpService.mostrarCotizaciones(obj, 'sp_ECommerce').subscribe(
          result => {
            console.log(result)
            let rest = result[0]
             if(rest.code == "200"){
              Swal.fire({
                title: "Guardado!",
                text: "Se guardaron correctamente los articulos.",
                icon: "success"
              });
               if(tipo == "NUEVO"){
                this.fnExtraerDestac();
                this.btnCerrarModalNuevos.nativeElement.click();
               }else if(tipo == "DESTACADO"){
                this.fnExtaerOfertas();
                this.btnCerrarModal.nativeElement.click();
               }else if(tipo == "EXCLUSIVO"){
                this.fnextrerExclusivos();
                this.btnModalExclusi.nativeElement.click();
               }
             }
            else{
              Swal.fire({
                title: "Error",
                text: "Al guardar productos.",
                icon: "error"
              });
             }
            },error => {
            var error = <any>error;
            console.log(error);
          });
      }

      generarTablaHtml(data: any[]): string {
        let html = '<table class="table table-bordered" style="width:100%; text-align:left;">';
        html += '<thead><tr><th style="text-align:center;font-size:13px;" >ARTICULO</th><th style="text-align:center;font-size:13px;">TIPO</th><th style="text-align:center;font-size:13px;">PRECIO OFERTA</th></tr></thead><tbody>';
        data.forEach((item) => {
          let precioFormateado = this.currencyPipe.transform(item.dblPrecioOferta , 'MXN', 'symbol-narrow', '1.2-2');
          let precioLista = this.currencyPipe.transform(item.dblPrecioLista , 'MXN', 'symbol-narrow', '1.2-2');
          html += `<tr><td style="text-align:center;font-size:12px;">${item.strArticulo}</td><td style="text-align:center;font-size:12px;">${item.strTipo}</td><td style="text-align:center;font-size:12px;">${item.strTipo=="LIQUIDACION" ||item.strTipo=="OFERTA"||item.strTipo=="EXCLUSIVO"?precioFormateado:precioLista}</td></tr>`;
        });
        html += '</tbody></table>';
        return html;
      }

      fnUpdateOrder(obj:any,strTipo:string){
        let objEnviar = {
          strAccion:strTipo=="OFERTA"?"updateOrdenOfertas":strTipo=="EXCLUSIVO"?"updateOrdenExclus":"updateOrdenDes",
          dblValor_Cupon:obj.dblCantidad,
          strArticulo:obj.strArticulo,
          strConexion:this.strConexion
        }
        this._httpService.mostrarCotizaciones(objEnviar, 'sp_ECommerce').subscribe(
          result => {
            let rest = result[0]
            
             if(rest.code == "200"){
              Swal.fire({
                title: "Guardado!",
                text: "Se guardaron correctamente.",
                icon: "success"
              });
               if(strTipo == "DESTACADO"){
                this.fnExtraerDestac();
                this.btnCerrarModalNuevos.nativeElement.click();
               }else if(strTipo == "OFERTA"){
                this.fnExtaerOfertas();
                this.btnCerrarModal.nativeElement.click();
               }else if(strTipo == "EXCLUSIVO"){
                this.fnextrerExclusivos();
                this.btnModalExclusi.nativeElement.click();
               }
               
             }else{
              Swal.fire({
                title: "Error",
                text: "Al guardar productos.",
                icon: "error"
              });
             }
            },error => {
            var error = <any>error;
            console.log(error);
            Swal.fire({
              title: "Error",
              text: "Al guardar productos.",
              icon: "error"
            });
          });
      }
      fnGetLineas(){
        let objEnviar = {
          strAccion:"getLineas",
          strConexion:this.strConexion
        }
        this._httpService.mostrarCotizaciones(objEnviar, 'sp_ECommerce').subscribe(
          result => {
            let rest = result
            this.arrayLineas  = rest;
           
             this.arrayLineas.forEach(lin => {
              lin.active = false
             });
            },error => {
            var error = <any>error;
            console.log(error);
            Swal.fire({
              title: "Error",
              text: "Al extraer lineas."+error.message,
              icon: "error"
            });
          });
      }


  scrollLeft() {
    const container = this.scrollContainer.nativeElement;
    container.scrollLeft -= 200; // Ajusta el valor según lo necesario
    this.updateScrollButtons();
  }

  scrollRight() {
    const container = this.scrollContainer.nativeElement;
    container.scrollLeft += 200; // Ajusta el valor según lo necesario
    this.updateScrollButtons();
  }

  fnarrowLeft() {
    const arrow = this.arrowContainer.nativeElement;
    arrow.scrollLeft -= 200; // Ajusta el valor según lo necesario
    this.updateArrowButtons();
  }

  fnarrowRight() {
    const arrow = this.arrowContainer.nativeElement;
    arrow.scrollLeft += 200; 
    this.updateArrowButtons();
  }


  updateScrollButtons(){
    const container = this.scrollContainer.nativeElement;
    this.canScrollLeft = container.scrollLeft > 0;
    this.canScrollRight = container.scrollLeft + container.offsetWidth < container.scrollWidth;
  }

  updateArrowButtons(){
    const arrow = this.arrowContainer.nativeElement;
    this.canArrowLeft = arrow.scrollLeft > 0;
    this.canArrowRight = arrow.scrollLeft + arrow.offsetWidth < arrow.scrollWidth;
  }


  fnActivelin(linea:string){
    this.arrayLineas.forEach(lin => {
      if(lin.strLinea == linea){
        lin.active = true;
      }else{
        lin.active = false;
      }
    })
    this.arrayArtsInsertar = this.arrayBusquedaArts.filter(o => o.strLinea == linea && !this.arrayProductosDestacados.some(destacado => destacado.strArticulo === o.strArticulo) );
    
    this.arrayArtsInsertar.forEach(item => {
      item.strTipo = "OFERTA";
      item.dblPrecioOferta =item.dblPrecioLista - (this.porcentajeDesc * (item.dblPrecioLista/100));
    });
  }
  validarDescuento(event: Event): void {
    const input = event.target as HTMLInputElement;
  
    // Elimina cualquier carácter que no sea número
    let valor = input.value.replace(/[^0-9]/g, '');
  
    // Si el valor comienza con ceros, elimínalos
    if (valor.startsWith('0') && valor.length > 1) {
      valor = valor.replace(/^0+/, '');
    }
  
    // Asegúrate de que el número esté entre 0 y 100
    const numero = parseInt(valor, 10);
    if (numero > 100) {
      valor = '100';
    }
  
    // Actualiza el valor en el input
    input.value = valor;
    this.porcentajeDesc = valor ? parseInt(valor, 10) : null;
    this.fnCambiarPorcentaje()
  }
  fnCambiarPorcentaje(){
    this.arrayArtsInsertar.forEach(item => {
      item.strTipo = "OFERTA";
      item.dblPrecioOferta =item.dblPrecioLista - (this.porcentajeDesc * (item.dblPrecioLista/100));
    });
  }
  fnEliminarLinea(){
    let mensaje = this.strLinea == ""?" ¿Estas seguro de eliminar todos los productos en oferta?":"¿Estas seguro de eliminar la linea "+this.strLinea+"?"
    
    Swal.fire({
      title: mensaje,
      showDenyButton: true,
      confirmButtonText: "SI",
      denyButtonText: "NO",
      customClass: {
        confirmButton: 'swal-button-spacing', // Clase personalizada para el botón "SI"
        denyButton: 'swal-button-spacing',    // Clase personalizada para el botón "NO"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let xmlArt = [];
        this.arrayProductosDestacados.forEach(art => {
          xmlArt.push({strArticulo:art.strArticulo})
        });
        let objenv = {
          strAccion: 'deleteLinOfertas',
          xmlArticulos:xmlArt,
          strConexion: this.strConexion
        }
        this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
          result => {
              let res = result[0];
              if(res.code == "200"){
                Swal.fire({
                  title: "Eliminado",
                  text: res.strMensaje,
                  icon: "success",
                  timer: 2000, 
                  timerProgressBar: true, 
                  showConfirmButton: false 
                });
                this.fnExtaerOfertas();
              }else{
                Swal.fire("Error",res.strMensaje, "error");
              }
            },error => {
            var error = <any>error;
            console.log(error);
          });
      }
    });
  }
  fnSeleccionarLinea(){
    this.arrayProductosDestacados = this.strLinea == ""? this.arrayFullArtsOfertas: this.arrayFullArtsOfertas.filter( o => o.strLinea == this.strLinea);
    this.arrayProductosDestacados.forEach(item => {
      item.imageUrl='https://www.cleanclean.mx/imagenes/'+item.strArticulo.trim()+'.JPG'
      item.hovered = false;
      item.oferta= Math.ceil( 100-((item.dblSaldo/item.dblPrecioLista)*100))
    });
    this.arrayProductosDestacados.sort((a, b) => a.dblCantidad - b.dblCantidad);
    this.currentPageDest = 1
    this.paginacionOfertas();
  }

  fnEliminarLineaDest(){
    let mensaje = this.strLinea == ""?" ¿Estas seguro de eliminar todos los productos destacados?":"¿Estas seguro de eliminar la linea "+this.strLinea+"?"
    Swal.fire({
      title: mensaje,
      showDenyButton: true,
      confirmButtonText: "SI",
      denyButtonText: "NO",
      customClass: {
        confirmButton: 'swal-button-spacing', // Clase personalizada para el botón "SI"
        denyButton: 'swal-button-spacing',    // Clase personalizada para el botón "NO"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let xmlArt = [];
        this.arrayNovedades.forEach(art => {
          xmlArt.push({strArticulo:art.strArticulo})
        });
        let objenv = {
          strAccion: 'deleteLinDest',
          xmlArticulos:xmlArt,
          strConexion: this.strConexion
        }
        this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
          result => {
              let res = result[0];
              if(res.code == "200"){
                Swal.fire({
                  title: "Eliminado",
                  text: res.strMensaje,
                  icon: "success",
                  timer: 2000, 
                  timerProgressBar: true, 
                  showConfirmButton: false 
                });
                this.fnExtraerDestac();
              }else{
                Swal.fire("Error",res.strMensaje, "error");
              }
            },error => {
            var error = <any>error;
            console.log(error);
          });
      }
    });
  }
  fnSeleccionarLineaDest(){
    this.arrayNovedades = this.strLinea == ""? this.arrayFullArtsDestacados: this.arrayFullArtsDestacados.filter( o => o.strLinea == this.strLinea);
      this.arrayNovedades.forEach(item => {
        item.hovered = false;
        item.oferta= Math.ceil( 100-((item.dblSaldo/item.dblPrecioLista)*100))
      });
   this.arrayItemsNuevos = this.arrayNovedades
   this.arrayItemsNuevos.forEach(nuev => {
    nuev.imageUrl='https://www.cleanclean.mx/imagenes/'+nuev.strArticulo.trim()+'.JPG'
   });
   this.arrayNovedades.sort((a, b) => a.dblCantidad - b.dblCantidad);
   this.currentPage =1;
   this.updatePaginDestacados();
   this.spinnerNuevo = false;
  }

fnextrerExclusivos(){
  this.spinnerExclusivo = true;
  this.arrayItemsExclusivos = []
  let objenv = {
    strAccion: 'getArtsExclu',
    strConexion: this.strConexion
  }
  this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
    result => {
      this.arrayItemsExclusivos = result;
      console.log(this.arrayItemsExclusivos);
      this.spinnerExclusivo = false;
    }, error =>{
      this.spinnerExclusivo = false;
    })
}

  fnGuardarArtExclusivos(){
 
    let xmlArt:any = [];
    let intPrecios:number =0;
    this.arrayArtsInsertar.forEach(art => {
      xmlArt.push({strArticulo:art.strArticulo,strEstatus:art.strEstatus,strFechaCambio:art.strFechaLastCm,dblPrecioOferta:art.dblPrecioOferta,strTipo:art.strTipo,});
    });
    let bandera = 0
    this.arrayArtsInsertar.forEach(art => {
      if((art.dblPrecioOferta==0 && art.strTipo=="")||((art.strTipo=="LIQUIDACION" || art.strTipo=="OFERTA"|| art.strTipo=="EXCLUSIVO") && art.dblPrecioOferta>0 )){
        bandera = bandera+1
        art.boolbg = false;
        intPrecios = art.strTipo=="LIQUIDACION" || art.strTipo=="OFERTA"|| art.strTipo=="EXCLUSIVO"?intPrecios +1:intPrecios;
      }else{
        art.boolbg = true;
      }
    });
    if(bandera == this.arrayArtsInsertar.length){
      let objenv = {
        strAccion: 'insertarEclusivos',
        xmlArticulos: xmlArt,
        strConexion: this.strConexion
      }
       let tablaHtml = this.generarTablaHtml(this.arrayArtsInsertar);
        Swal.fire({
          title: "¿Los precios son correctos?",
          html: tablaHtml,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "GUARDAR",
           cancelButtonText: "CANCELAR"
        }).then((result) => {
          if (result.isConfirmed) {
            this.fnGuardar(objenv,"EXCLUSIVO");
          }
        });
        
    }else{
      Swal.fire("Error","Favor de llenar campos de precio oferta","error")
    }
  }


  fnInsertarArtsExclusivos(art:any){
    let encontrado = this.arrayItemsExclusivos.find(o => o.strArticulo == art.strArticulo)
    let encontradoNuevo = this.arrayItemsExclusivos.find(o => o.strArticulo == art.strArticulo)
    if(encontrado || encontradoNuevo){
      Swal.fire("ERROR","el articulo ya esta agregado","error")
    }else{
      let fechaActual = new Date();
      let artNew = {
        ...art,
        dblPrecioOferta: 0,
        boolbg:false,
        strFechaLastCm:fechaActual,
        strTipo:"EXCLUSIVO",
      };
      this.arrayArtsInsertar.push(artNew);
    }
   
  }

  fnEliminarArtsExclusivos(art:any){
    Swal.fire({
      title: "¿Estas seguro de eliminar este articulo destacado: "+"("+art.strArticulo+")"+art.strDescripcion+"?",
      showDenyButton: true,
      confirmButtonText: "SI",
      denyButtonText: "NO",
      customClass: {
        confirmButton: 'swal-button-spacing', // Clase personalizada para el botón "SI"
        denyButton: 'swal-button-spacing',    // Clase personalizada para el botón "NO"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let objenv = {
          strAccion: 'deleteExclusivos',
          intID_Pedido:art.intID_Pedido,
          strConexion: this.strConexion
        }

        this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
          result => {
              let res = result[0];
              if(res.code == "200"){
                Swal.fire({
                  title: "Eliminado",
                  text: "El artículo se eliminó correctamente",
                  icon: "success",
                  timer: 2000, 
                  timerProgressBar: true, 
                  showConfirmButton: false 
                });
                this.fnExtraerDestac();
              }else{
                Swal.fire("Error", "Error al eliminar articulo", "error");
              }
            },error => {
            var error = <any>error;
            console.log(error);
          });
      }
    });
  }

}
