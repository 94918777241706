import { Component, NgZone, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
import { FormasPagoService } from '../servicios/formas-pago.service';
import { PeticionesService } from '../servicios/httpservice';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-pago-error',
  templateUrl: './pago-error.component.html',
  styleUrls: ['./pago-error.component.css']
})
export class PagoErrorComponent implements OnInit {
  private idEncriptado:string;
  private id:string
  private secretKey ='ece1122';
  public strConexion:string = "COMERCIAL";
/*   public form1:any;
  public form_f:any; */
  public errorPago:any;
  private public_key:string='TEST-2e4c3c6b-f817-4fac-a957-aefb90e4848c';
  constructor(private ngZone: NgZone,private router: Router,private formPago:FormasPagoService, private renderer: Renderer2,private cartService: EcommerceCarrService,private fb: FormBuilder, private _httpService:PeticionesService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.idEncriptado = params['id'];
      if (this.idEncriptado) {
        this.id = this.desencriptarParametro(this.idEncriptado);
      }
      
    /*   try {
        this.errorPago = params['mensajeError'];
        this.form1 = params['form1'] ? JSON.parse(params['form1']) : null;
        this.form_f = params['form2'] ? JSON.parse(params['form2']) : null;
      } catch (error) {
        console.error('Error al parsear form1 o form2:', error);
      } */
    });
    
  }
  desencriptarParametro(idEncriptado: string): string {
    const bytes = CryptoJS.AES.decrypt(idEncriptado, this.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  fnIntentarDeNuevo(){

/*     let valoresForm_f = JSON.stringify(this.form_f);
    let valoresformIni = JSON.stringify(this.form1); */
    //resetamos el brick para que puedan enviarla de nuevo
      this.router.navigate(['/pasarela'], {
        queryParams: { tipo:"error",id: this.idEncriptado},/* ,form1:valoresformIni,form2:valoresForm_f */
      });
  }

  fnSeguirComprando(){
    this.router.navigate(['/tienda'])
  }
}
