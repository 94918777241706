import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agenteFilter'
})
export class AgenteFilterPipe implements PipeTransform {

  transform(value:any, gte: any, suc:any): any {
 
  let resultPost=[];
  if(gte=='' && suc ==''){
    return resultPost = value;
  }
  else if (gte =='' && suc !=''){
    for(const post of value){
      if(post.strSucursal==suc ){
        resultPost.push(post);
      }  
    
    };
    return resultPost;   
  }
  else if (gte !='' && suc ==''){
    for(const post of value){
      if(post.strGerente==gte){
        resultPost.push(post);
      }  
    
    };
    return resultPost;   
  }
  else {
    for(const post of value){
      if(post.strGerente==gte  && post.strSucursal==suc ){
        resultPost.push(post);
      }  
    
    };
    return resultPost;   
  } 
  //return null;
}


}
