import { Component, OnInit,ElementRef,AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorageService } from 'ngx-webstorage';
import {MatPaginator,MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import Swal from 'sweetalert2';
import { ComodatoService } from '../servicios/comodato.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataDBService } from '../servicios/data-db.service';
export interface UserData {
  strIDComodatos: string;
  strCliente: string;
  strSucursal: string;
  strClienteNombreCorto:string;
  strAgenteNombre:string;
  strFechaSolicitud:string;
  strFechaInstalacion:string;
  strEstatus:string;
  atencion:string;
}
export interface dataRenov {
  strCliente: string;
  strNombreCte: string;
  strFechaInicio: string;
  strCteEnviarA: string;
  strObservaciones: string;
  intIDCancelacion: string;
  strAgente: string;
  strEstatus: string;
  intIDComodatos: string;
  intID: string;
}
export interface dataCancelacion {
  strCliente: string;
  strNombreCte: string;
  strFechaInicio: string;
  strCteEnviarA: string;
  strObservaciones: string;
  intIDCancelacion: string;
  strAgente: string;
  strEstatus: string;
  intIDComodatos: string;
  intID: string;
} 


@Component({
  selector: 'app-listado-comodatos',
  templateUrl: './listado-comodatos.component.html',
  styleUrls: ['./listado-comodatos.component.css']
})
export class ListadoComodatosComponent implements OnInit , OnDestroy{
  columnsRenovacion: string[] = ['strCliente', 'strNombreCte', 'strFechaInicio', 'strCteEnviarA', 'strObservaciones','intIDCancelacion','strAgente','strEstatus','intIDComodatos','intID'];/* , 'fechaVencimiento' ,'progreso'*/
  columnsCancelacion:string[] = ['strCliente', 'strNombreCte', 'strFechaInicio', 'strCteEnviarA', 'strObservaciones','intIDCancelacion','strAgente','strEstatus','intIDComodatos','intID'];
  displayedColumns: string[] = ['strIDComodatos', 'strCliente','strClienteNombreCorto','strSucursal', 'strAgenteNombre','strFechaSolicitud','strFechaInstalacion','strEstatus','atencion'];
  @ViewChild('btnModalRenovacion') btnModalRenovacion!: ElementRef;
  @ViewChild('btnModalCancelar') btnModalCancelar!: ElementRef;
  
  public arraylistComodatos:any;
  public arrayFiltrado:any;
  public arrayFiltradoRenovacion:any=[];
  public arrayFiltradoCancelacion:any=[];
  public objModalRenovacion:any;
  public objModalCancelar:any;
  public strGerente:string="Todos"; 
  public strSucursal:string="";
  public strAgente:string="";
  public flagrechazo:boolean=false;
  public spinerMod:boolean=false;
  public dateFechaActual:Date= new Date();
  public intEjercicio:number=0;
  public intPeriodo:number=0;
  public strEstatus:string="PENDIENTE";
  public arrayGerente:any;
  public strMotivoRechazoRenovacion:string=""; 
  public strMotivoRechazoCancelacion:string="";
  public strComentarioEnviarCancelacion:string="";
  public isHoveredButton:boolean;
  public boolGerente:boolean;
  public boolSucursal:boolean;
  public boolAgente:boolean;
  public arrayAgente:any;
  public arrayArtsRenovacion:any;
  public arrayArtsCancelacion:any;
  public agenteSelected:any;
  datosSolCancelacion: MatTableDataSource<dataCancelacion>;
  datosSolRenovacion: MatTableDataSource<dataRenov>;
  dataSource: MatTableDataSource<UserData> ;
  //public arraylistComodatos:any;
  public strComentarioEnviar:string="";
  public mensajeError:string="Seleccione un agente";
  public arrayGerentes:any = [];
  public arraySucursales:any = [];
  public arrayAgentes:any = [];
  public arrayPeriodo:any = [];
  public arrayEjercicio:any = [];
  public arrayEstatus:any=["PENDIENTE","AUTORIZADO","RECHAZADO"];
  public strBuscarRenovacion:string="";
  public spinSol:boolean=false;
  public strEstautsRenovacion:string="PENDIENTE";
  public strBuscarCancelacion:string="";
  public strEstautsCancelacion:string="PENDIENTE";
  public arrayAccesos:any = [];
  public tagName:any;
  public strConexion:string="";
  public spiner:boolean=false;
  public arraylistRenovacion:any =[];
  public arraylistCancelacion:any=[];
  @ViewChild('paginadorSolicitud',{ static: false }) paginadorSolicitud: MatPaginator;
  @ViewChild('sortRenovacion',{ static: false }) sortRenovacion: MatSort;
  @ViewChild('paginadorRenovacion',{ static: false }) paginadorRenovacion: MatPaginator;
  @ViewChild('sortSolicitud',{ static: false }) sortSolicitud: MatSort;
  @ViewChild('paginadorCancelacion',{ static: false }) paginadorCancelacion: MatPaginator;
  @ViewChild('sortCancelacion',{ static: false }) sortCancelacion: MatSort;

  private unsubscribe$ = new Subject<void>();
  constructor(private indexedDBService: DataDBService,public commodatoservice:ComodatoService,elem:ElementRef,private _peticionesService: PeticionesService, public _alertify: AlertifyService, private paginatorIntl: MatPaginatorIntl, private localSt:LocalStorageService, private router: Router,private _filtroServ :FiltrosService) {

    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];

    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      } else {
        this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
        this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.strSucursal =!this.boolSucursal  ? this.localSt.retrieve('strSucursal'): '';
        this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
        this.strAgente=this.boolAgente?'': this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strGerente =(!this.boolGerente && this.boolSucursal )?this.localSt.retrieve('strUsuario'): ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.fnGetComodatos();
        this.fnGetSolicitud_Renovacion();
        this.fnGetSolicitud_Cancelacion();
      }
    }
    this.customizeItemsPerPageLabel();
  }
  
  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.fnGetFiltros();
    });
  
  }

  ngAfterViewInit() {
    if (this.arraylistComodatos && this.arraylistComodatos.length > 0) {
      this.fnTabledataSol();
    }

    if (this.arraylistRenovacion && this.arraylistRenovacion.length > 0) {
      this.fnTabledataRenov();
    }

    if (this.arraylistCancelacion && this.arraylistCancelacion.length > 0) {
      this.fnTabledataCnacelacion();
    }
  } 
  ngOnDestroy(): void {
    // Emitir un valor para completar las suscripciones
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  fnTabledataSol() {
    this.dataSource.paginator = this.paginadorSolicitud;
    this.dataSource.sort = this.sortSolicitud;
  }

  fnTabledataRenov() {
    this.datosSolRenovacion.paginator = this.paginadorRenovacion;
    this.datosSolRenovacion.sort = this.sortRenovacion;
  }
    
  fnTabledataCnacelacion() {
    this.datosSolCancelacion.paginator = this.paginadorCancelacion;
    this.datosSolCancelacion.sort = this.sortCancelacion;
  }
  
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'items por página';
  }

  fnRedirecciona_Detalle(obj_solicitud:any){
   
     this.router.navigate(['/comodatoDetalle'], { queryParams: { id: obj_solicitud.intIDComodatos} });
   
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
    
      this.arrayAgentes = nuevoValor;
    }); 

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });
 
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    }); 
  }

  fnGetSol(){
   
    this.fnGetComodatos();
    this.fnGetSolicitud_Renovacion();
    this.fnGetSolicitud_Cancelacion();
   
  }

  fnGetComodatos(){
    this.spinSol = true
    let objDatos={
    strSucursal:this.strSucursal,
    strAgente:this.strAgente,
    intEjercicio:this.intEjercicio,
    intPeriodo:this.intPeriodo,
    strGerente:this.strGerente ,
    strAccion:"getListado_Solicitudes",
    strConexion:this.strConexion
  }
  console.log(objDatos);
    this._peticionesService.getComodatos(objDatos,"sp_ModVentas_Comodato")
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      this.arraylistComodatos=result;
      if (this.arraylistComodatos && this.arraylistComodatos.length > 0) {
        this.arrayFiltrado = this.arraylistComodatos.filter(c=> c.strEstatus == this.strEstatus)
        this.dataSource = new MatTableDataSource(this.arrayFiltrado);
        console.log(result);
        this.fnTabledataSol();
      }
      this.spinSol = false
    },error=>{
      this.spinSol = false
       console.error('Error al consumir la API:', error);
    });
  }
  fnGetSolicitud_Cancelacion(){
    this.arraylistCancelacion=[];
    this.spiner = true
    let objDatos={
    strSucursal:this.strSucursal,
    strAgente:this.strAgente,
    intEjercicio:this.intEjercicio,
    intPeriodo:this.intPeriodo,
    strGerente:this.strGerente ,
    strAccion:"getSolicitudCancelacion",
    strConexion:this.strConexion
  }
    this._peticionesService.getComodatos(objDatos,"sp_ModVentas_Comodato")
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
    result => {
      this.arraylistCancelacion=result;
      this.arrayFiltradoCancelacion = this.strEstautsCancelacion==""?this.arraylistCancelacion:this.arraylistCancelacion.filter(c=> c.strEstatus == this.strEstautsCancelacion);
      this.datosSolCancelacion = new MatTableDataSource(this.arrayFiltradoCancelacion);
      this.fnTabledataCnacelacion();
      this.spiner = false
    },error=>{
      this.spiner = false
       console.error('Error al consumir la API:', error);
    });
  }

  fnGetSolicitud_Renovacion(){
    this.arraylistRenovacion=[];
    this.spiner = true
    let objDatos={
    strSucursal:this.strSucursal,
    strAgente:this.strAgente,
    intEjercicio:this.intEjercicio,
    intPeriodo:this.intPeriodo,
    strGerente:this.strGerente ,
    strAccion:"getSolicitudRenovacion",
    strConexion:this.strConexion
  }
    this._peticionesService.getComodatos(objDatos,"sp_ModVentas_Comodato")
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
    result => { 
      this.arraylistRenovacion=result;
      this.arrayFiltradoRenovacion = this.strEstautsRenovacion==""? this.arraylistRenovacion:this.arraylistRenovacion.filter(c=> c.strEstatus == this.strEstautsRenovacion);
      this.datosSolRenovacion = new MatTableDataSource(this.arrayFiltradoRenovacion);
      this.fnTabledataRenov();
      this.spiner = false
    },error=>{
      this.spiner = false
       console.error('Error al consumir la API:', error);
    });
  }

  fitrarSelect(){
    this.arrayFiltrado =this.strEstatus==""? this.arraylistComodatos:this.arraylistComodatos.filter(c=> c.strEstatus == this.strEstatus);
    this.dataSource = new MatTableDataSource(this.arrayFiltrado);
    this.fnTabledataSol();
  }

  fitrarSelectRenovacion(){
    this.arrayFiltradoRenovacion = this.strEstautsRenovacion==""? this.arraylistRenovacion:this.arraylistRenovacion.filter(c=> c.strEstatus == this.strEstautsRenovacion);
    this.datosSolRenovacion = new MatTableDataSource(this.arrayFiltradoRenovacion);
    this.fnTabledataRenov();
  }

  fitrarSelectCnacelacion(){
     this.arrayFiltradoCancelacion = this.strEstautsCancelacion==""?this.arraylistCancelacion:this.arraylistCancelacion.filter(c=> c.strEstatus == this.strEstautsCancelacion);
    this.datosSolCancelacion = new MatTableDataSource(this.arrayFiltradoCancelacion);
    this.fnTabledataCnacelacion();
  }
  fnEditar(objComodato:any){
    console.log(objComodato)
    this.fnGetDetalle(objComodato);
    //1.- agregamos el cliente a comodato
    //2.- agregamos los articulos
  }
  fnGetDetalle(objComodato:any){
    
    let objDatos={
      intIDComodatos:objComodato.intIDComodatos  ,
      strAccion:"getEditar_solicitud",
      strConexion:this.strConexion
    }
    
     this._peticionesService.getComodatos(objDatos,"sp_ModVentas_Comodato")
     .pipe(takeUntil(this.unsubscribe$))
     .subscribe(
      result => { 
        let arrayArt=result
        arrayArt.forEach(element => {
          let arrayConsumible=[];
          //convertimos las opciones a xml
          let parser = new DOMParser();
          let xmlDoc = parser.parseFromString(element.strObservacionesGte, "application/xml");
         
          let resultadoNode = xmlDoc.getElementsByTagName('Resultado')[0];
          console.log(resultadoNode)
          let items = resultadoNode.getElementsByTagName('Item');
          

          for (let i = 0; i < items.length; i++) {
            let item = items[i];
            //accedemos a cada una de las propiedades 
            let strNombreConsumible = item.getElementsByTagName('strNombreConsumible')[0]?.textContent?.trim();
            let strConsumible = item.getElementsByTagName('strConsumible')[0]?.textContent?.trim();
            let strTipoOpcion = item.getElementsByTagName('strTipoOpcion')[0]?.textContent?.trim();
            let strGrupo = item.getElementsByTagName('strGrupo')[0]?.textContent?.trim();
      
           
           
            arrayConsumible.push({strTipoOpcion:strTipoOpcion, strConsumible:strConsumible, strNombreConsumible: strNombreConsumible , strGrupo:strGrupo})
          }
          console.log(arrayConsumible)

          let objArt={
            strArticulo:element.strArticulo,
            strDescripcion1:element.strDescripcion1,
            dblCantidad: element.dblCantidadDespachador,
            dblPrecioLista:element.dblPrecio,
            strTipoOpcion:element.strTipoOpcion,
            strTipo:element.strTipo,
            strOpcion:element.strOpcion?element.strOpcion:'',
            objConsumible:{strTipoOpcion:element.strTipoOpcionConsumible , strConsumible:element.strConsumible, strNombreConsumible:element.strNombreConsumible, },
            arrayConsumible:arrayConsumible
          }
          this.fnAddToCarr(objArt);
        });
        
      },error=>{  
         console.error('Error al consumir la API:', error);
      });
  
   }
   fnAddToCarr( objArticulo:any){ //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado
  
    objArticulo.strComentario = "";
    //this.spinerCarrito = true;
     objArticulo.dblCantidad = parseInt(objArticulo.dblCantidad);
   const deepCopy = JSON.parse(JSON.stringify(objArticulo)); 
    this.commodatoservice.setArticulo( deepCopy); 
    setTimeout(() => {
      //this.spinerCarrito = false;
      this._alertify.success("Articulo agregado correctamente");
    }, 100);
  }
  applyFilter(event: Event) {
    console.log(event);
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterRenovacion(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosSolRenovacion.filter = filterValue.trim().toLowerCase();
    if (this.datosSolRenovacion.paginator) {
      this.datosSolRenovacion.paginator.firstPage();
    }
  }
  applyFilterCancelacion(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosSolCancelacion.filter = filterValue.trim().toLowerCase();
    if (this.datosSolCancelacion.paginator) {
      this.datosSolCancelacion.paginator.firstPage();
    }
  }

  fnAbrirModal(obj:any){
    console.log(obj)
    this.objModalRenovacion = {};
    this.objModalRenovacion =  obj;
    if(this.objModalRenovacion.strEstatus == "PENDIENTE" ){
      this.strMotivoRechazoRenovacion = "";
    }else{
      this.strMotivoRechazoRenovacion = obj.strObservacionesGte;
    }
    let objDatos = { 
      "strAccion":'getDetalleRenovacion',
      "strConexion":"COMERCIAL",
      "intIDCancelacion":obj.intIDCancelacion}
      this._peticionesService.getComodatos(objDatos,"sp_ModVentas_Comodato")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => { 
          this.arrayArtsRenovacion=result;
          this.spiner = false
        },error=>{
          this.spiner = false
           console.error('Error al consumir la API:', error);
        });
  }

  fnAbrirModalCancelacion(obj:any){
    this.objModalCancelar = {};
    this.objModalCancelar =  obj;
    if(this.objModalCancelar.strEstatus == "PENDIENTE" ){
      this.strMotivoRechazoCancelacion= "";
      this.strComentarioEnviarCancelacion = obj.strObservaciones;
    }else{
      this.strMotivoRechazoCancelacion = obj.strObservacionesGte;
      this.strComentarioEnviarCancelacion = obj.strObservaciones;
    }
    let  objDatos = { 
      "strAccion":'getTodoComodato_Det',
      "strConexion":"COMERCIAL",
      "intIDComodatos":obj.intIDComodatos,
      "strCliente":obj.strCliente.trim(),
      "strCteEnviarA":obj.strCteEnviarA.trim()
    }
      this._peticionesService.getComodatos(objDatos,"sp_ModVentas_Comodato")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => { 
          this.arrayArtsCancelacion=result;
          this.spiner = false
        },error=>{
          this.spiner = false
           console.error('Error al consumir la API:', error);
        });

  }

  fnAutorizarRenovacion(){
    console.log(this.objModalRenovacion)
    let objectComodato= { 
      "strAccion":'setAutorizarSolR',
      "strConexion":"COMERCIAL",
      "intIDComodatos":this.objModalRenovacion.intIDComodatos,
      "intID":this.objModalRenovacion.intID,
      "intIDCancelacion": this.objModalRenovacion.intIDCancelacion,
      "strObservacionesGte":this.strMotivoRechazoRenovacion
   }
   this._peticionesService.getComodatos(objectComodato,"sp_ModVentas_Comodato")
   .pipe(takeUntil(this.unsubscribe$))
   .subscribe(
    result => { 
      if (result[0].code == 200) {
        Swal.fire({
          icon: 'success',
          title: 'Autorizado',
          text: result[0].strMensaje,
        });
        this.fnGetSolicitud_Renovacion()
        this.btnModalRenovacion.nativeElement.click()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: result[0].strMensaje,
        });
      }
      this.spiner = false
    },error=>{
      this.spiner = false
       console.error('Error al consumir la API:', error);
    });
  }
  fnRechazarRenovacion(){
    let objectComodato= { 
      "strAccion":'setRechazarSolR',
      "strConexion":"COMERCIAL",
      "intIDComodatos":this.objModalRenovacion.intIDComodatos,
      "intID":this.objModalRenovacion.intID,
      "intIDCancelacion": this.objModalRenovacion.intIDCancelacion,
      "strObservacionesGte":this.strMotivoRechazoRenovacion
   }
   this._peticionesService.getComodatos(objectComodato,"sp_ModVentas_Comodato")
   .pipe(takeUntil(this.unsubscribe$))
   .subscribe(
    result => { 
      if (result[0].code == 200) {
        Swal.fire({
          icon: 'success',
          title: 'Autorizado',
          text: result[0].strMensaje,
        });
        this.fnGetSolicitud_Renovacion()
        this.btnModalRenovacion.nativeElement.click()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: result[0].strMensaje,
        });
      }
      this.spiner = false
    },error=>{
      this.spiner = false
       console.error('Error al consumir la API:', error);
    });
  }




  cancelarSolicitud() {
    let objectComodato = {
      "strAccion": 'setCancelarComodato',
      "strConexion": 'COMERCIAL',
      "intIDComodatos": this.objModalCancelar.intIDComodatos,
      "intID": this.objModalCancelar.intID,
      "intIDCancelacion": this.objModalCancelar.intIDCancelacion
    };
  
    // Llamada al servicio utilizando getComodatos
    this._peticionesService.getComodatos(objectComodato, "sp_ModVentas_Comodato")
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => { 
        if (result[0].code == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Solicitud Cancelada',
            text: result[0].strMensaje,
          });
          this.fnGetSolicitud_Cancelacion(); // Llamar función que recarga la lista
          this.btnModalCancelar.nativeElement.click(); // Cerrar el modal
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: result[0].strMensaje,
          });
        }
        this.spinerMod = false; // Detener el spinner
      },
      error => {
        this.spinerMod = false; // Detener el spinner en caso de error
        console.error('Error al consumir la API:', error);
      }
    );
  }

  fnRechazarcancelacion() {
    if (this.strMotivoRechazoCancelacion !== "") {
      this.flagrechazo = false;
  
      let objectComodato = {
        "strAccion": 'setRechazarSolC',
        "strConexion": 'COMERCIAL',
        "strObservacionesGte": this.strMotivoRechazoCancelacion,
        "intIDCancelacion": this.objModalCancelar.intIDCancelacion
      };
  
      // Llamada al servicio utilizando getComodatos
      this._peticionesService.getComodatos(objectComodato, "sp_ModVentas_Comodato")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => { 
          if (result[0].code == 200) {
            Swal.fire({
              icon: 'success',
              title: 'Solicitud Rechazada',
              text: result[0].strMensaje,
            });
            this.fnGetSolicitud_Cancelacion(); // Llamar función que recarga la lista
            this.btnModalCancelar.nativeElement.click(); // Cerrar el modal
          } else if (result[0].code == 409) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: result[0].strMensaje,
            });
          }
          this.spinerMod = false; // Detener el spinner
        },
        error => {
          this.spinerMod = false; // Detener el spinner en caso de error
          console.error('Error al consumir la API:', error);
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Favor de escribir el motivo de rechazo',
      });
      this.flagrechazo = true;
    }
  }
  fnRetirarcancelacion() {
    if (this.strMotivoRechazoCancelacion !== "") {
      this.flagrechazo = false; // Bandera para manejo de error
      let objectComodato = {
        "strAccion": 'setRetirarSolC',
        "strConexion": 'COMERCIAL',
        "strObservacionesGte": this.strMotivoRechazoCancelacion,
        "intIDCancelacion": this.objModalCancelar.intIDCancelacion
      };
  
      // Llamada al servicio utilizando getComodatos
      this._peticionesService.getComodatos(objectComodato, "sp_ModVentas_Comodato")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => { 
          if (result[0].code == 200) {
            Swal.fire({
              icon: 'success',
              title: 'Cancelación Retirada',
              text: result[0].strMensaje,
            });
            this.fnGetSolicitud_Cancelacion(); // Llamar función que recarga la lista
            this.btnModalCancelar.nativeElement.click(); // Cerrar el modal
          } else if (result[0].code == 409) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: result[0].strMensaje,
            });
          }
          this.spinerMod = false; // Detener el spinner
        },
        error => {
          this.spinerMod = false; // Detener el spinner en caso de error
          console.error('Error al consumir la API:', error);
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Favor de escribir el motivo de retiro',
      });
      this.flagrechazo = true; // Activar bandera de error si no hay motivo
    }
  }
  
}




/* 

    $scope.detalleComodatos = function(obj) {
        console.log(obj)
        $scope.spinerMod = true;
        if(obj.strEstatus == "PENDIENTE" ){
            $scope.strMotivoRechazo = "";
        }else{
            $scope.strMotivoRechazo = obj.strObservacionesGte;
        }
       
        $scope.bolComentarioEnviar = false;
        $scope.objComodato = {};
        $scope.strComentarioEnviar = obj.strObservaciones;
        $scope.objComodato = obj;
        $scope.arrayDetalleComodato = [];
        myobjectListaComodatos = { 
            "strAccion":'getTodoComodato_Det',
            "strConexion":"COMERCIAL",
            "intIDComodatos":obj.intIDComodatos,
            "strCliente":obj.strCliente.trim(),
            "strCteEnviarA":obj.strCteEnviarA.trim()
        };
        console.log($scope.objComodato)
        $http({
            method: "POST",
            url: rutaApiPremia + "sp_ModVentas_Comodato", 
            data: Object.toparams(myobjectListaComodatos),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function mySuccess(response) {
            $scope.arrayDetalleComodato = response.data;
            console.log( $scope.arrayDetalleComodato );
            $scope.spinerMod = false;
            $scope.ListaComodatosCancelar();
                }, function myError(response) {
            $scope.spinerMod = false;
        });
    };


















   

        $scope.fnRechazarcancelacion = function () {
            if($scope.strMotivoRechazo!=""){
                $scope.flagrechazo = false;
                let  objectComodato= { 
                    "strAccion":'setRechazarSolC',
                    "strConexion":"COMERCIAL",
                    "strObservacionesGte":$scope.strMotivoRechazo,
                    "intIDCancelacion":$scope.objComodato.intIDCancelacion
                },
                arrayRespRetirar=[];
                $http({
                    method: "POST",
                    url: rutaApiPremia + "sp_ModVentas_Comodato", 
                    data: Object.toparams(objectComodato),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function mySuccess(response) {
                  arrayRespRetirar = response.data;
                    console.log(arrayRespRetirar);
                    $scope.spinerMod = false;
                    if(arrayRespRetirar[0].code=="200"){
                        alertify.alert(arrayRespRetirar[0].strMensaje);
                        $scope.ListaComodatosCancelar();
                        $('#exampleModal').modal('hide');
                    }else if(arrayRespRetirar[0].code=="409"){
                        alertify.alert(arrayRespRetirar[0].strMensaje);
                    }
                }, function myError(response) {
                    $scope.spinerMod = false;
                    
                });
            }else{
                alertify.error("Favor de escribir el motivo de retiro");
                $scope.flagrechazo = true;
            }
          };

        $scope.fnRetirarcancelacion = function () {
            if($scope.strMotivoRechazo!=""){
                $scope.flagrechazo = false;
                let  objectComodato= { 
                    "strAccion":'setRetirarSolC',
                    "strConexion":"COMERCIAL",
                    "strObservacionesGte":$scope.strMotivoRechazo,
                    "intIDCancelacion":$scope.objComodato.intIDCancelacion,
                },
                arrayRespRetirar=[];
                $http({
                    method: "POST",
                    url: rutaApiPremia + "sp_ModVentas_Comodato", 
                    data: Object.toparams(objectComodato),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function mySuccess(response) {
                  arrayRespRetirar = response.data;
                    console.log(arrayRespRetirar);
                    $scope.spinerMod = false;
                    if(arrayRespRetirar[0].code=="200"){
                        alertify.alert(arrayRespRetirar[0].strMensaje);
                        $scope.ListaComodatosCancelar();
                        $('#exampleModal').modal('hide');
                    }else if(arrayRespRetirar[0].code=="409"){
                        alertify.alert(arrayRespRetirar[0].strMensaje);
                    }
                }, function myError(response) {
                    $scope.spinerMod = false;
                    
                });
            }else{
                alertify.error("Favor de escribir el motivo de retiro");
                $scope.flagrechazo = true;
            }

        
          };
*/