<h1 mat-dialog-title class="title">Decisión del <b>{{strTipo}}</b> <br>
   
</h1>
<mat-dialog-content class="mat-typography">
    Tipo Reto:{{strTipoReto}}
    <div class="cien min80">
        <div class="col-sm-12 mt-10">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="strRespuesta" (change)="fnCambioRespuesta()">
                <div class="cien">
                    <mat-radio-button value="Acepta">{{strTipo}} <b>Acepta</b> Reto</mat-radio-button>
                </div>
                <div class="cien mt-20">
                    <mat-radio-button value="Rechaza">{{strTipo}} <b>Rechaza</b> Reto</mat-radio-button>
                </div>
            </mat-radio-group>
        </div>

        <div *ngIf="strRespuesta=='Acepta' && strTipo=='Prospecto'" class="col-sm-12 mt-20">
            <label class="label">Buscar Cliente</label>
            <input type="text" [(ngModel)]="strBusqueda" placeholder="Cliente" maxlength="100" class="form-control mayus" (keyup)="fnBuscarClienteProsp()" />
            <div class="cien text-center fLeft" *ngIf="mostrarCargando">
                <mat-spinner></mat-spinner>
            </div>
            <ul class="ul_busqueda" *ngIf="arrayClientes.length>0">
                <li *ngFor="let C of arrayClientes" (click)="fnSeleccionoCliente(C)" class="fondo_{{C.fondo}}">
                    {{C.strCliente}} - {{C.strNombre}}
                    <span *ngIf="C.intIDSucursal>0"><b>Sucursal:</b> {{C.intIDSucursal}} - {{C.strNombreSucursal}}</span>
                </li>
            </ul>
            <h3 *ngIf="arrayDatosCteSeleccionado!=''" class="cte_select">
                <b>Cliente</b> seleccionado: <b>{{strCliente}} - {{strNombre}}</b>
                <br><span *ngIf="intIDSucursal>0"><b>Sucursal:</b> {{intIDSucursal}} - {{strNombreSucursal}}</span>
            </h3>
            <h3 *ngIf="arrayDatosCteSeleccionado==''" class="cte_select">
                <b>Prospecto</b> seleccionado: <b>{{strCliente}} - {{strNombre}}</b>
                <br><span *ngIf="intIDSucursal>0"><b>Sucursal:</b> {{intIDSucursal}} - {{strNombreSucursal}}</span>
            </h3>
            <br>
        </div>
        
        <div *ngIf="strRespuesta=='Rechaza'" class="col-sm-12 mt-20">                    
            <label class="label">Motivo por el que NO Acepta el Reto</label>
            <textarea type="text" [(ngModel)]="strMotivoRechazoReto" placeholder="Motivo por el que NO Acepta" maxlength="200" class="form-control mayus"></textarea>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CERRAR</button>
    <button mat-button (click)="fnAutorizaRechazaReto()" class="btn_autorizarreto btn_{{strRespuesta}}" *ngIf="strRespuesta!=''">{{strRespuesta}} Reto</button>
</mat-dialog-actions>