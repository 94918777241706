<div class="container">
    <div class="row py-3">
        <div class="col-12 text-center position-relative">
            <h1 class="fw-bolder fs-3 mb-0 ">CAPTURADOR</h1>
            <div *ngIf="!capturador" class="position-absolute top-50 start-0 translate-middle-y ms-4 mt-3 d-flex flex-column">
                <button  class="btn btn-primary d-flex justify-content-center" (click)="fnCambiarstatus('boton');">
                    <i class="bi bi-capslock"></i> <span class="d-none d-md-block">Archivo</span> 
                </button>
                <span class="badge bg-light text-dark my-2 d-none d-md-block"><span class="text-dark">Archivo Seleccionado:  </span><br>{{ nameArchivo }}</span>
            </div>
            <div (click)="fnDescargarFormato();" class=" d-none position-absolute top-50 end-0 translate-middle-y ms-4 mt-3 d-md-flex flex-column">
                <button class="btn btn-success"><i class="bi bi-download"></i> Formato</button>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center" *ngIf="capturador">
        <div id="drop-area" (drop)="handleDrop($event)" (dragover)="handleDragOver($event)" (dragleave)="handleDragLeave($event)" class="col-7 bordeslin text-center">
            <span class="material-symbols-outlined fCloud text-success" [ngClass]="{ 'd-none': excelData?.length < 1 || excelData == null }">
                done_outline
            </span>
            <span class="material-symbols-outlined fCloud text-secondary" *ngIf="!excelData">
                cloud_upload
            </span>
            <div class="col-12 d-flex justify-content-center">
                <div class="col-6">
                    <label for="">{{ nameArchivo }} </label>
                    <button class="btn btn-outline-info my-2 form-control hob-text" (click)="fileInput.click()">
                        <span class="fw-bolder"> . . .</span>
                    </button>
                    <input (change)="handleFileInput($event)" type="file" class="d-none" accept=".xlsx, .xls, .xml"
                        #fileInput />
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center py-3 " *ngIf="capturador">
            <h1 class="mb-0 pt-3 d-flex align-items-center justify-content-center">
                <span class="material-symbols-outlined"> inventory </span> Articulos por
                Cliente
            </h1>
        </div>

        <div class="row" style="height:77vh;" *ngIf="spinner">
            <div class="d-flex justify-content-center align-items-center" >
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
        </div>
        <div class="col-12" *ngIf="!spinner">
            <div *ngFor="let A of arraySucursales; let j = index">
                <div class="my-3 text-center" *ngIf="A.arrayArticulos?.length > 0">
                    <h1 class="mb-0">
                        <b>
                            Sucursal: {{ A.strNombreSucursal }}
                            <span *ngIf="A.strNombreSucursal == ''">Domicilio Fiscal</span>
                        </b>
                    </h1>

                    <div class="row my-2" *ngIf="A.spinner">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                    </div>
                    <div class="row blueEco py-2 w-100 mx-auto" *ngIf="!A.spinner">
                        <div class="col-6 text-center border-end">
                          <span class="fw-bolder">Introducir Orden</span>  
                          <input [disabled]="A.bolEnviado" [(ngModel)]="A.strOrdenPedido" type="text" placeholder="ejemplo:MB51812" class="form-control w-50 mx-auto"><!--A.fechaRequerida fechaActual -->
                        </div>
                        <div class="col-6 text-center">
                           <span class="fw-bolder p-1 " [ngClass]="{'bg-danger rounded':!A.BolFecha}">Fecha Requerida</span> <input [disabled]="A.bolEnviado" [(ngModel)]="A.fechaRequerida"  type="date"  class="form-control w-50 mx-auto " [ngClass]="{'border border-danger border-2':!A.BolFecha}">
                        </div>
                    </div>
                    <div class="table-responsive" *ngIf="!A.spinner">
                        <table class="table  table-bordered">
                            <thead>
                                <tr class="thcenter blueEco">
                                    <th>Eliminar</th>
                                    <th>No. Cliente</th>
                                    <th>Nombre</th>
                                    <th>Sucursal</th>
                                    <th>Articulo</th>
                                    <th>Descripción</th>
                                    <th>Opcion</th>
                                    <th>Cantidad</th>
                                    <th>Precio</th>
                                    <th>Precio Total</th>
                                </tr>
                            </thead>
                            <tbody class="h500">
                                <tr *ngFor="let B of A.arrayArticulos; let i = index" class="thcenter bg-white">
                                    <td>
                                        <button [disabled]="A.bolEnviado"  class="btn logo" (click)="fneliminar(i, A.strIDSucursal)">
                                            <span class="material-symbols-outlined text-danger fs-2">delete</span>
                                        </button>
                                    </td>
                                    <td>{{ B.No_Cliente }}</td>
                                    <td>{{ B.Nombre }}</td>
                                    <td>{{ B.Sucursal }}</td>
                                    <td>{{ B.Articulo }}</td>
                                    <td class="text-nowrap" [innerHTML]="formatDescripcion(B.Descripcion)"></td>
                                    <td>{{ B.Opcion }}</td>
                                    <td class="text-end">{{ B.Cantidad }}</td>
                                    <td>
                                        <div *ngIf="B.plc == 0; else elseBlock" class="text-end">
                                            <div *ngIf="B.arrayPrecios && (B.arrayPrecios.intPrecioLista != 0 || B.arrayPrecios.intPrecio2 != 0 || B.arrayPrecios.intPrecio3 != 0 || B.arrayPrecios.intPrecio4 != 0); else elsiblock">
                                                <select [disabled]="A.bolEnviado" class="form-select" [(ngModel)]="B.Precio" (change)="fnpreciototal(A.strIDSucursal, B.Articulo)" aria-label="Default select example">
                                                    <option [value]="0" selected><!-- *ngFor="let food of foods" -->Seleccione
                                                    </option>
                                                    <option *ngIf="B.arrayPrecios.intPrecioLista > 0" [value]="B.arrayPrecios.intPrecioLista"><!-- *ngFor="let food of foods" -->PL: ${{ B.arrayPrecios.intPrecioLista }}
                                                    </option>
                                                   <!--  <option *ngIf="B.arrayPrecios.intPrecio2 > 0"
                                                        [value]="B.arrayPrecios.intPrecio2">
                                                        P2: ${{ B.arrayPrecios.intPrecio2 }}
                                                    </option>
                                                    <option *ngIf="B.arrayPrecios.intPrecio3 > 0" [value]="B.arrayPrecios.intPrecio3"> 
                                                        P3: ${{ B.arrayPrecios.intPrecio3 }}
                                                    </option>
                                                    <option *ngIf="B.arrayPrecios.intPrecio4 > 0" [value]="B.arrayPrecios.intPrecio4">
                                                        P4: ${{ B.arrayPrecios.intPrecio4 }}
                                                    </option> -->
                                                </select>
                                            </div>
                                            <ng-template #elsiblock>
                                                <div class="alert alert-danger p-2 m-0 text-center" role="alert">
                                                    Sin Existencia
                                                </div>
                                            </ng-template>
                                        </div>
                                        <ng-template #elseBlock>
                                            ${{ B.Precio | number : "1.2-2" }}
                                        </ng-template>
                                    </td>
                                    <td class="text-nowrap text-end">
                                        ${{ B.Precio_Total | number : "1.2-2" }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="4">
                                        <div class="d-flex justify-content-between align-items-center">
                                        <div class=" d-flex justify-content-center"  [ngClass]="{'col-6':A.bolEnviado,'col-12':!A.bolEnviado}">
                                            <button [disabled]="A.bolEnviado" [ngClass]="{'btn-secondary':A.bolEnviado,'btn-primary':!A.bolEnviado}" class="btn d-flex align-items-center rounded-pill shadow-sm trans"
                                            (click)="enviarPedido(A.strIDSucursal, A.strSucursal,A)">
                                            <b>Enviar Pedido</b>
                                            <span class="material-symbols-outlined">
                                                order_approve
                                            </span>
                                        </button>
                                        </div>
                                        <div class="col-6" *ngIf="A.bolEnviado">
                                            <div class="col-10 d-flex flex-column">
                                                <label class="rounded btncol   text-start "><span class="ms-2">ID :</span><span class="ms-1 me-2 fw-light">{{A.intID}}</span></label>
                                                <label class="rounded btncol mt-2 text-start"><span class="ms-2">Estatus :</span><span class="ms-1 me-2 fw-light">{{A.strEstatus}}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    </td>
                                    <td colspan="3" class="text-end text-nowrap">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <div class="col-6" *ngIf="A.bolEnviado">
                                                <label class="rounded bg-secondary text-white text-start h-100 w-100 float-start text-wrap"><span class="ms-2">Mensaje:</span><span class="ms-1 me-2 fw-light">{{A.strMensaje}}</span></label>
                                            </div>
                                            <div class="" [ngClass]="{'col-6':A.bolEnviado,'col-12':!A.bolEnviado}">
                                                <b class="float-end">TOTAL ARTICULOS:</b>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <b>{{ fnsumartotalart(A.strIDSucursal) }}</b>
                                    </td>
                                    <td class="text-end text-nowrap">
                                        <b>COSTO TOTAL:</b>
                                    </td>
                                    <td class="text-end">
                                        <b>
                                            ${{fnsumartotal(A.strIDSucursal) | number : "1.2-2"}}
                                        </b>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>