<div class="d-flex align-items-end floating-buttons" id="iconoFijo">
    <span   class=" rounded-circle shadow btn bgMenu p-4 position-relative" (click)="fnCambiarMenu()">
       <div class="d-flex flex-column position-absolute top-50 start-50 translate-middle">
        <div class="position-relative">
             <!-- Primer icono: Rota -45 grados [@menuAnimation]="!boolMenu ? 'visible1' : 'hidden2'" -->
              <div    [@rotateIcon]="boolMenu ? 'rotatedLeft' : 'initial'">
                <i class="fa-solid fa-minus fs-4 position-absolute top-50 start-50 translate-middle"></i>
<!--                 <i class="bi fs-3 bi-dash-lg position-absolute top-50 start-50 translate-middle "></i> -->
              </div>
            <!-- Segundo icono: Desaparece -->
            <i class="fa-solid fa-minus fs-4 position-absolute top-50 start-50 translate-middle" [@disappearIcon]="boolMenu ? 'hidden' : 'visible'"></i>

            <!-- Tercer icono: Rota 45 grados  [@menuAnimation]="!boolMenu ? 'visible1' : 'hidden2'" -->
            <div  [@rotateIcon]="boolMenu ? 'rotatedRight' : 'initial'" class="">
                <i class="fa-solid fa-minus fs-4 position-absolute top-50 start-50 translate-middle"></i>
 <!--                <i class="bi fs-3 bi-dash-lg position-absolute top-50 start-50 translate-middle " ></i> -->
            </div>
        </div>
       </div>
    </span>
    <!-- Aplica la animación al contenedor del menú -->
    <div class="" [@menuAnimation]="boolMenu ? 'visible' : 'hidden'" [ngClass]="boolMenu ? 'fadein' : 'fadeout'" [ngClass]="{'d-flex':boolMenu,'d-none':!boolMenu}">
        <ng-container *ngIf="!boolLogin">
            <span class="rounded-circle shadow btn clsIconMenu ms-2 ball d-flex flex-column" matTooltip="Colaborador" [routerLink]="['/login']">
                <i class="p-0 bi bi-person-fill-lock fs14" ></i>
            </span>
            <span class="rounded-circle shadow btn clsIconTienda ms-2 ball" matTooltip="Tienda en línea" [routerLink]="['/tienda']">
                <i class="bi bi-bag-fill fs14"></i>
            </span>
        </ng-container>

        <ng-container *ngIf="boolLogin">
            <span class="rounded-circle shadow btn clsIconTienda ms-2 ball" matTooltip="Tienda en linea" [routerLink]="['/tienda']">
                <i class="bi bi-bag-fill fs14"></i>
            </span>
            <span class="rounded-circle shadow btn clsIconMenu ms-2 ball" matTooltip="Nuevo pedido" [routerLink]="['/cotizador_Linea']">
                <i class="p-0 bi-house-fill fs14" ></i>
            </span>
        </ng-container>
    </div>
  </div>
  <div class="col-12 position-relative floating-buttons" *ngIf="!strUsuario || strUsuario==undefined || strUsuario==''">
    <div class="position-absolute bottom-0 end-0">
        <div class="d-flex flex-column ">
            <a href="https://api.whatsapp.com/send?phone=524777296302&amp; text=Hola, contacto desde el sitio web para pedir más información." target="_blank" class="me-3 mb-4">
                <img class="iconRedes" [src]="'assets/ecommerce/WhatsApp_icon.png'" alt="">
            </a>
            <a href="http://m.me/111625762716231" target="_blank" class="me-3 mb-4">
                <img class="iconRedes" src="https://images.vexels.com/media/users/3/223275/isolated/preview/fedc6cee8713c35480ad54ff5a4fbc0e-icono-de-mensajero-dise-o-plano.png" alt="">
            </a>
           </div>
    </div>

  </div>