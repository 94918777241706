import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';

declare var $: any

@Component({
  selector: 'app-detalle-pedidos-p',
  templateUrl: './detalle-pedidos-p.component.html',
  styleUrls: ['./detalle-pedidos-p.component.css']
})
export class DetallePedidosPComponent implements OnInit {

  public totalMov: any = 0
  public totalImporte: any = 0
  public totalImportePendiente: any = 0
  public InfoEncabezado: any = []
  public strID: any = ''
  public strSituacion: any = ''
  public listaDetalle: any = []
  public ArrayCondicion: any = []
  public selectedCondicion: any = ''
  public strtipo: any = ''
  public strusuario: any = ''
  public strIDVenta: any = ''
  public SituacionAutorizada: any = ''
  public ngMotivoRechazo: any = '' 
  public ordenCompra: any = []
  public spOrdenCompra: any = ''
  public nombreOrdenCompra: any = ''
  public listaAlmacenDisponible: any = []
  public strMotivoCambio:string='';
  public strConexion:string='';
  @ViewChild('btnCerrar') btnCerrar!: ElementRef;
 

  constructor(private _httpService: PeticionesService, public route: ActivatedRoute, public localSt: LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strusuario = this.localSt.retrieve('strusuario'),
      this.route.queryParamMap.subscribe(params => {
        this.strID = params.get('strID');
        this.strSituacion = params.get('strSituacion')
        this.strtipo = params.get('tipo')
      }); 
  }

  ngOnInit(): void {
    this.InfoCabecera()
    this.getVentasMovimientosDetalle()
    this.CondicionContado()
  }

  InfoCabecera() {
    let obj: any;
    obj = {
      intID: this.strID,
      strConexion:this.strConexion,
      strAccion:'getInfoCabecera'
    }
    //this._httpService.getInfoCabecera(obj)
    this._httpService.mostrarCotizaciones(obj, "sp_ModVentas_DashBoard")
    .subscribe(
      result => {
        console.log(result)
        this.InfoEncabezado = result[0]
      });
  }

  getVentasMovimientosDetalle() {
    let obj: any;
    obj = {
      intID: this.strID,
      strConexion:this.strConexion,
      strAccion:'getDetallePedido'
    }
    //this._httpService.getVentasMovimientosDetalle(obj)
    this._httpService.mostrarCotizaciones(obj, "sp_ModVentas_DashBoard")
    .subscribe(
      result => {
        console.log(result)
        this.listaDetalle = result
        this.totalMov = this.listaDetalle.length;
        for (let i = 0; i < this.listaDetalle.length; i++) {
          this.totalImporte = this.totalImporte + parseFloat(this.listaDetalle[i].dblSubTotal);
          this.totalImportePendiente = this.totalImportePendiente + parseFloat(this.listaDetalle[i].dblSubTotalPendiente);
        }
      });
  }


  //Funcion para obtener la condicion de Contado
  CondicionContado() {
    let obj: any;
    obj = {

    }
    this._httpService.getCondicionContado(obj).subscribe(
      result => {
        console.log(result)
        this.ArrayCondicion = result
      });
  }

  ActualizacionDeCondicion() {
   
    let obj: any;
    obj = {
      strNuevaCondicion: this.selectedCondicion,
      strID: this.strID
    } 
 
 
    this._httpService.setCambioCondicionPedido(obj).subscribe(
      result => {
        console.log(result)
        this.ArrayCondicion = result
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El pedido fue actualizado correctamente",
          showConfirmButton: false,
          timer: 1500
        });
        //cerramos el modal
         this.btnCerrar.nativeElement.click();
        //recargamos todo
        this.InfoCabecera()
        this.getVentasMovimientosDetalle()
        this.CondicionContado() 
      }); 
  }

  autorizar(pedido, flag) {
    $('#autorizar').hide();
    this.strIDVenta = pedido;
    this.AutorizarPedido(flag);
  };

  AutorizarPedido(flag: any) {
    var status = "";
    let fromPedAutorizar: any
    if (flag == 1) { //autorizar 
      status = "AUTORIZADO";
    }
    else if (flag == 2) { //rechazar 
      status = "RECHAZADO";
    }
    this.SituacionAutorizada = '';
    if ((status == "AUTORIZADO") || (status == "RECHAZADO" && this.ngMotivoRechazo != "")) {
      fromPedAutorizar = { 'strIDVenta': this.strIDVenta, 'strEstatus': status, 'strMotivoRechazo': this.ngMotivoRechazo, 'strUsuario': this.strusuario, 'strSituacionAutorizada': this.SituacionAutorizada };

      this._httpService.setPedidosPorAutorizar(fromPedAutorizar).subscribe(
        result => {
          console.log(result)
          Swal.fire({
            position: "center",
            icon: "success",
            title: "El pedido fue afectado correctamente",
            showConfirmButton: false,
            timer: 1500
          });
        });
    }
  }

  GuardarID(strID: any) {
    this.strID = strID;
  };

  guardarArchivo(event: any) {
    this.ordenCompra = []
    var allowedExtensions = /(jpg|jpeg|png|pdf)$/i;
    const fileInput = event.target as HTMLInputElement;
    var filePath1 = (fileInput.files[0].type).split('/')[1];
    this.ordenCompra.push(fileInput.files[0]);

    if (filePath1 != "") {
      if (!allowedExtensions.exec(filePath1)) {
        //alertify.log('Por favor suba archivo con estas extenciones: .jpeg/.jpg/.png./.pdf');
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Por favor suba archivo con estas extenciones: .jpeg/.jpg/.png./.pdf!"
        });

      } else {
        if (fileInput.files[0].size < 500000) {
          //this.finalizarOrdenCompra();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Vericar los Archivos, el tamaño es mayor al permitido"
          });

        }
      }
    } else {
      //alertify.log('Favor de Adjuntar la Orden de Compra');
    }
  }

  subirOrdendeCompra() {
    console.log(this.ordenCompra[0].name)
    let formData = new FormData()
    formData.append('archivo', this.ordenCompra[0]);
    this._httpService.uploadOrdenCompra(formData).subscribe(
      (response) => {
        console.log(response.nombreDocumento[0])
        if (response.nombreDocumento) {
          let array = response.nombreDocumento;
          this.spOrdenCompra = "https://www.ecodeli.com/" + array[0]; // cuando devuelve 200 puedes enviar a guardar a la base de datos

          Swal.fire({
            icon: 'success',
            title: 'Agregado Correctamente',
            //text: 'Archivo Agregado',
            confirmButtonText: 'Cerrar'
          });
          this.fnGuardarOrdenCompra()
        } else {
          Swal.fire("Error", "Error al subir " + response, "error")
          //this.spinerAll =false;
        }
      },
      (error) => {
        Swal.fire("Error", "No se recibio respuesta del servidor", "error")

      });
  }

  fnGuardarOrdenCompra() {
    let obj: any;
    obj = {
      'strUsuario': this.strusuario,
      'strID': this.strID,
      'strOrdenCompraAdjunto': this.spOrdenCompra
    }
    this._httpService.setAdjuntoOrdenCompra(obj).subscribe(
      result => {

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Guardado Correctamente",
          showConfirmButton: false,
          timer: 1500
        });
        this.InfoCabecera()
      });
  }

  cambiarSituacion() {
    let obj: any;
    obj = {
      'strIDPedido': this.strID
    }
    this._httpService.setReActivarPedido(obj).subscribe(
      result => {
        this.InfoCabecera()
        this.getVentasMovimientosDetalle()
        this.CondicionContado()
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Correctamente",
          showConfirmButton: false,
          timer: 1500
        });
      });
  }

  VerModal(pedido: any) {
    this.strIDVenta = pedido;  //OBTENEMOS EL ID PEDIDO
  };

  //Funcion para ver los articulos disponibles en Almacen
  ArticuloDisponible(obj: any) {
    let SubCuenta = "";
    if (obj.strSubcuenta) {
      SubCuenta = obj.strSubcuenta;
    }

    let objEnviar: any;
    objEnviar = {
      'strArticulo': obj.strArticulo,
      'strSubcuenta': SubCuenta
    }
    this._httpService.getExistenciasAlm(obj).subscribe(
      result => {
        if (result.length > 0) {
          this.listaAlmacenDisponible = result;
        }
      });

    /* myobject = {'strArticulo': obj.strArticulo, 'strSubcuenta':SubCuenta}; 
    $http({
      method: "POST",
      url: rutaApi +'getDisponibleXAlmacen',
      data: Object.toparams(myobject),
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    }).then(function (response){
      $scope.listaAlmacenDisponible = response.data ; 
    },function (error){
      console.log(error, 'no se puede consumir datos');
    }); */
  };
}
