import { Component, OnInit,HostListener,ViewChild,ElementRef,Renderer2,EventEmitter, Output} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';
import { CarroService } from '../servicios/carroservice';
import { ScrollService } from '../servicios/scroll.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare var Shepherd: any; 
@Component({
  selector: 'app-cotizador-articulos',
  templateUrl: './cotizador-articulos.component.html',
  styleUrls: ['./cotizador-articulos.component.css']
})
export class CotizadorArticulosComponent implements OnInit {
  @ViewChild('contenedor') contenedor!: ElementRef;
   private unsubscribe$ = new Subject<void>();
  public objCliente:any={} 
  public arrayArticulos:any=[];
  public strConexion: string = "";
  public selectedCategory:string='';
  public strLinea:string='';
  public strSubLinea:string='';
  public priceRange:string='';
  public strbuscarLista:"";
  public strArticulo:string='';
  public strTitulo:string=''
  public spiner:boolean=false;
  public strAgente_Crea: string;
  strAgente: string;
  public arrayAccesos: any = [];
  public strUsuario: string;
  public tagName: string;

  isButtonVisible = false; 
  lastScrollTop = 0;/* 
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef; */


  scrollContainer: HTMLElement | null = null;


  public arraySubcategorias:any=[];/* {category:'uno'},{category:'uno'},{category:'uno'},{category:'uno'} */
  constructor(private routEs : ActivatedRoute,private scrollService: ScrollService,elem: ElementRef,private renderer: Renderer2,private carroservice:CarroService,private indexedDBService: DataDBService,private router: Router,private localSt: LocalStorageService,private _httpService: PeticionesService) {
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strLinea= this.localSt.retrieve('strLinea_Seleccionada');
    this.strSubLinea= this.localSt.retrieve('strSubLinea_Seleccionada');
    this.strArticulo=  this.localSt.retrieve('strArticulo_Seleccionada');

    //suscripcion al servicio para detectar cuando se ejecuta una busqueda desde el menu
    this.routEs.queryParams.subscribe(params => {
      let step = params['addstep']; // 'tour'
      if(step == "sublinea"){
        setTimeout(() => {
          this.iniciarTour();
        }, 700)
      
      }
      console.log('Viene del tour:', step);
    });

    if(this.strLinea !=''){
      console.log('entra 2')
      this.strTitulo=this.strLinea;
      this.strSubLinea= this.localSt.retrieve('strSubLinea_Seleccionada');
     // this.strSubLinea= this.strLinea;
      this.fnGetArticulos();
    }
    
    
   }

  ngOnInit(): void {
    this.arraySubcategorias=[];
    this.arrayArticulos = [];

    this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgente_Crea = this.localSt.retrieve('strAgente');
    this.strAgente = this.localSt.retrieve('strAgente');
    this.strUsuario = this.localSt.retrieve('strUsuario');
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];

      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        this.strConexion = this.localSt.retrieve('strConexion');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strAgente = this.localSt.retrieve('strAgente');
        this.strUsuario = this.localSt.retrieve('strUsuario');
        //------------conexi+on-------------------
        this.indexedDBService.getItem('strConexion', (value) => {
          this.strConexion = value;
          
        }); 
      }
      else {
        this.router.navigate(['/login']);
      }
    });

    this.indexedDBService.Busqueda_Articulo_$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((nuevoValor) => {
      
      this.strArticulo=nuevoValor?nuevoValor==0?'':nuevoValor  :'';
      if( this.strArticulo !=''){
        console.log('entra 1');
        this.strLinea= this.localSt.retrieve('strLinea_Seleccionada');
        this.strSubLinea= this.localSt.retrieve('strSubLinea_Seleccionada');
        this.strArticulo=  this.localSt.retrieve('strArticulo_Seleccionada');
        this.strTitulo='Resultados de la Búsqueda'
        this.fnGetArticulos_2();
      }
      
    });

  }
  ngAfterViewInit(): void {
    const scrollElementRef: any = this.scrollService.getScrollContainer();
    
    if (scrollElementRef) {
     // console.log('✅ ScrollContainer obtenido en Componente2:', scrollElementRef);

      // Verificar si el elemento es una instancia de MatSidenavContent
      if (scrollElementRef instanceof Object && scrollElementRef.elementRef) {
        this.scrollContainer = scrollElementRef.elementRef.nativeElement;
      } else if (scrollElementRef.nativeElement) {
        this.scrollContainer = scrollElementRef.nativeElement;
      }

      // Validación final para evitar errores
      if (this.scrollContainer) {
        //console.log('✅ Elemento HTML detectado:', this.scrollContainer);
        this.scrollContainer.addEventListener('scroll', this.onScroll.bind(this));
      } else {
        console.error('❌ No se pudo obtener el elemento HTML para agregar el evento de scroll');
      }
    } else {
      console.warn('❌ No se pudo obtener el scrollContainer en Componente2');
    } }
  ngOnDestroy(): void {
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener('scroll', this.onScroll.bind(this));
    }
  }
  onScroll(event: Event): void {
    const scrollTop = (event.target as HTMLElement).scrollTop;
    if (scrollTop > this.lastScrollTop) {
      this.isButtonVisible = scrollTop > 100; // Mostrar botón si baja más de 100px
    } else {
      this.isButtonVisible = false; // Ocultar botón si sube
    }
    this.lastScrollTop = scrollTop;
  }

  scrollToTop(): void {
    if (this.scrollContainer) {
      this.scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
/*   onScroll(event: Event): void {

    const scrollTop = this.scrollContainer.nativeElement.scrollTop;
    if (scrollTop > this.lastScrollTop) {
      this.isButtonVisible = scrollTop > 100; // Mostrar el botón solo si se ha hecho scroll hacia abajo y más allá de 100px
    } else {
      this.isButtonVisible = false; // Ocultar el botón si el usuario desplaza hacia arriba
    }
  }
  scrollToTop(): void {
    this.scrollContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    
  } */

  fnGetArticulos_2(){
    this.spiner = true;
    this.arraySubcategorias=[];
    this.arrayArticulos = [];
    this.strSubLinea='';
    this.strLinea ='';
    let articulo = {
      strAccion: "getArticulo_Busqueda",
      strArticuloBusqueda: this.strArticulo,
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => { 
        let algo=result;
        console.log(result)
        algo.forEach(element => {
          let art =element.strArticulo.toLowerCase()
          if(art==this.strArticulo.toLowerCase()){
            this.strLinea = element.strCategoria;
            this.strSubLinea = element.strGrupo
          }
          else  if (element.strGrupo.toLowerCase().includes(this.strArticulo.toLowerCase())) {
            this.strLinea = element.strCategoria;
            this.strSubLinea = element.strGrupo
          }
          else  if (element.strDescripcion1.toLowerCase().includes(this.strArticulo.toLowerCase())) {
            this.strLinea = element.strCategoria;
            this.strSubLinea = element.strGrupo
          }
        
        
          element.imageUrl='https://www.cleanclean.mx/imagenes/'+element.strArticulo+'.JPG'

          let valida = this.arraySubcategorias.find(o=>o.strGrupo ==element.strGrupo );

          if(!valida && valida!='' && valida!=' '){
            this.arraySubcategorias.push({strGrupo:element.strGrupo  })
          }
        });
        this.arraySubcategorias.push({'strGrupo':''})
        
      /*  this.arraySubcategorias.sort(function (a,b){
          if (a.strGrupo > b.strGrupo) {
            return 1;
          }
          if (a.strGrupo < b.strGrupo) {
            return -1;
          }
          return 0;
        })*/
        
       
        this.arrayArticulos = algo; 
       // this.strLinea = this.strLinea =='' ?this.arrayArticulos[0]?.strCategoria:this.strLinea;
       this.strLinea = '';
        this.strSubLinea = '';//this.strSubLinea=='' && (this.arrayArticulos[0]?.strGrupo == ""||this.arrayArticulos[0]?.strGrupo == null)? this.arrayArticulos[1]?.strGrupo:this.strSubLinea=='' && this.arrayArticulos[0]?.strGrupo != ""  ?this.arrayArticulos[0]?.strGrupo: this.strSubLinea;
        this.spiner = false;

        //notificar al menu que se realizo la consulta de busqueda
        this.indexedDBService.fnNotifica_fin_busqueda_articulo('');
      },error=>{
        console.log(error.message)
        this.spiner = false; 
      });
  }
  fnGetArticulos( ) {
    this.spiner =true
    this.arraySubcategorias=[];
    this.arrayArticulos = [];
  
    let articulo = {
      strAccion: "getArticulo_Cat",
      strCategoria: this.strLinea, 
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {
        console.log(result)
        let algo=result;
        algo.forEach(element => {
          element.imageUrl='https://www.cleanclean.mx/imagenes/'+element.strArticulo+'.JPG'
          let valida = this.arraySubcategorias.find(o=>o.strGrupo ==element.strGrupo );
          if(!valida && valida!='' && valida!=' '){
            this.arraySubcategorias.push({strGrupo:element.strGrupo  })
          }
        });
       /* this.arraySubcategorias.sort(function (a,b){
          if (a.strGrupo > b.strGrupo) {
            return 1;
          }
          if (a.strGrupo < b.strGrupo) {
            return -1;
          }
          return 0;
        })*/
        
        this.arrayArticulos = algo;
        this.strLinea = this.strLinea =='' ?this.arrayArticulos[0].strCategoria:this.strLinea;
        this.strSubLinea =this.strSubLinea=='' && (this.arrayArticulos[0].strGrupo == ""||this.arrayArticulos[0].strGrupo == null)? this.arrayArticulos[1].strGrupo:this.strSubLinea=='' && this.arrayArticulos[0].strGrupo != ""  ?this.arrayArticulos[0].strGrupo: this.strSubLinea;
        this.spiner =false;
     
      },error=>{
        console.log(error.message)
        this.spiner =false;
      });
  }
  addToCart(obj:any)
  {
    console.log(obj)
    //agregar a carrito
    this.localSt.store('strArticulo_Seleccionado_Cotizador',obj);
    this.localSt.clear('strArticulo_Seleccionado_Comodato' );
    this.router.navigate(['/detalle_articulo']);
  }

  filterByCategory(strGrupo:string){
    this.strSubLinea= strGrupo == '-Todos-' ?'':strGrupo ; 
  }

  iniciarTour() {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: { enabled: true },
        classes: 'shadow-md bg-white rounded custom-tooltip',
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 8,
       
      },
      useModalOverlay: true  // 👈 Esto activa el fondo oscuro
    });
  
 
    tour.addStep({
      id: 'partner',
      text: 'seleccionamos el articulo.',
      attachTo: {
        element: '#partner',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Siguiente',
          action: () => {
            tour.hide(); 
           

            this.localSt.store('strArticulo_Seleccionado_Cotizador',{
              "dblPrecioLista": 628.48,
              "imageUrl": "https://www.cleanclean.mx/imagenes/68739022P.JPG",
              "strArticulo": "68739022P",
              "strCategoria": "AMENIDADES",
              "strDescripcion1": "GEL DE DUCHA DOVE LECHE NUTRITIVA DE 5000 ML",
              "strGrupo": "AMENIDADES",
              "strLinea": "DOVE",
              "strTipo": "Lote",
              "strTipoOpcion": "No",
              "strUrlImg1": "https://www.cleanclean.mx/imagenes/68739022P.JPG",
              "strUrlImg2": "https://www.cleanclean.mx/imagenes/68739022P-PO-2.JPG",
              "strUrlImg3": "https://www.cleanclean.mx/imagenes/68739022P-PO-3.JPG",
              "strUrlImg4": "https://www.cleanclean.mx/imagenes/68739022P-PO-4.JPG",
              "strUrlImg5": "https://www.cleanclean.mx/imagenes/68739022P-PO-5.JPG"
            });
            this.localSt.clear('strArticulo_Seleccionado_Comodato' );
            this.router.navigate(['/detalle_articulo'], {
              queryParams: { addstep:"intro" }
            });
          }
        }
      ]
    });
  
    // Iniciar el tour
    tour.start();
  }

  
}
