<div class="container py-5">
    <div class="row justify-content-center">
        <div class="col-9 bg-white shadow-sm">
            <div class="row ">
                <div class="col-12 text-center">
                    <h1 class="mt-3 mb-0 nunito fw-bold">Transacción Fallida</h1>
                </div>
            </div>
            <div class="row justify-content-center  pb-3">
                <div class="col-4  position-relative  "><!--      ms-auto d-flex -->
                 <!--    <div class="col-12 position-absolute top-50 start-50 translate-middle">
                        <img class="w-100 " alt="" [src]="'../assets/ecommerce/fondo1.png'">
                    </div> -->
                    <div class="col-12 ">
                        <img class="w-100 " alt="" [src]="'../assets/ecommerce/credit.png'">
                    </div>
              <!--       <div class="col-12 position-absolute top-50 start-50 translate-middle">
                        <img class="w-100" alt="" [src]="'../assets/ecommerce/credit.png'">
                    </div> -->
                  <!--   <div class="col-12 position-absolute top-50 start-50 translate-middle">
                        <div class="col-6  ms-auto d-flex">
                            <img class="w-75 " alt="" [src]="'../assets/ecommerce/errorPago.png'">
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row my-4 justify-content-center">
                <div class="col-9">
                    <span class="fw-bold text-secondary fs14">
                        El pago ha sido rechazado. Te recomendamos revisar la información proporcionada y seguir estos pasos:
                    </span>
                    <ul class="py-4 mb-0">
                        <li class="fs-6">
                            Verifica que los datos de tu tarjeta sean correctos.
                        </li>
                        <li class="py-2 fs-6"> 
                            Asegúrate de que tu tarjeta o cuenta tenga fondos suficientes. 
                        </li>
                        <li class="fs-6">
                            Si el problema persiste, contacta a tu banco o intenta con otro método de pago.
                        </li>
                    </ul>
                    <span class="fw-bold text-secondary 14">
                        Apreciamos tu comprensión y estamos aquí para asistirte si tienes más preguntas. Por favor, contáctanos en 477 788 98 00 o <span class="pointer" href="https://wa.me/524777296302"><i class="bi bi-whatsapp"></i></span> 477 729 63 02 si necesitas más ayuda.
                    </span>
                </div>
            </div>
            <div class="row py-4 justify-content-center">
                <div class="col-9 d-flex justify-content-between">
                    <button class="btn btnIntNuevo fs14 shadow-sm" (click)="fnIntentarDeNuevo()"><i class="bi bi-repeat pe-1"></i> Intentar de Nuevo</button>
                    <button class="btn btnVolver fs14 shadow-sm" (click)="fnSeguirComprando()"><i class="bi bi-bag pe-1"></i> Seguir Comprando</button>
                </div>
            </div>
        </div>
    </div>
</div>