<br><br>
<div class="container">
    <div class="row seccion">
        <div class="col-12">
            <mat-tab-group mat-align-tabs="start" class="bodyTab" (selectedTabChange)="yourFn($event)">
                <mat-tab label="Objetivo Actual"> <br><br>

                    <h1 class="title">Objetivos Mix<span class="title2"> Divisional </span></h1>
                    <br>
                    <div class="cien">
                        <div class="col-sm-3">
                            <div class="widget widget-rojo" *ngIf="strEstatusActual=='RECHAZADO'">
                                <span class="material-icons">assignment_late</span>
                                <p>RECHAZADO</p>
                            </div>
                            <div class="widget widget-verde" *ngIf="strEstatusActual=='AUTORIZADO'">
                                <span class="material-icons">assignment_turned_in</span>
                                <p>AUTORIZADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatusActual=='ASIGNADO'">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatusActual=='PENDIENTE'">
                                <span class="material-icons">assignment_returned</span>
                                <p>PENDIENTE</p>
                            </div>
                        </div>

                        <div class="col-sm-5 " *ngIf="strObservaciones!='' && strObservaciones "> 
                            <div class="widget widget-observaciones">
                                <span class="material-icons">
                                    description
                                </span>
                                <p>Observaciones: {{strObservaciones}} </p>
                            </div>
                        </div>
                    </div>
 
                    <br><br>
                    <div class="row">
                       
                        <mat-form-field appearance="fill" class="matcell col-3"     style="color: #2494cc;"
                        >
                            <mat-label>Promedio de Venta 2023</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoPDivisionalA | currency}}"   name="first2">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="matcell col-3" style="color: #00c3af;">
                            <mat-label>Promedio de Venta 2024</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoPDivisional | currency}}"   name="first2">
                        </mat-form-field>

                     
                        <mat-form-field appearance="fill" class="matcell col-3" style="color: #815799; font-weight: 600;">
                            <mat-label>Objetivo Capturado </mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoA | currency}}"  name="first1">
                        </mat-form-field>
                    </div>
                    <br><br><br>
                    <div class="row">
                        <div class="col contenedor_tabla">
                            <div class="divYs">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="headcolS encabezado">Linea</th>
                                            <th scope="col" class="headcol22  encabezado">%Ideal Nacional</th>
                                            <th scope="col" class="subject encabezado">%6 Meses</th>
                                            <th scope="col" class="subject encabezado">%Sucursales</th>
                                            <th scope="col" class="subject encabezado">Objetivo Capturado</th>
                                            <th scope="col" class="subject encabezado"> Último Trimestre 2024</th>
                                            
                                            <th scope="col" class="subject encabezado"> Último Trimestre 2023</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of arrayHistorico; index as i; ">
                                            <th class="headcolS panel">{{item.strLinea}}</th>
                                            <td class="headcol22 " style="    color: #ffff;
                                            font-weight: 500;
                                            text-align: center;
                                            background-color: #5e6472;">{{item.dblPObjetivo*100|number:'1.1-2'}}%

                                            </td>
                                            <td class="cuerpo1">
                                                <span *ngIf="item.dblPromedio<item.dblPObjetivo" style="color: red;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                                <span *ngIf="item.dblPromedio >=item.dblPObjetivo" style="color: green;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td class=" ">
                                                <span [ngClass]="{'text-success':item.dblPAgente >=item.dblPObjetivo || item.dblPAgente >=item.dblPromedio,'text-danger':item.dblPAgente <item.dblPObjetivo || item.dblPAgente <item.dblPromedio}">{{item.dblPAgente*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td class="text-right ">{{item.dblObjetivo| currency}}</td>
                                            <td class="cuerpo1">{{item.promedioAct| currency}}</td>
                                            <td class="cuerpo1">{{item.promedioAnt| currency}}</td>
                                             
                                        </tr>
                                        <tr>
                                            <th class="headcolS pie1"> </th>
                                            <th class="headcol22 pie1"> 100%</th>
                                            
                                            <th class=" pie1"> 100%</th>
                                            
                                            <th class="pie1 "> 100%</th> 
                                            
                                            <td class="pie1"> {{dblObjetivoG | currency}}</td>
                                            <td class="pie1"> {{dblObjetivoPDivisional | currency}}</td>
                                            
                                            <td class="pie1"> {{dblObjetivoPDivisionalA | currency}}</td>
                                         </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>


                </mat-tab>
                <mat-tab label="Objetivo Siguiente Mes"> <br><br>

                    <h1 class="title">Objetivos Mix<span class="title2"> Divisional Siguiente Mes</span></h1>
                    <br>
                    <div class="row d-flex justify-content-between ">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="widget widget-rojo" *ngIf="strEstatus=='RECHAZADO'">
                                <span class="material-icons">assignment_late</span>
                                <p>RECHAZADO</p>
                            </div>
                            <div class="widget widget-verde" *ngIf="strEstatus=='AUTORIZADO'">
                                <span class="material-icons">assignment_turned_in</span>
                                <p>AUTORIZADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatus=='ASIGNADO'">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatus==''">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatus=='PENDIENTE'">
                                <span class="material-icons">assignment_returned</span>
                                <p>PENDIENTE</p>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngIf="strObservaciones && strObservaciones!=''">
                            <div class="widget widget-observaciones">
                                <span class="material-icons">
                                    description
                                </span>
                                <p>Observaciones: {{strObservaciones}} </p>
                            </div>
                        </div>
                       
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 float-end">
                            <table class="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Total Sucursales</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of arrayEstatusSucursales">
                                        <th scope="row">{{e.intTotal}}</th>
                                        <td>
                                            <span *ngIf="e.strEstatus=='ASIGNADO'" class="material-icons texto-azul-e" title="ASIGNADO">pending</span>
                                            <span *ngIf="e.strEstatus==''||!e.strEstatus " class="material-icons texto-azul-e" title="ASIGNADO">pending</span>
                                            <span *ngIf="e.strEstatus=='PENDIENTE'" class="material-icons texto-azul-e" title="PENDIENTE">assignment_returned</span>
                                            <span *ngIf="e.strEstatus=='AUTORIZADO'" class="material-icons texto-verde-e" title="AUTORIZADO">assignment_turned_in</span>
                                            <span *ngIf="e.strEstatus=='RECHAZADO'" class="material-icons texto-rojo-e" title="RECHAZADO">assignment_late</span>
                                            <!--{{item.strEstatus}}-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
 
                    <br><br>
                    <div class="row">
                       
                        <mat-form-field appearance="fill" class="matcell col-3" style="color: #2494cc;">
                            <mat-label>Promedio de Venta 2023</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoPDivisionalA | currency}}"  name="first2">
                        </mat-form-field>
 
                        <mat-form-field appearance="fill" class="matcell col-3" style="color: #00c3af;">
                            <mat-label>Promedio de Venta 2024</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoPDivisional | currency}}"  name="first2">
                        </mat-form-field>
 
                        <mat-form-field appearance="fill" class="matcell col-3" style="color: #815799; font-weight: 600;">
                            <mat-label>Objetivo Capturado </mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoG | currency}}"  name="first1">
                        </mat-form-field>
                      
                        <button *ngIf=" totalSucursalesAut==totalSucursales && strEstatus==''" class="col-3" mat-raised-button style="background-color: #3e6294;color:white;height: 50px;margin-top: -7px;" (click)="fnGuardar()" [disabled]="btnGuardarD">Guardar </button>
                    </div>
                    <br><br><br>
                    <div class="row">
                        <div class="col contenedor_tabla">
                            <div class="divYs">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="headcolS encabezado">Linea</th>
                                            <th scope="col" class="headcol22  encabezado">%Ideal Nacional</th>
                                            <th scope="col" class="subject encabezado">%6 Meses</th>
                                            <th scope="col" class="subject encabezado">%Sucursales</th>
                                            <th scope="col" class="subject encabezado">Objetivo Capturado</th>
                                            <th scope="col" class="subject encabezado">Último Trimestre 2024</th>
                                            <th scope="col" class="subject encabezado">Último Trimestre 2023</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of arrayHistorico; index as i; ">
                                            <th class="headcolS panel">{{item.strLinea}}</th>
                                            <td class="headcol22 " style="    color: #ffff;
                                            font-weight: 500;
                                            text-align: center;
                                            background-color: #5e6472;">
                                            {{item.dblPObjetivo*100|number:'1.1-2'}}%

                                            </td>
                                            <td class="cuerpo1">
                                                <span *ngIf="item.dblPromedio<item.dblPObjetivo" style="color: red;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                                <span *ngIf="item.dblPromedio >=item.dblPObjetivo" style="color: green;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td class=" ">
                                                <span [ngClass]="{'text-success':item.dblPAgente >=item.dblPObjetivo || item.dblPAgente >=item.dblPromedio,'text-danger':item.dblPAgente <item.dblPObjetivo || item.dblPAgente <item.dblPromedio}">
                                                    {{item.dblPAgente*100|number:'1.1-2'}}%</span>
                                            </td>
                                            

                                            <td class="text-right ">{{item.strSucursal| currency}}</td>
                                            <td class="text-right ">{{item.promedioAct| currency}}</td>
                                            <td class="text-right ">{{item.promedioAnt| currency}}</td>
                                            
                                        </tr>
                                        <tr>
                                            <th class="headcolS pie1"> </th>
                                            <th class="headcol22 pie1"> 100%</th>
                                            <!-- <th class="headcol3 pie1"> Autorizar/Rechazar</th>-->
                                            <td class="pie1"> 100%</td>
                                            <td class="pie1"> 100%</td>
                                            <td class="pie1"> {{dblObjetivoG | currency}}</td>
                                            <td class="pie1"> {{dblObjetivoPDivisional | currency}}</td>
                                            <td class="pie1"> {{dblObjetivoPDivisionalA | currency}}</td>
                                         </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>


                </mat-tab>
                <mat-tab label="Objetivo Sucursales">
                    <br><br>
                    <h1 class="title">Capturar <span class="title2">Objetivo</span> por <span class="title2"> Sucursales </span></h1>
                    <br><br>
             
                    <div class="row">
                        <div class="col-sm-12">
                            <br><br>
                            <div class="col-sm-6 col-lg-4">
                                <h4>Seleccione Sucursal</h4>
                                <div class="input-group mb-3">
                                    <mat-form-field class=" pe-2 mx-0" style="width: 65%;" appearance="fill">
                                        <mat-label>Seleccione sucursal</mat-label>
                                        <mat-select [(ngModel)]="sucursalSelected">
                                            <!-- (change)="fnFiltrar()"-->
                                            <mat-option *ngFor="let item of arraySucursales" [value]="item">
                                                {{item.strEstatus}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <button mat-raised-button style="background-color:#3e6294;color:white;height:50px;" (click)="fnFiltrar()"  class="px-0 mx-0 w-25">Filtrar</button>
                                </div>
                            </div>
                      
                            <div class="col-sm-3">
                                <div class="widget widget-rojo" *ngIf="strEstatusS=='RECHAZADO'">
                                    <span class="material-icons">assignment_late</span>
                                    <p>RECHAZADO</p>
                                </div>
                                <div class="widget widget-verde" *ngIf="strEstatusS=='AUTORIZADO'">
                                    <span class="material-icons">assignment_turned_in</span>
                                    <p>AUTORIZADO</p>
                                </div>
                                <div class="widget widget-azul" *ngIf="strEstatusS=='ASIGNADO'">
                                    <span class="material-icons">pending</span>
                                    <p>ASIGNADO</p>
                                </div>
                                <div class="widget widget-azul" *ngIf="strEstatusS=='PENDIENTE'">
                                    <span class="material-icons">assignment_returned</span>
                                    <p>PENDIENTE</p>
                                </div>
                            </div>
                            <br><br>
                        </div>

                        <div class="row" *ngIf='arrayObjetivoCapturadoSuc.length>0'>
                            <br><br>
                            <h1 class="title">Objetivo Capturado <span class="title2">{{sucursalSelected.strEstatus}} </span></h1>
                            <br>
                            <div class="row">
                                <form class="example-form">
                                    <mat-form-field class="example-full-width inputObj col-3" appearance="fill" style="color: #2494cc;">
                                        <mat-label>Promedio de Venta 2023</mat-label>
                                       <input matInput placeholder="0" value="{{dblObjetivoPSA | currency}}" name="tres"  >
                                    </mat-form-field>
                                    
                                    <mat-form-field class="example-full-width inputObj col-3" appearance="fill" style="color: #00c3af;">
                                        <mat-label>Promedio de Venta 2024</mat-label>
                                       <input matInput placeholder="0" value="{{dblObjetivoPS | currency}}" name="tres" >
                                    </mat-form-field>

                                    <mat-form-field class="example-full-width  inputObj col-3" appearance="fill"  style="color: #815799; font-weight: 600;">
                                        <mat-label>Objetivo Capturado</mat-label>
                                      <input matInput placeholder="0" value="{{dblObjetivoC | currency}}" name="tres"  >
                                    </mat-form-field>

                                   
                                </form>
                            </div>
                            <br>
                            <div class="row mb-3-px">
                                <div class="col-8">
                                    <table class="table ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Linea
                                                </th>
                                                <th scope="col">Objetivo</th>
                                                <th scope="col">Promedio Vta 2024</th>
                                                
                                                <th scope="col">Promedio Vta 2023</th>
                                                <th scope="col">%Capturado</th>
                                                <th scope="col">%3 Meses</th>
                                                <th scope="col">%Ideal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of arrayObjetivoCapturadoSuc; index as i; ">
                                                <th scope="row">{{item.strLinea}}</th>
                                                <td><b>{{item.dblObjetivo|currency}}</b></td>
                                                <td>{{item.promedioAct|currency}}</td>
                                                <td>{{item.promedioAnt|currency}}</td>
                                                <td [ngClass]="{'text-danger':item.dblObjetivo/dblObjetivoC<item.dblPromedio||item.dblObjetivo/dblObjetivoC<item.dblPObjetivo,
                                                'text-success':item.dblObjetivo/dblObjetivoC>=item.dblPromedio||item.dblObjetivo/dblObjetivoC>=item.dblPObjetivo}">
                                                    {{(item.dblPAgente)*100 |number:'1.1-2'}}%
                                                </td>
                                                <td [ngClass]="{'text-danger':item.dblPromedio<item.dblPObjetivo,'text-success':item.dblPromedio>=item.dblPObjetivo}">
                                                    {{item.dblPromedio*100|number:'1.1-2'}}%</td>
                                                <td style="color: #118ce4;
                                                    font-weight: 500;">{{item.dblPObjetivo*100|number:'1.1-2' }}%</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-4">
                                    <div class="form-group ">
                                        <label for="exampleFormControlTextarea1">Observaciones</label>
                                        <textarea [(ngModel)]="strObservacionesS" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                    <div class="row">
                                        <button class="col-5" mat-raised-button style="  background-color: #849974; 
                                             color: white;height: 50px;    margin-top: 60px;" (click)="fnAutorizar('AUTORIZADO')" *ngIf="btnAutorizar&&strEstatusS=='PENDIENTE'">Autorizar</button>
                                        <div class="col-2"></div>
                                        <button class="col-5" mat-raised-button style="  background-color: #613a43;  color: white;height: 50px;    margin-top: 60px;" (click)="fnAutorizar('RECHAZADO')" *ngIf="btnRechazar&&strEstatusS=='PENDIENTE'">Rechazar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <br><br><br>
                            <h2 class="title">Objetivos <span class="title2">Asignados</span></h2>
                            <br>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Sucursal</th>
                                        <th scope="col">Objetivo</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayTotalObj">
                                        <th scope="row">({{item.strSucursal}}) {{item.strGerente}}</th>
                                        <td> <button type="button" (click)="fnGetObjSuc(item)" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            {{item.dblObjetivo|currency}}
                                          </button></td>
                                        <td>
                                            <!--{{item.strEstatus}}-->
                                            <span *ngIf="item.strEstatus=='ASIGNADO'" class="material-icons texto-azul-e" title="ASIGNADO">pending</span>
                                            <span *ngIf="item.strEstatus=='PENDIENTE'" class="material-icons texto-azul-e" title="PENDIENTE">assignment_returned</span>
                                            <span *ngIf="item.strEstatus=='AUTORIZADO'" class="material-icons texto-verde-e" title="AUTORIZADO">assignment_turned_in</span>
                                            <span *ngIf="item.strEstatus=='RECHAZADO'" class="material-icons texto-rojo-e" title="RECHAZADO">assignment_late</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br>
                  
                </mat-tab>
            </mat-tab-group>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" id="exampleModalLabel">Sucursales con Objetivos Asignados</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Agente</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Objetivo</th>
                                        <th scope="col">Objetivo Anterior</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayGerentesSuc">
                                        <th scope="row">{{item.strAgente}}</th>
                                        <td>{{item.strLinea}}</td>
                                        <td>{{item.dblObjetivo|currency}}</td>

                                        <td>{{item.dblPObjetivo|currency}}</td>
                                        <td>{{item.strEstatus}}</td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td>Total Asignado:</td>
                                        <td><b>{{ dblTotalAsigSuc|currency}}</b></td>
                                        <td></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<app-footer></app-footer>