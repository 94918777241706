<div class="container-fluid py-3">
    <div class="container">
        <div class="row mb-3">
            <div class="col-12 text-center d-flex  align-items-center">
                <div class="col-4"></div>
                <div class="col-4">
                    <h1 class="fs-2 fw-bolder mb-0">
                        {{strTipo | uppercase}}
                   </h1> 
                </div>
                <div class="col-4 d-flex justify-content-end">
                    <button  data-bs-toggle="modal" data-bs-target="#exampleModalCenter" class="btn btn-primary ms-auto d-flex"><i class="bi bi-plus-circle"></i> <span class="d-none d-md-block ms-2">Nuevo Lead</span> </button>
                </div>
            </div>
        </div>
        <!--<div class="row ">
            <div class="card px-0 shadow">
                <div class="card-body pt-0">
                    <div class="row my-3">
                        <div class="col-12 d-flex justify-content-center align-items-center">

                            <h5 class="m-0 d-flex flex-column flex-md-row"> <span class="spanTop mx-auto">OBJETIVO MENSUAL:</span><span style="background-color: #28A745;" class=" ms-2  p-1 h5 text-white rounded spanTop text-nowrap">$12,000,000 &nbsp;/&nbsp;{{ global | currency:'':'symbol':'1.2-2'}}</span></h5>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-12 col-md-4 d-flex justify-content-center" *ngIf="arrayFiltros.lstDivisionales">
                            <mat-form-field >
                                <mat-label><i class="bi bi-person-lock"></i> Gerente</mat-label>
                                <mat-select [(ngModel)]="strGerenteDiv" (selectionChange)="fnSelect('Sucursal');">
                                    <mat-option *ngFor="let ger of arrayDivicionales" [value]="ger.strGerente">{{ger.strNombre}}</mat-option> 
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4 my-3 my-md-0 d-flex justify-content-center"  *ngIf="arrayFiltros.lstSucursales">
                            <mat-form-field>
                                <mat-label><i class="bi bi-buildings"></i> Sucursal</mat-label>
                                <mat-select [(ngModel)]="strSucursal" (selectionChange)="fnSelect('Agente');">
                                    <mat-option *ngFor="let suc of arraySucursales" [value]="suc.strSucursal">{{suc.strNombre}}</mat-option> 
                                </mat-select>
                              </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4 d-flex justify-content-center" *ngIf="arrayFiltros.lstAgentes">
                            <mat-form-field>
                                <mat-label><i class="bi bi-person"></i> Vendedor</mat-label>
                                <mat-select [(ngModel)]="strAgentee" > 
                                        <mat-option *ngFor="let agent of arrayAgentess" [value]="agent.strAgente">{{agent.strNombre}}</mat-option> 
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
    <div class="row mt-3 ">
        <div class="col-12 d-flex justify-content-center">
            <div class="bg-white col-12">
                <div class="d-flex justify-content-between py-2" >
                <div class="d-flex align-items-center col-12  col-md-5">
                    <div class="input-group mx-3 ">
                        <span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
                        <input type="text" class="form-control" placeholder=" Buscar" [(ngModel)]="filterPost">
                    </div>
                    <div class="dropdown">
                       
                        <button class="btn btn-outline-secondary  dropdown-toggle justify-content-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi " [ngClass]="{'bi-funnel':!checkboxes.hoy && !checkboxes.manana && !checkboxes.estaSemana && !checkboxes.esteMes && !checkboxes.futuros && !checkboxes.conDemora,
                            'bi-calendar-check':checkboxes.hoy,
                            'bi-calendar-day':checkboxes.manana,
                            'bi-calendar-date':checkboxes.estaSemana,
                            'bi-calendar-month':checkboxes.esteMes,
                            'bi-cloud-upload':checkboxes.futuros,
                            'bi-stopwatch':checkboxes.conDemora}" ></i> {{ 
                                (checkboxes.hoy && 'Hoy') || 
                                (checkboxes.manana && 'Mañana') || 
                                (checkboxes.estaSemana && 'Esta semana') || 
                                (checkboxes.esteMes && 'Este mes') || 
                                (checkboxes.futuros && 'Futuros') || 
                                (checkboxes.conDemora && 'Con demora') ||
                                'Filtrar' 
                              }}
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <form>
                                <div class="dropdown-item d-flex">
                                    <input type="checkbox" id="checkbox1" name="radioGroup" [checked]="checkboxes['conDemora']" > <!--(change)="onCheckboxChange('conDemora')"-->
                                    <label for="checkbox1" class="ms-2 me-auto">Con demora</label>
                                </div>
                                <div class="dropdown-item d-flex">
                                    <input type="checkbox" id="checkbox2" name="radioGroup" [checked]="checkboxes['hoy']"> <!-- (change)="onCheckboxChange('hoy')"-->
                                    <label for="checkbox2" class="ms-2 me-auto">Hoy</label>
                                </div>
                                <div class="dropdown-item d-flex">
                                    <input type="checkbox" id="checkbox3" name="radioGroup" [checked]="checkboxes['manana']" ><!--(change)="onCheckboxChange('manana')"-->
                                    <label for="checkbox3" class="ms-2 me-auto">Mañana</label>
                                </div>
                                <div class="dropdown-item d-flex">
                                    <input type="checkbox" id="checkbox4" name="radioGroup" [checked]="checkboxes['estaSemana']"> <!-- (change)="onCheckboxChange('estaSemana')"-->
                                    <label for="checkbox4" class="ms-2 me-auto">Esta semana</label>
                                </div>
                                <div class="dropdown-item d-flex">
                                    <input type="checkbox" id="checkbox5" name="radioGroup" [checked]="checkboxes['esteMes']" > <!--(change)="onCheckboxChange('esteMes')"-->
                                    <label for="checkbox5" class="ms-2 me-auto">Este mes</label>
                                </div>
                                <div class="dropdown-item d-flex">
                                    <input type="checkbox" id="checkbox6" class="float-start" name="radioGroup" [checked]="checkboxes['futuros']" > <!--(change)="onCheckboxChange('futuros')"-->
                                    <label for="checkbox6" class="ms-2 me-auto">Futuros</label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="d-flex col-12 justify-content-end col-md-4">
                    <div class="btn-group mx-2  float-end" role="group" aria-label="Basic example">
                        <button type="button" class="btn  btn-sm d-flex flex-column no-btn p-0"
                            [ngClass]="{'active ': activeButton === 'calendario'}" (click)="cambiarVista('calendario')">  
                            <i class="m-auto fs-3 bi"  [ngClass]="{'bi-calendar-date-fill active text-primary': activeButton === 'calendario',' bi-calendar-date': activeButton != 'calendario'}"></i>
                            <span class="mx-auto fw-lighter"  [ngClass]="{'fw-bold': activeButton === 'calendario'}">Calendario</span>
                        </button>
                        <button type="button" class="btn  btn-sm d-flex flex-column no-btn p-0 mx-3"
                            [ngClass]="{'active ': activeButton === 'lista'}" (click)="cambiarVista('lista')">  
                            <i class="m-auto fs-3 bi" [ngClass]="{'bi-list-check active text-primary': activeButton === 'lista','bi-list': activeButton != 'lista'}"></i>
                            <span class="mx-auto fw-lighter"  [ngClass]="{'fw-bold': activeButton === 'lista'}">Lista</span>
                        </button>
                        <button type="button" class="btn  btn-sm d-flex flex-column active no-btn p-0" 
                            [ngClass]="{'active ': activeButton === 'cards'}" (click)="cambiarVista('cards')">  
                            <i class="m-auto fs-3 bi" [ngClass]="{'bi-credit-card-2-front-fill active text-primary': activeButton === 'cards','bi-credit-card-2-front': activeButton != 'cards'}"></i>   
                            <span class="mx-auto fw-lighter"  [ngClass]="{'fw-bold': activeButton === 'cards'}">Cards</span>
                        </button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <!-- Cards -->
    <div class="row mt-1" *ngIf="banderaCards">
        <div class="col-lg-3 col-md-6 col-sm-12 border-end " *ngIf="strTipo == 'lead'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-7 d-flex align-items-center">
                            <div class="circleCantidadLeads mx-2">
                                <span class="textCantidad">{{intTotal_por_contactar}}</span>
                            </div>
                            <span class="p-2 text-sm text-bold me-auto mt-auto">Por contactar</span>
                        </div>
                        <div class="col-5 d-flex align-items-center">
                            <span class="me-auto text-sm px-3">{{ dblMontoPorContactar | currency:'':'symbol':'1.2-2' }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado w-100 m-auto"></div>
                </div>
            </div>
            <div class="row columna-con-scroll p-2">
                <div class="card card-sin-datos mx-auto mt-1" *ngIf="array_por_contactar.length == 0"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="shadow card mx-auto mt-1 px-0" data-bs-toggle="modal" data-bs-target="#detalleLead" style="width: 95%;" *ngFor="let item of datosFiltradosContactar.slice(0, elementosPorPaginaPorContactar) | pipeTablero: filterPost; let i = index" (click)="fnDetalleLead(item.intIdLeads)" > 
                    <div class="card-body container-fluid">
                       
                        <div class="row d-flex">
                            <div class="col-3 d-flex px-0">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-7 text-center d-flex align-items-center justify-content-end px-0">
                                <p class="mb-0">{{item.strNombreEmpresa}}</p>
                            </div>
                            <div class="col-2 d-flex justify-content-end pe-1">
                                <i class="far fa-star  mt-0"></i>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-between px-1 mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> 
                                    <span class="m-auto" style="font-size: 10px; font-weight: 600;"> &nbsp;0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-end"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <i class="far fa-star me-auto mx-3" *ngIf="item.strTipo == 'GANADO'"></i>
                        <div class="ms-auto d-flex align-items-center">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1 fw-bolder"></i>
                        </div>
                    </div>
                </div>
                <button *ngIf="esDispositivoPequeno && datosFiltradosContactar.length > 3 && intTotal_por_contactar != elementosPorPaginaPorContactar" class="btn btn-outline-success btn-sm m-auto"  (click)="mostrarMas('porContactar')">Ver más</button> 
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 border-end" *ngIf="strTipo == 'lead'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 d-flex align-items-center">
                            <div class="circleCantidadLeads rounded-circle p-2 mx-2">
                                <span class="text-white">{{intTotal_presentacion}}</span>
                            </div>
                            <span class="p-2 text-sm text-bold me-auto mt-auto">Presentación</span>
                        </div>
                        <div class="col-6 d-flex">
                            <span class="m-auto text-sm px-3">{{ dblMontoPresentacion | currency:'':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado2 w-100 m-auto"></div>
                </div>
            </div>
            <div class="row columna-con-scroll p-2">
                <div class="card card-sin-datos mx-auto mt-1 " *ngIf="arrayPresentacion.length == 0" style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="card mx-auto mt-1 px-0" data-bs-toggle="modal" data-bs-target="#detalleLead" style="width: 95%;"
                    *ngFor="let item of datosFiltradosPresentacion.slice(0, elementosPresentacion)| pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item.intIdLeads)" > 
                    <div class="card-body container-fluid">
                        <div class="row d-flex">
                            <div class="col-3 d-flex px-0">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-7 text-center d-flex align-items-center justify-content-end px-0">
                                <p class="mb-0">{{item.strNombreEmpresa}}</p>
                            </div>
                            <div class="col-2 d-flex justify-content-end pe-1">
                                <i class="far fa-star  mt-0"></i>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-between px-1 mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> 
                                    <span class="m-auto" style="font-size: 10px; font-weight: 600;"> &nbsp;0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-end"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <div class="ms-auto d-flex align-items-center ">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 || item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1 fw-bolder"></i>
                        </div>
                    </div>
                </div>
                <button *ngIf="esDispositivoPequeno && datosFiltradosPresentacion.length > 3"
                     class="btn btn-outline-success btn-sm m-auto"  (click)="mostrarMas('presentacion')">Ver más</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 border-end" *ngIf="strTipo == 'lead'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 d-flex ">
                            <div class="circleCantidadLeads mx-2">
                                <span class="textCantidad">{{intTotal_recorrido_lead}}</span>
                            </div>
                            <span class="p-2 text-sm text-bold me-auto mt-auto">Recorrido</span>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <span class="m-auto text-sm px-3">{{ dblMontoRecorridoLead | currency:'':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado3 w-100 m-auto"></div>
                </div>
            </div>
            <div class="row columna-con-scroll p-2">
                <div class="card card-sin-datos mx-auto mt-1" *ngIf="arrayRecorridoLead.length == 0"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="card mx-auto mt-1 px-0" data-bs-toggle="modal" data-bs-target="#detalleLead" style="width: 95%;"
                    *ngFor="let item of datosFiltradosRecorrido.slice(0, elementosRecorrido)| pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item.intIdLeads)"  >
                    <div class="card-body container-fluid">
                        <div class="row d-flex">
                            <div class="col-3 d-flex px-0">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-7 text-center d-flex align-items-center justify-content-end px-0">
                                <p class="mb-0">{{item.strNombreEmpresa}}</p>
                            </div>
                            <div class="col-2 d-flex justify-content-end pe-1">
                                <i class="far fa-star  mt-0"></i>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-between px-1 mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> 
                                    <span class="m-auto" style="font-size: 10px; font-weight: 600;"> &nbsp;0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-end"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <div class="ms-auto d-flex align-items-center ">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 || item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>
                <button *ngIf="esDispositivoPequeno && datosFiltradosRecorrido.length > 3"
                    class="btn btn-outline-success btn-sm m-auto"  (click)="mostrarMas('recorrido')">Ver más</button>
            </div>

        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 border-end" *ngIf="strTipo == 'lead'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 d-flex ">
                            <div class="circleCantidadLeads mx-2">
                                <span class="textCantidad">{{intTotal_Perdido}}</span>
                            </div>
                            <span class="p-2 text-sm text-bold me-auto mt-auto">Perdidos</span>
                        </div>
                        <div class="col-6 d-flex alig-items-center">
                            <span class="m-auto text-sm px-3">{{ dblMontoPerdidos | currency:'':'symbol':'1.2-2'
                                }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado4 w-100 m-auto"></div>
                </div>
            </div>
            <div class="row columna-con-scroll p-2">
           
                <div class="card card-sin-datos mx-auto mt-1" *ngIf="arrayPerdidos.length == 0" style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="card mx-auto mt-1 px-0" data-bs-toggle="modal" data-bs-target="#detalleLead" style="width: 95%;"
                    *ngFor="let item of arrayPerdidos.slice(0, elementosPerdido)| pipeTablero: filterPost; let i = index" (click)="fnDetalleLead(item.intIdLeads)">
                    <div class="card-body container-fluid">
                        <div class="row d-flex">
                            <div class="col-3 d-flex px-0">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-7 text-center d-flex align-items-center justify-content-end px-0">
                                <p class="mb-0">{{item.strNombreEmpresa}}</p>
                            </div>
                            <div class="col-2 d-flex justify-content-end pe-1">
                                <i class="far fa-star  mt-0"></i>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-between px-1 mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> 
                                    <span class="m-auto" style="font-size: 10px; font-weight: 600;"> &nbsp;0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-end"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <div class="ms-auto d-flex align-items-center">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 || item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>
                <button *ngIf="esDispositivoPequeno && arrayPerdidos.length > 3" 
                    class="btn btn-outline-success btn-sm m-auto" (click)="mostrarMas('perdido')">Ver más</button>
            </div>

        </div>


        <!-- ñ__ñ/ aqui sigue el prospecto -->
     
        <!--cliente-->
        <div class="col-md-4 col-sm-12 border-end" *ngIf="strTipo == 'familia'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-8 d-flex ">
                            <div class="circleCantidadLeads mx-2">
                                <span class="textCantidad">{{arrayFamilias.length }}</span>
                            </div>
                            <span class="p-2 text-sm text-bold me-auto mt-auto"><b>Familias</b></span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado4 w-100 m-auto"></div>
                </div>
            </div><!--header de columna-->
            <!--inicia cuerpo-->
            <div class="row columna-con-scroll p-2">
                <div class="px-0 card card-sin-datos mx-auto mt-1" *ngIf="arrayFamilias.length == 0"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="px-0 card mx-auto mt-3 border-0 shadow-sm" data-bs-toggle="modal" data-bs-target="#detalleLead" style="width: 95%;" *ngFor="let item of arrayFamilias" (click)="fnDetalleLead(item.intIdLeads)"> <!-- datosFiltradosPropuestaCliente.slice(0, elementosPECliente) | pipeTablero: filterPost; let i = index-->
                    <div class="card-body container-fluid">
                        <div class="row">
                            <div class="col-3 d-flex px-0">
                                <div class="circle"> 
                                    <span class="text"  >{{item.strABNombre}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center d-block">
                                <p class="textoCards m-auto"><b>{{item.strFamilia}}</b></p>
                                <span class="bgSecondary fw-bold fs12" *ngIf="item.strPropietario !=''">{{item.strNombreAgente}}</span>
                                <span class="bgSecondary fw-bold fs12" *ngIf="item.strPropietario ==''">Sin Asignar</span>
                            </div>
                            <div class="col-1 d-flex px-0">
                                <button type="button" class="btn btn-link ps-1"><i class="fa-solid fa-circle-info"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex border-0 mt-2" [ngClass]="{'footerRojo':item.strPropietario =='', 'footerAzul':item.strPropietario !=''}">
                       <span class="fs12">Total Clientes: <span class="fw-bold">{{item.intTotalCte}}</span></span> 
                    </div>
                </div>
                <button *ngIf="esDispositivoPequeno && datosFiltradosPropuestaCliente.length > 3"
                     class="btn btn-outline-success btn-sm m-auto" (click)="mostrarMas('propuestaCliente')">Ver
                    más</button>
            </div>

        </div>
        <div class="col-md-4 col-sm-12 border-end columna-con-scroll" *ngIf="strTipo == 'familia'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 d-flex ">
                            <div class="circleCantidadLeads mx-2">
                                <span class="textCantidad">{{intTotal_negociacion_cliente}}</span>
                            </div>
                            <span class="p-2 text-sm text-bold me-auto mt-auto"><b>Sin Asignar</b></span>
                        </div>
                      
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado5 w-100 m-auto"></div>
                </div>
            </div>
            <div class="row columna-con-scroll p-2">
                <div class="px-0 card card-sin-datos mx-auto mt-1" *ngIf="arrayNegociacionCliente.length == 0"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="px-0 card mx-auto" data-bs-toggle="modal" data-bs-target="#detalleLead" style="width: 95%;"
                    *ngFor="let item of datosFiltradosNegociacionCliente.slice(0, elementosNCliente) | pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item.intIdLeads)" >
                    <div class="card-body container-fluid">
                        <div class="row">
                            <div class="col-3 d-flex">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center d-flex">
                                <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                                <span>{{item.strFechaInicioServicio}}</span>
                            </div>
                            <div class="col-1 d-flex">
                                <i class="far fa-star mx-auto mt-0"></i>
                            </div>
                            <div class="col-12 d-flex mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> <span class="m-auto"
                                        style="font-size: 10px; font-weight: 600;"> &nbsp;0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex  mt-2"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 || item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>
                <button *ngIf="esDispositivoPequeno && datosFiltradosNegociacionCliente.length > 3"
                     class="btn btn-outline-success btn-sm m-auto"  (click)="mostrarMas('negociacionCliente')">Ver
                    más</button> 
            </div>

        </div>
        <div class="col-md-4 col-sm-12 border-end" *ngIf="strTipo == 'familia'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 d-flex ">
                            <div class="circleCantidadLeads mx-2">
                                <span class="textCantidad">{{intTotal_cierre_cliente}}</span>
                            </div>
                            <span class="p-2 text-sm text-bold ms-auto mt-auto">Asignados</span>
                        </div>
                     
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado6 w-100 m-auto"></div>
                </div>
            </div>
            <div class="row columna-con-scroll p-2">
                <div class="px-0 card card-sin-datos mx-auto mt-1" *ngIf="arrayCierreCliente.length == 0"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="px-0 card mx-auto mt-1" data-bs-toggle="modal" data-bs-target="#detalleLead" style="width: 95%;"
                    *ngFor="let item of datosFiltradosCierreCliente.slice(0, elementosCCliente) | pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item.intIdLeads)"  >
                    <div class="card-body container-fluid">
                        <div class="row">
                            <div class="col-3 d-flex">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center d-block justify-content-center align-items-center">
                                <p class="textoCards mt-2">{{item.strNombreEmpresa}}</p>
                                <span class="text-bold m-auto" *ngIf="item.strFechaInicioServicio"
                                    style="font-size: 11px;">Inicio: {{item.strFechaInicioServicio.slice(0,
                                    item.strFechaInicioServicio.indexOf(' '))}}</span>
                            </div>
                            <div class="col-1 d-flex">
                                <i class="far fa-star mx-auto mt-0"></i>
                            </div>
                            <div class="col-12 d-flex mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> <span class="m-auto"
                                        style="font-size: 10px; font-weight: 600;"> &nbsp;0</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex  mt-2"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <i class="far fa-star me-auto mx-3 text-warning" *ngIf="item.strTipo == 'GANADO'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>
                <button *ngIf="esDispositivoPequeno && datosFiltradosCierreCliente.length > 3"
                     class="btn btn-outline-success btn-sm m-auto" (click)="mostrarMas('cierreCliente')">Ver
                    más</button>
            </div>
        </div>
    </div>

    <!--////////////////////////////////// Lista ///////////////////////////////////////////////7 -->
  <div class="row mt-1" *ngIf="banderaLista">
        <div class="col-12">
            <ul class="list-group" *ngIf="strTipo == 'lead'">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex align-items-center">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_por_contactar}}</span>
                                    </div>

                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Por contactar</span>
                                </div>
                                <div class="col-6 d-flex align-items-center">
                                    <span class="m-auto text-sm px-3">{{ dblMontoPorContactar |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of datosFiltradosContactar.slice(0, elementosPorPaginaPorContactar) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;" (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal" data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Presentacion-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_presentacion}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Presentación</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoPresentacion |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado2 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of datosFiltradosPresentacion.slice(0, elementosPresentacion) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>
                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Recorrido-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_recorrido_lead}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Recorrido</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="m-auto text-sm px-3">{{ dblMontoRecorridoLead |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado3 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of datosFiltradosRecorrido.slice(0, elementosRecorrido) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto | currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Perdidos-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_Perdido}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Perdido</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoPerdidos |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado4 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of arrayPerdidos.slice(0, elementosPerdido) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
            </ul>
            <!--Prospecto-->
            <ul class="list-group" *ngIf="strTipo == 'prospecto'">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_propuesta}}</span>
                                    </div>

                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Propuesta Economica</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoPropuesta |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of datosFiltradosPropuesta.slice(0, elementosPEProspecto) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Negociacion-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_negociacion}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Negociación</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoNegociacion |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado2 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of datosFiltradosNegociacion.slice(0, elementosNProspecto) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Cierre-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_cierre}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Cierre</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoCierre | currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado3 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of datosFiltradosCierre.slice(0, elementosCProspecto) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto | currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
            </ul>
            <!--Cliente-->
            <ul class="list-group" *ngIf="strTipo == 'cliente'">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_propuesta_cliente}}</span>
                                    </div>

                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Propuesta Economica</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoPropuestaCliente |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of datosFiltradosPropuestaCliente.slice(0, elementosPECliente) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Negociacion-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_negociacion_cliente}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Negociación</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoNegociacionCliente |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado2 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of datosFiltradosNegociacionCliente.slice(0, elementosNCliente) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Cierre-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_cierre_cliente}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Cierre</span>
                                </div>
                                <div class="col-6 d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoCierreCliente |
                                        currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado3 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Fecha Inicio</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of datosFiltradosCierreCliente.slice(0, elementosCCliente) | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;"
                                            (click)="fnDetalleLead(item.intIdLeads)" data-bs-toggle="modal"
                                            data-bs-target="#detalleLead"><strong>{{item.strNombreEmpresa}}</strong></td>

                                        <td>{{item.dblMonto | currency:'':'symbol':'1.2-2'}}</td>
                                        <td>{{item.strFechaInicioServicio.split(' ')[0]}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mt-1" *ngIf="banderaCalendario">
        <div class="col-12 d-flex">
            <full-calendar class="m-auto col-10" id="calendar" #calendar [options]="calendarOptions"></full-calendar>
        </div>
    </div>

    <!-- Modal -->
<div class="modal fade" id="detalleLead" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content hmodal">
        <div class="modal-header d-flex img-fondo">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-1 d-flex">
                        <i class="fas fa-star text-lg mx-3 text-warning m-auto"></i>
                    </div>
                    <div class="col-10 d-flex text-center">
                        <h2 class="modal-title m-auto fw-bolder" id="exampleModalLongTitle">{{strNombreEmpresa}}</h2>
                    </div>
                    <div class="col-1">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body color-body-modal hmodal">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
                <mat-tab label="Principal">
                    <ng-template mat-tab-label>
                        <i class="fa-solid fa-circle-info"></i><span>PRINCIPAL</span>
                    </ng-template>
                    <div class="container-fluid">
                        <form [formGroup]="myForm" (ngSubmit)="onSave()">
                        <div class="row">
                            <div class="col-12 mt-3">
                              <span class="numeros">Descripción:</span>  
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100 bgTextArea rounded">
                                    <mat-label></mat-label>
                                    <textarea matInput placeholder="Descripcíon Cliente" formControlName="descripcion"></textarea>
                                    <mat-error *ngIf="myForm.get('descripcion').hasError('required')">Agrega descripcion</mat-error>
                                  </mat-form-field>
                            </div>
                        </div>
                         <div class="row my-4">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <span class="numeros me-2">Lider Responsable Ecodeli:</span> 
                                <mat-form-field appearance="outline" class="bgTextArea rounded col-6">
                                    <mat-label></mat-label>
                                    <select matNativeControl formControlName="lider">
                                        <option value="" selected></option>
                                        <option *ngFor="let car of foods" [value]="car.value">
                                          {{car.viewValue}}
                                        </option>
                                      </select>
                                      <i class="bi bi-person-check fs-4" matSuffix></i>
                                      <mat-error *ngIf="myForm.get('lider').hasError('required')">Agrega descripcion</mat-error>
                                  </mat-form-field>
                            </div>
                        </div>
                       <div class="row justify-content-between">
                            <div class="col-12 col-md-5">
                               <div class="col-12 mx-0 px-0 d-flex flex-column">
                                 <span class="numeros">Oficinas Corporativo:</span> 
                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                    <mat-label></mat-label>
                                    <input matInput placeholder="Placeholder" formControlName="corporativo">
                                    <mat-icon matSuffix>domain</mat-icon>
                                    <mat-error *ngIf="myForm.get('corporativo').hasError('required')">La ofcina es obligatorio</mat-error>
                                  </mat-form-field>
                               </div>
                               <div class="col-12 mx-0 px-0 mt-2  d-flex flex-column">
                                 <span class="numeros">Telefono:</span> 
                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                    <mat-label></mat-label>
                                    <input matInput placeholder="477-123-5445" formControlName="telefono" type="number" [(ngModel)]="prinPhone" (keypress)="onKeyPress($event)" maxlength="10">
                                    <mat-icon matSuffix> stay_current_portrait</mat-icon>
                                    <mat-error *ngIf="myForm.get('telefono').hasError('required')">El teléfono es obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('telefono').hasError('maxlength')">El teléfono debe tener máximo 10 caracteres</mat-error>
                                  </mat-form-field>
                               </div>
                            </div>
                             <div class="col-12 mt-2 mt-md-0 col-md-6 d-flex flex-column">
                                <span class="numeros">Domicilio:</span> 
                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                    <mat-label></mat-label>
                                    <textarea matInput placeholder="Descripcíon Cliente" formControlName="domicilio" rows="6" ></textarea>
                                    <mat-icon matSuffix> pin_drop</mat-icon>
                                    <mat-error *ngIf="myForm.get('domicilio').hasError('required')">El domicilio es obligatorio</mat-error>
                                  </mat-form-field>
                            </div>
                        </div>
                        <div class="row justify-content-between mt-2">
                            <div class="col-12 col-md-5">
                                <span class="numeros">Propiedades:</span> 
                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                    <mat-label></mat-label>
                                    <input type="number" formControlName="propiedades" matInput placeholder="ejemplo: 5, 12, 342, 6774">
                                    <mat-icon matSuffix>settings</mat-icon>
                                    <mat-error *ngIf="myForm.get('propiedades').hasError('required')">Agrega cuantas propiedades</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 mt-2 mt-md-0 col-md-6 d-flex flex-column">
                                <span class="numeros">Asignadas:</span> 
                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                    <mat-label></mat-label>
                                    <input type="number" formControlName="asignadas" matInput placeholder="ejemplo: 5, 12, 342, 6774">
                                    <mat-icon matSuffix>assignment_ind</mat-icon>
                                    <mat-error *ngIf="myForm.get('asignadas').hasError('required')">Agrega cuantas asignadas</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row justify-content-between mt-2">
                           <div class="col-12 col-md-5">
                                <span class="numeros">Estados: 
                                    <div class="row mb-2">
                                        <div class="d-flex flex-wrap" >
                                            <ng-container *ngFor="let j of arrayEstados">
                                                <span *ngIf="j.Seleccionado" class="rounded bg-secondary text-white p-1 mb-2 ms-2" >{{j.strEstado}}</span>
                                            </ng-container>
                                        </div>
                                    </div>                                        
                                 </span> 
                                 <div class="btn-group col-12 shadow-sm dropup">
                                    <button type="button" class="btn btn-light dropdown-toggle w-100 " data-bs-toggle="dropdown" aria-expanded="false">
                                      Seleccione:
                                    </button>
                                    <ul class="dropdown-menu heitdrop">
                                        <li *ngFor="let j of arrayEstados">
                                            <div class="form-check ms-1">
                                            <input class="form-check-input"  type="checkbox" [value]="j.Seleccionado" (change)="toggleEstado(j.strEstado)" id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault">
                                              {{j.strEstado}}
                                            </label>
                                          </div>
                                        </li>
                                    </ul>
                                  </div>
                            </div>
                             <div class="col-12 mt-2 mt-md-0 col-md-6 d-flex flex-column">
                                <span class="numeros">Marcas:</span> 
                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                    <mat-label></mat-label>
                                    <input matInput placeholder="Placeholder" formControlName="marcas">
                                    <mat-icon matSuffix>collections_bookmark</mat-icon>
                                    <mat-error *ngIf="myForm.get('marcas').hasError('required')">Las marcas son obligatorias </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <button mat-button type="submit" [disabled]="myForm.invalid">Enviar</button>
                            </div>
                        </div>
                        </form>
                    </div>
                </mat-tab>
                <!--fin tab principal-->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="fas fa-book-reader mx-1"></i> <span>LEAD</span>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-3">
                            <div class="col-md-4 col-sm-12 d-block ">
                                <span class="text-sm">Nombre: <span class="text-danger">*</span></span>
                                <input type="text" placeholder="Ingresa Nombre empresa" class="form-control"
                                    [(ngModel)]="strNombreEmpresa">
                            </div>
                            <div class="col-md-4 col-sm-12 d-block">
                                <span class="text-sm">Etapa</span>
                                <select name="etapa" id="etapa" class="form-select" [(ngModel)]="strEtapa"
                                    > <!--(change)="cambiarFase($event)"-->
                                    <option value="Por Contactar">Por contactar</option>
                                    <option value="Presentación">Presentación</option>
                                    <option value="Recorrido">Recorrido</option>
                                    <option value="Propuesta Economica">Propuesta Economica</option>
                                    <option value="Negociación">Negociación</option>
                                    <option value="Cierre">Cierre</option>
                                    <option value="PERDIDO">PERDIDO</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block">
                                <span class="text-sm">Fase</span>
                                <select name="fase" id="fase" class="form-select" [(ngModel)]="strFase"
                                    [disabled]="banderaFase">
                                    <option value="LEAD" >LEAD</option>
                                    <option value="PROSPECTO">PROSPECTO</option>
                                    <option value="CLIENTE">CLIENTE</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="text-sm m-auto">Sector : <span class="text-danger">*</span></span>
                                <select name="sector" id="sector" class="form-select" [(ngModel)]="strSector">
                                    <option value="" selected>-Selecciona una opcion-</option>
                                    <option value="Publico">Publico</option>
                                    <option value="Privado">Privado</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="text-sm m-auto">Giro: <span class="text-danger">*</span></span>
                                <select name="giro" id="giro" class="form-select" [(ngModel)]="intGiro">
                                    <option value="">-Selecciona una opcion-</option>
                                    <option *ngFor="let item of arrayGiros; let i = index"
                                        value="{{item.intID_Giro}}">{{item.strNombreGiro}}</option>

                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class=" m-auto text-sm">Familia</span>
                                <select name="familia" id="familia" class="form-select" [(ngModel)]="strFamilia">
                                    <option value="">--Selecciona una opcion--</option>
                                    <option *ngFor="let item of arrayLisFamilia; let i = index"
                                        value="{{item.strZona}}">{{item.strTipo}}</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="text-sm m-auto">Rango Empleados:</span>
                                <select name="probabilidad" id="probabilidad" class="form-select"
                                    [(ngModel)]="strNumeroEmpleados" > <!--(change)="cambiarPrioridad($event)"-->
                                    <option value="">-Selecciona una opcion-</option>
                                    <option value="{{item.strRangoEmpleados}}"
                                        *ngFor="let item of arrayTamanoEmpresa; let i = index">
                                        {{item.strRangoEmpleados}}</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="text-sm">Importe</span>
                                <input type="text" placeholder="Ingresa Importe" class="form-control"
                                    [(ngModel)]="dblImporte">
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="text-sm m-auto"># Elementos:</span>
                                <input type="number" min="0" value="0" class="form-control"
                                    [(ngModel)]="intCantidadElementos">
                            </div>
                            <div class="col-md-4 col-sm-12 p-2">
                                <span class="text-sm">Prioridad</span>
                                <select name="probabilidad" id="probabilidad" class="form-select"
                                    [(ngModel)]="strPrioridad" disabled="true">
                                    <option value="-Selecciona una opcion-">-Selecciona una opcion-</option>
                                    <option value="Alta">Alta</option>
                                    <option value="Media">Media</option>
                                    <option value="Baja">Baja</option>
                                    <option value="URGENTE">URGENTE</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="text-sm">Fecha Estimada Cierre</span>
                                <input type="date" class="form-control" min="{{strFechaEstimadaCierreInput}}"
                                    value="{{strFechaEstimadaCierreInput}}" > <!--(change)="cambiarFecha($event)"-->
                            </div>

                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="m-auto text-sm">Total Sucursales:</span>
                                <input type="text" placeholder="Total Sucursales" class="form-control ms-auto"
                                    [(ngModel)]="intTotalSucursales">
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-1">
                                <span class="m-auto">Razon Social:</span> <br>
                                <input type="text" placeholder="Razon Social" class="form-control  m-auto"
                                    [(ngModel)]="strRazonSocial">
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-1">
                                <span class="m-auto">Sitio Web:</span>
                                <input type="text" placeholder="Sitio Web" class="form-control ms-auto"
                                    [(ngModel)]="strSitioWeb">
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-1">
                                <span class="m-auto">RFC:</span>
                                <input type="text" placeholder="RFC" class="form-control  ms-auto"
                                    [(ngModel)]="strRFC">
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-1">
                                <span class="m-auto">Tipo Cotización:</span>
                                <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                    [(ngModel)]="strTipoCotizacion">
                                    <option value="">--Selecciona una opción--</option>
                                    <option value="Servicios especiales">Servicios especiales</option>
                                    <option value="Cotizacion Rapida">Cotizacion Rapida</option>
                                    <option value="Cotizacion por TYM">Cotizacion por TYM</option>
                                    <option value="Licitaciones">Licitaciones</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-1">
                                <span class=" m-auto text-sm">¿Por cual medio nos conocio?</span>
                                <select name="etiqueta" id="etiqueta" class="form-select"
                                    [(ngModel)]="strMedioConocio">
                                    <option value="">-Selecciona una opcion-</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Likedin">Linkedin</option>
                                    <option value="Conocidos">Conocidos</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-1">
                                <span class=" m-auto text-sm">¿Recomendado por?</span>
                                <input type="text" placeholder="Recomendado por" class="form-control"
                                    [(ngModel)]="strRecomendacion">
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="fas fa-id-card-alt mx-1"></i> <span>DATOS CONTACTO</span>
                    </ng-template>
                    <div class="container-fluid mt-2">
                       <div class="row justify-content-center">
                            <div class="col-11 card-contacto py-2 my-2">
                                <div class="row">
                                    <div class="col-12 col-md-6 d-flex flex-column  mt-2">
                                        <span class="numeros">
                                            contacto1
                                        </span>
                                        <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                            <mat-label></mat-label>
                                            <input matInput placeholder="Placeholder">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-6  d-flex flex-column  mt-2">
                                        <span class="numeros">
                                            contacto2
                                        </span>
                                        <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                            <mat-label></mat-label>
                                            <input matInput placeholder="Placeholder">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-6 d-flex flex-column  mt-2">
                                        <span class="numeros">
                                            Puesto
                                        </span>
                                        <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                            <mat-label></mat-label>
                                            <input matInput placeholder="Placeholder">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-6  d-flex flex-column  mt-2">
                                        <span class="numeros">
                                            Puesto
                                        </span>
                                        <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                            <mat-label></mat-label>
                                            <input matInput placeholder="Placeholder">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-6 d-flex flex-column  mt-2">
                                        <span class="numeros">
                                            Email
                                        </span>
                                        <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                            <mat-label></mat-label>
                                            <input matInput placeholder="Placeholder">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-6  d-flex flex-column  mt-2">
                                        <span class="numeros">
                                            Email
                                        </span>
                                        <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                            <mat-label></mat-label>
                                            <input matInput placeholder="Placeholder">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-9 d-flex flex-column">
                                                <span class="numeros">
                                                    Telefono
                                                </span>
                                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                                    <mat-label></mat-label>
                                                    <input matInput placeholder="Placeholder">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-3 d-flex flex-column">
                                                <span class="numeros">
                                                    Ext:
                                                </span>
                                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                                    <mat-label></mat-label>
                                                    <input matInput placeholder="Placeholder">
                                                </mat-form-field>
                                            </div>
                                        </div>  
                                       
                                    </div>
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-9 d-flex flex-column">
                                                <span class="numeros">
                                                    Telefono
                                                </span>
                                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                                    <mat-label></mat-label>
                                                    <input matInput placeholder="Placeholder">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-3 d-flex flex-column">
                                                <span class="numeros">
                                                    Ext:
                                                </span>
                                                <mat-form-field appearance="outline" class="bgTextArea rounded w-100">
                                                    <mat-label></mat-label>
                                                    <input matInput placeholder="Placeholder">
                                                </mat-form-field>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div>
                </mat-tab>
                <mat-tab label="DATOS FISCALES">
                    <ng-template mat-tab-label>
                        <i class="fas fa-route mx-1"></i> <span>DATOS FISCALES</span>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto">Codigo Postal:</span> <br>
                                <input type="text" placeholder="codigo postal" class="form-control m-auto"
                                    [(ngModel)]="strCodigoPostal" > <!--(keyup.enter)="fnGetDatosFiscales()"-->
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto">Calle <span class="text-danger">*</span></span>
                                <br>
                                <input type="text" placeholder="Calle" class="form-control  m-auto"
                                    [(ngModel)]="strCalle">
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto">Estado <span class="text-danger">*</span></span>
                                <br>
                                <input readonly type="text" placeholder="Estado" class="form-control  m-auto"
                                    [(ngModel)]="strEstado">
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto">No. Exterior <span
                                        class="text-danger">*</span></span> <br>
                                <input type="text" placeholder="No. Exterior " class="form-control  m-auto"
                                   > <!-- [(ngModel)]="strNumeroExterior"-->
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto">Delegación <span
                                        class="text-danger">*</span></span> <br>
                                <input readonly  type="text" placeholder="Delegación" 
                                    class="form-control  m-auto"><!--[(ngModel)]="strDelegacion"-->
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto">Entre Calles <span
                                        class="text-danger">*</span></span> <br>
                                <input  type="text" placeholder="Entre Calles"
                                    class="form-control  m-auto"> <!--[(ngModel)]="strEntreCalles"-->
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-2">
                                <span class="datosEmpresa m-auto">Colonia:</span>
                                <select name="probabilidad" id="probabilidad" class="form-select"
                                    ><!--[(ngModel)]="strColonia"npm-->
                                    <option *ngFor="let item of arrayColonias; let i = index" value="{{item.strColonia}}">{{item.strColonia}}
                                    </option>
                                    <option value="otraColonia">Otra</option>
                                </select>
                            </div>
                            <div class="col-md-6 col-sm-12 ">
                                <div class="row">
                                    <div class="col-12">
                                        <span class="datosEmpresa m-auto">Telefono <span
                                                class="text-danger">*</span></span>
                                    </div>
                                    <div class="col-3 p-1">
                                        <input type="text" placeholder="Lada" class="form-control"
                                           > <!-- [(ngModel)]="strLadaEmpresa"-->
                                    </div>
                                    <div class="col-9 p-1">
                                        <input type="text" placeholder="Telefono" class="form-control"
                                            > <!--[(ngModel)]="strTelefonoEmpresa"-->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-2">
                                <span class="datosEmpresa m-auto">Zona:</span>
                                <select name="zona" id="zona" class="form-select"> <!-- [(ngModel)]="strZona"-->
                                    <option *ngFor="let item of arrayZonas; let i = index" value="{{item.strZona}}">
                                        {{item.strZona}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 col-sm-12" *ngIf="banderaColonia">
                                <span class="datosEmpresa m-auto">Escriba Colonia <span
                                        class="text-danger">*</span></span> <br>
                                <input type="text" placeholder="Otra colonia" class="form-control  m-auto"
                                    > <!--[(ngModel)]="strOtraColonia"-->
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <i class="fas fa-paperclip mx-1"></i> <span>ANEXOS</span>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-3">
                            <div class="col-6">
                                <label class="btn btn-secondary btn-file text-sm ms-auto ">
                                    <i class="fas fa-plus"></i> Agregar Anexo <input 
                                        #file type="file" style="display: none;" required
                                        accept=".xlsx, .pdf, .png, .jpg, .jpeg"> <!--(change)="agregarAnexo($event)"-->
                                </label>
                            </div>
                            <div class="col-6 d-flex">
                                <select name="tipo" id="tipo" class="form-select w-75 ms-auto"
                                    [(ngModel)]="strTipoAnexo">
                                    <option value="Contrato">Contrato</option>
                                    <option value="Cotización">Cotización</option>
                                    <option value="evidencia">Evidencia foto</option>
                                    <option value="Servicios especiales">Servicios especiales</option>
                                    <option value="Cotizacion Rapida">Cotizacion Rapida</option>
                                    <option value="Cotizacion por TYM">Cotizacion por TYM</option>
                                    <option value="Licitaciones">Licitaciones</option>
                                    <option value="InfoCotizacion">Información para cotizar</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-4 d-block text-center"
                                *ngFor="let item of arrayAnexosUpdate; let i = index">
                                <div *ngIf="item.strTipo == 'xlsx'">
                                    <a>
                                        <svg class="svg_excel" xmlns="http://www.w3.org/2000/svg" height="1em"
                                            viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                            <path
                                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                        </svg>
                                    </a>
                                    <br>
                                    <label class="text-sm">{{item.strNombre}}</label>
                                </div>
                                <div *ngIf="item.strTipo == 'pdf'">
                                    <a>
                                        <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                            viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <g>
                                                    <path
                                                        d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                        fill="#f9f8f9" />
                                                    <path
                                                        d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                        fill="#e3e0e4" />
                                                    <path
                                                        d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                        fill="#e3e0e4" />
                                                    <path
                                                        d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                        fill="#dc4955" />
                                                    <path
                                                        d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                        fill="#dc4955" />
                                                    <path
                                                        d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                        fill="#c42430" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                    <path
                                                        d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                    <path
                                                        d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                    <path
                                                        d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                    <br>
                                    <label class="text-sm">{{item.strNombre}}</label>
                                </div>
                                <div
                                    *ngIf="item.strTipo == 'jpg' || item.strTipo == 'png' || item.strTipo == 'jpeg' || item.strTipo == 'jpg'">
                                    <a>
                                        <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                            viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <g>
                                                    <path
                                                        d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                        fill="#f9f8f9" />
                                                    <path
                                                        d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                        fill="#e3e0e4" />
                                                    <path
                                                        d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                        fill="#e3e0e4" />
                                                    <path
                                                        d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                        fill="#a29aa5" />
                                                    <path
                                                        d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                                                        fill="#9ec8fc" />
                                                    <path
                                                        d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                                                        fill="#62a4fb" />
                                                    <path
                                                        d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                        fill="#9ec8fc" />
                                                    <path
                                                        d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                                                        fill="#b9a1d3" />
                                                    <path
                                                        d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                                                        fill="#9573bb" />
                                                    <path
                                                        d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                        fill="#b9a1d3" />
                                                    <circle cx="219.587" cy="285.995" fill="#faf063" r="21.578" />
                                                </g>
                                                <g>
                                                    <path
                                                        d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                                                    <path
                                                        d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                                                    <path
                                                        d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                    <br>
                                    <label>{{item.strNombre}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12" *ngIf="arrayAnexos.length==0">
                                <div class="card">
                                    <div class="card-body d-flex">
                                        <span class="m-auto text-bold">
                                            Sin Anexos
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-accordion class="col-12">
                                <mat-expansion-panel (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false"
                                    *ngFor="let item of arrayAnexos; let i = index" class="m-1" id="bordeCard">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="d-flex">
                                            <i class="fas fa-trash-alt mx-3 text-danger text-md"
                                                (click)="fnBorrarAnexo(item.intIdAnexos)"></i>
                                            {{item.strUrl.split('/')[1]}}

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-12" *ngIf="item.strTipo != 'pdf'">
                                                <img src="https://ecodeli-industrial.com/PaginaIndustrial/{{item.strUrl}}"
                                                    alt="" class="img-fluid">
                                            </div>
                                            <div class="col-12 d-flex" *ngIf="item.strTipo == 'pdf'">
                                                
                                                <a href="https://ecodeli-industrial.com/PaginaIndustrial/{{item.strUrl}}"
                                                    class="m-auto" target="_blank">
                                                    <svg id="Capa_1" enable-background="new 0 0 512 512"
                                                        height="512" viewBox="0 0 512 512"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                    fill="#f9f8f9" />
                                                                <path
                                                                    d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                    fill="#dc4955" />
                                                                <path
                                                                    d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                    fill="#dc4955" />
                                                                <path
                                                                    d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                    fill="#c42430" />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                <path
                                                                    d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                <path
                                                                    d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                <path
                                                                    d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>

                                            </div>
                                            <div class="col-12 d-flex" *ngIf="item.strTipo == 'xlsx'">
                                                <a href="https://ecodeli-industrial.com/PaginaIndustrial/{{item.strUrl}}"
                                                    class="m-auto" download>
                                                    <svg class="svg_excel" xmlns="http://www.w3.org/2000/svg"
                                                        height="1em"
                                                        viewBox="0 0 384 512">
                                                        <path
                                                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                    </svg>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="modal-footer d-flex img-fondo">
            <button id="boton" type="button" class="btn btn-secondary d-none" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-info text-white ms-auto mx-3" (click)="fnUpdateLead_Nuevo()"
                >Guardar</button>
        </div>
    </div>
</div>
</div>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <!-- div class="modal-header d-flex img-fondo">
        <div class="container-fluid">
          <div class="row">
            <div class="col-11 d-flex">
              <h5 class=" m-auto">NUEVO LEAD</h5>
            </div>
            <div class="col-1">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <div class="modal-header py-3 img-fondo d-flex align-items-center">
        <h3 class="modal-title pb-0 mb-0" id="exampleModalLabel">NUEVO LEAD</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body color-body-modal">
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-book-reader mx-1"></i> <span>LEAD</span>
            </ng-template>
            <div class="container-fluid">
              <div class="row mt-3">
                <div class="col-4 d-block ">
                  <span class="text-sm">Nombre Empresa <span class="text-danger">*</span></span>
                  <input type="text" placeholder="Ingresa Nombre empresa" class="form-control"
                    [(ngModel)]="strNombreEmpresa">
                </div>
                <div class="col-4 d-block">
                  <span class="text-sm">Etapa</span>
                  <select name="etapa" id="etapa" class="form-select" [(ngModel)]="strEtapa"
                    > <!--(change)="cambiarFase($event)"-->
                    <option value="Por Contactar">Por contactar</option>
                    <option value="Presentación">Presentación</option>
                    <option value="Recorrido">Recorrido</option>
                    <option value="Propuesta Economica">Propuesta Economica</option>
                    <option value="Negociación">Negociación</option>
                    <option value="Cierre">Cierre</option>
                  </select>
                </div>
                <div class="col-4 d-block">
                  <span class="text-sm">Fase</span>
                  <select name="fase" id="fase" class="form-select" [(ngModel)]="strFase" [disabled]="banderaFase">
                    <option value="LEAD" *ngIf="banderaFase">LEAD</option>
                    <option value="PROSPECTO">PROSPECTO</option>
                    <option value="CLIENTE" *ngIf="banderaCierre">CLIENTE</option>
                  </select>
                </div>
                <div class="col-4 d-block p-2">
                  <span class="text-sm m-auto">Sector : <span class="text-danger">*</span></span>
                  <select name="sector" id="sector" class="form-select" [(ngModel)]="strSector">
                    <option selected value="">-Selecciona una opcion-</option>
                    <option value="Publico">Publico</option>
                    <option value="Privado">Privado</option>
                  </select>
                </div>
                <div class="col-4 d-block p-2">
                  <span class="text-sm m-auto">Giro: <span class="text-danger">*</span></span>
                  <select name="giro" id="giro" class="form-select" [(ngModel)]="intGiro">
                    <option value="">-Selecciona una opcion-</option>
                    <option *ngFor="let item of arrayGiros; let i = index" value="{{item.intID_Giro}}">
                      {{item.strNombreGiro}}</option>

                  </select>
                </div>
                <div class="col-4 d-block p-2">
                  <span class=" m-auto text-sm">Familia</span>
                  <select name="familia" id="familia" class="form-select" [(ngModel)]="strFamilia">
                    <option value="">--Selecciona una opción</option>
                    <option *ngFor="let item of arrayLisFamilia; let i = index" value="{{item.strTipo}}">
                      {{item.strTipo}}</option>
                  </select>
                </div>

                <div class="col-4 d-block p-2">
                  <span class="text-sm m-auto">Rango Empleados:</span>
                  <select name="probabilidad" id="probabilidad" class="form-select" [(ngModel)]="strNumeroEmpleados"
                    > <!--(change)="cambiarPrioridad($event)"-->
                    <option value="">-Selecciona una opcion-</option>
                    <option value="{{item.strRangoEmpleados}}" *ngFor="let item of arrayTamanoEmpresa; let i = index">
                      {{item.strRangoEmpleados}}</option>
                  </select>
                </div>
                <div class="col-4 d-block p-2">
                  <span class="text-sm">Importe</span>
                  <input type="text" placeholder="Ingresa Importe" class="form-control" [(ngModel)]="dblImporte">
                </div>
                <div class="col-4 d-block p-2">
                  <span class="text-sm m-auto"># Elementos:</span>
                  <input type="number" min="0" value="0" class="form-control" [(ngModel)]="intCantidadElementos">
                </div>
                <div class="col-4 p-2">
                  <span class="text-sm">Prioridad</span>
                  <select name="probabilidad" id="probabilidad" class="form-select" [(ngModel)]="strPrioridad"
                    disabled="true">
                    <option value="">-Selecciona una opcion-</option>
                    <option value="Alta">Alta</option>
                    <option value="Media">Media</option>
                    <option value="Baja">Baja</option>
                    <option value="URGENTE">URGENTE</option>
                  </select>
                </div>
                <div class="col-4 d-block p-2">
                  <span class="text-sm">Fecha Estimada Cierre</span>
                  <input value="{{strFechaEstimadaCierreInput}}" type="date" class="form-control"
                     min="{{minDate}}"> <!--(change)="cambiarFecha($event)"-->
                </div>
                <div class="col-4 d-block p-2">
                  <span class="m-auto text-sm">Total Sucursales:</span>
                  <input type="text" placeholder="Total Sucursales" class="form-control ml-auto"
                    [(ngModel)]="intTotalSucursales">
                </div>
                <div class="col-4 d-block p-1">
                  <span class="datosEmpresa m-auto">Razon Social:</span> <br>
                  <input type="text" placeholder="Razon Social" class="form-control  m-auto"
                    [(ngModel)]="strRazonSocial">
                </div>
                <div class="col-4 d-block p-1">
                  <span class="datosEmpresa m-auto">Sitio Web:</span>
                  <input type="text" placeholder="Sitio Web" class="form-control ml-auto" [(ngModel)]="strSitioWeb">
                </div>
                <div class="col-4 d-block">
                  <span class="datosEmpresa m-auto">RFC:</span>
                  <input type="text" placeholder="RFC" class="form-control  ml-auto" [(ngModel)]="strRFC">
                </div>
                <div class="col-4 d-block">
                  <span class="datosEmpresa m-auto">Tipo Cotización:</span>
                  <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                    [(ngModel)]="strTipoCotizacion">
                    <option value="">--Selecciona una opción--</option>
                    <option value="Servicios especiales">Servicios especiales</option>
                    <option value="Cotizacion Rapida">Cotizacion Rapida</option>
                    <option value="Cotizacion por TYM">Cotizacion por TYM</option>
                    <option value="Licitaciones">Licitaciones</option>
                    <option value="Estudio de Mercado">Estudio de Mercado</option>
                  </select>
                </div>
                <div class="col-4 d-block">
                  <span class=" m-auto text-sm">¿Por cual medio nos conocio?</span>
                  <select name="etiqueta" id="etiqueta" class="form-select" [(ngModel)]="strMedioConocio">
                    <option value="" selected>-Selecciona una opcion-</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Likedin">Likedin</option>
                    <option value="Conocidos">Conocidos</option>
                  </select>
                </div>
                <div class="col-4 d-block">
                  <span class=" m-auto text-sm">¿Recomendado por?</span>
                  <input type="text" placeholder="Recomendado por" class="form-control" [(ngModel)]="strRecomendacion">
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-id-card-alt mx-1"></i> <span>DATOS CONTACTO</span>
            </ng-template>
            <div class="container-fluid">
              <div class="col-12 d-flex mt-2">
                <button class="btn btn-info ml-auto text-white" > <!--(click)="fnAgregarContacto()"--><i
                    class="fas fa-plus-circle mx-1"></i>Agregar</button>
              </div>
              <div class="row mt-3 card-contacto p-3" *ngFor="let item of arrayContacto; let i = index">
                <div class="col-12 d-flex mt-2" *ngIf="arrayContacto.length > 1">
                  <button class="btn btn-danger ml-auto btn-sm" ><!--(click)="fnEliminarContacto(i)"--><i
                      class="fas fa-trash-alt"></i></button>
                </div>
                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">Nombre:</span>
                  <input type="text" placeholder="Nombre Contacto" class="form-control ml-auto"
                    [(ngModel)]="item.strNombreContacto"  name="{{i}}"> <!--(input)="validarCampos(item)"-->
                </div>

                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">Puesto:</span> <br>
                  <input type="text" placeholder="Puesto Contacto" [(ngModel)]="item.strContactoPuesto"
                    class="form-control m-auto"   name="{{i}}"> <!--(input)="validarCampos(item)"-->
                </div>

                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">Teléfono:</span>
                  <input type="text" placeholder="Teléfono Contacto" class="form-control ml-auto"
                    [(ngModel)]="item.strContactoTelefono"   name="{{i}}"><!--(input)="validarCampos(item)"-->
                  <hr>
                </div>
                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">E-mail:</span> <br>
                  <input type="text" placeholder="Email Contacto" class="form-control  m-auto"
                    [(ngModel)]="item.strContactoEmail" [ngClass]="{'campo-obligatorio': item.emailEsObligatorio}"
                     name="{{i}}"> <!--(input)="validarEmailContacto(item)"-->
                  <div *ngIf="item.emailEsObligatorio" class="error-message text-sm text-danger">
                    El correo electrónico es obligatorio si se ha ingresado nombre, puesto o teléfono.
                  </div>
                  <hr>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="DATOS FISCALES">
            <ng-template mat-tab-label>
              <i class="fas fa-route mx-1"></i> <span>DATOS FISCALES</span>
            </ng-template>
            <div class="container-fluid">
              <div class="row">
                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">Codigo Postal:</span> <br>
                  <input type="text" placeholder="Codigo postal" class="form-control m-auto"
                    [(ngModel)]="strCodigoPostal" > <!--(keyup.enter)="fnGetDatosFiscales()"-->
                </div>
                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">Calle <span class="text-danger">*</span></span> <br>
                  <input type="text" placeholder="Calle" class="form-control  m-auto" [(ngModel)]="strCalle">
                </div>
                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">Estado <span class="text-danger">*</span></span> <br>
                  <input readonly type="text" placeholder="Estado" class="form-control  m-auto" [(ngModel)]="strEstado">
                </div>
                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">No. Exterior <span class="text-danger">*</span></span> <br>
                  <input type="text" placeholder="No. Exterior " class="form-control  m-auto"
                    [(ngModel)]="strNumeroExterior">
                </div>
                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">Delegación <span class="text-danger">*</span></span> <br>
                  <input readonly [(ngModel)]="strDelegacion" type="text" placeholder="Delegación"
                    class="form-control  m-auto">
                </div>
                <div class="col-6 d-block p-1">
                  <span class="datosEmpresa m-auto">Entre Calles <span class="text-danger">*</span></span> <br>
                  <input [(ngModel)]="strEntreCalles" type="text" placeholder="Entre Calles"
                    class="form-control  m-auto">
                </div>
                <div class="col-6 d-block p-2">
                  <span class="datosEmpresa m-auto">Colonia:</span>
                  <select name="probabilidad" id="probabilidad" class="form-select" [(ngModel)]="strColonia" > <!--
                    (change)="cambiarBanderaColonia($event)"-->
                    <option *ngFor="let item of arrayColonias; let i = index">{{item.strColonia}}</option>
                    <option value="otraColonia">Otra</option>
                  </select>
                </div>
                <div class="col-6 ">
                  <div class="row">
                    <div class="col-12">
                      <span class="datosEmpresa m-auto">Telefono <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-3 p-1">
                      <input type="text" placeholder="Lada" class="form-control" > <!--[(ngModel)]="strLadaEmpresa"-->
                    </div>
                    <div class="col-9 p-1">
                      <input type="text" placeholder="Telefono" class="form-control"> <!-- [(ngModel)]="strTelefonoEmpresa"-->
                    </div>
                  </div>
                </div>
                <div class="col-6" > <!--*ngIf="banderaColonia"-->
                  <span class="datosEmpresa m-auto">Escriba Colonia <span class="text-danger">*</span></span> <br>
                  <input type="text" placeholder="Otra colonia" class="form-control  m-auto"
                    > <!--[(ngModel)]="strOtraColonia"-->
                </div>
                <div class="col-6 d-block p-2">
                  <span class="datosEmpresa m-auto">Zona:</span>
                  <select name="zona" id="zona" class="form-select" > <!--[(ngModel)]="strZona"-->
                    <option *ngFor="let item of arrayZonas; let i = index" value="{{item.strZona}}">{{item.strZona}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-paperclip mx-1"></i> <span>ANEXOS</span>
            </ng-template>
            <div class="container-fluid">
              <div class="row mt-3">
                <div class="col-6">
                  <label class="btn btn-secondary btn-file text-sm ml-auto ">
                    <i class="fas fa-plus"></i> Agregar Anexo <input  #file type="file"
                      style="display: none;" required accept=".xlsx, .pdf, .png, .jpg, .jpeg"> <!--(change)="agregarAnexo($event)"-->
                  </label>
                </div>
                <div class="col-6 d-flex">
                  <select name="tipo" id="tipo" class="form-select w-75 ml-auto" [(ngModel)]="strTipoAnexo"
                    > <!--(change)="cambiarEstatusAnexo($event)"-->
                    <option value="Contrato">Contrato</option>
                    <option value="Cotización">Cotización</option>
                    <option value="Evidencia">Evidencia foto</option>
                    <option value="Servicios especiales">Servicios especiales</option>
                    <option value="Cotizacion Rapida">Cotizacion Rapida</option>
                    <option value="Cotizacion por TYM">Cotizacion por TYM</option>
                    <option value="Licitaciones">Licitaciones</option>
                    <option value="InfoCotizacion">Información para cotizar</option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4 d-block text-center" *ngFor="let item of arrayAnexos; let i = index">
                  <div *ngIf="item.strTipo == 'xlsx'">
                    <a>
                      <svg class="svg-excel" xmlns="http://www.w3.org/2000/svg" height="1em"
                        viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                      </svg>
                    </a>
                    <br>
                    <label class="text-sm">{{item.strNombre}}</label>
                  </div>
                  <div *ngIf="item.strTipo == 'pdf'">
                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <g>
                          <path
                            d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                            fill="#f9f8f9" />
                          <path
                            d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                            fill="#e3e0e4" />
                          <path
                            d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                            fill="#e3e0e4" />
                          <path
                            d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                            fill="#dc4955" />
                          <path
                            d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                            fill="#dc4955" />
                          <path
                            d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                            fill="#c42430" />
                        </g>
                        <g>
                          <path
                            d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                          <path
                            d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                          <path
                            d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                          <path
                            d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                        </g>
                      </g>
                    </svg> <br>
                    <label class="text-sm">{{item.strNombre}}</label>
                  </div>
                  <div
                    *ngIf="item.strTipo == 'jpg' || item.strTipo == 'png' || item.strTipo == 'jpeg' || item.strTipo == 'jpg'">
                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"
                      xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <g>
                          <path
                            d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                            fill="#f9f8f9" />
                          <path
                            d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                            fill="#e3e0e4" />
                          <path
                            d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                            fill="#e3e0e4" />
                          <path
                            d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                            fill="#a29aa5" />
                          <path
                            d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                            fill="#9ec8fc" />
                          <path
                            d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                            fill="#62a4fb" />
                          <path d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z" fill="#9ec8fc" />
                          <path
                            d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                            fill="#b9a1d3" />
                          <path d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                            fill="#9573bb" />
                          <path d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z" fill="#b9a1d3" />
                          <circle cx="219.587" cy="285.995" fill="#faf063" r="21.578" />
                        </g>
                        <g>
                          <path
                            d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                          <path
                            d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                          <path
                            d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                        </g>
                      </g>
                    </svg><br>
                    <label>{{item.strNombre}}</label>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="modal-footer img-fondo d-flex">
        <button type="button" class="btn btn-info ml-auto text-white" 
          data-bs-dismiss="modal" >Guardar</button> <!-- [disabled]="emailEsObligatorio" npm[disabled]="banderaContacto" (click)="SubirAnexos()"-->
      </div>
    </div>
  </div>
</div>

</div>



