<!--<app-menu></app-menu>-->

<div class="container">
    <br><br>
    <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb fs15">
            <li class="breadcrumb-item"><a [routerLink]="['/retoTork']" class="texto-gris">Listado de Retos</a></li>
            <li class="breadcrumb-item">/</li>
            <li class="breadcrumb-item active" aria-current="page">Seguimiento de Reto Tork</li>
        </ol>
    </nav> 

    <div class="row section_fondo">
        <div class="cien">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <h1><span class="icon-ini"><i class="fa-solid fa-circle-check"></i></span> Seguimiento de Reto Tork</h1>
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                    <div class="col-12">
                        <div class="informacion_seguimiento row">
                            <div class="col-12">
                                <p class="txt-color-gris espacio">DATOS DEL CLIENTE</p>
                            </div>
                            <div class="seguimiento_int col-lg-6 col-md-6 col-12">
                                <p><span><i class="fa-solid fa-user"></i>&nbsp;&nbsp;{{strTipo}}:</span> <b> {{strCliente}} - {{strNombre}}</b></p>
                                <p><span><i class="fa-solid fa-building"></i>&nbsp;&nbsp;Giro:</span> <b> {{strGiro}}</b></p>
                                <p><span><i class="fa-solid fa-calendar"></i>&nbsp;&nbsp;Fecha Inicial:</span> <b> {{strFechaInicial}}</b></p>
                            </div>
                            <div class="seguimiento_int col-lg-6 col-md-6 col-12">
                                <p><span><i class="fa-solid fa-user-check"></i>&nbsp;&nbsp;Contacto:</span> <b> {{strContacto}}</b></p>
                                <p><span><i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Teléfono:</span> <b> {{strTelefono}}</b></p>
                                <p><span><i class="fa-solid fa-briefcase"></i>&nbsp;&nbsp;Puesto:</span> <b> {{strPuesto}}</b></p>
                            </div>
                            <div class="seguimiento_int col-lg-12 col-md-12 col-12">
                                <p><span><i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Dirección:</span> <b> {{strDireccion}}</b></p>
                                <p><span><i class="fa-solid fa-comment"></i>&nbsp;&nbsp;Comentarios:</span> <b> {{strComentarios}}</b></p>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="identificador shadow" [ngClass]="{'ide-info': strEstatusSeguimiento=='PENDIENTE', 
                        'ide-success': strEstatusSeguimiento=='CONCLUIDO', 
                        'ide-warning': strEstatusSeguimiento=='NO EXISTE', 
                        'ide-danger': strEstatusSeguimiento=='RECHAZADO', 
                        'ide-aceptado': strEstatusSeguimiento=='ACEPTADO'}">
                            <div class="row espacioBox">
                                <div class="col-12">
                                    <p class="txt-color-gris espacio">SEGUIMIENTO</p>
                                </div>
                                <div class="col-5">
                                    <h1 [ngClass]="{'text-info': strEstatusSeguimiento=='PENDIENTE', 
                                    'text-success': strEstatusSeguimiento=='CONCLUIDO', 
                                    'text-warning': strEstatusSeguimiento=='NO EXISTE', 
                                    'text-danger': strEstatusSeguimiento=='RECHAZADO', 
                                    'text-aceptado': strEstatusSeguimiento=='ACEPTADO'}">ID</h1>
                                </div>
                                <div class="col-7">
                                    <b *ngIf="intIDReto>0" class="textoG">{{intIDReto}}</b>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="identificador shadow" [ngClass]="{'ide-info': strEstatusSeguimiento=='PENDIENTE', 
                        'ide-success': strEstatusSeguimiento=='CONCLUIDO', 
                        'ide-warning': strEstatusSeguimiento=='NO EXISTE', 
                        'ide-danger': strEstatusSeguimiento=='RECHAZADO', 
                        'ide-aceptado': strEstatusSeguimiento=='ACEPTADO'}">
                            <div class="row espacioBox">
                                <div class="col-12">
                                    <p class="txt-color-gris espacio">ESTATUS SEGUIMIENTO DE VISITAS</p>
                                </div>
                                <div class="col-2">
                                    <h1 class="txt-color-gris"><i class="fa-solid fa-handshake"></i></h1>
                                </div>
                                <div class="col-10">
                                    <h1 class="textoM" [ngClass]="{'text-info': strEstatusSeguimiento=='PENDIENTE', 
                                    'text-success': strEstatusSeguimiento=='CONCLUIDO', 
                                    'text-warning': strEstatusSeguimiento=='NO EXISTE', 
                                    'text-danger': strEstatusSeguimiento=='RECHAZADO', 
                                    'text-aceptado': strEstatusSeguimiento=='ACEPTADO'}">{{strEstatusSeguimiento}}
                                </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  class="col-12">
                        <p *ngIf="strMotivoRechazoReto!='' && strEstatusSeguimiento=='RECHAZADO'" class="p_rojo"><b>EL CLIENTE NO ACEPTO EL RETO</b> {{strMotivoRechazoReto}}</p>
                    </div>

                </div>
                
            </div>            
        </div>


       
        <div class="cien">
        <!-------------------TABLAS PDF------------------------------------> 
           <div class="cien"   id="pdfTable" #pdfTable  style="display: none;"><br>
                    <div class="informacion_seguimiento">                        
                            <label style="font-size: 14px;">{{strTipo}}: <b>{{strCliente}} - {{strNombre}}</b></label><br>
                            <label style="font-size: 14px;">Fecha Inicial: <b>{{strFechaInicial}}</b></label><br>
                            <label style="font-size: 14px;">Contacto: <b>{{strContacto}}</b></label><br>
                            <label style="font-size: 14px;margin-left: 20px;">Teléfono: <b>{{strTelefono}}</b></label> <br>
                       
                            <label style="font-size: 14px;margin-left: 20px;">Puesto: <b>{{strPuesto}}</b></label> <br>
                            <label style="font-size: 14px;margin-left: 20px;">Giro: <b>{{strGiro}}</b></label><br>
                            <label style="font-size: 14px;">Dirección: <b>{{strDireccion}}</b></label><br>
                         
                    </div>                
                    <hr> 
                <table class="table table-hover">
                    <thead style="background-color: #00386C;color: white;">

                        <th style="font-size:12px;background-color: #00386C;color: white;">Categoria</th>
                        <th style="font-size:12px;background-color: #00386C;color: white;">Tipo</th>
                        <th style="font-size:12px;background-color: #00386C;color: white;">Despachador</th>
                        <th style="font-size:12px;background-color: #00386C;color: white;">Consumible</th>
                        <th style="font-size:12px;background-color: #00386C;color: white;">Cantidad Consumida</th>
                        <th style="font-size:12px;background-color: #00386C;color: white;">Precio Unitario</th>
                        <th style="font-size:12px;background-color: #00386C;color: white;">Total</th>
                        <th style="font-size:12px;background-color: #00386C;color: white;">Resultado</th>
                      
                    </thead>
                    <tbody class="tLeft" style="background-color: white;">
                        <tr *ngFor="let I of resultCategoria">
                            <td style="font-size:9px"><br>{{I.strCategoria}}</td>
                            <td style="font-size:9px"><span *ngFor="let X of I.arrayConsumibles">{{X.strTipo}}<br><br></span>
                            </td>
                            <td style="font-size:9px"><span *ngFor="let X of I.arrayConsumibles">(<b>{{X.strClaveDespachador}}</b>) {{X.strNombreDespachador}}<br><br></span>
                            </td>
                            <td style="font-size:9px">
                                <span *ngFor="let X of I.arrayConsumibles">(<b>{{X.strClaveConsumible}}</b>) {{X.strNombreConsumible}}<br><br></span>
                            </td>
                            <td style="font-size:12px; text-align: center;"><span *ngFor="let X of I.arrayConsumibles"> <b>{{X.dblValor}}</b><br><br></span>
                            </td>
                            <td style="font-size:12px; text-align: right;"> <span *ngFor="let X of I.arrayConsumibles">
                                 {{X.PrecioSeleccionado|currency}} 
                                <br><br></span>
                            </td>
                            <td style="font-size:14px;"> <span *ngFor="let X of I.arrayConsumibles">{{X.dblTotal|currency}}<br><br></span></td>
                            <td  style="color: #275677;font-weight: 900;font-size:16px;
                            text-align: center;"><br>{{I.dblResult|percent}}</td>
                        </tr>

                    </tbody>
                </table>
            </div> 
            <!---------------------------------SEGUNDA TABLA PDF----------------------------------------->
            <div class="cien"   id="pdfTable2" #pdfTable2    style="display: none;"><br>
                <div class="informacion_seguimiento">                        
                        <label style="font-size: 14px;">{{strTipo}}: <b>{{strCliente}} - {{strNombre}}</b></label><br>
                        <label style="font-size: 14px;">Fecha Inicial: <b>{{strFechaInicial}}</b></label><br>
                        <label style="font-size: 14px;">Contacto: <b>{{strContacto}}</b></label><br>
                        <label style="font-size: 14px;margin-left: 20px;">Teléfono: <b>{{strTelefono}}</b></label> <br>
                   
                        <label style="font-size: 14px;margin-left: 20px;">Puesto: <b>{{strPuesto}}</b></label> <br>
                        <label style="font-size: 14px;margin-left: 20px;">Giro: <b>{{strGiro}}</b></label><br>
                        <label style="font-size: 14px;">Dirección: <b>{{strDireccion}}</b></label><br>
                     
                </div>                
                <hr> <hr> 
                
                <div *ngFor="let pCat of resultCategoria">
                    <table class="table table-hover">
                        <thead style="background-color: #00386C;color: white;">
                            <thead class="header_tablaR" *ngIf="pCat.strCategoria != 'DESPACHADOR PARA JABON'">

                                <th style="font-size:12px;background-color: #00386C;color: white;">Categoria</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Tipo</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Despachador</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Consumible</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Hoja x rollo</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Cm x hoja</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Cm x rollo</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Consumo</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Sobrantes</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Metros Totales</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Piezas</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Precio Por Caja</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Precio Por CM</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Total</th> 
                                <th style="font-size:12px;background-color: #00386C;color: white;">Resultado</th> 
                                
                            
                            </thead>
                            <thead class="header_tablaR" *ngIf="pCat.strCategoria == 'DESPACHADOR PARA JABON'">
    
                                <th style="font-size:12px;background-color: #00386C;color: white;">Categoria</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Tipo</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Despachador</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Consumible</th>
                               
                                <th style="font-size:12px;background-color: #00386C;color: white;">Ml x Cartucho</th>
                               <th style="font-size:12px;background-color: #00386C;color: white;">Consumo</th>
                                 <th style="font-size:12px;background-color: #00386C;color: white;">Sobrantes</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Ml Consumidos</th>
                                 <th style="font-size:12px;background-color: #00386C;color: white;">Piezas</th>
                                <th  style="font-size:12px;background-color: #00386C;color: white;">Precio Por Caja</th>
                               <th style="font-size:12px;background-color: #00386C;color: white;">Precio x ml</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Total</th>
                                <th style="font-size:12px;background-color: #00386C;color: white;">Resultado</th> 
                                
                            
                            </thead>
                            
                        </thead>
                        <tbody class="tLeft" style="background-color: white;">
                            <tr *ngFor="let I of resultCategoria | filterCategoria:pCat.strCategoria">

                                <td style="font-size:9px"> <br>{{I.strCategoria}}</td>
                                <td style="font-size:9px"> <span *ngFor="let X of I.arrayConsumibles"  > {{X.strTipo}} <br><br> </span>  </td>
                                <td style="font-size:9px"> <span *ngFor="let X of I.arrayConsumibles">(<b>{{X.strClaveDespachador}}</b>) {{X.strNombreDespachador}}<br><br></span></td>
                                <td style="font-size:9px"> <span *ngFor="let X of I.arrayConsumibles">(<b>{{X.strClaveConsumible}}</b>) {{X.strNombreConsumible}}<br><br> </span>
                                </td>
                                <td style="font-size:9px" *ngIf="pCat.strCategoria != 'DESPACHADOR PARA JABON'">  
                                    <span *ngFor="let X of I.arrayConsumibles">    {{X.hojaxrollo}} <br><br>  </span>
                                </td>
                                <td style="font-size:9px" *ngIf="pCat.strCategoria != 'DESPACHADOR PARA JABON'">  
                                    
                                    <span *ngFor="let X of I.arrayConsumibles"> {{X.cmxhoja}} <br><br> 
                                    </span>
                                </td>
                                 <!--cmxrollo-->
                                 <td style="font-size:9px"> 
                                    <span  *ngFor="let X of I.arrayConsumibles" >
                                       
                                        <label  *ngIf="pCat.strCategoria != 'DESPACHADOR PARA JABON'">{{X.cmxrollo}}</label>
                                        <label  *ngIf="pCat.strCategoria == 'DESPACHADOR PARA JABON'" >{{X.mlxcartucho}}</label>
                                        <br><br> 
                                    </span>
                                </td>                                         
                                     <!--consumo-->
                                <td style="font-size:9px"> 
                                    <span *ngFor="let X of I.arrayConsumibles"> 
                                        <b [ngClass]="{'azul-T' : X.strTipo=='TORK'}">{{X.dblValor}}</b>
                                        <br><br> 
                                       
                                    </span>
                                </td>
                                   <!--sobrantes-->
                                <td  style="font-size:9px">  
                                    <span *ngFor="let X of I.arrayConsumibles">
                                        {{X.sobrantes}}
                                        <br><br>                               
                                    </span>
                                </td>
                                 <!--cm totales-->
                                 <td  style="font-size:9px">  
                                    <span *ngFor="let X of I.arrayConsumibles">
                                        {{X.mtstotales}}    
                                        <br><br>                                
                                     </span>
                                </td>
                                  <!--Piezas-->
                                <td  style="font-size:9px">   
                                    <span *ngFor="let X of I.arrayConsumibles">
                                        {{X.intPiezas}}    <br><br>                              
                                    </span>
                                </td>  
                                 <!--precio-->
                                <td  style="font-size:9px">  
                                <span *ngFor="let X of I.arrayConsumibles">
                                        {{X.PrecioSeleccionadoCJ|currency}}  <br><br> 
                                </span>
                            </td>
                            <td  style="font-size:9px"> 
                                <span *ngFor="let X of I.arrayConsumibles">
                                    <label>{{X.precioxcm|number:'1.2-2'}}</label>
                                    <br><br>                                
                                </span>
                                
                            </td>
                            <td  style="font-size:9px"> 
                                <span *ngFor="let X of I.arrayConsumibles">
                                    <label>{{X.result2|currency}}</label>    <br><br>                             
                                </span>
                            </td>
                            <td  style="color: #275677;font-weight: 900;font-size:16px;
                            text-align: center;"> <br>
                                 {{I.dblResult2|percent}}   
                            </td>
                            </tr>

                        </tbody>
                    </table>
            </div> 
        </div> 

      <!-----------------FIN DE --TABLAS PDF------------------------------------>
            
            <div class="espacioG"></div>

          


            <div class="row" *ngIf="resultCategoria.length>0">
               

                <div class="col-lg-6 col-md-6 col-8">
                    <h1><span class="icon-ini"><i class="fa-solid fa-clipboard-list"></i></span> Resumen de visitas
                
                    </h1>    
                </div>
                

            </div>
          
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Resultado en Precios</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Resultado en Consumo</button>
                </li>
                
              </ul>
              <div class="tab-content" id="myTabContent">
               

                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="col-12 d-flex justify-content-end ">
                        <button type="button" class="btn downloadpdf m-2" title="Descargar Resumen de visitas" (click)="fnPdf()"><i class="fa-solid fa-file-pdf"></i> Descargar</button>
                        
                    </div>
                    <table class="table table-hover">
                        <thead class="header_tablaR">
    
    
                            <th>Categoria</th>
                            <th>Tipo</th>
                            <th>Despachador</th>
                            <th>Consumible</th>
                            <th>Cantidad Consumida</th>
                            <th>Precio Unitario</th>
                            <th>Total</th>
                            <th>Resultado</th>
                           
                        </thead>
                        <tbody class="tLeft" style="background-color: white;">
                            <tr *ngFor="let I of resultCategoria">
    
                                <td class="txt-M azul"><div class="espacioG"></div>{{I.strCategoria}}</td>
                                <td class="txt-T"><div class="espacioC"></div><span *ngFor="let X of I.arrayConsumibles" [ngClass]="{'azul-T' : X.strTipo=='TORK'}">{{X.strTipo}} <div class="espacioG"></div> </span>
    
                                </td>
                                <td class="txt-M"><div class="espacioC"></div><span *ngFor="let X of I.arrayConsumibles">(<b>{{X.strClaveDespachador}}</b>) {{X.strNombreDespachador}}<div class="espacioG"></div></span>
    
                                </td>
                                <td class="txt-M"><div class="espacioC"></div>
                                    <span *ngFor="let X of I.arrayConsumibles">(<b>{{X.strClaveConsumible}}</b>) {{X.strNombreConsumible}}<div class="espacioG"></div></span>
                                </td>
                                <td class="font11 cantidadC"><div class="espacioC"></div><span *ngFor="let X of I.arrayConsumibles"> <b [ngClass]="{'azul-T' : X.strTipo=='TORK'}">{{X.dblValor}}</b><div class="espacioG"></div></span>
                                </td>
                           
                                <td class="font11 w-A"> <div class="espacioC"></div>
                                    <span *ngFor="let X of I.arrayConsumibles">
                                        <select *ngIf="X.arrayPrecios.length>1"class="form-select font12" aria-label="Default select example" (change)="getCalculo(X,I)" [(ngModel)]="X.PrecioSeleccionado">
                                            <option [value]="precio.dblPrecioLista" *ngFor="let precio of X.arrayPrecios|filterprecio:'U'">{{precio.dblPrecioLista|currency}}
                                            </option>
                                        </select>
                                        
                                        <div class="input-group input-group-sm mb-3" *ngIf="X.arrayPrecios.length!=1">
                                            <span class="input-group-text" id="inputGroup-sizing-sm">Otro Precio</span>
                                            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" (change)="getCalculo(X,I)" [(ngModel)]="X.PrecioSeleccionado">
                                        </div>
                                
                                        <span class="txt-Total" *ngIf="X.arrayPrecios.length==1"  >{{X.dblCostoPromedio|currency}}</span>
                                        <div class="espacioG"></div>
                                    </span>
                                </td>
                                <td class="txt-Total"> <div class="espacioC"></div><span *ngFor="let X of I.arrayConsumibles">{{X.dblTotal|currency}}<div class="espacioG"></div></span></td>
                                <td class="porcentaje" [ngClass]="{'done' : I.intNumVisita==1,'close' : I.intNumVisita==0}"><div class="espacioG"></div><span>{{I.dblResult|percent}}</span> </td>
                            
                            </tr>
    
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btn downloadpdf m-2" title="Descargar Resumen de visitas" (click)="fnPdf2()"><i class="fa-solid fa-file-pdf"></i> Descargar</button>
                    </div> 
                    <div *ngFor="let pCat of resultCategoria" class="table-responsive">
                    <table class="table table-hover">
                        <thead class="header_tablaR" *ngIf="pCat.strCategoria != 'DESPACHADOR PARA JABON'">
                            <tr>
                            <th class="colsticky1 thco">Categoria</th>
                            <th class="colsticky2 thco">Tipo</th>
                            <th class="colsticky3 thco">Despachador</th>
                            <th>Consumible</th>
                            <th>Hoja x rollo</th>
                            <th class="text-wrap">Cm x hoja</th>
                            <th>Cm x rollo</th>
                            <th>Cantidad Consumida</th>
                            <th>Sobrantes</th>
                            <th>Metros Totales</th>
                            <th>Piezas</th>
                            <th>Precio Por Caja</th>
                            <th>Precio Por CM</th>
                            <th>Total</th>
                            <th>Resultado</th>
                        </tr>
                        </thead>
                        <thead class="header_tablaR" *ngIf="pCat.strCategoria == 'DESPACHADOR PARA JABON'">

                            <th>Categoria</th>
                            <th>Tipo</th>
                            <th>Despachador</th>
                            <th>Consumible</th>
                            <th>ml x Cartucho</th>
                            <th>Cantidad Consumida</th>
                            <th>Sobrantes</th>
                            <th>Ml  Consumidos</th>
                            <th>Piezas</th>
                            <th>Precio Por Caja</th>
                            <th>Precio x ml</th>
                            <th>Total</th>
                            
                            
                        
                        </thead>
                        <tbody class="tLeft" style="background-color: white;">
                            <tr *ngFor="let I of resultCategoria | filterCategoria:pCat.strCategoria">
                                <td class="txt-M azul align-middle celtam0 colsticky1 " >
                                    <span class="celtam12 d-flex justify-content-center align-items-center">
                       {{I.strCategoria}}              </span> 
                                </td>

                                <td class="txt-T colsticky2" >
                                   <!--  <div class="espacioC"></div> -->
                                    <span class="celtam1 d-flex justify-content-center align-items-center" *ngFor="let X of I.arrayConsumibles" [ngClass]="{'azul-T' : X.strTipo=='TORK'}"> 
                                        {{X.strTipo}}
                                   <!--      <div class="espacioG"></div>  -->
                                    </span> 
                                </td>
                                <td class="txt-M colsticky3">
                                    <!-- <div class="espacioC"></div> -->
                                    <span class="celtam d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles"> <span class="d-flex">(<b>{{X.strClaveDespachador}}</b>)</span>{{X.strNombreDespachador}}
                                     <!--    <div class="espacioG"></div> -->
                                    </span>
                                </td>
                                
                                <td class="txt-M">
                                    <!-- <div class="espacioC"></div> -->
                                    <span class="celtam d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles"><span class="d-flex">(<b>{{X.strClaveConsumible}}</b>)</span>{{X.strNombreConsumible}}
                                       <!--  <div class="espacioG"></div> -->
                                    </span>
                                </td>
                                <td class="txt-T"  *ngIf="pCat.strCategoria != 'DESPACHADOR PARA JABON'"> <!-- <div class="espacioC"></div> -->
                                    <span class="celtam1 d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles">
                                        <input    [(ngModel)]= "X.hojaxrollo" (change)="getCalculoConsumo(X,I)" [value]="X.hojaxrollo"  class="form-control form-control-sm" type="number" placeholder="Hojas x Rollo" aria-label=".form-control-sm example">
                                      <!--   <div class="espacioG"></div> -->
                                    </span>
                                </td>
                                <td class="txt-M " *ngIf="pCat.strCategoria != 'DESPACHADOR PARA JABON'">  
                                    <!-- <div class="espacioC"></div> -->
                                    <span class="celtam2 d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles">
                                        <select class="form-select font12" aria-label="Default select example" (change)="getCalculoConsumo(X,I)" [(ngModel)]="X.cmxhoja">
                                            <option [value]="hoja.valor" *ngFor="let hoja of X.arrayCmxhoja">{{hoja.valor}}
                                            </option>
                                        </select>
                                       <!--  <div class="espacioG"></div> -->
                                    </span>
                                </td>
                                <!--cmxrollo-->
                                <td class="txt-M">  <!-- <div class="espacioC"></div> -->
                                    <span class="celtam1 d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles" >
                                       
                                        <input  *ngIf="pCat.strCategoria != 'DESPACHADOR PARA JABON'" [(ngModel)]="X.cmxrollo"  [value]= "X.hojaxrollo * X.cmxhoja" class="form-control form-control-sm" type="number" placeholder="Hojas x Rollo" aria-label=".form-control-sm example" [disabled]="true">
                                        <input  *ngIf="pCat.strCategoria == 'DESPACHADOR PARA JABON'" (change)="getCalculoConsumo(X,I)"  [(ngModel)]="X.mlxcartucho"  [value]= "X.mlxcartucho" class="form-control form-control-sm" type="number" placeholder="ml x cartucho" aria-label=".form-control-sm example"  >
                                        
                                  <!--       <div class="espacioG"></div> -->
                                    </span>
                                    
                                  
                                </td> 
                                <!--consumo-->
                                <td class="font10 cantidadC"><!-- <div class="espacioC"></div> -->
                                    <span class="celtam1 d-flex justify-content-center align-items-center flex-column col-12"  *ngFor="let X of I.arrayConsumibles"> 
                                        <b [ngClass]="{'azul-T' : X.strTipo=='TORK'}">{{X.dblValor}}</b>
                                      <!--   <div class="espacioG"></div> -->
                                    </span>
                                </td>
                                <!--sobrantes-->
                                <td class="txt-M "> <!-- <div class="espacioC"></div> -->
                                    <span class="celtam1 d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles">
                                        <input [(ngModel)]="X.sobrantes"  (change)="getCalculoConsumo(X,I)" [value]= "X.sobrantes" class="form-control form-control-sm" type="number" placeholder="sobrantes" aria-label=".form-control-sm example" >
                                       <!--  <div class="espacioG"></div>    -->                                 
                                    </span>
                                </td>
                         
                                <!--cm totales-->
                                <td class="txt-M"> <!-- <div class="espacioC"></div> -->
                                    <span class="celtam1 d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles">
                                        <input [(ngModel)]="X.mtstotales"  [value]= "X.mtstotales" class="form-control form-control-sm" type="number"   aria-label=".form-control-sm example" >
                                       <!--  <div class="espacioG"></div>     -->                                
                                     </span>
                                </td>
                                <!--Piezas-->
                                <td class="font11 w-A">  
                                    <!-- <div class="espacioC"></div> -->
                                    <span class="celtam1 d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles">
                                        <input [(ngModel)]="X.intPiezas" type="number" (change)="getCalculoConsumo(X,I)" [value]= "X.intPiezas" class="form-control form-control-sm" type="number" placeholder="sobrantes" aria-label=".form-control-sm example" >
                                        <!-- <div class="espacioG"></div> -->                                    
                                    </span>
                                </td>    
                                <!--precio-->
                              <td class="font11 w-A"> <!-- <div class="espacioC"></div> -->
                                    <span class="celtam1 d-flex justify-content-center align-items-center flex-column col-12" *ngFor="let X of I.arrayConsumibles">
                                         

                                        <select *ngIf="X.arrayPrecios.length>1" class="form-select font12" aria-label="Default select example" 
                                           [(ngModel)]="X.PrecioSeleccionadoCJ" (change)="getCalculoConsumo(X,I)" >
                                            <option [value]="precio.dblPrecioLista" *ngFor="let precio of X.arrayPrecios |filterprecio:'CJ'">
                                                {{precio.dblPrecioLista|currency}}
                                            </option>
                                        </select>
                                        
                                        <div class=" d-flex justify-content-center flex-column" *ngIf="X.arrayPrecios.length!=1">
                                            <label for="" class="rounded-top p-1 mt-2 text-center" style="background:#a7a9ab; color: #f5f5f5;">Otro Precio</label>
                                            <input class="form-control form-control-sm rounded-0 rounded-bottom" type="text" (change)="getCalculoConsumo(X,I)" [(ngModel)]="X.PrecioSeleccionadoCJ">
                                        </div>
                                        
                                     <!--   <div class="input-group input-group-sm " *ngIf="X.arrayPrecios.length!=1">
                                            <span class="input-group-text" id="inputGroup-sizing-sm">Otro Precio</span>
                                            <input type="text" class="form-control" aria-label="Sizing example input" 
                                            aria-describedby="inputGroup-sizing-sm" (change)="getCalculoConsumo(X,I)" [(ngModel)]="X.PrecioSeleccionadoCJ">
                                        </div> -->

                                        

                                        <span class="txt-Total" *ngIf="X.arrayPrecios.length==1"> 
                                            <input [(ngModel)]="X.PrecioSeleccionadoCJ" (change)="getCalculoConsumo(X,I)" [value]= "X.dblCostoPromedio" class="form-control form-control-sm" type="number"   aria-label=".form-control-sm example" >
                                        </span>
                                       <!--  <div class="espacioG"></div> -->
                                    </span>
                                </td>


                                <td class="font11 w-A align-middle celtam0">
                                   <!--  <div class="espacioC"></div> -->
                                    <span *ngFor="let X of I.arrayConsumibles">
                                        <label class="rounded bg-secondary text-white px-1"><b> {{X.precioxcm|number:'1.2-2'}}</b></label>
                                      <!--   <div class="espacioG"></div>  -->                                   
                                    </span>
                                    
                                </td>
                                <td class="font11 w-A align-middle celtam0">
                                    <!-- <div class="espacioC"></div> -->
                                  
                                    <span *ngFor="let X of I.arrayConsumibles ">
                                        <label class="rounded bg-secondary text-white px-1"><b> {{X.result2|currency}}</b></label>
                                      <!--   <div class="espacioG"></div>   -->                                  
                                    </span>
                                </td>
                                <td class="porcentaje align-middle celtam0" [ngClass]="{'done' : I.dblResult2>0,'close' : I.dblResult2<0}">
                                   <!--  <div class="espacioC"></div> -->
                                     {{I.dblResult2|percent}}   
                                </td>
                                 <!--  <td class="txt-Total"> <div class="espacioC"></div><span *ngFor="let X of I.arrayConsumibles">{{X.dblTotal|currency}}<div class="espacioG"></div></span></td>
                                <td class="porcentaje" [ngClass]="{'done' : I.intNumVisita==1,'close' : I.intNumVisita==0}"><div class="espacioG"></div><span>{{I.dblResult|percent}}</span> </td>
                            -->
                            </tr>

                        </tbody>
                    </table>
                    </div>
                </div>
              


     
            

            <div class="cien"><br><br>
                <h1 *ngIf="arraySegInicial.length>0"><span class="icon-sec"><i class="fa-solid fa-box"></i></span> Consumo Inicial</h1>
                <div class="fondoAzul" *ngIf="arraySegInicial.length>0">
                    <div class="divYN">
                        <table class="tabla_producto table">
                            <thead class="header_tabla">
                                <div class="headcolN flex">
                                    <th class="w20">Categoría</th>

                                    <th class="w30">Despachador</th>
                                    <th class="w30">Consumible</th>

                                    <th class="w20">Cantidad Inicial</th>
                                </div>
                                <div class="no_fijas">
                                    <th *ngFor="let I of arrayVisitaLocal"> Visita {{I.intNumVisita}}</th>

                                </div>
                            </thead>
                            <tbody class="tLeft">
                                <tr *ngFor="let I of arraySegInicial">
                                    <!--<tr *ngFor="let I of arraySegInicial">-->
                                    <div class="headcolN">
                                        <td class="w20">{{I.strCategoria}}</td>
                                        <td class="w30">{{I.strNombreDespachador}} <br> (<b>{{I.strClaveDespachador}}</b>)</td>
                                        <td class="w30">{{I.strNombreConsumible}}<br>(<b>{{I.strClaveConsumible}}</b>)</td>
                                        <td class="w20 cantidadInicial">
                                            <p>{{I.intCantidadConsumible}} - {{I.strUnidadConsumible}}</p>
                                        </td>
                                    </div>
                                    <div class="no_fijas">
                                        <td *ngFor="let v of arrayVisitaLocal">
                                            <input type="number" min="0" [(ngModel)]="I['strVisita'+v.intNumVisita]" placeholder="Visita 1" class="form-control inputI" [disabled]="v.strVisita2!='1'">
                                            <!--(blur)="fnCambioValorFecha(I, 1, v.intNumVisita)"-->
                                            <p class="mt-10"><i class="fa-solid fa-calendar icon-sec"></i>&nbsp;<b>{{v.strFecha}}</b></p>
                                            <div class="form-check" *ngIf="v.strVisita2=='1'">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [checked]="I.checked" (change)="changeStatus(I)">
                                                <label class="form-check-label" for="flexCheckDefault">
                                                Sin Valor
                                                </label>
                                            </div>
                                        </td>

                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div *ngIf="visitaActivaL==0">
                    <!---->
                    <br>
                    <h1><span class="icon-ini"><i class="fa-solid fa-box"></i></span> Consumo Medición Tork </h1>
                    <!--<button type="button" class="btn btn-outline-primary">Más consumibles</button> preguntar si se pondrá o no la opción--> 
                    <div class="fondoAzulT">
                        <div class="divYN">
                            <table class="tabla_producto table">
                                <thead class="header_tablaT ">
                                    <div class="headcolT flex  ">
                                        <th class="w20">Categoría</th>
                                        <th class="w30">Despachador</th>
                                        <th class="w30">Consumible</th>
                                        <th class="w20">Cantidad Inicial</th>
                                        
                                    </div>
                                    <div class="no_fijas">
                                        <th class="w20"> Disponible</th> 
                                       
                                        <th *ngFor="let I of arrayVisitaTork"> Visita {{I.intNumVisita}}</th>
                                       
                                    </div>
                                </thead>
                                <tbody class="tLeft">
                                    <tr *ngFor="let I of arraySegTork">
                                        <div class="headcolT">
                                            <td class="w20 txt-M azul">{{I.strCategoria}}</td>
                                            <td class="w30">{{I.strNombreDespachador}} <br> (<b>{{I.strClaveDespachador}}</b>)</td>
                                            <td class="w30">{{I.strNombreConsumible}}<br>(<b>{{I.strClaveConsumible}}</b>)</td>
                                            <td class="w20 cantidadInicial-T">
                                                 <p>{{I.intCantidadConsumible}} - {{I.strUnidadConsumible}}</p>

                                               <!--<input type="number" min="0" [(ngModel)]="I.intCantidadInicial" class="form-control" style="min-width: 80px;">--> 

                                            </td> 
                                            
                                        </div>
                                        <div class="no_fijas">
                                            <td class="w20 cantidadInicial2-T"> 
                                            
                                             <p *ngIf="strEstatusSeguimiento=='CONCLUIDO'">{{I.intCantidadConsumible -I.totalTork}}</p>
                                                <button *ngIf="strEstatusSeguimiento!='CONCLUIDO'" type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal">{{I.intCantidadConsumible -I.totalTork}}</button>
                                               
                                           </td> 
                                           

                                            <td *ngFor="let v of arrayVisitaTork">

                                                <input type="number" min="0" [(ngModel)]="I['strVisita'+v.intNumVisita]" placeholder="Visita 1" class="form-control inputT" [disabled]="v.strVisita2!='1'">
                                                <!--(blur)="fnCambioValorFecha(I, 1, v.intNumVisita)" -->
                                                <p class="mt-10"><i class="fa-solid fa-calendar icon-sec"></i>&nbsp; <b>{{v.strFecha}}</b></p>
                                                <div class="form-check" *ngIf="v.strVisita2=='1'">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [checked]="I.checked" (change)="changeStatus(I)">
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                      Sin Valor
                                                    </label>
                                                </div>
                                            </td>
                                             

                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <br><br>

            </div>

            <br>

            <!--<div class="cien mt-20">
                <label class="label">Comentarios</label>
                <textarea type="text" [(ngModel)]="strComentariosSeguimiento" placeholder="Comentarios seguimiento" maxlength="200" class="form-control"></textarea>
            </div>-->

            <div class="group-control mar_btn">
                <!---->
                <button type="button" class="btn btn-success fRight" (click)="fnGuardarVisita()" *ngIf="(strEstatusSeguimiento=='PENDIENTE' || strEstatusSeguimiento=='NO EXISTE') && btnGuardar==true && (visitaActivaL>0||visitaActivaT>0)">Guardar Visita</button>

            </div>
            <div class="group-control mar_btn" *ngIf="showBtnFin">
                <button type="button" class="btn btn-success fRight" (click)="openDialogDetalle('TORK')" 
                *ngIf="strEstatusSeguimiento=='CONCLUIDO' && btnFinalizar==true && (strEstatusAnalisis=='' ||strEstatusAnalisis=='RECHAZADO') ">Finalizar Seguimiento</button>
            </div>
            <div class="group-control mar_btn" *ngIf="arraySegInicial.length==0 && strEstatusSeguimiento!='CONCLUIDO'">
                <button type="button" class="btn btn-danger fRight" (click)="openDialogDetalle('DIRECTO')">Cambio Directo</button>
            </div>
        </div>
    </div>
   
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header" style="background-color:rgb(218, 225, 225);color:#39393e;text-align: center;">
          <h1   id="exampleModalLabel" >Solicitar Consumibles</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <table class="table">
                <thead>
                  <tr style="text-align: center;">
                    <th scope="col">Consumible</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Cantidad Actual</th> 
                    <th scope="col">Nueva Cantidad (cajas)</th> 
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let I of arraySegTork">
                    <th scope="row">{{I.strClaveConsumible}}</th>
                    <td>{{I.strNombreConsumible}}</td>
                    <td style="text-align: center;"><b>{{I.intCantidadConsumible -I.totalTork}}</b></td> 
                    <td> 
                        <input type="number" class="form-control" placeholder="Cantidad" [(ngModel)]="I.dblNuevaCantidad"
                        aria-label="Cantidad" aria-describedby="basic-addon1">
                    </td> 
                  </tr>
                   
                </tbody>
              </table>
        </div>
        <div class="modal-footer"  *ngIf="!spCargando">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-success" (click)="fnNuevaSolicitudComodato()">Generar Solicitud</button>
        </div>
        <div class="cien text-center fLeft" *ngIf="spCargando">
            <mat-spinner></mat-spinner>
        </div>

      </div>
    </div>
  </div>

<app-footer></app-footer>