import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { LocalStorageService } from 'ngx-webstorage';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { PeticionesService } from '../servicios/httpservice';
import esLocale from '@fullcalendar/core/locales/es';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.css']
})

export class LeadComponent implements OnInit {
  /* @ViewChild('summernote', { static: true }) summernote: any; */
  @ViewChild('calendar', { static: true }) calendar: any;
  @ViewChild('btncerrar') btnCerrarMdl!: ElementRef;
  public intPeriodo: number;
  public intEjercicio: number;
  public strAgenteLD: string = '';
  public strTipo: string = "lead";
  public strConexion: string = "";
  public strSucursal: string = "";
  public strSucursal_modal: string = "";
  public strGerente: string = "";
  public strGerente_modal: string = "";
  public strAgente: string = "";
  public activeButton: string = "cards";
  public strClasificacion: string = "CCC"
  public intFlagActividad: any
  public arrayTipoCotizacion: any = []
  public boolGerente: boolean = false;
  public boolSucursal: boolean = false;
  public boolAgente: boolean = false;
  public isHoveredButton: boolean = false;
  public maxLengthComentario: number = 100;
  public arraySucursales: any = [];
  public arrayGerentes: any = [];
  public arrayAccesos: any = [];
  public arrayEjercicio: any = [];
  public arrayPeriodo: any = [];
  public arrayGiros: any = [];
  public arrayTamanoEmpresa: any = [];
  public arrayPresencia: any = [];
  public arrayUbicaciones: any = [];
  public array_por_contactar: any = [];

  public myForm: FormGroup;
  public formLead: FormGroup;

  public tagName: any;
  public filterPost: any;

  public arrayLisFamilia: any = [];
  public strTipoCotizacion: string = "";
  public intTotalSucursales: any = '';
  public strRazonSocial: any = '';
  public strSitioWeb: any = '';
  public strRFC: any = '';
  public strFechaEstimadaCierreInput: any = '';
  public dblImporte: string = "";
  public strPrioridad: string = "";
  public strNombreEmpresa: string = "";
  public strEtapa: string = "Por Contactar";
  public strSector: string = "";
  public panelOpenState = false;
  public emailEsObligatorio: any;
  public emailEsValido: any;
  public calendarioLeads: any = []

  public ListadoHistorial: any = [];
  public arrayPresentacion: any = [];
  public arrayRecorridoLead: any = [];
  public arrayRecorridoProspecto: any = [];
  public arrayPropuesta: any = [];
  public arrayNegociacion: any = [];
  public arrayCierre: any = [];
  public arrayPropuestaCliente: any = [];
  public arrayNegociacionCliente: any = [];
  public arrayContacto: any = [];
  public arrayCalendario: any = []
  public arrayCierreCliente: any = []; public strZona: any;
  public spinnerPC: any
  public spinnerP: any
  public spinnerR: any
  public spinnerPER: any
  public arrayAgentes: any = [];
  public intTotal_por_contactar: any = 0;
  public intTotal_presentacion: any = 0;
  public intTotal_recorrido_lead: any = 0;
  public intTotal_recorrido_prospecto: any = 0;
  public intTotal_propuesta: any = 0;
  public intTotal_negociacion: any = 0;
  public intTotal_cierre: any = 0;
  public intTotal_Perdido: any = 0;
  public intTotal_propuesta_cliente: any = 0;
  public intTotal_negociacion_cliente: any = 0;
  public intTotal_cierre_cliente: any = 0;
  public intFlagAvanza1: any
  public strAgenteSP: any;
  public strAgenteAsignado: any;
  public strInicialesAgente: any = '';
  public strFechaActividadUltima: any;
  public strFechaPerdido: any;
  public dblMontoPorContactar: any = 0;
  public dblMontoPresentacion: any = 0;
  public dblMontoRecorridoLead: any = 0;
  public dblMontoRecorridoProspecto: any;
  public dblMontoPropuesta: any;
  public dblMontoNegociacion: any;
  public dblMontoCierre: any;
  public dblMontoPropuestaCliente: any;
  public dblMontoNegociacionCliente: any;
  public dblMontoCierreCliente: any;
  public diferenciaDias: any;
  public diferenciaHoras: any;
  public diferenciaMinutos: any;
  public strActividadSimple: any = '';
  public intValidacionFamilia: number = 0
  public arrayAnexosUpdate: any = [];
  public strMotivoCancelado: any;
  public strMotivoPerdido: any;
  public strFechaActividad: any;
  public intDuracionActividad: any = 0;
  public strComentario: any;
  public strResultado: any = '';
  public strTipoActividad: any = '';
  public strEstatus: any;
  public strDescripcionActividad: any;
  public intTiempoDias: any = 0;
  public intTiempoHoras: any = 0;
  public banderaCierre: any = true;
  public banderaFuturo: any = false;
  public guardarHabilitado: any = true;
  public strCoordinadorOperativo: any;
  public objCliente: any = []
  public intGiro: any = '';
  public strFamilia: any = '';
  public strMedioConocio: any = 'VENDEDOR';

  public strProbabilidad: any = '';
  public strFechaEstimadaCierre: any = '';

  public strFechaCancelado: any;
  public strNumeroEmpleados: any = '';

  public strNombreContacto: any = '';
  public strPuestoContacto: any = '';
  public strTelefonoContacto: string = '';
  public strEmailContacto: any = '';
  public strUrl: any = '';
  public strCodigoPostal: any;
  public strCalle: any = '';
  public strEstado: any = '';
  public strNumeroExterior: any = '';
  public strDelegacion: any = '';
  public strEntreCalles: any = '';
  public strColonia: any = '';
  public strLadaEmpresa: any = '';
  public strTelefonoEmpresa: any = '';
  public strOtraColonia: any = '';
  public strTipoAnexo: any = 'Contrato';
  public intid_Cliente: any = 0
  public arrayAnexos: any = [];
  public intID_Familia: any = 0
  public banderaColonia: any = false;
  public banderaTipo: any = true;
  public strTieneServicio: any
  public arrayColonias: any = [];
  public ListaAnexos: any = [];

  public strTipoAgente: any;

  public arrayTotalLeads: any;
  public intIDLeads: any;
  public strFase: any;
  public banderaFase: any;
  public arrayZonas: any;
  public opcionSeleccionada: string = 'espera';
  public fechaActual: any;
  public arrayActividades: any = [];

  public subcadena = ",";
  public fechaInicioServicio: any = null;
  public strComentarioCancelado: any;
  public strComentarioPerdido: any;
  public strFechaInicioServicio: any;
  public intCantidadElementos: any = 0;
  public arrayFamilias: any = [];
  public datosFiltradosContactar: any = [];
  public datosFiltradosPresentacion: any = [];
  public datosFiltradosRecorrido: any = [];
  public filtroFecha: string = '';
  public datosFiltradosPerdidos: any;
  public arrayPerdidos: any = [];
  public dblMontoPerdidos: any = 0;
  public intIDAnexo: any;
  public arrayContactos: any;
  public arrayContactosDetalle: any = [];
  public banderaEspera: any = true;
  public banderaGanado: any = false;
  public banderaCancelado: any = false;
  public banderaPerdido: any = false;
  public strRecomendacion: any;
  public selectedItem: any;
  public banderaContacto: any;

  public elementosPresentacion: any;
  public elementosRecorrido: any;
  public elementosPerdido: any;
  public elementosPEProspecto: any;
  public elementosNProspecto: any;
  public elementosCProspecto: any;
  public elementosPECliente: any;
  public elementosNCliente: any;
  public elementosCCliente: any;
  public esDispositivoPequeno: any;
  public intIDPeritaje: any;
  public intFlagAvanza3: any;
  public intIDUbicacion: any;
  public intIDCliente: any
  public calendarOptions: any;
  public montoPrueba: any
  public intFlagAvanza2: any
  public intTotalServiciosPC: any = 0;
  public intTotalMaterialesPC: any = 0;
  public intTotalServiciosP: any = 0;
  public intTotalMaterialesP: any = 0;
  public intTotalServiciosR: any = 0;
  public intTotalMaterialesR: any = 0;
  public intTotalServiciosPER: any = 0;
  public intTotalMaterialesPER: any = 0;
  public intTotalServiciosPRO: any = 0;
  public intTotalMaterialesPRO: any = 0;
  public intTotalServiciosNEG: any = 0;
  public intTotalMaterialesNEG: any = 0;
  public intTotalServiciosCIERRE: any = 0;
  public intTotalMaterialesCIERRE: any = 0;
  public intTotalServiciosPROPUESTACLIENTE: any = 0;
  public intTotalMaterialesPROPUESTACLIENTE: any = 0;
  public intTotalServiciosNECLIENTE: any = 0;
  public intTotalMaterialesNECLIENTE: any = 0;
  public intTotalServiciosCICLIENTE: any = 0;
  public intTotalMaterialesCICLIENTE: any = 0;
  public intId_Contacto: any = 0
  public intIDPresencia: any = ''
  public intId_Leads_Relacion: any = 0
  public intId_Direccion: any = 0
  public contenido: string = '';
  public strTextButton: string = 'seleccione Archivo'
  public strAsunto: string = '';
  public file: File;
  public strDestinatario: string;
  public strRemitente: string;
  public arrayCotizaciones: any = []
  @ViewChild('fileInput') fileInput: ElementRef;
  opciones = [
    { valor: 'llamada', icono: 'fas fa-phone-alt', },
    { valor: 'correo', icono: 'fas fa-envelope' },
    { valor: 'visita', icono: 'fas fa-walking' },
  ];
  checkboxes: any = {
    hoy: false,
    manana: false,
    estaSemana: false,
    esteMes: false,
    futuros: false,
    conDemora: false,
  };

  constructor(private sanitizer: DomSanitizer, private carroservice: CarroService, public _alertify: AlertifyService, private formbuilder: FormBuilder, private _filtroServ: FiltrosService, public router: Router, elem: ElementRef, public route: ActivatedRoute, private cdr: ChangeDetectorRef, public _serviciosService: PeticionesService, public localSt: LocalStorageService) {/*, public socket: SocketServiceService */
  this.arrayTipoCotizacion = [
    {strTipoCotizacion : 'Materiales', Seleccionado: false},
    {strTipoCotizacion : 'Servicios especiales', Seleccionado: false},
    {strTipoCotizacion : 'Cotizacion Rapida', Seleccionado: false},
    {strTipoCotizacion : 'Cotizacion por TYM', Seleccionado: false},
    {strTipoCotizacion : 'Licitaciones', Seleccionado: false},
    {strTipoCotizacion : 'Estudio de Mercado', Seleccionado: false}
  ]
    $('[data-toggle="popover"]').popover({
      html: true
    });
    this.esDispositivoPequeno = this.isMobile();
    this.strAgenteSP = this.localSt.retrieve('strAgente')
    this.strTipoAgente = this.localSt.retrieve('strEmail')

    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos') ? this.localSt.retrieve('arrayAccesos') : [];

    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;

        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.strSucursal_modal = this.strSucursal;


        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;

        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');

        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.strGerente_modal = this.strGerente;
      }
      //validamos para inicializar los filtros
      /* console.log(this.localSt.retrieve('strUsuario'))
        console.log(this.strGerente );
       console.log(this.strSucursal);
       console.log(this.strAgente); */
    }

    this.myForm = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strSector: new FormControl('', [Validators.maxLength(20), Validators.required]),
      intId_Giro: new FormControl('', [Validators.required]),
      strCodigoPostal: new FormControl('', [Validators.maxLength(5), Validators.required]),
      strDelegacion: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strEstado: new FormControl('', [Validators.maxLength(100), Validators.required]),
      intIDPresencia: new FormControl('', [Validators.required]),
      intIDNo_Empleados: new FormControl('', [Validators.required]),
      intid_Ubicacion: new FormControl('', [Validators.required]),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      strEstatus: new FormControl('POR CONTACTAR', []),
      strAccion: new FormControl('setLeads', []),
      strConexion: new FormControl(this.strConexion, []),
      strAgente: new FormControl(this.strAgenteSP, []),
      strClasificacion: new FormControl('', [])
    });

    this.formLead = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.required]),
      strSector: new FormControl('', [Validators.required]),
      intGiro: new FormControl('', [Validators.required]),
      intIDPresencia: new FormControl('', [Validators.required]),
      intID_Familia: new FormControl(0, []),
      strNumeroEmpleados: new FormControl('', [Validators.required]),
      strRazonSocial: new FormControl('', []),
      strRFC: new FormControl('', []),
      strTipoCotizacion: new FormControl('', [Validators.required]),
      strMedioConocio: new FormControl('VENDEDOR', [Validators.required]),
      strTieneServicio: new FormControl('', [Validators.required]),
      intIDUbicacion: new FormControl('', [Validators.required]),
    })

    this.strFechaEstimadaCierreInput = this.formatoFecha(fecha);
    this.emailEsObligatorio = false
    this.fnListadoFamilias()
    this.arrayContactosDetalle = [{
      strNombreContacto: '',
      strContactoPuesto: '',
      strContactoTelefono: '',
      strContactoEmail: '',
      emailEsObligatorio: false
    }]
    this.fngetPresencia();
    this.fngetUbicaciones();
  }

 /*  ngAfterViewInit() {
    // Inicializa Summernote después de que la vista se haya cargado completamente
    
     if (this.summernote) {
      $(this.summernote.nativeElement).summernote();  // Ensure this is not undefined
    }else{
      console.log('UNDEFINED')
    }
     $(this.summernote.nativeElement).summernote({
      placeholder: 'Hello stand alone ui',
      tabsize: 2,
      height: 120,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
    }) 
  } */

  ngOnInit(): void {
    
    this.fnGetFiltros()
    this.fnGetPorContactar();
    this.fngetGiros();
    this.fngetRangoEmpleados();
    this.fnGetFamilias()
  }
  // Método para verificar si el formulario es válido
  isFormValid(): boolean {
    return this.myForm.valid;
  }

  onSave(): void {
    let rest: any = [];
    let objForm: any;
    let giro = (this.myForm.value.intId_Giro).split(',')
    this.myForm.value.intId_Giro = giro[0]
    let presencia = (this.myForm.value.intIDPresencia).split(',')
    this.myForm.value.intIDPresencia = presencia[0]
    let ubicacion = (this.myForm.value.intid_Ubicacion).split(',')
    this.myForm.value.intid_Ubicacion = ubicacion[0]
    this.myForm.value.strClasificacion = this.strClasificacion

    objForm = this.myForm.value;

    this._serviciosService.mostrarCotizaciones(objForm, 'sp_modVentas_Leads').subscribe(
      result => {
        rest = result

        Swal.fire({
          icon: rest[0].code == '200' ? 'success' : 'error',
          title: rest[0].code == '200' ? 'Enviado' : 'Error',
          text: rest[0].strMensaje,
        });
        if (rest[0].code == '200') {
          this.fnGetPorContactar();
          this.btnCerrarMdl.nativeElement.click();
        }
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      /* console.log('regresa del filtro')
      console.log(nuevoValor); */
      this.arrayAgentes = nuevoValor;
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  fngetGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayGiros = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetUbicaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getUbicaciones',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayUbicaciones = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetRangoEmpleados() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getRangoEmpleados',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
      
        this.arrayTamanoEmpresa = result

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  limpiarform() {
    this.myForm.reset();
    this.myForm = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strSector: new FormControl('', [Validators.maxLength(20), Validators.required]),
      intId_Giro: new FormControl('', [Validators.required]),
      strCodigoPostal: new FormControl('', [Validators.maxLength(5), Validators.required]),
      strDelegacion: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strEstado: new FormControl('', [Validators.maxLength(100), Validators.required]),
      intIDPresencia: new FormControl('', [Validators.required]),
      intIDNo_Empleados: new FormControl('', [Validators.required]),
      intid_Ubicacion: new FormControl('', [Validators.required]),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      strEstatus: new FormControl('POR CONTACTAR', []),
      strAccion: new FormControl('setLeads', []),
      strConexion: new FormControl(this.strConexion, []),
      strAgente: new FormControl(this.strAgenteSP, []),
    })
  }

  stopPropagation2(event: any) {
    event.stopPropagation();
  }

  playPropagacion(event: Event) {
    event.preventDefault();
  }

  fnGetDatosFiscales() {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: (this.strCodigoPostal) ? this.strCodigoPostal : this.myForm.controls['strCodigoPostal'].value
    }).subscribe(
      result => {
        if (result.length > 0) {
          if (this.strCodigoPostal) {
            this.strEstado = result[0].strEstado
            this.strDelegacion = result[0].strDelegacion
            this.arrayColonias = result;
          } else {
            this.myForm.controls['strEstado'].setValue(result[0].strEstado);
            this.myForm.controls['strDelegacion'].setValue(result[0].strDelegacion);
            this.arrayColonias = result;
          }


        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Codigo Postal Incorrecto!',
          });
          this.myForm.controls['strEstado'].setValue('');
          this.myForm.controls['strDelegacion'].setValue('');
          this.arrayColonias = []
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetDatosFiscalesDetalle(CP: any) {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: CP
    }).subscribe(
      result => {
        if (result.length > 0) {

          this.arrayColonias = result;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Codigo Postal Incorrecto!',
          });

        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  onKeyDown(event: KeyboardEvent) {
    // Código ASCII de la tecla Delete y Retroceso     const DELETE_KEY = 46;     const BACKSPACE_KEY = 8;
    const ENTER_KEY = 13;
    // Verificar si se presionó la tecla Delete o Retroceso
    if (event.keyCode === ENTER_KEY) {
      this.fnGetDatosFiscales();
    } else {
      this.myForm.controls['strEstado'].setValue('');
      this.myForm.controls['strDelegacion'].setValue('');
    }
  }

  fnGetPorContactar() {
    this.array_por_contactar = []
    this.spinnerPC = true
    this.spinnerP = true
    this.spinnerR = true
    this.spinnerPER = true
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'POR CONTACTAR',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        
        this.array_por_contactar = result
        this.intTotal_por_contactar = this.array_por_contactar.length
        this.arrayCalendario = this.array_por_contactar.filter((b: any) => b.strFechaActividad != null || b.setrFechaActividad)

        this.fnGetPresentacion()
        this.spinnerPC = false
      },
      error => {
        var error = <any>error;
        console.log(error);
        this.fnGetPresentacion();

      }
    );
  }

  fnGetPresentacion() {
    this.arrayPresentacion = []
    
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'PRESENTACION',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayPresentacion = result
        this.arrayCalendario.push(...this.arrayPresentacion.filter((b: any) => b.strFechaActividad));
        this.intTotal_presentacion = this.arrayPresentacion.length
        this.spinnerP = false
        this.fnGetRecorrido()
       
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnGetRecorrido() {
    this.arrayRecorridoLead = []
   
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'RECORRIDO',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayRecorridoLead = result
        this.arrayCalendario.push(...this.arrayRecorridoLead.filter((b: any) => b.strFechaActividad));
        this.intTotal_recorrido_lead = this.arrayRecorridoLead.length
        this.spinnerR = false
        this.fnGetPerdidos()
       
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnGetPerdidos() {
    
    this.calendarioLeads = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'PERDIDO',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayPerdidos = result
        this.arrayCalendario.push(...this.arrayPerdidos.filter((b: any) => b.strFechaActividad));
        this.intTotal_Perdido = this.arrayPerdidos.length

        this.arrayCalendario.forEach((b: any) => {

          let fecha = b.strFechaActividad.split(' ')
          let fechaStart = fecha[0].split('/')[2] + '-' + fecha[0].split('/')[0] + '-' + fecha[0].split('/')[1]

          let datosCalendario = {
            title: b.strNombreEmpresa,
            start: fechaStart,
            description: b.strTipo,
            intIdLeads: b.intIdLeads,
            strFase: b.strFase
          }
          this.calendarioLeads.push(datosCalendario)

        })

        const calendarOptions: CalendarOptions = {
          initialView: 'multiMonthYear',
          contentHeight: 700,
          plugins: [dayGridPlugin, timeGridPlugin, multiMonthPlugin],
          locale: esLocale,
          themeSystem: 'bootstrap5',
          events: this.calendarioLeads,
          eventClick: (arg) => {
            this.fnDetalleLead(arg.event.extendedProps['intIdLeads'])
          },
          eventDidMount: function (info) {
            const eventElement = info.el;
            eventElement.setAttribute('data-bs-toggle', 'tooltip');
            eventElement.setAttribute('data-bs-placement', 'center');
            eventElement.setAttribute('title', info.event.extendedProps['description']);
          },
          dayMaxEventRows: true,
          views: {
            Año: {
              type: 'multiMonth',
              duration: { months: 12 },
              buttonText: 'Año'
            },
            timeGridMonth: {
              type: 'timeGrid',
              duration: { months: 1 },
              dayMaxEventRows: 3,
              buttonText: 'Mes'
            },
            timeGridWeek: {
              type: 'timeGrid',
              duration: { weeks: 1 },
              buttonText: 'Semana'
            },
            timeGridDay: {
              type: 'timeGrid',
              duration: { days: 1 },
              buttonText: 'Día'
            }
          },
          headerToolbar: {
            start: 'prev,Año,dayGridMonth,timeGridWeek,timeGridDay',
            center: 'title',
            end: 'next'
          }
        };

        this.calendarOptions = calendarOptions
        //this.aplicarFiltros();
        this.spinnerPER = false
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  onGiroChange(event: any, tipo: string) {
    let clasificacion = event.target.value.split(',')[1];

    if (tipo === 'giro') {
      this.strClasificacion = clasificacion + this.strClasificacion.substring(1);
    } else if (tipo === 'presencia') {
      this.strClasificacion = this.strClasificacion.charAt(0) + clasificacion + this.strClasificacion.substring(2);
    } else {
      this.strClasificacion = this.strClasificacion.substring(0, 2) + clasificacion;
    }
  }

  fnInsertActividades() {

    let fechaAux = (this.strFechaActividad) ? this.strFechaActividad : this.fechaInicioServicio

    let fechaF: any
    if (this.fechaInicioServicio) {
      fechaF = this.fechaInicioServicio
    } else {
      let fechaFormato = new Date(fechaAux)
      let dia = (fechaFormato.getDate() < 10) ? ('0' + fechaFormato.getDate()) : fechaFormato.getDate()
      let mes = (fechaFormato.getMonth() < 10) ? ('0' + (fechaFormato.getMonth() + 1)) : (fechaFormato.getMonth() + 1)
      let anio = fechaFormato.getFullYear()
      let hora = (fechaFormato.getHours() < 10) ? ('0' + fechaFormato.getHours()) : fechaFormato.getHours()
      let minutos = (fechaFormato.getMinutes() < 10) ? ('0' + fechaFormato.getMinutes()) : fechaFormato.getMinutes()
      let segundos = (fechaFormato.getSeconds() < 10) ? ('0' + fechaFormato.getSeconds()) : fechaFormato.getSeconds()
      fechaF = dia + '-' + (mes) + '-' + anio + ' ' + hora + ':' + minutos + ':' + segundos
    }

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'Insert_Actividades',
      intIdLeads: this.intIDLeads,
      strAgente: this.strAgenteSP,
      strFechaCierre_Estimada: fechaF,
      strTipo: this.strTipoActividad,
      strResultado: '',
      strComentario: this.strComentario,
      strAsunto: this.strResultado,
      strContenido: '',
      strEstatus: (this.fechaInicioServicio) ? 'CONCLUIDO' : 'PENDIENTE',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == '200') {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fngetActividades(this.intIDLeads)
          this.fnGetPorContactar()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnupdate_Actividad(item: any) {

    console.log(this.contenido);
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad',
      intID_Actividad: item.intID_Actividad,
      strResultado: this.strResultado,
      strComentario: this.contenido,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == '200') {
          this._alertify.success('Guardado Correctamente');
          this.fngetActividades(this.intIDLeads)
          this.strResultado = ''
          this.strComentario = ''

        } else {

        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    ); 
  }

  fngetActividades(id: any) {
    this.arrayActividades = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getActividades',
      intIdLeads: id,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
       console.log(result)
        if (result.length > 0) {
          this.intFlagAvanza1 = result[0].intFlagAvanza1
          this.intFlagActividad = result[0].intFlagActividad
          this.arrayActividades = result
        } else {
          this.intFlagAvanza1 = 0
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetPresencia() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getPresencia',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayPresencia = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnUpdateLead_Nuevo() {
    this.strTipoCotizacion = ''
    let tipoCotizacion = this.arrayTipoCotizacion.filter((b: any)=> b.Seleccionado == true)
    if(tipoCotizacion.length > 0){
      tipoCotizacion.forEach(element => {
        this.strTipoCotizacion += (element.strTipoCotizacion) + ','
      });
    }


    this._serviciosService.mostrarCotizaciones({
      strAccion: 'updateLeads',
      intid_Ubicacion: (this.intIDUbicacion).split(',')[0],
      intID_Familia: this.intID_Familia,
      strCodigoPostal: this.strCodigoPostal,
      strEstado: this.strEstado,
      strDelegacion: this.strDelegacion,
      strColonia: this.strColonia,
      strCalle: this.strCalle,
      strNo_exterior: this.strNumeroExterior,
      strEntrecalles: this.strEntreCalles,
      intLada: this.strLadaEmpresa,
      intTelefono: this.strTelefonoEmpresa,
      strZona: this.strZona,
      intId_Contacto: this.intId_Contacto,
      strContacto_Nombre: this.arrayContactosDetalle[0].strNombreContacto,
      strContacto_Telefono: this.arrayContactosDetalle[0].strContactoTelefono,
      strContacto_Puesto: this.arrayContactosDetalle[0].strContactoPuesto,
      strContacto_Email: this.arrayContactosDetalle[0].strContactoEmail,
      intIdLeads: this.intIDLeads,
      strNombreEmpresa: this.strNombreEmpresa,
      intId_Giro: (this.intGiro).split(',')[0],
      strSector: this.strSector,
      strRazon_Social: this.strRazonSocial,
      strRFC: this.strRFC,
      intIDNo_Empleados: this.formLead.value.strNumeroEmpleados,
      strMedio_NosConocio: this.strMedioConocio,
      strEstatus: this.formLead.value.strTieneServicio,
      strAgente: this.strAgenteLD,
      dblMonto: this.dblImporte,
      strFechaCierre_Estimada: this.strFechaEstimadaCierre,
      strEtiqueta: '',
      strSitio_Web: this.strSitioWeb,
      intId_Leads_Relacion: this.intId_Leads_Relacion,
      strPrioridad: this.strPrioridad,
      intId_Direccion: this.intId_Direccion,
      strObservaciones: '',
      intNo_Sucursales: this.intTotalSucursales,
      strFase: this.strFase,
      strResolucion: '',
      strtipoCotizacion: this.strTipoCotizacion,
      strFechaInicioServicio: this.fechaInicioServicio,
      intCantidadEmpleados: this.intCantidadElementos,
      strRecomendacion: this.strRecomendacion,
      intid_Cliente: this.intid_Cliente,
      intIDPresencia: (this.intIDPresencia).split(',')[0],
      strClasificacion: this.strClasificacion,
      xmlAnexos: this.arrayAnexosUpdate,
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Actualizado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetPorContactar()
          this.arrayAnexosUpdate = []
        } else {
          /* Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al actualizar!"
          }); */
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Actualizado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetPorContactar()
        }
        this.strTipoCotizacion = ''
        
        document.getElementById('botonCerrar').click()
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  SubirAnexos() {
    if (this.ListaAnexos.length > 0) {
      this._serviciosService.makeFileRequest('https://www.ecodeli.com/EvidenciaAnexo_Leads.php', [], this.ListaAnexos).then((result) => {

        Swal.fire({
          icon: 'success',
          title: 'Correcto...',
          text: 'Anexos Guardados',
        });
        //this.fnInsertLead()
        this.fnUpdateLead_Nuevo()
      }, (error) => {
        console.log(error);
      });
    } else {
      //this.fnInsertLead()
      this.fnUpdateLead_Nuevo()
    }

  }

  fnCotizador() {

    let objNombre_familia = this.arrayFamilias.find(o => o.intID_Familia == this.intID_Familia);
    let strNombre_familia = objNombre_familia ? objNombre_familia.strFamilia : '';
    this.objCliente = {
      strCliente: this.intIDLeads + '',
      strNombre: this.strNombreEmpresa,
      strDireccion: 'Morelos ', //this.intIDLeads,
      ClienteSeleccionado: true,
      strNombreSucursal: this.strSucursal,
      intIDSucursal: "",
      strTipo: "PROSPECTO",
      strAgente: this.strAgenteSP,
      strAlmacen: "",
      strClaveUsoCFDI: "NA",
      strCondicion: "Contado",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: "NA",
      strOrigen: '/leads/', //Servirá para redireccionar al componente origen
      strCteFam: strNombre_familia//agregamos familia 

    };

    this.carroservice.setModoPlantilla(true);
    this.carroservice.deleteCarrito();
    this.carroservice.setCte(this.objCliente);
    this.router.navigate(['/Cotizador/']);
  }

  fnGetCotizaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getCotizaciones_Cte',
      strCliente: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Cotizador').subscribe(
      result => {

        this.arrayCotizaciones = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetFamilias() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFamilias',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayFamilias = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  validarNumeros(event: KeyboardEvent) {
    const codigoTecla = event.keyCode;
    // Permite los números (0-9) y la tecla de retroceso (Backspace)
    if (codigoTecla < 48 || codigoTecla > 57) {
      event.preventDefault();
    }
  }

  toggleCotizacion(cotizacion: any){
    this.arrayTipoCotizacion.forEach(e => {
      if(cotizacion == e.strTipoCotizacion){
        if(e.Seleccionado){
          e.Seleccionado = false;
        }else{
          e.Seleccionado = true;
        }
      }
    });
  }


























  fnListadoFamilias() {}
  formatoFecha(fecha: Date): string {
    // Formatear la fecha en el formato deseado (ejemplo: "yyyy-MM-dd")
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  isMobile() {
    return window.innerWidth <= 768; // Puedes ajustar el valor 768 según tus necesidades.
  }
  selectFile() {
    // Simula un clic en el input de tipo archivo
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    // Obtiene el nombre del archivo seleccionado y lo muestra en el botón
    this.file = event.target.files[0];
    if (this.file) {
      this.strTextButton = this.file.name;
    } else {
      this.strTextButton = 'Seleccionar archivo';
    }
  }
  cambiarVista(vista: any) {
    this.activeButton = vista
  }
  onCheckboxChange(checkboxName: string) {
    // Deseleccionar todos los checkboxes
    Object.keys(this.checkboxes).forEach((name) => {
      if (name !== checkboxName) {
        this.checkboxes[name] = false;
      }
    });

    // Cambiar el estado del checkbox seleccionado
    this.checkboxes[checkboxName] = !this.checkboxes[checkboxName];

    // Aplicar los filtros con el estado actual de los checkboxes
    this.aplicarFiltros();
  }

  aplicarFiltros() {
    // Lógica para aplicar los filtros
    this.datosFiltradosContactar = this.array_por_contactar.filter((dato: any) => {
      const fecha = this.parseFecha(dato.strFechaActividad); // Asume que tienes una propiedad de fecha en tus datos
      const hoy = new Date();
      const manana = new Date(hoy);
      manana.setDate(manana.getDate() + 1);
      return (
        (!this.checkboxes.hoy || this.esMismaFecha(fecha, hoy)) &&
        (!this.checkboxes.manana || this.esMismaFecha(fecha, manana)) &&
        (!this.checkboxes.estaSemana || this.estasEnLaSemana(fecha, hoy)) &&
        (!this.checkboxes.esteMes || this.estasEnElMes(fecha, hoy)) &&
        (!this.checkboxes.futuros || this.esFuturo(fecha, hoy)) &&
        (!this.checkboxes.conDemora || this.esDemora(fecha, hoy))
      );
    });
    this.datosFiltradosPresentacion = this.arrayPresentacion.filter((dato: any) => {
      const fecha = this.parseFecha(dato.strFechaActividad); // Asume que tienes una propiedad de fecha en tus datos
      const hoy = new Date();
      const manana = new Date(hoy);
      manana.setDate(manana.getDate() + 1);
      return (
        (!this.checkboxes.hoy || this.esMismaFecha(fecha, hoy)) &&
        (!this.checkboxes.manana || this.esMismaFecha(fecha, manana)) &&
        (!this.checkboxes.estaSemana || this.estasEnLaSemana(fecha, hoy)) &&
        (!this.checkboxes.esteMes || this.estasEnElMes(fecha, hoy)) &&
        (!this.checkboxes.futuros || this.esFuturo(fecha, hoy)) &&
        (!this.checkboxes.conDemora || this.esDemora(fecha, hoy))
      );
    });
    this.datosFiltradosRecorrido = this.arrayRecorridoLead.filter((dato: any) => {
      const fecha = this.parseFecha(dato.strFechaActividad); // Asume que tienes una propiedad de fecha en tus datos
      const hoy = new Date();
      const manana = new Date(hoy);
      manana.setDate(manana.getDate() + 1);
      return (
        (!this.checkboxes.hoy || this.esMismaFecha(fecha, hoy)) &&
        (!this.checkboxes.manana || this.esMismaFecha(fecha, manana)) &&
        (!this.checkboxes.estaSemana || this.estasEnLaSemana(fecha, hoy)) &&
        (!this.checkboxes.esteMes || this.estasEnElMes(fecha, hoy)) &&
        (!this.checkboxes.futuros || this.esFuturo(fecha, hoy)) &&
        (!this.checkboxes.conDemora || this.esDemora(fecha, hoy))
      );
    });

  }
  parseFecha(fechaStr: string): Date {
    const partes = fechaStr.split('/');
    const dia = Number(partes[0]);
    const mes = Number(partes[1]) - 1;
    const anio = Number(partes[2]);
    return new Date(anio, mes, dia);
  }
  esMismaFecha(fecha1: Date, fecha2: Date): boolean {
    return (
      fecha1.getDate() === fecha2.getDate() &&
      fecha1.getMonth() === fecha2.getMonth() &&
      fecha1.getFullYear() === fecha2.getFullYear()
    );
  }

  // Función auxiliar para verificar si la fecha está dentro de la misma semana
  estasEnLaSemana(fecha: Date, hoy: Date): boolean {
    const inicioSemana = new Date(hoy);
    inicioSemana.setDate(inicioSemana.getDate() - hoy.getDay());
    const finSemana = new Date(inicioSemana);
    finSemana.setDate(finSemana.getDate() + 6);
    return fecha >= inicioSemana && fecha <= finSemana;
  }

  // Función auxiliar para verificar si la fecha está dentro del mismo mes
  estasEnElMes(fecha: Date, hoy: Date): boolean {
    return (
      fecha.getMonth() === hoy.getMonth() &&
      fecha.getFullYear() === hoy.getFullYear()
    );
  }

  // Función auxiliar para verificar si la fecha es un día futuro
  esFuturo(fecha: Date, hoy: Date): boolean {
    return fecha > hoy;
  }

  restarUnDia(fecha: Date): Date {
    const unDia = 24 * 60 * 60 * 1000; // Cantidad de milisegundos en un día
    const fechaRestada = new Date(fecha.getTime() - unDia); // Resta un día a la fecha
    return fechaRestada;
  }

  // Función auxiliar para verificar si la fecha es con demora
  esDemora(fecha: Date, hoy: Date): boolean {
    hoy = this.restarUnDia(hoy); // Resta un día a la variable 'hoy'
    return fecha < hoy;
  }

  fnDetalleLead(item: any) {
    
    this.intIDLeads = item.intIdLeads
    this.router.navigate(['/detalleLead'],{queryParams:{idLead: this.intIDLeads}});
    /* let tipoCotizacion = (item.strtipoCotizacion).split(',')
    
    if(tipoCotizacion.length > 0){
      tipoCotizacion.forEach(element => {
        this.arrayTipoCotizacion.map((dato: any) => {
          if (dato.strTipoCotizacion === element) {
            dato.Seleccionado = true;
          }
          return dato;
        });
      });
     
    }
    
    this.strNumeroExterior = item.strNo_exterior
    let fecha = new Date(item.strFechaCierre_Estimada)
    this.strFechaEstimadaCierreInput = this.formatoFecha(fecha)
    this.strTieneServicio = (item.strServicio_Limpieza) ? item.strServicio_Limpieza : ''
    this.intValidacionFamilia = item.intValidacionFamilia
    
    this.strNumeroEmpleados = item.intIDNo_Empleados
   this.intFlagAvanza3 = item.intFlagAvanza3
    this.intFlagAvanza2 = item.intFlagAvanza2
    this.intID_Familia = (item.intID_Familia == 0) ? 0 : item.intID_Familia
    
    this.strCodigoPostal = item.strCodigoPostal
    this.strDelegacion = item.strDelegacion
    this.strEstado = item.strEstado
    this.strCalle = item.strCalle
    this.strColonia = item.strColonia
    this.strEntreCalles = item.strEntrecalles
    this.strFase = item.strFase
    this.intId_Direccion = item.intId_Direccion
    this.strClasificacion = item.strClasificacion
    this.intId_Leads_Relacion = item.intId_Leads_Relacion
    this.intId_Direccion = item.intId_Direccion
    this.intid_Cliente = item.intid_Cliente
    this.strNombreEmpresa = item.strNombreEmpresa
    this.strSector = item.strSector
    this.dblImporte = item.dblMonto
    this.strEtapa = item.strEstatus
    this.strFase = item.strFase
    this.strSitioWeb = item.strSitio_Web
    this.strMedioConocio = 'VENDEDOR'
    this.strPrioridad = item.strPrioridad
    this.strRFC = item.strRFC;
    this.strAgenteLD = item.strAgente;
    
    let giro = this.arrayGiros.filter((b: any) => b.intId_Giro == item.intId_Giro)
    this.intGiro = item.intId_Giro + ',' + giro[0].strClasificacion
    let presencia = this.arrayPresencia.filter((b: any) => b.intIDPresencia == item.intIDPresencia)
    this.intIDPresencia = item.intIDPresencia + ',' + presencia[0].strClasificacion
    this.strRecomendacion = item.strRecomendacion
    this.arrayContactosDetalle[0].strNombreContacto = item.strContacto_Nombre
    this.arrayContactosDetalle[0].strContactoPuesto = item.strContacto_Puesto
    this.arrayContactosDetalle[0].strContactoTelefono = item.strContacto_Telefono
    this.arrayContactosDetalle[0].strContactoEmail = item.strContacto_Email
    this.intCantidadElementos = item.intCantidadEmpleados
    this.intTotalSucursales = item.intNo_Sucursales
    this.strRazonSocial = item.strRazon_Social
    let ubicacion = this.arrayUbicaciones.filter((b: any) => b.intid_Ubicacion == item.intid_Ubicacion)
    this.intIDUbicacion = item.intid_Ubicacion + ',' + ubicacion[0].strClasificacion
    this.fngetActividades(item.intIdLeads)
    this.fnGetCotizaciones()
    if (item.strCodigoPostal) {
      this.fnGetDatosFiscalesDetalle(item.strCodigoPostal)
    }
    this.fnGetAnexos(item.intIdLeads) */
    //this.fnInitSummer()
  }

  fnInitSummer(item: any){
  console.log(item)
    $('.summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
      },
      height: 150,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video']]
      ]
    })
    console.log(item.strComentario)
    
    setTimeout(() => {
      $('.summernote').summernote('code', item.strComentario);
    }, 500);
  }

  stopPropagation(event: any, estado: any) {
    event.stopPropagation();
    if (estado == 'Ganado') {
      this.banderaGanado = true
      this.banderaEspera = false
      this.banderaCancelado = false
      this.banderaPerdido = false
    } else if (estado == 'Espera') {
      this.banderaEspera = true
      this.banderaGanado = false
      this.banderaCancelado = false
      this.banderaPerdido = false
    } else if (estado == 'perdido') {
      this.banderaEspera = false
      this.banderaGanado = false
      this.banderaCancelado = false
      this.banderaPerdido = true
    } else {
      this.banderaEspera = false
      this.banderaGanado = false
      this.banderaCancelado = true
      this.banderaPerdido = false
    }
  }


  cambiarFechaInicioServicio(event: any) {
    let fecha = (event.target.value).split('-')
    this.fechaInicioServicio = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }

  fnGetAnexos(id: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getAnexos',
      intIdLeads: id,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayAnexos = result
      },
      error => {
        var error = <any>error;
        console.log(error);

      }
    );
  }

  fnAvanzarPresentacion() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_1',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetPorContactar()
          this.fnGetPresentacion()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAvanzarRecorrido() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_2',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetPresentacion()
          this.fnGetRecorrido()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAvanzarProspecto() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_3',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetPresentacion()
          this.fnGetRecorrido()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnInsertFechaInicioServicio() { }
  fnCambiarFuturo(event: any) {
    if (event.target.value == 'SI') {
      this.banderaFuturo = true
    } else {
      this.banderaFuturo = false
    }
  }
  cambiarFechaPerdido(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaPerdido = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }
  fnCancelado_Perdido() { }
  cambiarFechaCancelado(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaCancelado = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }
  validarUbicacion() {

    if (this.strEtapa == 'Recorrido') {

      if (!this.strCodigoPostal) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'No se tiene con una ubicación registrada, Favor de agregar los datos fiscales!',
        })
      } else {
        //this.fnValidacion_Ubicacion()
      }
    }
  }

  fnInsertActividadesSinF(event: Event) { }
  fnEnviarEdificios() {

    this.router.navigate(['/coordinadores/Edificios', this.intIDCliente, this.intIDUbicacion, this.strNombreEmpresa]);
  }

  getInitials(name: string): string {
    const names = name.split(' ');
    const initials = names.slice(0, 2).map(n => n.charAt(0).toUpperCase());
    return initials.join('');
  }
  fnObtenerHistorial() { }
  cambiarFase(event: any) {

    if (event.target.value == 'Por Contactar' || (event.target.value == 'Presentación' || event.target.value == 'Presentacion') || event.target.value == 'Recorrido') {
      this.strFase = 'LEAD'
      this.banderaFase = true
    } else if (event.target.value == 'Propuesta Economica' || event.target.value == 'Negociación') {
      this.strFase = 'PROSPECTO'
      this.banderaFase = false
      this.banderaCierre = true
    }

    this.validarUbicacion()
  }


  cambiarFecha(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaEstimadaCierre = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
    this.strFechaEstimadaCierreInput = fecha[0] + '-' + fecha[1] + '-' + fecha[2]
  }

  fnAgregarContacto() {
    this.arrayContactosDetalle.push({
      strNombreContacto: '',
      strContactoPuesto: '',
      strContactoTelefono: '',
      strContactoEmail: '',
      emailEsObligatorio: false
    })
  }

  fnEliminarContacto(index: any) {
    this.arrayContactosDetalle.splice(index, 1)
  }
  validarCampos(item: any) {
    let aux = item.strNombreContacto !== '' || item.strContactoPuesto !== '' || item.strContactoTelefono !== ''
    if (aux) {
      item.emailEsObligatorio = true
      this.banderaContacto = true
    } else {
      item.emailEsObligatorio = false
      this.banderaContacto = false
    }
  }
  validarTelefono(telefono: string): boolean {
    const pattern = /^\d{10}$/;

    return pattern.test(telefono);
  }
  validarEmailContacto(item: any) {
    if ((item.strNombreContacto !== '' || item.strContactoPuesto !== '' || item.strContactoTelefono !== '') && item.strContactoEmail != '') {
      item.emailEsObligatorio = true
      this.banderaContacto = true
      if (item.emailEsObligatorio && this.validarEmail(item.strContactoEmail)) {
        item.emailEsObligatorio = false
        this.banderaContacto = false
      }
    }
  }


  validarEmail(email: string): boolean {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    return regex.test(email);
  }


  agregarAnexo(event: any) {
    const fecha = new Date();
    const archivo: File = event.target.files[0];

    const tipo = archivo.type.split('/');
    if (tipo[1] == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      tipo[1] = "xlsx";
    }
    const nombreArchivo = this.intIDLeads + '-' + this.strTipoAnexo + ' ' + fecha.getFullYear() + '-' + fecha.getMonth() + '-' + fecha.getDay() + ' ' + fecha.getHours() + ':' + fecha.getMinutes() + ':' + fecha.getSeconds() + '.' + tipo[1];
    const rutaArchivo = 'EvidenciaLeads/' + nombreArchivo;

    const nuevoArchivo = new File([archivo], nombreArchivo, { type: archivo.type });
    this.ListaAnexos.push(nuevoArchivo);

    this.arrayAnexosUpdate.push({
      strTipo: tipo[1],
      strUrl: rutaArchivo,
      strTipoDocumento: this.strTipoCotizacion,
      strNombre: nombreArchivo
    });
  }
  fnBorrarAnexo(event: any) { }

  calcularMontoMateriales(array: any): number {
    return array.reduce((acumulador: number, item: any) => {
      if (item.strTipoCotizacion === 'Materiales') {
        return acumulador + item.dblMonto;
      } else {
        return acumulador;
      }
    }, 0); // El 0 es el valor inicial del acumulador
  }
  calcularMontoPorContactar(): number {
    return this.array_por_contactar.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }

  calcularMontoPresentacion(): number {
    return this.arrayPresentacion.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }
  calcularMontoRecorridoLead(): number {
    return this.arrayRecorridoLead.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }
  calcularMontoPerdidos(): number {
    return this.arrayPerdidos.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }

  calcularMontoPropuesta(): number {
    return this.arrayPropuesta.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }
  calcularMontoNegociacion(): number {
    return this.arrayNegociacion.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }
  calcularMontoCierre(): number {
    return this.arrayCierre.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }
  calcularMontoPropuestaCliente(): number {
    return this.arrayPropuestaCliente.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }
  calcularMontoNegociacionCliente(): number {
    return this.arrayNegociacionCliente.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.dblMonto;
    }, 0);
  }
  calcularMontoCierreCliente(): number {

    return this.arrayCierreCliente.reduce((accumulator: any, currentItem: any) => {
      /*  const formatoPesos = new Intl.NumberFormat('es-MX', {
         style: 'currency',
         currency: 'MXN',
         minimumFractionDigits: 2,
       }).format(currentItem.dblMonto);
 
       const Total = new Intl.NumberFormat('es-MX', {
         style: 'currency',
         currency: 'MXN',
         minimumFractionDigits: 2,
       }).format(accumulator);
       console.log('TOTAL ACUMULADO: '+ Total, '   NombreEmpresa:  '+currentItem.strNombreEmpresa, '   Monto:     ' + formatoPesos ) */
      return accumulator + currentItem.dblMonto;
    }, 0);
  }
  parseFecha2(strFecha: string): Date {
    let partes: any
    let dia: any;
    let mes: any;
    let anio: any;
    let hora: any;
    let minutos: any;
    let segundos: any;
    if (strFecha != '') {
      partes = strFecha.split(/[\s/:]+/);
      dia = parseInt(partes[0], 10);
      mes = parseInt(partes[1], 10) - 1;
      anio = parseInt(partes[2], 10);
      hora = parseInt(partes[3], 10) + (partes[6] === 'p' ? 12 : 0);
      minutos = parseInt(partes[4], 10);
      segundos = parseInt(partes[5], 10);
    }

    return new Date(anio, mes, dia, hora, minutos, segundos);
  }

  fnEnviarCOrreo() {
    let rest: any, cuerpo: string, asunto: string, destinatario: string, remitente: string, correoValido: boolean;
    destinatario = this.strDestinatario;
    remitente = this.strRemitente;
    cuerpo = this.contenido;
    asunto = this.strAsunto;

    const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    correoValido = regexCorreo.test(destinatario);

    if (correoValido) {

      if (asunto == "" || asunto == null) {
        Swal.fire({
          title: 'Confirmación',
          text: "¿Está seguro de enviar el correo sin asunto?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, Enviar'
        }).then((result) => {
          if (result.isConfirmed) {
            this._serviciosService.getObjCorreo(cuerpo, asunto, this.file, destinatario, remitente).subscribe(
              result => {
                rest = result;
                if (rest.success) {
                  Swal.fire('Correo Enviado Correctamente', '', 'success');
                  this.fnLimpiarCorreo();
                } else {
                  Swal.fire('Error al enviar correo', '', 'error');
                }

              }
            );
          }
        })
      } else {
        this._serviciosService.getObjCorreo(cuerpo, asunto, this.file, destinatario, remitente).subscribe(
          result => {
            rest = result;
            if (rest.success) {
              Swal.fire('Correo Enviado Correctamente', '', 'success');
              this.fnLimpiarCorreo();
            } else {
              Swal.fire('Error al enviar correo', '', 'error');
            }

          }
        );
      }
    } else {
      Swal.fire('Error', 'Correo de destino invalido, favor de verificarlo!', 'error');
    }
  }

  fnLimpiarCorreo() {
    this.strDestinatario = "";
    this.strAsunto = "";
    //$(this.summernote.nativeElement).summernote('code', '');
  }

}
