<div class="container mb-4 ">
  <input type="hidden" id="deviceId">
  <div class="row">
    <div class="col-12 col-lg-7 mb-3 mb-lg-0 pe-0"  >
  
      <div class="col-12 ">
        <h1 class="fw-bold text-center my-3 nunito">Dirección de envío</h1>
      </div>
      <form [formGroup]="formEntrega" class="row " (ngSubmit)="onSubmit()">
        <div class="col-12">
          <div class="form-floating  col-12">
            <input type="email" class="form-control mb-2 fs13" id="exampleFormControlInput1" placeholder="correo electrónico" 
             formControlName="email" [ngClass]="{'is-valid': f.email.touched && f.email.valid,'is-invalid': f.email.touched && f.email.invalid}" (ngModelChange)="checkEmailValidity()">
            <label for="exampleFormControlInput1" class="form-label">Correo Electrónico</label>
            <div *ngIf="f.email.touched && f.email.invalid" class="text-danger">
            <div *ngIf="f.email.errors?.required">El campo es obligatorio.</div>
            <div *ngIf="f.email.errors?.email">Debe ser un correo valido</div>
          </div>
          </div>
          <div class="mb-3">
            <div class="form-check d-flex align-items-center">
              <input type="checkbox" formControlName="strEnvioNovedades" class="me-2">
              <label class="form-check-label" for="flexCheckDefault">
                Enviarme novedades y ofertas por correo electrónico
              </label>
            </div>
          </div>
      </div>
          <div class="col-12">
            <div class="form-floating mb-3 col-12">
              <input type="text" class="form-control fs13" id="País" formControlName="strPais"  [ngClass]="{'is-valid': f.strPais.touched && f.telefono.valid,'is-invalid': f.strPais.touched && f.strPais.invalid}"><!--[(ngModel)]="telefono" -->
              <label for="floatingInput">País</label>
              <div *ngIf="f.strPais.touched && f.strPais.invalid" class="text-danger">
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" >
            <div class="form-floating mb-3">
              <input type="text" class="form-control fs13" id="Nombre" formControlName="strNombre" (ngModelChange)="checknameValidity()"
              [ngClass]="{'is-valid': f.strNombre.touched && f.strNombre.valid,
              'is-invalid': f.strNombre.touched && f.strNombre.invalid}"><!--[(ngModel)]="strNombre"  -->
              <label for="floatingInput">Nombre</label>
              <div *ngIf="f.strNombre.touched && f.strNombre.invalid" class="text-danger">
                <div *ngIf="f.strNombre.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strNombre.errors?.minlength">Debe tener al menos 2 caracteres.</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" >
            <div class="form-floating mb-3">
              <input type="text" class="form-control fs13" id="Apellidos" formControlName="strApellidos" (ngModelChange)="checklnameValidity()"
              [ngClass]="{'is-valid': f.strApellidos.touched && f.strApellidos.valid,
              'is-invalid': f.strApellidos.touched && f.strApellidos.invalid}"><!--[(ngModel)]="strApellidos"  -->
              <label for="floatingInput">Apellidos</label>
              <div *ngIf="f.strApellidos.touched && f.strApellidos.invalid" class="text-danger">
                <div *ngIf="f.strApellidos.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strApellidos.errors?.minlength">Debe tener al menos 2 caracteres.</div>
              </div>
            </div>
          </div>
         
        
          <div class="col-12 col-lg-4" >
            <div class="form-floating mb-3">
              <input type="text" (keydown)="onEnter($event)" class="form-control valid fs13" (input)="updateCantidad($event)"  formControlName="strCodigoPostal"
              [ngClass]="{'is-valid': f.strCodigoPostal.touched && f.strCodigoPostal.valid,'is-invalid': f.strCodigoPostal.touched && f.strCodigoPostal.invalid}"
               [maxlength]="5" autocomplete="new-password" autocorrect="off" autocapitalize="off" spellcheck="false" id="strCodigoPostal"><!--[(ngModel)]="strCodigoPostal" -->
                <label for="floatingInput">Código Postal</label>
                <div *ngIf="f.strCodigoPostal.touched && f.strCodigoPostal.invalid" class="text-danger">
                <div *ngIf="f.strCodigoPostal.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strCodigoPostal.errors?.pattern">Debe ingresar al menos 5 dígitos </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 position-relative mb-3 mb-lg-0" >
           
            <div class="form-floating">
              <input type="text" class="form-control valid fs13" readonly id="strEstado" formControlName="strEstado"
              [ngClass]="{'is-valid': f.strEstado.touched && f.strEstado.valid,'is-invalid': f.strEstado.touched && f.strEstado.invalid}" ><!--[(ngModel)]="strCodigoPostal" -->
                <label for="floatingInput">Estado</label>
              
              <div *ngIf="f.strEstado.touched && f.strEstado.invalid" class="text-danger">
                Ingrese un codigo postal correcto.
              </div>
            </div>
            <div *ngIf="spinnerCol" class="mb-2 col-12 d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
              <mat-spinner [diameter]="23"></mat-spinner>
            </div>
          </div>
          <div class="col-12 col-lg-4 position-relative" >
            <div class="form-floating mb-3">
              <input type="text" readonly class="form-control fs13" id="Ciudad" formControlName="strCiudad" 
              [ngClass]="{'is-valid': f.strCiudad.touched && f.strCiudad.valid,
              'is-invalid': f.strCiudad.touched && f.strCiudad.invalid}"><!--[(ngModel)]="strCiudad" -->
              <label for="floatingInput">Ciudad</label>
              <div *ngIf="f.strCiudad.touched && f.strCiudad.invalid" class="text-danger">
                Ingrese un codigo postal correcto.
              </div>
            </div>
            <div *ngIf="spinnerCol" class="mb-2 col-12 d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
              <mat-spinner [diameter]="23"></mat-spinner>
            </div>
          </div>
          <div class="col-12 col-lg-5" >
            <div class="form-floating mb-3">
              <select class="form-select fs13" id="floatingSelect" aria-label="Floating label select example" [ngClass]="{'is-valid': f.strColonia.touched && f.strColonia.valid, 'is-invalid': f.strColonia.touched && f.strColonia.invalid}" formControlName="strColonia">
                <option class="fs13" value="" selected>--Seleccione--</option>
                <option class="fs13" [value]="col.strColonia" *ngFor="let col of arrayColonias">{{col.strColonia}}</option>
              </select>
              <label for="floatingSelect">Colonia</label>
              <div *ngIf="f.strColonia.touched && f.strColonia.invalid" class="text-danger">
                El campo es obligatorio.
              </div>
          
            </div>
          </div>
          <div class="col-lg-7 col-12" >
            <div class="form-floating mb-3">
              <input type="text" class="form-control fs13" id="Casa" formControlName="strEntreCalles"
              [ngClass]="{'is-valid': f.strEntreCalles.touched && f.strEntreCalles.valid,
              'is-invalid': f.strEntreCalles.touched && f.strEntreCalles.invalid}"><!--[(ngModel)]="strEntreCalles"  -->
              <label for="floatingInput">Casa, apartamento, etc (opcional)</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control fs13" id="Direccion" formControlName="strDireccion"
              [ngClass]="{'is-valid': f.strDireccion.touched && f.strDireccion.valid,
              'is-invalid': f.strDireccion.touched && f.strDireccion.invalid}"><!--[(ngModel)]="strDireccion" -->
              <label for="floatingInput">Dirección (Calle y número)</label>
              <div *ngIf="f.strDireccion.touched && f.strDireccion.invalid" class="text-danger">
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-3">
              <input type="number" [maxlength]="10"  class="form-control no-spin fs13" id="floatingInput" formControlName="strTelefono"  (ngModelChange)="checktelefonoValidity()"
              [ngClass]="{'is-valid': f.strTelefono.touched && f.strTelefono.valid,
              'is-invalid': f.strTelefono.touched && f.strTelefono.invalid}"> <!-- [(ngModel)]="strTelefono" -->
              <label for="floatingInput">Teléfono</label>
              <div *ngIf="f.strTelefono.touched && f.strTelefono.invalid" class="text-danger">
                <div *ngIf="f.strTelefono.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strTelefono.errors?.pattern">Debe ser un número.</div>
              </div>
            </div>
          </div>
      </form>
      <div class="col-12 mt-3">

        <div class="form-check mb-3 borLeftBlue border bg-white shadow-sm ps-1 col-10 rounded py-2">
          <input  class="form-check-input ms-1"
            type="checkbox"    id="requerirFactura"    (change)="toggleFormulario()"          />
          <label class="form-check-label fw-bold ps-2 " for="requerirFactura">
            ¿Requiere factura?
          </label>
        </div>
        <div class="col-12" *ngIf="mostrarFormulario && formEntrega.invalid">
          <div class="col-8 border bg-white py-2 text-start rounded shadow-sm borLeft ps-3">
            Por favor, ingrese los datos de entrega para proceder con la emisión de la factura.
          </div>
        </div>
        <div class="form-check mb-3 borLeftBlue border bg-white shadow-sm ps-1 col-10 rounded py-2 d-flex" *ngIf="mostrarFormulario && formEntrega.valid">
          <input class="form-check-input ms-1" type="checkbox" id="requerirFactura" (change)="toggleMismaDireccion()" />
          <label class="form-check-label fw-bold ps-2" for="requerirFactura">
            ¿Desea utilizar la misma dirección proporcionada para la emisión de la factura?
          </label>
        </div>
        
        <div *ngIf="mostrarFormulario && formEntrega.valid">
          <form [formGroup]="facturaForm" class="row" (ngSubmit)="onSubmitFacturacion()">
            <div class="col-12 col-lg-6" >
              <div class="form-floating mb-3 col-12">
                <input type="text" class="form-control fs13" id="País" formControlName="strNombre"  [ngClass]="{'is-valid': fact.strNombre.touched && fact.strNombre.valid,'is-invalid': fact.strNombre.touched && fact.strNombre.invalid}"><!--[(ngModel)]="telefono" -->
                <label for="floatingInput">Nombre </label>
                <div *ngIf=" fact.strNombre.touched && fact.strNombre.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6" >
              <div class="form-floating mb-3 col-12">
                <input type="text" class="form-control fs13" id="País" formControlName="strApellidos"  [ngClass]="{'is-valid': fact.strApellidos.touched && fact.strApellidos.valid,'is-invalid': fact.strApellidos.touched && fact.strApellidos.invalid}"><!--[(ngModel)]="telefono" -->
                <label for="floatingInput">Apellidos</label>
                <div *ngIf=" fact.strApellidos.touched && fact.strApellidos.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>
            <div class="col-8" >
              <div class="form-floating mb-3 col-12">
                <input type="text" class="form-control fs13" id="País" formControlName="strRazonSocial"  [ngClass]="{'is-valid': fact.strRazonSocial.touched && fact.strRazonSocial.valid,'is-invalid': fact.strRazonSocial.touched && fact.strRazonSocial.invalid}"><!--[(ngModel)]="telefono" -->
                <label for="floatingInput">Razon Social</label>
                <div *ngIf=" fact.strRazonSocial.touched && fact.strRazonSocial.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-4" >
              <div class="form-floating mb-3 col-12">
                <input type="text" class="form-control fs13" id="País" formControlName="strPais"  [ngClass]="{'is-valid': fact.strPais.touched && fact.strPais.valid,'is-invalid': fact.strPais.touched && fact.strPais.invalid}"><!--[(ngModel)]="telefono" -->
                <label for="floatingInput">País</label>
                <div *ngIf="fact.strPais.touched && fact.strPais.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-5" >
              <div class="form-floating mb-3 col-12">
                <input (input)="convertirAMayusculas($event)"  type="text" class="form-control fs13" [maxlength]="13" id="País" formControlName="strRFC"  [ngClass]="{'is-valid': fact.strRFC.touched && fact.strRFC.valid,'is-invalid': fact.strRFC.touched && fact.strRFC.invalid}"><!--[(ngModel)]="strRFC" -->
                <label for="floatingInput">RFC</label>
                <div *ngIf="fact.strRFC.touched && fact.strRFC.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="col-7 " >
              <div class="form-floating mb-3">
                <select class="form-select" id="floatingSelect fs13" aria-label="Floating label select example" [ngClass]="{'is-valid': fact.strRegimenFiscal.touched && fact.strRegimenFiscal.valid, 'is-invalid': fact.strRegimenFiscal.touched && fact.strRegimenFiscal.invalid}" formControlName="strRegimenFiscal">
                  <option value="" class="" selected>--Seleccione--</option>
                  <option class="fs13" [value]="col.strFiscalRegimen" *ngFor="let col of arrayRegimenFiscal">{{col.strDescripcion}}</option>
                </select>
                <label for="floatingSelect">Regimen Fiscal</label>
                <div *ngIf="fact.strRegimenFiscal.touched && fact.strRegimenFiscal.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-6" >
              <div class="form-floating mb-3">
                <select class="form-select" id="floatingSelect fs13" aria-label="Floating label select example" [ngClass]="{'is-valid': fact.strMetodoPago.touched && fact.strMetodoPago.valid, 'is-invalid': fact.strMetodoPago.touched && fact.strMetodoPago.invalid}" formControlName="strMetodoPago">
                  <option value="" class="" selected>--Seleccione--</option>
                  <option class="fs13" [value]="col.strClaveSat" *ngFor="let col of arrayFormaPago">{{col.strFormaPagoTipo}}</option>
                </select>
                <label for="floatingSelect">Metodo Pago</label>
                <div *ngIf="fact.strMetodoPago.touched && fact.strMetodoPago.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-6" >
              <div class="form-floating mb-3 col-12">
                <select class="form-select fs13" id="floatingSelect" aria-label="Floating label select example" [ngClass]="{'is-valid': fact.strUsoCfdi.touched && fact.strUsoCfdi.valid, 'is-invalid': fact.strUsoCfdi.touched && fact.strUsoCfdi.invalid}" formControlName="strUsoCfdi">
                  <option value="" class="fs12" selected>--Seleccione--</option>
                  <option class="fs12" [value]="col.strClaveUsoCFDI" *ngFor="let col of arrayCfdi">{{col.strDescripcion}}</option>
                </select>
                <label for="floatingInput">Uso CFDI</label>
                <div *ngIf="fact.strUsoCfdi.touched && fact.strUsoCfdi.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-4" >
              <div class="form-floating mb-3">
                <input type="text" (keydown)="onEnterFact($event)" class="form-control valid fs13" (input)="updateCantidadFact($event)"  formControlName="strCodigoPostal"
                [ngClass]="{'is-valid': fact.strCodigoPostal.touched && fact.strCodigoPostal.valid,'is-invalid': fact.strCodigoPostal.touched && fact.strCodigoPostal.invalid}" [maxlength]="5" autocomplete="new-password" autocorrect="off" 
                autocapitalize="off" spellcheck="false" id="strCodigoPostalF"><!--[(ngModel)]="strCodigoPostal" -->
                  <label for="floatingInput">Código Postal</label>
                  <div *ngIf="fact.strCodigoPostal.touched && fact.strCodigoPostal.invalid" class="text-danger">
                  <div *ngIf="fact.strCodigoPostal.errors?.required">El campo es obligatorio.</div>
                  <div *ngIf="fact.strCodigoPostal.errors?.pattern">Debe ingresar al menos 5 dígitos </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 position-relative" >
             
              <div class="form-floating">
                <input type="text" class="form-control valid fs13" readonly id="strEstado" formControlName="strEstado"
                [ngClass]="{'is-valid': fact.strEstado.touched && fact.strEstado.valid,'is-invalid': fact.strEstado.touched && fact.strEstado.invalid}" ><!--[(ngModel)]="strCodigoPostal" -->
                  <label for="floatingInput">Estado</label>
             
                <div *ngIf="fact.strEstado.touched && fact.strEstado.invalid" class="text-danger">
                  Ingrese codigo postal correcto.
                </div>
              </div>
              <div *ngIf="spinnerCol" class="mb-2 col-12 d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
                <mat-spinner [diameter]="23"></mat-spinner>
              </div>
            </div>
            <div class="col-12 col-lg-4 position-relative" >
              <div class="form-floating mb-3">
                <input type="text" readonly class="form-control fs13" id="Ciudad" formControlName="strCiudad" 
                [ngClass]="{'is-valid': fact.strCiudad.touched && fact.strCiudad.valid,
                'is-invalid': fact.strCiudad.touched && fact.strCiudad.invalid}"><!--[(ngModel)]="strCiudad" -->
                <label for="floatingInput">Ciudad</label>
                <div *ngIf="fact.strCiudad.touched && fact.strCiudad.invalid" class="text-danger">
                  Ingrese codigo postal correcto.
                </div>
              </div>
              <div *ngIf="spinnerCol" class="mb-2 col-12 d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
                <mat-spinner [diameter]="23"></mat-spinner>
              </div>
            </div>
            <div class="col-12 col-lg-5" >
              <div class="form-floating mb-3">
                <select class="form-select fs13" id="floatingSelect" aria-label="Floating label select example" [ngClass]="{'is-valid': fact.strColonia.touched && fact.strColonia.valid, 'is-invalid': fact.strColonia.touched && fact.strColonia.invalid}" formControlName="strColonia">
                  <option value="" selected>--Seleccione--</option>
                  <option [value]="col.strColonia" *ngFor="let col of arrayColonias">{{col.strColonia}}</option>
                </select>
                <label for="floatingSelect">Colonia</label>
                <div *ngIf="fact.strColonia.touched && fact.strColonia.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
          
              </div>
            </div>
            <div class="col-7" >
              <div class="form-floating mb-3">
                <input type="text" class="form-control fs13" id="Direccion" formControlName="strDireccion"
                [ngClass]="{'is-valid': fact.strDireccion.touched && fact.strDireccion.valid,
                'is-invalid': fact.strDireccion.touched && fact.strDireccion.invalid}"><!--[(ngModel)]="strDireccion" -->
                <label for="floatingInput">Dirección (Calle y número)</label>
                <div *ngIf="fact.strDireccion.touched && fact.strDireccion.invalid" class="text-danger">
                  El campo es obligatorio.
                </div>
              </div>
            </div>










        
         
          </form>
        </div>
        






      </div>
      <div class="col-12">
        <h1 class="my-3 fw-bold text-center nunito">Pago</h1>
      </div>
      <div class="row">
          <div class="col-12">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <i class="fa-regular fa-credit-card px-2" style="font-size: 20px;"></i> Pagos con tarjeta de crédito y débito
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div id="cardPaymentBrick_container"></div></div>
                </div>
              </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed  " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <img src="assets/logos/logo-aplazo-color.svg" alt="Aplazo" class="aplazo-icon" style="width: 10%;" > Pago con Aplazo
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">

                    <div class="button-container"> 
                      <button class="aplazo-button" (click)="fnGetToken_aplazo()" [disabled]="!formEntrega.valid">
                        <img src="assets/logos/logo-aplazo-color.svg" alt="Aplazo" class="aplazo-icon"> <b>Paga en 5 cómodas quincenas</b>
                        
                      </button>
                      <br><br>
                    </div>
                    <div class="button-container">   
                      <aplazo-placement
                      price-format="NO-DECIMAL"
                      default-selector="div.test"
                      xs-selector="div.test-1"
                      ignore=".product__price--compare" >
                    </aplazo-placement>
                    </div>
  
                   
                  </div>
                </div>
              </div>  
            </div>
          </div>
      </div>
          <!--<div id="paymentBrick_container"></div>-->
    </div>
   
    <div class="col-12 col-lg-5 mb-3 mb-lg-0  ps-0 pt-3 sticky-top vhAlt section-Product" >
      <div class=" col-12">
          <div class="cart-container">
            <div *ngFor="let item of cartItems" class="cart-item">
              <div class="cart-item-image-container">
                <img [src]="item.strUrlImg" alt="{{ item.strArticulo }}" class="cart-item-image" />
                <span class="cart-item-quantity-badge">{{ item.dblCantidad }}</span>
              </div>
              <div class="cart-item-details">
                <h4 class="cart-item-title">{{ item.strDescripcion1}}</h4>
                <p class="cart-item-weight">{{ item.strArticulo }} {{ item.strOpcion }} {{item.strNombreOpcion}}</p>
              </div>
              <p class="cart-item-price">${{ item.intPrecioLista | number: '1.2-2' }}</p>
            </div>
     
            <div class="cart-summary">
              <p>Subtotal: <span class="subtotal">${{ dblSubTotal | number: '1.2-2' }}</span></p>
              <p>Descuento  ({{objCupon_descuento.strNombre}}) : <span class="monto_descuento"> -{{ dblDescuento | currency }}</span></p>
              <p>Envío: <span class="shipping">{{ dblEnvio | currency }}</span></p>
              <h3>Total: <span class="total">${{ dblTotal | number: '1.2-2' }}</span></h3>
            </div> 
          </div>
        </div>
    </div>
  </div>
</div>



 


