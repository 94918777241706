import { Component, ElementRef, OnInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solicitudes-familias',
  templateUrl: './solicitudes-familias.component.html',
  styleUrls: ['./solicitudes-familias.component.css']
})
export class SolicitudesFamiliasComponent implements OnInit {
 public strConexion: string="";
 public inputSearch: string="";
 public arraySolPendientes:any=[];
 public arrayEstatus:any=[{strEstatus:"PENDIENTE",value:"1"},{strEstatus:"AUTORIZADO",value:"2"},{strEstatus:"CANCELADO",value:"3"}];

 public boolGerente: boolean = true
 public boolSucursal: boolean = true
 public boolAgente: boolean = true
 public isHoveredButton: boolean = true 
 
 public spiner: any = false

 public strGerente: any
 public strEstatus: string="";

 public arrayGerentes: any = [];
 public strSucursal: any
 public arraySucursales: any = [];
 public strAgente: any
 public arrayAgentes: any = [];
 public arrayAccesos:any = [];
public arrayActividades:any=[1,2,3,4,5,6,7]

 public tagName:any;
  constructor(public _serviciosService: PeticionesService, private router: Router,public localSt: LocalStorageService, private _filtroServ :FiltrosService,elem:ElementRef) { 
    this.strConexion = this.localSt.retrieve('strConexion');
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    if(this.arrayAccesos.length>0){

      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      } else {
        this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;

        this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.strSucursal =!this.boolSucursal  ? this.localSt.retrieve('strSucursal'): '';

        this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
       
        this.strAgente=this.boolAgente?'': this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strGerente =(!this.boolGerente && this.boolSucursal )?this.localSt.retrieve('strUsuario'): ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
      }
    }
  }

  ngOnInit(): void {
    this.fngetSolicitudes();
    this.fnGetFiltros();
  }

  fngetSolicitudes() {
    this.spiner=true;
    this._serviciosService.mostrarCotizaciones({
      strSucursal:this.strSucursal,
      strAgente:this.strAgente,
      strGerente:this.strGerente,
      strAccion: 'getSolicitud_ValidacionCte',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arraySolPendientes = result;
        this.arraySolPendientes.forEach(x => {
          let objSuc = this.arraySucursales.find(o => o.strSucursal == x.strSucursal);
          x.strNombreSucursal = objSuc.strNombreSucursal;
        });       
        this.spiner=false;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
      },
      error => {
        var error = <any>error;
        console.log(error);
        this.spiner=false;           
      }
    );
  }
  fnDetalleFam(x:any){
    this.router.navigate(['/detalleLeadFamilia'], { queryParams: { idLead: x.intIdLeads } });

  }
  fnUpdateSol(lead:any,estatus:string) {
    if(estatus=="3"){
      Swal.fire({
        title: "¿DESEA RECHAZAR LA SOLICITUD?",
        showDenyButton: true,
        confirmButtonText: "SI ",
        denyButtonText: `NO`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let objEnviar = {
            strAccion: 'updateSolicitud_ValidacionCte',
            intIdLeads:lead.intIdLeads,
            strEstatus:estatus,
            strConexion: this.strConexion
          }
          this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_modVentas_Leads').subscribe(
            result => {
              let rest = result
              Swal.fire({
                icon: rest[0].code == '200' ? 'success' : 'error',
                title: rest[0].code == '200' ? 'Enviado' : 'Error',
                text: rest[0].strMensaje,
              });
              if(rest[0].code == '200'){
                this.fngetSolicitudes();
              }
            },
            error => {
              var error = <any>error;
              console.log(error);
            }
          ); 
         
        } else if (result.isDenied) {
        }
      });
    }else if(estatus=="2"){
      let objEnviar = {
        strAccion: 'updateSolicitud_ValidacionCte',
        intIdLeads:lead.intIdLeads,
        strEstatus:estatus,
        strConexion: this.strConexion
      }
      this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_modVentas_Leads').subscribe(
        result => {
          let rest = result
          Swal.fire({
            icon: rest[0].code == '200' ? 'success' : 'error',
            title: rest[0].code == '200' ? 'Enviado' : 'Error',
            text: rest[0].strMensaje,
          });
          if(rest[0].code == '200'){
            this.fngetSolicitudes();
          }
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    
    }
  }

  fnGetFiltros(){
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  //console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }

}
