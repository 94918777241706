    <div class="container-fluid text-center vhLogin position-relative imgfond" style="height: 100vh;">
        <div class="row" style="height: 100vh;">
           <!--  <div class="col-12 mx-0 px-0" >
                <img src="assets/Fondos/personal.jpg" class="w-100 " style="height: 100vh;" alt="">
            </div> -->
            <div class="col-12 mx-0 px-0">
                <img src="assets/Fondos/25101.jpg" class="w-100 " style="height: 100vh;" alt="">
            </div>
        </div>
      
        <div class="box_login position-absolute top-50 start-50 translate-middle " >
           <div class="row">
            <div class="col-7 mx-auto">
                <img src="assets/img/logo/ecodeli.png" class="w-100" alt="">
            </div>
           </div>
           <div class="row pt-2">
                <div class="col-12">
                    Por favor, seleccione el módulo al que desea acceder
                </div>
           </div>
            <div class="row d-flex justify-content-evenly my-3 pb-3">
                <div  #miInput (click)="fnSelectEmpresa('COMERCIAL');" [ngClass]="{'hover-sha':selectEmpresa=='COMERCIAL'}" class="col-5  text-dark roundedCard d-flex align-items-center justify-content-center hover-shadow ">
                    <h4 class="fw-bold mb-0 p-1 "  [ngClass]="{'text-dark':selectEmpresa=='COMERCIAL','text-secondary' :selectEmpresa!='COMERCIAL'}">Comercial</h4>
                </div>
                <div (click)="fnSelectEmpresa('DOMINICANA');" [ngClass]="{'hover-sha':selectEmpresa=='DOMINICANA'}" class="col-5 text-dark  roundedCard text-center hover-shadow ">
                    <h4 class="fw-bold mb-0 p-1" [ngClass]="{'text-dark':selectEmpresa=='DOMINICANA','text-secondary' :selectEmpresa!='DOMINICANA'}">Republica Dominicana</h4>
                </div>
            </div>
            <form action="">
            <div class="row justify-content-center">
                <div class="col-10">
                    <mat-form-field appearance="outline" class="w100 bgwhite rounded" >
                        <mat-label>Usuario</mat-label>
                        <input matInput type="text" #inputUser  [(ngModel)]="strUsuario" class="" autocomplete="current-username" >
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row justify-content-center">
             
 

                <div class="col-10">
                    <div class="example-container">
                       
                        <mat-form-field appearance="outline" class="w100 bgwhite rounded">
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="strPass" class="inputL" autocomplete="current-password" >          
                            <i  class="fs-4 pointer bi " matSuffix (click)="hide = !hide" [ngClass]="{'bi-eye-fill': hide,'bi-eye-slash-fill':!hide}"></i>       
                        </mat-form-field>
                    
                    </div>
                </div>
            
            </div>
        </form>
            <br>
            <div class="row">
                <div class="col-12">
                    <button (click)="fnGetEncriptado()" class="btn btnLogin rounded-pill shadow-sm" [disabled]="selectEmpresa=='' || strUsuario=='' || strPass==''">Iniciar Sesión</button>
                </div>
                <!-- <div class="col-12 my-3">
                    <span>
                        Conviértete en Partner y gana más haz <a [routerLink]="['/partner-register']">click aquí</a> 
                    </span>
                </div> -->
            </div>
        </div>
    </div>
