<div class="container-fluid imgfondo">

    <div class="row my-auto h-100">
      <div class="col-12 px-0">
        <div class=" h-100 partner-dashboard-container w-100 d-flex flex-column justify-content-center">
          <div class="my-auto"></div>
          <div class=" px-2 px-lg-4 w-100 mt-5">
            <div class="welcome-banner">
              <h1>¡BIENVENIDO, <span class="partner-name text-nowrap">{{objPartner.strNombre}} {{objPartner.strApellido}}</span>!</h1>
            </div>
          </div>
          <div class="menu-container">
            <!--<button class="menu-button">
              <i class="fa fa-chart-line"></i> <span class="fsSpan">DESEMPEÑO</span> 
            </button>-->
            <!--<button class="menu-button"  (click)="ir('/partner-beneficios')">
              <i class="fa fa-gift"></i> <span class="fsSpan">BENEFICIOS</span> 
            </button>-->
            <button class="menu-button" (click)="ir('/cotizador_Linea')">
              <i class="fa fa-shopping-cart" ></i> <span class="fsSpan">COMPRAR</span> 
            </button>
            <button class="menu-button"  (click)="ir('/partner-recompensas')">
              <i class="fa fa-trophy"></i> <span class="fsSpan">RECOMPENSAS</span> 
            </button>
           <!-- <button class="menu-button">
              <i class="fa fa-calculator"></i> <span class="fsSpan">COTIZADOR</span> 
            </button>-->
            <button class="menu-button" (click)="ir('/partner-cuenta')">
              <i class="fa fa-user"></i> <span class="fsSpan"  >MI CUENTA</span> 
            </button>
            <button class="menu-button" (click)="ir('/partner-clientes')">
              <i class="bi bi-archive-fill"></i> <span class="fsSpan"  >COTIZADOR</span> 
            </button>
            <button class="menu-button" >
              <i class="fa fa-box"></i> <span class="fsSpan" (click)="ir('/partner-mispedidos')">MIS PEDIDOS</span> 
            </button>
          </div>
          <div class="my-auto"></div>
        </div>
      </div>
    </div>
  </div>


  