import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccesosServiceService {
  private accessosSubject = new BehaviorSubject<any[]>([]);
  public accesoss$ = this.accessosSubject.asObservable();

  constructor(private localSt: LocalStorageService) {
    const storedAccesos = this.localSt.retrieve('arrayAccesos')|| [];
    this.accessosSubject.next(storedAccesos);
   }

   updateAccesos(newAccesos:any){
    this.accessosSubject.next(newAccesos);
    this.localSt.store('arrayAccesos',newAccesos);
   }
}
