import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';

export interface tareasCte {
  strCliente: string;
  strMeses: string;
  strCompras: string;
}
@Component({
  selector: 'app-clientes-faltantes',
  templateUrl: './clientes-faltantes.component.html',
  styleUrls: ['./clientes-faltantes.component.css']
})
export class ClientesFaltantesComponent implements OnInit {
  public inputSearch:string="";
  public isHoveredButton: boolean = false;
  public strConexion:string="";
  public arrayAgentes:any=[];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[]; 
  public strGerente: string="";
  public arrayAccesos:any=[];
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public tagName:any;
  public spiner:boolean=false;
  public arrayCtesFaltantes:any=[];
  dataSourceCtes: MatTableDataSource<tareasCte>;
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  columnsTareasCte: string[] = ['strCliente', 'strMeses', 'strCompras'];
  constructor(private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, 
    elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
     private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
    this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this._httpService.setMostrarMenu(true);
    this.indexedDBService.getItem('arrayResult', (value) => {
    this.arrayAccesos=value?value:[];
    if (this.arrayAccesos.length > 0){
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? objAcceso[0].strUsuario : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
      }
     }
     else {
      this.router.navigate(['/login']);
     }
    });
  }

  ngOnInit(): void {
    this.fnGetFiltros()
 
  }
  fnTabletareasCte() {
    this.dataSourceCtes.paginator = this.paginadorTareasCte;
    this.dataSourceCtes.sort = this.sortTareasCte;
  }

  fnGetFiltros() {
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  fnFiltrarPorAcceso(){
    this.arrayCtesFaltantes =[];
    if( this.strAgente != ""){
      this.spiner = true;
      let obj = {
         strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""),   
      };
      this._httpService.getpagViejitaPlantillas(obj,'getReporteCtesVentaPeriodo').subscribe(
        result => {  
          console.log(result)
          this.arrayCtesFaltantes = result
          this.dataSourceCtes = new MatTableDataSource(this.arrayCtesFaltantes);
          this.fnTabletareasCte();
          this.spiner = false; 
        },
        error => {
          this.spiner = false;
          console.error('Error al consumir la API:', error);
          // Puedes manejar el error de alguna manera específica aquí
        });
    }else{
      Swal.fire('Error','Debe seleccionar un agente','error')
    }
  
  }

  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceCtes.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceCtes.paginator) {
      this.dataSourceCtes.paginator.firstPage();
    }
  }

  

}
