<div class="container-fluid">

    <div class="row">
      <div class="col-12">
        <div class="product-line-container">
          <h2>Inscripción a Concurso</h2>
            <form> 
                <div class="row">
                    <div class="col-6">
                        <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Agente</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                            
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputlinea" class="form-label">Linea</label>
                           
                            <select class="form-select" aria-label="Default select example" id="exampleInputlinea">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select> 
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Nombre Agente</label>
                        <input type="text" class="form-control" id="exampleInputPassword1">
                        </div>

                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Minimo</label>
                            <input type="text" class="form-control" id="exampleInputPassword1">
                        </div>
                    </div>
                                 

                </div>
                <button type="submit" class="btn btn-primary">Submit</button>   
            </form>
         
        </div>
      </div>
    </div>
  </div>
   
