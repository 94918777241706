import { Component, OnInit,HostListener, ElementRef  } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { LocalStorageService } from 'ngx-webstorage';
import {MatPaginator,MatPaginatorIntl } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';
@Component({
  selector: 'app-reporte-principal',
  templateUrl: './reporte-principal.component.html',
  styleUrls: ['./reporte-principal.component.css']
})
export class ReportePrincipalComponent implements OnInit {
  public arrayPeriodo:any = [];
  public arraySucursales:any=[];
  public arrayAgentes:any=[];
  public arrayDiasHabiles:any=[];
  public arrayEjercicio:any=[];
  public arrayClientesPorLinea = [];
  public arrayCarteraRezagada = [];
  public arrayCajas:any=[];
  public arrayCuentasPorpagar:any= [];
  public arrayvtaHoy:any= [];
  public arraySaldosBancos:any = [];
  public arrayTipoCliente:any = [];
  public arraycte4500:any = [];
  public arraycte10000:any = [];
  public arraycte50000:any = [];
  public arraycte100000:any = [];
  public arraycte150000:any = [];
  public arraycte250000:any = [];

  public arrayCteNuevos:any = [];
  public arrayInventarioideal:any = [];
  public arrayOtrosCostos:any=[];
  public arrayEstadoResultados:any=[]; 
  public arrayVentaVendedor:any=[];
  public arrayAccesos:any=[];
  public arrayAccesosMenu:any=[];
  public arrayGerentes:any=[];
  public arrayVentaHoyClienteD:any=[];
  public arrayVentaHoyCteArt:any=[];
  public arrayVentaNivelNacional:any= [];
  public arrayDetTipCliente:any = [];
  public arrayDetClientesXlinea:any = [];
  public arrayDetCarteraRezagada:any=[];
  public arrayDetCarteraRezagadaSaldo:any=[];
  public arrayDetXpagar:any=[];
  public arrayDetventasPeridas:any=[];
  public arrayDetGastosOp:any=[];
  public arrayDetOtrosCostos:any=[];
  public arrayDetCostosoMov:any=[];
  public arrayDetCostosRem:any=[];

  public objTotalesVenta:any={
    strLinea:"Total", intMonto:0, porMix:0,intMonto2:0, intMargen:0, porMargen:0, porAvance:0, porMix2:0,monto3:0,porAvanceT:0,
  };
  public objDiasInventario = {strLinea: 'TOTAL',Ideal: 0,Real: 0,Diferencia: 0,VueltasAlInventario: 0,Dias: 0,IdealDias: 0
  };

  public dblImporteDistintivoH:number= 0;
  public objInfoTotal:any = {
    dblVentas:0, dblUtilidadBruta:0, porBruto:0,dblGastos:0, dblDepreciacion:0,porGastos:0,dblProductosFinancieros:0,dblOtrosGastos:0,dblImpuestos:0,uneta:0,porUneta:0
  };
  public IntVenta:number=0;
  public ObjetivoVenta:number=0;
  public progreso:number=0;
  public dblMargen:number=0;
  public totalMonto:number=0;
  public intDiasHabiles:number=0;
  public intDiasRecorridos:number=0;
  public dblComodato:any;
  public comodatosKimberly:any;
  public dblRemision:any;
  public dblInventario:any;
  public dateFechaActual:Date= new Date();
  public strSucursal:string="";
  public strGerente:string="";
  public intEjercicio:number=0;
  public intPeriodo:number=0;
  public strConexion:string=""; 
  public intVentaDia:number=0;
  public intCosto:number=0;
  public intMargen:number=0;
  public intSaldoBanco:number=0;
  public totalSaldo:number = 0;
  public totalObjetivo:number = 0;
  public totalCobrado:number = 0;
  public totalFalta:number = 0;
  public totalPorcentaje:number = 0;
  public dblImporteConFactor:number=0;
  public intDiasCartera:number=0;
  public strAgente:string;

  public pagesize:number = 5;
  public ObjModal:any = {
  }
  public  filtroCliente: string = '';
  public currentYear: number;
  public objReporte:any={};
  public  isHoveredButton:boolean=false;

  /*variables  spiners  */
  public spinerModal:boolean=false;
  public spinerInfVentas:boolean=false;
  public spinerTipoClientes:boolean=false;
  public spinerNavTabs:boolean=false;
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public spinerTabEdoResult:boolean=false;
  public spinerMixProd:boolean=false;
  public spinerDiasCart:boolean=false;
  public spinerCajas:boolean=false;
  public strAcceso:string = ""; 
  public strUsuario:string = "";
  public userhttp:any;
  public Acceso:any;
  public con:any;
  public tagName:any;
 

  constructor(private indexedDBService: DataDBService,private _peticionesService: PeticionesService,
    elem:ElementRef,private route: ActivatedRoute,  private router: Router,private localSt:LocalStorageService,private _filtroServ :FiltrosService) {
    this.route.queryParams.subscribe(params => {
      this.userhttp = params['userhttp'];
      this.Acceso = params['Acceso'];
      this.con = params['method'];
    });
    this.tagName= elem.nativeElement.tagName.toLowerCase();

}

  ngOnInit(): void {
 
    this.intPeriodo = this.dateFechaActual.getMonth() + 1;
    this.intEjercicio =  this.dateFechaActual.getFullYear();
    this.arrayEjercicio.push(this.intEjercicio,this.intEjercicio - 1,this.intEjercicio - 2,this.intEjercicio - 3,this.intEjercicio - 4,this.intEjercicio - 5,this.intEjercicio - 6)
    this.currentYear = new Date().getFullYear();
    
 
    if(this.Acceso == 1){
      this.strAcceso = this.Acceso
      this.strUsuario = this.userhttp 
      this.strConexion = this.con
      this._peticionesService.setMostrarMenu(false);
      this.boolGerente = true;
      this.boolSucursal =true;
      this.objReporte = {strAccion:"",strSucursal:this.strSucursal,strGerente:this.strGerente,strConexion:this.strConexion,intPeriodo:this.intPeriodo,intEjercicio:this.intEjercicio};
  
      this.fnGetFiltros()
      this.fngetDiasHabiles();  
    }else{
      
      this._peticionesService.setMostrarMenu(true);
      //this.arrayAccesos = this.localSt.retrieve('arrayAccesos'); 
      //this.strAcceso = this.arrayAccesos[0].strTipoAcceso
     // this.strUsuario = this.arrayAccesos[0].strUsuario 
     // this.strConexion = this.localSt.retrieve('strConexion');
      
      //this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];



      /*this.indexedDBService.usuario_$.subscribe((nuevoValor) => {
        this.strUsuario=nuevoValor?nuevoValor.strUsuario:'';
        
      });*/

      this.indexedDBService.getItem('arrayResult', (value) => {
        this.strUsuario  =value[0].strUsuario;;
        console.log(' CARGA usuario  reporte principal')
        
        this.arrayAccesos=value?value:[];
        this.strAcceso = value.length>0?this.arrayAccesos[0].strTipoAcceso:'';
        console.log(' CARGA arrayaccesos reporte principal')
        if(this.arrayAccesos.length>0){
          console.log('se ejecuta array accesos de reporte principal')
          var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
          if(objAcceso==undefined||objAcceso.length==0){
            this.router.navigate(['/login']);
          }else{
            this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
            this.strGerente = this.boolGerente?'':this.localSt.retrieve('strUsuario');
            this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
            this.strSucursal =this.boolSucursal?'':this.localSt.retrieve('strSucursal');
    
            this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
            this.strAgente= this.boolAgente?'':this.localSt.retrieve('strAgente');
         
               //------------conexi+on-------------------
               this.indexedDBService.getItem('strConexion', (value) => {
                this.strConexion =value;
                console.log(' CARGA conexion reporte principal')
           
                this.objReporte = {strAccion:"",strSucursal:this.strSucursal,strGerente:this.strGerente,strConexion:this.strConexion,intPeriodo:this.intPeriodo,intEjercicio:this.intEjercicio};
                this.fnGetFiltros()
                this.fngetDiasHabiles();  
              });

             
        }
      }
    
      });

      //-----------------------array accesos
    

     
      
   

    
    }

  }

  fngetDiasHabiles(){
    this.objReporte.strAccion="getDiasHabiles",
   
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arrayDiasHabiles = result;
        this.intDiasHabiles = this.arrayDiasHabiles[0].intDiasHabiles ? this.arrayDiasHabiles[0].intDiasHabiles :0;
        this.intDiasRecorridos = this.arrayDiasHabiles[0].intDiasRecorridos ? this.arrayDiasHabiles[0].intDiasRecorridos :0;
        this.fnexecuteFilt();
      },error => {
        console.log("Error en: "+ error)
      }
    ); 
}

 /*  fnGetGerentes(){
    this.objReporte.strAccion="getGerentes"
    this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
      result => { 
        this.arrayGerente=result; 
        this.arrayGerente.unshift({strGerente: '', strNombreProveedor: '--Todos--'})
      
        this.fnSelects()
      }
    );
  } */

  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      console.log('regresa del filtro')
   
      this.arrayAgentes = nuevoValor;
    }); 

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });
 
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    }); 
  }
  


  fnGetSucursales(gerente:string){  
    this.objReporte.strAccion="getSucursales";
    this.objReporte.strGerente=gerente;
    
    this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
      result => {  
        this.arraySucursales=result; 

        this.fnSelects();
      }
    );
    }

    fnSelects(){
      this.objReporte.strSucursal=this.strSucursal;
      this.objReporte.strGerente=this.strGerente;
      this.objReporte.strConexion=this.strConexion;
      this.objReporte.intPeriodo=this.intPeriodo;
      this.objReporte.intEjercicio=this.intEjercicio;
    }

    fnabrirModal(x:string,objTipoCte:any={},intMes:number=0,tipocte:string=""){
      this.ObjModal={}
      if(x=="infNaVtas"){
        this.fngetVentaHoy(x);
      }else if(x=="tipoCte"){
        let accion = "";
        accion = tipocte == "7500"?"getClientes4500Detalle":tipocte =="Nuevos"  ?
        "getClientesNuevosDetalle":tipocte =="Total"  ?"getClientesTotalDetalle":
        "getClientesXTipoDetalle";
        this.fngetdet4500(objTipoCte,accion,intMes,tipocte) ;
      }else if(x=="ctePorLinea"){
        this.fngetctesXlinea(objTipoCte.strLinea,intMes) ;
      }else if(x=="detCarterraRezagada"){
        this.fngetDetalleCarteraRezagada(this.strSucursal,this.strGerente,tipocte);
      }else if(x=="cuentasxpagar"){
        this.fngetdetCxPagar(tipocte,intMes);
      }else if(x=="gastoOperacion"){
        this.fngetDetGastosOp(intMes);
      }else if(x=="cajaComodatos"){
        this.fngetDetOtrosCostos();
      }else if(x=="movInventario"){
        this.fngetDetCostosMov()
      }else if(x=="cajaRemisiones"){
        this.fngetDetGastosRemisiones();
      }else if(x=="ventasPerdidas"){
        this.fngetDetVentasPerdidas();
      }
      

    }

    fnverDet(i:number,objc:any,strTipo:string="",strCte:string=""){
      this.ObjModal.datos.forEach((x,index) => {
        if(index==i){
          if( x.seeDet == false){
            x.seeDet = true;
           
            if(strTipo == "carterarez"){
              this.fngetdetCarteraCliente(x.strTipo,strCte);
            }else{
              this.fngetVentaHoyDetC(objc);
            }
          }else{
            x.seeDet = false;
          }
        }else{
          x.seeDet = false;
        }
      });
    }

    fnverDetListVxc(i:number,objc:any){
      this.arrayVentaHoyClienteD.forEach((x,index) => {
        if(index==i){
          if( x.seeDet == false){
            x.seeDet = true;
            this.fngetVentaHoyDetD(objc);
          }else{
            x.seeDet = false;
          }
        }else{
          x.seeDet = false;
        }
      });
    }

    fnvtaHoy(){
      this.spinerInfVentas = true;
      this.objReporte.strAccion="getVentaHoy",
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
      this.arrayvtaHoy = result;
      if(this.arrayvtaHoy && this.arrayvtaHoy.length>0) {
          this.intVentaDia = this.arrayvtaHoy[0].dblVenta
          this.intCosto = this.arrayvtaHoy[0].dblCosto
          this.intMargen = this.arrayvtaHoy[0].dblMargen
      }
      this.spinerInfVentas = false;
       }, error => {
        console.log('Error en la solicitud HTTP:', error);
        this.spinerInfVentas = false;
       }
       ); 
    }

    fnSaldoBancos(){
      this.objReporte.strAccion="getSaldoBancos";
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
       result => {
         this.arraySaldosBancos = result;
         this.intSaldoBanco = this.arraySaldosBancos[0].dblSaldo
       }); 
    }

    fncteXlinea(){ 
      this.spinerNavTabs = true;
       this.objReporte.strAccion="getClientesXLinea",
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayClientesPorLinea = result;
          this.spinerNavTabs = false;
        },error => {
          console.log("Error en: "+error);
           this.spinerNavTabs=false;
          }); 
     }
  
     fnget4500(){
      this.spinerTipoClientes=true;
      this.objReporte.strAccion="getClientes4500",
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
       result => {
         this.arraycte4500 = result;
         this.spinerTipoClientes=false;
       },error => {
       console.log("Error en: "+error.message);
        this.spinerTipoClientes = false;
       }
       ); 
    }

    fnget10000(){
      this.spinerTipoClientes=true;
      this.objReporte.strAccion="getClientes4500",
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
       result => {
         this.arraycte10000 = result;
         this.spinerTipoClientes=false;
       },error => {
       console.log("Error en: "+error.message);
        this.spinerTipoClientes = false;
       }
       ); 
    }

    fnget50000(){
      this.spinerTipoClientes=true;
      this.objReporte.strAccion="getClientes4500",
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
       result => {
         this.arraycte50000 = result;
         this.spinerTipoClientes=false;
       },error => {
       console.log("Error en: "+error.message);
        this.spinerTipoClientes = false;
       }
       ); 
    }
    fnget100000(){
      this.spinerTipoClientes=true;
      this.objReporte.strAccion="getClientes4500",
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
       result => {
         this.arraycte100000 = result;
         this.spinerTipoClientes=false;
       },error => {
       console.log("Error en: "+error.message);
        this.spinerTipoClientes = false;
       }
       ); 
    }
    fnget150000(){
      this.spinerTipoClientes=true;
      this.objReporte.strAccion="getClientes4500",
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
       result => {
         this.arraycte150000 = result;
         this.spinerTipoClientes=false;
       },error => {
       console.log("Error en: "+error.message);
        this.spinerTipoClientes = false;
       }
       ); 
    }
    fnget250000(){
      this.spinerTipoClientes=true;
      this.objReporte.strAccion="getClientes4500",
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
       result => {
         this.arraycte250000 = result;
         this.spinerTipoClientes=false;
       },error => {
       console.log("Error en: "+error.message);
        this.spinerTipoClientes = false;
       }
       ); 
    }



    fngetCteXtipo(){
      this.spinerTipoClientes=true;
        this.objReporte.strAccion="getClientesXTipo",
        this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayTipoCliente = result;
          
          this.spinerTipoClientes=false;
        },error => {
          console.log("Error en: "+error.message);
           this.spinerTipoClientes=false;
        }
          ); 
     }

    fngetCtenuevos(){
      this.spinerTipoClientes=true;
        this.objReporte.strAccion="getClientesNuevosTotal";
        this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayCteNuevos = result;
          this.spinerTipoClientes=false;
        }, error => {
          console.log("Error en: "+error.message);
          this.spinerTipoClientes=false;
        });
    }

    fngetEdoResultados(){ 
      this.objReporte.strAccion="getEstadodeResultados";
      this.spinerTabEdoResult = true;
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
      result => {
        this.arrayEstadoResultados = result;
        this.arrayEstadoResultados.forEach(o => {
          o.strPeriodo=o.intPeriodo==1?"Enero":o.intPeriodo==2?"Febrero":o.intPeriodo==3?"Marzo":o.intPeriodo==4?"Abril":
          o.intPeriodo==5?"Mayo":o.intPeriodo==6?"Junio":o.intPeriodo==7?"Julio":o.intPeriodo==8?"Agosto":o.intPeriodo==9?"Septiembre":
          o.intPeriodo==10?"Octubre":o.intPeriodo==11?"Noviembre":o.intPeriodo==12?"Diciembre":"";
          o.porBruto = o.dblVentas>0?  (o.dblUtilidadBruta/o.dblVentas) * 100 :0;
          o.porGastos = o.dblVentas>0?  (o.dblGastos/o.dblVentas)*100 :0;
          o.uneta = o.dblUtilidadBruta - o.dblGastos - o.dblDepreciacion + o.dblProductosFinancieros - o.dblOtrosGastos - o.dblImpuestos
          o.porUneta = o.dblVentas>0?  ((o.dblUtilidadBruta - o.dblGastos  + o.dblProductosFinancieros - o.dblOtrosGastos - o.dblImpuestos)/o.dblVentas) * 100 :0;
      });
      this.objInfoTotal.dblVentas= this.arrayEstadoResultados.reduce((total, estadoResultado) => total + estadoResultado.dblVentas, 0);
      this.objInfoTotal.dblUtilidadBruta= this.arrayEstadoResultados.reduce((total, estadoResultado) => total + estadoResultado.dblUtilidadBruta, 0);
      this.objInfoTotal.porBruto = (this.objInfoTotal.dblUtilidadBruta /  this.objInfoTotal.dblVentas)*100
      this.objInfoTotal.dblGastos= this.arrayEstadoResultados.reduce((total, estadoResultado) => total + estadoResultado.dblGastos, 0);
      this.objInfoTotal.dblDepreciacion= this.arrayEstadoResultados.reduce((total, estadoResultado) => total + estadoResultado.dblDepreciacion, 0);
      this.objInfoTotal.porGastos= (this.objInfoTotal.dblGastos/ this.objInfoTotal.dblVentas)*100
      this.objInfoTotal.dblProductosFinancieros= this.arrayEstadoResultados.reduce((total, estadoResultado) => total + estadoResultado.dblProductosFinancieros, 0);
      this.objInfoTotal.dblOtrosGastos= this.arrayEstadoResultados.reduce((total, estadoResultado) => total + estadoResultado.dblOtrosGastos, 0);
      this.objInfoTotal.dblImpuestos= this.arrayEstadoResultados.reduce((total, estadoResultado) => total + estadoResultado.dblImpuestos, 0);
      this.objInfoTotal.uneta=  this.objInfoTotal.dblUtilidadBruta - this.objInfoTotal.dblGastos - this.objInfoTotal.dblDepreciacion + this.objInfoTotal.dblProductosFinancieros - this.objInfoTotal.dblOtrosGastos - this.objInfoTotal.dblImpuestos
      this.objInfoTotal.porUneta=((this.objInfoTotal.dblUtilidadBruta - this.objInfoTotal.dblGastos  + this.objInfoTotal.dblProductosFinancieros - this.objInfoTotal.dblOtrosGastos - this.objInfoTotal.dblImpuestos) /  this.objInfoTotal.dblVentas)*100
        this.spinerTabEdoResult=false;
      },error => {
        console.log("Error en: "+error);
         this.spinerTabEdoResult=false;
        }
      ); 
  }

    fngetInventarioIdeal(){
      this.spinerNavTabs=true
      this.objDiasInventario.Ideal= 0;
      this.objDiasInventario.Real= 0;
      this.objDiasInventario.Diferencia = 0;
      this.objDiasInventario.VueltasAlInventario=0;
      this.objDiasInventario.Dias= 0;
      this.objDiasInventario.IdealDias= 0;
      this.objReporte.strAccion="getInventarioIdeal",
       this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
       result => {
         this.arrayInventarioideal = result;
         this.arrayInventarioideal.forEach(o => {
          o.intDiferencia = (o.dblInventarioIdeal ? o.dblInventarioIdeal:0) - (o.dblCostoTotal ? o.dblCostoTotal:0) 
          o.intVueltaInventario = ((o.dblCostoTotal * o.intDias)/o.dblInventarioIdeal) 
          o.dias = (o.dblCostoTotal * o.intDias) / o.dblInventarioIdeal ;
          this.objDiasInventario.Ideal += o.dblInventarioIdeal;
          this.objDiasInventario.Real += o.dblCostoTotal;
          this.objDiasInventario.Diferencia += o.intDiferencia;
         
         
          this.objDiasInventario.IdealDias += o.intDias;
         });
         this.objDiasInventario.VueltasAlInventario =  (this.objDiasInventario.Real * this.objDiasInventario.IdealDias) /this.objDiasInventario.Ideal ;
         this.objDiasInventario.Dias =this.objDiasInventario.VueltasAlInventario   ;
         this.spinerNavTabs=false
       },error => {
        console.log("Error en: "+ error)
        this.spinerNavTabs=false
       }); 
    }

     fncteCarteraRezagada(){
      this.spinerNavTabs = true;
      this.totalSaldo = 0;
      this.totalObjetivo = 0;
      this.totalCobrado =0;
      this.totalFalta= 0;
      this.totalPorcentaje= 0;
      this.objReporte.strAccion = "getCarteraRezagada";
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayCarteraRezagada = result;
          this.arrayCarteraRezagada.forEach(o => {
            o.intPorcentaje = o.dblObjetivo>0?((o.dblCobrado / o.dblObjetivo) * 100):0;
            this.totalSaldo += o.dblSaldo;
            this.totalObjetivo += o.dblObjetivo;
            this.totalCobrado += o.dblCobrado;
            this.totalFalta += o.dblObjetivo - o.dblCobrado;

           });
           this.totalPorcentaje = this.totalObjetivo>0?((this.totalCobrado / this.totalObjetivo) * 100):0;
           this.spinerNavTabs=false;
        },error =>{
          console.log("Error en: "+ error);
          this.spinerNavTabs=false;
        }
        );
      }

     fngetCajas(){
      this.spinerCajas = true;
      this.objReporte.strAccion ="getCajas"
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayCajas = result;
          this.spinerCajas = false;
        },error =>{
          console.log("Error en: "+ error);
          this.spinerCajas = false;
        }); 
     }

     fngetCxp(){
      this.spinerNavTabs=true;
      this.objReporte.strAccion ="getCXP";
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayCuentasPorpagar = result;
          this.arrayCuentasPorpagar.forEach( x => {
            x.totalcxp=  (x.dblCorriente?x.dblCorriente:0) +(x.dbl15?x.dbl15:0) + (x.dbl30?x.dbl30:0)  + (x.dbl60?x.dbl60:0) +(x.dbl90?x.dbl90:0)+(x.dblMayor?x.dblMayor:0)
          });
          this.spinerNavTabs=false;
        },error =>{
          console.log("Error en: "+ error);
          this.spinerNavTabs=false;
        }
        ); 
     }

     fngetDiasCartera() {
      this.spinerDiasCart=true;
      this.objReporte.strAccion = "getDiasCartera";
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.intDiasCartera = result[0].intDiasCartera ? result[0].intDiasCartera:0 ;
          this.spinerDiasCart=false;
        },error =>{
          console.log("Error en: "+ error);
          this.spinerDiasCart=false;
        }); 
     }


     fngetcovid() {
      this.objReporte.strAccion ="getDistintivoH";
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.dblImporteDistintivoH = result[0].dblImporte?result[0].dblImporte:0;
        }); 
     }

     fngetctesXlinea(strLinea:string,intMes:number) {
      this.spinerModal = true;
      this.objReporte.strAccion ="getClientesXLineaDetalle";
      this.objReporte.strLinea =strLinea;
      this.objReporte.intPeriodo =intMes
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayDetClientesXlinea = result;
          this.ObjModal = {
            Titulo:"CLIENTES POR LINEA",
            Subtitulo: "LISTADO DE CLIENTES POR LINEA",
            datos:this.arrayDetClientesXlinea,
            totalmov:this.arrayDetClientesXlinea.length,
            importetotal:0,
            strMdal:'ctePorLinea',
            strTipoCte:strLinea,
            intPeriodo:intMes
          }
          this.spinerModal = false;
        },error => {
          console.log("error en: "+error)
          this.spinerModal = false;
        }); 

     }
     
     
     fngetPerdidaSuc() {
      this.objReporte.strAccion ="getPerdidaSucursal";
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.dblImporteConFactor = result[0].dblImporteConFactor?result[0].dblImporteConFactor:0;
        }); 
     }

      fngetOtrosCostos() {
        this.spinerCajas = true;
        this.objReporte.strAccion = "getOtrosCostos";
        this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayOtrosCostos = result;
          this.dblComodato = this.arrayOtrosCostos[0].dblComodato?this.arrayOtrosCostos[0].dblComodato:0
          this.dblRemision = this.arrayOtrosCostos[0].dblRemision?this.arrayOtrosCostos[0].dblRemision:0
          this.dblInventario = this.arrayOtrosCostos[0].dblInventario?this.arrayOtrosCostos[0].dblInventario:0
         this.comodatosKimberly = this.arrayOtrosCostos[0].dblComodatoKimberly?this.arrayOtrosCostos[0].dblComodatoKimberly:0 
          this.spinerCajas = false;
        },error =>{
          console.log("Error en: "+ error);
          this.spinerCajas=false;
        }); 
      }

     fngetVenta(){
      this.spinerMixProd = true;
      this.objTotalesVenta.intMonto = 0;
      this.objTotalesVenta.porMix = 0;
      this.objTotalesVenta.intMonto2 = 0; 
      this.objTotalesVenta.intMargen = 0;
      this.objTotalesVenta.porMargen = 0; 
      this.objTotalesVenta.porAvance = 0; 
      this.objTotalesVenta.porMix2 = 0;
      this.objTotalesVenta.monto3 = 0;
      this.objTotalesVenta.porAvanceT = 0;
      this.IntVenta=0;
      this.ObjetivoVenta=0;
      this.progreso=0;
      this.dblMargen=0;
      this.totalMonto=0;
      this.objReporte.strAccion ="getVentaVendedor";
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayVentaVendedor = result;
           this.arrayVentaVendedor.forEach(o => {
            this.IntVenta += o.dblImporte;
            this.ObjetivoVenta += o.dblObjetivo;
            o.PorcentajeMargen =o.dblImporte>0? ( o.dblMargen/o.dblImporte)*100 :0;
            o.PorcentajeAvance1 = o.dblObjetivo>0?(o.dblImporte / o.dblObjetivo ) * 100:0;
            o.Monto3 = (o.dblObjetivo / this.intDiasHabiles) * this.intDiasRecorridos;
            o.PorcentajeAvance2= this.calcular(o.dblImporte, o.dblObjetivo);
          });
          this.arrayVentaVendedor.forEach(o => {
            o.Mix1=(o.dblObjetivo * 100) / this.ObjetivoVenta;
            o.Mix2=  (o.dblImporte / this.IntVenta) * 100
            this.objTotalesVenta.intMonto += o.dblObjetivo;
            this.objTotalesVenta.porMix += o.Mix1;
            this.objTotalesVenta.intMonto2 += o.dblImporte; 
            this.objTotalesVenta.intMargen += o.dblMargen;
            this.objTotalesVenta.porMargen += o.PorcentajeMargen; 
            this.objTotalesVenta.porAvance += o.PorcentajeAvance1; 
            this.objTotalesVenta.porMix2 += o.Mix2;
            this.objTotalesVenta.monto3 += o.Monto3;
      
          }); 
          let avancetotal = (this.objTotalesVenta.intMonto/this.intDiasHabiles) * this.intDiasRecorridos
          this.objTotalesVenta.porAvanceT = (this.objTotalesVenta.intMonto2/ avancetotal) * 100
          this.spinerMixProd = false;
        },error =>{
          console.log("Error en: "+ error);
          this.spinerMixProd=false;
        }); 
     }
     calcular(val: number, val2: number): number {
      let PorcentajeLinea = 0;
      if (val2 > 0) {
        const dblAvanceHoy: number = (val2 / this.intDiasHabiles) * this.intDiasRecorridos;
        PorcentajeLinea = ((val / dblAvanceHoy) * 100);
        PorcentajeLinea = parseFloat(PorcentajeLinea.toFixed(2));
      }
      return PorcentajeLinea;
    }

    fngetVentaHoy(x:string) {
      this.spinerModal=true;
      this.objReporte.strAccion ="getVentaHoyCliente";
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayVentaNivelNacional = result;
          this.ObjModal={
            Titulo:"Información Nacional de Ventas",
            Subtitulo:"LISTADO DE VENTA AL DIA DE HOY",
            datos:this.arrayVentaNivelNacional,
            totalmov:this.arrayVentaNivelNacional.length,
            importetotal:0,
            strMdal:x
          }
          this.arrayVentaNivelNacional.forEach(o => {
            this.ObjModal.importetotal = o.Importe + this.ObjModal.importetotal
            o.seeDet = false;
          });
          this.spinerModal=false;
        },
        error => {
          this.ObjModal={
            Titulo:"Información Nacional de Ventas",
            Subtitulo:error,
            datos:this.arrayVentaNivelNacional,
            totalmov:0,
            importetotal:0,
            strMdal:x
          }
        
        }
        
        ); 
     }

     fngetVentaHoyDetC(objC:any) {
      
      this.objReporte.strAccion ="getVentaHoyMovimiento";
      this.objReporte.strCliente =objC.strCliente;
     
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
        this.arrayVentaHoyClienteD = result;
        this.arrayVentaHoyClienteD.forEach(o => {
          o.seeDet = false
        });
       
        }); 
     }

     fngetVentaHoyDetD(objC:any) {
 
      this.objReporte.strAccion ="getVentaHoyArticulo";
      this.objReporte.intID =objC.intID;
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
        this.arrayVentaHoyCteArt = result;
     
        }); 
        this.objReporte.intEjercicio = this.intEjercicio
     }
     fnImporteTotal():number{
      let respTotal:number=0; 
       this.arrayVentaHoyCteArt.forEach(element => {
      respTotal += element.dblImporteConFactor;
     });
     return respTotal;
     }

     fnImporteTotalDetCte():number{
      let respTotal:number=0; 
       this.arrayVentaHoyClienteD.forEach(element => {
      respTotal += element.dblImporteConFactor;
     });
     return respTotal;
     }

     fnImporteTotalListCte():number{
      let respTotal:number=0; 
       this.ObjModal.datos.forEach(element => {
      respTotal += element.dblImporteConFactor;
     });
     return respTotal;
     }


     fngetdet4500(objC:any,det:string,intmes:number,strTipoCliente:string="") {
      this.spinerModal= true
      this.objReporte.strAccion = det;
      this.objReporte.intPeriodo = intmes; 
      this.objReporte.strTipo = strTipoCliente
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
        this.arrayDetTipCliente = result; 
        this.ObjModal = {
          Titulo:"Tipo clientes",
          Subtitulo: det == "getClientes4500Detalle"?"LISTADO DE CLIENTES ATENDIDOS CON MONTO MAYOR A 4500":det =="getClientesNuevosDetalle"  ?
          "Listado de Clientes Nuevos":det =="getClientesTotalDetalle"  ?"Listado de Clientes Total":
          "LISTADO DE TIPO DE CLIENTES",
          datos:this.arrayDetTipCliente,
          totalmov:this.arrayDetTipCliente.length,
          importetotal:0,
          strMdal:'tipoCte',
          strTipoCte:strTipoCliente,
          intPeriodo:intmes

        }
        this.objReporte.intPeriodo = this.intPeriodo ;
        this.spinerModal= false;
        },error=>{
          console.log("Error en: "+ error);
          this.spinerModal= false;
        }
        ); 
     }

   

     
     fngetfiltrar4500(det:string,intmes:number,strModal:string) { 
      if(this.ObjModal.strMdal== "tipoCte"){
        let strAccion:string,tipocte:string=this.ObjModal.strTipoCte;
        strAccion = tipocte == "7500"?"getClientes4500Detalle":tipocte =="Nuevos"  ?
      "getClientesNuevosDetalle":tipocte =="Total"  ?"getClientesTotalDetalle":
      "getClientesXTipoDetalle";
        this.spinerModal = true
        this.objReporte.strAccion = strAccion;
        this.objReporte.intPeriodo = intmes; 
        this.objReporte.strSucursal = det; 
        this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
          result => {
          this.arrayDetTipCliente = result; 
          this.ObjModal = {
            Titulo:"Tipo clientes",
            Subtitulo: strAccion == "getClientes4500Detalle"?"LISTADO DE CLIENTES ATENDIDOS CON MONTO MAYOR A 4500":strAccion =="getClientesNuevosDetalle"  ?
            "Listado de Clientes Nuevos":strAccion =="getClientesTotalDetalle"  ?"Listado de Clientes Total":
            "LISTADO DE TIPO DE CLIENTES",
            datos:this.arrayDetTipCliente,
            totalmov:this.arrayDetTipCliente.length,
            importetotal:0,
            strMdal:'tipoCte',
            strTipoCte:tipocte,
            intPeriodo:intmes
          }
          this.objReporte.intPeriodo = this.intPeriodo ;
          this.spinerModal= false;
          },error => {
            console.log("Error en: "+ error);
            this.spinerModal = false;
          }
          );
      }else if(this.ObjModal.strMdal== "ctePorLinea"){
        this.spinerModal = false;
        this.objReporte.strAccion = "getClientesXLineaDetalle";
        this.objReporte.intPeriodo =intmes
        this.objReporte.strSucursal =det 
        this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
          result => {
            this.arrayDetClientesXlinea = result; 
            this.ObjModal.datos=this.arrayDetClientesXlinea
            this.ObjModal.totalmov=this.arrayDetClientesXlinea.length
            this.spinerModal = false;
            this.ObjModal.intPeriodo=intmes
          },error => {
            console.log("error en: "+error)
            this.spinerModal = false;
          }); 
      }
     }

     fngetDetalleCarteraRezagada(sucursal:string,gerente:string,tipo:string){
      this.spinerModal = true;
      this.objReporte.strAccion ="getCarteraRezagadaSaldoCliente";
      this.objReporte.strSucursal = sucursal;
      this.objReporte.strGerente = gerente;
      this.objReporte.strTipo = tipo;
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayDetCarteraRezagada = result;
          this.arrayDetCarteraRezagada.forEach(obj => {
            obj.seeDet=false;
          }); 
          this.ObjModal = {
            Titulo:"CARTERA REZAGADA",
            Subtitulo: "LISTADO DE CARTERA REZAGADA",
            datos:this.arrayDetCarteraRezagada,
            totalmov:this.arrayDetCarteraRezagada.length,
            importetotal:0,
            strMdal:'detCarterraRezagada',
          }
          this.spinerModal = false;
        },error => {
          console.log("error en: "+error)
          this.spinerModal = false;
        }); 
     }

     fngetdetCarteraCliente(strTipo:string,strCte:string) {
      this.objReporte.strAccion ="getCarteraRezagadaSaldo";
      this.objReporte.strTipo = strTipo;
      this.objReporte.strCliente = strCte; 
      this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayDetCarteraRezagadaSaldo = result;
          console.log(this.arrayDetCarteraRezagadaSaldo)
        }); 
     }

     fnImporteTotalDetcarterarezagada():number{
       let respTotal:number=0; 
       this.arrayDetCarteraRezagadaSaldo.forEach(element => {
        respTotal += element.dblImporteConFactor;
       });
       return respTotal;
     }

    fngetdetCxPagar(strTipo:string,intDias:number){
      this.spinerModal = true;
      this.objReporte.strAccion ="getCXPDetalle";
      this.objReporte.strTipo =strTipo;
      this.objReporte.intDias =intDias;
      this._peticionesService.getLineasComodatos( this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayDetXpagar = result; 
          this.ObjModal = {
            Titulo:"CUENTAS POR PAGAR",
            Subtitulo: "LISTADO DE CUENTAS POR PAGAR",
            datos:this.arrayDetXpagar,
            totalmov:this.arrayDetXpagar.length,
            importetotal:0,
            strMdal:'cuentasxpagar',
          }
          this.spinerModal = false;
        },error => {
          console.log("error en: "+error)
          this.spinerModal = false;
      }); 
    }



fngetDetGastosOp(intMes:number) {
  this.spinerModal = true;
  this.objReporte.strAccion ="getDetalleGastosOP";
  this.objReporte.intPeriodo = intMes

  this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
    result => {
      this.arrayDetGastosOp = result;    
      this.ObjModal = {
        Titulo:"ESTADO DE RESULTADOS",
        Subtitulo: "DETALLE GASTOS OP",
        datos:this.arrayDetGastosOp,
        totalmov:this.arrayDetGastosOp.length,
        importetotal:0,
        strMdal:'gastoOperacion',
      }
      this.spinerModal = false;
    },
    error => {
      console.log('Error en: '+error)
      this.spinerModal = false;
    }); 
    this.objReporte.intPeriodo = this.intPeriodo
 }

 fngetDetOtrosCostos() {
  this.spinerModal = true;
  this.objReporte.strAccion ="getOtrosCostosComodatosDetalle";
 
  this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
    result => {
      this.arrayDetOtrosCostos = result;
      this.arrayDetOtrosCostos .forEach(o => {
        o.dblImporteConFactor = o.dblCosto
      }); 
       this.ObjModal = {
        Titulo:"COMODATOS",
        Subtitulo: "LISTADO COSTO DE COSTO COMODATOS",
        datos:this.arrayDetOtrosCostos,
        totalmov:this.arrayDetOtrosCostos.length,
        importetotal:0,
        strMdal:'cajaComodatos',
      }
      this.spinerModal = false;
    },
    error => {
      console.log('Error en: '+error)
      this.spinerModal = false;
    }); 
 }

 fngetDetCostosMov() {
  this.spinerModal = true;
  this.objReporte.strAccion ="getOtrosCostosMovDetalle";
 
  this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
    result => {
      this.arrayDetCostosoMov = result;
      this.arrayDetCostosoMov.forEach(o => {
        o.dblImporteConFactor = o.dblCosto
      }); 
       this.ObjModal = {
        Titulo:"CAJAS",
        Subtitulo: "MOVIMIENTO DE INVENTARIO",
        datos:this.arrayDetCostosoMov,
        totalmov:this.arrayDetCostosoMov.length,
        importetotal:0,
        strMdal:'movInventario',
      }
      this.spinerModal = false;
    },
    error => {
      console.log('Error en: '+error)
      this.spinerModal = false;
    }); 
 }

 fngetDetGastosRemisiones() {
  this.objReporte.strAccion ="getCostosRemisionesDetalle";
  this.spinerModal = true;
  this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
    result => {
      this.arrayDetCostosRem = result; 
      this.ObjModal = {
       Titulo:"CAJAS",
       Subtitulo: "LISTADO DE COSTO REMISIONES",
       datos:this.arrayDetCostosRem,
       totalmov:this.arrayDetCostosRem.length,
       importetotal:0,
       strMdal:'cajaRemisiones',
     }
     this.spinerModal = false;
   },
   error => {
     console.log('Error en: '+error)
     this.spinerModal = false;
   }); 
 }

 fngetDetVentasPerdidas() {
  this.objReporte.strAccion ="getReporteVentasPerdidas";
  this.spinerModal = true;
  this._peticionesService.getLineasComodatos(this.objReporte,'sp_ModVentas_ReportePrincipal').subscribe(
    result => {
      this.arrayDetventasPeridas = result;   
      this.ObjModal = {
       Titulo:"CAJAS",
       Subtitulo: "VENTAS PERDIDAS",
       datos:this.arrayDetventasPeridas,
       totalmov:this.arrayDetventasPeridas.length,
       importetotal:0,
       strMdal:'ventasPerdidas',
     }
     this.spinerModal = false;
   },
   error => {
     console.log('Error en: '+error)
     this.spinerModal = false;
   }); 
 }

     fnexecuteFilt(){
      this.fncteCarteraRezagada();
      this.fncteXlinea();
      this.fngetCajas();
      this.fngetCxp();
      this.fnvtaHoy();
      this.fnSaldoBancos();
      this.fnget4500();
      this.fngetCteXtipo();
      this.fngetCtenuevos();
      this.fngetInventarioIdeal();
      this.fngetcovid();
      this.fngetPerdidaSuc();
      this.fngetOtrosCostos();
      this.fngetDiasCartera();
      this.fngetEdoResultados();
      this.fngetVenta();
     }


}

