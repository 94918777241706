import { Component, ElementRef, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { DataDBService } from '../servicios/data-db.service';
import { FiltrosService } from '../servicios/filtros.service';
import { PeticionesService } from '../servicios/httpservice';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface DataItem {
  strCliente: string;
  strMovIDP: string;
  dblImporteF: number;
  dblImporteFactura: number;
  intEjercicio: number;
  intPeriodo: number;
}

@Component({
  selector: 'app-cliente-reporte-excedidos',
  templateUrl: './cliente-reporte-excedidos.component.html',
  styleUrls: ['./cliente-reporte-excedidos.component.css']
})

export class ClienteReporteExcedidosComponent implements OnInit {
  public strConexion:string="";
  public arrayEjercicio:any=[];
  public intEjercicio:number=0;
  public intPeriodo:number; 
  public arrayPeriodo:any= [];
  public strCliente:string="";
  public strFamilia:string="";
  public arrayAccesos:any =[];
  public objCliente:any ={};
  public arrayDatos:any = [];
  public tagName: string;
  public strAgente_Crea: string;
  public strUsuario: string;
  public datosFiltrados:any = [];
  public strAgente: any;
  public spinner:boolean=false
  public boolEditaAgente: boolean = true;
  displayedColumns: string[] = ['strCliente', 'strMovIDP', 'intEjercicio', 'intPeriodo', 'dblImporteFactura', 'dblImporteF'];
  dataSource = new MatTableDataSource<DataItem>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(elem: ElementRef,public router: Router,private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl,private _filtroServ:FiltrosService,private localSt:LocalStorageService,private _httpService:PeticionesService) {
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.arrayAccesos = this.localSt.retrieve('ArrayAccesos');
    this.objCliente = this.arrayAccesos[0]; 
    this.strFamilia = this.objCliente.strFamiliaCte;
    this.strCliente = this.objCliente.strCliente;
    this.strConexion = this.localSt.retrieve('strConexion');
   }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        this.strConexion = this.localSt.retrieve('strConexion');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strAgente = this.localSt.retrieve('strAgente');
        this.strUsuario = this.localSt.retrieve('strUsuario');
        this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;
        //------------conexi+on-------------------
        this.indexedDBService.getItem('strConexion', (value) => {
          this.strConexion = value;
          /*    console.log(' CARGA conexion directorio') */
        });
        this.fnGetDatos();
        this.fnGetFiltros();
      }
      else {
        this.router.navigate(['/login']);
      }
    });
  }
  ngAfterViewInit() {
    this.fnTable()
  }
  fnTable(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fnGetDatos(){
    this.spinner = true;
    let objEnv: {
      intEjercicio: number;
      intPeriodo: number;
      strAccion: string;
      strConexion: string;
      strFamilia?: string;
      strCliente?: string;
    } = {
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo,
      strAccion: "getUniExced",
      strConexion:this.strConexion
    };
    if(this.strFamilia!=""){
      objEnv.strFamilia = this.strFamilia;
    }else{
      objEnv.strCliente =  this.strCliente;
      objEnv.strFamilia =  this.strFamilia;
    }
    console.log(objEnv)
    this._httpService.getClienteDatos(objEnv,"sp_SeguimientoPedidos").subscribe(
      result => {
        console.log(result);
        this.arrayDatos = result
        this.arrayDatos.forEach(ped => {
          ped.strPeriodo =
          ped.intPeriodo == 1 ? "Enero" :
          ped.intPeriodo == 2 ? "Febrero" :
          ped.intPeriodo == 3 ? "Marzo" :
          ped.intPeriodo == 4 ? "Abril" :
          ped.intPeriodo == 5 ? "Mayo" :
          ped.intPeriodo == 6 ? "Junio" :
          ped.intPeriodo == 7 ? "Julio" :
          ped.intPeriodo == 8 ? "Agosto" :
          ped.intPeriodo == 9 ? "Septiembre" :
          ped.intPeriodo == 10 ? "Octubre" :
          ped.intPeriodo == 11 ? "Noviembre" :
          ped.intPeriodo == 12 ? "Diciembre" : "Desconocido";
        });
        this.fnFiltrar()
        this.spinner = false
      },error =>{
        console.log(error.message);
        this.spinner = false
      });
  }
  fnFiltrar(){
    this.datosFiltrados = []

    if(this.intEjercicio == 0  && this.intPeriodo == 0 ){
      this.datosFiltrados = this.arrayDatos
    }else if(this.intEjercicio == 0  && this.intPeriodo != 0 ){
      this.datosFiltrados = this.arrayDatos.filter(o => o.intPeriodo == this.intPeriodo)
    }else if(this.intEjercicio != 0  && this.intPeriodo == 0 ){
      this.datosFiltrados = this.arrayDatos.filter(o => o.intEjercicio == this.intEjercicio)
    }else{
      this.datosFiltrados = this.arrayDatos.filter(o => o.intPeriodo == this.intPeriodo && o.intEjercicio == this.intEjercicio)
    }

    this.dataSource =new MatTableDataSource<DataItem>(this.datosFiltrados);
    this.fnTable();
  }
  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
      console.log(this.arrayEjercicio)
    });
    this.arrayPeriodo.unshift({strPeriodo:0,strNombre:"--Todos--"})
  }
  fnDescargarExcel(){

        // Define los encabezados personalizados
        const headers = [
          "#",
          "Cliente",
          "Sucursal",
          "Ejercicio",
          "Periodo",
          "Importe Con Factor",
          "Presupuesto"
        ];
      
        // Prepara los datos para exportar, agregando índices como "#"
        const dataToExport = this.datosFiltrados.map((item, index) => ({
          "#": index + 1,
          "Cliente": item.strCliente,
          "Sucursal": item.strMovIDP +" "+ item.strOrdenCompraP,
          "Ejercicio": item.intEjercicio,
          "Periodo": item.strPeriodo,
          "Importe Con Factor":"$"+Number(item.dblImporteFactura).toFixed(2),
          "Presupuesto":"$"+Number(item.dblImporteF).toFixed(2)
        }));
      
        // Crea una hoja de cálculo
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, { header: headers });
        
        // Agrega los encabezados personalizados manualmente
        worksheet["!cols"] = headers.map(() => ({ wch: 20 })); // Ajusta el ancho de las columnas opcionalmente
      
        // Crea un libro de trabajo
        const workbook: XLSX.WorkBook = {
          Sheets: { "Venta Por Presupuestos": worksheet },
          SheetNames: ["Venta Por Presupuestos"]
        };
      
        // Genera el archivo Excel en formato binario
        const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      
        // Guarda el archivo con FileSaver
        const data: Blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(data, "ventaPresupuestos.xlsx");
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
