import { Component, OnInit, ElementRef, ViewChild, Inject} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetoTorkBitacoraInicialComponent } from '../reto-tork-bitacora-inicial/reto-tork-bitacora-inicial.component';

import * as moment from 'moment';
import Swal from 'sweetalert2';
import 'moment/locale/pt-br';
import { filter } from 'rxjs/operators';

export interface autenticarPrecios {
  intID: number,
  strMov: string, 
  strMovID: string, 
  strFechaEmision: string, 
  strUsuario: string, 
  strEstatus: string
  strSituacion: string, 
  strCliente: string,
  strAgente: string,
  intIDSucursal: number,
  dblImporte: number,
  intPeriodo: number,
  intEjercicio: number
}

@Component({
  selector: 'app-autpreciomin',
  templateUrl: './autpreciomin.component.html',
  styleUrls: ['./autpreciomin.component.css']
})
export class AutpreciominComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['intID', 'strMovID', 'strUsuario', 'strAgente', 'strCliente', 'strEstatus'];
  
  arrayListado: autenticarPrecios[]=[];
  dataSource: MatTableDataSource<autenticarPrecios>;
  public tagName:any;
  public arrayAccesos:any=[];
  public strAgente:string="";
  public arrayGerentes: any=[];
  public arraySucursales: any=[];
  public arrayAgentes: any=[];
  public agenteSelected:any={};
  public sucursalSelected:any={};
  public selectGte:any=false;
  public selectSuc:any=false;
  public selectAgt:any=false;
  public gteSelected:any='';
  public strGerente:string = '';
  public strTipoAcceso: string="";
  public btnAvanzar: boolean=false;
  public listadoGeneral: boolean=false;
  public strFechaActual:any;
  public intEjercicio: number;
  public intPeriodo: number;  
  public estatusSelected: string='';
  public arrayEstatus: any[]=[
    {value:'', view: "TODOS"},
    {value:'CONCLUIDO', view: "CONCLUIDO"},
    {value:'PENDIENTE', view: "PENDIENTE"},
    {value:'PROCESAR', view: "PROCESAR"},
    {value:'SINAFECTAR', view: "SINAFECTAR"}
  ]
  public periodo: any[] = [
    {value: '', viewValue: 'Todos'},
    {value: '1', viewValue: 'Enero'},
    {value: '2', viewValue: 'Febrero'},
    {value: '3', viewValue: 'Marzo'},
    {value: '4', viewValue: 'Abril'},
    {value: '5', viewValue: 'Mayo'},
    {value: '6', viewValue: 'Junio'},
    {value: '7', viewValue: 'Julio'},
    {value: '8', viewValue: 'Agosto'},
    {value: '9', viewValue: 'Septiembre'},
    {value: '10', viewValue: 'Octubre'},
    {value: '11', viewValue: 'Noviembre'},
    {value: '12', viewValue: 'Diciembre'}
  ];
  public ejercicio: any[] = [
    {value: "", viewValue: "Todos"},
    {value: "2020", viewValue: "2020"},
    {value: "2021", viewValue: "2021"},
    {value: "2022", viewValue: "2022"}
  ];
  

  constructor(public dialog: MatDialog, elem:ElementRef, private _peticionesService: PeticionesService, public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router ) {
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.dataSource = new MatTableDataSource(this.arrayListado);
  }

  ngAfterViewInit() {       
    this.dataSource.paginator = this.paginator!;
    this.dataSource.paginator._intl.itemsPerPageLabel='Documentos por página';         
    this.dataSource.paginator.pageSize=10;      
    this.dataSource.sort = this.sort!;
  }

  
  ngOnInit(): void {
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):{};
    //Crear las variables para mostrar o no los botones o permisos a los que tiene acceso el usuario
    if(this.arrayAccesos.length>0){
      this.strAgente=this.arrayAccesos[0]?this.arrayAccesos[0].strAgente:'';
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }else { 
        const date = moment();
        this.strFechaActual = date;      
        this.intEjercicio= this.strFechaActual.format('YYYY');
        this.intPeriodo=this.strFechaActual.format('M');

        this.strTipoAcceso=objAcceso[0].strTipoAcceso?objAcceso[0].strTipoAcceso:"";
        this.listadoGeneral=objAcceso.find((o:any)=>o.strAccion=='Listado')?true:false;     
        this.btnAvanzar=objAcceso.find((o:any)=>o.strAccion=='Avanzar')?true:false;    
        
        //Filtros:
        this.strGerente= objAcceso[0].strUsuario;
        this.selectGte=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
        this.selectSuc=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.selectAgt=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
   
        if(this.selectSuc){
            this.gteSelected= {strGerente:objAcceso[0].strUsuario };
            this.fnGetSucursales(this.gteSelected);
        }
        if(this.selectGte){   
            this.fnGetGerentes();
        }
        if(this.selectAgt){
            this.sucursalSelected = {strSucursal:objAcceso[0].sucursal};
            this.fnGetAgentes(this.sucursalSelected);
        }
        this.fnGetListado();
      }
    }else{
      this.router.navigate(['/login']);
    }
  }


  fnGetGerentes(){
    this._peticionesService.getApiCompras({
      strAccion: 'getGerentes' 
    }).subscribe(
      result => { 
        this.arrayGerentes=result; 
        this.arrayGerentes.push({strGerente: '', strEstatus: 'Todos'})
      }
    );
  }

  fnGetSucursales(gerente:any){  
    this._peticionesService.getApiCompras({
      strAccion: 'getSucursales' ,
      strGerente:gerente.strGerente
    }).subscribe(
      result => {  
        this.arraySucursales=result; 
      }
    );
  }

  fnGetAgentes(sucursal:any){
    this._peticionesService.getApiCompras({
      strAccion: 'getAgentes' ,
      strSucursal:sucursal.strSucursal
    }).subscribe(
    result => {  
      this.arrayAgentes=result;
      if(this.strAgente){//Si existe el agente, se inicializa en ese agente
        let objAgente=this.arrayAgentes.filter((a:any)=>a.strEstatus == this.strAgente);      
        this.agenteSelected={}
      }
    });  
  }

  fnGetListado(){
    //alert("dio click")
    let objGetListado={
      "strAccion": "getListado",
      "intPeriodo": this.intPeriodo,
      "intEjercicio": this.intEjercicio,
      "strGerente": '',
      "strGerente2": this.gteSelected.strGerente && this.gteSelected.strGerente!='agustin'?this.gteSelected.strGerente:'',
      "strEstatus": this.estatusSelected
    };
    console.log(objGetListado)
    this._peticionesService.getApiComprasGral(objGetListado, 'sp_PreciosMinimos').subscribe( result => {
      this.arrayListado=result;      
      console.log(result);
      
      this.dataSource.data = this.arrayListado as autenticarPrecios [];
      this.dataSource.paginator = this.paginator!;
      this.dataSource.paginator._intl.itemsPerPageLabel='Documentos por página';         
      this.dataSource.paginator.pageSize=10;      
      this.dataSource.sort = this.sort!;
    });
  }  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  fnAbrirDetalle(obj: any){
    console.log(obj);
    const dialogRef = this.dialog.open(DialogPreciosMinimos, {data:obj});
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.fnGetListado();
    });
  }

}

@Component({
  selector: 'dialog-precios-minimos-content',
  templateUrl: 'dialog-precios-minimos-content.html',
})

export class DialogPreciosMinimos {
  public intID: number=0;
  public arrayDetalle: any=[];
  public arrayRespuesta: any=[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialog, private _peticionesService: PeticionesService, private router: Router, private localSt:LocalStorageService,) { 
    console.log(this.data);
    if(this.data!=undefined){
      this.intID=this.data.intID;
    }
  }

  ngOnInit(): void {
    //alert("entro al detalle")
    this.fnDetalle(this.intID);
  }
  
  fnDetalle(id: number){
    let objGetListado={
      "strAccion": 'getDetalle',
      "intID": id
    }
    console.log(objGetListado);
    this._peticionesService.getApiComprasGral(objGetListado, 'sp_PreciosMinimos').subscribe( result => {
      this.arrayDetalle=result;
      console.log(this.arrayDetalle);      
    });
  }

  fnAutorizarPreciosMinimos(){
    //alert("guardar preguntas");
    let objAutoriza={
      "strAccion": 'setAutorizarPedido',
      "intID": this.intID
    }
    //console.log(objAutoriza);
    this._peticionesService.getApiComprasGral(objAutoriza, 'sp_PreciosMinimos').subscribe( result => {
      this.arrayRespuesta=result;
      console.log(this.arrayRespuesta);
      let mensaje=this.arrayRespuesta[0]?this.arrayRespuesta[0].strDos:'El pedido se Autorizo correctamente.'
      //Mensaje
      Swal.fire({
        icon:  mensaje.includes('Error')? 'error': 'success',
        title: mensaje.includes('Error')?'Error':'Correcto',
        text: mensaje
      }); 
      this.dialogRef.closeAll();//Cerrar modal  
    });
  }

  fnRechazarPreciosMinimos(){    
    let objAutoriza={
      "strAccion": 'setRechazarPedido',
      "intID": this.intID
    }
    //console.log(objAutoriza);
    this._peticionesService.getApiComprasGral(objAutoriza, 'sp_PreciosMinimos').subscribe( result => {
      console.log(this.arrayRespuesta);
      let mensaje=this.arrayRespuesta[0]?this.arrayRespuesta[0].strDos:'El pedido se Rechazo correctamente.'
      //Mensaje
      Swal.fire({
        icon:  mensaje.includes('Error')? 'error': 'success',
        title: mensaje.includes('Error')?'Error':'Correcto',
        text: mensaje
      }); 
      this.dialogRef.closeAll();//Cerrar modal

    }, error => {
      var error = <any>error;
      console.log(error);
      Swal.fire({
        icon:  'error',
        title: 'Error',
        text: error
      }); 
    });
  }
}
