import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DataDBService {
  private dbName = 'myAppDB';
  private dbVersion = 4;
   /****variables para manejar datos de usuario ******/
 private usuario_Subject = new Subject<any>(); //: BehaviorSubject<any> = new BehaviorSubject<any>([]);
 public usuario_$: Observable<any> = this.usuario_Subject.asObservable();
 private usuario:any=[];

 /**** tipo usuario *****/
 private arrayAccesosMenuCte_Subject =new Subject<any>(); //: BehaviorSubject<any> = new BehaviorSubject<any>([]);
 public arrayAccesosMenuCte_$: Observable<any> = this.arrayAccesosMenuCte_Subject.asObservable();
 
  /***variable para manejar el array accesos menu */
  private arrayAccesosMenu_Subject= new Subject<any>(); //: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public arrayAccesosMenu_$: Observable<any> = this.arrayAccesosMenu_Subject.asObservable();
 // private arrayAccesosMenu:any=[];
  //---------------------------------------------------------------------------------------------
  private arrayAccesosMenu={
    Aromia:false,
    Clientes: false,
    Ventas: false,
    Reportes: false,
    Otros: false, 
    Rubbermaid:false,
    ObjetivosMix:false,
    Leads:false,
    TiendaEcodeli:false,
    Directorio:false,
    RH:false,
    Logisitica:false,
    Financiero:false,
    Cliente:false
  }

  /***variable para manejar el array accesos   */
  private arrayAccesos_Subject= new Subject<any>(); //: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public arrayAccesos_$: Observable<any> = this.arrayAccesos_Subject.asObservable();
  private arrayAccesos:any=[];

  /***variable para manejar el array accesos submenu   */
  private arrayAccesoSubMenu_Subject= new Subject<any>(); // = new BehaviorSubject<any>([]);
  public arrayAccesoSubMenu_$: Observable<any> = this.arrayAccesoSubMenu_Subject.asObservable();
 
   /***variable para conexion  */
   private strConexion_Subject= new Subject<any>(); //: BehaviorSubject<any> = new BehaviorSubject<any>([]);
   public strConexion_$: Observable<any> = this.strConexion_Subject.asObservable();
   private strConexion:any='';

   /***variable para redireccion de busqueda desde menu */
    private Busqueda_Articulo_Subject = new BehaviorSubject<any>([]); //este debe quedarse asi para que funcione la busqueda de articulos en la barra del menu
    public Busqueda_Articulo_$: Observable<any> = this.Busqueda_Articulo_Subject.asObservable();
    private strBusqueda_Articulo:string='';

    private strfinBusqueda_Articulo:string='';
    private finBusqueda_Articulo_Subject = new BehaviorSubject<string>(''); //este debe quedarse asi para que funcione la busqueda de articulos en la barra del menu
    public finBusqueda_Articulo_$: Observable<string> = this.finBusqueda_Articulo_Subject.asObservable();
    




      /**variable para manejar cambio de sucursales */
     
    private arraySucursales_partner_Subject = new Subject<any>(); 
    public arraySucursales_partner_$ = this.arraySucursales_partner_Subject.asObservable();
 //--------------------------------------------------------------------------------------
 private arrayAccesoSubMenu={
  DirectorioClientes: false,
  ObjetivosMixNacional: false,
  ObjetivosMixDivisional:false,
  ObjetivosMixAgregar:false,
  MiObjetivosMixDivisional:false,
  ObjetivosMixSucursal:false,
  ReporteVtasNetas:false,
  ReporteVtasDetalle:false,
  ReporteComodatos:false,
  ReportePrincipal:false,
  ReporteVtasVsObjCtes: false,
  ReporteRetoTork: false,
  ReportePrecioMin: false,
  ReporteClientesFaltantes:false,
  Pedidos:false,
  Inventarios:false,
  PedidosVtas:false,
  PedidosAromia:false,
  Comodatos:false,
  Cartera:false,
  Prospecto:false,
  Cliente:false,
  TEPedidos:false,
  TEInventario:false,
  TECupones:false,
  TEProductos:false,
  Plantillas:false,
  Politica:false,
  ecoPremia:false,
  AltaClientes:false,
  Familias:false,
  Leads:false,
  Prospectos:false,
  ListadoComodatos:false,
  ComodatosCliente: false,
  RelojChecador: false,
  Vacantes:false,
  ReporteFacturaDevolucion:false,
  ReporteScoreCard:false,
  ReporteRemisiones:false,
  ReporteEvidenciaComodatos:false,
  ReporteComplementoPago:false,
  ReporteEmbarques:false,
  ReporteComodatosCliente:false,
  ComprasIndirectas:false,
  SolicitudPresupuesto:false,
  ReporteAvanceMensual: false,
  ReporteVentaDiaria:false,
  ComprasDetalleArticulo:false,
  Cliente_Principal:false,
  Cliente_Facturas:false,
  Cliente_ComplementosP:false,
  Cliente_SolicitudesP:false,
  Cliente_SeguimientoP:false,
  Cliente_VtaArtCant:false,
  Precios_Especiales:false,
  Cliente_VtaMonto:false,
  Cliente_SeleccionarCte:false,
  Cliente_VentaLinea:false,
  Cliente_PresupuestoSucursal:false,
  Cliente_unidadesExc:false,
  NuevoPedido : false
}

  constructor( private router: Router,private localSt:LocalStorageService) { 
    this.openDB();

  }
  fnIniciaBusqueda_Articulo(strBuscar:string){
    this.strBusqueda_Articulo=strBuscar;
    this.localSt.store('strLinea_Seleccionada','');
    this.localSt.store('strSubLinea_Seleccionada','');
    this.localSt.store('strArticulo_Seleccionada',this.strBusqueda_Articulo);

    //respaldar la busqueda
    localStorage.setItem('busqueda_respaldo', strBuscar ); 
    this.router.navigate(['/cotizador_Articulos']);
    this.Busqueda_Articulo_Subject.next(this.strBusqueda_Articulo)
  }
  fnNotifica_fin_busqueda_articulo(strBuscar:string){
    this.localSt.store('strLinea_Seleccionada','');
    this.localSt.store('strSubLinea_Seleccionada','');
    this.localSt.store('strArticulo_Seleccionada','');
    this.finBusqueda_Articulo_Subject.next('')
    //avisar que se borro
    this.Busqueda_Articulo_Subject.next('');
  }
  fnValida_Existe_Configuracion_inicial ()
  {
    this.getItem('arrayResult', (value) => {
      this.fnConfiguracion_inicial(value);
    });
    this.fnGet_Conexion();
  }
  fnGet_Conexion(){
    this.getItem('strConexion', (value) => {
      this.strConexion=value?value:[];
      this.localSt.store('strConexion',this.strConexion); 
      this.strConexion_Subject.next(this.strConexion)
    });
  }
  fnSet_InfoUsuario(){   
        this.usuario= '';    
        this.localSt.clear();
        this.usuario='';
        this.usuario_Subject.next(this.usuario);
     
        
  }

  private openDB(): void {
    const request = indexedDB.open(this.dbName, this.dbVersion);

   /* request.onupgradeneeded = (event: any) => {
      const db = event.target.result;
      db.createObjectStore('data', { keyPath: 'id' });
    };*/
    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains('data')) {
        db.createObjectStore('data', { keyPath: 'id' });
      }
    };
    request.onsuccess = (event: any) => {
      

    };

    request.onerror = (event: any) => {
      console.error('Error opening IndexedDB', event);
    };
  }

  public setItem(key: string, value: any): void { 
 
    const request = indexedDB.open(this.dbName, this.dbVersion);

    request.onsuccess = (event: any) => {
      
      const db = event.target.result;
      if (!db.objectStoreNames.contains('data')) {
        console.error('Object store "data" not found in database');
        return;
      }
      const transaction = db.transaction(['data'], 'readwrite');
      const store = transaction.objectStore('data');
      store.put({ id: key, value });
      transaction.oncomplete = () => {
        console.log('Data stored successfully');
        if (key == 'strConexion') {
          this.fnGet_Conexion();
        } else if (key == 'arrayResult') {
          this.fnConfiguracion_inicial(value); 
        }
        else if (key == 'arrayResult_parnert') {
          this.fnConfiguracion_inicial_partnert(value); 
        }
      };
      transaction.onerror = (event) => {
        console.error('Error storing data:', event.target.error);
      };
    
    };
  }
 
  fnConfiguracion_inicial_partnert(result:any)
  {      
    if(result && result.length>0){ 
      this.arrayAccesos=result;
       //DASTOS DE USUARIO
       this.usuario=result[0]?.strUsuario;
      
       this.localSt.store('strNombre',result[0]?.strNombre);          
       this.localSt.store('strEmail',result[0]?.strEmail);
      this.localSt.store('intIDRolAcceso',result[0]?.strTipoAcceso);
      this.localSt.store('strUrlHome',result[0]?.strUrlHome);
      this.localSt.store('strSucursal',result[0]?.sucursal);
      this.localSt.store('strAgente',result[0]?.strAgente);
      this.localSt.store('strUsuario',result[0]?.strUsuario); 
      this.localSt.store('strAlmacen',result[0]?.strAlmacen); 
       this.localSt.store('arrayAccesosMenu',this.arrayAccesosMenu);

       this.localSt.store('arrayAccesoSubMenu',this.arrayAccesoSubMenu);
       this.localSt.store('arrayAccesos',this.arrayAccesos);
       this.localSt.store('intIDRolAcceso', result[0]?.strTipoAcceso);
       this.localSt.store('strUrlHome', result[0]?.strUrlHome);
       //this.usuario_Subject.next(this.usuario);

      this.localSt.store('arrayAccesos',this.arrayAccesos); //guardamos en sesion
      //avisamos que hubo un cambio en las sucursales del partner
      this.arraySucursales_partner_Subject.next(this.arrayAccesos);
   
    }
    else {
          console.log('aun no se reciben datos para almacenar en la bd')
    } 

  }
  fnConfiguracion_inicial(result:any)
  {      
    if(result && result.length>0){ 
      this.arrayAccesos=result;
         /* Activar Menú Principal*/
         this.arrayAccesosMenu.Aromia=result.filter((b: any) => b.strModulo == 'Aromia').length > 0 ? true : false;
         this.arrayAccesosMenu.ObjetivosMix=result.filter((b: any) => b.strModulo == 'ObjetivosMix').length > 0 ? true : false;
         this.arrayAccesosMenu.Clientes=result.filter((b: any) => b.strModulo == 'Clientes').length > 0 ? true : false;
         this.arrayAccesosMenu.Ventas=result.filter((b: any) => b.strModulo == 'Ventas').length > 0 ? true : false;
         this.arrayAccesosMenu.Reportes=result.filter((b: any) => b.strModulo == 'Reportes').length > 0 ? true : false;
         this.arrayAccesosMenu.Otros=result.filter((b: any) => b.strModulo == 'Otros').length > 0 ? true : false;
         this.arrayAccesosMenu.Rubbermaid=result.filter((b: any) => b.strModulo == 'RUBBERMAID').length > 0 ? true : false;
         this.arrayAccesosMenu.Leads=result.filter((b: any) => b.strModulo == 'Leads').length > 0 ? true : false;
         this.arrayAccesosMenu.TiendaEcodeli=result.filter((b: any) => b.strModulo == 'TiendaEcodeli').length > 0 ? true : false;
         this.arrayAccesosMenu.Directorio=result.filter((b: any) => b.strModulo == 'Directorio').length > 0 ? true : false;
         this.arrayAccesosMenu.RH=result.filter((b: any) => b.strModulo == 'RH').length > 0 ? true : false;
         this.arrayAccesosMenu.Logisitica=result.filter((b: any) => b.strModulo == 'Logisitica').length > 0 ? true : false;
         this.arrayAccesosMenu.Financiero=result.filter((b: any) => b.strModulo == 'Financiero').length > 0 ? true : false;
         this.arrayAccesosMenu.Cliente=result.filter((b: any) => b.strModulo == 'Cliente').length > 0 ? true : false;
      
        
  
         /* Activar SubMenú */
         this.arrayAccesoSubMenu.DirectorioClientes=result.filter((b: any) => b.strComponente == 'app-directorio').length > 0 ? true : false;          
         this.arrayAccesoSubMenu.ObjetivosMixNacional=result.filter((b: any) => b.strComponente == 'app-objmixn').length > 0 ? true : false;
         this.arrayAccesoSubMenu.ObjetivosMixDivisional=result.filter((b: any) => b.strComponente == 'app-objmixdivisional').length > 0 ? true : false;
        //this.arrayAccesoSubMenu.ObjetivosMixSucursal=result.filter((b: any) => b.strComponente == 'app-objmixsucursal').length > 0 ? true : false;
         this.arrayAccesoSubMenu.MiObjetivosMixDivisional=result.filter((b: any) => b.strComponente == 'app-objmixsucursal').length > 0 ? true : false;
         this.arrayAccesoSubMenu.ObjetivosMixSucursal=result.filter((b: any) => b.strComponente == 'app-objmixgs').length > 0 ? true : false;
         this.arrayAccesoSubMenu.ObjetivosMixAgregar=result.filter((b: any) => b.strComponente == 'app-obj-mix').length > 0 ? true : false;
         this.arrayAccesoSubMenu.ReporteComodatos=result.filter((b: any) => b.strComponente == 'app-reportecomodatos').length > 0 ? true : false;
         this.arrayAccesoSubMenu.ReporteVtasVsObjCtes=result.filter((b: any) => b.strComponente == 'app-reportevtaobjcte').length > 0 ? true : false;
         this.arrayAccesoSubMenu.ReporteRetoTork=result.filter((b: any) => b.strComponente == 'app-reto-tork').length > 0 ? true : false;
         this.arrayAccesoSubMenu.ReportePrecioMin=result.filter((b:any) => b.strComponente == 'app-autpreciomin').length >0 ? true : false;
         this.arrayAccesoSubMenu.ReportePrincipal=result.filter((b:any) => b.strComponente == 'app-reporte-principal').length >0 ? true : false;
         this.arrayAccesoSubMenu.ReporteVtasNetas=result.filter((b:any) => b.strComponente == 'app-ventas-netas').length >0 ? true : false;
         this.arrayAccesoSubMenu.ReporteVtasDetalle=result.filter((b:any) => b.strComponente == 'app-ventas-detalle').length >0 ? true : false; 
         this.arrayAccesoSubMenu.ReporteFacturaDevolucion=result.filter((b:any) => b.strComponente == 'app-reporte-factvs-devoluciones').length >0 ? true : false; 
         this.arrayAccesoSubMenu.ReporteScoreCard=result.filter((b:any) => b.strComponente == 'app-score-card').length >0 ? true : false; 
         this.arrayAccesoSubMenu.ReporteClientesFaltantes=result.filter((b:any) => b.strComponente == 'app-clientes-faltantes').length >0 ? true : false;

         this.arrayAccesoSubMenu.ReporteComodatosCliente=result.filter((b:any) => b.strComponente == 'app-reporte-comodatos-cliente').length >0 ? true : false; 

         this.arrayAccesoSubMenu.ComodatosCliente=result.filter((b:any) => b.strComponente == 'app-comodatos-cliente').length >0 ? true : false;
       
         this.arrayAccesoSubMenu.ReporteRemisiones=result.filter((b:any) => b.strComponente == 'app-list-remisiones').length >0 ? true : false;
         this.arrayAccesoSubMenu.ReporteEvidenciaComodatos=result.filter((b:any) => b.strComponente == 'app-listado-evidencias-comodatos').length >0 ? true : false;
         this.arrayAccesoSubMenu.ReporteComplementoPago=result.filter((b:any) => b.strComponente == 'app-listado-complemento-pago').length >0 ? true : false;
         this.arrayAccesoSubMenu.ReporteEmbarques=result.filter((b:any) => b.strComponente == 'app-listado-reporte-embarques').length >0 ? true : false;
        
         this.arrayAccesoSubMenu.ReporteAvanceMensual=result.filter((b:any) => b.strComponente == 'app-reporte-avance-gerente').length >0 ? true : false;
         this.arrayAccesoSubMenu.ReporteVentaDiaria=result.filter((b:any) => b.strComponente == 'app-reporte-ventadiaria').length >0 ? true : false;
         this.arrayAccesoSubMenu.ComprasDetalleArticulo=result.filter((b:any) => b.strComponente == 'app-costos-detalle-articulo').length >0 ? true : false;
         
         //Ventas
         this.arrayAccesoSubMenu.PedidosVtas = result.filter((b:any) => b.strComponente == 'app-pedidos-ventas').length > 0 ? true: false;
         this.arrayAccesoSubMenu.Comodatos = result.filter((b:any) => b.strComponente == 'app-insertar-comodatos').length > 0 ? true: false;
         this.arrayAccesoSubMenu.Plantillas = result.filter((b:any) => b.strComponente == 'app-plantillas').length > 0 ? true: false;
         this.arrayAccesoSubMenu.Politica = result.filter((b:any) => b.strComponente == 'app-politica').length > 0 ? true: false;
         this.arrayAccesoSubMenu.ecoPremia = result.filter((b:any) => b.strComponente == 'app-ecodeli-premia').length > 0 ? true: false;
         this.arrayAccesoSubMenu.ListadoComodatos = result.filter((b:any) => b.strComponente == 'app-listado-comodatos').length > 0 ? true: false;
         this.arrayAccesoSubMenu.NuevoPedido = result.filter((b:any) => b.strComponente == 'app-cotizador-linea').length > 0 ? true: false;
         //rubbermaid
         this.arrayAccesoSubMenu.Pedidos=result.filter((b:any) => b.strComponente == 'app-vtex').length >0 ? true : false;
         this.arrayAccesoSubMenu.Inventarios=result.filter((b:any) => b.strComponente == 'app-inventarios').length >0 ? true : false;
         this.arrayAccesoSubMenu.PedidosAromia=result.filter((b:any) => b.strComponente == 'app-aromia').length >0 ? true : false;
         //LEADS
         this.arrayAccesoSubMenu.Cartera=result.filter((b:any) => b.strComponente == 'app-leads-cartera').length >0 ? true : false;
         this.arrayAccesoSubMenu.Prospecto=result.filter((b:any) => b.strComponente == 'app-leads-cartera').length >0 ? true : false;
         this.arrayAccesoSubMenu.Cliente=result.filter((b:any) => b.strComponente == 'app-leads-cartera').length >0 ? true : false;

         //DIRECTORIO
         this.arrayAccesoSubMenu.AltaClientes=result.filter((b:any) => b.strComponente == 'app-altacliente').length >0 ? true : false;
         this.arrayAccesoSubMenu.Familias=result.filter((b:any) => b.strComponente == 'app-familias').length >0 ? true : false;
         this.arrayAccesoSubMenu.Leads=result.filter((b:any) => b.strComponente == 'app-tablero-leads-oportunidades').length >0 ? true : false;
         this.arrayAccesoSubMenu.Prospectos=result.filter((b:any) => b.strComponente == 'app-prospecto').length >0 ? true : false;
         
         //TIENDA ECODELI
         this.arrayAccesoSubMenu.TEPedidos=result.filter((b:any) => b.strComponente == 'app-pedidos-ecodeli').length >0 ? true : false;
         this.arrayAccesoSubMenu.TEInventario=result.filter((b:any) => b.strComponente == 'app-inventarios-ecodeli').length >0 ? true : false;
         this.arrayAccesoSubMenu.TECupones=result.filter((b:any) => b.strComponente == 'app-admin-cupones').length >0 ? true : false;
         this.arrayAccesoSubMenu.TEProductos=result.filter((b:any) => b.strComponente == 'app-ecommerce-admin-art').length >0 ? true : false;
         
         //RH
         this.arrayAccesoSubMenu.RelojChecador=result.filter((b:any) => b.strComponente == 'app-relojchecador').length >0 ? true : false;
         this.arrayAccesoSubMenu.Vacantes=result.filter((b:any) => b.strComponente == 'app-relojchecador').length >0 ? true : false;

         //LOGISTICA
         this.arrayAccesoSubMenu.ComprasIndirectas=result.filter((b:any) => b.strComponente == 'app-comprasindirectas').length >0 ? true : false;

          //FINANCIERO
          this.arrayAccesoSubMenu.SolicitudPresupuesto=result.filter((b:any) => b.strComponente == 'app-solicitudpresupuestos').length >0 ? true : false;
        //PERFIL DE CLIENTES
        this.arrayAccesoSubMenu.Cliente_Principal=result.filter((b:any) => b.strComponente == 'app-cliente-reporte-principal').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_Facturas=result.filter((b:any) => b.strComponente == 'app-cliente-facturas').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_ComplementosP=result.filter((b:any) => b.strComponente == 'app-cliente-complementopago').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_SolicitudesP=result.filter((b:any) => b.strComponente == 'app-cliente-solicitud-pedido').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_SeguimientoP=result.filter((b:any) => b.strComponente == 'app-cliente-seguimiento-pedidos').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_VtaArtCant=result.filter((b:any) => b.strComponente == 'app-cliente-venta-articulo').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_VtaMonto=result.filter((b:any) => b.strComponente == 'app-relojchecador').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_SeleccionarCte=result.filter((b:any) => b.strComponente == 'app-relojchecador').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_VentaLinea=result.filter((b:any) => b.strComponente == 'app-cliente-venta-linea').length >0 ? true : false;
        this.arrayAccesoSubMenu.Precios_Especiales=result.filter((b:any) => b.strComponente == 'app-cliente-precios-especiales').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_PresupuestoSucursal=result.filter((b:any) => b.strComponente == 'app-cliente-presupuesto-sucursal').length >0 ? true : false;
        this.arrayAccesoSubMenu.Cliente_unidadesExc=result.filter((b:any) => b.strComponente == 'app-cliente-reporte-excedidos').length >0 ? true : false;
        
        
         //DASTOS DE USUARIO
         this.usuario=result[0]?.strUsuario;
      
         this.localSt.store('strNombre',result[0]?.strNombre);          
         this.localSt.store('strEmail',result[0]?.strEmail);
        this.localSt.store('intIDRolAcceso',result[0]?.strTipoAcceso);
        this.localSt.store('strUrlHome',result[0]?.strUrlHome);
        this.localSt.store('strSucursal',result[0]?.sucursal);
        this.localSt.store('strAgente',result[0]?.strAgente);
        this.localSt.store('strUsuario',result[0]?.strUsuario); 
        this.localSt.store('strAlmacen',result[0]?.strAlmacen); 
         this.localSt.store('arrayAccesosMenu',this.arrayAccesosMenu);

         this.localSt.store('arrayAccesoSubMenu',this.arrayAccesoSubMenu);
         this.localSt.store('arrayAccesos',this.arrayAccesos);
         this.localSt.store('intIDRolAcceso', result[0]?.strTipoAcceso);
         this.localSt.store('strUrlHome', result[0]?.strUrlHome);


         this.usuario_Subject.next(this.usuario);
         this.arrayAccesosMenu_Subject.next(this.arrayAccesosMenu);
         this.arrayAccesoSubMenu_Subject.next(this.arrayAccesoSubMenu);
         this.arrayAccesos_Subject.next(this.arrayAccesos);


         var strUrlHome = '/'+this.localSt.retrieve('strUrlHome');
         // this.router.navigate([strUrlHome]);
      
        }
        else {
          console.log('aun no se reciben datos para almacenar en la bd')
        } 

  }
  public getItem(key: string, callback: (value: any) => void): void {
  
    const request = indexedDB.open(this.dbName, this.dbVersion);

    request.onsuccess = (event: any) => {
      const db = event.target.result;
      const transaction = db.transaction(['data'], 'readonly');
      const store = transaction.objectStore('data');
      const getRequest = store.get(key);

      getRequest.onsuccess = () => {
        //volvemos a poner en sesion la informacion
        if(getRequest.result && getRequest.result.id ){        
        if(getRequest.result.id=='arrayResult'){
         
          this.fnConfiguracion_inicial(getRequest.result.value)
        }
        else if(getRequest.result.id=='strConexion'){
          this.localSt.store('strConexion',getRequest.result.value); 
        }
        else if(getRequest.result.id=='arrayResult_parnert'){
          this.fnConfiguracion_inicial_partnert(getRequest.result.value)
        }
      }
      else { 
        
        this.router.navigate(['/login']); //este debera ser   this.router.navigate(['/tienda']); --antes   this.router.navigate(['/login']); 
      }
        callback(getRequest.result ? getRequest.result.value : null);
      };
    };
  }

  public removeItem(key: string): void {
    const request = indexedDB.open(this.dbName, this.dbVersion);

    request.onsuccess = (event: any) => {
      const db = event.target.result;
      const transaction = db.transaction(['data'], 'readwrite');
      const store = transaction.objectStore('data');
      store.delete(key);
      if (key !='objRuta_Anterior'){
        this.fnSet_InfoUsuario();
      }
      if (key == 'arrayResult') {
        this.fnConfiguracion_inicial([]);
      }
    //  this.fnSet_arrayAccesosMenus();
     // this.fnGet_Conexion();
    };
  }
  public   eliminarBaseDeDatos(): void {
    let request = indexedDB.deleteDatabase(this.dbName);

    request.onsuccess = () => {
      this.router.navigate(['login']);
      console.log(`Base de datos ${this.dbName} eliminada con éxito`);
    };

    request.onerror = (event) => {
      console.log(`Error al eliminar la base de datos ${this.dbName}:`, event);
    };

    request.onblocked = () => {
      console.log(`La eliminación de la base de datos ${this.dbName} está bloqueada`);
    };
  }
}
