<div class="container-fluid px-0">
  <div id="carouselExample" class="carousel slide">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="https://www.ecodeli.com/ArchivosMailing/banner/banner_web2.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="https://www.ecodeli.com/ArchivosMailing/banner/banner_web4.gif" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="https://www.ecodeli.com/ArchivosMailing/banner/banner_web3.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="https://www.ecodeli.com/ArchivosMailing/banner/banner_web4.png" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="https://www.ecodeli.com/ArchivosMailing/banner/banner_web5.png" class="d-block w-100" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
<div class="container pb-0">
  <div class="row  justify-content-between">
    <div class="col-12 col-md-6 mt-2 d-flex" [ngClass]="{'col-lg-4 ':cartItems.length>0,'col-lg-8':cartItems.length==0}">
      <div class="col-12 bg-white shadow-sm pb-2 px-2 d-flex flex-column" >
        <label for="" class="col-11 titCards fs-6 fw-bold nunito text-start pt-2 ">
          ARTÍCULOS DESTACADOS
        </label>
        <div id="carDestacados" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" *ngIf="arrayNovedades.length>0 ">
              <div class="row" *ngIf="cartItems.length>0">
                <div class="col-6 d-flex flex-column pointer" (click)="addToCart(arrayNovedades[0])">
                  <img src="https://www.cleanclean.mx/imagenes/{{arrayNovedades[0]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 1" >
                  <div class="d-flex flex-column h-100">  
                    <h5 class="descripcion-truncada">{{arrayNovedades[0]?.strDescripcion}}</h5>
                    <p class="fw-bold fs14 nunito d-flex justify-content-between px-2">
                      <i class="bi bi-cart4 fs-5"></i>
                      <span>
                        {{arrayNovedades[0]?.dblPrecioLista | currency}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-6 d-flex flex-column pointer" *ngIf="arrayNovedades.length>1" (click)="addToCart(arrayNovedades[1])">
                  <img src="https://www.cleanclean.mx/imagenes/{{arrayNovedades[1]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 2">
                  <h5 class="descripcion-truncada">{{arrayNovedades[1]?.strDescripcion}}</h5>
                  <p class="fw-bold fs14 nunito d-flex justify-content-between px-2">
                    <i class="bi bi-cart4 fs-5"></i>
                    <span>
                      {{arrayNovedades[1]?.dblPrecioLista | currency}}
                    </span>
                  </p>
                </div>
              </div>
              <div class="row" *ngIf="cartItems.length==0">
                <div class="col-3 d-flex flex-column pointer" (click)="addToCart(arrayNovedades[0])">
                  <img src="https://www.cleanclean.mx/imagenes/{{arrayNovedades[0]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 1" >
                  <div class="d-flex flex-column h-100">
                    <h5 class="descripcion-truncada">{{arrayNovedades[0]?.strDescripcion}}</h5>
                    <p class="fw-bold fs14 nunito d-flex justify-content-between px-2 mt-auto">
                      <i class="bi bi-cart4 fs-5"></i>
                      <span>{{arrayNovedades[0]?.dblPrecioLista | currency}}</span>
                    </p>
                  </div>     
                </div>
                <div class="col-3 d-flex flex-column pointer" (click)="addToCart(arrayNovedades[1])">
                  <img src="https://www.cleanclean.mx/imagenes/{{arrayNovedades[1]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 2">
                  <div class="d-flex flex-column h-100">
                    <h5 class="descripcion-truncada">{{arrayNovedades[1]?.strDescripcion}}</h5>
                    <p class="fw-bold fs14 nunito d-flex justify-content-between px-2 mt-auto">
                      <i class="bi bi-cart4 fs-5"></i>
                        <span>{{arrayNovedades[1]?.dblPrecioLista | currency}}</span>
                      </p>
                  </div>
                </div>
                <div class="col-3 d-flex flex-column pointer" *ngIf="arrayNovedades.length>2" (click)="addToCart(arrayNovedades[2])">
                  <img src="https://www.cleanclean.mx/imagenes/{{arrayNovedades[2]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 1" >
                  <div class="d-flex flex-column h-100">  
                  <h5 class="descripcion-truncada">{{arrayNovedades[2]?.strDescripcion}}</h5>
                    <p class="fw-bold fs14 nunito d-flex justify-content-between px-2 mt-auto">
                      <i class="bi bi-cart4 fs-5"></i>
                        <span>{{arrayNovedades[2]?.dblPrecioLista | currency}}</span>
                      </p>
                    </div>
                </div>
                <div class="col-3 d-flex flex-column pointer" *ngIf="arrayNovedades.length>3" (click)="addToCart(arrayNovedades[3])">
                  <img src="https://www.cleanclean.mx/imagenes/{{arrayNovedades[3]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 2">
                  <div class="d-flex flex-column h-100">
                    <h5 class="descripcion-truncada">{{arrayNovedades[3]?.strDescripcion}}</h5>
                    <p class="fw-bold fs14 nunito d-flex justify-content-between px-2 mt-auto">
                      <i class="bi bi-cart4 fs-5 "></i>
                      <span>
                        {{arrayNovedades[3]?.dblPrecioLista | currency}}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" *ngIf="arrayNovedades.length>2 && cartItems.length>0">
              <div class="row">
                <div class="col-6 d-flex flex-column pointer" (click)="addToCart(arrayNovedades[2])">
                  <img src="https://www.cleanclean.mx/imagenes/{{arrayNovedades[2]?.strArticulo}}.JPG" class="d-block w-100"  alt="{{arrayNovedades[2]?.strDescripcion}}" 
                  title="{{arrayNovedades[2]?.strDescripcion}}">
                  <h5 class="descripcion-truncada">{{arrayNovedades[2]?.strDescripcion}}</h5>
                  <p class="fw-bold fs14 nunito d-flex justify-content-between px-2">
                    <i class="bi bi-cart4 fs-5"></i>
                    <span>{{arrayNovedades[2]?.dblPrecioLista | currency}}</span>
                  </p>
                </div>
                <div class="col-6 d-flex flex-column pointer"  *ngIf="arrayNovedades.length>3" (click)="addToCart(arrayNovedades[3])">
                  <img src="https://www.cleanclean.mx/imagenes/{{arrayNovedades[3]?.strArticulo}}.JPG" class="d-block w-100" alt="{{arrayNovedades[3]?.strDescripcion}}" 
                  title="{{arrayNovedades[3]?.strDescripcion}}">
                  <h5 class="descripcion-truncada">{{arrayNovedades[3]?.strDescripcion}}</h5>
                  <p class="fw-bold fs14 nunito d-flex justify-content-between px-2">
                    <i class="bi bi-cart4 fs-5"></i>
                    <span>{{arrayNovedades[3]?.dblPrecioLista | currency}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button *ngIf="arrayNovedades.length>2 && cartItems.length>0" class="carousel-control-prev" type="button" data-bs-target="#carDestacados" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Anterior</span>
          </button>
          <button class="carousel-control-next" *ngIf="arrayNovedades.length>2 && cartItems.length>0" type="button" data-bs-target="#carDestacados" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Siguiente</span>
          </button>
        </div>
        <div class="col-12 mt-auto" (click)="fnNav('destacados')">
          <span class="text-decoration-underline text-primary">ver más</span>  
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block col-lg-4 mt-2 d-flex" *ngIf="cartItems.length>0">
      <div class="col-12 bg-white shadow-sm pb-2 px-2">
        <label for="" class="col-12 fs-6 fw-bold titCards nunito text-start pt-2 d-flex">
          RETOMA TU COMPRA 
        </label>
        <div id="carRetomaCompra" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-6" >
                  <img src="https://www.cleanclean.mx/imagenes/{{cartItems[0]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 1" (error)="onImageError($event)">
                    <h5 class="descripcion-truncada">{{cartItems[0]?.strDescripcion}}</h5>
                    <p class="fw-bold fs14 nunito d-flex justify-content-between px-2">
                      <i class="bi bi-cart4 fs-5"></i>
                      <span>{{cartItems[1]?.dblPrecioLista | currency}}</span>
                    </p>
                </div>
                <div class="col-6" *ngIf="cartItems.length>1">
                  <img src="https://www.cleanclean.mx/imagenes/{{cartItems[1]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 2" (error)="onImageError($event)">
                    <h5 class="descripcion-truncada">{{cartItems[1]?.strDescripcion}}</h5>
                    <p class="fw-bold fs14 nunito d-flex justify-content-between px-2">
                      <i class="bi bi-cart4 fs-5"></i>
                        <span>{{cartItems[1]?.dblPrecioLista | currency}}</span>
                      </p>
                </div>
              </div>
            </div>
            <div class="carousel-item" *ngIf="cartItems.length>2">
              <div class="row">
                <div class="col-6">
                  <img src="https://www.cleanclean.mx/imagenes/{{cartItems[2]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 3" (error)="onImageError($event)">
                  <h5 class="descripcion-truncada">{{cartItems[2]?.strDescripcion}}</h5>
                  <p class="fw-bold fs14 nunito d-flex justify-content-between px-2">
                    <i class="bi bi-cart4 fs-5"></i>
                      <span>{{cartItems[2]?.dblPrecioLista | currency}}</span>
                    </p>
                </div>
                <div class="col-6" *ngIf="cartItems.length>3">
                  <img src="https://www.cleanclean.mx/imagenes/{{cartItems[3]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 4" (error)="onImageError($event)">
                  <h5 class="descripcion-truncada">{{cartItems[3]?.strDescripcion}}</h5>
                  <p class="fw-bold fs14 nunito d-flex justify-content-between px-2">
                    <i class="bi bi-cart4 fs-5"></i>
                      <span>{{cartItems[3]?.dblPrecioLista | currency}}</span>
                    </p>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carRetomaCompra" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Anterior</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carRetomaCompra" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Siguiente</span>
          </button>
        </div>
        <!-- <div class="col-12 d-flex justify-content-center">
          <span class="titCards fs11 fw-bold">Sub total:</span> <span class="text-danger fs11 fw-bold">{{subTotalCar|currency}}</span>
        </div> -->
        <div class="col-12" [routerLink]="['/tienda-carrito']">
          <span class="text-decoration-underline text-primary pointer">ver más</span>  
        </div>
      </div>
    </div>
    <div class="col-12 py-2 d-md-none">
    </div>
    <div class="col-12 col-md-6 col-lg-4 mt-2 pointer d-flex" >
      <div class="col-12 bg-white shadow-sm pb-2 px-2 d-flex flex-column">
      <label for="" class="col-11 titCards fs-6 fw-bold nunito text-start pt-2 ">
        ARTÍCULOS EN LIQUIDACIÓN
      </label>
      <div id="carLiqui" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" *ngIf="arrayProductosDestacados.length>0">
            <div class="row">
              <div class="col-6 d-flex flex-column" (click)="addToCart(arrayProductosDestacados[0])">
                <img src="https://www.cleanclean.mx/imagenes/{{arrayProductosDestacados[0]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 1" (error)="onImageError($event)"
                 alt="{{arrayProductosDestacados[0]?.strDescripcion}}" 
                title="{{arrayProductosDestacados[0]?.strDescripcion}}">
                  <h5 class="descripcion-truncada mt-auto">{{arrayProductosDestacados[0]?.strDescripcion}}</h5>
                  <div class="col-12 px-0 d-flex">
                    <span class="fw-bold fs11 text-danger me-1">{{arrayProductosDestacados[0]?.intDescuento|number:'1.0-0'}}%</span> <span class="bg-danger px-2 text-white fw-bold fs11 rborder">DESCUENTO</span>
                  </div>
              </div>
              <div class="col-6 d-flex flex-column" *ngIf="arrayProductosDestacados.length>1" (click)="addToCart(arrayProductosDestacados[1])">
                <img src="https://www.cleanclean.mx/imagenes/{{arrayProductosDestacados[1]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 2" (error)="onImageError($event)" 
                alt="{{arrayProductosDestacados[1]?.strDescripcion}}" 
                title="{{arrayProductosDestacados[1]?.strDescripcion}}">
                  <h5 class="descripcion-truncada mb-1 mt-auto">{{arrayProductosDestacados[1]?.strDescripcion}}</h5>
                  <div class="col-12 px-0 d-flex">
                    <span class="fw-bold fs11 text-danger me-1">{{arrayProductosDestacados[1]?.intDescuento|number:'1.0-0'}}%</span> <span class="bg-danger px-2 text-white fw-bold fs11 rborder">DESCUENTO</span>
                  </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="arrayProductosDestacados.length>2">
            <div class="row">
              <div class="col-6 d-flex flex-column" (click)="addToCart(arrayProductosDestacados[2])">
                <img src="https://www.cleanclean.mx/imagenes/{{arrayProductosDestacados[2]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 3" (error)="onImageError($event)"
                alt="{{arrayProductosDestacados[2]?.strDescripcion}}" 
                title="{{arrayProductosDestacados[2]?.strDescripcion}}">
                <h5 class="descripcion-truncada mt-auto">{{arrayProductosDestacados[2]?.strDescripcion}}</h5>
                <div class="col-12 px-0 d-flex">
                  <span class="fw-bold fs11 text-danger me-1">{{arrayProductosDestacados[2]?.intDescuento|number:'1.0-0'}}%</span> <span class="bg-danger px-2 text-white fw-bold fs11 rborder">DESCUENTO</span>
                </div>
              </div>
              <div class="col-6" *ngIf="arrayProductosDestacados.length>3" (click)="addToCart(arrayProductosDestacados[3])">
                <img src="https://www.cleanclean.mx/imagenes/{{arrayProductosDestacados[3]?.strArticulo}}.JPG" class="d-block w-100" alt="Producto 4" (error)="onImageError($event)"
                alt="{{arrayProductosDestacados[3]?.strDescripcion}}" 
                title="{{arrayProductosDestacados[3]?.strDescripcion}}">
                <h5 class="descripcion-truncada mt-auto">{{arrayProductosDestacados[3]?.strDescripcion}}</h5>
                  <div class="col-12 px-0 d-flex">
                    <span class="fw-bold fs11 text-danger me-1">{{arrayProductosDestacados[3]?.intDescuento|number:'1.0-0'}}%</span> <span class="bg-danger px-2 text-white fw-bold fs11 rborder">DESCUENTO</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carLiqui" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Anterior</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carLiqui" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Siguiente</span>
        </button>
      </div>
      <div class="col-12 mt-auto" (click)="fnNav('liquidacion')">
        <span class="text-decoration-underline text-primary pointer">ver más</span>  
       </div>
      </div>
      <!-- <img src="assets/mercados/Mercados.jpg" class="w-100 shadow-sm pointer product"(click)="fnNav('ofertas')" alt=""> -->
    </div>
  </div>
  <div class="row mt-2 pt-2 clsSub justify-content-center">
    <div class="col-12 position-relative pointer" [routerLink]="['/partner-info']">
      <img src="assets/ecommerce/banners/BANNER_PARTNER.jpg" class="w-100  pointer"alt="">
    </div>
  </div>
</div>
<div class="container">
  <div class="row my-2">
    <div class="col-12">
      <label class="text-center w-100 fw-bold fs-4 nunito py-2" for="">
        NUESTRO CATÁLOGO
      </label>
    </div>
  </div>
  <div class="row mt-0 pt-0">
    <div class="col-12">
      <div class="row justify-content-between">
        <div class=" px-0 d-flex justify-content-center mb-4" [ngClass]="{'col5':ind != 0 && ind != 1 && ind != 2&& ind != 3,'col-3':ind == 0 || ind == 1 || ind == 2 ,'col3':ind == 3}"  (click)="fnIrSubLinea(categoria)" *ngFor="let categoria of arrayCategorias;let ind = index">
          <div class="w95  position-relative shadowSm " >
            <img class="w-100 " [src]="categoria.imagen" alt="{{ categoria.strCategoria }}">
            <div class="py-3 bg-transparent col-12 position-absolute bottom-0 start-50 translate-middle-x">
              <h5 class="mb-0 fw-bolder fs-6 text-center">{{ categoria.strCategoria }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-12 text-center">
      <span class="mb-0 text-center fw-bold fs-4 nunito my-2">
        NUESTROS METODOS DE PAGO
      </span>
    </div>
    <div class="col-12 justify-content-center d-flex py-2">
      <div class="row wid95">
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <img src="assets/logos/logo-aplazo-color.svg"  alt="Aplazo" class="w-50">
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <img class="w-50" src="https://logotipoz.com/wp-content/uploads/2021/10/version-horizontal-large-logo-mercado-pago.webp" alt="">
        </div>
        <div class="col-6 col-md-3 text-center d-flex align-items-center">
          <img class="w-25 mx-auto" [src]="'assets/ecommerce/mastercard.png'" alt="">
        </div>
        <div class="col-6 col-md-3  d-flex align-items-center justify-content-center">
          <img class="w-50" [src]="'assets/ecommerce/visacard.png'" alt="">
        </div>
      </div>
     
    </div>
  </div>
</div>


  
