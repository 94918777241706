<div class="container">
    <div class="row">
        <div class="col-12 text-center mt-3">
            <h1 class="fw-bold h1">Listado de <span class="bgtittle">Comodatos</span></h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
      <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
          <span class="material-symbols-outlined">
              content_paste_search
          </span>
          <span class="fw-bold">
              Filtros
          </span> 
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
            <mat-select [(ngModel)]="strGerente" class="" >
              <mat-option   value=""> --Todos --</mat-option> 
             <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
            <mat-select [(ngModel)]="strSucursal" class="" >
              <mat-option value=""> --Todas --</mat-option> 
             <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
            </mat-select>
          </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
            <mat-select [(ngModel)]="strAgente" class="" >
              <mat-option value=""> --Todos --</mat-option> 
             <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
            <mat-select [(ngModel)]="intPeriodo" class="" >
             <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
              <mat-select [(ngModel)]="intEjercicio">
                  <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                      {{e.ejercicio}}
                  </mat-option> 
              </mat-select>
          </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
          <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetComodatos()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
              <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
          </button>
      </div>
  </div>
    <div class="row pt-4" *ngIf=" arraylistComodatos?.length === 0 && !spiner">
        <div class="col-12">
            <div class="alert alert-danger text-center fw-bold fs-5" role="alert">
                {{mensajeError}}
            </div>
        </div>
    </div>

    <div class="row mt-3 justify-content-center" *ngIf="spiner">
      <div class="col-12 d-flex justify-content-center" >
          <mat-spinner></mat-spinner>
      </div>
  </div>
   
    <div class="row mt-4" [hidden]="spiner || arraylistComodatos?.length == 0 ">
      <div class="col-12 py-1 mt-2 bordertop bgheader rounded-top px-3">
        <span class="fw-bold ms-2">Tabla de Documentos: 
          <span class="badge bgbadge fw-bold text-white">{{arraylistComodatos?.length}}</span>
        </span>
      </div>
      <div class="col-12 mb-4 shadow  bgheader  borderbott px-0">
          <div class="col-12 bgtopTable py-3  bordertop d-flex justify-content-around ">
              <mat-form-field appearance="outline" class="bg-white text-dark rounded ">
                  <mat-label>Buscar</mat-label>
                  <input matInput (keyup)="filterComodatos($event)" placeholder="ID,Tarea,etc..." #input>
                  <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" class="bg-white rounded text-dark">
                  <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Estatus</mat-label>
                  <mat-select [(ngModel)]="strEstatusCom" class="" (selectionChange)="fnFiltrarEstatus()">
                      <mat-option value=''>Todos</mat-option>
                      <mat-option value='1'>Vencido</mat-option>
                      <mat-option value='2'>Solicitud Cancelación</mat-option>
                      <mat-option value='3'>Solicitud Renovación</mat-option>
                      <mat-option value='0'>Vigente</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="mb-0 table-responsive mb-0">
              <div class="mat-elevation-z8 mb-0  shadow rounded">
                  <table mat-table [dataSource]="datosComodatos" class="table table-hover table-bordered"  matSort #sortComodatos="matSort">
                      <!-- intVencido Column -->
                      <ng-container matColumnDef="intVencido">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 text-center align-middle">Estatus</th>
                          <td mat-cell *matCellDef="let row" class="px-2  text-center align-middle" (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <span class="badge" [ngClass]="{
                              'bg-warning': row.intVencido === 2,
                              'bg-danger': row.intVencido === 1,
                              'bg-success': row.intVencido === 0,
                              'bg-info': row.intVencido === 3
                            }">
                              {{ row.intVencido === 1 ? 'Vencido' : row.intVencido == 2 ? 'Solicitud Cancelación' : row.intVencido === 3 ? 'Solicitud Renovación' : 'Vigente' }}
                            </span>
                          </td>
                      </ng-container>

                      <!-- intIDComodatos Column -->
                      <ng-container matColumnDef="intIDComodatos">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 align-middle"> Id </th>
                          <td mat-cell *matCellDef="let row" class="mostDet fw-bold ps-0 fs13 text-start align-middle" (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal"> 
                           <span class="ps-2">{{row.intIDComodatos}}</span>   
                          </td>
                      </ng-container>

                      <!-- strMov Column -->
                      <ng-container matColumnDef="strMov">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 align-middle"> Mov </th>
                          <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle"  (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{row.strMov}}
                          </td>
                      </ng-container>

                      <!-- strMovID Column -->
                      <ng-container matColumnDef="strMovID">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header  class="fs13 align-middle"> MovId </th>
                          <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-start align-middle text-center" (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{row.strMovID}}
                          </td>
                      </ng-container>

                      <!-- strFechaInicio Column -->
                      <ng-container matColumnDef="strFechaInicio">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 text-nowrap align-middle text-nowrap"> Fecha Inicio </th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0" (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{row.strFechaInicio | date: 'shortDate'}}
                          </td>
                      </ng-container>

                      <!-- strFechaFin Column -->
                      <ng-container matColumnDef="strFechaFin">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle text-nowrap" >Fecha Fin</th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0" (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{row.strFechaFin | date: 'shortDate'}}
                          </td>
                      </ng-container>

                      <!-- strCliente Column -->
                      <ng-container matColumnDef="strCliente">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle">Cliente</th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                            <button class="btn btn-link p-0 fs13"  (click)="enviarDetalleCliente(row)">{{row.strCliente}}</button>
                          </td>
                      </ng-container>

                      <!-- strNombreCte Column -->
                      <ng-container matColumnDef="strNombreCte">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle text-nowrap"> Nombre Cliente </th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0 fs13"  (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            {{row.strNombreCte}}
                          </td>
                      </ng-container>

                      <!-- strCteEnviarA Column -->
                      <ng-container matColumnDef="strCteEnviarA">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle text-nowrap"> Enviar A </th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0"  (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                           <span class="fw-bold fs13">{{row.strCteEnviarA}}</span>
                          </td>
                      </ng-container>

                        <!-- strAgente Column -->
                        <ng-container matColumnDef="strAgente">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle"> Agente </th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0"  (click)="detalleComodatos(row)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            (<span class="fw-bold">{{row.strAgente}}</span>) {{row.strNombreAgente}}
                          </td>
                      </ng-container>

                        <!-- strEstatusSolC Column -->
                        <ng-container matColumnDef="strEstatusSolC">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle"> S. Cancelación </th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0"  (click)="fndetretirar(row)" data-bs-toggle="modal" data-bs-target="#modalRetirada">
                            <span class="btn mx-auto p-0 shadow-sm d-flex align-items-center" [ngClass]="{
                              'btn-info': row.strEstatusSolC === 'PENDIENTE',
                              'btncontr': row.strEstatusSolC === 'CONCLUIDO',
                              'btn-warning': row.strEstatusSolC === 'OBSERVACIONES',
                              'btn-primary': row.strEstatusSolC === 'RETIRAR',
                              'retirarRenov': row.strEstatusSolC === 'RETIRAREV'
                            }">
                              <i class="ps-2 fs10 bi pe-1" [ngClass]="{
                                'bi-alarm': row.strEstatusSolC === 'PENDIENTE',
                                'bi-check-lg': row.strEstatusSolC === 'CONCLUIDO',
                                'bi-chat-dots': row.strEstatusSolC === 'OBSERVACIONES',
                                'bi-box-arrow-down': row.strEstatusSolC === 'RETIRAR',
                                'bi-file-earmark-check': row.strEstatusSolC === 'RETIRAREV'
                              }"></i> 
                              <span class="fs10 pe-2">{{row.strEstatusSolC}}</span>
                            </span>
                          </td>
                      </ng-container>

                        <!-- strEstatusSolR Column -->
                      <ng-container matColumnDef="strEstatusSolR">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 align-middle"> S. Renovación </th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0" (click)="fnGenerarPfdRenov(row)" data-bs-toggle="modal" data-bs-target="#mdlRenAutorizad">
                            <div class="row" *ngIf="row.intVencido === 3">
                              <div class="col-12 text-center pt-2">
                                <span class="btn mx-auto px-0 py-1 shadow-sm d-flex align-items-center" [ngClass]="{
                                  'pendienteRen': row.strEstatusSolR === 'PENDIENTE',
                                  'concluidoRen': row.strEstatusSolR === 'CONCLUIDO',
                                  'rechazadoRen': row.strEstatusSolR === 'RECHAZADO',
                                  'autorizadoRen': row.strEstatusSolR === 'AUTORIZADO'
                                }">
                                  <i class="ps-2 fs10 bi pe-1" [ngClass]="{
                                    'bi-alarm': row.strEstatusSolR === 'PENDIENTE',
                                    'bi-check-lg': row.strEstatusSolR === 'CONCLUIDO',
                                    'bi-x-circle': row.strEstatusSolR === 'RECHAZADO',
                                    'bi-hand-thumbs-up': row.strEstatusSolR === 'AUTORIZADO'
                                  }"></i> 
                                  <span class="fs10 pe-2">{{row.strEstatusSolR}}</span>
                                </span>
                              </div>
                            </div>
                          </td>
                      </ng-container>
          
                      <tr mat-header-row class="rounded  bgheadertab sticky-top " *matHeaderRowDef="columnsComodatos"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnsComodatos;" class="pointer"></tr>
                      <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="12">No se encontraron datos de: "{{input.value}}"</td>
                      </tr>
                  </table>
              </div>
          </div>
          <mat-paginator class="paginator borderbott" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorComodatos></mat-paginator>
      </div>
  </div>








<!-- 
    <div class="row pt-2" *ngIf="arraylistComodatos && arraylistComodatos.length > 0">
        <div class="col-12 py-1 mt-2 bgheader rounded-top px-3">
            <span class="fw-bold ms-2">Tabla de Documentos: 
              <span class="badge bgbadge fw-bold text-white">{{arraylistComodatos.length}}</span>
            </span>
          </div>
          <div class="col-12 table-responsive mb-0 px-0">
            <table class="table table-bordered rounded table-hover">
              <thead>
                <tr class="text-white bgheadertab sticky-top">
                  <th scope="col">Estatus</th>
                  <th scope="col">Id</th>
                  <th scope="col">Mov</th>
                  <th class="text-nowrap" scope="col">Id Mov</th>
                  <th class="text-nowrap" scope="col">Fecha Inicio</th>
                  <th class="text-nowrap" scope="col">Fecha Fin</th>
                  <th scope="col">Cliente</th>
                  <th class="text-nowrap" scope="col">Nombre Cliente</th>
                  <th class="text-nowrap" scope="col">Enviar A</th>
                  <th scope="col">Agente</th>
                  <th class="text-nowrap" scope="col">S. Cancelación</th>
                  <th class="text-nowrap" scope="col">S. Renovación</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tdcenter pointer" *ngFor="let x of arraylistComodatos ">
                  <td class="fw-bold" (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <span class="badge" [ngClass]="{
                      'bg-warning': x.intVencido === 2,
                      'bg-danger': x.intVencido === 1,
                      'bg-success': x.intVencido === 0,
                      'bg-info': x.intVencido === 3
                    }">
                      {{ x.intVencido === 1 ? 'Vencido' : x.intVencido == 2 ? 'Solicitud Cancelación' : x.intVencido === 3 ? 'Solicitud Renovación' : 'Vigente' }}
                    </span>
                  </td>
                  <td class="fw-bold" (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">{{x.intIDComodatos}}</td>
                  <td class="fs10 text-nowrap" (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">{{x.strMov}}</td>
                  <td (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">{{x.strMovID}}</td>
                  <td class="fs11" (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">{{x.strFechaInicio | date: 'shortDate'}}</td>
                  <td class="fs11" (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">{{x.strFechaFin | date: 'shortDate'}}</td>
                  <td><a [href]="'AnalisisVendedor.php?c=' + x.strCliente + '&n=' + x.strNombreCte">{{x.strCliente}}</a></td>
                  <td class="fs10 text-primary" (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">{{x.strNombreCte}}</td>
                  <td (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">{{x.strCteEnviarA}}</td>
                  <td (click)="detalleComodatos(x)" data-bs-toggle="modal" data-bs-target="#exampleModal">(<span class="fw-bold">{{x.strAgente}}</span>) {{x.strNombreAgente}}</td>
                  <td (click)="fndetretirar(x)" data-bs-toggle="modal" data-bs-target="#modalRetirada">
                    <span class="btn mx-auto p-0 shadow-sm d-flex align-items-center" [ngClass]="{
                      'btn-info': x.strEstatusSolC === 'PENDIENTE',
                      'btncontr': x.strEstatusSolC === 'CONCLUIDO',
                      'btn-warning': x.strEstatusSolC === 'OBSERVACIONES',
                      'btn-primary': x.strEstatusSolC === 'RETIRAR',
                      'retirarRenov': x.strEstatusSolC === 'RETIRAREV'
                    }">
                      <i class="ps-2 fs10 bi pe-1" [ngClass]="{
                        'bi-alarm': x.strEstatusSolC === 'PENDIENTE',
                        'bi-check-lg': x.strEstatusSolC === 'CONCLUIDO',
                        'bi-chat-dots': x.strEstatusSolC === 'OBSERVACIONES',
                        'bi-box-arrow-down': x.strEstatusSolC === 'RETIRAR',
                        'bi-file-earmark-check': x.strEstatusSolC === 'RETIRAREV'
                      }"></i> 
                      <span class="fs10 pe-2">{{x.strEstatusSolC}}</span>
                    </span>
                  </td>
                  <td (click)="fnGenerarPfdRenov(x)" data-bs-toggle="modal" data-bs-target="#mdlRenAutorizad">
                    <div class="row" *ngIf="x.intVencido === 3">
                      <div class="col-12 text-center pt-2">
                        <span class="btn mx-auto px-0 py-1 shadow-sm d-flex align-items-center" [ngClass]="{
                          'pendienteRen': x.strEstatusSolR === 'PENDIENTE',
                          'concluidoRen': x.strEstatusSolR === 'CONCLUIDO',
                          'rechazadoRen': x.strEstatusSolR === 'RECHAZADO',
                          'autorizadoRen': x.strEstatusSolR === 'AUTORIZADO'
                        }">
                          <i class="ps-2 fs10 bi pe-1" [ngClass]="{
                            'bi-alarm': x.strEstatusSolR === 'PENDIENTE',
                            'bi-check-lg': x.strEstatusSolR === 'CONCLUIDO',
                            'bi-x-circle': x.strEstatusSolR === 'RECHAZADO',
                            'bi-hand-thumbs-up': x.strEstatusSolR === 'AUTORIZADO'
                          }"></i> 
                          <span class="fs10 pe-2">{{x.strEstatusSolR}}</span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    </div> -->




</div>
<!-- Modal -->
<div class="modal fade" id="mdlRenAutorizad" tabindex="-1" aria-labelledby="mdlRenAutorizadLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title mx-auto mb-0 fw-bold" id="mdlRenAutorizadLabel">{{ objComodatoRenovacion.strMovID }}</h3>
          <button type="button" class=" ms-0 btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div *ngIf="cancelRenov" class="alert alert-warning w-100" role="alert">
            Comentario Gerente: {{ strMensajeRenovmdl }}
          </div>
  
          <div *ngIf="spinerRenovmdl && !cancelRenov" class="alert alert-primary w-100 fs-5 fw-bold text-center cursorHang" role="alert" (click)="fnCreatePdf()">
            Descargar Contrato <i class="bi bi-file-earmark-pdf"></i>
          </div>
  
          <div class="row justify-content-center" *ngIf="spinerRenovmdl">
           
              <div *ngIf="spinerMod" class="col-12 d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
         
  
            <div *ngIf="!spinerMod" class="col-10 border roundedBordes shadow-sm text-center" [ngClass]="bgclass" (click)="clickInput(1)">
              <span class="text-dark fs-6 fw-bold">{{ buttonText }}</span>
              <i class="{{ iconClass }} fs-1"></i>
            </div>
          </div>
  
          <div class="d-none">
            <input type="file" [(ngModel)]="selectedFile" (change)="onFileInputChange($event)" id="fileInput" #fileInput>
          </div>
        </div>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" #cerrarModalRenov data-bs-dismiss="modal" >Cerrar</button>
          <button type="button" class="btn btn-primary" [disabled]="!concluirRenov" (click)="fnenviarDocRenovacion()" *ngIf="objComodatoRenovacion.strEstatusSolR === 'AUTORIZADO'">Concluir</button>
          <button type="button" class="btn btn-danger" (click)="fnenviarCancelarRechazo()" *ngIf="objComodatoRenovacion.strEstatusSolR === 'RECHAZADO' && cancelRenov">Eliminar Solicitud</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="modalRetirada" tabindex="-1" aria-labelledby="modalRetiradaLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title mb-0 mx-auto" id="modalRetiradaLabel">MOV ID: {{ objComodatoCancelacion.strMovID }}</h3>
          <button type="button" class="btn-close"   data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        
        <div class="modal-body">
          <!-- Alerta de cancelación -->
          <div class="alert alert-warning text-center fw-bold" role="alert" *ngIf="bolEstatusCancelacion !== 2">
            {{ bolComentarioCancelacion }}
          </div>
  
          <!-- Spinner de carga -->
          <div class="col-12 d-flex justify-content-center" *ngIf="spinerModSolC && bolEstatusCancelacion === 2">
            <mat-spinner></mat-spinner>
          </div>

        
  
          <!-- Contenedor para subir archivos -->
          <div class="row justify-content-center" *ngIf="!spinerModSolC && bolEstatusCancelacion === 2">
            <div class="col-10 border rounded shadow-sm text-center" [ngClass]="bgclassSolC" (click)="clickInput(2)">
              <span class="text-dark fs-6 fw-bold">{{ buttonTextSolC }}</span>
              <i [ngClass]="iconClassSolC" class="fs-1"></i>
            </div>
          </div>
  
          <!-- Campo de carga de archivo oculto -->
          <input type="file" #fileInputSolC id="fileInputSolC" class="d-none" (change)="onFileInputSolCChange($event)" accept="image/*">
        </div>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnSolc>Cerrar</button>
          
          <!-- Botón para cancelar solicitud -->
          <button *ngIf="objComodatoCancelacion.intVencido === 2 && objComodatoCancelacion.strEstatusSolC === 'OBSERVACIONES'" 
                  type="button" class="btn btn-primary" 
                  (click)="fnenviarRechazarObsevacionesCancelacion()">
            Cancelar Solicitud
          </button>
  
          <!-- Botón para enviar evidencia -->
          <button *ngIf="objComodatoCancelacion.intVencido === 2 && objComodatoCancelacion.strEstatusSolC === 'RETIRAR'" 
                  type="button" class="btn btn-primary" 
                  (click)="fnenviarDocRetCancelacion()" 
                  [disabled]="!concluirRet">
            Enviar Evidencia
          </button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title mb-0 mx-auto fw-bold" id="exampleModalLabel">MOV ID: {{ objComodato.strMovID }}</h2>
          <button type="button" class="btn-close ms-0"  data-bs-dismiss="modal" aria-label="Close" ></button>
        </div>
        <div class="modal-body mhmodal position-relative">
            <div *ngIf="spinerMod" class="col-12 d-flex justify-content-center" > 
              <mat-spinner></mat-spinner>
            </div>

            
          <div class="row justify-content-center" *ngIf="!spinerMod">
            <div class="col-12 col-md-10">
              <div class="row shadow-sm mb-3 bg-light py-1 justify-content-between align-items-center roundedBordes position-relative" *ngFor="let com of arrayDetalleComodato;let i = index">
                <span class="position-absolute top-0 end-0 text-end mt-2 cursorHang" *ngIf="objComodato.intVencido==1 && boolrenovarcontrato && arrayDetalleComodato.length > 1" (click)="fnEliminardedetRen(com, i)">
                  <i class="bi bi-x-circle-fill text-danger rounded-circle fs-6 shadow-sm"></i>
                </span>
                <div class="col-4 col-md-3 py-1 border-end text-center">
                  <div class="col-12 text-center roundedBordestop bg-main">
                    <span class="fs14 fw-bold text-white">{{ com.strDespachador }}</span>
                  </div>
                  <img [src]="'https://www.cleanclean.mx/imagenes/' + com.strDespachador + '.jpg'" class="w-100 bg-white" alt="">
                  <div class="col-12 text-center roundedBordesbottom bg-main">
                    <span class="fs10 text-white">{{ com.strNombreDespachador }}</span>
                  </div>
                </div>
                <div class="col-7 col-md-9">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="row"><span class="fw-bold">Consumible:</span></div>
                      <div class="row d-flex">
                        <span>
                          <select class="form-control" (change)="seleccionarConsumible(com.selectConsumible, i)" [(ngModel)]="com.selectConsumible" *ngIf="boolrenovarcontrato">
                            <option *ngFor="let articulo of com.arrayConsumible" [ngValue]="articulo">
                              {{ articulo.strConsumible }} ({{ articulo.strNombre }})
                            </option>
                          </select>
                          <span class="text-dark fw-bold" *ngIf="!boolrenovarcontrato">{{ com.strConsumible }}</span>
                          <span *ngIf="!boolrenovarcontrato">{{ com.strNombreConsumible }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <hr class="d-block d-md-none">
                      <div class="row"><span class="fw-bold">Última Compra:</span></div>
                      <div class="row"><span>{{ com.strUltimaCompra  }}</span></div>
                    </div>
                    <div class="col-12 col-md-6">
                      <hr>
                      <div class="row"><span class="fw-bold">Precio:</span></div>
                      <div class="row">
                        <div class="col-12">
                          <div *ngIf="boolrenovarcontrato">
                            <input type="number" class="form-control" [(ngModel)]="com.dblPrecio" *ngIf="com.dblPrecio > 0" disabled>
                            <div class="alert alert-danger fs10" role="alert" *ngIf="com.dblPrecio <= 0">
                              Es necesario introducir el precio plantilla para su asignación.
                            </div>
                          </div>
                          <span *ngIf="!boolrenovarcontrato">{{ com.dblPrecio | currency }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <hr>
                      <div class="row"><div class="col-12"><span class="fw-bold">Cantidad Prometida:</span></div></div>
                      <div class="row">
                        <div class="col-12">
                          <input type="number" class="form-control" [(ngModel)]="com.dblCantidadPrometida" *ngIf="boolrenovarcontrato">
                          <span *ngIf="!boolrenovarcontrato">{{ com.dblCantidadPrometida }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <hr>
                    <div class="col-12 col-md-7">
                      <div class="row"><div class="col-12"><span class="fw-bold">Cantidad Despachadores:</span></div></div>
                      <div class="row"><div class="col-12"><span>{{ com.dblCantidadDespachador }}</span></div></div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="row"><div class="col-12"><span class="fw-bold">Opción:</span></div></div>
                      <div class="row" *ngIf="com.strOpcion && com.strOpcion != ''">
                        <div class="col-12"><span>({{ com.strOpcion }}) {{ com.strNombreOpcion }}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0 px-0 justify-content-evenly">
          <div class="col-12 mx-0 my-0 d-flex justify-content-center bg-light py-2">
            <div class="col-11">
              <label for="" class="fw-bold">Comentario</label>
              <input type="text" [(ngModel)]="strComentarioEnviar" [attr.disabled]="objComodato.intVencido != 1 ? true : null" class="form-control {{ bolComentarioEnviar ? 'is-invalid' : '' }}">
            </div>
          </div>
          <button type="button" class="btn btn-primary mt-3" (click)="fneditElimin()" *ngIf="objComodato?.intVencido == 1 && boolrenovarcontrato && arrayEliRenov.length > 0">Cancelar Partida</button>
          <button type="button" class="btn btn-danger ms-3 mt-3" (click)="fncancelarContrato()" *ngIf="arrayDetalleComodato[0]?.intMuestraCancelar == 1 && objComodato?.intVencido == 1 && !boolrenovarcontrato">Cancelar Servicio</button>
          <button type="button" class="btn btncontr mt-3" (click)="fnrenovacionContrato(1)" *ngIf="objComodato?.intVencido == 1 && !boolrenovarcontrato">Renovar Contrato</button>
          <button type="button" class="btn btn-warning mt-3" (click)="fnCancelarRen(objComodato)" *ngIf="objComodato?.intVencido == 1 && boolrenovarcontrato">Cancelar Renovación</button>
          <button type="button" class="btn btncontr mt-3" (click)="fnEnviarRenovacionContrato()" *ngIf="objComodato?.intVencido == 1 && boolrenovarcontrato && arrayEliRenov.length == 0" [attr.disabled]="!fnvalidarEnviar() ? true : null">Guardar y Enviar</button>
        </div>
      </div>
    </div>
  </div>
  


<!-- =================================================================================================== -->
<!-- =====================================Generar pdf====================================================== -->
<!-- =================================================================================================== -->

      <!--   <button class="btn btn-info " ng-click="fncreatepdf()">Generar PDF</button> -->
      <div class="d-none"><!-- class="d-none" -->
        <div class="container" id="pdfTableFiniquito">
            <div class="row exPdfBordePrincipal p-1 mb-4 d-flex justify-content-center">
                <div class="row exPdfBordePrincipal p-1 ">
                    <div class="col-4 d-flex align-items-center">
                     <img src="/assets/img/logo/ecodeli.png" class="w-75" alt="">
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-center">
                        <span class=" mb-0 clstitPDF fs-6">CONTRATO DE COMODATOS</span>
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-center">
                        <div class="row">
                            <div class="col-12 text-center text-dark fs10"><span class="fw-bold ">CÓDIGO:</span> ATC-RC-015</div>
                            <div class="col-12 text-center text-dark fs10"><span class="fw-bold">VERSIÓN:</span> 07</div>
                            <div class="col-12 text-center text-dark fs10"><span class="fw-bold">FECHA DE ÚLTIMA REVISIÓN:</span> {{getDate()}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-2">
                <div class="col-12">
                    <span class="fs8 text-dark">
                        EL PRESENTE CONTRATO DE COMODATO QUE CELEBRAN POR UNA PARTE ECODELI COMERCIAL S.A. DE C.V. EN LO SUCESIVO "LA COMODANTE", Y POR LA OTRA PARTE:
                        <span class="fs10">{{objComodatoRenovacion?.strNombreCte}}' EL COMODATARIO'</span>, REPRESENTADA EN ESTE ACTO POR: {{datosArrayPDF[0]?.strContacto| uppercase }} CON DOMICILIO FISCAL: <span class="fs12"> {{datosArrayPDF[0]?.strDireccion| uppercase }} 
                         </span>. AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS:
                    </span>
                </div>
            </div>
            <div class="row my-1">
                <div class="col-12 text-center bgtit py-2 fw-bold ">
                    DECLARACIONES
                </div>
            </div>
            <div class="row my-1 ">
                <div class="col-12 text-start py-2 d-flex flex-column txtdark">
                    <span class="fs10">
                        I.- Declara EL COMODANTE por medio de su representante que:
                    </span>
                    <span class="ms-3 fs10">
                        a) Es una sociedad legalmente constituida conforme a la legislación mercantil mexicana.
                    </span>
                    <span class="ms-3 fs10">
                        b) Que su representante cuenta con las facultades necesarias para celebrar el presente contrato, y que no le han sido revocadas ni restringidas de forma alguna.
                    </span>
                    <span class="ms-3 mb-2 fs10">
                        c) Que fabrica y vende una serie de productos que requieren de dispositivos 'Dosificadores' para su consumo, el cual se hace en el inmueble que el COMODATARIO posee o
                        arrienda de tercera persona.
                    </span>
                    <span class="fs10">
                        II.- Declara el COMODATARIO que:
                    </span>
                    <span class="ms-3 fs10">
                        a) Es una persona con personalidad jurádica propia y legalmente facultada para celebrar contratos  </span>
                    <span class="ms-3 fs10">
                        b) Que su representante en este acto cuenta con las facultades necesarias para celebrar el presente contrato.
                        </span>
                    <span class="ms-3 fs10">
                        c) Que adquiere un consumo en el o los inmuebles que ocupa los productos que fabrica o vende de la COMODANTE, para lo cual desea recibir el comodato los dispositivos
                        'Dosificadores' que la COMODANTE ofrece para el suministro al usuario de dichos productos
                    </span>
                    <span class="mt-2 fs10">
                    Ambas partes declaran que no existe dolo, mala fe o cualquier otro vicio que pudiera invalidar parcial o totalmente el presente contrato de acuerdo a las siguientes.
                    </span>
                </div>
            </div>
            <div class="row my-1">
                <div class="col-12 text-center bgtit py-2 fw-bold ">
                    CLAUSULAS
                </div>
                <div class="col-12">
                    <ul class="fs10 text-dark">
                        <li class="mb-1 mt-3">
                           <span class="clstitPDF fw-bold">PRIMERA:</span>  LA COMODANTE entregará en comodato al COMODATARIO los 'Dosificadores y/o Despachadores' necesarios para suministrar el producto adquirido de la
                            COMODANTE al usuario de dicho producto, quedando constancia en la Orden de Instalación a entregarse en el momento de la instalación de los mismos.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">SEGUNDA:</span> LA COMODANTE instalará los 'Dosificadores y/o Despachadores' en el o los inmuebles que EL COMODATARIO ocupa, a través de personas designadas por ella
                            o por sus distribuidores.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">TERCERA:</span> El COMODATARIO se obliga a mantener en buen estado los 'Dosificadores y/o Despachadores', responsabilizándose por los daños que sufran para el caso
                              de vandalismo, uso inadecuado de los mismo e incluso robo en cuyos casos cubrirá a LA COMODANTE el valor de los mismos de acuerdo a lo pactado en este contrato dentro de los 10 días naturales siguientes
                               a que se detecte alguno de estos supuestos, exceptuándose el desgaste debido al uso normal y adecuado de los mismos, en este último caso LA COMODANTE reparará o reemplazará los 'Dosificadores y/o Despachadores’ que se encuentren deteriorados por el uso normal.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">CUARTA:</span> El COMODATARIO se obliga expresamente a destinar los ' Dosificadores y/o Despachadores ' exclusivamente para ser usados con el producto adquirido de
                            COMODANTE, siendo el caso contrario causa de rescisión del presente contrato.
                        </li>
                        <li class="mb-1">
                            <span class="clstitPDF fw-bold"> QUINTA:</span> El COMODATARIO se obliga a permitir la inspección de los 'Dosificadores y/o Despachadores' por parte delCOMODANTE, de sus distribuidores o subdistribuidores de éstos, en cualquier momento, durante días y horas hábiles, para verificar su buen estado y con productos de la comodataria.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">SEXTA:</span> La vigencia de este contrato será por 12 (doce) meses forzosos como máximo, una vez terminado dicho plazo el contrato seguirá vigente por tiempo indeterminado y
                            en caso de que alguna de las partes desee dar por terminado una vez concluido los 12 (doce) meses forzosos, dará aviso por escrito a la contraparte con 30 (treinta) días
                            naturales de anticipación.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">SÉPTIMA:</span> Cuando cualquiera de las partes, en los términos de la cláusula anterior o por rescisión, dé por terminado el presente contrato, EL COMODATARIO devolverá a
                            LA COMODANTE
                            <div class="row d-flex justify-content-center my-3 avoid-break ">
                                <div class="col-10">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr class="bgtitable">
                                                <th class="text-center">Cant.</th>
                                                <th class="text-center">Pruducto</th>
                                                <th class="text-center">Descripción</th>
                                                <th class="text-center text-nowrap">Precio Unitario</th>
                                                <th class="text-center">Importe</th>
                                                <th class="text-center">Consumo Comprometido Mensual</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let art of datosArrayPDF" >
                                                <td>{{art.dblCantidadDespachador}}</td>
                                                <td>
                                                    <div class=" text-center roundedBordestop bg-main " style="width: 70px;">
                                                        <span class="fs10 fw-bold text-white">{{art.strDespachador}}</span>  
                                                    </div>
                                                    <img src="https://www.ecodeli.com/img/PaginaWeb/{{art.strDespachador}}.JPG" class="bg-white roundedBordesbottom" style="width: 70px;" alt="">
                                                </td>
                                                <td class="align-middle">{{art.strNombreDespachador}}</td>
                                                <td>{{art.dblPrecio|currency}}</td>
                                                <td>{{(art.dblCantidadDespachador * art.dblPrecio )|currency}}</td>
                                                <td class="align-middle">
                                                    <div class="fw-bold mb-2">
                                                        <span class="fw-bold">{{art.strConsumible}} </span> <span class="fw-normal">{{art.strNombreConsumible}}</span>
                                                    </div>
                                                    <div class="fw-bold ">
                                                        Precio: <span class="fw-normal">{{art.dblPrecioConsumible|currency}}</span>
                                                    </div>
                                                    <div class="fw-bold mt-2">
                                                        <span class="text-nowrap">Cantidad: </span> <span class="fw-normal">{{art.dblCantidadPrometida}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 mt-3 text-end fs12">
                                    Subtotal: <span class="fw-bold">{{SubtotalPDF|currency}}</span>
                                </div>
                                <div class="col-12 my-1 text-end fs12">
                                    IVA 16%: <span class="fw-bold">{{ivaPDF|currency}}</span>
                                </div>
                                <div class="col-12 mb-2 text-end fs12">
                                    Importe Total: <span class="fw-bold">{{ImportetotalPDF|currency}}</span>
                                </div>
                            </div>
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">OCTAVA:</span>LA COMODANTE retirará los 'Dosificadores y/o Despachadores' a la terminación del presente contrato por su cuenta, y por cuenta del COMODATARIO en caso de
                                rescisión. El COMODATARIO renuncia expresamente en todo caso a cualquier acción en contra de LA COMODANTE por la reparación o resane de las huellas, marcas o
                                vestigios que el retiro de los 'Dosificadores' deje en el inmueble.
                        </li>
                        <li class="mb-1">
                            <span class="clstitPDF fw-bold">NOVENA:</span>Para el caso de que EL COMODATARIO:


                             <div class="col-12 text-start py-2 d-flex flex-column txtdark">
                                <span class="fs10">
                                1).- De por terminado el presente contrato con anterioridad a la vigencia mínima pactada en la cláusula sexta de este contrato;
                                </span>
                                <span class="ms-3 fs10">
                                2).- Deje de adquirir los productos suministrados (consumibles) por LA COMODANTE para ser aplicados con los "Dosificadores y/o Despachadores" durante la citada vigencia mínima, y
                                </span>
                                <span class="ms-3 mb-2 fs10">
                                3).- Desinstale sin aviso y autorización de LA COMODANTE los "Dosificadores y/o Despachadores" dados en comodato durante la citada vigencia mínima.
                                </span>
                            </div>
                            <div class="col-12 my-2">
                                EL COMODATARIO se obliga, a su elección, a pagar a LA COMODANTE el 100% del valor de todos y cada uno de los "Dosificadores y/o Despachadores" que se le hayan otorgado en comodato,
                                o bien, se obliga a adquirir a LA COMODANTE el “consumo comprometido mensual” o “Vta. Prometida” de los consumibles pactada en el recuadro de la cláusula séptima anterior que se 
                                encuentre pendiente de adquirir durante el plazo de la vigencia mínima a LA COMODANTE con base en el precio de lista de esta última vigente a la fecha en que ocurra cualquiera de los 
                                supuestos enumerados con anterioridad; el pago de los "Dosificadores y/o Despachadores" y/o de los consumibles pendientes de adquirirse lo realizará EL COMODATARIO dentro de los 15 
                                días naturales siguientes a que se de cualquiera de los supuestos antes señalados.
                            </div>

                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">DECIMA:</span>Para la interpretación y cumplimiento del presente contrato, las partes se someten a las leyes aplicables, así como a los tribunales 
                             de la ciudad de: León, Guanajuato, renunciando expresamente a cualquier fuero que por razón de su domicilio pudiere corresponderles.
                        </li>
                        <li class="mb-1">
                             <span class="clstitPDF fw-bold">DECIMA PRIMERA:</span> Estando las partes de acuerdo en el contenido del presente contrato, lo firman el día {{getDate()}} fecha en que EL COMODANTE se compromete a la entrega
                                a EL COMODATARIO 'los Dosificadores' que se listan a continuación en las cantidades que se indican:
                            <div class="row d-flex justify-content-center my-3">
                                <div class="col-10">
                                    <table class="table ">
                                        <thead>
                                            <tr class="bgtitable">
                                                <th class="text-center">FIRMA DE AUTORIZACIÓN DEL COMODANTE </th>
                                                <th class="text-center">NOMBRE. FIRMA DEL COMODATARIO Y SELLO DE LA EMPRESA </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="border-end border-start">
                                                    NOMBRE: {{datosArrayPDF[0]?.strGerente| uppercase }}
                                                </td>
                                                <td rowspan="2" class="align-middle text-center">
                                                    NOMBRE: {{datosArrayPDF[0]?.strContacto| uppercase }}
                                                    <div class="col-10 mx-auto">
                                                        <hr class="my-0">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                            <td class="border-end border-start">
                                                CARGO: GERENTE DE VENTAS
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
<!-- =================================================================================================== -->
<!-- =====================================Fin pdf======================================================= -->
<!-- =================================================================================================== -->