import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocalStorageService } from 'ngx-webstorage';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import {FormControl} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { DataDBService } from '../servicios/data-db.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare var $: any;

export interface tareasCte {
  strNombre: string;
}

@Component({
  selector: 'app-minuta-lead',
  templateUrl: './minuta-lead.component.html',
  styleUrls: ['./minuta-lead.component.css']
})
export class MinutaLeadComponent implements   AfterViewInit  {
  @ViewChild('summernote', { static: false }) summernoteRef!: ElementRef;
  @ViewChild('summernote_tarea', { static: false }) summernote_tareaRef!: ElementRef;
  myControl = new FormControl();
  filteredOptions:any[];
  options:any[] = []; 
  columnsTareasCte: string[] = ['btnRadio', 'titulo', 'responsable', 'origen', 'fechaInicio'];/* , 'fechaVencimiento' ,'progreso'*/
  @ViewChild('btnCerrarAct') btnCerrarAct!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputActividades') fileInputActividades!: ElementRef;
  
  objAgregarMiembro:any = {}
  public objInfo:any ={
    intId_Lead:292,
    strTitulo:"1ra Reunion con MegaGas",
    strFecha:"30/09/2024",
    strHoraInicio:"13:00",
    strHoraFin:"01:20",
    strDuracion:"01:20"
  }
  /*variables para editar-elimnar actividades */
  public boolEditaAgente: boolean = true;
   public strComentarioD: string = ''
  public arrayAccesos: any = [];
  public arraySubTareas: any = []
  public objEditarActividad:any ={};
  public strfecha_editar:string;
  public strBuscarAgente:string;
  public strBuscarAct:string = "";
  public arrayAgente_Filter:any=[];
  @ViewChild('btnCerrarEdit') btnCerrarEdit!: ElementRef;
  @ViewChild('editableDiv') editableDiv!: ElementRef;
  @ViewChild('editableDiv2') editableDiv2!: ElementRef;
  /*fin --variables para editar-elimnar actividades */
  public contenido_minuta:string;
  public strAgente_Crea: string;
  public objModal: any = { titulo: "" };
  public strfecha: Date;
  public intIDActividad: any = 0
  public strGerente_header:string = "";
  public strAgente: any;
  public arrayAnexosModal:any = []
  public arrayAnexMdlAdd:any = []
  public boolAgente: boolean = true;
  public arrayAgentes_tareas: any = [];
  public contenido: string = '';
  public tipoMiembro:string="ecodeli"
  public strSucursal_header:string="";
  public strConexion: string = "";
  public mdlComentarios: string = "";
  public time1: string = "am";
  public time2: string = "am";
  public strNombreAdd:string ="";
  public strCorreoAdd:string ="";
  public arrayActividades: any = [];
  public bandMiembro:boolean=false;
  public strResultado: string = "";
  public mdlHoraInicio: string = "07:00";
  public mdlHoraFin: string = "07:00";
  @ViewChild('btnCerrar') btnCerrar!: ElementRef;
  public strUsuario: string;
  public arrayAm: any = [];
  public arraySucursales: any = [];
  public objDetActividad: any;
  public arraypm: any = [];
  public arrayAgentes: any = [];
  public mdlNombre: string = "";
  public arrayAnexoGuardar:any =[]
  public objAct: any;
  public arrayComentarios: any = []
  public arrayAgregarAnexosActividades:any = []
  public arrayEnviarAnexosActividades:any = []
  private unsubscribe$ = new Subject<void>();
  public arrayGerentes:any = [];
  datosTareasCte: MatTableDataSource<tareasCte>;
  public intID_Leads: number;
  public intID_Minuta:number;
  public arrayFileModal:any =[];
 public  isSummernoteInitialized: boolean = false; // Variable para controlar la visibilidad
 public arrayAgentesActividades: any = [];
 public arrayAgregarAnexos: any = [];
 public arrayAnexosExistentes: any = [];

  columnsMiembros: string[] = ['strNombre','strOrigen',"delete"];
  dataMiembros: MatTableDataSource<tareasCte>;
  public arrayMiembros:any = []/* [
    { strNombre: "Carlos Mendoza", strCorreo: "carlos.mendoza@example.com" },
    { strNombre: "Lucía Ramírez", strCorreo: "lucia.ramirez@example.com" },
    { strNombre: "Andrés López", strCorreo: "andres.lopez@example.com" },
    { strNombre: "María Fernández", strCorreo: "maria.fernandez@example.com" },
    { strNombre: "Jorge Pérez", strCorreo: "jorge.perez@example.com" },
    { strNombre: "Ana Morales", strCorreo: "ana.morales@example.com" },
    { strNombre: "Sofía González", strCorreo: "sofia.gonzalez@example.com" },
    { strNombre: "Juan Torres", strCorreo: "juan.torres@example.com" },
    { strNombre: "Patricia Castillo", strCorreo: "patricia.castillo@example.com" },
    { strNombre: "Miguel Hernández", strCorreo: "miguel.hernandez@example.com" }
  ];*/
  @ViewChild('paginatorMiembros') paginatorMiembros: MatPaginator;
  @ViewChild('sortMiembros') sortMiembros: MatSort;
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  @ViewChild('fileModal') fileModal!: ElementRef;
  public objSubtarea: any = []
  
  constructor(private indexedDBService: DataDBService, private route: ActivatedRoute,public router: Router,private paginatorIntl: MatPaginatorIntl, public localSt: LocalStorageService, private _httpService: PeticionesService,private _filtroServ: FiltrosService) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgente_Crea = this.localSt.retrieve('strAgente');
    this.strAgente = this.localSt.retrieve('strAgente');
    this.customizeItemsPerPageLabel();
    let hora = 0, minutos = 0, strH = "", strM = "";
    hora = 7
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayAm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos === 30) {
        minutos = 0;
        hora += 1;
      } else {
        minutos += 30;
      }


      console.log('entra al constructor de minuta')
    }
 
    this.myControl.valueChanges.subscribe(value => {
      this.filteredOptions = this.options.filter(option =>
        option.strNombre.toLowerCase().includes(value.toLowerCase())
      );
    });


  }

  ngOnInit(): void {
 
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
 
      if (this.arrayAccesos.length > 0) {
       // var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        this.strConexion = this.localSt.retrieve('strConexion');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strAgente = this.localSt.retrieve('strAgente');
        this.strUsuario = this.localSt.retrieve('strUsuario'); 
 
        //------------conexi+on-------------------
        this.indexedDBService.getItem('strConexion', (value) => {
          this.strConexion = value;
          /*    console.log(' CARGA conexion directorio') */
        });
      }
      else {
        this.router.navigate(['/login']);
      }
    });
  
  }

  ngAfterViewInit() {

    this.route.queryParams.subscribe(params => {
      this.intID_Minuta = +params['intID_Minuta']; // El signo '+' convierte a número
      console.log(this.intID_Minuta); // Muestra el valor en la consola
      this.fnGetMiembros();
     
    });
    this.fnGetFiltros()
    this.fnGetAgentes_tareas();
    this.fnInitSummer_minuta();
    this.dataMiembros = new MatTableDataSource(this.arrayMiembros);
    this.fntablemiembros();
  
    this.fnInitSummer_tarea();
    this.fnGetMinutaDetalle();
    this.fnExtraerAnexos();
    
    $('.summernote').on('summernote.change', (e, contents) => {
        
      this.contenido_minuta = contents;
      console.log('Contenido cambiado manualmente:', contents);
    });
  }
  fnGetMinutaDetalle(){
    let obj = {
      strAccion: 'getMinuta_Detalle', 
      strConexion: this.strConexion,
      intID_Minuta:this.intID_Minuta
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        console.log(result);
        let resultado=result[0]
        this.objInfo=resultado?resultado:{}
        this.contenido_minuta= resultado.strComentario; 
        this.intID_Leads=resultado.intID_Leads;
        this.fnllenarArrayTareas();
        $('.summernote').summernote('code', this.contenido_minuta);
      }, error => {
     
        console.error('Error al consumir la API:', error);
      });
  }
  fnUpdateMinuta(){ 
    let obj = { 
      strAccion: 'updateMinuta_Data', 
      strConexion: this.strConexion,
      intID_Minuta:this.intID_Minuta,
      strTitulo :this.objInfo.strTitulo,
      strFechaInicioServicio:this.objInfo.strFechaInicioServicio, 
      strHoraInicio:this.objInfo.strHoraInicio
    }
     this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        console.log(result);
        Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
        if (result[0].code == "200") {
          this.fnGetMinutaDetalle();    
        }
       
        $('.summernote').summernote('code', this.contenido_minuta);
      }, error => {
     
        console.error('Error al consumir la API:', error);
      }); 
  }
/**inicial  miembros */
  fnGetMiembros(){
    let obj = {
      strAccion: 'getMiembro_Minuta', 
      strConexion: this.strConexion,
      intID_Minuta:this.intID_Minuta
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        console.log(result);
        this.arrayMiembros=result;
        this.dataMiembros = new MatTableDataSource(this.arrayMiembros);
        this.fntablemiembros()
      }, error => {
     
        console.error('Error al consumir la API:', error);
      });
   
  }
  fnAgregarMiembro(tipo:string){
    let nombre='';
    let correo='';
    let agente =''
    if(tipo=='externo'){
      nombre=this.strNombreAdd;
      correo=this.strCorreoAdd;

    }
    else {
      nombre=this.objAgregarMiembro.strNombre;
      correo=this.objAgregarMiembro.strGiro;
      agente=this.objAgregarMiembro.strAgente;
    }
    let obj = {
      strAccion: 'setMiembro_Minuta', 
      strConexion: this.strConexion,
      intID_Minuta:this.intID_Minuta,
      strContacto_Nombre:nombre,
      strContacto_Email:correo,
      strTipo:tipo,
      strAgente:agente
    }
    console.log(obj)
     this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        console.log(result);
        Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
        if (result[0].code == "200") {
          this.fnGetMiembros();         
        }
      }, error => {
     
        console.error('Error al consumir la API:', error);
      }); 
    
   
  }

  fnEliminarMiembro(objMiembro:any){
    let obj = {
      strAccion: 'deleteMiembro_Minuta', 
      strConexion: this.strConexion,
      intID_Miembro:objMiembro.intID_Miembro, 
    }
    console.log(obj)
     this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        console.log(result);
        Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
        if (result[0].code == "200") {
          this.fnGetMiembros();         
        }
      }, error => {
     
        console.error('Error al consumir la API:', error);
      }); 
  }

  /**fin de miembros */
  /**inicia funcion para guardar notas */
  fnGuardarNota(){
      console.log(this.contenido)
      let obj = {
        strAccion: 'updateMinuta', 
        strConexion: this.strConexion,
        intID_Minuta: this.intID_Minuta,
        strComentario :this.contenido_minuta
      }
      console.log(obj)
       this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
        result => {
          console.log(result);
          Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          if (result[0].code == "200") {
            this.fnGetMiembros();         
          }
        }, error => {
       
          console.error('Error al consumir la API:', error);
        }); 
  }
   /**fin funcion para guardar notas */
  fnGetFiltros(){
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  fnTabletareasCte() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }
 
  fnInitSummer_minuta(){ 
    $('#summernote').summernote('destroy');
    $('#summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onInit: () => {
          console.log('se inicia summer de minuta')
          this.isSummernoteInitialized = true; // Habilitar el editor
          // Ajustar tamaños de las imágenes al inicializar
          $('#summernote').summernote('code', this.contenido_minuta);
        },
        onChange: (contents: string) => {
          console.log('cadena:'+contents )
          this.contenido_minuta = contents;
        },
        onImageUpload: (files) => {
          this.adjustImageSizes();
          this.addImageToEditor(files[0]);
        }
      
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['picture']]
      ],
      popover: {
        image: [
          ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ]
      }
    });

    // Habilitar el input para capturar imágenes desde la cámara
    $('#summernote').on('summernote.init', function () {
      $('input[type="file"]').attr('accept', 'image/*').attr('capture', 'camera');
    });
  }
  fnInitSummer_tarea (){
    $('#summernote_tarea').summernote('destroy');
    $('#summernote_tarea').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
        onImageUpload: (files) => {
        //  this.adjustImageSizes();
          //this.addImageToEditor(files[0]);
        },
        onInit: () => {
          // Ajustar tamaños de las imágenes al inicializar
        }
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['picture']]
      ],
      popover: {
        image: [
          ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ]
      }
    });

    // Habilitar el input para capturar imágenes desde la cámara
    $('#summernote_tarea').on('summernote_tarea.init', function () {
      $('input[type="file"]').attr('accept', 'image/*').attr('capture', 'camera');
    });
  }
    // Método para agregar la imagen al editor
    addImageToEditor(file: File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imgNode = $('<img>').attr('src', e.target.result as string).css({
          width: '300px !important',  // Ancho fijo con !important
          height: 'auto !important'   // Mantiene la proporción con !important
        });
        $('.summernote').summernote('insertNode', imgNode[0]);
      };
      reader.readAsDataURL(file);
    }
  
    adjustImageSizes() {
      $('.summernote').summernote('code', function (contents) {
        const $contents = $('<div>').html(contents);
        $contents.find('img').each(function () {
          $(this).css({
            width: '100px !important',  // Ancho deseado
            height: '100px !important'   // Mantiene la proporción
          }).addClass('custom-img-size'); // Asegurar que tenga la clase personalizada
        });
        return $contents.html();
      });
    }

  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
  }

  fntablemiembros() {
    this.dataMiembros.paginator = this.paginatorMiembros;
    this.dataMiembros.sort = this.sortMiembros;
  }

  applyFilterMiembros(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataMiembros.filter = filterValue.trim().toLowerCase();
    if (this.dataMiembros.paginator) {
      this.dataMiembros.paginator.firstPage();
    }
  }
  
  addMiembro(){
    if(this.bandMiembro){
      this.bandMiembro =false
    }else{
      this.bandMiembro =true
    }
  }

  fnSelectEmpleado(object:any){
    console.log(object);
    this.objAgregarMiembro = object
  }
  fnSetMiembro(){

  }

  limpiar(){
    this.strNombreAdd =""
this.strCorreoAdd =""
this.objAgregarMiembro ={}
  }

  fnModalRegistrar(tipo: string) {
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = tipo

  }

  fnGetAgentes_tareas(){
    let obj = {
      strAccion: 'getAgentes_Leads', 
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
      
         this.arrayAgentes_tareas=result
          this.options=result;
      }, error => {
     
        console.error('Error al consumir la API:', error);
      });
  }
  onInput(event: Event) {
    const target = event.target as HTMLElement;
    this.mdlComentarios= target.innerHTML; // Guarda el contenido HTML
  }
  

  fnEnviarActividad(anexos:any = {}) {
    console.log(anexos)
    let xml: any = []
    if (this.arrayAgentesActividades.length > 0) {
      this.arrayAgentesActividades.forEach(item => {
        xml.push({ strAgente: item.strAgente, strNombreAgente: item.strNombre })
      });
    }
  

    if (this.strfecha == null || this.strfecha == undefined) {
      Swal.fire("Error", "seleccione una fecha correcta", "error");
    } else {
      let objEnv: { [key: string]: any } = {
        strAccion: 'setActividad_Multiples_Minuta',
        intID_Leads: this.intID_Leads,
        intID_TIPO:  6,// aplica solo para actividad de tipo tarea
        strTitulo: this.mdlNombre,
        strAgente_Crea: this.strAgente_Crea,
        strAgente: this.strAgente,
        strFechaInicioServicio: this.convertDateToString(this.strfecha),
        strConexion: this.strConexion,
        strComentario: this.mdlComentarios,
        intID_Minuta:this.intID_Minuta,
        xmlArticulos: xml,
        strEstatus:""
      }
      if(anexos && Object.keys(anexos).length>0){
        objEnv.strTipo = anexos.strTipo;
        objEnv.strUrl_Logo = anexos.strRespuesta;
        objEnv.strNombreCorto = anexos.strNombre;
      }
      

      if (this.objModal.titulo != "Tarea") {
        objEnv.strHoraInicio = this.mdlHoraInicio;
        objEnv.strHoraFin = this.mdlHoraFin;
      }

      if(this.objModal.titulo == "Subtarea"){
        objEnv.intID_Actividad = this.objSubtarea.intID_Actividad;
      }
      console.log(objEnv)
      this._httpService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads').subscribe(
        result => {
          Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          if (result[0].code == "200") {
         
            this.btnCerrar.nativeElement.click();
            //RECARGAR ACTIVIDADES

            this.fnllenarArrayTareas();
          }
        }, error => {
          var error = <any>error; console.log(error);
        }
      );
    }
  }
  convertDateToString(dateString: Date): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
/**funciones para las tareas */
  fnllenarArrayTareas() {
    this.arrayActividades = [];
    /*  this.arrayActividades = this.arrayOport.filter(o=>o.strTipo=="Visita" ||o.strTipo=="Llamada" ||o.strTipo=="Correo") */

    this._httpService.mostrarCotizaciones({
      strAccion: 'getActividad_Minuta',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion,
      strGerente: this.strGerente_header,
      strSucursal :this.strSucursal_header,
      intID_Minuta :this.intID_Minuta 
    }, 'sp_ModVentas_Leads').subscribe(
      result => {
        this.arrayActividades = result
        console.log(this.arrayActividades)
        if (this.arrayActividades.length > 0) {
          this.arrayActividades.forEach(obj => {
            // Paso 1: Convertir strFechaRequerida a un objeto Date
            let fechaRequerida = new Date(obj.strFechaRequerida);
            // Paso 2: Obtener la fecha actual
            let fechaActual = new Date();
            // Paso 3: Calcular la diferencia en milisegundos
            let diferenciaMilisegundos = fechaRequerida.getTime() - fechaActual.getTime();
            // Paso 4: Convertir la diferencia de milisegundos a días
            let diferenciaDias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
            obj.diasDiferencia = diferenciaDias
          });
        }
        this.datosTareasCte = new MatTableDataSource(this.arrayActividades);
        this.fnTabletareasCte();
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }

  fnmDLConAct(obj: any) {
    this.arrayFileModal = [];
    this.intIDActividad = obj.intID_Actividad
    this.contenido='';
    $('#summernote_tarea').summernote('code', this.contenido);
    this.fnInitSummer_tarea();
    this.objAct = obj;
    this.fngetComentarios()
    let agente = this.arrayAgentes.find(x => x.strAgente == obj.strAgente_Crea)
    this.objAct.nombreCrea = agente.strNombre
    console.log(this.objAct);
    if (obj.strEstatus == "CONCLUIDO" || this.objAct?.strTipo == 'Update_Lead_Tarea' || obj.strEstatus == "POR AUTORIZAR") {
      this.fnGetDetActi(obj);
    }
    this.fnGetAnexosActividades()
  }

  fnGetDetActi(objAct: any) {
    this.objDetActividad = {};
    let objEnv = {
      strAccion: 'getDetalleActividad',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads').subscribe(
      result => {
        this.objDetActividad = result[0];
        this.objAct.strObservaciones =  this.objDetActividad.strObservaciones ==""?"--campo vacio--":  this.objDetActividad.strObservaciones 
        this.objAct.strAsunto=  this.objDetActividad.strAsunto ==""?"--campo vacio--":  this.objDetActividad.strAsunto 
        this.objAct.strResultado =this.objDetActividad.strResultado =="" ?"0":this.objDetActividad.strResultado

        console.log(objAct)
      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnConcluirActividad(objAct: any) {
    let processedContent = this.contenido;
    processedContent = processedContent.replace(/<img/g, '<img class="img-fluid"');

    // Ahora se puede asignar el contenido procesado a [innerHTML]
    this.contenido = processedContent;
    let objEnv = {
      strAccion: 'update_Actividad',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intID_Leads,
      strComentario: this.contenido,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }
    console.log(objEnv)
 
    this._httpService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = ''
          this.contenido = ''
         
          this.fnllenarArrayTareas();  
          this.btnCerrarAct.nativeElement.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    ); 
  }

  fnEnProceso(objAct: any) {

    this._httpService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Proceso',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: objAct.intID_Leads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = "";
          this.contenido = "";
          this.fnllenarArrayTareas()
          this.btnCerrarAct.nativeElement.click();

        } else {
          this.contenido = ''
          /*    this.strMensaje = '' */
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAutorizar(objAct: any) {
    let objEnv = {
      strAccion: 'update_Actividad_Autorizar',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Autorizado',
            text: result[0].strMensaje,
          });
          this.fnllenarArrayTareas()
          this.btnCerrarAct.nativeElement.click()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result[0].strMensaje,
          });
        }
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }

  fnRechazar(objAct: any) {

    this._httpService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Rechazar',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: objAct.intID_Leads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = ""
          this.contenido = "";
          this.fnllenarArrayTareas();
          this.btnCerrarAct.nativeElement.click();


        } else {
          this.contenido = ''

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
 
/***inician funciones para editar y eliminar actividades *****/
fnDelete_Tarea( tarea:any){
  let intID_Actividad =tarea.intID_Actividad;
  Swal.fire({
    title: "¿Estás seguro de eliminar esta Actividad?",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Sí, Eliminar",
    denyButtonText: `No, Cancelar`
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
    
      this.fnEliminar_Tarea(intID_Actividad)
    
    } else if (result.isDenied) {
      Swal.fire("No se guardaron los cambios", "", "info");
    }
  });
  
}
fnEliminar_Tarea(intID_Actividad:number){
  console.log(intID_Actividad);
  let objParam ={
    strAccion: 'deleteTarea', 
    intID_Actividad:intID_Actividad,
    strConexion: this.strConexion
  }
  
  this._httpService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads').subscribe(
    result => {
      let resultado= result[0];
      if(resultado && resultado.code ==200){
        Swal.fire("Tarea Eliminada Correctamente", "", "success");
      }
      else {
        Swal.fire("Error al eliminar Tarea", "", "warning");
      }
      this.fnllenarArrayTareas();
    },
    error => {
      var error = <any>error;
      Swal.fire("Error al eliminar Tarea", "", "warning");
      console.log(error);
    }
  );
}
fnEditar_Actividad (objAct:any){
  console.log(objAct);
    this.objEditarActividad={};
    this.objEditarActividad= objAct;
    this.mdlNombre=this.objEditarActividad.strTitulo;
  let strf =this.objEditarActividad.strFechaRequerida;

  // Dividir la cadena en partes
  const [datePart, timePart] = strf.split(' '); // Divide en fecha y hora
  const [month, day, year] = datePart.split('/'); // Divide la parte de fecha en mes, día y año
    
    this.strfecha_editar =  year +'-'+ month+'-'+day;
   // let hora1 = this.objEditarActividad.strHoraInicio.slice(0, -3);
   // this.mdlHoraInicio= hora1 ;
   // let hora2 = this.objEditarActividad.strHoraFin.slice(0, -3);
   // this.mdlHoraFin =hora2;
    this.strAgente= this.objEditarActividad.strAgente;
    this.strBuscarAgente=this.objEditarActividad.strAgente+'-'+this.objEditarActividad.strNombreAgente ;
    this.mdlComentarios=this.objEditarActividad.strObservaciones;
    this.editableDiv.nativeElement.innerHTML = this.mdlComentarios; 
     
}
fnEditarActividad(intID_Actividad:number){
  console.log(intID_Actividad);
  let objParam ={
    strAccion: 'editTarea', 
    strFechaInicioServicio: this.strfecha_editar,
    strComentario: this.mdlComentarios,
    strAgente: this.strAgente,
    intID_Actividad:intID_Actividad,
    strConexion: this.strConexion,
    strTitulo: this.mdlNombre,
    strHoraInicio: this.mdlHoraInicio,
    strHoraFin: this.mdlHoraFin
  }
  
 
  this._httpService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads').subscribe(
    result => {
      let resultado= result[0];
      if(resultado && resultado.code ==200){
        Swal.fire("Tarea Editada Correctamente", "", "success");
      }
      else {
        Swal.fire("Error al Editar Tarea", "", "warning");
      }
      this.btnCerrarEdit.nativeElement.click();
      this.fnllenarArrayTareas();
    },
    error => {
      var error = <any>error;
      Swal.fire("Error al eliminar Tarea", "", "warning");
      console.log(error);
    }
  );
}

fnBusquedaCte(strBuscarAgente:string){
  let texto=strBuscarAgente.toUpperCase();
      this.arrayAgente_Filter=[];
      for(const post of this.arrayAgentes_tareas){
        let texto2=post.strNombre.toUpperCase();
        if(texto2.indexOf(texto) > -1){
          this.arrayAgente_Filter.push(post);
        }      
      };
}
/*
fnSeleccionaAgente (agt:any){
  this.strAgente=agt.strAgente; 
  this.strBuscarAgente=agt.strAgente+'-'+agt.strNombre ;
  this.arrayAgente_Filter=[];
}*/

  fnSeleccionaAgente(agt: any) {
    this.strAgente = agt.strAgente;
    this.strBuscarAgente = "";/* agt.strAgente+'-'+agt.strNombre  */
    this.arrayAgente_Filter = [];
    let findAgente = this.arrayAgentesActividades.find(agtf => agtf.strAgente == agt.strAgente);
    if (!findAgente) {
      this.arrayAgentesActividades.push(agt)
    } else {
      Swal.fire("Error", "Agente existente, seleccione otro agente.", "error")
    }

  }

  fnEliminarAgenteAct(agt: any) {

    this.arrayAgentesActividades = this.arrayAgentesActividades.filter(item => item !== agt);
  }


  fnAdjuntarArchivos(): void {
   
    if (this.fileInput) {
      this.arrayAgregarAnexos = [];
      this.fileInput.nativeElement.value = '';
      this.fileInput.nativeElement.click();
    }
  }

  fnAdjuntarArchivosActividad(): void {
   
    if (this.fileInputActividades) {
      this.arrayAgregarAnexosActividades =[];
      this.fileInputActividades.nativeElement.value = '';
      this.fileInputActividades.nativeElement.click();
    }
  }

  fnEliminarAnexosAct(): void {
    this.fileInputActividades.nativeElement.value = '';
      this.arrayAgregarAnexosActividades =[];
  }


    onFileSelected(event: any): void {
      const files: FileList = event.target.files;
  
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          let newTotalSize = file.size;
          if (newTotalSize > 10 * 1024 * 1024) {
            Swal.fire({
              icon: 'warning',
              title: 'Límite de almacenamiento alcanzado',
              text: 'No puedes agregar más archivos, el límite es de 10MB.',
            });
            return; 
          }
    
          const nombreOriginal = file.name.split('.').slice(0, -1).join('.'); 
          const extension = file.name.split('.').pop(); 
    
          this.arrayAgregarAnexos.push({
            nombreArchivo: nombreOriginal,
            extencion: extension,
            archivo: file
          });
    
        }
      }
    }
    
    

  eliminarArchivo(index: number): void {
    this.fileInput.nativeElement.value = '';
    this.arrayAgregarAnexos.splice(index, 1);
  }
  fnInsertComentario() {
  
      let obj = {
        strAccion: 'setComentario_Leads_Actividad',
        intID_Actividad: this.intIDActividad,
        strAgente: this.strAgente,
        strComentario: this.strComentarioD,
        strConexion: this.strConexion
      }
      this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            if (result[0].code == 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: result[0].strMensaje,
                showConfirmButton: false,
                timer: 1500
              });
              this.strComentarioD = ''
              this.fngetComentarios();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: result[0].strMensaje,
              });
            }
          }, error => {
            var error = <any>error; console.log(error);
           // this.spinerBool = false
          });
    }

    fngetComentarios() {

      let obj = {
        strAccion: 'getListComentario_Actividad',
        intID_Actividad: this.intIDActividad,
        strConexion: this.strConexion
      }
      this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.arrayComentarios = result
          }, error => {
            var error = <any>error; console.log(error);
           // this.spinerBool = false
          });
    }

    


    
      SubirAnexos() {
        let formData = new FormData();
        this.arrayAgregarAnexos.forEach((o, index) => {
          if (o.archivo instanceof File) {
            formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
          } else {
            console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
          }
        });
       
        if (this.arrayAgregarAnexos.length > 0) {
          this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Minuta.php', [], formData).then((result) => {
            console.log(result)
            let arrayRest = result;
            arrayRest.forEach(obj => {
              this.arrayAnexoGuardar.push(
                {intIDPregunta:this.intID_Minuta,
                  strTipo:obj.strTipo,
                  strRespuesta:obj.file,
                  strNombre:obj.strNombre,
                  strAgente:this.strAgente,
                  strEstatus:""
                });
            });
            this.fnGuardarAnexos(this.arrayAnexoGuardar) 
            /*   if(result[0].code == 200){
              Swal.fire({
                icon: 'success',
                title: 'Correcto...',
                text:  result[0].strMensaje,
              });
              
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error...',
                text: result[0].strMensaje,
              });
            } */
     
          }, (error) => {
            console.log(error);
          });
        } else {
        
        }
    
      }

      fnEliminarArchivos(){
        Swal.fire({
          title: "¿Estas seguro?",
          text: "No podrás revertir los cambios efectuados!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Borrar!"
        }).then((result) => {
          if (result.isConfirmed) {
            this.arrayAgregarAnexos = []
            Swal.fire({
              title: "Eliminados!",
              text: "Tus archivos han sido eliminados.",
              icon: "success"
            });
          }
        });
       
      }

      fnGuardarAnexos(arrayArchivos:any){
        let obj = {
          strAccion: 'setMinuta_Anexos',
          xmlArticulos:arrayArchivos,
          strConexion: this.strConexion
        }
        this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              if (result[0].code == 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: result[0].strMensaje,
                  showConfirmButton: false,
                  timer: 1500
                });
               
                this.arrayAgregarAnexos = []
                this.arrayAnexoGuardar = []
                this.fnExtraerAnexos()
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: result[0].strMensaje,
                });
              }
            }, error => {
              var error = <any>error; console.log(error);
             // this.spinerBool = false
            });
      }

      fnExtraerAnexos(){
        this.arrayAnexosExistentes = [];
        let obj = {
          strAccion: 'getAnexos_Minuta',
          intID_Leads:this.intID_Minuta,
          strConexion: this.strConexion
        }
        this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              if(result.length>0){
                this.arrayAnexosExistentes = result
                this.arrayAnexosExistentes.forEach(obj => {
                  obj.urlImagen = "https://www.ecodeli.com/"+obj.strUrl_Logo
                });
                console.log(this.arrayAnexosExistentes);
              }
             
            }, error => {
              var error = <any>error; console.log(error);
             // this.spinerBool = false
            });
      }



      onFileSelectedActividad(event: any): void {
        let files: FileList = event.target.files;
 
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let newTotalSize =  file.size;
            if (newTotalSize > 10 * 1024 * 1024) {
              Swal.fire({
                icon: 'warning',
                title: 'Límite de almacenamiento alcanzado',
                text: 'No puedes agregar más archivos, el límite es de 10MB.',
              });
              return; 
            }
      
            let nombreOriginal = file.name.split('.').slice(0, -1).join('.'); 
            let extension = file.name.split('.').pop(); 
      
            this.arrayAgregarAnexosActividades.push({
              nombreArchivo: nombreOriginal,
              extencion: extension,
              archivo: file
            });
          }
        }
      }
     
      fnAnexosActividad(){
     
        if (this.arrayAgregarAnexosActividades.length > 0) {
        let formData = new FormData();
        this.arrayAgregarAnexosActividades.forEach((o, index) => {
          if (o.archivo instanceof File) {
            formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
          } else {
            console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
          }
        });
          this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/AnexoActividad_Minuta.php', [], formData).then((result) => {
            console.log(result)
            this.arrayEnviarAnexosActividades = []
            let arrayRest = result;
            arrayRest.forEach(obj => {
              this.arrayEnviarAnexosActividades.push(
                {intID_Minuta:this.intID_Minuta,
                  strTipo:obj.strTipo,
                  strRespuesta:obj.file,
                  strNombre:obj.strNombre,
                  strAgente:this.strAgente,
                  strEstatus:""
                });
            });
            console.log(this.arrayEnviarAnexosActividades[0])
            this.fnEnviarActividad(this.arrayEnviarAnexosActividades[0]) 
          }, (error) => {
            console.log(error);
          });
        } else {
          this.fnEnviarActividad() 
        }
      }
 
      fnAbrirModal(){
        console.log('abrir modal')
        this.editableDiv2.nativeElement.innerHTML ='';

        this.arrayAgregarAnexosActividades = []
        this.arrayEnviarAnexosActividades = []
        this.arrayAgentesActividades = []
        this.strBuscarAgente =""
        this.mdlNombre = ""
        this.strfecha = null
      }

      fnGetAnexosActividades(){
        this.arrayAnexosModal = [];
        let obj = {
          strAccion: 'getAnexosActMin',
          intID_Minuta:this.intID_Minuta,
          intID_Actividad:this.intIDActividad,
          strConexion: this.strConexion
        }

        this._httpService.mostrarCotizaciones(obj, 'sp_modVentas_Leads')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              console.log(result)
              if(result.length>0){
                this.arrayAnexosModal = result
                this.arrayAnexosModal.forEach(obj => {
                  obj.urlImagen = "https://www.ecodeli.com/"+obj.strUrl_Logo
                });
                console.log(this.arrayAnexosModal);
              }
             
            }, error => {
              var error = <any>error; console.log(error);
             // this.spinerBool = false
            });
        
      }

      fnAjuntarAnexsModal(): void {
        if (this.fileModal) {
          this.arrayAnexMdlAdd = [];
          this.fileModal.nativeElement.value= ""
          this.fileModal.nativeElement.click();
        }
      }
      
      onFileInsert(event: any): void {
        let files: FileList = event.target.files;
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            let newTotalSize =  file.size;
            if (newTotalSize > 10 * 1024 * 1024) {
              Swal.fire({
                icon: 'warning',
                title: 'Límite de almacenamiento alcanzado',
                text: 'No puedes agregar más archivos, el límite es de 10MB.',
              });
              return; 
            }
      
            const nombreOriginal = file.name.split('.').slice(0, -1).join('.'); 
            const extension = file.name.split('.').pop(); 
      
            this.arrayAnexMdlAdd.push({
              nombreArchivo: nombreOriginal,
              extencion: extension,
              archivo: file
            });
      
          }
        }
      }

      fnAgregarArchivo(){
        let formData = new FormData();
        this.arrayAnexMdlAdd.forEach((o, index) => {
          if (o.archivo instanceof File) {
            formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
          } else {
            console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
          }
        });
        console.log(this.arrayAnexMdlAdd)
          this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/AnexoActividad_Minuta.php', [], formData).then((result) => {
            console.log(result)
            if(result.length>0){
              this.arrayEnviarAnexosActividades = []
              let arrayRest = result;
              let enviar = {}
              arrayRest.forEach(obj => {
                enviar = {
                  strAccion: 'setAnexActMin',
                  intID_Minuta:this.intID_Minuta,
                  intID_Actividad:this.intIDActividad,
                  strConexion: this.strConexion,
                  strTipo:obj.strTipo,
                  strUrl_Logo:obj.file,
                  strNombreCorto:obj.strNombre,
                  strAgente:this.strAgente,
                  strEstatus:""
                }
              });
              this.fnGuardarArchivo(enviar);
            }else{
              Swal.fire("Error","Error al guardar archivo.","error");
            }
          }, (error) => {
            console.log(error);
          });
      }
      fnElinimarArchivos(){
        this.arrayAnexMdlAdd = []
      }

      fnGuardarArchivo(obj:any){
        this._httpService.mostrarCotizaciones(obj, 'sp_modVentas_Leads')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              console.log(result)
              if(result[0].code == 200){
                Swal.fire("Guardado",result[0].strMensaje,"success")
                  this.arrayAnexMdlAdd = [];
                  this.fileModal.nativeElement.value= ""
                  this.arrayAnexosModal = [];
                  this.fnGetAnexosActividades()
              }else{
                Swal.fire("Error",result[0].strMensaje,"error")
              }
            }, error => {
              var error = <any>error; console.log(error);
             // this.spinerBool = false
            });
      }

        fnSeeRow(ind: any, actividad: any) {
          console.log(ind)
          this.fngetSubtareas(actividad.intID_Actividad)
           let valAnt = this.arrayActividades[ind].seeSubAct
          this.arrayActividades.forEach(act => {
            act.seeSubAct = false
          });
      
          this.arrayActividades[ind].seeSubAct = valAnt == true ? false : true 
        }
      
         fngetSubtareas(intID_Actividad: any) {
            this.arraySubTareas = [];
            let obj = {
              strAccion: 'getSubActividad_Minuta',
             /* strAgente: this.strAgente,
               strSucursal: this.strSucursal,
              strGerente: this.strGerente, */ 
              intID_Minuta :this.intID_Minuta,
              intID_Actividad: intID_Actividad,
              strConexion: this.strConexion
            };
            console.log(obj)
            this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads')
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                result => {
                  console.log(result)
                  this.arraySubTareas =  result
                 /*  this.arraySubTareas.forEach(item => {
                    let objAgente = this.arrayAgentesTodos.find(o => o.strAgente == item.strAgente_Crea)
                    item.nombreCrea = objAgente && objAgente.strNombre ? objAgente.strNombre : "";
                    item.select = false;
                  }); */
                }, error => {
                  //this.spinnerTab = false
                  console.error('Error al consumir la API:', error);
        
                });
          }

          fnModalRegistrarSubtarea(tipo: string, obj: any) {
  
            this.objSubtarea = obj
            this.editableDiv2.nativeElement.innerHTML ='';
            this.mdlHoraInicio = "07:00";
            this.mdlHoraFin = "07:00";
            this.mdlNombre = "";
            this.time1 = "am";
            this.time2 = "am";
            this.mdlComentarios = "";
            this.strfecha = null;
            this.objModal.titulo = tipo;
            this.objModal.subTitulo = obj.intID_Leads + '.-' + obj.strTitulo
            this.arrayAgentesActividades = [];
            this.strBuscarAgente = "";
            this.arrayAgregarAnexosActividades = [];
            this.fileInputActividades.nativeElement.value = '';
          }
          fnCleanSearch(){
            this.strBuscarAct = ""
          }
}
