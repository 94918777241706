import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtersearch'
})
export class FiltersearchPipe implements PipeTransform {
  transform(items: any[], searchText: string, fields: string[]): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    
    return items.filter(item => {
      for (const field of fields) {
      
        //veririficamos si el campo es de tipo string, de lo contrario convertimos a tring para eviatar errores
        let busqueda = typeof item[field] === 'string'?item[field].toLowerCase(): String(item[field]).toLowerCase();
      
        if (item[field] && busqueda.includes(searchText)) {
          return true;
        }
      }
      return false;
    });
  }

/*   transform(items: any[], searchText: string, fieldName: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    
    return items.filter(item => {
      return item[fieldName].toLowerCase().includes(searchText);
    });
  } */

}
