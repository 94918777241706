import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';


export interface data {
  strIDPedido: string;
  strCliente: string;
  strClienteNombreCorto: string;
  strCteEnviarANombre: string;
  strFechaSolicitud: string;
  strAgenteNombre: string;
  strEstatusE:string;
}

@Component({
  selector: 'app-cliente-seguimiento-pedidos',
  templateUrl: './cliente-seguimiento-pedidos.component.html',
  styleUrls: ['./cliente-seguimiento-pedidos.component.css']
})
export class ClienteSeguimientoPedidosComponent implements OnInit {

   arrayEjercicio:any=[];
        @ViewChild(MatPaginator) paginator: MatPaginator;
        @ViewChild(MatSort) sort: MatSort;
      intEjercicio:number=0;
      intPeriodo:number; 
      inputSearch:string="";
      isHoveredButton: boolean = false;
      spinerPed: boolean = false;
      displayedColumns: string[] = [ 'strCliente','strNombreCorto','strOrdenCompra','strArchivoOrdenCompra','strFechaOrdenCompra', 'PedidoEcodeli','strEstatusPedido','strRequisicion' ,'dblImporte' ,'facturaEcodeli', 'fechaemisionFactura','importeFactura','fechaEntregaD','evidenciaEntrega','estatusEntrega','indicadorDias' ];
      dataSource: MatTableDataSource<data>;
      public strConexion:string="";
      public arrayAgentes:any=[];
      public arrayPeriodo:any= [];
      public strAgente:string="";
      public arrayAccesos:any=[];
      public arrayPedidos:any =[]
      public strUsuario: string;
      public strObservaciones:string="";
      public boolRechazarPro:boolean=false;
      public strEstatus:string=""
      public strAgente_Crea: string;
      public strCliente:string="";
      public strFamilia:string="";
      public objModal:any={};
      public objCliente:any ={};
      public spinerModal:boolean=false;
      public objEncabezado:any ={};
      public boolEditaAgente: boolean = true;
      public tagName:any;
      public rutaPdf :string = "http://35.202.0.97/CFD/";
      public DocumentosConEvidencia : number;
      public DocumentosSinEvidencia: number;
      public arrayRazonSocial:any = [];
      public model_razonSocial:string = '';
      public arraySucursalcte:any = [];
      public model_Sucursalcte:string = '';
      public model_ESTATUS:string='';
    constructor(private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl,  elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
         private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
          
          this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
      let fecha = new Date();
      this.intEjercicio = fecha.getFullYear();
      this.intPeriodo = fecha.getMonth() + 1;
      this.strConexion = this.localSt.retrieve('strConexion');
      this.tagName = elem.nativeElement.tagName.toLowerCase();
      this._httpService.setMostrarMenu(true);
  
      
    }
    ngOnInit(): void {
      this.indexedDBService.getItem('arrayResult', (value) => {
        this.arrayAccesos = value ? value : [];
        if (this.arrayAccesos.length > 0) {
          var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgente_Crea = this.localSt.retrieve('strAgente');
          this.strAgente = this.localSt.retrieve('strAgente');
          this.strUsuario = this.localSt.retrieve('strUsuario');
          this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;
          //------------conexi+on-------------------
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
      
          });
          let fecha = new Date();
          this.intEjercicio = fecha.getFullYear();
          this.intPeriodo = fecha.getMonth() + 1;
          this.objCliente = this.arrayAccesos[0]; 
          this.strFamilia = this.objCliente.strFamiliaCte;
          this.strCliente = this.objCliente.strCliente;
          this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
          this.fnGetPedidos();
        }
        else {
          this.router.navigate(['/login']);
        }
      });
    this.fnGetFiltros(); 
    }
  
    fntabla(){
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  
    fnGetFiltros() {
      this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
        this.arrayPeriodo = nuevoValor;
      });
      this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
        this.arrayEjercicio = nuevoValor;
      });
      this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  
        this.arrayAgentes = nuevoValor;
      });
    }
  
  
    fnGetPedidos(){
      this.spinerPed= true;
      this.arrayPedidos = [];
      let objEnv = {
        intEjercicio: this.intEjercicio,
        intPeriodo: this.intPeriodo,
         strCliente:this.strCliente ,
         strAccion: 'getPedidosFact',
         strFamilia:this.strFamilia
      };
   
      this._httpService.getClienteDatos(objEnv,"sp_SeguimientoPedidos").subscribe(
        result => {
          console.log(result);
          this.arrayPedidos = result;
          this.DocumentosConEvidencia =0;
          this.DocumentosSinEvidencia = 0;
          //let arrayInsert = this.arrayPedidos.filter(o => o.strEstatus == this.strEstatus);
          this.arrayPedidos.forEach(element => {
            let aux = this.arrayRazonSocial.find(o=> o.strNombreCte ==element.strNombreCte );
            let aux2 =  this.arraySucursalcte.find(o=> o.strNombreSucursalCte ==element.strNombreSucursalCte );
            if(!aux){
              this.arrayRazonSocial.push({strNombreCte:element.strNombreCte}) ;
            }
            if(!aux2){
              this.arraySucursalcte.push({strNombreSucursalCte:element.strNombreSucursalCte}) ;
            }
          });
          this.spinerPed= false;
          this.dataSource = new MatTableDataSource(this.arrayPedidos);
          this.fntabla()
        }, error=>{
          this.spinerPed= false;
        });
    }
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    fnFiltrar_otros(valor:string){
      const filterValue = valor;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    fnFiltrar_estatus(valor:string){
      const filterValue = valor;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    fnFiltrar(){
   
      this.spinerPed= false;
     this.fnGetPedidos();
      
    }
    fnAbrirModal(obj:any){
      console.log(obj)
      this.boolRechazarPro = false;
      this.objModal.pedido = obj.strIDPedido
      this.spinerModal= true;
      this.objEncabezado = {};
      let objEnv = {
        strIDPedido: obj.strIDPedido,
        strConexion:this.strConexion
      };
  
      console.log(objEnv)
      this._httpService.getClienteDatos(objEnv,"getSolicitudPedidosClienteDetalle").subscribe(
        result => {
          console.log(result);
          this.objEncabezado = result[0];
          this.spinerModal= false;
        }, error=>{
          this.spinerModal= false;
        });
  
  
        let objEnv2 = {
          strIDPedido: obj.strIDPedido,
          strConexion:this.strConexion
        };
    
        console.log(objEnv)
        this._httpService.getClienteDatos(objEnv2,"getPedidoClienteArticulos").subscribe(
          result => {
            console.log(result);
            this.objModal.arrayPedido = result;
            if(this.objModal.arrayPedido.length>0){
              this.objModal.totalPrecio = 0
              this.objModal.arrayPedido.forEach(obj => {
                this.objModal.totalPrecio = this.objModal.totalPrecio + obj.dblPrecio 
              });
            }
            this.spinerModal= false;
          }, error=>{
            this.spinerModal= false;
          });
    }
  
    fnRechazarProd(accion:string,obj:any={}){
      if(accion == "cancelProd"){
        this.objModal.tituloRechazo = obj.strArticulo ? obj.strArticulo:"";
        this.boolRechazarPro = true
      }else{
        this.boolRechazarPro = false
      }
     
    }

}
