<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold  text-decoration-underline">Reporte de <span class="titulo">Clientes Faltantes.</span></h1>
        </div>
    </div>
    <div class="row bg-white justify-content-between bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 d-flex justify-content-center"[ngClass]="{'col-md-5':!boolGerente,'col-md-3':boolGerente}"  *ngIf="boolSucursal"><!-- [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" -->
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 d-flex justify-content-center" [ngClass]="{'col-md-5':!boolGerente && boolSucursal,'col-md-7':!boolGerente && !boolSucursal,'col-md-3':boolGerente && boolSucursal}"  *ngIf="boolAgente"><!--  [ngClass]="{'col-xl-3':boolGerente||boolSucursal}"  -->
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        
        <div class="col-12 mt-2 pt-1  col-md-2 d-flex justify-content-center align-items-center"  ><!-- [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" -->
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnFiltrarPorAcceso()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12 d-flex justify-content-center" *ngIf="spiner">
            <mat-spinner [diameter]="60"  class="spinner-container"></mat-spinner> 
        </div>
        <div class="col-12 d-flex justify-content-center" *ngIf="!spiner && arrayCtesFaltantes.length==0">
            <div class="alert alert-danger col-12 text-center fw-bold" role="alert">
                Es necesario seleccionar un agente y aplicar el filtro correspondiente.
            </div>
        </div>
        <div class="col-12 my-3 bordertop shadow borderbott px-0" [hidden]="arrayCtesFaltantes.length==0">
            <div class="col-12 bgTableCte py-2 ps-3 bordertop">
                <mat-form-field appearance="outline" class="bg-white text-dark rounded">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilterTareasCte($event)"
                        placeholder="ID,Tarea,etc..." #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
            <div class="mb-0 table-responsive">
                <div class="mat-elevation-z8 mb-0  shadow rounded">
                    <table mat-table [dataSource]="dataSourceCtes" class="table table-hover table-bordered"
                        matSort #sortTareasCte="matSort">
                        <!-- strCliente Column -->
                        <ng-container matColumnDef="strCliente">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 align-middle bgTableCte">
                              <span class="textTable">Cliente</span>  
                            </th>
                            <td mat-cell *matCellDef="let row" class="ps-0 fs11 text-center align-middle">
                                <strong>{{row.strCliente}} - {{row.strClienteNombre}}</strong>
                            </td>
                        </ng-container>
                        <!-- strCompras Column -->
                        <ng-container matColumnDef="strCompras">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 fs13 align-middle bgTableCte">
                                <span class="textTable">Numero de Compras</span>  
                            </th>
                            <td mat-cell *matCellDef="let row" class="mostDet text-center fw-bold pe-0 fs13 text-start align-middle">
                                {{row.strCompras}}
                            </td>
                        </ng-container>
    
                        <!-- strMeses Column -->
                        <ng-container matColumnDef="strMeses">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 align-middle bgTableCte">
                                <span class="textTable">Meses Sin Compras </span>
                            </th>
                            <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle">
                                {{row.strMeses}}
                            </td>
                        </ng-container>
                        <tr mat-header-row class="rounded" *matHeaderRowDef="columnsTareasCte">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: columnsTareasCte;" >
                        </tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="6">
                                No se encontraron datos de:
                                "{{input.value}}"
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <mat-paginator class="paginator borderbott " [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorTareasCte></mat-paginator>
        </div>
    </div>
</div>