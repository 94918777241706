<div class="container">
    <br><br>
    <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb fs15">
            <li class="breadcrumb-item"><a [routerLink]="['/retoTork']" class="texto-gris">Listado de Retos</a></li>
            <li class="breadcrumb-item">/</li>
            <li class="breadcrumb-item active" aria-current="page" *ngIf="blnDetalle==false">Agregar un nuevo Reto</li>
            <li class="breadcrumb-item active" aria-current="page" *ngIf="blnDetalle==true">Detalle de Reto</li>
        </ol>
    </nav>

    <div class="row section_fondo">
        <div class="cien">
            <h1 *ngIf="blnDetalle==false">Agregar un nuevo Reto</h1>
            <h1 *ngIf="blnDetalle==true">Detalle Reto Tork con ID: {{intIDReto}}</h1>
            <!--<br>*{{blnDetalle}}*<br>-->
            <div class="cien">
                <div class="group-control col-sm-3 fLeft cont_radio mt-20">
                    <label for="Cliente" class="badge py-2 text-dark border border-primary fs-6 fw-lighter labct" [ngClass]="{'labct': strTipo=='Cliente'}">Cliente <i class="bi bi-person-fill"></i> </label>
                    <input class="radio_tarjeta mt-1" type="radio" name="opcion" id="Cliente" value="Cliente" [(ngModel)]="strTipo" (change)="fnBuscarClienteProsp()">
                </div>
                <div class="group-control col-sm-4 fLeft cont_radio mt-20">
                    <label for="Prospecto" class="badge py-2 text-dark border border-primary fs-6 fw-lighter" [ngClass]="{'labct': strTipo=='Prospecto'}"> Prospecto <i class="bi bi-person-video2"></i> </label>
                    <input class="radio_tarjeta mt-1" type="radio" name="opcion" id="Prospecto" value="Prospecto" [(ngModel)]="strTipo" (change)="fnBuscarClienteProsp()">
                </div>
                <div class="group-control col-sm-5 fLeft cont_radio mt-20" *ngIf="strEstatus">
                    <h3>Estatus:
                        <b [ngClass]="{'text-info': strEstatus=='PENDIENTE', 
                            'text-danger': strEstatus=='RECHAZADO', 
                            'text-aceptado': strEstatus=='AUTORIZADO'}">{{strEstatus}}</b>
                        <p *ngIf="strMotivoRechazoReto!='' && strEstatus=='RECHAZADO'" class="p_rojo">Motivo rechazo: <b>{{strMotivoRechazoReto}}</b></p>
                    </h3>
                </div>
            </div>

            <div class="cien">
                <div class="con_fom_50 mt-20 ">
                  <!--  <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="potenciallab">Buscar {{strTipo}}</mat-label>
                        <input  matInput placeholder="{{strTipo}}" [(ngModel)]="strBusqueda" (keyup)="fnBuscarClienteProsp()">
                        <mat-icon matSuffix >search</mat-icon>
                      </mat-form-field>  -->

                 
                      <div class="input-group  mb-3">
                        <input type="text" class="form-control bg-transparent border border-dark py-2"[(ngModel)]="strBusqueda" placeholder="Buscar {{strTipo}}" aria-label="Recipient's username" aria-describedby="button-addon2">
                        <button class="btn btn-outline-primary" type="button" id="button-addon2" (click)="fnBuscarClienteProsp()"><i class="bi bi-search"></i></button>
                      </div>
                      
                    <!--  <label class="label">Buscar {{strTipo}}</label>
                    <input type="text" [(ngModel)]="strBusqueda" placeholder="{{strTipo}}" maxlength="100" class="form-control mayus" (keyup)="fnBuscarClienteProsp()" /> -->
                 <div class="cien text-center fLeft" *ngIf="mostrarCargando">
                        <mat-spinner></mat-spinner>
                    </div>

                  

                <ul class="ul_busqueda" *ngIf="arrayClientes.length>0">
                        <li *ngFor="let C of arrayClientes" (click)="fnSeleccionoCliente(C)" class="fondo_{{C.fondo}}">
                            {{C.strCliente}} - {{C.strNombre}}
                            <span *ngIf="C.intIDSucursal>0"><b>Sucursal:</b> {{C.intIDSucursal}} - {{C.strNombreSucursal}}</span>
                        </li>
                    </ul> 
                    <mat-form-field appearance="outline" *ngIf="strCliente!=''" class="w-100 cteselec my-3">
                        <mat-label class="potenciallab" >{{strTipo}} seleccionado:</mat-label>
                        <input class="fw-bold fs-6" matInput value="{{strCliente}} - {{strNombre}}" readonly>
                        <mat-icon matSuffix> contacts</mat-icon>
                      </mat-form-field>

                      <mat-form-field appearance="outline" *ngIf="intIDSucursal>0" class="w-100 cteselec">
                        <mat-label class="potenciallab" >Sucursal:</mat-label>
                        <input class="fw-bold fs-6" matInput value="{{intIDSucursal}} - {{strNombreSucursal}}" readonly>
                        <mat-icon matSuffix>place</mat-icon>
                      </mat-form-field>
<!-- 
                    <h3 *ngIf="strCliente!=''" class="cte_select">
                        {{strTipo}} seleccionado: <b>{{strCliente}} - {{strNombre}}</b>
                        <br><span *ngIf="intIDSucursal>0"><b>Sucursal:</b> {{intIDSucursal}} - {{strNombreSucursal}}</span>
                    </h3> -->
                </div>

                <div class="con_fom_50 mt-20 ">
                    <div class="row d-flex justify-content-between align-items-center">
                        <div class="col-6">
                            <!--  <label class="label">Inicio Reto</label>
                             <input type="date" [(ngModel)]="strFechaInicial" class="form-control inpDer" />
                            -->
                               <mat-form-field appearance="outline" class="col-12">
                                <mat-label class="potenciallab">Inicio Reto</mat-label>
                                <input class="mb-0 pb-0" matInput type="date" [(ngModel)]="strFechaInicial">
                              </mat-form-field>
                             <!--disabled="{{intIDReto>0}}"-->
                         </div>
                         <div class="col-6">
                          <!--    <label class="label">Fin Reto</label>
                             <input type="date" [(ngModel)]="strFechaFinal" class="form-control inpIzq" />  min="{{diaFin}}"-->
                               <mat-form-field appearance="outline" class="col-12">
                                <mat-label class="potenciallab">Fin Reto</mat-label>
                                <input  matInput type="date" [(ngModel)]="strFechaFinal">
                              </mat-form-field>
                             <!--disabled="{{intIDReto>0}}"-->
                         </div>
     

                    </div>
                    <mat-form-field appearance="outline" *ngIf="strCliente!=''" class="w-100 potencialmat my-3">
                        <mat-label class="potenciallab" >Potencial de Venta:</mat-label>
                        <input class="fw-bold fs-6" matInput value="{{dblPotencialVenta | currency}}" readonly>
                      </mat-form-field>
                  <!--   <h3 *ngIf="strCliente!=''" class="potencial">Potencial de Venta: <b>{{dblPotencialVenta | currency}}</b></h3>
                    --> 
                    <mat-form-field appearance="outline"  *ngIf="strCliente!=''" class="w-100 costmat" >
                        <mat-label class="potenciallab">Costo Total del Reto:</mat-label>
                        <input class="fw-bold fs-6" matInput value="{{dblTotalReto | currency}}">
                      </mat-form-field>

           <!--          <h3 *ngIf="strCliente!=''" class="costoReto">Costo Total del Reto: <b>{{dblTotalReto | currency}}</b></h3>
 -->
                </div>
            </div>
            <div class="cien">
                
                <div class="con_fom_50 mt-20">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="potenciallab">Contacto </mat-label>
                        <input [(ngModel)]="strContacto" matInput placeholder="Contacto">
                        <mat-icon matSuffix>perm_contact_calendar</mat-icon>
                      </mat-form-field>
             <!--        <label class="label">Contacto</label>
                    <input type="text" [(ngModel)]="strContacto" placeholder="Contacto" maxlength="80" class="form-control" /> -->
                </div>

                <div class="con_fom_50 mt-20">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="potenciallab">Teléfono </mat-label>
                        <input  [(ngModel)]="strTelefono" matInput placeholder="Teléfono">
                        <mat-icon matSuffix>phone</mat-icon>
                      </mat-form-field>
                    <!-- <label class="label">Teléfono</label>
                    <input type="text" [(ngModel)]="strTelefono" placeholder="Teléfono" maxlength="10" class="form-control" /> -->
                </div>
            </div>

            <div class="cien">
                <div class="con_fom_50 mt-20">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="potenciallab" >Puesto</mat-label>
                        <mat-select [disabled]="disableSelect.value" [(ngModel)]="strPuesto">
                          <mat-option disabled value="">-Seleccionar un Puesto-</mat-option>
                          <mat-option *ngFor="let G of arrayPuestos" value="{{G.strPuesto}}">{{G.strPuesto}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>work</mat-icon>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="w-100" *ngIf="strPuesto=='Otro'">
                        <input  [(ngModel)]="strOtroPuesto" placeholder="Otro Puesto" matInput >
                        <mat-icon matSuffix>card_travel</mat-icon>
                      </mat-form-field>
                    <!--<input type="text" [(ngModel)]="strPuesto" placeholder="Puesto" maxlength="50" class="form-control" />-->
                <!--     <label class="label">Puesto</label>
                    <select class="form-control" [(ngModel)]="strPuesto">
                        <option disabled value="">-Seleccionar un Puesto-</option>
                        <option *ngFor="let G of arrayPuestos" value="{{G.strPuesto}}">{{G.strPuesto}}</option>
                    </select>
                    <input type="text" *ngIf="strPuesto=='Otro'" [(ngModel)]="strOtroPuesto" placeholder="Otro Puesto" maxlength="50" class="form-control mt-20" /> -->
                </div>

                <div class="con_fom_50 mt-20">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="potenciallab" >Giro</mat-label>
                        <mat-select [disabled]="disableSelect.value" [(ngModel)]="strGiro">
                          <mat-option disabled value="">-Seleccionar un Giro-</mat-option>
                          <mat-option *ngFor="let G of arrayGiros" value="{{G.strGiro}}">{{G.strGiro}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>autorenew</mat-icon>
                      </mat-form-field>

                    <!--<input type="text" [(ngModel)]="strGiro" placeholder="Giro" maxlength="50" class="form-control" />-->
                   <!--  <label class="label">Giro</label>
                    <select class="form-control" [(ngModel)]="strGiro">
                        <option disabled value="">-Seleccionar un Giro-</option>
                        <option *ngFor="let G of arrayGiros" value="{{G.strGiro}}">{{G.strGiro}}</option>
                    </select> -->
                </div>
            </div>

            <div class="cien">
                <div class="con_fom_50 mt-20">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="potenciallab">Dirección </mat-label>
                        <textarea style="resize: none;"  [(ngModel)]="strDireccion" matInput placeholder="Dirección"></textarea>
                        <mat-icon matSuffix>directions</mat-icon>
                      </mat-form-field>


                    <!-- <label class="label">Dirección</label>
                    <textarea type="text" [(ngModel)]="strDireccion" placeholder="Dirección" maxlength="130" class="form-control"></textarea> -->
                </div>

                <div class="con_fom_50 mt-20">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="potenciallab">Comenarios </mat-label>
                        <textarea style="resize: none;"  [(ngModel)]="strComentarios" matInput placeholder="Comentarios" maxlength="200"></textarea>
                        <mat-icon matSuffix>chat</mat-icon>
                      </mat-form-field>

                    <!-- <label class="label">Comentarios</label>
                    <textarea type="text" [(ngModel)]="strComentarios" placeholder="Comentarios" maxlength="200" class="form-control"></textarea> -->
                </div>
            </div>

            <!--<div class="cien mt-20 fondo_tipo_reto">
                <div class="group-control col-sm-3 fLeft cont_radio mt-20">
                    <label for="Directo">DIRECTO</label>
                    <input class="radio_tarjeta" type="radio" name="tipoReto" id="Directo" value="DIRECTO" [(ngModel)]="strTipoReto" (change)="fnTipoReto('DIRECTO')">
                </div>
                <div class="group-control col-sm-3 fLeft cont_radio mt-20">
                    <label for="Reto">RETO TORK</label>
                    <input class="radio_tarjeta" type="radio" name="tipoReto" id="Reto" value="RETO" [(ngModel)]="strTipoReto" (change)="fnTipoReto('RETO')">
                </div>
            </div>-->

            <div class="cien mt-30">
                <mat-stepper  orientation="vertical" [linear]="isLinear" #stepper >
                    <mat-step class="" *ngIf="strTipoReto=='RETO'">
                        <ng-template matStepLabel > <b class="fs-5"> Inventario actual</b></ng-template>
                        <div class="cien col-sm-12 mt-20">
                            <h2>Actualmente ¿Qué dispensadores tiene el Cliente?</h2>
                            <div class="table-responsive">
                                <table class="table table-bordered ">
                                    <!--  <div class="tabla_resp">
                                         <table class="tabla_producto"> 
                                             <tr class="header_tabla"></tr>-->
                                             <thead class="">
                                             <tr class="text-center text-white" style="background-color:#003E80;">
                                                 <th class="w20">Categoría</th>
                                                 <th class="w10">Marca</th>
                                                 <th class="w10">Clave</th>
                                                 <th class="w20">Descripción</th>
                                                 <th class="w10">Cantidad</th>
                                                 <th class="w10">Unidad</th>
                                                 <th class="w10">Precio</th>
                                                 <th class="w10">Eliminar</th>
                                             </tr>
                                         </thead>
                                          <!--    <tr *ngFor="let A of arrayActual" class="tr_body"> -->  
                                              <tbody>
                                               <tr *ngFor="let A of arrayActual" class="tr_body" >
                                                 <td class="w20 align-middle">
                                                     <!-- <mat-form-field appearance="fill" class="fBco">
                                                         <mat-label>Categoría:</mat-label>
                                                         <mat-select [(ngModel)]="A.strCategoria">
                                                             <mat-option *ngFor="let G of arrayCategoria" value="{{G.strCategoria}}">
                                                                 {{G.strCategoria}}
                                                             </mat-option>
                                                         </mat-select>
                                                     </mat-form-field> -->
         
                                                     <mat-form-field appearance="outline" class="w-100 intab">
                                                         <mat-label class="potenciallab" >Categoría:</mat-label>
                                                         <mat-select [disabled]="disableSelect.value" [(ngModel)]="A.strCategoria">
                                                           <mat-option disabled value="">-Seleccionar Categoria-</mat-option>
                                                           <mat-option *ngFor="let G of arrayCategoria" value="{{G.strCategoria}}">{{G.strCategoria}}</mat-option>
                                                         </mat-select>
                                                         
                                                         <mat-icon style="color: #003E80;" matSuffix>local_offer</mat-icon> 
                                                       </mat-form-field>
                                                 </td>
                                                 <td class="w10 align-middle">
                                                  <!--    <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Marca: </mat-label>
                                                         <input matInput [(ngModel)]="A.strMarca" maxlength="80">
                                                     </mat-form-field> -->
         
                                                     <mat-form-field appearance="outline" class="w-100 intab">
                                                         <mat-label class="potenciallab">Marca: </mat-label>
                                                         <input matInput [(ngModel)]="A.strMarca" maxlength="80">
                                                         <mat-icon style="color: #003E80;" matSuffix>bookmark</mat-icon>
                                                     </mat-form-field>
                                                 </td>
                                                 <td class="w10">
                                                     <mat-form-field appearance="outline" class="w-100 intabdes">
                                                         <mat-label class="potenciallab">Clave Despachador:</mat-label>
                                                         <input matInput [(ngModel)]="A.strClaveDespachador" maxlength="80">
                                                         <mat-icon style="color: #876544;" matSuffix>camera_rear</mat-icon>
                                                     </mat-form-field>
         
                                                 <!--     <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Clave Despachador:</mat-label>
                                                         <input matInput [(ngModel)]="A.strClaveDespachador" maxlength="20">
                                                     </mat-form-field> -->
                                                     <br><br>
                                                     <mat-form-field appearance="outline" class="w-100 intabcon">
                                                         <mat-label class="potenciallab">Clave Consumible: </mat-label>
                                                         <input matInput [(ngModel)]="A.strClaveConsumible" maxlength="20">
                                                         <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                                     </mat-form-field>
         
                                                   <!--   <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Clave Consumible: </mat-label>
                                                         <input matInput [(ngModel)]="A.strClaveConsumible" maxlength="20">
                                                     </mat-form-field> -->
                                                 </td>
                                                 <td class="w20">
                                                <!--      <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Descripción del Despachador: </mat-label>
                                                         <input matInput [(ngModel)]="A.strNombreDespachador" maxlength="80">
                                                     </mat-form-field> -->
                                                     <mat-form-field appearance="outline" class="w-100 intabdes">
                                                         <mat-label class="potenciallab">Descripción Despachador:</mat-label>
                                                         <input matInput [(ngModel)]="A.strNombreDespachador" maxlength="80">
                                                         <mat-icon style="color: #876544;" matSuffix>camera_rear</mat-icon>
                                                     </mat-form-field>
                                                     <br><br>
                                                     <mat-form-field appearance="outline" class="w-100 intabcon">
                                                         <mat-label class="potenciallab">Descripción Consumible: </mat-label>
                                                         <input matInput [(ngModel)]="A.strNombreConsumible" maxlength="80">
                                                         <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                                     </mat-form-field>
                                                     <!-- <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Descripción del Consumible: </mat-label>
                                                         <input matInput [(ngModel)]="A.strNombreConsumible" maxlength="80">
                                                     </mat-form-field> -->
                                                 </td>
                                                 <td class="w10">
                                                     <mat-form-field appearance="outline" class="w-100 intabdes">
                                                         <mat-label class="potenciallab">Cantidad Despachador:</mat-label>
                                                         <input matInput [(ngModel)]="A.intCantidadDespachador" (blur)="fnValidarCantNum(A, 'Despachador')" min="1">
                                                         <mat-icon style="color: #876544;" matSuffix>camera_rear</mat-icon>
                                                     </mat-form-field>
                                      <!--                <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Cantidad Despachador: </mat-label>
                                                         <input matInput [(ngModel)]="A.intCantidadDespachador" (blur)="fnValidarCantNum(A, 'Despachador')" min="1">
                                                     </mat-form-field> -->
                                                     <br><br>
                                                     <mat-form-field appearance="outline" class="w-100 intabcon">
                                                         <mat-label class="potenciallab">Cantidad Consumible: </mat-label>
                                                         <input matInput [(ngModel)]="A.intCantidadConsumible" (blur)="fnValidarCantNum(A, 'Consumible')" min="1">
                                                         <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                                     </mat-form-field>
                                                  <!--    <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Cantidad Consumible: </mat-label>
                                                         <input matInput [(ngModel)]="A.intCantidadConsumible" (blur)="fnValidarCantNum(A, 'Consumible')" min="1">
                                                     </mat-form-field> -->
                                                 </td>
                                                 <td class="w10">
                                                    <mat-form-field appearance="outline" class="w-100 intabdes">
                                                        <mat-label class="potenciallab">Unidad Despachador:</mat-label>
                                                        <input matInput [(ngModel)]="A.strUnidadDespachador" maxlength="10" readonly>
                                                        <mat-icon style="color: #876544;" matSuffix>camera_rear</mat-icon>
                                                    </mat-form-field>
                                                     <!-- <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Unidad Despachador: </mat-label>
                                                         <input matInput [(ngModel)]="A.strUnidadDespachador" maxlength="10" disabled>
                                                     </mat-form-field> -->
                                                     <br><br>
                                                     
                                                     <mat-form-field appearance="outline" class="w-100 intabcon">
                                                        <mat-label class="potenciallab">Unidad Consumible: </mat-label>
                                                        <input matInput [(ngModel)]="A.strUnidadConsumible" maxlength="10" readonly>
                                                        <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                                    </mat-form-field>
                                                   <!--   <mat-form-field appearance="fill" class="matcelln fBco">
                                                         <mat-label>Unidad Consumible: </mat-label>
                                                         <input matInput [(ngModel)]="A.strUnidadConsumible" maxlength="10" disabled>
                                                     </mat-form-field> -->
                                                 </td>
                                                 <td class="w10 align-middle">
                                                     <!-- <mat-form-field appearance="fill" class="matcell">
                                                         <mat-label>Precio Consumible: </mat-label>
                                                         <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="A.dblPrecioConsumible " (blur)="updatePrecioConsumible(A, $event)" />
                                                     </mat-form-field> -->
                                                     <mat-form-field appearance="outline" class="w-100 intabcon">
                                                        <mat-label class="potenciallab">Precio Consumible: </mat-label>
                                                        <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="A.dblPrecioConsumible " (blur)="updatePrecioConsumible(A, $event)">
                                                        <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                                    </mat-form-field>
                                                 </td>
                                                 <td class="w10 text-center align-middle"><a class="trash-icon" (click)="fnEliminar('Actual', A)"><i class="fa-solid fa-trash-can"></i></a></td>
                                             </tr>
                                             <tr>
                                                 <td colspan="8"><a class="plus-icon fLeft" (click)="fnAgregar('Actual')"><i class="fa-solid fa-circle-plus"></i></a></td>
                                             </tr>
                                         </tbody>
                                         </table>
                            </div>
                        </div>
                    </mat-step>

                    <mat-step class="">
                        <ng-template matStepLabel><b class="fs-5">Despachadores y Consumibles del Reto Tork entregados</b></ng-template>
                        <div class="cien col-sm-12 mt-20">
                            <h2>Sistema Reto Tork a Probar</h2>
                            <div class="tabla_resp">
                                <table class="tabla_producto border border-dark">
                                    <tr class="header_tabla">
                                        <th class="w20">Categoría</th>
                                        <th class="w10">Clave</th>
                                        <th class="w20">Descripción</th>
                                        <th class="w10">Cantidad</th>
                                        <th class="w10">Unidad</th>
                                        <th class="w10">Precio</th>
                                        <th class="w10">Costo</th>
                                        <th class="w10">Eliminar</th>
                                    </tr>
                                    <tr *ngFor="let A of arrayDespachadores" class="tr_body_tork">
                                        <td class="w20">
                                          <!--   <mat-form-field appearance="fill" class="fBco">
                                                <mat-label>Categoría:</mat-label>
                                                <mat-select [(ngModel)]="A.strCategoria" (selectionChange)="fnCambioCategoria(A)">
                                                    <mat-option *ngFor="let G of arrayCategoria" value="{{G.strCategoria}}">
                                                        {{G.strCategoria}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field> -->
                                            
                                            <mat-form-field appearance="outline" class="w-100 intab">
                                                <mat-label class="potenciallab" >Categoría:</mat-label>
                                                <mat-select [(ngModel)]="A.strCategoria" (selectionChange)="fnCambioCategoria(A)">
                                                    <mat-option *ngFor="let G of arrayCategoria" value="{{G.strCategoria}}">
                                                        {{G.strCategoria}}
                                                    </mat-option> 
                                                </mat-select>
                                                <mat-icon style="color: #003E80;" matSuffix>local_offer</mat-icon>
                                              </mat-form-field>
                                        </td>
                                        <td class="w20">
                                            <mat-form-field appearance="outline" class="w-100 intabdes">
                                                <mat-label class="potenciallab">Clave Despachador:</mat-label>
                                                <mat-select [(ngModel)]="A.strClaveDespachador" (selectionChange)="fnCambioDespachador(A, 'clave')" disabled="{{A.strCategoria==''}}">
                                                    <mat-option *ngFor="let G of arrayListadoDespachadores | filterCategoria: A.strCategoria" value="{{G.strClaveDespachador}}">
                                                        {{G.strClaveDespachador}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-icon style="color: #876544;" matSuffix>camera_rear</mat-icon>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="w-100 intabcon mt-3">
                                                <mat-label class="potenciallab">Clave Consumible:</mat-label>
                                                <mat-select [(ngModel)]="A.strClaveConsumible" (selectionChange)="fnCambioConsumible(A, 'clave')" disabled="{{A.strCategoria=='' || A.strClaveDespachador==''}}">
                                                    <mat-option *ngFor="let G of arrayListadoConsumibles | filterDespachador: A.strClaveDespachador" value="{{G.strClaveConsumible}}">
                                                        {{G.strClaveConsumible}}
                                                    </mat-option> 
                                                </mat-select>
                                                <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                            </mat-form-field>
                                          
                                        </td>
                                        <td class="w20">
                                            <mat-form-field appearance="outline" class="w-100 intabdes">
                                                <mat-label class="potenciallab">Descripción del Despachador:</mat-label>
                                                <mat-select [(ngModel)]="A.strNombreDespachador" (selectionChange)="fnCambioDespachador(A, 'nombre')" disabled="{{A.strCategoria==''}}">
                                                    <mat-option *ngFor="let G of arrayListadoDespachadores | filterCategoria: A.strCategoria" value="{{G.strNombreDespachador}}">
                                                        {{G.strNombreDespachador}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-icon style="color: #876544;" matSuffix>camera_rear</mat-icon>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" class="w-100 intabcon mt-3">
                                                <mat-label class="potenciallab">Descripción del Consumible:</mat-label>
                                                <mat-select [(ngModel)]="A.strNombreConsumible" (selectionChange)="fnCambioConsumible(A, 'nombre')" disabled="{{A.strCategoria=='' || A.strClaveDespachador==''}}">
                                                    <mat-option *ngFor="let G of arrayListadoConsumibles | filterDespachador: A.strClaveDespachador" value="{{G.strNombreConsumible}}">
                                                        {{G.strNombreConsumible}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                            </mat-form-field>
                                       <!--      <mat-form-field appearance="fill" class="fBco">
                                                <mat-label>Descripción del Despachador:</mat-label>
                                                <mat-select [(ngModel)]="A.strNombreDespachador" (selectionChange)="fnCambioDespachador(A, 'nombre')" disabled="{{A.strCategoria==''}}">
                                                    <mat-option *ngFor="let G of arrayListadoDespachadores | filterCategoria: A.strCategoria" value="{{G.strNombreDespachador}}">
                                                        {{G.strNombreDespachador}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <br><br>
                                            <mat-form-field appearance="fill" class="fBco">
                                                <mat-label>Descripción del Consumible:</mat-label>
                                                <mat-select [(ngModel)]="A.strNombreConsumible" (selectionChange)="fnCambioConsumible(A, 'nombre')" disabled="{{A.strCategoria=='' || A.strClaveDespachador==''}}">
                                                    <mat-option *ngFor="let G of arrayListadoConsumibles | filterDespachador: A.strClaveDespachador" value="{{G.strNombreConsumible}}">
                                                        {{G.strNombreConsumible}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field> -->
                                        </td>
                                        <td class="w10">
                                            <mat-form-field appearance="outline" class="w-100 intabdes">
                                                <mat-label class="potenciallab">Cantidad Despachador:</mat-label>
                                                <input matInput placeholder="" [(ngModel)]="A.intCantidadDespachador" (blur)="fnValidarCantNum(A, 'Despachador')" min="1">
                                                <mat-icon style="color: #876544;" matSuffix>camera_rear</mat-icon>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="w-100 intabcon mt-3">
                                                <mat-label class="potenciallab">Cantidad Consumible: </mat-label>
                                                <input matInput [(ngModel)]="A.intCantidadConsumible" min="1" (blur)="fnSumarTotalReto(); fnValidarCantNum(A, 'Consumible')">
                                            <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                            </mat-form-field>
                                           <!--  <mat-form-field appearance="fill" class="matcelln fBco">
                                                <mat-label>Cantidad Despachador: </mat-label>
                                                <input matInput placeholder="" [(ngModel)]="A.intCantidadDespachador" (blur)="fnValidarCantNum(A, 'Despachador')" min="1">
                                            </mat-form-field>
                                            <br><br>
                                            <mat-form-field appearance="fill" class="matcelln fBco">
                                                <mat-label>Cantidad Consumible: </mat-label>
                                                <input matInput [(ngModel)]="A.intCantidadConsumible" min="1" (blur)="fnSumarTotalReto(); fnValidarCantNum(A, 'Consumible')">
                                            </mat-form-field> -->
                                        </td>
                                        <td class="w10">
                                            <mat-form-field appearance="outline" class="w-100 intabdes">
                                                <mat-label class="potenciallab">Unidad Despachador: </mat-label>
                                                <input matInput [(ngModel)]="A.strUnidadDespachador" maxlength="10" readonly>
                                                <mat-icon style="color: #876544;" matSuffix>camera_rear</mat-icon>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="w-100 intabcon mt-3">
                                                <mat-label class="potenciallab">Unidad Consumible: </mat-label>
                                                <input matInput [(ngModel)]="A.strUnidadConsumible" maxlength="10" readonly>
                                            <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                            </mat-form-field>
                                            <!-- <mat-form-field appearance="fill" class="matcelln fBco">
                                                <mat-label>Unidad Despachador: </mat-label>
                                                <input matInput [(ngModel)]="A.strUnidadDespachador" maxlength="10" disabled>
                                            </mat-form-field>
                                            <br><br>
                                            <mat-form-field appearance="fill" class="matcelln fBco">
                                                <mat-label>Unidad Consumible: </mat-label>
                                                <input matInput [(ngModel)]="A.strUnidadConsumible" maxlength="10" disabled>
                                            </mat-form-field> -->
                                        </td>
                                        <td class="w10">
                                            <mat-form-field appearance="outline" class="w-100 intabcon mt-3">
                                                <mat-label class="potenciallab">Precio Consumible: </mat-label>
                                                <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="A.dblPrecioConsumible" (blur)="updatePrecioConsumible(A, $event)" />
                                            <mat-icon style="color: #1E1AAD;" matSuffix><span class="material-symbols-outlined">clean_hands</span></mat-icon>
                                        </mat-form-field>
                                           <!--  <mat-form-field appearance="fill" class="matcell">
                                                <mat-label>Precio Consumible: </mat-label>
                                                <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="A.dblPrecioConsumible" (blur)="updatePrecioConsumible(A, $event)" />
                                            </mat-form-field> -->
                                        </td>
                                        <td class="w10">
                                            <mat-form-field appearance="outline" class="w-100 intab">
                                                <mat-label class="potenciallab">Costo del Consumible:</mat-label>
                                                <input matInput class="textCosto text-center fs-6" value="{{A.dblCostoPromedio | currency}}"  readonly>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="w-100 intab mt-2">
                                                <mat-label class="potenciallab">Costo Total de los Consumibles:</mat-label>
                                                <input matInput class="textCosto text-center fs-6" value="{{(A.dblCostoPromedio * A.intCantidadConsumible) | currency}}"  readonly>
                                            </mat-form-field>
                                            <!-- <div class="cont_consu">
                                                <span>Costo del Consumible:</span>
                                                <p class="textCosto">{{A.dblCostoPromedio | currency}}</p>
                                            </div>
                                            <div class="cont_consu">
                                                <span>Costo Total de los Consumibles:</span>
                                                <p class="textCosto">{{(A.dblCostoPromedio * A.intCantidadConsumible) | currency}}</p>
                                            </div> -->
                                        </td>
                                        <td class="w10">
                                            <a class="trash-icon" (click)="fnEliminar('Entregado', A)"><i class="fa-solid fa-trash-can"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="8"><a class="plus-icon fLeft" (click)="fnAgregar('Entregado')"><i class="fa-solid fa-circle-plus"></i></a></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </mat-step>
                </mat-stepper>
            </div>


            <div class="group-control col-sm-12 mt-3">
               <!--  <button (click)="fnGuardarReto('guardado')" class="btn boton_guardar_nuevo fRight" *ngIf="blnDetalle==false && btnGuardar">Guardar Solicitud de Reto</button>
                <button (click)="fnGuardarReto('editado')" class="btn boton_guardar_nuevo fRight" *ngIf="blnDetalle==true && btnEditar && (strEstatus=='RECHAZADO' || (strEstatus=='PENDIENTE' && strTipoAcceso=='3')) && spOcultarBoton==false">Editar Solicitud de Reto</button>

                <button (click)="fnGuardarAutorizarRechazar('AUTORIZADO')" class="btn boton_guardar_nuevo boton_autorizar fRight" *ngIf="(blnDetalle==true && btnAutorizar && strEstatus=='PENDIENTE') && spOcultarBoton==false">Autorizar Solicitud de Reto</button>
                <button (click)="fnGuardarRechazo()" class="btn boton_guardar_nuevo boton_rechazar fRight" *ngIf="(blnDetalle==true && btnRechazar && strEstatus=='PENDIENTE') && spOcultarBoton==false">Rechazar Solicitud de Reto</button>
 -->                
 <button (click)="fnGuardarReto('guardado')" class="btn boton_guardar_nuevo editbtn fRight" *ngIf="blnDetalle==false && btnGuardar">Guardar Solicitud de Reto</button>
                <button (click)="fnGuardarReto('editado')" class="btn boton_guardar_nuevo editbtn fRight" *ngIf="blnDetalle==true && btnEditar && (strEstatus=='RECHAZADO' || (strEstatus=='PENDIENTE' && strTipoAcceso=='3')) && spOcultarBoton==false">*Editar Solicitud de Reto</button>

                <button (click)="fnGuardarAutorizarRechazar('AUTORIZADO')" class="btn boton_guardar_nuevo  Autoribtn fRight" *ngIf="(blnDetalle==true && btnAutorizar && strEstatus=='PENDIENTE') && spOcultarBoton==false">Autorizar Solicitud de Reto</button>
                <button (click)="fnGuardarRechazo()" class="btn boton_guardar_nuevo rechabtn fRight" *ngIf="(blnDetalle==true && btnRechazar && strEstatus=='PENDIENTE') && spOcultarBoton==false">Rechazar Solicitud de Reto</button>



                <div class="cien text-center" *ngIf="spCargando">
                    <mat-spinner></mat-spinner>
                </div>
            </div>

        </div>
    </div>
</div>

<app-footer></app-footer>