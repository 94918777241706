<div class="container mt-2">
    <h2>Datos de facturación</h2>
    <form [formGroup]="facturaForm" (ngSubmit)="onSubmit(facturaForm.value)">
        <div class="form-group">
            <label for="name">Nombre</label>
            <input [ngClass]="{'is-valid': f.strNombre.touched && f.strNombre.valid,
              'is-invalid': f.strNombre.touched && f.strNombre.invalid}"  id="name" type="text" class="form-control fs13" formControlName="strNombre" placeholder="Ingresa tu nombre" />
            <div *ngIf="f.strNombre.touched && f.strNombre.invalid" class="text-danger">
                <div *ngIf="f.strNombre.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strNombre.errors?.minlength">Debe tener al menos 2 caracteres.</div>
              </div>
        </div>
        <div class="form-group">
            <label for="apellido">Apellidos</label>
            <input [ngClass]="{'is-valid': f.strApellidos.touched && f.strApellidos.valid, 'is-invalid': f.strApellidos.touched && f.strApellidos.invalid}" id="apellido" type="text" class="form-control fs13" formControlName="strApellidos" placeholder="Ingresa tus Apellidos" />
            <div *ngIf="f.strApellidos.touched && f.strApellidos.invalid" class="text-danger">
              <div *ngIf="f.strApellidos.errors?.required">El campo es obligatorio.</div>
              <div *ngIf="f.strApellidos.errors?.minlength">Debe tener al menos 2 caracteres.</div>
            </div>
        </div>

        <div class="col-12">
          <label for="floatingInput">Razon Social</label>
          <div class="form-group mb-3">
            <input type="text" class="form-control fs13" id="País" formControlName="strRazonSocial"  [ngClass]="{'is-valid': f.strRazonSocial.touched && f.strRazonSocial.valid,'is-invalid': f.strRazonSocial.touched && f.strRazonSocial.invalid}"><!--[(ngModel)]="telefono" -->
           
            <div *ngIf=" f.strRazonSocial.touched && f.strRazonSocial.invalid" class="text-danger">
              El campo es obligatorio.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group  mb-3">
            <label for="floatingInput">País</label>
            <input type="text" class="form-control fs13" id="País" formControlName="strPais"  [ngClass]="{'is-valid': f.strPais.touched && f.strPais.valid,'is-invalid': f.strPais.touched && f.strPais.invalid}"><!--[(ngModel)]="telefono" -->
            <div *ngIf="f.strPais.touched && f.strPais.invalid" class="text-danger">
              El campo es obligatorio.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group  mb-3">
            <label for="floatingInput">RFC</label>
            <input (input)="convertirAMayusculas($event)"  type="text" class="form-control fs13" [maxlength]="13" id="País" formControlName="strRFC"  [ngClass]="{'is-valid': f.strRFC.touched && f.strRFC.valid,'is-invalid': f.strRFC.touched && f.strRFC.invalid}"><!--[(ngModel)]="strRFC" -->
            <div *ngIf="f.strRFC.touched && f.strRFC.invalid" class="text-danger">
              El campo es obligatorio.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group  mb-3">
            <label for="floatingSelect">Regimen Fiscal</label>
            <select class="form-select fs13" id="floatingSelect" aria-label="Floating label select example" [ngClass]="{'is-valid': f.strRegimenFiscal.touched && f.strRegimenFiscal.valid, 'is-invalid': f.strRegimenFiscal.touched && f.strRegimenFiscal.invalid}" formControlName="strRegimenFiscal">
              <option value="" class="" selected>--Seleccione--</option>
              <option class="fs13" [value]="col.strFiscalRegimen" *ngFor="let col of arrayRegimenFiscal">{{col.strDescripcion}}</option>
            </select>
            <div *ngIf="f.strRegimenFiscal.touched && f.strRegimenFiscal.invalid" class="text-danger">
              El campo es obligatorio.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group mb-3">
            <label for="floatingSelect">Metodo Pago</label>
            <select class="form-select fs13" id="floatingSelect fs13" aria-label="Floating label select example" [ngClass]="{'is-valid': f.strMetodoPago.touched && f.strMetodoPago.valid, 'is-invalid': f.strMetodoPago.touched && f.strMetodoPago.invalid}" formControlName="strMetodoPago">
              <option value="" class="" selected>--Seleccione--</option>
              <option class="fs13" [value]="col.strClaveSat" *ngFor="let col of arrayFormaPago">{{col.strFormaPagoTipo}}</option>
            </select>
            <div *ngIf="f.strMetodoPago.touched && f.strMetodoPago.invalid" class="text-danger">
              El campo es obligatorio.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group mb-3">
            <label for="floatingInput">Uso CFDI</label>
            <select class="form-select fs13" id="floatingSelect" aria-label="Floating label select example" [ngClass]="{'is-valid': f.strUsoCfdi.touched && f.strUsoCfdi.valid, 'is-invalid': f.strUsoCfdi.touched && f.strUsoCfdi.invalid}" formControlName="strUsoCfdi">
              <option value="" class="fs12" selected>--Seleccione--</option>
              <option class="fs12" [value]="col.strClaveUsoCFDI" *ngFor="let col of arrayCfdi">{{col.strDescripcion}}</option>
            </select>
            <div *ngIf="f.strUsoCfdi.touched && f.strUsoCfdi.invalid" class="text-danger">
              El campo es obligatorio.
            </div>
          </div>
        </div>














        <div class="col-12">
            <div class="form-group mb-3">
                <label for="apellido">Código Postal</label>
              <input   placeholder="Ingrese Codigo Postal" type="text"  id="apellido" (keydown)="onEnter($event)" class="form-control valid fs13" (input)="updateCantidad($event)"  formControlName="strCodigoPostal"
              [ngClass]="{'is-valid': f.strCodigoPostal.touched && f.strCodigoPostal.valid,'is-invalid': f.strCodigoPostal.touched && f.strCodigoPostal.invalid}"
               [maxlength]="5" autocomplete="new-password" autocorrect="off" autocapitalize="off" spellcheck="false" id="strCodigoPostal"><!--[(ngModel)]="strCodigoPostal" -->
                <div *ngIf="f.strCodigoPostal.touched && f.strCodigoPostal.invalid" class="text-danger">
                <div *ngIf="f.strCodigoPostal.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strCodigoPostal.errors?.pattern">Debe ingresar al menos 5 dígitos </div>
              </div>
            </div>
          </div>
          <div class="col-12 position-relative">
            <div class="form-group  mb-3">
                <label for="floatingInput">Estado</label>
              <input type="text"   placeholder="Ingrese Codigo Postal"  class="form-control valid fs13" readonly id="strEstado" formControlName="strEstado"
              [ngClass]="{'is-valid': f.strEstado.touched && f.strEstado.valid,'is-invalid': f.strEstado.touched && f.strEstado.invalid}" ><!--[(ngModel)]="strCodigoPostal" -->
              <div *ngIf="f.strEstado.touched && f.strEstado.invalid" class="text-danger">
                Ingrese un codigo postal correcto.
              </div>
            </div>
            <div *ngIf="spinnerCol" class="mb-2 col-12 d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
              <mat-spinner [diameter]="23"></mat-spinner>
            </div>
          </div>
          <div class="col-12 position-relative">
            <div class="form-group  mb-3">
                <label for="floatingInput">Ciudad</label>
              <input type="text"   placeholder="Ingrese Codigo Postal"  readonly class="form-control fs13" id="Ciudad" formControlName="strCiudad" 
              [ngClass]="{'is-valid': f.strCiudad.touched && f.strCiudad.valid,
              'is-invalid': f.strCiudad.touched && f.strCiudad.invalid}"><!--[(ngModel)]="strCiudad" -->
              
              <div *ngIf="f.strCiudad.touched && f.strCiudad.invalid" class="text-danger">
                Ingrese un codigo postal correcto.
              </div>
            </div>
            <div *ngIf="spinnerCol" class="mb-2 col-12 d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
              <mat-spinner [diameter]="23"></mat-spinner>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group  mb-3">
                <label for="floatingSelect">Colonia</label>
              <select class="form-select fs13" id="floatingSelect" aria-label="Floating label select example" [ngClass]="{'is-valid': f.strColonia.touched && f.strColonia.valid, 'is-invalid': f.strColonia.touched && f.strColonia.invalid}" formControlName="strColonia">
                <option class="fs13" value="" selected>--Seleccione--</option>
                <option class="fs13" [value]="col.strColonia" *ngFor="let col of arrayColonias">{{col.strColonia}}</option>
              </select>
             
              <div *ngIf="f.strColonia.touched && f.strColonia.invalid" class="text-danger">
                El campo es obligatorio.
              </div>
          
            </div>
          </div>
          <div class="col-12">
            <div class="form-group  mb-3">
                <label for="floatingInput">Dirección (Calle y número)</label>
              <input type="text"   placeholder="Ingrese Calle y Numero"  class="form-control fs13" id="Direccion" formControlName="strDireccion"
              [ngClass]="{'is-valid': f.strDireccion.touched && f.strDireccion.valid,
              'is-invalid': f.strDireccion.touched && f.strDireccion.invalid}"><!--[(ngModel)]="strDireccion" -->
            
              <div *ngIf="f.strDireccion.touched && f.strDireccion.invalid" class="text-danger">
                El campo es obligatorio.
              </div>
            </div>
          </div>
      <button type="submit" class="btn btn-primary" [disabled]="facturaForm.invalid">
        Guardar
      </button>
  
      
    </form>
  </div>
 