<!--<app-menu></app-menu>-->

<div class="container">
    <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
      <ol class="breadcrumb fs15">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item" aria-current="page">Busqueda de artículos</li>
        <li class="breadcrumb-item active" aria-current="page">Detalle de artículos</li>
      </ol>
    </nav>
  
    <h1 class="fs30">Detalle de producto</h1>
    <br>
  
    <div class="row">
      <div class="col-md-4">
        <div id="carouselExampleControls" class="carousel slide text-primary" data-ride="carousel">
          <div class="carousel-inner">
            <!--<div class="carousel-item active">
              <img class="d-block w-100" src="../../assets/img/productos/BN.jpg" alt="First slide">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="../../assets/img/productos/BN-po-3.jpg" alt="Second slide">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="../../assets/img/productos/BN-po-2.jpg" alt="Third slide">
            </div>-->
            <div class="carousel-item active" >
              <img class="d-block w-100" src="{{urlImg}}{{strArticulo}}.jpg" alt="First slide">
            </div>
            <div class="carousel-item" *ngFor="let imagen of arrayImagenes">
              <img class="d-block w-100" src="{{imagen.imagen}}" alt="Next slide">
            </div>
          </div>
          <a class="carousel-control-prev text-primary" href="#carouselExampleControls" role="button" data-slide="prev">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
            </svg>
          </a>
          <a class="carousel-control-next text-primary" href="#carouselExampleControls" role="button" data-slide="next">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
          </a>
        </div>
        <br>
        
        <a [routerLink]="['/detalle/BN']"  [routerLinkActive]="'activado'">BN</a> <BR>
        <a [routerLink]="['/detalle/CL20']"  [routerLinkActive]="'activado'">CL20</a> <BR>
        <a [routerLink]="['/detalle/PAW']"  [routerLinkActive]="'activado'">PAW</a> <BR>
        <a [routerLink]="['/detalle/4701']"  [routerLinkActive]="'activado'">4701</a> <BR>
      </div>
      <div class="col-md-5 text-left">
        <h3 class="my-3 det-h3">{{strNombreArticulo}}</h3>
        <a class="det_hip" href="home">Regresar al listado</a>
        <h4 class="det_h4 gris">{{strDescripcion}}</h4>
        <br><br>
        <div class="cien"  *ngIf="intFichaTecnica>0 || intHojaSeguridad>0">
        <table class="table">
          <tbody>
            <tr>
              <th *ngIf="intFichaTecnica>0">Ficha técnica: </th>
              <td *ngIf="intFichaTecnica>0">
                <a href="https://www.ecodeli.com/catalogos/FichasTecnicas/{{strArticulo}}.pdf" target="_blank" class="icon_detalle">
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path fill-rule="evenodd"
                      d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </td>
              <th *ngIf="intHojaSeguridad>0">Hoja de seguridad: </th>
              <td *ngIf="intHojaSeguridad>0">
                <a href="https://www.ecodeli.com/catalogos/HojasSeguridad/{BN.pdf" target="_blank" class="icon_detalle">
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path fill-rule="evenodd"
                      d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
  
      <div class="col-md-3 text-left">
        <h4 class="my-3 det-h4">REF:  <span class="text-info bold">{{strArticulo}}</span></h4>
        <div>
          <label class="det-parr">${{dblPrecio}}</label>
        </div>
        <br>
        <p class="det-parr">
          <span>Cantidad</span>
          <input type="number" [(ngModel)]="intCantidad" class="form-control input_cantidad" min="0"> <br>
        </p>
  
        <p class="det-parr">
          <label class="detparr2">
            <a (click)="fnAbrirModal()" data-toggle="modal" data-target="#myModalInventario">
              <b style="color: #0c84e4">{{intExistenciasSucursal}}</b> disponibles en tu sucursal,
              <b style="color: #0c84e4">{{intExistencias}}</b> disponibles a nivel nacional</a>
          </label>
        </p>
  
        <div class="det-parr"  *ngIf="strTipoOpcion=='Si'">
          <span>Opciones</span>
          <select class="form-control select_opciones" [(ngModel)]="objOpcion" (change)="fnSeleccionoOpcion()" required>
              <option [ngValue]="opcion" *ngFor="let opcion of arrayOpciones"  >
                  {{opcion.strNombreOpcion}}
              </option> 
          </select>
        </div>
        <button class="btn_add" (click)="fnSetArticulo(strArticulo,intCantidad,strNombreArticulo,dblPrecio,objOpcion)"
          [disabled]="strTipoOpcion=='Si' && objOpcion==''">
          <svg class="icon-default menu_icon" width="25" height="25" viewBox="0 0 36 30"
            xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <path d="M7 7h22v18a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V7z" stroke="currentColor" stroke-width="2"></path>
              <path d="M13 10V6c0-2.993 2.009-5 5-5s5 2.026 5 5v4" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"></path>
              <circle class="cart-not-empty" cx="18" cy="18" r="4" fill="currentColor"></circle><!--*ngIf="totalCarrito>0"-->
            </g>
          </svg>
          <span class="bo medio">Añadir a carrito</span>
        </button>
        
        <p class="text-danger" *ngIf="strTipoOpcion=='Si' && objOpcion==''">Agregue una opción</p>
        <br><br>
        <!--<button class="btn_cotizar" (click)="fnSetArticuloCot(strArticulo,intCantidad,strNombreArticulo,dblPrecio,objOpcion)">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-journal-check"
            viewBox="0 0 16 16">
            <path
              d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
            <path
              d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
            <path fill-rule="evenodd"
              d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
          </svg>
          <span class="bo medio">Cotizar</span>
        </button>-->
      </div>
    </div>
  
  
    <!--Modal listado de Inventario por sucursal-->
  
    <div class="modal inmodal" id="myModalInventario" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog  modal-lg">
        <div class="modal-content animated fadeIn">
          <div class="modal-header fondoModalTitle">
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
            </button><br>
            <h4 class="modal-title text-center h4_titulo">Inventario por sucursales</h4>
          </div>
          <div class="modal-body">
            <div class="cont_int_modal">
              <table class="table table-hover tooltip-demo titleTabla">
                <thead>
                  <tr class="text-center text-uppercase">
                    <th height="30px">Almacén</th>
                    <th height="30px">Nombre Almacén</th>
                    <th height="30px">Disponibles</th>
                  </tr>
                </thead>
                <tbody class="fs15 text-center">
                  <tr *ngFor="let inv of arrayInventarioPorSucursales">
                    <td>{{inv.strSucursal}}</td>
                    <td class="text-uppercase text-left">{{inv.strNombreAlmacen}}</td>
                    <td>{{inv.intInventario}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>  
    <br><br>
</div>

<app-footer></app-footer>