import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { CarroService } from '../servicios/carroservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from "rxjs";
import { CarritoNService } from '../servicios/carrito-n.service';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit {
  public strArticulo: string;
  public strNombreArticulo: string;
  public dblPrecio: number;
  public objOpcion: any;  
  public strEstatus: string; 
  public urlImg: string;
  public strSucursal: string;
  public arrayInventarioPorSucursales: any;
  public arrayListado: any;
  public arrayListadoMostrar: any;
  public strDescripcion: string;
  public strLinea: string;
  public strFamilia: string;
  public strCategoria: string;
  public strTipoOpcion: string;
  public cargando:boolean;
  public arrayFamilia:any;
  public arrayCategoria:any;
  public arrayLinea:any;
  //propiedad para almacenar el observable
  public mercados$: Observable<any>;
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5,10,15,25,50,100];
  showFirstLastButtons = true;
  value = 'Clear me';
  arrayOpciones: any = [
    {strTipoOpcion: ''},
    {strTipoOpcion: 'Si'},
    {strTipoOpcion: 'No'}
  ];


  constructor(private _router: Router, private rutaActiva: ActivatedRoute, private _peticionesService: PeticionesService, private _carro: CarroService, private _carrito: CarritoNService) {//
    this.strArticulo = "dtcell";
    this.strNombreArticulo = "";
    this.dblPrecio = 0;
    this.strEstatus = "";      
    this.strSucursal = "0";
    this.urlImg = "https://www.cleanclean.mx/imagenes/";    
    this.arrayListado=[];
    this.arrayListadoMostrar=[];
    this.strDescripcion = "carro";
    this.strLinea="RUBBERMAID";
    this.strFamilia="RUBBERMAID";
    this.strCategoria="MANEJO DE MATERIALES Y CARROS DE SERVICIO";
    this.strTipoOpcion = "Si"; 
    this.cargando=false;
    this.arrayFamilia=[];
    this.arrayCategoria=[];
    this.arrayLinea=[];
    //this.mercados$ =this._CotizadorComponent.getMercadoSeleccionado$();

  }

  ngOnInit(): void {
    this.fnGetDetalle();
    this.fnGetLinea();
    this.fnGetFamilia();
    this.fnGetCategoria();
    this.mercados$.subscribe(n => 
      this.fnGetAreas(n)
    );
  }
  //obtener Areas
  fnGetAreas(obj:any){
    this._peticionesService.getModuloVtas({
      strAccion: 'getAreas' ,
      intIDMercado:obj.intIDMercado
    }).subscribe(
      result => { 
        console.log(result);
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  //Paginacion:
  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.iterator();
  }

  private iterator() {
    const start = this.pageIndex * this.pageSize;
    const end = (this.pageIndex + 1) * this.pageSize;
    const part = this.arrayListado.slice(start, end);
    this.arrayListadoMostrar = part; // llamada de arreglo 
  }
  

  fnGetDetalle() {
    this.cargando=true;
    this._peticionesService.getModuloVtas({
      strAccion: 'getListado',
      strDescripcion: this.strDescripcion,
      strLinea: this.strLinea,
      strFamilia: this.strFamilia,
      strCategoria: this.strCategoria,
      strTipoOpcion: this.strTipoOpcion
    }).subscribe(
      result => {
        this.cargando=false;
        this.arrayListado = result;
        if (this.arrayListado.length > 0) {
          this.arrayListadoMostrar=this.arrayListado;
          this.length=this.arrayListado.length;
        }else{//No hay articulos
          this.length=0;
          this.arrayListadoMostrar=[];
        }
        this.iterator();//Funcion para llamar la paginacion
        console.log(this.arrayListado);
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetLinea(){
    this._peticionesService.getModuloVtas({
      strAccion: 'getLinea'
    }).subscribe(
      result => {
        this.arrayLinea = result;
        this.arrayLinea.unshift({strLinea:''});
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetFamilia(){
    this._peticionesService.getModuloVtas({
      strAccion: 'getFamilia'
    }).subscribe(
      result => {
        this.arrayFamilia = result;
        this.arrayFamilia.unshift({strFamilia:''});
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetCategoria(){
    this._peticionesService.getModuloVtas({
      strAccion: 'getCategoria'
    }).subscribe(
      result => {
        this.arrayCategoria = result;
        this.arrayCategoria.unshift({strCategoria:''});
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

}
