import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-ecommerce-register',
  templateUrl: './ecommerce-register.component.html',
  styleUrls: ['./ecommerce-register.component.css']
})
export class EcommerceRegisterComponent implements OnInit {
 
  registerForm: FormGroup;
  passwordMismatchError: boolean = false;
  passwordVisible: boolean = false; // Controla la visibilidad de la contraseña
  confirmPasswordVisible: boolean = false; // Controla la visibilidad de la confirmación de contraseña
  public strConexion:string ='COMERCIAL';
  constructor(private _httpService: PeticionesService,private fb: FormBuilder, private router: Router) { 
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      apellido: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
     // confirmPassword: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  
  }
   // Getter para acceder más fácilmente a los controles del formulario
   get f() { return this.registerForm.controls; }

  onSubmit(customerData:any): void {
     if (this.registerForm.invalid) {
       return;
     }
  
     this.passwordMismatchError = false;
 
     // Aquí iría el código para manejar el registro, por ejemplo, hacer una solicitud HTTP
     console.log('Registro exitoso', this.registerForm.value);
     this.fnSetRegister( this.registerForm.value)
     // Redirigir a la página de login o lo que sea necesario
     this.router.navigate(['/tlogin']);
   }
 
    // Función para alternar la visibilidad de la contraseña
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  // Función para alternar la visibilidad de la confirmación de contraseña
  toggleConfirmPasswordVisibility() {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
  }

    fnSetRegister(data:any){
      const encrypted = this.encrypt(data.password , 'eco');
      let articulo = {
        strAccion: "setRegistro",  
        strConexion: this.strConexion,
        strUsuario: data.email,
        strPassword:encrypted,
        strNombre:data.name,
        strApellidos:data.apellido,
        strCorreo:data.email
      };
      console.log(articulo);
       this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
        result => {
          console.log(result);
          if(result[0] && result[0].code){
               Swal.fire({
                        icon: "success",
                        title: "Registro Exitoso",
                        text:  result[0].strMensaje 
                      });
          }
          else {
                Swal.fire({
                          icon: "error",
                          title: "Error al generar registro.",
                          text: result[0].strMensaje 
                        });
          }
        }) 
    }
  encrypt(string: string, key: string): string {
    let result = '';
    for (let i = 0; i < string.length; i++) {
        const char = string[i]; // Obtener el   carácter
        let lk = key.length;
        let mod = i%lk;
        let position=  mod - 1;
       

        if (position < 0) {
          position = lk - 1; // Ajustar a la última posición si es negativo
      }
  
        const keychar =  key.charAt(position);  // Obtener el código ASCII del carácter de la clave
        
        const asciiValue = char.charCodeAt(0) + keychar.charCodeAt(0) ; // Devuelve 97

        const char2 = String.fromCharCode(asciiValue); 

       
        result += char2;
       
        //const encryptedChar = String.fromCharCode(char + keychar); // Sumar los códigos ASCII
        //result += encryptedChar; // Agregar el carácter cifrado al resultado
    }
    return btoa(result); // Codificar el resultado en Base64
}
}
