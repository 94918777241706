import { Component, OnInit ,ElementRef,AfterViewInit, ViewChild } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as moment from 'moment';
import 'moment/locale/pt-br';

export interface PeriodicElement {
  
  strAgente: string;
  strCliente: string;
  strEstatus: string;
  strNombreAgente: string;
  strNombreCliente: string;
  strNombreSucursal: string;
  strSucursal:string;
  dblCantidadPendiente:number;
  intCantidadPrometida:number;
  intCantidadComprada:number;
  semaforo:number;
}
@Component({
  selector: 'app-reportecomodatos',
  templateUrl: './reportecomodatos.component.html',
  styleUrls: ['./reportecomodatos.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),]
})
export class ReportecomodatosComponent implements OnInit,AfterViewInit  {
  displayedColumns: string[] = ['strCliente','strNombreCliente','dblCantidadPendiente','intCantidadPrometida','intCantidadComprada', 'semaforo','strNombreAgente', 'strNombreSucursal'];//,'strEstatus'
  public expandedElement?: PeriodicElement | null;
  public arrayEvidencias?:PeriodicElement[] ;
  public boolTable:any=true;
  public boolBarraD:any=false;
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  
  public arraySucursales:any=[];
  public sucursalSelected:any={};
  public tagName:any;
  public strGerente:string = '';
  public arrayAccesos:any=[];
  public selectGte:any=false;
  public selectSuc:any=false;
  public selectAgt:any=false;
  public arrayGerentes:any=[];
  public gteSelected:any='';
  public arrayAgentes:any=[];
  public agenteSelected:any={};
  public arrayDetalle:any=[];
  public periodo: any[] = [
    {value: '1', viewValue: 'Enero'},
    {value: '2', viewValue: 'Febrero'},
    {value: '3', viewValue: 'Marzo'},
    {value: '4', viewValue: 'Abril'},
    {value: '5', viewValue: 'Mayo'},
    {value: '6', viewValue: 'Junio'},
    {value: '7', viewValue: 'Julio'},
    {value: '8', viewValue: 'Agosto'},
    {value: '9', viewValue: 'Septiembre'},
    {value: '10', viewValue: 'Octubre'},
    {value: '11', viewValue: 'Noviembre'},
    {value: '12', viewValue: 'Diciembre'}
  ];

  public ejercicio: any[] = [
    {value: "2020", viewValue: "2020"},
    {value: "2021", viewValue: "2021"},
    {value: "2022", viewValue: "2022"}
  ];
  public strFechaActual:any ='';
  public intPeriodo:number=1;
  public intEjercicio:number=2022;
  constructor(elem:ElementRef,private _peticionesService: PeticionesService,public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router) { 
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.dataSource = new MatTableDataSource(this.arrayEvidencias);
  }

  ngOnInit(): void {
    const date = moment();
    this.strFechaActual = date;      
      var fecha = this.strFechaActual.format('YYYY.M.D');
      this.intEjercicio= this.strFechaActual.format('YYYY');
      this.intPeriodo=this.strFechaActual.format('M');

    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }
      else { 
      this.strGerente= objAcceso[0].strUsuario;
      this.selectGte=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
      this.selectSuc=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
      this.selectAgt=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
 
        if(this.selectSuc){
          this.gteSelected= {strGerente:objAcceso[0].strUsuario };
          this.fnGetSucursales(this.gteSelected);
        }
        if(this.selectGte){
          
          this.fnGetGerentes();
        }
        if(this.selectAgt){
          this.sucursalSelected = {strSucursal:objAcceso[0].sucursal};
          this.fnGetAgentes(this.sucursalSelected);
        }
        this.fnGetComodatos();
      
      }
    }
 
  }
  ngAfterViewInit() { 
    this.dataSource.paginator = this.paginator!;
    this.dataSource.paginator._intl.itemsPerPageLabel='Documentos por página'; 
    
    this.dataSource.paginator.pageSize=30;
  
    this.dataSource.sort = this.sort!;
  }

  fnGetSucursales(gerente:any){
    
    this._peticionesService.getApiCompras({
      strAccion: 'getSucursales' ,
      strGerente:gerente.strGerente
    }).subscribe(
      result => {  
        this.arraySucursales=result; 
      }
    );
  }
  fnGetAgentes(sucursal:any){
    this._peticionesService.getApiCompras({
      strAccion: 'getAgentes' ,
      strSucursal:sucursal.strSucursal
    }).subscribe(
    result => {  
      this.arrayAgentes=result;
      console.log(this.arrayAgentes);
    });  
  }
  fnFiltrar(){
    
  }
 
  fnGetGerentes(){
    this._peticionesService.getApiCompras({
      strAccion: 'getGerentes' 
    }).subscribe(
      result => { 
        this.arrayGerentes=result; 
      }
    );
  }

  fnGetComodatos(){
    this.boolTable=true;
    let objSend = {
      strAccion: 'getReporteComodatos',
      strGerente:(this.gteSelected.strGerente || this.gteSelected.strGerente!='')?this.gteSelected.strGerente:'',
      intPeriodo: this.intPeriodo,
      intEjercicio:this.intEjercicio,
      strSucursal: (this.sucursalSelected.strSucursal || this.sucursalSelected.strSucursal!='') ? this.sucursalSelected.strSucursal:'',
      strAgente: (this.agenteSelected.strEstatus || this.agenteSelected.strEstatus!='') ? this.agenteSelected.strEstatus:'',
      strJefeAgente: ''
    } 
    console.log(objSend);
    this._peticionesService.getApiComprasComodatos(objSend).subscribe(
      result => { 
        this.arrayEvidencias=result;
        this.dataSource.data = this.arrayEvidencias as PeriodicElement [];
        this.boolTable=false;
        this.dataSource.paginator = this.paginator!;
        this.dataSource.paginator._intl.itemsPerPageLabel='Documentos por página'; 
        
        this.dataSource.paginator.pageSize=30;
      
        this.dataSource.sort = this.sort!;
        console.log(result);
      }
    );
  }
  fnDetalle(element:any){
    this.boolBarraD=true;
    
    this.arrayDetalle=[];
    let objSend = {
      strAccion: 'getDetalleComodatos',
      intPeriodo: this.intPeriodo,
      intEjercicio:this.intEjercicio,
      strCliente: element.strCliente,
      strAgente:element.strAgente
    }
    
    this._peticionesService.getApiComprasComodatos(objSend).subscribe(
      result => { 
       this.arrayDetalle=result; 
       this.boolBarraD=false;
      }
    );
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
