<div class="container">
  <div class="row">
    <div class="col-12 text-center my-4">
      <h1>
        <b>Inventarios Rubbermaid </b>
      </h1>
    </div>
  </div>
  <div class="row pb-3">
    <div class="col-12">
      <div class="card shadow">
        <div class="card-header">
          <h2>
            <b>Buscar</b>
          </h2>
        </div>
        <div class="card-body">
          <mat-form-field appearance="fill" class="col-12 col-sm-7 col-lg-5">
            <mat-label>Artículo o SKU ID</mat-label>
            <input matInput placeholder="Escriba Artículo o SKU" (keyup)="applyFilter($event)" autocomplete="off"
              #input>
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row py-3">
    <div class="col-12">
      <div class="card shadow">
        <div class="card-body">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <h2 class="float-start">
                  <b>Inventarios</b>
                </h2>
                <button class="btn btn-success shadow-sm text-center float-right" (click)="fnActualizarTodo()">
                  <label class="fw-lighter" for>
                  </label>Actualizar Todo
                  <mat-icon style="vertical-align: middle">loop</mat-icon>
                </button>
              </div>
            </div>
            <!-- 
            <div class="row mx-1 mb-3 d-flex justify-content-between bg-light">
              <div class="col-12 p-2 clverde" >
                <label for=""><b> Actualizar por lotes</b></label>
              </div>
              <div class="col-5 col-md-3 py-3">
                <select [(ngModel)]="selected"  class="form-select" (change)="fnchangeSelect();">
       
                  <option value="{{item}}" *ngFor="let item of arraySelect;let i = index;" [selected]="item === 0">
                    {{item}}
                  </option>
                </select>
              </div> 
              <div class="col-3 col-md-2 position-relative">
                <label class="position-absolute top-50 start-50 translate-middle" for="">
                  {{strCantActualizar}}
                </label>
              </div>
              <div class="col-12 p-3">
                <button class="btn btn-success shadow-sm text-center float-right" (click)="fnActualizarTodo()">
                  <label class="fw-lighter" for>
                  </label>
                  <mat-icon style="vertical-align: middle">loop</mat-icon>
                </button>
              </div>
               
            </div>-->
          </div>
          <div class="col-12 table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 col-12 text-center">
              <!-- Num Sku id Column -->
              <ng-container matColumnDef="intskuID">
                <th mat-header-cell *matHeaderCellDef class="fw-bold text-black text-center px-1">
                  ID
                </th>
                <td mat-cell *matCellDef="let element; let i = index" class="px-1 border-end border-start">
                  <b>{{element.intskuID}}</b>
                </td>
              </ng-container>
              <!-- COLUMNA DE foto -->
              <ng-container matColumnDef="Foto">
                <th mat-header-cell *matHeaderCellDef class="fw-bold text-black text-center px-1">
                  FOTO
                </th>
                <td mat-cell *matCellDef="let element" class="px-1 border-end">
                  <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArtEcodeli + '.jpg'" class="imgTable py-2" alt="...">
                </td>
              </ng-container>
              <!-- Articulo Column -->
              <ng-container matColumnDef="strArtEcodeli">
                <th mat-header-cell *matHeaderCellDef class="fw-bold text-black text-center px-1">
                  Artículo
                </th>
                <td mat-cell *matCellDef="let element" class="border-end">
                  <b>{{ element.strArtEcodeli }}</b>
                </td>
              </ng-container>
              <ng-container matColumnDef="strArtVtex">
                <th mat-header-cell *matHeaderCellDef class="fw-bold text-black text-center ">
                  Reference Code
                </th>
                <td mat-cell *matCellDef="let element" class=" border-end">
                  <b>{{ element.strArtVtex }}</b>
                </td>
              </ng-container>
              <!-- Descripccion Column -->
              <ng-container matColumnDef="strDescripcion">
                <th mat-header-cell *matHeaderCellDef class="fw-bold text-black text-center">
                  Descripción
                </th>
                <td mat-cell *matCellDef="let element" class="border-end ">
                  {{ element.strDescripcion }}
                </td>
              </ng-container>
              <!-- Cantidad Ecodeli Column -->
              <ng-container matColumnDef="dblInventario">
                <th mat-header-cell *matHeaderCellDef class="fw-bold text-black text-center px-1">
                  Cantidad Ecodeli
                </th>
                <td mat-cell *matCellDef="let element" class="border-end">
                  <span class="badge bg-success rounded-circle shadow">
                    {{element.dblInventario}}
                  </span>
                </td>
              </ng-container>
              <!-- Accion Column -->
              <ng-container matColumnDef="Accion">
                <th mat-header-cell *matHeaderCellDef class="fw-bold text-black text-center px-3">
                  Actualizar
                </th>
                <!-- "fnUpdateInventory(element.intskuID,element.dblInventario,'Input');" -->
                <td mat-cell *matCellDef="let element" class="border-end text-center pe-0">
                  <button class="btn btn-primary my-1 shadow-sm" (click)="fnGetSkuID(element)">
                    <mat-icon>import_export</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tbody>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="clverde"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">
                    No existen datos al Buscar
                    <b>"{{ input.value }}"</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>