var lectura = 0;
export async function readTag(id) {
  let numSerie="";
  if ("NDEFReader" in window) {
    const ndefe = new NDEFReader();
    const ctrl = new AbortController();
    try {
      await ndefe.scan({ signal: ctrl.signal });
      ndefe.onreading = ({serialNumber})=>{
            document.getElementById(id+"t").value = serialNumber;
            numSerie=serialNumber;
            consoleLog("serie:" + serialNumber);  
      }
      ctrl.signal.onabort = () => {
        if(numSerie.length>1){
          alertify.success("LeidaCorrectamente");
        }else{
          document.getElementById(id+"t").value = "0";
        }
      };
      // Stop listening to NDEF messages after 3s.
      setTimeout(() => ctrl.abort(), 4_000);
      // Stop listening to NDEF messages after 3s.
    } catch(error) {
      alertify.error("Errore en: \n"+error);
   
      return String("error");
    }
  } else {
     alertify.error("Dispositivo no cuenta con soporte para NFC.");
   return String("error");
  }
}
 


/*





const ndef = new NDEFReader();
let ignoreRead = false;

ndef.onreading = (event) => {
  if (ignoreRead) {
    return; // write pending, ignore read.
  }

  console.log("We read a tag, but not during pending write!");
};




function write(id,serie) {
  alert(id+"////"+serie);
  let data={};
  let variable = "";
  variable = document.getElementById(id).value;
  data =  {
    records: [{ recordType: "url", data: "https://www.ecodeli.com/ComodatosProgramadorPreguntas/preguntas/" + variable }]
  };
  ignoreRead = true;
  return new Promise((resolve, reject) => {
    alert("Entra a la promesa");
    ndef.addEventListener("reading",event => {
      alert("Entra al listeneer"+event.serialNumber);
      // Check if we want to write to this tag, or reject.
      if(serie == event.serialNumber){
        alert("compara el serial "+event.serialNumber);
        ndef.write(data).then(resolve, reject).finally(() => {alertify.success("Escrito correctamente");});
      }else{
        alertify.error("Numero de serie incorrecto");
      }
    }, { once: true });
  });
}

export async function writeTag (id,serie) {
  await ndef.scan();
  try {
    await write(id,serie);
    console.log("We wrote to a tag!")
  } catch(err) {
    console.error("Something went wrong", err);
  }
}
 

 */

/* 






export async function writeTag(id,serie) {

  let varid = "";
  varid = id;
  let variable = "";
   variable = document.getElementById(varid).value;
if(validlectura(serie)){
  if ("NDEFReader" in window){
    const ndef = new NDEFReader();
    try {
      await ndef.write(
        {
          records: [{ recordType: "url", data: "https://www.ecodeli.com/ComodatosProgramadorPreguntas/preguntas/" + variable }]
        }
      );  
     alertify.success('Escrito correctamente: \n'+"https://www.ecodeli.com/ComodatosProgramadorPreguntas/preguntas/" + variable  ); 
    } catch(error){
      console.log(error);
      document.getElementById(id+"t").value = "";
     alertify.error("Errore en: \n"+error);
    }
  } else {
  alertify.error("Dispositivo no cuenta con soporte para NFC.");
  }
} else {alertify.error("Los numeros de serie de la tajeta no coinciden!")};
}


async function validlectura(serie) {
    const ndef = new NDEFReader();
   const ctrl = new AbortController();
    try {
      await ndef.scan({ signal: ctrl.signal });
           ndef.onreading = ({serialNumber})=>{
            if(serie===serialNumber){
            
              ctrl.signal.onabort = () => {
                return true;
              };
             
            }else{
              ctrl.signal.onabort = () => {
                return false;
              };
            }
      }
    } catch(error) {
      alertify.error("Errore en: \n"+error);
      ctrl.signal.onabort = () => {
        return false;
      };
     
    }
}
 */

export function read(variable,serie) {
 let  data =  {
     records: [{ recordType: "url", data: "https://www.ecodeli.com/ComodatosProgramadorPreguntas/preguntas/"+serie }]
   };
  const ndef = new NDEFReader();
  return new Promise((resolve, reject) => {
    const ctlr = new AbortController();
   /*  ctlr.signal.onabort = reject; */
    ctlr.signal.onabort = () => {reject("No se acerco ninguna tarjeta!"),resolve("Termino correctamente")};
    setTimeout(() => ctlr.abort(), 3_000);
    ndef.addEventListener("reading", event => {
    if(serie== event.serialNumber){ 
      ndef.write(data,{ signal: ctlr.signal }).then(resolve, reject).finally(() => {resolve(event);});
    }else{
      reject("Acerco una tarjeta diferente");
      /* alertify.error("error serie diferente"); */
    } 
     /*  ctlr.abort(); */
     /*  resolve(event); */
    }, { once: true });
    ndef.scan({ signal: ctlr.signal }).catch(err => reject(err));
  });
}

export async function writeTag(id,serie)  {
  if ("NDEFReader" in window) { 
    let variable = document.getElementById(id).value;  
    let boton = document.getElementById(id+"btn");
 /*    const { serialNumber } = await read(variable,serie); */
 try {
  await read(variable, serie);
  alertify.success("Escritura completada con éxito");
  boton.click();
} catch (error) {
  alertify.error(`Error al Escribir en tarjeta: ${error}`);
  cleaninput(id);
}  
   }else{
     alertify.error("No tiene nfc");
   }
}

export function cleaninput(id){
  document.getElementById(id+"t").value="0"; 
}
/* export function cleanIntser(id){
  document.getElementById(id+"noserie").value="0";
  document.getElementById(id+"t").value="0"; 
} */











/* 
export function read(variable,serie) {

  let  data =  {
      records: [{ recordType: "url", data: "https://www.ecodeli.com/ComodatosProgramadorPreguntas/preguntas/"+variable }]
    };
   const ndef = new NDEFReader();
   
   return new Promise((resolve, reject) => {
     const ctlr = new AbortController();
   
     ctlr.signal.onabort = () => {reject("Problemas en conexion!"),resolve("Termino correctamente")};
     setTimeout(() => ctlr.abort(), 3_000);
     ndef.addEventListener("reading", event => {
     if(serie== event.serialNumber){ 
       ndef.write(data,{ signal: ctlr.signal }).then(resolve, reject).finally(() => {resolve(event);});
     }else{
       alertify.error("error serie diferente");
     } 
    
     }, { once: true });
     ndef.scan({ signal: ctlr.signal }).catch(err => reject(err));
   });
 }
 
 export async function writeTag(id,serie)  {
   if ("NDEFReader" in window) { 
   let variable = "";
    variable = document.getElementById(id).value; 

  const result = await read(variable,serie);
  if (result=="Problemas en conexion!"){
   alertify.error("Problemas en conexion!");
  }else if(result=="Termino correctamente"){}
 
  alertify.success("Termino correctamente");     
    }else{
      alertify.error("No tiene nfc");
    }
 } */
 
