<div class="container" id="email-content">
    
    <div class="row justify-content-center mb-4">
        <div class="col-12 col-lg-8 text-start fs-6 bg-white rounded shadow-sm pb-3 my-4">
            <div class="row">
                <div class="col-12">
                    <h1 class="nunito my-3 fw-bold text-center">
                        <span class="text-success me-2"><i class="bi bi-check-circle"></i></span>Pedido Confirmado
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>
                        Hola {{genDatos.strCliente}}. 
                     </p>
                     <p class="lh-sm">
                         Gracias por su compra. Todos los detalles se encuentran a continuación. Le enviaremos un correo electrónico una vez que su pedido haya sido despachado. 
                     </p>
                     <p class="lh-sm">
                        No dude en comunicarse con nosotros si tiene alguna pregunta o necesita asistencia con respecto a su compra.
                     </p>
                     <p class="lh-sm">
                         Saludos cordiales,Equipo de Ecodeli.
                     </p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="nunito fw-bold my-2">Número De Pedido</h3>
                </div>
                <div class="col-12">
                    <p>{{intPedido}}</p>
                </div>
                <div class="col-12">
                    <h3 class="nunito fw-bold my-2">Fecha Del Pedido</h3>
                </div>
                <div class="col-12">
                    <p>{{strFechaPedido}}</p>
                </div>

                <div class="col-12">
                    <h3 class="nunito fw-bold my-2">Código de entrega:</h3>
                </div>
                <div class="col-12">
                    <p><b>{{strCodigoEntrega}} </b></p>
                </div>

            </div>
            <div class="row justify-content-center">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="nunito fw-bold my-2">Dirección de entrega</h3>
                </div>
                <div class="col-12 d-flex flex-column">
                    <span class="my-1 clsGray">{{genDatos.strNombre}}</span><br>
                    <span class="my-1 clsGray">{{genDatos.strDireccion}}, Col: {{genDatos.strColonia}},</span><br>
                    <span class="my-1 clsGray">
                     
                       <span>{{genDatos.strPoblacion}},</span> 
                        
                        {{genDatos.strEstado}}.</span><br>
                   
                    <span class="my-1 clsGray">CP: {{genDatos.strCodigoPostal}}</span><br>
                    <span class="my-1 clsGray">Telefono: {{genDatos.strTelefonos}} </span><br>
                    <span class="my-1 text-primary" *ngIf="!boolFacturacion">{{genDatos.strEmail_f}} </span><br>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="boolFacturacion">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row" *ngIf="boolFacturacion">
                <div class="col-12">
                    <h3 class="nunito fw-bold my-2">Dirección de facturación</h3>
                </div>
                <div class="col-12 d-flex flex-column">
                    <span class="my-1 clsGray">{{strNombre_f}}</span><br>
                    <span class="my-1 clsGray">{{strDireccion_f}}, Col: {{strColonia_f}}</span><br>
                    <span class="my-1 clsGray">{{strCiudad_f}}, {{strEstado_f}}.</span><br>
                    <span class="my-1 clsGray">CP: {{strCodigoPostal_f}}</span><br>
                    <span class="my-1 clsGray">Telefono: {{strTelefono_f}} </span><br>
                    <span class="my-1 text-primary">{{strEmail_f}} </span><br>
                    <div class="row justify-content-start" *ngIf="boolFacturacion">
                        <div class="col-5">
                            <hr>
                        </div>
                    </div>
                    <span class="mt-2 clsGray">RAZON SOCIAL: {{strRazonSocial_f}}</span>
                    <span class="my-1 clsGray">CFDI: {{strUsoCfdi_f}}</span>
                    <span class="mb-2 clsGray">RFC: {{strRFC_f}}</span>
                    
                </div>
                <div class="col-12">
                    <h3 class="nunito fw-bold my-2">Forma Pago</h3>
                </div>
                <div class="col-12 d-flex flex-column">
                    <span class="my-1 clsGray">{{strFormaPago}}</span>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="nunito fw-bold my-2">Productos</h3>
                </div>
                <div class="col-1 d-none d-lg-block" ></div>
                <div class="col-12 col-lg-10 d-flex flex-column nunito">
                     <div class="row bg-light align-items-center my-2 py-2" *ngFor="let prod of arrayProductos">
                        <div class="col-4 col-lg-2 ">
                            <img class="w-100  rounded shadow-sm" src="{{prod.urlImagen}}" alt="" style="width: 8rem;" (error)="onImageError($event, prod)">
                        </div>
                        <div class="col-5 col-lg-4 d-flex flex-column">
                            <span class="fw-bold fs13 mt-auto">
                                {{prod.strDescripcion}}
                            </span>

                            <span class="fw-bold fs13" *ngIf="prod.opcion && prod.opcion!= ''">
                                ({{prod.opcion}}): {{prod.strNombreOpcion}}
                            </span>
                            <span class="fw-bold fs13 text-secondary mb-auto">
                                {{prod.strProducto}}
                            </span>
                          
                        </div>
                        <div class="col-3 col-lg-3 d-flex flex-column">
                            <span class="fw-bold fs12 text-end text-secondary mt-auto">
                                {{prod.dblPrecio|currency}}
                            </span>
                            <span class="fw-bold fs12 d-flex justify-content-end text-secondary mb-auto">
                             <span> Cantidad: {{prod.dblCantidad}} {{prod.strUnidad}}</span> 
                            </span>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12">
                    <hr>
                </div>
            </div>
 
            <div class="row ">
                <div class="col-7 d-flex flex-column  ">
                    <span class="ms-auto fw-bold fs-6">Total ({{arrayProductos.length}})</span>
                </div>
                <div class="col-4 d-flex flex-column">
                    <span class="ms-auto fs-6"> {{dblTotal|currency}}</span>
                </div>
                <div class="col-1"></div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            
            <div class="row ">
                <div class="col-12 text-center mb-3">
                    <span class="fw-bold" style="text-align: center;">CONTACTO</span>
                </div>
                <div class="col-12 text-center clsGray d-flex flex-column">
                    <span>Lunes a viernes de 08:30 am a 06:00 pm </span><br>
                    <span class="my-1">Sabado 09:00 am a 02:00 pm </span>
                </div>
                <div class="col-12 text-center clsGray mt-2 d-flex flex-column">
                    <span>https://www.ecodeli.com/</span> <br>
                    <span class="my-1">477 729 63 02</span>
                </div>
                <div class="col-12 text-center clsGray mt-3">
                    <a href="https://wa.me/524777296302" class="clsGray mx-2" target="_blank">
                        <i class="fa-brands fa-whatsapp fs-4"></i>
                    </a>
                    <a href="https://www.facebook.com/GrupoEcodeli" class="clsGray mx-2" target="_blank">
                        <i class="fa-brands fa-facebook fs-4"></i>
                    </a>
                    <a href="https://www.instagram.com/ecodelioficial/" class="clsGray mx-2" target="_blank">
                        <i class="fa-brands fa-instagram fs-4"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>