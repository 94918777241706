<h2 mat-dialog-title>Información</h2>
<mat-dialog-content class="mat-typography text-center">

    <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let preguntas of arrayPreguntas; let i = index">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + i" aria-expanded="true" aria-controls="collapseOne">
                {{preguntas.Articulo}}
                {{preguntas.ID}}
              </button>
            </h2>
          <div [attr.id]="'collapse-' + i" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">

              <div class="row">
                <div class="col-6">
                  <mat-form-field appearance="outline" class="inputG">
                    <mat-label>Edificio</mat-label>
                    <input matInput>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Nivel</mat-label>
                    <mat-select  placeholder="Selecciona una opción" (selectionChange)="AgregarRespuesta(preguntas.Articulo, i, $event.value, 'nivel' )">
                      <mat-option *ngFor="let nivel of arrayNivel" [value]="nivel.nivel">{{ nivel.nivel }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-4 col-md-4 col-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Nombre Área</mat-label>
                    <input matInput [(ngModel)]="miValor">

                    <!--<input type="text" [(ngModel)]="miValor" (ngModelChange)="guardarValor()">-->


                  </mat-form-field>
                </div>
                
                <div class="col-lg-4 col-md-4 col-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo Área</mat-label>
                    <mat-select  placeholder="Selecciona una opción" (selectionChange)="AgregarRespuesta(preguntas.Articulo, i, $event.value, 'tipoArea' )">
                      <mat-option *ngFor="let tipo of arrayTipoArea" [value]="tipo.tipo">{{tipo.tipo}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              
                <div class="col-lg-4 col-md-4 col-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Afluencia</mat-label>
                    <mat-select  placeholder="Selecciona una opción" (selectionChange)="AgregarRespuesta(preguntas.Articulo, i, $event.value, 'afluencia' )">
                      <mat-option *ngFor="let afluencia of arrayAfluencia" [value]="afluencia.afluencia">{{ afluencia.afluencia }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <input type="time" class="form-control ng-pristine ng-untouched ng-valid ng-empty">
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <input type="time" class="form-control ng-pristine ng-untouched ng-valid ng-empty">
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <input type="time" class="form-control ng-pristine ng-untouched ng-valid ng-empty">
                </div>
              </div>

            </div>
          </div>
        </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-danger">Cancelar</button>
  <button mat-button class="btn-success" cdkFocusInitial>Guardar Prueba</button><!--(click)="" -->
</mat-dialog-actions>