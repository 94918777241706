import { Component, OnInit,ViewChild,ElementRef,Renderer2 } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';
import { CarroService } from '../servicios/carroservice';
import Swal from 'sweetalert2';
import { AlertifyService } from '../alertify.service';
import { ComodatoService } from '../servicios/comodato.service';
@Component({
  selector: 'app-comodatos-articulos',
  templateUrl: './comodatos-articulos.component.html',
  styleUrls: ['./comodatos-articulos.component.css']
})
export class ComodatosArticulosComponent implements OnInit {
  isExpanded = false;
  public objCliente:any={};
  public arrayArticulos:any=[];
  public strConexion: string = "";
  public selectedCategory:string='';
  public strCategoria:string='';
  public strGrupo:string='';
  public strSubLinea:string='';
  public priceRange:string='';
  public strbuscarLista:"";
  public strArticulo:string='';
  public strTitulo:string=''
  public spiner:boolean=false;
  public arrayArtsporlinea:any=[];
  public strLinea:string = "";
  public strBuscar:string="";
  public arrayLineas : any =[];
  public objArt:any = {
    strArticulo:'', 
    strDescripcion1:'', 
    dblCantidad:1,
    strOpcion:'', 
    strNombreOpcion:'', 
    dblPrecio:0,
    objConsumible:{strTipoOpcion:'No', strConsumible:''} 
  };
  public spinerCarrito:boolean;
  public imagenUrl1: string;
  public imagenUrl2: string;
  public imagenUrl3: string;
  public imagenUrl4: string;
  public imagenUrl5: string ;
  public intPrecioPolitica:number =0;
  public intPrecioPlantilla:number =0;
  public intPrecioLista:number =0;
  public intPrecio:number=0;
  public intPrecio2:number=0;
  public intPrecio3:number=0;
  public intPrecio4:number=0;
  public Accesop4:any;
  public arrayCarritoArts:any;
  public arrayPreciosArt = [];
  public arrayExistentes:any;
  public arrayConsumible:any;
  public arrayConsumibleOpcion:any;
  public spinnerModal:boolean=false;
  constructor(public commodatoservice:ComodatoService,public _alertify: AlertifyService,private renderer: Renderer2,private carroservice:CarroService,private indexedDBService: DataDBService,private router: Router
    ,private localSt: LocalStorageService,private _httpService: PeticionesService) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strCategoria= this.localSt.retrieve('strCategoria_Comodato');
    this.strGrupo= this.localSt.retrieve('strGrupo_Comodato');
   }

  ngOnInit(): void {
    this.fnExtraerXlinea();
    this.fnExtraerlineas();
  }
  fnExtraerXlinea(){
    this.arrayArtsporlinea=[];
   // this.arrayLineas = [];
    let myobject1 = {strAccion:'getArt-Linea','strCategoria': this.strCategoria,'strGrupo': this.strGrupo,strConexion:this.strConexion};
    this._httpService.getLineasComodatos(myobject1,"sp_ModVentas_Comodato").subscribe(
      result => {
        this.arrayArtsporlinea = result;
        /*this.arrayArtsporlinea.forEach(x => {
          let bandera:any;
          bandera = this.arrayLineas.find(item => item.strLinea == x.strLinea);
          if(!bandera){
            this.arrayLineas.push(x)
          }
          x.mosDescrip = false;
        });*/
        console.log(this.arrayLineas)
        this.strLinea = this.strGrupo;//this.arrayLineas[0] && this.arrayLineas[0].strLinea ?this.arrayLineas[0].strLinea :""; 
      });
  }
  fnExtraerlineas(){
    this.arrayLineas=[];
    this._httpService.getLineasComodatos({
      strAccion:'getCategoriasComodatos',
      strConexion:this.strConexion
    },"sp_ModVentas_Comodato").subscribe(
      result => {
        this.arrayLineas = result;
        console.log(this.arrayLineas)
      });
  }
  fnSelectLinea(obj:any){
    this.strLinea = obj.strGrupo;
    this.strGrupo=obj.strGrupo;
    this.fnExtraerXlinea();
  }
    
  getImageUrl(strArticulo: string): string {
    if (strArticulo) {
      return `https://www.cleanclean.mx/imagenes/${strArticulo}.jpg`;
    }
    return 'https://www.cleanclean.mx/imagenes/NO-DISPONIBLE.JPG';
  }

  onImageError(event: any): void {
    event.target.src = 'https://www.cleanclean.mx/imagenes/NO-DISPONIBLE.JPG';
  }
  fnAbrirModal(objArt:any){
    try {
      this.spinnerModal = true;
      console.log(objArt)
      objArt.objConsumible ={strTipoOpcion:'No', strConsumible:''};
      this.fnGetConsumible(objArt);
      if(objArt.strTipoOpcion != "No"){
        this.objArt.arrayOpciones =[];
        this.fnExtraerOpciones(objArt);
      } else {
         objArt.strNombreOpcion ='';
         objArt.strOpcion ='';
      }
      let url ='https://www.cleanclean.mx/imagenes/'
      console.log('abre modal');
      this.imagenUrl1=url+objArt.strArticulo+'.JPG';
      this.imagenUrl2= url+objArt.strArticulo+'-PO-2.JPG';
      this.imagenUrl3=url+objArt.strArticulo+'-PO-3.JPG';
      this.imagenUrl4=url+objArt.strArticulo+'-PO-4.JPG';
      this.imagenUrl5=url+objArt.strArticulo+'-PO-5.JPG';
      objArt.dblCantidad=1; //inicializamos la cantidad
      this.objArt=objArt;
      this.fnArtSinLp(objArt);
      this.fntraerExistencias(objArt);
      setTimeout(() => {
        this.spinnerModal = false;
      }, 2000); 
    } catch (error) {
      this.spinnerModal = false;
      let  message = 'Ocurrió un error: ' + error;
      console.log(message);
    }
   

  }
  fnGetConsumible(objArt:any){
    this.arrayConsumible=[];
     this._httpService.getLineasComodatos({
       strAccion:'getOpcionArticuloConsumible',
       strConexion:this.strConexion,
       strArticulo:objArt.strArticulo
     },"sp_ModVentas_Comodato").subscribe(
       result => {
        let arrayC =result;
        
        arrayC.forEach(element => {
         this.arrayConsumible.push({strTipoOpcion:element.strTipoOpcion, strConsumible:element.strConsumible, strNombreConsumible: element.strNombreConsumible});
        });
        this.objArt.arrayConsumible=this.arrayConsumible;
       
         this.objArt.objConsumible= this.arrayConsumible.length >0 ?   this.arrayConsumible[0] :{strTipoOpcion:'No', strConsumible:'', strNombreConsumible:''}; 
   
         if ( this.objArt.objConsumible &&  this.objArt.objConsumible.strTipoOpcion=='Si')
         {
           
           this.fnGetOpcionConsumible(this.objArt.objConsumible.strConsumible)
         }
        
      
       });
   }
   onOpcionSeleccionada(opcion:string,objArt:any){
    this.objArt.arrayOpciones.forEach(o => {
      if(opcion == o.strOpcion ){
        this.objArt.strNombreOpcion = o.strNombreOpcion
      }
    });
    this.fntraerExistencias(objArt);
  }
  fnCambiaConsumible(objArt:any){
    if ( objArt &&  objArt.strTipoOpcion=='Si')
    {
      this.fnGetOpcionConsumible(this.objArt.objConsumible.strConsumible)
    }
  }
  fnAdd_Rest_Amount(objArt:any, tipo:string){
    if( tipo == 'add' ) {
        objArt.dblCantidad=objArt.dblCantidad + 1;
    }else{
        objArt.dblCantidad=objArt.dblCantidad - 1;
    }
  }
  fnAddToCarr( objArticulo:any){ //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado
  
    objArticulo.strComentario = "";
    this.spinerCarrito = true;
     objArticulo.dblCantidad = parseInt(objArticulo.dblCantidad);
   const deepCopy = JSON.parse(JSON.stringify(objArticulo)); 
    this.commodatoservice.setArticulo( deepCopy); 
    setTimeout(() => {
      this.spinerCarrito = false;
      this._alertify.success("Articulo agregado correctamente");
    }, 400);
  }
  fntraerExistencias(object:any){ 

    let opcion = "";
    if(object.strTipoOpcion == "Si"){
      opcion=object.strOpcion;
    }
    let articulo = {
      strArticulo:object.strArticulo,
      strSubcuenta:opcion,
      strConexion: this.strConexion,
      strAccion:'getExistencias'
    }; 
    this._httpService.getListaPrecios(articulo).subscribe(
      result => {
       this.arrayExistentes = result;

     //  this.toggleAccordion();
       this.ordenarArray() ;
      }); 
   }
   fnExtraerOpciones(objArt:any){
    let articulo = {
      strAccion:'getOpcion',
      strArticuloBusqueda:objArt.strArticulo,
      strConexion:this.strConexion
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => { 
        this.objArt.arrayOpciones = result;
        this.objArt.strNombreOpcion = this.objArt.arrayOpciones[0].strNombreOpcion;
        this.objArt.strOpcion = this.objArt.arrayOpciones[0].strOpcion; 
      });
  }
  fnArtSinLp(arrayArt:any){ 
    this.intPrecioPolitica =0;
    this.intPrecioPlantilla =0;
    this.intPrecioLista =0;
    this.intPrecio=0;
    this.intPrecio2=0;
    this.intPrecio3=0;
    this.intPrecio4=0;
    this.arrayPreciosArt = [];
    this._httpService.getListaPrecios({
      strAccion: 'get_PreciosArticulos',
      xmlArticulos:[arrayArt],
      strCliente:this.objCliente.strCliente,
      strConexion:this.strConexion
    }).subscribe(
      result => {
        this.arrayPreciosArt = result; 
        if(arrayArt.strTipoOpcion == "Si"){
          let rest = this.arrayPreciosArt.find(obj => obj.strOpcion.trim() == arrayArt.strOpcion.trim());
          this.intPrecioLista = rest.intPrecioLista
          this.intPrecio=rest.intPrecio;
          this.intPrecio2=rest.intPrecio2;
          this.intPrecio3=rest.intPrecio3;
          this.intPrecio4= this.Accesop4 ? rest.intPrecio4:0;
          this.intPrecioPlantilla= rest.intPrecioPlantilla;
          this.intPrecioPolitica= rest.intPrecioPolitica; 
        }else{
          this.intPrecioLista = this.arrayPreciosArt[0].intPrecioLista;
          this.intPrecio=this.arrayPreciosArt[0].intPrecio;
          this.intPrecio2=this.arrayPreciosArt[0].intPrecio2;
          this.intPrecio3=this.arrayPreciosArt[0].intPrecio3;
          this.intPrecio4=this.Accesop4 ? this.arrayPreciosArt[0].intPrecio4:0;
          this.intPrecioPlantilla= this.arrayPreciosArt[0].intPrecioPlantilla;
          this.intPrecioPolitica= this.arrayPreciosArt[0].intPrecioPolitica; 
        }
        this.objArt.dblPrecioLista =this.intPrecioPolitica>0?this.intPrecioPolitica:this.intPrecioPlantilla>0?this.intPrecioPlantilla:this.intPrecioLista;
      },
      error => {
        var error = <any>error;
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Uuups...',
          text: 'Error en conexion al extraer articulos sin precio lista, intente de nuevo.'
        });});
   }
   fnGetOpcionConsumible(articulo:string) {
    this.arrayConsumibleOpcion=[];
    this._httpService.getLineasComodatos({
      strAccion:'GetArticuloOpciones',
      strConexion:this.strConexion,
      strArticulo:articulo
    },"sp_ModVentas_Comodato").subscribe(
      result => {
        let arrayC=result;
        arrayC.forEach(element => {
          this.arrayConsumibleOpcion.push({strOpcion:element.strOpcion, strNombreOpcion:element.strNombreOpcion });
        });      
       // this.objArt.objConsumible.objOpcion=this.arrayConsumibleOpcion.length>0? this.arrayConsumibleOpcion[0]:{};
        this.objArt.objOpcionConsumible=this.arrayConsumibleOpcion.length>0? this.arrayConsumibleOpcion[0]:{};
        this.objArt.arrayOpcionConsumible=this.arrayConsumibleOpcion; 
      });
  }
  ordenarArray(): void {
    this.arrayExistentes.sort(this.compareAlphanumeric);
  }
  compareAlphanumeric(a: { strAlmacen: string }, b: { strAlmacen: string }): number {
    return a.strAlmacen.localeCompare(b.strAlmacen, undefined, { numeric: true, sensitivity: 'base' });
  }
  addToCart(obj:any)
  {
    //agregar a carrito
    this.localSt.store('strArticulo_Seleccionado_Comodato',obj);
    
    this.localSt.clear('strArticulo_Seleccionado_Cotizador' );
    this.router.navigate(['/detalle_articulo']);
  }
  toggleAccordion(objArt:any ) {
    let collapseId='collapseOne'
    this.isExpanded = !this.isExpanded;
    this.fntraerExistencias(objArt)
    // Optionally, you could also use ViewChild to manually toggle classes or access elements.
  }
}
