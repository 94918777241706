import { Component, OnInit, ElementRef} from '@angular/core';//Inject
import { PeticionesService } from '../servicios/httpservice';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {FormControl} from '@angular/forms';



import Swal from 'sweetalert2';

@Component({
  selector: 'app-reto-tork-bitacora-inicial',
  templateUrl: './reto-tork-bitacora-inicial.component.html',
  styleUrls: ['./reto-tork-bitacora-inicial.component.css']
})
export class RetoTorkBitacoraInicialComponent implements OnInit {
  disableSelect = new FormControl(false);
  public arrayClientes: any=[];
  public strCliente: string="";
  public intIDSucursal: number=0;
  public strNombreSucursal: string="";
  public strTipo: string="Cliente";
  public strAgente: string="";
  public mostrarCargando:boolean=false;
  public strFechaInicial: any;
  public strFechaFinal: any;
  public diaFin: any;
  public diaInicio: any;
  public strGiro: string="";
  public strDireccion: string="";
  public dblPotencialVenta: number=0;
  public strTelefono: string="";
  public strContacto: string="";
  public strPuesto: string="";
  public strOtroPuesto: string="";
  public strBusqueda: string="";
  public strNombre: string="";
  public arrayBitacora: any=[];
  public arrayRespuesta: any=[];
  public arrayActual: any=[];
  public arrayConsumibles: any=[];
  public arrayDespachadores: any=[];
  public strComentarios:string="";
  public strNombreVendedor:string="";
  public arrayAccesos: any=[];
  public arrayGiros: any=[];
  public arrayListadoDespachadores: any=[];
  public arrayListadoConsumibles: any=[];
  public arrayPuestos: any=[{"strPuesto":"Administración"}, {"strPuesto":"Compras"}, {"strPuesto": "Dirección"}, {"strPuesto":"Subdirección"}, {"strPuesto":"Otro"}]
  public arrayDetalleTork: any=[];
  public intIDReto: number=0;
  public blnDetalle:boolean=false;
  public arrayListaDetalle: any=[];
  public arrayListaDetalleActual: any=[];
  public strEstatus: string="PENDIENTE";
  public strAccion: string="";
  public tagName:any;
  public btnGuardar: boolean=false;
  public btnAutorizar: boolean=false;
  public btnRechazar: boolean=false;
  public btnEditar: boolean=false;
  public arrayCategoria: any=[];  
  isLinear = false;
  public spOcultarBoton:boolean=false;
  public spCargando: boolean=false;
  public strMotivoRechazoReto: string='';
  public dblTotalReto: number=0;
  public strTipoAcceso: string="";
  public strUsuario: string="";
  public strTipoReto: string="RETO";
  public strSucursal:string="";
  //constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialog, private _peticionesService: PeticionesService, private localSt:LocalStorageService) {
  constructor( elem:ElementRef, private _formBuilder: FormBuilder, private dialogRef: MatDialog, private _peticionesService: PeticionesService, private localSt:LocalStorageService, private router: Router ) {  
    this.tagName=elem.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    var date = new Date();
    var mes=date.getMonth() + 1;
    var dia=date.getDate();
    var mes2=mes<10?'0'+mes:mes.toString();
    var dia2=dia<10?'0'+dia:dia.toString();
    this.strFechaInicial=date.getFullYear()+'-'+mes2+'-'+dia2;

    var dateFin = new Date();
    dateFin.setDate(date.getDate() + 4);

    var mesF=dateFin.getMonth() + 1;
    var diaF=dateFin.getDate();
    var mesF2=mesF<10?'0'+mesF:mesF.toString();
    var diaF2=diaF<10?'0'+diaF:diaF.toString();

    //console.log(dateFin);
    this.strFechaFinal=dateFin.getFullYear()+'-'+mesF2+'-'+diaF2;
    this.diaFin=dateFin.getFullYear()+'-'+mesF2+'-'+diaF2;
    
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):{};
    console.log(this.arrayAccesos);
    this.strTipoAcceso=this.arrayAccesos.length>0 && this.arrayAccesos!=undefined?this.arrayAccesos[0].strTipoAcceso:'';
    //Crear las variables para mostrar o no los botones o permisos a los que tiene acceso el usuario
    if(this.arrayAccesos.length>0 && this.arrayAccesos!=undefined){
      this.strAgente=this.arrayAccesos[0]?this.arrayAccesos[0].strAgente:'';
      this.strTipoAcceso=this.arrayAccesos[0]?this.arrayAccesos[0].strTipoAcceso:'';
      this.strUsuario=this.arrayAccesos[0]?this.arrayAccesos[0].strUsuario:'';
      this.strSucursal=this.arrayAccesos[0]?this.arrayAccesos[0].strSucursal:'';

      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      this.strNombreVendedor=this.arrayAccesos[0].strNombre+' '+this.arrayAccesos[0].strApellido;      
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }
      else { 
        this.btnGuardar=objAcceso.find((o:any)=>o.strAccion=='Guardar')?true:false;  
        this.btnEditar=objAcceso.find((o:any)=>o.strAccion=='Editar')?true:false;         
        this.btnAutorizar=objAcceso.find((o:any)=>o.strAccion=='Autorizar')?true:false;   
        this.btnRechazar=objAcceso.find((o:any)=>o.strAccion=='Rechazar')?true:false;  
      }
    }else{
      this.router.navigate(['/login']);
    }

    //this.fnAgregar('Actual');//Armar array inicial
    //this.fnAgregar('Tork');//Armar array inicial
    //this.fnAgregar('Entregado');//Armar array inicial

    this.fnBuscarGiros();
    this.fnTraerDespachadoresTork();
    this.fnGetCategorias();
    this.fnTraerConsumiblesTork();

    //Valida si esta viendo detalle
    this.arrayDetalleTork=this.localSt.retrieve('arraydetalletork')?this.localSt.retrieve('arraydetalletork'):{};
    if(this.arrayDetalleTork.intIDReto>0){
      console.log(this.arrayDetalleTork);
      this.blnDetalle=true;
      this.strCliente=this.arrayDetalleTork.strCliente?this.arrayDetalleTork.strCliente:'';
      this.strGiro=this.arrayDetalleTork.strGiro?this.arrayDetalleTork.strGiro:'';
      this.strDireccion=this.arrayDetalleTork.strDireccion?this.arrayDetalleTork.strDireccion:'';
      this.strTelefono=this.arrayDetalleTork.strTelefono?this.arrayDetalleTork.strTelefono:'';
      this.strContacto=this.arrayDetalleTork.strContacto?this.arrayDetalleTork.strContacto:'';
      this.strNombre=this.arrayDetalleTork.strNombre?this.arrayDetalleTork.strNombre:'';
      this.intIDSucursal=this.arrayDetalleTork.intIDSucursal?this.arrayDetalleTork.intIDSucursal:0;
      this.strNombreSucursal=this.arrayDetalleTork.strNombreSucursal?this.arrayDetalleTork.strNombreSucursal:'';
      this.strNombre=this.arrayDetalleTork.strNombre?this.arrayDetalleTork.strNombre:'';
      this.strTipo=this.arrayDetalleTork.strTipo?this.arrayDetalleTork.strTipo:'';
      this.strFechaInicial=this.arrayDetalleTork.strFechaInicial?this.arrayDetalleTork.strFechaInicial:'';
      this.strFechaFinal=this.arrayDetalleTork.strFechaFinal?this.arrayDetalleTork.strFechaFinal:'';

      this.intIDReto=this.arrayDetalleTork.intIDReto?this.arrayDetalleTork.intIDReto:0;
      this.strComentarios=this.arrayDetalleTork.strComentarios?this.arrayDetalleTork.strComentarios:'';
      this.strEstatus=this.arrayDetalleTork.strEstatus?this.arrayDetalleTork.strEstatus:'';
      this.strMotivoRechazoReto=this.arrayDetalleTork.strMotivoRechazoReto?this.arrayDetalleTork.strMotivoRechazoReto:'';
      this.dblPotencialVenta=this.arrayDetalleTork.dblPotencialVenta?this.arrayDetalleTork.dblPotencialVenta:0;

      //Valida si el puesto existe en el array de puestos o puso otro:
      var buscarPuesto=this.arrayPuestos.filter((p:any) => p.strPuesto == (this.arrayDetalleTork.strPuesto?this.arrayDetalleTork.strPuesto:''));
      if(buscarPuesto.length>0){
        this.strPuesto=this.arrayDetalleTork.strPuesto?this.arrayDetalleTork.strPuesto:'';
      }else{
        this.strPuesto='Otro';
        this.strOtroPuesto=this.arrayDetalleTork.strPuesto?this.arrayDetalleTork.strPuesto:'';
      }
      this.fnGetListaDetalle(this.intIDReto);
    }else{
      //Llenar las tablas con minimo 1 articulo (es un Nuevo Reto)
      this.fnAgregar('Actual');
      this.fnAgregar('Tork');
      this.fnAgregar('Entregado');
    }
    //console.log("Detalle"); console.log(this.arrayDetalleTork);
  }

  fnGetListaDetalle(idReto:number){
    this.dblTotalReto=0;
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getListaDetalle',
      "intIDReto": idReto
    }).subscribe( result => {
      this.arrayListaDetalle=result;
      console.log(result);
      if(this.arrayListaDetalle.length>0){
        this.arrayListaDetalle.forEach(element => {
          this.arrayDespachadores.push({
            strCategoria: element.strCategoria,
            strClaveDespachador: element.strClaveDespachador,
            strNombreDespachador: element.strNombreDespachador,
            intCantidadDespachador: element.intCantidadDespachador,
            strUnidadDespachador: element.strUnidadDespachador, //'PZ',//
            strClaveConsumible: element.strClaveConsumible,
            strNombreConsumible: element.strNombreConsumible,
            intCantidadConsumible: element.intCantidadConsumible,
            strUnidadConsumible:element.strUnidadConsumible,// 'PZ',//
            dblPrecioConsumible: element.dblPrecioConsumible,
            dblCostoPromedio: element.dblCostoPromedio
          });
          //TotalReto
          this.dblTotalReto=this.dblTotalReto+(element.dblCostoPromedio*element.intCantidadConsumible);
        });
      }
      this.fnGetListaDetalleActual(idReto);//Traer detalle de tabla Actual
    });
  }
  
  fnGetListaDetalleActual(idReto:number){
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getListaDetalleActual',
      "intIDReto": idReto
    }).subscribe( result => {
      this.arrayListaDetalleActual=result;
      //console.log("arrayListaDetalleActual");

      if(this.arrayListaDetalleActual.length>0){
        this.arrayListaDetalleActual.forEach(element => {
          this.arrayActual.push({
            strCategoria: element.strCategoria,
            strMarca: element.strMarca,        
            strClaveDespachador: element.strClaveDespachador,
            strNombreDespachador: element.strNombreDespachador,
            intCantidadDespachador: element.intCantidadDespachador,
            strUnidadDespachador: 'PZ',//element.strUnidadDespachador,
            strClaveConsumible: element.strClaveConsumible,
            strNombreConsumible: element.strNombreConsumible,
            intCantidadConsumible: element.intCantidadConsumible,
            strUnidadConsumible: 'PZ',//element.strUnidadConsumible,
            dblPrecioConsumible: element.dblPrecioConsumible
          });
        });
      }
    });
  }

  fnTipoReto(tipo:string){
    this.strTipoReto=tipo;
  }

  updatePrecioConsumible(element: any, event:any){
    element.dblPrecioConsumible = event.target.value;//Modifica el valor de la variable
  }

  fnBuscarClienteProsp(){
    this.fnLimpiarCampos();
    this.mostrarCargando=true;
    this.arrayClientes=[];

    if(this.strBusqueda==undefined || this.strBusqueda=='' || this.strBusqueda==null){
      this.mostrarCargando=false;
      this.arrayClientes=[];      
      return;
    }
    let objParams={
      "strAccion": this.strTipo=='Cliente'?'getCliente':'getProspecto',
      "strAgente": this.strTipoAcceso=='1' ||this.strTipoAcceso=='2'?'':this.strAgente,
      "strBusqueda": this.strBusqueda,
      "strGerente":this.strTipoAcceso=='3' ||this.strTipoAcceso=='7'?this.strUsuario:'',
      "strSucursal":this.strTipoAcceso=='4'?this.strSucursal:''
    }
    this._peticionesService.getApiRetoTork(objParams).subscribe( result => {
      console.log(result);
      this.arrayClientes=result;
      this.mostrarCargando=false;
    });
  }

  fnBuscarGiros(){
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getGiros'
    }).subscribe( result => {
      this.arrayGiros=result;
    });
  }

  fnTraerDespachadoresTork(){
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getDespachadoresTork'
    }).subscribe( result => {
      this.arrayListadoDespachadores=result;
      console.log(this.arrayListadoDespachadores);
    });
  } 

  fnCambioDespachador(elem: any, tipo: string){
    var arrayProdBusq=[];
    if(tipo=='clave')  {
      arrayProdBusq = this.arrayListadoDespachadores.filter(prod => prod.strClaveDespachador==elem.strClaveDespachador);
    }else if(tipo=='nombre'){
      arrayProdBusq = this.arrayListadoDespachadores.filter(prod => prod.strNombreDespachador==elem.strNombreDespachador);
    }
    //Cambiar los campos de los input por el de la busqueda:
    elem.strClaveDespachador=arrayProdBusq[0].strClaveDespachador;
    elem.strNombreDespachador=arrayProdBusq[0].strNombreDespachador;
    elem.intCantidadDespachador=1;
    elem.strUnidadDespachador='PZ'//arrayProdBusq[0].strUnidadDespachador;

    //Valida que no exista esa Clave de Despachado en el "arrayDespachadores"
    let validaExisteDespachador=this.arrayDespachadores.filter(desp => desp.strClaveDespachador==elem.strClaveDespachador);
    //let validaExisteDespachador=this.arrayDespachadores.filter(desp => desp.strClaveDespachador==arrayProdBusq[0].strClaveDespachador);
    //console.log(validaExisteDespachador);

    if(validaExisteDespachador.length>1){//No puede agregar ese Despachador otra vez
      elem.strClaveDespachador="";
      elem.strNombreDespachador="";
      elem.strClaveConsumible="";
      elem.strNombreConsumible="";
      Swal.fire({
        icon: 'error',
        title: 'Lo sentimos',
        text: 'No puede volver a agregar ese despachador',
      }); 
      return;
    }
  }

  fnValidarCantNum(obj:any, tipo: string){
    console.log(obj);
    var valoresAceptados = /^[0-9]+$/;
    let cant: string="";

    if(tipo=='Despachador'){
      cant=obj.intCantidadDespachador;
      //Valida que sea numero la cantidad de Despachadores
      if (!cant.match(valoresAceptados)){
        Swal.fire({ icon: 'warning', title: 'Corregir', text: 'No es un valor númerico', });    
        obj.intCantidadDespachador=1;
      }
    }else{
      cant=obj.intCantidadConsumible; 
      //Valida que sea numero la cantidad de Consumibles
      if (!cant.match(valoresAceptados)){
        Swal.fire({ icon: 'warning', title: 'Corregir', text: 'No es un valor númerico', });    
        obj.intCantidadConsumible=1;
      }
    }
  }

  fnGetCategorias(){
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getCategorias'
    }).subscribe( result => {
      this.arrayCategoria=result;
      //console.log(this.arrayCategoria);
    });
  }

  fnCambioCategoria(elem:any){
    elem.strClaveDespachador="";
    elem.strNombreDespachador="";
    elem.strUnidadDespachador="";
    elem.intCantidadDespachador=1;

    elem.strClaveConsumible="";
    elem.strNombreConsumible="";
    elem.strUnidadConsumible="";
    elem.intCantidadConsumible=1;
    elem.dblPrecioConsumible=0;
    elem.dblCostoPromedio=0;
    this.fnSumarTotalReto();
  }


  fnTraerConsumiblesTork(){
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getConsumiblesTork'
    }).subscribe( result => {
      this.arrayListadoConsumibles=result;
      console.log(this.arrayListadoConsumibles);
    });
  }

  fnCambioConsumible(elem: any, tipo:string){ 
    var arrayProdBusq=[];
    if(tipo=='clave')  {
      arrayProdBusq = this.arrayListadoConsumibles.filter(prod => prod.strClaveConsumible==elem.strClaveConsumible);
    }else if(tipo=='nombre'){
      arrayProdBusq = this.arrayListadoConsumibles.filter(prod => prod.strNombreConsumible==elem.strNombreConsumible);
    }

    //console.log(arrayProdBusq);
    //Cambiar los campos de los input por el de la busqueda:
    elem.strClaveConsumible=arrayProdBusq[0].strClaveConsumible;
    elem.strNombreConsumible=arrayProdBusq[0].strNombreConsumible;
    elem.intCantidadConsumible=elem.intCantidadConsumible>0?elem.intCantidadConsumible:1;
    elem.strUnidadConsumible=arrayProdBusq[0].strUnidadConsumible;//'PZ';//
    elem.dblPrecioConsumible=arrayProdBusq[0].dblPrecioConsumible;
    elem.dblCostoPromedio=arrayProdBusq[0].dblCostoPromedio;
    //Sumar total al Reto
    this.fnSumarTotalReto();
  }
    
  fnSumarTotalReto=function(){//TotalReto
    this.dblTotalReto=0;
    this.arrayDespachadores.forEach(element => {
        this.dblTotalReto=this.dblTotalReto+(element.dblCostoPromedio*element.intCantidadConsumible);
    });
  }

  fnAgregar(tabla: string){
    if(tabla=='Actual'){
      this.arrayActual.push({
        strCategoria: "",
        strMarca: "",        
        strClaveDespachador: "",
        strNombreDespachador: "",
        intCantidadDespachador: 1,
        strUnidadDespachador: "PZ",
        strClaveConsumible: "",
        strNombreConsumible: "",
        intCantidadConsumible: 1,
        strUnidadConsumible: "PZ",
        dblPrecioConsumible: 0
      });
    }
    else if(tabla=='Entregado'){
      this.arrayDespachadores.push({
        strCategoria: "",
        strClaveDespachador: "",
        strNombreDespachador: "",
        intCantidadDespachador: 1,
        strUnidadDespachador: "",
        strClaveConsumible: "",
        strNombreConsumible: "",
        intCantidadConsumible: 1,
        strUnidadConsumible: "",
        dblPrecioConsumible: 0,
        dblCostoPromedio: 0,
      });
    }
  }

  fnEliminar(tabla: string, elemento:any){
    if(tabla=='Actual'){
      let indice= this.arrayActual.indexOf(elemento); //Obtener el indice del elemento a borrar
      this.arrayActual.splice(indice,1); //Eliminar el elemento en la posicion seleccionada
    }else if(tabla=='Entregado'){     
      let indice= this.arrayDespachadores.indexOf(elemento); //Obtener el indice del elemento a borrar
      this.arrayDespachadores.splice(indice,1); //Eliminar el elemento en la posicion seleccionada
      this.fnSumarTotalReto();
    }
  }

  fnLimpiarCampos(){
    this.strCliente="";
    this.strGiro="";
    this.strDireccion="";
    this.strTelefono="";
    this.strContacto="";
    this.strPuesto="";
    this.strNombre="";
    this.intIDSucursal=0;
    this.strNombreSucursal="";
  }
  
  fnSeleccionoCliente(cte:any){
    //console.log(cte);
    this.arrayClientes=[];
    let cliente:any=cte;
    cliente.fondo="seleccionado";

    let direccion=cliente.strDireccion?cliente.strDireccion:'';
    let direccionNumero=cliente.strDireccionNumero?cliente.strDireccionNumero:'';
    let colonia=cliente.strColonia?', '+cliente.strColonia:'';
    let cp=cliente.strCodigoPostal?', '+cliente.strCodigoPostal:'';
    let estado=cliente.strEstado?', '+cliente.strEstado:'';
    let direccionCompuesta=direccion+' '+direccionNumero+colonia+cp+estado;
    
    this.strCliente=cliente.strCliente?cliente.strCliente:'';
    this.strGiro=cliente.strGiro?cliente.strGiro:'';
    this.strDireccion=direccionCompuesta;
    this.strTelefono=cliente.strTelefono?cliente.strTelefono:'';
    this.strContacto=cliente.strContacto1?cliente.strContacto1:'';
    this.strPuesto=cliente.strPuesto?cliente.strPuesto:'';
    this.strNombre=cliente.strNombre?cliente.strNombre:'';
    this.intIDSucursal=cliente.intIDSucursal?cliente.intIDSucursal:0;
    this.strNombreSucursal=cliente.strNombreSucursal?cliente.strNombreSucursal:'';
    this.dblPotencialVenta=cliente.dblPotencialVenta?cliente.dblPotencialVenta:0;

    //Mensaje
    Swal.fire({
      icon: 'success',
      title: 'Correcto',
      text: this.strTipo+' seleccionado correctamente',
    });    
  }


  fnGuardarReto(acc: string){
    let accion=acc;
    let puesto=this.strPuesto!='Otro' && this.strPuesto!=''?this.strPuesto:this.strOtroPuesto;
    
    if(this.strFechaInicial==undefined || this.strFechaInicial=='' || this.strFechaInicial==null && 
       this.strFechaFinal==undefined || this.strFechaFinal=='' || this.strFechaFinal==null ){
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar una Fecha de Inicio y Fin validas',});    
      return;
    }

    //Validar que la fecha inicio se menos que la fecha fin y que haya minimo 4 dias de diferencia entre ambas 
    var date_1:any = new Date(this.strFechaInicial);
    var date_2:any = new Date(this.strFechaFinal);
    var day_as_milliseconds = 86400000;
    var diff_in_millisenconds:any = date_2 - date_1;
    var diff_in_days = diff_in_millisenconds / day_as_milliseconds;
    //console.log("dias de diferencia entre fechas:" + diff_in_days);
    if((this.strFechaInicial>=this.strFechaFinal) || (diff_in_days<4)){
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'La Fecha de Inicio debe por lo menos 4 días menor que la Fecha Fin',});    
      return;
    }

    if(this.strCliente==undefined || this.strCliente=='' || this.strCliente==null ){
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar un '+this.strTipo,});    
      return;
    }
    if(this.strGiro==undefined || this.strGiro=='' || this.strGiro==null){
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar un Giro',});    
      return;
    }
    if(this.strDireccion==undefined || this.strDireccion=='' || this.strDireccion==null){
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar una Dirección',});    
      return;
    }
    if(this.strTelefono==undefined || this.strTelefono=='' || this.strTelefono==null){
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar un Teléfono',});    
      return;
    }
    if(this.strContacto==undefined || this.strContacto=='' || this.strContacto==null){
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar un Contacto',});    
      return;
    }
    if(puesto==undefined || puesto=='' || puesto==null){
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar un Puesto',});    
      return;
    }
    
    if(this.arrayActual.length>0){
      var arrayActual=[];
      this.arrayActual.forEach(element => {
        if(element.strNombreDespachador!='' || element.strClaveDespachador!=''){
          arrayActual.push(element);
        }
      });
    }

    if(this.arrayDespachadores.length>0){
      var arrayDespachadores=[];
      var arrayConsumibles=[];
      this.arrayDespachadores.forEach(element => {
        if(element.strCategoria!='' && 
            element.strClaveDespachador!='' && element.intCantidadDespachador>0 && element.strUnidadDespachador!='' &&
            element.strClaveConsumible!='' && element.intCantidadConsumible>0 && element.strUnidadConsumible!='' && element.dblPrecioConsumible>0){
          //arrayDespachadores.push(element);
          arrayDespachadores.push({
            strCategoria: element.strCategoria,
            strClaveDespachador: element.strClaveDespachador,
            strNombreDespachador: element.strNombreDespachador,
            intCantidadDespachador: element.intCantidadDespachador,
            strUnidadDespachador:element.strUnidadDespachador,// 'PZ',//
            dblCostoPromedio: element.dblCostoPromedio
          });

          arrayConsumibles.push({            
            strClaveConsumible: element.strClaveConsumible,
            strNombreConsumible: element.strNombreConsumible,
            intCantidadConsumible: element.intCantidadConsumible,
            strUnidadConsumible: element.strUnidadConsumible, //'PZ',//
            dblPrecioConsumible: element.dblPrecioConsumible,
            strClaveDespachador: element.strClaveDespachador
          })
        }
      });

      if(arrayDespachadores.length==0 || arrayDespachadores==undefined || arrayDespachadores==null || arrayDespachadores.length==0){
        //Mensaje
        Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar mínimo un Despachador y su Consumible',});    
        return;
      }
    }else{
      Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Debe agregar mínimo un Despachador y su Consumible',});    
      return;
    }

    if(accion=='guardado'){
      this.strAccion='setInsertarRetoTork';
      this.strEstatus='PENDIENTE';
    }else if(accion=='editado'){
      this.strAccion='setEditarRetoTork';
      this.strEstatus='PENDIENTE';
    }

    let objGuardar={
      "strAccion": this.strAccion,
      "strEstatus": this.strEstatus,
      "intIDReto": this.intIDReto,
      "strAgente": this.strAgente,
      "strTipo": this.strTipo,
      "strComentarios": this.strComentarios,
      "strCliente": this.strCliente,
      "strFechaInicial": this.strFechaInicial,
      "strFechaFinal": this.strFechaFinal,
      "strGiro": this.strGiro,
      "strDireccion": this.strDireccion,
      "strTelefono": this.strTelefono,
      "strContacto": this.strContacto,
      "strPuesto": this.strPuesto!='Otro' && this.strPuesto!=''?this.strPuesto:this.strOtroPuesto,
      "strNombreVendedor": this.strNombreVendedor,
      "intIDSucursal": this.intIDSucursal,
      "strNombreSucursal": this.strNombreSucursal,
      "xmlBitacoraActual": arrayActual,
      "xmlBitacoraTork": arrayDespachadores,
      "xmlBitacoraEntregados": arrayConsumibles,
      "strTipoReto": this.strTipoReto
    }
    console.log(objGuardar);

    this._peticionesService.getApiRetoTork(objGuardar).subscribe( result => {
      console.log(result);
      this.arrayRespuesta=result;
      //Mensaje
      Swal.fire({
        icon: 'success',
        title: 'Correcto',
        text: 'Reto '+accion+' correctamente',
      }); 
      
      this.router.navigate(['/retoTork']);
    });
  
  }


  fnGuardarRechazo(){
    this.spOcultarBoton=true;
    this.spCargando=true;
    
    Swal.fire({
      title: 'Escriba el motivo de rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Rechazar',
      showLoaderOnConfirm: true,
      preConfirm: (mensaje) => {
        return mensaje       
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      this.strMotivoRechazoReto=result.value;
      if(result.isDismissed==true){
        Swal.fire({icon: 'info', title: 'Cancelado', text: 'Se ha cancelado'}); 
        this.spOcultarBoton=false;
        this.spCargando=false;    
        return;
      }
      if(this.strMotivoRechazoReto=='' || this.strMotivoRechazoReto==undefined){
        Swal.fire({icon: 'error', title: 'Lo sentimos', text: 'Agregue un motivo de rechazo'}); 
        this.spOcultarBoton=false;
        this.spCargando=false;        
        return;
      }else{
        this.fnGuardarAutorizarRechazar('RECHAZADO');
      }
    });
  }

  fnGuardarAutorizarRechazar(accion:string){    
    this.spOcultarBoton=true; //genera la salida de inventario
    this.spCargando=true; 

    this._peticionesService.getApiRetoTork({
      "strAccion": "setEstatusReto",
      "strEstatus": accion,
      "strMotivoRechazoReto": this.strMotivoRechazoReto,
      "intIDReto": this.intIDReto
    }).subscribe( result => {
      console.log(result);
      console.log("hollllla");
      this.arrayRespuesta=result;
      let mensaje =this.arrayRespuesta[0]?this.arrayRespuesta[0].strAccion:'Error al intentar afectar solicitud'
      let cliente = this.arrayRespuesta[0]?this.arrayRespuesta[0].strCliente:''
      this.spCargando=false;

      //Mensaje
      Swal.fire({
        icon:  mensaje.includes('Error')? 'error': 'success',
        title: mensaje.includes('Error')?'Error':'Correcto',
        text: mensaje + ' Cliente:' + this.strCliente,
      });  
      
    //  this.router.navigate(['/retoTork']);  
    },
    error => {
         error.message;
        console.log('There was an error!', error);
        this.fnGuardarAutorizarRechazar_2(accion);
    });
  }
  fnGuardarAutorizarRechazar_2(accion:string){    
    this.spOcultarBoton=true; //genera la salida de inventario
    this.spCargando=true; 

    this._peticionesService.getApiRetoTork({
      "strAccion": "setEstatusReto",
      "strEstatus": accion,
      "strMotivoRechazoReto": this.strMotivoRechazoReto,
      "intIDReto": this.intIDReto
    }).subscribe( result => {
      console.log(result);
      console.log("hollllla");
      this.arrayRespuesta=result;
      let mensaje =this.arrayRespuesta[0]?this.arrayRespuesta[0].strAccion:'Error al intentar afectar solicitud'
      let cliente = this.arrayRespuesta[0]?this.arrayRespuesta[0].strCliente:''
      this.spCargando=false;

      //Mensaje
      Swal.fire({
        icon:  mensaje.includes('Error')? 'error': 'success',
        title: mensaje.includes('Error')?'Error':'Correcto',
        text: mensaje + ' Cliente:' + this.strCliente,
      });  
      
    //  this.router.navigate(['/retoTork']);  
    },
    error => {
         error.message;
        console.log('There was an error!', error);
        this.fnGuardarAutorizarRechazar_3(accion);
    });
  }
  fnGuardarAutorizarRechazar_3(accion:string){    
    this.spOcultarBoton=true; //genera la salida de inventario
    this.spCargando=true; 

    this._peticionesService.getApiRetoTork({
      "strAccion": "setEstatusReto",
      "strEstatus": accion,
      "strMotivoRechazoReto": this.strMotivoRechazoReto,
      "intIDReto": this.intIDReto
    }).subscribe( result => {
      console.log(result);
      console.log("hollllla");
      this.arrayRespuesta=result;
      let mensaje =this.arrayRespuesta[0]?this.arrayRespuesta[0].strAccion:'Error al intentar afectar solicitud'
      let cliente = this.arrayRespuesta[0]?this.arrayRespuesta[0].strCliente:''
      this.spCargando=false;

      //Mensaje
      Swal.fire({
        icon:  mensaje.includes('Error')? 'error': 'success',
        title: mensaje.includes('Error')?'Error':'Correcto',
        text: mensaje + ' Cliente:' + this.strCliente,
      });  
      
    //  this.router.navigate(['/retoTork']);  
    },
    error => { 
        console.log('There was an error!', error); 
        Swal.fire({
          icon:   'error' ,
          title:  'Error',
          text: 'Tiempo de espera agotado, intente d enuevo',
        });
    });
  }
  

}
