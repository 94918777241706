<div class="container">
    <div class="row">
        <div class="col-12 text-center mt-3">
            <h1 class="fw-bold">Inventarios <span class="titulo">Tienda Ecodeli</span></h1>
        </div>
    </div>
    <div class="row shadow cardBottom">
        <div class="col-12 titulos d-flex align-items-center cardTop justify-content-between ">
            <mat-form-field class="example-full-width bg-white ms-3 my-2 rounded" appearance="outline">
                <mat-label> <i class="bi bi-funnel  fs-5"></i>   Buscar</mat-label>
                <input matInput placeholder="Ex.id, articulo" [(ngModel)]="textoBusqueda" (input)="aplicarFiltro()" class="text-dark">
                <button mat-icon-button matSuffix (click)="limpiarFiltro()">
                  <mat-icon class="text-dark">close</mat-icon>
                </button>
            </mat-form-field>
            <button class="btn btnAct shadow" (click)="fnActualizarMasivo()">
              <i class="bi bi-arrow-repeat fs-5 text-dark"></i>
            </button>
        </div>
        <div class="col-12 table-responsive px-0 mb-0">
          <div class="col-12">
            <div class="progress-container">
              <div class="progress-bar" [style.width.%]="progress" [hidden]="this.progress==100">
                <span class="fs10 fw-bold text-white">{{this.progress}}%</span> 
              </div>
            </div>  
          
          </div>
            <div class="col-12 d-flex justify-content-center" *ngIf="spinerTab">
                <mat-spinner></mat-spinner>
            </div>
            
            <div class="mat-elevation-z8"  [hidden]="spinerTab">
                <table mat-table [dataSource]="dataSource" class="table table-bordered table-hover">
                  <!-- Position Column -->
                  <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef class="fw-bold text-dark ps-0 fs14 text-center"> SKU </th>
                    <td mat-cell *matCellDef="let element" class="text-center align-middle ps-0 fs14 fw-bold px-2 position-relative" [ngClass]="{'text-danger':!element.existBD}">
                      <i  matTooltip="No existe en Ecodeli Comercial"  class="bi bi-info-circle text-dark position-absolute top-0 start-0 mt-1 ms-1" *ngIf="!element.existBD"></i>
                       {{element.sku}}
                       </td>
                  </ng-container>
                  <!-- Name Column -->
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef class="fw-bold text-dark fs14 text-center"> ID </th>
                    <td mat-cell *matCellDef="let element" class="text-center align-middle fs12 px-2" > {{element.id}} </td>
                  </ng-container>
                  <ng-container matColumnDef="inventoryId">
                    <th mat-header-cell *matHeaderCellDef class="fw-bold text-dark fs14 text-center"> ID INVENTARIO </th>
                    <td mat-cell *matCellDef="let element" class="text-center align-middle fs12 px-2" > {{element.inventoryId}} </td>
                  </ng-container>
                     <!-- Symbol Column -->
                  <ng-container matColumnDef="strDescripcion">
                    <th mat-header-cell *matHeaderCellDef class="fw-bold text-dark pe-0 fs14 text-center"> DESCRIPCIÓN </th>
                    <td mat-cell *matCellDef="let element" class="text-center align-middle pe-0 fs12" > {{splitText(element.strDescripcion)}} </td>
                  </ng-container>

                  <!-- Weight Column -->
                  <ng-container matColumnDef="dblInventario">
                    <th mat-header-cell *matHeaderCellDef class="fw-bold text-dark fs14 text-center pe-0"> INVENTARIO ECODELI</th>
                    <td mat-cell *matCellDef="let element" class="text-center pe-0 align-middle fs12"> <span class="p-1 rounded text-white fw-bold shadow-sm " [ngClass]="{'bg-secondary':!element.existBD,'bg-success':element.existBD}"> {{element.dblInventario}} </span></td>
                  </ng-container>
                  <ng-container matColumnDef="invAnterior">
                    <th mat-header-cell *matHeaderCellDef class="fw-bold text-dark fs14 text-center pe-0"> INVENTARIO SHOPIFY</th>
                    <td mat-cell *matCellDef="let element" class="text-center pe-0 align-middle fs12"> <span class="p-1 rounded text-white fw-bold shadow-sm " [ngClass]="{'bg-secondary':!element.existBD,'bgIconAnt':element.existBD}"> {{element.invAnterior}} </span></td>
                  </ng-container>
                  <ng-container matColumnDef="actualizar">
                    <th mat-header-cell *matHeaderCellDef class="fw-bold text-dark fs14 text-center pe-0"> ACTUALIZAR </th>
                    <td mat-cell *matCellDef="let element" class="text-center pe-0 align-middle fs12"> 
                      <button class="btn btnActuTab shadow-sm" [disabled]="!element.existBD" (click)="fnActInvSingular(element)">
                        <i class="bi bi-pencil-square text-white"></i>
                      </button> 
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sticky-top text-dark topTableBg "></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              
              
              </div>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 75, 100]" showFirstLastButtons aria-label="Select page of periodic elements" class="cardBottom topTableBg" >
        </mat-paginator>
     
    </div>
</div>