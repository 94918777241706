<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="my-3 text-center fw-bold nunito"> Reporte venta por monto</h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott align-items-center pb-2 mb-4">
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md mt-2 pt-1   d-flex justify-content-center align-items-center" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="getArtsPromocion()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>




    <div class="row">
        <div class="col-12">
            <div *ngIf="spinerArts" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="alert alert-danger text-center fw-bold my-3" *ngIf=" !spinerArts && arrayExistentes.length==0" role="alert">
                No se han encontrado datos de pedidos
              </div>
              <div  [hidden]="arrayExistentes.length==0">
                <div class="col-12 d-flex px-2 bg-white justify-content-between align-items-center">
                    <mat-form-field appearance="outline" class="bg-white rounded my-2">
                        <mat-label>Buscar:</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                      </mat-form-field>
                      <span > 
                      
                          <button class="btn btn-outline-success shadow-sm px-2 py-1 me-3" *ngIf=" arrayExistentes.length>0" (click)="exportToExcel_hist1()" (mouseenter)="isHoveredButton1 = true" (mouseleave)="isHoveredButton1 = false">
                            <i class="fa-regular fa-file-excel pe-2"></i>
                           <span class="fs12 fw-bold">Descargar</span> 
                          </button>
                        
                       <span class="fw-bold">Total Sucursales:</span>  {{arrayExistentes.length}}
                      </span>

                  </div>
              </div>
              
            <div class="table-responsive mb-0" [hidden]="arrayExistentes.length==0">
                  <div class="mat-elevation-z8">
                    <table  class="table w-100 table-bordered border-top-0" mat-table [dataSource]="dataSource" matSort>
                       
                        <ng-container matColumnDef="strCliente">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text ps-0 sticky-top roundTopLeft border-0"> Cliente </th>
                            <td mat-cell *matCellDef="let row" matTooltip="{{row.strClienteNombre}}" class="text-center align-middle fs-txt-table fs12 nunito px-2 ">
                                <span class="d-flex justify-content-center">
                                    <span class="fw-bold" *ngIf="row.strCliente && row.strCliente!= ''">
                                        ({{row.strCliente}})
                                    </span> 
                                    <span class="text-start descripcion-truncada">
                                        {{row.strClienteNombre}}
                                    </span>
                                </span> 
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">Total</td>
                          </ng-container>

                          <ng-container matColumnDef="strClienteNombreCorto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text ps-0 sticky-top"> NombreCorto </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table fs12 nunito fw-bold ps-0 ">{{row.strClienteNombreCorto}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold"></td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strNombreSucursalCte">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Sucursal </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle nunito fs-txt-table txtDescripcion"><span *ngIf="row.strIDSucursalCte && row.strIDSucursalCte!= ''">({{row.strIDSucursalCte}}){{row.strNombreSucursalCte}}</span> </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblEnero">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Enero </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-light':row.dblEnero==0,'text-primary fw-bold':row.dblEnero>0}">{{row.dblEnero|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                            <span class="px-2">{{ TotalEnero|number:'1.2-2' }}</span>  
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblFebrero">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Febrero </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-secondary':row.dblFebrero==0,'text-primary fw-bold':row.dblFebrero>0}">{{row.dblFebrero|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalFebrero|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblMarzo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Marzo </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblMarzo==0,'text-primary fw-bold':row.dblMarzo>0}">{{row.dblMarzo|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalMarzo|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblAbril">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Abril </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblAbril==0,'text-primary fw-bold':row.dblAbril>0}">{{row.dblAbril|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalAbril|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblMayo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Mayo </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblMayo==0,'text-primary fw-bold':row.dblMayo>0}">{{row.dblMayo|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalMayo|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblJunio">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Junio </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblJunio==0,'text-primary fw-bold':row.dblJunio>0}">{{row.dblJunio|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalJunio|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblJulio">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Julio </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-secondary':row.dblJulio==0,'text-primary fw-bold':row.dblJulio>0}" >{{row.dblJulio|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalJulio|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblAgosto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Agosto </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-secondary':row.dblAgosto==0,'text-primary fw-bold':row.dblAgosto>0}" >{{row.dblAgosto|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalAgosto|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblSeptiembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Septiembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblSeptiembre==0,'text-primary fw-bold':row.dblSeptiembre>0}">{{row.dblSeptiembre|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalSeptiembre|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblOctubre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Octubre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table" [ngClass]="{'text-secondary':row.dblOctubre==0,'text-primary fw-bold':row.dblOctubre>0}">{{row.dblOctubre|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalOctubre|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblNoviembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Noviembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblNoviembre==0,'text-primary fw-bold':row.dblNoviembre>0}">{{row.dblNoviembre|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalNoviembre|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblDiciembre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> Diciembre </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table"  [ngClass]="{'text-secondary':row.dblDiciembre==0,'text-primary fw-bold':row.dblDiciembre>0}">{{row.dblDiciembre|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold">
                                <span class="px-2">{{ TotalDiciembre|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="totales">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top roundTopRigth border-0 pe-0"> TOTAL </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table pe-0"  [ngClass]="{'text-secondary':row.total==0,'text-dark fw-bold':row.total>0}">{{row.total|number:'1.2-2'}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fw-bold pe-0">
                                <span class="px-2">{{ getTotalGeneral()|number:'1.2-2' }}</span>
                            </td>
                          </ng-container>
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                  
                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                      </tr>
                    </table>
                  </div>
            </div>
            <div class="col-12" [hidden]="arrayExistentes.length==0">
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>
<table id="tableHist_1" class="table table-bordered table-hover d-none">
    <!-- Cabecera -->
    <thead class="sticky-top bg-light">
      <tr>
        <th scope="col" class="text-center">Cliente</th>
        <th scope="col" class="text-center">Nombre</th>
        <th scope="col" class="text-center">Nombre Corto</th>
        <th scope="col" class="text-center">Sucursal</th>
        <th scope="col" class="text-center">Enero</th>
        <th scope="col" class="text-center">Febrero</th>
        <th scope="col" class="text-center">Marzo</th>
        <th scope="col" class="text-center">Abril</th>
        <th scope="col" class="text-center">Mayo</th>
        <th scope="col" class="text-center">Junio</th>
        <th scope="col" class="text-center">Julio</th>
        <th scope="col" class="text-center">Agosto</th>
        <th scope="col" class="text-center">Septiembre</th>
        <th scope="col" class="text-center">Octubre</th>
        <th scope="col" class="text-center">Noviembre</th>
        <th scope="col" class="text-center">Diciembre</th>
        <th scope="col" class="text-center">Total</th>
      </tr>
    </thead>
  
    <!-- Cuerpo -->
    <tbody>
      <tr *ngFor="let row of arrayExistentes">
        <td class="text-center align-middle">{{ row.strCliente }}</td>
        <td class="text-center align-middle">{{ row.strClienteNombre }}</td>
        <td class="text-center align-middle">{{ row.strClienteNombreCorto }}</td>
        <td class="text-center align-middle">{{ row.strNombreSucursalCte }}</td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblEnero == 0, 'text-primary fw-bold': row.dblEnero > 0}">
          {{ row.dblEnero }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblFebrero == 0, 'text-primary fw-bold': row.dblFebrero > 0}">
          {{ row.dblFebrero }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblMarzo == 0, 'text-primary fw-bold': row.dblMarzo > 0}">
          {{ row.dblMarzo }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblAbril == 0, 'text-primary fw-bold': row.dblAbril > 0}">
          {{ row.dblAbril }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblMayo == 0, 'text-primary fw-bold': row.dblMayo > 0}">
          {{ row.dblMayo }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblJunio == 0, 'text-primary fw-bold': row.dblJunio > 0}">
          {{ row.dblJunio }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblJulio == 0, 'text-primary fw-bold': row.dblJulio > 0}">
          {{ row.dblJulio }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblAgosto == 0, 'text-primary fw-bold': row.dblAgosto > 0}">
          {{ row.dblAgosto }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblSeptiembre == 0, 'text-primary fw-bold': row.dblSeptiembre > 0}">
          {{ row.dblSeptiembre }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblOctubre == 0, 'text-primary fw-bold': row.dblOctubre > 0}">
          {{ row.dblOctubre }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblNoviembre == 0, 'text-primary fw-bold': row.dblNoviembre > 0}">
          {{ row.dblNoviembre }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.dblDiciembre == 0, 'text-primary fw-bold': row.dblDiciembre > 0}">
          {{ row.dblDiciembre }}
        </td>
        <td class="text-center align-middle" [ngClass]="{'text-secondary': row.total == 0, 'text-dark fw-bold': row.total > 0}">
          {{ row.total }}
        </td>
      </tr>
    </tbody>
  
    <!-- Pie -->
    <tfoot class="bg-light">
      <tr>
        <th class="text-center align-middle">Total</th>
        <th></th>
        <th></th>
        <th></th>
        <th class="text-center align-middle">{{ TotalEnero }}</th>
        <th class="text-center align-middle">{{ TotalFebrero }}</th>
        <th class="text-center align-middle">{{ TotalMarzo }}</th>
        <th class="text-center align-middle">{{ TotalAbril }}</th>
        <th class="text-center align-middle">{{ TotalMayo }}</th>
        <th class="text-center align-middle">{{ TotalJunio }}</th>
        <th class="text-center align-middle">{{ TotalJulio }}</th>
        <th class="text-center align-middle">{{ TotalAgosto }}</th>
        <th class="text-center align-middle">{{ TotalSeptiembre }}</th>
        <th class="text-center align-middle">{{ TotalOctubre }}</th>
        <th class="text-center align-middle">{{ TotalNoviembre }}</th>
        <th class="text-center align-middle">{{ TotalDiciembre }}</th>
        <th class="text-center align-middle">{{ getTotalGeneral() }}</th>
      </tr>
    </tfoot>
  </table>