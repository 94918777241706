<div [ngClass]="expand_screen ? 'table_full':''">
  
  

  <!-- ******************   OPCIONES EXTRAS   ****************** -->
  <div class="d-flex align-items-center w-100 gap-2" [ngClass]="((export[0] !== false || top_template) && !(filter || screen_expand)) ? 'justify-content-end' : export[0] === true || top_template? 'pb-2 justify-content-between' : (filter || screen_expand) ? 'pb-2':''"

    *ngIf="filter || screen_expand || export[0] === true || top_template">

    <div style="overflow: hidden;" class="d-flex align-items-center gap-2" *ngIf="filter || screen_expand" [ngStyle]="screen.isXSmall || screen.isSmall ? {flexGrow: '1'}:{}" [ngClass]="!filter && export[0] === true ? 'pr-2': filter && screen_expand && export[0] === false ? 'w-100':''">
      
      <!-- ******************   FILTRO   ****************** -->
      <mat-form-field style="color: #002f87;"
        [ngStyle]="(screen.isXSmall || screen.isSmall) && export[0] === true && screen_expand ? {maxWidth:'75.9%'} : (screen.isXSmall || screen.isSmall) && export[0] === true ? {width:'100%'} : (screen.isXSmall || screen.isSmall) && export[0] === false ? {minWidth:'80%'}:(screen.isXSmall || screen.isSmall) && !filter && export[0] === false ? {minWidth:'100%'}:null" *ngIf="filter ? filter:false"
        class="flex-grow-0 pb-0 small-mat-form" appearance="outline">
        <mat-label>Filtrar</mat-label>

        <!-- Icono al inicio del campo de entrada -->
        <mat-icon matPrefix>search</mat-icon>

        <!-- Icono al inicio del campo de entrada -->
        <button mat-button *ngIf="value_filter" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>

        <input matInput [(ngModel)]="value_filter" (keyup)="applyFilter()"
          placeholder="Escriba la búsqueda">
      </mat-form-field>

      <!-- ******************   BOTTON PANTALLA COMPLETA   ****************** -->
      <button (click)="expand_table()" class="d-flex justify-content-center align-items-center" mat-stroked-button
        *ngIf="screen_expand ? screen_expand:false"
        style="min-width: 45px !important;line-height: 40px !important;padding: 0px 10px;color:#0084ff;border-color: #0084ff">
        <mat-icon class="puntero">{{expand_screen ? 'fullscreen_exit':'fullscreen'}}</mat-icon>
      </button>

    </div>

    <!-- ******************   BOTTON EXPORTAR EXCEL   ****************** -->
    <div *ngIf="export ? export[0]:false" [ngClass]="!(filter) && (screen.isXSmall || screen.isSmall) ? 'w-100 p-1':''">
      <button style="color: #28a745;background: none;" mat-stroked-button [ngClass]="!(filter) && (screen.isXSmall || screen.isSmall) ? 'w-100':''"
        class="shadow-none d-flex align-items-center justify-content-center"
        [ngStyle]="(screen.isXSmall || screen.isSmall) && !(filter || screen_expand) || (filter || screen_expand) && !(screen.isXSmall || screen.isSmall) ? {'outline':'1px solid #28a745','padding':'2px 8px','borderRadius':'0.25rem', 'border':'none'}:{'borderColor':'#28a745',minWidth: '45px','padding':'0px 8px', 'lineHeight':'40px'}"
        (click)="exportToExcel()">
        <!-- <mat-icon class="mb-1">assignment_returned</mat-icon> -->
        <svg viewBox="0 0 512 512" width="25" height="25" xmlns="http://www.w3.org/2000/svg">
          <path fill="none"
            d="M291 513H1V1.1h511.8V513H291M140.5 317h-25.3l-.2 3.2v112a58.7 58.7 0 0 0 59.6 54.8c83.9.2 167.7 0 251.5 0 12.7 0 24.7-2.1 35.5-9.2 17-11.1 27-26.8 27.2-47 .5-79.4.2-158.9.2-238.3V187h-6.2c-32.9 0-65.7.2-98.5 0a53 53 0 0 1-34.7-14.1 59.5 59.5 0 0 1-20.6-47.6V27H172.2a57.5 57.5 0 0 0-46 24.4A56.8 56.8 0 0 0 115 87h6c22.7 0 45.6.5 68.4-.3a69.8 69.8 0 0 1 32.2 8.2c9.5 5.7 11.7 16.4 11.8 25.2 1 53 .3 106 .7 159 0 7.1-3.2 13-4.2 19.8-.3 1.7-.8 3.5-1.7 5-6 9.3-16.5 12-25.8 12.7-20.3 1.4-40.7.4-62 .4M26 157.5v115c0 13 10.3 23.5 23.3 23.5h140.4c13 0 23.3-10.5 23.3-23.5v-140c0-13-10.3-23.5-23.3-23.5H49.3c-13 0-23.2 10.5-23.3 23.5v25m433-23-98.2-98.2-.7 2c0 30.6-.5 61.3 0 91.9.3 14.5 11.8 26 27.2 25.9 29-.2 58-.1 87-.1h6.3L459 134.5z" />
          <path fill="currentColor"
            d="M141 317c20.8 0 41.2 1 61.5-.4 9.3-.7 19.8-3.4 25.8-12.8 1-1.4 1.4-3.2 1.7-5 1-6.6 4.3-12.6 4.2-19.8-.4-53 .3-106-.7-159-.1-8.7-2.3-19.4-11.8-25.1a69.8 69.8 0 0 0-32.2-8.2c-22.8.8-45.7.3-68.5.3h-6c-.3-13.4 3.6-25 11.1-35.6a57.5 57.5 0 0 1 46-24.3l152.5-.1h4.4v98.3c-.2 19 6.2 35.1 20.6 47.6a53 53 0 0 0 34.7 14c32.8.3 65.6.1 98.5.1h6.2v5.5c0 79.4.3 159-.2 238.4a54.8 54.8 0 0 1-27.2 46.9 62.7 62.7 0 0 1-35.5 9.2c-83.8 0-167.6.2-251.4 0A58.7 58.7 0 0 1 115 432V317H141m168-59.7c-1.9-1.4-3.8-3-5.9-4-.7-.4-2 .6-3 1.1-.8.4-1.3 1.4-2.1 1.5-6 1.5-10.2 6.8-10.1 13.2.2 39.7.1 79.3 0 119 0 2.5.9 5.5-3.4 5.7-.3-.8-.4-2-1-2.6-7.5-7-15-13.7-22.3-20.7-5.2-5-10-10.4-15.4-15-5-4.3-11.3-3.4-16.3-1-4.7 2.3-7 7.6-8.5 12.8-1.1 4.1 2.5 5.7 4.5 7.8a985 985 0 0 0 22.8 23.6c14.1 14.2 28 28.5 42.4 42.4 8.6 8.3 13.6 8.2 20.4 2.1 10.6-9.6 20.6-20 30.8-30.1 11.3-11 22.6-22.1 33.6-33.4 3-3 6.4-6.8 7.2-10.7 1.5-7.5-5.8-15.1-13.8-16a16 16 0 0 0-13.6 4.6c-3.5 3.4-6.4 7.6-9.8 11-3.3 3.3-7.3 5.8-10.5 9-5.3 5.3-10.2 11-15.2 16.5l-1.4-.6c-.1-1.9-.4-3.7-.4-5.6 0-38.2.1-76.3-.1-114.5 0-6.2-2.2-12.1-8.8-16.1z" />
          <path fill="#006b19"
            d="M26 157v-24.5c0-13 10.3-23.5 23.3-23.5h140.4c13 0 23.3 10.4 23.3 23.5v140c0 13-10.3 23.5-23.3 23.5H49.3c-13 0-23.3-10.4-23.3-23.5V157m125.5 80L129 202.8l32.9-51.5h-19l-23.4 40L96.1 151H77.4c.5 1.2.9 2.3 1.5 3.2l12 18.4c5.5 8.3 10.7 16.8 16.3 25 2.5 3.6 2.7 6.6-.2 9.9-.9 1-1.6 2-2.3 3.1l-17.6 27L77 254h17.5c.8 0 2-.5 2.3-1.2l14.3-25.3c2.5-4.3 5.2-8.4 8.4-13.5l23.2 39.8h19L151.4 237zM459.3 134.8l21.3 21.2h-93.3a26.2 26.2 0 0 1-27.1-25.8c-.6-30.6-.2-61.3-.1-92l.7-1.9 98.4 98.4z" />
          <path fill="none"
            d="M309.3 257.6c6.4 3.7 8.5 9.6 8.6 15.8.2 38.2 0 76.3.1 114.5 0 1.9.3 3.7.4 5.6l1.4.6c5-5.5 10-11.2 15.2-16.5 3.2-3.2 7.2-5.7 10.5-9 3.4-3.4 6.3-7.6 9.8-11a16 16 0 0 1 13.6-4.6c8 .9 15.3 8.5 13.8 16-.8 4-4.3 7.6-7.2 10.7-11 11.3-22.3 22.3-33.6 33.4-10.2 10.1-20.2 20.5-30.8 30.1-6.8 6.1-11.8 6.2-20.4-2.1-14.3-14-28.3-28.2-42.4-42.4a985 985 0 0 1-22.8-23.6c-2-2.1-5.6-3.7-4.5-7.8 1.4-5.2 3.8-10.5 8.5-12.8 5-2.4 11.3-3.3 16.3 1 5.4 4.6 10.2 10 15.4 15 7.3 7 14.8 13.8 22.2 20.7.7.6.8 1.8 1.1 2.6 4.3-.2 3.5-3.2 3.5-5.7 0-39.7.1-79.3-.1-119 0-6.4 4.1-11.7 10.1-13.2.8-.1 1.3-1 2-1.5 1-.5 2.4-1.5 3.1-1.2 2.1 1 4 2.7 6.2 4.4zM151.7 237.4l10 16.4h-19l-23-39.8c-3.3 5.1-6 9.2-8.5 13.5L97 252.8c-.4.7-1.5 1.1-2.3 1.1H77c3.6-5.7 6.7-11 10-16.1l17.6-27c.7-1.2 1.4-2.3 2.3-3.2 2.9-3.3 2.7-6.3.2-10-5.6-8.1-10.8-16.6-16.3-24.9l-12-18.4c-.6-.9-1-2-1.5-3.2h18.7l23.4 40 23.3-40h19L129 202.8l22.7 34.7z" />
        </svg>
        <span *ngIf="(screen.isXSmall || screen.isSmall) && !(filter) || !(screen.isXSmall)" class="ml-2">Descargar Excel</span>
      </button>

    </div>

    <!-- ******************   TOP TABLE   ****************** -->
    <div *ngIf="top_template" class="d-flex justify-content-end">
      <ng-container *ngTemplateOutlet="top_template"></ng-container>
    </div>
    
  </div>

  <!-- ******************   TABLA   ****************** -->
    
  <div class="eco_simple_table">
    
    <div class="eco_simple_table_row_header">
      <ng-container *ngFor="let data of data_table; let idx = index">
        <!-- No afecta la multiplicación de elementos -->
        <ng-container *ngFor="let item_table of items_table; let index_table = index">
          <ng-container *ngIf="index_table == 0">
            <div class="eco_simple_table_header" style="padding-right: 15px;" *ngIf="(data.header_rules ? data.header_rules(item_table, index_table) : true)" [ngStyle]="data.style_header ? data.style_header : {}">
              
              <div class="d-flex align-items-center puntero" (click)="sortData((data.value.includes('|') ? data.value.split('|').join('') : data.value === 'undefined' ? data.value : data.value), idx)">
                {{data.title}}
                <mat-icon class="animated-icon" [ngStyle]="idx === column_selected && icon_order != 0 ? {opacity: '1', transform: 'translateY(0)'}:null">
                  {{icon_order === 1 ? 'keyboard_arrow_up': this.icon_order === 2 ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}
                </mat-icon>
              </div>
              <span class="box_resize" (dblclick)="doubleClickResize($event, data)" matTooltip="Arrastra para ajustar el ancho de la columna, también puedes dar doble clic para expandir y contraer" [matTooltipDisabled]="tooltipDisabled" matTooltipPosition="above" (mousedown)="startResize($event, data, idx)"></span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    

    <div class="px-3">
      <span class="d-flex justify-content-center align-items-center my-3 p-2 alert alert-warning" *ngIf="value_filter != '' ? items_table_filtrado.length  === 0 : items_table.length === 0">No hay registros para mostrar en la tabla</span>
    </div>

    <div *ngFor="let item of items_table; let i = index" class="eco_simple_table_row_cell">
      <!-- Por cada objeto en items_table, queremos mostrar cada valor basándonos en data_table -->
      <ng-container *ngFor="let data of data_table; let data_i = index">

        <div class="eco_simple_table_cell gap-2" [attr.data-textTooltip]="data.textTooltip ? data.textTooltip(item, i):''" [ngClass]="data.icons && ((data.value && item[data.value] != '' && item[data.value] != null && item[data.value] != undefined) || data.ngValue && data.ngValue != '' && data.ngValue != null && data.ngValue != undefined) ? data.textTooltip ? 'tooltipText justify-content-between' : 'justify-content-between': data.textTooltip ? 'tooltipText justify-content-center' : 'justify-content-center'" [ngStyle]="data.styles ? data.styles:{}"
          *ngIf="(data.header_rules ?  data.header_rules(item, i) : true)" [attr.data-label]="data.title">
          <!-- <span  style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;" *ngIf="!(screen.isXSmall || screen.isSmall)"></span> -->
        <!-- <div class="eco_simple_table_cell gap-2" [matTooltip]="data.textTooltip ? data.textTooltip(item, i):''" [matTooltipDisabled]="true" matTooltipPosition="after" [ngClass]="data.icons && ((data.value && item[data.value] != '' && item[data.value] != null && item[data.value] != undefined) || data.ngValue && data.ngValue != '' && data.ngValue != null && data.ngValue != undefined) ? 'justify-content-between':'justify-content-center'" [ngStyle]="data.styles ? data.styles:{}"
          *ngIf="(data.header_rules ?  data.header_rules(item, i) : true)"  [attr.data-label]="data.title"> -->

          <!-- ******************   TEXT PREFIX   ****************** -->
          <ng-container *ngIf="data.text_prefix && (data.rules ?  data.rules(item, i) : true)" [class.highlight]="texto_filtrado(item[data.value])">
            {{data.text_prefix}}
          </ng-container>

          <!-- ******************   VALUE EN IMPORTE   ****************** -->
          <ng-container *ngIf="data.format === 'importe' && (data.rules ?  data.rules(item, i) : true)">
            <span *ngIf="data.value" [ngClass]="data.metodo && data.metodo != undefined ? 'link': data.class ? data.class : data.ngClass ? data.ngClass(item, i):''" [class.highlight]="texto_filtrado(item[data.value])" [ngStyle]="data.styles_value ? data.styles_value : data.ngStyles_value ? data.ngStyles_value(item, i):''" (click)="data.metodo ? data.metodo(item, i) : ''">
                {{ item[data.value] | currency:'':'symbol':'1.2-2' }}
              </span>
              <span *ngIf="data.ngValue" [ngClass]="data.metodo && data.metodo != undefined ? 'link': data.class ? data.class : data.ngClass ? data.ngClass(item, i):''" [class.highlight]="texto_filtrado(data.ngValue)" [ngStyle]="data.styles_value ? data.styles_value : data.ngStyles_value ? data.ngStyles_value(item, i):''" (click)="data.metodo ? data.metodo(item, i) : ''">
                  {{ data.ngValue(item, i) | currency:'':'symbol':'1.2-2' }}
                </span>
          </ng-container>

          <!-- ******************   VALUE EN NUMEROS   ****************** -->
          <ng-container *ngIf="data.format === 'numero' && (data.rules ?  data.rules(item, i) : true)">
            <span *ngIf="data.value" [ngClass]="data.metodo && data.metodo != undefined ? 'link': data.class ? data.class : data.ngClass ? data.ngClass(item, i):''" [class.highlight]="texto_filtrado(item[data.value])" [ngStyle]="data.styles_value ? data.styles_value : data.ngStyles_value ? data.ngStyles_value(item, i):''" (click)="data.metodo ? data.metodo(item, i) : ''">
                {{ item[data.value] | currency:'':'':'1.2-2' }}
              </span>
              <span *ngIf="data.ngValue" [ngClass]="data.metodo && data.metodo != undefined ? 'link': data.class ? data.class : data.ngClass ? data.ngClass(item, i):''" [class.highlight]="texto_filtrado(data.ngValue)" [ngStyle]="data.styles_value ? data.styles_value : data.ngStyles_value ? data.ngStyles_value(item, i):''" (click)="data.metodo ? data.metodo(item, i) : ''">
                {{ data.ngValue(item, i) | currency:'':'':'1.2-2' }}
                </span>
          </ng-container>

          <!-- ******************   CHECKBOX   ****************** -->
          <ng-container *ngIf="(data.checkbox === true || data.checkbox === false) && (data.rules ?  data.rules(item, i) : true)">
            <input type="checkbox" value="" [checked]="data.checkbox" (click)="data.metodo ? data.metodo(item, i) : ''">
          </ng-container>

          <!-- ******************   VALUE PRINCIPAL   ****************** -->
          <ng-container *ngIf="data.value && !data.format && (data.rules ?  data.rules(item, i) : true)">
              <span [ngClass]="data.metodo && data.metodo != undefined ? 'link' : data.class ? data.class : data.ngClass ? data.ngClass(item, i):''" [class.highlight]="texto_filtrado((data.value.includes('|') ? item[data.value.split('|').join('')] : item[data.value] === 'undefined' ? data.value : item[data.value]))" [ngStyle]="data.styles_value ? data.styles_value : data.ngStyles_value ? data.ngStyles_value(item, i):''"
              (click)="data.metodo ? data.metodo(item, i) : ''">
                {{(data.value.includes('|') ? item[data.value.split('|').join('')] : item[data.value] === 'undefined' ? data.value : item[data.value])}}</span>
          </ng-container>

          <!-- ******************   VALUE PRINCIPAL   ****************** -->
          <ng-container *ngIf="data.ngValue && !data.format && (data.rules ?  data.rules(item, i) : true)">
              <span [ngClass]="data.metodo && data.metodo != undefined ? 'link' : data.class ? data.class : data.ngClass ? data.ngClass(item, i):''" [class.highlight]="texto_filtrado(data.ngValue)" [ngStyle]="data.styles_value ? data.styles_value : data.ngStyles_value ? data.ngStyles_value(item, i):''"
              (click)="data.metodo ? data.metodo(item, i) : ''">
                {{data.ngValue(item, i)}}</span>
          </ng-container>


          <!-- ******************   ICONOS   ****************** -->
          <ng-container *ngIf="data.icons">
            <ng-container *ngFor="let icon of data.icons">

              <div *ngIf="icon.fontawesome && (icon.rules ?  icon.rules(item, i) : true)">
                  <i [ngClass]="icon.fontawesome" [ngStyle]="icon.styles ? icon.styles : icon.ngStyles ? icon.ngStyles(item, i):''" style="cursor: pointer;font-size: 20px !important;" (click)="icon.metodo ? icon.metodo(item, i) : ''"></i>
              </div>

              <div *ngIf="icon.mat_icon && (icon.rules ?  icon.rules(item, i) : true)">
                  <mat-icon [ngStyle]="icon.styles ? icon.styles : icon.ngStyles ? icon.ngStyles(item, i):''" style="cursor: pointer" (click)="icon.metodo ? icon.metodo(item, i) : ''">{{icon.mat_icon}}</mat-icon>
              </div>
              
              <div *ngIf="(icon.img || icon.ngImg) && (icon.rules ?  icon.rules(item, i) : true)" >
                  <img [ngStyle]="icon.styles ? icon.styles : icon.ngStyles ? icon.ngStyles(item, i):''" [src]="icon.img ? icon.img : icon.ngImg ? icon.ngImg(item, i) : ''" onerror="this.src='assets/img/NO-DISPONIBLE.jpg'" style="cursor: pointer;min-width:25px !important; height: auto !important;" (click)="icon.metodo ? icon.metodo(item, i) : ''"/>
              </div>

            </ng-container>
          </ng-container>

          <!-- ******************   TEXT SUFFIX   ****************** -->
          {{data.text_suffix != '' ? data.text_suffix : ''}}

          <ng-container *ngIf="data.template && (data.rules ?  data.rules(item, i) : true)">
            <div [innerHTML]="data.template(item, i)"></div>
          </ng-container>

        
          <ng-container *ngIf="data.input_value || data.textarea_value || data.input_type_check">
            <input *ngIf="data.input_value && (data.input_value_rules ?  data.input_value_rules(item, i) : true)" #miInput class="inputs_data" [name]="data.input_value" (change)="data.input_metodo ? data.input_metodo(item, i, $event, inputs) : ''" [ngClass]="data.class ? data.class : data.ngClass ? data.ngClass(item, i):''" matInput [type]="data.input_type" [value]="item[data.input_value]" (keypress)="data.input_type == 'number' ? solo_numeros($event):null" [min]="data.input_type == 'number' ? data.input_min ? data.input_min(item, i):0:''" [max]="data.input_type == 'number' && data.input_max ? data.input_max(item, i):'' " [readonly]="data.readonly ? data.readonly(item, i):false" [required]="data.required ? data.required(item, i):false" [ngStyle]="data.styles ? data.styles:{}">
            <input *ngIf="(data.input_type_check == 'radio' || data.input_type_check == 'checkbox') && (data.input_type_check_rules ?  data.input_type_check_rules(item, i) : true)" type="radio" [value]="data.input_type_check_value" (click)="data.metodo_check ? data.metodo_check(item, i, $event) : ''" [checked]="data.checked ? data.checked(item, i):false">
            <textarea *ngIf="data.textarea_value && (data.rules ?  data.textarea_value_rules(item, i) : true)" matInput [value]="item[data.textarea_value]" (change)="data.input_metodo ? data.input_metodo(item, i, $event) : ''" [readonly]="data.readonly ? data.readonly(item, i):false" [ngStyle]="data.styles ? data.styles:{}"></textarea>
          </ng-container>

        </div>

      </ng-container>


    </div>

    
      <ng-container *ngIf="!screen.isXSmall && !screen.isSmall">
        <ng-container *ngFor="let item of items_table; let i = index">
          <div *ngIf="i == 0" class="eco_simple_table_row_cell" style="position: sticky;bottom: 0;width: 100%;">
            <ng-container *ngFor="let data of data_table">
              <div class="eco_simple_table_cell gap-2 justify-content-center" style="background: #f2f7f9 !important" [ngClass]="data.footer_cell ? '':'p-0'" [ngStyle]="data.styles ? data.styles:{}" *ngIf="(data.header_rules ?  data.header_rules(item, i) : true)" >
                <span *ngIf="data.footer_cell && !data.footer_cell_format" [ngStyle]="data.footer_cell_styles ? data.footer_cell_styles:{}">{{data.footer_cell ? data.footer_cell(item, i):''}}</span>
                <span *ngIf="data.footer_cell && data.footer_cell_format" [ngStyle]="data.footer_cell_styles ? data.footer_cell_styles:{}">{{(data.footer_cell ? data.footer_cell(item, i):'') | currency:'':(data.footer_cell_format == 'importe' ? 'symbol':''):'1.2-2' }}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
    </ng-container>

  </div>
    
  <!-- ******************   FOOTER TABLE   ****************** -->
  <ng-container *ngIf="template">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ng-container>

  <!-- ******************   PAGINADOR   ****************** -->
  <div *ngIf="value_filter != '' ? footer_paginador && items_table_filtrado.length > 0 : footer_paginador && items_table.length > 0" class="eco_simple_table_paginador" [ngClass]=" (screen.isXSmall || screen.isSmall) ? 'w-100' : ''">
    <select [(ngModel)]="elementosPorPagina" class="paginador_cantidad_por_pagina" (change)="actualizarElementosPorPagina()">
      <option *ngFor="let value of rango_registros" [value]="value" [disabled]="elementosPorPagina == value">{{value}}</option>
    </select>

    <div class="rango" style="padding: 0 5px;">{{inicio() + 1}} - {{fin()}} de {{value_filter != '' ? items_table_filtrado.length : data_items.length}} </div>

    <button mat-mini-fab class="paginador_btn_anterior" (click)="cambiarPagina(paginaActual - 1)" [disabled]="paginaActual === 0"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button mat-mini-fab class="paginador_btn_siguiente" (click)="cambiarPagina(paginaActual + 1)" [disabled]="esUltimaPagina()"><mat-icon>keyboard_arrow_right</mat-icon></button>

  <!-- <div class="example-button-container" id="btn_scroll_up">
    <button mat-icon-button color="primary" style="background:#00000010;width: 30px;height: 30px;display: grid;place-content: center;" (click)="scroll_up()" matTooltip="Da click para ir al inicio o al final de la tabla" matTooltipPosition="left">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div> -->

  </div>
  

</div>