import { Injectable,ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scrollContainer!: ElementRef | null;
  constructor() { }


  setScrollContainer(el: ElementRef) {
    this.scrollContainer = el;
  }

  getScrollContainer(): ElementRef | null {
    return this.scrollContainer;
  }
}
