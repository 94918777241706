import { Component, OnInit ,ElementRef} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {ThemePalette} from '@angular/material/core';
 
import * as moment from 'moment';
import 'moment/locale/pt-br';
@Component({
  selector: 'app-objmix-nacional',
  templateUrl: './objmix-nacional.component.html',
  styleUrls: ['./objmix-nacional.component.css']
})
export class ObjmixNacionalComponent implements OnInit {
  public today:any = new Date();
  public dblObjetivoPDivisionalA:number=0;
  public dblObjetivoPDivisional:number=0;
  public arrayGerentesSuc:any=[];
  public gteSelected:any={};
  public arrayGerentes:any=[];
  public arrayHistorico:any=[];
  public dblObjetivoNacional:number=0;
  public arrayObjetivoNacional:any=[];
  public arrayObjetivoCapturado:any=[];
  public dblTEnero:number=0;
  public dblTFebrero:number=0;
  public dblTMarzo:number=0;
  public dblTAbril:number=0;
  public dblTMayo:number=0;
  public dblTJunio:number=0;
  public dblTJulio:number=0;
  public dblTAgosto:number=0;
  public dblTSeptiembre:number=0;
  public dblTOctubre:number=0;
  public dblTNoviembre:number=0;
  public dblTDiciembre:number=0;
  public dblObjetivoGerente:number=0;
  public strObservaciones:string='';
  public dblObjetivoA:number=0;
  public dblObjetivoC:number=0;
  public dblObjetivoD:number=0;
  public arrayAccesos:any=[];
  public tagName:any;
  public btnAutorizar:any=false;
  public btnRechazar:any=false;
  public arrayTotalObj:any=[];
  public dblObjNacional:number=0;
  public dblObjNacionalA:number=0;
  public dblObjNacionalD:number=0;
  public strEstatus:string=''; 
  public btnMostrarTabla:boolean=true;
 public arrayEncabezado:any=[];
 public statusGte:string='';
 public tabSelected:string='';
 public dblObjNacionalActual:number=0;
 public arrayHistoricoD:any=[];
 
 public dblTotalAsigSuc:number=0;
public arrayEncabezadoHist:any=[];
  color: ThemePalette = 'primary';
 
  

  constructor( elem:ElementRef,private _peticionesService: PeticionesService,public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router) {
    this.tagName='app-objmixdivisional';//elem.nativeElement.tagName.toLowerCase();

  }
  
  ngOnInit(): void {
    //obtener niveles de acceso
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      }
      else{
        this.btnAutorizar=objAcceso.find((o:any)=>o.strAccion=='Autorizar')?true:false;
        this.btnRechazar=objAcceso.find((o:any)=>o.strAccion=='Rechazar')?true:false;
    
        this.fgGetGerentes();
        // this.fnGetHistNacional();
        this.fnGetHistNacionalActual();
        this.fnGetObjetivoNacionalActual();
      }
    }
  }
  yourFn($event:any){
    this.tabSelected=$event.index;
    this.dblObjetivoA=0; 
    this.dblObjetivoD=0;
    this.dblObjNacionalD=0;
    if($event.index==0){
      this.fnGetHistNacionalActual();
      this.fnGetObjetivoNacionalActual();
     // this.fnGetTotalObjetivosActual();
      //this.fnGetTotalEstatusActual();
    } 
    else if($event.index==1){
      this.fnGetHistNacional();
      this.fnGetObjetivoNacional();
     // this.fnGetTotalEstatus();
      
      //this.fnGetTotalObjetivos();
    } 
    else if($event.index==2){
       this.fnGetTotalObjetivos();
    } 
}
fnGetObjSuc(item:any){
  this.arrayGerentesSuc=[];
  this.dblTotalAsigSuc=0;
  this._peticionesService.getApiCompras({
    strAccion: 'getObjAsignadoDiv' ,
    strGerente:item.strGerente
  }).subscribe(
    result => {  
      this.arrayGerentesSuc=result; 
      this.arrayGerentesSuc.forEach((elemento:any)=> { 
        this.dblTotalAsigSuc=this.dblTotalAsigSuc + elemento.dblObjetivo;
      });
    }
  );
}
  fgGetGerentes(){
    this._peticionesService.getApiCompras({
      strAccion: 'getGerentes' 
    }).subscribe(
      result => { 
        this.arrayGerentes=result; 
      }
    );
  }

  fnFiltrar(){
     
    //this.statusGte = this.arrayTotalObj.length>0?this.arrayTotalObj.find((o: { strSucursal: string; }) =>o.strSucursal==this.gteSelected.strEstatus).strEstatus:'ASIGNADO';
    //resetear variables
    this.arrayObjetivoCapturado=[];
    this.dblObjetivoNacional=0;
    this.dblObjetivoA=0;
    this.dblObjetivoC=0;
    this.dblObjetivoD=0;
    this.fnGetObjetivoDiv(); //obtener los objetivos capturados de cada gerente
    
  }
  fnGetHistNacional(){
    this.arrayEncabezado=[];
    var today = new Date();
    let periodo =  today.getMonth()+2;
    let ejercicio =  today.getFullYear();
    let obj={
      strAccion: 'getHistNacional2' ,
      strGerente:this.gteSelected.strGerente,
      intPeriodo:periodo==13?1:periodo,
      intEjercicio: periodo==13?ejercicio+1:ejercicio
    };
    this._peticionesService.getApiCompras(obj).subscribe(
      result => { 
        this.arrayHistorico=result; 
        this.arrayHistorico.forEach((elemento:any)=> { 
        
       /*   let objAux = this.arrayObjetivoNacional.find
          ((o: { strLinea: string; }) =>o.strLinea==elemento.strLinea);
          elemento.dblObjetivoNacional= objAux.dblObjetivo*/
          this.dblTEnero= this.dblTEnero+elemento.dblEnero;
          this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
          this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
          this.dblTAbril= this.dblTAbril+elemento.dblAbril;
          this.dblTMayo= this.dblTMayo+elemento.dblMayo;
          this.dblTJunio= this.dblTJunio+elemento.dblJunio;
          this.dblTJulio= this.dblTJulio+elemento.dblJulio;
          this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
          this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
          this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
          this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
          this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre; 
         
        });
        this.arrayHistorico.forEach((elemento:any)=>{
          let objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Enero');
          elemento.dblEnero && objAux==undefined? this.arrayEncabezado.push({strMes:'Enero', total:this.dblTEnero}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Febrero');
          elemento.dblFebrero && objAux==undefined? this.arrayEncabezado.push({strMes:'Febrero',total:this.dblTFebrero}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Marzo');
          elemento.dblMarzo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Marzo',total:this.dblTMarzo}):0;

          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Abril');
          elemento.dblAbril&& objAux==undefined? this.arrayEncabezado.push({strMes:'Abril',total:this.dblTAbril}):0;

          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Mayo');
          elemento.dblMayo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Mayo', total:this.dblTMayo}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Junio');
          elemento.dblJunio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Junio',total:this.dblTJunio}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Julio');
          elemento.dblJulio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Julio',total:this.dblTJulio}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Agosto');
          elemento.dblAgosto&& objAux==undefined? this.arrayEncabezado.push({strMes:'Agosto',total:this.dblTAgosto}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Septiembre');
          elemento.dblSeptiembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Septiembre',total:this.dblTSeptiembre}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Octubre');
          elemento.dblOctubre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Octubre',total:this.dblTOctubre}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Noviembre');
          elemento.dblNoviembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Noviembre',total:this.dblTNoviembre}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Diciembre');
          elemento.dblDiciembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Diciembre',total:this.dblTDiciembre}):0;
        })
      }
    );
  }
  fnGetHistNacionalActual(){
    this.arrayEncabezado=[];
    this.arrayHistorico=[];
    var today = new Date(); 
    let periodo =  today.getMonth()+1;
    let ejercicio =  today.getFullYear();
    let obj={
      strAccion: 'getHistNacional2' ,
      strGerente:this.gteSelected.strGerente,
      intPeriodo:periodo,
      intEjercicio: ejercicio
    };

    this._peticionesService.getApiCompras(obj).subscribe(
      result => { 
        this.arrayHistorico=result;
        console.log('historico nacional actual')
        console.log(result);

        this.arrayHistorico.forEach((elemento:any)=> { 
        
       /*   let objAux = this.arrayObjetivoNacional.find
          ((o: { strLinea: string; }) =>o.strLinea==elemento.strLinea);
          elemento.dblObjetivoNacional= objAux.dblObjetivo*/
          this.dblTEnero= this.dblTEnero+elemento.dblEnero;
          this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
          this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
          this.dblTAbril= this.dblTAbril+elemento.dblAbril;
          this.dblTMayo= this.dblTMayo+elemento.dblMayo;
          this.dblTJunio= this.dblTJunio+elemento.dblJunio;
          this.dblTJulio= this.dblTJulio+elemento.dblJulio;
          this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
          this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
          this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
          this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
          this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre; 
         
        });
        this.arrayHistorico.forEach((elemento:any)=>{
          let objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Enero');
          elemento.dblEnero && objAux==undefined? this.arrayEncabezado.push({strMes:'Enero', total:this.dblTEnero}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Febrero');
          elemento.dblFebrero && objAux==undefined? this.arrayEncabezado.push({strMes:'Febrero',total:this.dblTFebrero}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Marzo');
          elemento.dblMarzo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Marzo',total:this.dblTMarzo}):0;

          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Abril');
          elemento.dblAbril&& objAux==undefined? this.arrayEncabezado.push({strMes:'Abril',total:this.dblTAbril}):0;

          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Mayo');
          elemento.dblMayo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Mayo', total:this.dblTMayo}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Junio');
          elemento.dblJunio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Junio',total:this.dblTJunio}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Julio');
          elemento.dblJulio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Julio',total:this.dblTJulio}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Agosto');
          elemento.dblAgosto&& objAux==undefined? this.arrayEncabezado.push({strMes:'Agosto',total:this.dblTAgosto}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Septiembre');
          elemento.dblSeptiembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Septiembre',total:this.dblTSeptiembre}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Octubre');
          elemento.dblOctubre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Octubre',total:this.dblTOctubre}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Noviembre');
          elemento.dblNoviembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Noviembre',total:this.dblTNoviembre}):0;
          objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Diciembre');
          elemento.dblDiciembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Diciembre',total:this.dblTDiciembre}):0;
        })
      }
    );
  }
  fnGetObjetivoNacional(){
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivoNacional' ,
      strGerente:this.gteSelected.strGerente
      
    }).subscribe(
      result => { 
        console.log('objetivo nacional');
        this.arrayObjetivoNacional=result;  
        this.dblObjNacional=0;
        this.arrayObjetivoNacional.forEach((elemento:any)=> { 
          this.dblObjNacional=this.dblObjNacional+elemento.dblObjetivo;
          this.fnGetTotalObjetivos();
        });
        console.log('objetivo nacional')
        console.log(this.dblObjNacional)
       
      }
    );
    
  }
  fnGetObjetivoNacionalActual(){
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivoNacionalActual' ,
      strGerente:this.gteSelected.strGerente
      
    }).subscribe(
      result => { 
        this.arrayObjetivoNacional=result;  
        this.dblObjNacionalActual=0;
        this.arrayObjetivoNacional.forEach((elemento:any)=> { 
          this.dblObjNacionalActual=this.dblObjNacional+elemento.dblObjetivo;
          this.fnGetTotalObjetivos();
        });
        console.log('objetivo nacional actual');
        console.log(this.dblObjNacionalActual)
       
      }
    );
    
  }
  fnCambiam(event:any){
    let v =event.target.value;
    let z=v.replace(',','').replace(',','').replace('$','');
    this.dblObjetivoGerente=parseFloat(z);
  }
  fnSetObjetivoGte(){ 
    let today  = new Date();
    let periodo = today.getMonth()+2;
    let ejercicio = today.getFullYear();
    let obj ={
      strAccion: 'setObjetivoDivisionalNac' ,
      strGerente:this.gteSelected.strGerente,
      xmlLineas:[{dblObjetivo:this.dblObjetivoGerente,strLinea:'OBJETIVO NACIONAL'}],
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
      
    }
    console.log(this.dblObjetivoGerente);
    /* {
      strAccion: 'setObjetivoDivisionalNac' ,
      strGerente:this.gteSelected.strGerente,
      xmlLineas:[{dblObjetivo:this.dblObjetivoGerente,strLinea:'OBJETIVO NACIONAL'}]
      
    } */
    this._peticionesService.getApiCompras(obj).subscribe(
      result => { 
        this.fnGetObjetivoNacional();
        this._alertify.success('Objetivo Asignado Correctamente'); 
      }
    );
  }
  fnGetObjetivoDiv(){
    let today  = new Date();
    let periodo = today.getMonth()+2; 
    let ejercicio = today.getFullYear();
    let obj ={
      strAccion: 'getHistDivisional_New' ,
      strGerente:this.gteSelected.strGerente,
      intPeriodo:periodo,
      intEjercicio:ejercicio
      
    }
 
    this._peticionesService.getApiCompras(obj).subscribe(
      result => { 
        this.arrayHistoricoD=result;
        this.fnGetPromedioVta_Divisional('siguiente');
        /*this.dblObjetivoC=0;
        console.log(result)
        let x= result.find
        ((o: { strLinea: string; }) =>o.strLinea=='OBJETIVO NACIONAL');
        this.dblObjetivoA= x?x.dblObjetivo:0;
        this.dblObjetivoGerente= this.dblObjetivoA?this.dblObjetivoA:this.dblObjetivoGerente;
        result.forEach((elemento:any)=> { 
          console.log(elemento);
          if(elemento.strLinea=='OBJETIVO NACIONAL'){
          
            elemento.strLinea='';
            elemento.dblObjetivo=0;
          }else {
            this.dblObjetivoC=this.dblObjetivoC + elemento.dblObjetivo;
            this.strEstatus=elemento.strEstatus;
            this.strObservaciones=elemento.strSucursal;
          }
        });
        this.arrayObjetivoCapturado=result;
        this.fnGetHistoricoDiv();*/
      }
      );
  }
  fnGetPromedioVta_Divisional(tipo:String){
    this.dblObjetivoPDivisional=0;
    this.dblObjetivoPDivisionalA=0;
    let periodo = this.today.getMonth()+1  ;
    let ejercicio = this.today.getFullYear();
    this._peticionesService.getApiCompras({
      strAccion: 'getPromedioVta_Divisional' ,
       strGerente:this.gteSelected.strGerente,
      strTipo:tipo
    }).subscribe(
      result => {  
        console.log(result)
        let arrayR =result;
        let tipo='Último  trimestre '+ejercicio;
        let objResult = arrayR.find(o=>o.strTipo ==tipo);
        this.dblObjetivoPDivisional =  (objResult.strAccesorios?parseFloat(objResult.strAccesorios):0);
        this.dblObjetivoPDivisional = this.dblObjetivoPDivisional  + (objResult.strBlue?parseFloat(objResult.strBlue):0);
        this.dblObjetivoPDivisional = this.dblObjetivoPDivisional  + ( objResult.strDegrapack?parseFloat(objResult.strDegrapack):0);  
        this.dblObjetivoPDivisional = this.dblObjetivoPDivisional  +(objResult.strDespachadores?parseFloat(objResult.strDespachadores):0);
        this.dblObjetivoPDivisional = this.dblObjetivoPDivisional  + (objResult.strFoaom?parseFloat(objResult.strFoaom):0);
        this.dblObjetivoPDivisional = this.dblObjetivoPDivisional  + (objResult.strKimberly?parseFloat(objResult.strKimberly):0);
        this.dblObjetivoPDivisional = this.dblObjetivoPDivisional  + (objResult.strQuimicos?parseFloat(objResult.strQuimicos):0);
        this.dblObjetivoPDivisional = this.dblObjetivoPDivisional  +(objResult.strRubbermaid?parseFloat(objResult.strRubbermaid):0);
        this.dblObjetivoPDivisional = this.dblObjetivoPDivisional  + (objResult.strTork?parseFloat(objResult.strTork):0 ) ; 
       

        let tipo2='Último  trimestre '+(ejercicio-1);
        let objResult2 = arrayR.find(o=>o.strTipo ==tipo2);
        this.dblObjetivoPDivisionalA =  (objResult.strAccesorios?parseFloat(objResult2.strAccesorios):0);
        this.dblObjetivoPDivisionalA = this.dblObjetivoPDivisionalA  + (objResult2.strBlue?parseFloat(objResult2.strBlue):0);
        this.dblObjetivoPDivisionalA = this.dblObjetivoPDivisionalA  + ( objResult2.strDegrapack?parseFloat(objResult2.strDegrapack):0);  
        this.dblObjetivoPDivisionalA = this.dblObjetivoPDivisionalA  +(objResult2.strDespachadores?parseFloat(objResult2.strDespachadores):0);
        this.dblObjetivoPDivisionalA = this.dblObjetivoPDivisionalA  + (objResult2.strFoaom?parseFloat(objResult2.strFoaom):0);
        this.dblObjetivoPDivisionalA = this.dblObjetivoPDivisionalA  + (objResult2.strKimberly?parseFloat(objResult2.strKimberly):0);
        this.dblObjetivoPDivisionalA = this.dblObjetivoPDivisionalA  + (objResult2.strQuimicos?parseFloat(objResult2.strQuimicos):0);
        this.dblObjetivoPDivisionalA = this.dblObjetivoPDivisionalA  +(objResult2.strRubbermaid?parseFloat(objResult2.strRubbermaid):0);
        this.dblObjetivoPDivisionalA = this.dblObjetivoPDivisionalA  + (objResult2.strTork?parseFloat(objResult2.strTork):0 ) ; 
       
        this.dblObjetivoA=0;
        this.arrayHistoricoD.forEach((elemento:any)=> { 
          this.dblObjetivoA=this.dblObjetivoA+elemento.dblObjetivo;
          console.log(elemento.strLinea);
          elemento.promedioAct = elemento.strLinea=='ACCESORIOS'? parseFloat(objResult.strAccesorios)   : 
          elemento.strLinea=='BLUE TISSUE'? parseFloat(objResult.strBlue) :
          elemento.strLinea=='DEGRAPACK'?   parseFloat(objResult.strDegrapack) :
          elemento.strLinea=='DESPACHADORES ECODELI'?  parseFloat(objResult.strDespachadores) :
          elemento.strLinea=='FOAOM'?  parseFloat(objResult.strFoaom) :
          elemento.strLinea=='KIMBERLY'?  parseFloat(objResult.strKimberly) :
          elemento.strLinea=='QUIMICOS'?  parseFloat(objResult.strQuimicos) :
          elemento.strLinea=='RUBBERMAID'?  parseFloat(objResult.strRubbermaid) :
          elemento.strLinea=='TORK'? parseFloat(objResult.strTork) :0;

          elemento.promedioAnt = elemento.strLinea=='ACCESORIOS'? parseFloat(objResult2.strAccesorios)   : 
          elemento.strLinea=='BLUE TISSUE'? parseFloat(objResult2.strBlue) :
          elemento.strLinea=='DEGRAPACK'?   parseFloat(objResult2.strDegrapack) :
          elemento.strLinea=='DESPACHADORES ECODELI'?  parseFloat(objResult2.strDespachadores) :
          elemento.strLinea=='FOAOM'?  parseFloat(objResult2.strFoaom) :
          elemento.strLinea=='KIMBERLY'?  parseFloat(objResult2.strKimberly) :
          elemento.strLinea=='QUIMICOS'?  parseFloat(objResult2.strQuimicos) :
          elemento.strLinea=='RUBBERMAID'?  parseFloat(objResult2.strRubbermaid) :
          elemento.strLinea=='TORK'? parseFloat(objResult2.strTork) :0;
        });
        console.log(result);
      });
  }
  fnGetHistoricoDiv(){
  let today  = new Date();
  let periodo = today.getMonth()+2;
  let ejercicio = today.getFullYear();
  let obj ={
    strAccion: 'getHistDivisionalN' ,
    strGerente:this.gteSelected.strGerente,
    intPeriodo:periodo,
    intEjercicio:ejercicio
    
  }
 
  this._peticionesService.getApiCompras(obj).subscribe(
    result => { 
      console.log(result);
      this.arrayEncabezadoHist=[];
      this.dblTEnero= 0;
      this.dblTFebrero= 0;
      this.dblTMarzo= 0;
      this.dblTAbril= 0;
      this.dblTMayo= 0;
      this.dblTJunio= 0;
      this.dblTJulio=0;
      this.dblTAgosto= 0;
      this.dblTSeptiembre= 0;
      this.dblTOctubre= 0;
      this.dblTNoviembre= 0;
      this.dblTDiciembre= 0; 
      this.arrayHistoricoD = result;
      this.arrayHistoricoD.forEach((elemento:any)=> { 
        
           this.dblTEnero= this.dblTEnero+elemento.dblEnero;
           this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
           this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
           this.dblTAbril= this.dblTAbril+elemento.dblAbril;
           this.dblTMayo= this.dblTMayo+elemento.dblMayo;
           this.dblTJunio= this.dblTJunio+elemento.dblJunio;
           this.dblTJulio= this.dblTJulio+elemento.dblJulio;
           this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
           this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
           this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
           this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
           this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre; 
          
         });

      this.arrayHistoricoD.forEach((elemento:any)=>{
        let objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Enero');
        elemento.dblEnero && objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Enero', total:this.dblTEnero}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Febrero');
        elemento.dblFebrero && objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Febrero',total:this.dblTFebrero}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Marzo');
        elemento.dblMarzo&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Marzo',total:this.dblTMarzo}):0;

        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Abril');
        elemento.dblAbril&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Abril',total:this.dblTAbril}):0;

        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Mayo');
        elemento.dblMayo&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Mayo', total:this.dblTMayo}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Junio');
        elemento.dblJunio&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Junio',total:this.dblTJunio}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Julio');
        elemento.dblJulio&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Julio',total:this.dblTJulio}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Agosto');
        elemento.dblAgosto&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Agosto',total:this.dblTAgosto}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Septiembre');
        elemento.dblSeptiembre&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Septiembre',total:this.dblTSeptiembre}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Octubre');
        elemento.dblOctubre&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Octubre',total:this.dblTOctubre}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Noviembre');
        elemento.dblNoviembre&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Noviembre',total:this.dblTNoviembre}):0;
        objAux = this.arrayEncabezadoHist.find ((o: { strMes: string; }) =>o.strMes=='Diciembre');
        elemento.dblDiciembre&& objAux==undefined? this.arrayEncabezadoHist.push({strMes:'Diciembre',total:this.dblTDiciembre}):0;
      })
      console.log(this.arrayEncabezadoHist);
    }
    );
}  fnAutorizar(estatus:any){
    this._peticionesService.getApiCompras({
      strAccion: 'autorizarDivisional' ,
      strGerente:this.gteSelected.strGerente,
      strLinea :'OBJETIVO NACIONAL',
      strSucursal:this.strObservaciones,
      strEstatus:estatus      
    }).subscribe(
      result => { 
        let msj = 'Objetivo '+estatus+' Correctamente';
        this._alertify.success(msj); 
        this.fnFiltrar();
      });
  }
  fnGetTotalObjetivos(){
    let today  = new Date();
    let periodo =  today.getMonth()+2;
    let ejercicio =  today.getFullYear();
    let obj={
      strAccion: 'getTotalObjetivosNacional'  ,
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
      
    }
    this._peticionesService.getApiCompras(obj).subscribe(
      result => {  this.arrayTotalObj=result;
        this.dblObjNacionalA=0;
        this.arrayTotalObj.forEach((elemento:any)=> { 
          this.dblObjNacionalA=this.dblObjNacionalA+elemento.dblObjetivo;
          
        });
        this.dblObjNacionalD=this.dblObjNacional-this.dblObjNacionalA;
      });
  }
  
  fnOcultarTabla(){
    console.log(this.btnMostrarTabla);    
  }

}
