<!--<app-menu></app-menu>-->

<div class="container">
    <br><br>
    <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb fs15">
            <li class="breadcrumb-item"><a [routerLink]="['/retoTork']" class="texto-gris">Listado de Retos</a></li>
            <li class="breadcrumb-item">/</li>
            <li class="breadcrumb-item active" aria-current="page">Listado de Despachadores</li>
        </ol>
    </nav>
    <div class="row section_fondo">
        <div class="cien">
            <h1 class="bo">Listado de Despachadores Tork, <span>ID Reto: <b>{{intIDReto}}</b></span></h1>
            <h2 class="bo fRight" *ngIf="strFolio">Folio: <b>{{strFolio}}</b></h2>
        </div>
        <div class="cien">
            <h4 class="bo">Estatus Análisis:
                <b [ngClass]="{'text-warning': strEstatusAnalisis=='BORRADOR', 
                    'text-info': strEstatusAnalisis=='PENDIENTE', 
                    'text-success': strEstatusAnalisis=='AVANZADO', 
                    'text-danger': strEstatusAnalisis=='RECHAZADO', 
                    'text-aceptado': strEstatusAnalisis=='AUTORIZADO'}">{{strEstatusAnalisis}}</b>
            </h4>
            
            <!--<h3 class="potencial">Potencial de Venta: <b>{{dblPotencialVenta | currency}}</b></h3>-->
            <h3 class="costoReto">Costo Total del Reto: <b>{{dblTotalReto | currency}}</b></h3>

            <button class="btn btn-success bo fRight" *ngIf="strEstatusAnalisis=='AUTORIZADO' && btnAutorizar==false" (click)="fnGenerarComodato2()">Generar Comodato</button>
            <!--<button class="btn btn-info bo fRight" (click)="fnGenerarComodato2()">PRUEBA</button>-->
        </div>
        <div class="cien">
            <br>
            <p *ngIf="strMotivoRechazoAnalisis!='' && strEstatusAnalisis=='RECHAZADO'" class="p_rojo"><b>Motivo por el que se rechazo el reto:</b> {{strMotivoRechazoAnalisis}}</p>
            
            <!--{{arrayInfoReto | json}}-->
            <div class="tabla_resp">
                <table class="tabla_producto">
                    <tr class="header_tabla">
                        <th class="w20">Categoría</th>
                        <th class="w10">Clave</th>
                        <th class="w20">Nombre</th>
                        <th class="w10">Cantidad</th>
                        <th class="w10">Unidad</th>
                        <th class="w10">Precio</th>
                        <th class="w10">Costo</th>
                        <th class="w10">Eliminar</th>
                    </tr>
                    <tr *ngFor="let A of arrayDespachadores" class="fondo_fijo_{{A.intExisteDespachador}} tr_body_tork">
                        <td class="w20">
                            <!--<select class="form-control" [(ngModel)]="A.strCategoria" disabled="{{A.intExisteDespachador==1}}">
                                <option disabled value="">-Seleccionar una categoría-</option>
                                <option *ngFor="let G of arrayCategoria" value="{{G.strCategoria}}">{{G.strCategoria}}</option>
                            </select>-->
                            <mat-form-field appearance="fill" class="fBco">
                                <mat-label>Categoría:</mat-label>
                                <mat-select [(ngModel)]="A.strCategoria" (selectionChange)="fnCambioCategoria(A)" disabled="{{A.intExisteDespachador==1}}">
                                    <mat-option *ngFor="let G of arrayCategoria" value="{{G.strCategoria}}">
                                        {{G.strCategoria}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <br>
                            <a (click)="fnResponderPreguntas(A)" class="textCosto" title="Responder preguntas">
                                <span class="material-symbols-outlined">help</span>
                            </a>
                            <a (click)="fnResponderPreguntasCons(A)" class="textCosto" title="Responder preguntas">
                                <span class="material-symbols-outlined">OTRO</span> 
                            </a>
                        </td>
                        <td class="w10">
                            <mat-form-field appearance="fill" class="fBco">
                                <mat-label>Clave Despachador:</mat-label>
                                <mat-select [(ngModel)]="A.strClaveDespachador" (selectionChange)="fnCambioDespachador(A, 'clave')" disabled="{{A.strCategoria=='' || A.intExisteDespachador==1}}">
                                    <mat-option *ngFor="let G of arrayListadoDespachadores | filterCategoria: A.strCategoria" value="{{G.strClaveDespachador}}">
                                        {{G.strClaveDespachador}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <br><br>
                            <mat-form-field appearance="fill" class="fBco">
                                <mat-label>Clave Consumible:</mat-label>
                                <mat-select [(ngModel)]="A.strClaveConsumible" (selectionChange)="fnCambioConsumible(A, 'clave')" disabled="{{A.strCategoria=='' || A.strClaveDespachador=='' || A.intExisteDespachador==1}}">
                                    <mat-option *ngFor="let G of arrayListadoConsumibles | filterDespachador: A.strClaveDespachador" value="{{G.strClaveConsumible}}">
                                        {{G.strClaveConsumible}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td class="w20">
                            <mat-form-field appearance="fill" class="fBco">
                                <mat-label>Nombre Despachador:</mat-label>
                                <mat-select [(ngModel)]="A.strNombreDespachador" (selectionChange)="fnCambioDespachador(A, 'nombre')" disabled="{{A.strCategoria=='' || A.intExisteDespachador==1}}">
                                    <mat-option *ngFor="let G of arrayListadoDespachadores | filterCategoria: A.strCategoria" value="{{G.strNombreDespachador}}">
                                        {{G.strNombreDespachador}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <br><br>
                            <mat-form-field appearance="fill" class="fBco">
                                <mat-label>Nombre Consumible:</mat-label>
                                <mat-select [(ngModel)]="A.strNombreConsumible" (selectionChange)="fnCambioConsumible(A, 'nombre')" disabled="{{A.strCategoria=='' || A.strClaveDespachador=='' || A.intExisteDespachador==1}}">
                                    <mat-option *ngFor="let G of arrayListadoConsumibles | filterDespachador: A.strClaveDespachador" value="{{G.strNombreConsumible}}">
                                        {{G.strNombreConsumible}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td class="w10">
                             <mat-form-field appearance="fill" class="matcelln fBco">
                                <mat-label>Cantidad Despachador: </mat-label>
                                <input matInput placeholder="" [(ngModel)]="A.intCantidadDespachador" min="1">
                            </mat-form-field>
                            <br><br>
                            <!--<mat-form-field appearance="fill" class="matcelln fBco">
                                <mat-label>Cantidad Mensual del Consumible en Cajas: </mat-label>
                                <input matInput [(ngModel)]="A.intCantidadConsumible" min="1" (blur)="fnSumarTotalReto()" disabled>
                            </mat-form-field>-->
                            <div class="cont_consu fBco">
                                <span>Consumible mensual en cajas:</span>
                                <p class="textCosto">{{A.intCantidadConsumible }}</p>
                            </div>
                        </td>
                        <td class="w10">
                            <mat-form-field appearance="fill" class="matcelln fBco">
                                <mat-label>Unidad Despachador: </mat-label>
                                <input matInput [(ngModel)]="A.strUnidadDespachador" maxlength="10" disabled="{{A.intExisteDespachador==1}}">
                            </mat-form-field>
                            <br><br>
                            <mat-form-field appearance="fill" class="matcelln fBco">
                                <mat-label>Unidad Consumible: </mat-label>
                                <input matInput [(ngModel)]="A.strUnidadConsumible" maxlength="10" disabled="{{A.intExisteDespachador==1}}">
                            </mat-form-field>
                        </td>
                        <td class="w10">
                            <!--<mat-form-field appearance="fill" class="matcell fBco">
                                <mat-label>Precio Consumible: </mat-label>
                                <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="A.dblPrecioConsumible" (blur)="updatePrecioConsumible(A, $event)" disabled/>
                            </mat-form-field>-->
                            <div class="cont_consu fBco">
                                <span>Precio por Caja del Consumible:</span>
                                <p class="textCosto">{{A.dblPrecioConsumible | currency}}</p>
                            </div>
                            <br><br>
                            <div class="cont_consu fBco">
                                <span>Lugar de Instalación:</span>
                                <p class="textCosto">{{A.strLugarInstalacion}}</p>
                            </div>
                        </td>
                        <td class="w10">                            
                            <div class="cont_consu">
                                <span>Costo por Caja del Consumible:</span>
                                <p class="textCosto">{{A.dblCostoPromedio | currency}}</p>
                            </div>
                            <div class="cont_consu">
                                <span>Costo Total de Cajas de Consumible:</span>
                                <p class="textCosto">{{(A.dblCostoPromedio * A.intCantidadConsumible) | currency}}</p>
                            </div>
                        </td>
                        <td class="w10"><!--*ngIf="A.intExisteDespachador==0"-->
                            <a class="trash-icon" (click)="fnEliminar(A)"><i class="fa-solid fa-trash-can"></i></a>                            
                        </td>
                    </tr>
                    <tr>
                        <td colspan="8"><a class="plus-icon fLeft" (click)="fnAgregar()"><i class="fa-solid fa-circle-plus"></i></a></td>
                    </tr>
                </table>
            </div>
            
                
            <div class="cien mt-20">
                <div class="col-sm-6">
                    <div class="cien">
                        <label class="label">Fecha Instalación</label>
                        <input type="date" [(ngModel)]="strFechaInstalacion" class="form-control">
                    </div>
                    <br><br>
                    <div class="cien">
                        <label class="label">Contacto</label>
                        <input type="text" [(ngModel)]="strContactoAd" class="form-control">
                    </div>
                    <br><br>
                    <div class="cien">
                        <label class="label">Teléfono</label>
                        <input type="tel" [(ngModel)]="strTelefonoAd" class="form-control">
                    </div>
                    <!--
                    <div class="cien mt-20">
                        <label class="label">Dias Laborales:</label><br>
                        <p class="diasLaborales">{{strDiasLaborales}}</p>  
                    </div>
                    -->
                    <!--<p class="diasLaborales">{{arrayDiasLaborales | json}}</p>-->
                    <!--<div class="cien cont_checkbox">
                        <div class="cien">
                            <input type="checkbox" id="Lunes" value="Lunes" (click)="fnAddDia('Lunes')"> <label for="Lunes">Lunes</label>
                        </div>
                        <div class="cien">
                            <input type="checkbox" id="Martes" value="Martes" (click)="fnAddDia('Martes')"> <label for="Martes">Martes</label>
                        </div>
                        <div class="cien">
                            <input type="checkbox" id="Miercoles" value="Miercoles" (click)="fnAddDia('Miercoles')"> <label for="Miercoles">Miercoles</label>
                        </div>
                        <div class="cien">
                            <input type="checkbox" id="Jueves" value="Jueves" (click)="fnAddDia('Jueves')"> <label for="Jueves">Jueves</label>
                        </div>
                        <div class="cien">
                            <input type="checkbox" id="Viernes" value="Viernes" (click)="fnAddDia('Viernes')"> <label for="Viernes">Viernes</label>
                        </div>
                        <div class="cien">
                            <input type="checkbox" id="Sabado" value="Sabado" (click)="fnAddDia('Sabado')"> <label for="Sabado">Sábado</label>
                        </div>
                        <div class="cien">
                            <input type="checkbox" id="Domingo" value="Domingo" (click)="fnAddDia('Domingo')"> <label for="Domingo">Domingo</label>
                        </div>
                    </div>-->
                </div>

                <div class="col-sm-6">
                    <!--<div class="cien">
                        <label class="label" for="hrEntrada">Hora Entrada:</label><br>
                        <input type="time" [(ngModel)]="strHoraEntrada" id="hrEntrada" name="hrEntrada" min="09:00" required class="form-control">
                    </div>

                    <div class="cien mt-20">
                        <label class="label" for="hrSalida">Hora Salida:</label><br>
                        <input type="time" [(ngModel)]="strHoraSalida" id="hrSalida" name="hrSalida" min="09:00" required class="form-control">
                    </div>
                    -->
                    <div class="cien mt-20">
                        <label class="label">Comentarios</label>
                        <textarea type="text" [(ngModel)]="strComentarioAnalisis" placeholder="Comentarios" maxlength="200" class="form-control"></textarea>
                    </div>
                   
                    <div class="cien mt-20" *ngIf="strAdjuntoSolicitud==''">
                        <label class="label">Adjuntar bitacora</label>
                        <input type="file" class="form-control select45" (value)="strBitacoraPDF" name="strBitacoraPDF" [(ngModel)]="strBitacoraPDF" (change)="seleccionarPDF($event)">
                    </div>

                    <div class="cien mt-20" *ngIf="strAdjuntoSolicitud!=''">
                        <label class="label">Adjuntar bitacora</label><br>
                        <a class="hiper_icon" target="_blank" href="https://www.ecodeli.com/{{strAdjuntoSolicitud}}"><span class="material-symbols-outlined">downloading</span></a>
                    </div>
                    
                </div>
            </div>
            
            <div class="cien mt-20">
                <button (click)="fnGuardarListado()" class="boton_guardar_nuevo fRight" *ngIf="arrayDespachadores.length>0 && spOcultaBoton==false && strEstatusAnalisis=='BORRADOR' && btnEnviarListado==true">Enviar Solicitud de Comodatos</button>
                <!--<button (click)="uploadPDF()" class="boton_guardar_nuevo fRight">Adjuntar archivo</button>-->

                <!--<button (click)="fnGuardarListado()" class="boton_guardar_nuevo fRight" *ngIf="arrayDespachadores.length>0 && spOcultaBoton==false && strEstatusAnalisis=='BORRADOR' && btnEnviarListado==true">Enviar Listado a Autorizazión</button>
                <button (click)="fnAvanzarRechazarAnalisis('Avanzado')" *ngIf="(strEstatusAnalisis=='PENDIENTE' && spOcultaBoton==false) && (btnAvanzar==true)" class="boton_guardar_nuevo boton_autorizar fRight">Avanzar Análisis</button>
                <button (click)="fnAutorizaAnalisis()" *ngIf="(strEstatusAnalisis=='AVANZADO' && spOcultaBoton==false) && (btnAutorizar==true)" class="boton_guardar_nuevo boton_autorizar fRight">Autorizar Análisis</button>
                <button (click)="fnRechazarAnalisis()" *ngIf="(strEstatusAnalisis=='PENDIENTE' && spOcultaBoton==false && btnRechazar==true) || (strEstatusAnalisis=='AVANZADO' && btnAutorizar==true)"  class="boton_guardar_nuevo boton_rechazar fRight">Rechazar Análisis</button>-->
            </div>
        </div>
    </div>

</div>

<app-footer></app-footer>