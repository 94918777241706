
import { Component, OnInit,ElementRef,ViewChild  } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { MatSort, Sort } from '@angular/material/sort';
import { CarroService } from '../servicios/carroservice';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface data {
  strCliente: string;
  strClienteNombreCorto: string;
  strNombreSucursalCte: string;
  dblEnero: string;
  dblFebrero: string;
  dblMarzo: string;
  dblAbril: string;
  dblMayo: string;
  dblJunio: string;
  dblJulio: string;
  dblAgosto: string;
  dblSeptiembre: string;
  dblOctubre: string;
  dblNoviembre: string;
  dblDiciembre: string;
  totales: string;
}
@Component({
  selector: 'app-cliente-venta-monto',
  templateUrl: './cliente-venta-monto.component.html',
  styleUrls: ['./cliente-venta-monto.component.css']
})
export class ClienteVentaMontoComponent implements OnInit {

  intEjercicio:number=0;
  intPeriodo:number; 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['strCliente','strClienteNombreCorto',
  'strNombreSucursalCte',
  'dblEnero',
  'dblFebrero',
  'dblMarzo',
  'dblAbril',
  'dblMayo',
  'dblJunio',
  'dblJulio',
  'dblAgosto',
  'dblSeptiembre',
  'dblOctubre',
  'dblNoviembre',
  'dblDiciembre',
  'totales'];
  isHoveredButton: boolean = false;
  isHoveredButton1: boolean = false;
  dataSource: MatTableDataSource<data>;
  public strConexion:string="";
  public arrayAccesos:any=[];
  public arrayTotal:any=[];
  public arrayArts:any=[];
  arrayEjercicio:any=[];
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayArtsDistinct: any=[];
  public TotalEnero:number=0;
  public TotalFebrero:number=0;
  public TotalMarzo:number=0;
  public TotalAbril:number=0;
  public TotalMayo:number=0;
  public TotalJunio:number=0;
  public TotalJulio:number=0;
  public TotalAgosto:number=0;
  public TotalSeptiembre:number=0;
  public TotalOctubre:number=0;
  public TotalNoviembre:number=0;
  public TotalDiciembre:number=0;
  
  public arrayExistentes: any = [];
  public spinerArts:boolean = false;
  public strAgente:string="";
  public activeProduct:boolean=false;
  public strAgente_Crea: string;
  public strCliente:string="";
  public strFamilia:string="";
  public strUsuario: string;
  
  public boolEditaAgente: boolean = true;
  public objCliente:any ={};
  public tagName:any;
  constructor(private carroservice: CarroService,private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, 
    elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
    private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
      this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
      let fecha = new Date();
      this.intEjercicio = fecha.getFullYear();
      this.intPeriodo = fecha.getMonth() + 1;
      this.strConexion = this.localSt.retrieve('strConexion');
      this.tagName = elem.nativeElement.tagName.toLowerCase();
     }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        if(objAcceso[0].strComponente == this.tagName){
          this.objCliente = this.arrayAccesos[0]; 
          console.log(this.objCliente)
          this.strFamilia = this.objCliente.strFamiliaCte;
          this.strCliente = this.objCliente.strCliente;
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgente_Crea = this.localSt.retrieve('strAgente');
          this.strAgente = this.localSt.retrieve('strAgente');
          this.strUsuario = this.localSt.retrieve('strUsuario');
          this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
          this.getArtsPromocion()
          this.fnGetFiltros();
        }else{
          let urlHome = this.arrayAccesos[0].strUrlHome;
          this.router.navigate(['/'+urlHome]);
        }
      }
      else {
        this.router.navigate(['/login']);
      }
    });
  }


  fntabla(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  
 fnGetFiltros(){
  this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
    this.arrayEjercicio = nuevoValor;
  });

}
getArtsPromocion() {
  this.spinerArts = true;
  this.arrayTotal = []
  this.arrayExistentes = [];
  let objEnv = {}
  if (this.strFamilia==''){
    objEnv = {
      'intEjercicio': this.intEjercicio,
      'strFamilia':'',
      'strAccion': 'getReporteImporte',
      'strCliente':this.strCliente,
    strConexion:this.strConexion
  }; 
  }else{
    objEnv = {
      'intEjercicio': this.intEjercicio, 
      'strFamilia':this.strFamilia,
      'strCliente':'',
      'strAccion': 'getReporteImporte',
      strConexion:this.strConexion}; 
  }
console.log(objEnv)
  this._httpService.getClienteDatos(objEnv, 'spReporteVentaPorImporte').subscribe(
      result => {
        console.log(result)
        this.arrayTotal = result;
      this.fnCalcularARTS();
      this.spinerArts = false;
      }, error => {
        var error = <any>error; console.log(error);
        this.spinerArts = false;
      });
}
fnCalcularARTS(){
  console.log()
  this.arrayExistentes = [];
  this.arrayArtsDistinct  = this.arrayTotal.reduce((prev, current) => 
    prev.some(x => x.strIDSucursalCte === current.strIDSucursalCte)? prev: [...prev, current ], [])
  this.TotalEnero=0;
  this.TotalFebrero=0;
  this.TotalMarzo=0;
  this.TotalAbril=0;
  this.TotalMayo=0;
  this.TotalJunio=0;
  this.TotalJulio=0;
  this.TotalAgosto=0;
  this.TotalSeptiembre=0;
  this.TotalOctubre=0;
  this.TotalNoviembre=0;
  this.TotalDiciembre=0;
  this.arrayArtsDistinct.forEach(element => {
    let item:any ={}
    item.strCliente = element.strCliente;
    item.strClienteNombre = element.strClienteNombre
    item.strClienteNombreCorto = element.strClienteNombreCorto;
    item.strNombreSucursalCte = element.strNombreSucursalCte;
    item.strIDSucursalCte = element.strIDSucursalCte
    item.dblEnero = 0;
    item.dblFebrero = 0;
    item.dblMarzo = 0;
    item.dblAbril = 0;
    item.dblMayo = 0;
    item.dblJunio = 0;
    item.dblJulio = 0;
    item.dblAgosto = 0;
    item.dblSeptiembre = 0;
    item.dblOctubre = 0;
    item.dblNoviembre = 0;
    item.dblDiciembre = 0;
    this.arrayTotal.forEach(art => {
    if(art.strIDSucursalCte == element.strIDSucursalCte){
      if (art.intPeriodo=='1'){
        item.dblEnero=art.dblImporte;
        this.TotalEnero=this.TotalEnero+art.dblImporte;
      }else if (art.intPeriodo=='2'){
        item.dblFebrero=art.dblImporte;
        this.TotalFebrero=this.TotalFebrero+art.dblImporte;
      }else if (art.intPeriodo=='3'){
        item.dblMarzo=art.dblImporte;
        this.TotalMarzo=this.TotalMarzo+art.dblImporte;
      }else if (art.intPeriodo=='4'){
        item.dblAbril=art.dblImporte;
        this.TotalAbril=this.TotalAbril+art.dblImporte;
      }else if (art.intPeriodo=='5'){
        item.dblMayo=art.dblImporte;
        this.TotalMayo=this.TotalMayo+art.dblImporte;
      }else if (art.intPeriodo=='6'){
        item.dblJunio=art.dblImporte;
        this.TotalJunio=this.TotalJunio+art.dblImporte;
      }else if (art.intPeriodo=='7'){
        item.dblJulio=art.dblImporte;
        this.TotalJulio=this.TotalJulio+art.dblImporte;
      }else if (art.intPeriodo=='8'){
        item.dblAgosto=art.dblImporte;
        this.TotalAgosto=this.TotalAgosto+art.dblImporte;
      }else if (art.intPeriodo=='9'){
        item.dblSeptiembre=art.dblImporte;
        this.TotalSeptiembre=this.TotalSeptiembre+art.dblImporte;
      }else if (art.intPeriodo=='10'){
        item.dblOctubre=art.dblImporte;
        this.TotalOctubre=this.TotalOctubre+art.dblImporte;
      }else if (art.intPeriodo=='11'){
        item.dblNoviembre=art.dblImporte;
        this.TotalNoviembre=this.TotalNoviembre+art.dblImporte;
      }else if (art.intPeriodo=='12'){
        item.dblDiciembre=art.dblImporte;
        this.TotalDiciembre=this.TotalDiciembre+art.dblImporte;
      }
    }
    });
    item.total = item.dblEnero + item.dblFebrero+item.dblMarzo+item.dblAbril+item.dblMayo+
    item.dblJunio + item.dblJulio+item.dblAgosto+item.dblSeptiembre+item.dblOctubre+item.dblNoviembre+item.dblDiciembre
    this.arrayExistentes.push(item);
  });
  this.dataSource = new MatTableDataSource(this.arrayExistentes);
  this.fntabla();
}


getTotalGeneral() {
  return this.TotalEnero + this.TotalFebrero + this.TotalMarzo + this.TotalAbril + this.TotalMayo +
    this.TotalJunio + this.TotalJulio + this.TotalAgosto + this.TotalSeptiembre + this.TotalOctubre +
    this.TotalNoviembre + this.TotalDiciembre;
}
  exportToExcel_hist1(): void {
        // Obtén el elemento de la tabla por su id
        const element = document.getElementById('tableHist_1'); // Asegúrate de que este ID coincide con el de tu tabla HTML
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
        
        // Crea un libro de trabajo
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        
        // Agrega la hoja al libro de trabajo
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
        // Descarga el archivo Excel con el nombre que elijas
        XLSX.writeFile(wb, 'venta_articulo.xlsx');
      }
}
