import { Component, OnInit, ElementRef, ViewChild ,Inject} from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';

import { CarroService } from '../servicios/carroservice';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { AlertifyService } from '../alertify.service';

import { filter } from 'rxjs/operators';
import { AltaClienteService } from '../servicios/alta-cliente.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Clientes } from '../directorio/directorio.component';
import { DataDBService } from '../servicios/data-db.service';
@Component({
  selector: 'app-solicitud-alta-cliente',
  templateUrl: './solicitud-alta-cliente.component.html',
  styleUrls: ['./solicitud-alta-cliente.component.css']
})
export class SolicitudAltaClienteComponent implements OnInit {
  arrayEjercicio: any = [];
  intEjercicio: number = 0;
  public arrayActualizacionCredito: any = [];
  public strAgente_Crea: string;
  public strUsuario: string;
  intPeriodo: number;
  public strCondicion: any = ''
  public arrayCondicionPago: any = [];
  public strNombreComercial: any = ''  
  inputSearch: string = "";
  public strCondicionModificada: any = ''
  isHoveredButton: boolean = false;
  public arrayTipo: any = [{ nombre: '--TODOS--', strTipo: '' }, { nombre: 'CLIENTE', strTipo: 'CLIENTE' }, { nombre: 'LEADS', strTipo: 'LEADS' }];
  public strTipo: string = '';
  public arrayCotizaciones: any = [];
  public strConexion: string = "";
  public arrayDetalleArts: any = [];
  public arrayAgentes: any = [];
  public arrayPeriodo: any = [];
  public strAgente: string = "";
  public arraySucursales: any = [];
  public strCliente: any = ''
  public strUrl_Pagare: any = ''
  public strSucursal: string = "";
  public arrayGerentes: any = [];
  public strGerente: string = "";
  public arrayAccesos: any = [];
  public arrayDetPlantillas: any = [];
  public boolGerente: boolean = false;
  public boolSucursal: boolean = false;
  public boolAgente: boolean = false;
  public tagName: any;
  public objCte: any = {};
  public strNombreAgente: any = ''
  public dblLimiteCreditoActualizado: any
  public spiner: boolean = false;
  public spinerMdl: boolean = false;
  public arrayOriginal: any = [];
  public strEstatus: string = "POR AUTORIZAR"
  public strTipo_plantilla: string = '';
  public ArchivoPagare: any = []
  currentPage: number = 1;
  public objAlta: any = []
  itemsPerPage: number = 10;
  arrayItems = [10, 25, 50, 100]
  public rutaPagare: any = ''
  public arrayArchivos: any = []
  public formData = new FormData();
  public strAgenteLS: any= ''
  public banderaBotonEnviarSolcitud: boolean = true
  public objDetalleSolcitud: any = []
  public boolCteExiste:boolean=false;
  public strCte:string="";
  form: FormGroup;
  formDomFiscal: FormGroup;
  public arrayColonias:any;
  public strPais:string="";
  public objAgenSuc:any;
  public objClienteValid:any;
  public nameFileIvaSat:File|null = null;
  public strSitFiscal:string="Subir Archivo";
  public strDomicilio:string="Subir Archivo";
  public boolSitFiscal:boolean=false;
  public boolDomicilio:boolean=false;
  public docSitFiscal:File|null = null;
  public docDomicilio:File|null = null;
  public objDocs:any={
    objectSit:{},
    objectDom:{}
  }
  @ViewChild('btncerrar') btnCerrarMdl!: ElementRef;
  @ViewChild('btnCerrarAltaSuc') btnAltaSuc!: ElementRef;
  @ViewChild('fileIvaSat') fileIvaSat!: ElementRef;
  @ViewChild('btnDocIVa') btnDocIVa!: ElementRef;

  @ViewChild('docSitFiscal') fileSitFiscal!: ElementRef;
  @ViewChild('docDomicilio') fileDomicilio!: ElementRef;
  @ViewChild('btnCerrarSitDoc') btnMdlDomicilio!: ElementRef;
  
  

  public arrayEstatus: any = [{ strEstatus: 'AUTORIZADO' }, { strEstatus: 'POR AUTORIZAR' }, { strEstatus: 'RECHAZADO' }];
  constructor(private indexedDBService: DataDBService,private fb: FormBuilder,private alta_cliente: AltaClienteService, private _altaCteServ: AltaClienteService,
     public _alertify: AlertifyService, private carroservice: CarroService, elem: ElementRef, 
     private localSt: LocalStorageService, private editPl: PlantillafinService, private router: Router, private _httpService: PeticionesService, private _filtroServ: FiltrosService) {
    this.strAgenteLS = this.localSt.retrieve('strAgente')
     
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strPais = this.localSt.retrieve('strPais');
    //array de accesos
    //obtener niveles de acceso
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos') ? this.localSt.retrieve('arrayAccesos') : [];
    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);

      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      }
      else {
        console.log(objAcceso)
        this._httpService.setMostrarMenu(true); 

        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
      }
    }
    this.fnInitForm()
  }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
 
      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        this.strConexion = this.localSt.retrieve('strConexion');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strAgente = this.localSt.retrieve('strAgente');
        this.strUsuario = this.localSt.retrieve('strUsuario'); 
     
        //------------conexi+on-------------------
        this.indexedDBService.getItem('strConexion', (value) => {
          this.strConexion = value;
          /*    console.log(' CARGA conexion directorio') */
        });
      }
      else {
        this.router.navigate(['/login']);
      }
    });

    this.fnGetFiltros();
    //this.fnGetSolicitudes();
    this.fngetSolicitud_Alta_Cte()
    this.fnGetCondicionPago()
    this.objAgenSuc = this.arrayAgentes.find(oc => oc.strAgente == this.strAgenteLS)
    console.log(this.objAgenSuc)
  }
  fnInitForm(){
    this.form = this.fb.group({
      nombreCte: ['', Validators.required],
      sucursal: ['', Validators.required],
      cp: ['', Validators.required],
      estado: ['', Validators.required],
      delegacion: ['', Validators.required],
      colonia: ['', Validators.required],
      strColoniaOtra: ['',[]],
      poblacion: ['', Validators.required],
      calle: ['', Validators.required],
      noExterior: ['', Validators.required],
      noInterior: ['', Validators.required],
      entreCalles: ['', Validators.required],
      encargado: ['', Validators.required],
      telefono: ['',  [Validators.minLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]],
      correo: ['',  [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})?$'), Validators.required]],
      vendedor: [this.objAgenSuc?.strNombre, Validators.required],
      almacen: ['', Validators.required],
      intAlmacen:['', Validators.required],
      strRuta:['', Validators.required],
    });

    this.formDomFiscal = this.fb.group({
      nombreCte: ['', Validators.required],
/*       sucursal: ['', Validators.required], */
      cp: ['', Validators.required],
      estado: ['', Validators.required],
      almacen: ['', Validators.required],
      intAlmacen:['', Validators.required],
      delegacion: ['', Validators.required],
      colonia: ['', Validators.required],
      poblacion: ['', Validators.required],
      calle: ['', Validators.required],
      noExterior: ['', Validators.required],
       entreCalles: ['', Validators.required],
    /*  encargado: ['', Validators.required],
      telefono: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      vendedor: [this.objAgenSuc?.strNombre, Validators.required], */
   
  /*     strRuta:['', Validators.required], */
    });
  }
  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      //    console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
    });

    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });

    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }
  fnVer_Editar_Solicitud(obj: any, tipo_accion: string) {
    obj.TipoCredito_Contado= parseFloat(obj.dblCreditoLimite) >0 || obj.strTipoSolicitud==    "CONTADO A CREDITO" || tipo_accion == 'ACTUALIZAR_CREDITO' ? 'CREDITO':'CONTADO';
    obj.strAccion = tipo_accion;
    obj.strOrigen = "/SolicitudAltaCte/";
    obj.strGerente = this.strGerente
    obj.strSucursal = this.strSucursal
    obj.strAgente = this.strAgenteLS;
    
    this._altaCteServ.setCte_alta(obj)

    this.router.navigate(['/altaCliente']); 
  }

  fngetSolicitud_Alta_Cte() {
    let obj = {
      strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""),
      strGerente: this.strGerente,
      strSucursal: this.strSucursal,
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo,
      strConexion: this.strConexion,
      strAccion: 'getSolicitud_Alta_Cte'
    };

    console.log(obj)
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio').subscribe(
      result => {
        console.log(result)
        this.arrayCotizaciones = result
        // Usar un Set para eliminar duplicados
        const clienteSet = new Set(this.arrayCotizaciones.map(cot => cot.strTipoSolicitud));
        this.arrayTipo = Array.from(clienteSet);
      },
      error => {
        this.spiner = false;
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });
  }
  fnGetDetalle(obj: any, tipo: number) {

  }
  fechasComoDate(cotizacion): Date {
    const fecha = moment(cotizacion.strFecha, 'MM/DD/YYYY hh:mm:ss');
    // Verificar si la fecha es válida antes de convertirla a Date
    if (fecha.isValid()) {
      return fecha.toDate();
    } else {
      console.warn('Fecha inválida:', cotizacion.strFecha);
      // Puedes devolver null o una fecha por defecto, según tus necesidades
      return cotizacion.strFecha;
    }
  }

  fnSetEstatusPlantilla(estatus: string, intIDMovimientoVenta: number, strObservaciones: string, strCliente: string) {
    //actualizamos los precios 
    this.fnUpatePreciosPlantilla(intIDMovimientoVenta)

    let xmlArticulos = []/* [{strArticulo:'BN',dblPrecio:20}] */
    this.arrayDetPlantillas.forEach(x => {
      xmlArticulos.push({ strArticulo: x.strArticulo, dblPrecio: x.dblPrecioSugerido })
    });
    let obj = {
      strVigencia: '',
      strCliente: strCliente,
      strEstatus: estatus,
      strAutorizo: this.localSt.retrieve('strUsuario'),
      strObservaciones: strObservaciones,
      intIDMovimientoVenta: intIDMovimientoVenta,
      strConexion: this.strConexion,
      xmlArticulos: xmlArticulos,
      strAccion: 'setEstatusPlantilla'
    }


    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == "200") {
          this._alertify.success(result[0].strMensaje)

          this.btnCerrarMdl.nativeElement.click();
        } else {
          this._alertify.error("Error en:" + result[0].strMensaje)
        }
        console.log(result);
      },
      error => {
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });
  }

  

  fnSetEstatusCotizacion(estatus: string, intIDMovimientoVenta: number, strObservaciones: string, strCliente: string) {
    //actualizamos los precios 
    this.fnUpatePreciosPlantilla(intIDMovimientoVenta)

    let xmlArticulos = []/* [{strArticulo:'BN',dblPrecio:20}] */
    this.arrayDetPlantillas.forEach(x => {
      xmlArticulos.push({ strArticulo: x.strArticulo, dblPrecio: x.dblPrecioSugerido })
    });
    let obj = {
      strVigencia: '',
      strCliente: strCliente,
      strEstatus: estatus,
      strAutorizo: this.localSt.retrieve('strUsuario'),
      strObservaciones: strObservaciones,
      intIDMovimientoVenta: intIDMovimientoVenta,
      strConexion: this.strConexion,
      xmlArticulos: xmlArticulos,
      strAccion: 'setEstatusPlantilla_Cotizacion_leads'
    }


    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == "200") {
          this._alertify.success(result[0].strMensaje)

          this.btnCerrarMdl.nativeElement.click();
        } else {
          this._alertify.error("Error en:" + result[0].strMensaje)
        }
        console.log(result);
      },
      error => {
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });
  }
  fnUpatePreciosPlantilla(intIDMovimientoVenta: number) {
    let xmlArticulos = []
    this.arrayDetPlantillas.forEach(x => {
      xmlArticulos.push({ strArticulo: x.strArticulo, dblPrecio: x.dblPrecioSugerido })
    });
    let obj = {
      intIDMovimientoVenta: intIDMovimientoVenta,
      strConexion: this.strConexion,
      strAccion: 'updatePreciosPlantilla',
      xmlArticulos: xmlArticulos
    }


    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == "200") {
          Swal.fire({
            icon: "success",
            title: "Actualizado Correctamente",
            text: result[0].strMensaje
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error al Actualizar",
            text: result[0].strMensaje
          });
        }
        console.log(result);
      },
      error => {
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });
  }
  fnSetCarrito(arrayArtis: any, intIDMovimientoVenta: number, objVenta: any, opcion: number) {
    //borramos carrito
    this.carroservice.deleteCarrito();
    console.log(arrayArtis)

    //preparamos los articulos
    arrayArtis.forEach(element => {
      let precio = opcion == 2 ? element.dblPrecioLista : element.dblPrecioSugerido;
      let preciop = opcion == 2 ? element.dblPrecioPlantilla : element.dblPrecioSugerido;
      let objArt = {
        boolEditaPlantilla: opcion == 2 ? true : false, checkbx: opcion == 2 ? true : false,
        dblCalificacion: 3,
        dblCantidad: element.dblCantidad,
        dblCostoPromedio: 0, dblCostoTeorico: 0,
        dblPrecio: 0, dblPrecio2: 0, dblPrecio3: 0, dblPrecio4: 0, dblPrecio10: 0,
        dblPrecioLista: precio, dblPrecioMinimo: 0,

        dblPrecioPlantilla: preciop, dblPrecioPolitica: element.dblPrecioPolitica, dblPrecioSugerido: element.dblPrecioSugerido,
        dblTipoCambio: 0, dblUltimoPrecio: 0, intEjercicio: 0,
        intIDMovimientoVenta: intIDMovimientoVenta, intIDSucursal: 0,
        intPeriodo: 0, intPrecio: 0, intPrecio2: element.dblPrecio2,
        intPrecio3: element.dblPrecio3, intPrecio4: element.dblPrecio4, intPrecioLista: element.dblPrecioLista,
        intPrecioPlantilla: preciop, intPrecioPolitica: 0, intTotalEstrellas: 2,
        pagina: 1, strArticulo: element.strArticulo, strCategoria: element.strCategoria,
        strComentario: "", strDescripcion1: element.strDescripcion1, strLinea: element.strLinea,
        strNombreOpcion: "", strOpcion: element.strOpcion, strTipo: element.strTipo,
        strTipoOpcion: element.strTipoOpcion, arrayOpciones: []


      }
      let mdP = opcion == 2 ? true : false;
      this.carroservice.setModoPlantilla(mdP);
      this.carroservice.setArticulo(objArt);
    });
    this._alertify.success("Articulo agregado correctamente");
    this.router.navigate(['/carritoresumen']);
    console.log(objVenta)
    //agregar cliente
    let alm = objVenta.strSucursal == '0' ? '1' : objVenta.strSucursal;
    let objCte = {
      ClienteSeleccionado: true,
      strAgente: objVenta.strAgente,
      strNombre: objVenta.strNombreCliente,
      strCliente: objVenta.strCliente,
      intIDSucursal: objVenta.intIDSucursal,
      strAlmacen: alm,
      strClaveUsoCFDI: objVenta.strClaveUsoCFDI,
      strCondicion: objVenta.strCondicion,
      strDescripcionUsoCFDI: objVenta.strDescripcionUsoCFDI,
      strDireccion: objVenta.strDireccion,
      strInfopago: objVenta.strInfopago,
      strInfopagoDescripcion: objVenta.strInfopagoDescripcion,
      strNombreSucursal: "",
      strSucursal: "0",
      strTipo: "CLIENTE"
    }
    console.log(objCte)
    this.carroservice.setCte(objCte);
  }

  fnEliminarPartida(intIDMovimientoVenta: number, art: any) {

    let obj = {
      intIDMovimientoVenta: intIDMovimientoVenta,
      strArticuloBusqueda: art.strArticulo,
      strObservaciones: art.strOpcion,
      strConexion: this.strConexion,
      strAccion: 'deletePlantilla_Art'
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == "200") {
          this._alertify.success(result[0].strMensaje)
        } else {
          this._alertify.error("Error en:" + result[0].strMensaje)
        }

      },
      error => {
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });
  }
  fnReenviarSolicitud(intIDMovimientoVenta: number) {

    let arrayEnviar = [];
    this.arrayDetPlantillas.forEach(element => {
      let objEnv = { strArticulo: element.strArticulo, strOpcion: element.strOpcion, dblPrecio: element.dblPrecioSugerido, dblCantidad: element.dblCantidad }
      arrayEnviar.push(objEnv);
    });
    console.log(arrayEnviar);
    let obj = {
      intIDMovimientoVenta: intIDMovimientoVenta,
      xmlArticulos: arrayEnviar,
      strConexion: this.strConexion,
      strAccion: 'setTerminarEdicion_Plantilla'
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == "200") {
          this._alertify.success(result[0].strMensaje)
          //this.fnGetSolicitudes();
          this.btnCerrarMdl.nativeElement.click();
        } else {
          this._alertify.error("Error en:" + result[0].strMensaje)
        }

      },
      error => {
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });
  }
  fnEliminarlst(objVenta: any) {
    this.arrayDetPlantillas = [];
    this.objCte = objVenta;
    let obj = {
      intIDMovimientoVenta: objVenta.intIDMovimientoVenta,
      strConexion: this.strConexion,
      strAccion: 'deletePlantilla'
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        console.log(result)
        if (result[0].code == "200") {
          Swal.fire({
            icon: "success",
            title: "Eliminado Correctamente",
            text: result[0].strMensaje
          });
          //  this.fnGetSolicitudes()
        } else {
          Swal.fire({
            icon: "error",
            title: "Error al Eliminar",
            text: result[0].strMensaje
          });
        }
      }, error => {
        console.error('Error al consumir la API:', error);
        this.spinerMdl = false;
        // Puedes manejar el error de alguna manera específica aquí
      });
  }

  fnEnviarAltaCliente() {
    this.router.navigate(['/altaCliente/']);
  }

  fnGetCondicionPago() {
    this._httpService.mostrarCotizaciones({
      strAccion: 'getCondicionCredito',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
        this.arrayCondicionPago = result;
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetCte_Update() {
    this._httpService.mostrarCotizaciones({
      strAccion: 'getCte_Update',
      strCliente: this.strCliente,
      strGerente: this.strGerente,//se toma de los accesos declarados en el constructor
      strSucursal: this.strSucursal, //se toma de los accesos declarados en el constructor
      strAgente: this.strAgente, //se toma de los accesos declarados en el constructor
      strConexion: this.strConexion
    }, 'sp_ModVentas_Directorio').subscribe(
      result => {
        if(result[0].code == 200){
          this.banderaBotonEnviarSolcitud = true
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.arrayActualizacionCredito = result[0]
         this.strNombreComercial = result[0].strNombreComercial
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
          this.arrayActualizacionCredito = []
          this.banderaBotonEnviarSolcitud = false
          this.strCliente = ''
        }
        
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnset_ACTUALIZACION_CREDITO() {
    this._httpService.mostrarCotizaciones({
      strAccion: 'set_ACTUALIZACION_CREDITO',
      strCliente: this.strCliente,
      dblCreditoLimite: this.dblLimiteCreditoActualizado,
      strCondicion: this.strCondicionModificada,
      strAgente: (this.strAgente != '')? this.strAgente: this.strAgenteLS, // si el agente es vacio, tomarlo de local storage
      strNombreComercial: this.strNombreComercial,
      strSucursal: this.strSucursal,
      strUrl_Pagare: this.strUrl_Pagare,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Directorio').subscribe(
      result => {
        if(result[0].code == 200){
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.dblLimiteCreditoActualizado = ''
          this.strCondicionModificada = ''
          this.strNombreComercial = ''
          this.strUrl_Pagare = ''

        }else{
          Swal.fire({
            position: "center",
            icon: "error",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
        }
       
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  compressFile(event: any) {

    this.strUrl_Pagare = 'Adjuntos/AltaCteCredito/Pagare/' + event.target.files[0].name
    
    this.formData.append('files[]', event.target.files[0], event.target.files[0].name);
    this.formData.append('inputIdentifier[]', 'AltaCteCredito/Pagare/');
  
  }

  subirDocumento() {
    
    
    this._httpService.uploadPagare('https://www.ecodeli.com/anexoAltaCliente.php', this.formData).subscribe(
      (response) => {

        this.arrayArchivos = response;
        if (this.arrayArchivos[0].code == "200") {
          this.fnset_ACTUALIZACION_CREDITO(); // cuando devuelve 200 puedes enviar a guardar a la base de datos
        } else {
          Swal.fire("Error", "Error al subir anexo" + this.arrayArchivos.message, "error")
        }
      }, (error) => {
        Swal.fire("Error", "No se recibio respuesta del servidor", "error")
        console.log(error.message)

      })
  }

  fngetDetalleSolicitud(idSolicitud: any) {
    this._httpService.mostrarCotizaciones({
      strAccion: 'getDetalle_Solicitu_Actualizacion',
      intID: idSolicitud,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Directorio').subscribe(
      result => {
        console.log(result)
        this.objDetalleSolcitud = result
        
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnLimpiarObjDetalleSolicitud(){
    this.objDetalleSolcitud = []
  }

  onSubmit() {
    let objCol =this.form.controls['colonia'].value == "otra"?this.arrayColonias[0] : this.arrayColonias.find(o => o.strColonia == this.form.controls['colonia'].value)
    
   
    if (this.form.valid) {
      let env = {
        strConexion:this.strConexion,
        strAccion: 'setSucursal_Cte',
        strCliente: this.strCte,
        strNombreReferencia2:  this.form.controls['sucursal'].value,
        strDireccion: this.form.controls['calle'].value,
        strDireccionNumero: this.form.controls['noExterior'].value,
        strDireccionInt: this.form.controls['noInterior'].value,
        strEntreCalles: this.form.controls['entreCalles'].value,
        strColonia: this.form.controls['colonia'].value == "otra"?this.form.controls['strColoniaOtra'].value:this.form.controls['colonia'].value,
        strDelegacion: this.form.controls['delegacion'].value,
        strPoblacion: this.form.controls['poblacion'].value,
        strEstado: this.form.controls['estado'].value,
        strPais: this.strPais,
        strRuta: this.form.controls['strRuta'].value,
        strCodigoPostal: this.form.controls['cp'].value,
        strTelefono: this.form.controls['telefono'].value,
        strContacto1: this.form.controls['encargado'].value,
        strTelefono1: "",
        streMail1: this.form.controls['correo'].value,
        strAgente: this.objAgenSuc.strAgente,
        strAlmacenDef: objCol.strAlmacen,
        intSucursalEmpresa:  this.arrayAccesos[0].sucursal  // objCol.strAlmacen == "1" ? "0":objCol.strAlmacen,
      };
      console.log(env);
      this._httpService.mostrarCotizaciones(env,"sp_ModVentas_Directorio").subscribe(
        result => {
          Swal.fire(result[0].code=="200"?"Guardado":"Error",result[0].strMensaje, result[0].code=="200"?"success":"error")
          if(result[0].code=="200"){
            this.btnAltaSuc.nativeElement.click()
          }
         console.log(result)
        },error =>{

        })

    }



  }
  onSelectColonias() {
    console.log(this.form.controls['colonia'].value)
    if(this.form.controls['colonia'].value == "otra"){
      this.form.get('strColoniaOtra').setValidators([Validators.required]);
    }else{
      this.form.get('strColoniaOtra').clearValidators();
    }
    this.form.get('strColoniaOtra').updateValueAndValidity();
  }
  fnMdlAltaSuc(){
    this.strCte ="";
    this.boolCteExiste = false;
    this.fnInitForm();
  }


  fnValidarSuc(){
    let obj = {
      strCliente:this.strCte,/* this.form.controls['strNombreComercial'].value */
      strAgente:"",
      strGerente:"",
      strSucursal:"",
      strAccion:"getCte_Sucursal",
      strConexion :this.strConexion
    }
    if(this.strAgente!="" && this.strAgente != null){
      obj.strAgente= this.strAgente 
      obj.strSucursal=""
      obj.strGerente=""
    }else if(this.strSucursal!=""&& this.strSucursal != null){
      obj.strAgente= ""
      obj.strSucursal=this.strSucursal
      obj.strGerente=""
    }else if(this.strGerente!=""&& this.strGerente != null){
      obj.strAgente= ""
      obj.strSucursal=""
      obj.strGerente=this.strGerente
    }
    this._httpService.mostrarCotizaciones(obj,"sp_ModVentas_Directorio").subscribe(
      result  =>{
        let rest = result[0];
        if (rest.code == "200") {
          this.boolCteExiste = true;
          this.objClienteValid = result[0];
          this.form.controls['nombreCte'].setValue(rest.strNombreComercial);
          this.formDomFiscal.controls['nombreCte'].setValue(rest.strNombreComercial);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error al Actualizar",
            text: result[0].strMensaje
          });
          this.boolCteExiste = false;
        }
        console.log(result)
      }, error=>{
        this.boolCteExiste = false;
        console.log(error.message)
      }
    )
  }

  fnGetDatosFiscales(numb:number=0) {
    this._httpService.mostrarCotizaciones({
      strCodigoPostal:numb==1? this.formDomFiscal.controls['cp'].value: this.form.controls['cp'].value,
      strAccion:'getCodigoPostal',
      strConexion:this.strConexion
    },"sp_ModVentas_Directorio").subscribe(
      result => {
        if (result.length > 0) {
          console.log(numb)
          if(numb == 1){
            this.formDomFiscal.controls['estado'].setValue(result[0].strEstado);
            this.formDomFiscal.controls['delegacion'].setValue(result[0].strDelegacion);
            this.formDomFiscal.controls['poblacion'].setValue(result[0].strPoblacion);
            this.formDomFiscal.controls['almacen'].setValue(result[0].strNombre);
            this.formDomFiscal.controls['intAlmacen'].setValue(result[0].strAlmacen);
          
          }else{
            this.form.controls['estado'].setValue(result[0].strEstado);
            this.form.controls['delegacion'].setValue(result[0].strDelegacion);
            this.form.controls['poblacion'].setValue(result[0].strPoblacion);
            this.form.controls['almacen'].setValue(result[0].strNombre);
            this.form.controls['intAlmacen'].setValue(result[0].strAlmacen);
            this.form.controls['strRuta'].setValue(result[0].strRuta);
          }
           

            this.arrayColonias = result;
            console.log(this.arrayColonias);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Codigo Postal Incorrecto!',
          });
          if(numb == 1){
            this.formDomFiscal.controls['estado'].setValue("");
            this.formDomFiscal.controls['delegacion'].setValue("");
            this.formDomFiscal.controls['poblacion'].setValue("");
            this.formDomFiscal.controls['almacen'].setValue("");
            this.formDomFiscal.controls['intAlmacen'].setValue("");
          
          }else{
            this.form.controls['estado'].setValue("");
            this.form.controls['delegacion'].setValue("");
            this.form.controls['poblacion'].setValue("");
            this.form.controls['almacen'].setValue("");
            this.form.controls['intAlmacen'].setValue("");
            this.form.controls['strRuta'].setValue("");
          }
           
          this.arrayColonias = []
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  onInput(event: Event) {
    this.form.controls['estado'].setValue('');
    this.form.controls['delegacion'].setValue('');
    this.form.controls['poblacion'].setValue('');
    this.form.controls['almacen'].setValue('');
    this.form.controls['intAlmacen'].setValue('');
    this.form.controls['strRuta'].setValue('');
    
  }
  onInput2(event: Event) {
    this.form.controls['nombreCte'].setValue('');
    this.boolCteExiste = false;
  
  }
  fnClickFile(){
    this.fileIvaSat.nativeElement.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.nameFileIvaSat = input.files[0];
      if (this.nameFileIvaSat.size > 3 * 1024 * 1024) {
        Swal.fire({
          title: "El documento el límite de tamaño permitido, que es de 3 megabytes.",
          text: "ERROR",
          icon: "error"
        });
        this.nameFileIvaSat = null
      } else {
        console.log('Archivo seleccionado:', this.nameFileIvaSat);
      }
 
    } else {
      this.nameFileIvaSat = null;
      console.log('No se ha seleccionado ningún archivo.');
    }
  }

  fnEnviarDoc(){
    const formData = new FormData();
    formData.append('files[]', this.nameFileIvaSat, this.nameFileIvaSat.name);
    formData.append('inputIdentifier[]', 'IVAFronteriza/');
    this._httpService.uploadFiles(formData).subscribe(
      (response) => {
        console.log(response);
        let rest = response[0]
        if(rest.code == "200"){
          let objEnv= {
            strAccion:'setSolicitud_iVA_FRONTERIZO',
            strCliente:this.strCte,
            strAgente:this.objAgenSuc.strAgente, 
            strNombre:this.objClienteValid.strNombreComercial,
            strUrl_IVAFronterizo:rest.ruta_archivo,
            intSucursalEmpresa:this.objAgenSuc.strSucursal,
            strConexion:this.strConexion
          }
          this._httpService.mostrarCotizaciones(objEnv,"sp_ModVentas_Directorio").subscribe(
            (result) => {
              Swal.fire(result[0].code=="200"?"Guardado":"Error",result[0].strMensaje, result[0].code=="200"?"success":"error")
              console.log(result);
              if(result[0].code=="200"){
                this.strCte ="";
                this.boolCteExiste = false;
                this.nameFileIvaSat = null;
                this.fileIvaSat.nativeElement.value = '';
                this.objClienteValid = {};
                this.btnDocIVa.nativeElement.click();
              }
            });
        }
      });
  }
  fnAbrirCambDomicilio(){
    this.strSitFiscal ="Subir Archivo"
    this.strDomicilio ="Subir Archivo"
    this.boolSitFiscal = false
    this.boolDomicilio = false
    this.docSitFiscal = null
    this.docDomicilio = null
    this.fileDomicilio.nativeElement.value = '';
    this.fileSitFiscal.nativeElement.value = '';
  }

  fnClickDomicilio(){
    this.fileDomicilio.nativeElement.click()
  }

  fnClickSitfsical(){
    this.fileSitFiscal.nativeElement.click()
  }

  onFileDimicilio(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.docDomicilio = input.files[0];
      if (this.docDomicilio.size > 3 * 1024 * 1024) {
        Swal.fire({
          title: "El documento el límite de tamaño permitido, que es de 3 megabytes.",
          text: "ERROR",
          icon: "error"
        });
        this.docDomicilio = null
      } else {
        const formData1 = new FormData();
        formData1.append('files[]', this.docDomicilio, this.docDomicilio.name);
        formData1.append('inputIdentifier[]', 'AltaCteCredito/ComprobanteDomicilio/');
        this._httpService.uploadFiles(formData1).subscribe(
          (response) => {
            if(response[0].code == "200"){
              this.boolDomicilio =true
              this.strDomicilio = this.docDomicilio.name
              this.objDocs.objectDom=response[0]
             }else{
                this.boolDomicilio =false
                this.strDomicilio ="Subir Archivo"
                this.objDocs.objectDom={}
             }
          });
        console.log('Archivo seleccionado:', this.docDomicilio);
      }
    } else {
      this.docDomicilio = null;
      console.log('No se ha seleccionado ningún archivo.');
    }
  }

  onFileSitFiscal(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.docSitFiscal = input.files[0];
      if (this.docSitFiscal.size > 3 * 1024 * 1024) {
        Swal.fire({
          title: "El documento el límite de tamaño permitido, que es de 3 megabytes.",
          text: "ERROR",
          icon: "error"
        });
        this.docSitFiscal = null
      } else {
        const formData2 = new FormData();
        formData2.append('files[]', this.docSitFiscal, this.docSitFiscal.name);
        formData2.append('inputIdentifier[]', 'AltaCteCredito/CedulaFiscal/');
        this._httpService.uploadFiles(formData2).subscribe(
          (response) => {
            console.log(response);
            if(response[0].code == "200"){
             this.boolSitFiscal =  true;
             this.strSitFiscal = this.docSitFiscal.name;
             this.objDocs.objectSit = response[0];
            }else{
              this.boolSitFiscal = false;
              this.strSitFiscal ="Subir Archivo";
              this.objDocs.objectSit={}
            }
          });
        console.log('Archivo seleccionado:', this.docSitFiscal);
      }
    } else {
      this.docSitFiscal = null;
      console.log('No se ha seleccionado ningún archivo.');
    }
  }

  fnEnviarSolDomicilio(){
    let objEnviar={
      strConexion:this.strConexion,
      strAccion:"setSolicitud_CambioDomicilio_Fiscal",
      strCliente:this.strCte
,strDireccion:this.formDomFiscal.controls['calle'].value
,strDireccionNumero:this.formDomFiscal.controls['noExterior'].value
 ,strEntreCalles:this.formDomFiscal.controls['entreCalles'].value
 ,strDelegacion:this.formDomFiscal.controls['delegacion'].value
,strColonia:this.formDomFiscal.controls['colonia'].value
 ,strEstado:this.formDomFiscal.controls['estado'].value
 ,strCodigoPostal:this.formDomFiscal.controls['cp'].value
,strUrl_CedulaFiscal:this.objDocs.objectSit.ruta_archivo
 ,strUrl_CDomicilio: this.objDocs.objectDom.ruta_archivo
,strAgente:this.objAgenSuc.strAgente
 ,strAlmacenDef:this.formDomFiscal.controls['intAlmacen'].value
,intSucursalEmpresa:this.objAgenSuc.strSucursal == "1"?"0":this.objAgenSuc.strSucursal,
    }
    this._httpService.mostrarCotizaciones(objEnviar,"sp_ModVentas_Directorio").subscribe(
      (result) => {
        Swal.fire(result[0].code=="200"?"Guardado":"Error",result[0].strMensaje, result[0].code=="200"?"success":"error")
        console.log(result);
        if(result[0].code=="200"){
          this.strCte ="";
          this.boolCteExiste = false;
          this.objClienteValid = {};
          this.strSitFiscal ="Subir Archivo"
          this.strDomicilio ="Subir Archivo"
          this.boolSitFiscal = false
          this.boolDomicilio = false
          this.docSitFiscal = null
          this.docDomicilio = null
          this.fileDomicilio.nativeElement.value = '';
          this.fileSitFiscal.nativeElement.value = '';
          this.btnMdlDomicilio.nativeElement.click();
        }
      });
    console.log(objEnviar)
  }

}
