<div class="faq-container mt-2">
    <h2>Preguntas Frecuentes</h2>
  
    <div *ngFor="let faq of faqs; let i = index" class="faq-item">
      <div class="faq-question" (click)="toggleAnswer(i)">
        <h3>{{ faq.question }}</h3>
        <span *ngIf="activeIndex === i" class="toggle-icon">-</span>
        <span *ngIf="activeIndex !== i" class="toggle-icon">+</span>
      </div>
      <div *ngIf="activeIndex === i" class="faq-answer">
        <p>{{ faq.answer }}</p>
      </div>
    </div>
  </div>
