import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenStateService {

  private destroyed = new Subject<void>();

  // Estado de los breakpoints
  isXSmall = false;
  isSmall = false;
  isMedium = false;
  isLarge = false;
  isXLarge = false;

  private displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.initializeBreakpoints();
  }

  private initializeBreakpoints(): void {
    this.breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ])
    .pipe(takeUntil(this.destroyed))
    .subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          const breakpointName = this.displayNameMap.get(query);
          this.resetBreakpointFlags();
          this.setActiveBreakpointFlag(breakpointName);
        }
      }
    });
  }

  private resetBreakpointFlags(): void {
    this.isXSmall = this.isSmall = this.isMedium = this.isLarge = this.isXLarge = false;
  }

  private setActiveBreakpointFlag(breakpointName: string): void {
    switch (breakpointName) {
      case 'XSmall':
        this.isXSmall = true;
        break;
      case 'Small':
        this.isSmall = true;
        break;
      case 'Medium':
        this.isMedium = true;
        break;
      case 'Large':
        this.isLarge = true;
        break;
      case 'XLarge':
        this.isXLarge = true;
        break;
    }
  }

  // Método para limpiar los observables
  destroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
