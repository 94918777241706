import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FiltrosService } from '../servicios/filtros.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarOptions } from '@fullcalendar/core';
import Swal from 'sweetalert2';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import multiMonthPlugin from '@fullcalendar/multimonth'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import * as XLSX from 'xlsx';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import { MatTableDataSource } from '@angular/material/table';
import { PeriodicElement } from '../reportecomodatos/reportecomodatos.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AltaClienteService } from '../servicios/alta-cliente.service';
declare var $: any;
@Component({
  selector: 'app-prospecto',
  templateUrl: './prospecto.component.html',
  styleUrls: ['./prospecto.component.css']
})
export class ProspectoComponent implements OnInit {
  displayedColumns: string[] = ['#', 'Imagen', 'strArticulo', 'strDescripcion', 'intCotizaciones', 'dblCantidad'];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild('summernote', { static: true }) summernote: any;
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;
  public intTotal_propuesta: any = 0
  public intTotalServiciosPRO: any = 0
  public intTotalMaterialesPRO: any = 0
  public dblMontoPropuesta: any = 0.0
  public arrayPropuesta: any = []
  public formLead: FormGroup;
  public datosFiltradosPropuesta: any = []
  public elementosPEProspecto: any = 0
  public esDispositivoPequeno: any
  public filterPost: any = ''
  public intTotal_negociacion: any = 0
  public intTotalServiciosNEG: any = 0
  public intTotalMaterialesNEG: any = 0
  public dblMontoNegociacion: any = 0.0
  public intFlagAvanza2: any
  public arrayNegociacion: any = []
  public maxLengthComentario: number = 100;
  public datosFiltradosNegociacion: any = []
  public elementosNProspecto: any = 0
  public intTotal_cierre: any = 0
  public intTotalServiciosCIERRE: any = 0
  public intValidacionFamilia: any
  public intTotalMaterialesCIERRE: any = 0
  public dblMontoCierre: any = 0
  public arrayCierre: any = []
  public datosFiltradosCierre: any = []
  public elementosCProspecto: any = 0
  public strTipo: any = 'prospecto'
  public global: any = 12000000
  public intEjercicio: any
  public arrayEjercicio: any = []
  public activeButton: any = 'cards'
  public banderaCards: any = true
  public banderaLista: any = false
  public banderaCalendario: any = false
  public datosFiltradosContactar: any = []
  public calendarOptions: any; @ViewChild('calendar', { static: true }) calendar: any;
  public strNombreEmpresa: any
  public arrayCotizaciones: any = []
  public diferenciaDias: any
  public diferenciaHoras: any
  public diferenciaMinutos: any
  public banderaGanado: any
  public banderaEspera: any = true
  public banderaCancelado: any
  public banderaPerdido: any
  public objCliente: any = []
  public objAlta: any = []
  public opcionSeleccionada: string = 'espera'
  public strFechaActividad: any
  public intDuracionActividad: any
  public intTiempoDias: any = 0; public intTiempoHoras: any = 0
  public strComentario: any
  public strTipoActividad: any
  public strResultado: any = ''
  public strFechaInicioServicio: any
  public fechaInicioServicio: any
  public strMotivoPerdido: any
  public banderaFuturo: any
  public strFechaEstimadaCierreInput: any
  public strFechaPerdido: any
  public strComentarioPerdido: any
  public strMotivoCancelado: any
  public strComentarioCancelado: any
  public strCodigoPostal: any
  public strEtapa: any
  public dblImporte: any
  public strRazonSocial: any
  public strRFC: any
  public strCalle: any
  public strColonia: any
  public strSitioWeb: any
  public intTotalSucursales: any
  public arrayActividades: any = []
  public strDescripcionActividad: any
  public strActividadSimple: any
  public intIDPeritaje: any
  public strCoordinadorOperativo: any
  public strEstado: any
  public strMedioConocio: any
  public strFase: any = 'PROSPECTO'
  public banderaFase: any
  public strSector: any
  public intGiro: any
  public arrayGiros: any = []
  public strFamilia: any
  public arrayLisFamilia: any = []
  public banderaCierre: any
  public strNumeroEmpleados: any
  public strPrioridad: any
  public arrayTamanoEmpresa: any = []
  public intCantidadElementos: any
  public strFechaEstimadaCierre: any
  public isDropdownOpen = false;
  public strTipoCotizacion: any
  public strRecomendacion: any
  public arrayContactosDetalle: any
  public banderaContacto: any
  public strTelefonoContacto: any
  public arrayAnexosUpdate: any = []
  public strTipoAnexo: any = ''
  public arrayAnexos: any = []
  public ListaAnexos: any = []
  public panelOpenState: any
  public calendarioLeads: any = []
  public intIDLeads: any;
  public strFechaCancelado: any
  public arrayPresencia: any = []
  public spinner: boolean = false;
  public spinnerN: boolean = false;
  public spinnerC: boolean = false;
  public arrayArticulos: any = []
  public excelData: any = []
  public excelDataAux: any = []
  public arrayTipoCotizacion: any = []
  public arrayCotizacionesAvanzar: any = []
  checkboxes: any = {
    hoy: false,
    manana: false,
    estaSemana: false,
    esteMes: false,
    futuros: false,
    conDemora: false,
  };
  public strAgenteLD: any
  public intID_Familia: any
  public arrayFamilias: any = []
  opciones = [
    { valor: 'llamada', icono: 'fas fa-phone-alt' },
    { valor: 'correo', icono: 'fas fa-envelope' },
    { valor: 'visita', icono: 'fas fa-walking' },
  ];
  public strTieneServicio: any

  public intIDUbicacion: any = 0
  public strClasificacion: any = ''
  public boolGerente: any = true
  public strGerente: any
  public arrayGerentes: any = []
  public strSucursal: any
  public arraySucursales: any = []
  public strAgente: any
  public arrayAgentes: any = []
  public intPeriodo: any
  public arrayPeriodo: any = []
  public intFlagActividad: any
  public isHoveredButton: any = true
  public boolSucursal: any = true
  public boolAgente: any = true
  public strNumeroExterior: any
  public strDelegacion: any
  public strEntreCalles: any
  public arrayColonias: any
  public strLadaEmpresa: any
  public strTelefonoEmpresa: any
  public strZona: any
  public maxTelefono: number = 10
  public arrayZonas: any = []
  public banderaColonia: any
  public intIDCotizacion: any
  public strEstatusCotizacion: any = ''
  public strOtraColonia: any
  public arrayTotalLeads: any = []
  public strConexion: any = ''
  public strAgenteSP: any = ''
  public arrayCalendario: any = []
  public intId_Contacto: any = 0
  public intIDPresencia: any = 0
  public intId_Leads_Relacion: any = 0
  public intId_Direccion: any = 0
  public intIDCliente: any
  public intid_Cliente: any = 0
  public arrayUbicaciones: any = []
  public arrayAccesos: any = [];
  public nameArchivo: any = ''
  public tagName: any;
  public strNombreAgente: any = ''
  public intFlagAvanza1: any
  public intFlagAvanza4: any = true
  public intFlagAvanza5: any = true
  constructor(private elementRef: ElementRef, private formbuilder: FormBuilder, public _alertify: AlertifyService, private router: Router, elem: ElementRef, private carroservice: CarroService, private _filtroServ: FiltrosService, public _serviciosService: PeticionesService, public localSt: LocalStorageService, private alta_cliente: AltaClienteService) {
    this.arrayTipoCotizacion = [
      {strTipoCotizacion : 'Materiales', Seleccionado: false},
      {strTipoCotizacion : 'Servicios especiales', Seleccionado: false},
      {strTipoCotizacion : 'Cotizacion Rapida', Seleccionado: false},
      {strTipoCotizacion : 'Cotizacion por TYM', Seleccionado: false},
      {strTipoCotizacion : 'Licitaciones', Seleccionado: false},
      {strTipoCotizacion : 'Estudio de Mercado', Seleccionado: false}
    ]
   
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgenteSP = this.localSt.retrieve('strAgente');
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos') ? this.localSt.retrieve('arrayAccesos') : [];


    this.formLead = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.required]),
      strSector: new FormControl('', [Validators.required]),
      intGiro: new FormControl('', [Validators.required]),
      intIDPresencia: new FormControl('', [Validators.required]),
      intID_Familia: new FormControl(0, []),
      strNumeroEmpleados: new FormControl('', [Validators.required]),
      strRazonSocial: new FormControl('', [Validators.required]),
      strRFC: new FormControl('', [Validators.required]),
      strTipoCotizacion: new FormControl('', [Validators.required]),
      strMedioConocio: new FormControl('VENDEDOR', [Validators.required]),
      strTieneServicio: new FormControl('', [Validators.required]),
      intIDUbicacion: new FormControl('', [Validators.required]),
    })


    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;

        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        // this.strSucursal_modal=this.strSucursal;


        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;

        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');

        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
        //this.strGerente_modal=this.strGerente;
      }
      //validamos para inicializar los filtros
      /*  console.log(this.localSt.retrieve('strUsuario'))
       console.log(this.strGerente);
       console.log(this.strSucursal);
       console.log(this.strAgente); */
    }

    this.arrayContactosDetalle = [{
      strNombreContacto: '',
      strContactoPuesto: '',
      strContactoTelefono: '',
      strContactoEmail: '',
      emailEsObligatorio: false
    }]

    this.arrayArticulos = [
      {
        numero: 1,
        Imagen: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlKThWY1yJKUhTvzRgCJZ3daqrAUDBlBhwrCcv4OobsQ&s',
        Articulo: '(01254) Bolda Ecodeli',
        cotizaciones: 3,
        cantidad: 6
      }
    ]

    this.dataSource = this.arrayArticulos
  }

  /*  ngAfterViewInit() {
     $(this.summernote.nativeElement).summernote({
       placeholder: 'Hello Bootstrap 5',
       tabsize: 2,
       height: 150,
       toolbar: [
         ['style', ['style']],
         ['font', ['bold', 'underline', 'clear']],
         ['color', ['color']],
         ['para', ['ul', 'ol', 'paragraph']],
         ['table', ['table']],
         ['insert', ['link', 'picture', 'video']],
         ['view', ['fullscreen', 'codeview', 'help']]
       ]
     });
   } */

  ngOnInit(): void {
    this.fnGetFiltros();
    this.fnGetPropuesta()
    this.fngetGiros();

    this.fngetRangoEmpleados();
    this.fngetPresencia();
    this.fngetUbicaciones();
    this.fnGetFamilias()

    document.addEventListener('mousedown', this.onDocumentClick.bind(this));
  }

  fnGetPropuesta() {
    this.spinner = true;
    this.spinnerN = true;
    this.spinnerC = true
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'PROPUESTA ECONOMICA',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayPropuesta = result
        this.intTotal_propuesta = this.arrayPropuesta.length
        this.arrayCalendario = this.arrayPropuesta.filter((b: any) => b.strFechaActividad)
        this.spinner = false;
        this.fnGetNegociacion()
      },
      error => {
        var error = <any>error;
        console.log(error);
        this.fnGetNegociacion()
      }
    );
  }

  fnGetAnexos(id: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getAnexos',
      intIdLeads: id,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayAnexos = result
      },
      error => {
        var error = <any>error;
        console.log(error);

      }
    );
  }

  fnGetNegociacion() {
    this.arrayNegociacion = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'NEGOCIACION',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayNegociacion = result
        this.intTotal_negociacion = this.arrayNegociacion.length
        this.arrayCalendario.push(...this.arrayNegociacion.filter((b: any) => b.strFechaActividad));
        this.spinnerN = false
        this.fnGetCierre()
      },
      error => {
        var error = <any>error;
        console.log(error);
        this.fnGetCierre()
      }
    );
  }

  fnGetCierre() {
    this.arrayCierre = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLeads_estatus',
      strEstatus: 'CIERRE',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayCierre = result
        this.intTotal_cierre = this.arrayCierre.length
        this.arrayCalendario.push(...this.arrayCierre.filter((b: any) => b.strFechaActividad));

        this.arrayCalendario.forEach((b: any) => {

          let fecha = b.strFechaActividad.split(' ')
          let fechaStart = fecha[0].split('/')[2] + '-' + fecha[0].split('/')[0] + '-' + fecha[0].split('/')[1]

          let datosCalendario = {
            title: b.strNombreEmpresa,
            start: fechaStart,
            description: b.strTipo,
            intIdLeads: b.intIdLeads,
            strFase: b.strFase
          }
          this.calendarioLeads.push(datosCalendario)

        })

        const calendarOptions: CalendarOptions = {
          initialView: 'multiMonthYear',
          contentHeight: 700,
          plugins: [dayGridPlugin, timeGridPlugin, multiMonthPlugin],
          locale: esLocale,
          themeSystem: 'bootstrap5',
          events: this.calendarioLeads,
          eventClick: (arg) => {
            // this.fnDetalleLead(arg.event.extendedProps['intIdLeads'])
          },

          eventDidMount: function (info) {
            const eventElement = info.el;
            eventElement.setAttribute('data-bs-toggle', 'tooltip');
            eventElement.setAttribute('data-bs-placement', 'center');
            eventElement.setAttribute('title', info.event.extendedProps['description']);
          },
          dayMaxEventRows: true,
          views: {
            Año: {
              type: 'multiMonth',
              duration: { months: 12 },
              buttonText: 'Año'
            },
            timeGridMonth: {
              type: 'timeGrid',
              duration: { months: 1 },
              dayMaxEventRows: 3,
              buttonText: 'Mes'
            },
            timeGridWeek: {
              type: 'timeGrid',
              duration: { weeks: 1 },
              buttonText: 'Semana'
            },
            timeGridDay: {
              type: 'timeGrid',
              duration: { days: 1 },
              buttonText: 'Día'
            }
          },
          headerToolbar: {
            start: 'prev,Año,dayGridMonth,timeGridWeek,timeGridDay',
            center: 'title',
            end: 'next'
          }
        };

        this.calendarOptions = calendarOptions

        this.spinnerC = false
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnupdate_Actividad(item: any) {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad',
      intID_Actividad: item.intID_Actividad,
      strResultado: this.strResultado,
      strComentario: this.strComentario,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == '200') {
          this._alertify.success('Guardado Correctamente');
          this.fngetActividades(this.intIDLeads)
          this.strResultado = ''
          this.strComentario = ''

        } else {

        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });

    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  cambiarVista(vista: any) {
    this.activeButton = vista
    if (vista == 'cards') {
      this.banderaCards = true
      this.banderaLista = false
      this.banderaCalendario = false
    } else if (vista == 'lista') {
      this.banderaCards = false
      this.banderaLista = true
      this.banderaCalendario = false
    } else {
      this.banderaCards = false
      this.banderaLista = false
      this.banderaCalendario = true
    }
  }
  onCheckboxChange(checkboxName: string) {
    Object.keys(this.checkboxes).forEach((name) => {
      if (name !== checkboxName) {
        this.checkboxes[name] = false;
      }
    });

    this.checkboxes[checkboxName] = !this.checkboxes[checkboxName];

    this.aplicarFiltros();
  }

  aplicarFiltros() {

    this.datosFiltradosPropuesta = this.arrayPropuesta.filter((dato: any) => {
      const fecha = this.parseFecha(dato.strFechaActividad);
      const hoy = new Date();
      const manana = new Date(hoy);
      manana.setDate(manana.getDate() + 1);
      return (
        (!this.checkboxes.hoy || this.esMismaFecha(fecha, hoy)) &&
        (!this.checkboxes.manana || this.esMismaFecha(fecha, manana)) &&
        (!this.checkboxes.estaSemana || this.estasEnLaSemana(fecha, hoy)) &&
        (!this.checkboxes.esteMes || this.estasEnElMes(fecha, hoy)) &&
        (!this.checkboxes.futuros || this.esFuturo(fecha, hoy)) &&
        (!this.checkboxes.conDemora || this.esDemora(fecha, hoy))
      );
    });
    this.datosFiltradosNegociacion = this.arrayNegociacion.filter((dato: any) => {
      const fecha = this.parseFecha(dato.strFechaActividad);
      const hoy = new Date();
      const manana = new Date(hoy);
      manana.setDate(manana.getDate() + 1);
      return (
        (!this.checkboxes.hoy || this.esMismaFecha(fecha, hoy)) &&
        (!this.checkboxes.manana || this.esMismaFecha(fecha, manana)) &&
        (!this.checkboxes.estaSemana || this.estasEnLaSemana(fecha, hoy)) &&
        (!this.checkboxes.esteMes || this.estasEnElMes(fecha, hoy)) &&
        (!this.checkboxes.futuros || this.esFuturo(fecha, hoy)) &&
        (!this.checkboxes.conDemora || this.esDemora(fecha, hoy))
      );
    });
    this.datosFiltradosCierre = this.arrayCierre.filter((dato: any) => {
      const fecha = this.parseFecha(dato.strFechaActividad);
      const hoy = new Date();
      const manana = new Date(hoy);
      manana.setDate(manana.getDate() + 1);
      return (
        (!this.checkboxes.hoy || this.esMismaFecha(fecha, hoy)) &&
        (!this.checkboxes.manana || this.esMismaFecha(fecha, manana)) &&
        (!this.checkboxes.estaSemana || this.estasEnLaSemana(fecha, hoy)) &&
        (!this.checkboxes.esteMes || this.estasEnElMes(fecha, hoy)) &&
        (!this.checkboxes.futuros || this.esFuturo(fecha, hoy)) &&
        (!this.checkboxes.conDemora || this.esDemora(fecha, hoy))
      );
    });
  }
  esMismaFecha(fecha1: Date, fecha2: Date): boolean {
    return (
      fecha1.getDate() === fecha2.getDate() &&
      fecha1.getMonth() === fecha2.getMonth() &&
      fecha1.getFullYear() === fecha2.getFullYear()
    );
  }

  estasEnElMes(fecha: Date, hoy: Date): boolean {
    return (
      fecha.getMonth() === hoy.getMonth() &&
      fecha.getFullYear() === hoy.getFullYear()
    );
  }

  esFuturo(fecha: Date, hoy: Date): boolean {
    return fecha > hoy;
  }

  restarUnDia(fecha: Date): Date {
    const unDia = 24 * 60 * 60 * 1000;
    const fechaRestada = new Date(fecha.getTime() - unDia);
    return fechaRestada;
  }

  esDemora(fecha: Date, hoy: Date): boolean {
    hoy = this.restarUnDia(hoy);

    return fecha < hoy;
  }

  parseFecha(fechaStr: string): Date {
    const partes = fechaStr.split('/');
    const dia = Number(partes[0]);
    const mes = Number(partes[1]) - 1;
    const anio = Number(partes[2]);
    return new Date(anio, mes, dia);
  }

  estasEnLaSemana(fecha: Date, hoy: Date): boolean {
    const inicioSemana = new Date(hoy);
    inicioSemana.setDate(inicioSemana.getDate() - hoy.getDay());
    const finSemana = new Date(inicioSemana);
    finSemana.setDate(finSemana.getDate() + 6);
    return fecha >= inicioSemana && fecha <= finSemana;
  }

  stopPropagation(event: any, estado: any) {

    event.stopPropagation();

    if (estado == 'Ganado') {
      this.banderaGanado = true
      this.banderaEspera = false
      this.banderaCancelado = false
      this.banderaPerdido = false
    } else if (estado == 'Espera') {
      this.banderaEspera = true
      this.banderaGanado = false
      this.banderaCancelado = false
      this.banderaPerdido = false
    } else if (estado == 'perdido') {
      this.banderaEspera = false
      this.banderaGanado = false
      this.banderaCancelado = false
      this.banderaPerdido = true
    } else {
      this.banderaEspera = false
      this.banderaGanado = false
      this.banderaCancelado = true
      this.banderaPerdido = false
    }
  }

  stopPropagation2(event: any) {
    event.stopPropagation();
  }

  stopPropagation3(event: any) { }

  stopPropagation4(event: any) {
    event.preventDefault();
  }


  fnInsertActividades(event: any) {

    let fechaAux = (this.strFechaActividad) ? this.strFechaActividad : this.fechaInicioServicio

    let fechaF: any
    if (this.fechaInicioServicio) {
      fechaF = this.fechaInicioServicio
    } else {
      let fechaFormato = new Date(fechaAux)
      let dia = (fechaFormato.getDate() < 10) ? ('0' + fechaFormato.getDate()) : fechaFormato.getDate()
      let mes = (fechaFormato.getMonth() < 10) ? ('0' + (fechaFormato.getMonth() + 1)) : (fechaFormato.getMonth() + 1)
      let anio = fechaFormato.getFullYear()
      let hora = (fechaFormato.getHours() < 10) ? ('0' + fechaFormato.getHours()) : fechaFormato.getHours()
      let minutos = (fechaFormato.getMinutes() < 10) ? ('0' + fechaFormato.getMinutes()) : fechaFormato.getMinutes()
      let segundos = (fechaFormato.getSeconds() < 10) ? ('0' + fechaFormato.getSeconds()) : fechaFormato.getSeconds()
      fechaF = dia + '-' + (mes) + '-' + anio + ' ' + hora + ':' + minutos + ':' + segundos
    }

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'Insert_Actividades',
      intIdLeads: this.intIDLeads,
      strAgente: this.strAgenteSP,
      strFechaCierre_Estimada: fechaF,
      strTipo: this.strTipoActividad,
      strResultado: '',
      strComentario: this.strComentario,
      strAsunto: this.strResultado,
      strContenido: '',
      strEstatus: 'PENDIENTE',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == '200') {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.isDropdownOpen = !this.isDropdownOpen;
          this.fngetActividades(this.intIDLeads)
          this.fnGetPropuesta()


        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  cambiarFechaInicioServicio(event: any) {
    let fecha = (event.target.value).split('-')
    this.fechaInicioServicio = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }

  fnInsertFechaInicioServicio() {

  }

  fnCambiarFuturo(event: any) {
    if (event.target.value == 'SI') {
      this.banderaFuturo = true
    } else {
      this.banderaFuturo = false
    }
  }

  cambiarFechaPerdido(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaPerdido = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }

  fnCancelado_Perdido() { }
  fnInsertActividadesSinF(event: any) { }
  validarUbicacion() {

    if (this.strEtapa == 'Recorrido') {

      if (!this.strCodigoPostal) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'No se tiene con una ubicación registrada, Favor de agregar los datos fiscales!',
        })
      } else {
        //this.fnValidacion_Ubicacion()
      }
    }
  }

  fnEnviarEdificios() { }

  getInitials(name: string): string {
    const names = name.split(' ');
    const initials = names.slice(0, 2).map(n => n.charAt(0).toUpperCase());
    return initials.join('');
  }

  fnObtenerHistorial() { }

  cambiarFase(event: any) {

    if (event.target.value == 'Por Contactar' || (event.target.value == 'Presentación' || event.target.value == 'Presentacion') || event.target.value == 'Recorrido') {
      this.strFase = 'LEAD'
      this.banderaFase = true
    } else if (event.target.value == 'Propuesta Economica' || event.target.value == 'Negociación') {
      this.strFase = 'PROSPECTO'
      this.banderaFase = false
      this.banderaCierre = true
    }

    this.validarUbicacion()
  }

  cambiarPrioridad(event: any) {
    if (event.target.value == '1-20 empleados') {
      this.strPrioridad = 'Baja'
    } else if (event.target.value == '21-50 empleados' || event.target.value == '51 - 300 empleados') {
      this.strPrioridad = 'Media'
    } else if (event.target.value == '3001 - 1000 empleados') {
      this.strPrioridad = 'Alta'
    } else if (event.target.value == '1001 - 2000 empleados' || event.target.value == '2001 + empleados') {
      this.strPrioridad = 'URGENTE'
    }
  }

  cambiarFecha(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaEstimadaCierre = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
    this.strFechaEstimadaCierreInput = fecha[0] + '-' + fecha[1] + '-' + fecha[2]
  }

  fnAgregarContacto() {
    this.arrayContactosDetalle.push({
      strNombreContacto: '',
      strContactoPuesto: '',
      strContactoTelefono: '',
      strContactoEmail: '',
      emailEsObligatorio: false
    })
  }

  fnEliminarContacto(index: any) {
    this.arrayContactosDetalle.splice(index, 1)
  }

  validarCampos(item: any) {
    let aux = item.strNombreContacto !== '' || item.strContactoPuesto !== '' || item.strContactoTelefono !== ''
    if (aux) {
      item.emailEsObligatorio = true
      this.banderaContacto = true
    } else {
      item.emailEsObligatorio = false
      this.banderaContacto = false
    }
  }
  validarTelefono(telefono: string): boolean {
    const pattern = /^\d{10}$/;

    return pattern.test(telefono);
  }

  validarEmailContacto(item: any) {
    if ((item.strNombreContacto !== '' || item.strContactoPuesto !== '' || item.strContactoTelefono !== '') && item.strContactoEmail != '') {
      item.emailEsObligatorio = true
      this.banderaContacto = true
      if (item.emailEsObligatorio && this.validarEmail(item.strContactoEmail)) {
        item.emailEsObligatorio = false
        this.banderaContacto = false
      }
    }
  }

  validarEmail(email: string): boolean {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    return regex.test(email);
  }

  fnGetDatosFiscales() { }

  agregarAnexo(event: any) {
    const fecha = new Date();
    const archivo: File = event.target.files[0];

    const tipo = archivo.type.split('/');
    if (tipo[1] == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      tipo[1] = "xlsx";
    }
    const nombreArchivo = this.intIDLeads + '-' + this.strTipoAnexo + ' ' + fecha.getFullYear() + '-' + fecha.getMonth() + '-' + fecha.getDay() + ' ' + fecha.getHours() + ':' + fecha.getMinutes() + ':' + fecha.getSeconds() + '.' + tipo[1];
    const rutaArchivo = 'EvidenciaLeads/' + nombreArchivo;

    const nuevoArchivo = new File([archivo], nombreArchivo, { type: archivo.type });
    this.ListaAnexos.push(nuevoArchivo);

    this.arrayAnexosUpdate.push({
      strTipo: tipo[1],
      strUrl: rutaArchivo,
      strTipoDocumento: this.strTipoAnexo,
      strNombre: nombreArchivo
    });

  }

  fnBorrarAnexo(intIDAnexo: any) { }
  
  fnUpdateLead_Nuevo(bandera: string) {

    this.strTipoCotizacion = ''
    let tipoCotizacion = this.arrayTipoCotizacion.filter((b: any)=> b.Seleccionado == true)
    if(tipoCotizacion.length > 0){
      tipoCotizacion.forEach(element => {
        this.strTipoCotizacion += (element.strTipoCotizacion) + ','
      });
    }
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'updateLeads',
      intid_Ubicacion: (this.intIDUbicacion).split(',')[0],
      intID_Familia: this.intID_Familia,
      strCodigoPostal: this.strCodigoPostal,
      strEstado: this.strEstado,
      strDelegacion: this.strDelegacion,
      strColonia: this.strColonia,
      strCalle: this.strCalle,
      strNo_exterior: this.strNumeroExterior,
      strEntrecalles: this.strEntreCalles,
      intLada: this.strLadaEmpresa,
      intTelefono: this.strTelefonoEmpresa,
      strZona: this.strZona,
      intId_Contacto: this.intId_Contacto,
      strContacto_Nombre: this.arrayContactosDetalle[0].strNombreContacto,
      strContacto_Telefono: this.arrayContactosDetalle[0].strContactoTelefono,
      strContacto_Puesto: this.arrayContactosDetalle[0].strContactoPuesto,
      strContacto_Email: this.arrayContactosDetalle[0].strContactoEmail,
      intIdLeads: this.intIDLeads,
      strNombreEmpresa: this.strNombreEmpresa,
      intId_Giro: (this.intGiro).split(',')[0],
      strSector: this.strSector,
      strRazon_Social: this.strRazonSocial,
      strRFC: this.strRFC,
      intIDNo_Empleados: this.strNumeroEmpleados,
      strMedio_NosConocio: this.strMedioConocio,
      strEstatus: this.formLead.value.strTieneServicio,
      strAgente: this.strAgenteSP,
      dblMonto: this.dblImporte,
      strFechaCierre_Estimada: this.strFechaEstimadaCierre,
      strEtiqueta: '',
      strSitio_Web: this.strSitioWeb,
      intId_Leads_Relacion: this.intId_Leads_Relacion,
      strPrioridad: this.strPrioridad,
      intId_Direccion: this.intId_Direccion,
      strObservaciones: '',
      intNo_Sucursales: this.intTotalSucursales,
      strFase: this.strFase,
      strResolucion: '',
      strtipoCotizacion: this.strTipoCotizacion,
      strFechaInicioServicio: this.fechaInicioServicio,
      intCantidadEmpleados: this.intCantidadElementos,
      strRecomendacion: this.strRecomendacion,
      intid_Cliente: this.intid_Cliente,
      intIDPresencia: (this.intIDPresencia).split(',')[0],
      strClasificacion: this.strClasificacion,
      xmlAnexos: this.arrayAnexosUpdate,
      strFamilia: this.intID_Familia,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          if (bandera == 'alta') {
            this.fnEnviarAltaCliente()
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Actualizado",
              showConfirmButton: false,
              timer: 1500
            });
            this.fnGetPropuesta()
            document.getElementById('botonCerrar').click()
          }


        } else {
          /* Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al actualizar!"
          }); */
          if (bandera == 'alta') {
            this.fnEnviarAltaCliente()
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Actualizado",
              showConfirmButton: false,
              timer: 1500
            });
            this.fnGetPropuesta()
            document.getElementById('botonCerrar').click()
          }
        }
        error => {
          var error = <any>error;
          console.log(error);
        }
      }
    );
  }

  mostrarMas(fase: any) {
    if (fase == 'propuestaProspecto') {
      if (this.elementosPEProspecto == (this.datosFiltradosPropuesta.length)) {
        this.elementosPEProspecto = 0
      } else {
        this.elementosPEProspecto += this.datosFiltradosPropuesta.length
      }
    }
  }

  fnDetalleLead(item: any) {
    console.log(item)
    this.intIDLeads = item.intIdLeads
    this.router.navigate(['/detalleLead'],{queryParams:{idLead: this.intIDLeads}});

    /* let tipoCotizacion = (item.strtipoCotizacion)? (item.strtipoCotizacion).split(','): ''
    console.log(tipoCotizacion)
    if(tipoCotizacion.length > 0){
      tipoCotizacion.forEach(element => {
        this.arrayTipoCotizacion.map((dato: any) => {
          if (dato.strTipoCotizacion === element) {
            dato.Seleccionado = true;
          }
          return dato;
        });
      });
     
    }

    this.strNumeroExterior = item.strNo_exterior
    let fecha = new Date(item.strFechaCierre_Estimada)
    this.strFechaEstimadaCierreInput = this.formatoFecha(fecha)
    this.strTieneServicio = (item.strServicio_Limpieza) ? item.strServicio_Limpieza : ''
    this.intValidacionFamilia = item.intValidacionFamilia
    this.strTipoCotizacion = item.strtipoCotizacion
    this.strNumeroEmpleados = item.intIDNo_Empleados
    this.intFlagAvanza4 = item.intFlagAvanza4
    this.intFlagAvanza5 = item.intFlagAvanza5
    this.intFlagAvanza2 = item.intFlagAvanza2
    this.intID_Familia = (item.intID_Familia == 0) ? 0 : item.intID_Familia
    this.intIDLeads = item.intIdLeads
    this.strCodigoPostal = item.strCodigoPostal
    this.strDelegacion = item.strDelegacion
    this.strEstado = item.strEstado
    this.strCalle = item.strCalle
    this.strColonia = item.strColonia
    this.strEntreCalles = item.strEntrecalles
    this.strFase = item.strFase
    this.intId_Direccion = item.intId_Direccion
    this.strClasificacion = item.strClasificacion
    this.intId_Leads_Relacion = item.intId_Leads_Relacion
    this.intId_Direccion = item.intId_Direccion
    this.intid_Cliente = item.intid_Cliente
    this.strNombreEmpresa = item.strNombreEmpresa
    this.strSector = item.strSector
    this.dblImporte = item.dblMonto
    this.strEtapa = item.strEstatus
    this.strFase = item.strFase
    this.strSitioWeb = item.strSitio_Web
    this.strMedioConocio = 'VENDEDOR'
    this.strPrioridad = item.strPrioridad
    this.strRFC = item.strRFC;
    this.strAgenteLD = item.strAgente;
    this.strNombreAgente = item.strNombreAgente
    let giro = this.arrayGiros.filter((b: any) => b.intId_Giro == item.intId_Giro)
    this.intGiro = item.intId_Giro + ',' + giro[0].strClasificacion
    let presencia = this.arrayPresencia.filter((b: any) => b.intIDPresencia == item.intIDPresencia)
    this.intIDPresencia = item.intIDPresencia + ',' + presencia[0].strClasificacion
    this.strRecomendacion = item.strRecomendacion
    this.arrayContactosDetalle[0].strNombreContacto = item.strContacto_Nombre
    this.arrayContactosDetalle[0].strContactoPuesto = item.strContacto_Puesto
    this.arrayContactosDetalle[0].strContactoTelefono = item.strContacto_Telefono
    this.arrayContactosDetalle[0].strContactoEmail = item.strContacto_Email
    this.intCantidadElementos = item.intCantidadEmpleados
    this.intTotalSucursales = item.intNo_Sucursales
    this.strRazonSocial = item.strRazon_Social
    let ubicacion = this.arrayUbicaciones.filter((b: any) => b.intid_Ubicacion == item.intid_Ubicacion)
    this.intIDUbicacion = item.intid_Ubicacion + ',' + ubicacion[0].strClasificacion
    this.fngetActividades(item.intIdLeads)
    this.fnGetCotizaciones()
    if (item.strCodigoPostal) {
      this.fnGetDatosFiscalesDetalle(item.strCodigoPostal)
    }
    this.fnGetAnexos(item.intIdLeads)
    this.fngetArticulosFavoritos(item.intIdLeads) */
  }

  fnGetDatosFiscalesDetalle(CP: any) {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: CP
    }).subscribe(
      result => {
        if (result.length > 0) {

          this.arrayColonias = result;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Codigo Postal Incorrecto!',
          });

        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  onGiroChange(event: any, tipo: string) {
    let clasificacion = event.target.value.split(',')[1];

    if (tipo === 'giro') {
      this.strClasificacion = clasificacion + this.strClasificacion.substring(1);
    } else if (tipo === 'presencia') {
      this.strClasificacion = this.strClasificacion.charAt(0) + clasificacion + this.strClasificacion.substring(2);
    } else {
      this.strClasificacion = this.strClasificacion.substring(0, 2) + clasificacion;
    }
  }

  fngetActividades(id: any) {
    this.arrayActividades = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getActividades',
      intIdLeads: id,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        console.log(result)
        if (result.length > 0) {
          this.intFlagAvanza1 = result[0].intFlagAvanza1
          this.intFlagActividad = result[0].intFlagActividad
          this.arrayActividades = result
        } else {
          this.intFlagAvanza1 = undefined
        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fngetArticulosFavoritos(id: any) {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFavoritos_leads',
      intIdLeads: id,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result.length > 0) {
          this.dataSource = result
        } else {

        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  cambiarFechaCancelado(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaCancelado = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
  }

  SubirAnexos(bandera: string) {
    if (this.ListaAnexos.length > 0) {
      this._serviciosService.makeFileRequest('https://www.ecodeli.com/EvidenciaAnexo_Leads.php', [], this.ListaAnexos).then((result) => {

        Swal.fire({
          icon: 'success',
          title: 'Correcto...',
          text: 'Anexos Guardados',
        });
        this.fnUpdateLead_Nuevo(bandera)
      }, (error) => {
        console.log(error);
      });
    } else {
      this.fnUpdateLead_Nuevo(bandera)
    }
  }

  fngetRangoEmpleados() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getRangoEmpleados',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayTamanoEmpresa = result

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayGiros = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetPresencia() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getPresencia',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayPresencia = result

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetUbicaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getUbicaciones',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayUbicaciones = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnGetCotizaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getCotizaciones_Cte',
      strCliente: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        console.log(result)
        this.arrayCotizaciones = result
        this.arrayCotizacionesAvanzar = this.arrayCotizaciones.filter((b: any) => b.strEstatusCliente == 'ACEPTADO' || b.strEstatusCliente == 'RECHAZADO')
        console.log(this.arrayCotizacionesAvanzar)
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnCotizador() {

    let objNombre_familia = this.arrayFamilias.find(o => o.intID_Familia == this.intID_Familia);
    let strNombre_familia = objNombre_familia ? objNombre_familia.strFamilia : '';
    this.objCliente = {
      strCliente: this.intIDLeads + '',
      strNombre: this.strNombreEmpresa,
      strDireccion: this.strCalle + ' ' + this.strNumeroExterior,
      ClienteSeleccionado: true,
      strNombreSucursal: this.strSucursal,
      intIDSucursal: "",
      strTipo: "PROSPECTO",
      strAgente: this.strAgenteSP,
      strAlmacen: "",
      strClaveUsoCFDI: "NA",
      strCondicion: "Contado",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: "NA", strOrigen: '/prospectos/', //Servirá para redireccionar al componente origen
      strCteFam: strNombre_familia//agregamos familia 

    };
    this.carroservice.setModoPlantilla(false);
    this.carroservice.deleteCarrito();
    this.carroservice.setCte(this.objCliente);
    this.router.navigate(['/Cotizador/']);
  }
  fnCotizador_Negociacion() {

    let objNombre_familia = this.arrayFamilias.find(o => o.intID_Familia == this.intID_Familia);
    let strNombre_familia = objNombre_familia ? objNombre_familia.strFamilia : '';
    this.objCliente = {
      strCliente: this.intIDLeads + '',
      strNombre: this.strNombreEmpresa,
      strDireccion: this.strCalle + ' ' + this.strNumeroExterior,
      ClienteSeleccionado: true,
      strNombreSucursal: this.strSucursal,
      intIDSucursal: "",
      strTipo: "PROSPECTO",
      strAgente: this.strAgenteSP,
      strAlmacen: "",
      strClaveUsoCFDI: "NA",
      strCondicion: "Contado",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: "NA", strOrigen: '/prospectos/', //Servirá para redireccionar al componente origen
      strCteFam: strNombre_familia,//agregamos familia 
      strTipoMovimiento: 'COTIZACION_LEADS'

    };
    //ACTIVAMOS MODO PLANTILLA
    this.carroservice.setModoPlantilla(true);
    this.carroservice.deleteCarrito();
    this.carroservice.setCte(this.objCliente);
    this.router.navigate(['/Cotizador/']);
  }


  fnGetFamilias() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFamilias',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayFamilias = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnDescargarFormato() {
    const rutaArchivo = 'assets/formatoSucursales.xlsx'
    const link = document.createElement('a');
    link.href = rutaArchivo;
    link.download = 'FormatoSucursales.xlsx';
    link.click();
  }

  Readexcel(file: File) {
    this.spinner = true;
    this.excelDataAux = []
    let filereader = new FileReader();
    filereader.readAsBinaryString(file);
    filereader.onload = (e) => {
      try {
        let arrayErrores: any = []
        var workBook = XLSX.read(filereader.result, { type: 'binary' });
        var sheetNames = workBook.SheetNames;
        this.excelDataAux = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]]);
        if (this.excelDataAux[0].Nombre_Sucursal) {
          this.excelDataAux.forEach((e: any) => {

            if (e.Codigo_Postal.toString().length != 5) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Registros con codigo postal invalido, Favor de agregarlo y adjuntar de nuevo el archivo excel!",
              });
              arrayErrores.push(e.Codigo_Postal)
              this.excelDataAux = []
            } else if (!this.contieneSoloNumeros(e.Codigo_Postal)) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Registros con codigo postal invalido, Favor de revisarlo y adjuntar de nuevo el archivo excel!",
              });
              arrayErrores.push(e.Codigo_Postal)
              this.excelDataAux = []
            }
            else {

            }

          });
        } else {

        }

        if (arrayErrores.length == 0) {
          const xmlCP = this.excelDataAux.map((cp: any) => {
            return { strCodigoPostal: cp.Codigo_Postal };
          });

          this.fnValidarCP(xmlCP)

        } else {
          console.log('no se puede hacer esta transaccion')
        }


      } catch (error) {
        //  this.capturador= true;
        //  this.nameArchivo= '';
        this.excelData = null;
        this.spinner = false;
        Swal.fire({ icon: 'error', title: 'Error', text: 'El archivo seleccionado no es un archivo valido, descarga el layout y vuelve a intentarlo.', });
      }
    }
  }

  fnValidarCP(xmlCP: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setValidaCP',
      xmlAnexos: xmlCP,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.excelData = this.excelDataAux
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
    document.removeEventListener('mousedown', this.onDocumentClick.bind(this));
  }



  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  contieneSoloNumeros(cadena: string): boolean {
    // Iterar sobre cada carácter de la cadena
    for (let i = 0; i < cadena.length; i++) {
      // Verificar si el carácter actual no es un dígito numérico
      if (!this.esDigitoNumerico(cadena[i])) {
        return false; // Se encontró un carácter que no es un número
      }
    }
    return true; // Todos los caracteres son numéricos
  }

  // Función auxiliar para verificar si un carácter es un dígito numérico
  private esDigitoNumerico(caracter: string): boolean {
    return /^[0-9]$/.test(caracter); // Utiliza una expresión regular para verificar si el carácter es un número del 0 al 9
  }

  handleDrop(event: DragEvent) {
    this.spinner = true
    event.preventDefault();
    const fileList = event.dataTransfer.files;

    if (fileList.length > 0) {
      //this.capturador = false;

      // this.nameArchivo= fileList[0].name;
      this.Readexcel(fileList[0]);
    } else {
      this.spinner = false;
    }
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    // Eliminar estilos de resaltado cuando se sale del área de soltar
  }
  handleFileInput(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const fileList = fileInput.files;
    // Puedes acceder al archivo seleccionado utilizando fileList[0]

    this.nameArchivo = fileList[0].name;
    //this.capturador = false;
    this.Readexcel(fileList[0]);
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    // Agregar estilos para resaltar el área de soltar
  }

  formatoFecha(fecha: Date): string {
    // Formatear la fecha en el formato deseado (ejemplo: "yyyy-MM-dd")
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.dropdownMenu.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  fnAvanzarCierre() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_Cierre',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetPropuesta()
          this.fnGetCierre()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnAvanzarNegociacion() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setAvanzaPaso_Negociacion',
      intIdLeads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Avanzado",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetPropuesta()
          this.fnGetNegociacion()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error!"
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnsetEstatus_Cotizacion(idCotizacion: any, event: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setEstatus_Cotizacion',
      intIDMovimientoVenta: idCotizacion,
      strEstatus: this.strEstatusCotizacion,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.stopPropagation4(event)
          this.intIDCotizacion = ''
          this.strEstatusCotizacion = ''
          this.fnGetCotizaciones()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnEnviarAltaCliente() {

    this.objAlta = {
      strRfc: (this.strRFC) ? this.strRFC : '',
      strRazonSocial: (this.strRazonSocial) ? this.strRazonSocial : '',
      strNombreAgente: this.strNombreAgente,
      strCfdi: '',
      strRegimenFiscal: '',
      strCp: (this.strCodigoPostal) ? this.strCodigoPostal : '',
      strEstado: (this.strEstado) ? this.strEstado : '',
      strCiudad: (this.strDelegacion) ? this.strDelegacion : '',
      strColonia: (this.strColonia) ? this.strColonia : '',
      strCalle: (this.strCalle) ? this.strCalle : '',
      strNoExterior: (this.strNumeroExterior) ? this.strNumeroExterior : '',
      strNombreComercial: this.strNombreEmpresa,
      strTipoCliente: '',
      strAgente: this.strAgenteLD,
      strContacto: (this.arrayContactosDetalle[0].strNombreContacto) ? this.arrayContactosDetalle[0].strNombreContacto : '',
      strCorreo: (this.arrayContactosDetalle[0].strContactoEmail) ? this.arrayContactosDetalle[0].strContactoEmail : '',
      strTelefono: (this.arrayContactosDetalle[0].strContactoTelefono) ? this.arrayContactosDetalle[0].strContactoTelefono : '',
      strOrigen: '/prospectos/'
    }

    this.alta_cliente.setCte_alta(this.objAlta);
    this.router.navigate(['/altaCliente/']);
  }

  toggleCotizacion(cotizacion: any){
    this.arrayTipoCotizacion.forEach(e => {
      if(cotizacion == e.strTipoCotizacion){
        if(e.Seleccionado){
          e.Seleccionado = false;
        }else{
          e.Seleccionado = true;
        }
      }
    });
  }

}
