<div class="container">
    <div class="row mt-3">
        <div class="col tex-center">
         <h1>REPORTE DE AVANCE MENSUAL</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <button type="button" class="btn btn-outline-success" title="descargar" id="exportButton" (click)="exportToExcel()"><i class="fa-solid fa-file-excel"></i></button>
        </div>
    </div>
    <div class="row mt-3">

    
    <div class="table-responsive" style="max-height: 80vh!important ; overflow-y: auto;">
        <table class="table  table-hover" id="myTable">
            <thead>
                <tr>
                    <th scope="col" class="fixed-width headerC">Sucursal</th>
                    <th scope="col" class="fixed-width headerC">Objetivo</th>
                    <th scope="col" class="fixed-width headerC">Avance de Ventas</th>
                    <th scope="col" class="fixed-width headerC">Notas de Crédito</th>
                    <th scope="col" class="fixed-width headerC">Avance Total</th>
                    <th scope="col" class="fixed-width headerC">Remisiones</th>
                    <th scope="col" class="fixed-width headerC">Avance Interno</th>
                    <th scope="col" class="fixed-width headerC">Monto Interno debe Llevar</th>
                    <th scope="col" class="fixed-width headerC">Diferencia</th>
                    <th scope="col" class="fixed-width headerC">Avance %</th>
                    <th scope="col" class="fixed-width headerC">Utilidad</th>
                    <th scope="col" class="fixed-width headerC">Margen %</th>
                    <th scope="col" class="fixed-width headerC">Presupuesto de Gasto</th>
                    <th scope="col" class="fixed-width headerC">Gasto</th>
                    <th scope="col" class="fixed-width headerC">Diferencia P - G</th>
                    <th scope="col" class="fixed-width headerC">Posible Utilidad</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let gte of arrayGerentes">
                    <ng-container *ngFor="let sucursal of gte.arraySucursales">
                        <tr>
                            <td class="fixed-width"><b>{{sucursal.strSucursal}}</b> -{{sucursal.strNombreSucursal}} </td>
                            <td class="fixed-width text-right">{{sucursal.dblObjetivo | currency}}</td>
                            <td class="fixed-width text-right headerC2">{{sucursal.dblAvance | currency}}</td>
                            <td class="fixed-width text-right">{{sucursal.dblNotaCredito | currency}}</td>
                            <td class="fixed-width text-right headerC2">{{sucursal.dblAvanceVentasTotal | currency}}</td>
                            <td class="fixed-width text-right">{{sucursal.dblRemisiones | currency}}</td>

                            <td class="fixed-width text-right">{{sucursal.dblAvanceInterno  | number:'1.2-2'}} %
                                <i class="fa-solid fa-turn-up text-success" *ngIf="sucursal.dblAvanceInterno >=100"></i>
                                <i class="fa-solid fa-turn-down text-danger" *ngIf="sucursal.dblAvanceInterno < 100"></i>
                            </td>
                            <td class="fixed-width text-right headerC2">{{sucursal.dblMontoInternoDebeLlevar | currency}}</td>

                            <td class="fixed-width text-right"><b>{{sucursal.dblDiferencian| currency}}</b></td>
                            <td class="fixed-width text-right headerC2 text-nowrap"> {{sucursal.dblPavance*100 | number:'1.2-2'}}%
                                <i class="fa-solid fa-turn-up text-success" *ngIf="sucursal.dblPavance >=100"></i>
                                <i class="fa-solid fa-turn-down text-danger" *ngIf="sucursal.dblPavance < 100"></i>
                            </td>
                            <td class="fixed-width text-right">{{sucursal.dblUtilidad | currency}}</td>
                            <td class="fixed-width text-right headerC2">{{sucursal.dblPorcentaje | number:'1.2-2'}}%
                                <i class="fa-solid fa-turn-up text-success" *ngIf="sucursal.dblPorcentaje >=22"></i>
                                <i class="fa-solid fa-turn-down text-danger" *ngIf="sucursal.dblPorcentaje < 22"></i>
                            </td>
                            <td class="fixed-width text-right">{{sucursal.dblPGasto | currency}}</td>
                            <td class="fixed-width text-right">{{sucursal.dblGasto | currency}}</td>
                            <td class="fixed-width text-right headerC2">{{sucursal.dblDiferencia  | currency}}</td>
                            <td class="fixed-width text-right text-info"><b>{{sucursal.dblPosibleUtilidad  | currency}}</b></td>
                        </tr>
                    </ng-container>
                    <tr class="gteClass2">
                        <th scope="row" class="gteClass2" >Totales {{gte.strNombreGerente}}</th>
                        <td class="gteClass2 text-right" >{{gte.dblTObjetivo | currency}}</td> 
                        <td class="gteClass2  text-right" >{{gte.dblTAvance | currency}}</td>
                        <td class="gteClass2  text-right" >{{gte.dblTNotaCredito | currency}}</td>
                        <td class="gteClass2  text-right" >{{gte.dblTAvanceVentasTotal | currency}}</td>

                        <td class="gteClass2  text-right" >{{gte.dblTRemisiones | currency}}</td>
                        <td class="gteClass2  text-right" >{{gte.dblTAvanceInterno | number:'1.2-2'}}%</td>
                        <td class="gteClass2  text-right" >{{gte.dblTMontoInternoDebeLlevar | currency}}</td>
                        <td class="gteClass2  text-right" >{{gte.dblTDiferencian | currency}}</td>
                        <td class="gteClass2  text-right" > {{gte.dblTPavance | number:'1.2-2'}}%</td>
                        <td class="gteClass2  text-right" > {{gte.dbTlUtilidad | currency}}</td>
                        <td class="gteClass2  text-right" > {{gte.dblTPorcentaje | number:'1.2-2'}}%</td>
                        <td class="gteClass2  text-right" > {{gte.dblTPGasto | currency}}</td>
                        <td class="gteClass2  text-right" > {{gte.dblTGasto | currency}}</td>
                        <td class="gteClass2  text-right" > {{gte.dblTDiferencia | currency}}</td>
                        <td class="gteClass2  text-right" > {{gte.dblTPosibleUtilidad | currency}}</td>
                    </tr>
                </ng-container>
              
            </tbody>
            <tfoot>
                <tr class="gteClass">
                    <th scope="row" class="gteClass" >Totales  </th>
                    <td class="gteClass text-right" >{{ dblTObjetivo | currency}}</td> 
                    <td class="gteClass  text-right" >{{ dblTAvance | currency}}</td>
                    <td class="gteClass  text-right" >{{ dblTNotaCredito | currency}}</td>
                    <td class="gteClass  text-right" >{{ dblTAvanceVentasTotal | currency}}</td>

                    <td class="gteClass  text-right" >{{ dblTRemisiones | currency}}</td>
                    <td class="gteClass  text-right" >{{ dblTAvanceInterno | number:'1.2-2'}}%</td>
                    <td class="gteClass  text-right" >{{ dblTMontoInternoDebeLlevar | currency}}</td>
                    <td class="gteClass  text-right" >{{ dblTDiferencian | currency}}</td>
                    <td class="gteClass  text-right" > {{ dblTPavance | number:'1.2-2'}}%</td>
                    <td class="gteClass  text-right" > {{ dbTlUtilidad | currency}}</td>
                    <td class="gteClass  text-right" > {{ dblTPorcentaje | number:'1.2-2'}}%</td>
                    <td class="gteClass  text-right" > {{ dblTPGasto | currency}}</td>
                    <td class="gteClass  text-right" > {{ dblTGasto | currency}}</td>
                    <td class="gteClass  text-right" > {{ dblTDiferencia | currency}}</td>
                    <td class="gteClass  text-right" > {{ dblTPosibleUtilidad | currency}}</td>
                </tr>
            </tfoot>
        </table>
        
        
    </div>
</div>
</div>
