import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-plantilla-filtros',
  templateUrl: './plantilla-filtros.component.html',
  styleUrls: ['./plantilla-filtros.component.css']
})
export class PlantillaFiltrosComponent implements OnInit {
  //Solo se deben de copiar este bloque hasta antes de la ultima llave de la clase PlantillaFiltrosComponent 
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;
  public strConexion:string="";
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[]; 
  public strGerente: string="";
  public arrayAccesos:any=[];
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public tagName:any;
  constructor(private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, 
    elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
     private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
    this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this._httpService.setMostrarMenu(true);

    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos=value?value:[];
      
    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? objAcceso[0].strUsuario : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
      }
     }
     else {
      this.router.navigate(['/login']);
     }
    });
   


       /*  if(res && res.code == "200"){
                Swal.fire({
                  title: 'Guardado correctamente!',
                  text: res.strMensaje,
                  icon: 'success',
                  timer: 2000, // tiempo en milisegundos (3 segundos)
                  timerProgressBar: true,
                  showConfirmButton: false
                });
                this.btnCerrarCteNuevo.nativeElement.click();
                this.fnGetClientes();
              }else{
                Swal.fire("Error!",res.strMensaje,"success")
              } */



    /* 
        this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgente_Crea = this.localSt.retrieve('strAgente');
    this.strAgente = this.localSt.retrieve('strAgente');
    this.strUsuario = this.localSt.retrieve('strUsuario');
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];

      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        this.strConexion = this.localSt.retrieve('strConexion');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strAgente = this.localSt.retrieve('strAgente');
        this.strUsuario = this.localSt.retrieve('strUsuario');
        this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;

        //------------conexi+on-------------------
        this.indexedDBService.getItem('strConexion', (value) => {
          this.strConexion = value;
          console.log(' CARGA conexion directorio')
        });
      }
      else {
        this.router.navigate(['/login']);
      }
    });
    */

  }

  ngOnInit(): void {
    this.fnGetFiltros()
  }
  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {

      this.arrayAgentes = nuevoValor;
    });
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }
  fnFiltrarPorAcceso(){

  }

//hasta aqui se debe copiar el codigo para su funcionamiento 
}
