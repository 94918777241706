<!--<app-menu></app-menu>-->
<br><br>
<div class="container">
  
    <div class="row bg-white bordertop borderbott shadow-sm pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
          <span class="material-symbols-outlined ">
              content_paste_search
          </span>
          <span class="fw-bold ">
              Filtros
          </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
            <mat-select [(ngModel)]="strGerente" class="" (selectionChange)="fnSelects()">
              <mat-option   value=""> --Todos --</mat-option> 
             <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente,'col-xl-4':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
            <mat-select [(ngModel)]="strSucursal" class="" (selectionChange)="fnSelects()">
              <mat-option value=""> --Todas --</mat-option> 
             <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
     
      
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente,'col-xl-4':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" (selectionChange)="fnSelects()">
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
          </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" >
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
              <mat-select [(ngModel)]="intEjercicio" (selectionChange)="fnSelects()">
                  <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                      {{e.ejercicio}}
                  </mat-option> 
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
          <button class="btn btnFilter shadow-sm px-2 py-1"  (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
              <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}" (click)="fnGetListadoVtasVsObjCtes();"></i> 
          </button>
        </div>
      </div>
   <!-- <div class="row">
        <div class="col-sm-12">
            <h1 class="title">Reporte de <span class="title2">Venta vs Objetivo</span> de Clientes</h1>
        </div>
        <br><br><br>
        <div class="col-3 select-left mb-3" *ngIf="selectGte">
            <mat-form-field appearance="fill">
                <mat-label>Seleccione Gerente</mat-label>
                <mat-select [(ngModel)]="gteSelected.strGerente" (selectionChange)="fnGetSucursales(gteSelected.strGerente)">
                    <mat-option *ngFor="let item of arrayGerentes" [value]="item.strGerente">
                        {{item.strEstatus}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
        
        </div>
        
        <div class="col-3 select-left mb-4" *ngIf="selectSuc">
            <mat-form-field appearance="fill">
                <mat-label>Seleccione sucursal</mat-label>
                <mat-select [(ngModel)]="sucursalSelected.strSucursal" (selectionChange)="fnGetAgentes(sucursalSelected.strSucursal)">
                
                    <mat-option *ngFor="let item of arraySucursales" [value]="item.strSucursal">
                        {{item.strEstatus}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            
        </div>

        <div class="col-3 select-left mb-4" *ngIf="selectAgt">
            <mat-form-field appearance="fill">
                <mat-label>Seleccione Agente</mat-label>
                <mat-select [(ngModel)]="agenteSelected.strEstatus">
                 
                    <mat-option *ngFor="let item of arrayAgentes" [value]="item.strEstatus">
                        {{item.strLinea}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
    
        </div>
        
        <div class="col-3 select-left mb-4" *ngIf="selectAgt">
            <button mat-raised-button style="background-color: #144361;color: white;height: 50px;margin-left: 15px;" (click)="fnGetListadoVtasVsObjCtes()" [disabled]="agenteSelected.strEstatus=='' || !agenteSelected.strEstatus">Filtrar</button>
            <label *ngIf="agenteSelected.strEstatus=='' || !agenteSelected.strEstatus" style="margin-top: -12px; color: red;">Seleccione un Agente</label>
        </div>       
    </div>-->

    <div class="row">
        <div class="col-sm-12">
              
            <div class="col-sm-12 text-center" *ngIf="spCargando==true">
                <p style="display: inline-block;"><mat-spinner></mat-spinner></p>
            </div>
            <div class="alert alert-danger my-3 text-center fw-bold" role="alert" *ngIf="spNoHayDatos==true">
                No se encontraron datos, Seleccione otro agente.
            </div>
            
        </div>
        <br>
        <div class="col-sm-12">
            <div class="rounded" [hidden]="spCargando" *ngIf="spNoHayDatos==false">
                
                    <table mat-table  [dataSource]="dataSource" matSort multiTemplateDataRows class="tabla_100 rounded">
                        <ng-container matColumnDef="strCliente">
                            <th mat-header-cell *matHeaderCellDef> Cliente</th>
                            <td mat-cell *matCellDef="let element" class="gastoCell" (click)="fnGetAvanceCte(element)">
                                <b class="resalta">{{element.strCliente}}</b>
                                <!-- Button trigger modal 
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Launch demo modal
  </button>-->
                            </td>
                        </ng-container>
    
                        <ng-container matColumnDef="strNombre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</th>
                            <td mat-cell *matCellDef="let element" class="gastoCell"> {{element.strNombre}}</td>
                        </ng-container>

                        <ng-container matColumnDef="dblObjetivo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Objetivo</th>
                            <td mat-cell *matCellDef="let element" class="gastoCell"> {{element.dblObjetivo | currency}} </td>
                        </ng-container>                        
                    
                        <ng-container matColumnDef="dblVenta">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Venta</th>
                            <td mat-cell *matCellDef="let element" class="gastoCell">{{element.dblVenta | currency}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dblDiferencia">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Diferencia</th>
                            <td mat-cell *matCellDef="let element" class="gastoCell">{{element.dblDiferencia | currency}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dblAvance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Avance</th>
                            <td mat-cell *matCellDef="let element" class="gastoCell">
                                <p [ngClass]="{'rojo': (element.dblAvance<1), 'verde': (element.dblAvance>=1)}"><b>{{element.dblAvance | percent}}</b></p>
                                <!--*{{element.dblAvance}}*-->
                            </td>
                        </ng-container>                        
                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20,30,60,120]" showFirstLastButtons></mat-paginator>
            </div>
            <br><br>
        </div>


    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>