<div class="container">
  <div class="row">
    <div class="col-12 text-center mt-3">
        <h1 class="fw-bold h1">Listado de <span class="bgtittle">Solicitudes</span></h1>
    </div>
  </div>
  <div class="row bg-white bordertop borderbott shadow-sm pb-4">
    <div class="col-12 titulos py-1 mb-3 d-flex bordertop">
      <span class="material-symbols-outlined text-dark">
        content_paste_search
      </span>
      <span class="fw-bold text-dark">
        Filtros
      </span> 
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
        <mat-select [(ngModel)]="strGerente" class="" >
          <mat-option   value=""> --Todos --</mat-option> 
          <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
        <mat-select [(ngModel)]="strSucursal" class="" >
          <mat-option value=""> --Todas --</mat-option> 
          <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
        <mat-select [(ngModel)]="strAgente" class="" >
          <mat-option value=""> --Todos --</mat-option> 
          <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
        </mat-select>
      </mat-form-field>
    </div> 
    <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
        <mat-select [(ngModel)]="intPeriodo" class="" >
          <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
        <mat-select [(ngModel)]="intEjercicio">
          <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
            {{e.ejercicio}}
          </mat-option> 
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
      <button  class=" shadow-sm px-2 py-1 btn btn-secondary"  (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false" (click)="fnGetSol();">
        <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i><!-- (click)="fnGetPlantillas()" -->
      </button>
    </div>
  </div>
  <!--<div class="row pt-4" *ngIf="(!arraylistComodatos || arraylistComodatos.length === 0) && !spiner">
    <div class="col-12">
      <div class="alert alert-danger text-center fw-bold fs-5" role="alert">
        {{mensajeError}}
      </div>
    </div>
  </div>
   fin de filtros -->
  <div class="row" *ngIf="spiner">
    <div class="col-12 d-flex justify-content-center my-3">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="row mt-3" [hidden]="  spiner"><!--  *ngIf="(arraylistComodatos && arraylistComodatos.length > 0) && !spiner" -->
    <!-- Barra de navegación con tabs -->
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="listado-tab" data-bs-toggle="tab" href="#listado" role="tab" aria-controls="listado" aria-selected="true">Listado de Solicitudes</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="renovacion-tab" data-bs-toggle="tab" href="#renovacion" role="tab" aria-controls="renovacion" aria-selected="false">Solicitud Renovación</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="cancelacion-tab" data-bs-toggle="tab" href="#cancelacion" role="tab" aria-controls="cancelacion" aria-selected="false">Solicitud Cancelación</a>
      </li>
    </ul>
    <!-- Contenido de las pestañas -->
    <div class="tab-content bg-white " id="myTabContent">
      <div class="tab-pane fade show active" id="listado" role="tabpanel" aria-labelledby="listado-tab">
        <div class="row" *ngIf="!spiner">
          <div class="col-6 mt-3 d-flex justify-content-start">
            <button type="button" class="btn btn-primary"  [routerLink]="['/comodatos_lineas']" ><i class="fa-solid fa-file-circle-plus"></i> Nueva Solicitud</button>
          </div>
        </div>
        <div class="col-12" >
          <div class="mat-elevation-z8 my-3">
            <div class="row d-flex justify-content-evenly titulos py-2 bordertop">
              <div class="col-12 col-md-6   text-start">
                <mat-form-field appearance="outline" class="bg-white rounded">
                  <mat-label>Filtrar</mat-label>
                  <input matInput (keyup)="applyFilter($event)" placeholder="Dato" #input>
                  <i class="bi bi-search fs-5" matSuffix></i>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 text-end pt-2 pt-md-0">
                <mat-form-field appearance="outline" class="ms-auto bg-white rounded">
                  <mat-label>Estatus</mat-label>
                  <mat-select [(ngModel)]="strEstatus" (ngModelChange)="fitrarSelect()">
                    <mat-option *ngFor="let est of arrayEstatus" [value]="est">
                      {{est}}
                    </mat-option>
                  </mat-select>
                  <i class="bi bi-person-vcard fs-4" matSuffix></i>
                </mat-form-field>
              </div>
            </div>
            <div class="row" [hidden]="!spinSol">
              <div class="col-12 d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
            </div>
            <div [hidden]="spinSol" class="row">
              <div class="col-12 table-responsive mb-0 px-0">
                <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered" matSort #sortSolicitud="matSort">
                  <ng-container matColumnDef="strIDComodatos">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">ID Comodato</th>
                    <td mat-cell *matCellDef="let row" class="text-center fs14 fw-bold align-middle"> {{row.intIDComodatos}} </td>
                  </ng-container>
    
                  <ng-container matColumnDef="strCliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="mx-auto">Cliente</th>
                    <td mat-cell *matCellDef="let row" class="text-center fs10 align-middle"> <a (click)="fnRedirecciona_Detalle(row);" class="text-link pointer"> ({{row.strCliente}}){{row.strClienteNombre}}</a> 
                    <br><span *ngIf="row.strEstatus === 'AUTORIZADO'">MovID : <b> {{row.strMovID}}</b> Estatus:<b>{{row.strEstatusSolC}}</b></span> 
                    </td>
                  </ng-container>
              
                  <ng-container matColumnDef="strSucursal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Sucursal</th>
                    <td mat-cell *matCellDef="let row" class="text-center fs10 align-middle"> 
                 
                      <span *ngIf="row.strCteEnviarA !=''"><b>({{row.strCteEnviarA}})</b> {{row.strCteEnviarANombre}}</span>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="strClienteNombreCorto">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Nombre Corto</th>
                    <td mat-cell *matCellDef="let row" class="text-center fs10 align-middle"> {{row.strClienteNombreCorto}} </td>
                  </ng-container>
             
                   <ng-container matColumnDef="strAgenteNombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Agente</th>
                    <td mat-cell *matCellDef="let row" class="text-center fs10 align-middle"><b>({{row.strAgente}})</b> {{row.strAgenteNombre}}</td>
                  </ng-container>
                  <ng-container matColumnDef="strFechaSolicitud">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Fecha Solicitud</th>
                    <td mat-cell *matCellDef="let row" class="text-center fs10 align-middle"> {{row.strFechaSolicitud|date:'dd/MM/yyyy'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="strFechaInstalacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Fecha Instalacion</th>
                    <td mat-cell *matCellDef="let row" class="text-center fs10 align-middle"> {{row?.strFechaInstalacion}}</td>
                  </ng-container>
    
                  <ng-container matColumnDef="strEstatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Estatus</th>
                    <td mat-cell *matCellDef="let row" class="text-center fs10 align-middle"> <span class="rounded py-1 px-2 text-nowrap"
                       [ngClass]="{'bg-danger text-white':row.strEstatus == 'RECHAZADO','bg-warning text-bg-warning':row.strEstatus == 'PENDIENTE',
                       'bg-success text-bg-success ':row.strEstatus == 'AUTORIZADO'}" >
                       <i class="pe-1 bi "  [ngClass]="{'bi-x-lg text-white': row.strEstatus === 'RECHAZADO',
                       'bi-check-lg text-white': row.strEstatus === 'CONCLUIDO',
                       'bi-alarm text-dark': row.strEstatus === 'PENDIENTE',
                       'bi-hand-thumbs-up text-white': row.strEstatus === 'AUTORIZADO'
                     }"></i>
                       {{row.strEstatus}}</span> </td>
                  </ng-container>
    
                  <ng-container matColumnDef="atencion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center pe-0"></th>
                    <td mat-cell *matCellDef="let row" class="text-center fs10 pe-0 align-middle">
                      <button  *ngIf="row.strEstatus === 'PENDIENTE'" type="button" class="btn btn-outline-primary" title="editar" (click)="fnEditar(row)"><i class="fa-regular fa-pen-to-square"></i></button>
                    <a *ngIf="row.strEstatus === 'AUTORIZADO'" type="button" class="btn btn-outline-danger" title="contrato" 
                    [href]="'https://www.ecodeli.com/PDFContratosComodatos/ContratoComodato_' + row.intIDComodatos + '.pdf'" target="_blank" download><i class="fa-solid fa-file-pdf"></i></a>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="thcenter sticky-top"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9">No se encontraron datos al filtrar: "{{input.value}}"</td>
                  </tr>
                </table>
              </div>
              <mat-paginator class="paginator" [pageSizeOptions]="[10, 25, 50,100]" aria-label="Select page of users" #paginadorSolicitud></mat-paginator>
            </div>
          </div>
        </div>
      </div>
      <!--solicitud de renovacion -->
      <div class="tab-pane fade " id="renovacion" role="tabpanel" aria-labelledby="renovacion-tab">
        <div class="row mt-3 ">
          <div class="col-12 py-2  d-flex justify-content-between bgazul bordertop shadow">
            <mat-form-field appearance="outline" class="bg-white rounded">
              <mat-label>Buscar</mat-label>
              <input matInput (keyup)="applyFilterRenovacion($event)" placeholder="ID,Tarea,etc..." #input>
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline"  class="bg-white rounded">
              <mat-label>Estatus</mat-label>
              <mat-select [(ngModel)]="strEstautsRenovacion" (ngModelChange)="fitrarSelectRenovacion()">
                <mat-option value="">--TODOS--</mat-option>
                <mat-option value="RECHAZADO">RECHAZADO</mat-option>
                <mat-option value="CONCLUIDO">CONCLUIDO</mat-option>
                <mat-option value="PENDIENTE">PENDIENTE</mat-option>
                <mat-option value="AUTORIZADO">AUTORIZADO</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 px-0">
            <div class="mb-0 table-responsive">
              <div class="mat-elevation-z8 mb-0  shadow rounded">
                <table mat-table [dataSource]="datosSolRenovacion" class="table table-hover table-bordered table-striped"  matSort #sortRenovacion="matSort">
                  <ng-container matColumnDef="strCliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Cliente</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strCliente}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strNombreCte">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Nombre Cliente</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strNombreCte}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strFechaInicio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Fecha Inicio</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strFechaInicio|date:'dd/MM/yyyy'}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strCteEnviarA">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Enviar a</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strCteEnviarA}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strObservaciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Observaciones</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strObservaciones}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="intIDCancelacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul"> ID. Renovacion </th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.intIDCancelacion}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strAgente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul"> Agente </th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      <b>{{row.strAgente}}</b> {{row.strNombreAgente }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="strEstatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Estatus</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs10 text-center align-middle"> 
                      <button type="button" class="btn btn-primary fs10 p-1 mx-auto d-flex align-items-center" [ngClass]="{
                        ' btn-danger': row.strEstatus === 'RECHAZADO',
                        ' btn-success': row.strEstatus === 'CONCLUIDO',
                        ' btn-warning': row.strEstatus === 'PENDIENTE',
                        ' btn-info': row.strEstatus === 'AUTORIZADO'
                      }"><i class="pe-1 bi "  [ngClass]="{'bi-x-lg text-white': row.strEstatus === 'RECHAZADO',
                        'bi-check-lg text-white': row.strEstatus === 'CONCLUIDO',
                        'bi-alarm text-dark': row.strEstatus === 'PENDIENTE',
                        'bi-hand-thumbs-up text-dark': row.strEstatus === 'AUTORIZADO'
                      }"></i>{{row.strEstatus  }}</button>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="intIDComodatos">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">ID Comodato</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.intID}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="intID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul"> IDIntellisis </th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.intIDComodatos}}
                    </td>
                  </ng-container>
                  <tr mat-header-row class="rounded" *matHeaderRowDef="columnsRenovacion"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsRenovacion;"  (click)="fnAbrirModal(row)" data-bs-toggle="modal" data-bs-target="#modalRenovacion" ></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No se encontraron datos de: "{{input.value}}"</td>
                  </tr>
                </table>
              </div>
            </div>
            <mat-paginator class="paginator" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorRenovacion></mat-paginator>
          </div>
        </div>
      </div>
        <!--solicitud de Cancelacion-->
      <div class="tab-pane fade" id="cancelacion" role="tabpanel" aria-labelledby="cancelacion-tab">
        <div class="row mt-3">
          <div class="col-12 py-2  d-flex justify-content-between bgazul bordertop">
            <mat-form-field appearance="outline" class="bg-white rounded">
              <mat-label>Buscar</mat-label>
              <input matInput (keyup)="applyFilterCancelacion($event)" placeholder="ID,Tarea,etc..." #input>
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline"  class="bg-white rounded">
              <mat-label>Estatus</mat-label>
              <mat-select [(ngModel)]="strEstautsCancelacion" (ngModelChange)="fitrarSelectCnacelacion()">
                <mat-option value="">--Todos--</mat-option>
                <mat-option value="PENDIENTE">PENDIENTE</mat-option>
                <mat-option value="CONCLUIDO">CONCLUIDO</mat-option>
                <mat-option value="OBSERVACIONES">OBSERVACIONES</mat-option>
                <mat-option value="RETIRAR">RETIRAR</mat-option>
                <mat-option value="RETIRAREV">RETIRAREV</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 px-0">
            <div class="mb-0 table-responsive">
              <div class="mat-elevation-z8 mb-0  shadow rounded">
                <table mat-table [dataSource]="datosSolCancelacion" class="table table-hover"  matSort #sortCancelacion="matSort">
                  <ng-container matColumnDef="strCliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Cliente</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strCliente}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strNombreCte">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Nombre Cliente</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strNombreCte}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strFechaInicio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Fecha Inicio</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strFechaInicio|date:'dd/MM/yyyy'}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strCteEnviarA">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Enviar a</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strCteEnviarA}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strObservaciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Observaciones</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.strObservaciones}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="intIDCancelacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul"> ID. Renovacion </th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.intIDCancelacion}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="strAgente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul"> Agente </th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      <b>{{row.strAgente}}</b> {{row.strNombreAgente }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="strEstatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">Estatus</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs10 text-center align-middle"> 
                      <button type="button" class="btn btn-primary fs10  p-1 mx-auto d-flex align-items-center" [ngClass]="{
                         ' btn-warning': row.strEstatus === 'PENDIENTE'||row.strEstatus=== 'OBSERVACIONES',
                         ' btn-success': row.strEstatus === 'CONCLUIDO',
                         ' btn-primary': row.strEstatus === 'RETIRAR',
                        
                        ' btn-info': row.strEstatus === 'RETIRAREV'
                      }">
                      
                      <i class="pe-1 bi "  [ngClass]="{'bi-x-lg text-white': row.strEstatus === 'RECHAZADO',
                        'bi-check-lg text-white': row.strEstatus === 'CONCLUIDO',
                        'bi-alarm text-dark': row.strEstatus === 'PENDIENTE',
                        'bi-hand-thumbs-up text-dark': row.strEstatus === 'AUTORIZADO',
                        'bi-chat-dots text-dark':row.strEstatus === 'OBSERVACIONES',
                        'bi-box-arrow-down text-dark':row.strEstatus === 'RETIRAR',
                        'bi-file-arrow-down text-dark':row.strEstatus === 'RETIRAREV'
                      }"></i> {{row.strEstatus  }}</button>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="intIDComodatos">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul">ID Comodato</th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.intID}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="intID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgazul"> IDIntellisis </th>
                    <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                      {{row.intIDComodatos}}
                    </td>
                  </ng-container>
                  <tr mat-header-row class="rounded" *matHeaderRowDef="columnsRenovacion"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsCancelacion;"  (click)="fnAbrirModalCancelacion(row)" data-bs-toggle="modal" data-bs-target="#modalCancelacion" ></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="10">No se encontraron datos de: "{{input.value}}"</td>
                  </tr>
                </table>
              </div>
            </div>
            <mat-paginator class="paginator" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorCancelacion></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modalRenovacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-0 mx-auto" id="exampleModalLabel">Solicitud Renovación {{objModalRenovacion?.intIDComodatos}}</h2>
        <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0 " >
        <div class="container">
          <div class="row justify-content-evenly my-2 maxheightModal">
            <div class="col-12 col-sm-6 d-flex justify-content-center mt-2" *ngFor="let com of arrayArtsRenovacion">
              <div class="col-11 borderbott bordertop bg-light">
                <div class="row borderbott bordertop">
                  <div class="col-5 borderbott bordertop ">
                    <div class="col-12 text-center bgimg bordertop ">
                      <span class="fw-bold fs12 ">
                        {{com.strDespachador}}
                      </span>
                    </div>
                    <div class="col-12">
                      <img [src]="'https://www.cleanclean.mx/imagenes/' + com.strDespachador + '.jpg'" class="w-100" alt="">
                    </div>
                    <div class="col-12 bgimg fs11 borderbott px-1">
                      {{com.strNombreDespachador}}
                    </div>
                  </div>
                  <div class="col-7 ">
                   <div class="row">
                    <div class="col-12">
                      <div class="col-12">
                        Consumible:
                      </div>
                      <div class="col-12 fs10">
                        {{com.strNombreConsumible}}
                      </div>
                      <div class="col-12">
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-12">
                        Precio:
                      </div>
                      <div class="col-12 fs10">
                        {{com.dblPrecio}}
                      </div>
                      <div class="col-12">
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-12">
                        Cantidad Prometida:
                      </div>
                      <div class="col-12 fs10">
                        {{com.dblCantidadPrometida}}
                      </div>
                      <div class="col-12">
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="col-12">
                        Cantidad Despachadores:
                      </div>
                      <div class="col-12 fs10">
                        {{com.dblCantidadDespachador}}
                      </div>
                    </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 bg-light">
              <div class="py-2">
                <label for="exampleInputEmail1" class="form-label mb-0">Motivo de Rechazo o Retiro</label>
                <input type="email" class="form-control" [disabled]="objModalRenovacion?.strEstatus!='PENDIENTE'" [(ngModel)]="strMotivoRechazoRenovacion" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-secondary"  data-bs-dismiss="modal" #btnModalRenovacion>Cerrar</button>
        <button type="button" class="btn btn-primary" *ngIf="objModalRenovacion?.strEstatus=='PENDIENTE'" (click)="fnAutorizarRenovacion()">Autorizar</button>
        <button type="button" class="btn btn-danger" *ngIf="objModalRenovacion?.strEstatus=='PENDIENTE'" (click)="fnRechazarRenovacion()">Rechazar</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade mi-modal" id="modalCancelacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="bg-light row justify-content-center mx-auto row justify-content-center w-100">
        <div class="col-12 mx-2 px-0 border-bottom">
          <div class="col-11 modal-header p-1 mx-auto">
            <h3 class="modal-title mb-0 mx-auto fw-bold" id="exampleModalLabel">
              Comodato: {{objModalCancelar?.intIDComodatos}}
            </h3>
            <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close" id="miBoton"></button>
          </div>
          <div class="col-11 mx-auto pb-2">
            <label for="comentario" class="fw-bold">Comentario</label>
            <input type="text" [(ngModel)]="strComentarioEnviarCancelacion" class="form-control" disabled>
          </div>
        </div>
      </div>
      <div class="modal-body mhmodal position-relative">
        <div *ngIf="spinerMod" class="text-center">
          <mat-spinner></mat-spinner>
        </div>

        <div class="row justify-content-center" *ngIf="!spinerMod && objModalCancelar?.strEstatus !== 'RETIRAREV'">
          <div class="col-12 col-md-10">
            <div class="row shadow-sm mb-3 bg-light py-1 justify-content-between align-items-center roundedBordes" *ngFor="let com of arrayArtsCancelacion" >
              <div class="col-4 col-md-3 py-1 border-end bordertop borderbott text-center">
                <div class="col-12 text-center bordertop  bg-main">
                  <span class="fs14 fw-bold text-white">{{com.strDespachador}}</span>
                </div>
                <img [src]="'https://www.cleanclean.mx/imagenes/' + com.strDespachador + '.jpg'" class="w-100 bg-white" alt="">
                <div class="col-12 text-center borderbott bg-main">
                  <span class="fs10 text-white">{{com.strNombreDespachador}}</span>
                </div>
              </div>

              <div class="col-7 col-md-9">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="row">
                      <span class="fw-bold">Consumible:</span>
                    </div>
                    <div class="row d-flex">
                      <span>
                        <span class="text-dark fw-bold">{{com.strConsumible}}</span> {{com.strNombreConsumible}}
                      </span>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <hr class="d-block d-md-none">
                    <div class="row">
                      <span class="fw-bold">Última Compra:</span>
                    </div>
                    <div class="row">
                      <span>{{com.strUltimaCompra | date: 'shortDate'}}</span>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <hr>
                    <div class="row">
                      <span class="fw-bold">Precio:</span>
                    </div>
                    <div class="row">
                      <span>{{com.dblPrecio | currency}}</span>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <hr>
                    <div class="row">
                      <span class="fw-bold">Cantidad Prometida:</span>
                    </div>
                    <div class="row">
                      <span>{{com.dblCantidadPrometida}}</span>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <hr>
                  <div class="col-12">
                    <span class="fw-bold">Cantidad Despachadores:</span>
                  </div>
                  <div class="col-12">
                    {{com.dblCantidadDespachador}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!spinerMod && objModalCancelar?.strEstatus === 'RETIRAREV'" class="text-center">
          <div class="row">
            <div class="col-12">
              <img [src]="'http://www.ecodeli.com/Adjuntos/EvidenciaComodato/Retiro/' + objModalCancelar?.strUrlEvidencia" class="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer pt-0 px-0 justify-content-evenly bg-light" ><!-- *ngIf="acceso === 1 || acceso === 0" -->
        <div class="col-12 mx-0 my-0 py-1 border-bottom">
          <div class="col-11 mx-auto">
            <label for="motivo" class="fw-bold">Motivo de Rechazo o Retiro</label>
            <input type="text" [(ngModel)]="strMotivoRechazoCancelacion" class="form-control" [ngClass]="{'is-invalid': flagrechazo}" [disabled]="objModalCancelar?.strEstatus !== 'PENDIENTE'">
          </div>
        </div>
        <button type="button" class="btn btn-secondary" #btnModalCancelar data-bs-dismiss="modal" >Cerrar</button>
        
        <button type="button" class="btn btncontr mt-2" (click)="cancelarSolicitud()" *ngIf="objModalCancelar?.strEstatus === 'PENDIENTE' || objModalCancelar?.strEstatus === 'RETIRAREV'">
          {{objModalCancelar?.strEstatus === 'PENDIENTE' ? 'CANCELAR COMODATO' : 'CONCLUIR CANCELACIÓN'}}
        </button>
        <button type="button" class="btn btn-danger mt-2" (click)="fnRechazarcancelacion()" *ngIf="objModalCancelar?.strEstatus === 'PENDIENTE'">RECHAZAR</button>
        <button type="button" class="btn btn-warning mt-2" (click)="fnRetirarcancelacion()" *ngIf="objModalCancelar?.strEstatus === 'PENDIENTE'">RETIRAR</button>
      </div>
    </div>
  </div>
</div>
