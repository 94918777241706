import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';

@Component({
  selector: 'app-ecommerce-user-direccion',
  templateUrl: './ecommerce-user-direccion.component.html',
  styleUrls: ['./ecommerce-user-direccion.component.css']
})
export class EcommerceUserDireccionComponent implements OnInit {
  formAdress: FormGroup;
  passwordMismatchError: boolean = false;
  public spinnerCol: boolean=false;
  public arrayColonias:any =[];
  //public userData :any;
  public userData_tienda:any=[];
  passwordVisible: boolean = false; // Controla la visibilidad de la contraseña
  confirmPasswordVisible: boolean = false; // Controla la visibilidad de la confirmación de contraseña
  public strConexion:string ='COMERCIAL';
  constructor(private cartService: EcommerceCarrService,private _httpService: PeticionesService,private fb: FormBuilder, private router: Router) {
    
    


    this.fnInitForm();
   }

   fnInitForm(){

    this.formAdress = this.fb.group({
      strPais:['México', Validators.required],
        strNombre:[ '', Validators.required],
        strApellido:[ '', Validators.required],
        strDireccion:['', Validators.required],
        strColonia: ['', [Validators.required, Validators.minLength(6)]],
        strCodigoPostal: ['', [Validators.maxLength(5), Validators.required]],
        strCiudad: ['', [Validators.maxLength(100), Validators.required]],
        strEstado: ['', [Validators.maxLength(100), Validators.required]],
        strTelefono: ['', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]],
        strEntreCalles: [''],
       // confirmPassword: ['', [Validators.required]]
      });
   }
  ngOnInit(): void {
   let obj = localStorage.getItem('USR_DATA')

   //   this.userData =  JSON.parse(localStorage.getItem('USR_DATA')); // Convierte la cadena de texto en un objeto
    

              //buscamos si el usuario esta logueedo
              this.cartService.getUsr().subscribe((data) =>{
                this.userData_tienda = data?data:[]; // Actualizamos la variable cuando los datos cambian
                console.log(this.userData_tienda);
                    if(this.userData_tienda && this.userData_tienda.length>0){
                      this.fnADDvalues()
                    }else{
                      this.router.navigate(['/tlogin']);
                    }
              })

  }
    fnADDvalues(){
      this.formAdress.setValue({
        strPais: 'México', // Valor actual, no se modifica porque es disabled
        strNombre: this.userData_tienda[0]?this.userData_tienda[0].strNombre_D:'',
        strApellido: this.userData_tienda[0]?this.userData_tienda[0].strApellidos_D:'',
        strDireccion: this.userData_tienda[0]?this.userData_tienda[0].strDireccion:'', 
        strColonia: this.userData_tienda[0]?this.userData_tienda[0].strColonia:'', 
        strEntreCalles: this.userData_tienda[0]?this.userData_tienda[0].strEntreCalles:'', 
        strCodigoPostal: this.userData_tienda[0]?this.userData_tienda[0].strCodigoPostal:'', 
        strCiudad: this.userData_tienda[0]?this.userData_tienda[0].strCiudad:'', 
        strEstado: this.userData_tienda[0]?this.userData_tienda[0].strEstado:'', 
  
      //  email: this.userData_tienda[0]?this.userData_tienda[0].strCorreo:'',
        strTelefono: this.userData_tienda[0]?this.userData_tienda[0].strTelefono:''// Mantenemos el valor actual
      });
  
     // this.toggleFormulario();
      // Marcamos el campo 'email' como tocado
    //  this.formAdress.get('email')?.markAsTouched();
      this.formAdress.get('strNombre')?.markAsTouched(); 
      this.formAdress.get('strApellido')?.markAsTouched();
      this.formAdress.get('strColonia')?.markAsTouched();
      this.formAdress.get('strEntreCalles')?.markAsTouched();
      this.formAdress.get('strCodigoPostal')?.markAsTouched();
      this.formAdress.get('strDireccion')?.markAsTouched();
      this.formAdress.get('strTelefono')?.markAsTouched();
       this.simulateEnter();
    }
  // Getter para acceder más fácilmente a los controles del formulario
     onSubmit(customerData:any): void {
       if (this.formAdress.invalid) {
         return;
       }
      
        let obj = this.formAdress.value
      
         let articulo = {
               strAccion: "updateDireccion",  
               strConexion: this.strConexion,
               strPais: obj.strPais,
               strNombre:obj.strNombre,
               strApellidos:obj.strApellido,
               strDireccion:obj.strDireccion,
               strColonia:obj.strColonia,
               strCodigoPostal:obj.strCodigoPostal,
               strCiudad:obj.strCiudad,
               strEstado:obj.strEstado,
               strTelefono:obj.strTelefono,
               strEntreCalles:obj.strEntreCalles,
               intID_Direccion: this.userData_tienda[0].intID_Direccion
             };
      
              this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
               result => {
             
                 if(result[0] && result[0].code){
                      Swal.fire({
                               icon: "success",
                               title: "Registro Exitoso",
                               text:  result[0].strMensaje 
                             });
                             this.userData_tienda[0].strNombre_D = obj.strNombre;
                             this.userData_tienda[0].strApellidos_D = obj.strApellido;
                             this.userData_tienda[0].strDireccion = obj.strDireccion;
                             this.userData_tienda[0].strColonia = obj.strColonia;
                             this.userData_tienda[0].strCodigoPostal = obj.strCodigoPostal;

                             this.userData_tienda[0].strCiudad = obj.strCiudad;
                             this.userData_tienda[0].strEstado = obj.strEstado;

                             this.userData_tienda[0].strTelefono = obj.strTelefono;
                             this.userData_tienda[0].strEntreCalles = obj.strEntreCalles;
                             this.cartService.updateLogin(this.userData_tienda) ;
                             

                             this.fnInitForm();
                             this.fnADDvalues();
                 } else {
                       Swal.fire({
                                 icon: "error",
                                 title: "Error al generar registro.",
                                 text: result[0].strMensaje 
                });}
            }) 
        }
   



    getCp(codigoPostal:string,boolFact:boolean=false){
      this.spinnerCol=true;
    this._httpService.getCp(codigoPostal).subscribe({
      next: (data) => {
      
        if(data.zip_codes.length>0){
          let rest =data.zip_codes;
          rest.forEach(col => {
              this.arrayColonias.push({strColonia:col.d_asenta})
            });
              this.formAdress.patchValue({ strEstado: rest[0].d_estado });
          this.formAdress.patchValue({ strCiudad: rest[0].d_mnpio});
          this.formAdress.controls['strCiudad'].markAsTouched();
          this.formAdress.controls['strCiudad'].updateValueAndValidity();
          this.formAdress.controls['strEstado'].markAsTouched();
          this.formAdress.controls['strEstado'].updateValueAndValidity();
        }else{
          Swal.fire("Error","Ingreseun codigo postal valido","error")
            this.formAdress.patchValue({ strEstado: "" });
            this.formAdress.patchValue({ strCiudad: ""});
            this.formAdress.controls['strCiudad'].markAsTouched();
            this.formAdress.controls['strCiudad'].updateValueAndValidity();
            this.formAdress.controls['strEstado'].markAsTouched();
            this.formAdress.controls['strEstado'].updateValueAndValidity();
        }
        this.spinnerCol=false;
      },
      error: (error) => { 
       /*  console.log(error) */
        this.spinnerCol=false;
      }
    });
  }
  onEnter(event: KeyboardEvent) {
    if (event.key === "Enter") {
      let inputElement = event.target as HTMLInputElement;
      this.getCp(inputElement.value)
    }
  }
  updateCantidad(event: Event): void {
    this.arrayColonias=[];
    let inputElement = event.target as HTMLInputElement;
    let valor = inputElement.value;
    const soloNumeros = /^\d*$/;
    if (!soloNumeros.test(valor)){
      inputElement.value = valor.replace(/[^0-9]/g, '');
    }
    this.formAdress.patchValue({ strCodigoPostal: inputElement.value });
    if(inputElement.value.length==5){
      this.getCp(inputElement.value)
    }else{
      this.formAdress.patchValue({ strEstado: "" });
      this.formAdress.patchValue({ strCiudad: "" });
      this.formAdress.patchValue({ strColonia: "" });
    }
  }

  simulateEnter() {
    // Creamos un objeto KeyboardEvent simulado sin la propiedad "which"
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',      // Definimos que la tecla presionada es 'Enter'
      code: 'Enter',     // Establecemos el código de la tecla
      keyCode: 13,       // El código de la tecla "Enter" es 13 (aunque es deprecated, sigue siendo válido)
      bubbles: true,     // Propagación del evento (si es necesario)
    });
  
    // Obtenemos el elemento de input
    const inputElement = document.getElementById('strCodigoPostal') as HTMLInputElement;

    let   strCodigoPostal = this.userData_tienda[0]?this.userData_tienda[0].strCodigoPostal:''
    inputElement.value = strCodigoPostal;
   // Establecemos el target del evento como el input correspondiente
    Object.defineProperty(event, 'target', { value: inputElement });
    // Llamamos a la función onEnter y le pasamos el evento simulado
    this.onEnter(event);
  }
  get f() { return this.formAdress.controls; }
}
