import { Component, OnInit ,ElementRef} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as moment from 'moment';
import 'moment/locale/pt-br';
@Component({
  selector: 'app-objmixsucursal',
  templateUrl: './objmixsucursal.component.html',
  styleUrls: ['./objmixsucursal.component.css']
})
export class ObjmixsucursalComponent implements OnInit {
  public dblTEnero:number=0;
  public dblTFebrero:number=0;
  public dblTMarzo:number=0;
  public dblTAbril:number=0;
  public dblTMayo:number=0;
  public dblTJunio:number=0;
  public dblTJulio:number=0;
  public dblTAgosto:number=0;
  public dblTSeptiembre:number=0;
  public dblTOctubre:number=0;
  public dblTNoviembre:number=0;
  public dblTDiciembre:number=0;
  public dblTSEnero:number=0;
  public dblTSFebrero:number=0;
  public dblTSMarzo:number=0;
  public dblTSAbril:number=0;
  public dblTSMayo:number=0;
  public dblTSJunio:number=0;
  public dblTSJulio:number=0;
  public dblTSAgosto:number=0;
  public dblTSSeptiembre:number=0;
  public dblTSOctubre:number=0;
  public dblTSNoviembre:number=0;
  public dblTSDiciembre:number=0;
  public arrayObjetivoCapturadoSuc:any=[];
  public arrayTotalObj:any;
  public today:any = new Date();
  public arrayEstatusSucursales:any=[];
  public totalSucursales: number=0;
  public totalSucursalesAut: number=0;

 /*
 1.-Obtener el objetivo asignado por juan josé
 2.-Mostrar su Historico de ventas
 3.-Distribuir por linea su objetivo
 4.-Enviar sus objetivos para autorización
 ------------------------------------------
 una vez autorizado su objetivo, habilitar pestaña para distribución de monto, por sucursales
 1.-Poner filtro para sucursales
 2.-Mostrar Historico de ventas para sucursales
 3.-Asignar Montos a cada sucursal
 4.-Autorizar/Rechazar objetivo de sucursales

  */
 public dblObjetivoA:any=0;
 
 public dblObjetivoD:any=0;
 public dblObjetivoG:any=0;
 public arrayObjetivosAux:any=[{strLinea:'ACCESORIOS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
 {strLinea:'BLUE TISSUE',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
 {strLinea:'DESPACHADORES ECODELI',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
 {strLinea:'KIMBERLY',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
 {strLinea:'OTROS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
 {strLinea:'QUIMICOS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
 {strLinea:'RUBBERMAID',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
 {strLinea:'TORK',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
 {strLinea:'XIBU',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0}
];
public strGerente:string='';
public arrayHistorico:any=[];
public arrayAccesos:any=[];
public tagName:any;
public btnAutorizar:any=false;
public btnRechazar:any=false;
public btnGuardarD:any=true;
public strEstatus:string='';
public strEstatusS:string='';

public strEstatusActual:string='';
public strObservaciones:string='';

public strObservacionesS:string='';
public arraySucursales:any=[];
public sucursalSelected:any={};
public dblObjetivoSucursal:any=0;
public arrayHistSucursal:any=[];
public dblObjetivoC:any=0;
public dblObjetivoAS:any=0;
public dblObjetivoAA:any=0;
public dblObjetivoAD:any=0;
public arrayEncabezado:any=[];
public tabSelected :string='';
public arrayGerentesSuc:any=[];
public dblTotalAsigSuc:number=0;
 
  constructor(elem:ElementRef,private _peticionesService: PeticionesService,public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router) { 
    this.tagName=elem.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }
      else { 
      this.strGerente= objAcceso[0].strUsuario;
      this.btnAutorizar=objAcceso.find((o:any)=>o.strAccion=='Autorizar')?true:false;
      this.btnRechazar=objAcceso.find((o:any)=>o.strAccion=='Rechazar')?true:false;
      this.fnGetHistorialActual();
    
      this.fnGetSucursales();
      
      }
    }
  }
  yourFn($event:any){
    this.arrayHistorico=[];
    this.tabSelected=$event.index;
    this.dblObjetivoA=0;
    this.dblObjetivoAD=0;
    this.dblObjetivoG=0;
    this.dblObjetivoD=0;
    if($event.index==0){ 
      this.fnGetHistorialActual();
    } 
    else if($event.index==1){ 
      this.fnGetHistorial();
      this.fnGetTotalEstatus();
    } 
    else if($event.index==2){ 
      this.fnGetObjetivos();
    } 
}
fnGetObjSuc(item:any){
  console.log(item); 
  const date = moment();
  let periodo =  this.today.getMonth()+2;
  let ejercicio = this.today.getFullYear();

  this.arrayGerentesSuc=[];
  this.dblTotalAsigSuc=0;
  this._peticionesService.getApiCompras({
    strAccion: 'getObjAsignadoSuc' ,
    strSucursal:item.strSucursal,
    intPeriodo:periodo==13?1:periodo,
    intEjercicio: periodo==13?ejercicio+1:ejercicio
  }).subscribe(
    result => {  
      this.arrayGerentesSuc=result; 
      this.arrayGerentesSuc.forEach((elemento:any)=> { 
        this.dblTotalAsigSuc=this.dblTotalAsigSuc + elemento.dblObjetivo;
      });
    }
  );
}
  fnFiltrar(){
    this.fnGetTotalObjetivos();//Llamar funcion general para traes estatus y datos actuales
    //obetener estatus y objetivo de la sucursal seleccionada
    let objX =this.arrayTotalObj.find((o: { strSucursal:string; }) =>o.strSucursal==this.sucursalSelected.strSucursal);
    this.dblObjetivoAS=objX.dblObjetivo;
    this.strEstatusS=objX.strEstatus;
    this.strObservacionesS=objX.strObservaciones?objX.strObservaciones:""; //Trae las observaciones
    
    this.fnGetObjetivoSuc(); //obtener los objetivos capturados de cada sucursal
  
  }

  fnGetObjetivoSuc(){
    let periodo =  this.today.getMonth()+2; 
let ejercicio = this.today.getFullYear();

    this.dblObjetivoSucursal=0;
    this._peticionesService.getApiCompras({
      strAccion: 'getHistSucursal', //'getObjetivoSucursal' ,
      strSucursal:this.sucursalSelected.strSucursal,
      intPeriodo :periodo==13?1:periodo, //da 8, más 2 para obtener el siguiente mes
      intEjercicio :periodo==13?ejercicio+1:ejercicio
    }).subscribe(
      result => {  
        console.log(result);
        this.dblObjetivoC=0;         
        this.dblObjetivoSucursal =this.dblObjetivoAS?this.dblObjetivoAS:0;
        result.forEach((elemento:any)=> {            
            this.dblObjetivoC=this.dblObjetivoC + elemento.dblObjetivo;
        
        });
        this.arrayObjetivoCapturadoSuc=result;
      }
    );
  }

  fnGetSucursales(){
    this._peticionesService.getApiCompras({
      strAccion: 'getSucursales' ,
      strGerente:this.strGerente
    }).subscribe(
      result => {  
        this.arraySucursales=result; 
      }
    );
  }

  fnSetObjetivoSuc(){
    let periodo =  this.today.getMonth()+2;
    let ejercicio = this.today.getFullYear();
    this._peticionesService.getApiCompras({
      strAccion: 'setObjetivoSucursalDivisional' ,
      strSucursal:this.sucursalSelected.strSucursal,
      xmlLineas:[{strLinea:'OBJETIVO DIVISIONAL',dblObjetivo:this.dblObjetivoSucursal}],
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
    }).subscribe(
      result => {  
        this._alertify.success('Objetivo Guardado Correctamente'); 
        this.fnGetTotalObjetivos();
        this.fnFiltrar();
      }
    );
  }

  
  fnGetTotalEstatus(){
    this._peticionesService.getApiCompras({
      strAccion: 'getEstatusSucursales',
      strGerente: this.strGerente     
    }).subscribe(
      result => {  
        this.arrayEstatusSucursales=result;
        this.totalSucursales=0;
        this.totalSucursalesAut=0;
        if(this.arrayEstatusSucursales.length>0){
          this.arrayEstatusSucursales.forEach((element:any)=> {  
            if(element.strEstatus=='AUTORIZADO'){
              this.totalSucursalesAut=this.totalSucursalesAut+element.intTotal;
            }
            this.totalSucursales=this.totalSucursales+element.intTotal;
          });
        }
        //console.log(result);
      });
  }

 
  fnGuardar(){ 
    let today = new Date();
    let periodo =   today.getMonth()+2;
    let ejercicio =  today.getFullYear();

    let obj ={
      strAccion: 'setObjetivoDivisional' ,
      strGerente:this.strGerente,
      xmlLineas:this.arrayHistorico,
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio,
    }
    this._peticionesService.getApiCompras(obj).subscribe(
      result => { 
        this.fnGetHistorial();
        this._alertify.success('Objetivo Guardado Correctamente'); 
      });
  }
  fnGetHistorialActual(){
   let obj= {
      strAccion: 'getHistDivisionalActual' ,
      strGerente:this.strGerente,
      intPeriodo:this.today.getMonth()+1,
      intEjercicio:this.today.getFullYear()
    }
    this._peticionesService.getApiCompras(obj).subscribe(
      result => { 
        console.log(result);
          this.arrayHistorico=result;
          this.arrayHistorico.forEach((elemento:any)=> { 

            this.dblTEnero= this.dblTEnero+elemento.dblEnero;
            this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
            this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
            this.dblTAbril= this.dblTAbril+elemento.dblAbril;
            this.dblTMayo= this.dblTMayo+elemento.dblMayo;
            this.dblTJunio= this.dblTJunio+elemento.dblJunio;
            this.dblTJulio= this.dblTJulio+elemento.dblJulio;
            this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
            this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
            this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
            this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
            this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre; 
            
          });
          this.arrayHistorico.forEach((elemento:any)=>{
            let objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Enero');
            elemento.dblEnero && objAux==undefined? this.arrayEncabezado.push({strMes:'Enero', total:this.dblTEnero}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Febrero');
            elemento.dblFebrero && objAux==undefined? this.arrayEncabezado.push({strMes:'Febrero',total:this.dblTFebrero}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Marzo');
            elemento.dblMarzo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Marzo',total:this.dblTMarzo}):0;
  
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Abril');
            elemento.dblAbril&& objAux==undefined? this.arrayEncabezado.push({strMes:'Abril',total:this.dblTAbril}):0;
  
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Mayo');
            elemento.dblMayo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Mayo', total:this.dblTMayo}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Junio');
            elemento.dblJunio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Junio',total:this.dblTJunio}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Julio');
            elemento.dblJulio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Julio',total:this.dblTJulio}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Agosto');
            elemento.dblAgosto&& objAux==undefined? this.arrayEncabezado.push({strMes:'Agosto',total:this.dblTAgosto}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Septiembre');
            elemento.dblSeptiembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Septiembre',total:this.dblTSeptiembre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Octubre');
            elemento.dblOctubre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Octubre',total:this.dblTOctubre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Noviembre');
            elemento.dblNoviembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Noviembre',total:this.dblTNoviembre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Diciembre');
            elemento.dblDiciembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Diciembre',total:this.dblTDiciembre}):0;
          })
          this.fnGetObjetivosActual();
       
      });
  }
  fnGetHistorial(){ 
    let periodo =  this.today.getMonth()+2;
    let ejercicio = this.today.getFullYear();
    let obj={
      strAccion: 'getHistDivisional' ,
      strGerente:this.strGerente,
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
    }
    this._peticionesService.getApiCompras(obj).subscribe(
      result => { 
        console.log(result);
          this.arrayHistorico=result;
          this.arrayHistorico.forEach((elemento:any)=> { 

            this.dblTEnero= this.dblTEnero+elemento.dblEnero;
            this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
            this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
            this.dblTAbril= this.dblTAbril+elemento.dblAbril;
            this.dblTMayo= this.dblTMayo+elemento.dblMayo;
            this.dblTJunio= this.dblTJunio+elemento.dblJunio;
            this.dblTJulio= this.dblTJulio+elemento.dblJulio;
            this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
            this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
            this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
            this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
            this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre; 
           
          });
          this.arrayHistorico.forEach((elemento:any)=>{
            let objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Enero');
            elemento.dblEnero && objAux==undefined? this.arrayEncabezado.push({strMes:'Enero', total:this.dblTEnero}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Febrero');
            elemento.dblFebrero && objAux==undefined? this.arrayEncabezado.push({strMes:'Febrero',total:this.dblTFebrero}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Marzo');
            elemento.dblMarzo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Marzo',total:this.dblTMarzo}):0;
  
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Abril');
            elemento.dblAbril&& objAux==undefined? this.arrayEncabezado.push({strMes:'Abril',total:this.dblTAbril}):0;
  
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Mayo');
            elemento.dblMayo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Mayo', total:this.dblTMayo}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Junio');
            elemento.dblJunio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Junio',total:this.dblTJunio}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Julio');
            elemento.dblJulio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Julio',total:this.dblTJulio}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Agosto');
            elemento.dblAgosto&& objAux==undefined? this.arrayEncabezado.push({strMes:'Agosto',total:this.dblTAgosto}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Septiembre');
            elemento.dblSeptiembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Septiembre',total:this.dblTSeptiembre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Octubre');
            elemento.dblOctubre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Octubre',total:this.dblTOctubre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Noviembre');
            elemento.dblNoviembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Noviembre',total:this.dblTNoviembre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Diciembre');
            elemento.dblDiciembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Diciembre',total:this.dblTDiciembre}):0;
          })
          this.fnGetObjetivos();
       
      });
  }
  fnGetObjetivosActual(){
    let periodo = this.today.getMonth()+1;
    let ejercicio = this.today.getFullYear();
console.log(periodo)
console.log(ejercicio)
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivoDivisionalActual' ,
      strGerente:this.strGerente,
      intPeriodo:periodo,
      intEjercicio:ejercicio
    }).subscribe(
      result => { 
        if(result.length >0){
          let objA =result.find((o: { strLinea: string; }) =>o.strLinea=='OBJETIVO NACIONAL');
        
          this.strEstatusActual=objA.strEstatus;
          this.dblObjetivoA=objA.dblObjetivo;
          console.log(this.dblObjetivoA)
          this.strObservaciones=objA?objA.strSucursal:'';
          this.fnGetTotalObjetivos();
        }
        this.arrayHistorico.forEach((elemento:any)=> {  
          let objA =result.find((o: { strLinea: string; }) =>o.strLinea==elemento.strLinea);
          console.log(objA);
           elemento.dblObjetivo=objA?objA.dblObjetivo:this.dblObjetivoA*elemento.dblPAgente;
          // this.strEstatus=objA.strEstatus;
           
           this.dblObjetivoG=this.dblObjetivoG+ ( objA?objA.dblObjetivo:elemento.dblObjetivo);
         });
         
         this.dblObjetivoD=this.dblObjetivoA-this.dblObjetivoG;
         this.btnGuardarD=( this.strEstatus!='AUTORIZADO')?false:true;
      });
  }

  fnGetObjetivos(){
    let periodo =  this.today.getMonth()+2 ==13?1:this.today.getMonth()+2 ; 
    let ejercicio = this.today.getMonth()+2 ==13?this.today.getFullYear()+1:this.today.getFullYear();
    console.log(periodo)
    console.log(ejercicio)
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivoDivisional' ,
      strGerente:this.strGerente,
      intPeriodo:periodo,
      intEjercicio:ejercicio
    }).subscribe(
      result => { 
        if(result.length >0){
          let objA =result.find((o: { strLinea: string; }) =>o.strLinea=='OBJETIVO NACIONAL');
        
          this.strEstatus=objA.strEstatus;
          this.dblObjetivoA=objA.dblObjetivo;
          this.strObservaciones=objA?objA.strSucursal:'';
          this.fnGetTotalObjetivos();
        }
        this.arrayHistorico.forEach((elemento:any)=> {  
          let objA =result.find((o: { strLinea: string; }) =>o.strLinea==elemento.strLinea);
           elemento.dblObjetivo=objA?objA.dblObjetivo:this.dblObjetivoA*elemento.dblPAgente;
          // this.strEstatus=objA.strEstatus;
           
           this.dblObjetivoG=this.dblObjetivoG+ ( objA?objA.dblObjetivo:elemento.dblObjetivo);
         });
         
         this.dblObjetivoD=this.dblObjetivoA-this.dblObjetivoG;
         this.btnGuardarD=( this.strEstatus!='AUTORIZADO')?false:true;
      });
  }

  fnSumaObjetivo(){
    this.dblObjetivoG=0;
    var algo=0;
    this.arrayHistorico.forEach((elemento:any)=> {  
      algo=algo+parseFloat(elemento.dblObjetivo);
     });
     this.dblObjetivoG=algo;
     this.dblObjetivoD=this.dblObjetivoA-this.dblObjetivoG;
     console.log(this.strEstatus);
     this.btnGuardarD=(this.strEstatus!='AUTORIZADO')?false:true;
  }

  fnAutorizar(estatus:any){
    console.log('autorizarSucursal')
    this._peticionesService.getApiCompras({
      strAccion: 'autorizarSucursal' ,
      strSucursal: this.sucursalSelected.strSucursal,
      strGerente: this.strObservacionesS,
      strEstatus: estatus,
      xmlLineas: this.arrayObjetivoCapturadoSuc,    
      intPeriodo :  this.today.getMonth()+2, //da 8, más 2 para obtener el siguiente mes
      intEjercicio: this.today.getFullYear()
    }).subscribe(
      result => { 
        //console.log(result);
        this.fnGetObjetivoSuc();
        this.fnGetTotalObjetivos();
        //Ocultar botones:
        this.fnFiltrar();
        let msj = 'Objetivo '+estatus+' Correctamente';
        this._alertify.success(msj); 
      });
  }

  

  updateUSInterno(event:any, item:any){
    //this.dblObjetivoD = event.target.value;//Modifica el valor de la variable
    //console.log(this.dblObjetivoD);
    item.dblObjetivo=event.target.value;
    
    this.fnSumaObjetivo();
  }

  updateObjetivoSucursal(event:any){
    this.dblObjetivoSucursal = event.target.value;//Modifica el valor de la variable
  }

  fnGetTotalObjetivos(){
    let periodo =  this.today.getMonth()+2
    let ejercicio = this.today.getFullYear();

    this._peticionesService.getApiCompras({
      strAccion: 'getTotalObjetivosDivisional',
       strGerente:this.strGerente,
       intPeriodo:periodo==13?1:periodo,
       intEjercicio:periodo==13?ejercicio+1:ejercicio
      
    }).subscribe(
      result => {  this.arrayTotalObj=result;
        this.dblObjetivoAA=0;
        this.arrayTotalObj.forEach((elemento:any)=> { 
          this.dblObjetivoAA=this.dblObjetivoAA+elemento.dblObjetivo;
          
        });
        this.dblObjetivoAD=this.dblObjetivoA-this.dblObjetivoAA;
      });
  }
  

}
