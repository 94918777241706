<div class="container">
    <h1>Términos y Condiciones de uso de Ecodeli.com</h1>
    
    <p>Bienvenido a Ecodeli. Al acceder y utilizar nuestro sitio web y comprar productos, usted acepta los siguientes términos y condiciones. Por favor, léalos cuidadosamente.</p>
  
    <h2>CONDICIONES GENERALES</h2>
    
    <p>1. El acceso y uso del sitio web en que se ofrecen los productos Ecodeli Comercial (el “Sitio”), así como los actos jurídicos que se realicen a través de los sistemas de oferta y comercialización de dichos productos, entre el consumidor y el proveedor, y sus efectos jurídicos, se regirán por las presentes Condiciones Generales, por las condiciones específicas de compra de productos y prestación de servicios que se detallarán a continuación, y por la legislación aplicable de los Estados Unidos Mexicanos vigente a la fecha de uso del Sitio.</p>
  
    <h3 class="section-title">Definiciones:</h3>
    <ul>
      <li><strong>“Sitio”:</strong> el sitio web https://ecodeli.com/</li>
      <li><strong>“Consumidor” o “Usuario”:</strong> la persona natural o jurídica, mayor de edad que compra bienes a través de la plataforma electrónica.</li>
      <li><strong>“Productos”:</strong> todos los bienes ofrecidos por el Proveedor a través del Sitio.</li>
      <li><strong>“Proveedor” o “Empresa”:</strong> la sociedad [•] que ofrece bienes a los Consumidores.</li>
    </ul>
  
    <h2>REGISTRO DEL CONSUMIDOR O USUARIO</h2>
  
    <p>La navegación por el Sitio es abierta. No obstante, será requisito necesario para la adquisición de los Productos ofrecidos en el Sitio, la aceptación de estos Términos y Condiciones.</p>
    <p>El Usuario no está obligado a registrarse para adquirir Productos, pero deberá entregar algunos datos personales para completar la transacción, como su nombre completo, correo electrónico, y domicilio para el envío.</p>
  
    <h2>MEDIOS DE PAGO</h2>
    <p>Los medios de pago autorizados son los siguientes:</p>
 
                <ul>
                <li>Tarjetas de crédito</li>
                <li>Tarjetas de débito</li>
                
                </ul>

        <p>Recuerda que todas las transacciones son procesadas por <strong>MercadoPago</strong>, lo que garantiza seguridad y confidencialidad en tus datos.</p>
    <p class="important">El Proveedor no se responsabiliza por la continuidad de los servicios de pago proporcionados por terceros.</p>
  
    <h2>ENVÍO DE LOS PRODUCTOS</h2>
    <p>El envío de los productos adquiridos es realizado por un tercero, con un tiempo estimado de entre 4 a 9 días naturales. Los productos con un valor menor a $1,499 MXN no aplican para promociones de envío gratis.</p>
  
    <h2>POLÍTICA DE DEVOLUCIÓN DE PRODUCTOS</h2>
    <p>El Consumidor tiene derecho a la devolución o cambio de productos, cumpliendo con ciertos requisitos, como devolver el producto en su embalaje original y dentro del plazo legal estipulado.</p>
  
    <h2>OFERTAS Y PROMOCIONES</h2>
    <p>Las promociones del Sitio pueden diferir de las ofertas en otros canales de venta, a menos que se indique lo contrario.</p>
  
    <h2>ASPECTOS LEGALES</h2>
    <h3 class="section-title">Autorización, uso y protección de datos personales:</h3>
    <p>Al registrarse en el Sitio, el Usuario autoriza al Proveedor para el tratamiento de sus datos personales conforme a la legislación vigente.</p>
  
    <h3 class="section-title">Confidencialidad de contraseñas:</h3>
    <p>El Usuario es responsable de mantener la confidencialidad de su contraseña para evitar su uso por terceros.</p>
  
    <h2>SERVICIO DE ATENCIÓN AL CLIENTE</h2>
    <p>El servicio de atención al cliente está disponible en los números 477 729 6302 y 477 788 9800, de lunes a viernes de 8:30 a 18:00 y sábados de 9:00 a 14:00.</p>
  
    <h2>FACTURACIÓN</h2>
    <p>Para emitir una factura, el Usuario debe seleccionar la casilla correspondiente durante el proceso de compra y proporcionar los datos fiscales. También se puede solicitar una factura posterior dentro de un plazo de 72 horas.</p>
  </div>
  
