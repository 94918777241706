<div class="container-fluid">
    <div class="row">
        <div class="col-12 px-0">
            <nav aria-label="breadcrumb" class="px-0 ">
                <ol class="breadcrumb d-flex align-items-center mb-0"> 
                    <li class="breadcrumb-item"><a  (click)="fnAtras()" >Atrás</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Detalle Artículo</li> 
                </ol>
            </nav>
        </div>
    </div>
    <div class="row">
        <div class="col-12 d-flex mt-2">
            <div class="card border-0 card-principal m-auto p-4">
                <div class="row">
                    <div class="col-md-5 col-12 d-flex align-items-center">
                        <div id="carouselExampleIndicators" class="carousel slide w-100" data-bs-ride="false">
                            <div class="carousel-indicators">
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            </div>
                            <div class="carousel-inner rounded">
                                <div class="carousel-item active ">
                                  <img  (error)="onImageError($event)" [src]='objArticulo.strUrlImg1' class="d-block mx-auto rounded " alt="..." width="100%">
                                </div>
                                <div class="carousel-item">
                                    <img  (error)="onImageError($event)" [src]='objArticulo.strUrlImg2' class="d-block mx-auto rounded" alt="..." width="100%">
                                </div>
                                <div class="carousel-item">
                                    <img  (error)="onImageError($event)" [src]='objArticulo.strUrlImg3' class="d-block mx-auto rounded" alt="..." width="100%">
                                </div>
                                <div class="carousel-item">
                                    <img  (error)="onImageError($event)" [src]='objArticulo.strUrlImg4' class="d-block mx-auto rounded" alt="..." width="100%">
                                </div>
                                <div class="carousel-item">
                                     <img  (error)="onImageError($event)" [src]='objArticulo.strUrlImg5' class="d-block mx-auto rounded" alt="..." width="100%">
                                </div>
                            </div>
                            <button class="carousel-control-prev text-dark" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <i class="bi bi-chevron-left" style="font-size: 2rem; color: black;"></i>
                              <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next text-dark" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <i class="bi bi-chevron-right" style="font-size: 2rem; color: black;"></i>
                              <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-7 col-12">
                        <div class="row">
                            <div class="col-md-5 border-end col-12 d-flex flex-column">
                                <span class="d-flex" >
                                   <span class="me-2">clave:</span> <h1 class="fw-bold art_titulo">{{this.objArticulo.strArticulo}}</h1>
                                </span>
                            
                                <span @fadeInOut>Estatus Nacional: <span class="badge p-2  text-bg-secondary" [ngClass]="{
                                    'text-bg-info': objData_Art.strEstatus === 'SOBRE PEDIDO',
                                    'text-bg-success': objData_Art.strEstatus === 'ALTA',
                                    'text-bg-danger': objData_Art.strEstatus === 'BAJA',
                                    'text-bg-warning text-white': objData_Art.strEstatus === 'DESCONTINUADO'}">{{ objData_Art.strEstatus}}</span></span>
                                <span class="mt-3" *ngIf="strEstatus_Almacen=='SOBREPEDIDO'" @fadeInOut>
                                    <span class="badge W-50  p-2 text-bg-info" >{{strEstatus_Almacen}}</span>
                                </span>
                                <span @fadeInOut class="mt-3 text-info fw-bold" *ngIf="strEstatus_Almacen=='SOBREPEDIDO'">* De 30 a 45 Dias Hábiles</span>
                                <span @fadeInOut class="mt-3 text-danger fw-bold" *ngIf="objData_Art.strSustituto !=''">
                                     *LA CLAVE BUSCADA SE RECOMIENDA SUSTITUIR POR <a class="text-primary text-decoration-underline" (click)="addToCart({strArticulo:objData_Art.strSustituto})"> {{objData_Art.strSustituto}}</a>
                                </span>
                                <span @fadeInOut class="bg-danger text-white shadow rounded fw-bold align-self-start px-2" *ngIf="boolAgotado">
                                    PRODUCTO AGOTADO
                                </span>
                            </div>
                            <div class="col-md-7 col-12">
                                <div class="row" *ngIf="boolBtn_Costo">
                                    <div class="col-12 mb-3 mb-xl-0 col-xl-6 d-flex flex-column">
                                        <label for="" class="fw-bold text-secondary text-center">Costo Neto</label>
                                        <label class="fw-bold text-dark rounded bg-light shadow-sm text-center">{{objData_Art.dblCostoNeto|currency}}</label>
                                    </div>
                                    <div class="col-12 col-xl-6 d-flex flex-column">
                                        <label for="" class="fw-bold text-secondary text-center">Costo Teorico</label>
                                        <label class="fw-bold text-dark rounded bg-light shadow-sm text-center">{{objData_Art.dblCostoTeorico|currency}}</label>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center">
                                        <div class="col-11">
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex">
                                    
                                    <div class=" m-auto col-12 d-flex flex-column mb-2" [ngClass]="{'col-xl-6':objData_Art.strTipoOpcion=='Si'}">
                                        <label class=" mb-1">Precio:</label>
                                        <select name="categoria" id="" class="form-select w-100 " [(ngModel)]="dblPrecio_Selected">
                                            <ng-container  *ngFor="let precio of arrayPrecio">
                                               
                                                <optgroup label="{{precio.nombre}}" [ngClass]="{'d-none':(precio.nombre == 'Precio 4' && !boolBtn_Precio4)||(precio.nombre == 'Precio Minimo' && !boolBtn_preciominimo)||precio.valor==0}"><!--  se agrego precio minimo -->
                                                    <option  [value]="precio.valor" *ngIf="precio.valor >0">
                                                        {{ precio.valor | currency}}                                            
                                                    </option>
                                                </optgroup>
                                            </ng-container>
                                           
                                        </select>
                                    </div>
                                    <div class="col-12 col-xl-6 d-flex flex-column" *ngIf="objData_Art.strTipoOpcion=='Si'">
                                        <label class=" mb-1">Opciones:</label>
                                        <mat-select name="categoria" id="" class="form-control w-100" [(ngModel)]="strOpcion_Selected" (selectionChange)="fnCambio_opcion()">                                      
                                            <mat-option  [value]="opcion.strOpcion" *ngFor="let opcion of arrayOpciones"    >
                                                <div class="d-flex">
                                                    <span><span class="fw-bold me-2">({{opcion.strOpcion}})</span>{{ opcion.strNombreOpcion }} </span> 
                                                    <span class="ms-auto text-danger" *ngIf="opcion.strEstatus=='DESCONTINUADO'">{{opcion.strEstatus}}</span>
                                                </div>
                                               
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-12 mt-3 d-xl-flex">
                                        <div class="col-12 col-xl-6 text-center " *ngIf="boolBtn_Comparar">
                                            <button class="btn btn-primary d-flex mx-auto" data-bs-toggle="modal" data-bs-target="#mdlComparar" (click)="fnGetCompartivoArts();">
                                                <i class="bi bi-arrows-angle-contract pe-2"></i> Comparar
                                            </button>
                                        </div>
                                       <!--  <div class="col-12 col-xl-6 text-center " *ngIf="boolBtn_Comparar">
                                            <button class="btn btn-primary d-flex mx-auto" data-bs-toggle="modal" data-bs-target="#mdlComparar" (click)="fnGetCostos();">
                                                <i class="bi bi-arrows-angle-contract pe-2"></i> Costos
                                            </button>
                                        </div>-->
                                        <div class="col-12 col-xl-6  mt-xl-0 mt-3" *ngIf="boolBtn_CPrecion">
                                            <button class="btn btn-primary d-flex  mx-auto" data-bs-toggle="modal" data-bs-target="#mdlCambiarPrecio">
                                                <i class="bi bi-currency-exchange"></i> Cambiar Precio
                                            </button>
                                        </div>
                                           <!--  
                                        <span class="text-info">Comparar</span><h3 class="fw-bold mb-0 my-2"></h3>-->
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex mt-3">
                                <span class="py-3 description">{{objData_Art.strDescripcion}}</span>
                            </div>
                            <div class="col-12 d-flex my-4 justify-content-between">
                                <div class="col-5 border-start px-0 border-end d-flex flex-column text-center">
                                    <span class="text-info fs-18 border-bottom mb-2"><a data-bs-toggle="modal" data-bs-target="#existenciaModal">Existencias </a></span> 
                                    <div class="row ">
                                        <div class="col-6 fw-bold m-auto pe-0">
                                            <div class="border-end">
                                                <span  >{{dblDisponible_nacional - dblReservado_nacional | number:'1.2-2' }}</span>
                                                <span class="titulo_disponible_1">Disponibles</span>
                                            </div>
                                        </div>
                                        <div class="col-6  fw-bold m-auto ps-0">
                                            <div>
                                                <span class="text-secondary">{{dblReservado_nacional | number:'1.2-2' }}</span>
                                                <span class="titulo_disponible_1">Reservados</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5  px-0 border-end border-start d-flex flex-column text-center"> 
                                    <span class="text-info  fs-18 border-bottom mb-2 ">En tu almacen</span>
                                    <div class="row">
                                        <div class="col-6 fw-bold m-auto pe-0 ">
                                            <div class="border-end">
                                                <span  >{{((objDisponible_almacen && objDisponible_almacen.dblCantidad ? objDisponible_almacen.dblCantidad :0) -(objDisponible_almacen && objDisponible_almacen.dblReservado?objDisponible_almacen.dblReservado:0)) | number:'1.2-2' }}</span>
                                                <span class="titulo_disponible_1">Disponibles</span>
                                            </div>
                                        </div>
                                        <div class="col-6  fw-bold m-auto ps-0 ">
                                            <div>
                                                <span class="text-secondary">{{(objDisponible_almacen&&objDisponible_almacen.dblReservado ?objDisponible_almacen.dblReservado:0) | number:'1.2-2' }}</span>
                                                <span class="titulo_disponible_1">Reservados</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-12 d-md-flex align-items-center mt-5">
                                <div class="col-12 col-md-6 d-flex flex-column">
                                    <div class="input-group rounded-pill mx-auto w-75">
                                        <button class="btn  btnCirs" (click)="fnAdd_Rest_Amount(objData_Art,'rest');" type="button">-</button>
                                        <input type="number" value="0" #inputRef (keydown)="restrictInput($event,inputRef,(dblDisponible_nacional - dblReservado_nacional))" [(ngModel)]="objData_Art.dblCantidad" class="form-control text-center" placeholder="" aria-label="Recipient's username with two button addons">
                                        <button class="btn  btnCire" (click)="fnAdd_Rest_Amount(objData_Art,'add');" type="button">+</button>
                                    </div>
                                    <div class="col-12  p-0 d-flex justify-content-center" *ngIf="(objData_Art.dblCantidad>(dblDisponible_nacional - dblReservado_nacional)) && (dblDisponible_nacional - dblReservado_nacional)>0 ">
                                        <div class="col-10 alert alert-danger p-0 mt-2">
                                            La cantidad excede la existencia
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 my-3 my-md-0 "  > <!--[ngClass]="{'d-none':
                                    objData_Art?.dblCantidad > objDisponible_almacen?.dblCantidad && 
                                    objOpcion?.strEstatus  == 'DESCONTINUADO','d-flex':objData_Art?.dblCantidad <= objDisponible_almacen?.dblCantidad}" -->
                                    <button *ngIf="!boolBtn_Precio_Partner"  class="btn btn-success mx-auto w-75" (click)="fnAddToCarr(objData_Art);"><i class="bi bi-cart3"   ></i>Agregar a Carrito</button> 
                                   
                                    <button  *ngIf="boolBtn_Precio_Partner" class="btn btn-success mx-auto w-75" 
                                    [disabled]="((dblDisponible_nacional - dblReservado_nacional)<1 ) || (objData_Art.dblCantidad>(dblDisponible_nacional - dblReservado_nacional))
                                        || objData_Art.dblCantidad < 1 
                                        || objDisponible_almacen.dblCantidad -objDisponible_almacen.dblReservado<1
                                        " 
                                        (click)="fnAddToCarr_partner(objData_Art);">
                                        <i class="bi bi-cart3 me-1"   ></i>Agregar a Carrito</button>  <br>
                                    <!--<b *ngIf="boolBtn_Precio_Partner">{{objDescuento_Parner.mensaje_mostrar}}</b>-->
                                </div>
                               
                             </div>
                             <div class="col-12 col-md-6">

                             </div>
                             <div class="col-12 col-md-6 px-0" >
                                <div class="col-12 mt-4 px-0"> 
                                    <button  *ngIf="boolBtn_Precio_Partner" id="btnAgregarArt" #btnAgregarArt class="btn btn-info mx-auto w-75" 
                                    [disabled]="((dblDisponible_nacional - dblReservado_nacional)<1 ) 
                                    || (objData_Art.dblCantidad>(dblDisponible_nacional - dblReservado_nacional))
                                    || objData_Art.dblCantidad < 1
                                    || (objDisponible_almacen.dblCantidad -objDisponible_almacen.dblReservado )<1" 
                                        (click)="fnAddToCarr_partner_cotizador(objData_Art);">
                                        <i class="bi bi-file-earmark-richtext me-1"></i> Agregar a Cotización</button>
                                        <br>
                           
                                        <span *ngIf="!(objDisponible_almacen.dblCantidad -objDisponible_almacen.dblReservado) && boolBtn_Precio_Partner" class="text-danger" style="font-size: 12px;">*Elige una dirección distinta de entrega</span>
                                 </div>
                             </div>
                             
                            <div class="col-12"> 
                                <div class="table-responsive mt-4 px-0 shadow-sm rounded">
                                    <table class="table table-sm  ">
                                        <thead>
                                            <tr class="text-center">
                                                <th scope="col" class="table-primary" colspan="4">INFORMACIÓN DEL PRODUCTO
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Marca</th>
                                                <td>{{objData_Art.strFamilia}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">SubLinea</th>
                                                <td>{{objData_Art.strGrupo}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Unidad</th>
                                                <td>{{objData_Art.strUnidad}}</td>
                                            </tr>
                                            <tr *ngIf="objData_Art.strFichaTecnica=='1'">
                                                <th scope="row">Ficha Tecnica</th>
                                                <td  >  
                                                    <a href="https://www.ecodeli.com/catalogos/FichasTecnicas/{{objData_Art.strArticulo}}.pdf" target="_blank">
                                                        <span class="material-symbols-outlined">
                                                          download_for_offline
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr  *ngIf="objData_Art.strHojaSeguridad=='1'">
                                                <th scope="row">Hoja de Seguridad:</th>
                                                <td  >  
                                                    <a href="https://www.ecodeli.com/catalogos/HojasSeguridad/{{objData_Art.strArticulo}}.pdf" target="_blank">
                                                        <span class="material-symbols-outlined">
                                                          download_for_offline
                                                        </span>
                                                      </a>
                                                </td>
                                            </tr>
                                            <tr  *ngIf="objData_Art.strFichaBiodegradable=='1'">
                                                <th scope="row">Hoja Biodegradable:</th>
                                                <td  > 
                                                    <a href="https://www.ecodeli.com/catalogos/HojaBiodegradable/{{objData_Art.strArticulo}}.pdf" target="_blank">
                                                        <span class="material-symbols-outlined">
                                                          download_for_offline
                                                        </span>
                                                      </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Estatus Artículo Almacen:</th>
                                                <td  [ngClass]="{
                                                    'text-info': strEstatus_Almacen === 'SOBRE PEDIDO',
                                                    'text-success': strEstatus_Almacen === 'ALTA',
                                                    'text-danger': strEstatus_Almacen === 'BAJA',
                                                    'text-warning': strEstatus_Almacen === 'DESCONTINUADO'
                                                }">{{strEstatus_Almacen}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5 " *ngIf="arraySusPrincipal.length>0">
        <div class="col-12 text-center">
            <h1 class="titulo">PRODUCTOS SUSTITUTOS</h1>
        </div>
        <div class="col-12 d-flex">
            <div class=" w-100 ">
                <div class="row">
                    <div class="col-12 d-flex ">
                        <div id="carouselProdcSus" class="carousel slide w-100 " data-bs-ride="carousel" data-bs-ride="false">
                         <!--    <div class="carousel-inner d-flex">
                                <div class="carousel-item mt-1" [ngClass]="{'active': i === 0}"    *ngFor="let bloq of bloques; let i = index;">
                                    <div class="row justify-content-evenly">
                                        <div class="mt-4 text-center px-0 d-flex flex-column roundedcrdbottom" [ngClass]="{'col-2': elementosPorPagina === 6||elementosPorPagina === 5,'col-3': elementosPorPagina === 4 || elementosPorPagina === 3, 'col-5': elementosPorPagina === 2  }"  *ngFor="let x of bloq">
                                            <img class="w-100 imgitemcar" [src]="x.strArticulo" alt="">
                                            <span class="text-secondary p-1 fw-bold fs4 categoriass h-100 d-flex align-items-center justify-content-center roundedcrdbottom">
                                                {{x.strArticulo}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="carousel-inner rounded py-3">
                                <div class="carousel-item  " [ngClass]="{'active': i === 0}"    *ngFor="let bloq of arraySusPrincipal; let i = index;">
                                    <div class="row justify-content-center">
                                    <div class="col-12 col-lg-10 d-flex justify-content-center">
                                        <div class="col-3 d-flex" *ngFor="let imgs of bloq.arts;" [ngClass]="{'d-none':imgs.strArticulo == undefined}">
                                            <div class="w-90 mx-auto shadow rounded position-relative d-flex flex-column cursorPointer" (click)="addToCart(imgs)">
                                                <div class="col-12 bg-white">
                                                    <img (error)="onImageError($event)" [src]="imgs.url" class="w-100  zoom-img" alt="...">
                                                </div>
                                            
                                                <span class="position-absolute top-0 start-0 clsArts fw-bold shadow-sm px-3 py-1" >{{imgs.strArticulo}}</span>
                                                <div class="col-12  fs13 w-100 py-1 px-1 rounded-bottom mt-auto">
                                                    <span  class="text-dark">{{imgs.strDescripcion}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev text-dark" type="button" data-bs-target="#carouselProdcSus" data-bs-slide="prev">
                                <i class="bi bi-chevron-left" style="font-size: 2rem; color: black;"></i>
                              <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next text-dark" type="button" data-bs-target="#carouselProdcSus" data-bs-slide="next">
                                <i class="bi bi-chevron-right" style="font-size: 2rem; color: black;"></i>
                              <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-4" *ngIf="arrayRelacionadosPrincipal.length>0">
        <div class="col-12 text-center">
            <h1 class="titulo">ARTICULOS RELACIONADOS</h1>
        </div>
        <div class="col-12 d-flex">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 d-flex ">
                        <div id="cars" class="carousel slide w-100 " data-bs-ride="false" >
                            <!-- <div class="carousel-inner d-flex">
                                <div class="carousel-item mt-1" [ngClass]="{'active': i === 0}"
                                    *ngFor="let bloq of bloques; let i = index;">
                                    <div class="row justify-content-evenly">
                                        <div class="mt-4 text-center px-0 d-flex flex-column roundedcrdbottom"
                                            [ngClass]="{
                                         
                                         'col2': elementosPorPagina === 6,
                                         'col-2': elementosPorPagina === 5,
                                         'col3': elementosPorPagina === 4,
                                         'col-3': elementosPorPagina === 3,
                                         'col-5': elementosPorPagina === 2
                                       }" *ngFor="let x of bloq">
                                            <img class="w-100 imgitemcar" [src]="x.strCategoria" alt="">
                                            <span
                                                class="text-secondary p-1 fw-bold fs4 categoriass h-100 d-flex align-items-center justify-content-center roundedcrdbottom">
                                                {{x.strCategoria}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev clsIconCarousel" type="button"
                                data-bs-target="#carouselArtsRelacionados" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden text-secondary">Previous</span>
                            </button>
                            <button class="carousel-control-next clsIconCarousel" type="button"
                                data-bs-target="#carouselArtsRelacionados" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button> -->
                            <div class="carousel-inner rounded">
                                <div class="carousel-item  " [ngClass]="{'active': i === 0}"    *ngFor="let bloq of arrayRelacionadosPrincipal; let i = index;">
                                    <div class="row justify-content-center">
                                        <div class="col-12 col-lg-10 d-flex justify-content-center py-3" >
                                            <div class="col-3 d-flex  " *ngFor="let imgs of bloq.arts;" [ngClass]="{'d-none':imgs.strArticulo == undefined}">
                                                <div class="w-90 mx-auto shadow  rounded position-relative d-flex flex-column cursorPointer" (click)="addToCart(imgs)">
                                                    <div class="col-12 bg-white">
                                                        <img (error)="onImageError($event)" [src]="imgs.url" class="w-100  zoom-img" alt="...">
                                                    </div>
                                                    <span class="position-absolute top-0 start-0 clsArts fw-bold shadow-sm px-3 py-1">{{imgs.strArticulo}}</span>
                                                    <div class="col-12  fs13 w-100 py-1 px-1 rounded-bottom mt-auto">
                                                        <span  class="text-dark">{{imgs.strDescripcion}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <button class="carousel-control-prev text-dark" type="button" data-bs-target="#cars" data-bs-slide="prev">
                                <i class="bi bi-chevron-left" style="font-size: 2rem; color: black;"></i>
                              <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next text-dark" type="button" data-bs-target="#cars" data-bs-slide="next">
                                <i class="bi bi-chevron-right" style="font-size: 2rem; color: black;"></i>
                              <span class="visually-hidden">Next</span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div> 
 
</div>
<!-- Modal -->
<div class="modal fade" id="existenciaModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Existencias</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="table-responsive">
                <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th scope="col">Disponibles</th>
                    <th scope="col">Sin Reservar</th>
                    <th scope="col">Reservado</th>
                    <th scope="col">Almacén</th>
                    <th scope="col">Nombre</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let saldo of arraySaldoUFilter">
                    <th scope="row">{{saldo.dblCantidad  - saldo.dblReservado  | number:'1.2-2' }}  </th>
                    <td>{{saldo.dblCantidad  | number:'1.2-2' }}</td>
                    <td>{{saldo.dblReservado  | number:'1.2-2' }}</td>
                    <td><b>{{saldo.strAlmacen    }}</b></td>
                    <td>{{saldo.strNombreAlmacen    }}</td>
                  </tr>
                 
                </tbody>
               </table>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
     
        </div>
      </div>
    </div>
  </div>

  <!-- Modal comparar-->
<div class="modal fade" id="mdlComparar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header modalMdl p-2">
          <h2 class="modal-title mb-0 mx-auto " id="exampleModalLabel">Comparativo de Articulos</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="mt-3 col-6 col-md-4 col-xl-3 d-flex justify-content-center" *ngFor="let art of arrayCompararArts">
                    <div class="col-10 shadow bg-light d-flex flex-column position-relative">
                        <span class="position-absolute top-0 end-0 fs12 bg-secondary fw-bold text-white ps-2 roundARTS mt-2 pe-1">
                            {{art.strArticulo}}
                        </span>
                        <div class="text-center bg-white">
                            <img [src]="'https://www.cleanclean.mx/imagenes/'+art.strArticulo+'.JPG'" class="w-75" alt="">
                        </div>
                        <span class="fs12 fw-bold nunito px-1 descripcion-truncada">
                            {{art.strDescripcion1}}
                        </span>
                        <div class="col-12 mt-auto">
                            <div class="col-10 mx-auto">
                                <hr class="my-2">
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column px-1 fs13">
                            <span class="d-flex">Precio Lista: <span class="ms-auto">{{art.dblPrecioLista|currency}}</span></span>
                            <span class="fw-bold mt-2 col-12 text-center">Existencia</span>
                            <span class="d-flex">
                                Tu Almacen: <span class="ms-auto">{{art.dblExistencia|number:'1.0-0'}}</span></span>
                            <span class="d-flex mt-2">Nacional: <span class="ms-auto">{{art.dblExistenciaNacional|number:'1.0-0'}}</span> </span>
                        </div>
                        <div class="col-12  mt-2 mb-3  d-flex">
                            <button class="btn btn-outline-primary mx-auto w-75 text-nowrap fs12 d-flex justify-content-center" (click)="fnAddToCarr(art);"><i class="bi bi-cart3 "   ></i> <span class="d-none d-lg-flex">Agregar a Carrito</span> <span class="d-lg-none">Agregar</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer modalMdl">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

    <!-- Modal Cambiar Precio-->
<div class="modal fade" id="mdlCambiarPrecio" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modalMdl  p-2">
            <h2 class="modal-title mb-0 mx-auto" id="exampleModalLabel">Cambiar Precio</h2>
            <button type="button" class="btn-close ms-0 " data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col12">
                <div class="table-responsive mb-0 shadow-sm rounded">
                    <table class="table w-100">
                        <thead>
                            <tr class="thcenter ">
                                <th class="bgModal text-white">Tipo de precio</th>
                                <th class="bgModal text-white">Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center align-middle">Precio de Lista</td>
                                <td> <input type="number" class="form-control sin-flechas text-end" [(ngModel)]="objData_Art.dblPrecioLista"></td>
                            </tr>
                            <tr>
                                <td class="text-center align-middle">Precio 2</td>
                                <td> <input type="number" class="form-control sin-flechas text-end" [(ngModel)]="objData_Art.dblPrecio2"></td>
                            </tr>
                            <tr>
                                <td class="text-center align-middle">Precio 3</td>
                                <td> <input type="number" class="form-control sin-flechas text-end" [(ngModel)]="objData_Art.dblPrecio3"></td>
                            </tr>
                            <tr>
                                <td class="text-center align-middle">Precio 4</td>
                                <td> <input type="number" class="form-control sin-flechas text-end" [(ngModel)]="objData_Art.dblPrecio4"></td>
                            </tr>
                            <tr>
                                <td class="text-center align-middle">Precio 5</td>
                                <td> <input type="number" class="form-control sin-flechas text-end" [(ngModel)]="objData_Art.dblPrecio5"></td>
                            </tr>
                         
                            <tr>
                                <td class="text-center align-middle">Precio Mínimo</td>
                                <td> <input type="number" class="form-control sin-flechas text-end" [(ngModel)]="objData_Art.dblPrecioMinimo"></td>
                            </tr>
                            <tr>
                                <td class="text-center align-middle">Precio Partner</td>
                                <td> <input type="number" class="form-control sin-flechas text-end" [(ngModel)]="objData_Art.dblPrecio9"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer modalMdl p-2">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #mdlCambPrecio>Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="fnGetCambiarPrecioArts()">Guardar</button>
        </div>
      </div>
    </div>
  </div>