<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold d-flex justify-content-center nunito"><span>Reporte </span><span class="ms-2 titulo text-decoration-underline"> Remisiones</span> </h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow-sm pb-3">
        <div class="col-12 bgtable2 py-1 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="">
                <mat-option value="">-- Todos --</mat-option>
                <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option>
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente || boolSucursal || boolAgente }">
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnFiltrar ()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
<div class="row my-3">
    <div class="col-12 borderbott px-0">
        <div class="col-12 bgtable bordertop d-flex align-items-center justify-content-between">
            <mat-form-field appearance="outline" class="my-2 ms-3 bg-white rounded ">
                <mat-label>Buscar</mat-label>
                <input class="text-dark" matInput (keyup)="applyFilter($event)" placeholder="Cliente, movId, Agente, etcc." #input>
            </mat-form-field>
        </div>
        <div class="table-responsive mb-0 shadow-sm  ">
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" class="table w-100 table-bordered">
                    <!-- ID Column -->
                    <ng-container matColumnDef="intID">
                        <th mat-header-cell *matHeaderCellDef  class="px-2 bgtable text-center"> Sucursal </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13"> {{element.strSucursal}} </td>
                    </ng-container>

                    <!-- Sucursal Column -->
                    <ng-container matColumnDef="strSucursal">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center">Cliente / Sucursal </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center"> ({{element.strCliente}}) {{element.strSucursalCliente}} </td>
                    </ng-container>
  
                    <!-- Almacen Column -->
                    <ng-container matColumnDef="strAlmacen">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center"> Almacén </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center"> ({{element.strAlmacen}})  {{element.strAlmacenNombre}} </td>
                    </ng-container>
  
                    <!-- Cliente Column -->
                    <ng-container matColumnDef="strCliente">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center"> Cliente </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center"> ({{element.strCliente}}) {{element.strClienteNombre}}</td>
                    </ng-container>
  
                    <!-- MovID Column -->
                    <ng-container matColumnDef="strMovID">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center">Origen ID / Movimiento ID </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center fw-bold"> {{element.strOrigenID}}/ {{element.strMovID}} </td>
                    </ng-container>
  
                    <!-- FechaFechaEmision Column -->
                    <ng-container matColumnDef="strFechaFechaEmision">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center"> Fecha de Emisión </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center "> {{element.strFechaEmision}} </td>
                    </ng-container>
  
                    <!-- Referencia Column -->
                    <ng-container matColumnDef="strReferencia">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center"> Referencia </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center"> {{element.strReferencia}} </td>
                    </ng-container>
  
                    <!-- OrdenCompra Column -->
                    <ng-container matColumnDef="strOrdenCompra">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center"> Orden de Compra </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center"> {{element.strOrdenCompra}} </td>
                    </ng-container>
  
                    <!-- Importe Column -->
                    <ng-container matColumnDef="dblImporte">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center"> Importe </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-end"> {{element.dblImporte|currency}}</td>
                    </ng-container>
  
                    <!-- CteEnviarA Column -->
                    <ng-container matColumnDef="intCteEnviarA">
                        <th mat-header-cell *matHeaderCellDef class="px-2 bgtable text-center"> Enviar A </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center"> 
                            <span *ngIf="element.intCteEnviarA>0"><b>({{element.intCteEnviarA}})</b> {{element.strCteEnviarANombre}}</span>
                            </td>
                    </ng-container>
  
                    <!-- CteEnviarAnombre Column -->
                    <ng-container matColumnDef="strCteEnviarAnombre">
                        <th mat-header-cell *matHeaderCellDef  class="px-2 bgtable text-center"> Nombre del Destinatario </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center"> {{element.strCteEnviarAnombre}} </td>
                    </ng-container>
  
                    <!-- DireccionEvidencia Column -->
                    <ng-container matColumnDef="strDireccionEvidencia">
                        <th mat-header-cell *matHeaderCellDef class="px-2 pe-0 bgtable text-center"> Dirección de Evidencia </th>
                        <td mat-cell *matCellDef="let element" class="px-2 fs13 text-center pe-0"  >
                            <a  *ngIf = "element.strDireccionEvidencia!=''" href="{{element.strDireccionEvidencia}}" download="nombre-de-descarga.pdf" target="_blank" title="{{element.strDireccionEvidencia}}">Ver Evidencia</a>
                             
                        </td>
                    </ng-container>
  
              
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              
                
              </div>
              
        </div>
        <mat-paginator class="bgtable borderbott" [pageSizeOptions]="[5, 10, ]"showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>

</div>