import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Form, FormBuilder, FormControl, FormGroup, MinLengthValidator, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DataDBService } from '../servicios/data-db.service';
import * as html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { filter } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import autoTable from 'jspdf-autotable';



declare var $: any;
export interface UserData {
  strCliente: string;
  strNombreEmpresa: string;
  strRFC: string;
  strGiro: string;
  strEstatus: string;
  strContacto_Nombre: string;
  intSucursales: string;
  strEstatus_Agente: string;
  strAgente: string;
}

export interface data {
  indice: string;
  img: string;
  strArticulo: string;
  strDescripcion: string;
  strUnidad: string;
  dblPrecio: string;
  strTipo: string;
  strLista: string;
}

export interface ctaCte {
  indice: string;
  cliente: string;
  movimiento: string;
  fechaEmision: string;
  fechavencimiento: string;
  dias: string;
  saldoCorriente: string;
  a15dias: string;
  a30dias: string;
  a60dias: string;
  a90dias: string;
  mas90dias: string;
}
export interface tareasCte {
  origen: string;
  titulo: string;
  responsable: string;
  fechaInicio: string;
  fechaVencimiento: string;
  progreso: string;
  btnRadio: string;
}

export interface historicovtaxlinea {
  ejercicio: number;
  periodo: number;
  acces: number;
  margenA: number;
  kimberly: number;
  margenk: number;
  rubermaid: number;
  margenru: number;
  bluetisue: number;
  margenblu: number;
  quimicos: number;
  margenqu: number;
  despachadores: number;
  margendes: number;
  tork: number;
  margentork: number;
  xibu: number;
  margenx: number;
  foaom: number;
  margenf: number;
  total: number;
}
export interface artsFavoritos {
  strArticulo: string;
  strOpcion: string;
  strDescripcion: string;
  strLinea: string;
  dblCantidad: string;
}

@Component({
  selector: 'app-detalle-familia',
  templateUrl: './detalle-familia.component.html',
  styleUrls: ['./detalle-familia.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '*',
        opacity: 1,
        display: 'block'
      })),
      state('out', style({
        height: '0px',
        opacity: 0,
        display: 'none'
      })),
      transition('in => out', [
        animate('300ms ease-in-out')
      ]),
      transition('out => in', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})

export class DetalleFamiliaComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['strCliente', 'strNombreEmpresa', 'strRFC', 'strGiro', 'strContacto_Nombre', 'intSucursales', 'strEstatus', 'strAgente', 'strEstatus_Agente'];
  displayedColumnsCotizacion: string[] = ['indice', 'img', 'strArticulo', 'strDescripcion', 'strUnidad', 'dblPrecio', 'strTipo', 'strLista'];
  displayedColumnsCtaCte: string[] = ['indice', 'cliente', 'movimiento', 'fechaEmision', 'fechavencimiento', 'dias', 'saldoCorriente', 'a15dias', 'a30dias', 'a60dias', 'a90dias', 'mas90dias'];
  displayhisvtas: string[] = ['ejercicio', 'periodo', 'acces', 'margenA', 'kimberly', 'margenk', 'rubermaid', 'margenru', 'bluetisue', 'margenblu', 'quimicos', 'margenqu', 'despachadores', 'margendes', 'tork', 'margentork', 'xibu', 'margenx', 'foaom', 'margenf', 'total'];
  displayedColumnsProductosFavoritos: string[] = ['strArticulo', 'strOpcion', 'strDescripcion', 'strLinea', 'dblCantidad'];
  displayedColumnsProductosFavoritosDetalle: string[] = ['strArticulo', 'strDescripcion', 'strOpcion', 'dblCantidad', 'dblPrecio', 'dblImporte', 'strFechaCreacion'];
  public banderaCrearLead: any = false
  percentage: any = 0;
  public arrayComentarios: any = []
  gaugeAngle: number = 90; // 50% de 180° es 90°
  indicatorAngle: number = -108; // Ajuste inicial para el indicador
  columnsTareasCte: string[] = ['btnRadio', 'titulo', 'responsable', 'origen', 'fechaInicio'];/* , 'fechaVencimiento' ,'progreso'*/
  public strComentarioD: any = ''
  dataSource: MatTableDataSource<UserData>;
  dataSourceC: MatTableDataSource<data>;
  dataSourceFavoritos: MatTableDataSource<artsFavoritos>;
  dataSourceFavoritosDetalle: MatTableDataSource<data>;
  TableCtaCte: MatTableDataSource<ctaCte>;
  public arrayEnviarAnexosActividades: any = []
  datosTareasCte: MatTableDataSource<tareasCte>;
  public arrayPedidos: any = []
  public arrayLineasHistVentaNew: any = [];
  public arrayRemisiones: any = []
  public arrayRFC: any = []
  public arrayVentasConsigna: any = []
  public arrayComodatos: any = [];
  public arrayAnexosModal: any = []
  public intCantidadPedidos: any = 0
  public intCantidadRemisiones: any = 0
  public intCantidadVentasConsigna: any = 0
  public intCantidadComodato: any = 0
  public strAgente_Crea: string;
  public isHoveredButton: boolean;
  /*variables para editar-elimnar actividades */
  public objEditarActividad: any = {};
  public strfecha_editar: string;
  public boolPolitica: boolean = false;
  public strBuscarAgente: string;
  public arrayAgente_Filter: any = [];
  @ViewChild('btnCerrarEdit') btnCerrarEdit!: ElementRef;
  @ViewChild('editableDiv') editableDiv!: ElementRef;
  /*fin --variables para editar-elimnar actividades */
  @ViewChild('pdfTableFlujoCte', { static: true }) el!: ElementRef
  @ViewChild('pdfTableFlujoCte_Total', { static: true }) el2!: ElementRef
  @ViewChild('pdfTableCte', { static: true }) pdfEdoCta!: ElementRef
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('paginVtasXlin') paginador: MatPaginator;
  @ViewChild('sort1') sort1: MatSort;
  @ViewChild('sort2') sort2: MatSort;
  @ViewChild('paginator3') paginator3: MatPaginator;
  @ViewChild('sort3') sort3: MatSort;
  @ViewChild('btnCerrarAct') btnCerrarAct!: ElementRef;
  @ViewChild('inputComentarios') inputComentarios!: ElementRef;
  public arraySubTareas: any = []
  @ViewChild('btnCerrarOportunidades') btnCerrarOportunidades!: ElementRef;

  @ViewChild('btnCerrar') btnCerrar!: ElementRef;
  @ViewChild('mdlSuc') btnCerrarMdlSuc!: ElementRef;
  @ViewChild('fileInput2') fileInput2!: ElementRef;
  @ViewChild('ingoGralDiv') ingoGralDiv!: ElementRef;
  @ViewChild('headerArtsAsing', { static: true }) headerArtsAsing!: ElementRef
  @ViewChild('pdfArtsPolitica', { static: true }) pdfArtsPolitica!: ElementRef
  @ViewChild('fileModal') fileModal!: ElementRef;
  public altInfo: any;
  dataHisXvta = new MatTableDataSource<historicovtaxlinea>();
  @ViewChild('pdfHeaderFlujoCte', { static: true }) headerFlujoCte!: ElementRef
  @ViewChild('pdfHeaderFlujoCte_2', { static: true }) headerFlujoCte_2!: ElementRef;
  @ViewChild('pdfHeaderCtaCte', { static: true }) headerCte!: ElementRef
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;

  @ViewChild('pagArtsFav') pagArtsFav: MatPaginator;
  @ViewChild('sortTareasCte') sortArtFav: MatSort;
  public intEditInfoGeneral: number = 0;  // 0 no editable y 1 editable
  public strBuscarContacto: any = '';
  public intIDFamilia: number;
  public strClasificacion: string = "";
  public strNombreEmpresa: string = "";
  public arrayContactosDetalle: any = [];
  public boolAgente: boolean = true;
  public strAgenteLD: string = "";
  public arrayAgentes: any = [];
  public arrayAgentes_tareas: any = [];
  public arrayFamilias: any = [];
  public intValidacionFamilia: number;
  public strTipoLead: string = "";
  public inthov: boolean = false;
  public strLineaHistVta: string = "";
  public strNombreCarpeta: any = ''
  public arrayLineasHistVenta: any = [
    { id: 0, strLinea: "ACCESORIOS" },
    { id: 1, strLinea: "KIMBERLY" },
    { id: 2, strLinea: "RUBBERMAID" },
    { id: 3, strLinea: "BLUE TISSUE" },
    { id: 4, strLinea: "QUIMICOS" },
    { id: 5, strLinea: "DESPACHADORES ECODELI" },
    { id: 6, strLinea: "TORK" },
    { id: 7, strLinea: "DEGRAPACK" },
    { id: 8, strLinea: "FOAOM" },
    { id: 9, strLinea: "DOVE" },
    { id: 10, strLinea: "TAPETES" }
  ];
  public strEstatus: string = "RECORRIDO"
  public IntObjetivo: number = 0;
  public IntVenta: number = 0;
  public IntDiferencia: number = 0;
  public IntPorcentajeAvance: number = 0;
  public intFamValida: boolean = false;
  public strAgente: any;
  public arrayOportunidades: any = [];
  public strAgenteSelect: string = ""
  public arrayAgentesHistVenta: any = []
  public time1: string = "am";
  public time2: string = "am";
  public mdlHoraInicio: string = "07:00";
  public mdlHoraFin: string = "07:00";
  public arrayAm: any = []
  public arraypm: any = []
  public mdlNombre: string = "";
  public mdlComentarios: string = "";
  public strfecha: Date;
  public arraySecciones: any = []
  public arrayTime: any = [{ time: "am" }, { time: "pm" }]
  public objModal: any = { titulo: "", subTitulo: "" };
  public arrayHora: any = [];
  public arrayInfo: any = [];
  public objInfo: any = {};
  public arrayAnexMdlAdd: any = []
  public strSucursal: string = "";
  public boolSucursal: boolean = true;
  public boolGerente: boolean = true;
  public arraySucursales: any = [];
  public arraySucursales_cte: any = [];
  public arraySucursalesHistVenta: any = [];
  public arrayActividades: any = [];
  public arrayOport: any = [];
  public arrayListSucursales: any = [];
  public arrayAjusteLineas: any = [];
  public strConexion: string = "";
  public searchInput: string = "";
  public objAct: any;
  public objObjetivos: any;
  public objDetActividad: any;
  public intID_Familia: string;
  public loadingPendientes: boolean = true
  public intID_Leads: number;
  public strFamilia: string = ""
  public contenido: string = '';
  public strResultado: string = "";
  public intIDMovimientoVenta: number = 0
  public strNombreAgente: any = ''
  public strNombreOportunidad: string = ''
  public dblMonto: any = ''
  public strFechaCierre: any = ''
  public intID_linea: any = ''
  public strLinea: string = "";
  public arrayLineas2: any = []
  public strResponsable: string = ''
  public objResponsable: any = {};
  public arrayAsigAgentes: any = [];
  public strBuscarArticulo: any = ''
  public arrayArts: any = [];
  public arrayArticuloAgregados: any = []
  public strDescripcionArticulo: any = ''
  public intCantidadArticulos: number = 0;
  public arrayHistVenta: any = [];
  public arrayFiltradoHistClientes: any = [];
  public arrayCompletoHisVta: any = [];
  public boolSuc: boolean = false;
  public arrayLineas: any = [];
  public strBuscarHistVta: string = "";
  public spinerBool: boolean = false;
  public arrayFlujoCliente: any = [];
  public arrayFlujoClienteFamilia: any = [];
  public arrayFlujoClienteFiltrado: any = [];
  public arrayFlujoClienteFamiliaFiltrado: any = [];
  public searchFlujoCte: string = "";
  public strRutaLogo: any = ''
  public strDBRutaLogo: any = ''
  public selectedRenovFile: File;
  public objLinea: any;
  public strRazonSocial: any = '';
  public myForm: FormGroup;
  public myFormLeads: FormGroup;
  public formLead: FormGroup;
  public formSucursal: FormGroup;
  public strSector: string = "";
  public intGiro: number = 0;
  public arrayGiros: any = [];
  public intIDPresencia: any = ''
  public arrayPresencia: any = [];
  public arrayAgentesActividades: any = [];
  public objSubtarea: any = []
  public arrayUbicaciones: any = [];
  public arrayTamanoEmpresa: any = [];
  public intIDUbicacion: any;
  public arrayHistoricoXlinea: any = [];
  public strNumeroEmpleados: any = '';
  public strFechaEstimadaCierreInput: any = '';
  public strFechaEstimadaCierre: any = '';
  public strRFC: any = '';
  public banderaCotizaciones: any = false;
  public arrayTipoCotizacion: any = []
  public arrayNombresSecciones: any = []
  public strAgenteSP: any;
  public strMedioConocio: any = 'VENDEDOR';
  public strTieneServicio: any
  public banderaContacto: any;
  public ListaAnexos: any = [];
  public strTipoCotizacion: string = "";
  public strCodigoPostal: any;
  public strCalle: any = '';
  public strEstado: any = '';
  public strNumeroExterior: any = '';
  public strDelegacion: any = '';
  public strEntreCalles: any = '';
  public banderaFormulario: boolean;
  public strColonia: any = '';
  public strLadaEmpresa: any = '';
  public strTelefonoEmpresa: any = '';
  public strOtraColonia: any = '';
  public strZona: any;
  public intId_Contacto: any = 0
  public intIDLeads: any = 0;
  public dblImporte: string = "";
  public strPrioridad: string = "";
  public strSitioWeb: any = '';
  public intId_Direccion: any = 0;
  public intTotalSucursales: any = '';
  public strFase: any;
  public fechaInicioServicio: any = null;
  public intCantidadElementos: any = 0;
  public arrayAnexosUpdate: any = [];
  public strRecomendacion: any;
  public intid_Cliente: any = 0
  public panelOpenState = false;
  public arrayAnexos: any = [];
  public strTipoAnexo: any = 'Contrato';
  public itemD: any = 0;
  public strFechaInicioServicio: any;
  public strTituloActividad: string = ''
  public intTotalPreguntas: number = 0
  public intIDActividad: any;
  public urlIframe: SafeResourceUrl;
  public iframeVisible: boolean = false;
  public arrayListadoSucursales: any = [];
  public arrayLineasSelect: any = [];
  public excelData: any = [];
  public excelDataAux: any = [];
  public intTotalContestadas: any = 0
  public arrayEdoCuenta: any = [];
  public arrayEdoCtaDist: any = [];
  public strBuscarEdoCta: string = "";
  public strNombre_ruta: string = "";
  public strRuta_Origen: string = "";
  public arrayLineasHist: any = [];
  public strBuscar: string = "";
  public arrayColonias: any = [];
  public arrayAsigContacto1: any = [];
  public arrayAsigContacto2: any = [];
  public arrayAsigContacto3: any = [];
  public arrayAsigRFC: any = [];
  public objIndicadores: any = []
  public arraySubGiro: any = []
  public arraySubGiroTotal: any = []
  public arraySegundaLetra: any = []
  public arraySectores: any = []
  public arrayArtsPolitica: any = [];
  public strPotencializador: any = ''
  public intId_segunda: any = 0
  public intId_tercera: any = 0
  public strBuscarAct:string = "";
  public strNombreCorto: any = ''
  public objSucManual: any = {
    RAZON_SOCIAL: "",
    RFC: "",
    NOMBRE_SUCURSAL: "",
    DIRECCION: "",
    DIRECCION_NUMERO: "",
    CODIGO_POSTAL: "",
    COLONIA: "",
    CONTACTO_NOMBRE: "",
    CONTACTO_PUESTO: "",
    CONTACTO_EMAIL: "",
    CONTACTO_TELEFONO: "",
  }
  public objHisxLin: any = {
    strEjercicio: "", strPeriodo: "", dblAccesorios: 0, dblAccesoriosMargen: 0,
    dblKimberly: 0, dblKimberlyMargen: 0, dblRubbermaid: 0, dblRubbermaidMargen: 0
    , dblBlueTissue: 0, dblBlueTissueMargen: 0, dblQuimicos: 0, dblQuimicosMargen: 0,
    dblDespachadores: 0, dblDespachadoresMargen: 0, dblTork: 0, dblTorkMargen: 0,
    dblXibu: 0, dblXibuMargen: 0, dblFoaom: 0, dblFoaomMargen: 0, total: 0
  }
  public intSubGiro: any = 0
  public spinerVtaXlin: boolean = false;
  public strGerente: string = "";
  public strGerente_header: string = "";
  public arrayGerentes: any = [];
  public strSucursal_header: string = "";
  public arrayHistLineas: any = [];
  public strAgenteHistVenta: string = ""
  public expandedElement: any | null;
  public strBuscarCte: string = "";
  public loaderDetalleFavoritos: boolean = true;
  public spinnerHistVta: boolean = false;
  public spinerCtes: boolean = false;
  public spinerArtsPolitica: boolean = false;
  public spinerHisVtaCte: boolean = false;
  public spinerHisVtaLIn: boolean = false;
  public spinerFlujoCte: boolean = false;
  public spinerEdoCuenta: boolean = false;
  public spinerIndicadores: boolean = false;
  public spinnerObjectivos: boolean = false;
  public spinnerTimeline: boolean = false;
  public spinerImg: boolean = false;
  public total_importe_fam: number = 0;
  public total_cobrado_fam: number = 0;
  public total_saldo_fam: number = 0;
  public arrayFitroSegunda: any = []
  public arrayTercerLetra: any = []
  public arrayFiltroTercera: any = []
  public arrayPotenciador: any = []
  public intPotenciador: number = 0
  public objHistCliente: any = {
    dblEnero: 0,
    dblFebrero: 0,
    dblMarzo: 0,
    dblAbril: 0,
    dblMayo: 0,
    dblJunio: 0,
    dblJulio: 0,
    dblAgosto: 0,
    dblSeptiembre: 0,
    dblOctubre: 0,
    dblNoviembre: 0,
    dblDiciembre: 0,
    dblTotal: 0
  };
  public objHistLinea: any = {
    dblEnero: 0,
    dblFebrero: 0,
    dblMarzo: 0,
    dblAbril: 0,
    dblMayo: 0,
    dblJunio: 0,
    dblJulio: 0,
    dblAgosto: 0,
    dblSeptiembre: 0,
    dblOctubre: 0,
    dblNoviembre: 0,
    dblDiciembre: 0,
    dblTotal: 0
  };

  public objHistMargen: any = {
    Accesorios: 0,
    Kimberly: 0,
    Rubbermaid: 0,
    BlueTissue: 0,
    Quimicos: 0,
    Despachadores: 0,
    Tork: 0,
    Xibu: 0,
    Foaom: 0,
    Dove: 0,
    Tapetes: 0,
    total: 0,
  };
  public arrayMinuta: any = [];

  public strUsuario: string;
  public vartri: boolean = false;
  public arrayInfoPDFFlujoCte: any;
  public arrayInfoPDFEdoCta: any;
  public strSucursalHistVta: string = "";
  @ViewChild('btnCerrarMinuta') btnCerrarMinuta!: ElementRef;
  @ViewChild('fileInputActividades') fileInputActividades!: ElementRef;
  public arrayAgregarAnexosActividades: any = []
  public formMinuta: FormGroup;
  public formOportunidades: FormGroup;
  public arrayAccesos: any = [];
  private unsubscribe$ = new Subject<void>();
  public boolEditaAgente: boolean = true;
  public tagName: string;
  public arrayEjercicio: any = [];
  public intEjercicio: number = 2025;
  public arrayCarpetas: any = []
  tabSeleccionada: number = 0;
  constructor(private indexedDBService: DataDBService, private sanitizer: DomSanitizer, private formbuilder: FormBuilder, private paginatorIntl: MatPaginatorIntl, private rutaActiva: ActivatedRoute, private _httpService: PeticionesService, private carroservice: CarroService, public _alertify: AlertifyService, private _filtroServ: FiltrosService, public router: Router, elem: ElementRef, public route: ActivatedRoute, private cdr: ChangeDetectorRef, public _serviciosService: PeticionesService, public localSt: LocalStorageService, private elementRef: ElementRef) {/*, public socket: SocketServiceService */
    this.tagName = elem.nativeElement.tagName.toLowerCase();

    let hora = 0, minutos = 0, strH = "", strM = "";

    hora = 7
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayAm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos === 30) {
        minutos = 0;
        hora += 1;
      } else {
        minutos += 30;
      }
    }

    //inicializamos la ruta de retorno
    this.indexedDBService.getItem('objRuta_Anterior', (value) => {

      this.strNombre_ruta = value && value.ruta_destino == '/detalleLeadFamilia' ? value.nombre :
        (value.ruta_destino == '/detalleLeadFamilia' && value.ruta_anterior_1) ? value.ruta_anterior_1.nombre : 'Directorio';

      this.strRuta_Origen = value && value.ruta_destino == '/detalleLeadFamilia' ? value.ruta_origen :
        (value.ruta_destino == '/detalleLeadFamilia' && value.ruta_anterior_1) ? value.ruta_anterior_1.ruta_origen :
          '/directorio';
    });
    this.route.queryParamMap.subscribe(params => {
      this.intID_Familia = params.get('idFamilia');
      this.strFamilia = params.get('strFamilia');
    });
    this.customizeItemsPerPageLabel();
    this.arrayTipoCotizacion = [
      { strTipoCotizacion: 'Productos en General', Seleccionado: false },
      { strTipoCotizacion: 'Licitaciones', Seleccionado: false },
      { strTipoCotizacion: 'Cuenta Nacional', Seleccionado: false },
      { strTipoCotizacion: 'Público en General', Seleccionado: false }
    ]

    this.arrayContactosDetalle = [{
      strNombreContacto: '',
      strContactoPuesto: '',
      strContactoTelefono: '',
      strContactoEmail: '',
      emailEsObligatorio: false
    }]

    this.myForm = this.formbuilder.group({
      strAccion: new FormControl('setContacto', []),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      strConexion: new FormControl(this.strConexion, []),
    });

    this.myFormLeads = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strSector: new FormControl('', [Validators.maxLength(20), Validators.required]),
      //strRFC: new FormControl('', [Validators.maxLength(13), Validators.required]),
      intId_Giro: new FormControl('', [Validators.required]),
      strCodigoPostal: new FormControl('', [Validators.maxLength(5), Validators.required]),
      strDelegacion: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strEstado: new FormControl('', [Validators.maxLength(100), Validators.required]),
      intIDPresencia: new FormControl('', [Validators.required]),
      intIDNo_Empleados: new FormControl('', [Validators.required]),
      intid_Ubicacion: new FormControl('', [Validators.required]),
      strEstatus: new FormControl('POR CONTACTAR', []),
      strAccion: new FormControl('setContacto', []),
      strConexion: new FormControl(this.strConexion, []),
      strAgente: new FormControl(this.strAgenteSP, []),
      intID_Contacto: new FormControl(0, [Validators.required]),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      //strClasificacion: new FormControl('', [])
    });

    this.formLead = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.required]),
      strNombreCorto: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      strSector: new FormControl('', [Validators.required]),
      intId_Giro: new FormControl('', [Validators.required]),
      intId_subGiro: new FormControl(0, []),
      intIDPresencia: new FormControl(0, [Validators.required]),
      intID_Familia: new FormControl(this.intID_Familia, []),
      intId_segunda: new FormControl(0, []),
      intId_tercera: new FormControl(0, []),
      intID_Contacto: new FormControl(0, []),
      strPotencializador: new FormControl('', []),
      strMedioConocio: new FormControl('VENDEDOR', [])
    });
    this.fnAddMinuta();
    this.fnFormOportunidades()
    this.fnformSucursal();

    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }

  ngOnInit(): void {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgente_Crea = this.localSt.retrieve('strAgente');
    this.strAgente = this.localSt.retrieve('strAgente');
    this.strUsuario = this.localSt.retrieve('strUsuario');
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];

      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        this.strConexion = this.localSt.retrieve('strConexion');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strAgente = this.localSt.retrieve('strAgente');
        this.strUsuario = this.localSt.retrieve('strUsuario');
        this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;

        //------------conexi+on-------------------
        this.indexedDBService.getItem('strConexion', (value) => {
          this.strConexion = value;

        });
      }
      else {
        this.router.navigate(['/login']);
      }
    });
    this.fnGetFiltros();
    this.fnGetInfoFamilias();
    this.fnListadoSolicitud();
    this.fnGetAgentes_tareas();


    this.fngetGiros();

    this.fnGetSuc();
    this.fnGetObjetivos();
    this.fnGetLinea();
    this.fngetSubGiros();
    this.fngetLineas();
    this.fnGetDetalleEdoCta();

    this.fngetCompras_Favoritas()
    this.fnGetDetalleCotizacion();
    this.fnCatalogoFamilia();
    this.fnGetAgentesFamilia()
    this.fngetSectores();
    this.fngetSegundaLetra();
    this.fngetTerceraLetra();
    this.fnGetFamilias();
    this.fngetHistVtaLineas();
    this.fnGetHistoricoVenta();
    this.fnGetHistoricoVentaLineas()
    this.fnGetFlujoCliente();
    this.fnGetFlujoFamiliaCliente();
    /*   this.fnGetHistoricoxLinea({strCliente:"1018"}) */
  }
  ngOnDestroy(): void {
    // Emitir un valor para completar las suscripciones
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
  }

  ngAfterViewInit() {
    this.altInfo = this.ingoGralDiv.nativeElement.offsetHeight;


    $('.summernote').summernote('destroy');
    $('.summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
        onImageUpload: (files) => {
          this.adjustImageSizes();
          this.addImageToEditor(files[0]);
        },
        onInit: () => {
          // Ajustar tamaños de las imágenes al inicializar
        }
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['picture']]
      ],
      popover: {
        image: [
          ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ]
      }
    });

    // Habilitar el input para capturar imágenes desde la cámara
    $('.summernote').on('summernote.init', function () {
      $('input[type="file"]').attr('accept', 'image/*').attr('capture', 'camera');
    });
  }
  // Método para exportar la tabla HTML a Excel
  exportToExcel_hist1(): void {
    // Obtén el elemento de la tabla por su id
    const element = document.getElementById('tableHist_1'); // Asegúrate de que este ID coincide con el de tu tabla HTML
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // Crea un libro de trabajo
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Agrega la hoja al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Descarga el archivo Excel con el nombre que elijas
    XLSX.writeFile(wb, 'Historico_clientes.xlsx');
  }
  exportToExcel_hist2(): void {
    // Obtén el elemento de la tabla por su id
    const element = document.getElementById('tableHist_2'); // Asegúrate de que este ID coincide con el de tu tabla HTML
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // Crea un libro de trabajo
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Agrega la hoja al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Descarga el archivo Excel con el nombre que elijas
    XLSX.writeFile(wb, 'Historico_lineas.xlsx');
  }
  exportToExcel_hist3(): void {
    // Obtén el elemento de la tabla por su id
    const element = document.getElementById('tableHist_3'); // Asegúrate de que este ID coincide con el de tu tabla HTML
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // Crea un libro de trabajo
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Agrega la hoja al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Descarga el archivo Excel con el nombre que elijas
    XLSX.writeFile(wb, 'Historico_margen.xlsx');
  }
  fnFiltrarHistorico_vta() {
    this.fnGetHistoricoVenta();
    this.fngetHistVtaLineas();
    this.fnGetHistoricoVentaLineas();
  }
  fnUpdateAgente(agente_seleccionado: string, cliente: string) {

    Swal.fire({
      title: "¿Está seguro de reasignar Agente?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Sí, Guardar",
      denyButtonText: `No,Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Swal.fire("Saved!", "", "success");

        let objEnviar = {
          strAccion: 'UPDATE_AGENTE',
          strAgente: agente_seleccionado,
          strCliente: cliente,
          strSucursal: '',
          strTipo: 'CLIENTE',
          strGerente: this.strUsuario,//ES EL USUARIO QUE ESTA HACIENDO LA ACTUALIZACIÓN
          strConexion: this.strConexion
        }

        this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_ModVentas_Leads')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              if (result[0].code == 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: result[0].strMensaje,
                  showConfirmButton: false,
                  timer: 1500
                });
                this.fnListadoSolicitud();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: result[0].strMensaje,
                });
              }

            },
            error => {
              var error = <any>error;
              console.log(error);
            }
          );



      } else if (result.isDenied) {
        Swal.fire("No se realizó  ningún cambio", "", "info");
      }
    });


  }

  getAnimationState(obj) {
    return obj.seeCte ? 'out' : 'in';
  }

  fnInitSummer() {
    $('.summernote').summernote('destroy');
    $('.summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
        onImageUpload: (files) => {
          this.adjustImageSizes();
          this.addImageToEditor(files[0]);
        },
        onInit: () => {
          // Ajustar tamaños de las imágenes al inicializar
        }
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['picture']]
      ],
      popover: {
        image: [
          ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ]
      }
    });

    // Habilitar el input para capturar imágenes desde la cámara
    $('.summernote').on('summernote.init', function () {
      $('input[type="file"]').attr('accept', 'image/*').attr('capture', 'camera');
    });
  }

  fnTabletareasCte() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }

  fnTableArtsFavoritos() {
    this.dataSourceFavoritos.paginator = this.pagArtsFav;
    this.dataSourceFavoritos.sort = this.sortArtFav;
  }
  fnformSucursal() {
    this.formSucursal = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strRFC: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(13)]),
      strRazonSocial: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strCodigoPostal: new FormControl('', [Validators.minLength(5), Validators.maxLength(5), Validators.required]),
      strColonia: new FormControl('', [Validators.maxLength(100), Validators.required]),
      direccion: new FormControl('', [Validators.required]),
      numeroDireccion: new FormControl('', [Validators.required]),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
    });
  }

  fnFormOportunidades() {
    this.arrayLineasSelect = [];
    this.arrayLineas2.forEach(lin => {
      lin.select = false;
    });
    this.formOportunidades = this.formbuilder.group({
      strTitulo: new FormControl('', [Validators.required]),
      intMonto: new FormControl(0, [Validators.required]),
      strLinea: new FormControl('', []),
      strArticulo: new FormControl('', []),
      strFecha: new FormControl('', [Validators.required]),
      strResponsable: new FormControl('', []),
      strResponsable2: new FormControl('', [Validators.required]),
    });

  }

  fnGetFamilias() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFamilias',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayFamilias = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  enviarDetalleOporturnidad(item: any) {

    //guardamos en el servicio de indexed db la ruta
    let objRuta_Anterior = {
      ruta_origen: '/detalleLeadFamilia',
      ruta_destino: '/detoport',
      nombre: 'Lead Familia',
      parametros: { idFamilia: this.intID_Familia, strFamilia: this.strFamilia },
      ruta_anterior_1: {
        nombre: this.strNombre_ruta,
        ruta_origen: this.strRuta_Origen,
        parametros: {}
      }
    }
    this.indexedDBService.removeItem('objRuta_Anterior');
    this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);
    this.router.navigate(['/detoport'], { queryParams: { idLead: item.intID_Leads_Oportunidad, sts: item.strEstatus } });
  }

  enviarDetalleCliente(item: any) {
    //guardamos en el servicio de indexed db la ruta
    let objRuta_Anterior = {
      ruta_origen: '/detalleLeadFamilia',
      ruta_destino: '/detalleLeadCliente',
      nombre: 'Lead Familia',
      parametros: { idFamilia: this.intID_Familia, strFamilia: this.strFamilia },
      ruta_anterior_1: {
        nombre: this.strNombre_ruta,
        ruta_origen: this.strRuta_Origen,
        parametros: {}
      }
    }
    this.indexedDBService.removeItem('objRuta_Anterior');
    this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);

    this.carroservice.setFamilia({ idFamilia: this.intID_Familia, strFamilia: this.strFamilia, strClienteSeleccionado: item.strCliente, strNombreCliente: item.strNombreCliente })
    this.router.navigate(['/detalleLeadCliente'], { queryParams: { strCliente: item.strCliente } });
  }
  fnGetFiltros() {
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });

    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
    //ejercicio
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  }
  fnGetAgentes_tareas() {

    let obj = {
      strAccion: 'getAgentes_Leads',
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayAgentes_tareas = result
        }, error => {

          console.error('Error al consumir la API:', error);
        });

  }

  fnTable() {
    this.paginator1.pageSize = 15;
    this.dataSource.paginator = this.paginator1;

    this.dataSource.sort = this.sort1;
  }

  fnTableArts() {
    this.dataSourceC.paginator = this.paginator2;
    this.dataSourceC.sort = this.sort2;

  }

  fnTableCtaCte() {
    this.TableCtaCte.paginator = this.paginator3;
    this.TableCtaCte.sort = this.sort3;
  }

  fnCatalogoFamilia() {
    this.iframeVisible = true;
    /* const url = `https://ecodelinegocio.com/Intranet/catalogoDigital/${this.strFamilia}`; */

    const url = `https://ecodelinegocio.com/Intranet/catalogoDigital/${'""'}/${this.strFamilia}`;

    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    setTimeout(() => {
      this.iframeVisible = false;
    }, 3000);
  }

  fnGetInfoFamilias() {
    this.spinerIndicadores = true
    let obj = {
      strAccion: 'valida_familia_existe',
      intID_Familia: this.intID_Familia,
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == "200") {
            this.intFamValida = true;
            this.intID_Leads = result[0].intID_Leads
            //this.fnGetInfo();
            this.fnGetTimeLine()
            this.fnIndicadores()
            this.fnListadoOoprtunidad();
            this.fnGetFormulario();
            this.fnGetMinutas();
          } else {
            this.intFamValida = true;
          }
          this._alertify.success(result[0].strMensaje);
          /* Swal.fire(result[0].code=="200"?'Exito':'Pendiente',result[0].strMensaje,result[0].code=="200"?'success':"info"); */
        }, error => {
          this.spinerIndicadores = false
          console.error('Error al consumir la API:', error);
        });
  }

  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }

  fnGetTimeLine() {
    this.spinnerTimeline = true
    let obj = {
      strAccion: 'get_TimeLine_Familia',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayOport = result
          // this.fnListadoSolicitud();
          this.spinnerTimeline = false
        }, error => {
          this.spinnerTimeline = false
          console.error('Error al consumir la API:', error);
        });
  }

  fnModalRegistrar(tipo: string) {

    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlNombre = "";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = tipo
    this.arrayAgentesActividades = [];
    this.arrayAnexMdlAdd = [];
    this.strBuscarAgente = ""
    this.fileInputActividades.nativeElement.value = "";
    this.arrayAgregarAnexosActividades = [];

    this.inputComentarios.nativeElement.innerHTML = ""
  }

  fncambiarHoraInicio() {
    if (this.time1 == "am") {
      this.mdlHoraInicio = "07:00";
    } else if (this.time1 == "pm") {
      this.mdlHoraInicio = "00:00";
    }
  }

  fncambiarHoraFin() {
    if (this.time2 == "am") {
      this.mdlHoraFin = "07:00";
    } else if (this.time2 == "pm") {
      this.mdlHoraFin = "00:00";
    }
  }

  fnllenarArrayTareas() {
    this.arrayActividades = [];
    /*  this.arrayActividades = this.arrayOport.filter(o=>o.strTipo=="Visita" ||o.strTipo=="Llamada" ||o.strTipo=="Correo") */
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getActividades',
      intID_Leads: this.objInfo.intID_Leads,
      strConexion: this.strConexion,
      strGerente: this.strGerente_header,
      strSucursal: this.strSucursal_header
    }, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayActividades = result

          if (this.arrayActividades.length > 0) {
            this.arrayActividades.forEach(obj => {
              // Paso 1: Convertir strFechaRequerida a un objeto Date
              let fechaRequerida = new Date(obj.strFechaRequerida);
              // Paso 2: Obtener la fecha actual
              let fechaActual = new Date();
              // Paso 3: Calcular la diferencia en milisegundos
              let diferenciaMilisegundos = fechaRequerida.getTime() - fechaActual.getTime();
              // Paso 4: Convertir la diferencia de milisegundos a días
              let diferenciaDias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
              obj.diasDiferencia = diferenciaDias
            });
          }
          this.datosTareasCte = new MatTableDataSource(this.arrayActividades);
          this.fnTabletareasCte();
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  transform(value: string): string {
    if (!value) return value;
    const datePart = value.split(' ')[0];
    return datePart;
  }

  fnConcluirActividad(objAct: any) {
    let processedContent = this.contenido;
    processedContent = processedContent.replace(/<img/g, '<img class="img-fluid"');
    // Ahora se puede asignar el contenido procesado a [innerHTML]
    this.contenido = processedContent;
    let objEnv = {
      strAccion: 'update_Actividad',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intID_Leads,
      strComentario: this.contenido,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.strResultado = ''
            this.contenido = ''

            this.fnGetTimeLine();
            this.fnllenarArrayTareas();
            this.btnCerrarAct.nativeElement.click();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnGetFormulario() {

    this.arraySecciones = []
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFormulario',
      intID_Giro: this.intGiro,
      intID_leads: this.intID_Leads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          let seccionesMap = new Map<string, any[]>();
          result.forEach((element: any) => {
            let seccion = element.strSeccion;
            if (!seccionesMap.has(seccion)) {
              seccionesMap.set(seccion, []);
              this.arrayNombresSecciones.push(seccion);
            }
            seccionesMap.get(seccion).push(element);
          });
          this.intTotalPreguntas = 0;
          let resultado: any = ''
          seccionesMap.forEach((arregloAux: any[], seccion: any, index: any) => {
            resultado = arregloAux[0].strResultado
            const arrayPreguntas = arregloAux.map((b: any) => ({
              strRespuesta: (!b.strResultado) ? '' : b.strResultado,
              intID_Formulario: b.intID_Formulario,
              strTipoRespuesta: b.strTipoRespuesta,
              strPregunta: b.strPregunta,
              arrayRespuestas: b.strTipoRespuesta === 'LISTA' ? b.strRespuesta.split(',') : [],
              depende_pregunta_id: b.intDependeId,
              depende_pregunta_text: b.strDependeRespuesta,
              disabled: false,
              disabledRespuesta: (b.strResultado) ? true : false
            }));
            this.intTotalPreguntas += arregloAux.length;
            this.arraySecciones.push({
              index,
              strSeccion: seccion,
              intTotalPreguntas: arregloAux.length,
              intTotalContestadas: (resultado) ? arregloAux.length : 0,
              preguntas: arrayPreguntas,
            });
          });
          this.banderaFormulario = (resultado) ? true : false
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnAutorizar(objAct: any) {
    let objEnv = {
      strAccion: 'update_Actividad_Autorizar',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              icon: 'success',
              title: 'Autorizado',
              text: result[0].strMensaje,
            });
            this.fnllenarArrayTareas()
            this.btnCerrarAct.nativeElement.click()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: result[0].strMensaje,
            });
          }
        }, error => {
          var error = <any>error; console.log(error);
        }
      );
  }

  fnGetSuc() {
    let objEnv = {
      strAccion: 'getCte_Familia',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          // this.arraySucursales = result;
          this.arraySucursales_cte = result;

        }, error => {
          var error = <any>error; console.log(error);
        }
      );
  }

  fnGetInfo() {
    this.spinerImg = true
    let objEnv = {
      strAccion: 'getLead_Unique',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayInfo = result
          this.objInfo = this.arrayInfo[0]
          this.fnllenarArrayTareas();
          this.itemD = this.objInfo
          if (this.itemD) {
            this.strTipoLead = this.itemD.strTipo_Leads
            this.intIDActividad = this.itemD.intID_Actividad
            this.strTituloActividad = this.itemD.strTitulo
            this.strNombreCorto = this.itemD.strNombreCorto
            this.strClasificacion = this.itemD && this.itemD.strClasificacion ? this.itemD.strClasificacion : 'CCC';
            this.strNombreEmpresa = this.itemD.strNombreEmpresa;
            this.arrayContactosDetalle[0].strNombreContacto = this.itemD.strContacto_Nombre;
            this.arrayContactosDetalle[0].strContactoPuesto = this.itemD.strContacto_Puesto;
            this.arrayContactosDetalle[0].strContactoTelefono = this.itemD.strContacto_Telefono;
            this.arrayContactosDetalle[0].strContactoEmail = this.itemD.strContacto_Email;
            this.strFechaInicioServicio = this.itemD.fechaInicioServicio;
            if (this.itemD.strtipoCotizacion) {
              let tipoCotizacion = (this.itemD.strtipoCotizacion).split(',')
              if (tipoCotizacion.length > 0) {
                tipoCotizacion.forEach(element => {
                  this.arrayTipoCotizacion.map((dato: any) => {
                    if (dato.strTipoCotizacion === element) {
                      dato.Seleccionado = true;
                      this.banderaCotizaciones = true
                    }
                    return dato;
                  });
                });
              }
            }
            this.strPotencializador = this.itemD && this.itemD.strPotencializador ? this.itemD.strPotencializador : ""
            if (this.itemD.strPotencializador) {
              let poten = (this.itemD.strPotencializador).split(',')
              if (poten.length > 0) {
                poten.forEach(element => {
                  this.arrayPotenciador.map((dato: any) => {
                    if (dato.strLinea === element) {
                      dato.Seleccionado = true;

                    }
                    return dato;
                  });
                });
              }
            }
            if (this.itemD.strFechaCierre_Estimada) {
              let fecha = new Date(this.itemD.strFechaCierre_Estimada)
              this.strFechaEstimadaCierreInput = this.formatoFecha(fecha)
              this.strFechaEstimadaCierre = this.formatoFecha2(fecha)
            }

            this.strTieneServicio = (this.itemD.strServicio_Limpieza) ? this.itemD.strServicio_Limpieza : ''
            this.intValidacionFamilia = this.itemD.intValidacionFamilia
            this.strDBRutaLogo = (this.itemD.strUrl_Logo) ? this.itemD.strUrl_Logo : ''
            this.strAgenteLD = this.itemD.strAgente
            this.strCodigoPostal = this.itemD.strCodigoPostal
            this.strDelegacion = this.itemD.strDelegacion
            this.strEstado = this.itemD.strEstado
            this.strCalle = this.itemD.strCalle
            this.strColonia = this.itemD.strColonia
            this.strFase = this.itemD.strFase
            this.intId_Direccion = this.itemD.intId_Direccion
            this.intid_Cliente = this.itemD.intid_Cliente
            this.strNombreEmpresa = this.itemD.strNombreEmpresa
            this.strSector = this.itemD.strSector
            this.dblImporte = this.itemD.dblMonto
            this.strSitioWeb = this.itemD.strSitio_Web
            this.strMedioConocio = 'VENDEDOR'
            this.strPrioridad = this.itemD.strPrioridad;
            this.strRFC = this.itemD.strRFC;
            this.strNombreAgente = this.itemD.strNombreAgente
            this.intGiro = this.itemD.intId_Giro
            this.intIDPresencia = this.itemD.intIDPresencia
            this.strRecomendacion = this.itemD.strRecomendacion
            this.intCantidadElementos = this.itemD.intCantidadEmpleados
            this.intTotalSucursales = this.itemD.intNo_Sucursales
            this.strRazonSocial = this.itemD.strRazon_Social
            this.intIDUbicacion = this.itemD.intid_Ubicacion
            this.intId_segunda = this.itemD.intId_segunda
            this.intId_tercera = this.itemD.intId_tercera
            this.intSubGiro = this.itemD.intId_SubGiro
          }
          this.fnFiltrarSegundaLetra();
          this.fnListadoOoprtunidad();
          this.fnGetAnexos();
          this.spinerImg = false
        }, error => {
          this.spinerImg = false
          var error = <any>error; console.log(error);
        }
      );
  }

  fnGetAnexos() {
    try {

      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getAnexos',
        intIdLeads: this.intID_Leads,
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            if (result.length > 0) {
              let nombresCarpetasSet = new Set<string>();
              let carpetasMap = new Map<string, any>();

              result.forEach((b: any) => {
                let nombreCarpeta = b.strUrl.split('/')[1];

                // Obtener la extensión del archivo
                let extension = nombreCarpeta.split('.').pop()?.toLowerCase();

                // Si la extensión es una de las especificadas, asignar a "Sin Carpeta"
                let extensionesSinCarpeta = ["pdf", "png", "xlsx", "jpg", "jpeg"];
                if (extensionesSinCarpeta.includes(extension || "")) {
                  nombreCarpeta = "Sin Carpeta";
                }

                if (!carpetasMap.has(nombreCarpeta)) {
                  carpetasMap.set(nombreCarpeta, {
                    nombreCarpeta,
                    xmlDocumentos: [],
                    xmlDocumentosBD: [],
                  });
                  nombresCarpetasSet.add(nombreCarpeta);
                }

                // Agregar el documento a la carpeta correspondiente
                carpetasMap.get(nombreCarpeta).xmlDocumentosBD.push(b);
              });


              // Convertir el Map en un array
              this.arrayCarpetas = Array.from(carpetasMap.values());
         
              //this.arrayAnexos = result
            } else {
              this.arrayCarpetas = [
                {
                  nombreCarpeta: 'Sin Carpeta',
                  xmlDocumentos: [],
                  xmlDocumentosBD: []
                }
              ]
            }

          },
          error => {
            var error = <any>error;
            console.log(error);

          }
        );
    } catch (error) {
      console.log(error.message)

    }
  }

  onTimeChange(event: any, obj: string) {
    if (obj == "fin") {
      this.fncambiarHoraFin()
    } else if (obj == "inicio") {
      this.fncambiarHoraInicio()
    }
  }

  formatoFecha(fecha: Date): string {
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  formatoFecha2(fecha: Date): string {
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }


  convertDateToString(dateString: Date): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  fnEnviarActividad(anexos: any = {}) {

    let xml: any = []
    if (this.arrayAgentesActividades.length > 0) {
      this.arrayAgentesActividades.forEach(item => {
        xml.push({ strAgente: item.strAgente, strNombreAgente: item.strNombre })
      });
    }

    if (this.strfecha == null || this.strfecha == undefined) {
      Swal.fire("Error", "seleccione una fecha correcta", "error");
    } else {
      let objEnv: { [key: string]: any } = {
        strAccion: "setActividad_Leads_Familia_Multiple", /* 'setActividad_Leads_Familia', */
        intID_TIPO: this.objModal.titulo == "Correo" ? 1 : this.objModal.titulo == "Llamada" ? 3 : this.objModal.titulo == "Visita" ? 2 : 6,
        strTitulo: this.mdlNombre,
        strAgente: "",/* this.strAgente */
        strFechaInicioServicio: this.convertDateToString(this.strfecha),
        strConexion: this.strConexion,
        strComentario: this.mdlComentarios,
        intID_Leads: this.intID_Leads,
        xmlArticulos: xml,
        strAgente_Crea: this.strAgente_Crea
      }
      if (this.objModal.titulo != "Tarea") {
        objEnv.strHoraInicio = this.mdlHoraInicio;
        objEnv.strHoraFin = this.mdlHoraFin;
      }
      if (anexos && Object.keys(anexos).length > 0) {
        objEnv.strTipo_CIERRE = anexos.strTipo;
        objEnv.strUrl_Logo = anexos.strRespuesta;
        objEnv.strResultado = anexos.strNombre;
        objEnv.strMotivo = ""
      }
      if(this.objModal.titulo == "Subtarea"){
        objEnv.intID_Actividad = this.objSubtarea.intID_Actividad;
      }

      this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
            if (result[0].code == "200") {
              this.arrayAgentesActividades = [];
              this.fnGetTimeLine();
              this.btnCerrar.nativeElement.click();
              this.fnllenarArrayTareas();
            }
          }, error => {
            var error = <any>error; console.log(error);
          }
        );
    }
  }

  fnGetObjetivos() {
    this.spinnerObjectivos = true;
    this.objObjetivos = {};

    let objEnv = {
      strAccion: 'getObj_Vta',
      strFamilia: this.strFamilia,
      strCliente: this.strSucursal,
      strConexion: this.strConexion,
      strGerente: this.strGerente_header,
      strSucursal: this.strSucursal_header
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.objObjetivos = result[0];
          this.percentage = (this.objObjetivos?.dblImporte > 0 && this.objObjetivos?.dblObjetivo > 0) ? ((this.objObjetivos?.dblImporte / this.objObjetivos?.dblObjetivo) * 100).toFixed(2) : 0
          this.percentage = (this.percentage <= 100) ? this.percentage : 100
          this.gaugeAngle = ((this.percentage / 100) * 180 - 90);
          this.spinnerObjectivos = false;
        }, error => {
          this.spinnerObjectivos = false;
          var error = <any>error; console.log(error);
        }
      );
  }

  onSucursalChange(event: any): void {
    this.fnGetObjetivos();
    this.strBuscarHistVta = event.value
    if (event.value == "") {
      this.boolSuc = false
    } else {
      this.boolSuc = true
    }
  }

  fnListadoSolicitud() {
    this.spinerCtes = true

    let objEnv = {
      strAccion: 'getListado_SucursalCte',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayListSucursales = result;
          this.dataSource = new MatTableDataSource(this.arrayListSucursales);
          this.spinerCtes = false;
          this.fnTable();
          this.fnGetListadoSucursal()
        }, error => {
          this.spinerCtes = false;
          var error = <any>error; console.log(error);
        }
      );
  }

  fnListadoOoprtunidad() {
    this.arrayOportunidades = [];
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getListado_oportunidad_lead',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion,
      strGerente: this.strGerente_header,
      strSucursal: this.strSucursal_header
    }, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayOportunidades = result

        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnmDLConAct(obj: any) {
    this.intIDActividad = obj.intID_Actividad
    let idAct = obj.intID_Actividad, Act: any;
    this.fnInitSummer();
    Act = this.arrayActividades.find(x => x.intID_Actividad == idAct);
    if(Act){
      this.objAct = Act;
    }else{
      Act = this.arraySubTareas.find(x => x.intID_Actividad == idAct);
      this.objAct = Act;
    }
    let agente = this.arrayAgentes.find(x => x.strAgente == Act.strAgente_Crea);
    this.objAct.nombreCrea = agente.strNombre;
    let contenido_atrea = this.objAct.strObservaciones && this.objAct.strObservaciones != '' ? this.objAct.strObservaciones : '';
    $('.summernote').summernote('code', contenido_atrea);
    this.fngetComentarios()
   
    if (obj.strEstatus == "CONCLUIDO" || this.objAct?.strTipo == 'Update_Lead_Tarea' || obj.strEstatus == "POR AUTORIZAR") {
      this.fnGetDetActi(obj);
    }
    this.fnGetAnexosActividades()
  }

  fnGetDetActi(objAct: any) {
    this.objDetActividad = {};
    let objEnv = {
      strAccion: 'getDetalleActividad',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.objDetActividad = result[0];
          this.objAct.strObservaciones = this.objDetActividad.strObservaciones == "" ? "--campo vacio--" : this.objDetActividad.strObservaciones
          this.objAct.strResultado = this.objDetActividad.strResultado == "" ? "0" : this.objDetActividad.strResultado
 
        }, error => {
          var error = <any>error; console.log(error);
        });
  }

  fnEiminarProductos(indice: number) {
    this.arrayArticuloAgregados.splice(indice, 1);
  }

  fnInsertOportunidad(object: any) {
    let idLinea = this.arrayLineas2.filter((b: any) => b.strLinea == object.strLinea)/* this.strLinea */
    let objenv = {
      strAccion: 'setOportunidad_Leads',
      strTitulo: object.strTitulo,/* this.strNombreOportunidad */
      dblImporte: object.intMonto,/* this.dblMonto */
      strFecha_Cierre: object.strFecha,/* this.strFechaCierre */
      strAgente: this.objResponsable.strAgente,//this.strResponsable,
      intID_Leads: this.intID_Leads,
      intID_Linea: (idLinea[0]) ? idLinea[0].intID_linea : '',
      xmlArticulos: this.arrayLineasSelect,/* arrayArticuloAgregados */
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objenv, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oportunidad Registrada con exito",
              showConfirmButton: false,
              timer: 1500
            });

            this.fnListadoOoprtunidad()
            this.btnCerrarOportunidades.nativeElement.click();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error al Agregar!"
            });
          }
          this.strNombreOportunidad = ''
          this.dblMonto = ''
          this.strFechaCierre = ''
          this.intID_linea = ''
          this.strResponsable = ''
          this.arrayArts = []
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnselecCliente(objClient: any) {
    this.objResponsable = objClient;
    this.strResponsable = objClient.strNombreAgente;
    this.formOportunidades.patchValue({
      strResponsable: objClient.strNombreAgente
    });
    this.formOportunidades.patchValue({
      strResponsable2: objClient.strNombreAgente
    });
    this.arrayAsigAgentes = [];
  }

  fnselecArticulo(obj: any) {
    this.strBuscarArticulo = obj.strArticulo
    this.strDescripcionArticulo = obj.strDescripcion
    this.arrayArts = [];
    this.fnAgregarArticulo();
  }

  fnBuscarCte(cte: string) {
    this.formOportunidades.patchValue({
      strResponsable2: ""
    });
    if (cte.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getAgentes_OP',
        strBusqueda: cte,
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.arrayAsigAgentes = result
          },
          error => {
            var error = <any>error;
            console.log(error);
          }
        );
    } else {
      this.arrayAsigAgentes = [];
    }
  }

  funConsultarArt(art: any) {
  
    if (art.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getArt_Linea',
        strLinea: this.formOportunidades.get('strLinea')?.value,
        strBusqueda: art,
        strCliente: '',
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.arrayArts = result
          },
          error => {
            var error = <any>error;
            console.log(error);
          }
        );
    } else {
      this.arrayArts = []
    }
  }

  fnAgregarArticulo() {
    let data = {
      strArticulo: this.strBuscarArticulo
    }
    this.arrayArticuloAgregados.push(data)
    this.strBuscarArticulo = ''
    this.intCantidadArticulos = 0
  }

  fngetLineas() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLineas',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayLineas2 = result

          result.forEach((element: any) => {
            let data = {
              Seleccionado: false,
              strLinea: element.strLinea,
              intID_linea: element.intID_linea
            }
            this.arrayPotenciador.push(data)
          });
          this.arrayLineas2.forEach(lin => {
            lin.select = false;
          });
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }


  fnGetDetalleCotizacion() {
    this.spinerArtsPolitica = true;
    let obj = {
      strAccion: 'GetArticulos_politica',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arrayArtsPolitica = result;
          this.dataSourceC = new MatTableDataSource(result);
          this.fnTableArts()
          this.spinerArtsPolitica = false
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerArtsPolitica = false
        });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterArt(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceC.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceC.paginator) {
      this.dataSourceC.paginator.firstPage();
    }
  }

  applyFilterEdoCuenta(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TableCtaCte.filter = filterValue.trim().toLowerCase();
    if (this.TableCtaCte.paginator) {
      this.TableCtaCte.paginator.firstPage();
    }
  }

  /* Historico de venta */
  fnGetHistoricoVenta() {
    this.arrayHistVenta = [];
    this.spinerBool = true;
    let obj = {
      strAccion: 'getHistorico_Vta_fam',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayHistVenta = result
          this.fnDividirEnClientes();
          // this.spinerBool = false
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerBool = false
        });
  }



  fnDividirEnClientes() {
    let arrayClientes = JSON.parse(JSON.stringify(this.arrayHistVenta));

    this.arrayFiltradoHistClientes = Array.from(
      new Set(arrayClientes.map(cliente => cliente.strCliente))
    ).map(strCliente => {
      let cliente = arrayClientes.find(cliente => cliente.strCliente === strCliente);
      return {
        strCliente: cliente.strCliente,
        strNombreEmpresa: cliente.strNombreEmpresa,
        strNombreCliente: cliente.strNombreCliente,
        strNombreAgente: cliente.strNombreAgente,
        strAgente: cliente.strAgente,
        strGerente: cliente.strGerente,
        strSucursal: cliente.strSucursal,
        strLinea: cliente.strLinea,
        strDescripcion: cliente.strDescripcion,
        strArticulo: cliente.strArticulo,
        sumEnero: 0,
        dblMargenEnero: 0,
        sumFebrero: 0,
        dblMargenFebrero: 0,
        sumMarzo: 0,
        dblMargenMarzo: 0,
        sumAbril: 0,
        dblMargenAbril: 0,
        sumMayo: 0,
        dblMargenMayo: 0,
        sumJunio: 0,
        dblMargenJunio: 0,
        sumJulio: 0,
        dblMargenJulio: 0,
        sumAgosto: 0,
        dblMargenAgosto: 0,
        sumSeptiembre: 0,
        dblMargenSeptiembre: 0,
        sumOctubre: 0,
        dblMargenOctubre: 0,
        sumNoviembre: 0,
        dblMargenNoviembre: 0,
        sumDiciembre: 0,
        dblMargenDiciembre: 0
      };
    });
    this.fnHistVentaNew()
  }

  fnHistVentaNew() {
    this.spinerBool = true;
    let deepCopyArray = JSON.parse(JSON.stringify(this.arrayHistVenta));
    this.arrayFiltradoHistClientes.forEach(cte => {
      cte.seeLineas = false
      if (!cte.arrayLineasTodo) {
        cte.arrayLineasTodo = [];
      }
      deepCopyArray.forEach(obj => {
        if (cte.strCliente == obj.strCliente) {
          cte.arrayLineasTodo.push(obj);
        }
      });
      if (!cte.arrayLineas) {
        cte.arrayLineas = [];
      }
      let LinCopy = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
      LinCopy.forEach(object => {
        let objetoEncontrado = cte.arrayLineas.find(obj => obj.strLinea === object.strLinea);
        let resultado = objetoEncontrado ? 1 : 0;
        if (resultado == 0) {
          cte.arrayLineas.push({
            seeArts: false,
            strCliente: object.strCliente,
            strNombreEmpresa: object.strNombreEmpresa,
            strNombreCliente: object.strNombreCliente,
            strNombreAgente: object.strNombreAgente,
            strAgente: object.strAgente,
            strGerente: object.strGerente,
            strSucursal: object.strSucursal,
            strLinea: object.strLinea,
            strArticulo: object.strArticulo,
            strDescripcion: object.strDescripcion,
            sumEnero: 0,
            dblMargenEnero: 0,
            sumFebrero: 0,
            dblMargenFebrero: 0,
            sumMarzo: 0,
            dblMargenMarzo: 0,
            sumAbril: 0,
            dblMargenAbril: 0,
            sumMayo: 0,
            dblMargenMayo: 0,
            sumJunio: 0,
            dblMargenJunio: 0,
            sumJulio: 0,
            dblMargenJulio: 0,
            sumAgosto: 0,
            dblMargenAgosto: 0,
            sumSeptiembre: 0,
            dblMargenSeptiembre: 0,
            sumOctubre: 0,
            dblMargenOctubre: 0,
            sumNoviembre: 0,
            dblMargenNoviembre: 0,
            sumDiciembre: 0,
            dblMargenDiciembre: 0
          });
        }
      });
      cte.arrayLineas.forEach(lineas => {
        lineas.seeArts = false;
        if (!lineas.arrayArticulosTodos) {
          lineas.arrayArticulosTodos = [];
        }
        if (!lineas.arrayArticulos) {
          lineas.arrayArticulos = [];
        }

        let LinCopy2 = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
        LinCopy2.forEach(lin => {
          if (lin.strLinea == lineas.strLinea) {
            lineas.arrayArticulosTodos.push({
              seeArts: false,
              strCliente: lin.strCliente,
              strNombreEmpresa: lin.strNombreEmpresa,
              strNombreCliente: lin.strNombreCliente,
              strNombreAgente: lin.strNombreAgente,
              strAgente: lin.strAgente,
              strGerente: lin.strGerente,
              strSucursal: lin.strSucursal,
              strLinea: lin.strLinea,
              strArticulo: lin.strArticulo,
              strDescripcion: lin.strDescripcion,
              dblEnero: 0,
              dblMargenEnero: 0,
              dblFebrero: 0,
              dblMargenFebrero: 0,
              dblMarzo: 0,
              dblMargenMarzo: 0,
              dblAbril: 0,
              dblMargenAbril: 0,
              dblMayo: 0,
              dblMargenMayo: 0,
              dblJunio: 0,
              dblMargenJunio: 0,
              dblJulio: 0,
              dblMargenJulio: 0,
              dblAgosto: 0,
              dblMargenAgosto: 0,
              dblSeptiembre: 0,
              dblMargenSeptiembre: 0,
              dblOctubre: 0,
              dblMargenOctubre: 0,
              dblNoviembre: 0,
              dblMargenNoviembre: 0,
              dblDiciembre: 0,
              dblMargenDiciembre: 0,
              total: 0
            });
          }
        });
      });
    });

    this.fnSumasArticulosHistVentas()

  }

  fnSumasArticulosHistVentas() {

    this.arrayFiltradoHistClientes.forEach(cte => {
      let copiaProfundTodo = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
      cte.arrayLineas.forEach(lineas => {
        lineas.arrayArticulos = [];
        const articulosUnicos = new Set();
        // Filtrar los artículos únicos y asignarlos a lineas.arrayArticulos
        lineas.arrayArticulos = lineas.arrayArticulosTodos.filter(arti => {
          if (!articulosUnicos.has(arti.strArticulo)) {
            articulosUnicos.add(arti.strArticulo); // Agregar el strArticulo al Set
            return true; // Mantener el artículo en el nuevo array
          }
          return false; // Filtrar el artículo duplicado
        });
        lineas.arrayArticulos.forEach(art => {
          if (!art.arrayArt) {
            art.arrayArt = [];
          }
          art.arrayArt = copiaProfundTodo.filter(o => o.strArticulo === art.strArticulo).map(o => ({
            strArticulo: o.strArticulo,
            intPeriodo: o.intPeriodo,
            dblMargenBruto: o.dblSaldo,
            dblImporte: o.dblImporte
          }));

          art.dblEnero = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 1 ? acc + o.dblImporte : acc;
          }, 0);

          art.dblMargenEneroB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 1 ? acc + o.dblMargenBruto : acc;
          }, 0);

          art.dblMargenEnero = art.dblMargenEneroB > 0 ? ((art.dblMargenEneroB / art.dblEnero) * 100) : 0;
          // Febrero
          art.dblFebrero = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 2 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenFebreroB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 2 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenFebrero = art.dblMargenFebreroB > 0 ? ((art.dblMargenFebreroB / art.dblFebrero) * 100) : 0;

          // Marzo
          art.dblMarzo = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 3 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenMarzoB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 3 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenMarzo = art.dblMargenMarzoB > 0 ? ((art.dblMargenMarzoB / art.dblMarzo) * 100) : 0;

          // Abril
          art.dblAbril = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 4 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenAbrilB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 4 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenAbril = art.dblMargenAbrilB > 0 ? ((art.dblMargenAbrilB / art.dblAbril) * 100) : 0;

          // Mayo
          art.dblMayo = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 5 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenMayoB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 5 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenMayo = art.dblMargenMayoB > 0 ? ((art.dblMargenMayoB / art.dblMayo) * 100) : 0;

          // Junio
          art.dblJunio = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 6 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenJunioB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 6 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenJunio = art.dblMargenJunioB > 0 ? ((art.dblMargenJunioB / art.dblJunio) * 100) : 0;

          // Julio
          art.dblJulio = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 7 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenJulioB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 7 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenJulio = art.dblMargenJulioB > 0 ? ((art.dblMargenJulioB / art.dblJulio) * 100) : 0;

          // Agosto
          art.dblAgosto = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 8 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenAgostoB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 8 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenAgosto = art.dblMargenAgostoB > 0 ? ((art.dblMargenAgostoB / art.dblAgosto) * 100) : 0;

          // Septiembre
          art.dblSeptiembre = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 9 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenSeptiembreB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 9 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenSeptiembre = art.dblMargenSeptiembreB > 0 ? ((art.dblMargenSeptiembreB / art.dblSeptiembre) * 100) : 0;

          // Octubre
          art.dblOctubre = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 10 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenOctubreB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 10 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenOctubre = art.dblMargenOctubreB > 0 ? ((art.dblMargenOctubreB / art.dblOctubre) * 100) : 0;

          // Noviembre
          art.dblNoviembre = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 11 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenNoviembreB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 11 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenNoviembre = art.dblMargenNoviembreB > 0 ? ((art.dblMargenNoviembreB / art.dblNoviembre) * 100) : 0;

          // Diciembre
          art.dblDiciembre = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 12 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenDiciembreB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 12 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenDiciembre = art.dblMargenDiciembreB > 0 ? ((art.dblMargenDiciembreB / art.dblDiciembre) * 100) : 0;

          art.total = (Number(art.dblEnero) > 0 ? Number(art.dblEnero) : 0) +
            (Number(art.dblFebrero) > 0 ? Number(art.dblFebrero) : 0) +
            (Number(art.dblMarzo) > 0 ? Number(art.dblMarzo) : 0) +
            (Number(art.dblAbril) > 0 ? Number(art.dblAbril) : 0) +
            (Number(art.dblMayo) > 0 ? Number(art.dblMayo) : 0) +
            (Number(art.dblJunio) > 0 ? Number(art.dblJunio) : 0) +
            (Number(art.dblJulio) > 0 ? Number(art.dblJulio) : 0) +
            (Number(art.dblAgosto) > 0 ? Number(art.dblAgosto) : 0) +
            (Number(art.dblSeptiembre) > 0 ? Number(art.dblSeptiembre) : 0) +
            (Number(art.dblOctubre) > 0 ? Number(art.dblOctubre) : 0) +
            (Number(art.dblNoviembre) > 0 ? Number(art.dblNoviembre) : 0) +
            (Number(art.dblDiciembre) > 0 ? Number(art.dblDiciembre) : 0);

          art.totalMargenBruto = art.dblMargenEneroB + art.dblMargenFebreroB + art.dblMargenMarzoB +
            art.dblMargenAbrilB + art.dblMargenMayoB + art.dblMargenJunioB +
            art.dblMargenJulioB + art.dblMargenAgostoB + art.dblMargenSeptiembreB +
            art.dblMargenOctubreB + art.dblMargenNoviembreB + art.dblMargenDiciembreB;
          art.totalMargen = art.totalMargenBruto > 0 ? ((art.totalMargenBruto / art.total) * 100) : 0;
        });
      });
    });
    this.spinerBool = false;
    this.fnHistoricoVentaSumasLineas()

  }

  fnLlenarArrayCompleto() {
    let deepCopyArray = JSON.parse(JSON.stringify(this.arrayHistVenta));
    this.arrayFiltradoHistClientes.forEach(cte => {
      cte.seeLineas = false
      if (!cte.arrayLineasTodo) {
        cte.arrayLineasTodo = [];
      }
      deepCopyArray.forEach(obj => {
        if (cte.strCliente == obj.strCliente) {
          cte.arrayLineasTodo.push(obj);
        }
      });
      if (!cte.arrayLineas) {
        cte.arrayLineas = [];
      }
      let LinCopy = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
      LinCopy.forEach(object => {
        let objetoEncontrado = cte.arrayLineas.find(obj => obj.strLinea === object.strLinea);
        let resultado = objetoEncontrado ? 1 : 0;
        if (resultado == 0) {
          cte.arrayLineas.push(object);
        }
      });
      cte.arrayLineas.forEach(lineas => {
        lineas.seeArts = false;
        if (!lineas.arrayArticulos) {
          lineas.arrayArticulos = [];
        }
        let LinCopy2 = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
        LinCopy2.forEach(lin => {
          if (lin.strLinea == lineas.strLinea) {
            lineas.arrayArticulos.push(lin)
          }
        });
      });
    });

    this.fnHistoricoVentaSumasLineas();
    /*    console.log( this.arrayFiltradoHistClientes); */
  }

  fnCambiarEstatusVista(ind: number, obj: any) {
    this.arrayFiltradoHistClientes.forEach(el => {
      if (el.strCliente == obj.strCliente) {
        if (el.seeLineas == false) {
          el.seeLineas = true
        } else {
          el.seeLineas = false
        }
      } else {
        el.seeLineas = false
      }
    });
  }

  fnCambiarEstatusVistaLineas(ind: number, obj: any, ob: any) {
    this.arrayFiltradoHistClientes.forEach(el => {
      if (el.strCliente == ob.strCliente) {
        el.arrayLineas.forEach(el => {
          if (el.strLinea == obj.strLinea) {
            if (el.seeArts == false) {
              el.seeArts = true
            } else {
              el.seeArts = false
            }
          } else {
            el.seeArts = false
          }
        });
      }
    });
  }

  /* Flujo de cliente */
  fnGetFlujoCliente() {
    this.spinerFlujoCte = true
    let obj = {
      strAccion: 'getFlujoCte',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayFlujoCliente = result
          this.fnDividirFlujoCte();
          this.spinerFlujoCte = false
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerFlujoCte = false
        });
  }

  /* Flujo de cliente */
  fnGetFlujoFamiliaCliente() {
    this.spinerFlujoCte = true
    let obj = {
      strAccion: 'getFlujoCteFamilia',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayFlujoClienteFamilia = result
          this.arrayFlujoClienteFamilia.forEach(element => {
            this.total_importe_fam = this.total_importe_fam + element.dblImporte;
            this.total_cobrado_fam = this.total_cobrado_fam + element.dblCobrado;
            this.total_saldo_fam = this.total_saldo_fam + element.dblSaldo;
          });
          //  this.fnDividirFlujoCteFamilia();
          this.spinerFlujoCte = false
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerFlujoCte = false
        });
  }


  fnDividirFlujoCte() {
    const flujoCte = new Set<string>();
    this.arrayFlujoClienteFiltrado = this.arrayFlujoCliente.filter(obj => {
      if (!flujoCte.has(obj.strCliente)) {
        flujoCte.add(obj.strCliente);
        return true;
      }
      return false;
    });
    /*    console.log(this.arrayFlujoClienteFiltrado ); */
    this.fnLlenarArrayFlujoCte()
  }

  fnDividirFlujoCteFamilia() {
    const flujoCte = new Set<string>();
    this.arrayFlujoClienteFamiliaFiltrado = this.arrayFlujoClienteFamilia.filter(obj => {
      if (!flujoCte.has(obj.strCliente)) {
        flujoCte.add(obj.strCliente);
        return true;
      }
      return false;
    });
    this.fnLlenarArrayFlujoCte()
  }

  fnLlenarArrayFlujoCte() {

    const deepCopyArray = JSON.parse(JSON.stringify(this.arrayFlujoCliente));
    this.arrayFlujoClienteFiltrado.forEach(cte => {
      cte.seeLineas = false
      if (!cte.arrayXcte) {
        cte.arrayXcte = [];
      }
      deepCopyArray.forEach(obj => {
        if (cte.strCliente == obj.strCliente) {
          cte.arrayXcte.push(obj);
        }
      });
      if (!cte.arrayEjercicio) {
        cte.arrayEjercicio = [];
      }
      const LinCopy = JSON.parse(JSON.stringify(cte.arrayXcte));
      LinCopy.forEach(object => {
        const objetoEncontrado = cte.arrayEjercicio.find(obj => obj.intEjercicio === object.intEjercicio);
        const resultado = objetoEncontrado ? 1 : 0;
        if (resultado == 0) {
          cte.arrayEjercicio.push(object);
        }
      });
      cte.arrayEjercicio.forEach(lineas => {
        lineas.seePer = false;
        if (!lineas.arrayPeriodo) {
          lineas.arrayPeriodo = [];
        }
        const LinCopy2 = JSON.parse(JSON.stringify(cte.arrayXcte));
        LinCopy2.forEach(lin => {
          if (lin.intEjercicio == lineas.intEjercicio) {
            lineas.arrayPeriodo.push(lin)
          }
        });
      });
    });

    this.fnFlujoCteSumasLineas()
  }

  fnLlenarArrayFlujoCteFamilia() {

    const deepCopyArray = JSON.parse(JSON.stringify(this.arrayFlujoClienteFamilia));
    this.arrayFlujoClienteFamiliaFiltrado.forEach(cte => {
      cte.seeLineas = false
      if (!cte.arrayXcte) {
        cte.arrayXcte = [];
      }
      deepCopyArray.forEach(obj => {
        if (cte.strCliente == obj.strCliente) {
          cte.arrayXcte.push(obj);
        }
      });
      if (!cte.arrayEjercicio) {
        cte.arrayEjercicio = [];
      }
      const LinCopy = JSON.parse(JSON.stringify(cte.arrayXcte));
      LinCopy.forEach(object => {
        const objetoEncontrado = cte.arrayEjercicio.find(obj => obj.intEjercicio === object.intEjercicio);
        const resultado = objetoEncontrado ? 1 : 0;
        if (resultado == 0) {
          cte.arrayEjercicio.push(object);
        }
      });
      cte.arrayEjercicio.forEach(lineas => {
        lineas.seePer = false;
        if (!lineas.arrayPeriodo) {
          lineas.arrayPeriodo = [];
        }
        const LinCopy2 = JSON.parse(JSON.stringify(cte.arrayXcte));
        LinCopy2.forEach(lin => {
          if (lin.intEjercicio == lineas.intEjercicio) {
            lineas.arrayPeriodo.push(lin)
          }
        });
      });
    });

    this.fnFlujoCteFamiliaSumasLineas()
  }

  fnCambiarEstatusVistaFlujoCte(ind: number, obj: any) {
    this.arrayFlujoClienteFiltrado.forEach(el => {
      if (el.strCliente == obj.strCliente) {
        if (el.seeLineas == false) {
          el.seeLineas = true
        } else {
          el.seeLineas = false
        }
      } else {
        el.seeLineas = false
      }
    });
  }

  fnCambiarEstatusVistaLineasCteFlujo(ind: number, obj: any, ob: any) {
    this.arrayFlujoClienteFiltrado.forEach(el => {
      if (el.strCliente == ob.strCliente) {
        el.arrayEjercicio.forEach(el => {
          if (el.intEjercicio == obj.intEjercicio) {
            if (el.seePer == false) {
              el.seePer = true
            } else {
              el.seePer = false
            }
          } else {
            el.seePer = false
          }
        });
      }
    });
  }

  cambiarImgLogo(event: Event) {
    this.spinerImg = true
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedRenovFile = fileInput.files[0];
      this.strDBRutaLogo = 'Adjuntos/Leads/Logo/' + this.selectedRenovFile.name
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedRenovFile);
      reader.onload = (event: any) => {
        this.strRutaLogo = event.target.result;
      };
      this.spinerImg = false
    } else {
      this.spinerImg = false
    }
  }

  fnAnexoMov() {
    const formData = new FormData();
    formData.append('archivo', this.selectedRenovFile);
    this._serviciosService.setAnexMov1(formData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resultados: any) => {
          if (resultados.mensaje == '200') {
            Swal.fire({
              icon: 'success',
              title: 'Oops...',
              text: 'Actualizado!',
            });
            this.fnUpdateLogo()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Error al subir imagen!',
            });
          }
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error al subir imagen!',
          });
        }
      );
  }

  fnUpdateLogo() {
    this.spinerImg = true
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Logo_Leads',
      intID_Leads: this.intID_Leads,
      strUrl_Logo: this.strDBRutaLogo,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.fnGetInfo()
            /*   this.fnDetalleLead() */
            this.strRutaLogo = ''
            this.spinerImg = false
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
            this.spinerImg = false
          }
        },
        error => {
          this.spinerImg = false
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnHistoricoVentaSumasLineas() {
    this.arrayFiltradoHistClientes.forEach(ctee => {
      ctee.arrayLineas.forEach(lin => {
        lin.sumEnero = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblEnero;
        }, 0);
        lin.dblMargenEneroB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenEneroB;
        }, 0);
        lin.dblMargenEnero = lin.dblMargenEneroB > 0 ? ((lin.dblMargenEneroB / lin.sumEnero) * 100) : 0;
        // Febrero
        lin.sumFebrero = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblFebrero;
        }, 0);
        lin.dblMargenFebreroB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenFebreroB;
        }, 0);
        lin.dblMargenFebrero = lin.dblMargenFebreroB > 0 ? ((lin.dblMargenFebreroB / lin.sumFebrero) * 100) : 0;

        // Marzo
        lin.sumMarzo = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMarzo;
        }, 0);
        lin.dblMargenMarzoB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenMarzoB;
        }, 0);
        lin.dblMargenMarzo = lin.dblMargenMarzoB > 0 ? ((lin.dblMargenMarzoB / lin.sumMarzo) * 100) : 0;

        // Abril
        lin.sumAbril = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblAbril;
        }, 0);
        lin.dblMargenAbrilB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenAbrilB;
        }, 0);
        lin.dblMargenAbril = lin.dblMargenAbrilB > 0 ? ((lin.dblMargenAbrilB / lin.sumAbril) * 100) : 0;

        // Mayo
        lin.sumMayo = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMayo;
        }, 0);
        lin.dblMargenMayoB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenMayoB;
        }, 0);
        lin.dblMargenMayo = lin.dblMargenMayoB > 0 ? ((lin.dblMargenMayoB / lin.sumMayo) * 100) : 0;

        // Junio
        lin.sumJunio = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblJunio;
        }, 0);
        lin.dblMargenJunioB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenJunioB;
        }, 0);
        lin.dblMargenJunio = lin.dblMargenJunioB > 0 ? ((lin.dblMargenJunioB / lin.sumJunio) * 100) : 0;

        // Julio
        lin.sumJulio = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblJulio;
        }, 0);
        lin.dblMargenJulioB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenJulioB;
        }, 0);
        lin.dblMargenJulio = lin.dblMargenJulioB > 0 ? ((lin.dblMargenJulioB / lin.sumJulio) * 100) : 0;

        // Agosto
        lin.sumAgosto = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblAgosto;
        }, 0);
        lin.dblMargenAgostoB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenAgostoB;
        }, 0);
        lin.dblMargenAgosto = lin.dblMargenAgostoB > 0 ? ((lin.dblMargenAgostoB / lin.sumAgosto) * 100) : 0;

        // Septiembre
        lin.sumSeptiembre = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblSeptiembre;
        }, 0);
        lin.dblMargenSeptiembreB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenSeptiembreB;
        }, 0);
        lin.dblMargenSeptiembre = lin.dblMargenSeptiembreB > 0 ? ((lin.dblMargenSeptiembreB / lin.sumSeptiembre) * 100) : 0;

        // Octubre
        lin.sumOctubre = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblOctubre;
        }, 0);
        lin.dblMargenOctubreB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenOctubreB;
        }, 0);
        lin.dblMargenOctubre = lin.dblMargenOctubreB > 0 ? ((lin.dblMargenOctubreB / lin.sumOctubre) * 100) : 0;

        // Noviembre
        lin.sumNoviembre = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblNoviembre;
        }, 0);
        lin.dblMargenNoviembreB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenNoviembreB;
        }, 0);
        lin.dblMargenNoviembre = lin.dblMargenNoviembreB > 0 ? ((lin.dblMargenNoviembreB / lin.sumNoviembre) * 100) : 0;

        // Diciembre
        lin.sumDiciembre = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblDiciembre;
        }, 0);
        lin.dblMargenDiciembreB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenDiciembreB;
        }, 0);
        lin.dblMargenDiciembre = lin.dblMargenDiciembreB > 0 ? ((lin.dblMargenDiciembreB / lin.sumDiciembre) * 100) : 0;

        lin.total = lin.sumEnero + lin.sumFebrero + lin.sumMarzo + lin.sumAbril + lin.sumMayo + lin.sumJunio +
          lin.sumJulio + lin.sumAgosto + lin.sumSeptiembre + lin.sumOctubre + lin.sumNoviembre + lin.sumDiciembre

        lin.totalMargenB = lin.dblMargenEneroB + lin.dblMargenFebreroB + lin.dblMargenMarzoB + lin.dblMargenAbrilB + lin.dblMargenMayoB +
          lin.dblMargenJunioB + lin.dblMargenJulioB + lin.dblMargenAgostoB + lin.dblMargenSeptiembreB + lin.dblMargenOctubreB + lin.dblMargenNoviembreB +
          lin.dblMargenDiciembreB

        lin.totalMargen = lin.totalMargenB > 0 ? ((lin.totalMargenB / lin.total) * 100) : 0
      });
    });


    this.arrayFiltradoHistClientes.forEach(lin => {
      /*   ctee.arrayLineas.forEach(lin => { */
      lin.sumEnero = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumEnero;
      }, 0);
      lin.dblMargenEneroB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenEneroB;
      }, 0);
      lin.dblMargenEnero = lin.dblMargenEneroB > 0 ? ((lin.dblMargenEneroB / lin.sumEnero) * 100) : 0;
      // Febrero
      lin.sumFebrero = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumFebrero;
      }, 0);
      lin.dblMargenFebreroB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenFebreroB;
      }, 0);
      lin.dblMargenFebrero = lin.dblMargenFebreroB > 0 ? ((lin.dblMargenFebreroB / lin.sumFebrero) * 100) : 0;

      // Marzo
      lin.sumMarzo = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumMarzo;
      }, 0);
      lin.dblMargenMarzoB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenMarzoB;
      }, 0);
      lin.dblMargenMarzo = lin.dblMargenMarzoB > 0 ? ((lin.dblMargenMarzoB / lin.sumMarzo) * 100) : 0;

      // Abril
      lin.sumAbril = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumAbril;
      }, 0);
      lin.dblMargenAbrilB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenAbrilB;
      }, 0);
      lin.dblMargenAbril = lin.dblMargenAbrilB > 0 ? ((lin.dblMargenAbrilB / lin.sumAbril) * 100) : 0;

      // Mayo
      lin.sumMayo = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumMayo;
      }, 0);
      lin.dblMargenMayoB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenMayoB;
      }, 0);
      lin.dblMargenMayo = lin.dblMargenMayoB > 0 ? ((lin.dblMargenMayoB / lin.sumMayo) * 100) : 0;

      // Junio
      lin.sumJunio = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumJunio;
      }, 0);
      lin.dblMargenJunioB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenJunioB;
      }, 0);
      lin.dblMargenJunio = lin.dblMargenJunioB > 0 ? ((lin.dblMargenJunioB / lin.sumJunio) * 100) : 0;

      // Julio
      lin.sumJulio = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumJulio;
      }, 0);
      lin.dblMargenJulioB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenJulioB;
      }, 0);
      lin.dblMargenJulio = lin.dblMargenJulioB > 0 ? ((lin.dblMargenJulioB / lin.sumJulio) * 100) : 0;

      // Agosto
      lin.sumAgosto = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumAgosto;
      }, 0);
      lin.dblMargenAgostoB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenAgostoB;
      }, 0);
      lin.dblMargenAgosto = lin.dblMargenAgostoB > 0 ? ((lin.dblMargenAgostoB / lin.sumAgosto) * 100) : 0;

      // Septiembre
      lin.sumSeptiembre = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumSeptiembre;
      }, 0);
      lin.dblMargenSeptiembreB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenSeptiembreB;
      }, 0);
      lin.dblMargenSeptiembre = lin.dblMargenSeptiembreB > 0 ? ((lin.dblMargenSeptiembreB / lin.sumSeptiembre) * 100) : 0;

      // Octubre
      lin.sumOctubre = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumOctubre;
      }, 0);
      lin.dblMargenOctubreB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenOctubreB;
      }, 0);
      lin.dblMargenOctubre = lin.dblMargenOctubreB > 0 ? ((lin.dblMargenOctubreB / lin.sumOctubre) * 100) : 0;

      // Noviembre
      lin.sumNoviembre = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumNoviembre;
      }, 0);
      lin.dblMargenNoviembreB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenNoviembreB;
      }, 0);
      lin.dblMargenNoviembre = lin.dblMargenNoviembreB > 0 ? ((lin.dblMargenNoviembreB / lin.sumNoviembre) * 100) : 0;

      // Diciembre
      lin.sumDiciembre = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumDiciembre;
      }, 0);
      lin.dblMargenDiciembreB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenDiciembreB;
      }, 0);
      lin.dblMargenDiciembre = lin.dblMargenDiciembreB > 0 ? ((lin.dblMargenDiciembreB / lin.sumDiciembre) * 100) : 0;

      lin.total = lin.sumEnero + lin.sumFebrero + lin.sumMarzo + lin.sumAbril + lin.sumMayo + lin.sumJunio +
        lin.sumJulio + lin.sumAgosto + lin.sumSeptiembre + lin.sumOctubre + lin.sumNoviembre + lin.sumDiciembre

      lin.totalMargenB = lin.dblMargenEneroB + lin.dblMargenFebreroB + lin.dblMargenMarzoB + lin.dblMargenAbrilB + lin.dblMargenMayoB +
        lin.dblMargenJunioB + lin.dblMargenJulioB + lin.dblMargenAgostoB + lin.dblMargenSeptiembreB + lin.dblMargenOctubreB + lin.dblMargenNoviembreB +
        lin.dblMargenDiciembreB

      lin.totalMargen = lin.totalMargenB > 0 ? ((lin.totalMargenB / lin.total) * 100) : 0
    });


    this.objHistCliente.dblEnero = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumEnero ? objetoActual.sumEnero : 0);
    }, 0);

    this.objHistCliente.dblMargenEneroB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenEneroB ? objetoActual.dblMargenEneroB : 0);
    }, 0);

    this.objHistCliente.dblMargenEnero = this.objHistCliente.dblMargenEneroB > 0 ? ((this.objHistCliente.dblMargenEneroB / this.objHistCliente.dblEnero) * 100) : 0


    this.objHistCliente.dblFebrero = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumFebrero ? objetoActual.sumFebrero : 0);
    }, 0);

    this.objHistCliente.dblMargenFebreroB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenFebreroB ? objetoActual.dblMargenFebreroB : 0);
    }, 0);

    this.objHistCliente.dblMargenFebrero = this.objHistCliente.dblMargenFebreroB > 0 ? ((this.objHistCliente.dblMargenFebreroB / this.objHistCliente.dblFebrero) * 100) : 0;


    this.objHistCliente.dblMarzo = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumMarzo ? objetoActual.sumMarzo : 0);
    }, 0);

    this.objHistCliente.dblMargenMarzoB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenMarzoB ? objetoActual.dblMargenMarzoB : 0);
    }, 0);

    this.objHistCliente.dblMargenMarzo = this.objHistCliente.dblMargenMarzoB > 0 ? ((this.objHistCliente.dblMargenMarzoB / this.objHistCliente.dblMarzo) * 100) : 0;



    this.objHistCliente.dblAbril = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumAbril ? objetoActual.sumAbril : 0);
    }, 0);

    this.objHistCliente.dblMargenAbrilB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenAbrilB ? objetoActual.dblMargenAbrilB : 0);
    }, 0);

    this.objHistCliente.dblMargenAbril = this.objHistCliente.dblMargenAbrilB > 0 ? ((this.objHistCliente.dblMargenAbrilB / this.objHistCliente.dblAbril) * 100) : 0;


    this.objHistCliente.dblMayo = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumMayo ? objetoActual.sumMayo : 0);
    }, 0);

    this.objHistCliente.dblMargenMayoB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenMayoB ? objetoActual.dblMargenMayoB : 0);
    }, 0);

    this.objHistCliente.dblMargenMayo = this.objHistCliente.dblMargenMayoB > 0 ? ((this.objHistCliente.dblMargenMayoB / this.objHistCliente.dblMayo) * 100) : 0;


    this.objHistCliente.dblJunio = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumJunio ? objetoActual.sumJunio : 0);
    }, 0);

    this.objHistCliente.dblMargenJunioB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenJunioB ? objetoActual.dblMargenJunioB : 0);
    }, 0);

    this.objHistCliente.dblMargenJunio = this.objHistCliente.dblMargenJunioB > 0 ? ((this.objHistCliente.dblMargenJunioB / this.objHistCliente.dblJunio) * 100) : 0;


    this.objHistCliente.dblJulio = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumJulio ? objetoActual.sumJulio : 0);
    }, 0);

    this.objHistCliente.dblMargenJulioB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenJulioB ? objetoActual.dblMargenJulioB : 0);
    }, 0);

    this.objHistCliente.dblMargenJulio = this.objHistCliente.dblMargenJulioB > 0 ? ((this.objHistCliente.dblMargenJulioB / this.objHistCliente.dblJulio) * 100) : 0;


    this.objHistCliente.dblAgosto = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumAgosto ? objetoActual.sumAgosto : 0);
    }, 0);

    this.objHistCliente.dblMargenAgostoB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenAgostoB ? objetoActual.dblMargenAgostoB : 0);
    }, 0);

    this.objHistCliente.dblMargenAgosto = this.objHistCliente.dblMargenAgostoB > 0 ? ((this.objHistCliente.dblMargenAgostoB / this.objHistCliente.dblAgosto) * 100) : 0;



    this.objHistCliente.dblSeptiembre = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumSeptiembre ? objetoActual.sumSeptiembre : 0);
    }, 0);

    this.objHistCliente.dblMargenSeptiembreB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenSeptiembreB ? objetoActual.dblMargenSeptiembreB : 0);
    }, 0);

    this.objHistCliente.dblMargenSeptiembre = this.objHistCliente.dblMargenSeptiembreB > 0 ? ((this.objHistCliente.dblMargenSeptiembreB / this.objHistCliente.dblSeptiembre) * 100) : 0;


    this.objHistCliente.dblOctubre = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumOctubre ? objetoActual.sumOctubre : 0);
    }, 0);

    this.objHistCliente.dblMargenOctubreB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenOctubreB ? objetoActual.dblMargenOctubreB : 0);
    }, 0);

    this.objHistCliente.dblMargenOctubre = this.objHistCliente.dblMargenOctubreB > 0 ? ((this.objHistCliente.dblMargenOctubreB / this.objHistCliente.dblOctubre) * 100) : 0;


    this.objHistCliente.dblNoviembre = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumNoviembre ? objetoActual.sumNoviembre : 0);
    }, 0);

    this.objHistCliente.dblMargenNoviembreB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenNoviembreB ? objetoActual.dblMargenNoviembreB : 0);
    }, 0);
    this.objHistCliente.dblMargenNoviembre = this.objHistCliente.dblMargenNoviembreB > 0 ? ((this.objHistCliente.dblMargenNoviembreB / this.objHistCliente.dblNoviembre) * 100) : 0;


    this.objHistCliente.dblDiciembre = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumDiciembre ? objetoActual.sumDiciembre : 0);
    }, 0);

    this.objHistCliente.dblMargenDiciembreB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenDiciembreB ? objetoActual.dblMargenDiciembreB : 0);
    }, 0);

    this.objHistCliente.dblMargenDiciembre = this.objHistCliente.dblMargenDiciembreB > 0 ? ((this.objHistCliente.dblMargenDiciembreB / this.objHistCliente.dblDiciembre) * 100) : 0;


    this.objHistCliente.dblTotal = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.total ? objetoActual.total : 0);
    }, 0);

    this.objHistCliente.dblTotalMargenB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalMargenB ? objetoActual.totalMargenB : 0);
    }, 0);

    this.objHistCliente.dblTotalMargen = this.objHistCliente.dblTotalMargenB > 0 ? ((this.objHistCliente.dblTotalMargenB / this.objHistCliente.dblTotal) * 100) : 0


    /*  this.objHistCliente.dblMargenTotalB = this.objHistCliente.dblMargenEneroB + this.objHistCliente.dblMargenFebreroB +
     this.objHistCliente.dblMargenMarzoB + this.objHistCliente.dblMargenAbrilB + this.objHistCliente.dblMargenMayoB +
     this.objHistCliente.dblMargenJunioB + this.objHistCliente.dblMargenJulioB + this.objHistCliente.dblMargenAgostoB +
     this.objHistCliente.dblMargenSeptiembreB + this.objHistCliente.dblMargenOctubreB + this.objHistCliente.dblMargenNoviembreB +
     this.objHistCliente.dblMargenDiciembreB; */


  }


  fnFlujoCteSumasLineas() {

    this.arrayFlujoClienteFiltrado.forEach(cte => {

      cte.arrayEjercicio.forEach(ejer => {
        let sumImporte = 0, sumCobrado = 0, sumSaldo = 0;
        ejer.arrayPeriodo.forEach(per => {
          sumImporte += per.dblImporte
          sumCobrado += per.dblCobrado
          sumSaldo += per.dblSaldo
        });
        ejer.sumImporte = sumImporte
        ejer.sumCobrado = sumCobrado
        ejer.sumSaldo = sumSaldo
      });
    });

    this.fnFlujoCteSumas()
  }

  fnFlujoCteFamiliaSumasLineas() {

    this.arrayFlujoClienteFamiliaFiltrado.forEach(cte => {

      cte.arrayEjercicio.forEach(ejer => {
        let sumImporte = 0, sumCobrado = 0, sumSaldo = 0;
        ejer.arrayPeriodo.forEach(per => {
          sumImporte += per.dblImporte
          sumCobrado += per.dblCobrado
          sumSaldo += per.dblSaldo
        });
        ejer.sumImporte = sumImporte
        ejer.sumCobrado = sumCobrado
        ejer.sumSaldo = sumSaldo
      });
    });

    this.fnFlujoCteFamiliaSumas()
  }

  fnFlujoCteSumas() {
    this.arrayFlujoClienteFiltrado.forEach(cte => {
      let sumImporte = 0, sumCobrado = 0, sumSaldo = 0;
      cte.arrayEjercicio.forEach(ejer => {
        sumImporte += ejer.sumImporte
        sumCobrado += ejer.sumCobrado
        sumSaldo += ejer.sumSaldo
      });
      cte.sumImporte = sumImporte
      cte.sumCobrado = sumCobrado
      cte.sumSaldo = sumSaldo
    });

  }

  fnFlujoCteFamiliaSumas() {
    this.arrayFlujoClienteFamiliaFiltrado.forEach(cte => {
      let sumImporte = 0, sumCobrado = 0, sumSaldo = 0;
      cte.arrayEjercicio.forEach(ejer => {
        sumImporte += ejer.sumImporte
        sumCobrado += ejer.sumCobrado
        sumSaldo += ejer.sumSaldo
      });
      cte.sumImporte = sumImporte
      cte.sumCobrado = sumCobrado
      cte.sumSaldo = sumSaldo
    });

  }

  fnGetLinea() {
    let obj = {
      strAccion: 'getIndicadores_Familia',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.objLinea = result[0]


        }, error => {
          var error = <any>error; console.log(error);
        });
  }

  onGiroChange(event: any, tipo: string) {
    let clasificacion = event.target.value.split(',')[1];
    if (tipo === 'presencia') {
      this.strClasificacion = clasificacion + this.strClasificacion.substring(1);
    } else if (tipo === 'sucursales') {
      this.strClasificacion = this.strClasificacion.charAt(0) + clasificacion + this.strClasificacion.substring(2);
    } else {
      this.strClasificacion = this.strClasificacion.substring(0, 2) + clasificacion;
    }
    this.objInfo.strClasificacion
  }

  fngetGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayGiros = result
          this.fngetPresencia()
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fngetPresencia() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getPresencia',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayPresencia = result

          this.fngetUbicaciones()

        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fngetUbicaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getUbicaciones',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayUbicaciones = result

          this.fngetRangoEmpleados();

        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fngetRangoEmpleados() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getRangoEmpleados',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayTamanoEmpresa = result
          this.fnGetInfo();

        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  cambiarFecha(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaEstimadaCierre = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
    this.strFechaEstimadaCierreInput = fecha[0] + '-' + fecha[1] + '-' + fecha[2]
  }

  toggleCotizacion(cotizacion: any) {
    this.arrayTipoCotizacion.forEach(e => {
      if (cotizacion == e.strTipoCotizacion) {
        if (e.Seleccionado) {
          e.Seleccionado = false;
        } else {
          e.Seleccionado = true;
        }
      }
    });
  }

  SubirAnexos() {
    if (this.ListaAnexos.length > 0) {
      this._serviciosService.makeFileRequest('https://www.ecodeli.com/EvidenciaAnexo_Leads.php', [], this.ListaAnexos).then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Correcto...',
          text: 'Anexos Guardados',
        });
        //this.fnInsertLead()
        this.fnUpdateLead_Nuevo()
        this.fnGetInfo()
      }, (error) => {
        console.log(error);
      });
    } else {
      //this.fnInsertLead()
      this.fnUpdateLead_Nuevo()
    }

  }



  fnUpdateLead_Nuevo() {
    console.log(this.arrayAnexosUpdate)
    this.strTipoCotizacion = ''
    this.formLead.value.strNombreEmpresa = (this.strNombreEmpresa) ? this.strNombreEmpresa : ''
    this.formLead.value.strNombreCorto = (this.strNombreCorto) ? this.strNombreCorto : ''
    let tipoCotizacion = this.arrayTipoCotizacion.filter((b: any) => b.Seleccionado == true)
    if (tipoCotizacion.length > 0) {
      tipoCotizacion.forEach(element => {
        this.strTipoCotizacion += (element.strTipoCotizacion) + ','
      });
    }

    this.strPotencializador = ''
    let poten = this.arrayPotenciador.filter((b: any) => b.Seleccionado == true)
    if (poten.length > 0) {
      poten.forEach((element: any) => {
        this.strPotencializador += (element.strLinea) + ','
      });
    }

    this.arrayCarpetas.forEach((b: any) => {
      if (b.xmlDocumentos.length > 0) {
        b.xmlDocumentos.forEach((c: any) => {
          this.arrayAnexosUpdate.push(c)
        })

      }
    })

    let envi = {
      strAccion: 'updateLeads',
      intId_segunda: this.formLead.value.intId_segunda,
      intId_tercera: this.formLead.value.intId_tercera,
      intid_Ubicacion: (this.intIDUbicacion),
      strNombreCorto: this.formLead.value.strNombreCorto,
      intID_Familia: this.formLead.value.intID_Familia,
      strCodigoPostal: this.formLead.value.strCodigoPostal,
      strEstado: this.formLead.value.strEstado,
      strDelegacion: this.formLead.value.strDelegacion,
      strColonia: this.formLead.value.strColonia,
      intId_Contacto: this.intId_Contacto,
      strContacto_Nombre: this.arrayContactosDetalle[0].strNombreContacto,
      strContacto_Telefono: this.arrayContactosDetalle[0].strContactoTelefono,
      strContacto_Puesto: this.arrayContactosDetalle[0].strContactoPuesto,
      strContacto_Email: this.arrayContactosDetalle[0].strContactoEmail,
      intIdLeads: this.intID_Leads,
      strNombreEmpresa: this.formLead.value.strNombreEmpresa,
      intId_Giro: this.formLead.value.intId_Giro,
      intId_subGiro: this.formLead.value.intId_subGiro,
      strSector: this.formLead.value.strSector,
      strRazon_Social: this.strRazonSocial,
      strRFC: this.strRFC,
      strMedio_NosConocio: this.strMedioConocio,
      strAgente: this.objInfo.strAgente,
      dblMonto: this.dblImporte,
      strFechaCierre_Estimada: this.strFechaEstimadaCierre,
      intId_Direccion: this.intId_Direccion,
      strFase: this.strFase,
      strtipoCotizacion: this.strTipoCotizacion,
      strFechaInicioServicio: this.fechaInicioServicio,
      strRecomendacion: this.strRecomendacion,
      intid_Cliente: this.intid_Cliente,
      intIDPresencia: this.formLead.value.intIDPresencia,
      xmlAnexos: this.arrayAnexosUpdate,
      strFamilia: this.strFamilia,
      strPotencializador: this.strPotencializador,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(envi, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Actualizado",
              showConfirmButton: false,
              timer: 1500
            });
            this.arrayAnexosUpdate = []
            this.fnGetInfo();
            this.fnGetAnexos();
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Actualizado",
              showConfirmButton: false,
              timer: 1500
            });
            this.arrayAnexosUpdate = [];
          }
          this.strTipoCotizacion = '';
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  agregarAnexo(event: any, nombreCarpeta: any) {
    const fecha = new Date();
    const archivo: File = event.target.files[0];

    const tipo = archivo.type.split('/');
    if (tipo[1] == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      tipo[1] = "xlsx";
    }
    const nombreArchivo = this.intID_Leads + '-' + this.strTipoAnexo + ' ' + fecha.getFullYear() + '-' + fecha.getMonth() + '-' + fecha.getDay() + ' ' + fecha.getHours() + ':' + fecha.getMinutes() + ':' + fecha.getSeconds() + '.' + tipo[1];
    const rutaArchivo = 'EvidenciaLeads/' + nombreCarpeta + '/' + nombreArchivo;

    const nuevoArchivo = new File([archivo], nombreArchivo, { type: archivo.type });
    this.ListaAnexos.push(nuevoArchivo);

    this.arrayCarpetas.map((b: any) => {
      if (b.nombreCarpeta == nombreCarpeta) {
        let obj = {
          strTipo: tipo[1],
          strUrl: rutaArchivo,
          strTipoDocumento: this.strTipoAnexo,
          strNombre: nombreArchivo
        }
        b.xmlDocumentos.push(obj)
      }
    })
    console.log(this.arrayCarpetas)

    /*  this.arrayAnexosUpdate.push({
       strTipo: tipo[1],
       strUrl: rutaArchivo,
       strTipoDocumento: this.strTipoAnexo,
       strNombre: nombreArchivo
     }); */

  }

  fnEliminarAnexo(ind: number) {
    this.arrayAnexosUpdate.splice(ind, 1);
    this.ListaAnexos.splice(ind, 1);
  }
  // funciones subir excel FAMILIA
  handleFileInput(event: any) {
    const fileInput = event.target as HTMLInputElement;
    const fileList = fileInput.files;

    //this.nameArchivo = fileList[0].name;

    this.Readexcel(fileList[0]);
  }

  Readexcel(file: File) {
    //this.spinner = true;
    this.excelData = []
    this.excelDataAux = []
    let filereader = new FileReader();
    filereader.readAsBinaryString(file);
    filereader.onload = (e) => {
      try {
        let arrayErrores: any = []
        var workBook = XLSX.read(filereader.result, { type: 'binary' });
        var sheetNames = workBook.SheetNames;
        this.excelDataAux = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]]);
        let todosTienenCampos: any

        todosTienenCampos = this.excelDataAux.every(item =>
          item.hasOwnProperty('CODIGO_POSTAL') &&
          item.hasOwnProperty('RAZON_SOCIAL') &&
          item.hasOwnProperty('RFC') &&
          item.hasOwnProperty('CONTACTO_NOMBRE')
        );


        if (todosTienenCampos) {
          this.excelDataAux.forEach((e: any) => {

            if (e.CODIGO_POSTAL.toString().length != 5) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Registros con codigo postal invalido, Favor de agregarlo y adjuntar de nuevo el archivo excel!",
              });
              arrayErrores.push(e.CODIGO_POSTAL)
              this.excelDataAux = []
            } else if (!this.contieneSoloNumeros(e.CODIGO_POSTAL)) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Registros con codigo postal invalido, Favor de revisarlo y adjuntar de nuevo el archivo excel!",
              });
              arrayErrores.push(e.CODIGO_POSTAL)
              this.excelDataAux = []
            }
            else { }
          });

          if (arrayErrores.length == 0) {
            const xmlCP = this.excelDataAux.map((cp: any) => {
              return { strCodigoPostal: cp.CODIGO_POSTAL };
            });

            this.fnValidarCP(xmlCP)

          } else {
            this.excelDataAux = []
            console.log('no se puede hacer esta transaccion')
          }
        } else {
          try {
            this.excelDataAux = []
            this.excelData = []

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Campos Incompletos!, Revisa que todos los campos esten con información",
            });
            const fileInput = this.fileInput2.nativeElement as HTMLInputElement;
            fileInput.value = ''; // Esto debería resetear el input file

            // Forzar el cambio de valor para disparar el evento 'change'
            fileInput.dispatchEvent(new Event('input', { bubbles: true }));
            fileInput.dispatchEvent(new Event('change', { bubbles: true }));
          } catch (error) {
            console.log('error')
          }


        }

      } catch (error) {

        this.excelDataAux = [];
        //this.spinner = false;
        Swal.fire({ icon: 'error', title: 'Error', text: 'El archivo seleccionado no es un archivo valido, descarga el layout y vuelve a intentarlo.', });
      }
    }
  }

  fnValidarCP(xmlCP: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setValidaCP',
      xmlAnexos: xmlCP,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });

            this.excelData = this.excelDataAux
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            /*Swal.fire({
              icon: "error",
              title: "Oops...",
              text: 'Error al validar documento',
            });*/
            this.excelData = []
          }
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  contieneSoloNumeros(cadena: string): boolean {
    // Iterar sobre cada carácter de la cadena
    for (let i = 0; i < cadena.length; i++) {
      // Verificar si el carácter actual no es un dígito numérico
      if (!this.esDigitoNumerico(cadena[i])) {
        return false; // Se encontró un carácter que no es un número
      }
    }
    return true; // Todos los caracteres son numéricos
  }

  private esDigitoNumerico(caracter: string): boolean {
    return /^[0-9]$/.test(caracter); // Utiliza una expresión regular para verificar si el carácter es un número del 0 al 9
  }

  fnDescargarFormatoFamilia() {
    const rutaArchivo = 'assets/FormatoSucursalesFamilia.xlsx'
    const link = document.createElement('a');
    link.href = rutaArchivo;
    link.download = 'FormatoSucursalesFamilias.xlsx';
    link.click();
  }

  handleDrop(event: DragEvent) {

    event.preventDefault();
    const fileList = event.dataTransfer.files;

    if (fileList.length > 0) {
      //this.capturador = false;

      // this.nameArchivo= fileList[0].name;
      this.Readexcel(fileList[0]);
    } else {
      // this.spinner = false;
    }
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    // Agregar estilos para resaltar el área de soltar
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    // Eliminar estilos de resaltado cuando se sale del área de soltar
  }

  fnInsertSucursales(val: number = 0) {
    let objEnv = {
      strAccion: 'setLeads_Cte_Suc',
      xmlSucursales: this.excelData,
      strAgente: this.objInfo.strAgente,
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });


            this.fnGetListadoSucursal();
            if (val == 1) {
              this.btnCerrarMdlSuc.nativeElement.click()
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje
            });
          }
        }, error => {
          var error = <any>error; console.log(error);
        }
      );
  }

  fnGetListadoSucursal() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'GetListado_Suc_Familia',
      intID_leads: this.intID_Leads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arrayListadoSucursales = result
          let razonesSociales: any = []
          this.arrayListadoSucursales.forEach((element: any) => {

            let nombre = element.strNombreEmpresa_Cliente
            if (razonesSociales.includes(nombre)) {
            } else {
              razonesSociales.push(nombre)
            }
          });

          let arrayRazon: any = []
          razonesSociales.forEach(element => {
            let filtro = this.arrayListadoSucursales.filter((b: any) => b.strNombreEmpresa_Cliente == element)
            arrayRazon.push(filtro)
          });

        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }


  fnGetDetalleEdoCta() {
    this.spinerEdoCuenta = true;
    let obj = {
      strAccion: 'getEstado_Cta_Fam',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayEdoCuenta = result

          this.TableCtaCte = new MatTableDataSource(result);
          this.fnTableCtaCte()
          this.fnDividirCtaCte();
          this.spinerEdoCuenta = false;
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerEdoCuenta = false;
        });
  }

  fnDividirCtaCte() {
    const flujoCte = new Set<string>();
    this.arrayEdoCtaDist = this.arrayEdoCuenta.filter(obj => {
      if (!flujoCte.has(obj.strCliente)) {
        flujoCte.add(obj.strCliente);
        return true;
      }
      return false;
    });
    this.fnSumarCtaCte();
  }

  fnSumarCtaCte() {
    const CopyArrayCte = JSON.parse(JSON.stringify(this.arrayEdoCuenta));
    let saldo = 0, a15 = 0, a30 = 0, a60 = 0, a90 = 0, a90mas = 0;
    this.arrayEdoCtaDist.forEach(cte => {
      saldo = 0; a15 = 0;
      a30 = 0; a60 = 0;
      a90 = 0; a90mas = 0;
      cte.arrayCteCuenta = [];
      CopyArrayCte.forEach(cliente => {
        if (cliente.strCliente == cte.strCliente) {
          saldo += cliente.dblSaldoCorriente;
          a15 += cliente.dblSaldo15;
          a30 += cliente.dblSaldo30;
          a60 += cliente.dblSaldo60;
          a90 += cliente.dblSaldo90;
          a90mas += cliente.dblSaldoA;
          cte.arrayCteCuenta.push(cliente);
        }
      });
      cte.totalSaldo = saldo;
      cte.totalMas15 = a15;
      cte.totalMas30 = a30;
      cte.totalMas60 = a60;
      cte.totalMas90 = a90;
      cte.total90Mas = a90mas;
      cte.seeCte = false;
    });

  }

  fnCambiarVistaCtaCte(objx: any) {
    this.arrayEdoCtaDist.forEach(el => {
      if (el.strCliente == objx.strCliente) {
        if (el.seeCte == false) {
          el.seeCte = true
        } else {
          el.seeCte = false
        }
      } else {
        el.seeCte = false
      }
    });
  }
  fnGetHistoricoVentaLineas() {
    this.spinerBool = true;
    let obj = {
      strAccion: 'getReporteLineas_fam',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion,
      intEjercicio: this.intEjercicio
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arrayLineasHistVentaNew = result
          this.fnHistoricoPorLinea(this.arrayLineasHistVentaNew)
          this.spinerBool = false
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerBool = false
        });
  }

  fnHistoricoPorLinea(array: any) {
    let arrayProf = JSON.parse(JSON.stringify(array));
    let lineasUnicas = new Set<string>();
    this.arrayLineasHist = arrayProf.filter(obj => {
      if (!lineasUnicas.has(obj.strLinea)) {
        lineasUnicas.add(obj.strLinea);
        obj.arrayCte = []
        obj.seeLineas = false
        return true;
      }
      return false;
    });

    this.arrayLineasHist.sort((a, b) => {
      if (a.strLinea < b.strLinea) {
        return -1;
      } else if (a.strlinea > b.strlinea) {
        return 1;
      } else {
        return 0;
      }
    });
    this.fnAgregarCtesLineas(array);
  }

  fnAgregarCtesLineas(arrayOriginal: any) {

    let arrayProf = JSON.parse(JSON.stringify(arrayOriginal));
    this.arrayLineasHist.forEach(cte => {
      if (!cte.arrayCtees) {
        cte.arrayCtees = []
      }
      arrayProf.forEach(lin => {
        if (cte.strLinea == lin.strLinea) {
          cte.arrayCtees.push(lin)
        }
      });
      cte.arrayCte = cte.arrayCtees.reduce((acc, curr) => {
        // Verifica si el cliente ya está en el array basado en strCliente
        if (!acc.some(obj => obj.strCliente === curr.strCliente)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      cte.arrayCte.forEach(element => {
        element.seeArts = false
      });

      /*       cte.arrayLineas.forEach(lin => {
              this.arrayLineasHist.forEach(linea => {
                if (lin.strLinea == linea.strLinea) {
                  linea.arrayCte.push({ strNombreEmpresa: cte.strNombreEmpresa, seeArts: false, strCliente: cte.strCliente, strNombreCliente: cte.strNombreCliente, arrayArts: lin.arrayArticulos })
                }
              });
            });
       */

    });

    this.fnAgregarArrayArts()
  }

  fnAgregarArrayArts() {


    let arraycopyRigth = JSON.parse(JSON.stringify(this.arrayLineasHistVentaNew));

    this.arrayLineasHist.forEach(cte => {
      cte.arrayCte.forEach(art => {
        if (!art.arrayArts) {
          art.arrayArts = [];
        }
        art.arrayArts = arraycopyRigth.filter(o => o.strLinea == art.strLinea && o.strCliente == art.strCliente);

      });
    });
    this.fnSumasPorCliente()

    /*   cte.arrayCte.forEach(arts => {
        if(!arts.arrayArts){
          arts.arrayArts = [];
        }  
        arts.arrayArts = cte.arrayCtees.filter(o => o.strCliente == arts.strCliente && cte.strLinea == o.strLinea )
        arts.arrayArts.forEach(ele => {
          ele.seeArts = false
        });
    });
  
    */
  }
  fnSumasPorCliente() {
    let sumEnero = 0, sumFebrero = 0, sumMarzo = 0, sumAbril = 0,
      sumMayo = 0, sumJunio = 0, sumJulio = 0, sumAgosto = 0,
      sumSeptiembre = 0, sumOctubre = 0, sumNoviembre = 0, sumDiciembre = 0;

    this.arrayLineasHist.forEach(cte => {
      cte.arrayCte.forEach(lin => {
        sumEnero = 0; sumFebrero = 0; sumMarzo = 0;
        sumAbril = 0; sumMayo = 0; sumJunio = 0;
        sumJulio = 0; sumAgosto = 0; sumSeptiembre = 0;
        sumOctubre = 0; sumNoviembre = 0; sumDiciembre = 0;
        lin.arrayArts.forEach(art => {
          sumEnero += art.dblEnero;
          sumFebrero += art.dblFebrero;
          sumMarzo += art.dblMarzo;
          sumAbril += art.dblAbril;
          sumMayo += art.dblMayo;
          sumJunio += art.dblJunio;
          sumJulio += art.dblJulio;
          sumAgosto += art.dblAgosto;
          sumSeptiembre += art.dblSeptiembre;
          sumOctubre += art.dblOctubre;
          sumNoviembre += art.dblNoviembre;
          sumDiciembre += art.dblDiciembre;
          art.total = art.dblEnero + art.dblFebrero + art.dblMarzo + art.dblAbril + art.dblMayo + art.dblJunio + art.dblJulio + art.dblAgosto + art.dblSeptiembre + art.dblOctubre + art.dblNoviembre + art.dblDiciembre
        });
        lin.sumEnero = sumEnero
        lin.sumFebrero = sumFebrero
        lin.sumMarzo = sumMarzo
        lin.sumAbril = sumAbril
        lin.sumMayo = sumMayo
        lin.sumJunio = sumJunio
        lin.sumJulio = sumJulio
        lin.sumAgosto = sumAgosto
        lin.sumSeptiembre = sumSeptiembre
        lin.sumOctubre = sumOctubre
        lin.sumNoviembre = sumNoviembre
        lin.sumDiciembre = sumDiciembre
        lin.total = lin.sumEnero + lin.sumFebrero + lin.sumMarzo + lin.sumAbril + lin.sumMayo + lin.sumJunio + lin.sumJulio + lin.sumAgosto + lin.sumSeptiembre + lin.sumOctubre + lin.sumNoviembre + lin.sumDiciembre
      });
    });

    this.fnHistoricoVentaLineasSumas()
  }

  fnHistoricoVentaLineasSumas() {
    let sumEnero = 0, sumFebrero = 0, sumMarzo = 0, sumAbril = 0,
      sumMayo = 0, sumJunio = 0, sumJulio = 0, sumAgosto = 0,
      sumSeptiembre = 0, sumOctubre = 0, sumNoviembre = 0, sumDiciembre = 0;

    this.arrayLineasHist.forEach(lin => {
      sumEnero = 0; sumFebrero = 0; sumMarzo = 0;
      sumAbril = 0; sumMayo = 0; sumJunio = 0;
      sumJulio = 0; sumAgosto = 0; sumSeptiembre = 0;
      sumOctubre = 0; sumNoviembre = 0; sumDiciembre = 0;
      lin.arrayCte.forEach(art => {
        sumEnero += art.sumEnero;
        sumFebrero += art.sumFebrero;
        sumMarzo += art.sumMarzo;
        sumAbril += art.sumAbril;
        sumMayo += art.sumMayo;
        sumJunio += art.sumJunio;
        sumJulio += art.sumJulio;
        sumAgosto += art.sumAgosto;
        sumSeptiembre += art.sumSeptiembre;
        sumOctubre += art.sumOctubre;
        sumNoviembre += art.sumNoviembre;
        sumDiciembre += art.sumDiciembre;
      });
      lin.sumEnero = sumEnero
      lin.sumFebrero = sumFebrero
      lin.sumMarzo = sumMarzo
      lin.sumAbril = sumAbril
      lin.sumMayo = sumMayo
      lin.sumJunio = sumJunio
      lin.sumJulio = sumJulio
      lin.sumAgosto = sumAgosto
      lin.sumSeptiembre = sumSeptiembre
      lin.sumOctubre = sumOctubre
      lin.sumNoviembre = sumNoviembre
      lin.sumDiciembre = sumDiciembre
      lin.total = lin.sumEnero + lin.sumFebrero + lin.sumMarzo + lin.sumAbril + lin.sumMayo + lin.sumJunio + lin.sumJulio + lin.sumAgosto + lin.sumSeptiembre + lin.sumOctubre + lin.sumNoviembre + lin.sumDiciembre
    });



    this.objHistLinea.dblEnero = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumEnero ? objetoActual.sumEnero : 0);
    }, 0);

    this.objHistLinea.dblFebrero = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumFebrero ? objetoActual.sumFebrero : 0);
    }, 0);

    this.objHistLinea.dblMarzo = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumMarzo ? objetoActual.sumMarzo : 0);
    }, 0);

    this.objHistLinea.dblAbril = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumAbril ? objetoActual.sumAbril : 0);
    }, 0);

    this.objHistLinea.dblMayo = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumMayo ? objetoActual.sumMayo : 0);
    }, 0);

    this.objHistLinea.dblJunio = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumJunio ? objetoActual.sumJunio : 0);
    }, 0);

    this.objHistLinea.dblJulio = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumJulio ? objetoActual.sumJulio : 0);
    }, 0);

    this.objHistLinea.dblAgosto = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumAgosto ? objetoActual.sumAgosto : 0);
    }, 0);

    this.objHistLinea.dblSeptiembre = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumSeptiembre ? objetoActual.sumSeptiembre : 0);
    }, 0);

    this.objHistLinea.dblOctubre = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumOctubre ? objetoActual.sumOctubre : 0);
    }, 0);

    this.objHistLinea.dblNoviembre = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumNoviembre ? objetoActual.sumNoviembre : 0);
    }, 0);

    this.objHistLinea.dblDiciembre = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumDiciembre ? objetoActual.sumDiciembre : 0);
    }, 0);

    this.objHistLinea.dblTotal = this.arrayLineasHist.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.total ? objetoActual.total : 0);
    }, 0);
  }

  fnVerCtexLinea(obj: any) {
    this.arrayLineasHist.forEach(el => {
      if (el.strLinea == obj.strLinea) {
        if (el.seeLineas == false) {
          el.seeLineas = true
        } else {
          el.seeLineas = false
        }
      } else {
        el.seeLineas = false
      }
    });
  }

  fnCambiarVistaArtsCte(obj: any, ob: any) {
    this.arrayLineasHist.forEach(el => {
      if (el.strLinea == ob.strLinea) {
        el.arrayCte.forEach(el => {
          if (el.strCliente == obj.strCliente) {
            if (el.seeArts == false) {
              el.seeArts = true
            } else {
              el.seeArts = false
            }
          } else {
            el.seeArts = false
          }
        });
      }
    });
  }

  onKeyDown(event: KeyboardEvent) {
    // Código ASCII de la tecla Delete y Retroceso     const DELETE_KEY = 46;     const BACKSPACE_KEY = 8;
    const ENTER_KEY = 13;
    // Verificar si se presionó la tecla Delete o Retroceso
    if (event.keyCode === ENTER_KEY) {
      this.fnGetDatosFiscales(this.objSucManual.CODIGO_POSTAL);
    }
  }

  blurevent() {
    if (this.objSucManual.CODIGO_POSTAL == 5) {
      this.fnGetDatosFiscales(this.objSucManual.CODIGO_POSTAL);
    }
  }

  fnGetDatosFiscales(cp) {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: cp
    })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result.length > 0) {
            if (this.objSucManual.CODIGO_POSTAL) {
              this.arrayColonias = result;
            } else {
              this.arrayColonias = result;
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: '¡Codigo Postal Incorrecto!',
            });
            this.arrayColonias = []
          }

        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  validarNumeros(event: KeyboardEvent) {
    const codigoTecla = event.keyCode;
    // Permite los números (0-9) y la tecla de retroceso (Backspace)
    if (codigoTecla < 48 || codigoTecla > 57) {
      event.preventDefault();
      this.arrayColonias = [];
    } else {
      let cp = this.objSucManual.CODIGO_POSTAL + event.key
      if (cp.length == 5) {


        this.fnGetDatosFiscales(this.objSucManual.CODIGO_POSTAL + event.key);
      } else {
        this.arrayColonias = [];
      }
    }

  }

  fnGuardarSuc() {
    this.excelData = []
    this.excelData.push(this.objSucManual)
    this.fnInsertSucursales(1);
  }

  fnlimpiarmdlSuc() {
    this.objSucManual.RAZON_SOCIAL = ""
    this.objSucManual.RFC = ""
    this.objSucManual.NOMBRE_SUCURSAL = ""
    this.objSucManual.DIRECCION = ""
    this.objSucManual.DIRECCION_NUMERO = ""
    this.objSucManual.CODIGO_POSTAL = ""
    this.objSucManual.COLONIA = ""
    this.objSucManual.CONTACTO_NOMBRE = ""
    this.objSucManual.CONTACTO_PUESTO = ""
    this.objSucManual.CONTACTO_EMAIL = ""
    this.objSucManual.CONTACTO_TELEFONO = ""
    this.fnformSucursal();
  }

  fnNumberPregunta(event: any, respuestas: any) {
    let filtro = Array.from(new Set(respuestas.preguntas
      .filter((b: any) => b.depende_pregunta_id > 0)
      .map((b: any) => b.depende_pregunta_id)));

    if (filtro.length > 0) {
      filtro.forEach((element: any) => {
        let depende = respuestas.preguntas.find((b: any) => b.intID_Formulario == element);
        let resp = respuestas.preguntas.filter((b: any) => b.depende_pregunta_id == element);

        if (depende.strRespuesta != resp[0].depende_pregunta_text) {
          resp.forEach((b: any) => {
            b.strRespuesta = 'N/A';
            b.disabled = true;
          });
        } else {
          resp.forEach((b: any) => {
            if (b.strRespuesta == 'N/A') {
              b.strRespuesta = '';
            }
            b.disabled = false;
          });
        }
      });
    }

    respuestas.intTotalContestadas = respuestas.preguntas.reduce((total: number, element: any) => {
      return total + (element.strRespuesta !== '' ? 1 : 0);
    }, 0);

    if ((respuestas.intTotalContestadas == respuestas.intTotalPreguntas) && this.intTotalPreguntas > this.intTotalContestadas) {
      this.intTotalContestadas += respuestas.intTotalContestadas;
    }

  }

  fnSETFORM_RESPUESTA() {

    let xmlRespuestas: any = []
    this.arraySecciones.forEach((element: any) => {

      element.preguntas.forEach((pregunta: any) => {
        let data = {
          intID_Formulario: pregunta.intID_Formulario,
          strRespuesta: pregunta.strRespuesta
        }

        xmlRespuestas.push(data)
      });
    });


    let objEnv = {
      strAccion: 'SETFORM_RESPUESTA',
      xmlAnexos: xmlRespuestas,
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.fnUpdateLead_Nuevo()
            this.fnGetFormulario()
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje
            });
          }
        }, error => {
          var error = <any>error; console.log(error);
        }
      );
  }

  fnReturnMes(num: number): string {
    let Mes = ""
    Mes = num == 1 ? "Enero" : num == 2 ? "Febrero" : num == 3 ? "Marzo" : num == 4 ? "Abril" :
      num == 5 ? "Mayo" : num == 6 ? "Junio" : num == 7 ? "Julio" : num == 8 ? "Agosto" :
        num == 9 ? "Septiembre" : num == 10 ? "Octubre" : num == 11 ? "Noviembre" : "Diciembre"
    return Mes
  }

  fngetHistVtaLineas() {
    this.spinnerHistVta = true;
    this.arrayHistLineas = [];
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getHistLinea_Cte',
      strFamilia: this.strFamilia,
      intEjercicio: this.intEjercicio
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          const arrayCopiaPro = JSON.parse(JSON.stringify(result));
          var ejercicio = arrayCopiaPro.reduce((acc, current) => {
            if (!acc.some(item => item.strEjercicio === current.strEjercicio)) {
              acc.push({ strEjercicio: current.strEjercicio });
            }
            return acc;
          }, []);
          arrayCopiaPro.forEach(element => {
            let valida = this.arrayHistLineas.find(o => o.strCliente == element.strCliente)
            if (!valida) {
              var arrayPeriodo = []
              ejercicio.forEach(ejer => {
                ejer.seePer = false
                arrayPeriodo = arrayCopiaPro.filter(o => o.strCliente == element.strCliente && o.strEjercicio == ejer.strEjercicio)
                ejer.arrayPeriodoTodos = arrayPeriodo;
                ejer.arrayPeriodo = []
                for (let i = 1; i < 13; i++) {
                  ejer.arrayPeriodo.push(
                    {
                      strEjercicio: ejer.strEjercicio, strPeriodoNombre: this.fnReturnMes(i), strPeriodo: i,
                      dblAccesorios: 0, dblAccesoriosMargen: 0, dblAccesoriosMargenx: 0, dblKimberly: 0
                      , dblKimberlyMargen: 0, dblKimberlyMargenx: 0, dblRubbermaid: 0, dblRubbermaidMargen: 0
                      , dblRubbermaidMargenx: 0, dblBlueTissue: 0, dblBlueTissueMargen: 0, dblBlueTissueMargenx: 0, dblQuimicos: 0
                      , dblQuimicosMargen: 0, dblQuimicosMargenx: 0, dblDespachadores: 0, dblDespachadoresMargen: 0, dblDespachadoresMargenx: 0
                      , dblTork: 0, dblTorkMargen: 0, dblTorkMargenx: 0, dblXibu: 0, dblXibuMargen: 0, dblXibuMargenx: 0
                      , dblFoaom: 0, dblFoaomMargen: 0, dblFoaomMargenx: 0, dblDove: 0,
                      dblDoveMargen: 0, dblDoveMargenx: 0, dblTapetes: 0, dblTapetesMargen: 0, dblTapetesMargenx: 0, total: 0
                    }
                  )
                }
              });
              let ejercicio2 = JSON.parse(JSON.stringify(ejercicio));
              this.arrayHistLineas.push({ strCliente: element.strCliente, strNombre: element.strNombre, arrayEjercicio: ejercicio2, seeCte: false })
            }
          });
          this.fnAgregarLineasAclientes()
        }, (error) => {
          console.log(error.message);
          this.spinnerHistVta = false
        })

  }

  fnAgregarLineasAclientes() {
    this.arrayHistLineas = [];
    this.arrayHistLineas.forEach(cte => {
      cte.arrayEjercicio.forEach(ejer => {
        ejer.seePer == true
        ejer.arrayPeriodo.forEach(o => {
          ejer.arrayPeriodoTodos.forEach(x => {
            if (o.strPeriodo === x.strPeriodo) {
              if (x.strLinea == 'ACCESORIOS') {
                o.dblAccesorios = x.dblImporte;
                o.dblAccesoriosMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblAccesoriosMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'KIMBERLY') {
                o.dblKimberly = x.dblImporte;
                o.dblKimberlyMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblKimberlyMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'QUIMICOS') {
                o.dblQuimicos = x.dblImporte;
                o.dblQuimicosMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblQuimicosMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'RUBBERMAID') {
                o.dblRubbermaid = x.dblImporte;
                o.dblRubbermaidMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblRubbermaidMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'BLUE TISSUE') {
                o.dblBlueTissue = x.dblImporte;
                o.dblBlueTissueMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblBlueTissueMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'DESPACHADORES ECODELI') {
                o.dblDespachadores = x.dblImporte;
                o.dblDespachadoresMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblDespachadoresMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'TORK') {
                o.dblTork = x.dblImporte;
                o.dblTorkMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblTorkMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'FOAOM') {
                o.dblFoaom = x.dblImporte;
                o.dblFoaomMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblFoaomMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'XIBU') {
                o.dblXibu = x.dblImporte;
                o.dblXibuMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblXibuMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'TAPETES') {
                o.dblTapetes = x.dblImporte;
                o.dblTapetesMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblTapetesMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              } else if (x.strLinea == 'DOVE') {
                o.dblDove = x.dblImporte;
                o.dblDoveMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
                o.dblDoveMargenx = x.dblImporte > 0 ? x.dblMargen : 0;
              }
            }
          }
          );
          o.total = (o.dblAccesorios ? o.dblAccesorios : 0) + (o.dblKimberly ? o.dblKimberly : 0) + (o.dblQuimicos ? o.dblQuimicos : 0) +
            (o.dblRubbermaid ? o.dblRubbermaid : 0) + (o.dblBlueTissue ? o.dblBlueTissue : 0) + (o.dblDespachadores ? o.dblDespachadores : 0) +
            (o.dblTork ? o.dblTork : 0) + (o.dblFoaom ? o.dblFoaom : 0) + (o.dblXibu ? o.dblXibu : 0) + (o.dblDove ? o.dblDove : 0) + (o.dblTapetes ? o.dblTapetes : 0);
        });

        ejer.totalAcc = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblAccesorios
        }, 0);

        ejer.totalKimb = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblKimberly
        }, 0);

        ejer.totalRubberMa = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblRubbermaid
        }, 0);

        ejer.totalBlueTisue = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblBlueTissue
        }, 0);

        ejer.totalQuimicos = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblQuimicos
        }, 0);

        ejer.totalDespa = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblDespachadores
        }, 0);

        ejer.totalTork = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblTork
        }, 0);

        ejer.totalXibu = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblXibu
        }, 0);

        ejer.totalFoaom = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblFoaom
        }, 0);

        ejer.totalTapetes = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + (objetoActual.dblTapetes ? objetoActual.dblTapetes : 0)
        }, 0);


        ejer.totalDove = ejer.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + (objetoActual.dblDove ? objetoActual.dblDove : 0);
        }, 0);


        ejer.totalImporte = ejer.totalAcc + ejer.totalKimb + ejer.totalRubberMa + ejer.totalBlueTisue + ejer.totalQuimicos
          + ejer.totalDespa + ejer.totalTork + ejer.totalXibu + ejer.totalFoaom + ejer.totalTapetes + ejer.totalDove
      });
    });
    this.fnSumxAnio()
  }



  fnSumxAnio() {

    this.arrayHistLineas.forEach(cte => {

      cte.totalAcc = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalAcc
      }, 0);

      cte.totalKimb = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalKimb
      }, 0);

      cte.totalRubberMa = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalRubberMa
      }, 0);

      cte.totalBlueTisue = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalBlueTisue
      }, 0);

      cte.totalQuimicos = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalQuimicos
      }, 0);

      cte.totalDespa = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalDespa
      }, 0);

      cte.totalTork = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalTork
      }, 0);

      cte.totalXibu = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalXibu
      }, 0);

      cte.totalFoaom = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.totalFoaom
      }, 0);

      cte.totalTapetes = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + (objetoActual.totalTapetes ? objetoActual.totalTapetes : 0)
      }, 0);


      cte.totalDove = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + (objetoActual.totalDove ? objetoActual.totalDove : 0);
      }, 0);

      cte.totalImporte = cte.totalAcc + cte.totalKimb + cte.totalRubberMa + cte.totalBlueTisue + cte.totalQuimicos
        + cte.totalDespa + cte.totalTork + cte.totalXibu + cte.totalFoaom + cte.totalTapetes + cte.totalDove
    });

    this.objHistMargen.Accesorios = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalAcc ? objetoActual.totalAcc : 0);
    }, 0);

    this.objHistMargen.Kimberly = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalKimb ? objetoActual.totalKimb : 0);
    }, 0);

    this.objHistMargen.Rubbermaid = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalRubberMa ? objetoActual.totalRubberMa : 0);
    }, 0);

    this.objHistMargen.BlueTissue = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalBlueTisue ? objetoActual.totalBlueTisue : 0);
    }, 0);

    this.objHistMargen.Quimicos = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalQuimicos ? objetoActual.totalQuimicos : 0);
    }, 0);

    this.objHistMargen.Despachadores = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalDespa ? objetoActual.totalDespa : 0);
    }, 0);

    this.objHistMargen.Tork = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalTork ? objetoActual.totalTork : 0);
    }, 0);

    this.objHistMargen.Xibu = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalXibu ? objetoActual.totalXibu : 0);
    }, 0);

    this.objHistMargen.Foaom = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalFoaom ? objetoActual.totalFoaom : 0);
    }, 0);

    this.objHistMargen.Dove = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalDove ? objetoActual.totalDove : 0);
    }, 0);

    this.objHistMargen.Tapetes = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalTapetes ? objetoActual.totalTapetes : 0);
    }, 0);

    this.objHistMargen.total = this.arrayHistLineas.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalImporte ? objetoActual.totalImporte : 0);
    }, 0);
    this.spinnerHistVta = false;
    this.fnSumMargenes()
  }
  fnSumMargenes() {

    this.arrayHistLineas.forEach(cte => {
      cte.arrayEjercicio.forEach(year => {

        year.dblAccesoriosMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblAccesoriosMargenx
        }, 0)
        year.dblAccesoriosMargen = year.totalAcc > 0 ? (year.dblAccesoriosMargenx / year.totalAcc) * 100 : 0;

        year.dblKimberlyMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblKimberlyMargenx
        }, 0)
        year.dblKimberlyMargen = year.totalKimb > 0 ? ((year.dblKimberlyMargenx / year.totalKimb) * 100) : 0;

        year.dblRubbermaidMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblRubbermaidMargenx
        }, 0)
        year.dblRubbermaidMargen = year.totalRubberMa > 0 ? ((year.dblRubbermaidMargenx / year.totalRubberMa) * 100) : 0;

        year.dblBlueTissueMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblBlueTissueMargenx
        }, 0);
        year.dblBlueTissueMargen = year.totalBlueTisue > 0 ? ((year.dblBlueTissueMargenx / year.totalBlueTisue) * 100) : 0;

        year.dblQuimicosMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblQuimicosMargenx
        }, 0)
        year.dblQuimicosMargen = year.totalQuimicos > 0 ? ((year.dblQuimicosMargenx / year.totalQuimicos) * 100) : 0;

        year.dblDespachadoresMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblDespachadoresMargenx
        }, 0)
        year.dblDespachadoresMargen = year.totalDespa > 0 ? (year.dblDespachadoresMargenx / year.totalDespa) * 100 : 0;

        year.dblTorkMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblTorkMargenx
        }, 0)
        year.dblTorkMargen = year.totalTork > 0 ? ((year.dblTorkMargenx / year.totalTork) * 100) : 0;

        year.dblXibuMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblXibuMargenx
        }, 0)
        year.dblXibuMargen = year.totalXibu > 0 ? ((year.dblXibuMargenx / year.totalXibu) * 100) : 0;

        year.dblFoaomMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + objetoActual.dblFoaomMargenx
        }, 0)
        year.dblFoaomMargen = year.totalFoaom > 0 ? ((year.dblFoaomMargenx / year.totalFoaom) * 100) : 0;

        year.dblTapetesMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + (objetoActual.dblTapetesMargenx ? objetoActual.dblTapetesMargenx : 0)
        }, 0)
        year.dblTapetesMargen = year.totalTapetes > 0 ? ((year.dblTapetesMargenx / year.totalTapetes) * 100) : 0;

        year.dblDoveMargenx = year.arrayPeriodo.reduce((acumulador, objetoActual) => {
          return acumulador + (objetoActual.dblDoveMargenx ? objetoActual.dblDoveMargenx : 0);
        }, 0)
        year.dblDoveMargen = year.totalDove > 0 ? ((year.dblDoveMargenx / year.totalDove) * 100) : 0;

      });

      cte.dblAccesoriosMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblAccesoriosMargenx
      }, 0)
      cte.dblAccesoriosMargen = cte.totalAcc > 0 ? (cte.dblAccesoriosMargenx / cte.totalAcc) * 100 : 0;

      cte.dblKimberlyMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblKimberlyMargenx
      }, 0)
      cte.dblKimberlyMargen = cte.totalKimb > 0 ? ((cte.dblKimberlyMargenx / cte.totalKimb) * 100) : 0;

      cte.dblRubbermaidMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblRubbermaidMargenx
      }, 0)
      cte.dblRubbermaidMargen = cte.totalRubberMa > 0 ? ((cte.dblRubbermaidMargenx / cte.totalRubberMa) * 100) : 0;

      cte.dblBlueTissueMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblBlueTissueMargenx
      }, 0);
      cte.dblBlueTissueMargen = cte.totalBlueTisue > 0 ? ((cte.dblBlueTissueMargenx / cte.totalBlueTisue) * 100) : 0;

      cte.dblQuimicosMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblQuimicosMargenx
      }, 0)
      cte.dblQuimicosMargen = cte.totalQuimicos > 0 ? ((cte.dblQuimicosMargenx / cte.totalQuimicos) * 100) : 0;

      cte.dblDespachadoresMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblDespachadoresMargenx
      }, 0)
      cte.dblDespachadoresMargen = cte.totalDespa > 0 ? (cte.dblDespachadoresMargenx / cte.totalDespa) * 100 : 0;

      cte.dblTorkMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblTorkMargenx
      }, 0)
      cte.dblTorkMargen = cte.totalTork > 0 ? ((cte.dblTorkMargenx / cte.totalTork) * 100) : 0;

      cte.dblXibuMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblXibuMargenx
      }, 0)
      cte.dblXibuMargen = cte.totalXibu > 0 ? ((cte.dblXibuMargenx / cte.totalXibu) * 100) : 0;

      cte.dblFoaomMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + objetoActual.dblFoaomMargenx
      }, 0)
      cte.dblFoaomMargen = cte.totalFoaom > 0 ? ((cte.dblFoaomMargenx / cte.totalFoaom) * 100) : 0;

      cte.dblTapetesMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + (objetoActual.dblTapetesMargenx ? objetoActual.dblTapetesMargenx : 0)
      }, 0)
      cte.dblTapetesMargen = cte.totalTapetes > 0 ? ((cte.dblTapetesMargenx / cte.totalTapetes) * 100) : 0;

      cte.dblDoveMargenx = cte.arrayEjercicio.reduce((acumulador, objetoActual) => {
        return acumulador + (objetoActual.dblDoveMargenx ? objetoActual.dblDoveMargenx : 0);
      }, 0)
      cte.dblDoveMargen = cte.totalDove > 0 ? ((cte.dblDoveMargenx / cte.totalDove) * 100) : 0;

    });
  }

  fnVerHistLineas(obj: any) {
    this.arrayHistLineas.forEach(el => {
      if (el.strCliente == obj.strCliente) {
        if (el.seeCte == false) {
          el.seeCte = true
        } else {
          el.seeCte = false
        }
      } else {
        el.seeCte = false
      }
    });
  }

  fnVerHistLineasPeriodo(obj: any, ejer: any) {
    this.arrayHistLineas.forEach(el => {
      if (obj.strCliente == el.strCliente) {
        el.arrayEjercicio.forEach(cte => {
          if (cte.strEjercicio == ejer.strEjercicio) {
            if (cte.seePer == false) {
              cte.seePer = true
            } else {
              cte.seePer = false
            }
          } else {
            cte.seePer = false
          }
        });
      }
    });
  }
  getDividedText(tex: string): string {
    const text = tex;
    const midIndex = Math.ceil(text.length / 2);
    const firstPart = text.substring(0, midIndex);
    const secondPart = text.substring(midIndex);
    return `${firstPart}<br>${secondPart}`;
  }

  fngetCompras_Favoritas() {
    let obj = {
      strAccion: 'getCompras_Favoritas',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.dataSourceFavoritos = new MatTableDataSource(result);
          this.fnTableArtsFavoritos()
        }, error => {
          var error = <any>error; console.log(error);
        });
  }

  fngetCompras_Favoritas_Detalle(articulo: any) {
    this.loaderDetalleFavoritos = true
    this.dataSourceFavoritosDetalle = new MatTableDataSource<data>([]);
    let obj = {
      strAccion: 'getCompras_Favoritas_Detalle',
      strFamilia: this.strFamilia,
      strArticulo: articulo,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.dataSourceFavoritosDetalle = new MatTableDataSource(result);
          this.loaderDetalleFavoritos = false

        }, error => {
          var error = <any>error; console.log(error);
        });
  }



  fngetMovimientos_pendientes_fam() {
    this.loadingPendientes = true
    let obj = {
      strAccion: 'getMovimientos_pendientes_fam',
      strConexion: this.strConexion,
      strFamilia: this.strFamilia
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          //console.log(result)
          if (result.length > 0) {
            this.arrayPedidos = result.filter((b: any) => b.strTipo == 'PEDIDO')
            this.intCantidadPedidos = this.arrayPedidos.length
            this.arrayRemisiones = result.filter((b: any) => b.strTipo == 'REMISIONES')
            this.intCantidadRemisiones = this.arrayRemisiones.length
            this.arrayVentasConsigna = result.filter((b: any) => b.strTipo == 'VENTA CONSIGNA')
            this.intCantidadVentasConsigna = this.arrayVentasConsigna.length
            this.arrayComodatos = result.filter((b: any) => b.strTipo == 'COMODATO')
            this.intCantidadComodato = this.arrayComodatos.length

            this.loadingPendientes = false
          }
        }, error => {

          console.error('Error al consumir la API:', error);
        });
  }

  fncreatepdf_2() {
    const Flujoce = this.headerFlujoCte_2.nativeElement;
    const options = {
      margin: [13, 8, 10, 8],
      filename: 'FlujoCliente.pdf',
      image: { type: 'JPEG', quality: 0.98 },
      html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
    };
    const content = this.el2.nativeElement;
    html2pdf()
      .from(content)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.text(Flujoce.innerText, 80, 8); // Ajusta las coordenadas según sea necesario
          pdf.setPage(i);
          pdf.setFontSize(9);
          pdf.text(i.toString(), 5, 205);
        }
        pdf.save("FlujoCliente.pdf");
        /*   if(this.flagProspecto){
            this.fncerrarmodal()
         } */
      });
  }

  fncreatepdf() {
    const Flujoce = this.headerFlujoCte.nativeElement;
    const options = {
      margin: [13, 8, 10, 8],
      filename: 'FlujoCliente.pdf',
      image: { type: 'JPEG', quality: 0.98 },
      html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
    };
    const content = this.el.nativeElement;
    html2pdf()
      .from(content)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.text(Flujoce.innerText, 80, 8); // Ajusta las coordenadas según sea necesario
          pdf.setPage(i);
          pdf.setFontSize(9);
          pdf.text(i.toString(), 5, 205);
        }
        pdf.save("FlujoCliente.pdf");
        /*   if(this.flagProspecto){
            this.fncerrarmodal()
         } */
      });
  }

  fncreateEdoCta() {
    const headerElement = this.headerCte.nativeElement;
    const options = {
      margin: [13, 8, 10, 8],
      filename: 'EstadoCuenta.pdf',
      image: { type: 'JPEG', quality: 0.98 },
      html2canvas: { scale: 1, useCORS: true, logging: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
    };
    const content = this.pdfEdoCta.nativeElement;
    html2pdf()
      .from(content)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(12);
          pdf.text(headerElement.innerText, 80, 8);
          pdf.setPage(i);
          pdf.setFontSize(9);
          pdf.text(i.toString(), 5, 205);
        }
        pdf.save("EstadoCuenta.pdf");
      });
  }

  fncreatepdfFlujo(obj: any) {
    this.arrayInfoPDFFlujoCte = obj
    //console.log( this.arrayInfoPDFFlujoCte)
    this.fncreatepdf()

  }
  fncreatepdfFlujo_2() {
    //  this.arrayInfoPDFFlujoCte = obj
    //console.log( this.arrayInfoPDFFlujoCte)
    this.fncreatepdf_2()

  }
  fncreatepdfEdoCta(obj: any) {
    this.arrayInfoPDFEdoCta = obj;
    //console.log(this.arrayInfoPDFEdoCta)
    this.fncreateEdoCta()
  }

  // Método para agregar la imagen al editor
  addImageToEditor(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const imgNode = $('<img>').attr('src', e.target.result as string).css({
        width: '300px !important',  // Ancho fijo con !important
        height: 'auto !important'   // Mantiene la proporción con !important
      });
      $('.summernote').summernote('insertNode', imgNode[0]);
    };
    reader.readAsDataURL(file);
  }

  adjustImageSizes() {
    $('.summernote').summernote('code', function (contents) {
      const $contents = $('<div>').html(contents);
      $contents.find('img').each(function () {
        $(this).css({
          width: '100px !important',  // Ancho deseado
          height: '100px !important'   // Mantiene la proporción
        }).addClass('custom-img-size'); // Asegurar que tenga la clase personalizada
      });
      return $contents.html();
    });
  }


  fnBuscarContacto1(event: KeyboardEvent): void {
    var inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getContacto_Busqueda',
        strResultado: inputValue,
        strConexion: this.strConexion
      }, 'sp_ModVentas_Leads_Familia')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.arrayAsigContacto1 = result
          },
          error => {
            var error = <any>error;
            console.log(error);
          }
        );
    } else {
      this.arrayAsigContacto1 = [];
    }
  }

  fnBuscarContacto2(event: KeyboardEvent): void {
    var inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getContacto_Busqueda',
        strResultado: inputValue,
        strConexion: this.strConexion
      }, 'sp_ModVentas_Leads_Familia')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.arrayAsigContacto2 = result
          },
          error => {
            var error = <any>error;
            console.log(error);
          }
        );
    } else {
      this.arrayAsigContacto2 = [];
    }
  }

  fnBuscarContacto3(event: KeyboardEvent): void {
    var inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getContacto_Busqueda',
        strResultado: inputValue,
        strConexion: this.strConexion
      }, 'sp_ModVentas_Leads_Familia')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.arrayAsigContacto3 = result
          },
          error => {
            var error = <any>error;
            console.log(error);
          }
        );
    } else {
      this.arrayAsigContacto3 = [];
    }
  }

  fnselecContacto(objCont: any) {
    this.strBuscarContacto = '';
    this.myForm.controls['strContacto_Nombre'].setValue(objCont.strContacto_Nombre);
    this.myForm.controls['strContacto_Telefono'].setValue(objCont.strContacto_Telefono);
    this.myForm.controls['strContacto_Email'].setValue(objCont.strContacto_Email);
    this.myForm.controls['strContacto_Puesto'].setValue(objCont.strContacto_Puesto);

    /*this.myForm.get('strContacto_Nombre').disable();
    this.myForm.get('strContacto_Puesto').disable();
    this.myForm.get('strContacto_Telefono').disable();
    this.myForm.get('strContacto_Email').disable();*/

    this.arrayAsigContacto1 = [];
    this.arrayAsigContacto2 = [];
    this.arrayAsigContacto3 = [];
  }

  resetForm() {
    this.myForm.controls['strContacto_Nombre'].setValue('');
    this.myForm.controls['strContacto_Telefono'].setValue('');
    this.myForm.controls['strContacto_Email'].setValue('');
    this.myForm.controls['strContacto_Puesto'].setValue('');
    this.arrayAsigContacto1 = [];
    this.arrayAsigContacto2 = [];
    this.arrayAsigContacto3 = [];

    this.myForm.get('strContacto_Nombre').enable();
    this.myForm.get('strContacto_Puesto').enable();
    this.myForm.get('strContacto_Telefono').enable();
    this.myForm.get('strContacto_Email').enable();

    this.intEditInfoGeneral = 0;
  }

  editarInformacionGeneral() {
    this.intEditInfoGeneral = 1

    this.myForm.controls['strContacto_Nombre'].setValue(this.objInfo.strContacto_Nombre);
    this.myForm.controls['strContacto_Telefono'].setValue(this.objInfo.strContacto_Telefono);
    this.myForm.controls['strContacto_Email'].setValue(this.objInfo.strContacto_Email);
    this.myForm.controls['strContacto_Puesto'].setValue(this.objInfo.strContacto_Puesto);
  }


  saveContacto() {
    this.intEditInfoGeneral = 0
    let rest: any = [];
    let objForm: any;
    this.myForm.value.strContacto_Nombre = this.myForm.get('strContacto_Nombre')?.value;
    this.myForm.value.strContacto_Telefono = this.myForm.get('strContacto_Telefono')?.value;
    this.myForm.value.strContacto_Email = this.myForm.get('strContacto_Email')?.value;
    this.myForm.value.strContacto_Puesto = this.myForm.get('strContacto_Puesto')?.value;
    this.myForm.value.intID_Contacto = this.objInfo.intID_Contacto
    this.myForm.value.strConexion = this.strConexion;
    objForm = this.myForm.value;

    this._serviciosService.mostrarCotizaciones(objForm, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          rest = result
          Swal.fire({
            icon: rest[0].code == '200' ? 'success' : 'error',
            title: rest[0].code == '200' ? 'Enviado' : 'Error',
            text: rest[0].strMensaje,
          });
          if (rest[0].code == '200') {
            this.fnGetInfo();
          }
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnBuscarRFC(event: any): void {

    var inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getRFC_Busqueda',
        strResultado: inputValue,
        strConexion: this.strConexion
      }, 'sp_ModVentas_Leads_Familia')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {

            this.arrayRFC = result

          },
          error => {
            var error = <any>error;
            console.log(error);
          }
        );
    } else {
      this.arrayRFC = [];
    }
  }

  fnIndicadores(): void {
    this.spinerIndicadores = true
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getIndicadores',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion,
      strGerente: this.strGerente_header,
      strSucursal: this.strSucursal_header
    }, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          // console.log(result)
          this.objIndicadores = result[0]
          this.spinerIndicadores = false
        },
        error => {
          this.spinerIndicadores = false
          var error = <any>error;
          console.log(error);
        }
      );
  }

  // Método para verificar si el formulario es válido
  isFormValid(): boolean {
    return this.myFormLeads.valid;
  }

  validarNumerosCP(event: KeyboardEvent) {
    const codigoTecla = event.keyCode;
    // Permite los números (0-9) y la tecla de retroceso (Backspace)
    if (codigoTecla < 48 || codigoTecla > 57) {
      event.preventDefault();
    }
  }

  onKeyDownCP(event: KeyboardEvent) {

    // Código ASCII de la tecla Delete y Retroceso     const DELETE_KEY = 46;     const BACKSPACE_KEY = 8;
    const ENTER_KEY = 13;
    // Verificar si se presionó la tecla Delete o Retroceso
    if (event.keyCode === ENTER_KEY) {
      this.fnGetDatosFiscalesCP();
    } else {
      this.myFormLeads.controls['strEstado'].setValue('');
      this.myFormLeads.controls['strDelegacion'].setValue('');
    }
  }

  blureventCP() {
    this.fnGetDatosFiscalesCP();
  }

  fnGetDatosFiscalesCP() {
    this._serviciosService.getAllSATCP({
      strCodigoPostal: (this.strCodigoPostal) ? this.strCodigoPostal : this.myFormLeads.controls['strCodigoPostal'].value
    })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result.length > 0) {
            if (this.strCodigoPostal) {
              this.strEstado = result[0].strEstado
              this.strDelegacion = result[0].strDelegacion
              this.arrayColonias = result;
            } else {
              this.myFormLeads.controls['strEstado'].setValue(result[0].strEstado);
              this.myFormLeads.controls['strDelegacion'].setValue(result[0].strDelegacion);
              this.arrayColonias = result;
            }


          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: '¡Codigo Postal Incorrecto!',
            });
            this.myFormLeads.controls['strEstado'].setValue('');
            this.myFormLeads.controls['strDelegacion'].setValue('');
            this.arrayColonias = []
          }
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  onSaveLeads(): void {
    let rest: any = [];
    let objForm: any;
    let rango = (this.myFormLeads.value.intIDNo_Empleados).split(',')
    this.myFormLeads.value.intIDNo_Empleados = rango[0]
    let presencia = (this.myFormLeads.value.intIDPresencia).split(',')
    this.myFormLeads.value.intIDPresencia = presencia[0]
    let ubicacion = (this.myFormLeads.value.intid_Ubicacion).split(',')
    this.myFormLeads.value.intid_Ubicacion = ubicacion[0]
    this.myFormLeads.value.strClasificacion = this.strClasificacion

    objForm = this.myFormLeads.value;

    this._serviciosService.mostrarCotizaciones(objForm, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          rest = result

          Swal.fire({
            icon: rest[0].code == '200' ? 'success' : 'error',
            title: rest[0].code == '200' ? 'Enviado' : 'Error',
            text: rest[0].strMensaje,
          });
          if (rest[0].code == '200') {
            // this.fnGetPorContactar();
            //this.btnCerrarMdl.nativeElement.click();
          }
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnCambiarBanderaBusqueda() {
    if (this.banderaCrearLead) {
      this.banderaCrearLead = false
    } else {
      this.banderaCrearLead = true
    }
  }

  fnRechazar(objAct: any) {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Rechazar',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.strResultado = ""
            this.contenido = "";
            this.fnllenarArrayTareas();
            this.btnCerrarAct.nativeElement.click();


          } else {
            this.contenido = ''

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }
  fnEnProceso(objAct: any) {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Proceso',
      intID_Actividad: objAct.intID_Actividad,
      strComentario: this.contenido,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.strResultado = "";
            this.contenido = "";
            this.fnllenarArrayTareas()
            this.btnCerrarAct.nativeElement.click();

          } else {
            this.contenido = ''
            /*    this.strMensaje = '' */
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnRelacionarRFC() {

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Relacionado con exito!",
      showConfirmButton: false,
      timer: 1500
    });
    document.getElementById('btnNuevoLead')!.click();

  }


  fnGetAgentesFamilia() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFiltroAgenteHistorico_Vta_fam',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayAgentesHistVenta = result

        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }








  fnHistoricoVentaSumasFiltrado() {

    let sumEnero = 0, sumFebrero = 0, sumMarzo = 0, sumAbril = 0,
      sumMayo = 0, sumJunio = 0, sumJulio = 0, sumAgosto = 0,
      sumSeptiembre = 0, sumOctubre = 0, sumNoviembre = 0, sumDiciembre = 0;

    const copiaProfunda = JSON.parse(JSON.stringify(this.arrayFiltradoHistClientes));
    let filter = this.strAgenteSelect == "" ? copiaProfunda : copiaProfunda.filter(o => o.strNombreAgente == this.strAgenteSelect)

    filter.forEach(lin => {
      sumEnero = 0; sumFebrero = 0; sumMarzo = 0;
      sumAbril = 0; sumMayo = 0; sumJunio = 0;
      sumJulio = 0; sumAgosto = 0; sumSeptiembre = 0;
      sumOctubre = 0; sumNoviembre = 0; sumDiciembre = 0;
      lin.arrayLineas.forEach(art => {
        sumEnero += art.sumEnero;
        sumFebrero += art.sumFebrero;
        sumMarzo += art.sumMarzo;
        sumAbril += art.sumAbril;
        sumMayo += art.sumMayo;
        sumJunio += art.sumJunio;
        sumJulio += art.sumJulio;
        sumAgosto += art.sumAgosto;
        sumSeptiembre += art.sumSeptiembre;
        sumOctubre += art.sumOctubre;
        sumNoviembre += art.sumNoviembre;
        sumDiciembre += art.sumDiciembre;


      });
      lin.sumEnero = sumEnero
      lin.sumFebrero = sumFebrero
      lin.sumMarzo = sumMarzo
      lin.sumAbril = sumAbril
      lin.sumMayo = sumMayo
      lin.sumJunio = sumJunio
      lin.sumJulio = sumJulio
      lin.sumAgosto = sumAgosto
      lin.sumSeptiembre = sumSeptiembre
      lin.sumOctubre = sumOctubre
      lin.sumNoviembre = sumNoviembre
      lin.sumDiciembre = sumDiciembre
      lin.total = lin.sumEnero + lin.sumFebrero + lin.sumMarzo + lin.sumAbril + lin.sumMayo + lin.sumJunio + lin.sumJulio + lin.sumAgosto + lin.sumSeptiembre + lin.sumOctubre + lin.sumNoviembre + lin.sumDiciembre
    });

    this.objHistCliente.dblEnero = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumEnero ? objetoActual.sumEnero : 0);
    }, 0);

    this.objHistCliente.dblFebrero = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumFebrero ? objetoActual.sumFebrero : 0);
    }, 0);

    this.objHistCliente.dblMarzo = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumMarzo ? objetoActual.sumMarzo : 0);
    }, 0);

    this.objHistCliente.dblAbril = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumAbril ? objetoActual.sumAbril : 0);
    }, 0);

    this.objHistCliente.dblMayo = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumMayo ? objetoActual.sumMayo : 0);
    }, 0);

    this.objHistCliente.dblJunio = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumJunio ? objetoActual.sumJunio : 0);
    }, 0);

    this.objHistCliente.dblJulio = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumJulio ? objetoActual.sumJulio : 0);
    }, 0);

    this.objHistCliente.dblAgosto = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumAgosto ? objetoActual.sumAgosto : 0);
    }, 0);

    this.objHistCliente.dblSeptiembre = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumSeptiembre ? objetoActual.sumSeptiembre : 0);
    }, 0);

    this.objHistCliente.dblOctubre = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumOctubre ? objetoActual.sumOctubre : 0);
    }, 0);

    this.objHistCliente.dblNoviembre = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumNoviembre ? objetoActual.sumNoviembre : 0);
    }, 0);

    this.objHistCliente.dblDiciembre = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumDiciembre ? objetoActual.sumDiciembre : 0);
    }, 0);

    this.objHistCliente.dblTotal = filter.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.total ? objetoActual.total : 0);
    }, 0);
    console.log(this.arrayFiltradoHistClientes)
  }
  fnFiltrarSegundaLetra() {
    this.arraySubGiro = this.arraySubGiroTotal.filter((b: any) => b.intId_Giro == this.intGiro)
    //this.arrayFitroSegunda = this.arraySegundaLetra.filter((b: any) => b.intIDPresencia == this.formLead.value.intIDPresencia && b.intId_Giro == this.formLead.value.intId_Giro)
    this.arrayFitroSegunda = this.arraySegundaLetra.filter((b: any) => b.intIDPresencia == this.intIDPresencia && b.intId_Giro == this.intGiro)
    //this.arrayFiltroTercera = this.arrayTercerLetra.filter((b: any) => b.intIDPresencia == this.formLead.value.intIDPresencia && b.intId_Giro == this.formLead.value.intId_Giro)
    this.arrayFiltroTercera = this.arrayTercerLetra.filter((b: any) => b.intIDPresencia == this.intIDPresencia && b.intId_Giro == this.intGiro)

    let potenciador = this.arrayGiros.filter((b: any) => b.intId_Giro == this.intGiro)
    this.intPotenciador = potenciador && potenciador[0] ? potenciador[0].intPotenciador : 0;
  }

  fngetSectores() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSector',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {

          this.arraySectores = result
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  toggleMultiple(linea: any) {

    this.arrayPotenciador.forEach(e => {
      if (linea == e.strLinea) {
        if (e.Seleccionado) {
          e.Seleccionado = false;
        } else {
          e.Seleccionado = true;
        }
      }
    });

  }

  fngetSegundaLetra() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSegundaLetra',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arraySegundaLetra = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fngetTerceraLetra() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getTerceraLetra',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayTercerLetra = result
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fngetSubGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSubGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arraySubGiroTotal = result
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }
  fnSeleccionarLinea() {
    if (this.strLineaHistVta == "") {
      this.arrayLineasHist.forEach(linea => {
        linea.seeLineas = false
      });
    } else {
      this.arrayLineasHist.forEach(linea => {
        if (this.strLineaHistVta == linea.strLinea) {
          linea.seeLineas = true
        }
      });
    }

  }
  fnFiltrarInfo(gerente: string, sucursal: string) {
    this.fnIndicadores();
    this.fnGetObjetivos();
    this.fnllenarArrayTareas();
    this.fnListadoOoprtunidad();
  }

  onGerenteChange(srtCam: string) {
    if (srtCam == "Ger") {
      this.strSucursal_header = ""
      //this.strAgente =""
    }
    /*else if(srtCam=="Suc"){
      this.strAgente =""
    }*/
  }


  onInput(event: Event) {
    const target = event.target as HTMLElement;
    this.mdlComentarios = target.innerHTML; // Guarda el contenido HTML
  }


  /***inician funciones para editar y eliminar actividades *****/
  fnDelete_Tarea(tarea: any) {
    let intID_Actividad = tarea.intID_Actividad;
    Swal.fire({
      title: "¿Estás seguro de eliminar esta Actividad?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Sí, Eliminar",
      denyButtonText: `No, Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.fnEliminar_Tarea(intID_Actividad)
      } else if (result.isDenied) {
        Swal.fire("No se guardaron los cambios", "", "info");
      }
    });

  }
  fnEliminar_Tarea(intID_Actividad: number) {
    console.log(intID_Actividad);
    let objParam = {
      strAccion: 'deleteTarea',
      intID_Actividad: intID_Actividad,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          let resultado = result[0];
          if (resultado && resultado.code == 200) {
            Swal.fire("Tarea Eliminada Correctamente", "", "success");
          }
          else {
            Swal.fire("Error al eliminar Tarea", "", "warning");
          }
          this.fnllenarArrayTareas();
        },
        error => {
          var error = <any>error;
          Swal.fire("Error al eliminar Tarea", "", "warning");
          console.log(error);
        }
      );
  }
  fnEditar_Actividad(objAct: any) {
    console.log(objAct);
    this.objEditarActividad = {};
    this.objEditarActividad = objAct;
    this.mdlNombre = this.objEditarActividad.strTitulo;
    let strf = this.objEditarActividad.strFechaRequerida;

    // Dividir la cadena en partes
    const [datePart, timePart] = strf.split(' '); // Divide en fecha y hora
    const [month, day, year] = datePart.split('/'); // Divide la parte de fecha en mes, día y año

    this.strfecha_editar = year + '-' + month + '-' + day;
    let hora1 = this.objEditarActividad.strHoraInicio.slice(0, -3);
    this.mdlHoraInicio = hora1;
    let hora2 = this.objEditarActividad.strHoraFin.slice(0, -3);
    this.mdlHoraFin = hora2;
    this.strAgente = this.objEditarActividad.strAgente;
    this.strBuscarAgente = this.objEditarActividad.strAgente + '-' + this.objEditarActividad.strNombreAgente;
    this.mdlComentarios = this.objEditarActividad.strOpcion;
    this.editableDiv.nativeElement.innerHTML = this.mdlComentarios;

  }
  fnEditarActividad(intID_Actividad: number) {
    console.log(intID_Actividad);
    let objParam = {
      strAccion: 'editTarea',
      strFechaInicioServicio: this.strfecha_editar,
      strComentario: this.mdlComentarios,
      strAgente: this.strAgente,
      intID_Actividad: intID_Actividad,
      strConexion: this.strConexion,
      strTitulo: this.mdlNombre,
      strHoraInicio: this.mdlHoraInicio,
      strHoraFin: this.mdlHoraFin
    }


    this._serviciosService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          let resultado = result[0];
          if (resultado && resultado.code == 200) {
            Swal.fire("Tarea Editada Correctamente", "", "success");
          }
          else {
            Swal.fire("Error al Editar Tarea", "", "warning");
          }
          this.btnCerrarEdit.nativeElement.click();
          this.fnllenarArrayTareas();
        },
        error => {
          var error = <any>error;
          Swal.fire("Error al eliminar Tarea", "", "warning");
          console.log(error);
        }
      );
  }
  fnBusquedaCte(strBuscarAgente: string) {
    let texto = strBuscarAgente.toUpperCase();
    this.arrayAgente_Filter = [];
    for (const post of this.arrayAgentes_tareas) {
      let texto2 = post.strNombre.toUpperCase();
      if (texto2.indexOf(texto) > -1) {
        this.arrayAgente_Filter.push(post);
      }
    };
  }
  fnSeleccionaAgente(agt: any) {
    this.strAgente = agt.strAgente;
    this.strBuscarAgente = "";/* agt.strAgente+'-'+agt.strNombre  */
    this.arrayAgente_Filter = [];
    let findAgente = this.arrayAgentesActividades.find(agtf => agtf.strAgente == agt.strAgente);
    if (!findAgente) {
      this.arrayAgentesActividades.push(agt)
    } else {
      Swal.fire("Error", "Agente existente, seleccione otro agente.", "error")
    }

  }

  fnEliminarAgenteAct(agt: any) {

    this.arrayAgentesActividades = this.arrayAgentesActividades.filter(item => item !== agt);
  }

  fnAddMinuta() {
    this.formMinuta = this.formbuilder.group({
      strTitulo: new FormControl('', [Validators.required]),
      strFecha: new FormControl('', [Validators.required]),
      strHora: new FormControl('', [Validators.required])
    });
  }

  fnGetMinutas() {
    let objMin = {
      intID_Leads: this.intID_Leads,
      strAccion: 'getMinuta',
      strConexion: this.strConexion
    }
    console.log(objMin)
    this._serviciosService.mostrarCotizaciones(objMin, 'sp_ModVentas_Leads')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayMinuta = result;
          console.log(this.arrayMinuta)
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
  }

  fnSetMinuta(objMin: any) {
    try {
      let objEnviar = {
        strConexion: this.strConexion,
        strAccion: 'setMinuta',
        strTitulo: objMin.strTitulo,
        strFechaInicioServicio: objMin.strFecha,
        strHoraInicio: objMin.strHora,
        intID_Leads: this.intID_Leads,
        strAgente: this.strAgente_Crea
      }
      console.log(objEnviar);
      this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_ModVentas_Leads')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            let rest = result[0]
            Swal.fire(rest.code == "200" ? "Guardado Correctamente" : "Error", rest.strMensaje, rest.code == "200" ? "success" : "error");
            if (rest.code == "200") {
              this.fnGetMinutas();
              this.btnCerrarMinuta.nativeElement.click()
            }
          }, error => {
            var error = <any>error; console.log(error);
          }
        );
    } catch (error) {
      Swal.fire("Error", error.message, "error")
    }
  }

  onSubmit() {
    if (this.formMinuta.valid) {
      this.fnSetMinuta(this.formMinuta.value);
      // Procesar los datos del formulario
      /* console.log(this.formMinuta.value); */
    }
  }
  onSubmitOportunidades() {
    if (this.formOportunidades.valid) {
      this.fnInsertOportunidad(this.formOportunidades.value);
      // Procesar los datos del formulario
      /*    console.log(this.formOportunidades.value); */
    }
  }

  fnDetalleMinuta(obj: any) {
    console.log(obj)
    this.router.navigate(['/minuta'], { queryParams: { intID_Minuta: obj.intID_Minuta } });
  }


  deleteMinuta(objMin: any, event: Event): void {
    event.stopPropagation();

    Swal.fire({
      title: "¿Estás seguro de eliminar esta minuta?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Sí, Eliminar",
      denyButtonText: `No, Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          let objEnviar = {
            strConexion: this.strConexion,
            strAccion: 'deleteMinuta',
            intID_Minuta: objMin.intID_Minuta
          }
          console.log(objEnviar);
          this._serviciosService.mostrarCotizaciones(objEnviar, 'sp_ModVentas_Leads')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              result => {
                let rest = result[0]
                Swal.fire(rest.code == "200" ? "Elimina Correctamente" : "Error", rest.strMensaje, rest.code == "200" ? "success" : "error");
                this.fnGetMinutas();
              }, error => {
                var error = <any>error; console.log(error);
              }
            );
        } catch (error) {
          Swal.fire("Error", error.message, "error")
        }


      } else if (result.isDenied) {
        Swal.fire({
          title: "No se guardaron los cambios",
          icon: "info",
          timer: 2500,  // Tiempo en milisegundos (3 segundos)
          showConfirmButton: false,  // No mostrar el botón "OK"
          timerProgressBar: true
        });
      }

    })
  }

  applyFilterArtsFavoritos(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceFavoritos.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceFavoritos.paginator) {
      this.dataSourceFavoritos.paginator.firstPage();
    }
  }
  fnInsertComentario() {

    let obj = {
      strAccion: 'setComentario_Leads_Actividad',
      intID_Actividad: this.intIDActividad,
      strAgente: this.strAgente,
      strComentario: this.strComentarioD,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.strComentarioD = ''
            this.fngetComentarios();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerBool = false
        });
  }

  fngetComentarios() {
    let obj = {
      strAccion: 'getListComentario_Actividad',
      intID_Actividad: this.intIDActividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayComentarios = result
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerBool = false
        });
  }

  fnAddLinea(obj: any) {
    obj.select = true;
    let bandera = 0;
    this.arrayLineasSelect.forEach(lin => {
      if (lin.strLinea == obj.strLinea) {
        bandera += 1
      }
    });
    if (bandera == 0) {
      this.arrayLineasSelect.push({ strLinea: obj.strLinea, dblCantidad: 0 })
    } else {
      this.arrayLineasSelect = this.arrayLineasSelect.filter(lin => lin.strLinea !== obj.strLinea);
      obj.select = false;
    }
    this.totalImporteLineas();
  }
  totalImporteLineas(): number {
    let result = this.arrayLineasSelect.reduce((sum, item) => sum + item.dblCantidad, 0)

    this.formOportunidades.patchValue({ intMonto: result });
    return result;
  }
  updateCantidad(event: Event, cet: any): void {
    let inputElement = event.target as HTMLInputElement;
    let valor = parseFloat(inputElement.value);
    if (inputElement.value === 'e' || inputElement.value === '' || isNaN(valor) || valor <= 0) {
      cet.dblCantidad = 0;
      inputElement.value = '0'; // Esto asegura que el valor mostrado en el input sea '0'
    } else {
      cet.dblCantidad = valor;
    }
    this.totalImporteLineas();
  }

  fnAdjuntarArchivosActividad(): void {

    if (this.fileInputActividades) {
      this.arrayAgregarAnexosActividades = [];
      this.fileInputActividades.nativeElement.value = '';
      this.fileInputActividades.nativeElement.click();
    }
  }
  onFileSelectedActividad(event: any): void {
    let files: FileList = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let newTotalSize = file.size;
        if (newTotalSize > 10 * 1024 * 1024) {
          Swal.fire({
            icon: 'warning',
            title: 'Límite de almacenamiento alcanzado',
            text: 'No puedes agregar más archivos, el límite es de 10MB.',
          });
          return;
        }

        let nombreOriginal = file.name.split('.').slice(0, -1).join('.');
        let extension = file.name.split('.').pop();

        this.arrayAgregarAnexosActividades.push({
          nombreArchivo: nombreOriginal,
          extencion: extension,
          archivo: file
        });
      }
    }
  }
  fnEliminarAnexosAct(): void {
    this.fileInputActividades.nativeElement.value = '';
    this.arrayAgregarAnexosActividades = [];
  }


  fnAnexosActividad() {

    if (this.arrayAgregarAnexosActividades.length > 0) {
      let formData = new FormData();
      this.arrayAgregarAnexosActividades.forEach((o, index) => {
        if (o.archivo instanceof File) {
          formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
        } else {
          console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
        }
      });
      this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
        console.log(result)
        this.arrayEnviarAnexosActividades = []
        let arrayRest = result;
        arrayRest.forEach(obj => {
          this.arrayEnviarAnexosActividades.push(
            {
              strTipo: obj.strTipo,
              strRespuesta: obj.file,
              strNombre: obj.strNombre,
              strAgente: this.strAgente,
              strEstatus: ""
            });
        });
        console.log(this.arrayEnviarAnexosActividades[0])
        this.fnEnviarActividad(this.arrayEnviarAnexosActividades[0])
      }, (error) => {
        console.log(error);
      });
    } else {
      this.fnEnviarActividad()
    }
  }

  onFileInsert(event: any): void {
    let files: FileList = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let newTotalSize = file.size;
        if (newTotalSize > 10 * 1024 * 1024) {
          Swal.fire({
            icon: 'warning',
            title: 'Límite excedido',
            text: 'El tamaño total de los archivos no puede superar los 10MB.',
          });
          return;
        }

        const nombreOriginal = file.name.split('.').slice(0, -1).join('.');
        const extension = file.name.split('.').pop();

        this.arrayAnexMdlAdd.push({
          nombreArchivo: nombreOriginal,
          extencion: extension,
          archivo: file
        });

      }
    }
  }

  fnAgregarArchivo() {
    let formData = new FormData();
    this.arrayAnexMdlAdd.forEach((o, index) => {
      if (o.archivo instanceof File) {
        formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
      } else {
        console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
      }
    });

    this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
      console.log(result)
      if (result.length > 0) {
        this.arrayEnviarAnexosActividades = []
        let arrayRest = result;
        let enviar = {}
        arrayRest.forEach(obj => {
          enviar = {
            strAccion: 'setAnexoActividad',
            intID_Leads: this.objInfo.intID_Leads,
            intID_Actividad: this.intIDActividad,
            strConexion: this.strConexion,
            strTipo_CIERRE: obj.strTipo,
            strUrl_Logo: obj.file,
            strResultado: obj.strNombre,
            strAgente_Crea: this.strAgente,
            strMotivo: ""
          }
        });
        console.log(enviar)
        this.fnGuardarArchivo(enviar);
      } else {
        Swal.fire("Error", "Error al guardar archivo.", "error");
      }
    }, (error) => {
      console.log(error);
    });
  }
  fnElinimarArchivos() {
    this.arrayAnexMdlAdd = []
  }

  fnGuardarArchivo(obj: any) {
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          console.log(result)
          if (result[0].code == 200) {
            Swal.fire("Guardado", result[0].strMensaje, "success")
            this.arrayAnexMdlAdd = [];
            this.fileModal.nativeElement.value = ""
            this.arrayAnexosModal = [];
            this.fnGetAnexosActividades()
          } else {
            Swal.fire("Error", result[0].strMensaje, "error")
          }
        }, error => {
          var error = <any>error; console.log(error);
          // this.spinerBool = false
        });
  }
  fnAjuntarAnexsModal(): void {
    if (this.fileModal) {
      this.arrayAnexMdlAdd = [];
      this.fileModal.nativeElement.value = ""
      this.fileModal.nativeElement.click();
    }
  }
  fnGetAnexosActividades() {
    this.arrayAnexosModal = [];
    let obj = {
      strAccion: 'getActAnexos',
      intID_Actividad: this.intIDActividad,
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          /*   console.log(result) */
          if (result.length > 0) {
            this.arrayAnexosModal = result
            this.arrayAnexosModal.forEach(obj => {
              obj.urlImagen = "https://www.ecodeli.com/" + obj.strUrl_Logo
            });
            /*     console.log(this.arrayAnexosModal); */
          }

        }, error => {
          var error = <any>error; console.log(error);
          // this.spinerBool = false
        });

  }

  seleccionarTab(index: number) {
    this.tabSeleccionada = index;
  }

  agregarCarpeta() {
    this.arrayCarpetas.push({
      nombreCarpeta: this.strNombreCarpeta,
      xmlDocumentos: []
    })
    this.strNombreCarpeta = ''
  }



  async fnPdfArtsPolitica() {
    this.boolPolitica = true;

    const doc = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 25; // Tamaño de imagen en mm
    const imgHeight = 25;

    const titulo = "Artículos - Asignados " + this.objInfo.strNombreEmpresa;
    doc.setFontSize(14); // Tamaño del texto
    doc.setTextColor(40); // Color del texto (negro)
    doc.text(titulo, 105, 15, { align: "center" })

    const columns = ['#', 'Imagen', 'Articulo', 'Descripcion', 'Unidad', 'Precio', 'Tipo']; // Definir columnas
    const rows: any[] = [];

    const imagenesBase64: string[] = await Promise.all(
      this.arrayArtsPolitica.map(async (item) => {
        const urlImagen = `https://images.weserv.nl/?url=www.cleanclean.mx/imagenes/${item.strArticulo}.jpg`
        /* const urlImagen = `https://www.cleanclean.mx/imagenes/${item.strArticulo}.jpg`; */
        /*  const urlImagen = `https://picsum.photos/200/300` */
        return await this.getBase64Image(urlImagen);
      })
    );

    this.arrayArtsPolitica.forEach((item, index) => {


      rows.push([(index + 1).toString(), "", item.strArticulo.toString(), item.strDescripcion.toString(), item.strUnidad.toString(), "$" + item.dblPrecio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), item.strTipo.toString()]); // Insertamos la URL temporalmente
    });

    autoTable(doc, {
      head: [columns],
      body: rows,
      startY: 20,
      theme: 'grid',
      didDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1 && typeof imagenesBase64[data.row.index] === "string") {
          const xPos = data.cell.x + (data.cell.width - imgWidth) / 2; // Centrar imagen horizontalmente
          const yPos = data.cell.y + (data.cell.height - imgHeight) / 2; // Centrar imagen verticalmente
          doc.addImage(imagenesBase64[data.row.index], 'JPEG', xPos, yPos, imgWidth, imgHeight);
        }
      },
      styles: { fontSize: 8 },
      columnStyles: {
        1: { cellWidth: imgWidth + 8 }, // Ajustar tamaño de celda de la imagen
        3: { cellWidth: 'auto' }, // Ajustar la celda de descripción automáticamente
        6: { cellWidth: 'auto' }
      },
      rowPageBreak: 'avoid', // Evita que una fila con imagen se corte en dos páginas
      margin: { top: 10 },
      didParseCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          data.cell.styles.minCellHeight = imgHeight + 5; // Asegurar que la fila sea más alta que la imagen
        }
      }
    });

    doc.save('Articulos_Asignados.pdf');
    this.boolPolitica = false;

    /*      const Flujoce = this.headerArtsAsing.nativeElement;
         const options = {
           margin: [13, 8, 10, 8],
           filename: 'ArticulosAsinados.pdf',
           image: { type: 'JPEG', quality: 0.98 },
           html2canvas: { scale: 1.5, useCORS: true, allowTaint: true },
           jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
         };
         const content = this.pdfArtsPolitica.nativeElement;
         html2pdf()
           .from(content)
           .set(options)
           .toPdf()
           .get('pdf')
           .then(pdf => {
             const totalPages = pdf.internal.getNumberOfPages();
             for (let i = 1; i <= totalPages; i++) {
               pdf.setPage(i);
               pdf.setFontSize(12);
               pdf.text(Flujoce.innerText, 100, 8);
               pdf.setFontSize(9);
               pdf.text(i.toString(), 5, 205);
             }
             pdf.save("ArticulosAsinados.pdf");
           }); */



    /*    const Flujoce = this.headerArtsAsing.nativeElement;
       const options = {
         margin: [13, 8, 10, 8],
         filename: 'ArticulosAsinados.pdf',
         image: { type: 'JPEG', quality: 0.98 },
         html2canvas: { scale: 1, autoPaging: true, useCORS: true, ignoreElements: (element) => element.classList.contains('no-print') }, // Habilita autoPaging
         jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
       };
       const content = this.pdfArtsPolitica.nativeElement;
       html2pdf()
         .from(content)
         .set(options)
         .toPdf()
         .get('pdf')
         .then(pdf => {
           const totalPages = pdf.internal.getNumberOfPages();
           for (let i = 1; i <= totalPages; i++) {
             pdf.setPage(i);
             pdf.setFontSize(12);
             pdf.text(Flujoce.innerText, 100, 8); // Ajusta las coordenadas según sea necesario
             pdf.setPage(i);
             pdf.setFontSize(9);
             pdf.text(i.toString(), 5, 205);
           }
           pdf.save("ArticulosAsinados.pdf");   });*/
    /*   if(this.flagProspecto){
        this.fncerrarmodal()
     } */
  }

  async getBase64Image(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';

      img.src = url;
      /*  img.src = url; */
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx!.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/jpeg')); // Retorna la imagen en Base64
      };
      img.onerror = (error) => reject(error);
    });
  }
  fnSeeRow(ind: any, actividad: any) {
    console.log(ind)
    this.fngetSubtareas(actividad.intID_Actividad)
    let valAnt = this.arrayActividades[ind].seeSubAct
    this.arrayActividades.forEach(act => {
      act.seeSubAct = false
    });

    this.arrayActividades[ind].seeSubAct = valAnt == true ? false : true
  }
  fngetSubtareas(intID_Actividad: any) {
    this.arraySubTareas = [];
    let obj = {
        strAccion: 'getSubActividades',
        intID_Actividad: intID_Actividad,
        intID_Leads:this.objInfo?.intID_Leads,
        strConexion: this.strConexion
    };
    console.log(obj)
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          console.log(result)
          this.arraySubTareas = result
          /*  this.arraySubTareas.forEach(item => {
             let objAgente = this.arrayAgentesTodos.find(o => o.strAgente == item.strAgente_Crea)
             item.nombreCrea = objAgente && objAgente.strNombre ? objAgente.strNombre : "";
             item.select = false;
           }); */
        }, error => {
          //this.spinnerTab = false
          console.error('Error al consumir la API:', error);

        });
  }
  fnModalRegistrarSubtarea(tipo: string, obj: any) {

    this.objSubtarea = obj
    //this.editableDiv2.nativeElement.innerHTML ='';
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = tipo;
    this.objModal.subTitulo = obj.intID_Leads + '.-' + obj.strTitulo
    this.arrayAgentesActividades = [];
    this.strBuscarAgente = "";
    this.arrayAgregarAnexosActividades = [];
    this.fileInputActividades.nativeElement.value = '';
    
    this.inputComentarios.nativeElement.innerHTML = ""
  }
  fnCleanSearch(){
    this.strBuscarAct = ""
  }
}

