<div class="container mt-5"> 
    <h1 class="text-center text-primary mb-4">Mis Pedidos</h1>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let pedido of arrayPedidos; let i = index">
        <mat-expansion-panel-header>
          <mat-panel-title>
            N° Pedido: {{pedido.intID_Pedido}}
          </mat-panel-title>
          <mat-panel-description>
            {{pedido.strFecha}} - Total: {{pedido.dblImporte | currency}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div class="pedido-detalle">
            <p><strong>Estatus:</strong>
                <span 
                  [ngClass]="getEstatusClass(pedido.strEstatus_Pago)" 
                  class="badge text-dark">
                  <i [ngClass]="getEstatusIcon(pedido.strEstatus_Pago)"></i> 
                  {{pedido.strEstatus_Pago}}
                </span>
           </p>
           
          <p><strong>Detalle del Pedido:</strong></p>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Artículo</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Unidad</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of pedido.detalles">
                <td>
                    <div class="d-flex align-items-center">
                      <!-- Imagen con tamaño definido -->
                      <img class="rounded w-25" 
                           alt="Product image" 
                           [src]="'https://www.cleanclean.mx/imagenes/'+item.strArticulo+'.JPG'">
                      <!-- Nombre del artículo a la derecha, centrado verticalmente -->
                      <span class="ms-3">{{ item.strArticulo }}</span>
                    </div>
                  </td>
                <td>{{ item.strDescripcion1 }} <br> <b *ngIf="item.strOpcion !=''">({{item.strOpcion}}) {{item.strNombreOpcion}}</b>   </td>
                <td>{{ item.dblCantidad }}</td>
                <td>{{ item.dblPrecioLista | currency }}</td>
                <td>{{ item.strUnidad }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  