<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold  text-decoration-underline">Solicitud <span class="titulo">Pedidos</span></h1>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col mt-2 pt-1 d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col mt-2 pt-1 d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col mt-2 pt-1 d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Estatus</mat-label>
                <mat-select [(ngModel)]="strEstatus" (selectionChange)="fnFiltrar();">
                    <mat-option value="POR AUTORIZAR">
                        POR AUTORIZAR
                    </mat-option>
                    <mat-option value="AUTORIZADO">
                        AUTORIZADO
                    </mat-option>
                    <mat-option value="RECHAZADO">
                        RECHAZADO
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col mt-2 pt-1 d-flex justify-content-center align-items-center" >
            <button (click)="fnGetPedidos()" class="btn btnFilter shadow-sm px-2 py-1"  (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div *ngIf="spinerPed" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="alert alert-danger text-center fw-bold my-3" *ngIf=" !spinerPed && arrayPedidos_filter.length==0" role="alert">
                No se han encontrado datos de pedidos
              </div>
              <div  [hidden]="arrayPedidos.length==0">
                <div class="col-12 d-flex px-2 bg-white justify-content-between align-items-center">
                    <mat-form-field appearance="outline" class="bg-white rounded my-2">
                        <mat-label>Buscar:</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                      </mat-form-field>
                      <span > 
                       <span class="fw-bold">Total Documentos:</span>  {{arrayPedidos_filter.length}}
                      </span>
                  </div>
              </div>
              
            <div class="table-responsive mb-0" [hidden]="arrayPedidos_filter.length==0">
                  <div class="mat-elevation-z8">
                    <table class="table w-100 table-bordered" mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="strIDPedido">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2"> ID Pedido </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> {{row.strIDPedido}} </td>
                          </ng-container>
                        <ng-container matColumnDef="strCliente">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fw-bold fs12 text-primary pointer" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal(row)"> ({{row.strCliente}}) {{row.strClienteNombre}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strClienteNombreCorto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Corto </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs12"> {{row.strClienteNombreCorto}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strCteEnviarANombre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal Cliente</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> <span class="text-nowrap">{{row.strCteEnviarANombre}} </span></td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strFechaSolicitud">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Solicitud</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs12"> {{row.strFechaSolicitud}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strAgenteNombre">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Agente </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap"> {{row.strAgenteNombre}} </span></td>
                          </ng-container>
                
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  
                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                      </tr>
                    </table>
                  
                    
                  </div>
            </div>
            <div class="col-12" [hidden]=" arrayPedidos.length==0">
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header position-relative">
            <div class="ms-2 position-absolute top-50 start-0 translate-middle-y " *ngIf="objEncabezado.strOrdenCompra != ''">
               <a class="d-flex flex-column justify-content-center" href="{{objEncabezado.strordenCompraDocumento}}" matTooltip="{{objEncabezado.strordenCompraDocumento}}" target="_blank" rel="noopener noreferrer">
                <span class="fw-bold">
                    Descargar Adjunto
                </span>
                <span class="text-center">
                    <i class="fa-solid fa-cloud-arrow-down fs-4 text-primary pointer"></i>
                </span>
            </a>
            </div>
          <h2 class="modal-title fw-bold mb-0 mx-auto" id="exampleModalLabel">PEDIDO: {{objModal.pedido}}</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close" #btnCerrar></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-11">
                    <div class="col-12">
                        <div class="table-responsive mb-0 shadow-sm rounded border-0">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colspan="4" class="bgThModal">
                                            DATOS DEL CLIENTE: {{objEncabezado?.strCliente}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="fs12 fw-bold bg-light">NOMBRE</td>
                                        <td class="fs11">{{objEncabezado?.strNombreCliente}}</td>
                                        <td class="fs12 fw-bold bg-light">Familia</td>
                                        <td class="fs11">{{objEncabezado?.strFamilia}}</td>
                                    </tr>
                                    <tr>
                                        <td class="fs12 fw-bold bg-light">Domicilio</td>
                                        <td class="fs11">{{objEncabezado?.strDomicilio}}</td>
                                        <td class="fs12 fw-bold bg-light">Fecha Requerida</td>
                                        <td class="fs11">{{objEncabezado?.strFechaRequerida}}</td>
                                    </tr>
                                    <tr>
                                        <td class="fs12 fw-bold bg-light">Orden de Compra</td>
                                        <td class="fs11">{{objEncabezado?.strOrdenCompra}}</td>
                                        <td class="fs12 fw-bold bg-light">Referencia</td>
                                        <td class="fs11">{{objEncabezado?.strReferencia}}</td>
                                    </tr>
                                    <tr>
                                        <td class="fs12 fw-bold bg-light">Observaciones</td>
                                        <td class="fs11">{{objEncabezado?.strObservaciones}}</td>
                                        <td class="fs12 fw-bold bg-light"></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mt-4 mb-2" [hidden]="boolRechazarPro">
                <div class="col-11">
                    <div class="col-12">
                        <div class="table-responsive  mb-0 shadow rounded border-0" >
                            <table class="table table-bordered border">
                                <thead>
                                    <tr>
                                        <th class="fw-bold fs13 bgThModal nunito">Articulo</th>
                                        <th class="fw-bold fs13 bgThModal nunito">SubCuenta</th>
                                        <th class="fw-bold fs13 bgThModal nunito">Descripcion</th>
                                        <th class="fw-bold fs13 bgThModal nunito">Unidad</th>
                                        <th class="fw-bold fs13 bgThModal nunito">Precio</th>
                                        <th class="fw-bold fs13 bgThModal nunito">Cantidad</th>
                                        <th class="fw-bold fs13 bgThModal nunito" *ngIf="strEstatus == 'POR AUTORIZAR' && btnAutorizar">Accion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let x of objModal?.arrayPedido; let i = index">
                                        <td class="fs12 fw-bold text-secondary align-middle">{{x.strArticulo}}</td>
                                        <td class="fs12 align-middle">{{x.strSubCuenta}}</td>
                                        <td class="fs11 align-middle">{{x.strDescripcion1}}</td>
                                        <td class="fs12 align-middle">{{x.strUnidad}}</td>
                                        <td class="fs12 fw-bold align-middle">{{x.dblPrecio|currency}}</td>
                                        <td class="fs12 align-middle">
                                            <input *ngIf="strEstatus == 'POR AUTORIZAR'" type="number" class="form-control fs12" [(ngModel)]="x.dblCantidad">
                                            <span *ngIf="strEstatus != 'POR AUTORIZAR'">{{x.dblCantidad}}</span>
                                        </td>
                                        <td class="fs12 align-middle" *ngIf="strEstatus == 'POR AUTORIZAR'  && btnAutorizar">
                                            <button class="btn btn-danger fs11 py-1" (click)="fnRechazarProd('cancelProd',x)">
                                                RECHAZAR
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="">
                                    <tr>
                                        <td colspan="4"></td>
                                        <td class="fw-bold fs14">{{objModal.totalPrecio|currency}}</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-3" *ngIf="strEstatus=='RECHAZADO'">
                <div class="col-12">
                    <div class="alert alert-danger text-center fw-bold" role="alert">
                        A simple danger alert—check it out!
                      </div>
                </div>
            </div>
            
            <div class="row justify-content-center" [hidden]="!boolRechazarPro">
                <div class="col-11 mt-3">
                    <h4 class="text-center fw-bold text-danger mb-0">
                         {{objModal?.tituloRechazo}}
                    </h4>
                </div>
                
                <div class="col-11 mb-3">
                    <div class="col-12">
                        <h5 class="mb-0 fw-bold">
                            Observaciones de cancelación
                        </h5>
                    </div>
                    <textarea name="" class="form-control" id="" [(ngModel)]="strObservaciones"></textarea>
                </div>
                <div class="col-9 d-flex justify-content-between" >
                    <button class="btn btn-secondary fs11 fw-bold" (click)="fnRechazarProd('cancelar')">
                        CANCELAR
                    </button>
                    <button class="btn btn-primary fs11 fw-bold" [hidden]="btnRechazo" (click)="fnguardarRechazoArtSolicitud()">
                        GUARDAR
                    </button>
                    <button class="btn btn-danger fs11 fw-bold"[hidden]="!btnRechazo" (click)="fnguardarRechazoSolicitud('rechazado')">
                        RECHAZAR
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="btnAutorizar">
          <button type="button" class="btn btn-secondary me-auto"[ngClass]="{'d-flex':!boolRechazarPro}" [hidden]="boolRechazarPro" data-bs-dismiss="modal">Cerrar</button>
            <button class="btn btn-outline-primary  align-items-center py-1 px-2" [ngClass]="{'d-flex':!boolRechazarPro && strEstatus== 'POR AUTORIZAR'}" [hidden]="boolRechazarPro || strEstatus!='POR AUTORIZAR'" (click)="fnguardarRechazoSolicitud('autorizado')"> 
                <i class="bi bi-check-lg me-2 fs-6"></i> <span class="fs13">ACEPTAR</span> 
            </button>
            <button class="btn btn-outline-danger ms-5  align-items-center py-1 px-2" [ngClass]="{'d-flex':!boolRechazarPro && strEstatus== 'POR AUTORIZAR'}" [hidden]="boolRechazarPro ||strEstatus!='POR AUTORIZAR'" (click)="fnRechazarSol('cancelSol')"> 
                <i class="bi bi-x me-1fs-6"></i> <span class="fs13">RECHAZAR</span> 
            </button>
        </div>
      </div>
    </div>
</div>




