<div class="container-fluid"><!-- scrollT2 #scrollContainer  -->
  <div class="row">
    <div class="col-12 px-0">
        <nav aria-label="breadcrumb" class="px-0 ">
            <ol class="breadcrumb d-flex align-items-center mb-0">
                <li class="breadcrumb-item"><a routerLink="/cotizador_Linea"  >Categorias</a></li>
                <li class="breadcrumb-item"><a routerLink="/cotizador_SubLinea"  >{{strLinea}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">Resultado de Búsqueda</li>
            </ol>
        </nav>
    </div>
</div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="page-container">
        <div class="page-title">
          <h1 class="fw-bold">{{  strTitulo }}</h1> <!-- Título centrado y vistoso -->
        </div>
        <div class="d-flex align-items-start">
          <div class="filter-section stickTop">
            <h2>Filtrar Sublineas</h2>
            <ul class="scrollT">
              <li class="fs13" *ngFor="let category of arraySubcategorias" [ngClass]="{'d-none':category.strGrupo =='' ||category.strGrupo ==undefined,'selectProd':category.strGrupo == strSubLinea}" (click)="filterByCategory(category.strGrupo)">
                {{category.strGrupo}}
              </li>
            </ul>
          </div>
          <div *ngIf="isButtonVisible" class="position-fixed bottom-0 end-0 mb-5 p-2 bg-white shadow borderrounstart d-flex align-items-start" (click)="scrollToTop()" >
            <button  id="scrollTopBtn"  class="btn selectProd rounded-circle shadow-sm d-flex" ><i class="bi bi-chevron-up icon-size"></i>
            </button>
          </div>
          
          <div class="product-section pt-0 ">
          
            <div class="row my-4" *ngIf="spiner">
              <div class="col-12 d-flex justify-content-center">
                  <mat-spinner  [strokeWidth]="6" [diameter]="70"  class=" rounded-circle custom-spinner"></mat-spinner>
              </div>
            </div>
            <div class="row" *ngIf="!spiner"  >
              <div class="col-12 d-flex  mb-3">
                <div class="col-12  bg-white py-2 rounded shadow-sm ">
                  <mat-form-field appearance="outline" class="rounded bg-light custom-form-field ms-2">
                    <mat-label>Buscar Articulo</mat-label>
                    <input [(ngModel)]="strbuscarLista" matInput placeholder="Clave">
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div  *ngFor="let product of arrayArticulos | filtergrupo:strSubLinea|filtersearch: strbuscarLista: ['strArticulo'];let ind = index" class="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center" >
                <div #partner id="partner" *ngIf="ind == 0; else contenidoAlternativo">
                  <div class="col-12  shadow-sm px-0 mb-4 rounded d-flex flex-column bg-white cardTrans pointer" (click)="addToCart(product)">
                    <div class="col-12 pt-2 mb-2 text-end me-1 ">  
                      <span class="clsBad fs14 fw-bold px-3 py-2 text-white borderRad"> {{ product.strArticulo }}</span>
                    </div>
                    <img [src]="product.imageUrl" alt="{{ product.strArticulo }}" class="w-100 rounded">
                   
                    <div class="col-12 descripcion fs12 px-2">
                      <span class="descripcion-truncada">{{ product.strDescripcion1 }}</span>
                    </div>
                    <div class="col-12 p-2 text-center mt-auto">
                      <button type="button" class="btn btn-outline-primary fs14 d-flex mx-auto"  (click)="addToCart(product)"><i class="bi bi-cart3 mx-1" ></i>Agregar <span class="d-none d-xxl-block">al Carrito</span> </button>
                    </div>
                  </div>
                </div>
                
                <ng-template #contenidoAlternativo>
                  <div class="col-12  shadow-sm px-0 mb-4 rounded d-flex flex-column bg-white cardTrans pointer" (click)="addToCart(product)">
                    <div class="col-12 pt-2 mb-2 text-end me-1 ">  
                      <span class="clsBad fs14 fw-bold px-3 py-2 text-white borderRad"> {{ product.strArticulo }}</span>
                    </div>
                    <img [src]="product.imageUrl" alt="{{ product.strArticulo }}" class="w-100 rounded">
                   
                    <div class="col-12 descripcion fs12 px-2">
                      <span class="descripcion-truncada">{{ product.strDescripcion1 }}</span>
                    </div>
                    <div class="col-12 p-2 text-center mt-auto">
                      <button type="button" class="btn btn-outline-primary fs14 d-flex mx-auto"  (click)="addToCart(product)"><i class="bi bi-cart3 mx-1" ></i>Agregar <span class="d-none d-xxl-block">al Carrito</span> </button>
                    </div>
                  </div>
                </ng-template>


                
               

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
