<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold titulo">Cotizaciones</h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow-sm pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
          <span class="material-symbols-outlined text-dark">
              content_paste_search
          </span>
          <span class="fw-bold text-dark">
              Filtros
          </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
            <mat-select [(ngModel)]="strGerente" class="" >
              <mat-option   value=""> --Todos --</mat-option> 
             <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
            <mat-select [(ngModel)]="strSucursal" class="" >
              <mat-option value=""> --Todas --</mat-option> 
             <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
            <mat-select [(ngModel)]="strAgente" class="" >
              <mat-option value=""> --Todos --</mat-option> 
             <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div> 
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
            <mat-select [(ngModel)]="intPeriodo" class="" >
             <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
              <mat-select [(ngModel)]="intEjercicio">
                  <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                      {{e.ejercicio}}
                  </mat-option> 
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
          <button class="btn btnFilter shadow-sm px-2 py-1"  (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false" (click)="fnextraercotizaciones()">
              <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}" ></i><!-- (click)="fnGetPlantillas()" -->
          </button>
        </div>
      </div>
    <div class="row my-4 justify-content-center">
        <div class="row bgtable rounded-top">
            <div class="col-6 col-md-4 my-2">
                <input type="text" class="form-control" [(ngModel)]="inputSearch" placeholder="Buscar: Cliente,Nombre">
            </div>
        </div>
        <div class="col-12 text-center">
            <div class="spinner-border text-secondary my-4" role="status" *ngIf="spinerTab">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="alert alert-danger my-3" role="alert" *ngIf="!spinerTab && arrayCotizaciones.length==0">
                Sin datos seleccione otro agente
            </div>
        </div>
        <div class="col-12 table-responsive mx-0 px-0 rounded-bottom border" *ngIf="!spinerTab && arrayCotizaciones.length>0">
            <table class="table table-bordered table-hover bg-white">
                <thead class="" >
                    <tr class="thcenter bgtable ">
                        <th>
                            <span>ID</span>
                        </th>
                        <th>
                            <span>Fecha</span>
                        </th>
                        <th>
                            <span>Tipo</span>
                        </th>
                        <th>
                            <span> Nombre</span>
                        </th>

                        <th>
                            <span>Cliente</span>
                        </th>
                        <th>
                            <span>Importe</span>
                        </th>
                        <th>
                            <span></span>
                        </th>
                        <th>
                            <span></span>
                        </th>
                       
                        <th>
                            <span></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let cot of arrayCotizaciones | filtersearch: inputSearch : ['strNombreCliente','strCliente']; let ind = index;">
                    <tr class="tdcenter" [class.table-hover]="!cot.mostrarDetalle">
                        <td>
                            <span class="fs12 fw-bold">
                                {{cot.intIDMovimientoVenta}}
                            </span>
                        </td>
                        <td>
                            <span class="text-nowrap fs12">
                                {{fechasComoDate(cot)|date}}
                            </span>
                        </td>
                        <td>
                            <span class="fs12">
                                {{cot.strTipo}}
                            </span>
                        </td>
                        <td>
                            <span class="fs12">
                                {{cot.strNombreCliente}}
                            </span>
                        </td>
                        <td>
                            <span class="fs12">
                                {{cot.strCliente}}
                            </span>
                        </td>
                        <td class="text-end">
                            <span class="fw-bold fs12">
                                {{cot.dblImporte|currency}}
                            </span>
                        </td>
                        <td>
                            <button class="btn btn-outline-primary fs11 p-1 shadow-sm" (click)="fgCotizador(cot)">Editar</button>
                        </td>
                        <td> <!-- (mouseenter)="cot.isHovered = true" (mouseleave)="cot.isHovered = false" [ngClass]="{'bi-trash3': !cot.isHovered, 'bi-trash3-fill': cot.isHovered}"-->
                            <i class="bi  mouseHov text-warning fs-5" (mouseenter)="cot.mostrarEye = true" (mouseleave)="cot.mostrarEye = false" [ngClass]="{'bi-eye': !cot.mostrarDetalle && !cot.mostrarEye, 'bi-eye-slash': cot.mostrarDetalle  && !cot.mostrarEye,'bi-eye-fill': !cot.mostrarDetalle && cot.mostrarEye, 'bi-eye-slash-fill': cot.mostrarDetalle  &&  cot.mostrarEye}" (click)="fnMostrarDetalleCotizacion(cot)"  ></i>
                        </td>
                        <td (mouseenter)="cot.isHovered = true" (mouseleave)="cot.isHovered = false">
                            <i class="bi   text-danger fs-5 mouseHov" (click)="fnEliminarCotizacion(cot)"  [ngClass]="{'bi-trash3': !cot.isHovered, 'bi-trash3-fill': cot.isHovered}"></i>
                        </td>
                    </tr>
                    <tr *ngIf="cot.mostrarDetalle" class="hovtable fadeInOut">
                        <td [attr.colspan]="9">
                        <div class="table-responsive theig">
                          <table class="table  table-bordered">
                            <thead class="tableInt">
                                <tr >
                                    <th class="text-center">Articulo</th>
                                    <th class="text-center">Descripción</th>
                                    <th class="text-center">Unidad</th>
                                    <th class="text-center">Cantidad</th>
                                    <th class="text-center">Precio</th>
                                </tr>
                            </thead>
                            <tbody >
                              <tr class="tdtext" *ngFor="let x of arrayDetalleArts; let ind = index;">
                                <td class="text-center">{{x.strArticulo}}</td>
                                <td class="text-center">{{x.strDescripcion}}</td>
                                <td class="text-center">{{x.strUnidad}}</td>
                                <td class="text-center">{{x.intCantidad}}</td>
                                <td class="text-center fw-bold">{{x.dblPrecio|currency}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        </td>
                      </tr>
                </ng-container>
                </tbody>
                <tfoot>

                </tfoot>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-12">

        </div>
    </div>
</div>