import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CarroService } from '../servicios/carroservice';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import 'pdfmake/build/vfs_fonts';
import { CurrencyPipe } from '@angular/common';
import * as html2pdf from 'html2pdf.js';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { MatSelectChange } from '@angular/material/select';
import { DataDBService } from '../servicios/data-db.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PartnercteService } from '../servicios/partnercte.service';
import { faL } from '@fortawesome/free-solid-svg-icons';

declare var Shepherd: any; 
@Component({
  selector: 'app-cotizador-partner',
  templateUrl: './cotizador-partner.component.html',
  styleUrls: ['./cotizador-partner.component.css']
})
export class CotizadorPartnerComponent implements OnInit {

  /**datos de cliente */
  public strCorreoUser: string = "";
  public strEmpresaProspecto:string = "";
  public strProstectro:string = "";
  public conp: boolean = false;
  public currentDate = new Date().toLocaleDateString();
  public flagProspecto: boolean = true;
  public strUrlLogo:string="";
    private unsubscribe$ = new Subject<void>();
    public strCliente:string="";
  /**fin de datos de cliente */
  
  @ViewChild('pdfTableFiniquito', { static: true }) el!: ElementRef;
  @ViewChild('btnPdf') btnPdf!: ElementRef;
  @ViewChild('inputPrecioSugerido') inputPrecioSugerido: ElementRef;
 public vartri: boolean = false;
 public arrayOpcionesTodo = [];
 public strNumCte:string='';
 public strConexion: string = "";
 public arrayClientes:any = [];
 public strUsuario:string='viviana';
 public objCliente:any = {};
 public arrayCarritoArts:any = [];
 public ctePartner:any = {};
 public objectCotizacion: any = {};
 public boolModoPlantilla: boolean;
 public dblDisponible_nacional:number=0;
 public dblReservado_nacional:number=0;
 public disponible:number =0;
 public arrayAccesos:any=[];
 public dblSubTotal = 0;  
 
 public data_empresa:any={};
 public objArtModal: any = {
  strArticulo: "", strDescripcion: "", strObservaciones: ""
}; 
  constructor(private routEs: ActivatedRoute,private servicioPartner:PartnercteService, private indexedDBService: DataDBService,
     private router: Router,private carroservice: CarroService, private localSt: LocalStorageService,private _httpService: PeticionesService) { 
    this.routEs.queryParams.subscribe(params => {
      let step = params['addstep']; // 'tour'
      if(step == "introCotizador"){

        setTimeout(() => {
          this.iniciarTour();
        }, 400)

      }else if(step == "finCarrito"){
        setTimeout(() => {
          this.finIntroTour();
        }, 400)
      }
      console.log('Viene del tour:', step);
    });
  }

  ngOnInit(): void {

    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      this.strConexion = this.localSt.retrieve('strConexion'); 

      let objPartner = this.arrayAccesos[0];
      this.strCliente = objPartner.strCliente
      this.fnGetLogo();
      this.fnGetInfo_empresa(this.strCliente);
      

    });
    this.servicioPartner.cteSeleccionado$.subscribe(valor => {
      this.ctePartner = valor;
      this.strNumCte = this.ctePartner && this.ctePartner.intID ? this.ctePartner.intID:"" 
      this.objCliente.strRazonSocial = this.ctePartner && this.ctePartner.strRazonSocial ? this.ctePartner.strRazonSocial:"" 
      this.objCliente.strNombre = this.ctePartner && this.ctePartner.strNombreComercial ? this.ctePartner.strNombreComercial:"" 
      this.objCliente.strContacto = this.ctePartner && this.ctePartner.strContacto1 ? this.ctePartner.strContacto1:"" 
      console.log('Recibido en Componente B:', this.ctePartner);
    });
    this.carroservice.arrayItems_Partner_cot$.subscribe((nuevoValor) => {
      this.arrayCarritoArts = nuevoValor; 
      this.fnValida_Precio_sugerido();
      this.fnGetOpcionTodo();

    });
    this.carroservice.dblSubTotalItems_Partner_Cot$.subscribe((nuevoValor) => {
      
      this.dblSubTotal = nuevoValor;
    });
  }
  fnValida_Precio_sugerido(){ 
    let count = 0;
    this.arrayCarritoArts.forEach(element =>{
    
     let precio =  (element.dblPrecioSugerido && element.dblPrecioSugerido  >0) && (element.dblPrecioSugerido  - element.dblPrecioLista)> 0? 1:0;
     count = count + precio;
    })

    if(count == this.arrayCarritoArts.length){
        return false;
    }
    else {
        return true;
    }
  }
  fnGetOpcionTodo() {
    let aaryOpciones = []
    this.arrayCarritoArts.forEach(element => {
      if (element.strTipoOpcion == 'Si' && (element.arrayOpciones.length == 0)) {
        let objop = { strArticulo: element.strArticulo }
        aaryOpciones.push(objop);
      }
    });
    if (aaryOpciones.length > 0) {
      let obj = {
        strConexion: this.strConexion,
        strAccion: 'getOpcionTodo',
        xmlArticulos: aaryOpciones
      }

      this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
        result => {
          this.arrayOpcionesTodo = result;
          this.arrayCarritoArts.forEach(element => {
            if (element.strTipoOpcion == 'Si' && (element.arrayOpciones.length == 0)) {
              element.arrayOpciones = this.arrayOpcionesTodo.filter(o => o.strArticulo == element.strArticulo);
              this.carroservice.updateArt(element);

            }
          });
        },
        error => {
          console.error('Error al consumir la API:', error);
          // Puedes manejar el error de alguna manera específica aquí
        });
    }

  }
  fnEligePrecio(precio: number, objArt: any, opcion: number) { 

  }

  fnbuttomGroup(objArt: any, tipo: string) {
    if (tipo == '+') {
      objArt.dblCantidad = objArt.dblCantidad + 1;
      objArt.intCantidad = objArt.dblCantidad;
       this.fnUpdateAmountCar(objArt)  
      //  this.fnArtspasarCant()
    } else {
      if (objArt.dblCantidad >= 2) {
        objArt.dblCantidad = objArt.dblCantidad - 1;
        objArt.intCantidad = objArt.dblCantidad;
         this.fnUpdateAmountCar(objArt)  
        //this.fnArtspasarCant()
      }
    }

    if(objArt.strEstatus== "DESCONTINUADO"){
      this.fnGetDisponible(objArt, event,"");
    }else{
      this.fnUpdateAmountCar(objArt)
    }
  }


  fnGetDisponible(obj:any,event: Event,tipo:string){
  
   /* const input = event.target as HTMLInputElement;
    let currentValue = input.value;
  
  
    this.strOpcion_Selected = obj.strOpcion
    this.arraySaldou =[] 
    let articulo = {
      strAccion: "getDisponible",  
      strConexion: this.strConexion,
      strArticulo:obj.strArticulo 
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => { 
          this.arraySaldou = result; 
          this.objDisponible_almacen = this.strOpcion_Selected !=''? this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen && o.strOpcion==this.strOpcion_Selected) :this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen);
          this.dblDisponible_nacional=0
          this.dblReservado_nacional=0
          let nacional_opcion =[]
          nacional_opcion = this.strOpcion_Selected !=''? this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) :this.arraySaldou;
          nacional_opcion.forEach(element => {
            let disponible=element && element.dblCantidad ?element.dblCantidad :0;
            let reservado =element && element.dblReservado ?element.dblReservado :0;
            this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
            this.dblReservado_nacional=this.dblReservado_nacional +reservado;
            this.fnFiltrarSaldou()
          });
          this.disponible = this.dblDisponible_nacional - this.dblReservado_nacional
          
  
          if(tipo == "input"){
            if (currentValue === '' || currentValue === '0'||currentValue === ' ' || currentValue.toLowerCase() === 'e' ) {
              input.value = '1';
              obj.dblCantidad = 1;
            } else {
              obj.dblCantidad = parseInt(currentValue, 10); 
            }
          }
        
  
          if(obj.dblCantidad <=this.disponible){
            obj.intCantidad = obj.dblCantidad;
            this.fnUpdateAmountCar(obj);
          }else{
            input.value = ""+this.disponible;
            obj.dblCantidad = this.disponible;
            this.fnUpdateAmountCar(obj);
          Swal.fire("Correción","Se coloco una cantidad disponible","info")
          }
          
      });*/
  }

  fnUpdateAmountCar(objArt: any) {
    this.carroservice.updateArt_partner_cot(objArt);
  }

  restrictInput(event: Event, obj: any): void {

    const input2 = event.target as HTMLInputElement;
    let currentValue = input2.value;
  
    let cantAnterior = obj.dblCantidad
  
    this.dblDisponible_nacional=0;
    this.dblReservado_nacional=0;
    this.disponible =0;
  
    if(obj.strEstatus== "DESCONTINUADO"){
      this.fnGetDisponible(obj, event,"input");
    }else{
      if (currentValue === '' || currentValue === '0'||currentValue === ' ' || currentValue.toLowerCase() === 'e' ) {
        input2.value = '1';
        obj.dblCantidad = 1;
      } else {
        obj.dblCantidad = parseInt(currentValue, 10); 
      }
      obj.intCantidad = obj.dblCantidad;
              this.fnUpdateAmountCar(obj);
    }
  
  
   
  }

  restric(event: KeyboardEvent): void {
    const key = event.key;
  
    // Permite solo números, backspace, tab, y flechas
    if (!/[0-9]/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
        event.preventDefault();
    }
  }
  fnDeleteCar(intId: number, objArt: any) {
    this.arrayCarritoArts.splice(intId, 1);
    this.carroservice.deleteArt_Partner_cot(objArt);
   // this.fnArtspasarCant()
  }  
  fnmodal(objArt: any, ind: number) {
    this.objArtModal.strArticulo = objArt.strArticulo;
    this.objArtModal.strDescripcion = objArt.strDescripcion1;
    this.objArtModal.indice = ind;
    this.objArtModal.strComentario =objArt.strComentario ? objArt.strComentario :'';
   
  }
  redirectToCarrito() {
    this.router.navigate(['/cotizador_Linea']);
  }
  fnborrarCarrito() {
   /* let objPlantilla = { boolActive: false, intIDMovimientoVenta: 0 }
    this.servPlantilla.setPlantilla(objPlantilla);
 

    this.carroservice.setModoPlantilla(false);*/
    this.carroservice.deleteCarrito_partner_cot();
 
  }

  fnTotal(): number {
    let result = 0;
    result = this.fnSubTotal() + this.fnSubiva();
    return result;
  }
  fnSubTotal(): number {
    let result = 0;
    result = this.dblSubTotal;
    return result;
  }

  fnSubiva(): number {
    let result = 0;
    if (this.strConexion == "DOMINICANA") {
      result = this.fnSubTotal() * .18;
    } else {
      result = this.fnSubTotal() * .16;
    }

    return result; 
  }

    fnGuardarCotizacion() {
      let xml=[]
      this.arrayCarritoArts.forEach(art => {
        xml.push({
          strArticulo:art.strArticulo,
          strOpcion:art.strTipoOpcion,
          dblCantidad:art.dblCantidad,
          dblPrecio:art.dblPrecioLista,
          dblSugerido:art.dblPrecioSugerido,
          strObservaciones:art.strComentario,
          dblImporte:art.dblPrecioSugerido * art.dblCantidad
        })
      });
   
      this.objectCotizacion = {
        strConexion: this.strConexion,
        strAccion: "insertCotizacion",
        strUsuario:this.ctePartner.strCliente,
        strAgente:"",
        strSucursal:"",
        strCliente:this.ctePartner.intID,
        strNombreCliente:this.ctePartner.strRazonSocial,
        strOrdenCompra:"",
        strComentarios:"",
        strMoneda:"PESOS",
        strTipoMov:"COTIZACION",
        strEstatus:"POR AUTORIZAR",
        xmlArticulos:xml

 }
      
      this._httpService.mostrarCotizaciones(this.objectCotizacion, 'sp_PartNer')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => { 
          console.log(result);
          let res = result[0]
          if(res && res.code == "200"){
            Swal.fire({
              title: 'Guardado correctamente!',
              text: res.strMensaje,
              icon: 'success',
              timer: 2000, 
              timerProgressBar: true,
              showConfirmButton: false
            });
            this.fnborrarCarrito();
          }else{
            Swal.fire("Error!",res.strMensaje,"error")
          }
      });
    }
      fncreatepdf() {
        const options = {
          margin: 8,
          filename: 'Cotizacion.pdf',
          image: { type: 'JPEG', quality: 0.98 },
          html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        const content = this.el.nativeElement;
        html2pdf()
          .from(content)
          .set(options)
          .toPdf()
          .get('pdf')
          .then(pdf => {
           
           const blob = pdf.output('blob');
           const url = URL.createObjectURL(blob); // Crea un objeto URL
             pdf.save("Cotizacion.pdf");
           window.open(url); // Abre el PDF en una nueva pestaña
           
          });
    
      }

      formatCurrency(objArt:any) {
        objArt.dblPrecioLista = Number(objArt.dblPrecioLista); 
      }
      
      updateValue(event: any, objArt:any) {
        const value = event.target.value.replace(/[^0-9.]/g, ''); // Quita caracteres no numéricos
        objArt.dblPrecioLista = value ? parseFloat(value) : 0;
       
      }
      updateValue_sugerido(event: any, objArt:any) {
        let rawValue = event.target.value;

        // Eliminamos el símbolo de pesos y cualquier carácter no deseado excepto números y punto
        rawValue = rawValue.replace(/[^0-9.]/g, '');
      
        // Dividimos en parte entera y decimal
        const parts = rawValue.split('.');
      
        // Si hay más de un punto decimal, salir
        if (parts.length > 2) {
          return;
        }
      
        // Limitamos la parte decimal a 2 dígitos
        if (parts.length === 2) {
          parts[1] = parts[1].substring(0, 2); // Solo dejamos 2 dígitos decimales
          rawValue = parts[0] + '.' + parts[1];
        }
      
        // Convertimos a número (evita NaN si está vacío)
        objArt.dblPrecioSugerido = rawValue ? parseFloat(rawValue) : 0;
      
        // Mostramos en el input el valor con el símbolo de pesos
        event.target.value = rawValue ? '$' + rawValue : '';
      
        this.carroservice.updateArt_partner_cot(objArt);
        /* const value = event.target.value.replace(/[^0-9.]/g, ''); // Quita caracteres no numéricos
        objArt.dblPrecioSugerido =0;
        objArt.dblPrecioSugerido = value ? parseFloat(value) : 0;
        console.log(value)
        this.carroservice.updateArt_partner_cot(objArt); */
      }

      getNombreOpcion(x: any): string {
        const opcionEncontrada = x.arrayOpciones.find(opcion => opcion.strOpcion === x.strOpcion);
        return opcionEncontrada ? opcionEncontrada.strNombreOpcion : '';
      }

      fnGetLogo(){
        let objEnv = {
                strAccion: 'getLogoPartner',
                strCliente:this.strCliente,
                strConexion: this.strConexion
        } 
        this._httpService.mostrarCotizaciones(objEnv, 'sp_PartNer')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => { 
             let rest = result[0];
             this.strUrlLogo = rest.code ==200?"https://www.ecodeli.com/"+rest.strUrl:"assets/partner/ilus2.jpg"
        });
      }
       fnGetInfo_empresa(cte:string) {
        return new Promise((resolve) => {
          this._httpService.mostrarCotizaciones({
            strAccion: 'getCliente',
            strUsuario:cte,
            strConexion: this.strConexion
          }, 'sp_PartNer').subscribe(
            result => {
              console.log(result)
              this.data_empresa = result[0]
            
            }
          );
        });
        }

        
  iniciarTour() {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: { enabled: true },
        classes: 'shadow-md bg-white rounded custom-tooltip',
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 8,
       
      },
      useModalOverlay: true  // 👈 Esto activa el fondo oscuro
    });
  
 
    tour.addStep({
      id: 'btnContinuarCompra',
      text: 'Se selecciona el boton continuar comprando para ir a los articulos.',
      attachTo: {
        element: '#btnContinuarCompra',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Siguiente',
          action: () => {
            tour.hide(); 
            this.router.navigate(['/cotizador_Linea'], {
              queryParams: { addstep:"linea" }
            });
          }
        }
      ]
    });
  
    // Iniciar el tour
    tour.start();
  }

  finIntroTour() {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: { enabled: true },
        classes: 'shadow-md bg-white rounded custom-tooltip',
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 8,
       
      },
      useModalOverlay: true  // 👈 Esto activa el fondo oscuro
    });
  
   // Paso 2 - Input de búsqueda
   tour.addStep({
    id: 'inputCosto',
    text: 'Aquí se visualiza el precio establecido por la empresa.',
    attachTo: {
      element: '#inputCosto',
      on: 'bottom'
    },
    buttons: [
      {
        text: 'Anterior',
        action: tour.back
      },
      {
        text: 'Siguiente',
        action: tour.next
      }
    ]
  });

  // Paso 3 - Botón Agregar Cliente
  tour.addStep({
    id: 'inputPrecioSug',
    text: 'aquí se coloca un precio sugerido mayor al precio establecido por la empresa.',
   
    attachTo: {
      element: '#inputPrecioSug',
      on: 'bottom'
    },
    when: {
      show: () => {
        this.inputPrecioSugerido.nativeElement.value = '$800';
        this.arrayCarritoArts[0].dblPrecioSugerido = 800
      }
    },
    buttons: [
      {
        text: 'Anterior',
        action: tour.back
      },
      {
        text: 'Siguiente',
        action: tour.next
      }
    ]
  });
  tour.addStep({
    id: 'inputGanancia',
    text: 'Aquí podrás visualizar tus ganancias apartir del precio que has sugerido.',
    attachTo: {
      element: '#inputGanancia',
      on: 'bottom'
    },
    buttons: [
      {
        text: 'Anterior',
        action: tour.back
      },
      {
        text: 'Siguiente',
        action: tour.next
      }
    ]
  }); 
  tour.addStep({
    id: 'btnCotizacion',
    text: 'Da click en este botón, si deseas guardar tu cotización, para visualizarla posteriormente',
    attachTo: {
      element: '#btnCotizacion',
      on: 'bottom'
    },
    buttons: [
      {
        text: 'Anterior',
        action: tour.back
      },
      {
        text: 'Siguiente',
        action: tour.next
      }
    ]
  });

  tour.addStep({
    id: 'btnPdf',
    text: 'Si deseas generar el PDF  de tu cotización, da click en este botón',
    attachTo: {
      element: '#btnPdf',
      on: 'bottom'
    },
    buttons: [
      {
        text: 'Anterior',
        action: tour.back
      },
      {
        text: 'Siguiente',
        action: tour.complete
      }
    ,
    ]
  });

  tour.on('complete', () => {
    this.btnPdf.nativeElement.click();
    this.fnborrarCarrito();
  });
  
  
    // Iniciar el tour
    tour.start();
  }

}
