<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="text-center fw-bold nunito my-3">Presupuesto vs Venta</h1>
        </div>
    </div>
    <div class="row" [hidden]="!spinner">
      <div class="col-12 d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="row mb-5" [hidden]="spinner">
      <div class="col-12 d-flex mb-3 align-items-center">
        <div class="mt-2 pt-1 col-5 col-lg-3  d-flex justify-content-center">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
              <mat-select [(ngModel)]="intEjercicio" (selectionChange)="fnFiltrar()">
                <mat-option value="0">--Todos--</mat-option>
                  <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                      {{e.ejercicio}}
                  </mat-option> 
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-1">
        </div>
        <div class=" mt-2 pt-1 col-5 col-lg-3 d-flex justify-content-center">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
            <mat-select [(ngModel)]="intPeriodo" class="" (selectionChange)="fnFiltrar()">
             <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-1">

        </div>
        <div class="col-2">
          <button class="btn btn-outline-success d-flex align-items-center py-1 px-2"  (click)="fnDescargarExcel()">
            <i class="bi bi-file-earmark-excel me-2 fs14"></i> <span class="fs11 d-flex align-items-center">Descargar Excel</span> 
          </button>
        </div>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline" class="bg-white rounded my-3">
          <mat-label>Buscar:</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
        </mat-form-field>
      </div>
        <div class="col-12 ">
            <div class="table-responsive mb-0">
                <table mat-table [dataSource]="dataSource"  matSort class="mat-elevation-z8 w-100 table table-bordered">
              
                  <!-- Cliente -->
                  <ng-container matColumnDef="strCliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0"> Cliente </th>
                    <td mat-cell *matCellDef="let element" class="ps-0 text-center txtPrincipal align-middle fw-bold"> {{ element.strCliente }} </td>
                  </ng-container>

                  <!-- Movimiento ID -->
                  <ng-container matColumnDef="strMovIDP">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                    <td mat-cell *matCellDef="let element" class="text-center fs12 align-middle">  <span class="fw-bold">({{ element.strMovIDP }})</span>  {{element.strOrdenCompraP}}</td>
                  </ng-container>
              
                  <!-- Importe Factura -->
                  <ng-container matColumnDef="dblImporteFactura">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Con Factor </th>
                    <td mat-cell *matCellDef="let element" class="text-end pe-2 align-middle">
                      <span class="fw-bold" [ngClass]="{' text-success ':element.dblImporteFactura<=element.dblImporteF,' text-danger':element.dblImporteFactura>element.dblImporteF}">
                          {{ element.dblImporteFactura | currency }}<i class="ms-1 bi " [ngClass]="{' bi-check-circle-fill text-success ':element.dblImporteFactura<=element.dblImporteF,' bi-x-circle-fill text-danger':element.dblImporteFactura>element.dblImporteF}"></i>
                      </span>
                    </td>
                  </ng-container>
              
                  <!-- Importe Final -->
                  <ng-container matColumnDef="dblImporteF">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0"> Presupuesto </th>
                    <td mat-cell *matCellDef="let element" class="pe-0 text-end pe-2 align-middle fw-bold">
                        {{ element.dblImporteF | currency }}
                    </td>
                  </ng-container>
              
                  <!-- Ejercicio -->
                  <ng-container matColumnDef="intEjercicio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ejercicio </th>
                    <td mat-cell *matCellDef="let element" class="text-center align-middle"> {{ element.intEjercicio }} </td>
                  </ng-container>
              
                  <!-- Período -->
                  <ng-container matColumnDef="intPeriodo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Período </th>
                    <td mat-cell *matCellDef="let element" class="text-center align-middle"> {{ element.strPeriodo }} </td>
                  </ng-container>
              
                  <!-- Encabezado -->
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              
                </table>
              
              </div>
              
        </div>
        <div class="col-12">
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>