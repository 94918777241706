import { Component, OnInit ,ElementRef} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';
@Component({
  selector: 'app-objmix-gte-sucursal',
  templateUrl: './objmix-gte-sucursal.component.html',
  styleUrls: ['./objmix-gte-sucursal.component.css']
})
export class ObjmixGteSucursalComponent implements OnInit {
  public objetivoPromedioAnterior:number=0;
  public objetivoPromedio:number=0;
  public dblTEnero:number=0;
  public dblTFebrero:number=0;
  public dblTMarzo:number=0;
  public dblTAbril:number=0;
  public dblTMayo:number=0;
  public dblTJunio:number=0;
  public dblTJulio:number=0;
  public dblTAgosto:number=0;
  public dblTSeptiembre:number=0;
  public dblTOctubre:number=0;
  public dblTNoviembre:number=0;
  public dblTDiciembre:number=0;
  public dblTSEnero:number=0;
  public dblTSFebrero:number=0;
  public dblTSMarzo:number=0;
  public dblTSAbril:number=0;
  public dblTSMayo:number=0;
  public dblTSJunio:number=0;
  public dblTSJulio:number=0;
  public dblTSAgosto:number=0;
  public dblTSSeptiembre:number=0;
  public dblTSOctubre:number=0;
  public dblTSNoviembre:number=0;
  public dblTSDiciembre:number=0;
  public arrayAgentes:any=[];
  public tagName:any;
  public btnGuardarD:any=true;
  public strEstatusS:string='';
  public strEstatus:string='';
  public strEstatusActual:string='';
  public arrayHistorico:any=[];
  public arrayAccesos:any=[];

  public dblObjetivoA:number=0;
 
  public btnAutorizar:any=false;
  public btnRechazar:any=false;
  public lstSucursales:any=false;
  public dblObjetivoD:number=0;
  public dblObjetivoG:number=0;
  public strGerente:string='';
  public arrayObjetivosAgentes:any=[];
  public strObservaciones:string='';
  public strSucursal:string='';
  public agenteSelected:any={};
  public dblObjetivoAgente:any=0;
  public arrayObjetivoCapturadoSuc:any=[];
  public dblObjetivoC :number=0;
  public dblObjetivoAS :number=0;
  public dblObjetivoSucursal:number=0;
  public strObservacionesS:number=0;
  public arraySucursales:any=[];
  public sucursalSelected:any='';
  public arrayTotalObj:any;
  public arrayEstatusAgentes:any;
  public totalAgentesAut:number=0;
  public totalAgentes:number=0;
  public dblObjetivoAgentesTotal:number=0;
  public dblObjetivoActAgt:number=0;
  public dblObjetivoPS:number=0;
  public dblObjetivoPSA:number=0;
  public arrayObjetivosAux:any= [{strLinea:'ACCESORIOS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'BLUE TISSUE',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'DESPACHADORES ECODELI',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'KIMBERLY',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'OTROS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'QUIMICOS',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'RUBBERMAID',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'TORK',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0},
  {strLinea:'XIBU',dblObjetivo:0, dblMontoSug:0,dblPsugerido:0}
 ];
 public arrayEncabezado:any=[];
 public arrayEncabezadoAgt:any=[];
 public today:any = new Date();
 public tabSelected:any='';
 public strEstatusAgente: string="";

  constructor(private indexedDBService: DataDBService,elem:ElementRef,private _peticionesService: PeticionesService,public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router) { 
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayTotalObj=[];
    this.arrayEstatusAgentes=[];
  }

   

  ngOnInit(): void {   
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == 'app-objmixgs');
    
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }
      else { 
        this.strSucursal= objAcceso[0].sucursal;
        this.btnAutorizar=objAcceso.find((o:any)=>o.strAccion=='Autorizar')?true:false;
        this.btnRechazar=objAcceso.find((o:any)=>o.strAccion=='Rechazar')?true:false;
        this.lstSucursales=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
       
        if(this.lstSucursales){
          this.fnGetSucursales();
        }
        else {
          this.fnGetHistorialActual();
        //  this.fnGetHistorial();
          this.fnGetAgentes();
     
          this.fnGetTotalObjetivosActual();
          this.fnGetTotalEstatusActual();

          this.fnGetObjetivoCapturado('actual'); 
        }
      }
    }
    })
    

    
  }
  yourFn($event:any){
    this.tabSelected=$event.index;
   // this.dblObjetivoA=0;
    this.dblObjetivoG=0;
    this.dblObjetivoD=0;
    if($event.index==0){
      this.fnGetHistorialActual();
      
      this.fnGetTotalObjetivosActual();
      this.fnGetTotalEstatusActual();
      this.fnGetObjetivoCapturado('actual'); 
    } 
    else if($event.index==1){
      this.fnGetHistorial();
      this.fnGetTotalEstatus();
      this.fnGetObjetivoCapturado('siguiente');
      
      //this.fnGetTotalObjetivos();
    } 
    else if($event.index==2){ 
       this.fnGetObjetivos(); 
       this.fnGetTotalObjetivos();
       this.fnGetObjetivoCapturado('siguiente');
    } 
}
  fnGetAgentes(){
    this._peticionesService.getApiCompras({
      strAccion: 'getAgentes' ,
      strSucursal:this.strSucursal 
    }).subscribe(
    result => {  
      this.arrayAgentes=result;
      console.log(this.arrayAgentes);
    });  
  }

  fnGetSucursales(){
    /* let gte = this.strSucursal=='7'?'gleal':this.strGerente=this.arrayAccesos[0].strUsuario;     
     this.strSucursal=='58'?'crodriguez': this.strSucursal=='22'?'gleal':this.strGerente;*/
     let gte =this.strGerente=this.arrayAccesos[0].strUsuario;
     console.log(gte);
    this._peticionesService.getApiCompras({
      strAccion: 'getSucursales' ,
      strGerente: gte==''? this.strGerente:gte//no modificar, acceso solo para korina y adelgado
    }).subscribe(
      result => {  
        this.arraySucursales=result; 
        console.log('hola')
        console.log(result)
        //this.fnGetAgentes();
      }
    );
  }
 
  
  fnFiltrarSuc(){
    this.dblObjetivoG=0;
    this.strSucursal=this.lstSucursales==true?this.sucursalSelected.strSucursal:this.strSucursal;//Valida si tiene acceso al filtro de sucursales, entonces que tome la sucursal seleccionada, si no que tome la que trae en sesion
    console.log(this.strSucursal);
     
    this.fnGetAgentes(); 
    if(this.tabSelected==0){
      this.fnGetHistorialActual();
      this.fnGetTotalObjetivosActual();
      this.fnGetTotalEstatusActual();
      this.fnGetObjetivoCapturado('actual');
    } 
    else if(this.tabSelected==1){
      this.fnGetHistorial();
      this.fnGetTotalEstatus();
      this.fnGetTotalObjetivos();
      this.fnGetObjetivoCapturado('siguiente');
      this.fnGetObjAgt();
    } 
  }
  fnGetHistorialActual(){    
    console.log(this.today.getMonth())
    this._peticionesService.getApiCompras({
      strAccion: 'getHistSucursal' ,
      strSucursal:this.strSucursal,
      intPeriodo :this.today.getMonth()+1, //da 8, más 2 para obtener el siguiente mes
      intEjercicio :this.today.getFullYear()
    }).subscribe(
      result => { 
        console.log(result);
          this.arrayHistorico=result;
          this.arrayHistorico.forEach((elemento:any)=> {  
            elemento.dblObjetivo = this.dblObjetivoA*elemento.dblPAgente;
            this.dblTEnero= this.dblTEnero+elemento.dblEnero;
            this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
            this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
            this.dblTAbril= this.dblTAbril+elemento.dblAbril;
            this.dblTMayo= this.dblTMayo+elemento.dblMayo;
            this.dblTJunio= this.dblTJunio+elemento.dblJunio;
            this.dblTJulio= this.dblTJulio+elemento.dblJulio;
            this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
            this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
            this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
            this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
            this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre;            
          });
          this.arrayHistorico.forEach((elemento:any)=>{
            let objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Enero');
            elemento.dblEnero && objAux==undefined? this.arrayEncabezado.push({strMes:'Enero', total:this.dblTEnero}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Febrero');
            elemento.dblFebrero && objAux==undefined? this.arrayEncabezado.push({strMes:'Febrero',total:this.dblTFebrero}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Marzo');
            elemento.dblMarzo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Marzo',total:this.dblTMarzo}):0;
  
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Abril');
            elemento.dblAbril&& objAux==undefined? this.arrayEncabezado.push({strMes:'Abril',total:this.dblTAbril}):0;
  
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Mayo');
            elemento.dblMayo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Mayo', total:this.dblTMayo}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Junio');
            elemento.dblJunio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Junio',total:this.dblTJunio}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Julio');
            elemento.dblJulio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Julio',total:this.dblTJulio}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Agosto');
            elemento.dblAgosto&& objAux==undefined? this.arrayEncabezado.push({strMes:'Agosto',total:this.dblTAgosto}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Septiembre');
            elemento.dblSeptiembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Septiembre',total:this.dblTSeptiembre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Octubre');
            elemento.dblOctubre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Octubre',total:this.dblTOctubre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Noviembre');
            elemento.dblNoviembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Noviembre',total:this.dblTNoviembre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Diciembre');
            elemento.dblDiciembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Diciembre',total:this.dblTDiciembre}):0;
          })
          this.fnGetObjetivosActual();
          this.fnGetPromedioVta_Sucursal('actual');
      });
  }

  fnGetHistorial(){    
    console.log('entra a sig historial')
    this.arrayHistorico=[];
    this.arrayEncabezado=[];
    let periodo = this.today.getMonth() +2  ;
    let ejercicio = this.today.getFullYear();
    let obj = {
      strAccion: 'getHistSucursal' ,
      strSucursal:this.strSucursal,
      intPeriodo :periodo==13?1:periodo, //da 8, más 2 para obtener el siguiente mes
      intEjercicio :periodo==13?ejercicio+1:ejercicio,
    };
    this._peticionesService.getApiCompras(obj).subscribe(
      result => { 
        console.log(result);
          this.arrayHistorico=result;
          this.arrayHistorico.forEach((elemento:any)=> {  
            elemento.dblObjetivo = this.dblObjetivoA*elemento.dblPAgente;
            this.dblTEnero= this.dblTEnero+elemento.dblEnero;
            this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
            this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
            this.dblTAbril= this.dblTAbril+elemento.dblAbril;
            this.dblTMayo= this.dblTMayo+elemento.dblMayo;
            this.dblTJunio= this.dblTJunio+elemento.dblJunio;
            this.dblTJulio= this.dblTJulio+elemento.dblJulio;
            this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
            this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
            this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
            this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
            this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre;            
          });
          this.arrayHistorico.forEach((elemento:any)=>{
            let objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Enero');
            elemento.dblEnero && objAux==undefined? this.arrayEncabezado.push({strMes:'Enero', total:this.dblTEnero}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Febrero');
            elemento.dblFebrero && objAux==undefined? this.arrayEncabezado.push({strMes:'Febrero',total:this.dblTFebrero}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Marzo');
            elemento.dblMarzo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Marzo',total:this.dblTMarzo}):0;
  
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Abril');
            elemento.dblAbril&& objAux==undefined? this.arrayEncabezado.push({strMes:'Abril',total:this.dblTAbril}):0;
  
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Mayo');
            elemento.dblMayo&& objAux==undefined? this.arrayEncabezado.push({strMes:'Mayo', total:this.dblTMayo}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Junio');
            elemento.dblJunio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Junio',total:this.dblTJunio}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Julio');
            elemento.dblJulio&& objAux==undefined? this.arrayEncabezado.push({strMes:'Julio',total:this.dblTJulio}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Agosto');
            elemento.dblAgosto&& objAux==undefined? this.arrayEncabezado.push({strMes:'Agosto',total:this.dblTAgosto}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Septiembre');
            elemento.dblSeptiembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Septiembre',total:this.dblTSeptiembre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Octubre');
            elemento.dblOctubre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Octubre',total:this.dblTOctubre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Noviembre');
            elemento.dblNoviembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Noviembre',total:this.dblTNoviembre}):0;
            objAux = this.arrayEncabezado.find ((o: { strMes: string; }) =>o.strMes=='Diciembre');
            elemento.dblDiciembre&& objAux==undefined? this.arrayEncabezado.push({strMes:'Diciembre',total:this.dblTDiciembre}):0;
          })
          this.fnGetObjetivos();
          this.fnGetPromedioVta_Sucursal('siguiente');
      });
  }

  fnGetObjetivos(){
    let periodo = this.today.getMonth()+2  ;
    let ejercicio = this.today.getFullYear();
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivoSucursal' ,
      strSucursal:this.strSucursal,
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
    }).subscribe(
      result => { 
        this.strEstatus=""; 
        //this.dblObjetivoA=0;
        this.strObservaciones="";
        if(result.length >0){
          let objA =result.find((o: { strLinea: string; }) =>o.strLinea=='OBJETIVO DIVISIONAL');
          this.strEstatus=objA.strEstatus; 
          //this.dblObjetivoA=objA.dblObjetivo;
          this.strObservaciones=objA?objA.strGerente:'';
        }
        this.arrayHistorico.forEach((elemento:any)=> {  
          let objA =result.find((o: { strLinea: string; }) =>o.strLinea==elemento.strLinea);
           elemento.dblObjetivo=objA?objA.dblObjetivo:this.dblObjetivoA*elemento.dblPAgente;         
           this.dblObjetivoG=this.dblObjetivoG+ ( objA?objA.dblObjetivo:elemento.dblObjetivo);
         });
         this.dblObjetivoD=this.dblObjetivoA-this.dblObjetivoG;
         this.btnGuardarD= (this.strEstatus!='AUTORIZADO')?false:true;
         
      });
  }
  fnGetTotalObjetivos(){
    console.log(this.strSucursal);
    let periodo =  this.today.getMonth()+2;
    let ejercicio = this.today.getFullYear();

    this.dblObjetivoAgentesTotal=0;
    this._peticionesService.getApiCompras({
      strAccion: 'getTotalObjetivosSucursal',
       strSucursal: this.strSucursal ,
       intPeriodo:periodo==13?1:periodo,
       intEjercicio:periodo==13?ejercicio +1:ejercicio    
    }).subscribe(
      result => {  
        this.arrayTotalObj=result;
        this.arrayTotalObj.forEach((elemento:any)=> {
          this.dblObjetivoAgentesTotal = this.dblObjetivoAgentesTotal +parseFloat(elemento.dblObjetivo);
        });
        console.log(result);
      });
  }
  fnGetObjetivosActual(){
    console.log(this.today.getMonth()+1);
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivoSucursalActual' ,
      strSucursal:this.strSucursal,
      intPeriodo:this.today.getMonth()+1
    }).subscribe(
      result => { 
        this.strEstatusActual=""; 
        //this.dblObjetivoA=0;
        this.strObservaciones="";
        if(result.length >0){
          let objA =result.find((o: { strLinea: string; }) =>o.strLinea=='OBJETIVO DIVISIONAL');
          this.strEstatusActual=objA.strEstatus; 
         // this.dblObjetivoA=objA.dblObjetivo;
          this.strObservaciones=objA?objA.strGerente:'';
        }
        this.arrayHistorico.forEach((elemento:any)=> {  
          let objA =result.find((o: { strLinea: string; }) =>o.strLinea==elemento.strLinea);
           elemento.dblObjetivo=objA?objA.dblObjetivo:this.dblObjetivoA*elemento.dblPAgente;         
           this.dblObjetivoG=this.dblObjetivoG+ ( objA?objA.dblObjetivo:elemento.dblObjetivo);
         });
         this.dblObjetivoD=this.dblObjetivoA-this.dblObjetivoG;
        // this.btnGuardarD= (this.strEstatusActual!='AUTORIZADO')?false:true;
      });
  }
/**************************nuevo *****************/
  fnGetObjetivoCapturado(tipo:string){
    this.dblObjetivoA=0;
    this._peticionesService.getApiCompras({
      strAccion: 'getObjetivo_Capturado_Sucursal' ,
      strSucursal:this.strSucursal,
      strTipo:tipo
    }).subscribe(
      result => { 
        this.dblObjetivoA=result[0].dblObjetivo? result[0].dblObjetivo:0;
        console.log(result);
      });
  }
  fnGetPromedioVta(tipo:string){
   // this.objetivoPromedio=0; revisar aqui
    let periodo = this.today.getMonth()+1  ;
    let ejercicio = periodo <4?this.today.getFullYear()-1:this.today.getFullYear();
    let xenv = {
      strAccion: 'getPromedioVta' ,
      strAgente:this.agenteSelected.strEstatus,
      strTipo:tipo
    }
    console.log(xenv)
    this.dblObjetivoA=0;
    this._peticionesService.getApiCompras(xenv).subscribe(
      result => {  
        console.log(result)
        let arrayR =result;
        let tipo='Último  trimestre '+ejercicio;
        let objResult = arrayR.find(o=>o.strTipo ==tipo);
        if(objResult){
        this.objetivoPromedio =  (objResult.strAccesorios?parseFloat(objResult.strAccesorios):0);
        this.objetivoPromedio = this.objetivoPromedio  + (objResult.strBlue?parseFloat(objResult.strBlue):0);
        this.objetivoPromedio = this.objetivoPromedio    + ( objResult.strDegrapack?parseFloat(objResult.strDegrapack):0);  
        this.objetivoPromedio = this.objetivoPromedio   +(objResult.strDespachadores?parseFloat(objResult.strDespachadores):0);
        this.objetivoPromedio = this.objetivoPromedio   + (objResult.strFoaom?parseFloat(objResult.strFoaom):0);
        this.objetivoPromedio = this.objetivoPromedio  + (objResult.strKimberly?parseFloat(objResult.strKimberly):0);
        this.objetivoPromedio = this.objetivoPromedio  + (objResult.strQuimicos?parseFloat(objResult.strQuimicos):0);
        this.objetivoPromedio = this.objetivoPromedio  +(objResult.strRubbermaid?parseFloat(objResult.strRubbermaid):0);
        this.objetivoPromedio = this.objetivoPromedio  + (objResult.strTork?parseFloat(objResult.strTork):0 ) ; 

        this.objetivoPromedio = this.objetivoPromedio  + (objResult.strDove?parseFloat(objResult.strDove):0 ) ; 
        this.objetivoPromedio = this.objetivoPromedio  + (objResult.strTapetes?parseFloat(objResult.strTapetes):0 ) ; 
        }
        else {
          objResult={};
        }
       
        let tipo2='Último  trimestre '+(ejercicio-1);
        let objResult2 = arrayR.find(o=>o.strTipo ==tipo2);
        if(objResult2){
        this.objetivoPromedioAnterior =  (objResult2 && objResult2.strAccesorios?parseFloat(objResult2.strAccesorios):0);
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior  + (objResult2 && objResult2.strBlue?parseFloat(objResult2.strBlue):0);
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior    + ( objResult2 && objResult2.strDegrapack?parseFloat(objResult2.strDegrapack):0);  
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior   +(objResult2 && objResult2.strDespachadores?parseFloat(objResult2.strDespachadores):0);
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior   + (objResult2 && objResult2.strFoaom?parseFloat(objResult2.strFoaom):0);
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior  + (objResult2 && objResult2.strKimberly?parseFloat(objResult2.strKimberly):0);
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior  + (objResult2 && objResult2.strQuimicos?parseFloat(objResult2.strQuimicos):0);
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior  +(objResult2 && objResult2.strRubbermaid?parseFloat(objResult2.strRubbermaid):0);
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior  + (objResult2 && objResult2.strTork?parseFloat(objResult2.strTork):0 ) ; 

        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior  + (objResult2 && objResult2.strDove?parseFloat(objResult2.strDove):0 ) ; 
        this.objetivoPromedioAnterior = this.objetivoPromedioAnterior  + (objResult2 && objResult2.strTapetes?parseFloat(objResult2.strTapetes):0 ) ; 
        }
        else {
          objResult2={};
        }
       


        this.arrayObjetivoCapturadoSuc.forEach((elemento:any)=> { 
          console.log(elemento.strLinea);
          elemento.promedioAct = (elemento && elemento.strLinea=='ACCESORIOS')? parseFloat(objResult.strAccesorios)   : 
          elemento && elemento.strLinea=='BLUE TISSUE'? parseFloat(objResult.strBlue) :
          elemento && elemento.strLinea=='DEGRAPACK'?   parseFloat(objResult.strDegrapack) :
          elemento &&  elemento.strLinea=='DESPACHADORES ECODELI'?  parseFloat(objResult.strDespachadores) :
          elemento &&  elemento.strLinea=='DOVE'?  parseFloat(objResult.strDove) :
          elemento &&  elemento.strLinea=='FOAOM'?  parseFloat(objResult.strFoaom) :
          elemento &&  elemento.strLinea=='KIMBERLY'?  parseFloat(objResult.strKimberly) :
          elemento && elemento.strLinea=='QUIMICOS'?  parseFloat(objResult.strQuimicos) :
          elemento && elemento.strLinea=='RUBBERMAID'?  parseFloat(objResult.strRubbermaid) :
          elemento && elemento.strLinea=='TAPETES'?  parseFloat(objResult.strTapetes) :
          elemento && elemento.strLinea=='TORK'? parseFloat(objResult.strTork) :0;

          if (objResult2){
          elemento.promedioAnt = elemento && elemento.strLinea=='ACCESORIOS'? parseFloat(objResult2.strAccesorios)   : 
          elemento &&  elemento.strLinea=='BLUE TISSUE'? parseFloat(objResult2.strBlue) :
          elemento && elemento.strLinea=='DEGRAPACK'?   parseFloat(objResult2.strDegrapack) :
          elemento &&  elemento.strLinea=='DESPACHADORES ECODELI'?  parseFloat(objResult2.strDespachadores) :
          elemento &&  elemento.strLinea=='DOVE'?  parseFloat(objResult2.strDove) :
          elemento && elemento.strLinea=='FOAOM'?  parseFloat(objResult2.strFoaom) :
          elemento && elemento.strLinea=='KIMBERLY'?  parseFloat(objResult2.strKimberly) :
          elemento && elemento.strLinea=='QUIMICOS'?  parseFloat(objResult2.strQuimicos) :
          elemento && elemento.strLinea=='RUBBERMAID'?  parseFloat(objResult2.strRubbermaid) :
          elemento && elemento.strLinea=='TAPETES'?  parseFloat(objResult2.strTapetes) :
          elemento && elemento.strLinea=='TORK'? parseFloat(objResult2.strTork) :0;
          }
        });
        console.log(result);
      });
  }
  fnGetPromedioVta_Sucursal(tipo:String){
    this.dblObjetivoPS=0;
    this.dblObjetivoPSA=0;
    let periodo = this.today.getMonth()+1  ;
    let ejercicio =periodo<4? this.today.getFullYear()-1:this.today.getFullYear();

    this._peticionesService.getApiCompras({
      strAccion: 'getPromedioVta_Sucursal' ,
      strSucursal:this.strSucursal,
      strTipo:tipo
    }).subscribe(
      result => {  
        console.log(result);
        let arrayR =result;
        let tipo='Último  trimestre '+ejercicio;
        let objResult = arrayR.find(o=>o.strTipo ==tipo);
        if(objResult){
         this.dblObjetivoPS =  (objResult.strAccesorios?parseFloat(objResult.strAccesorios):0);
        this.dblObjetivoPS = this.dblObjetivoPS  + (objResult.strBlue?parseFloat(objResult.strBlue):0);
        this.dblObjetivoPS = this.dblObjetivoPS    + ( objResult.strDegrapack?parseFloat(objResult.strDegrapack):0);  
        this.dblObjetivoPS = this.dblObjetivoPS   +(objResult.strDespachadores?parseFloat(objResult.strDespachadores):0);
        this.dblObjetivoPS = this.dblObjetivoPS   + (objResult.strFoaom?parseFloat(objResult.strFoaom):0);
        this.dblObjetivoPS = this.dblObjetivoPS  + (objResult.strKimberly?parseFloat(objResult.strKimberly):0);
        this.dblObjetivoPS = this.dblObjetivoPS  + (objResult.strQuimicos?parseFloat(objResult.strQuimicos):0);
        this.dblObjetivoPS = this.dblObjetivoPS  +(objResult.strRubbermaid?parseFloat(objResult.strRubbermaid):0);
        this.dblObjetivoPS = this.dblObjetivoPS  + (objResult.strTork?parseFloat(objResult.strTork):0 ) ; 
        }
        let tipo2='Último  trimestre '+(ejercicio-1);
        let objResult2 = arrayR.find(o=>o.strTipo ==tipo2);
        if(objResult2){
        this.dblObjetivoPSA =  (objResult2.strAccesorios?parseFloat(objResult.strAccesorios):0);
        this.dblObjetivoPSA = this.dblObjetivoPSA  + (objResult2.strBlue?parseFloat(objResult2.strBlue):0);
        this.dblObjetivoPSA = this.dblObjetivoPSA    + ( objResult2.strDegrapack?parseFloat(objResult2.strDegrapack):0);  
        this.dblObjetivoPSA = this.dblObjetivoPSA   +(objResult2.strDespachadores?parseFloat(objResult2.strDespachadores):0);
        this.dblObjetivoPSA = this.dblObjetivoPSA   + (objResult2.strFoaom?parseFloat(objResult2.strFoaom):0);
        this.dblObjetivoPSA = this.dblObjetivoPSA  + (objResult2.strKimberly?parseFloat(objResult2.strKimberly):0);
        this.dblObjetivoPSA = this.dblObjetivoPSA  + (objResult2.strQuimicos?parseFloat(objResult2.strQuimicos):0);
        this.dblObjetivoPSA = this.dblObjetivoPSA  +(objResult2.strRubbermaid?parseFloat(objResult2.strRubbermaid):0);
        this.dblObjetivoPSA = this.dblObjetivoPSA  + (objResult2.strTork?parseFloat(objResult2.strTork):0 ) ; 
        }

        this.arrayHistorico.forEach((elemento:any)=> { 
          console.log(elemento.strLinea);
          elemento.promedioAct = elemento.strLinea=='ACCESORIOS'? parseFloat(objResult.strAccesorios)   : 
          elemento.strLinea=='BLUE TISSUE'? parseFloat(objResult.strBlue) :
          elemento.strLinea=='DEGRAPACK'?   parseFloat(objResult.strDegrapack) :
          elemento.strLinea=='DESPACHADORES ECODELI'?  parseFloat(objResult.strDespachadores) :
          elemento.strLinea=='FOAOM'?  parseFloat(objResult.strFoaom) :
          elemento.strLinea=='KIMBERLY'?  parseFloat(objResult.strKimberly) :
          elemento.strLinea=='QUIMICOS'?  parseFloat(objResult.strQuimicos) :
          elemento.strLinea=='RUBBERMAID'?  parseFloat(objResult.strRubbermaid) :
          elemento.strLinea=='TORK'? parseFloat(objResult.strTork) :0;

          elemento.promedioAnt = elemento.strLinea=='ACCESORIOS'? parseFloat(objResult2.strAccesorios)   : 
          elemento.strLinea=='BLUE TISSUE'? parseFloat(objResult2.strBlue) :
          elemento.strLinea=='DEGRAPACK'?   parseFloat(objResult2.strDegrapack) :
          elemento.strLinea=='DESPACHADORES ECODELI'?  parseFloat(objResult2.strDespachadores) :
          elemento.strLinea=='FOAOM'?  parseFloat(objResult2.strFoaom) :
          elemento.strLinea=='KIMBERLY'?  parseFloat(objResult2.strKimberly) :
          elemento.strLinea=='QUIMICOS'?  parseFloat(objResult2.strQuimicos) :
          elemento.strLinea=='RUBBERMAID'?  parseFloat(objResult2.strRubbermaid) :
          elemento.strLinea=='TORK'? parseFloat(objResult2.strTork) :0;
        });
        console.log(result);
      });
  }
  /***************fin nuevo************* */
fnSumaObjetivo  (){
    this.dblObjetivoG=0;
    var algo=0;
    this.arrayHistorico.forEach((elemento:any)=> {  
      algo=algo+elemento.dblObjetivo;
     });
     this.dblObjetivoG=algo;
     this.dblObjetivoD=this.dblObjetivoA-this.dblObjetivoG;
     //this.dblObjetivoD<=0 && this.strEstatus!='AUTORIZADO'
     this.btnGuardarD=(this.dblObjetivoD<=0 && this.strEstatus!='AUTORIZADO')?false:true;
  } 
  
  fnSumar(event:any,item:any){
    item.dblObjetivo=parseFloat(event.target.value);
    this.fnSumaObjetivo();   
  }

  updateObjetivoAgente(event:any){
    this.dblObjetivoAgente = event.target.value;//Modifica el valor de la variable
  }

  fnGuardar(){ 
    let periodo =  this.today.getMonth()+2 ;
    let ejercicio = this.today.getFullYear();
    this._peticionesService.getApiCompras({
      strAccion: 'setObjetivoSucursal' ,
      strSucursal:this.strSucursal,
      xmlLineas:this.arrayHistorico,
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
    }).subscribe(
      result => { 
        this.dblObjetivoG=0;
        this._alertify.success('Objetivo Guardado Correctamente'); 

        this.fnGetHistorial();
      });
  }
  fnSetObjetivoSuc(){
    let periodo =  this.today.getMonth()+2;
    let ejercicio = this.today.getFullYear();
    this._peticionesService.getApiCompras({
      strAccion: 'setObjetivoAgenteSucursal' ,
      strSucursal:this.agenteSelected.strEstatus,
      xmlLineas:[{strLinea:'OBJETIVO SUCURSAL',dblObjetivo:this.dblObjetivoAgente}],
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
    }).subscribe(
      result => {  
        this._alertify.success('Objetivo Guardado Correctamente'); 
        this.fnGetTotalObjetivos();
        this.fnGetObjAgt();
      }
    );
  }
  
  fnFiltrar(){
    this.fnGetObjAgt();
    this.fnGetTotalObjetivos();
    this.fnGetTotalEstatus();
    this.fnGetHistAgt();
  }
fnGetHistAgt(){
  let   today2 =new Date();
  let periodo =  today2.getMonth()+2;
  let ejercicio = today2.getFullYear();
  this.arrayEncabezadoAgt=[];
  console.log('periodo')
  console.log(periodo);
  console.log('getHistoricoAgtSucursal')
  let objEnv = {
    strAccion: 'getHistoricoAgtSucursal',
    intEjercicio:periodo==13?ejercicio+1:ejercicio,
    intPeriodo:periodo==13?1:periodo,
    strAgente:this.agenteSelected.strEstatus
  }
  console.log(objEnv)
  this._peticionesService.getApiCompras(objEnv).subscribe(
    result => {  
      console.log(result)
      this.arrayObjetivosAgentes=result;
      this.dblTEnero= 0;
      this.dblTFebrero=0;
      this.dblTMarzo=0;
      this.dblTAbril= 0;
      this.dblTMayo= 0;
      this.dblTJunio= 0;
      this.dblTJulio= 0;;
      this.dblTAgosto= 0;
      this.dblTSeptiembre=0;
      this.dblTOctubre= 0;
      this.dblTNoviembre= 0;
      this.dblTDiciembre= 0;
      this.arrayObjetivosAgentes.forEach((elemento:any)=> {  
        elemento.dblObjetivo = this.dblObjetivoA*elemento.dblPAgente;
        this.dblTEnero= this.dblTEnero+elemento.dblEnero;
        this.dblTFebrero= this.dblTFebrero+elemento.dblFebrero;
        this.dblTMarzo= this.dblTMarzo+elemento.dblMarzo;
        this.dblTAbril= this.dblTAbril+elemento.dblAbril;
        this.dblTMayo= this.dblTMayo+elemento.dblMayo;
        this.dblTJunio= this.dblTJunio+elemento.dblJunio;
        this.dblTJulio= this.dblTJulio+elemento.dblJulio;
        this.dblTAgosto= this.dblTAgosto+elemento.dblAgosto;
        this.dblTSeptiembre= this.dblTSeptiembre+elemento.dblSeptiembre;
        this.dblTOctubre= this.dblTOctubre+elemento.dblOctubre;
        this.dblTNoviembre= this.dblTNoviembre+elemento.dblNoviembre;
        this.dblTDiciembre= this.dblTDiciembre+elemento.dblDiciembre;            
      });
      let total = this.dblTEnero + this.dblTFebrero+this.dblTMarzo+this.dblTAbril+this.dblTMayo+
      this.dblTJunio + this.dblTJulio+ this.dblTAgosto +this.dblTSeptiembre+this.dblTOctubre+
      this.dblTNoviembre + this.dblTDiciembre;

      this.arrayObjetivosAgentes.forEach((elemento:any)=>{
        let objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Enero');
        elemento.dblEnero && objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Enero', total:this.dblTEnero}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Febrero');
        elemento.dblFebrero && objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Febrero',total:this.dblTFebrero}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Marzo');
        elemento.dblMarzo&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Marzo',total:this.dblTMarzo}):0;

        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Abril');
        elemento.dblAbril&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Abril',total:this.dblTAbril}):0;

        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Mayo');
        elemento.dblMayo&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Mayo', total:this.dblTMayo}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Junio');
        elemento.dblJunio&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Junio',total:this.dblTJunio}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Julio');
        elemento.dblJulio&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Julio',total:this.dblTJulio}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Agosto');
        elemento.dblAgosto&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Agosto',total:this.dblTAgosto}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Septiembre');
        elemento.dblSeptiembre&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Septiembre',total:this.dblTSeptiembre}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Octubre');
        elemento.dblOctubre&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Octubre',total:this.dblTOctubre}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Noviembre');
        elemento.dblNoviembre&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Noviembre',total:this.dblTNoviembre}):0;
        objAux = this.arrayEncabezadoAgt.find ((o: { strMes: string; }) =>o.strMes=='Diciembre');
        elemento.dblDiciembre&& objAux==undefined? this.arrayEncabezadoAgt.push({strMes:'Diciembre',total:this.dblTDiciembre}):0;
      
        let linea =( elemento.dblAbril + elemento.dblMayo + elemento.dblJunio + elemento.dblJulio+elemento.dblAgosto+elemento.dblSeptiembre)/total;
        elemento.promedio=linea;
      })
      //this.arrayObjetivosAgentes = result;
    }
  );
}
  fnGetObjAgt(){ 
    let periodo =  this.today.getMonth()+2;
    let ejercicio = this.today.getFullYear();
    let objenvio={
      strAccion: 'getObjetivosAgentes',
      strSucursal:this.strSucursal,
      strAgente:this.agenteSelected.strEstatus,
      intPeriodo:periodo==13?1:periodo,
      intEjercicio:periodo==13?ejercicio+1:ejercicio
    }
    this._peticionesService.getApiCompras(objenvio).subscribe(
      result => {  
        console.log('fnGetObjAgt');
        console.log(result);//AQUI TOMAR ESTATUS DEL AGENTE SELECCIONADO
        
        
        this.dblObjetivoActAgt=result[0].dblPObjetivo?result[0].dblPObjetivo:'';
        this.strEstatusAgente=result[0].strEstatus?result[0].strEstatus:'';
        this.dblObjetivoAgente=result[0].dblObjetivo?result[0].dblObjetivo:'';
        this.fnGetObjetivoSuc(result);
        //this.arrayObjetivosAgentes = result;
      }
    );
  }

  fnGetObjetivoSuc(arr:any){
    this.dblObjetivoAgente=0;
    let periodo = this.today.getMonth()+2;
    let ejercicio = this.today.getFullYear();
    this.arrayObjetivoCapturadoSuc=[];
    let objeEnv = {
      //strAccion: 'getObjetivoAgentes' ,
      strAccion:'getHistoricoAgt',
      strAgente:this.agenteSelected.strEstatus,
      intPeriodo :periodo==13?1:periodo, //da 8, más 2 para obtener el siguiente mes
      intEjercicio :periodo==13?ejercicio+1:ejercicio
  }
  console.log(objeEnv)
    this._peticionesService.getApiCompras(objeEnv).subscribe(
        result => {   
          console.log(arr);
          this.dblObjetivoC=0;
          this.dblObjetivoAgente= arr[0].dblObjetivo;
          this.dblObjetivoAS=  arr[0].dblObjetivo;
          this.strEstatusS=arr[0].strEstatus;
          console.log('hola---');
          this.strObservacionesS=arr[0].strSucursal;
         
          result.forEach((elemento:any)=> {
              this.dblObjetivoC=this.dblObjetivoC + elemento.dblObjetivo;            
          });
          this.arrayObjetivoCapturadoSuc=result;
          this.fnGetPromedioVta('siguiente');
  
          console.log(result);
        }
    );
  }

  fnAutorizar(estatus:any){
    this._peticionesService.getApiCompras({
      strAccion: 'autorizarAgente' ,
      strAgente :this.agenteSelected.strEstatus,
      strLinea :'OBJETIVO NACIONAL',
      strSucursal :this.strObservacionesS,
      strEstatus:estatus,
      xmlLineas:this.arrayObjetivoCapturadoSuc      
    }).subscribe(
      result => { 
        this.fnFiltrarSuc();        //this.fnFiltrar();
        let msj = 'Objetivo '+estatus+' Correctamente';
        this._alertify.success(msj); 
      });
  }


  fnGetTotalObjetivosActual(){
    console.log(this.strSucursal);
    let periodo = this.today.getMonth()+1;
    let ejercicio = this.today.getFullYear();
    let obj ={
      strAccion: 'getTotalObjSucursalActual',
       strSucursal: this.strSucursal  ,
       intPeriodo:periodo, 
       intEjercicio:ejercicio    
    }
    this._peticionesService.getApiCompras(obj).subscribe(
      result => {  
        this.arrayTotalObj=result;
        console.log(result);
      });
  }


  fnGetTotalEstatus(){
    let periodo =  this.today.getMonth()+2;
    let ejercicio = this.today.getFullYear();
    this._peticionesService.getApiCompras({
      strAccion: 'getEstatusAgentes ',
       strSucursal: this.strSucursal,
       intPeriodo:periodo==13?1:periodo,
       intEjercicio:periodo==13?ejercicio+1:ejercicio      
    }).subscribe(
      result => {  
        this.arrayEstatusAgentes=result;
        this.totalAgentes=0;
        this.totalAgentesAut=0;
        if(this.arrayEstatusAgentes.length>0){
          this.arrayEstatusAgentes.forEach((element:any)=> {  
            if(element.strEstatus=='AUTORIZADO'){
              this.totalAgentesAut=this.totalAgentesAut+element.intTotal;
            }
            this.totalAgentes=this.totalAgentes+element.intTotal;
          });
        }
         console.log(this.totalAgentes);
         console.log(this.totalAgentesAut);
      });
  }
  
  fnGetTotalEstatusActual(){
    let periodo = this.today.getMonth()+1;
    let ejercicio = this.today.getFullYear();
    this._peticionesService.getApiCompras({
      strAccion: 'getEstatusAgentesActual',
       strSucursal: this.strSucursal,
       intPeriodo:periodo,
       intEjercicio:ejercicio    
    }).subscribe(
      result => {  
        this.arrayEstatusAgentes=result;
        this.totalAgentes=0;
        this.totalAgentesAut=0;
        if(this.arrayEstatusAgentes.length>0){
          this.arrayEstatusAgentes.forEach((element:any)=> {  
            if(element.strEstatus=='AUTORIZADO'){
              this.totalAgentesAut=this.totalAgentesAut+element.intTotal;
            }
            this.totalAgentes=this.totalAgentes+element.intTotal;
          });
        }
        //console.log(result);
      });
  }
}
 