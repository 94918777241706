<div class="container py-5 bg-white my-4">
    <div class="row">
        <div class="col-12 col-md-3 text-center">
            <img class="w-75" [src]="'./assets/ecommerce/user.jpg'" alt="">
        </div>
        <div class="col-12 col-md-9">
            <div class="col-12 position-relative">
                <h1 class="nunito fs-3 fw-bold text-uppercase">
                    {{user.strNombre_f}} {{user.strApellidos}}
                </h1>

                <button class="btn position-absolute top-0 end-0 border-0" style="color:#D19919" [routerLink]="['/editdireccion']">
                    <i class="fa-solid fa-user-pen borderRad border-bottom px-1 pb-1 border-2 border-warning"></i>
                </button>
            
            </div>
            <div class="col-12 mt-2">
                <i class="fa-regular fa-envelope-open clsIcon"></i>
                <span>
                    {{user.strCorreo}}
                </span>
            </div>
            <div class="col-12 mt-2">
                <i class="fa-solid fa-phone clsIcon"></i>
                <span>
                    {{user.strTelefono}}
                </span>
            </div>
            <div class="col-12 mt-2">
                <i class="fa-regular fa-id-card clsIcon"></i>
                <span>
                    {{user.strUsuario}}
                </span>
            </div>
            <div class="col-12 mt-2">
                <i class="fa-solid fa-location-dot clsIcon"></i>
                <span>
                    {{user.strDireccion}}
                </span>
            </div>
        </div>
    </div>
</div>