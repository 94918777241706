import { Component, OnInit,ElementRef, ViewChild} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CarroService } from '../servicios/carroservice';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { AlertifyService } from '../alertify.service';
 
import { filter } from 'rxjs/operators';

export interface tareasCte {
  movimiento: string;
  cliente: string;
  nombre: string;
  fecharequerida: string;
  importe: string;
  semaforo: string;

}
@Component({
  selector: 'app-remisiones-pendientes',
  templateUrl: './remisiones-pendientes.component.html',
  styleUrls: ['./remisiones-pendientes.component.css']
})


export class RemisionesPendientesComponent implements OnInit {
  columnsTareasCte: string[] = ['movimiento', 'cliente', 'nombre', 'fecharequerida','importe','semaforo'];
  datosTareasCte: MatTableDataSource<tareasCte>;
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  
  public strConexion:string="";
  public arrayDetalleArts:any=[];
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayAccesos:any=[];
  isHoveredButton: boolean = false;
  public tagName:any;
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public arrayRemisiones:any=[];
  public totalMov:number=0;
  public totalImporte:number=0;
  public strID:any="";
    public strSituacion: any = ""
  constructor( private paginatorIntl: MatPaginatorIntl,public _alertify: AlertifyService,private carroservice:CarroService,elem:ElementRef,private localSt:LocalStorageService, private editPl:PlantillafinService,   private router: Router,   private _httpService:PeticionesService, private _filtroServ :FiltrosService) {
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
    //array de accesos
    //obtener niveles de acceso
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      }
      else{
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
      }
    }
    this.customizeItemsPerPageLabel();
   }

  ngOnInit(): void {
    this.fnGetFiltros()
    this.fnGetRemisiones()
  }
  ngAfterViewInit() {
    this.datosTareasCte = new MatTableDataSource(this.arrayRemisiones);
    this.fnTabletareasCte()
  }
  fnGetRemisiones() {
    let obj: any;
    obj = {
      strMov: 'Remision',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }
    this._httpService.getVentasMovimientos(obj).subscribe(
      result => {
        console.log(result)
          this.arrayRemisiones = result;  

        this.totalMov = this.arrayRemisiones.length
        for (let i = 0; i < this.arrayRemisiones.length; i++) {
          this.totalImporte = this.totalImporte + parseFloat(this.arrayRemisiones[i].dblImporte);
        }

        this.datosTareasCte = new MatTableDataSource(this.arrayRemisiones);
        this.fnTabletareasCte();
    /*     if (this.arrayRemisiones.length > 0) {
          this.banderaRegistros = false;
          this.banderaRegistrosOK = true;
        } else {
          this.banderaRegistros = true;
          this.banderaRegistrosOK = false;
        } */
      });
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  //    console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }
  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }

  
  fnTabletareasCte() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }

  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'items por página';
  }

  fnDesgloceRemisiones(obj) {
    console.log(obj)
    this.strID = obj.strID;//Encrypt.encode(obj.strID);
    this.strSituacion = obj.strSituacion;
    this.router.navigate(['/detalle_pedido/'], { queryParams: { tipo: 'r', strID: this.strID, strSituacion: this.strSituacion } });
    /* location.href = "DesglocePRVC.php?tipo=p&strID=" + this.strID + "&strSituacion=" + this.strSituacion; */
  }
}
