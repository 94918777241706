<div class="container">
  <div class="row">
    <div class="col-12 text-center mt-3">
      <h1 class="fw-bold text-decoration-underline">Directorio <span class="tittle">Clientes</span></h1>
    </div>
  </div>
  <div class="row bg-white bordertop borderbott shadow-sm pb-4">
    <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
     
      <i class="fa-solid fa-arrow-up-wide-short text-dark"></i>
      <span class="fw-bold text-dark">
        Filtros
      </span>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
        <mat-select [(ngModel)]="strGerente" class="" (selectionChange)="onGerenteChange('Ger')" >
          <mat-option value=""> --Todos --</mat-option>
          <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
      [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
        <mat-select [(ngModel)]="strSucursal" class="" (selectionChange)="onGerenteChange('Suc')">
          <mat-option value=""> --Todas --</mat-option>
          <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente "
            [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
      [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
        <mat-select [(ngModel)]="strAgente" class="">
          <mat-option value=""> --Todos --</mat-option>
          <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
            [value]="x.strAgente">{{x.strNombre}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
      [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
        <mat-select [(ngModel)]="intPeriodo" class="">
          <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"
      [ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }">
      <mat-form-field appearance="outline" class="bg-white rounded w-100">
        <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
        <mat-select [(ngModel)]="intEjercicio">
          <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
            {{e.ejercicio}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
      [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente}">
      <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true"
        (mouseleave)="isHoveredButton = false" (click)="fnGetFamilias();fnGetClientes(true);">
        <i class="bi  p-1 fs-4"
          [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i><!-- (click)="fnGetPlantillas()" -->
      </button>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-12 bordertop borderbott shadow-sm bg-white">
      <div class="row">
        <div class="col-12 d-flex mt-2 mb-0 ">
          <ul class="nav nav-pills align-items-center" id="pills-tab" role="tablist">
            <li class="nav-item " role="presentation" *ngIf="boolFamilias">
              <button class="nav-link shadow-sm me-3" [ngClass]="{'active':boolFamilias}" id="prospectos-tab" data-bs-toggle="pill" data-bs-target="#prospectos"
                type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Familias</button>
            </li>
            <li class="nav-item " role="presentation">
              <button class="nav-link me-3 shadow-sm"  [ngClass]="{'active':!boolFamilias}"  id="pills-home-tab" data-bs-toggle="pill"
                data-bs-target="#ctes" type="button" role="tab" aria-controls="pills-home" aria-selected="false"
                aria-selected="true">Clientes</button>
            </li>
            
            <li class="nav-item " role="presentation">
              <button class="nav-link  me-3 shadow-sm" id="pills-home-tab" data-bs-toggle="pill"
                data-bs-target="#contacto" type="button" role="tab" aria-controls="pills-home" aria-selected="false"
                aria-selected="true">Contacto</button>
            </li>

            <li class="nav-item " role="presentation">
              <button class="nav-link me-3 shadow-sm" id="pills-home-tab" data-bs-toggle="pill"
                data-bs-target="#Prospectos" type="button" role="tab" aria-controls="pills-home" aria-selected="false"
                aria-selected="true">Prospectos</button>
            </li>
            

            
            <!-- <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</button>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="col12 px-0 mx-0 ">
        <div class="tab-content" id="pills-tabContent">
          <!-- comienza la tab de Contacto -->
          <div class="tab-pane fade  " id="contacto" role="tabpanel" aria-labelledby="contacto-tab">
            <div class="row justify-content-center my-3">
              <div class="col-12 d-flex justify-content-center" *ngIf="spinerContacto">
                <mat-spinner></mat-spinner>
              </div>
              <div class="col-12 "  [hidden]="spinerContacto">
                <div class="col-12 bgtable bordertop shadow-sm">
                  <mat-form-field appearance="outline" class="bg-white rounded my-2 ms-3">
                    <mat-label>Buscar</mat-label>
                    <input class="text-dark" matInput (keyup)="applyFilterContacto($event)" placeholder="Familia, Giro, NombreAgente, etcc." #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                </div>
                <div class="col-12">
                  <div class="mb-0 table-responsive">
                    <div class="mat-elevation-z8 mb-0 shadow rounded">
                        <table mat-table [dataSource]="dataSourceContacto" class="table table-hover table-bordered" matSort #sortContacto="matSort">
                            
                          <!-- indice Column -->
                            <ng-container matColumnDef="indice">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgTable3 text-dark"><span class="text-dark fw-bold">#</span></th>
                                <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                                  <span class="rounded fw-bold px-2 py-1 fs11">{{ row.indice }}</span>
                                </td>
                            </ng-container>

                            <!-- intID_Contacto Column -->
                            <ng-container matColumnDef="intID_Contacto">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Logo</span>  </th>
                                <td mat-cell *matCellDef="let row; let i = index" class="px-2 fs11 text-center align-middle">
                                  <div class="p-1 rounded-circle text-center circte position-relative m-2" [ngClass]="{'clsCir1': i % 2 === 0,'clsCir2': i % 2 !== 0 }">
                                    <span class="fs10 fw-bold position-absolute top-50 start-50 translate-middle">{{row.intID_Contacto}}</span>
                                  </div>
                                </td>
                            </ng-container>

                            <!-- strContacto_Nombre Column -->
                            <ng-container matColumnDef="strContacto_Nombre">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Nombre</span>  </th>
                                <td mat-cell *matCellDef="let row" class="text-start fs11 fw-bold align-middle">
                                  <span class="d-block ms-2 text-primary fs-6 fw-bold">{{row.strContacto_Nombre}}</span>
                                  <span class="d-block ms-2 text-muted fs-7">{{row.strContacto_Email}}</span>
                                  <span class="d-block ms-2 text-muted fs-7">{{row.strContacto_Telefono}}</span>
                                </td>
                            </ng-container>

                            <!-- strNombreEmpresa Column -->
                            <ng-container matColumnDef="strNombreEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Empresa</span> </th>
                                <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-center align-middle">
                                  <span class="d-block text-primary fs-7 fw-bold">{{row.strNombreEmpresa}}</span>
                                  <span class="d-block text-muted fs-7">{{row.strContacto_Puesto}}</span>
                                </td>
                            </ng-container>

                            <!-- strTipo_Leads Column -->
                            <ng-container matColumnDef="strTipo_Leads">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    class="fs13 pe-0 bgTable3 text-dark"><span class="text-dark fw-bold">Tipo</span>  </th>
                                <td mat-cell *matCellDef="let row" class="text-center fs11 align-middle">
                                  {{row.strTipo_Leads}}
                                </td>
                            </ng-container>

                            <!-- strFechaCreacion Column -->
                            <ng-container matColumnDef="strFechaCreacion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bgTable3 text-dark"> <span class="text-dark fw-bold">Fecha Creación</span> </th>
                              <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                {{ row.strFechaCreacion == 0 ? 'Hoy' : 'hace ' + row.strFechaCreacion + ' días' }}
                              </td>
                          </ng-container>

                          <!-- strFechaRequerida Column -->
                          <ng-container matColumnDef="strFechaRequerida">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bgTable3 text-dark"><span class="text-dark fw-bold">Ultima Actividad</span> </th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                              {{ row.strFechaRequerida == -1 ? 'Sin actividad reciente' : (row.strFechaRequerida == 0 ? 'Hoy' : 'hace ' + row.strFechaRequerida + ' días') }}
                            </td>
                        </ng-container>

                     
                            <tr mat-header-row class="rounded sticky-top " *matHeaderRowDef="columnsContacto">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: columnsContacto;" class="pointer" (click)="enviarDetalleContacto(row)"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="7">No se encontraron datos de: "{{input.value}}"</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <mat-paginator class="bgpaginator borderbott shadow-sm" [pageSizeOptions]="[25, 50, 100, 200]" aria-label="Select page of users" #paginadorContacto></mat-paginator>
                </div>
              </div>
            </div>



          <!--      <div class="row bgtable py-2 ps-2 mx-auto mt-3 w-100 bordertop">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <mat-form-field class="bg-white rounded" appearance="outline">
                  <mat-label>Buscar</mat-label>
                  <input class="text-dark" matInput placeholder="Ingrese el dato a filtrar" 
                    [(ngModel)]="searchText">
                  <mat-icon matSuffix class="text-dark">search</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div class="table-responsive mb-0  ">
              <div class="col-12 d-flex justify-content-center py-4" *ngIf="spinerContacto">
                <mat-spinner diameter="75" strokeWidth="8"></mat-spinner>
              </div>
              <div class="mat-elevation-z8" *ngIf="!spinerContacto">
                <table class=" table table-bordered bg-white table-hover">
                  <thead class="bgTable3">
                    <tr class="bgtable   bgTable3">
                      <th class="text-center text-dark bgTable3 sticky-top">#</th>
                      <th class="text-center text-dark bgTable3 sticky-top">Logo</th>
                      <th class="text-center text-dark bgTable3 sticky-top">Nombre</th>
                      <th class="text-center text-dark bgTable3 sticky-top">Empresa</th>
                      <th class="text-center text-dark bgTable3 sticky-top">Tipo</th>
                      <th class="text-center text-dark bgTable3 sticky-top">Fecha Creacion</th>
                      <th class="text-center text-dark bgTable3 sticky-top">Ultima actividad</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr (click)="enviarDetalleContacto(item)" *ngFor="let item of arrayContactos | filtersearch: searchText: ['strFamilia']; let i = index">  
                      <td class="text-center align-middle"><span class="rounded fw-bold px-2 py-1 fs11">{{ i + 1 }}</span></td>
                      <td class="text-center fw-bold fs11 cursorHand" >
                        <div class="p-1 rounded-circle text-center circte position-relative m-2" [ngClass]="{'clsCir1': i % 2 === 0,'clsCir2': i % 2 !== 0 }">
                          <span class="fs10 fw-bold position-absolute top-50 start-50 translate-middle">{{item.intID_Contacto}}</span>
                        </div>
                      </td>
                      <td class="text-start fs11 fw-bold align-middle">
                        <span class="d-block text-primary fs-6 fw-bold">{{item.strContacto_Nombre}}</span>
                        <span class="d-block text-muted fs-7">{{item.strContacto_Email}}</span>
                        <span class="d-block text-muted fs-7">{{item.strContacto_Telefono}}</span>
                      </td>
                      <td class="text-center fs11 align-middle">
                        <span class="d-block text-primary fs-7 fw-bold">{{item.strNombreEmpresa}}</span>
                        <span class="d-block text-muted fs-7">{{item.strContacto_Puesto}}</span>
                      </td>
                      <td class="text-center fs11 align-middle">{{item.strTipo_Leads}}</td> 
                      <td class="text-center fs11 align-middle"> {{ item.strFechaCreacion == 0 ? 'Hoy' : 'hace ' + item.strFechaCreacion + ' días' }} </td> 
                      <td class="text-center fs11 align-middle"> {{ item.strFechaRequerida == -1 ? 'Sin actividad reciente' : (item.strFechaRequerida == 0 ? 'Hoy' : 'hace ' + item.strFechaRequerida + ' días') }}
                      </td> 
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-paginator #paginatorProspectos [pageSizeOptions]="[100,150,200,250]" class="bgpaginator borderbott mb-3 shadow-sm" aria-label="Select page of users" [length]="arrayContactos.length" (page)="onPageChange($event)"></mat-paginator>
              </div>
            </div> -->
          


          
          </div>
           <!-- finaliza la tab de Contacto -->

           <!-- comienza la tab de prospectos -->
           <div class="tab-pane fade " id="Prospectos" role="tabpanel" aria-labelledby="Prospectos-tab">
            <div class="row justify-content-center my-3">
              <div class="col-12 d-flex justify-content-center" *ngIf="spinerProspecto">
                <mat-spinner></mat-spinner>
              </div>
              <div class="col-12 "  [hidden]="spinerProspecto">
                <div class="col-12 bgtable bordertop shadow-sm">
                  <mat-form-field appearance="outline" class="bg-white rounded my-2 ms-3">
                    <mat-label>Buscar</mat-label>
                    <input class="text-dark" matInput (keyup)="applyFilterprospectos($event)" placeholder="Familia, Giro, NombreAgente, etcc." #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                </div>
                <div class="col-12">
                  <div class="mb-0 table-responsive">
                    <div class="mat-elevation-z8 mb-0 shadow rounded">
                        <table mat-table [dataSource]="dataProspectos" class="table table-hover table-bordered" matSort #sortProspectos="matSort">
                            
                          <!-- indice Column -->
                            <ng-container matColumnDef="indice">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgTable3 text-dark"><span class="text-dark fw-bold">#</span></th>
                                <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                                  <span class="rounded fw-bold px-2 py-1 fs11">{{ row.indice }}</span>
                                </td>
                            </ng-container>

                            <!-- strEstatus Column -->
                            <ng-container matColumnDef="strEstatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Estatus</span>  </th>
                                <td mat-cell *matCellDef="let row"class="mostDet  ps-0 fs13 text-center align-middle">
                                  {{row.strEstatus}}
                                </td>
                            </ng-container>

                            <!-- strNombreEmpresa Column -->
                            <ng-container matColumnDef="strNombreEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Empresa</span>  </th>
                                <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle">
                                  <button class="btn btn-link fs12 p-0">{{row.strNombreEmpresa}}</button>
                                </td>
                            </ng-container>

                            <!-- strNombreAgente Column -->
                            <ng-container matColumnDef="strNombreAgente">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Agente</span> </th>
                                <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-center align-middle">
                                  {{row.strNombreAgente}}
                                </td>
                            </ng-container>

                            <!-- strClasificacion Column -->
                            <ng-container matColumnDef="strClasificacion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    class="fs13 pe-0 bgTable3 text-dark"><span class="text-dark fw-bold">Clasificación</span>  </th>
                                <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                  {{row.strClasificacion}}
                                </td>
                            </ng-container>

                            <!-- strFamilia Column -->
                            <ng-container matColumnDef="strFamilia">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bgTable3 text-dark"> <span class="text-dark fw-bold">Familia</span> </th>
                              <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                               {{row.strFamilia}}
                              </td>
                          </ng-container>

                          <!-- intSucursales Column -->
                          <ng-container matColumnDef="strFechaActividad">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bgTable3 text-dark"><span class="text-dark fw-bold">Fecha Actividad</span> </th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                             {{row.strFechaActividad}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="strContacto_Nombre">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header
                              class="fs13 pe-0 bgTable3 "> <span class="text-dark fw-bold">Contacto</span></th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                            {{row.strContacto_Nombre}}
                          </td>
                        </ng-container>
                            <tr mat-header-row class="rounded sticky-top " *matHeaderRowDef="displayProspectos">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: displayProspectos;" class="pointer" (click)="fnDetalleLead(row, row.strEstatus)"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">No se encontraron datos de: "{{input.value}}"</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <mat-paginator class="bgpaginator borderbott shadow-sm" [pageSizeOptions]="[25, 50, 100, 200]" aria-label="Select page of users" #paginadorProspectos></mat-paginator>
                </div>
              </div>
           </div>

            <!-- <div class="row bgtable py-2 ps-2 mx-auto mt-3 w-100 bordertop">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <mat-form-field class="bg-white rounded" appearance="outline">
                  <mat-label>Buscar</mat-label>
                  <input class="text-dark" matInput placeholder="Ingrese el dato a filtrar" 
                    [(ngModel)]="searchText">
                  <mat-icon matSuffix class="text-dark">search</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div class="table-responsive mb-0  ">
              <div class="col-12 d-flex justify-content-center py-4" *ngIf="spinerContacto">
                <mat-spinner diameter="75" strokeWidth="8"></mat-spinner>
              </div>

              <div class="mat-elevation-z8" *ngIf="!spinerContacto">
                <table class=" table table-bordered bg-white table-hover">
                  <thead class="bgTable3">
                    <tr class="bgtable sticky-top bgTable3">
                      <th class="text-center text-dark bgTable3">#</th>
                      <th class="text-center text-dark bgTable3">Estatus</th>
                      <th class="text-center text-dark bgTable3">Empresa</th>
                      <th class="text-center text-dark bgTable3">Agente</th>
                      <th class="text-center text-dark bgTable3">Familia</th>
                      <th class="text-center text-dark bgTable3">Clasificacion</th>
                      <th class="text-center text-dark bgTable3">Fecha</th>
                      <th class="text-center text-dark bgTable3">Contacto</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr (click)="fnDetalleLead(item, item.strEstatus)" *ngFor="let item of arrayListaLeads | filtersearch: searchText: ['strFamilia']; let i = index">  
                      <td class="text-center align-middle"><span class="rounded fw-bold px-2 py-1 fs11">{{ i + 1 }}</span></td>
                      <td class="text-center fs11 align-middle">{{item.strEstatus}}</td> 
                      <td class="text-center fs11 align-middle">{{item.strNombreEmpresa}}</td> 
                      <td class="text-center fs11 align-middle">{{item.strNombreAgente}}</td> 
                      <td class="text-center fs11 align-middle">{{item.strFamilia}}</td> 
                      <td class="text-center fs11 align-middle">{{item.strClasificacion}}</td> 
                      <td class="text-center fs11 align-middle">{{item.strFechaActividad}}</td>
                      <td class="text-start fs11 fw-bold align-middle">
                        <span  >{{item.strContacto_Nombre}}</span>
                      </td> 
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-paginator #paginatorProspectos [pageSizeOptions]="[100,150,200,250]"
                  class="bgpaginator borderbott mb-3 shadow-sm" aria-label="Select page of users"
                  [length]="arrayListaLeads.length" (page)="onPageChange($event)"></mat-paginator>
              </div>
            </div> -->

          </div>

          <!-- comienza tap de clientes -->
          <div class="tab-pane fade " id="ctes" [ngClass]="{'show active':!boolFamilias}"  role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="row bgtable py-2 ps-2 mx-auto  bordertop w-100 mt-3">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <mat-form-field class="bg-white rounded" appearance="outline">
                  <mat-label>Buscar</mat-label>
                  <input matInput  class="text-dark" (keyup)="applyFilterClientes($event)"
                    placeholder="Ingrese el dato a filtrar" #inputClientes>
                  <mat-icon matSuffix class="text-dark">search</mat-icon>
                </mat-form-field>
                <mat-spinner diameter="25" strokeWidth="4" *ngIf="spinerDatos"></mat-spinner>
              </div>
            </div>
            <div class="table-responsive mb-0  ">
              <div class="mat-elevation-z8 ">
                <table mat-table [dataSource]="dataSource" class="table table-hover">
                  <!-- id Column -->
                  <ng-container matColumnDef="no">
                    <th mat-header-cell *matHeaderCellDef class="text-center ps-0 py-2 text-dark fw-bold fs13 bgTable2"> # </th>
                    <td mat-cell *matCellDef="let element" class="align-middle border-end text-center ps-0">
                      <span class="fw-bold fs12 rounded badgeind p-1 mx-1">{{element?.intFila}}</span>
                    </td>
                  </ng-container>

                  <!-- cliente Column -->
                  <ng-container matColumnDef="cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> Cliente </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                      <div class="p-1 rounded-circle text-center circte position-relative m-2"
                        [ngClass]="{'clsCir1': element.intFila % 2 === 0,'clsCir2': element.intFila % 2 !== 0 }">
                        <span
                          class="fs10 fw-bold position-absolute top-50 start-50 translate-middle">{{element.strCliente}}</span>
                      </div>
                    </td>
                  </ng-container>

                  <!-- nombre Column -->
                  <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> Nombre </th>
                    <td mat-cell *matCellDef="let element" class="align-middle fs11 text-center cursor text-primary"
                      [innerHTML]="dividirTextoDosLineas(element.strNombre)" (click)="enviarDetalleCliente(element)"> </td>
                  </ng-container>

                  <!-- rfc Column -->
                  <ng-container matColumnDef="rfc">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> RFC </th>
                    <td mat-cell *matCellDef="let element" class="fs11 fw-bold align-middle text-center">
                      {{element.strRfc}}
                    </td>
                  </ng-container>

                  <!-- contacto Column -->
                  <ng-container matColumnDef="contacto">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> Contacto
                    </th>
                    <td mat-cell *matCellDef="let element" class="fs11 fw-bold align-middle text-center">
                      {{element.strContacto}} </td>
                  </ng-container>

                  <!-- estatus Column -->
                  <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> Estatus </th>
                    <td mat-cell *matCellDef="let element" class="align-middle text-center">
                      <span class="fs11 p-1 rounded fw-bold" [ngClass]="{'bgStatusBlock':element.strEstatus == 'BLOQUEADO','bgStatus':element.strEstatus == 'ALTA'}">{{element.strEstatus}}</span>
                    </td>
                  </ng-container>
                  <!-- intPreciosInferioresMinimo Column -->
                  <ng-container matColumnDef="intPreciosInferioresMinimo">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> 
                      <span *ngIf = "boolCheck"> Precios Inferior Al minimo</span>

                    </th>
                    <td mat-cell *matCellDef="let element;let i = index;" class="align-middle text-center">
                      <input *ngIf = "boolCheck" type="checkbox" [disabled]="!boolCheck" [checked]="element.intPreciosInferioresMinimo"  class="form-check-input large-checkbox" (change)="changebox($event, i, element)"  ><!-- (change)="onCheckboxChange(i, element,element.intPreciosInferioresMinimo)" -->
                      <!-- <span class="fs11 p-1 rounded fw-bold">{{element.intPreciosInferioresMinimo}}</span> -->
                    </td>
                  </ng-container>

                  <!-- giro Column -->
                  <ng-container matColumnDef="giro">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> Giro </th>
                    <td mat-cell *matCellDef="let element" class="fs11 fw-bold align-middle text-center">
                      {{element.strGiro}} </td>
                  </ng-container>

                  <!-- estrellas Column -->
                  <ng-container matColumnDef="estrellas">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> Estrellas
                    </th>
                    <td mat-cell *matCellDef="let x"
                      class="fs11 fw-bold text-center align-middle text-warning text-center">
                      <span class="text-dark" *ngIf="x?.strClasificacionE ==null">
                        <mat-icon>not_interested</mat-icon>
                      </span>
                      <div class="cursorHand" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <span class=""
                          *ngIf="x?.strClasificacionE ==1 || x?.strClasificacionE ==2 || x?.strClasificacionE ==3">
                          <i class="bi bi-star-fill"></i>
                        </span>
                        <span class="" *ngIf="x?.strClasificacionE ==2 || x?.strClasificacionE ==3">
                          <i class="bi bi-star-fill"></i>
                        </span>
                        <span class="" *ngIf=" x?.strClasificacionE ==3">
                          <i class="bi bi-star-fill"></i>
                        </span>
                      </div>
                    </td>
                  </ng-container>

                  <!-- categoria Column -->
                  <ng-container matColumnDef="categoria">
                    <th mat-header-cell *matHeaderCellDef class="text-center py-2 text-dark fw-bold fs13 bgTable2"> Categoria
                    </th>
                    <td mat-cell *matCellDef="let x"
                      class="fs11 fw-bold text-center align-middle text-primary text-center">
                      <span class="text-dark" *ngIf="x?.strClasificacionA ==null">
                        <mat-icon>not_interested</mat-icon>
                      </span>
                      <div class="cursorHand" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <span *ngIf="x?.strClasificacionA ==1 || x?.strClasificacionA ==2 || x?.strClasificacionA ==3">
                          A
                        </span>
                        <span *ngIf="x?.strClasificacionA == 2 || x?.strClasificacionA == 3">
                          A
                        </span>
                        <span *ngIf=" x?.strClasificacionA == 3">
                          A
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <!-- detalle Column -->
                  <ng-container matColumnDef="detalle"> 
                    <th mat-header-cell *matHeaderCellDef class="pe-0 text-center py-2 text-dark fw-bold fs13 bgTable2"> Agregar
                    </th>
                    <td mat-cell *matCellDef="let x" class="fw-bold align-middle text-center pe-0">
                      <span class="badge bg-primary cursorHand" (click)="fnSeleccionarCliente(x)" matTooltip="Agregar Cliente">Seleccionar</span>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bgtable sticky-top"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="border-bottom"></tr>
                </table>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center py-3" *ngIf="spinerTable">
              <mat-spinner></mat-spinner>
            </div>
            <mat-paginator #paginatorClientes class="bgpaginator borderbott mb-3 shadow-sm" [pageSizeOptions]="[100,200,500]" showFirstLastButtons aria-label="Select page of periodic elements">
            </mat-paginator>
          </div>
          <!-- finaliza tab de clientes -->

          <!-- aqui comienza la tab de Familias  -->
          <div class="tab-pane fade  " [ngClass]="{'show active':boolFamilias}" id="prospectos" role="tabpanel" aria-labelledby="prospectos-tab">
           <div class="row justify-content-center my-3">
            <div class="col-12 d-flex justify-content-center" *ngIf="spinerFamilias">
              <mat-spinner></mat-spinner>
            </div>
              <div class="col-12 "  [hidden]="spinerFamilias">
                <div class="col-12 bgtable bordertop shadow-sm">
                  <mat-form-field appearance="outline" class="bg-white rounded my-2 ms-3">
                    <mat-label>Buscar</mat-label>
                    <input class="text-dark" matInput (keyup)="appliFilterListadoFamilia($event)" placeholder="Familia, Giro, NombreAgente, etcc." #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                </div>
                <div class="col-12">
                  <div class="mb-0 table-responsive">
                    <div class="mat-elevation-z8 mb-0 shadow rounded">
                        <table mat-table [dataSource]="datafamilias" class="table table-hover table-bordered" matSort #sortTareasCte="matSort">
                            <!-- i Column -->
                            <ng-container matColumnDef="indice">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgTable3 text-dark"><span class="text-dark fw-bold">#</span></th>
                                <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                                  <span class="rounded fw-bold px-2 py-1 fs11">{{ row.indice }}</span>
                                </td>
                            </ng-container>

                            <!-- strUrl_Logo Column -->
                            <ng-container matColumnDef="strUrl_Logo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Logo</span>  </th>
                                <td mat-cell *matCellDef="let row"class="mostDet fw-bold ps-0 fs13 text-center align-middle">
                                  <img *ngIf="!row.strUrl_Logo" src="assets/detoportunidad/noDisponible.jpg" width="100px" alt="">
                                  <img *ngIf="row.strUrl_Logo" src="https://www.ecodeli.com/{{row.strUrl_Logo}}" width="100px" alt="">
                                </td>
                            </ng-container>

                            <!-- strFamilia Column -->
                            <ng-container matColumnDef="strFamilia">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Familia</span>  </th>
                                <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle">
                                  <button class="btn btn-link fs12 p-0">{{row.strFamilia}}</button>
                                </td>
                            </ng-container>

                            <!-- strGiro Column -->
                            <ng-container matColumnDef="strGiro">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bgTable3 text-dark"><span class="text-dark fw-bold">Giro</span> </th>
                                <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-center align-middle">
                                  {{row.strGiro}}
                                </td>
                            </ng-container>

                            <!-- strNombreAgente Column -->
                            <ng-container matColumnDef="strNombreAgente">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    class="fs13 pe-0 bgTable3 text-dark"><span class="text-dark fw-bold">Agente</span>  </th>
                                <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                  {{row.strNombreAgente}}
                                </td>
                            </ng-container>

                            <!-- strNombreAgente Column -->
                            <ng-container matColumnDef="strEstrellas">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bgTable3 text-dark"> <span class="text-dark fw-bold">Estrellas</span> </th>
                              <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                <span *ngIf="row.strEstrellas == 'A'">
                                  <i class="fas fa-star fs-6 amarilla"></i>
                                  <i class="fas fa-star fs-6 amarilla"></i>
                                  <i class="fas fa-star fs-6 amarilla"></i>
                                </span>
                                <span *ngIf="row.strEstrellas == 'B'">
                                  <i class="fas fa-star fs-6 amarilla"></i>
                                  <i class="fas fa-star fs-6 amarilla"></i>
                                  <i class="far fa-star fs-6 sinFondo"></i>
                                </span>
                                <span *ngIf="row.strEstrellas == 'C'">
                                  <i class="fas fa-star fs-6 amarilla"></i>
                                  <i class="far fa-star fs-6 sinFondo"></i>
                                  <i class="far fa-star fs-6 sinFondo"></i>
                                </span>
                                <span *ngIf="row.strEstrellas == 'Z'">
                                  <i class="far fa-star fs-6 sinFondo"></i>
                                  <i class="far fa-star fs-6 sinFondo"></i>
                                  <i class="far fa-star fs-6 sinFondo"></i>
                                </span>
                              </td>
                          </ng-container>

                          <!-- intSucursales Column -->
                          <ng-container matColumnDef="intSucursales">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bgTable3 text-dark"><span class="text-dark fw-bold">Sucursales</span> </th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                              <span class="cantidadSucursales m-auto">{{row.intSucursales}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="strFechaCreacion">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header
                              class="fs13 pe-0 bgTable3 "> <span class="text-dark fw-bold">Fecha Creación</span>  </th>
                          <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                            {{row.strFechaCreacion}}
                          </td>
                        </ng-container>
                            <tr mat-header-row class="rounded sticky-top " *matHeaderRowDef="displayFamilias">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: displayFamilias;" class="pointer" (click)="enviarDetalle(row)"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">No se encontraron datos de: "{{input.value}}"</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <mat-paginator class="bgpaginator borderbott shadow-sm" [pageSizeOptions]="[25, 50, 100, 200]" aria-label="Select page of users" #paginadorTareasCte></mat-paginator>
                </div>
              </div>
           </div>
           <!--  <div class="row bgtable py-2 ps-2 mx-auto mt-3 w-100 bordertop">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <mat-form-field class="bg-white rounded" appearance="outline">
                  <mat-label>Buscar</mat-label>
                  <input class="text-dark" matInput placeholder="Ingrese el dato a filtrar" 
                    [(ngModel)]="searchText">
                  <mat-icon matSuffix class="text-dark">search</mat-icon>
                </mat-form-field>
             
              </div>
            </div>
            <div class="table-responsive mb-0">
              <div class="col-12 d-flex justify-content-center py-4" *ngIf="spinerFamilias">
                <mat-spinner diameter="75" strokeWidth="8"></mat-spinner>
              </div> -->













        <!--       <div class="mat-elevation-z8" *ngIf="!spinerFamilias">
                <table class=" table table-bordered bg-white table-hover">
                  <thead class="bgTable3">
                    <tr class="bgtable sticky-top bgTable3">
                      <th class="text-center text-dark bgTable3">#</th>
                      <th class="text-center text-dark bgTable3">Logo</th>
               
                      <th class="text-center text-dark bgTable3">Nombre</th>
                      <th class="text-center text-dark bgTable3">Sector</th>
                      <th class="text-center text-dark bgTable3">Propietario</th>
                      <th class="text-center text-dark bgTable3">Estrellas</th>
                      <th class="text-center text-dark bgTable3">Sucursales</th>
                      <th class="text-center text-dark bgTable3 ">Fecha Creación</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr (click)="enviarDetalle(item)" *ngFor="let item of arrayListadoFamilias | filtersearch: searchText: ['strFamilia']; let i = index">
                      <td class="text-center align-middle">
                        <span class="rounded fw-bold px-2 py-1 fs11">{{ i + 1 }}</span>
                      </td>
                      <td class="text-center fw-bold fs11 cursorHand" >
                        <img *ngIf="!item.strUrl_Logo" src="assets/detoportunidad/noDisponible.jpg" width="100px" alt="">
                        <img *ngIf="item.strUrl_Logo" src="https://www.ecodeli.com/{{item.strUrl_Logo}}" width="100px" alt=""></td>
                      <td class="text-center fs11 fw-bold align-middle">
                        <a class="text-primary">{{item.strFamilia}}</a>
                      </td>
                      <td class="text-center fs11 align-middle">{{item.strGiro}}</td>
                      <td class="text-center fs11 align-middle">{{item.strNombreAgente}}</td>
                      <td class="text-center fs11 align-middle">
                        <span *ngIf="item.strEstrellas == 'A'">
                          <i class="fas fa-star fs-6 amarilla"></i>
                          <i class="fas fa-star fs-6 amarilla"></i>
                          <i class="fas fa-star fs-6 amarilla"></i>
                        </span>
                        <span *ngIf="item.strEstrellas == 'B'">
                          <i class="fas fa-star fs-6 amarilla"></i>
                          <i class="fas fa-star fs-6 amarilla"></i>
                          <i class="far fa-star fs-6 sinFondo"></i>
                        </span>
                        <span *ngIf="item.strEstrellas == 'C'">
                          <i class="fas fa-star fs-6 amarilla"></i>
                          <i class="far fa-star fs-6 sinFondo"></i>
                          <i class="far fa-star fs-6 sinFondo"></i>
                        </span>
                        <span *ngIf="item.strEstrellas == 'Z'">
                          <i class="far fa-star fs-6 sinFondo"></i>
                          <i class="far fa-star fs-6 sinFondo"></i>
                          <i class="far fa-star fs-6 sinFondo"></i>
                        </span>
                      </td>
                      <td class="text-center fs11 align-middle"><span class="cantidadSucursales m-auto">{{item.intSucursales}}</span>  </td>
                      <td class="text-center fs11 align-middle"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->
         <!--    <div class="row">
              <div class="col-12">
                <mat-paginator #paginatorProspectos [pageSizeOptions]="[100,150,200,250]"
                  class="bgpaginator borderbott mb-3 shadow-sm" aria-label="Select page of users"
                  [length]="arrayListadoFamilias.length" (page)="onPageChange($event)"></mat-paginator>
              </div>
            </div> -->

      <!--     </div> -->
          <!-- Finaliza tab de prospecto -->
          <!--  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div> -->
        </div>
      </div>

    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-1">
          <div class="row align-items-center">
            <div class="col-12 my-2 ms-3">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active py-0 px-2 fs12 d-flex align-items-center" id="pills-home-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"
                    aria-controls="pills-home" aria-selected="true">
                    <span class="material-symbols-outlined fs15">account_circle</span> Cliente
                  </button>
                </li>
                <li class="nav-item mx-2" role="presentation">
                  <button class="nav-link py-0 px-2 fs12 d-flex align-items-center" id="pills-profile-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                    aria-controls="pills-profile" aria-selected="false">
                    <span class="material-symbols-outlined fs15 ">event_note</span> Estado de cuenta
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link py-0 px-2 fs12 d-flex align-items-center" id="pills-contact-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab"
                    aria-controls="pills-contact" aria-selected="false">
                    <span class="material-symbols-outlined fs15">history</span> Historico Vtas.
                  </button>
                </li>
                <li class="nav-item mx-2" role="presentation">
                  <button class="nav-link py-0 px-2 fs12 d-flex align-items-center" id="pills-profile-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-oter" type="button" role="tab"
                    aria-controls="pills-profile" aria-selected="false">
                    <span class="material-symbols-outlined fs15">
                      forms_add_on
                    </span>
                    <span>Información Adicional</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link py-0 px-2 fs12 d-flex align-items-center" id="pills-contact-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-oter1" type="button" role="tab"
                    aria-controls="pills-contact" aria-selected="false">
                    <span class="material-symbols-outlined fs15">
                      user_attributes
                    </span>
                    <span>Flujo CTE.</span>
                  </button>
                </li>
                <li class="nav-item mx-2" role="presentation">
                  <button class="nav-link py-0 px-2 fs12 d-flex align-items-center" id="pills-contact-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-hisXlinea" type="button" role="tab"
                    aria-controls="pills-contact" aria-selected="false">
                    <span class="material-symbols-outlined fs15">
                      turn_sharp_right
                    </span>
                    <span>Vtas. Por Linea</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link py-0 px-2 fs12 d-flex align-items-center" id="pills-contact-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-reportVtas" type="button" role="tab"
                    aria-controls="pills-contact" aria-selected="false">
                    <span class="material-symbols-outlined fs15">
                      bar_chart
                    </span>
                    <span>Reporte de Vtas.</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <button type="button" class="btn-close mx-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div class="row">
                    <div class="col-12 col-lg-5 d-flex flex-column">
                      <h2 class="mb-0 fw-bold me-3 d-flex align-items-center">
                        <span class="">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                      </h2>
                      <span>
                        <span class="fs11 me-3">
                          <i class="bi bi-diamond-fill me-2" style="color: #00ff37;"></i>Fecha de ultima compra:
                        </span>
                        <span class="fs12 fw-bold">
                          {{ objModal.strUltimaFecha?.toString() | date:'MM/dd/yyyy' }}
                        </span>
                      </span>
                    </div>
                    <div class="col-12 col-lg-7 ">
                      <div class="row">
                        <div class="col-6 col-md-3 d-flex flex-column">
                          <div class="row">
                            <div class="col-11  shadow-sm rounded py-2 bg-light d-flex flex-column">
                              <span class="text-center fs12 fw-bold d-flex align-items-center justify-content-center ">
                                <span class="material-symbols-outlined fs17 me-1">
                                  verified_user
                                </span>
                                Objetivo
                              </span>
                              <span class="fs-5 text-center fw-bold bg1">
                                {{objModal.dblObjetivoVta|currency}}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-3 d-flex flex-column">
                          <div class="row ">
                            <div class="col-11 text-center shadow-sm rounded py-2 bg-light d-flex flex-column">
                              <span class=" fs12 fw-bold d-flex align-items-center justify-content-center">
                                <span class="material-symbols-outlined fs17 me-2">
                                  currency_exchange
                                </span>
                                Venta
                              </span>
                              <span class="text-center fs-5 fw-bold bg2">
                                {{objModal.dblVenta|currency}}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-3 d-flex flex-column ">
                          <div class="row ">
                            <div class="col-11 text-center shadow-sm rounded py-2 bg-light d-flex flex-column">
                              <span class="text-center fs12 fw-bold d-flex align-items-center justify-content-center">
                                <span class="material-symbols-outlined fs17 me-1">
                                  hide
                                </span>
                                Diferencia
                              </span>
                              <span class="fs-5  fw-bold bg3">
                                {{objModal.dblFaltaVender|currency}}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-3 d-flex flex-column">
                          <div class="row">
                            <div class="col-11 text-center shadow-sm rounded py-2 bg-light d-flex flex-column">
                              <span class="text-center fs12 fw-bold d-flex align-items-center justify-content-center">
                                <span class="material-symbols-outlined fs17 me-1">
                                  trending_up
                                </span>
                                Porcentaje avance
                              </span>
                              <span class="text-center fs-5 fw-bold text-nowrap bg4">
                                {{(objModal.dblObjetivoVta>0?((objModal.dblVenta/objModal.dblObjetivoVta)*100):0)|number:'1.2-2'}}%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <hr>
                    </div>
                  </div>
                  <div class="row" *ngIf="spinerCte">
                    <div class="col-12 d-flex justify-content-center">
                      <mat-spinner></mat-spinner>
                    </div>
                  </div>
                  <div *ngIf="!spinerCte" class="row justify-content-evenly">
                    <div class="col-12 col-md-5  shadow borderbott bordertop ">
                      <div class="col-12 table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th colspan="2" class="text-center">
                                DATOS PERSONALES
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-geo-alt"></i> Direccion:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strDireccion}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-geo"></i>Colonia:</td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strColonia}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i
                                  class="bi bi-globe-americas"></i>Poblacion:</td>
                              <td class="ps-0 fs12 fw-bold ">
                                {{objModal.strPoblacion}}
                              </td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-map"></i> Estado:</td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strEstado}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-telephone"></i> Telefono:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strTelefonos}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-bag"></i> Conctacto:</td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strContacto1}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-envelope-at"></i> Email:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.streMail2}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-house-door"></i> Almacen:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strAlmacen}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-people"></i> Familia:</td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strFamilia}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start d-flex">
                                <span class="material-symbols-outlined fs17">
                                  post_add
                                </span>
                                Datos Importantes:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strComentarios}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-12 col-md-5  shadow borderbott bordertop">
                      <div class="col-12 table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th colspan="2" class="text-center">
                                DATOS FISCALES
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-bank"></i> Clave uso CFDI:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strClaveUsoCFDI}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-bank"></i> Descrip. uso
                                CFDI:</td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strDescripcionUso}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-cash-coin"></i> Forma Pago:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strDescripcionFP}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-arrow-repeat"></i> Giro:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strDescripcion12}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start"><i class="bi bi-safe2"></i> Clave Banco:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.intClaveBanco}}</td>
                            </tr>
                            <tr class="border-bottom align-middle">
                              <td class="fw-bold bg1 text-nowrap text-start d-flex border-bottom-0 ">
                                <span class="material-symbols-outlined fs17">account_balance_wallet</span>
                                Nombre Banco:
                              </td>
                              <td class="ps-0 fs12 fw-bold border-bottom-0">{{objModal.strNombreBanco}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start d-flex"><span
                                  class="material-symbols-outlined fs17">
                                  support_agent
                                </span> Nombre agente:</td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strAgenteNombre}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1  text-nowrap text-start"> <i
                                  class="bi bi-credit-card-2-back"></i>Pagos:</td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strContacto2}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start d-flex"><span
                                  class="material-symbols-outlined fs17">handshake</span> Condición de pago:</td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strCondicionPago}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start d-flex">
                                <span class="material-symbols-outlined fs17">checkbook</span>Número de cuenta:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strNumeroCuenta}}</td>
                            </tr>
                            <tr>
                              <td class="fw-bold bg1 text-nowrap text-start d-flex">
                                <span class="material-symbols-outlined fs17">vpn_key</span> Clabe Interbancaria:
                              </td>
                              <td class="ps-0 fs12 fw-bold ">{{objModal.strClaveInterbancaria}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div class="row" *ngIf="spinerEdoCta">
                    <div class="col-12 d-flex justify-content-center">
                      <mat-spinner></mat-spinner>
                    </div>
                  </div>
                  <div class="row " *ngIf="!spinerEdoCta">
                    <div class="col-10 shadow borderbott bordertop mx-auto my-3">
                      <div class="row">
                        <div class=" table-responsive mx-auto ">
                          <table class="table">
                            <thead>
                              <tr class="thcenter sticky-top bg-white">
                                <th class="bg1"><i class="bi bi-calendar-event"></i> Ejercicio</th>
                                <th class="bg1"><i class="bi bi-hourglass-bottom"></i> Vencido</th>
                                <th class="bg1"><i class="bi bi-hourglass-split"></i> Por Vencer</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="tdcenter" *ngFor="let o of arrayEstadoCuenta">
                                <td>{{o.strEjercicioVencimiento }}</td>
                                <td>{{o.dblVencido |currency}}</td>
                                <td>{{o.dblPorVencer|currency}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="fw-bold mb-0 text-center">HISTORICO DE VENTAS</h2>
                    </div>
                  </div>
                  <div class="row" *ngIf="spinerHisVta">
                    <div class="col-12 d-flex justify-content-center">
                      <mat-spinner></mat-spinner>
                    </div>
                  </div>
                  <div class="row " *ngIf="!spinerHisVta">
                    <div class="col-11 borderbott bordertop shadow-sm mx-auto pb-3">
                      <h3 class="my-1">INFORMACIÓN</h3>
                      <div class="col-12">
                        <hr class="mt-0">
                      </div>
                      <div class="row justify-content-evenly pb-2">
                        <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1">
                          <div class="row">
                            <div class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                              <div class="col-12 d-flex flex-column">
                                <span class="mx-auto fs11">
                                  AGENTE
                                </span>
                                <span class="material-symbols-outlined mx-auto">
                                  account_circle
                                </span>
                              </div>
                            </div>
                            <div class="col-9 d-flex flex-column">
                              <span class="fs12 fw-bold">{{objModalHistorico?.strAgenteNombre}}</span>
                              <span class="fs12 fw-bold clsHisto">{{objModalHistorico?.strAgenteEmail}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1 my-2 my-lg-0">
                          <div class="row">
                            <div class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                              <div class="col-12 d-flex flex-column">
                                <span class="mx-auto fs11">
                                  COBRADOR
                                </span>
                                <span class="material-symbols-outlined mx-auto">
                                  car_tag
                                </span>
                              </div>
                            </div>
                            <div class="col-9 d-flex flex-column">
                              <span class="fs12 fw-bold">{{objModalHistorico?.strNombreCobrador}}</span>
                              <span class="fs12 fw-bold clsHisto">{{objModalHistorico?.strCobradorEmail}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-evenly pb-2">
                        <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1">
                          <div class="row">
                            <div class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                              <div class="col-12 d-flex flex-column">
                                <span class="mx-auto fs11">
                                  DIVISIONAL
                                </span>
                                <span class="material-symbols-outlined mx-auto">
                                  deployed_code_account
                                </span>
                              </div>
                            </div>
                            <div class="col-9 d-flex flex-column">
                              <span class="fs12 fw-bold">{{objModalHistorico?.strNombreDivisional}}</span>
                              <span class="fs12 fw-bold clsHisto">{{objModalHistorico?.strEmailDivisional}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1 my-2 my-lg-0">
                          <div class="row">
                            <div class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                              <div class="col-12 d-flex flex-column">
                                <span class="mx-auto fs11">
                                  ADMINISTRATIVO
                                </span>
                                <span class="material-symbols-outlined mx-auto">
                                  manage_accounts
                                </span>
                              </div>
                            </div>
                            <div class="col-9 d-flex flex-column">
                              <span class="fs12 fw-bold">{{objModalHistorico?.strNombreAdministrativo}}</span>
                              <span class="fs12 fw-bold clsHisto">{{objModalHistorico?.strEmailAdministrativo}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-evenly pb-2">
                        <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1">
                          <div class="row">
                            <div class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                              <div class="col-12 d-flex flex-column">
                                <span class="mx-auto fs11">
                                  SUCURSAL
                                </span>
                                <span class="material-symbols-outlined mx-auto">
                                  source_environment
                                </span>
                              </div>
                            </div>
                            <div class="col-9 d-flex flex-column">
                              <span class="fs12 fw-bold">{{objModalHistorico?.strSucursalNombre}}</span>
                              <span class="fs12 fw-bold clsHisto">{{objModalHistorico?.strEmailCliente}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-10 col-lg-5 rounded shadow-sm bgHisto py-1 my-2 my-lg-0">
                          <div class="row">
                            <div class="col-3 border-end border-dark d-flex align-items-center justify-content-center">
                              <div class="col-12 d-flex flex-column">
                                <span class="mx-auto fs11">
                                  CLABE
                                </span>
                                <span class="material-symbols-outlined mx-auto">
                                  account_balance
                                </span>
                              </div>
                            </div>
                            <div class="col-9 d-flex flex-column">
                              <span class="fs12 fw-bold">{{objModalHistorico?.strClaveInterbancaria}}</span>
                              <span class="fs12 fw-bold clsHisto">CTA:{{objModalHistorico?.strNumeroCuenta}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row  mt-3" *ngIf="!spinerHisVta">
                    <div class="col-11 borderbott bordertop shadow mx-auto pb-3 bgtabHistory">
                      <div class="table-responsive ">
                        <table class="table">
                          <thead>
                            <tr class="thcenter sticky-top clsHisto bgtabHistory">
                              <th class=""><span class="material-symbols-outlined align-middle">
                                  date_range
                                </span><span class="align-middle">EJERCICIO</span></th>
                              <th class=""><span class="material-symbols-outlined align-middle">
                                  calendar_month
                                </span><span class="align-middle">PERIODO</span></th>
                              <th class=""> <span class="material-symbols-outlined align-middle">
                                  paid
                                </span><span class="align-middle">IMPORTE</span></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="tdcenter" *ngFor="let o of arrayHistoricoVta">
                              <td>{{o.strEjercicio}}</td>
                              <td>{{fnNamePeriodo(o.strPeriodo)}}</td>
                              <td>{{o.dblImporte|currency}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-oter" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div class="row">
                    <div class="col-11 mx-auto py-2 d-flex justify-content-center">
                      <h2 class="mb-0 fw-bold text-center  mx-auto">ESTADO DE CUENTA</h2>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-11 d-flex justify-content-between mx-auto">
                      <div class="col-8 col-md-3">
                        <input type="text" class="form-control " placeholder="Movimiento:"
                          [(ngModel)]="inputSearchInfoAdd">
                      </div>
                      <span class="text-danger fs-2" (click)="fncreatepdf('cuentacte')"><i
                          class="bi bi-file-pdf"></i></span>
                    </div>
                  </div>
                  <div class="row" *ngIf="spinerInfAdd">
                    <div class="col-12 d-flex justify-content-center">
                      <mat-spinner></mat-spinner>
                    </div>
                  </div>
                  <div class="row" *ngIf="!spinerInfAdd">
                    <div class="col-11 mx-auto borderbott bordertop shadow bgtableinfoadd">
                      <h2 class="me-auto fw-bold py-2 ps-3 mb-0">
                        <span class="bg4">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                      </h2>
                      <div class="table-responsive mb-0">
                        <table class="table table-hover">
                          <thead>
                            <tr class="fs12 thcenter">
                              <th>Movimiento</th>
                              <th>Fecha Emisión</th>
                              <th>Fecha Vencimiento</th>
                              <th>Días</th>
                              <th>Saldo Corriente</th>
                              <th>de 1 a 15</th>
                              <th>de 16 a 30</th>
                              <th>de 31 a 60</th>
                              <th>de 61 a 90</th>
                              <th>más de 90</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="fs12 tdcenter"
                              *ngFor="let o of arrayInfoAdicional | filtersearch: inputSearchInfoAdd : ['strMovID'];">
                              <td>{{o.strMovID}}</td>
                              <td>{{o.strFechaEmision}}</td>
                              <td>{{o.strVencimiento}}</td>
                              <td>{{o.strDias}}</td>
                              <td>{{o.dblSaldoCorriente|currency}}</td>
                              <td>{{o.dblSaldo15}}</td>
                              <td>{{o.dblSaldo30}}</td>
                              <td>{{o.dblSaldo60}}</td>
                              <td>{{o.dblSaldo90}}</td>
                              <td>{{o.dblSaldoA}}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr class="tdcenter">
                              <td colspan="4" class="text-center fw-bold"> TOTAL</td>
                              <td class="fw-bold fs13">{{intSaldoCorriente|currency}}</td>
                              <td class="fw-bold fs13">{{int15dias|currency}}</td>
                              <td class="fw-bold fs13">{{int30dias|currency}}</td>
                              <td class="fw-bold fs13">{{int60dias|currency}}</td>
                              <td class="fw-bold fs13">{{int90dias|currency}}</td>
                              <td class="fw-bold fs13">{{int90mas|currency}}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div [hidden]="!booltitl">
                    <div class="row" #pdfTableInfoAdd>
                      <div class="col-11 mx-auto py-2 d-flex justify-content-center ">
                        <div>
                          <h2 class="mb-0 fw-bold text-center  mx-auto">ESTADO DE CUENTA</h2>
                        </div>
                      </div>
                      <div class="col-11 mx-auto borderbott bordertop shadow bgtableinfoadd">
                        <h2 class="me-auto fw-bold py-2 ps-3 mb-0">
                          <span class="bg4">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                        </h2>
                        <div class="table-responsive mb-0">
                          <table class="table table-hover">
                            <thead>
                              <tr class="fs12 thcenter">
                                <th>Movimiento</th>
                                <th>Fecha Emisión</th>
                                <th>Fecha Vencimiento</th>
                                <th>Días</th>
                                <th>Saldo Corriente</th>
                                <th>de 1 a 15</th>
                                <th>de 16 a 30</th>
                                <th>de 31 a 60</th>
                                <th>de 61 a 90</th>
                                <th>más de 90</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="fs12 tdcenter"
                                *ngFor="let o of arrayInfoAdicional | filtersearch: inputSearchInfoAdd : ['strMovID'];">
                                <td>{{o.strMovID}}</td>
                                <td>{{o.strFechaEmision}}</td>
                                <td>{{o.strVencimiento}}</td>
                                <td>{{o.strDias}}</td>
                                <td>{{o.dblSaldoCorriente|currency}}</td>
                                <td>{{o.dblSaldo15}}</td>
                                <td>{{o.dblSaldo30}}</td>
                                <td>{{o.dblSaldo60}}</td>
                                <td>{{o.dblSaldo90}}</td>
                                <td>{{o.dblSaldoA}}</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr class="tdcenter">
                                <td colspan="4" class="text-center fw-bold"> TOTAL</td>
                                <td class="fw-bold fs13">{{intSaldoCorriente|currency}}</td>
                                <td class="fw-bold fs13">{{int15dias|currency}}</td>
                                <td class="fw-bold fs13">{{int30dias|currency}}</td>
                                <td class="fw-bold fs13">{{int60dias|currency}}</td>
                                <td class="fw-bold fs13">{{int90dias|currency}}</td>
                                <td class="fw-bold fs13">{{int90mas|currency}}</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-oter1" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div class="row">
                    <div class="col-11 mx-auto py-2 d-flex justify-content-center">
                      <h2 class="mb-0 fw-bold text-center  mx-auto ">FLUJO DE CLIENTE</h2>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-11 d-flex justify-content-between mx-auto">
                      <div class="col-8 col-md-3">
                        <input type="text" class="form-control " placeholder="cte, periodo, ejercicio:"
                          [(ngModel)]="inputFlujoCte">
                      </div>
                      <span class="text-danger fs-2" (click)="fncreatepdf('flujoCte')"><i
                          class="bi bi-file-pdf"></i></span>
                    </div>
                  </div>
                  <div class="row" *ngIf="spinerFlujoCte">
                    <div class="col-12 d-flex justify-content-center">
                      <mat-spinner></mat-spinner>
                    </div>
                  </div>
                  <div class="row" *ngIf="!spinerFlujoCte">
                    <div class="col-11 mx-auto borderbott bordertop shadow bgflujocte">
                      <h2 class="me-auto fw-bold py-2 ps-3 mb-0">
                        <span class="bg4">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                      </h2>
                      <div class="table-responsive mb-0">
                        <table class="table table-hover">
                          <thead>
                            <tr class="thcenter">
                              <th colspan="3" class="text-end">Totales:</th>
                              <th>{{intTotalImportFCte|currency}}</th>
                              <th>{{intCobradoFCte|currency}}</th>
                              <th>{{IntSaldoFCte|currency}}</th>
                            </tr>
                            <tr class="fs12 thcenter">
                              <th>Cliente</th>
                              <th>Perido</th>
                              <th>Ejercicio</th>
                              <th>Importe Ventas</th>
                              <th>Cobrado</th>
                              <th>Saldo</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="fs12 tdcenter"
                              *ngFor="let o of arrayFlujocte | filtersearch: inputFlujoCte : ['intPeriodo','intEjercicio','strNombre','strCliente'];">
                              <td><span class="fw-bold">({{o.strCliente}})</span>{{o.strNombre}}</td>
                              <td>{{o.intPeriodo}}</td>
                              <td>{{o.intEjercicio}}</td>
                              <td>{{o.dblImporte|currency}}</td>
                              <td>{{o.dblCobrado|currency}}</td>
                              <td>{{o.dblSaldo|currency}}</td>
                            </tr>
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                  <div [hidden]="!booltitl">
                    <div class="row" #pdftableFlujoCte>
                      <div class="row">
                        <div class="col-11 mx-auto py-2 d-flex justify-content-center">
                          <h2 class="mb-0 fw-bold text-center  mx-auto bgflujoctext">FLUJO DE CLIENTE</h2>
                        </div>
                      </div>
                      <div class="col-11 mx-auto borderbott bordertop shadow bgflujocte">
                        <h2 class="me-auto fw-bold py-2 ps-3 mb-0">
                          <span class="bg4">({{objModal.strCliente}})</span>{{objModal.strNombre}}
                        </h2>
                        <div class="table-responsive mb-0">
                          <table class="table table-hover">
                            <thead>
                              <tr class="thcenter">
                                <th colspan="3" class="text-end">Totales:</th>
                                <th>{{intTotalImportFCte|currency}}</th>
                                <th>{{intCobradoFCte|currency}}</th>
                                <th>{{IntSaldoFCte|currency}}</th>
                              </tr>
                              <tr class="fs12 thcenter">
                                <th>Cliente</th>
                                <th>Perido</th>
                                <th>Ejercicio</th>
                                <th>Importe Ventas</th>
                                <th>Cobrado</th>
                                <th>Saldo</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="fs12 tdcenter"
                                *ngFor="let o of arrayFlujocte | filtersearch: inputFlujoCte : ['intPeriodo','intEjercicio','strNombre','strCliente'];">
                                <td><span class="fw-bold">({{o.strCliente}})</span>{{o.strNombre}}</td>
                                <td>{{o.intPeriodo}}</td>
                                <td>{{o.intEjercicio}}</td>
                                <td>{{o.dblImporte|currency}}</td>
                                <td>{{o.dblCobrado|currency}}</td>
                                <td>{{o.dblSaldo|currency}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-hisXlinea" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div class="row">
                    <div class="col-12 text-center">
                      <h1 class="my-2">HISTORICO DE CLIENTE POR LINEA</h1>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-11 borderbott bordertop shadow-sm">
                      <div class="row">
                        <div class="col-12 d-flex ">
                          <h3 class="mx-auto fw-bold  mb-0 d-flex align-items-center">
                            <i class="bi bi-person-circle me-2 fs-3"></i>
                            <span class="d-flex flex-column">
                              <span class="mx-auto">{{objVtasXlinea.strNombre}}</span>
                              <span class="bg4 mx-auto">({{objVtasXlinea.strCliente}})</span>
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div class="row justify-content-evenly mb-3">
                        <div class="col-11 col-md-5 bgtableinfoadd borderbott bordertop shadow">
                          <h2 class="text-center mb-2">Estrellas</h2>
                          <div class="row">
                            <div class="col-12 text-center">
                              <span>
                                <i class="bi bi-star-fill text-warning fs-3"></i>
                                <i class="bi bi-star-fill text-warning fs-3"
                                  *ngIf="objVtasXlinea.strClasificacionE==2 || objVtasXlinea.strClasificacionE==3"></i>
                                <i class="bi bi-star-fill text-warning fs-3"
                                  *ngIf="objVtasXlinea.strClasificacionE==3"></i>
                              </span>
                            </div>
                          </div>
                          <div class="row my-2">
                            <div class="col-12 fs11 fw-bold">
                              Catalogar al Cliente conforme a Potencial de Venta.
                            </div>
                            <div class="col-12 fs11">
                              <i class="bi bi-star-fill text-warning fs10"></i> - No se le puede vender más de su
                              potencial de venta.
                            </div>
                            <div class="col-12 fs11">
                              <i class="bi bi-star-fill text-warning fs10"></i>
                              <i class="bi bi-star-fill text-warning fs10"></i> - Venta superior al 70% de su potencial
                              de venta.
                            </div>
                            <div class="col-12 fs11">
                              <i class="bi bi-star-fill text-warning"></i>
                              <i class="bi bi-star-fill text-warning"></i>
                              <i class="bi bi-star-fill text-warning"></i> - Tiene buena capacidad de compra, hay que
                              redoblar esfuerzos.
                            </div>
                          </div>
                        </div>
                        <div class="mt-3 mt-md-0 col-11 col-md-5 bgtableinfoadd borderbott bordertop shadow">
                          <h2 class="text-center mb-2">Clasificación</h2>
                          <div class="row">
                            <div class="col-12 text-center">
                              <span class="text-primary fs-3 fw-bold">A</span>
                              <span class="text-primary fs-3 fw-bold"
                                *ngIf="objVtasXlinea.strClasificacionA==2 || objVtasXlinea.strClasificacionA==3">A</span>
                              <span class="text-primary fs-3 fw-bold"
                                *ngIf="objVtasXlinea.strClasificacionA==3">A</span>

                            </div>
                          </div>
                          <div class="row my-2">
                            <div class="col-12 fs11 fw-bold">
                              Clasificación del Cliente conforme al monto de compra.
                            </div>
                            <div class="col-12 fs11">
                              <span class="text-primary fw-bold fs12">A</span> - Monto de compra menor a $10,000
                            </div>
                            <div class="col-12 fs11">
                              <span class="text-primary fw-bold fs12">A</span>
                              <span class="text-primary fw-bold fs12">A</span> - Monto de compra mayor a $10,000 y menor
                              a $50,000
                            </div>
                            <div class="col-12 fs11">
                              <span class="text-primary fw-bold fs12">A</span>
                              <span class="text-primary fw-bold fs12">A</span>
                              <span class="text-primary fw-bold fs12">A</span> - Monto de compra mayor a $50,000
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row bgtable py-2 ps-2 mx-auto mt-3 w-100 bordertop">
                      <div class="col-12 d-flex justify-content-between align-items-center">
                        <mat-form-field class="bg-white rounded" appearance="outline">
                          <mat-label>Buscar</mat-label>
                          <input class="text-dark" matInput (keyup)="applyFilterhisxvta($event)"
                            placeholder="Ingrese el dato a filtrar" #inputHisVe>
                          <mat-icon matSuffix class="text-dark">search</mat-icon>
                        </mat-form-field>
                        <span class="fs14 fw-bold">Movimientos: {{arrayAjusteLineas.length}}</span>
                      </div>
                    </div>
                    <div class="row" *ngIf="spinerVtaXlin">
                      <div class="col-12 d-flex justify-content-center">
                        <mat-spinner></mat-spinner>
                      </div>
                    </div>
                    <div class="table-responsive mb-0  px-0" *ngIf="!spinerVtaXlin">
                      <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataHisXvta" class="table table-bordered table-hover">

                          <ng-container matColumnDef="ejercicio">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top "> Ejercicio
                            </th>
                            <td mat-cell *matCellDef="let row;let i = index;"
                              class=" align-middle ps-0 fs12 fw-bold text-center">{{row.strEjercicio}}</td>
                            <td mat-footer-cell *matFooterCellDef> </td>
                          </ng-container>

                          <ng-container matColumnDef="periodo">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Periodo </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs12 fw-bold">
                              {{row.strPeriodo}}</td>
                            <td mat-footer-cell *matFooterCellDef class="fw-bold fs15">Total:</td>
                          </ng-container>

                          <ng-container matColumnDef="acces">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Accesorios </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{row.dblAccesorios|currency}} </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblAccesorios|currency}} </td>
                          </ng-container>

                          <ng-container matColumnDef="margenA">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{(row.dblAccesoriosMargen !== 0) ? (row.dblAccesoriosMargen | number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblAccesoriosMargen<50,'bg-warning':row.dblAccesoriosMargen >=50 && row.dblAccesoriosMargen<90,'bg-success':row.dblAccesoriosMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblAccesoriosMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblAccesoriosMargen !== 0) ? (objHisxLin.dblAccesoriosMargen |
                              number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblAccesoriosMargen<50,'bg-warning':objHisxLin.dblAccesoriosMargen >=50 && objHisxLin.dblAccesoriosMargen<90,'bg-success':objHisxLin.dblAccesoriosMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblAccesoriosMargen"></div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="kimberly">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top "> Kimberly
                            </th>
                            <td mat-cell *matCellDef="let row;let i = index;" class=" align-middle ps-0 fs11 fw-bold">
                              {{row.dblKimberly|currency}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblKimberly|currency}} </td>
                          </ng-container>

                          <ng-container matColumnDef="margenk">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{(row.dblKimberlyMargen !== 0) ? (row.dblKimberlyMargen | number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblKimberlyMargen<50,'bg-warning':row.dblKimberlyMargen >=50 && row.dblKimberlyMargen<90,'bg-success':row.dblKimberlyMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblKimberlyMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblKimberlyMargen !== 0) ? (objHisxLin.dblKimberlyMargen | number:'1.2-2') :
                              0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblKimberlyMargen<50,'bg-warning':objHisxLin.dblKimberlyMargen >=50 && objHisxLin.dblKimberlyMargen<90,'bg-success':objHisxLin.dblKimberlyMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblKimberlyMargen"></div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="rubermaid">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Rubbermaid </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{row.dblRubbermaid|currency }}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblRubbermaid|currency }} </td>
                          </ng-container>

                          <ng-container matColumnDef="margenru">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{(row.dblRubbermaidMargen !== 0) ? (row.dblRubbermaidMargen | number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblRubbermaidMargen<50,'bg-warning':row.dblRubbermaidMargen >=50 && row.dblRubbermaidMargen<90,'bg-success':row.dblRubbermaidMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblRubbermaidMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblRubbermaidMargen !== 0) ? (objHisxLin.dblRubbermaidMargen |
                              number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblRubbermaidMargen<50,'bg-warning':objHisxLin.dblRubbermaidMargen >=50 && objHisxLin.dblRubbermaidMargen<90,'bg-success':objHisxLin.dblRubbermaidMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblRubbermaidMargen"></div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="bluetisue">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top "> BlueTissue
                            </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{row.dblBlueTissue|currency}} </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblBlueTissue|currency}} </td>
                          </ng-container>

                          <ng-container matColumnDef="margenblu">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row;let i = index;" class=" align-middle ps-0 fs11 fw-bold">
                              {{(row.dblBlueTissueMargen !== 0) ? (row.dblBlueTissueMargen | number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblBlueTissueMargen<50,'bg-warning':row.dblBlueTissueMargen >=50 && row.dblBlueTissueMargen<90,'bg-success':row.dblBlueTissueMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblBlueTissueMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblBlueTissueMargen !== 0) ? (objHisxLin.dblBlueTissueMargen |
                              number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblBlueTissueMargen<50,'bg-warning':objHisxLin.dblBlueTissueMargen >=50 && objHisxLin.dblBlueTissueMargen<90,'bg-success':objHisxLin.dblBlueTissueMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblBlueTissueMargen"></div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="quimicos">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Quimicos </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{row.dblQuimicos|currency}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblQuimicos|currency}} </td>
                          </ng-container>

                          <ng-container matColumnDef="margenqu">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{(row.dblQuimicosMargen !== 0) ? (row.dblQuimicosMargen | number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblQuimicosMargen<50,'bg-warning':row.dblQuimicosMargen >=50 && row.dblQuimicosMargen<90,'bg-success':row.dblQuimicosMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblQuimicosMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblQuimicosMargen !== 0) ? (objHisxLin.dblQuimicosMargen | number:'1.2-2') :
                              0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblQuimicosMargen<50,'bg-warning':objHisxLin.dblQuimicosMargen >=50 && objHisxLin.dblQuimicosMargen<90,'bg-success':objHisxLin.dblQuimicosMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblQuimicosMargen"></div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="despachadores">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top "> Despachadores
                            </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{row.dblDespachadores|currency}} </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblDespachadores|currency}} </td>
                          </ng-container>

                          <ng-container matColumnDef="margendes">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row;" class="text-center align-middle ps-0 fs11 fw-bold">
                              {{(row.dblDespachadoresMargen !== 0) ? (row.dblDespachadoresMargen | number:'1.2-2') : 0
                              }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblDespachadoresMargen<50,'bg-warning':row.dblDespachadoresMargen >=50 && row.dblDespachadoresMargen<90,'bg-success':row.dblDespachadoresMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblDespachadoresMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblDespachadoresMargen !== 0) ? (objHisxLin.dblDespachadoresMargen |
                              number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblDespachadoresMargen<50,'bg-warning':objHisxLin.dblDespachadoresMargen >=50 && objHisxLin.dblDespachadoresMargen<90,'bg-success':objHisxLin.dblDespachadoresMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblDespachadoresMargen"></div>
                              </div>
                            </td>

                          </ng-container>

                          <ng-container matColumnDef="tork">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Tork </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{row.dblTork|currency}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblTork|currency}} </td>
                          </ng-container>

                          <ng-container matColumnDef="margentork">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{(row.dblTorkMargen !== 0) ? (row.dblTorkMargen | number:'1.2-2') : 0}}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblTorkMargen<50,'bg-warning':row.dblTorkMargen >=50 && row.dblTorkMargen<90,'bg-success':row.dblTorkMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblTorkMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblTorkMargen !== 0) ? (objHisxLin.dblTorkMargen | number:'1.2-2') : 0}}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblTorkMargen<50,'bg-warning':objHisxLin.dblTorkMargen >=50 && objHisxLin.dblTorkMargen<90,'bg-success':objHisxLin.dblTorkMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblTorkMargen"></div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="xibu">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top "> Xibu </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{row.dblXibu|currency}} </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblXibu|currency}} </td>
                          </ng-container>

                          <ng-container matColumnDef="margenx">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 px-2 border-end border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row;" class="text-center align-middle ps-0 fs11 fw-bold">
                              {{(row.dblXibuMargen !== 0) ? (row.dblXibuMargen | number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblXibuMargen<50,'bg-warning':row.dblXibuMargen >=50 && row.dblXibuMargen<90,'bg-success':row.dblXibuMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblXibuMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblXibuMargen !== 0) ? (objHisxLin.dblXibuMargen | number:'1.2-2') : 0 }}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblXibuMargen<50,'bg-warning':objHisxLin.dblXibuMargen >=50 && objHisxLin.dblXibuMargen<90,'bg-success':objHisxLin.dblXibuMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblXibuMargen"></div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="foaom">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Foaom </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{row.dblFoaom|currency}}</td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.dblFoaom|currency}} </td>
                          </ng-container>

                          <ng-container matColumnDef="margenf">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end border-top "> Margen </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold">
                              {{(row.dblFoaomMargen !== 0) ? (row.dblFoaomMargen | number:'1.2-2') : 0}}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':row.dblFoaomMargen<50,'bg-warning':row.dblFoaomMargen >=50 && row.dblFoaomMargen<90,'bg-success':row.dblFoaomMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="row.dblFoaomMargen"></div>
                              </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{(objHisxLin.dblFoaomMargen !== 0) ? (objHisxLin.dblFoaomMargen | number:'1.2-2') : 0}}%
                              <div class="progress" style="height:6px">
                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  [ngClass]="{'bg-danger':objHisxLin.dblFoaomMargen<50,'bg-warning':objHisxLin.dblFoaomMargen >=50 && objHisxLin.dblFoaomMargen<90,'bg-success':objHisxLin.dblFoaomMargen>=90}"
                                  role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                  [style.width.%]="objHisxLin.dblFoaomMargen"></div>
                              </div>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef
                              class="text-center text-dark fw-bold fs12 py-2 border-end px-2 border-top ">Total</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs11 fw-bold pe-0">
                              {{row.total|currency}}
                            <td>
                            <td mat-footer-cell *matFooterCellDef class="text-center align-middle fs12 fw-bold">
                              {{objHisxLin.total|currency}} </td>
                          </ng-container>


                          <tr mat-header-row *matHeaderRowDef="displayhisvtas" class="bgtable"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayhisvtas;"></tr>
                          <tr mat-footer-row *matFooterRowDef="displayhisvtas" class="bgtable borderbott"></tr>
                          <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4" class="">No se encontro el dato: <span
                                class="fw-bold">{{inputHisVe.value}}</span> </td>
                          </tr>
                        </table>
                      </div>
                    </div>


                  </div>
                </div>
                <div class="tab-pane fade" id="pills-reportVtas" role="tabpanel" aria-labelledby="pills-reportVtas-tab">
                  <div class="row">
                    <div class="col-12 text-center">
                      <h3>VENTA POR ARTICULO - {{objVtasXlinea.strNombre}}<span
                          class="bg4 mx-auto">({{objVtasXlinea.strCliente}})</span></h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 d-md-flex justify-content-evenly">
                      <div class="col-12 col-md-5">
                        <mat-form-field appearance="outline">
                          <mat-label>Linea</mat-label>
                          <select matNativeControl [(ngModel)]="strLinea" name="car">
                            <option *ngFor="let lin of arrayLineas" [value]="lin.value">
                              {{lin.viewValue}}
                            </option>
                          </select>
                          <mat-icon matSuffix class="text-dark">search</mat-icon>
                        </mat-form-field>
                      </div>
                      <div class="col-12 col-md-5 mt-4 mt-md-0">
                        <mat-form-field class="bg-white rounded" appearance="outline">
                          <mat-label>Buscar Articulo</mat-label>
                          <input class="text-dark" matInput [(ngModel)]="strBuscar" placeholder="Ingrese el articulo">
                          <mat-icon matSuffix class="text-dark">search</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="spinerVtaXart">
                    <div class="col-12 d-flex justify-content-center">
                      <mat-spinner></mat-spinner>
                    </div>
                  </div>
                  <div class="row mt-3" *ngIf="!spinerVtaXart">
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-bodered">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th colspan="12" *ngFor="let i of arrayCabeceraXart;let ind = index" class="text-center"
                                [ngClass]="{'bgedocta1': ind % 2 === 0, 'bgst': ind % 2 !== 0}">
                                {{i.strEjercicio}}
                              </th>
                              <th></th>
                            </tr>
                            <tr>
                              <th>Articulo</th>
                              <th>Descripcion</th>
                              <ng-container *ngFor="let i of arrayCabeceraXart; let ind = index">
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Ene</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Feb</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Mar</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Abr</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">May</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Jun</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Jul</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Ago</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Sep</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Oct</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Nov</th>
                                <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">Dic</th>
                              </ng-container>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let f of arrayArts | filtersearch: strBuscar: ['strArticulo']|filtersearch: strLinea: ['strLinea']; let j = index">
                              <td class="fs12 fw-bold  text-center align-middle "><span
                                  class="p-1 bgtabHistory rounded">{{f.strArticulo}}</span></td>
                              <td class="fs10">{{f.strDescripcion}}</td>
                              <ng-container *ngFor="let i of arrayCabeceraXart; let ind = index">
                                <td class="align-middle text-center "
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('ene' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['ene' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock>
                                    <span class="text-secondary fs13 fw-bold">
                                      0
                                    </span>
                                  </ng-template>
                                </td>
                                <td class="align-middle text-center "
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('feb' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['feb' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center "
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('mar' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['mar' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center "
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('abr' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['abr' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center"
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('may' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['may' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center"
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('jun' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['jun' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center"
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('jul' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['jul' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center"
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('ago' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['ago' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center"
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('sep' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['sep' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center "
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('oct' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['oct' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center "
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('nov' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['nov' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                                <td class="align-middle text-center "
                                  [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                  <span *ngIf="f.hasOwnProperty('dic' + i.strEjercicio); else elseBlock"
                                    class="text-primary fs13 fw-bold">{{ f['dic' + i.strEjercicio] }}</span>
                                  <ng-template #elseBlock><span
                                      class="text-secondary fs13 fw-bold">0</span></ng-template>
                                </td>
                              </ng-container>
                              <td class="align-middle text-center fw-bold">{{f.intTotal}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>