import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
import { FormasPagoService } from '../servicios/formas-pago.service';

@Component({
  selector: 'app-ecommerce-factura-data',
  templateUrl: './ecommerce-factura-data.component.html', 
  styleUrls: ['./ecommerce-factura-data.component.css']
})

export class EcommerceFacturaDataComponent implements OnInit {
  formAdress: FormGroup;
  passwordMismatchError: boolean = false;
  public spinnerCol: boolean=false;
  public arrayColonias:any =[];
  public userData :any;
  public userData_tienda:any=[];
  public ArrayFormasPago:any =[];
  public facturaForm: FormGroup;
  passwordVisible: boolean = false; // Controla la visibilidad de la contraseña
  confirmPasswordVisible: boolean = false; // Controla la visibilidad de la confirmación de contraseña
  public strConexion:string ='COMERCIAL';
  public arrayRegimenFiscal: any = [];
  public arrayCfdi: any = [];
  public arrayFormaPago: any = [];
  public valoresFormulario:any={};
  constructor(private formPago:FormasPagoService,private cartService: EcommerceCarrService,private _httpService: PeticionesService,private fb: FormBuilder, private router: Router) { 

    this.fnFormFacturacion();
  }

  ngOnInit(): void {
    this.getFormasPago();
    this.fnGetCfdi();
    this.fnGetFormaPago();
    this.fnGetRegimenFiscal();
    this.cartService.getUsr().subscribe((data) =>{
      this.userData_tienda = data?data:[]; // Actualizamos la variable cuando los datos cambian
      console.log(this.userData_tienda);
      if(this.userData_tienda && this.userData_tienda.length>0){
        this.valoresFormulario = {
          strPais: 'México',
          strNombre: this.userData_tienda[0] && this.userData_tienda[0].strNombre_f ?this.userData_tienda[0].strNombre_f:'',
          strApellidos: this.userData_tienda[0] && this.userData_tienda[0].strApellidos_f?this.userData_tienda[0].strApellidos_f:'',
          strRazonSocial: this.userData_tienda[0] && this.userData_tienda[0].strRazonSocial_f?this.userData_tienda[0].strRazonSocial_f:'',          
          strRFC: this.userData_tienda[0] && this.userData_tienda[0].strRFC_f?this.userData_tienda[0].strRFC_f:'',
          strRegimenFiscal: this.userData_tienda[0] && this.userData_tienda[0].strRegimenFiscal_f?this.userData_tienda[0].strRegimenFiscal_f:'',
          strMetodoPago: this.userData_tienda[0] && this.userData_tienda[0].strMetodoPago_f?this.userData_tienda[0].strMetodoPago_f:'',
          strUsoCfdi: this.userData_tienda[0] && this.userData_tienda[0].strUsoCfdi_f?this.userData_tienda[0].strUsoCfdi_f:'',
          strCodigoPostal: this.userData_tienda[0] && this.userData_tienda[0].strCodigoPostal_f?this.userData_tienda[0].strCodigoPostal_f:'',
          strEstado: this.userData_tienda[0] && this.userData_tienda[0].strEstado_f?this.userData_tienda[0].strEstado_f:'',
          strCiudad: this.userData_tienda[0] && this.userData_tienda[0].strCiudad_f?this.userData_tienda[0].strCiudad_f:'',
          strColonia: this.userData_tienda[0] && this.userData_tienda[0].strColonia_f?this.userData_tienda[0].strColonia_f:'',
          strDireccion:  this.userData_tienda[0] && this.userData_tienda[0].strDireccion_f?this.userData_tienda[0].strDireccion_f:'',
        };
        this.facturaForm.setValue(this.valoresFormulario);
        this.facturaForm.get('strPais')?.markAsTouched();
        this.facturaForm.get('strRazonSocial')?.markAsTouched();
        this.facturaForm.get('strRFC')?.markAsTouched();
       this.facturaForm.get('strRegimenFiscal')?.markAsTouched();
       this.facturaForm.get('strMetodoPago')?.markAsTouched();
       this.facturaForm.get('strUsoCfdi')?.markAsTouched();
       this.facturaForm.get('strNombre')?.markAsTouched();
       this.facturaForm.get('strApellidos')?.markAsTouched();
       this.facturaForm.get('strColonia')?.markAsTouched();
       this.facturaForm.get('strCodigoPostal')?.markAsTouched();
       this.facturaForm.get('strCiudad')?.markAsTouched();
       this.facturaForm.get('strEstado')?.markAsTouched();
       this.facturaForm.get('strDireccion')?.markAsTouched();
       if(this.userData_tienda.length >0){
        this.simulateEnter();
       }
      }else{
        this.router.navigate(['/tlogin']);
      }
    });
  }

  fnGetCfdi() {
    this._httpService.mostrarCotizaciones({
      strAccion: 'getUsoCfdi',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
        this.arrayCfdi = result
      }, error => {
        var error = <any>error;
      }
    );
  }




   onSubmit(customerData:any): void {
      if (this.facturaForm.invalid) {
         return;
      } 
      let obj = this.facturaForm.value
    
      let articulo = {
                  strAccion: "updateFactData",  
                  strPais_f: obj.strPais,
                  strRFC_f:obj.strRFC,
                  strNombre_f:obj.strNombre,
                  strApellidos_f:obj.strApellidos,
                  strDireccion_f:obj.strDireccion,
                  strRazonSocial_f:obj.strRazonSocial,
                  strMetodoPago_f:obj.strMetodoPago,
                  strUsoCfdi_f:obj.strUsoCfdi,
                  strColonia_f:obj.strColonia,
                  strCodigoPostal_f:obj.strCodigoPostal,
                  strCiudad_f:obj.strCiudad,
                  strEstado_f:obj.strEstado,
                  strRegimenFiscal_f:obj.strRegimenFiscal,
                  strConexion: this.strConexion,
                  intID_Facturacion: this.userData_tienda[0].intID_Facturacion
                };
                 this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
                  result => {
                    if(result[0] && result[0].code){
                         Swal.fire({
                                  icon: "success",
                                  title: "Registro Exitoso",
                                  text:  result[0].strMensaje 
                                });
                                this.fnFormFacturacion();
                    } else {
                        Swal.fire({
                                    icon: "error",
                                    title: "Error al generar registro.",
                                    text: result[0].strMensaje 
                              });}
                     },(error) => { 
                Swal.fire({
                  icon: "error",
                  title: "Error al generar registro.",
                  text: ""
            })
                 this.spinnerCol=false;
               }) 
           }

       getCp(codigoPostal:string,boolFact:boolean=false){
         this.spinnerCol=true;
       this._httpService.getCp(codigoPostal).subscribe({
         next: (data) => {
           if(data.zip_codes.length>0){
             let rest =data.zip_codes;
             rest.forEach(col => {
                 this.arrayColonias.push({strColonia:col.d_asenta})
               });
               this.facturaForm.patchValue({ strEstado: rest[0].d_estado });
               this.facturaForm.patchValue({ strCiudad: rest[0].d_mnpio});
               this.facturaForm.controls['strCiudad'].markAsTouched();
               this.facturaForm.controls['strCiudad'].updateValueAndValidity();
               this.facturaForm.controls['strEstado'].markAsTouched();
               this.facturaForm.controls['strEstado'].updateValueAndValidity();
           }else{
             Swal.fire("Error","Ingreseun codigo postal valido","error")
           }
           this.spinnerCol=false;
         },
         error: (error) => { 
          /*  console.log(error) */
           this.spinnerCol=false;
         }
       });
     }
     onEnter(event: KeyboardEvent) {
       if (event.key === "Enter") {
         let inputElement = event.target as HTMLInputElement;
         this.getCp(inputElement.value)
       }
     }
     updateCantidad(event: Event): void {
       this.arrayColonias=[];
       let inputElement = event.target as HTMLInputElement;
       let valor = inputElement.value;
       const soloNumeros = /^\d*$/;
       if (!soloNumeros.test(valor)) {
         inputElement.value = valor.replace(/[^0-9]/g, '');
       }
     
       this.facturaForm.patchValue({ strCodigoPostal: inputElement.value });
       if(inputElement.value.length==5){
         this.getCp(inputElement.value)
       }else{
         this.facturaForm.patchValue({ strEstado: "" });
         this.facturaForm.patchValue({ strCiudad: "" });
         this.facturaForm.patchValue({ strColonia: "" });
       }
     }
     get f() { return this.facturaForm.controls; }

     fnFormFacturacion(){
      this.facturaForm = this.fb.group({
        strPais: [this.strConexion == "COMERCIAL"?"México":"REPUBLICA DOMINICANA", Validators.required],
        strRazonSocial: ['', Validators.required],
        strRFC: ['', [Validators.required, Validators.pattern('^[A-ZÑ&]{3,4}\\d{6}[A-Z0-9]{2,3}$')]],
        strRegimenFiscal: ['', Validators.required],
        strMetodoPago: ['', Validators.required],
        strUsoCfdi: ['', Validators.required],
        strNombre: ['', Validators.required],
        strApellidos: ['', Validators.required],
        strColonia:['',[Validators.required,Validators.minLength(1)]],
        strCodigoPostal: ['', [Validators.required, Validators.pattern('^\\d{5}$')]],
        strCiudad: ['', Validators.required],
        strEstado: ['', Validators.required],
        strDireccion: ['', Validators.required]
      });
       
      if(this.valoresFormulario && this.valoresFormulario.strPais   ){
        //console.log('entra1')
        console.log(this.valoresFormulario)
         this.facturaForm.setValue(this.valoresFormulario); 
         this.facturaForm.get('strPais')?.markAsTouched();
         this.facturaForm.get('strRazonSocial')?.markAsTouched();
         this.facturaForm.get('strRFC')?.markAsTouched();
         this.facturaForm.get('strRegimenFiscal')?.markAsTouched();
         this.facturaForm.get('strMetodoPago')?.markAsTouched();
         this.facturaForm.get('strUsoCfdi')?.markAsTouched();
         this.facturaForm.get('strNombre')?.markAsTouched();
         this.facturaForm.get('strApellidos')?.markAsTouched();
         this.facturaForm.get('strColonia')?.markAsTouched();
         this.facturaForm.get('strCodigoPostal')?.markAsTouched();
         this.facturaForm.get('strCiudad')?.markAsTouched();
         this.facturaForm.get('strEstado')?.markAsTouched();
         this.facturaForm.get('strDireccion')?.markAsTouched();
         
      }
      this.facturaForm.statusChanges.subscribe(status => {
        // Si el formulario es válido, ejecutar onSubmit
      });
    }

    simulateEnter() {
      // Creamos un objeto KeyboardEvent simulado sin la propiedad "which"
      const event = new KeyboardEvent('keydown', {
        key: 'Enter',      // Definimos que la tecla presionada es 'Enter'
        code: 'Enter',     // Establecemos el código de la tecla
        keyCode: 13,       // El código de la tecla "Enter" es 13 (aunque es deprecated, sigue siendo válido)
        bubbles: true,     // Propagación del evento (si es necesario)
      });
    
      // Obtenemos el elemento de input
      const inputElement = document.getElementById('strCodigoPostal') as HTMLInputElement;
      let cp = this.userData_tienda[0] && this.userData_tienda[0].strCodigoPostal_f?this.userData_tienda[0].strCodigoPostal_f:'';
      inputElement.value = cp; 
     // Establecemos el target del evento como el input correspondiente
      Object.defineProperty(event, 'target', { value: inputElement });
      // Llamamos a la función onEnter y le pasamos el evento simulado
      this.onEnterFact(event);
    }

  
    onEnterFact(event: KeyboardEvent) {
      if (event.key === "Enter") {
        let inputElement = event.target as HTMLInputElement;
       
        this.getCp(inputElement.value,true)
    
      }
    }

    convertirAMayusculas(event: Event) {
      const input = event.target as HTMLInputElement;
      input.value = input.value.toUpperCase();
      this.facturaForm.get('strRFC')?.setValue(input.value, { emitEvent: false });
    }
    fnGetRegimenFiscal() {
      this._httpService.mostrarCotizaciones({
        strAccion: 'getFiscalRegimen',
        strConexion: this.strConexion
      }, 'sp_modventas_Directorio').subscribe(
        result => { 
          this.arrayRegimenFiscal = result
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } 
    getFormasPago(){
      this.ArrayFormasPago =this.formPago.getFormasPago()
     }

     fnGetFormaPago() {
      this._httpService.mostrarCotizaciones({
        strAccion: 'getFormaPago',
        strConexion: this.strConexion
      }, 'sp_modventas_Directorio').subscribe(
        result => {
          this.arrayFormaPago = result
        }, error => {
          var error = <any>error;
          console.log(error);
        }
      );
    }
}
