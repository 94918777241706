<div class="grid-container"> 
  <div class="row">
    <div class="col-12 text-center my-4">
      <h1>
        <b>Pedidos Rubbermaid </b>
      </h1>
    </div>
  </div>
  <mat-grid-list cols="2" rowHeight="80" >
    <mat-grid-tile *ngFor="let card of cards | async" [colspan]="card.cols" [rowspan]="card.rows">
      <mat-card class="dashboard-card " [ngClass]="{'sinBack':card.id==2}">
        <mat-card-header>
          <mat-card-title>
            {{card.title}}
           
          </mat-card-title> 
        </mat-card-header>
        <mat-card-content class="dashboard-card-content ">
          <div *ngIf="card.id==1" class="row ">
            <div class="col-2"></div>
             <!--DATE PICKER-->
              <mat-form-field appearance="fill" class="col-4">
                <mat-label>Elije una Fecha</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="strFecha" (dateInput)="fnFechaSelected(strFecha)">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <!--FILTRO-->
              <mat-form-field  appearance="fill"  class="col-4">
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="ID" #input>
              </mat-form-field>
       
    
          </div>
          <div *ngIf="card.id==2" class="row  ">
            
            <section class="example-container mat-elevation-z8" tabindex="0">
              
              <table mat-table [dataSource]="dataSource" matSort>
            
                <!-- ID Column -->
                <ng-container matColumnDef="orderId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                  <td mat-cell *matCellDef="let row"> <a class="blueColor" (click)= "fnOrderDetail(row.orderId,row.creationDate, row.strEstatus)" data-bs-toggle="modal" data-bs-target="#exampleModal">{{row.orderId}} 

                  </a> 
                  </td>
                </ng-container>
                 

                <!-- Progress Column -->
                <ng-container matColumnDef="clientName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de Cliente </th>
                  <td mat-cell *matCellDef="let row"> {{row.clientName}} </td>
                </ng-container>
                <!-- Progress Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </th>
                  <td mat-cell *matCellDef="let row"> 
                   
                      <span class="material-icons-sharp redColor" title="{{row.status}}" *ngIf="row.status=='canceled'">
                        cancel
                      </span>
                      <span class="material-icons-outlined redColor" title="{{row.status}}"  *ngIf="row.status=='cancellation-requested'">
                        cancel
                      </span>
                      <span class="material-icons blueColor" title="{{row.status}}"   *ngIf="row.status=='invoiced'" >
                        task
                      </span>
                      <span class="material-icons-outlined greenColor" title="{{row.status}}"  *ngIf="row.status=='ready-for-handling'" >
                        local_shipping
                      </span>
                      <span class="material-icons-outlined pPendiente" title="{{row.status}}"  *ngIf="row.status=='waiting-ffmt-authorization'" >
                  
                          hourglass_bottom
                           
                      </span>
                    <!--{{row.status}}-->
                  </td>
                </ng-container>
            
                <!-- Name Column -->
                <ng-container matColumnDef="creationDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Emisión </th>
                  <td mat-cell *matCellDef="let row"> {{row.creationDate}} </td>
                </ng-container>
            
                <!-- Fruit Column -->
                <ng-container matColumnDef="lastChange">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Último Cambio </th>
                  <td mat-cell *matCellDef="let row"> {{row.lastChange}} </td>
                </ng-container>
                <!-- strMovID Column -->
                <ng-container matColumnDef="strMovID">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>MovID Pedido </th>
                  <td mat-cell *matCellDef="let row"> 

                    <button [title]="row.strEstatus" *ngIf ="row.strEstatus!='CONCLUIDO'" type="button" style="background-color: white;border-color: white;" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#ModalPedido"  [ngClass]="{'pPendiente':row.strEstatus=='PENDIENTE',
                    'pSinAfectar':row.strEstatus=='SINAFECTAR','pConcluido':row.strEstatus=='CONCLUIDO'}" (click)= "fnAfectarPedido(row)">
                    {{row.strMovID}}
                    </button>
                    <button [title]="row.strEstatus" *ngIf ="row.strEstatus=='CONCLUIDO'" type="button" style="background-color: white;border-color: white;" class="btn btn-primary"     [ngClass]="{'pPendiente':row.strEstatus=='PENDIENTE',
                    'pSinAfectar':row.strEstatus=='SINAFECTAR','pConcluido':row.strEstatus=='CONCLUIDO'}" (click)= "fnAfectarPedido(row)">
                    {{row.strMovID}}
                    </button>
                  
                  </td>
                </ng-container>

                <!-- currencyCode Column -->
                <ng-container matColumnDef="strMovIDRem">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Factura</th>
                  <td mat-cell *matCellDef="let row"> <span [title]="row.strEstatusRem" [ngClass]="{'rPendiente':row.strEstatusRem=='PENDIENTE',
                    'rSinAfectar':row.strEstatusRem=='SINAFECTAR','rConcluido':row.strEstatusRem=='CONCLUIDO'}"> {{row.strMovIDRem}}</span> </td>
                </ng-container>

                <!-- currencyCode Column -->
               <!-- <ng-container matColumnDef="strMovIDEmb">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>MovID Embarque</th>
                  <td mat-cell *matCellDef="let row"> <span [ngClass]="{'pPendiente':row.strEstatusEmb=='PENDIENTE',
                    'pSinAfectar':row.strEstatusEmb=='SINAFECTAR','pConcluido':row.strEstatusEmb=='CONCLUIDO'}"> {{row.strMovIDEmb}}</span> </td>
                </ng-container>-->

                <!-- currencyCode Column -->
                <ng-container matColumnDef="totalItems">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad de Artículos </th>
                  <td mat-cell *matCellDef="let row"> {{row.totalItems}} </td>
                </ng-container>

                <!-- currencyCode Column -->
                <ng-container matColumnDef="totalValue">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Importe </th>
                  <td mat-cell *matCellDef="let row"> {{row.totalValue/100|currency}} </td>
                </ng-container>
             
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter </td>
                </tr>
              </table>
            
            
              <mat-paginator [pageSizeOptions]="[ 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </section>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

  <!-- Modal -->
<div class="modal fade " id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header mat-header-cell ">
        <h3 class="modal-title col" id="exampleModalLabel">Número de Pedido: {{strOrderID}}</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="boolDetalle">
          <div class="col-4"></div>
        <img  class="col-6" src="assets/load.gif" style="width:30% ;" >
      </div>
        <div class="row" *ngIf="!boolDetalle">
          <div class="col-6">
            <label  ><b>Contacto:</b> 
              {{dataCte.firstName}}  {{dataCte.lastName}}  <br>
              Teléfono:{{dataCte.phone}}
            </label>
           
            </div> 

          <div class="col-6">
          <label  ><b>Dirección:</b> {{objDatosEnvio.address.street }}, Número:{{objDatosEnvio.address.number}}
            ,Int:{{objDatosEnvio.address.complement}} , Col.{{objDatosEnvio.address.neighborhood}}
           <br>
           {{objDatosEnvio.address.city}}, {{objDatosEnvio.address.state}}, 
            {{objDatosEnvio.address.country}}, {{objDatosEnvio.address.postalCode}}</label>
          </div>
        </div>
        <br>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col"> #</th>
                <th scope="col">Articulo</th>
                <th scope="col">Nombre</th>
                <th scope="col">Precio</th>  
                <th scope="col">Cantidad</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let art of arrayArticulos  " >
                <th><img src="{{art.imageUrl}}" class="card-img-center" alt="..."> </th>
                <th scope="row"> 
                  <span *ngIf="art.tipo=='KIT'" style="color: darkblue;"> {{art.tipo}} {{art.refId}}</span>
                  <span *ngIf="art.tipo=='KITC'">
                    {{art.refId}}
                  <br>
                   <span  style="color: blueviolet;font-size: 10px;">{{art.referencia}}</span>
                 </span>
                  <span *ngIf="art.tipo!='KIT' && art.tipo!='KITC'"  >{{art.refId}}</span>

                </th>
                <td>
                  <span *ngIf="art.tipo=='KIT'" style="color: darkblue;"> {{art.name}} 
                    
                  </span>
                  <span *ngIf="art.tipo!='KIT'"  >{{art.name}}</span>
                 
                </td>
                <!--<td>{{art.price/100|currency}}</td>-->
                
                 <td>{{art.precioReal/100|currency}}</td> 
                <td>{{art.quantity}}</td>
               
                 <!-- <td>{{art.sellingPrice/100|currency}}</td>-->
                 <td>{{(art.precioReal/100)*art.quantity|currency}}  </td>
              </tr>
            
            </tbody>
            <tfoot>
              <tr *ngFor="let total of arrayTotales">
                <td colspan="3" height="20px" style="padding-bottom: 0!important;border-bottom-width: 0;"> </td>
                <td height="20px" colspan="2" style="font-size: 11px;    padding-bottom: 0!important;border-bottom-width: 0;">{{total.name}}</td>
                <td height="20px" style="font-size: 11px;text-align: right;font-weight: bold;padding-bottom: 0!important;border-bottom-width: 0;">{{total.value/100 |currency}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <button *ngIf="estatusPedido==''" type="button" class="btn btn-primary primaryButton" (click)="fnSetPedido_Ecodeli(objDatosEnvio,arrayArticulos,strOrderID,arrayTotales)">Enviar a Intelisis</button>
      </div>
    </div>
  </div>
</div>

<!--modal avanzar pedido-->
<div class="modal fade" id="ModalPedido" data-bs-backdrop="static" data-bs-keyboard="false"  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div  class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header mat-header-cell">
        <h3 class="modal-title col" id="exampleModalLabel" style="text-align: center;">
          <span *ngIf="!boolRem">Afectar Pedido</span>
         <!-- <span *ngIf="boolRem">Generar Remisión</span>-->
        </h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <button *ngIf="!boolRem" type="button" class="btn btn-primary" >Afectar Pedido</button>
       <!-- <button *ngIf="boolRem" type="button" class="btn btn-primary" (click)= "fnGenerarRemision()">Generar Remisión</button>-->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <!--<button type="button" class="btn btn-primary">Save changes</button>-->
      </div>
    </div>
  </div>
</div>
</div>
