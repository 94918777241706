<div class="container mt-5">
    <h2>Iniciar sesión</h2>
    <form (ngSubmit)="fnGetEncriptado()" #loginForm="ngForm">
      <div class="form-group">
        <label for="email">Correo Electrónico</label>
        <input
          type="email"
          id="email"
          name="email"
          [(ngModel)]="loginData.email"
          required
          class="form-control"
          placeholder="Ingresa tu correo"
        />
      </div>
  
      <div class="form-group">
        <label for="password">Contraseña</label>
        <input
          type="password"
          id="password"
          name="password"
          [(ngModel)]="loginData.password"
          required
          class="form-control"
          placeholder="Ingresa tu contraseña"
        />
      </div>
  
      <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">Iniciar sesión</button>
  
      <div class="register-link">
        <p>¿No tienes una cuenta? <a routerLink="/register">Regístrate</a></p>
      </div>
      
    </form>
    <div class=" w-100 mt-2 text-center "  >Conviértete en Partner y gana más <a [routerLink]="['/partner-register']">haz click aquí</a></div>
  </div>
  