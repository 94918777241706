<div class="container ">
    <div class="row justify-content-center">
        <div class="col-11 bg-white shadow-sm mt-2 pt-2 px-3">
            <h1 class="text-center fw-bold nunito">ECODELI</h1>
    
            <h2 class="text-center fw-bold">Aviso de Privacidad</h2>
    
            <p>
            Con fundamento en los artículos 15 y 16 de la <strong>Ley Federal de Protección de Datos Personales en
            Posesión de Particulares</strong>, hacemos de su conocimiento que la empresa <strong>Ecodeli Comercial
            SA de CV</strong> (La empresa) con domicilio en Avenida Restauradores Ote 1001 int 2 Col. Los Arcos de
        la ciudad de León, Guanajuato, México. C.P. 37490, es el responsable del uso y protección de sus datos
        personales, y al respecto le informamos lo siguiente:
    </p>

    <h3 class="fw-bold">¿Para qué fines utilizaremos sus datos personales?</h3>
    <p>Utilizaremos su información personal para las siguientes finalidades que nos permiten y facilitan brindarle
        una mejor atención:</p>
    <ul>
        <li>Proveer los servicios y productos que ha solicitado y evaluar la calidad del servicio que le brindamos.</li>
        <li>Celebración de todo tipo de contratos.</li>
        <li>Dar cumplimiento con las obligaciones contraídas con nuestros clientes y proveedores.</li>
        <li>Registro y pago de proveedores.</li>
        <li>Encuestas y evaluaciones de la calidad en el servicio.</li>
        <li>Para estudios estadísticos o para otros contratos con diversas entidades, e incluso para enviarle
            información sobre los productos y servicios que directa o indirectamente ofrecemos.</li>
        <li>Proporcionar información a nuestros clientes y proveedores (incluyendo cambios en nuestras listas de precios).</li>
        <li>Integrar expedientes de personal para el área de Recursos Humanos.</li>
        <li>Prospección comercial y mercadotecnia.</li>
    </ul>
    <p>
        En caso de que no desee que sus datos personales sean tratados para estos fines, desde este momento usted
        nos puede comunicar lo anterior a través del correo electrónico <a href="mailto:aviso.privacidad@ecodeli.com">aviso.privacidad&#64;ecodeli.com</a>.
        indicando de forma precisa el no deseo de la utilización de sus datos personales.
    </p>
    <p>
        La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le
        neguemos los servicios y productos que solicita o contrata con nosotros.
    </p>

    <h3 class=" fw-bold">¿Qué datos personales utilizaremos para estos fines?</h3>
    <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes
        datos personales:</p>
    <ul>
        <li>Datos de identificación.</li>
        <li>Datos de contacto.</li>
        <li>Datos laborales.</li>
        <li>Datos patrimoniales y/o financieros.</li>
    </ul>
    <p>
        De esta manera, los datos personales se pueden recabar de distintas formas: cuando usted los proporciona
        directamente, cuando visita nuestros sitios de Internet o utiliza nuestros servicios en línea y cuando
        obtenemos información a través de otras fuentes que están permitidas por la ley.
    </p>
    <p>
        Conforme a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (Ley en adelante), 
        no será necesario el consentimiento para el tratamiento de los datos personales cuando tenga el propósito de 
        cumplir obligaciones derivadas de una relación jurídica entre el titular y el responsable.
    </p>

    <h3 class="fw-bold">¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</h3>
    <p>
        Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las
        condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su
        información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la
        eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada
        adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos
        (Oposición). Estos derechos se conocen como derechos ARCO.
    </p>
    <p>
        Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud de acceso, 
        rectificación, cancelación u oposición al correo electrónico <a href="mailto:aviso.privacidad@ecodeli.com">aviso.privacidad&#64;ecodeli.com</a>
        y contener y acompañar lo siguiente
    </p>

    <ul>
        <li>El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;</li>
        <li>Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</li>
        <li>Datos laborales.</li>
        <li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados.</li>
    </ul>


    <p>En el caso de solicitudes de rectificación de datos personales, el titular deberá indicar, además de lo señalado anteriormente, las modificaciones a realizarse y aportar la documentación que sustente su petición.</p>
    <p>La empresa comunicará al titular en un plazo máximo de veinte días hábiles, contados desde la fecha en que se recibió la solicitud de acceso, rectificación, cancelación u oposición, la determinación adoptada, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los quince días hábiles siguientes a la fecha en que se comunica la respuesta. Tratándose de solicitudes de acceso a datos personales, procederá la entrega previa, acreditación de la identidad del solicitante o representante legal, según corresponda. Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.</p>
    <p>La obligación de acceso a la información se dará por cumplida cuando se pongan a disposición del titular los datos personales; o bien, mediante la expedición de copias simples, documentos electrónicos o cualquier otro medio que determine la empresa en este aviso de privacidad.</p>
    <p>En el caso de que el titular solicite el acceso a los datos a una persona que presume es el responsable, y la empresa resulta no serlo, bastará con que así se le indique al titular por cualquiera de los medios a que se refiere el párrafo anterior, para tener por cumplida la solicitud.</p>
    <p>La empresa podrá negar el acceso a los datos personales, o a realizar la rectificación o cancelación o conceder la oposición al tratamiento de los mismos, en los siguientes supuestos:</p>

    <ul>
        <li>Cuando el solicitante no sea el titular de los datos personales, o el representante legal no esté debidamente acreditado para ello;</li>
        <li>Cuando en su base de datos, no se encuentren los datos personales del solicitante;</li>
        <li>Cuando se lesionen los derechos de un tercero;</li>
        <li>Cuando exista un impedimento legal, o la resolución de una autoridad competente, que restrinja el acceso a los datos personales, o no permita la rectificación, cancelación u oposición de los mismos;</li>
        <li>Cuando la rectificación, cancelación u oposición haya sido previamente realizada.</li>
    </ul>

    <h3 class="fw-bold">Usted puede revocar su consentimiento para el uso de sus datos personales.</h3>
    <p>
        A fin de que dejemos de hacer uso de sus datos personales, el consentimiento podrá ser revocado en cualquier momento, sin que se le atribuyan efectos retroactivos. Para revocar el consentimiento envíe su solicitud indicando de forma precisa el consentimiento que desea revocar, a la dirección de correo  <a href="mailto:aviso.privacidad@ecodeli.com">aviso.privacidad&#64;ecodeli.com</a> 
    </p>
    <p>
        En un plazo máximo de veinte días hábiles, contados a partir de la fecha en que la empresa reciba su solicitud, atenderemos su petición y le informaremos sobre la procedencia de la misma, a través de una respuesta al mismo medio por el que nos envió su petición.
    </p>
    <h3 class="fw-bold">¿Cómo puede limitar el uso o divulgación de su información personal?</h3>
    <p>Usted puede limitar el uso y divulgación de sus datos personales enviando su solicitud indicando de forma precisa, la limitación que requiere a la dirección de correo aviso.privacidad&#64;ecodeli.com.</p>
    <p>En un plazo máximo de veinte días hábiles, contados desde la fecha en que se reciba su solicitud, atenderemos su petición y le informaremos sobre la procedencia de la misma, a través de una respuesta al mismo correo electrónico en el que nos envió su petición.</p>
    
    <h3 class="fw-bold">Derechos de los Titulares de Datos Personales.</h3>
    <p>Usted, cualquier titular o, en su caso, su representante legal, podrá ejercer los derechos de acceso, rectificación y cancelación de sus datos personales; oponerse al tratamiento de los mismos o a revocar el consentimiento que para tal fin nos haya otorgado. El ejercicio de cualquiera de ellos no es requisito previo ni impide el ejercicio de otros.</p>
    <p>El titular de los datos tendrá derecho a rectificarlos cuando sean inexactos o incompletos. El titular tendrá en todo momento el derecho a cancelar sus datos personales. La cancelación de datos personales dará lugar a un periodo de bloqueo tras el cual se procederá a la supresión del dato. La empresa podrá conservarlos exclusivamente para efectos de las responsabilidades nacidas del tratamiento.</p>
    
    <h3 class="fw-bold">Transferencia de Datos</h3>
    <p>
        La empresa se compromete a cuidar el cumplimiento de todos los principios legales de protección en torno a
        la transferencia de sus datos personales. De igual forma, manifiesta su compromiso para que se respete en
        todo momento este aviso de privacidad, por las personas físicas o morales a las que se pudiera transferir la información proporcionada.
    </p>
    <p>Sus datos personales pueden ser transferidos y tratados dentro y fuera del país, por personas distintas a esta empresa, con las finalidades señaladas anteriormente en el presente aviso de privacidad.</p>
    <p>Con el uso de esta página web, sus aplicaciones y llenado de cualquier formato de la empresa, usted acepta la transferencia de sus datos.</p>
    <p>Las transferencias nacionales o internacionales de datos podrán llevarse a cabo sin el consentimiento del Titular, entre otros casos establecidos por la Ley, siempre que la transferencia sea efectuada a 
        sociedades controladoras, subsidiarias o afiliadas bajo el control común del Responsable, o una sociedad matriz o a cualquier sociedad del mismo grupo del Responsable que opere bajo los mismos procesos 
        y políticas internas; sea necesaria en virtud de un contrato celebrado o por celebrar en interés del Titular, por el responsable y un tercero; y cuando la transferencia sea precisa para el mantenimiento o 
        cumplimiento de una relación jurídica entre el Responsable y el Titular.</p>

    <h3 class="fw-bold">Cambios al Aviso de privacidad</h3>
    <p>
        El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos
        requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de
        nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
    </p>
    <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad,
        a través de nuestra página de internet <a href="https://www.ecodeli.com">www.ecodeli.com</a>, en donde se
        darán a conocer las modificaciones.</p>
    <p><strong>Fecha de última actualización:</strong> 24 de Noviembre de 2018.</p>
        </div>
    </div>
</div>