import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import { ModuleWithProviders } from '@angular/core';
//componentes
import { EcommerceFinpedidoComponent } from './ecommerce-finpedido/ecommerce-finpedido.component';
import { EcommercePedidosComponent } from './ecommerce-pedidos/ecommerce-pedidos.component';
import { DetalleComponent } from './detalle/detalle.component';
import {ReporteComodatosClienteComponent} from './reporte-comodatos-cliente/reporte-comodatos-cliente.component'
import { DirectorioComponent } from './directorio/directorio.component';
import { HomeComponent } from './home/home.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { ObjmixnComponent } from './objmixn/objmixn.component';
import { ObjmixGteSucursalComponent } from './objmix-gte-sucursal/objmix-gte-sucursal.component';
import { ObjmixdivisionalComponent } from './objmixdivisional/objmixdivisional.component';
import { ObjmixsucursalComponent } from './objmixsucursal/objmixsucursal.component';
import { ObjmixGteDivisionalComponent } from './objmix-gte-divisional/objmix-gte-divisional.component';
import { ObjmixNacionalComponent } from './objmix-nacional/objmix-nacional.component';
import { ObjmixgsComponent } from './objmixgs/objmixgs.component';
import { ObjmixagenteComponent } from './objmixagente/objmixagente.component';
import { LoginComponent } from './login/login.component';
import { LoginnComponent } from './loginn/loginn.component';

import { ScordcardgsComponent } from './scordcardgs/scordcardgs.component';
import { ReportecomodatosComponent } from './reportecomodatos/reportecomodatos.component';
import { ReportevtaobjcteComponent } from './reportevtaobjcte/reportevtaobjcte.component';
import { RetoTorkComponent } from './reto-tork/reto-tork.component';
import { RetoTorkBitacoraInicialComponent } from './reto-tork-bitacora-inicial/reto-tork-bitacora-inicial.component';
import { RetoTorkBitacoraSeguimientoComponent } from './reto-tork-bitacora-seguimiento/reto-tork-bitacora-seguimiento.component';
import { RetoTorkListaDespachadoresComponent } from './reto-tork-lista-despachadores/reto-tork-lista-despachadores.component';
import { PoconexionComponent } from './poconexion/poconexion.component';
import { AutpreciominComponent } from './autpreciomin/autpreciomin.component';
import { CotizadornewComponent } from './cotizadornew/cotizadornew.component';
import { VtexComponent } from './vtex/vtex.component';
import {InventariosComponent} from './inventarios/inventarios.component';
import {ComodatosproComponent} from './comodatospro/comodatospro.component';
import {DetallescomodaproComponent} from './detallescomodapro/detallescomodapro.component';
import {CapturadorComponent} from './capturador/capturador.component';
import { ConfigMercadosComponent } from './config-mercados/config-mercados.component';
import {PedidosVentasComponent} from './pedidos-ventas/pedidos-ventas.component';
import {LeadsCarteraComponent} from './leads-cartera/leads-cartera.component';
import {LeadsFamiliasComponent} from './leads-familias/leads-familias.component';
import { CarritoresumenComponent } from './carritoresumen/carritoresumen.component';
import {CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import {AromiaComponent} from './aromia/aromia.component'
import { InsertarComodatosComponent } from './comodatos/comodatos.component';
import {AltaclienteComponent} from './altacliente/altacliente.component';
import {FamiliasComponent} from './familias/familias.component';
import {ListadoComodatosComponent}from './listado-comodatos/listado-comodatos.component'
import {ReportePrincipalComponent}from './reporte-principal/reporte-principal.component'
import {ListadoComodatosTodosComponent}from './listado-comodatos-todos/listado-comodatos-todos.component'
import {CarroComodatosComponent} from './carro-comodatos/carro-comodatos.component'
import {InventariosEcodeliComponent}from './inventarios-ecodeli/inventarios-ecodeli.component';
import {PedidosEcodeliComponent}from './pedidos-ecodeli/pedidos-ecodeli.component';
import {EcodeliPremiaComponent } from './ecodeli-premia/ecodeli-premia.component';
import {LeadComponent} from './lead/lead.component';
import {ProspectoComponent} from './prospecto/prospecto.component';
import {PlantillasComponent}from './plantillas/plantillas.component';
import {PoliticaComponent} from './politica/politica.component'
import {PasarelaPagoComponent}from './pasarela-pago/pasarela-pago.component';
import { SolicitudesFamiliasComponent } from './solicitudes-familias/solicitudes-familias.component';
import { DetalleLeadsComponent } from './detalle-leads/detalle-leads.component';
import {  ComodatosClienteComponent} from './comodatos-cliente/comodatos-cliente.component';
import { DetalleFamiliaComponent } from './detalle-familia/detalle-familia.component';
import { TableroLeadsOportunidadesComponent } from './tablero-leads-oportunidades/tablero-leads-oportunidades.component';
import {PruebasComponent}from './pruebas/pruebas.component';
import { DetalleOportunidadComponent} from './detalle-oportunidad/detalle-oportunidad.component';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { DetalleFamiliaClienteComponent } from './detalle-familia-cliente/detalle-familia-cliente.component';
import { DetalleFamiliaSucursalComponent } from './detalle-familia-sucursal/detalle-familia-sucursal.component';
import {DashboardVendedorComponent} from './dashboard-vendedor/dashboard-vendedor.component';
import {SolicitudAltaClienteComponent} from './solicitud-alta-cliente/solicitud-alta-cliente.component';
import { SolicitudActualizacionCreditoComponent } from './solicitud-actualizacion-credito/solicitud-actualizacion-credito.component';
import { ActualizacionDatosGeneralesComponent } from './actualizacion-datos-generales/actualizacion-datos-generales.component';
import { AltaSucursalClienteComponent } from './alta-sucursal-cliente/alta-sucursal-cliente.component';
import { AltaReglaNegocioComponent } from './alta-regla-negocio/alta-regla-negocio.component';
import { SolicitudIvaFronterizoComponent } from './solicitud-iva-fronterizo/solicitud-iva-fronterizo.component';
import { CambioDomicilioFiscalComponent } from './cambio-domicilio-fiscal/cambio-domicilio-fiscal.component';
import { ConvenioFamiliaComponent } from './convenio-familia/convenio-familia.component';
import {ObjMixComponent} from './obj-mix/obj-mix.component';
import {RelojchecadorComponent} from './relojchecador/relojchecador.component';
import {VacantesComponent} from './vacantes/vacantes.component';
import { VentasNetasComponent } from './ventas-netas/ventas-netas.component';
import {VentasDetalleComponent} from './ventas-detalle/ventas-detalle.component';
import {ReporteFactvsDevolucionesComponent} from './reporte-factvs-devoluciones/reporte-factvs-devoluciones.component';
import {ScoreCardComponent} from './score-card/score-card.component';

import {ListRemisionesComponent} from './list-remisiones/list-remisiones.component';
import {ListadoComplementoPagoComponent} from './listado-complemento-pago/listado-complemento-pago.component';
import {ListadoEvidenciasComodatosComponent} from './listado-evidencias-comodatos/listado-evidencias-comodatos.component';
import {ListadoReporteEmbarquesComponent} from './listado-reporte-embarques/listado-reporte-embarques.component';
import { DetalleArticuloComponent } from './detalle-articulo/detalle-articulo.component';

import { CotizadorArticulosComponent } from './cotizador-articulos/cotizador-articulos.component';
import { CotizadorLineaComponent } from './cotizador-linea/cotizador-linea.component';
import { CotizadorSubLineaComponent } from './cotizador-sub-linea/cotizador-sub-linea.component';
import { DetalleContactoComponent } from './detalle-contacto/detalle-contacto.component';

import { CatalogoComponent } from './catalogo/catalogo.component';
import { ComprasindirectasComponent } from './comprasindirectas/comprasindirectas.component';
import { SolicitudpresupuestosComponent } from './solicitudpresupuestos/solicitudpresupuestos.component';
import { ComodatoDetalleComponent } from './comodato-detalle/comodato-detalle.component';
import {ComodatosLineasComponent} from './comodatos-lineas/comodatos-lineas.component';
import {ComodatosArticulosComponent} from './comodatos-articulos/comodatos-articulos.component'
import { PedidosPendientesComponent } from './pedidos-pendientes/pedidos-pendientes.component';
import { DetallePedidosPComponent } from './detalle-pedidos-p/detalle-pedidos-p.component';
import {RemisionesPendientesComponent } from './remisiones-pendientes/remisiones-pendientes.component';
import {DetalleRemisionComponent } from './detalle-remision/detalle-remision.component';
import {CancelarPedidoComponent} from './cancelar-pedido/cancelar-pedido.component'
import {ReporteAvanceGerenteComponent } from './reporte-avance-gerente/reporte-avance-gerente.component';
import {CostosDetalleArticuloComponent } from './costos-detalle-articulo/costos-detalle-articulo.component';
import {ReporteVentadiariaComponent } from './reporte-ventadiaria/reporte-ventadiaria.component';
import {VentaConsignaComponent}from './venta-consigna/venta-consigna.component';
import {ClientesFaltantesComponent} from './clientes-faltantes/clientes-faltantes.component';
import {PlantillaFiltrosComponent} from './plantilla-filtros/plantilla-filtros.component';
import {MinutaLeadComponent}from './minuta-lead/minuta-lead.component';
import {EcommerceComponent}from './ecommerce/ecommerce.component';
import {EcommerceProductoComponent}from './ecommerce-producto/ecommerce-producto.component';
import {PagoErrorComponent}from './pago-error/pago-error.component';
import {AdminCuponesComponent} from './admin-cupones/admin-cupones.component';
import {EcommerceAdminArtComponent} from './ecommerce-admin-art/ecommerce-admin-art.component'
import {EcommerceDetalleCategoriaComponent} from './ecommerce-detalle-categoria/ecommerce-detalle-categoria.component'
import {EcommerceDetalleArtCategoriaComponent} from './ecommerce-detalle-art-categoria/ecommerce-detalle-art-categoria.component'
import {EcommerceDetallearticuloComponent}from './ecommerce-detallearticulo/ecommerce-detallearticulo.component';
import {EcommerceCarritoComponent}from './ecommerce-carrito/ecommerce-carrito.component';
import {EcommerceAyudaComponent}from './ecommerce-ayuda/ecommerce-ayuda.component';
import {EcommerceLoginComponent}from './ecommerce-login/ecommerce-login.component';
import {EcommerceRegisterComponent}from './ecommerce-register/ecommerce-register.component';
import {EcommerceUserDireccionComponent}from './ecommerce-user-direccion/ecommerce-user-direccion.component';
import {EcommerceUserInicioComponent}from './ecommerce-user-inicio/ecommerce-user-inicio.component';
import {EcommercePedidosCteComponent}from './ecommerce-pedidos-cte/ecommerce-pedidos-cte.component';
import {EcommerceRastrearPedidoComponent}from './ecommerce-rastrear-pedido/ecommerce-rastrear-pedido.component';
import {EcommerceFacturaDataComponent}from './ecommerce-factura-data/ecommerce-factura-data.component';
import {EcommercePerfilComponent}from './ecommerce-perfil/ecommerce-perfil.component';
import {PartnerInfoComponent} from './partner-info/partner-info.component';
/* Componentes cliente */
import {ClienteReportePrincipalComponent}from './cliente-reporte-principal/cliente-reporte-principal.component';
import {ClienteSolicitudPedidoComponent}from './cliente-solicitud-pedido/cliente-solicitud-pedido.component';
import {ClientePreciosEspecialesComponent}from './cliente-precios-especiales/cliente-precios-especiales.component';
import {ClienteFacturasComponent}from './cliente-facturas/cliente-facturas.component';
import {ClienteComplementopagoComponent}from './cliente-complementopago/cliente-complementopago.component';
import {ClienteSeguimientoPedidosComponent}from './cliente-seguimiento-pedidos/cliente-seguimiento-pedidos.component';
import {ClienteVentaMontoComponent}from './cliente-venta-monto/cliente-venta-monto.component';
import {ClienteVentaLineaComponent}from './cliente-venta-linea/cliente-venta-linea.component';
import {ClientePresupuestoSucursalComponent} from './cliente-presupuesto-sucursal/cliente-presupuesto-sucursal.component';
import {ClienteReporteExcedidosComponent}from './cliente-reporte-excedidos/cliente-reporte-excedidos.component';
/*-----------------------------------*/
import {PartnerMicuentaComponent} from './partner-micuenta/partner-micuenta.component';
import {EcommercePrivacidadComponent}from './ecommerce-privacidad/ecommerce-privacidad.component';
import {EcommerceTerminoenvioComponent}from './ecommerce-terminoenvio/ecommerce-terminoenvio.component';
import {ClienteVentaArticuloComponent} from'./cliente-venta-articulo/cliente-venta-articulo.component'
import {ConcursoInscripcionComponent}from './concurso-inscripcion/concurso-inscripcion.component';

/***ecodeli partner ****/
import {PartnerFinpedidoComponent}from './partner-finpedido/partner-finpedido.component'
import {PartnerErrorCompraComponent} from './partner-error-compra/partner-error-compra.component'
import {PartnerPrincipalComponent}from './partner-principal/partner-principal.component';
import {PartnerBeneficiosComponent}from './partner-beneficios/partner-beneficios.component'; 
import {PartnerRecompensasComponent}from './partner-recompensas/partner-recompensas.component';
import {PartnerRegistroComponent}from './partner-registro/partner-registro.component';
import {PartnerCarritoComponent}from './partner-carrito/partner-carrito.component';
import {PartnerPasarelaComponent}from './partner-pasarela/partner-pasarela.component';
import {PartnerPedidosComponent}from './partner-pedidos/partner-pedidos.component';
import {PartnerMispedidosComponent}from './partner-mispedidos/partner-mispedidos.component';
import {PartnerOfertasComponent}from './partner-ofertas/partner-ofertas.component';
import {PartnerClientesComponent} from './partner-clientes/partner-clientes.component';

import {CotizadorPartnerComponent}from './cotizador-partner/cotizador-partner.component';

/**fin ecodeli partner ***/
const routes: Routes = [

  { path: 'reporteVentaDiaria', component: ReporteVentadiariaComponent },
  { path: 'costosDetalleArt', component: CostosDetalleArticuloComponent },
  { path: 'ReporteAvanceMensual', component: ReporteAvanceGerenteComponent },
  { path: 'comodatoDetalle', component: ComodatoDetalleComponent },
  { path: 'solicitudpresupuestos', component: SolicitudpresupuestosComponent },
  { path: 'comprasindirectas', component: ComprasindirectasComponent },
  { path: 'cotizador_Articulos', component: CotizadorArticulosComponent },
  { path: 'cotizador_Linea', component: CotizadorLineaComponent },
  { path: 'cotizador_SubLinea', component:CotizadorSubLineaComponent  },
  { path: 'Reporte_ScoreCard', component: ScoreCardComponent },
  { path: 'Facturas_Devoluciones', component: ReporteFactvsDevolucionesComponent },
  { path: 'RelojChecador', component: RelojchecadorComponent },
  { path: 'SolicitudAltaCte', component: SolicitudAltaClienteComponent },
  { path: 'Vacantes', component: VacantesComponent },
  { path: 'po', component: PoconexionComponent },
  { path: 'home', component: HomeComponent },
  { path: 'busqueda', component: BusquedaComponent },
  { path: 'directorio', component: DirectorioComponent },
  { path: 'detalle', component: DetalleComponent },
  { path: 'detalle/:parametro', component: DetalleComponent }, 
  { path: 'objmixN', component: ObjmixnComponent },  
  { path: 'objmixD', component: ObjmixdivisionalComponent},
  { path: 'objmixS', component: ObjmixsucursalComponent},
  { path: 'objmixGteDivisional', component: ObjmixGteDivisionalComponent},
  { path: 'login', component: LoginComponent},
  { path: 'tiendaproducto', component:EcommerceProductoComponent},
  //{ path: 'objmixSG', component: ObjmixgsComponent},
  { path: 'altaCliente', component: AltaclienteComponent},
 // { path: 'familias', component: FamiliasComponent},
  { path: 'objmixGteSucursal', component: ObjmixGteSucursalComponent},
  { path: 'objmixNacional', component: ObjmixNacionalComponent } , 
  { path: 'objmixAgt', component: ObjmixagenteComponent},
  { path: 'scordcardgs', component: ScordcardgsComponent},

  { path: 'reportecomodatos', component: ReportecomodatosComponent},
  { path: 'reportevtaobj', component: ReportevtaobjcteComponent},
  { path: 'retoTork', component: RetoTorkComponent},
  { path: 'retoTorkBitacoraInicial', component: RetoTorkBitacoraInicialComponent},
  { path: 'retoTorkSeguimiento', component: RetoTorkBitacoraSeguimientoComponent  },
  { path: 'listaDespachadores', component: RetoTorkListaDespachadoresComponent },
  { path: 'AutPrecioMinimo', component: AutpreciominComponent } , 
  { path: 'inventarios', component: InventariosComponent},
  { path: 'Cotizador', component: CotizadornewComponent } , 
  { path: 'pedidosVentas', component: PedidosVentasComponent },
  { path: 'cotizaciones',component:CotizacionesComponent},
  { path: 'leadsCartera', component: LeadsCarteraComponent } ,
  { path: 'vtex', component: VtexComponent },
  { path: 'Aromia', component: AromiaComponent },
  { path: 'detallesComodatosProgramador',component: DetallescomodaproComponent, pathMatch: 'full'},
  { path: 'carritoresumen',component: CarritoresumenComponent},
  { path: 'comodatosprogram', component: ComodatosproComponent } , 
  { path: 'capturadorPedidos', component: CapturadorComponent } , 
  { path: 'ConfiguraciondeMercados',component: ConfigMercadosComponent},
  { path: 'comodatos',component: InsertarComodatosComponent },
  { path: 'listadoComodatos',component:ListadoComodatosComponent},
  { path: 'listadoComodatosTodos',component:ListadoComodatosTodosComponent},
  { path: 'reportePrincipal',component:ReportePrincipalComponent},
  { path: 'leads',component:LeadComponent},
  { path: 'prospectos',component:ProspectoComponent},
  {path: 'EcodeliPremia',component:EcodeliPremiaComponent},
  { path: 'PruebasApis',component:LoginnComponent},
  { path: 'carcmv2',component:CarroComodatosComponent},
  { path: 'pedidosEcodeli',component:PedidosEcodeliComponent },
  { path: 'inventariosEcodeli',component:InventariosEcodeliComponent},
  { path: 'lstPlantillas',component:PlantillasComponent},
  { path: 'dashboard',component:DashboardVendedorComponent},
  {path: 'leadsFamilias',component:LeadsFamiliasComponent},
  {path: 'pasarela',component:PasarelaPagoComponent},
  {path: 'solicitudes_familias',component:SolicitudesFamiliasComponent},
  {path: 'comodatosPorCliente',component:ComodatosClienteComponent},
  {path: 'detalleLead',component:DetalleLeadsComponent},
  {path:'detoport',component:DetalleOportunidadComponent},
  {path: 'pruebas',component:PruebasComponent},
  {path:'cotizadorPrueba',component:CotizadorComponent},
  {path: 'detalleLeadFamilia',component:DetalleFamiliaComponent},/* /:intID_Leads/:intID_Familia/:strFamilia */
  {path: 'tableroLeads',component:TableroLeadsOportunidadesComponent},
  {path: 'detalleLeadCliente',component:DetalleFamiliaClienteComponent},
  {path: 'detalleLeadSucursal',component:DetalleFamiliaSucursalComponent},
  {path: 'solicitud_actualizacionCredito',component:SolicitudActualizacionCreditoComponent},
  {path: 'actualizacion_datosGenerales',component:ActualizacionDatosGeneralesComponent},
  {path: 'alta_sucursalCliente',component:AltaSucursalClienteComponent},
  {path: 'alta_reglaNegocio',component:AltaReglaNegocioComponent},
  {path: 'iva_fronterizo',component:SolicitudIvaFronterizoComponent},
  {path: 'cambio_domicilioFiscal',component:CambioDomicilioFiscalComponent},
  {path: 'convenio_familia',component: ConvenioFamiliaComponent},
  {path: 'objMixAdd', component: ObjMixComponent},
  {path: 'ventasNetas', component: VentasNetasComponent},
  {path: 'ventasDetalle', component: VentasDetalleComponent},
  {path: 'listadoRemisiones',component:ListRemisionesComponent},
  {path: 'listadoComplementoPago',component:ListadoComplementoPagoComponent},
  {path: 'listadoEvidenciaComodatos',component:ListadoEvidenciasComodatosComponent},
  {path: 'listadoEmbarques',component:ListadoReporteEmbarquesComponent},
  {path: 'detalle_articulo',component:DetalleArticuloComponent},
  {path: 'detalle_contacto',component:DetalleContactoComponent},
  {path: 'comodatos_lineas',component:ComodatosLineasComponent},
  {path: 'comodatos_articulos',component:ComodatosArticulosComponent},
  {path: 'catalogo',component:CatalogoComponent},
  {path:'remisiones_pendientes',component:RemisionesPendientesComponent},
  {path: 'pedidos_pendientes',component:PedidosPendientesComponent},
  {path: 'venta_consigna',component:VentaConsignaComponent},
  {path: 'detalle_pedido',component:DetallePedidosPComponent},
  {path: 'cancelar_pedido',component:CancelarPedidoComponent},
  {path: 'clientes_faltantes',component:ClientesFaltantesComponent},
  {path: 'detalle_remision',component:DetalleRemisionComponent},
  {path: 'plantillaFiltros',component:PlantillaFiltrosComponent},
  {path: 'reporte-comodatos-cliente',component:ReporteComodatosClienteComponent},
  {path: 'minuta',component:MinutaLeadComponent},
  {path: 'error-pago', component: PagoErrorComponent },
  {path: 'tienda-art', component: EcommerceDetallearticuloComponent },
  {path: 'tienda', component: EcommerceComponent },
  {path: 'tienda-carrito', component: EcommerceCarritoComponent },    
  {path: 'tienda-pedidos',component:EcommercePedidosComponent},
  {path: 'admin-destacados-nuevos',component:EcommerceAdminArtComponent},
  {path: 'tienda-categoria', component:EcommerceDetalleCategoriaComponent},
  {path: 'admin-cupones', component:AdminCuponesComponent},
  {path: 'tienda-sub-categoria', component:EcommerceDetalleArtCategoriaComponent},
  {path: 'confirma-pedido_mp', component:EcommerceFinpedidoComponent},
  {path: 'concurso-inscripcion', component:ConcursoInscripcionComponent},
  {path: 'ayuda', component:EcommerceAyudaComponent},
  {path: 'tlogin', component:EcommerceLoginComponent},
  {path: 'register', component:EcommerceRegisterComponent},
  {path: 'editdireccion',component:EcommerceUserDireccionComponent},
  {path: 'tHome', component:EcommerceUserInicioComponent}, 
  {path: 'mispedidos', component:EcommercePedidosCteComponent},
  {path: 'seguimientop', component:EcommerceRastrearPedidoComponent},
  {path: 'facturaCte', component:EcommerceFacturaDataComponent},
  {path: 'perfilCte', component:EcommercePerfilComponent},
  {path: 'politica', component:PoliticaComponent},
  
  {path: 'tablerocliente', component:ClienteReportePrincipalComponent},
  {path: 'cliente-factura', component:ClienteFacturasComponent},
  {path: 'venta-articulo', component:ClienteVentaArticuloComponent},
  {path: 'listado-solicitud-cliente',component:ClienteSolicitudPedidoComponent},
  {path: 'cliente-complemento-pago',component:ClienteComplementopagoComponent},
  {path: 'cliente-seguimiento-pedido',component:ClienteSeguimientoPedidosComponent},
  {path: 'cliente-monto',component:ClienteVentaMontoComponent},
  {path: 'cliente-venta-linea',component:ClienteVentaLineaComponent},
  {path: 'cliente-presupuesto-sucursal', component:ClientePresupuestoSucursalComponent},
  {path: 'cliente_unidades_excedidas',component:ClienteReporteExcedidosComponent},
  {path: 'declaracion-privacidad', component:EcommercePrivacidadComponent}, 
  {path: 'terminos-envio-servicio', component:EcommerceTerminoenvioComponent}, 
  {path: 'precios-especiales', component:ClientePreciosEspecialesComponent}, 
  {path: 'partner-principal', component:PartnerPrincipalComponent}, 
  {path: 'partner-beneficios', component:PartnerBeneficiosComponent}, 
  {path: 'partner-recompensas', component:PartnerRecompensasComponent},
  {path: 'partner-cuenta',component:PartnerMicuentaComponent}, 
  {path: 'partner-register', component:PartnerRegistroComponent},  
  {path: 'partner-carrito', component:PartnerCarritoComponent},  
  {path: 'partner-finpedido',component:PartnerFinpedidoComponent},
  {path: 'partner-error-compra',component:PartnerErrorCompraComponent},
  {path: 'partner-pasarela', component:PartnerPasarelaComponent},
  {path: 'partner-info', component:PartnerInfoComponent}, 
  {path: 'partner-pedidos', component:PartnerPedidosComponent},   
  {path: 'partner-clientes', component:PartnerClientesComponent}, 
  
  {path: 'partner-mispedidos', component:PartnerMispedidosComponent}, 
  {path: 'partner-ofertas', component:PartnerOfertasComponent}, 
  {path: 'partner-cotizador', component:CotizadorPartnerComponent},
  {path: '**', component:EcommerceComponent},//modificar aqui el componente con el que inicia la carga LoginComponent
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const appRoutingProviders: any[] = []; //Es un procedimiento que necesita angular para poder cargar Provider de la ruta

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes);// con este metodo vamos a decir que a raiz de rutas tienen que cargar todas las rutas que nosotros indicamos
