import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCategoria'
})
export class FilterPipeCategoria implements PipeTransform {
  transform(value:any, args: any): any {
    const resultPost=[];
    for(const post of value){
      if(post.strCategoria.indexOf(args) > -1){
        resultPost.push(post);
      }
    
    };
    return resultPost;
  }
}
 