import { Component  } from '@angular/core';
import { PeticionesService } from './servicios/httpservice';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'] 
})
export class AppComponent {
  title = 'modVentas';
  public mostrarMenu:boolean=true;
  constructor(private menuService: PeticionesService) {
     // Suscribir al array del carrito
     this.menuService.menu$.subscribe((nuevoValor) => {
      this.mostrarMenu = nuevoValor;
     /*  console.log(this.mostrarMenu) */
    });
  }

 
}
