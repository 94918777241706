import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FiltrosService } from '../servicios/filtros.service';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import {MatPaginator,MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export interface vtasNetas{
  cliente:string;
  sucursal:string;
  ordencompra:string;
  movimiento:string;
  fecha:string;
  almacen:string;
  agente:string;
  articulo:string;
  subcuenta:string
  descripcion:string;
  cantidad:string;
  precio:string;
  importe:string;
}

@Component({
  selector: 'app-ventas-detalle',
  templateUrl: './ventas-detalle.component.html',
  styleUrls: ['./ventas-detalle.component.css']
})
export class VentasDetalleComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public datosVentasDetalle:MatTableDataSource<vtasNetas>
  public columnsVtasNetas :string[]=['cliente','sucursal','ordencompra','movimiento','fecha','almacen','agente','articulo','descripcion','subcuenta','cantidad','precio','importe'];/* ,*/
  public intEjercicio:number=0;
  public intPeriodo:number; 
  public inputSearch:string="";
  public isHoveredButton: boolean = false;
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public arrayEjercicio:any=[];
  public arrayAccesos:any=[];
  public arraySucursales:any=[];
  public arrayGerentes:any=[];
  public arrayVentasReportes:any=[];
  public strSucursal:string="";
  public strGerente: string="";
  public strConexion:string="";
  public strAgente:string="";
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public tagName:any;
  public strSearch:string="";
  public spiner:boolean= false;
  public totalImporte:number=0;
  constructor(private localSt:LocalStorageService,elem:ElementRef,public _alertify: AlertifyService, private router: Router, private paginatorIntl: MatPaginatorIntl,  private _httpService:PeticionesService, private _filtroServ :FiltrosService) {
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1;
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      } else {
        this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
        this.strGerente = this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.strSucursal =this.boolSucursal?'':this.localSt.retrieve('strSucursal');
        this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
        this.strAgente= this.boolAgente?'':this.localSt.retrieve('strAgente');
      }
    }
    this.datosVentasDetalle = new MatTableDataSource(this.arrayVentasReportes);
    this.customizeItemsPerPageLabel();
   }

  ngOnInit(): void {
    this.fnGetFiltros();
    this.fnGetVtasNetas();
  }
  ngAfterViewInit() {
    this.fninitTabla();
  }
  fninitTabla(){
    this.datosVentasDetalle.paginator = this.paginator;
    this.datosVentasDetalle.sort = this.sort;
  }
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'items por página';
  }

  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
      this.arrayAgentes.sort((a, b) => {
        if (a.strNombre < b.strNombre) {
          return -1;
        }
        if (a.strNombre > b.strNombre) {
          return 1;
        }
        return 0;
      });
      this.strAgente=this.localSt.retrieve('strAgente').toString();
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
      this.arraySucursales.sort((a, b) => {
        if (a.strNombreSucursal < b.strNombreSucursal) {
          return -1;
        }
        if (a.strNombreSucursal > b.strNombreSucursal) {
          return 1;
        }
        return 0;
      });
      this.strSucursal=this.localSt.retrieve('strsucursal').toString();
      console.log(this.strSucursal)
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
      this.arrayGerentes.sort((a, b) => {
        if (a.strNombre < b.strNombre) {
          return -1;
        }
        if (a.strNombre > b.strNombre) {
          return 1;
        }
        return 0;
      });
    });
  }

  fnGetVtasNetas(){
    this.spiner= true;
    let obj = {
       strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""),
       intEjercicio: this.intEjercicio,
       intPeriodo:this.intPeriodo ,
       strSucursal:this.strSucursal,
       strGerente:this.strGerente,
       strConexion:this.strConexion,
       strAccion:'getVentas_Detalle',
    };
    if(this.strAgente!="" && this.strAgente != null){
      obj.strAgente= this.strAgente;
      obj.strSucursal=""
      obj.strGerente=""
    }else if(this.strSucursal!=""&& this.strSucursal != null){
      obj.strAgente= ""
      obj.strSucursal=this.strSucursal;
      obj.strGerente=""
    }else if(this.strGerente!=""&& this.strGerente != null){
      obj.strAgente= ""
      obj.strSucursal=""
      obj.strGerente=this.strGerente;
    }
    console.log(obj);
    this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Reportes').subscribe(
      result => {
        this.arrayVentasReportes = result;
        this.totalImporte = this.arrayVentasReportes.map(t => t.dblImporte).reduce((acc, value) => acc + value, 0);
        this.datosVentasDetalle = new MatTableDataSource(this.arrayVentasReportes);
        this.fninitTabla();
        console.log(this.arrayVentasReportes);
      });
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.datosVentasDetalle.filter = filterValue.trim().toLowerCase();
      if (this.datosVentasDetalle.paginator) {
        this.datosVentasDetalle.paginator.firstPage();
      }
    }
}
