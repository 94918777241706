import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService} from 'ngx-webstorage'
import { Router } from '@angular/router';

@Component({
  selector: 'app-comodatos-lineas',
  templateUrl: './comodatos-lineas.component.html',
  styleUrls: ['./comodatos-lineas.component.css']
})
export class ComodatosLineasComponent implements OnInit {
  public arrayLineas:any="";
  public strConexion:string="";
  constructor(private router: Router,private _httpService:PeticionesService,private localSt: LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
   }
  ngOnInit(): void {
    this.fnExtraerlineas()
  }
  fnExtraerlineas(){
    this.arrayLineas=[];
    this._httpService.getLineasComodatos({
      strAccion:'getCategoriasComodatos',
      strConexion:this.strConexion
    },"sp_ModVentas_Comodato").subscribe(
      result => {
        this.arrayLineas = result;
        console.log(this.arrayLineas)
      });
  }
  onCardClick(categoria:any){
    this.localSt.store('strCategoria_Comodato',categoria.strCategoria);
    this.localSt.store('strGrupo_Comodato',categoria.strGrupo);
    this.router.navigate(['/comodatos_articulos']);
    console.log(categoria)
  }
}
