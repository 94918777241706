import { Component, ElementRef, OnInit, ViewChild,HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../alertify.service';
import { FiltrosService } from '../servicios/filtros.service';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService } from 'ngx-webstorage';
import * as CryptoJS from 'crypto-js';
import { trigger, state, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-ecommerce-detalle-art-categoria',
  templateUrl: './ecommerce-detalle-art-categoria.component.html',
  styleUrls: ['./ecommerce-detalle-art-categoria.component.css'],
    animations: [
      trigger('menuAnimation', [
        state('hidden', style({
          transform: 'rotate(0)' , // Empieza doblada
        })),
        state('visible', style({
          transform: 'rotate(180deg)', // Plana cuando es visibl
        })),
        transition('hidden => visible', [
          animate('0.5s ease-out')
        ]),
        transition('visible => hidden', [
          animate('0.5s ease-in') 
        ]),
      ])
    ]
})
export class EcommerceDetalleArtCategoriaComponent implements OnInit {
  public strCategoria:string=""; 
    public strConexion:string = "COMERCIAL"
    public arraySubCategoria:any=[];
    public spiner:boolean=false;
    public arraySubcategorias:any=[];
    public arrayArticulos:any = [];
    public strLinea:string ="";
    public selectedCategory:string='';
    scrollPosition: number = 0;
    @ViewChild('canvasCategoria') canvasCategoria!:ElementRef;
    public strSubLinea:string='';
    public priceRange:string='';
    public strbuscarLista:"";
    public strArticulo:string='';
    public strTitulo:string=''
     public tipoBusqueda:string=""
    isButtonVisible = false; 
    lastScrollTop = 0;
    private secretKey: string = 'ec0d3li2o2e';
   /*  @ViewChild('mat-drawer-content', { static: true }) drawerContent: ElementRef; */
    @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;

    @HostListener('window:scroll', [])
    onWindowScroll() { 
      
      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.scrollPosition = scrollTop;
       
  
      if (this.scrollPosition > 100) { 
      }
      
    }



    constructor(private el: ElementRef,private _httpService: PeticionesService,private route: ActivatedRoute,public _alertify: AlertifyService, private _filtroServ: FiltrosService, public router: Router, public localSt: LocalStorageService) { 
      this.route.queryParams.subscribe(params => {
        this.strLinea = params['strLinea'];
        this.strSubLinea= params['subLinea'];
        this.tipoBusqueda = params['tipoBusqueda'];
        this.strArticulo = params['strArticulo'];
      });

      if(this.tipoBusqueda == "artTienda"){
        this.route.queryParams.subscribe(params => {
        // Aquí puedes manejar lo que sucede cuando cambian los parámetros de la URL
      // Este es el parámetro que necesitas manejar
        this.buscarArticulo(params['strArticulo']);
        });
      }else{
        this.fnGetArticulos()
      }
      document.addEventListener('click', (event: any) => {
        const offcanvasElement = document.querySelector('.offcanvas.show');
        if (offcanvasElement && !offcanvasElement.contains(event.target)) { 
          this.canvasCategoria.nativeElement.click()
          this.canvasCategoria.nativeElement.click()
        }
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
   
   // this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }




  onScroll(event: Event): void {
    const scrollTop = this.scrollContainer.nativeElement.scrollTop;
    if (scrollTop > this.lastScrollTop)  {
      this.isButtonVisible = scrollTop > 100; // Mostrar el botón solo si se ha hecho scroll hacia abajo y más allá de 100px
    } else {
      this.isButtonVisible = false; // Ocultar el botón si el usuario desplaza hacia arriba
    }
    
  }
  fnGetArticulos() {
    this.spiner =true
    this.arraySubcategorias=[];
    this.arrayArticulos = [];
    let articulo = {
      strAccion: "getArticulo_Cat",
      strCategoria: this.strLinea,
      strConexion: this.strConexion
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => { 
          let algo=result;
          console.log(algo)
          algo.forEach(element => {
          element.imageUrl='https://www.cleanclean.mx/imagenes/'+element.strArticulo+'.JPG'
          let valida = this.arraySubcategorias.find(o=>o.strGrupo ==element.strGrupo );
          if(!valida && valida!='' && valida!=' '){
            this.arraySubcategorias.push({strGrupo:element.strGrupo  })
          }
          if(element.strTipoPedido != ""){
            let descuento = 100 - ((element.dblPrecioOferta / element.dblPrecioLista) * 100);
            let decimal = descuento - Math.floor(descuento);
            let resultado = decimal > 0.001 ? Math.ceil(descuento) : Math.floor(descuento);
            element.oferta = resultado}
         
        });
       /* this.arraySubcategorias.sort(function (a,b){
          if (a.strGrupo > b.strGrupo) {
            return 1;
          }
          if (a.strGrupo < b.strGrupo) {
            return -1;
          }
          return 0;
        })*/
        
        this.arrayArticulos = algo;
        this.spiner =false;
     
      },error=>{
        console.log(error.message)
        this.spiner =false;
      });
  }

  addToCart(obj:any)
  {
    const objString = JSON.stringify(obj); // Convertir el objeto a string
    const encryptedData = this.encryptData(objString);
    this.router.navigate(['/tienda-art'], { 
      queryParams: { data: encryptedData } // Enviar el string como parámetro
    });
 /*   this.router.navigate(['/tienda-art'], { 
      queryParams: obj
  }); */

  }
  scrollToTop(): void {
    this.scrollContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  
  filterByCategory(strGrupo:string){
    this.strSubLinea= strGrupo; 
  }

  buscarArticulo(strArticulo: string) {
    this.spiner = true;
    this.arraySubcategorias=[];
    this.arrayArticulos = [];
    this.strSubLinea='';
    let articulo = {
      strAccion: "getArticulo_Busqueda",
      strBusqueda: strArticulo,
      strConexion: this.strConexion
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => { 
        console.log(result)
        if(result.length>0){
          let algo=result;
          algo.forEach(element => {
            if(element.strArticulo.toLowerCase()==strArticulo.toLowerCase()){
              this.strLinea = element.strCategoria;
              this.strSubLinea = element.strGrupo
            }
            else  if (element.strGrupo.toLowerCase().includes(strArticulo.toLowerCase())) {
              this.strLinea = element.strCategoria;
              this.strSubLinea = element.strGrupo
            }
  
  
            element.imageUrl='https://www.cleanclean.mx/imagenes/'+element.strArticulo+'.JPG'
            let valida = this.arraySubcategorias.find(o=>o.strGrupo ==element.strGrupo );
            if(!valida && valida!='' && valida!=' '){
              this.arraySubcategorias.push({strGrupo:element.strGrupo  })
            }
            if(element.strTipoPedido != ""){
              let descuento = 100 - ((element.dblPrecioOferta / element.dblPrecioLista) * 100);
              let decimal = descuento - Math.floor(descuento);
              let resultado = decimal > 0.001 ? Math.ceil(descuento) : Math.floor(descuento);
              element.oferta = resultado
            }
          });
         /* this.arraySubcategorias.sort(function (a,b){
            if (a.strGrupo > b.strGrupo) {
              return 1;
            }
            if (a.strGrupo < b.strGrupo) {
              return -1;
            }
            return 0;
          })*/
          this.arrayArticulos = algo;
          this.strLinea = '';//this.strLinea =='' ?this.arrayArticulos[0].strCategoria:this.strLinea;
          this.strSubLinea ='';//this.strSubLinea==''? this.arrayArticulos[0].strGrupo:this.strSubLinea;
          this.spiner = false;
        }else{
          this.spiner = false;
        }
      },error=>{
        console.log(error.message)
        this.spiner = false;
      });
}

fnIrSubLinea(){
  // Componente de origen
  this.router.navigate(['/tienda-categoria'], { 
    queryParams: { strCategoria: this.strLinea }
});
/*   this.router.navigate(['/tienda-categoria', {strCategoria:obj.strCategoria}]); */
}
 encryptData(data: any): string {
      try {
        const stringifiedData = JSON.stringify(data); // Convertir objeto a string
        const encrypted = CryptoJS.AES.encrypt(stringifiedData, this.secretKey).toString();
        return encodeURIComponent(encrypted); // Codificar para URL
      } catch (error) {
        console.error('Error al encriptar datos:', error);
        return '';
      }
    }

    
  onImageError(event: any) {
    event.target.src = 'assets/Fondos/image.png';
  }
}
