import {Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { filter } from 'rxjs/operators';


export interface tareasCte {
  fechaemision: string;
  sucursal: string;
  dia: string;
  importe: string;
}
@Component({
  selector: 'app-reporte-ventadiaria',
  templateUrl: './reporte-ventadiaria.component.html',
  styleUrls: ['./reporte-ventadiaria.component.css']
})
export class ReporteVentadiariaComponent implements OnInit {
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;
  datosTareasCte: MatTableDataSource<tareasCte>;
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayVentaDiaria:any [];
  arrayEjercicio:any=[];
  public Intdia=""
  public tagName: any;
  public arrayAccesos: any = [];
  public arrayDias = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  columnsTareasCte: string[] = ['fechaemision', 'sucursal', 'dia', 'importe'];/* , 'fechaVencimiento','progreso' */
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  constructor( private router: Router,private _httpService:PeticionesService,private localSt: LocalStorageService, elem: ElementRef, private _filtroServ :FiltrosService,private paginatorIntl: MatPaginatorIntl) {
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
 /*    this.strConexion = this.localSt.retrieve('strConexion'); */
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos') ? this.localSt.retrieve('arrayAccesos') : [];

    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
      }

    }
    this.customizeItemsPerPageLabel();
   }

  ngOnInit(): void {
    this.fnGetFiltros();
    this.fnGetVentasDiarias() 
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  //    console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }
  
  fnTabletareasCte() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }

  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }

  fnGetVentasDiarias() {
    let obj= {
      strEjercicio:this.intEjercicio,
      strPeriodo: this.intPeriodo,
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente
    }
    this._httpService.getpagViejitaPlantillas(obj,"getVentaDiaria").subscribe(
      result => {
        console.log(result)
          this.arrayVentaDiaria = result;  
          this.Intdia =""
        this.datosTareasCte = new MatTableDataSource(this.arrayVentaDiaria);
        this.fnTabletareasCte();

      });
  }
  convertirFecha(fechaString: string): Date | null {
    // Verificar si la fecha es válida
    if (!fechaString) {
      return null;
    }
    
    // Partir el string de fecha por espacio
    const partesFechaHora = fechaString.split(' ');
    
    if (partesFechaHora.length >= 2) {
      const [fecha, hora] = partesFechaHora;
      const [dia, mes, anio] = fecha.split('/');
      
      // Construir el formato de fecha que JavaScript entiende
      const fechaFormateada = `${anio}-${mes}-${dia}T${hora}`;
      const fechaConvertida = new Date(fechaFormateada);
      
      // Validar que la fecha sea válida
      return isNaN(fechaConvertida.getTime()) ? null : fechaConvertida;
    }
    
    return null;
  }
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'items por página';
  }

  fnFiltrarDias(){
    let filter:any=[]
    if(this.Intdia ==""){
      this.datosTareasCte = new MatTableDataSource(this.arrayVentaDiaria);
      this.fnTabletareasCte();
    }else{
       filter = this.arrayVentaDiaria.filter(o=> o.intDia == this.Intdia)
    this.datosTareasCte = new MatTableDataSource(filter);
        this.fnTabletareasCte();
    }
   
  }
 
}
