import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
import { LocalStorageService } from 'ngx-webstorage';
import { DataDBService } from '../servicios/data-db.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-partner-micuenta',
  templateUrl: './partner-micuenta.component.html',
  styleUrls: ['./partner-micuenta.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0, transform: 'scale(0.95)' })), // Estado inicial invisible
      transition(':enter', [
        animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'scale(0.95)' }))
      ])
    ]),
    trigger('fadeInOut1', [
      state('void', style({ opacity: 0, transform: 'scale(0.95)' })), // Estado inicial invisible
      transition(':enter', [
        animate('500ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ opacity: 0, transform: 'scale(0.95)' }))
      ])
    ])
  ]
})
export class PartnerMicuentaComponent implements OnInit {
  public registerForm: FormGroup;
  public passwordMismatchError: boolean = false;
  public arrayRegimenFiscal: any = [];
  public arrayColonias:any = [];
  public arrayAgregarAnexosActividades: any = []
  public arrayCfdi:any = [];
  public user:any = [];
  public boolCteExiste:boolean=false;
  
  public arrayEnviarAnexosActividades: any = []
  public strCte:string="";
  public strPais:string="";
  public strSucursal: string = "";
    @ViewChild('btnCerrarAltaSuc') btnAltaSuc!: ElementRef;
    @ViewChild('btnEditar') btnEditar!: ElementRef;
    @ViewChild('inputFile') inputFile!:ElementRef;
  public objAgenSuc:any;
  public strConexion:string ='COMERCIAL';
  public passwordVisible: boolean = false; // Controla la visibilidad de la contraseña
  public confirmPasswordVisible: boolean = false;
  public editarParner:boolean = false;
  public boolDireccion:boolean = false;
  public arrayAccesos: any = [];
  formDomFiscal: FormGroup;
  public tagName: string = '';
  public strNombreAgente:string = ""
  public arrayDirecciones :any = []
  public strAgenteLS: any = '' 
  public objClienteValid:any;
  public strAgente: string = "";
  public strAgente_Crea: string;
  public strGerente: string = "";
  public strUsuario:string;
  form: FormGroup;
  public strNombre: string = '';
  public strSSN:string;
  public boolEditar :boolean =false
  public objPartner:any = {};
  public strCliente:string="";
  public strUrlLogo:string="";
  public id_monedero:number =0;
  public objMonedero:any={};
  private unsubscribe$ = new Subject<void>();
  constructor(private route: ActivatedRoute,elem: ElementRef,public localSt: LocalStorageService,public router: Router, private _httpService: PeticionesService,private indexedDBService: DataDBService,private fb: FormBuilder) { 

    this.strNombre = localSt.retrieve('strNombre')
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.strPais = this.localSt.retrieve('strPais');
    this.indexedDBService.getItem('arrayResult', (value) => {
    this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) {
        this.objPartner = this.arrayAccesos[0];
        this.strCliente = this.objPartner.strCliente
        this.fnGetLogo()
        this.fnListadoDireccion()
         this.fnGetInfo(this.objPartner.strCliente).then(() => {
        this.fnGetRegimenFiscal();
        this.fnGetCfdi();
       })
       
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      
        if (objAcceso == undefined || objAcceso.length == 0) {
          this.router.navigate(['/login']);
        } else {


        


          let objData = this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
          //obtenemos el id del monedero
          this.id_monedero = objData.intID_Monedero?objData.intID_Monedero:0;
          //obtenemos saldo se monedero
          this.fnGetSaldo_monedero();
          this._httpService.setMostrarMenu(true);
          // this.localSt.retrieve('strAgente'); //this.boolAgente ? '' : 
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgenteLS = objData.strAgente ; //this.localSt.retrieve('strAgente');
          this.strAgente_Crea = objData.strAgente; // this.localSt.retrieve('strAgente');
          this.strUsuario = objData.strUsuario;
          this.strNombreAgente = objData.strNombre + " " + objData.strApellido 
          this.strSSN =  objData.strSSN;
          //------------conexi+on-------------------
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
          this.route.queryParams.subscribe(params => {
            this.boolEditar = params['editar'] ? JSON.parse(params['editar']) : false; 
          });
        }

        this.registerForm = this.fb.group({
          name: ['', Validators.required],
          apellido: ['', Validators.required],
          telefono: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^\d{10}$/)]],
         
          nombreComercial: ['', Validators.required],
          razonSocial: ['', Validators.required],
          rfc: ['', [Validators.minLength(12), Validators.maxLength(13), Validators.required]],
          regimenFiscal: ['', Validators.required],
          usoCfdi: ['', Validators.required] ,
          codigoPostal: ['', Validators.required],
          estado: ['', Validators.required] ,
          ciudad: ['', Validators.required] ,
          colonia: ['', Validators.required] ,
          calle: ['', Validators.required] ,
          numeroExterior: ['', Validators.required], });
        
       setTimeout(() => {
        if(this.boolEditar == true){
          this.btnEditar.nativeElement.click()
        }else{
          this.editarParner =false
        }
       }, 900);
        

      } else {
        this.router.navigate(['/login']);
      }
    });  
    this.fnInitForm()
  }

  ngOnInit(): void {
 
  }

  ngAfterViewInit():void{
   
  }
  get f() { return this.registerForm.controls; }

onSubmit(customerData:any) { 
   if (this.registerForm.invalid) {
      return;
    }

  let objForm =this.registerForm.value
  let objEnv = {}
      objEnv = {
        strAccion:"updateCte",
        strUsuario:this.objPartner.strCliente,
        strConexion:this.strConexion,
        strNombre:objForm.name,
        strTelefono:objForm.telefono,
        strApellidos:objForm.apellido,
        strDireccion:objForm.calle,
        strColonia:objForm.colonia,
        strPoblacion:objForm.ciudad,
        strDireccionNumero:objForm.numeroExterior,
        strEstado:objForm.estado,
        strCodigoPostal:objForm.codigoPostal,
        strRfc:objForm.rfc,
        strFiscalRegimen:objForm.regimenFiscal,
        strRazonSocial:objForm.razonSocial,
        strNombreComercial:objForm.nombreComercial,
        strUsoCFDI:objForm.usoCfdi
      }
       
      this._httpService.mostrarCotizaciones(objEnv, 'sp_PartNer').subscribe(
        result => { 
          if (result[0]?.code === 200){
            Swal.fire("Guardado",result[0].strMensaje,"success");

            this.fnGetInfo(this.objPartner.strCliente).then(() => {
              this.fnSelectRegimen();
              this.fnSelectCfdi()
             })
            //this.fnGetInfo(this.objPartner.strCliente);
            
            this.editarParner = false;
          } else {
            Swal.fire("Error",result[0].strMensaje,"error");
          }
        },error => {
          Swal.fire("Error","Error en Conexion","error");
        }
      )
  }

    fnSetRegister(data:any){
    }

    onInput(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      const length = inputElement.value.length;
    
      if (length == 5) { 
        this.fnGetDatosFiscalesAddSuc()
      } else {
        this.form.controls['estado'].setValue('');
      this.form.controls['delegacion'].setValue('');
      this.form.controls['poblacion'].setValue('');
      this.form.controls['almacen'].setValue('');
      this.form.controls['intAlmacen'].setValue('');
      this.form.controls['strRuta'].setValue('');
      }
      
      
    }
    getInvalidControls() {
      const invalid = [];
      const controls = this.registerForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      return invalid;
    }
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    }
    limitarTelefono(event: any) {
      let valor = event.target.value;
      if (valor.length > 10) {
        event.target.value = valor.slice(0, 10);
      }
    }

      onKeyDown(event: KeyboardEvent) {
        const ENTER_KEY = 13;
        const TAB_KEY = 9;
      
        if (event.keyCode === ENTER_KEY || event.keyCode === TAB_KEY) {
          this.fnGetDatosFiscales();
        } else {
          this.registerForm.controls['estado'].setValue('');
          this.registerForm.controls['ciudad'].setValue('');
        }
      }
      
      onInputChange(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const value = inputElement.value;
      
        if (value.length === 5) {
          // Simula la pulsación de la tecla Enter
          this.fnGetDatosFiscales();
        }else{
          this.arrayColonias = [];
          this.registerForm.controls['estado'].setValue('');
          this.registerForm.controls['ciudad'].setValue('');
          this.registerForm.controls['colonia'].setValue('');
    
        }
      }
    
       fnGetDatosFiscales() {
         if (this.registerForm.controls['codigoPostal'].value) {
           this._httpService.mostrarCotizaciones({
             strAccion: 'getCodigoPostal',
             strCodigoPostal: this.registerForm.controls['codigoPostal'].value,
             strConexion: this.strConexion
           }, 'sp_modventas_Directorio').subscribe(
             result => { 
               if (result.length > 0) {
                 this.registerForm.controls['estado'].setValue(result[0].strEstado);
                 this.registerForm.controls['ciudad'].setValue(result[0].strDelegacion);
                 this.arrayColonias = result; 
             
               } else {
                 Swal.fire({
                   icon: 'error',
                   title: 'Oops...',
                   text: '¡Codigo Postal Incorrecto!',
                 });
                 this.registerForm.controls['strEstado'].setValue('');
                 this.registerForm.controls['strCiudad'].setValue('');
                 this.arrayColonias = []
               }
             }, error => {
               var error = <any>error;
               console.log(error);
             });
         } else {
           console.log('Sin CP')
         }
       }
     
       fnGetRegimenFiscal() {
        this._httpService.mostrarCotizaciones({
          strAccion: 'getFiscalRegimen',
          strConexion: this.strConexion
        }, 'sp_modventas_Directorio').subscribe(
          result => { 
            this.arrayRegimenFiscal = result
            this.fnSelectRegimen();
          }, error => {
            var error = <any>error;
            console.log(error);
          }
        );
      }
      
      fnSelectRegimen(){
        this.user.regimen = this.arrayRegimenFiscal.find( o => o.strFiscalRegimen == this.user.strFiscalRegimen);
      }

      fnGetCfdi() {
        this._httpService.mostrarCotizaciones({
          strAccion: 'getUsoCfdi',
          strConexion: this.strConexion
        }, 'sp_modventas_Directorio').subscribe(
          result => {
            
            this.arrayCfdi = result
            this.fnSelectCfdi()
          }, error => {
            var error = <any>error;
            console.log(error);
          }
        );
      }
      fnSelectCfdi(){
        this.user.cfdiNombre = this.arrayCfdi.find( o => o.strClaveUsoCFDI == this.user.strUsoCFDI);
      }
      fnEditarUser(){
        if(this.editarParner == false){
          this.editarParner = true;
          this.registerForm.controls['name'].setValue(this.user.strNombre);
          this.registerForm.controls['apellido'].setValue(this.user.strApellidos);
          this.registerForm.controls['telefono'].setValue(this.user.strTelefono);
          this.registerForm.controls['codigoPostal'].setValue(this.user.strCodigoPostal);
          this.fnGetDatosFiscales();
          setTimeout(() => {
            this.registerForm.controls['estado'].setValue(this.user.strEstado);
            this.registerForm.controls['ciudad'].setValue(this.user.strDelegacion);
            this.registerForm.controls['colonia'].setValue(this.user.strColonia);
          }, 800);
          this.registerForm.controls['calle'].setValue(this.user.strDireccion);
          this.registerForm.controls['numeroExterior'].setValue(this.user.strDireccionNumero);
          this.registerForm.controls['nombreComercial'].setValue(this.user.strNombreComercial);
          this.registerForm.controls['razonSocial'].setValue(this.user.strRazonSocial);
          this.registerForm.controls['rfc'].setValue(this.user.strRFC);
          this.registerForm.controls['regimenFiscal'].setValue(this.user.strFiscalRegimen);
          this.registerForm.controls['usoCfdi'].setValue(this.user.strUsoCFDI);

        }else{
          this.editarParner = false;
        }
      }
    async  fnGetInfo(cte:string): Promise<void>{
      return new Promise((resolve) => {
        this._httpService.mostrarCotizaciones({
          strAccion: 'getCliente',
          strUsuario:cte,
          strConexion: this.strConexion
        }, 'sp_PartNer').subscribe(
          result => {
            this.user = result[0]
            resolve();
          }
        );
      });
      }

      onSelectColonias() { 
        if(this.form.controls['colonia'].value == "otra"){
          this.form.get('strColoniaOtra').setValidators([Validators.required]);
        }else{
          this.form.get('strColoniaOtra').clearValidators();
        }
        this.form.get('strColoniaOtra').updateValueAndValidity();
      }
    /*   onInput2(event: Event) {
        this.form.controls['nombreCte'].setValue('');
        this.boolCteExiste = false;
      
      } */

        fnValidarSuc(){
          let obj = {
            strCliente:this.strCte,/* this.form.controls['strNombreComercial'].value */
            strAgente:"",
            strGerente:"",
            strSucursal:"",
            strAccion:"getCte_Sucursal",
            strConexion :this.strConexion
          }
          if(this.strAgente!="" && this.strAgente != null){
            obj.strAgente= this.strAgente 
            obj.strSucursal=""
            obj.strGerente=""
          }else if(this.strSucursal!=""&& this.strSucursal != null){
            obj.strAgente= ""
            obj.strSucursal=this.strSucursal
            obj.strGerente=""
          }else if(this.strGerente!=""&& this.strGerente != null){
            obj.strAgente= ""
            obj.strSucursal=""
            obj.strGerente=this.strGerente
          }
          this._httpService.mostrarCotizaciones(obj,"sp_ModVentas_Directorio").subscribe(
            result  =>{
              let rest = result[0];
              if (rest.code == "200") {
                this.boolCteExiste = true;
                this.objClienteValid = result[0];
                this.form.controls['nombreCte'].setValue(rest.strNombreComercial);
                this.formDomFiscal.controls['nombreCte'].setValue(rest.strNombreComercial);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error al Actualizar",
                  text: result[0].strMensaje
                });
                this.boolCteExiste = false;
              } 
            }, error=>{
              this.boolCteExiste = false;
              console.log(error.message)
            }
          )
        }
          onSubmitAddress() {
            let objCol =this.form.controls['colonia'].value == "otra"?this.arrayColonias[0] : this.arrayColonias.find(o => o.strColonia == this.form.controls['colonia'].value)

            if (this.form.valid) {
              let env = {
                strConexion:this.strConexion,
                strAccion: 'setSucursal_Cte',
                strCliente: this.strCte,
                strNombreReferencia2:  this.form.controls['sucursal'].value,
                strDireccion: this.form.controls['calle'].value,
                strDireccionNumero: this.form.controls['noExterior'].value,
                strDireccionInt: this.form.controls['noInterior'].value,
                strEntreCalles: this.form.controls['entreCalles'].value,
                strColonia: this.form.controls['colonia'].value == "otra"?this.form.controls['strColoniaOtra'].value:this.form.controls['colonia'].value,
                strDelegacion: this.form.controls['delegacion'].value,
                strPoblacion: this.form.controls['poblacion'].value,
                strEstado: this.form.controls['estado'].value,
                strPais: this.strPais,
                strRuta: this.form.controls['strRuta'].value,
                strCodigoPostal: this.form.controls['cp'].value,
                strTelefono: this.form.controls['telefono'].value,
                strContacto1: this.form.controls['encargado'].value,
                strTelefono1: "",
                streMail1: this.form.controls['correo'].value,
                strAgente: this.objPartner?.strAgente,
                strAlmacenDef: objCol.strAlmacen,
                intSucursalEmpresa:  this.arrayAccesos[0].sucursal  // objCol.strAlmacen == "1" ? "0":objCol.strAlmacen,
              };
              
              this._httpService.mostrarCotizaciones(env,"sp_ModVentas_Directorio").subscribe(
                result => {
                  Swal.fire(result[0].code=="200"?"Guardado":"Error",result[0].code=="200"?"Se insertó dirección exitosamente.":"Error al insertar dirección.", result[0].code=="200"?"success":"error")
                  if(result[0].code=="200"){
                    this.fnListadoDireccion();
                    this.btnAltaSuc.nativeElement.click()
                  }
             
                },error =>{
        
                })
        
            }
          }
          fnMdlAltaSuc(){
            this.strCte ="";
            this.strCte = this.objPartner.strCliente
            this.boolCteExiste = false;
           
            this.fnInitForm();
            this.fnValidarSuc()
          }
          fnInitForm(){
            this.form = this.fb.group({
              nombreCte: ['', Validators.required],
              sucursal: ['', Validators.required],
              cp: ['', Validators.required],
              estado: ['', Validators.required],
              delegacion: ['', Validators.required],
              colonia: ['', Validators.required],
              strColoniaOtra: ['',[]],
              poblacion: ['', Validators.required],
              calle: ['', Validators.required],
              noExterior: ['', Validators.required],
              noInterior: [''],
              entreCalles: ['', Validators.required],
              encargado: ['', Validators.required],
              telefono: ['',  [Validators.minLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]],
              correo: ['',  [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})?$'), Validators.required]],
              vendedor: [this.strNombreAgente, Validators.required],
              almacen: ['', Validators.required],
              intAlmacen:['', Validators.required],
              strRuta:['', Validators.required],
            });
           
            this.formDomFiscal = this.fb.group({
              nombreCte: ['', Validators.required],
    
              cp: ['', Validators.required],
              estado: ['', Validators.required],
              almacen: ['', Validators.required],
              intAlmacen:['', Validators.required],
              delegacion: ['', Validators.required],
              colonia: ['', Validators.required],
              poblacion: ['', Validators.required],
              calle: ['', Validators.required],
              noExterior: ['', Validators.required],
               entreCalles: ['', Validators.required],
            });
          }


           fnGetDatosFiscalesAddSuc(numb:number=0) {
              this._httpService.mostrarCotizaciones({
                strCodigoPostal:numb==1? this.formDomFiscal.controls['cp'].value: this.form.controls['cp'].value,
                strAccion:'getCodigoPostal',
                strConexion:this.strConexion
              },"sp_ModVentas_Directorio").subscribe(
                result => {
                  if (result.length > 0) {
                
                    if(numb == 1){
                      this.formDomFiscal.controls['estado'].setValue(result[0].strEstado);
                      this.formDomFiscal.controls['delegacion'].setValue(result[0].strDelegacion);
                      this.formDomFiscal.controls['poblacion'].setValue(result[0].strPoblacion);
                      this.formDomFiscal.controls['almacen'].setValue(result[0].strNombre);
                      this.formDomFiscal.controls['intAlmacen'].setValue(result[0].strAlmacen);
                    
                    }else{
                      this.form.controls['estado'].setValue(result[0].strEstado);
                      this.form.controls['delegacion'].setValue(result[0].strDelegacion);
                      this.form.controls['poblacion'].setValue(result[0].strPoblacion);
                      this.form.controls['almacen'].setValue(result[0].strNombre);
                      this.form.controls['intAlmacen'].setValue(result[0].strAlmacen);
                      this.form.controls['strRuta'].setValue(result[0].strRuta);
                    }
                     
                      this.arrayColonias = result;
                      
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: '¡Codigo Postal Incorrecto!',
                    });
                    if(numb == 1){
                      this.formDomFiscal.controls['estado'].setValue("");
                      this.formDomFiscal.controls['delegacion'].setValue("");
                      this.formDomFiscal.controls['poblacion'].setValue("");
                      this.formDomFiscal.controls['almacen'].setValue("");
                      this.formDomFiscal.controls['intAlmacen'].setValue("");
                    
                    }else{
                      this.form.controls['estado'].setValue("");
                      this.form.controls['delegacion'].setValue("");
                      this.form.controls['poblacion'].setValue("");
                      this.form.controls['almacen'].setValue("");
                      this.form.controls['intAlmacen'].setValue("");
                      this.form.controls['strRuta'].setValue("");
                    }
                     
                    this.arrayColonias = []
                  }
                },
                error => {
                  var error = <any>error;
                  console.log(error);
                }
              );
            }
              fnListadoDireccion() {
                this.boolDireccion = true
                let objEnv = {
                  strAccion: 'getListado_sucursales_CTE',
                  strCliente: this.objPartner.strCliente,
                  strConexion: this.strConexion
                }
                this._httpService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads')
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe(
                    result => {
                      this.arrayDirecciones = result; 
                      setTimeout(() => {
                        this.boolDireccion = false
                      }, 1500);
                    
                    }, error => {
                      this.boolDireccion = false
                      var error = <any>error; console.log(error);
                    }
                  );
              }

              fnDeleteDireccion(obj:any) {
                Swal.fire({
                  icon:"question",
                  title: "Esta acción no se puede deshacer. ¿Deseas continuar?",
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: "Sí, eliminar",
                  denyButtonText: `Cancelar`,
                  customClass: {
                    confirmButton: "btn btn-danger", // Rojo para "Sí, eliminar"
                    denyButton: "ms-5 btn btn-secondary" // Gris para "Cancelar"
                  },
                  buttonsStyling: false
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    let objEnv = {
                      strAccion: 'deleteSucursalCte',
                      strCliente: this.objPartner.strCliente,
                      intId_Direccion:obj.intId_Direccion,
                      strConexion: this.strConexion
                    }
                    this._httpService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads')
                      .pipe(takeUntil(this.unsubscribe$))
                      .subscribe(
                        result => { 
                          if(result && result[0] && result[0].code == 200){
                            Swal.fire({
                                      title: "Envíado Correctamente.",
                                      text: result[0].strMensaje,
                                      icon: "success",
                                      timer: 2000, 
                                      timerProgressBar: true, 
                                      showConfirmButton: false, // Oculta el botón "OK"
                                      })
                            this.fnListadoDireccion()
                          }else{
                            Swal.fire("Error.",result[0].strMensaje,"error")
                          }
                        }, error => {
                          Swal.fire("Error.","En conexion, favor de intentar mas tarde!","error")
                          var error = <any>error; console.log(error);
                        }
                      );
                  } 
                });

                
              }

        fnGetSaldo_monedero(){
          let objEnv = {
            strAccion: 'getMonederoPartner', 
            intID_Monedero:this.id_monedero,
            strConexion: this.strConexion
          }
          this._httpService.mostrarCotizaciones(objEnv, 'sp_ECommerce')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              result => { 
                this.objMonedero= result[0]?result[0]:{};
              });
        }

 

        fnClickInput(){
          this.inputFile.nativeElement.click();
        }

        onFileSelected(event: any) : void{
          this.arrayAgregarAnexosActividades = [];
              let files: FileList = event.target.files;
              if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                  let file = files[i];
                  let newTotalSize = file.size;
                  if (newTotalSize > 10 * 1024 * 1024) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Límite de almacenamiento alcanzado',
                      text: 'No puedes agregar más archivos, el límite es de 10MB.',
                    });
                    return;
                  }
                  let nombreOriginal = file.name.split('.').slice(0, -1).join('.');
                  let extension = file.name.split('.').pop();
                  this.arrayAgregarAnexosActividades.push({
                    nombre: nombreOriginal,
                    extencion: extension,
                    archivo: file
                  });
                }
                this.fnInsertar();
                console.log(this.arrayAgregarAnexosActividades);
              }
        }

        fnInsertar(){
          console.log("Ingresa a insertar");
          let formData = new FormData();
          this.arrayAgregarAnexosActividades.forEach((o, index) => {
            if (o.archivo instanceof File) {
              formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
            } else {
              console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
            }
          });
          this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/AnexoLogoPartner.php', [], formData).then((result) => {
            this.arrayEnviarAnexosActividades = []
            let arrayRest = result;
            arrayRest.forEach(obj => {
              this.arrayEnviarAnexosActividades.push(
                {
                  strTipo: obj.strTipo,
                  strRespuesta: obj.file,
                  strNombre: obj.strNombre,
                  strAgente: this.strAgente,
                  strEstatus: ""
                });
            });
            console.log(this.arrayEnviarAnexosActividades[0]);
            this.fnsetLogo(this.arrayEnviarAnexosActividades[0]);
          }, (error) => {
            console.log(error);
          });
      }

      fnsetLogo(obj:any){
        let objEnv = {
          strAccion: 'insertLogo', 
          strTipo:obj.strTipo,
          strUrl:obj.strRespuesta,
          strCliente:this.strCliente,
          strConexion: this.strConexion
        }
        this._httpService.mostrarCotizaciones(objEnv, 'sp_PartNer')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => { 
              let res = result[0]
              if(res && res.code == "200"){
                Swal.fire("Guardado correctamente!",res.strMensaje,"success")
                this.fnGetLogo();
              }else{
                Swal.fire("Error!",res.strMensaje,"success")
              }
            },error=>{
              Swal.fire("Error!",error.message,"success")
            });
      }

      fnGetLogo(){
        let objEnv = {
          strAccion: 'getLogoPartner',
          strCliente:this.strCliente,
          strConexion: this.strConexion
        }
        console.log(objEnv)
        this._httpService.mostrarCotizaciones(objEnv, 'sp_PartNer')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => { 
           
              let rest = result[0];
       
              this.strUrlLogo = rest.code ==200?"https://www.ecodeli.com/"+rest.strUrl:"assets/partner/ilus2.jpg"
            });
      }
    }
