import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';
import { CurrencyPipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-partner-ofertas',
  templateUrl: './partner-ofertas.component.html',
  styleUrls: ['./partner-ofertas.component.css']
})
export class PartnerOfertasComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  public inputSearch:string="";
  public strConexion:string="";
  public arrayLineas:any=[];
  public arrayArticulos:any=[];
  public strLinea:string;
  public spinnerDest:boolean = false;
  constructor(private currencyPipe: CurrencyPipe,private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
    private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
      this.strConexion = this.localSt.retrieve('strConexion');
    }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.strConexion = this.localSt.retrieve('strConexion');
    });
    this.fnGetLineas();
  }
  fnFiltrarArtsAdd(){

  }
        fnGetLineas(){
          let objEnviar = {
            strAccion:"getLineas",
            strConexion:this.strConexion
          }
          this._httpService.mostrarCotizaciones(objEnviar, 'sp_ECommerce').subscribe(
            result => {
              let rest = result
              this.arrayLineas  = rest;
             console.log(this.arrayLineas)
               this.arrayLineas.forEach(lin => {
                lin.active = false
               });
              },error => {
              var error = <any>error;
              console.log(error);
              Swal.fire({
                title: "Error",
                text: "Al extraer lineas."+error.message,
                icon: "error"
              });
            });
        }
  fnGetArticulos_linea(linea){
    this.strLinea=linea;
    let objEnviar = {
      strAccion:"getArt_linea",
      strConexion:this.strConexion,
      strLinea: this.strLinea
    }
    this._httpService.mostrarCotizaciones(objEnviar, 'sp_ECommerce').subscribe(
      result => {
        console.log(result);
        this.arrayArticulos = result.length >0 ? result:[];
        this.arrayArticulos.forEach(element => {
          element.imageUrl='https://www.cleanclean.mx/imagenes/'+element.strArticulo.trim()+'.JPG'
        });
        },error => {
        var error = <any>error;
        console.log(error);
       
      });
  }
  fnEliminarArtsOferta(art:any){/*
          Swal.fire({
            title: "¿Estas seguro de eliminar este articulo destacado: "+"("+art.strArticulo+")"+art.strDescripcion+"?",
            showDenyButton: true,
            confirmButtonText: "SI",
            denyButtonText: "NO",
            customClass: {
              confirmButton: 'swal-button-spacing', // Clase personalizada para el botón "SI"
              denyButton: 'swal-button-spacing',    // Clase personalizada para el botón "NO"
            }
          }).then((result) => {
            if (result.isConfirmed) {
              let objenv = {
                strAccion: 'deleteDestacado',
                intID_Pedido:art.intID_Pedido,
                strConexion: this.strConexion
              }
          
              this._httpService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
                result => {
                    let res = result[0];
                    if(res.code == "200"){
                      Swal.fire({
                        title: "Eliminado",
                        text: "El artículo se eliminó correctamente",
                        icon: "success",
                        timer: 2000, 
                        timerProgressBar: true, 
                        showConfirmButton: false 
                      });
                      this.fnExtaerOfertas();
                    }else{
                      Swal.fire("Error", "Error al eliminar articulo", "error");
                    }
                  },error => {
                  var error = <any>error;
                  console.log(error);
                });
            }
          });*/
  }
        
}
