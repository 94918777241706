 
import {  Component,OnInit, ViewChild, ElementRef,ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatPaginator,MatPaginatorIntl  } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DataDBService } from '../servicios/data-db.service';
import * as html2pdf from 'html2pdf.js';
import { Modal } from 'bootstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-comodato-detalle',
  templateUrl: './comodato-detalle.component.html',
  styleUrls: ['./comodato-detalle.component.css']
})
export class ComodatoDetalleComponent implements OnInit {
  public boolspiner:boolean=true
  public boolMostrarAceptar:boolean=true
  public ImportetotalPDF:number=0;
  public ivaPDF:number=0;
  public SubtotalPDF:number=0;
  public datosArrayPDF:any= [{}];
  public objComodatoRenovacion:any
  public spinnerAut:boolean=false;
public strConexion:string; 
public intIDComodato:number;
public spiner:boolean = false;
public arrayArticulos:any = []; 
public objInfo:any={};
public objArt_Det:any={};
public objDespachador:any={};
public strMotivorechazo:string;
public strUsuarioInt:string;
public strGerente:string;
public boolMostrarBoton:boolean=true;
public arraySaldou:any=[];
public tagName:string='';
public arrayAccesos: any=[]
public boolRechazar:boolean=false;
public boolAutorizar:boolean=false;
private modal: Modal;
public fechaFormateada:string;
  constructor(private renderer: Renderer2,private indexedDBService: DataDBService,private sanitizer: DomSanitizer,private formbuilder: FormBuilder,private paginatorIntl: MatPaginatorIntl,
    private rutaActiva: ActivatedRoute, private _httpService: PeticionesService, private carroservice: CarroService, public _alertify: AlertifyService, private _filtroServ: FiltrosService, public router: Router, elem: ElementRef, public route: ActivatedRoute, private cdr: ChangeDetectorRef, public localSt: LocalStorageService, private elementRef: ElementRef) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      this.boolAutorizar= objAcceso.find((o: any) => o.strAccion == 'btnAceptar') ? true : false;
      this.boolRechazar = objAcceso.find((o: any) => o.strAccion == 'btnRechazar') ? true : false;
      this.boolMostrarAceptar=this.boolAutorizar
    }

    this.route.queryParamMap.subscribe(params => {
      this.intIDComodato = parseInt(params.get('id')) ; 
      console.log(this.intIDComodato)
  });
  this.strUsuarioInt = this.localSt.retrieve('strusuario');
  this.strGerente = this.localSt.retrieve('strUsuario') ;
  }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      // Obtener la fecha actual
      const fechaActual: Date = new Date();

      // Obtener el año, mes y día
      const anio: number = fechaActual.getFullYear();
      const mes: number = fechaActual.getMonth() + 1; // Los meses en JavaScript son de 0 (enero) a 11 (diciembre), así que se suma 1.
      const dia: number = fechaActual.getDate();

      // Formatear la fecha en un formato específico, por ejemplo: "YYYY-MM-DD"
      this.fechaFormateada  = `${anio}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
      const modalElement = document.getElementById('existenciaModal');
      if (modalElement) {
        this.modal = new Modal(modalElement);
      }
      this.fnGetDetalle()
    });
  }
 fnGetDetalle(){
  this.spiner = true
  let objDatos={
    intIDComodatos:this.intIDComodato  ,
    strAccion:"getDetalle_solicitud",
    strConexion:this.strConexion
  }
  
   this._httpService.getComodatos(objDatos,"sp_ModVentas_Comodato").subscribe(
    result => {
      this.boolspiner=false
      console.log(result)
      this.objInfo = result && result.length > 0 ? result[0]:{};
      this.objComodatoRenovacion=result && result.length > 0 ? result[0]:{};
      this.arrayArticulos=result;
      this.datosArrayPDF=result;

      this.datosArrayPDF.forEach(element => {
        this.SubtotalPDF=this.SubtotalPDF + (element.dblCantidadDespachador * element.dblPrecio);
      });
      this.ivaPDF = this.SubtotalPDF* .16;
      this.ImportetotalPDF= this.SubtotalPDF+this.ivaPDF;
    },error=>{
      this.spiner = false
      this.boolspiner=false
      
       console.error('Error al consumir la API:', error);
    });

 }
 fnGetDetalleArt(obj){
 this.objDespachador=obj;
  let objParam = {
    strArticulo:obj.strArticulo,
    intIDComodatos: this.intIDComodato,
    strSucursal:this.objInfo.strSucursal,
    strCliente:this.objInfo.strCliente,
    intIDArticulo:obj.intConsecutivo,
    strAccion:'getDetalle_Articulo',
    strConexion: this.strConexion
  }
  console.log(objParam)
  this._httpService.getComodatos(objParam,"sp_ModVentas_Comodato").subscribe(
    result => {
      this.objArt_Det=result && result.length >0 ? result[0]:{};
      console.log(result) 
    },error=>{
      this.spiner = false
      
       console.error('Error al consumir la API:', error);
    });
 }
 getRoundedValue(valor:number): number {
  let valorR =0
 if(valor <1 && valor >0){
  valorR=1;
 }
 else {
    valorR =Math.round(valor);
 }
   
  return valorR
}
fnActualizarRespuesta(objArticulo:any ){
  let cantidad=objArticulo.intCantidad;
  let precio =objArticulo.dblPrecio
 
  let objParam = {
    strArticulo:this.objDespachador.strArticulo   ,
    intIDArticulo:this.objDespachador.intConsecutivo,
    intIDComodatos: this.intIDComodato,
    intCantidad:cantidad,
    dblPrecio:precio,   
    strAccion:'setRespuesta_Articulo',
    strConexion: this.strConexion
  }
   
  this._httpService.getComodatos(objParam,"sp_ModVentas_Comodato").subscribe(
    result => {
      if(result.length > 0 )
      {
        let code = result[0].code;
        let mensaje=result[0].strMensaje;
        let titulo = code == 409 ? mensaje: code == 200 ? mensaje: 'Error al Intentar Actualizar';
        Swal.fire({
          icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
          title: titulo,
          text: mensaje,
          confirmButtonText: 'Cerrar'
        });

      } 
    },error=>{
      this.spiner = false
      
       console.error('Error al consumir la API:', error);
    });
}
 
fnRechazar(){
  let objParam = {
    strMotivorechazo: this.strMotivorechazo,
    intIDComodatos: this.intIDComodato,  
    strAccion:'setRechazado',
    strConexion: this.strConexion
  } 
   this._httpService.getComodatos(objParam,"sp_ModVentas_Comodato_Insertar").subscribe(
    result => {
      if(result.length > 0 )
      {
        let code = result[0].code;
        let mensaje=result[0].strMensaje;
        let titulo = code == 409 ? mensaje: code == 200 ? mensaje: 'Error al Intentar Actualizar';
        Swal.fire({
          icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
          title: titulo,
          text: mensaje,
          confirmButtonText: 'Cerrar'
        });
     
        //recargamos
        document.getElementById('btnModal'  )!.click()
        this.fnGetDetalle();
           //redireccionar a las solicitudes
           this.router.navigate(['/listadoComodatos']);
       
      } 
    },error=>{
      this.spiner = false
      
       console.error('Error al consumir la API:', error);
       Swal.fire({
        icon:   'error',
        title: 'Error de Conexión',
        text: 'Ocurrió un error de conexión, por favor intentelo nuevamente',
        confirmButtonText: 'Cerrar'
      });
    }); 
}
fnAutorizar(){
this.spinnerAut = true;
  console.log(this.objInfo);
  let total =0
  let xmlArticulos=[]
  this.arrayArticulos.forEach(element => {
    total= total + (element.dblPrecio * element.dblCantidadDespachador )
    let objArt = {strArticulo:element.strArticulo ,dblPrecio:element.dblPrecio , intCantidad:element.dblCantidadDespachador , strOpcion:element.strOpcion}
    xmlArticulos.push(objArt)
  });
  let iva = total * .16;
 
  let objParam = { 
    strConexion: this.strConexion,
    strAccion:'setAutorizado',    
    strUsuario:this.strUsuarioInt ,
    strCliente: this.objInfo.strCliente,
    intCteEnviarA: this.objInfo.strCteEnviarA? this.objInfo.strCteEnviarA: null,
    strAgente: this.objInfo.strAgente,
    strCondicion:'Contado' ,
    strListaPreciosEsp:'LP'+this.objInfo.strCliente,
    strSucursal:this.objInfo.strSucursal,
    strAlmacen:this.objInfo.strAlmacen,
    dblImporte:total,
    dblImpuestos:iva,
    xmlArticulos:xmlArticulos,
    strAdjuntoSolicitud: this.objInfo.strAdjuntoSolicitud,
    strAdjuntoReposicion:this.objInfo.strAdjuntoReposicion,
    strMotivorechazo:this.strMotivorechazo?this.strMotivorechazo:'',
    strAutorizo:this.strGerente,
    strAtencion:'WEB '+ this.intIDComodato,
    strOrdenCompra:'',
    strEstatus:'AUTORIZADO',
    strMovIDReposicion: this.objInfo.strMovIDReposicion ?this.objInfo.strMovIDReposicion :'',
    strTipoSolicitud: this.objInfo.strTipoSolicitud,
    intIDComodatos: this.intIDComodato
  }
  
  this.boolMostrarAceptar=false;
  console.log(objParam);
   this._httpService.getComodatos(objParam,"sp_ModVentas_Comodato_Insertar").subscribe(
    result => {
      console.log(result);
      if(result.length > 0 )
      {
        let code = result[0].code;
        let mensaje=result[0].strMensaje;
        let titulo = code == 409 ? mensaje: code == 200 ? mensaje: 'Error al Intentar Autorizar';
        Swal.fire({
          icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
          title: titulo,
          text: mensaje,
          confirmButtonText: 'Cerrar'
        });
        if(code==200){
          this.boolMostrarAceptar=false;
          this.fncreatepdf();
          //redireccionar
          this.router.navigate(['/listadoComodatos']);
        }
        else {
          this.boolMostrarAceptar=true;
          //redireccionar
          this.router.navigate(['/listadoComodatos']);
        }

      } 
      this.spinnerAut = false;
    },error=>{
      this.boolMostrarAceptar=true;
      this.spiner = false
      this.spinnerAut = false;
       console.error('Error al consumir la API:', error);
       Swal.fire({
        icon:   'error',
        title: 'Error de Conexión',
        text: 'Ocurrió un error de conexión, por favor intentelo nuevamente',
        confirmButtonText: 'Cerrar'
      });
    });  
}
fnGetDisponible(objArticulo:any){
  this.arraySaldou =[];
  let objarticulo = {
    strAccion: "getDisponible",  
    strConexion: this.strConexion,
    strArticulo: objArticulo.strArticulo 
  };
  this._httpService.getCotizador(objarticulo).subscribe(
    result => {  
      this.arraySaldou = result;  
    });
}
//FUNCIONES PARA PDF
getDate(){

}
fncreatepdf  () {
  //$filename2 = "PDFContratosComodatos/ContratoComodato_".$strIDComodatos.".pdf";
       
  const options = {
      margin: 8,
      filename: 'Comodato.pdf',
      image: { type: 'JPEG', quality: 0.98 },
      html2canvas: { scale: 2, autoPaging: true, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };
  const content =this.objInfo.strTipoSolicitud=='REPOSICIÓN'?document.getElementById('pdfTableReposicion'): document.getElementById('pdfTableFiniquito');
 
      html2pdf()
    .from(content)
    .set(options)
    .toPdf()
    .get('pdf')
    .then(pdf => {
      this.convertPDFToBlob(pdf);
    })
    .catch(error => {
      console.error('Error generating PDF:', error);
    });
 
};
convertPDFToBlob(pdf: jsPDF) {
  console.log('entra a convertPDFToBlob')
  const pdfBlob = pdf.output('blob');
  const formData = new FormData();
       let idComodato=this.intIDComodato;
        let nombre ='ContratoComodato_'+ idComodato+'.pdf'
  formData.append('archivo', pdfBlob, nombre);
  this.fnEnviarContrato(formData,pdf, nombre);
}
  fnEnviarContrato (formData:any, pdf:jsPDF, nombre :string){
  
        this._httpService.uploadContrato_Comodato(formData).subscribe(
          (response) => {
      console.log(response)
      console.log(response.mensaje)
      //
            if(response.mensaje ==408){
              Swal.fire("Error", "No se envió ningún documento", "error")
            }
            else if(response.mensaje ==409){
              Swal.fire("Error", "Ocurrió un error al guardar contrato", "error")
            }
            else if(response.mensaje ==200){
              pdf.save(nombre);
              Swal.fire({
                icon:  'success'  ,
                title: 'Documento Adjuntado Correctamente',
                text: 'Documento Adjuntado Correctamente',
                confirmButtonText: 'Cerrar'
              });
            }
      },
      (error) => {
      console.log(error)
      Swal.fire("Error", "No se recibio respuesta del servidor", "error")
      console.log(error.message)

      });
  }
  
}
