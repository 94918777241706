import { Component, OnInit,ElementRef } from '@angular/core';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comprasindirectas',
  templateUrl: './comprasindirectas.component.html',
  styleUrls: ['./comprasindirectas.component.css']
})
export class ComprasindirectasComponent implements OnInit {
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayAccesos:any=[];
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  isHoveredButton: boolean = false;
  public tagName:any;
  public strConexion:string="";
  constructor(private localSt:LocalStorageService,elem:ElementRef,public _alertify: AlertifyService, private router: Router,   private _httpService:PeticionesService, private _filtroServ :FiltrosService) { 
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      } else {
        this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
        this.strGerente = this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.strSucursal =this.boolSucursal?'':this.localSt.retrieve('strSucursal');
        this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
        this.strAgente= this.boolAgente?'':this.localSt.retrieve('strAgente');
      }
    }
  }

  ngOnInit(): void {
    this.fnGetFiltros();
  }

  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
      this.arrayAgentes.sort((a, b) => {
        if (a.strNombre < b.strNombre) {
          return -1;
        }
        if (a.strNombre > b.strNombre) {
          return 1;
        }
        return 0;
      });
      this.strAgente=this.localSt.retrieve('strAgente').toString();
      console.log(this.strAgente)
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
      this.arraySucursales.sort((a, b) => {
        if (a.strNombreSucursal < b.strNombreSucursal) {
          return -1;
        }
        if (a.strNombreSucursal > b.strNombreSucursal) {
          return 1;
        }
        return 0;
      });
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    // console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
      this.arrayGerentes.sort((a, b) => {
        if (a.strNombre < b.strNombre) {
          return -1;
        }
        if (a.strNombre > b.strNombre) {
          return 1;
        }
        return 0;
      });
    });
  }
  

}
