<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold d-flex justify-content-center nunito"><span>Ventas</span><span class="ms-2 titulo text-decoration-underline">Detalle</span> </h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow-sm pb-3">
        <div class="col-12 bgtable py-1 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="">
                <mat-option value="">-- Todos --</mat-option>
                <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option>
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente || boolSucursal || boolAgente }">
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetVtasNetas()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>



    <div class="row my-4">
        <div class="col-12 ">
            <div class="col-12 headTable bordertop d-flex align-items-center justify-content-between">
                <mat-form-field appearance="outline" class="my-2 ms-3 bg-white rounded ">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Cliente, movId, Agente, etcc." style="color: #1a1919!important "  #input>
                </mat-form-field>
                <span class="rounded shadow-sm p-2 bg-light text-secondary fs14 me-2">
                  Importe: <span class="fw-bold">{{totalImporte|currency}}</span> 
                </span>
            </div>
            <div class="mat-elevation-z8 table-responsive mb-0">
                <table mat-table [dataSource]="datosVentasDetalle" class="table table-bordered" matSort>
                  <!-- cliente Column -->
                  <ng-container matColumnDef="cliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 headTable text-center align-middle"> Cliente </th>
                    <td mat-cell *matCellDef="let row" class="ps-0 fs13 text-center"> {{row.strCliente}} </td>
                  </ng-container>
              
                  <!-- sucursal Column -->
                  <ng-container matColumnDef="sucursal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Sucursal </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strSucursalCliente}} </td>
                  </ng-container>
              
                  <!-- ordencompra Column -->
                  <ng-container matColumnDef="ordencompra">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Orden Compra </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strOrdenCompra}} </td>
                  </ng-container>
              
                  <!-- movimiento Column -->
                  <ng-container matColumnDef="movimiento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Movimiento </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13 "> {{row.strMovID}} </td>
                  </ng-container>

                  <!-- fecha Column -->
                  <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Fecha </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13 px-2"> {{row.strFechaEmision}} </td>
                  </ng-container>

                  <!-- almacen Column -->
                  <ng-container matColumnDef="almacen">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Almacen </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13 px-1"> ({{row.strAlmacen}}) {{row.strAlmacenNombre}}  </td>
                  </ng-container>

                  <!--   agente Column -->
                  <ng-container matColumnDef="agente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Agente </th>
                    <td mat-cell *matCellDef="let row" class="fw-bold text-center fs13 pe-2"> {{row.strAgente}} </td>
                  </ng-container>

                  <!-- articulo Column -->
                  <ng-container matColumnDef="articulo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 headTable text-center align-middle"> Articulo </th>
                    <td mat-cell *matCellDef="let row" class="pe-0 text-center fs13"> {{row.strArticulo}} </td>
                  </ng-container>

                  <!-- descripcion Column -->
                  <ng-container matColumnDef="descripcion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 headTable text-center align-middle"> Descripcion </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strDescripcion1}} </td>
                  </ng-container>
                  
                  <!-- subcuenta Column -->
                  <ng-container matColumnDef="subcuenta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 headTable text-center align-middle"> Sub Cuenta </th>
                    <td mat-cell *matCellDef="let row" class=" text-center fs13"> {{row.strSubCuenta}} </td>
                  </ng-container>
                  
                  <!-- cantidad Column -->
                  <ng-container matColumnDef="cantidad">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 headTable text-center align-middle"> Cantidad </th>
                    <td mat-cell *matCellDef="let row" class=" text-center fs13"> {{row.dblCantidad}} </td>
                  </ng-container>

                   <!-- precio Column -->
                   <ng-container matColumnDef="precio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 headTable text-center align-middle"> Precio </th>
                    <td mat-cell *matCellDef="let row" class="pe-2 ps-3 text-end fs13 fw-bold"> {{row.dblPrecio|currency}} </td>
                  </ng-container>

                   <!-- importe Column -->
                   <ng-container matColumnDef="importe">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 headTable text-center align-middle"> Importe </th>
                    <td mat-cell *matCellDef="let row" class="pe-2 ps-3 text-end fs13 fw-bold"> {{row.dblImporte|currency}} </td>
                  </ng-container>
              
                  <tr mat-header-row *matHeaderRowDef="columnsVtasNetas" class="sticky-top"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsVtasNetas;"></tr>
              
                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9">No se encuentra el dato: "{{input.value}}"</td>
                  </tr>
                </table>
            </div>
            <div class="col-12  ">
                <mat-paginator class="headTable borderbott" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>



        </div>
    </div>
</div>