import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-partner-registro',
  templateUrl: './partner-registro.component.html',
  styleUrls: ['./partner-registro.component.css']
})
export class PartnerRegistroComponent implements OnInit {
  registerForm: FormGroup;
  passwordMismatchError: boolean = false;
  passwordVisible: boolean = false; // Controla la visibilidad de la contraseña
  confirmPasswordVisible: boolean = false; // Controla la visibilidad de la confirmación de contraseña
  public strConexion:string ='COMERCIAL';
  public arrayColonias:any = [];
  public arrayRegimenFiscal: any = [];
  public arrayCfdi:any = [];
    constructor(private _httpService: PeticionesService,private fb: FormBuilder, private router: Router) { 
      this.registerForm = this.fb.group({
        name: ['', Validators.required],
        apellido: ['', Validators.required],
        nombreComercial: ['', Validators.required],
        razonSocial: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        telefono: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^\d{10}$/)]],
        rfc: ['', [Validators.minLength(12), Validators.maxLength(13), Validators.required]],
        regimenFiscal: ['', Validators.required],
        usoCfdi: ['', Validators.required] ,
        codigoPostal: ['', Validators.required],
        estado: ['', Validators.required] ,
        ciudad: ['', Validators.required] ,
        colonia: ['', Validators.required] ,
        calle: ['', Validators.required] ,
        numeroExterior: ['', Validators.required],
        numeroInterior: ['']  
       // confirmPassword: ['', [Validators.required]]
      });
    }
  
  // Getter para acceder más fácilmente a los controles del formulario
  get f() { return this.registerForm.controls; }
  ngOnInit(): void {
    this.fnGetRegimenFiscal();
    this.fnGetCfdi();

/*
    this.registerForm.statusChanges.subscribe(status => {
      console.log('Estado del formulario:', status);
      console.log('Valores del formulario:', this.registerForm.value);
      console.log('Errores del formulario:', this.registerForm.errors);
      console.log('Campos inválidos:', this.getInvalidControls());
    });*/

  }
  getInvalidControls() {
    const invalid = [];
    const controls = this.registerForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  onSubmit(customerData:any): void {
      if (this.registerForm.invalid) {
        return;
      }
   
      this.passwordMismatchError = false;
  
      // Aquí iría el código para manejar el registro, por ejemplo, hacer una solicitud HTTP
  


      
      this.fnValidLogin(this.registerForm.value).then(isValid => {
        if (!isValid) {
        
          this.fnSetRegister(this.registerForm.value);
        } else {
          Swal.fire("Error","Usuario existente ingrese otro email","error");
        }
      }).catch(error => {
        console.error("Error al validar el usuario:", error);
      });


      /* this.fnSetRegister( this.registerForm.value) */
    }
  
     // Función para alternar la visibilidad de la contraseña
   togglePasswordVisibility() {
     this.passwordVisible = !this.passwordVisible;
   }
 
   // Función para alternar la visibilidad de la confirmación de contraseña
   toggleConfirmPasswordVisibility() {
     this.confirmPasswordVisible = !this.confirmPasswordVisible;
   }
 
     fnSetRegister(data:any){
      let objCol = this.arrayColonias.find(o=> o.strColonia ==data.colonia ) ;
      let sucursal = objCol ? objCol.strAlmacen ==1?'0': objCol.strAlmacen: '0';
      let almacen  =   objCol ?  objCol.strAlmacen: '1';
       const encrypted = this.encrypt(data.password , 'eco');
       let articulo = {
         strAccion: "setRegistro",
         strConexion: this.strConexion,
         strRazonSocial:data.razonSocial,
         strNombreComercial:data.nombreComercial,
         strRFC:data.rfc,
         strFiscalRegimen:data.regimenFiscal,
         strSucursalEmpresa: sucursal ,
         strCodigoPostal : data.codigoPostal,
         strEstado:objCol.strEstado,
         strPoblacion: objCol.strPoblacion,
         strColonia:data.colonia,
         strDireccion:data.calle,
         strDireccionNumero:data.numeroExterior,
         strDelegacion:objCol.strDelegacion,
         strTelefono:data.telefono,
         strRuta:objCol.strRuta ? objCol.strRuta :'',
         strAlmacenDef: almacen,
         strContacto1: data.name + ' '+ data.apellido,
         strTelefono1: data.telefono,
         streMail1:data.email,
         strUsuario: data.email,
         strPassword:encrypted,
         strNombre:data.name,
         strApellidos:data.apellido,
         strCorreo:data.email,
         strUsoCFDI:data.usoCfdi
       }; 

       console.log(articulo)
        this._httpService.mostrarCotizaciones(articulo, 'sp_PartNer').subscribe(
         result => {
           console.log(result);
           if(result[0] && result[0].code && result[0].code == 200){
                Swal.fire({
                         icon: "success",
                         title: "Registro Exitoso",
                         text:  result[0].strMensaje 
                       });
                       this.router.navigate(['/login']);
           }
           else {
                 Swal.fire({
                           icon: "error",
                           title: "Error al generar registro.",
                           text: result[0].strMensaje 
                         });
           }
         }) 
     }
   encrypt(string: string, key: string): string {
     let result = '';
     for (let i = 0; i < string.length; i++) {
         const char = string[i]; // Obtener el   carácter
         let lk = key.length;
         let mod = i%lk;
         let position=  mod - 1;
        
 
         if (position < 0) {
           position = lk - 1; // Ajustar a la última posición si es negativo
       }
   
         const keychar =  key.charAt(position);  // Obtener el código ASCII del carácter de la clave
         
         const asciiValue = char.charCodeAt(0) + keychar.charCodeAt(0) ; // Devuelve 97
 
         const char2 = String.fromCharCode(asciiValue); 
 
        
         result += char2;
        
         //const encryptedChar = String.fromCharCode(char + keychar); // Sumar los códigos ASCII
         //result += encryptedChar; // Agregar el carácter cifrado al resultado
     }
     return btoa(result); // Codificar el resultado en Base64
 }
/*  onKeyDown(event: KeyboardEvent) {
  // Código ASCII de la tecla Delete y Retroceso     const DELETE_KEY = 46;     const BACKSPACE_KEY = 8;
  const ENTER_KEY = 13;
  const TAB_KEY =9;
  // Verificar si se presionó la tecla Delete o Retroceso
  if (event.keyCode === ENTER_KEY || event.keyCode === TAB_KEY) {
    this.fnGetDatosFiscales();
  } else {
    this.registerForm.controls['estado'].setValue('');
    this.registerForm.controls['ciudad'].setValue('');
  }
} */
  onKeyDown(event: KeyboardEvent) {
    const ENTER_KEY = 13;
    const TAB_KEY = 9;
  
    if (event.keyCode === ENTER_KEY || event.keyCode === TAB_KEY) {
      this.fnGetDatosFiscales();
    } else {
      this.registerForm.controls['estado'].setValue('');
      this.registerForm.controls['ciudad'].setValue('');
    }
  }
  
  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
  
    if (value.length === 5) {
      // Simula la pulsación de la tecla Enter
      this.fnGetDatosFiscales();
    }else{
      this.arrayColonias = [];
      this.registerForm.controls['estado'].setValue('');
      this.registerForm.controls['ciudad'].setValue('');
      this.registerForm.controls['colonia'].setValue('');

    }
  }

   fnGetDatosFiscales() {
     if (this.registerForm.controls['codigoPostal'].value) {
       this._httpService.mostrarCotizaciones({
         strAccion: 'getCodigoPostal',
         strCodigoPostal: this.registerForm.controls['codigoPostal'].value,
         strConexion: this.strConexion
       }, 'sp_modventas_Directorio').subscribe(
         result => {
           if (result.length > 0) {
             this.registerForm.controls['estado'].setValue(result[0].strEstado);
             this.registerForm.controls['ciudad'].setValue(result[0].strDelegacion);
             this.arrayColonias = result; 
             console.log(result);
      
           } else {
             Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: '¡Codigo Postal Incorrecto!',
             });
             this.registerForm.controls['strEstado'].setValue('');
             this.registerForm.controls['strCiudad'].setValue('');
             this.arrayColonias = []
           }
         },
         error => {
           var error = <any>error;
           console.log(error);
         });
     } else {
       console.log('Sin CP')
     }
   }
 
   fnGetRegimenFiscal() {
    this._httpService.mostrarCotizaciones({
      strAccion: 'getFiscalRegimen',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
       /*  console.log(result) */
        this.arrayRegimenFiscal = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnGetCfdi() {
    this._httpService.mostrarCotizaciones({
      strAccion: 'getUsoCfdi',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
        this.arrayCfdi = result
      }, error => {
        var error = <any>error;
        console.log(error);
      });
  }
  limitarTelefono(event: any) {
    let valor = event.target.value;
    if (valor.length > 10) {
      event.target.value = valor.slice(0, 10);
    }
  }

  fnValidLogin(data:any):Promise<boolean> {
    return new Promise((resolve,reject)=>{
      this._httpService.mostrarCotizaciones({
        strAccion: 'loginExist',
        strNombre:data.email,
        strConexion: this.strConexion
      }, 'sp_PartNer').subscribe(
        result => {
          if (result[0]?.code === 200) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, error => {
          console.error("Error en la validación del login:", error);
          reject(false);
        }
      );
    })
  } 

}
