import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2'; 

@Component({
  selector: 'app-dialog-autorizar-reto',
  templateUrl: './dialog-autorizar-reto.component.html',
  styleUrls: ['./dialog-autorizar-reto.component.css']
})
export class DialogAutorizarRetoComponent implements OnInit {

  public strTipo: string="";
  public strAgente: string="";
  public strCliente: string="";
  public strNombre: string="";
  public intIDSucursal: number=0;
  public strNombreSucursal: string="";
  public strRespuesta:string="";
  public arrayClientes: any=[];
  public strBusqueda: string="";
  public mostrarCargando: boolean=false;
  public arrayDatosCteSeleccionado: any=[];
  public strMotivoRechazoReto: string="";
  public intIDReto: number=0;
  public arrayRespuesta: any=[];
  public strTipoReto:string;
 
  constructor( @Inject(DOCUMENT) private document: Document,@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialog, private _peticionesService: PeticionesService, private router: Router, private localSt:LocalStorageService,) { 
    console.log(this.data);
    if(this.data!=undefined){
      this.intIDSucursal=this.data.intIDSucursal?this.data.intIDSucursal:0;
      this.strAgente=this.data.strAgente?this.data.strAgente:0;
      this.strCliente=this.data.strCliente?this.data.strCliente:0;
      this.strNombre=this.data.strNombre?this.data.strNombre:0;
      this.strNombreSucursal=this.data.strNombreSucursal?this.data.strNombreSucursal:0;
      this.strTipo=this.data.strTipo?this.data.strTipo:0;
      this.intIDReto=this.data.intIDReto?this.data.intIDReto:0;
      this.strTipoReto=this.data.strTipoReto?this.data.strTipoReto:'';
    }
    this.arrayDatosCteSeleccionado=[];
  }

  ngOnInit(): void {
    this.localSt.store('arrayRetoAceptado',[]);//Limpiar variable
  }

  
  fnCambioRespuesta(){
    //alert("cambio")
    console.log(this.strRespuesta)
    if(this.strRespuesta=='Acepta' && this.strTipo=='Prospecto'){
      Swal.fire({
        icon: 'warning',
        title: 'Seleccione un Cliente valido',
        text: 'Por favor seleccione un cliente valido para generar el movimiento',
      }); 
    }
  }
  
  fnBuscarClienteProsp(){
    this.mostrarCargando=true;
    this.arrayClientes=[];

    if(this.strBusqueda==undefined || this.strBusqueda=='' || this.strBusqueda==null){
      this.mostrarCargando=false;
      this.arrayClientes=[];      
      return;
    }
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getCliente',
      "strAgente": this.strAgente,
      "strBusqueda": this.strBusqueda
    }).subscribe( result => {
      console.log(result);
      this.arrayClientes=result;
      this.mostrarCargando=false;
    });
  }

  fnSeleccionoCliente(cte:any){
    //console.log(cte);
    this.arrayClientes=[];
    this.arrayDatosCteSeleccionado=cte;

    this.strCliente=this.arrayDatosCteSeleccionado.strCliente?this.arrayDatosCteSeleccionado.strCliente:'';
    this.strNombre=this.arrayDatosCteSeleccionado.strNombre?this.arrayDatosCteSeleccionado.strNombre:'';
    this.intIDSucursal=this.arrayDatosCteSeleccionado.intIDSucursal?this.arrayDatosCteSeleccionado.intIDSucursal:0;
    this.strNombreSucursal=this.arrayDatosCteSeleccionado.strNombreSucursal?this.arrayDatosCteSeleccionado.strNombreSucursal:'';
    
    //Mensaje
    Swal.fire({
      icon: 'success',
      title: 'Correcto',
      text: 'Cliente seleccionado correctamente',
    });    
  }

  fnAutorizaRechazaReto(){
    if(this.strRespuesta=='Rechaza'){
      if(this.strMotivoRechazoReto==''){
        Swal.fire({
          icon: 'error',
          title: 'Agregue un comentario',
          text: 'Por favor agregue un motivo de rechazo del Cliente',
        });
        return;
      }else{
        //Ir a BD a guardar el comentario en la base de datos y hacer el movimineto Devolucion
        this._peticionesService.getApiRetoTork({
          "strAccion": 'setRechazaReto',
          "strMotivoRechazoReto": this.strMotivoRechazoReto,
          "intIDReto": this.intIDReto,
          "strComentarios":this.strTipoReto
        }).subscribe( result => {
          this.arrayRespuesta=result;
          //console.log(result);
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: 'Se han guardado sus comentarios y se rechazo correctamente el reto',
          });
          this.dialogRef.closeAll();//Cerrar modal
          this.router.navigate(['/retoTork']);
        });
      }
    }else{
      if(this.strTipo=='Prospecto'){
        //console.log(this.arrayDatosCteSeleccionado);
        if(this.arrayDatosCteSeleccionado.strCliente!='' && this.arrayDatosCteSeleccionado.strCliente!=undefined){
          //Ir a BD a guardar el Clien en la base de datos y hacer el movimineto Devolucion
          this._peticionesService.getApiRetoTork({
            "strAccion": 'setEditProspACte',
            "strCliente": this.strCliente,
            "intIDSucursal": this.intIDSucursal,
            "strNombreSucursal": this.strNombreSucursal,
            "intIDReto": this.intIDReto
          }).subscribe( result => {
            this.arrayRespuesta=result;
            //console.log(result);
            Swal.fire({
              icon: 'success',
              title: 'Correcto',
              text: 'Se ha guardado correctamente el Cliente',
            });
          });

          this.fnAceptarReto();
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Seleccione un Cliente',
            text: 'Por favor seleccione un Cliente para generarle los movimientos',
          });
        }
      }else{
        this.fnAceptarReto();
      }  
    }
  }


  fnAceptarReto(){
    let datosAceptaReto={
      "strAccion": 'setAceptaReto',
      "intIDReto": this.intIDReto,
      "strComentarios":this.strTipoReto
    }
    console.log(datosAceptaReto);
    this._peticionesService.getApiRetoTork(datosAceptaReto).subscribe( result => {
      this.arrayRespuesta=result;
      console.log(result);
     
      /*Swal.fire({
        icon: 'success',
        title: 'Correcto',
        text: 'Se ha Aceptado el Reto',
      });   */

      //Guardar en sesion IDReto ACEPTADO:
      let datosRetoAceptado={
        intIDReto: this.intIDReto,
        strEstatusSeguimiento: 'ACEPTADO',
        strEstatusAnalisis: 'BORRADOR'
      }
      this.localSt.store('arrayRetoAceptado', datosRetoAceptado);
      this.dialogRef.closeAll();//Cerrar modal  
      //this.router.navigate(['/listaDespachadores']);
      
      this.document.location.href = 'https://www.ecodeli.com/Puente_retoTork.php?idReto='+this.intIDReto;
    }, error => {
      
      Swal.fire({
        icon: 'error',
        title: 'Lo sentimos',
        text: 'Ocurrio un error, intentelo nuevamente',
      });   
      console.log(error);
    });
  }

  
}
