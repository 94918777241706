import { ElementRef, Component, OnInit } from '@angular/core';
 
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
 
import { CarroService } from '../servicios/carroservice';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { AlertifyService } from '../alertify.service';
import { DataDBService } from '../servicios/data-db.service';

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.css']
})
export class ScoreCardComponent implements OnInit {
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  public strConexion:string="";
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayAccesos:any=[]; 
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public tagName:any;
  isHoveredButton: boolean = false;
  public arrayResultado:any=[];
  public ListaModulos:any=[];
  constructor(private indexedDBService: DataDBService, elem:ElementRef,public _alertify: AlertifyService,private carroservice:CarroService,private localSt:LocalStorageService, private editPl:PlantillafinService,   private router: Router,   private _httpService:PeticionesService, private _filtroServ :FiltrosService) { 
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
      //obtener niveles de acceso
      this.tagName=elem.nativeElement.tagName.toLowerCase();
      
      this.fnGetFiltros();
  }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {

      //this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
      this.arrayAccesos=value?value:[];
      
      if(this.arrayAccesos.length>0){
        var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
        
        if(objAcceso==undefined||objAcceso.length==0){
          this.router.navigate(['/login']);
        }
        else{  

          this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
          this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
          this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
          this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
          this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
          this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';

             //------------conexi+on-------------------
             this.indexedDBService.getItem('strConexion', (value) => {
              this.strConexion =value;
              console.log(' CARGA conexion reporte principal')
         
              this.fnGetData();
            });
        }
      }
      else {
        this.router.navigate(['/login']);
      }

    });
 
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  //    console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }
  fnGetData(){
    this.arrayResultado=[];
    this.ListaModulos=[];
    let objParametros = {
      "strAccion": "ScordCard",
      "strSucursal": this.strSucursal !=''?this.strSucursal:null,
      "strGerente": this.strGerente !=''?this.strGerente :null
    };
    if(this.strAgente !='')
    {
      let objParametros = {
        "strAccion": "ScordCard",
        "strAgente": this.strAgente 
      };
      this._httpService.getApiComprasScordCardVendedor(objParametros).subscribe(
        result => {
          console.log(result)
          this.arrayResultado.push(...result);
          this.arrayResultado.forEach(element => {
            let mod =this.ListaModulos.find(o=>o==element.strModulo )
            if(!mod){
              this.ListaModulos.push(element.strModulo)
            }
            
          });
        });
    }
    else {
      this._httpService.getApiComprasScordCard(objParametros).subscribe(
      result => {
        
       // this.arrayResultado=result;
        console.log(result)
        this.fnGetScore_Card2da();
      });
    }

  }
  fnGetScore_Card2da(){
    let objParametros = {
      "strAccion": "ScordCard2",
      "strSucursal": this.strSucursal !=''?this.strSucursal:null,
      "strGerente": this.strGerente !=''?this.strGerente :null
    };
    
    this._httpService.getApiComprasScordCard(objParametros).subscribe(
      result => {
        console.log(result)
        this.arrayResultado.push(...result);
        this.arrayResultado.forEach(element => {
          let mod =this.ListaModulos.find(o=>o==element.strModulo )
          if(!mod){
            this.ListaModulos.push(element.strModulo)
          }
          
        });
        console.log(this.arrayResultado)
      });
  }

}
