
<div class="container py-3">
    <div class="row">
        <h1 class="text-center fw-bold nunito">
            TABLERO PRINCIPAL
        </h1>
    </div>
    <div class="row ">
        <div class=" mt-2 pt-1 col d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" (selectionChange)="fnGetTableroCliente()">
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="mt-2 pt-1 col  d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio" (selectionChange)="fnGetTableroCliente()">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
       <!--  <div class="col mt-2 pt-1   d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label>Buscar Pedido</mat-label>
                <input matInput placeholder="Buscar">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
        </div> -->
        <div class="col mt-2 pt-1   d-flex justify-content-center align-items-center"  >
            <button class="btn btn-outline-success shadow-sm px-2 py-1" *ngIf=" arraySeguimientoPedidos.length>0" (click)="exportToExcel()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="fa-regular fa-file-excel pe-2"></i>
               <span class="fs12 fw-bold">Descargar</span> 

               
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="spMostrarCargandoPV" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="alert alert-danger text-center fw-bold my-3" *ngIf=" !spMostrarCargandoPV && arraySeguimientoPedidos.length==0" role="alert">
                No se han encontrado datos de pedidos
              </div>
            <div class="table-responsive mb-0" [hidden]=" arraySeguimientoPedidos.length==0">
                <mat-form-field appearance="outline" class="bg-white rounded my-3">
                    <mat-label>Buscar:</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                  </mat-form-field>
                  
                  <div class="mat-elevation-z8">
                    <table class="table w-100 table-bordered" mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="strPeriodo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2 fs12"> # </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2"> {{row.strPeriodo}} </td>
                          </ng-container>
                        <ng-container matColumnDef="strMovIDP">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> Total Pedidos Generados  </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fw-bold" (click)="fnAbrirModal('Total Pedidos','',row.intPeriodo)" data-bs-toggle="modal" data-bs-target="#exampleModal"> {{row.strMovIDP}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strOrdenCompraP">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12">Entrega Menor a 3 días  </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle text-primary" (click)="fnAbrirModal('Entrega Menor a 3 días','getMTres',row.intPeriodo)" data-bs-toggle="modal" data-bs-target="#exampleModal"> {{row.strOrdenCompraP}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strOrdenCompraPorcentaje">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> %</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 text-primary" (click)="fnAbrirModal('Entrega Menor a 3 días','getMTres',row.intPeriodo)" data-bs-toggle="modal" data-bs-target="#exampleModal"> <span class="text-nowrap">{{row.strOrdenCompraPorcentaje|number:'1.2-2'}} %</span></td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strFemisionP">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12">  Entrega de 4 a 7 días </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle text-primary" (click)="fnAbrirModal('Entrega de 4 a 7 días','getMSiete',row.intPeriodo)" data-bs-toggle="modal" data-bs-target="#exampleModal"> {{row.strFemisionP}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strFemisionPorcentaje">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> %</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal('Entrega de 4 a 7 días','getMSiete',row.intPeriodo)"><span class="text-nowrap"> {{row.strFemisionPorcentaje|number:'1.2-2'}} %</span></td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strDelegacion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> Entrega Mayor a 7 días </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal('Entrega Mayor a 7 días','getMaySiete',row.intPeriodo)"> {{row.strDelegacion}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strDelegacionPorcentaje">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> % </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal('Entrega Mayor a 7 días','getMaySiete',row.intPeriodo)"> <span class="text-nowrap">{{row.strDelegacionPorcentaje|number:'1.2-2'}} %</span></td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strCte">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> Pendientes de Factura </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal('Pendientes de Factura','getPFactura',row.intPeriodo)"> {{row.strCte}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strCtePorcentaje">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> % </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal('Pendientes de Factura','getPFactura',row.intPeriodo)"><span class="text-nowrap">{{row.strCtePorcentaje|number:'1.2-2'}} %</span> </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strMovIDE">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> En Tránsito </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal('En Tránsito','getPTransito',row.intPeriodo)"> {{row.strMovIDE}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strMovIDEPorcentaje">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> % </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal('En Tránsito','getPTransito',row.intPeriodo)" > <span class="text-nowrap">{{row.strMovIDEPorcentaje|number:'1.2-2'}} %</span></td>
                          </ng-container>

                          <ng-container matColumnDef="strEntregas7dias">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs12"> Total Entregas Menor a 7 días</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fw-bold"> {{row.strEntregas7dias}} </td>
                          </ng-container>

                          <ng-container matColumnDef="strEntregas7diasPorcentaje">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2"> % </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2">
                                <span class="text-nowrap fw-bold" [ngClass]="{'text-succes':row.strEntregas7diasPorcentaje>=95,'text-danger':row.strEntregas7diasPorcentaje<95}">{{row.strEntregas7diasPorcentaje|number:'1.2-2'}} %</span>
                            </td>
                          </ng-container>
                          
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  
                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                      </tr>
                    </table>
                  
                 
                  </div>
            </div>
            <div class="col-12">
              <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>


</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title mb-0 fw-bold mx-auto" id="exampleModalLabel">{{objModal.titulo}}</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div *ngIf="spMostrarCargandoPV" class="text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="contTabla table-responsive" *ngIf="!spMostrarCargandoPV">
                <div *ngIf="objModal.arrayDatos.length == 0" class="text-center">
                  <h3 class="text-danger mb-0">No hay información</h3>
                </div>
                <!-- Tabla -->
                <table class="table table-hover table-bordered" *ngIf="objModal.arrayDatos.length >0">
                  <thead>
                    <tr class="azulFuerte2">
                      <th class="text-center bg-light">#</th>
                      <th class="text-center bg-light">Cte</th>
                      <th class="text-center bg-light">Nombre Cte</th>
                      <th class="text-center bg-light">Fecha Pedido</th>
                      <th class="text-center bg-light">Importe Pedido</th>
                      <th class="text-center bg-light">Factura</th>
                      <th class="text-center bg-light">Importe Factura</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let DPV of objModal.arrayDatos; let i = index">
                      <td class="text-center">{{ i + 1 }}</td>
                      <td class="text-left fw-bold fs13">{{ DPV.strCte }}</td>
                      <td class="text-center fw-bold fs11">{{ DPV.strNombreCte }}</td>
                      <td class="text-right fs13">{{ DPV.strFemisionP }}</td>
                      <td class="text-right fs13">{{ DPV.dblImporteP | currency }}</td>
                      <td class="text-right fs13">{{ DPV.strMovIDF }}</td>
                      <td class="text-right">
                        <span *ngIf="DPV.dblImporteFactura > 0">
                          {{ DPV.dblImporteFactura | currency }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

        </div>
      </div>
    </div>
  </div>