import { Component,OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import Swal from 'sweetalert2';
import * as _moment from 'moment';
export interface UserData {
  creationDate: string;
  currencyCode: string;
  lastChange: string;
  fruit: string;
  orderId:string;
  orderIsComplete:boolean;
  status:string;
  totalItems:number;
  totalValue:number;
  clientName:string;

}


@Component({
  selector: 'app-vtex',
  templateUrl: './vtex.component.html',
  styleUrls: ['./vtex.component.css']
})
export class VtexComponent implements OnInit,AfterViewInit  {
  displayedColumns: string[] = ['orderId', 'clientName','status', 'creationDate', 'lastChange','totalItems','totalValue',
  'strMovID',
'strMovIDRem' ];
  public strOrderID:string;
  public arrayArticulos:any;
  public strFecha: any=_moment();
  public arrayTotales:any;
  public objDatosEnvio:any={address:{}};
  public fechaRow:any;
  public boolRem: boolean=false;
  public objOrdenCompra:any;
  public boolDetalle:boolean=true;
  public dataCte:any={};
  public estatusPedido:string='';
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Filtros', cols: 2, rows: 2 ,id:1},
          { title: 'Pedidos', cols: 2, rows: 8,id:2 }
        ];
      }

      return [
        { title: 'Filtros', cols: 2, rows: 2,id:1 },
        { title: 'Pedidos', cols: 2, rows: 7,id:2 } 
      ];
    })
  );

  constructor(private breakpointObserver: BreakpointObserver,private localSt:LocalStorageService,
    private _httpService:PeticionesService) {
      // Create 100 users
    //const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    //this.dataSource = new MatTableDataSource(users);
    }
    ngOnInit(): void {
      this.fnGetEstatusPedido();      
    }
    ngAfterViewInit() { 
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      console.log(filterValue)
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    fnFechaSelected(algo:any){
      console.log(algo.format())
      this.strFecha=algo;
    
      this.fnGetEstatusPedido();
    }  

    
    fnApi(arrayPedidosIntelisis:any){
      console.log(this.strFecha.toLocal)
      let fecha = _moment(this.strFecha).format('YYYY-MM-DD');
      let api='/api/orders/extendsearch/orders?per_page=100&f_creationDate=creationDate%3A%5B'+fecha+'T00%3A00%3A00.000Z%20TO%20'+fecha+'T23%3A59%3A59.999Z%5D'//&f_status=ready-for-handling
     // let api ='/api/oms/pvt/orders?per_page=100&f_status=ready-for-handling';
      this._httpService.putVtex_php(api,{'quantity' :0,'api':api,'tipo':'GET'}).subscribe(
        result => {  
          console.log('result:');
          console.log(result.list);
          const users = result.list;
          users.forEach(function (value) {
            value.creationDate=_moment(value.creationDate).format("YYYY-MM-DD");
            value.lastChange=_moment(value.lastChange).format("YYYY-MM-DD");
            console.log(value.creationDate);
            //buscar por orden de compra
            let objPIntel = arrayPedidosIntelisis.find(o=>o.strOrdenCompra==value.orderId);
            value.strMovID=objPIntel?objPIntel.strMovID:'';
            value.strEstatus=objPIntel?objPIntel.strEstatus:'';
            value.strMovIDRem=objPIntel?objPIntel.strMovIDRem:'';
            value.strEstatusRem=objPIntel?objPIntel.strEstatusRem:'';
            value.strMovIDEmb=objPIntel?objPIntel.strMovIDEmb:'';
            value.strEstatusEmb=objPIntel?objPIntel.strEstatusEmb:'';
            value.intIDPedido=objPIntel?objPIntel.intIDPedido:'';
  
          }); 
          this.dataSource = new MatTableDataSource(users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
           console.log(users)
        });
    }
  fnApi_r(arrayPedidosIntelisis:any){
    let api='api/orders/extendsearch/orders'
    this._httpService.getVtex_php(api).subscribe(
      result => { 
        const users = result.list;
        users.forEach(function (value) {
          value.creationDate=_moment(value.creationDate).format("YYYY-MM-DD");
          value.lastChange=_moment(value.lastChange).format("YYYY-MM-DD");
          console.log(value.creationDate);
          //buscar por orden de compra
          let objPIntel = arrayPedidosIntelisis.find(o=>o.strOrdenCompra==value.orderId);
          value.strMovID=objPIntel?objPIntel.strMovID:'';
          value.strEstatus=objPIntel?objPIntel.strEstatus:'';
          value.strMovIDRem=objPIntel?objPIntel.strMovIDRem:'';
          value.strEstatusRem=objPIntel?objPIntel.strEstatusRem:'';
          value.strMovIDEmb=objPIntel?objPIntel.strMovIDEmb:'';
          value.strEstatusEmb=objPIntel?objPIntel.strEstatusEmb:'';
          value.intIDPedido=objPIntel?objPIntel.intIDPedido:'';

        }); 
        this.dataSource = new MatTableDataSource(users);
        this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
         console.log(users)
      });
  }
  fnOrderDetail(id:string, fecha:any, estatus:string){
    this.boolDetalle=true;
   this.strOrderID =id
   this.arrayArticulos=[];
   this.arrayTotales=[];
   this.objDatosEnvio={};
   this.fechaRow=fecha
   this.dataCte={};
   this.estatusPedido=estatus;
    console.log(id)
    let api='/api/oms/pvt/orders/'+id
    this._httpService.putVtex_php(api,{api:api,tipo:'GET'}).subscribe(
      result => { 
        console.log(result)
       // this.arrayArticulos=result.items;  
        let arrayArticulos=result.items;       
        this.dataCte=result.clientProfileData;
        var sum=0;

         arrayArticulos.forEach((value) => {
            let components =(value.components && value.components.length>0)?value.components:[]; //en caso de ser kit obtenemos los componentes
            if(components.length>0){ //combinamos los dos arrays             
              value.precioReal=0;
              value.tipo='KIT'
               //CALCULAMOS EL PRECIO REAL POR CADA ARTICULO
              components.forEach((value2) => {
                let precioReal=value2.priceDefinition.calculatedSellingPrice?value2.priceDefinition.calculatedSellingPrice:value2.price;
                value2.precioReal=precioReal; 
                value2.referencia ='KIT'+value.refId; 
                value2.tipo='KITC';          
                sum = sum+(value2.precioReal*value2.quantity);
              });
              this.arrayArticulos.push(value);
              this.arrayArticulos.push(...components)
            }
            else {

            //obtener el descuento 
            let precioReal=value.priceDefinition.calculatedSellingPrice?value.priceDefinition.calculatedSellingPrice:value.price;
            value.precioReal=precioReal;            
            sum = sum+(value.precioReal*value.quantity);
            this.arrayArticulos.push(value);
           }
        });
        this.arrayTotales = result.totals;
        this.arrayTotales.push({id:'Total Articulos', name:'Total Artículos' ,value:sum})
        this.arrayTotales.push({id:'Total', name:'Total' ,value:result.value})
        this.objDatosEnvio=result.shippingData; 
        this.boolDetalle=false;
        console.log(this.objDatosEnvio) 
        console.log(this.dataCte);
        
      }); 
  }
  fnGetEstatusPedido(){
    let arrayPedidosIntelisis = [];
    const cadenaDeTexto = this.strFecha.format('YYYY-MM-DD');
    let objParams= {strAccion:'getSeguimiento', strFechaEmision:cadenaDeTexto};
  
    console.log(objParams)
    this._httpService.getVtexEcodeli(objParams).subscribe(
      result => {
          console.log(result);
          arrayPedidosIntelisis=result;
          this.fnApi(arrayPedidosIntelisis);
        });
    
  } 
  fnAfectarPedido(obj:any){
    this.boolRem = obj.strEstatus=='PENDIENTE'?true:false;
    this.objOrdenCompra=obj;
    
  }
  fnGenerarRemision(){
    let objParams= {strAccion:'setRemision', strOrdenCompra:this.objOrdenCompra.orderId};
    console.log(objParams)
    this._httpService.getVtexEcodeli(objParams).subscribe(
      result => {
          console.log(result);
          if(result[0]){
            if(result[0].code== 200){
                  Swal.fire({
                  icon: 'success',
                  title: 'Correcto...',
                  text: result[0].strMensaje + 'remision ID:'+result[0].intIDArticulo,
                  });  
            }
            else if(result[0].code== 409){
                Swal.fire({
                icon: 'error',
                title: 'Lo sentimos',
                text: result[0].strMensaje,
              }); 
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Lo sentimos',
              text: 'No se obtuvo respuesta',
            }); 
          }
        });
  }
  fnSetPedido_Ecodeli(objDireccion:any, arrayArticulo:any, strOrdenCompra:string,objCosto:any){ 
    let direccion = objDireccion.address.street+ ' #'+objDireccion.address.number+' Int.'+objDireccion.address.complement+' Col.'+objDireccion.address.neighborhood+', '+objDireccion.address.city+
    ' Edo.'+objDireccion.address.state+','+objDireccion.address.country + ' cp:'+objDireccion.address.postalCode
   var strReferencia=' Recibe: '+objDireccion.address.receiverName;
   let strCodigoPostal=objDireccion.address.postalCode;
    console.log(strReferencia)
    console.log(arrayArticulo);
    console.log(objDireccion);
    console.log(objCosto);
    console.log(strCodigoPostal);
    var xmlArticulos=[]
    arrayArticulo.forEach(function (value) { //refId
      if( value.tipo !='KIT'){
        let objArt={strArticulo:value.refId,dblPrecio:value.precioReal/100  , intCantidad:value.quantity  }
        xmlArticulos.push(objArt)
      }
    })
    let envio = objCosto.find(o=>o.id =='Shipping')?objCosto.find(o=>o.id =='Shipping').value /100:0;
    envio=envio>=1?envio:0.89;
    // agregamos envio
    let objArt={strArticulo:'ENVIO',dblPrecio:envio  , intCantidad:1 }
    xmlArticulos.push(objArt)
  
    let objParams= {strAccion:'setPedido', strReferencia:strReferencia ,strObservaciones:direccion,strOrdenCompra:strOrdenCompra, xmlArticulos:xmlArticulos,strFechaEmision:this.fechaRow, 
    strCodigoPostal: strCodigoPostal};
    console.log(objParams)
   this._httpService.getVtexEcodeli(objParams).subscribe(
      result => {
          console.log(result);
          if(result[0]){
            if(result[0].code== 200){
                  Swal.fire({
                  icon: 'success',
                  title: 'Correcto...',
                  text: result[0].strMensaje + 'pedido ID:'+result[0].intIDArticulo,
                  });  
                  this.fnGetEstatusPedido();
            }
            else if(result[0].code== 409){
                Swal.fire({
                icon: 'error',
                title: 'Lo sentimos',
                text: result[0].strMensaje,
              }); 
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Lo sentimos',
              text: 'No se obtuvo respuesta',
            }); 
          }
        });
  }
 
}
