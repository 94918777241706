import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { CarroService } from '../servicios/carroservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from "rxjs";
import { CarritoNService } from '../servicios/carrito-n.service';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css']
})
export class BusquedaComponent implements OnInit {

  constructor(private _router: Router, private rutaActiva: ActivatedRoute, private _peticionesService: PeticionesService, private _carro: CarroService, private _carrito: CarritoNService) {//
  
  }

  ngOnInit(): void {
  }

}
