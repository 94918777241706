<div class="container">
    <div class="row justify-content-between">
        <div class="col-12 col-lg-8">
            <!-- Título del carrito -->
             <div class="col-12 text-center">
                <h1 class="text-center">Tu carrito ({{ cartItems.length }} Artículo(s))</h1>
             </div>
             <div class="d-none d-lg-block">
                <div class="col-12 bgTablaProd rounded-top py-2  d-flex">
                    <div class="col-2 text-center fw-bold">
                        <span class="text-center">
                            PRODUCTO
                        </span>
                    </div>
                    <div class="col-3 text-center fw-bold">
                        <span class="text-center">
                        </span>
                    </div>
                    <div class="col-2 text-center fw-bold">
                        <span class="text-center">
                            PRECIO
                        </span>
                    </div>
                    <div class="col-2 text-center fw-bold">
                        <span class="text-center">
                            CANTIDAD
                        </span>
                    </div>
                    <div class="col-2 text-center fw-bold">
                        <span class="text-center">
                            TOTAL
                        </span>
                    </div>
                    <div class="col-1 text-center fw-bold">
                        <span class="text-center">
                        </span>
                    </div>
                 </div>
                <div class="cart-item bg-white shadow-sm rounded mb-2" *ngFor="let product of cartItems">
                    <div class="col-12 d-flex">
                        <div class="col-2 text-center fw-bold">
                            <span class="text-center pointer" (click)="addToCart(product)">
                                <img class="w-100" [src]="product.strUrlImg" alt="{{ product.name }}">
                            </span>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <span class=" fs12 mt-auto" [ngClass]="{'mb-auto':product.strTipoOpcion!='Si'}">
                                <span class="fw-bold">({{product.strArticulo}})</span>
                                {{product.strDescripcion1}}
                            </span>
                            <span class="mt-2 fw-bold mb-auto text-secondary" *ngIf="product.strTipoOpcion=='SI' || product.strTipoOpcion=='Si' || product.strTipoOpcion=='si'">
                                Opcion: <span class="text-dark"> {{product.strNombreOpcion}} <span class="text-normal">({{product.strOpcion}})</span></span> 
                            </span>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center">
                            <span class="">
                                <span class="old-price" *ngIf="product.oldPrice">{{ product.oldPrice | currency }}</span>
                                <span class="">{{ product.dblPrecioLista | currency }}</span> <!--<span>{{ product.dblPrecioConDescuento | currency }} </span>-->
                            </span>
                        </div>
                        <div class="col-2 text-center d-flex align-items-center justify-content-center">
                            <span class="">
                                <div class="input-group"> 
                                    <button [disabled]="(product.dblCantidad == 1 || product.dblCantidad < 1) " (click)="decreaseQuantity(product)" class="btn  btFlechas shadow-sm py-1 px-2 fs12" >-</button>
                                    <input (paste)="preventPaste($event)" [min]="1"(keydown)="preventDecimal($event)" (input)="fnCapInput($event,product)" [(ngModel)]="product.dblCantidad" type="number" class="form-control fs12 py-0 text-center sin-flechas" id="quantity" min="1" />
                                    <button (click)="increaseQuantity(product)" [disabled]="((product.dblCantidad > product.intDisponibleNacional) && (product.strEstatus == 'DESCONTINUADO'))" class="btn  btFlechas shadow-sm py-1 px-2 fs12">+</button> 
                                </div>
                            </span>
                        </div>
                        <div class="col-2 text-center fw-bold d-flex align-items-center justify-content-center">
                            <span class="fw-bold fs-5">
                                {{ product.dblPrecioLista * product.dblCantidad| currency }}
                            </span>
                        </div>
                        <div class="col-1 text-center d-flex align-items-center justify-content-center">
                            <span class="pointer btn btn-outline-danger p-1">
                                <i class="bi bi-trash-fill fs-5 clsIconDelete " (click)="removeItem(product)"></i>
                            </span>
                        </div>
                     </div>
                    <!-- <img [src]="'https://www.cleanclean.mx/imagenes/'+product.strArticulo +'.JPG'" alt="{{ product.name }}">
                    <div class="product-details">
                        <h3>{{ product.strDescripcionCorta }}</h3>
                        <p>SKU: {{ product.strArticulo }}</p>
                        <p>Color: {{ product.strOpcion }} {{product.strNombreOpcion}}</p> 
            
                          
                        <label for="quantity">Cantidad:</label>
                        <div class="quantity-control input-group">
                            <button (click)="decreaseQuantity(product)" class="btn btn-primary">-</button>
                            <input [(ngModel)]="product.dblCantidad" type="number" class="form-control text-center" id="quantity" min="1" />
                            <button (click)="increaseQuantity(product)" class="btn btn-primary">+</button>
                        </div>
                      
                        <div class="price-container">
                            <span class="old-price" *ngIf="product.oldPrice">{{ product.oldPrice | currency }}</span>
                            <span class="new-price">{{ product.price | currency }}</span>
                        </div>
            
                        
                        <div class="product-options">
                            <a href="#" (click)="editItem(product)">Editar</a>
                            <a href="#" (click)="removeItem(product)">Eliminar</a>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row d-lg-none justify-content-center" *ngFor="let product of cartItems">
                <div class="col-11 rounded shadow-sm bg-white">
                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <img class="w-100"  (click)="addToCart(product)" [src]="product.strUrlImg1" alt="{{ product.name }}">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <span class="">
                                    <span class="old-price" *ngIf="product.oldPrice">{{ product.oldPrice | currency }}</span>
                                    <span class="">{{ product.dblPrecioLista | currency }}</span> 
                                </span>
                            </div>
                        </div>
                        <div class="col-6 d-flex flex-column">
        
                            <div class="col-12 d-flex flex-column mt-auto">
                                <span class=" fs12 mt-auto" [ngClass]="{'mb-auto':product.strTipoOpcion!='Si'}">
                                    <span class="fw-bold">({{product.strArticulo}})</span>
                                    {{product.strDescripcion1}}
                                </span>
                                <span class="mt-2 fw-bold mb-auto text-secondary" *ngIf="product.strTipoOpcion=='SI' || product.strTipoOpcion=='Si' || product.strTipoOpcion=='si'">
                                    Opcion: <span class="text-dark"> {{product.strNombreOpcion}} <span class="text-normal">({{product.strOpcion}})</span></span> 
                                </span>
                            </div>
                           
                            <div class="col-12 my-auto text-center d-flex align-items-center justify-content-center my-2">
                                <span class="">
                                    <div class="input-group"> 
                                        <button [disabled]="product.dblCantidad == 1 || product.dblCantidad < 1" (click)="decreaseQuantity(product)" class="btn  btFlechas shadow-sm py-1 px-2 fs12" >-</button>
                                        <input [min]="1" (input)="fnCapInput($event,product)" [(ngModel)]="product.dblCantidad" type="number" class="form-control fs12 py-0 text-center sin-flechas" id="quantity" min="1" />
                                        <button (click)="increaseQuantity(product)" class="btn  btFlechas shadow-sm py-1 px-2 fs12">+</button>
                                    </div>
                                </span>
                            </div>
                            <div class="col-12 mb-auto text-center fw-bold d-flex align-items-center justify-content-center">
                                <span class="fw-bold fs-6">
                                 Total: {{ product.dblPrecioLista * product.dblCantidad| currency }}
                                </span>
                            </div>
                            
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center">
                            
                                <span class="pointer btn btn-outline-danger p-1">
                                    <i class="bi bi-trash-fill fs-5 clsIconDelete " (click)="removeItem(product)"></i>
                                </span>
                         
                        </div>
                    </div>
                </div>
               
            </div>
            <div class="col-12 d-flex justify-content-between my-3">
                <!-- <button class="btn btn-primary"><i class="fa-solid fa-arrow-left"></i></button>
                --> <button class="btn btn-danger" (click)="fnLimpiarCarrito()"><i class="fa-solid fa-trash"></i> <span class="fs14 ms-2">Limpiar carrito</span></button>
            </div>
        </div>


        <div class="col-12 col-lg-4 ">
            <!-- Resumen del pedido -->
            <div class="order-summary bg-white shadow-sm rounded">
            <h3>Resumen del pedido</h3>
            
           
            <p class="d-flex">Subtotal  ({{ cartItems.length }}):<span class="text-end ms-auto fw-bold"> {{ dblSubTotal | currency }}</span></p>
     
            <p class="d-flex" *ngIf="objCupon_descuento.strCodigoPromocional">Descuento  ({{objCupon_descuento.strNombre}}) :
                <span class="text-end ms-auto fw-bold"> {{ dblDescuento | currency }}</span></p>
            <!--<p class="d-flex">IVA(16%): <span class="ms-auto fw-bold">{{intIva|currency}}</span></p>-->
            <p class="d-flex">Envío Estimado: <span class="ms-auto fw-bold">{{ dblEnvio | currency }}</span></p>
            <!--<p class="d-flex">Descuento Por Envío:<span class="ms-auto fw-bold text-decoration-line-through">{{ discount | currency }}</span> </p>-->
            <h3 class="d-flex">Total Estimado: <span class="ms-auto fw-bold">{{ dblTotal | currency }}</span></h3>
                   <!--Codigo aplicado-->
                   <div class="discount-label" *ngIf="objCupon_descuento.strCodigoPromocional">
                    <div class="discount-content">
                        <span class="discount-code">{{objCupon_descuento.strCodigoPromocional}}</span>
                       <!-- <span class="discount-text">{{objCupon_descuento.strNombre}}</span>-->
                    </div>
                    <button class="cancel-button" (click)="fnCancelarCodigo()">CANCELAR CUPÓN</button>
                </div>
                
            
            <!-- Aplicar código promocional -->
             
            <div class="discount-section">
                <input type="text"  [(ngModel)]="strCodigoPromocional" placeholder="Código de descuento" />
                <button (click)="applyPromoCode()" class="btn btn-light" [disabled]="objCupon_descuento.strCodigoPromocional">Aplicar</button>
              </div>
            
            <!-- Botón de pagar -->
            <button (click)="proceedToPayment()" class="pay-button " [disabled]="cartItems.length ==0 ">Pagar</button>
            </div>
        </div>
    </div>
  </div>
  