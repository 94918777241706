<div class="container-fluid">
  <div class="row">
    <div class="col-12 px-0">
        <nav aria-label="breadcrumb" class="px-0 ">
            <ol class="breadcrumb d-flex align-items-center mb-0">
                <li class="breadcrumb-item"><a routerLink="/cotizador_Linea"  >Categorias</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{strLinea}} </li>
            </ol>
        </nav>
    </div>
</div>
  <div class="row">
    <div class="col-12">
      <div class="sublineas-container">
        <h2>{{ strLinea }}</h2>
        <div class="sublineas-cards-container">
          <div *ngFor="let sublinea of sublineas"  id="{{sublinea.strGrupo}}" #sublinea.strGrupo class="sublinea-card" (click)="onCardClick(sublinea.strGrupo )">
            <img [src]="sublinea.imageUrl"  alt="{{sublinea.strGrupo}}">
            <h3>{{ sublinea.strGrupo }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>