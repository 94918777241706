import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';


//import {PeticionesService} from '../servicios/httpservice';

@Injectable()
export class CarroService {
    arrayArticulos: any[] = [];
    arrayCotizador: any[] = [];
 
  	dblTotal:number = 0;	//total en cantidad
    dblTotalCotizador:number=0;
    arrayTotalesPrecio: any[] = []; //total en monto del carrito
    arrayTotalesPrecioCotizador: any[] = []; //total en monto del cotizador
  	dblIva:number = 0;
    objDescuentos:any;
  	private aux:any;
  	private articulos$ = new Subject<any>(); //sirve  para generar el stream de eventos
    private articulosCotizador$ = new Subject<any>(); //total de articulos en el cotizador
  	private arrayArticulos$ = new Subject<any>();
    private arrayArticulosCotizador$ = new Subject<any>();
    private totalesPrecio$ = new Subject<any>();
    private totalesPrecioCotizador$ = new Subject<any>();//totales para el cotizador
    private descuentos$ = new Subject<any>();
    private arrayCotizador$ = new Subject<any>(); //observable de cotizaciones

    /****variables para manejar el total del carrito ******/
    private intTotalItemsSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public intTotalItems$: Observable<number> = this.intTotalItemsSubject.asObservable();
    private intTotal: number = 0;  // Inicializamos en 0
    
    /****variables para manejar el total del carrito partner ******/ 
    private intTotalItems_PartnerSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public intTotalItems_Partner$: Observable<number> = this.intTotalItems_PartnerSubject.asObservable();
    private intTotal_partner: number = 0;  // Inicializamos en 0
   
    /****variables para manejar el cotizador_clientes total del carrito partner ******/ 
    private intTotalItems_Partner_Cot_Subject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public intTotalItems_Partner_Cot$: Observable<number> = this.intTotalItems_Partner_Cot_Subject.asObservable();
    private intTotal_partner_Cot: number = 0;  // Inicializamos en 0
       
    /****variables para manejar articulos en carrito carrito ******/
    private arrayItems:any=[];
    private arrayItemsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.arrayItems);
    public arrayItems$: Observable<any> = this.arrayItemsSubject.asObservable();
     

    /****variables para manejar articulos en carrito partner ******/
     private arrayItems_Partner:any=this.localSt.retrieve('arrayItems_Partner') ?
     this.localSt.retrieve('arrayItems_Partner') :[];
     private arrayItems_PartnerSubject  = new BehaviorSubject<any>(this.arrayItems_Partner);
     public arrayItems_Partner$: Observable<any> = this.arrayItems_PartnerSubject.asObservable();
     
    /****variables para manejar articulos en carrito_cotizador partner ******/
    private arrayItems_Partner_cot:any=this.localSt.retrieve('arrayItems_Partner_cot') ?
    this.localSt.retrieve('arrayItems_Partner_cot') :[];
    private arrayItems_PartnerSubject_cot  = new BehaviorSubject<any>(this.arrayItems_Partner_cot);
    public arrayItems_Partner_cot$: Observable<any> = this.arrayItems_PartnerSubject_cot.asObservable();

  /**************variables para obtener subtotal partner********** */
    private dblSubTotalItems_ParnerSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public dblSubTotalItems_Partner$: Observable<number> = this.dblSubTotalItems_ParnerSubject.asObservable();
    private dblSubTotal_partner: number = 0;  // Inicializamos en 0

     /**************variables para obtener subtotal partner_cotizador********** */
     private dblSubTotalItems_Parner_cot_Subject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
     public dblSubTotalItems_Partner_Cot$: Observable<number> = this.dblSubTotalItems_Parner_cot_Subject.asObservable();
     private dblSubTotal_partner_Cot: number = 0;  // Inicializamos en 0
     /****************variables para descuento partner ******************/
     private objDescuento_ParnerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(0);
    public objDescuento_Partner$: Observable<any> = this.objDescuento_ParnerSubject.asObservable();
    private objDescuento_partner: any = this.localSt.retrieve('objDescuento_partner') ?
    this.localSt.retrieve('objDescuento_partner') :{}; 

     /**************variables para obtener subtotal********** */
     private dblSubTotalItemsSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
     public dblSubTotalItems$: Observable<number> = this.dblSubTotalItemsSubject.asObservable();
     private dblSubTotal: number = 0;  // Inicializamos en 0
     
     /**********************VARIABLES para guardar datos del cliente******************************************/
     private ObjClienteSubject: BehaviorSubject<any> = new BehaviorSubject<any>(0);
     public ObjCliente$: Observable<any> = this.ObjClienteSubject.asObservable();
     private ObjCliente: any = {};  // Inicializamos en 0

    /**********************VARIABLES para guardar datos de la familia ******************************************/
    private ObjFamiliaSubject: BehaviorSubject<any> = new BehaviorSubject<any>(0);
    public ObjFamilia$: Observable<any> = this.ObjFamiliaSubject.asObservable();
    private ObjFamilia: any = {};  // Inicializamos en 0
    /**********************VARIABLES para guardar datos del modo plantlla******************************************/
     private boolModoPlantillaSubject: BehaviorSubject<any> = new BehaviorSubject<any>(0);
     public boolModoPlantilla$: Observable<any> = this.boolModoPlantillaSubject.asObservable();
     private boolModoPlantilla: any = {};  // Inicializamos en 0
    /***************************variables para el envio de la mercancia ************/
    private arrayArts_envio_especial:any=[{articulo:'1887156'},{articulo:'1889849'},{articulo:'2035896'},{articulo:'2119054'},{articulo:'2119055'},{articulo:'1862706'},{articulo:'1995297'}]
      
    private monto_envio:number= 99;
    private minimo_compra:number= 1000;
    private monto_envio_especial:number=1000; 
    private envio_total:number= 99;    
    private envioSubject = new BehaviorSubject<number>(this.envio_total); // Observable para cambios en el carrito
          
     constructor(private localSt:LocalStorageService,private _httpService: PeticionesService) {//private _peticionesService:PeticionesService
        this.dblTotal =0;
        let clienteSesision= this.localSt.retrieve('ObjCliente') ?this.localSt.retrieve('ObjCliente') :{};        
        this.setCte(clienteSesision);

        let familiaSesision= this.localSt.retrieve('ObjFamilia') ?this.localSt.retrieve('ObjFamilia') :{};        
        this.setFamilia(familiaSesision);

        let modoPlantilla = this.localSt.retrieve('boolModoPlantilla') ?this.localSt.retrieve('boolModoPlantilla') :false;  
        this.setModoPlantilla(modoPlantilla);

        this.arrayItems=this.localSt.retrieve('arrayItems') ?this.localSt.retrieve('arrayItems') :[];
         this.getTotalArts();
         this.getSubtotal();
         this.getArticulos();

         //obtenemos totales del partner
         this.getTotalArts_Partner()

         //obtenemos totales del partner_cotizador
         this.getTotalArts_Partner_cotizador();
         this.getSubtotal_Partner_cot();
    }

    ngOnInit(){
    }    
     setModoPlantilla(boolModoPlantilla:boolean){
      this.boolModoPlantilla=boolModoPlantilla;
      
      this.localSt.store('boolModoPlantilla',this.boolModoPlantilla);
      this.boolModoPlantillaSubject.next(this.boolModoPlantilla);

      //actualizazamos totales
      
      this.getSubtotal();
    
     }
     setCte(objCte:any){ 
      this.ObjCliente=objCte;      
      this.localSt.store('ObjCliente',this.ObjCliente);
      this.ObjClienteSubject.next(this.ObjCliente);
     }
     setFamilia(ObjFamilia:any){
      this.ObjFamilia=ObjFamilia;      
      this.localSt.store('ObjFamilia',this.ObjFamilia);
      this.ObjFamiliaSubject.next(this.ObjFamilia);
     }
    setArticulo(objArticulo:any) {
      
    //  objArticulo.dblPrecioSugerido= this.boolModoPlantilla? objArticulo.dblPrecioLista:0;

      this.arrayItems=this.localSt.retrieve('arrayItems') ?this.localSt.retrieve('arrayItems') :[];
       //revisar si el articulo existe en el array
    
      let flag1= this.arrayItems.find(o=>o.strArticulo==objArticulo.strArticulo&&o.strOpcion==objArticulo.strOpcion);
     

      if(!flag1){
        this.arrayItems.push(objArticulo);
      }
      else {
        flag1.dblCantidad=  flag1.dblCantidad+ objArticulo.dblCantidad ;
      }
   
     this.getTotalArts();
     this.getSubtotal();
     this.getArticulos(); 
    }

    //--------------------partner---------------------------
    setArticulo_partner(objArticulo:any) { 
      //  objArticulo.dblPrecioSugerido= this.boolModoPlantilla? objArticulo.dblPrecioLista:0;
      objArticulo.strUrlImg='https://www.cleanclean.mx/imagenes/'+objArticulo.strArticulo+'.JPG'
        this.arrayItems_Partner=this.localSt.retrieve('arrayItems_Partner') ?this.localSt.retrieve('arrayItems_Partner') :[];
         //revisar si el articulo existe en el array
      
        let flag1= this.arrayItems_Partner.find(o=>o.strArticulo==objArticulo.strArticulo&&o.strOpcion==objArticulo.strOpcion);
       
  
        if(!flag1){
          this.arrayItems_Partner.push(objArticulo);
        }
        else {
          flag1.dblCantidad=  flag1.dblCantidad+ objArticulo.dblCantidad ;
        }

       // this.applyShippment();
       //REVISAMOS LOS DESCUENTOS
       this.fnGetDescuentos_partner();
     /*
       this.getTotalArts_Partner();
       this.getSubtotal_Partner();
       this.getArticulos_Partner(); */
      }
      setArticulo_partner_cotizacion(objArticulo:any) { 
        //  objArticulo.dblPrecioSugerido= this.boolModoPlantilla? objArticulo.dblPrecioLista:0;
        objArticulo.strUrlImg='https://www.cleanclean.mx/imagenes/'+objArticulo.strArticulo+'.JPG'
          this.arrayItems_Partner_cot=this.localSt.retrieve('arrayItems_Partner_cot') ?this.localSt.retrieve('arrayItems_Partner_cot') :[];
           //revisar si el articulo existe en el array
        
          let flag1= this.arrayItems_Partner_cot.find(o=>o.strArticulo==objArticulo.strArticulo&&o.strOpcion==objArticulo.strOpcion);
         
    
          if(!flag1){
            this.arrayItems_Partner_cot.push(objArticulo);
          }
          else {
            flag1.dblCantidad=  flag1.dblCantidad+ objArticulo.dblCantidad ;
          }
  
         // this.applyShippment();
         //REVISAMOS LOS DESCUENTOS
        // this.fnGetDescuentos_partner();
     
         this.getTotalArts_Partner_cot();
         this.getSubtotal_Partner_cot();
         this.getArticulos_Partner_cot();  
        }
      applyShippment(){
     
        this.getTotalArts_Partner();
        this.getSubtotal_Partner();
        this.getArticulos_Partner(); 
      }
    deleteCarrito(){
      this.arrayItems=[];
      this.dblSubTotal=0;
      this.ObjCliente={};
      this.setCte(this.ObjCliente);
      this.getTotalArts();
      this.getSubtotal();
      this.getArticulos();
    }
    deleteCarrito2(){
      this.arrayItems=[];
      this.dblSubTotal=0;
   
      this.getTotalArts();
      this.getSubtotal();
      this.getArticulos();
    }
    deleteCarrito_partner(){
      this.arrayItems_Partner=[];
      this.dblSubTotal_partner=0;
   
      this.getTotalArts_Partner();
      this.getSubtotal_Partner();
      this.getArticulos_Partner();
    }
  
    deleteArt(objElimnar:any){ 
      this.arrayItems=this.localSt.retrieve('arrayItems') ?this.localSt.retrieve('arrayItems') :[];
      const index = this.arrayItems.indexOf(objElimnar);
      if (index !== -1) {
        this.arrayItems.splice(index, 1);
      }

      this.getTotalArts();
      this.getSubtotal();
      this.getArticulos();
    }
    updateArt(objElimnar:any){
      this.arrayItems=this.localSt.retrieve('arrayItems') ?this.localSt.retrieve('arrayItems') :[];
      this.getTotalArts();
      this.getSubtotal();
      this.getArticulos();
    }

    getArticulos(){
      
      this.localSt.store('arrayItems',this.arrayItems);
      this.arrayItemsSubject.next(this.arrayItems);
    }
    //--------partner------------
    getArticulos_Partner(){
      
      this.localSt.store('arrayItems_Partner',this.arrayItems_Partner);
      this.arrayItems_PartnerSubject.next(this.arrayItems_Partner);
      this.envioSubject.next(this.envio_total);
    }
    getArticulos_Partner_cot(){
      
      this.localSt.store('arrayItems_Partner_cot',this.arrayItems_Partner_cot);
      this.arrayItems_PartnerSubject_cot.next(this.arrayItems_Partner_cot);
     // this.envioSubject.next(this.envio_total);
    }
      
    deleteArt_Partner_cot(objElimnar:any){ 
      this.arrayItems_Partner_cot=this.localSt.retrieve('arrayItems_Partner_cot') ?this.localSt.retrieve('arrayItems_Partner_cot') :[];
      const index = this.arrayItems_Partner_cot.indexOf(objElimnar);
      if (index !== -1) {
        this.arrayItems_Partner_cot.splice(index, 1);
      }

      this.getArticulos_Partner_cot()
      this.getTotalArts_Partner_cot();
      this.getSubtotal_Partner_cot();
    }
    deleteCarrito_partner_cot(){
      this.arrayItems_Partner_cot=[];
      this.dblSubTotal_partner_Cot=0;
   
      this.getArticulos_Partner_cot()
      this.getTotalArts_Partner_cot();
      this.getSubtotal_Partner_cot();
    }
    updateArt_partner_cot(objUpdate:any){
      
      this.arrayItems_Partner_cot=this.localSt.retrieve('arrayItems_Partner_cot') ?this.localSt.retrieve('arrayItems_Partner_cot') :[];

      let objResult = this.arrayItems_Partner_cot.find(o=>o.strArticulo == objUpdate.strArticulo  && o.strOpcion == objUpdate.strOpcion)
      objResult.dblPrecioSugerido = objUpdate.dblPrecioSugerido;
      
      //actualizamos la cantidad
      objResult.dblCantidad = objUpdate.dblCantidad; 
      this.localSt.store('arrayItems_Partner_cot',this.arrayItems_Partner_cot);
      this.getArticulos_Partner_cot()
      this.getTotalArts_Partner_cot();
      this.getSubtotal_Partner_cot();
   
    }
    getTotalArts(){
       //calcular total de items
       this.intTotal  =0;
       this.arrayItems.forEach(element => {
         this.intTotal  =this.intTotal + parseFloat(element.dblCantidad )
       });
 
       this.intTotalItemsSubject.next(this.intTotal);
    }
    getSubtotal(){
       //calcular total de items
       this.dblSubTotal  =0;
       this.arrayItems.forEach(element => {
         this.dblSubTotal  =this.dblSubTotal + ( element.dblCantidad * parseFloat(element.dblPrecioLista));
       });
       
       this.dblSubTotalItemsSubject.next(this.dblSubTotal);
    }
   
    // -----------partner---------------------------------------
    fnGetDescuentos_partner(){
      this.fnAplicarDescuentos_partner()
    }
    fnDescuento_partner(objDescuento:any,opcion_promocion:number){
      this.objDescuento_partner=objDescuento;
      //GUARDAMOS EN SESIÓN LA INFORMACION
     // this.localSt.store('objDescuento_partner',this.objDescuento_partner); 
      let objC =this.objDescuento_partner;

      console.log(objC)
      localStorage.setItem('objDescuento_partner', JSON.stringify(objC));
      localStorage.setItem('opcion_promocion', JSON.stringify(opcion_promocion));
      this.fnAplicarDescuentos_partner( ); 
     }

     fnAplicarDescuentos_partner(){ 
      let opcion_promocion= localStorage.getItem('opcion_promocion')?parseInt(localStorage.getItem('opcion_promocion')):1  ;//el uno representa la promocion descuento, la seleccionamos por default cuando inician sesion
      let objC =localStorage.getItem('objDescuento_partner');
      let descuentoAplicable = objC  ? JSON.parse(objC) : {} ;
      
      console.log(descuentoAplicable)
    
      this.arrayItems_Partner.forEach(item => {
        let   descuento = opcion_promocion == 1 ?item.dblPrecioLista * descuentoAplicable.dblDescuentoCantidad :0;
        item.dblPrecioConDescuento = Math.max(item.dblPrecioLista - descuento, 0);
        item.strNombreDescuento = opcion_promocion == 1 ?descuentoAplicable.strNombre :'Monedero Ecodeli';
        item.pDescuento =descuentoAplicable.dblDescuentoCantidad;
        item.pMonedero = descuentoAplicable.dblValor_Cupon;
       });

      this.getArticulos_Partner(); //actualizamos el arraycarrito
      this.getTotalArts_Partner();
      this.getSubtotal_Partner();
     }
 
    updateQuantity(item_id: any, opcion:string ){
      const existingItem = this.arrayItems_Partner.find(i => i.strArticulo === item_id.strArticulo && i.strOpcion === item_id.strOpcion );
      if (existingItem) { 
        if(opcion =='aumenta'){
          existingItem.dblCantidad = item_id.dblCantidad + 1;
        }
        else {
          existingItem.dblCantidad = item_id.dblCantidad - 1;
        }     
    
        
      }
     /* this.getArticulos_Partner(); //actualizamos el arraycarrito
      this.getTotalArts_Partner();
      this.getSubtotal_Partner();
      */

      this.fnGetDescuentos_partner()
     // this.applyShippment(); 
    }
    removeFromCart(item_id: any) { 
      let pre = [];
      
      if(item_id.strOpcion !=''){
          pre =this.arrayItems_Partner.filter(art => art.strArticulo != item_id.strArticulo  && art.strOpcion != item_id.strOpcion );
      }
      else {
        pre =this.arrayItems_Partner.filter(art => art.strArticulo != item_id.strArticulo  );
      }
       
      this.arrayItems_Partner = pre 
      /*this.getTotalArts_Partner();
      this.getSubtotal_Partner();
      this.getArticulos_Partner(); */

      this.fnGetDescuentos_partner();
 
    }


    getTotalArts_Partner(){
      //calcular total de items
      this.intTotal_partner  =0;
      this.arrayItems_Partner.forEach(element => {
        this.intTotal_partner  =this.intTotal_partner + parseFloat(element.dblCantidad )
      });
 
      this.intTotalItems_PartnerSubject.next(this.intTotal_partner);

       //LANZAMOS EL SERVICIO PARA QUE MUESTRE EL MENSAJE
       //this.objDescuento_ParnerSubject.next(this.objDescuento_partner);
   }

   getTotalArts_Partner_cot(){
    //calcular total de items
    this.intTotal_partner_Cot  =0;
    this.arrayItems_Partner_cot.forEach(element => {
      this.intTotal_partner_Cot  =this.intTotal_partner_Cot + parseFloat(element.dblCantidad )
    });
    this.intTotalItems_Partner_Cot_Subject.next(this.intTotal_partner_Cot);
    

     //LANZAMOS EL SERVICIO PARA QUE MUESTRE EL MENSAJE
    // this.objDescuento_ParnerSubject.next(this.objDescuento_partner);
 }
   removeFromCart_cotizador(item_id: any) { 
    let pre = [];
    
    if(item_id.strOpcion !=''){
        pre =this.arrayItems_Partner_cot.filter(art => art.strArticulo != item_id.strArticulo  && art.strOpcion != item_id.strOpcion );
    }
    else {
      pre =this.arrayItems_Partner_cot.filter(art => art.strArticulo != item_id.strArticulo  );
    }
     
    this.arrayItems_Partner_cot = pre 
   this.getTotalArts_Partner_cot();
    this.getSubtotal_Partner();
    this.getArticulos_Partner();  

    //this.fnGetDescuentos_partner();

  }


   getTotalArts_Partner_cotizador(){
    //calcular total de items
    this.intTotal_partner_Cot  =0;
    this.arrayItems_Partner_cot.forEach(element => {
      this.intTotal_partner_Cot  =this.intTotal_partner_Cot + parseFloat(element.dblCantidad )
    });

    this.intTotalItems_Partner_Cot_Subject.next(this.intTotal_partner_Cot);

     //LANZAMOS EL SERVICIO PARA QUE MUESTRE EL MENSAJE
    // this.objDescuento_ParnerSubject.next(this.objDescuento_partner);
 }


   

   getSubtotal_Partner(){
      //calcular total de items
      this.dblSubTotal  =0;
      this.arrayItems_Partner.forEach(element => {
        this.dblSubTotal  =this.dblSubTotal + ( element.dblCantidad * parseFloat(element.dblPrecioLista));
      });
      
      this.dblSubTotalItems_ParnerSubject.next(this.dblSubTotal);
   }
   getSubtotal_Partner_cot(){
    //calcular total de items
    this.dblSubTotal  =0;
    this.arrayItems_Partner_cot.forEach(element => {
      let precio = (element.dblPrecioSugerido &&  parseFloat(element.dblPrecioSugerido) > 0 ) ?parseFloat(element.dblPrecioSugerido) :  parseFloat(element.dblPrecioLista);
      this.dblSubTotal  =this.dblSubTotal + ( element.dblCantidad * precio);
    });
    
    this.dblSubTotalItems_Parner_cot_Subject.next(this.dblSubTotal);
 }




  
   /*
   fnAplicarDescuentos_partner(tabla_descuento:any){ 
       
       let totalCompra = 0;
       this.arrayItems_Partner.forEach(element => {
        totalCompra= totalCompra + (element.dblCantidad * element.dblPrecioLista);
       });

       tabla_descuento.sort((a, b) => b.dblMontoMin_Cupon - a.dblMontoMin_Cupon);

       // Buscamos el mayor descuento aplicable
       const descuentoAplicable = tabla_descuento.find((descuento) => totalCompra >= descuento.dblMontoMin_Cupon); 
       if (descuentoAplicable) {
         console.log(`✅ Aplicando ${descuentoAplicable.dblDescuentoCantidad * 100}% de descuento`);
         
         //obtenemos el siguiente descuento
         this.objDescuento_partner =tabla_descuento.find((descuento) =>  descuento.intID_Cupon  ==descuentoAplicable.intID_Cupon + 1 );  
        //validar si hay otro nivel para descuento
         if(!this.objDescuento_partner) {
          this.objDescuento_partner =tabla_descuento.find((descuento) =>  descuento.intID_Cupon  ==descuentoAplicable.intID_Cupon  );  
         }

         this.arrayItems_Partner.forEach(item => {
          let   descuento = item.dblPrecioLista * descuentoAplicable.dblDescuentoCantidad;
          item.dblPrecioConDescuento = Math.max(item.dblPrecioLista - descuento, 0);
          item.strNombreDescuento = descuentoAplicable.strTipo;
         });

         console.log(this.arrayItems_Partner)
        
       }
       else {
        console.log("❌ No aplica ningún descuento");
        this.arrayItems_Partner.forEach(item => {
          let   descuento = 0;
          item.dblPrecioConDescuento = item.dblPrecioLista;
          item.strNombreDescuento = '';
         });
         this.objDescuento_partner =tabla_descuento.find((descuento) =>  descuento.intID_Cupon  == 1 );  
         }

         //CALCULAMOS EL FALTANTE PARA ALCANZAR MAS DESCUENTO
         let falta_compra =this.objDescuento_partner.dblMontoMin_Cupon -  totalCompra;
         let falta_compra_str = falta_compra.toLocaleString('es-MX'); 

         let siguiente_descuento = (this.objDescuento_partner.dblDescuentoCantidad * 100).toLocaleString('es-MX');
         this.objDescuento_partner.falta_siguiente = falta_compra;
         this.objDescuento_partner.mensaje_mostrar = falta_compra  <= 0 ? '' :'Añade ' + falta_compra_str + ' más a tu carrito y obtén  ' + siguiente_descuento + '% de descuento'
         console.log(this.objDescuento_partner);
        //GUARDAMOS EN SESIÓN LA INFORMACION
        this.localSt.store('objDescuento_partner',this.objDescuento_partner);
       
  
       this.getArticulos_Partner(); //actualizamos el arraycarrito
       this.getTotalArts_Partner();
       this.getSubtotal_Partner();
       
       

   }*/
  //--------fin -------------partner
  //--------------------------------------------------------
    setArtToCotizacion(objArticulo:any){ //añadir articulos al array de cotizador
        let strArticulo = '';
        let intCantidad = 0;
        let dblMonto = 0; 
        objArticulo.dblPrecioVenta=objArticulo.dblPrecio;
        //revisar que el articulo no se encuentre en el arrayArticulos
        if (objArticulo.strOpcion) {
           this.aux= this.arrayCotizador.find(o=>o.strArticulo==objArticulo.strArticulo&&o.strOpcion==objArticulo.strOpcion);
        }
         else{
           this.aux= this.arrayCotizador.find(o=>o.strArticulo==objArticulo.strArticulo);
         }
         //si no se encuentra se hace push
         if (!this.aux) {
          //revisar si se excede a la cantidad disponible en su sucursal, para hacer cobro de flete
          objArticulo.intExcede = objArticulo.dblCantidad > objArticulo.intExistenciasSucursal?1:0;
          this.arrayCotizador.push(objArticulo);
          strArticulo= objArticulo.strArticulo; 
          intCantidad=objArticulo.dblCantidad;
          dblMonto= objArticulo.dblCantidad * objArticulo.dblPrecio;
         }
         else{ //si se encuentra, se suman cantidades
           this.aux.dblCantidad=  this.aux.dblCantidad+objArticulo.dblCantidad;
           this.aux.intExcede = this.aux.dblCantidad > objArticulo.intExistenciasSucursal?1:0;
           strArticulo= this.aux.strArticulo; 
           intCantidad=this.aux.dblCantidad;
           dblMonto= this.aux.dblCantidad * this.aux.dblPrecio; 
         }
   


        //this.fnGetDescuento(strArticulo,intCantidad,dblMonto,2); 
        //this.localSt.store('arrayCotizador',this.arrayCotizador);
        //this.fnTotales();
    }
 
  


    getTotal(){
        return this.dblTotal;
    }
    
    getTotalCot(){
        return this.dblTotalCotizador;
    }
  
    sendParam(){
      alert("FuncionaCorrecto");
    }
    getCartItems() {
      return this.arrayItems_PartnerSubject.asObservable(); // Devuelve el observable
    }
    getEnvio() {
      return this.envioSubject.asObservable(); // Devuelve el observable
    }
    getDescuento_partner(){
      return this.objDescuento_ParnerSubject.asObservable();
    }
}