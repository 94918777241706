<div class="container">
    <div class="row my-3">
        <div class="col-12">
            <h1 class="text-center mb-0 fw-bold text-decoration-underline">Cotizador</h1>
        </div>
    </div>
    <div class="row">
      <div class="col-12 bg-white borderbott bordertop shadow-sm px-0">
        <div class="col-12 text-center bgHeadCards bordertop fs-6 py-2 px-0">
          Información del Cliente
        </div>
        <div class="col-12 px-0">
          <div class="row px-3 py-3">
            <div class="col-12 p-2 col-sm-6 col-md-4 p-2">
              <mat-form-field class="col-12 " appearance="outline">
                <mat-label>Cliente</mat-label>
                  <input matInput placeholder="Placeholder" id="form1" [(ngModel)]="strNumCte" placeholder="Ingrese número de cliente" (keydown)="onKeyDown($event)" (keyup)="fnBuscarCte(strNumCte);" aria-label="Recipient's username" aria-describedby="button-addon2" autocomplete="off" matInput [matAutocomplete]="auto">
                  <span matSuffix class="material-symbols-outlined fs-3 clicon" [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                    check_circle
                  </span>
              </mat-form-field>
              <mat-autocomplete #auto="matAutocomplete" class="w-100">
                <mat-option class="fs12 hoption" *ngFor="let option of arrayClientes; let i = index;" [value]="option.strCliente" (click)="fnllenarInputs(i);">
                  {{option.strNombreMostrar}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="col-12 py-md-2 col-sm-6 mx-auto col-md-4">
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>Nombre</mat-label>
                  <input matInput placeholder="Ejemplo: Ecodeli Restauradores" class="border-0" [ngModel]="objCliente.strNombre" readonly>
                  <span matSuffix class="material-symbols-outlined fs-3 clicon" [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                            check_circle
                          </span>
                        </mat-form-field>
                      </div>
                      <div class="col-12 py-2 py-sm-0 py-md-2 col-sm-6 col-md-4 ">
                        <mat-form-field class="col-12" appearance="outline">
                          <mat-label>Direccion</mat-label>
                          <input class="border-0" matInput placeholder="Ejemplo: Ecodeli, Leon, Gto."
                            [ngModel]="objCliente.strDireccion" readonly>
                          <span matSuffix class="material-symbols-outlined fs-3 clicon"
                            [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                            check_circle
                          </span>
                        </mat-form-field>
                      </div>
                      <div class="row px-0 mx-auto mt-3" *ngIf="objCliente.strTipo == 'SUCURSAL'">
                        <div class="col-12 py-2 py-sm-0 py-md-2 col-sm-6 col-md-4">
                          <mat-form-field class="col-12" appearance="outline">
                            <mat-label>Sucursal</mat-label>
                            <input class="border-0" matInput placeholder="Placeholder" [ngModel]="objCliente.intIDSucursal"
                              readonly>
                            <span matSuffix class="material-symbols-outlined fs-3 clicon"
                              [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                              check_circle
                            </span>
                          </mat-form-field>
                        </div>
                        <div class="col-12 py-2 py-sm-0 py-md-2 col-sm-6 col-md-4 ">
                          <mat-form-field class="col-12" appearance="outline">
                            <mat-label>Sucursal</mat-label>
                            <input class="border-0" matInput placeholder="Placeholder" [ngModel]="objCliente.strNombreSucursal"
                              readonly>
                            <span matSuffix class="material-symbols-outlined fs-3 clicon"
                              [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                              check_circle
                            </span>
                          </mat-form-field>
                        </div>
                        <div class="col-12 py-2 py-sm-0 py-md-2 col-sm-6 col-md-3"></div>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12 bordertop bgHeadCards shadow-sm ">
            <h3 class="mb-0 py-2 text-center">Categorias</h3>
        </div>
        <div class="col-12 py-3 bglineas roundedcrdbottom bg-white shadow-sm">
          <div id="carouselExampleControls roundedcrdbottom" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner roundedcrdbottom ">
                <div class="carousel-item " [ngClass]="{'active':i==0}" *ngFor="let bloq of bloques; let i = index;">
                  <div class="row ">
                      <div class="col text-center position-relative px-0 mx-2 my-2" [ngClass]="{'imgselect':x==objLinea}" *ngFor="let x of bloq" (click)="fnGetGrupos(x)" >
                          <img class="w-100  shadow-sm"  [ngClass]="{'imgitemcar':x!=objLinea}" [src]="'https://www.ecodeli.com/img/lineas/'+x.strCategoria+'.jpg'" alt="">
                          <div class="w-100 position-absolute bottom-0 start-50 translate-middle-x roundedcrdbottom shadow-sm" style="background: #FCE5E0;">
                              <span class="text-secondary fw-bold fs4">{{x.strCategoria}}</span>
                          </div>
                      </div>
                      <div class="col" *ngIf="bloq.length < 6 && elementosPorPagina == 6" >
                      </div>
                      <div class="col" *ngIf="bloq.length < 5 && elementosPorPagina == 6" >
                      </div>
                      <div class="col" *ngIf="bloq.length < 4  && elementosPorPagina == 6" >
                      </div>
                      <div class="col" *ngIf="bloq.length < 3  && (elementosPorPagina == 6 || elementosPorPagina == 3)">
                      </div>
                      <div class="col" *ngIf="bloq.length < 2  && (elementosPorPagina == 6 || elementosPorPagina == 3)">
                      </div>
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
        </div>
      
    </div>









    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="..." class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="..." class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="..." class="d-block w-100" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>


</div>



  <!-- <div class="col-12 d-flex bglineas borderbott">
            <div id="carouselExampleControls" class="carousel slide " data-bs-ride="carousel">
                <div class="carousel-inner d-flex">
                    <div class="carousel-item  mt-1" [ngClass]="{'active':i==0}" *ngFor="let bloq of bloques; let i = index;">
                        <div class="row justify-content-evenly ">
                            <div class="mt-4 text-center  px-0 d-flex flex-column  roundedcrdbottom" [ngClass]="{'imgitemcar':x!=objLinea,'imgselect':x==objLinea,'col2':elementosPorPagina == 6, 'col-2': elementosPorPagina == 5,'col3': elementosPorPagina == 4,'col-3': elementosPorPagina == 3,'col-5': elementosPorPagina == 2}"  *ngFor="let x of bloq" (click)="fnGetGrupos(x)">
                                <img  class="w-100 imgitemcar"  [src]="'https://www.ecodeli.com/img/lineas/'+x.strCategoria+'.jpg'" alt="">
                                <span class="text-secondary p-1 fw-bold fs4 categoriass h-100 d-flex align-items-center justify-content-center roundedcrdbottom ">{{x.strCategoria}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev clsIconCarousel" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon " aria-hidden="true"></span>
                    <span class="visually-hidden text-secondary">Previous</span>
                </button>
                <button class="carousel-control-next clsIconCarousel" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden ">Next</span>
                </button>
            </div>
        </div> -->