<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-4 fw-bold fs-4 text-decoration-underline">Pedidos <span class="titulo">Tienda Ecodeli</span></h1>
        </div>
    </div>
<!-- Comienzan los filtros -->
    <div class="row justify-content-center">
        <div class="col-12  px-0 shadow-sm roundedcrdbottom">
            <div class="col-12 roundedcrd titulos fw-bold d-flex align-items-center py-1 ">
                <span class="material-symbols-outlined ms-3">
                    content_paste_search
                </span>
                <h2 class="ms-2 fw-bold mb-0">
                  Filtros
                </h2>
            </div>
            <div class=" row py-3 justify-content-evenly ">
              <div class="col-5 col-md-3 d-flex justify-content-center ">
                <mat-form-field class="w-100"  (click)="picker.open()" appearance="outline">
                  <mat-label>Elige una fecha</mat-label>
                  <input matInput [(ngModel)]="filtroFecha" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                 <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              </div>
              <div class="col-4 col-md-3 col my-md-0  d-flex justify-content-center">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Filtrar por ID</mat-label>
                  <input matInput placeholder="Ingrese ID" (keyup)="applyFilter($event)">
                    <span matSuffix class="material-symbols-outlined">
                      problem
                    </span>
                </mat-form-field>
              </div>
              <div class="col-2 col-md-3 d-flex justify-content-center align-items-center">
                <button (click)="fnlstarPedidos()" class="btn btn-primary">
                 <span class="d-none d-md-block">Filtrar</span> <i class="bi bi-funnel d-md-none d-block"></i>
                </button>
              </div>
            </div>
        </div>
    </div>
<!-- Comienza la tabla -->
    <div class="row mt-3 pb-5">
      <div class="row" *ngIf="spinner">
        <div class="col-3"></div>
        <div class="col-12 col-md-6">
          <img   src="assets/load.gif" class="w-100" >
        </div>
        <div class="col-3"></div>
      </div>
        <div class="col-12 px-0 "  *ngIf="!spinner">
          <div class="col-12 d-flex justify-content-between  titulos align-items-center roundedcrd">
            <h2 class="mb-0 ms-3 fw-bold">
                Listado
            </h2>
            <mat-form-field class=" my-2 me-3 bg-white rounded" appearance="outline">
              <mat-label>Buscar</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
            <div class="mat-elevation-z8 theig ">
              <div *ngIf="">
              </div>
              <table class="table " mat-table [dataSource]="dataSource" matSort>
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                  <td class="text-center" mat-cell *matCellDef="let row" > 
                    <span class="cursor"  data-bs-toggle="modal" data-bs-target="#exampleModal">
                      <a class="text-decoration-none fw-bold txtsmall" (click)=" fnMosatrarModal(row);">{{row.id}} </a>  
                    </span> 
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="customer">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de Cliente </th>
                  <td class="border-end border-start text-center txtsmall" mat-cell *matCellDef="let row"> {{row.customer.first_name}} {{row.customer.last_name}}</td>
                </ng-container>
              
                <!-- Fruit Column -->
                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emision </th>
                  <td mat-cell *matCellDef="let row" class="text-center txtsmall "> {{row.created_at|date}} </td>
                </ng-container>

                <!-- Fruit Column -->
                <ng-container matColumnDef="updated_at">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ultimo Cambio</th>
                  <td mat-cell *matCellDef="let row" class="text-center txtsmall border-start"> {{row.updated_at|date}} </td>
                </ng-container>

                <!-- Fruit Column -->
                <ng-container matColumnDef="totalItems">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad de Articulos</th>
                  <td mat-cell *matCellDef="let row" class="text-center border-start border-end txtsmall "> 
                    <div class="h-100 col-12 d-flex align-items-center justify-content-center mt-2">
                      <span class="rounded bgbadlle py-1 px-2 shadow fw-bold"> {{row.totalItems}}</span>
                    </div> 
                  </td>
                </ng-container>

                <ng-container matColumnDef="current_total_price">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Importe </th>
                  <td mat-cell *matCellDef="let row" class=" text-end textmidium fw-bold"> {{row.current_total_price|currency}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>No. Orden</th>
                  <td mat-cell *matCellDef="let row" class="text-center border-start border-end txtsmall fw-bolder"> {{row.name}} </td>
                </ng-container> 
              
                <!--iD PEDIDO-->
                
                <ng-container matColumnDef="pedido">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Pedido</th>
                  <td mat-cell *matCellDef="let row" class="text-center border-start border-end txtsmall fw-bolder"
                  [ngClass]="{  'negrita-amarillo': row.strEstatusPedido === 'PENDIENTE',
                    'negrita-verde': row.strEstatusPedido === 'CONCLUIDO',
                    'negrita-gris': row.strEstatusPedido === 'SINAFECTAR' || row.strEstatusPedido === '' }"> <label [title]="row.strEstatusPedido">{{row.strPedidoMovID}}</label> </td>
                </ng-container> 

                <tr class="clShortBlue zindesmin " mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr class="" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row " *matNoDataRow>
                  <td class="mat-cell" colspan="4">No se encontro el dato"{{input.value}}"</td>
                </tr>
                </table>
              </div>
              <mat-paginator class="clShortBlue text-white roundedcrdbottom" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
        </div>
    </div>
</div>
<div class="modal fade hmdal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">
      <div class="modal-header p-2 clShortBlue">
        <h3 class="modal-title mb-0 fw-bold text-white mx-auto" id="exampleModalLabel">Orden:{{objDatosEnvio.address.order_name}}</h3>
        <button type="button" class="ms-0 btn-close text-light" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body ">
        <div class="row" *ngIf="boolDetalle">
          <div class="col-4"></div>
          <img  class="col-6" src="assets/load.gif" style="width:30% ;" >
        </div>
        <div class="row justify-content-evenly" *ngIf="!boolDetalle">
         
          <div class="col-6 shadow-sm border d-flex flex-column rounded-start">
            <label>
              <b class="textoCard">Contacto:</b>
              <span class="ftext fw-bold">{{dataCte.firstName}}  {{dataCte.lastName}} </span> <br>
             <b class="textoCard">Teléfono:</b>
             <span class="ftext fw-bold"> {{dataCte.phone}}</span>
            </label>
            <span>
              <b class="textoCard">Dirección:</b> 
             <span class="ftext fw-bold">{{objDatosEnvio.address.street }},</span> 
            </span>
              <span>
                <span class="ftext fw-bold">   
                {{objDatosEnvio.address.city}}, 
                {{objDatosEnvio.address.state}}, 
                {{objDatosEnvio.address.country}}, 
                {{objDatosEnvio.address.postalCode}}</span>
              </span>
          </div>
          <div class="col-6 shadow-sm border rounded-end">
            <div class="row">
              <div class="col-12 d-flex justify-content-end">
                <div class=""> 
                  <span class="col-4 text-end me-2">Descuento:</span>
                  <span [ngClass]="{'text-danger text-decoration-line-through':descuentos>0}" class="fw-bold ">{{descuentos|currency}}</span>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-end">
                <div class="">
                <span class="col-4 text-end me-2">Sub Total:</span>
                <span class="fw-bold">{{subtotalModal|currency}}</span>
              </div>
            </div> 
              <div class="col-12  d-flex justify-content-end">
                <div class=""> 
                <span class="col-4 text-end me-2">Impuestos({{(porcentImpuesto-1)*100|number:'1.0-0'}}%):</span>
                <span class="fw-bold">{{IvaModal|currency}}</span>
              </div> 
            </div> 
              <div class="col-12 d-flex justify-content-end">
                <div class=""> 
                  <span class="col-4 text-end me-2">Envio:</span>
                  <span class="fw-bold">  {{CobroEnvio|currency}}</span>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-end">
                <div class="bgcardTotal rounded shadow my-1 p-2">
                <span class="col-4 text-end me-2 text-white">Total:</span>
                <span class="fw-bold text-white">{{this.totalModal|currency}}</span>
              </div>
              </div>
            </div>
          </div> 
        </div>
        <br>
        <div class="table-responsive hmdal th30vh">
          <table class="table table-bordered">
            <thead>
              <tr class="thcenter">
                <th scope="col">#</th>
                <th scope="col">Articulo</th>
                <th scope="col">Nombre</th>
                <th scope="col">Precio</th>  
                <th scope="col">Cantidad</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let art of arrayArtsModal">
                <td>
                  <img src="{{art.urlImagen}}" class="" style="width: 90px;" alt="...">
                </td>
                <td>
                  <span   style="font-size: 10px;">{{art.variant_title}}</span>
                </td>
                <td>
                  <span class="" style="font-size: 10px;">{{art.name}}</span>
                </td>
                 <td class="text-end">
                    <span *ngIf="descuentos>0" class="txtsmall1 text-danger text-decoration-line-through" >{{((art.price - (art.price*porcentajeDescuento))/porcentImpuesto)+(art.price*porcentajeDescuento) |currency}}</span>
                    <br *ngIf="descuentos>0">
                    <span class="fw-bold txtsmall" >{{(art.price-(art.price*porcentajeDescuento))/porcentImpuesto|currency}}</span>
                  </td> 
                <td class="text-center ">
                    <span class="rounded bgbadlle py-1 px-2 shadow-sm fw-bold"> {{art.quantity}}</span>
                </td>
                <td class="text-end ">
                    <span *ngIf="descuentos>0" class="txtsmall1 text-danger text-decoration-line-through" >{{(((art.price - (art.price*porcentajeDescuento))/porcentImpuesto)+(art.price*porcentajeDescuento) )* art.quantity|currency}}</span>
                    <br *ngIf="descuentos>0">
                    <span class="txtsmall fw-bold"  > {{((art.price-(art.price*porcentajeDescuento))/porcentImpuesto)*art.quantity|currency}}</span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr *ngFor="let total of arrayTotales">
                <td colspan="3" height="20px" style="padding-bottom: 0!important;border-bottom-width: 0;"> </td>
                <td height="20px" colspan="2" style="font-size: 11px;    padding-bottom: 0!important;border-bottom-width: 0;">{{total.name}}</td>
                <td height="20px" style="font-size: 11px;text-align: right;font-weight: bold;padding-bottom: 0!important;border-bottom-width: 0;">{{total.value/100 |currency}}</td>
              </tr>
            </tfoot>
          </table> 
        </div>
      </div>
      <div class="modal-footer clShortBlue">{{mustraEnvioIntel}}
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button><!--  *ngIf="estatusPedido==''" -->
        <button type="button" *ngIf="mustraEnvioIntel==0" class="btn btn-primary primaryButton" (click)="fnSetPedido_Ecodeli(objDatosEnvio,arrayArtsModal,objDatosEnvio.address.order_name,arrayTotales)">Enviar a Intelisis</button>
      </div>
    </div>
  </div>
</div>