import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
@Injectable({
  providedIn: 'root'
})
export class AltaClienteService {
 /**********************VARIABLES para guardar datos del cliente******************************************/
 private ObjAlta_Cte_Subject: BehaviorSubject<any> = new BehaviorSubject<any>(0);
 public ObjAlta_Cte$: Observable<any> = this.ObjAlta_Cte_Subject.asObservable();
 private ObjAlta_Cte: any = {};  // Inicializamos en 0

 constructor(private localSt:LocalStorageService) { 
    let objcte = this.localSt.retrieve('ObjAlta_Cte');
    this.setCte_alta(objcte);
 }
    setCte_alta(objCte:any){

      console.log(objCte)
    this.ObjAlta_Cte=objCte;
  
  this.localSt.store('ObjAlta_Cte',this.ObjAlta_Cte);
  this.ObjAlta_Cte_Subject.next(this.ObjAlta_Cte);

 }


 deleteCte(){
  
  this.ObjAlta_Cte={};
  this.localSt.store('ObjAlta_Cte',{})
   this.ObjAlta_Cte_Subject.next(this.ObjAlta_Cte);
 }


} 
 

/*
strRfc:'',
strRazonSocial: '',
      strCfdi: '',
      strRegimenFiscal:'',
    
      strCp: new '',
      strEstado: '',
      strCiudad: '',
      strColonia: '',
      strCalle: '',
      strNoExterior: '' ,
       strNombreComercial: '' ,
      strTipoCliente: '' ,
      strTelefono: '' ,
      strVendedor: '' ,
 
     strContacto:  '' ,
      strCorreo:  '' ,
      strTelefono:  '' 
    }

 */