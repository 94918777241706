<div class="container-fluid px-5">
    <div class="row">
        <div class="col-12 product-line">
            <h1 class="mb-0 text-center my-3 titulo nunito fs-3">LINEAS  <span >COMODATOS</span> </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 col-lg-4 col-xxl-3 d-flex justify-content-center mt-3 h-75" *ngFor="let x of arrayLineas">
            <div class="col-12 d-flex flex-column bg-white shadow brderBot brdertop pointer conte" (click)="onCardClick(x)">
                <div class="col-9 mx-auto px-0 h80  img-container">
                    <img [src]="'https://www.cleanclean.mx/imagenes/Sublinea/'+x.strGrupo+'.jpg'" class="w-100 h-100  zoom-image " alt="">
                </div>
                <div class="col-12 h20 cl3 brderBot d-flex align-items-center justify-content-center">
                    <span class="fw-bold nunito fs-6 text-center text-effect">{{x.strGrupo}}</span>
                </div>
            </div>
        </div>
    </div>
</div>