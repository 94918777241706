import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetoTorkBitacoraInicialComponent } from '../reto-tork-bitacora-inicial/reto-tork-bitacora-inicial.component';
import { DataDBService } from '../servicios/data-db.service';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { filter } from 'rxjs/operators';

export interface bitacoraTork {
  intIDReto: number,
  intIDArticulo: number, 
  strFechaInicial: string, 
  strUltimaModificacion: string, 
  strCliente: string, 
  strPuesto: string
  strDireccion: string, 
  strTelefono: string,
  strContacto: string,
  strAgente: string,
  intIDSucursal: number,
  strNombreSucursal: string,
  strTipo: string,
  strFirmaTork: string,
  strFirmaCliente: string,
  strComentarios: string,
  strNombreTork: string,
  intTotalProductosInicial: number,
  intTotalProductosTork: number,
  intTotalProductosEntregado: number,
 /*  strTipoReto:string; */
}

@Component({
  selector: 'app-reto-tork',
  templateUrl: './reto-tork.component.html',
  styleUrls: ['./reto-tork.component.css']
})
export class RetoTorkComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['intIDReto'/* , 'strTipoReto' */, 'strFechaInicial', 'strTipo', 'strCliente', 'strAgente','estatus','strSeguimiento','strMovIDCom','strMovIDSa', 'strAnalisis'];
  //displayedColumns: string[] = ['intIDReto', 'strFechaInicial', 'strTipo', 'strCliente', 'strAgente', 'estatus','strSeguimiento', 'strAnalisis'];//'strContacto',  'intIDSucursal',
  public arrayListaRetosCompleta: any=[];
  arrayListaRetos: bitacoraTork[]=[];
  dataSource: MatTableDataSource<bitacoraTork>;
  public tagName:any;
  public arrayAccesos:any=[];
  public strAgente:string="";
  public btnAgregar: boolean=false;
  public listadoGeneral: boolean=false;
  public arrayGerentes: any=[];
  public arraySucursales: any=[];
  public arrayAgentes: any=[];
  public agenteSelected:any={};
  public sucursalSelected:any={};
  public selectGte:any=false;
  public selectSuc:any=false;
  public selectAgt:any=false;
  public gteSelected:any='';
  public strGerente:string = '';
  public strTipoAcceso: string="";
  items = ['Mustard', 'Ketchup', 'Relish'];
  selectedItems = new Set<string>();
  isDropdownOpen = false;
  //public arrayEstatus:any=[{value:'PENDIENTE', view:'PENDIENTE'},{value:'RECHAZADO', view: 'RECHAZADO'},{value:'AUTORIZADO', view:'AUTORIZADO'},{value:'', view:'TODOS'}];
  public arrayEstatus:any=[];
  
  public estatusSelected: string='';
  constructor(private indexedDBService: DataDBService,public dialog: MatDialog, elem:ElementRef,private _peticionesService: PeticionesService, public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router ) {
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.dataSource = new MatTableDataSource(this.arrayListaRetos);
  }
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleSelection(item: string): void {
    if (this.selectedItems.has(item)) {
      this.selectedItems.delete(item);
    } else {
      this.selectedItems.add(item);
    }
  }

  isSelected(item: string): boolean {
    return this.selectedItems.has(item);
  }
  ngAfterViewInit() {       
    this.dataSource.paginator = this.paginator!;
    this.dataSource.paginator._intl.itemsPerPageLabel='Documentos por página';         
    this.dataSource.paginator.pageSize=10;      
    this.dataSource.sort = this.sort!;
  }
  
  ngOnInit(): void {
    
    this.indexedDBService.getItem('arrayResult', (value) => {
  
      console.log('carga en reto tork')
      this.arrayAccesos=value?value:[];
    
    //this.arrayAccesos = this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):{};
    //Crear las variables para mostrar o no los botones o permisos a los que tiene acceso el usuario
    if(this.arrayAccesos.length>0){
      this.strAgente=this.arrayAccesos[0]?this.arrayAccesos[0].strAgente:'';
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }
      else { 
        this.strTipoAcceso=objAcceso[0].strTipoAcceso?objAcceso[0].strTipoAcceso:"";
        this.listadoGeneral=objAcceso.find((o:any)=>o.strAccion=='Listado')?true:false;     
        this.btnAgregar=objAcceso.find((o:any)=>o.strAccion=='Agregar')?true:false;    
        
        //Filtros:
        this.strGerente= objAcceso[0].strUsuario;
        this.selectGte=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
        this.selectSuc=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.selectAgt=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
   
        if(this.selectSuc){
            this.gteSelected= {strGerente:objAcceso[0].strUsuario };
            this.fnGetSucursales(this.gteSelected);
        }
        if(this.selectGte){   
            this.fnGetGerentes();
        }
        if(this.selectAgt){
            this.sucursalSelected = {strSucursal:objAcceso[0].sucursal};
            this.fnGetAgentes(this.sucursalSelected);
        }
        this.fnGetListaRetos();
      }
    }else{
      this.router.navigate(['/login']);
    }
    this.localSt.store('arrayRetoAceptado',[]);//Limpiar variable
    this.localSt.store('arrayDetalleTork',[]);//Limpiar variable
    this.localSt.store('arraySeguimientoTork',[]);//Limpiar variable
  });
    //this.fnGetListaRetos();
  }

  fnGetGerentes(){
    this._peticionesService.getApiCompras({
      strAccion: 'getGerentes' 
    }).subscribe(
      result => { 
        this.arrayGerentes=result; 
        this.arrayGerentes.push({strGerente: '', strEstatus: 'Todos'})
        //console.log(result)
      }
    );
  }

  fnGetSucursales(gerente:any){  
    this._peticionesService.getApiCompras({
      strAccion: 'getSucursales' ,
      strGerente:gerente.strGerente
    }).subscribe(
      result => {  
        this.arraySucursales=result; 
        //console.log(result)
      }
    );
  }

  fnGetAgentes(sucursal:any){
    this._peticionesService.getApiCompras({
      strAccion: 'getAgentes' ,
      strSucursal:sucursal.strSucursal
    }).subscribe(
    result => {  
      this.arrayAgentes=result;
      
      if(this.strAgente){//Si existe el agente, se inicializa en ese agente
        let objAgente=this.arrayAgentes.filter((a:any)=>a.strEstatus == this.strAgente);      
        //this.agenteSelected=objAgente[0];
        this.agenteSelected={}
      }
      //console.log(result)
    });  
  }



  fnGetListaRetos(){
    let accion=this.strTipoAcceso=="8"?"getListaRetoTorkCte":"getListaRetoTork";
    //getListaRetoTork
    if(this.strTipoAcceso=="8"){
      this.arrayEstatus=[{value:'AVANZADO', view:'AVANZADO'},{value:'AUTORIZADO', view: 'AUTORIZADO'},{value:'RECHAZADO', view:'RECHAZADO'},{value:'', view:'TODOS'}];
    }else{
      this.arrayEstatus=[{value:'PENDIENTE', view:'PENDIENTE'},{value:'RECHAZADO', view: 'RECHAZADO'},{value:'AUTORIZADO', view:'AUTORIZADO'},{value:'', view:'TODOS'}];
    }

    let objGetListado={
      "strAccion": accion,//"getListaRetoTork",
      "strAgente": this.selectAgt?this.agenteSelected.strEstatus?this.agenteSelected.strEstatus:'':this.strAgente, //this.agenteSelected.strEstatus  ?this.agenteSelected.strEstatus:'',//this.strAgente,
      "strSucursal": this.sucursalSelected.strSucursal?this.sucursalSelected.strSucursal:'',
      "strGerente": this.gteSelected.strGerente && this.gteSelected.strGerente!='agustin'?this.gteSelected.strGerente:'',
      "strEstatus":this.estatusSelected!='' ?this.estatusSelected:''
    };

    //console.log(objGetListado);
    //console.log("holaaaa");

    this._peticionesService.getApiRetoTork(objGetListado).subscribe( result => {
      this.arrayListaRetos=result;      
      console.log(result);

      this.dataSource.data = this.arrayListaRetos as bitacoraTork [];
      this.dataSource.paginator = this.paginator!;
      this.dataSource.paginator._intl.itemsPerPageLabel='Documentos por página';         
      this.dataSource.paginator.pageSize=10;      
      this.dataSource.sort = this.sort!;
    });
  }  

  fnNuevoReto(){  
    this.router.navigate(['/retoTorkBitacoraInicial']);
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  fnDetalle(detalle: any){
    //console.log(detalle);
    this.localSt.store('arrayDetalleTork',detalle);
    this.router.navigate(['/retoTorkBitacoraInicial']);
  }
  
  fnSeguimiento(detalle: any){
    //console.log(detalle);
    this.localSt.store('arraySeguimientoTork',detalle);
    this.router.navigate(['/retoTorkSeguimiento']);
  }

  fnAnalisis(detalle: any){
    //console.log("detalle analisis");
    //Guardar en sesion IDReto ACEPTADO:
    let datosRetoAnalisis={
      intIDReto: detalle.intIDReto,
      strEstatusSeguimiento: detalle.strEstatusSeguimiento,
      strEstatusAnalisis: detalle.strEstatusAnalisis,
      strComentarioAnalisis: detalle.strComentarioAnalisis,
      strMotivoRechazoAnalisis: detalle.strMotivoRechazoAnalisis,
      strFolio: detalle.strFolio?detalle.strFolio:''
    }
    this.localSt.store('arrayRetoAceptado', datosRetoAnalisis);

    this.router.navigate(['/listaDespachadores']);
  }
}
