import { Component, OnInit,ElementRef, ViewChild} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
 
import { CarroService } from '../servicios/carroservice';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { AlertifyService } from '../alertify.service';

import { filter } from 'rxjs/operators';

import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';

declare var $:any;
declare var bootstrap:any;
import { RecursosHumanosService } from '../servicios/recursos-humanos.service';
import { ScreenStateService } from '../servicios/styles/screenState.service';

@Component({
  selector: 'app-relojchecador',
  templateUrl: './relojchecador.component.html',
  styleUrls: ['./relojchecador.component.css']
})
export class RelojchecadorComponent implements OnInit {
  @ViewChild("contenido") contenido: ElementRef; 
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;
  public strConexion:string="";
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayAccesos:any=[];
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public tagName:any;
  public objCte:any={};
  public spiner:boolean=false;
  public spinerMdl:boolean=false;

  public data_table_reloj: any[] = [];
  //VARIABLES DEL DE DETALLE
  public valDetalle1: string;
  public valDetalle2: string;
  public valDetalle3: string;
  public valDetalle4: string;
  public valDetalle5: string;
  public valDetalle6: string;
  public valDetalle7: string;
  public valDetalle8: string;

  public banderaCargando:boolean;
  public arrayRelojChecador:any[] = [];
  public banderaPermisos:boolean;

  //TOTALES DEL RELOJ
  public Correctas: number;
  public Retardos: number;
  public Faltas: number;
  public banderaTab: number=1;

  public ctrlQuincena: FormControl = new FormControl();
  public ctrlSemana: FormControl = new FormControl();
  public ctrlEstatus: FormControl = new FormControl();

  public arrayPermisosAux;
  public arrayPermisos;
  public data_table_permisos: any[] = [];
  public data_table_vacaciones: any[] = []
  public arrayVacaciones;
  public arrayVacacionesAux;
  public arrayIndicadores;

  public strPrestamosPendientes:string
  public strIDVacacionesPendientes:string
  public strIDPermisosPendientes:string
  public strDiasVacacionesPendientes:string
  public dblTotalPorPagar:number;
  public strFechaAniversario:string;

  //VARIABLES PARA AUTORIZAR O RECHAZAR PERMISO O VACACIONES
  public strAccion: string;
  public IDMovimiento: string;
  public motivo:string;
  public goceSueldo:string;
  public accionstore:string;
  public arrayDatos;

  //Formulario para generar nuevo Permiso
  public permisoForm: FormGroup;
  //Fecha
  public Ejercicio: string = String(new Date().getFullYear());
  public Periodo: string = String(new Date().getMonth() + 1);
  public Dia: string = String(new Date().getDate());
  public fecha: string;
  public arrayMotivoPermisos;
  public motivoPermiso: FormControl = new FormControl();
  public banderaPermisoOtro: boolean;
  

  //Formulario para genrar nueva solicitud de vacaciones
  public vacacionesForm: FormGroup;
  public fechaIncremento;
  public ctrlDiasSolicitados:number;
  public ctrlDiasVacacionales:number;
  public DiaFecha: string;
  public arrayFeriados;
  public banderaDias: number;

  constructor(public _alertify: AlertifyService,private carroservice:CarroService,elem:ElementRef,private localSt:LocalStorageService, private editPl:PlantillafinService,   private router: Router,   private _httpService:PeticionesService, private _filtroServ :FiltrosService, private _movimientosRHService :RecursosHumanosService, public screen: ScreenStateService) {
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');

    

    if (this.strConexion =='COMERCIAL'){
      this.strConexion='connection'
    }
     //array de accesos
    //obtener niveles de acceso
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      }
      else{
        this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
        this.strGerente = this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.strSucursal =this.boolSucursal?'':this.localSt.retrieve('strSucursal');
        this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
        this.strAgente= this.boolAgente?'':this.localSt.retrieve('strAgente');
      }
    }

    this.ctrlQuincena.setValue("");
    this.ctrlSemana.setValue("");
    this.ctrlEstatus.setValue("PENDIENTE")
    this.arrayPermisosAux = "";
    this.arrayPermisos = [];
    this.arrayVacacionesAux = "";
    this.arrayIndicadores = [];
    this.arrayVacaciones = [];
    
    this.strPrestamosPendientes = '0';
    this.strIDVacacionesPendientes = '0';
    this.strIDPermisosPendientes = '0';
    this.strPrestamosPendientes = '0';
    this.strDiasVacacionesPendientes = '0';
    this.dblTotalPorPagar = 0;
    this.strFechaAniversario = "";

    let fechaActual = this.Ejercicio + '-' + this.Periodo + '-' + this.Dia ;

    this.permisoForm = new FormGroup({
      fecha: new FormControl(fechaActual, [Validators.required]),
      fechaPermiso: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      motivoPermiso: new FormControl('', [Validators.required]),
      horaInicio: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      horaFin: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      goceSueldo: new FormControl('', [Validators.required]),
      otroMotivo: new FormControl('',)
    });

    this.banderaPermisos = false;
    this.banderaCargando = true;
    this.motivo="";
    this.arrayMotivoPermisos = [];
    this.banderaPermisoOtro = false;
    this.MotivosPermisos();

    this.buildForm();
    this.buildFormVacaciones();
  }

  ngOnInit(): void {
    this.fnGetFiltros();
    this.ProcesarReporte();
  }

  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  //    console.log(nuevoValor);

      console.log(nuevoValor)
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }


  ProcesarReloj(){

    console.log(this.intEjercicio);
    console.log(this.intPeriodo);

    this.data_table_reloj = [
      {title: 'Fecha', value: 'strFecha'},
      {title: 'Entrada', value: 'strFechaEntrada'},
      {title: 'Salida Comida', value: 'strHoraEntradaComida'},
      {title: 'Entrada Comida', value: 'strHoraSalidaComida'},
      {title: 'Salida', value: 'strFechaSalida'},
      {title: 'Semáforo', icons: [
          {fontawesome: 'fa fa-circle-check verde', rules: (item) => item.strEstatusEntrada==1},
          {fontawesome: 'fa fa-clock naranja', rules: (item) => item.strEstatusEntrada==2},
          {fontawesome: 'fa fa-circle-xmark rojo', rules: (item) => item.strEstatusEntrada==3},
          {fontawesome: 'fa fa-bell oro', rules: (item) => item.strMotivoPermiso!='', metodo: (item) => this.DetalleModal('PERMISO', item.strMotivoPermiso, item.strPermisoHoraInicio, item.strPermisoHoraFin, item.strGoceSueldo, item.strFechaSolicitudPermiso, item.strFechaAutorizacionPermiso, item.strMotivoAutorizacion)},
          {fontawesome: 'fa fa-plane oro', rules: (item) => item.strFechaSolicitudInicio!='', metodo: (item) => this.DetalleModal('VACACIONES', item.strFechaSolicitudInicio, item.strFechaSolicitudFin, item.strDiasCorrespondientes, item.strDiasPendientes, item.strFechaAutorizacionVacaciones, '', '')}
      ]},
      {title: 'Horas Trabajadas', value: 'dblHorasTrabajadas'},
      {title: 'Semáforo', icons: [
          {fontawesome: 'fa fa-circle-check verde', rules: (item) => item.intDiaSemana!=6 && item.dblHorasTrabajadas>=8.0},
          {fontawesome: 'fa fa-circle-xmark rojo', rules: (item) => item.intDiaSemana!=6 && item.dblHorasTrabajadas<=7.9},
          {fontawesome: 'fa fa-circle-check verde', rules: (item) => item.intDiaSemana==6 && item.dblHorasTrabajadas>=5.0},
          {fontawesome: 'fa fa-circle-xmark rojo', rules: (item) => item.intDiaSemana==6 && item.dblHorasTrabajadas<=4.9}
      ]}
    ]


    this.banderaCargando = true;
    this.arrayRelojChecador = [];
    this.banderaPermisos = true;
    this.Correctas = 0;
    this.Retardos = 0;
    this.Faltas = 0;
      
    let strPersonal =  this.localSt.retrieve('arrayAccesos')[0].strSSN;

    console.log(this.localSt.retrieve('arrayAccesos')[0]);

    this._movimientosRHService.get_RelojCte("1", this.localSt.retrieve('arrayAccesos')[0].strUsuario,strPersonal,this.intPeriodo, this.intEjercicio, this.ctrlQuincena.value, this.strConexion,'', this.ctrlSemana.value).subscribe(
      result =>{
        this.banderaCargando = false;
        if(result.length>0){
            this.arrayRelojChecador = result;
            console.log(this.arrayRelojChecador);


            this.arrayRelojChecador.forEach(element =>{
                if (element.strEstatusEntrada=="1"){
                    this.Correctas = this.Correctas + 1;
                }else if (element.strEstatusEntrada=="2"){
                    this.Retardos = this.Retardos + 1;
                }else{
                    this.Faltas = this.Faltas + 1;
                }

            })
        }else{
            this.arrayRelojChecador = result;
        }
      },
      error =>{
          console.log("Error");
          /*this._message.error('Error', <any>error, {
              position: ['bottom', 'right'],
              timeOut: 5000,
              animate: 'fade',
              showProgressBar: true
          });*/ 
      }
    );

  }

  //DETALLE DE PERMISOS O VACACIONES EN MODAL
  
  DetalleModal( tipo,  parametro1, parametro2, parametro3, parametro4, parametro5, parametro6, parametro7 ){
    console.log("Entro");
    this.valDetalle1 = tipo;
    this.valDetalle2 = parametro1;
    this.valDetalle3 = parametro2;
    this.valDetalle4 = parametro3;
    this.valDetalle5 = parametro4;
    this.valDetalle6 = parametro5;
    this.valDetalle7 = parametro6;
    this.valDetalle8 = parametro7;
    
    let modalcontenido = new  bootstrap.Modal(document.getElementById("contenido"));
    modalcontenido.show()
    //$("#contenido").modal('show');
    //this.modal.open(contenido,{size:'sm', scrollable: true, centered: true});
  }

  cerrar(){
    console.log("Cerramos")
  }


  //FUNCIÓN PARA EVALUAR EL TAB AL QUE LE DIERON CLIC.
  onTabClick(event) {
    console.log(event);
    if(event.tab.textLabel=="RelojChecador"){
      this.banderaTab = 1;
      if(this.arrayRelojChecador.length == 0){
        this.ProcesarReloj();
      }
    }
    else if (event.tab.textLabel=="Permisos"){
      this.banderaTab = 2;

      if(this.arrayPermisosAux==""){
          this.ProcesarPermisos();
      }
    }else if (event.tab.textLabel=="Vacaciones"){
      this.banderaTab = 3;

      if(this.arrayVacacionesAux==""){
          this.ProcesarVacaciones();
      }
    }else{
      this.banderaTab = 4;
      if(this.arrayIndicadores==""){
          this.ProcesarIndicadores();
      }
    }
  }

  ProcesarReporte(){
    if(this.banderaTab == 1 ){
        this.ProcesarReloj();
    }else if(this.banderaTab == 2){
        this.ProcesarPermisos();
    }else{
        this.ProcesarVacaciones();
    }
  }

  //PESTAÑA DE MOVIMIENTOS PERMISO
  ProcesarPermisos(){
    this.generar_data_table_permisos()
    //console.log('muestra permisos');
    
    this.banderaCargando = true;
    
    this.arrayPermisos = [];

    let strPersonal =  this.localSt.retrieve('arrayAccesos')[0].strSSN;

    if (this.strAgente!='' && this.strAgente!=null && this.strAgente!=undefined && this.boolAgente){
      strPersonal = this.strAgente;
    }
    
    this._movimientosRHService.get_MovimientosRH("1", strPersonal,this.intPeriodo, this.intEjercicio, this.strConexion).subscribe(
      result =>{
        this.banderaCargando = false;
        if(result.length>0){
            console.log(result)
            this.arrayPermisosAux = result;
            //console.log(this.arrayPermisosAux)
            this.filtrandoPermisos();

        }else{
            this.arrayPermisos = result;
        }
      },
      error =>{
        console.log("Error")
        /*this._message.error('Error', <any>error, {
            position: ['bottom', 'right'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
        });*/
      }
    );
    
  }

  generar_data_table_permisos(){
    this.data_table_permisos = [
        {title: 'ID', value: 'strIDPermiso'},
        {title: 'Sucursal', value: 'strSucursal'},
        {title: 'Solicitante', value: 'strUsuarioNombre'},
        {title: 'Fecha Permiso', value: 'strFechaSolicitudPermiso'},
        {title: 'Con Goce', value: 'strGoceSueldo'},
        {title: 'Duración', value: 'strDuracionPermiso'},
        {title: 'Motivo', value: 'strMotivoPermiso'},
        {title: 'Fecha Solicitud', value: 'strFechaSolicitud'},
        {title: 'Autorizó', value: 'strUsuarioAutorizacion|strMotivoAutorizacion'},
        {title: 'Acciones', header_rules: (item) => item.strEstatus=='PENDIENTE', icons: [
        //{title: 'Acciones', header_rules: (item) => this.DatosUsuario.strAutorizacionCte != '' && this.sw_modificarEnModulo && item.strEstatus=='PENDIENTE', icons: [
            {fontawesome: 'fa fa-circle-check verde', metodo: (item) => this.AccionesPermiso('AUTORIZADO', item.strIDPermiso, item.strGoceSueldo,item),  rules: (item) => (item.strSSN != this.localSt.retrieve('arrayAccesos')[0].strSSN.trim() || item.strSSN == item.strSSNJefe)},
            {fontawesome: 'fa fa-circle-xmark rojo', metodo: (item) => this.AccionesPermiso('RECHAZADO', item.strIDPermiso, item.strGoceSueldo,item), rules: (item) => (item.strSSN != this.localSt.retrieve('arrayAccesos')[0].strSSN.trim() || item.strSSN == item.strSSNJefe)}
        ]}
    ]
  }

  //CAMBIO DE SELECT ESTATUS SEGUN EL TAB LLAMAMOS LA ACCIÓN CORRESPONDIENTE
  CambioEstatus(){
    if(this.banderaTab ==2){
        this.filtrandoPermisos();
    }else if (this.banderaTab ==3){
        this.filtrandoVacaciones()
    }
  }

  //FILTRANDO PERMISOS POR ESTATUS
  filtrandoPermisos(){
    this.generar_data_table_permisos()
    this.arrayPermisos = [];
    //console.log(this.arrayPermisosAux)
    if (this.arrayPermisosAux!=""){
        this.arrayPermisosAux.forEach(element =>{
            //console.log(element)
            if(element.strEstatus==this.ctrlEstatus.value){
                this.arrayPermisos.push(element);
            }           
        })
    }
  }

  //FILTRANDO VACACIONES POR ESTATUS
  filtrandoVacaciones(){
    //console.log("Entro");
    this.generar_data_table_vacaciones()
    this.arrayVacaciones = [];
    if (this.arrayVacacionesAux!=""){
        console.log("Entro");
        this.arrayVacacionesAux.forEach(element =>{
            if(element.strEstatus==this.ctrlEstatus.value){
                this.arrayVacaciones.push(element);
            }           
        })
    }
  }
  generar_data_table_vacaciones(){
    this.data_table_vacaciones = [
        {title: 'ID', value: 'strIDVacaciones'},
        {title: 'Sucursal', value: 'strSucursal'},
        {title: 'Solicitante', value: 'strUsuarioNombre'},
        {title: 'Días Vigentes', value: 'strDiasCorrespondientes'},
        {title: 'Dias Solicitados', value: 'strDiasPendientes'},
        {title: 'Fecha Inicio', value: 'strFechaSolicitudInicio'},
        {title: 'Fecha Final', value: 'strFechaSolicitudFin'},
        {title: 'Fecha Solicitud', value: 'strFechaSolicitud'},
        {title: 'Acciones', header_rules: (item) => item.strEstatus=='PENDIENTE', icons: [
            {fontawesome: 'fa fa-circle-check verde', metodo: (item) => this.AccionesVacaciones('AUTORIZADO', item.strIDVacaciones, item), rules: (item) => item.strEstatus=='PENDIENTE'&& (item.strSSN != this.localSt.retrieve('arrayAccesos')[0].strSSN.trim() || item.strSSN == item.strSSNJefe)},
            {fontawesome: 'fa fa-circle-xmark rojo', metodo: (item) => this.AccionesVacaciones('RECHAZADO', item.strIDVacaciones, item), rules: (item) => item.strEstatus=='PENDIENTE'&& (item.strSSN != this.localSt.retrieve('arrayAccesos')[0].strSSN.trim() || item.strSSN == item.strSSNJefe)}
        ]},
        {title: 'Reversa de Vacaciones', styles: {width: '220px'}, icons: [
            {fontawesome: 'fa fa-circle-xmark rojo', metodo: (item) => this.ReversaVacaciones(item), rules: (item) => item.strEstatus=='AUTORIZADO' && item.strSSN == this.localSt.retrieve('arrayAccesos')[0].strSSN && item.strDuracionPermiso>0}
        ]}
    ]
  }
/*
  generar_data_table_vacaciones(){
    this.data_table_vacaciones = [
        {title: 'ID', value: 'strIDVacaciones'},
        {title: 'Sucursal', value: 'strSucursal'},
        {title: 'Solicitante', value: 'strUsuarioNombre'},
        {title: 'Días Vigentes', value: 'strDiasCorrespondientes'},
        {title: 'Dias Solicitados', value: 'strDiasPendientes'},
        {title: 'Fecha Inicio', value: 'strFechaSolicitudInicio'},
        {title: 'Fecha Final', value: 'strFechaSolicitudFin'},
        {title: 'Fecha Solicitud', value: 'strFechaSolicitud'},
        {title: 'Acciones', header_rules: (item) => item.strEstatus=='PENDIENTE', icons: [
            {fontawesome: 'fa fa-circle-check verde', metodo: (item) => this.AccionesVacaciones('AUTORIZADO', item.strIDVacaciones, item), rules: (item) => item.strEstatus=='PENDIENTE'&& (item.strSSN != this.localSt.retrieve('arrayAccesos')[0].strSSN.trim() || item.strSSN == item.strSSNJefe)},
            {fontawesome: 'fa fa-circle-xmark rojo', metodo: (item) => this.AccionesVacaciones('RECHAZADO', item.strIDVacaciones, item), rules: (item) => item.strEstatus=='PENDIENTE'&& (item.strSSN != this.localSt.retrieve('arrayAccesos')[0].strSSN.trim() || item.strSSN == item.strSSNJefe)}
        ]},
        {title: 'Reversa de Vacaciones', styles: {width: '220px'}, icons: [
            {fontawesome: 'fa fa-circle-xmark rojo', metodo: (item) => this.ReversaVacaciones(item), rules: (item) => item.strEstatus=='AUTORIZADO' && item.strSSN == this.localSt.retrieve('arrayAccesos')[0].strSSN && item.strDuracionPermiso>0}
        ]}
    ]
  }*/

  //PESTAÑA DE MOVIMIENTOS VACACIONES
  ProcesarVacaciones(){
    this.generar_data_table_vacaciones()
      this.banderaCargando = true;
      this.arrayVacaciones = [];
      console.log(this.strAgente)
      let strPersonal =  this.localSt.retrieve('arrayAccesos')[0].strSSN;

      if (this.strAgente!='' && this.strAgente!=null && this.strAgente!=undefined && this.boolAgente){
        strPersonal = this.strAgente;
      }

      this._movimientosRHService.get_MovimientosRH("2", strPersonal,this.intPeriodo, this.intEjercicio, this.strConexion).subscribe(
          result =>{
              this.banderaCargando = false;
              if(result.length>0){
                  console.log(result);
                  this.arrayVacacionesAux = result;
                  
                  this.filtrandoVacaciones();
              }else{
                  this.arrayVacaciones = result;
              }
          },
          error =>{
            console.log("Entro");
            /*this._message.error('Error', <any>error, {
                position: ['bottom', 'right'],
                timeOut: 5000,
                animate: 'fade',
                showProgressBar: true
            });*/
          }
      );
  }

  //PESTAÑA DE INDICADORES
  ProcesarIndicadores(){
    this.generar_data_table_vacaciones()
   

    this.banderaCargando = true;
    this.arrayIndicadores = [];
        
    let strPersonal =  this.localSt.retrieve('arrayAccesos')[0].strSSN;
    
    
    this._movimientosRHService.get_MovimientosRH("9", strPersonal,this.intPeriodo, this.intEjercicio, this.strConexion).subscribe(
        result =>{
            this.banderaCargando = false;
            if(result.length>0){
                console.log(result);
                this.arrayIndicadores = result;
                let resultado = result[0]

                if(resultado!='' && resultado!=null && resultado!=undefined){
                    //console.log(result);
                    this.strPrestamosPendientes = resultado.strPrestamosPendientes;
                    this.strIDVacacionesPendientes = resultado.strIDVacacionesPendientes;
                    this.strIDPermisosPendientes = resultado.strIDPermisosPendientes;
                    this.strDiasVacacionesPendientes = resultado.strDiasVacacionesPendientes;
                    this.dblTotalPorPagar = resultado.dblTotalPorPagar;
                    this.strFechaAniversario = resultado.strFechaAutorizacionVacaciones;
                }
            }else{
                this.arrayVacaciones = result;
            }
        },
        error =>{
          console.log("Error")
          /*this._message.error('Error', <any>error, {
              position: ['bottom', 'right'],
              timeOut: 5000,
              animate: 'fade',
              showProgressBar: true
          });*/
        }
    );
  }


  AccionesPermiso(accion, IDMovimiento, GoceSueldo, datos){
    this.accionstore="3"
    this.strAccion = accion;
    this.IDMovimiento = IDMovimiento;
    this.goceSueldo = GoceSueldo;
    this.arrayDatos = datos;
    
    //this.modal.open(contenidoMotivo,{size:'lg', scrollable: true, centered: true});

    let modalcontenido = new  bootstrap.Modal(document.getElementById("contenidoMotivo"));
    modalcontenido.show()
  }


  AutorizaMov(){
    //console.log(this.accionstore);
    if (this.motivo!="" || this.accionstore == "4"){
 

      this._movimientosRHService.set_MovimientosRH(this.accionstore, this.IDMovimiento, this.localSt.retrieve('arrayAccesos')[0].strUsuario,this.strAccion, this.goceSueldo, this.motivo, this.strConexion ).subscribe(
          result =>{
              if(result.length>0){

                  this.motivo = "";
                  let modalcontenido = new  bootstrap.Modal(document.getElementById("contenidoMotivo"));
                  modalcontenido.hide()
                  this.ProcesarReporte();
                  /*
                  this._message.success('Correcto', 'Movimiento realizado correctamente.', {
                      position: ['bottom', 'right'],
                      timeOut: 2000,
                      animate: 'fade',
                      showProgressBar: true
                  });
                  */
                  
              }else{
                  console.log("Error");
                  /*
                  this._message.error('Error', 'Al realizar el movimiento.', {
                      position: ['bottom', 'right'],
                      timeOut: 2000,
                      animate: 'fade',
                      showProgressBar: true
                  });
                  */
              }
          },
          error =>{
              console.log("Error");
              /*
              this._message.error('Error', <any>error, {
                  position: ['bottom', 'right'],
                  timeOut: 5000,
                  animate: 'fade',
                  showProgressBar: true
              }); 
              */
          }
      );
    }else{
        console.log("Error");
        /*this._message.warn('Alerta', 'Debe ingresar el motivo para finalizar el movimiento.', {
            position: ['bottom', 'right'],
            timeOut: 2000,
            animate: 'fade',
            showProgressBar: true
        });
        */
    }
  }

  AccionesVacaciones(accion, IDMovimiento, datos){
    console.log("Entro")
    this.strAccion = accion;
    this.IDMovimiento = IDMovimiento;
    this.goceSueldo = "";
    this.arrayDatos = datos;
    
    console.log(this.arrayDatos);

    if(accion=="AUTORIZADO"){
        this.accionstore="4"
        this.AutorizaMov();
    }else{
        this.accionstore="10"
        
        let modalcontenido = new  bootstrap.Modal(document.getElementById("contenidoMotivo"));
        modalcontenido.show()
    }   
  }

  ReversaVacaciones(vacaciones){
    //console.log(vacaciones);

    this._movimientosRHService.set_Vacaciones("11", this.localSt.retrieve('arrayAccesos')[0].strUsuario, vacaciones.strSSN, vacaciones.strFechaSolicitudInicio, vacaciones.strFechaSolicitudFin,vacaciones.strDiasPendientes, '', this.strConexion).subscribe(
        result =>{
            
            if(result.length>0){
                
                //console.log(result)

                let resultado = result[0].strIDVacaciones;
                
                if (resultado!=null && resultado!=null && resultado!=undefined){
                    /*this._message.success('Correcto', 'Se dio reversa a la solicitud', {
                        position: ['bottom', 'right'],
                        timeOut: 2000,
                        animate: 'fade',
                        showProgressBar: true
                    });*/
                    console.log("Correcto");
                    this.ProcesarVacaciones();
                }else{
                    console.log("Error");
                    /*this._message.error('Error', 'Al cancelar la solicitud de Vacaciones', {
                        position: ['bottom', 'right'],
                        timeOut: 2000,
                        animate: 'fade',
                        showProgressBar: true
                    });*/
                }

                
            }else{
                console.log("Error");
                /*this._message.error('Error', 'Al cancelar la solicitud de Vacaciones', {
                    position: ['bottom', 'right'],
                    timeOut: 2000,
                    animate: 'fade',
                    showProgressBar: true
                });*/
            }
        },
        error =>{
            /*this._message.error('Error', 'Al cancelar la solicitud de Vacaciones', {
                position: ['bottom', 'right'],
                timeOut: 2000,
                animate: 'fade',
                showProgressBar: true
            });*/
        }
    );
  }


  AbrirModalPermiso(){
    let modalcontenido = new  bootstrap.Modal(document.getElementById("contenidoNuevoPermiso"));
    modalcontenido.show()
  }

  CambioMotivo(event){
    console.log('event: ', event);
    const value = event.value;

    console.log('value: ', value);
    
    if (value == "otro"){    
        
        this.permisoForm.setControl('otroMotivo', new FormControl('',[Validators.required,Validators.maxLength(200)])); 
        this.banderaPermisoOtro = true;
    }else{
        this.permisoForm.setControl('otroMotivo', new FormControl('')); 
        this.banderaPermisoOtro = false;
    }
  }

  save(event: Event) {
    event.preventDefault();
    const value = this.permisoForm.value;
    //console.log(value);

    //console.log(value.fechaPermiso);

    if(value.motivoPermiso == "otro"){
        value.motivoPermiso = value.otroMotivo;
    }

    
    //console.log(value.fechaPermiso)
    //console.log(value.fecha)
    if(Date.parse(value.fechaPermiso) < Date.parse(this.fecha) ){
        Swal.fire({
          position: "bottom-end",
          icon: "error",
          title: 'Al ingresar el permiso, fecha minima ' + this.fecha,
          showConfirmButton: false,
          timer: 1500
        });
    }else{
         
        this._movimientosRHService.set_Permiso("6", this.localSt.retrieve('arrayAccesos')[0].strUsuario, this.localSt.retrieve('arrayAccesos')[0].strSSN, value.fechaPermiso, value.motivoPermiso, value.horaInicio, value.horaFin, value.goceSueldo, this.strConexion).subscribe(
            result =>{
                if(result.length>0){
                    //console.log(result);
                    let error =  result[0].strEstatus;

                    if(error=='Error'){
                        Swal.fire({
                          position: "bottom-end",
                          icon: "error",
                          title: 'Enviamos el permiso. Permitidos:2 Ingresados:' + result[0].strIDPermiso,
                          showConfirmButton: false,
                          timer: 1500
                        });
                    }else{
                        
                        if(result[0].strIDPermiso!=""){
                            this.buildForm();
                            //this.modal.dismissAll();
                            this.banderaPermisoOtro = false;
                            
                            let modalcontenido = new  bootstrap.Modal(document.getElementById("contenidoNuevoPermiso"));
                            modalcontenido.hide()
                            Swal.fire({
                              position: "bottom-end",
                              icon: "success",
                              title: 'Se inserto el permiso de forma correcta.',
                              showConfirmButton: false,
                              timer: 1500
                            });
                            this.ProcesarPermisos();

                                

                        }else{
                          //console.log("Error")
                          Swal.fire({
                            position: "bottom-end",
                            icon: "error",
                            title: 'Al ingresar el permiso, fecha minima ' + this.fecha,
                            showConfirmButton: false,
                            timer: 1500
                          });
                        }
                    }
                }else{
                  Swal.fire({
                    position: "bottom-end",
                    icon: "error",
                    title: 'Al ingresar el permiso.',
                    showConfirmButton: false,
                    timer: 1500
                  });
                  
                }
            },
            error =>{
              Swal.fire({
                position: "bottom-end",
                icon: "error",
                title: 'Al ingresar el permiso.',
                showConfirmButton: false,
                timer: 1500
              }); 
            }
        );
    }
  }

  private buildForm() {
    if (parseFloat(this.Periodo)<10){
        this.Periodo = 0 + String(this.Periodo)
    }

    if (parseFloat(this.Dia)<10){
        this.Dia = 0 + String(this.Dia)
    }

    this.fecha = this.Ejercicio + '-' + this.Periodo + '-' + this.Dia ;

    let fechaActual = this.fecha;
    var fecha = new Date();

    fecha.setDate(fecha.getDate() + 1);

    if (parseFloat(this.Periodo)<10){
        this.Periodo = 0 + String(this.Periodo)
    }

    let MesFecha = String(fecha.getMonth() + 1);
    if (fecha.getMonth() + 1<10){
        MesFecha = 0 + String(MesFecha)
    }

    let DiaFecha = String(fecha.getDate());
    if (fecha.getDate()<10){
        DiaFecha = 0 + String(DiaFecha)
    }

    this.fecha = fecha.getFullYear() + '-' + (MesFecha) + '-' + DiaFecha
    
    
    this.permisoForm = new FormGroup({
      fecha: new FormControl(fechaActual, [Validators.required]),
      fechaPermiso: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      motivoPermiso: new FormControl('', [Validators.required]),
      horaInicio: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      horaFin: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      goceSueldo: new FormControl('', [Validators.required]),
      otroMotivo: new FormControl('',)
    });

    /*this.permisoForm.valueChanges
    .pipe(
        debounceTime(500)
    )
    .subscribe(value => {
        //console.log(value);
    });*/
  }


  private buildFormVacaciones() {

    if (parseFloat(this.Periodo)<10){
        this.Periodo = 0 + String(this.Periodo)
    }

    if (parseFloat(this.Dia)<10){
        this.Dia = 0 + String(this.Dia)
    }

    let fechaActual = this.Ejercicio + '-' + this.Periodo + '-' + this.Dia ;
    
    this.vacacionesForm = new FormGroup({
      fechaRegistro: new FormControl(fechaActual, [Validators.required]),
      DiasVigentes: new FormControl(0, [Validators.required]),
      fechaInicioVacaciones: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      fechaFinVacaciones: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      diasSolicitados: new FormControl('',)
    });

    /*this.permisoForm.valueChanges
    .pipe(
        debounceTime(500)
    )
    .subscribe(value => {
        //console.log(value);
    });*/
  }

  MotivosPermisos(){
    this._movimientosRHService.get_MovimientosRH("5","",this.intPeriodo, this.intEjercicio, this.strConexion).subscribe(
        result =>{
            if(result.length>0){
                this.arrayMotivoPermisos = result
                //console.log(this.arrayMotivoPermisos);
            }
        },
        error =>{
            console.log("Entro")
            Swal.fire({
              position: "bottom-end",
              icon: "error",
              title: 'Al consultar los motivos de permisos.',
              showConfirmButton: false,
              timer: 1500
            }); 
        }
    );

  }

  cerrarModal(){
    let modalcontenido = new  bootstrap.Modal(document.getElementById("contenidoNuevoPermiso"));
    modalcontenido.hide()
  }

  AbrirModalVacaciones(){

    this.banderaDias = 0;
        //LLAMAMOS LA API DE CUANTOS DÍAS VACACIONALES DISPONDRIA
        //console.log(this.DatosUsuario.strSSN);
        this._movimientosRHService.get_MovimientosRH("7",this.localSt.retrieve('arrayAccesos')[0].strSSN,this.intPeriodo, this.intEjercicio, this.strConexion).subscribe(
            result =>{
                if(result.length>0){
                    this.arrayFeriados = result;
                    //console.log(this.arrayFeriados);
                    if(result[0].intDiasVacacionales>0){
                        this.ctrlDiasVacacionales = result[0].intDiasVacacionales
                        this.vacacionesForm.setControl('DiasVigentes', new FormControl(this.ctrlDiasVacacionales,[Validators.required,Validators.maxLength(5)])); 
                        let modalcontenido = new  bootstrap.Modal(document.getElementById("contenidoNuevoVacaciones"));
                        modalcontenido.show()
                    }else{
                      Swal.fire({
                        position: "bottom-end",
                        icon: "error",
                        title: 'No cuentas con días disponibles para tomar.',
                        showConfirmButton: false,
                        timer: 1500
                      }); 
                        
                    }
                    
                }else{
                  Swal.fire({
                    position: "bottom-end",
                    icon: "error",
                    title: 'No cuentas con días disponibles para tomar.',
                    showConfirmButton: false,
                    timer: 1500
                  }); 
                }
            },
            error =>{
              Swal.fire({
                position: "bottom-end",
                icon: "error",
                title: 'Al consultar los días disponibles.',
                showConfirmButton: false,
                timer: 1500
              }); 
            }
        );

    
  }

  CambioFecha(){
    //console.log("Entro")
    this.banderaDias=0;
  }

  calcularVacaciones(event: Event) {
    this.ctrlDiasSolicitados = 0;
    
    event.preventDefault();
    const value = this.vacacionesForm.value;
    //console.log(value);


    if(Date.parse(value.fechaInicioVacaciones)>=Date.parse(this.fecha) 
    && Date.parse(value.fechaFinVacaciones)>=Date.parse(this.fecha)
    && Date.parse(value.fechaFinVacaciones)>=Date.parse(value.fechaInicioVacaciones))
    {
        //DECLARAMOS EL IDIOMA PARA LAS FECHAS
        moment.locale('es');

        //SACAMOS LA DIFERENCIA DE DIAS ENTRE LOS SOLICITADOS       
        let start = moment(value.fechaInicioVacaciones);
        let end = moment(value.fechaFinVacaciones);
        let difDias = moment.duration(end.diff(start)).asDays();
        //console.log(difDias);
        this.fechaIncremento = moment(value.fechaInicioVacaciones);
        this.DiaFecha =  moment(this.fechaIncremento).format('dddd');
        this.fechaIncremento = moment(value.fechaInicioVacaciones).format('DD/MM/YYYY');
        
        //RECORREMOS EN UN CICLO PARA INCREMENTAR LOS DÍAS SOLICITADOS EN BASE EL RANGO DE FECHAS.
        let i: number = 1;
        while (i <= difDias + 1) {
            //console.log(this.fechaIncremento);
            //console.log(this.DiaFecha);    
            let FechaFestiva = this.BuscarArreglo(this.fechaIncremento);
            //console.log(FechaFestiva);

            if (this.DiaFecha!="domingo" && FechaFestiva =="" ){
                this.ctrlDiasSolicitados = this.ctrlDiasSolicitados + 1;
            }
            this.AumentaDia(value.fechaInicioVacaciones,i);
            
            i++;
        }

        //console.log(this.ctrlDiasSolicitados);
    
        this.vacacionesForm.setControl('diasSolicitados', new FormControl(this.ctrlDiasSolicitados,[Validators.required,Validators.maxLength(200)])); 

        if(this.ctrlDiasVacacionales>=this.ctrlDiasSolicitados){
            this.banderaDias = 3;
        }else{
            this.banderaDias = 2;
        }
    }
    
    else{
        this.banderaDias = 1;
        Swal.fire({
          position: "bottom-end",
          icon: "error",
          title: 'Fecha Invalida, solo puede ingresar fechas actuales o futuras.',
          showConfirmButton: false,
          timer: 1500
        }); 
        
    }        
  }

  BuscarArreglo(fecha){
    let fechaEvaluar = fecha;
    //console.log(fechaEvaluar);
    //console.log(this.arrayFeriados);
    let found = this.arrayFeriados.find(element => String(element.strFechaFestiva) == String(fechaEvaluar));

    if (found==undefined){
        found = "";
    }
    //console.log(found);
    return found;
  }

  AumentaDia(fecha, incremento){

    this.fechaIncremento = moment(fecha);
    this.fechaIncremento = moment(this.fechaIncremento).add(incremento, 'days');
    this.fechaIncremento = moment(this.fechaIncremento);
    this.DiaFecha =  moment(this.fechaIncremento).format('dddd');
    this.fechaIncremento = this.fechaIncremento.format('DD/MM/YYYY');
    
    //console.log(this.fechaIncremento)
  }

  saveVacaciones(event: Event) {
    this.ctrlDiasSolicitados = 0;
    
    event.preventDefault();
    const value = this.vacacionesForm.value;
    //console.log(value);


    if(Date.parse(value.fechaInicioVacaciones)>=Date.parse(this.fecha) 
    && Date.parse(value.fechaFinVacaciones)>=Date.parse(this.fecha)
    && Date.parse(value.fechaFinVacaciones)>=Date.parse(value.fechaInicioVacaciones))
    {
        //DECLARAMOS EL IDIOMA PARA LAS FECHAS
        moment.locale('es');

        //SACAMOS LA DIFERENCIA DE DIAS ENTRE LOS SOLICITADOS       
        let start = moment(value.fechaInicioVacaciones);
        let end = moment(value.fechaFinVacaciones);
        let difDias = moment.duration(end.diff(start)).asDays();
        //console.log(difDias);
        this.fechaIncremento = moment(value.fechaInicioVacaciones);
        this.DiaFecha =  moment(this.fechaIncremento).format('dddd');
        this.fechaIncremento = moment(value.fechaInicioVacaciones).format('DD/MM/YYYY');

        //RECORREMOS EN UN CICLO PARA INCREMENTAR LOS DÍAS SOLICITADOS EN BASE EL RANGO DE FECHAS.
        let i: number = 1;
        while (i <= difDias + 1) {
            //console.log(this.fechaIncremento);
            //console.log(this.DiaFecha);    
            let FechaFestiva = this.BuscarArreglo(this.fechaIncremento);
            //console.log(FechaFestiva);

            if (this.DiaFecha!="domingo" && FechaFestiva =="" ){
                this.ctrlDiasSolicitados = this.ctrlDiasSolicitados + 1;
            }
            this.AumentaDia(value.fechaInicioVacaciones,i);
            
            i++;
        }

        //console.log(this.ctrlDiasSolicitados);
    
        this.vacacionesForm.setControl('diasSolicitados', new FormControl(this.ctrlDiasSolicitados,[Validators.required,Validators.maxLength(200)])); 

        if(this.ctrlDiasVacacionales>=this.ctrlDiasSolicitados){
            this.banderaDias = 4;
            
            this._movimientosRHService.set_Vacaciones("8", this.localSt.retrieve('arrayAccesos')[0].strUsuario, this.localSt.retrieve('arrayAccesos')[0].strSSN, moment(value.fechaInicioVacaciones).format('DD/MM/YYYY'), moment(value.fechaFinVacaciones).format('DD/MM/YYYY'),value.DiasVigentes, value.diasSolicitados, this.strConexion).subscribe(
                result =>{
                    console.log(result);
                    if(result.length>0){
                        if(result[0].strIDVacaciones!=""){

                            this.buildFormVacaciones();
                            Swal.fire({
                              position: "bottom-end",
                              icon: "success",
                              title: 'Se inserto la solicitud de Vacaciones correctamente.',
                              showConfirmButton: false,
                              timer: 1500
                            }); 
                            this.ProcesarVacaciones();
    
                            
    
                        }else{
                          Swal.fire({
                            position: "bottom-end",
                            icon: "error",
                            title: 'Al ingresar la solicitud de Vacaciones',
                            showConfirmButton: false,
                            timer: 1500
                          }); 
                            
                        }
                    }else{
                      Swal.fire({
                        position: "bottom-end",
                        icon: "error",
                        title: 'Al ingresar la solicitud de Vacaciones',
                        showConfirmButton: false,
                        timer: 1500
                      }); 
                        
                    }
                },
                error =>{
                  Swal.fire({
                    position: "bottom-end",
                    icon: "error",
                    title: 'Al insertar la Vacaciones',
                    showConfirmButton: false,
                    timer: 1500
                  }); 
                    
                }
            );
            
        }else{
            this.banderaDias = 2;
        }
    }
    
    else{
      this.banderaDias = 1;
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: 'Fecha Invalida, solo puede ingresar fechas actuales o futuras.',
        showConfirmButton: false,
        timer: 1500
      }); 
    }        
  }
}
