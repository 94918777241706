<div class="container-fluid bg-white">
    <div class="row">
        <div class="col-12 px-0">
            <img [src]="'assets/partner/info/ban3.jpg'" class="w-100" alt="">
        </div>
    </div>
    <div class="row justify-content-center my-5">
        <div class="col-10 col-md-6 nunito text-center fs-5">

            <span class="blueS fst-italic fs-5">
                Conecta con <span class="fw-bold redE">las mejores marcas</span> para impulsar tu negocioy lograr un crecimiento sin límites
            </span>
        </div>
    </div>
    <div class="row bgcardEmp">
        <div class="col-12">
            <h1 class="fs-2 text-center blueS mb-0 py-4">
                SOMOS EL ALIADO PERFECTO PARA:
            </h1>
        </div>
        <div class="col-6 col-md-3 borderLin">
            <img [src]="'assets/partner/info/LP1.jpg'" class="w-100 " alt="">
        </div>
        <div class="col-6 col-md-3 borderLin">
            <img [src]="'assets/partner/info/LP2.jpg'" class="w-100 " alt="">
        </div>
        <div class="col-6 col-md-3 borderLin">
            <img [src]="'assets/partner/info/LP3.jpg'" class="w-100 " alt="">
        </div>
        <div class="col-6 col-md-3">
            <img [src]="'assets/partner/info/LP4.jpg'" class="w-100" alt="">
        </div>
        <div class="col-12 text-center my-4">
            <span class="blueS  fs-4 d-none d-md-block">
                QUE DESEEN<span class=" redE"> INTEGRAR PRODUCTOS DE LIMPIEZA </span>A SU OFERTA COMERCIAL
            </span>
            <span class="blueS  fs-6 d-md-none">
                QUE DESEEN<span class=" redE"> INTEGRAR PRODUCTOS DE LIMPIEZA </span>A SU OFERTA COMERCIAL
            </span>
        </div>
    </div>
    <div class="row justify-content-center py-5">
        <div class="col-10 col-md-5 nunito text-center fs-5 blueS fst-italic fw-bold">
            Ponemos a disposición del 
            <span class="redE fw-bold"> "Partner" </span>
              un catalogo de más de
              <span class="redE fw-bold"> 7,000 </span> productos de limpieza.
        </div>
    </div>
    <div class="row bgcardEmp">
        <div class="col-12 col-md-6 px-0 d-flex">
            <img [src]="'assets/partner/info/ban1.jpg'" class="w-100 imalt"  alt="">
        </div>
        <div class="col-12 col-md-6  d-flex flex-column">
            <img [src]="'assets/partner/info/Landig page 5.png'" class="w-100 imalt"  alt="">
        </div>
    </div>
    <div class="row bgcarfoot py-4">
        <div class="col-12 ">
            <h1 class="nunito fs-1 text-center fw900">
                ¡Juntos comencemos algo <span class="clsred">grande</span>!
            </h1>
        </div>
        <div class="col-12 d-flex justify-content-center mt-2">
            <button class="btn btnInfo fw-bold px-3 fs-5" [routerLink]="['/partner-register']">
                Registrarme
            </button>
        </div>
    </div>
</div>