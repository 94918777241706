<div class="container">

    <div class="row">
        <div class="col-12 text-center">
            <h1 class="fw-bold my-3">
                INFORMACIÓN NACIONAL <span class="bgtittle">DE VENTAS</span> 
            </h1>
        </div>
    </div>

    <div class="row bg-white bordertop borderbott shadow-sm pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
          <span class="material-symbols-outlined ">
              content_paste_search
          </span>
          <span class="fw-bold ">
              Filtros
          </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
            <mat-select [(ngModel)]="strGerente" class="" (selectionChange)="fnSelects()">
              <mat-option   value=""> --Todos --</mat-option> 
             <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente,'col-xl-4':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
            <mat-select [(ngModel)]="strSucursal" class="" (selectionChange)="fnSelects()">
              <mat-option value=""> --Todas --</mat-option> 
             <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
     
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente,'col-xl-4':boolSucursal && !boolGerente}">
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
            <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
            <mat-select [(ngModel)]="intPeriodo" class="" (selectionChange)="fnSelects()">
             <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" >
          <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
              <mat-select [(ngModel)]="intEjercicio" (selectionChange)="fnSelects()">
                  <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                      {{e.ejercicio}}
                  </mat-option> 
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
          <button class="btn btnFilter shadow-sm px-2 py-1"  (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
              <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}" (click)="fnexecuteFilt();"></i><!-- (click)="fnGetPlantillas()" -->
          </button>
        </div>
      </div>

    <!-- <div class="row shadow bordertop borderbott d-flex align-items-center justify-content-center">
        <div class="row px-0">
            <div class="col-12 mx-0 bgEscl3 bordertop d-flex align-items-center">
                 <i class="bi bi-funnel fs-5 fw-bold my-2 ms-3 text-white"></i>
            </div>
        </div>
        <div class="row  py-4 bg-white borderbott justify-content-evenly">
            <div class="col-11">
                <div class="row">
                    <div class="col-12 col-sm-6 col-xl-3 text-center text-center" *ngIf="boolGerente">
                        <mat-form-field appearance="outline" class="w-100 bg-white rounded">
                            <mat-label>Gerente</mat-label>
                            <mat-select [(ngModel)]="strGerente" (selectionChange)="fnSelects()">
                                <mat-option  value="">
                                    --Todos--
                                </mat-option>
                                <mat-option *ngFor="let ger of arrayGerentes" [value]="ger.strGerente">
                                    {{ger.strNombre}}
                                </mat-option>
                            </mat-select>
                            <i class="bi bi-person-gear fs-4 clsicon" matSuffix></i>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-3 text-center" *ngIf="boolSucursal" >
                        <mat-form-field appearance="outline" class="w-100 bg-white rounded">
                            <mat-label>Sucursal</mat-label>
                            <mat-select [(ngModel)]="strSucursal" (selectionChange)="fnSelects()">
                                <mat-option  value="">
                                    --Todas--
                                </mat-option>
                                <mat-option *ngFor="let suc of arraySucursales | sucursalFilter:strGerente " [value]="suc.strSucursal">
                                    {{suc.strNombreSucursal}}
                                </mat-option>
                            </mat-select>
                            <i class="bi bi-building-gear fs-4 clsicon" matSuffix></i>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-3 text-center   mt-3 mt-xl-0" >
                        <mat-form-field appearance="outline" class="w-100 bg-white rounded">
                            <mat-label>Periodo</mat-label>
                            <mat-select [(ngModel)]="intPeriodo" (selectionChange)="fnSelects()">
                                <mat-option  value="">
                                    --Todos--
                                </mat-option>
                                <mat-option *ngFor="let per of arrayPeriodo" [value]="per.strPeriodo">
                                    {{per.strNombre}}
                                </mat-option>
                            </mat-select>
                            <i class="bi bi-calendar-month fs-4 clsicon" matSuffix></i>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-3 text-center  mt-3 mt-xl-0" >
                        <mat-form-field appearance="outline" class="w-100 bg-white rounded">
                            <mat-label>Ejercicio</mat-label>
                            <mat-select [(ngModel)]="intEjercicio" (selectionChange)="fnSelects()">
                                <mat-option  value="">
                                    --Todos--
                                </mat-option>
                                <mat-option *ngFor="let eje of arrayEjercicio" [value]="eje.ejercicio">
                                    {{eje.ejercicio}}
                                </mat-option>
                            </mat-select>
                            <i class="bi bi-calendar-date fs-4 clsicon" matSuffix ></i>
                        </mat-form-field>
                    </div>
                </div>
            </div>
           <div class="col-12 col-md-1 d-flex align-items-center">
            <div class="col-12 text-center mt-3 mt-md-0 ">
                <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false" (click)="fnexecuteFilt();">
                    <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
                </button>
            </div>
           </div>
          
        </div>
    </div> -->
    <div class="row justify-content-between mt-4">
        <div class="col-12 col-lg-4 ">
            <div class="row borderbott shadow bordertop justify-content-center bg-white">
                <div class="col-12 text-center bg-arena bordertop">
                    <h2 class="mb-0 my-1 text-dark">Inf. Nacional Ventas. <i class="bi bi-globe text-dark"></i></h2>
                </div>
                <div class="row"  *ngIf="spinerInfVentas"  ><!-- *ngIf="spinerInfVentas" -->
                    <div class="col-12 ">
                        <mat-spinner class="mx-auto " style="font-size: 10px !important;"></mat-spinner>
                    </div>
                </div>
                <div class="col-12 borderbott" *ngIf="!spinerInfVentas" >
                    <div class="row  align-items-center border-bottom border-top">
                        <div class="col-6 text-center backcl3 text-white ">
                            <span class="fs10 fw-bold">VENTA AL DIA DE HOY NIVEL NACIONAL</span>
                        </div>
                        <div class="col-6 text-center" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('infNaVtas')">
                          
                            <span class="fs-5 clsvtanacional fw-bold fmnunito">{{intVentaDia|currency}}</span>
                        </div>
                    </div>
                    <div class="row align-items-center border-bottom">
                        <div class="col-6 text-center backcl2 py-2">
                            <span class="fs10 fw-bold text-white">COSTO</span>
                        </div>
                        <div class="col-6 text-center">
                           <span class="fs-6"> {{intCosto|currency}}</span>
                        </div>
                    </div>
                    <div class="row"  [ngClass]="{'border-bottom':strAcceso== '1' || strAcceso == '2','borderbottL borderbottR':strAcceso != '1' && strAcceso != '2' }">
                        <div class="col-6 justify-content-center backcl1 align-items-center d-flex" [ngClass]="{'borderbottL ':strAcceso != '1' && strAcceso != '2'}">
                            <span class="fs10 fw-bold text-white align-middle">MARGEN</span>
                        </div>
                        <div class="col-6 text-center borderbottL py-2 " >
                           <span class="fs-6"  [ngClass]="{'text-success':intMargen>90,'text-warning':intMargen<=90 && intMargen>50,'text-danger':intMargen<=50 && intMargen>0}"> {{intMargen | number:'1.2-2'}}%</span>
                           <div class="progress " style="height: 5px;">
                            <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="intMargen"  [ngClass]="{'bg-success':intMargen>90,'bg-warning':intMargen<=90 && intMargen>50,'bg-danger':intMargen<=50 && intMargen>0}"></div>
                        </div>
                        </div>
                    </div>
                    <div class="row"  [ngClass]="{'borderbottL borderbottR':strAcceso== '1' || strAcceso == '2'}" *ngIf="['1', '2'].includes(strAcceso)">
                        <div class="col-6 text-center backcl1 justify-content-center borderbottL align-items-center d-flex">
                            <span class="fs10 fw-bold text-white ">SALDO BANCO</span>
                        </div>
                        <div class="col-6 text-center borderbottL py-2">
                           <span class="fs-6"> {{intSaldoBanco|currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-8  mt-3 mt-lg-0">
            <div class="col-12 col-lg-11 ms-lg-auto px-0">
                <div class="row borderbott shadow bordertop">
                <div class="col-12 text-center bgEscl1 bordertop">
                    <h2 class="mb-0 my-1 text-white"><i class="bi bi-person-plus"></i>Tipo Clientes</h2>
                </div>
                <div class="row"  *ngIf="spinerTipoClientes"  ><!-- *ngIf="spinerInfVentas" -->
                    <div class="col-12 ">
                        <mat-spinner class="mx-auto " style="font-size: 10px !important;"></mat-spinner>
                    </div>
                </div>
                <div class="col-12  px-0 table-responsive mb-0" *ngIf="!spinerTipoClientes">
                    <table class="table ">
                        <thead>
                            <tr class=" ">
                                <th class="text-center border-dark border-end fs14 bg-gris">Clientes</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Año</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Ene</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Feb</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Mar</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Abr</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">May</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Jun</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Jul</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Ago</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Sep</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Oct</th>
                                <th class="text-center border-dark border-end fs14 bg-amarillo">Nov</th>
                                <th class="text-center bg-amarillo">Dic</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let x of arraycte4500;let last = last;">
                                <td class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12 text-nowrap" >+ $7,500.00</td>
                                <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,1,'7500');" [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,2,'7500'); " [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,3,'7500'); " [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,4,'7500'); " [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,5,'7500'); " [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,6,'7500'); " [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,7,'7500'); " [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,8,'7500'); " [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,9,'7500'); " [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,10,'7500'); " [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,11,'7500'); " [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span></td>
                                <td class=" text-center fs12" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,12,'7500')" [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}"><span>{{x.intDiciembre}}</span></td>
                            </tr>
                            <tr  *ngFor="let x of arrayTipoCliente;let last = last;">
                                <td class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12" >{{x.strTipo}}</td>
                                <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,1,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,2,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,3,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,4,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,5,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,6,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,7,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,8,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,9,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,10,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,11,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span></td>
                                <td class=" text-center fs12" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,12,x.strTipo)" [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}"><span>{{x.intDiciembre}}</span></td>
                            </tr>
                            <tr  *ngFor="let x of arrayCteNuevos;let last = last;">
                                <td class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12" [ngClass]="{'borderbottL border-bottom-0': last }">{{x.strTipo}}</td>
                                <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,1,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,2,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,3,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,4,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,5,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,6,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,7,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,8,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,9,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,10,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span></td>
                                <td class="border-end text-center fs12"><span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,11,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span></td>
                                <td class=" text-center fs12" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('tipoCte',x,12,x.strTipo)" [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}"><span>{{x.intDiciembre}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row my-4 shadow bordertop borderbott">
        <ul class="nav nav-tabs bg-azulF bordertop border-bottom" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active ms-3 mt-2 fw-bold " id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Clientes por linea.</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link mt-2 fw-bold" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Cartera Rezagada</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link mt-2 fw-bold" id="profile-tab" data-bs-toggle="tab" data-bs-target="#cuentasPagar" type="button" role="tab" aria-controls="profile" aria-selected="false">Cuentas Por Pagar</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link mt-2 fw-bold" id="profile-tab" data-bs-toggle="tab" data-bs-target="#diasInventario" type="button" role="tab" aria-controls="profile" aria-selected="false">Días de Inventario(Distribución y Compras)</button>
              </li>
        </ul>
        <div class="tab-content borderbott bg-white px-0" id="myTabContent">
            <!-- clientes por linea -->
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row"  *ngIf="spinerNavTabs"  ><!-- *ngIf="spinerInfVentas" -->
                    <div class="col-12 ">
                        <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                    </div>
                </div>
                <div class="col-12  px-0 table-responsive mb-0"  *ngIf="!spinerNavTabs" >
                    <table class="table  table-hover">
                        <thead>
                            <tr class="bg-gris">
                                <th class="fs14 border-end text-center border-dark">LINEA</th>
                                <th class="fs14 border-end text-center border-dark">Año</th>
                                <th class="fs14 border-end text-center border-dark">Ene</th>
                                <th class="fs14 border-end text-center border-dark">Feb</th>
                                <th class="fs14 border-end text-center border-dark">Mar</th>
                                <th class="fs14 border-end text-center border-dark">Abr</th>
                                <th class="fs14 border-end text-center border-dark">May</th>
                                <th class="fs14 border-end text-center border-dark">Jun</th>
                                <th class="fs14 border-end text-center border-dark">Jul</th>
                                <th class="fs14 border-end text-center border-dark">Ago</th>
                                <th class="fs14 border-end text-center border-dark">Sep</th>
                                <th class="fs14 border-end text-center border-dark">Oct</th>
                                <th class="fs14 border-end text-center border-dark">Nov</th>
                                <th class="fs14 border-end text-center border-dark">Dic</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let x of arrayClientesPorLinea;let last = last;">
                                <td class="bg-gris border-dark border-top-0 fs14 border-start-0 border-end fw-bold" [ngClass]="{'borderbottL border-bottom-0': last }">{{x.strLinea}}</td>
                                <td class="fs12 border-end text-center">{{currentYear}}</td>
                                <td class="fs12 border-end text-center">
                                    <span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,1)"[ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}" >
                                        {{x.intEnero}}
                                    </span>
                                </td>
                                <td class="fs12 border-end text-center">
                                    <span [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,2)" >
                                        {{x.intFebrero}}
                                    </span>
                                </td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,3)" >
                                    {{x.intMarzo}}</span></td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,4)" >
                                    {{x.intAbril}}</span></td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,5)" >
                                    {{x.intMayo}}</span></td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,6)" >
                                    {{x.intJunio}}</span></td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,7)" >
                                    {{x.intJulio}}</span></td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,8)" >
                                    {{x.intAgosto}}</span></td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,9)" >
                                    {{x.intSeptiembre}}</span></td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,10)" >
                                    {{x.intOctubre}}</span></td>
                                <td class="fs12 border-end text-center"><span [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,11)" >
                                    {{x.intNoviembre}}</span></td>
                                <td class="fs12"><span [ngClass]="{'text-primary fw-bold':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ctePorLinea',x,12)" >
                                    {{x.intDiciembre}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Fin clientes por linea -->

            <!-- Cartera Rezagada -->
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="row"  *ngIf="spinerNavTabs"  ><!-- *ngIf="spinerInfVentas" -->
                    <div class="col-12 ">
                        <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                    </div>
                </div>
                <div class="col-12  px-0 table-responsive mb-0"  *ngIf="!spinerNavTabs" >
                    <table class="table  table-hover">
                        <thead >
                            <tr class="theadtab text-center">
                                <th>Tipo</th>
                                <th>Antiguedad</th>
                                <th>Objetivo</th>
                                <th>Real</th>
                                <th>Falta</th>
                                <th>Porcentaje</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let x of arrayCarteraRezagada;let last = last;">
                                <td class="border-end text-start fs12 text-dark">{{x.strTipo}}</td>
                                <td class="border-end text-end fs10 fw-bold text-primary" (click)="fnabrirModal('detCarterraRezagada',x,0,x.strTipo);" data-bs-toggle="modal" data-bs-target="#exampleModal" >{{x.dblSaldo|currency}}</td>
                                <td class="border-end text-end fs10 text-dark">{{x.dblObjetivo|currency}}</td>
                                <td class="border-end text-end fs10 text-dark">{{x.dblCobrado|currency}}</td>
                                <td class="border-end text-end fs10 text-dark">{{x.dblObjetivo - x.dblCobrado|currency}}</td>
                                <td class="border-end text-center fs10 text-dark"> <span class="fs10 fw-bold">{{  x.intPorcentaje| number:'1.2-2' }} %</span>
                                    <div class="progress" style="height: 5px;">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="x.intPorcentaje"  [ngClass]="{'bg-success':x.intPorcentaje>90,'bg-warning':x.intPorcentaje<=90 && x.intPorcentaje>50,'bg-danger':x.intPorcentaje<=50 && x.intPorcentaje>0}"></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="theadtab fw-bold borderbottL " >Total</td>
                                <td class="theadtab fw-bold text-end ">{{totalSaldo|currency}}</td>
                                <td class="theadtab fw-bold text-end">{{totalObjetivo|currency}}</td>
                                <td class="theadtab fw-bold text-end">{{totalCobrado|currency}}</td>
                                <td class="theadtab fw-bold text-end">{{totalFalta|currency}}</td>
                                <td class="theadtab fw-bold text-center borderbottR">{{totalPorcentaje | number:'1.2-2' }}%
                                    <div class="progress " style="height: 5px;">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" [ngClass]="{'bg-success':totalPorcentaje>90,'bg-warning':totalPorcentaje<=90 && totalPorcentaje>50,'bg-danger':totalPorcentaje<=50 && totalPorcentaje>0}" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="totalPorcentaje"></div>
                                      </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <!--fin Cartera Rezagada -->

            <!--  Cuentas por pagar -->
            <div class="tab-pane fade" id="cuentasPagar" role="tabpanel" aria-labelledby="cuentasPagar-tab">
                <div class="row"  *ngIf="spinerNavTabs"  ><!-- *ngIf="spinerInfVentas" -->
                    <div class="col-12 ">
                        <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                    </div>
                </div>
                <div class="col-12  px-0 table-responsive mb-0" *ngIf="!spinerNavTabs">
                    <table class="table table-hover">
                        <thead >
                            <tr class="theadtab text-center">
                                <th>Tipo</th>
                                <th class="text-nowrap">Saldo Corriente</th>
                                <th class="text-nowrap">Saldo 15 Dias</th>
                                <th class="text-nowrap">Saldo 30 Dias</th>
                                <th class="text-nowrap">Saldo 60 Dias</th>
                                <th class="text-nowrap">Saldo 90 Dias</th>
                                <th class="text-nowrap">Saldo Atrasado</th>
                                <th class="text-nowrap">Saldo Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of arrayCuentasPorpagar;let last = last;">
                                <td class="theadtab border-dark border-top-0  border-start-0 border-end fw-bold" [ngClass]="{'borderbottL border-bottom-0': last }">{{x.strTipo}}</td>
                                <td class="border-end text-end fs10" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('cuentasxpagar',x,1,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.dblCorriente>0 ||x.dblCorriente<0 ,'text-dark': x.dblCorriente==0}">{{x.dblCorriente|currency}}</td>
                                <td class="border-end text-end fs10" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('cuentasxpagar',x,15,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.dbl15>0 ||x.dbl15<0  ,'text-dark': x.dbl15==0}">{{x.dbl15|currency}}</td>
                                <td class="border-end text-end fs10" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('cuentasxpagar',x,30,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.dbl30>0 ||x.dbl30<0  ,'text-dark': x.dbl30==0}">{{x.dbl30|currency}}</td>
                                <td class="border-end text-end fs10" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('cuentasxpagar',x,60,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.dbl60>0 ||x.dbl60<0  ,'text-dark': x.dbl60==0}">{{x.dbl60|currency}}</td>
                                <td class="border-end text-end fs10" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('cuentasxpagar',x,90,x.strTipo)" [ngClass]="{'text-primary fw-bold':x.dbl90>0 ||x.dbl90<0  ,'text-dark': x.dbl90==0}">{{x.dbl90|currency}}</td>
                                <td class="border-end text-end fs10" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('cuentasxpagar',x,0,x.strTipo)"  [ngClass]="{'text-primary fw-bold':x.dblMayor>0,'text-dark': x.dblMayor==0}">{{x.dblMayor|currency}}</td>
                                <td class="border-end text-end fw-bold fs12">{{x.totalcxp |currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- fin Cuentas por pagar -->

            <!--  Dias Inventario -->
            <div class="tab-pane fade" id="diasInventario" role="tabpanel" aria-labelledby="diasInventario-tab">
                <div class="row"  *ngIf="spinerNavTabs"  ><!-- *ngIf="spinerInfVentas" -->
                    <div class="col-12 ">
                        <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                    </div>
                </div>
                <div class="col-12  px-0 table-responsive mb-0" *ngIf="!spinerNavTabs"> 
                    <table class="table table-hover">
                        <thead >
                            <tr class="theadtab text-center">
                                <th>Linea</th>
                                <th>Ideal</th>
                                <th>Real</th>
                                <th>Diferencia</th>
                                <th>Vueltas al Inventario</th>
                                <th>Dias</th>
                                <th>Ideal Dias</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let x of arrayInventarioideal;let last = last;">
                                <td class="theadtab border-dark fs12 border-top-0  border-start-0 border-end fw-bold" >{{x.strLinea}}</td>
                                <td class="border-end text-end fs12">$ {{x.dblInventarioIdeal|number:'1.2-2'}}</td>
                                <td class="border-end text-end fs12">$ {{x.dblCostoTotal|number:'1.2-2'}}</td>
                                <td class="border-end text-end fs12">$ {{x.intDiferencia |number:'1.2-2'}}</td><!--  diferencia {IIdeal.dblInventarioIdeal - IIdeal.dblCostoTotal -->
                                <td class="border-end text-center fs12">{{x.intVueltaInventario|number:'1.0-0'}}</td><!-- vueltas al inventario ((IIdeal.dblCostoTotal*IIdeal.intDias)/IIdeal.dblInventarioIdeal)  -->
                                <td class="border-end text-center "><span class="badge bg-secondary fs12">{{x.dias |number:'1.0-0'}} Dias</span> </td><!-- dias (IIdeal.dblCostoTotal * IIdeal.intDias) / IIdeal.dblInventarioIdeal -->
                                <td class="border-end text-center fs12"> {{x.intDias}}</td> 
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="theadtab fw-bold borderbottL fs12">{{objDiasInventario.strLinea}}</td>
                                <td class="theadtab fw-bold text-end fs12">$ {{objDiasInventario.Ideal|number:'1.2-2'}}</td>
                                <td class="theadtab fw-bold text-end fs12">$ {{objDiasInventario.Real|number:'1.2-2'}}</td>
                                <td class="theadtab fw-bold text-end fs12">$ {{objDiasInventario.Diferencia|number:'1.2-2'}}</td>
                                <td class="theadtab fw-bold text-center fs12">{{objDiasInventario.VueltasAlInventario|number:'1.0-0'}}</td>
                                <td class="theadtab fw-bold text-center"><span class=" fs12"> {{objDiasInventario.Dias|number:'1.0-0'}} Dias</span></td>
                                <td class="theadtab fw-bold text-center borderbottR fs12">{{objDiasInventario.IdealDias}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <!-- Fin Días inventario -->
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-12 col-md-3">
           
                <div class="row borderbott shadow bordertop justify-content-center bg-white">
                    <div class="col-12 text-center titulos bordertop">
                        <h2 class="mb-0 my-1">Días Cartera(cobranza).</h2>
                    </div>
                    <div class="row"  *ngIf="spinerDiasCart"  >
                        <div class="col-12 ">
                            <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                        </div>
                    </div>
                    <div class="col-12 borderbott" *ngIf="!spinerDiasCart">
                        <div class="row   border-bottom border-top">
                            <div class="col-6 text-center bg-gris text-dark ">
                                <span class="fs-6 fw-bold ">Saldo al Día</span>
                            </div>
                            <div class="col-6 text-center bg-gris">
                               <span class="fs-6 clsvtanacional fw-bold fmnunito text-dark">Porcentaje</span>
                            </div>
                        </div>
                        <div class="row align-items-center border-bottom ">
                            <div class="col-6 text-center   borderbottL">
                                <span class="fs12 fw-bold ">{{intDiasCartera|number:'1.0-0'}}</span>
                            </div>
                            <div class="col-6 text-center border-start py-2">
                               <span class="fs12">
                                    <i class="bi " [ngClass]="{'bi-arrow-90deg-up':intDiasCartera<=45, 'bi-arrow-90deg-down':intDiasCartera>45}"></i>
                               </span>
                            </div>
                        </div>
                      
                    </div>
                </div>

                <div class="row borderbott shadow bordertop justify-content-center bg-white mt-3">
                    <div class="col-12 text-center titulos bordertop">
                        <h2 class="mb-0 my-1">CAJAS</h2>
                    </div>
                    <div class="row"  *ngIf="spinerCajas"  >
                        <div class="col-12 ">
                            <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                        </div>
                    </div>
                    <div class="col-12 borderbott"  *ngIf="!spinerCajas" >
                        <div class="row   border-bottom border-top" *ngFor="let cj of arrayCajas ">
                            <div class="col-6 text-center bg-rojo text-light ">
                                <span class="fs-6 fw-bold">{{cj.strLinea}}</span>
                            </div>
                            <div class="col-6 text-center ">
                               <span class="fs12 fw-bold  ">{{cj.intCajas}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 px-0 ">
                                <hr class="my-2">
                            </div>
                        </div>
                        <div class="row"  *ngIf="spinerCajas"  ><!-- *ngIf="spinerInfVentas" -->
                            <div class="col-12 ">
                                <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                            </div>
                        </div>
                        <div class="row border-bottom border-top" *ngIf="!spinerCajas" >
                            <div class="col-6 text-center bg-grenb text-dark ">
                                <span class="fs14 fw-bold">COMODATOS</span>
                            </div>
                            <div class="col-6 text-center ">
                               <span class="fs12 fw-bold text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('cajaComodatos');">{{dblComodato|currency}}</span> 
                            </div>
                        </div>
                        <div class="row border-bottom border-top">
                            <div class="col-6 text-center bg-greenmin text-dark ">
                                <span class="fs14 fw-bold">COMODATOS KIMBERLY</span>
                            </div>
                            <div class="col-6 text-center ">
                               <span class="fs12 fw-bold  ">{{comodatosKimberly|currency}}</span>
                            </div>
                        </div>
                        <div class="row border-bottom border-top">
                            <div class="col-6 text-center bg-grenb text-dark ">
                                <span class="fs14 fw-bold">MOVIMIENTO DE INVENTARIO</span>
                            </div>
                            <div class="col-6 text-center ">
                               <span class="fs12 fw-bold text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('movInventario');">{{dblInventario|currency}}</span>
                            </div>
                        </div>
                        <div class="row border-bottom border-top">
                            <div class="col-6 text-center bg-greenmin text-dark ">
                                <span class="fs14 fw-bold">REMISIONES</span>
                            </div>
                            <div class="col-6 text-center ">
                               <span class="fs12 fw-bold text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('cajaRemisiones');">{{dblRemision|currency}}</span>
                            </div>
                        </div>
                        <div class="row border-bottom border-top">
                            <div class="col-6 text-center bg-grenb text-dark ">
                                <span class="fs14 fw-bold">VENTAS PERDIDAS</span>
                            </div>
                            <div class="col-6 text-center">
                               <span class="fs12 fw-bold text-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('ventasPerdidas');">{{dblImporteConFactor|currency}}</span>
                            </div>
                        </div>
                        <div class="row border-bottom border-top">
                            <div class="col-6 text-center bg-greenmin text-dark borderbottL">
                                <span class="fs14 fw-bold">COVID</span>
                            </div>
                            <div class="col-6 text-center ">
                               <span class="fs12 fw-bold  ">{{dblImporteDistintivoH|currency}}</span>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div class="col-12 col-md-9 d-flex justify-content-end">
            <div class="col-11 px-0 mx-0">
                <div class="row borderbott shadow bordertop">
                    <div class="col-12 text-center titulos bordertop">
                        <h2 class="mb-0 my-1"><i class="bi bi-card-checklist"></i> ESTADO DE RESULTADOS</h2>
                    </div>
                    <div class="row"  *ngIf="spinerTabEdoResult"  ><!-- *ngIf="spinerInfVentas" -->
                        <div class="col-12 ">
                            <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                        </div>
                    </div>
                    <div class="col-12  px-0 table-responsive mb-0"  *ngIf="!spinerTabEdoResult" >
                        <table class="table ">
                            <thead>
                                <tr class="theadtab">
                                    <th class="border-end  text-center fw-bold border-dark fs12">Periodo</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">Venta</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">UtilidadBruta</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">PorcentajeBruto</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">GastoOperacion</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">DepreciacionContable</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">PorcentajeGastos</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">ProdFinancierosOtros</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">GastosFinancierosOtros</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">ISRAnual</th>
                                    <th class="border-end text-center fw-bold border-dark fs12">UtilidadNeta</th>
                                    <th class="text-center fw-bold border-dark fs12">PorcentajeNeta</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let x of arrayEstadoResultados;let last = last;">
                                    <td class="border-end text-start fs12 border-dark" [ngClass]="{'borderbottL border-bottom-0': last }">{{x.strPeriodo}}</td>
                                    <td class="border-end text-end fs12">{{x.dblVentas|currency}}</td>
                                    <td class="border-end text-end fs12">{{x.dblUtilidadBruta |currency}}</td>
                                    <td class="border-end text-end fs12 text-center" [ngClass]="{'text-info':x.porBruto>25,'text-danger':x.porBruto<26}"><i class="bi " [ngClass]="{'bi-arrow-90deg-up text-info':x.porBruto>25,'bi-arrow-90deg-down text-danger':x.porBruto<26}"></i> {{x.porBruto|number:'1.2-2'}}% </td>
                                    <td class="border-end text-end text-primary fw-bold fs12" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnabrirModal('gastoOperacion',{},x.intPeriodo);">{{x.dblGastos |currency}}</td>
                                    <td class="border-end text-end fs12">{{x.dblDepreciacion |currency}}</td>
                                    <td class="border-end text-end fs12 text-center" [ngClass]="{'text-info':x.porGastos<12,'text-danger':x.porGastos>=12}"><i class="bi " [ngClass]="{'bi-arrow-90deg-up text-info':x.porGastos<12,'bi-arrow-90deg-down text-danger':x.porGastos>=12}"></i> {{x.porGastos|number:'1.2-2'}}% </td>
                                    <td class="border-end text-end fs12">{{x.dblProductosFinancieros |currency}}</td>
                                    <td class="border-end text-end fs12">{{x.dblOtrosGastos |currency}}</td>
                                    <td class="border-end text-end fs12">{{x.dblImpuestos |currency}}</td>
                                    <td class="border-end text-end fs12">{{x.uneta |currency}}</td>
                                    <td class="border-end text-end fs12 text-center">
                                        <span class="fs10 fw-bold">{{x.porUneta |number:'1.2-2'}}%</span>
                                        <div class="progress " style="height: 5px;">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="x.porUneta"  [ngClass]="{'bg-success':x.porUneta>90,'bg-warning':x.porUneta<=90 && x.porUneta>50,'bg-danger':x.porUneta<=50 && x.porUneta>0}"></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="theadtab">
                                    <td class="border-end border-top border-bottom-0 text-center fw-bold border-dark fs12 text-nowrap" >TOTAL {{intEjercicio}}</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" >{{objInfoTotal.dblVentas|currency}}</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" >{{objInfoTotal.dblUtilidadBruta |currency}}</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" [ngClass]="{'text-info':objInfoTotal.porBruto>25,'text-danger':objInfoTotal.porBruto<26}"><i class="bi " [ngClass]="{'bi-arrow-90deg-up text-info':objInfoTotal.porBruto>25,'bi-arrow-90deg-down text-danger':objInfoTotal.porBruto<26}"></i>{{objInfoTotal.porBruto |number:'1.2-2'}} %</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" >{{objInfoTotal.dblGastos |currency}}</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" >{{objInfoTotal.dblDepreciacion |currency}}</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12 text-center" [ngClass]="{'text-info':objInfoTotal.porGastos<12,'text-danger':objInfoTotal.porGastos>=12}"><i class="bi " [ngClass]="{'bi-arrow-90deg-up text-info':objInfoTotal.porGastos<12,'bi-arrow-90deg-down text-danger':objInfoTotal.porGastos>=12}"></i>{{objInfoTotal.porGastos|number:'1.2-2'}}%</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" >{{objInfoTotal.dblProductosFinancieros |currency}}</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" >{{objInfoTotal.dblOtrosGastos |currency}}</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" >{{objInfoTotal.dblImpuestos |currency}}</td>
                                    <td class="border-end text-center border-bottom-0 fw-bold border-dark fs12" >{{objInfoTotal.uneta |currency}}</td>
                                    <td class="text-center border-bottom-0  fw-bold border-dark fs12">
                                        <span class="fs10 fw-bold">{{objInfoTotal.porUneta |number:'1.2-2'}}%</span>
                                        <div class="progress " style="height: 5px;">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="objInfoTotal.porUneta"  [ngClass]="{'bg-success':objInfoTotal.porUneta>90,'bg-warning':objInfoTotal.porUneta<=90 && objInfoTotal.porUneta>50,'bg-danger':objInfoTotal.porUneta<=50 && objInfoTotal.porUneta>0}"></div>
                                        </div>
                                      </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="row borderbott shadow bordertop mt-3">
                    <div class="col-12 text-center titulos bordertop">
                        <h2 class="mb-0 my-1"><i class="bi bi-graph-up-arrow"></i> Mix de productos</h2>
                    </div>
                    <div class="row"  *ngIf="spinerMixProd"  ><!-- *ngIf="spinerInfVentas" -->
                        <div class="col-12 ">
                            <mat-spinner class="mx-auto "  style="font-size: 10px !important;"></mat-spinner>
                        </div>
                    </div>
                    <div class="col-12  px-0 table-responsive mb-0" *ngIf="!spinerMixProd">
                        <table class="table table-hover">
                            <thead>
                                <tr class="">
                                    <th class="fs12 border-end text-center border-dark bg-gris">LINEA</th>
                                    <th class="fs12 border-end text-center border-dark bg-amarillo" colspan="2">OBJETIVO</th>
                                    <th class="fs12 border-end text-center border-dark bg-amarillo" colspan="5">AVANCE MENSUAL</th>
                                    <th class="fs12 border-end text-center border-dark bg-amarillo" colspan="2">CUÁNTO DEBEMOS LLEVAR HOY</th>
                                </tr>
                                <tr class="theadtab">
                                    <th class="border-end text-center border-dark"></th>
                                    <th class="border-end text-center border-dark bg-amarillo">Monto</th>
                                    <th class="border-end text-center border-dark bg-amarillo">%Mix</th>
                                    <th class="border-end text-center border-dark bg-amarillo">Monto</th>
                                    <th class="border-end text-center border-dark bg-amarillo">Margen</th>
                                    <th class="border-end text-center border-dark bg-amarillo">%Margen</th>
                                    <th class="border-end text-center border-dark bg-amarillo">%Avance</th>
                                    <th class="border-end text-center border-dark bg-amarillo">%Mix</th>
                                    <th class="border-end text-center border-dark bg-amarillo">Monto</th>
                                    <th class="border-end text-center border-dark bg-amarillo">%Avance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of arrayVentaVendedor;let last = last;">
                                    <td class="theadtab fs12 fw-bold border-end">{{x.strLinea}}</td>
                                    <td class="fs12 border-end text-end">{{x.dblObjetivo|currency}}</td>
                                    <td class="text-center border-end">
                                        <span class="fs10 fw-bold">
                                            {{x.Mix1|number:'1.2-2'}}%
                                        </span>
                                        <div class="progress " style="height: 5px;">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="x.Mix1"  [ngClass]="{'bg-success':x.Mix1>90,'bg-warning':x.Mix1<=90 && x.Mix1>50,'bg-danger':x.Mix1<=50 && x.Mix1>0}">
                                            </div>
                                        </div>
                                    </td>
                                    <td class="fs12 border-end">{{x.dblImporte |currency}}</td>
                                    <td class="fs12 border-end">{{x.dblMargen |currency}}</td>
                                    <td class="text-center border-end">
                                        <span class="fs10 fw-bold">{{x.PorcentajeMargen|number:'1.2-2'}}%</span>
                                        <div class="progress " style="height: 5px;">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="x.PorcentajeMargen"  [ngClass]="{'bg-success':x.PorcentajeMargen>90,'bg-warning':x.PorcentajeMargen<=90 && x.PorcentajeMargen>50,'bg-danger':x.PorcentajeMargen<=50 && x.PorcentajeMargen>0}"></div>
                                        </div>
                                    </td>
                                    <td class="text-center border-end">
                                        <span class="fs10 fw-bold">{{x.PorcentajeAvance1|number:'1.2-2'}}%</span>
                                        <div class="progress " style="height: 5px;">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="x.PorcentajeAvance1"  [ngClass]="{'bg-success':x.PorcentajeAvance1>90,'bg-warning':x.PorcentajeAvance1<=90 && x.PorcentajeAvance1>50,'bg-danger':x.PorcentajeAvance1<=50 && x.PorcentajeAvance1>0}"></div>
                                        </div>
                                    </td>
                                    <td class="text-center border-end"> <span class="fs10 fw-bold">{{x.Mix2|number:'1.2-2'}}%</span>
                                        <div class="progress " style="height: 5px;">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="x.Mix2"  [ngClass]="{'bg-success':x.Mix2>90,'bg-warning':x.Mix2<=90 && x.Mix2>50,'bg-danger':x.Mix2<=50 && x.Mix2>0}"></div>
                                        </div>
                                    </td>
                                    <td class="fs12 border-end text-end">{{x.Monto3|currency}}</td>
                                    <td class="text-center border-end">
                                        <span class="fs10 fw-bold">{{x.PorcentajeAvance2}}%</span>
                                        <div class="progress " style="height: 5px;">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated text-center text-dark" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width.%]="x.PorcentajeAvance2"  [ngClass]="{'bg-success':x.PorcentajeAvance2>90,'bg-warning':x.PorcentajeAvance2<=90 && x.PorcentajeAvance2>50,'bg-danger':x.PorcentajeAvance2<=50 && x.PorcentajeAvance2>0}"></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="">
                                    <th class="border-end bg-gris">{{objTotalesVenta.strLinea}}</th>
                                    <th class="border-end bg-amarillo">{{objTotalesVenta.intMonto|currency}}</th>
                                    <th class="border-end bg-amarillo">{{objTotalesVenta.porMix|number:'1.0-0'}}%</th>
                                    <th class="border-end bg-amarillo">{{objTotalesVenta.intMonto2|currency}}</th>
                                    <th class="border-end bg-amarillo">{{objTotalesVenta.intMargen|currency}}</th>
                                    <th class=" bg-amarillo"><!-- {{objTotalesVenta.porMargen|number:'1.2-2'}} % --></th>
                                    <th class="bg-amarillo"><!-- {{objTotalesVenta.porAvance|number:'1.2-2'}} % --></th>
                                    <th class="border-end bg-amarillo"><!-- {{objTotalesVenta.porMix2|number:'1.2-2'}} % --></th>
                                    <th class="border-end bg-amarillo">{{objTotalesVenta.monto3|currency}}</th>
                                    <th class="text-nowrap bg-amarillo">{{objTotalesVenta.porAvanceT|number:'1.2-2'}} %</th>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header titulos py-1 px-2">
            <!--    *ngIf="spinerModal"    -->
          <h2 class="modal-title mb-0 mx-auto text-center" id="exampleModalLabel"  *ngIf="spinerModal">Cargando....</h2><br>
          <h2 class="modal-title mb-0 mx-auto" id="exampleModalLabel"  *ngIf="!spinerModal">{{ObjModal.Titulo}}</h2>
          <button type="button" class="btn-close ms-0 me-2" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 text-center">
                    <h4 class="fw-bold mb-2">
                        {{ObjModal.Subtitulo}}
                    </h4>
                </div>
            </div>
            <div class="row" *ngIf="spinerModal">
                <div class="col-12 ">
                    <mat-spinner class="mx-auto"></mat-spinner>
                </div>
            </div>
            <div class="row justify-content-center mb-3" *ngIf="ObjModal.strMdal == 'infNaVtas' && !spinerModal">
                <div class="col-12 d-flex justify-content-between mb-2">
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span>
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-11 px-0  shadow ">
                    <div class="table-responsive mb-0 borderbott bordertop">
                        <table class="table table-bordered">
                        <thead class="sticky-top">
                            <tr class="csldark1">
                                <th>Cliente</th>
                                <th>Nombre</th>
                                <th>Costo</th>
                                <th>Margen</th>
                                <th>%</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <ng-container   *ngFor="let x of ObjModal.datos;let i = index">
                            <tr>
                                <td class="fs12">
                                    <div class="d-flex justify-content-between">
                                        <span class="ms-2 text-primary">{{x.strCliente}} </span><i class="fs-6 bi " (click)="fnverDet(i,x,x.strCliente)" [ngClass]="{'bi-chevron-compact-down text-dark':!x.seeDet,'bi-chevron-compact-up text-dark':x.seeDet}"></i>
                                    </div>
                                </td>
                                <td class="fs10">{{x.strClienteNombre}}</td>
                                <td class="fs10">{{x.dblCosto|currency}}</td>
                                <td class="fs10">{{x.dblMargen|currency}}</td>
                                <td class="fs10">{{x.dblMargenPorcentaje |number:'1.2-2'}}%</td>
                                <td class="fs12 text-end fw-bold">{{x.dblImporteConFactor|currency}}</td>
                            </tr>
                            <tr *ngIf="x.seeDet">
                                <td colspan="6" class=" mx-auto">
                                    <div class="col-12 text-center">
                                        <h4  class="mb-0 fw-bold">Listado de venta al día de hoy por cliente</h4>
                                    </div>
                                    <div class="col-12 d-flex justify-content-between">
                                        <span>
                                            Total de movimientos:<span class="fw-bold">{{arrayVentaHoyClienteD.length}}</span>
                                        </span>
                                        <span >
                                            Importe total: <span class="fw-bold">{{fnImporteTotalDetCte()|currency}}</span> 
                                        </span>
                                    </div>
                                <div class="col-12 text-center table-responsive borderbott bordertop shadow-sm">
                                    <table class="table table-bordered bg-white">
                                        <thead> 
                                            <tr class="theadtab">
                                                <td class="fs12">Cliente</td>
                                                <td class="fs12">Nombre</td>
                                                <td class="fs12">Movimiento</td>
                                                <td class="fs12">IDMov</td>
                                                <td class="fs12">Costo</td>
                                                <td class="fs12">Margen</td>
                                                <td class="fs12">Porcentaje</td>
                                                <td class="fs12">Importe</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container    *ngFor="let a of arrayVentaHoyClienteD;let ind = index;">
                                            <tr>
                                                <td class="fs10">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="">{{a.strCliente}} </span><i class="fs-6 bi " (click)="fnverDetListVxc(ind,a)" [ngClass]="{'bi-chevron-compact-down text-dark':!a.seeDet,'bi-chevron-compact-up text-dark':a.seeDet}"></i>
                                                    </div>
                                                </td>
                                                <td class="fs10">{{a.strClienteNombre}}</td>
                                                <td class="fs10">{{a.strMov}}</td>
                                                <td class="fs10">{{a.strMovID}}</td>
                                                <td class="fs10">{{a.dblCosto|currency}}</td>
                                                <td class="fs10">{{a.dblMargen|number:'1.2-2'}}</td>
                                                <td class="fs10">{{a.dblMargenPorcentaje|number:'1.2-2'}} %</td>
                                                <td class="fs10">{{a.dblImporteConFactor|currency}}</td>
                                            </tr>
                                            <tr *ngIf="a.seeDet">
                                                <td colspan="8" class=" mx-auto">
                                                    <div class="col-12 text-center">
                                                        <h4  class="mb-0">Detalle de venta al día de hoy por cliente</h4>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-between">
                                                        <span>
                                                            Total de movimientos: 
                                                            <span class="fw-bold"> {{arrayVentaHoyCteArt.length}}</span>
                                                        </span>
                                                        <span >
                                                            Importe total: 
                                                            <span class="fw-bold">{{fnImporteTotal()|currency}}</span> 
                                                        </span>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="table-responsive mb-2 borderbott bordertop">
                                                        <table class="table table-bordered bg-white">
                                                            <thead> 
                                                                <tr class="Subtitulos">
                                                                    <th class="fs12">Artiuculo</th>
                                                                    <th class="fs12">SubCuenta</th>
                                                                    <th class="fs12">Descripción</th>
                                                                    <th class="fs12">Mov</th>
                                                                    <th class="fs12">MovID</th>
                                                                    <th class="fs12">Almacen</th>
                                                                    <th class="fs12">Precio</th>
                                                                    <th class="fs12">Cantidad</th>
                                                                    <th class="fs12">Costo</th>
                                                                    <th class="fs12">Margen</th>
                                                                    <th class="fs12">%</th>
                                                                    <th class="fs12">Importe</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let art of arrayVentaHoyCteArt">
                                                                    <td class="fs10">{{art.strArticulo}}</td>
                                                                    <td class="fs10">{{art.strSubCuenta}}</td>
                                                                    <td class="fs10">{{art.strDescripcion}}</td>
                                                                    <td class="fs10">{{art.strMov}}</td>
                                                                    <td class="fs10">{{art.strMovID}}</td>
                                                                    <td class="fs10">{{art.strAlmacen}}</td>
                                                                    <td class="fs10">{{art.dblPrecio|currency}}</td>
                                                                    <td class="fs10">{{art.dblCantidad}}</td>
                                                                    <td class="fs10">{{art.dblCosto|currency}}</td>
                                                                    <td class="fs10">{{art.dblMargen|number:'1.2-2'}}</td>
                                                                    <td class="fs10">{{art.dblMargenPorcentaje|number:'1.2-2'}}</td>
                                                                    <td class="fs10">{{art.dblImporteConFactor|currency}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                                </td>
                            </tr>
                            </ng-container>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="(ObjModal.strMdal=='tipoCte'|| ObjModal.strMdal=='ctePorLinea')  && !spinerModal">
                <div class="row " [ngClass]="{'justify-content-evenly':strGerente != '' || strSucursal != ''}">
                    <div class="col-12 col-md-6 col-xl-3 mb-3" *ngIf="strGerente != '' || strSucursal != '' ">
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="strSucursal" (change)="fngetfiltrar4500(strSucursal, ObjModal.intPeriodo,ObjModal.strMdal)">
                            <option selected value=""> --Todas--</option>
                            <option *ngFor="let suc of arraySucursales" [value]="suc.strSucursal">{{suc.strEstatus}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3 mb-3">
                        <input type="text" placeholder="buscar por cliente" [(ngModel)]="filtroCliente" class="form-control">
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-between mb-2">
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span >
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-12">
                    <div class="table-responsive mb-2 borderbott bordertop shadow">
                    <table class="table table-bordered ">
                        <thead>
                            <tr class="csldark1 sticky-top">
                                <th class="fs12">Cliente</th>
                                <th class="fs12">Nombre</th>
                                <th class="fs12"  *ngIf="strGerente != '' || strSucursal != '' ">Sucursal</th>
                                <th class="fs12">Ejercicio</th>
                                <th class="fs12">Margen</th>
                                <th class="fs12">Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let o of ObjModal.datos |  filtersearch: filtroCliente: ['strCliente', 'strClienteNombre']">
                                <td class="text-center fs12">{{o.strCliente}}</td>
                                <td class="text-center fs10">{{o.strClienteNombre}}</td>
                                <td class="fs12"  *ngIf="strGerente != '' || strSucursal != '' ">{{o.strSucursal}}</td>
                                <td class="text-center fs10">{{o.intEjercicio}}</td>
                                <td class="text-end fs10">{{o.dblMargen|number:'1.2-2'}}</td>
                                <td class="text-end fs10">{{o.dblImporteConFactor|currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="ObjModal.strMdal=='detCarterraRezagada' && !spinerModal">
                <div class="col-12 d-flex justify-content-between ">
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span >
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-11 px-0 ">
                    <div class="table-responsive mb-0 borderbott bordertop shadow">
                    <table class="table table-bordered col-11">
                        <thead class="sticky-top">
                            <tr class="csldark1">
                                <th>Tipo</th>
                                <th>Cliente</th>
                                <th>Nombre</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <ng-container *ngFor="let x of ObjModal.datos;let i = index">
                            <tr>
                                <td class="fs10">{{x.strTipo}}</td>
                                <td class="fs12 text-primary">
                                    <div class="d-flex justify-content-between">
                                        <span class="">{{x.strCliente}} </span><i class="fs-6 text-dark bi " (click)="fnverDet(i,x,'carterarez',x.strCliente)" [ngClass]="{'bi-chevron-compact-down ':!x.seeDet,'bi-chevron-compact-up':x.seeDet}"></i>
                                    </div>
                                </td>
                                <td class="fs10">{{x.strClienteNombre}}</td>
                                <td class="fs12 text-end fw-bold">{{x.dblImporteConFactor|currency}}</td>
                            </tr>
                            <tr *ngIf="x.seeDet">
                                <td colspan="6" class="mx-auto">
                                    <div class="col-12 text-center">
                                        <h4  class="mb-0 fw-bold">Listado de venta al día de hoy por cliente</h4>
                                    </div>
                                    <div class="col-12 d-flex justify-content-between">
                                        <span>
                                            Total de movimientos:<span class="fw-bold">{{arrayDetCarteraRezagadaSaldo.length}}</span>
                                        </span>
                                        <span >
                                            Importe total: <span class="fw-bold">{{fnImporteTotalDetcarterarezagada()|currency}}</span> 
                                        </span>
                                    </div>
                                <div class="col-12 text-center">
                                    <div class="table-responsive mb-2 borderbott bordertop shadow-sm">
                                    <table class="table table-bordered bg-white">
                                        <thead> <tr class="theadtab">
                                            <td class="fs12">Cliente</td>
                                            <td class="fs12">Nombre</td>
                                            <td class="fs12">Movimiento</td>
                                            <td class="fs12">IDMov</td>
                                            <td class="fs12">Agente </td>
                                            <td class="fs12">Sucursal</td>
                                            <td class="fs12">Vencimiento</td>
                                            <td class="fs12">Importe</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container    *ngFor="let a of arrayDetCarteraRezagadaSaldo;let ind = index;">
                                            <tr>
                                                <td class="fs10">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="fw-bold">{{a.strCliente}} </span>
                                                    </div>
                                                </td>
                                                <td class="fs10">{{a.strClienteNombre}}</td>
                                                <td class="fs10">{{a.strMov}}</td>
                                                <td class="fs10">{{a.strMovID}}</td>
                                                <td class="fs10">{{a.strAgente}}</td>
                                                <td class="fs10">{{a.strSucursal|number:'1.2-2'}}</td>
                                                <td class="fs10">{{a.strVencimiento}} </td>
                                                <td class="fs10 fw-bold">{{a.dblImporteConFactor|currency}}</td>
                                            </tr>
                                       
                                        </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                                </td>
                            </tr>


                     
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="ObjModal.strMdal=='cuentasxpagar' && !spinerModal">
                <div class="col-12 d-flex justify-content-between ">
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span >
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-11 px-0">
                    <div class="col-12 px-0 table-responsive shadow  borderbott bordertop "> 
                    <table class="table table-bordered col-11">
                        <thead class="sticky-top">
                            <tr class="csldark1 sticky-top">
                                <th>Proveedor</th>
                                <th>Proveedor Nombre</th>
                                <th>Saldo</th>
                            </tr>
                        </thead>
                        <tbody> 
                        
                            <tr *ngFor="let x of ObjModal.datos;let i = index">
                           
                                <td class="fs12">
                                   {{x.strProveedor}}
                                </td>
                                <td class="fs10">{{x.strNombreProveedor}}</td>
                                <td class="fs12 text-end fw-bold">{{x.dblImporteConFactor|currency}}</td>
                            </tr>
                 

                     
                        
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="ObjModal.strMdal=='gastoOperacion' && !spinerModal">
                <div class="col-12 d-flex justify-content-between ">
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span >
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-11 px-0 ">
                    <div class="col-12 px-0 table-responsive borderbott bordertop shadow">     
                    <table class="table table-bordered ">
                        <thead class="sticky-top">
                            <tr class="csldark1">
                                <th>Movimiento</th>
                                <th>Fecha Emision</th>
                                <th>Sucursal</th>
                                <th>Estatus</th>
                                <th>Clase</th>
                                <th>Observaciones</th>
                                <th>Importe</th>

                            </tr>
                        </thead>
                        <tbody> 
                            <tr *ngFor="let x of ObjModal.datos;let i = index">
                                <td class="fs12">{{x.strMov}}</td>
                                <td class="fs10">{{x.strMovID}}</td>
                                <td class="fs10">{{x.strEstatus}}</td>
                                <td class="fs10">{{x.strAcreedor}}</td>
                                <td class="fs10">{{x.strClase}}</td>
                                <td class="fs10">{{x.strObservaciones}}</td>
                                <td class="fs10">{{x.dblImporteConFactor}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="ObjModal.strMdal=='cajaComodatos' && !spinerModal">
                <div class="col-12 d-flex justify-content-between " >
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span >
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-11 px-0  my-2">
                    <div class="col-11 px-0 table-responsive shadow borderbott bordertop">  
                    <table class="table table-bordered col-11 table-hover">
                        <thead class="sticky-top">
                            <tr class="csldark1">
                                <th>Cliente</th>
                                <th>Cliente Nombre</th>
                                <th>MovId</th>
                                <th>Articulo</th>
                                <th>Descripcion</th>
                                <th>Marca</th>
                                <th>Cantidad</th>
                                <th>Costo Unitario</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr *ngFor="let x of ObjModal.datos;let i = index">
                                <td class="fs12 text-center fw-bold">{{x.strCliente}}</td>
                                <td class="fs10 text-center">{{x.strClienteNombre}}</td>
                                <td class="fs10 text-center fw-bold">{{x.strMovID}}</td>
                                <td class="fs10 text-center">{{x.strArticulo}}</td>
                                <td class="fs10 text-center">{{x.strDescripcion1}}</td>
                                <td class="fs10 text-center">{{x.strMarca}}</td>
                                <td class="fs10 text-center">{{x.dblCantidad}}</td>
                                <td class="fs10 text-end">{{x.dblCostoUnitario|currency}}</td>
                                <td class="fs10 text-end fw-bold">{{x.dblCosto|currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
                </div>
            </div>
            <!--  -->
            <div class="row justify-content-center" *ngIf="ObjModal.strMdal=='movInventario' && !spinerModal">
                <div class="col-12 d-flex justify-content-between " >
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span >
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-11 px-0 ">
                    <div class="col-12 px-0 table-responsive shadow borderbott bordertop">
                        <table class="table table-bordered col-11 table-hover">
                        <thead class="sticky-top">
                            <tr class="csldark1">
                                <th>Mov</th>
                                <th>MovId</th>
                                <th>Usuario</th>
                                <th>Almacén</th>
                                <th>Articulo</th>
                                <th>Costo</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr *ngFor="let x of ObjModal.datos;let i = index">
                                <td class="fs12 text-center fw-bold">
                                    {{x.strMov}}
                                </td>
                                <td class="fs10 text-end">
                                    {{x.strMovID}}
                                </td>
                                <td class="fs10 text-center">
                                    {{x.strUsuario}}
                                </td>
                                <td class="fs10 text-center fw-bold">
                                    {{x.strAlmacenNombre}}
                                </td>
                                <td class="fs10 text-center">
                                    {{x.strArticulo}}
                                </td>
                                <td class="fs10 text-end fw-bold">
                                    {{x.dblCosto|currency}}
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="row justify-content-center" *ngIf="ObjModal.strMdal=='cajaRemisiones' && !spinerModal">
                <div class="col-12 d-flex justify-content-between " >
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span >
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-11 px-0">
                    <div class="col-12 px-0 table-responsive shadow borderbott bordertop">
                    <table class="table table-bordered w-100 table-hover">
                        <thead class="sticky-top">
                            <tr class="csldark1">
                                <th>Cliente</th>
                                <th>Cliente Nombre</th>
                                <th>MovId</th>
                                <th>Costo </th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr *ngFor="let x of ObjModal.datos;let i = index">
                                <td class="fs12 text-center fw-bold">{{x.strCliente}}</td>
                                <td class="fs10 text-center">{{x.strClienteNombre}}</td>
                                <td class="fs10 text-center fw-bold">{{x.strMovID}}</td>
                                <td class="fs10 text-center">{{x.dblCosto|currency}}</td>
                                <td class="fs10 text-end fw-bold">{{x.dblImporteConFactor|currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row justify-content-center" *ngIf="ObjModal.strMdal=='ventasPerdidas' && !spinerModal">
                <div class="col-12 d-flex justify-content-between " >
                    <span>
                        Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                    </span>
                    <span >
                        Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span> 
                    </span>
                </div>
                <div class="col-11 px-0 table-responsive shadow">
                    <table class="table table-bordered col-11 table-hover">
                        <thead class="sticky-top">
                            <tr class="csldark1">
                                
                                <th>Concepto</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr *ngFor="let x of ObjModal.datos;let i = index">
                            
                                <td class="text-center">{{x.strConcepto}}</td>
                                <td class="text-end fw-bold">{{x.dblImporteConFactor|currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--  -->
        </div>
        <div class="modal-footer titulos py-1 px-2">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>




