<div class="container">
    
    <div class="row mb-4"> 
        <div class="col-12 pb-3">
            <div class="col-12 text-center position-relative">
                <h1 class="fw-bold my-2 text-center nunito">ADMINISTRAR OFERTAS</h1>
            </div>
        </div>    
    </div>
    <div class="row mb-4"> 
        <div class="col-4">
            <!-- Button trigger modal -->
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                
                Agregar Articulo
            </button>
        </div>
    </div>

    <!-- inicia tabla-->
     
        <div class="row">
            <div class="col-4 col-lg-4">
                <select class="form-select" aria-label="Default select example" [(ngModel)]="strLinea">
                    <option selected>Filtrar por linea</option>
                    <option  [value]="linea.strLinea" *ngFor="let linea of arrayLineas" > 
                        {{linea.strLinea}}
                 
                       </option>
            
                </select>

            </div>
            <div class="col-3 col-lg-3">
                <button type="button" class="btn btn-primary" (click)="fnGetArticulos_linea(strLinea )">Filtrar</button>

            </div>

           
       </div>
       <div class="row">
        <div class="alert alert-danger text-center fw-bold" *ngIf="!spinnerDest && arrayArticulos.length==0" role="alert">
            No hay productos disponibles en este momento.
        </div>
        <div *ngIf="!spinnerDest && arrayArticulos.length>0" class="table-responsive mb-0  shadow-sm px-0 roundedtop">
            <table class="table table-striped table-bordered rounded">
                <thead>
                    <tr class="th-center sticky-top">
                        <th>Orden</th>
                        <th>Articulo </th>
                        <th>Descripcion </th>
                        <th>Precio 4</th>
                        <th>Precio Lista</th>
                        <th>Tipo</th>
                        <th>Aplica</th>
                        <th>Precio Promocion</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nov of arrayArticulos " class="fs13"><!-- arrayNovedades -->
                        <td class="align-middle">
                           <!-- <div class="input-group mb-3">
                                <span class="input-group-text py-0 px-2 pointer" id="basic-addon1" (click)="fnUpdateOrder(nov,'OFERTA')">
                                    <i class="bi bi-floppy-fill fs14 text-primary"></i>
                                </span>
                                <input type="number" [(ngModel)]="nov.dblCantidad" class="form-control text-center fs14">
                            </div>-->
                        </td>
                        <td class="align-middle">
                          <div class="col-12 d-flex flex-column">                                              
                            <img class="rounded shadow-sm mx-auto" style="width: 60px;height: 60px;"  [src]="nov.imageUrl" alt="{{ nov.strArticulo}}" >
                            <span class="mx-auto">{{nov.strArticulo}}</span>
                          </div>
                        </td>
                        <td class="align-middle fs12">{{nov.strDescripcion}}</td>
                        <td class="text-end align-middle">
                            <!--{{nov.dblSaldo|currency}}-->
                        </td>
                        <td class="text-end align-middle"><!--{{nov.dblPrecioLista|currency}}-->

                        </td>
                        <td class="align-middle text-center fw-bold" ><span class="rounded px-2 nunito" [ngClass]="{'bgOferta':nov.strTipo=='OFERTA','bgLiquidacion':nov.strTipo=='LIQUIDACION'}"> {{nov.strTipo}}</span></td>
                        <td class="align-middle text-center fw-bold" ><span class="rounded px-2 nunito" [ngClass]="{'bgOferta':nov.strTipo=='OFERTA','bgLiquidacion':nov.strTipo=='LIQUIDACION'}"> {{nov.strCondicion_Cupon}}</span></td>
                        <td class="text-end align-middle fw-bold"> {{nov.dblPrecioOferta  |currency}} </td>
                        <td class="text-center align-middle">
                            <button class="btn p-0" (click)="fnEliminarArtsOferta(nov)">
                                <i class="fa-solid fa-trash-can text-danger shadow-sm fs-4"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
       </div>

</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Agregar Artículo</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
           <div class="row">
                <div class="col-4 col-lg-4">
                    <select class="form-select" aria-label="Default select example" [(ngModel)]="strLinea">
                        <option selected>Filtrar por linea</option>
                        <option  [value]="linea.strLinea" *ngFor="let linea of arrayLineas" > 
                            {{linea.strLinea}}
                     
                           </option>
                
                    </select>

                </div>
                <div class="col-3 col-lg-3">
                    <button type="button" class="btn btn-primary" (click)="fnGetArticulos_linea(strLinea )">Filtrar</button>

                </div>

               
           </div>
           <div class="row">
            <table class="table">
                <thead>
                    <tr>
                        <div class="col-4 col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Buscar:</mat-label>
                                <input [(ngModel)]="inputSearch" (input)="fnFiltrarArtsAdd()" matInput placeholder="Articulo. Descripcion, etc."><!-- (keydown.enter)="fnBuscarArticulo()"  -->
                                <mat-icon matSuffix class="pointer" >search</mat-icon><!-- (click)="fnBuscarArticulo()" -->
                            </mat-form-field>
                        </div>
                    </tr>
                  <tr>
                    <th scope="col">Seleccionar Todo</th>
                    <th scope="col">Articulo</th>
                    <th scope="col">Descripción</th> 
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let art of arrayArticulos">
                    <th scope="row"><div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault"> 
                        </label>
                      </div></th>
                    <td>{{art.strArticulo}}</td>
                    <td>{{art.strDescripcion}}</td>
                   
                  </tr>
              
                </tbody>
              </table>
           </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">cerrar</button>
          
        </div>
      </div>
    </div>
  </div>