<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold  text-decoration-underline">Listado de  <span class="titulo">Facturas y Devoluciones</span></h1>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col mt-2 pt-1 d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col mt-2 pt-1 d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div> 
        <div class="col mt-2 pt-1 d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Evidencia</mat-label>
                <mat-select [(ngModel)]="strEstatus" (selectionChange)="fnFiltrar();">
                    <mat-option value="">
                        -- Todos --
                    </mat-option>
                    <mat-option value="Faltante">
                        Faltante
                    </mat-option>
                    <mat-option value="Existente">
                        Existente
                    </mat-option>
                   
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col mt-2 pt-1 d-flex justify-content-center align-items-center" >
            <button (click)="fnGetPedidos()" class="btn btnFilter shadow-sm px-2 py-1"  (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-4  d-flex  px-2 bg-white justify-content-between align-items-center">
            <span > 
                <span class="fw-bold">EVIDENCIAS DE ENTREGA</span>
                <h4><i class="fa-solid fa-box"></i> Completa  <span class="badge text-bg-success">{{DocumentosConEvidencia}}</span></h4>
                <h4><i class="fa-solid fa-truck-fast"></i> En proceso <span class="badge text-bg-info">{{DocumentosSinEvidencia}}</span></h4>
            </span>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div *ngIf="spinerPed" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="alert alert-danger text-center fw-bold my-3" *ngIf=" !spinerPed && arrayPedidos.length==0" role="alert">
                No se han encontrado datos de Facturas
              </div>
              <div  [hidden]="arrayPedidos.length==0">
                <div class="col-12 d-flex px-2 bg-white justify-content-between align-items-center">
                    <mat-form-field appearance="outline" class="bg-white rounded my-2">
                        <mat-label>Buscar:</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                      </mat-form-field>

                      
                      <span > 
                       <span class="fw-bold">Total Documentos:</span>  {{arrayPedidos.length}}
                      </span>
                </div>
              </div>
              
            <div class="table-responsive mb-0" [hidden]="arrayPedidos.length==0">
                  <div class="mat-elevation-z8">
                    <table class="table w-100 table-bordered" mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="strSucursal">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2"> Sucursal </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> 
                               
                                <span class="badge text-bg-info"> {{row.strSucursal}} </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="strCliente">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente / Sucursal </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fw-bold fs12 text-primary pointer" > ({{row.strCliente}}) {{row.strClienteNombre}} </td>
                          </ng-container>
                          <ng-container matColumnDef="strMovID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> OrigenID / Movimiento ID </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fw-bold fs12 text-primary pointer"  >  {{row.strMovID}}    </td>
                          </ng-container> 
                        <ng-container matColumnDef="strAlmacen">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2"> Almacén </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> {{row.strAlmacen}} {{row.strAlmacenNombre}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="strFechaSolicitud">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs12"> {{row.strFechaEmision}} </td>
                        </ng-container>

                          <ng-container matColumnDef="strReferencia">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs12"> {{row.strReferencia}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strOrdenCompra">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Orden Compra</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> <span class="text-nowrap"><b> {{row.strOrdenCompra}} </b> </span></td>
                          </ng-container>

                          <ng-container matColumnDef="strEvOrdenCompra">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Evidencia Ord.Compra</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> 
                                <a  *ngIf ="row.strDireccionOrdenCompra !=''" data-toggle="tooltip" data-placement="top" title="{{row.strDireccionOrdenCompra}}" href="{{row.strDireccionOrdenCompra}}" target="_NEW">
                                    <i class="fa fa-download fa-2x"  style="color:#2892af"></i>
                                </a>
                        </td>
                          </ng-container>
                          

                          
                          <ng-container matColumnDef="dblImporte">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap"> {{row.dblImporte | currency}} </span></td>
                          </ng-container>

                          <ng-container matColumnDef="strVerPDF">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver PDF </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" >
                                <a href="{{rutaPdf+intEjercicio+'/'+intPeriodo+'/'+row.strCliente+'/'+row.strMov+' '+row.strMovID+'.pdf'}}" target="_blank\" id='step5'>
                                    {{row.strMov}} {{row.strMovID}}
                                </a>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="strVerXML">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver XML </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap">
                                <a href="{{rutaPdf+intEjercicio+'/'+intPeriodo+'/'+row.strCliente+'/'+row.strMov+' '+row.strMovID+'.xml'}}" target="_blank\" id='step5'>
                                    {{row.strMov}} {{row.strMovID}}
                                </a>

                            </span></td>
                          </ng-container>

                          <ng-container matColumnDef="strEvidencia">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Evidencia </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" > 
                                <a *ngIf="row.strDireccionEvidencia !=''" data-toggle="tooltip" data-placement="top" title="{{row.strDireccionEvidencia}}" href="{{row.strDireccionEvidencia}}" target="_NEW">
                                    <i class="fa fa-download fa-2x"  style="color:#2892af"></i>
                                </a> &nbsp;&nbsp;

                            </td>
                          </ng-container>
                
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  
                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                      </tr>
                    </table>
                  
                    
                  </div>
            </div>
            <div class="col-12" [hidden]=" arrayPedidos.length==0">
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>