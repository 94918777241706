import { Component, ElementRef,OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService} from 'ngx-webstorage';
import { FiltrosService } from '../servicios/filtros.service';
import {MatPaginator,MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';

export interface UserData {
  strPeriodo:string;
  strMovIDP: string;
  strOrdenCompraP: string;
  strOrdenCompraPorcentaje: string;
  strFemisionP:string;
  strFemisionPorcentaje:string;
  strDelegacion:string;
  strDelegacionPorcentaje:string;
  strCte:string;
  strCtePorcentaje:string;
  strMovIDE:string;
  strMovIDEPorcentaje:string; 
  strEntregas7dias:string;
  strEntregas7diasPorcentaje:string;
}
@Component({
  selector: 'app-cliente-reporte-principal',
  templateUrl: './cliente-reporte-principal.component.html',
  styleUrls: ['./cliente-reporte-principal.component.css']
})

export class ClienteReportePrincipalComponent implements OnInit {
  displayedColumns: string[] = ['strPeriodo','strMovIDP','strOrdenCompraP','strOrdenCompraPorcentaje','strFemisionP','strFemisionPorcentaje','strDelegacion','strDelegacionPorcentaje','strCte','strCtePorcentaje','strMovIDE','strMovIDEPorcentaje','strEntregas7dias','strEntregas7diasPorcentaje'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public strConexion:string="";
  public arrayEjercicio:any=[];
  public intEjercicio:number=0;
  public intPeriodo:number; 
  public arrayPeriodo:any= [];
  public strCliente:string="";
  public strFamilia:string="";
  public arrayAccesos:any =[];
  public objCliente:any ={};
  public tagName: string;
  public spMostrarCargandoPV:boolean=false;
  public objModal:any={
    titulo:"",
    arrayDatos:[]
  }
  
  public strAgente_Crea: string;
  public strAgente: any;
  public boolEditaAgente: boolean = true;
  public strUsuario: string;
  public arraySeguimientoPedidos:any=[];
  public isHoveredButton: boolean = false;
  constructor(elem: ElementRef,public router: Router,private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl,private _filtroServ:FiltrosService,private localSt:LocalStorageService,private _httpService:PeticionesService,) {
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.arrayAccesos = this.localSt.retrieve('ArrayAccesos');
    this.objCliente = this.arrayAccesos[0]; 
    this.strFamilia = this.objCliente.strFamiliaCte;
    this.strCliente = this.objCliente.strCliente;
    this.strConexion = this.localSt.retrieve('strConexion');
    this.fnGetTableroCliente();
  }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        this.strConexion = this.localSt.retrieve('strConexion');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strAgente = this.localSt.retrieve('strAgente');
        this.strUsuario = this.localSt.retrieve('strUsuario');
        this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;
        //------------conexi+on-------------------
        this.indexedDBService.getItem('strConexion', (value) => {
          this.strConexion = value;
          /*    console.log(' CARGA conexion directorio') */
        });
      }
      else {
        this.router.navigate(['/login']);
      }
    });



    this.fnGetFiltros();
 
  }

 /*  ngAfterViewInit() {
   
  } */
fntabla(){
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  fnGetTableroCliente(){
    this.spMostrarCargandoPV= true;
    this.arraySeguimientoPedidos = [];
    let objEnv: {
      intEjercicio: number;
      intPeriodo: number;
      strAccion: string;
      strConexion: string;
      strFamilia?: string; // Propiedades opcionales
      strCliente?: string; // Propiedades opcionales
    } = {
      intEjercicio: this.intEjercicio,
      intPeriodo: this.intPeriodo,
      strAccion: "getTablero1",
      strConexion:this.strConexion
    };

    if(this.strFamilia!=""){
      objEnv.strFamilia = this.strFamilia;
    }else{
      objEnv.strCliente =  this.strCliente;
      objEnv.strFamilia =  this.strFamilia;
    }
    console.log(objEnv)
    this._httpService.getClienteDatos(objEnv,"sp_SeguimientoPedidos").subscribe(
      result => {
        console.log(result)
       let arrayRest = result;
       arrayRest.forEach(ped => {
        this.arraySeguimientoPedidos.push({
          intPeriodo:ped.intPeriodo,
          strPeriodo:ped.intPeriodo == 1 ? "Enero" :
          ped.intPeriodo == 2 ? "Febrero" :
          ped.intPeriodo == 3 ? "Marzo" :
          ped.intPeriodo == 4 ? "Abril" :
          ped.intPeriodo == 5 ? "Mayo" :
          ped.intPeriodo == 6 ? "Junio" :
          ped.intPeriodo == 7 ? "Julio" :
          ped.intPeriodo == 8 ? "Agosto" :
          ped.intPeriodo == 9 ? "Septiembre" :
          ped.intPeriodo == 10 ? "Octubre" :
          ped.intPeriodo == 11 ? "Noviembre" :
          ped.intPeriodo == 12 ? "Diciembre" : "Desconocido",
          strMovIDP:ped.strMovIDP,
          strOrdenCompraP:ped.strOrdenCompraP,
          strOrdenCompraPorcentaje:(ped.strOrdenCompraP/ped.strMovIDP)*100,
          strFemisionP:ped.strFemisionP,
          strFemisionPorcentaje:(ped.strFemisionP/ped.strMovIDP)*100,
          strDelegacion:ped.strDelegacion,
          strDelegacionPorcentaje:(ped.strDelegacion/ped.strMovIDP)*100,
          strCte:ped.strCte,
          strCtePorcentaje:(ped.strCte/ped.strMovIDP)*100,
          strMovIDE:ped.strMovIDE,
          strMovIDEPorcentaje:(ped.strMovIDE/ped.strMovIDP)*100 ,
          strEntregas7dias:(ped.strOrdenCompraP*1 +ped.strFemisionP*1 ),
          strEntregas7diasPorcentaje:((ped.strOrdenCompraP*1 +ped.strFemisionP*1 )/(ped.strOrdenCompraP*1 +ped.strFemisionP*1+ped.strDelegacion*1))*100,
        })
       });
       this.spMostrarCargandoPV= false;
       this.dataSource = new MatTableDataSource(this.arraySeguimientoPedidos);
       this.arraySeguimientoPedidos.sort((a, b) => a.intPeriodo - b.intPeriodo);

       this.fntabla()
      },error=>{
        this.spMostrarCargandoPV= false;
      });
  }

  fnBuscar(){

  }

  fnGetFiltros() {
   
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
    this.arrayPeriodo.unshift({strPeriodo:0,strNombre:"--Todos--"})
  }
  exportToExcel() {
    // Define los encabezados personalizados
    const headers = [
      "#",
      "Total Pedidos Generados",
      "Entrega Menor a 3 días",
      "Menor a 3 días %",
      "Entrega de 4 a 7 días",
      "Entrega de 4 a 7 días %",
      "Entrega Mayor a 7 días",
      "Mayor a 7 días %",
      "Pendientes de Factura",
      "Pendientes Factura %",
      "En Tránsito",
      "Tránsito %",
      "Total Entregas Menor a 7 días",
      "TOTAL %"
    ];
  
    // Prepara los datos para exportar, agregando índices como "#"
    const dataToExport = this.arraySeguimientoPedidos.map((item, index) => ({
      "#": index + 1,
      "Total Pedidos Generados": item.strMovIDP,
      "Entrega Menor a 3 días": item.strOrdenCompraP,
      "Menor a 3 días %":  Number(item.strOrdenCompraPorcentaje).toFixed(2),
      "Entrega de 4 a 7 días": item.strFemisionP,
      "Entrega de 4 a 7 días %":  Number(item.strFemisionPorcentaje).toFixed(2),
      "Entrega Mayor a 7 días": item.strDelegacion,
      "Mayor a 7 días %":  Number(item.strDelegacionPorcentaje).toFixed(2),
      "Pendientes de Factura": item.strCte,
      "Pendientes Factura %":  Number(item.strCtePorcentaje).toFixed(2),
      "En Tránsito": item.strMovIDE,
      "Tránsito %":  Number(item.strMovIDEPorcentaje).toFixed(2),
      "Total Entregas Menor a 7 días": item.strEntregas7dias,
      "TOTAL %":  Number(item.strEntregas7diasPorcentaje).toFixed(2)
    }));
  
    // Crea una hoja de cálculo
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, { header: headers });
    
    // Agrega los encabezados personalizados manualmente
    worksheet["!cols"] = headers.map(() => ({ wch: 20 })); // Ajusta el ancho de las columnas opcionalmente
  
    // Crea un libro de trabajo
    const workbook: XLSX.WorkBook = {
      Sheets: { "Seguimiento Pedidos": worksheet },
      SheetNames: ["Seguimiento Pedidos"]
    };
  
    // Genera el archivo Excel en formato binario
    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
    // Guarda el archivo con FileSaver
    const data: Blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "Seguimiento_Pedidos.xlsx");
  }
  fnAbrirModal(titulo:string,accion:string,periodo:string){
    this.spMostrarCargandoPV =true
    this.objModal.titulo = titulo == 'Total Pedidos'? "Total Pedidos" : "Detalle "+titulo
    let objEnv: {
      intEjercicio: number;
      intPeriodo: number;
      strAccion: string;
      strConexion: string;
      strFamilia?: string; // Propiedades opcionales
      strCliente?: string; // Propiedades opcionales
    } = {
      intEjercicio: this.intEjercicio,
      intPeriodo: parseInt(periodo,10),
      strAccion: accion,
      strConexion:this.strConexion
    };

    if(this.strFamilia!=""){
      objEnv.strFamilia = this.strFamilia;
    }else{
      objEnv.strCliente =  this.strCliente;
      objEnv.strFamilia =  this.strFamilia;
    }
    console.log(objEnv)
    this._httpService.getClienteDatos(objEnv,"sp_SeguimientoPedidos").subscribe(
      result => {
        this.objModal.arrayDatos = result
        this.spMostrarCargandoPV =false
      },error => {
        this.spMostrarCargandoPV =false
      });
  }
}
