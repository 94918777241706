<!--<app-menu></app-menu>-->
<br><br>
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h1 class="title">Reporte de <span class="title2">Comodatos</span></h1>
        </div>
    </div>

    <br><br><br>

    <div class="row">
        <div class="col-3 select-left mb-3" *ngIf="selectGte">
            <mat-form-field appearance="fill">
                <mat-label>Seleccione Gerente</mat-label>
                <mat-select [(ngModel)]="gteSelected" (selectionChange)="fnGetSucursales(gteSelected)">
                    <mat-option *ngFor="let item of arrayGerentes" [value]="item">
                        {{item.strEstatus}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-3 select-left mb-3" *ngIf="selectSuc">
            <mat-form-field appearance="fill">
                <mat-label>Seleccione sucursal</mat-label>
                <mat-select [(ngModel)]="sucursalSelected" (selectionChange)="fnGetAgentes(sucursalSelected)">
                    <mat-option *ngFor="let item of arraySucursales" [value]="item">
                        {{item.strEstatus}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-3 select-left mb-3" *ngIf="selectAgt">
            <mat-form-field appearance="fill">
                <mat-label>Seleccione Agente</mat-label>
                <mat-select [(ngModel)]="agenteSelected">
                    <!--(change)="fnFiltrar()"-->
                    <mat-option *ngFor="let item of arrayAgentes" [value]="item">
                        {{item.strLinea}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-3 select-left mb-3" *ngIf="selectAgt">
            <button mat-raised-button style="background-color: #144361;;color: white;height: 50px;margin-left: 15px;" (click)="fnGetComodatos()" [disabled]="agenteSelected.strEstatus==''||  !agenteSelected.strEstatus ">Filtrar</button>
            <label *ngIf="agenteSelected.strEstatus =='' ||  !agenteSelected.strEstatus" style="margin-top: -12px; color: red;">Seleccione un Agente</label>
        </div>
    </div>

    <div class="row">
        <div class="col-3 select-left mb-3">
            <mat-form-field appearance="fill">
                <mat-label>Periodo</mat-label>
                <mat-select [(ngModel)]="intPeriodo" name="periodo">
                    <mat-option *ngFor="let item of periodo" [value]="item.value">
                        {{item.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-3 select-left mb-3">
            <mat-form-field appearance="fill">
                <mat-label>Ejercicio</mat-label>
                <mat-select [(value)]="intEjercicio" name="ejercicio">
                    <mat-option *ngFor="let item of ejercicio" [value]="item.value">
                        {{item.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div class="col-3 select-left mb-3">
            <mat-form-field appearance="standard" style="width:100%;font-size: 16px;">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Buscar en Tabla" #input>
                <mat-icon aria-hidden="false" matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <br>
    <div class="row">
        <div class="col-sm-12 text-center">
            <p style="display: inline-block;">
                <mat-spinner [hidden]="!boolTable"></mat-spinner>
            </p>
        </div>

        <div class="table-responsive2" [hidden]="boolTable">
            <div class="min-wid800">
                <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z8">
                    <ng-container matColumnDef="strCliente">
                        <th mat-header-cell *matHeaderCellDef> Cliente</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 8%;" (click)="fnDetalle(element)"><b>{{element.strCliente}} </b> </td>
                    </ng-container>
                    <ng-container matColumnDef="strNombreCliente">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Cliente </th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 30%;" (click)="fnDetalle(element)"> {{element.strNombreCliente}} </td>
                    </ng-container>
                    <ng-container matColumnDef="dblCantidadPendiente">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Comodatos</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 5%;" (click)="fnDetalle(element)"> {{element.dblCantidadPendiente}} </td>
                    </ng-container>

                    <ng-container matColumnDef="intCantidadPrometida">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Anual</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 5%;" (click)="fnDetalle(element)"> {{element.intCantidadPrometida}} </td>
                    </ng-container>
                    <ng-container matColumnDef="intCantidadComprada">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Vendida</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 5%;" (click)="fnDetalle(element)"> {{element.intCantidadComprada}} </td>
                    </ng-container>
                    <ng-container matColumnDef="semaforo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Semáforo</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 5%;" (click)="fnDetalle(element)">
                            <svg *ngIf=" element.intCantidadComprada >= element.intCantidadPrometida && element.intCantidadComprada>0 " style="
                                color: #26c326;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightbulb-fill" viewBox="0 0 16 16">
                                <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/>
                              </svg>
                            <svg *ngIf=" element.intCantidadComprada < element.intCantidadPrometida ||element.intCantidadComprada==0" style="
                                color: #f13e08;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightbulb-off-fill" viewBox="0 0 16 16">
                                <path d="M2 6c0-.572.08-1.125.23-1.65l8.558 8.559A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm10.303 4.181L3.818 1.697a6 6 0 0 1 8.484 8.484zM5 14.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5zM2.354 1.646a.5.5 0 1 0-.708.708l12 12a.5.5 0 0 0 .708-.708l-12-12z"/>
                              </svg>
                        </td>
                    </ng-container>




                    <!-- <ng-container matColumnDef="strSucursal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" (click)="fnDetalle(element)"> {{element.strSucursal }} </td>
                    </ng-container>-->

                    <ng-container matColumnDef="strNombreSucursal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Sucursal</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 20%;" (click)="fnDetalle(element)">({{element.strSucursal }}) {{element.strNombreSucursal}} </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="strAgente">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Agente</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 5%;" (click)="fnDetalle(element)"> {{element.strAgente}} </td>
                    </ng-container>-->
                    <ng-container matColumnDef="strNombreAgente">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Agente</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" style="width: 15%;" (click)="fnDetalle(element)"> ({{element.strAgente}}) {{element.strNombreAgente}} </td>
                    </ng-container>
                    <!--<ng-container matColumnDef="strEstatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus</th>
                        <td mat-cell *matCellDef="let element" class="gastoCell" (click)="fnDetalle(element)"> {{element.strEstatus}} </td>
                    </ng-container>-->





                    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="example-element-diagram">
                                    <h3 class="titulo_detalle">Detalle de Comodato</h3>
                                    <mat-progress-bar mode="indeterminate" *ngIf="boolBarraD"></mat-progress-bar>
                                    <table class="table sub_tabla" style="background-color: white;">
                                        <thead>
                                            <th>Comodato</th>
                                            <th>Nombre Comodato</th>
                                            <th>Cantidad Comodato</th>
                                            <th>Cantidad Prometida</th>
                                            <th>Precio Prometido</th>
                                            <th>Cantidad Vendida</th>
                                            <th>Importe Vendido</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of arrayDetalle">
                                                <td>{{item.strArticulo}}</td>
                                                <td> {{item.strNombreArticulo}}</td>

                                                <td>{{item.intCantidadAsignada}}</td>
                                                <td>{{item.intCantidadPrometida}}</td>
                                                <td>{{item.dblPrecioPrometido |currency}}</td>
                                                <td>{{item.intCantidadComprada}}</td>
                                                <td>{{item.dblImporteCompra |currency}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p class="text-danger" *ngIf="arrayDetalle.length==0">No hay información para el periodo:{{intPeriodo}} y ejercicio {{intEjercicio}} seleccionados</p>
                                </div>

                            </div>
                        </td>
                    </ng-container>



                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20,30,60,120,240]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>

</div>