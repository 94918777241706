import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeTablero'
})
export class PipeTableroPipe implements PipeTransform {


  transform(items: any[], filterpost: string): any[] {
    if (!items) return [];
    if (!filterpost) return items;

    filterpost = filterpost.toLowerCase();
    return items.filter((it) => {
      return it.strNombreEmpresa.toLowerCase().includes(filterpost);
    });
  }
  
}
