<br><br>
<div class="container">
    <div class="row seccion">
        <div class=" back col-12">
            <mat-tab-group mat-align-tabs="start" class="bodyTab" (selectedTabChange)="yourFn($event)">
                <mat-tab label="Objetivo Actual"> <br><br>
                    <h1 class="title">Objetivo Mix<span class="title2"> Sucursal </span></h1>
                    <br>
                    <div class="row">
                        <div class="col-6 col-sm-8 col-md-6 col-lg-5" *ngIf="lstSucursales">
                            <h4> Seleccione Sucursal</h4>
                            <div class="input-group mb-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Seleccione sucursal</mat-label>
                                    <mat-select [(ngModel)]="sucursalSelected">
                                        <mat-option *ngFor="let item of arraySucursales" [value]="item">
                                            {{item.strEstatus}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button mat-raised-button style="background-color:#3e6294;color:white;height:50px;margin-left:15px;" (click)="fnFiltrarSuc()">Filtrar</button>
                            </div>
                        </div>
                        <div class="col-6 col-sm-8 col-md-6 col-lg-5">
                            <button *ngIf="(strEstatus=='ASIGNADO' || strEstatus== 'RECHAZADO') && (totalAgentesAut==totalAgentes)" class="col-3" mat-raised-button style="background-color: #3e6294;color: white;height: 50px;margin-top: -7px;"
                            (click)="fnGuardar()" [disabled]="btnGuardarD">Enviar a Revisión</button> 
                        </div>
                    </div>
                    <br>
                    <div class="cien">
                        <div class="col-sm-3">
                            <div class="widget widget-rojo" *ngIf="strEstatusActual=='RECHAZADO'">
                                <span class="material-icons">assignment_late</span>
                                <p>RECHAZADO</p>
                            </div>
                            <div class="widget widget-verde" *ngIf="strEstatusActual=='AUTORIZADO'">
                                <span class="material-icons">assignment_turned_in</span>
                                <p>AUTORIZADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatusActual=='ASIGNADO'">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatusActual==''">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatusActual=='PENDIENTE'">
                                <span class="material-icons">assignment_returned</span>
                                <p>PENDIENTE</p>
                            </div>
                        </div>

                        <div class="col-sm-4" *ngIf="strObservaciones!=''">
                            <div class="widget widget-observaciones">
                                <span class="material-icons">
                                    description
                                </span>
                                <p>Observaciones: {{strObservaciones}} </p>
                            </div>
                        </div>

                     
                    </div>

                    <!--Estatus = {{strEstatus}} Observaciones={{strObservaciones}}-->
                    <br>
                    <br>
                    <div class="row">
                        <mat-form-field appearance="fill" class="matcell col-3" style="color: #2494cc;">
                            <mat-label>Promedio de Venta 2023</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoPSA | currency}}"   name="first">
                   
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="matcell col-3" style="color: #00c3af;">
                            <mat-label>Promedio de Venta 2024</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoPS | currency}}"   name="first">
                   
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="matcell col-3" style="    color: #815799; font-weight: 600;">
                            <mat-label>*Objetivo Capturado </mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoA | currency}}"   name="first">
                           
                        </mat-form-field>
                        
                       
                        
                    </div>
                    <br><br><br><br>
                    <div class="row">
                        <div class="col">
                            <div class="divYs"> 
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="headcolS encabezado">Linea</th>
                                            <th scope="col" class="headcol22  encabezado">% Ideal Nacional</th>

                                            <th scope="col" class="subject encabezado">%3 M</th>
                                            <th scope="col" class="subject encabezado">% Agente</th>

                                            <th scope="col" class="subject encabezado">Objetivo Capturado</th>
                                            <th scope="col" class="subject encabezado">Último Trimestre 2024</th>
                                            <th scope="col" class="subject encabezado">Último Trimestre 2023</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of arrayHistorico; index as i; ">
                                            <th class="headcolS panel">{{item.strLinea}}</th>
                                            <td class="headcol22 text-center fondoLinea">{{item.dblPObjetivo*100|number:'1.1-2'}}%</td>
                                            <td class="text-center cuerpo1">
                                                <span [ngClass]="{'text-success':item.dblPromedio >=item.dblPObjetivo,
                                                    'text-danger':item.dblPromedio<item.dblPObjetivo}">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td class="text-center">
                                                <span [ngClass]="{'text-success':item.dblPAgente >=item.dblPObjetivo 
                                                    || item.dblPAgente >=item.dblPromedio,'text-danger':item.dblPAgente <item.dblPObjetivo 
                                                    || item.dblPAgente <item.dblPromedio}"><b>{{item.dblPAgente*100|number:'1.1-2'}}%</b></span>
                                            </td>
                                            <td class="text-right">{{item.dblObjetivo| currency}}</td>
                                            <td class="text-right pe-5">{{item.promedioAct| currency}}</td>
                                            <td class="text-right pe-5">{{item.promedioAnt| currency}}</td>
                                        </tr>
                                        <tr>
                                            <th class="headcolS pie1" colspan=2>Total</th>
                                            <th class="headcol22 pie1" colspan=3> :</th>

                                            
                                            <td class="pie1"> {{1|percent}}</td>
                                            <td class="pie1"> {{1|percent}}</td>
                                            <td class="pie1"> {{dblObjetivoA | currency}}</td>
                                            <td class="pie1">{{dblObjetivoPS|currency}}</td>
                                            <td class="pie1">{{dblObjetivoPSA|currency}}</td>
                                             </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Objetivo Siguiente Mes" (click)="fnGetHistorial()"> <br><br>


                    <h1 class="title">Objetivo Mix<span class="title2"> Sucursal Siguiente Mes </span></h1>
                    <br>
                  
                    <div class="row d-flex justify-content-between ">
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="widget widget-rojo" *ngIf="strEstatus=='RECHAZADO'">
                                <span class="material-icons">assignment_late</span>
                                <p>RECHAZADO</p>
                            </div>
                            <div class="widget widget-verde" *ngIf="strEstatus=='AUTORIZADO'">
                                <span class="material-icons">assignment_turned_in</span>
                                <p>AUTORIZADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatus=='ASIGNADO'">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatus==''">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatus=='PENDIENTE'">
                                <span class="material-icons">assignment_returned</span>
                                <p>PENDIENTE</p>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-lg-3" *ngIf="strObservaciones!=''">
                            <div class="widget widget-observaciones">
                                <span class="material-icons">
                                    description
                                </span>
                                <p>Observaciones: {{strObservaciones}} </p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6  col-lg-3 float-end" >
                            <table class="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Total Agentes</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of arrayEstatusAgentes">
                                        <th scope="row">{{e.intTotal}}</th>
                                        <td>
                                            <span *ngIf="e.strEstatus=='ASIGNADO'" class="material-icons texto-azul-e" title="ASIGNADO">pending</span>
                                            <span *ngIf="e.strEstatus=='PENDIENTE'" class="material-icons texto-azul-e" title="PENDIENTE">assignment_returned</span>
                                            <span *ngIf="e.strEstatus=='AUTORIZADO'" class="material-icons texto-verde-e" title="AUTORIZADO">assignment_turned_in</span>
                                            <span *ngIf="e.strEstatus=='RECHAZADO'" class="material-icons texto-rojo-e" title="RECHAZADO">assignment_late</span>
                                            <!--{{item.strEstatus}}-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
             
                    <br>
                    <br>
                    <div class="row my-3">
                        <div class="col-8 col-sm-8 col-lg-5" *ngIf="lstSucursales">
                            <h4>Seleccione Sucursal</h4>
                            <div class="input-group mb-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Seleccione sucursal</mat-label>
                                    <mat-select [(ngModel)]="sucursalSelected">
                                        <mat-option *ngFor="let item of arraySucursales" [value]="item">
                                            {{item.strEstatus}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> <br>
                                <button mat-raised-button style="background-color:#3e6294;color:white;height:50px;margin-left:15px;" (click)="fnFiltrarSuc()">Filtrar</button>
                            </div>
                        </div>
                        <div  class="col-4 col-sm-4 col-lg-5" > <label></label><br><br>
                            <button  *ngIf="(totalAgentesAut==totalAgentes) && (strEstatus!= 'AUTORIZADO' && strEstatus!= 'PENDIENTE')"  mat-raised-button style="background-color: #3e6294;color: white;height: 50px;margin-top: -7px;" (click)="fnGuardar()" [disabled]="btnGuardarD">Enviar a Revisión</button>
                        </div>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="fill" class="matcell col-4" style="color: #2494cc;">
                            <mat-label>Promedio de venta 2023 </mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoPSA | currency}}"   name="first">
                         
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="matcell col-4" style="color: #00c3af;">
                            <mat-label>Promedio de venta 2024 </mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoPS | currency}}"   name="first">
                         
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="matcell col-4" style="color: #815799; font-weight: 600;">
                            <mat-label>Objetivo Capturado </mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoA | currency}}"   name="first">
                          
                        </mat-form-field>
                       
                          
                        
                        <!--*ngIf="(strEstatus=='ASIGNADO' || strEstatus== 'RECHAZADO') && (totalAgentesAut==totalAgentes)"-->
                    </div>
                    <br><br><br><br>
                    <div class="row">
                        <div class="col">
                            <div class="divYs">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="headcolS encabezado">Linea</th>
                                            <th scope="col" class="headcol22  encabezado">% Ideal Nacional</th>

                                            <th scope="col" class="subject encabezado">%6 M</th>
                                            <th scope="col" class="subject encabezado">% Agente</th>

                                            <th scope="col" class="subject encabezado">Objetivo Capturado</th>
                                            <th scope="col" class="subject encabezado">Último Trimestre 2024</th>   
                                            <th scope="col" class="subject encabezado">Último Trimestre 2023</th>   
                                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of arrayHistorico; index as i; ">
                                            <th class="headcolS panel">{{item.strLinea}}</th>
                                            <td class="headcol22 text-center fondoLinea">{{item.dblPObjetivo*100|number:'1.1-2'}}%</td>
                                            <td class="text-center cuerpo1">
                                                <span [ngClass]="{'text-success':item.dblPromedio >=item.dblPObjetivo,
                                                    'text-danger':item.dblPromedio<item.dblPObjetivo}">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td class="text-center">
                                                <span [ngClass]="{'text-success':item.dblPAgente >=item.dblPObjetivo 
                                                    || item.dblPAgente >=item.dblPromedio,'text-danger':item.dblPAgente <item.dblPObjetivo 
                                                    || item.dblPAgente <item.dblPromedio}"><b>{{item.dblPAgente*100|number:'1.1-2'}}%</b></span>
                                            </td>
                                            <td class="text-right pe-5">{{item.dblObjetivo| currency}}</td>
                                            <td class="text-right pe-5">{{item.promedioAct| currency}}</td>
                                            <td class="text-right pe-5">{{item.promedioAnt| currency}}</td>
                                        </tr>
                                        <tr>
                                            <td class="headcolS panel pie1">Total: </td>
                                            <td class="headcol22  pie1"> {{1|percent}}</td>
                                            <td class="pie1"> {{1|percent}}</td>
                                            <td class="pie1"> {{1|percent}}</td>
                                            <td class="pie1">{{dblObjetivoA|currency}}</td>
                                            <td class="pie1">{{dblObjetivoPS|currency}}</td>
                                            <td class="pie1">{{dblObjetivoPSA|currency}}</td>
                                            <!--<td class="pie1"> </td>
                                            <td class="pie1"> </td>
                                            <td class="pie1"> </td>
                                           <td class="pie1" *ngFor="let item of arrayEncabezado; ">{{ item.total| currency}}</td>-->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Objetivo Agentes">
                    <!--*ngIf="strEstatus=='AUTORIZADO'"-->
                    <br><br>
                    <div class="row">
                        <div class="col-12 col-sm-8 col-md-6 col-lg-5" *ngIf="lstSucursales">
                            <h4>Seleccione Sucursal</h4>
                            <div class="input-group mb-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Seleccione sucursal</mat-label>
                                    <mat-select [(ngModel)]="sucursalSelected">
                                        <mat-option *ngFor="let item of arraySucursales" [value]="item">
                                            {{item.strEstatus}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button mat-raised-button style="background-color:#3e6294;color:white;height:50px;margin-left:15px;" (click)="fnFiltrarSuc()">Filtrar</button>
                            </div>
                        </div>

                     

                        <div class="col-12 col-md-7 col-lg-4 ">
                            <h4>Seleccione Agente</h4>
                            <div class="input-group mb-3 ">
                                <mat-form-field appearance="fill" style="width: 65%;">
                                    <mat-label>Agente</mat-label>
                                    <mat-select [(ngModel)]="agenteSelected">
                                        <!--(change)="fnFiltrar()"-->
                                        <mat-option *ngFor="let item of arrayAgentes" [value]="item">
                                            {{item.strLinea}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button mat-raised-button style="  background-color: #3e6294;  color: white;height: 50px; margin-left: 5px;" (click)="fnFiltrar()">Filtrar</button>
                            </div>
                        </div>

                       
                    </div>

                    <!------------HISTORICO DE VENTA --------->
                 
                    <!------------FIN HISTORICO DE VENTA --------->
                    <br>
                    <div class="row" *ngIf='arrayObjetivoCapturadoSuc.length>0'>
                        <h1 class="title">---Objetivo Capturado <span class="title2">{{agenteSelected.strLinea}} </span></h1>
                        <br>
                        <div class="row">
                            <form class="example-form">
                           
                                

                                <mat-form-field class="example-full-width inputObj col-3" appearance="fill" style="color: #2494cc;">
                                    <mat-label>Promedio de Venta 2023</mat-label>
                                    <input matInput placeholder="0" value="{{objetivoPromedioAnterior | currency}}"   name="dos">
                                </mat-form-field>
                                <mat-form-field class="example-full-width inputObj col-3" appearance="fill" style="    color: #00c3af;">
                                    <mat-label>Promedio de Venta 2024</mat-label>
                                    <input type="text" matInput placeholder="0" value="{{ objetivoPromedio | currency}}"  name="cuatro">
                                </mat-form-field>
                                <mat-form-field class="example-full-width  inputObj col-3" appearance="fill" style="    color: #815799; font-weight: 600;">
                                    <mat-label>Objetivo Capturado</mat-label>
                                    <input matInput placeholder="0" value="{{dblObjetivoC|currency}}"  name="tres">
                                </mat-form-field>
                               

                            </form>
                        </div>
                        <br>
                        <div class="row ">
                            <div class="col-8">
                                <table class="table ">
                                    <thead>
                                        <tr>
                                            <th scope="col">Linea</th>
                                            <th scope="col">Objetivo Capturado</th>
                                            <th scope="col">último trimestre 2024 </th>
                                            <th scope="col">último trimestre 2023 </th>
                                            <th scope="col">%Objetivo Capturado</th>
                                            <th scope="col">%6 meses</th>
                                            <th scope="col">%Ideal</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of arrayObjetivoCapturadoSuc; index as i; ">
                                            <th scope="row">{{item.strLinea}}</th>
                                            <td><b>${{item.dblObjetivo|number:'1.1-2'}}</b></td>
                                            <td>${{item.promedioAct|number:'1.1-2'}}</td>
                                            <td>${{item.promedioAnt|number:'1.1-2'}}</td>
                                            <td [ngClass]="{'text-danger':item.dblObjetivo/dblObjetivoC<item.dblObjetivoNacional||item.dblObjetivo/dblObjetivoC<item.dblPObjetivo,
                                        'text-success':item.dblObjetivo/dblObjetivoC>=item.dblObjetivoNacional||item.dblObjetivo/dblObjetivoC>=item.dblPObjetivo}">
                                        *{{(item.dblObjetivo/dblObjetivoC)*100 |number:'1.1-2'}}%</td>
                                           
                                        <td [ngClass]="{'text-danger':item.dblObjetivoNacional<item.dblPObjetivo,'text-success':item.dblObjetivoNacional>=item.dblPObjetivo}">{{item.dblObjetivoNacional*100|number:'1.1-2'}}%</td>
                                            <td style="color: #118ce4;  font-weight: 500;">{{item.dblPObjetivo*100|number:'1.1-2' }}%</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div class="col-4">
                                <div class="form-group "><br>
                                    <textarea [(ngModel)]="strObservacionesS" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    <br>
                                    <!--<label for="exampleFormControlTextarea1">Estatus: {{strEstatusS}}</label>-->
                                    <div class="col-8">
                                        <div class="widget widget-rojo" *ngIf="strEstatusS=='RECHAZADO'">
                                            <span class="material-icons">assignment_late</span>
                                            <p>RECHAZADO</p>
                                        </div>
                                        <div class="widget widget-verde" *ngIf="strEstatusS=='AUTORIZADO'">
                                            <span class="material-icons">assignment_turned_in</span>
                                            <p>AUTORIZADO</p>
                                        </div>
                                        <div class="widget widget-azul" *ngIf="strEstatusS=='ASIGNADO'">
                                            <span class="material-icons">pending</span>
                                            <p>ASIGNADO</p>
                                        </div>
                                        <div class="widget widget-azul" *ngIf="strEstatusS=='PENDIENTE'">
                                            <span class="material-icons">assignment_returned</span>
                                            <p>PENDIENTE</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row"> <br><br>
                                    <button class="col-5" mat-raised-button style="  background-color: #849974; 
                                         color: white;height: 50px;    margin-top: 60px;" (click)="fnAutorizar('AUTORIZADO')" *ngIf="btnAutorizar&&strEstatusS=='PENDIENTE'">Autorizar</button>
                                    <div class="col-2"></div>
                                    <button class="col-5" mat-raised-button style="background-color: #613a43;color: white;height: 50px;margin-top: 60px;" (click)="fnAutorizar('RECHAZADO')" *ngIf="btnRechazar&&strEstatusS=='PENDIENTE'">Rechazar</button>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>

                    </div>

                    <div class="row">
                        <div class="col-12">
                            <br><br><br>
                            <h2 class="title">Objetivos <span class="title2">Asignados</span></h2>
                            <br>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Agente</th>
                                        <th scope="col">Objetivo</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayTotalObj">
                                        <th scope="row">{{item.strAgente}} - {{item.strNombreAgente}}</th>
                                        <td>{{item.dblObjetivo|currency}}</td>
                                        <td>
                                            <span *ngIf="item.strEstatus=='ASIGNADO'" class="material-icons texto-azul-e" title="ASIGNADO">pending</span>
                                            <span *ngIf="item.strEstatus=='PENDIENTE'" class="material-icons texto-azul-e" title="PENDIENTE">assignment_returned</span>
                                            <span *ngIf="item.strEstatus=='AUTORIZADO'" class="material-icons texto-verde-e" title="AUTORIZADO">assignment_turned_in</span>
                                            <span *ngIf="item.strEstatus=='RECHAZADO'" class="material-icons texto-rojo-e" title="RECHAZADO">assignment_late</span>
                                            <!--{{item.strEstatus}}-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <br><br><br>
</div>
 