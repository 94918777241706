import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  public url:string = '';
  public urlPaginaEcodeli:string = '';
  public urlEnc:string = '';
  public urlTYM:string = '';
  public urlRutaComercial:string = ''
  public urlAltaOperarios:string = '';
  public urlLaborae:string = '';
  public urlAppMovil:string = '';
  public urlBajaPersonal:string = '';
  public urlNuevaIndustrial:string = '';
  

  constructor(private _http: HttpClient) {
    this.url='https://www.limpiezamexico.mx/ApiRest/APIPaginaIndustrial/v1/paginaIndustrial/';
		this.urlPaginaEcodeli = "https://www.cleanclean.mx/ApiRest/APIPaginaWeb/v1/PaginaEcodeli/";
    this.urlEnc='https://www.limpiezamexico.mx/ApiRest/APITicket/v1/Ticket/';
    this.urlTYM='https://www.limpiezamexico.mx/ApiRest/APITiempos/v1/Tiempos/';
    this.urlRutaComercial='https://www.limpiezamexico.mx/ApiRest/APIComercialEIndustrial/v1/ComercialEIndustrial/';
    this.urlAltaOperarios='https://www.limpiezamexico.mx/ApiRest/APIEncuestas/v1/Encuestas/';
    this.urlLaborae='https://www.limpiezamexico.mx/ApiRest/APILaborae/v1/Laborae/';
    this.urlAppMovil = 'https://www.limpiezamexico.mx/ApiRest/APIAppMovilInd/v1/AppMovilInd/';
    this.urlBajaPersonal='https://www.limpiezamexico.mx/ApiRest/APIBajaPersonal/v1/bajaPersonal/'
    this.urlNuevaIndustrial='https://www.limpiezamexico.mx/ApiRest/ApiNuevaIndustrial/v1/nuevaIndustrial/'
   }

   
   sp_ScordCard(objLogin:any):Observable<any>{
    return this._http.post(this.url+'sp_ScordCard',objLogin);
  }
  
   sp_Leads(objLogin:any):Observable<any>{
    return this._http.post(this.url+'sp_Leads',objLogin);
  }

  sp_Contactame(objLogin:any):Observable<any>{
    return this._http.post(this.url+'sp_Contactame',objLogin);
  }

   getSistemaEncuestas(objLogin:any):Observable<any>{
     return this._http.post(this.url+'sp_SistemaEncuestas',objLogin);
   }
 
   getReciboNomina(objLogin:any):Observable<any>{
     return this._http.post(this.url+'GetEcoNomina',objLogin);
   }
 
   sp_DatosPersonales(objLogin:any):Observable<any>{
     return this._http.post(this.url+'sp_DatosPersonales',objLogin);
   }
 
   getSistemaEncuestasEncargado(objLogin:any):Observable<any>{
     return this._http.post(this.urlEnc+'sp_SistemaEncuestasEncargados',objLogin);
   }
 
   getMaterialesAnuales(objLogin:any):Observable<any>{
     return this._http.post(this.urlTYM+'sp_MaterialesAnuales',objLogin);
   }

   SetPeritaje(objLogin:any):Observable<any>{
    return this._http.post(this.urlTYM+'SetPeritaje',objLogin);
  }

  GetIInfoEdificios(objLogin:any):Observable<any>{
    return this._http.post(this.urlTYM+'GetIInfoEdificios',objLogin);
  }
 
   getInventarioIndustrial(objLogin:any):Observable<any>{
     return this._http.post(this.urlRutaComercial+'sp_GetInventario_Industrial',objLogin);
   }
 
   getRelojChecadorOperarios(objLogin:any):Observable<any>{
     return this._http.post(this.url+'sp_RelojChecadorOperarios',objLogin);
   }
 
   getRelojChecador(objLogin:any):Observable<any>{
     return this._http.post(this.url+'sp_RelojChecador',objLogin);
   }
 
   getDefaultRH(objLogin:any):Observable<any>{
     return this._http.post(this.url+'sp_DefaultRH',objLogin);
   }
 
   getAltaProspectos(objLogin:any):Observable<any>{
     return this._http.post(this.urlEnc+'sp_insertarProspectoOperario_Encargado',objLogin);
   }
 
   GetNacionalidad(objLogin:any):Observable<any>{
     return this._http.post(this.urlAltaOperarios+'GetNacionalidad',objLogin);
   }
 
   GetEstadoCivil(objLogin:any):Observable<any>{
     return this._http.post(this.urlAltaOperarios+'GetEstadoCivil',objLogin);
   }
 
   GetLugarNacimiento(objLogin:any):Observable<any>{
     return this._http.post(this.urlAltaOperarios+'GetLugarNacimiento',objLogin);
   }
 
   GetTurnoFiltrado(objLogin:any):Observable<any>{
     return this._http.post(this.urlAltaOperarios+'GetTurnoFiltrado',objLogin);
   }
 
   GetPersonalPorCC(objLogin:any):Observable<any>{
     return this._http.post(this.urlAltaOperarios+'GetPersonalPorCC',objLogin);
   }
 
   GetPuestoFiltrado(objLogin:any):Observable<any>{
     return this._http.post(this.urlEnc+'GetPuestoFiltrado',objLogin);
   }
 
   getAllSATCP(objLogin:any):Observable<any>{
     return this._http.post(this.urlAltaOperarios+'getAllSATCP',objLogin);
   }
 
   getLaboraeArticulos(objLogin:any):Observable<any>{
     return this._http.post(this.urlLaborae+'sp_LaboraeArticulos',objLogin);
   }
 
   getLaboraeOpciones(objLogin:any):Observable<any>{
     return this._http.post(this.urlLaborae+'sp_LaboraeOpciones',objLogin);
   }
 
   getLaboraeGuardarRequisicion(objLogin:any):Observable<any>{
     return this._http.post(this.urlLaborae+'sp_LaboraeSetRequisicion',objLogin);
   }
   
   sp_Laborae(objLogin:any):Observable<any>{
    return this._http.post(this.urlLaborae+'sp_Laborae',objLogin);
  }

   getAltaPreProspectos(objLogin:any):Observable<any>{
     return this._http.post(this.url+'sp_AgendaReclutadores',objLogin);
   }
 
   getCodigoPostal(objLogin:any):Observable<any>{
     return this._http.post(this.urlEnc+'sp_CodigoPostal',objLogin);
   }
 
   getAppMovil(objLogin:any):Observable<any>{
     return this._http.post(this.urlAppMovil+'spAppMovil_Ind',objLogin);
   }
   spListaAsistencia(objLogin:any):Observable<any>{
     return this._http.post(this.url+'spListaAsistencia',objLogin);
   }

   sp_CRUD_NUEVAPAGINA(objLogin:any):Observable<any>{
     return this._http.post(this.urlNuevaIndustrial+'sp_CRUD_NUEVAPAGINA',objLogin);
   }
 
   sp_AppInsertaCronograma(objLogin:any):Observable<any>{
     return this._http.post(this.urlTYM+'sp_AppInsertaCronograma',objLogin);
   }
   GetInfoPersonal(objLogin:any):Observable<any>{
     return this._http.post(this.urlBajaPersonal+'GetInfoPersonal',objLogin);
   }
 
   GetMaterialesCotizacion(objLogin:any):Observable<any>{
     return this._http.post(this.urlTYM+'GetMaterialesCotizacion',objLogin);
   }

   GetSeccionesCotizacion(objLogin:any):Observable<any>{
    return this._http.post(this.urlTYM+'GetSeccionesCotizacion',objLogin);
  }

  GetCabeceraCotizacion(objLogin:any):Observable<any>{
    return this._http.post(this.urlTYM+'GetCabeceraCotizacion',objLogin);
  }
  GetActividadesCotizacion(objLogin:any):Observable<any>{
    return this._http.post(this.urlTYM+'GetActividadesCotizacion',objLogin);
  }
  sp_CheckList(objLogin:any):Observable<any>{
    return this._http.post(this.url+'sp_CheckList',objLogin);
  }
  sp_PermisosVacaciones(objLogin:any):Observable<any>{
    return this._http.post(this.urlNuevaIndustrial+'sp_PermisosVacaciones',objLogin);
  }
  sp_Recibos_No_timbrados(objLogin:any):Observable<any>{
    return this._http.post(this.url+'sp_Recibos_No_timbrados',objLogin);
  }

  sp_Presupuesto(objLogin:any):Observable<any>{
    return this._http.post(this.url+'sp_Presupuesto',objLogin);
  }
 
 
   makeFileRequest(url:string, params:Array<string>, files:Array<File>){
     return new Promise((resolve, reject)=>{
       var formData: any =new FormData();
       var xhr = new XMLHttpRequest();
 
       for(var i =0; i <files.length; i++){
         formData.append('uploads[]', files[i], files[i].name);
         xhr.onreadystatechange = function(){
           if(xhr.readyState==4){
             if(xhr.status == 200){
               resolve(JSON.parse(xhr.response));
             }else{
               reject(xhr.response);
             }
           }
         };
       }
     
       xhr.open("POST", url, true)
       xhr.send(formData)
     });
 
   }

   public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
   
}
