import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DataDBService } from '../servicios/data-db.service';

export interface CapturaObj{
  strCliente: string;
  objAccesorios: string;
  objDespachadores: string;
  objKimberly: string;
  objQuimicos: string;
  objRubbermaid: string;
  objBlueTissue: string;
  objTork: string;
  objDegrapack: string;
  objFoaom: string;
  objDove: string;
  objTapetes:string;
  total:string;
  promedio:string;
  guardar:string;
}

@Component({
  selector: 'app-obj-mix',
  templateUrl: './obj-mix.component.html',
  styleUrls: ['./obj-mix.component.css']
})

export class ObjMixComponent implements OnInit {
  @ViewChild('btnCaptura') btnCaptura!: ElementRef;
  columnsCapObj: string[] = ['strCliente', 'objAccesorios', 'objDespachadores', 'objKimberly',
     'objQuimicos','objRubbermaid','objBlueTissue','objTork','objDegrapack','objFoaom','objDove',
    'objTapetes','total','promedio','guardar'];
  datosObjx: MatTableDataSource<CapturaObj>;
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayAccesos:any=[];
  public strConexion:string="";
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public spiner:boolean=false;
  public arrayClientes:any=[];
  public tagName:any;
  public strSearch:string="";
  public spinerHistVenta:boolean = false;
  public tituloart:string = ""
  public arrayHistVenta:any = []
  public arrayMontosLineas: any = [];
  public nombreMesA:string=""
  public nombreMes3:string=""
  public nombreMes2:string=""
  public  nombreMes1:string=""
  public intPerIni:number =0;
  public intEjercicioSig:number;
  public intPeriodoSig:number;
  public totalMov=0;
  public dblSumatoriaGeneral:number = 0
  totalMes1 = 0;
  totalMes2 = 0;
  totalMes3 = 0;
  totalMesA = 0; 
  totalCan1 = 0;
  totalCan2 = 0; // Este valor lo inicializas correctamente ahora
  totalCan3 = 0;
  totalCanA = 0;
  public periodoMostrar:any = {nombre:"",periodo:0}
  public objLineas:any={
    objAccesorios: 0,
    objDespachadores: 0,
    objKimberly: 0,
    objQuimicos: 0,
    objRubbermaid: 0,
    objBlueTissue: 0,
    objTork: 0,
    objDegrapack: 0,
    objFoaom: 0,
    objDove: 0,
    objTapetes: 0,
    dblTotal: 0
};
public cteModal :string=""
public cteNombreModal :string=""
public objLineasTri:any={
  objAccesorios: 0,
  objDespachadores: 0,
  objKimberly: 0,
  objQuimicos: 0,
  objRubbermaid: 0,
  objBlueTissue: 0,
  objTork: 0,
  objDegrapack: 0,
  objFoaom: 0,
  objDove: 0,
  objTapetes: 0,
  dblTotal: 0
};
public arrayPeriodoHist = [
  { mes: 'Enero' }, // 0
  { mes: 'Febrero' }, // 1
  { mes: 'Marzo' }, // 2
  { mes: 'Abril' }, // 3
  { mes: 'Mayo' }, // 4
  { mes: 'Junio' }, // 5
  { mes: 'Julio' }, // 6
  { mes: 'Agosto' }, // 7
  { mes: 'Septiembre' }, // 8
  { mes: 'Octubre' }, // 9
  { mes: 'Noviembre' }, // 10
  { mes: 'Diciembre' } // 11
];
  public objCaptura:any ={
    strEstatus:"ASIGNADO",//--- 3 RECHAZADO --- 0 ASIGNADO--- 1 PENDIENTE--- 2 AUTORIZADO
    intEstatus:0
  };
  @ViewChild('paginatorObjx') paginatorObjx: MatPaginator;
  @ViewChild('sortObjx') sortObjx: MatSort;
  @ViewChild('thmed', { static: false }) thmed!: ElementRef;
  public thwidt:number = 0
  public spOpcionSeleccionada:string="SIGUIENTE"
  constructor(private indexedDBService: DataDBService,private paginatorIntl: MatPaginatorIntl,private localSt:LocalStorageService,elem:ElementRef,public _alertify: AlertifyService, private router: Router,   private _httpService:PeticionesService, private _filtroServ :FiltrosService) {
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
    this.intPerIni =  fecha.getMonth()+ 1;

    this.intEjercicioSig = fecha.getFullYear()
    this.intPeriodoSig = fecha.getMonth()+ 1
    this.spOpcionSeleccionada = 'SIGUIENTE';
    //array de accesos
    //obtener niveles de acceso
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
      }
    }
    this.customizeItemsPerPageLabel();
   }

  ngOnInit(): void { 
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      this.strConexion = this.localSt.retrieve('strConexion'); 

      let objPartner = this.arrayAccesos[0];
    
      

    });
    this.fnGetFiltros();
    this.fnGetClientes("Captura","SIGUIENTE");
    this.fnGetHistLineas("0");
  }

  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
      this.arrayAgentes.sort((a, b) => {
        if (a.strNombre < b.strNombre) {
          return -1;
        }
        if (a.strNombre > b.strNombre) {
          return 1;
        }
        return 0;
      });
      this.strAgente=this.localSt.retrieve('strAgente').toString();
      console.log(this.strAgente)
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
      this.arraySucursales.sort((a, b) => {
        if (a.strNombreSucursal < b.strNombreSucursal) {
          return -1;
        }
        if (a.strNombreSucursal > b.strNombreSucursal) {
          return 1;
        }
        return 0;
      });
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    // console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
      this.arrayGerentes.sort((a, b) => {
        if (a.strNombre < b.strNombre) {
          return -1;
        }
        if (a.strNombre > b.strNombre) {
          return 1;
        }
        return 0;
      });
    });
  } 
  
  fnTableobjX() {
    this.datosObjx.paginator = this.paginatorObjx;
    this.datosObjx.sort = this.sortObjx;
  }




  fnGetClientes(tipo:string,opcion:string){
    this.spOpcionSeleccionada = opcion
    this.spiner= true;
    let IntEjerc=0,intPer=0;
    intPer= tipo == "Captura"?this.intPeriodo+1:this.intPeriodo;
    IntEjerc = intPer == 13 ? this.intEjercicio + 1 :this.intEjercicio;
    let obj = {
       strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""),
       intEjercicio: IntEjerc,
       intPeriodo: intPer == 13?1:intPer,
       strConexion:this.strConexion,
       strAccion:'getClientes',
    };
    this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Objetivos_Mix').subscribe(
      result => {
        const periodo = this.arrayPeriodo.find(p => p.strPeriodo === obj.intPeriodo);
        
        this.periodoMostrar.nombre = periodo.strNombre
        this.periodoMostrar.periodo = periodo.strPeriodo
        this.arrayClientes = result
        console.log(this.arrayClientes);
        this.arrayClientes.forEach(obj => {
          obj.objAccesoriosAnt = obj.objAccesorios
          obj.objDespachadoresAnt = obj.objDespachadores
          obj.objKimberlyAnt = obj.objKimberly
          obj.objQuimicosAnt = obj.objQuimicos
          obj.objRubbermaidAnt = obj.objRubbermaid
          obj.objBlueTissueAnt = obj.objBlueTissue
          obj.objTorkAnt = obj.objTork
          obj.objDegrapackAnt = obj.objDegrapack
          obj.objFoaomAnt = obj.objFoaom
          obj.objDoveAnt = obj.objDove
          obj.objTapetesAnt = obj.objTapetes
        });
        this.objLineas.objAccesorios = this.arrayClientes.map(t => t.objAccesorios).reduce((acc, value) => acc + value, 0);
        this.objLineas.objDespachadores = this.arrayClientes.map(t => t.objDespachadores).reduce((acc, value) => acc + value, 0);
        this.objLineas.objKimberly = this.arrayClientes.map(t => t.objKimberly).reduce((acc, value) => acc + value, 0);
        this.objLineas.objQuimicos = this.arrayClientes.map(t => t.objQuimicos).reduce((acc, value) => acc + value, 0);
        this.objLineas.objRubbermaid = this.arrayClientes.map(t => t.objRubbermaid).reduce((acc, value) => acc + value, 0);
        this.objLineas.objBlueTissue = this.arrayClientes.map(t => t.objBlueTissue).reduce((acc, value) => acc + value, 0);
        this.objLineas.objTork = this.arrayClientes.map(t => t.objTork).reduce((acc, value) => acc + value, 0);
        this.objLineas.objDegrapack = this.arrayClientes.map(t => t.objDegrapack).reduce((acc, value) => acc + value, 0);
        this.objLineas.objFoaom = this.arrayClientes.map(t => t.objFoaom).reduce((acc, value) => acc + value, 0);
        this.objLineas.objDove = this.arrayClientes.map(t => t.objDove).reduce((acc, value) => acc + value, 0);
        this.objLineas.objTapetes = this.arrayClientes.map(t => t.objTapetes).reduce((acc, value) => acc + value, 0);
        this.objLineas.dblTotal = (this.objLineas.objAccesorios+this.objLineas.objDespachadores+this.objLineas.objKimberly+this.objLineas.objQuimicos+
          this.objLineas.objRubbermaid+this.objLineas.objBlueTissue+this.objLineas.objTork+this.objLineas.objDegrapack
         + this.objLineas.objFoaom+this.objLineas.objDove+this.objLineas.objTapetes)
        this.spiner = false; 
        this.datosObjx = new MatTableDataSource(this.arrayClientes);
        this.fnTableobjX()

     
      },error => {
        this.spiner = false;
        console.error('Error al consumir la API:', error);
    });
  }
  onlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
  handleInput(event: Event) {
    const input = event.target as HTMLInputElement;
    // Elimina el 0 inicial si hay un valor nuevo en el input
    if (input.value.startsWith('0') && input.value.length > 1) {
      input.value = input.value.replace(/^0+/, '');
    }
  }
  onValueChange(newValue: number,obj:any,linea:string) {
    if (newValue > 0) {
      if(linea == "acce"){
        obj.objAccesoriosAnt = 0
      }else if(linea == "despa"){
        obj.objDespachadoresAnt = 0
      }else if(linea == "kim"){
        obj.objKimberlyAnt = 0
      }else if(linea == "qui"){
        obj.objQuimicosAnt = 0
      }else if(linea == "rubb"){
        obj.objRubbermaidAnt = 0
      }else if(linea == "blue"){
        obj.objBlueTissueAnt = 0
      }else if(linea == "tork"){
        obj.objTorkAnt = 0
      }else if(linea == "degra"){
        obj.objDegrapackAnt = 0
      } else if(linea == "foa"){
        obj.objFoaomAnt = 0
      }else if(linea == "dove"){
        obj.objDoveAnt = 0
      }else if(linea == "tap"){
        obj.objTapetesAnt = 0
      }
    }
  }

  fnGetHistLineas(tipo:string=""){
    this.spiner= true;
    let IntEjerc=0,intPer=0;
  
    intPer= this.spOpcionSeleccionada == "SIGUIENTE"?(this.intPeriodo+1 >= 13?1:this.intPeriodo+1 ):this.intPeriodo;
    IntEjerc = this.intPeriodo+1 >= 13 && this.spOpcionSeleccionada == "SIGUIENTE"  ? this.intEjercicio + 1 :this.intEjercicio;
    let obj = {
      strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""),
       intEjercicio: IntEjerc,
       intPeriodo: intPer,
       strConexion:this.strConexion,
       strAccion:'getTabla_Historico_agente',
    };
    console.log(obj)
    this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Objetivos_Mix').subscribe(
      result => {
          this.dblSumatoriaGeneral = 0;
        this.arrayMontosLineas = result
        console.log(result)
        this.arrayMontosLineas.forEach(xo => {
          if(xo.strLinea == "ACCESORIOS"){
            this.objLineasTri.objAccesorios = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "BLUE TISSUE"){
            this.objLineasTri.objBlueTissue = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "DEGRAPACK"){
            this.objLineasTri.objDegrapack = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "DESPACHADORES ECODELI"){
            this.objLineasTri.objDespachadores = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "DOVE"){
            this.objLineasTri.objDove = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "FOAOM"){
            this.objLineasTri.objFoaom = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "KIMBERLY"){
            this.objLineasTri.objKimberly = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "QUIMICOS"){
            this.objLineasTri.objQuimicos = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "RUBBERMAID"){
            this.objLineasTri.objRubbermaid = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "TAPETES"){
            this.objLineasTri.objTapetes = xo.dblUltimo_3_Meses
          }else if(xo.strLinea == "TORK"){
            this.objLineasTri.objTork = xo.dblUltimo_3_Meses
          }
        });
        this.objCaptura.strEstatus=this.arrayMontosLineas[0].strAutorizado;//--- 3 RECHAZADO --- 0 ASIGNADO--- 1 PENDIENTE--- 2 AUTORIZADO
        this.objCaptura.intEstatus =this.objCaptura.strEstatus=="AUTORIZADO"?2:this.objCaptura.strEstatus=="RECHAZADO"?3:this.objCaptura.strEstatus=="PENDIENTE"?1:0
        this.objLineasTri.dblTotal = (this.objLineasTri.objAccesorios+this.objLineasTri.objDespachadores+this.objLineasTri.objKimberly+this.objLineasTri.objQuimicos+
          this.objLineasTri.objRubbermaid+this.objLineasTri.objBlueTissue+this.objLineasTri.objTork+this.objLineasTri.objDegrapack
         + this.objLineasTri.objFoaom+this.objLineasTri.objDove+this.objLineasTri.objTapetes) /11
        console.log(this.arrayMontosLineas)

        this.arrayMontosLineas.forEach(element => { //Hacer la sumatoria general:
          this.dblSumatoriaGeneral = this.dblSumatoriaGeneral + element.dblObjCapturado;
         
      });
        this.spiner = false; 
        if(tipo=="1"){
          this.fnGuardarTodo();
        }
      },error => {
        this.spiner = false;
        console.error('Error al consumir la API:', error);
        
    });
  }
  
  fnGuardarObjetivos(cte:any){
    let obj = {
      strAgente:this.strAgente,
      strCliente:cte.strCliente,
      intBA: cte.objAccesorios>0?1:0,
      intBQ: cte.objQuimicos>0?1:0,
      intBK: cte.objKimberly>0?1:0,
      intBB: cte.objBlueTissue>0?1:0,
      intBR: cte.objRubbermaid>0?1:0,
      intBT: cte.objTork>0?1:0,
      intBX: cte.objXibu>0?1:0,
      intBD: cte.objDespachadores>0?1:0,
      intBDP: cte.objDegrapack>0?1:0,
      intBFO: cte.objFoaom>0?1:0,
      intBDove: cte.objDove>0?1:0,
      intBTap: cte.objTapetes>0?1:0,
      dblAccesorios: cte.objAccesorios==""||cte.objAccesorios==null?0:cte.objAccesorios,
      dblDespachadores: cte.objDespachadores==""||cte.objDespachadores==null?0:cte.objDespachadores ,
      dblKimberly: cte.objKimberly==""||cte.objKimberly==null?0:cte.objKimberly ,
      dblQuimicos: cte.objQuimicos==""||cte.objQuimicos==null?0:cte.objQuimicos,
      dblRubbermaid: cte.objRubbermaid==""||cte.objRubbermaid==null?0:cte.objRubbermaid,
      dblBlueTissue: cte.objBlueTissue==""||cte.objBlueTissue==null?0:cte.objBlueTissue ,
      dblTork: cte.objTork==""||cte.objTork==null?0:cte.objTork ,
      dblXibu: cte.objXibu==""||cte.objXibu==null?0:cte.objXibu,
      dblDegrapack: cte.objDegrapack==""||cte.objDegrapack==null?0:cte.objDegrapack ,
      dblFoaom: cte.objFoaom==""||cte.objFoaom==null?0:cte.objFoaom ,
      dblDove: cte.objDove==""||cte.objDove==null?0:cte.objDove ,
      dblTapetes: cte.objTapetes==""||cte.objTapetes==null?0:cte.objTapetes ,
      strConexion:this.strConexion,
      strAccion:'setObjetivo_Cte',
    };
    console.log(obj);
    this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Objetivos_Mix').subscribe(
      result => {
        Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
       if(result[0].code == "200"){

        this.fnGetClientes("Captura","SIGUIENTE");
        this.fnGetHistLineas("0");
       } 
      },error => {
        this.spiner = false;
        console.error('Error al consumir la API:', error);
  
    });
  }
  fnFiltrar(){
    this.btnCaptura.nativeElement.click();
    this.fnGetHistLineas("0")
  }

  fnGetHistVenta(obj:any,strLinea:string) {
    this.totalCan1  =0
    this.totalMes1  =0
    this.totalCan2 =0
    this.totalMes2  =0
    this.totalCan3  =0
    this.totalMes3 =0
    this.totalCanA  =0
    this.totalMesA  =0
    let f = new Date();
    let periodoA = f.getMonth();
    console.log(obj)
    this.spinerHistVenta =true;
    this.tituloart =""
    this.tituloart = strLinea;
    this.cteModal = obj.strCliente
    this.cteNombreModal = obj.strNombre

    this.nombreMesA = this.arrayPeriodoHist[periodoA].mes;
    if (periodoA == 0) {
        periodoA = 11
    } else {
        periodoA = periodoA - 1
    }

    this.nombreMes3 = this.arrayPeriodoHist[periodoA].mes;
    if (periodoA - 1 < 0) {
        periodoA = 11
    } else {
        periodoA = periodoA - 1
    }

    this.nombreMes2 = this.arrayPeriodoHist[periodoA].mes;
    if (periodoA - 1 < 0) {
        periodoA = 11
    } else {
        periodoA = periodoA - 1
    }
    this.nombreMes1 = this.arrayPeriodoHist[periodoA].mes;

    let objEnviar = {
      'strCliente': obj.strCliente.replace(/^\s+|\s+$/g, ""),
      'strLinea': strLinea 
        }
    this._httpService.getpagViejitaPlantillas(objEnviar,"getHistoricoXLineaCte").subscribe(
      result => {
        this.arrayHistVenta= result;
        console.log(result)
        this.totalMov =  this.arrayHistVenta.length;
        this.arrayHistVenta.forEach(HL => {
          this.totalCan1 += HL.intCantidadConFactor1;
          this.totalMes1 += HL.dblMes1;
          this.totalCan2 += HL.intCantidadConFactor2;
          this.totalMes2 += HL.dblMes2;
          this.totalCan3 += HL.intCantidadConFactor3;
          this.totalMes3 += HL.dblMes3;
          this.totalCanA += HL.intCantidadConFactorA;
          this.totalMesA += HL.dblMesA;
        });
        this.spinerHistVenta =false;
      },error=>{
        this.spinerHistVenta =false;
        console.log(error.message)
      }); 
  }
  
  fnGuardarTodo(){
    let pCapturado = 0
     let xmlLineas = [];
    let flag = 0;
    let flag2 = 0;
    console.log(this.arrayMontosLineas)

    this.arrayMontosLineas.forEach(element => {
      
      element.dblObjCapturado = element.strLinea == "ACCESORIOS" ? this.objLineas.objAccesorios:
      element.strLinea == "BLUE TISSUE"? this.objLineas.objBlueTissue:
      element.strLinea == "DEGRAPACK"? this.objLineas.objDegrapack:
      element.strLinea == "DESPACHADORES ECODELI" ? this.objLineas.objDespachadores:
      element.strLinea == "DOVE"?this.objLineas.objDove:
      element.strLinea == "FOAOM"?this.objLineas.objFoaom:
      element.strLinea == "KIMBERLY"?this.objLineas.objKimberly:
      element.strLinea == "QUIMICOS"?this.objLineas.objQuimicos:
      element.strLinea == "RUBBERMAID"?this.objLineas.objRubbermaid:
      element.strLinea == "TAPETES" ?this.objLineas.objTapetes:
      element.strLinea == "TORK"?this.objLineas.objTork:0


     
        xmlLineas.push({
            strLinea: element.strLinea,
            dblObjetivo:  element.dblObjCapturado,
            dblPrecio: element.dblPCaptura_Objetivo >0 ?element.dblPCaptura_Objetivo * 100 :0
        });
    });


   let formData = {
      "strAccion": "setObjetivoAgente_N",
      "strAgente": this.strAgente,
      "xmlLineas": xmlLineas
  };
  console.log(formData);
  this._httpService.getApiComprasGral(formData,"sp_ObjMix").subscribe(
    result => {
      let rest = result;

      console.log(rest);


      Swal.fire("Enviado","Se han guardado los valores de su flujo de objetivos propuestos","success")
      this.btnCaptura.nativeElement.click();


    },error=>{
      console.log("Hubo un error, vuelva a intentar");
      console.log(error.message)
    }); 

 
  }


  applyFilterObjx(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosObjx.filter = filterValue.trim().toLowerCase();
    if (this.datosObjx.paginator) {
      this.datosObjx.paginator.firstPage();
    }
  }
}







/* 

  <tbody>
                                    <tr *ngFor="let x of arrayClientes|filtersearch: strSearch : ['strNombreCliente','strCliente'];">
                                        <td class="border-end sticky-col bg-light">
                                            <div class="position-relative">
                                                (<span class="fw-bold fs13">{{x.strCliente}}</span>)
                                                <span class="fs13 fw-bold">{{x.strNombre}}</span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Accesorios')"></i>
                                                <div class=" position-relative">
                                                    <input type="number"  class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'acce')"  [ngClass]="{ 'is-valid': x.objAccesoriosAnt > 0,'is-yellow':x.objAccesorios> 0 && x.objAccesoriosAnt == 0  }" [(ngModel)]="x.objAccesorios" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objAccesorios> 0 && x.objAccesoriosAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DESPACHADORES ECODELI')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'despa')" [ngClass]="{ 'is-valid': x.objDespachadoresAnt > 0,'is-yellow':x.objDespachadores> 0 && x.objDespachadoresAnt == 0}"  [(ngModel)]="x.objDespachadores" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDespachadores> 0  && x.objDespachadoresAnt == 0"></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Kimberly')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'kim')" [ngClass]="{ 'is-valid': x.objKimberlyAnt > 0,'is-yellow':x.objKimberly> 0  && x.objKimberlyAnt == 0}"  [(ngModel)]="x.objKimberly" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objKimberly> 0 && x.objKimberlyAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Quimicos')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'qui')"[ngClass]="{ 'is-valid': x.objQuimicosAnt > 0 ,'is-yellow':x.objQuimicos> 0  && x.objQuimicosAnt == 0}"  [(ngModel)]="x.objQuimicos" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objQuimicos> 0 && x.objQuimicosAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Rubbermaid')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'rubb')" [ngClass]="{ 'is-valid': x.objRubbermaidAnt > 0 ,'is-yellow':x.objRubbermaid> 0  && x.objRubbermaidAnt == 0   }"  [(ngModel)]="x.objRubbermaid" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objRubbermaid> 0  && x.objRubbermaidAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'BLUE TISSUE')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'blue')" [ngClass]="{ 'is-valid': x.objBlueTissueAnt > 0 ,'is-yellow':x.objBlueTissue> 0  && x.objBlueTissueAnt == 0   }"  [(ngModel)]="x.objBlueTissue" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objBlueTissue> 0  && x.objBlueTissueAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'TORK')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'tork')" [ngClass]="{ 'is-valid': x.objTorkAnt > 0 ,'is-yellow':x.objTork> 0  && x.objTorkAnt == 0  }"  [(ngModel)]="x.objTork" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objTork> 0 && x.objTorkAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DEGRAPACK')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'degra')" [ngClass]="{ 'is-valid': x.objDegrapackAnt > 0 ,'is-yellow':x.objDegrapack> 0 && x.objDegrapackAnt == 0  }"  [(ngModel)]="x.objDegrapack" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDegrapack> 0 && x.objDegrapackAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'FOAOM')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'foa')" [ngClass]="{ 'is-valid': x.objFoaomAnt > 0 ,'is-yellow':x.objFoaom> 0 && x.objFoaomAnt == 0  }"  [(ngModel)]="x.objFoaom" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objFoaom> 0  && x.objFoaomAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DOVE')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'dove')" [ngClass]="{ 'is-valid': x.objDoveAnt > 0 ,'is-yellow':x.objDove> 0 && x.objDoveAnt == 0  }"  [(ngModel)]="x.objDove" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDove> 0 && x.objDoveAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'TAPETES')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'tap')" [ngClass]="{ 'is-valid': x.objTapetesAnt > 0,'is-yellow':x.objTapetes> 0 && x.objTapetesAnt == 0 }"  [(ngModel)]="x.objTapetes" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objTapetes> 0 && x.objTapetesAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs13 text-end border-end align-middle">
                                            {{((x.objAccesorios)+(x.objDespachadores)+(x.objKimberly)+(x.objQuimicos)+
                                                (x.objRubbermaid)+(x.objBlueTissue)+(x.objTork)+(x.objDegrapack)+
                                                (x.objFoaom)+(x.objDove)+(x.objTapetes))|currency}}
                                        </td>
                                        <td class="fs13 text-end border-end align-middle"></td>
                                        <td class="text-center border-end align-middle"  [ngClass]="{'d-none':intPerIni != intPeriodo}">
                                            <button class="btn  py-0 px-1  border border-primary shadow-sm" (click)="fnGuardarObjetivos(x)">
                                                <i class="bi bi-floppy2-fill fs-6 text-primary "></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>

*/