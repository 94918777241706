import { Component,OnInit,ViewChild,ViewEncapsulation,  ChangeDetectorRef,ElementRef,HostListener ,OnDestroy  } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import {PlantillafinService} from '../servicios/plantillafin.service';
import { map, shareReplay } from 'rxjs/operators';
import { CarritoNService } from '../servicios/carrito-n.service';
import { CarroService } from '../servicios/carroservice';
import { ComodatoService } from '../servicios/comodato.service';
import { PartnercteService } from '../servicios/partnercte.service';
import {LocalStorage,LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { DataDBService } from '../servicios/data-db.service';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
import {AccesosServiceService}from '../servicios/accesos-service.service'
import { Subscription } from 'rxjs';
import { ScrollService } from '../servicios/scroll.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var bootstrap: any; 
@Component({
  selector: 'app-newmenu',
  templateUrl: './newmenu.component.html',
  styleUrls: ['./newmenu.component.css'],
  encapsulation: ViewEncapsulation.None 
})

export class NewmenuComponent implements OnInit, OnDestroy{
  private unsubscribe$ = new Subject<void>();

  @ViewChild('drawer') drawer: MatSidenav;
 //  @LocalStorage('strUsuario') public strUsuario: any; 
  //@LocalStorage('arrayAccesos') public arrayAccesos: any; 
  //@LocalStorage('arrayAccesoSubMenu') public arrayAccesoSubMenu: any; 
  //@LocalStorage('arrayAccesosMenu') public arrayAccesosMenu: any;
 
  private cartSubscription: Subscription;
  public   isDropdownOpen = false;

  toggleDropdown(event: Event) {
    event.stopPropagation(); // Evita que se cierre el menú al hacer clic en su interior
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event) {
    this.isDropdownOpen = false;
  }
  public objSucursal_envio_partner:any;
  public objEnvio_partner:any ={strTitulo:''};
  public strAlmacen_partner:string;
  public strSucursal_partner:string;
 
  public strSucSeleccionada:string =""; 
  public strNombre_entrega_partner:string='';
  public btnEnvio:boolean =false ;
  public btnPickup:boolean =true;
  public hovCarPartner:boolean =false;
  public arrayDireccion_partner:any=[];
  public arraySucursal_partner:any=[];
  public acceso: any = 0
  public arrayAccesos: any; 
  public arrayAccesoSubMenu: any; 
  public arrayAccesosMenu: any;
  public lenguaje:any="";
  public strUsuario: any;
  public arrayArticulos: any[] = [];
  public openCart: boolean = false;
  public totalCarrito: number = 0;
  public isHovered:boolean=false;
  @ViewChild('idetiqueta') idetiqueta!: ElementRef; 
  @ViewChild('btnCanvas') btnCanvas!:ElementRef;
  @ViewChild('btnCanvas2') btnCanvas2!:ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  public articulos$: Observable<any>;
  public articulosCotizador$: Observable<any>;
  public strArticulo: string = '';
  public strCliente: string = '';
  public strNombre:string;
  public strUsuarioInt:string="";
  mostrarComodatosSubMenu = false;
  public objMix1:boolean=false;
  public compras:boolean=false;
  public spresupuesto:boolean=false;
  public rh:boolean=false;
  public reportes1:boolean=false;
  public ventas1:boolean=false;
  public leads1:boolean=false;
  public ruber:boolean = false;
  public aromia1:boolean = false;
  public directorio :boolean = false;
  public otros1:boolean = false;
  public ecomerce:boolean = false;
  public itemsCotizador:number = 0;
  public itemsCotizador_partner:number = 0;
  public itemsCotizador_partner_cot:number=0;
  public hovCar:boolean = false;
  public hovCarCm:boolean = false;
  public arrayCarritoArts:any;
  public arrayCarritoArtsCmdts:any;
  public dblSubTotal:number = 0 ;
  public strTipoUser:string = "";
  public altoEtiqueta: number = 0;
  public dblSubTotalCmdts:number = 0 ;
  public objCliente:any = {
    strCliente:"",
    strNombre:"",
    strDireccion:"",
    ClienteSeleccionado:false,
    strSucursal:"",
    NombreSucursal:"",
    tipoSucursal:""
  };
  public objClienteCmdts:any = {
    strCliente:"",
    strNombre:"",
    strDireccion:"",
    ClienteSeleccionado:false,
    strSucursal:"",
    NombreSucursal:"",
    tipoSucursal:""
  };
  public ctePartner:any = {};
  public strNumCte:string;
  public strNumCteCmdts:string;
  public strNombreCliente:string="";
  public strNombreSucursal:string="";
  public intTotalItems:number;
  public strSucursal:string="";
  public cteSelect:boolean=false; 
  public strArticuloTienda:string="";
  public strBuscar:string;
  public totalArtTienda:number=0;
  public objFamilia:any;
  public cartItems:any =[];
  public flagMenuCte:boolean=false
  public userData_tienda:any=[];
  public isSidenavOpened = false;
  public arrayCategorias:any=[];
  public tipoUsuario:any;
  public tagName:string='';
  public btnQuitarCte:boolean=false;
  public btnBarra_busqueda: boolean=false;
  public btnCarrito:boolean=false;
  public btnComodato:boolean=false;
  public objPartner_direccionEnvio:any={}
  public btnCarrito_partner: boolean =false;
  public strOpcion_envio_partner:string='Pick Up'
  public strTitulo_Canva_Partner:string='Seleccione Sucursal';
  public strConexion:string  ='';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches),shareReplay());

  constructor(private servicioPartner:PartnercteService,private scrollService: ScrollService,elem:ElementRef,public _serviciosService: PeticionesService,private ServAccesos:AccesosServiceService ,private cartService: EcommerceCarrService, private indexedDBService: DataDBService,private cdr: ChangeDetectorRef,private servPlantilla:PlantillafinService,
    private breakpointObserver: BreakpointObserver,private _peticionesService: PeticionesService, private carroservice:CarroService,
    private _carro: CarritoNService,  private localSt:LocalStorageService, private router: Router,private commodatoservice:ComodatoService,
    private _filtroServ :FiltrosService) {
 
   this.tagName=elem.nativeElement.tagName.toLowerCase();

 
  }

  ngOnInit(): void { 
//inicio de servicios para menu7
  this.indexedDBService.finBusqueda_Articulo_$
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe((nuevoValor) => {
    this.strBuscar=nuevoValor;
  });
  this.indexedDBService.usuario_$
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe((nuevoValor) => {
  this.strUsuario=nuevoValor?nuevoValor==0?'':nuevoValor  :'';
  this.strNombre=this.localSt.retrieve('strNombre')?this.localSt.retrieve('strNombre'):'';   

      if(this.strUsuario && this.strUsuario !='')
      {
         
          
      }
  });

this.indexedDBService.arrayAccesos_$
.pipe(takeUntil(this.unsubscribe$))
.subscribe((nuevoValor) => {
this.arrayAccesos=nuevoValor?nuevoValor:[];
this.acceso = nuevoValor[0]?.strTipoAcceso;
var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);

this.btnQuitarCte= objAcceso.find((o: any) => o.strAccion == 'btnQuitar') ? true : false;  
this.btnBarra_busqueda =  objAcceso.find((o: any) => o.strAccion == 'btnBarra') ? true : false;  
this.btnCarrito=  objAcceso.find((o: any) => o.strAccion == 'btnCarrito') ? true : false;  
this.btnComodato=  objAcceso.find((o: any) => o.strAccion == 'btnComodato') ? true : false; 
this.btnCarrito_partner=  objAcceso.find((o: any) => o.strAccion == 'btnCarrito_Partner') ? true : false;   
if(this.acceso ==1015){
  
  this.servicioPartner.cteSeleccionado$.subscribe(valor => {
    this.ctePartner = valor;
    console.log('Recibido en Componente B:', this.ctePartner);
  });
if( !this.arrayAccesos[0].detalle_envio_partner ) { // si no hay ninguna direccion seleccionada, poner pikup por default con el alamcen del cliente
   this.fnGetSucursales_partner_inicio();
}
else {
  this.fnBuscarSucursal_partner();
}
 
}

});

this.indexedDBService.arrayAccesosMenu_$
.pipe(takeUntil(this.unsubscribe$))
.subscribe((nuevoValor) => {  
  this.arrayAccesosMenu =nuevoValor?nuevoValor:[];
});

this.indexedDBService.arrayAccesoSubMenu_$
.pipe(takeUntil(this.unsubscribe$))
.subscribe((nuevoValor) => {  
  this.arrayAccesoSubMenu= nuevoValor?nuevoValor:[];
}); 

this.indexedDBService.strConexion_$
.pipe(takeUntil(this.unsubscribe$))
.subscribe((nuevoValor) => { 
   this.strConexion = nuevoValor;
});


  this.indexedDBService.arraySucursales_partner_$
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe((nuevoValor) => {
    console.log('se selecciono una sucursal') 
      this.acceso = nuevoValor[0]?.strTipoAcceso;
      //funciones que se ejecutan solo para partners
      //actualizamos el store en la base de datos
      this.indexedDBService.setItem('arrayResult',   nuevoValor );
  });

// ----fin de menu--------


    document.addEventListener('click', (event: any) => {
      const offcanvasElement = document.querySelector('.offcanvas.show');
      if (offcanvasElement && !offcanvasElement.contains(event.target)) { 
        this.btnCanvas.nativeElement.click()
        this.btnCanvas2.nativeElement.click()
      }
    });
    this.fnGetCategorias();
    this.loadCart();

    this.commodatoservice.intTotalItems_C$.subscribe((nuevoValor) => {
      this.intTotalItems = nuevoValor;
    });
    this.commodatoservice.arrayItems_C$.subscribe((nuevoValor) => {
      this.arrayCarritoArtsCmdts = nuevoValor;
    });
    this.commodatoservice.dblSubTotalItems_C$.subscribe((nuevoValor) => {
      this.dblSubTotalCmdts = nuevoValor;
    });

    this.commodatoservice.ObjCliente_C$.subscribe((nuevoValor) => {
      this.objClienteCmdts = nuevoValor;
      if(nuevoValor.strCliente){
        this.strNumCteCmdts=nuevoValor.strCliente; 
        this.strNombreCliente=nuevoValor.strNombre;
        this.strSucursal =nuevoValor.intIDSucursal;
        this.strNombreSucursal=nuevoValor.strNombreSucursal;
        this.cteSelect = true;
      }
    });

    this.carroservice.intTotalItems$.subscribe((nuevoValor) => {
      this.itemsCotizador = nuevoValor;
    });
    /**carrito partner */
    this.carroservice.intTotalItems_Partner$.subscribe((nuevoValor) => {
      this.itemsCotizador_partner = nuevoValor;
    });
    /**cotizador partner */
    this.carroservice.intTotalItems_Partner_Cot$.subscribe((nuevoValor) => {
      this.itemsCotizador_partner_cot = nuevoValor;
    });
    this.carroservice.arrayItems$.subscribe((nuevoValor) => {
      this.arrayCarritoArts = nuevoValor;
    });

    this.carroservice.dblSubTotalItems$.subscribe((nuevoValor) => {
      this.dblSubTotal = nuevoValor;
    });

    this.carroservice.ObjCliente$.subscribe((nuevoValor) => {
      this.objCliente = nuevoValor;
      if(nuevoValor.ClienteSeleccionado){
        this.strNumCte=nuevoValor.strCliente;
      }else{
        this.strNumCte = ""
      }
    });
    //obj familia
    this.carroservice.ObjFamilia$.subscribe((nuevoValor) => {
      this.objFamilia = nuevoValor && nuevoValor.strFamilia && nuevoValor.strFamilia? nuevoValor :null;
    });

    
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.idetiqueta) {
        this.altoEtiqueta = this.idetiqueta.nativeElement.offsetHeight + 14;
      }
      
    }, 700);
    if (this.scrollContainer) {
      this.scrollService.setScrollContainer(this.scrollContainer);
    }
  }

  fnCloseTienda(){
    this.cartService.deleteLogin();
    this.router.navigate(['/tienda'])
  }

  loadCart() {
      // Suscribirse al carrito para recibir actualizaciones
      this.cartSubscription = this.cartService.getCartItems().subscribe((items) => { 
         let items_arr = items;
        this.fnSumasItems(items_arr);
      });
    this.cartService.getUsr().subscribe(data => {
        this.userData_tienda = data?data:[]; // Actualizamos la variable cuando los datos cambian
 
    });
  }
  fnSumasItems(items_arr:any){
    this.cartItems =0;
    items_arr.forEach(art => {
      this.cartItems = this.cartItems + art.dblCantidad  
    });
   
   }
  
  fnIraFamilia(){
       //guardamos en el servicio de indexed db la ruta
       let objRuta_Anterior={
        ruta_origen:'/directorio',
        ruta_destino:'/detalleLeadFamilia',
        nombre:'Directorio'
      }
  
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
      this.carroservice.setFamilia({ idFamilia: this.objFamilia.idFamilia, strFamilia: this.objFamilia.strFamilia, strClienteSeleccionado:'', strNombreCliente:''})
      this.router.navigate(['/detalleLeadFamilia'], {
      queryParams: { idFamilia: this.objFamilia.idFamilia, strFamilia: this.objFamilia.strFamilia }
    });
  }
  fnReportesAdministrativos() {
    //this.iframeVisible = true;
    /* const url = "https://ecodelinegocio.com/Intranet/catalogoDigital/"+this.strFamilia; */
    const url = `https://www.ecodelinegocio.com/Intranet/catalogoDigital/${this.strCliente}/${'""'}`;
    //this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  fnBuscar(){ 
    this.indexedDBService.fnIniciaBusqueda_Articulo(this.strBuscar)
  }
  toggleComodatosSubMenu(): void {
    this.mostrarComodatosSubMenu = !this.mostrarComodatosSubMenu;
  }
  fnGetArticulo() {
    this.totalCarrito = this._carro.getTotal();
    this.articulos$ = this._carro.getTotalArticulos$();
    this.articulos$.subscribe(n =>
    this.totalCarrito = n); 
  }

  toggleDrawer() {
    this.drawer.toggle();
  }
  fnGetCategorias(){
    let objenv = {
      strAccion: 'getCategorias',
      strConexion: "COMERCIAL"
    }
    this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
      result => {
        this.arrayCategorias = result
      
        this.arrayCategorias.forEach(cat => {
          cat.seeHov = false;
          cat.imagen = cat.strCategoria =="ALMACENAMIENTO Y ORGANIZACION" ?"./assets/categorias_ecommerce/ALMACENAMIENTO Y ORGANIZACION.jpg":
          cat.strCategoria =="AMENIDADES"?"./assets/categorias_ecommerce/AMENIDADES.jpg":
          cat.strCategoria =="ARTICULOS DE LIMPIEZA"?"./assets/categorias_ecommerce/ARTICULOS DE LIMPIEZA.jpg":
          cat.strCategoria =="BOLSA PARA BASURA"?"./assets/categorias_ecommerce/BOLSA PARA BASURA.jpg":
          cat.strCategoria =="BOTES Y CONTENEDORES"?"./assets/categorias_ecommerce/BOTES Y CONTENEDORES.jpg":
          cat.strCategoria =="CARROS UTILITARIOS"?"./assets/categorias_ecommerce/CARROS UTILITARIOS.jpg":
          cat.strCategoria =="COMPLEMENTOS PARA BAÑO"?"./assets/categorias_ecommerce/COMPLEMENTOS PARA BAÑO.jpg":
          cat.strCategoria =="CONTROL DE AROMAS"?"./assets/categorias_ecommerce/CONTROL DE AROMAS.jpg":
          cat.strCategoria =="DESECHABLES Y BIODEGRADABLES"?"./assets/categorias_ecommerce/DESECHABLES Y BIODEGRADABLES.jpg":
          cat.strCategoria =="EQUIPO DE PROTECCION"?"./assets/categorias_ecommerce/EQUIPO DE PROTECCION.jpg":
          cat.strCategoria =="EQUIPOS Y MAQUINAS DE LIMPIEZA INDUSTRIAL"?"./assets/categorias_ecommerce/EQUIPOS Y MAQUINAS DE LIMPIEZA INDUSTRIAL.jpg":
          cat.strCategoria =="HIGIENE Y CUIDADO PARA MANOS"?"./assets/categorias_ecommerce/HIGIENE Y CUIDADO PARA MANOS.jpg":
          cat.strCategoria =="MICROFIBRA"?"./assets/categorias_ecommerce/MICROFIBRA.jpg":
          cat.strCategoria =="PAPEL Y DESPACHADORES"?"./assets/categorias_ecommerce/PAPEL Y DESPACHADORES.jpg":
          cat.strCategoria =="QUIMICOS PARA LIMPIEZA"?"./assets/categorias_ecommerce/QUIMICOS PARA LIMPIEZA.jpg":
          cat.strCategoria =="QUIMICOS PARA MANTENIMIENTO"?"./assets/categorias_ecommerce/QUIMICOS PARA MANTENIMIENTO.jpg":
          cat.strCategoria =="TAPETES"?"./assets/categorias_ecommerce/TAPETES.jpg":
          cat.strCategoria =="UTENSILIOS DE COCINA"?"./assets/categorias_ecommerce/UTENSILIOS DE COCINA.jpg":
          cat.strCategoria =="WIPERS Y TOALLAS HUMEDAS"?"./assets/categorias_ecommerce/WIPERS Y TOALLAS HUMEDAS.jpg":"./assets/categorias_ecommerce/NO_DISPONIBLE.jpg"
        });
      })
  }

  fnCerrarSesion(){

  if (this.drawer.opened) {
    this.drawer.close().then(() => {
      setTimeout(() => {
        this.fnquitarCliente();
        this.fnquitarFamilia();
        this.fncerrar()
      }, 1); // 100 ms de retraso
    });
  } else {
    this.fnquitarCliente();
    this.fnquitarFamilia();
    this.fncerrar()
    this.fnquitarClientePartner();
  }
  }
  fncerrar(){
    let objPlantilla = {boolActive:false, intIDMovimientoVenta:0};
    this.objCliente = {};
    this.carroservice.setCte(this.objCliente);
    this.carroservice.deleteCarrito();
    this.servPlantilla.setPlantilla(objPlantilla);
    this.localSt.clear();
    this.commodatoservice.setCte({});
    this.commodatoservice.deleteCarrito();
    this.localSt.store('arrayAccesosMenu',[]);
    this.localSt.store('arrayAccesos',[]);
    this.localSt.store('arrayAccesosMenuCte',[]);
    this.localSt.store('arrayAccesoSubMenu', []);
    this.localSt.store('ObjPlantillaEdit', {});
    this.localSt.store('arrayItemsComodato', []);
    this.localSt.store('ObjClienteComodato', []);
    this.localSt.store('arrayCotizador', []);
    this.localSt.store('arrayTotalesPrecio', []);
    this.localSt.store('arrayArticulos', []);
    this.localSt.store('dblTotal', 0);
    this.localSt.store('cteParner', {});
    this.localSt.store('array_Agentes',[]);
    this.localSt.store('array_Sucursales', []);
    this.localSt.store('array_Gerentes', []);
    this.ServAccesos.updateAccesos([]);
    this.carroservice.setModoPlantilla(false);
     this.fnBorrarCarrito()
    this.fnquitarCliente();
    this.router.navigate(['/login']);
  }
  

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.drawer.close();
    let objPlantilla = {boolActive:false, intIDMovimientoVenta:0}
    this.objCliente = {};
    this.carroservice.setCte(this.objCliente);
    this.carroservice.deleteCarrito();
    this.servPlantilla.setPlantilla(objPlantilla); 
    this.fnBorrarCarrito()
    this.localSt.clear();
    this.commodatoservice.setCte({});
    this.commodatoservice.deleteCarrito();
    this.localSt.store('arrayAccesosMenu',[]);
    this.localSt.store('arrayAccesos',[]);
    this.localSt.store('arrayAccesoSubMenu', []);
    this.localSt.store('ObjPlantillaEdit', {});
    this.localSt.store('arrayItemsComodato', []);
    this.localSt.store('ObjClienteComodato', []);
    this.localSt.store('arrayCotizador', []);
    this.localSt.store('arrayTotalesPrecio', []);
    this.localSt.store('arrayArticulos', []);
    this.localSt.store('dblTotal', 0);
    this.localSt.store('array_Agentes', []);
    this.localSt.store('array_Sucursales', []);
    this.localSt.store('array_Gerentes', []);
    this.ServAccesos.updateAccesos([]);
    this.carroservice.setModoPlantilla(false);
    this.fnCerrarSesion(); 
    this.fnBorrarCarrito();
     this.fnquitarClientePartner();
  }

  fnBorrarCarrito(){
    this.indexedDBService.removeItem('arrayResult');
    this.localSt.clear(); 
    this.fnGetArticulo();  
 }

  fnShowCarrito() {
  }
  
  fnquitarCliente(){ 
    this.strNumCte =""
    this.objCliente = {};
    let objPlantilla = { boolActive: false, intIDMovimientoVenta: 0 }
    this.servPlantilla.setPlantilla(objPlantilla);
    this.carroservice.setCte({});
    this.carroservice.setModoPlantilla(false);
    this.carroservice.deleteCarrito();
  }
  fnquitarFamilia(){
    this.carroservice.setFamilia(null);
  }

  toggleSidenav() {
    this.isSidenavOpened = !this.isSidenavOpened;
    this.drawer.toggle();
  }
  fnDetalleCte(){
        //guardamos en el servicio de indexed db la ruta
        let objRuta_Anterior={
          ruta_origen:'/directorio',
          ruta_destino:'/detalleLeadCliente',
          nombre:'Directorio'
        }
        this.indexedDBService.removeItem('objRuta_Anterior');
        this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
        //agregamos a carrito el cliente
      this.router.navigate(['/detalleLeadCliente'],{queryParams:{strCliente: this.strNumCte}});
  }

  fnBuscarArtTienda() {
    this.router.navigate(['/tienda-sub-categoria'], {
      queryParams: { tipoBusqueda: 'artTienda', strArticulo: this.strArticuloTienda,strLinea:"RESULTADOS DE LA BÚSQUEDA",subLinea:"" },
      queryParamsHandling: 'merge' // Esto combina los nuevos parámetros con los existentes
    });
    this.strArticuloTienda = ""
    this.btnCanvas.nativeElement.click();
  }

  fnIrSubLinea(obj:any){  
    // Componente de origen
  /*   this.router.navigate(['/tienda-categoria'], { 
      queryParams: { strCategoria: obj.strCategoria }
  }); */
  this.router.navigate(['/tienda-categoria'], { 
    queryParams: { strCategoria: obj.strCategoria }
  }).then(() => {
    // Forzar un reload de la página
    window.location.reload();
  });
  }
  fnNav(nav:string){
    this.router.navigate(['/tiendaproducto'], { 
      queryParams: { strTipo: nav }
    }).then(() => {
      // Forzar un reload de la página
      window.location.reload();
    });
   
}


fnIrHome(){
  this.router.navigate(['/'+this.arrayAccesos[0].strUrlHome+''])
}

/**partner */
toggleEnvio(event: Event) {
      

  this.btnEnvio = !this.btnEnvio;
  this.btnPickup = this.btnEnvio == true ? false : this.btnPickup;
  this.strOpcion_envio_partner = this.btnEnvio == true ? 'Envío Estándar':'Pick Up';
  this.strTitulo_Canva_Partner =  this.btnEnvio == true ? 'Seleccione su dirección':'Seleccione Sucursal';
   
}
togglePickUP(event: Event) {
  this.objEnvio_partner.strTitulo =  this.arrayAccesos[0].nombre_envioPartner 

  this.btnPickup = !this.btnPickup;
  this.btnEnvio = this.btnPickup == true ? false : this.btnEnvio;
  this.strOpcion_envio_partner = this.btnPickup == true ? 'Pick Up': 'Envío Estándar';
  this.strTitulo_Canva_Partner =  this.btnPickup == true ?  'Seleccione Sucursal': 'Seleccione su dirección';

}
fnGetDireccion_partner(){
  this.arrayDireccion_partner = []; 
  let objenv = {
    strAccion: 'getListado_sucursales_CTE',
    strConexion: "COMERCIAL",
     strCliente: this.strNumCte
  } 
  this._serviciosService.mostrarCotizaciones(objenv, 'sp_ModVentas_Leads').subscribe(
    result => {
      this.arrayDireccion_partner = result  ;
       this.arraySucursal_partner = [];
       let objDetalleenvio_partner =this.arrayAccesos[0].detalle_envio_partner ? this.arrayAccesos[0].detalle_envio_partner:{};
      
      this.objEnvio_partner = this.arrayDireccion_partner.find(o=>o.intId_Direccion == objDetalleenvio_partner.intId_Direccion  ) ? 
      this.arrayDireccion_partner.find(o=>o.intId_Direccion == objDetalleenvio_partner.intId_Direccion  ):this.arrayDireccion_partner[0];

      this.objEnvio_partner.strTitulo = this.objEnvio_partner.strSucursal;   
      this.arrayDireccion_partner.forEach(suc => {
          suc.selected_partner = false
      });
    
      this.arrayDireccion_partner.forEach(suc => {
        if(this.objEnvio_partner.intId_Direccion == suc.intId_Direccion){
          suc.selected_partner = true
        }else{
          suc.selected_partner = false
        }
      });
     console.log('seleccionar direccion de envio')
      if(!this.arrayAccesos[0].detalle_envio_partner || (this.arrayAccesos[0].detalle_envio_partner && this.arrayAccesos[0].detalle_envio_partner.tipo_envio !='ENVIO')  ){
         //seleccionamos por default la primera direccion
      let direccion = this.arrayDireccion_partner[0];
      this.fnSelecciona_sucursal_Partner(direccion,'ENVIO')
      }

      }
   )
}
 
fnGetSucursales_partner(){
  this.arraySucursal_partner =[];
  let objenv = {
    strAccion: 'getSucursales',
    strConexion: "COMERCIAL" 
  } 

  this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
    result => {
          this.arraySucursal_partner = result;
          this.arraySucursal_partner.forEach(suc => {
            suc.selected_partner = false
          });
          console.log('asignar sucursal') 
          this.objEnvio_partner = this.arraySucursal_partner.find(o=>o.strAlmacen == this.strAlmacen_partner && o.strSucursal == this.strSucursal_partner ) ;
          this.objEnvio_partner.strTitulo =this.objEnvio_partner && this.objEnvio_partner.strNombre?this.objEnvio_partner.strNombre:"" ; 
        
        
          this.arraySucursal_partner.forEach(suc => {
            if(this.objEnvio_partner.strSucursal == suc.strSucursal && this.objEnvio_partner.strNombre == suc.strNombre){
              suc.selected_partner = true
            }else{
              suc.selected_partner = false
            }
          });  
           if(!this.arrayAccesos[0].detalle_envio_partner){
            this.fnSelecciona_sucursal_Partner(this.objEnvio_partner,'PICKUP') //si todavía no existe, ponemos el primero
           }
      }
   )
}
 
//---------------------------------------------------------------------------------------------------------------------------------------------------------
  fnBuscarSucursal_partner(){  
    let tipo_envio_partner = (this.arrayAccesos && this.arrayAccesos[0] && this.arrayAccesos[0].strTipo_envio_partner) ?this.arrayAccesos[0].strTipo_envio_partner :'PICKUP';
   
    if(tipo_envio_partner == 'PICKUP'){
      this.btnEnvio=false
      this.btnPickup = true;
      this.strAlmacen_partner = (this.arrayAccesos && this.arrayAccesos[0]) ?this.arrayAccesos[0].strAlmacen :'1'; 
      this.strSucursal_partner = (this.arrayAccesos && this.arrayAccesos[0]) ?this.arrayAccesos[0].strSucursal :'0'; 
      this.fnGetSucursales_partner(); 
      
    }
    else {
      this.btnEnvio=true
      this.btnPickup = false;
      this.strAlmacen_partner = (this.arrayAccesos && this.arrayAccesos[0]) ?this.arrayAccesos[0].strAlmacen :'1'; 
      this.strOpcion_envio_partner='Envío Estándar'
      this.fnGetDireccion_partner();
    
    }
    
    
  }
  fnSelecciona_sucursal_Partner (objSuc:any, tipo:string){
    console.log(objSuc)
   /* if(tipo == "PICKUP"){*/
      let nombre = tipo=='PICKUP'?objSuc.strNombre:objSuc.strSucursal;
      let almacen =  tipo=='PICKUP'?objSuc.strAlmacen: objSuc.strAlmacen;
      let codigoPostal = objSuc.strCodigoPostal;
      let sucursal = objSuc.strSucursal;

      
    
      //let objEnvioSelecionado = {tipo:tipo, strNombre:nombre, strAlmacen :almacen, strCodigoPostal:codigoPostal};
      if(tipo =="PICKUP"){
        this.objEnvio_partner = this.arraySucursal_partner.find(o=>o.strNombre == objSuc.strNombre && o.strSucursal == objSuc.strSucursal);
        this.objEnvio_partner.strTitulo = objSuc.strNombre;
        this.arraySucursal_partner.forEach(suc => {
          if(this.objEnvio_partner.strSucursal == suc.strSucursal && this.objEnvio_partner.strNombre == suc.strNombre){
            suc.selected_partner = true
          }else{
            suc.selected_partner = false
          }
        });
      }else{
        let objDetalleenvio_partner =this.arrayAccesos[0].detalle_envio_partner ? this.arrayAccesos[0].detalle_envio_partner:{};
        this.objEnvio_partner = this.arrayDireccion_partner.find(o=>o.intId_Direccion == objDetalleenvio_partner.intId_Direccion  ) ? this.arrayDireccion_partner.find(o=>o.intId_Direccion == objDetalleenvio_partner.intId_Direccion  ):this.arrayDireccion_partner[0];
        this.objEnvio_partner.strTitulo = objDetalleenvio_partner.strSucursal;


        this.arrayDireccion_partner.forEach(suc => {
          if(this.objEnvio_partner.intId_Direccion == suc.intId_Direccion){
            suc.selected_partner = true
          }else{
            suc.selected_partner = false
          }
        });
      }
       //actualizamos el almacen para todos los accesos, ejecutamos el servicio
       this.arrayAccesos.forEach(element => {
        element.strSucursal = sucursal;
        element.strAlmacen =almacen;
        element.strTipo_envio_partner = tipo;
        element.strCodigoPostal = codigoPostal;
        element.nombre_envioPartner = nombre;
        objSuc.tipo_envio = tipo;
        element.detalle_envio_partner=objSuc;
       });
      this.indexedDBService.setItem('arrayResult_parnert',  this.arrayAccesos );
   
 
  }


  //--------------codigo nuevo partner ---------------------------
  fnGetSucursales_partner_inicio(){
    this.arraySucursal_partner =[];
    let objenv = {
      strAccion: 'getSucursales',
      strConexion: "COMERCIAL" 
    } 
  
    this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
      result => {
        this.arraySucursal_partner = result;
        /*this.arraySucursal_partner.forEach(suc => {
        suc.selected_partner = false
        });*/

        this.btnEnvio=false
        this.btnPickup = true;
        this.strAlmacen_partner = (this.arrayAccesos && this.arrayAccesos[0]) ?this.arrayAccesos[0].strAlmacen :'1';
        this.strSucursal_partner= (this.arrayAccesos && this.arrayAccesos[0]) ?this.arrayAccesos[0].sucursal :'0';
          
       // console.log('asignar sucursal inicio') 
        this.objEnvio_partner = this.arraySucursal_partner.find(o=>o.strAlmacen == this.strAlmacen_partner && o.strSucursal == this.strSucursal_partner) ;
        this.objEnvio_partner.strTitulo =this.objEnvio_partner && this.objEnvio_partner.strNombre?this.objEnvio_partner.strNombre:"" ; 
      
      
        this.arraySucursal_partner.forEach(suc => {
          if(this.objEnvio_partner.strSucursal == suc.strSucursal && this.objEnvio_partner.strNombre == suc.strNombre){
            suc.selected_partner = true
          }else{
            suc.selected_partner = false
          }
        });  
        this.fnSelecciona_sucursal_Partner(this.objEnvio_partner,'PICKUP') 
        }
     )
  }
  fnquitarClientePartner(){
    this.servicioPartner.setCte({});
    this.ctePartner = {};

  }
}