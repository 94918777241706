import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class PlantillafinService {
  private objPlantillaEdit_subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public objPlantillaEdit$: Observable<any> = this.objPlantillaEdit_subject.asObservable();
  private objPlantillaEdit:any={};
  constructor(private localSt:LocalStorageService) {
    let plantillaSesision= this.localSt.retrieve('ObjPlantillaEdit') ?this.localSt.retrieve('ObjPlantillaEdit') :{};  
    this.setPlantilla(plantillaSesision); 
   }

  setPlantilla(objP:any){
    this.objPlantillaEdit={ 
      boolActive:objP.boolActive  ? true:false , 
      intIDMovimientoVenta:objP.intIDMovimientoVenta? objP.intIDMovimientoVenta:0 ,
      Activar_Plantilla:objP.Activar_Plantilla,
      strTipo_Accion_Cotizacion:objP.strTipo_Accion_Cotizacion?objP.strTipo_Accion_Cotizacion:''
    };
  /*   console.log(this.objPlantillaEdit); */
    this.localSt.store('ObjPlantillaEdit',this.objPlantillaEdit);
    this.objPlantillaEdit_subject.next(this.objPlantillaEdit);
   }

}

