import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject } from 'rxjs';
import { Articulo } from '../interfaces/articulo';
 
import Swal from 'sweetalert2';
interface CartItem {
  dblPrecioConDescuento:number;
  dblPrecioLista:number;
  dblPrecio: number;
  dblCantidad: number;
  strArticulo:string;
  strOpcion:string;
  strNombreOpcion:string;
  strDescripcionCorta:string;
  strDescripcionLarga:string;
  strLinea:string;
}
@Injectable({
  providedIn: 'root' 
})
export class EcommerceCarrService {
      /****variables para manejar el total del carrito ******/
  
      private tokenKey = 'authToken';
      private items: CartItem[] = this.loadCartFromLocalStorage();
      private cartItemsSubject = new BehaviorSubject<CartItem[]>(this.items); // Observable para cambios en el carrito
      private monto_envio:number= 99;
      private minimo_compra:number= 1000;
      private monto_envio_especial:number=1000; 
      private arrayArts_envio_especial:any=[{articulo:'1887156'},{articulo:'1889849'},{articulo:'2035896'},{articulo:'2119054'},{articulo:'2119055'},{articulo:'1862706'},{articulo:'1995297'}]
      private envio_total:number= 99;    
      private envioSubject = new BehaviorSubject<number>(this.envio_total); // Observable para cambios en el carrito
      private objCupon:any = ''
      private cuponSubject = new BehaviorSubject<any>(this.objCupon); 
      private total_items:number=0;
      private dataUsr:any =[];
      private dataUsrSubject = new BehaviorSubject<any>(this.getUserDataFromStorage());

  constructor() {
    //this.  clearCart() ;

 
   }

  /**inicio de funciones de sesion */
  setToken(token: string) {
    localStorage.setItem(this.tokenKey, token); 
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  removeToken() {
    localStorage.removeItem(this.tokenKey);
  }

  clearStorage() {
    localStorage.clear();
  }
  /**fin de funciones  de sesion */ 

  private loadCartFromLocalStorage(): CartItem[] {
    const cartData = localStorage.getItem('cart');
    return cartData ? JSON.parse(cartData) : [];
  }

  private saveCartToLocalStorage() {
    localStorage.setItem('cart', JSON.stringify(this.items));
    this.cartItemsSubject.next(this.items); // Notifica cambios
    this.envioSubject.next(this.envio_total);
  }
    // Método auxiliar para obtener los datos de localStorage (en caso de no estar inicializados)
    private getUserDataFromStorage(): any {
      const userData = localStorage.getItem('USR_DATA');
      return userData ? JSON.parse(userData) : null;
    }
 
  setLogin(data:any){
    //this.clearCart();
    localStorage.setItem('USR_DATA', JSON.stringify(data));
    this.dataUsr=data;
    this.dataUsrSubject.next(this.dataUsr)
  }
  updateLogin(data:any){

    localStorage.setItem('USR_DATA', JSON.stringify(data));
    this.dataUsr=data;
    this.dataUsrSubject.next(this.dataUsr)
  }
  deleteLogin(){
    localStorage.removeItem('USR_DATA');
     this.dataUsr=[];
     //this.clearCart();
    this.dataUsrSubject.next(this.dataUsr)
  }
  applyShippment(){
    let total =0;  
    //1.- revisar que el articulo no esté en los de envio especial
    this.envio_total =this.monto_envio;
    this.items.forEach(element => {
        let audit = this.arrayArts_envio_especial.find(o=>o.articulo == element.strArticulo);
        if(audit){ //si hay un articulo que requiera monto de envio especial, asignamos el monto de envio
          this.envio_total=this.monto_envio_especial;
        }
         
        let precio = element.dblPrecioConDescuento >0 ? element.dblPrecioConDescuento: element.dblPrecioLista;
        total=total + ( precio * element.dblCantidad)
      });

    //validamos si el envio es igual al monto normal, si es asi, validamos el total de la cuenta
    if(this.envio_total==this.monto_envio){
      
      if(total >= this.minimo_compra){
        this.envio_total=0;
      }
      else {
        this.envio_total=this.monto_envio;
      }
    } 
    //guardamos el monto de envio en sesion
    localStorage.setItem('envio', JSON.stringify(this.envio_total));
    this.saveCartToLocalStorage();
  }
  applyCoupon(cupon: any){   
    let subtotal=this.calculateTotal();
    let cumple=0;
    let monto_minimo = cupon.dblMontoMinimo?cupon.dblMontoMinimo:0; //revisamos si existe condicion de monto minimo
    let cantidad_minima =cupon.dblCantidadMinima?cupon.dblCantidadMinima:0;
    if(monto_minimo >0){ //revisar si cumple con el monto minimo
      if (subtotal >= monto_minimo)
      {
        cumple=1;
      } 
      else {
        cupon={};
       // let objCupon_descuento = {};
        //this.desapplyCoupon(objCupon_descuento);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No cumple con el Monto Mínimo de Compra Para Aplicar El Cupon" 
        });
       
      }
    }
    else if(cantidad_minima >0){
      if (this.total_items >= cantidad_minima)
        {
          cumple=1;
        } 
        else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No cumple con la Cantidad Mínima de Compra  Para Aplicar El Cupon" 
          });
          cupon={};
         // let  objCupon_descuento = {};
         // this.desapplyCoupon(objCupon_descuento);
        }
    }
    else {
      cumple=1;
    }


    if(cumple==1){
      this.items.forEach(element => { 
        console.log(element)
        //REVISAMOS QUE CUMPLA CON LA LINEA
        let linea = (cupon.strLinea && cupon.strLinea != '')?cupon.strLinea : '';

        let Descuento=0;
        if(linea!= ''){
          if(cupon.strTipo =='Monto Fijo' && element.strLinea == linea){
            const proportion = element.dblPrecioLista / subtotal;
            const discountForItem = cupon.dblCantidad * proportion; // Descuento proporcional
            element.dblPrecioConDescuento = Math.max(element.dblPrecioLista - discountForItem, 0); // Precio ajustado sin negativo
          }
          else if(cupon.strTipo=='Porcentaje'  && element.strLinea == linea){
            Descuento=element.dblPrecioLista * ( cupon.dblCantidad/100);
            element.dblPrecioConDescuento = Math.max(element.dblPrecioLista - Descuento, 0);
          }
          else {
            element.dblPrecioConDescuento = Math.max(element.dblPrecioLista  , 0); //dejamos el mismo precio lista para que no se tome como descuento
          }
        }
        else {
          if(cupon.strTipo =='Monto Fijo'){
            const proportion = element.dblPrecioLista / subtotal;
            const discountForItem = cupon.dblCantidad * proportion; // Descuento proporcional
            element.dblPrecioConDescuento = Math.max(element.dblPrecioLista - discountForItem, 0); // Precio ajustado sin negativo
          }
          else if(cupon.strTipo=='Porcentaje'){
            Descuento=element.dblPrecioLista * ( cupon.dblCantidad/100);
            element.dblPrecioConDescuento = Math.max(element.dblPrecioLista - Descuento, 0);
          }
        }

        
      });
    }
    else {
      let  objCupon_descuento = {};
      cupon={};
      this.desapplyCoupon(objCupon_descuento);
    }
    localStorage.setItem('coupon', JSON.stringify(cupon));// guardamos el cupon en sesion
    this.objCupon =cupon;
    this.cuponSubject.next(this.objCupon);
    
     
    this.applyShippment();
    
  }
  desapplyCoupon(cupon: any){
    this.items.forEach(element => {
      element.dblPrecioConDescuento = null;
    });
    localStorage.setItem('coupon', JSON.stringify(cupon));

     this.objCupon = {}
    this.cuponSubject.next(this.objCupon);
    //this.saveCartToLocalStorage();
    this.applyShippment();
  }
  addToCart(item: CartItem) { 
    console.log('agregar al carrito')
    const existingItem = this.items.find(i => i.strArticulo === item.strArticulo && i.strOpcion === item.strOpcion );
    if (existingItem) {
      existingItem.dblCantidad += item.dblCantidad;
    } else {
      this.items.push(item);
    }
    //this.saveCartToLocalStorage(); lo comento aqui porque en applyshippment ya se ejecuta
    
     //revisamos si existe un cupon de descuento
     let objC =localStorage.getItem('coupon');
     let objCupon_descuento = objC? JSON.parse(objC) : {} ;
     if(objCupon_descuento.strCodigoPromocional){
      this.applyCoupon(objCupon_descuento);
     }
     else{
      this.applyShippment();
     }

    
  }
  updateQuantity(item_id: CartItem, opcion:string ){
    const existingItem = this.items.find(i => i.strArticulo === item_id.strArticulo && i.strOpcion === item_id.strOpcion );
    if (existingItem) { 
      if(opcion =='aumenta'){
        existingItem.dblCantidad = item_id.dblCantidad + 1;
      }
      else {
        existingItem.dblCantidad = item_id.dblCantidad - 1;
      }     
  
      
    }
 /*   let dblSubTotal = 0;
    let total_items=0;
    this.items.forEach(element => {
    
      dblSubTotal =dblSubTotal + (element.dblCantidad * element.dblPrecioLista);
      total_items=total_items +  (element.dblCantidad );
    });
*/
    console.log('update cantidad')
    //revisamos si existe un cupon de descuento
    let objC =localStorage.getItem('coupon')
    let objCupon_descuento = objC?  JSON.parse(objC) : {} ;
   // this.applyCoupon(objCupon_descuento);
    if(objCupon_descuento.strCodigoPromocional){
      this.applyCoupon(objCupon_descuento);
     
     /* let monto_minimo = objCupon_descuento.dblMontoMinimo?objCupon_descuento.dblMontoMinimo:0; //revisamos si existe condicion de monto minimo
      let cantidad_minima =objCupon_descuento.dblCantidadMinima?objCupon_descuento.dblCantidadMinima:0;
      if(monto_minimo >0){ //revisar si cumple con el monto minimo
        if (dblSubTotal >= monto_minimo)
        {
          this.applyCoupon(objCupon_descuento);
        } 
        else {
          objCupon_descuento = {};
          this.desapplyCoupon(objCupon_descuento);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No cumple con el Monto Mínimo de Compra Para Aplicar El Cupon" 
          });
         
        }
      }
      else if(cantidad_minima >0){
        if (total_items >= cantidad_minima)
          {
            this.applyCoupon(objCupon_descuento);
          } 
          else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "No cumple con la Cantidad Mínima de Compra  Para Aplicar El Cupon" 
            });
            objCupon_descuento = {};
            this.desapplyCoupon(objCupon_descuento);
          }
      }
*/

     
    }
    else {
     // this.saveCartToLocalStorage();
      this.applyShippment();
    }
    
  }
  removeFromCart(item_id: CartItem) {
    console.log(item_id)
    let pre = [];
    
    if(item_id.strOpcion !=''){
        pre =this.items.filter(art => art.strArticulo != item_id.strArticulo  && art.strOpcion != item_id.strOpcion );
    }
    else {
      pre =this.items.filter(art => art.strArticulo != item_id.strArticulo  );
    }
    
    console.log(pre)
    this.items = pre
    //this.saveCartToLocalStorage();
    this.applyShippment();
  }
 
  gettotalItems(): number {
    let totalArtTienda =0
    this.items.forEach(obj => {
      totalArtTienda = obj.dblCantidad + totalArtTienda 
     });
    return totalArtTienda;
  }
  calculateTotal(): number {
    this.total_items =this.items.reduce((total, item) => total +  item.dblCantidad, 0);
    return this.items.reduce((total, item) => total + item.dblPrecioLista * item.dblCantidad, 0);
  }

  calculateTotal_Descuento(){
    const total = this.items.reduce((sum, item) => {
      const precio = item.dblPrecioConDescuento > 0 ? item.dblPrecioConDescuento : item.dblPrecioLista;
      return sum + precio * item.dblCantidad;
    }, 0);
  }
  clearCart() {
    this.clearStorage(); 
    this.items = [];
    this.envio_total=0;
    this.saveCartToLocalStorage();
  //  this.applyShippment();
   
  }

  /**observables */

  getCartItems() {
    return this.cartItemsSubject.asObservable(); // Devuelve el observable
  }

  getEnvio() {
    return this.envioSubject.asObservable(); // Devuelve el observable
  }
  getCupon() {
    
    return this.cuponSubject.asObservable(); // Devuelve el observable
  }
  getUsr(){
    return this.dataUsrSubject.asObservable();
  }
}
