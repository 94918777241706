<div class="container">
    <div class="row">
        <div class="col-12 text-center  py-3">
            <h1 class="mt-3 mi-div3">
                Detalles Comodato <span class="title"> Programador</span> 
            </h1>
        </div>
    </div>
    <div class="row mb-4">
        <div class="card shadow px-0 mb-4 mi-div">
            <div class="card-body" style="background: #ecedee;">
                <div class="col-12 position-absolute top-0 start-0 pb-0 titulo">
                        <h3 class="card-title titulo-card">Cliente</h3>
                </div>
                <div class="row pt-5">
                    <div class="col-12 col-md-6 col-lg-2">
                        <mat-form-field appearance="outline" class="col-12">
                            <mat-label>Comodato</mat-label>
                            <input matInput placeholder="Ex. Pfizer"  [(ngModel)]="intIdComodato"  readonly>
                            <mat-icon matSuffix>bookmark</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 col-lg-2">
                        <mat-form-field appearance="outline" class="col-12">
                            <mat-label>Cliente</mat-label>
                            <input matInput placeholder="Ex. Pfizer" [(ngModel)]="strcliente" readonly>
                            <mat-icon matSuffix>card_travel</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 ">
                        <mat-form-field appearance="outline" class="col-12">
                            <mat-label>Nombre</mat-label>
                            <input matInput placeholder="Placeholder" [(ngModel)]="strNombreCte" readonly>
                            <mat-icon matSuffix>face</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 ">
                        <mat-form-field appearance="outline" class="col-12">
                            <mat-label>Dirección</mat-label>
                            <input matInput placeholder="Placeholder" [(ngModel)]="strDireccion" readonly>
                            <mat-icon matSuffix>directions</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row  ">
               
                            <!-- <div class="col-12 mt-0 mb-0">
                               <h4 class="mb-0"> <span class="badge bg-primary">
                            Filtro</span></h4>
                            </div> -->
                    <div class="col-12">
                        <div class="col-12 col-md-6  col-lg-4 my-3 ">
                            <mat-form-field appearance="fill" class="inFiltro">
                                <mat-label>Filtrar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="id, despachador, descripcion, etcc." #input>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <!--  <div class="row">
        <div class="col-12 col-lg-4 col-md-6 col-sm-12  py-4 ">
           
        </div>
    </div> -->
    <div class="row mb-5 position-relative mi-div2">
        <div class="col-12  table-responsive pb-0 mb-0 px-0 mx-0 mat-elevation-z8 mi-div2"  tabindex="0">
            <div [hidden]="spinnerImg==false" class="text-center h-25">
                <img src="assets/spinner.gif" class="altimg" alt="">
            </div>
            
            <table [hidden]="spinnerImg==true" class="table " mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="intIDComodato">
                    <th mat-header-cell *matHeaderCellDef class="p-1 text-center">Despachador</th>
                    <td class="border-end border-dark  text-center py-2 ps-0 " mat-cell *matCellDef="let row">
                        <img [src]="'https://www.cleanclean.mx/imagenes/' + row.strArticulo + '.jpg'"  class="imgEco" alt="">
                    </td>
                </ng-container> 
                <ng-container matColumnDef="intIDArticulo">
                    <th mat-header-cell *matHeaderCellDef class="text-center px-3 lt"> Id  </th>
                    <td class="border-end text-center border-dark px-3 lt fz11" mat-cell *matCellDef="let row">
                        <b> {{row.intIDArticulo}}</b>
                    </td>
                </ng-container>
                <ng-container matColumnDef="strArticulo">
                    <th mat-header-cell *matHeaderCellDef class="text-center lt1">Despachador</th>
                    <td class="border-end text-center border-dark lt1 fz11" mat-cell *matCellDef="let row">
                        <b>{{row.strArticulo}}</b>
                    </td>
                </ng-container>
                <ng-container matColumnDef="strDescripcion">
                    <th mat-header-cell *matHeaderCellDef class="text-center ">Descripcion</th>
                    <td class="border-end text-center border-dark  fz11" mat-cell *matCellDef="let row">
                        <b>{{row.strDescripcion}}</b>
                    </td>
                </ng-container>
                <ng-container matColumnDef="intDiasActividad">
                    <th mat-header-cell *matHeaderCellDef class="text-center ps-1 ">Dias De <br> Actividad</th>
                    <td class="border-end  text-center border-dark fz11" mat-cell *matCellDef="let row"> <b>
                            {{row.intDiasActividad}}</b> </td>
                </ng-container>
                <ng-container matColumnDef="strEdificio">
                    <th mat-header-cell *matHeaderCellDef class="text-center"> Edificio </th>
                    <td class="border-end  text-center border-dark fz11" mat-cell *matCellDef="let row">
                        <b> {{row.strEdificio}}</b>
                    </td>
                </ng-container>
                <ng-container matColumnDef="strNivel">
                    <th mat-header-cell *matHeaderCellDef class="text-center p-1 px-xl-3"> Nivel </th>
                    <td class="border-end  text-center border-dark p-1 px-xl-3 fz11" mat-cell *matCellDef="let row"> <b>
                            {{row.strNivel}}</b> </td>
                </ng-container>
                <ng-container matColumnDef="strTipoArea">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center px-3">Area</th>
                    <td mat-cell *matCellDef="let row" class="border-end  text-center border-dark px-3 fz11"><b>
                            {{row.strTipoArea}}</b> </td>
                </ng-container>
               
                <ng-container matColumnDef="strArea">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center px-3">Nombre Area</th>
                    <td mat-cell *matCellDef="let row" class="border-end  text-center border-dark px-3 fz11"><b>
                            {{row.strArea}}</b> </td>
                </ng-container>
                <ng-container matColumnDef="strHrInicio">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center px-3">Hora Inicio</th>
                    <td mat-cell *matCellDef="let row" class="border-end  text-center border-dark px-3 fz11"><b>
                            {{row.strHrInicio}}</b> </td>
                </ng-container>
                <ng-container matColumnDef="strHrFin">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center px-3">Hora Fin</th>
                    <td mat-cell *matCellDef="let row" class="border-end  text-center border-dark px-3 fz11"><b>
                            {{row.strHrFin}}</b></td>
                </ng-container>
                <ng-container matColumnDef="strHoraInicioFS">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center px-3">Hora Inicio Especifico</th>
                    <td mat-cell *matCellDef="let row" class="border-end  text-center border-dark px-3 fz11"><b>
                            {{row.strHoraInicioFS}}</b> </td>
                </ng-container>
                <ng-container matColumnDef="strHoraFinFS">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center px-3">Hora Fin Inicio Especifico</th>
                    <td mat-cell *matCellDef="let row" class="border-end  text-center border-dark px-3 fz11"><b>
                            {{row.strHoraFinFS}}</b></td>
                </ng-container>
                <ng-container matColumnDef="intDiasActividadFS">
                    <th mat-header-cell *matHeaderCellDef class="text-center ps-1 ">Días De <br> Actividad Especifico</th>
                    <td class="border-end  text-center border-dark fz11" mat-cell *matCellDef="let row"> <b>
                            {{row.intDiasActividadFS}}</b></td>
                </ng-container>
               <!--  <ng-container matColumnDef="strNumSerie">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center p-1 px-xl-3 ">No. Serie</th>
                    <td mat-cell *matCellDef="let row; let jp =index;" class="border-end  text-center border-dark p-1 px-xl-3">
                          <input type="text" id="{{jp}}noserie" [disabled]="row.intEstatusDespachador==1" [(ngModel)]="row.strNumSerie"
                            (blur)="fniNgresarSerie(row.intIDArticulo,row.strArticulo,row.strNumSerie);"
                            class="form-control" > 
                        <label  [ngClass]="{'d-none':row.lblboolean==0 || row.strNumSerie.length>1,'d-block mt-1': row.lblboolean==1}" class="rounded shadow-sm alert-danger w-100">Escribir 2 o mas caracteres </label> 
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="intEstatusDespachador">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center p-1">Estatus <br> Despachador</th>
                    <td mat-cell *matCellDef="let row; let k = index;" class="border-end  text-center border-dark p-0 form-switch">
                      

                        <button [disabled]="row.intEstatusDespachador==1"  id="{{k}}btn" [ngClass]="{'btn-success':row.intEstatusDespachador==1,'btn-warning text-white':row.intEstatusDespachador==0}"
                            (click)="fnvalid(row.intIDArticulo,row.strArticulo,k);" class="btn  shadow buttonicon">
                            <mat-icon >camera_rear</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="serietarjeta">
                    <th class="" mat-header-cell *matHeaderCellDef class="text-center px-3">Serie <br> Tarjeta</th>
                    <td mat-cell *matCellDef="let row;let t = index;" class="border-end text-center border-dark px-3">
                        <input class="form-control" type="text" id="{{t}}t" [(ngModel)]="row.strNumSerieNFC" readonly >
                        <label id="{{t}}l"  class="rounded shadow-sm alert-danger w-100 d-none">error en TagSerie</label> 
                        </td>
                </ng-container>
                <ng-container matColumnDef="EscrituraNfc">
                    <th mat-header-cell *matHeaderCellDef class="text-center lt2 px-4">NFC</th>
                    <td class="border-end text-center border-dark lt2 text-center pe-0 " mat-cell *matCellDef="let row;let j = index;">
                        <button [disabled]="row.intEstatusDespachador==1" (click)="fnwritenfc(j,row.strNumSerieNFC,j+'t',row.intIDArticulo,row.strArticulo);" class="buttonicon btn btn-primary text-center ms-auto">
                            <mat-icon>nfc</mat-icon>
                        </button>
                       <input type="hidden" id="{{j}}"  value="{{row.strNumSerie}}">
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="bg-primary"></tr>
                <!-- Row shown when there is no matching data. onclick="writeTag(this.name);" -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell table" colspan="7">No se encontro el dato<b>"{{input.value}}"</b></td>
                </tr>
            </table>
        </div>
        <mat-paginator class="w-100 shadow " [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
        <div class="position-absolute bottom-0 pb-2" [hidden]="arrayDetalle.length == 0">
            <button [disabled]="btnDis||strEstatus=='PROGRAMADO'"  (click)="fnEnviarComodato();" class="btn btn-success fs-6 shadow">Enviar</button>
        </div>
    </div>
  
<!-- 
    <div class="row d-flex justify-content-center">
        <div class="col-8">                                                              21.067549205068747, -101.67872729431
                                                                                         18.357222369762674, -100.66755662866366                              
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15146.853092408372!2d-101.67872729431!3d21.067549205068747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84332da966cc871b%3A0x394917a499835fe8!2sTerminal%20Zinacatepec!5e0!3m2!1ses-419!2smx!4v1676744322367!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div> -->
</div>