<div class="container" ><!-- oncopy="return false" onpaste="return false" -->
    <div class="row">
        <div class="col-12 ">
            <h1 class="my-3 fw-bold text-center text-decoration-underline">ALTA DE CLIENTE -<span class="tittle"> {{strTipoCliente}}</span> </h1>
        </div>

        <div class="col-12 "  *ngIf="objAlta_Cte?.strEstatus=='RECHAZADO'">
            <h3 class="my-3 fw-bold text-center  ">Motivo de Rechazo  </h3>
            <span class="badge text-bg-danger fs-6">{{objAlta_Cte.strMensaje}}</span>
        </div>

    </div>
    <div class="row" *ngIf="spinerAll">
        <div class="col-12 d-flex justify-content-center flex-column py-4">
            <mat-spinner class="mx-auto"></mat-spinner>
            <span class="mx-auto">
                Subiendo archivos......
            </span>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(strAccion_Solicitud == 'ACTUALIZAR_CREDITO' || strAccion_Solicitud == 'DATOS_GENERALES') && !spinerAll">
        <div class="col-12 d-flex justify-content-evenly align-items-center mt-3">
            <span>No. de Cliente</span> <input [(ngModel)]="strCliente"  type="text" class="form-control w-50" placeholder="Ingresa numero de cliente"> 
            <button  class="btn btn-primary" (click)="fngetCte_Solicitud_Credito()">Filtrar</button>
            <button  class="btn btn-secondary"  data-bs-toggle="modal" data-bs-target="#correoModal" *ngIf="strAccion_Solicitud == 'DATOS_GENERALES' " (click)="fnGetCliente_Contactos(strCliente)">Enviar Factura por Correo</button>
        </div>
    </div>
 
    <div class="row justify-content-center" *ngIf="!spinerAll">
        <nav class="px-0 d-none">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active fw-bold" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Principal</button>
                <button class="nav-link fw-bold" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Documentos</button>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <!-- Primer tab -->
            <div class="tab-pane fade show active " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" >
                <div class="row  borderbott">
                    <div class="col-12 bgtable bordertop py-2 mt-3">
                        <div class="col-12 text-center" *ngIf="strTipoCliente==''">
                            <span class="fw-bold justify-content-center d-flex align-items-end jumping-text">Seleccione el tipo de cliente "CREDITO O CONTADO"</span>
                        </div>
                        <div class="col-12 d-flex  justify-content-around" >
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group w-100" [(ngModel)]="strTipoCliente"  (change)="onTipoClienteChange()"  >
                                <div class="col-12 d-flex justify-content-around">
                                    <mat-radio-button class="example-radio-button " color="primary" [value]="'CREDITO'" [disabled]="strAccion_Solicitud == 'DATOS_GENERALES' ">
                                        CREDITO
                                    </mat-radio-button>
                                    <mat-radio-button class="example-radio-button" color="primary"  [value]="'CONTADO'" [disabled]="strAccion_Solicitud == 'ACTUALIZAR_CREDITO' || strAccion_Solicitud == 'VER' ">
                                        CONTADO
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row  justify-content-center bgFondoTab borderbott mb-3">
                            <div class="col-11 bg-light borderbott bordertop  mt-3 pb-3">
                                <div class="row align-items-center mt-3">
                                    <div class="col-4 d-flex align-items-center">
                                        <i class="bi bi-info-circle text-warning fs-4" *ngIf="!isFormVendedorValid()" matTooltip="Faltan campos por llenar">
                                        </i>
                                        <i class="bi bi-check-circle text-success fs-4" *ngIf="isFormVendedorValid()">
                                        </i>
                                    </div>
                                    <div class="col-4">
                                        <h3 class="mb-0 text-center fw-bold">
                                            INFORMACIÓN GENERAL
                                        </h3>
                                    </div>
                                    <div class="col-4">

                                    </div>
                                </div>
                                <div class="row">
                                    <form [formGroup]="formVendedor" class="row" >
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded" [disabled]="boolDeshabilita_Form">
                                                <mat-label>Nombre Comercial</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strNombreComercial"  [maxlength]="100">
                                                <i matSuffix class="bi bi-person-square fs-4" [ngClass]="{'text-warning': formVendedor.get('strNombreComercial').hasError('required'),'text-success': !formVendedor.get('strNombreComercial').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formVendedor.get('strNombreComercial').hasError('required') && formVendedor.get('strNombreComercial').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Tipo Cliente</mat-label>
                                                <mat-select formControlName="strTipoCliente">
                                                    <mat-option value="">--Seleccione--</mat-option>
                                                    <mat-option [value]="o.strTipo" *ngFor="let o of arrayTipoCliente">{{o.strTipo}}</mat-option>
                                                </mat-select>
                                                <i matSuffix class="bi bi-person-circle fs-4" [ngClass]="{'text-warning': formVendedor.get('strTipoCliente').hasError('required'),'text-success': !formVendedor.get('strTipoCliente').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formVendedor.get('strTipoCliente').hasError('required') && formVendedor.get('strTipoCliente').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div> 
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Telefono</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strTelefono" type="text" (keypress)="validarNumeros($event)"  [maxlength]="10">
                                                <i matSuffix class="bi bi-telephone fs-4" [ngClass]="{'text-warning': formVendedor.get('strTelefono').invalid ,'text-success': !formVendedor.get('strTelefono').invalid }"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formVendedor.get('strTelefono').hasError('required') && formVendedor.get('strTelefono').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formVendedor.get('strTelefono').hasError('minlength')" class="fs10">
                                                Se requieren exactamente 10 caracteres.
                                            </mat-error>
                                        </div>  
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Vendedor</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strVendedor" readonly>
                                                <i matSuffix class="bi bi-person-lock fs-4" [ngClass]="{'text-warning': formVendedor.get('strVendedor').hasError('required'),'text-success': !formVendedor.get('strVendedor').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formVendedor.get('strVendedor').hasError('required') && formVendedor.get('strVendedor').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>          
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Sucursal</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strSucursal" readonly>
                                                <i matSuffix class="bi bi-buildings fs-4" [ngClass]="{'text-warning': formVendedor.get('strSucursal').hasError('required'),'text-success': !formVendedor.get('strSucursal').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formVendedor.get('strSucursal').hasError('required') && formVendedor.get('strSucursal').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3"  *ngIf="strTipoCliente=='CREDITO'">
                                             <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                 <mat-label>Limite de credito</mat-label>
                                                 <input matInput placeholder="$1234434." (keypress)="validarNumeros($event)" type="number" formControlName="strLimiteCredito" [maxlength]="20">
                                                 <i matSuffix class="bi bi-credit-card-2-back fs-4" [ngClass]="{'text-warning': formVendedor.get('strLimiteCredito').hasError('required'),'text-success': !formVendedor.get('strLimiteCredito').hasError('required')}"></i>
                                             </mat-form-field>
                                            <mat-error *ngIf="formVendedor.get('strLimiteCredito').hasError('required') && formVendedor.get('strLimiteCredito').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                         </div>
                                         <div class="col-12 col-md-6 col-lg-4 mt-3" >
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Condición de pago</mat-label>
                                                <!-- <input matInput placeholder="Placeholder" formControlName="strCondicionPago" > -->
                                                <mat-select formControlName="strCondicionPago">
                                                    <mat-option value="">--Seleccione--</mat-option>
                                                    <mat-option [value]="o.strCondicionPago" *ngFor="let o of arrayCondicionPago">{{o.strCondicionPago}}</mat-option>
                                                </mat-select>
                                                <i matSuffix class="bi bi-journal-check fs-4" [ngClass]="{'text-warning': formVendedor.get('strCondicionPago').hasError('required'),'text-success': !formVendedor.get('strCondicionPago').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formVendedor.get('strCondicionPago').hasError('required') && formVendedor.get('strCondicionPago').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-11 bg-light borderbott bordertop  mt-3 pb-3">
                                <div class="row align-items-center mt-3">
                                    <div class="col-4 d-flex align-items-center">
                                        <i class="bi bi-info-circle text-warning fs-4" *ngIf="!isFormDatosFiscalesValid()" matTooltip="Faltan campos por llenar"></i>
                                        <i class="bi bi-check-circle text-success fs-4" *ngIf="isFormDatosFiscalesValid()"></i>
                                    </div>
                                    <div class="col-4">
                                        <h3 class="mb-0 text-center fw-bold">
                                            INFORMACIÓN FISCAL
                                        </h3>
                                    </div>
                                    <div class="col-4"></div>
                                </div>
                                <div class="row ">
                                    <form [formGroup]="formDatosFiscales" class="row">
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>RFC</mat-label>
                                                <input matInput placeholder="235565V9P" formControlName="strRfc" [maxLength]="13">
                                                <i matSuffix class="bi bi-bank fs-4"[ngClass]="{'text-warning': formDatosFiscales.get('strRfc').hasError('required'),'text-success': !formDatosFiscales.get('strRfc').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strRfc').hasError('required') && formDatosFiscales.get('strRfc').touched" class="fs10">
                                               Campo Obligatorio
                                           </mat-error>
                                           <mat-error *ngIf="formDatosFiscales.get('strRfc').hasError('minlength')" class="fs10">
                                            deben ser 13 caracteres
                                        </mat-error>

                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Razon Social</mat-label>
                                                <input matInput placeholder="Nombre Cliente" formControlName="strRazonSocial" [maxlength]="100">
                                                <i matSuffix class="bi bi-bank fs-4" [ngClass]="{'text-warning': formDatosFiscales.get('strRazonSocial').hasError('required'),'text-success': !formDatosFiscales.get('strRazonSocial').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strRazonSocial').hasError('required') && formDatosFiscales.get('strRazonSocial').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Uso de CFDI</mat-label>
                                                <mat-select  formControlName="strCfdi">
                                                    <mat-option value="">--Seleccione--</mat-option>
                                                    <mat-option [value]="o.strClaveUsoCFDI" *ngFor="let o of arrayCfdi">{{o.strDescripcion}}</mat-option>
                                                    
                                                </mat-select>
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formDatosFiscales.get('strCfdi').hasError('required'),'text-success': !formDatosFiscales.get('strCfdi').hasError('required')}">
                                                    receipt_long
                                                </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strCfdi').hasError('required') && formDatosFiscales.get('strCfdi').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Regimen Fiscal</mat-label>
                                                <mat-select formControlName="strRegimenFiscal">
                                                    <mat-option value="">--Selecciona--</mat-option>
                                                    <mat-option [value]="o.strFiscalRegimen" *ngFor="let o of arrayRegimenFiscal">{{o.strDescripcion}}</mat-option>
                                                </mat-select>
                                                <i matSuffix class="bi bi-building fs-4"  [ngClass]="{'text-warning': formDatosFiscales.get('strRegimenFiscal').hasError('required'),'text-success': !formDatosFiscales.get('strRegimenFiscal').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strRegimenFiscal').hasError('required') && formDatosFiscales.get('strRegimenFiscal').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Tipo Pago</mat-label>
                                                <mat-select formControlName="strTipoPago">
                                                   <mat-option value="">--Seleecione--</mat-option>
                                                   <mat-option [value]="o.strClaveSat" *ngFor="let o of arrayFormaPago">{{o.strFormaPagoTipo}}</mat-option>
                                                </mat-select>
                                                <i matSuffix class="bi bi-cash fs-4" [ngClass]="{'text-warning': formDatosFiscales.get('strTipoPago').hasError('required'),'text-success': !formDatosFiscales.get('strTipoPago').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strTipoPago').hasError('required') && formDatosFiscales.get('strTipoPago').touched" class="fs10">
                                               Campo Obligatorio
                                           </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Codigo Postal</mat-label>
                                                <input matInput placeholder="C.P.:36213"  formControlName="strCp" type="text"  (keydown)="onKeyDown($event)" [maxlength]="15">
                                                <i matSuffix class="bi bi-pin-map fs-4" [ngClass]="{'text-warning': formDatosFiscales.get('strEstado').hasError('required'),'text-success': !formDatosFiscales.get('strEstado').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strCp').hasError('required') && formDatosFiscales.get('strCp').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error> 
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Estado</mat-label>
                                                <input matInput placeholder="Estado" readonly="true" formControlName="strEstado" [maxlength]="30">
                                                <span matSuffix class="material-symbols-outlined"  [ngClass]="{'text-warning': formDatosFiscales.get('strEstado').hasError('required'),'text-success': !formDatosFiscales.get('strEstado').hasError('required')}">villa</span>
                                            </mat-form-field>
                                            <mat-error *ngIf="!formDatosFiscales.get('strCp').hasError('required') && formDatosFiscales.get('strEstado').hasError('required')" class="fs10">
                                                Debe presionar enter en C.P. 
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Ciudad</mat-label>
                                                <input matInput placeholder="Ciudad" readonly="true" formControlName="strCiudad" [maxlength]="100">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formDatosFiscales.get('strCiudad').hasError('required'),'text-success': !formDatosFiscales.get('strCiudad').hasError('required')}"> location_city </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="!formDatosFiscales.get('strCp').hasError('required') && formDatosFiscales.get('strCiudad').hasError('required')" class="fs10">
                                                Debe presionar enter en C.P. 
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Colonia</mat-label>
                                                <mat-select formControlName="strColonia" (selectionChange)="onSelectColonias()">
                                                    <mat-option value="">--Seleccione--</mat-option>
                                                    <mat-option value="{{o.strColonia}}" *ngFor="let o of arrayColonias">{{o.strColonia}}</mat-option>
                                                    <mat-option value="otra">Otra</mat-option>
                                                </mat-select>
                                                <i matSuffix class="bi bi-geo-alt fs-4" [ngClass]="{'text-warning': formDatosFiscales.get('strColonia').hasError('required'),'text-success': !formDatosFiscales.get('strColonia').hasError('required')}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strColonia').hasError('required') && formDatosFiscales.get('strColonia').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            
                                            <input type="text" formControlName="strColoniaOtra" placeholder="Escriba otra colonia" class="form-control mt-2" *ngIf="formDatosFiscales.get('strColonia').value =='otra'">
                                            <mat-error *ngIf="formDatosFiscales.get('strColoniaOtra').hasError('required')" class="fs10">Campo Obligatorio</mat-error>
                                                
                                          
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Calle</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strCalle" [maxlength]="100">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formDatosFiscales.get('strCalle').hasError('required'),'text-success': !formDatosFiscales.get('strCalle').hasError('required')}">
                                                    edit_road
                                                </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strCalle').hasError('required') && formDatosFiscales.get('strCalle').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>No. Exterior</mat-label>
                                                <input matInput placeholder="Placeholder" type="text" formControlName="strNoExterior" [maxlength]="20">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formDatosFiscales.get('strNoExterior').hasError('required'),'text-success': !formDatosFiscales.get('strNoExterior').hasError('required')}"> home_pin </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formDatosFiscales.get('strNoExterior').hasError('required') && formDatosFiscales.get('strNoExterior').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>            
                            <div class="col-11 bg-light borderbott bordertop  mt-3 pb-3"  *ngIf="strTipoCliente=='CREDITO'">
                                <div class="row align-items-center mt-3">
                                    <div class="col-4 d-flex align-items-center">
                                        <i class="bi bi-info-circle text-warning fs-4" *ngIf="!isFormBankValid()" matTooltip="Faltan campos por llenar">
                                        </i>
                                        <i class="bi bi-check-circle text-success fs-4" *ngIf="isFormBankValid()">
                                        </i>
                                    </div>
                                    <div class="col-4">
                                        <h3 class="mb-0 text-center fw-bold">
                                            INFORMACIÓN BANCARIA
                                        </h3>
                                    </div>
                                    <div class="col-4"></div>
                                </div>
                                <div class="row">
                                    <form [formGroup]="formBanck" class="row">
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Banco</mat-label>
                                                <!-- <input matInput placeholder="Placeholder" formControlName="strBanco"> -->
                                                <mat-select  formControlName="strBanco">
                                                    <mat-option value="">--Selecciona--</mat-option>
                                                    <mat-option [value]="o.strClaveBanco" *ngFor="let o of arrayBancos">{{o.strBanco}}</mat-option>
                                                  </mat-select>
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formBanck.get('strBanco').hasError('required'),'text-success': !formBanck.get('strBanco').hasError('required')}">
                                                    account_balance
                                                </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formBanck.get('strBanco').hasError('required') && formBanck.get('strBanco').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Cuenta</mat-label>
                                                <input matInput placeholder="Cuenta" formControlName="strCuenta" [maxlength]="10" (keypress)="validarNumeros($event)">
                                                <i matSuffix class="bi bi-file-earmark-text fs-4" [ngClass]="{'text-warning': formBanck.get('strCuenta').invalid,'text-success': !formBanck.get('strCuenta').invalid}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formBanck.get('strCuenta').hasError('required') && formBanck.get('strCuenta').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formBanck.get('strCuenta').hasError('minlength')" class="fs10">
                                                 Se requieren exactamente 10 caracteres.
                                            </mat-error>
                                        </div>        
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Cuenta Clave</mat-label>
                                                <input matInput placeholder="cuenta clave" formControlName="strCuentaClave" [maxlength]="18" (keypress)="validarNumeros($event)">
                                                <i matSuffix class="bi bi-file-lock2 fs-4" [ngClass]="{'text-warning': formBanck.get('strCuentaClave').invalid,'text-success': !formBanck.get('strCuentaClave').invalid}"></i>
                                            </mat-form-field>
                                            <mat-error *ngIf="formBanck.get('strCuentaClave').hasError('required') && formBanck.get('strCuentaClave').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formBanck.get('strCuentaClave').hasError('minlength')" class="fs10">
                                                Se requieren exactamente 18 caracteres.
                                            </mat-error>
                                        </div>
                                    </form>
                                 </div>
                            </div>
                            <div class="col-11 bg-light borderbott bordertop  mt-3 pb-3"  *ngIf="strTipoCliente=='CREDITO'">
                                <div class="row align-items-center mt-3">
                                    <div class="col-4 d-flex align-items-center">
                                        <i class="bi bi-info-circle text-warning fs-4" *ngIf="!isFormReferenciasValid()" matTooltip="Faltan campos por llenar">
                                        </i>
                                        <i class="bi bi-check-circle text-success fs-4" *ngIf="isFormReferenciasValid()">
                                        </i>
                                    </div>
                                    <div class="col-4">
                                        <h3 class="mb-0 text-center fw-bold">
                                            INFORMACIÓN DE REFERENCIAS
                                        </h3>
                                    </div>
                                    <div class="col-4"></div>
                                </div>
                                <div class="row">
                                    <form [formGroup]="formReferencias" class="row">
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Empresa 1</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strEmpresa" [maxlength]="100">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formReferencias.get('strEmpresa').hasError('required'),'text-success': !formReferencias.get('strEmpresa').hasError('required')}">
                                                    support_agent
                                                </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formReferencias.get('strEmpresa').hasError('required') && formReferencias.get('strEmpresa').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Nombre</mat-label>
                                                <input matInput type="email" placeholder="Placeholder" formControlName="strNombre" [maxlength]="50">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formReferencias.get('strNombre').invalid,'text-success': !formReferencias.get('strNombre').invalid}"> contact_mail </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formReferencias.get('strNombre').hasError('required') && formReferencias.get('strNombre').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Telefono </mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strTelefono"  (keypress)="validarNumeros($event)"  [maxlength]="10">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formReferencias.get('strTelefono').invalid,'text-success': !formReferencias.get('strTelefono').invalid}"> contact_phone </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formReferencias.get('strTelefono').hasError('required') && formReferencias.get('strTelefono').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formReferencias.get('strTelefono').hasError('minlength')" class="fs10">
                                                Se requieren exactamente 10 caracteres.
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Empresa 2</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strEmpresa2" [maxlength]="100">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formReferencias.get('strEmpresa2').hasError('required'),'text-success': !formReferencias.get('strEmpresa2').hasError('required')}">
                                                    support_agent
                                                </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formReferencias.get('strEmpresa2').hasError('required') && formReferencias.get('strEmpresa2').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Nombre 2</mat-label>
                                                <input matInput type="email" placeholder="Placeholder" formControlName="strNombre2" [maxlength]="50">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formReferencias.get('strNombre2').invalid,'text-success': !formReferencias.get('strNombre2').invalid}"> contact_mail </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formReferencias.get('strNombre2').hasError('required') && formReferencias.get('strNombre2').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Telefono 2</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strTelefono2"  (keypress)="validarNumeros($event)"  [maxlength]="10">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formReferencias.get('strTelefono2').invalid,'text-success': !formReferencias.get('strTelefono2').invalid}"> contact_phone </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formReferencias.get('strTelefono2').hasError('required') && formReferencias.get('strTelefono2').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formReferencias.get('strTelefono2').hasError('minlength')" class="fs10">
                                                Se requieren exactamente 10 caracteres.
                                            </mat-error>
                                        </div>
                                        
                                    </form>
                                 </div>
                            </div>
                            <div class="col-11 bg-light borderbott bordertop  mt-3 pb-3">
                                <div class="row align-items-center mt-3">
                                    <div class="col-4 d-flex align-items-center">
                                        <span class="material-symbols-outlined text-warning fs-3" *ngIf="!isFormContactoValid()" matTooltip="Faltan campos por llenar">
                                            info
                                        </span>
                                        <span class="material-symbols-outlined text-success fs-3" *ngIf="isFormContactoValid()">
                                            check_circle
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <h3 class="mb-0 text-center fw-bold">
                                            INFORMACIÓN DE CONTACTO COMPRA
                                        </h3>
                                    </div>
                                    <div class="col-4"></div>
                                </div>
                                <div class="row">
                                    <form [formGroup]="formContacto" class="row">
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Contacto compra</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strContacto" [maxlength]="50">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formContacto.get('strContacto').hasError('required'),'text-success': !formContacto.get('strContacto').hasError('required')}">
                                                    support_agent
                                                    </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formContacto.get('strContacto').hasError('required') && formContacto.get('strContacto').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Telefono </mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strTelefono"  (keypress)="validarNumeros($event)"  [maxlength]="10">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formContacto.get('strTelefono').invalid,'text-success': !formContacto.get('strTelefono').invalid}"> contact_phone </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formContacto.get('strTelefono').hasError('required') && formContacto.get('strTelefono').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formContacto.get('strTelefono').hasError('minlength')" class="fs10">
                                                Se requieren exactamente 10 caracteres.
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Correo</mat-label>
                                                <input matInput type="email" placeholder="Placeholder" formControlName="strCorreo" [maxlength]="56">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formContacto.get('strCorreo').invalid,'text-success': !formContacto.get('strCorreo').invalid}"> contact_mail </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formContacto.get('strCorreo').hasError('required') && formContacto.get('strCorreo').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formContacto.get('strCorreo').hasError('email')" class="fs10">
                                                Ingrese un correo valido
                                            </mat-error>
                                        </div>
                                    </form>
                                 </div>
                            </div>
                            <div class="col-11 bg-light borderbott bordertop  mt-3 pb-3">
                                <div class="row align-items-center mt-3">
                                    <div class="col-4 d-flex align-items-center">
                                        <span class="material-symbols-outlined text-warning fs-3" *ngIf="!isFormContactoPagoValid()" matTooltip="Faltan campos por llenar">
                                            info
                                        </span>
                                        <span class="material-symbols-outlined text-success fs-3" *ngIf="isFormContactoPagoValid()">
                                            check_circle
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <h3 class="mb-0 text-center fw-bold">
                                            INFORMACIÓN DE CONTACTO PAGO
                                        </h3>
                                    </div>
                                    <div class="col-4"></div>
                                </div>
                                <div class="row">
                                    <form [formGroup]="formContactoPago" class="row">
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Contacto Pago</mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strContacto" [maxlength]="50">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formContactoPago.get('strContacto').hasError('required'),'text-success': !formContactoPago.get('strContacto').hasError('required')}">
                                                    support_agent
                                                    </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formContactoPago.get('strContacto').hasError('required') && formContactoPago.get('strContacto').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Telefono </mat-label>
                                                <input matInput placeholder="Placeholder" formControlName="strTelefono"  (keypress)="validarNumeros($event)"  [maxlength]="10">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formContactoPago.get('strTelefono').invalid,'text-success': !formContactoPago.get('strTelefono').invalid}"> contact_phone </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formContactoPago.get('strTelefono').hasError('required') && formContactoPago.get('strTelefono').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formContactoPago.get('strTelefono').hasError('minlength')" class="fs10">
                                                Se requieren exactamente 10 caracteres.
                                            </mat-error>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                                            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                                                <mat-label>Correo</mat-label>
                                                <input matInput type="email" placeholder="Placeholder" formControlName="strCorreo" [maxlength]="56">
                                                <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-warning': formContactoPago.get('strCorreo').invalid,'text-success': !formContactoPago.get('strCorreo').invalid}"> contact_mail </span>
                                            </mat-form-field>
                                            <mat-error *ngIf="formContactoPago.get('strCorreo').hasError('required') && formContactoPago.get('strCorreo').touched" class="fs10">
                                                Campo Obligatorio
                                            </mat-error>
                                            <mat-error *ngIf="formContactoPago.get('strCorreo').hasError('email')" class="fs10">
                                                Ingrese un correo valido
                                            </mat-error>
                                        </div>
                                    </form>
                                 </div>
                            </div>
                            <div class="col-11 bg-light borderbott bordertop  my-3 pb-3">
                                <div class="row align-items-center mt-3">
                                    <div class="col-4 d-flex align-items-center">
                                        <span class="material-symbols-outlined text-warning fs-3" *ngIf="!isFormPreguntasValid()" matTooltip="Faltan campos por llenar">
                                            info
                                        </span>
                                        <span class="material-symbols-outlined text-success fs-3" *ngIf="isFormPreguntasValid()">
                                            check_circle
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <h3 class="mb-0 text-center fw-bold">
                                            PREGUNTAS
                                        </h3>
                                    </div>
                                    <div class="col-4"></div>
                                </div>
                                <div class="row">
                                    <form [formGroup]="formPreguntas" class="row">
                                    <div class="col-12 col-md-6  mt-3 d-flex flex-column text-center">
                                        <mat-label class="fw-bold">¿Formato de entrega?</mat-label>   
                                        <mat-radio-group aria-label="Select an option" formControlName="strPregunta1">
                                            <mat-radio-button value="1" color="primary">Si</mat-radio-button>
                                            <mat-radio-button value="2" color="primary" class="ms-5">No</mat-radio-button>
                                          </mat-radio-group> 
                                          <mat-error *ngIf="formPreguntas.get('strPregunta1').hasError('required')" class="fs10">
                                            Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-12 col-md-6  mt-3 d-flex flex-column text-center">
                                        <mat-label class="fw-bold">¿Es necesario llevar orden de compra?</mat-label>   
                                        <mat-radio-group  aria-label="Select an option" formControlName="strPregunta2">
                                            <mat-radio-button color="primary" value="1">Si</mat-radio-button>
                                            <mat-radio-button color="primary" value="2" class="ms-5">No</mat-radio-button>
                                          </mat-radio-group> 
                                          <mat-error *ngIf="formPreguntas.get('strPregunta2').hasError('required')" class="fs10">
                                            Campo Obligatorio
                                        </mat-error>
                                    </div>
                                </form>
                                </div>
                            </div>  
                            <div class="col-12 my-3">
                              <button *ngIf="strAccion_Solicitud != 'DATOS_GENERALES'" class="btn d-flex ms-auto align-items-center text-primary fw-bold" (click)="mostrarDocumentos()">Continuar con documentos<i class="bi bi-arrow-right-circle ms-2 fs-4"></i></button>  
                            </div>
                            <div class="col-12 my-3 mx-3" *ngIf="strAccion_Solicitud == 'DATOS_GENERALES'">
                                <button [disabled]="strCliente == ''" (click)="fnUpdate_Datos_generales_Cte()" class="btn btn-primary d-flex ms-auto p-2">ACTUALIZAR DATOS</button>  
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Fin de primer tab -->
            <!-- inicio de segundo tab -->
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <div class="row  justify-content-center bgFondoTab borderbott my-3 bordertop ">
                        <div class="col-11 bgtable  bordertop py-2 mt-3 ">
                            <div class="col-12 d-flex justify-content-end align-items-center">
                                <a class="btn btn-secondary px-2 py-1 fw-bold fs10 d-flex align-items-center shadow-sm me-2" href="https://www.ilovepdf.com/es/comprimir_pdf"  target="_blank" matTooltip="Comprimir Archivo">
                                      Comprimir <span class="material-symbols-outlined fs-5">
                                        description
                                        </span> 
                                </a>
                            </div>
                            <div class="col-12 text-center" *ngIf="strTipoCliente==''">
                                <span class="fw-bold justify-content-center d-flex align-items-end jumping-text">Seleccione el tipo de cliente "CREDITO O CONTADO"</span>
                            </div>
                            <div class="col-12 d-flex  justify-content-around">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group w-100" [(ngModel)]="strTipoCliente">
                                    <div class="col-12 d-flex justify-content-around">
                                        <mat-radio-button class="example-radio-button " color="primary" value="CREDITO" >
                                            CREDITO
                                        </mat-radio-button>
                                        <mat-radio-button class="example-radio-button" color="primary"  value="CONTADO" [disabled]="strAccion_Solicitud == 'ACTUALIZAR_CREDITO' ||strAccion_Solicitud == 'VER' ">
                                            CONTADO
                                        </mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-11 bg-light borderbott  py-3 mb-3">
                               <!--   INE--> 
                            <div class="row align-items-center" *ngIf="strTipoCliente == 'CREDITO' ">
                                <div *ngIf="strAccion_Solicitud =='VER'">
                                    <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                        <span class="me-2">INE </span>
                                        <!-- Button trigger modal -->
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#VERModal" (click)="fnOpenModal_VER('INE',nombreIne)">
                                            VER DOCUMENTO
                                        </button> 
                                    </mat-label> 
                                </div>
                                <div *ngIf="strAccion_Solicitud !='VER'" class="col-12 d-flex align-items-center">
                                    <div class="col-6 text-center d-flex flex-column">
                                        <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                            <span class="me-2">INE</span> 
                                            <span *ngIf="nombreIne!=''" class="material-symbols-outlined fs-5 shadow-sm rounded text-warning"  (click)="fnClickIne()" matTooltip="Sustituir Archivo">
                                                cloud_upload
                                            </span>
                                        </mat-label>
                                            <span *ngIf="nombreIne!=''" class="material-symbols-outlined text-success fs-1">
                                                check_circle
                                            </span>
                                            <span *ngIf="nombreIne!=''" class="d-flex align-items-center justify-content-center">
                                                Cargado correctamente
                                            </span> 
                                            <button *ngIf="nombreIne==''" (click)="fnClickIne()" class="btn btn-primary d-flex align-items-center justify-content-center w-75 mx-auto">
                                                <span class="material-symbols-outlined">
                                                    cloud_upload
                                                </span>   
                                                Seleccionar Archivo
                                            </button>
                                        <input class="d-none" accept="application/pdf,image/*" (change)="handleFileIne($event)" #fileIne placeholder="Placeholder" type="file">
                                    </div>
                                    <div class="col-6 text-center">
                                        <span *ngIf="strAccion_Solicitud==''"class="rounded fs-6  py-2 px-3" [ngClass]="{'bg-warning text-dark':nombreIne=='','bg-success text-white':nombreIne!=''}">
                                                                       
                                            <span *ngIf="nombreIne==''">Archivo Vacio</span> 
                                            <span *ngIf="nombreIne!=''  && strAccion_Solicitud==''"> {{nombreIne}}  </span> 
                                        </span>

                                        
                                            <a *ngIf="nombreIne!='' && strAccion_Solicitud=='EDITAR'" [href]="nombreIne" type="button" class="btn btn-success" title="{{nombreIne}}" target="_blank"><i class="fa-solid fa-download"></i></a>
                                       
                                    </div>
                                </div>
                            </div>
                               <!-- FIN INE--> 
                            <hr *ngIf="strTipoCliente == 'CREDITO'">
                            <div class="row align-items-center" *ngIf="strTipoCliente == 'CREDITO'|| strTipoCliente == 'CONTADO'">
                                <div *ngIf="strAccion_Solicitud =='VER'">
                                    <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                        <span class="me-2">CONSTANCIA FISCAL </span> 
                                        <!-- Button trigger modal -->  
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#VERModal" (click)="fnOpenModal_VER('CONSTANCIA FISCAL',nombreConstanciaFiscal)">
                                            VER DOCUMENTO
                                        </button> 
                                    </mat-label> 
                                </div>
                                <div *ngIf="strAccion_Solicitud !='VER'" class="col-12 d-flex align-items-center">
                                    <div class="col-6 text-center d-flex flex-column">
                                        <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                            <span class="me-2">CONSTANCIA FISCAL</span> 
                                            <span *ngIf="nombreConstanciaFiscal!=''" class="material-symbols-outlined fs-5 shadow-sm rounded text-warning"  (click)="fnClickconstanciaFiscal()" matTooltip="Sustituir Archivo">
                                                cloud_upload
                                            </span>
                                        </mat-label>
                                
                                    
                                        <span *ngIf="nombreConstanciaFiscal!=''" class="material-symbols-outlined text-success fs-1">
                                            check_circle
                                        </span>
                                        <span *ngIf="nombreConstanciaFiscal!=''" class="d-flex align-items-center justify-content-center">
                                            Cargado correctamente
                                        </span> 
                                        <button *ngIf="nombreConstanciaFiscal==''"  (click)="fnClickconstanciaFiscal()" class="btn btn-primary d-flex align-items-center justify-content-center w-75 mx-auto">
                                            <span class="material-symbols-outlined">
                                                cloud_upload
                                            </span>   
                                            Seleccionar Archivo
                                        </button>
                                        <input class="d-none" #fileConstanciaFiscal accept="application/pdf,image/*" (change)="handleFileConstFisc($event)" placeholder="Placeholder" type="file">
                                    </div>
                                    <div class="col-6 text-center">
                                        <span *ngIf = "strAccion_Solicitud==''" class="rounded fs-6  py-2 px-3" [ngClass]="{'bg-warning text-dark':nombreConstanciaFiscal=='','bg-success text-white':nombreConstanciaFiscal!=''}">
                                        
                                            <span *ngIf="nombreConstanciaFiscal==''">Archivo Vacio</span> 
                                            <span *ngIf="nombreConstanciaFiscal!=''  && strAccion_Solicitud==''"> {{nombreConstanciaFiscal}}  </span> 
                                        </span>
                                        <a *ngIf="nombreConstanciaFiscal!='' && strAccion_Solicitud=='EDITAR'" [href]="nombreConstanciaFiscal" type="button" class="btn btn-success" title="{{nombreConstanciaFiscal}}" target="_blank"><i class="fa-solid fa-download"></i></a>
                                       
                                    </div>
                                </div>
                            </div>
                             <!-- estado de cuenta --> 
                            <hr *ngIf="strTipoCliente == 'CREDITO'">
                            <div *ngIf="strTipoCliente == 'CREDITO'">
                                <div *ngIf="strAccion_Solicitud =='VER'">
                                    <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                        <span class="me-2">ESTADO DE CUENTA</span> 
                                        <!-- Button trigger modal --> 
                                         <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#VERModal" (click)="fnOpenModal_VER('ESTADO DE CUENTA',nombreEdoCuenta)">
                                            VER DOCUMENTO
                                        </button>
                                    </mat-label>
                                </div>
                                <div *ngIf="strAccion_Solicitud !='VER'">
                                    <div class="row align-items-center" *ngIf="strTipoCliente == 'CREDITO'">
                                        <div class="col-6 text-center d-flex flex-column">
                                            <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                                <span class="me-2">ESTADO DE CUENTA</span> 
                                                <span *ngIf="nombreEdoCuenta!=''" class="material-symbols-outlined fs-5 shadow-sm rounded text-warning"  (click)="fnClickEdoCuenta()" matTooltip="Sustituir Archivo">
                                                    cloud_upload
                                                </span>
                                            </mat-label>
                                            <span *ngIf="nombreEdoCuenta!=''" class="material-symbols-outlined text-success fs-1">
                                                check_circle
                                            </span>
                                            <span *ngIf="nombreEdoCuenta!=''" class="d-flex align-items-center justify-content-center">
                                                Cargado correctamente
                                            </span> 
                                            <button *ngIf="nombreEdoCuenta==''" (click)="fnClickEdoCuenta()" class="btn btn-primary d-flex align-items-center justify-content-center w-75 mx-auto">
                                                <span class="material-symbols-outlined">
                                                    cloud_upload
                                                </span>   
                                                Seleccionar Archivo
                                            </button>
                                            <input class="d-none" #fileEdoCuenta accept="application/pdf,image/*" (change)="handleFileEdoCuenta($event)" placeholder="Placeholder" type="file">
                                        </div>
                                        <div class="col-6 text-center">
                                            <span *ngIf=" strAccion_Solicitud==''" class="rounded fs-6  py-2 px-3" [ngClass]="{'bg-warning text-dark':nombreEdoCuenta=='','bg-success text-white':nombreEdoCuenta!=''}">
                                              
                                                <span *ngIf="nombreEdoCuenta==''">Archivo Vacio</span> 
                                                <span *ngIf="nombreEdoCuenta!=''  && strAccion_Solicitud==''"> {{nombreEdoCuenta}}  </span> 
                                            </span>
                                            <a *ngIf="nombreEdoCuenta!='' && strAccion_Solicitud=='EDITAR'" [href]="nombreEdoCuenta" type="button" class="btn btn-success" title="{{nombreEdoCuenta}}" target="_blank"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <!-- fin estado de cuenta --> 
                               <!-- buro de credito --> 
                            <hr *ngIf="strTipoCliente == 'CREDITO'">
                            <div *ngIf="strTipoCliente == 'CREDITO'">
                                <div *ngIf="strAccion_Solicitud =='VER'">
                                    <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                        <span class="me-2">REPORTE BURO DE CREDITO</span> 
                                        <!-- Button trigger modal --> 
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#VERModal" (click)="fnOpenModal_VER('REPORTE BURO DE CREDITO',nombreBuroCredito)">
                                            VER DOCUMENTO
                                        </button>
                                    </mat-label>
                                </div>
                                <div *ngIf="strAccion_Solicitud !='VER'">
                                    <div class="row align-items-center" *ngIf="strTipoCliente == 'CREDITO'">
                                        <div class="col-6 text-center d-flex flex-column">
                                            <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                                <span class="me-2">REPORTE BURO DE CREDITO</span> 
                                                <span *ngIf="nombreBuroCredito!=''" class="material-symbols-outlined fs-5 shadow-sm rounded text-warning"  (click)="fnclickBuroCredito()" matTooltip="Sustituir Archivo">
                                                    cloud_upload
                                                </span>
                                            </mat-label>
                                            <span *ngIf="nombreBuroCredito!=''" class="material-symbols-outlined text-success fs-1">
                                                check_circle
                                            </span>
                                            <span *ngIf="nombreBuroCredito!=''" class="d-flex align-items-center justify-content-center">
                                                Cargado correctamente
                                            </span> 
                                            <button *ngIf="nombreBuroCredito==''"  (click)="fnclickBuroCredito()" class="btn btn-primary d-flex align-items-center justify-content-center w-75 mx-auto">
                                                <span class="material-symbols-outlined">
                                                    cloud_upload
                                                </span>   
                                                Seleccionar Archivo
                                            </button>
                                            <input class="d-none" #fileBuroCredito accept="application/pdf,image/*" (change)="handleFileBuro($event)" placeholder="Placeholder" type="file">
                                        </div>
                                        <div class="col-6 text-center">
                                            <span *ngIf="strAccion_Solicitud==''" class="rounded fs-6  py-2 px-3" [ngClass]="{'bg-warning text-dark':nombreBuroCredito=='','bg-success text-white':nombreBuroCredito!=''}">
                                                <span *ngIf="nombreBuroCredito==''">Archivo Vacio</span> 
                                                <span *ngIf="nombreBuroCredito!=''  && strAccion_Solicitud==''"> {{nombreBuroCredito}}  </span> 
                                            </span>
                                            
                                            <a *ngIf="nombreBuroCredito!='' && strAccion_Solicitud=='EDITAR'" [href]="nombreBuroCredito" type="button" class="btn btn-success" title="{{nombreBuroCredito}}" target="_blank"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--fin buro de credito --> 
                              <!--CONTRATO COMPRA VENTA --> 
                            <hr *ngIf="strTipoCliente  == 'CREDITO'">
                            <div *ngIf="strTipoCliente == 'CREDITO'">
                                <div *ngIf="strAccion_Solicitud =='VER' ">
                                    <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                        <span class="me-2">CONTRATO COMPRA VENTA</span> 
                                        <!-- Button trigger modal --> 
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#VERModal" (click)="fnOpenModal_VER('CONTRATO COMPRA VENTA',nombreCompraVenta)">
                                            VER DOCUMENTO
                                        </button>
                                    </mat-label>
                                </div>
                                <div *ngIf="strAccion_Solicitud !='VER'">
                                    <div class="row align-items-center" *ngIf="strTipoCliente  == 'CREDITO'">
                                        <div class="col-6 text-center d-flex flex-column">
                                            <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                                <span class="me-2">CONTRATO COMPRA VENTA</span> 
                                                <span *ngIf="nombreCompraVenta!=''" class="material-symbols-outlined fs-5 shadow-sm rounded text-warning"  (click)="fnClickCompraVenta()" matTooltip="Sustituir Archivo">
                                                    cloud_upload
                                                </span>
                                            </mat-label>
                                            <span *ngIf="nombreCompraVenta!=''" class="material-symbols-outlined text-success fs-1">
                                                check_circle
                                            </span>
                                            <span *ngIf="nombreCompraVenta!=''" class="d-flex align-items-center justify-content-center">
                                                Cargado correctamente
                                            </span> 
                                            <button *ngIf="nombreCompraVenta==''" (click)="fnClickCompraVenta()" class="btn btn-primary d-flex align-items-center justify-content-center w-75 mx-auto">
                                                <span class="material-symbols-outlined">
                                                    cloud_upload
                                                </span>   
                                                Seleccionar Archivo
                                            </button>
                                            <input class="d-none" #fileCompraVenta accept="application/pdf,image/*" placeholder="Placeholder" (change)="handleFileCompraVenta($event)" type="file">
                                        </div>
                                        <div class="col-6 text-center">
                                            <span *ngIf="strAccion_Solicitud==''" class="rounded fs-6  py-2 px-3" [ngClass]="{'bg-warning text-dark':nombreCompraVenta=='','bg-success text-white':nombreCompraVenta!=''}">
                                                <span *ngIf="nombreCompraVenta==''">Archivo Vacio</span> 
                                                <span *ngIf="nombreCompraVenta!=''  && strAccion_Solicitud==''"> {{nombreCompraVenta}}  </span> 
                                               </span>
                                            <a *ngIf="nombreCompraVenta!=''  && strAccion_Solicitud=='EDITAR'" [href]="nombreCompraVenta" type="button" class="btn btn-success" title="  {{nombreCompraVenta}}" target="_blank"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <!--fin CONTRATO COMPRA VENTA --> 
                              <!--PAGARE --> 
                            <hr *ngIf="strTipoCliente  == 'CREDITO'">
                            <div *ngIf="strTipoCliente == 'CREDITO'">
                                <div *ngIf="strAccion_Solicitud =='VER'">
                                    <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                        <span class="me-2">PAGARE</span> 
                                        <!-- Button trigger modal --> 
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#VERModal" (click)="fnOpenModal_VER('PAGARE',nombrePagare)">
                                            VER DOCUMENTO
                                        </button>
                                    </mat-label>
                                </div>
                                <div *ngIf="strAccion_Solicitud !='VER'">
                                    <div class="row align-items-center" *ngIf="strTipoCliente  == 'CREDITO'">
                                        <div class="col-6 text-center d-flex flex-column">
                                            <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                                <span class="me-2">PAGARE</span> 
                                                <span *ngIf="nombrePagare!=''" class="material-symbols-outlined fs-5 shadow-sm rounded text-warning"  (click)="fnClickPagare()"  matTooltip="Sustituir Archivo">
                                                    cloud_upload
                                                </span>
                                            </mat-label>
                                            <span *ngIf="nombrePagare!=''" class="material-symbols-outlined text-success fs-1">
                                                check_circle
                                            </span>
                                            <span *ngIf="nombrePagare!=''" class="d-flex align-items-center justify-content-center">
                                                Cargado correctamente
                                            </span> 
                                            <button *ngIf="nombrePagare==''"  (click)="fnClickPagare()" class="btn btn-primary d-flex align-items-center justify-content-center w-75 mx-auto">
                                                <span class="material-symbols-outlined">
                                                    cloud_upload
                                                </span>   
                                                Seleccionar Archivo
                                            </button>
                                            <input class="d-none" #filePagare accept="application/pdf,image/*" (change)="handleFilePagare($event)" placeholder="Placeholder" type="file">
                                        </div>
                                        <div class="col-6 text-center">
                                            <span *ngIf="strAccion_Solicitud==''" class="rounded fs-6  py-2 px-3" [ngClass]="{'bg-warning text-dark':nombrePagare=='','bg-success text-white':nombrePagare!=''}">
                                               
                                                <span *ngIf="nombrePagare==''">Archivo Vacio</span> 
                                                <span *ngIf="nombrePagare!=''  && strAccion_Solicitud==''"> {{nombrePagare}}  </span> 
                                            
                                            </span>
                                            <a *ngIf="nombrePagare!=''  && strAccion_Solicitud=='EDITAR'" [href]="nombrePagare" type="button" class="btn btn-success" title="  {{nombrePagare}}" target="_blank"><i class="fa-solid fa-download"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--FIN PAGARE --> 
                            <!--ACTA CONSTITUTIVA--> 
                            <hr *ngIf="strTipoCliente  == 'CREDITO'">
                            <div *ngIf="strTipoCliente == 'CREDITO'">
                                <div *ngIf="strAccion_Solicitud =='VER'">
                                    <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                        <span class="me-2">ACTA CONSTITUTIVA</span> 
                                        <!-- Button trigger modal --> 
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#VERModal" (click)="fnOpenModal_VER('ACTA CONSTITUTIVA',nombreAltaConst)">
                                            VER DOCUMENTO
                                        </button>
                                    </mat-label>
                                </div>
                                <div *ngIf="strAccion_Solicitud !='VER'">
                                    <div class="row align-items-center" *ngIf="strTipoCliente  == 'CREDITO'">
                                        <div class="col-6 text-center d-flex flex-column">
                                            <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                                <span class="me-2">ACTA CONSTITUTIVA</span> 
                                                <span *ngIf="nombreAltaConst!=''" class="material-symbols-outlined fs-5 shadow-sm rounded text-warning"  (click)="fnClickActaCons()" matTooltip="Sustituir Archivo">
                                                    cloud_upload
                                                </span>
                                            </mat-label>
                                            <span *ngIf="nombreAltaConst!=''" class="material-symbols-outlined text-success fs-1">
                                                check_circle
                                            </span>
                                            <span *ngIf="nombreAltaConst!=''" class="d-flex align-items-center justify-content-center">
                                                Cargado correctamente
                                            </span> 
                                            <button *ngIf="nombreAltaConst==''" (click)="fnClickActaCons()" class="btn btn-primary d-flex align-items-center justify-content-center w-75 mx-auto">
                                                <span class="material-symbols-outlined">
                                                    cloud_upload
                                                </span>
                                                Seleccionar Archivo
                                            </button>
                                            <input class="d-none" #fileActaConst accept="application/pdf,image/*" (change)="handleFileAltaConst($event)" placeholder="Placeholder" type="file">
                                        </div>
                                        <div class="col-6 text-center">
                                            <span *ngIf="strAccion_Solicitud==''" class="rounded fs-6  py-2 px-3" [ngClass]="{'bg-warning text-dark':nombreAltaConst=='','bg-success text-white':nombreAltaConst!=''}">
                                                <span *ngIf="nombreAltaConst==''">Archivo Vacio</span> 
                                                <span *ngIf="nombreAltaConst!=''  && strAccion_Solicitud==''"> {{nombreAltaConst}}  </span> 
                                               
                                            </span>
                                            <a *ngIf="nombreAltaConst!='' && strAccion_Solicitud=='EDITAR'" [href]="nombreAltaConst" type="button" class="btn btn-success" title="{{nombreAltaConst}}" target="_blank"><i class="fa-solid fa-download"></i></a>
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--FIN ACTA CONSTITUTIVA--> 
                            <!--CREDITO--> 
                            <hr *ngIf="strTipoCliente == 'CREDITO'">
                            <div *ngIf="strTipoCliente == 'CREDITO'">
                                <div *ngIf="strAccion_Solicitud =='VER'">
                                    <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                        <span class="me-2">COMPROBANTE DE DOMICILIO</span> 
                                        <!-- Button trigger modal --> 
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#VERModal" (click)="fnOpenModal_VER('COMPROBANTE DE DOMICILIO',nombreCompDomicilio)">
                                            VER DOCUMENTO
                                        </button>
                                    </mat-label>
                                </div>
                                <div *ngIf="strAccion_Solicitud !='VER'">
                                    <div class="row align-items-center" *ngIf="strTipoCliente == 'CREDITO'">
                                        <div class="col-6 text-center d-flex flex-column">
                                            <mat-label class="fw-bold d-flex justify-content-center align-items-center">
                                                <span class="me-2">COMPROBANTE DE DOMICILIO</span> 
                                                <span *ngIf="nombreCompDomicilio!=''" class="material-symbols-outlined fs-5 shadow-sm rounded text-warning"  (click)="fnClickComproDomicilio()" matTooltip="Sustituir Archivo">
                                                    cloud_upload
                                                </span>
                                            </mat-label>
                                            <span *ngIf="nombreCompDomicilio!=''" class="material-symbols-outlined text-success fs-1">
                                                check_circle
                                            </span>
                                            <span *ngIf="nombreCompDomicilio!=''" class="d-flex align-items-center justify-content-center">
                                                Cargado correctamente
                                            </span> 
                                            <button *ngIf="nombreCompDomicilio==''" (click)="fnClickComproDomicilio()" class="btn btn-primary d-flex align-items-center justify-content-center w-75 mx-auto">
                                                <span class="material-symbols-outlined">
                                                    cloud_upload
                                                </span>   
                                                Seleccionar Archivo
                                            </button>
                                            <input class="d-none" #fileComproDomicilio placeholder="Placeholder" accept="application/pdf,image/*" (change)="handleFileComproDomic($event)" type="file">
                                        </div>
                                        <div class="col-6 text-center">
                                            <span *ngIf="strAccion_Solicitud==''" class="rounded fs-6  py-2 px-3" [ngClass]="{'bg-warning text-dark':nombreCompDomicilio=='','bg-success text-white':nombreCompDomicilio!=''}">
                                              
                                                <span *ngIf="nombreCompDomicilio==''">Archivo Vacio</span> 
                                                <span *ngIf="nombreCompDomicilio!=''  && strAccion_Solicitud==''"> {{nombreCompDomicilio}}  </span> 
                                            </span>
                                            <a *ngIf="nombreCompDomicilio!='' && strAccion_Solicitud=='EDITAR'" [href]="nombreCompDomicilio" type="button" class="btn btn-success" title="{{nombreCompDomicilio}}" target="_blank"><i class="fa-solid fa-download"></i></a>
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <!--FIN CREDITO--> 
                        </div>

                        <div class="row mb-4">
                            <div class="col-12 d-flex justify-content-between">
                                <button class="btn d-flex align-items-center text-primary fw-bold" (click)="mostrarprincipal()"><i class="bi bi-arrow-left-circle me-2 fs-4"></i>Regresar al formulario</button>  
                                <button *ngIf="strAccion_Solicitud != 'ACTUALIZAR_CREDITO' && strTipoCliente=='CREDITO' && strAccion_Solicitud !='EDITAR'" [disabled]="!isFormBankValid() || !isFormDatosFiscalesValid() ||!isFormContactoValid() || !isFormContactoPagoValid() ||!isFormVendedorValid() || !isFormPreguntasValid() || !isFormReferenciasValid()"  class="btn btn-primary" (click)="uploadFiles('NUEVO_CREDITO')">ENVIAR SOLICITUD DE CREDITO</button>
                               
                                <button *ngIf="strTipoCliente=='CREDITO' && strAccion_Solicitud =='EDITAR' && (objAlta_Cte.strTipoSolicitud == 'ALTA_CREDITO' ||objAlta_Cte.strTipoSolicitud == 'CONTADO A CREDITO' )" [disabled]="!isFormBankValid() || !isFormDatosFiscalesValid() ||!isFormContactoValid() || !isFormContactoPagoValid() ||!isFormVendedorValid() || !isFormPreguntasValid() || !isFormReferenciasValid()"  class="btn btn-primary" (click)="uploadFiles_Editar('EDITAR_S_CREDITO')">EDITAR SOLICITUD DE CREDITO</button>
                               
                                <button *ngIf="strAccion_Solicitud == 'ACTUALIZAR_CREDITO'" [disabled]="!isFormBankValid() || !isFormDatosFiscalesValid() ||!isFormContactoValid() || !isFormContactoPagoValid() ||!isFormVendedorValid() || !isFormPreguntasValid() || !isFormReferenciasValid()" class="btn btn-primary" (click)="uploadFiles2()">ENVIAR SOLICITUD</button>
                                <button *ngIf="strAccion_Solicitud != 'ACTUALIZAR_CREDITO' && strTipoCliente=='CONTADO'" [disabled]="!isFormDatosFiscalesValid()|| !isFormContactoValid() || !isFormContactoPagoValid() ||!isFormVendedorValid()  "  class="btn btn-primary" (click)="uploadFiles('NUEVO_CONTADO')">ENVIAR ALTA CONTADO</button>
                                
                           
                            </div>
                        </div>
                    </div>
            </div>
           <!-- fIN De segundo tab -->
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="VERModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">{{titulo_ver}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <iframe *ngIf="url_ver" id="urlIframe" nombre="urlIframe" scrolling="no" border="none" frameborder="1" [src]="getembenurl(url_ver)" style="width: 100%; height: 600px;">
            </iframe>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
<div class="modal fade" id="correoModal" tabindex="-1" aria-labelledby="correoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="correoModalLabel">Enviar Factura</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <form [formGroup]="facturaForm" (ngSubmit)="fnEnviar_dataFactura()">
                        <div>
                          <label for="nombre">Nombre:</label>
                          <input id="nombre" class="form-control" formControlName="nombre" />
                        </div>
                      
                        <div>
                          <label for="apellidoPaterno">Apellido Paterno:</label>
                          <input id="apellidoPaterno" class="form-control"  formControlName="apellidoPaterno" />
                        </div>
                      
                        <div>
                          <label for="apellidoMaterno">Apellido Materno:</label>
                          <input id="apellidoMaterno"class="form-control"  formControlName="apellidoMaterno" />
                        </div>
                      
                        <div>
                          <label for="email">Email:</label>
                          <input id="email" class="form-control"  formControlName="email" />
                        </div>
                      
                        <div class="row mt-2 mb-2">
                          <label>Envio masivo</label><br>
                          <label>
                            <input type="radio" formControlName="envioMasivo" value="SI" /> SI
                          </label>
                          <label>
                            <input type="radio" formControlName="envioMasivo" value="NO" /> NO
                          </label>
                        </div>
                      
                        <div class="row">
                            
                          <label>Envio CFD</label> <br>
                          <label>
                            <input type="radio" formControlName="envioCFD" value="SI" /> SI
                          </label>
                          <label>
                            <input type="radio" formControlName="envioCFD" value="NO" /> NO
                          </label>
                        </div>
                      
                        <button type="submit"  class="btn btn-primary" [disabled]="!isFormValid">Enviar</button>
                      </form>
                </div>
                <div class="col-6">
                    <table class="table">
                        <thead>
                          <tr>
                          
                            <th scope="col">Nombre</th>
                            <th scope="col">Correo</th>
                            <th scope="col"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let contacto of arrayContactos">
                           
                            <td>{{contacto.strNombre}}</td>
                            <td>{{contacto.streMail}}</td>
                            <td><button type="button" class="btn btn-outline-danger" (click)="fnEliminarCorreo(contacto.intIDCteCto)"><i class="fa-solid fa-trash-can"></i></button></td>
                          </tr>
                        
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
    
        </div>
      </div>
    </div>
  </div>