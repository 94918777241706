
import { AfterViewInit ,OnInit,Component, ViewChild,ElementRef,ChangeDetectorRef, Inject } from '@angular/core';

import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetallevtasvsobjComponent } from '../detallevtasvsobj/detallevtasvsobj.component';
import { FiltrosService } from '../servicios/filtros.service';
import * as moment from 'moment';
import 'moment/locale/pt-br';

export interface PeriodicElement {  
  strCliente: string;
  strNombre: string;
  dblObjetivo: number;
  dblVenta: number;
  dblAvance: number;
}

export interface DetalleVentaElement {
  dblVenta:number;
  dblObjetivo: number;
  dblVenta1: number;
  dblObjetivo1: number;
  dblVenta2: number;
  dblObjetivo2: number;
  strNombre: string;
}

@Component({
  selector: 'app-reportevtaobjcte',
  templateUrl: './reportevtaobjcte.component.html',
  styleUrls: ['./reportevtaobjcte.component.css']
})
export class ReportevtaobjcteComponent implements OnInit {
  displayedColumns: string[] = ['strCliente','strNombre','dblObjetivo','dblVenta','dblDiferencia','dblAvance'];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  public arrayGerentes:any=[];
  public tagName:any;
  public arraySucursales:any=[];
  public arrayListadoVtasVsObjCtes:any=[];
  public sucursalSelected:any={};
  public strGerente:string = '';
  public arrayAccesos:any=[];
  public intIDRolAcceso:number=0;
  public strAgente:string="";
  public selectGte:any=false;
  public selectSuc:any=false;
  public selectAgt:any=false;
  public gteSelected:any='';
  public arrayAgentes:any=[];
  public agenteSelected:any={};
  public arrayDetalle:any=[];
  public arrayDetalleAvanceCte:any=[];
  public arrayDetalleLineaAvanceCte:any=[];
  public spNoHayDatos:boolean=false;
  public spCargando:boolean=false;
  public  isHoveredButton:boolean=false;
  public arrayPeriodo:any;
  public arrayEjercicio:any;
  public periodo: any[] = [
    {value: '1', viewValue: 'Enero'},
    {value: '2', viewValue: 'Febrero'},
    {value: '3', viewValue: 'Marzo'},
    {value: '4', viewValue: 'Abril'},
    {value: '5', viewValue: 'Mayo'},
    {value: '6', viewValue: 'Junio'},
    {value: '7', viewValue: 'Julio'},
    {value: '8', viewValue: 'Agosto'},
    {value: '9', viewValue: 'Septiembre'},
    {value: '10', viewValue: 'Octubre'},
    {value: '11', viewValue: 'Noviembre'},
    {value: '12', viewValue: 'Diciembre'}
  ];

  public ejercicio: any[] = [
    {value: "2020", viewValue: "2020"},
    {value: "2021", viewValue: "2021"},
    {value: "2022", viewValue: "2022"}
  ];
  public strFechaActual:any ='';
  public intPeriodo:number=11;
  public intEjercicio:number=2021;
  public boolGerente:boolean= false;
  public boolSucursal:boolean= false;
  public boolAgente:boolean= false;
  public strSucursal:string='';
  public dateFechaActual:Date= new Date();

  constructor(public dialog: MatDialog,elem:ElementRef,private _peticionesService: PeticionesService,public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router,private _filtroServ :FiltrosService ){ 
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayListadoVtasVsObjCtes=[];
    this.dataSource = new MatTableDataSource(this.arrayListadoVtasVsObjCtes);
    this.intPeriodo = this.dateFechaActual.getMonth() + 1;
    this.intEjercicio =  this.dateFechaActual.getFullYear();
    this.fnGetFiltros();
  }

  ngOnInit(): void {
    //const date = moment();
   // this.strFechaActual = date;      
    //var fecha = this.strFechaActual.format('YYYY.M.D');
    //this.intEjercicio= this.strFechaActual.format('YYYY');
    //this.intPeriodo=this.strFechaActual.format('M');

    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    this.intIDRolAcceso=this.localSt.retrieve('intIDRolAcceso')?this.localSt.retrieve('intIDRolAcceso'):0;
    this.strAgente=this.localSt.retrieve('strAgente')?this.localSt.retrieve('strAgente'):"";
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }else { 
       // this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
       // this.strGerente = this.boolGerente?'':this.localSt.retrieve('strUsuario');
       // this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        //this.strSucursal =this.boolSucursal?'':this.localSt.retrieve('strSucursal');

       // this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
        this.strAgente= this.localSt.retrieve('strAgente');



        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ?this.localSt.retrieve('strSucursal') : ''; //
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strGerente = (!this.boolGerente && this.boolSucursal) ?this.localSt.retrieve('strUsuario') : ''; //
        this.fnGetListadoVtasVsObjCtes();
           
      }
    }
  }

  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      console.log('regresa del filtro')
   
      this.arrayAgentes = nuevoValor;
    }); 

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });
 
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    }); 
  }
  


  fnGetGerentes(){
    this._peticionesService.getApiCompras({
      strAccion: 'getGerentes' 
    }).subscribe(
      result => { 
        this.arrayGerentes=result; 
      }
    );
  }

  fnGetSucursales(gerente:string){ 
    this._peticionesService.getApiCompras({
      strAccion: 'getSucursales',
      strGerente: gerente
    }).subscribe(
      result => {  
        this.arraySucursales=result; 
      }
    );
  }

  fnGetAgentes(sucursal:string){
   
    this._peticionesService.getApiCompras({
      strAccion: 'getAgentes',
      strSucursal: sucursal
    }).subscribe(
    result => {  
      this.arrayAgentes=result;
      this.agenteSelected={strEstatus: this.arrayAgentes[0].strEstatus.toString()};
    
      this.fnGetListadoVtasVsObjCtes();
    });  
  }

  ngAfterViewInit() { 
    this.dataSource.paginator = this.paginator!;
    this.dataSource.paginator._intl.itemsPerPageLabel='Documentos por página';     
    this.dataSource.paginator.pageSize=10;  
    this.dataSource.sort = this.sort!;
  }

  fnGetListadoVtasVsObjCtes(){
 
    this.spCargando=true;
    this.spNoHayDatos=false;
    this._peticionesService.getApiCompras({
      strAccion: 'getAvanceCte',
      strAgente: this.strAgente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio
    }).subscribe(
      result => {  
        this.arrayListadoVtasVsObjCtes=result;  
        if(this.arrayListadoVtasVsObjCtes.length==0 || !this.arrayListadoVtasVsObjCtes || this.arrayListadoVtasVsObjCtes==null || this.arrayListadoVtasVsObjCtes==undefined){
          this.spNoHayDatos=true;
        }
        this.spCargando=false;

        this.arrayListadoVtasVsObjCtes.forEach((element:any) => {
          element.dblDiferencia = element.dblObjetivo - element.dblVenta;
        });

        this.dataSource.data = this.arrayListadoVtasVsObjCtes as PeriodicElement [];
        this.dataSource.paginator = this.paginator!;
        this.dataSource.paginator._intl.itemsPerPageLabel='Documentos por página';         
        this.dataSource.paginator.pageSize=10;      
        this.dataSource.sort = this.sort!;

      }
    );
  }

  fnGetAvanceCte(obj:any){ 
    this._peticionesService.getApiCompras({
      strAccion: 'getDetAvanceCte',
      strAgente: this.strAgente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strCliente: obj.strCliente
    }).subscribe(
      result => {  
        this.arrayDetalleAvanceCte=result; 
      
        this.fnGetLineasAvanceCte(obj); 
      }
    );
  }

  fnGetLineasAvanceCte(obj:any){ 
    this._peticionesService.getApiCompras({
      strAccion: 'getDetAvanceCteL',
      strAgente: this.strAgente,
      intPeriodo: this.intPeriodo,
      intEjercicio: this.intEjercicio,
      strCliente: obj.strCliente
    }).subscribe(
      result => {  
        this.arrayDetalleLineaAvanceCte=result;  
        this.fnDetalleVenta(this.arrayDetalleAvanceCte, this.arrayDetalleLineaAvanceCte, obj.strNombre);
      }
    );
  }

  
  fnDetalleVenta(obj:any, linea:any, cliente:any) {
    
    let array:any=[];
    array.push(obj);
    array.push(linea);
    array.push(cliente);
 
    this.dialog.open(DetallevtasvsobjComponent, {
      data: array
    });
  }
  fnSelects(){
 
  }
}


