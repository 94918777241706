<div class="container  shadow">
    <div class="row">
        <div class="col-lg-12 text-center animated fadeInDown">
            <div class="navy-line m-t-n-xxs"></div>
            <h2>DETALLE DE -
                <SPAN class="text-navy">ARTICULOS</SPAN>
            </h2>
        </div>
    </div>
    <div class="row ibox-content sombra animated fadeInUp">
        <table class="table">
            <thead>
                <tr class="turclaro">
                    <th><i class="fas fa-shopping-bag"></i>&nbsp;DETALLE DE ARTICULO</th>
                </tr>
            </thead>
        </table>


        <div class="col-12 d-flex justify-content-between mt-2" *ngIf='banderaRegistros==false'>

            <div class="col-sm-2 animated fadeInRight" *ngIf='bandera'>
                <button type="button" class="btn btn-info" (click)="VerExistencias()">Existencias</button>
                <!--<i data-toggle="tooltip" data-placement="top" title="Existencias" class="fas fa-dolly-flatbed" style="font-size:18px; color:#00A714" ng-click="VerExistencias()"></i>-->
            </div>

            <div class="col-sm-2 animated fadeInRight"
                *ngIf='bandera && ArrayArticulo[0]?.strTipoOpcion=="SI"'>
                <button type="button" class="btn btn-secondary" (click)="VerOpciones(2)">Opciones</button>
            </div>


            <div class="col-sm-2 animated fadeInRight" *ngIf='bandera'>
                <button type="button" class="btn btn-danger" (click)="VerDetalle()">Reservados</button>
                <!--<i data-toggle="tooltip" data-placement="top" title="Reservados" class="fas fa-edit" style="font-size:18px; color:#CD5C5C" ng-click="VerDetalle()"></i>-->
            </div>
            <div class="col-sm-2 animated fadeInRight"
                *ngIf='bandera && ArrayArticulo[0]?.strTipo=="Lote"'>
                <button type="button" class="btn btn-warning" (click)="VerLotes()">Lotes</button>
                <!--<i data-toggle="tooltip" data-placement="top" title="Lotes" class="fas fa-euro-sign" style="font-size:18px; color:#FFA07A" ng-click="VerLotes()"></i>-->
            </div>
            <div class="col-sm-2 animated fadeInRight" *ngIf='bandera'>
                <button type="button" class="btn btn-success" (click)="VerVenta(1)">Ventas</button>
            </div>
        </div>
        <div class="col-12 ">
            <br *ngIf='banderaRegistros==false'>
            <hr *ngIf='banderaRegistros==false'>
            <div *ngIf='banderaRegistros' class="alert alert-danger text-center animated rubberBand" role="alert"
                >
                <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <strong>Debe ingresar una clave de articulo valida .</strong>
            </div>

            <div class="row d-flex mb-2" [ngClass]="{'col-sm-7': bandera, 'col-12': !bandera}">
                <div class="form-group m-auto col-sm-5"><label>Clave</label>
                    <input type="text" placeholder="Nombre de clave" class="form-control" max="10"
                        [(ngModel)]="spArticulo" (blur)="ValidarArticulo()" (keydown.enter)="ValidarArticulo()" required>
                </div>
            </div>
            <div class="col-sm-5" *ngIf='bandera'>
                <img height="100" width="100" [src]='UrlrepositorioImagenes+spArticulo+".jpg"' class="img-responsive">
            </div>


            <div class="col-12" id="divInfoArt">

                <h3>Datos Generales <span class="fas fa-clipboard"></span> </h3>
                <hr>
                <div class="col-sm-6 animated fadeInRight">
                    <div class="form-group">
                        <label class=" label" style="font-size:11px">Descripción</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.strDescripcion" [disabled]="true" />
                    </div>
                </div>
                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Familia</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.strFamilia" [disabled]="true" />
                    </div>
                </div>

                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Grupo</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.strGrupo" [disabled]="true" />
                    </div>
                </div>

                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Clave Fab</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.strClaveFabricante" ng-disabled="true" />
                    </div>
                </div>

                <div class="col-sm-6 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Linea</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.strLinea" [disabled]="true" />
                    </div>
                </div>

                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Unidad</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.strUnidad" [disabled]="true" />
                    </div>
                </div>


                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Unidad Compra</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.strUnidadCompra" [disabled]="true" />
                    </div>
                </div>

                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">C. SAT</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.strClaveSAT" [disabled]="true" />
                    </div>
                </div>

            </div>
            <div class="col-12 mt-5" id="divInfoArt2">
                <br>
                <br>
                <h3>Lista de Precios de Venta <span class="fas fa-calculator"></span> </h3>
                <hr>
                <div class="col-sm-3 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Precio de Lista</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.dblPrecioLista" [disabled]="true" />
                    </div>
                </div>

                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Precio 2</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.dblPrecio2" [disabled]="true" />
                    </div>
                </div>

                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Precio 3</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.dblPrecio3" [disabled]="true" />
                    </div>
                </div>

                <div class="col-sm-2 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Precio 4</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.dblPrecio4" [disabled]="true" />
                    </div>
                </div>

                <div class="col-sm-3 animated fadeInRight">
                    <div class="form-group">
                        <label class="label" style="font-size:11px">Precio Minimo</label>
                        <input class="form-control" [value]="ArrayArticulo[0]?.dblPrecioMinimo" [disabled]="true" />
                    </div>
                </div>
            </div>
            
            <div class="col-12 mt-3" id="divCostos">

                <div class="row mt-2" id="divTablas">
                    <div class="col-md-6 col-12">
                        <h3>Costos <span class="fas fa-chart-line"></span> </h3>
                        <hr>
                        <div class="form-group ">
                            <label>Opciones</label>
                            <div class="d-flex justify-content-between">
                                <select name="status" id="opcion" class="form-select w-50" [(ngModel)]="spOpcion">
        
                                    <option value="">Ninguna</option>
                                    <option *ngFor="let x of arrayDetalleOpciones" value="{{x.strSubCuenta}}">
                                        {{x.strSubCuentaNombre}}</option>
                                </select>
                                <button type="button" class="btn btn-success m-auto" (click)="ValidarCostos()">Ver Costos</button>
                            </div>
                          
                        </div>
                        <div class="table-responsive">
                            <table class="tblHeadFijo table table-bordered  tooltip-demo mt-4">
                                <thead class="">
                                    <tr class="turquesa sticky-top">
                                        <th class="text-center sticky">Sucursal</th>
                                        <th class="text-center sticky">Ultimo Costo</th>
                                        <th class="text-center sticky">Costo Promedio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let P of arrayCostosArticulo">
                                        <td class="LetraT bordeTable text-center">{{P.strSucursal}} - {{P.strSucursalNombre}}
                                        </td>
                                        <td class="LetraT bordeTable text-right">{{P.dblUltimoCosto | currency}} </td>
                                        <td class="LetraT bordeTable text-right" (ngInit)="incrementarRegistros()">
                                            {{P.dblCostoPromedio | currency}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="LetraT bordeTable text-center stickyFoo" colspan="2">Total: </th>
                                        <th class="LetraT bordeTable text-center stickyFoo">{{arrayCostosArticulo.length}} </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-6 col-12" id="divProv">
                        <h3>Proveedores que venden el articulo <span class="fas fa-glasses"></span> </h3>
                        <hr>
                        <div class="table-responsive">
                            <table class="tblHeadFijo table table-bordered  tooltip-demo ng-scope">
                                <thead class="">
                                    <tr class="turquesa sticky-top">
                                        <th class="text-center sticky">Proveedor</th>
                                        <th class="text-center sticky">Precio de Compra</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let P of ArrayArticuloProv ">
                                        <td class=" LetraT bordeTable text-center">{{P.strProveedor}} -
                                            {{P.strProveedorNombre}}</td>
                                        <td class="LetraT bordeTable text-right" (ngInit)="incrementarRegistros2()">
                                            {{P.dblCosto | currency}} </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="LetraT bordeTable text-center stickyFoo">Total: </th>
                                        <th class="LetraT bordeTable text-center stickyFoo">{{ArrayArticuloProv.length}} </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
              
            </div>
            
        </div>

    </div>
</div>

<!--MODAL DE EXISTENCIAS-->
<div class="modal inmodal fade" id="DetalleExistencias" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex ">
                <h4 class="modal-title textogris">EXISTENCIAS</h4>
                <button type="button" class="close" data-bs-dismiss="modal"><span aria-bs-hidden="true">&times;</span><span
                        class="sr-only">Close</span></button>

            </div>
            <div class="modal-body">
                <div class="ibox-content sombra table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="turquesa tex-center sticky-top">
                                <th class="text-center sticky">SubCuenta</th>
                                <th class="text-center sticky">Almacén</th>
                                <th class="text-center sticky">Existencia</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let P of arrayDetalleExistencias">
                                <td class="LetraT bordeTable text-center">{{P.strSubCuenta}} - {{P.strSubCuentaNombre}}
                                </td>
                                <td class="LetraT bordeTable text-center">{{P.strAlmacen}} - {{P.strAlmacenNombre}}</td>
                                <td class="LetraT bordeTable text-center" (ngInit)="incrementarRegistros3()">
                                    {{P.intCantidad }}</td>
                            </tr>

                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="LetraT bordeTable text-center stickyFoo" colspan="2">Total: </th>
                                <th class="LetraT bordeTable text-center stickyFoo">{{registros5}} </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL DE OPCIONES-->
<div class="modal inmodal fade" id="DetalleOpciones" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header cabecera ">
                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
                        class="sr-only">Close</span></button>
                <h4 class="modal-title textogris">OPCIONES</h4>
            </div>
            <div class="modal-body">
                <div class="ibox-content sombra table-responsive">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="turquesa sticky-top">
                                <th class="text-center sticky">SubCuenta</th>
                                <th class="text-center sticky">Información Adicional</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let P of arrayDetalleOpciones">
                                <td class="LetraT bordeTable text-center">{{P.strSubCuenta}} - {{P.strSubCuentaNombre}}
                                </td>
                                <td class="LetraT bordeTable text-center">{{P.strInformacionAdicional}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL DE RESERVADOS-->
<div class="modal inmodal fade" id="DetalleReservados" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header cabecera ">
                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
                        class="sr-only">Close</span></button>
                <h4 class="modal-title textogris">Reservados</h4>
            </div>
            <div class="modal-body">
                <div class="ibox-content sombra table-responsive">
                    <table class="table">
                        <thead>
                            <tr class="turquesa sticky-top">
                                <th class="text-center sticky">Movimiento</th>
                                <th class="text-center sticky">Almacen</th>
                                <th class="text-center sticky">FechaEmision</th>
                                <th class="text-center sticky">Opción</th>
                                <th class="text-center sticky">Reservada</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let P of arrayDetalleReservados">

                                <td class="LetraT bordeTable text-center">{{P.strMov}} {{P.strMovID}} </td>
                                <td class="LetraT bordeTable text-center">{{P.strAlmacen}} {{P.strAlmacenNombre}} </td>
                                <td class="LetraT bordeTable text-center">{{P.strFechaEmision}}</td>
                                <td class="LetraT bordeTable text-center" (ngInit)="incrementarRegistros4()">
                                    {{P.strSubCuenta}} - {{P.strSubCuentaNombre}} </td>
                                <td class="LetraT bordeTable text-center">{{P.dblCantidadReservada}}</td>
                            </tr>

                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="LetraT bordeTable text-center stickyFoo" colspan="4">Total: </th>
                                <th class="LetraT bordeTable text-center stickyFoo">{{registros3}} </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL DE LOTES-->
<div class="modal inmodal fade" id="DetalleLotes" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header cabecera ">
                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
                        class="sr-only">Close</span></button>
                <h4 class="modal-title textogris">LOTES</h4>
            </div>
            <div class="modal-body">
                <div class="ibox-content sombra table-responsive">
                    <table class="table">
                        <thead>
                            <tr class="turquesa">
                                <th class="text-center sticky">SubCuenta</th>
                                <th class="text-center sticky">Serie Lote</th>
                                <th class="text-center sticky">Propiedades</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let P of arrayDetalleLotes">
                                <td class="LetraT bordeTable text-center">{{P.strSubCuenta}} - {{P.strSubCuentaNombre}}
                                </td>
                                <td class="LetraT bordeTable text-center">{{P.strSerieLote}}</td>
                                <td class="LetraT bordeTable text-center" (ngInit)="incrementarRegistros5()">
                                    {{P.strPropiedades}}</td>
                            </tr>

                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="LetraT bordeTable text-center stickyFoo" colspan="2">Total: </th>
                                <th class="LetraT bordeTable text-center stickyFoo">{{registros4}} </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL DE VENTAS-->
<div class="modal inmodal fade" id="DetalleVentas" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header cabecera ">
                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
                        class="sr-only">Close</span></button>
                <h4 class="modal-title textogris">VENTAS</h4>
            </div>


            <div class="row">
                <br>
                <div class="col-lg-6 animated fadeInRight">
                    <div class="form-group">
                        <label class="col-sm-3 control-label text-right">
                            <h4>Almacen</h4>
                        </label>
                        <div class="col-sm-9">
                            <select class="form-control m-b" [(ngModel)]="SelectAlmacen">
                                <option value="">TODOS</option>
                                <option *ngFor="let lA of listaAlmacen" value="{{lA.strAlmacen}}">
                                    {{lA.strNombreAlmacen}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 animated fadeInRight">
                    <a class="btn btn-info" (click)="VerVenta(2)">Filtrar</a>
                </div>
            </div>


            <div class="modal-body">
                <div class="ibox-content sombra table-responsive">
                    <table class="table">
                        <thead>
                            <tr class="turquesa">
                                <th class="text-center sticky">Ejercicio</th>
                                <th class="text-center sticky">Periodo</th>
                                <th class="text-center sticky">Cantidad</th>
                                <th class="text-center sticky">Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let P of arrayDetalleVentas">
                                <td class="LetraT bordeTable text-center">{{P.strEjercicio}}</td>
                                <td class="LetraT bordeTable text-center">{{P.strPeriodo}}</td>
                                <td class="LetraT bordeTable text-center"
                                    ng-init="$parent.registros9 = $parent.registros9 + P.intCantidad">{{P.intCantidad }}
                                </td>
                                <td class="LetraT bordeTable text-right"
                                    ng-init="$parent.registros10 = $parent.registros10 + P.dblImportePendiente">
                                    ${{P.dblImportePendiente | currency}}</td>
                            </tr>

                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="LetraT bordeTable text-center stickyFoo" colspan="2">Total: </th>
                                <th class="LetraT bordeTable text-center stickyFoo">{{TotalCantidad | currency}} </th>
                                <th class="LetraT bordeTable text-right stickyFoo">{{TotalImporte | currency}} </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>