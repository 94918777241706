import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vacantes',
  templateUrl: './vacantes.component.html',
  styleUrls: ['./vacantes.component.css']
})
export class VacantesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
