import { Component, OnInit,ElementRef, ViewChild} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
 
import { CarroService } from '../servicios/carroservice';
import { PlantillafinService } from '../servicios/plantillafin.service';
import { AlertifyService } from '../alertify.service';
import {MatPaginator,MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

 

export interface vtasNetas{
  strMov:string;
  strFechaEmision:string;
  strCliente:string;
  strClienteNombre:string;
  strAlmacenNombre:string;
  strReferencia:string;
  strOrdenCompra:string;
  dblImporte:string;
  strNombreAgente:string;
  strSucursal:string;
  strDireccionOrdenCompra:string;
  strDireccionEvidencia:string;
  pdf:string;
  xml:string;
}
@Component({
  selector: 'app-reporte-factvs-devoluciones',
  templateUrl: './reporte-factvs-devoluciones.component.html',
  styleUrls: ['./reporte-factvs-devoluciones.component.css']
})
export class ReporteFactvsDevolucionesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public datosVentasNetas:MatTableDataSource<vtasNetas>
  public columnsVtasNetas :string[]=['strSucursal','strCliente','strClienteNombre','strMov','strAlmacenNombre','strFechaEmision','strReferencia','strOrdenCompra','strDireccionOrdenCompra','dblImporte','pdf','xml','strDireccionEvidencia','strNombreAgente'];
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  public strConexion:string="";
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayAccesos:any=[]; 
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public boolTable:boolean=false;
  public tagName:any;
  isHoveredButton: boolean = false;
  public arrayVentasReportes:any=[];
  constructor(private paginatorIntl: MatPaginatorIntl,public _alertify: AlertifyService,private carroservice:CarroService,elem:ElementRef,private localSt:LocalStorageService, private editPl:PlantillafinService,   private router: Router,   private _httpService:PeticionesService, private _filtroServ :FiltrosService) { 
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
      //obtener niveles de acceso
      this.tagName=elem.nativeElement.tagName.toLowerCase();
      this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
      
      if(this.arrayAccesos.length>0){
        var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
        
        if(objAcceso==undefined||objAcceso.length==0){
          this.router.navigate(['/login']);
        }
        else{ 

          this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
          this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
          this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
          this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
          this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
          this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
    
        }
      }
      this.datosVentasNetas = new MatTableDataSource(this.arrayVentasReportes);
      this.customizeItemsPerPageLabel();
  }

  ngOnInit(): void {
    this.fnGetFiltros();
    this.fnGetFacturas();
  }
  ngAfterViewInit() {
   
  }
  fnGetFacturas(){ 
    this.arrayVentasReportes = []
    //this.spiner= true;
    this.boolTable =true
    let obj = {
       strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""),
       intEjercicio: this.intEjercicio,
       intPeriodo:this.intPeriodo ,
       strSucursal:this.strSucursal,
       strConexion:this.strConexion,
       strGerente:this.strGerente,
       strAccion:'getReporte_Devoluciones_Facturas',
    };
    if(this.strAgente!="" && this.strAgente != null){
      obj.strAgente= this.strAgente 
      obj.strSucursal=""
      obj.strGerente=""
    }else if(this.strSucursal!=""&& this.strSucursal != null){
      obj.strAgente= ""
      obj.strSucursal=this.strSucursal
      obj.strGerente=""
    }else if(this.strGerente!=""&& this.strGerente != null){
      obj.strAgente= ""
      obj.strSucursal=""
      obj.strGerente=this.strGerente
    }
    console.log(obj)
    this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Reportes').subscribe(
      result => {
        console.log(result)
        this.arrayVentasReportes = result;
       // this.totalImporte = this.arrayVentasReportes.map(t => t.dblImporte).reduce((acc, value) => acc + value, 0);
        this.datosVentasNetas = new MatTableDataSource(this.arrayVentasReportes);
        this.fninitTabla();
        
        this.boolTable = false;
      },error => {
        this.boolTable = false; 
        console.log(error.message)
      });
  }
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'items por página';
  }
  fninitTabla(){
    this.paginator.pageSize = 25;
    this.datosVentasNetas.paginator = this.paginator;
    this.datosVentasNetas.sort = this.sort;
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  //    console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosVentasNetas.filter = filterValue.trim().toLowerCase();
    if (this.datosVentasNetas.paginator) {
      this.datosVentasNetas.paginator.firstPage();
    }
  }
  
} 
 