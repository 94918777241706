<div class="container"> 
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold titulo text-decoration-underline">Reporte Score Card</h1>
        </div> 
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetData()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <!--fin de filtros-->
    <div class="row my-4">
        <div class="row" *ngIf="ListaModulos==undefined || ListaModulos=='' || ListaModulos==[]">
            <div class="col-sm-12 text-center">
                <div class="bo">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </div>
        <div class="mb30" *ngFor='let M of ListaModulos'>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead class="">
                        <tr>
                            <th class="text-center fondo_tablas" colspan="14">
                                <strong style="font-weight: 500;">{{M}}</strong>
                            </th>
                        </tr>
                        <tr class="grisUnidad">
                            <th class="font-bold" style="width:12%;font-weight: 500;">Concepto</th>
                            <th class="font-bold" style="width:8%;font-weight: 500;">Objetivo Ppto</th>
                            <!--   <th class="font-bold" style="width:4%">Objetivo Ppto</th>-->
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Ene</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Feb</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Mar</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Abr</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">May</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Jun</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Jul</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Ago</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Sep</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Oct</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Nov</th>
                            <th class="text-center font-bold" style="width:7%;font-weight: 500;">Dic</th>
                            <!--<th class="text-center font-bold" style="width:7%;font-weight: 500;">Totales</th>-->
                        </tr>
                    </thead>
                    <tbody style="background:#FFF">
                        <tr *ngFor='let C of arrayResultado | filter : M'>
                            <td class="bordeTable LetraT text-left font-bold fondo_fijas_gris">
                                <span *ngIf="C.strConcepto!='Días Cartera'">{{C.strConcepto}}</span>
                                <a *ngIf="C.strConcepto =='Días Cartera' " target="_blank" ng-Click="fnDCartera()">{{C.strConcepto}}</a>
                            </td>

                            <td class="bordeTable LetraT text-center font-bold fondo_fijas_gris2">
                                <!--<span>{{C.strParametro | number}}</span>-->
                                <label *ngIf="C.strTipoDato =='$'">{{C.strParametro |currency }}</label>
                                <label *ngIf="C.strTipoDato =='%'">{{C.strParametro | number }}%</label>
                                <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.strParametro | number }}</label>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleEnero}}">
                                <div *ngIf="C.dblEnero!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblEnero |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblEnero | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblEnero | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleEnero=='TextoRojo', 'bi bi-arrow-up':C.strStyleEnero=='TextoVerde'} "></i>
                                    <!--<label>{{C.dblEnero | number }}</label>-->
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleFebrero}}">
                                <div *ngIf="C.dblFebrero!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblFebrero |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblFebrero | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblFebrero | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleFebrero=='TextoRojo', 'bi bi-arrow-up':C.strStyleFebrero=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleMarzo}}">
                                <div *ngIf="C.dblMarzo!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblMarzo |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblMarzo | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblMarzo | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleMarzo=='TextoRojo', 'bi bi-arrow-up':C.strStyleMarzo=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleAbril}}">
                                <div *ngIf="C.dblAbril!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblAbril |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblAbril | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblAbril | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleAbril=='TextoRojo', 'bi bi-arrow-up':C.strStyleAbril=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleMayo}}">
                                <div *ngIf="C.dblMayo!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblMayo |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblMayo | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblMayo | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleMayo=='TextoRojo', 'bi bi-arrow-up':C.strStyleMayo=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleJunio}}">
                                <div *ngIf="C.dblJunio!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblJunio |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblJunio | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblJunio | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleJunio=='TextoRojo', 'bi bi-arrow-up':C.strStyleJunio=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleJulio}}">
                                <div *ngIf="C.dblJulio!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblJulio |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblJulio | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblJulio | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleJulio=='TextoRojo', 'bi bi-arrow-up':C.strStyleJulio=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleAgosto}}">
                                <div *ngIf="C.dblAgosto!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblAgosto |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblAgosto | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblAgosto | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleAgosto=='TextoRojo', 'bi bi-arrow-up':C.strStyleAgosto=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleSeptiembre}}">
                                <div *ngIf="C.dblSeptiembre!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblSeptiembre |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblSeptiembre | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblSeptiembre | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleSeptiembre=='TextoRojo', 'bi bi-arrow-up':C.strStyleSeptiembre=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleOctubre}}">
                                <div *ngIf="C.dblOctubre!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblOctubre |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblOctubre | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblOctubre | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleOctubre=='TextoRojo', 'bi bi-arrow-up':C.strStyleOctubre=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleNoviembre}}">
                                <div *ngIf="C.dblNoviembre!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblNoviembre |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblNoviembre | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblNoviembre | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleNoviembre=='TextoRojo', 'bi bi-arrow-up':C.strStyleNoviembre=='TextoVerde'} "></i>
                                </div>
                            </td>
                            <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleDiciembre}}">
                                <div *ngIf="C.dblDiciembre!=0 ">
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblDiciembre |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblDiciembre | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblDiciembre | number }}</label>
                                    <i [ngClass]="{'bi bi-arrow-down': C.strStyleDiciembre=='TextoRojo', 'bi bi-arrow-up':C.strStyleDiciembre=='TextoVerde'} "></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>