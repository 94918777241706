<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="my-3 fw-bold text-decoration-underline text-center">
               Solicitud <span class="tittle">Familias</span> 
            </h1>
        </div>
    </div>

    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-3 d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-3  d-flex justify-content-center" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-3 d-flex justify-content-center" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
      
        <div class="col-12 mt-2 pt-1  col-md-3 d-flex justify-content-center align-items-center"  >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fngetSolicitudes()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>

    <div class="row my-4 justify-content-center">
        <div class="row bgtable bordertop">
            <div class="col-6 col-md-4 my-2">
                <input type="text" class="form-control" style="height:43px ;" [(ngModel)]="inputSearch" placeholder="Buscar:Nombre Empresa">
            </div>
          <!--   <div class="col-6 col-md-4 my-2">
                <mat-form-field appearance="outline" class="bg-white rounded ">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Estatus</mat-label>
                    <mat-select [(ngModel)]="strEstatus" class="">
                    <mat-option *ngFor="let x of arrayEstatus" [value]="x.value">{{x.strEstatus}}</mat-option> 
                    </mat-select>
                </mat-form-field>
            </div> -->
        </div>
        <div class="col-12 bg-white" *ngIf="spiner">
            <mat-spinner class="mx-auto"></mat-spinner>
        </div>
        <div class="col-12 table-responsive mx-0 px-0 mb-0 border"  *ngIf="!spiner">
            <table class="table table-bordered  bg-white table-striped">
                <thead class="" >
                    <tr class="thcenter  sticky-top">
                        <th class="bgtable">
                            <span>ID</span>
                        </th>
                        <th class="bgtable">
                            <span>Nombre Comercial</span>
                        </th>
                        <th class="bgtable">
                            <span>RFC</span>
                        </th>
                        <th class="bgtable">
                            <span>Razon social</span>
                        </th>
                        <th class="bgtable">
                            <span>Agente</span>
                        </th>
                        <th class="bgtable">
                            <span>Sucursal</span>
                        </th>
                        <th class="bgtable">
                            <span>
                                Autorizar
                            </span>
                        </th>
                        <th class="bgtable">Detalle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tdcenter" *ngFor="let cot of arraySolPendientes | filtersearch: inputSearch : ['strNombreEmpresa']| filtersearch: strEstatus : ['strEstatus'] let ind = index;" [class.table-hover]="!cot.mostrarDetalle" >
                        <td    >
                            <span class="fs12 fw-bold">
                                {{cot.intIdLeads}}
                            </span>
                        </td>
                        <td class="text-start ">
                            {{cot.strNombreEmpresa}}
                           
                        </td>
                        <td>
                            <span class="text-nowrap fs12">
                                {{cot.strRFC}}
                            </span>
                        </td>
                        <td>
                            <span class="fs12 fw-bold">
                                {{cot.strRazon_Social}}
                            </span>
                        </td>
                        <td>
                            (<span class="fs12 fw-bold">
                                {{cot.strAgente}}
                            </span>)
                            <span>
                                {{cot.strNombreAgente}}
                            </span>
                        </td>
                        <td class="text-center">
                            (<span class="fs12 fw-bold">{{cot.strSucursal}}</span>)
                            <span class="fs12 "> - {{cot.strNombreSucursal}}</span>
                        </td>
                        <td class="align-middle "> <!-- (mouseenter)="cot.isHovered = true" (mouseleave)="cot.isHovered = false" [ngClass]="{'bi-trash3': !cot.isHovered, 'bi-trash3-fill': cot.isHovered}"-->
                            <div class="col-11" *ngIf="boolGerente || boolSucursal">
                                <div class="row justify-content-center">
                                    <div class="col-11 col-lg-5 mb-lg-0 mb-3 text-center">
                                        <button (click)="fnUpdateSol(cot,'2')" class="btn btn-success fs12 py-0 px-2 m-0 " matTooltip="AUTORIZAR"><i class="bi bi-check-circle"></i> </button>
                                    </div>
                                    <div class="col-12 col-lg-5 text-center">
                                        <button (click)="fnUpdateSol(cot,'3')" class="btn btn-danger fs12 m-0 py-0 px-2" matTooltip="RECHAZAR"><i class="bi bi-x-circle"></i></button>
                                    </div>
                                </div>
                            </div>
<!--                             <span class="btn btn-outline-primary fs12 p-1 shadow-sm text-nowrap"  data-bs-toggle="modal" data-bs-target="#exampleModal">  <i class="bi  bi-pencil-square  fs12 "  ></i> Editar</span> -->
                        </td>
                        <!-- <td class=""  (mouseenter)="cot.isHovered = true" (mouseleave)="cot.isHovered = false">
                            <i class="bi   text-danger fs-5 mouseHov align-middle"   [ngClass]="{'bi-trash3': !cot.isHovered, 'bi-trash3-fill': cot.isHovered}"></i>
                        </td> -->
                        <td (click)="fnDetalleFam(cot)">
                            <button class=" btn btn-secondary  d-flex justify-content-center align-items-center rounded fw-bold py-0 px-2 mx-auto">...</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
  
    </div>


</div>

  
