import { Component, OnInit , AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { ComodatoService } from '../servicios/comodato.service';
import { CurrencyPipe } from '@angular/common';
import { PeticionesService } from '../servicios/httpservice';
import { CarroService } from '../servicios/carroservice';
import {LocalStorageService} from 'ngx-webstorage';
import * as moment from 'moment'; // Importa Moment
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import {MatPaginator,MatPaginatorIntl} from '@angular/material/paginator'
import { Router } from '@angular/router';
@Component({
  selector: 'app-carro-comodatos',
  templateUrl: './carro-comodatos.component.html',
  styleUrls: ['./carro-comodatos.component.css']
}) 
export class CarroComodatosComponent implements  AfterViewInit  {
  public strGerente:string;
  public arraySucursalesCte:any =[];
  public arrayInstalacion:any=[{strRespuesta:'SI'},{strRespuesta:'NO'}];
  public strInstalacion:string= this.arrayInstalacion[0].strRespuesta;
  public preguntasCompletas:number=0;
  public arraySolicitud:any=[{strRespuesta:'NUEVO'},{strRespuesta:'REPOSICIÓN'}];
  public strTipoSolicitud:string= this.arraySolicitud[0].strRespuesta;
  public arrayNiveles:any=[{strNivel:'Piso 1'},{strNivel:'Piso 2'},{strNivel:'Piso 3'},{strNivel:'Piso 4'},{strNivel:'Piso 5'},{strNivel:'Piso 6'},{strNivel:'Piso 7'},{strNivel:'Piso 8'},{strNivel:'Piso 9'},{strNivel:'Piso 10'},{strNivel:'Piso 11'},{strNivel:'Piso 12'},{strNivel:'Piso 13'},{strNivel:'Piso 14'},{strNivel:'Piso 15'}
  ,{strNivel:'Piso 16'},{strNivel:'Piso 17'},{strNivel:'Piso 18'},{strNivel:'Piso 19'},{strNivel:'Piso 20'},{strNivel:'Piso 21'},{strNivel:'Piso 22'},{strNivel:'Piso 23'},{strNivel:'Piso 24'},{strNivel:'Piso 25'},{strNivel:'Piso 26'}
  ]
  public arrayTipoArea:any=[{strArea:'Recepción'},{strArea:'Sala de Espera'},{strArea:'Sala de Juntas'},{strArea:'Zona Operativa'},{strArea:'Oficina'},
  {strArea:'Baño Privado'},{strArea:'Baño Público'},{strArea:'Comedor'},{strArea:'Pasillo'},{strArea:'Escaleras'},{strArea:'Lobby'},{strArea:'Accesos'}]
  car:any={
    strTipoOpcion:"Si",
    strArticulo:"",
    strNombreOpcion:"",
    dblPrecioLista:10,
    dblCantidad:20,  
  }; 
  dblSubTotal:number=0
  public arrayTipoComodato:any = [{strRespuesta:'Renta'},{strRespuesta:'Venta'}]
  public strRTipoComodato:string=this.arrayTipoComodato[0].strRespuesta;
  public arrayDiasActividad:any=[{intDia:1},{intDia:2},{intDia:3},{intDia:4},{intDia:5},{intDia:6},{intDia:7}];
  public arrayAfluencia=[{strAfluencia:'ALTA'},{strAfluencia:'MEDIA'},{strAfluencia:'BAJA'}]
  public dblRRentaMensual:number;
  public dblRCantidadMensual:number=1;
  public dblRPrecioConsumible:number=0;
  public dteHrInicioAct:string =  moment().set({hour: 8, minute: 0}).format('HH:mm');
  public dteHrFinAct:string =  moment().set({hour:18, minute: 0}).format('HH:mm');
  public intDiasAct:number=this.arrayDiasActividad[5].intDia;
  public strDespachador_titulo:string;
  public arrayUbicacionesDesp:any;
  public strMOVID :any={};
  public dteHrInicioAct_2:string = moment().set({hour: 8, minute: 0}).format('HH:mm');
  public dteHrFinAct_2:string =  moment().set({hour:14, minute: 0}).format('HH:mm');
  public intDiasAct_2:number=this.arrayDiasActividad[0].intDia;
  public fileContr:boolean=false;
  public textFile:string="Seleccione Documento";
  public fileRenov:boolean=false;
  public textRenov:string="Seleccione Documento";
  public arrayComodatosM : any;
  public strBuscar:string="";
  public strAgente:string="";
  public arrayLineas:any=[];
  public arrayAgente:any=[];
  public arrayArtsporlinea:any=[];
  public objLinea:any;
  public strlinea:string="";
  public cteSelect:boolean=false;
  public lststrDivisional:boolean=false;
  public lstSucursal:boolean=false;
  public lstVendedor:boolean=false;
  public lstEstatus:boolean=false;
  public strDivisional:string="";
  public strSucursal:string="";
  public strVendedor:string="";
  public strEstatus:string="";
  public bloques: any[][] = [];
  public strConexion:string="";
  public arrayClientes:any;
  public objCliente:any = { strCliente:"", strNombre:"", strDireccion:"", ClienteSeleccionado:false, strSucursal:"", NombreSucursal:"", tipoSucursal:""};
  public strNumCte:string;
  public strNombreCliente:string="";
  public strNombreSucursal:string="";
  public elementosPorPagina:number=0;
  public intSubtotal:number = 0 ;
  public intIva:number=0;
  public intTotal:number=0;
  public arrayExistentes:any;
  public objDespachadorSeleccionado:any;
  public muestraarraytipo:number;
  public rutaDirectorio:string = 'adjuntoComodatos/';
  public strNombreCartSolicitud:string = "";
  public strNombreCartRenovacion:string = "";
  public arrayCarritocomodato:any=[
    {strArticulo:'1793507',strOpcion:'B5',strOpcionNombre:'BLANCO',opcion:'Si',dblPrecio:150,dblPrecioSugerido:90,precioDesc:100,intCantidad:3,totalPorProducto:300,strImagen:'https://www.cleanclean.mx/imagenes/1793507.jpg',strDescripcion1:'DIFUSOR DE AROMA CON 3 BATERIAS 100 M2'},
    {strArticulo:'1793507',strOpcion:'',strOpcionNombre:'',opcion:'No',dblPrecio:150,dblPrecioSugerido:90,precioDesc:100,intCantidad:3,totalPorProducto:300,strImagen:'https://www.cleanclean.mx/imagenes/1793507.jpg',strDescripcion1:'DESPACHADOR DE AROMA MICROBURST 3000'},
    {strArticulo:'1793507',strOpcion:'B6',strOpcionNombre:'BLANCO',opcion:'Si',dblPrecio:150,dblPrecioSugerido:90,precioDesc:100,intCantidad:3,totalPorProducto:300,strImagen:'https://www.cleanclean.mx/imagenes/1793507.jpg',strDescripcion1:'DESPACHADOR DE AROMA MICROBURST 3000'},
    {strArticulo:'1793507',strOpcion:'',strOpcionNombre:'',opcion:'No',dblPrecio:150,dblPrecioSugerido:90,precioDesc:100,intCantidad:3,totalPorProducto:300,strImagen:'https://www.cleanclean.mx/imagenes/1793507.jpg',strDescripcion1:'DESPACHADOR DE AROMA MICROBURST 3000'},
    {strArticulo:'AROMIA-X100',strOpcion:'B7',strOpcionNombre:'BLANCO',opcion:'Si',dblPrecio:150,dblPrecioSugerido:90,precioDesc:100,intCantidad:3,totalPorProducto:300,strImagen:'https://www.cleanclean.mx/imagenes/1793507.jpg',strDescripcion1:'DESPACHADOR DE AROMA MICROBURST 3000'},
    {strArticulo:'1793507',strOpcion:'',strOpcionNombre:'',opcion:'No',dblPrecio:150,dblPrecioSugerido:90,precioDesc:100,intCantidad:3,totalPorProducto:300,strImagen:'https://www.cleanclean.mx/imagenes/1793507.jpg',strDescripcion1:'DESPACHADOR DE AROMA MICROBURST 3000'},
    {strArticulo:'1793507',strOpcion:'B8',strOpcionNombre:'BLANCO',opcion:'Si',dblPrecio:150,dblPrecioSugerido:90,precioDesc:100,intCantidad:3,totalPorProducto:300,strImagen:'https://www.cleanclean.mx/imagenes/1793507.jpg',strDescripcion1:'DESPACHADOR DE AROMA MICROBURST 3000'},
    {strArticulo:'AROMIA-100',strOpcion:'',strOpcionNombre:' ',opcion:'No',dblPrecio:150,dblPrecioSugerido:90,precioDesc:100,intCantidad:3,totalPorProducto:300,strImagen:'https://www.cleanclean.mx/imagenes/1793507.jpg',strDescripcion1:'DESPACHADOR DE AROMA MICROBURST 3000'},
  ];
  public objArt:any = {
    strArticulo:'', 
    strDescripcion1:'', 
    dblCantidad:1, 
    strOpcion:'', 
    strNombreOpcion:'', 
    dblPrecio:0
  };
  public totalProductos:number=0;
  public spinerCarrito:boolean;
  public arrayCarritoArts: any[];
  public tipoCliente:string="";

  public strFechaInstalacion:string=  moment().format('YYYY-MM-DD HH:mm');
  public strCteDirige:string="";
  public strObservaciones:string="";
  public strTelContacto:string="";

 public selectedFile:File;
 public selectedRenovFile:File;
 public arrayReposiciones:any=[];
 public arrayAccesos:any=[];
public tagName:string;
  @ViewChild('inpContrato') inputContrato!: ElementRef;
  @ViewChild('inpRenovacion') inputRenovacion!: ElementRef;
  @ViewChild('btnCerrarModal2') btnCloseModal!: ElementRef;
  
  public boolAgente:boolean= false;
  public boolSucursal:boolean= false;
  public boolGerente:boolean= false;
  public strUsuarioInt: string = "";
  public strUsuario: string = "";
  public strCorreoUser: string = "";
 // public strSucursal: any;
  public strSucursalCte: string ="";
  public strSucursal_cot: any;
  public fechaMinima: string;
  public strAgenteCot: string = "";
 // public strGerente:boolean= false;
 @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedItems: any[] = []; // Los elementos que se muestran en la página actual
  pageSize = 5; // Tamaño inicial de página
  currentPage = 0;

  constructor(private paginatorIntl: MatPaginatorIntl,private carroservice: CarroService, private router: Router, elem:ElementRef,private _httpService:PeticionesService,private http:HttpClient, 
    private currencyPipe: CurrencyPipe,public commodatoservice:ComodatoService,private localSt:LocalStorageService) { 
    this.strConexion = this.localSt.retrieve('strConexion');
    console.log(this.localSt.retrieve('strusuario'));
     //obtener niveles de acceso
     this.tagName=elem.nativeElement.tagName.toLowerCase();
     this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
     this.strConexion = this.localSt.retrieve('strConexion'); 
     if(this.arrayAccesos.length>0){
       var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);

       if(objAcceso==undefined||objAcceso.length==0){
         this.router.navigate(['/login']);
       } else {
         this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
         this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
         this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
         this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
         this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
         this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
        
         this.strUsuario = this.arrayAccesos[0].strNombre + ' ' + this.arrayAccesos[0].strApellido;
         this.strUsuarioInt = this.localSt.retrieve('strusuario'); // se usa para guardar en el usuario de los movimientos
         this.strAgenteCot = this.localSt.retrieve('stragente') ? this.localSt.retrieve('stragente') : '';//se usa para guardar el agente de los movimientos
         this.strSucursal_cot= this.localSt.retrieve('strsucursal'); //se usa para la sucursal de la cotizacion
         this.strCorreoUser = this.localSt.retrieve('strEmail');
       
       }
       }

       const hoy = new Date();
       hoy.setDate(hoy.getDate() + 1);
     const mes = (hoy.getMonth() + 1).toString().padStart(2, '0'); // Agregar cero si el mes es menor a 10
     const dia = hoy.getDate().toString().padStart(2, '0'); 
     this.fechaMinima = `${hoy.getFullYear()}-${mes}-${dia}`;

    
     this.paginatorIntl.itemsPerPageLabel = 'Registros por página';

  }

  ngAfterViewInit(): void {
        this.commodatoservice.ObjCliente_C$.subscribe((nuevoValor) => {
          this.objCliente = nuevoValor;
          this.strNumCte=nuevoValor.strCliente;
 
         });
         if(this.strNumCte !=''){
          this.fnGetSucursal_CTe();
         }
   
        this.tipoCliente = this.objCliente.strTipo?this.objCliente.strTipo :"";

        this.commodatoservice.dblSubTotalItems_C$.subscribe((nuevoValor) => {
          this.dblSubTotal = nuevoValor;
        });
        const currencyValue = this.currencyPipe.transform(123.45, 'USD', 'symbol', '1.2-2');
        // Suscribir al array del carrito
      this.commodatoservice.arrayItems_C$.subscribe((nuevoValor) => {
        this.arrayCarritoArts = nuevoValor;
       
      });

  
      this.commodatoservice.intTotalItems_C$.subscribe((nuevoValor) => {
        this.totalProductos = nuevoValor;
      });
      console.log(this.fileContr)
      //suscribir al tipo del comodato
      this.commodatoservice.objTipoComodato_C$.subscribe((nuevoValor) => {
          this.strTipoSolicitud = nuevoValor.strTipoSolicitud;
          this.strInstalacion= nuevoValor.strInstalacion;
          this.strMOVID=  nuevoValor.strTipoSolicitud=='REPOSICIÓN'? nuevoValor.objMOVID:{};
             // this. fnArtspasarCant();
          this.fileRenov = this.strTipoSolicitud ==  'NUEVO'?true:false;
          console.log(this.fileContr)
       });



       this.paginator.page.subscribe(() => {
        this.currentPage = this.paginator.pageIndex;
        this.pageSize = this.paginator.pageSize;
        this.updateDisplayedItems();
      });
  }
  updateDisplayedItems() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayedItems = this.arrayUbicacionesDesp.slice(startIndex, endIndex);
  }

  toggleAccordion(item: any) {
    item.isOpen = !item.isOpen;
  }
  fnllenarInputs(cte:any, sucursal:any, opcion:number) {
  
        this.strSucursalCte = '' 
        
        this.objCliente.strDireccion_cliente = !sucursal ? cte.strDireccion:this.objCliente.strDireccion_cliente;
        this.objCliente.strDireccion =  sucursal && sucursal.intIDSucursal ? sucursal.strDireccion :"" /* this.objCliente.strDireccion_cliente */
    
        this.objCliente.strNombreSucursal = sucursal && sucursal.intIDSucursal ? sucursal.strNombreSucursal :'';
        this.objCliente.intIDSucursal = sucursal && sucursal.intIDSucursal ?sucursal.intIDSucursal:null;
    
        this.objCliente.strAgente_cliente = !sucursal ? cte.strAgente:this.objCliente.strAgente_cliente;
        this.objCliente.strAgente = sucursal && sucursal.intIDSucursal? sucursal.strAgente: this.objCliente.strAgente_cliente ;
    
        this.objCliente.strAlmacen_cliente = !sucursal ? cte.strAlmacen:this.objCliente.strAlmacen_cliente;
        this.objCliente.strAlmacen = sucursal && sucursal.intIDSucursal ?sucursal.strAlmacen:  this.objCliente.strAlmacen_cliente;
    
        this.objCliente.strSucursal_cliente = !sucursal ? cte.strSucursal:this.objCliente.strSucursal_cliente;
        this.objCliente.strSucursal = sucursal && sucursal.intIDSucursal?sucursal.strSucursal :this.objCliente.strSucursal_cliente;
    
    
        this.objCliente.strNombre  = cte.strNombre
        this.objCliente.strCliente = cte.strCliente;
        this.objCliente.strClaveUsoCFDI = cte.strClaveUsoCFDI;
        this.objCliente.strCondicion = cte.strCondicion;
        this.objCliente.strDescripcionUsoCFDI = cte.strDescripcionUsoCFDI;
        this.objCliente.strInfopago = cte.strInfopago;
        this.objCliente.strInfopagoDescripcion = cte.strInfopagoDescripcion;
    
        
        
        this.objCliente.ClienteSeleccionado = true;
    
        this.objCliente.strTipo = cte.strTipo;
        this.objCliente.getSucursales = opcion ==2?false:true;
       
        this.commodatoservice.setCte(this.objCliente); 
          if(opcion!=2){
            this.fnGetSucursal_CTe()
          }
      }
  fnArtspasarCant(){
    let cantArts = 0 
    this.arrayCarritoArts.forEach(o => {
      cantArts = cantArts + o.dblCantidad;
      o.intCantidad = o.dblCantidad
      o.dblPrecio =  o.dblPrecioLista
      o.SubTotal=this.fnSubTotal();
      o.totalPorProducto=  o.intCantidad * o.dblPrecio
    });
    this.arrayCarritoArts.forEach(o => {
      o.cantidadTotal = cantArts;
    });
   
  }
  fnSubTotal():number{
    let result= 0;
    result =this.dblSubTotal;
      return result;
  }
  fnmodal(objArt:any,ind:number){
    /* this.fnRelacionados(objArt); */
   /* this.objArtModal.strArticulo = objArt.strArticulo;
    this.objArtModal.strDescripcion=objArt.strDescripcion1;
    this.objArtModal.indice=ind;*/
  }
  onOpcionSeleccionada(opcion:string){
     this.commodatoservice.updateArt();
   }
   fnbuttomGroup(objArt:any, tipo:string){
    if( tipo == '+' ) {
       objArt.dblCantidad=objArt.dblCantidad + 1;
       objArt.intCantidad=objArt.dblCantidad;
       this.fnUpdateAmountCar(objArt)
       this.fnArtspasarCant()
    }else{
      if(objArt.dblCantidad >=2){
        objArt.dblCantidad=objArt.dblCantidad - 1;
        objArt.intCantidad=objArt.dblCantidad;
        this.fnUpdateAmountCar(objArt)
        this.fnArtspasarCant()
      } 
    }
  } 
  fnUpdateAmountCar(objArt:any){
    this.commodatoservice.deleteArt( objArt);
  }
  fnDeleteCar(intId:number, objArt:any){
    this.arrayCarritoArts.splice(intId, 1);
    this.commodatoservice.deleteArt( objArt);
    this.fnArtspasarCant()
  }
  fnCambiaConsumible(objArt:any, objA:any){
   
    if ( objArt &&  objArt.strTipoOpcion=='Si')
    {
      this.fnGetOpcionConsumible(objArt.strConsumible,objA)
    }
    else {
      this.fnUpdateAmountCar(objArt);
    }
  }
  fnGetOpcionConsumible(articulo:string, objA:any) {
    objA.arrayOpcionConsumible=[];
    this._httpService.getLineasComodatos({
      strAccion:'GetArticuloOpciones',
      strConexion:this.strConexion,
      strArticulo:articulo
    },"sp_ModVentas_Comodato").subscribe(
      result => {
        let arrayC=result;
        arrayC.forEach(element => {
          objA.arrayOpcionConsumible.push({strOpcion:element.strOpcion, strNombreOpcion:element.strNombreOpcion });
         });
         objA.objOpcionConsumible=objA.arrayOpcionConsumible.length>0? objA.arrayOpcionConsumible[0]:{};
         this.fnUpdateAmountCar(objA);
     
      });
  } 
 
  compareObjects(o1: any, o2: any) {
    if(o1 && o2 && o1.strConsumible && o2.strConsumible &&  o1.strConsumible == o2.strConsumible ){
    return true;}
    else{ return false}
  }
  compareObjects2(o1: any, o2: any) {
    if(o1.strOpcion == o2.strOpcion )
    return true;
    else return false
  }

  // modal cuestionario
  fnAbreModalCuestionario(cantidad:number, despachador:string, objDespachador:any){
   this.muestraarraytipo =  objDespachador.strGrupo.includes('AROMA') ?1:0;
   
    //inicializar todas las variables
    
    this.objDespachadorSeleccionado=objDespachador;
    this.preguntasCompletas =this.objDespachadorSeleccionado.preguntasCompletas?this.objDespachadorSeleccionado.preguntasCompletas:0;
    this.strRTipoComodato=this.objDespachadorSeleccionado.strRTipoComodato?this.objDespachadorSeleccionado.strRTipoComodato:'Venta';
    this.arrayUbicacionesDesp=[];
    this.strDespachador_titulo=despachador;
    this.dblRRentaMensual=this.objDespachadorSeleccionado.dblRRentaMensual?this.objDespachadorSeleccionado.dblRRentaMensual:0;
    this.dblRCantidadMensual=this.objDespachadorSeleccionado.dblRCantidadMensual?this.objDespachadorSeleccionado.dblRCantidadMensual:1;
    this.dblRPrecioConsumible=this.objDespachadorSeleccionado.dblRPrecioConsumible?this.objDespachadorSeleccionado.dblRPrecioConsumible:0;

    this.dteHrInicioAct = this.objDespachadorSeleccionado.dteHrInicioAct?this.objDespachadorSeleccionado.dteHrInicioAct: moment().set({hour: 8, minute: 0}).format('HH:mm');
    this.dteHrFinAct = this.objDespachadorSeleccionado.dteHrFinAct?this.objDespachadorSeleccionado.dteHrFinAct:  moment().set({hour:18, minute: 0}).format('HH:mm');
    this.intDiasAct=this.objDespachadorSeleccionado.intDiasAct?this.objDespachadorSeleccionado.intDiasAct:this.arrayDiasActividad[5].intDia;  
  
    this.dteHrInicioAct_2 =this.objDespachadorSeleccionado.dteHrInicioAct_2?this.objDespachadorSeleccionado.dteHrInicioAct_2: moment().set({hour: 8, minute: 0}).format('HH:mm');

    this.dteHrFinAct_2 =  this.objDespachadorSeleccionado.dteHrFinAct_2?this.objDespachadorSeleccionado.dteHrFinAct_2:moment().set({hour:14, minute: 0}).format('HH:mm');

    this.intDiasAct_2=this.objDespachadorSeleccionado.intDiasAct_2?this.objDespachadorSeleccionado.intDiasAct_2:this.arrayDiasActividad[0].intDia;
    if (!objDespachador.arrayUbicacionesDesp ){  //crearArray de despachadores
        for(let i=0; i<cantidad;i++){
          this.arrayUbicacionesDesp[i]={id:i+1, despachador:despachador,edificio:'',nivel:'Piso 1', nombreArea:'', tipoArea:'Recepción', afluencia:'MEDIA', intRepetirEdificio:1,
          intRepetirNivel:1 , intRepetirArea:1,intRepetirTipoArea:1,intRepetirAfluencia:1, checkCompleto:0,
          dteHrInicioAct:this.dteHrInicioAct, dteHrFinAct: this.dteHrFinAct, intDiasAct:this.intDiasAct, dteHrInicioAct_2:this.dteHrInicioAct_2, dteHrFinAct_2 : this.dteHrFinAct_2,
          intDiasAct_2 : this.intDiasAct_2
          }
         }
    }
    else {
      this.arrayUbicacionesDesp=objDespachador.arrayUbicacionesDesp;
    }
    this.updateDisplayedItems()
  }
  fnRepetirEdificio(edificio:string, cantidadEdificio:number, id:number){
    let inicio=id-1;  
    let fin =id+cantidadEdificio-1; 
    for(let i=inicio; i<fin;i++){
      this.arrayUbicacionesDesp[i].edificio=edificio;
      this.fnValidarCompleto(this.arrayUbicacionesDesp[i].id);
    }
    
  }
  fnRepetirNivel(nivel:string, cantidadNivel:number, id:number){
    let inicio=id-1;  
    let fin =id+cantidadNivel-1; 
    for(let i=inicio; i<fin;i++){
      this.arrayUbicacionesDesp[i].nivel=nivel;
      this.fnValidarCompleto(this.arrayUbicacionesDesp[i].id);
    }
    
  }
  fnRepetirNombreArea  (nombreArea:string, cantidadArea:number, id:number){
    let inicio=id-1;  
    let fin =id+cantidadArea-1; 
    for(let i=inicio; i<fin;i++){
      this.arrayUbicacionesDesp[i].nombreArea=nombreArea;
      this.fnValidarCompleto(this.arrayUbicacionesDesp[i].id);
    }
    
  }
  fnRepetirTipoArea  (nombreArea:string, cantidadArea:number, id:number){
    let inicio=id-1;  
    let fin =id+cantidadArea-1; 
    for(let i=inicio; i<fin;i++){
      this.arrayUbicacionesDesp[i].tipoArea=nombreArea;
      this.fnValidarCompleto(this.arrayUbicacionesDesp[i].id);
    }
    
  }
  fnRepetirAfluencia (nombreArea:string, cantidadArea:number, id:number){
    let inicio=id-1;  
    let fin =id+cantidadArea-1; 
    for(let i=inicio; i<fin;i++){
      this.arrayUbicacionesDesp[i].afluencia=nombreArea;
      this.fnValidarCompleto(this.arrayUbicacionesDesp[i].id);
    }
    
  }
  fnValidarCompleto(id:number){
      let desp= this.arrayUbicacionesDesp.find(o=>o.id==id);
      desp.checkCompleto = (desp.edificio !='' && desp.nivel !='' && desp.nombreArea !='' && desp.tipoArea !='' && desp.afluencia !='')?1:0;
    this.fnValidarCompleto_2();
 
  }
  fnValidarCompleto_2 (){
         //validar que todos los despachadores tengan respondidas las preguntas
         let contar=0;
         this.arrayUbicacionesDesp.forEach(element => {
           if(element.checkCompleto==1){
             contar=contar + 1;
           }
         }); 
         this.preguntasCompletas = (contar ==this.arrayUbicacionesDesp.length)? 1:0;
         let validarTipo =  (this.strRTipoComodato =='Venta' && this.dblRPrecioConsumible>0)?1:(this.strRTipoComodato =='Renta' && this.dblRRentaMensual>0)?1:0;
         this.objDespachadorSeleccionado.preguntasCompletas=(this.preguntasCompletas==1 && validarTipo ==1)? 1:0;
  }
  fnGuardarPreguntas(){
    this.fnValidarCompleto_2();
    this.objDespachadorSeleccionado.arrayUbicacionesDesp=this.arrayUbicacionesDesp;
    this.objDespachadorSeleccionado.strRTipoComodato = this.strRTipoComodato;
    this.objDespachadorSeleccionado.dblRRentaMensual =this.dblRRentaMensual;
    this.objDespachadorSeleccionado.dblRCantidadMensual =this.dblRCantidadMensual;
    this.objDespachadorSeleccionado.dblRPrecioConsumible =this.dblRPrecioConsumible;
    this.objDespachadorSeleccionado.dteHrInicioAct=this.dteHrInicioAct;
    this.objDespachadorSeleccionado.dteHrFinAct=this.dteHrFinAct;
    this.objDespachadorSeleccionado.intDiasAct=this.intDiasAct;
    this.objDespachadorSeleccionado.dteHrInicioAct_2=this.dteHrInicioAct_2 ;
    this.objDespachadorSeleccionado.dteHrFinAct_2=this.dteHrFinAct_2;
    this.objDespachadorSeleccionado.intDiasAct_2=this.intDiasAct_2;
    
    
   // this.objDespachadorSeleccionado.preguntasCompletas=(this.preguntasCompletas==1 && validarTipo ==1)? 1:0;

    //actualizamos el servicio del carrito con la nueva información
    this.fnUpdateAmountCar(this.objDespachadorSeleccionado)
    //confirmamos
    Swal.fire({
      title: "Cambios Guardados",
      text: "Se Guardaron los Cambios Exitosamente",
      icon: "success",
      timer: 2000, 
      showConfirmButton: false,
      timerProgressBar: true,});
      this.fncerrarModal();
  }

  fncerrarModal(){
   
    this.btnCloseModal.nativeElement.click();
  }
  
  fnSubiva():number{
    let result= 0;
    if(this.strConexion == "DOMINICANA"){
      result = this.dblSubTotal * .18;
    }else{
      result = this.dblSubTotal * .16;
    }

    return result;  
  }
  fnTotal():number{
    let result= 0;
    result= this.fnSubTotal() + this.fnSubiva();
    return result;
  }

  fnFormarRespuestas(){
    //validar que haya un cliente seleccionado
    if( this.objCliente.strCliente  && this.objCliente.strCliente !=''){
        //validar que todos los despachadores tengan sus preguntas respondidas
        
        let total=0;
        this.arrayCarritoArts.forEach(element=>{
          total=total + element.preguntasCompletas
        })
        if(total == this.arrayCarritoArts.length){
           //validar que las urls de los documentos no estén vacios
           let validaDoctos = (this.strTipoSolicitud =='REPOSICIÓN' && this.strNombreCartSolicitud !='' && this.strNombreCartRenovacion!='')? 1 :
           (this.strTipoSolicitud =='NUEVO'&& this.strNombreCartSolicitud !='')? 1:0;
           console.log(validaDoctos);
          
        
            let arrayRespuestas=[];
            for(let i=0; i<this.arrayCarritoArts.length; i++){
              let element = this.arrayCarritoArts[i];
              
              var intIDArticulo= 1
              let objValida = arrayRespuestas.find(o=> o.strArticulo== element.strArticulo)
              if(objValida){
                //filtramos el array
                let arrayObjValida = arrayRespuestas.filter(o=> o.strArticulo== element.strArticulo)
                // Encontrar el objeto con el intIDArticulo más alto
                const objConMayorID = arrayObjValida.reduce((max, obj) => (obj.intIDArticulo > max.intIDArticulo ? obj : max), arrayObjValida[0]);
                 intIDArticulo= objConMayorID.intIDArticulo  + 1
              } 
              
              let objR={intIDPregunta:3,strArticulo:element.strArticulo,strRespuesta:element.dblRRentaMensual,intIDArticulo:intIDArticulo, pregunta:'Costo de Renta Mensual'};// 3.-Costo de Renta Mensual por Despachador
              arrayRespuestas.push(objR)
              objR={intIDPregunta:17,strArticulo:element.strArticulo,strRespuesta:element.dblRRentaMensual,intIDArticulo:intIDArticulo, pregunta:'Costo de Renta Mensual'};// 17.-Costo de Renta Mensual por Despachador
              arrayRespuestas.push(objR)
              //----------------------------------------------------------------------------------------------------------
              objR={intIDPregunta:6,strArticulo:element.strArticulo,strRespuesta:element.objConsumible.strConsumible,intIDArticulo:intIDArticulo, pregunta:' Articulo (Consumible)'};// 6  .- Articulo (Consumible)  8 11
              arrayRespuestas.push(objR)
              //----------------------------------------------------------------------------------------------------------
              objR={intIDPregunta:7,strArticulo:element.strArticulo,strRespuesta:element.dblRPrecioConsumible,intIDArticulo:intIDArticulo, pregunta:'Precio (Consumible)'};// 7 .- Precio (Consumible) 8 
              arrayRespuestas.push(objR)
              //----------------------------------------------------------------------------------------------------------
              objR={intIDPregunta:8,strArticulo:element.strArticulo,strRespuesta:element.dblRCantidadMensual,intIDArticulo:intIDArticulo, pregunta:'Cantidad (Consumible)'};// 8 .- Cantidad (Consumible)
              arrayRespuestas.push(objR)
              //----------------------------------------------------------------------------------------------------------
              let opCon =(element.objOpcionConsumible && element.objOpcionConsumible.strOpcion )? element.objOpcionConsumible.strOpcion:'';
              objR={intIDPregunta:11,strArticulo:element.strArticulo,strRespuesta:opCon ,intIDArticulo:intIDArticulo, pregunta:'Opcion (Consumible)'};//11.- Opcion (Consumible)
              arrayRespuestas.push(objR);

              element.arrayUbicacionesDesp.forEach(element2 => {
                let objR3={intIDPregunta:13,strArticulo:element.strArticulo,strRespuesta:element2.edificio,intIDArticulo:intIDArticulo,pregunta:'edificio'};//13.- edificio
                arrayRespuestas.push(objR3);
                //--------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:14,strArticulo:element.strArticulo,strRespuesta:element2.nivel,intIDArticulo:intIDArticulo,pregunta:'nivel'};//14.- nivel
                arrayRespuestas.push(objR3);
                //---------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:16,strArticulo:element.strArticulo,strRespuesta:element2.nombreArea,intIDArticulo:intIDArticulo, pregunta:'area'};//16.- area
                arrayRespuestas.push(objR3);
                //-------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:18,strArticulo:element.strArticulo,strRespuesta:element2.tipoArea,intIDArticulo:intIDArticulo, pregunta:'tipo area'};//18.- tipo area
                arrayRespuestas.push(objR3);
                //-------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:19,strArticulo:element.strArticulo,strRespuesta:element2.dteHrInicioAct,intIDArticulo:intIDArticulo, pregunta:'Hora Inicio Actividad'};//19.- Hora Inicio Actividad
                arrayRespuestas.push(objR3);
                //-------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:20,strArticulo:element.strArticulo,strRespuesta:element2.dteHrFinAct,intIDArticulo:intIDArticulo, pregunta:'Hora Fin Actividad'};//20	Hora Fin Actividad
                arrayRespuestas.push(objR3);
                //-------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:21,strArticulo:element.strArticulo,strRespuesta:element2.intDiasAct,intIDArticulo:intIDArticulo, pregunta:'Dias de Actividad'};//21	Dias de Actividad
                arrayRespuestas.push(objR3);
                //-------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:22,strArticulo:element.strArticulo,strRespuesta:element2.dteHrInicioAct_2,intIDArticulo:intIDArticulo, pregunta:'Hora Inicio Actividad FS'};//22.- Hora Inicio Actividad FS
                
                //-------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:23,strArticulo:element.strArticulo,strRespuesta:element2.dteHrFinAct_2,intIDArticulo:intIDArticulo, pregunta:'Hora Fin Actividad FS'};//23	Dias de Actividad FS
          
                //-------------------------------------------------------------------------------------------------------
                objR3={intIDPregunta:24,strArticulo:element.strArticulo,strRespuesta:element2.intDiasAct,intIDArticulo:intIDArticulo, pregunta:'Dias de Actividad FS'};//24 Dias de Actividad FS
                arrayRespuestas.push(objR3);
              });

            }
            //console.log(arrayRespuestas)
            
             this.fnEnviarSolicitud(arrayRespuestas);
          }
          else {
            Swal.fire({
              title: "Preguntas Incompletas",
              text: "Debe Completar Todas Las Preguntas",
              icon: "warning",
              timer: 2000, 
              showConfirmButton: false,
              timerProgressBar: true,});
        
          }
      }
      else {
          Swal.fire({
            title: "Información de Cliente",
            text: "Falta Seleccionar Cliente",
            icon: "error",
            timer: 2000, 
            showConfirmButton: false,
            timerProgressBar: true,});
       
        }
  }
  fnGetComodatos(tipo) {
    console.log('entra a get comodatos')
     //actualizar el tipo de solicitud en comodato
     let inst =tipo.strInstalacion ?tipo.strInstalacion :'SI';
     let tipoSol =tipo.strTipoSolicitud?tipo.strTipoSolicitud:'NUEVO';
     let objTipoS={strTipoSolicitud:tipoSol, strInstalacion:inst, objMOVID :{}}
     this.commodatoservice.setTipoComodato(objTipoS);

    if(tipo.strTipoSolicitud =='REPOSICIÓN'){
      this.fnGetListComodato();
    }
    

  }
  fnGetListComodato(){
    let idSuc = (this.objCliente.intIDSucursal && this.objCliente.intIDSucursal) >0 ? this.objCliente.intIDSucursal:'';
    this._httpService.getLineasComodatos({
      strAccion:'getComodatoCte',
      strConexion:this.strConexion,
      strCliente:this.objCliente.strCliente ,
      strCteEnviarA: idSuc
    },"sp_ModVentas_Comodato").subscribe(
      result => {
        console.log(result)
        this.arrayComodatosM=result;     
      });
  }
  fnGetComodatosDetalle(objMOVID:any){
    
    if(objMOVID.strMovID && objMOVID.strMovID  !=''){
      Swal.fire({
        title: "Se Obtendrá el detalle del comodato seleccionado",
   
        showCancelButton: true,
        confirmButtonText: "Aceptar" 
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log('obtener detalle de comodato')
          this.fnGetComodatosDetalle_2(objMOVID);
        } else if (result.isDenied) {
          console.log('se cancelo' )
        }
      });
     
    }
    else {
      Swal.fire({
        title: "Movimiento Incorrecto",
        text: "Elige un Comodato Válido",
        icon: "warning",
        timer: 2000, 
        showConfirmButton: false,
        timerProgressBar: true,});
    }

  }
  fnGetComodatosDetalle_2(objMOVID:any)
  { 
    //actualizar el tipo de solicitud en comodato
    /*let objTipoS={strTipoSolicitud:'REPOSICIÓN', objMOVID :objMOVID}
    this.commodatoservice.setTipoComodato(objTipoS);*/

    this._httpService.getLineasComodatos({
      strAccion:'getComodatoCteDet',
      strConexion:this.strConexion,
      intID:objMOVID.intID 
    },"sp_ModVentas_Comodato").subscribe(
      result => {
        let arrayDesp=result;
        
        //borrar carrito
        this.commodatoservice.deleteOnlyCarrito();
        //2.- agregar todos los articulos al carrito
         
        arrayDesp.forEach(element => {
          let tipoOp= element.strOpcion && element.strOpcion !='' ?'Si':'No'; 
          let arrayOpciones = [{strOpcion:element.strOpcion , strNombreOpcion:element.strNombreOpcion}];

          let objDep ={strArticulo: element.strArticulo, strTipoOpcion:tipoOp, strOpcion:element.strOpcion , strNombreOpcion:element.strNombreOpcion, 
            strDescripcion1: element.strNombreDespachador, dblPrecioLista:element.dblPrecio
            ,dblCantidad:element.dblCantidadDespachador, arrayOpciones:arrayOpciones,
            objConsumible:{strTipoOpcion:'No',strNombreConsumible:element.strNombreConsumible, strConsumible:element.strConsumible},
            arrayConsumible:[{strTipoOpcion:'No',strNombreConsumible:element.strNombreConsumible, strConsumible:element.strConsumible}]
            , strGrupo:element.strGrupo
            }
          
          this.commodatoservice.setArticulo(objDep);
        });
        
       
      });
  }

  compareWithFn(option: any, selection: any): boolean {
    return option && selection ? option.strMovid === selection.strMovid : option === selection;
  }


  cliclkInputFile(){
    this.strNombreCartSolicitud =""
    this.fileContr = false;
    this.textFile = "Seleccione Documento";
    this.inputContrato.nativeElement.value = null;
    this.selectedFile = null;
    this.inputContrato.nativeElement.click();
  }

  cliclkInputFileRenov(){
    this.strNombreCartRenovacion = ""
    this.fileRenov = false;
    this.textRenov = "Seleccione Documento";
    this.inputRenovacion.nativeElement.value = null;
    this.selectedRenovFile = null;
    this.inputRenovacion.nativeElement.click();
  }


  onFileRenov(event: any) {
    const selectedFiles: any = event.target.files;
    if(selectedFiles.length > 0){
      this.selectedRenovFile = selectedFiles[0];

      const formData = new FormData();
      formData.append("archivo", this.selectedRenovFile);
      formData.append('rutaDirectorio',this.rutaDirectorio);
      this._httpService.setAnexMov(formData).subscribe( resultados => {  
        console.log(resultados);
        if(resultados.mensaje=="200"){
          this.fileRenov = true;
          this.textRenov = 'Documento enviado';
          this. strNombreCartRenovacion = this.rutaDirectorio + this.selectedRenovFile.name
        }else{
          this.fileRenov = false;
          this.textRenov = 'Seleccione Documento';
        }
      
      }
      ,
        (error) => {
          this.fileRenov = false;
          console.error('Error al enviar el archivo:', error);
        });  

    } else {
      this.fileRenov = false;
      console.log('No se ha seleccionado ningún archivo.');
    }
  }

  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      this.selectedFile = selectedFiles[0];

      const formData = new FormData();
      formData.append("archivo", this.selectedFile);
      formData.append('rutaDirectorio',this.rutaDirectorio);
      console.log(this.rutaDirectorio + this.selectedFile.name)
      this._httpService.setAnexMov(formData).subscribe( resultados => {  
        console.log(resultados);
        if(resultados.mensaje=="200"){
          this.fileContr = true;
          this.textFile = 'Documento enviado';
          this.strNombreCartSolicitud = this.rutaDirectorio + this.selectedFile.name
        }else{
          this.fileContr = false;
          this.textFile = 'Seleccione Documento';
        }
    
      }
      ,
        (error) => {
          this.fileContr = false;
          console.error('Error al enviar el archivo:', error);
        });  

    } else {
      this.fileContr = false;
      console.log('No se ha seleccionado ningún archivo.');
    }
  }

  fnEnviarSolicitud(arrayRespuestas:any){
    //formar el array articulo
    let arrayArti=[]
    this.arrayCarritoArts.forEach(elment=>{
      arrayArti.push({strArticulo:elment.strArticulo,strOpcion:elment.strOpcion ,dblPrecio: elment.dblPrecioLista, dblCantidad:elment.dblCantidad})
    })
  
    let almacen = this.objCliente.strAlmacen;
    let agente =  this.objCliente.strAgente;
    let sucursal = this.objCliente.strSucursal;
    let fecha =  moment(this.strFechaInstalacion).format('YYYY-MM-DD HH:mm');

    let objEnviar={strAccion:'setSolicitudComodato'
      ,strConexion:this.strConexion, xmlRespuestas:arrayRespuestas, strCliente:this.objCliente.strCliente, 
       strObservaciones : this.strObservaciones,
      strCteEnviarA: this.objCliente.intIDSucursal,
      strUsuario: this.localSt.retrieve('strusuario'), strSucursal:sucursal, strAgente:agente, strAlmacen:almacen, strFechaInstalacion: fecha,
      strDirigiraCon:this.strCteDirige, strRequiereInstalacion:this.strInstalacion, 
      strTipoSolicitud:this.strTipoSolicitud, strAdjuntoSolicitud :this.strNombreCartSolicitud, strAdjuntoResposicion:this.strNombreCartRenovacion,
      xmlArticulos:arrayArti

    }
    console.log(objEnviar)
  this._httpService.getLineasComodatos(objEnviar,"sp_ModVentas_Comodato").subscribe(
      result => {
        console.log(result);
        let code=result[0].code;
        let msj =result[0].strMensaje;
       
        if(code==200){
          Swal.fire({
            title: "Solicitud Enviada",
            text: "Solicitud enviada correctamente",
            icon: "success"
          });
          this.fnIniciar();
          // vaciamos carrito vivi
          //this.carroservice.setCte({});
          this.commodatoservice.deleteCarrito();
        }
        else {
          Swal.fire({
            title: "¡Error! al enviar Solicitud",
            text: msj,
            icon: "error"
          });
        }
      
      }); 
      
   // 
  }

  fnIniciar(){
    this.strCteDirige = "";
    this.strFechaInstalacion = null;
     this.fileContr=false;
     this.textFile="Seleccione Documento";
     this.fileRenov=false;
     this.textRenov="Seleccione Documento";
     this.strObservaciones = ""
     this.strNombreCartRenovacion = ""
     this.strNombreCartSolicitud =""
  }

  fnBorrarCarrito(){
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Se eliminaran todos los productos del carrito de comodatos.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText:"Cancelar",
      confirmButtonText: "Si, Borrar!"
    }).then((result) => {
      if (result.isConfirmed) {
          this.commodatoservice.deleteCarrito();
  
        Swal.fire({
          title: "",
          text: "se eliminaron correctamente.",
          icon: "success"
        });
      }
    });
  }

  fnCambiaTipoCom(){
    this.dblRRentaMensual=0;
    this.dblRPrecioConsumible=0;
   // this.fnGuardarPreguntas();
  }

  fnvalText():boolean{
    let result:boolean= false;
    if (this.strFechaInstalacion && this.strCteDirige != "") {
      const fechaHoy = new Date();
      fechaHoy.setHours(0, 0, 0, 0); // Establecer la hora a medianoche
      // Parsear la fecha de instalación si es una cadena en un formato específico
      const fechaInstalacion = new Date(this.strFechaInstalacion);
      fechaInstalacion.setDate(fechaInstalacion.getDate() + 1);
      fechaInstalacion.setHours(0, 0, 0, 0); // Establecer la hora a medianoche
      // Comparar las fechas

      
      if (fechaInstalacion.getTime() > fechaHoy.getTime()) {
        result = true; // La fecha de instalación es posterior a la fecha de hoy
      }
    }
    return result; 
  }

  onFechaInstalacionBlur() {
    if (!this.strFechaInstalacion) {
      this.strFechaInstalacion = null;
    }
  }
  fnCambiaHrInicioGlobal(dteHrInicioAct:any){
    this.arrayUbicacionesDesp.forEach(element => {
      element.dteHrInicioAct=dteHrInicioAct;
    });
  }
  fnCambiaHrFinGlobal(dteHrFinAct:any){
    this.arrayUbicacionesDesp.forEach(element => {
      element.dteHrFinAct=dteHrFinAct;
    });
  }
  fnCambiaHrInicioGlobal_2(dteHrInicioAct:any){
    this.arrayUbicacionesDesp.forEach(element => {
      element.dteHrInicioAct_2=dteHrInicioAct;
    });
  }
  fnCambiaHrFinGlobal_2(dteHrFinAct:any){
    this.arrayUbicacionesDesp.forEach(element => {
      element.dteHrFinAct_2=dteHrFinAct;
    });
  }
  fnCambiaDias(intDiasAct:number){
    this.arrayUbicacionesDesp.forEach(element => {
      element.intDiasAct=intDiasAct;
    });
  }
  fnCambiaDias_2(intDiasAct:number){
    this.arrayUbicacionesDesp.forEach(element => {
      element.intDiasAct_2=intDiasAct;
    });
  }
  fnGetSucursal_CTe()
{
  this.arraySucursalesCte = [];
  let cliente = {
    strAccion: "getCte_Sucursal",
    strCliente: this.strNumCte,
    strGerente: this.strGerente,
    strSucursal: this.strSucursal,
    strAgente: this.strAgente,
    strConexion: this.strConexion
  };
  console.log(cliente)
  this._httpService.getCotizador(cliente).subscribe(
    result => {
      this.arraySucursalesCte = result   
      console.log( this.arraySucursalesCte);
    });
}

  }


