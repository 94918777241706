import { Component, Directive, HostListener, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CarroService } from '../servicios/carroservice';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from '../alertify.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PlantillafinService } from "../servicios/plantillafin.service"

@Component({
  selector: 'app-cotizadornew',
  templateUrl: './cotizadornew.component.html',
  styleUrls: ['./cotizadornew.component.css']
})

export class CotizadornewComponent implements OnInit {
  @Directive({
    selector: '[appFocusListener]'
  })
  options: string[] = ['Angular', 'React', 'Vue'];
  objectoptions = [{ name: 'Angular' }, { name: 'React' }, { name: 'Vue' }, { name: 'Angular Material ' }];
  /*************paginador****** */
  public items: number = 16;
  public arrayPaginas: any[] = [];
  public currentPage: number = 1;
  public arrayExistentes: any = [];
  public strGrupoSelect: any = ''
  public arrayArts_all: any = [];
  /************Carrito***************/
  public intTotalItems: number;
  /********************/
  card1: any = [
    { title: 'Info de cliente', cols: 4, rows: 2, id: 1 },
    { title: 'Categorias', cols: 4, rows: 3, id: 8 }, //se agrego para el carrusel de categorias
    { title: 'Filtros', cols: 1, rows: 8, id: 5 },
    /* { title: 'buscador', cols: 3, rows: 1, id: 6 }, */
    { title: 'Resultados', cols: 3, rows: 7, id: 4 }

  ]
  card2: any = [
    { title: 'Info de cliente', cols: 4, rows: 3, id: 1 },
    { title: 'Categorias', cols: 4, rows: 3, id: 8 }, //se agrego para el carrusel de categorias
    { title: 'Filtros', cols: 4, rows: 3, id: 3 },
    { title: 'Resultados', cols: 4, rows: 7, id: 4 }
  ]

  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return this.card2;
      }
      return this.card1;
    })
  );
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public cantProduct: number = 0;
  public strCheckMarca: string;
  public dispositivo: number;
  public arrayFilter: any[] = [];
  public strNombreCte: string;
  public strCategoSeleccionada: any = ''
  public strDireccionCte: string;
  public arrayCategoria: any;
  public arrayMarca: any;
  public strNumCte: string/* ="62" */;
  public strBuscarArticulo: string/*  = "BN" */;
  public arrayClientes: any;
  public arrayBusquedaArticulo: any;
  public arrayArticulos: any;
  public optionHid: string = "";
  public strConexion: string = "";
  public grupos: Record<string, any[]> = {};
  public arrayGrupos: any = []
  public objArt: any = {
    strArticulo: '',
    strDescripcion1: '',
    dblCantidad: 1,
    strOpcion: '',
    strNombreOpcion: '',
    dblPrecio: 0
  };
  public arrayPreciosArt: any = [
    {
      intPrecioLista: 0,
      intPrecio: 0,
      intPrecio2: 0,
      intPrecio3: 0,
      intPrecio4: 0
    }
  ];
  public Accesop4: any;
  public gruposUnicos = new Set<string>();
  public intPrecioLista: number = 0;
  public intPrecio: number = 0;
  public intPrecio2: number = 0;
  public intPrecio3: number = 0;
  public intPrecio4: number = 0;
  public intPrecioPlantilla: number = 0;
  public intPrecioPolitica: number = 0;
  public imagenUrl1: string;
  public imagenUrl2: string;
  public imagenUrl3: string;
  public imagenUrl4: string;
  public imagenUrl5: string;
  public isButtonFocused = false;
  public artOpc: boolean = true;
  public spinerCarrito: boolean = false
  public objCliente: any = {
    strCliente: "",
    strNombre: "",
    strDireccion: "",
    ClienteSeleccionado: false,
    strSucursal: "",
    NombreSucursal: "",
    tipoSucursal: ""
  };
  public arrayAccesosMenu: any = [];
  public arrayAccesos: any;
  public arrayCarritoArts: any = [];
  private offsetX = 0;
  private offsetY = 0;
  private isDragging = false;
  public ArrayNum: number = 0;
  public dblSubTotal = 0;
  public tagName: any;

  //Lineas
  public bloques: any[][] = [];
  public objLinea: any;
  public strlinea: string = "";
  public arrayLineas: any = [];
  public arrayArtsporlinea: any = [];
  public elementosPorPagina: number = 0;

  constructor(private servPlantilla: PlantillafinService, private router: Router, private renderer: Renderer2, public _alertify: AlertifyService,
    private el: ElementRef, private breakpointObserver: BreakpointObserver,
    private carroservice: CarroService, private localSt: LocalStorageService,
    private _httpService: PeticionesService, private http: HttpClient, private paginatorIntl: MatPaginatorIntl) {
    this.arrayClientes = [];
    console.log('entra a constructor de cotizador')
    this.paginatorIntl.itemsPerPageLabel = 'Elementos por página:';
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    console.log('entra a constructor de cotizador 2')
    this.arrayAccesosMenu = this.localSt.retrieve('arrayAccesosMenu')?this.localSt.retrieve('arrayAccesosMenu'):[];
    this.strConexion = this.localSt.retrieve('strConexion')?this.localSt.retrieve('strConexion'):'COMERCIAL';
    console.log('entra a constructor de cotizador 3')
    this.tagName = el.nativeElement.tagName.toLowerCase();
    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.Accesop4 = this.arrayAccesos.some((x) => x.strAccion.trim() === "ListadoP4");
        this._httpService.setMostrarMenu(true);
      }
    }
    else{
      this._httpService.setMostrarMenu(true);
      console.log('REDIRECCIONAR A LOGIN')
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(event: Event): void {
    this.renderer.setStyle(this.el.nativeElement.nextElementSibling, 'display', 'block');
  }

  @HostListener('blur', ['$event'])
  onBlur(event: Event): void {
    this.renderer.setStyle(this.el.nativeElement.nextElementSibling, 'display', 'none');
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isAnchoMayorQue1400px();
    this.detectarTamanioPantalla();
    // Manejar el evento de cambio de tamaño de la ventana aquí
    console.log('La ventana ha cambiado de tamaño');
    this.fnpaginarcarousel();
  }

  ngOnInit(): void {
   /*  this.carroservice.intTotalItems$.subscribe((nuevoValor) => {
      this.intTotalItems = nuevoValor;
    });
    this.carroservice.arrayItems$.subscribe((nuevoValor) => {
      this.arrayCarritoArts = nuevoValor;
    });
    this.carroservice.dblSubTotalItems$.subscribe((nuevoValor) => {
      this.dblSubTotal = nuevoValor;
    });
    this.carroservice.ObjCliente$.subscribe((nuevoValor) => {
      this.objCliente = nuevoValor;
   console.log(this.objCliente )
      if (nuevoValor.ClienteSeleccionado) {
        this.strNumCte = nuevoValor.strCliente;
      }
    });
    //console.log(this.objCliente)
    this.card1[0].rows = this.objCliente.strTipo == 'SUCURSAL' ? 3 : 2;
    this.arrayFilter = [];
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        //hide stuff
        this.dispositivo = 1;
        this.fnCalcular(1);
      } else {
        // show stuff
        this.dispositivo = 2;
        this.fnCalcular(2);
      }
    });
    this.detectarTamanioPantalla();
    this.fnGetCategorias(); */
  }

  onKeyDown(event: KeyboardEvent): void {

   // this.carroservice.deleteCarrito();
    this.strBuscarArticulo = '';
    this.funArticulos(this.strBuscarArticulo);
    if (event.key === 'Delete' || event.code === 'Delete' || event.key === 'Backspace' || event.code === 'Backspace') {
    }
  }

  private detectarTamanioPantalla() {
    this.elementosPorPagina = 0;
    const anchoPantalla = window.innerWidth;
    if (anchoPantalla < 400) {
      this.elementosPorPagina = 2;
    } else if (anchoPantalla >= 400 && anchoPantalla < 600) {
      this.elementosPorPagina = 3;
    } else if (anchoPantalla >= 600 && anchoPantalla < 800) {
      this.elementosPorPagina = 4;
    }
    else if (anchoPantalla >= 800 && anchoPantalla < 1000) {
      this.elementosPorPagina = 5;
    }
    else if (anchoPantalla >= 1000) {
      this.elementosPorPagina = 6;
    }
  }
  /* 'col-3':elementosPorPagina == 3,'col-5'elementosPorPagina == 2  */
  redirectToCarrito() {
    this.router.navigate(['/carritoresumen']);
  }

  isAnchoMayorQue1400px(): boolean {
    return window.innerWidth > 1450;
  }

  fnPagin(pageIndex: number, previousPageIndex: number) {
    if (previousPageIndex < pageIndex) {
      this.fnPaginator(0, 'next');
    } else {
      this.fnPaginator(0, 'prev');
    }
  }

  fnAbrirModal(objArt: any) {

    if (objArt.strTipoOpcion != "No") {
      this.objArt.arrayOpciones = [];
      this.fnExtraerOpciones(objArt);
    } else {
      objArt.strNombreOpcion = '';
      objArt.strOpcion = '';
    }
    let url = 'https://www.cleanclean.mx/imagenes/'
    /*  console.log('abre modal'); */
    this.imagenUrl1 = url + objArt.strArticulo + '.JPG';
    this.imagenUrl2 = url + objArt.strArticulo + '-PO-2.JPG';
    this.imagenUrl3 = url + objArt.strArticulo + '-PO-3.JPG';
    this.imagenUrl4 = url + objArt.strArticulo + '-PO-4.JPG';
    this.imagenUrl5 = url + objArt.strArticulo + '-PO-5.JPG';
    objArt.dblCantidad = 1; //inicializamos la cantidad
    this.objArt = objArt;
    this.fnArtSinLp(objArt);
    this.fntraerExistencias(objArt);
  }

  onOpcionSeleccionada(opcion: string, objArt: any) {
    this.objArt.arrayOpciones.forEach(o => {
      if (opcion == o.strOpcion) {
        this.objArt.strNombreOpcion = o.strNombreOpcion
      }
    });
    this.fntraerExistencias(objArt);
  }

  fnExtraerOpciones(objArt: any) {
    let articulo = {
      strAccion: 'getOpcion',
      strArticuloBusqueda: objArt.strArticulo,
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {
        this.objArt.arrayOpciones = result;
        this.objArt.strNombreOpcion = this.objArt.arrayOpciones[0].strNombreOpcion ? this.objArt.arrayOpciones[0].strNombreOpcion : '';
        this.objArt.strOpcion = this.objArt.arrayOpciones[0].strOpcion ? this.objArt.arrayOpciones[0].strOpcion : '';

      });
  }

  fnGetCategorias() {
    let listado = {
      strAccion: 'getCategorias',
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(listado).subscribe(
      result => {
        this.arrayLineas = result;
        this.fnGetArticulos(result[0].strCategoria)
        this.fnpaginarcarousel();
      });
  }

  fnGetGrupos(categoria: any) {
    this.objLinea ={};
    this.objLinea = categoria;
    this.arrayGrupos =[];
   /*  console.log(categoria) */
    let grupos = {
      strAccion: 'getGrupos',
      strCategoria: categoria.strCategoria,
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(grupos).subscribe(
      result => {
        console.log(result)
        this.arrayGrupos = result

       /*  this.arrayGrupos.forEach((element: any) => {
          const nombreGrupo = element.strGrupo;
          this.gruposUnicos.add(nombreGrupo);
    
          if (!this.grupos[nombreGrupo]) {
            this.grupos[nombreGrupo] = [];
          }
    
          this.grupos[nombreGrupo].push(element);
        }); */
        this.fnGetArticulos(categoria.strCategoria);
      });
      
    
  }

  stopClickPropagation(event: Event): void {
    event.stopPropagation();
  }

  getImageUrl(strArticulo: string): string {
    if (strArticulo) {
      return `https://www.cleanclean.mx/imagenes/${strArticulo}.jpg`;
    }
    return 'https://www.cleanclean.mx/imagenes/NO-DISPONIBLE.JPG';
  }

  onImageError(event: any): void {
    event.target.src = 'https://www.cleanclean.mx/imagenes/NO-DISPONIBLE.JPG';
  }

  fnAdd_Rest_Amount(objArt: any, tipo: string) {
    if (tipo == 'add') {
      objArt.dblCantidad = objArt.dblCantidad + 1;
    } else {
      objArt.dblCantidad = objArt.dblCantidad - 1;
    }
  }

  fnAddToCarr(objArticulo: any) { //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado
 
 
    objArticulo.strComentario = "";
    this.spinerCarrito = true;
    const deepCopy = JSON.parse(JSON.stringify(objArticulo));
    /*   console.log(deepCopy); */
    this.carroservice.setArticulo(deepCopy);
    setTimeout(() => {
      this.spinerCarrito = false;
      this._alertify.success("Articulo agregado correctamente");
    }, 400);
  }

  fnDeleteCar(intId: number, objArt: any) { 
    this.arrayCarritoArts.splice(intId, 1);
    this.carroservice.deleteArt(objArt);
  }

  fnUpdateAmountCar(objArt: any) {
    this.carroservice.deleteArt(objArt);
  }

  fnBuscarCte(strNumCte: string) {
    this.objCliente = {
      strCliente: "",
      strNombre: "",
      strDireccion: "",
      ClienteSeleccionado: false,
      strNombreSucursal: "",
      intIDSucursal: "",
      strTipo: "",
      strAgente: "",
      strAlmacen: "",
      strClaveUsoCFDI: "",
      strCondicion: "",
      strDescripcionUsoCFDI: "",
      strInfopago: "",
      strInfopagoDescripcion: ""
    };
   // let objPlantilla = { boolActive: false, intIDMovimientoVenta: 0 }
   // this.servPlantilla.setPlantilla(objPlantilla);
   // this.carroservice.setCte(this.objCliente);
    this.card1[0].rows = 2;
    this.card2[0].rows = 3;
    let cliente = {
      strAccion: "getCte",
      strCliente: strNumCte,
      strGerente: this.localSt.retrieve('strUsuario'), //strUsuario
      strTipoAcceso: this.localSt.retrieve('intIDRolAcceso'),//intIDRolAcceso
      strSucursal: this.localSt.retrieve('strSucursal'),//strSucursal
      strAgente: this.localSt.retrieve('strAgente'), //strAgente
      strConexion: this.strConexion
    };
    /*  console.log(cliente) */
    this._httpService.getCotizador(cliente).subscribe(
      result => {
        //devuelve password encriptado 
        this.arrayClientes = result;
        let sucursales = []
        console.log(this.arrayClientes);
        this.arrayClientes.forEach(x => {
          if (x.strNombreSucursal != "") {
            sucursales.push(x);
          }
        });
      });
  }

  funllenarPorMarca() {
    let cont = 0;
    let filterarray = [];
    filterarray = this.arrayArticulos;
    this.arrayFilter = [];
    for (let check of this.arrayMarca) {
      if (check.checkbx === true) {
        cont = cont + 1;
        this.arrayFilter = this.arrayFilter.concat(filterarray.filter(item => item.strLinea === check.strLinea));
      }
    }
    if (cont === 0) {
      this.arrayFilter = this.arrayArticulos;
    }
    this.fnPaginar();
  }

  funArticulos(Articulo: string) {
    this.currentPage = 1;
    this.paginator.pageIndex = 0;
    if (Articulo === "" || Articulo === undefined) {
      this.arrayArticulos = [];
      this.arrayFilter = [];
      this.fnCalcular(this.dispositivo);
      this.funFiltrarCategoria();
      this.funfiltrarMarca();
    }
    else {
      this.strBuscarArticulo = Articulo;
      let articulo = {
        strAccion: "getArticulos",
        strArticuloBusqueda: this.strBuscarArticulo,
        strCliente: this.strNumCte,
        strConexion: this.strConexion,
        strCteFam: this.objCliente.strCteFam ? this.objCliente.strCteFam : ''
      };
      this._httpService.getCotizador(articulo).subscribe(
        result => {
          console.log(result)
          this.arrayArticulos = result;
          this.arrayFilter = result;
          this.arrayBusquedaArticulo = [];
          this.ArrayNum = this.arrayBusquedaArticulo.length;
          this.fnCalcular(this.dispositivo);
          this.funFiltrarCategoria();
          this.funfiltrarMarca();
        });
    }
  }

  funquitarElementosBusqueda() {
    this.arrayBusquedaArticulo = [];
    this.ArrayNum = this.arrayBusquedaArticulo;
  }

  funFiltrarCategoria() {
    let resultado = new Array();
    resultado = this.arrayArticulos;

    this.arrayCategoria = resultado.filter(
      (thing, i, arr) => arr.findIndex(t => t.strCategoria === thing.strCategoria) === i);
    this.arrayCategoria.sort((a, b) => b.strCategoria - a.strCategoria);

  }

  funfiltrarMarca() {
    let resultado = new Array();
    resultado = this.arrayArticulos;
    this.arrayMarca = resultado.filter(
      (thing, i, arr) => arr.findIndex(t => t.strLinea === thing.strLinea) === i);
    for (let i = 0; i < this.arrayMarca.length; i++) {
      this.arrayMarca[i].checkbx = false;
    }
    this.arrayMarca.sort((a, b) => a.strLinea - b.strLinea);
  }

  funCambiarImagenes() {
    for (let i = 0; i < this.arrayFilter.length; i++) {
      this.arrayFilter[i].strImg = "https://www.cleanclean.mx/imagenes/" + this.arrayFilter[i].strArticulo + ".JPG";
    }

  }

  funllenarArtiPorCategoria(catego: string) {
    let filterarray = [];
    filterarray = this.arrayArticulos;
    this.arrayFilter = [];
    this.arrayFilter = filterarray.filter(item => item.strCategoria === catego);
    this.fnPaginar();
  }

  funConsultarArt(strBuscarArticulo: string) {
    this.strBuscarArticulo = strBuscarArticulo;
    let articulo = {
      strAccion: "getBusqueda",
      strArticuloBusqueda: this.strBuscarArticulo,
      strCliente: this.objCliente.strCliente,
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {
        this.arrayBusquedaArticulo = result;
        this.ArrayNum = this.arrayBusquedaArticulo.length;

        this.fnCalcular(this.dispositivo);
      });
  }

  fnllenarInputs(i: number) {
    this.objCliente.strDireccion = this.arrayClientes[i].strDireccion;
    this.objCliente.strNombre = this.arrayClientes[i].strNombre;
    this.objCliente.strCliente = this.arrayClientes[i].strCliente;
    this.objCliente.strNombreSucursal = this.arrayClientes[i].strNombreSucursal;
    this.objCliente.intIDSucursal = this.arrayClientes[i].intIDSucursal;
    this.objCliente.strAgente = this.arrayClientes[i].strAgente;
    this.objCliente.ClienteSeleccionado = true;
    this.objCliente.strTipo = this.arrayClientes[i].strTipo;
    this.objCliente.strAlmacen = this.arrayClientes[i].strAlmacen;
    this.objCliente.strClaveUsoCFDI = this.arrayClientes[i].strClaveUsoCFDI;
    this.objCliente.strCondicion = this.arrayClientes[i].strCondicion;
    this.objCliente.strDescripcionUsoCFDI = this.arrayClientes[i].strDescripcionUsoCFDI;
    this.objCliente.strInfopago = this.arrayClientes[i].strInfopago;
    this.objCliente.strInfopagoDescripcion = this.arrayClientes[i].strInfopagoDescripcion;
    this.objCliente.strSucursal = this.arrayClientes[i].strSucursal;
    this.arrayClientes = [];
    this.card1[0].rows = this.objCliente.strTipo == "SUCURSAL" ? 3 : 2;
    this.card2[0].rows = this.objCliente.strTipo == "SUCURSAL" ? 5 : 3;
    this.carroservice.setCte(this.objCliente);
    this.carroservice.setModoPlantilla(false);
  }

  fnCalcular(dispositivo: number) {

    let artFila = dispositivo == 2 ? 4 : 2;
    let tamFila = dispositivo == 2 ? 4.4 : 4.6;
    let filas = dispositivo == 2 ? this.arrayFilter.length / artFila <= 1 ? tamFila :
      this.arrayFilter.length > 4 && this.arrayFilter.length <= 8 ? tamFila * 2 :
        this.arrayFilter.length > 8 && this.arrayFilter.length <= 12 ? tamFila * 3 :
          this.arrayFilter.length > 12 && this.arrayFilter.length <= 16 ? tamFila * 4 :
            this.arrayFilter.length > 16 ? 18 : tamFila :
      this.arrayFilter.length <= 16 ?
        Math.ceil(this.arrayFilter.length / artFila) * tamFila : 36;
    this.card1[3].rows = Math.ceil(filas) + 1;
    this.card2[2].rows = 1.5/*  filas + 1; */
    this.fnPaginar();
    if (dispositivo == 2) {
      this.fnpaginarcarousel()
    } else {

    }

  }

  fnPaginar() {
    let npaginas = Math.ceil(this.arrayFilter.length / this.items);
    npaginas = npaginas == 0 ? 1 : npaginas;
    this.arrayPaginas = [];
    for (let i = 1; i <= npaginas; i++) {
      this.arrayPaginas.push({ pagina: i })
      this.arrayFilter.forEach((element, index, arreglo) => {
        element.dblCalificacion = 3
        element.intTotalEstrellas = 2;
        if (index < this.items * i && index >= ((this.items * i) - this.items)) {
          element.pagina = i;
        }
      });
    }
  }

  fnPaginator(pagina?: number, prev?: string) {
    if (prev == 'prev') {
      this.currentPage = this.currentPage - 1 > 1 ? this.currentPage - 1 : 1;
    }
    else if (prev == 'next') {
      this.currentPage = this.currentPage + 1 < this.arrayPaginas.length ? this.currentPage + 1 : this.arrayPaginas.length;
    }
    else {
      this.currentPage = pagina;
    }
  }

  fnArtSinLp(arrayArt: any) {
console.log(arrayArt)
    this.intPrecioPolitica = 0;
    this.intPrecioPlantilla = 0;
    this.intPrecioLista = 0;
    this.intPrecio = 0;
    this.intPrecio2 = 0;
    this.intPrecio3 = 0;
    this.intPrecio4 = 0;
    this.arrayPreciosArt = [];
    this._httpService.getListaPrecios({
      strAccion: 'get_PreciosArticulos',
      xmlArticulos: [arrayArt],
      strCliente: this.objCliente.strCliente,
      strConexion: this.strConexion,
      strCteFam: this.objCliente.strCteFam
    }).subscribe(
      result => {
        this.arrayPreciosArt = result;
        /*   console.log(this.arrayPreciosArt); */
        /*if(arrayArt.strTipoOpcion == "Si"){
          let arop = arrayArt.strOpcion?arrayArt.strOpcion.trim():'';
          let rest = this.arrayPreciosArt.find(obj => obj.strOpcion.trim() ==arop );
          this.intPrecioLista = rest.intPrecioLista
          this.intPrecio=rest.intPrecio;
          this.intPrecio2=rest.intPrecio2;
          this.intPrecio3=rest.intPrecio3;
          this.intPrecio4= this.Accesop4 ? rest.intPrecio4:0;
          this.intPrecioPlantilla= rest.intPrecioPlantilla;
          this.intPrecioPolitica= rest.intPrecioPolitica;
         
        }else{*/
        this.intPrecioLista = this.arrayPreciosArt[0].intPrecioLista;
        this.intPrecio = this.arrayPreciosArt[0].intPrecio;
        this.intPrecio2 = this.arrayPreciosArt[0].intPrecio2;
        this.intPrecio3 = this.arrayPreciosArt[0].intPrecio3;
        this.intPrecio4 = this.Accesop4 ? this.arrayPreciosArt[0].intPrecio4 : 0;
        this.intPrecioPlantilla = this.arrayPreciosArt[0].intPrecioPlantilla;
        this.intPrecioPolitica = this.arrayPreciosArt[0].intPrecioPolitica;

        //}
        //guardamos todos los precios del articulo
        this.objArt.intPrecioPolitica = this.intPrecioPolitica;
        this.objArt.intPrecioPlantilla = this.intPrecioPlantilla;
        this.objArt.intPrecio = this.intPrecio;
        this.objArt.intPrecio2 = this.intPrecio2;
        this.objArt.intPrecio3 = this.intPrecio3;
        this.objArt.intPrecio4 = this.intPrecio4;
        this.objArt.intPrecioLista = this.intPrecioLista;

        this.objArt.dblPrecioLista = this.intPrecioPolitica > 0 ? this.intPrecioPolitica : this.intPrecioPlantilla > 0 ? this.intPrecioPlantilla : this.intPrecioLista;
      },
      error => {
        var error = <any>error;
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Uuups...',
          text: 'Error en conexion al extraer articulos sin precio lista, intente de nuevo.'
        });
      });
  }

  fntraerExistencias(object: any) {

    let opcion = "";
    if (object.strTipoOpcion == "Si") {
      opcion = object.strOpcion;
    }
    let articulo = {
      strArticulo: object.strArticulo,
      strSubcuenta: opcion,
      strConexion: this.strConexion,
      strAccion: 'getExistencias'
    };

    this._httpService.getListaPrecios(articulo).subscribe(
      result => {
        this.arrayExistentes = result;

      });
  }

  //Lineas
  fnseleclinea(obj: any) {
    this.objLinea = {};
    this.objLinea = obj;
    this.strlinea = obj.strGrupo;
    this.fnExtraerXlinea();
  }

  fnExtraerXlinea() {
    this.arrayArtsporlinea = [];
    let myobject1 = { strAccion: 'getArt-Linea', 'strCategoria': this.objLinea.strCategoria.replace(/^\s+|\s+$/g, ""), 'strGrupo': this.objLinea.strGrupo.replace(/^\s+|\s+$/g, ""), strConexion: this.strConexion };
    this._httpService.getLineasComodatos(myobject1, "sp_ModVentas_Comodato").subscribe(
      result => {
        this.arrayArtsporlinea = result;
        this.arrayArtsporlinea.forEach(x => {
          x.mosDescrip = false;
        });
      });
  }


  fnExtraerlineas() {
    this.arrayLineas = [];
    this._httpService.getLineasComodatos({
      strAccion: 'getCategoriasComodatos',
      strConexion: this.strConexion
    }, "sp_ModVentas_Comodato").subscribe(
      result => {
        /* console.log(result) */
        this.arrayLineas = result;
        this.fnpaginarcarousel();
      });
  }

  fnpaginarcarousel() {
    this.bloques = [];
    for (let i = 0; i < this.arrayLineas.length; i += this.elementosPorPagina) {
      this.bloques.push(this.arrayLineas.slice(i, i + this.elementosPorPagina));
    }
  }

  fnGetArticulos(categoria: any) {
    this.arrayFilter = []
    this.arrayArts_all = [];
    this.strCategoSeleccionada = categoria
    let articulo = {
      strAccion: "getArticulo_Cat",
      strCategoria: categoria,
      strCliente: (this.objCliente.strCliente) ? this.objCliente.strCliente : '',
      strCteFam: (this.objCliente.strCteFam) ? this.objCliente.strCteFam : '',
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {
        console.log(result)
        this.arrayArts_all = result;
        this.arrayFilter = result
        this.fnPaginar();
        this.fnCalcular(this.dispositivo);
      });
  }

  fnSelectGroup(grupo: any) {
    console.log(grupo)
    this.arrayFilter = this.arrayArts_all.filter(o => o.strGrupo == grupo);
    this.strGrupoSelect = grupo
    this.fnPaginar();
    this.fnCalcular(this.dispositivo);
  }
}
