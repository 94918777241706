<div class="container">
    <div class="row">
        <div class="col-12 mt-3">
            <h1 class="fw-bold nunito text-center">{{strTipo}}</h1>
        </div>
    </div>
    <div class="row" *ngIf="arrayProductos.length==0">
        <div class="col-12">
            <div class="alert alert-danger fw-bold text-center" role="alert">
                No se encontraron productos disponibles.
              </div>
        </div>
    </div>
    <div class="row" *ngIf="arrayProductos.length>0">
        <ng-container *ngFor="let producto of arrayProductos">
          <div *ngIf="producto.dblPrecioLista>0" class="col-6 col-md-4 col-xl-3 d-flex justify-content-center"  (click)="addToCart(producto)" >
            <div class="w96 transition1 shadow-sm rounded mb-2 px-2 py-2 bg-white d-flex">
              <div class="col-12 d-flex flex-column" (mouseenter)="producto.hovered = true" (mouseleave)="producto.hovered = false">
                <div class="col-12 ovh position-relative">
                  <img class="col-12" style="visibility: hidden;" [src]="'https://www.cleanclean.mx/imagenes/'+producto?.strArticulo+'.JPG'" alt="{{ producto.strArticulo}}" (error)="onImageError($event)">
                  <img class="col-12 position-absolute top-50 start-50 translate-middle" [@menuAnimation]="!producto.hovered ? 'visible' : 'hidden'"   [src]="'https://www.cleanclean.mx/imagenes/'+producto?.strArticulo+'.JPG'" alt="{{ producto.strArticulo}}" (error)="onImageError($event)">
                  <img class="h-100 col-12 position-absolute top-50 start-50 translate-middle" [@menuAnimation]="producto.hovered ? 'visible' : 'hidden'"  [src]="'https://www.cleanclean.mx/imagenes/'+producto?.strArticulo+'-PO-2.JPG'" alt="{{ producto.strArticulo}}" (error)="onImageError($event)">
                  <span class="bgOferta position-absolute top-0 end-0 fw-bold nunito fs13 px-1 text-white mt-3" *ngIf="producto.strTipo == 'OFERTA'">
                    OFERTA
                  </span>
                  <span class="bgLiquidacion position-absolute top-0 end-0 fw-bold nunito fs13 px-1 text-white mt-3" *ngIf="producto.strTipo == 'LIQUIDACION'">
                    LIQUIDACIÓN
                  </span>
                </div>
                <div class="d-flex flex-column h-100">
                  <span class="mb-0 fs13 nunito text-dark fw-bold mt-auto">{{ producto.strDescripcion }}</span>
                  <div class="col-12 ">
                    <div class="row">
                      <div class="d-flex flex-column col-6 my-2">
                          <h4 class="precio mb-0 text-start" [ngClass]="{'fs11 text-decoration-line-through text-dark':producto.dblPrecioOferta>0,'fw-bolder fs-5 text-dark':producto.dblPrecioOferta==0}">{{ producto?.dblPrecioLista|currency}}</h4>
                          <h4 class="precio fw-bolder mb-0 text-start fs-5 text-dark" *ngIf="producto.dblPrecioOferta>0">{{ producto?.dblPrecioOferta|currency}}</h4>
                      </div>
                      <div class="d-flex flex-column col-6">
                        <span class="fs16 fw-bold mb-0 text-end text-dark "><i class="bi bi-cart4 fs-4 pointer"></i></span>
                      </div>
                    </div>
                  </div>
                
                  <div class="col-12  d-flex align-items-center  ">
                    <div class="d-flex  col-12 justify-content-between">
                      <h4 class="ref text-start fw-bold fs13 mb-0 text-secondary">
                        REF {{producto?.strArticulo}}
                      </h4>
                      <h4 class="fw-bold text-success fs13 mb-0" *ngIf="producto.dblPrecioOferta>0">
                        Ahorra: {{producto.oferta}}%
                      </h4>
                    </div>
                  </div>
                  <button class="btn btn-outline-secondary fs13 fw-bold" (click)="addToCart(producto)">Compre ahora</button>
                </div>
              </div>
              
            </div>
          </div>
        </ng-container>
        
      </div>
</div>