import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService} from 'ngx-webstorage';
@Component({
  selector: 'app-pruebas',
  templateUrl: './pruebas.component.html',
  styleUrls: ['./pruebas.component.css']
})
export class PruebasComponent implements OnInit {
  @ViewChild('articuloTD') articuloTD!: ElementRef;
  @ViewChild('descripcionTD') descripcionTD!: ElementRef;
public anchoArticulo:number=0;
public anchodescripcion:number=0;
  public arrayPeriodo = [
    { strMes: "Enero", intMes: 1 },
    { strMes: "Febrero", intMes: 2 },
    { strMes: "Marzo", intMes: 3 },
    { strMes: "Abril", intMes: 4 },
    { strMes: "Mayo", intMes: 5 },
    { strMes: "Junio", intMes: 6 },
    { strMes: "Julio", intMes: 7 },
    { strMes: "Agosto", intMes: 8 },
    { strMes: "Septiembre", intMes: 9 },
    { strMes: "Octubre", intMes: 10 },
    { strMes: "Noviembre", intMes: 11 },
    { strMes: "Diciembre", intMes: 12 }
];
data = [];
public strConexion:string="";
public arrayArticulos:any=[];

  constructor(private _httpService:PeticionesService,private localSt:LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
   }

  ngOnInit(): void {
   this.fnGetdatos() 
  }

ngAfterViewInit() {
  
  
}

  fniniciar(){
    let bandera=0;
    this.data.forEach (o =>{
      bandera = 0
      this.arrayArticulos.forEach (x =>{
      if(x.strArticulo == o.strArticulo){
        bandera = bandera+1
      }
      })
      if(bandera == 0){
        this.arrayArticulos.push(o);
      }
    })
   this.arrayArticulos.forEach( art => {
      this.data.forEach(dat =>{
        if(dat.strArticulo == art.strArticulo){
          art[dat.strMes] = {intCantidad:dat.intCantidad,dblImporte:dat.dblImporte,intSucursalesCte:dat.intSucursalesCte} 
        }
      })
   });
   
    setTimeout(() => {
      this.logAnchoTDs();
    }, 3000);

  }
  logAnchoTDs() {
     this.anchoArticulo = this.articuloTD.nativeElement.offsetWidth;
    this.anchodescripcion = this.descripcionTD.nativeElement.offsetWidth;

    console.log('Ancho del TD de Articulo:', this.anchoArticulo);
    console.log('Ancho del TD de Descripcion:', this.anchodescripcion);
  }

/*   dividirTexto(texto: string): string {
    const mitadLongitud = Math.ceil(texto.length / 2); // Obtener la mitad de la longitud redondeada hacia arriba
    const primeraMitad = texto.substring(0, mitadLongitud);
    const segundaMitad = texto.substring(mitadLongitud);
    return `${primeraMitad}<br>${segundaMitad}`;
  }
 */
  fnGetdatos(){
    let objBono: any =
     { 
      strAccion: 'getReporteHistVta',
      strFamilia: "AVENDRA", 
      intEjercicio: 2024,
      "strConexion": this.strConexion };
    this._httpService.mostrarCotizaciones(objBono,'sp_modventas_directorio').subscribe(
      result =>{
        this.data = result;
        console.log(this.data);
        this.fniniciar()
      }, error => {
        console.log("error: ", error);
      });
  

  }


}