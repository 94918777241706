<div class="container-fluid ">
    <div class="row">
      <div class="col-12 px-0">
          <nav aria-label="breadcrumb" class="px-0 ">
              <ol class="breadcrumb d-flex align-items-center mb-0">
                  <li class="breadcrumb-item"><a routerLink="/tienda"  >Categorias</a></li>
                  <li class="breadcrumb-item active"><a class="text-link" (click)="fnIrSubLinea();" >{{strLinea}}</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Resultado de Búsqueda</li>
              </ol>
          </nav>
      </div>
  </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="page-container">
          <div class="page-title">
            <h1 class="fw-bold">{{strLinea}}</h1> <!-- Título centrado y vistoso -->
          </div>
          <div class="d-flex align-items-start">
            <div class="filter-section stickTop d-none d-lg-block">
              <h2>Filtrar Sublineas</h2>
              <ul class="scrollT">
                <li class="fs13" *ngFor="let category of arraySubcategorias" [ngClass]="{'d-none':category.strGrupo =='' ||category.strGrupo ==undefined,'selectProd':category.strGrupo == strSubLinea}" (click)="filterByCategory(category.strGrupo)">
                  {{category.strGrupo}}
                </li>
              </ul>
            </div>
            <div *ngIf="isButtonVisible" class="position-fixed bottom-0 end-0 mb-5 p-2 bg-white shadow borderrounstart d-flex align-items-start" (click)="scrollToTop()" >
              <button  id="scrollTopBtn"  class="btn selectProd rounded-circle shadow-sm d-flex" ><i class="bi bi-chevron-up icon-size"></i></button>
            </div>
            <div class="product-section pt-0 px-0" >
              <div class="row my-4" *ngIf="spiner">
                <div class="col-12 d-flex justify-content-center">
                    <mat-spinner  [strokeWidth]="6" [diameter]="70"  class=" rounded-circle custom-spinner"></mat-spinner>
                </div>
              </div>
              <div class="row" *ngIf="!spiner">
                <div class="col-12 d-flex  mb-3" *ngIf="arrayArticulos.length>0">
                  <div class="col-10  bg-white py-2 rounded shadow-sm ">
                    <mat-form-field appearance="outline" class="rounded bg-light custom-form-field ms-2">
                      <mat-label>Buscar Articulo</mat-label>
                      <input [(ngModel)]="strbuscarLista" matInput placeholder="Clave">
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                  </div>
                  <div class="col-2 bg-white d-flex align-items-center justify-content-center d-lg-none">
                    <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#canvasCategoria" aria-controls="canvasCategoria"><i class="bi bi-funnel-fill"></i></button>
                  </div>
                </div>
                <div *ngIf="arrayArticulos.length==0">
                  <div class="alert alert-danger text-center" role="alert">
                    <strong>Artículo no encontrado.</strong> Intenta con otra búsqueda o verifica la informacón ingresada.
                  </div>
                </div>
                <div *ngFor="let product of arrayArticulos | filtergrupo:strSubLinea|filtersearch: strbuscarLista: ['strArticulo']" class="col-6 col-lg-4 col-xl-3 d-flex justify-content-center" >
                  <div class="col-12 pb-2 shadow-sm px-0 mb-4 rounded d-flex flex-column bg-white cardTrans pointer" (click)="addToCart(product)">
                    
                   <!--  <img [src]="product.imageUrl" alt="{{ product.strArticulo }}" class="w-100 rounded">
                    <div class="col-12 fw-bold fs13 px-2 nunito mb-2">
                      <span class="fsch">{{ product.strDescripcion1 }}</span>
                    </div>
                    <div class="col-12 d-flex justify-content-evenly mt-auto">
                      <div class="col-6 text-start fw-bold descripcion fs13 nunito ps-2">
                       REF: {{ product.strArticulo  }}
                      </div>
                      <div class="col-6 text-end descripcion fs14 pe-2">
                        {{ product.dblPrecioLista|currency}}
                      </div>
                    </div>
                    <div class="col-12 p-2 text-center mt-auto">
                      <button type="button" class="btn btn-outline-secondary fs13 text-nowrap d-flex"  (click)="addToCart(product)"><i class="bi bi-cart3 mx-1 d-none d-lg-block" ></i> Compre ahora</button>
                    </div> -->


                    <div class="col-12 ">
                      <div class="col-12 ovh position-relative">
                        <img class="col-12" style="visibility: hidden;" [src]="'https://www.cleanclean.mx/imagenes/'+product?.strArticulo+'.JPG'" alt="{{ product.strArticulo}}" (error)="onImageError($event)">
                        <img class="col-12 position-absolute top-50 start-50 translate-middle"  [src]="'https://www.cleanclean.mx/imagenes/'+product?.strArticulo+'.JPG'" alt="{{ product.strArticulo}}" (error)="onImageError($event)">
                        
                       <!-- <img class="col-12 position-absolute top-50 start-50 translate-middle" [@menuAnimation]="!product.hovered ? 'visible' : 'hidden'"   [src]="'https://www.cleanclean.mx/imagenes/'+product?.strArticulo+'.JPG'" alt="{{ product.strArticulo}}" (error)="onImageError($event)">
                        <img class="h-100 col-12 position-absolute top-50 start-50 translate-middle" [@menuAnimation]="product.hovered ? 'visible' : 'hidden'"  [src]="'https://www.cleanclean.mx/imagenes/'+product?.strArticulo+'-PO-2.JPG'" alt="{{ product.strArticulo}}" (error)="onImageError($event)">
                      -->
                        <span class="bgOferta position-absolute top-0 end-0 fw-bold nunito fs13 px-1 text-white mt-3" *ngIf="product.strTipoPedido == 'OFERTA'">
                          OFERTA
                        </span>
                        <span class="bgLiquidacion position-absolute top-0 end-0 fw-bold nunito fs13 px-1 text-white mt-3" *ngIf="product.strTipoPedido == 'LIQUIDACION'">
                          LIQUIDACIÓN
                        </span>
                      </div>
                      <div class="d-flex flex-column px-1">
                        <span class="mb-0 fs13 nunito text-dark fw-bold descripcion-truncada">{{ product.strDescripcion1 }}</span>
                        <div class="col-12">
                       
                          <div class="row">
                            <div class="d-flex flex-column col-6 my-2 pe-0">
                                <h4 class="precio mb-0 text-start" [ngClass]="{'fs11 text-decoration-line-through text-dark':product.dblPrecioOferta>0,'fw-bolder fs-6 text-dark':product.dblPrecioOferta==0}">{{ product?.dblPrecioLista|currency}}</h4>
                                <h4 class="precio fw-bolder mb-0 text-start fs-6 text-dark" *ngIf="product.dblPrecioOferta>0">{{ product?.dblPrecioOferta|currency}}</h4>
                            </div>
                            <div class="d-flex flex-column col-6 ps-0">
                              <span class="fs16 fw-bold mb-0 text-end text-dark "><i class="bi bi-cart4 fs-4 pointer"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12  d-flex align-items-center  ">
                          <div class="d-flex  col-12 justify-content-between">
                            <h4 class="ref text-start fw-bold fs12 mb-0 text-secondary">
                              REF {{product?.strArticulo}}
                            </h4>
                            <h4 class="fw-bold text-success fs12 mb-0" *ngIf="product.dblPrecioOferta>0 && product.strTipoPedido != ''" >
                              Ahorra: {{product.oferta}}%
                            </h4>
                          </div>
                        </div>
                        <button class="btn btn-outline-secondary fs12 fw-bold" (click)="addToCart(product)">Compre ahora</button>
                      </div>
                    </div>


              







                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="offcanvas offcanvas-bottom " style="height: 70vh;" tabindex="-1" id="canvasCategoria" aria-labelledby="canvasCategoriaLabel">
    <div class="offcanvas-header">
     
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" #canvasCategoria></button>
    </div>
    <div class="offcanvas-body small">
      
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="filter-section stickTop">
                <h2>Filtrar Sublineas</h2>
                <ul class="scrollT">
                  <li class="fs13" *ngFor="let category of arraySubcategorias" [ngClass]="{'d-none':category.strGrupo =='' ||category.strGrupo ==undefined,'selectProd':category.strGrupo == strSubLinea}" (click)="filterByCategory(category.strGrupo)">
                    {{category.strGrupo}}
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  