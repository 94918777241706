<div class="container-fluid">
    <div class="row justify-content-center mt-2 d-none d-md-flex">
        <div class="col-12 col-md-11 mb-2">
            <div class="row bg-white bordertop borderbott shadow pb-2 justify-content-center">
                <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" [hidden]="!boolGerente">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
                        <mat-select [(ngModel)]="strGerente" class="">
                            <mat-option value=""> --Todos --</mat-option>
                            <mat-option *ngFor="let x of arrayGerentes"
                                [value]="x.strGerente">{{x.strNombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                    [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" [hidden]="!boolSucursal">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                        <mat-select [(ngModel)]="strSucursal" class="">
                            <mat-option value=""> --Todas --</mat-option>
                            <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente"
                                [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                    [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" [hidden]="!boolAgente">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
                        <mat-select [(ngModel)]="strAgente" class="">
                            <mat-option value=""> --Todos --</mat-option>
                            <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
                                [value]="x.strAgente">{{x.strNombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                    [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
                        <mat-select [(ngModel)]="intPeriodo" class="">
                            <mat-option *ngFor="let x of arrayPeriodo"
                                [value]="x.strPeriodo">{{x.strNombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"
                    [ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                        <mat-select [(ngModel)]="intEjercicio">
                            <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                                {{e.ejercicio}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
                    [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }">
                    <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true"
                        (mouseleave)="isHoveredButton = false" (click)="fnGetLead_All()"> <!-- -->
                        <i class="bi  p-1 fs-4"
                            [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 d-inline-flex">
            <div class="ms-auto d-flex align-items-center justify-content-end mt-2">
                <div class="col-6 d-md-none">
                    <!--     <button class="btn  btn-primary  rounded-circle d-flex align-items-center p-1 shadow mx-auto"
                      type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" matTooltip="Opciones de filtrado" aria-controls="offcanvasTop">
                      <span class="material-symbols-outlined" style="font-size: 30px;">
                        filter_list
                      </span>
                    </button> -->
                    <div class="offcanvas offcanvas-bottom offcanvas-custom-height" tabindex="-1" id="offcanvasTop"
                        aria-labelledby="offcanvasTopLabel">
                        <!--   <div class="offcanvas-header">
                        <div class="row" style="background: #c7daff;">
                          <h5 id="offcanvasTopLabel" class="titulos">Filtar por: <button type="button"
                              class="btn-close text-reset btn-outline-primary" data-bs-dismiss="offcanvas"
                              aria-label="Close"></button>
                          </h5>
                        </div>
                      </div> -->
                        <div class="offcanvas-body large">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <button type="button" class="btn-close text-reset ms-auto"
                                        data-bs-dismiss="offcanvas" #btncerrarFiltros aria-label="Close"></button>
                                </div>
                                <div class="col-12 d-flex flex-column" *ngIf="boolGerente">
                                    <label for="">Gerente</label>
                                    <select class="form-select form-select-lg mb-3" [(ngModel)]="strGerente"
                                        aria-label=".form-select-lg example">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12" *ngIf="boolSucursal">
                                    <label for="">Sucursal</label>
                                    <select [(ngModel)]="strSucursal" class="form-select form-select-lg mb-3"
                                        aria-label=".form-select-lg example">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let x of arraySucursales | sucursalFilter:strGerente"
                                            [value]="x.strSucursal">{{x.strNombreSucursal}}</option>
                                    </select>
                                </div>
                                <div class="col-12" *ngIf="boolAgente">
                                    <label for="">Agente</label>
                                    <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                                        [(ngModel)]="strAgente">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
                                            [value]="x.strAgente">{{x.strNombre}}</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <label for="">Periodo</label>
                                    <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                                        [(ngModel)]="intPeriodo">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <label for="">Ejercicio</label>
                                    <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                                        [(ngModel)]="intEjercicio">
                                        <option value="" selected>--Seleccione--</option>
                                        <option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">{{e.ejercicio}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 mt-2 pt-1   d-flex justify-content-center align-items-center">
                                    <button class="btn btnFilter shadow-sm px-2 py-1"
                                        (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false"
                                        (click)="fnGetLead_All()"> <!-- -->
                                        <i class="bi  p-1 fs-4"
                                            [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <input type="text" placeholder="Buscar" class="form-control mx-2" [(ngModel)]="searchText">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#nuevoLead"><i
                        class="fas fa-plus mx-1"></i></button>
                <div class="btn-group ms-2 me-3" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked
                        (click)="cambiarVista('cards')">
                    <label class="btn btn-outline-secondary" for="btnradio1"><i class="far fa-list-alt"></i></label>

                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                        (click)="cambiarVista('lista')">
                    <label class="btn btn-outline-secondary" for="btnradio2"><i class="fas fa-list"></i></label>
                </div>
                <div class="dropdown">
                    <!-- <button class="btn btn-outline-secondary  dropdown-toggle justify-content-center d-none d-md-block " type="button"
                        id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-filter"></i> Filtros
                    </button> -->
                    <button class="btn btn-outline-secondary  dropdown-toggle justify-content-center d-md-none"
                        type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop"
                        matTooltip="Opciones de filtrado">
                        <i class="fas fa-filter"></i> Filtros
                    </button>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <form>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox1" name="radioGroup"
                                    [checked]="checkboxes['conDemora']" (change)="onCheckboxChange('conDemora')">
                                <label for="checkbox1" class="m-auto">Con demora</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox2" name="radioGroup" [checked]="checkboxes['hoy']"
                                    (change)="onCheckboxChange('hoy')">
                                <label for="checkbox2" class="m-auto">Hoy</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox3" name="radioGroup" [checked]="checkboxes['manana']"
                                    (change)="onCheckboxChange('manana')">
                                <label for="checkbox3" class="m-auto">Mañana</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox4" name="radioGroup"
                                    [checked]="checkboxes['estaSemana']" (change)="onCheckboxChange('estaSemana')">
                                <label for="checkbox4" class="m-auto">Esta semana</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox5" name="radioGroup"
                                    [checked]="checkboxes['esteMes']" (change)="onCheckboxChange('esteMes')">
                                <label for="checkbox5" class="m-auto">Este mes</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox6" name="radioGroup"
                                    [checked]="checkboxes['futuros']" (change)="onCheckboxChange('futuros')">
                                <label for="checkbox6" class="m-auto">Futuros</label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mb-3">

        <div class="row mt-2" *ngIf="banderaCards">
            <div class="scroll-container" style="white-space: nowrap; ">
                <div class="">

                    <div class="scrollbar-top overflow-auto col pt-3" #scrollbarTop></div>
                    <div class="scroll-content overflow-auto col" #scrollContent>
                        <div class="col border-end columnas"
                            style="display: inline-block; min-width: 250px; vertical-align: top;">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <div class="me-auto contenedorTitulo">
                                        <span class="titulo">POR CONTACTAR</span>
                                    </div>
                                    <div class="ms-auto contenedorNumero">
                                        <span>{{intTotal_por_contactar}}</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex mt-2 mb-1">
                                    <div class="degradado m-auto"></div>
                                </div>

                                <div class="col-6 col-lg d-flex ope-0">
                                    <span class="me-auto d-flex contenedorTitulo" style="font-size: 12px;"
                                        matTooltip="Total Leads">
                                        <div class="square me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>

                                <div class="col-6 col-lg d-flex">
                                    <span class="ms-auto d-flex contenedorNumero" style="font-size: 12px;"
                                        matTooltip="Total Oportunidades">
                                        <div class="squareOportunidad me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>
                            </div>
                            <div class="row columna-con-scroll p-2 d-flex">
                                <div class="card card-sin-datos mx-auto px-0"
                                    *ngIf="((array_por_contactar | filtersearch: filterPost : ['strAgente', 'strNombreEmpresa']).length === 0) ">
                                    <div class="card-header d-flex justify-content-center pt-0">
                                        <span class="text-bold text-sm ">SIN DATOS</span>
                                    </div>
                                </div>
                                <div class="card mx-auto mt-2 px-0 py-0"
                                    [ngClass]="{'cardLead': item.strTipo_leads== 'LEADS', 'cardOportunidad': item.strTipo_leads == 'OPORTUNIDAD'}"
                                    (click)="fnDetalleLead(item, 'POR CONTACTAR')"
                                    *ngFor="let item of array_por_contactar | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-12">
                                                <span *ngIf="item.strTipo_leads == 'LEADS'"
                                                    class="fw-bold text-wrap">{{item.strNombreEmpresa}}</span>
                                                <span *ngIf="item.strTipo_leads == 'OPORTUNIDAD'"
                                                    class="fw-bold text-wrap">{{item.strTitulo}}</span>
                                            </div>
                                            <!-- <div class="col-2">
                                                <span class="fw-bold">{{item.strClasificacion}}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row d-flex">
                                            <div class="col-5 mb-2">
                                                <div
                                                    [ngClass]="{'rectangulo': item.strTipo_leads== 'LEADS', 'rectanguloOpo': item.strTipo_leads == 'OPORTUNIDAD'}">
                                                    {{item.strTipo_leads}}
                                                </div>
                                            </div>
                                            <div class="col-5 mb-2" *ngIf="item.strTipo_leads == 'OPORTUNIDAD'">
                                                <div class="rectanguloMonto">
                                                    {{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2" *ngIf="item.strFamilia"
                                                [ngClass]="{'col-7': item.strTipo_leads == 'LEADS', 'col-12': item.strTipoLeads == 'OPORTUNIDAD' }">
                                                <div class="rectanguloFamilia">
                                                    {{item.strFamilia}}
                                                </div>
                                            </div>
                                            <div class="col-6 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-phone-alt mx-1"></i> {{item.strContacto_Nombre}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-user-tag"></i> {{item.strNombreAgente}}
                                                </div>
                                            </div>
                                            <!-- <div class="col-6 mb-2 mx-auto">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-calendar-alt mx-1"></i> Jun 15 - Jun 17
                                        </div>
                                    </div> -->
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span
                                                    class="fw-bold clasificacion me-auto">{{item.strClasificacion}}</span>
                                            </div>
                                            <!--<div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span class="ms-auto" *ngIf="item.strGiro == 'A'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'B'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'C'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'Z' || !item.strGiro">
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                -- <span class="ms-auto">
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro === 'A' || item.strGiro === 'B' || item.strGiro === 'C'), 'sinFondo': (item.strGiro === 'Z' || !item.strGiro)  }"></i>
                                                    <i class="far fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B' || item.strGiro == 'C'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                </span> --
                                            </div>-->
                                            <!-- <div class="col-4 d-flex aling-items-end justify-content-center">
                                                <span class="m-auto">
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card mx-auto mt-3 px-0 py-0 cardOportunidad">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-10">
                                        <span class="fw-bold">SMART FIT</span>
                                    </div>
                                    <div class="col-2">
                                        <span class="fw-bold">CCC</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row d-flex">
                                    <div class="col-4 mb-2">
                                        <div class="rectanguloOpo">
                                            OPORTUNIDAD
                                        </div>
                                    </div>
                                    <div class="col-4 mb-2">
                                        <div class="rectanguloFamilia">
                                            HOME DEPOT
                                        </div>
                                    </div>
                                    <div class="col-4 mb-2">
                                        <div class="rectanguloMonto">
                                            $100.00
                                        </div>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-phone-alt mx-1"></i> JUAN GUZMAN
                                        </div>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-user-tag mx-1"></i> IRMA FRAUSTO
                                        </div>
                                    </div>
                                    <div class="col-6 mb-2 mx-auto">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-calendar-alt mx-1"></i> Jun 15 - Jun 17
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                            </div>
                        </div>
                        <div class="col border-end columnas"
                            style="display: inline-block; min-width: 250px; vertical-align: top;">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <div class="me-auto contenedorTitulo">
                                        <span class="titulo">PRESENTACIÓN</span>
                                    </div>
                                    <div class="ms-auto contenedorNumero">
                                        <span>{{intTotal_presentacion}}</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex mt-2 mb-1">
                                    <div class="degradado2 m-auto"></div>
                                </div>
                                <div class="col-6 col-lg d-flex ope-0">
                                    <span class="me-auto d-flex contenedorTitulo" style="font-size: 12px;"
                                        matTooltip="Total Leads">
                                        <div class="square me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>

                                <div class="col-6 col-lg d-flex">
                                    <span class="ms-auto d-flex contenedorNumero" style="font-size: 12px;"
                                        matTooltip="Total Oportunidades">
                                        <div class="squareOportunidad me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>
                            </div>
                            <div class="row columna-con-scroll p-2 d-flex">
                                <div class="card card-sin-datos mx-auto px-0"
                                    *ngIf="((arrayPresentacion | filtersearch: filterPost : ['strAgente', 'strNombreEmpresa']).length === 0) ">
                                    <div class="card-header d-flex justify-content-center pt-0">
                                        <span class="text-bold text-sm ">SIN DATOS</span>
                                    </div>
                                </div>
                                <div class="card mx-auto mt-2 px-0 py-0"
                                    [ngClass]="{'cardLead': item.strTipo_leads== 'LEADS', 'cardOportunidad': item.strTipo_leads == 'OPORTUNIDAD'}"
                                    (click)="fnDetalleLead(item, 'PRESENTACION')"
                                    *ngFor="let item of arrayPresentacion | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-12">
                                                <span *ngIf="item.strTipo_leads == 'LEADS'"
                                                    class="fw-bold text-wrap">{{item.strNombreEmpresa}}</span>
                                                <span *ngIf="item.strTipo_leads == 'OPORTUNIDAD'"
                                                    class="fw-bold text-wrap">{{item.strTitulo}}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row d-flex">
                                            <div class="col-5 mb-2">
                                                <div
                                                    [ngClass]="{'rectangulo': item.strTipo_leads== 'LEADS', 'rectanguloOpo': item.strTipo_leads == 'OPORTUNIDAD'}">
                                                    {{item.strTipo_leads}}
                                                </div>
                                            </div>
                                            <div class="col-5 mb-2" *ngIf="item.strTipo_leads == 'OPORTUNIDAD'">
                                                <div class="rectanguloMonto">
                                                    {{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2" *ngIf="item.strFamilia"
                                                [ngClass]="{'col-7': item.strTipo_leads == 'LEADS', 'col-12': item.strTipoLeads == 'OPORTUNIDAD' }">
                                                <div class="rectanguloFamilia">
                                                    {{item.strFamilia}}
                                                </div>
                                            </div>
                                            <div class="col-6 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-phone-alt mx-1"></i> {{item.strContacto_Nombre}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-user-tag"></i> {{item.strNombreAgente}}
                                                </div>
                                            </div>
                                            <!-- <div class="col-6 mb-2 mx-auto">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-calendar-alt mx-1"></i> Jun 15 - Jun 17
                                        </div>
                                    </div> -->
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span
                                                    class="fw-bold clasificacion me-auto">{{item.strClasificacion}}</span>
                                            </div>
                                            <!--<div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span class="ms-auto" *ngIf="item.strGiro == 'A'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'B'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'C'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'Z' || !item.strGiro">
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                -- <span class="ms-auto">
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro === 'A' || item.strGiro === 'B' || item.strGiro === 'C'), 'sinFondo': (item.strGiro === 'Z' || !item.strGiro)  }"></i>
                                                    <i class="far fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B' || item.strGiro == 'C'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                </span> --
                                            </div>-->
                                            <!-- <div class="col-4 d-flex aling-items-end justify-content-center">
                                                <span class="m-auto">
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col border-end columnas"
                            style="display: inline-block; min-width: 250px; vertical-align: top;">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <div class="me-auto contenedorTitulo">
                                        <span class="titulo">RECORRIDO/COTIZACIÓN</span>
                                    </div>
                                    <div class="ms-auto contenedorNumero">
                                        <span>{{intTotal_recorrido_lead}}</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex mt-2 mb-1">
                                    <div class="degradado3  m-auto"></div>
                                </div>
                                <div class="col-6 col-lg d-flex ope-0">
                                    <span class="me-auto d-flex contenedorTitulo" style="font-size: 12px;"
                                        matTooltip="Total Leads">
                                        <div class="square me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>

                                <div class="col-6 col-lg d-flex">
                                    <span class="ms-auto d-flex contenedorNumero" style="font-size: 12px;"
                                        matTooltip="Total Oportunidades">
                                        <div class="squareOportunidad me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>
                            </div>
                            <div class="row columna-con-scroll p-2">
                                <div class="card card-sin-datos mx-auto px-0"
                                    *ngIf="((arrayRecorrido | filtersearch: filterPost : ['strAgente', 'strNombreEmpresa']).length === 0) ">
                                    <div class="card-header d-flex justify-content-center pt-0">
                                        <span class="text-bold text-sm ">SIN DATOS</span>
                                    </div>
                                </div>
                                <div class="card mx-auto mt-2 px-0 py-0"
                                    [ngClass]="{'cardLead': item.strTipo_leads== 'LEADS', 'cardOportunidad': item.strTipo_leads == 'OPORTUNIDAD'}"
                                    (click)="fnDetalleLead(item, 'RECORRIDO')"
                                    *ngFor="let item of arrayRecorrido | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-12">
                                                <span *ngIf="item.strTipo_leads == 'LEADS'"
                                                    class="fw-bold text-wrap">{{item.strNombreEmpresa}}</span>
                                                <span *ngIf="item.strTipo_leads == 'OPORTUNIDAD'"
                                                    class="fw-bold text-wrap">{{item.strTitulo}}</span>
                                            </div>
                                            <!-- <div class="col-2">
                                                <span class="fw-bold">{{item.strClasificacion}}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row d-flex">
                                            <div class="col-5 mb-2">
                                                <div
                                                    [ngClass]="{'rectangulo': item.strTipo_leads== 'LEADS', 'rectanguloOpo': item.strTipo_leads == 'OPORTUNIDAD'}">
                                                    {{item.strTipo_leads}}
                                                </div>
                                            </div>
                                            <div class="col-5 mb-2" *ngIf="item.strTipo_leads == 'OPORTUNIDAD'">
                                                <div class="rectanguloMonto">
                                                    {{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2" *ngIf="item.strFamilia"
                                                [ngClass]="{'col-7': item.strTipo_leads == 'LEADS', 'col-12': item.strTipoLeads == 'OPORTUNIDAD' }">
                                                <div class="rectanguloFamilia">
                                                    {{item.strFamilia}}
                                                </div>
                                            </div>
                                            <div class="col-6 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-phone-alt mx-1"></i> {{item.strContacto_Nombre}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-user-tag"></i> {{item.strNombreAgente}}
                                                </div>
                                            </div>
                                            <!-- <div class="col-6 mb-2 mx-auto">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-calendar-alt mx-1"></i> Jun 15 - Jun 17
                                        </div>
                                    </div> -->
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span
                                                    class="fw-bold clasificacion me-auto">{{item.strClasificacion}}</span>
                                            </div>
                                            <!--<div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span class="ms-auto" *ngIf="item.strGiro == 'A'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'B'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'C'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'Z' || !item.strGiro">
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                -- <span class="ms-auto">
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro === 'A' || item.strGiro === 'B' || item.strGiro === 'C'), 'sinFondo': (item.strGiro === 'Z' || !item.strGiro)  }"></i>
                                                    <i class="far fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B' || item.strGiro == 'C'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                </span> 
                                            </div>-->
                                            <!-- <div class="col-4 d-flex aling-items-end justify-content-center">
                                                <span class="m-auto">
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col border-end columnas"
                            style="display: inline-block; min-width: 250px; vertical-align: top;">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <div class="me-auto contenedorTitulo">
                                        <span class="titulo">PROPUESTA ECONOMICA</span>
                                    </div>
                                    <div class="ms-auto contenedorNumero">
                                        <span>{{intTotal_propuesta}}</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex mt-2 mb-1">
                                    <div class="degradado4 m-auto"></div>
                                </div>
                                <div class="col-6 col-lg d-flex ope-0">
                                    <span class="me-auto d-flex contenedorTitulo" style="font-size: 12px;"
                                        matTooltip="Total Leads">
                                        <div class="square me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>
                                <div class="col-6 col-lg d-flex">
                                    <span class="ms-auto d-flex contenedorNumero" style="font-size: 12px;"
                                        matTooltip="Total Oportunidades">
                                        <div class="squareOportunidad me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>
                            </div>
                            <div class="row columna-con-scroll p-2">
                                <div class="card card-sin-datos mx-auto px-0" *ngIf="arrayPropuesta.length === 0 ">
                                    <div class="card-header d-flex justify-content-center pt-0">
                                        <span class="text-bold text-sm ">SIN DATOS</span>
                                    </div>
                                </div>
                                <div class="card mx-auto mt-2 px-0 py-0"
                                    [ngClass]="{'cardLead': item.strTipo_leads== 'LEADS', 'cardOportunidad': item.strTipo_leads == 'OPORTUNIDAD'}"
                                    (click)="fnDetalleLead(item, 'PROPUESTA ECONOMICA')"
                                    *ngFor="let item of arrayPropuesta | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-12">
                                                <span *ngIf="item.strTipo_leads == 'LEADS'"
                                                    class="fw-bold text-wrap">{{item.strNombreEmpresa}}</span>
                                                <span *ngIf="item.strTipo_leads == 'OPORTUNIDAD'"
                                                    class="fw-bold text-wrap">{{item.strTitulo}}</span>
                                            </div>
                                            <!-- <div class="col-2">
                                                <span class="fw-bold">{{item.strClasificacion}}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row d-flex">
                                            <div class="col-5 mb-2">
                                                <div
                                                    [ngClass]="{'rectangulo': item.strTipo_leads== 'LEADS', 'rectanguloOpo': item.strTipo_leads == 'OPORTUNIDAD'}">
                                                    {{item.strTipo_leads}}
                                                </div>
                                            </div>
                                            <div class="col-5 mb-2" *ngIf="item.strTipo_leads == 'OPORTUNIDAD'">
                                                <div class="rectanguloMonto">
                                                    {{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2" *ngIf="item.strFamilia"
                                                [ngClass]="{'col-7': item.strTipo_leads == 'LEADS', 'col-12': item.strTipoLeads == 'OPORTUNIDAD' }">
                                                <div class="rectanguloFamilia">
                                                    {{item.strFamilia}}
                                                </div>
                                            </div>
                                            <div class="col-6 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-phone-alt mx-1"></i> {{item.strContacto_Nombre}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-user-tag"></i> {{item.strNombreAgente}}
                                                </div>
                                            </div>
                                            <!-- <div class="col-6 mb-2 mx-auto">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-calendar-alt mx-1"></i> Jun 15 - Jun 17
                                        </div>
                                    </div> -->
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span
                                                    class="fw-bold clasificacion me-auto">{{item.strClasificacion}}</span>
                                            </div>
                                            <!--<div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span class="ms-auto" *ngIf="item.strGiro == 'A'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'B'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'C'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'Z' || !item.strGiro">
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                -- <span class="ms-auto">
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro === 'A' || item.strGiro === 'B' || item.strGiro === 'C'), 'sinFondo': (item.strGiro === 'Z' || !item.strGiro)  }"></i>
                                                    <i class="far fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B' || item.strGiro == 'C'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                </span> 
                                            </div>-->
                                            <!-- <div class="col-4 d-flex aling-items-end justify-content-center">
                                                <span class="m-auto">
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col border-end columnas"
                            style="display: inline-block; min-width: 250px; vertical-align: top;">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <div class="me-auto contenedorTitulo">
                                        <span class="titulo">NEGOCIACIÓN</span>
                                    </div>
                                    <div class="ms-auto contenedorNumero">
                                        <span>{{intTotal_negociacion}}</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex mt-2 mb-1">
                                    <div class="degradado5 m-auto"></div>
                                </div>
                                <div class="col-6 col-lg d-flex ope-0">
                                    <span class="me-auto d-flex contenedorTitulo" style="font-size: 12px;"
                                        matTooltip="Total Leads">
                                        <div class="square me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>

                                <div class="col-6 col-lg d-flex">
                                    <span class="ms-auto d-flex contenedorNumero" style="font-size: 12px;"
                                        matTooltip="Total Oportunidades">
                                        <div class="squareOportunidad me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>
                            </div>
                            <div class="row columna-con-scroll p-2">
                                <div class="card card-sin-datos mx-auto px-0" *ngIf="arrayNegociacion.length === 0">
                                    <div class="card-header d-flex justify-content-center pt-0">
                                        <span class="text-bold text-sm ">SIN DATOS</span>
                                    </div>
                                </div>
                                <div class="card mx-auto mt-2 px-0 py-0"
                                    [ngClass]="{'cardLead': item.strTipo_leads== 'LEADS', 'cardOportunidad': item.strTipo_leads == 'OPORTUNIDAD'}"
                                    (click)="fnDetalleLead(item, 'NEGOCIACION')"
                                    *ngFor="let item of arrayNegociacion | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-12">
                                                <span *ngIf="item.strTipo_leads == 'LEADS'"
                                                    class="fw-bold text-wrap">{{item.strNombreEmpresa}}</span>
                                                <span *ngIf="item.strTipo_leads == 'OPORTUNIDAD'"
                                                    class="fw-bold text-wrap">{{item.strTitulo}}</span>
                                            </div>
                                            <!-- <div class="col-2">
                                                <span class="fw-bold">{{item.strClasificacion}}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row d-flex">
                                            <div class="col-5 mb-2">
                                                <div
                                                    [ngClass]="{'rectangulo': item.strTipo_leads== 'LEADS', 'rectanguloOpo': item.strTipo_leads == 'OPORTUNIDAD'}">
                                                    {{item.strTipo_leads}}
                                                </div>
                                            </div>
                                            <div class="col-5 mb-2" *ngIf="item.strTipo_leads == 'OPORTUNIDAD'">
                                                <div class="rectanguloMonto">
                                                    {{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2" *ngIf="item.strFamilia"
                                                [ngClass]="{'col-7': item.strTipo_leads == 'LEADS', 'col-12': item.strTipoLeads == 'OPORTUNIDAD' }">
                                                <div class="rectanguloFamilia">
                                                    {{item.strFamilia}}
                                                </div>
                                            </div>
                                            <div class="col-6 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-phone-alt mx-1"></i> {{item.strContacto_Nombre}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-user-tag"></i> {{item.strNombreAgente}}
                                                </div>
                                            </div>
                                            <!-- <div class="col-6 mb-2 mx-auto">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-calendar-alt mx-1"></i> Jun 15 - Jun 17
                                        </div>
                                    </div> -->
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span
                                                    class="fw-bold clasificacion me-auto">{{item.strClasificacion}}</span>
                                            </div>
                                            <!-- <div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span class="ms-auto" *ngIf="item.strGiro == 'A'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'B'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'C'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'Z' || !item.strGiro">
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                 -- <span class="ms-auto">
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro === 'A' || item.strGiro === 'B' || item.strGiro === 'C'), 'sinFondo': (item.strGiro === 'Z' || !item.strGiro)  }"></i>
                                                    <i class="far fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B' || item.strGiro == 'C'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                </span>  --
                                            </div>-->
                                            <!-- <div class="col-4 d-flex aling-items-end justify-content-center">
                                                <span class="m-auto">
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col border-end columnas"
                            style="display: inline-block; min-width: 250px; vertical-align: top;">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <div class="me-auto contenedorTitulo">
                                        <span class="titulo">GANADOS</span>
                                    </div>
                                    <div class="ms-auto contenedorNumero">
                                        <span>{{intTotal_ganados}}</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex mt-2 mb-1">
                                    <div class="degradado6 m-auto"></div>
                                </div>
                                <div class="col-6 col-lg d-flex ope-0">
                                    <span class="me-auto d-flex contenedorTitulo" style="font-size: 12px;"
                                        matTooltip="Total Leads">
                                        <div class="square me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>

                                <div class="col-6 col-lg d-flex">
                                    <span class="ms-auto d-flex contenedorNumero" style="font-size: 12px;"
                                        matTooltip="Total Oportunidades">
                                        <div class="squareOportunidad me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>
                            </div>
                            <div class="row columna-con-scroll p-2">
                                <div class="card card-sin-datos mx-auto px-0"
                                    *ngIf="((arrayGanados | filtersearch: filterPost : ['strAgente', 'strNombreEmpresa']).length === 0) ">
                                    <div class="card-header d-flex justify-content-center pt-0">
                                        <span class="text-bold text-sm ">SIN DATOS</span>
                                    </div>
                                </div>
                                <div class="card mx-auto mt-2 px-0 py-0"
                                    [ngClass]="{'cardLead': item.strTipo_leads== 'LEADS', 'cardOportunidad': item.strTipo_leads == 'OPORTUNIDAD'}"
                                    (click)="fnDetalleLead(item, 'GANADO')"
                                    *ngFor="let item of arrayGanados | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-12">
                                                <span *ngIf="item.strTipo_leads == 'LEADS'"
                                                    class="fw-bold text-wrap">{{item.strNombreEmpresa}}</span>
                                                <span *ngIf="item.strTipo_leads == 'OPORTUNIDAD'"
                                                    class="fw-bold text-wrap">{{item.strTitulo}}</span>
                                            </div>
                                            <!--  <div class="col-2">
                                                <span class="fw-bold">{{item.strClasificacion}}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row d-flex">
                                            <div class="col-5 mb-2">
                                                <div
                                                    [ngClass]="{'rectangulo': item.strTipo_leads== 'LEADS', 'rectanguloOpo': item.strTipo_leads == 'OPORTUNIDAD'}">
                                                    {{item.strTipo_leads}}
                                                </div>
                                            </div>
                                            <div class="col-5 mb-2" *ngIf="item.strTipo_leads == 'OPORTUNIDAD'">
                                                <div class="rectanguloMonto">
                                                    {{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2" *ngIf="item.strFamilia"
                                                [ngClass]="{'col-7': item.strTipo_leads == 'LEADS', 'col-12': item.strTipoLeads == 'OPORTUNIDAD' }">
                                                <div class="rectanguloFamilia">
                                                    {{item.strFamilia}}
                                                </div>
                                            </div>
                                            <div class="col-6 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-phone-alt mx-1"></i> {{item.strContacto_Nombre}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-user-tag"></i> {{item.strNombreAgente}}
                                                </div>
                                            </div>
                                            <!-- <div class="col-6 mb-2 mx-auto">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-calendar-alt mx-1"></i> Jun 15 - Jun 17
                                        </div>
                                    </div> -->
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span
                                                    class="fw-bold clasificacion me-auto">{{item.strClasificacion}}</span>
                                            </div>
                                            <!--<div class="col-6 d-flex aling-items-center justify-content-center">
                                                <span class="ms-auto" *ngIf="item.strGiro == 'A'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'B'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'C'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'Z' || !item.strGiro">
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                -- <span class="ms-auto">
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro === 'A' || item.strGiro === 'B' || item.strGiro === 'C'), 'sinFondo': (item.strGiro === 'Z' || !item.strGiro)  }"></i>
                                                    <i class="far fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                    <i class="fas fa-star fs-6" [ngClass]="{'amarilla': (item.strGiro == 'A' || item.strGiro == 'B' || item.strGiro == 'C'), 'sinFondo': (item.strGiro == 'Z')  }"></i>
                                                </span> --
                                            </div>-->
                                            <!-- <div class="col-4 d-flex aling-items-end justify-content-center">
                                                <span class="m-auto">
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                    <i class="fas fa-dollar-sign pesos fw-bold"></i>
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col border-end columnas"
                            style="display: inline-block; min-width: 250px; vertical-align: top;">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <div class="me-auto contenedorTitulo">
                                        <span class="titulo">PERDIDOS</span>
                                    </div>
                                    <div class="ms-auto contenedorNumero">
                                        <span>{{intTotal_Perdido}}</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex mt-2 mb-1">
                                    <div class="degradado7 m-auto"></div>
                                </div>
                                <div class="col-6 col-lg d-flex ope-0">
                                    <span class="me-auto d-flex contenedorTitulo" style="font-size: 12px;"
                                        matTooltip="Total Leads">
                                        <div class="square me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>

                                <div class="col-6 col-lg d-flex">
                                    <span class="ms-auto d-flex contenedorNumero" style="font-size: 12px;"
                                        matTooltip="Total Oportunidades">
                                        <div class="squareOportunidad me-1"></div>
                                        <b>$0.00</b><!--{{0 | currency:'':'symbol':'1.2-2'}}-->
                                    </span>
                                </div>
                            </div>
                            <div class="row columna-con-scroll p-2">
                                <div class="card card-sin-datos mx-auto px-0"
                                    *ngIf="((arrayPerdidos | filtersearch: filterPost : ['strAgente', 'strNombreEmpresa']).length === 0) ">
                                    <div class="card-header d-flex justify-content-center pt-0">
                                        <span class="text-bold text-sm ">SIN DATOS</span>
                                    </div>
                                </div>
                                <div class="card mx-auto mt-2 px-0 py-0"
                                    [ngClass]="{'cardLead': item.strTipo_leads== 'LEADS', 'cardOportunidad': item.strTipo_leads == 'OPORTUNIDAD'}"
                                    *ngFor="let item of arrayPerdidos | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                    <div class="card-header" (click)="fnDetalleLead(item, 'PERDIDO')">
                                        <div class="row">
                                            <div class="col-12">
                                                <span *ngIf="item.strTipo_leads == 'LEADS'"
                                                    class="fw-bold text-wrap">{{item.strNombreEmpresa}}</span>
                                                <span *ngIf="item.strTipo_leads == 'OPORTUNIDAD'"
                                                    class="fw-bold text-wrap">{{item.strTitulo}}</span>
                                            </div>
                                            <!-- <div class="col-2">
                                                <span class="fw-bold">{{item.strClasificacion}}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="card-body" (click)="fnDetalleLead(item, 'PERDIDO')">
                                        <div class="row d-flex">
                                            <div class="col-5 mb-2">
                                                <div
                                                    [ngClass]="{'rectangulo': item.strTipo_leads== 'LEADS', 'rectanguloOpo': item.strTipo_leads == 'OPORTUNIDAD'}">
                                                    {{item.strTipo_leads}}
                                                </div>
                                            </div>
                                            <div class="col-5 mb-2" *ngIf="item.strTipo_leads == 'OPORTUNIDAD'">
                                                <div class="rectanguloMonto">
                                                    {{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2" *ngIf="item.strFamilia"
                                                [ngClass]="{'col-7': item.strTipo_leads == 'LEADS', 'col-12': item.strTipoLeads == 'OPORTUNIDAD' }">
                                                <div class="rectanguloFamilia">
                                                    {{item.strFamilia}}
                                                </div>
                                            </div>
                                            <div class="col-6 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-phone-alt mx-1"></i> {{item.strContacto_Nombre}}
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="rectanguloSinFondo">
                                                    <i class="fas fa-user-tag"></i> {{item.strNombreAgente}}
                                                </div>
                                            </div>
                                            <!-- <div class="col-6 mb-2 mx-auto">
                                        <div class="rectanguloSinFondo">
                                            <i class="fas fa-calendar-alt mx-1"></i> Jun 15 - Jun 17
                                        </div>
                                    </div> -->
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row d-flex justify-content-between">
                                            <div class="col-4 d-flex aling-items-center justify-content-center">
                                                <span
                                                    class="fw-bold clasificacion me-auto">{{item.strClasificacion}}</span>
                                            </div>
                                            <div class="col-4 d-flex">
                                                <button class="btn btn-danger m-auto" (click)="fnPreguntaEliminar(item)"
                                                    *ngIf="item.strTipo_leads == 'OPORTUNIDAD'">
                                                    <i class="bi bi-trash3-fill"></i>
                                                </button>
                                            </div>

                                            <!--<div class="col-4 d-flex aling-items-center justify-content-center">
                                                <span class="ms-auto" *ngIf="item.strGiro == 'A'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'B'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="fas fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'C'">
                                                    <i class="fas fa-star fs-6 amarilla"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                                <span class="ms-auto" *ngIf="item.strGiro == 'Z' || !item.strGiro">
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                    <i class="far fa-star fs-6 sinFondo"></i>
                                                </span>
                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="banderaLista">
            <div class="col-12 row">
                <div class="todo-container">
                    <div class="todo-header">
                        <h2 style="text-decoration: underline; color: #000000DE; font-weight: 600;">Listado Actividades
                        </h2>
                    </div>
                    <div class="col-12 d-flex" *ngIf="arrayListaActividades.length == 0">
                        <div class="card m-auto">
                            <div class="card-header d-flex">
                                <span class="fw-bold m-auto">SIN ACTIVIDADES</span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngFor="let item of arrayListaActividades; let i = index">
                        <div class="date-indicator aling-items-center justify-content-center mt-4">
                            <h3 class="fw-bold text-dark mx-3">{{ (item.fecha)? getDayOfWeek(item.fecha.split(' ')[0]):
                                ''}}</h3>
                            <h3 class="fw-bold text-dark mx-3 ms-auto">{{item.fecha.split(' ')[0]}}</h3>
                        </div>
                        <div class="col-12 mb-3">
                            <hr>
                        </div>
                        <div class="todo-item d-flex justify-content-center aling-items-center"
                            *ngFor="let act of item.arrayActividades; let i = index" (click)="fnmDLConAct(act)"
                            data-bs-toggle="modal" data-bs-target="#MConcluirActividades">
                            <label class="containers d-flex"
                                *ngIf="act.strEstatus != 'POR AUTORIZAR' && act.strEstatus != 'PROCESO'">
                                <input type="checkbox" [disabled]="true" [checked]="act.strEstatus === 'CONCLUIDO'"
                                    class="d-none"> <!--checked="checked"-->
                                <div class="checkmark"></div>
                                &nbsp; &nbsp; &nbsp;
                            </label>
                            <div class=" text-center" *ngIf="act.strEstatus == 'POR AUTORIZAR'">
                                <button type="button" class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                        class="fa-solid fa-bell"></i></button>
                            </div>
                            <div class=" text-center" *ngIf="act.strEstatus == 'PROCESO'">
                                <button type="button" class="btn btn-outline-info px-2 py-1 shadow-sm"
                                    title="En Proceso"><i class="fa-solid fa-circle-half-stroke"></i></button>
                            </div>
                            <div class="todo-text d-flex">
                                <span style="text-decoration: none !important;">
                                    <i class="fas fa-file-signature mr-auto me-2 iconos"
                                        *ngIf="act.strTipo == 'Minuta'"></i>
                                    <i class="fas fa-envelope mr-auto me-2 correo iconos"
                                        *ngIf="act.strTipo == 'Correo'"></i>
                                    <i class="fas fa-phone-alt mr-auto me-2 iconos"
                                        *ngIf="act.strTipo == 'Llamada'"></i>
                                    <i class="fas fa-walking mr-auto me-2 iconos" *ngIf="act.strTipo == 'Visita'"></i>
                                    {{act.strTipo}} <br>
                                    <span class="fw-bold">({{act.strAgente}})</span>{{act.strNombreAgente}}</span>
                                <span class="agent-info m-auto text-center"
                                    [ngClass]="{'subrayado': act.strEstatus == 'CONCLUIDO'}">{{act.strTitulo}} <br>
                                    {{act.strAsunto}}</span>
                            </div>
                            <div class="todo-time "> <span
                                    style="font-weight: 900 !important; color: #1478aa">{{act.strHoraInicio.split(':')[0]}}:{{act.strHoraInicio.split(':')[1]}}
                                    - {{act.strHoraFin.split(':')[0]}}:{{act.strHoraFin.split(':')[1]}}</span></div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="col-12 d-flex">
            <full-calendar style="width: 90%; background: white !important;" class="m-auto" id="calendar" #calendar
                [options]="calendarOptions"></full-calendar>
        </div> -->
        </div>
    </div>

    <div class="modal fade" id="nuevoLead" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header d-flex img-fondo">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-10 d-flex">
                                <h2 class="m-auto fw-bold">NUEVO PROSPECTO</h2>
                            </div>
                            <div class="col-2">
                                <button type="button" class="close" data-bs-dismiss="modal" #btncerrar
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body pt-0 color-body-modal">
                    <div class="container-fluid">
                        <form [formGroup]="myForm"> <!-- (ngSubmit)="onSave()" -->
                            <div class="row mt-3">
                                <div class="col-md-6 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11">
                                        <span class="text-danger">*</span> Nombre Empresa:
                                    </span>
                                    <input type="text" placeholder="Ingresa Nombre empresa" class="form-control"
                                        formControlName="strNombreEmpresa">
                                    <mat-error
                                        *ngIf="myForm.get('strNombreEmpresa').hasError('required') && myForm.get('strNombreEmpresa').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('strNombreEmpresa').hasError('maxlength')"
                                        class="fs10">
                                        son como maximo 100 caracteres</mat-error>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11">
                                        <span class="text-danger">*</span> Nombre Corto:
                                    </span>
                                    <input type="text" placeholder="Ingresa Nombre Corto" class="form-control"
                                        formControlName="strNombreCorto">
                                    <mat-error
                                        *ngIf="myForm.get('strNombreCorto').hasError('required') && myForm.get('strNombreCorto').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('strNombreCorto').hasError('maxlength')" class="fs10">
                                        son como maximo 60 caracteres</mat-error>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-2"
                                    [ngClass]="{'col-md-6': arraySubGiro.length < 2, 'col-md-4': arraySubGiro.length > 1}">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Sector :
                                    </span>
                                    <select name="sector" id="sector" class="form-select" formControlName="strSector">
                                        <option selected value="">-Selecciona una opcion-</option>
                                        <option [value]="s.strSector" *ngFor="let s of arraySectores; let i = index">
                                            {{s.strSector}} ({{s.strClasificacion}})</option>
                                    </select>
                                    <mat-error
                                        *ngIf="myForm.get('strSector').hasError('required') && myForm.get('strSector').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('strSector').hasError('maxlength')"
                                        class="fs10">
                                        son
                                        como maximo 20 caracteres</mat-error>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-2"
                                    [ngClass]="{'col-md-6': arraySubGiro.length < 2, 'col-md-4': arraySubGiro.length > 1}">
                                    <span class="m-auto fw-bold fs11"><span class="text-danger">*</span> Giro: </span>
                                    <select name="giro" id="giro" class="form-select" formControlName="intId_Giro"
                                        ><!--(change)="fnFiltrarSegundaLetra()"-->
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option *ngFor="let item of arrayGiros; let i = index"
                                            [value]="item.intId_Giro">
                                            {{item.strGiro}}
                                        </option>
                                    </select><!--[value]="item.intId_Giro + ',' + item.strClasificacion"-->
                                    <mat-error
                                        *ngIf="myForm.get('intId_Giro').hasError('required') && myForm.get('intId_Giro').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <!-- <div class="col-md-4 col-sm-12 d-block p-2" *ngIf="arraySubGiro.length > 1">
                                    <span class="m-auto fw-bold fs11"><span class="text-danger">*</span> SubGiro:
                                    </span>
                                    <select name="giro" id="giro" class="form-select" formControlName="intId_subGiro">
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option *ngFor="let item of arraySubGiro; let i = index"
                                            [value]="item.intId_subGiro">
                                            {{item.strTitulo}}
                                        </option>
                                    </select>
                                    <mat-error
                                        *ngIf="myForm.get('intId_subGiro').hasError('required') && myForm.get('intId_subGiro').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div> -->
                                <div class="col-md-4 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Presencia en el
                                        pais: </span>
                                    <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                        formControlName="intIDPresencia"><!--(change)="onGiroChange($event, 'presencia')" (change)="fnFiltrarSegundaLetra()"-->
                                        <option value="0" selected>-Selecciona una opcion-</option>
                                        <option [value]="item.intIDPresencia"
                                            *ngFor="let item of arrayPresencia; let i = index">
                                            {{item.strPresencia}}
                                        </option>
                                    </select>
                                    <mat-error
                                        *ngIf="myForm.get('intIDPresencia').hasError('required') && myForm.get('intIDPresencia').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div>
                                <!-- <div class="col-md-4 col-sm-12 d-block p-2" *ngIf="arrayFitroSegunda.length > 0">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Numero de Habitaciones
                                    </span>
                                    <input type="text" class="form-control" formControlName="intId_segunda"
                                        placeholder="Ingresa Cantidad de habitaciones">
                                    <mat-error
                                        *ngIf="myForm.get('intId_segunda').hasError('required') && myForm.get('intId_segunda').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div> -->
                                <!-- <div class="col-md-4 col-sm-12 d-block p-2" *ngIf="arrayFiltroTercera.length > 0">
                                    <span class="fw-bold fs11 m-auto">
                                        <span class="text-danger">*</span> Numero de Ubicaciones
                                    </span>
                                    <input type="text" class="form-control" formControlName="intId_tercera"
                                        placeholder="Ingresa Cantidad de Ubicaciones">
                                    <mat-error
                                        *ngIf="myForm.get('intId_tercera').hasError('required') && myForm.get('intId_tercera').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                </div> -->

                                <!-- <div class="col-md-6 col-sm-12 d-block p-2" *ngIf="intPotenciador == 1">
                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Lineas:</span>
                                    <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Seleccione:
                                    </button>
                                    <ul class="dropdown-menu w-25">
                                        <li *ngFor="let j of arrayLineas">
                                            <div class="form-check ms-1">
                                                <input class="form-check-input" [checked]="j.Seleccionado"
                                                    type="checkbox" [value]="j.intID_linea"
                                                    (change)="toggleMultiple(j.strLinea)" id="flexCheckDefault">
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    {{j.strLinea}}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngFor="let select of arrayLineas; let i = index">
                                        <span *ngIf="select.Seleccionado"
                                            class="badge bg-primary text-white p-1 mb-2 mx-1">{{select.strLinea}}</span>
                                    </ng-container>
                                </div> -->
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-4 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11"><span class="text-danger">*</span> C. P.</span>
                                    <input type="text" placeholder="ejemp:36213, 40660, etc.." class="form-control"
                                        (keydown)="onKeyDown($event)" (keypress)="validarNumeros($event)"
                                        (blur)="blurevent()" formControlName="strCodigoPostal">
                                    <mat-error
                                        *ngIf="myForm.get('strCodigoPostal').hasError('required') && myForm.get('strCodigoPostal').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('strCodigoPostal').hasError('maxlength')" class="fs10">
                                        son
                                        como maximo 5 caracteres</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2">
                                    <span class=" m-auto fw-bold fs11"><span class="text-danger">*</span> Estado</span>
                                    <input type="text" placeholder="Ejemp:Guanajuato" class="form-control"
                                        formControlName="strEstado" readonly>
                                    <mat-error *ngIf="myForm.get('strEstado').hasError('required')" class="fs10">Inserte
                                        c.p. y presione enter</mat-error>
                                    <mat-error *ngIf="myForm.get('strEstado').hasError('maxlength')" class="fs10"> son
                                        como
                                        maximo 100 caracteres</mat-error>
                                </div>
                                <div class="col-md-4 col-sm-12 d-block p-2">
                                    <span class="fw-bold fs11"><span class="text-danger">*</span> Ciudad </span>
                                    <input type="text" placeholder="ejemp:Leon, Cd. Victoria, Guadalajara, etc."
                                        class="form-control" formControlName="strDelegacion" readonly>
                                    <mat-error *ngIf="myForm.get('strDelegacion').hasError('required')"
                                        class="fs10">Inserte
                                        c.p. y presione enter</mat-error>
                                    <mat-error *ngIf="myForm.get('strDelegacion').hasError('maxlength')" class="fs10">
                                        son
                                        como maximo 100 caracteres</mat-error>
                                </div>
                            </div> -->
                            <div class="row mt-4 card-contacto p-3 justify-content-center ">
                                <div class="col-12">
                                    <h2 class="mb-0 fw-bold"> Información de Contacto <i class="fa fa-trash fa-xs"
                                            *ngIf="myForm.get('intID_Contacto').value !== 0" aria-hidden="true"
                                            (click)="resetForm()"></i> </h2>
                                </div>

                                <input type="text" formControlName="intID_Contacto" value="0" style="display: none;">

                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">
                                        Nombre:
                                    </span>
                                    <input type="text" placeholder="Nombre Contacto" class="form-control ms-auto"
                                        formControlName="strContacto_Nombre" (keydown)="fnBuscarContacto1($event)"
                                        (keypress)="fnBuscarContacto1($event)">
                                    <ul *ngIf="arrayAsigContacto1.length > 0" class="list-group mt-1 overflowy">
                                        <li class="list-group-item cursorPoint hver py-1 fs10"
                                            *ngFor="let contacto of arrayAsigContacto1"
                                            (click)="fnselecContacto(contacto)">
                                            {{contacto.strContacto_Nombre}} - {{ "<"+contacto.strContacto_Email+">"}} -
                                                {{ "("+contacto.strContacto_Telefono+")" }}
                                        </li>
                                    </ul>
                                    <mat-error
                                        *ngIf="myForm.get('strContacto_Nombre').hasError('required') && myForm.get('strContacto_Nombre').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('strContacto_Nombre').hasError('maxlength')"
                                        class="fs10">
                                        son como maximo 50 caracteres
                                    </mat-error>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">
                                        Puesto:</span> <br>
                                    <input type="text" placeholder="Puesto Contacto" class="form-control  m-auto"
                                        formControlName="strContacto_Puesto">
                                    <mat-error
                                        *ngIf="myForm.get('strContacto_Puesto').hasError('required') && myForm.get('strContacto_Puesto').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('strContacto_Puesto').hasError('maxlength')"
                                        class="fs10">
                                        son como maximo 50 caracteres</mat-error>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">
                                        Telefono:</span>
                                    <input type="text" placeholder="Telefono Contacto" class="form-control ms-auto"
                                        formControlName="strContacto_Telefono" [maxlength]="10"
                                        (keydown)="fnBuscarContacto2($event)" (keypress)="fnBuscarContacto2($event)">
                                    <ul *ngIf="arrayAsigContacto2.length > 0" class="list-group mt-1 overflowy">
                                        <li class="list-group-item cursorPoint hver py-1 fs10"
                                            *ngFor="let contacto of arrayAsigContacto2"
                                            (click)="fnselecContacto(contacto)">
                                            {{contacto.strContacto_Nombre}} - {{ "<"+contacto.strContacto_Email+">"}} -
                                                {{ "("+contacto.strContacto_Telefono+")" }}
                                        </li>
                                    </ul>
                                    <mat-error
                                        *ngIf="myForm.get('strContacto_Telefono').hasError('required') && myForm.get('strContacto_Telefono').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('strContacto_Telefono').hasError('maxlength')"
                                        class="fs10"> son como maximo 10 caracteres</mat-error>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm"> E-mail
                                    </span> <br>
                                    <input type="email" placeholder="Email Contacto" class="form-control  m-auto"
                                        formControlName="strContacto_Email" (keydown)="fnBuscarContacto3($event)"
                                        (keypress)="fnBuscarContacto3($event)">
                                    <ul *ngIf="arrayAsigContacto3.length > 0" class="list-group mt-1 overflowy">
                                        <li class="list-group-item cursorPoint hver py-1 fs10"
                                            *ngFor="let contacto of arrayAsigContacto3"
                                            (click)="fnselecContacto(contacto)">
                                            {{contacto.strContacto_Nombre}} - {{ "<"+contacto.strContacto_Email+">"}} -
                                                {{ "("+contacto.strContacto_Telefono+")" }}
                                        </li>
                                    </ul>
                                    <mat-error
                                        *ngIf="myForm.get('strContacto_Email').hasError('required') && myForm.get('strContacto_Email').touched"
                                        class="fs10">Campo Obligatorio</mat-error>
                                    <mat-error *ngIf="myForm.get('strContacto_Email').hasError('maxlength')"
                                        class="fs10">
                                        son como maximo 50 caracteres</mat-error>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer img-fondo d-flex">
                    <button class="btn btn-primary me-auto" data-bs-dismiss="modal" (click)="onSave()" type="submit"
                        [disabled]="!isFormValid()">Enviar</button>
                </div>
            </div>
        </div>
    </div>

    <!--Modal Concluir Actividades-->
    <div class="modal fade" id="MConcluirActividades" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bgtitModal text-center d-flex">
                    <h1 class="modal-title fs-5 m-auto" id="exampleModalLabel">Origen: <span
                            class="fw-bold">{{objDetActividad?.intID_Actividad}} .-
                            {{objDetActividad?.strTitulo}}</span></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-6">
                            <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                <span>Titulo</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strTitulo}}</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                <span>Asunto</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strAsunto}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-11"
                            *ngIf="objAct?.strEstatus=='CONCLUIDO' || objAct?.strEstatus=='POR AUTORIZAR'">
                            <div class="row">

                                <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                    <span class="float-end">Resultado</span>
                                    <span
                                        class="py-1 px-2 bg-light float-end rounded shadow-sm">{{objDetActividad?.strResultado}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3">
                                    <span>Obervaciones</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objDetActividad?.strObservaciones"></span>
                                </div>
                                <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                    <span>Hora Inicio</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strHoraInicio}}</span>
                                </div>
                                <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                    <span>Hora Fin</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strHoraFin}}</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-12 h-100 mb-2"
                            [ngClass]="{'visually-hidden':objAct?.strEstatus == 'CONCLUIDO' || objAct?.strEstatus=='POR AUTORIZAR'}">
                            <div class="form-group h-100">
                                <textarea [(ngModel)]="contenido" class="custom-summernote h-100 summernote"
                                    id="summernote" #summernote></textarea>
                            </div>
                        </div>
                        <div class="col-12 d-flex mt-2"
                            *ngIf="objAct?.strEstatus=='PENDIENTE'  || objAct?.strEstatus=='PROCESO'">
                            <select name="accioness" id="acciones" class="form-select m-auto" [(ngModel)]="strResultado"
                                [disabled]="objAct?.strEstatus=='CONCLUIDO' ">
                                <option value="">
                                    --Selecciona
                                    una opción--</option>
                                <option value="1">
                                    EXITOSO</option>
                                <option value="2">
                                    SIN EXITO
                                </option>
                            </select>
                        </div>
                        <div class="col-12 d-flex mt-3">
                            <button type="button" class="btn btn-secondary btncerrarModal" data-bs-dismiss="modal"
                                #btnCerrarAct>{{objAct?.strEstatus=='PENDIENTE'?'Cancelar':'Cerrar'}} </button>
                            <button class="btn btn-primary  text-white ms-auto mt-auto" (click)="fnConcluirActividad()"
                                *ngIf="objAct?.strEstatus=='PENDIENTE' || objAct?.strEstatus=='PROCESO'"
                                data-bs-dismiss="modal">Concluir</button>
                            <button type="button" class="btn btn-info  ms-auto" *ngIf="objAct?.strEstatus=='PENDIENTE' "
                                (click)="fnEnProceso(objAct)">En Proceso</button>
                            <button type="button" class="btn btn-success  ms-auto"
                                *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea "
                                (click)="fnAutorizar(objAct)">Autorizar</button>

                            <button type="button" class="btn btn-danger  ms-auto"
                                *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea "
                                (click)="fnRechazar(objAct)">Rechazar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>