 
import {AfterViewInit, Component, ViewChild, OnInit, ElementRef} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];
@Component({
  selector: 'app-ecommerce-pedidos',
  templateUrl: './ecommerce-pedidos.component.html',
  styleUrls: ['./ecommerce-pedidos.component.css']
})
export class EcommercePedidosComponent implements OnInit ,AfterViewInit{
  displayedColumns: string[] = ['position',  'fecha', 'weight','cliente', 'telefono', 'correo', 'origen_pago', 'estatus_pago','referencia_pago','idPedidoIntel', 'detalle', 'eliminar'];
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSource = new MatTableDataSource<any>(); // Fuente de datos para la tabla
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public isHoveredButton:boolean=true;
  public objPedido:any={};
  public arrayPedidos:any=[];
  public arrayPedidos_original:any=[];
  private strConexion:string;
  public strfechaRequerida: string;
  public strReferencia: string = "";
  public strObservaciones: string = "";
  public strOrdenCompra: string = "";
  public strComentarios: string = ""; 
  public strUsuarioInt:string=""; 
  public isLoading:boolean=false;
  public strBuscarCte:string='';
  public objCte:any={};
  public arrayPeriodo:any= [];
  public arrayEjercicio:any=[];
  public strEstatus:string='';
  public arrayEstatus: any=[];
  public intEjercicio:number=0;
  public intPeriodo:number; 
  @ViewChild('btnCerrarModalPedido') btnCerrarModalPedido!: ElementRef;
  constructor(private indexedDBService: DataDBService,elem:ElementRef, private localSt:LocalStorageService,private _httpService: PeticionesService,private route: ActivatedRoute,public _alertify: AlertifyService, private _filtroServ :FiltrosService) { }

  ngOnInit(): void {
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.arrayEstatus = [{strEstatus: 'PAGADO'}, {strEstatus: 'PENDIENTE'}, {strEstatus: 'PROCESO'},{strEstatus: 'NEW'} ,{strEstatus:  'ACTIVE'}];
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.strConexion = this.localSt.retrieve('strConexion');
      this.fnGetPedidos();
      this.strBuscarCte='WECE1';
      this.fnBuscarCliente(this.strBuscarCte);
      this.fnGetFiltros()
    })
    
  }
 ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  }
  fnBuscarCliente(cliente:string){
    let objBuscar = {
      strAccion: "getDataCte", 
      strConexion: this.strConexion,
      strCliente:cliente
    };
    this._httpService.mostrarCotizaciones(objBuscar, 'sp_ECommerce').subscribe(
      result => {
       console.log(result)
       if(result[0]){
        this.objCte=result[0];
       }
       else {
        this.objCte={};
        Swal.fire({
          icon:   'error',
          title: 'Error',
          text: 'No se encontró información relacionada al cliente seleccionado',
          confirmButtonText: 'Cerrar'
        });
       }
      },error=>{
        console.log(error.message) 
      });
  }
  fnGetPedidos(){
 
    this.arrayPedidos = [];
    let articulo = {
      strAccion: "getPedidos", 
      strConexion: this.strConexion,
      intEjercicio:this.intEjercicio,
      intPeriodo:this.intPeriodo
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => { 
       this.arrayPedidos_original = result;
        if(result){
         let uniquePedidos=[]
           result.forEach(element => {
            let objB = uniquePedidos.find(o=>o.intID_Pedido ==element.intID_Pedido)
            if(!objB){
              uniquePedidos.push(element)
            }
            
          });
          this.arrayPedidos =uniquePedidos;
           this.dataSource.data = this.arrayPedidos;
        }
      
        
      
     
      },error=>{
        console.log(error.message) 
      });
  }
  fnGetDetalle(intID_Pedido:number){
    this.objPedido={}
    this.objPedido.arrayArticulos=[];
    let arrayArticulos = this.arrayPedidos_original.filter(o=>o.intID_Pedido== intID_Pedido);
    this.objPedido=arrayArticulos[0]
    this.objPedido.arrayArticulos=arrayArticulos;
    let total =0
    arrayArticulos.forEach(element => {
      total=total + (element.dblPrecioLista * element.dblCantidad)
    });
    this.objPedido.total=total;
  }
  setPedido(){
    this.isLoading = true;
    let strIDSucursal =null; // this.objCliente.intIDSucursal > 0 ? this.objCliente.intIDSucursal : null;
    let strCliente =  this.objCte.strCliente;//'WECE1'; 
    let strSucursal =  '60';
    let agente =  '599';
    let strCondicion = 'PContado'//this.objCliente.strCondicion;
    let alm =  '1';
    this.strComentarios = this.objPedido.strDireccion +', col. '+ this.objPedido.strColonia + '. '+ this.objPedido.strCiudad+', '+ this.objPedido.strEstado+', '+ this.objPedido.strPais +'. cp.'+ this.objPedido.strCodigoPostal
    this.strObservaciones= this.objPedido.strDireccion +', col. '+ this.objPedido.strColonia + '. '+ this.objPedido.strCiudad+', '+ this.objPedido.strEstado+', '+ this.objPedido.strPais +'. cp.'+ this.objPedido.strCodigoPostal
    
    this.strOrdenCompra = this.objPedido.intID_Pedido;
    this.strUsuarioInt='ECOMERCE';
    this.strReferencia=this.objPedido.strReferencia_Pago;
    let xmlArticulos = [];
    console.log(this.objPedido.arrayArticulos);
    this.objPedido.arrayArticulos.forEach(element =>{
      console.log( " hay este valor: " + element);
      let precio =(element.dblPrecioLista/1.16)
      xmlArticulos.push({ strArticulo: element.strArticulo, dblPrecio: precio, intCantidad: element.dblCantidad, strOpcion: element.strOpcion });
    });
    
    const fecha: Date = new Date();
    fecha.setDate(fecha.getDate() + 1);
    const dia = fecha.getDate().toString().padStart(2, '0'); // Obtiene el día y lo ajusta al formato 'dd'
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Obtiene el mes (se suma 1 porque los meses son base 0) y lo ajusta al formato 'mm'
    const anio = fecha.getFullYear();
    let strFechaRequerida = dia + '/' + mes + '/' + anio
    console.log("Esta es la fecha requerida que se esta enviando: " + strFechaRequerida);
    console.log("Este es el agente enviando: " + agente);
    console.log("Este es el cliente al que se esta enviando: " + strCliente);
    console.log("Esta es id sucursal que se envia: " + strIDSucursal);
    console.log("Esta es a la sucursal que se envia: " + strSucursal);
    console.log(" referencia que se envia: (50 caracteres)" + this.strReferencia);
    console.log("ordencompra que se envia: " + this.strOrdenCompra);
    console.log("comentarios que se envia: text" + this.strComentarios);
    console.log("observaciones que se envia: (100 caracteres)" + this.strObservaciones);
    console.log("strCondicion que se envia: " + strCondicion);
    console.log("almacen que se envia: " + alm);
    console.log("articulos que se envia: ");
    console.log(xmlArticulos);
 
    this._httpService.setPedido({
      strAccion: 'setPedido',
      strAgente: agente,
      strCliente: strCliente,
      strSucursal: strSucursal,
      xmlArticulos: xmlArticulos,
      strCteEnviarA: strIDSucursal,
      strFechaRequerida: strFechaRequerida,
      strOrdenCompra: this.strOrdenCompra,
      strReferencia: this.strReferencia,
      strObservaciones: this.strObservaciones,
      strComentarios: this.strComentarios,
      strSistema: 'TIENDA_ECODELI',
      strCondicion: strCondicion,
      strAlmacen: alm,
    //  strArchivo_orden_compra: this.nombreOrdenCompra,
      strConexion: this.strConexion,
      strUsuario: this.strUsuarioInt
    }).subscribe(
      result => {
        
        let pruebas = [];
        pruebas = result;
        let code = pruebas.length == 0 ? 410 : pruebas[0] ? pruebas[0].code : 410;
        let msj = pruebas.length == 0 ? 'Ocurrió un error al intentar generar el pedido' : pruebas[0] ? pruebas[0].strMensaje : 'Ocurrió un error al intentar generar el pedido';
        let strMensaje = msj;
        let titulo = code == 409 ? 'Pedido Generado SINAFECTAR' : code == 200 ? 'Pedido Generado Correctamente' : 'Error al Intentar Generar Pedido';
        Swal.fire({
          icon: code == 409 ? 'warning' : code == 200 ? 'success' : 'error',
          title: titulo,
          text: msj,
          confirmButtonText: 'Cerrar'
        });
        console.log(pruebas);
        if (code==200 || code==409){
          this.isLoading = false;
        //recargar pedidos
          this.fnGetPedidos();
        }
        else{
          this.isLoading = false;
        }
         
        this.btnCerrarModalPedido.nativeElement.click();
      },
      error => {
    
        var error = <any>error;
        console.log(error);
        // object.spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'Uuups...',
          text: 'Error en conexion al insertar pedido'
        });
      }
    ); 
  }
  fnEliminarPedido(intID_Pedido:number){
    this.arrayPedidos = [];
    let articulo = {
      strAccion: "deletePedido", 
      strConexion: this.strConexion,
      intID_Pedido: intID_Pedido 
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => {
       console.log(result) 
        if(result[0] && result[0].code == "200"){
          Swal.fire({
                     title: "Eliminado correctamente",
                     text: result[0].strMensaje,
                     icon: "success"
                   });

                   this.fnGetPedidos();
        }
        else {
          Swal.fire({
            title: "Ocurrió un error ",
            text: 'Error al intentar eliminar el pedido',
            icon: "success"
          });
        }
      
        
      
     
      },error=>{
        console.log(error.message) 
      });
  }
}
