import { Injectable } from '@angular/core';
import {PeticionesService} from '../servicios/httpservice';
import {BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import * as moment from 'moment'; // Importa Moment

@Injectable({
  providedIn: 'root'
})
export class FiltrosService {
  public strConexion:string;
    public diferenciaHoras:number;
    /****variables para manejar gerentes divisionales   ******/
    private array_Divisionales_Subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public array_Divisionales_$: Observable<any> = this.array_Divisionales_Subject.asObservable();
    private array_Divisionales:any=[];

    /****variables para manejar gerentes sucursales   ******/
    private array_Sucursales_Subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public array_Sucursales_$: Observable<any> = this.array_Sucursales_Subject.asObservable();
    private array_Sucursales:any=[];

    /****variables para manejar agentes  ******/
    private array_Agentes_Subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public array_Agentes_$: Observable<any> = this.array_Agentes_Subject.asObservable();
    private array_Agentes:any=[];
     /****variables para manejar agentes leads ******/
     private array_Agentes_Leads_Subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
     public array_Agentes_Leads_$: Observable<any> = this.array_Agentes_Leads_Subject.asObservable();
     private array_Agentes_Leads:any=[];
    /****variables para manejar periodos ******/
    private array_Periodos_Subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public array_Periodos_$: Observable<any> = this.array_Periodos_Subject.asObservable();
    private array_Periodos:any=[];

    /****variables para manejar ejercicios  ******/
    private array_Ejercicios_Subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public array_Ejercicios_$: Observable<any> = this.array_Ejercicios_Subject.asObservable();
    private array_Ejercicios:any=[];
 /****variables para manejar datos de usuario ******/
 private usuario_Subject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
 public usuario_$: Observable<any> = this.usuario_Subject.asObservable();
 private usuario:any=[];

  constructor(private _http:PeticionesService, private localSt:LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion')?this.localSt.retrieve('strConexion'):'COMERCIAL';
    this.array_Ejercicios=[{ejercicio:2019},{ejercicio:2020},{ejercicio:2021},{ejercicio:2022},{ejercicio:2023},{ejercicio:2024},{ejercicio:2025},{ejercicio:2026}];
    this.array_Periodos=[
      {strNombre:'Enero',strPeriodo:1},
      {strNombre:'Febrero',strPeriodo:2},
      {strNombre:'Marzo',strPeriodo:3},
      {strNombre:'Abril',strPeriodo:4},
      {strNombre:'Mayo',strPeriodo:5},
      {strNombre:'Junio',strPeriodo:6},
      {strNombre:'Julio',strPeriodo:7},
      {strNombre:'Agosto',strPeriodo:8},
      {strNombre:'Septiembre',strPeriodo:9},
      {strNombre:'Octubre',strPeriodo:10},
      {strNombre:'Noviembre',strPeriodo:11},
      {strNombre:'Diciembre',strPeriodo:12},
    ];
    this.fnActualizarPeriodo_Ejercicio();
    this.fnGetAgentes();
    this.fnGetSucursales();
    this.fnGetGerentes();
    this.fnGetAgentes_Leads();
  }
   
fnSet_InfoUsuario(result:any,selectEmpresa){
   
}
 fnGet_InfoUsuario(){
  this.usuario =this.localSt.retrieve('array_usuario');
  this.localSt.store('array_usuario',this.usuario);
  this.usuario_Subject.next(this.array_Agentes);
 }

   fnActualizarPeriodo_Ejercicio(){ 
    this.array_Periodos_Subject.next(this.array_Periodos);
    this.array_Ejercicios_Subject.next(this.array_Ejercicios);
   }
   fnGetAgentes(){ 
    this.strConexion =  this.strConexion ?this.strConexion : 'COMERCIAL';
      //revisar si ya existen los agentes 
      let agtExiste = (this.localSt.retrieve('array_Agentes') && this.localSt.retrieve('array_Agentes').length)>0 ?1:0;
      if(agtExiste ==0){ /* console.log('no existe agente') */
      this._http.getEcodeli({strAccion:'getAgentes' , strConexion:this.strConexion}, 'sp_ModVentas_Cotizador').subscribe(
        result => {
    
          this.array_Agentes=result;
          this.localSt.store('array_Agentes',this.array_Agentes);
          this.array_Agentes_Subject.next(this.array_Agentes)
        }), error => {
          console.log('error api agentes')
          var error = <any>error;
          console.log(error);
        }; 
    
      } 
      else{ /* console.log('  existe agente') */
        this.array_Agentes =this.localSt.retrieve('array_Agentes');
        this.localSt.store('array_Agentes',this.array_Agentes);
        this.array_Agentes_Subject.next(this.array_Agentes)
      }
    }
   
    fnGetSucursales (){
      let agtExiste = (this.localSt.retrieve('array_Sucursales') && this.localSt.retrieve('array_Sucursales').length)>0 ?1:0;
      if(agtExiste ==0){ 
       /*  console.log('no existe sucursal') */
      this._http.getEcodeli({strAccion:'getSucursales' , strConexion:this.strConexion}, 'sp_ModVentas_Cotizador').subscribe(
        result => { 
          this.array_Sucursales=result;

          this.array_Sucursales.push()
          this.localSt.store('array_Sucursales',this.array_Sucursales);
          this.array_Sucursales_Subject.next(this.array_Sucursales)
        }), error => {
          console.log('error api sucursal')
          var error = <any>error;
          console.log(error);
        };
    
      } 
      else{   /* console.log('  existe sucursal') */
        this.array_Sucursales =this.localSt.retrieve('array_Sucursales');
        this.array_Sucursales_Subject.next(this.array_Sucursales)
      }
    }
     
    fnGetGerentes (){
      let agtExiste = (this.localSt.retrieve('array_Gerentes') && this.localSt.retrieve('array_Gerentes').length)>0 ?1:0;
      if(agtExiste ==0){/*  console.log('no existe gerente ') */
      let objParamGte = {strAccion:'getGerentes' , strConexion:this.strConexion};
     /*  console.log(objParamGte) */
      this._http.getEcodeli(objParamGte, 'sp_ModVentas_Cotizador').subscribe(
        result => {  /* console.log('  existe gerente ') */
         
          this.array_Divisionales=result;
          this.localSt.store('array_Gerentes',this.array_Divisionales);
          this.array_Divisionales_Subject.next(this.array_Divisionales)
        }), error => {
          console.log('eror api gerentes')
          var error = <any>error;
          console.log(error);
        };
    
      } 
      else{  
        this.array_Divisionales =this.localSt.retrieve('array_Gerentes');
        this.array_Divisionales_Subject.next(this.array_Divisionales)
      }
    }
    fnGetAgentes_Leads(){
      //revisar si ya existen los agentes 
      let agtExiste = (this.localSt.retrieve('array_Agentes_Leads') && this.localSt.retrieve('array_Agentes_Leads').length)>0 ?1:0;
      if(agtExiste ==0){ /* console.log('no existe agente leads') */
      this._http.getEcodeli({strAccion:'getAgentes_Leads' , strConexion:this.strConexion}, 'sp_ModVentas_Cotizador').subscribe(
        result => {
    
          this.array_Agentes_Leads=result;
          this.localSt.store('array_Agentes_Leads',this.array_Agentes_Leads);
          this.array_Agentes_Leads_Subject.next(this.array_Agentes_Leads)
        }), error => {
          console.log('error api agentes leads')
          var error = <any>error;
          console.log(error);
        }; 
    
      } 
      else{ /* console.log('  existe agente') */
        this.array_Agentes_Leads =this.localSt.retrieve('array_Agentes_Leads');
        this.localSt.store('array_Agentes_Leads',this.array_Agentes_Leads);
        this.array_Agentes_Leads_Subject.next(this.array_Agentes_Leads)
      }
    }

}
