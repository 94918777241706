import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';



export interface tareasCte {
  cliente: string;

  fecha: string;
  importe: string;
  diasinstalacion:string;
}
@Component({
  selector: 'app-venta-consigna',
  templateUrl: './venta-consigna.component.html',
  styleUrls: ['./venta-consigna.component.css']
})
export class VentaConsignaComponent implements OnInit {
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;
  datosTareasCte: MatTableDataSource<tareasCte>;
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public arrayAgentes:any=[];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayVentaConsigna:any [];
  public tagName: any;
  public strID :string="";
  public strSituacion :string="";
  public arrayAccesos: any = [];
  public spinnerVtaConsigna:boolean=false;
  columnsTareasCte: string[] = ['cliente', 'fecha', 'importe','diasinstalacion'];
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  constructor( private router: Router,private _httpService:PeticionesService,private localSt: LocalStorageService, elem: ElementRef, private _filtroServ :FiltrosService,private paginatorIntl: MatPaginatorIntl) { 
 /*    this.strConexion = this.localSt.retrieve('strConexion'); */
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos') ? this.localSt.retrieve('arrayAccesos') : [];

    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
      }

    }
    this.customizeItemsPerPageLabel();
  }

  ngOnInit(): void {
    this.fnGetFiltros();
    this.fnGetVentasConsina();
  }

  fnGetFiltros(){
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  //    console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }
  
  fnTableVentasConsigna() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }

  applyFilterVentasConsigna(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }

  fnGetVentasConsina() {
    this.spinnerVtaConsigna =true;
    let obj= {
     'strMov':'VentaConsignacion',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente
    }
    console.log(obj)
    this._httpService.getpagViejitaPlantillas(obj,"getVentasMovimientos").subscribe(
      result => {
        console.log(result)
        this.arrayVentaConsigna = result; 
        this.datosTareasCte = new MatTableDataSource(this.arrayVentaConsigna);
        this.fnTableVentasConsigna();
        this.spinnerVtaConsigna =false;
      },error =>{
        this.spinnerVtaConsigna =false;
        console.log(error.message)
      });
  }
  convertirFecha(fechaString: string): Date | null {
    // Verificar si la fecha es válida
    if (!fechaString) {
      return null;
    }
    
    // Partir el string de fecha por espacio
    const partesFechaHora = fechaString.split(' ');
    
    if (partesFechaHora.length >= 2) {
      const [fecha, hora] = partesFechaHora;
      const [dia, mes, anio] = fecha.split('/');
      
      // Construir el formato de fecha que JavaScript entiende
      const fechaFormateada = `${anio}-${mes}-${dia}T${hora}`;
      const fechaConvertida = new Date(fechaFormateada);
      
      // Validar que la fecha sea válida
      return isNaN(fechaConvertida.getTime()) ? null : fechaConvertida;
    }
    
    return null;
  }
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'items por página';
  }
  desgloceConsignacion(obj) {
    console.log(obj)
    this.strID = obj.strID;//Encrypt.encode(obj.strID);
    this.strSituacion = obj.strSituacion;
    this.router.navigate(['/detalle_pedido/'], { queryParams: { tipo: 'v', strID: this.strID, strSituacion: this.strSituacion } });
    /* location.href = "DesglocePRVC.php?tipo=p&strID=" + this.strID + "&strSituacion=" + this.strSituacion; */
  }


}
