import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-ecommerce-login',
  templateUrl: './ecommerce-login.component.html',
  styleUrls: ['./ecommerce-login.component.css']
})
export class EcommerceLoginComponent implements OnInit {
  loginData = {
    email: '',
    password: ''
  };
  public strPassEncriptado:string;
  public strConexion: string = 'COMERCIAL';
  constructor(private cartService: EcommerceCarrService,private _httpService: PeticionesService,private router: Router) { }

  ngOnInit(): void {
  }
  onLogin(): void {
    // Aquí puedes realizar la lógica de autenticación con tu API
    //console.log('Usuario autenticado:', this.loginData);
    

    let articulo = {
      strAccion: "getLogin",  
      strConexion: this.strConexion,
      strUsuario: this.loginData.email ,
      strPassword:this.strPassEncriptado// this.loginData.password 
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => {
       // console.log(result);
        if(result[0] && result[0].code==200){
          Swal.fire({
            icon: "success",
            title: "Bienvenido " +result[0].strNombre  
          });
          //GUARDAMOS LOS DATOS EN SESION
          
          this.cartService.setLogin(result);
          // Redirigir al usuario al home o dashboard después de iniciar sesión correctamente
          this.router.navigate(['/tienda']);
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Usuario o contraseña incorrectos" 
          });
        }
      })
  }

  
  encrypt(string: string, key: string): string {
    let result = '';
    for (let i = 0; i < string.length; i++) {
        const char = string[i]; // Obtener el   carácter
        let lk = key.length;
        let mod = i%lk;
        let position=  mod - 1;
       

        if (position < 0) {
          position = lk - 1; // Ajustar a la última posición si es negativo
      }
  
        const keychar =  key.charAt(position);  // Obtener el código ASCII del carácter de la clave
        
        const asciiValue = char.charCodeAt(0) + keychar.charCodeAt(0) ; // Devuelve 97

        const char2 = String.fromCharCode(asciiValue); 

       
        result += char2;
       
        //const encryptedChar = String.fromCharCode(char + keychar); // Sumar los códigos ASCII
        //result += encryptedChar; // Agregar el carácter cifrado al resultado
    }
    return btoa(result); // Codificar el resultado en Base64
}

 
fnGetEncriptado(){ 
  const encrypted = this.encrypt(this.loginData.password , 'eco');
 // console.log(encrypted);
  if(encrypted){
    this.strPassEncriptado=encrypted;
     //console.log(this.strPassEncriptado);
    this.onLogin();
  }
 
}
}
