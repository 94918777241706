<!--<app-menu></app-menu>-->
<div class="container"><br><br>
    <div class="row seccion">
        <div class="col"><br>
            <h1 class="title">Histórico de <span class="title2">Ventas Nacional</span></h1>
            <div class="col contenedor_tabla">
                <div class="divYN">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="headcolN encabezado">Linea</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Enero</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Febrero</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Marzo</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Abril</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Mayo</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Junio</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Julio</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Agosto</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Septiembre</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Octubre</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Noviembre</th>
                                <th scope="col" class="subject encabezado">%</th>
                                <th scope="col" class="subject encabezado">Diciembre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arrayHistorico; index as i; ">
                                <th class="headcolN panel">{{item.strLinea}}</th>
                                <td class="cuerpo3">{{item.dblEnero/dblTEnero| percent}}</td>
                                <td class="cuerpo1">{{item.dblEnero| currency}}</td>
                                <td class="cuerpo3">{{item.dblFebrero/dblTFebrero| percent}}</td>
                                <td class="cuerpo1">{{item.dblFebrero| currency}}</td>
                                <td class="cuerpo3">{{item.dblMarzo/dblTMarzo| percent}}</td>
                                <td class="cuerpo1">{{item.dblMarzo| currency}}</td>
                                <td class="cuerpo3">{{item.dblAbril/dblTAbril| percent}}</td>
                                <td class="cuerpo1">{{item.dblAbril| currency}}</td>
                                <td class="cuerpo3">{{item.dblMayo/dblTMayo| percent}}</td>
                                <td class="cuerpo1">{{item.dblMayo| currency}}</td>
                                <td class="cuerpo3">{{item.dblJunio/dblTJunio| percent}}</td>
                                <td class="cuerpo1">{{item.dblJunio| currency}}</td>
                                <td class="cuerpo3">{{item.dblJulio/dblTJulio| percent}}</td>
                                <td class="cuerpo1">{{item.dblJulio| currency}}</td>
                                <td class="cuerpo3">{{item.dblAgosto/dblTAgosto| percent}}</td>
                                <td class="cuerpo1">{{item.dblAgosto| currency}}</td>
                                <td class="cuerpo3">{{item.dblSeptiembre/dblTSeptiembre| percent}}</td>
                                <td class="cuerpo1">{{item.dblSeptiembre| currency}}</td>
                                <td class="cuerpo3">{{item.dblOctubre/dblTOctubre| percent}}</td>
                                <td class="cuerpo1">{{item.dblOctubre| currency}}</td>
                                <td class="cuerpo3">{{item.dblNoviembre/dblTNoviembre| percent}}</td>
                                <td class="cuerpo1">{{item.dblNoviembre| currency}}</td>
                                <td class="cuerpo3">{{item.dblDiciembre/dblTDiciembre| percent}}</td>
                                <td class="cuerpo1">{{item.dblDiciembre| currency}}</td>
                            </tr>
                            <tr>
                                <th class="headcolN pie1">Total</th>
                                <td class="pie1" colspan=2>{{ dblTEnero| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTFebrero| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTMarzo| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTAbril| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTMayo| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTJunio| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTJulio| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTAgosto| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTSeptiembre| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTOctubre| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTNoviembre| currency}}</td>
                                <td class="pie1" colspan=2>{{ dblTDiciembre| currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br><br>

        </div>
    </div><br><br>
    <div class="row seccion">
        <div class="col-3"></div>
        <div class="col-6"><br>
            <h1 class="title">Captura de <span class="title2">Objetivos</span></h1><br>
            <div class="row">
                <div class="col-5">

                    <mat-form-field appearance="fill" class="matcell" *ngIf="dblObjetivoNacional>0">
                        <mat-label>Objetivo Nacional</mat-label>
                        <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="dblObjetivoNacional" (change)="updatedObjNacional($event)" />
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="matcell" *ngIf="dblObjetivoNacional<=0 || dblObjetivoNacional==undefined">
                        <mat-label>Objetivo Nacional</mat-label>
                        <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="0" (change)="updatedObjNacional($event)" />
                    </mat-form-field>


                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <mat-form-field appearance="fill" class="matcell">
                        <mat-label>% Total</mat-label>
                        <input matInput [(ngModel)]="dblTPorcentaje">
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div>
                <table class="table table-hover topics  ">
                    <thead>
                        <tr>
                            <th scope="col" class="  encabezado">Linea</th>

                            <th scope="col" class="  encabezado">Objetivo</th>
                            <th scope="col" class="  encabezado">% sugerido</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of arrayObjetivos; index as i; ">
                            <th class="  panel2">{{item.strLinea}}</th>
                            <td class="cuerpo2">
                                <mat-form-field appearance="fill" class="matcell">
                                    <mat-label>Objetivo</mat-label>
                                    <!--<input matInput [(ngModel)]="item.dblObjetivo">-->
                                    <input matInput value="{{item.dblObjetivo | currency}}" disabled="true">
                                </mat-form-field>
                            </td>
                            <td class="cuerpo2">
                                <mat-form-field appearance="fill" class="matcell">
                                    <mat-label>% Sugerido</mat-label>
                                    <input matInput [(ngModel)]="item.dblPsugerido" (ngModelChange)="changeObjNacional()">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>

                        </tr>
                    </tbody>
                </table>
                <button mat-raised-button style="
                color: rec;
                background-color: #3e6294;
                color: white;
            " (click)="fnGuardarObjetivoNacional()" *ngIf="btnGuardar&&arrayObjetivoAsignado.length==0">Guardar</button> <br><br>
            </div>
            <br>
        </div>

        <div class="col-3"></div>
    </div>
</div>
<br>
<br>
<app-footer></app-footer>