import { Component, ElementRef,OnInit ,ViewChild} from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import { Router,ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DataDBService } from '../servicios/data-db.service';
import { trigger, style, animate, transition } from '@angular/animations';
declare var Shepherd: any; 
@Component({
  selector: 'app-detalle-articulo',
  templateUrl: './detalle-articulo.component.html',
  styleUrls: ['./detalle-articulo.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),  // Empieza invisible
        animate('400ms ease-in', style({ opacity: 1 })) // Aparece suavemente
      ]),
      transition(':leave', [
        animate('400ms ease-out', style({ opacity: 0 })) // Desaparece suavemente
      ]) ])
    ]
})
export class DetalleArticuloComponent implements OnInit {
  @ViewChild('mdlCambPrecio') btnCambPrecio!: ElementRef; 
  public strRuta_Origen: string = "";
  public strNombre_ruta : string = "";
  public bloques: any[] = [];
  public arrayLineas: any = [];
  public elementosPorPagina: number = 5;
  public strConexion: any = ''
  public items: any = []
  public objArticulo:any={};
  public strNumCte:string;
  public objData_Art:any={strSustituto:""}
  public strAlmacen:string='1';
  public strEstatus_Almacen:string='';
  public arrayPrecio:any=[];
  public arrayOpciones:any=[];
  public strOpcion_Selected:string='';
  public dblPrecio_Selected:number=0;
  public dblDisponible_nacional:number=0;
  public  dblReservado_nacional:number=0;
  public  objDisponible_almacen:any={dblCantidad:0, dblReservado:0};
  public arraySaldou:any=[];
  public arrayRelacionados: any=[];
  public arraySustitutos:any=[];
  public arraySusPrincipal:any=[];
  public arrayRelacionadosPrincipal:any=[];
  public arrayCompararArts:any=[];
  public tagName:any;
  public arrayAccesos:any=[];
  public boolBtn_Comparar:boolean=false;
  public boolBtn_CPrecion:boolean=false
  public boolBtn_Precio4:boolean=false;
  public arraySaldoUFilter:any =[];
  public boolBtn_preciominimo:boolean=false;
  public boolBtn_Costo:any =false;
  public arrayCarritoArts:any = [];
  private btnPrecio_cte:boolean=false;
  public boolAgotado:boolean = false;
  public objCliente: any = {
    strCliente: "",
    strNombre: "",
    strDireccion: "",
    ClienteSeleccionado: false,
    strSucursal: "",
    NombreSucursal: "",
    tipoSucursal: ""
  };
  cards = [
    { image: 'https://via.placeholder.com/150', description: 'Descripción 1', key: '001' },
    { image: 'https://via.placeholder.com/150', description: 'Descripción 2', key: '002' },
    { image: 'https://via.placeholder.com/150', description: 'Descripción 3', key: '003' },
    // Agrega más tarjetas según sea necesario
  ];
  public objOpcion:any={};
  currentIndex = 0;


  value: number = 0;
 
  itemsPerSlide = 5;
  visibleItems: any[] = [];
  public boolBtn_Precio_Partner:boolean =false;
  public objDescuento_Parner:any ={};
  constructor(private routEs : ActivatedRoute,private indexedDBService: DataDBService,elem:ElementRef, private router: Router, public _alertify: AlertifyService,private carroservice: CarroService,private _httpService: PeticionesService,private localSt: LocalStorageService) {
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    this.strConexion = this.localSt.retrieve('strConexion')?this.localSt.retrieve('strConexion'):'COMERCIAL'; 
  
    this.strAlmacen =this.localSt.retrieve('strAlmacen')?this.localSt.retrieve('strAlmacen'):'1'; 
    this.objArticulo= this.localSt.retrieve('strArticulo_Seleccionado_Cotizador')? this.localSt.retrieve('strArticulo_Seleccionado_Cotizador'):this.localSt.retrieve('strArticulo_Seleccionado_Comodato');
 

    //validamos los accesos para mostrar botones
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);      
      this.boolBtn_Comparar = objAcceso.find((o: any) => o.strAccion == 'btnComparar') ? true : false;
      this.boolBtn_CPrecion = objAcceso.find((o: any) => o.strAccion == 'btnActualizarPrecio') ? true : false;
      this.boolBtn_Precio4 = objAcceso.find((o: any) => o.strAccion == 'btnPrecio4') ? true : false;
      this.boolBtn_preciominimo = objAcceso.find((o: any) => o.strAccion == 'btnPrecioMinimo') ? true : false;
      this.boolBtn_Costo = objAcceso.find((o: any) => o.strAccion == 'btnCosto') ? true : false;
      this.boolBtn_Precio_Partner= objAcceso.find((o: any) => o.strAccion == 'btnPrecioPartner') ? true : false;
      this.btnPrecio_cte = this.arrayAccesos[0].strCliente && this.arrayAccesos[0].strCliente !='' ? false:true;
 
    }
    //obtenemos el cliente, si es que hay alguuno seleccionado
    this.carroservice.ObjCliente$.subscribe((nuevoValor) => {
       this.objCliente = nuevoValor; 
       this.strNumCte = nuevoValor.strCliente;
    });
    this.routEs.queryParams.subscribe(params => {
      let step = params['addstep']; // 'tour'
      if(step == "intro"){

        setTimeout(() => {
          this.iniciarTour();
        }, 400)

      }
      console.log('Viene del tour:', step);
    });
    
   
   }

   ngOnInit() {
    this.indexedDBService.getItem('arrayResult', (value) => {

    });
    this.carroservice.arrayItems$.subscribe((nuevoValor) => {
      this.arrayCarritoArts = nuevoValor;
    });
       // ----------------servicio de descuento ---------------------

    this.carroservice.getDescuento_partner().subscribe((objDescuento)=>{
        this.objDescuento_Parner = objDescuento; 
  });
    this.fnGetDetalle();
   
    this.fnGetSustitutos();
    this.fnGetRelacionados();
 
    this.objArticulo.strUrlImg1 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' ;
    this.objArticulo.strUrlImg2 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-PO-2.JPG' ;
    this.objArticulo.strUrlImg3 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-PO-3.JPG' ;
    this.objArticulo.strUrlImg4 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-PO-4.JPG' ;
    this.objArticulo.strUrlImg5 = 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'-PO-5.JPG' ;
  this.items = [
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG'  },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' },
    { strCategoria: 'https://www.cleanclean.mx/imagenes/'+this.objArticulo.strArticulo+'.JPG' }
  ];



   // this.bloques = this.chunkArray(this.items, this.elementosPorPagina);
  }

  fnAtras(){
    //obtenemos la busqueda de respaldo
    let busqueda_respaldo = localStorage.getItem('busqueda_respaldo'); 
    console.log(busqueda_respaldo);
    this.indexedDBService.fnIniciaBusqueda_Articulo(busqueda_respaldo)
    //routerLink="/cotizador_Articulos"
    this.router.navigate(['/cotizador_Articulos']);
  }
  fnAdd_Rest_Amount(objArt: any, tipo: string) {
    if (tipo == 'add') {
      objArt.dblCantidad = objArt.dblCantidad + 1;
    } else {
      objArt.dblCantidad = objArt.dblCantidad - 1;
    }
  }
fnGetRelacionados(){
  let articulo = {
    strAccion: "getRelacionados",  
    strConexion: this.strConexion,
    strArticulo:this.objArticulo.strArticulo 
  };
  this._httpService.getCotizador(articulo).subscribe(
    result => {
     /*  arrayRelacionadosPrincipal */
      
      this.arrayRelacionados=result; 

      let CantArts = 0,cantPags = 0, art=0;
      this.arrayRelacionadosPrincipal =[];
      this.arrayRelacionados = result; 
      CantArts = this.arrayRelacionados.length 
      cantPags =  Math.ceil(CantArts / 4)
      for(let i = 0; i< cantPags ;i++){
        this.arrayRelacionadosPrincipal[i] = { arts: [] };
        this.arrayRelacionadosPrincipal[i].arts.push({
          strArticulo: this.arrayRelacionados[art]?.strArticulo,
          url: this.arrayRelacionados[art] && this.arrayRelacionados[art].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arrayRelacionados[art].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arrayRelacionados[art]?.strDescripcion1
        });
        
        this.arrayRelacionadosPrincipal[i].arts.push({
          strArticulo: this.arrayRelacionados[art + 1]?.strArticulo,
          url: this.arrayRelacionados[art + 1] && this.arrayRelacionados[art + 1].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arrayRelacionados[art + 1].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arrayRelacionados[art+1]?.strDescripcion1
        });
      
        this.arrayRelacionadosPrincipal[i].arts.push({
          strArticulo: this.arrayRelacionados[art + 2]?.strArticulo,
          url: this.arrayRelacionados[art + 2] && this.arrayRelacionados[art + 2].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arrayRelacionados[art + 2].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arrayRelacionados[art+2]?.strDescripcion1
        });
      
        this.arrayRelacionadosPrincipal[i].arts.push({
          strArticulo: this.arrayRelacionados[art + 3]?.strArticulo, 
          url: this.arrayRelacionados[art + 3] && this.arrayRelacionados[art + 3].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arrayRelacionados[art + 3].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
             strDescripcion:this.arrayRelacionados[art+3]?.strDescripcion1

        });
        art += 4;
      }
    });
}
fnGetSustitutos(){
  let articulo = {
    strAccion: "getSustitutos",  
    strConexion: this.strConexion,
    strArticulo:this.objArticulo.strArticulo 
  };
  this._httpService.getCotizador(articulo).subscribe(
    result => {
  
      let CantArts = 0,cantPags = 0, art=0;
      this.arraySusPrincipal =[];
      this.arraySustitutos = result; 
      CantArts = this.arraySustitutos.length 
      cantPags =  Math.ceil(CantArts / 4)
      for(let i = 0; i< cantPags ;i++){
        this.arraySusPrincipal[i] = { arts: [] };
        this.arraySusPrincipal[i].arts.push({
          strArticulo: this.arraySustitutos[art]?.strArticulo,
          url: this.arraySustitutos[art] && this.arraySustitutos[art].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arraySustitutos[art].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arraySustitutos[art]?.strDescripcion1
        });
        
        this.arraySusPrincipal[i].arts.push({
          strArticulo: this.arraySustitutos[art + 1]?.strArticulo,
          url: this.arraySustitutos[art + 1] && this.arraySustitutos[art + 1].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arraySustitutos[art + 1].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arraySustitutos[art+1]?.strDescripcion1
        });
      
        this.arraySusPrincipal[i].arts.push({
          strArticulo: this.arraySustitutos[art + 2]?.strArticulo,
          url: this.arraySustitutos[art + 2] && this.arraySustitutos[art + 2].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arraySustitutos[art + 2].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arraySustitutos[art+2]?.strDescripcion1
        });
      
        this.arraySusPrincipal[i].arts.push({
          strArticulo: this.arraySustitutos[art + 3]?.strArticulo, 
          url: this.arraySustitutos[art + 3] && this.arraySustitutos[art + 3].strArticulo 
            ? 'https://www.cleanclean.mx/imagenes/' + this.arraySustitutos[art + 3].strArticulo + '.JPG' 
            : 'assets/detoportunidad/noDisponible.jpg',
            strDescripcion:this.arraySustitutos[art+3]?.strDescripcion1
        });
        art += 4;
      }
    });
}
  fnAddToCarr(objArticulo: any) { //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado
   
    let disponible= 0;
    disponible =  this.dblDisponible_nacional - this.dblReservado_nacional 
   
      
  
if(objArticulo.dblCantidad && objArticulo.dblCantidad > 0 ){

    if( objArticulo.strEstatus == "DESCONTINUADO"){
      if(this.arrayCarritoArts.length >= 1 ){
        let objArt:any;
        this.arrayCarritoArts.forEach(art => {
          if(art.strArticulo==objArticulo.strArticulo){
            objArt = art
          }
        });
        if(objArt){
          if(objArt.dblCantidad<disponible){
            let cantTArticulo = objArt.dblCantidad + objArticulo.dblCantidad
            if(cantTArticulo<=disponible){
              this.fnaddCart(objArticulo);
            }else{
              Swal.fire("ERROR","Articulo descontinuado, La cantidad agregeda excede con lo que ya tiene precargado en el carrito.","error")
            }
          }else{
            Swal.fire("ERROR","Articulo descontinuado, cantidad disponible agregada en el carrito.","error")
          }
        }else{
          if(objArticulo.dblCantidad<=disponible){
            this.fnaddCart(objArticulo);
          }else{
            Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
          }
        }

      }else{
        if(objArticulo.dblCantidad<=disponible){
          this.fnaddCart(objArticulo);
        }else{
          Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
        }
      }
    }else{
      this.fnaddCart(objArticulo);
    }
  /* else{
      Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
  } */
    }else{
      Swal.fire("ERROR","Ingrese una cantidad valida.","error")
 
    }
  }
fnaddCart(objArticulo: any){
  objArticulo.strOpcion=this.strOpcion_Selected;
  objArticulo.arrayOpciones=this.arrayOpciones; 
  objArticulo.intPrecioPolitica=objArticulo.dblPrecioPolitica;
  objArticulo.intPrecioPlantilla=objArticulo.dblPrecioPlantilla;
  objArticulo.intPrecioLista=objArticulo.dblPrecioLista;
  objArticulo.intPrecio =objArticulo.dblPrecioLista;
  objArticulo.intPrecio2 =this.btnPrecio_cte ?objArticulo.dblPrecio2 :null; //si es cliente no puede ver otros precios
  objArticulo.intPrecio3 =this.btnPrecio_cte ?objArticulo.dblPrecio3 :null; //si es cliente no puede ver otros precios
  objArticulo.intPrecio4 = this.boolBtn_Precio4? objArticulo.dblPrecio4 : null; //si no tiene permisos para ver el precio 4 no lo podemos agregar para que no se muestre en carrito resumen
  objArticulo.intPrecio9 = this.boolBtn_Precio_Partner? objArticulo.dblPrecio9 : null; //precio exclusivo para partners
  objArticulo.dblPrecioLista=this.dblPrecio_Selected;
 
  objArticulo.strComentario = "";
 // this.spinerCarrito = true;
  const deepCopy = JSON.parse(JSON.stringify(objArticulo));
 
  this.carroservice.setArticulo(deepCopy);
  setTimeout(() => {
   // this.spinerCarrito = false;
    this._alertify.success("Articulo agregado correctamente");
  }, 400);
}

  fnCambio_opcion(){ 
    
    this.objData_Art.strOpcion= this.strOpcion_Selected;
    this.fnGetEstatus_Almacen();
    this.objDisponible_almacen = this.strOpcion_Selected !=''? this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen && o.strOpcion==this.strOpcion_Selected) :this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen);
    this.objDisponible_almacen = this.objDisponible_almacen ? this.objDisponible_almacen : {dblCantidad:0, dblReservado:0};
    //cambia lo disponible nacional de acuerdo a la opcion
    let nacional_opcion =[]
    nacional_opcion = this.strOpcion_Selected !=''? this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) :this.arraySaldou;
    this.dblDisponible_nacional=0;
    this.dblReservado_nacional=0;
    nacional_opcion.forEach(element => {
      let disponible=element.dblCantidad ?element.dblCantidad :0;
      let reservado =element.dblReservado ?element.dblReservado :0;
      this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
      this.dblReservado_nacional=this.dblReservado_nacional +reservado;
     });
     this.objOpcion = this.arrayOpciones.find(x => x.strOpcion == this.objData_Art.strOpcion);
     this.fnFiltrarSaldou(); 
    
  }
  fnGetOpciones(){
    let articulo = {
      strAccion: "getOpcion",  
      strConexion: this.strConexion,
      strArticuloBusqueda:this.objArticulo.strArticulo 
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {
        this.arrayOpciones=result; 
        console.log(result)
        this.strOpcion_Selected = (this.arrayOpciones && this.arrayOpciones.length > 0 && this.arrayOpciones[0].strOpcion) ? this.arrayOpciones[0].strOpcion : "";
       
        this.objOpcion = this.arrayOpciones.find(x => x.strOpcion == this.objData_Art.strOpcion);
     
        
      });
      

  }
  fnGetDetalle(){
    let articulo = {
      strAccion: "getDataArt", 
      strCliente:  (this.objCliente.strCliente) ? this.objCliente.strCliente : '',
      strCteFam:   (this.objCliente.strCteFam) ? this.objCliente.strCteFam : '',
      strConexion: this.strConexion,
      strArticuloBusqueda:this.objArticulo.strArticulo
      ,
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => { 
        this.objData_Art= result[0];
        this.objData_Art.dblCantidad=1;
        this.strOpcion_Selected= this.objData_Art.strOpcion &&  this.objData_Art.strOpcion !='' ?this.objData_Art.strOpcion:'';
       
        if(this.boolBtn_Precio4){
          this.arrayPrecio= 
          [
          {nombre:'Precio Lista', valor:this.objData_Art.dblPrecioLista},
          {nombre:'Precio 2', valor:this.objData_Art.dblPrecio2},{nombre:'Precio 3', valor:this.objData_Art.dblPrecio3},
          {nombre:'Precio 4', valor:this.objData_Art.dblPrecio4},{nombre:'Precio Plantilla', valor:this.objData_Art.dblPrecioPlantilla},
          {nombre:'Precio Politica', valor:this.objData_Art.dblPrecioPolitica}];
        }
        else if( this.boolBtn_Precio_Partner){
          this.arrayPrecio= 
          [
          {nombre:'Precio Partner', valor:this.objData_Art.dblPrecio9} 
        ];
        }
        else if(!this.btnPrecio_cte  ){
          this.arrayPrecio= [
            {nombre:'Precio 3', valor:this.objData_Art.dblPrecio3},
            {nombre:'Precio Plantilla', valor:this.objData_Art.dblPrecioPlantilla},
            {nombre:'Precio Politica', valor:this.objData_Art.dblPrecioPolitica}];
        }
        else {
          this.arrayPrecio= [{nombre:'Precio Lista', valor:this.objData_Art.dblPrecioLista},
            {nombre:'Precio 2', valor:this.objData_Art.dblPrecio2},{nombre:'Precio 3', valor:this.objData_Art.dblPrecio3},
             {nombre:'Precio Plantilla', valor:this.objData_Art.dblPrecioPlantilla},
            {nombre:'Precio Politica', valor:this.objData_Art.dblPrecioPolitica}];
        }
        if(this.boolBtn_preciominimo){
          this.arrayPrecio.push({nombre:'Precio Minimo', valor:this.objData_Art.dblPrecioMinimo})
        }
          this.dblPrecio_Selected=this.objData_Art.dblPrecioPolitica >0?
          this.objData_Art.dblPrecioPolitica:
          this.objData_Art.dblPrecioPlantilla  >0?
          this.objData_Art.dblPrecioPlantilla :
          !this.btnPrecio_cte && !this.boolBtn_Precio_Partner?this.objData_Art.dblPrecio3:
          this.boolBtn_Precio_Partner?this.objData_Art.dblPrecio9:
          this.objData_Art.dblPrecioLista; 
          
          if(this.objData_Art.strTipoOpcion == "Si"){
            this.fnGetOpciones();
          }
          setTimeout(() => {
            this.fnGetEstatus_Almacen(); //se ponen aqui porque dependen del parametro stropcion que devuelve esta funcion
        this.fnGetDisponible();
          }, 100);
         //se ponen aqui porque dependen del parametro stropcion que devuelve esta funcion
        
       
      });
  }
  fnGetEstatus_Almacen(){
    let articulo = {
      strAccion: "getEstatusAlm",  
      strConexion: this.strConexion,
      strArticulo:this.objArticulo.strArticulo ,
      strOpcion:this.objData_Art.strOpcion ? this.objData_Art.strOpcion :null,
      strAlmacen:this.strAlmacen
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {
        this.strEstatus_Almacen=result[0]?result[0].strEstatusAlmacen:'';
        
      });
  }
  fnFiltrarSaldou(){ 
    this.arraySaldoUFilter = this.strOpcion_Selected !=''?this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) : this.arraySaldou;
    this.boolAgotado =  (this.dblDisponible_nacional - this.dblReservado_nacional)<1?true:false;
  
  }
  fnGetDisponible(){
    this.arraySaldou =[] 
    let articulo = {
      strAccion: "getDisponible",  
      strConexion: this.strConexion,
      strArticulo:this.objArticulo.strArticulo 
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {  
          this.arraySaldou = result; 
          this.objDisponible_almacen = this.strOpcion_Selected !=''? this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen && o.strOpcion==this.strOpcion_Selected) :this.arraySaldou.find(o=>o.strAlmacen==this.strAlmacen);
          this.objDisponible_almacen = this.objDisponible_almacen ? this.objDisponible_almacen : {dblCantidad:0, dblReservado:0};
          this.dblDisponible_nacional=0
          this.dblReservado_nacional=0
          let nacional_opcion =[] ;
          nacional_opcion = this.strOpcion_Selected !=''? this.arraySaldou.filter(o=> o.strOpcion==this.strOpcion_Selected) :this.arraySaldou;
          nacional_opcion.forEach(element => {
            
            let disponible=element && element.dblCantidad ?element.dblCantidad :0;
            let reservado =element && element.dblReservado ?element.dblReservado :0;
            this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
            this.dblReservado_nacional=this.dblReservado_nacional +reservado;
            this.fnFiltrarSaldou()
          }); 
      });
  }
 
   // Función para dividir el array en bloques del tamaño especificado
   chunkArray(array: any[], size: number): any[] {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  increment() {
    this.value++;
  }

  decrement() {
    if (this.value > 1) {
      this.value--;
    }
  }

  onImageError(event: any) {
    event.target.src = 'assets/detoportunidad/noDisponible.jpg'; // Ruta de tu imagen local en los assets
  }

  addToCart(obj:any){  

    this.localSt.store('strArticulo_Seleccionado_Cotizador', obj);
    // Usar navigateByUrl para navegar fuera y volver a la misma ruta
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/detalle_articulo']);})




  }

  fnGetCompartivoArts(){ 
  let articulo = {
      strAccion: "getComparar",
      strConexion: this.strConexion,
      strAlmacen:this.strAlmacen,
      strArticulo:this.objData_Art.strArticulo
    }; 
      this._httpService.getCotizador(articulo).subscribe(
      result => { 
        this.arrayCompararArts = result;
        this.arrayCompararArts.forEach(item => {
          item.dblCantidad = 1
        }); 
      });
  }


  fnGetCambiarPrecioArts(){
  let articulo = {
      strAccion: "update_Precio_Art",  
      strConexion: this.strConexion,
      dblPrecioLista:this.objData_Art.dblPrecioLista,
      dblPrecio2:this.objData_Art.dblPrecio2,
      dblPrecio3:this.objData_Art.dblPrecio3,
      dblPrecio4:this.objData_Art.dblPrecio4,
      dblPrecio5:this.objData_Art.dblPrecio5,
      dblPrecioMinimo:this.objData_Art.dblPrecioMinimo,
      strArticulo:this.objData_Art.strArticulo
    };
   
      this._httpService.getCotizador(articulo).subscribe(
      result => { 
      
        let res = result[0]
        if(res.code == "200"){

          Swal.fire({
            icon: "success",
            title: "Exito al guardar.",
            text:res.strMensaje,
            showConfirmButton: false,
            timer: 2000
          });
          this.btnCambPrecio.nativeElement.click();
          this.localSt.store('strArticulo_Seleccionado_Cotizador', this.objData_Art);
          // Usar navigateByUrl para navegar fuera y volver a la misma ruta
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/detalle_articulo']);
          });
        }else{
          Swal.fire({
            icon: "error",
            title: "Error",
            text:res.strMensaje
          });
        }
      
      });
  }
  fnGetCostos(){
    
  }

  restrictInput(event: KeyboardEvent, inputElement: HTMLInputElement, nacional: number): void {
    // Obtiene el código de la tecla presionada
    const key = event.key;
let currentValue = inputElement.value
    // Permite solo números, backspace, tab, y flechas
    if (!/[0-9]/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
        event.preventDefault();
        return;
    }
    if (key === 'Backspace') {
      setTimeout(() => {
          if (inputElement.value === '' || parseInt(inputElement.value, 10) < 1) {
              inputElement.value = '1';
          }
      }, 0); // Usamos `setTimeout` para esperar que el valor se actualice después del Backspace
      return;
  }
  let newValue = currentValue + key;

    // Si el valor actual más la tecla presionada supera el límite `nacional`, bloquear entrada
    if (parseInt(newValue, 10) > nacional) {
        console.log('Límite alcanzado:', nacional);
        event.preventDefault();
    }

/*     let newValue = inputElement.value + key;
console.log(newValue)
    if (parseInt(newValue, 10) > nacional) {
        console.log('Límite alcanzado:', nacional);
        event.preventDefault();
    } */
}

//---------------------------funciones para partnert
fnAddToCarr_partner(objArticulo: any) { //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado
   
  let disponible= 0;
  disponible =  this.dblDisponible_nacional - this.dblReservado_nacional 
 
    

if(objArticulo.dblCantidad && objArticulo.dblCantidad > 0 ){

  if( objArticulo.strEstatus == "DESCONTINUADO"){
    if(this.arrayCarritoArts.length >= 1 ){
      let objArt:any;
      this.arrayCarritoArts.forEach(art => {
        if(art.strArticulo==objArticulo.strArticulo){
          objArt = art
        }
      });
      if(objArt){
        if(objArt.dblCantidad<disponible){
          let cantTArticulo = objArt.dblCantidad + objArticulo.dblCantidad
          if(cantTArticulo<=disponible){
            this.fnaddCart_partner(objArticulo);
          }else{
            Swal.fire("ERROR","Articulo descontinuado, La cantidad agregeda excede con lo que ya tiene precargado en el carrito.","error")
          }
        }else{
          Swal.fire("ERROR","Articulo descontinuado, cantidad disponible agregada en el carrito.","error")
        }
      }else{
        if(objArticulo.dblCantidad<=disponible){
          this.fnaddCart_partner(objArticulo);
        }else{
          Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
        }
      }

    }else{
      if(objArticulo.dblCantidad<=disponible){
        this.fnaddCart_partner(objArticulo);
      }else{
        Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
      }
    }
  }else{
    this.fnaddCart_partner(objArticulo);
  }
/* else{
    Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
} */
  }else{
    Swal.fire("ERROR","Ingrese una cantidad valida.","error")

  }
}
fnAddToCarr_partner_cotizador(objArticulo: any) { //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado

  let disponible= 0;
  disponible =  this.dblDisponible_nacional - this.dblReservado_nacional 
 
    

if(objArticulo.dblCantidad && objArticulo.dblCantidad > 0 ){

  if( objArticulo.strEstatus == "DESCONTINUADO"){
    if(this.arrayCarritoArts.length >= 1 ){
      let objArt:any;
      this.arrayCarritoArts.forEach(art => {
        if(art.strArticulo==objArticulo.strArticulo){
          objArt = art
        }
      });
      if(objArt){
        if(objArt.dblCantidad<disponible){
          let cantTArticulo = objArt.dblCantidad + objArticulo.dblCantidad
          if(cantTArticulo<=disponible){
            console.log(objArticulo)
            this.fnaddCart_partner_cotizador(objArticulo);
          }else{
            Swal.fire("ERROR","Articulo descontinuado, La cantidad agregeda excede con lo que ya tiene precargado en el carrito.","error")
          }
        }else{
          Swal.fire("ERROR","Articulo descontinuado, cantidad disponible agregada en el carrito.","error")
        }
      }else{
        if(objArticulo.dblCantidad<=disponible){
          console.log(objArticulo)
          this.fnaddCart_partner_cotizador(objArticulo);
        }else{
          Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
        }
      }

    }else{
      if(objArticulo.dblCantidad<=disponible){
        this.fnaddCart_partner_cotizador(objArticulo);
      }else{
        Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
      }
    }
  }else{
    this.fnaddCart_partner_cotizador(objArticulo);
  }
/* else{
    Swal.fire("ERROR","Ingrese una cantidad menor o igual a la existencia por que este articulo se encuentra descontinuado.","error")
} */
  }else{
    Swal.fire("ERROR","Ingrese una cantidad valida.","error")

  }
}

fnaddCart_partner(objArticulo: any){
  objArticulo.strOpcion=this.strOpcion_Selected;
  objArticulo.arrayOpciones=this.arrayOpciones; 
  objArticulo.intPrecioPolitica=objArticulo.dblPrecioPolitica;
  objArticulo.intPrecioPlantilla=objArticulo.dblPrecioPlantilla;
  objArticulo.intPrecioLista=objArticulo.dblPrecioLista;
  objArticulo.intPrecio =objArticulo.dblPrecioLista;
  objArticulo.intPrecio2 =this.btnPrecio_cte ?objArticulo.dblPrecio2 :null; //si es cliente no puede ver otros precios
  objArticulo.intPrecio3 =this.btnPrecio_cte ?objArticulo.dblPrecio3 :null; //si es cliente no puede ver otros precios
  objArticulo.intPrecio4 = this.boolBtn_Precio4? objArticulo.dblPrecio4 : null; //si no tiene permisos para ver el precio 4 no lo podemos agregar para que no se muestre en carrito resumen
  objArticulo.intPrecio9 = this.boolBtn_Precio_Partner? objArticulo.dblPrecio9 : null; //precio exclusivo para partners
  objArticulo.dblPrecioLista=this.dblPrecio_Selected;
 
  objArticulo.strComentario = "";
 // this.spinerCarrito = true;
  const deepCopy = JSON.parse(JSON.stringify(objArticulo));
 
  this.carroservice.setArticulo_partner(deepCopy);
  setTimeout(() => {
   // this.spinerCarrito = false;
    this._alertify.success("Articulo agregado correctamente");
  }, 400);
}

fnaddCart_partner_cotizador(objArticulo: any){
  objArticulo.strOpcion=this.strOpcion_Selected;
  objArticulo.arrayOpciones=this.arrayOpciones; 
  objArticulo.intPrecioPolitica=objArticulo.dblPrecioPolitica;
  objArticulo.intPrecioPlantilla=objArticulo.dblPrecioPlantilla;
  objArticulo.intPrecioLista=objArticulo.dblPrecioLista;
  objArticulo.intPrecio =objArticulo.dblPrecioLista;
  objArticulo.intPrecio2 =this.btnPrecio_cte ?objArticulo.dblPrecio2 :null; //si es cliente no puede ver otros precios
  objArticulo.intPrecio3 =this.btnPrecio_cte ?objArticulo.dblPrecio3 :null; //si es cliente no puede ver otros precios
  objArticulo.intPrecio4 = this.boolBtn_Precio4? objArticulo.dblPrecio4 : null; //si no tiene permisos para ver el precio 4 no lo podemos agregar para que no se muestre en carrito resumen
  objArticulo.intPrecio9 = this.boolBtn_Precio_Partner? objArticulo.dblPrecio9 : null; //precio exclusivo para partners
  objArticulo.dblPrecioLista=this.dblPrecio_Selected;
 
  objArticulo.strComentario = "";
 // this.spinerCarrito = true;
  const deepCopy = JSON.parse(JSON.stringify(objArticulo));

  this.carroservice.setArticulo_partner_cotizacion(deepCopy);

  setTimeout(() => {
   // this.spinerCarrito = false;
    this._alertify.success("Articulo agregado correctamente");
  }, 400);
}

iniciarTour() {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      scrollTo: true,
      cancelIcon: { enabled: true },
      classes: 'shadow-md bg-white rounded custom-tooltip',
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 8,
     
    },
    useModalOverlay: true  // 👈 Esto activa el fondo oscuro
  });


  tour.addStep({
    id: 'btnAgregarArt',
    text: 'agregar el articulo a la cotización.',
    attachTo: {
      element: '#btnAgregarArt',
      on: 'bottom'
    },
    buttons: [
      {
        text: 'Siguiente',
        action: () => {
          tour.hide(); 
          let deepCopy ={
            arrayOpciones: [],dblCantidad: 1,dblCostoTeorico: 215.66,
            dblPrecio2: 563.94,dblPrecio3: 508.82,dblPrecio4: 462.18,
            dblPrecio9: 536.1288,dblPrecioLista: 536.1288,dblPrecioMinimo: 401.21,
            intPrecio: 628.48,intPrecioLista: 628.48,intPrecio9: 536.1288,
            strArticulo: "68739022P",strCategoria: "AMENIDADES",strDescripcion: "Gel de Ducha Dove Leche Nutritiva: formulado con nutrientes que la piel absorbe a profundidad, para mantenerla hidratada y suave por mas tiempo.",
            strDescripcion1: "GEL DE DUCHA DOVE LECHE NUTRITIVA DE 5000 ML",strEstatus: "ALTA",strFamilia: "DOVE",
            strGrupo: "AMENIDADES",strLinea: "DOVE",strTipo: "Lote",
            strTipoOpcion: "No",strUnidad: "PZ",strUrlImg: "https://www.cleanclean.mx/imagenes/68739022P.JPG"
          }
          this.carroservice.setArticulo_partner_cotizacion(deepCopy);
          this.router.navigate(['/partner-cotizador'], {
            queryParams: { addstep:"finCarrito" }
          });
        }
      }
    ]
  });

  // Iniciar el tour
  tour.start();
}
}
