import { Component, OnInit , ElementRef} from '@angular/core';
import { CarroService } from '../servicios/carroservice';
import { Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';
import { DataDBService } from '../servicios/data-db.service';
import { LocalStorageService } from 'ngx-webstorage'; 
@Component({
  selector: 'app-partner-carrito',
  templateUrl: './partner-carrito.component.html',
  styleUrls: ['./partner-carrito.component.css']
})
export class PartnerCarritoComponent implements OnInit {
  public objCliente:any = {
    strCliente:"",
    strNombre:"",
    strDireccion:"",
    ClienteSeleccionado:false,
    strSucursal:"",
    NombreSucursal:"",
    tipoSucursal:""
  };
  public selectedPromotion: string = '';
  public objPromocionDescuento : any ={strNombre:'% DE DESCUENTO ' , dblImporte:0};
  public objPromocionMonedero : any ={strNombre:' EN MONEDERO ECODELI ' , dblImporte:0};
  public dblDescuento:number=0;
  private cartSubscription: Subscription;
  public cartItems:any =[];
  public strConexion= "COMERCIAL"
  public dblSubTotal:number=0; 
  public dblTotal:number=0;
  public arrayOpciones:any=[];
  public dblTotal_ahorrado:number=0;
  public objDisponible_almacen:number = 0;
  public dblDisponible_nacional:number = 0;
  public dblReservado_nacional:number = 0;
  products = [];
  public intIva:number=0;
  public spiner:boolean=false;
  quantities = [1, 2, 3, 4, 5];
  shipping = 0;
  private secretKey ='ece1122';
  private secretKeys: string = 'ec0d3li2o2e';
  discount = 0;
  public arraySaldou:any=[];  
  public strCodigoPromocional:[];
  public intDisponible:number = 0
  public arrayDescuentos:any=[];
  public objCupon_descuento:any={};
  private envioSubscription: Subscription;
  private cuponSubscription: Subscription;
  public dblEnvio:number=0;//99;
  private total_items:number=0;
  public userData_tienda:any=[];
  public tagName:string;
  public arrayAccesos:any=[];
  public objPartner:any;
  public strAgenteLS:string;
  public strAgente_Crea:string;
  public strUsuario:string;
  public strSSN:string;
  public objEnvioPartner:any ={};
  public strTipo_envio_partner:string='';
  public strTitulo_envio:string='';
  public strNombre_envio_partner:string='';
  public strAlmacen:string;
  public strCteenviar:string;
  public strNombre_Descuento:string;
  public strNumCte:string;
  public objDescuento_Parner:any={};
  constructor(private carroservice:CarroService, public localSt: LocalStorageService,private indexedDBService: DataDBService,elem: ElementRef,private router: Router, private cartService: CarroService,private _httpService: PeticionesService) {
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.indexedDBService.getItem('arrayResult', (value) => {
    this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) { 


          this.objEnvioPartner=this.arrayAccesos[0].detalle_envio_partner?this.arrayAccesos[0].detalle_envio_partner:{}
          this.strTipo_envio_partner=this.arrayAccesos[0].strTipo_envio_partner?this.arrayAccesos[0].strTipo_envio_partner:'PICKUP';
          this.strNombre_envio_partner = this.arrayAccesos[0].nombre_envioPartner ?  this.arrayAccesos[0].nombre_envioPartner:'';
          this.strTitulo_envio = this.strTipo_envio_partner == 'PICKUP' ? 'Pick Up'  :'Envío Estándar';
         
          this.strCteenviar =  this.objEnvioPartner.intId_Direccion ?  this.objEnvioPartner.intId_Direccion:null;
        
      
       // this.fnGetInfo(this.objPartner.strCliente);
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        if (objAcceso == undefined || objAcceso.length == 0) {
          this.router.navigate(['/login']);
        } else {
          let objData = this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
          //this._httpService.setMostrarMenu(true);
          // this.localSt.retrieve('strAgente'); //this.boolAgente ? '' : 
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgenteLS = objData.strAgente ; //this.localSt.retrieve('strAgente');
          this.strAgente_Crea = objData.strAgente; // this.localSt.retrieve('strAgente');
          this.strUsuario = objData.strUsuario;
          this.strSSN =  objData.strSSN;
          //------------conexi+on-------------------
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
        }

         //servicio de cliente
    
        this.carroservice.ObjCliente$.subscribe((nuevoValor) => {
          this.objCliente = nuevoValor;
          if(nuevoValor.ClienteSeleccionado){
            this.strNumCte=nuevoValor.strCliente;
            this.strAlmacen = this.objEnvioPartner.strAlmacen ?  this.objEnvioPartner.strAlmacen:this.objCliente.strAlmacen;
          }else{
            this.strNumCte = ""
          }
        });
      } else {
        this.router.navigate(['/login']);
      }
    });  

   
   }
  ngOnInit(): void { 

    //this.fnGetDescuentos_partner()
    this.indexedDBService.arraySucursales_partner_$.subscribe((nuevoValor) => { 
          //funciones que se ejecutan solo para partners
          this.arrayAccesos = nuevoValor
          this.objEnvioPartner=this.arrayAccesos[0].detalle_envio_partner?this.arrayAccesos[0].detalle_envio_partner:{}
          this.strTipo_envio_partner=this.arrayAccesos[0].strTipo_envio_partner?this.arrayAccesos[0].strTipo_envio_partner:'PICKUP';
          this.strNombre_envio_partner = this.arrayAccesos[0].nombre_envioPartner ?  this.arrayAccesos[0].nombre_envioPartner:'';
          this.strTitulo_envio = this.strTipo_envio_partner == 'PICKUP' ? 'Pick Up'  :'Envío Estándar';
         
          this.strCteenviar =  this.objEnvioPartner.intId_Direccion ?  this.objEnvioPartner.intId_Direccion:null;
    });

     //PRECARGAMOS CUPON DE DESCUENTO
     let objC =localStorage.getItem('coupon')
     this.objCupon_descuento = objC?  JSON.parse(objC):{};
     this.fnGetDescuentos();
     this.loadCart(); 
  }
  public resaltarSeleccion() {
    console.log('Promoción seleccionada:', this.selectedPromotion);

    //obtenemo el nivel de la sesion
    let objC =localStorage.getItem('objDescuento_partner');
    let descuentoAplicable = objC  ? JSON.parse(objC) : {} ;
    
    let opcion_promocion = parseInt(this.selectedPromotion);
    //avisar el nivel donde está
    this.carroservice.fnDescuento_partner(descuentoAplicable, opcion_promocion); //seleccionamos por default la promocion DESCUENTO
    
   
  }
  loadCart() { 
   // this.cartService.applyShippment();
    let objC =localStorage.getItem('coupon')
    this.objCupon_descuento = objC?  JSON.parse(objC) : {} ;
    //this.cartService.applyCoupon(this.objCupon_descuento);
    // Suscribirse al carrito para recibir actualizaciones
    this.cartSubscription = this.cartService.getCartItems().subscribe((items) => {
        this.cartItems = items;
        this.cartItems.forEach(prod => {
          this.total_items=this.total_items + prod.dblCantidad;
          let opcion:any;
          if(prod.strTipoOpcion=='Si'){
            opcion = prod.arrayOpciones.find(o => o.strOpcion == prod.strOpcion)
            prod.strNombreOpcion = opcion.strNombreOpcion
          }
        
          if(prod.strEstatus == "DESCONTINUADO"){
            this.intDisponible = 0
            this.fnGetDisponible(prod)
          }else{
            prod.intDisponibleNacional = ""
          }
        
       });
    
       this.fnSumasItems();//lugar provisional
    });

    // ----------------servicio de descuento ---------------------

    this.cartService.getDescuento_partner().subscribe((objDescuento)=>{ 
      this.objDescuento_Parner = objDescuento; 
      
    });

 
 
  }
  fnGetDescuentos(){
    let objParam = {
      strAccion: "getDescuentos",
      strConexion: this.strConexion  
    };
    this._httpService.mostrarCotizaciones(objParam, 'sp_ECommerce').subscribe(
      result => {  
          this.arrayDescuentos=result;
      });
  }
  decreaseQuantity(itemId: any){ 
    this.cartService.updateQuantity(itemId, 'disminuye');
    this.loadCart();
  }

  increaseQuantity(itemId: any){ 
    if(itemId.strEstatus == "DESCONTINUADO" && itemId.dblCantidad >= itemId.intDisponibleNacional){
      
    }else{
      this.cartService.updateQuantity(itemId, 'aumenta');
      this.loadCart();
    }
    
  }

  saveForLater(itemId: any) {
    this.cartService.removeFromCart(itemId);
    this.loadCart();
  }

  editItem(itemId: any) {
    this.cartService.removeFromCart(itemId);
    this.loadCart();
  }

  removeItem(itemId: any) {
    this.cartService.removeFromCart(itemId);
    this.loadCart();
  }

  clearCart() {
    this.cartService.deleteCarrito_partner();
    this.loadCart();
  }

  applyPromoCode() {
    // Lógica para aplicar un código promocional
    //console.log(this.strCodigoPromocional)
    //--1.- validar si el codigo promocional existe
    let codigo_existe =this.arrayDescuentos.find(o=>o.strCodigoPromocional==this.strCodigoPromocional);
    if(!codigo_existe){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El Cupón de Descuento No Existe" 
      });
    }
    else{
     // console.log(codigo_existe);
      let monto_minimo = codigo_existe.dblMontoMinimo?codigo_existe.dblMontoMinimo:0; //revisamos si existe condicion de monto minimo
      let cantidad_minima =codigo_existe.dblCantidadMinima?codigo_existe.dblCantidadMinima:0;
      if(monto_minimo >0){ //revisar si cumple con el monto minimo
        if (this.dblSubTotal >= monto_minimo)
        {
          this.objCupon_descuento = codigo_existe;
         // this.cartService.applyCoupon(codigo_existe);
        } 
        else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No cumple con el Monto Mínimo de Compra" 
          });
        
        }
      }
      else if(cantidad_minima >0){
        if (this.total_items >= cantidad_minima)
          {
            this.objCupon_descuento = codigo_existe;
           // this.cartService.applyCoupon(codigo_existe);
          } 
          else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "No cumple con la Cantidad Mínima de Compra" 
            });
          
          }
      }
      else {
        this.objCupon_descuento = codigo_existe;
       // this.cartService.applyCoupon(codigo_existe);
      }
      
     
    }
  }
fnCancelarCodigo(){
  this.objCupon_descuento ={};
  //this.cartService.desapplyCoupon(this.objCupon_descuento);
}
  proceedToPayment() {
    let xmlArts =[];
          this.spiner = true;
          this.cartItems.forEach(art => {
            xmlArts.push({
              dblCantidad:art.dblCantidad,
              strArticulo:art.strArticulo,
              strOpcion:art.strOpcion,
              dblPrecio:art.dblPrecioLista,
              dblPrecioDescuento:art.dblPrecioConDescuento
              })
          });
        
    //agregamos el envio
  /*  xmlArts.push({
      dblCantidad:1,
      strArticulo:'ENVIO',
      strOpcion:'',
      dblPrecio:this.dblEnvio,
      dblPrecioDescuento:0
      }) */

    let id_pedido= localStorage.getItem('id_pedido');
    
    if(id_pedido && id_pedido !=''){
      let articulo = {
        strAccion: "update_preOrden_partner",  
        strConexion: this.strConexion,
        dblImporte:this.dblTotal ,
       // strDescuento:this.discount,
       strSucursal:this.objEnvioPartner && this.objEnvioPartner.strSucursal?  this.objEnvioPartner.strSucursal:null,
       strAlmacen:this.strAlmacen,
       strCteenviar: this.strCteenviar,
        xmlArticulos:xmlArts,
        intID_Pedido: this.desencriptarParametro(id_pedido),
        strDescuento: this.objCupon_descuento.strCodigoPromocional?this.objCupon_descuento.strTipo:'',
        dblDescuentoCantidad:this.objCupon_descuento.strCodigoPromocional?this.objCupon_descuento.dblCantidad:'',
        strCliente: this.strNumCte
      };  
      
      this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
        result => { 
          let rest = result[0]
          if(rest.code == "200"){
            this.router.navigate(['/partner-pasarela'], {queryParams: {id: id_pedido } });
          }else{
            Swal.fire("Error",rest.strMensaje,"error")
          }
    
        this.spiner =false;
        },error=>{
          console.log(error.message)
          this.spiner =false;
        });
      
    }
    else {

      let articulo = {
        strSucursal:this.objEnvioPartner && this.objEnvioPartner.strSucursal?  this.objEnvioPartner.strSucursal:null,
        strCliente:this.strNumCte ,
        strAccion: "setOrden_partner",  
        strTipo: this.strTipo_envio_partner,
        strConexion: this.strConexion,
        dblImporte:this.dblTotal , 
        strAlmacen:  this.objEnvioPartner && this.objEnvioPartner.strAlmacen? this.objEnvioPartner.strAlmacen: this.strAlmacen,
        strCteenviar: this.strCteenviar,
        xmlArticulos:xmlArts,
        strDescuento: this.objCupon_descuento.strCodigoPromocional?this.objCupon_descuento.strTipo:'',
        dblDescuentoCantidad:this.objCupon_descuento.strCodigoPromocional?this.objCupon_descuento.dblCantidad:'',
        intID_Contacto: this.userData_tienda && this.userData_tienda.length>0 && this.userData_tienda[0].intID_Contacto?this.userData_tienda[0].intID_Contacto:null,
        intID_Direccion:this.userData_tienda && this.userData_tienda.length>0 && this.userData_tienda[0].intID_Direccion?this.userData_tienda[0].intID_Direccion:null
      };  
      console.log(articulo);
            this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
            result => { 
              let rest = result[0]
              if(rest.code == "200"){ 

                const idEncriptado = this.encriptarParametro(rest.intID_Pedido.toString());
                localStorage.setItem('id_pedido', idEncriptado);
                this.router.navigate(['/partner-pasarela'], {queryParams: {id: idEncriptado } });
               
              }else{
                Swal.fire("Error",rest.strMensaje,"error")
              }
        
            this.spiner =false;
            },error=>{
              console.log(error.message)
              this.spiner =false;
            }); 
    }
  }

  desencriptarParametro(idEncriptado: string): string {
    const bytes = CryptoJS.AES.decrypt(idEncriptado, this.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  encriptarParametro(id: string ): string {
    return CryptoJS.AES.encrypt(id, this.secretKey).toString();
  }

  fnSetPreorden(){

 
  }

 fnSumasItems(){
  let pMonedero =0;
  let pDescuento=0;
  this.dblDescuento=0;
  this.dblSubTotal = 0;
  this.intIva = 0;
  this.dblTotal = 0;
  this.cartItems.forEach(art => {
    this.dblSubTotal = this.dblSubTotal + (art.dblCantidad * art.dblPrecioLista);
    if(art.dblPrecioConDescuento){ //si existe un descuento, calculamos el total tomando en cuenta el prcio de descuento
      this.dblDescuento = this.dblDescuento + (art.dblCantidad * (art.dblPrecioLista - art.dblPrecioConDescuento));
      this.strNombre_Descuento = art.strNombreDescuento;

      /**inicializamos siempre la promocion en tipo descuento */
      this.selectedPromotion = localStorage.getItem('opcion_promocion')? localStorage.getItem('opcion_promocion'):'1'  ; 
      pMonedero = art.pMonedero;
      pDescuento = art.pDescuento;
    }
   
  });
  

 // this.intIva = this.dblSubTotal * .16
  this.dblTotal = this.dblDescuento > 0 ? this.dblSubTotal - this.dblDescuento : this.dblSubTotal;//calculamos el total tomando en cuenta el prcio de descuento this.intIva+
  this.dblTotal= this.dblTotal + this.dblEnvio;
  /**inicializamos las promociones */
  //descuento
  this.objPromocionDescuento.dblImporte =  this.dblDescuento > 0 ? this.dblDescuento:  this.dblTotal * pDescuento;
  this.objPromocionDescuento.strNombre =  pDescuento * 100 + '% DE DESCUENTO'
   //monedero
  this.objPromocionMonedero.dblImporte =  (this.dblSubTotal * pMonedero);
}

 addToCart(obj:any)
 {
 
  
     //agregar a carrito
     this.localSt.store('strArticulo_Seleccionado_Cotizador',obj);
     this.localSt.clear('strArticulo_Seleccionado_Comodato' );
     this.router.navigate(['/detalle_articulo'], {     });
 }
 fnCapInput(event: Event,obj:any){
  const input2 = event.target as HTMLInputElement;
  /* let currentValue =  parseFloat(input2.value); */
  let currentValue = input2.value;
  if (currentValue.trim() === '') {
    currentValue = '1';
    input2.value = currentValue; // Actualiza el valor en el input
  }

    // Convertir a entero
    let parsedValue = parseInt(currentValue, 10);
  // Si el valor es menor o igual a 0, se ajusta a 1
  if (parsedValue <= 0 || isNaN(parsedValue)) {
    parsedValue = 1;
    input2.value = currentValue.toString();
  } 
    if(obj.strEstatus == "DESCONTINUADO" && parseFloat(input2.value)>= obj.intDisponibleNacional ){
      obj.dblCantidad = obj.intDisponibleNacional+1
      input2.value = obj.intDisponibleNacional.toString();
      
      this.cartService.updateQuantity(obj, 'disminuye');
      this.loadCart();
    }else{
      obj.dblCantidad = parseFloat(input2.value)+1
      this.cartService.updateQuantity(obj, 'disminuye');
      this.loadCart(); 
    }
   
 }
 preventPaste(event: ClipboardEvent) {
  event.preventDefault();
}
 preventDecimal(event: KeyboardEvent) {
  if (event.key === '.') {
    event.preventDefault(); // Previene la entrada del punto o "e" (si el input es numérico)
  }
}
 fnLimpiarCarrito(){
  this.cartService.deleteCarrito_partner();
 }


 fnGetDisponible(art:any){
  this.arraySaldou =[] 
  let articulo = {
    strAccion: "getDisponible",
    strConexion: this.strConexion,
    strArticulo:art.strArticulo
  };
  this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
    result => { 
        this.arraySaldou = result; 
        this.objDisponible_almacen = art.strOpcion !=''? this.arraySaldou.find(o=>o.strAlmacen==art.strOpcion && o.strOpcion==art.strOpcion) :this.arraySaldou.find(o=>o.strAlmacen==1);
        this.dblDisponible_nacional=0
        this.dblReservado_nacional=0
        let nacional_opcion =[]
        
        nacional_opcion = art.strOpcion !=''? this.arraySaldou.filter(o=> o.strOpcion==art.strOpcion) :this.arraySaldou;
        nacional_opcion.forEach(element => {
          let disponible=element && element.dblCantidad ?element.dblCantidad :0;
          let reservado =element && element.dblReservado ?element.dblReservado :0;
          this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
          this.dblReservado_nacional=this.dblReservado_nacional +reservado;
        }); 
       this.intDisponible = this.dblDisponible_nacional - this.dblReservado_nacional
       art.intDisponibleNacional = this.intDisponible
      
    });
}
 encryptData(data: any): string {
      try {
        const stringifiedData = JSON.stringify(data); // Convertir objeto a string
        const encrypted = CryptoJS.AES.encrypt(stringifiedData, this.secretKeys).toString();
        return encodeURIComponent(encrypted); // Codificar para URL
      } catch (error) {
        console.error('Error al encriptar datos:', error);
        return '';
      }
    }
 
}
