import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser'
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { AltaClienteService } from '../servicios/alta-cliente.service';
@Component({
  selector: 'app-altacliente',
  templateUrl: './altacliente.component.html',
  styleUrls: ['./altacliente.component.css']
})
export class AltaclienteComponent implements OnInit {
  facturaForm: FormGroup;
  public strTipoCliente: string = "CREDITO";
  public strConexion: string = "";
  public strFecha: Date;
  public spinerAll: boolean = false;

  @ViewChild('fileIne') fileIne!: ElementRef;
  @ViewChild('fileConstanciaFiscal') fileConstanciaFiscal!: ElementRef;
  @ViewChild('fileEdoCuenta') fileEdoCuenta!: ElementRef;
  @ViewChild('fileBuroCredito') fileBuroCredito!: ElementRef;
  @ViewChild('fileCompraVenta') fileCompraVenta!: ElementRef;
  @ViewChild('filePagare') filePagare!: ElementRef;
  @ViewChild('fileActaConst') fileActaConst!: ElementRef;
  @ViewChild('fileComproDomicilio') fileComproDomicilio!: ElementRef;

  public nombreIne: string = ""; 
  public nombreConstanciaFiscal: string = ""; 
  public nombreEdoCuenta: string = "";
  public nombreBuroCredito: string = "";
  public nombreCompraVenta: string = "";
  public nombrePagare: string = "";
  public nombreAltaConst: string = "";
  public nombreCompDomicilio: string = ""; 
  public strCliente: any = ''
  public fileIneSave: File;
  public fileConsFiscalSave: File;
  public fileEdoCuentSave: File;
  public fileBuroSave: File;
  public fileCompraVtaSave: File;
  public filePagareSave: File;
  public fileActaConsSave: File;
  public fileDomicilioSave: File;

  public formBanck: FormGroup;
  public formDatosFiscales: FormGroup;
  public formContacto: FormGroup;
  public formVendedor: FormGroup;
  public formPreguntas: FormGroup;
  public formContactoPago: FormGroup;
  public formReferencias: FormGroup;

  public arrayColonias: any = [];
  public arrayCfdi: any = [];
  public arrayFormaPago: any = [];
  public arrayRegimenFiscal: any = [];
  public arrayAgentes: any = [];
  public arraySucursales: any = [];
  public arrayCondicionPago: any = [];
  public arrayTipoCliente: any = [];
  public arrayBancos: any = [];
  public arrayArchivos: any = [];
  public boolDeshabilita_Form_Datos_Generales: boolean = false

  public objSucursal: any = {};
  public objInfoAgente: any = {};
  public objAgente: any = {};
  public objAlta_Cte: any = {};
  public objAlta_Cte_Solcitud: any = {};
  public strAccion_Solicitud: string = ''
  public boolDeshabilita_Form: boolean = true;//false;
  public url_ver: string = '';
  public titulo_ver: string = '';
  public arrayContactos:any=[];
  constructor(private sanitizer: DomSanitizer, private _altaCteServ: AltaClienteService, private _filtroServ: FiltrosService, public _alertify: AlertifyService, private formbuilder: FormBuilder, public router: Router, elem: ElementRef, public route: ActivatedRoute, private cdr: ChangeDetectorRef, public _serviciosService: PeticionesService, public localSt: LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.objInfoAgente = {
      stragente: this.localSt.retrieve('stragente'),
      strnombre: this.localSt.retrieve('strnombre'),
      strsucursal: this.localSt.retrieve('strsucursal'),
      strusuario: this.localSt.retrieve('strusuario'),
    }
    this.strFecha = new Date();
    this.fnGetFiltros();
   /*  console.log(this.objAgente); */
    this.initForms();
    
    this.facturaForm = this.formbuilder.group({
      nombre: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      apellidoMaterno: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      envioMasivo: ['', Validators.required],
      envioCFD: ['', Validators.required]
    });
  }

  ngOnInit(): void {

    //------------inicio servicio----------------------------------
    this._altaCteServ.ObjAlta_Cte$.subscribe((nuevoValor) => {
   /**/    console.log(nuevoValor) 
      this.objAlta_Cte = nuevoValor;
      if (this.objAlta_Cte && this.objAlta_Cte.strOrigen && this.objAlta_Cte.strOrigen == "/prospectos/") {
        this.fnllenarInputs()
      }
      else if (this.objAlta_Cte && this.objAlta_Cte.strOrigen && this.objAlta_Cte.strOrigen == "/SolicitudAltaCte/") {
        //TIPOSS DE ACCIONES. VER EDITAR ACTUALIZAR_CREDITO
        //obtenemos el valor de la accion a realizar, ya sea ver, o editar
        this.strAccion_Solicitud = this.objAlta_Cte.strAccion ? this.objAlta_Cte.strAccion : '';

        this.strTipoCliente = this.objAlta_Cte.TipoCredito_Contado; //this.strAccion_Solicitud && this.strAccion_Solicitud=='ACTUALIZAR_CREDITO'?'CREDITO':this.strTipoCliente
        this.boolDeshabilita_Form = this.strAccion_Solicitud && this.strAccion_Solicitud == 'VER' ? true : false;
        this.boolDeshabilita_Form_Datos_Generales = this.strAccion_Solicitud && this.strAccion_Solicitud == 'DATOS_GENERALES' ? true : false;
        if (this.boolDeshabilita_Form) {
          this.formVendedor.disable();
        /*   this.formContacto.disable(); */
      /*     this.formContactoPago.disable(); */
          this.formDatosFiscales.disable();
          this.formPreguntas.disable();
          this.formReferencias.disable();
          this.formBanck.disable();
          this.fnllenarInputs_2();
        } else if (this.boolDeshabilita_Form_Datos_Generales) {
          this.formReferencias.disable();
        /*   this.formContactoPago.disable(); */
          this.formPreguntas.disable();
       /*    this.formContacto.disable(); */
          //Informacion general
          this.formVendedor.controls['strNombreComercial'].disable()
          this.formVendedor.controls['strVendedor'].disable()
          this.formVendedor.controls['strSucursal'].disable()
          //Informacion fiscal
          this.formDatosFiscales.controls['strRfc'].disable()
          this.formDatosFiscales.controls['strRazonSocial'].disable()
          this.formDatosFiscales.controls['strRegimenFiscal'].disable()
          this.formDatosFiscales.controls['strCp'].disable()
          this.formDatosFiscales.controls['strEstado'].disable()
          this.formDatosFiscales.controls['strCiudad'].disable()
          this.formDatosFiscales.controls['strColonia'].disable()
          this.formDatosFiscales.controls['strCalle'].disable()
          this.formDatosFiscales.controls['strNoExterior'].disable()
        }

        //validamos para poder editar
        if (this.strAccion_Solicitud == 'EDITAR' &&( this.objAlta_Cte.strTipoSolicitud == 'ALTA_CREDITO' || this.objAlta_Cte.strTipoSolicitud == 'CONTADO A CREDITO'))
        {
          this.fnllenarInputs_2();
        }
      }
    });


    //------------fin servicio --------------------------------------
    this.fnGetCfdi();
    this.fnGetRegimenFiscal();
    this.fnGetFormaPago();
    this.fnGetTipoCliente();
    this.fnGetCondicionPago();
    this.fnGetBancos();
    this.onTipoClienteChange();
  }
 /***fomulario de enviiar correos */
 dfnEnviar_dataFactura() {
  if (this.facturaForm.valid) {
    console.log(this.facturaForm.value);
    let objFC = this.facturaForm.value
    console.log(objFC.nombre  )
    // Aquí puedes agregar la lógica para enviar los datos
  }
}

get isFormValid() {
  return this.facturaForm.valid;
}

  fnGetCliente_Contactos(cliente:string) {
    this.arrayContactos=[];
 
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getEmail_Factura',
        strCliente: cliente,
        strConexion: this.strConexion
      }, 'sp_modventas_Directorio').subscribe(
        result => {
           this.arrayContactos=result;
        },
        error => {
          var error = <any>error;
          console.log(error);
        });
    

  }
  fnEnviar_dataFactura( ) {
    if (this.facturaForm.valid) {
      console.log(this.facturaForm.value);
      let objFC = this.facturaForm.value
 
      // Aquí puedes agregar la lógica para enviar los datos
    
 
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'setEmail_Factura',
        strCliente: this.strCliente,
        strConexion: this.strConexion,
        strNombre:objFC.nombre ,
        strApellidoPaterno:objFC.apellidoPaterno,
        strApellidoMaterno:objFC.apellidoMaterno,
        streMail:objFC.email, 
        intPeriodo:  objFC.envioCFD=='SI'? 1:0,
        intEjercicio:objFC.envioMasivo=='SI'? 1:0
      }, 'sp_modventas_Directorio').subscribe(
        result => {
           let objResult=result[0]?result[0]:{code:409, strMensaje:'Error'};
           let code=objResult.code;
           let mensaje=objResult.strMensaje;
          let tipo =code==200?"success":"error";
          Swal.fire({
            title:tipo ,
            text: mensaje,
            icon: code==200?"success":"error"
          });
          this.fnGetCliente_Contactos(this.strCliente);
        },
        error => {
          var error = <any>error;
          console.log(error);
        });
      }
      else {

      }

  }
  fnEliminarCorreo(id:number){
    let objEliminar={
      strAccion: 'deleteContacto_Factura',
      strCliente: this.strCliente,
      strConexion: this.strConexion,
      intIDCteCto:id , 
      
    };
    console.log(objEliminar)
   
    this._serviciosService.mostrarCotizaciones(objEliminar, 'sp_modventas_Directorio').subscribe(
      result => {
         let objResult=result[0]?result[0]:{code:409, strMensaje:'Error'};
         let code=objResult.code;
         let mensaje=objResult.strMensaje;
        let tipo =code==200?"success":"error";
        Swal.fire({
          title:tipo ,
          text: mensaje,
          icon: code==200?"success":"error"
        });
        this.fnGetCliente_Contactos(this.strCliente);
      },
      error => {
        var error = <any>error;
        console.log(error);
      }); 
  }
/**fin de formulario enviiar correo */
 
  fnOpenModal_VER(documento: string, url: string) {
    this.titulo_ver = documento;
    this.url_ver = url;

  }
  getembenurl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  fnllenarInputs_2() {
   
    let url_Ecodeli = 'https://www.ecodeli.com/'
    this.nombreConstanciaFiscal = this.objAlta_Cte.strUrl_CedulaFiscal ? url_Ecodeli + this.objAlta_Cte.strUrl_CedulaFiscal : '';
    this.nombreEdoCuenta = this.objAlta_Cte.strUrl_EdoCta ? url_Ecodeli + this.objAlta_Cte.strUrl_EdoCta : '';
    this.nombreBuroCredito = this.objAlta_Cte.strUrl_BuroCredito ? url_Ecodeli + this.objAlta_Cte.strUrl_BuroCredito : '';
    this.nombreCompraVenta = this.objAlta_Cte.strUrl_ContratoBase ? url_Ecodeli + this.objAlta_Cte.strUrl_ContratoBase : '';
    this.nombrePagare = this.objAlta_Cte.strUrl_Pagare ? url_Ecodeli + this.objAlta_Cte.strUrl_Pagare : '';
    this.nombreAltaConst = this.objAlta_Cte.strUrl_ActaConstitutiva ? url_Ecodeli + this.objAlta_Cte.strUrl_ActaConstitutiva : '';
    this.nombreCompDomicilio = this.objAlta_Cte.strUrl_CDomicilio ? url_Ecodeli + this.objAlta_Cte.strUrl_CDomicilio : '';
    this.nombreIne = this.objAlta_Cte.strUrl_Ine ? url_Ecodeli + this.objAlta_Cte.strUrl_Ine : '';

    this.formVendedor.controls['strNombreComercial'].setValue(this.objAlta_Cte.strNombreComercial);
    this.formVendedor.controls['strTipoCliente'].setValue(this.objAlta_Cte.strTipoCliente);
    this.formVendedor.controls['strTelefono'].setValue(this.objAlta_Cte.strTelefono);
    this.formVendedor.controls['strVendedor'].setValue(this.objAlta_Cte.strNombreAgente);
    let strSucursalNombre = this.objAlta_Cte.strSucursalNombre ? this.objAlta_Cte.strSucursalNombre : '';
    this.formVendedor.controls['strSucursal'].setValue(strSucursalNombre);
    this.objInfoAgente.strsucursal = this.objAlta_Cte.strSucursal;
    this.objAgente.stragente = this.objAlta_Cte.strAgente
    this.formVendedor.controls['strCondicionPago'].setValue(this.objAlta_Cte.strCondicion);
    let credito_limite = this.objAlta_Cte.dblCreditoLimite?parseFloat(this.objAlta_Cte.dblCreditoLimite):0;
    this.formVendedor.controls['strLimiteCredito'].setValue(credito_limite);

    this.formDatosFiscales.controls['strRfc'].setValue(this.objAlta_Cte.strRFC);
    this.formDatosFiscales.controls['strRazonSocial'].setValue(this.objAlta_Cte.strRazonSocial);
    this.formDatosFiscales.controls['strCfdi'].setValue(this.objAlta_Cte.strUsoCFDI);
    this.formDatosFiscales.controls['strRegimenFiscal'].setValue(this.objAlta_Cte.strFiscalRegimen);

    this.formDatosFiscales.controls['strCp'].setValue(this.objAlta_Cte.strCodigoPostal);
    this.formDatosFiscales.controls['strEstado'].setValue(this.objAlta_Cte.strEstado);
    this.formDatosFiscales.controls['strCiudad'].setValue(this.objAlta_Cte.strPoblacion);
    this.formDatosFiscales.controls['strColonia'].setValue(this.objAlta_Cte.strColonia);
    this.formDatosFiscales.controls['strCalle'].setValue(this.objAlta_Cte.strDireccion);
    this.formDatosFiscales.controls['strNoExterior'].setValue(this.objAlta_Cte.strDireccionNumero);
    this.formDatosFiscales.controls['strTipoPago'].setValue(this.objAlta_Cte.strFormaPagoTipo);

    this.formContacto.controls['strContacto'].setValue(this.objAlta_Cte.strContacto1);
    this.formContacto.controls['strCorreo'].setValue(this.objAlta_Cte.streMail1);
    this.formContacto.controls['strTelefono'].setValue(this.objAlta_Cte.strTelefono1);

    this.formContactoPago.controls['strContacto'].setValue(this.objAlta_Cte.strContacto2);
    this.formContactoPago.controls['strCorreo'].setValue(this.objAlta_Cte.streMail2);
    this.formContactoPago.controls['strTelefono'].setValue(this.objAlta_Cte.strTelefono2);

    this.formBanck.controls['strBanco'].setValue(this.objAlta_Cte.strClaveBanco);
    this.formBanck.controls['strCuenta'].setValue(this.objAlta_Cte.strCuenta);
    this.formBanck.controls['strCuentaClave'].setValue(this.objAlta_Cte.strClaveInterbancaria);

    this.formReferencias.controls['strEmpresa'].setValue(this.objAlta_Cte.strEmpresaReferencia1);
    this.formReferencias.controls['strNombre'].setValue(this.objAlta_Cte.strNombreReferencia1);
    this.formReferencias.controls['strTelefono'].setValue(this.objAlta_Cte.strTelefonoReferencia1);

    this.formReferencias.controls['strEmpresa2'].setValue(this.objAlta_Cte.strEmpresaReferencia2);
    this.formReferencias.controls['strNombre2'].setValue(this.objAlta_Cte.strNombreReferencia2);
    this.formReferencias.controls['strTelefono2'].setValue(this.objAlta_Cte.strTelefonoReferencia2);

    this.formPreguntas.controls['strPregunta1'].setValue(this.objAlta_Cte.strFormato_entrega);
    this.formPreguntas.controls['strPregunta2'].setValue(this.objAlta_Cte.strOrden_compra);
    this.fnGetDatosFiscales();
  }

  fnllenarInputs() {
    this.formVendedor.controls['strNombreComercial'].setValue(this.objAlta_Cte.strNombreComercial);
    this.formVendedor.controls['strTipoCliente'].setValue(this.objAlta_Cte.strTipoCliente);
    this.formVendedor.controls['strTelefono'].setValue(this.objAlta_Cte.strTelefono);
    this.formVendedor.controls['strVendedor'].setValue(this.objAlta_Cte.strNombreAgente);
    this.objAgente.stragente = this.objAlta_Cte.strAgente


    this.formDatosFiscales.controls['strRfc'].setValue(this.objAlta_Cte.strRfc);
    this.formDatosFiscales.controls['strRazonSocial'].setValue(this.objAlta_Cte.strRazonSocial);
    this.formDatosFiscales.controls['strCfdi'].setValue(this.objAlta_Cte.strCfdi);
    this.formDatosFiscales.controls['strRegimenFiscal'].setValue(this.objAlta_Cte.strRegimenFiscal);

    this.formDatosFiscales.controls['strCp'].setValue(this.objAlta_Cte.strCp);
    this.formDatosFiscales.controls['strEstado'].setValue(this.objAlta_Cte.strEstado);
    this.formDatosFiscales.controls['strCiudad'].setValue(this.objAlta_Cte.strCiudad);
    this.formDatosFiscales.controls['strColonia'].setValue(this.objAlta_Cte.strColonia);
    this.formDatosFiscales.controls['strCalle'].setValue(this.objAlta_Cte.strCalle);
    this.formDatosFiscales.controls['strNoExterior'].setValue(this.objAlta_Cte.strNoExterior);

    this.formContacto.controls['strContacto'].setValue(this.objAlta_Cte.strContacto1);
    this.formContacto.controls['strCorreo'].setValue(this.objAlta_Cte.streMail1);
    this.formContacto.controls['strTelefono'].setValue(this.objAlta_Cte.strTelefono1);

    this.formContactoPago.controls['strContacto'].setValue(this.objAlta_Cte_Solcitud.strContacto2);
    this.formContactoPago.controls['strCorreo'].setValue(this.objAlta_Cte_Solcitud.streMail2);
    this.formContactoPago.controls['strTelefono'].setValue(this.objAlta_Cte_Solcitud.strTelefono2);
    this.fnGetDatosFiscales();
  }

  fnllenarInputs_3() {
    console.log(this.objAlta_Cte_Solcitud)
    this.formVendedor.controls['strNombreComercial'].setValue(this.objAlta_Cte_Solcitud.strNombreComercial);
    this.formVendedor.controls['strTipoCliente'].setValue(this.objAlta_Cte_Solcitud.strTipoCliente);
    this.formVendedor.controls['strTelefono'].setValue(this.objAlta_Cte_Solcitud.strTelefono);
    this.formVendedor.controls['strVendedor'].setValue(this.objAgente.strNombre);
    //this.formVendedor.controls['strSucursal'].setValue(this.objAlta_Cte_Solcitud.strSucursal);
    let strSucursalNombre = this.objSucursal.strNombreSucursal ? this.objSucursal.strNombreSucursal : '';
    this.formVendedor.controls['strSucursal'].setValue(strSucursalNombre);
    this.objInfoAgente.strsucursal = this.objAlta_Cte_Solcitud.strSucursal;

    this.formVendedor.controls['strCondicionPago'].setValue(this.objAlta_Cte_Solcitud.strCondicion);
    this.formVendedor.controls['strLimiteCredito'].setValue(this.objAlta_Cte_Solcitud.dblCreditoLimite);
    this.objAgente.stragente = this.objAlta_Cte.strAgente

    this.formDatosFiscales.controls['strRfc'].setValue(this.objAlta_Cte_Solcitud.strRfc);
    this.formDatosFiscales.controls['strRazonSocial'].setValue(this.objAlta_Cte_Solcitud.strNombreComercial);
    this.formDatosFiscales.controls['strCfdi'].setValue(this.objAlta_Cte_Solcitud.strCfdi);
    this.formDatosFiscales.controls['strRegimenFiscal'].setValue(this.objAlta_Cte_Solcitud.strFiscalRegimen);
    this.formDatosFiscales.controls['strCfdi'].setValue(this.objAlta_Cte_Solcitud.strUsoCFDI);

    this.formBanck.controls['strBanco'].setValue(this.objAlta_Cte_Solcitud.strClaveBanco);
    this.formBanck.controls['strCuenta'].setValue(this.objAlta_Cte_Solcitud.strCuenta);
    this.formBanck.controls['strCuentaClave'].setValue(this.objAlta_Cte_Solcitud.strClaveInterbancaria);

    this.formDatosFiscales.controls['strCp'].setValue(this.objAlta_Cte_Solcitud.strCodigoPostal);
    this.formDatosFiscales.controls['strEstado'].setValue(this.objAlta_Cte_Solcitud.strEstado);
    this.formDatosFiscales.controls['strCiudad'].setValue(this.objAlta_Cte_Solcitud.strCiudad);
    this.formDatosFiscales.controls['strColonia'].setValue(this.objAlta_Cte_Solcitud.strColonia);
    this.formDatosFiscales.controls['strCalle'].setValue(this.objAlta_Cte_Solcitud.strCalle);
    this.formDatosFiscales.controls['strNoExterior'].setValue(this.objAlta_Cte_Solcitud.strNoExterior);
    this.formDatosFiscales.controls['strTipoPago'].setValue(this.objAlta_Cte_Solcitud.strFormaPagoTipo);

    this.formContacto.controls['strContacto'].setValue(this.objAlta_Cte_Solcitud.strContacto1);
    this.formContacto.controls['strCorreo'].setValue(this.objAlta_Cte_Solcitud.streMail1);
    this.formContacto.controls['strTelefono'].setValue(this.objAlta_Cte_Solcitud.strTelefono1);

    this.formContactoPago.controls['strContacto'].setValue(this.objAlta_Cte_Solcitud.strContacto2);
    this.formContactoPago.controls['strCorreo'].setValue(this.objAlta_Cte_Solcitud.streMail2);
    this.formContactoPago.controls['strTelefono'].setValue(this.objAlta_Cte_Solcitud.strTelefono2);
    this.fnGetDatosFiscales();
  }

  private initForms() {
    this.formDatosFiscales = this.formbuilder.group({
      strRfc: new FormControl('', [Validators.minLength(12), Validators.maxLength(13), Validators.required]),
      strRazonSocial: new FormControl('', [Validators.required]),
      strCfdi: new FormControl('', [Validators.required]),
      strRegimenFiscal: new FormControl('', [Validators.required]),
      strTipoPago: new FormControl('', [Validators.required]),
      strCp: new FormControl('', [Validators.required]),
      strEstado: new FormControl('', [Validators.required]),
      strCiudad: new FormControl('', [Validators.required]),
      strColonia: new FormControl('', [Validators.required]),
      strColoniaOtra: new FormControl('',[]),
      strCalle: new FormControl('', [Validators.required]),
      strNoExterior: new FormControl('', [Validators.required]),
      strRuta: new FormControl('', [])
    });

    this.formVendedor = this.formbuilder.group({
      strNombreComercial: new FormControl('', [Validators.required]),
      strTipoCliente: new FormControl('', [Validators.required]),
      strTelefono: new FormControl('', [Validators.minLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strVendedor: new FormControl(this.objAgente && this.objAgente.strNombre ? this.objAgente.strNombre : '', [Validators.required]),
      strSucursal: new FormControl(this.objSucursal && this.objSucursal.strNombreSucursal ? this.objSucursal.strNombreSucursal : '', [Validators.required]),
      strLimiteCredito: new FormControl('', [Validators.required]),
      strCondicionPago: new FormControl('', [Validators.required]),
    },
  );
 

    this.formBanck = this.formbuilder.group({
      strBanco: new FormControl('', [Validators.required]),
      strCuenta: new FormControl('', [Validators.minLength(10), Validators.required]),
      strCuentaClave: new FormControl('', [Validators.minLength(18), Validators.required]),
    });

    this.formContacto = this.formbuilder.group({
      strContacto: new FormControl('', [Validators.required]),
      strCorreo: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})?$'), Validators.required]),
      strTelefono: new FormControl('', [Validators.minLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')])
    });

    this.formContactoPago = this.formbuilder.group({
      strContacto: new FormControl('', [Validators.required]),
      strCorreo: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})?$'), Validators.required]),
      strTelefono: new FormControl('', [Validators.minLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')])
    });

    this.formReferencias = this.formbuilder.group({
      strEmpresa: new FormControl('', [Validators.required]),
      strNombre: new FormControl('', [Validators.required]),
      strTelefono: new FormControl('', [Validators.minLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strEmpresa2: new FormControl('', [Validators.required]),
      strNombre2: new FormControl('', [Validators.required]),
      strTelefono2: new FormControl('', [Validators.minLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')])
    });

    this.formPreguntas = this.formbuilder.group({
      strPregunta1: new FormControl('', [Validators.required]),
      strPregunta2: new FormControl('', [Validators.required])
    });
  }

  fnGetFiltros() {
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      // console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
      this.objAgente = this.arrayAgentes.find((item) => item.strAgente == this.objInfoAgente.stragente)
    });
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      //console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
      this.objSucursal = this.arraySucursales.find((item) => item.strSucursal == this.objInfoAgente.strsucursal)
    });
   /*  console.log(this.objSucursal); */
  }

  validarNumeros(event: KeyboardEvent) {
    const codigoTecla = event.keyCode;
    // Permite los números (0-9) y la tecla de retroceso (Backspace)
    if (codigoTecla < 48 || codigoTecla > 57) {
      event.preventDefault();
    }
  }

  fnClickIne() {
    this.fileIne.nativeElement.click();
  }

  fnClickconstanciaFiscal() {
    this.fileConstanciaFiscal.nativeElement.click();
  }

  fnClickEdoCuenta() {
    this.fileEdoCuenta.nativeElement.click();
  }

  fnclickBuroCredito() {
    this.fileBuroCredito.nativeElement.click();
  }

  fnClickPagare() {
    this.filePagare.nativeElement.click();
  }

  fnClickActaCons() {
    this.fileActaConst.nativeElement.click();
  }

  fnClickCompraVenta() {
    this.fileCompraVenta.nativeElement.click();
  }

  fnClickComproDomicilio() {
    this.fileComproDomicilio.nativeElement.click();
  }

  handleFileIne(event: Event) {
    let fileInput = event.target as HTMLInputElement;
 
    if (fileInput.files && fileInput.files.length > 0) {
      let fileiNE:File;
      fileiNE = fileInput.files[0]; 

      /* this.fileIneSave = fileInput.files[0]; */ // Obtén el primer archivo de la lista
      if (fileiNE.size>  1.8 * 1024 * 1024) {
        Swal.fire({
          title: "El documento excede el límite de tamaño permitido, que es de 1.8 megabytes.",
          text: "ERROR",
          icon: "error"
        });
      } else {
       /*  console.log('Archivo seleccionado:', this.fileIneSave);
        this.nombreIne = this.fileIneSave.name; */
        let fechaActual = new Date();
        let dia = fechaActual.getDate(); // Día del mes
        let mes = fechaActual.getMonth() + 1; // Mes (se le suma 1 porque los meses empiezan en 0)
        let anio = fechaActual.getFullYear(); // Año
        let hora = fechaActual.getHours(); // Hora
        let minutos = fechaActual.getMinutes(); // Minutos
        let segundos = fechaActual.getSeconds(); // Segundos
        let fechaFormateada = `${dia}-${mes}-${anio}${hora}:${minutos}:${segundos}`;
        let nombreIne= this.formVendedor.get("strNombreComercial").value.replace(/\//g, "-")+"_"+"INE"+"_"+fechaFormateada+"_"+fileiNE.name;
        let nuevoArchivo = new File([fileiNE], nombreIne, {
          type: fileiNE.type
        });
        this.nombreIne = nombreIne;
        this.fileIneSave = nuevoArchivo
      }
    }
  }

  handleFileConstFisc(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      let fileFiscal:File;
      fileFiscal = fileInput.files[0]; 
    /*   this.fileConsFiscalSave = fileInput.files[0];  */
      if (fileFiscal.size >  1.8 * 1024 * 1024) {
        Swal.fire({
          title: "El documento excede el límite de tamaño permitido, que es de 1.8 megabytes.",
          text: "ERROR",
          icon: "error"
        });
      } else {
        let fechaActual = new Date();
        let dia = fechaActual.getDate(); // Día del mes
        let mes = fechaActual.getMonth() + 1; // Mes (se le suma 1 porque los meses empiezan en 0)
        let anio = fechaActual.getFullYear(); // Año
        let hora = fechaActual.getHours(); // Hora
        let minutos = fechaActual.getMinutes(); // Minutos
        let segundos = fechaActual.getSeconds(); // Segundos
        let fechaFormateada = `${dia}-${mes}-${anio}${hora}:${minutos}:${segundos}`;
        console.log('Archivo seleccionado constancia fiscal:', this.fileConsFiscalSave);
        let nombreConstFiscal= this.formVendedor.get("strNombreComercial").value.replace(/\//g, "-")+"_"+"CONSTANCIA_FISCAL"+"_"+fechaFormateada+"_"+fileFiscal.name;
        let nuevoArchivo = new File([fileFiscal], nombreConstFiscal, {
          type: fileFiscal.type
        });
        this.nombreConstanciaFiscal = nombreConstFiscal;
        this.fileConsFiscalSave = nuevoArchivo
      }
    }
  }

  handleFileEdoCuenta(event: Event) {
    let fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      let fileEdoCta:File;
      fileEdoCta = fileInput.files[0]; 
     /*  this.fileEdoCuentSave = fileInput.files[0]; */

      if (fileEdoCta.size > 1.8 * 1024 * 1024) {
        Swal.fire({
          title: "El documento excede el límite de tamaño permitido, que es de 1.8 megabytes.",
          text: "ERROR",
          icon: "error"
        });
      } else {
        let fechaActual = new Date();
        let dia = fechaActual.getDate(); // Día del mes
        let mes = fechaActual.getMonth() + 1; // Mes (se le suma 1 porque los meses empiezan en 0)
        let anio = fechaActual.getFullYear(); // Año
        let hora = fechaActual.getHours(); // Hora
        let minutos = fechaActual.getMinutes(); // Minutos
        let segundos = fechaActual.getSeconds(); // Segundos
        let fechaFormateada = `${dia}-${mes}-${anio}${hora}:${minutos}:${segundos}`;
        let nombreNuevo= this.formVendedor.get("strNombreComercial").value.replace(/\//g, "-")+"_"+"ESTADO_CUENTA"+"_"+fechaFormateada+"_"+fileEdoCta.name;
        let nuevoArchivo = new File([fileEdoCta], nombreNuevo, {
          type: fileEdoCta.type
        });
        this.nombreEdoCuenta = nombreNuevo;
        this.fileEdoCuentSave = nuevoArchivo
      }
    }
  }

  handleFileBuro(event: Event) {
    let fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      let fileReplace:File;
      fileReplace = fileInput.files[0]; 
      /* this.fileBuroSave = fileInput.files[0];  */// Obtén el primer archivo de la lista

      if (fileReplace.size >  1.8 * 1024 * 1024) {
        Swal.fire({
          title: "El documento excede el límite de tamaño permitido, que es de 1.8 megabytes.",
          text: "ERROR",
          icon: "error"
        });
      } else {
        let fechaActual = new Date();
        let dia = fechaActual.getDate(); // Día del mes
        let mes = fechaActual.getMonth() + 1; // Mes (se le suma 1 porque los meses empiezan en 0)
        let anio = fechaActual.getFullYear(); // Año
        let hora = fechaActual.getHours(); // Hora
        let minutos = fechaActual.getMinutes(); // Minutos
        let segundos = fechaActual.getSeconds(); // Segundos
        let fechaFormateada = `${dia}-${mes}-${anio}${hora}:${minutos}:${segundos}`;
        let nombreNuevo= this.formVendedor.get("strNombreComercial").value.replace(/\//g, "-")+"_"+"BURO_CREDITO"+"_"+fechaFormateada+"_"+fileReplace.name;
        let nuevoArchivo = new File([fileReplace], nombreNuevo, {
          type: fileReplace.type
        });
        this.nombreBuroCredito = nombreNuevo;
        this.fileBuroSave = nuevoArchivo

       
      }
    }

  }

  handleFileCompraVenta(event: Event) {
    this.fileCompraVtaSave = null;
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      let fileReplace:File;
      fileReplace = fileInput.files[0]; 
      /* this.fileCompraVtaSave = fileInput.files[0]; */ // Obtén el primer archivo de la lista
      if (fileReplace.size >  1.8 * 1024 * 1024) {
        Swal.fire({
          title: "El documento excede el límite de tamaño permitido, que es de 1.8 megabytes.",
          text: "ERROR",
          icon: "error"
        });
      } else {
        let fechaActual = new Date();
        let dia = fechaActual.getDate(); // Día del mes
        let mes = fechaActual.getMonth() + 1; // Mes (se le suma 1 porque los meses empiezan en 0)
        let anio = fechaActual.getFullYear(); // Año
        let hora = fechaActual.getHours(); // Hora
        let minutos = fechaActual.getMinutes(); // Minutos
        let segundos = fechaActual.getSeconds(); // Segundos
        let fechaFormateada = `${dia}-${mes}-${anio}${hora}:${minutos}:${segundos}`;
        let nombreNuevo= this.formVendedor.get("strNombreComercial").value.replace(/\//g, "-")+"_"+"COMPRA_VENTA"+"_"+fechaFormateada+"_"+fileReplace.name;
        let nuevoArchivo = new File([fileReplace], nombreNuevo, {
          type: fileReplace.type
        });
        this.nombreCompraVenta = nombreNuevo;
        this.fileCompraVtaSave = nuevoArchivo
/* 
        console.log('Archivo seleccionado:', this.fileCompraVtaSave);
        this.nombreCompraVenta = this.fileCompraVtaSave.name; */
      }

    }

  }

  handleFilePagare(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      let fileReplace:File;
      fileReplace = fileInput.files[0]; 
      /* this.filePagareSave = fileInput.files[0]; */ // Obtén el primer archivo de la lista
      if (fileReplace.size >  1.8 * 1024 * 1024) {
        Swal.fire({
          title: "El documento excede el límite de tamaño permitido, que es de 1.8 megabytes.",
          text: "ERROR",
          icon: "error"
        });
      } else {
        let fechaActual = new Date();
        let dia = fechaActual.getDate(); // Día del mes
        let mes = fechaActual.getMonth() + 1; // Mes (se le suma 1 porque los meses empiezan en 0)
        let anio = fechaActual.getFullYear(); // Año
        let hora = fechaActual.getHours(); // Hora
        let minutos = fechaActual.getMinutes(); // Minutos
        let segundos = fechaActual.getSeconds(); // Segundos
        let fechaFormateada = `${dia}-${mes}-${anio}${hora}:${minutos}:${segundos}`;
        let nombreNuevo= this.formVendedor.get("strNombreComercial").value.replace(/\//g, "-")+"_"+"PAGARE"+"_"+fechaFormateada+"_"+fileReplace.name;
        let nuevoArchivo = new File([fileReplace], nombreNuevo, {
          type: fileReplace.type
        });
        this.nombrePagare = nombreNuevo;
        this.filePagareSave = nuevoArchivo

        /* console.log('Archivo seleccionado:', this.filePagareSave);
        this.nombrePagare = this.filePagareSave.name; */
      }
    }
  }

  handleFileAltaConst(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      let fileReplace:File;
      fileReplace = fileInput.files[0]; 
     /*  this.fileActaConsSave = fileInput.files[0];  */// Obtén el primer archivo de la lista
      if (fileReplace.size >  1.8 * 1024 * 1024) {
        Swal.fire({
          title: "El documento excede el límite de tamaño permitido, que es de 1.8 megabytes.",
          text: "ERROR",
          icon: "error"
        });
      } else {
        let fechaActual = new Date();
        let dia = fechaActual.getDate(); // Día del mes
        let mes = fechaActual.getMonth() + 1; // Mes (se le suma 1 porque los meses empiezan en 0)
        let anio = fechaActual.getFullYear(); // Año
        let hora = fechaActual.getHours(); // Hora
        let minutos = fechaActual.getMinutes(); // Minutos
        let segundos = fechaActual.getSeconds(); // Segundos
        let fechaFormateada = `${dia}-${mes}-${anio}${hora}:${minutos}:${segundos}`;
        let nombreNuevo= this.formVendedor.get("strNombreComercial").value.replace(/\//g, "-")+"_"+"ACTA_CONSTITUTIVA"+"_"+fechaFormateada+"_"+fileReplace.name;
        let nuevoArchivo = new File([fileReplace], nombreNuevo, {
          type: fileReplace.type
        });
        this.nombreAltaConst = nombreNuevo;
        this.fileActaConsSave = nuevoArchivo

       
      }
    }

  }

  handleFileComproDomic(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      let fileReplace:File;
      fileReplace = fileInput.files[0]; 
     // this.fileDomicilioSave = fileInput.files[0]; // Obtén el primer archivo de la lista
      if (fileReplace.size >  1.8 * 1024 * 1024) {
        Swal.fire({
          title: "El documento excede el límite de tamaño permitido, que es de 1.8 megabytes.",
          text: "ERROR",
          icon: "error"
        });
      } else {
        let fechaActual = new Date();
        let dia = fechaActual.getDate(); // Día del mes
        let mes = fechaActual.getMonth() + 1; // Mes (se le suma 1 porque los meses empiezan en 0)
        let anio = fechaActual.getFullYear(); // Año
        let hora = fechaActual.getHours(); // Hora
        let minutos = fechaActual.getMinutes(); // Minutos
        let segundos = fechaActual.getSeconds(); // Segundos
        let fechaFormateada = `${dia}-${mes}-${anio}${hora}:${minutos}:${segundos}`;
        let nombreNuevo= this.formVendedor.get("strNombreComercial").value.replace(/\//g, "-")+"_"+"COMPROBANTE_DOMICILIO"+"_"+fechaFormateada+"_"+fileReplace.name;
        let nuevoArchivo = new File([fileReplace], nombreNuevo, {
          type: fileReplace.type
        });
        this.nombreCompDomicilio = nombreNuevo;
        this.fileDomicilioSave = nuevoArchivo
 
      }
    }

  }

  isFormBankValid(): boolean {
    return this.formBanck.valid;
  }

  isFormDatosFiscalesValid(): boolean {
    return this.formDatosFiscales.valid;
  }

  isFormContactoValid(): boolean {
    return this.formContacto.valid;
  }

  isFormContactoPagoValid(): boolean {
    return this.formContactoPago.valid;
  }

  isFormVendedorValid(): boolean {
    return this.formVendedor.valid;
  }

  isFormPreguntasValid(): boolean {
    return this.formPreguntas.valid;
  }

  isFormReferenciasValid(): boolean {
    return this.formReferencias.valid;
  }

  onKeyDown(event: KeyboardEvent) {
    // Código ASCII de la tecla Delete y Retroceso     const DELETE_KEY = 46;     const BACKSPACE_KEY = 8;
    const ENTER_KEY = 13;
    // Verificar si se presionó la tecla Delete o Retroceso
    if (event.keyCode === ENTER_KEY) {
      this.fnGetDatosFiscales();
    } else {
      this.formDatosFiscales.controls['strEstado'].setValue('');
      this.formDatosFiscales.controls['strCiudad'].setValue('');
    }
  }

  fnGetDatosFiscales() {

    if (this.formDatosFiscales.controls['strCp'].value) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getCodigoPostal',
        strCodigoPostal: this.formDatosFiscales.controls['strCp'].value,
        strConexion: this.strConexion
      }, 'sp_modventas_Directorio').subscribe(
        result => {
          if (result.length > 0) {
            this.formDatosFiscales.controls['strEstado'].setValue(result[0].strEstado);
            this.formDatosFiscales.controls['strCiudad'].setValue(result[0].strDelegacion);
            this.arrayColonias = result;
            console.log(this.arrayColonias)
            
            console.log(this.objAlta_Cte);
            console.log(this.arrayColonias)
            let newColonia = this.arrayColonias[0];
            newColonia.strColonia = this.objAlta_Cte.strColonia;
            this.arrayColonias.push(newColonia);
            console.log(this.arrayColonias)
        
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: '¡Codigo Postal Incorrecto!',
            });
            this.formDatosFiscales.controls['strEstado'].setValue('');
            this.formDatosFiscales.controls['strCiudad'].setValue('');
            this.arrayColonias = []
          }
        },
        error => {
          var error = <any>error;
          console.log(error);
        });
    } else {
      console.log('Sin CP')
    }

  }

  onTipoClienteChange() {
    if (this.strTipoCliente == 'CREDITO') {
      this.formVendedor.get('strLimiteCredito').setValidators([Validators.required]);

    } else {
      this.formVendedor.get('strLimiteCredito').clearValidators();

    }
    // Actualiza las validaciones
    this.formVendedor.get('strLimiteCredito').updateValueAndValidity();

  }

  onSelectColonias() {
    console.log(this.formDatosFiscales.controls['strColonia'].value )
    if(this.formDatosFiscales.controls['strColonia'].value == "otra"){
     
      this.formDatosFiscales.get('strColoniaOtra').setValidators([Validators.required]);
    }else{

      this.formDatosFiscales.get('strColoniaOtra').clearValidators();
    }
    
    this.formDatosFiscales.get('strColoniaOtra').updateValueAndValidity();
  }

  fnGetCfdi() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getUsoCfdi',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
        this.arrayCfdi = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetBancos() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getBanco',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
        this.arrayBancos = result

      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetCondicionPago() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getCondicionCredito',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
        this.arrayCondicionPago = result;
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetTipoCliente() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getTipoCliente',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
        this.arrayTipoCliente = result
       /*  console.log(this.arrayTipoCliente) */
      }, error => {
        var error = <any>error;
        console.log(error);
      });
  }

  fnGetRegimenFiscal() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFiscalRegimen',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
       /*  console.log(result) */
        this.arrayRegimenFiscal = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetFormaPago() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFormaPago',
      strConexion: this.strConexion
    }, 'sp_modventas_Directorio').subscribe(
      result => {
        this.arrayFormaPago = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  mostrarDocumentos() {
    document.getElementById('nav-profile-tab').click();
    console.log(this.objAlta_Cte)
  }

  mostrarprincipal() {
    document.getElementById('nav-home-tab').click();
  }

  uploadFiles_Editar(opcion:String) {
    let count=0;
    const formData = new FormData();

    if(this.fileIneSave && this.fileIneSave instanceof File){
      formData.append('files[]', this.fileIneSave, this.fileIneSave.name);
      formData.append('inputIdentifier[]', 'AltaCteCredito/IFE/');
      count=count + 1;
    }
    
    if(this.fileConsFiscalSave && this.fileConsFiscalSave instanceof File){
      formData.append('files[]', this.fileConsFiscalSave, this.fileConsFiscalSave.name);
      formData.append('inputIdentifier[]', 'AltaCteCredito/CedulaFiscal/');
      count=count + 1;
    }

    if((this.fileEdoCuentSave && this.fileEdoCuentSave instanceof File)){
      formData.append('files[]', this.fileEdoCuentSave, this.fileEdoCuentSave.name);
      formData.append('inputIdentifier[]', 'AltaCteCredito/EstadoDeCuenta/');
      count=count + 1;
    }

    if (this.fileBuroSave && this.fileBuroSave instanceof File) {
      formData.append('files[]', this.fileBuroSave, this.fileBuroSave.name);
      formData.append('inputIdentifier[]', 'AltaCteCredito/BuroDeCredito/');
      count=count + 1;
    }

    if (this.fileCompraVtaSave && this.fileCompraVtaSave instanceof File){
      formData.append('files[]', this.fileCompraVtaSave, this.fileCompraVtaSave.name);
      formData.append('inputIdentifier[]', 'AltaCteCredito/ContratoBase/');
      count=count + 1;
    }

    if (this.filePagareSave && this.filePagareSave instanceof File){
      formData.append('files[]', this.filePagareSave, this.filePagareSave.name);
      formData.append('inputIdentifier[]', 'AltaCteCredito/Pagare/');
      count=count + 1;
    }

    if (this.fileActaConsSave && this.fileActaConsSave instanceof File){
      formData.append('files[]', this.fileActaConsSave, this.fileActaConsSave.name);
      formData.append('inputIdentifier[]', 'AltaCteCredito/ActaConsecutiva/');
      count=count + 1;
    }

    if (this.fileDomicilioSave && this.fileDomicilioSave instanceof File){
      formData.append('files[]', this.fileDomicilioSave, this.fileDomicilioSave.name);
      formData.append('inputIdentifier[]', 'AltaCteCredito/ComprobanteDomicilio/');
      count=count + 1;
    }
  
    //revisamos si se adjuntaron archivos
    if (count >0)
    {
      this._serviciosService.uploadFiles(formData).subscribe(
        (response) => {
          console.log(response)
          let contador = 0;
          this.arrayArchivos = response;
          if (this.arrayArchivos.length > 0) {
            this.arrayArchivos.forEach(item => {
              if (item.code == "200") {
                contador = contador + 1
              } else {
                contador = contador - 1
                Swal.fire("Error", "error en documento: ", "error")
              }
            });
             if (contador == this.arrayArchivos.length) {
  
              this.fnEnviardatos_update_Solicitud(this.arrayArchivos);
  
            } else {
              Swal.fire("Error", "En archivos.", "error")
              this.spinerAll = false;
            } 
          } else {
            Swal.fire("Error", "No se recibio respuesta del servidor", "error");
            this.spinerAll = false;
          }
        },
        (error) => {
          Swal.fire("Error", "No se recibio respuesta del servidor", "error")
          console.log(error.message)
          this.spinerAll = false;
        });
    
    }
    else { //si no se adjunto nada, solo actualizamos la informacion de los inputs
      this.fnEnviardatos_update_Solicitud(this.arrayArchivos);
    }
   
  }
  uploadFiles(opcion:String) {
    console.log(this.strTipoCliente)
    if (this.strTipoCliente == 'CREDITO') {
      if ((this.fileIneSave && this.fileIneSave instanceof File) && (this.fileConsFiscalSave && this.fileConsFiscalSave instanceof File) &&
        (this.fileEdoCuentSave && this.fileEdoCuentSave instanceof File) && (this.fileBuroSave && this.fileBuroSave instanceof File) &&
        (this.fileCompraVtaSave && this.fileCompraVtaSave instanceof File) && (this.filePagareSave && this.filePagareSave instanceof File) &&
        (this.fileActaConsSave && this.fileActaConsSave instanceof File) && (this.fileDomicilioSave && this.fileDomicilioSave instanceof File)) {
        this.spinerAll = true;
        const formData = new FormData();
        // Identificadores únicos para cada archivo

        formData.append('files[]', this.fileIneSave, this.fileIneSave.name);
        formData.append('files[]', this.fileConsFiscalSave, this.fileConsFiscalSave.name);
        formData.append('files[]', this.fileEdoCuentSave, this.fileEdoCuentSave.name);
        formData.append('files[]', this.fileBuroSave, this.fileBuroSave.name);
        formData.append('files[]', this.fileCompraVtaSave, this.fileCompraVtaSave.name);
        formData.append('files[]', this.filePagareSave, this.filePagareSave.name);
        formData.append('files[]', this.fileActaConsSave, this.fileActaConsSave.name);
        formData.append('files[]', this.fileDomicilioSave, this.fileDomicilioSave.name);

        formData.append('inputIdentifier[]', 'AltaCteCredito/IFE/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/CedulaFiscal/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/EstadoDeCuenta/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/BuroDeCredito/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/ContratoBase/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/Pagare/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/ActaConsecutiva/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/ComprobanteDomicilio/');

        this._serviciosService.uploadFiles(formData).subscribe(
          (response) => {
            console.log(response)
            let contador = 0;
            this.arrayArchivos = response;
            if (this.arrayArchivos.length > 0) {
              this.arrayArchivos.forEach(item => {
                if (item.code == "200") {
                  contador = contador + 1
                } else {
                  contador = contador - 1
                  Swal.fire("Error", "error en documento: ", "error")
                }
              });
              if (contador == this.arrayArchivos.length) {

                this.fnEnviardatos(this.arrayArchivos);

              } else {
                Swal.fire("Error", "En archivos.", "error")
                this.spinerAll = false;
              }
            } else {
              Swal.fire("Error", "No se recibio respuesta del servidor", "error");
              this.spinerAll = false;
            }
          },
          (error) => {
            Swal.fire("Error", "No se recibio respuesta del servidor", "error")
            console.log(error.message)
            this.spinerAll = false;
          });
      } else {
        Swal.fire("error", "Falta cargar Archivos.", "error");
        this.spinerAll = false;
      }


    } else if (this.strTipoCliente == 'CONTADO') {
      /*if (this.fileConsFiscalSave && this.fileConsFiscalSave instanceof File) {
        this.spinerAll = true;
        // El objeto fileIneSave contiene un archivo
        const formData = new FormData();
        formData.append('files[]', this.fileConsFiscalSave, this.fileConsFiscalSave.name);
        formData.append('inputIdentifier[]', 'AltaCteCredito/CedulaFiscal/');
        this._serviciosService.uploadFiles(formData).subscribe(
          (response) => {
            console.log(response);
            this.arrayArchivos = response;
            if (this.arrayArchivos[0].code == "200") {
              this.fnEnviardatos(this.arrayArchivos);
            } else {
              Swal.fire("Error", "Error al subir cedula fiscal" + this.arrayArchivos.message, "error")
              this.spinerAll = false;
            }
          }, (error) => {
            Swal.fire("Error", "No se recibio respuesta del servidor", "error")
            console.log(error.message)
            this.spinerAll = false;
          }) 
           
      } else {
        Swal.fire("error", "Falta cargar la constancia fiscal.", "error");
        this.spinerAll = false;
      }*/
        this.fnEnviardatos(this.arrayArchivos);
    }
  }

  uploadFiles2() {
    console.log(this.strTipoCliente)
    if (this.strTipoCliente == 'CREDITO') {
      if ((this.fileIneSave && this.fileIneSave instanceof File) && (this.fileConsFiscalSave && this.fileConsFiscalSave instanceof File) &&
        (this.fileEdoCuentSave && this.fileEdoCuentSave instanceof File) && (this.fileBuroSave && this.fileBuroSave instanceof File) &&
        (this.fileCompraVtaSave && this.fileCompraVtaSave instanceof File) && (this.filePagareSave && this.filePagareSave instanceof File) &&
        (this.fileActaConsSave && this.fileActaConsSave instanceof File) && (this.fileDomicilioSave && this.fileDomicilioSave instanceof File)) {
        this.spinerAll = true;
        const formData = new FormData();
        // Identificadores únicos para cada archivo

        formData.append('files[]', this.fileIneSave, this.fileIneSave.name);
        formData.append('files[]', this.fileConsFiscalSave, this.fileConsFiscalSave.name);
        formData.append('files[]', this.fileEdoCuentSave, this.fileEdoCuentSave.name);
        formData.append('files[]', this.fileBuroSave, this.fileBuroSave.name);
        formData.append('files[]', this.fileCompraVtaSave, this.fileCompraVtaSave.name);
        formData.append('files[]', this.filePagareSave, this.filePagareSave.name);
        formData.append('files[]', this.fileActaConsSave, this.fileActaConsSave.name);
        formData.append('files[]', this.fileDomicilioSave, this.fileDomicilioSave.name);

        formData.append('inputIdentifier[]', 'AltaCteCredito/IFE/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/CedulaFiscal/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/EstadoDeCuenta/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/BuroDeCredito/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/ContratoBase/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/Pagare/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/ActaConsecutiva/');
        formData.append('inputIdentifier[]', 'AltaCteCredito/ComprobanteDomicilio/');

        this._serviciosService.uploadFiles(formData).subscribe(
          (response) => {
            console.log(response)
            let contador = 0;
            this.arrayArchivos = response;
            if (this.arrayArchivos.length > 0) {
              this.arrayArchivos.forEach(item => {
                if (item.code == "200") {
                  contador = contador + 1
                } else {
                  contador = contador - 1
                  Swal.fire("Error", "error en documento: ", "error")
                }
              });
              if (contador == this.arrayArchivos.length) {

                this.fnEnviardatos_Solicitud_Credito(this.arrayArchivos);

              } else {
                Swal.fire("Error", "En archivos.", "error")
                this.spinerAll = false;
              }
            } else {
              Swal.fire("Error", "No se recibio respuesta del servidor", "error");
              this.spinerAll = false;
            }
          },
          (error) => {
            Swal.fire("Error", "No se recibio respuesta del servidor", "error")
            console.log(error.message)
            this.spinerAll = false;
          });
      } else {
        Swal.fire("error", "Falta cargar Archivos.", "error");
        this.spinerAll = false;
      }


    } else if (this.strTipoCliente == 'CONTADO') {
      if (this.fileConsFiscalSave && this.fileConsFiscalSave instanceof File) {
        this.spinerAll = true;
        // El objeto fileIneSave contiene un archivo
        const formData = new FormData();
        formData.append('files[]', this.fileConsFiscalSave, this.fileConsFiscalSave.name);
        formData.append('inputIdentifier[]', 'AltaCteCredito/CedulaFiscal/');
        this._serviciosService.uploadFiles(formData).subscribe(
          (response) => {
            console.log(response);
            this.arrayArchivos = response;
            if (this.arrayArchivos[0].code == "200") {
              this.fnEnviardatos(this.arrayArchivos);
            } else {
              Swal.fire("Error", "Error al subir cedula fiscal" + this.arrayArchivos.message, "error")
              this.spinerAll = false;
            }
          }, (error) => {
            Swal.fire("Error", "No se recibio respuesta del servidor", "error")
            console.log(error.message)
            this.spinerAll = false;
          })
      } else {
        Swal.fire("error", "Falta cargar la constancia fiscal.", "error");
        this.spinerAll = false;
      }
    }
  }

  fnEnviardatos_update_Solicitud(arrayArc: any) {
    console.log('Se ha cargado  archivo.');
    let ruta =this.formDatosFiscales.controls['strColonia'].value == "otra"?this.arrayColonias[0] : this.arrayColonias.find(o => o.strColonia == this.formDatosFiscales.controls['strColonia'].value)
    const urlToReplace = 'https://www.ecodeli.com/';
    this.nombreIne=this.nombreIne.replace(urlToReplace, '')
    this.nombreConstanciaFiscal=this.nombreConstanciaFiscal.replace(urlToReplace, '')  
    this.nombreCompDomicilio=this.nombreCompDomicilio.replace(urlToReplace, '') 
    this.nombreEdoCuenta=this.nombreEdoCuenta.replace(urlToReplace, '')
    this.nombreBuroCredito=this.nombreBuroCredito.replace(urlToReplace, '') 
    this.nombrePagare = this.nombrePagare.replace(urlToReplace, '')  
    this.nombreCompraVenta=  this.nombreCompraVenta.replace(urlToReplace, '')
    this.nombreAltaConst= this.nombreAltaConst.replace(urlToReplace, '')
    let objFormulario = {

      // info general
      strNombreComercial: this.formVendedor.controls['strNombreComercial'].value,
      strTipoCliente: this.formVendedor.controls['strTipoCliente'].value,
      strTelefono: this.formVendedor.controls['strTelefono'].value,
      strAgente: this.objInfoAgente.stragente,
      intSucursalEmpresa: this.objInfoAgente.strsucursal,
      dblCreditoLimite: this.formVendedor.controls['strLimiteCredito'].value.toString(),
      strCondicion: this.formVendedor.controls['strCondicionPago'].value,

      //info fiscal
      strRFC: this.formDatosFiscales.controls['strRfc'].value,
      strRazonSocial: this.formDatosFiscales.controls['strRazonSocial'].value,
      strUsoCFDI: this.formDatosFiscales.controls['strCfdi'].value,
      strFiscalRegimen: this.formDatosFiscales.controls['strRegimenFiscal'].value,
      strFormaPagoTipo: this.formDatosFiscales.controls['strTipoPago'].value,
      strCodigoPostal: this.formDatosFiscales.controls['strCp'].value,
      strEstado: this.formDatosFiscales.controls['strEstado'].value,
      strPoblacion: this.formDatosFiscales.controls['strCiudad'].value,
      strColonia:this.formDatosFiscales.controls['strColonia'].value == "otra"?this.formDatosFiscales.controls['strColoniaOtra'].value : this.formDatosFiscales.controls['strColonia'].value,
      strDireccion: this.formDatosFiscales.controls['strCalle'].value,
      strDireccionNumero: this.formDatosFiscales.controls['strNoExterior'].value,
      strRuta: ruta.strRuta,

      //informacion bancaria
      strClaveBanco: this.formBanck.controls['strBanco'].value,
      strCuenta: this.formBanck.controls['strCuenta'].value,
      strClaveInterbancaria: this.formBanck.controls['strCuentaClave'].value,

      //informacion referencias
      strEmpresaReferencia1: this.formReferencias.controls['strEmpresa'].value,
      strNombreReferencia1: this.formReferencias.controls['strNombre'].value,
      strTelefonoReferencia1: this.formReferencias.controls['strTelefono'].value,
      strEmpresaReferencia2: this.formReferencias.controls['strEmpresa2'].value,
      strNombreReferencia2: this.formReferencias.controls['strNombre2'].value,
      strTelefonoReferencia2: this.formReferencias.controls['strTelefono2'].value,

      //informacion contacto compra
      strContacto1: this.formContacto.controls['strContacto'].value,
      streMail1: this.formContacto.controls['strCorreo'].value,
      strTelefono1: this.formContacto.controls['strTelefono'].value,

      //informacion contacto pago
      strContacto2: this.formContactoPago.controls['strContacto'].value,
      strTelefono2: this.formContactoPago.controls['strTelefono'].value,
      streMail2: this.formContactoPago.controls['strCorreo'].value,

      //informacion preguntas
      strFormato_entrega: this.formPreguntas.controls['strPregunta1'].value,  
      strOrden_compra: this.formPreguntas.controls['strPregunta2'].value,   

      strUrl_CedulaFiscal: arrayArc.length>0  && this.fileConsFiscalSave?"Adjuntos/AltaCteCredito/CedulaFiscal/" + this.fileConsFiscalSave.name:this.nombreConstanciaFiscal.replace(urlToReplace, ''),
      strUrl_CDomicilio: arrayArc.length>0  && this.fileDomicilioSave ? "Adjuntos/AltaCteCredito/ComprobanteDomicilio/" + this.fileDomicilioSave.name : this.nombreCompDomicilio.replace(urlToReplace, ''),

      strUrl_Ine: arrayArc.length>0  && this.fileIneSave? "Adjuntos/AltaCteCredito/IFE/" + this.fileIneSave.name : this.nombreIne,

      strUrl_EdoCta: arrayArc.length>0  && this.fileEdoCuentSave? "Adjuntos/AltaCteCredito/EstadoDeCuenta/" + this.fileEdoCuentSave.name : this.nombreEdoCuenta.replace(urlToReplace, ''),
      strUrl_BuroCredito: arrayArc.length>0  &&  this.fileBuroSave? "Adjuntos/AltaCteCredito/BuroDeCredito/" + this.fileBuroSave.name : this.nombreBuroCredito.replace(urlToReplace, ''),
      strUrl_ActaConstitutiva: arrayArc.length>0  &&  this.fileActaConsSave ? "Adjuntos/AltaCteCredito/ActaConsecutiva/" + this.fileActaConsSave.name : this.nombreAltaConst.replace(urlToReplace, ''),
      strUrl_Pagare: arrayArc.length>0  &&  this.filePagareSave ? "Adjuntos/AltaCteCredito/Pagare/" + this.filePagareSave.name : this.nombrePagare.replace(urlToReplace, ''),
      strUrl_ContratoBase: arrayArc.length>0  &&  this.fileCompraVtaSave ? "Adjuntos/AltaCteCredito/ContratoBase/" + this.fileCompraVtaSave.name : this.nombreCompraVenta.replace(urlToReplace, ''),
      strUrl_Escrituras: "",
      strUrl_CartaIntension: "",
      strDelegacion: "",
      strDirInternet: "",
      strPais: this.strConexion == "COMERCIAL" ? "MEXICO" : "REPUBLICA DOMINICANA",
      strAlmacenDef: ruta.strAlmacen,
      strGiro: "",
      strEstatus: "",
      strTipoSolicitud: this.objAlta_Cte.strTipoSolicitud,
      strAccion: 'setUpdate_Alta_Cliente',
      strConexion: this.strConexion,
      intID: this.objAlta_Cte.intID //ID SOLICITUD
    }
    console.log(objFormulario);
   this._serviciosService.mostrarCotizaciones(objFormulario, 'sp_modventas_Directorio').subscribe(
      result => {
        let resultado = result
        console.log(resultado);
        if (resultado[0].code == "200") {
          if (this.objAlta_Cte && this.objAlta_Cte.strOrigen && this.objAlta_Cte.strOrigen == "/prospectos/") {
            this._altaCteServ.setCte_alta({})
            this.router.navigate([this.objAlta_Cte.strOrigen]);
          } else {
            this.limpiarForms();
            //this.mostrarprincipal()
            Swal.fire("Enviado", resultado[0].strMensaje, "success");
            //REDIRECCIONAMOS A LISTADO DE SOLICITUDES
            this.router.navigate(['/SolicitudAltaCte']);
          }
        } else {
          Swal.fire("Error", resultado[0].strMensaje, "error");
        }
        this.spinerAll = false;
      }, error => {
        var error = <any>error;
        Swal.fire("Error", error.message, "error")
        console.log(error);
        this.spinerAll = false;
      }
    ); 
  }

  fnEnviardatos(arrayArc: any) {
    try {
      
 
    console.log('Se ha cargado un archivo.');
    let ruta =  this.formDatosFiscales.controls['strColonia'].value == "otra"?this.arrayColonias[0] : this.arrayColonias.find(o => o.strColonia == this.formDatosFiscales.controls['strColonia'].value)

    let objFormulario = {

      // info general
      strNombreComercial: this.formVendedor.controls['strNombreComercial'].value,
      strTipoCliente: this.formVendedor.controls['strTipoCliente'].value,
      strTelefono: this.formVendedor.controls['strTelefono'].value,
      strAgente: this.objInfoAgente.stragente,
      intSucursalEmpresa: this.objInfoAgente.strsucursal,
      dblCreditoLimite: this.formVendedor.controls['strLimiteCredito'].value.toString(),
      strCondicion: this.formVendedor.controls['strCondicionPago'].value,

      //info fiscal
      strRFC: this.formDatosFiscales.controls['strRfc'].value,
      strRazonSocial: this.formDatosFiscales.controls['strRazonSocial'].value,
      strUsoCFDI: this.formDatosFiscales.controls['strCfdi'].value,
      strFiscalRegimen: this.formDatosFiscales.controls['strRegimenFiscal'].value,
      strFormaPagoTipo: this.formDatosFiscales.controls['strTipoPago'].value,
      strCodigoPostal: this.formDatosFiscales.controls['strCp'].value,
      strEstado: this.formDatosFiscales.controls['strEstado'].value,
      strPoblacion: this.formDatosFiscales.controls['strCiudad'].value,
      strColonia: this.formDatosFiscales.controls['strColonia'].value == "otra" ?this.formDatosFiscales.controls['strColoniaOtra'].value:this.formDatosFiscales.controls['strColonia'].value,
      strDireccion: this.formDatosFiscales.controls['strCalle'].value,
      strDireccionNumero: this.formDatosFiscales.controls['strNoExterior'].value,
      strRuta: ruta.strRuta,

      //informacion bancaria
      strClaveBanco: this.formBanck.controls['strBanco'].value,
      strCuenta: this.formBanck.controls['strCuenta'].value,
      strClaveInterbancaria: this.formBanck.controls['strCuentaClave'].value,

      //informacion referencias
      strEmpresaReferencia1: this.formReferencias.controls['strEmpresa'].value,
      strNombreReferencia1: this.formReferencias.controls['strNombre'].value,
      strTelefonoReferencia1: this.formReferencias.controls['strTelefono'].value,
      strEmpresaReferencia2: this.formReferencias.controls['strEmpresa2'].value,
      strNombreReferencia2: this.formReferencias.controls['strNombre2'].value,
      strTelefonoReferencia2: this.formReferencias.controls['strTelefono2'].value,

      //informacion contacto compra
      strContacto1: this.formContacto.controls['strContacto'].value,
      streMail1: this.formContacto.controls['strCorreo'].value,
      strTelefono1: this.formContacto.controls['strTelefono'].value,

      //informacion contacto pago
      strContacto2: this.formContactoPago.controls['strContacto'].value,
      strTelefono2: this.formContactoPago.controls['strTelefono'].value,
      streMail2: this.formContactoPago.controls['strCorreo'].value,

      //informacion preguntas
      strFormato_entrega: this.formPreguntas.controls['strPregunta1'].value,
      strOrden_compra: this.formPreguntas.controls['strPregunta2'].value,

      strUrl_CedulaFiscal: this.fileConsFiscalSave?"Adjuntos/AltaCteCredito/CedulaFiscal/" + this.fileConsFiscalSave.name:'',
      strUrl_CDomicilio: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/ComprobanteDomicilio/" + this.fileDomicilioSave.name : "",
      strUrl_Ine: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/IFE/" + this.fileIneSave.name : "",
      strUrl_EdoCta: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/EstadoDeCuenta/" + this.fileEdoCuentSave.name : "",
      strUrl_BuroCredito: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/BuroDeCredito/" + this.fileBuroSave.name : "",
      strUrl_ActaConstitutiva: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/ActaConsecutiva/" + this.fileActaConsSave.name : "",
      strUrl_Pagare: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/Pagare/" + this.filePagareSave.name : "",
      strUrl_ContratoBase: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/ContratoBase/" + this.fileCompraVtaSave.name : "",
      strUrl_Escrituras: "",
      strUrl_CartaIntension: "",
      strDelegacion: "",
      strDirInternet: "",
      strPais: this.strConexion == "COMERCIAL" ? "MEXICO" : "REPUBLICA DOMINICANA",
      strAlmacenDef: ruta.strAlmacen,
      strGiro: "",
      strEstatus: "",
      strTipoSolicitud: this.strTipoCliente == "CREDITO" ? "ALTA_CREDITO" : "ALTA_CONTADO",
      strAccion: 'setAlta_Cliente',
      strConexion: this.strConexion
    }
    console.log(objFormulario);
    this._serviciosService.mostrarCotizaciones(objFormulario, 'sp_modventas_Directorio').subscribe(
      result => {
        let resultado = result
        console.log(resultado);
        if (resultado[0].code == "200") {
          if (this.objAlta_Cte && this.objAlta_Cte.strOrigen && this.objAlta_Cte.strOrigen == "/prospectos/") {
            this._altaCteServ.setCte_alta({})
            this.router.navigate([this.objAlta_Cte.strOrigen]);
          } else {
            this.limpiarForms();
            //this.mostrarprincipal()
            Swal.fire("Enviado", resultado[0].strMensaje, "success");
            //REDIRECCIONAMOS A LISTADO DE SOLICITUDES
            this.router.navigate(['/SolicitudAltaCte']);
          }
        } else {
          Swal.fire("Error", resultado[0].strMensaje, "error");
        }
        this.spinerAll = false;
      }, error => {
        var error = <any>error;
        Swal.fire("Error", error.message, "error")
        console.log(error);
        this.spinerAll = false;
      }
    );
  } catch (error) {
      Swal.fire("Error",error.message,"error")
  }
  }

  fnEnviardatos_Solicitud_Credito(arrayArc: any) {
    console.log('Se ha cargado un archivo.');
    let ruta = this.formDatosFiscales.controls['strColonia'].value == "otra"?this.arrayColonias[0] : this.arrayColonias.find(o => o.strColonia == this.formDatosFiscales.controls['strColonia'].value)
    let objFormulario = {
      //Se agrego el strCliente
      strCliente: this.strCliente,

      // info general
      strNombreComercial: this.formVendedor.controls['strNombreComercial'].value,
      strTipoCliente: this.formVendedor.controls['strTipoCliente'].value,
      strTelefono: this.formVendedor.controls['strTelefono'].value,
      strAgente: this.objInfoAgente.stragente,
      intSucursalEmpresa: this.objInfoAgente.strsucursal,
      dblCreditoLimite: this.formVendedor.controls['strLimiteCredito'].value.toString(),
      strCondicion: this.formVendedor.controls['strCondicionPago'].value,

      //info fiscal
      strRFC: this.formDatosFiscales.controls['strRfc'].value,
      strRazonSocial: this.formDatosFiscales.controls['strRazonSocial'].value,
      strUsoCFDI: this.formDatosFiscales.controls['strCfdi'].value,
      strFiscalRegimen: this.formDatosFiscales.controls['strRegimenFiscal'].value,
      strFormaPagoTipo: this.formDatosFiscales.controls['strTipoPago'].value,
      strCodigoPostal: this.formDatosFiscales.controls['strCp'].value,
      strEstado: this.formDatosFiscales.controls['strEstado'].value,
      strPoblacion: this.formDatosFiscales.controls['strCiudad'].value,
      strColonia: this.formDatosFiscales.controls['strColonia'].value == "otra" ?this.formDatosFiscales.controls['strColoniaOtra'].value:this.formDatosFiscales.controls['strColonia'].value,
      strDireccion: this.formDatosFiscales.controls['strCalle'].value,
      strDireccionNumero: this.formDatosFiscales.controls['strNoExterior'].value,
      strRuta: ruta.strRuta,

      //informacion bancaria
      strClaveBanco: this.formBanck.controls['strBanco'].value,
      strCuenta: this.formBanck.controls['strCuenta'].value,
      strClaveInterbancaria: this.formBanck.controls['strCuentaClave'].value,

      //informacion referencias
      strEmpresaReferencia1: this.formReferencias.controls['strEmpresa'].value,
      strNombreReferencia1: this.formReferencias.controls['strNombre'].value,
      strTelefonoReferencia1: this.formReferencias.controls['strTelefono'].value,
      strEmpresaReferencia2: this.formReferencias.controls['strEmpresa2'].value,
      strNombreReferencia2: this.formReferencias.controls['strNombre2'].value,
      strTelefonoReferencia2: this.formReferencias.controls['strTelefono2'].value,

      //informacion contacto compra
      strContacto1: this.formContacto.controls['strContacto'].value,
      streMail1: this.formContacto.controls['strCorreo'].value,
      strTelefono1: this.formContacto.controls['strTelefono'].value,

      //informacion contacto pago
      strContacto2: this.formContactoPago.controls['strContacto'].value,
      strTelefono2: this.formContactoPago.controls['strTelefono'].value,
      streMail2: this.formContactoPago.controls['strCorreo'].value,

      //informacion preguntas
      strFormato_entrega: this.formPreguntas.controls['strPregunta1'].value,
      strOrden_compra: this.formPreguntas.controls['strPregunta2'].value,

      strUrl_CedulaFiscal: "Adjuntos/AltaCteCredito/CedulaFiscal/" + this.fileConsFiscalSave.name,
      strUrl_CDomicilio: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/ComprobanteDomicilio/" + this.fileDomicilioSave.name : "",
      strUrl_Ine: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/IFE/" + this.fileIneSave.name : "",
      strUrl_EdoCta: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/EstadoDeCuenta/" + this.fileEdoCuentSave.name : "",
      strUrl_BuroCredito: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/BuroDeCredito/" + this.fileBuroSave.name : "",
      strUrl_ActaConstitutiva: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/ActaConsecutiva/" + this.fileActaConsSave.name : "",
      strUrl_Pagare: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/Pagare/" + this.filePagareSave.name : "",
      strUrl_ContratoBase: this.strTipoCliente == "CREDITO" ? "Adjuntos/AltaCteCredito/ContratoBase/" + this.fileCompraVtaSave.name : "",
      strUrl_Escrituras: "",
      strUrl_CartaIntension: "",
      strDelegacion: "",
      strDirInternet: "",
      strPais: this.strConexion == "COMERCIAL" ? "MEXICO" : "REPUBLICA DOMINICANA",
      strAlmacenDef: ruta.strAlmacen,
      strGiro: "",
      strEstatus: "",
      strTipoSolicitud: this.strTipoCliente == "CREDITO" ? "ALTA_CREDITO" : "ALTA_CONTADO",
      strAccion: 'setSolicitud_Credito',
      strConexion: this.strConexion
    }
    console.log(objFormulario);
    this._serviciosService.mostrarCotizaciones(objFormulario, 'sp_modventas_Directorio').subscribe(
      result => {
        let resultado = result
        console.log(resultado);
        if (resultado[0].code == "200") {
          if (this.objAlta_Cte.strOrigen && this.objAlta_Cte.strOrigen == "/prospectos/") {
            this._altaCteServ.setCte_alta({})
            this.router.navigate([this.objAlta_Cte.strOrigen]);
          } else {
            this.limpiarForms();
            //this.mostrarprincipal()
            Swal.fire("Enviado", resultado[0].strMensaje, "success");
            //REDIRECCIONAMOS A LISTADO DE SOLICITUDES
            this.router.navigate(['/SolicitudAltaCte']);
          }
        } else {
          Swal.fire("Error", resultado[0].strMensaje, "error");
        }
        this.spinerAll = false;
      }, error => {
        var error = <any>error;
        Swal.fire("Error", error.message, "error")
        console.log(error);
        this.spinerAll = false;
      }
    );
  }

  ngOnDestroy() {
    this._altaCteServ.setCte_alta({})
  }
  limpiarForms() {
    this.formDatosFiscales.controls['strRfc'].setValue('');
    this.formDatosFiscales.controls['strRazonSocial'].setValue('');
    this.formDatosFiscales.controls['strCfdi'].setValue('');
    this.formDatosFiscales.controls['strRegimenFiscal'].setValue('');
    this.formDatosFiscales.controls['strTipoPago'].setValue('');
    this.formDatosFiscales.controls['strCp'].setValue('');
    this.formDatosFiscales.controls['strEstado'].setValue('');
    this.formDatosFiscales.controls['strCiudad'].setValue('');
    this.formDatosFiscales.controls['strColonia'].setValue('');
    this.formDatosFiscales.controls['strColoniaOtra'].setValue('');
    this.formDatosFiscales.controls['strCalle'].setValue('');

    this.formDatosFiscales.controls['strNoExterior'].setValue('');
    this.formDatosFiscales.controls['strRuta'].setValue('');

    this.formVendedor.controls['strNombreComercial'].setValue('');
    this.formVendedor.controls['strTipoCliente'].setValue('');
    this.formVendedor.controls['strTelefono'].setValue('');
    this.formVendedor.controls['strSucursal'].setValue('');
    this.formVendedor.controls['strLimiteCredito'].setValue('');
    this.formVendedor.controls['strCondicionPago'].setValue('');

    this.formBanck.controls['strBanco'].setValue('');
    this.formBanck.controls['strCuenta'].setValue('');
    this.formBanck.controls['strCuentaClave'].setValue('');

    this.formContacto.controls['strContacto'].setValue('');
    this.formContacto.controls['strCorreo'].setValue('');
    this.formContacto.controls['strTelefono'].setValue('');

    this.formContactoPago.controls['strContacto'].setValue('');
    this.formContactoPago.controls['strCorreo'].setValue('');
    this.formContactoPago.controls['strTelefono'].setValue('');

    this.formReferencias.controls['strEmpresa'].setValue('');
    this.formReferencias.controls['strNombre'].setValue('');
    this.formReferencias.controls['strTelefono'].setValue('');
    this.formReferencias.controls['strEmpresa2'].setValue('');
    this.formReferencias.controls['strNombre2'].setValue('');
    this.formReferencias.controls['strTelefono2'].setValue('');

    this.formPreguntas.controls['strPregunta1'].setValue('');
    this.formPreguntas.controls['strPregunta2'].setValue('');

    this.nombreIne = "";
    this.nombreConstanciaFiscal = "";
    this.nombreEdoCuenta = "";
    this.nombreBuroCredito = "";
    this.nombreCompraVenta = "";
    this.nombrePagare = "";
    this.nombreAltaConst = "";
    this.nombreCompDomicilio = "";

    this.fileIneSave = null;
    this.fileConsFiscalSave = null;
    this.fileEdoCuentSave = null;
    this.fileBuroSave = null;
    this.fileCompraVtaSave = null;
    this.filePagareSave = null;
    this.fileActaConsSave = null;
    this.fileDomicilioSave = null;
  }

  fngetCte_Solicitud_Credito() {
    let objEnv ={
      strAccion: 'getCte_Solicitud_Credito',
      strCliente: this.strCliente,
      strAgente: '',
      strGerente: this.objAlta_Cte.strGerente,
      strSucursal: this.objAlta_Cte.strSucursal,
      strConexion: this.strConexion
    }
    console.log(objEnv)
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modventas_Directorio').subscribe(
      result => {
        console.log(result)
        if (result[0].code == 409) {
          Swal.fire({
            title: result[0].strMensaje,
            text: "Deseas ir a generar una actualizacion de credito?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, ir ahora!"
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/SolicitudAltaCte/']);
            } else {
              this.objAlta_Cte_Solcitud = {}
              this.limpiarForms()
              this.strCliente = ''
            }
          });
        } else {
          this.objAlta_Cte_Solcitud = result[0]
          this.fnllenarInputs_3()
        }
      }, error => {
        var error = <any>error;
        console.log(error);
      });
  }

  fnUpdate_Datos_generales_Cte() {
    let objFormulario = {
      //Se agrego el strCliente
      strCliente: this.strCliente,
      // info general
      strTipoCliente: this.formVendedor.controls['strTipoCliente'].value,
      strTelefono: this.formVendedor.controls['strTelefono'].value,
      //info fiscal
      strFormaPagoTipo: this.formDatosFiscales.controls['strTipoPago'].value,
      strUsoCFDI: this.formDatosFiscales.controls['strCfdi'].value,
      //informacion bancaria
      strClaveBanco: this.formBanck.controls['strBanco'].value,
      strCuenta: this.formBanck.controls['strCuenta'].value,
      strClaveInterbancaria: this.formBanck.controls['strCuentaClave'].value,
      
      //informacion contacto compra
      strContacto1: this.formContacto.controls['strContacto'].value,
      streMail1: this.formContacto.controls['strCorreo'].value,
      strTelefono1: this.formContacto.controls['strTelefono'].value,
      
      //informacion contacto pago
      strContacto2: this.formContactoPago.controls['strContacto'].value,
      strTelefono2: this.formContactoPago.controls['strTelefono'].value,
      streMail2: this.formContactoPago.controls['strCorreo'].value,
      strAccion: 'Update_Datos_generales_Cte',
      strConexion: this.strConexion
    }
    console.log(objFormulario)

    this._serviciosService.mostrarCotizaciones(objFormulario, 'sp_modventas_Directorio').subscribe(
      result => {
        let resultado = result
        if (resultado[0].code == "200") {
          if (this.objAlta_Cte.strOrigen && this.objAlta_Cte.strOrigen == "/prospectos/") {
            this._altaCteServ.setCte_alta({})
            this.router.navigate([this.objAlta_Cte.strOrigen]);
          } else {
            this.limpiarForms();
            Swal.fire("Enviado", resultado[0].strMensaje, "success");
            //REDIRECCIONAMOS A LISTADO DE SOLICITUDES
            this.router.navigate(['/SolicitudAltaCte']);
          }
        } else {
          Swal.fire("Error", resultado[0].strMensaje, "error");
        }
        this.spinerAll = false;
      }, error => {
        var error = <any>error;
        Swal.fire("Error", error.message, "error")
        console.log(error);
        this.spinerAll = false;
      }
    );
  }
}