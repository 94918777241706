import { Component, OnInit  , OnDestroy, Renderer2,NgZone, AfterViewInit , HostListener, ElementRef  } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { ActivatedRoute } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
// SDK de Mercado Pago
import { loadMercadoPago } from "@mercadopago/sdk-js";
import Swal from 'sweetalert2';  


import { CarroService } from '../servicios/carroservice';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {FormasPagoService} from '../servicios/formas-pago.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataDBService } from '../servicios/data-db.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AlertifyService } from '../alertify.service';

@Component({
  selector: 'app-partner-recompensas',
  templateUrl: './partner-recompensas.component.html',
  styleUrls: ['./partner-recompensas.component.css']
})
export class PartnerRecompensasComponent implements OnInit {
  public objCliente:any = {
    strCliente:"",
    strNombre:"",
    strDireccion:"",
    ClienteSeleccionado:false,
    strSucursal:"",
    NombreSucursal:"",
    tipoSucursal:""
  };
  public tagName:string;
  public arrayAccesos:any=[];
  public arrayDescuentos:any =[];
  public objEnvioPartner:any ={};
  public strTipo_envio_partner:string='';
  public strTitulo_envio:string='';
  public strNombre_envio_partner:string='';
  public strAlmacen:string;
  public strCteenviar:string;
  public objPartner:any;
  public strConexion:string = "";
  public objClienteDatos:any;
  public strCliente:string="";
  public strNumCte:string;
  public strAgenteLS:string;
  public strAgente_Crea:string;
  public strUsuario:string;
  public strSSN:string;
  public montoCompra: number=0;
  public strNivelActual:string;
  public objDescuento_Parner:any;
  public descuentoAplicable:any;
  coloresNivel: string[] = ['#FF6347', '#FF4500', '#32CD32', '#1E90FF','#008B8B','#9370DB','#FFD700'];

  constructor(  public alertify: AlertifyService,elem: ElementRef,private carroservice: CarroService, public localSt: LocalStorageService, private indexedDBService: DataDBService, private ngZone: NgZone,private router: Router,private formPago:FormasPagoService, private renderer: Renderer2,private fb: FormBuilder, private _httpService:PeticionesService, private route: ActivatedRoute) { 
    this.tagName = elem.nativeElement.tagName.toLowerCase();

    this.indexedDBService.getItem('arrayResult', (value) => {
    
      this.arrayAccesos = value ? value : [];
        if (this.arrayAccesos.length > 0) {
            this.objEnvioPartner=this.arrayAccesos[0].detalle_envio_partner?this.arrayAccesos[0].detalle_envio_partner:{}
            this.strTipo_envio_partner=this.arrayAccesos[0].strTipo_envio_partner?this.arrayAccesos[0].strTipo_envio_partner:'PICKUP';
            this.strNombre_envio_partner = this.arrayAccesos[0].nombre_envioPartner ?  this.arrayAccesos[0].nombre_envioPartner:'';
            this.strTitulo_envio = this.strTipo_envio_partner == 'PICKUP' ? 'Pick Up'  :'Envío Estándar';
            this.strCteenviar =  this.objEnvioPartner.intId_Direccion ?  this.objEnvioPartner.intId_Direccion:null;
          
          var objAcceso = this.arrayAccesos[0] //this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        
          if (objAcceso.length == 0) {
            this.router.navigate(['/login']);
          } else {
            let objData = this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
            this.objClienteDatos =  this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
            this.strConexion = this.localSt.retrieve('strConexion');
            this.strAgenteLS = objData.strAgente ; //this.localSt.retrieve('strAgente');
            this.strAgente_Crea = objData.strAgente; // this.localSt.retrieve('strAgente');
            this.strUsuario = objData.strUsuario;
            this.strCliente = objData.strCliente
            this.strSSN =  objData.strSSN;
          
           
          }
           //servicio de cliente
          this.carroservice.ObjCliente$.subscribe((nuevoValor) => {
            this.objCliente = nuevoValor;
            if(nuevoValor.ClienteSeleccionado){
              this.strNumCte=nuevoValor.strCliente;
              this.strAlmacen = this.objEnvioPartner.strAlmacen ?  this.objEnvioPartner.strAlmacen:this.objCliente.strAlmacen;
            }else{
              this.strNumCte = ""
            }
          });

        } else {
          this.router.navigate(['/login']);
        }
      });  
  }

  ngOnInit(): void {
      //------------conexi+on-------------------
      this.indexedDBService.getItem('strConexion', (value) => {
        this.strConexion = value;
        let objC =localStorage.getItem('objDescuento_partner');
        this.descuentoAplicable = objC? JSON.parse(objC) : {} ;        
        this.montoCompra = this.descuentoAplicable.montoCompra ? this.descuentoAplicable.montoCompra:0;
        this.fnGetCupones();
      });
    
  
  }

  fnGetCupones(){
    let objEnviar = {
      strAccion:"getDescuento_partner",
      strConexion:this.strConexion
    }
    console.log(objEnviar)
    this._httpService.getEcodeli(objEnviar,'sp_eCommerce').subscribe(
      result =>{
        this.arrayDescuentos = result;
        this.getNivelActual();
      }
    )
  }

  getNivelActual()  {
    let objNivel = this.arrayDescuentos.find(o=> o.intID_Cupon == this.montoCompra) //aqui monto compra trae un id, ya no el importe
    let nivelActual = objNivel?objNivel.strNombre :'';    this.arrayDescuentos[0].strNombre; // Nivel por defecto
    let objNivelActual =objNivel?  objNivel: {}; //inicializamos en vacio por si no ha tenido compras o es su primer vez comprando
    /* for (let nivel of this.arrayDescuentos) {
      if (this.montoCompra >= nivel.dblMontoMin_Cupon) {
        nivelActual = nivel.strNombre;
        objNivelActual =nivel;

      }
    }*/
    this.strNivelActual=nivelActual;

    //avisar el nivel donde está
   // this.carroservice.fnDescuento_partner(objNivelActual);
   // return nivelActual;
  }

}
