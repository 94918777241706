<mat-sidenav-container class="sidenav-container imgfond "> 
  <mat-sidenav  #drawer class="sidenav " fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"  mode="over"  (opened)="isSidenavOpened = true" (closed)="isSidenavOpened = false">
    <mat-toolbar class="back">Menu</mat-toolbar>
    <mat-nav-list [ngClass]="{'p60':(strNumCte && strNumCte !='')||(objFamilia && objFamilia !='') , 'pt-0':!strNumCte||strNumCte == undefined || strNumCte =='','p120':((strNumCte && strNumCte !='')&&(objFamilia && objFamilia !=''))||((strNumCte && strNumCte !='')&&(ctePartner && ctePartner?.intID!=''&& ctePartner?.intID!=null))}">
      <div class="col-12 d-md-none bg-light p-0">
        <a class="nav-link active mouseHov  text-dark text-end fw-bold py-2"  matTooltip="Cerrar Sesión" aria-current="page" [routerLink]="['/login']" (click)="fnCerrarSesion()" title="Cerrar Sesión">
          <span class="vertical-middle name text-dark">{{strUsuario}}</span> &nbsp; <span class="material-icons vertical-middle name text-dark pe-2">logout</span></a>
      </div> 
      <mat-list-item *ngIf=" arrayAccesosMenu?.Directorio" (mouseenter)="directorio = true" (mouseleave)="directorio = false">
        <span class="w-100 fw-bold text-start ms-3 fs-6" mat-button [matMenuTriggerFor]="leads" ><i class="bi " [ngClass]="{'bi-journal-bookmark':!directorio,'bi-journal-bookmark-fill':directorio}"></i> Directorio <i class="bi bi-caret-down-fill fs12"></i></span>
        <mat-menu   #leads="matMenu">
          <button   *ngIf="arrayAccesoSubMenu.DirectorioClientes" class=" border-bottom" mat-menu-item  [routerLink]="['/directorio']"  (click)="drawer.close() ">Clientes</button>
          <button   *ngIf="arrayAccesoSubMenu.DirectorioClientes" class=" border-bottom" mat-menu-item  [routerLink]="['/SolicitudAltaCte']"  (click)="drawer.close() "> Alta Cliente</button>
          <button   *ngIf="arrayAccesoSubMenu.Familias" class=" border-bottom" mat-menu-item  [matMenuTriggerFor]="menuFamilia" (click)="drawer.close() ">Familias</button>
          <button   *ngIf="arrayAccesoSubMenu.Leads" class="border-bottom" mat-menu-item  [routerLink]="['/tableroLeads']"  (click)="drawer.close() ">Prospectos</button>
          <button    class=" border-bottom" mat-menu-item  [routerLink]="['/dashboard']"  (click)="drawer.close() ">Dashboard</button>
        </mat-menu>
        </mat-list-item>
        <mat-menu #menuFamilia="matMenu">
          <button class=" border-bottom" mat-menu-item [routerLink]="['/solicitudes_familias']" (click)="drawer.close()">Listado Solicitudes</button>
        </mat-menu>
      <mat-list-item *ngIf=" arrayAccesosMenu?.ObjetivosMix" (mouseenter)="objMix1 = true" (mouseleave)="objMix1 = false">
          <span class="w-100 fw-bold text-start ms-3 fs-6" mat-button [matMenuTriggerFor]="objMix" >
            <i class="bi "[ngClass]="{'bi-flag':!objMix1,'bi-flag-fill':objMix1}"></i>  Objetivos Mix <i class="bi bi-caret-down-fill fs12"></i></span>
          <mat-menu  #objMix="matMenu">
             <button *ngIf="arrayAccesoSubMenu.ObjetivosMixDivisional" class=" border-bottom" mat-menu-item [routerLink]="['/objmixNacional']" (click)="drawer.close()" >Objetivo Mix Nacional-Divisional</button>
            <button *ngIf="arrayAccesoSubMenu.MiObjetivosMixDivisional" class=" border-bottom" mat-menu-item [routerLink]="['/objmixGteDivisional']" (click)="drawer.close()"  >Objetivo Mix Divisional-Sucursal </button>
            <button *ngIf="arrayAccesoSubMenu.ObjetivosMixSucursal" class=" border-bottom" mat-menu-item   [routerLink]="['/objmixGteSucursal']" (click)="drawer.close()" >Objetivo Mix Sucursal-Agente</button>
            <button *ngIf="arrayAccesoSubMenu.ObjetivosMixAgregar" class=" border-bottom" mat-menu-item   [routerLink]="['/objMixAdd']" (click)="drawer.close()" >Capturar Objetivos Mix</button>
          </mat-menu>
      </mat-list-item>

      <mat-list-item *ngIf=" arrayAccesosMenu?.Cliente" (mouseenter)="objMix1 = true" (mouseleave)="objMix1 = false">
        <span class="w-100 fw-bold text-start ms-3 fs-6" mat-button [matMenuTriggerFor]="objMix" >
          <i class="fa-solid fa-user-tie"></i>  
          Plataforma de Cliente 
          <i class="bi bi-caret-down-fill fs12"></i>
        </span>
        <mat-menu  #objMix="matMenu">
           <button *ngIf="arrayAccesoSubMenu.Cliente_Principal" class=" border-bottom" mat-menu-item [routerLink]="['/tablerocliente']" (click)="drawer.close()" >Reporte Principal</button>
 
           <button *ngIf="arrayAccesoSubMenu.Precios_Especiales"  class=" border-bottom" mat-menu-item [routerLink]="['/precios-especiales']" (click)="drawer.close()" >Listado Precios Especiales</button>

          
           <button *ngIf="arrayAccesoSubMenu.Cliente_Facturas" class=" border-bottom" mat-menu-item [routerLink]="['/cliente-factura']" (click)="drawer.close()" >Facturas</button>

           <button *ngIf="arrayAccesoSubMenu.Cliente_ComplementosP" class=" border-bottom" mat-menu-item [routerLink]="['/cliente-complemento-pago']" (click)="drawer.close()" >Complementos de Pago</button>
           <button *ngIf="arrayAccesoSubMenu.Cliente_SolicitudesP" class=" border-bottom" mat-menu-item [routerLink]="['/listado-solicitud-cliente']" (click)="drawer.close()" >Solicitudes de Pedidos</button>
           <button *ngIf="arrayAccesoSubMenu.Cliente_SeguimientoP" class=" border-bottom" mat-menu-item [routerLink]="['/cliente-seguimiento-pedido']" (click)="drawer.close()" >Seguimiento de Pedidos</button>
           <button *ngIf="arrayAccesoSubMenu.Cliente_unidadesExc" class=" border-bottom" mat-menu-item [routerLink]="['/cliente_unidades_excedidas']" (click)="drawer.close()" >Presupuesto vs Venta</button>
           <button *ngIf="arrayAccesoSubMenu.Cliente_VtaArtCant" class=" border-bottom" mat-menu-item [routerLink]="['/venta-articulo']" (click)="drawer.close()" >Venta por Articulo Cantidad</button>
           <button *ngIf="arrayAccesoSubMenu.Cliente_VtaMonto" class=" border-bottom" mat-menu-item [routerLink]="['/cliente-monto']" (click)="drawer.close()" >Venta por Monto</button>
           <button *ngIf="arrayAccesoSubMenu.Cliente_SeleccionarCte" class=" border-bottom" mat-menu-item [routerLink]="['/tablerocliente']" (click)="drawer.close()" >Seleccionar Cliente</button>
           <button *ngIf="arrayAccesoSubMenu.Cliente_VentaLinea" class=" border-bottom" mat-menu-item [routerLink]="['/cliente-venta-linea']" (click)="drawer.close()" >Venta por Linea</button>
           <button *ngIf="arrayAccesoSubMenu.Cliente_PresupuestoSucursal" class=" border-bottom" mat-menu-item [routerLink]="['/cliente-presupuesto-sucursal']" (click)="drawer.close()" >Presupuesto por Sucursal</button>
          </mat-menu>
        </mat-list-item>
      <mat-list-item *ngIf=" arrayAccesosMenu?.RH" (mouseenter)="rh = true" (mouseleave)="rh = false">
        <span class="w-100 fw-bold text-start ms-3 fs-6" mat-button [matMenuTriggerFor]="RH" ><i class="bi " [ngClass]="{'bi-people':!rh,'bi-people-fill':rh}"></i>  Recursos Humanos<i class="bi bi-caret-down-fill fs12"></i></span>
        <mat-menu  #RH="matMenu">
          <button *ngIf="arrayAccesoSubMenu.RelojChecador" class=" border-bottom" mat-menu-item  [routerLink]="['/RelojChecador']" (click)="drawer.close() ">Reloj Checador</button> 
         </mat-menu>
      </mat-list-item>
      <mat-list-item *ngIf="strUsuario=='cmarquez' || strUsuario=='ifrausto' || arrayAccesosMenu?.Reportes" (mouseenter)="reportes1 = true" (mouseleave)="reportes1 = false">
        <span class="w-100 fw-bold text-start ms-3 fs-6" mat-button [matMenuTriggerFor]="Reportes" ><i class="bi " [ngClass]="{'bi-clipboard-pulse':!reportes1,'bi-clipboard2-pulse-fill':reportes1}"></i> Reportes<i class="bi bi-caret-down-fill fs12"></i></span>
        <mat-menu   #Reportes="matMenu">
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/reportePrincipal']" (click)="drawer.close()" *ngIf="arrayAccesoSubMenu.ReportePrincipal" >Reporte Principal</button>
          <a class=" border-bottom" mat-menu-item  href="https://www.ecodelinegocio.com/Intranet/logeoNegocio/{{strUsuario}}/ADMON" target="_blank" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteScoreCard"  >Reportes  Administrativos</a>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/Reporte_ScoreCard']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteScoreCard"  >Reporte ScoreCard</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/ReporteAvanceMensual']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteAvanceMensual"  >Reporte Avance Mensual</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/reporteVentaDiaria']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteVentaDiaria"  >Reporte Venta Diaria</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/clientes_faltantes']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteClientesFaltantes">Reporte Clientes Faltantes</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/costosDetalleArt']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ComprasDetalleArticulo"  >Compras:: Detalle Articulo</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/scordcardgs']" (click)="drawer.close() "*ngIf="strUsuario=='ifrausto'">Scord Card Gerente Sucursal</button>
     <!--      <button class=" border-bottom" mat-menu-item [routerLink]="['/reportecomodatos']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteComodatos">Reporte Comodatos</button>
     <button class=" border-bottom" mat-menu-item [routerLink]="['/comodatosPorCliente']" (click)="drawer.close()"*ngIf="arrayAccesoSubMenu.ComodatosCliente">Comodatos Por Cliente</button> -->
          <button class=" border-bottom" mat-menu-item [routerLink]="['/reportevtaobj']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteVtasVsObjCtes">Reporte Ventas vs Objetivo Ctes</button>
          <button class=" border-bottom" mat-menu-item [routerLink]="['/reporte-comodatos-cliente']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteComodatosCliente">Reporte Comodatos Clientes</button>
         <!--      <button class=" border-bottom" mat-menu-item  [routerLink]="['/AutPrecioMinimo']" (click)="drawer.close()" *ngIf="arrayAccesoSubMenu.ReportePrecioMin">Autorización Precios Mínimos</button> -->
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/ventasNetas']" (click)="drawer.close()" *ngIf="arrayAccesoSubMenu.ReporteVtasNetas" >Ventas Netas</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/ventasDetalle']" (click)="drawer.close()" *ngIf="arrayAccesoSubMenu.ReporteVtasDetalle" >Ventas Detalle</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/Facturas_Devoluciones']" (click)="drawer.close()" *ngIf="arrayAccesoSubMenu.ReporteFacturaDevolucion" >Ver Facturas</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/listadoRemisiones']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteRemisiones"  >Reporte Remisiones</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/listadoEvidenciaComodatos']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteEvidenciaComodatos"  >Reporte Evidencia Comodatos</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/listadoComplementoPago']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteComplementoPago"  >Reporte Complemento Pago</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/listadoEmbarques']" (click)="drawer.close()"  *ngIf="arrayAccesoSubMenu.ReporteEmbarques"  >Reporte Embarques</button>
        </mat-menu>
      </mat-list-item>
      <mat-list-item *ngIf="arrayAccesosMenu?.Ventas" (mouseenter)="ventas1 = true" (mouseleave)="ventas1 = false">
        <span class="w-100 fw-bold text-start ms-3 fs-6" mat-button [matMenuTriggerFor]="ventas" ><i class="bi " [ngClass]="{'bi-bag':!ventas1,'bi-bag-fill':ventas1}"></i> Ventas <i class="bi bi-caret-down-fill fs12"></i></span>
        <mat-menu class=""  #ventas="matMenu">
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/cotizador_Linea']" (click)="drawer.close()  " *ngIf="arrayAccesoSubMenu.NuevoPedido" >Nuevo Pedido</button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/EcodeliPremia']" (click)="drawer.close()" *ngIf="arrayAccesoSubMenu.ecoPremia">Ecodeli Premia</button>
          <button class=" border-bottom" mat-menu-item [matMenuTriggerFor]="cotizaciones" *ngIf="arrayAccesoSubMenu.PedidosVtas">Cotizaciones</button>
          <button class=" border-bottom" mat-menu-item [matMenuTriggerFor]="comodatos" *ngIf="arrayAccesoSubMenu.Comodatos">Comodatos</button> 
         <!-- <button class=" border-bottom" mat-menu-item [routerLink]="['/comodatosprogram']" (click)="drawer.close()" *ngIf="arrayAccesosMenu.Otros">ComoDatosProgramador</button>-->
          <button class=" border-bottom" mat-menu-item [routerLink]="['/capturadorPedidos']" (click)="drawer.close()">Capturista</button> 
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/pedidos_pendientes']" (click)="drawer.close()">Pedidos</button><!-- pedidosVentas -->
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/lstPlantillas']" (click)="drawer.close()" *ngIf="arrayAccesoSubMenu.Plantillas">Plantillas </button>
          <button class=" border-bottom" mat-menu-item  [routerLink]="['/politica']" (click)="drawer.close()" *ngIf="arrayAccesoSubMenu.Politica">Politica </button>
        </mat-menu>
        <mat-menu #comodatos="matMenu">
          <button mat-menu-item class=" border-bottom" [routerLink]="['/comodatos_lineas']" (click)="drawer.close()"  >Nuevo </button>
          <button mat-menu-item class=" border-bottom" [routerLink]="['/listadoComodatos']"  *ngIf="arrayAccesoSubMenu.ListadoComodatos" (click)="drawer.close()">Listado Solicitudes</button>
          <button mat-menu-item class=" border-bottom" [routerLink]="['/listadoComodatosTodos']"  (click)="drawer.close()">Listado Comodatos</button>
          <button mat-menu-item class=" border-bottom" [routerLink]="['/carcmv2']" (click)="drawer.close()">Carrito Comodatos</button>
        </mat-menu>
        <mat-menu #cotizaciones="matMenu">
            <!-- <button class=" border-bottom" mat-menu-item  [routerLink]="['/Cotizador']" (click)="drawer.close()" >Cotizador</button>-->
            <button class=" border-bottom" mat-menu-item  [routerLink]="['/cotizador_Linea']" (click)="drawer.close()"  >Nuevo Pedido</button>
          <button class=" border-bottom" mat-menu-item [routerLink]="['/cotizaciones']" (click)="drawer.close()">Listado de cotizaciónes</button>
        </mat-menu>
      </mat-list-item>
  <mat-list-item class="nav-item dropdown" (mouseenter)="ecomerce = true" (mouseleave)="ecomerce = false" *ngIf="(arrayAccesosMenu?.Aromia||arrayAccesosMenu?.Rubbermaid||arrayAccesosMenu?.TiendaEcodeli)">  
        <span class="w-100 fw-bold text-start ms-3 fs-6" mat-button [matMenuTriggerFor]="menuEcomerce" ><i class="bi " [ngClass]="{'bi-basket2':!ecomerce,'bi-basket2-fill':ecomerce}"></i> Ecommerce <i class="bi bi-caret-down-fill fs12"></i></span>
        <mat-menu   #menuEcomerce="matMenu">
          <button class="border-bottom" mat-menu-item [matMenuTriggerFor]="menuTiendaEcodeliN"   >Nueva Tienda Ecodeli</button>
          <button class="border-bottom" mat-menu-item [matMenuTriggerFor]="menuTiendaEcodeliPart"   >Tienda Partner</button>
          <button class=" border-bottom" mat-menu-item [matMenuTriggerFor]="menuRubermaid" *ngIf="arrayAccesosMenu?.Rubbermaid">Rubbermaid</button>
          <button class=" border-bottom" mat-menu-item [matMenuTriggerFor]="menuAromia" *ngIf="arrayAccesosMenu?.Aromia">Aromia</button>
        </mat-menu>
        <mat-menu #menuTiendaEcodeliN="matMenu">
          <button   class=" border-bottom" mat-menu-item [routerLink]="['/tienda-pedidos']" (click)="drawer.close()" >Pedidos</button>
          <button *ngIf="arrayAccesoSubMenu.TECupones" class="border-bottom" mat-menu-item [routerLink]="['/admin-cupones']" (click)="drawer.close()" >Cupones</button>
          <button *ngIf="arrayAccesoSubMenu.TEProductos" class="" mat-menu-item [routerLink]="['/admin-destacados-nuevos']" (click)="drawer.close()" >Administrar Productos</button> 
        </mat-menu>
        <!--menu de partner-->
        <mat-menu #menuTiendaEcodeliPart="matMenu">
          <button   class=" border-bottom" mat-menu-item [routerLink]="['/partner-pedidos']" (click)="drawer.close()" >Pedidos</button>
       <!--   <button *ngIf="arrayAccesoSubMenu.TECupones" class="border-bottom" mat-menu-item [routerLink]="['/admin-cupones']" (click)="drawer.close()" >Cupones</button>-->
          <button *ngIf="arrayAccesoSubMenu.TEProductos" class="" mat-menu-item [routerLink]="['/partner-ofertas']" (click)="drawer.close()" >Administrar Ofertas</button>
        </mat-menu>
        <mat-menu #menuRubermaid="matMenu">
          <button   *ngIf="arrayAccesoSubMenu.Inventarios" class="border-bottom" mat-menu-item  [routerLink]="['/inventarios']" (click)="drawer.close() ">Inventarios</button>
          <button *ngIf="arrayAccesoSubMenu.Pedidos" class="border-bottom" mat-menu-item [routerLink]="['/vtex']" (click)="drawer.close()" >Pedidos</button>
        </mat-menu>
        <mat-menu   #menuAromia="matMenu">
          <button *ngIf="arrayAccesoSubMenu.PedidosAromia" class="" mat-menu-item [routerLink]="['/Aromia']" (click)="drawer.close()" >Pedidos</button>
        </mat-menu>
      </mat-list-item>
      <mat-list-item  class="nav-item dropdown" *ngIf="arrayAccesosMenu?.Otros"(mouseenter)="otros1 = true" (mouseleave)="otros1 = false">
        <span class="w-100 fw-bold text-start ms-3 fs-6" mat-button [matMenuTriggerFor]="otros" ><i class="bi bi-box-seam" [ngClass]="{'bi-box-seam':!otros1,'bi-box-seam-fill':otros1}"></i> Otros <i class="bi bi-caret-down-fill fs12"></i></span>
        <mat-menu #otros="matMenu">
          <button *ngIf="arrayAccesoSubMenu.ReporteRetoTork" class="border-bottom" mat-menu-item [routerLink]="['/retoTork']" (click)="drawer.close()" >Reto Tork</button>
          <button *ngIf="arrayAccesoSubMenu.ReporteRetoTork" class="border-bottom" mat-menu-item [routerLink]="['/ConfiguraciondeMercados']" (click)="drawer.close()" >Mercados</button>
        </mat-menu>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content  #scrollContainer  [ngClass]="{'imgfond d-flex flex-column':strUsuario=='' || strUsuario == undefined }" >
    <!-- Menu Empleado-->
    <mat-toolbar color="primary" class="back"  *ngIf="strUsuario">  
  
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleSidenav();">
        <i class="icon fs-4 bi " [class.opened]="isSidenavOpened" aria-label="Side nav toggle icon" [ngClass]="{'bi-list':!isSidenavOpened,'bi-x-lg':isSidenavOpened}"></i>
      </button>
      <a class="navbar-brand" *ngIf="strUsuario && btnQuitarCte "  [routerLink]="['/dashboard']" [routerLinkActive]="'activado'" >
        
        <img class="logo d-none d-md-block" [src]="'assets/EcodeliLogo.png'" style="width: 160px;" />
        <img class="logo d-md-none" [src]="'assets/Recurso.png'" style="width: 45px;" />
      </a>
      <!--logo ecodeli para clientes-->
      <a class="navbar-brand" *ngIf="strUsuario && !btnQuitarCte " (click)="fnIrHome()" [routerLinkActive]="'activado'" >
        
        <img class="logo d-none d-md-block" [src]="'assets/EcodeliLogo.png'" style="width: 160px;" />
        <img class="logo d-md-none" [src]="'assets/Recurso.png'" style="width: 45px;" />
      </a>
      <!--fin --logo ecodeli para clientes-->
      <a class="navbar-brand"  *ngIf="!strUsuario" > 
        <img class="logo d-none d-md-block" [src]="'assets/EcodeliLogo.png'" style="width: 160px;" />
        <img class="logo d-md-none" [src]="'assets/Recurso.png'" style="width: 45px;" />
      </a>
      <span class="example-spacer1 ms-auto">
      </span>
      <span   class="d-flex align-items-center" *ngIf="strUsuario "> 
        <div class="me-4 position-relative d-flex align-items-center" *ngIf="btnBarra_busqueda">
            <div class="input-group mb-1">
              <input (keyup.enter)="fnBuscar()" type="text" class="form-control" placeholder="Buscar" aria-label="Buscar" aria-describedby="button-addon2"  [(ngModel)]="strBuscar">
              <button class="btn btn-secondary" type="button" id="button-addon2" (click)="fnBuscar()"><i class="fa-solid fa-magnifying-glass"></i></button>
            </div>
        </div>
        <div class="me-4 position-relative d-flex align-items-center" *ngIf="btnCarrito">
          <span matTooltip="Ir al cotizador" (mouseenter)="hovCar = true" (mouseleave)="hovCar = false" class="mouseHov" [routerLink]="['/carritoresumen']" ><i class="bi " [ngClass]="{'bi-cart3':!hovCar,'bi-cart-fill':hovCar}"></i></span>
          <span class="position-absolute top-100 start-0 translate-middle">
            <span class="bg-danger rounded p-1 fs8 fw-bold">{{itemsCotizador}}</span></span>
        </div>
         <!--carritos partner-->
        <div class="me-4 position-relative d-flex align-items-center" *ngIf="btnCarrito_partner"> <!--carrito para partner-->
          <span matTooltip="Ir al carrito" (mouseenter)="hovCar = true" (mouseleave)="hovCar = false" class="mouseHov" [routerLink]="['/partner-carrito']" ><i class="bi " [ngClass]="{'bi-cart3':!hovCar,'bi-cart-fill':hovCar}"></i></span>
          <span class="position-absolute top-100 start-0 translate-middle">
            <span class="bg-danger rounded p-1 fs8 fw-bold">{{itemsCotizador_partner}}</span></span>
        </div>
        <div class="me-4 position-relative d-flex align-items-center" *ngIf="btnCarrito_partner"> <!--carrito para partner-->
          <span matTooltip="Ir al cotizador" (mouseenter)="hovCarPartner = true" (mouseleave)="hovCarPartner = false" class="mouseHov" [routerLink]="['/partner-cotizador']" >
            
            <i class="bi " id="cotizadorPartner"  [ngClass]="{'bi-file-earmark-richtext':!hovCarPartner,'bi-file-earmark-richtext-fill':hovCarPartner}"> </i>
          
          </span>
          <span class="position-absolute top-100 start-0 translate-middle">
            <span class="bg-danger rounded p-1 fs8 fw-bold">{{itemsCotizador_partner_cot}}</span></span>
        </div>
        <!--fin partner-->
        <div class="position-relative me-2" *ngIf="btnComodato">  
          <span  matTooltip="ir a Comodatos" (mouseenter)="hovCarCm = true" (mouseleave)="hovCarCm = false" class="mouseHov" [routerLink]="['/carcmv2']"><i class="bi " [ngClass]="{'bi-bag-plus':!hovCarCm,'bi-bag-plus-fill':hovCarCm}"></i></span>
          <span class="position-absolute top-100 start-0 translate-middle">
            <span class="bg-danger rounded p-1 fs8 fw-bold">{{intTotalItems}}</span>
          </span>
        </div>
        <a class="nav-link active mouseHov d-none d-md-flex"  matTooltip="Cerrar Sesión" aria-current="page"  (click)="fnCerrarSesion()" title="Cerrar Sesión">
           <span class="vertical-middle name d-none d-md-flex">{{strUsuario}}</span> &nbsp;
            <i class="fa-solid fa-right-from-bracket vertical-middle"></i>
        </a>
      </span>
    </mat-toolbar>
    <!-- Fin menu Empleado-->
    <!--menu para tienda-->
    <mat-toolbar   class="bg-white py-2" *ngIf="!strUsuario || strUsuario==undefined || strUsuario==''">
        <div #idetiqueta class="col-6 d-none d-lg-block">
          <span class="d-flex">

            <a class="navbar-brand" *ngIf="strUsuario"  [routerLink]="['/tienda']" [routerLinkActive]="'activado'" >
              <img class="logo d-none d-md-block" [src]="'assets/img/logo/ecodeli.png'" style="width: 160px;" />
              <img class="logo d-md-none" [src]="'assets/Recurso.png'" style="width: 45px;" />
            </a>
            <a class="navbar-brand" [routerLink]="['/tienda']"  *ngIf="!strUsuario" >  
              <img class="logo d-none d-md-block" [src]="'assets/img/logo/ecodeli.png'" style="width: 160px;" />
              <img class="logo d-md-none" [src]="'assets/Recurso.png'" style="width: 45px;" />
            </a>
            <div class="ms-4 d-none d-lg-block position-relative form-control border-0 ">
              <input [(ngModel)]="strArticuloTienda" type="text" placeholder="buscar" class="rounded-0 form-control text-secondary" (keyup.enter)="fnBuscarArtTienda()">
              <span class="position-absolute top-50 end-0 translate-middle-y d-flex align-items-center pe-3">
                <i class="bi bi-search text-secondary fs-5 pointer border-start ps-2" (click)="fnBuscarArtTienda()"></i>
              </span>
            </div>
          </span>
        </div>
        <div class="col-2 d-none d-lg-block">
        </div>
        <div class="col-lg-4 col-12 d-flex align-items-center">
          <button class="btn btn-outline-dark d-lg-none rounded-0 " type="button" data-bs-toggle="offcanvas" data-bs-target="#canvasEcommerce" aria-controls="canvasEcommerce"><i class="bi bi-list text-dark"></i></button>
            <img class="logo mx-auto d-lg-none "  [src]="'assets/Recurso.png'" style="width: 45px;" [routerLink]="['/tienda']" [routerLinkActive]="'activado'" />
            <span class="fs10 mx-auto p-1 fw-bold d-flex  align-items-center mx-3 btn btn-outline-secondary">
              <span class="inline-span"><i class="bi  bi-file-earmark-arrow-down fs-5"></i></span>   
             <span class="d-flex flex-column ">
              <span class="inline-span">Catalogo</span>
              <span class="inline-span">Digital</span>
             </span> 
            </span>
            <div class="dropdown mx-auto">
              <button class="btn btn-white border-0 text-dark dropdown-toggle py-1 px-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <img [src]="'assets/Bandera.png'" class="w20" alt=""> 
              </button>
              <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" > <img [src]="'assets/Bandera.png'" class="w20" alt="">  México</a></li>
              </ul>
            </div>
         
          <div class="btn-group">
            <button class="btn  py-1 px-2 btn-sm dropdown-toggle  border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-person-fill fs-3 text-secondary " *ngIf="!userData_tienda[0] "></i>  
              <i class="bi bi-person-fill fs-3 text-info" *ngIf="userData_tienda[0]"></i> 
            </button>
            <ul class="dropdown-menu p-0">
              <li  *ngIf="!userData_tienda[0]" class="p-0"><a class="dropdown-item"   [routerLink]="['/tlogin']">Iniciar sesión</a></li>
              <li  *ngIf="!userData_tienda[0]" class="p-0"><a class="dropdown-item"   [routerLink]="['/login']">Acceso a Partner</a></li>
              <!--<li  *ngIf="!userData_tienda[0]"><a class="dropdown-item"   [routerLink]="['/seguimientop']">Seguimiento de Pedidos</a></li>-->

              <li  *ngIf="userData_tienda[0]"><a class="dropdown-item"  [routerLink]="['/perfilCte']" >Hola,{{userData_tienda[0].strNombre}}</a></li>
              <li  *ngIf="userData_tienda[0]"><hr class="dropdown-divider"></li>
              <li  *ngIf="userData_tienda[0]"><a class="dropdown-item" [routerLink]="['/editdireccion']" >Dirección de envío</a></li>
              <li  *ngIf="userData_tienda[0]"><a class="dropdown-item" [routerLink]="['/facturaCte']">Datos de Facturación</a></li>
              <li  *ngIf="userData_tienda[0]"><a class="dropdown-item"  [routerLink]="['/mispedidos']" >Mis Compras</a></li>
              <li  *ngIf="userData_tienda[0]"><hr class="dropdown-divider"></li>
              <li  *ngIf="userData_tienda[0]"><a class="dropdown-item" (click)="fnCloseTienda()">Cerrar Sesión</a></li>
            </ul>
          </div> 

            <div class="position-relative mx-auto">
              <a  [routerLink]="['/tienda-carrito']">
                <i class=" text-primary"[ngClass]="{'fa-solid fa-cart-shopping fs-4':isHovered,'bi bi-cart3 fs-4':!isHovered}" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false"></i>

                <span class="rounded-circle position-absolute top-100 start-100 translate-middle text-white badge bg-danger p-0 fs12 nunito fw-bolder p-1">{{cartItems }}</span> 
              </a>
            </div>
           
        </div> 
    </mat-toolbar>
    <mat-toolbar  style="position: sticky; z-index:800 !important;" [style.top.px]="altoEtiqueta" class="sid bg-white d-none d-lg-block" *ngIf="!strUsuario || strUsuario==undefined || strUsuario==''">
      <ul class="nav nav-pills w-100 fs12 navLink">
        <li class="nav-item dropdown mx-auto">
          <a class="nav-link dropdown-toggle bgCls px-3 py-0 rounded-0" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="bi bi-list"></i> CATEGORIAS</a>
          <ul class="dropdown-menu hslt py-0 ">
            <li class="border-bottom" *ngFor="let cat of arrayCategorias">
              <a class="dropdown-item fs10 fw-bold ps-1"  (click)="fnIrSubLinea(cat)">{{cat.strCategoria}} </a> </li>
          </ul>
        </li>
        <li class="nav-item mx-auto d-flex align-items-center">
          <a class="nav-link  text-dark fw-bold btn"  (click)="fnNav('novedades')">NOVEDADES</a>
        </li>
        <li class="nav-item mx-auto  d-flex align-items-center">
          <a class="nav-link text-dark fw-bold btn"  (click)="fnNav('ofertas')">OFERTAS</a>
        </li>
        <li class="nav-item mx-auto  d-flex align-items-center">
          <a class="nav-link text-dark fw-bold btn" [routerLink]="['/ayuda']">AYUDA</a>
        </li>
      </ul>
    </mat-toolbar>
    <!-- fin menu para tienda -->
    <!-- Cliente -->
    <div class="col-12 py-1 bg-secondary d-flex justify-content-center sticNameCte" *ngIf="strNumCte && strNumCte !=''">
      <div class="dropdown position-absolute top-50 start-0 translate-middle-y" *ngIf=" acceso ==1015">
        <button class="btn btn-secondary no-hover fs13 dropdown-toggle py-0 px-1   ms-2 rounded-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="border-end me-1 pe-1">
            <img [hidden]="!btnEnvio" class="imgPar" src="assets/partner-evio.png" alt="Partner Envío">
            <img [hidden]="!btnPickup" class="imgPar" src="assets/partner-pickup.png" alt="Partner PickUp">
             {{strOpcion_envio_partner}}
          </span>
          <span>
            {{objEnvio_partner?.strTitulo}}
          </span> 
        </button>
        <ul class="dropdown-menu pt-0" style="width: auto;" (click)="$event.stopPropagation()">
          <li > 
            <div class="row">
              <div class="col-6 d-flex flex-column justify-content-center rounded" >
                <div class="col-11 d-flex flex-column px-0" [ngClass]="{'bg-light':btnEnvio}" (click)="toggleEnvio($event)">
                  <button class="round-button d-flex justify-content-center" [class.selected]="btnEnvio"  >
                    <img src="assets/partner-evio.png" alt="Partner Envío">
                  </button> 
                  <span class="text-center fs13" [ngClass]="{'fw-bold':btnEnvio}">Envío a Domicilio</span>
                </div>
                
              </div>
              <div class="col-6 d-flex flex-column justify-content-center rounded" >
                <div class="col-11 d-flex flex-column px-0" [ngClass]="{'bg-light':btnPickup}" (click)="togglePickUP($event)">
                  <button class="round-button" [class.selected]="btnPickup">
                    <img src="assets/partner-pickup.png" alt="Partner PickUp">
                  </button>
                  <span class="text-center fs13" [ngClass]="{'fw-bold':btnPickup}"> Recoger en Sucursal</span>
                </div>
                
              </div>
            </div>
          </li>
          <li>
            <div class="row mt-3">
              <hr>          
              <div class="col" *ngIf="btnEnvio">
                 <span class="mb-2 fs14"><i class="fa-solid fa-location-dot"></i><b> Selecciona una dirección para recibir el pedido</b></span> <br>
                 <span class="fs14 d-flex flex-column" *ngIf="objEnvio_partner?.strCalle">
                  <span> {{objEnvio_partner?.strCalle}},{{objEnvio_partner?.strNo_exterior}}</span>
                  <span> {{objEnvio_partner?.strColonia}},{{objEnvio_partner?.strDelegacion}}</span>
                  <span> {{objEnvio_partner?.strEstado}}, <b>{{objEnvio_partner?.strCodigoPostal}}</b></span>
                </span><br>
                  <div class="row me-1 ms-1 mt-3">
                    <a class="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" (click)="fnGetDireccion_partner()">
                      Seleccionar Dirección
                    </a>
                  </div>
              </div>
              <div class="col" *ngIf="btnPickup">
                <span class="fs14"><i class="fa-solid fa-house"></i><b> Selecciona una sucursal para recoger el pedido</b></span> <br>
                <span class="fs14 d-flex flex-column" *ngIf="objEnvio_partner">
                  <span > {{objEnvio_partner?.strDireccion}}, {{objEnvio_partner?.strDelegacion}}, </span>
                  <span> {{objEnvio_partner?.strEstado}}, CP:{{objEnvio_partner?.strCodigoPostal}} </span></span><br>
                <div class="row me-1 ms-1 mt-3">
                  <a class="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" (click)="fnGetSucursales_partner()">
                    Seleccionar Sucursal
                  </a>
                </div>
             </div>
            </div>
          </li>
  
        </ul>
      </div>
 
      
       

      <div class="mx-auto text-center w-100">
        <button class="btn  py-0 px-1 fs12  shadow-sm"   (click)="fnDetalleCte()" title=" Ver Detalle" *ngIf="btnQuitarCte">
          <i class="bi bi-list-columns-reverse"></i>
        </button> 
        <span class="fs12 text-white pe-3">
        ({{strNumCte}}) {{objCliente?.strNombre}}
        </span> 
        <button  class="btn  py-0 px-1 fs12  shadow-sm" style="background: #D2B27F;" (click)="fnquitarCliente()" *ngIf="btnQuitarCte">
          <i class="bi bi-person-dash"></i> Quitar
        </button>
      </div>
        
    </div>
    <!--Fin cliente-->



    <!-- Cliente -->
    <div class="col-12 py-1 bgCteSelect d-flex justify-content-center sticNameCte" *ngIf=" ctePartner?.intID != null&& ctePartner?.intID !='' && acceso ==1015">
     

      <div class="mx-auto text-center w-100">
        <!-- (click)="fnDetalleCte()" -->
        <!-- <button class="btn  py-0 px-1 fs12  shadow-sm"    title=" Ver Detalle" *ngIf="ctePartner?.intID !=''">
          <i class="bi bi-list-columns-reverse"></i>
        </button>  -->
        <span class="fs12 text-dark pe-3">
          Cliente habilitado para cotizar: <span class="fw-bold"> ({{ctePartner?.intID}}) {{ctePartner?.strNombreComercial}} </span> 
        </span> 
        <button  class="btn  py-0 px-1 fs12  shadow-sm" style="background: #3974AA;color:#e2f1ff;" (click)="fnquitarClientePartner()" *ngIf="ctePartner?.intID !=''" >
          <i class="bi bi-person-dash"></i> Quitar
        </button>
      </div>
        
    </div>
    <!--Fin cliente-->




    <!--Familia-->
    <div class="col-12 py-1 bgFamilia d-flex justify-content-center sticNameCte" *ngIf="objFamilia">
        <span class="fs12 text-white pe-3">
          Familia: 
        </span> 
      <button class="btn  py-0 px-1 fs12  shadow-sm" style="background: #a4b5c4;" (click)="fnIraFamilia()">
        <i class="fa-solid fa-people-line"></i> {{objFamilia.strFamilia}}
      </button>
      <span class="fs12 text-white pe-3 ms-2" *ngIf="objFamilia.strClienteSeleccionado && objFamilia.strClienteSeleccionado !=''">
        Cliente: ({{objFamilia.strClienteSeleccionado }}) {{objFamilia.strNombreCliente}}
      </span>
      <button class="btn  py-0 px-1 fs12  shadow-sm ms-2" style="background: #D2B27F;" (click)="fnquitarFamilia()">
        <i class="bi bi-person-dash"></i> Quitar
      </button>
    </div>
    <!-- Fin familia -->
    <div class="d-flex flex-column h-100">
      <router-outlet></router-outlet> 
      <app-footer class="mt-auto" *ngIf="!strUsuario || strUsuario==undefined || strUsuario==''"></app-footer>
    </div> 
  </mat-sidenav-content>
</mat-sidenav-container>
 

<div class="offcanvas offcanvas-bottom vh50" tabindex="-1" id="canvasEcommerce" aria-labelledby="canvasEcommerceLabel" data-bs-backdrop="true">
  <div class="offcanvas-header py-2">
    <h3 class="offcanvas-title mb-0 mx-auto fw-bold" id="canvasEcommerceLabel">Filtros</h3>
    <button type="button" class="btn-close text-reset ms-0" data-bs-dismiss="offcanvas" aria-label="Close" #btnCanvas></button>
  </div>
  <div class="offcanvas-body small px-0 py-0">
   <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="position-relative form-control border-0 ">
            <input [(ngModel)]="strArticuloTienda" type="text" placeholder="buscar" class="rounded-0 form-control text-secondary" (keyup.enter)="fnBuscarArtTienda()">
            <span class="position-absolute top-50 end-0 translate-middle-y d-flex align-items-center pe-3">
              <i class="bi bi-search text-secondary fs-5 pointer border-start ps-2" (click)="fnBuscarArtTienda()"></i>
            </span>
          </div>
        </div>
        <div class="col-12">
            <a class="nav-link dropdown-toggle bgCls px-3 py-0 rounded-0" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="bi bi-list"></i> CATEGORIAS</a>
            <ul class="dropdown-menu hslt py-0 ">
              <li class="border-bottom" *ngFor="let cat of arrayCategorias">
                <a class="dropdown-item fs10 fw-bold ps-1"  (click)="fnIrSubLinea(cat)">{{cat.strCategoria}} </a> </li>
            </ul>
        </div>
        <div class="col-12  my-2">
            <a class="nav-link  text-dark fw-bold btn"  (click)="fnNav('novedades')">NOVEDADES</a>
        </div>
        <div class="col-12  my-2">
            <a class="nav-link text-dark fw-bold btn"  (click)="fnNav('ofertas')">OFERTAS</a>
        </div>
      <!--   <div class="col-12  my-2">
            <a class="nav-link text-dark fw-bold btn">HISTORIAL</a>
        </div>
        <div class="col-12  my-2">
            <a class="nav-link text-dark fw-bold btn">COMPRAR DE NUEVO</a>
        </div>
        <div class="col-12  my-2">
            <a class="nav-link text-dark fw-bold btn">NUESTRO BLOG</a>
        </div> -->
        <div class="col-12  my-2">
            <a class="nav-link text-dark fw-bold btn" [routerLink]="['/ayuda']">AYUDA</a>
        </div>
      </div>
   </div>
    
  </div>
</div>
<!--listado de sucursales / direcciones partner-->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <h2 class="offcanvas-title" id="offcanvasExampleLabel">{{strTitulo_Canva_Partner}}</h2>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" #btnCanvas2></button>
  </div>
  <div class="offcanvas-body">
    <div *ngIf="btnEnvio">
       <div class="form-check" *ngFor="let direccion of arrayDireccion_partner"  >
        <input class="form-check-input mt-4" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
        [checked]="direccion.selected_partner" (input)="fnSelecciona_sucursal_Partner(direccion, 'ENVIO')"
          ><!-- (input)="fnSelecciona_sucursal_Partner(direccion, 'ENVIO')" -->
        <label class="form-check-label mt-4" for="flexRadioDefault1">
          <b>{{direccion.strSucursal}}</b> <br>
          <span> {{direccion.strCalle}},{{direccion.strNo_exterior}}</span><br>
          <span> {{direccion.strColonia}},{{direccion.strDelegacion}}</span><br>
          <span> {{direccion.strEstado}}, <b>{{direccion.strCodigoPostal}}</b></span>
        </label> <br>
       
      </div>
      <hr class="mt-5">
      <button type="button" class="btn btn-success mt-3 " [routerLink]="['/partner-cuenta']">Agregar Nueva Dirección</button>
    </div>
    
   <!--sucursales-->
   <div *ngIf="btnPickup">
   <div class="form-check" *ngFor="let direccion of arraySucursal_partner"  >
    <input class="form-check-input mt-5" type="radio" [checked]="direccion.selected_partner" name="flexRadioDefault" id="flexRadioDefault1" (input)="fnSelecciona_sucursal_Partner(direccion,'PICKUP')">
    <label class="form-check-label mt-4" for="flexRadioDefault1">
      <b>{{direccion.strNombre}}</b> <br>
      <span> {{direccion.strDireccion}},{{direccion.strNumeroExterior}}</span> <span *ngIf="direccion.strNumeroInterior && direccion.strNumeroInterior !=''">{{direccion.strNumeroInterior}}</span>  <br>
      <span> {{direccion.strColonia}},{{direccion.strDelegacion}}</span><br>
      <span> {{direccion.strEstado}}, <b>{{direccion.strCodigoPostal}}</b></span> <br>
      <span> {{direccion.strTelefonos}}</span>

    </label>
  </div>
</div>
  </div>
</div>