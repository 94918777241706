import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import Swal from 'sweetalert2';
import { DataDBService } from '../servicios/data-db.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { filter } from 'rxjs/operators';

declare var $: any
export interface tareasCte {
  strNombre: string;
  strMovID: string;
  strEstatus: string;
  strSituacion: string;
  strFechaRequerida: string;
  dblImporte: string;
  strDiasDif: string;
  cancelar: string;
  intEstatusPedido: string;
}
@Component({
  selector: 'app-pedidos-pendientes',
  templateUrl: './pedidos-pendientes.component.html',
  styleUrls: ['./pedidos-pendientes.component.css']
})
export class PedidosPendientesComponent implements OnInit {
 

  public strConexion: string = ''
  public strAgenteSP: string = ''
  public tagName: any;
  public arrayAccesos: any = []
  public intEjercicio: any
  public intPeriodo: any
  public boolAgente: boolean = false;
  public boolSucursal: boolean = false; 
  public strSucursal: any = ''
  public strSucursal_modal: any = ''
  public boolGerente: boolean = false;
  public strAgente: any
  public strAgente_Crea: any
  public strGerente: any
  public strGerente_modal: any
  public arrayGerentes: any = [];
  public arraySucursales: any = [];
  public arrayAgentes: any = [];
  public arrayPeriodo: any = [];
  public arrayEjercicio: any = [];
  public isHoveredButton: boolean = false;
  public listaPedidos: any = []
  public totalMov: any = 0
  public totalImporte: any = 0
  public banderaRegistros = false;
  public banderaRegistrosOK = false;
  public propertyName = '-strDiasDif';
  public strMercancia: string = "";
  public reverse = true;
  public IDPedidoF: any
  public strID: any = ''
  public strSituacion: any = ''
  public strAgenteLS: any = ''
  public loader: any = true;
  public spiner: boolean = false;
  public aFechaRequeridaS;
 /*  public datosTareasCte: MatTableDataSource<tareasCte>; */
  public datosTareasCte: MatTableDataSource<tareasCte> = new MatTableDataSource<tareasCte>([]);


  @ViewChild('paginadorTareasCte') paginadorTareasCte!: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte!: MatSort;
  @ViewChild('btncerrar') btnCerrarMdl!: ElementRef;
  columnsTareasCte: string[] = ['strNombre','strMovID','strEstatus','strFechaRequerida','dblImporte','strDiasDif','cancelar','intEstatusPedido'];
  constructor(private indexedDBService: DataDBService,private localSt: LocalStorageService,private paginatorIntl: MatPaginatorIntl, private router: Router, public elem: ElementRef, private _httpService: PeticionesService, private _filtroServ: FiltrosService) {
    
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgenteSP = this.localSt.retrieve('stragente');
    console.log(this.strAgenteSP)
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    console.log(this.tagName)
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos') ? this.localSt.retrieve('arrayAccesos') : [];
    console.log(this.arrayAccesos)
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this._httpService.setMostrarMenu(true);
    this.customizeItemsPerPageLabel();
  
  }

  ngOnInit(): void {
    console.log('tag name')
    console.log(this.tagName)
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos=value?value:[];
 
      console.log(' CARGA arrayaccesos en pedidos')

        if (this.arrayAccesos.length > 0) {
          var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
          if (objAcceso == undefined || objAcceso.length == 0) {
            this.router.navigate(['/login']);
          } else { 
          
          
            this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
            this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
            this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
            this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
            this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
            this.strGerente = (!this.boolGerente && this.boolSucursal) ? objAcceso[0].strUsuario : ''; //this.localSt.retrieve('strUsuario')
            this.fnGetFiltros()
            this.fnMostrarPedidos()
          }
        }
        else {
          this.router.navigate(['/login']);
        }
      });
 
    
  } 
  ngAfterViewInit() {
    this.fnTabletareasCte();
  }

  fnTabletareasCte() {
    this.datosTareasCte.sort = this.sortTareasCte;
    this.datosTareasCte.paginator = this.paginadorTareasCte;

  }
  fnGet_All() {
    this.fnMostrarPedidos();
  }

  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
      console.log(this.arraySucursales)
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  fnMostrarPedidos_respaldo() {
    this.loader = true
    this.spiner =true;
    this.listaPedidos = []
    let obj: any;
    obj = {
      strMov: 'Pedido',
      strAgente: this.strAgente ,//(this.strAgente == '')? this.strAgenteSP: this.strAgente,
      strSucursal: this.strSucursal,
      strDivision: this.strGerente,
      strConexion: this.strConexion
    }
    console.log(obj)
    this._httpService.getVentasMovimientos(obj).subscribe(
      result => {
        console.log(result)
          this.listaPedidos = result; 
/*         this.listaPedidos = this.strMercancia == "" ? result : result.filter(o => o.intEstatusPedido == this.strMercancia) */
        this.totalMov = this.listaPedidos.length
        for (let i = 0; i < this.listaPedidos.length; i++) {
          this.totalImporte = this.totalImporte + parseFloat(this.listaPedidos[i].dblImporte);
        }
        if (this.listaPedidos.length > 0) {
          this.banderaRegistros = false;
          this.banderaRegistrosOK = true;
        } else {
          this.banderaRegistros = true;
          this.banderaRegistrosOK = false;
        }
        this.spiner =false;
        this.datosTareasCte = new MatTableDataSource(this.listaPedidos);
        this.fnTabletareasCte();
        this.datosTareasCte.sort = this.sortTareasCte;
      },error =>{
        console.log(error.message)
        this.spiner =false;
      } );
      this.loader = false

  }
  fnMostrarPedidos() {
    this.loader = true
    this.spiner =true;
    this.listaPedidos = []
    let obj: any;
    obj = {
      strMov: 'Pedido',
      strAgente: this.strAgente ,//(this.strAgente == '')? this.strAgenteSP: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion:'getMovimientos_Pendientes_Detalles'
    }
    console.log(obj) 
      this._httpService.mostrarCotizaciones(obj, "sp_ModVentas_DashBoard")
      .subscribe(
      result => {
        console.log(result)
          this.listaPedidos = result; 
/*         this.listaPedidos = this.strMercancia == "" ? result : result.filter(o => o.intEstatusPedido == this.strMercancia) */
        this.totalMov = this.listaPedidos.length
        for (let i = 0; i < this.listaPedidos.length; i++) {
          this.totalImporte = this.totalImporte + parseFloat(this.listaPedidos[i].dblImporte);
        }
        if (this.listaPedidos.length > 0) {
          this.banderaRegistros = false;
          this.banderaRegistrosOK = true;
        } else {
          this.banderaRegistros = true;
          this.banderaRegistrosOK = false;
        }
        this.spiner =false;
        this.datosTareasCte = new MatTableDataSource(this.listaPedidos);
        this.fnTabletareasCte();
        this.datosTareasCte.sort = this.sortTareasCte;
      },error =>{
        console.log(error.message)
        this.spiner =false;
      } );
      this.loader = false

  }
  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
  }

  sortBy(propertyName) { // funcion para ordenar por otro campo
    this.reverse = (this.propertyName === propertyName) ? !this.reverse : false; //cambia la forma de ordenarse, ascendente o descendente
    this.propertyName = propertyName; // se ordena por el campo que se preciono.
  };

  desglocePedido(obj) {
    console.log(obj)
    this.strID = obj.strID;//Encrypt.encode(obj.strID);
    this.strSituacion = obj.strSituacion;
    this.router.navigate(['/detalle_pedido/'], { queryParams: { tipo: 'p', strID: this.strID, strSituacion: this.strSituacion } });
    /* location.href = "DesglocePRVC.php?tipo=p&strID=" + this.strID + "&strSituacion=" + this.strSituacion; */
  }

  CambiarFR(idPedido: any) {
    this.aFechaRequeridaS = null
    this.IDPedidoF = idPedido;
  }

  cancelarPedido(obj: any) {
    Swal.fire({
      title: "¿Confirmar cancelación de pedido "+ obj.strMovID+"?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, cancelar!",
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/cancelar_pedido/'], { queryParams: { strID: obj.strID } });
      }
    });
   /*  if (confirm(String.fromCharCode(191) + 'Est' + String.fromCharCode(225) + 's seguro de cancelar este pedido?')) {
      location.href = "CancelarPedido.php?strID=" + obj.strID;
    } */
  }

  eliminarPedido(obj: any) { // Funcion para eliminar el pedido que no haya sido afectado; (que no tenga MovId)
    let objEnviar: any;
    obj = { 'strID': obj.strID }
    this._httpService.dropPedido(objEnviar).subscribe(
      result => {
        console.log(result)
        this.fnMostrarPedidos()
      });
  }

  CambiarFechaRequerida() {
     let arrayRespuesta = [];
    let spFechaRequerida =new Date(this.aFechaRequeridaS);
    let objEnviar = {
      'strID': this.IDPedidoF,
      'strFechaRequerida': spFechaRequerida
    };
    console.log(objEnviar);
    this._httpService.getpagViejitaPlantillas(objEnviar, 'setFechaRequeridaVenta').subscribe(
      result => {
        arrayRespuesta = result;
        console.log(arrayRespuesta)
        Swal.fire({
          icon: "success",
          title: "Fecha Requerida actualizada correctamente.",
          showConfirmButton: false,
          timer: 2000
        });
  
        this.btnCerrarMdl.nativeElement.click();
        this.fnMostrarPedidos();
      },
      (error: any) => {
        Swal.fire({
          icon: "error",
          title: "Error en:",
          text: error.message
        });
        console.error(error.message);
      }
    );
  }

  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }
  fnFiltrarMercancia(){
    let newArray =[]
    newArray = this.strMercancia == ""?this.listaPedidos: this.listaPedidos.filter(o => o.intEstatusPedido == this.strMercancia)
    this.datosTareasCte = new MatTableDataSource(newArray);
    this.fnTabletareasCte();
  }
}


