import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';//Inject
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogAutorizarRetoComponent } from '../dialog-autorizar-reto/dialog-autorizar-reto.component';
declare var require: any;
  import Swal from 'sweetalert2';  
  import * as pdfMake from 'pdfmake/build/pdfmake';
  import * as pdfFonts from 'pdfmake/build/vfs_fonts';
 const htmlToPdfmake = require("html-to-pdfmake");
 (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-reto-tork-bitacora-seguimiento',
  templateUrl: './reto-tork-bitacora-seguimiento.component.html',
  styleUrls: ['./reto-tork-bitacora-seguimiento.component.css']
})
export class RetoTorkBitacoraSeguimientoComponent implements OnInit {
  public strTipo:string="Cliente";
  public strCliente: string="";
  public strNombre: string="";
  public intIDSucursal: number=0;
  public strNombreSucursal: string="";
  public strFechaInicial: string="";
  public strContacto: string="";
  public strTelefono: string="";
  public strPuesto: string="";
  public strGiro: string="";
  public strDireccion: string=""
  public strComentarios: string="";
  public strEstatus: string;
  public arraySeguimientoTork: any=[];
  public strComentariosSeguimiento: string="";
  public strEstatusSeguimiento: string="";
  public strEstatusAnalisis: string="";
  public tagName:any;
  public intIDReto: number=0;
  public arrayListaSeguimiento: any=[];
  public arraySegInicial: any=[];
  public arraySegTork: any=[];
  public arrayRespuesta: any=[];
  public xmlVisitas: any=[];
  public spBloquea1: boolean=false;
  public spBloquea2: boolean=false;
  public spBloquea3: boolean=false;
  public spBloquea4: boolean=false;
  public spBloquea5: boolean=false;
  public spBloquea6: boolean=false;
  public strFechaActual: string="";
  public validarCamposCompletos: boolean=true;
  public showBtnFin:boolean=false;
  public strRespuesta:string='';
  public spCargando:boolean=false;
  //public spValidaAceptaRechazaReto:boolean=true;
  public spMostrarBuscarCliente: boolean=false;
  public strBusqueda: string="";
  public arrayClientes: any=[];
  public mostrarCargando:boolean=false;
  public strAgente: string="";
  public arrayAccesos: any=[];
  public arrayDatosCteSeleccionado:any=[];
  public spOcultaBoton: boolean=false;
  public strMotivoRechazoReto: string="";
  public btnGuardar: boolean=false;
  public btnFinalizar: boolean=false;
  public totalVisitas:number=6;
  public arrayVisitaLocal:any[];
  public arrayVisitaTork:any[];
  public arrayVisitas:any[];
  public visitaActivaL:number;
  public visitaActivaT:number;
  public arrayPresentacion:any[];
  public resultCategoria:any[]=[];
  public arrayPrecios:any[];
  public totalTork:number=0;
  @ViewChild('autorizaRetoModal') autorizaRetoModal?: ElementRef;
  @ViewChild('pdfTable') pdfTable!: ElementRef;
  @ViewChild('pdfTable2') pdfTable2!: ElementRef;
  constructor(elem:ElementRef, private _formBuilder: FormBuilder, private dialogRef: MatDialog, private _peticionesService: PeticionesService, private localSt:LocalStorageService, private router: Router ) {  
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  }

  ngOnInit(): void {
    var date = new Date();
    var mes=date.getMonth() + 1;
    var mes2:string=mes.toString();
    if(mes<10){
      mes2='0'+mes;
    }
    
    this.strFechaActual=date.getFullYear()+'-'+mes2+'-'+date.getDate()


    this.arrayAccesos = this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):{};
    //Crear las variables para mostrar o no los botones o permisos a los que tiene acceso el usuario
    if(this.arrayAccesos.length>0){
      this.strAgente=this.arrayAccesos[0]?this.arrayAccesos[0].strAgente:'';
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined || objAcceso.length ==0){
        this.router.navigate(['/login']);
      }
      else { 
        this.btnGuardar=objAcceso.find((o:any)=>o.strAccion=='Guardar')?true:false;  
        this.btnFinalizar=objAcceso.find((o:any)=>o.strAccion=='Finalizar')?true:false;        
      }      
    }else{
      this.router.navigate(['/login']);
    }

    

    //Consultar la informacion de cabecera a mostrar
    this.arraySeguimientoTork=this.localSt.retrieve('arraySeguimientoTork')?this.localSt.retrieve('arraySeguimientoTork'):{};
    if(this.arraySeguimientoTork.intIDReto>0){
      //this.blnDetalle=true;
      this.strCliente=this.arraySeguimientoTork.strCliente?this.arraySeguimientoTork.strCliente:'';
      this.strGiro=this.arraySeguimientoTork.strGiro?this.arraySeguimientoTork.strGiro:'';
      this.strDireccion=this.arraySeguimientoTork.strDireccion?this.arraySeguimientoTork.strDireccion:'';
      this.strTelefono=this.arraySeguimientoTork.strTelefono?this.arraySeguimientoTork.strTelefono:'';
      this.strContacto=this.arraySeguimientoTork.strContacto?this.arraySeguimientoTork.strContacto:'';
      this.strNombre=this.arraySeguimientoTork.strNombre?this.arraySeguimientoTork.strNombre:'';
      this.intIDSucursal=this.arraySeguimientoTork.intIDSucursal?this.arraySeguimientoTork.intIDSucursal:0;
      this.strNombreSucursal=this.arraySeguimientoTork.strNombreSucursal?this.arraySeguimientoTork.strNombreSucursal:'';
      this.strNombre=this.arraySeguimientoTork.strNombre?this.arraySeguimientoTork.strNombre:'';
      this.strTipo=this.arraySeguimientoTork.strTipo?this.arraySeguimientoTork.strTipo:'';
      this.strFechaInicial=this.arraySeguimientoTork.strFechaInicial?this.arraySeguimientoTork.strFechaInicial:'';
      this.intIDReto=this.arraySeguimientoTork.intIDReto?this.arraySeguimientoTork.intIDReto:0;
      this.strComentarios=this.arraySeguimientoTork.strComentarios?this.arraySeguimientoTork.strComentarios:'';
      this.strComentariosSeguimiento=this.arraySeguimientoTork.strComentariosSeguimiento?this.arraySeguimientoTork.strComentariosSeguimiento:'';
      this.strEstatus=this.arraySeguimientoTork.strEstatus?this.arraySeguimientoTork.strEstatus:'';
      this.strPuesto=this.arraySeguimientoTork.strPuesto?this.arraySeguimientoTork.strPuesto:'';
      this.strEstatusSeguimiento=this.arraySeguimientoTork.strEstatusSeguimiento?this.arraySeguimientoTork.strEstatusSeguimiento:'';
      this.strEstatusAnalisis=this.arraySeguimientoTork.strEstatusAnalisis?this.arraySeguimientoTork.strEstatusAnalisis:'';
      this.strMotivoRechazoReto=this.arraySeguimientoTork.strMotivoRechazoReto?this.arraySeguimientoTork.strMotivoRechazoReto:'';
      this.fnGetVisitas(this.intIDReto);
     
      
    }
    
  }
 
  fnPdf(){
    var dd = { content: 'your pdf data' };
    //pdfMake.createPdf(dd).download();
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download(); 
  }
  fnPdf2(){
    var dd = { content: 'your pdf data' };
    //pdfMake.createPdf(dd).download();
    const pdfTable = this.pdfTable2.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html ,pageOrientation: 'landscape'};
    pdfMake.createPdf(documentDefinition).download(); 
  }
  getCalculo(obj:any, padre :any){
      let cantidadC = obj.dblValor
      let precioN = obj.PrecioSeleccionado
      obj.dblTotal =obj.dblValor*obj.PrecioSeleccionado;
      //obtener el total del consumible del cliente
      let totalActual = padre.arrayConsumibles.find(o=>o.strTipo== "ACTUAL").dblTotal;
      let nuevaDivision = 1-(obj.dblTotal/totalActual);
      padre.dblResult = nuevaDivision
      console.log(nuevaDivision);
      padre.intNumVisita= nuevaDivision <= 0?0:1 //icono de flecha o tach
  }
  getCalculoConsumo(obj:any, padre :any){
    console.log(obj);
    console.log(padre);
    obj.cmxhoja=obj.cmxhoja?obj.cmxhoja:0;
    obj.hojaxrollo=obj.hojaxrollo?obj.hojaxrollo:0;
    let cmxhoja = obj.cmxhoja
    let hojaxrollo = obj.hojaxrollo;
     
    obj.cmxrollo =obj.cmxhoja*obj.hojaxrollo;
    let valor =padre.strCategoria!=  "DESPACHADOR PARA JABON" ?obj.cmxrollo:obj.mlxcartucho;
    obj.cmtotales=valor * (obj.dblValor-obj.sobrantes);
    let div =padre.strCategoria!=  "DESPACHADOR PARA JABON" ?100:obj.mlxcartucho;

    obj.mtstotales=obj.cmtotales>0?obj.cmtotales/div:0;
    obj.precioxcm=obj.PrecioSeleccionadoCJ/(valor*obj.intPiezas);
    obj.result2=obj.precioxcm*obj.cmtotales;
   
    padre.uno=obj.strTipo=='ACTUAL'?obj.result2:padre.uno;

    padre.dos= obj.strTipo!='ACTUAL'?obj.result2:padre.dos;     
    padre.dblResult2=1-(padre.dos/padre.uno)  ;
    
}
  fnGetVisitas(idReto:number){
    this.arrayVisitaLocal=[];
    this.arrayVisitaTork=[];
    this.visitaActivaL=0;
    this.visitaActivaT=0;
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getVisitas',
      "intIDReto": idReto
    }).subscribe( result => {
      this.arrayVisitas = result; 
   
      this.arrayVisitas.forEach(element => {
        if(element.strTipo=='LOCAL'){
          this.visitaActivaL=element.strVisita2>0?element.intNumVisita:this.visitaActivaL;
          let obj=this.arrayVisitaLocal.find(o=>o.intNumVisita ==element.intNumVisita);
          if(!obj){this.arrayVisitaLocal.push(element)}
        }
        else {
          this.visitaActivaT=element.strVisita2>0?element.intNumVisita:this.visitaActivaT;
          let obj=this.arrayVisitaTork.find(o=>o.intNumVisita ==element.intNumVisita);
        
          if(!obj){  this.arrayVisitaTork.push(element) ;
     
          }
        }
      });  
      this.fnGetListaSeguimientoInicial(this.intIDReto); 
      this.fnGetListaSeguimientoTork(this.intIDReto);  
      this.fnGetPrecios(this.intIDReto); 
      if(this.visitaActivaL==0 && this.visitaActivaT==0){       
        this.showBtnFin=true;
      }
    });
  }

  fnGetPrecios(idReto:number){
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getPreciosArticuloR',
      "intIDReto": idReto
    }).subscribe( result => {
      this.arrayPrecios=result; 
      this.fnGetPresentacion(idReto);
    });
      
       
  
  }
  fnGetPresentacion(idReto:number){
    this.resultCategoria=[];
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getPresentacion',
      "intIDReto": idReto
    }).subscribe( result => {
      let arrayCmxhoja =[{valor:16},{valor:18},{valor:20},{valor:22},{valor:24}]
      this.arrayPresentacion = result;
      this.arrayPresentacion.forEach(element => {
        let obj= this.resultCategoria.find(o=>o.strCategoria==element.strCategoria);
        
        if(!obj){ 
          let consumible = this.arrayPresentacion.filter(o=>o.strCategoria==element.strCategoria)
       
          //obtener lista de precios
          consumible.forEach(elementc => {
            elementc.hojaxrollo=elementc.strTipo=='ACTUAL'?0:620;
            elementc.sobrantes=0;
            
            elementc.cmxhoja=elementc.strTipo=='ACTUAL'?20:18;
            elementc.cmxrollo=elementc.strTipo=='ACTUAL'?0:elementc.hojaxrollo* elementc.cmxhoja;
            elementc.arrayCmxhoja=arrayCmxhoja;
            elementc.cmtotales=elementc.cmxrollo * (elementc.dblValor-elementc.sobrantes);
 
            elementc.cmtotales=elementc.cmxrollo * (elementc.dblValor-elementc.sobrantes);
            elementc.mtstotales=elementc.cmtotales>0?elementc.cmtotales/100:0;
           

            let arrPrecios=this.arrayPrecios.filter(o=>o.strArticulo==elementc.strClaveConsumible) 
            let arrPrecios1= [{dblPrecioLista:elementc.dblCostoPromedio}];
 
            elementc.arrayPrecios = elementc.strTipo=='ACTUAL'?arrPrecios1:arrPrecios;
           // elementc.PrecioSeleccionado=elementc.strTipo=='ACTUAL'?elementc.dblCostoPromedio:   arrPrecios.length>0?arrPrecios[0].dblPrecioLista:0;
           elementc.PrecioSeleccionado=elementc.dblCostoPromedio>0?elementc.dblCostoPromedio: arrPrecios[0].dblPrecioLista;//arrPrecios.length>0?arrPrecios[0].dblPrecioLista:elementc.dblCostoPromedio;
           elementc.PrecioSeleccionadoCJ=0;
           elementc.precioxcm=elementc.PrecioSeleccionadoCJ/(elementc.cmxrollo*elementc.intPiezas);
           elementc.result2=elementc.precioxcm*elementc.cmtotales;
           // elementc.dblTotal=elementc.PrecioSeleccionado*elementc.dblValor;
            console.log(elementc);
            console.log(element);
            // element.dblResult=elementc.PrecioSeleccionado * element
          })
          
           
          this.resultCategoria.push({arrayConsumibles:consumible,strCategoria:element.strCategoria, dblResult:element.dblResult, 
            intNumVisita:element.intNumVisita })
         }
      });
      console.log(this.resultCategoria)
       
    });
  }
  fnGetListaSeguimientoInicial(idReto:number){
   

    this._peticionesService.getApiRetoTork({
      "strAccion": 'getSeguimientoInicial',
      "intIDReto": idReto
    }).subscribe( result => {
      this.arraySegInicial=result;
        this.arraySegInicial.forEach(element => {
        let arrayAux = this.arrayVisitas.filter(o=>o.strTipo=='LOCAL' && o.strVisita3==element.strClaveConsumible)
         arrayAux.forEach(item => { 
          element["strVisita"+item.intNumVisita] =item.dblValor;
          element.visitaActiva=item.strVisita2>0?item.intNumVisita:element.visitaActiva;
         })
      })
      console.log(this.arraySegInicial) 
    });
  }  
  
  fnGetListaSeguimientoTork(idReto:number){
    this._peticionesService.getApiRetoTork({
      "strAccion": 'getSeguimientoTork',
      "intIDReto": idReto
    }).subscribe( result => {
      this.arraySegTork=result; 
      console.log(this.arraySegTork) 
      this.arraySegTork.forEach(element => {
        element.totalTork=0;
        let arrayAux = this.arrayVisitas.filter(o=>o.strTipo=='TORK' && o.strVisita3==element.strClaveConsumible)
         arrayAux.forEach(item => { 
          element["strVisita"+item.intNumVisita] =item.dblValor;
          element.visitaActiva=item.strVisita2>0?item.intNumVisita:element.visitaActiva;
          element.totalTork= element.totalTork+ item.dblValor;
         })
      })
    });
  }

  fnCambioValorFecha(element:any, numero:number, numeroVisita:any){
     
    if(numero==1){element.strFechaVisita1=this.strFechaActual;}
    if(numero==2){element.strFechaVisita2=this.strFechaActual;}
    if(numero==3){element.strFechaVisita3=this.strFechaActual;}
    if(numero==4){element.strFechaVisita4=this.strFechaActual;}
    if(numero==5){element.strFechaVisita5=this.strFechaActual;}
    if(numero==6){element.strFechaVisita6=this.strFechaActual;}
  }
  
  changeStatus(obj){
    obj.checked=obj.checked?false:true;
    console.log(obj)
  }
  fnGuardarVisita(){    
    this.xmlVisitas=[];//Limpiar array que guardara las vitas
    this.validarCamposCompletos=true;

      if(this.arraySegInicial.length>0){
        this.arraySegInicial.forEach(element => { 
          let x = 'strVisita'+element.visitaActiva
          let valor =element[x]; 
          let objxml = {strCodigo:element.strClaveConsumible, strVisita1: valor, strVisita2:element.visitaActiva,strTipo:'LOCAL'}
        // if(valor>0){}
          if(valor>0 || element.checked){
            this.xmlVisitas.push(objxml)
          }
        });
      }
    console.log(this.xmlVisitas);
    if(this.arraySegTork.length>0){
      this.arraySegTork.forEach(element => {
        let x = 'strVisita'+element.visitaActiva
        let valor =element[x]; 
        let objxml = {intSobrante:element.intSobrante,intCantidadInicial:element.intCantidadInicial,strCodigo:element.strClaveConsumible, strVisita1: valor, strVisita2:element.visitaActiva,strTipo:'TORK'}
        if(valor>0 || element.checked||element.intCantidadInicial>0){
          if(element.intCantidadInicial>0 && valor==0&&!element.checked){
            objxml.strVisita2=0
          }
         this.xmlVisitas.push(objxml)
        }
        //revisar si insertaron sobrantes
        if(element.intSobrante>0){
          let objxml = { intCantidadInicial:element.intCantidadInicial,strCodigo:element.strClaveConsumible, strVisita1: element.intSobrante, strVisita2:'Sobrante',strTipo:'TORK'}
        
          this.xmlVisitas.push(objxml);
        }
      });
    } 

    console.log(this.xmlVisitas);

    this.fnGuardarVisitaOk();    
  }

  fnGuardarVisitaOk(){
    this._peticionesService.getApiRetoTork({
      "strAccion": "setVisitasTork",
      "intIDReto": this.intIDReto,
      "strComentariosSeguimiento": this.strComentariosSeguimiento,
      "xmlVisitasRetoTork": this.xmlVisitas
    }).subscribe( result => { 
      this.arrayRespuesta=result;
      
      //Mensaje
      Swal.fire({
        icon: 'success',
        title: 'Correcto',
        text: 'Se ha guardado su visita correctamente',
      });       
      /*if (this.xmlVisitas[0].strVisita6== undefined){this.router.navigate(['/retoTork']);}else {this.showBtnFin =true;}*/
      this.router.navigate(['/retoTork']);
    });   
  }
 
  openDialogDetalle(strTipoReto:string): void {//obj:any
    let arrayInfo={
      strTipo: this.strTipo,
      strCliente: this.strCliente,
      strNombre: this.strNombre,
      intIDSucursal: this.intIDSucursal,
      strNombreSucursal: this.strNombreSucursal,
      strAgente: this.strAgente,
      intIDReto: this.intIDReto,
      strTipoReto:strTipoReto
    }
    const dialogRef=this.dialogRef.open(DialogAutorizarRetoComponent, {
      width: '450px',
      data: arrayInfo 
    });//
    
    dialogRef.afterClosed().subscribe(result => {
      if(result != "false" && result != undefined){
       
      }
    });    
  }
  fnNuevaSolicitudComodato(){
    this.spCargando=true;
    let arraySalidaDiv =[];
    this.arraySegTork.forEach(element => {
      if(element.dblNuevaCantidad && element.dblNuevaCantidad>0){
        element.strUnidadConsumible='CJ';
        element.intCantidadConsumible=element.dblNuevaCantidad;
        arraySalidaDiv.push(element)
      }
    })
    console.log(arraySalidaDiv)
    if (arraySalidaDiv.length>0) {
      let objParam={
        "strAccion": "setNewSalidaDiversa",
        xmlBitacoraEntregados:arraySalidaDiv,
        intIDReto:this.intIDReto
      }

      this._peticionesService.getApiRetoTork( 
        objParam
      ).subscribe( result => { 
      
        let  Respuesta=result;
        let mensaje =Respuesta[0]?Respuesta[0].strAccion:'Error al intentar afectar solicitud'
         
        this.spCargando=false;

        //Mensaje
        Swal.fire({
          icon:  mensaje.includes('Error')? 'error': 'success',
          title: mensaje.includes('Error')?'Error':'Correcto',
          text: mensaje  ,
        });  
        document.getElementById('exampleModal').click();
        this.fnGetVisitas(this.intIDReto);
        
      }); 
    }
    else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Debe ingresar cantidades correctas',
        });      
    }
  }

  

}
