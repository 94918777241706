<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold  text-decoration-underline">Seguimiento  de <span class="titulo">  Pedidos</span></h1>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col mt-2 pt-1 d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Razon Social</mat-label>
              <mat-select [(ngModel)]="model_razonSocial" class="" (selectionChange)="fnFiltrar_otros(model_razonSocial)" >
                <mat-option value="">
                    -- Todos --
                </mat-option>
               <mat-option *ngFor="let x of arrayRazonSocial" [value]="x.strNombreCte">{{x.strNombreCte}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col mt-2 pt-1 d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal Cliente</mat-label>
              <mat-select [(ngModel)]="model_Sucursalcte" class="" (selectionChange)="fnFiltrar_otros(model_Sucursalcte)" >
                <mat-option value="">
                    -- Todos --
                </mat-option>
               <mat-option *ngFor="let x of arraySucursalcte" [value]="x.strNombreSucursalCte">{{x.strNombreSucursalCte}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>

        <div class="col mt-2 pt-1 d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Estatus Pedido</mat-label>
              <mat-select [(ngModel)]="model_ESTATUS" class=""   >
                <mat-option value="">
                    -- Todos --
                </mat-option>
                <mat-option value="PENDIENTE">
                    PENDIENTE
                </mat-option>
                <mat-option value="CONCLUIDO">
                    CONCLUIDO
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col mt-2 pt-1 d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col mt-2 pt-1 d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div> 
       
        <div class="col mt-2 pt-1 d-flex justify-content-center align-items-center" >
            <button (click)="fnGetPedidos()" class="btn btnFilter shadow-sm px-2 py-1"  (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
   
    <div class="row mt-3">
        <div class="col-12">
            <div *ngIf="spinerPed" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="alert alert-danger text-center fw-bold my-3" *ngIf=" !spinerPed && arrayPedidos.length==0" role="alert">
                No se han encontrado datos de Facturas
              </div>
              <div  [hidden]="arrayPedidos.length==0">
                <div class="col-12 d-flex px-2 bg-white justify-content-between align-items-center">
                    <mat-form-field appearance="outline" class="bg-white rounded my-2">
                        <mat-label>Buscar:</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                      </mat-form-field>

                      
                      <span > 
                       <span class="fw-bold">Total Documentos:</span>  {{arrayPedidos.length}}
                      </span>
                </div>
              </div>
              
            <div class="table-responsive mb-0" [hidden]="arrayPedidos.length==0">
                  <div class="mat-elevation-z8">
                    <table class="table w-100 table-bordered" mat-table [dataSource]="dataSource " matSort>
                      
                        <ng-container matColumnDef="strCliente">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente  </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle  fs12  " ><b>({{row.strCte}})</b>  {{row.strNombreCte}} </td>
                          </ng-container>

                          <ng-container matColumnDef="strNombreCorto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Corto   </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle  fs12 " > {{row.strNombreCortoCte}}    </td>
                          </ng-container>

                          <ng-container matColumnDef="strOrdenCompra">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Orden Compra</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12">  <b> {{row.strOrdenCompraP}} </b>  </td>
                          </ng-container>

                          <ng-container matColumnDef="strArchivoOrdenCompra">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Archivo Orden Compra</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" >
                                 <a *ngIf="row.strRutaArchivoOC && row.strRutaArchivoOC !=''" [href]="row.strRutaArchivoOC" target="_blank">
                                    <i class="fa-solid fa-cloud-arrow-down" style="font-size: 18px;"></i>
                                 </a>  
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="strFechaOrdenCompra">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Orden Compra</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> <span class="text-nowrap">  {{row.strFemisionP}}   </span></td>
                          </ng-container>

                          <ng-container matColumnDef="PedidoEcodeli">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pedido Ecodeli</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> <span class="text-nowrap">  {{row.strMovIDP}}   </span></td>
                          </ng-container>

                          <ng-container matColumnDef="strEstatusPedido">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus Pedido  </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fw-bold fs12 "  > {{row.strEstatusP }}     </td>
                          </ng-container> 

                          <ng-container matColumnDef="strRequisicion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>  Requisición </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fw-bold fs12"  >  {{row.strReferencia}}    </td>
                          </ng-container> 


                      
                          

                          
                          <ng-container matColumnDef="dblImporte">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap"> {{row.dblImporteP | currency}} </span></td>
                          </ng-container>

                            
                          <ng-container matColumnDef="facturaEcodeli">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Factura Ecodeli </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap"> {{row.strMovIDF  }} </span></td>
                          </ng-container>

                          <ng-container matColumnDef="fechaemisionFactura">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Factura  </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap"> {{row.strFechaEmisionF  }} </span></td>
                          </ng-container>
                          <ng-container matColumnDef="importeFactura">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Factura  </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap"> {{row.dblImporteF | currency}} </span></td>
                          </ng-container>

                          <ng-container matColumnDef="fechaEntregaD">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Entrega Docto  </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap"> {{row.strFechaConclusionE  }} </span></td>
                          </ng-container>

                          <ng-container matColumnDef="evidenciaEntrega">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Evidencia de Entrega</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" > 
                                <a *ngIf="row.strRutaArchivo && row.strRutaArchivo !=''" [href]="row.strRutaArchivo" target="_blank">
                                    <i class="fa-solid fa-cloud-arrow-down" style="font-size: 18px;"></i>
                                 </a> 
                            
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="estatusEntrega">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus Entrega</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap"> {{row.strEstatusE}} </span></td>
                          </ng-container>

                          <ng-container matColumnDef="indicadorDias">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Indicador Días Pedido</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12" ><span class="text-nowrap">
                                <span class="badge text-bg-light">{{row.strAccion}} </span>
                                 </span>
                                </td>
                          </ng-container>


                          
                
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  
                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                      </tr>
                    </table>
                  
                    
                  </div>
            </div>
            <div class="col-12" [hidden]=" arrayPedidos.length==0">
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>
