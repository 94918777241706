<div class="container"> 
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold titulo text-decoration-underline">Plantillas</h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetPlantillas()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div class="row my-4 justify-content-center">
        <div class="row bgtable_2 bordertop">
            <div class="col-6 col-md-4 my-2">
                <input type="text" class="form-control" style="height:43px ;" [(ngModel)]="inputSearch" placeholder="Buscar: Cliente,Nombre">
            </div>
            <div class="col-6 col-md-4 my-2">
                <mat-form-field appearance="outline" class="bg-white rounded ">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Estatus</mat-label>
                    <mat-select [(ngModel)]="strEstatus" class=""  (selectionChange)="fnGetPlantillas()"> <!-- (selectionChange)="fnGetPlantillas()" -->
                    <mat-option *ngFor="let x of arrayEstatus" [value]="x.strEstatus">{{x.strEstatus}}</mat-option> 
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-2 my-2">
                <mat-form-field appearance="outline" class="bg-white rounded ">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Tipo Solicitud</mat-label>
                    <mat-select [(ngModel)]="strTipo" class=""  > <!-- (selectionChange)="fnGetPlantillas()" -->
                    <mat-option *ngFor="let x of arrayTipo" [value]="x.strTipo">{{x.nombre}}</mat-option> 
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 bg-white" *ngIf="spiner">
            <mat-spinner class="mx-auto"></mat-spinner>
        </div>
        <div class="col-12 table-responsive mx-0 px-0 mb-0 border"  *ngIf="!spiner">
            <table class="table table-bordered table-hover bg-white">
                <thead class="" >
                    <tr class="thcenter bgtable sticky-top">
                        <th>
                            <span>ID</span>
                        </th>
                        <th>
                            <span>Cliente</span>
                        </th>
                        <th>
                            <span>Fecha</span>
                        </th>
                        <th>
                            <span>Agente</span>
                        </th>
                        <th>
                            <span>Sucursal</span>
                        </th>
                        <th>
                            <span>Autorizo</span>
                        </th>
                        
                        <th>
                            <span>Importe</span>
                        </th>
                        <th>
                            <span>
                            </span>
                        </th>
                        <th *ngIf="strEstatus=='POR AUTORIZAR'">
                            <span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tdcenter" *ngFor="let cot of arrayCotizaciones | 
                    filtersearch: inputSearch : ['strNombreCliente','strCliente','intIDMovimientoVenta' ]|
                     filtersearch: strTipo : ['strTipo']  let ind = index;" [class.table-hover]="!cot.mostrarDetalle" >
                        <td data-bs-toggle="modal" data-bs-target="#mdlAutorizar" (click)="fnGetDetallePlantilla(cot,1)">
                          
                            <span class="fs12 fw-bold">
                                {{cot.intIDMovimientoVenta}}
                            </span>
                        </td>
                        <td class="text-start " >
                        <a data-bs-toggle="modal" data-bs-target="#mdlAutorizar"  (click)="fnGetDetallePlantilla(cot,1)">
                            <span  class="fs12  " [ngClass]="{'text-success': cot.strTipo === 'CLIENTE', 'text-info': cot.strTipo !== 'CLIENTE'}"  >
  
                                    <i class="fs16  "[ngClass]="{'bi bi-person-fill-check': cot.strTipo === 'CLIENTE', 'bi bi-person-bounding-box': cot.strTipo !== 'CLIENTE'}" 
                                    title="{{cot.strTipo}}"></i> {{cot.strCliente}}   </span> 
                                     <span class="fs12 "> - {{cot.strNombreCliente}}</span>
                        </a>
                        </td>
                        <td data-bs-toggle="modal" data-bs-target="#mdlAutorizar"  (click)="fnGetDetallePlantilla(cot,1)">
                            <span class="text-nowrap fs12">
                                {{fechasComoDate(cot)|date}}
                            </span>
                        </td>
                        <td data-bs-toggle="modal" data-bs-target="#mdlAutorizar" (click)="fnGetDetallePlantilla(cot,1)">
                            <span class="fs12 fw-bold">
                                {{cot.strNombreMostrar}}
                            </span>
                        </td>
                        <td data-bs-toggle="modal" data-bs-target="#mdlAutorizar" (click)="fnGetDetallePlantilla(cot,1)">
                            <span class="fs12 fw-bold">
                                {{cot.strSucursalNombre}}
                            </span>
                        </td>
                        <td class="text-end" data-bs-toggle="modal" data-bs-target="#mdlAutorizar"  (click)="fnGetDetallePlantilla(cot,1)">
                            <span class="fw-bold fs12 ">
                                {{cot.strAutorizo}}
                            </span>
                        </td>
                       
                     
                        <td class="text-end" data-bs-toggle="modal" data-bs-target="#mdlAutorizar"  (click)="fnGetDetallePlantilla(cot,1)">
                            <span class="fw-bold fs12 ">
                                {{cot.dblPrecioLista|currency}}
                            </span>
                        </td>
                        <td >   <span *ngIf="cot.strEstatus=='POR AUTORIZAR'" class="btn btn-outline-primary fs12 p-1 shadow-sm text-nowrap" (click)="fnGetDetallePlantilla(cot,2)" >  <i class="bi  bi-pencil-square  fs12 "  ></i> Editar</span>
                          <span *ngIf="cot.strEstatus=='AUTORIZADO' && cot.strTipo=='CLIENTE'" class="btn btn-success fs12 p-1 shadow-sm text-nowrap"  (click)="fnGetDetallePlantilla(cot,3)" >  <i class="bi  bi-send  fs12 " ></i> Enviar a pedido</span>
                          <span *ngIf="cot.strEstatus=='RECHAZADO'" (click)="fnGetDetallePlantilla(cot,1)"  data-bs-toggle="modal" data-bs-target="#mdlAutorizar"  class="btn btn-danger fs12 p-1 shadow-sm text-nowrap">  <i class="bi  bi-x  fs12 " ></i> Corregir</span>
                        </td>
                        <td *ngIf="cot.strEstatus=='POR AUTORIZAR'" class=""  (mouseenter)="cot.isHovered = true" (mouseleave)="cot.isHovered = false">
                            <i class="bi   text-danger fs-5 mouseHov align-middle" (click)="fnEliminarlst(cot)"  [ngClass]="{'bi-trash3': !cot.isHovered, 'bi-trash3-fill': cot.isHovered}"></i>
                        </td>
                    </tr>
                </tbody>
        
            </table> 
        </div>
        <div class="col-12 px-0 borderbott bgtable text-end py-2">
     
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="mdlAutorizar" tabindex="-1" aria-labelledby="mdlAutorizarLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xxl">
      <div class="modal-content">
        <div class="modal-header p-2 bgModal">
          <h2 class="modal-title mb-0 mx-auto text-white text-decoration-underline" id="mdlAutorizarLabel">DETALLE DE PLANTILLA </h2>
          <button type="button" class="btn-close ms-0 " data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row justify-content-evenly">
                <div class="col-12 mb-3 mb-lg-0 col-lg-9 shadow-sm borderbott bordertop py-2 bg-light d-flex flex-column" >
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="input-group mb-2">
                                <span class="input-group-text fs12" id="basic-addon1">Cliente</span>
                                <span type="text"  class="form-control fs12 d-flex "  placeholder="Cliente" aria-label="Username" aria-describedby="basic-addon1">
                                    <span class="text-primary"><a (click)="fnDetalleCte(objCte.strCliente)" >{{objCte.strCliente}} </a></span><span> - {{objCte.strNombreCliente}}</span>
                                </span>
                                <span class="input-group-text"><i class="bi bi-person-video2"></i></span>
                              </div>
                              <div class="input-group mb-2">
                                <span class="input-group-text fs12" id="basic-addon1">Fecha Inicio</span>
                                <input readonly type="text" class="form-control fs12 inputbg"[value]="objCte.strFecha" placeholder="01/01/2024" aria-label="Username" aria-describedby="basic-addon1">
                                <span class="input-group-text"><i class="bi bi-calendar-check"></i></span>
                              </div>
                              <div class="input-group mb-2">
                                <span class="input-group-text fs12" id="basic-addon1">Vendedor Solicitante</span>
                                <input readonly  type="text" class="form-control fs12 inputbg" [value]="objCte.strNombreMostrar" placeholder="Irma Frausto" aria-label="Username" aria-describedby="basic-addon1">
                                <span class="input-group-text"><i class="bi bi-person-check"></i></span>
                              </div>
                            </div>
                              <div class="col-12 col-md-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Moneda</span>
                                    <input  type="text" class="form-control fs12 inputbg" [value]="objCte.strMoneda" placeholder="pesos" aria-label="Username" aria-describedby="basic-addon1" readonly>
                                    <span class="input-group-text"><i class="bi bi-coin"></i></span>
                                  </div>
                                  <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Familia</span>
                                    <input readonly  type="text" class="form-control fs12 inputbg" placeholder="" [value]="objCte.strFamilia" aria-label="Username" aria-describedby="basic-addon1">
                                    <span class="input-group-text"><i class="bi bi-people"></i></span>
                                  </div>
                                  <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Giro</span>
                                    <input type="text"  class="form-control fs12 inputbg" [value]="objCte.strGiro" placeholder="Manufactura" aria-label="Username" aria-describedby="basic-addon1" readonly>
                                    <span class="input-group-text"><i class="bi bi-bank"></i></span>
                                  </div>
                            </div>
                            <div class="col-12 col-md-6" *ngIf="boolShowExportar">
                                <div class="alert alert-danger" role="alert">
                                ¡Atención! la plantilla cuenta con precios inferiores al mínimo
                                  </div>
                            </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <textarea name="" class="form-control inputbg" id="" [(ngModel)]="objCte.strObservaciones" rows="3"></textarea>
                        </div>
                    </div>
                 <!-- <div class="row mt-auto">
                        <div class="col-12 d-flex">
                            <button class="btn btn-success d-flex align-items-center ms-auto" (click)="fnAddArt()" *ngIf="!boolAddArt">
                                <i class="bi bi-plus-lg me-2"></i>
                                <span class="fs12 fw-bold">Agregar Articulo</span>
                            </button>
                            <button class="btn btn-warning d-flex align-items-center  ms-auto" (click)="fnAddArt()" *ngIf="boolAddArt">
                                <i class="bi bi-arrow-left me-2"></i> 
                                <span class="fs12 fw-bold">Regresar</span>
                            </button>
                        </div>
                    </div>-->
                </div>

                <div class="col-12 col-lg-2 shadow-sm borderbott bordertop py-2 bg-light">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="mb-0">ESTATUS DE PRECIOS</h3>
                            <hr class="mt-1">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 align-self-start">
                            <span class="text-nowrap"><span class="bg-success rounded-circle text-white py-1 px-2">1</span> Mayor a precio 2</span>
                             <hr class="mt-1">
                        </div>
                        <div class="col-12 align-self-center">
                            <span class="text-nowrap"><span class="clsPrecio3r rounded-circle text-white py-1 px-2">2</span> Mayor a precio 3</span>
                             <hr class="mt-1">
                        </div>
                        <div class="col-12 align-self-end">
                            <span class="text-nowrap"><span class="bg-danger rounded-circle text-white py-1 px-2">3</span> Menor a precio 3</span>
                            <hr class="mt-1">
                        </div>
                        <div class="col-12 align-self-end">
                            <span class="text-nowrap"><span class="bg-danger rounded-circle text-white py-1 px-2 clsPrecio4 clsPrecio4_bg">4</span> Menor a precio 4</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-12 text-center"  *ngIf="objCte.strEstatus == 'RECHAZADO' || objCte.strEstatus == 'POR AUTORIZAR'"> <!---->
                            <h4>GUARDAR TODO</h4>
                        </div>
                      
                        <div class="col-12 text-center" *ngIf="(objCte.strEstatus == 'RECHAZADO' || objCte.strEstatus == 'POR AUTORIZAR') && !boolShowExportar"> <!-- -->
                            <button class="btn bg-white shadow-sm rounded-pill" (click)=" fnUpatePreciosPlantilla(objCte.intIDMovimientoVenta)">
                              <i class="bi bi-arrow-repeat  fs-5 align-middle clsIcon"  style="font-weight: 800;"></i> Actualizar 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-3   justify-content-center" *ngIf="boolAddArt">   
            <div class="col-10 shadow-sm borderbott bordertop bg-light">
                <div class="col-12">
                    <h3 class="mb-2">Agregar articulos</h3>
                </div>
                <div class="col-12 d-flex mx-auto my-2">
                    <div class="col-lg-3">
                        <div class="input-group">
                            <input type="text" [(ngModel)]="strArticulo" class="form-control fs11" placeholder="ingrese la clave" aria-label="Recipient's username" aria-describedby="button-addon2">
                            <button (click)="fnGetArticulos_2()" class="btn btn-secondary fs12 d-flex align-items-center" type="button" >
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mx-auto">
                    <div class="table-responsive mb-2 my3auto">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="fsLett sticky-top">
                                    <th>Articulo</th>
                                    <th>Descripcion</th>
                                    <th>PrecioLista</th>
                                    <th>Opcion</th>
                                    <th>PrecioSugerido</th>
                                    <th>Agregar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let art of arrayArticulos;let ind = index">
                                    <td>
                                        <div class="d-flex flex-column position-relative">
                                            <img [src]="art.imageUrl" class="imgSize mx-auto" alt="">
                                            <span class="fs10 borderbott text-center fw-bold col-12 mx-auto position-absolute bottom-0 start-50 translate-middle-x bgart">
                                                {{art.strArticulo}}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="fs10">{{art.strDescripcion1}}</td>
                                    <td class="fs12 text-end">{{art.dblPrecioLista|currency}}</td>
                                    <td class="fs12 text-center">{{art.strOpcion}}</td>
                                    <td class="fs12">
                                        <input type="number" [(ngModel)]="art.dblPrecioSugerido" class="sinFlechas fs11 form-control">
                                    </td>
                                    <td class="text-center"> 
                                        <button class="btn btn-primary fs12" (click)="fnagregarArticulo()">
                                            Agregar
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
            <div class="col-12 bg-white" *ngIf="spinerMdl">
                <mat-spinner class="mx-auto"></mat-spinner>
            </div>
            <div class="row my-3 justify-content-center" *ngIf="!spinerMdl">
                <div class="col12  ">
                    <div class="table-responsive bordertop borderbott shadow-sm px-0">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="fsthead sticky-top" >
                                    <th class="sticky-col">Clave</th>
                                    <th>Vol.</th>
                                    <th>Ultimo Precio</th>
                                    <th>Precio Lista</th>
                                    <th>Precio 2</th>
                                    <th>Precio 3</th>
                                    <th>Precio 4</th>
                                    <th *ngIf="boolBtnAutorizar">Precio Minimo</th>
                                    <th *ngIf="boolBtnAutorizar">Costo Promedio</th>
                                    <th *ngIf="boolBtnAutorizar">Margen</th>
                                    <th *ngIf="boolBtnAutorizar">Costo Teorico</th>
                                    <th *ngIf="boolBtnAutorizar">Margen CT</th>
                                    <th>Precio Politica</th>
                                    <th>Precio Plantilla</th>
                                    <th class="text-nowrap">%  </th>
                                    <th class="text-nowrap">Precio Sugerido</th>
                                    <th class="text-nowrap">% Inc.</th>
                                    <th *ngIf="arrayDetPlantillas.length>1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of  arrayDetPlantillas" >
                                    <td class="fw-bold fs12 text-nowrap sticky-col bg-light  "><div class="fw-normal fs10" [innerHTML]="dividirTextoEnDosFilas(x.strDescripcion1,x.strOpcion,x.strArticulo)"></div></td>
                                    <td class="fw-bold fs12">{{x.dblCantidad}}</td>
                                    <td class="fw-bold fs12 text-primary pointer"data-bs-dismiss="modal" data-bs-toggle="modal" (click)="onSecondModalClose(x)"> {{x.dblUltimoPrecio|currency}}</td>
                                    <td class="fw-bold fs12" [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2 ,'text-danger':x.dblPrecioSugerido<x.dblPrecio3 &&x.dblPrecioSugerido>=x.dblPrecio4 ,'clsPrecio4':x.dblPrecioSugerido<x.dblPrecio4 }">{{x.dblPrecioLista|currency}}</td>
                                    <td class="fw-bold fs12" [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3 &&x.dblPrecioSugerido>=x.dblPrecio4 ,'clsPrecio4':x.dblPrecioSugerido<x.dblPrecio4 }">{{x.dblPrecio2|currency}}</td>
                                    <td class="fw-bold fs12" [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3 &&x.dblPrecioSugerido>=x.dblPrecio4 ,'clsPrecio4':x.dblPrecioSugerido<x.dblPrecio4 }">{{x.dblPrecio3|currency}}</td>
                                    <td class="fw-bold fs12" [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3 &&x.dblPrecioSugerido>=x.dblPrecio4 ,'clsPrecio4':x.dblPrecioSugerido<x.dblPrecio4 }">{{x.dblPrecio4|currency}}</td>
                                    <td *ngIf="boolBtnAutorizar" class="fw-bold fs12 bgPrecioMinimo" [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3 &&x.dblPrecioSugerido>=x.dblPrecio4 ,'clsPrecio4':x.dblPrecioSugerido<x.dblPrecio4 } ">{{x.dblPrecioMinimo|currency}}</td>
                                    <td *ngIf="boolBtnAutorizar" class="fw-bold fs12">{{x.dblCostoPromedio|currency}}</td>
                                    <td *ngIf="boolBtnAutorizar" class="fw-bold fs12 bgPrecioMinimo">  {{(x.dblPrecioSugerido> 0 ?((1-(x.dblCostoPromedio/(x.dblPrecioSugerido*(objCte.strMoneda.toUpperCase()=="DOLARES"?x.dblTipoCambio:1))))* 100):0)| number:'1.2-2'}}%</td>
                                    <td *ngIf="boolBtnAutorizar" class="fw-bold fs12">{{x.dblCostoTeorico|currency}}</td>
                                    <td *ngIf="boolBtnAutorizar" class="fw-bold fs12">{{(x.dblPrecioSugerido> 0 ? ((1-(x.dblCostoTeorico/x.dblPrecioSugerido))*100):0) | number:'1.2-2'}}%</td>
                                    <td class="fw-bold fs12" [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3  &&x.dblPrecioSugerido>=x.dblPrecio4 ,'clsPrecio4':x.dblPrecioSugerido<x.dblPrecio4 }">{{x.dblPrecioPolitica|currency}}</td>
                                    <td class="fw-bold fs12" [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3  &&x.dblPrecioSugerido>=x.dblPrecio4 ,'clsPrecio4':x.dblPrecioSugerido<x.dblPrecio4 }">{{x.dblPrecioPlantilla|currency}}</td>
                                        <td class="">  
                                            <input  type="number" class="form-control fs12" [(ngModel)]="x.dblPorcentaje"  (input)="fnCalculaPorcentaje(x)"  min="1">
                                        </td>
                                    <td class=""> <!--[disabled]="objCte.strEstatus == 'AUTORIZADO'"-->
                                        <input  type="number" class="form-control fs12" [(ngModel)]="x.dblPrecioSugerido "  [ngClass]="{'text-success':x.dblPrecioSugerido> x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 && x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}" (input)="fnValida_preciominimo(x)">
                                    </td>
                                    <td class="fw-bold fs12">{{(x.dblUltimoPrecio>0?((x.dblPrecioSugerido /x.dblUltimoPrecio)-1)*100:0) |number:'1.2-2'}}%</td>
                                    <td *ngIf="arrayDetPlantillas.length>1" ><i class="bi bi-trash3 text-danger fs-5" (click)="fnEliminarPartida(objCte.intIDMovimientoVenta,x)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer p-2 bgModal">
            <div class="row w-100">
                <div class="col-4">
                    <button type="button" class="btn btn-secondary shadow-sm" data-bs-dismiss="modal" #btncerrar>Cerrar</button>
                </div>
                <div class="col-8 d-flex justify-content-evenly" *ngIf="strTipo_plantilla=='CLIENTE'">
                    <button type="button" class="btn btn-success shadow-sm d-flex" *ngIf="objCte.strEstatus == 'RECHAZADO'" (click)="fnReenviarSolicitud(objCte.intIDMovimientoVenta)" ><i class="bi bi-send d-lg-none"></i> <span class="d-none d-lg-block">Enviar</span> Solicitud</button>
                    <div *ngIf="boolMostrarMsj_Aut">
                        <span class="badge text-bg-info">Por Favor Espere Mientras se Procesa el Movimiento...</span>
                    </div>
                    <!--objCte.strEstatus == 'POR AUTORIZAR' &&-->
                     <button type="button" class="btn btn-success shadow-sm d-flex" *ngIf=" boolBtnAutorizar && !boolMostrarMsj_Aut && !boolShowExportar" (click)="fnSetEstatusPlantilla('AUTORIZADO',objCte.intIDMovimientoVenta,objCte.strObservaciones,objCte.strCliente)"><i class="bi bi-upload d-lg-none"></i> <span class="d-none d-lg-block">Exportar a </span> Intelisis</button>
                     <button type="button" class="btn btn-danger shadow-sm d-flex" *ngIf="objCte.strEstatus == 'POR AUTORIZAR' && boolBtnRechazar && !boolMostrarMsj_Aut" data-bs-dismiss="modal" data-bs-toggle="modal" (click)="onModal3Close()" ><i class="bi bi-x-circle d-lg-none"></i> <span  class="d-none d-lg-block">Rechazar</span> Plantilla</button><!-- (click)="fnSetEstatusPlantilla('RECHAZADO',objCte.intIDMovimientoVenta,objCte.strObservaciones,objCte.strCliente)"  -->
                </div>
                <div class="col-8 d-flex justify-content-evenly" *ngIf="strTipo_plantilla=='LEADS' || strTipo_plantilla=='OPORTUNIDAD'">
 
                    <button type="button" class="btn btn-success shadow-sm d-flex" *ngIf="objCte.strEstatus == 'POR AUTORIZAR'" (click)="fnSetEstatusCotizacion('AUTORIZADO',objCte.intIDMovimientoVenta,objCte.strObservaciones,objCte.strCliente)"><i class="bi bi-upload d-lg-none"></i> <span class="d-none d-lg-block">Autorizar  Cotización</span></button>
                    <button type="button" class="btn btn-danger shadow-sm d-flex" *ngIf="objCte.strEstatus == 'POR AUTORIZAR'"  (click)="fnSetEstatusCotizacion('RECHAZADO',objCte.intIDMovimientoVenta,objCte.strObservaciones,objCte.strCliente)" ><i class="bi bi-x-circle d-lg-none"></i> <span  class="d-none d-lg-block">Rechazar  Cotización</span></button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>


  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" (hidden.bs.modal)="openFirsModal()">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header py-2">
          <h2 class="modal-title mx-auto mb-0" id="staticBackdropLabel">Antecedentes de Precios <span class="fw-bold">{{tituloart}}</span></h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="row" *ngIf=" spinerHistPrecios && arrayHistPrecios.length==0">
                    <div class="col-12 d-flex justify-content-center">
                        <mat-spinner></mat-spinner>
                    </div>
                </div>
                
                <div class="row" *ngIf="!spinerHistPrecios && arrayHistPrecios.length==0">
                    <div class="col-12">
                        <div class="alert alert-danger mb-0 text-center fw-bold" role="alert">
                            No se cuenta con historico de precios
                          </div> 
                    </div>
                </div>
                <div class="row"  *ngIf="!spinerHistPrecios && arrayHistPrecios.length>0">
                    <div class="col-12">
                        <div class="table-responsive bordertop borderbott shadow mb-0">
                            <table class="table">
                                <thead>
                                    <tr >
                                        <th class="bgtable text-center">EJERCICIO</th>
                                        <th class="bgtable text-center">PERIODO</th>
                                        <th class="bgtable text-center">PRECIO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let prec of arrayHistPrecios">
                                        <td class="text-center">{{prec.strEjercicio}}</td>
                                        <td class="text-center">{{prec.strPeriodo}}</td>
                                        <td class="text-end "><span class="me-3">{{prec.dblPrecio|currency}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary d-flex align-items-center fs13"  data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-arrow-left-circle fs-6 pe-2"></i> PLANTILLA</button>
        </div>
      </div>
    </div>
  </div>



  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header py-2">
          <h2 class="modal-title mx-auto mb-0" id="staticBackdropLabel">MOTIVO DE RECHAZO<span class="fw-bold">{{tituloart}}</span></h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close" (click)="openFirsModal()"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label for="" class="fw-bold">
                        Ingrese el motivo
                    </label>
                </div>
                <div class="col-12">
                    <textarea name="" [(ngModel)]="comentarioRechazo" id="" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary d-none fs13 " data-bs-dismiss="modal" aria-label="Close" #btnCerrarRechazo> CERRAR</button>
            <button type="button" class="btn btn-outline-primary d-flex align-items-center fs13" data-bs-dismiss="modal" aria-label="Close" (click)="openFirsModal()"><i class="bi bi-arrow-left-circle fs-6 pe-2"></i> PLANTILLA</button>
            <button type="button" class="btn btn-danger shadow-sm d-flex" [disabled]="comentarioRechazo==''" (click)="fnRechazarPlantilla()" ><i class="bi bi-x-circle d-lg-none"></i> <span  class="d-none d-lg-block">Rechazar</span> Plantilla</button>
        </div>
      </div>
    </div>
  </div>

