<div class="container">
  <div class="row mb-3">
    <div class="col-12">
      <h1 class="text-center my-3">Pedidos <span class="TituloC">Ventas</span></h1>
    </div>
  </div>
  <div class="row ">
    <div class="row bg-white bordertop borderbott shadow-sm pb-4">
      <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
        <span class="material-symbols-outlined text-dark">
          content_paste_search
        </span>
        <span class="fw-bold text-dark">
          Filtros
        </span>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
        <mat-form-field appearance="outline" class="bg-white rounded w-100">
          <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
          <mat-select [(ngModel)]="strGerente" class="" (selectionChange)="onGerenteChange('Ger')" >
            <mat-option value=""> --Todos --</mat-option>
            <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
        [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
        <mat-form-field appearance="outline" class="bg-white rounded w-100">
          <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
          <mat-select [(ngModel)]="strSucursal" class="" (selectionChange)="onGerenteChange('Suc')">
            <mat-option value=""> --Todas --</mat-option>
            <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente "
              [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
        [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
        <mat-form-field appearance="outline" class="bg-white rounded w-100">
          <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
          <mat-select [(ngModel)]="strAgente" class="">
            <mat-option value=""> --Todos --</mat-option>
            <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
              [value]="x.strAgente">{{x.strNombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
        [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
        <mat-form-field appearance="outline" class="bg-white rounded w-100">
          <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
          <mat-select [(ngModel)]="intPeriodo" class="">
            <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"
        [ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }">
        <mat-form-field appearance="outline" class="bg-white rounded w-100">
          <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
          <mat-select [(ngModel)]="intEjercicio">
            <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
              {{e.ejercicio}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
        [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente}">
        <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true"
          (mouseleave)="isHoveredButton = false" (click)="fnMostrarPedidos()">
          <i class="bi  p-1 fs-4"
            [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i><!-- (click)="fnGetPlantillas()" -->
        </button>
      </div>
    </div>
    <!--<div class="card px-0">
      <div class="card-header d-flex justify-content-start align-items-center"
        style="background-color: #EDF6F9; color: #83C5BE;">
        <span class="material-symbols-outlined">
          filter_alt
        </span> &nbsp; &nbsp;
        <span class="material-symbols-outlined">
          content_paste_search
        </span>
      </div>
      <div class="card-body my-3 px-2 row d-flex">
        <div class="col-12 col-sm-6 col-lg-3 d-flex justify-content-center " *ngIf="boolGerente">
          <mat-form-field>
            <mat-label class="fw-bold"><mat-icon>perm_contact_calendar</mat-icon> Gerente</mat-label>
            <mat-select [(ngModel)]="strGerente" (selectionChange)="fnSelect('Sucursal');">
              <mat-option *ngFor="let ger of arrayGerentes"
                [value]="ger.strGerente">{{ger |json}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 my-4  d-flex justify-content-center"
          [ngClass]="{'mt-sm-0':arrayFiltros.boolGerente,'my-lg-0':!arrayFiltros.boolGerente}" *ngIf="boolSucursal">
          <mat-form-field>
            <mat-label class="fw-bold"><mat-icon> location_on</mat-icon> Sucursal</mat-label>
            <mat-select [(ngModel)]="strSucursal" (selectionChange)="fnSelect('Agente');">
              <mat-option *ngFor="let suc of arraySucursales"
                [value]="suc.strSucursal">{{suc.strNombreSucursal}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 my-sm-4 my-lg-0 d-flex justify-content-center" *ngIf="boolAgente">
          <mat-form-field>
            <mat-label class="fw-bold"><mat-icon>how_to_reg</mat-icon>Agente</mat-label>
            <mat-select [(ngModel)]="strAgente" (selectionChange)="fnMostrarPedidos();">
              <mat-option *ngFor="let agent of arrayAgentes"
                [value]="agent.strAgente">{{agent.strNombreProveedor}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 my-4 my-lg-0 d-flex justify-content-center">
          <mat-form-field>
            <mat-label class="fw-bold"><mat-icon>date_range</mat-icon>Periodo</mat-label>
            <mat-select [(ngModel)]="intPeriodo" (selectionChange)="fnMostrarPedidos();">
              <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 mt-sm-4 mt-lg-0 d-flex justify-content-center">
          <mat-form-field>
            <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
            <mat-select [(ngModel)]="intEjercicio" (selectionChange)="fnMostrarPedidos();">
              <mat-option *ngFor="let e of arrayEjercicio" [value]="e">{{e}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div> -->
 
  </div>
  <div class="row pb-5">
    <div class="col-12 d-flex justify-content-center bg-white" *ngIf="spinerTab == 2">
      <img class="w-50 vh60 " src="/assets/load.gif" alt="">
    </div>
    <div class="col-12" [hidden]="spinerTab === 2 || spinerTab === 0 ">
      <!--        <div class="row my-2" >
            <div class="card px-0" > -->
      <!--  <div class="card-header d-flex" style="background-color:       #EDF6F9;
              color: #83C5BE;">
                <span class="material-symbols-outlined">
                  filter_alt
                  </span> &nbsp; &nbsp;
                  <span class="material-symbols-outlined">
                    source_notes
                    </span>
              </div>  -->
      <!--  <div class="card-body px-2 row"> -->
      <!-- <div class="col-6 col-sm-6 col-lg-3  pt-2 d-flex justify-content-start">
                <mat-form-field class="col-12">
                  <mat-label class="fw-bold"><mat-icon class="fw-bold">search</mat-icon> Buscar</mat-label>
                  <input matInput (keyup)="applyFilter($event)" placeholder="Cliente, Nombre, Moviento, Emision, Importe" #input>
                </mat-form-field>
              </div> -->
      <!--  <div class="col-6 col-sm-6 col-lg-3 d-flex justify-content-end">
                <mat-form-field>
                    <mat-label class="fw-bold"><mat-icon>swap_vert</mat-icon>Estatus</mat-label>
                    <mat-select (valueChange)="applyFilter($event)" [(ngModel)]="strStatus" style="font-size: 12px;">
                      <mat-select-trigger>
                        <span class="d-flex align-items-center justify-content-center text-muted" *ngIf="selectedValue==0">
                          <mat-icon class=" fs-5">all_inclusive</mat-icon> TODOS
                        </span>
                        <span class="d-flex align-items-center justify-content-center" *ngIf="selectedValue==1">
                          <span class="material-symbols-outlined iconSucces">check</span> <span class="text-muted">CONCLUIDO</span> 
                        </span>
                        <span class="d-flex align-items-center justify-content-center" *ngIf="selectedValue==2">
                          <span class="material-symbols-outlined text-warning">bolt</span> <span class="text-muted">PENDIENTE</span>
                        </span>
                        <span class="d-flex align-items-center justify-content-center" *ngIf="selectedValue==3">
                          <span  class="material-symbols-outlined text-muted">bolt</span> <span class="text-muted">SIN AFECTAR</span>
                        </span>
                        <span class="d-flex align-items-center justify-content-center" *ngIf="selectedValue==4">
                          <mat-icon  class="text-danger fs-5">cancel</mat-icon> <span class="text-muted">CANCELADO</span>
                        </span>
                      </mat-select-trigger>
                        <mat-option (click)="setSelectedValue(0)" [value]="0" > <mat-icon class="text-muted ">all_inclusive</mat-icon>TODOS</mat-option> 
                        <mat-option (click)="setSelectedValue(1)" [value]="1" ><div class="d-flex align-items-center"><span class="material-symbols-outlined iconSucces ">check</span>&nbsp; &nbsp; &nbsp; CONCLUIDO</div></mat-option> 
                        <mat-option (click)="setSelectedValue(2)" [value]="2" ><div class="d-flex align-items-center"><span class="material-symbols-outlined text-warning ">bolt</span>&nbsp; &nbsp; &nbsp;PENDIENTE</div></mat-option> 
                        <mat-option (click)="setSelectedValue(3)" [value]="3" ><div class="d-flex align-items-center"><span  class="material-symbols-outlined text-muted ">bolt</span>&nbsp; &nbsp; &nbsp;SIN AFECTAR</div></mat-option> 
                        <mat-option (click)="setSelectedValue(4)" [value]="4"><mat-icon  class="text-danger">cancel</mat-icon>CANCELADO</mat-option>                
                    </mat-select>
                  </mat-form-field>
              </div> 
            </div> -->
      <!-- </div>
        </div> -->

      <div class="table-responsive mb-0 mt-5">
        <div class="row w-100 mx-auto d-flex justify-content-around" style="background-color: #EDF6F9; color: #83C5BE;">
          <div class="col-6  my-3 col-sm-6 col-lg-3  pt-2 d-flex justify-content-center">
            <mat-form-field class="col-10">
              <mat-label class="fw-bold "><mat-icon class="fw-bold">search</mat-icon> Buscar</mat-label>
              <input class="" matInput (keyup)="applyFilter($event)"
                placeholder="Cliente, Nombre, Moviento, Emision, Importe" #input>
            </mat-form-field>
          </div>
          <div class="col-6  my-3 col-sm-6 col-lg-3 d-flex justify-content-center ">
            <mat-form-field class="col-10">
              <mat-label class="fw-bold "><mat-icon>swap_vert</mat-icon>Estatus</mat-label>
              <mat-select class="" (valueChange)="applyFilter($event)" [(ngModel)]="strStatus" style="font-size: 12px;">
                <mat-select-trigger>
                  <span class="d-flex align-items-center justify-content-center text-muted" *ngIf="selectedValue==0">
                    <mat-icon class=" fs-5">all_inclusive</mat-icon><span>TODOS</span>
                  </span>
                  <span class="d-flex align-items-center justify-content-center" *ngIf="selectedValue==1">
                    <span class="material-symbols-outlined iconSucces">check</span><span>CONCLUIDO</span>
                  </span>
                  <span class="d-flex align-items-center justify-content-center" *ngIf="selectedValue==2">
                    <span class="material-symbols-outlined text-warning">bolt</span><span>PENDIENTE</span>
                  </span>
                  <span class="d-flex align-items-center justify-content-center" *ngIf="selectedValue==3">
                    <span class="material-symbols-outlined text-muted">bolt</span><span>SIN AFECTAR</span>
                  </span>
                  <span class="d-flex align-items-center justify-content-center" *ngIf="selectedValue==4">
                    <mat-icon class="text-danger fs-5">cancel</mat-icon> <span>CANCELADO</span>
                  </span>
                </mat-select-trigger>
                <mat-option (click)="setSelectedValue(0)" [value]="0"> <mat-icon
                    class="text-muted ">all_inclusive</mat-icon>TODOS</mat-option>
                <mat-option (click)="setSelectedValue(1)" [value]="1">
                  <div class="d-flex align-items-center"><span
                      class="material-symbols-outlined iconSucces ">check</span><!--  <mat-icon  class="text-success">local_mall</mat-icon> -->&nbsp;
                    &nbsp; &nbsp; CONCLUIDO</div>
                </mat-option>
                <mat-option (click)="setSelectedValue(2)" [value]="2">
                  <div class="d-flex align-items-center"><span
                      class="material-symbols-outlined text-warning ">bolt</span>&nbsp; &nbsp; &nbsp;PENDIENTE</div>
                  <!-- <mat-icon class="text-warning">local_mall</mat-icon> -->
                </mat-option>
                <mat-option (click)="setSelectedValue(3)" [value]="3">
                  <div class="d-flex align-items-center">
                    <!-- <mat-icon  class="text-danger">local_mall</mat-icon> --><span
                      class="material-symbols-outlined text-muted ">bolt</span>&nbsp; &nbsp; &nbsp;SIN AFECTAR</div>
                </mat-option>
                <mat-option (click)="setSelectedValue(4)" [value]="4"><mat-icon
                    class="text-danger">cancel</mat-icon>CANCELADO</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!------------INICIA TABLA--------------------->
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
          <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">

            <th mat-header-cell *matHeaderCellDef class="text-center textHeader"> {{column}} </th>
            <td mat-cell *matCellDef="let element" class="textCss" [ngClass]="{'text-end pe-2':column=='Importe','text-center':column!='Importe' && column!='Movimiento' ,'txtImporte':column=='Cliente' || column=='Importe','text-start ps-2':column=='Movimiento'}" >
              <ng-container *ngIf="column === 'Importe'; else checkDate">
                {{ element[column] | currency }}
              </ng-container>
              <ng-template #checkDate>
                <ng-container *ngIf="column === 'FechaRequerida' || column === 'FechaEmision'; else otherColumn">
                  <span> {{ element[column] }} </span>
                </ng-container >
                <ng-template #otherColumn>
                  <ng-container *ngIf="column === 'Movimiento'; else checkSituation">
                    <span *ngIf="element['Estatus']=='PENDIENTE'" class="material-symbols-outlined text-warning">bolt</span> 
                    <span *ngIf="element['Estatus']=='SINAFECTAR'" class="material-symbols-outlined text-muted">bolt</span> 
                    <span *ngIf="element['Estatus']=='CONCLUIDO'" class="material-symbols-outlined iconSucces">check</span>
                    <mat-icon *ngIf="element['Estatus']=='CANCELADO'" class="text-danger">cancel</mat-icon>
                    &nbsp;{{ element[column]}} 
                  </ng-container>
                  <ng-template #checkSituation>
                    <ng-container *ngIf="column === 'Situacion'; else checkChangeSituation">
                      
                      <span *ngIf="element['Situacion']=='Rechazado'" class="text-danger fw-bold">{{ element[column] }}</span> 
                    <span *ngIf="element['Situacion']=='Por Autorizar'" class="text-info fw-bold">{{ element[column] }}</span> 
                    </ng-container>
                    <ng-template #checkChangeSituation>
                      <ng-container *ngIf="(column == 'cambiarSituacion' && element['Estatus']=='SINAFECTAR'); else dirty">
                        <button (click)="toggleExpand(element['intID'], $event)" class="btn btn-primary small-button">POR AUTORIZAR</button>
                      </ng-container>
                    </ng-template>
                  </ng-template>
                  <ng-template #dirty>
                    <span> {{ element[column] }}</span>
                  </ng-template>
                </ng-template>
              </ng-template>  
            </td>
            

          </ng-container>
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef class="text-center" aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element" class="text-center p-0 backTable">
              <button mat-icon-button
                aria-label="expand row"><!--(click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()"-->
                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell class="px-0 " *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
              <div class="example-element-detail border-top "
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="col-12 d-flex justify-content-center" *ngIf="spinerSubTab == 2">
                  <img class="w-50 vh60" src="/assets/load.gif" alt="">
                </div>
                <div class="col-12 my-3 " [hidden]="spinerSubTab === 2 || spinerSubTab === 0 ">
                  <div class="tableH100 mx-3">
                    <table class="table table-bordered  w-100 ">
                      <thead class="sticky-top " style="background: #E29578;">
                        <tr class="thcenter">
                          <th>Artículo</th>
                          <th>Descripción</th>
                          <th>Opción</th>
                          <th>Cantidad</th>
                          <th>Precio</th>
                          <th>Cantidad Dependiente</th>
                          <th *ngIf="element.Estatus=='SINAFECTAR' ">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let x of arrayDetalle" style="font-size: 12px;">
                          <td class="text-center">{{x.strArticulo}}</td>
                          <td class="text-center">{{x.strDescripcion}}</td>
                          <td class="text-center"><b>{{x.strNombreOpcion}}</b><br> {{x.strSubCuenta}}</td>
                          <td class="text-center">{{x.intCantidad}}</td>
                          <td class="text-center">{{x.dblPrecio| currency }}</td>
                          <td class="text-center">{{x.intCantidadPendiente}}</td>
                          <td *ngIf="element.Estatus=='SINAFECTAR'   "
                            [ngClass]="{'justify-content-center':element.Estatus=='PENDIENTE','justify-content-between':element.Estatus=='SINAFECTAR'}"
                            class="d-flex">
                            <button *ngIf="element.Estatus=='SINAFECTAR'" data-bs-toggle="modal"
                              data-bs-target="#exampleModal" (click)="fnActualizarDetallePedido(x,element.Movimiento);"
                              class="btn p-0 "><mat-icon class="text-warning">edit</mat-icon></button>
                            <button *ngIf="element.Estatus=='SINAFECTAR'"
                              (click)="fneliminarDetallePedido(x,element.Movimiento);" class="btn p-0"><mat-icon
                                class="text-danger">delete_outline</mat-icon></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="row mx-3"
                    *ngIf="element.Estatus=='PENDIENTE' || (element.Estatus=='SINAFECTAR' && element.Movimiento!='') ">
                    <div class="col-12  bg-light d-flex justify-content-center">
                      <button (click)="fnCancelarPedido(element);"
                        class="btn btn-danger my-2 d-flex justify-content-center align-items-center"
                        style="font-size: 11px;">
                        <span class="material-symbols-outlined">
                          cancel
                        </span>&nbsp;CANCELAR
                      </button>
                    </div>
                  </div>
                  <div class="row bg-light" *ngIf="element.Estatus=='SINAFECTAR' && element.Movimiento==''">
                    <div class="col-12 d-flex justify-content-center">
                      <button (click)="fnEliminarPedido(element);"
                        class="btn btn-danger my-2 d-flex justify-content-center align-items-center"
                        style="font-size: 11px;">
                        <span class="material-symbols-outlined">
                          cancel
                        </span>&nbsp;Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand" class="tableHeade"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" (click)="fnDetallePedido(element);"
            class="example-element-row" [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
      </div>
      <mat-paginator class="bg-light" [pageSizeOptions]="[40,70,100,200]" aria-label="Select page of users">
      </mat-paginator>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="exampleModalLabel">Actualizar Pedido</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row ">
            <div class="col-12 d-sm-flex">
              <div class="col-12 col-sm-3">
                <label class="text-muted fw-bold" for="">Articulo:</label>
              </div>
              <div class="col-12 col-sm-9">
                <span> {{objModal.strArticulo}}</span>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12 d-sm-flex">
              <div class="col-12 col-sm-3">
                <label class="text-muted fw-bold" for="">Descripción:</label>
              </div>
              <div class="col-12 col-sm-9">
                <span> {{objModal.strDescripcion}}</span>
              </div>
            </div>
          </div>
          <div class="row my-3" *ngIf="objModal.strSubCuenta && objModal.strSubCuenta!=''">
            <div class="col-12 d-sm-flex">
              <div class="col-12 col-sm-3">
                <label class="text-muted fw-bold" for="">Opción:</label>
              </div>
              <div class="col-12 col-sm-9">
                <select class="form-select" aria-label="Default select example" [(ngModel)]="objModal.strSubCuenta">
                  <option *ngFor="let opcion of arraySubcuenta" [value]="opcion.strSubCuenta">{{opcion.strNombreOpcion}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-12 d-sm-flex align-items-sm-center">
              <div class="col-12 col-sm-3">
                <label class="text-muted fw-bold" for="">Cantidad:</label>
              </div>
              <div class="col-12 col-sm-9">
                <input [(ngModel)]="objModal.intCantidad" type="text" class="form-control"
                  [value]="objModal.intCantidad">
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" #modalCls class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="fnGuardarActualizados(objModal)">Guardar
            Cambios</button>
        </div>
      </div>
    </div>
  </div>
</div>