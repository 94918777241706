<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold  text-decoration-underline">Reporte <span class="titulo">Comodatos Clientes</span></h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class=""  (selectionChange)="onGerenteChange('Ger')"  >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class=""  (selectionChange)="onGerenteChange('Suc')">
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="getDatosTabla()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>

    <div class="col-12 my-4">
        <div [hidden]="load">
            <div class="col-12 py-2 ps-3 bordertop bgtTale shadow d-flex justify-content-between align-items-center">
                <mat-form-field appearance="outline" class="bg-white rounded">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilterTareasCte($event)"
                        placeholder="ID,Tarea,etc..." #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <button class="btn btn-success me-3 fs14 p-2 bgBtns" (click)="exportexcel()">
                    <i class="bi bi-file-earmark-excel "></i> Descargar
                </button>
            </div>
        </div>
   
        
        <div [hidden]="!load">
            <div class="col-12 d-flex justify-content-center py-4 " >
              <mat-spinner [diameter]="60"  class="spinner-container"></mat-spinner> 
            </div>
        </div>
        <div class="mb-0 table-responsive shadow" [hidden]="load">
            <div class="mat-elevation-z8 mb-0 shadow rounded">
                <table mat-table [dataSource]="datosTareasCte" class="table table-hover" matSort #sortTareasCte="matSort">
                    
                    <ng-container matColumnDef="strCliente" class="yes">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top border-end border-white  border-top ">
                            Cliente
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                            <span class="fw-bold"> ({{row.strCliente}})</span>  {{row.strNombreCte}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator"> <span class="fw-bold">Total</span>  </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="strCteEnviarA">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top yes border-end border-white px-2  border-top ">
                            Sucursal
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                           <span *ngIf="row.strCteEnviarA!=0">(<span>{{row.strCteEnviarA}}</span>) {{row.strSucursal}} </span> 
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator">  </td>
                    </ng-container>

                    <ng-container matColumnDef="strAgente">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class=" fs13 sticky-top yes border-end border-white px-2  border-top ">
                            Agente
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                           <span *ngIf="row.strAgente!='' && row.strAgente">(<span class="fw-bold">{{row.strAgente}}</span>) {{row.strAgenteNombre}} </span> 
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator">  </td>
                    </ng-container>

                    <ng-container matColumnDef="strDespachador">
                        <th mat-header-cell *matHeaderCellDef class="ps-0 fs13 sticky-top" >
                            <!--Despachador mat-sort-header -->
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                            <div class="position-relative my-2" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirImagen(row,'Despachador')">
                                <img class="pointer rounded shadow my-2" style="width: 40px;" [src]="'https://www.cleanclean.mx/imagenes/'+row.strDespachador+'.JPG'" alt="">
                              <span class="text-nowrap fw-bold  position-absolute texto-despachador  top-100 start-50 translate-middle">
                                {{row.strDespachador}}
                              </span>  
                            </div> 
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator">  </td>
                    </ng-container>
                    <ng-container matColumnDef="strNombreDespachador">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top border-end border-white px-2  border-top ">
                            Despachador
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center texto-despachador fw-bold align-middle">
                          {{row.strNombreDespachador}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator">  </td>
                    </ng-container>

                    <ng-container matColumnDef="dblCantidad_Articulo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top border-end border-white" >
                            Cantidad Despachador
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 text-center align-middle">
                              <span class="fw-bold texto-despachador bgCantDes rounded p-1">
                                {{row.dblCantidad_Articulo}}
                              </span>  
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator">  </td>
                    </ng-container>



                    <ng-container matColumnDef="strArticulo">
                        <th mat-header-cell *matHeaderCellDef class="ps-0 fs13 sticky-top" >
                           <!--  Consumible  mat-sort-header -->
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                            <div class="d-flex flex-column " data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirImagen(row,'articulo')">
                                 
                                <img class="pointer rounded shadow mx-auto" style="width: 40px;" [src]="'https://www.cleanclean.mx/imagenes/'+row.strConsumible+'.JPG'" alt="">
                                <span class="text-nowrap fw-bold texto-consumible d-flex align-items-end mx-auto">
                                    <span>{{row.strConsumible}}</span> 
                                 </span> 
                            </div> 
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator">  </td>
                    </ng-container>
                    <ng-container matColumnDef="strNombreConsumible">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top border-end border-white px-2  border-top ">
                            Consumible
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fw-bold fs11 text-center texto-consumible align-middle">
                          {{row.strNombreConsumible}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator"></td>
                    </ng-container>
                   
                    <ng-container matColumnDef="strSubCuenta" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top  border-top  border-end border-white px-2">
                            Sub Cuenta
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                            <span *ngIf="row.strSubCuenta!=''" class="fw-bold"> (  {{row.strSubCuenta}})</span> {{row.strNombreOpcion}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="paginator">  </td>
                    </ng-container>

                    <ng-container matColumnDef="dblCantidadPrometida">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top border-end  border-top border-white px-2">
                            Cantidad Prometida
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                            <span class="bgDisponible rounded p-1 fw-bold fs14">{{row.dblCantidadPrometida}}</span>   
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="text-center paginator">
                            <span class="fw-bold">
                                {{objSumas.dblCantidadPrometida|number:'1.0-0'}}
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dblPrecio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top border-end  border-top border-white px-2">
                            Importe Cantidad_Prometida
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                            <span class="bgDisponible rounded p-1 fw-bold fs14">{{row.dblPrecio|currency}}</span>   
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="text-center paginator">
                            <span class="fw-bold">
                                {{objSumas.dblprecioPrometida|currency}}
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dblCantidad_Comprada">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top border-end  border-top  border-white px-2">
                            Cantidad Comprada
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                            <span class="bgCcompra rounded p-1 fw-bold fs14">{{row.dblCantidad_Comprada}}</span>
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="text-center paginator">
                            <span class="fw-bold">
                                {{objSumas.dblCantidadComprada|number:'1.0-0'}}
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dblPrecioPlantilla">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 sticky-top border-end  border-top border-white px-2">
                            Importe Cantidad_Comprada
                        </th>
                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle">
                            <span class="bgCcompra rounded p-1 fw-bold fs14">{{row.dblPrecioPlantilla|currency}}</span>   
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="text-center paginator">
                           <span class="fw-bold">{{objSumas.dblprecioComprada|currency }}</span> 
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsTareasCte"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsTareasCte;" ></tr> 
                    <tr mat-footer-row *matFooterRowDef="columnsTareasCte; sticky: true"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="11">
                            No se encontraron datos de:
                            "{{input.value}}"
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <mat-paginator [hidden]="load" class="paginator borderbott shadow" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorTareasCte></mat-paginator>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-1">
          <h2 class="modal-title mb-0 mx-auto fw-bold" id="exampleModalLabel">{{artImagen}}</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="col-12">
                    <img class="w-100" [src]="urlImagen" alt="">
                </div>
            </div>
        </div>
        
      </div>
    </div>
  </div>
<!-- 
<div class="container mt-5">
    <div class="row">
        <div class="col-12">
            <table mat-table [dataSource]="datosTareasCte" multiTemplateDataRows class="mat-elevation-z8">
      
                <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsTareasCte">

         <th mat-header-cell *matHeaderCellDef> {{column}} </th>
         <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
       </ng-container>
     

       <ng-container matColumnDef="expandedDetail">
         <td mat-cell *matCellDef="let element" [attr.colspan]="columnsTareasCte.length">
           <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
             <div class="example-element-diagram">
               <div class="example-element-position"> {{element.position}} </div>
               <div class="example-element-symbol"> {{element.symbol}} </div>
               <div class="example-element-name"> {{element.name}} </div>
               <div class="example-element-weight"> {{element.weight}} </div>
             </div>
             <div class="example-element-description">
               {{element.description}}
               <span class="example-element-description-attribution"> -- Wikipedia </span>
             </div>
           </div>
         </td>
       </ng-container>
     
       <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
       <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
           class="example-element-row"
           [class.example-expanded-row]="expandedElement === element"
           (click)="expandedElement = expandedElement === element ? null : element">
       </tr>
       <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
     </table>
        </div>
    </div>
</div> -->