import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice'; 
import Swal from 'sweetalert2';
@Component({
  selector: 'app-ecommerce-rastrear-pedido',
  templateUrl: './ecommerce-rastrear-pedido.component.html',
  styleUrls: ['./ecommerce-rastrear-pedido.component.css']
})
export class EcommerceRastrearPedidoComponent implements OnInit {
  pedidoID: number; // Para almacenar el número de pedido ingresado
  public pedidoEncontrado :any =null; // Para almacenar los detalles del pedido
  strConexion:string='COMERCIAL';
   arrayPedidos_original:any =[];
  // Datos simulados de pedidos
  arrayPedidos = [
    {
      intID_Pedido: 123,
      strFecha: '2025-01-01',
      dblImporte: 100.50,
      strEstatus_Pago: 'PENDIENTE',
      detalles: [
        { strArticulo: 'Art001', strDescripcion1: 'Descripción 1', dblCantidad: 2, dblPrecioLista: 50, strUnidad: 'Unidad1' }
      ]
    },
    {
      intID_Pedido: 456,
      strFecha: '2025-01-02',
      dblImporte: 200.75,
      strEstatus_Pago: 'FACTURADO',
      detalles: [
        { strArticulo: 'Art002', strDescripcion1: 'Descripción 2', dblCantidad: 1, dblPrecioLista: 200.75, strUnidad: 'Unidad2' }
      ]
    }
  ];
  constructor(private _httpService: PeticionesService) { }

  ngOnInit(): void {
  
  }
  buscarPedido(){
    this.pedidoEncontrado=[];
    this.arrayPedidos = [];
    let articulo = {
      strAccion: "getSeguimiento", 
      strConexion: this.strConexion,
      intID_Contacto:this.pedidoID
      //intEjercicio:this.intEjercicio,
     // intPeriodo:this.intPeriodo
    };
    this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
      result => {
       console.log(result)
       this.arrayPedidos_original = result;
        if(result){
         let uniquePedidos=[]
           result.forEach(element => {
            let objB = uniquePedidos.find(o=>o.intID_Pedido ==element.intID_Pedido)
            if(!objB){
              element.detalles = this.arrayPedidos_original.filter(o=>o.intID_Pedido ==element.intID_Pedido)
              uniquePedidos.push(element)
            }
            
          });
          this.pedidoEncontrado =uniquePedidos[0];
         console.log(this.pedidoEncontrado)
        }
        else {
          this.pedidoEncontrado=null;
        }
      
        
      
     
      },error=>{
        console.log(error.message) 
      });
  }
  buscarPedido_2() {
    this.pedidoEncontrado = this.arrayPedidos.find(pedido => pedido.intID_Pedido === this.pedidoID);
    if (!this.pedidoEncontrado) {
      alert('Pedido no encontrado');
    }
  }
  // Método para asignar las clases de Bootstrap basadas en el estatus
  getEstatusClass(estatus: string) {
    switch (estatus) {
      case 'PENDIENTE':
        return 'bg-warning'; // Amarillo
      case 'FACTURADO':
        return 'bg-success'; // Verde
      case 'TRANSITO':
        return 'bg-primary'; // Azul
      case 'ENTREGADO':
        return 'bg-info'; // Cian
      default:
        return 'bg-secondary'; // Gris si no se encuentra el estatus
    }
  }

  // Método para asignar los iconos relacionados con el estatus
  getEstatusIcon(estatus: string) {
    switch (estatus) {
      case 'PENDIENTE':
        return 'fas fa-clock'; // Icono de reloj para pendiente
      case 'FACTURADO':
        return 'fas fa-file-invoice'; // Icono de factura para facturado
      case 'TRANSITO':
        return 'fas fa-truck'; // Icono de camión para tránsito
      case 'ENTREGADO':
        return 'fas fa-check-circle'; // Icono de check para entregado
      default:
        return 'fas fa-question-circle'; // Icono de pregunta si no se encuentra el estatus
    }
  }
}
