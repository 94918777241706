<div class="container">
    <div class="row"><br>
        <div class="col-lg-12 animated fadeInDown text-center">
            <div class="navy-line m-t-n-xxs"></div>
            <h2>Detallado por <span class="text-navy">Articulos</span></h2>
        </div>
    </div>
    <div class="row animated fadeInDown">
        <div class="col-lg-12">
            <div class="ibox-content4 float-e-margins sombra1 table-responsive">
                <table class="table table-hover tooltip-demo align-middle" id='step5'>
                    <tbody>
                        <tr>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Forma de Pago</th>
                            <th class="bordeTable">({{InfoEncabezado.strFormaPagoTipo}}) {{InfoEncabezado.strFormaPago}}
                            </th>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Uso de CFDI</th>
                            <th class="bordeTable">({{InfoEncabezado.strUsoCFDI}})
                                {{InfoEncabezado.strUsoCFDIDescipcion}}</th>
                        </tr>
                        <tr>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Condición</th>
                            <th class="bordeTable">
                                <a data-toggle="modal" data-bs-target="#CondicionPago" (click)="GuardarID(strID)"
                                    *ngIf="(strusuario  =='agustin' || strusuario  == 'jreyes' || strusuario =='sduran' || strusuario =='lbeltran' || strusuario  =='angarcia' ||strusuario  =='adrianah' )">
                                    {{InfoEncabezado.strCondicion}}
                                </a>

                                <span
                                    *ngIf="(strusuario != 'agustin' && strusuario != 'jreyes' && strusuario !='sduran' && strusuario !='lbeltran') ">
                                    {{InfoEncabezado.strCondicion}}
                                </span>
                            </th>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Almacen</th>
                            <th class="bordeTable">({{InfoEncabezado.strAlmacen}}) {{InfoEncabezado.strAlmacenNombre}}
                            </th>
                        </tr>
                        <tr>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Orden de Compra</th>
                            <th class="bordeTable">{{InfoEncabezado.strOrdenCompra}}</th>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Dirección de Entrega</th>
                            <th class="bordeTable" colspan="3">{{InfoEncabezado.strDireccionEntrega}}</th>
                        </tr>
                        <tr>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Importe</th>
                            <th class="bordeTable">{{InfoEncabezado.dblImporte | currency}}</th>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Impuestos - Zona</th>
                            <th class="bordeTable" colspan="3">{{InfoEncabezado.dblImpuestos | currency}} -
                                {{InfoEncabezado.strZonaImpuestos}}</th>
                        </tr>
                        <tr class="">
                            <th style="background:#ecf5f6" class="text-center bordeTable">Adjunto Orden de Compra</th>
                            <th class="bordeTable" *ngIf="InfoEncabezado.strRutaOrdenCompra==''">
                                <div *ngIf="strtipo=='p'">
                                    <form role="form" id='formDoc'>
                                        <div class="d-flex flex-column">
                                            <span>* Orden de Compra.</span>
                                            <div class="fileinput fileinput-new input-group sombra"
                                                data-provides="fileinput">
                                                <!--  <div class="form-control input_adjuntar" data-trigger="fileinput subir">
                                                    <span class="fileinput-filename"></span>
                                                </div> -->
                                                <span
                                                    class="input-group-addon btn btn-default btn-file turclaro d-flex flex-column">
                                                    <span class="fileinput-new ">Archivo .jpg ó .PDF</span>
                                                    <span class="fileinput-exists">Cambiar</span>
                                                    <input (change)="guardarArchivo($event)" type="file"
                                                        class='validasize fileinput' name="archivo" id="adjunto1"
                                                        required>
                                                </span>
                                            </div>

                                            <div class="col-sm-6 d-flex">
                                                <button [disabled]="ordenCompra.length == 0" type="button"
                                                    (click)="subirOrdendeCompra()"
                                                    class="btn btn-primary  sombra m-auto">Subir Orden</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </th>
                            <th class="bordeTable" colspan="1" *ngIf="InfoEncabezado.strRutaOrdenCompra!=''">
                                <div class="col-lg-12 full-height-scroll">
                                    <div class="lightBoxGallery tooltip-demo">
                                        <p class="font-bold"></p>
                                        <a data-toggle="tooltip" data-placement="top"
                                            title="{{InfoEncabezado.strRutaOrdenCompra}}" target="_new"
                                            href="{{InfoEncabezado.strRutaOrdenCompra}}"
                                            download="{{InfoEncabezado.strRutaOrdenCompra}}">
                                            <i class="fa fa-download fa-2x"></i>
                                        </a> &nbsp;&nbsp;
                                    </div>
                                </div>
                            </th>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Solicitar Cambio de Condicion
                            </th>
                            <th class="bordeTable">
                                <div
                                    *ngIf="(InfoEncabezado.strAtencionTelefono=='') && (InfoEncabezado.strEstatus=='SINAFECTAR')">
                                    <div class="col-sm-6 col-md-offset-1">
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#CondicionPago2"
                                            (click)="GuardarID(strID)" class="btn btn-primary btn-xs sombra">Cambiar</button>
                                    </div>
                                </div>
                                <div *ngIf="InfoEncabezado.strAtencionTelefono!=''">
                                    {{InfoEncabezado.strAtencionTelefono}}</div>
                            </th>
                        </tr>
                        <tr *ngIf="InfoEncabezado.strSituacion!=''">
                            <th class="turclaro text-center bordeTable">Situación</th>
                            <th class="bordeTable" colspan="3">
                                {{InfoEncabezado.strSituacion}} - {{InfoEncabezado.strSituacionNota}}
                            </th>
                        </tr>
                        <tr *ngIf="(InfoEncabezado.strSituacion=='Rechazado' || InfoEncabezado.strSituacion=='') && InfoEncabezado.strEstatus=='SINAFECTAR'"
                            >
                            <th class="turclaro text-center bordeTable">Cambiar Situacion --{{InfoEncabezado.strSituacion}}--</th>
                            <th class="bordeTable" colspan="3"><!-- -->
                                <div>
                                    <div class="button1 raised blue">
                                        
                                        <a class="btn btn-primary" role="button" (click)="cambiarSituacion()">POR AUTORIZAR</a>
                                        <paper-ripple fit=""></paper-ripple>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th style="background:#ecf5f6" class="text-center bordeTable">Folio de Negocio</th>
                            <th class="bordeTable">{{InfoEncabezado.strAtencion}}</th>
                            <th style="background:#ecf5f6" class="text-center bordeTable"></th>
                            <th class="bordeTable" colspan="3"></th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br><br />
    <div class="row animated fadeInDown">
        <div class="col-lg-12">
            <div class="ibox-content4 float-e-margins sombra1 table-responsive mb-0 shadow bordertop borderbott">
                <table class="table table-hover table-bordered tooltip-demo ">
                    <thead>
                        <tr class="sticky-top turquesa" style="background:#ecf5f6">
                            <th class="text-center" *ngIf="strtipo!='p'">Mov</th>
                            <th class="text-center">MovID</th>
                            <th class="text-center">Articulo</th>
                            <th class="text-center">SubCuenta</th>
                            <th class="text-center">Descripción</th>
                            <th class="text-center">Almacén</th>
                            <th class="text-center">Precio</th>
                            <th class="text-center">Unidad</th>
                            <th class="text-center">Cantidad</th>
                            <th class="text-center">Cantidad Pendiente</th>
                            <th class="text-center">Linea</th>
                            <th class="text-center" *ngIf="strtipo!='c'">Subtotal</th>
                            <th class="text-center" *ngIf="strtipo!='c'">Subtotal Pendiente</th>
                            <th class="text-center" *ngIf="strtipo=='p'">Ver Almacen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of listaDetalle" class="text-center">
                            <td class="bordeTable LetraT" *ngIf="strtipo!='p'">{{d.strMov}}</td>
                            <td class="bordeTable">{{d.strMovID}}</td>
                            <td class="bordeTable">{{d.strArticulo}}</td>
                            <td class="bordeTable">{{d.strSubCuenta}} {{d.strNombreOpcion}}</td>
                            <td class="bordeTable LetraT">{{d.strDescripcion}}</td>
                            <td class="bordeTable">{{d.strAlmacen}}</td>
                            <td class="bordeTable">{{d.dblPrecio}}</td>
                            <td class="bordeTable">{{d.strUnidad}}</td>
                            <td class="bordeTable">{{d.dblCantidad}}</td>
                            <td class="bordeTable">{{d.dblCantidadPendiente}}</td>
                            <td class="bordeTable">{{d.strLinea}}</td>
                            <td class="bordeTable" *ngIf="strtipo!='c'">{{d.dblSubTotal | currency }}</td>
                            <td class="bordeTable" *ngIf="strtipo!='c'">{{d.dblSubTotalPendiente | currency}}</td>
                            <td   data-bs-toggle="tooltip" data-placement="top" title="Ver">
                                <!--<a>
                                    <i (click)='ArticuloDisponible(d)' class="fa fa-pencil-square-o fa-2x text-info"
                                        data-toggle="modal" data-target="#myModaDisponible" id='step4'></i>
                                </a>-->
                                <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#myModaDisponible" (click)='ArticuloDisponible(d)' style="font-size: 12px;" ><i class="fa-solid fa-boxes-stacked"></i>  </button>

                            </td>
                        </tr>

                    </tbody>
                    <tfoot>
                       
                            <tr class="gris bordeazul text-center">
                                <td>Totales</td>
                                <td colspan="10" *ngIf="strtipo!='p'">Movimientos: {{totalMov}}</td>
                                <td colspan="9" *ngIf="strtipo=='p'">Movimientos: {{totalMov}}</td>
                                <td class="text-center" *ngIf="strtipo!='c'">{{totalImporte | currency}}</td>
                                <td class="text-center" *ngIf="strtipo!='c'"> {{totalImportePendiente | currency}}</td>
                                <td colspan="1" > </td>
                            </tr>
                        
                    </tfoot>
                </table>

            </div>
        </div>
        <div class="col-lg-12 d-flex justify-content-center align-items-center" id="autorizar"
       *ngIf='(strusuario  =="agustin" || strusuario  =="lbeltran" || strusuario  =="jreyes" ) && strID !="" '> <!--|| $storage.nAcceso == 6-->
            <div class="col-sm-3">
                <button class="btn btn-info btn-xs sombra" (click)="autorizar(strID,1)"><i class="fa fa-check fa-1x">
                    </i> ACEPTAR</button>
            </div>
            <div class="col-sm-3">
                <div class="btn btn-danger btn-xs sombra " data-bs-toggle="modal" data-bs-target="#myModal"
                    (click)="VerModal(strID)">
                    <div class="center" fit="">
                        <i class="fa fa-times fa-1x"> </i> RECHAZAR
                    </div>
                    <paper-ripple fit=""></paper-ripple>
                </div>
            </div>
        </div>
    </div>
    <!--Modal Rechazo-->
    <div class="modal inmodal" id="myModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content animated bounceInRight">
                <div class="modal-header d-flex">

                    <h3 class="modal-title m-auto"><i class="fa fa-times fa-2x mx-2 text-danger"
                            style="color:#DFDCDC"></i>Rechazar Pedido</h3>
                    <button type="button" class="close" data-bs-dismiss="modal"><span
                            aria-hidden="true">&times;</span><span class="sr-only">Close</span>
                    </button>
                </div>
                <div class="modal-body">

                    <small class="font-bold">Escriba el motivo por el cual rechaza el pedido.</small>
                    <div class="form-group"><input type="text" [(ngModel)]="ngMotivoRechazo" placeholder="Escribir..."
                            class="form-control"></div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="ngMotivoRechazo == ''" type="button" class="btn btn-primary"
                        (click)="AutorizarPedido(2)" data-bs-dismiss="modal">Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="CondicionPago2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" #modalRef>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Condición de Pago</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <label>Condición</label>
                        <select class="form-select m-b" [(ngModel)]="selectedCondicion">
                            <option value="">-Selecciona una opción-</option>
                            <option *ngFor="let C of ArrayCondicion" value="{{C.strCondicion}}">{{C.strCondicion}}
                            </option>
                        </select>
                        <br>
                        <div class="mb-3">
                       
                            <div class="input-group">
                              <span class="input-group-text" id="basic-addon3">Motivo:</span>
                              <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" [(ngModel)]="strMotivoCambio">
                            </div>
                          
                          </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrar>Cerrar</button>
                <button type="button" class="btn btn-primary" (click)="ActualizacionDeCondicion()">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
            <h2 class="modal-title">E X I S T E N C I A S</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 table-responsive">
                <table class="table  ">
                    <tr class="text-center">
                        <th class="gris">Disponibles</th>
                        <th class="gris">Almacen</th>
                        <th class="gris">Nombre</th>
                    </tr>
                    <tr class="text-center" *ngFor="let AD of listaAlmacenDisponible">
                        <th>{{AD.strDisponible}}</th>
                        <th>{{AD.strAlmacen}}</th>
                        <th>{{AD.strAlmacenNombre}}</th>
                    </tr>
                </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>

   
  </div>

   <!--inicio modal-->
   <div class="modal fade" id="myModaDisponible" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Existencias</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <table class="table table-hover ">
                <thead>
                  <tr>
                    <th scope="col">Almacén</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Disponibles</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let alm of listaAlmacenDisponible">
                    <th scope="row">{{alm.strAlmacen}}</th>
                    <td>{{alm.strAlmacenNombre}}</td>
                    <td><b>{{alm.strDisponible}}</b></td>
                   
                  </tr>
                
                </tbody>
              </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
         
        </div>
      </div>
    </div>
  </div>
<!--fin modal-->