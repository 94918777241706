import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alta-sucursal-cliente',
  templateUrl: './alta-sucursal-cliente.component.html',
  styleUrls: ['./alta-sucursal-cliente.component.css']
})
export class AltaSucursalClienteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
