import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import pdfmake from 'pdfmake/build/pdfmake';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService} from 'ngx-webstorage';
import * as html2pdf from 'html2pdf.js';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfmake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})
export class CatalogoComponent implements OnInit {
  @ViewChild('pdfTableFiniquito', { static: true }) el!: ElementRef
  public strConexion:string="";
  public arrayArticulos:any = [];
  logoBase64: string;
  public arrayCategorias:any=[]
  constructor(private _httpService:PeticionesService,private localSt:LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
  }

  ngOnInit(): void {
    this.fnGetArticulos();
   /*  this.loadImage(); */
  }

  fnGetArticulos(){
    this._httpService.getLineasComodatos({
      strAccion: "getCatalogo",
      strCliente:"",
      strFamilia:"CASTORES",
      strConexion:this.strConexion
    },"sp_ModVentas_Catalogo").subscribe(
      result => {
        this.arrayArticulos = result;
        console.log(this.arrayArticulos)
        this.fnSepararCategoriaGrupo()
      });
  }

  fnSepararCategoriaGrupo(){
    let arrayCopArts = JSON.parse(JSON.stringify(this.arrayArticulos));
    arrayCopArts.forEach(item => {
    let encontrado = this.arrayCategorias.find(obj => obj.strCategoriaCatalogo === item.strCategoriaCatalogo);
      if (!encontrado) {
        this.arrayCategorias.push({strCategoriaCatalogo:item.strCategoriaCatalogo});
      }
    });
    this.fnSepararGrupos()
  }

  fnSepararGrupos(){
    this.arrayCategorias.forEach(cat => {
      cat.arrayGrupos = [];
      let arrayCat = this.arrayArticulos.filter(art => art.strCategoriaCatalogo == cat.strCategoriaCatalogo)
      let arrayCopCat= JSON.parse(JSON.stringify(arrayCat));
      arrayCopCat.forEach(grupo => {
        let encontrado = cat.arrayGrupos.find(obj => obj.strGrupo === grupo.strGrupo);
        if (!encontrado) {
          cat.arrayGrupos.push({strGrupo:grupo.strGrupo});
        }
        });
    });
    this.fnSepararArticulos();
  }
  
  fnSepararArticulos(){
    this.arrayCategorias.forEach(cat => {
      cat.arrayGrupos.forEach(grup => {
        grup.arrayArts = [];
        let arrayGrup = this.arrayArticulos.filter(art => art.strGrupo == grup.strGrupo);
        let arrayCopGrup= JSON.parse(JSON.stringify(arrayGrup));
        arrayCopGrup.forEach(art => {
          let encontrado = grup.arrayArts.find(obj => obj.strArticulo === art.strArticulo);
          if (!encontrado) {
            
            grup.arrayArts.push({strArticulo:art.strArticulo,strImagen:art.strImagen,strDescripcion:art.strDescripcion,strDescripcionCorta:art.strDescripcionCorta,strDescripcionLarga:art.strDescripcionLarga,strOpcion:art.strOpcion,dblPrecioLista:art.dblPrecioLista,dblPrecioPlantilla:art.dblPrecioPlantilla});
          }
        });
      });
    });
    this.fnagregarOpcionesArts();
    
  }

  fnagregarOpcionesArts(){
    this.arrayCategorias.forEach(cat => {
      cat.arrayGrupos.forEach(grup => {
        grup.arrayArts.forEach(art => {
          this.convertImageToBase64(art.strImagen).then(base64 => {
        
           art.base64 = base64
            art.opciones = []
            let arrayArticulos = this.arrayArticulos.filter(arti => arti.strArticulo == art.strArticulo);
            let arrayCoparts= JSON.parse(JSON.stringify(arrayArticulos));
            if(art.strOpcion != ""){
              art.opciones= arrayCoparts;
            }
          }).catch(error => {
            console.error('Error:', error);
          });
        });
      });
    });
    console.log(this.arrayCategorias)
  }

  formatDescription(description: string): string {
    return description.split('•').join('<br>•');
  }

  createPDF() {
    const tamAncho = 564;
    const lin75 = 0.75 * tamAncho;
    const lin10 = 0.10 * lin75;
    const lin45 = 0.45 * lin75;
    const lin25 = 0.25 * tamAncho;
    const tamLin = 50;
  
    // Definición del PDF
    const pdfDefinition: any = {
      pageMargins: [15, 35, 15, 15],
      content: [
        ...this.arrayCategorias.map((cat: any) => {
          return {
            margin: [0, 0, 0, 0],
            stack: [
              ...cat.arrayGrupos.map((grup: any) => {
                let marginValue, margint;
                if (grup.strGrupo.length > 40) {
                  marginValue = [3, -20, 0, 0];
                } else if (grup.strGrupo.length > 20) {
                  marginValue = [3, -14, 0, 0];
                } else {
                  marginValue = [3, -7, 0, 0];
                }
                if (cat.strCategoriaCatalogo.length > 41) {
                  margint = [3, -20, 0, 0];
                } else if (cat.strCategoriaCatalogo.length > 21) {
                  margint = [3, -14, 0, 0];
                } else {
                  margint = [3, -7, 0, 0];
                }
                return {
                  margin: [0, 0, 0, 45],
                  columns: [
                    {
                      width: '75%',
                      columns: [
                        {
                          width: '10%',
                          canvas: [
                            {
                              type: 'line',
                              x1: 0,
                              y1: 0,
                              x2: lin10,
                              y2: 0,
                              lineWidth: tamLin,
                              lineColor: '#002F87'
                            }
                          ]
                        },
                        {
                          width: '45%',
                          image: this.logoBase64,
                          fit: [105, 50],
                          alignment: 'center',
                          margin: [0, -14, 0, 0],
                        },
                        {
                          width: '45%',
                          stack: [
                            {
                              canvas: [
                                {
                                  type: 'line',
                                  x1: 0,
                                  y1: 0,
                                  x2: lin45+1,
                                  y2: 0,
                                  lineWidth: tamLin,
                                  lineColor: '#002F87'
                                }
                              ]
                            },
                            {
                              text: cat.strCategoriaCatalogo,
                              style: 'headerRight',
                              alignment: 'right',
                              color: '#F0F8FF',
                              margin: margint,
                            }
                          ]
                        },
                      ],
                    },
                    {
                      width: '25%',
                      stack: [
                        {
                          canvas: [
                            {
                              type: 'line',
                              x1: 0,
                              y1: 0,
                              x2: lin25,
                              y2: 0,
                              lineWidth: tamLin,
                              lineColor: '#FF0000'
                            }
                          ]
                        },
                        {
                          text: grup.strGrupo,
                          style: 'headerLeft',
                          alignment: 'left',
                          color: '#F0F8FF',
                          margin: marginValue,
                        },
                        ...grup.arrayArts.map((art: any) => {
                          return {
                            image: art.base64, // Imagen en formato Base64
                            width: 100,
                            margin: [0, 5], // Espaciado vertical entre imágenes
                          };
                        }),
                      ]
                    },
                  ],
                };
              }),
            ],
          };
        }),
      ],
      styles: {
        headerRight: {
          fontSize: 13,
          bold: true,
        },
        headerLeft: {
          fontSize: 11,
          bold: true,
        },
      },
    };
    const pdf = pdfmake.createPdf(pdfDefinition);
    pdf.open();
  }
  
  fnimprimirpdf(){
    this.convertImageToBase64('assets/img/logo/ecodeli.png').then(base64 => {
      this.logoBase64 = base64;
      console.log(this.logoBase64);
      this.createPDF();
    });
  }
  
  convertImageToBase64(url: string): Promise<string> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }
  


  fnimprimirpdf1() {
    this.convertImageToBase64('/api/images/CUF20.JPG').then(base64 => {
      console.log(base64);
    }).catch(error => {
      console.error('Error:', error);
    });
  }
  

  
  // Ejemplo de uso
 
  
  
/* 
  createPDF(){
    const pdfDefinition:any={
      content: [
        {
          image: this.imageBase64,
          width: 700,
          height: 300,
          margin: [0, 10],
        },
      ],
    };
    const pdf = pdfmake.createPdf(pdfDefinition);
    pdf.open();
  }

  async loadImage(): Promise<void> {
    this.imageBase64 = await this.convertImageToBase64('assets/bonTri.png');
    this.createPDF();
  }

  convertImageToBase64(url: string): Promise<string> {
    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch image: ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result as string;
            if (base64String) {
              console.log('Base64 String:', base64String);
              resolve(base64String);
            } else {
              reject(new Error('Failed to convert image to base64: result is empty'));
            }
          };
          reader.onerror = (event) => {
            reject(new Error(`FileReader error: ${(event as any).error?.message || 'Unknown error'}`));
          };
          reader.readAsDataURL(blob);
        });
      });
  }
   */

 
/*       fncreatepdf() {
        const options = {
          margin: 8,
          filename: 'Cotizacion.pdf',
          image: { type: 'JPEG', quality: 0.98 },
          html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        const content = this.el.nativeElement;
        html2pdf()
          .from(content)
          .set(options)
          .toPdf()
          .get('pdf')
          .then(pdf => {
            pdf.save("Cotizacion.pdf");
          
          });
    
      } */
}
