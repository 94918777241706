<div class="container mt-5">
    <h1 class="text-center text-primary mb-4">Buscar Pedido</h1>
    
    <!-- Formulario de búsqueda -->
    <div class="mb-3">
      <label for="pedidoID" class="form-label">Número de Pedido</label>
      <input type="number" class="form-control" id="pedidoID" [(ngModel)]="pedidoID" placeholder="Ingresa el número de pedido">
    </div>
  
    <!-- Botón de Buscar -->
    <button class="btn btn-primary" (click)="buscarPedido()">Buscar</button>
  
    <!-- Mostrar tabla con los detalles del pedido -->
    <div *ngIf="pedidoEncontrado" class="mt-4">
      <h3>Detalles del Pedido: N° {{pedidoEncontrado.intID_Pedido}}</h3>
      <p><strong>Estatus:</strong>
        <span 
          [ngClass]="getEstatusClass(pedidoEncontrado.strEstatus_Pago)" 
          class="badge text-dark">
          <i [ngClass]="getEstatusIcon(pedidoEncontrado.strEstatus_Pago)"></i>   
          {{pedidoEncontrado.strEstatus_Pago}}
        </span>

      </p>
     <!-- <p><strong>Total:</strong>{{pedidoEncontrado.dblImporte | currency}}</p>--> 
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Artículo</th>
            <th>Descripción</th>
            <th>Cantidad</th>
           <!--  <th>Precio</th>-->
            <th>Unidad</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of pedidoEncontrado.detalles">
            <td>
              <div class="d-flex align-items-center">
                <img class="rounded w-25" alt="Product image" [src]="'https://www.cleanclean.mx/imagenes/'+item.strArticulo+'.JPG'">
                <span class="ms-3">{{ item.strArticulo }}</span>
              </div>
            </td>
            <td>{{ item.strDescripcion1 }} <br> <b *ngIf="item.strOpcion !=''">({{item.strOpcion}}) {{item.strNombreOpcion}}</b></td>
            <td>{{ item.dblCantidad }}</td>
           <!-- <td>{{ item.dblPrecioLista | currency }}</td>-->
            <td>{{ item.strUnidad }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!pedidoEncontrado" class="mt-5">
        <span class="mt-5">No se encontró información con el número de pedido proporcionado, por favor verifique el número de pedido e intente de nuevo.</span>
    </div>
  </div>
  
