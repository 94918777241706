import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class ComodatoService {
  arrayArticulos: any[] = [];
  arrayCotizador: any[] = [];

  dblTotal:number = 0;	//total en cantidad
  dblTotalCotizador:number=0;
  arrayTotalesPrecio: any[] = []; //total en monto del carrito
  arrayTotalesPrecioCotizador: any[] = []; //total en monto del cotizador
  dblIva:number = 0;
  objDescuentos:any;
  private aux:any;
  private articulos$ = new Subject<any>(); //sirve  para generar el stream de eventos
  private articulosCotizador$ = new Subject<any>(); //total de articulos en el cotizador
  private arrayArticulos$ = new Subject<any>();
  private arrayArticulosCotizador$ = new Subject<any>();
  private totalesPrecio$ = new Subject<any>();
  private totalesPrecioCotizador$ = new Subject<any>();//totales para el cotizador
  private descuentos$ = new Subject<any>();
  private arrayCotizador$ = new Subject<any>(); //observable de cotizaciones

  /****variables para manejar el total del carrito ******/
  private intTotalItems_CSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public intTotalItems_C$: Observable<number> = this.intTotalItems_CSubject.asObservable();
  private intTotal_C: number = 0;  // Inicializamos en 0
  
   /****variables para manejar articulos en carrito carrito ******/
   private arrayItems_CSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
   public arrayItems_C$: Observable<any> = this.arrayItems_CSubject.asObservable();
   private arrayItems_C:any=[];

   /**************variables para obtener subtotal********** */
   private dblSubTotalItems_CSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
   public dblSubTotalItems_C$: Observable<number> = this.dblSubTotalItems_CSubject.asObservable();
   private dblSubTotal_C: number = 0;  // Inicializamos en 0
   
   /**********************VARIABLES para guardar datos del cliente******************************************/
   private ObjClienteSubject_C: BehaviorSubject<any> = new BehaviorSubject<any>(0);
   public ObjCliente_C$: Observable<any> = this.ObjClienteSubject_C.asObservable();
   private ObjCliente_C: any = {};  // Inicializamos en 0

    ///**********************************VARIABLES PARA TIPO DE COMODATO***************************************************************/
    private objTipoComodato_CSubject: BehaviorSubject<any> = new BehaviorSubject<number>(0);
    public objTipoComodato_C$: Observable<any> = this.objTipoComodato_CSubject.asObservable();
    private objTipoComodato_C:  any = {};  // Inicializamos en 0

  constructor(private localSt:LocalStorageService) {
  /*   console.log('ingresa a constructor') */
    this.objTipoComodato_C= this.localSt.retrieve('ObjTipoComodato') ?this.localSt.retrieve('ObjTipoComodato') :{strTipoSolicitud:'NUEVO', strInstalacion:'SI', objMOVID :{}};  
    this.setTipoComodato(this.objTipoComodato_C);
    this.dblTotal =0;
    let clienteSesision= this.localSt.retrieve('ObjClienteComodato') ?this.localSt.retrieve('ObjClienteComodato') :{};        
    this.setCte(clienteSesision);
    this.arrayItems_C=this.localSt.retrieve('arrayItemsComodato') ?this.localSt.retrieve('arrayItemsComodato') :[];
    this.getTotalArts();
    this.getSubtotal(); 
    this.getArticulos();
   }
    setTipoComodato (objTipo:any){
      this.objTipoComodato_C=objTipo;
      this.localSt.store('ObjTipoComodato',this.objTipoComodato_C);
      this.objTipoComodato_CSubject.next(this.objTipoComodato_C);
    }
   setCte(objCte:any){
    objCte.intIDSucursal=   objCte.intIDSucursal==0? '':objCte.intIDSucursal;

   /*  console.log(objCte); */
    this.ObjCliente_C=objCte;
    
    this.localSt.store('ObjClienteComodato',this.ObjCliente_C);
    this.ObjClienteSubject_C.next(this.ObjCliente_C);
   }


   setArticulo(objArticulo:any) {
    console.log('agregar articulo a comodato')
    this.arrayItems_C=this.localSt.retrieve('arrayItemsComodato') ?this.localSt.retrieve('arrayItemsComodato') :[];

   
      let flag1= this.arrayItems_C.find(o=>o.strArticulo==objArticulo.strArticulo 
        &&o.strOpcion==objArticulo.strOpcion 
        && o.objConsumible.strConsumible ==  objArticulo.objConsumible.strConsumible
        && o.objOpcionConsumible.strOpcion ==objArticulo.objOpcionConsumible.strOpcion

       );
      if(!flag1){
        this.arrayItems_C.push(objArticulo);
      }
      else {
        flag1.dblCantidad=  flag1.dblCantidad+ objArticulo.dblCantidad ;
      }
      console.log(this.arrayItems_C);
      this.getTotalArts();
      this.getSubtotal();
      this.getArticulos(); 
   }
   updateArt(){
    this.arrayItems_C=this.localSt.retrieve('arrayItemsComodato') ?this.localSt.retrieve('arrayItemsComodato') :[];
    this.getTotalArts();
    this.getSubtotal();
    this.getArticulos();
  }
   deleteCarrito(){
    this.arrayItems_C=[];
    this.dblSubTotal_C=0;
    this.ObjCliente_C={}; 
    this.setCte(this.ObjCliente_C);
    this.objTipoComodato_C={strTipoSolicitud:'NUEVO', strInstalacion:'SI', objMOVID :{}};
    this.setTipoComodato(this.objTipoComodato_C);
    this.getTotalArts();
    this.getSubtotal();
    this.getArticulos();
  }
  deleteOnlyCarrito(){
    this.arrayItems_C=[];
    this.dblSubTotal_C=0;
   
    this.getTotalArts();
    this.getSubtotal();
    this.getArticulos();
  }
  deleteArt(objElimnar:any){
    console.log(this.arrayItems_C);
    this.getTotalArts();
    this.getSubtotal();
    this.getArticulos();
  }
  getArticulos(){
      
    this.localSt.store('arrayItemsComodato',this.arrayItems_C);
    this.arrayItems_CSubject.next(this.arrayItems_C);
  }
  getTotalArts(){
    //calcular total de items
    this.intTotal_C  =0;
    this.arrayItems_C.forEach(element => {
      this.intTotal_C  =this.intTotal_C + parseInt(element.dblCantidad);
    });
    this.intTotalItems_CSubject.next(this.intTotal_C);
 }
 getSubtotal(){
  //calcular total de items
  this.dblSubTotal_C  =0;
  this.arrayItems_C.forEach(element => {
    this.dblSubTotal_C  =this.dblSubTotal_C + ( element.dblCantidad * parseFloat(element.dblPrecioLista));
  });
  
  this.dblSubTotalItems_CSubject.next(this.dblSubTotal_C);
}
getTotal(){
  return this.dblTotal;
}

getTotalCot(){
  return this.dblTotalCotizador;
}


}
 