import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDespachador'
})
export class FilterPipeDespachador implements PipeTransform {
  transform(value:any, args: any): any {
    console.log(value);
    console.log(args);
    const resultPost=[];
    for(const post of value){
      if(post.strClaveDespachador.indexOf(args) > -1){
        resultPost.push(post);
      }
    
    };
    return resultPost;    
    //return null;
  }

}
