import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actualizacion-datos-generales',
  templateUrl: './actualizacion-datos-generales.component.html',
  styleUrls: ['./actualizacion-datos-generales.component.css']
})
export class ActualizacionDatosGeneralesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
