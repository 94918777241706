import { Component, OnInit, ElementRef, ViewChild, NgZone, OnDestroy  } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';

import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import * as html2pdf from 'html2pdf.js';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DataDBService } from '../servicios/data-db.service';
import { CarroService } from '../servicios/carroservice';
import Swal from 'sweetalert2';
import { ComodatoService } from '../servicios/comodato.service';
import { MatSort, Sort } from '@angular/material/sort';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
export interface Contacto {
  no: number;
  nombre: string;
  correo: string;
  telefono: string;
  empresa: string;
  estado: string;
  FechaCreacion: string;
  UltimoContacto: string;
};

export interface Clientes {
  no: number;
  cliente: string;
  nombre: string;
  rfc: string;
  contacto: string;
  estatus: string;
  giro: string;
  estrellas: string;
  categoria: string;
  detalle: string;
  intPreciosInferioresMinimo:number;
}

export interface familias {
  indice:string;
  strUrl_Logo:string;
  strFamilia:string; 
  strGiro:string;
  strNombreAgente:string 
  strEstrellas:string;
  intSucursales:string;
  strFechaCreacion:string;
}

export interface historicovtaxlinea{
  ejercicio: number;
  periodo: number;
  acces: number;
  margenA: number;
  kimberly: number;
  margenk: number;
  rubermaid: number;
  margenru: number;
  bluetisue: number;
  margenblu: number;
  quimicos: number;
  margenqu: number;
  despachadores: number;
  margendes: number;
  tork: number;
  margentork: number;
  xibu: number;
  margenx: number;
  foaom: number;
  margenf: number;
  total: number;
}
interface Food {
  value: string;
  viewValue: string;
}
interface prospectos {
  indice: string;
  strEstatus: string;
  strNombreEmpresa: string;
  strNombreAgente: string;
  strFamilia: string;
  strClasificacion: string;
  strFechaActividad: string;
  strContacto_Nombre: string;
}
interface contacto {
  indice: string;
  intID_Contacto: string;
  strContacto_Nombre: string;
  strNombreEmpresa: string;
  strTipo_Leads: string;
  strFechaCreacion: string;
  strFechaRequerida: string;
}

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.css'],
  animations: [
    trigger('slideUpDown', [
      state('void', style({
        height: '0',
        opacity: '0',
        overflow: 'hidden'
      })),
      state('*', style({
        height: '*',
        opacity: '1'
      })),
      transition('void <=> *', animate('300ms ease-in-out'))
    ])
  ]
})


export class DirectorioComponent implements OnInit , OnDestroy{
  @ViewChild('pdfTableInfoAdd', { static: true }) el!: ElementRef
  @ViewChild('pdftableFlujoCte', { static: true }) tableFlujoCte!: ElementRef
  columnsContacto: string[] = ['indice', 'intID_Contacto', 'strContacto_Nombre', 'strNombreEmpresa', 'strTipo_Leads', 'strFechaCreacion', 'strFechaRequerida'];
/*   displayContacto: string[] = ['no', 'nombre', 'correo', 'telefono', 'empresa', 'estado', 'FechaCreacion', 'UltimoContacto']; */
  displayedColumns: string[] = ['no', 'cliente', 'nombre', 'rfc', 'contacto', 'estatus', 'intPreciosInferioresMinimo','giro', 'estrellas', 'categoria','detalle'];
  displayFamilias: string[] = ['indice','strUrl_Logo', 'strFamilia', 'strGiro', 'strNombreAgente', 'strEstrellas', 'intSucursales','strFechaCreacion'];
  displayhisvtas: string[] = ['ejercicio', 'periodo', 'acces', 'margenA', 'kimberly', 'margenk', 'rubermaid', 'margenru', 'bluetisue', 'margenblu', 'quimicos', 'margenqu', 'despachadores', 'margendes', 'tork', 'margentork', 'xibu', 'margenx', 'foaom', 'margenf', 'total'];
  displayProspectos: string[] = ['indice', 'strEstatus', 'strNombreEmpresa', 'strNombreAgente', 'strFamilia', 'strClasificacion', 'strFechaActividad', 'strContacto_Nombre'];

  @ViewChild('paginatorClientes') paginator: MatPaginator;
  @ViewChild('paginVtasXlin') paginador: MatPaginator;
  public searchText: string = ''
  arrayLineas: Food[] = [
    { value: '', viewValue: 'TODAS' },
    { value: 'ACCESORIOS', viewValue: 'ACCESORIOS' },
    { value: 'KIMBERLY', viewValue: 'KIMBERLY' },
    { value: 'QUIMICOS', viewValue: 'QUIMICOS' },
    { value: 'RUBBERMAID', viewValue: 'RUBBERMAID' },
    { value: 'BLUE TISSUE', viewValue: 'BLUE TISSUE' },
    { value: 'DESPACHADORES ECODELI', viewValue:'DESPACHADORES ECODELI'},
    { value: 'TORK', viewValue: 'TORK' },
    { value: 'FOAOM', viewValue: 'FOAOM' },
    { value: 'XIBU', viewValue: 'XIBU' }
  ];
  public spinerFamilias: boolean = false;
  public spinerProspecto: boolean = false;
  public arrayGerentes: any = [];
  public arraySucursales: any = [];
  public arrayAgentes: any = [];
  // @LocalStorage('array_Agentes') public arrayAgentes: any; 
  public arrayPeriodo: any = [];
  public arrayEjercicio: any = [];
  public arrayDetCliente: any = [];
  public arrayContactos: any = [];
  public arrayClientes: any = [];
  public arrayProspectos: any = [];
  public arrayAccesos: any = [];
  public arrayEstadoCuenta: any = [];
  public arrayHistoricoVta: any = [];
  public arrayInfoAdicional: any = [];
  public arrayFlujocte: any = [];
  public arrayHistoricoXlinea: any = [];
  public arrayAjusteLineas: any = [];
  public arrayVtaXart: any = [];
  public arrayVtaXartOrder: any = [];
  public arrayCabeceraXart: any = [];
  public arrayArts: any = [];
  public arrayFamilias: any = [];
  public arrayListaLeads: any = [];  
  public arrayListadoFamilias: any = []
  public arrayDetFamilia: any = [];
  public pageSize: number = 100;
  public currentPage: number = 0;
  public strGerente: string;
  public strAgente: string;
  public strLinea: string = "";
  public strBuscar: string = "";
  public strSucursal: string;
  public strConexion: string = "";
  public inputSearch: string = "";
  public inputSearchInfoAdd: string = "";
  public inputFlujoCte: string = "";
  public intPeriodo: number;
  public intEjercicio: number;
  public intSaldoCorriente: number;
  public int15dias: number;
  public int30dias: number;
  public int60dias: number;
  public int90dias: number;
  public int90mas: number;
  public intInicioList: number = 0;
  public cantSum: number = 5000;
  public intFinList: number = this.cantSum;
  public intCant: number = 0;

  public spinerTable: boolean = false;
  public spinerDatos: boolean = false;
  public spinerCte: boolean = false;
  public spinerEdoCta: boolean = false;
  public spinerHisVta: boolean = false;
  public spinerInfAdd: boolean = false;
  public spinerFlujoCte: boolean = false;
  public spinerVtaXlin: boolean = false;
  public spinerVtaXart: boolean = false;
  public spinerContacto: boolean = false;

  public intTotalImportFCte: number;
  public intCobradoFCte: number;
  public IntSaldoFCte: number;
  public cargando: boolean;
  public isHoveredButton: boolean;
  public boolGerente: boolean;
  public boolSucursal: boolean;
  public boolAgente: boolean;
  public booltitl: boolean = false;
  public tagName: any;
  public objModal: any = {};
  public objModalHistorico: any = {};
  public objVtasXlinea: any = {};
  public objvtaXarticulo: any = {};
  public boolFamilias:boolean=false;
  public boolCheck:boolean = false;
  public objHisxLin: any = {
    strEjercicio: "", strPeriodo: "", dblAccesorios: 0, dblAccesoriosMargen: 0,
    dblKimberly: 0, dblKimberlyMargen: 0, dblRubbermaid: 0, dblRubbermaidMargen: 0
    , dblBlueTissue: 0, dblBlueTissueMargen: 0, dblQuimicos: 0, dblQuimicosMargen: 0,
    dblDespachadores: 0, dblDespachadoresMargen: 0, dblTork: 0, dblTorkMargen: 0,
    dblXibu: 0, dblXibuMargen: 0, dblFoaom: 0, dblFoaomMargen: 0, total: 0
  }
  public strSearchfamilias: string = "";
  public intIDLeads : number;
  dataSourceContacto = new MatTableDataSource<contacto>(this.arrayContactos);
  dataSource = new MatTableDataSource<Clientes>(this.arrayClientes);
  datafamilias= new MatTableDataSource<familias>(this.arrayListadoFamilias);
  dataProspectos= new MatTableDataSource<prospectos>(this.arrayListaLeads);
  dataHisXvta = new MatTableDataSource<historicovtaxlinea>()
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  @ViewChild('paginadorProspectos') paginadorProspectos: MatPaginator;
  @ViewChild('sortProspectos') sortProspectos: MatSort;
  @ViewChild('paginadorContacto') paginadorContacto: MatPaginator;
  @ViewChild('sortContacto') sortContacto: MatSort;
  private unsubscribe$ = new Subject<void>();
  constructor(private commodatoservice:ComodatoService, private carroservice: CarroService,private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, 
    private zone: NgZone, elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
     private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
    this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.strConexion = this.localSt.retrieve('strConexion');
    
    this.tagName =  elem.nativeElement.tagName.toLowerCase();
   

  }

  ngOnInit(): void {
    
    this._httpService.setMostrarMenu(true);
    // this.arrayAccesos = this.localSt.retrieve('arrayAccesos') ? this.localSt.retrieve('arrayAccesos') : [];
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos=value?value:[];
      
    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? objAcceso[0].strUsuario : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.boolFamilias =  objAcceso.find((o: any) => o.strAccion == 'lstFamilias') ? true : false;
        this.boolCheck = objAcceso.find((o: any) => o.strAccion == 'checkPrecioMinimo') ? true : false;
        console.log(this.boolCheck)
           //------------conexi+on-------------------
           this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
            console.log(' CARGA conexion directorio')
            this.fnGetClientes();
            //this.fnGetFamilias();
            this.fnGetInfoFamilias()
            this.intInicioList = 0;
            this.intFinList = this.cantSum;
            this.intCant = 0;
            // this.fnGetProspectos();
               
            this.fnGetContacto();
           
            this.fnGetFiltros();
            this.fnGetListadoLeads();
           
          });
       
      }
 
     }
     else {
      this.router.navigate(['/login']);
     }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataHisXvta.paginator = this.paginador;
  
  }
  ngOnDestroy(): void {
    // Emitir un valor para completar las suscripciones
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  fnTableContacto(){
    this.dataSourceContacto.paginator = this.paginadorContacto;
    this.dataSourceContacto.sort = this.sortContacto;
  }

  fnTabletareasCte(){
    this.datafamilias.paginator = this.paginadorTareasCte;
    this.datafamilias.sort = this.sortTareasCte;
  }
  fnTableProspectos() {
    this.dataProspectos.paginator = this.paginadorProspectos;
    this.dataProspectos.sort = this.sortProspectos;
  }

  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {

      this.arrayAgentes = nuevoValor;
    });
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  applyFilterClientes(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterFamilias(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.arrayListadoFamilias.filter = filterValue.trim().toLowerCase();
  }

  applyFilterhisxvta(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataHisXvta.filter = filterValue.trim().toLowerCase();
  }

  fnGetClientes(filter:boolean=false) {
    if (filter==true){
      this.cantSum = 5000
      this.intInicioList = 0;
      this.intFinList = this.cantSum;
      this.intCant = 0;
      this.arrayClientes =[] 
      this.dataSource.data = [];
       }
    if (this.intCant == 0) {
      this.spinerTable = true;
    }
    let obj = {
      strAgente:"",
      strSucursal:"",
      strGerente:"",
      strConexion: this.strConexion,
      strAccion: 'getCte',
      offset: this.intInicioList,
      pageSize: this.intFinList
    }
    if(this.strAgente!="" && this.strAgente != null){
      obj.strAgente= this.strAgente 
      obj.strSucursal=""
      obj.strGerente=""
    }else if(this.strSucursal!=""&& this.strSucursal != null){
      obj.strAgente= ""
      obj.strSucursal=this.strSucursal
      obj.strGerente=""
    }else if(this.strGerente!=""&& this.strGerente != null){
      obj.strAgente= ""
      obj.strSucursal=""
      obj.strGerente=this.strGerente
    } 
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.spinerTable = false;
        this.arrayClientes.push(...result);
        this.intCant += this.cantSum;
        if (this.arrayClientes.length == this.intCant) {
          this.spinerDatos = true;
          this.dataSource.data = this.arrayClientes;
          this.dataSource.paginator = this.paginator;
          this.intInicioList = this.intInicioList + this.cantSum;
          this.fnGetClientes();
        } else {
          this.spinerTable = false;
          this.spinerDatos = false;
          this.dataSource.data = this.arrayClientes;
          this.dataSource.paginator = this.paginator;
          this.intCant == 0  
          console.log("Se finalizo la llamada esto")
        }
      }, error => {
        this.spinerTable = false;
        this.spinerDatos = false;
        console.error('Error al consumir la API:', error);
      });
  }
  
  fnGetContacto() {
    this.spinerContacto = true;
    let obj = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion: 'getContacto'
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia ')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        let ind =0;
        this.arrayContactos = result;                      
        this.arrayContactos.forEach(ele => {
          ind +=1
          ele.indice = ind
          ele.seeDet = false
        });
        this.dataSourceContacto = new MatTableDataSource(this.arrayContactos);
        this.fnTableContacto()
        this.spinerContacto = false;
      },
      error => {
        this.spinerContacto = false;
        console.error('Error al consumir la API:', error);
      });
  }

  fnIrFamilias(obj: any) {
      let objRuta_Anterior={
        ruta_origen:'/directorio',
        ruta_destino:'/detalleLeadFamilia',
        nombre:'Directorio'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );

    this.router.navigate(['/detalleLeadFamilia'], { queryParams: { strFamilia: obj.strFamilia } });
  }

  onGerenteChange(srtCam:string){
    if(srtCam=="Ger"){
      this.strSucursal =""
      this.strAgente =""
    }else if(srtCam=="Suc"){
      this.strAgente =""
    }
  }

  fncreatepdf(strC: string) {
    const options = {
      margin: 8,
      filename: 'Cotizacion.pdf',
      image: { type: 'JPEG', quality: 0.98 },
      html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    const content = strC == "cuentacte" ? this.el.nativeElement : this.tableFlujoCte.nativeElement;

    html2pdf()
      .from(content)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        pdf.save("Estado de Cuenta.pdf");
      });
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return ''; //Retorna cadena vacía si dateString es nulo o vacío
    }
    const formattedDate = dateString.replace(/\s12:00AM$/, '');
    return formattedDate;
  }

  fncleanModal() {
    this.arrayVtaXart = [];
    this.arrayDetCliente = [];
    this.arrayEstadoCuenta = [];
    this.arrayHistoricoVta = [];
    this.arrayHistoricoXlinea = [];
    this.arrayVtaXart = [];
    this.objModalHistorico = {}
    this.objVtasXlinea = {}
    this.objvtaXarticulo = {}
  }

  fnGetClientesDetalle(x: any) {
    this.fncleanModal();
    this.spinerCte = true;
    this.objvtaXarticulo = x;
    this.objVtasXlinea = x;
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getInfoCte',
      strCliente: x.strCliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.arrayDetCliente = result;
        this.objModal = this.arrayDetCliente[0]
        this.objModal.strCliente = x.strCliente
        this.objModal.strNombre = x.strNombre
        this.objModal.strEstatus = x.strEstatus
        this.spinerCte = false;
        this.fnGetEstadoCuenta(x);
      }, error => {
        this.spinerCte = false;
        console.error('Error al consumir la API:', error);
      });
  }

  fnGetEstadoCuenta(x: any) {
    this.spinerEdoCta = true;
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getEstadoCuenta',
      strCliente: x.strCliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.arrayEstadoCuenta = result;

        this.spinerEdoCta = false;
        this.fnGetHistoricoVta(x)
      }, error => {
        this.spinerEdoCta = false;
        console.error('Error al consumir la API:', error);
      });
  }
 
  fnGetHistoricoVta(x: any) {
    this.spinerHisVta = true
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getHistorico',
      strCliente: x.strCliente
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.arrayHistoricoVta = result;
        this.objModalHistorico = this.arrayHistoricoVta[0]
        this.spinerHisVta = false
        this.fnGetInfoAdicional(x);

      }, error => {
        this.spinerHisVta = false
        console.error('Error al consumir la API:', error);
      });
  }

  fnGetHistoricoxLinea(objCte: any) {
    this.spinerVtaXlin = false
    this.objHisxLin = {
      dblAccesorios: 0, dblAccesoriosMargen: 0,
      dblKimberly: 0, dblKimberlyMargen: 0, dblRubbermaid: 0, dblRubbermaidMargen: 0
      , dblBlueTissue: 0, dblBlueTissueMargen: 0, dblQuimicos: 0, dblQuimicosMargen: 0,
      dblDespachadores: 0, dblDespachadoresMargen: 0, dblTork: 0, dblTorkMargen: 0,
      dblXibu: 0, dblXibuMargen: 0, dblFoaom: 0, dblFoaomMargen: 0, total: 0
    }
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getHistLinea_Cte',
      strCliente: objCte.strCliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {

        this.arrayHistoricoXlinea = result;

        this.arrayHistoricoXlinea.forEach(x => {
          const intEle = this.arrayAjusteLineas.filter(j => j.strEjercicio === x.strEjercicio && j.strPeriodo === x.strPeriodo);
          if (intEle.length === 0) {
            this.arrayAjusteLineas.push({
              strEjercicio: x.strEjercicio, strPeriodo: x.strPeriodo,
              dblAccesorios: 0, dblAccesoriosMargen: 0, dblKimberly: 0
              , dblKimberlyMargen: 0, dblRubbermaid: 0, dblRubbermaidMargen: 0
              , dblBlueTissue: 0, dblBlueTissueMargen: 0, dblQuimicos: 0
              , dblQuimicosMargen: 0, dblDespachadores: 0, dblDespachadoresMargen: 0
              , dblTork: 0, dblTorkMargen: 0, dblXibu: 0, dblXibuMargen: 0
              , dblFoaom: 0, dblFoaomMargen: 0, total: 0
            });
          }
        });

        this.arrayAjusteLineas.forEach(o => {
          this.arrayHistoricoXlinea.forEach(x => {
            if (o.strEjercicio === x.strEjercicio && o.strPeriodo === x.strPeriodo) {
              if (x.strLinea == 'ACCESORIOS') {
                o.dblAccesorios = x.dblImporte;
                o.dblAccesoriosMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'KIMBERLY') {
                o.dblKimberly = x.dblImporte;
                o.dblKimberlyMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'QUIMICOS') {
                o.dblQuimicos = x.dblImporte;
                o.dblQuimicosMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'RUBBERMAID') {
                o.dblRubbermaid = x.dblImporte;
                o.dblRubbermaidMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'BLUE TISSUE') {
                o.dblBlueTissue = x.dblImporte;
                o.dblBlueTissueMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'DESPACHADORES ECODELI') {
                o.dblDespachadores = x.dblImporte;
                o.dblDespachadoresMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'TORK') {
                o.dblTork = x.dblImporte;
                o.dblTorkMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'FOAOM') {
                o.dblFoaom = x.dblImporte;
                o.dblFoaomMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              } else if (x.strLinea == 'XIBU') {
                o.dblXibu = x.dblImporte;
                o.dblXibuMargen = x.dblImporte > 0 ? parseFloat(((x.dblMargen / x.dblImporte) * 100).toFixed(2)) : 0;
              }
            }
          });
          o.total = (o.dblAccesorios ? o.dblAccesorios : 0) + (o.dblKimberly ? o.dblKimberly : 0) + (o.dblQuimicos ? o.dblQuimicos : 0) +
            (o.dblRubbermaid ? o.dblRubbermaid : 0) + (o.dblBlueTissue ? o.dblBlueTissue : 0) + (o.dblDespachadores ? o.dblDespachadores : 0) +
            (o.dblTork ? o.dblTork : 0) + (o.dblFoaom ? o.dblFoaom : 0) + (o.dblXibu ? o.dblXibu : 0);

          this.objHisxLin.dblAccesorios = this.objHisxLin.dblAccesorios + o.dblAccesorios;
          this.objHisxLin.dblAccesoriosMargen = this.objHisxLin.dblAccesoriosMargen + o.dblAccesoriosMargen;
          this.objHisxLin.dblKimberly = this.objHisxLin.dblKimberly + o.dblKimberly;
          this.objHisxLin.dblKimberlyMargen = this.objHisxLin.dblKimberlyMargen + o.dblKimberlyMargen;
          this.objHisxLin.dblRubbermaid = this.objHisxLin.dblRubbermaid + o.dblRubbermaid;
          this.objHisxLin.dblRubbermaidMargen = this.objHisxLin.dblRubbermaidMargen + o.dblRubbermaidMargen;
          this.objHisxLin.dblBlueTissue = this.objHisxLin.dblBlueTissue + o.dblBlueTissue;
          this.objHisxLin.dblBlueTissueMargen = this.objHisxLin.dblBlueTissueMargen + o.dblBlueTissueMargen;
          this.objHisxLin.dblQuimicos = this.objHisxLin.dblQuimicos + o.dblQuimicos;
          this.objHisxLin.dblQuimicosMargen = this.objHisxLin.dblQuimicosMargen + o.dblQuimicosMargen;
          this.objHisxLin.dblDespachadores = this.objHisxLin.dblDespachadores + o.dblDespachadores;
          this.objHisxLin.dblDespachadoresMargen = this.objHisxLin.dblDespachadoresMargen + o.dblDespachadoresMargen;
          this.objHisxLin.dblTork = this.objHisxLin.dblTork + o.dblTork;
          this.objHisxLin.dblTorkMargen = this.objHisxLin.dblTorkMargen + o.dblTorkMargen;
          this.objHisxLin.dblXibu = this.objHisxLin.dblXibu + o.dblXibu;
          this.objHisxLin.dblXibuMargen = this.objHisxLin.dblXibuMargen + o.dblXibuMargen;
          this.objHisxLin.dblFoaom = this.objHisxLin.dblFoaom + o.dblFoaom;
          this.objHisxLin.dblFoaomMargen = this.objHisxLin.dblFoaomMargen + o.dblFoaomMargen;
          this.objHisxLin.total = this.objHisxLin.total + o.total;
        });

        this.objHisxLin.dblAccesoriosMargen = ((this.objHisxLin.dblAccesoriosMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblKimberlyMargen = ((this.objHisxLin.dblKimberlyMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblRubbermaidMargen = ((this.objHisxLin.dblRubbermaidMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblBlueTissueMargen = ((this.objHisxLin.dblBlueTissueMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblQuimicosMargen = ((this.objHisxLin.dblQuimicosMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblDespachadoresMargen = ((this.objHisxLin.dblDespachadoresMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblTorkMargen = ((this.objHisxLin.dblTorkMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblXibuMargen = ((this.objHisxLin.dblXibuMargen / this.arrayAjusteLineas.length));
        this.objHisxLin.dblFoaomMargen = ((this.objHisxLin.dblFoaomMargen / this.arrayAjusteLineas.length));

        this.dataHisXvta.data = this.arrayAjusteLineas
        this.dataHisXvta.paginator = this.paginador;
        this.spinerVtaXlin = false
        this.fnVtaXarticulo(objCte, this.intEjercicio);

      }, error => {
        this.spinerVtaXlin = false
        console.error('Error al consumir la API:', error);
      });
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  fnVtaXarticulo(x: any, ejer: number) {
    this.spinerVtaXart = true;

    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getVtaXArt',
      strCliente: x.strCliente,
      intEjercicio: ejer,
      strLinea: ''
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.arrayVtaXart = result

        const uniqueYearsSet = new Set(this.arrayVtaXart.map(item => item.strEjercicio));
        this.arrayCabeceraXart = Array.from(uniqueYearsSet).map(year => ({ strEjercicio: year }));
        const arts = new Set(this.arrayVtaXart.map(item => item.strArticulo));
        this.arrayArts = Array.from(arts).map(year => ({ strArticulo: year }));
        this.arrayArts.forEach(elem => {
          const matchingObj = this.arrayVtaXart.find(item => item.strArticulo === elem.strArticulo);
          if (matchingObj) {
            Object.assign(elem, matchingObj);
          }
        });
        this.arrayVtaXart.forEach(art => {
          this.arrayArts.forEach(item => {
            if (art.strArticulo == item.strArticulo) {
              if (art.strPeriodo == 1) {
                item['ene' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 2) {
                item['feb' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 3) {
                item['mar' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 4) {
                item['abr' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 5) {
                item['may' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 6) {
                item['jun' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 7) {
                item['jul' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 8) {
                item['ago' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 9) {
                item['sep' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 10) {
                item['oct' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 11) {
                item['nov' + art.strEjercicio] = art.intCantidad;
              } else if (art.strPeriodo == 12) {
                item['dic' + art.strEjercicio] = art.intCantidad;
              }
            }
          });
        });

        this.arrayArts.forEach(item => {
          item.intTotal = 0
          this.arrayCabeceraXart.forEach(art => {
            let suma =
              (item['ene' + art.strEjercicio] ? item['ene' + art.strEjercicio] : 0) +
              (item['feb' + art.strEjercicio] ? item['feb' + art.strEjercicio] : 0) +
              (item['mar' + art.strEjercicio] ? item['mar' + art.strEjercicio] : 0) +
              (item['abr' + art.strEjercicio] ? item['abr' + art.strEjercicio] : 0) +
              (item['may' + art.strEjercicio] ? item['may' + art.strEjercicio] : 0) +
              (item['jun' + art.strEjercicio] ? item['jun' + art.strEjercicio] : 0) +
              (item['jul' + art.strEjercicio] ? item['jul' + art.strEjercicio] : 0) +
              (item['ago' + art.strEjercicio] ? item['ago' + art.strEjercicio] : 0) +
              (item['sep' + art.strEjercicio] ? item['sep' + art.strEjercicio] : 0) +
              (item['oct' + art.strEjercicio] ? item['oct' + art.strEjercicio] : 0) +
              (item['nov' + art.strEjercicio] ? item['nov' + art.strEjercicio] : 0) +
              (item['dic' + art.strEjercicio] ? item['dic' + art.strEjercicio] : 0)
            item.intTotal = item.intTotal + suma
          })
        });
        this.spinerVtaXart = false;

      }, error => {
        this.spinerVtaXart = false;
        console.error('Error al consumir la API:', error);
      });
  }

  fnGetInfoAdicional(x: any) {
    this.spinerInfAdd = true
    this.intSaldoCorriente = 0;
    this.int15dias = 0;
    this.int30dias = 0;
    this.int60dias = 0;
    this.int90dias = 0;
    this.int90mas = 0;
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getInfoAdicional',
      strCliente: x.strCliente
    };
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.arrayInfoAdicional = result;
        this.arrayInfoAdicional.forEach(x => {
          this.intSaldoCorriente += x.dblSaldoCorriente
          this.int15dias += x.dblSaldo15
          this.int30dias += x.dblSaldo30
          this.int60dias += x.dblSaldo60
          this.int90dias += x.dblSaldo90
          this.int90mas += x.dblSaldoA
        });
        this.spinerInfAdd = false
        this.fnGetflujocte(x)

      }, error => {
        this.spinerInfAdd = false
        console.error('Error al consumir la API:', error);
      });
  }

  fnGetflujocte(x: any) {
    this.spinerFlujoCte = true
    this.intTotalImportFCte = 0;
    this.intCobradoFCte = 0;
    this.IntSaldoFCte = 0;
    let obj = {
      strConexion: this.strConexion,
      strAccion: 'getFlujoCte',
      strCliente: x.strCliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.arrayFlujocte = result;
        this.arrayFlujocte.forEach(e => {
          this.intTotalImportFCte = this.intTotalImportFCte + e.dblImporte;
          this.intCobradoFCte = this.intCobradoFCte + e.dblCobrado;
          this.IntSaldoFCte = this.IntSaldoFCte + e.dblSaldo;
        });
        this.spinerFlujoCte = false
        this.fnGetHistoricoxLinea(x);
      }, error => {
        this.spinerFlujoCte = false
        console.error('Error al consumir la API:', error);
      });
  }

  dividirTextoDosLineas(texto: string): string {
    if (texto && texto.trim().length > 0) {
      const palabras = texto.trim().split(' ');
      const mitad = Math.ceil(palabras.length / 2);
      const primeraMitad = palabras.slice(0, mitad).join(' ');
      const segundaMitad = palabras.slice(mitad).join(' ');
      return `<div class='fs11 fw-bold'>${primeraMitad}</div><div class='fs11 fw-bold'>${segundaMitad}</div>`;
    } else {
      return texto;
    }
  }

  fnNamePeriodo(intPer: number): string {
    let strPeriodo = ""
    strPeriodo = intPer == 1 ? "Enero" : intPer == 2 ? "Febrero" : intPer == 3 ? "Marzo" : intPer == 4 ? "Abril" :
      intPer == 5 ? "Mayo" : intPer == 6 ? "Junio" : intPer == 7 ? "Julio" : intPer == 8 ? "Agosto" : intPer == 9 ? "Septiembre" :
        intPer == 10 ? "Octubre" : intPer == 11 ? "Noviembre" : intPer == 12 ? "Diciembre" : "";
    return strPeriodo;
  }

  fnGetFamilias() {
    this.spinerFamilias = true;
    this.arrayFamilias = [];
    let obj = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion,
      strAccion: 'getFamilia'
    } 
    console.log(obj)
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio ')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.arrayFamilias = result;
       
        this.arrayFamilias.forEach(ele => {
          ele.seeDet = false;
        });
        console.log(this.arrayFamilias)
        this.spinerFamilias = false;
      },
      error => {
        this.spinerFamilias = false;
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });
  }

  fnClickRow(item: any) {
    this.arrayFamilias.forEach(x => {
      if (x.intCliente == item.intCliente && x.strFamilia == item.strFamilia && x.intSucursalesCte == item.intSucursalesCte) {
        if (x.seeDet == true) {
          x.seeDet = false;
        } else {
          x.seeDet = true;
          this.fnGetFamiliasDetalle(x)
        }
      } else {
        x.seeDet = false;
      }
    });
  }

  fnGetFamiliasDetalle(item: any) {
    let obj = {
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strFamilia: item.strFamilia.trimEnd(),
      strConexion: this.strConexion,
      strAccion: 'getDetalleFamilia'
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio ')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => {
        this.arrayDetFamilia = result 
      },
      error => {
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      });
  }

  get pagedData() {
    let arrayDatos: any = []
    arrayDatos = this.datafamilias.data.filter(item => {
      // Iteramos sobre las claves (campos) de cada objeto
      for (let campo in item) {
        // Convertimos el valor del campo a string para que la comparación funcione en ambos casos
        let valorCampo = item[campo].toString().toLowerCase();
        // Si el valor del campo incluye el filtro, retornamos true
        if (valorCampo.includes(this.strSearchfamilias)) {
          return true;
        }
      }
      // Si no se encuentra el filtro en ningún campo, retornamos false
      return false;
    });
    const startIndex = this.currentPage * this.pageSize;
    return arrayDatos.slice(startIndex, startIndex + this.pageSize);
    /* return this.datafamilias.data.slice(startIndex, startIndex + this.pageSize); */
  }

  // Función para manejar el cambio de página
  onPageChange(event: any) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  enviarDetalle(item: any) {
     //guardamos la familia seleccionada
     
       //guardamos en el servicio de indexed db la ruta
       let objRuta_Anterior={
        ruta_origen:'/directorio',
        ruta_destino:'/detalleLeadFamilia',
        nombre:'Directorio'
      } 
      this.carroservice.setFamilia({ idFamilia: item.intID_Familia, strFamilia: item.strFamilia })
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
      this.router.navigate(['/detalleLeadFamilia'], {
      queryParams: { idFamilia: item.intID_Familia, strFamilia: item.strFamilia }
    });
  }

  enviarDetalleContacto(item: any) {
    //guardamos en el servicio de indexed db la ruta
    let objRuta_Anterior={
     ruta_origen:'/directorio',
     ruta_destino:'/detalle_contacto',
     nombre:'Directorio'
   }
   this.indexedDBService.removeItem('objRuta_Anterior');
   this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
  this.router.navigate(['/detalle_contacto'], {
    queryParams: { idContacto: item.intID_Contacto }
  });
}

  enviarDetalleCliente(item: any) { 
          //guardamos en el servicio de indexed db la ruta
          let objRuta_Anterior={
            ruta_origen:'/directorio',
            ruta_destino:'/detalleLeadCliente',
            nombre:'Directorio'
          }
          this.indexedDBService.removeItem('objRuta_Anterior');
          this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
          //agregamos a carrito el cliente
          this.fnSeleccionarCliente(item); //vivi
    this.router.navigate(['/detalleLeadCliente'],{queryParams:{strCliente: item.strCliente}});
  }

  fnGetInfoFamilias() {
    this.spinerFamilias =true
    this.arrayListadoFamilias = []
    let obj = {
      strAccion: 'getFamilias',
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => { 
        this.arrayListadoFamilias = result
        let ind=0
        this.arrayListadoFamilias.forEach(ele => {
          ind +=1;
          ele.indice = ind ;
        });



        this.datafamilias = new MatTableDataSource(this.arrayListadoFamilias);
        
        this.fnTabletareasCte();
        this.spinerFamilias =false
      }, error => {
        this.spinerFamilias =false
        console.error('Error al consumir la API:', error);
      });
  }

  fnGetListadoLeads() {
    this.spinerProspecto =true
    this.arrayListaLeads = []
    let obj = {
      strAccion: 'getListadoLeads',
      strAgente: this.strAgente,
      strSucursal: this.strSucursal,
      strGerente: this.strGerente,
      strConexion: this.strConexion
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_modVentas_Leads')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      result => { 
        this.arrayListaLeads = result;
        let ind = 0;
        if(this.arrayListaLeads.length >0){
          this.arrayListaLeads.forEach(item => {
            ind+=1
            item.indice = ind
          });
        }
       
        this.dataProspectos = new MatTableDataSource(this.arrayListaLeads);
        this.fnTableProspectos();
        this.spinerProspecto =false
      }, error => {
        console.error('Error al consumir la API:', error);
        this.spinerProspecto =false
      });

   
  }

  fnDetalleLead(item: any, estatus: any) { 
    let strCliente = item.strCliente
    this.intIDLeads = item.intIdLeads
   console.log(this.intIDLeads)
    
    if (item.intRedireccion == 1) {
      let objRuta_Anterior={
        ruta_origen:'/directorio',
        ruta_destino:'/detalleLead',
        nombre:'Directorio'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );

      //redireccionamiento a detalle lead
      this.router.navigate(['/detalleLead'], { queryParams: { idLead: this.intIDLeads } });

    } else if (item.intRedireccion == 2) {
      let objRuta_Anterior={
        ruta_origen:'/directorio',
        ruta_destino:'/detalleLeadFamilia',
        nombre:'Directorio'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
      //redireccionamiento a detalle familia
      this.router.navigate(['/detalleLeadFamilia'], { queryParams: { idFamilia: item.intID_Familia, strFamilia: item.strFamilia } });

    } else if (item.intRedireccion == 3) {
      let objRuta_Anterior={
        ruta_origen:'/directorio',
        ruta_destino:'/detalleLeadCliente',
        nombre:'Directorio'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );

      //redireccionamiento a detalle cliente
      this.router.navigate(['/detalleLeadCliente'],{queryParams:{strCliente: item.strCliente}});

    } else if (item.intRedireccion == 4) {
      let objRuta_Anterior={
        ruta_origen:'/directorio',
        ruta_destino:'/detoport',
        nombre:'Directorio'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
      //redireccionamiento a detalle oportunidad
      this.router.navigate(['/detoport'], { queryParams: { idLead: this.intIDLeads, sts: estatus } });

    } else if (item.intRedireccion == 5) {
      let objRuta_Anterior={
        ruta_origen:'/directorio',
        ruta_destino:'/detalleLeadSucursal',
        nombre:'Directorio'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
      //redireccionamiento a detalle sucursal
      this.router.navigate(['/detalleLeadSucursal'], { queryParams: {  intId_Direccion: item.intId_Direccion , strCliente: strCliente } } );
    } else { }
    
  }

  fnSeleccionarCliente(x:any){
    //borramos todo antes de seleccionar un cliente
    
    this.carroservice.setModoPlantilla(false);
    this.carroservice.deleteCarrito();
 
      x.ClienteSeleccionado =true;
      this.carroservice.setCte(x);

      //seleccionamos el cliente también para comodatos
      this.commodatoservice.setCte(x)
      console.log(x) 
  }

  appliFilterListadoFamilia(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datafamilias.filter = filterValue.trim().toLowerCase();
    if (this.datafamilias.paginator) {
      this.datafamilias.paginator.firstPage();
    }
  }
  applyFilterprospectos(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataProspectos.filter = filterValue.trim().toLowerCase();
    if (this.dataProspectos.paginator) {
      this.dataProspectos.paginator.firstPage();
    }
  }

  applyFilterContacto(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceContacto.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceContacto.paginator) {
      this.dataSourceContacto.paginator.firstPage();
    }
  }

/*   onCheckboxChange(index: number, element: any,val:any) {
    let originalValue = element.intPreciosInferioresMinimo;
    console.log("Valor Original: "+originalValue);
    console.log("Elemento:", element.intPreciosInferioresMinimo);
    if(this.boolCheck){
      Swal.fire({
        title: "Cambio de estatus",
        text: (val ==1 || val == true? "¿Estas seguro de asignarle precios inferior al minimo":"¿Estas seguro de quitarle precios inferior al minimo")+" al cliente:"+ element.strNombre+" ?",
        showDenyButton: true,
        showCancelButton: false,
        icon:"info",
        confirmButtonText: "Guardar",
        denyButtonText: `Cancelar`,
        allowOutsideClick: false, 
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Guardado correctamente!", "", "success");
          element.intPreciosInferioresMinimo = val==true?false:true;
        } else if (result.isDenied) {
          element.intPreciosInferioresMinimo = originalValue;
        }
      });
    }else{
      console.log("originalValue: "+originalValue)
      element.intPreciosInferioresMinimo = originalValue;
      Swal.fire("Error", "No cuentas con permisos para activar esta acción", "error");
    }
  }
 */
  changebox(event: any, index: number, element: any) {
    let originalValue = element.intPreciosInferioresMinimo;
    let newValue = event.target.checked; // Obtiene el valor del checkbox después del cambio
  console.log("new:"+newValue)
    if (this.boolCheck) {
      Swal.fire({
        title: "Cambio de estatus",
        text: (newValue ? "¿Estás seguro de asignarle precios inferior al mínimo" : "¿Estás seguro de quitarle precios inferior al mínimo") + " al cliente: " + element.strNombre + " ?",
        showDenyButton: true,
        icon: "info",
        confirmButtonText: "Guardar",
        denyButtonText: `Cancelar`,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {




          let obj = {
            strAccion: 'setPrecioMinimo',
            strCliente:element.strCliente,
            intPreciosInferioresMinimo:newValue==true?1:0,
            strConexion: this.strConexion
          }
          console.log(obj)
          this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Directorio')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => { 
              console.log(result)
             let rest = result[0]
              Swal.fire({
                title:rest.code == 200?"Guardado correctamente!":"Error al guardar",
                icon:"success",
                text:newValue==true?rest.strMensaje:"Se quito permiso correctamente",
                showConfirmButton:false,
                timer:2000,
                timerProgressBar:true
              });
              setTimeout(() => {
                element.intPreciosInferioresMinimo = newValue==true?1:0;
                event.target.checked = newValue; // Fuerza la actualización visual
              }, 0);
              
            })
        } else if (result.isDenied) {
          
          setTimeout(() => {
            element.intPreciosInferioresMinimo = originalValue;
            event.target.checked = originalValue; // Fuerza la actualización visual
          }, 0);
        }
      });
    } else {
      setTimeout(() => {
        event.target.checked = originalValue; // Restaura visualmente el valor
      }, 0);
      Swal.fire("Error", "No cuentas con permisos para activar esta acción", "error");
    }
  }
  
}

