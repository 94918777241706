import { Component, OnInit, HostListener} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DataDBService } from '../servicios/data-db.service';
import { Router,ActivatedRoute  } from '@angular/router';
import { Location } from '@angular/common';
import { PlatformLocation } from '@angular/common';
declare var Shepherd: any; 
@Component({
  selector: 'app-cotizador-sub-linea',
  templateUrl: './cotizador-sub-linea.component.html',
  styleUrls: ['./cotizador-sub-linea.component.css']
})
export class CotizadorSubLineaComponent implements OnInit {
public strLinea:string;
public sublineas:any=[];
tour: any; 
public strConexion:string;
  constructor(private location: PlatformLocation,private routEs : ActivatedRoute, private indexedDBService: DataDBService,private router: Router,private localSt: LocalStorageService,private _httpService: PeticionesService) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.routEs.queryParams.subscribe(params => {
      let step = params['addstep']; // 'tour'
      if(step == "linea"){
        setTimeout(() => {
          this.iniciarTour();
        }, 700)
      
      }
      console.log('Viene del tour:', step);
    });

    this.location.onPopState(() => {
      this.cancelarTour();
 
    });
  
   }

   @HostListener('window:beforeunload', ['$event'])
   onBeforeUnload(event: Event) {
     this.cancelarTour();
     console.log('🔄 Recarga detectada');
   }
  ngOnInit(): void {
    
    //------------conexi+on-------------------
    this.indexedDBService.getItem('arrayResult', (value) => {
    this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
            this.strLinea= this.localSt.retrieve('strLinea_Seleccionada');
            this.fnGetData()
            
    });
  })
    
  } 

 
  cancelarTour() {
    if (this.tour) {
      this.tour.cancel();
    }
  }
  fnGetData(){
    let grupos = {
      strAccion: 'getGrupos',
      strCategoria: this.strLinea,
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(grupos).subscribe(
      result => { 
         console.log(result)
        let algo=result
        algo.forEach(element => {
          element.imageUrl='https://www.cleanclean.mx/imagenes/Sublinea/'+element.strGrupo + '.jpg'
        });
        this.sublineas = algo;
     
 
      });
  }
  onCardClick(categoria:string){
    this.localSt.store('strSubLinea_Seleccionada',categoria);
    this.router.navigate(['/cotizador_Articulos']); 
  }

  iniciarTour() {
    this.tour = new Shepherd.Tour({
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: { enabled: true },
        classes: 'shadow-md bg-white rounded custom-tooltip',
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 8,
       
      },
      useModalOverlay: true  // 👈 Esto activa el fondo oscuro
    });
  
 
   this.tour.addStep({
      id: 'AMENIDADES',
      text: 'seleccionamos la categoria.',
      attachTo: {
        element: '#AMENIDADES',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Siguiente',
          action: () => {
            this.tour.hide(); 
            this.localSt.store('strSubLinea_Seleccionada',"AMENIDADES");
            this.router.navigate(['/cotizador_Articulos'], {
              queryParams: { addstep:"sublinea" }
            });
          }
        }
      ]
    });
  
    // Iniciar el tour
   this.tour.start();
  }
  
}
