import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnercteService {

  private cteSeleccionado = new BehaviorSubject<any>(null);
  cteSeleccionado$ = this.cteSeleccionado.asObservable();
  constructor() {
    const item = sessionStorage.getItem('cteSeleccionado');
    if (item) {
      this.cteSeleccionado.next(JSON.parse(item));
    }
   }
  
  setCte(item){
    this.cteSeleccionado.next(item);
    sessionStorage.setItem('cteSeleccionado', JSON.stringify(item));
  }

}
