import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecommerce-ayuda',
  templateUrl: './ecommerce-ayuda.component.html',
  styleUrls: ['./ecommerce-ayuda.component.css']
})
export class EcommerceAyudaComponent implements OnInit {
 // Lista de preguntas frecuentes
 faqs = [

  {
    question: '¿Hay mínimo de compra?',
    answer: 'No hay mínimo, ni en cantidad, ni en precio; tú decides cuánto llevar Puedes comprar desde una pieza.'
  },
  {
    question: '¿Cuáles son los métodos de pago disponibles?',
    answer: 'Aceptamos pagos con tarjetas de crédito, débito, y Aplazo.'
  },

  {
    question: '¿Puedo Facturar mis compras?',
    answer: 'Sí puedes facturar tus compras, hay un apartado para llenar la información de facturación al realizar tu pedido.'+
     'El correo que ingreses será al que llegue tu factura. Asegurate de ingresar correctamente toda tu información.'
  },

  {
    question: '¿Pueden traer mis pedidos a domicilio?',
    answer: 'Tenemos envíos a TODO México, la paquetería que utilizamos actualmente es Estafeta, sin embargo también hacemos entregas con camiones de Ecodeli.'
  },

  {
    question: '¿Tiene Costo de envío?',
    answer: 'Los envíos son GRATIS en compras a partir de los $1000 pesos de compra, de otra forma tienen costo de $99 pesos.'
  },

  {
    question: 'Tengo dudas de los productos',
    answer: 'Con gusto te asesoramos, contáctanos a los números: 477 788 98 00 Ext: 256 y 257, vía whats app al 477 729 6302 ó   escribenos al  correo atencion@ecodeli.com.mx'
  },
  {
    question: 'Tengo un problema con mi compra',
    answer: 'No te preocupes, con gusto te apoyamos, contáctanos a los números: 477 788 98 00 Ext: 256 y 257, vía whats app al 477 729 6302 ó   escribenos al  correo atencion@ecodeli.com.mx'
  },
  {
    question: '¿Hacen cambios y devoluciones?',
    answer: 'Todos nuestros productos cuentan con garantía de cambio, para consultar los detalles revisa nuestras políticas de cambio y devoluciones. En los casos que aplique las devoluciones se entregan como crédito en tienda'
  },
  {
    question: '¿puedo ser distribuidor Ecodeli?',
    answer: 'Claro, envíanos tus datos o contáctanos a los números: 477 788 98 00 Ext: 256 y 257, vía whats app al 477 729 6302 ó   escribenos al  correo atencion@ecodeli.com.mx'
  },
  {
    question: '¿Tienen descuento por mayoreo?',
    answer: 'Sí, cotiza a través de:  477 788 98 00 Ext: 256 y 257, vía whats app al 477 729 6302 ó   escribenos al  correo atencion@ecodeli.com.mx'
  },
  {
    question: '¿Tienen lista de precio?',
    answer: 'Puedes explorar nuestro catálogo o preguntar al: 477 788 98 00 Ext: 256 y 257, vía whats app al 477 729 6302 ó   escribenos al  correo atencion@ecodeli.com.mx'
  },
  {
    question: '¿Tienen catálogo de productos?',
    answer: 'Actualmente nuestro catálogo online cuenta con los productos más actuales y buscados, pero contamos con más de 7,000 productos distintos ¿no lo encuentras en línea? consúltanos: 477 788 98 00 Ext: 256 y 257, vía whats app al 477 729 6302 ó   escribenos al  correo atencion@ecodeli.com.mx'
  },
];

// Índice de la respuesta activa (la respuesta que se muestra)
activeIndex: number = -1;

// Método para alternar la respuesta
toggleAnswer(index: number): void {
  this.activeIndex = this.activeIndex === index ? -1 : index;
}
  constructor() { }

  ngOnInit(): void {
  }

}
