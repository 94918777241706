import { Component, OnInit,HostListener, ElementRef  } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';

import * as XLSX from
'xlsx'
;
@Component({
  selector: 'app-reporte-avance-gerente',
  templateUrl: './reporte-avance-gerente.component.html',
  styleUrls: ['./reporte-avance-gerente.component.css']
})
export class ReporteAvanceGerenteComponent implements OnInit {
  public strConexion:string=""; 
  public arrayGerentes :any ;
  public dblTObjetivo:number = 0;
  public dblTAvance:number= 0;
  public dblTNotaCredito:number= 0;
  public dblTAvanceVentasTotal:number= 0;
  public dblTRemisiones:number= 0;
  public  dblTAvanceInterno:number= 0;
  public dblTMontoInternoDebeLlevar:number= 0;
  public dblTDiferencian:number= 0;
  public dblTPavance:number= 0;
  public dbTlUtilidad:number= 0;
  public dblTPorcentaje:number= 0;
  public dblTPGasto:number= 0;
  public dblTGasto:number= 0;
  public  dblTDiferencia:number= 0;
  public dblTPosibleUtilidad:number= 0;

 
  constructor(private indexedDBService: DataDBService,private _peticionesService: PeticionesService,
    elem:ElementRef,private route: ActivatedRoute,  private router: Router,private localSt:LocalStorageService,private _filtroServ :FiltrosService) { 
      this.strConexion = this.localSt.retrieve('strConexion');
    }

  ngOnInit(): void {
    this.fnGetData()
  }

  fnGetData(){
    this.arrayGerentes=[];
    let objPram = {
      strAccion:'getAvanceMensualGerentes',
      strConexion:this.strConexion
    }
    this._peticionesService.getLineasComodatos(objPram,'sp_ModVentas_Reportes').subscribe(
      result => {
       
       let algo= result;
       let habiles=algo[0].intHabil
       let recorridos=algo[0].intRecorrido
         console.log(result) ;
         algo.forEach(element => {
          let valida =  this.arrayGerentes.find(o=> o.strGerente ==element.strGerente)
          if(!valida) {
            let arraySucursales = algo.filter(o=> o.strGerente ==element.strGerente)
            
            let dblTObjetivo=0
            let dblTAvance=0;
            let dblTNotaCredito =0;
            let dblTAvanceVentasTotal=0
            let dblTRemisiones=0;
            let dblTMontoInternoDebeLlevar=0;
            let dblTDiferencian=0;
            let dblTPavance=0;
            let dbTlUtilidad=0;
            let dblTPorcentaje=0;
            let dblTPGasto=0;
            let dblTGasto=0;
            let dblTDiferencia=0;
            let dblTPosibleUtilidad=0;
            arraySucursales.forEach(element2 => {
              dblTObjetivo=dblTObjetivo + element2.dblObjetivo;
              dblTAvance=dblTAvance+element2.dblAvance;
              dblTNotaCredito=dblTNotaCredito+element2.dblNotaCredito;
              dblTAvanceVentasTotal=dblTAvanceVentasTotal+element2.dblAvanceVentasTotal
              dblTRemisiones=dblTRemisiones + element2.dblRemisiones;
              dblTMontoInternoDebeLlevar=dblTMontoInternoDebeLlevar + element2.dblMontoInternoDebeLlevar;
              dblTDiferencian=dblTDiferencian+element2.dblDiferencian;
              dblTPavance=dblTPavance + element2.dblPavance;
              dbTlUtilidad=dbTlUtilidad + element2.dblUtilidad;
              dblTPorcentaje=dblTPorcentaje + element2.dblPorcentaje;
              dblTPGasto=dblTPGasto + element2.dblPGasto;
               dblTGasto=dblTGasto +element2.dblGasto ;
               dblTDiferencia=dblTDiferencia+element2.dblDiferencia;
               dblTPosibleUtilidad=dblTPosibleUtilidad + element2.dblPosibleUtilidad;
            });
            element.arraySucursales = arraySucursales;
            element.dblTObjetivo=dblTObjetivo;
            element.dblTAvance=dblTAvance;
            element.dblTNotaCredito=dblTNotaCredito;
            element.dblTAvanceVentasTotal=dblTAvanceVentasTotal;
            element.dblTRemisiones=dblTRemisiones;
            element.dblTAvanceInterno=(element.dblTAvanceVentasTotal /dblTObjetivo)*100;

            element.dblTMontoInternoDebeLlevar=dblTMontoInternoDebeLlevar;
            element.dblTDiferencian=dblTDiferencian;
            element.dblTPavance=(dblTAvanceVentasTotal / (dblTObjetivo /habiles * recorridos)) * 100;

            element.dbTlUtilidad=dbTlUtilidad;

            element.dblTPorcentaje=(dbTlUtilidad / dblTAvance) *100;

            element.dblTPGasto=dblTPGasto;
            element.dblTGasto=dblTGasto;
            element.dblTDiferencia=dblTDiferencia;
            element.dblTPosibleUtilidad=dblTPosibleUtilidad;

            this.arrayGerentes.push(element)
          }
          
         });
         console.log(this.arrayGerentes)
         this.arrayGerentes.forEach(element => {
          this.dblTObjetivo = this.dblTObjetivo + element.dblTObjetivo;
          this.dblTAvance = this.dblTAvance + element.dblTAvance
          this.dblTNotaCredito = this.dblTNotaCredito + element.dblTNotaCredito
          this.dblTAvanceVentasTotal = this.dblTAvanceVentasTotal  + element.dblTAvanceVentasTotal
          this.dblTRemisiones =   this.dblTRemisiones + element.dblTRemisiones

          this.dblTAvanceInterno = (this.dblTAvanceVentasTotal / this.dblTObjetivo )*100

          this.dblTMontoInternoDebeLlevar = this.dblTMontoInternoDebeLlevar + element.dblTMontoInternoDebeLlevar

          this.dblTDiferencian =  this.dblTDiferencian  + element.dblTDiferencian

          this.dblTPavance =   (this.dblTAvanceVentasTotal / (this.dblTObjetivo /habiles * recorridos)) * 100;

          this.dbTlUtilidad =  this.dbTlUtilidad  + element.dbTlUtilidad
          this.dblTPorcentaje =  (this.dbTlUtilidad / this.dblTAvance) *100;
          this.dblTPGasto = this.dblTPGasto + element.dblTPGasto
          this.dblTGasto =   this.dblTGasto + element.dblTGasto
          this.dblTDiferencia =  this.dblTDiferencia + element.dblTDiferencia
          this.dblTPosibleUtilidad =  this.dblTPosibleUtilidad  + element.dblTPosibleUtilidad

         })
    
        
      },error => {
        console.log("Error en: "+ error)
      }
    ); 
  }
  exportToExcel(){
    let tableId='myTable';
    let fileName='tabla.xlsx';
    const ws = XLSX.utils.table_to_sheet(document.getElementById(tableId));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }
}
 