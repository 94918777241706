import { Component, OnInit , AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { PeticionesService } from '../servicios/httpservice';
@Component({
  selector: 'app-ecommerce-finpedido',
  templateUrl: './ecommerce-finpedido.component.html',
  styleUrls: ['./ecommerce-finpedido.component.css']
})
export class EcommerceFinpedidoComponent implements OnInit, AfterViewInit {

  public intPedido:number=91080866157;
  public strFechaPedido:string="11/09/2024";
  public strNombreCte:string="";
  private secretKey ='ece1122';
  //private public_key:string='TEST-2e4c3c6b-f817-4fac-a957-aefb90e4848c';
  /* datos de envio */
  public strNombre_e:string="";
  public strApellidos_e:string="";
  public strColonia_e:string="";
  public strCiudad_e:string="";
  public strCodigoPostal_e:string="";
  public strEstado_e:string="";
  public strTelefono_e:string="";
  public strDireccion_e:string="";
  public strEmail_e:string="";

  /* Datos de facturacion */
  public boolFacturacion:boolean=false;
  public strNombre_f:string="";
  public strApellidos_f:string="";
  public strColonia_f:string="";
  public strCiudad_f:string="";
  public strCodigoPostal_f:string="";
  public strUsoCfdi_f:string="";
  public strRFC_f:string="";
  public strRazonSocial_f:string="";
  public strEstado_f:string="";
  public strTelefono_f:string="";
  public strDireccion_f:string="";
  public strEmail_f:string="";
  public dblPrecioSub:number=0;
  public dblTotal:number=0;
  public dblDescuentoSub:number=0;
  public genDatos:any={};
  public dblDescuentoCup:number=0;
  public dblCostoEnvio:number=0;
  public dblImpuesto:number=0;
  private idEncriptado:string;
  private id:string;
  public arrayResult:any=[];
  public arrayProductos:any = [];
  public strConexion:string="COMERCIAL"
  public strFormaPago:string="";
  
  constructor(private _httpService:PeticionesService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.idEncriptado = params['id'];
      if (this.idEncriptado) {
        this.id = this.desencriptarParametro(this.idEncriptado);
        console.log("ID desencriptado:", this.id);
      }
    });
    this.fnGetDatos()
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
    this.fnSendinBlue();
    }, 500);
  }
  fnSendinBlue(){
    let to = this.strEmail_e;//"viviana_espinosa12@hotmail.com";
    let name =  this.strNombre_e + ' '+ this.strApellidos_e;
    //let content ="Hola";
    const content = document.getElementById('email-content')?.innerHTML || '';
    console.log(name,to)
    this._httpService.sendinblue(to,name ,content).subscribe(
      result => {  
        console.log('Resultado de la API:', result);
      });
  }
  fnGetDatos(){
    this.arrayProductos= [];
    this.arrayResult = [];
    let objEnviar={
      strConexion: this.strConexion,
      strAccion:'getResumenPedido_MP',
      intID_Pedido:this.id
    }

    this._httpService.getEcodeli(objEnviar,'sp_eCommerce').subscribe(
      result => {  
        console.log('Resultado de la API:', result);
        this.arrayResult = result
        this.dblPrecioSub = 0;
        this.dblImpuesto = 0;
        this.dblTotal = 0
        this.arrayResult.forEach(art => {
        this.arrayProductos.push(
            {
              strArticulo:art.strArticulo,
              urlImagen:art.strOpcion !='' ? "https://www.cleanclean.mx/imagenes/"+art.strArticulo+"-"+art.strOpcion+".JPG":"https://www.cleanclean.mx/imagenes/"+art.strArticulo+".JPG",
              urlImagen_principal:"https://www.cleanclean.mx/imagenes/"+art.strArticulo+".JPG",
              strDescripcion:art.strDescripcion1,
              opcion:art.strOpcion,
              strNombreOpcion:art.strNombreOpcion,
              strProducto:art.strArticulo,
              dblPrecio:art.dblPrecioLista,
              dblCantidad:art.dblCantidad,
              strUnidad:art.strUnidad,
              dblImporte:art.dblImporte
            })
            this.dblPrecioSub = this.dblPrecioSub + art.dblPrecioLista;
        });

        this.genDatos = this.arrayResult[0]
        this.dblTotal = this.genDatos.dblImporte
        this.dblImpuesto = this.dblTotal - this.dblPrecioSub
        this.strNombre_e = this.genDatos.strNombre;
        this.strApellidos_e = this.genDatos.strApellidos;
        this.strNombreCte=this.genDatos.strNombre + " "+this.genDatos.strApellidos ;
        this.strColonia_e = this.genDatos.strColonia;
        this.strCiudad_e = this.genDatos.strCiudad;
        this.strCodigoPostal_e = this.genDatos.strCodigoPostal;
        this.strEstado_e = this.genDatos.strEstado;
        this.strTelefono_e = this.genDatos.strTelefono;
        this.strDireccion_e = this.genDatos.strDireccion; // Usando strDireccion_f ya que no existe strDireccion en el objeto
        this.strEmail_e = this.genDatos.strCorreo;
        this.strFechaPedido = this.genDatos.strFecha;
        this.intPedido = parseInt(this.id, 10)
        if(this.genDatos.strRFC_f && this.genDatos.strRFC_f != "" && this.genDatos.strRFC_f != null && this.genDatos.strRFC_f != undefined ){
          this.boolFacturacion = true
          this.strNombre_f = this.genDatos.strNombre_f;
          this.strApellidos_f = this.genDatos.strApellidos_f;
          this.strColonia_f = this.genDatos.strColonia_f;
          this.strCiudad_f = this.genDatos.strCiudad_f;
          this.strCodigoPostal_f = this.genDatos.strCodigoPostal_f;
          this.strEstado_f = this.genDatos.strEstado_f;
          this.strTelefono_f = this.genDatos.strTelefono;
          this.strDireccion_f = this.genDatos.strDireccion_f;
          this.strEmail_f = this.genDatos.strCorreo;
          this.strFormaPago = this.genDatos.strMetodoPago_f

          this.strUsoCfdi_f = this.genDatos.strUsoCfdi_f;
          this.strRFC_f=this.genDatos.strRFC_f
          this.strRazonSocial_f= this.genDatos.strRazonSocial_f;
        }else{
          this.boolFacturacion = false
        }

        let arrayArticulos:any=[];
        this.arrayProductos.forEach(art => {
          arrayArticulos.push(
            {
              strArticulo:art.strArticulo,
              nombre:art.strDescripcion,
              cantidad:art.dblCantidad,
              precio:art.dblImporte,
              urlImagen:"https://www.cleanclean.mx/imagenes/"+art.strArticulo+".JPG",
              opcion:art.strOpcion,
            })
        });
        let objEmail = {
          pedidoId:this.id,
          clienteNombre:this.strNombre_e+" "+this.strApellidos_e,
          clienteEmail:this.strEmail_e,
          articulos:arrayArticulos,
          total:this.dblTotal,
        }
        console.log("Objeto email: ",objEmail)
       // this.fnSendinBlue();
      });
  }

  onImageError(event: any, art:any) { 
    event.target.src = art.opcion !=''?art.urlImagen_principal : 'assets/detoportunidad/noDisponible.jpg'; // Ruta de tu imagen local en los assets
   // this.objArticulo.strUrlImg1 =this.strOpcion_Selected !=''?this.objArticulo.strUrlImg_principal: 'assets/detoportunidad/noDisponible.jpg';
  
  }

  desencriptarParametro(idEncriptado: string): string {
    const bytes = CryptoJS.AES.decrypt(idEncriptado, this.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  enviarPedido(pedido:any) {
    this._httpService.sendEmail(pedido).subscribe(
      response => {
        console.log('Respuesta del servidor:', response);
      },
      error => {
        console.error('Error al enviar el pedido:', error);
      }
    );
  }
}
