<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold titulo text-decoration-underline">Alta de Cliente</h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
                <mat-select [(ngModel)]="strGerente" class="">
                    <mat-option value=""> --Todos --</mat-option>
                    <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
            [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                <mat-select [(ngModel)]="strSucursal" class="">
                    <mat-option value=""> --Todas --</mat-option>
                    <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente "
                        [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
            [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
                <mat-select [(ngModel)]="strAgente" class="">
                    <mat-option value=""> --Todos --</mat-option>
                    <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
            [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
                <mat-select [(ngModel)]="intPeriodo" class="">
                    <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"
            [ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
            [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }">
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fngetSolicitud_Alta_Cte()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4" [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div class="row my-4 justify-content-center">
        <div class="col-12 d-flex">
            <div class="dropdown ms-auto">
                <button class="btn dropdown-toggle" style="background-color: #a4b3d4;color: #444b5c; font-weight: 500;"
                    type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Proceso de Altas <i class="bi bi-person-check mx-2 fs-5"></i>
                </button>
                <ul class="dropdown-menu menuProcesoAlta py-0">
                    <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc"
                        (click)="fnEnviarAltaCliente()">
                        <a class="dropdown-item  d-flex align-items-center justify-content-center opc">
                            <span>Alta Clientes Contado / Credito</span>
                            <i class="bi bi-cash ms-auto"></i>
                        </a>
                    </li>
                    <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc"
                        data-bs-toggle="modal" data-bs-target="#actualizacionCredito" (click)="fnLimpiarObjDetalleSolicitud()">
                        <a class="dropdown-item d-flex align-items-center justify-content-center opc">
                            <span>Actualización de Credito</span>
                            <i class="bi bi-card-checklist ms-auto"></i>
                        </a>

                    </li>
                    <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc"
                        (click)="fnVer_Editar_Solicitud({}, 'ACTUALIZAR_CREDITO')">
                        <a class="dropdown-item d-flex align-items-center justify-content-center opc">
                            <span>Solicitud de Credito</span>
                            <i class="bi bi-arrow-repeat ms-auto"></i>
                        </a>

                    </li>
                    <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc"
                    (click)="fnVer_Editar_Solicitud({}, 'DATOS_GENERALES')">
                        <a class="dropdown-item d-flex align-items-center justify-content-center opc">
                            <span>Actualización Datos Generales</span>
                            <i class="bi bi-person-fill-check ms-auto"></i>
                        </a>
                    </li>
                    <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc">
                        <a class="dropdown-item d-flex align-items-center justify-content-center opc" data-bs-toggle="modal" data-bs-target="#ModalAltaSuc" (click)="fnMdlAltaSuc()">
                            <span>Alta Sucursal Cliente</span>
                            <i class="bi bi-building-add ms-auto"></i>
                        </a>
                    </li>
                  <!--   <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc">
                        <a class="dropdown-item d-flex align-items-center justify-content-center opc">
                            <span>Alta Regla Negocio</span>
                            <i class="bi bi-card-checklist ms-auto"></i>
                        </a>
                    </li> -->
                    <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc">
                        <button class="dropdown-item d-flex align-items-center justify-content-center opc" data-bs-toggle="modal" data-bs-target="#mdlIvaFronterisa">
                            <span>Solicitud IVA Fronteriza</span>
                            <i class="bi bi-shield-check ms-auto"></i>
                        </button>
                    </li>
                    <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc">
                        <a class="dropdown-item d-flex align-items-center justify-content-center opc" data-bs-toggle="modal" data-bs-target="#mdlCambioDom" (click)="fnMdlAltaSuc();">
                            <span>Solicitud Cambio Domicilio Fiscal</span>
                            <i class="bi bi-geo-alt-fill ms-auto"></i>
                        </a>
                    </li>
                  <!--   <li class="d-flex align-items-center justify-content-center border-bottom p-1 opc">
                        <a class="dropdown-item d-flex align-items-center justify-content-center opc">
                            <span>Convenio Familia</span>
                            <i class="bi bi-person-lines-fill ms-auto"></i>
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
    <div class="row my-4 justify-content-center">
        <div class="row bgtable_2 bordertop">
            <div class="col-6 col-md-4 my-2">
                <input type="text" class="form-control" style="height:43px ;" [(ngModel)]="inputSearch"
                    placeholder="Buscar: Cliente,Nombre">
            </div>
            <div class="col-6 col-md-4 my-2">
                <mat-form-field appearance="outline" class="bg-white rounded ">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Estatus</mat-label>
                    <mat-select [(ngModel)]="strEstatus" class="">
                        <!-- (selectionChange)="fnGetPlantillas()" (selectionChange)="fngetSolicitud_Alta_Cte()" -->
                        <mat-option *ngFor="let x of arrayEstatus" [value]="x.strEstatus">{{x.strEstatus}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-2 my-2">
                <mat-form-field appearance="outline" class="bg-white rounded ">
                    <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Tipo Solicitud</mat-label>
                    <mat-select [(ngModel)]="strTipo" class=""> <!-- (selectionChange)="fnGetPlantillas()" -->
                        <mat-option value=""> --Todos --</mat-option>
                        <mat-option *ngFor="let x of arrayTipo" [value]="x">{{x}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 bg-white" *ngIf="spiner">
            <mat-spinner class="mx-auto"></mat-spinner>
        </div>
        <div class="col-12 table-responsive mx-0 px-0 mb-0 border" *ngIf="!spiner">
            <table class="table table-bordered table-hover bg-white">
                <thead class="">
                    <tr class="thcenter bgtable sticky-top">
                        <th>
                            <span>ID</span>
                        </th>
                        <th>
                            <span>Cliente</span>
                        </th>
                        <th>
                            <span>Fecha</span>
                        </th>
                        <th>
                            <span>Estatus</span>
                        </th>
                        <th>
                            <span>Condición</span>
                        </th>
                        <th>
                            <span>Tipo Solicitud</span>
                        </th>

                        <th>
                            <span>Agente</span>
                        </th>
                        <th>
                            <span>Semaforo</span>
                        </th>
                        <!-- <th>
                            <span>
                            </span>
                        </th>-->
                        <th *ngIf="strEstatus=='POR AUTORIZAR' || strEstatus=='RECHAZADO'">
                            <span>
                            </span>
                        </th> 
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngIf="(arrayCotizaciones | filtersearch: inputSearch : ['strNombreCliente','strCliente','intID'] | filtersearch: strEstatus : ['strEstatus'] | filtersearch: strTipo : ['strTipoSolicitud']).length === 0">
                        <td colspan="10" class="text-center fw-bold">
                            SIN DATOS
                        </td>
                    </tr>
                    <tr class="tdcenter" *ngFor="let cot of arrayCotizaciones | 
                     filtersearch: inputSearch : ['strNombreCliente','strCliente','intID','strNombreComercial' ]|
                     filtersearch: strEstatus : ['strEstatus'] |
                     filtersearch: strTipo : ['strTipoSolicitud'] let ind = index;"
                        [class.table-hover]="!cot.mostrarDetalle">
                        <td (click)="fnGetDetalle(cot,1)">
                            <span class="fs12 fw-bold">
                                {{cot.intID}}
                            </span> 
                        </td><!--data-bs-toggle="modal" data-bs-target="#mdlAutorizar"-->
                        <td class="text-start " *ngIf="cot.strTipoSolicitud == 'ACTUALIZACION CREDITO'" data-bs-toggle="modal" data-bs-target="#actualizacionCredito"
                        (click)="fngetDetalleSolicitud(cot.intID)">
                            <span class="fs12 cursor" *ngIf="cot.strCliente">({{cot.strCliente}})</span>
                            <span class="fs12 mx-2 cursor" *ngIf="cot.strNombre != ''">{{cot.strNombre}}</span>
                            <span class="fs12 mx-2 cursor" *ngIf="cot.strNombre == ''">{{cot.strNombre2}}</span>
                        </td>
                        <td class="text-start cursor" (click)="fnVer_Editar_Solicitud(cot,'VER')" *ngIf="cot.strTipoSolicitud != 'ACTUALIZACION CREDITO'">
                            <span class="fs12 cursor" *ngIf="cot.strCliente">({{cot.strCliente}})</span>
                            <span class="fs12 mx-2" *ngIf="cot.strNombre != ''">{{cot.strNombre}}</span>
                            <span class="fs12 mx-2" *ngIf="cot.strNombre == ''">{{cot.strNombre2}}</span>
                        </td>
                        <td>
                            <span class="text-nowrap fs12">
                                {{cot.strFechaAlta}}
                            </span>
                        </td>
                        <td>
                            <span class="fs12">
                                {{cot.strEstatus}}
                            </span>
                        </td>
                        <td>
                            <span class="fs12">
                                {{cot.strCondicion}}
                            </span>
                        </td>
                        <td class="text-center" (click)="fnGetDetalle(cot,1)">
                            <span class=" fs12 ">
                                {{cot.strTipoSolicitud}}
                            </span>
                        </td>

                        <td class="text-center" (click)="fnGetDetalle(cot,1)">
                            <span class="fs12 ">
                                ({{cot.strAgente}}) .- {{cot.strNombreAgente}}
                            </span>
                        </td>
                        <td class="text-center" (click)="fnGetDetalle(cot,1)">
                            <span class="fw-bold fs12 badge text-bg-info">
                                {{cot.intDias}}
                            </span>
                        </td>
                       <!--  <td> <span *ngIf="cot.strEstatus=='POR AUTORIZAR'"
                                class="btn btn-outline-primary fs12 p-1 shadow-sm text-nowrap"
                                (click)="fnVer_Editar_Solicitud(cot,'EDITAR')"> <i
                                    class="bi  bi-pencil-square  fs12 "></i> Editar</span>
                            <span *ngIf="cot.strEstatus=='AUTORIZADO' && cot.strTipo=='CLIENTE'"
                                class="btn btn-success fs12 p-1 shadow-sm text-nowrap" (click)="fnGetDetalle(cot,3)"> <i
                                    class="bi  bi-send  fs12 "></i> Enviar a pedido</span>
                            <span *ngIf="cot.strEstatus=='RECHAZADO'" (click)="fnGetDetalle(cot,1)"
                                class="btn btn-danger fs12 p-1 shadow-sm text-nowrap"> <i class="bi  bi-x  fs12 "></i>
                                Corregir</span>
                        </td>-->
                        <td *ngIf="cot.strEstatus=='POR AUTORIZAR'  || strEstatus=='RECHAZADO'" >
                            <button *ngIf="cot.strTipoSolicitud =='ALTA_CREDITO' || cot.strTipoSolicitud =='CONTADO A CREDITO'" type="button" class="btn btn-outline-primary" (click)="fnVer_Editar_Solicitud(cot,'EDITAR')"><i class="fa-solid fa-pen-to-square"></i></button>
                        </td> 
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 px-0 borderbott bgtable text-end py-2">

        </div>
    </div>
</div> 
<!-- Modal -->
<div class="modal fade" id="mdlAutorizar" tabindex="-1" aria-labelledby="mdlAutorizarLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
            <div class="modal-header p-2 bgModal">
                <h2 class="modal-title mb-0 mx-auto text-white text-decoration-underline" id="mdlAutorizarLabel">DETALLE
                    DE PLANTILLA </h2>
                <button type="button" class="btn-close ms-0 " data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-evenly align-items-start">
                    <div class="col-12 col-lg-9 shadow-sm borderbott bordertop py-2 bg-light">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Cliente</span>
                                    <span type="text" class="form-control fs12 d-flex " placeholder="Cliente"
                                        aria-label="Username" aria-describedby="basic-addon1">
                                        <span class="text-primary">{{objCte.strCliente}} </span><span> -
                                            {{objCte.strNombreCliente}}</span>
                                    </span>
                                    <span class="input-group-text"><i class="bi bi-person-video2"></i></span>
                                </div>
                                <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Fecha Inicio</span>
                                    <input readonly type="text" class="form-control fs12 inputbg"
                                        [value]="objCte.strFecha" placeholder="01/01/2024" aria-label="Username"
                                        aria-describedby="basic-addon1">
                                    <span class="input-group-text"><i class="bi bi-calendar-check"></i></span>
                                </div>
                                <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Vendedor Solicitante</span>
                                    <input readonly type="text" class="form-control fs12 inputbg"
                                        [value]="objCte.strNombreMostrar" placeholder="Irma Frausto"
                                        aria-label="Username" aria-describedby="basic-addon1">
                                    <span class="input-group-text"><i class="bi bi-person-check"></i></span>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Moneda</span>
                                    <input type="text" class="form-control fs12 inputbg" [value]="objCte.strMoneda"
                                        placeholder="pesos" aria-label="Username" aria-describedby="basic-addon1"
                                        readonly>
                                    <span class="input-group-text"><i class="bi bi-coin"></i></span>
                                </div>
                                <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Familia</span>
                                    <input readonly type="text" class="form-control fs12 inputbg" placeholder=""
                                        [value]="objCte.strFamilia" aria-label="Username"
                                        aria-describedby="basic-addon1">
                                    <span class="input-group-text"><i class="bi bi-people"></i></span>
                                </div>
                                <div class="input-group mb-2">
                                    <span class="input-group-text fs12" id="basic-addon1">Giro</span>
                                    <input type="text" class="form-control fs12 inputbg" [value]="objCte.strGiro"
                                        placeholder="Manufactura" aria-label="Username" aria-describedby="basic-addon1"
                                        readonly>
                                    <span class="input-group-text"><i class="bi bi-bank"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <textarea name="" class="form-control inputbg" id="" [value]="objCte.strObservaciones"
                                    rows="3" readonly></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-2 shadow-sm borderbott bordertop py-2 bg-light">
                        <div class="row">
                            <div class="col-12 text-center">
                                <h3 class="mb-0">ESTATUS DE PRECIOS</h3>
                                <hr class="mt-1">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 align-self-start">
                                <span class="text-nowrap"><span
                                        class="bg-success rounded-circle text-white py-1 px-2">1</span> Mayor a precio
                                    2</span>
                                <hr class="mt-1">
                            </div>
                            <div class="col-12 align-self-center">
                                <span class="text-nowrap"><span
                                        class="clsPrecio3r rounded-circle text-white py-1 px-2">2</span> Mayor a precio
                                    3</span>
                                <hr class="mt-1">
                            </div>
                            <div class="col-12 align-self-end">
                                <span class="text-nowrap"><span
                                        class="bg-danger rounded-circle text-white py-1 px-2">3</span> Menor a precio
                                    3</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12 text-center"
                                *ngIf="objCte.strEstatus == 'RECHAZADO' || objCte.strEstatus == 'POR AUTORIZAR'">
                                <h4>GUARDAR TODO</h4>
                            </div>
                            <div class="col-12 text-center"
                                *ngIf="objCte.strEstatus == 'RECHAZADO' || objCte.strEstatus == 'POR AUTORIZAR'">
                                <button class="btn bg-white shadow-sm rounded-pill"
                                    (click)=" fnUpatePreciosPlantilla(objCte.intIDMovimientoVenta)">
                                    <i class="bi bi-arrow-repeat  fs-5 align-middle clsIcon"
                                        style="font-weight: 800;"></i> Actualizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 bg-white" *ngIf="spinerMdl">
                    <mat-spinner class="mx-auto"></mat-spinner>
                </div>

                <div class="row my-3" *ngIf="!spinerMdl">
                    <div class="col-12 table-responsive bordertop borderbott shadow-sm px-0">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="fsthead">
                                    <th>Clave</th>

                                    <th>Vol.</th>
                                    <th>Ultimo Precio</th>
                                    <th>Precio Lista</th>
                                    <th>Precio 2</th>
                                    <th>Precio 3</th>
                                    <th>Precio 4</th>
                                    <th>Precio 10</th>
                                    <th>Costo Promedio</th>
                                    <th>Margen</th>
                                    <th>Costo Teorico</th>
                                    <th>Margen CT</th>
                                    <th>Precio Politica</th>
                                    <th>Precio Plantilla</th>
                                    <th class="text-nowrap">Precio Sugerido</th>
                                    <th class="text-nowrap">% Inc.</th>
                                    <th *ngIf="arrayDetPlantillas.length>1"></th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of  arrayDetPlantillas">
                                    <td class="fw-bold fs12">{{x.strArticulo}} <span
                                            class="fw-normal fs10">-{{x.strDescripcion1}} - </span><span
                                            class="fw-bold">{{x.strOpcion}}</span></td>

                                    <td class="fw-bold fs12">{{x.dblCantidad}}</td>
                                    <td class="fw-bold fs12">{{x.dblUltimoPrecio|currency}}</td>
                                    <td class="fw-bold fs12"
                                        [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2 ,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}">
                                        {{x.dblPrecioLista|currency}}</td>
                                    <td class="fw-bold fs12"
                                        [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}">
                                        {{x.dblPrecio2|currency}}</td>
                                    <td class="fw-bold fs12"
                                        [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}">
                                        {{x.dblPrecio3|currency}}</td>
                                    <td class="fw-bold fs12"
                                        [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}">
                                        {{x.dblPrecio4|currency}}</td>
                                    <td class="fw-bold fs12"
                                        [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}">
                                        {{x.dblPrecio10|currency}}</td>
                                    <td class="fw-bold fs12">{{x.dblCostoPromedio|currency}}</td>
                                    <td class="fw-bold fs12"> {{(x.dblPrecioSugerido> 0
                                        ?((1-(x.dblCostoPromedio/(x.dblPrecioSugerido*(objCte.strMoneda.toUpperCase()=="DOLARES"?x.dblTipoCambio:1))))*
                                        100):0)| number:'1.2-2'}}%</td>
                                    <td class="fw-bold fs12">{{x.dblCostoTeorico|currency}}</td>
                                    <td class="fw-bold fs12">{{(x.dblPrecioSugerido> 0 ?
                                        ((1-(x.dblCostoTeorico/x.dblPrecioSugerido))*100):0) | number:'1.2-2'}}%</td>
                                    <td class="fw-bold fs12"
                                        [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}">
                                        {{x.dblPrecioPolitica|currency}}</td>
                                    <td class="fw-bold fs12"
                                        [ngClass]="{'text-success':x.dblPrecioSugerido>x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 &&x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}">
                                        {{x.dblPrecioPlantilla|currency}}</td>
                                    <td>
                                        <input [disabled]="objCte.strEstatus == 'AUTORIZADO'" type="number"
                                            class="form-control fs12" [(ngModel)]="x.dblPrecioSugerido "
                                            [ngClass]="{'text-success':x.dblPrecioSugerido> x.dblPrecio2,'clsPrecio3':x.dblPrecioSugerido>=x.dblPrecio3 && x.dblPrecioSugerido<x.dblPrecio2,'text-danger':x.dblPrecioSugerido<x.dblPrecio3}">
                                    </td>
                                    <td class="fw-bold fs12">{{(x.dblUltimoPrecio>0?((x.dblPrecioSugerido
                                        /x.dblUltimoPrecio)-1)*100:0) |number:'1.2-2'}}%</td>
                                    <td *ngIf="arrayDetPlantillas.length>1"><i class="bi bi-trash3 text-danger fs-5"
                                            (click)="fnEliminarPartida(objCte.intIDMovimientoVenta,x)"></i></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer p-2 bgModal">
                <div class="row w-100">
                    <div class="col-4">
                        <button type="button" class="btn btn-secondary shadow-sm" data-bs-dismiss="modal"
                            #btncerrar>Cerrar</button>
                    </div>
                    <div class="col-8 d-flex justify-content-evenly" *ngIf="strTipo_plantilla=='CLIENTE'">
                        <button type="button" class="btn btn-success shadow-sm d-flex"
                            *ngIf="objCte.strEstatus == 'RECHAZADO'"
                            (click)="fnReenviarSolicitud(objCte.intIDMovimientoVenta)"><i
                                class="bi bi-send d-lg-none"></i> <span class="d-none d-lg-block">Enviar</span>
                            Solicitud</button>
                        <button type="button" class="btn btn-success shadow-sm d-flex"
                            *ngIf="objCte.strEstatus == 'POR AUTORIZAR'"
                            (click)="fnSetEstatusPlantilla('AUTORIZADO',objCte.intIDMovimientoVenta,objCte.strObservaciones,objCte.strCliente)"><i
                                class="bi bi-upload d-lg-none"></i> <span class="d-none d-lg-block">Exportar a</span>
                            Intelisis</button>
                        <button type="button" class="btn btn-danger shadow-sm d-flex"
                            *ngIf="objCte.strEstatus == 'POR AUTORIZAR'"
                            (click)="fnSetEstatusPlantilla('RECHAZADO',objCte.intIDMovimientoVenta,objCte.strObservaciones,objCte.strCliente)"><i
                                class="bi bi-x-circle d-lg-none"></i> <span class="d-none d-lg-block">Rechazar</span>
                            Plantilla</button>
                    </div>
                    <div class="col-8 d-flex justify-content-evenly"
                        *ngIf="strTipo_plantilla=='LEADS' || strTipo_plantilla=='OPORTUNIDAD'">

                        <button type="button" class="btn btn-success shadow-sm d-flex"
                            *ngIf="objCte.strEstatus == 'POR AUTORIZAR'"
                            (click)="fnSetEstatusCotizacion('AUTORIZADO',objCte.intIDMovimientoVenta,objCte.strObservaciones,objCte.strCliente)"><i
                                class="bi bi-upload d-lg-none"></i> <span class="d-none d-lg-block">Autorizar
                                Cotización</span> </button>
                        <button type="button" class="btn btn-danger shadow-sm d-flex"
                            *ngIf="objCte.strEstatus == 'POR AUTORIZAR'"
                            (click)="fnSetEstatusCotizacion('RECHAZADO',objCte.intIDMovimientoVenta,objCte.strObservaciones,objCte.strCliente)"><i
                                class="bi bi-x-circle d-lg-none"></i> <span class="d-none d-lg-block">Rechazar
                                Cotización</span> </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="actualizacionCredito" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header d-flex">
                <h3 class="modal-title fs-5 m-auto" id="exampleModalLabel">SOLICITUD DE CREDITO</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="objDetalleSolcitud.length == 0">
                    <div class="col-12 text-center datosFiscales d-flex justify-content-center align-items-center">
                        <h3 class="fw-bold">Datos - Fiscales</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-evenly align-items-center mt-3">
                        <span>No. de Cliente</span> 
                        <input [(ngModel)]="strCliente" type="text" class="form-control w-50" placeholder="Ingresa numero de cliente" *ngIf="objDetalleSolcitud.length == 0"> 
                        <input [disabled]="true" [(ngModel)]="objDetalleSolcitud[0].strCliente" type="text" class="form-control w-50" placeholder="Ingresa numero de cliente" *ngIf="objDetalleSolcitud.length > 0"> 
                        <button [disabled]="strCliente == ''" class="btn btn-primary" (click)="fngetCte_Update()">Filtrar</button>
                    </div>

                </div>
                <div class="row d-flex mt-4">
                    <div class="col-12 mb-4 text-center datosFiscales d-flex justify-content-center align-items-center">
                        <h3 class="fw-bold">Datos Actuales - Clientes con Credito</h3>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Cliente:</label>
                            <input [readonly]="true" type="text" class="form-control" [(ngModel)]="arrayActualizacionCredito.strCliente" *ngIf="objDetalleSolcitud.length == 0">
                            <input [disabled]="true" type="text" class="form-control" [(ngModel)]="objDetalleSolcitud[0].strCliente" *ngIf="objDetalleSolcitud.length > 0">
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Nombre:</label>
                            <input [readonly]="true" type="text" class="form-control" [(ngModel)]="arrayActualizacionCredito.strNombreComercial" *ngIf="objDetalleSolcitud.length == 0">
                            <input [disabled]="true" type="text" class="form-control" [(ngModel)]="objDetalleSolcitud[0].strNombreComercial" *ngIf="objDetalleSolcitud.length > 0">
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Credito Actual:</label>
                            <input readonly type="text" class="form-control" [(ngModel)]="arrayActualizacionCredito.dblCreditoLimite" *ngIf="objDetalleSolcitud.length == 0">
                            <input [disabled]="true" type="text" class="form-control" [(ngModel)]="objDetalleSolcitud[0].dblCreditoLimite_Actual" *ngIf="objDetalleSolcitud.length > 0">
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Actualizar Limite de Credito:</label>
                            <input type="text" [disabled]="!banderaBotonEnviarSolcitud" class="form-control" [(ngModel)]="dblLimiteCreditoActualizado" *ngIf="objDetalleSolcitud.length == 0">
                            <input type="text" [disabled]="true" class="form-control" [(ngModel)]="objDetalleSolcitud[0].dblCreditoLimite" *ngIf="objDetalleSolcitud.length > 0">
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Condición Actual:</label>
                            <select [disabled]="true" class="form-select" *ngIf="objDetalleSolcitud.length == 0"
                                [(ngModel)]="arrayActualizacionCredito.strCondicionPago">
                                <option [value]="v.strCondicionPago" *ngFor="let v of arrayCondicionPago">
                                    {{v.strCondicionPago}} </option>
                            </select>
                            <select [disabled]="true" class="form-select" *ngIf="objDetalleSolcitud.length > 0"
                                [(ngModel)]="objDetalleSolcitud[0].strCondicion_Actual">
                                <option [value]="v.strCondicionPago" *ngFor="let v of arrayCondicionPago">
                                    {{v.strCondicionPago}} </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mb-2 m-auto">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Actualizar Condición de pago:</label>
                            <select [disabled]="!banderaBotonEnviarSolcitud" class="form-select"  [(ngModel)]="strCondicionModificada" *ngIf="objDetalleSolcitud.length == 0">
                                <option [value]="v.strCondicionPago" *ngFor="let v of arrayCondicionPago">
                                    {{v.strCondicionPago}} </option>
                            </select>
                            <select [disabled]="true" class="form-select"  [(ngModel)]="objDetalleSolcitud[0].strCondicion" *ngIf="objDetalleSolcitud.length > 0">
                                <option [value]="v.strCondicionPago" *ngFor="let v of arrayCondicionPago">
                                    {{v.strCondicionPago}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-8 m-auto d-flex mb-2">
                        <div class="input-with-label" *ngIf="objDetalleSolcitud.length == 0">
                            <label for="nombre" class="corner-label">Pagare:</label>
                            <input C type="file" class="form-control" (change)="compressFile($event)">
                        </div>
                        <a class="btn btn-outline-secondary m-auto" *ngIf="objDetalleSolcitud.length > 0 && (objDetalleSolcitud[0].strUrl_Pagare != 'undefined' || objDetalleSolcitud[0].strUrl_Pagare != '')">
                            Ver Anexo
                        </a>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex">
                <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cerrar</button>
                <button *ngIf="objDetalleSolcitud.length == 0" type="button" class="btn btn-primary ms-auto" data-bs-dismiss="modal" (click)="subirDocumento()"
                    [disabled]="!banderaBotonEnviarSolcitud">Enviar Solicitud</button>
            </div>
        </div>
    </div>
</div>

<!-- modal alta sucursal -->
 <!-- Modal -->
<div class="modal fade" id="ModalAltaSuc" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header clsModal">
          <h3 class="modal-title mb-0 fw-bold" id="exampleModalLabel">Alta Sucursal de Cliente</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="max-height: 60vh;overflow-y: auto;">
            <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center mb-2">
                    <mat-form-field appearance="outline" >
                      <mat-label>Clave del Cliente</mat-label>
                      <input matInput [(ngModel)]="strCte" (input)="onInput2($event)">
                      <mat-icon matSuffix [ngClass]="{'text-success':boolCteExiste}">account_circle</mat-icon>
                    </mat-form-field>
                    <button class="py-1 px-2 btn btn-primary ms-3 d-flex align-items-center" (click)="fnValidarSuc()">
                        <i class="bi bi-funnel-fill" ></i> *Filtrar
                    </button>
                </div>
                <div class="col-12 col-md-6 my-2">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Agente</mat-label>
                      <input matInput  [value]="objAgenSuc?.strNombre" >
                      <mat-icon matSuffix [ngClass]="{'text-success': objAgenSuc?.strNombre}">person</mat-icon>
                    </mat-form-field>
                  </div>
            </div>
            
          <form [formGroup]="form" >

            <div class="row">
                <div class="col-12 col-md-6 my-2">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Nombre Sucursal</mat-label>
                      <input matInput formControlName="sucursal">
                      <mat-icon matSuffix [ngClass]="{'text-success': !form.get('sucursal').hasError('required')}">location_on</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 my-2">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Cliente</mat-label>
                      <input class="fw-bold" matInput formControlName="nombreCte" readonly>
                      <mat-icon matSuffix [ngClass]="{'text-success':boolCteExiste}">verified_user</mat-icon>
                    </mat-form-field>
                </div>
              
              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>C.P.</mat-label>
                  <input matInput formControlName="cp" (input)="onInput($event)"  (keydown.enter)="fnGetDatosFiscales()">
                  <mat-icon matSuffix class="mouseHov" [ngClass]="{'text-success': !form.get('cp').hasError('required')}" (click)="fnGetDatosFiscales()">mail</mat-icon>
                </mat-form-field>
              </div>

              

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Estado</mat-label>
                  <input matInput formControlName="estado" readonly>
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('estado').hasError('required')}">place</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Almacen</mat-label>
                  <input matInput formControlName="almacen" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('almacen').hasError('required')}">streetview</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Delegación</mat-label>
                  <input matInput formControlName="delegacion" readonly>
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('delegacion').hasError('required')}">location_city</mat-icon>
                </mat-form-field>
              </div>

            <!--  <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Poblacion</mat-label>
                  <input matInput formControlName="poblacion" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('poblacion').hasError('required')}">home</mat-icon>
                </mat-form-field>
              </div> -->

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Colonia</mat-label>
                  <select matNativeControl formControlName="colonia" (change)="onSelectColonias()">
                    <option value="" selected>-Seleccione Colonia-</option>
                    <option value="{{col.strColonia}}" *ngFor="let col of arrayColonias">{{col.strColonia}}</option>
                    <option value="otra" selected>Otra</option>
                  </select>
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('colonia').hasError('required')}">home</mat-icon>

                </mat-form-field> 
                <input type="text" formControlName="strColoniaOtra" placeholder="Escriba otra colonia" class="form-control mt-2" *ngIf="form.get('colonia').value =='otra'">
                <mat-error *ngIf="form.get('strColoniaOtra').hasError('required')" class="fs10">Campo Obligatorio</mat-error>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>No. Exterior</mat-label>
                  <input matInput formControlName="noExterior" class="no-spinners" type="text" maxlength="20">
                  <mat-icon matSuffix  [ngClass]="{'text-success': !form.get('noExterior').hasError('required')}">format_list_numbered</mat-icon>
                </mat-form-field>
              </div> 
              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>No. Interior</mat-label>
                  <input matInput formControlName="noInterior" class="no-spinners" type="text" maxlength="20">
                  <mat-icon matSuffix  [ngClass]="{'text-success': !form.get('noInterior').hasError('required')}">format_list_numbered</mat-icon>
                </mat-form-field>
              </div> 

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Calle</mat-label>
                  <input matInput formControlName="calle" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('calle').hasError('required')}">streetview</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Entre Calles</mat-label>
                  <input matInput formControlName="entreCalles" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('entreCalles').hasError('required')}">compare_arrows</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Encargado</mat-label>
                  <input matInput formControlName="encargado" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('encargado').hasError('required')}">supervisor_account</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Teléfono</mat-label>
                  <input type="number" matInput formControlName="telefono" class="no-spinners" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('telefono').invalid }">call</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Correo Electrónico</mat-label>
                  <input matInput formControlName="correo" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('correo').invalid}">email</mat-icon>
                </mat-form-field>
              </div>

            </div>
          </form>
        </div>
        <div class="modal-footer clsModal">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarAltaSuc>Cancelar</button>
          <button type="button" class="btn btn-primary" [disabled]="form.invalid || !boolCteExiste" (click)="onSubmit()">Guardar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="mdlIvaFronterisa" tabindex="-1" aria-labelledby="mdlIvaFronterisaLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header ">
          <h2 class="modal-title mb-0 mx-auto text-center" id="mdlIvaFronterisaLabel">Solicitud IVA Fronteriza</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 d-flex mb-2" *ngIf="boolCteExiste">
                    <span class="shadow-sm d-flex flex-column col-12 text-center clsModal1  " >Nombre Cliente: <span class="fw-bold">{{objClienteValid?.strNombreComercial}}</span></span>
                </div>
                <div class="col-12  d-flex align-items-center mb-2">

                    <mat-form-field appearance="outline" >
                      <mat-label>Clave del Cliente</mat-label>
                      <input matInput [(ngModel)]="strCte" (input)="onInput2($event)">
                      <mat-icon matSuffix [ngClass]="{'text-success':boolCteExiste}">account_circle</mat-icon>
                    </mat-form-field>
                    <button class="py-1 px-2 btn btn-primary ms-3 d-flex align-items-center" (click)="fnValidarSuc()">
                        <i class="bi bi-funnel-fill" ></i> Filtrar
                    </button>

                </div>
            </div>
            <div class="row my-3" *ngIf="boolCteExiste == false">
                <div class="col-12 text-center">
                    <span class="p-3 form-control is-yellow text-warning" >
                        <i class="bi bi-exclamation-triangle-fill text-warning"></i> 
                        Por favor, inserte un cliente válido.
                    </span>
                </div>
            </div>
            <div class="row my-3" *ngIf="boolCteExiste != false">
                <div class="col-12 d-flex flex-column">
                    <span class="fw-bold mx-auto">Documento de certificado ante el SAT</span>
                    <button class="btn btn-secondary mx-auto text-decoration-underline" (click)="fnClickFile()">Click aqui</button>
                    <span *ngIf="nameFileIvaSat" class="fw-bold  mt-2 form-control text-center" >Archivo: <span>{{nameFileIvaSat?.name}}</span></span>
                    <input type="file" name="" #fileIvaSat (change)="onFileSelected($event)" class="form-control d-none" id="" >
                </div>
            </div>
        </div>
        <div class="modal-footer ">
          <button type="button" class="btn btn-secondary" #btnDocIVa data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" [disabled]="!nameFileIvaSat" (click)="fnEnviarDoc();">Guardar</button>
        </div>
      </div>
    </div>
  </div>



  <!-- modal alta sucursal -->
 <!-- Modal -->
<div class="modal fade" id="mdlCambioDom" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header clsModal">
          <h3 class="modal-title mb-0 fw-bold mx-auto" id="exampleModalLabel">Solicitud Cambio Domicilio Fiscal</h3>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="max-height: 65vh;overflow-y: auto;">
            <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center mb-2">
                    <mat-form-field appearance="outline" >
                      <mat-label>Clave del Cliente</mat-label>
                      <input matInput [(ngModel)]="strCte" (input)="onInput2($event)">
                      <mat-icon matSuffix [ngClass]="{'text-success':boolCteExiste}">account_circle</mat-icon>
                    </mat-form-field>
                    <button class="py-1 px-2 btn btn-primary ms-3 d-flex align-items-center" (click)="fnValidarSuc()">
                        <i class="bi bi-funnel-fill" ></i> Filtrar
                    </button>
                </div>
                <div class="col-12 col-md-6 my-2">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Agente</mat-label>
                      <input matInput  [value]="objAgenSuc?.strNombre" >
                      <mat-icon matSuffix [ngClass]="{'text-success': objAgenSuc?.strNombre}">person</mat-icon>
                    </mat-form-field>
                  </div>
            </div>
            
          <form [formGroup]="formDomFiscal" >

            <div class="row">
             <!--    <div class="col-12 col-md-6 my-2">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Nombre Sucursal</mat-label>
                      <input matInput formControlName="sucursal">
                      <mat-icon matSuffix [ngClass]="{'text-success': !form.get('sucursal').hasError('required')}">location_on</mat-icon>
                    </mat-form-field>
                </div> -->
                <div class="col-12 col-md-6 my-2">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Cliente</mat-label>
                      <input class="fw-bold" matInput formControlName="nombreCte" readonly>
                      <mat-icon matSuffix [ngClass]="{'text-success':boolCteExiste}">verified_user</mat-icon>
                    </mat-form-field>
                </div>
              
              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>C.P.</mat-label>
                  <input matInput formControlName="cp" (input)="onInput($event)"  (keydown.enter)="fnGetDatosFiscales(1)">
                  <mat-icon matSuffix class="mouseHov" [ngClass]="{'text-success': !formDomFiscal.get('cp').hasError('required')}" (click)="fnGetDatosFiscales(1)">mail</mat-icon>
                </mat-form-field>
              </div>

              

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Estado</mat-label>
                  <input matInput formControlName="estado" readonly>
                  <mat-icon matSuffix [ngClass]="{'text-success': !formDomFiscal.get('estado').hasError('required')}">place</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Almacen</mat-label>
                  <input matInput formControlName="almacen" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !formDomFiscal.get('almacen').hasError('required')}">streetview</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Delegación</mat-label>
                  <input matInput formControlName="delegacion" readonly>
                  <mat-icon matSuffix [ngClass]="{'text-success': !formDomFiscal.get('delegacion').hasError('required')}">location_city</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Poblacion</mat-label>
                  <input matInput formControlName="poblacion" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !formDomFiscal.get('poblacion').hasError('required')}">home</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Colonia</mat-label>
                  <select matNativeControl formControlName="colonia">
                    <option value="" selected>-Seleccione Colonia-</option>
                    <option value="{{col.strColonia}}" *ngFor="let col of arrayColonias">{{col.strColonia}}</option>
                  </select>
                  <mat-icon matSuffix [ngClass]="{'text-success': !formDomFiscal.get('colonia').hasError('required')}">home</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>No. Exterior</mat-label>
                  <input matInput formControlName="noExterior" class="no-spinners" type="number">
                  <mat-icon matSuffix  [ngClass]="{'text-success': !formDomFiscal.get('noExterior').hasError('required')}">format_list_numbered</mat-icon>
                </mat-form-field>
              </div> 

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Calle</mat-label>
                  <input matInput formControlName="calle" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !formDomFiscal.get('calle').hasError('required')}">streetview</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Entre Calles</mat-label>
                  <input matInput formControlName="entreCalles" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !formDomFiscal.get('entreCalles').hasError('required')}">compare_arrows</mat-icon>
                </mat-form-field>
              </div>

              <!-- <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Encargado</mat-label>
                  <input matInput formControlName="encargado" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('encargado').hasError('required')}">supervisor_account</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Teléfono</mat-label>
                  <input type="number" matInput formControlName="telefono" class="no-spinners" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('telefono').hasError('required')}">call</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-6 my-2">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Correo Electrónico</mat-label>
                  <input matInput formControlName="correo" >
                  <mat-icon matSuffix [ngClass]="{'text-success': !form.get('correo').hasError('email') && !form.get('correo').hasError('required')}">email</mat-icon>
                </mat-form-field>
              </div> -->

            </div>
          </form>
          <div class="row">
            <div class="col-12 col-md-6 d-flex">
                <span class="col-11 rounded clsModal2 text-center shadow-sm d-flex flex-column py-2">
                    <span class="fw-bold">Constancia de situación fiscal</span> 
                    <button class="btn col-7 mx-auto fs12  shdow-sm d-flex align-items-center justify-content-center " [ngClass]="{'btnArch':strSitFiscal=='Subir Archivo','btn-success':strSitFiscal!='Subir Archivo'}"  (click)="fnClickSitfsical()">
                        {{strSitFiscal}} <i class="bi bi-check-circle text-white fs-5" *ngIf="boolSitFiscal"></i>
                    </button>
                    <input type="file"  (input)="onFileSitFiscal($event)" #docSitFiscal name="" id="" class="d-none">
                </span>
              
            </div>
            <div class="col-12 col-md-6 d-flex">
                <span class="col-11 rounded clsModal2 text-center shadow-sm d-flex flex-column py-2">
                   <span class="fw-bold">Domicilio</span> 
                   <button class="btn  col-7 mx-auto fs12 shdow-sm d-flex align-items-center justify-content-center" [ngClass]="{'btnArch':strDomicilio=='Subir Archivo','btn-success':strDomicilio!='Subir Archivo'}" (click)="fnClickDomicilio()">
                    {{strDomicilio}} <i class="bi bi-check-circle text-white fs-5" *ngIf="boolDomicilio"></i>
                   </button> 
                    <input type="file"  (input)="onFileDimicilio($event)" #docDomicilio name="" id="" class="d-none">
                </span>
              
            </div>
          </div>
        </div>
        <div class="modal-footer clsModal">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarSitDoc>Cancelar</button>
          <button type="button" class="btn btn-primary" [disabled]="formDomFiscal.invalid ||!boolCteExiste || !boolSitFiscal|| !boolDomicilio " (click)="fnEnviarSolDomicilio()">Guardar</button>
        </div>
      </div>
    </div>
  </div>