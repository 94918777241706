<!--<app-menu></app-menu>-->
<br><br>
<div class="container">
    <div class="row seccion">
        <div class="col-12">
            <mat-tab-group mat-align-tabs="start" class="bodyTab" (selectedTabChange)="yourFn($event)">
                <mat-tab label="Objetivo Actual"> <br><br>

                    <h1 class="title">Objetivos Mix<span class="title2"> Divisional</span></h1>
                    <br>
                    <div class="cien">
                        <div class="col-sm-3">
                            <div class="widget widget-rojo" *ngIf="strEstatusActual=='RECHAZADO'">
                                <span class="material-icons">assignment_late</span>
                                <p>RECHAZADO</p>
                            </div>
                            <div class="widget widget-verde" *ngIf="strEstatusActual=='AUTORIZADO'">
                                <span class="material-icons">assignment_turned_in</span>
                                <p>AUTORIZADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatusActual=='ASIGNADO'">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatusActual=='PENDIENTE'">
                                <span class="material-icons">assignment_returned</span>
                                <p>PENDIENTE</p>
                            </div>
                        </div>

                        <div class="col-sm-5 ">
                            <div class="widget widget-observaciones">
                                <span class="material-icons">
                                    description
                                </span>
                                <p>Observaciones: {{strObservaciones}} </p>
                            </div>
                        </div>
                    </div>

                    <!--Estatus = {{strEstatus}} Observaciones={{strObservaciones}}-->
                    <br><br>
                    <div class="row">
                        <mat-form-field appearance="fill" class="matcell col-3">
                            <mat-label>Objetivo Asignado </mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoA | currency}}" disabled="true" name="first1">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="matcell col-3">
                            <mat-label>Objetivo Cubierto</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoG | currency}}" disabled="true" name="first2">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="matcell col-3">
                            <mat-label>Diferencia de Objetivos</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoD | currency}}" disabled="true" name="first3">
                        </mat-form-field>

                    </div>
                    <br><br><br>
                    <div class="row">
                        <div class="col contenedor_tabla">
                            <div class="divYs">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="headcolS encabezado">Linea</th>
                                            <th scope="col" class="headcol22  encabezado">%Ideal Nacional</th>
                                            <th scope="col" class="subject encabezado">%6 Meses</th>
                                            <th scope="col" class="subject encabezado">%Sucursales</th>
                                            <th scope="col" class="subject encabezado">Objetivo Capturado</th>
                                            <th scope="col" class="subject encabezado" *ngFor="let item of arrayEncabezado; ">
                                                {{item.strMes}} </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of arrayHistorico; index as i; ">
                                            <th class="headcolS panel">{{item.strLinea}}</th>
                                            <td class="headcol22 " style="    color: #ffff;
                                            font-weight: 500;
                                            text-align: center;
                                            background-color: #5e6472;">{{item.dblPObjetivo*100|number:'1.1-2'}}%

                                            </td>
                                            <td class="cuerpo1">
                                                <span *ngIf="item.dblPromedio<item.dblPObjetivo" style="color: red;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                                <span *ngIf="item.dblPromedio >=item.dblPObjetivo" style="color: green;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td class=" ">
                                                <span [ngClass]="{'text-success':item.dblPAgente >=item.dblPObjetivo || item.dblPAgente >=item.dblPromedio,'text-danger':item.dblPAgente <item.dblPObjetivo || item.dblPAgente <item.dblPromedio}">{{item.dblPAgente*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td>{{item.dblObjetivo| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblEnero">{{item.dblEnero| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblFebrero">{{item.dblFebrero| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblMarzo">{{item.dblMarzo| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblAbril">{{item.dblAbril| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblMayo">{{item.dblMayo| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblJunio">{{item.dblJunio| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblJulio">{{item.dblJulio| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblAgosto">{{item.dblAgosto| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblSeptiembre">{{item.dblSeptiembre| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblOctubre">{{item.dblOctubre| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblNoviembre">{{item.dblNoviembre| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblDiciembre">{{item.dblDiciembre| currency}}</td>

                                        </tr>
                                        <tr>
                                            <th class="headcolS pie1"> </th>
                                            <th class="headcol22 pie1"> 100%</th>
                                            <!-- <th class="headcol3 pie1"> Autorizar/Rechazar</th>-->
                                            <td class="pie1"> </td>
                                            <td class="pie1"> </td>
                                            <td class="pie1"> {{dblObjetivoG | currency}}</td>
                                            <td class="pie1" *ngFor="let item of arrayEncabezado; ">{{ item.total| currency}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>


                </mat-tab>
                <mat-tab label="Objetivo Siguiente Mes"> <br><br>

                    <h1 class="title">Objetivos Mix<span class="title2"> Divisional </span></h1>
                    <br>
                    <div class="row d-flex justify-content-between ">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="widget widget-rojo" *ngIf="strEstatus=='RECHAZADO'">
                                <span class="material-icons">assignment_late</span>
                                <p>RECHAZADO</p>
                            </div>
                            <div class="widget widget-verde" *ngIf="strEstatus=='AUTORIZADO'">
                                <span class="material-icons">assignment_turned_in</span>
                                <p>AUTORIZADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatus=='ASIGNADO'">
                                <span class="material-icons">pending</span>
                                <p>ASIGNADO</p>
                            </div>
                            <div class="widget widget-azul" *ngIf="strEstatus=='PENDIENTE'">
                                <span class="material-icons">assignment_returned</span>
                                <p>PENDIENTE</p>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="widget widget-observaciones">
                                <span class="material-icons">
                                    description
                                </span>
                                <p>Observaciones: {{strObservaciones}} </p>
                            </div>
                        </div>
                       
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 float-end">
                            <table class="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Total Sucursales</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of arrayEstatusSucursales">
                                        <th scope="row">{{e.intTotal}}</th>
                                        <td>
                                            <span *ngIf="e.strEstatus=='ASIGNADO'" class="material-icons texto-azul-e" title="ASIGNADO">pending</span>
                                            <span *ngIf="e.strEstatus=='PENDIENTE'" class="material-icons texto-azul-e" title="PENDIENTE">assignment_returned</span>
                                            <span *ngIf="e.strEstatus=='AUTORIZADO'" class="material-icons texto-verde-e" title="AUTORIZADO">assignment_turned_in</span>
                                            <span *ngIf="e.strEstatus=='RECHAZADO'" class="material-icons texto-rojo-e" title="RECHAZADO">assignment_late</span>
                                            <!--{{item.strEstatus}}-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!--Estatus = {{strEstatus}} Observaciones={{strObservaciones}}-->
                    <br><br>
                    <div class="row">
                        <mat-form-field appearance="fill" class="matcell col-3">
                            <mat-label>Objetivo Asignado </mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoA | currency}}" disabled="true" name="first1">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="matcell col-3">
                            <mat-label>Objetivo Cubierto</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoG | currency}}" disabled="true" name="first2">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="matcell col-3">
                            <mat-label>Diferencia de Objetivos</mat-label>
                            <input matInput placeholder="0" value="{{dblObjetivoD | currency}}" disabled="true" name="first3">
                        </mat-form-field>

                        <button *ngIf="(strEstatus!='PENDIENTE' &&  strEstatus !='') && (totalSucursalesAut==totalSucursales)" class="col-3" mat-raised-button style="background-color: #3e6294;color:white;height: 50px;margin-top: -7px;" (click)="fnGuardar()" [disabled]="btnGuardarD">Guardar </button>
                    </div>
                    <br><br><br>
                    <div class="row">
                        <div class="col contenedor_tabla">
                            <div class="divYs">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="headcolS encabezado">Linea</th>
                                            <th scope="col" class="headcol22  encabezado">%Ideal Nacional</th>
                                            <th scope="col" class="subject encabezado">%6 Meses</th>
                                            <th scope="col" class="subject encabezado">%Sucursales</th>
                                            <th scope="col" class="subject encabezado">Objetivo Capturado</th>
                                            <th scope="col" class="subject encabezado" *ngFor="let item of arrayEncabezado; ">
                                                {{item.strMes}} </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of arrayHistorico; index as i; ">
                                            <th class="headcolS panel">{{item.strLinea}}</th>
                                            <td class="headcol22 " style="    color: #ffff;
                                            font-weight: 500;
                                            text-align: center;
                                            background-color: #5e6472;">{{item.dblPObjetivo*100|number:'1.1-2'}}%

                                            </td>
                                            <td class="cuerpo1">
                                                <span *ngIf="item.dblPromedio<item.dblPObjetivo" style="color: red;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                                <span *ngIf="item.dblPromedio >=item.dblPObjetivo" style="color: green;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td class=" ">
                                                <span [ngClass]="{'text-success':item.dblPAgente >=item.dblPObjetivo || item.dblPAgente >=item.dblPromedio,'text-danger':item.dblPAgente <item.dblPObjetivo || item.dblPAgente <item.dblPromedio}">{{item.dblPAgente*100|number:'1.1-2'}}%</span>
                                            </td>
                                            <td>{{item.dblObjetivo| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblEnero">{{item.dblEnero| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblFebrero">{{item.dblFebrero| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblMarzo">{{item.dblMarzo| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblAbril">{{item.dblAbril| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblMayo">{{item.dblMayo| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblJunio">{{item.dblJunio| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblJulio">{{item.dblJulio| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblAgosto">{{item.dblAgosto| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblSeptiembre">{{item.dblSeptiembre| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblOctubre">{{item.dblOctubre| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblNoviembre">{{item.dblNoviembre| currency}}</td>
                                            <td class="cuerpo1" *ngIf="item.dblDiciembre">{{item.dblDiciembre| currency}}</td>

                                        </tr>
                                        <tr>
                                            <th class="headcolS pie1"> </th>
                                            <th class="headcol22 pie1"> 100%</th>
                                            <!-- <th class="headcol3 pie1"> Autorizar/Rechazar</th>-->
                                            <td class="pie1"> </td>
                                            <td class="pie1"> </td>
                                            <td class="pie1"> {{dblObjetivoG | currency}}</td>
                                            <td class="pie1" *ngFor="let item of arrayEncabezado; ">{{ item.total| currency}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>


                </mat-tab>
                <mat-tab label="Objetivo Sucursales">
                    <br><br>
                    <h1 class="title">Capturar <span class="title2">Objetivo</span> por <span class="title2"> Sucursales </span></h1>
                    <br><br>
                    <div class="row seccion2">
                        <form class="col-12 example-form input100">
                            <div class="col-sm-4">
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>Objetivo Divisional</mat-label>
                                    <input matInput [value]="dblObjetivoA|currency ">
                                </mat-form-field>
                            </div> 
                            <div class="col-sm-4">
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>Objetivo Asignado</mat-label>
                                    <input matInput [value]=" dblObjetivoAA|currency">
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="example-full-width " appearance="fill">
                                    <mat-label>Falta por Asignar</mat-label>
                                    <input matInput [value]=" dblObjetivoAD|currency">
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <br><br>
                            <div class="col-sm-6 col-lg-4">
                                <h4>Seleccione Sucursal</h4>
                                <div class="input-group mb-3">
                                    <mat-form-field class=" pe-2 mx-0" style="width: 65%;" appearance="fill">
                                        <mat-label>Seleccione sucursal</mat-label>
                                        <mat-select [(ngModel)]="sucursalSelected">
                                            <!-- (change)="fnFiltrar()"-->
                                            <mat-option *ngFor="let item of arraySucursales" [value]="item">
                                                {{item.strEstatus}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <button mat-raised-button style="background-color:#3e6294;color:white;height:50px;" (click)="fnFiltrar()"  class="px-0 mx-0 w-25">Filtrar</button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <h4>Capture Objetivo para {{sucursalSelected.strEstatus}} </h4>
                                <div class="input-group mb-3" *ngIf="sucursalSelected.strEstatus">
                                    <mat-form-field appearance="fill" class="matcell" *ngIf="dblObjetivoSucursal>0" style="width: 65%;">
                                        <mat-label>Objetivo Propuesto</mat-label>
                                        <!--<input matInput [(ngModel)]="dblObjetivoSucursal" placeholder="0" value="{{dblObjetivoSucursal}}" name="first">-->
                                        <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="dblObjetivoSucursal" (blur)="updateObjetivoSucursal($event)" />
                                    </mat-form-field>

                                    <mat-form-field style="width: 65%;" appearance="fill" class="matcell pe-2" *ngIf="dblObjetivoSucursal<=0 || dblObjetivoSucursal==undefined">
                                        <mat-label>Objetivo Propuesto</mat-label>
                                        <input matInput type="text" mvndrMatCurrencyFormat [allowNegative]="false" [currencyCode]="'USD'" [value]="0" (blur)="updateObjetivoSucursal($event)" />
                                    </mat-form-field>
                                    <button *ngIf="strEstatusS=='ASIGNADO' || !strEstatusS " mat-raised-button style="background-color: #4c586f; margin-top: -6px; color: white;height: 50px; width: 30%;" class="text-start text-md-center" (click)="fnSetObjetivoSuc()">Guardar</button>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="widget widget-rojo" *ngIf="strEstatusS=='RECHAZADO'">
                                    <span class="material-icons">assignment_late</span>
                                    <p>RECHAZADO</p>
                                </div>
                                <div class="widget widget-verde" *ngIf="strEstatusS=='AUTORIZADO'">
                                    <span class="material-icons">assignment_turned_in</span>
                                    <p>AUTORIZADO</p>
                                </div>
                                <div class="widget widget-azul" *ngIf="strEstatusS=='ASIGNADO'">
                                    <span class="material-icons">pending</span>
                                    <p>ASIGNADO</p>
                                </div>
                                <div class="widget widget-azul" *ngIf="strEstatusS=='PENDIENTE'">
                                    <span class="material-icons">assignment_returned</span>
                                    <p>PENDIENTE</p>
                                </div>
                            </div>
                            <br><br>
                        </div>

                        <div class="row" *ngIf='arrayObjetivoCapturadoSuc.length>0'>
                            <br><br>
                            <h1 class="title">Objetivo Capturado <span class="title2">{{sucursalSelected.strEstatus}} </span></h1>
                            <br>
                            <div class="row">
                                <form class="example-form">
                                    <mat-form-field class="example-full-width inputObj col-3" appearance="fill">
                                        <mat-label>Objetivo Asignado</mat-label>
                                        <!--<input [(ngModel)]="dblObjetivoAS" matInput placeholder="0" value="{{dblObjetivoAS|currency}}" name="dos">-->
                                        <input matInput placeholder="0" value="{{dblObjetivoAS|currency}}" name="dos" disabled="true">
                                    </mat-form-field>

                                    <mat-form-field class="example-full-width  inputObj col-3" appearance="fill">
                                        <mat-label>Objetivo Capturado</mat-label>
                                        <!--<input [(ngModel)]="dblObjetivoC" matInput placeholder="0" value="{{dblObjetivoC|currency}}" name="tres">-->
                                        <input matInput placeholder="0" value="{{dblObjetivoC | currency}}" name="tres" disabled="true">
                                    </mat-form-field>

                                    <mat-form-field class="example-full-width inputObj col-3" appearance="fill">
                                        <mat-label>Diferencia de Objetivos</mat-label>
                                        <!--<input [(ngModel)]="dblObjetivoAS-dblObjetivoC" type="number" matInput placeholder="0" value="{{dblObjetivoAS-dblObjetivoC|currency}}" name="cuatro">-->
                                        <input matInput placeholder="0" value="{{(dblObjetivoAS-dblObjetivoC) | currency}}" name="tres" disabled="true">
                                    </mat-form-field>
                                </form>
                            </div>
                            <br>
                            <div class="row mb-3-px">
                                <div class="col-6">
                                    <table class="table ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Linea
                                                </th>
                                                <th scope="col">Objetivo</th>
                                                <th scope="col">%Capturado</th>
                                                <th scope="col">%6 Meses</th>
                                                <th scope="col">%Ideal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of arrayObjetivoCapturadoSuc; index as i; ">
                                                <th scope="row">{{item.strLinea}}</th>
                                                <td>{{item.dblObjetivo|currency}}</td>
                                                <td [ngClass]="{'text-danger':item.dblObjetivo/dblObjetivoC<item.dblPromedio||item.dblObjetivo/dblObjetivoC<item.dblPObjetivo,
                                                'text-success':item.dblObjetivo/dblObjetivoC>=item.dblPromedio||item.dblObjetivo/dblObjetivoC>=item.dblPObjetivo}">
                                                    {{(item.dblPAgente)*100 |number:'1.1-2'}}%
                                                </td>
                                                <td [ngClass]="{'text-danger':item.dblPromedio<item.dblPObjetivo,'text-success':item.dblPromedio>=item.dblPObjetivo}">
                                                    {{item.dblPromedio*100|number:'1.1-2'}}%</td>
                                                <td style="color: #118ce4;
                                                    font-weight: 500;">{{item.dblPObjetivo*100|number:'1.1-2' }}%</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6">
                                    <div class="form-group ">
                                        <label for="exampleFormControlTextarea1">Observaciones</label>
                                        <textarea [(ngModel)]="strObservacionesS" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                    <div class="row">
                                        <button class="col-5" mat-raised-button style="  background-color: #849974; 
                                             color: white;height: 50px;    margin-top: 60px;" (click)="fnAutorizar('AUTORIZADO')" *ngIf="btnAutorizar&&strEstatusS=='PENDIENTE'">Autorizar</button>
                                        <div class="col-2"></div>
                                        <button class="col-5" mat-raised-button style="  background-color: #613a43;  color: white;height: 50px;    margin-top: 60px;" (click)="fnAutorizar('RECHAZADO')" *ngIf="btnRechazar&&strEstatusS=='PENDIENTE'">Rechazar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <br><br><br>
                            <h2 class="title">Objetivos <span class="title2">Asignados</span></h2>
                            <br>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Sucursal</th>
                                        <th scope="col">Objetivo</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayTotalObj">
                                        <th scope="row">{{item.strGerente}}</th>
                                        <td> <button type="button" (click)="fnGetObjSuc(item)" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            {{item.dblObjetivo|currency}}
                                          </button></td>
                                        <td>
                                            <!--{{item.strEstatus}}-->
                                            <span *ngIf="item.strEstatus=='ASIGNADO'" class="material-icons texto-azul-e" title="ASIGNADO">pending</span>
                                            <span *ngIf="item.strEstatus=='PENDIENTE'" class="material-icons texto-azul-e" title="PENDIENTE">assignment_returned</span>
                                            <span *ngIf="item.strEstatus=='AUTORIZADO'" class="material-icons texto-verde-e" title="AUTORIZADO">assignment_turned_in</span>
                                            <span *ngIf="item.strEstatus=='RECHAZADO'" class="material-icons texto-rojo-e" title="RECHAZADO">assignment_late</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br>
                    <!--<div class="row">
                        <div class="col contenedor_tabla">
                            <div class="divYss">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="headcol22S encabezado">Linea</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Enero</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Febrero</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Marzo</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Abril</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Mayo</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Junio</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Julio</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Agosto</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Septiembre</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Octubre</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Noviembre</th>
                                            <th scope="col" class="subject encabezado">%</th>
                                            <th scope="col" class="subject encabezado">Diciembre</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of arrayHistSucursal; index as i; ">
                                            <th class="headcol22S panel">{{item.strLinea}}</th>



                                            <td class="cuerpo3">{{(item.dblEnero/dblTSEnero )|percent}}</td>
                                            <td class="cuerpo1">{{item.dblEnero| currency}}</td>
                                            <td class="cuerpo3">{{item.dblFebrero/dblTSFebrero| percent}}</td>
                                            <td class="cuerpo1">{{item.dblFebrero| currency}}</td>
                                            <td class="cuerpo3">{{item.dblMarzo/dblTSMarzo| percent}}</td>
                                            <td class="cuerpo1">{{item.dblMarzo| currency}}</td>
                                            <td class="cuerpo3">{{item.dblAbril/dblTSAbril| percent}}</td>
                                            <td class="cuerpo1">{{item.dblAbril| currency}}</td>
                                            <td class="cuerpo3">{{item.dblMayo/dblTSMayo| percent}}</td>
                                            <td class="cuerpo1">{{item.dblMayo| currency}}</td>
                                            <td class="cuerpo3">{{item.dblJunio/dblTSJunio| percent}}</td>
                                            <td class="cuerpo1">{{item.dblJunio| currency}}</td>
                                            <td class="cuerpo3">{{item.dblJulio/dblTSJulio| percent}}</td>
                                            <td class="cuerpo1">{{item.dblJulio| currency}}</td>
                                            <td class="cuerpo3">{{item.dblAgosto/dblTSAgosto| percent}}</td>
                                            <td class="cuerpo1">{{item.dblAgosto| currency}}</td>
                                            <td class="cuerpo3">{{item.dblSeptiembre/dblTSSeptiembre| percent}}</td>
                                            <td class="cuerpo1">{{item.dblSeptiembre| currency}}</td>
                                            <td class="cuerpo3">{{item.dblOctubre/dblTSOctubre| percent}}</td>
                                            <td class="cuerpo1">{{item.dblOctubre| currency}}</td>
                                            <td class="cuerpo3">{{item.dblNoviembre/dblTSNoviembre| percent}}</td>
                                            <td class="cuerpo1">{{item.dblNoviembre| currency}}</td>
                                            <td class="cuerpo3">{{item.dblDiciembre/dblTSDiciembre| percent}}</td>
                                            <td class="cuerpo1">{{item.dblDiciembre| currency}}</td>

                                        </tr>
                                        <tr>
                                            <th class="headcol22S pie1" colspan=2>Total</th> 
                    <td class="pie1" colspan=2>{{ dblTSEnero| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSFebrero| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSMarzo| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSAbril| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSMayo| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSJunio| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSJulio| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSAgosto| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSSeptiembre| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSOctubre| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSNoviembre| currency}}</td>
                    <td class="pie1" colspan=2>{{ dblTSDiciembre| currency}}</td>
                    </tr>
                    </tbody>
                    </table>
        </div>

    </div>
</div> -->
                </mat-tab>
            </mat-tab-group>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" id="exampleModalLabel">Sucursales con Objetivos Asignados</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Agente</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Objetivo</th>
                                        <th scope="col">Objetivo Anterior</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayGerentesSuc">
                                        <th scope="row">{{item.strAgente}}</th>
                                        <td>{{item.strLinea}}</td>
                                        <td>{{item.dblObjetivo|currency}}</td>

                                        <td>{{item.dblPObjetivo|currency}}</td>
                                        <td>{{item.strEstatus}}</td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td>Total Asignado:</td>
                                        <td><b>{{ dblTotalAsigSuc|currency}}</b></td>
                                        <td></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<app-footer></app-footer>