import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService} from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';
@Component({
  selector: 'app-ecodeli-premia',
  templateUrl: './ecodeli-premia.component.html',
  styleUrls: ['./ecodeli-premia.component.css']
})
export class EcodeliPremiaComponent implements OnInit {
  public load:boolean = false;
  public boolGerente:boolean = false;
  public boolAgente:boolean = true;
  public boolSucursal:boolean = false;
  public isHoveredButton:boolean = false;

  public strSucursal:string = "";
  public strGerente:string = "";
  public strAgente:string = "";
  public strConexion:string = "";
  public strNombreVendedor:string = "";
  public strTituloModal:string= "";

  public arrayGerentes:any = [];
  public arraySucursales:any = [];
  public arrayAgentes:any = [];
  public arrayProductosFoco:any=[];
  public arrayPreciosArt:any=[];
  public spinerFoco:boolean=false;
  public totalEcomonedasLineas:number = 0
 
  public objDegrapackCliente:any = {};
  public objDegrapackVenta:any = {};
  public objFoaomCliente:any = {};
  public objFoaomVenta:any = {};
  public objQuimicosEcodeliCliente:any = {};
  public objQuimicosEcodeliVenta:any = {};
  public objTorkCliente:any = {};
  public objTorkVenta:any = {};
  public objRubermideCliente:any = {};
  public objRubermideVenta:any = {};
  public objKimberlyCliente:any = {};
  public objKimberlyVenta:any = {};
  public objAccesoriosCliente:any = {};
  public objAccesoriosVenta:any = {};
  public objBonus:any = {};
  public arrayDetArt:any = []

  public objAvanceCte1:any = {};
  public objAvanceCte2:any = {};
  public objAvanceCte3:any = {};
  public objClientesNuevos:any= {
    strTitulo:"",
    strInformacionBono:"",
    obj :{},
    array:[],
    strDescripcion1:"",
    strDescripcion2:""
  };
  public ArrayDatos = [];
  public arrayMostrarModal = [];
  public arrayClientesAtendidos5000 = [];
  public arrayDatosTabla = [];
  public totalEcomonedas = 0;
  public totalPesos = 0;
  public intCte5000:number = 0

  public objdegrapackFoco:any;
  public objFoaomFoco:any;
  public objQuimicosFoco:any;
  public objDoveFoco:any;

  public arrayComisionPF= [];
  public ComisionPF:number = 0;
  public ComisionPFAvance = 0;
  public ComisionPFObjetivo = 0;
  public ComisionPFPorcentaje = 0;
  public dblClientesNuevos:number = 0;
  public ArrayCtsModal = [];
  public ObjCtsModal:any = {};
  public ArrayArtsModal = [];
  public Array5000arrib26 = [];
  public Array7500abaj26 = [];
  public Array5000abaj26 = [];
  public selectLineaArt = "";
  public tagName:any;
  public userhttp:any;
  public Acceso:any;
  public con:any;
  public user:any;
  public intComMay = 0;
  public intComMay26 = 0;
  public intComMen = 0;
  public porcentajeCtes5000 = 0;
  public ObjBonoTriCurrent = {
    intBonoActual:0,
    intPeriodoTriAnt:0,
    intPromedioVenta:0,
    intBonoTri:0,
    intMes1:"",
    intVentaMes1:0,
    intMes2:"",
    intVentaMes2:0,
    intMes3:"",
    intVentaMes3:0,
    intMetaNextBono:0,
    intNextBono:0,
    dblMargenSig:0
  };
  public arrayClientesVisitados:any =[];
  public arrayComisionMayoreo : any =[];
  public arrayComisionMayoreoDet : any =[];
  public arrayAccesos:any = [];
   public arrayInformacionParticipa : any =[
    {
    strLinea:"degrapack",
    strEcomonedas:"Al alcanzar tanto los indicadores de clientes como de ventas, cada $1,000.00 de ventas te otorgarán 3 valiosas Ecomonedas como recompensa.",
    strIndicador1:"Participan los agentes que su objetivo de venta sea mayor a $5,000 MXN",
    //strIndicador2:"Es indispensable cumplir con el objetivo de clientes, de lo contrario no obtendrá EcoMonedas."
  },
    { strLinea:"foaom",
      strEcomonedas:"Al alcanzar tanto los indicadores de clientes como de ventas, cada $1,000.00 de ventas te otorgarán 2 valiosas Ecomonedas como recompensa.",
      strIndicador1:"Los agentes cuyo objetivo sea inferior a $5,000 MXN no estarán habilitados para acceder a la posibilidad de adquirir Ecomonedas",
     // strIndicador2:"Es indispensable cumplir con el objetivo de clientes, de lo contrario no obtendrá EcoMonedas."
    },
    {strLinea:"quimicosEcodeli",
      strEcomonedas:"Al alcanzar tanto los indicadores de clientes como de ventas, cada $1,000.00 de ventas te otorgarán 3 valiosas Ecomonedas como recompensa.",
      strIndicador1:"Aquellos agentes cuyo objetivo sea menor a $15,000 MXN no tendrá acceso a ganar Ecomonedas.",
     // strIndicador2:"Es indispensable cumplir con el objetivo de clientes, de lo contrario no obtendrá EcoMonedas."
    },
    {strLinea:"Tork",
    strEcomonedas:"Al alcanzar tanto los indicadores de clientes como de ventas se te otorgarán 50 valiosas Ecomonedas como recompensa.",
    strIndicador1:"Es indispensable cumplir con el objetivo de clientes para desbloquear el objetivo de venta, de lo contrario no obtendrá EcoMonedas",
   // strIndicador2:""
    },
    {strLinea:"accesorios",
    strEcomonedas:"Al alcanzar tanto los indicadores de clientes como de ventas se te otorgarán 75 valiosas Ecomonedas como recompensa.",
    strIndicador1:"Aquellos agentes cuyo objetivo sea menor al 20% de su mix del monto total  no tendrá acceso a ganar Ecomonedas.",
   // strIndicador2:"Es indispensable cumplir con el objetivo de clientes para desbloquear el objetivo de venta, de lo contrario no obtendrá EcoMonedas"
    },
    {strLinea:"rubbermaid",
    strEcomonedas:"Al alcanzar tanto los indicadores de clientes como de ventas se te otorgarán 75 valiosas Ecomonedas como recompensa.",
    strIndicador1:"Aquellos agentes cuyo objetivo sea menor a $35,000 MXN no tendrá acceso a ganar Ecomonedas.",
   // strIndicador2:"Es indispensable cumplir con el objetivo de clientes, de lo contrario no obtendrá EcoMonedas."
    },
    {
      strLinea:"kimberly",
    strEcomonedas:"Al alcanzar tanto los indicadores de clientes como de ventas se te otorgarán 50 valiosas Ecomonedas como recompensa.",
    strIndicador1:"Es indispensable cumplir con el objetivo de clientes, de lo contrario no obtendrá EcoMonedas",
    //strIndicador2:""
  }
  ];


  constructor(private indexedDBService: DataDBService,private _filtroServ :FiltrosService, private _httpService:PeticionesService, private renderer: Renderer2, private elem: ElementRef,
    private localSt:LocalStorageService,private route: ActivatedRoute,private router: Router,private sanitizer: DomSanitizer) { 
    
      this.route.queryParams.subscribe(params => {
        this.userhttp = params['userhttp'];
        this.Acceso = params['Acceso'];
        this.con = params['method'];
        this.user = params['user'];
        this.boolAgente = params['bolAge']=='1'?true:false ;
        this.boolGerente = params['bolGer']=='1'?true:false;
        this.boolSucursal = params['bolSuc']=='1'?true:false;
        this.strSucursal = params['suc'];
      });
 

    if(this.Acceso == "EcPreVentas"){
      this.strConexion =this.con;
      this.strAgente = this.userhttp;
      //ocultar menu
     //this._httpService.setMostrarMenu(false);
      this.getDatos(3);
    }else{ 
      this.indexedDBService.getItem('arrayResult', (value) => {
        this.strConexion = this.localSt.retrieve('strConexion');
      this.tagName = elem.nativeElement.tagName.toLowerCase();
      this.arrayAccesos = this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
   
      if(this.arrayAccesos.length>0){
        var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      } else {
        this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
       
        this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.strSucursal =!this.boolSucursal  ? this.localSt.retrieve('strSucursal'): '';
        this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
       
        this.strAgente= this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
        this.strGerente =(!this.boolGerente && this.boolSucursal )?this.localSt.retrieve('strUsuario'): ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.getDatos(3);
      }
      }
        
      });
      
    }
    
   
    }

  ngOnInit(): void {
    this.fnGetFiltros(); 
   
  
  }

  fnGetFiltros(){
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });

    if((this.arrayAgentes  && this.arrayAgentes.length>0) &&  (this.strAgente != null ||this.strAgente != "")){
      let objAgente = this.arrayAgentes.find(o => o.strAgente == this.strAgente)
      this.strNombreVendedor =objAgente &&objAgente.strNombre ?objAgente.strNombre  :"";
      this.strSucursal =objAgente &&objAgente.strSucursal ?objAgente.strSucursal  :"";
    }
    
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

 
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    }); 
    if(this.Acceso == "EcPreVentas"){
      this.filtrarPorUsuario();
    }

  }

  filtrarPorUsuario(){

    this.strGerente = this.boolSucursal ?this.user:"";
    this.strSucursal = this.boolAgente ?this.strSucursal :"";


  }

  DatosModal(ctsModal: string,linea:string=""): void {
   this.arrayPreciosArt = []
    this.ArrayCtsModal = [];
    this.ObjCtsModal = {
      arrayLineas: [],
      card: "0",
      totalFacturacion: 0
    };
    this.selectLineaArt = linea;
    if (ctsModal == "card1") {
      this.ObjCtsModal.titulo = "Clientes Atendidos A + $" + this.objAvanceCte1.dblFacturacion.toLocaleString('es-MX', { minimumFractionDigits: 2 });
      this.ObjCtsModal.card = "1";
      this.getCtesModal(this.strAgente, "card1");
    } else if (ctsModal == "card2") {
      this.ObjCtsModal.titulo = "Productos Foco Participantes";
      this.ObjCtsModal.card = "2";
      this.getCtesModal(this.strAgente, "card2");
    } else if (ctsModal == "card3") {
      this.ObjCtsModal.titulo = "Clientes Atendidos A + $5,000.00";
      this.ObjCtsModal.card = "3";
      this.getCtesModal(this.strAgente, "card3");
    } else if (ctsModal == "card4") {
      this.ObjCtsModal.titulo = "Comision Mayoreo";
      this.ObjCtsModal.card = "4";
      this.getCtesModal(this.strAgente, "card4");
    } else if (ctsModal == "card5") {
      this.ObjCtsModal.titulo = "Bono Trimestral";
      this.ObjCtsModal.card = "5";
      this.ObjCtsModal.participa = "Para participar, es necesario contar con un margen mayor o igual al 22% por cliente.";
    } else if (ctsModal == "infoCtes5000") {
      this.ObjCtsModal.titulo = "Clientes con facturación menor a $5,000.00";
      this.ObjCtsModal.card = "infoCtes5000";
      this.getCtesModal(this.strAgente, "infoCtes5000");
    } else if (ctsModal == "infoCtes7500") {
      this.ObjCtsModal.titulo = "Clientes con facturación menor a $7,500.00";
      this.ObjCtsModal.card = "infoCtes7500";
      this.getCtesModal(this.strAgente, "infoCtes7500");
    }
  }





  getCtesModal(agt: string, card: string): void {
    this.ArrayCtsModal = [];
   
 
    if (card == "card1" || card == "card3") {
      let objBono= { strAccion: card == "card1" ? 'getDetalle7500' : 'getDetalle5000', strAgente: agt, "strConexion": this.strConexion };
      this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
        result =>{
          this.ArrayCtsModal = result;
          this.ArrayCtsModal.forEach(obj => {
            if (obj.dblFacturacion) {
              this.ObjCtsModal.totalFacturacion += parseFloat(obj.dblFacturacion);
            }
          });
        
        }, error => {
          console.log("error: ", error);
        });
    } else if (card == "card2") {
      this.spinerFoco = true;
      let objBono: any = { strAccion: 'getDetalleProductoFocO', "strConexion": this.strConexion };
      this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
        result =>{
          this.ArrayCtsModal = result;
          this.ArrayArtsModal = this.ArrayCtsModal;
          this.spinerFoco = true;
          this.fnExtraerPreciosArts()
    /*       this.filtroTabProdFoco(this.selectLineaArt) */
        
        

         /*  this.ObjCtsModal.arrayLineas = [...new Set(this.ArrayCtsModal.map(item => item.strLinea.trim()))];
          console.log(this.ObjCtsModal.arrayLineas);
          this.filtroTabProdFoco(this.selectLineaArt) */
        }, error => {
          console.log("error: ", error);
          this.spinerFoco = false;
        });
    } else if (card == "card4") {
      this.getComisionMayoreoDetalle(agt);
    } else if (card == "infoCtes5000") {
      this.ArrayCtsModal = [];
      let objBono: any = { strAccion: 'getDetalleMenos_5000', strAgente: agt, "strConexion": this.strConexion };
      this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
        result =>{
          this.ArrayCtsModal = result;
          console.log(this.ArrayCtsModal);
        }, error => {
          console.log("error: ", error);
        });
    } else if (card == "infoCtes7500") {
      this.Array5000arrib26 = [];
      this.Array7500abaj26 = [];
      this.Array5000abaj26 = [];
      this.ArrayCtsModal = [];
      let objBono: any = { strAccion: 'getDetalleMenos_7500', strAgente: agt, "strConexion": this.strConexion };
      this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
        result =>{
          this.ArrayCtsModal = result;
          if(this.ArrayCtsModal && this.ArrayCtsModal.length > 0) {
            this.ArrayCtsModal.forEach(obj => {
              if (obj.strLinea == "MAYOR A 5000 CON MARGEN MENOR AL 26%") {
                this.Array5000abaj26.push(obj);
              } else if (obj.strLinea == "MAYOR A 5000 CON MARGEN MAYOR AL 26%") {
                this.Array5000arrib26.push(obj);
              } else if (obj.strLinea == "MAYOR A 7500 CON MARGEN MENOR AL 26%") {
                console.log("ingresa al console de 7500")
                this.Array7500abaj26.push(obj);
              }
            });
          }else{
  
          }
          console.log(this.ArrayCtsModal);
        }, error => {
          console.log("error: ", error);
        });
    }
  }
/*   formatText(text: string): SafeHtml  {
    if (text) {
      const formattedText = text.replace(/(\d+)\s*( valiosas Ecomonedas)/g, '<strong class="fs-4">$1</strong> $2');
      return this.sanitizer.bypassSecurityTrustHtml(formattedText);
    } else {
      return ''; // Otra acción o valor predeterminado si text es undefined
    }
  } */
fnExtraerPreciosArts(){
  this.spinerFoco = true
  this.arrayPreciosArt = [];
  this._httpService.getListaPrecios({
    strAccion: 'get_PreciosArticulos',
    xmlArticulos: this.ArrayArtsModal,
    strConexion: this.strConexion
  }).subscribe(
    result => {
      this.arrayPreciosArt = result;

console.log(this.arrayPreciosArt)
this.spinerFoco = false
    },
    error => {
      var error = <any>error;
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Uuups...',
        text: 'Error en conexion al extraer articulos sin precio lista, intente de nuevo.'
      });
      this.spinerFoco = false
    });
    }
  getComisionMayoreoDetalle(agt: string): void {
    this.intComMay = 0;
    this.intComMen = 0;
    this.intComMay26 = 0;
    this.arrayComisionMayoreoDet = [];
    let objBono: any = { strAccion: 'getComisionMayoreoDetalle', strAgente: agt, "strConexion": this.strConexion };
    this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
      result =>{
        this.arrayComisionMayoreoDet = result;
        console.log(this.arrayComisionMayoreoDet);
        this.arrayComisionMayoreoDet.forEach(obj => {
          if (obj.dblMargen < 12) {
            this.intComMen += obj.dblEcomonedas;
          }
          if (obj.dblMargen >= 12 && obj.dblMargen <26) {
            this.intComMay += obj.dblEcomonedas;
          }
          if (obj.dblMargen >= 26) {
            this.intComMay26 += obj.dblEcomonedas;
          }
        });
      }, error => {
        console.log("error: ", error);
      });
  }

  getBackgroundStyle(): string {
    return `radial-gradient(closest-side, #e5f6ff 88%, transparent 80% 100%), conic-gradient(#49BCF6 ${this.porcentajeCtes5000}%, #e5f6ff 0)`;
  }

  getDatos(lin: any): void {
    this.scrollToMiddle(lin);
  let   myobject = {
      "strAccion": "getEcomonedas",
      "strAgente": this.strAgente,
      "strConexion": this.strConexion
  }
  console.log(myobject)
  this._httpService.mostrarCotizaciones(myobject,'sp_ELITE_EcodeliPremia').subscribe(
    result =>{
          this.ArrayDatos = result;
          this.clientesVisitados(this.strAgente);
         
         this.setDatos();
         this.load = true;
        },
        (error) => {
          console.log("error: ", error);
          this.load = true;
        }
      );
  }

  clientesVisitados(strAgente:string){
    this.arrayClientesVisitados = [];
    this.objAvanceCte1 = {};
    this.objAvanceCte2 = {};
    this.objAvanceCte3 = {};
    let objBono = { strAccion: 'getBono_Cte_Atendidos',
     strAgente: strAgente,"strConexion": this.strConexion};
     this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
      result =>{
         this.arrayClientesVisitados = result;
         this.arrayClientesVisitados.sort((a, b) => a.intMin_cte - b.intMin_cte);

         this.objAvanceCte1 = this.arrayClientesVisitados[0];
         this.objAvanceCte2 = this.arrayClientesVisitados[1];
         this.objAvanceCte3 = this.arrayClientesVisitados[2];
         this.clientesAtendidos5000(this.strAgente);
          },
          (error) => {
            console.log("error: ", error);
            this.load = true;
          }
        );
  }
  clientesAtendidos5000(strAgente:string){
    this.intCte5000 = 0;
    this.porcentajeCtes5000=0;
    this.arrayClientesAtendidos5000 = [];
    let objBono = { strAccion: 'getBono_Cte_Atendidos5000', strAgente: strAgente,"strConexion": this.strConexion};
    this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
      result =>{
        this.arrayClientesAtendidos5000=result;
        this.porcentajeCtes5000 = (this.arrayClientesAtendidos5000[0].dblAvance * 100)/this.arrayClientesAtendidos5000[0].dblActual
     /*  console.log(this.arrayClientesAtendidos5000); */
      this.intCte5000 =  parseInt(this.arrayClientesAtendidos5000[0].dblEcomonedas)
      this.getDatosTabla(this.strAgente);
      },
      (error) => {
        console.log("error: ", error);
        this.load = true;
      })
  }

  getDatosTabla(strAgente:string){
    this.arrayDatosTabla = [];
    try{
    let objBono = { strAccion: 'getSueldo', strAgente: strAgente,"strConexion": this.strConexion};
    this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
      result =>{
        this.arrayDatosTabla = result;
        this.fnComisionProdFoco(this.strAgente)
       /*  console.log(this.ArrayDatos)
        console.log(this.arrayClientesVisitados)
        console.log(this.arrayClientesAtendidos5000);  
        console.log(this.arrayDatosTabla); */
      },(error) => {
        console.log("error: ", error);
        this.load = true;
      });
    }catch(error){
      this.fnComisionProdFoco(this.strAgente)
      /* console.log(this.ArrayDatos)
      console.log(this.arrayClientesVisitados)
      console.log(this.arrayClientesAtendidos5000);  
      console.log(this.arrayDatosTabla); */
      this.load = true;
    }
  }

  fnComisionProdFoco (strAgente:string) {
    this.load= false;
    this.arrayComisionPF= [];
    this.ComisionPF=0;
    this.ComisionPFAvance = 0;
    this.ComisionPFObjetivo = 0;
    this.ComisionPFPorcentaje = 0;
    let objBono = { strAccion: 'getProductosFoco', strAgente: strAgente,"strConexion": this.strConexion};
    this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
      result =>{
        this.arrayComisionPF = result;
      /*   console.log(this.arrayComisionPF) */
        if (this.arrayComisionPF.length > 0) {
            this.ComisionPF = this.arrayComisionPF[0].dblEcomonedas == undefined ? 0 : this.arrayComisionPF[0].dblEcomonedas;
            this.ComisionPFAvance = this.arrayComisionPF[0].dblAvance == undefined ? 0:this.arrayComisionPF[0].dblAvance;
            this.ComisionPFObjetivo = this.arrayComisionPF[0].dblObjetivo == undefined ? 0:this.arrayComisionPF[0].dblObjetivo;
            this.ComisionPFPorcentaje  =  (this.ComisionPFAvance * 100) / this.ComisionPFObjetivo
        } 
        this.fngetTrimestral(strAgente);
        this.load= true;

    },(error) => {
        console.log("error: ", error);
        this.load = true;
    })
  }

  fngetTrimestral (strAgente:string) {
    this.load= false;
    this.ObjBonoTriCurrent= {
      intBonoActual:0,
      intPeriodoTriAnt:0,
      intPromedioVenta:0,
      intBonoTri:0,
      intMes1:"",
      intVentaMes1:0,
      intMes2:"",
      intVentaMes2:0,
      intMes3:"",
      intVentaMes3:0,
      intMetaNextBono:0,
      intNextBono:0,
      dblMargenSig:0
    };
    let objBono = { strAccion: 'getBonoTrimestral', strAgente: strAgente,"strConexion": this.strConexion};
    this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
      result =>{
        let arrayTri = []
    arrayTri = result;
 /*    console.log(arrayTri); */
    if(arrayTri && arrayTri.length > 0){
     this.ObjBonoTriCurrent= {
        intBonoActual:arrayTri[0].dblBonoAct,
        intPeriodoTriAnt:arrayTri[0].dblPromedioAct,
        intPromedioVenta:arrayTri[0].dblPromedioSig,
        intBonoTri:arrayTri[0].dblBonoSig,
        intMes1:arrayTri[0].strMes1Sig,
        intVentaMes1:arrayTri[0].dblFacturacion_Mes1Sig,
        intMes2:arrayTri[0].strMes2Sig,
        intVentaMes2:arrayTri[0].dblFacturacion_Mes2Sig,
        intMes3:arrayTri[0].strMes3Sig,
        intVentaMes3:arrayTri[0].dblFacturacion_Mes3Sig,
        intMetaNextBono:arrayTri[0].dblFacturacion,
        intNextBono:arrayTri[0].dblBono_Proximo,
        dblMargenSig:(arrayTri[0].dblMargenSig*100)
      };
    }
    this.getComisionMayoreo(strAgente);
    /* console.log(arrayTri); */
    this.load= true;
    this.scrollToTop();
      },(error) => {
        console.log("error: ", error);
      this.load= true;
      this.scrollToTop();
    })
  }

  getComisionMayoreo(strAgente:string){
    this.arrayComisionMayoreo = [];
    let objBono = { strAccion: 'getComisionMayoreo', strAgente: strAgente,"strConexion": this.strConexion};
    this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
      result =>{
        this.arrayComisionMayoreo = result;
        this.getComisionProductosFco(strAgente);

      },(error) => {
        console.log("error: ", error);
        this.load = true;
    })
  }

  sumas(strAgente:string){
    this.totalPesos = 0;
    if( this.arrayDatosTabla[0]  && this.objAvanceCte1){
      this.totalPesos = 
      parseFloat( this.arrayDatosTabla[0].dblSueldo)+
      parseFloat(this.arrayDatosTabla[0].dblGasolina)+
      parseFloat(this.objAvanceCte1.dblEcomonedas > 0 ? this.objAvanceCte1.dblEcomonedas:this.objAvanceCte2.dblEcomonedas > 0 ? this.objAvanceCte2.dblEcomonedas: this.objAvanceCte3.dblEcomonedas) +
      this.intCte5000+ this.ComisionPF+
      parseFloat(this.arrayComisionMayoreo[0].dblEcomonedas)+
      this.ObjBonoTriCurrent.intBonoActual +(this.objdegrapackFoco && this.objdegrapackFoco.dblEcomonedas ? this.objdegrapackFoco.dblEcomonedas :0) +(this.objFoaomFoco && this.objFoaomFoco.dblEcomonedas ? this.objFoaomFoco.dblEcomonedas :0)+
      (this.objQuimicosFoco && this.objQuimicosFoco.dblEcomonedas ?this.objQuimicosFoco.dblEcomonedas :0)+( this.objDoveFoco && this.objDoveFoco.dblEcomonedas ? this.objDoveFoco.dblEcomonedas :0);
    }
   
  }

  scrollToTop(): void {
    if (this.elem){
      this.elem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }



  scrollToMiddle(lin: number): void {
    // Obtén la altura de la ventana y la mitad de la altura de la página
    const windowHeight: number = window.innerHeight;
    const pageHeight: number = document.body.scrollHeight;
    const middlePosition: number = pageHeight / lin;

    // Calcula la posición de desplazamiento para centrar la página
    const scrollTo: number = middlePosition - windowHeight / lin;

    // Realiza el desplazamiento suave utilizando Renderer2
    this.renderer.setProperty(document.documentElement, 'scrollTop', scrollTo);
    this.renderer.setProperty(document.body, 'scrollTop', scrollTo);
  }

  setDatos(): void {
    this.totalEcomonedasLineas = 0;
    this.objDegrapackCliente = this.ArrayDatos.find(x => x.strLinea === "DEGRAPACK" && x.strTipo === "CLIENTE");
    this.objDegrapackVenta = this.ArrayDatos.find(x => x.strLinea === "DEGRAPACK" && x.strTipo === "VENTA");
    this.objFoaomCliente = this.ArrayDatos.find(x => x.strLinea === "FOAOM" && x.strTipo === "CLIENTE");
    this.objFoaomVenta = this.ArrayDatos.find(x => x.strLinea === "FOAOM" && x.strTipo === "VENTA");
    this.objQuimicosEcodeliCliente = this.ArrayDatos.find(x => x.strLinea === "QUIMICOS" && x.strTipo === "CLIENTE");
    this.objQuimicosEcodeliVenta = this.ArrayDatos.find(x => x.strLinea === "QUIMICOS" && x.strTipo === "VENTA");
    this.objTorkCliente = this.ArrayDatos.find(x => x.strLinea === "TORK" && x.strTipo === "CLIENTE");
    this.objTorkVenta = this.ArrayDatos.find(x => x.strLinea === "TORK" && x.strTipo === "VENTA");
    this.objRubermideCliente = this.ArrayDatos.find(x => x.strLinea === "RUBBERMAID" && x.strTipo === "CLIENTE");
    this.objRubermideVenta = this.ArrayDatos.find(x => x.strLinea === "RUBBERMAID" && x.strTipo === "VENTA");
    this.objKimberlyCliente = this.ArrayDatos.find(x => x.strLinea === "KIMBERLY" && x.strTipo === "CLIENTE");
    this.objKimberlyVenta = this.ArrayDatos.find(x => x.strLinea === "KIMBERLY" && x.strTipo === "VENTA");
    this.objAccesoriosCliente = this.ArrayDatos.find(x => x.strLinea === "ACCESORIOS" && x.strTipo === "CLIENTE");
    this.objAccesoriosVenta = this.ArrayDatos.find(x => x.strLinea === "ACCESORIOS" && x.strTipo === "VENTA");
    this.objBonus = this.ArrayDatos.find(x => x.strLinea === "BONO" && x.strTipo === "BONUS");

    if (this.ArrayDatos && this.ArrayDatos.length > 0) {
      this.totalEcomonedasLineas = (parseInt(this.objDegrapackVenta?.dblEcomonedas) || 0) +
        (parseInt(this.objFoaomVenta?.dblEcomonedas) || 0) +
        (parseInt(this.objQuimicosEcodeliVenta?.dblEcomonedas) || 0) +
        (parseInt(this.objTorkVenta?.dblEcomonedas) || 0) +
        (parseInt(this.objRubermideVenta?.dblEcomonedas) || 0) +
        (parseInt(this.objKimberlyVenta?.dblEcomonedas) || 0) +
        (parseInt(this.objAccesoriosVenta?.dblEcomonedas) || 0);
      this.totalEcomonedas = (this.totalEcomonedasLineas || 0) + (parseInt(this.objBonus?.dblEcomonedas) || 0);
    }
  }

  fnGetDetalleArticulos(accion:string){
    this.arrayMostrarModal = [];
    this.arrayDetArt = [];
    this.strTituloModal =
        accion == 'getArtAccesorios' ? 'Línea Accesorios' :
        accion == 'getArtTork' ? 'Línea Tork' :
        accion == 'getArtQuim' ? 'Línea Químicos' :
        accion == 'getArtFoam' ? 'Línea Foaom' :
        accion == 'getArt3M' ? 'Línea 3M' :
        accion == 'getArtRubbermaid' ? 'Línea Rubbermaid' :
        accion == 'getArtAnguiplast' ? 'Línea Anguiplast' :
        accion == 'getArtKimberly' ? 'Línea kimberly' : 'Línea Degrapack';
    console.log(this.strTituloModal);
    let objLinea = 
    accion == 'getArtAccesorios' ? this.arrayInformacionParticipa[4] :
    accion == 'getArtTork' ? this.arrayInformacionParticipa[3]  :
    accion == 'getArtQuim' ? this.arrayInformacionParticipa[2]  :
    accion == 'getArtFoam' ? this.arrayInformacionParticipa[1]  :
    accion == 'getArtRubbermaid' ? this.arrayInformacionParticipa[5] :
    accion == 'getArtKimberly' ? this.arrayInformacionParticipa[6]  :this.arrayInformacionParticipa[0]  ;
    this.arrayMostrarModal.push(objLinea);
    console.log(this.arrayMostrarModal);
   let myobject = {
        "strAccion": accion,
        "strAgente": this.strAgente,
        "strConexion": this.strConexion
    };
    this._httpService.mostrarCotizaciones(myobject,'sp_ELITE_EcodeliPremia').subscribe(
      result =>{
        this.arrayDetArt = result;
        console.log(this.arrayDetArt)
      },(error) => {
        console.log("error: ", error);
    })
}

filtroTabProdFoco(strLinea:string){
  if(strLinea == "todas"){
    this.ArrayArtsModal= this.ArrayCtsModal
  }else{
    this.ArrayArtsModal= this.ArrayCtsModal.filter(x=> x.strLinea.trim() == strLinea.trim());
  }
}

getComisionProductosFco(strAgente:string){
  this.load = false
  this.arrayProductosFoco = [];
  let objBono = { strAccion: 'getNewProducFocos',
  
   strAgente:  strAgente,
   "strConexion": this.strConexion};
  this._httpService.mostrarCotizaciones(objBono,'sp_ELITE_EcodeliPremia').subscribe(
    result =>{
      this.arrayProductosFoco = result;
     /*  console.log(this.arrayProductosFoco) */
      this.objdegrapackFoco= this.arrayProductosFoco.find(o => o.strLinea == "DEGRAPACK")
      this.objFoaomFoco= this.arrayProductosFoco.find(o => o.strLinea == "FOAOM")
      this.objQuimicosFoco= this.arrayProductosFoco.find(o => o.strLinea == "QUIMICOS")
      this.objDoveFoco= this.arrayProductosFoco.find(o => o.strLinea == "DOVE");
      this.load = true
      this.sumas(strAgente);
    },(error) => {
      console.log("error:", error);
      this.load = true;
    });
  }
  
  DatosModalCtesNuevos(){

    this.objClientesNuevos.titulo = "BONO DEL 2% MENSUAL SOBRE CLIENTES NUEVOS"
    this.objClientesNuevos.array = ["El cliente deberá de registrarse desde un comienzo como PROSPECTOS dentro del CRM de Ecodeli",
      "La venta debe de ser con un margen Mayor al 26%."]
    this.objClientesNuevos.strDescripcion1 = "Todo cliente nuevo generara una comisión del 2% de la venta total (antes de impuestos), siempre y cuando:"
    this.objClientesNuevos.strDescripcion2  = "Esta comisión es única por la primera venta realizada al cliente y se paga mensualmente."
  }
}
