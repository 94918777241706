<div class="container">
    <mat-tab-group (selectedTabChange)="yourFn($event)">
       <!-- <mat-tab label="ObjMix Mes Actual">
            <div class="row ">
                <div class="col"><br>
                    <div class="text-right col-sm-12">
                        <mat-slide-toggle class="example-margin" [(ngModel)]="btnMostrarTabla" [color]="color" [checked]="true">
                            <span *ngIf="btnMostrarTabla==true">Ocultar tabla Objetivos</span>
                            <span *ngIf="btnMostrarTabla==false">Mostrar tabla Objetivos</span>
                        </mat-slide-toggle>
                    </div>

                    <h1 class="title">Objetivos Mix <span class="title2">Nacional </span></h1>
                    <br>

                    <div class="contenedor_tabla">
                        <div class="divYD">
                            <div class="row">

                                <div class="col-8">
                                    <table class="table">
                                        <thead>
                                            <tr> 
                                                <th scope="col" class="headcol2D encabezado">Linea</th>
                                                <th scope="col" class="headcol3D encabezado">% Nacional</th>
                                                <th scope="col" class=" encabezado">% 6 Meses</th>
                                                <th scope="col" class=" encabezado">% Divisional</th>
                                                <th scope="col" class="subject encabezado" *ngFor="let item of arrayEncabezado; ">
                                                    {{item.strMes}} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of arrayHistorico; index as i; ">
                                                <th class="headcol2D panel">{{item.strLinea}}</th>
                                                <th class="headcol3D panel2">{{item.dblPObjetivo *100 |number:'1.1-2'}}%</th>

                                                <th class="cuerpo1">
                                                    <span *ngIf="item.dblPromedio<item.dblPObjetivo" style="color: red;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                                    <span *ngIf="item.dblPromedio >=item.dblPObjetivo" style="color: green;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>


                                                </th>
                                                <th class="cuerpo2"> <span [ngClass]="{'text-success':item.dblPAgente >=item.dblPObjetivo || item.dblPAgente >=item.dblPromedio,'text-danger':item.dblPAgente <item.dblPObjetivo || item.dblPAgente <item.dblPromedio}">{{item.dblPAgente*100|number:'1.1-2'}}%</span></th>
                                       
                                                <td class="cuerpo1" *ngIf="item.dblEnero">{{item.dblEnero| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblFebrero">{{item.dblFebrero| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblMarzo">{{item.dblMarzo| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblAbril">{{item.dblAbril| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblMayo">{{item.dblMayo| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblJunio">{{item.dblJunio| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblJulio">{{item.dblJulio| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblAgosto">{{item.dblAgosto| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblSeptiembre">{{item.dblSeptiembre| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblOctubre">{{item.dblOctubre| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblNoviembre">{{item.dblNoviembre| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblDiciembre">{{item.dblDiciembre| currency}}</td>

                                            </tr>
                                            <tr>
                                                <th class="headcol2D pie1"> Total:</th>
                                                <th class="headcol3D pie1"> 100%</th>
                                                <td class="pie1"></td>
                                                <td class="pie1"></td>
                                         
                                                <td class="pie1" *ngFor="let item of arrayEncabezado; ">{{ item.total| currency}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div><br><br>
        </mat-tab>
        <mat-tab label="ObjMix Siguiente Mes">
            <div class="row ">
                <div class="col"><br>
                    <div class="text-right col-sm-12">
                        <mat-slide-toggle class="example-margin" [(ngModel)]="btnMostrarTabla" [color]="color" [checked]="true">
                            <span *ngIf="btnMostrarTabla==true">Ocultar tabla Objetivos</span>
                            <span *ngIf="btnMostrarTabla==false">Mostrar tabla Objetivos</span>
                        </mat-slide-toggle>
                    </div>

                    <h1 class="title">Objetivos Mix <span class="title2">Nacional </span></h1>
                    <br>

                    <div class="contenedor_tabla">
                        <div class="divYD">
                            <div class="row">

                                <div class="col-8">
                                    <table class="table">
                                        <thead>
                                            <tr> 
                                                <th scope="col" class="headcol2D encabezado">Linea</th>
                                                <th scope="col" class="headcol3D encabezado">% Nacional</th>
                                                <th scope="col" class=" encabezado">% 6 Meses</th>
                                                <th scope="col" class=" encabezado">% Divisional</th>
                                                <th scope="col" class="subject encabezado" *ngFor="let item of arrayEncabezado; ">
                                                    {{item.strMes}} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of arrayHistorico; index as i; ">
                                                <th class="headcol2D panel">{{item.strLinea}}</th>
                                                <th class="headcol3D panel2">{{item.dblPObjetivo *100 |number:'1.1-2'}}%</th>

                                                <th class="cuerpo1">
                                                    <span *ngIf="item.dblPromedio<item.dblPObjetivo" style="color: red;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>
                                                    <span *ngIf="item.dblPromedio >=item.dblPObjetivo" style="color: green;">{{item.dblPromedio*100|number:'1.1-2'}}%</span>


                                                </th>
                                                <th class="cuerpo2"> <span [ngClass]="{'text-success':item.dblPAgente >=item.dblPObjetivo || item.dblPAgente >=item.dblPromedio,'text-danger':item.dblPAgente <item.dblPObjetivo || item.dblPAgente <item.dblPromedio}">{{item.dblPAgente*100|number:'1.1-2'}}%</span></th>
                                      
                                                <td class="cuerpo1" *ngIf="item.dblEnero">{{item.dblEnero| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblFebrero">{{item.dblFebrero| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblMarzo">{{item.dblMarzo| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblAbril">{{item.dblAbril| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblMayo">{{item.dblMayo| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblJunio">{{item.dblJunio| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblJulio">{{item.dblJulio| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblAgosto">{{item.dblAgosto| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblSeptiembre">{{item.dblSeptiembre| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblOctubre">{{item.dblOctubre| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblNoviembre">{{item.dblNoviembre| currency}}</td>
                                                <td class="cuerpo1" *ngIf="item.dblDiciembre">{{item.dblDiciembre| currency}}</td>

                                            </tr>
                                            <tr>
                                                <th class="headcol2D pie1"> Total:</th>
                                                <th class="headcol3D pie1"> 100%</th>
                                                <td class="pie1"></td>
                                                <td class="pie1"></td>
                                        
                                                <td class="pie1" *ngFor="let item of arrayEncabezado; ">{{ item.total| currency}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div><br><br>
        </mat-tab>-->

        <mat-tab label="Asignación de Objetivos">
            <div class="row  " *ngIf="btnMostrarTabla==true">

                <div class="row" style="padding: 0 15px; margin-top: 50px;"><br><br>
                    <div class="col-6">
                        <h4>Seleccione Gerente Divisional</h4>
                        <div class="input-group mb-3">
                            <mat-form-field appearance="fill">
                                <mat-label>Gerente</mat-label>
                                <mat-select [(ngModel)]="gteSelected" (change)="fnFiltrar()">
                                    <mat-option *ngFor="let item of arrayGerentes" [value]="item">
                                        {{item.strEstatus}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button style="background-color: #3e6294;color: white;height: 50px;margin-left: 15px;" (click)="fnFiltrar()">Filtrar</button>
                        </div>
                    </div>
                    <!--<div class="col-6 ">
                        <h4>Capture Objetivo para {{gteSelected.strEstatus}} </h4>
                        <div class="input-group mb-3  " *ngIf="gteSelected.strEstatus ">
                            <mat-form-field appearance="fill" class="matcell">
                                <mat-label>Objetivo Propuesto </mat-label>
                                <input matInput placeholder="0" [value]="dblObjetivoGerente|currency" name="first" (blur)="fnCambiam($event)">
                            </mat-form-field>
                            <button *ngIf="statusGte=='ASIGNADO'||statusGte=='RECHAZADO'||!statusGte" mat-raised-button style="  background-color: #4c586f; margin-top: -6px; color: white;height: 50px;margin-left: 15px;" (click)="fnSetObjetivoGte()">Guardar</button>
                        </div>
                    </div>-->
                </div>
                <!----------------------- HISTORICO VENTA--------------------------------------->
                <div class="row" *ngIf="arrayHistoricoD.length>0">
                       
                    <mat-form-field appearance="fill" class="matcell col-3"     style="color: #2494cc;"
                    >
                        <mat-label>Promedio de Venta 2022</mat-label>
                        <input matInput placeholder="0" value="{{dblObjetivoPDivisionalA | currency}}"   name="first2">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="matcell col-3" style="color: #00c3af;">
                        <mat-label>Promedio de Venta 2023</mat-label>
                        <input matInput placeholder="0" value="{{dblObjetivoPDivisional | currency}}"   name="first2">
                    </mat-form-field>

                 
                    <mat-form-field appearance="fill" class="matcell col-3" style="color: #815799; font-weight: 600;">
                        <mat-label>Objetivo Capturado </mat-label>
                        <input matInput placeholder="0" value="{{dblObjetivoA | currency}}"  name="first1">
                    </mat-form-field>
                </div>
                <br><br>  <br><br>
                <div *ngIf="arrayHistoricoD.length>0"> 
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Linea</th>
                                <th scope="col">Objetivo</th>
                                <th scope="col">Promedio Vta 2023</th>
                                <th scope="col">Promedio Vta 2022</th>
                                <th scope="col">%Capturado</th>
                               
                                <th scope="col">%Ideal</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arrayHistoricoD; index as i; ">
                                <th scope="row">{{item.strLinea}}</th>
                                <th scope="row">{{item.dblObjetivo |currency}}</th>
                                <th scope="row">{{item.promedioAct|currency}}</th>
                                <th scope="row">{{item.promedioAnt|currency}}</th>
                                <th scope="row">{{item.dblPromedio|percent}}</th>
                              
                                <th scope="row">{{item.dblPObjetivo|percent}}</th>
                                
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <!----------------------- FIN HISTORICO VENTA--------------------------------------->
                <h1 class="title mt-5">Objetivos <span class="title2">Asignados</span></h1>
                <br>
                <div class="col-3 text-center"> <br>
                    <form class="example-form">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Objetivo Nacional</mat-label>
                            <input matInput [value]="dblObjNacional|currency ">
                        </mat-form-field> <br>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Objetivo Asignado</mat-label>
                            <input matInput [value]=" dblObjNacionalA|currency">
                        </mat-form-field> <br>
                     
                    </form>
                </div>
                <div class="col-8">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Gerente</th>
                                <th scope="col">Objetivo</th>
                                <th scope="col">Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arrayTotalObj">
                                <th scope="row">{{item.strSucursal}}</th>
                                <td>
                                    <button type="button" (click)="fnGetObjSuc(item)" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        {{item.dblObjetivo|currency}}
                                      </button>
                                </td>
                                <td>
                                    <span *ngIf="item.strEstatus=='ASIGNADO'" class="material-icons texto-azul-e" title="ASIGNADO">pending</span>
                                    <span *ngIf="item.strEstatus=='PENDIENTE'" class="material-icons texto-verde-e" title="PENDIENTE">assignment_turned_in</span>
                                    <span *ngIf="item.strEstatus=='RECHAZADO'" class="material-icons texto-rojo-e" title="RECHAZADO">assignment_late</span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <br><br>
                </div>
            </div>
            <!-- <div class="row seccion" *ngIf='arrayObjetivoCapturado.length>0'>
                <div class="col"><br>

                    <h1 class="title">Objetivo Capturado <span class="title2">{{gteSelected.strEstatus}} </span></h1>
                    <br>
                    <div class="row">
                        <form class="example-form">
                            <mat-form-field class="example-full-width col-3 inputObj" appearance="fill">
                                <mat-label>Objetivo Asignado</mat-label>
                                <input matInput placeholder="0" [value]="dblObjetivoA|currency" name="dos">
                            </mat-form-field>

                            <mat-form-field class="example-full-width col-3 inputObj" appearance="fill">
                                <mat-label>Objetivo Capturado</mat-label>
                                <input matInput placeholder="0" [value]="dblObjetivoC|currency" name="tres">
                                
            </mat-form-field>

            <mat-form-field class="example-full-width col-3 inputObj" appearance="fill">
                <mat-label>Diferencia de Objetivos</mat-label>
                <input matInput placeholder="0" [value]="dblObjetivoA-dblObjetivoC | currency" name="cuatro">
            
            </mat-form-field>
            </form>
</div>
<br>
<div class="row">
    <div class="col-6">
        <table class="table ">
            <thead>
                <tr>
                    <th scope="col">Linea</th>
                    <th scope="col">Objetivo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of arrayObjetivoCapturado; index as i; ">
                    <th scope="row">{{item.strLinea}}</th>
                    <td>{{item.dblObjetivo|currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br>
    <div class="col-6">
        <div class="form-group ">
            <label for="exampleFormControlTextarea1">Observaciones</label>
            <textarea [(ngModel)]="strObservaciones" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div class="row">
            <button class="col-5" mat-raised-button style="  background-color: #849974; 
                         color: white;height: 50px;    margin-top: 60px;" (click)="fnAutorizar('AUTORIZADO')" *ngIf="btnAutorizar&&strEstatus=='PENDIENTE'">Autorizar</button>
            <div class="col-2"></div>
            <button class="col-5" mat-raised-button style="  background-color: #613a43;  color: white;height: 50px;    margin-top: 60px;" (click)="fnAutorizar('RECHAZADO')" *ngIf="btnRechazar&&strEstatus=='PENDIENTE'">Rechazar</button>
        </div>
    </div>
</div>
<br>
<br>
</div>
</div> -->
            <br><br>


        </mat-tab>
    </mat-tab-group>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLabel">Sucursales con Objetivos Asignados</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Sucursal</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Objetivo</th>
                                <th scope="col">Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arrayGerentesSuc">
                                <th scope="row">{{item.strSucursal}}</th>
                                <td>{{item.strLinea}}</td>
                                <td>{{item.dblObjetivo|currency}}</td>
                                <td>{{item.strEstatus}}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>Total Asignado:</td>
                                <td><b>{{ dblTotalAsigSuc|currency}}</b></td>
                                <td></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

                </div>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<app-footer></app-footer>