import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as html2pdf from 'html2pdf.js';
import { DataDBService } from '../servicios/data-db.service';
declare var $: any;

export interface UserData {
  intId_Direccion: string;
  strSucursal: string;
  strCalle: string;
  strNo_exterior: string;
  strColonia: string;
  strDelegacion: string;
  strEstado: string;
  strCodigoPostal: string;
  strAgente: string;
}

export interface data {
  indice: string;
  img: string;
  strArticulo: string;
  strDescripcion: string;
  strUnidad: string;
  dblPrecio: string;
  strTipo: string;
  strLista: string;
}
export interface tareasCte {
  origen: string;
  titulo: string;
  responsable: string;
  fechaInicio: string;
  fechaVencimiento: string;
  progreso: string;
  btnRadio: string;
  intID_Actividad: number;
}

@Component({
  selector: 'app-detalle-familia-sucursal',
  templateUrl: './detalle-familia-sucursal.component.html',
  styleUrls: ['./detalle-familia-sucursal.component.css']
})
export class DetalleFamiliaSucursalComponent implements OnInit {
  displayedColumns: string[] = ['intId_Direccion', 'strSucursal', 'strCalle', 'strNo_exterior', 'strColonia', 'strDelegacion', 'strEstado', 'strCodigoPostal', 'strAgente'];
  displayedColumnsCotizacion: string[] = ['indice', 'img', 'strArticulo', 'strDescripcion', 'strUnidad', 'dblPrecio', 'strTipo', 'strLista'];
  columnsTareasCte: string[] = ['btnRadio', 'titulo', 'responsable', 'fechaInicio', 'origen'];/* , 'fechaVencimiento' ,'progreso'*/
  datosTareasCte: MatTableDataSource<tareasCte>;
  dataSource: MatTableDataSource<UserData>;
  dataSourceC: MatTableDataSource<data>;
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;  
  @ViewChild('sort1') sort1: MatSort;
  @ViewChild('sort2') sort2: MatSort;
  @ViewChild('headerArtsAsing', { static: true }) headerArtsAsing!: ElementRef
  @ViewChild('btnCerrarAct') btnCerrarAct!: ElementRef;
  @ViewChild('btnCerrar') btnCerrar!: ElementRef;
  @ViewChild('infoGral') infoGral!: ElementRef;
  @ViewChild('inputComentarios') inputComentarios!: ElementRef;
  public altInfo:any;
  public arrayArtsPolitica:any = [];
  @ViewChild('timeLine') divTimeLine!: ElementRef;
  @ViewChild('timeLineBody') divtimeLineBody!: ElementRef;
  @ViewChild('fileInput2') fileInput2!: ElementRef;
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;
  @ViewChild('btnCerrarOportunidades') btnCerrarOportunidades!: ElementRef;
  public strUsuario: string;
  public tagName: string;
  public strAgente_Crea: string;
  public intIDFamilia: number;
  public vartri: boolean = false;
  public strBuscarAct:string = "";
  public strClasificacion: string = "";
  public strNombreEmpresa: string = "";
  public arrayContactosDetalle: any = [];
  public boolAgente: boolean = true;
  public strAgenteLD: string = "";
  public arrayAgentes: any = [];
  public arrayFamilias: any = [];
  public intValidacionFamilia: number;
  public strTipoLead: string = "";
  public inthov: boolean = false;
  public strEstatus: string = ""
  public IntObjetivo: number = 0;
  public strComentarioD: string = ''
  public arrayComentarios: any = []
  public IntVenta: number = 0;
  public IntDiferencia: number = 0;
  public IntPorcentajeAvance: number = 0;
  public intCteValida: boolean = false;
  public strAgente: any;
  public arrayOportunidades: any = [];
  public time1: string = "am";
  public time2: string = "am";
  public mdlHoraInicio: string = "07:00";
  public mdlHoraFin: string = "07:00";
  public arrayAm: any = []
  public arraypm: any = []
  public mdlNombre: string = "";
  public mdlComentarios: string = "";
  public strfecha: Date;
  public arrayTime: any = [{ time: "am" }, { time: "pm" }]
  public objModal: any = { titulo: "" };
  public arrayHora: any = [];
  public arrayInfo: any = [];
  public objInfo: any = {};
  public strSucursal: string = "";
  public arraySucursales: any = [];
  public objSubtarea: any = []
  public intID_Leads_Sucursal: number = 0
  public arrayActividades: any = [];
  public arrayOport: any = [];
  public arrayListSucursales: any = [];
  public arrayEnviarAnexosActividades:any = []
  public strConexion: string = "";
  public searchInput: string = "";
  public objAct: any;
  public objObjetivos: any;
  public objDetActividad: any;
  public intID_Familia: string;
  public intID_Leads: number;
  public strFamilia: string = ""
  public contenido: string = '';
  public strResultado: string = "";
  public intIDMovimientoVenta: number = 0
  public strNombreAgente: any = ''
  public strNombreOportunidad: string = ''
  public dblMonto: any = ''
  public strFechaCierre: any = ''
  public intID_linea: any = ''
  public strLinea: string = "";
  public arrayLineas2: any = []
  public arraySelectSucursales: any = []
  public strResponsable: string = ''
  public objResponsable: any = {};
  public arrayAsigAgentes: any = [];
  public strBuscarArticulo: any = ''
  public arrayArts: any = [];
  public intTotalContestadas: any = 0
  public arrayArticuloAgregados: any = []
  public strDescripcionArticulo: any = ''
  public intCantidadArticulos: number = 0;
  public arrayHistVenta: any = [];
  public arrayFiltradoHistClientes: any = [];
  public arrayCompletoHisVta: any = [];
  public boolSuc: boolean = false;
  public arrayLineas: any = [];
  public strBuscarHistVta: string = "";
  public spinerBool: boolean = false;
  public arrayFlujoCliente: any = [];
  public arrayFlujoClienteFiltrado: any = [];
  public searchFlujoCte: string = "";
  public strRutaLogo: any = ''
  public strDBRutaLogo: any = ''
  public selectedRenovFile: File;
  public objLinea: any;
  public strRazonSocial: any = '';
  public myForm: FormGroup;
  public formLead: FormGroup;
  public strSector: string = "";
  public intGiro: any = '';
  public arrayGiros: any = [];
  public intIDPresencia: any = ''
  public arrayPresencia: any = [];
  public arrayUbicaciones: any = [];
  public arrayTamanoEmpresa: any = [];
  public intIDUbicacion: any;
  public strNumeroEmpleados: any = '';
  public strFechaEstimadaCierreInput: any = '';
  public strFechaEstimadaCierre: any = '';
  public strRFC: any = '';
  public banderaCotizaciones: any = false;
  public arrayTipoCotizacion: any = []
  public strAgenteSP: any;
  public strMedioConocio: any = 'VENDEDOR';
  public strTieneServicio: any
  public banderaContacto: any;
  public ListaAnexos: any = [];
  public strTipoCotizacion: string = "";
  public intPotenciador: number = 0
  public strCodigoPostal: any;
  public strCalle: any = '';
  public strEstado: any = '';
  public strNumeroExterior: any = '';
  public arraySubTareas: any = []
  public strDelegacion: any = '';
  public strEntreCalles: any = '';
  public strColonia: any = '';
  public strLadaEmpresa: any = '';
  public strTelefonoEmpresa: any = '';
  public strOtraColonia: any = '';
  public strZona: any;
  public intId_Contacto: any = 0
  public intIDLeads: any = 0;
  public dblImporte: string = "";
  public strPrioridad: string = "";
  public strSitioWeb: any = '';
  public intId_Direccion: any = 0;
  public intTotalSucursales: any = '';
  public strFase: any;
  public fechaInicioServicio: any = null;
  public intCantidadElementos: any = 0;
  public arrayAnexosUpdate: any = [];
  public strRecomendacion: any;
  public intid_Cliente: any = 0
  public panelOpenState = false;
  public arrayAnexos: any = [];
  public strTipoAnexo: any = 'Contrato';
  public itemD: any = 0;
  public strFechaInicioServicio: any;
  public strTituloActividad: string = ''
  public intIDActividad: any;
  public altoInfoGral: number = 0;
  public arrayListadoSucursales: any = [];
  public excelData: any = []
  public excelDataAux: any = [];
  public strCliente: any = 'Contrato';
  public arrayAnexosModal:any = []
  public arraySecciones: any = []
  public arrayNombresSecciones: any = []
  public intTotalPreguntas: number = 0
  public banderaFormulario: boolean;
  public arraySectores: any = []
  public arraySubGiro: any = []
  public arraySubGiroTotal: any = []
  public arraySegundaLetra: any = []
  public arrayFitroSegunda: any = []
  public arrayTercerLetra: any = []
  public arrayPotenciador: any = []
  public arrayFiltroTercera: any = []
  public strPotencializador: any = ''
  public intId_segunda: any = 0;
  public arrayAgentesActividades: any = [];
  public arrayAccesos: any = [];
  public objHistCliente: any = {
    dblEnero: 0,
    dblFebrero: 0,
    dblMarzo: 0,
    dblAbril: 0,
    dblMayo: 0,
    dblJunio: 0,
    dblJulio: 0,
    dblAgosto: 0,
    dblSeptiembre: 0,
    dblOctubre: 0,
    dblNoviembre: 0,
    dblDiciembre: 0,
    dblTotal: 0
  };
  public 
  public intId_tercera: any = 0
  
  public strNombreCorto: any = ''
  public arrayAgentes_tareas: any = [];
  /*variables para editar-elimnar actividades */
  public objEditarActividad: any = {};
  public strfecha_editar: string;
  public strBuscarAgente: string;
  public arrayAgente_Filter: any = [];
  public arrayLineasSelect: any = [];
  @ViewChild('pdfArtsPolitica', { static: true }) pdfArtsPolitica!: ElementRef
  private unsubscribe$ = new Subject<void>();
  public formOportunidades: FormGroup;
  @ViewChild('btnCerrarEdit') btnCerrarEdit!: ElementRef;
  @ViewChild('editableDiv') editableDiv!: ElementRef;
  @ViewChild('fileModal') fileModal!: ElementRef;
  public arrayAgregarAnexosActividades:any=[];
  public arrayAnexMdlAdd:any = []
  @ViewChild('fileInputActividades') fileInputActividades!: ElementRef;
  /*fin --variables para editar-elimnar actividades */
  public arraySucursales_next: any = [];
  constructor(private indexedDBService: DataDBService,private formbuilder: FormBuilder, private paginatorIntl: MatPaginatorIntl, private rutaActiva: ActivatedRoute, private _httpService: PeticionesService, private carroservice: CarroService, public _alertify: AlertifyService, private _filtroServ: FiltrosService, public router: Router, elem: ElementRef, public route: ActivatedRoute, private cdr: ChangeDetectorRef, public _serviciosService: PeticionesService, public localSt: LocalStorageService, private elementRef: ElementRef) {
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgente_Crea = this.localSt.retrieve('strAgente');
    this.strAgente = this.localSt.retrieve('strAgente');
    let hora = 0, minutos = 0, strH = "", strM = "";
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayHora.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 30) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 30
      }
    }
    hora = 7
    while (hora < 24) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayAm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 30) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 30
      }
    }
    hora = 0
    while (hora < 8) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arraypm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 55) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 5
      }
    }
    this.route.queryParamMap.subscribe(params => {
      this.strCliente = params.get('strCliente');
      this.intId_Direccion = params.get('intId_Direccion');
      this.fnGetSucursales_next();

    });

    this.customizeItemsPerPageLabel();
    this.arrayTipoCotizacion = [
      { strTipoCotizacion: 'Productos en General', Seleccionado: false },
      { strTipoCotizacion: 'Licitaciones', Seleccionado: false },
      { strTipoCotizacion: 'Cuenta Nacional', Seleccionado: false },
      { strTipoCotizacion: 'Público en General', Seleccionado: false }
    ]

    this.arrayContactosDetalle = [{
      strNombreContacto: '',
      strContactoPuesto: '',
      strContactoTelefono: '',
      strContactoEmail: '',
      emailEsObligatorio: false
    }]

    this.myForm = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strSector: new FormControl('', [Validators.maxLength(20), Validators.required]),
      intId_Giro: new FormControl('', [Validators.required]),
      strCodigoPostal: new FormControl('', [Validators.maxLength(5), Validators.required]),
      strDelegacion: new FormControl('', [Validators.maxLength(100), Validators.required]),
      strEstado: new FormControl('', [Validators.maxLength(100), Validators.required]),
      intIDPresencia: new FormControl('', [Validators.required]),
      intIDNo_Empleados: new FormControl('', [Validators.required]),
      intid_Ubicacion: new FormControl('', [Validators.required]),
      strContacto_Nombre: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Puesto: new FormControl('', [Validators.maxLength(50), Validators.required]),
      strContacto_Telefono: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^[0-9]{10}$')]),
      strContacto_Email: new FormControl('', [Validators.email, Validators.maxLength(50), Validators.required]),
      strEstatus: new FormControl('POR CONTACTAR', []),
      strAccion: new FormControl('setLeads', []),
      strConexion: new FormControl(this.strConexion, []),
      strAgente: new FormControl(this.strAgenteSP, []),
      strClasificacion: new FormControl('', [])
    });
    this.formLead = this.formbuilder.group({
      strNombreEmpresa: new FormControl('', [Validators.required]),
      strNombreCorto: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      strSector: new FormControl('', [Validators.required]),
      intId_Giro: new FormControl('', [Validators.required]),
      intId_subGiro: new FormControl(0, []),
      intIDPresencia: new FormControl(0, [Validators.required]),
      intID_Familia: new FormControl(this.intID_Familia, []),
      strRazonSocial: new FormControl('', []),
      strRFC: new FormControl('', []),
      intId_segunda: new FormControl(0, []),
      intId_tercera: new FormControl(0, []),
      intID_Contacto: new FormControl(0, []),
      strPotencializador: new FormControl('', []),
      strMedioConocio: new FormControl('VENDEDOR', [])
    });
  
    this.fnGetFamilias()
    this.fnFormOportunidades();
  }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];

      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        this.strConexion = this.localSt.retrieve('strConexion');
        this.strAgente_Crea = this.localSt.retrieve('strAgente');
        this.strAgente = this.localSt.retrieve('strAgente');
        this.strUsuario = this.localSt.retrieve('strUsuario');

        //------------conexi+on-------------------
        this.indexedDBService.getItem('strConexion', (value) => {
          this.strConexion = value;
      
        });
      }
      else {
        this.router.navigate(['/login']);
      }
    });
    this.fngetLineas();
    this.fngetSubGiros();
    this.fngetSectores()
    this.fngetGiros();
    this.fnGetFiltros();
    this.getValidarSucursal();
    this.fngetSegundaLetra()
    this.fngetTerceraLetra()
    this.fnListadoSolicitud();
    this.fnGetDetalleCotizacion();
    this.fnGetHistoricoVenta();
    this.fnGetAgentes_tareas();
    this.fnGetFlujoCliente();

  }

  ngAfterViewInit() {
    this.altoInfoGral = this.infoGral.nativeElement.offsetHeight;
   

    $('.summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
        onImageUpload: (files) => {
          this.adjustImageSizes();
          this.addImageToEditor(files[0]);
        },
        onInit: () => {
          // Ajustar tamaños de las imágenes al inicializar
        }
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['picture']]
      ],
      popover: {
        image: [
          ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ]
      }
    });

    // Habilitar el input para capturar imágenes desde la cámara
    $('.summernote').on('summernote.init', function () {
      $('input[type="file"]').attr('accept', 'image/*').attr('capture', 'camera');
    });
  }
  /*** funciones para llennado de sucursales */
  fnGetSucursales_next() {
    let obj = {
      strAccion: 'getListado_sucursales_CTE_NEXT',
      strConexion: this.strConexion,
      strCliente: this.strCliente
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        this.arraySucursales_next = result
      }, error => {

        console.error('Error al consumir la API:', error);
      });
  }
  fnNextSucursal(sucursal: any) {
    //this.router.navigate(['/detalleLeadSucursal'], { queryParams: { intId_Direccion: sucursal.intId_Direccion, strCliente: this.strCliente } });
    this.router.navigate(['/detalleLeadSucursal'], { queryParams: { intId_Direccion: sucursal.intId_Direccion, strCliente: this.strCliente } })
      .then(() => {
        window.location.reload();  // Recarga la página
      });
  }
  /*** rfin funciones para llennado de sucursales */
  fnGetAgentes_tareas() {

    let obj = {
      strAccion: 'getAgentes_Leads',
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Cotizador').subscribe(
      result => {
        this.arrayAgentes_tareas = result
      }, error => {

        console.error('Error al consumir la API:', error);
      });

  }
  /***inician funciones para editar y eliminar actividades *****/
  fnDelete_Tarea(tarea: any) {
    let intID_Actividad = tarea.intID_Actividad;
    Swal.fire({
      title: "¿Estás seguro de eliminar esta Actividad?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Sí, Eliminar",
      denyButtonText: `No, Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        this.fnEliminar_Tarea(intID_Actividad)

      } else if (result.isDenied) {
        Swal.fire("No se guardaron los cambios", "", "info");
      }
    });

  }
  fnEliminar_Tarea(intID_Actividad: number) {
  
    let objParam = {
      strAccion: 'deleteTarea',
      intID_Actividad: intID_Actividad,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads').subscribe(
      result => {
        let resultado = result[0];
        if (resultado && resultado.code == 200) {
          Swal.fire("Tarea Eliminada Correctamente", "", "success");
        }
        else {
          Swal.fire("Error al eliminar Tarea", "", "warning");
        }
        this.fnllenarArrayTareas();
      },
      error => {
        var error = <any>error;
        Swal.fire("Error al eliminar Tarea", "", "warning");
        console.log(error);
      }
    );
  }

  fnEditar_Actividad(objAct: any) {
    this.objEditarActividad = {};
    this.objEditarActividad = objAct;
    this.mdlNombre = this.objEditarActividad.strTitulo;
    let strf = this.objEditarActividad.strFechaRequerida;

    // Dividir la cadena en partes
    const [datePart, timePart] = strf.split(' '); // Divide en fecha y hora
    const [month, day, year] = datePart.split('/'); // Divide la parte de fecha en mes, día y año

    this.strfecha_editar = year + '-' + month + '-' + day;
    let hora1 = this.objEditarActividad.strHoraInicio.slice(0, -3);
    this.mdlHoraInicio = hora1;
    let hora2 = this.objEditarActividad.strHoraFin.slice(0, -3);
    this.mdlHoraFin = hora2;
    this.strAgente = this.objEditarActividad.strAgente;
    this.mdlComentarios = this.objEditarActividad.strOpcion;
    this.editableDiv.nativeElement.innerHTML = this.mdlComentarios;
  }

  fnEditarActividad(intID_Actividad: number) { 
    let objParam = {
      strAccion: 'editTarea',
      strFechaInicioServicio: this.strfecha_editar,
      strComentario: this.mdlComentarios,
      strAgente: this.strAgente,
      intID_Actividad: intID_Actividad,
      strConexion: this.strConexion,
      strTitulo: this.mdlNombre,
      strHoraInicio: this.mdlHoraInicio,
      strHoraFin: this.mdlHoraFin
    }


    this._serviciosService.mostrarCotizaciones(objParam, 'sp_modVentas_Leads').subscribe(
      result => {
        let resultado = result[0];
        if (resultado && resultado.code == 200) {
          Swal.fire("Tarea Editada Correctamente", "", "success");
        }
        else {
          Swal.fire("Error al Editar Tarea", "", "warning");
        }
        this.btnCerrarEdit.nativeElement.click();
        this.fnllenarArrayTareas();
      },
      error => {
        var error = <any>error;
        Swal.fire("Error al eliminar Tarea", "", "warning");
        console.log(error);
      }
    );
  }
  fnBusquedaCte(strBuscarAgente: string) {
    let texto = strBuscarAgente.toUpperCase();
    this.arrayAgente_Filter = [];
    for (const post of this.arrayAgentes_tareas) {
      let texto2 = post.strNombre.toUpperCase();
      if (texto2.indexOf(texto) > -1) {
        this.arrayAgente_Filter.push(post);
      }
    };
  }
  fnSeleccionaAgente(agt: any) {
    this.strAgente = agt.strAgente;
    this.strBuscarAgente = "";/* agt.strAgente+'-'+agt.strNombre  */
    this.arrayAgente_Filter = [];
    let findAgente = this.arrayAgentesActividades.find(agtf => agtf.strAgente == agt.strAgente);
    if (!findAgente) {
      this.arrayAgentesActividades.push(agt)
    } else {
      Swal.fire("Error", "Agente existente, seleccione otro agente.", "error")
    }
  }
  /***finalizan funciones para editar y eliminar actividades *****/
  fnInitSummer() {
    $('.summernote').summernote('destroy');
    $('.summernote').summernote({
      placeholder: 'Escribe las observaciones',
      tabsize: 1,
      callbacks: {
        onChange: (contents: string) => {
          this.contenido = contents;
        },
        onImageUpload: (files) => {
          this.adjustImageSizes();
          this.addImageToEditor(files[0]);
        },
        onInit: () => {
          // Ajustar tamaños de las imágenes al inicializar
        }
      },
      height: 230,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['picture']]
      ],
      popover: {
        image: [
          ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
          ['float', ['floatLeft', 'floatRight', 'floatNone']],
          ['remove', ['removeMedia']]
        ]
      }
    });

    // Habilitar el input para capturar imágenes desde la cámara
    $('.summernote').on('summernote.init', function () {
      $('input[type="file"]').attr('accept', 'image/*').attr('capture', 'camera');
    });
  }
  fnTabletareasCte() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }
  fnTable() {
    this.dataSource.paginator = this.paginator1;
    this.dataSource.sort = this.sort1;
  }

  fnTableArts() {
    this.dataSourceC.paginator = this.paginator2;
    this.dataSourceC.sort = this.sort2;
  }

  fnGetFiltros() {
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });
  }
  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }
  obtenerAltoDivINfoGRAL() {
    this.altoInfoGral = this.infoGral.nativeElement.offsetHeight;
 
  }

  getValidarSucursal() {
    let obj = {
      strAccion: 'VALIDA_SUCURSAL',
      strCliente: this.strCliente,
      intID_direccion: this.intId_Direccion,
      strConexion: this.strConexion
    } /*   this.fnGetSuc(); */

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        if (result[0].code == "200") {
          this.intCteValida = true;
          this.intID_Leads = result[0].intID_Leads
          this.getSelectSucursales();
          this.fnGetObjetivos();
          this.fnGetTimeLine();
          this.fnListadoOoprtunidad();
          this.fnGetAnexos();
          this.fnGetLineaIndicadores();
          this.fnllenarArrayTareas()
          this._alertify.success(result[0].strMensaje);
        } else {
          this._alertify.error(result[0].strMensaje);
          this.intCteValida = false;
        }
        //Swal.fire(result[0].code == "200" ? 'Exito' : 'Pendiente', result[0].strMensaje, result[0].code == "200" ? 'success' : "info");
      }, error => {
        console.error('Error al consumir la API:', error);
      });
  }

  getSelectSucursales() {
    let obj = {
      strAccion: 'getSelect_Suc_Oportunidad',
      intID_leads: this.intID_Leads,
      strConexion: this.strConexion
    } /*   this.fnGetSuc(); */

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {

        this.arraySelectSucursales = result
      });
  }


  fnAutorizar(objAct: any) {
    let objEnv = {
      strAccion: 'update_Actividad_Autorizar',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Autorizado',
            text: result[0].strMensaje,
          });
          this.fnllenarArrayTareas()
          this.btnCerrarAct.nativeElement.click()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result[0].strMensaje,
          });
        }
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }
  fnGetObjetivos() {
    this.objObjetivos = {};
    let objEnv = {
      strAccion: 'getObj_Vta',
      /*  strFamilia:'AVENDRA'this.strFamilia, */
      strCliente: this.strCliente,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.objObjetivos = result[0];
        /*    this.fnGetLinea() */

      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }

  fngetLineas() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLineas',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayLineas2 = result

        result.forEach((element: any) => {
          let data = {
            Seleccionado: false,
            strLinea: element.strLinea,
            intID_linea: element.intID_linea
          }
          this.arrayPotenciador.push(data)
        });
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetTimeLine() {
    let obj = {
      strAccion: 'get_TimeLine_Familia',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayOport = result

      }, error => {
        console.error('Error al consumir la API:', error);

      });
  }

  fngetGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayGiros = result
        this.fngetPresencia()
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetPresencia() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getPresencia',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayPresencia = result
        this.fngetUbicaciones()

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetUbicaciones() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getUbicaciones',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayUbicaciones = result
        this.fngetRangoEmpleados()
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fngetRangoEmpleados() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getRangoEmpleados',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayTamanoEmpresa = result
        this.fnGetInfo()
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetInfo() {
    let objEnv = {
      strAccion: 'getLead_Unique',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => { 
        this.arrayInfo = result
        this.objInfo = this.arrayInfo[0]

        this.itemD = this.objInfo
        if (this.itemD) {
          this.strTipoLead = this.itemD.strTipo_Leads
          this.intIDActividad = this.itemD.intID_Actividad
          this.strTituloActividad = this.itemD.strTitulo
          this.strClasificacion = this.itemD.strClasificacion ? this.itemD.strClasificacion : 'CCC';
          this.strNombreEmpresa = this.itemD.strNombreEmpresa;

          this.arrayContactosDetalle[0].strNombreContacto = this.itemD.strContacto_Nombre;
          this.arrayContactosDetalle[0].strContactoPuesto = this.itemD.strContacto_Puesto;
          this.arrayContactosDetalle[0].strContactoTelefono = this.itemD.strContacto_Telefono;
          this.arrayContactosDetalle[0].strContactoEmail = this.itemD.strContacto_Email;
          this.intID_Familia = this.itemD.intID_Familia
          this.strFechaInicioServicio = this.itemD.fechaInicioServicio;
          if (this.itemD.strtipoCotizacion) {
            let tipoCotizacion = (this.itemD.strtipoCotizacion).split(',')
            if (tipoCotizacion.length > 0) {
              tipoCotizacion.forEach(element => {
                this.arrayTipoCotizacion.map((dato: any) => {
                  if (dato.strTipoCotizacion === element) {
                    dato.Seleccionado = true;
                    this.banderaCotizaciones = true
                  }
                  return dato;
                });
              });
            }
          }

          this.strPotencializador = this.itemD.strPotencializador
          if (this.itemD.strPotencializador) {
            let poten = (this.itemD.strPotencializador).split(',')
            if (poten.length > 0) {
              poten.forEach(element => {
                this.arrayPotenciador.map((dato: any) => {
                  if (dato.strLinea === element) {
                    dato.Seleccionado = true;

                  }
                  return dato;
                });
              });
            }
          }
          this.strNumeroExterior = this.itemD.strNo_exterior
          if (this.itemD.strFechaCierre_Estimada) {
            let fecha = new Date(this.itemD.strFechaCierre_Estimada)
            this.strFechaEstimadaCierreInput = this.formatoFecha(fecha)
            this.strFechaEstimadaCierre = this.formatoFecha2(fecha)
          }

          this.strTieneServicio = (this.itemD.strServicio_Limpieza) ? this.itemD.strServicio_Limpieza : ''
          this.intValidacionFamilia = this.itemD.intValidacionFamilia
          this.strEstatus = this.itemD.strEstatus

          if (this.itemD.intIDNo_Empleados > 0) {
            let rangoEmpleados = this.arrayTamanoEmpresa.filter((b: any) => b.intIDNo_Empleados == this.itemD.intIDNo_Empleados)
            this.strNumeroEmpleados = this.itemD.intIDNo_Empleados + ',' + (rangoEmpleados[0] && rangoEmpleados[0].strClasificacion ? rangoEmpleados[0].strClasificacion : "")
          }

          this.strDBRutaLogo = (this.itemD.strUrl_Logo) ? this.itemD.strUrl_Logo : ''
          this.strAgenteLD = this.itemD.strAgente
          this.strCodigoPostal = this.itemD.strCodigoPostal ? this.itemD.strCodigoPostal : "";
          this.strDelegacion = this.itemD.strDelegacion ? this.itemD.strDelegacion : "";
          this.strEstado = this.itemD.strEstado ? this.itemD.strEstado : "";
          this.strCalle = this.itemD.strCalle ? this.itemD.strCalle : "";
          this.strColonia = this.itemD.strColonia ? this.itemD.strColonia : "";
          this.strEntreCalles = this.itemD.strEntrecalles ? this.itemD.strEntrecalles : "";
          this.strFase = this.itemD.strFase ? this.itemD.strFase : "";
          this.intid_Cliente = this.itemD.intid_Cliente
          this.strNombreEmpresa = this.itemD.strNombreEmpresa
          this.strSector = this.itemD.strSector ? this.itemD.strSector : "";
          this.dblImporte = this.itemD.dblMonto ? this.itemD.dblMonto : 0;
          this.strSitioWeb = this.itemD.strSitio_Web ? this.itemD.strSitio_Web : "";
          this.strMedioConocio = 'VENDEDOR';
          this.strPrioridad = this.itemD.strPrioridad ? this.itemD.strPrioridad : "";
          this.strRFC = this.itemD.strRFC;
          this.strNombreAgente = this.itemD.strNombreAgente
          this.intGiro = this.itemD.intId_Giro ? this.itemD.intId_Giro : "";
          if (this.itemD.intIDPresencia > 0) {

            this.intIDPresencia = this.itemD.intIDPresencia
          }

          this.strRecomendacion = this.itemD.strRecomendacion
          this.intCantidadElementos = this.itemD.intCantidadEmpleados
          this.intTotalSucursales = this.itemD.intNo_Sucursales
          this.strRazonSocial = this.itemD.strRazon_Social
          if (this.itemD.intid_Ubicacion > 0) {
            let ubicacion = this.arrayUbicaciones.filter((b: any) => b.intid_Ubicacion == this.itemD.intid_Ubicacion)
            this.intIDUbicacion = this.itemD.intid_Ubicacion + ',' + (ubicacion[0] && ubicacion[0].strClasificacion ? ubicacion[0].strClasificacion : "");
          }
          this.strNombreCorto = this.itemD.strNombreCorto
          this.intId_segunda = this.itemD.intId_segunda,
            this.intId_tercera = this.itemD.intId_tercera,
            this.strFamilia = this.itemD.strFamilia
          this.fnllenarArrayTareas()

          console.log('sector', this.strSector)
          console.log('presencia', this.intIDPresencia)
          console.log('GIRO', this.intGiro)
        }
        this.fnFiltrarSegundaLetra()
      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  fnGetAnexos() {
    try {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getAnexos',
        intIdLeads: this.intID_Leads,
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads').subscribe(
        result => {

          this.arrayAnexos = result
          // this.fnGetLineaIndicadores();
        },
        error => {
          var error = <any>error;
          console.log(error);
          //this.fnGetLineaIndicadores();
        }
      );
    } catch (error) {
      console.log(error.message)
    }

  }

  fnGetLineaIndicadores() {
    let obj = {
      strAccion: 'getIndicadores_Cliente',
      intId_Leads: this.intID_Leads,
      strCliente: this.strCliente,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads').subscribe(
      result => {
        this.objLinea = result[0];
        //this.fnListadoSolicitud();
      }, error => {

        var error = <any>error; console.log(error);
      });
  }

  fnInsertOportunidad(object: any) {
    let idLinea = this.arrayLineas2.filter((b: any) => b.strLinea == this.strLinea)
    let objenv = {
      strAccion: 'setOportunidad_Leads',
      strTitulo: object.strTitulo,//this.strNombreOportunidad,
      dblImporte: object.intMonto,//this.dblMonto,
      strFecha_Cierre: object.strFecha,//this.strFechaCierre,
      strAgente: this.objResponsable.strAgente,//this.strResponsable,
      intID_Leads: this.intID_Leads,
      intID_Linea: (idLinea[0]) ? idLinea[0].intID_linea : '',
      xmlArticulos: this.arrayLineasSelect,//this.arrayArticuloAgregados,
      strConexion: this.strConexion
    } 
    this._serviciosService.mostrarCotizaciones(objenv, 'sp_modVentas_Leads').subscribe(
      result => { 
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Oportunidad Registrada con exito",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnListadoOoprtunidad()
          this.btnCerrarOportunidades.nativeElement.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al Agregar!"
          });
        }

        this.strNombreOportunidad = ''
        this.dblMonto = ''
        this.strFechaCierre = ''
        this.intID_linea = ''
        this.strResponsable = ''
        this.arrayArts = []
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnListadoOoprtunidad() {
    let envOport = {
      strAccion: 'getListado_oportunidad_lead',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(envOport, 'sp_modVentas_Leads').subscribe(
      result => { 
        this.arrayOportunidades = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnEiminarProductos(indice: number) {
    this.arrayArticuloAgregados.splice(indice, 1);
  }

  fnBuscarCte(cte: string) {
    if (cte.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getAgentes_OP',
        strBusqueda: cte,
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads').subscribe(
        result => {
          this.arrayAsigAgentes = result

        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayAsigAgentes = [];
    }
  }

  fnselecCliente(objClient: any) {
    this.objResponsable = objClient;
    this.strResponsable = objClient.strNombreAgente
    this.formOportunidades.patchValue({
      strResponsable: objClient.strNombreAgente
    });
    this.formOportunidades.patchValue({
      strResponsable2: objClient.strNombreAgente
    });
    this.arrayAsigAgentes = [];
  }

  funConsultarArt(art: any) {
    if (art.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getArt_Linea',
        strLinea: this.strLinea,
        strBusqueda: art,
        strCliente: '',
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads').subscribe(
        result => {
          this.arrayArts = result
          console.log(this.arrayArts)
        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayArts = []
    }
  }

  fnselecArticulo(obj: any) {
    this.strBuscarArticulo = obj.strArticulo
    this.strDescripcionArticulo = obj.strDescripcion
    this.arrayArts = [];
    this.fnAgregarArticulo();
  }

  fnAgregarArticulo() {
    let data = {
      strArticulo: this.strBuscarArticulo
    }
    this.arrayArticuloAgregados.push(data)
    this.strBuscarArticulo = ''
    this.intCantidadArticulos = 0
  }

  SubirAnexos() {
    if (this.ListaAnexos.length > 0) {
      this._serviciosService.makeFileRequest('https://www.ecodeli.com/EvidenciaAnexo_Leads.php', [], this.ListaAnexos).then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Correcto...',
          text: 'Anexos Guardados',
        });
        //this.fnInsertLead()
        this.fnUpdateLead_Nuevo()
      }, (error) => {
        console.log(error);
      });
    } else {
      //this.fnInsertLead()
      this.fnUpdateLead_Nuevo()
    }

  }

  fnUpdateLead_Nuevo() {
    this.strTipoCotizacion = ''
    let tipoCotizacion = this.arrayTipoCotizacion.filter((b: any) => b.Seleccionado == true)
    if (tipoCotizacion.length > 0) {
      tipoCotizacion.forEach(element => {
        this.strTipoCotizacion += (element.strTipoCotizacion) + ','
      });
    }
    this.strPotencializador = ''
    let poten = this.arrayPotenciador.filter((b: any) => b.Seleccionado == true)
    if (poten.length > 0) {
      poten.forEach((element: any) => {
        this.strPotencializador += (element.strLinea) + ','
      });
    }
    let envi = {
      strAccion: 'updateLeads',
      intId_segunda: this.formLead.value.intId_segunda,
      intId_tercera: this.formLead.value.intId_tercera,
      strPotencializador: this.strPotencializador,
      intid_Ubicacion: (this.intIDUbicacion),
      intID_Familia: this.intID_Familia,
      strCodigoPostal: this.strCodigoPostal,
      strEstado: this.strEstado,
      strDelegacion: this.strDelegacion,
      strColonia: this.strColonia,
      strCalle: this.strCalle,
      strNo_exterior: this.strNumeroExterior,
      strEntrecalles: this.strEntreCalles,
      intLada: this.strLadaEmpresa,
      intTelefono: this.strTelefonoEmpresa,
      strZona: this.strZona ? this.strZona : "",
      intId_Contacto: this.intId_Contacto,
      strContacto_Nombre: this.arrayContactosDetalle[0].strNombreContacto ? this.arrayContactosDetalle[0].strNombreContacto : "",
      strContacto_Telefono: this.arrayContactosDetalle[0].strContactoTelefono ? this.arrayContactosDetalle[0].strContactoTelefono : "",
      strContacto_Puesto: this.arrayContactosDetalle[0].strContactoPuesto ? this.arrayContactosDetalle[0].strContactoPuesto : "",
      strContacto_Email: this.arrayContactosDetalle[0].strContactoEmail ? this.arrayContactosDetalle[0].strContactoEmail : "",
      intIdLeads: this.intID_Leads,
      strNombreEmpresa: this.strNombreEmpresa,
      strNombreCorto: this.formLead.value.strNombreCorto,
      intId_Giro: this.intGiro, //(this.intGiro).split(',')[0],
      strSector: this.strSector,
      intIDPresencia: this.intIDPresencia,
      strRazon_Social: this.strRazonSocial,
      strRFC: this.strRFC,
      intIDNo_Empleados: (this.strNumeroEmpleados),
      strMedio_NosConocio: this.strMedioConocio,
      strEstatus: this.strEstatus,
      strAgente: this.objInfo.strAgente,
      dblMonto: this.dblImporte,
      strFechaCierre_Estimada: this.strFechaEstimadaCierre,
      strEtiqueta: '',
      strSitio_Web: this.strSitioWeb,
      strPrioridad: this.strPrioridad,
      intId_Direccion: this.intId_Direccion,
      strObservaciones: '',
      intNo_Sucursales: this.intTotalSucursales,
      strFase: this.strFase,
      strResolucion: '',
      strtipoCotizacion: this.strTipoCotizacion,
      strFechaInicioServicio: this.fechaInicioServicio,
      intCantidadEmpleados: this.intCantidadElementos,
      strRecomendacion: this.strRecomendacion ? this.strRecomendacion : "",
      intid_Cliente: this.intid_Cliente,
      xmlAnexos: this.arrayAnexosUpdate,
      strFamilia: this.strFamilia,
      strConexion: this.strConexion 
    }
    console.log(envi)
    this._serviciosService.mostrarCotizaciones(envi, 'sp_modVentas_Leads').subscribe(
      result => { 
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Actualizado",
            showConfirmButton: false,
            timer: 1500
          });

        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Actualizado",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.arrayAnexosUpdate = []
        this.fnGetInfo();
        this.strTipoCotizacion = ''
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  fnListadoSolicitud() {
    let objEnv = {
      strAccion: 'getListado_sucursales_CTE',
      strCliente: this.strCliente,
      strConexion: this.strConexion
    }
    console.log(objEnv);
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads').subscribe(
      result => {
        this.arrayListSucursales = result;
        console.log(this.arrayListSucursales)
        this.dataSource = new MatTableDataSource(this.arrayListSucursales);
        this.fnTable();
        /*    this.fnGetListadoSucursal(); */


      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }

  fnGetDetalleCotizacion() {
    let obj = {
      strAccion: 'GetArticulos_politica_CTE',
      strCliente: this.strCliente,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayArtsPolitica = result;
        this.dataSourceC = new MatTableDataSource(result);
        this.fnTableArts()
      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnDescargarFormatoCliente() {
    const rutaArchivo = 'assets/FormatoSucursalesCliente.xlsx'
    const link = document.createElement('a');
    link.href = rutaArchivo;
    link.download = 'FormatoSucursalesClientes.xlsx';
    link.click();
  }

  fnInsertSucursalesSucursales() {
    let objEnv = {
      strAccion: 'Set_Lead_Suc',
      xmlSucursales: this.excelData,
      strAgente: this.strAgenteLD,
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    console.log(objEnv)
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => { 
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetListadoSucursal()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  fnGetHistoricoVenta() {
    let obj = {
      strAccion: 'getHistorico_Vta_Suc',
      strCliente: this.strCliente,
      intID_Sucursal: this.intId_Direccion,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia').subscribe(
      result => { 
        this.arrayHistVenta = result
        this.fnDividirEnClientes();
      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnDividirEnClientes() {
    let arrayClientes = JSON.parse(JSON.stringify(this.arrayHistVenta));
    this.arrayFiltradoHistClientes = Array.from(
      new Set(arrayClientes.map(cliente => cliente.strCliente))
    ).map(strCliente => {
      let cliente = arrayClientes.find(cliente => cliente.strCliente === strCliente);
      return {
        strCliente: cliente.strCliente,
        strNombreEmpresa: cliente.strNombreEmpresa,
        strNombreCliente: cliente.strNombreCliente,
        strNombreAgente: cliente.strNombreAgente,
        strAgente: cliente.strAgente,
        strGerente: cliente.strGerente,
        strSucursal: cliente.strSucursal,
        strLinea: cliente.strLinea,
        strArticulo: cliente.strArticulo,
        sumEnero: 0,
        dblMargenEnero: 0,
        sumFebrero: 0,
        dblMargenFebrero: 0,
        sumMarzo: 0,
        dblMargenMarzo: 0,
        sumAbril: 0,
        dblMargenAbril: 0,
        sumMayo: 0,
        dblMargenMayo: 0,
        sumJunio: 0,
        dblMargenJunio: 0,
        sumJulio: 0,
        dblMargenJulio: 0,
        sumAgosto: 0,
        dblMargenAgosto: 0,
        sumSeptiembre: 0,
        dblMargenSeptiembre: 0,
        sumOctubre: 0,
        dblMargenOctubre: 0,
        sumNoviembre: 0,
        dblMargenNoviembre: 0,
        sumDiciembre: 0,
        dblMargenDiciembre: 0
      };
    });
    this.fnHistVentaNew()
  }
  fnHistVentaNew() {
    let deepCopyArray = JSON.parse(JSON.stringify(this.arrayHistVenta));
    this.arrayFiltradoHistClientes.forEach(cte => {
      cte.seeLineas = false
      if (!cte.arrayLineasTodo) {
        cte.arrayLineasTodo = [];
      }
      deepCopyArray.forEach(obj => {
        if (cte.strCliente == obj.strCliente) {
          cte.arrayLineasTodo.push(obj);
        }
      });
      if (!cte.arrayLineas) {
        cte.arrayLineas = [];
      }
      let LinCopy = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
      LinCopy.forEach(object => {
        let objetoEncontrado = cte.arrayLineas.find(obj => obj.strLinea === object.strLinea);
        let resultado = objetoEncontrado ? 1 : 0;
        if (resultado == 0) {
          cte.arrayLineas.push({
            seeArts: false,
            strCliente: object.strCliente,
            strNombreEmpresa: object.strNombreEmpresa,
            strNombreCliente: object.strNombreCliente,
            strNombreAgente: object.strNombreAgente,
            strAgente: object.strAgente,
            strGerente: object.strGerente,
            strSucursal: object.strSucursal,
            strLinea: object.strLinea,
            strArticulo: object.strArticulo,
            sumEnero: 0,
            dblMargenEnero: 0,
            sumFebrero: 0,
            dblMargenFebrero: 0,
            sumMarzo: 0,
            dblMargenMarzo: 0,
            sumAbril: 0,
            dblMargenAbril: 0,
            sumMayo: 0,
            dblMargenMayo: 0,
            sumJunio: 0,
            dblMargenJunio: 0,
            sumJulio: 0,
            dblMargenJulio: 0,
            sumAgosto: 0,
            dblMargenAgosto: 0,
            sumSeptiembre: 0,
            dblMargenSeptiembre: 0,
            sumOctubre: 0,
            dblMargenOctubre: 0,
            sumNoviembre: 0,
            dblMargenNoviembre: 0,
            sumDiciembre: 0,
            dblMargenDiciembre: 0
          });
        }
      });
      cte.arrayLineas.forEach(lineas => {
        lineas.seeArts = false;
        if (!lineas.arrayArticulosTodos) {
          lineas.arrayArticulosTodos = [];
        }
        if (!lineas.arrayArticulos) {
          lineas.arrayArticulos = [];
        }

        let LinCopy2 = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
        LinCopy2.forEach(lin => {
          if (lin.strLinea == lineas.strLinea) {
            lineas.arrayArticulosTodos.push({
              seeArts: false,
              strCliente: lin.strCliente,
              strNombreEmpresa: lin.strNombreEmpresa,
              strNombreCliente: lin.strNombreCliente,
              strNombreAgente: lin.strNombreAgente,
              strAgente: lin.strAgente,
              strGerente: lin.strGerente,
              strSucursal: lin.strSucursal,
              strLinea: lin.strLinea,
              strArticulo: lin.strArticulo,
              dblEnero: 0,
              dblMargenEnero: 0,
              dblFebrero: 0,
              dblMargenFebrero: 0,
              dblMarzo: 0,
              dblMargenMarzo: 0,
              dblAbril: 0,
              dblMargenAbril: 0,
              dblMayo: 0,
              dblMargenMayo: 0,
              dblJunio: 0,
              dblMargenJunio: 0,
              dblJulio: 0,
              dblMargenJulio: 0,
              dblAgosto: 0,
              dblMargenAgosto: 0,
              dblSeptiembre: 0,
              dblMargenSeptiembre: 0,
              dblOctubre: 0,
              dblMargenOctubre: 0,
              dblNoviembre: 0,
              dblMargenNoviembre: 0,
              dblDiciembre: 0,
              dblMargenDiciembre: 0,
              total: 0
            });
          }
        });
      });
    });

    this.fnSumasArticulosHistVentas()

  }
  fnSumasArticulosHistVentas() {
    this.arrayFiltradoHistClientes.forEach(cte => {
      let copiaProfundTodo = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
      cte.arrayLineas.forEach(lineas => {
        lineas.arrayArticulos = [];
        const articulosUnicos = new Set();
        // Filtrar los artículos únicos y asignarlos a lineas.arrayArticulos
        lineas.arrayArticulos = lineas.arrayArticulosTodos.filter(arti => {
          if (!articulosUnicos.has(arti.strArticulo)) {
            articulosUnicos.add(arti.strArticulo); // Agregar el strArticulo al Set
            return true; // Mantener el artículo en el nuevo array
          }
          return false; // Filtrar el artículo duplicado
        });
        lineas.arrayArticulos.forEach(art => {
          if (!art.arrayArt) {
            art.arrayArt = [];
          }
          art.arrayArt = copiaProfundTodo.filter(o => o.strArticulo === art.strArticulo).map(o => ({
            strArticulo: o.strArticulo,
            intPeriodo: o.intPeriodo,
            dblMargenBruto: o.dblSaldo,
            dblImporte: o.dblImporte
          }));

          art.dblEnero = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 1 ? acc + o.dblImporte : acc;
          }, 0);

          art.dblMargenEneroB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 1 ? acc + o.dblMargenBruto : acc;
          }, 0);

          art.dblMargenEnero = art.dblMargenEneroB > 0 ? ((art.dblMargenEneroB / art.dblEnero) * 100) : 0;
          // Febrero
          art.dblFebrero = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 2 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenFebreroB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 2 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenFebrero = art.dblMargenFebreroB > 0 ? ((art.dblMargenFebreroB / art.dblFebrero) * 100) : 0;

          // Marzo
          art.dblMarzo = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 3 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenMarzoB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 3 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenMarzo = art.dblMargenMarzoB > 0 ? ((art.dblMargenMarzoB / art.dblMarzo) * 100) : 0;

          // Abril
          art.dblAbril = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 4 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenAbrilB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 4 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenAbril = art.dblMargenAbrilB > 0 ? ((art.dblMargenAbrilB / art.dblAbril) * 100) : 0;

          // Mayo
          art.dblMayo = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 5 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenMayoB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 5 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenMayo = art.dblMargenMayoB > 0 ? ((art.dblMargenMayoB / art.dblMayo) * 100) : 0;

          // Junio
          art.dblJunio = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 6 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenJunioB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 6 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenJunio = art.dblMargenJunioB > 0 ? ((art.dblMargenJunioB / art.dblJunio) * 100) : 0;

          // Julio
          art.dblJulio = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 7 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenJulioB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 7 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenJulio = art.dblMargenJulioB > 0 ? ((art.dblMargenJulioB / art.dblJulio) * 100) : 0;

          // Agosto
          art.dblAgosto = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 8 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenAgostoB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 8 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenAgosto = art.dblMargenAgostoB > 0 ? ((art.dblMargenAgostoB / art.dblAgosto) * 100) : 0;

          // Septiembre
          art.dblSeptiembre = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 9 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenSeptiembreB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 9 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenSeptiembre = art.dblMargenSeptiembreB > 0 ? ((art.dblMargenSeptiembreB / art.dblSeptiembre) * 100) : 0;

          // Octubre
          art.dblOctubre = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 10 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenOctubreB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 10 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenOctubre = art.dblMargenOctubreB > 0 ? ((art.dblMargenOctubreB / art.dblOctubre) * 100) : 0;

          // Noviembre
          art.dblNoviembre = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 11 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenNoviembreB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 11 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenNoviembre = art.dblMargenNoviembreB > 0 ? ((art.dblMargenNoviembreB / art.dblNoviembre) * 100) : 0;

          // Diciembre
          art.dblDiciembre = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 12 ? acc + o.dblImporte : acc;
          }, 0);
          art.dblMargenDiciembreB = art.arrayArt.reduce((acc, o) => {
            return o.intPeriodo == 12 ? acc + o.dblMargenBruto : acc;
          }, 0);
          art.dblMargenDiciembre = art.dblMargenDiciembreB > 0 ? ((art.dblMargenDiciembreB / art.dblDiciembre) * 100) : 0;

          art.total = (Number(art.dblEnero) > 0 ? Number(art.dblEnero) : 0) +
            (Number(art.dblFebrero) > 0 ? Number(art.dblFebrero) : 0) +
            (Number(art.dblMarzo) > 0 ? Number(art.dblMarzo) : 0) +
            (Number(art.dblAbril) > 0 ? Number(art.dblAbril) : 0) +
            (Number(art.dblMayo) > 0 ? Number(art.dblMayo) : 0) +
            (Number(art.dblJunio) > 0 ? Number(art.dblJunio) : 0) +
            (Number(art.dblJulio) > 0 ? Number(art.dblJulio) : 0) +
            (Number(art.dblAgosto) > 0 ? Number(art.dblAgosto) : 0) +
            (Number(art.dblSeptiembre) > 0 ? Number(art.dblSeptiembre) : 0) +
            (Number(art.dblOctubre) > 0 ? Number(art.dblOctubre) : 0) +
            (Number(art.dblNoviembre) > 0 ? Number(art.dblNoviembre) : 0) +
            (Number(art.dblDiciembre) > 0 ? Number(art.dblDiciembre) : 0);

          art.totalMargenBruto = art.dblMargenEneroB + art.dblMargenFebreroB + art.dblMargenMarzoB +
            art.dblMargenAbrilB + art.dblMargenMayoB + art.dblMargenJunioB +
            art.dblMargenJulioB + art.dblMargenAgostoB + art.dblMargenSeptiembreB +
            art.dblMargenOctubreB + art.dblMargenNoviembreB + art.dblMargenDiciembreB;
          art.totalMargen = art.totalMargenBruto > 0 ? ((art.totalMargenBruto / art.total) * 100) : 0;
        });
      });
    });

    this.fnHistoricoVentaSumasLineas()

  }

  fnHistoricoVentaSumasLineas() {
    this.arrayFiltradoHistClientes.forEach(ctee => {
      ctee.arrayLineas.forEach(lin => {
        lin.sumEnero = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblEnero;
        }, 0);
        lin.dblMargenEneroB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenEneroB;
        }, 0);
        lin.dblMargenEnero = lin.dblMargenEneroB > 0 ? ((lin.dblMargenEneroB / lin.sumEnero) * 100) : 0;
        // Febrero
        lin.sumFebrero = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblFebrero;
        }, 0);
        lin.dblMargenFebreroB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenFebreroB;
        }, 0);
        lin.dblMargenFebrero = lin.dblMargenFebreroB > 0 ? ((lin.dblMargenFebreroB / lin.sumFebrero) * 100) : 0;

        // Marzo
        lin.sumMarzo = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMarzo;
        }, 0);
        lin.dblMargenMarzoB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenMarzoB;
        }, 0);
        lin.dblMargenMarzo = lin.dblMargenMarzoB > 0 ? ((lin.dblMargenMarzoB / lin.sumMarzo) * 100) : 0;

        // Abril
        lin.sumAbril = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblAbril;
        }, 0);
        lin.dblMargenAbrilB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenAbrilB;
        }, 0);
        lin.dblMargenAbril = lin.dblMargenAbrilB > 0 ? ((lin.dblMargenAbrilB / lin.sumAbril) * 100) : 0;

        // Mayo
        lin.sumMayo = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMayo;
        }, 0);
        lin.dblMargenMayoB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenMayoB;
        }, 0);
        lin.dblMargenMayo = lin.dblMargenMayoB > 0 ? ((lin.dblMargenMayoB / lin.sumMayo) * 100) : 0;

        // Junio
        lin.sumJunio = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblJunio;
        }, 0);
        lin.dblMargenJunioB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenJunioB;
        }, 0);
        lin.dblMargenJunio = lin.dblMargenJunioB > 0 ? ((lin.dblMargenJunioB / lin.sumJunio) * 100) : 0;

        // Julio
        lin.sumJulio = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblJulio;
        }, 0);
        lin.dblMargenJulioB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenJulioB;
        }, 0);
        lin.dblMargenJulio = lin.dblMargenJulioB > 0 ? ((lin.dblMargenJulioB / lin.sumJulio) * 100) : 0;

        // Agosto
        lin.sumAgosto = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblAgosto;
        }, 0);
        lin.dblMargenAgostoB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenAgostoB;
        }, 0);
        lin.dblMargenAgosto = lin.dblMargenAgostoB > 0 ? ((lin.dblMargenAgostoB / lin.sumAgosto) * 100) : 0;

        // Septiembre
        lin.sumSeptiembre = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblSeptiembre;
        }, 0);
        lin.dblMargenSeptiembreB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenSeptiembreB;
        }, 0);
        lin.dblMargenSeptiembre = lin.dblMargenSeptiembreB > 0 ? ((lin.dblMargenSeptiembreB / lin.sumSeptiembre) * 100) : 0;

        // Octubre
        lin.sumOctubre = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblOctubre;
        }, 0);
        lin.dblMargenOctubreB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenOctubreB;
        }, 0);
        lin.dblMargenOctubre = lin.dblMargenOctubreB > 0 ? ((lin.dblMargenOctubreB / lin.sumOctubre) * 100) : 0;

        // Noviembre
        lin.sumNoviembre = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblNoviembre;
        }, 0);
        lin.dblMargenNoviembreB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenNoviembreB;
        }, 0);
        lin.dblMargenNoviembre = lin.dblMargenNoviembreB > 0 ? ((lin.dblMargenNoviembreB / lin.sumNoviembre) * 100) : 0;

        // Diciembre
        lin.sumDiciembre = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblDiciembre;
        }, 0);
        lin.dblMargenDiciembreB = lin.arrayArticulos.reduce((acc, o) => {
          return acc + o.dblMargenDiciembreB;
        }, 0);
        lin.dblMargenDiciembre = lin.dblMargenDiciembreB > 0 ? ((lin.dblMargenDiciembreB / lin.sumDiciembre) * 100) : 0;

        lin.total = lin.sumEnero + lin.sumFebrero + lin.sumMarzo + lin.sumAbril + lin.sumMayo + lin.sumJunio +
          lin.sumJulio + lin.sumAgosto + lin.sumSeptiembre + lin.sumOctubre + lin.sumNoviembre + lin.sumDiciembre

        lin.totalMargenB = lin.dblMargenEneroB + lin.dblMargenFebreroB + lin.dblMargenMarzoB + lin.dblMargenAbrilB + lin.dblMargenMayoB +
          lin.dblMargenJunioB + lin.dblMargenJulioB + lin.dblMargenAgostoB + lin.dblMargenSeptiembreB + lin.dblMargenOctubreB + lin.dblMargenNoviembreB +
          lin.dblMargenDiciembreB

        lin.totalMargen = lin.totalMargenB > 0 ? ((lin.totalMargenB / lin.total) * 100) : 0
      });
    });


    this.arrayFiltradoHistClientes.forEach(lin => {
      /*   ctee.arrayLineas.forEach(lin => { */
      lin.sumEnero = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumEnero;
      }, 0);
      lin.dblMargenEneroB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenEneroB;
      }, 0);
      lin.dblMargenEnero = lin.dblMargenEneroB > 0 ? ((lin.dblMargenEneroB / lin.sumEnero) * 100) : 0;
      // Febrero
      lin.sumFebrero = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumFebrero;
      }, 0);
      lin.dblMargenFebreroB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenFebreroB;
      }, 0);
      lin.dblMargenFebrero = lin.dblMargenFebreroB > 0 ? ((lin.dblMargenFebreroB / lin.sumFebrero) * 100) : 0;

      // Marzo
      lin.sumMarzo = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumMarzo;
      }, 0);
      lin.dblMargenMarzoB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenMarzoB;
      }, 0);
      lin.dblMargenMarzo = lin.dblMargenMarzoB > 0 ? ((lin.dblMargenMarzoB / lin.sumMarzo) * 100) : 0;

      // Abril
      lin.sumAbril = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumAbril;
      }, 0);
      lin.dblMargenAbrilB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenAbrilB;
      }, 0);
      lin.dblMargenAbril = lin.dblMargenAbrilB > 0 ? ((lin.dblMargenAbrilB / lin.sumAbril) * 100) : 0;

      // Mayo
      lin.sumMayo = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumMayo;
      }, 0);
      lin.dblMargenMayoB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenMayoB;
      }, 0);
      lin.dblMargenMayo = lin.dblMargenMayoB > 0 ? ((lin.dblMargenMayoB / lin.sumMayo) * 100) : 0;

      // Junio
      lin.sumJunio = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumJunio;
      }, 0);
      lin.dblMargenJunioB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenJunioB;
      }, 0);
      lin.dblMargenJunio = lin.dblMargenJunioB > 0 ? ((lin.dblMargenJunioB / lin.sumJunio) * 100) : 0;

      // Julio
      lin.sumJulio = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumJulio;
      }, 0);
      lin.dblMargenJulioB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenJulioB;
      }, 0);
      lin.dblMargenJulio = lin.dblMargenJulioB > 0 ? ((lin.dblMargenJulioB / lin.sumJulio) * 100) : 0;

      // Agosto
      lin.sumAgosto = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumAgosto;
      }, 0);
      lin.dblMargenAgostoB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenAgostoB;
      }, 0);
      lin.dblMargenAgosto = lin.dblMargenAgostoB > 0 ? ((lin.dblMargenAgostoB / lin.sumAgosto) * 100) : 0;

      // Septiembre
      lin.sumSeptiembre = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumSeptiembre;
      }, 0);
      lin.dblMargenSeptiembreB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenSeptiembreB;
      }, 0);
      lin.dblMargenSeptiembre = lin.dblMargenSeptiembreB > 0 ? ((lin.dblMargenSeptiembreB / lin.sumSeptiembre) * 100) : 0;

      // Octubre
      lin.sumOctubre = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumOctubre;
      }, 0);
      lin.dblMargenOctubreB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenOctubreB;
      }, 0);
      lin.dblMargenOctubre = lin.dblMargenOctubreB > 0 ? ((lin.dblMargenOctubreB / lin.sumOctubre) * 100) : 0;

      // Noviembre
      lin.sumNoviembre = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumNoviembre;
      }, 0);
      lin.dblMargenNoviembreB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenNoviembreB;
      }, 0);
      lin.dblMargenNoviembre = lin.dblMargenNoviembreB > 0 ? ((lin.dblMargenNoviembreB / lin.sumNoviembre) * 100) : 0;

      // Diciembre
      lin.sumDiciembre = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.sumDiciembre;
      }, 0);
      lin.dblMargenDiciembreB = lin.arrayLineas.reduce((acc, o) => {
        return acc + o.dblMargenDiciembreB;
      }, 0);
      lin.dblMargenDiciembre = lin.dblMargenDiciembreB > 0 ? ((lin.dblMargenDiciembreB / lin.sumDiciembre) * 100) : 0;

      lin.total = lin.sumEnero + lin.sumFebrero + lin.sumMarzo + lin.sumAbril + lin.sumMayo + lin.sumJunio +
        lin.sumJulio + lin.sumAgosto + lin.sumSeptiembre + lin.sumOctubre + lin.sumNoviembre + lin.sumDiciembre

      lin.totalMargenB = lin.dblMargenEneroB + lin.dblMargenFebreroB + lin.dblMargenMarzoB + lin.dblMargenAbrilB + lin.dblMargenMayoB +
        lin.dblMargenJunioB + lin.dblMargenJulioB + lin.dblMargenAgostoB + lin.dblMargenSeptiembreB + lin.dblMargenOctubreB + lin.dblMargenNoviembreB +
        lin.dblMargenDiciembreB

      lin.totalMargen = lin.totalMargenB > 0 ? ((lin.totalMargenB / lin.total) * 100) : 0
    });


    this.objHistCliente.dblEnero = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumEnero ? objetoActual.sumEnero : 0);
    }, 0);

    this.objHistCliente.dblMargenEneroB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenEneroB ? objetoActual.dblMargenEneroB : 0);
    }, 0);

    this.objHistCliente.dblMargenEnero = this.objHistCliente.dblMargenEneroB > 0 ? ((this.objHistCliente.dblMargenEneroB / this.objHistCliente.dblEnero) * 100) : 0


    this.objHistCliente.dblFebrero = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumFebrero ? objetoActual.sumFebrero : 0);
    }, 0);

    this.objHistCliente.dblMargenFebreroB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenFebreroB ? objetoActual.dblMargenFebreroB : 0);
    }, 0);

    this.objHistCliente.dblMargenFebrero = this.objHistCliente.dblMargenFebreroB > 0 ? ((this.objHistCliente.dblMargenFebreroB / this.objHistCliente.dblFebrero) * 100) : 0;


    this.objHistCliente.dblMarzo = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumMarzo ? objetoActual.sumMarzo : 0);
    }, 0);

    this.objHistCliente.dblMargenMarzoB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenMarzoB ? objetoActual.dblMargenMarzoB : 0);
    }, 0);

    this.objHistCliente.dblMargenMarzo = this.objHistCliente.dblMargenMarzoB > 0 ? ((this.objHistCliente.dblMargenMarzoB / this.objHistCliente.dblMarzo) * 100) : 0;



    this.objHistCliente.dblAbril = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumAbril ? objetoActual.sumAbril : 0);
    }, 0);

    this.objHistCliente.dblMargenAbrilB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenAbrilB ? objetoActual.dblMargenAbrilB : 0);
    }, 0);

    this.objHistCliente.dblMargenAbril = this.objHistCliente.dblMargenAbrilB > 0 ? ((this.objHistCliente.dblMargenAbrilB / this.objHistCliente.dblAbril) * 100) : 0;


    this.objHistCliente.dblMayo = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumMayo ? objetoActual.sumMayo : 0);
    }, 0);

    this.objHistCliente.dblMargenMayoB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenMayoB ? objetoActual.dblMargenMayoB : 0);
    }, 0);

    this.objHistCliente.dblMargenMayo = this.objHistCliente.dblMargenMayoB > 0 ? ((this.objHistCliente.dblMargenMayoB / this.objHistCliente.dblMayo) * 100) : 0;


    this.objHistCliente.dblJunio = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumJunio ? objetoActual.sumJunio : 0);
    }, 0);

    this.objHistCliente.dblMargenJunioB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenJunioB ? objetoActual.dblMargenJunioB : 0);
    }, 0);

    this.objHistCliente.dblMargenJunio = this.objHistCliente.dblMargenJunioB > 0 ? ((this.objHistCliente.dblMargenJunioB / this.objHistCliente.dblJunio) * 100) : 0;


    this.objHistCliente.dblJulio = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumJulio ? objetoActual.sumJulio : 0);
    }, 0);

    this.objHistCliente.dblMargenJulioB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenJulioB ? objetoActual.dblMargenJulioB : 0);
    }, 0);

    this.objHistCliente.dblMargenJulio = this.objHistCliente.dblMargenJulioB > 0 ? ((this.objHistCliente.dblMargenJulioB / this.objHistCliente.dblJulio) * 100) : 0;


    this.objHistCliente.dblAgosto = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumAgosto ? objetoActual.sumAgosto : 0);
    }, 0);

    this.objHistCliente.dblMargenAgostoB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenAgostoB ? objetoActual.dblMargenAgostoB : 0);
    }, 0);

    this.objHistCliente.dblMargenAgosto = this.objHistCliente.dblMargenAgostoB > 0 ? ((this.objHistCliente.dblMargenAgostoB / this.objHistCliente.dblAgosto) * 100) : 0;



    this.objHistCliente.dblSeptiembre = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumSeptiembre ? objetoActual.sumSeptiembre : 0);
    }, 0);

    this.objHistCliente.dblMargenSeptiembreB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenSeptiembreB ? objetoActual.dblMargenSeptiembreB : 0);
    }, 0);

    this.objHistCliente.dblMargenSeptiembre = this.objHistCliente.dblMargenSeptiembreB > 0 ? ((this.objHistCliente.dblMargenSeptiembreB / this.objHistCliente.dblSeptiembre) * 100) : 0;


    this.objHistCliente.dblOctubre = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumOctubre ? objetoActual.sumOctubre : 0);
    }, 0);

    this.objHistCliente.dblMargenOctubreB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenOctubreB ? objetoActual.dblMargenOctubreB : 0);
    }, 0);

    this.objHistCliente.dblMargenOctubre = this.objHistCliente.dblMargenOctubreB > 0 ? ((this.objHistCliente.dblMargenOctubreB / this.objHistCliente.dblOctubre) * 100) : 0;


    this.objHistCliente.dblNoviembre = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumNoviembre ? objetoActual.sumNoviembre : 0);
    }, 0);

    this.objHistCliente.dblMargenNoviembreB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenNoviembreB ? objetoActual.dblMargenNoviembreB : 0);
    }, 0);
    this.objHistCliente.dblMargenNoviembre = this.objHistCliente.dblMargenNoviembreB > 0 ? ((this.objHistCliente.dblMargenNoviembreB / this.objHistCliente.dblNoviembre) * 100) : 0;


    this.objHistCliente.dblDiciembre = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.sumDiciembre ? objetoActual.sumDiciembre : 0);
    }, 0);

    this.objHistCliente.dblMargenDiciembreB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.dblMargenDiciembreB ? objetoActual.dblMargenDiciembreB : 0);
    }, 0);

    this.objHistCliente.dblMargenDiciembre = this.objHistCliente.dblMargenDiciembreB > 0 ? ((this.objHistCliente.dblMargenDiciembreB / this.objHistCliente.dblDiciembre) * 100) : 0;


    this.objHistCliente.dblTotal = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.total ? objetoActual.total : 0);
    }, 0);

    this.objHistCliente.dblTotalMargenB = this.arrayFiltradoHistClientes.reduce((acumulador, objetoActual) => {
      return acumulador + (objetoActual.totalMargenB ? objetoActual.totalMargenB : 0);
    }, 0);

    this.objHistCliente.dblTotalMargen = this.objHistCliente.dblTotalMargenB > 0 ? ((this.objHistCliente.dblTotalMargenB / this.objHistCliente.dblTotal) * 100) : 0


    /*  this.objHistCliente.dblMargenTotalB = this.objHistCliente.dblMargenEneroB + this.objHistCliente.dblMargenFebreroB +
     this.objHistCliente.dblMargenMarzoB + this.objHistCliente.dblMargenAbrilB + this.objHistCliente.dblMargenMayoB +
     this.objHistCliente.dblMargenJunioB + this.objHistCliente.dblMargenJulioB + this.objHistCliente.dblMargenAgostoB +
     this.objHistCliente.dblMargenSeptiembreB + this.objHistCliente.dblMargenOctubreB + this.objHistCliente.dblMargenNoviembreB +
     this.objHistCliente.dblMargenDiciembreB; */

    console.log(this.arrayFiltradoHistClientes);
  }

  /*   fnDividirEnClientes() {
      const clientesUnicos = new Set<string>();
      this.arrayFiltradoHistClientes = this.arrayHistVenta.filter(obj => {
        if (!clientesUnicos.has(obj.strCliente)) {
          clientesUnicos.add(obj.strCliente);
          return true;
        }
        return false;
      });
      this.fnLlenarArrayCompleto()
    } */

  /*   fnLlenarArrayCompleto() {
      this.spinerBool = true
      const deepCopyArray = JSON.parse(JSON.stringify(this.arrayHistVenta));
      this.arrayFiltradoHistClientes.forEach(cte => {
        cte.seeLineas = false
        if (!cte.arrayLineasTodo) {
          cte.arrayLineasTodo = [];
        }
        deepCopyArray.forEach(obj => {
          if (cte.strCliente == obj.strCliente) {
            cte.arrayLineasTodo.push(obj);
          }
        });
        if (!cte.arrayLineas) {
          cte.arrayLineas = [];
        }
        const LinCopy = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
        LinCopy.forEach(object => {
          const objetoEncontrado = cte.arrayLineas.find(obj => obj.strLinea === object.strLinea);
          const resultado = objetoEncontrado ? 1 : 0;
          if (resultado == 0) {
            cte.arrayLineas.push(object);
          }
        });
        cte.arrayLineas.forEach(lineas => {
          lineas.seeArts = false;
          if (!lineas.arrayArticulos) {
            lineas.arrayArticulos = [];
          }
          const LinCopy2 = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
          LinCopy2.forEach(lin => {
            if (lin.strLinea == lineas.strLinea) {
              lineas.arrayArticulos.push(lin)
            }
          });
        });
      });
      this.spinerBool = false
      this.fnHistoricoVentaSumasLineas();
   
    }
  
    fnHistoricoVentaSumasLineas() {
      let sumEnero = 0, sumFebrero = 0, sumMarzo = 0, sumAbril = 0,
        sumMayo = 0, sumJunio = 0, sumJulio = 0, sumAgosto = 0,
        sumSeptiembre = 0, sumOctubre = 0, sumNoviembre = 0, sumDiciembre = 0;
  
      this.arrayFiltradoHistClientes.forEach(cte => {
        cte.arrayLineas.forEach(lin => {
          sumEnero = 0; sumFebrero = 0; sumMarzo = 0;
          sumAbril = 0; sumMayo = 0; sumJunio = 0;
          sumJulio = 0; sumAgosto = 0; sumSeptiembre = 0;
          sumOctubre = 0; sumNoviembre = 0; sumDiciembre = 0;
          lin.arrayArticulos.forEach(art => {
            sumEnero += art.dblEnero;
            sumFebrero += art.dblFebrero;
            sumMarzo += art.dblMarzo;
            sumAbril += art.dblAbril;
            sumMayo += art.dblMayo;
            sumJunio += art.dblJunio;
            sumJulio += art.dblJulio;
            sumAgosto += art.dblAgosto;
            sumSeptiembre += art.dblSeptiembre;
            sumOctubre += art.dblOctubre;
            sumNoviembre += art.dblNoviembre;
            sumDiciembre += art.dblDiciembre;
          });
          lin.sumEnero = sumEnero
          lin.sumFebrero = sumFebrero
          lin.sumMarzo = sumMarzo
          lin.sumAbril = sumAbril
          lin.sumMayo = sumMayo
          lin.sumJunio = sumJunio
          lin.sumJulio = sumJulio
          lin.sumAgosto = sumAgosto
          lin.sumSeptiembre = sumSeptiembre
          lin.sumOctubre = sumOctubre
          lin.sumNoviembre = sumNoviembre
          lin.sumDiciembre = sumDiciembre
        });
      });
  
      this.fnHistoricoVentaSumas()
    } */

  /*  fnHistoricoVentaSumas() {
     let sumEnero = 0, sumFebrero = 0, sumMarzo = 0, sumAbril = 0,
       sumMayo = 0, sumJunio = 0, sumJulio = 0, sumAgosto = 0,
       sumSeptiembre = 0, sumOctubre = 0, sumNoviembre = 0, sumDiciembre = 0;
 
     this.arrayFiltradoHistClientes.forEach(lin => {
       sumEnero = 0; sumFebrero = 0; sumMarzo = 0;
       sumAbril = 0; sumMayo = 0; sumJunio = 0;
       sumJulio = 0; sumAgosto = 0; sumSeptiembre = 0;
       sumOctubre = 0; sumNoviembre = 0; sumDiciembre = 0;
       lin.arrayLineas.forEach(art => {
         sumEnero += art.sumEnero;
         sumFebrero += art.sumFebrero;
         sumMarzo += art.sumMarzo;
         sumAbril += art.sumAbril;
         sumMayo += art.sumMayo;
         sumJunio += art.sumJunio;
         sumJulio += art.sumJulio;
         sumAgosto += art.sumAgosto;
         sumSeptiembre += art.sumSeptiembre;
         sumOctubre += art.sumOctubre;
         sumNoviembre += art.sumNoviembre;
         sumDiciembre += art.sumDiciembre;
       });
       lin.sumEnero = sumEnero
       lin.sumFebrero = sumFebrero
       lin.sumMarzo = sumMarzo
       lin.sumAbril = sumAbril
       lin.sumMayo = sumMayo
       lin.sumJunio = sumJunio
       lin.sumJulio = sumJulio
       lin.sumAgosto = sumAgosto
       lin.sumSeptiembre = sumSeptiembre
       lin.sumOctubre = sumOctubre
       lin.sumNoviembre = sumNoviembre
       lin.sumDiciembre = sumDiciembre
     });
    
   } */

  fnGetFlujoCliente() {
    let obj = {
      strAccion: 'getFlujoCte_Suc',
      strCliente: this.strCliente,
      intId_Direccion: this.intId_Direccion,
      strConexion: this.strConexion
    }
    console.log(obj)
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayFlujoCliente = result
        this.fnDividirFlujoCte();

      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnDividirFlujoCte() {
    const flujoCte = new Set<string>();
    this.arrayFlujoClienteFiltrado = this.arrayFlujoCliente.filter(obj => {
      if (!flujoCte.has(obj.strCliente)) {
        flujoCte.add(obj.strCliente);
        return true;
      }
      return false;
    });
    /*    console.log(this.arrayFlujoClienteFiltrado ); */
    this.fnLlenarArrayFlujoCte()
  }

  fnLlenarArrayFlujoCte() {
    this.spinerBool = true
    const deepCopyArray = JSON.parse(JSON.stringify(this.arrayFlujoCliente));
    this.arrayFlujoClienteFiltrado.forEach(cte => {
      cte.seeLineas = false
      if (!cte.arrayXcte) {
        cte.arrayXcte = [];
      }
      deepCopyArray.forEach(obj => {
        if (cte.strCliente == obj.strCliente) {
          cte.arrayXcte.push(obj);
        }
      });
      if (!cte.arrayEjercicio) {
        cte.arrayEjercicio = [];
      }
      const LinCopy = JSON.parse(JSON.stringify(cte.arrayXcte));
      LinCopy.forEach(object => {
        const objetoEncontrado = cte.arrayEjercicio.find(obj => obj.intEjercicio === object.intEjercicio);
        const resultado = objetoEncontrado ? 1 : 0;
        if (resultado == 0) {
          cte.arrayEjercicio.push(object);
        }
      });
      cte.arrayEjercicio.forEach(lineas => {
        lineas.seePer = false;
        if (!lineas.arrayPeriodo) {
          lineas.arrayPeriodo = [];
        }
        const LinCopy2 = JSON.parse(JSON.stringify(cte.arrayXcte));
        LinCopy2.forEach(lin => {
          if (lin.intEjercicio == lineas.intEjercicio) {
            lineas.arrayPeriodo.push(lin)
          }
        });
      });
    });
    this.spinerBool = false
    this.fnFlujoCteSumasLineas()
  }





















































































































  fnGetFamilias() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getFamilias',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayFamilias = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }



  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
  }



  enviarDetalleOporturnidad(item: any) {
    console.log(item)
    this.router.navigate(['/detoport'], { queryParams: { idLead: item.intID_Leads_Oportunidad, sts: item.strEstatus } });
  }

  enviarDetalleCliente(item: any) {
    this.router.navigate(['/detalleLeadCliente'], { queryParams: { strCliente: item.strCliente } });
  }


  fnModalRegistrar(tipo: string) {
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = tipo;
    this.arrayAgentesActividades = [];
    this.arrayAnexMdlAdd = [];
    this.fileInputActividades.nativeElement.value = "";
    this.arrayAgregarAnexosActividades = [];
    this.strBuscarAgente = ""
this.inputComentarios.nativeElement.innerHTML = ""
    
  }

  fncambiarHoraInicio() {
    if (this.time1 == "am") {
      this.mdlHoraInicio = "07:00";
    } else if (this.time1 == "pm") {
      this.mdlHoraInicio = "00:00";
    }
  }

  fncambiarHoraFin() {
    if (this.time2 == "am") {
      this.mdlHoraFin = "07:00";
    } else if (this.time2 == "pm") {
      this.mdlHoraFin = "00:00";
    }
  }

  fnllenarArrayTareas() {
    /*  this.arrayActividades = this.arrayOport.filter(o => o.strTipo == "Visita" || o.strTipo == "Llamada" || o.strTipo == "Correo") */
console.log( this.intID_Leads,)
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getActividades',
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion,
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayActividades = result
        if (this.arrayActividades.length > 0) {
          this.arrayActividades.forEach(obj => {
            // Paso 1: Convertir strFechaRequerida a un objeto Date
            let fechaRequerida = new Date(obj.strFechaRequerida);
            // Paso 2: Obtener la fecha actual
            let fechaActual = new Date();
            // Paso 3: Calcular la diferencia en milisegundos
            let diferenciaMilisegundos = fechaRequerida.getTime() - fechaActual.getTime();
            // Paso 4: Convertir la diferencia de milisegundos a días
            let diferenciaDias = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
            obj.diasDiferencia = diferenciaDias
          });
        }
        this.datosTareasCte = new MatTableDataSource(this.arrayActividades);
        this.fnTabletareasCte();
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  transform(value: string): string {
    if (!value) return value;
    const datePart = value.split(' ')[0];
    return datePart;
  }

  fnConcluirActividad(objAct: any) {
    let processedContent = this.contenido;
    processedContent = processedContent.replace(/<img/g, '<img class="img-fluid"');
    console.log(processedContent)
    // Ahora se puede asignar el contenido procesado a [innerHTML]
    this.contenido = processedContent;
    let objEnv = {
      strAccion: 'update_Actividad',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intID_Leads,
      strComentario: this.contenido,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }
    console.log(objEnv)
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = "";
          this.contenido = "";
          this.fnGetTimeLine();
          this.fnllenarArrayTareas();
          this.btnCerrarAct.nativeElement.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        } 
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnGetSuc() {
    let objEnv = {
      strAccion: 'getCte_Familia',
      strFamilia: this.strFamilia,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arraySucursales = result;
        this.fnGetObjetivos()
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  }


  onTimeChange(event: any, obj: string) {
    if (obj == "fin") {
      this.fncambiarHoraFin()
    } else if (obj == "inicio") {
      this.fncambiarHoraInicio()
    }
  }

  formatoFecha(fecha: Date): string {
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }


  convertDateToString(dateString: Date): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  fnEnviarActividad(anexos:any ={}) {

    let xml: any = []
    if (this.arrayAgentesActividades.length > 0) {
      this.arrayAgentesActividades.forEach(item => {
        xml.push({ strAgente: item.strAgente, strNombreAgente: item.strNombre })
      });
    }

    if (this.strfecha == null || this.strfecha == undefined) {
      Swal.fire("Error", "seleccione una fecha correcta", "error");
    } else {
      let objEnv: { [key: string]: any } = {
        strAccion: 'setActividad_Leads_Familia_Multiple',/* setActividad_Leads_Familia */
        intID_TIPO: this.objModal.titulo == "Correo" ? 1 : this.objModal.titulo == "Llamada" ? 3 : this.objModal.titulo == "Visita" ? 2 : 6,
        strTitulo: this.mdlNombre,
        strAgente: "",/* this.strAgente */
        strFechaInicioServicio: this.convertDateToString(this.strfecha),
        xmlArticulos: xml,
        strConexion: this.strConexion,
        strComentario: this.mdlComentarios,
        intID_Leads: this.intID_Leads,
        strAgente_Crea: this.strAgente_Crea
      }
      if (this.objModal.titulo != "Tarea") {
        objEnv.strHoraInicio = this.mdlHoraInicio + this.time1;
        objEnv.strHoraFin = this.mdlHoraFin + this.time2;
      }
      if(anexos && Object.keys(anexos).length>0){
        objEnv.strTipo_CIERRE = anexos.strTipo;
        objEnv.strUrl_Logo = anexos.strRespuesta;
        objEnv.strResultado = anexos.strNombre;
        objEnv.strMotivo = ""
      }
      if(this.objModal.titulo == "Subtarea"){
        objEnv.intID_Actividad = this.objSubtarea.intID_Actividad;
      }
   


      this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
        result => {
          Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          if (result[0].code == "200") {
            this.arrayAgentesActividades = [];
            this.fnllenarArrayTareas();
            this.fnGetTimeLine();
            this.btnCerrar.nativeElement.click();
          }
        }, error => {
          var error = <any>error; console.log(error);
        }
      );
    }
  }



  onSucursalChange(event: any): void {
    this.fnGetObjetivos();
    this.strBuscarHistVta = event.value
    if (event.value == "") {
      this.boolSuc = false
    } else {
      this.boolSuc = true
    }
  }

  fnmDLConAct(obj) {
    this.intIDActividad = obj.intID_Actividad
    let idAct = obj.intID_Actividad, Act: any;
    this.fnInitSummer();
    Act = this.arrayActividades.find(x => x.intID_Actividad == idAct);
    if(Act){
      this.objAct = Act;
    }else{
      Act = this.arraySubTareas.find(x => x.intID_Actividad == idAct);
      this.objAct = Act;
    }
    this.fngetComentarios()
    let agente = this.arrayAgentes.find(x => x.strAgente == Act.strAgente_Crea);
    this.objAct.nombreCrea = agente.strNombre;
    if (obj.strEstatus == "CONCLUIDO" || this.objAct?.strTipo == 'Update_Lead_Tarea' || obj.strEstatus == "POR AUTORIZAR") {
      this.fnGetDetActi(obj);
    }
    
    this.fnGetAnexosActividades()
  }

  fnGetDetActi(objAct: any) {
    this.objDetActividad = {};
    let objEnv = {
      strAccion: 'getDetalleActividad',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }
    console.log(objEnv);
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.objDetActividad = result[0];
        this.objAct.strObservaciones = this.objDetActividad.strObservaciones == "" ? "--campo vacio--" : this.objDetActividad.strObservaciones
        this.objAct.strResultado = this.objDetActividad.strResultado == "" ? "0" : this.objDetActividad.strResultado
      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterArt(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceC.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceC.paginator) {
      this.dataSourceC.paginator.firstPage();
    }
  }
  /* Historico de venta */

  fnCambiarEstatusVista(ind: number, obj: any) {
    this.arrayFiltradoHistClientes.forEach(el => {
      if (el.strCliente == obj.strCliente) {
        if (el.seeLineas == false) {
          el.seeLineas = true
        } else {
          el.seeLineas = false
        }
      } else {
        el.seeLineas = false
      }
    });
  }

  fnCambiarEstatusVistaLineas(ind: number, obj: any, ob: any) {
    this.arrayFiltradoHistClientes.forEach(el => {
      if (el.strCliente == ob.strCliente) {
        el.arrayLineas.forEach(el => {
          if (el.strLinea == obj.strLinea) {
            if (el.seeArts == false) {
              el.seeArts = true
            } else {
              el.seeArts = false
            }
          } else {
            el.seeArts = false
          }
        });
      }
    });
  }
  /* Flujo de cliente */


  fnCambiarEstatusVistaFlujoCte(ind: number, obj: any) {
    this.arrayFlujoClienteFiltrado.forEach(el => {
      if (el.strCliente == obj.strCliente) {
        if (el.seeLineas == false) {
          el.seeLineas = true
        } else {
          el.seeLineas = false
        }
      } else {
        el.seeLineas = false
      }
    });
  }

  fnCambiarEstatusVistaLineasCteFlujo(ind: number, obj: any, ob: any) {
    this.arrayFlujoClienteFiltrado.forEach(el => {
      if (el.strCliente == ob.strCliente) {
        el.arrayEjercicio.forEach(el => {
          if (el.intEjercicio == obj.intEjercicio) {
            if (el.seePer == false) {
              el.seePer = true
            } else {
              el.seePer = false
            }
          } else {
            el.seePer = false
          }
        });
      }
    });
  }

  cambiarImgLogo(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedRenovFile = fileInput.files[0];
      this.strDBRutaLogo = 'Adjuntos/Leads/Logo/' + this.selectedRenovFile.name
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedRenovFile);
      reader.onload = (event: any) => {
        this.strRutaLogo = event.target.result;
      };
    }
  }

  fnAnexoMov() {
    const formData = new FormData();
    formData.append('archivo', this.selectedRenovFile);
    this._serviciosService.setAnexMov1(formData).subscribe(
      (resultados: any) => {
        if (resultados.mensaje == '200') {
          Swal.fire({
            icon: 'success',
            title: 'Oops...',
            text: 'Actualizado!',
          });
          this.fnUpdateLogo()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error al subir imagen!',
          });
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al subir imagen!',
        });
      }
    );
  }

  fnUpdateLogo() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Logo_Leads',
      intID_Leads: this.intID_Leads,
      strUrl_Logo: this.strDBRutaLogo,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetInfo()
          /*   this.fnDetalleLead() */
          this.strRutaLogo = ''
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }






  fnFlujoCteSumasLineas() {

    this.arrayFlujoClienteFiltrado.forEach(cte => {

      cte.arrayEjercicio.forEach(ejer => {
        let sumImporte = 0, sumCobrado = 0, sumSaldo = 0;
        ejer.arrayPeriodo.forEach(per => {
          sumImporte += per.dblImporte
          sumCobrado += per.dblCobrado
          sumSaldo += per.dblSaldo
        });
        ejer.sumImporte = sumImporte
        ejer.sumCobrado = sumCobrado
        ejer.sumSaldo = sumSaldo
      });
    });

    this.fnFlujoCteSumas()
  }

  fnFlujoCteSumas() {
    this.arrayFlujoClienteFiltrado.forEach(cte => {
      let sumImporte = 0, sumCobrado = 0, sumSaldo = 0;
      cte.arrayEjercicio.forEach(ejer => {
        sumImporte += ejer.sumImporte
        sumCobrado += ejer.sumCobrado
        sumSaldo += ejer.sumSaldo
      });
      cte.sumImporte = sumImporte
      cte.sumCobrado = sumCobrado
      cte.sumSaldo = sumSaldo
    });
    this.fnGetDetalleCotizacion();
  }



  onGiroChange(event: any, tipo: string) {
    let clasificacion = event.target.value.split(',')[1];
    if (tipo === 'presencia') {
      this.strClasificacion = clasificacion + this.strClasificacion.substring(1);
    } else if (tipo === 'sucursales') {
      this.strClasificacion = this.strClasificacion.charAt(0) + clasificacion + this.strClasificacion.substring(2);
    } else {
      this.strClasificacion = this.strClasificacion.substring(0, 2) + clasificacion;
    }
    this.objInfo.strClasificacion
  }




  cambiarFecha(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaEstimadaCierre = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
    this.strFechaEstimadaCierreInput = fecha[0] + '-' + fecha[1] + '-' + fecha[2]
  }
  toggleCotizacion(cotizacion: any) {
    this.arrayTipoCotizacion.forEach(e => {
      if (cotizacion == e.strTipoCotizacion) {
        if (e.Seleccionado) {
          e.Seleccionado = false;
        } else {
          e.Seleccionado = true;
        }
      }
    });
  }

  agregarAnexo(event: any) {
    const fecha = new Date();
    const archivo: File = event.target.files[0];

    const tipo = archivo.type.split('/');
    if (tipo[1] == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      tipo[1] = "xlsx";
    }
    const nombreArchivo = this.intID_Leads + '-' + this.strTipoAnexo + ' ' + fecha.getFullYear() + '-' + fecha.getMonth() + '-' + fecha.getDay() + ' ' + fecha.getHours() + ':' + fecha.getMinutes() + ':' + fecha.getSeconds() + '.' + tipo[1];
    const rutaArchivo = 'EvidenciaLeads/' + nombreArchivo;

    const nuevoArchivo = new File([archivo], nombreArchivo, { type: archivo.type });
    this.ListaAnexos.push(nuevoArchivo);

    this.arrayAnexosUpdate.push({
      strTipo: tipo[1],
      strUrl: rutaArchivo,
      strTipoDocumento: this.strTipoAnexo,
      strNombre: nombreArchivo
    });

  }

  // funciones subir excel FAMILIA
  handleFileInput(event: any) {
    const fileInput = event.target as HTMLInputElement;
    const fileList = fileInput.files;

    //this.nameArchivo = fileList[0].name;

    this.Readexcel(fileList[0]);
  }

  Readexcel(file: File) {
    //this.spinner = true;
    this.excelData = []
    this.excelDataAux = []
    let filereader = new FileReader();
    filereader.readAsBinaryString(file);
    filereader.onload = (e) => {
      try {
        let arrayErrores: any = []
        var workBook = XLSX.read(filereader.result, { type: 'binary' });
        var sheetNames = workBook.SheetNames;
        this.excelDataAux = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]]);
        let todosTienenCampos: any

        todosTienenCampos = this.excelDataAux.every(item =>
          item.hasOwnProperty('CODIGO_POSTAL') &&
          item.hasOwnProperty('CONTACTO_TELEFONO') &&
          item.hasOwnProperty('CONTACTO_NOMBRE')
        );


        if (todosTienenCampos) {
          this.excelDataAux.forEach((e: any) => {

            if (e.CODIGO_POSTAL.toString().length != 5) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Registros con codigo postal invalido, Favor de agregarlo y adjuntar de nuevo el archivo excel!",
              });
              arrayErrores.push(e.CODIGO_POSTAL)
              this.excelDataAux = []
            } else if (!this.contieneSoloNumeros(e.CODIGO_POSTAL)) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Registros con codigo postal invalido, Favor de revisarlo y adjuntar de nuevo el archivo excel!",
              });
              arrayErrores.push(e.CODIGO_POSTAL)
              this.excelDataAux = []
            }
            else { }
          });

          if (arrayErrores.length == 0) {
            const xmlCP = this.excelDataAux.map((cp: any) => {
              return { strCodigoPostal: cp.CODIGO_POSTAL };
            });

            this.fnValidarCP(xmlCP)

          } else {
            this.excelDataAux = []
            console.log('no se puede hacer esta transaccion')
          }
        } else {
          try {
            this.excelDataAux = []
            this.excelData = []

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Campos Incompletos!, Revisa que todos los campos esten con información",
            });
            const fileInput = this.fileInput2.nativeElement as HTMLInputElement;
            fileInput.value = ''; // Esto debería resetear el input file

            // Forzar el cambio de valor para disparar el evento 'change'
            fileInput.dispatchEvent(new Event('input', { bubbles: true }));
            fileInput.dispatchEvent(new Event('change', { bubbles: true }));
          } catch (error) {
            console.log('error')
          }


        }

      } catch (error) {

        this.excelDataAux = [];
        //this.spinner = false;
        Swal.fire({ icon: 'error', title: 'Error', text: 'El archivo seleccionado no es un archivo valido, descarga el layout y vuelve a intentarlo.', });
      }
    }
  }

  fnValidarCP(xmlCP: any) {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'setValidaCP',
      xmlAnexos: xmlCP,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });

          this.excelData = this.excelDataAux
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          /*Swal.fire({
            icon: "error",
            title: "Oops...",
            text: 'Error al validar documento',
          });*/
          this.excelData = []
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  contieneSoloNumeros(cadena: string): boolean {
    // Iterar sobre cada carácter de la cadena
    for (let i = 0; i < cadena.length; i++) {
      // Verificar si el carácter actual no es un dígito numérico
      if (!this.esDigitoNumerico(cadena[i])) {
        return false; // Se encontró un carácter que no es un número
      }
    }
    return true; // Todos los caracteres son numéricos
  }
  private esDigitoNumerico(caracter: string): boolean {
    return /^[0-9]$/.test(caracter); // Utiliza una expresión regular para verificar si el carácter es un número del 0 al 9
  }

  /*   fnDescargarFormatoFamilia() {
      const rutaArchivo = 'assets/FormatoSucursalesFamilia.xlsx'
      const link = document.createElement('a');
      link.href = rutaArchivo;
      link.download = 'FormatoSucursalesFamilias.xlsx';
      link.click();
    } */
  handleDrop(event: DragEvent) {

    event.preventDefault();
    const fileList = event.dataTransfer.files;

    if (fileList.length > 0) {
      //this.capturador = false;

      // this.nameArchivo= fileList[0].name;
      this.Readexcel(fileList[0]);
    } else {
      // this.spinner = false;
    }
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    // Agregar estilos para resaltar el área de soltar
  }
  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    // Eliminar estilos de resaltado cuando se sale del área de soltar
  }


  /* fnInsertSucursales() {
    let objEnv = {
      strAccion: 'setLeads_Cte_Suc',
      xmlSucursales: this.excelData,
      strAgente: this.objInfo.strAgente,
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    console.log(objEnv)
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if(result[0].code == 200){
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetListadoSucursal();
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      }, error => {
        var error = <any>error; console.log(error);
      }
    );
  } */


  fnGetListadoSucursal() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'GetListado_Suc_Familia',
      intID_leads: this.intID_Leads,
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arrayListadoSucursales = result
        let razonesSociales: any = []
        this.arrayListadoSucursales.forEach((element: any) => {

          let nombre = element.strNombreEmpresa_Cliente
          if (razonesSociales.includes(nombre)) {
          } else {
            razonesSociales.push(nombre)
          }
        });

        let arrayRazon: any = []
        razonesSociales.forEach(element => {
          let filtro = this.arrayListadoSucursales.filter((b: any) => b.strNombreEmpresa_Cliente == element)
          arrayRazon.push(filtro)
        });

      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnGetFormulario() {
    this.arraySecciones = []
    let objEnv = {
      strAccion: 'getFormulario',
      intID_Giro: this.intGiro,
      intID_leads: this.objInfo.intID_Leads,
      strConexion: this.strConexion
    }
    /* console.log(objEnv) */
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
      
        let seccionesMap = new Map<string, any[]>();
        result.forEach((element: any) => {
          let seccion = element.strSeccion;
          if (!seccionesMap.has(seccion)) {
            seccionesMap.set(seccion, []);
            this.arrayNombresSecciones.push(seccion);
          }
          seccionesMap.get(seccion).push(element);
        });
       
        this.intTotalPreguntas = 0;
        let resultado: any = ''
        seccionesMap.forEach((arregloAux: any[], seccion: any, index: any) => {
          resultado = arregloAux[0].strResultado
          const arrayPreguntas = arregloAux.map((b: any) => ({
            strRespuesta: (!b.strResultado) ? '' : b.strResultado,
            intID_Formulario: b.intID_Formulario,
            strTipoRespuesta: b.strTipoRespuesta,
            strPregunta: b.strPregunta,
            arrayRespuestas: b.strTipoRespuesta === 'LISTA' ? b.strRespuesta.split(',') : [],
            depende_pregunta_id: b.intDependeId,
            depende_pregunta_text: b.strDependeRespuesta,
            disabled: false,
            disabledRespuesta: (b.strResultado) ? true : false
          }));

          this.intTotalPreguntas += arregloAux.length;

          this.arraySecciones.push({
            index,
            strSeccion: seccion,
            intTotalPreguntas: arregloAux.length,
            intTotalContestadas: (resultado) ? arregloAux.length : 0,
            preguntas: arrayPreguntas,
          });
        });

        /* console.log(this.arraySecciones) */
        this.banderaFormulario = (resultado) ? true : false


      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnNumberPregunta(event: any, respuestas: any) {
    let filtro = Array.from(new Set(respuestas.preguntas
      .filter((b: any) => b.depende_pregunta_id > 0)
      .map((b: any) => b.depende_pregunta_id)));

    if (filtro.length > 0) {
      filtro.forEach((element: any) => {
        let depende = respuestas.preguntas.find((b: any) => b.intID_Formulario == element);
        let resp = respuestas.preguntas.filter((b: any) => b.depende_pregunta_id == element);

        if (depende.strRespuesta != resp[0].depende_pregunta_text) {
          resp.forEach((b: any) => {
            b.strRespuesta = 'N/A';
            b.disabled = true;
          });
        } else {
          resp.forEach((b: any) => {
            if (b.strRespuesta == 'N/A') {
              b.strRespuesta = '';
            }
            b.disabled = false;
          });
        }
      });
    }

    respuestas.intTotalContestadas = respuestas.preguntas.reduce((total: number, element: any) => {
      return total + (element.strRespuesta !== '' ? 1 : 0);
    }, 0);

    if ((respuestas.intTotalContestadas == respuestas.intTotalPreguntas) && this.intTotalPreguntas > this.intTotalContestadas) {
      this.intTotalContestadas += respuestas.intTotalContestadas;
    }

  }


  fnSETFORM_RESPUESTA() {
    console.log(this.arraySecciones)
    let xmlRespuestas: any = []
    this.arraySecciones.forEach((element: any) => {
      console.log(element)
      element.preguntas.forEach((pregunta: any) => {
        let data = {
          intID_Formulario: pregunta.intID_Formulario,
          strRespuesta: pregunta.strRespuesta
        }

        xmlRespuestas.push(data)
      });
    });
    console.log(xmlRespuestas)

    let objEnv = {
      strAccion: 'SETFORM_RESPUESTA',
      xmlAnexos: xmlRespuestas,
      intID_Leads: this.intID_Leads,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_modVentas_Leads').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fnUpdateLead_Nuevo()
          this.fnGetFormulario()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje
          });
        }
      }, error => {

        var error = <any>error; console.log(error);
      }
    );
  }

  // Método para agregar la imagen al editor
  addImageToEditor(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const imgNode = $('<img>').attr('src', e.target.result as string).css({
        width: '300px !important',  // Ancho fijo con !important
        height: 'auto !important'   // Mantiene la proporción con !important
      });
      $('.summernote').summernote('insertNode', imgNode[0]);
    };
    reader.readAsDataURL(file);
  }

  adjustImageSizes() {
    $('.summernote').summernote('code', function (contents) {
      const $contents = $('<div>').html(contents);
      $contents.find('img').each(function () {
        $(this).css({
          width: '100px !important',  // Ancho deseado
          height: '100px !important'   // Mantiene la proporción
        }).addClass('custom-img-size'); // Asegurar que tenga la clase personalizada
      });
      return $contents.html();
    });
  }

  fnRechazar(objAct: any) {
    console.log(objAct)
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Rechazar',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = ""
          this.contenido = "";
          this.fnllenarArrayTareas();
          this.btnCerrarAct.nativeElement.click();


        } else {
          this.contenido = ''

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fnEnProceso(objAct: any) {
    console.log(objAct)
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'update_Actividad_Proceso',
      intID_Actividad: objAct.intID_Actividad,
      intID_Leads: this.intIDLeads,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.strResultado = "";
          this.contenido = "";
          this.fnllenarArrayTareas()
          this.btnCerrarAct.nativeElement.click();

        } else {
          this.contenido = ''
          /*    this.strMensaje = '' */
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnFiltrarSegundaLetra() {
    this.arraySubGiro = this.arraySubGiroTotal.filter((b: any) => b.intId_Giro == this.formLead.value.intId_Giro)
 
    this.arrayFitroSegunda = this.arraySegundaLetra.filter((b: any) => b.intIDPresencia == this.intIDPresencia && b.intId_Giro == this.intGiro) 
    this.arrayFiltroTercera = this.arrayTercerLetra.filter((b: any) => b.intIDPresencia == this.intIDPresencia && b.intId_Giro == this.intGiro)

    const potenciador = this.arrayGiros.filter((b: any) => b.intId_Giro == this.intGiro)
    this.intPotenciador = potenciador && potenciador[0] ? potenciador[0].intPotenciador : 0;
  }
  fngetSectores() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSector',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arraySectores = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  toggleMultiple(linea: any) {

    this.arrayPotenciador.forEach(e => {
      if (linea == e.strLinea) {
        if (e.Seleccionado) {
          e.Seleccionado = false;
        } else {
          e.Seleccionado = true;
        }
      }
    });

  }

  fngetSegundaLetra() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSegundaLetra',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arraySegundaLetra = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fngetTerceraLetra() {

    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getTerceraLetra',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => { 
        this.arrayTercerLetra = result
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }
  fngetSubGiros() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getSubGiro',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {

        this.arraySubGiroTotal = result
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  formatoFecha2(fecha: Date): string {
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }
  onInput(event: Event) {
    const target = event.target as HTMLElement;
    this.mdlComentarios = target.innerHTML; // Guarda el contenido HTML
    /*    console.log( this.mdlComentarios) */
  }
  fnEliminarAgenteAct(agt: any) {

    this.arrayAgentesActividades = this.arrayAgentesActividades.filter(item => item !== agt);
  }

  fnInsertComentario() {

    let obj = {
      strAccion: 'setComentario_Leads_Actividad',
      intID_Actividad: this.intIDActividad,
      strAgente: this.strAgente,
      strComentario: this.strComentarioD,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          if (result[0].code == 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: result[0].strMensaje,
              showConfirmButton: false,
              timer: 1500
            });
            this.strComentarioD = ''
            this.fngetComentarios();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: result[0].strMensaje,
            });
          }
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerBool = false
        });
  }

  fngetComentarios() {

    let obj = {
      strAccion: 'getListComentario_Actividad',
      intID_Actividad: this.intIDActividad,
      strConexion: this.strConexion
    }
    this._serviciosService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.arrayComentarios = result
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerBool = false
        });
  }
  fnFormOportunidades() {
    this.arrayLineasSelect = [];
    this.arrayLineas2.forEach(lin => {  
      lin.select = false;
    });
    this.formOportunidades = this.formbuilder.group({
      strTitulo: new FormControl('', [Validators.required]),
      intMonto: new FormControl(0, [Validators.required]),
      strLinea: new FormControl('', []),
      strArticulo: new FormControl('', []),
      strFecha: new FormControl('', [Validators.required]),
      strResponsable: new FormControl('', []),
      strResponsable2: new FormControl('', [Validators.required]),
    });

  }
  onSubmitOportunidades() {
    if (this.formOportunidades.valid) {
      this.fnInsertOportunidad(this.formOportunidades.value);
      // Procesar los datos del formulario
      /*    console.log(this.formOportunidades.value); */
    }
  }
  fnAddLinea(obj:any){
    obj.select = true;
    let bandera = 0;
    this.arrayLineasSelect.forEach(lin => {
      if(lin.strLinea == obj.strLinea){
        bandera +=1 
      }
    });
    if(bandera == 0){
      this.arrayLineasSelect.push({strLinea:obj.strLinea,dblCantidad:0})
    }else{
      this.arrayLineasSelect = this.arrayLineasSelect.filter(lin => lin.strLinea !== obj.strLinea);
      obj.select = false; 
    }
    this.totalImporteLineas();
   }
   totalImporteLineas(): number {
    let result = this.arrayLineasSelect.reduce((sum, item) => sum + item.dblCantidad, 0)
  
    this.formOportunidades.patchValue({ intMonto: result });
    return  result;
  }
  updateCantidad(event: Event, cet: any): void {
    let inputElement = event.target as HTMLInputElement;
    let valor = parseFloat(inputElement.value);
    if (inputElement.value === 'e'||inputElement.value === '' || isNaN(valor) || valor <= 0) {
      cet.dblCantidad = 0;
      inputElement.value = '0'; // Esto asegura que el valor mostrado en el input sea '0'
    } else {
      cet.dblCantidad = valor;
    }
    this.totalImporteLineas();
  }

  fnAdjuntarArchivosActividad(): void {
       
      if (this.fileInputActividades) {
        this.arrayAgregarAnexosActividades =[];
        this.fileInputActividades.nativeElement.value = '';
        this.fileInputActividades.nativeElement.click();
      }
    }
         onFileSelectedActividad(event: any): void {
            let files: FileList = event.target.files;
            if (files.length > 0) {
              for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let newTotalSize =  file.size;
                if (newTotalSize > 10 * 1024 * 1024) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Límite excedido',
                    text: 'El tamaño total de los archivos no puede superar los 10MB.',
                  });
                  return; 
                }
          
                let nombreOriginal = file.name.split('.').slice(0, -1).join('.'); 
                let extension = file.name.split('.').pop(); 
          
                this.arrayAgregarAnexosActividades.push({
                  nombreArchivo: nombreOriginal,
                  extencion: extension,
                  archivo: file
                });
              }
            }
          }
          fnEliminarAnexosAct(): void {
            this.fileInputActividades.nativeElement.value = '';
              this.arrayAgregarAnexosActividades =[];
          }
    
    
          fnAnexosActividad(){
         
            if (this.arrayAgregarAnexosActividades.length > 0) {
            let formData = new FormData();
            this.arrayAgregarAnexosActividades.forEach((o, index) => {
              if (o.archivo instanceof File) {
                formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
              } else {
                console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
              }
            });
              this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
                console.log(result)
                this.arrayEnviarAnexosActividades = []
                let arrayRest = result;
                arrayRest.forEach(obj => {
                  this.arrayEnviarAnexosActividades.push(
                    {
                      strTipo:obj.strTipo,
                      strRespuesta:obj.file,
                      strNombre:obj.strNombre,
                      strAgente:this.strAgente,
                      strEstatus:""
                    });
                });
                console.log(this.arrayEnviarAnexosActividades[0])
                this.fnEnviarActividad(this.arrayEnviarAnexosActividades[0]) 
              }, (error) => {
                console.log(error);
              });
            } else {
              this.fnEnviarActividad() 
            }
          }
    
                onFileInsert(event: any): void {
                  let files: FileList = event.target.files;
                  if (files.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                      const file = files[i];
                      let newTotalSize =  file.size;
                      if (newTotalSize > 10 * 1024 * 1024) {
                        Swal.fire({
                          icon: 'warning',
                          title: 'Límite de almacenamiento alcanzado',
                          text: 'No puedes agregar más archivos, el límite es de 10MB.',
                        });
                        return; 
                      }
                
                      const nombreOriginal = file.name.split('.').slice(0, -1).join('.'); 
                      const extension = file.name.split('.').pop(); 
                
                      this.arrayAnexMdlAdd.push({
                        nombreArchivo: nombreOriginal,
                        extencion: extension,
                        archivo: file
                      });
                
                    }
                  }
                }
          
                fnAgregarArchivo(){
                  let formData = new FormData();
                  this.arrayAnexMdlAdd.forEach((o, index) => {
                    if (o.archivo instanceof File) {
                      formData.append(`uploads[${index}]`, o.archivo, o.archivo.name);
                    } else {
                      console.error(`⚠️ El elemento en índice ${index} no es un archivo válido:`, o);
                    }
                  });
                   
                    this._httpService.makeFileRequestMinuta('https://www.ecodeli.com/EvidenciaAnexo_Actividades.php', [], formData).then((result) => {
                      console.log(result)
                      if(result.length>0){
                        this.arrayEnviarAnexosActividades = []
                        let arrayRest = result;
                        let enviar = {}
                        arrayRest.forEach(obj => {
                          enviar = {
                            strAccion: 'setAnexoActividad',
                            intID_Leads:this.objInfo.intID_Leads,
                            intID_Actividad:this.intIDActividad,
                            strConexion: this.strConexion,
                            strTipo_CIERRE:obj.strTipo,
                            strUrl_Logo:obj.file,
                            strResultado:obj.strNombre,
                            strAgente_Crea:this.strAgente,
                            strMotivo:""
                          }
                        });
                        console.log(enviar)
                        this.fnGuardarArchivo(enviar);
                      }else{
                        Swal.fire("Error","Error al guardar archivo.","error");
                      }
                    }, (error) => {
                      console.log(error);
                    });
                }
                fnElinimarArchivos(){
                  this.arrayAnexMdlAdd = []
                }
          
                fnGuardarArchivo(obj:any){
                  this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                      result => {
                        console.log(result)
                        if(result[0].code == 200){
                          Swal.fire("Guardado",result[0].strMensaje,"success")
                            this.arrayAnexMdlAdd = [];
                            this.fileModal.nativeElement.value= ""
                            this.arrayAnexosModal = [];
                            this.fnGetAnexosActividades()
                        }else{
                          Swal.fire("Error",result[0].strMensaje,"error")
                        }
                      }, error => {
                        var error = <any>error; console.log(error);
                       // this.spinerBool = false
                      });
                }
                fnAjuntarAnexsModal(): void {
                  if (this.fileModal) {
                    this.arrayAnexMdlAdd = [];
                    this.fileModal.nativeElement.value= ""
                    this.fileModal.nativeElement.click();
                  }
                }
  
                fnGetAnexosActividades(){
                  this.arrayAnexosModal = [];
                  let obj = {
                    strAccion: 'getActAnexos',
                    intID_Actividad:this.intIDActividad,
                    intID_Leads:this.objInfo?.intID_Leads,
                    strConexion: this.strConexion
                  }
                  this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                      result => {
                      /*   console.log(result) */
                        if(result.length>0){
                          this.arrayAnexosModal = result
                          this.arrayAnexosModal.forEach(obj => {
                            obj.urlImagen = "https://www.ecodeli.com/"+obj.strUrl_Logo
                          });
                      /*     console.log(this.arrayAnexosModal); */
                        }
                       
                      }, error => {
                        var error = <any>error; console.log(error);
                       // this.spinerBool = false
                      });
                  
                }

                
                                fnPdfArtsPolitica(){
                                  const Flujoce = this.headerArtsAsing.nativeElement;
                                  const options = {
                                    margin: [13, 8, 10, 8],
                                    filename: 'ArticulosAsinados.pdf',
                                    image: { type: 'JPEG', quality: 0.98 },
                                    html2canvas: { scale: 2, autoPaging: true, useCORS: true }, // Habilita autoPaging
                                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
                                  };
                                  const content = this.pdfArtsPolitica.nativeElement;
                                  html2pdf()
                                    .from(content)
                                    .set(options)
                                    .toPdf()
                                    .get('pdf')
                                    .then(pdf => {
                                      const totalPages = pdf.internal.getNumberOfPages();
                                      for (let i = 1; i <= totalPages; i++) {
                                        pdf.setPage(i);
                                        pdf.setFontSize(12);
                                        pdf.text(Flujoce.innerText, 100, 8); // Ajusta las coordenadas según sea necesario
                                        pdf.setPage(i);
                                        pdf.setFontSize(9);
                                        pdf.text(i.toString(), 5, 205);
                                      }
                              
                                      pdf.save("ArticulosAsinados.pdf");
                                      /*   if(this.flagProspecto){
                                          this.fncerrarmodal()
                                       } */
                                    });
}
fnSeeRow(ind: any, actividad: any) {
  console.log(ind)
  this.fngetSubtareas(actividad.intID_Actividad)
  let valAnt = this.arrayActividades[ind].seeSubAct
  this.arrayActividades.forEach(act => {
    act.seeSubAct = false
  });

  this.arrayActividades[ind].seeSubAct = valAnt == true ? false : true
}
  fngetSubtareas(intID_Actividad: any) {
    this.arraySubTareas = [];
    let obj = {
      strAccion: 'getSubActividades',
       /* strAgente: this.strAgente,
         strSucursal: this.strSucursal,
        strGerente: this.strGerente, */ 
        intID_Actividad: intID_Actividad,
        intID_Leads:this.objInfo?.intID_Leads,
        strConexion: this.strConexion
    };
    console.log(obj)
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          console.log(result)
          this.arraySubTareas = result
          /*  this.arraySubTareas.forEach(item => {
             let objAgente = this.arrayAgentesTodos.find(o => o.strAgente == item.strAgente_Crea)
             item.nombreCrea = objAgente && objAgente.strNombre ? objAgente.strNombre : "";
             item.select = false;
           }); */
        }, error => {
          //this.spinnerTab = false
          console.error('Error al consumir la API:', error);

        });
  }

  fnModalRegistrarSubtarea(tipo: string, obj: any) {

    this.objSubtarea = obj
    //this.editableDiv2.nativeElement.innerHTML ='';
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = tipo;
    this.objModal.subTitulo = obj.intID_Leads + '.-' + obj.strTitulo
    this.arrayAgentesActividades = [];
    this.strBuscarAgente = "";
    this.arrayAgregarAnexosActividades = [];
    this.fileInputActividades.nativeElement.value = '';
    this.inputComentarios.nativeElement.innerHTML = ""
  }
  fnCleanSearch(){
    this.strBuscarAct = ""
  }
}

