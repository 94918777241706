<div class="container">
    <div class="row my-3">
        <!-- parte izquierda -->
        <div class="col-12 col-lg-3 mb-3 mb-lg-0 d-flex justify-content-center">
            <div class="row d-flex w-100">
                <div class="col-12 borderbott bordertop shadow px-0">
                    <div class="col-12 bg-ligth bordertop d-flex justify-content-between py-2 px-2"> 
                        <span class="fw-bold">Información Minuta</span>
                        <i class="fa-regular fa-clipboard text-secondary fs-5"></i>
                    </div>
                    <div class="col-12 borderbott bg-white px-0">
                        <div class="col-12 d-flex justify-content-between py-2">
                            <span class="me-2 fw-bold fs13 "><i class="bi bi-clipboard ms-2 me-1"></i> <span class="text-primary">Folio:</span></span>
                            <span class="me-2 fs12">{{intID_Minuta}}</span>
                        </div>
                        <div class="col-12 d-flex justify-content-between py-2">
                            <span class="me-2 fw-bold fs13 col-4"><i class="bi bi-tag ms-2 me-1"></i> <span class="text-success">Titulo:</span></span>
                            <span class="me-2 fs12"> 
                                <input class="form-control form-control-sm" type="text" placeholder="titulo" aria-label=".form-control-sm example" [(ngModel)]="objInfo.strTitulo">
                            </span>

                          
                        </div>
                        <div class="col-12 d-flex justify-content-between py-2">
                            <span class="me-2 fw-bold fs13"><i class="bi bi-calendar-week ms-2 me-1"></i>Fecha:</span>
                            <span class="me-2 fs12">
                                <input class="form-control form-control-sm" type="date" placeholder="titulo" aria-label=".form-control-sm example" [(ngModel)]="objInfo.strFechaInicioServicio">
                           
                            </span>
                        </div>
                        <div class="col-12 d-flex justify-content-between py-2">
                            <span class="me-2 fw-bold fs13"><i class="bi bi-alarm ms-2 me-1"></i>Hora Inicio:</span>
                            <span class="me-2 fs12"> 
                                <select class="form-select w-100  form-control-sm" *ngIf="time1=='am'" aria-label="Default select example"
                                [(ngModel)]="objInfo.strHoraInicio">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>
                               
                            </span>
                        </div>
                      
                        <div class="col-12 d-flex justify-content-between py-2">
                            <span class="me-2 fw-bold fs13"><i class="bi bi-hourglass-split ms-2 me-1"></i>Creado Por:</span>
                            <span class="me-2 fs12"><b>({{objInfo.strAgente}})</b> {{objInfo.strNombreAgente}}
                                
                            </span>
                        </div>
                        <div class="col-12 d-flex justify-content-between py-2 align-items-center text-center align-middle">
                            <button type="button" class="btn btn-primary ms-2 fs13" (click)="fnUpdateMinuta()"><i class="fa-regular fa-floppy-disk"></i> Actualizar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- parte derecha -->
        <div class="col-12 col-lg-9 d-flex justify-content-center">
            <div class="row justify-content-center justify-content-lg-end w-100">
                <div class="col95 borderbott bordertop bg-white shadow px-0">
                    <ul class="nav nav-pills  py-2 px-2 bordertop bg-light" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active py-1 px-2 fs14 " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Miembros</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link py-1 px-2 fs14 mx-1 mx-lg-3" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Notas</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link py-1 px-2 fs14 " id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" (click)="fnCleanSearch()">Actividades</button>
                        </li>
                        <li class="nav-item" role="adjuntos">
                            <button class="nav-link py-1 px-2 fs14 mx-1 mx-lg-3" id="pills-adjuntos-tab" data-bs-toggle="pill" data-bs-target="#pills-adjuntos" type="button" role="tab" aria-controls="pills-adjuntos" aria-selected="false">Adjuntos</button>
                          </li>
                      </ul>
                      <div class="tab-content bg-white borderbott px-2" id="pills-tabContent">
                        <!-- inicia tab de miembros -->
                        <div class="tab-pane fade show active py-3" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div class="col-12 my-2">
                                 
                                <button type="button" class="btn btn-outline-primary clsAdd fs13"  (click)="addMiembro()" *ngIf="!bandMiembro"><i class="bi bi-person-plus-fill clsAdd"></i> Agregar Miembro</button>
                                <span class="text-warning fw-bold ms-3 pointer d-flex align-items-center" (click)="addMiembro()" *ngIf="bandMiembro"><i class="bi bi-caret-up-fill text-warning me-2"></i>Miembros</span>
                            </div>
                            <div class="col-12 d-flex justify-content-evenly bgAgregar pt-2" *ngIf="bandMiembro">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" [(ngModel)]="tipoMiembro" value="ecodeli" (click)="limpiar()">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                      Miembro Ecodeli
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" [(ngModel)]="tipoMiembro" value="externo" (click)="limpiar()">
                                    <label class="form-check-label" for="flexRadioDefault2">
                                      Miembro Externo
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-evenly align-items-center bgAgregar py-3" *ngIf="bandMiembro && tipoMiembro == 'externo'">
                                <div class="col-3 d-flex flex-column">
                                    <label for="">Nombre:</label>
                                    <input type="text" class="form-control " [(ngModel)]="strNombreAdd">
                                </div>
                                <div class="col-3 d-flex flex-column">
                                    <label for="">Correo:</label>
                                    <input type="text" class="form-control " [(ngModel)]="strCorreoAdd">
                                </div>
                                <button class="btn btn-primary" [disabled]="strNombreAdd==''|| strCorreoAdd==''" (click)="fnAgregarMiembro('externo')">Agregar</button>
                            </div>

                            <div class="col-12 d-flex justify-content-evenly align-items-center bgAgregar py-3" *ngIf="bandMiembro && tipoMiembro == 'ecodeli'">
                                <div class="col-3 d-flex flex-column">
                                    <label for="">Nombre:</label>
                                    <input type="text" class="form-control "   [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="strNombreAdd">
                                    <mat-autocomplete   #auto="matAutocomplete"   >
                                        <mat-option *ngFor="let option of filteredOptions" [value]="option.strNombre" (click)="fnSelectEmpleado(option)">
                                         <b> {{option.strAgente}}</b> {{option.strNombre}}
                                        </mat-option>
                                      </mat-autocomplete>
                                </div>
                                <button class="btn btn-primary" [disabled]="strNombreAdd=='' " (click)="fnAgregarMiembro('ecodeli')">Agregar</button>


                                 
                            </div>


                            <div class="col-12 mt-3">
                                <mat-form-field appearance="outline">
                                    <mat-label>Buscar</mat-label>
                                    <input matInput (keyup)="applyFilterMiembros($event)"
                                        placeholder="Nombre o Correo" #input>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <div class="mb-0 table-responsive shadow-sm">
                                    <div class="mat-elevation-z8 mb-0 mt-3 shadow rounded">
                                        <table mat-table [dataSource]="dataMiembros" class="table table-hover bordertop" matSort #sortMiembros="matSort">
                                            
                                            <!-- miembro Column -->
                                            <ng-container matColumnDef="strNombre">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bordertopLeft">Miembros</th>
                                                <td mat-cell *matCellDef="let row" class="ps-0  text-center align-middle">
                                                    <div class="d-flex flex-column fs14">
                                                        <span class="fw-bold fs14">
                                                            {{row.intID_Miembro}}   {{row.strContacto_Nombre}}
                                                        </span>
                                                        <span class="text-secondary fs14">
                                                            {{row.strContacto_Email}}
                                                        </span>
                                                    </div> 
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="strOrigen">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13"> Tipo </th>
                                                <td mat-cell *matCellDef="let row" class="mostDet fw-bold ps-0  text-center align-middle">
                                                    <span class="text-secondary fs14">
                                                        {{row.strTipo}}
                                                    </span>
                                                </td>
                                            </ng-container>
                                             <!-- Editar Column 
                                             <ng-container matColumnDef="edit">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13"> Editar </th>
                                                <td mat-cell *matCellDef="let row" class="mostDet fw-bold ps-0  text-center align-middle">
                                                    <i class="bi bi-pen text-warning fs-5 pointer"></i>
                                                </td>
                                            </ng-container>-->
                                             <!-- Eliminar Column -->
                                             <ng-container matColumnDef="delete">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 fs13 bordertopright"> Eliminar </th>
                                                <td mat-cell *matCellDef="let row" class="mostDet fw-bold pe-0 text-center align-middle">
                                                    
                                                    <button type="button" class="btn btn-outline-danger" title="Eliminar Miembro" (click)="fnEliminarMiembro(row)"><i class="bi bi-trash3  fs-5 pointer"></i></button>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row class="rounded" *matHeaderRowDef="columnsMiembros">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: columnsMiembros;" >
                                            </tr>
                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="6">
                                                    No se encontraron datos de:
                                                    "{{input.value}}"
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <mat-paginator class="paginator borderbott" [pageSizeOptions]="[10, 25, 50, 100]"
                                    aria-label="Select page of users" #paginatorMiembros></mat-paginator>
                            </div>
                        </div>
                        <!-- fin tab miembros -->
                         <!-- inicia tab notas -->
                        <div class="tab-pane fade py-3" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div class="form-group h-100">
                                <div *ngIf="!isSummernoteInitialized">Cargando editor...</div>
                                <div class="custom-summernote h-100 summernote" id="summernote" #summernote ></div>

                               

                            </div>
                            <div class="row my-3">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="fnGuardarNota()">
                                        Guardar
                                    </button>   
                                </div>
                            </div>
                        </div>
                        <!-- fin tab notas -->
                        <!-- inicia tab Actividades -->
                        <div class="tab-pane fade py-3" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div class="col-12 my-2">
                                <button type="button" (click)="fnAbrirModal()" class="btn btn-outline-primary clsAdd ms-3 pointer d-flex"  data-bs-toggle="modal" data-bs-target="#mdlRegistrar"><i class="bi bi-journal-plus clsAdd"></i> <span class="fs13">Agregar Tarea</span> </button>
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Buscar</mat-label><!-- (keyup)="applyFilterTareasCte($event)" -->
                                    <input matInput [(ngModel)]="strBuscarAct" 
                                        placeholder="ID,Tarea,etc..." #input>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <div class="table-responsive mt-3">
                                    <table class="table">
                                        <thead>
                                            <tr class="encabezado">
                                                <th></th>
                                                <th></th>
                                                <th>Título</th>
                                                <th>Responsable</th>
                                                <th>Origen</th>
                                                <th>Fecha</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="arrayActividades.length == 0">
                                                <tr>
                                                    <td colspan="6">
                                                        <div class="card"
                                                            style="padding: 8px;text-align: center; font-weight: 600;">
                                                            <span>SIN DATOS</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngFor="let actividad of arrayActividades| filtersearch: strBuscarAct: ['strEstatus','strTitulo','strAgente','strNombreAgente','strOrigen']; let ind = index">
                                                <!-- Fila principal -->
                                                <tr>
                                                    <td>
                                                        <button class="btn btn-secondary py-0 px-2 ms-auto"
                                                            (click)="fnSeeRow(ind,actividad)">
                                                            <i class="bi bi-caret-down-fill fs13"
                                                                *ngIf="!actividad.seeSubAct"></i>
                                                            <i class="bi bi-caret-up-fill fs13"
                                                                *ngIf="actividad.seeSubAct"></i>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex justify-content-center">
                                                            <div class=" "
                                                                *ngIf="actividad.strEstatus != 'POR AUTORIZAR' && actividad.strEstatus != 'PROCESO'">
                                                                <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                                    disabled [checked]="actividad.strEstatus == 'CONCLUIDO'"
                                                                    type="checkbox" id="customRadioCheckbox">
                                                            </div>
                                                            <div class=" text-center" *ngIf="actividad.strEstatus == 'POR AUTORIZAR'">
                                                                <button type="button"
                                                                    class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                        class="fa-solid fa-bell"></i></button>
                                                            </div>
                                                            <div class=" text-center" *ngIf="actividad.strEstatus == 'PROCESO'">
                                                                <button type="button"
                                                                    class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                                    title="En Proceso"><i
                                                                        class="fa-solid fa-circle-half-stroke"></i></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                        (click)="fnmDLConAct(actividad)">
                                                        <span class="mx-auto flex-grow-1 text-center fw-bold">{{ actividad.strTitulo
                                                            }}</span>
                                                    </td>
                                                    <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                        (click)="fnmDLConAct(actividad)">
                                                        <span class="rounded-circle p-2 fs11 fw-bold text-center"
                                                            [ngClass]="{'res1':ind % 2 ==0,'res2':ind % 2 !=0}">
                                                            {{actividad.strAgente}}
    
                                                        </span>
                                                        <span class="ps-1 fs11 fw-bold">
                                                            {{actividad.strNombreAgente}}
                                                        </span>
                                                    </td>
                                                    <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                        (click)="fnmDLConAct(actividad)" class="fs12 fw-bold">{{ actividad.strOrigen }}</td>
                                                    <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                        (click)="fnmDLConAct(actividad)">
                                                        <span class="fw-bold px-2 d-flex fw-bold"
                                                            matTooltip="{{actividad.strEstatus}}"
                                                            [ngClass]="{'badFecha':actividad.diasDiferencia>-1 || (actividad.diasDiferencia<0 && actividad.strEstatus != 'PENDIENTE'),'badFechamenor':actividad.diasDiferencia<0 && actividad.strEstatus == 'PENDIENTE'}">
                                                            <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                            {{actividad.strFechaRequerida|date:'dd/MM/yyyy' }}
                                                        </span>
                                                    </td>
                                                    <td>
    
                                                        <button class="btn  my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                                            <i class="bi bi-three-dots-vertical"></i>
                                                          </button>
                                                          <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                                                            <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                                                            <button mat-menu-item data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                                            (click)="fnModalRegistrarSubtarea('Subtarea', actividad);">
                                                              <mat-icon class="text-success">note_add</mat-icon>
                                                              <span class="text-success">SubTarea </span>
                                                            </button>
                                                            <button mat-menu-item  data-bs-toggle="modal" data-bs-target="#mdlEditar"
                                                            (click)="fnEditar_Actividad(actividad)">
                                                              <mat-icon class="txtInfo">edit</mat-icon>
                                                              <span class="txtInfo">Editar</span>
                                                            </button>
                                                            <button mat-menu-item *ngIf="actividad.strEstatus == 'PENDIENTE'"
                                                            (click)="fnDelete_Tarea(actividad)">
                                                        
                                                                <mat-icon color="warn">delete</mat-icon>
                                                                <span class="text-danger">Eliminar</span>
                                                              </button>
                                                          </mat-menu>
                                                    </td>
                                                </tr>
    
                                                <!-- Tabla secundaria cuando seeSubAct es true -->
                                                <tr *ngIf="actividad.seeSubAct">
                                                    <td colspan="7" class="p-0">
                                                        <div class="container-fluid bg-light">
                                                            <div class="row justify-content-end">
                                                                <div class="col-11 py-2 ">
                                                                    <div class="table-responsive mb-0">
                                                                        <table class="table text-center table-striped">
                                                                            <thead>
                                                                                <tr class="subActivid">
                                                                               
                                                                                    <th></th>
                                                                                    <th>Título</th>
                                                                                    <th>Responsable</th>
                                                                                    <th>Origen</th>
                                                                                    <th>Fecha</th>
                                                                                    <th>Acciones</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <ng-container *ngFor="let subActividad of arraySubTareas; let inds= index">
                                                                                    <tr>
                                                                                      <!--   <td data-bs-toggle="modal"
                                                                                            data-bs-target="#MConcluirActividades"
                                                                                            (click)="fnmDLConAct(subActividad)">
                                                                                            <button
                                                                                                class="btn btn-warning text-white py-0 px-2">
                                                                                                <i class="bi bi-dash"></i>
                                                                                            </button>
                                                                                        </td> -->
                    
                                                                                        <td>
                                                                                            <div class="d-flex justify-content-center">
                                                                                                <div class=" "
                                                                                                    *ngIf="subActividad.strEstatus != 'POR AUTORIZAR' && subActividad.strEstatus != 'PROCESO'">
                                                                                                    <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                                                                        disabled [checked]="subActividad.strEstatus == 'CONCLUIDO'"
                                                                                                        type="checkbox" id="customRadioCheckbox">
                                                                                                </div>
                                                                                                <div class=" text-center" *ngIf="subActividad.strEstatus == 'POR AUTORIZAR'">
                                                                                                    <button type="button"
                                                                                                        class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                                                            class="fa-solid fa-bell"></i></button>
                                                                                                </div>
                                                                                                <div class=" text-center" *ngIf="subActividad.strEstatus == 'PROCESO'">
                                                                                                    <button type="button"
                                                                                                        class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                                                                        title="En Proceso"><i
                                                                                                            class="fa-solid fa-circle-half-stroke"></i></button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                    
                                                                                        
                                                                                        <td data-bs-toggle="modal"
                                                                                            data-bs-target="#MConcluirActividades"
                                                                                            (click)="fnmDLConAct(subActividad)">{{
                                                                                            subActividad.strTitulo }}</td>
                                                                                        <td data-bs-toggle="modal"
                                                                                            data-bs-target="#MConcluirActividades"
                                                                                            (click)="fnmDLConAct(subActividad)">
                                                                                            <span
                                                                                                class="rounded-circle p-2 fs11 fw-bold text-center"
                                                                                                [ngClass]="{'res1':inds % 2 ==0,'res2':inds % 2 !=0}">
                                                                                                {{subActividad.strAgente}}
                                                                                            </span>
                                                                                            <span class="ps-1 fs11">
                                                                                                {{subActividad.strNombreAgente}}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades" class="fs11" (click)="fnmDLConAct(subActividad)">{{subActividad.strOrigen }}</td>
                                                                                        <td data-bs-toggle="modal"
                                                                                            data-bs-target="#MConcluirActividades"
                                                                                            (click)="fnmDLConAct(subActividad)">
                                                                                            <span class="fw-bold px-2 d-flex fs11"
                                                                                                matTooltip="{{subActividad.strEstatus}}"
                                                                                                [ngClass]="{'badFecha':subActividad.diasDiferencia>-1 || (subActividad.diasDiferencia<0 && subActividad.strEstatus != 'PENDIENTE'),'badFechamenor':subActividad.diasDiferencia<0 && subActividad.strEstatus == 'PENDIENTE'}">
                                                                                                <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                                                                {{subActividad.strFechaRequerida|date:'dd/MM/yyyy'}}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <button class="btn my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                                                                                <i class="bi bi-three-dots-vertical"></i>
                                                                                              </button>
                                                                                              <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                                                                                                <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                                                                                                <button mat-menu-item  data-bs-toggle="modal"
                                                                                                data-bs-target="#mdlEditar"
                                                                                                (click)="fnEditar_Actividad(subActividad)">
                                                                                                  <mat-icon class="txtInfo">edit</mat-icon>
                                                                                                  <span class="txtInfo">Editar</span>
                                                                                                </button>
                                                                                                <button mat-menu-item *ngIf="subActividad.strEstatus == 'PENDIENTE'"
                                                                                                (click)="fnDelete_Tarea(subActividad)">
                                                                                            
                                                                                                    <mat-icon color="warn">delete</mat-icon>
                                                                                                    <span class="text-danger">Eliminar</span>
                                                                                                  </button>
                                                                                              </mat-menu>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-container>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
    
    
                                                        
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                               <!--  <div class="mb-0 table-responsive">
                                    <div class="mat-elevation-z8 mb-0 mt-3 shadow rounded">
                                        <table mat-table [dataSource]="datosTareasCte" class="table table-hover"
                                            matSort #sortTareasCte="matSort">
                                       
                                            <ng-container matColumnDef="btnRadio">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="ps-0 fs13"> </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="px-2 fs11 text-center align-middle">
                                                    <div class="d-flex justify-content-center">
                                                        <div class=" "
                                                            *ngIf="row.strEstatus != 'POR AUTORIZAR' && row.strEstatus != 'PROCESO'">
                                                            <input
                                                                class="form-check-input custom-checkbox fs-6 mx-2"
                                                                disabled [checked]="row.strEstatus == 'CONCLUIDO'"
                                                                type="checkbox" id="customRadioCheckbox">
                                                        </div>
                                                        <div class=" text-center"
                                                            *ngIf="row.strEstatus == 'POR AUTORIZAR'">
                                                            <button type="button"
                                                                class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                    class="fa-solid fa-bell"></i></button>
                                                        </div>
                                                        <div class=" text-center"
                                                            *ngIf="row.strEstatus == 'PROCESO'">
                                                            <button type="button"
                                                                class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                                title="En Proceso"><i
                                                                    class="fa-solid fa-circle-half-stroke"></i></button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                          
                                            <ng-container matColumnDef="titulo">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="ps-0 fs13"> Titulo </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="mostDet fw-bold ps-0 fs13 text-start align-middle">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <a data-bs-toggle="modal" data-bs-target="#MConcluirActividades" (click)="fnmDLConAct(row)">{{row.strTitulo|uppercase}}</a>
                                                        <div class="mostDet1 me-2">
                                                            <span class="d-flex align-items-center">
                                                                <mat-icon class="text-secondary">more_vert</mat-icon>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                          
                                            <ng-container matColumnDef="origen">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13">
                                                    Acciones </th>
                                                    <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle"> 
                                                        <button type="button" class="btn btn-outline-primary me-1"  data-bs-toggle="modal" data-bs-target="#mdlEditar" (click)="fnEditar_Actividad(row)"><i class="fa-solid fa-pen-to-square"></i></button>
                                                        <button  *ngIf="row.strEstatus =='PENDIENTE'" type="button" class="btn btn-outline-danger me-1" (click)="fnDelete_Tarea(row)"><i class="fa-solid fa-trash-can"></i></button>
                                                
                                                    </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="responsable">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13">
                                                    Responsable </th>
                                                <td mat-cell *matCellDef="let row;let ind=index"
                                                    class="fs12 text-start align-middle">
                                                    <div class="d-flex align-items-center">
                                                        <span class="rounded-circle p-2 fs11 fw-bold text-center"
                                                            [ngClass]="{'res1':ind % 2 ==0,'res2':ind % 2 !=0}">
                                                            {{row.strAgente}}
                                                          
                                                        </span>
                                                        <span class="ps-1 fs11">
                                                            {{row.strNombreAgente}}
                                                        </span>
                                                    </div>
                                                </td>
                                            </ng-container>
                                           
                                            <ng-container matColumnDef="fechaInicio">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="fs13 pe-0"> Fecha </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="fs12 text-center align-middle pe-0">
                                                    <div
                                                        class="col-12 d-flex align-items-center justify-content-center">
                                                        <span class="fw-bold px-2 d-flex"
                                                            matTooltip="{{row.strEstatus}}"
                                                            [ngClass]="{'badFecha':row.diasDiferencia>-1 || (row.diasDiferencia<0 && row.strEstatus != 'PENDIENTE'),'badFechamenor':row.diasDiferencia<0 && row.strEstatus == 'PENDIENTE'}">
                                                            <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                            {{row.strFechaRequerida|date:'dd/MM/yyyy' }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </ng-container>

                                     
                                            <tr mat-header-row class="rounded" *matHeaderRowDef="columnsTareasCte">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: columnsTareasCte;" >
                                            </tr>
                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="6">
                                                    No se encontraron datos de:
                                                    "{{input.value}}"
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <mat-paginator class="paginator" [pageSizeOptions]="[10, 25, 50, 100]"
                                    aria-label="Select page of users" #paginadorTareasCte></mat-paginator> -->
                            </div>
                        </div>
                        <!-- fin tab Actividades -->
                        <!-- inicia tab adjuntos -->
                        <div class="tab-pane fade py-3" id="pills-adjuntos" role="tabpanel" aria-labelledby="pills-adjuntos-tab">
                            <div class="container-fluid">
                                <div class="row justify-content-between">
                                    <div class="col-12 my-3 my-lg-0 col-lg-5 border shadow-sm rounded">
                                        <div class="col-12 position-relative my-2">
                                            <button class="ms-3 btn btnAdd position-absolute top-50 start-0 translate-middle py-0 px-2 shadow-sm" [disabled]="arrayAgregarAnexos.length>0" (click)="fnAdjuntarArchivos()">
                                                <i class="bi bi-file-earmark-plus"></i>
                                            </button>
                                            <h3 class="mb-0 text-center fw-bold">
                                                Agregar
                                            </h3>
                                        </div>
                                        <input #fileInput type="file" hidden  (change)="onFileSelected($event)">
                                        <div class="col-12 my-3" *ngIf="arrayAgregarAnexos.length == 0">
                                            <div class="alert alert-secondary text-center" role="alert" >
                                                ¡Adjunte Archivos!
                                            </div>
                                        </div>
                                        <ng-container *ngIf="arrayAgregarAnexos.length > 0">
                                            <div class="col-12 my-4 d-flex align-items-center" *ngFor="let anex of arrayAgregarAnexos;let i = index" >
                                                <div class="col">
                                                    <span>
                                                        <i *ngIf="anex.extencion === 'jpg' || anex.extencion === 'png'|| anex.extencion === 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                        <i *ngIf="anex.extencion === 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                        <i *ngIf="anex.extencion === 'xls' || anex.extencion === 'xlsx'|| anex.extencion === 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                        <i *ngIf="anex.extencion === 'zip' || anex.extencion === 'rar'" class="bi bi-file-zip fs-4"></i>
                                                    </span>
                                                </div>
                                                <div class="col-10 d-flex align-items-center">
                                                    <input type="text"  [(ngModel)]="anex.nombreArchivo" class="form-control fs12">
                                                    <span>.{{anex.extencion}}</span>
                                                    <button class="btn btn-danger ms-2 py-0 px-1 d-flex align-items-center shadow-sm" (click)="eliminarArchivo(i)">
                                                        <i class="bi bi-x-circle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex py-3 justify-content-between border-top">
                                                <button matTooltip="Eliminar Archivos" class="btn btn-danger ms-2 py-0 px-1 fs12 d-flex align-items-center shadow-sm" (click)="fnEliminarArchivos()">
                                                   Eliminar Archivos <i class="bi bi-trash fs-6"></i>
                                                </button>
                                                <button class="btn btn-primary ms-2 py-0 px-1 d-flex align-items-center shadow-sm fs12" (click)="SubirAnexos()">
                                                    Guardar <i class="ms-2 bi bi-cloud-upload fs-6"></i>
                                                </button>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                    <div class="col-12 my-3 my-lg-0 col-lg-6  border shadow-sm rounded d-flex flex-column pb-3">
                                        <div class="col-12 my-2">
                                            <h3 class="mb-0 text-center fw-bold">
                                                Guardados
                                            </h3>
                                        </div>
                                        <div class="col-12 my-3" *ngIf="arrayAnexosExistentes.length == 0">
                                            <div class="alert alert-secondary text-center" role="alert">
                                               No cuenta con anexos guardados.
                                              </div>
                                        </div>
                                        <div class="col-11 mx-auto" *ngIf="arrayAnexosExistentes.length > 0">
                                            <div class="row">
                                                <div class="col-3 d-flex" *ngFor="let anex of arrayAnexosExistentes; let i = index">
                                                        <div class="col-12 px-0 shadow-sm border text-center" [ngClass]="{'mt-2':i>3}">
                                                            <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}" target="_blank">
                                                            <i *ngIf="anex.strTipo == 'jpg' || anex.strTipo == 'png'|| anex.strTipo == 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                            <i *ngIf="anex.strTipo == 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                            <i *ngIf="anex.strTipo == 'xls' || anex.strTipo == 'xlsx'|| anex.strTipo == 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                            <i *ngIf="anex.strTipo == 'zip' || anex.strTipo == 'rar'" class="bi bi-file-zip fs-4"></i>
                                                            <span class="fs10 text-ellipsi">
                                                                {{anex.strNombreCorto}}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- fin tab adjuntos -->
                      </div>

  
                </div>
 
            </div>
        </div>
    </div>
</div>



<!-- Modal RegistrarActividad -->
<div class="modal fade" id="mdlRegistrar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"   data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Registrar {{objModal && objModal.titulo?objModal.titulo:""}}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row pb-2">
                    <div class="col-12 col-md-3">
                        <div class="col-12 mb-3">
                            <div class="col-12">
                                <h3 class="mb-1 fw-bold text-center">
                                    RESPONSABLES
                                </h3>
                            </div>
                            <mat-form-field class="example-full-width w-100">
                                <span matPrefix> </span>
                                <input type="text"  class="px-3" matInput placeholder="Nombre agente"  autocomplete="off" (keyup)="fnBusquedaCte(strBuscarAgente);" name="search" [(ngModel)]="strBuscarAgente">
                                <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-form-field>
                            <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                                <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let agt of arrayAgente_Filter"
                                    (click)="fnSeleccionaAgente(agt)">
                                    {{agt.strNombre}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-12" *ngFor="let agt of arrayAgentesActividades">
                            <button  class="btn btn-secondary position-relative fs12 shadow-sm col-12 mb-2">
                                ({{agt.strAgente}}) - {{agt.strNombre}}
                                <i (click)="fnEliminarAgenteAct(agt)" class="fa-solid fa-x position-absolute top-0 end-0 mt-1 me-1 fs11"></i> 
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col-5">
                                <div class="col-12">
                                    Asunto
                                </div>
                                <div class="col-12">
                                    <input type="text" placeholder="Agregue asunto" class="form-control"
                                        [(ngModel)]="mdlNombre">
                                </div>
                            </div>
                            <div class="col-2">
                            </div>
                            <div class="col-5">
                                <div class="col-12">
                                    Fecha Compromiso
                                </div>
                                <div class="col-12">
                                    <input type="date" class="form-control" [(ngModel)]="strfecha">
                                </div>
                            </div>
                        </div>
                
                        <div class="row my-3">
                            <div class="col-12 col-md-5" *ngIf="objModal.titulo != 'Tarea'">
                                <!--    <div class="col-12 mb-3">
                                    <mat-form-field class="example-full-width w-180">
                                        <span matPrefix> </span>
                                        <input type="text" class="px-3" matInput placeholder="Responsable"   (keyup)="fnBusquedaCte(strBuscarAgente);" name="search" [(ngModel)]="strBuscarAgente">
                                        <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy listaAgente" >
                                        <li class="list-group-item cursorPoint hver py-1 fs10 " *ngFor="let agt of arrayAgente_Filter"
                                            (click)="fnSeleccionaAgente(agt)">
                                            {{agt.strNombre}}
                                            </li>
                                    </ul>
                                </div>
                            <div class="col-12 mt-3 mb-2 d-block">
                                <span>Agente</span> <br>
                                <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                                [(ngModel)]="strAgente">
                                <option value="undefined">--Selecciona un Agente--</option>
                                <option *ngFor="let item of arrayAgentes_tareas; let i = index" value="{{item.strAgente}}">
                                    {{item.strNombre}}  <b *ngIf="item.strTipo !='Vendedor'">{{item.strTipo}}</b>
                                </option>
                            </select>
                        </div>-->
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-12">
                                Comentario
                            </div>
                            <div  #editableDiv2 contenteditable="true" (input)="onInput($event)"   class="editable-area"  style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;" >
                            </div>
                        </div> 
                    </div>
                    <div class="col-12 col-md-3 d-flex mt-3 my-0">
                        <div class="col-12 d-flex">
                            <div class="col-12 border">
                                <div class="col-12 px-0 position-relative">
                                    <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                       <span class="ms-2 fs13">ADJUNTO</span>
                                    </h3>
                                    <button class="me-2 btn btnAdd position-absolute top-50 end-0 translate-middle-y py-0 px-2 shadow-sm" (click)="fnAdjuntarArchivosActividad()">
                                        <i class="bi bi-file-earmark-plus"></i>
                                    </button>
                                </div>
                                <div class="col-12 px-0">
                                    <input #fileInputActividades type="file" hidden  (change)="onFileSelectedActividad($event)">
                                </div>
                                <div class="col-11 mx-auto" *ngIf="arrayAgregarAnexosActividades.length > 0">
                                    <div class="row">
                                        <div class="col-6 d-flex" *ngFor="let anex of arrayAgregarAnexosActividades">
                                                <div class="col-12 px-0 shadow-sm border text-center">
                                                    <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}" target="_blank">
                                                    <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.extencion == 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'" class="bi bi-file-zip fs-4"></i>
                                                    <span class="fs10 text-ellipsi">
                                                        {{anex.nombreArchivo}}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <button class="btn btn-danger fs12 p-1" (click)="fnEliminarAnexosAct()">
                                                <i class="bi bi-trash3 fs14"></i> Limpiar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrar>Cancelar</button>
                    <button type="button" class="btn btn-primary" (click)="fnAnexosActividad()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>



<!--modal concluir actividades-->
<div class="modal fade" id="MConcluirActividades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <span class="text-white shadow-sm borderbott bordertop px-2 py-1 d-flex flex-column  " [ngClass]="{'bg-secondary':objAct?.strEstatus!= 'CONCLUIDO' && objAct?.strEstatus!= 'PROCESO' && objAct?.strEstatus!= 'PENDIENTE','bg-success':objAct?.strEstatus== 'CONCLUIDO','bgInfo':objAct?.strEstatus== 'PROCESO','bg-warning':objAct?.strEstatus== 'PENDIENTE'}">
                    <!-- <span class="mx-auto fs12">Estatus</span>-->
                    <span class="fw-bold mx-auto fs12">{{ objAct && objAct.strEstatus ?objAct.strEstatus:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                </span>

                <h1 class="modal-title fs-5 m-auto" id="exampleModalLabel">{{objAct?.strEstatus=='CONCLUIDO'?'Actividad Concluida':objAct?.strEstatus == 'POR AUTORIZAR'?'AUTORIZAR ACTIVIDAD':''}} 
                    <span class="fw-bold">{{objAct?.strTitulo ? objAct?.strTitulo:objAct?.strOrigen}}</span>
                    <!-- <span *ngIf="objAct?.strEstatus=='PENDIENTE'"> para poder avanzar</span> -->
                </h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-11"> <!-- *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' " -->
                    
                        <div class="row">
                            <div class="col-12"> 
                                <div class="col-12 d-flex flex-column" >
                                    <span class="titIcon"><i class="bi bi-person-fill-gear clsIconModal "></i>Responsable:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strNombreAgente?objAct.strNombreAgente:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3" >
                                    <span class="titIcon"><i class="bi bi-person-circle clsIconModal"></i> Asignado por: </span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.nombreCrea?objAct.nombreCrea:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                            </div>
                           <!-- <div class="col-6">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora Inicio:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraInicio?objAct.strHoraInicio:'00'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora Fin:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraFin?objAct.strHoraFin:'00'}}</span>
                                </div>
                            </div>-->
                           
                            <div class="col-12 mt-3">
                                <div class="col-12 d-flex flex-column" >
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i> ASUNTO</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm" [innerHTML]="objAct&&objAct.strAsunto?objAct.strAsunto:'--Seleccione '+objAct?.strTipo+'--'"></span>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3" *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="titIcon"><i class="bi bi-chat-dots-fill clsIconModal"></i>Observaciones</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm"
                                    [innerHTML]="objAct?.strComentario"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-2" *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="float-end titIcon"><i class="clsIconModal bi " [ngClass]="{'bi-hand-thumbs-up-fill':objAct?.strResultado == '1','bi-hand-thumbs-down-fill':objAct?.strResultado == '2','bi-info-circle-fill':objAct?.strResultado == '0'}"></i> Resultado</span>
                               {{objAct?.strResultado}}
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-12 h-100 mb-2 mt-3"
                        [ngClass]="{'visually-hidden':objAct?.strEstatus == 'CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR'}">
                        <!-- ||objAct.strTipo=='Update_Lead_Tarea' -->
                        <div class="form-group h-100">
                            <textarea [(ngModel)]="contenido" class="custom-summernote h-100 summernote" id="summernote_tarea"
                                #summernote_tarea></textarea>
                        </div>
                    </div>
                    <div class="col-12 d-flex mt-2"
                        *ngIf="objAct?.strEstatus=='PENDIENTE' || objAct?.length == 0 || objAct?.strEstatus=='PROCESO'">
                        <select name="accioness" id="acciones" class="form-select m-auto" [(ngModel)]="strResultado"
                            [disabled]="objAct?.strEstatus=='CONCLUIDO'">
                            <option value="">--Selecciona una opción--</option>
                            <option value="1">EXITOSO</option>
                            <option value="2">SIN EXITO</option>
                        </select>
                    </div>
                    <div class="row d-flex mt-2" style="border-top: 2px dashed #b3abab;">
                        <div class="col-12 mt-3 colorComentarios d-flex justify-content-center align-items-center      ">
                            <h2 class="m-auto">Comentarios</h2>
                        </div>
                        <div class="col-10 m-auto d-flex flex-column">
                            <textarea [(ngModel)]="strComentarioD" class="form-control mt-3" name="nombre" rows="3" cols="5" placeholder="Escribe un comentario"></textarea>
                            <button class="btn btn-primary w-25 mt-2 ms-auto" (click)="fnInsertComentario()">
                                <i class="fas fa-plus"></i> Agregar </button>
                        </div>
                        <div class="col-12">
                            <div class="notification-container">
                                <div class="notification" *ngFor="let item of arrayComentarios; let i = index">
                                  <div class="circle-icon">{{item.strAgente}}</div>
                                  <div class="notification-content">
                                    <div class="notification-header">{{item.strNombreAgente}}</div>
                                    <div class="notification-text">{{item.strComentario}}</div>
                                  </div>
                                  <div class="notification-time">{{item.strFechaRequerida}}</div>
                                </div>
                              </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-12 py-3">
                        <div class="col-12 border">
                            <div class="col-12 px-0 ">
                                <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                   <span class=" fs13 mx-auto">ADJUNTOS</span>
                                </h3>
                                
                            </div>
                            <div hidden class="col-12 px-0">
                                <input #fileModal type="file"   (change)="onFileInsert($event)">
                            </div>
                           
                            <div class="col-11 mx-auto" >
                                <div class="row ">
                                    <div class="col-6 bg-light pb-2">
                                        <div class="col-12 d-flex py-2 justify-content-between">
                                            <span>Agregar</span> 
                                            <button class="me-2 btn btnAdd py-0 px-2 shadow-sm"  (click)="fnAjuntarAnexsModal()">
                                                <i class="bi bi-file-earmark-plus"></i>
                                            </button>
                                        </div>
                                        <div class="row" *ngIf="arrayAnexMdlAdd.length > 0">
                                            <div class="col-12 d-flex my-2" *ngFor="let anex of arrayAnexMdlAdd">
                                                <div class="col-6 px-0 shadow-sm border text-center">
                                                    <a class="d-flex flex-column p-2" target="_blank">
                                                    <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.extencion == 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'" class="bi bi-file-zip fs-4"></i>
                                                        <span class="fs10 text-ellipsi">
                                                            {{anex.nombreArchivo}}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div class="col-6 d-flex justify-content-center align-items-center px-2">
                                                    <button class="btn btn-primary fs12 p-1 me-3" (click)="fnAgregarArchivo()"><i class="bi bi-floppy"></i> <span class="fs11">Guardar</span></button>
                                                    <button class="btn btn-danger p-1 fs12" (click)="fnElinimarArchivos()" ><i class="bi bi-trash3"></i> <span class="fs11">Eliminar</span></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center" *ngIf="arrayAnexMdlAdd.length == 0">
                                            <div class="col-11 bg-secondary text-center rounded text-white">
                                                Seleccione archivos para agregar.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6" >
                                        <div class="col-12 py-2">
                                            <span class="text-center mb-0">Existentes</span>
                                        </div>
                                        <div class="row" *ngIf="arrayAnexosModal.length > 0">
                                            <div class="col-4 d-flex" *ngFor="let anex of arrayAnexosModal">
                                                <div class="col-12 px-0 shadow-sm border text-center">
                                                    <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}" target="_blank">
                                                    <i *ngIf="anex.strTipo == 'jpg' || anex.strTipo == 'png'|| anex.strTipo == 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.strTipo == 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.strTipo == 'xls' || anex.strTipo == 'xlsx'|| anex.strTipo == 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.strTipo == 'zip' || anex.strTipo == 'rar'" class="bi bi-file-zip fs-4"></i>
                                                        <span class="fs10 text-ellipsi">
                                                            {{anex.strNombre}}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center" *ngIf="arrayAnexosModal.length == 0">
                                            <div class="col-11 bg-secondary text-center rounded text-white">
                                                Sin archivos agregados.
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col-12 d-flex mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"   #btnCerrarAct>{{objAct?.strEstatus=='PENDIENTE'?'Cancelar':'Cerrar'}} </button>
                        <button [disabled]="strResultado == '' || contenido == ''"
                            class="btn btn-success text-white ms-auto mt-auto" (click)="fnConcluirActividad(objAct)"
                            *ngIf="objAct?.strEstatus=='PENDIENTE'   || objAct?.strEstatus=='PROCESO'">Concluir Actividad</button>


                        <button type="button" class="btn btn-primary  ms-auto" *ngIf="objAct?.strEstatus=='PENDIENTE' "
                            (click)="fnEnProceso(objAct)">En Proceso</button>
                        <button type="button" class="btn btn-success  ms-auto"
                            *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea "
                            (click)="fnAutorizar(objAct)">Autorizar</button>

                        <button type="button" class="btn btn-danger  ms-auto"
                            *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea "
                            (click)="fnRechazar(objAct)">Rechazar</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
  <!-- Modal Editar Actividad -->
<div class="modal fade" id="mdlEditar" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel2 ">Editar {{ objEditarActividad.strTipo  }}  id: {{ objEditarActividad.intID_Actividad  }}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-5">
                        <div class="col-12">
                            Asunto
                        </div>
                        <div class="col-12">
                            <input type="text" placeholder="Agregue asunto" class="form-control"
                                [(ngModel)]="mdlNombre">
                        </div>
                    </div>
                    <div class="col-2">

                    </div>
                    <div class="col-5">
                        <div class="col-12">
                            Fecha 
                        </div>
                        <div class="col-12">
                            <input type="date" class="form-control" [(ngModel)]="strfecha_editar">
                        </div>
                    </div>

                </div>
                <div class="row my-3">
                    <!--<div class="col-12 col-md-5" *ngIf="objEditarActividad.strTipo  != 'Tarea'">
                        <div class="col-12">
                            Hora Inicio 
                        </div>
                        <div class="col-12 d-flex">
                            <select class="form-select w-100" *ngIf="time1=='am'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraInicio">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>
                            
                        </div>
                    </div>
                 
                    <div class="col-12 col-md-5 mt-3 mt-md-0" *ngIf="objEditarActividad.strTipo != 'Tarea'">
                        <div class="col-12">
                            Hora Fin
                        </div>
                        <div class="col-12 d-flex">
                            <select class="form-select w-100" *ngIf="time2=='am'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraFin">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>
                      
                        </div>
                    </div> -->
                    <!--<div class="col-12 mt-3 mb-2 d-block">
                        <span>Agente</span> <br>
                        <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                            [(ngModel)]="strAgente">
                            <option value="undefined">-Selecciona un Agente- </option>
                            <option *ngFor="let item of  arrayAgentes_tareas; let i = index" value="{{item.strAgente}}">
                                {{item.strNombre}} <b *ngIf="item.strTipo !='Vendedor'">{{item.strTipo}}</b></option>
                        </select>
                    </div>-->
                    <div class="col-12 mb-3">
                        <mat-form-field class="example-full-width w-180">
                            <span matPrefix> </span>
                            <input type="text" class="px-3" matInput placeholder="Responsable"   (keyup)="fnBusquedaCte(strBuscarAgente);" name="search" [(ngModel)]="strBuscarAgente">
                            <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                        <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let agt of arrayAgente_Filter"
                                (click)="fnSeleccionaAgente(agt)">
                                {{agt.strNombre}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Comentario
                    </div> 
                    <div #editableDiv contenteditable="true" (input)="onInput($event)"   class="editable-area"  style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;" >
                    </div>

                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarEdit>Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="fnEditarActividad( objEditarActividad.intID_Actividad )">Editar</button>
            </div>
        </div>
    </div>
</div>

 