<div class="container-fluid my-3">
    <button class="d-none" id="botonMinuta" data-bs-toggle="modal" data-bs-target="#minutaCambioFase"></button>
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="px-0 ">
                <ol class="breadcrumb d-flex align-items-center mb-0">
                    <li class="breadcrumb-item"><a  (click)="fnRedirige()"   >{{strNombre_ruta}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Detalle Sueño</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row justify-content-center mb-3">
        <div class="col-11 rounded bg-white shadow-sm">
            <div class="row mt-2">
                <div class="col-12">
                    <h2 class="mb-0 d-flex">
                        <span class="nunito me-auto fw-bold clstitulo fs-3">{{objLeadInfo?.strTitulo}}</span>
                        <span class="nunito fw-bold clstitulo">{{objLeadInfo?.dblImporte|currency}}</span>
                    </h2>
                </div>
            </div>
            <div class="row my-4 fs10 justify-content-center">
                <div class="col-11">
                    <div class="row ">
                        <div class="col-2 borderleft  py-2  border-end-0" [ngClass]="{'clsActive cardisActive': strEstatus=='POR CONTACTAR', 'clsTerminado cardis':  strEstatus != 'POR CONTACTAR'}">
                            <!-- fnCambiarEstatus('POR CONTACTAR') -->
                            <div class="col-12 text-center ">Por Contactar</div>
                            <!-- <div class="col- text-center">0 Días</div> -->
                        </div>
                        <div class="col-2  py-2 border-start-0 border-end-0 cursorPointer"
                            [ngClass]="{'clsActive cardisActive': strEstatus=='PRESENTACION', 'clsTerminado2 cardisTerminado2': (strEstatus== 'RECORRIDO'||strEstatus== 'PROPUESTA ECONOMICA'||strEstatus== 'NEGOCIACION'||strEstatus== 'GANADO' || strEstatus== 'PERDIDO'),'clsDesactive2 carddeactive2': strEstatus== 'POR CONTACTAR'}"
                            (click)="fnCheckValidaciones('PRESENTACION')"> <!-- (click)="fnInitSummerNote()" -->
                            <!--(click)="fnCambiarEstatus('PRESENTACION')"-->
                            <div class="col-12 text-center ">Presentación</div>
                            <!--   <div class="col- text-center">1 Días</div> -->
                        </div>
                        <div class="col-2  py-2 border-start-0 border-end-0 cursorPointer"
                            [ngClass]="{'clsActive car3Active': strEstatus=='RECORRIDO','clsTerminado cardis': (strEstatus== 'PROPUESTA ECONOMICA'||strEstatus== 'NEGOCIACION'||strEstatus== 'GANADO' || strEstatus== 'PERDIDO'), 'clsDesactive car3': (strEstatus== 'POR CONTACTAR'||strEstatus== 'PRESENTACION')}"
                            (click)="fnCheckValidaciones('RECORRIDO')">
                            <div class="col-12 text-center ">Cotización/Recorrido</div>
                            <!--(click)="fnCambiarEstatus('RECORRIDO')"-->
                            <!--<div class="col- text-center">1 Días</div>-->
                        </div>
                        <div class="col-2  py-2  border-start-0 border-end-0 cursorPointer"
                            [ngClass]="{'clsActive cardisActive': strEstatus=='PROPUESTA ECONOMICA','clsTerminado2 cardisTerminado2':(strEstatus== 'NEGOCIACION'||strEstatus== 'GANADO' || strEstatus== 'PERDIDO'),'carddeactive2 clsDesactive2':  (strEstatus =='PRESENTACION'||strEstatus =='RECORRIDO'||strEstatus =='POR CONTACTAR')}"
                            (click)="fnCheckValidaciones('PROPUESTA ECONOMICA')">
                            <div class="col-12 text-center ">Propuesta Económica</div>
                            <!-- <div class="col- text-center">1 Días</div> -->
                        </div>
                        <div class="col-2 py-2 border-start-0 border-end-0 cursorPointer"
                            [ngClass]="{'clsActive cardisActive': strEstatus=='NEGOCIACION', 'clsTerminado cardis': strEstatus== 'GANADO' || strEstatus== 'PERDIDO','clsDesactive car3':  (strEstatus =='PROPUESTA ECONOMICA'||strEstatus =='RECORRIDO'||strEstatus =='PRESENTACION'||strEstatus =='POR CONTACTAR')}"
                            (click)="fnCheckValidaciones('NEGOCIACION')">
                            <div class="col-12 text-center ">Negociación</div>
                            <!-- <div class="col- text-center">1 Días</div> -->
                        </div>
                        <div class="col-2    py-2  border-start-0 border-end-0 position-relative cursorPointer"
                            [ngClass]="{'clsActive cardisActive': strEstatus=='GANADO' || strEstatus== 'PERDIDO', 'clsDesactive2 carddeactive2 ':  strEstatus !='GANADO' && strEstatus!= 'PERDIDO'}"
                            (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
                            <div class="col-12 text-center ">Cierre</div>
                            <!-- <div class="col- text-center">1 Días</div> -->
                            <div class="card d-flex flex-column position-absolute top-100 start-50 translate-middle mt-4 p-2 bg-ligth zind "
                                *ngIf="inthov &&  strEstatus!='GANADO' || strEstatus== 'PERDIDO'"
                                (click)="fnModalCerrar();">
                                <button class="btn btn-outline-info text-nowrap fs11 mb-2" data-bs-toggle="modal"
                                    data-bs-target="#modalCierre1" ><!--*ngIf="arrayAceptados.length > 0"-->
                                    <i class="bi bi-hand-thumbs-up-fill"></i>Sueño Ganado
                                </button>
                                <button class="btn btn-outline-info text-nowrap fs11" data-bs-toggle="modal"
                                    data-bs-target="#modalCierre2" (click)="fnModalCerrar()">
                                    <i class="bi bi-hand-thumbs-down-fill"></i>Sueño Perdido
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12 d-flex">
                    <span class="me-auto">
                        <i class="bi bi-flag"></i>
                        <span class="nunito">{{objLeadInfo?.strFechaCreacion }}</span>
                    </span>
                    <span class="d-flex alig-items-center">
                        <span class="nunito">{{objLeadInfo?.strFechaCierre_Estimada}}</span>
                        <i class="bi bi-flag-fill clsFlat"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-evenly">
        <!-- parte izquierda -->
        <div class="col-12 col-md-3 rounded bg-white shadow-sm d-flex flex-column py-2 fs12">
            <div class="col-12">
                <span class="col-12 d-flex justify-content-center">
                    <img *ngIf="strUrlLogo == ''" src="assets/detoportunidad/noDisponible.jpg" alt="Logotipo del Lead"
                        class=" rounded-circle w-50 m-auto ">
                    <img *ngIf="strUrlLogo != ''" src="https://www.ecodeli.com/{{strUrlLogo}}" alt="Logotipo del Lead"
                        width="180px" style="object-fit: cover; border-radius: 15px;" class="m-auto">
                </span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-clipboard"></i> Lead Origen:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.intID_Leads}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-tag"></i> Clasificación:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strClasificacion}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-buildings"></i>Nombre Empresa:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strNombreEmpresa}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-person"></i> Nombre Contacto:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strContacto_Nombre}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-person-check"></i> Puesto:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strContacto_Puesto}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-telephone"></i> Telefono:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strContacto_Telefono}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-envelope"></i> Email:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strContacto_Email}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-person-square"></i> Agente:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strNombreAgente}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1" *ngIf="objLeadInfo && objLeadInfo.strFamilia"><i class="bi bi-people"></i>
                    Familia:</span>
                <span class="mb-1 ms-auto"
                    *ngIf="objLeadInfo && objLeadInfo.strFamilia">{{objLeadInfo?.strFamilia}}</span>
            </div>

            <div class="col-10 mx-auto">
                <hr>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-bookmark"></i> Nombre:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strTitulo}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-person"></i> Agente Asignado:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strNombreAgente_Asignado}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-cart"></i> Linea:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strLinea}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-cash-coin"></i> Importe:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.dblImporte|currency}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-calendar-check"></i> Fecha Estimada Cierre:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strFechaCierre_Estimada}}</span>
            </div>
            <div class="col-12 d-flex mt-2">
                <span class="clsInact mt-1"><i class="bi bi-calendar-check"></i> Fecha Creación:</span>
                <span class="mb-1 ms-auto">{{objLeadInfo?.strFechaCreacion}}</span>
            </div>
        </div>
        <!-- parte del centro -->
        <div class="mt-3 mt-md-0 col-12 col-md-6  px-0 d-flex justify-content-center row">
            <div class="col-11 rounded bg-white shadow-sm px-0 container h-100">
                <div class="row d-flex">
                    <div class="p-0 text-center"><h4 class="fw-bold">Avance de Actividades(%)</h4></div>
                    <div class="col-10 m-auto">
                        <div class="progress mb-1" role="progressbar" aria-label="Success example" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100">
                        <div class="progress-bar progress-bar-striped bg-info" [ngStyle]="{'width.%': avance}">{{totalConcluidos}}/{{arrayActividades.length}}</div> 
                    </div>
                    </div>
                    <div class="col-1 m-auto">
                        <span class="fw-bold" style="font-size: 11px;">{{avance | number:'1.2-2'}}%</span>
                    </div>
                </div> 
              <div class="col-12 pb-3" #timeline1>
                <ul class="nav nav-pills  clsNavBg pt-2" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active custom-inactive fs13 " id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="true">Timeline</button>
                    </li>
                    <li class="nav-item " role="presentation">
                        <button class="nav-link custom-inactive fs13 " id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected="false" (click)="fnCleanSearch()">Tareas</button><!--(click)="fnllenarArrayTareas()"-->
                    </li>
                    <li class="nav-item " role="presentation">
                        <button class="nav-link custom-inactive fs13 " id="pills-minuta-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-minuta" type="button" role="tab" aria-controls="pills-minuta"
                            aria-selected="false">Minutas</button>
                    </li>
                    <!--  <li class="nav-item" role="presentation">
                      <button class="nav-link custom-inactive fs13" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Control de cambios</button>
                    </li> -->
                </ul>
              </div>
           
                <div class="tab-content px-3" #timeline id="pills-tabContent">
                    <div class="tab-pane fade show active "  id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-12 d-flex align-items-center ">
                                <span class="clsInact pe-2">Registrar</span>
                                <span class="pe-1">
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Correo');">
                                        <i class="bi bi-envelope pe-2"></i>
                                        Correo
                                    </button><!--[disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"-->
                                </span>
                                <span class="pe-1">
                                    <button class="btn d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Llamada');">
                                        <i class="bi bi-telephone-fill pe-2"></i>
                                        Llamada
                                    </button><!--[disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"-->
                                </span>
                                <span>
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Visita');">
                                        <span class="material-symbols-outlined fs13">
                                            handshake
                                        </span>
                                        Reunion
                                    </button><!--[disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"-->
                                </span>
                                <span>
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Tarea');">
                                        <span class="material-symbols-outlined fs13">
                                            edit_note
                                        </span>
                                        Tarea
                                    </button>
                                </span>
                                <span class="ms-auto col-3 position-relative">
                                    <input type="text" class="form-control ps-4 fs12" placeholder="Filtrar Timeline"
                                        [(ngModel)]="searchInput">
                                    <i class="bi bi-funnel-fill position-absolute top-50 start-0 translate-middle-y ms-2"></i>
                                </span>
                            </div>
                        </div>
                        <div class="timelinescroll w-100" >
                            <div class="col-12 d-flex" *ngFor="let obj of arrayOport| filtersearch: searchInput: ['strTitulo', 'strNombre_timeline']; let ind = index;" [ngClass]="{'mb-3':ind==(arrayOport.length - 1),'mt-3':ind == 0}">
                                <div class="col-12 d-flex timeLineHover" data-bs-toggle="modal" data-bs-target="#MConcluirActividades" (click)="fnmDLConAct(obj)" *ngIf=" obj.strTipo != 'Oportunidad'">
                                    <div class="col-2">
                                        <div class="d-flex justify-content-center">
                                            <span class="" [ngClass]="{'horizontal-line':ind==0,'horizontal-line2':ind>0}"></span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="border border-3 rounded-circle  d-flex justify-content-center "
                                                [ngClass]="{'border-info':obj.strEstatus == 'PROCESO','clsbordeTarea':obj.strEstatus == 'PENDIENTE','border-success':obj.strEstatus == 'CONCLUIDO','border-warning':obj.strEstatus == 'POR AUTORIZAR'}">
                                                <i class="bi px-2 py-1" *ngIf="obj.strEstatus != 'PROCESO'" [ngClass]="{'clsIconTarea bi-check-square':obj.strEstatus == 'PENDIENTE','text-success bi-check2-circle':obj.strEstatus == 'CONCLUIDO','text-warning bi-bell':obj.strEstatus == 'POR AUTORIZAR'}"></i>
                                                <i class="fa-solid fa-circle-half-stroke text-info px-2 py-1 " *ngIf="obj.strEstatus == 'PROCESO'"></i>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="horizontal-line2"></span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-flex align-items-center">
                                        <span class="fs12 pe-2">
                                            {{obj.strFechaCreacion}}
                                        </span>
                                    </div>
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="ps-3">
                                            <div class="col-12 fs13 fw-bold">
                                                <span> <i class="bi "
                                                        [ngClass]="{' bi-exclamation-triangle text-warning ':obj.strEstatus =='PENDIENTE',' bi-check-circle text-success':obj.strEstatus !='PENDIENTE'}"></i>
                                                    {{obj.strTitulo}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="clsInact fs12 me-2">{{obj.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs11 me-2"><i class="bi bi-building-fill me-2 text-secondary"></i>{{obj.strComentario}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs12" *ngIf="obj.strTipo!=='Oportunidad'">Fecha:
                                                    {{obj.strFechaCreacion}}</span>
                                                <span class="fs12 me-2" *ngIf="obj.strTipo=='Oportunidad'">Importe:
                                                    {{obj.dblImporte|currency}}</span>
                                                <span class="fs12" *ngIf="obj.strTipo=='Oportunidad'">Fecha Cierre:
                                                    {{obj.strFechaCierre_Estimada}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-12 d-flex" *ngIf=" obj.strTipo == 'Oportunidad' ">
                                    <div class="col-2">
                                        <div class="d-flex justify-content-center">
                                            <span class=""
                                                [ngClass]="{'horizontal-line':ind==0,'horizontal-line2':ind>0}"></span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span
                                                class="border border-3 rounded-circle  d-flex justify-content-center clsbordeOport Oportunidad">
                                                <i class="bi px-2 py-1 clsIconOport bi-cash"></i>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="horizontal-line2"></span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-flex align-items-center">
                                        <span class="fs12 pe-2">
                                            {{obj.strFechaCreacion}}
                                        </span>
                                    </div>
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="ps-3">
                                            <div class="col-12 fs13 fw-bold">
                                                <span><i class="bi bi-lightbulb clsIconOport "></i>
                                                    {{obj.strTitulo}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="clsInact fs12 me-2">{{obj.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs12" *ngIf="obj.strTipo!=='Oportunidad'">Fecha:
                                                    {{obj.strFechaCreacion}}</span>
                                                <span class="fs12 me-2" *ngIf="obj.strTipo=='Oportunidad'">Importe:
                                                    {{obj.dblImporte|currency}}</span>
                                                <span class="fs12" *ngIf="obj.strTipo=='Oportunidad'">Fecha Cierre:
                                                    {{obj.strFechaCierre_Estimada}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade timelinescroll" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Buscar</mat-label>
                                <input matInput [(ngModel)]="strBuscarAct"  placeholder="ID,Tarea,etc..." #input> <!-- (keyup)="applyFilterTareasCte($event)"  -->
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                            <div class="table-responsive mt-3">
                                <table class="table">
                                    <thead>
                                        <tr class="encabezado">
                                            <th></th>
                                            <th></th>
                                            <th>Título</th>
                                            <th>Responsable</th>
                                            <th>Origen</th>
                                            <th>Fecha</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="arrayActividades.length == 0">
                                            <tr>
                                                <td colspan="6">
                                                    <div class="card"
                                                        style="padding: 8px;text-align: center; font-weight: 600;">
                                                        <span>SIN DATOS</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngFor="let actividad of arrayActividades| filtersearch: strBuscarAct: ['strEstatus','strTitulo','strAgente','strNombreAgente','strOrigen']; let ind = index">
                                            <!-- Fila principal -->
                                            <tr class="p0">
                                                <td>
                                                    <button class="btn btn-secondary py-0 px-2 ms-auto"
                                                        (click)="fnSeeRow(ind,actividad)">
                                                        <i class="bi bi-caret-down-fill fs13"
                                                            *ngIf="!actividad.seeSubAct"></i>
                                                        <i class="bi bi-caret-up-fill fs13"
                                                            *ngIf="actividad.seeSubAct"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <div class="d-flex justify-content-center">
                                                        <div class=" "
                                                            *ngIf="actividad.strEstatus != 'POR AUTORIZAR' && actividad.strEstatus != 'PROCESO'">
                                                            <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                                disabled [checked]="actividad.strEstatus == 'CONCLUIDO'"
                                                                type="checkbox" id="customRadioCheckbox">
                                                        </div>
                                                        <div class=" text-center" *ngIf="actividad.strEstatus == 'POR AUTORIZAR'">
                                                            <button type="button"
                                                                class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                    class="fa-solid fa-bell"></i></button>
                                                        </div>
                                                        <div class=" text-center" *ngIf="actividad.strEstatus == 'PROCESO'">
                                                            <button type="button" class="btn btn-outline-info px-2 py-1 shadow-sm" title="En Proceso"><i class="fa-solid fa-circle-half-stroke"></i></button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades" (click)="fnmDLConAct(actividad)">
                                                    <span class="mx-auto flex-grow-1 text-center fw-bold">{{ actividad.strTitulo }}</span>
                                                </td>
                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                    (click)="fnmDLConAct(actividad)">
                                                    <span class="rounded-circle p-2 fs11 fw-bold text-center"
                                                        [ngClass]="{'res1':ind % 2 ==0,'res2':ind % 2 !=0}">
                                                        {{actividad.strAgente}}

                                                    </span>
                                                    <span class="ps-1 fs11 fw-bold">
                                                        {{actividad.strNombreAgente}}
                                                    </span>
                                                </td>
                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                    (click)="fnmDLConAct(actividad)" class="fs12 fw-bold descripcion-truncada" mattool>{{ actividad.strOrigen }}</td>
                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                    (click)="fnmDLConAct(actividad)">
                                                    <span class="fw-bold px-2 d-flex fw-bold"
                                                        matTooltip="{{actividad.strEstatus}}"
                                                        [ngClass]="{'badFecha':actividad.diasDiferencia>-1 || (actividad.diasDiferencia<0 && actividad.strEstatus != 'PENDIENTE'),'badFechamenor':actividad.diasDiferencia<0 && actividad.strEstatus == 'PENDIENTE'}">
                                                        <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                        {{actividad.strFechaRequerida|date:'dd/MM/yyyy' }}
                                                    </span>
                                                </td>
                                                <td>

                                                    <button class="btn  my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                      </button>
                                                      <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                                                        <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                                                        <button mat-menu-item data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                                        (click)="fnModalRegistrarSubtarea('Subtarea', actividad);">
                                                          <mat-icon class="text-success">note_add</mat-icon>
                                                          <span class="text-success">SubTarea </span>
                                                        </button>
                                                        <button mat-menu-item  data-bs-toggle="modal" data-bs-target="#mdlEditar"
                                                        (click)="fnEditar_Actividad(actividad)">
                                                          <mat-icon class="txtInfo">edit</mat-icon>
                                                          <span class="txtInfo">Editar</span>
                                                        </button>
                                                        <button mat-menu-item *ngIf="actividad.strEstatus == 'PENDIENTE'"
                                                        (click)="fnDelete_Tarea(actividad)">
                                                    
                                                            <mat-icon color="warn">delete</mat-icon>
                                                            <span class="text-danger">Eliminar</span>
                                                          </button>
                                                      </mat-menu>
                                                </td>
                                            </tr>

                                            <!-- Tabla secundaria cuando seeSubAct es true -->
                                            <tr *ngIf="actividad.seeSubAct">
                                                <td colspan="7" class="p-0">
                                                    <div class="container-fluid bg-light">
                                                        <div class="row justify-content-end">
                                                            <div class="col-11 py-2 ">
                                                                <div class="table-responsive mb-0">
                                                                    <table class="table text-center table-striped">
                                                                        <thead>
                                                                            <tr class="subActivid">
                                                                                <th></th>
                                                                                <th>Título</th>
                                                                                <th>Responsable</th>
                                                                                <th>Origen</th>
                                                                                <th>Fecha</th>
                                                                                <th>Acciones</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container *ngFor="let subActividad of arraySubTareas; let inds= index">
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="d-flex justify-content-center">
                                                                                            <div class=" "
                                                                                                *ngIf="subActividad.strEstatus != 'POR AUTORIZAR' && subActividad.strEstatus != 'PROCESO'">
                                                                                                <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                                                                    disabled [checked]="subActividad.strEstatus == 'CONCLUIDO'"
                                                                                                    type="checkbox" id="customRadioCheckbox">
                                                                                            </div>
                                                                                            <div class=" text-center" *ngIf="subActividad.strEstatus == 'POR AUTORIZAR'">
                                                                                                <button type="button"
                                                                                                    class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                                                        class="fa-solid fa-bell"></i></button>
                                                                                            </div>
                                                                                            <div class=" text-center" *ngIf="subActividad.strEstatus == 'PROCESO'">
                                                                                                <button type="button"
                                                                                                    class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                                                                    title="En Proceso"><i
                                                                                                        class="fa-solid fa-circle-half-stroke"></i></button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                
                                                                                    
                                                                                    <td data-bs-toggle="modal"
                                                                                        data-bs-target="#MConcluirActividades"
                                                                                        (click)="fnmDLConAct(subActividad)">{{
                                                                                        subActividad.strTitulo }}</td>
                                                                                    <td data-bs-toggle="modal"
                                                                                        data-bs-target="#MConcluirActividades"
                                                                                        (click)="fnmDLConAct(subActividad)">
                                                                                        <span
                                                                                            class="rounded-circle p-2 fs11 fw-bold text-center"
                                                                                            [ngClass]="{'res1':inds % 2 ==0,'res2':inds % 2 !=0}">
                                                                                            {{subActividad.strAgente}}
                                                                                        </span>
                                                                                        <span class="ps-1 fs11">
                                                                                            {{subActividad.strNombreAgente}}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades" class="fs11" (click)="fnmDLConAct(subActividad)">{{subActividad.strOrigen }}</td>
                                                                                    <td data-bs-toggle="modal"
                                                                                        data-bs-target="#MConcluirActividades"
                                                                                        (click)="fnmDLConAct(subActividad)">
                                                                                        <span class="fw-bold px-2 d-flex fs11"
                                                                                            matTooltip="{{subActividad.strEstatus}}"
                                                                                            [ngClass]="{'badFecha':subActividad.diasDiferencia>-1 || (subActividad.diasDiferencia<0 && subActividad.strEstatus != 'PENDIENTE'),'badFechamenor':subActividad.diasDiferencia<0 && subActividad.strEstatus == 'PENDIENTE'}">
                                                                                            <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                                                            {{subActividad.strFechaRequerida|date:'dd/MM/yyyy'}}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button class="btn my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                                                                            <i class="bi bi-three-dots-vertical"></i>
                                                                                          </button>
                                                                                          <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                                                                                            <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                                                                                            <button mat-menu-item  data-bs-toggle="modal"
                                                                                            data-bs-target="#mdlEditar"
                                                                                            (click)="fnEditar_Actividad(subActividad)">
                                                                                              <mat-icon class="txtInfo">edit</mat-icon>
                                                                                              <span class="txtInfo">Editar</span>
                                                                                            </button>
                                                                                            <button mat-menu-item *ngIf="subActividad.strEstatus == 'PENDIENTE'"
                                                                                            (click)="fnDelete_Tarea(subActividad)">
                                                                                        
                                                                                                <mat-icon color="warn">delete</mat-icon>
                                                                                                <span class="text-danger">Eliminar</span>
                                                                                              </button>
                                                                                          </mat-menu>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                          <!--   <div class="mb-0 table-responsive">
                                <div class="mat-elevation-z8 mb-0 mt-3 shadow rounded">
                                    <table mat-table [dataSource]="datosTareasCte" class="table table-hover"  matSort #sortTareasCte="matSort">
                                      
                                      <ng-container matColumnDef="btnRadio">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13">  </th>
                                        <td mat-cell *matCellDef="let row" class="px-2 fs11 text-center align-middle"> 
                                            <div class="d-flex justify-content-center"> 
                                                <div class=" " *ngIf="row.strEstatus != 'POR AUTORIZAR'  && row.strEstatus != 'PROCESO'">
                                                    <input class="form-check-input custom-checkbox fs-6 mx-2" disabled [checked]="row.strEstatus == 'CONCLUIDO'" type="checkbox" id="customRadioCheckbox">
                                                </div>
                                                <div class=" text-center" *ngIf="row.strEstatus == 'POR AUTORIZAR'">
                                                    <button type="button" class="btn btn-outline-warning px-2 py-1 shadow-sm"><i class="fa-solid fa-bell"></i></button>
                                                </div>
                                                <div class=" text-center" *ngIf="row.strEstatus == 'PROCESO'">
                                                    <button type="button" class="btn btn-outline-info px-2 py-1 shadow-sm" title="En Proceso"><i class="fa-solid fa-circle-half-stroke"></i></button>
                                                </div>
                                          </div> 
                                        </td>
                                      </ng-container>
                                
                                      <ng-container matColumnDef="titulo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13"> Titulo </th>
                                        <td mat-cell *matCellDef="let row" class="mostDet fw-bold ps-0 fs13 text-start align-middle"> 
                                          <div class="d-flex justify-content-between align-items-center">
                                            <a data-bs-toggle="modal" data-bs-target="#MConcluirActividades" (click)="fnmDLConAct(row)">{{row.strTitulo|uppercase}}</a>
                                            <div class="mostDet1 me-2">
                                                <span class="d-flex align-items-center">
                                                    <mat-icon class="text-secondary">more_vert</mat-icon>
                                                </span>
                                            </div>
                                          </div>  
                                        </td>
                                      </ng-container>
                                      origen Column 
                                      <ng-container matColumnDef="origen">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13"> Origen </th>
                                        <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle"> {{row.strOrigen}} </td>
                                      </ng-container>

                                      <ng-container matColumnDef="origen">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13"> Acciones </th>
                                        <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle"> 
                                            <button type="button" class="btn btn-outline-primary me-1"  data-bs-toggle="modal" data-bs-target="#mdlEditar" (click)="fnEditar_Actividad(row)"><i class="fa-solid fa-pen-to-square"></i></button>
                                            <button  *ngIf="row.strEstatus =='PENDIENTE'" type="button" class="btn btn-outline-danger me-1" (click)="fnDelete_Tarea(row)"><i class="fa-solid fa-trash-can"></i></button>
                                            
                                        </td>
                                      </ng-container>

                                       responsable Column 
                                      <ng-container matColumnDef="responsable">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="fs13"> Responsable </th>
                                        <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-start align-middle">
                                            <div class="d-flex align-items-center">
                                                <span class="rounded-circle p-2 fs11 fw-bold text-center"[ngClass]="{'res1':ind % 2 ==0,'res2':ind % 2 !=0}">
                                                   {{row.strAgente}}
                                                 <img [src]="'assets/avatar1.png'" class="rounded-circle "style="width:30px" alt=""> 
                                                </span>
                                                <span class="ps-1 fs11">
                                                    {{row.strNombreAgente}} 
                                                </span>
                                            </div> 
                                        </td>
                                      </ng-container>
                                     
                                      <ng-container matColumnDef="fechaInicio">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0"> Fecha </th>
                                        <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle pe-0">
                                           <div class="col-12 d-flex align-items-center justify-content-center">
                                                <span class="fw-bold px-2 d-flex" matTooltip="{{row.strEstatus}}" [ngClass]="{'badFecha':row.diasDiferencia>-1 || (row.diasDiferencia<0 && row.strEstatus != 'PENDIENTE'),'badFechamenor':row.diasDiferencia<0 && row.strEstatus == 'PENDIENTE'}">
                                                    <i class="bi bi-circle-fill fs4 pe-1"></i> {{row.strFechaRequerida|date:'dd/MM/yyyy' }}
                                                </span>
                                           </div>
                                        </td>
                                      </ng-container>

                                  
                                      <tr mat-header-row class="rounded" *matHeaderRowDef="columnsTareasCte"></tr>
                                      <tr mat-row *matRowDef="let row; columns: columnsTareasCte;" ></tr>
                                  
                                     
                                      <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="6">No se encontraron datos de: "{{input.value}}"</td>
                                      </tr>
                                    </table>
                                  </div>
                            </div>
                            <mat-paginator class="paginator" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorTareasCte></mat-paginator> -->
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    </div>
                      <!-- inicia minuta -->
                      <div class="tab-pane fade" id="pills-minuta" role="tabpanel" aria-labelledby="pills-minuta-tab">
                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <button type="button" class="btn btn-minuta col-12 mb-3" data-bs-toggle="modal"
                                    data-bs-target="#modalAddMinuta" (click)="fnAddMinuta()">
                                    <i class="fa-solid fa-plus"></i>
                                    Crear Nueva Minuta
                                </button>

                                <div class="mt-3 fs14 d-flex flex-column">
                                    <b>Bienvenido</b>
                                    <span class="mt-3">
                                        Minutas Ecodeli, permite documentar los temas más relevantes de las reuniones.
                                    </span>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="row">
                                    <div *ngFor="let minuta of arrayMinuta;let ind = index" class="col-12 col-xxl-6">
                                        <div class="bordertop borderbott mb-2 shadow-sm p-3 border borderMinBlue pointer"
                                            (click)="fnDetalleMinuta(minuta)"
                                            [ngClass]="{'mt-2':ind>1,'borderMinBlue': ind % 2 === 0, 'borderMinYellow': ind % 2 !== 0}">
                                            <!-- [ngClass]="{ 'par-class': i % 2 === 0, 'impar-class': i % 2 !== 0 }" style="width: 18rem;"  -->
                                            <div class="">
                                                <div class="col-12 text-center">
                                                    <p class="card-text fw-bold text-dark text-center fs14">
                                                        {{minuta.strTitulo}}</p>
                                                </div>
                                                <div class="col-12 d-flex justify-content-between my-3">
                                                    <div class="col-6 d-flex flex-column">
                                                        <h4 class="mb-0">Fecha Inicio</h4>
                                                        <h4 class="card-title fw-bold mb-0">
                                                            {{minuta.strFechaInicioServicio }}</h4>
                                                    </div>
                                                    <div class="col-6 d-flex flex-column text-end">
                                                        <h4 class="mb-0">Hora Inicio</h4>
                                                        <h4 class="mb-0 text-secondary fw-bold">{{minuta.strHoraInicio}}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-flex justify-content-between">
                                                    <button class="btn btn-outline-info py-1 px-2">
                                                        <i class="bi bi-pencil-square"></i>
                                                    </button>
                                                    <button class="btn btn-outline-danger py-1 px-2"
                                                        (click)="deleteMinuta(minuta, $event)">
                                                        <i class="fa-solid fa-trash-can "></i>
                                                    </button>
                                                </div>
                                                <!--  <a href="#" class="card-link me-2">Card link</a>
                                                <a href="#" class="card-link bg-danger btn"></a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- finaliza minuta -->
                </div>
            </div>

        </div>
        <!-- PARTE DERECHA -->
        <div class="col-12 col-md-3 mt-3 mt-md-0">
            
                <!-- Oportunidades
                <div >
                    <div class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-between mb-3">
                        <span class="clsInact fw-bold">SUEÑOS</span>
                        <button class="d-flex align-items-center btn btn-primary py-1 px-2 fw-bold" data-bs-toggle="modal" data-bs-target="#nuevaOportunidad">
                            <i class="bi bi-plus"></i> 
                            <span class="fs11">Agregar</span>
                        </button>
                    </div>
                    <div class="card" *ngIf="arrayOportunidades.length == 0" (click)="enviarDetalleOporturnidad(item.strEstatus)" >
                        <div class="card-header text-center">
                            <span class="fw-bold">SIN DATOS</span>
                        </div>
                    </div>
                 
                    <div class="card mb-2 shadow-sm cursor" *ngFor="let item of arrayOportunidades; let i = index"
                        (click)="enviarDetalleOporturnidad(item)">
                        <div class="card-header">
                            <span class="fw-bold fs13">{{item.strTitulo}}</span>
                        </div>
                        <div class="card-body fs13">
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <span class="badge bg-primary ms-auto">{{item.strEstatus}}</span>
                                </div>
                                <div class="col-3 d-flex align-items-center">
                                    <div class="circle p-2">
                                        <span class="text">{{item.strABNombre}}</span> 
                                    </div>
                                </div>
                                <div class="col-9 text-center row d-flex">
                                    <h5 class="fw-bold text-secondary m-auto"
                                        style="white-space: initial; font-size: 11px">
                                        {{item.strNombreAgente}}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12 d-flex fs11">
                                    <div class="me-auto">
                                        <span>{{item.dblImporte | currency:'':'symbol':'1.2-2' }}</span>
                                    </div>
                                    <div class="ms-auto">
                                        <span>
                                            <i class="fas fa-calendar-alt"></i>
                                            {{(item.strFechaCierre_Estimada)|date:'dd/MM/yyyy'}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!--productos-->
                <div >
                    <!-- <div class="col-12 d-flex align-items-center justify-content-between">
                        
                        <span class="clsInact fw-bold">Lineas</span> -->
                        <!-- <button  data-bs-dismiss="modal" class="btn btn-success py-0 px-1 align-items-center me-2 shadow-sm" data-bs-toggle="modal" data-bs-target="#mdlAddArts">
                            <i class="fa-solid fa-plus fs12 "></i>
                        </button> -->
                   <!--  </div> -->
                   <div [hidden]="spiner == false">
                    <div class="col-12 d-flex justify-content-center" >
                         <mat-spinner></mat-spinner>
                    </div>
                 </div>
                    <div class="col-12" [style.max-height.px]="altoInfoGral" style="overflow-y:auto;">
                            <div class="col-12" *ngIf="!spiner">
                                <mat-accordion>
                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                                         (closed)="panelOpenState = false">
                                      <mat-expansion-panel-header class="bgLin rounded-bottom-none py-2">
                                        <mat-panel-title >
                                          LINEAS
                                        </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <div class="col-12 rounded bg-ligth ">
                                        <div class="col-12 d-flex justify-content-between align-items-center border-bottom py-1" *ngFor="let cet of arrayLineasOportunidad;let ind = index" [ngClass]="{'bg-white':ind % 2 ==0 }">
                                            <div class="col-6">
                                                <label class="ps-1 fs12"> 
                                                    {{cet.strLinea}}
                                                </label>
                                            </div> 
                                            <div class="col-1 text-end">
                                                <span class="text-end">
                                                    $
                                                </span>
                                            </div>
                                            <div class="col-5 pe-1 d-flex align-items-center">
                                                <div class="input-group ">
                                                    <input type="number" class="form-control fs12 no-spin "   [value]="cet.dblMonto" (input)="updateCantidad($event, cet)">
                                                    <button class="btn btn-outline-primary dropdown-toggle fs10" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                                    <ul class="dropdown-menu dropdown-menu-end">
                                                      <li><button class="dropdown-item btn-warning fs10" (click)="fnActualizar(cet)">ACTUALIZAR</button></li>
                                                      <li><button class="dropdown-item btn btn-danger fs10" (click)="fnEliminarLinOport(ind)" >ELIMINAR</button></li>
                                                    </ul>
                                                  </div>

                                            </div> 
                                        </div>
                                        <div class="col-12 d-flex py-2 align-items-center bgAdd">
                                            <div class="col-6">
                                                <select class="form-select fs13" aria-label="Default select example" [(ngModel)]="lineaAgregar">
                                                    <option value="" selected>--Seleccióne--</option>
                                                    <option value="{{lin.strLinea}}" *ngFor="let lin of arrayLineas2">{{lin.strLinea}}</option>
                                                  </select>
                                            </div>
                                            
                                            <div class="col-1 d-flex justify-content-end align-items-center bgAdd">
                                              <span>$</span>  
                                            </div>
                                            <div class="col-5">
                                                <input class="form-control fs12 no-spin" type="number" (input)="updateCantidad1($event)" [(ngModel)]="dblMontoLineas">
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center bgAdd">
                                            
                                                <button class="btn btn-primary fs13" (click)="fnAgregarLineaOport(lineaAgregar)">
                                                    Agregar
                                                </button>
                                           
                                        </div>
                                    </div>


                                    </mat-expansion-panel>
                                  </mat-accordion>
                            </div>
                       

                        
                        





                        <div class="col-12 d-flex align-items-center justify-content-between mt-2">
                            <span class="clsInact fw-bold">PRODUCTOS</span>
                            <button  data-bs-dismiss="modal" class="btn btn-success py-0 px-1 align-items-center me-2 shadow-sm" data-bs-toggle="modal" data-bs-target="#mdlAddArts">
                                <i class="fa-solid fa-plus fs12 "></i>
                            </button>
                        </div>
                        <div class="col-12 rounded bg-white shadow-sm pt-2 mt-3" *ngFor="let o of arrayArticulos;let ind = index">
                            <div class="col-12   d-flex flex-column" >
                                <div class="col-12  ps-3">
                                    <span><i class="bi bi-basket-fill clsArts me-2"></i></span>
                                    <span class="me-2 fs12">{{o.strArticulo}}</span>
                                </div>
                                <div class="col-12 ps-3">
                                    <span class="clsInact fs10"> {{o.strDescripcion}}</span>
                                </div>
                            <!--  <div class="col-9 mx-auto">
                                    <hr *ngIf="ind != (arrayArticulos.length-1)" class="my-2">
                                </div> -->
                            </div>
                            <!-- <div class="col-12 text-center py-3">
                                <button class="btn clsTerminado px-2 py-1 fs13" [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'">Agregar Productos</button> 
                            </div>-->
                        </div>
                       
                        
                    </div>
                </div>
    
        </div>
    </div>
    <div class="row mt-3 d-flex">
        <div class="col-12 card m-auto">
            <mat-tab-group>
                <mat-tab >
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels" >
                                <i class="bi bi-file-earmark-plus mx-2 iconoTabs"></i> <span>Anexos</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-3">

                            <div class="col-10 d-flex justify-content-end mt-3">
                                <div class="input-group mb-3 w-50 ms-auto">
                                    <label class="input-group-text" for="inputGroupSelect01">Tipo
                                        Documento</label>
                                    <select name="tipo" id="tipo" class="form-select" [(ngModel)]="strTipoAnexo">
                                        <option value="">-- Selecciona una opcion --</option>
                                        <option value="Contrato">Contrato</option>
                                        <option value="Materiales">Materiales</option>
                                        <option value="Cotización">Cotización</option>
                                        <option value="evidencia">Evidencia foto</option>
                                        <option value="Servicios especiales">Servicios especiales</option>
                                        <option value="Cotizacion Rapida">Cotizacion Rapida</option>
                                        <option value="Cotizacion por TYM">Cotizacion por TYM</option>
                                        <option value="Licitaciones">Licitaciones</option>
                                        <option value="InfoCotizacion">Información para cotizar</option>
                                        <option value="Convenio_Precios">Convenio Precios</option>
                                        <option value="Catalogo">Catalogo</option>
                                        <option value="Formatos">Formatos</option>

                                    </select>
                                </div>
                            </div>
                            <div class="col-2 d-flex justify-content-center align-items-center">
                                <label class="btn btn-secondary btn-sm btn-file text-sm">
                                    <i class="fas fa-plus"></i> Agregar Anexo
                                    <input (change)="agregarAnexo($event)" #file type="file" style="display: none;"
                                        required accept=".xlsx, .pdf, .png, .jpg, .jpeg">
                                </label>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4 col-12 d-block mb-3 text-center"
                                *ngFor="let item of arrayAnexosUpdate; let i = index">
                                <div *ngIf="item.strTipo == 'xlsx'" class="d-flex justify-content-center">
                                    <div class="card w-75">
                                        <div class="card-body">
                                            <a>
                                                <svg class="svg_excel" xmsns="http://www.w3.org/2000/svg" height="1em"
                                                    viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                    <path
                                                        d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div class="card-footer">
                                            <label class="text-sm">{{item.strNombre}}</label>
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="item.strTipo == 'pdf'" class="d-flex justify-content-center">
                                    <div class="card w-75">
                                        <div class="card-body">
                                            <a>
                                                <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                                    viewBox="0 0 512 512" xmsns="http://www.w3.org/2000/svg">
                                                    <g>
                                                        <g>
                                                            <path
                                                                d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                fill="#f9f8f9" />
                                                            <path
                                                                d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                fill="#e3e0e4" />
                                                            <path
                                                                d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                fill="#e3e0e4" />
                                                            <path
                                                                d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                fill="#dc4955" />
                                                            <path
                                                                d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                fill="#dc4955" />
                                                            <path
                                                                d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                fill="#c42430" />
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                            <path
                                                                d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                            <path
                                                                d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                            <path
                                                                d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>
                                        </div>
                                        <div class="card-footer">
                                            <label class="text-sm">{{item.strNombre}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="item.strTipo == 'jpg' || item.strTipo == 'png' || item.strTipo == 'jpeg' || item.strTipo == 'jpg'"
                                    class="d-flex justify-content-center">
                                    <div class="card w-75">
                                        <div class="card-body">
                                            <a>
                                                <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                                    viewBox="0 0 512 512" width="512"
                                                    xmsns="http://www.w3.org/2000/svg">
                                                    <g>
                                                        <g>
                                                            <path
                                                                d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                fill="#f9f8f9" />
                                                            <path
                                                                d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                fill="#e3e0e4" />
                                                            <path
                                                                d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                fill="#e3e0e4" />
                                                            <path
                                                                d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                fill="#a29aa5" />
                                                            <path
                                                                d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                                                                fill="#9ec8fc" />
                                                            <path
                                                                d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                                                                fill="#62a4fb" />
                                                            <path
                                                                d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                fill="#9ec8fc" />
                                                            <path
                                                                d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                                                                fill="#b9a1d3" />
                                                            <path
                                                                d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                                                                fill="#9573bb" />
                                                            <path
                                                                d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                fill="#b9a1d3" />
                                                            <circle cx="219.587" cy="285.995" fill="#faf063"
                                                                r="21.578" />
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                                                            <path
                                                                d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                                                            <path
                                                                d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>
                                        </div>
                                        <div class="card-footer">
                                            <label class="text-sm">{{item.strNombre}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12" *ngIf="arrayAnexos.length==0 && arrayAnexosUpdate.length==0">
                                <div class="card mb-5" style="background: rgb(250, 250, 250);">
                                    <div class="card-header d-flex">
                                        <span class="m-auto fw-bold">
                                            Sin Anexos
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-accordion class="col-12">
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                    *ngFor="let item of arrayAnexos; let i = index" class="m-1" id="bordeCard">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="d-flex">
                                            {{item.strUrl.split('/')[1]}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-12 d-flex"
                                                *ngIf="item.strTipo != 'pdf' && item.strTipo !== 'xlsx'">

                                                <img src="https://ecodeli.com/{{item.strUrl}}" alt=""
                                                    style="object-fit: cover;" class="m-auto" width="200px">
                                            </div>
                                            <div class="col-12 d-flex" *ngIf="item.strTipo == 'pdf'">
                                                <a href="https://ecodeli.com/{{item.strUrl}}" class="m-auto"
                                                    target="_blank">
                                                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                                        viewBox="0 0 512 512" xmsns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                    fill="#f9f8f9" />
                                                                <path
                                                                    d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                    fill="#dc4955" />
                                                                <path
                                                                    d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                    fill="#dc4955" />
                                                                <path
                                                                    d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                    fill="#c42430" />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                <path
                                                                    d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                <path
                                                                    d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                <path
                                                                    d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="col-12 d-flex" *ngIf="item.strTipo == 'xlsx'">
                                                <a href="https://ecodeli.com/{{item.strUrl}}" class="m-auto" download>
                                                    <svg class="svg_excel" xmsns="http://www.w3.org/2000/svg"
                                                        height="1em"
                                                        viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                        <path
                                                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="row mt-3 mb-3">
                            <div class="col-12  d-flex">
                                <button type="button"
                                    class="btn btn-primary d-flex  ms-auto justify-content-center align-items-center mx-2"
                                    (click)="SubirAnexos()">
                                    <span class="material-icons-outlined me-1">save</span>
                                    <span>Guardar</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab >
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels">
                                <i class="bi bi-currency-dollar mx-2 iconoTabs"></i> <span>Cotizaciones</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="d-flex align-items-center justify-content-end mt-3">
                        <span (click)="fnCotizador()" data-bs-dismiss="modal"
                            class="btn btn-primary d-flex align-items-center mx-3 ">
                            <i class="fa-solid fa-plus"></i> Nueva Cotización
                        </span>
                    </div>
                    <div class="table-responsive mt-3">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr style="background: #071c34 !important; color: white;">
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>FECHA</th>
                                    <th>MONTO</th>
                                    <th>ESTATUS</th>
                                    <th *ngIf="strEtapa != 'PERDIDO'">ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="arrayCotizaciones.length == 0">
                                    <td colspan="5" class="text-center">SIN DATOS</td>
                                </tr>
                                <tr *ngFor="let item of arrayCotizaciones; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td data-bs-toggle="modal" data-bs-target="#detalleCotizacion"
                                        class="fw-bold cursor d-flex" (click)="fnGetDetalleCotizacion(item)">
                                        <span class="m-auto d-flex">
                                            <i class="bi bi-lightning-fill text-warning fs-6"
                                                *ngIf="item.strEstatus == 'POR AUTORIZAR'"></i>
                                            <i class="bi bi-x-circle-fill text-danger fs-6"
                                                *ngIf="item.strEstatus == 'RECHAZADO'"></i>
                                            <i class="bi bi-check-circle-fill text-success fs-6"
                                                *ngIf="item.strEstatus == 'AUTORIZADO'"></i>
                                            <span class="mx-2">{{item.intIDMovimientoVenta}}</span>
                                        </span>

                                    </td>
                                    <td>{{ item.strFecha }}</td>
                                    <td>{{ item.dblImporte | currency }}</td>
                                    <td><span class="badge " [ngClass]="{
                                        'text-bg-danger': item.strEstatus === 'RECHAZADO',
                                        'text-bg-success': item.strEstatus === 'AUTORIZADO',
                                        'text-bg-secondary': item.strEstatus === 'POR AUTORIZAR'    }">
                                      {{ item.strEstatus }}
                                    </span></td>

                                    <td class="d-flex" *ngIf="strEtapa != 'PERDIDO'">
                                        <!--[ngClass]="{'btn-warning': item.strEstatus == 'PENDIENTE', 'btn-info':item.strEstatus =='CONCLUIDO'}"-->
                                        <div class="m-auto">

                                            <button
                                                *ngIf="((strEtapa != 'NEGOCIACION') && item.strEstatus == 'AUTORIZADO' ) || (strEtapa == 'NEGOCIACION' && (item.strEstatus == 'AUTORIZADO' ))"
                                                [disabled]="item.strEstatusCliente == 'ACEPTADO' || item.strEstatusCliente == 'RECHAZADO'"
                                                [ngClass]="{'btn-outline-success': item.strEstatusCliente == 'ACEPTADO', 
                                                ' btn-outline-danger': item.strEstatusCliente == 'RECHAZADO',
                                                 ' btn-outline-dark': (!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')}"
                                                class=" dropdown-toggle text-sm btn " data-bs-toggle="dropdown"
                                                role="button" aria-haspopup="true" aria-expanded="false">
                                                <i class="bi bi-x-circle"
                                                    *ngIf="item.strEstatusCliente == 'RECHAZADO'"></i>
                                                <!--<i class="bi bi-check-circle"   *ngIf="item.strEstatusCliente == 'ACEPTADO'"></i>-->
                                                <i class="fa-solid fa-circle-check"
                                                    *ngIf="item.strEstatusCliente == 'ACEPTADO'"></i>

                                                <i class="fa-regular fa-circle-question"
                                                    *ngIf="(!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')"></i>
                                            </button>
                                            <!--(click)="stopPropagation4(i, 'miDropdownMenu')"-->
                                            <div #dropdownMenu (click)="stopPropagation2($event)"
                                                id="miDropdownMenu{{i}}"
                                                class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh">
                                                <div class="container-fluid">
                                                    <div class="row mt-2">
                                                        <div class="col-12 mt-1">
                                                            <div class="row">
                                                                <div class="col-8 d-flex justify-content-between">

                                                                    <select name="accioness"
                                                                        [(ngModel)]="strEstatusCotizacion" id="acciones"
                                                                        class="form-select" [(ngModel)]="strResultado">
                                                                        <option value="">
                                                                            --Selecciona una opción--</option>
                                                                        <option value="ACEPTADO">
                                                                            ACEPTADO</option>
                                                                        <option value="RECHAZADO">
                                                                            RECHAZADO
                                                                        </option>

                                                                    </select>
                                                                </div>
                                                                <div class="col-3 d-flex">
                                                                    <button
                                                                        class="btn btn-primary btn-sm  text-white ms-auto mt-auto fs-6 d-flex justify-content-center alight-items-center "
                                                                        (click)="fnsetEstatus_Cotizacion(item.intIDMovimientoVenta)">
                                                                        <span
                                                                            class="material-symbols-outlined mt-1 fs-6">
                                                                            save </span>Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- COPIADO DE COTIZACION-->
                                            <button *ngIf="item.strEstatus=='AUTORIZADO' || item.strEstatus=='RECHAZADO'"
                                              
                                                class="btn btn-outline-primary  mx-2"
                                                (click)="fnAddCar_Cotizacion(item,'COPIA')" title="Copiar Cotización">
                                                <i class="fa-solid fa-copy"></i>
                                            </button>
                                            <button *ngIf="item.strEstatusCliente=='POR AUTORIZAR' "
                                                
                                                class="btn btn-outline-info  mx-2"
                                                (click)="fnAddCar_Cotizacion(item,'EDITAR')" title="Editar Cotización">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button  *ngIf="item.strEstatus=='AUTORIZADO' "
                                               
                                                class="btn btn-outline-success  mx-2"
                                                (click)="fnAddCar_Cotizacion(item,'VER')" title="Ver Cotización">
                                                <i class="bi bi-eye-fill"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-bag-check iconoTabs mx-2"></i>
                                <span class="m-auto">
                                    Articulos Asignados
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center py-3" *ngIf="spinerArtsPolitica">
                                <mat-spinner></mat-spinner>
                            </div>
                            <div class="col-12 py-3 d-flex justify-content-around align-items-center" *ngIf="!spinerArtsPolitica">
                                <mat-form-field appearance="outline" class="rounded bg-white">
                                    <mat-label>Buscar</mat-label>
                                    <input matInput placeholder="Articulo, Descripcion, unidad, etcc." #input
                                        (keyup)="applyFilterArt($event)">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <button class="btn btn-outline-danger fs12 fw-bold" (click)="fnPdfArtsPolitica()">
                                    <i class="bi bi-file-earmark-pdf fs-6"></i> Descargar PDF
                                </button>
                               <!-- <button class="ms-auto btn btn-success py-1 px-2 fs13 fw-bold">Agregar a
                                    carrito</button>-->
                            </div>
                            <div class="col-12 table-responsive px-0 mb-0" [hidden]="spinerArtsPolitica">
                                <table mat-table [dataSource]="dataSourceC" matSort #sort2="matSort" class="table">
                                    <ng-container matColumnDef="indice">
                                        <th mat-header-cell *matHeaderCellDef class="ps-0">
                                            <span class="mx-auto">#</span>
                                        </th>
                                        <td mat-cell *matCellDef="let i = index"
                                            class="gastoCell ps-0 text-center align-middle">
                                            <b class="resalta">{{ i + 1 }}</b>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="img">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Imagen</th>
                                        <td mat-cell *matCellDef="let element" class="gastoCell ">
                                            <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArticulo + '.jpg'"
                                                alt="" class="p-2" width="100">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strArticulo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                            Articulo</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell fw-bold  align-middle text-center fs12">
                                            {{element.strArticulo}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strDescripcion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center">
                                            Descripción</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center fs10">
                                            {{element.strDescripcion}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strUnidad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="px-3 align-middle text-center">
                                            Unidad
                                        </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center fs12">
                                            {{element.strUnidad}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dblPrecio">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            Precio </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12 fw-bold">
                                            {{element.dblPrecio|currency}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strTipo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            Tipo </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12">
                                            <span class="fw-bold "
                                                [ngClass]="{'clsPlan':element.strTipo=='PLANTILLA','clsPolitica':element.strTipo=='POLITICA'}">{{element.strTipo}}</span>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strLista">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            Lista
                                        </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12">
                                            {{element.strLista}}
                                        </td> 
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCotizacioncte"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsCotizacioncte;"></tr>
                                </table>
                            </div>
                            <mat-paginator class="paginator"  [hidden]="spinerArtsPolitica" [pageSizeOptions]="[5, 10, 25, 100]"
                                aria-label="Select page of users" #paginator2></mat-paginator>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<!-- Modal RegistrarActividad -->
<div class="modal fade" id="mdlRegistrar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Registrar {{objModal &&
                    objModal.titulo?objModal.titulo:""}}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="col-12 mb-3">
                            <div class="col-12">
                                <h3 class="mb-1 fw-bold text-center">
                                    RESPONSABLES
                                </h3>
                            </div>
                            <mat-form-field class="example-full-width w-100">
                                <span matPrefix> </span>
                                <input type="text"  class="px-3" matInput placeholder="Nombre agente"  autocomplete="off" (keyup)="fnBusquedaCte(strBuscarAgente);" name="search" [(ngModel)]="strBuscarAgente">
                                <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-form-field>
                            <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                                <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let agt of arrayAgente_Filter"
                                    (click)="fnSeleccionaAgente(agt)">
                                    {{agt.strNombre}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-12" *ngFor="let agt of arrayAgentesActividades">
                            <button  class="btn btn-secondary position-relative fs12 shadow-sm col-12 mb-2">
                                ({{agt.strAgente}}) - {{agt.strNombre}}
                                <i (click)="fnEliminarAgenteAct(agt)" class="fa-solid fa-x position-absolute top-0 end-0 mt-1 me-1 fs11"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col-5">
                                <div class="col-12">
                                    Asunto
                                </div>
                                <div class="col-12">
                                    <input type="text" placeholder="Agregue asunto" class="form-control"
                                        [(ngModel)]="mdlNombre">
                                </div>
                            </div>
                            <div class="col-2">
        
                            </div>
                            <div class="col-5">
                                <div class="col-12">
                                    Fecha Compromiso
                                </div>
                                <div class="col-12">
                                    <input type="date" class="form-control" [(ngModel)]="strfecha">
                                </div>
                            </div>
        
                        </div>
                        <div class="row my-3">
                            <div class="col-12 col-md-5" *ngIf="objModal.titulo != 'Tarea'">
                                <div class="col-12">
                                    Hora Inicio
                                </div>
                                <div class="col-12 d-flex">
                                    <select class="form-select w-100" *ngIf="time1=='am'" aria-label="Default select example"
                                        [(ngModel)]="mdlHoraInicio">
                                        <option *ngFor="let x of arrayAm">
                                            {{x.hora}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 col-md-2" *ngIf="objModal.titulo != 'Tarea'">
                            </div>
                            <div class="col-12 col-md-5 mt-3 mt-md-0" *ngIf="objModal.titulo != 'Tarea'">
                                <div class="col-12">
                                    Hora Fin
                                </div>
                                <div class="col-12 d-flex">
                                    <select class="form-select w-100" *ngIf="time2=='am'" aria-label="Default select example"
                                        [(ngModel)]="mdlHoraFin">
                                        <option *ngFor="let x of arrayAm">
                                            {{x.hora}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="col-12 mt-3 mb-2 d-block">
                                <span>Agente</span> <br>
                                <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                                    [(ngModel)]="strAgente">
                                    <option value="undefined">-Selecciona un Agente- </option>
                                    <option *ngFor="let item of arrayAgentes; let i = index" value="{{item.strAgente}}">
                                        {{item.strNombre}}</option>
                                </select>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="col-12 d-flex">
                                    <span class="me-auto">Sucursal</span> <span
                                        class="ms-auto d-fles justify-content-center align-items-center"><input
                                            (change)="selectAll()" type="checkbox" class="mx-1">Seleccionar todos <span
                                            class="badge text-bg-primary mx-1">{{numeroSeleccionados}}</span></span>
                                </div>
                                <div class="col-12 mt-2"><input type="text" class="form-control" placeholder="Buscar Sucursal"
                                        [(ngModel)]="searchText"></div>
                                <br>
                                <div class="table-wrapper">
                                    <table class="table align-middle table-sm ">
                                        <tbody style="max-height: 150px; overflow-y: auto;">
                                            <tr
                                                *ngFor="let item of arraySelect_suc_tareas | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                                <td><input (change)="sortArray();" type="checkbox" [(ngModel)]="item.selected">
                                                </td>
                                                <td>{{item.strNombreEmpresa}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>  -->
                        </div>
                        <div class="row">
                            <div class="col-12">
                                Comentario
                            </div>
                           <!-- <div class="col-12">
                                <textarea class="form-control" name="" id="" [(ngModel)]="mdlComentarios"></textarea>
                            </div>-->
                            <div contenteditable="true" (input)="onInput($event)"  #inputComentarios  class="editable-area"  style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;" >
                            </div>
        
                        </div>
                    </div>
                    <div class="col-12 col-md-3 d-flex mt-3 my-0">
                        <div class="col-12 d-flex">
                            <div class="col-12 border">
                                <div class="col-12 px-0 position-relative">
                                    <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                       <span class="ms-2 fs13">ADJUNTO</span>
                                    </h3>
                                    <button class="me-2 btn btnAdd position-absolute top-50 end-0 translate-middle-y py-0 px-2 shadow-sm" (click)="fnAdjuntarArchivosActividad()">
                                        <i class="bi bi-file-earmark-plus"></i>
                                    </button>
                                </div>
                                <div class="col-12 px-0">
                                    <input #fileInputActividades type="file" hidden  (change)="onFileSelectedActividad($event)">
                                </div>
                                <div class="col-11 mx-auto" *ngIf="arrayAgregarAnexosActividades.length > 0">
                                    <div class="row">
                                        <div class="col-6 d-flex" *ngFor="let anex of arrayAgregarAnexosActividades">
                                                <div class="col-12 px-0 shadow-sm border text-center">
                                                    <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}" target="_blank">
                                                    <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.extencion == 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'" class="bi bi-file-zip fs-4"></i>
                                                    <span class="fs10 text-ellipsi">
                                                        {{anex.nombreArchivo}}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <button class="btn btn-danger fs12 p-1" (click)="fnEliminarAnexosAct()">
                                                <i class="bi bi-trash3 fs14"></i> Limpiar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrar>Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="fnAnexosActividad()">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal cierre1 -->
<div class="modal fade" id="modalCierre1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Cerrar Sueño</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 mb-3">
                        Bien hecho! Ahora a cerrar el negocio
                    </div>
                    <div class="col-12 fw-bold">
                        Fecha de cierre
                    </div>
                    <div class="col-12">
                        <input type="date" class="form-control" [(ngModel)]="mdlCerrarFecha">
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-5">
                        <div class="col-12 fw-bold">
                            Importe
                        </div>
                        <div class="col-12 ">
                            <input type="number" class="form-control" [(ngModel)]="mdlCerrarImporte">
                        </div>
                    </div>
                    <div class="col-2">
                    </div>
                    <div class="col-5">
                        <div class="col-12 fw-bold">
                            Moneda
                        </div>
                        <div class="col-12 ">
                            <input type="text" value="MXN" class="form-control" disabled>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 fw-bold">
                        Explica los motivos por los que has generado el sueño
                    </div>
                    <div class="col-12">
                        <textarea class="form-control" name="" id="" [(ngModel)]="mdlCerrarMotivo"></textarea>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btncerrarModal" data-bs-dismiss="modal"
                    #btnCerrarMdlGanado>Cancelar</button>
                <button type="button" class="btn bgtitModal" (click)="fnSetCerrar('GANADO')">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- MODAL CIERRE 2 -->
<div class="modal fade" id="modalCierre2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Cerrar Sueño</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">


                <div class="row">
                    <div class="col-12 mb-3 fw-bold">
                        No te desanimes
                    </div>

                    <div class="col-12">
                        Fecha de cierre
                    </div>
                    <div class="col-12">
                        <input type="date" class="form-control" [(ngModel)]="mdlCerrarFecha">
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-12">
                        Razón de pérdida
                    </div>
                    <div class="col-12">
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="mdlCerrarMotivo">
                            <option selected value="">------</option>
                            <option value="Precio">Precio</option>
                            <option value="Oferta no adecuada">Oferta no adecuada</option>
                            <option value="Pérdida de contacto">Pérdida de contacto</option>
                            <option value="Otro">Otro</option>
                            <option value="Cancelado">Cancelado</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btncerrarModal" data-bs-dismiss="modal"
                    #btnCerrarMdlPerdido>Cancelar</button>
                <button type="button" class="btn bgtitModal" (click)="fnSetCerrar('PERDIDO')">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Concluir actividades -->
<div class="modal fade" id="MConcluirActividades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <span class="text-white shadow-sm borderbott bordertop px-2 py-1 d-flex flex-column" [ngClass]="{'bg-secondary':objAct?.strEstatus!= 'CONCLUIDO' && objAct?.strEstatus!= 'PROCESO' && objAct?.strEstatus!= 'PENDIENTE','bg-success':objAct?.strEstatus== 'CONCLUIDO','bgInfo':objAct?.strEstatus== 'PROCESO','bg-warning':objAct?.strEstatus== 'PENDIENTE'}">
                    <span class="mx-auto fs12">Estatus</span>
                    <span class="fw-bold mx-auto fs12">{{ objAct && objAct.strEstatus ?objAct.strEstatus:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                </span>

                <h1 class="modal-title fs-5 m-auto" id="exampleModalLabel">{{objAct?.strEstatus=='CONCLUIDO'?'Actividad Concluida':objAct?.strEstatus == 'POR AUTORIZAR'?'AUTORIZAR ACTIVIDAD':''}} 
                    <span class="fw-bold">{{objAct?.strTitulo ? objAct?.strTitulo:objAct?.strOrigen}}</span>
                    <!-- <span *ngIf="objAct?.strEstatus=='PENDIENTE'"> para poder avanzar</span> -->
                </h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="spineract" >
                    <div class="col-12 d-flex justify-content-center">
                        <mat-spinner></mat-spinner>
                    </div>
                </div>
               <!--  <div class="row" *ngIf="!spineract || objAct?.strEstatus=='PENDIENTE'|| objAct?.strEstatus=='PROCESO'" >
                    <div class="col-6 col-md-6 d-flex flex-column mt-2">
                        <span>Titulo</span>
                        <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct?.strTitulo}}</span>
                    </div>
                    <div class="col-6 col-md-6 d-flex flex-column mt-2">
                        <span>Asunto</span>
                        <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct?.strTipo}}</span>
                    </div>
                </div> --> 
                <div class="row justify-content-center " *ngIf="!spineract">
                    <div class="col-11" >
                        <!-- *ngIf="objAct?.strEstatus=='CONCLUIDO'|| objAct?.strEstatus == 'POR AUTORIZAR'" -->
                        <div class="row">
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column" >
                                    <span class="titIcon"><i class="bi bi-person-fill-gear clsIconModal "></i> Responsable:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strNombreAgente?objAct.strNombreAgente:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3" >
                                    <span class="titIcon"><i class="bi bi-person-circle clsIconModal"></i> Asignado por: </span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.nombreCrea?objAct.nombreCrea:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora Inicio:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraInicio?objAct.strHoraInicio:'00'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora Fin:</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraFin?objAct.strHoraFin:'00'}}</span>
                                </div>
                            </div>
                           
                            <div class="col-12 mt-3">
                                <div class="col-12 d-flex flex-column" >
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i> ASUNTO</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm" [innerHTML]="objAct&&objAct.strOpcion?objAct.strOpcion:'--Seleccione '+objAct?.strTipo+'--'"></span>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3" *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="titIcon"><i class="bi bi-chat-dots-fill clsIconModal"></i>Obervaciones</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm"
                                    [innerHTML]="objAct?.strObservaciones"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-2" *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="float-end titIcon"><i class="clsIconModal bi " [ngClass]="{'bi-hand-thumbs-up-fill':objAct?.strResultado == '1'||objAct?.strResultado == 'EXITOSO' ,'bi-hand-thumbs-down-fill':objAct?.strResultado == '2'|| objAct?.strResultado == 'SIN EXITO','bi-info-circle-fill':objAct?.strResultado == '0'}"></i> Resultado</span>
                                <span *ngIf="objAct?.strResultado == '1' || objAct?.strResultado == 'EXITOSO'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">EXITOSO</span>
                                <span *ngIf="objAct?.strResultado == '2' || objAct?.strResultado == 'SIN EXITO'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN EXITO</span>
                                    <span *ngIf="objAct?.strResultado == '0'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN RESPUESTA</span>
                            </div>
                           
                            <!-- <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                <span class="float-end">Resultado</span>
                                <span *ngIf="objDetActividad.strResultado == '1'"
                                class="py-1 px-2 bg-light float-end rounded shadow-sm">EXITOSO</span>
                                <span *ngIf="objDetActividad.strResultado == '2'"
                                class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN EXITO</span>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3" *ngIf="objDetActividad && objDetActividad.strObservaciones && objDetActividad.strObservaciones !='' ">
                                <span>Obervaciones</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm" [innerHTML]="objDetActividad.strObservaciones"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                <span>Hora Inicio</span>
                                <span
                                    class="py-1 px-2 bg-light rounded shadow-sm" >{{objDetActividad.strHoraInicio}}</span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                <span>Hora Fin</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm" *ngIf="objDetActividad && objDetActividad.strHoraFin && objDetActividad.strHoraFin !='' ">{{objDetActividad.strHoraFin}}</span>
                            </div>
                            <div class="col-12 d-flex flex-column mt-2" *ngIf="objDetActividad && objDetActividad.strSucursal && objDetActividad.strSucursal !='' ">
                                <span>Sucursal</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad.strSucursal}}</span>
                            </div> -->
                        </div>

                    </div>  
                  <!--   <div class="col-12 d-flex flex-column mt-2 mb-3" [ngClass]="{'visually-hidden':objAct?.strEstatus != 'CONCLUIDO'&&objAct?.strEstatus != 'POR AUTORIZAR'}">
                        <span>Titulo</span>
                        <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct?.strTitulo}}</span>
                    </div>
                    <div class="col-12 d-flex flex-column mt-2 mb-3" [ngClass]="{'visually-hidden':objAct?.strEstatus != 'CONCLUIDO'&&objAct?.strEstatus != 'POR AUTORIZAR'}">
                        <span>Sucursal</span>
                        <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct?.strComentario}}</span>
                    </div> -->
                    <!-- <div class="col-12 d-flex flex-column mt-2 mb-3" [ngClass]="{'visually-hidden':objAct?.strEstatus != 'CONCLUIDO'||objAct?.strEstatus != 'POR AUTORIZAR'}">
                        <span>Obervaciones</span>
                        <span class="py-1 px-2 bg-light rounded shadow-sm"
                            [innerHTML]="objAct?.strNombre_timeline"></span>
                    </div> -->
                    <div class="col-12 h-100 my-3" [ngClass]="{'visually-hidden':objAct?.strEstatus == 'CONCLUIDO'||objAct?.strEstatus == 'POR AUTORIZAR'}">
                        <div class="form-group h-100">
                            <textarea [(ngModel)]="contenido" class="custom-summernote h-100 summernote" id="summernote"
                                #summernote></textarea>
                        </div>
                    </div>
                    <div class="col-12 d-flex " *ngIf="objAct?.strEstatus=='PENDIENTE' || objAct?.strEstatus=='PROCESO'">
                        <select name="accioness" id="acciones" class="form-select m-auto" [(ngModel)]="strResultado" [disabled]="objAct?.strEstatus=='CONCLUIDO' ">
                            <option value="">
                                --Selecciona
                                una opción--</option>
                            <option value="1">
                                EXITOSO</option>
                            <option value="2">
                                SIN EXITO
                            </option>
                        </select>
                    </div>
                    <div class="col-12 d-flex mt-3">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarAct>{{objAct?.strEstatus=='PENDIENTE'?'Cancelar':'Cerrar'}} </button>
                            <button [disabled]="strResultado == '' || contenido == ''" class="btn btn-success  text-white ms-auto mt-auto" (click)="fnConcluirActividad(objAct)" *ngIf="objAct?.strEstatus=='PENDIENTE' || objAct?.strEstatus=='PROCESO'">Concluir</button>
                            <button type="button" class="btn btn-primary  ms-auto" *ngIf="objAct?.strEstatus=='PENDIENTE' " (click)="fnEnProceso(objAct)">En Proceso</button>
                            <button type="button" class="btn btn-secondary  ms-auto" *ngIf="objAct?.strEstatus=='PROCESO' " (click)="fnEnProceso(objAct)">Actualizar</button>
                            <button type="button" class="btn btn-success  ms-auto" *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea  " (click)="fnAutorizar(objAct)">Autorizar</button>
                            <button type="button" class="btn btn-danger  ms-auto" *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea " (click)="fnRechazar(objAct)">Rechazar</button>
                    </div>
                </div>
                <div class="row">
                   
                    <div class="col-12 py-3">
                        <div class="col-12 border">
                            <div class="col-12 px-0 ">
                                <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                   <span class=" fs13 mx-auto">ADJUNTOS</span>
                                </h3>
                                
                            </div>
                            <div hidden class="col-12 px-0">
                                <input #fileModal type="file"   (change)="onFileInsert($event)">
                            </div>
                           
                            <div class="col-11 mx-auto" >
                                <div class="row ">
                                    <div class="col-6 bg-light pb-2">
                                        <div class="col-12 d-flex py-2 justify-content-between">
                                            <span>Agregar</span> 
                                            <button class="me-2 btn btnAdd py-0 px-2 shadow-sm"  (click)="fnAjuntarAnexsModal()">
                                                <i class="bi bi-file-earmark-plus"></i>
                                            </button>
                                        </div>
                                        <div class="row" *ngIf="arrayAnexMdlAdd.length > 0">
                                            <div class="col-12 d-flex my-2" *ngFor="let anex of arrayAnexMdlAdd">
                                                <div class="col-6 px-0 shadow-sm border text-center">
                                                    <a class="d-flex flex-column p-2" target="_blank">
                                                    <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.extencion == 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'" class="bi bi-file-zip fs-4"></i>
                                                        <span class="fs10 text-ellipsi">
                                                            {{anex.nombreArchivo}}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div class="col-6 d-flex justify-content-center align-items-center px-2">
                                                    <button class="btn btn-primary fs12 p-1 me-3" (click)="fnAgregarArchivo()"><i class="bi bi-floppy"></i> <span class="fs11">Guardar</span></button>
                                                    <button class="btn btn-danger p-1 fs12" (click)="fnElinimarArchivos()" ><i class="bi bi-trash3"></i> <span class="fs11">Eliminar</span></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center" *ngIf="arrayAnexMdlAdd.length == 0">
                                            <div class="col-11 bg-secondary text-center rounded text-white">
                                                Seleccione archivos para agregar.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6" >
                                        <div class="col-12 py-2">
                                            <span class="text-center mb-0">Existentes</span>
                                        </div>
                                        <div class="row" *ngIf="arrayAnexosModal.length > 0">
                                            <div class="col-4 d-flex" *ngFor="let anex of arrayAnexosModal">
                                                <div class="col-12 px-0 shadow-sm border text-center">
                                                    <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}" target="_blank">
                                                    <i *ngIf="anex.strTipo == 'jpg' || anex.strTipo == 'png'|| anex.strTipo == 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.strTipo == 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.strTipo == 'xls' || anex.strTipo == 'xlsx'|| anex.strTipo == 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.strTipo == 'zip' || anex.strTipo == 'rar'" class="bi bi-file-zip fs-4"></i>
                                                        <span class="fs10 text-ellipsi">
                                                            {{anex.strNombreCorto}}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center" *ngIf="arrayAnexosModal.length == 0">
                                            <div class="col-11 bg-secondary text-center rounded text-white">
                                                Sin archivos agregados.
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                





            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="minutaCambioFase" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal">
                <h1 class="modal-title fs-5 mb-0" id="exampleModalLabel">Registro cambio de fase</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-12 h-100 mb-2">
                        <div class="form-group h-100">
                            <textarea [(ngModel)]="contenido" class="custom-summernote h-100 summernote" id="summernote"
                                #summernote></textarea>
                        </div>
                    </div>
                    <div class="col-12 d-flex mt-3">
                        <button type="button" class="btn btncerrarModal" data-bs-dismiss="modal"
                            #btnCerrarAct>Cerrar</button>
                        <button class="btn btn-primary  text-white ms-auto mt-auto" data-bs-dismiss="modal"
                            (click)="fnGuardarMinuta('PRESENTACION')">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Cotizacion -->
<div class="modal fade" id="cotizacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Agrega una cotizacion para poder avanzar</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mt-2">
                    <div class="col-12">
                        <div class="d-flex align-items-center mt-3">
                            <span (click)="fnCotizador()" data-bs-dismiss="modal"
                                class="btn btn-success d-flex align-items-center m-auto">
                                <i class="fa-solid fa-plus"></i> Nueva Cotización
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Cotizacion -->
<div class="modal fade" id="cotizacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Agrega una cotizacion para poder avanzar</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mt-2">
                    <div class="col-12">
                        <div class="d-flex align-items-center mt-3">
                            <span (click)="fnCotizador()" data-bs-dismiss="modal"
                                class="btn btn-success d-flex align-items-center m-auto">
                                <i class="fa-solid fa-plus"></i> Nueva Cotización
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="ConcluirCotizaciones" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
             <div class="modal-header text-center">
                <button class="btn btn-danger" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row d-flex p-3" *ngIf="arrayAceptados.length > 0">
                    <div class="col-12 text-center mb-2">
                        <i class="bi bi-hand-thumbs-up-fill fs-1 text-success"></i>
                    </div>
                    <div class="col-12 text-center ">
                        <h2 class="text-success fw-bold">BIEN HECHO</h2>
                    </div>
                    <div class="col-8 text-center m-auto">
                        <h5 class="modal-title text-center" id="exampleModalLabel"><span
                                class="text-secondary ms-auto">{{strMensaje}}</span></h5>
                    </div>
                </div>
                <div class="row d-flex" *ngIf="arrayRechazados.length > 0 || arrayPendientes.length > 0">
                    <div class="col-10 m-auto">
                        <h4 class="modal-title text-center" id="exampleModalLabel"><span
                                class="text-danger ms-auto">{{strMensaje}}</span></h4>
                    </div>
                    <div class="col-12 text-center d-flex justify-content-center align-items-center">
                        <span>Da click aqui para generar una nueva cotización</span> <br>
                    </div>
                    <div class="col-12 d-flex mb-3">
                        <button (click)="fnCotizador()" data-bs-dismiss="modal"
                            class="btn btn-success d-flex align-items-center m-auto">
                            <i class="fa-solid fa-plus"></i> Nueva Cotización
                        </button>
                    </div>
                    <div class="col-12 table-responsive">
                        <table class="table table-bordered text-center">
                            <thead class="table-light">
                                <tr>
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>FECHA</th>
                                    <th>MONTO</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="arrayCotizaciones.length == 0">
                                    <td colspan="5" class="text-center">SIN DATOS</td>
                                </tr>
                                <tr *ngFor="let item of arrayCotizaciones; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ item.intIDMovimientoVenta }}</td>
                                    <td>{{ item.strFecha }}</td>
                                    <td>{{ item.dblImporte | currency }}</td>
                                    <td class="d-flex">
                                        <!--[ngClass]="{'btn-warning': item.strEstatus == 'PENDIENTE', 'btn-info':item.strEstatus =='CONCLUIDO'}"-->
                                        <button
                                            [disabled]="item.strEstatusCliente == 'ACEPTADO' || item.strEstatusCliente == 'RECHAZADO'"
                                            [ngClass]="{'btn-success': item.strEstatusCliente == 'ACEPTADO', 'btn-danger': item.strEstatusCliente == 'RECHAZADO', 'btn-primary': (!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')}"
                                            class="text-white dropdown-toggle text-sm btn btn-sm  m-auto "
                                            data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false">
                                            <i class="bi bi-x-circle" *ngIf="item.strEstatusCliente == 'RECHAZADO'"></i>
                                            <i class="bi bi-check-circle"
                                                *ngIf="item.strEstatusCliente == 'ACEPTADO'"></i>
                                            <span
                                                *ngIf="(!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')"
                                                class="material-symbols-outlined fs-6">
                                                action_key
                                            </span>

                                        </button>
                                        <!--(click)="stopPropagation4(i, 'miDropdownMenu')"-->
                                        <div #dropdownMenu (click)="stopPropagation2($event)" id="miDropdownMenu{{i}}"
                                            class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh">
                                            <div class="container-fluid">
                                                <div class="row mt-2">
                                                    <div class="col-12 mt-1">
                                                        <div class="row">
                                                            <div class="col-8 d-flex justify-content-between">

                                                                <select name="accioness"
                                                                    [(ngModel)]="strEstatusCotizacion" id="acciones"
                                                                    class="form-select" [(ngModel)]="strResultado">
                                                                    <option value="">
                                                                        --Selecciona
                                                                        una opción--</option>
                                                                    <option value="ACEPTADO">
                                                                        ACEPTADO</option>
                                                                    <option value="RECHAZADO">
                                                                        RECHAZADO
                                                                    </option>
                                                                    <!-- <option value="correo Enviado">
                                                            Mail Enviado</option>
                                                        <option value="visita">Visita
                                                            realizada</option> -->
                                                                </select>
                                                            </div>
                                                            <div class="col-3 d-flex">
                                                                <button
                                                                    class="btn btn-primary btn-sm  text-white ms-auto mt-auto fs-6 d-flex justify-content-center alight-items-center "
                                                                    (click)="fnsetEstatus_Cotizacion(item.intIDMovimientoVenta)">
                                                                    <span class="material-symbols-outlined mt-1 fs-6">
                                                                        save </span>Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="detalleCotizacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header d-flex">
                <h1 class="modal-title fs-4 m-auto" id="exampleModalLabel">Detalle Cotización: <span
                        class="fw-bold">{{intIDMovimientoVenta}}</span></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 table-responsive">
                        <table mat-table [dataSource]="dataSourceCotizaciones" matSort class="table">
                            <ng-container matColumnDef="#">
                                <th mat-header-cell *matHeaderCellDef class="px-3">
                                    <span class="mx-auto">#</span>
                                </th>
                                <td mat-cell *matCellDef="let i = index" class="gastoCell px-3 py-4"><b
                                        class="resalta">{{ i + 1 }}</b>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Imagen">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Imagen</th>
                                <td mat-cell *matCellDef="let element" class="gastoCell ">
                                    <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArticulo + '.jpg'"
                                        alt="" class="p-3" width="100">
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="strArticulo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Articulo</th>
                                <td mat-cell *matCellDef="let element" class="gastoCell fw-bold py-4">
                                    {{element.strArticulo}} </td>
                            </ng-container>

                            <ng-container matColumnDef="strDescripcion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Descripción</th>
                                <td mat-cell *matCellDef="let element" class="gastoCell text-start py-4 px-3">
                                    {{element.strDescripcion}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="strOpcion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-3">
                                    Opción
                                </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell px-3 py-4">
                                    {{element.strOpcion}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="strNombreOpcion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-3">
                                    Nombre Opción
                                </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell px-3 py-4">
                                    {{element.strNombreOpcion}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="strUnidad">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Unidad </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{element.strUnidad}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dblCantidad">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Cantidad </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{element.dblCantidad}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dblPrecioSugerido">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Precio Sugerido </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{element.dblPrecioSugerido | currency:'':'symbol':'1.2-2' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="dblPrecio">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Precio </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{element.dblPrecio | currency:'':'symbol':'1.2-2' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dblImporte">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                    Importe </th>
                                <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                    {{(element.dblPrecio * element.dblCantidad) | currency:'':'symbol':'1.2-2'}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsCotizacion"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsCotizacion;"></tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="mdlAddArts" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title fw-bold" id="exampleModalLabel">Agregar Articulos </h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              LINEA: <span class="fw-bold">{{objLeadInfo?.strLinea}}</span> 
            </div>

            <div class="col-10">
                <mat-form-field class="example-full-width w-100">
                    <span matPrefix> </span>
                    <input type="text" class="px-3" matInput placeholder="Buscar Articulo"
                        (keyup)="funConsultarArt(strBuscarArticulo);" name="search"
                        [(ngModel)]="strBuscarArticulo">
                    <button matSuffix class="btn btn-darkky d-flex align-items-center "
                       ><!--  (click)="funArticulos(strBuscarArticulo);" -->
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-2">
                <button style="margin-right: auto" class="btn btn-primary" (click)="fnAgregarArticulo()"><i
                        class="fas fa-plus mx-1"></i></button>
            </div>
          </div>
        
        <div class="col-12 mb-2">
            <ul *ngIf="arrayArts.length > 0" class="list-group mt-1 overflowy">
                <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayArts"
                    (click)="fnselecArticulo(age)">
                    ({{age.strArticulo}}) {{age.strDescripcion}}
                </li>
            </ul>
        </div>

        <div class="col-12 table-responsive mt-4">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Producto</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="arrayArticuloAgregados.length == 0">
                        <td colspan="4" class="text-center">
                            <span>SIN DATOS</span>
                        </td>
                    </tr>
                    <tr *ngFor="let art of arrayArticuloAgregados; let i = index">
                        <td class="text-center">
                            {{ i + 1 }}
                        </td>
                        <td>
                            (<span class="fw-bold fs12">{{ art.strArticulo }}</span>)
                            <span class="fs10">{{ art.strDescripcion }}</span>
                        </td>
                        <td>
                            <button class="btn btn-danger"  (click)="fnEliminarArt(i)"><i class="fas fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnModalArts>Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="fnAgregarArticulos()">Guardar</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal nueva Oportunidad -->
<div class="modal fade" id="nuevaOportunidad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
data-bs-backdrop="static" data-bs-keyboard="false">
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header bgtitModal">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Nuevo Sueño</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 mt-2 mb-2">
                    <div class="input-with-label">
                        <label for="nombre" class="corner-label">Nombre *:</label>
                        <input type="text" class="form-control" [(ngModel)]="strNombreOportunidad">
                    </div>
                </div>

                <div class="col-6 mb-2">
                    <div class="input-with-label">
                        <label for="nombre" class="corner-label">Monto *:</label>
                        <input type="number" class="form-control" [(ngModel)]="dblMonto">
                    </div>
                </div>
                <div class="col-6 mb-2">
                    <div class="input-with-label">
                        <label for="nombre" class="corner-label">Fecha Estimada Cierre *:</label>
                        <input type="date" class="form-control" [(ngModel)]="strFechaCierre">
                    </div>
                </div>
                <div class="col-12 mb-2 mb-1">
                    <div class="input-with-label">
                        <label for="nombre" class="corner-label">Lineas *:</label>
                        <select class="form-select" [(ngModel)]="strLinea">
                            <option value="">-Selecciona una opcion--</option>
                            <option [value]="linea.strLinea" *ngFor="let linea of arrayLineas2">
                                {{linea.strLinea}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-12 mb-3">
                    <mat-form-field class="example-full-width w-100">
                        <span matPrefix> </span>
                        <input type="text" class="px-3" matInput placeholder="Responsable"
                            (keyup)="fnBuscarCte(strResponsable);" name="search" [(ngModel)]="strResponsable">
                        <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <ul *ngIf="arrayAsigAgentes.length > 0" class="list-group mt-1 overflowy">
                        <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayAsigAgentes"
                            (click)="fnselecCliente(age)">
                            {{age.strNombreAgente}}
                        </li>
                    </ul>
                </div>

                <div class="col-12">
                    <mat-form-field class="example-full-width w-100">
                        <span matPrefix> </span>
                        <input type="text" class="px-3" matInput placeholder="Buscar Articulo"
                            (keyup)="funConsultarArt(strBuscarArticulo);" name="search"
                            [(ngModel)]="strBuscarArticulo">
                        <button matSuffix class="btn btn-darkky d-flex align-items-center "><!-- funArticulos -->
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-12 mb-2">
                    <ul *ngIf="arrayArts.length > 0" class="list-group mt-1 overflowy">
                        <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayArts"
                            (click)="fnselecArticulo(age)">
                            ({{age.strArticulo}}) {{age.strDescripcion}}
                        </li>
                    </ul>
                </div>
                <div class="col-12 table-responsive mt-4">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Producto</th>
                                <th scope="col">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="arrayArticuloAgregados.length == 0">
                                <td colspan="4" class="text-center">
                                    <span>SIN DATOS</span>
                                </td>
                            </tr>
                            <tr *ngFor="let art of arrayArticuloAgregados; let i = index">
                                <td class="text-center fs12 fw-bold">
                                    {{ i + 1 }}
                                </td>
                                <td class="fs12">
                                    ({{ art.strArticulo }}) {{ art.strDescripcion }}
                                </td>
                                <td class="text-center">
                                    <button class="btn btn-danger fs-6" (click)="fnEiminarProductos(i)"><i
                                            class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
            <button type="button" class="btn btn-primary" (click)="fnInsertOportunidad()"
                data-bs-dismiss="modal">Guardar</button>
        </div>
    </div>
</div>
</div>


<!-- Modal Editar Actividad -->
<div class="modal fade" id="mdlEditar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Editar {{ objEditarActividad.strTipo  }}  id: {{ objEditarActividad.intID_Actividad  }}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-5">
                        <div class="col-12">
                            Asunto
                        </div>
                        <div class="col-12">
                            <input type="text" placeholder="Agregue asunto" class="form-control"
                                [(ngModel)]="mdlNombre">
                        </div>
                    </div>
                    <div class="col-2">

                    </div>
                    <div class="col-5">
                        <div class="col-12">
                            Fecha 
                        </div>
                        <div class="col-12">
                            <input type="date" class="form-control" [(ngModel)]="strfecha_editar">
                        </div>
                    </div>

                </div>
                <div class="row my-3">
                    <div class="col-12 col-md-5" *ngIf="objModal.titulo != 'Tarea'">
                        <div class="col-12">
                            Hora Inicio 
                        </div>
                        <div class="col-12 d-flex">
                            <select class="form-select w-100" *ngIf="time1=='am'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraInicio">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>
                            
                        </div>
                    </div>
                    <div class="col-12 col-md-2" *ngIf="objModal.titulo != 'Tarea'">
                    </div>
                    <div class="col-12 col-md-5 mt-3 mt-md-0" *ngIf="objModal.titulo != 'Tarea'">
                        <div class="col-12">
                            Hora Fin
                        </div>
                        <div class="col-12 d-flex">
                            <select class="form-select w-100" *ngIf="time2=='am'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraFin">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>
                      
                        </div>
                    </div>
                    <div class="col-12 mt-3 mb-2 d-block">
                        <span>Agente</span> <br>
                        <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                            [(ngModel)]="strAgente">
                            <option value="undefined">-Selecciona un Agente- </option>
                            <option *ngFor="let item of arrayAgentes; let i = index" value="{{item.strAgente}}">
                                {{item.strNombre}}</option>
                        </select>
                    </div>
                    <!--<div class="col-12 mt-2">
                        <div class="col-12 d-flex">
                            <span class="me-auto">Sucursal</span> <span
                                class="ms-auto d-fles justify-content-center align-items-center"><input
                                    (change)="selectAll()" type="checkbox" class="mx-1">Seleccionar todos <span
                                    class="badge text-bg-primary mx-1">{{numeroSeleccionados}}</span></span>
                        </div>
                        <div class="col-12 mt-2"><input type="text" class="form-control" placeholder="Buscar Sucursal"
                                [(ngModel)]="searchText"></div>
                        <br>
                        <div class="table-wrapper">
                            <table class="table align-middle table-sm ">
                                <tbody style="max-height: 150px; overflow-y: auto;">
                                    <tr
                                        *ngFor="let item of arraySelect_suc_tareas | filtersearch: searchText: ['strNombreEmpresa']; let i = index">
                                        <td><input (change)="sortArray();" type="checkbox" [(ngModel)]="item.selected">
                                        </td>
                                        <td>{{item.strNombreEmpresa}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-12">
                        Comentario
                    </div>
                   <!-- <div class="col-12">
                        <textarea class="form-control" name="" id="" [(ngModel)]="mdlComentarios"></textarea>
                    </div>-->
                    <div #editableDiv contenteditable="true" (input)="onInput($event)"   class="editable-area"  style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;" >
                    </div>

                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarEdit>Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="fnEditarActividad( objEditarActividad.intID_Actividad )">Editar</button>
            </div>
        </div>
    </div>
</div>



<div class="row" [hidden]="!vartri">
    <div class="col-12" #headerArtsAsing>
        <h1 class="text-center">ARTICULOS ASIGNADOS {{objLeadInfo?.strNombreEmpresa}}</h1>
    </div>
    <div class="col-12" #pdfArtsPolitica>


<table  class="table table-striped table-bordered text-center align-middle">
    <thead class="table-dark">
        <tr>
            <th>#</th>
            <th>Imagen</th>
            <th>Artículo</th>
            <th>Descripción</th>
            <th>Unidad</th>
            <th>Precio</th>
            <th>Tipo</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let element of arrayArtsPolitica; let i = index">
            <td><b>{{ i + 1 }}</b></td>
            <td>
                <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArticulo + '.jpg'" 
                     alt="Imagen de {{element.strArticulo}}" class="p-2" width="100">
            </td>
            <td class="fw-bold">{{ element.strArticulo }}</td>
            <td class="fs10">{{ element.strDescripcion }}</td>
            <td class="fs12">{{ element.strUnidad }}</td>
            <td class="fs12 fw-bold">{{ element.dblPrecio | currency }}</td>
            <td class="fs12">
                <span class="fw-bold" [ngClass]="{'clsPlan': element.strTipo == 'PLANTILLA', 'clsPolitica': element.strTipo == 'POLITICA'}">
                    {{ element.strTipo }}
                </span>
            </td>
        </tr>
    </tbody>
</table>
</div>
</div>

<!--modal minuta-->
<div class="modal fade" id="modalAddMinuta" tabindex="-1" aria-labelledby="modalAddMinutaLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header bgtitModal py-2">
                <h2 class="modal-title mx-auto fw-bold mb-0 " id="modalAddMinutaLabel">Minuta</h2>
                <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"
                    #btnCerrarMinuta></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <h3 class="mb-0">
                            INFORMACIÓN MINUTA
                        </h3>
                    </div>
                </div>
                <form [formGroup]="formMinuta" (ngSubmit)="onSubmit()">
                    <div class="row mt-4 justify-content-center">
                        <!-- Título -->
                        <div class="col-10 fw-bold">
                            <span class="text-danger">*</span> Título
                        </div>
                        <div class="col-10">
                            <input type="text" class="form-control" placeholder="Ingrese nombre de la minuta"
                                formControlName="strTitulo">
                            <mat-error *ngIf="formMinuta.get('strTitulo').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                            </mat-error>
                        </div>

                        <!-- Fecha -->
                        <div class="col-10 mt-3 fw-bold">
                            <span class="text-danger">*</span> Fecha
                        </div>
                        <div class="col-10">
                            <input type="date" class="form-control" formControlName="strFecha">
                            <mat-error *ngIf="formMinuta.get('strFecha').hasError('required') "
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                            </mat-error>
                        </div>

                        <!-- Hora -->
                        <div class="col-10 mt-3 fw-bold">
                            <span class="text-danger">*</span> Hora Inicio
                        </div>
                        <div class="col-10">
                            <select class="form-select w-100" *ngIf="time1 == 'am'" formControlName="strHora"
                                aria-label="Default select example">
                                <option *ngFor="let x of arrayAm">{{ x.hora }}</option>
                            </select>
                            <!-- Error para Hora -->
                            <mat-error *ngIf="formMinuta.get('strHora').hasError('required') "
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                            </mat-error>
                        </div>
                    </div>

                    <!-- Botón de enviar -->
                    <div class="row mt-3 justify-content-center">
                        <div class="col-10">
                            <button type="submit" class="btn btn-primary w-100"
                                [disabled]="!formMinuta || formMinuta.invalid">Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary ms-auto">Guardar</button>
        </div> -->
        </div>
    </div>
</div>
<!--fin de modal minuta-->