import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';
import * as XLSX from 'xlsx';

export interface data {
  strCliente: string;
  SucursalCliente: string;
  strLinea: string;
  dblEnero: string;
  dblFebrero: string;
  dblMarzo: string;
  dblAbril: string;
  dblMayo: string;
  dblJunio: string;
  dblJulio: string;
  dblAgosto: string;
  dblSeptiembre: string;
  dblOctubre: string;
  dblNoviembre: string;
  dblDiciembre: string;
  totales: string;
}
@Component({
  selector: 'app-cliente-venta-linea',
  templateUrl: './cliente-venta-linea.component.html',
  styleUrls: ['./cliente-venta-linea.component.css']
})
export class ClienteVentaLineaComponent implements OnInit {

  intEjercicio:number=0;
  intPeriodo:number; 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['strCliente','SucursalCliente',
  'strLinea',
  'dblEnero',
  'dblFebrero',
  'dblMarzo',
  'dblAbril',
  'dblMayo',
  'dblJunio',
  'dblJulio',
  'dblAgosto',
  'dblSeptiembre',
  'dblOctubre',
  'dblNoviembre',
  'dblDiciembre',
  'totales'];
  isHoveredButton: boolean = false;
  isHoveredButton1: boolean = false;
  dataSource: MatTableDataSource<data>;
  public arrayListadoNew:any=[];
  public strConexion:string="";
  public arrayAccesos:any=[];
  public arrayTotal:any=[];
  public arrayArts:any=[];
  public arrayEjercicio:any=[];
  public arrayPeriodo:any=[];
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayArtsDistinct: any=[];
  public TotalEnero:number=0;
  public TotalFebrero:number=0;
  public TotalMarzo:number=0;
  public TotalAbril:number=0;
  public TotalMayo:number=0;
  public TotalJunio:number=0;
  public TotalJulio:number=0;
  public TotalAgosto:number=0;
  public TotalSeptiembre:number=0;
  public TotalOctubre:number=0;
  public TotalNoviembre:number=0;
  public TotalDiciembre:number=0;
  public strClienteSelect:string="";
  public arrayExistentes: any = [];
  public strIDSucursalCte:any;
  public spinerArts:boolean = false;
  public strAgente:string="";
  public activeProduct:boolean=false;
  public strAgente_Crea: string;
  public strCliente:string="";
  public strFamilia:string="";
  public strUsuario: string;
  
  public boolEditaAgente: boolean = true;
  public objCliente:any ={};
  public tagName:any;
  constructor(private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, 
      elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
      private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
        this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
        let fecha = new Date();
        this.intEjercicio = fecha.getFullYear();
        this.intPeriodo = fecha.getMonth() + 1;
        this.strConexion = this.localSt.retrieve('strConexion');
        this.tagName = elem.nativeElement.tagName.toLowerCase();
       
     }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        if(objAcceso&& objAcceso[0] && objAcceso[0].strComponente == this.tagName){
          this.objCliente = this.arrayAccesos[0];  
          this.strFamilia = this.objCliente.strFamiliaCte;
          this.strCliente = this.objCliente.strCliente;
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgente_Crea = this.localSt.retrieve('strAgente');
          this.strAgente = this.localSt.retrieve('strAgente');
          this.strUsuario = this.localSt.retrieve('strUsuario');
          this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
           this.getInfoLineas()

          this.fnGetFiltros();
        }else{
          let urlHome = this.arrayAccesos[0].strUrlHome;
          this.router.navigate(['/'+urlHome]);
        }
      }
      else {
        this.router.navigate(['/login']);
      }
    });
  }
  fntabla(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  }

  fnFiltrarPorAcceso(){

  }

  getInfoLineas() {
    this.spinerArts = true;
    this.arrayTotal = []

    this.arrayListadoNew = [];
    this.arrayArtsDistinct =[];
    let objEnv = {}
    if (this.strFamilia==''){
      objEnv = {
        'strEjercicio': this.intEjercicio,
        strPeriodo:this.intPeriodo,
        'strFamilia':'',
        'strCliente':this.strCliente,
        strConexion:this.strConexion
      }; 
    }else{
      objEnv = {
        'strEjercicio': this.intEjercicio, 
        strPeriodo:this.intPeriodo,
        'strFamilia':this.strFamilia,
        'strCliente':'',
        strConexion:this.strConexion}; 
    }
    this._httpService.getClienteDatos(objEnv, 'getReporteQuantumLineas2').subscribe(
        result => {
       
          this.arrayTotal = result;
        this.fnCalcularArrays();
        this.spinerArts = false;
        }, error => {
          var error = <any>error; console.log(error);
          this.spinerArts = false;
        });
  }

  fnCalcularArrays(){
   
    this.arrayArtsDistinct  = this.arrayTotal.reduce((prev, current) => 
      prev.some(x => x.strIDSucursalCte === current.strIDSucursalCte)? prev: [...prev, current ], [])
    let arrayT = JSON.parse(JSON.stringify(this.arrayTotal));
  this.TotalEnero=0;
  this.TotalFebrero=0;
  this.TotalMarzo=0;
  this.TotalAbril=0;
  this.TotalMayo=0;
  this.TotalJunio=0;
  this.TotalJulio=0;
  this.TotalAgosto=0;
  this.TotalSeptiembre=0;
  this.TotalOctubre=0;
  this.TotalNoviembre=0;
  this.TotalDiciembre=0;
    this.arrayArtsDistinct.forEach(suc => {
      let item:any= {};
      this.strCliente = suc.strCliente; 
      this.strIDSucursalCte = suc.strIDSucursalCte;

      item.strCliente = suc.strCliente;
      item.strClienteNombreCorto = suc.strClienteNombreCorto;
      item.strIDSucursalCte = suc.strIDSucursalCte;
      item.strNombreSucursalCte = suc.strNombreSucursalCte;

      item.dblEneroaccesorios = 0;
      item.dblFebreroaccesorios = 0;      
      item.dblMarzoaccesorios = 0;
      item.dblAbrilaccesorios = 0;
      item.dblMayoaccesorios = 0;
      item.dblJunioaccesorios = 0;
      item.dblJulioaccesorios = 0;
      item.dblAgostoaccesorios = 0;
      item.dblSeptiembreaccesorios = 0;
      item.dblOctubreaccesorios = 0;
      item.dblNoviembreaccesorios = 0;
      item.dblDiciembreaccesorios = 0;
      item.dblTotalaccesorios = 0;


      item.dblEnerodegrapack = 0;
      item.dblFebrerodegrapack = 0;
      item.dblMarzodegrapack = 0;
      item.dblAbrildegrapack = 0;
      item.dblMayodegrapack = 0;
      item.dblJuniodegrapack = 0;
      item.dblJuliodegrapack = 0;
      item.dblAgostodegrapack = 0;
      item.dblSeptiembredegrapack = 0;
      item.dblOctubredegrapack = 0;
      item.dblNoviembredegrapack = 0;
      item.dblDiciembredegrapack = 0;
      item.dblTotaldegrapack = 0;

      item.dblEnerokimberly = 0;
      item.dblFebrerokimberly = 0;
      item.dblMarzokimberly = 0;
      item.dblAbrilkimberly = 0;
      item.dblMayokimberly = 0;
      item.dblJuniokimberly = 0;
      item.dblJuliokimberly = 0;
      item.dblAgostokimberly = 0;
      item.dblSeptiembrekimberly = 0;
      item.dblOctubrekimberly = 0;
      item.dblNoviembrekimberly = 0;
      item.dblDiciembrekimberly = 0;
      item.dblTotalkimberly = 0;

      item.dblEneroquimicos = 0;
      item.dblFebreroquimicos = 0;
      item.dblMarzoquimicos = 0;
      item.dblAbrilquimicos = 0;
      item.dblMayoquimicos = 0;
      item.dblJunioquimicos = 0;
      item.dblJulioquimicos = 0;
      item.dblAgostoquimicos = 0;
      item.dblSeptiembrequimicos = 0;
      item.dblOctubrequimicos = 0;
      item.dblNoviembrequimicos = 0;
      item.dblDiciembrequimicos = 0;
      item.dblTotalquimicos = 0;

      item.dblEnerorubbermaid = 0;
      item.dblFebrerorubbermaid = 0;
      item.dblMarzorubbermaid = 0;
      item.dblAbrilrubbermaid = 0;
      item.dblMayorubbermaid = 0;
      item.dblJuniorubbermaid = 0;
      item.dblJuliorubbermaid = 0;
      item.dblAgostorubbermaid = 0;
      item.dblSeptiembrerubbermaid = 0;
      item.dblOctubrerubbermaid = 0;
      item.dblNoviembrerubbermaid = 0;
      item.dblDiciembrerubbermaid = 0;
      item.dblTotalrubbermaid = 0;

      item.dblEnerotork = 0;
      item.dblFebrerotork = 0;
      item.dblMarzotork = 0;
      item.dblAbriltork = 0;
      item.dblMayotork = 0;
      item.dblJuniotork = 0;
      item.dblJuliotork = 0;
      item.dblAgostotork = 0;
      item.dblSeptiembretork = 0;
      item.dblOctubretork = 0;
      item.dblNoviembretork = 0;
      item.dblDiciembretork = 0;
      item.dblTotaltork = 0;

      item.dblEnerobluetissue = 0;
      item.dblFebrerobluetissue = 0;
      item.dblMarzobluetissue = 0;
      item.dblAbrilbluetissue = 0;
      item.dblMayobluetissue = 0;
      item.dblJuniobluetissue = 0;
      item.dblJuliobluetissue = 0;
      item.dblAgostobluetissue = 0;
      item.dblSeptiembrebluetissue = 0;
      item.dblOctubrebluetissue = 0;
      item.dblNoviembrebluetissue = 0;
      item.dblDiciembrebluetissue = 0;
      item.dblTotalbluetissue = 0;

      arrayT.forEach(element => {
        if (item.strIDSucursalCte == element.strIDSucursalCte){
          if (element.strPeriodo == '1') {
            item.dblEneroaccesorios = element.accesorios ?element.accesorios :0;
            item.dblEnerodegrapack = element.degrapack ?element.degrapack:0;
            item.dblEnerokimberly = element.kimberly?element.kimberly:0;
            item.dblEneroquimicos = element.quimicos?element.quimicos:0;
            item.dblEnerorubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblEnerotork = element.tork?element.tork:0;
            item.dblEnerobluetissue = element.bluetissue?element.bluetissue:0;

            
          } else if (element.strPeriodo == '2') {
            item.dblFebreroaccesorios = element.accesorios ?element.accesorios :0;
            item.dblFebrerodegrapack = element.degrapack ?element.degrapack:0;
            item.dblFebrerokimberly = element.kimberly?element.kimberly:0;
            item.dblFebreroquimicos = element.quimicos?element.quimicos:0;
            item.dblFebrerorubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblFebrerotork = element.tork?element.tork:0;
            item.dblFebrerobluetissue = element.bluetissue?element.bluetissue:0;

          } else if (element.strPeriodo == '3') {
            item.dblMarzoaccesorios = element.accesorios ?element.accesorios :0;
            item.dblMarzodegrapack = element.degrapack ?element.degrapack:0;
            item.dblMarzokimberly = element.kimberly?element.kimberly:0;
            item.dblMarzoquimicos = element.quimicos?element.quimicos:0;
            item.dblMarzorubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblMarzotork = element.tork?element.tork:0;
            item.dblMarzobluetissue = element.bluetissue?element.bluetissue:0;

          } else if (element.strPeriodo == '4') {
            item.dblAbrilaccesorios = element.accesorios ?element.accesorios :0;
            item.dblAbrildegrapack = element.degrapack ?element.degrapack:0;
            item.dblAbrilkimberly = element.kimberly?element.kimberly:0;
            item.dblAbrilquimicos = element.quimicos?element.quimicos:0;
            item.dblAbrilrubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblAbriltork = element.tork?element.tork:0;
            item.dblAbrilbluetissue = element.bluetissue?element.bluetissue:0;

          } else if (element.strPeriodo == '5') {
            item.dblMayoaccesorios = element.accesorios ?element.accesorios :0;
            item.dblMayodegrapack = element.degrapack ?element.degrapack:0;
            item.dblMayokimberly = element.kimberly?element.kimberly:0;
            item.dblMayoquimicos = element.quimicos?element.quimicos:0;
            item.dblMayorubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblMayotork = element.tork?element.tork:0;
            item.dblMayobluetissue = element.bluetissue?element.bluetissue:0;
            
          } else if (element.strPeriodo == '6') {
            item.dblJunioaccesorios = element.accesorios ?element.accesorios :0;
            item.dblJuniodegrapack = element.degrapack ?element.degrapack:0;
            item.dblJuniokimberly = element.kimberly?element.kimberly:0;
            item.dblJunioquimicos = element.quimicos?element.quimicos:0;
            item.dblJuniorubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblJuniotork = element.tork?element.tork:0;
            item.dblJuniobluetissue = element.bluetissue?element.bluetissue:0;

          } else if (element.strPeriodo == '7') {
            item.dblJulioaccesorios = element.accesorios ?element.accesorios :0;
            item.dblJuliodegrapack = element.degrapack ?element.degrapack:0;
            item.dblJuliokimberly = element.kimberly?element.kimberly:0;
            item.dblJulioquimicos = element.quimicos?element.quimicos:0;
            item.dblJuliorubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblJuliotork = element.tork?element.tork:0;
            item.dblJuliobluetissue = element.bluetissue?element.bluetissue:0;
          } else if (element.strPeriodo == '8') {
            item.dblAgostoaccesorios = element.accesorios ?element.accesorios :0;
            item.dblAgostodegrapack = element.degrapack ?element.degrapack:0;
            item.dblAgostokimberly = element.kimberly?element.kimberly:0;
            item.dblAgostoquimicos = element.quimicos?element.quimicos:0;
            item.dblAgostorubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblAgostotork = element.tork?element.tork:0;
            item.dblAgostobluetissue = element.bluetissue?element.bluetissue:0;
          } else if (element.strPeriodo == '9') {
            item.dblSeptiembreaccesorios = element.accesorios ?element.accesorios :0;
            item.dblSeptiembredegrapack = element.degrapack ?element.degrapack:0;
            item.dblSeptiembrekimberly = element.kimberly?element.kimberly:0;
            item.dblSeptiembrequimicos = element.quimicos?element.quimicos:0;
            item.dblSeptiembrerubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblSeptiembretork = element.tork?element.tork:0;
            item.dblSeptiembrebluetissue = element.bluetissue?element.bluetissue:0;
          } else if (element.strPeriodo == '10') {
            item.dblOctubreaccesorios = element.accesorios ?element.accesorios :0;
            item.dblOctubredegrapack = element.degrapack ?element.degrapack:0;
            item.dblOctubrekimberly = element.kimberly?element.kimberly:0;
            item.dblOctubrequimicos = element.quimicos?element.quimicos:0;
            item.dblOctubrerubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblOctubretork = element.tork?element.tork:0;
            item.dblOctubrebluetissue = element.bluetissue?element.bluetissue:0;
          } else if (element.strPeriodo == '11') {
            item.dblNoviembreaccesorios = element.accesorios ?element.accesorios :0;
            item.dblNoviembredegrapack = element.degrapack ?element.degrapack:0;
            item.dblNoviembrekimberly = element.kimberly?element.kimberly:0;
            item.dblNoviembrequimicos = element.quimicos?element.quimicos:0;
            item.dblNoviembrerubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblNoviembretork = element.tork?element.tork:0;
            item.dblNoviembrebluetissue = element.bluetissue?element.bluetissue:0;
          } else if (element.strPeriodo == '12') {
            item.dblDiciembreaccesorios = element.accesorios ?element.accesorios :0;
            item.dblDiciembredegrapack = element.degrapack ?element.degrapack:0;
            item.dblDiciembrekimberly = element.kimberly?element.kimberly:0;
            item.dblDiciembrequimicos = element.quimicos?element.quimicos:0;
            item.dblDiciembrerubbermaid = element.rubbermaid?element.rubbermaid:0;
            item.dblDiciembretork = element.tork?element.tork:0;
            item.dblDiciembrebluetissue = element.bluetissue?element.bluetissue:0;
          }
        }
      });
      item.dblTotalaccesorios = parseFloat(item.dblEneroaccesorios) + parseFloat(item.dblFebreroaccesorios) + parseFloat(item.dblMarzoaccesorios) +
      parseFloat(item.dblAbrilaccesorios) + parseFloat(item.dblMayoaccesorios) + parseFloat(item.dblJunioaccesorios)+parseFloat(item.dblAgostoaccesorios) + parseFloat(item.dblSeptiembreaccesorios) 
      + parseFloat(item.dblOctubreaccesorios)  + parseFloat(item.dblNoviembreaccesorios)  + parseFloat(item.dblDiciembreaccesorios);
      
      item.dblTotaldegrapack = parseFloat(item.dblEnerodegrapack) + parseFloat(item.dblFebrerodegrapack) + parseFloat(item.dblMarzodegrapack) +
      parseFloat(item.dblAbrildegrapack) + parseFloat(item.dblMayodegrapack) + parseFloat(item.dblJuniodegrapack) + parseFloat(item.dblJuliodegrapack) +
      parseFloat(item.dblAgostodegrapack) + parseFloat(item.dblSeptiembredegrapack) + parseFloat(item.dblOctubredegrapack) +
      parseFloat(item.dblNoviembredegrapack) + parseFloat(item.dblDiciembredegrapack);

item.dblTotalkimberly = parseFloat(item.dblEnerokimberly) + parseFloat(item.dblFebrerokimberly) + parseFloat(item.dblMarzokimberly) +
      parseFloat(item.dblAbrilkimberly) + parseFloat(item.dblMayokimberly) + parseFloat(item.dblJuniokimberly) + parseFloat(item.dblJuliokimberly) +
      parseFloat(item.dblAgostokimberly) + parseFloat(item.dblSeptiembrekimberly) + parseFloat(item.dblOctubrekimberly) +
      parseFloat(item.dblNoviembrekimberly) + parseFloat(item.dblDiciembrekimberly);

item.dblTotalquimicos = parseFloat(item.dblEneroquimicos) + parseFloat(item.dblFebreroquimicos) + parseFloat(item.dblMarzoquimicos) +
      parseFloat(item.dblAbrilquimicos) + parseFloat(item.dblMayoquimicos) + parseFloat(item.dblJunioquimicos) + parseFloat(item.dblJulioquimicos) +
      parseFloat(item.dblAgostoquimicos) + parseFloat(item.dblSeptiembrequimicos) + parseFloat(item.dblOctubrequimicos) +
      parseFloat(item.dblNoviembrequimicos) + parseFloat(item.dblDiciembrequimicos);

item.dblTotalrubbermaid = parseFloat(item.dblEnerorubbermaid) + parseFloat(item.dblFebrerorubbermaid) + parseFloat(item.dblMarzorubbermaid) +
      parseFloat(item.dblAbrilrubbermaid) + parseFloat(item.dblMayorubbermaid) + parseFloat(item.dblJuniorubbermaid) + parseFloat(item.dblJuliorubbermaid) +
      parseFloat(item.dblAgostorubbermaid) + parseFloat(item.dblSeptiembrerubbermaid) + parseFloat(item.dblOctubrerubbermaid) +
      parseFloat(item.dblNoviembrerubbermaid) + parseFloat(item.dblDiciembrerubbermaid);

item.dblTotaltork = parseFloat(item.dblEnerotork) + parseFloat(item.dblFebrerotork) + parseFloat(item.dblMarzotork) +
      parseFloat(item.dblAbriltork) + parseFloat(item.dblMayotork) + parseFloat(item.dblJuniotork) + parseFloat(item.dblJuliotork) +
      parseFloat(item.dblAgostotork) + parseFloat(item.dblSeptiembretork) + parseFloat(item.dblOctubretork) +
      parseFloat(item.dblNoviembretork) + parseFloat(item.dblDiciembretork);

item.dblTotalbluetissue = parseFloat(item.dblEnerobluetissue) + parseFloat(item.dblFebrerobluetissue) + parseFloat(item.dblMarzobluetissue) +
      parseFloat(item.dblAbrilbluetissue) + parseFloat(item.dblMayobluetissue) + parseFloat(item.dblJuniobluetissue) + parseFloat(item.dblJuliobluetissue) +
      parseFloat(item.dblAgostobluetissue) + parseFloat(item.dblSeptiembrebluetissue) + parseFloat(item.dblOctubrebluetissue) +
      parseFloat(item.dblNoviembrebluetissue) + parseFloat(item.dblDiciembrebluetissue);
      
      this.TotalEnero=this.TotalEnero + parseFloat(item.dblEneroaccesorios) + parseFloat(item.dblEnerodegrapack)+parseFloat(item.dblEnerokimberly)+ 
      parseFloat(item.dblEneroquimicos)+
      parseFloat(item.dblEnerorubbermaid)+
      parseFloat(item.dblEnerotork)+
      parseFloat(item.dblEnerobluetissue);

      this.TotalFebrero = this.TotalFebrero + parseFloat(item.dblFebreroaccesorios) + parseFloat(item.dblFebrerodegrapack) + parseFloat(item.dblFebrerokimberly) +
    parseFloat(item.dblFebreroquimicos) + parseFloat(item.dblFebrerorubbermaid) + parseFloat(item.dblFebrerotork) + parseFloat(item.dblFebrerobluetissue);

this.TotalMarzo = this.TotalMarzo + parseFloat(item.dblMarzoaccesorios) + parseFloat(item.dblMarzodegrapack) + parseFloat(item.dblMarzokimberly) +
    parseFloat(item.dblMarzoquimicos) + parseFloat(item.dblMarzorubbermaid) + parseFloat(item.dblMarzotork) + parseFloat(item.dblMarzobluetissue);

this.TotalAbril = this.TotalAbril + parseFloat(item.dblAbrilaccesorios) + parseFloat(item.dblAbrildegrapack) + parseFloat(item.dblAbrilkimberly) +
    parseFloat(item.dblAbrilquimicos) + parseFloat(item.dblAbrilrubbermaid) + parseFloat(item.dblAbriltork) + parseFloat(item.dblAbrilbluetissue);

this.TotalMayo = this.TotalMayo + parseFloat(item.dblMayoaccesorios) + parseFloat(item.dblMayodegrapack) + parseFloat(item.dblMayokimberly) +
    parseFloat(item.dblMayoquimicos) + parseFloat(item.dblMayorubbermaid) + parseFloat(item.dblMayotork) + parseFloat(item.dblMayobluetissue);

this.TotalJunio = this.TotalJunio + parseFloat(item.dblJunioaccesorios) + parseFloat(item.dblJuniodegrapack) + parseFloat(item.dblJuniokimberly) +
    parseFloat(item.dblJunioquimicos) + parseFloat(item.dblJuniorubbermaid) + parseFloat(item.dblJuniotork) + parseFloat(item.dblJuniobluetissue);

this.TotalJulio = this.TotalJulio + parseFloat(item.dblJulioaccesorios) + parseFloat(item.dblJuliodegrapack) + parseFloat(item.dblJuliokimberly) +
    parseFloat(item.dblJulioquimicos) + parseFloat(item.dblJuliorubbermaid) + parseFloat(item.dblJuliotork) + parseFloat(item.dblJuliobluetissue);

this.TotalAgosto = this.TotalAgosto + parseFloat(item.dblAgostoaccesorios) + parseFloat(item.dblAgostodegrapack) + parseFloat(item.dblAgostokimberly) +
    parseFloat(item.dblAgostoquimicos) + parseFloat(item.dblAgostorubbermaid) + parseFloat(item.dblAgostotork) + parseFloat(item.dblAgostobluetissue);

this.TotalSeptiembre = this.TotalSeptiembre + parseFloat(item.dblSeptiembreaccesorios) + parseFloat(item.dblSeptiembredegrapack) + parseFloat(item.dblSeptiembrekimberly) +
    parseFloat(item.dblSeptiembrequimicos) + parseFloat(item.dblSeptiembrerubbermaid) + parseFloat(item.dblSeptiembretork) + parseFloat(item.dblSeptiembrebluetissue);

this.TotalOctubre = this.TotalOctubre + parseFloat(item.dblOctubreaccesorios) + parseFloat(item.dblOctubredegrapack) + parseFloat(item.dblOctubrekimberly) +
    parseFloat(item.dblOctubrequimicos) + parseFloat(item.dblOctubrerubbermaid) + parseFloat(item.dblOctubretork) + parseFloat(item.dblOctubrebluetissue);

this.TotalNoviembre = this.TotalNoviembre + parseFloat(item.dblNoviembreaccesorios) + parseFloat(item.dblNoviembredegrapack) + parseFloat(item.dblNoviembrekimberly) +
    parseFloat(item.dblNoviembrequimicos) + parseFloat(item.dblNoviembrerubbermaid) + parseFloat(item.dblNoviembretork) + parseFloat(item.dblNoviembrebluetissue);

this.TotalDiciembre = this.TotalDiciembre + parseFloat(item.dblDiciembreaccesorios) + parseFloat(item.dblDiciembredegrapack) + parseFloat(item.dblDiciembrekimberly) +
    parseFloat(item.dblDiciembrequimicos) + parseFloat(item.dblDiciembrerubbermaid) + parseFloat(item.dblDiciembretork) + parseFloat(item.dblDiciembrebluetissue);
      this.arrayListadoNew.push(item);
    });
    this.dataSource = new MatTableDataSource(this.arrayListadoNew);
    this.fntabla();

  }

  getTotalGeneral() {
    return this.TotalEnero + this.TotalFebrero + this.TotalMarzo + this.TotalAbril + this.TotalMayo +
      this.TotalJunio + this.TotalJulio + this.TotalAgosto + this.TotalSeptiembre + this.TotalOctubre +
      this.TotalNoviembre + this.TotalDiciembre;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

    exportToExcel_hist1(): void {
          // Obtén el elemento de la tabla por su id
          const element = document.getElementById('tableHist_1'); // Asegúrate de que este ID coincide con el de tu tabla HTML
          const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
          
          // Crea un libro de trabajo
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          
          // Agrega la hoja al libro de trabajo
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          
          // Descarga el archivo Excel con el nombre que elijas
          XLSX.writeFile(wb, 'venta_articulo.xlsx');
        }
}
