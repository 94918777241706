import { Component, ElementRef, OnInit } from '@angular/core';
import { DataDBService } from '../servicios/data-db.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage'; 
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-partner-error-compra',
  templateUrl: './partner-error-compra.component.html',
  styleUrls: ['./partner-error-compra.component.css']
})
export class PartnerErrorCompraComponent implements OnInit {
  public tagName:string;
  public arrayAccesos:any=[];
  public objEnvioPartner:any ={};
  public strTipo_envio_partner:string='';
  public strTitulo_envio:string='';
  public strCteenviar:string;
  public strNombre_envio_partner:string='';
  public strConexion:string= ""
  public strAgenteLS:string;
  public strUsuario:string;
  public strSSN:string;
 
  public strAgente_Crea:string;
  private idEncriptado:string;
  private id:string
  private secretKey ='ece1122';
  public errorPago:any;
  private public_key:string='TEST-2e4c3c6b-f817-4fac-a957-aefb90e4848c';
  constructor(private route: ActivatedRoute,public localSt: LocalStorageService,private router: Router,elem: ElementRef,private indexedDBService: DataDBService) { 
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.indexedDBService.getItem('arrayResult', (value) => {
    this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) { 
          this.objEnvioPartner=this.arrayAccesos[0].detalle_envio_partner?this.arrayAccesos[0].detalle_envio_partner:{}
          this.strTipo_envio_partner=this.arrayAccesos[0].strTipo_envio_partner?this.arrayAccesos[0].strTipo_envio_partner:'PICKUP';
          this.strNombre_envio_partner = this.arrayAccesos[0].nombre_envioPartner ?  this.arrayAccesos[0].nombre_envioPartner:'';
          this.strTitulo_envio = this.strTipo_envio_partner == 'PICKUP' ? 'Pick Up'  :'Envío Estándar';
          this.strCteenviar =  this.objEnvioPartner.intId_Direccion ?  this.objEnvioPartner.intId_Direccion:null;
        

       // this.fnGetInfo(this.objPartner.strCliente);
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        if (this.arrayAccesos.length == 0) {
          this.router.navigate(['/login']);
        } else {
          let objData = this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
          //this._httpService.setMostrarMenu(true);
          // this.localSt.retrieve('strAgente'); //this.boolAgente ? '' : 
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgenteLS = objData.strAgente ; //this.localSt.retrieve('strAgente');
          this.strAgente_Crea = objData.strAgente; // this.localSt.retrieve('strAgente');
          this.strUsuario = objData.strUsuario;
          this.strSSN =  objData.strSSN;
          //------------conexi+on-------------------
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
        }
      } else {
        this.router.navigate(['/login']);
      }
    });  
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.idEncriptado = params['id'];
      if (this.idEncriptado) {
        this.id = this.desencriptarParametro(this.idEncriptado);
      }
      
    });
  }

   desencriptarParametro(idEncriptado: string): string {
     const bytes = CryptoJS.AES.decrypt(idEncriptado, this.secretKey);
     return bytes.toString(CryptoJS.enc.Utf8);
   }
 
   fnIntentarDeNuevo(){
    console.log(this.id)
       this.router.navigate(['/partner-pasarela'], {
         queryParams: { tipo:"error",id: this.idEncriptado},
       });
   }
 
   fnSeguirComprando(){
     this.router.navigate(['/cotizador_Linea'])
   }
}
