<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="text-decoration-underline mb-0 text-center py-3 fw-bold">Comodatos Por Cliente</h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetComodatos()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-12 py-3 bgtable  bordertop">
            <mat-form-field appearance="outline" class="bg-white rounded shadow-sm">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Comodato,Mov, cliente, etc.." class="text-dark" #input>
            </mat-form-field>
        </div>
        <div class="col-12 bgtable px-0">
            <div class="mat-elevation-z8 ">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef  class="ps-0"> No. </th>
                        <td mat-cell *matCellDef="let element" class="ps-0"> {{element.position}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="weight">
                        <th mat-header-cell *matHeaderCellDef> Weight </th>
                        <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="symbol">
                        <th mat-header-cell *matHeaderCellDef class="pe-0"> Symbol </th>
                        <td mat-cell *matCellDef="let element" class="pe-0"> {{element.symbol}} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bgtable"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: <span class="fw-bold"> "{{input.value}}"</span></td>
                    </tr>
                </table>
                <mat-paginator class="bgtable borderbott" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
                </mat-paginator>
            </div>
        </div>
    </div>
 
    
</div>