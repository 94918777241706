import { Component, OnInit,AfterViewInit, ViewChild,  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';
import {NewmenuComponent} from '../newmenu/newmenu.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DataDBService } from '../servicios/data-db.service';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
import * as CryptoJS from 'crypto-js';

declare var $: any; // Declarar jQuery
@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.css'],
  animations: [
    trigger('menuAnimation', [
      state('hidden', style({
        transform: 'perspective(600px) rotateY(90deg)', // Empieza doblada
        opacity: 0,
        display: 'none'
      })),
      state('visible', style({
        transform: 'perspective(600px) rotateY(0)', // Plana cuando es visible
        opacity: 1,
        display: 'block'
      })),
      transition('hidden => visible', [
        animate('0.5s ease-out')
      ]),
      transition('visible => hidden', [
        animate('0.5s ease-in') 
      ]),
    ]
   )
  ]
})
export class EcommerceComponent implements  OnInit, AfterViewInit  { 
  arrayProductosDestacados:any = [];
  arrayCategorias:any = [];
  arrayNovedades:any = [];
  public piePagina:string="info@tu-tienda.com"
  public strConexion:string = "COMERCIAL"
  public hovered:boolean=false;
  public arrayAccesos: any;
  mostrarElementos = true;
  public arrayLiquidacion:any =[];
  public cartItems:any =[];
  private secretKey: string = 'ec0d3li2o2e';
  public subTotalCar:number = 0;
  private user:any;
constructor(private cartService: EcommerceCarrService,private newMenu:NewmenuComponent,private indexedDBService: DataDBService,public _alertify: AlertifyService, private _filtroServ: FiltrosService, public router: Router, public route: ActivatedRoute, public _serviciosService: PeticionesService, public localSt: LocalStorageService){
  this.arrayAccesos = this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[{strOrigen:'TIENDA'  }]; 
  this.user =this.localSt.retrieve('strusuario');
  if(this.user != "" && this.user != null && this.user != undefined ){
    this.router.navigate(['/strUrlHome']);
  }
}

  ngOnInit(): void {
    
    this.fnExtaerNuevos();
    this.fnExtaerDestacados();
    this.fnExtaerCategorias();
    this.cartService.getCartItems().subscribe((items) => {
      this.cartItems = items;
      if(this.cartItems.length > 0){
        this.subTotalCar = 0
        this.cartItems.forEach(item => {
         this.subTotalCar =  this.subTotalCar + item.intPrecio 
        });
      }
      /* console.log(this.cartItems) */
    })
  }
  ngAfterViewInit() {
    $('#carouselExample').carousel({
      interval: 2000 // Cambia de imagen cada 2 segundos (ajustable)
    });
       // Aquí ya puedes acceder a this.newMenu de manera segura
   
       if (this.arrayAccesos && this.arrayAccesos.length > 0) {
      
        this.newMenu.fnCerrarSesion();
        this.newMenu.fnquitarFamilia();
        this.router.navigate(['/tienda']);
      }
  }

  toggleElementos() {
    this.mostrarElementos = !this.mostrarElementos;
  }
  fnExtaerNuevos() {
    let objenv = {
        strAccion: 'getArtsNuevos',
        strConexion: this.strConexion
      }
      this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
        result => {
          this.arrayNovedades = result
          if(this.arrayNovedades.length>0){
            this.arrayNovedades.forEach(item => {
              item.hovered = false;
              item.oferta= Math.ceil( 100-((item.dblPrecioOferta/item.dblPrecioLista)*100))
            });
            this.arrayNovedades.sort((a, b) => a.dblCantidad - b.dblCantidad);
          }
          
      
         },
        error => {
          var error = <any>error;
        }
      );
    }
    fnExtaerDestacados() {
      let objenv = {
          strAccion: 'getArtsDestacados',
          strConexion: this.strConexion
        }
        this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
          result => {
            this.arrayProductosDestacados = result
           if(this.arrayProductosDestacados.length>0){
              this.arrayProductosDestacados.forEach(item => {
                item.hovered = false;
                item.oferta= Math.ceil( 100-((item.dblPrecioOferta/item.dblPrecioLista)*100))
              });
              this.arrayProductosDestacados.sort((a, b) => a.dblCantidad - b.dblCantidad);
           }
           this.arrayProductosDestacados.forEach( dest => {
              if(dest.strTipo!=""){
                dest.intDescuento =  (((dest.dblPrecioLista - dest.dblPrecioOferta) /dest.dblPrecioLista)* 100)
                this.arrayLiquidacion.push(dest)
              }
            });
            this.arrayLiquidacion.sort((a, b) => a.dblCantidad - b.dblCantidad);
           },
          error => {
            var error = <any>error;
          }
        );
      }
      fnExtaerCategorias() {
        let objenv = {
            strAccion: 'getCategorias',
            strConexion: this.strConexion
          }
          this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
            result => {
              this.arrayCategorias = result
              this.arrayCategorias.forEach(cat => {
                cat.seeHov = false;
                cat.imagen = cat.strCategoria =="ALMACENAMIENTO Y ORGANIZACION" ?"./assets/ecommerce/categorias/ALMACENAMIENTO Y ORGANIZACION.jpg":
                cat.strCategoria =="AMENIDADES"?"./assets/ecommerce/categorias/AMENIDADES.jpg":
                cat.strCategoria =="ARTICULOS DE LIMPIEZA"?"./assets/ecommerce/categorias/ARTICULOS DE LIMPIEZA.jpg":
                cat.strCategoria =="BOLSA PARA BASURA"?"./assets/ecommerce/categorias/BOLSA PARA BASURA.jpg":
                cat.strCategoria =="BOTES Y CONTENEDORES"?"./assets/ecommerce/categorias/BOTES Y CONTENEDORES.jpg":
                cat.strCategoria =="CARROS UTILITARIOS"?"./assets/ecommerce/categorias/CARROS UTILITARIOS.jpg":
                cat.strCategoria =="COMPLEMENTOS PARA BAÑO"?"./assets/ecommerce/categorias/COMPLEMENTOS PARA BANO.jpg":
                cat.strCategoria =="CONTROL DE AROMAS"?"./assets/ecommerce/categorias/CONTROL DE AROMAS.jpg":
                cat.strCategoria =="DESECHABLES Y BIODEGRADABLES"?"./assets/ecommerce/categorias/DESECHABLES Y BIODEGRADABLES.jpg":
                cat.strCategoria =="EQUIPO DE PROTECCION"?"./assets/ecommerce/categorias/EQUIPO DE PROTECCION.jpg":
                cat.strCategoria =="EQUIPOS Y MAQUINAS DE LIMPIEZA INDUSTRIAL"?"./assets/ecommerce/categorias/EQUIPOS Y MAQUINAS DE LIMPIEZA INDUSTRIAL.jpg":
                cat.strCategoria =="HIGIENE Y CUIDADO PARA MANOS"?"./assets/ecommerce/categorias/HIGIENE Y CUIDADO PARA MANOS.jpg":
                cat.strCategoria =="MICROFIBRA"?"./assets/ecommerce/categorias/MICROFIBRA.jpg":
                cat.strCategoria =="PAPEL Y DESPACHADORES"?"./assets/ecommerce/categorias/PAPEL Y DESPACHADORES.jpg":
                cat.strCategoria =="QUIMICOS PARA LIMPIEZA"?"./assets/ecommerce/categorias/QUIMICOS PARA LIMPIEZA.jpg":
                cat.strCategoria =="QUIMICOS PARA MANTENIMIENTO"?"./assets/ecommerce/categorias/QUIMICOS PARA MANTENIMIENTO.jpg":
                cat.strCategoria =="TAPETES"?"./assets/ecommerce/categorias/TAPETES.jpg":
                cat.strCategoria =="UTENSILIOS DE COCINA"?"./assets/ecommerce/categorias/UTENSILIOS DE COCINA.jpg":
                cat.strCategoria =="WIPERS Y TOALLAS HUMEDAS"?"./assets/ecommerce/categorias/WIPERS Y TOALLAS HUMEDAS.jpg":"./assets/ecommerce/categorias/NO_DISPONIBLE.jpg"
              });
             },
            error => {
              var error = <any>error;
            }
          );
        }
      fnIrDetalle(obj:any){
        // Componente de origen
        this.router.navigate(['/tienda-art', obj]);
      }

      fnIrSubLinea(obj:any){
        // Componente de origen
        this.router.navigate(['/tienda-categoria'], { 
          queryParams: { strCategoria: obj.strCategoria }
      });
      /*this.router.navigate(['/tienda-categoria', {strCategoria:obj.strCategoria}]); */
      }
  

      addToCart(obj:any)
      {
        const objString = JSON.stringify(obj); // Convertir el objeto a string
        const encryptedData = this.encryptData(objString);
        this.router.navigate(['/tienda-art'], { 
          queryParams: { data: encryptedData } // Enviar el string como parámetro
        });
      }

      onImageError(event: any) {
        // Si hay un error al cargar la imagen, usamos una imagen por defecto
        event.target.src = 'assets/Fondos/image.png';
      }
      
      fnNav(nav:string){
        this.router.navigate(['/tiendaproducto'], { 
          queryParams: { strTipo: nav }
      });
      }
        encryptData(data: any): string {
          try {
            const stringifiedData = JSON.stringify(data); // Convertir objeto a string
            const encrypted = CryptoJS.AES.encrypt(stringifiedData, this.secretKey).toString();
            return encodeURIComponent(encrypted); // Codificar para URL
          } catch (error) {
            console.error('Error al encriptar datos:', error);
            return '';
          }
        }
}
 