<div class="benefits-container">
    <!-- Gráfica de los niveles de descuento -->
    <div class="levels-chart">
      <h2>Descuentos por Niveles</h2>
      <div class="bar-container">
        <div class="bar" style="height: 5%; background-color: #FF6347;">
          <span class="bar-label">Nivel 1: 5%</span>
        </div>
        <div class="bar" style="height: 6%; background-color: #FF4500;">
          <span class="bar-label">Nivel 2: 6%</span>
        </div>
        <div class="bar" style="height: 7%; background-color: #32CD32;">
          <span class="bar-label">Nivel 3: 7%</span>
        </div>
        <div class="bar" style="height: 10%; background-color: #1E90FF;">
          <span class="bar-label">Nivel 4: 10%</span>
        </div>
      </div>
    </div>
  
    <!-- Barra de progreso -->
    <div class="progress-container">
      <h2>Progreso hacia el siguiente nivel</h2>
      <div class="progress-bar-background">
        <div class="progress-bar" [style.width.%]="progressBarValue"></div>
      </div>
      <p class="progress-text">Avance: {{ progressBarValue }}% - Necesitas {{ nextLevelProgress - progressBarValue }}% más para alcanzar el siguiente nivel.</p>
    </div>
  </div>

  