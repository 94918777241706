<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <div class="product-line-container">
        <h2>Línea de Productos</h2>
        <div class="category-cards-container">
          <div *ngFor="let category of categories" id="{{category.strCategoria}}" #category.strCategoria class="category-card" (click)="onCardClick(category.strCategoria )">
            <img [src]="category.imageUrl" alt="{{ category.strCategoria }}">
            <h4>{{ category.strCategoria }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 