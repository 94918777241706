<div class="container-fluid">
    <div class="row">
        <div class="col-12 px-0">
            <nav aria-label="breadcrumb" class="px-0 ">
                <ol class="breadcrumb d-flex align-items-center mb-0">
                    <li class="breadcrumb-item"><a routerLink="/tienda"  >Categorias</a></li>
                    <li class="breadcrumb-item"><a class="text-link" (click)="oncategoria()" >{{ objArticulo.strCategoria }}</a></li>
                    <li class="breadcrumb-item " aria-current="page"> <a (click)="onCardClick()"  class="text-link">{{objArticulo.strGrupo}}</a> </li>
                    <li class="breadcrumb-item active" aria-current="page">Articulo:{{objArticulo.strArticulo}}</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-11 d-flex bg-white">
            <div class=" col-12 py-4">
                <div class="row">
                    <div class="col-md-5 col-12 d-flex align-items-center">
                        <div class="d-flex col-12">
                          <!-- Miniaturas -->
                          <div class="thumbnails d-flex flex-column col-2">
                            <img *ngFor="let img of [objArticulo.strUrlImg1, objArticulo.strUrlImg2, objArticulo.strUrlImg3, objArticulo.strUrlImg4, objArticulo.strUrlImg5]; let i = index" [src]="img" class="thumbnail-img mb-2" (click)="setActiveSlide(i)" [class.active]="i === activeSlide" (error)="onImageError($event)" alt="Thumbnail" />
                          </div>
                          <!-- Carrusel principal -->
                          <div id="carouselExampleIndicators" class="carousel slide col-10 d-flex align-items-center" data-bs-ride="false">
                            <div class="carousel-inner rounded  col-12">
                              <div class="carousel-item " *ngFor="let img of [objArticulo.strUrlImg1, objArticulo.strUrlImg2, objArticulo.strUrlImg3, objArticulo.strUrlImg4, objArticulo.strUrlImg5]; let i = index"[class.active]="i === activeSlide">
                                <div class="d-flex align-items-center justify-content-center">
                                    <img [src]="img" class="rounded w-75" alt="Product image" (error)="onImageError($event)" />
                                </div>
                              </div>
                            </div>
                            <button class="carousel-control-prev" type="button" (click)="prevSlide()">
                                <span aria-hidden="true">
                                  <i class="bi bi-chevron-left" style="font-size: 2rem; color: #6d6d6d;"></i>
                                </span>
                                <span class="visually-hidden">Previous</span>
                              </button>
                              <button class="carousel-control-next" type="button" (click)="nextSlide()">
                                <span aria-hidden="true">
                                  <i class="bi bi-chevron-right" style="font-size: 2rem; color: #6d6d6d;"></i>
                                </span>
                                <span class="visually-hidden">Next</span>
                              </button>
                          </div>
                        </div>
                    </div>
                    <div class="d-md-none col-12">
                        <hr>
                    </div>
                    <div class="col-md-7 px-0 col-12">
                        <div class="row w-100">
                            <div class="col-md-7 col-12">
                                <div class="col-12">
                                    <h3 class="mb-0 fw-bold">{{objData_Art.strDescripcion1}}</h3>
                                </div>
                                <div class="col-12 d-flex my-2 justify-content-between" *ngIf="objData_Art.strTipoOpcion=='Si' && arrayOpciones.length >0">
                                    <div class="col-12 col-xl-6 d-flex flex-column" >
                                        <label class=" mb-1">Opciones:</label>
                                        <mat-select name="categoria" id="" class="form-control w-100 fs11 fw-bold" [(ngModel)]="strOpcion_Selected" (selectionChange)="fnCambio_opcion()">                                      
                                            <mat-option  [value]="opcion.strOpcion" *ngFor="let opcion of arrayOpciones"    >
                                                <div class="d-flex">
                                                    <span><span class="fw-bold me-2 fs11">({{opcion.strOpcion}})</span>{{ opcion.strNombreOpcion }} </span> 
                                                    <span class="ms-auto text-danger fs11" *ngIf="opcion.strEstatus=='DESCONTINUADO'">{{opcion.strEstatus}}</span>
                                                </div>
                                            </mat-option>
                                        </mat-select>
                                    </div>  
                                </div>
                                <div class="col-12 mt-2">
                                    <span class="d-flex align-items-end">
                                        <span class="me-2 art_titulo nunito fs12">clave:</span> <span class="fw-bold art_titulo mb-0 nunito fs12">{{objArticulo.strArticulo}}</span>
                                     </span>
                                </div>
                                <div class="col-12 mt-1" *ngIf="objData_Art.strTipoPedido == 'LIQUIDACION' || objData_Art.strTipoPedido == 'OFERTA'" > 
                                    <span class="fs13 fw-bold my-auto mx-auto px-2 text-white " [ngClass]="{'bgLiquidacion':objData_Art.strTipoPedido == 'LIQUIDACION','bgOferta':objData_Art.strTipoPedido == 'OFERTA'}">{{objData_Art.strTipoPedido}}</span>
                                </div>
                                <div class="col-12 mt-1" *ngIf="boolMostrar_agotado " > 
                                    <span class="fs13 fw-bold my-auto mx-auto px-2 text-white bgLiquidacion" >Producto Agotado</span>
                                </div>
                                <div class="col-12 mt-2">
                                    <h1 class="mb-0 fw-bold nunito fs-2 d-flex align-items-center">{{dblPrecio_Selected|currency}} 
                                      
                                    </h1>
                                </div>
                                <div class="col-12 mt-2">
                                    <span class="fs12">
                                        <span *ngIf="!objData_Art.strDescripcionArray">{{objData_Art.strDescripcion}}</span>
                                          <ul>
                                            <li *ngFor="let parte of objData_Art.strDescripcionArray" >{{ parte }}</li>
                                          </ul>
                                    </span>
                                </div>
                            </div>
                            <div class="d-md-none col-12">
                                <hr>
                            </div>
                            <div class="col-md-5 col-12 pe-0">
                                <div class="col-12 d-md-flex align-items-center ">
                                    <div class="d-flex justify-content-center">
                                        <div class="input-group mx-auto ">
                                            <button class="btn border border-secondary"  (click)="fnAdd_Rest_Amount(objData_Art,'rest');" type="button" [disabled]="(dblDisponible_nacional - dblReservado_nacional) <= 0 ||objData_Art.dblCantidad == 1 || objData_Art.dblCantidad < 1">-</button>
                                            <input (paste)="preventPaste($event)" (keydown)="preventDecimal($event)" (input)="validarCantidad(objData_Art,true,$event)" [disabled]="(dblDisponible_nacional - dblReservado_nacional) <= 0 || fnDisponible() == true"  type="number" value="0" [(ngModel)]="objData_Art.dblCantidad" class="form-control text-center" placeholder="" >
                                            <button class="btn border border-secondary" (click)="fnAdd_Rest_Amount(objData_Art,'add');" type="button" [disabled]="objDisponible_almacen.dblCantidad==objData_Art.dblCantidad  ">+</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="input-group mb-3 mx-auto d-flex justify-content-center">
                                        <span class="input-group-text text-center" id="basic-addon1">
                                            <i class="bi bi-bag"></i>
                                        </span>
                                        <button class="btn btnAhora  fs12" [disabled]="boolMostrar_agotado"  (click)="fnAddToCarr(objData_Art,true)">Comprar Ahora</button>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="input-group mb-3 d-flex justify-content-center">
                                        <span class="input-group-text text-center" id="basic-addon1">
                                            <i class="bi bi-cart4"></i>
                                        </span>
                                        <button class="btn btnCarrito fs12" [disabled]="boolMostrar_agotado"  (click)="fnAddToCarr(objData_Art)">Agregar a Carrito</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
    <div class="row justify-content-center  my-3" *ngIf="arraySusPrincipal.length>0">
        <div class="col-11 px-0 bg-white pb-3 d-flex justify-content-center ">
            <div class=" col-12  ">
                <div class="col-12 text-start py-2 px-0">
                    <h4 class=" ms-4 mb-0 fw-bold">PRODUCTOS SUSTITUTOS</h4>
                </div>
                <div class="col-12 d-flex px-0">
                    <div class="w-100">
                        <div class="row justify-content-center">
                            <div class="col-11 d-flex">
                                <div id="carouselProdcSus" class="carousel slide w-100" data-bs-interval="false">
                                    <div class="carousel-inner ">
                                        <div class="carousel-item" [ngClass]="{'active': i === 0}" *ngFor="let bloq of arraySusPrincipal; let i = index;">
                                            <div class="row justify-content-center">
                                                <div class="col-12 col-md-11 d-flex justify-content-center justify-content-md-start flex-wrap py-2">
                                                    <!-- Tarjetas de productos -->
                                                    <div class="col-4 col-md-3 col-lg-2 pb-2 mx-2 mt-2 mt-md-0 d-flex shadow-sm" *ngFor="let imgs of bloq.arts" [ngClass]="{'d-none': imgs.strArticulo == undefined}">
                                                        <div class="d-flex flex-column text-center cursorPointer" (click)="addToCart(imgs)">
                                                            <div class="bg-white rounded-top p-1">
                                                                <img (error)="onImageError($event)" [src]="imgs.url" class="w-100 rounded" alt="Producto">
                                                            </div>
                                                            <div class="px-2 pt-2 pb-0  h-100">
                                                                <div class="col-12 d-flex h-100">
                                                                    <div class="col-10 d-flex flex-column">
                                                                        <h6 class="mb-0 text-dark text-start descripcion-truncada">{{imgs.strDescripcion}}</h6>
                                                                        <p class="text-dark mb-1 text-start fw-bold">{{imgs.dblPrecio | currency}}</p>
                                                                        <p class="fs11 text-secondary mb-0 text-start mt-auto">CLAVE:  {{imgs.strArticulo}}</p>
                                                                    </div>
                                                                    <div class="col-2 d-flex align-items-start">
                                                                        <button class="btn btn-dark btn-sm p-1">
                                                                            <i class="bi bi-cart-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button *ngIf="arraySusPrincipal.length > 5" class="carousel-control-prev" type="button" data-bs-target="#carouselProdcSus" data-bs-slide="prev">
                                        <i class="bi bi-chevron-left" style="font-size: 2rem; color: black;"></i>
                                        <span class="visually-hidden">Anterior</span>
                                    </button>
                                    <button *ngIf="arraySusPrincipal.length > 5"  class="carousel-control-next" type="button" data-bs-target="#carouselProdcSus" data-bs-slide="next">
                                        <i class="bi bi-chevron-right" style="font-size: 2rem; color: black;"></i>
                                        <span class="visually-hidden">Siguiente</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                </div>
            </div>
            
        </div>
    </div>  
    <div class="row justify-content-center mb-3" *ngIf="arrayRelacionadosPrincipal.length>0">
        <div class="col-11 px-0 bg-white d-flex justify-content-center pb-3">
            <div class="col-12  justify-content-center">
                <div class="col-12 text-start py-2 px-0">
                    <h4 class=" ms-4 mb-0 fw-bold">ARTICULOS RELACIONADOS</h4>
                </div>
                <div class="col-12 d-flex px-0">
                    <div class="w-100">
                        <div class="row justify-content-center">
                            <div class="col-11 d-flex">
                                <div id="cars" class="carousel slide w-100" data-bs-interval="false">
                                    <div class="carousel-inner ">
                                        <div class="carousel-item  " [ngClass]="{'active': i === 0}"    *ngFor="let bloq of arrayRelacionadosPrincipal; let i = index;">
                                            <div class="row justify-content-center">
                                                <div class="col-12 col-md-11 d-flex justify-content-center justify-content-md-start flex-wrap py-2">
                                                    <!-- Tarjetas de productos -->
                                                    <div class="col-4 col-md-3 col-lg-2 pb-2 mx-2 mt-2 mt-md-0 d-flex shadow-sm" *ngFor="let imgs of bloq.arts" [ngClass]="{'d-none': imgs.strArticulo == undefined}">
                                                        <div class="d-flex flex-column text-center cursorPointer" (click)="addToCart(imgs)">
                                                            <div class="bg-white rounded-top p-2">
                                                                <img (error)="onImageError($event)" [src]="imgs.url" class="w-100 rounded" alt="Producto">
                                                            </div>
                                                            <div class="px-2 pt-2 pb-0 d-flex  h-100">
                                                                <div class="col-12 d-flex h-100">
                                                                    <div class="col-10 d-flex flex-column">
                                                                        <h6 class="mb-0 text-dark text-start descripcion-truncada">{{imgs.strDescripcion}}</h6>
                                                                        <p class="text-dark mb-1 text-start fw-bold">{{imgs.dblPrecio | currency}}</p>
                                                                        <p class="fs11 text-secondary mb-0 text-start mt-auto">CLAVE:  {{imgs.strArticulo}}</p>
                                                                    </div>
                                                                    <div class="col-2 d-flex align-items-start">
                                                                        <button class="btn btn-dark btn-sm p-1">
                                                                            <i class="bi bi-cart-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev text-dark" type="button" data-bs-target="#cars" data-bs-slide="prev" *ngIf="arrayRelacionadosPrincipal.length>5">
                                        <i class="bi bi-chevron-left" style="font-size: 2rem; color: black;"></i>
                                      <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next text-dark" type="button" data-bs-target="#cars" data-bs-slide="next" *ngIf="arrayRelacionadosPrincipal.length>5">
                                        <i class="bi bi-chevron-right" style="font-size: 2rem; color: black;"></i>
                                      <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </div> 
</div>

        


  