import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorageService } from 'ngx-webstorage';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router} from '@angular/router';
import * as html2pdf from 'html2pdf.js'; // Importa html2pdf
import { NgbModal } from 'bootstrap';
import Swal from 'sweetalert2';
import { DataDBService } from '../servicios/data-db.service';
import { CarroService } from '../servicios/carroservice';
import { FiltrosService } from '../servicios/filtros.service';
import { ComodatoService } from '../servicios/comodato.service';
export interface UserData {
  intVencido: string;
  intIDComodatos: string;
  strMov: string;
  strMovID: string;
  strFechaInicio: string;
  strFechaFin: string;
  strCliente: string;
  strNombreCte: string;
  strCteEnviarA: string;
  strAgente: string;
  strEstatusSolC: string;
  strEstatusSolR: string;
}

@Component({
  selector: 'app-listado-comodatos-todos',
  templateUrl: './listado-comodatos-todos.component.html',
  styleUrls: ['./listado-comodatos-todos.component.css']
})
export class ListadoComodatosTodosComponent implements OnInit {
  
  @ViewChild('cerrarModalRenov') cerrarModalRenov!: ElementRef;
  @ViewChild('btnSolc') btnSolc!: ElementRef;
  
  arrayListaComodato: any= [];  // Asume que se llena con los datos de tu lista
  arrayFiltrado: any = [];
  ServicioHide: boolean = false;
  DiasHide: boolean = false;
  ServicioSelected: string = '';
  FiltroPersonalizado: string = '';
  FiltroIntVencido: string = '';
public strGerente:string="";
public strSucursal:string="";
public strAgente:string="";
public agenteSesion:string="";
public dateFechaActual:Date= new Date();
public EnviarconcluirRenov = false;
public intEjercicio:number=0;
public intPeriodo:number=0;
public strEstatus:string="";
public arrayGerente:any;
public arraySucursales:any;
public strConexion:string="";
public arrayAgente:any;
public agenteSelected:any;
public arraylistComodatos:any;
arrayEliRenov: any[] = [];
boolrenovarcontrato: boolean = false;
spinerMod: boolean = false;
bolComentarioEnviar: boolean = false;
strComentarioEnviar: string = "";
objComodato: any = {};
arrayDetalleComodato: any[] = [];
banderaRegistros: boolean = false;
banderaRegistrosOK: boolean = false;
strNombreFile: string = '';
objComodatoCancelacion: any = {};
bolComentarioCancelacion: string = '';
bolEstatusCancelacion: number = 0;
concluirRet: boolean = false;
archivosSeleccionados: boolean = false;
objComodatoRenovacion: any = {};
spinerRenovmdl = false;
strMensajeRenovmdl = "";
cancelRenov = false;
concluirRenov = false;
selectedFile: any = null;
buttonTextSolC: string = '';
iconClassSolC: string = 'bg-light';
bgclass: string = '';
buttonText:string = 'Subir Evidencia de Firma y Sello del Cliente.';
iconClass: string = 'bi bi-cloud-upload';
public arrayAgentes:any=[];
SubtotalPDF = 0;
ivaPDF = 0;
ImportetotalPDF = 0;
datosArrayPDF: any[] = [];
@ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInputSolC') fileInputSolC!: ElementRef;
spinerModSolC: boolean = false;
bgclassSolC: string = '';
public mensajeError:string="Seleccione un agente";
public arrayPeriodo:any=[
  {intPeriodo:1,strPeriodo:"Enero"},{intPeriodo:2,strPeriodo:"Febrero"},{intPeriodo:3,strPeriodo:"Marzo"},
  {intPeriodo:4,strPeriodo:"Abril"},{intPeriodo:5,strPeriodo:"Mayo"},{intPeriodo:6,strPeriodo:"Junio"},
  {intPeriodo:7,strPeriodo:"Julio"},{intPeriodo:8,strPeriodo:"Agosto"},{intPeriodo:9,strPeriodo:"Septiembre"},
  {intPeriodo:10,strPeriodo:"Octubre"},{intPeriodo:11,strPeriodo:"Noviembre"},{intPeriodo:12,strPeriodo:"Diciembre"},
];
isHoveredButton: boolean = false;
public arrayEstatus:any=[
  {strEstatus:"VIGENTE"},
  {strEstatus:"VENCIDO"}
];
public strEstatusCom:string ="";
public boolGerente:boolean = false;
public boolSucursal:boolean=false;
public boolAgente:boolean=false;
public arrayGerentes:any=[];
public tagName:any;
public arrayEjercicio:any=[];
public arrayAccesos:any=[];
public datosComodatos: MatTableDataSource<UserData> = new MatTableDataSource<UserData>([]);
public spiner:boolean=false;

@ViewChild('paginadorComodatos') paginadorComodatos!: MatPaginator;
@ViewChild('sortComodatos') sortComodatos!: MatSort;
columnsComodatos: string[] = ['intVencido','intIDComodatos','strMov','strMovID','strFechaInicio','strFechaFin','strCliente','strNombreCte','strCteEnviarA','strAgente','strEstatusSolC','strEstatusSolR'];
constructor(private commodatoservice:ComodatoService,private _filtroServ :FiltrosService,private paginatorIntl: MatPaginatorIntl,  private carroservice: CarroService,private indexedDBService: DataDBService, private alertifyService: AlertifyService,elem:ElementRef,private _peticionesService: PeticionesService, public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router) {
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
    //array de accesos
    //obtener niveles de acceso
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      }
      else{
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
        this.agenteSesion = this.localSt.retrieve('strAgente')
      }
    }
    this.customizeItemsPerPageLabel()
  }


  ngAfterViewInit() {
    this.fnTabletareasCte();
  }

  fnTabletareasCte() {
    this.datosComodatos.sort = this.sortComodatos;
    this.datosComodatos.paginator = this.paginadorComodatos;

  }

    customizeItemsPerPageLabel() {
      this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
    }
  ngOnInit(): void {
    this.fnGetFiltros();
    this.fnGetComodatos();
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
  //    console.log(nuevoValor);
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    //  console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
    });
  }

  fnGetComodatos(){
    this.spiner =true
    let objDatos={
      strAccion:'getTodoComodato',
      strConexion:this.strConexion,
      intEjercicio:this.intEjercicio,
      intperiodo:this.intPeriodo,
      strSucursal:this.strSucursal,
      strAgente:this.strAgente,
      strGerente:this.strGerente
    }
    this._peticionesService.getComodatos(objDatos,"sp_ModVentas_Comodato").subscribe(
      result => {
        console.log(result)
        this.arraylistComodatos=result;
        this.datosComodatos = new MatTableDataSource(this.arraylistComodatos);
        this.fnTabletareasCte();
        this.spiner = false;
      },error => {
        this.spiner = false;
        console.log(error.message)
      });
  
    }

    detalleComodatos(obj: any) {
      this.arrayEliRenov = [];
      this.boolrenovarcontrato = false;
      this.spinerMod = true;
      this.bolComentarioEnviar = false;
      this.strComentarioEnviar = '';
      this.objComodato = obj;
      this.arrayDetalleComodato = [];
  
      let myobjectListaComodatos = {
        strAccion: 'getTodoComodato_Det',
        strConexion: 'COMERCIAL',
        intIDComodatos: obj.intIDComodatos,
        strCliente: obj.strCliente.trim(),
        strCteEnviarA: obj.strCteEnviarA
      };
  
      // Llamar al servicio para obtener los detalles del comodato
      this._peticionesService.getComodatos(myobjectListaComodatos, "sp_ModVentas_Comodato").subscribe(
        response => {
          this.arrayDetalleComodato = response;
  
          if (this.arrayDetalleComodato && this.arrayDetalleComodato.length > 0) {
            this.banderaRegistros = false;
            this.banderaRegistrosOK = true;
  
            this.arrayDetalleComodato.forEach((det: any) => {
              det.arrayConsumible = [];
              det.objPrecios = [];
              det.cantPromAnterior = det.dblCantidadPrometida;
              det.precioAnt = det.dblPrecio;
              det.selectConsumible = det.strConsumible;
              det.renConsumible = det.strConsumible;
              det.renConsumibleNombre = det.strNombreConsumible;
            });
          } else {
            this.banderaRegistros = true;
            this.banderaRegistrosOK = false;
          }
          this.spinerMod = false;
        },
        (error: any) => {
          console.error('Error en la solicitud', error);
          this.spinerMod = false;
        }
      );
    }

    fndetretirar(obj: any) {
      this.strNombreFile = '';
      this.objComodatoCancelacion = {};
      this.bolComentarioCancelacion = '';
      this.bolEstatusCancelacion = 0;
      this.concluirRet = false;
      this.selectedFile = null;
      console.log(obj);
      
      // Resetear el valor del input de archivo
      if (this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }
  
      this.objComodatoCancelacion = obj;
  
      if (obj.intVencido == 0) {
        this.bolComentarioCancelacion = "El comodato actual está en vigencia. Favor de elegir otra sección del contrato para acceder a la información, dado que no se ha presentado solicitud de cancelación.";
      } else if (obj.intVencido == 1) {
        this.bolComentarioCancelacion = "El comodato actual está Vencido. Favor de elegir otra sección del contrato para acceder a la información, dado que no se ha presentado solicitud de cancelación.";
      } else if (obj.intVencido == 2) {
        console.log("entra a retirar");
        if (obj.strEstatusSolC == 'OBSERVACIONES') {
          this.bolEstatusCancelacion = 1;
          this.bolComentarioCancelacion = this.objComodatoCancelacion.strObservacionesGte;
        } else if (obj.strEstatusSolC == 'RETIRAR') {
          this.bolEstatusCancelacion = 2;
          this.buttonTextSolC = 'Subir Evidencia';
          this.iconClassSolC = 'bi bi-cloud-upload';
          this.bgclassSolC = 'bg-light';
        } else if (obj.strEstatusSolC == 'PENDIENTE') {
          this.bolComentarioCancelacion = "El comodato actual está pendiente de cancelacion. Favor de elegir otra sección del contrato para acceder a la información.";
        } else if (obj.strEstatusSolC == 'RETIRAREV') {
          this.bolComentarioCancelacion = "Evidencia enviada correctamente.";
        }
      } else if (obj.intVencido == 3) {
        this.bolComentarioCancelacion = "El comodato actual está en renovación. Favor de elegir otra sección del contrato para acceder a la información, dado que no se ha presentado solicitud de cancelación.";
      }
    }
    clickInput(num: number) {
      if (num == 1) {
        this.fileInput.nativeElement.click();
      } else if (num == 2) {
        this.fileInputSolC.nativeElement.click();
      }
    }
  
    onFileInputChange(event: any) {
      this.spinerMod = true;
      setTimeout(() => {
        let file = event.target.files[0];
        if (file) {
          console.log(file)
          this.archivosSeleccionados = true;
          let fileName = file.name;
          let extension = fileName.split('.').pop()?.toLowerCase();
  
          if (extension == 'pdf') {
            this.selectedFile = null;
            let formData = new FormData();
            formData.append('archivo', file, `ContratoComodatoRenovacion_${this.objComodatoRenovacion.intIDComodatos}.${extension}`);
  
            this._peticionesService.setAnexoComotadoRev(formData).subscribe(
              response => {
                console.log(response)
                
                  let respuesta = response

                  if(respuesta.mensaje == "200"){
                    this.bgclass = 'bgclsenv';
                    this.buttonText = "ContratoComodatoRenovacion_"+this.objComodatoRenovacion.intIDComodatos+'.'+extension;
                    this.iconClass = 'bi bi-check-circle text-success';
                    this.spinerMod =false;
                    this.concluirRenov = true;
                }else{
                    this.bgclass = 'bgcls';
                    this.buttonText = "Error al subir el arrchivo intente de nuevo";
                    this.iconClass = 'bi bi-x-circle text-warning';
                    this.spinerMod =false;
                }
              });


            // Enviar el archivo al servidor
            // Reemplazar el $http.post con HttpClient (Angular service)
          } else {
            this.bgclass = 'bgcls';
            this.buttonText = 'Archivo incorrecto, seleccione un archivo PDF';
            this.iconClass = 'bi bi-x-circle text-warning';
          }
        }
        this.spinerMod = false;
      }, 2500);
    }
  
    onFileInputSolCChange(event: any) {
      this.spinerModSolC = true;
      setTimeout(() => {
        let file = event.target.files[0];
        if (file) {
          this.archivosSeleccionados = true;
          let fileName = file.name;
          let extension = fileName.split('.').pop()?.toLowerCase();
          let allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  
          if (allowedImageExtensions.includes(extension!)) {
            this.selectedFile = null;
            let formData = new FormData();
            this.strNombreFile = `EvidenciaRetiro_${this.objComodatoCancelacion.intIDComodatos}.${extension}`;
            formData.append('archivo', file, this.strNombreFile);
            this._peticionesService.setAnexoComotado(formData).subscribe(
              response => {
                console.log(response)
                let respuesta = response

                if(respuesta.mensaje == "200"){

                  this.bgclassSolC = 'bgclsenv';
                  this.buttonTextSolC = this.strNombreFile;
                  this.iconClassSolC = 'bi bi-check-circle text-success';
                  this.spinerModSolC =false;
                  this.concluirRet = true;
              }else{
                  this.bgclassSolC = 'bgcls';
                  this.buttonTextSolC = "Error al subir el arrchivo intente de nuevo";
                  this.iconClassSolC = 'bi bi-x-circle text-warning';
                  this.spinerModSolC =false;
              }



              });
            // Enviar el archivo al servidor
          } else {
            this.bgclassSolC = 'bgcls';
            this.buttonTextSolC = 'Archivo incorrecto, seleccione una imagen';
            this.iconClassSolC = 'bi bi-x-circle text-warning';
          }
        }
        this.spinerModSolC = false;
      }, 2500);
    }
  
    enviarDocCancelacion() {
      alert('Se envió correctamente el documento');
      this.bgclass = 'bgclsenv';
      this.iconClass = 'bi bi-check-circle text-success';
    }
  
    fnGenerarPfdRenov(x: any): void {
      this.bgclass = "";
      this.buttonText = 'Subir Evidencia de Firma y Sello del Cliente.';
      this.iconClass = 'bi bi-cloud-upload';
      this.cancelRenov = false;
      this.concluirRenov = false;
      this.archivosSeleccionados = false;
      this.selectedFile = null;
      this.objComodatoRenovacion = {};
      this.spinerRenovmdl = false;
      this.strMensajeRenovmdl = "";
      this.objComodatoRenovacion = x;
  
      if (x.intVencido == 3) {
        if (x.strEstatusSolR == "AUTORIZADO") {
          this.spinerRenovmdl = true;
          this.fnExtraerDatosPdf(x);
        } else if (x.strEstatusSolR == "RECHAZADO") {
          this.cancelRenov = true;
          this.strMensajeRenovmdl = x.strObservacionesGte;
        }
      } else {
        this.strMensajeRenovmdl = "Por favor, seleccione un Comodato que se encuentre en estado de solicitud de renovación y que haya sido autorizado.";
      }
    }
    fnExtraerDatosPdf(x: any): void {
      this.SubtotalPDF = 0;
      this.ivaPDF = 0;
      this.ImportetotalPDF = 0;
      this.datosArrayPDF = [];
  
      let objcancelar = { 
        strAccion: "getDetalleAutSolR",
        strConexion: "COMERCIAL",
        intIDCancelacion: x.intIDCancelacion
      };
  
      this._peticionesService.getComodatos(objcancelar, 'sp_ModVentas_Comodato').subscribe(
        response => {
          this.datosArrayPDF = response;
          this.datosArrayPDF.forEach(o => {
            this.SubtotalPDF += (o.dblPrecio * o.dblCantidadDespachador);
          });
          this.ivaPDF = this.SubtotalPDF * 0.16;
          this.ImportetotalPDF = this.ivaPDF + this.SubtotalPDF;
          console.log("==========RespuestaDeDatosDePDF==========");
          console.log(this.datosArrayPDF);
     
        },
        (error) => {
          console.error('Error:', error);

        }
      );
    }

    fnCreatePdf(): void {
      let options = {
        margin: 8,
        filename: 'Comodato.pdf',
        image: { type: 'JPEG', quality: 0.98 },
        html2canvas: { scale: 2, autoPaging: true, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
  
      let content = document.getElementById('pdfTableFiniquito');
      
      if (content) {
        html2pdf()
          .from(content)
          .set(options)
          .toPdf()
          .get('pdf')
          .then((pdf: any) => {
            pdf.save("Comodato.pdf");
          })
          .catch((error: any) => {
            console.error('Error generating PDF:', error);
          });
      } else {
        console.error('Content not found for PDF generation.');
      }
    }
    fnenviarDocRenovacion(): void {
      let objcancelar = { 
        strAccion: 'setConcluirSolR',
        strConexion: 'COMERCIAL',
        intIDCancelacion: this.objComodatoRenovacion.intIDCancelacion,
      };
  
      this._peticionesService.getComodatos(objcancelar, 'sp_ModVentas_Comodato').subscribe(
        response => {
          if (response[0]?.code == "200") {
            this.fnGetComodatos();
            this.alertifyService.success('La solicitud se concluyó correctamente.');
           /* faltaocultar modal */
           this.cerrarModalRenov.nativeElement.click()
          } else {
            this.alertifyService.error('Error al concluir: ' + response.strMensaje);
          }
          console.log("==========RespuestaDeDocumentoRenov==========");
          console.log(response);
        },
        error => {
          console.error('Error:', error);
          this.alertifyService.error('Hubo un error al concluir la solicitud.');
        }
      );
    }
    fnenviarCancelarRechazo(): void {
      let objcancelar = { 
        strAccion: 'deleteSolR',
        strConexion: 'COMERCIAL',
        intIDCancelacion: this.objComodatoRenovacion.intIDCancelacion,
      };
  
      this._peticionesService.getComodatos(objcancelar, 'sp_ModVentas_Comodato').subscribe(
        response => {
          if (response[0]?.code == "200") {
            this.fnGetComodatos();
            this.alertifyService.success('La solicitud se concluyó correctamente.');
            this.cerrarModalRenov.nativeElement.click()
         // O usa el método adecuado para ocultar el modal
          } else {
            this.alertifyService.error('Error al concluir: ' + response.mensaje);
          }
          console.log(response);
        },
        error => {
          console.error('Error:', error);
          this.alertifyService.error('Hubo un error al concluir la solicitud.');
        }
      );
    }

    fnenviarRechazarObsevacionesCancelacion(): void {
      let objcancelar = {
        strAccion: 'deleteSolC',
        strConexion: 'COMERCIAL',
        intIDCancelacion: this.objComodatoCancelacion.intIDCancelacion,
      };
  
      this._peticionesService.getComodatos(objcancelar, 'sp_ModVentas_Comodato').subscribe(
        response => {
          if (response[0]?.code == "200") {
            this.fnGetComodatos();
            this.alertifyService.success('La solicitud se canceló correctamente.');

            this.btnSolc.nativeElement.click()
          } else {
            this.alertifyService.error('Error al concluir: ' + response.mensaje);
          }
          console.log(response);
        },
        error => {
          console.error('Error:', error);
          this.alertifyService.error('Hubo un error al cancelar la solicitud.');
        }
      );
    }
  
    fnenviarDocRetCancelacion(): void {
      let objcancelar = {
        strAccion: 'setRETIRAR_EVIDENCIA',
        strConexion: 'COMERCIAL',
        intIDCancelacion: this.objComodatoCancelacion.intIDCancelacion,
        strObservaciones: this.strNombreFile
      };
  
      this._peticionesService.getComodatos(objcancelar, 'sp_ModVentas_Comodato').subscribe(
        response => {
          if (response[0].code == "200") {
            this.fnGetComodatos();
            this.alertifyService.success('La solicitud se concluyó correctamente.');
            this.btnSolc.nativeElement.click()
           /*  this.modalService.dismissAll();  */// O usa el método adecuado para ocultar el modal
          } else {
            this.alertifyService.error('Error al concluir: ' + response[0].strMensaje);
          }
          console.log(response);
        },
        error => {
          console.error('Error:', error);
          this.alertifyService.error('Hubo un error al retirar la evidencia.');
        }
      );
    }
    fnEliminardedetRen(x: any, index: number): void {
      // Agregar el elemento a arrayEliRenov
      this.arrayEliRenov.push(x);
  
      // Eliminar el elemento de arrayDetalleComodato
      this.arrayDetalleComodato.splice(index, 1);
    }

    seleccionarConsumible(articulo: any, index: number): void {
      console.log(this.objComodato);
      this.arrayDetalleComodato[index].strConsumible = articulo.strConsumible;
      this.arrayDetalleComodato[index].strNombreConsumible = articulo.strNombre;
      let objart = { strConsumible: articulo.strConsumible };
      this.extraerprecios(objart, index);
    }
  
    // Función para extraer precios
    extraerprecios(art: any, ind: number): void {
      let myobject3 = {
        'strArticulo': (art.strConsumible ? art.strConsumible.trim() : ''),
        'strCliente': (this.objComodato.strCliente ? this.objComodato.strCliente.trim() : '')
      };
  
      this._peticionesService.getpagViejitaPlantillas(myobject3,"getArtPrecios").subscribe(
        result => {
        let objPrecios = result;
        this.arrayDetalleComodato[ind].objPrecios = objPrecios;
        if (objPrecios.dblPrecioPolitica && objPrecios.dblPrecioPolitica > 0) {
          this.arrayDetalleComodato[ind].dblPrecio = objPrecios.dblPrecioPolitica;
        } else {
          this.arrayDetalleComodato[ind].dblPrecio = objPrecios.dblPrecioPlantilla;
        }
      });
    }

    fneditElimin() {
      let strMensajeAlert = "¿Está seguro de cancelar la partida: ";
      let xmlArticulos: any[] = [];
  
      this.arrayEliRenov.forEach(det => {
        xmlArticulos.push({
          strArticulo: det.strDespachador,
          dblCantidad: det.dblCantidadDespachador,
          strOpcion: det.strOpcion
        });
  
        if (det.strOpcion && det.strOpcion !== "") {
          // Si strOpcion está presente, incluir el texto entre paréntesis
          strMensajeAlert += "Art:" + det.strDespachador + ", OPC:(" + det.strOpcion + ")" + det.strNombreOpcion + ";";
        } else {
          // Si strOpcion no está presente, incluir solo el punto y coma
          strMensajeAlert += "Art:" + det.strDespachador + ";";
        }
      });
  
      // Mostrar confirmación con Swal.fire
      Swal.fire({
        title: 'Confirmación',
        text: strMensajeAlert,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, cancelar',
        cancelButtonText: 'No, mantener',
      }).then((result) => {
        if (result.isConfirmed) {
          let myobjectListaComodatos = {
            strAccion: 'setCancelar_Partida',
            strConexion: 'COMERCIAL',
            intIDComodatos: this.objComodato.intIDComodatos,
            intID: this.objComodato.intID,
            xmlArticulos: xmlArticulos
          };
  
          console.log(myobjectListaComodatos);
  
          this._peticionesService.getComodatos(myobjectListaComodatos, 'sp_ModVentas_Comodato').subscribe(
            response => {
              let arrayRest = response;
              console.log("========== Respuesta De Eliminar Despachadores De Comodato ==========");
              console.log(arrayRest);
  
              if (arrayRest[0].code == "200") {
                this.arrayEliRenov = [];
                Swal.fire('Éxito', 'La partida se canceló correctamente.', 'success');
              } else {
                Swal.fire('Error', 'Error al cancelar: ' + arrayRest[0].strMensaje, 'error');
              }
            },
            (error) => {
              console.error(error);
              Swal.fire('Error', 'Error en la petición.', 'error');
            }
          );
        } else {
          Swal.fire('Cancelado', 'La partida no se canceló.', 'info');
        }
      });
    }

    fncancelarContrato() {
      console.log(this.strComentarioEnviar);
  
      // Verificar si el comentario tiene más de 3 caracteres
      if (this.strComentarioEnviar.length <= 3) {
        Swal.fire('Error', 'El comentario debe tener más de 3 caracteres.', 'warning');
 
        this.bolComentarioEnviar = true;
      } else {
        this.bolComentarioEnviar = false;
  
        const objcancelar = {
          strAccion: 'SetSolicitudCancelacion',
          strConexion: 'COMERCIAL',
          intIDComodatos: this.objComodato.intIDComodatos,
          intID: this.objComodato.intID,
          strAgente: this.agenteSesion, // Ajusta según cómo guardes el agente
          strObservaciones: this.strComentarioEnviar
        };
  
        console.log(objcancelar);
  
        // Consumir el servicio para realizar la cancelación
        this._peticionesService.getComodatos(objcancelar, 'sp_ModVentas_Comodato').subscribe(
          response  => {
            let resCancelacion = response;
            Swal.fire('Éxito', 'Solicitud enviada correctamente.', 'success');
            this.fnGetComodatos(); // Asegúrate de tener esta función implementada
           // Asegúrate de tener esta función implementada
            console.log(resCancelacion);
          },
          (error) => {
            console.error(error);
            Swal.fire('Error', 'Error en la solicitud de cancelación.', 'error');
          }
        );
      }
    }

    fnrenovacionContrato(intfn: number) {
      if (intfn == 1) {
        this.boolrenovarcontrato = true;
  
        this.arrayDetalleComodato.forEach((det, index) => {
          let myobject = { strArticulo: det.strDespachador.trim() };
  
          // Consumir el servicio para obtener la opción del artículo consumible
          this._peticionesService.getpagViejitaPlantillas(myobject, 'getOpcionArticuloConsumible').subscribe(
            response=> {
              det.arrayConsumible = response;
  
              // Condiciones basadas en el grupo del consumible
              if (det.arrayConsumible[0].strGrupo == 'DESPACHADOR DE AROMA' || det.strConsumible == 'INT-4PLUS') {
                this.ServicioHide = false;
                this.ServicioSelected = 'Renta';
              } else if (det.arrayConsumible[0].strGrupo == 'DIFUSOR AROMIA') {
                this.ServicioHide = true;
                this.DiasHide = true;
                this.ServicioSelected = 'Renta';
              }
  
              // Seleccionar el consumible correspondiente
              det.selectConsumible = det.arrayConsumible.find((articulo: any) => {
                return articulo.strConsumible == det.strConsumible;
              });
  
              // Extraer precios utilizando otro servicio
              const objprecios = {
                strConsumible: det.strConsumible.trim(),
                strCliente: this.objComodato.strCliente.trim()
              };
              this.extraerprecios(objprecios, index);
            },
            (error) => {
              console.error('Error obteniendo opción de consumible', error);
            }
          );
        });
      } else if (intfn == 2) {
        this.boolrenovarcontrato = false;
      }
    }


    fnCancelarRen(obj:any){
      this.detalleComodatos(obj);
      this.boolrenovarcontrato =false
    }

    fnEnviarRenovacionContrato() {
      let xmlArticulos = [];
      this.arrayDetalleComodato.forEach(det => {
        xmlArticulos.push({
          strArticulo: det.strConsumible,
          dblPrecio: det.dblPrecio,
          dblCantidad: det.dblCantidadPrometida,
          strNombre: det.strDespachador
        });
      });
      let myobjectListaComodatos = {
        strAccion: 'SetSolicitudRenovacion',
        strConexion: 'COMERCIAL',
        intIDComodatos: this.objComodato.intIDComodatos,
        intID: this.objComodato.intID,
        strAgente:this.agenteSesion,
        xmlArticulos: xmlArticulos
      };
  
      // Llamada al servicio para enviar la solicitud de renovación
      this._peticionesService.getComodatos(myobjectListaComodatos, 'sp_ModVentas_Comodato').subscribe(
        response => {
          console.log("========== Respuesta de Renovación de Contrato ==========");
          console.log(response);
  
          // Refrescar la lista de Comodatos
          this.fnGetComodatos();
  
          // Mostrar alerta de éxito
          Swal.fire('Éxito', 'La solicitud se envió correctamente.', 'success');
  
          // Cerrar modal si es necesario
          const modal = document.getElementById('exampleModal');
          if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
          }
        },
        (error) => {
          console.error('Error al enviar la solicitud de renovación:', error);
        }
      );
    }

    fnvalidarEnviar(): boolean {
      // Setea la variable `EnviarconcluirRenov` a false
      this.EnviarconcluirRenov = false;
      // Devuelve true si todos los elementos de `arrayDetalleComodato` tienen `dblPrecio > 0`
      return this.arrayDetalleComodato.every(x => x.dblPrecio > 0);
    }

    filterComodatos(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.datosComodatos.filter = filterValue.trim().toLowerCase();
      if (this.datosComodatos.paginator) {
        this.datosComodatos.paginator.firstPage();
      }
    }
    fnFiltrarEstatus(){
      let newArray =[]
      newArray = this.strEstatusCom == ""?this.arraylistComodatos: this.arraylistComodatos.filter(o => o.intVencido == this.strEstatusCom)
      this.datosComodatos = new MatTableDataSource(newArray);
      this.fnTabletareasCte();
 
    }
    enviarDetalleCliente(item: any) { 
      //guardamos en el servicio de indexed db la ruta
      let objRuta_Anterior={
        ruta_origen:'/listadoComodatosTodos',
        ruta_destino:'/detalleLeadCliente',
        nombre:'Directorio'
      }
      this.indexedDBService.removeItem('objRuta_Anterior');
      this.indexedDBService.setItem('objRuta_Anterior',   objRuta_Anterior );
      //agregamos a carrito el cliente
      this.fnSeleccionarCliente(item); //vivi
    this.router.navigate(['/detalleLeadCliente'],{queryParams:{strCliente: item.strCliente}});
    }

    
  fnSeleccionarCliente(x:any){
    //borramos todo antes de seleccionar un cliente
    
    this.carroservice.setModoPlantilla(false);
    this.carroservice.deleteCarrito();
 
      x.ClienteSeleccionado =true;
      this.carroservice.setCte(x);

      //seleccionamos el cliente también para comodatos
      this.commodatoservice.setCte(x)
      console.log(x)
  }

  getDate(): string {
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1; // Los meses empiezan desde 0
    const year = today.getFullYear();

    // Asegurarse de que el día y el mes tengan dos dígitos
    const dayStr = (day < 10) ? '0' + day : day.toString();
    const monthStr = (month < 10) ? '0' + month : month.toString();

    // Devolver la fecha formateada
    return `${dayStr}/${monthStr}/${year}`;
  }
} 

