<div class="container">
    <div class="row">
        <div class="col-12 pt-3 text-center">
            <h1 class="fw-bold">
                 <span class="titulotext">Comodatos</span>
            </h1>
        </div>
    </div>
    <div class="row  shadow  rounded1 bg-white">
        <div class="col-12 titulos d-flex  align-items-center py-2 roundedcrd">
            <span  class="fs-5 fw-bold">Cliente</span> 
        </div>
        <div class=" row my-2">
            <div class="col-12 col-lg-7 col-xl-4 text-center mb-3 position-relative" >
                <mat-form-field appearance="outline" class="w-100 bgwhite rounded">
                    <mat-label>Cliente</mat-label>
                    <input matInput  class="" [(ngModel)]="strNumCte" placeholder="Ingrese número de cliente"   (keydown)="onKeyDown($event)" (keyup)="fnBuscarCte(strNumCte);" aria-label="Recipient's username" aria-describedby="button-addon2" autocomplete="off" matInput [matAutocomplete]="auto">
                    <i class="bi bi-person-gear fs-4" matSuffix [ngClass]="{'text-success':cteSelect,'text-secondary':!cteSelect}"></i>
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" class="w-100">
                  <mat-option class="fs2 hoption" *ngFor="let option of arrayClientes; let i = index;" [value]="option.strCliente" (click)="fnllenarInputs(option,null,1);" [title]="option.strNombreMostrar" >
                    <b>{{option.strCliente}} --</b> {{option.strNombre}}
                  </mat-option>
                </mat-autocomplete>
            </div>
       
            <div class="col-12 col-lg-5 col-xl-3 text-center mb-3" >
                <mat-form-field appearance="outline" class="w-100 bgwhite rounded">
                    <mat-label>Nombre</mat-label>
                    <input matInput [(ngModel)]="strNombreCliente" class="" readonly>
                    <i class="bi bi-person-vcard fs-4" matSuffix [ngClass]="{'text-success':cteSelect,'text-secondary':!cteSelect}"></i>
                </mat-form-field>
            </div>
        <!--<div class="col-12 col-lg-4 col-xl-2 text-center mb-3" >
                <mat-form-field appearance="outline" class="w-100 bgwhite rounded">
                    <mat-label>Sucursal</mat-label>
                    <input matInput [(ngModel)]="strSucursal" class="" readonly>
                    <i class="bi bi-building-gear fs-4" matSuffix [ngClass]="{'text-success':cteSelect,'text-secondary':!cteSelect}"></i>
                </mat-form-field>
            </div> -->
            <div class="col-12 col-lg-4 col-xl-2  mb-3">
              <mat-form-field  class="col-12" appearance="outline">
                  <mat-label>Sucursal  </mat-label>
                  <mat-select [(ngModel)]="objCliente.strNombreSucursal" name="sucursal" >
                      <mat-option  [value]="''" (click)="fnllenarInputs(objCliente,{},2);" > <!--(selectionChange)="onSelectionChange($event,objCliente)""-->
                          --Seleccione Sucursal--
                      </mat-option>
                      <mat-option  [value]="''"[disabled]="true"  > <!--(selectionChange)="onSelectionChange($event,objCliente)""-->
                          <input type="text" [(ngModel)]="strBuscarSuc" class="form-control" (click)="$event.stopPropagation()">
                      </mat-option>
                    <mat-option [ngClass]="{'d-none':food.strNombreSucursal == '' }"
                     *ngFor="let food of arraySucursalesCte| filtersearch: strBuscarSuc: ['strNombreSucursal']" [value]="food.strNombreSucursal" (click)="fnllenarInputs(objCliente,food,2);"> <!-- .strNombreSucursal -->
                    <b>({{food.intIDSucursal}} ) </b>  {{food.strNombreSucursal}}
                    </mat-option>
                  </mat-select>
                  <span matSuffix class="material-symbols-outlined" [ngClass]="{'text-success':cteSelect,'text-secondary':!cteSelect}">
                      location_city
                  </span>
              </mat-form-field>
          </div>
            <div class="col-12 col-lg-6 col-xl-3 text-center mb-3"  >
                <mat-form-field appearance="outline" class="w-100 bgwhite rounded">
                    <mat-label>Nombre Sucursal</mat-label>
                    <input matInput [(ngModel)]="strNombreSucursal" class="" readonly>
                    <i class="bi bi-buildings fs-4" matSuffix [ngClass]="{'text-success':cteSelect,'text-secondary':!cteSelect}"></i>
                </mat-form-field>
            </div>
        </div>
    </div>    
<!--Fin Cliente  -->
<!-- inicio de lineas -->
    <div class="row my-4 shadow roundedcrd roundedcrdbottom" >
        <div class="col-12 titulos roundedcrd py-2">
            <span class="fs-5 fw-bold">Lineas</span>
        </div>
        <div class="col-12 py-3 bglineas roundedcrdbottom">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item " [ngClass]="{'active':i==0}" *ngFor="let bloq of bloques; let i = index;">
                    <div class="row w-100">
                        <div class="col text-center position-relative px-0 mx-2 my-2" [ngClass]="{'imgselect':x==objLinea}" *ngFor="let x of bloq" (click)="fnseleclinea(x)" >
                            <img class="w-100  shadow-sm"  [ngClass]="{'imgitemcar':x!=objLinea}"  [src]="'https://www.cleanclean.mx/imagenes/Sublinea/'+x.strGrupo+'.jpg'" alt="">
                            <div class="w-100 position-absolute bottom-0 start-50 translate-middle-x roundedcrdbottom shadow-sm" style="background: #FCE5E0;">
                                <span class="text-secondary fw-bold fs4">{{x.strGrupo}}</span>
                            </div>
                        </div>
                        <div class="col" *ngIf="bloq.length < 6 && elementosPorPagina == 6" >
                        </div>
                        <div class="col" *ngIf="bloq.length < 5 && elementosPorPagina == 6" >
                        </div>
                        <div class="col" *ngIf="bloq.length < 4  && elementosPorPagina == 6" >
                        </div>
                        <div class="col" *ngIf="bloq.length < 3  && (elementosPorPagina == 6 || elementosPorPagina == 3)">
                        </div>
                        <div class="col" *ngIf="bloq.length < 2  && (elementosPorPagina == 6 || elementosPorPagina == 3)">
                        </div>
                    </div>
                  </div>
                </div>
                <button class="carousel-control-prev clsIconCarousel" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon " aria-hidden="true"></span>
                  <span class="visually-hidden text-secondary">Previous</span>
                </button>
                <button class="carousel-control-next clsIconCarousel" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon " aria-hidden="true"></span>
                  <span class="visually-hidden ">Next</span>
                </button>
              </div>
        </div>
    </div>
    <!-- Fin de lineas -->
    <!-- Inicio buscador  -->
    <div class="row my-4 shadow roundedcrd roundedcrdbottom" >
        <div class="col-12 titulos roundedcrd py-2 d-flex justify-content-between align-items-center">
            <div class="col-5 col-md-3">
                <mat-form-field appearance="outline" class="w-100 bg-white rounded">
                    <mat-label>Buscar</mat-label>
                    <input matInput [(ngModel)]="strBuscar" class="text-dark" >
                    <i class="bi bi-search fs-5" matSuffix></i>
                </mat-form-field>
            </div>
            <span class="fs-6 fw-bold">{{strlinea}}</span>
            <span class="fs-6 fw-bold btn-group">
                <button mat-icon-button type="button" class="btn btn-primary  position-relative btnCar" (click)="redirectToCarrito();">
                    <span class="material-symbols-outlined">
                      shopping_cart 
                    </span>
                </button>
                  <button  mat-icon-button type="button" class="btn btnCar px-0 mx-0 dropdown-toggle dropdown-toggle-split" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"><!-- class="dropdown-toggle dropdown-toggle-split"  data-bs-toggle="dropdown" aria-expanded="false"-->
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill badgCar">
                      {{intTotalItems}}
                    <span class="visually-hidden">unread messages</span> 
                    </span>
                  </button>
                  <mat-menu #menu="matMenu"  class="pttop" >
                    <div readonly class=" pt-0 col-12 text-center px-0 carritoCmp" >
                      <div class="row px-0 mx-0">
                        <div class="row px-0 mx-0 sticky-top bg-white ">
                        <div class="col-12 px-0 my-2">
                          <span class="h6 ">Sub total</span> 
                        </div>
                        <div class="col-12 p-0 m-0 d-flex justify-content-between align-items-center mb-2">
                          <div class="col-7">
                            <span class=" badgeCarrito p-2 mb-3 mt-0 fw-bold shadow-sm">{{dblSubTotal|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span *ngIf="strConexion=='DOMINICANA'">DOP</span> </span>
                          </div>
                          <div class="col">
                            <button (click)="redirectToCarrito();" class="btn shadow-sm d-flex align-items-center float-end me-2 rounded-pill border fs12">Ir al carrito</button>
                          </div>
                        </div>
                        <div class="col-12 p-0 m-0 d-flex justify-content-center">
                          <div class="col-11">
                            <hr class="mt-1 mb-0">
                          </div>
                        </div>
                      </div>
                        <div class="row mx-0 bg-light px-0 mtArt{{i}}" *ngFor="let car of arrayCarritoArts;index as i " (click)="$event.stopPropagation()" >
                          <div class="col-6">
                            <img [src]="getImageUrl(car.strArticulo)" (error)="onImageError($event)" class="img-fluid" alt="">
                          </div>
                          <div class="col-6 ">
                            <div class="row ">
                              <div class="px-1" [ngClass]="{'col-5':car.strTipoOpcion=='Si','col-12':car.strTipoOpcion=='No'}">
                                <span class="fs12">{{car.strArticulo}}</span>
                              </div>
                              <div class="col-7 px-1 text-center" *ngIf="car.strTipoOpcion=='Si'"> 
                                <span class="fs12 "><b>Opc:</b> {{car.strNombreOpcion}}</span>
                              </div>
                              <div class="col-12 text-center">
                                <span class="fs13 fw-bold">{{car.dblPrecioLista|currency}}</span>
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                              <div class="col-7 px-0">
                                <input [(ngModel)]="car.dblCantidad" (click)="fnUpdateAmountCar(car)" class="form-control" type="number">
                              </div>
                              <div class="col-4 d-flex">
                                <button class="btn btn-danger p-0 m-0 d-flex align-items-center rounded-circle" (click)="fnDeleteCar(i, car);$event.stopPropagation();">
                                  <span class="material-symbols-outlined p-1">
                                    delete
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 p-0 m-0 d-flex justify-content-center">
                            <div class="col-11">
                              <hr class="my-2">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </mat-menu>













                        <!-- <div  class=" pt-0 col-12 text-center px-0 carritoCmp dropdown-menu mhalt mhalt" style="width: 600px !important;" >
                            <div class="row px-0 mx-0">
                              <div class="row px-0 mx-0 sticky-top bg-white ">
                              <div class="col-12 px-0 my-2" readonly>
                                <span class="h6 ">Sub total</span> 
                              </div>
                              <div class="col-12 p-0 m-0 d-flex justify-content-between align-items-center mb-2">
                                <div class="col-7">
                                  <span class=" badgeCarrito p-2 mb-3 mt-0 fw-bold shadow-sm">{{dblSubTotal|currency}} MXN</span>
                                </div>
                                <div class="col">
                                  <button  class="btn shadow-sm d-flex align-items-center float-end me-2 rounded-pill border fs12" (click)="redirectToCarrito();">Ir al carrito</button>
                                </div>
                              </div>
                              <div class="col-12 p-0 m-0 d-flex justify-content-center">
                                <div class="col-11">
                                  <hr class="mt-1 mb-0">
                                </div>
                              </div>
                            </div>
                              <div class="row mx-0 bg-light px-0 mtArt{{i}}" *ngFor="let car of arrayCarritoArts;index as i " (click)="$event.stopPropagation()" >
                                <div class="col-6">
                                  <img [src]="getImageUrl(car.strArticulo)" (error)="onImageError($event)" class="img-fluid" alt="">
                                </div>
                                <div class="col-6 ">
                                  <div class="row ">
                                    <div class="px-1" [ngClass]="{'col-5':car.strTipoOpcion=='Si','col-12':car.strTipoOpcion=='No'}">
                                      <span class="fs12">{{car.strArticulo}}</span>
                                    </div>
                                    <div class="col-7 px-1 text-center" *ngIf="car.strTipoOpcion=='Si'"> 
                                      <span class="fs12 "><b>Opc:</b> {{car.strNombreOpcion}}</span>
                                    </div>
                                    <div class="col-12 text-center">
                                      <span class="fs13 fw-bold">{{car.dblPrecioLista|currency}}</span>
                                    </div>
                                  </div>
                                  <div class="row d-flex align-items-center mt-3">
                                    <div class="col-7 px-0"> 
                                      <input [(ngModel)]="car.dblCantidad" (click)="fnUpdateAmountCar(car)" class="form-control" type="number">
                                    </div>
                                    <div class="col-4 d-flex">
                                      <button class="btn btn-danger p-0 m-0 d-flex align-items-center rounded-circle" (click)="fnDeleteCar(i, car);$event.stopPropagation();">
                                        <span class="material-symbols-outlined p-1">
                                          delete
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 p-0 m-0 d-flex justify-content-center">
                                  <div class="col-11">
                                    <hr class="my-2">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                    
            </span>

        </div>
        <div class="col-12 py-3 bglineas roundedcrdbottom">
            <div class="row">
              <div class="col-12 py-2" *ngIf="arrayArtsporlinea.length==0 && strlinea==''">
                <div class="alert alert-warning text-center" role="alert">
                  Seleccione una linea
                </div>
              </div>
                <div class="col-12 py-2" *ngIf="arrayArtsporlinea.length==0 && strlinea!=''">
                  <div class="alert alert-danger text-center" role="alert">
                    No se cuenta con articulos de {{strlinea}}
                  </div>
                </div>
                <div class="col-12 mhalt" *ngIf="arrayArtsporlinea.length>0">
                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3" >
                        <div class="col-6 col-md-3 col-lg-2 mb-3 px-0 position-relative " *ngFor="let art of arrayArtsporlinea| filtersearch: strBuscar: ['strArticulo', 'strDescripcion1']" data-bs-toggle="modal" data-bs-target="#exampleModal">
                          <div class="d-flex flex-column h-100 ">
                            <div class="card position-relative shadow-sm mx-2 flex-grow-1" id="contenedor">
                                <img [src]="getImageUrl(art.strArticulo)" (error)="onImageError($event)" class="img1 zoom" alt="...">
                              <div class="card-body " id="contenedor2">
                                  <h5 class="card-title ps-2 fs2">{{art.strArticulo}}</h5>
                                  <p class="card-text fs2">{{art.strDescripcion1}}</p>
                                  <p class="precio text-center">{{art.dblPrecioLista|currency}}</p>
                                  <a id="boton" class="btn btn-primary mostrar" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal(art)">Agregar</a>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
     <!-- fin buscador  -->
</div>


<!-- Modal -->
<div class="modal fade  h-modal modalheigth" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg ">
      <div class="modal-content ">
        <div class="modal-header titulos">
          <h1 class="modal-title fs-5 mb-0 mx-auto" id="exampleModalLabel">Articulo {{objArt && objArt.strArticulo ? objArt.strArticulo : ""  }}</h1>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body ">
        <div class="row align-items-center ">
          <div class="col-12 col-lg-6">
            <h3 class="d-inline-block d-sm-none">{{objArt && objArt.strDescripcion1 ? objArt.strDescripcion1 : ""}}</h3>
            <div id="carouselExampleIndicators" class="col-12 h-75 carousel slide d-flex align-items-center" data-bs-touch="false" data-bs-interval="false">
              <div class="carousel-inner imgCar " >
                <div class="carousel-item active text-center " >
                  <img [src]="imagenUrl1" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                </div>
                <div class="carousel-item text-center">
                  <img [src]="imagenUrl2" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                </div>
                <div class="carousel-item text-center">
                  <img [src]="imagenUrl3" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                </div>
                <div class="carousel-item text-center">
                  <img [src]="imagenUrl4" (error)="onImageError($event)"  class="zoom1" alt="Product Image">
                </div>
                <div class="carousel-item text-center">
                  <img [src]="imagenUrl5" (error)="onImageError($event)"  class="zoom1" alt="Product Image">
                </div>
              </div>
              <button class="carousel-control-prev bg-transparent" style="color: black !important;" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="material-symbols-outlined">
                  arrow_back_ios
                  </span>
              </button>
              <button class="carousel-control-next bg-transparent" style="color: black !important;" type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next">
                <span class="material-symbols-outlined">
                  arrow_forward_ios
                  </span>
              </button>
            </div>
            <div class="col-12 product-image-thumbs mt-0">
              <div class="product-image-thumb active col" aria-current="true" aria-label="Slide 1" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"  >
                <img [src]="imagenUrl1" (error)="onImageError($event)" alt="Product Image">
              </div>
              <div class="product-image-thumb col" *ngIf="imagenUrl2!='False'" aria-current="true" aria-label="Slide 2" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"  >
                <img [src]="imagenUrl2" (error)="onImageError($event)" alt="Product Image">
              </div>
              <div class="product-image-thumb col" *ngIf="imagenUrl3!='False'" aria-current="true" aria-label="Slide 3" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"  >
                <img [src]="imagenUrl3" (error)="onImageError($event)"  alt="Product Image">
              </div>
              <div class="product-image-thumb col" *ngIf="imagenUrl4!='False'" aria-current="true" aria-label="Slide 4" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"  >
                <img [src]="imagenUrl4" (error)="onImageError($event)"  alt="Product Image">
              </div>
              <div class="product-image-thumb col" *ngIf="imagenUrl5!='False'" aria-current="true" aria-label="Slide 5" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"  >
                <img [src]="imagenUrl5" (error)="onImageError($event)"  alt="Product Image">
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 modalheigth1">
            <div class="row">
              <div class="col-12">
                <h2 class="my-3 fw-bolder">{{objArt && objArt.strArticulo ? objArt.strArticulo : ""  }}</h2>
                <p>{{objArt && objArt.strDescripcion1 ? objArt.strDescripcion1 : ""}}</p>
              </div>
           
            </div>
            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-12 col-md-6" [hidden]="objArt.strTipoOpcion == 'No'">
                <div class="col-12">
                  <h3 class="fw-bolder mb-1">
                    Opcion
                  </h3>
                </div>
                <div class="col-12">
                  <select class="form-select  shadow-sm" [(ngModel)]="objArt.strOpcion" aria-label=".form-select-lg example" (change)="onOpcionSeleccionada(objArt.strOpcion,objArt)">
                    <option *ngFor="let item of objArt.arrayOpciones; let i = index;"  [ngValue]="item.strOpcion"> 
                     <span class="me-2">
                      <b> {{item.strOpcion}}</b>
                     </span>
                     &nbsp;
                     &nbsp;
                     <span>
                      {{item.strNombreOpcion}}
                     </span> 
                    </option>
                  </select>
                </div>
                
              </div> 
              <div class=""  [ngClass]="{'col-12 col-md-6':objArt.strTipoOpcion != 'No','col-10':objArt.strTipoOpcion == 'No'}">
                <div class="ms-auto col-10">
                  <h3 class="fw-bolder mb-1">
                    Precio
                  </h3>
                </div> 
                <div class="col-12">
                  <select [(ngModel)]="objArt.dblPrecioLista" class="form-select fs-8" aria-label="Default select example">
                    <option *ngIf="intPrecioPolitica>0"  value="{{intPrecioPolitica}}">PP {{intPrecioPolitica|currency}}</option>
                    <option *ngIf="intPrecioPlantilla>0"  value="{{intPrecioPlantilla}}">PLA {{intPrecioPlantilla|currency}}</option>
                    <option *ngIf="intPrecioLista>0"  value="{{intPrecioLista}}">PL {{intPrecioLista|currency}}</option>
                    <option *ngIf="intPrecio>0"  value="{{intPrecio}}">P1 {{intPrecio|currency}}</option>
                    <option *ngIf="intPrecio2>0" value="{{intPrecio2}}">P2 {{intPrecio2|currency}}</option>
                    <option *ngIf="intPrecio3>0" value="{{intPrecio3}}">P3 {{intPrecio3|currency}}</option>
                    <option *ngIf="intPrecio4>0" value="{{intPrecio4}}">P4 {{intPrecio4|currency}}</option>
                  </select>
               <!--  <label for="" class="fw-bolder fs-4 w-100 text-dark text-center dblprecio">
                 {{ objArt.dblPrecioLista|currency}}
                </label> -->
              </div>
              </div>
              <!-------Consumible-------->
              <div class=""  [ngClass]="{'col-12 col-md-6':objArt.objConsumible.strTipoOpcion != 'No','col-10':objArt.objConsumible.strTipoOpcion == 'No'}">
                <div class="ms-auto col-10">
                  <h3 class="fw-bolder mb-1">
                    Consumible
                  </h3>
                </div> 
                <div class="col-12">
                  <select class="form-select fs-8" aria-label="Default select example" [(ngModel)]="objArt.objConsumible" (change)="fnCambiaConsumible(objArt.objConsumible)">
                    <option *ngFor="let consumible of arrayConsumible" [ngValue]="consumible">({{consumible.strConsumible }})  {{consumible.strNombreConsumible}}</option>
                  </select>
          <!--****{{objArt.objConsumible   | json }}**   {{objArt.arrayConsumible | json}}  -->  
              </div>
              </div>
               <!--opcion consumible-->
               <div class="col-12 col-md-6" [hidden]="objArt.objConsumible.strTipoOpcion== 'No'">
                <div class="col-12">
                  <h3 class="fw-bolder mb-1">
                    Opcion Consumible
                  </h3>
                </div>
                <div class="col-12">
                  <select class="form-select  shadow-sm" [(ngModel)]="objArt.objOpcionConsumible" aria-label=".form-select-lg example" > <!--(change)="onOpcionSeleccionada(objArt.strOpcion,objArt)"-->
                    <option *ngFor="let item of arrayConsumibleOpcion; let i = index;"  [ngValue]="item"> 
                     <span class="me-2">
                      <b> ({{item.strOpcion}})</b>
                     </span>
                  
                     <span>
                      {{item.strNombreOpcion}}
                     </span> 
                    </option>
                  </select>
                </div>
                
              </div> 
            </div>
            <div class="row mt-4 d-flex align-items-center">
              <div class="col-12 col-sm-6">
                <div class="col-10 mx-auto">
                  <div class="input-group rounded-pill">
                    <button class="btn  btnCirs" (click)="fnAdd_Rest_Amount(objArt,'rest');" type="button">-</button>
                      <input type="text" value="0" [(ngModel)]="objArt.dblCantidad" class="form-control text-center" placeholder="" aria-label="Recipient's username with two button addons">
                    <button class="btn  btnCire" (click)="fnAdd_Rest_Amount(objArt,'add');" type="button">+</button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 ">
                  <div class="col-10 mx-auto" >
                    <button [disabled]="spinerCarrito || !objArt.dblPrecioLista || objArt.dblPrecioLista < 1" (click)="fnAddToCarr(objArt);" class="w-100 position-relative btn btnCar py-2 fs13 rounded-pill d-flex align-items-center justify-content-center shadow-sm">
                      <span *ngIf="!spinerCarrito" class="fs4">Agregar a Comodato</span> 
                     <span style="color:#FFEF92" *ngIf="spinerCarrito">.</span> 
                     <div class="pt-1 position-absolute top-50 start-50 translate-middle" *ngIf="spinerCarrito">
                      <div class="spinner-border text-primary " role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                     </div>
                    </button>
                  </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="accordion accordion-flush " id="accordionFlushExample">
                  <div class="accordion-item py-1">
                    <h2 class="accordion-header mb-0" id="flush-headingOne">
                      <button (click)="fntraerExistencias(objArt);" class="fw-bold acordeon accordion-button collapsed border rounded px-3 py-1 fs12" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Existencias
                      </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body pt-2 pb-0 bgbodyacordeon">
                        <div class="row">
                          <div class="col-12 table-responsive mx-0 px-0 mb-2 border rounded">
                            <table class="table table-bordered bg-white fixed-header-table">
                              <thead>
                                <tr class="">
                                  <th class="text-center  text-white">Almacen</th>
                                  <th class="text-center  text-white">Nombre</th>
                                  <th class="text-center text-white">Disponibles</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let x of arrayExistentes; let ind = index">
                                  <td>{{x.strAlmacen}}</td>
                                  <td>{{x.strAlmacenNombre}}</td>
                                  <td><b>{{x.dblDisponible}}</b></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="modal-footer titulos py-1">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>