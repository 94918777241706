import { Component, Input, Output, EventEmitter, ChangeDetectorRef, TemplateRef, ViewChildren, QueryList, ElementRef, HostListener } from '@angular/core'
import { ScreenStateService } from '../servicios/styles/screenState.service'


import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

@Component({
    selector: 'dataTable',
    templateUrl: './dataTable.component.html',
    styleUrls: ['./dataTable.component.css']
})

export class dataTable {
    currentSortColumn: string = ''  // Para rastrear la columna actualmente ordenada
    sortOrder: 'asc' | 'desc' | '' = ''  // Para rastrear el orden actual
    icon_order: number = 0
    column_selected

    public items_table_filtrado: any = []

    nextColumn: any = null
    resizing = false
    resizingColumn = null
    initialX: number = null
    initialWidth: number = null

    tooltipDisabled: boolean = false

    paginaActual: number = 0
    elementosPorPagina: number = null

    //Desde el componente Padre

    
    @Input() footer_paginador: boolean = true
    @Input() filter: boolean = false
    @Input() screen_expand: boolean = false
    @Input() export: any[] = [false, '']
    
    displayedTitles: string[] = [];

    @Input() data_table: any[] = []
    @Input() template: TemplateRef<any>
    @Input() footer_column: TemplateRef<any>
    @Input() top_template: TemplateRef<any>

    @Input() registros_por_pagina
    @Input() rango_registros
    @Input() data_items: any[] = []
    @Output() callback_icons = new EventEmitter<void>()
    public items_table: Array<[]> = []
    public original_items_table: any[] = []
    @ViewChildren('miInput') inputs: QueryList<ElementRef>;

    // obtenerValoresInputs() {
    //   this.inputs.forEach((input: ElementRef, index: number) => {
    //     const valor = input.nativeElement.value;
    //     console.log(`Valor del input ${index + 1}: ${valor}`);
    //   });
    // }

    public value_filter
    public expand_screen: boolean = false

    constructor(
        public screen: ScreenStateService,
        private cdr: ChangeDetectorRef
    ) {
        this.rango_registros = (this.rango_registros ? this.rango_registros : [20, 100, 500])
        this.value_filter = ''

    }

    ngOnInit(){
        
        // this.mostrarResultados()
    }

    ngOnChanges() {

        if(this.value_filter != ''){
            this.applyFilter()
        }
        // console.log('data_items: ', this.data_items);
        
        this.elementosPorPagina = this.elementosPorPagina != null ? this.elementosPorPagina : this.registros_por_pagina ? this.registros_por_pagina : this.rango_registros[0]

        // Solo agregamos el valor si no está presente en el array

        const encontrado = this.rango_registros.find((numero) => {
            return numero === this.elementosPorPagina
          });

        if (encontrado === 0) {
            this.rango_registros.push(this.elementosPorPagina)
            this.rango_registros.sort((a, b) => a - b)
        }


        this.mostrarResultados()
        // console.log('rango_registros: ', this.rango_registros)

    }
    

  @HostListener('document:fullscreenchange', ['$event'])
  fullscreenHandler(event: Event) {
    
    // Verifica si la aplicación está en pantalla completa
    if (!document.fullscreenElement) {
        this.expand_screen = false
    }
  }


    solo_numeros(e): void {
        let input_value = e.target.value        
        if (isNaN(Number(input_value + String.fromCharCode(e.charCode)))) {
          e.preventDefault();
        }
    }
    
    desmarcar_checkboxs(){
        this.data_table.forEach(data => {
            if(data.checkbox){
                data.checkbox = false
            }
        });
    }


    exportToExcel() {
        const MAX_WIDTH = 60

        // Crear un array de arrays, cada array interno es una fila
        const ws_data: any[][] = []

        // Agregar headers
        ws_data.push(this.data_table.map(dt => dt.title))

        // Agregar contenido
        for (const item of this.data_items) {
            const row = []
            this.data_table.forEach(header => {
                



                // if (header.value.includes('|')) {
                // } else {
                //     row.push(item[header.value])
                // }




                // console.log('header.value: ', header.value);
                if(header.value != undefined){
                    if (header.value.includes('|')) {
                        // Si hay un pipe, entonces combinamos los valores
                        const keys = header.value.split('|');
                        const combinedValue = keys.map(key => item[key]).join(' - ');
                        row.push(combinedValue);
                    }
                
                    else if (header.value.includes('*') || header.value.includes('-') || header.value.includes('+') || header.value.includes('/')) {
                        // Utiliza una expresión regular para dividir el valor en partes (variables y operadores)
                        const parts = header.value.split(/([\+\-\*\/])/).map(part => part.trim());
                
                        // Inicializa un arreglo para mantener las partes finales de la expresión
                        const expressionParts = [];
                
                        // Itera a través de las partes y evalúa las expresiones matemáticas
                        for (let i = 0; i < parts.length; i++) {
                            if (parts[i] === '*' || parts[i] === '-' || parts[i] === '+' || parts[i] === '/') {
                                expressionParts.push(parts[i]);
                            } else {
                                // Evalúa la variable utilizando eval
                                try {
                                    const variableValue = parseFloat(eval(item[parts[i]]));
                
                                    if (!isNaN(variableValue)) {
                                        expressionParts.push(variableValue);
                                    } else {
                                        // console.log(`El valor de ${parts[i]} no es un número válido.`);
                                        // Manejar el caso en el que una variable no es un número válido
                                    }
                                } catch (error) {
                                    // console.log(`No se pudo evaluar ${parts[i]}.`);
                                    // Manejar el caso en el que no se pudo evaluar una variable
                                }
                            }
                        }
                
                        // Evalúa la expresión completa y almacena el resultado en row
                        try {
                            let result = eval(expressionParts.join(' '));

                            if (!isNaN(result)) {
                                result = parseFloat(result.toFixed(0));
                                row.push(result);
                                // console.log('result: ', result);
                            } else {
                                // console.log(`La expresión no produce un valor numérico válido.`);
                            }

                        } catch (error) {
                            // console.log(`No se pudo evaluar la expresión completa.`);
                            // Manejar el caso en el que no se pudo evaluar la expresión completa
                        }
                    } else {
                        row.push(item[header.value]);
                        // Si no se encuentra ningún operador válido, agregamos el valor directamente a row
                    }
                }
            })
            ws_data.push(row)
        }

        // Calcular el ancho de caracteres máximo para cada columna considerando el valor de MAX_WIDTH
        const colWidths = ws_data[0].map((_, i) =>
            Math.min(MAX_WIDTH, Math.max(...ws_data.map(row => (`${row[i]}`).toString().length)))
        )

        // Crear una hoja de trabajo y agregar los datos
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(ws_data)

        // Asignar esos anchos a la hoja de trabajo
        ws['!cols'] = colWidths.map(maxWidth => ({ wch: maxWidth }))
        // Asignar el alto de la primera fila (encabezados)
        const rowCount = ws_data.length

        // Inicialmente configura la altura para el encabezado a 50
        const rowHeights = [{ hpt: 50 }]

        // Agrega alturas de 25 para las filas restantes
        for (let i = 1; i < rowCount; i++) {
            rowHeights.push({ hpt: 25 })
        }

        ws['!rows'] = rowHeights

        // Crear un libro de trabajo y agregar la hoja de trabajo
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

        // Guardar el archivo
        XLSX.writeFile(wb, this.export[1] + '.xlsx')
    }

    expand_table() {
        this.expand_screen = !this.expand_screen

        const document: any = window.document
        const documentElement = document.documentElement

        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {

            this.expand_screen = true

            if (documentElement.requestFullscreen) {
                documentElement.requestFullscreen()
            } else if (documentElement.mozRequestFullScreen) {
                documentElement.mozRequestFullScreen()
            } else if (documentElement.webkitRequestFullscreen) {
                documentElement.webkitRequestFullscreen()
            } else if (documentElement.msRequestFullscreen) {
                documentElement.msRequestFullscreen()
            }
        } else {

            this.expand_screen = false
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        }        
    }

    applyFilter(): void {

        this.paginaActual = 0
        // Convertir el filtro a minúsculas para una búsqueda insensible a mayúsculas/minúsculas
        let filterValue = this.value_filter.trim().toLowerCase();

        this.items_table_filtrado = this.data_items.filter(item => {
            // Iterar a través de todas las propiedades del objeto para ver si alguna coincide con el filtro
            for (let key in item) {
                if (item.hasOwnProperty(key) && String(item[key]).toLowerCase().includes(filterValue)) {
                    return true; // Si alguna propiedad coincide, se incluye en los resultados filtrados
                }
            }
            // console.log('filterValue: ', filterValue);

            return false; // Si ninguna propiedad coincide, no se incluye en los resultados filtrados
            
        });
        
        this.mostrarResultados()
    }

    texto_filtrado(value: any): any {
        if(this.value_filter != ''){    
            const filterValue = this.value_filter.trim().toLowerCase();
        
            // Convertir el valor a cadena para la comparación
            const stringValue = String(value).toLowerCase();
        
            // Verificar si la cadena del valor incluye el filtro
            return stringValue.includes(filterValue);
        }
    }   

    clearFilter() {
        this.value_filter = ''
        this.applyFilter()
    }

    exportTable() {
        //   this.export_table_res.emit()
    }

    mostrar_datos(items_data_table) {
        // console.log('items_data_table: ', items_data_table);
        

        let items = []

        const widthPercentage = 100 / this.data_table.length
        const calculatedWidth = widthPercentage < (150 / window.innerWidth * 100) ? '150px' : `${widthPercentage}%`

        for (let singleResult of items_data_table) {
            let res = { ...singleResult } // Aquí clonamos singleResult a res.

            this.data_table.forEach(header => {

                let style_header = { width: '', minWidth: '', maxWidth: '' }

                if (header.styles) {
                    // Si header.styles.width no existe o su valor es menor que 150px, asigna el valor calculado.
                    header.styles.width = header.styles.width ? ((parseInt(header.styles.width) < 150) ? calculatedWidth : header.styles.width) : calculatedWidth
                    // header.styles.minWidth = header.styles.minWidth ? ((parseInt(header.styles.minWidth) < 150) ? '150px' : header.styles.minWidth) : calculatedWidth
                    // header.styles.maxWidth = header.styles.maxWidth ? ((parseInt(header.styles.maxWidth) < 150) ? '' : header.styles.maxWidth) : ''

                    style_header = { ...header.styles }

                } else {
                    // Si header.styles no existe, lo crea y le asigna valores calculados.
                    style_header.width = calculatedWidth
                    style_header.minWidth = '150px'
                    style_header.maxWidth = ''

                    header.styles = { ...style_header }
                    // console.log('calculatedWidth:', calculatedWidth, 'header:', header)
                }

                header.style_header = { ...style_header }
                header.style_header = style_header


                // console.log('header.value: ', header.value);
                if(header.value != undefined){
                    if (header.value.includes('*') || header.value.includes('-') || header.value.includes('+') || header.value.includes('/') || header.value.includes('|')) {

                        if (header.value.includes('|')) {
                            // Si hay un pipe, entonces combinamos los valores
                            const keys = header.value.split('|')
                            let combinedValue = keys.map(key => singleResult[key]).join(' - ')
                            res[keys.join('')] = combinedValue
                        } else {

                            // Utiliza una expresión regular para dividir el valor en partes (variables y operadores)
                            const parts = header.value.split(/([\+\-\*\/])/).map(part => part.trim());

                            // Inicializa un arreglo para mantener las partes finales de la expresión
                            const expressionParts = [];

                            // Itera a través de las partes y evalúa las expresiones matemáticas
                            for (let i = 0; i < parts.length; i++) {
                                if (parts[i] === '*' || parts[i] === '-' || parts[i] === '+' || parts[i] === '/') {
                                    expressionParts.push(parts[i]);
                                } else {
                                    // Evalúa la variable utilizando eval
                                    try {
                                        const variableValue = parseFloat(eval(`singleResult.${parts[i]}`));
                                        //   const variableValue = eval(`singleResult.${parts[i]}`);
                                        if (!isNaN(variableValue)) {
                                            expressionParts.push(variableValue);
                                            // console.log('expressionParts: ', expressionParts);

                                        } else {
                                            // console.log(`El valor de ${parts[i]} no es un número válido.`);
                                            // Manejar el caso en el que una variable no es un número válido
                                        }
                                    } catch (error) {
                                        // console.log(`No se pudo evaluar ${parts[i]}.`);
                                        // Manejar el caso en el que no se pudo evaluar una variable
                                    }
                                }
                            }

                            // Evalúa la expresión completa y almacena el resultado en res
                            try {
                                const result = eval(expressionParts.join(' '));
                                res[header.value] = result;
                            } catch (error) {
                                // console.log(`No se pudo evaluar la expresión completa.`);
                                // Manejar el caso en el que no se pudo evaluar la expresión completa
                            }
                        }
                    } else {
                        // console.log('No se encontró ningún operador válido en el valor');
                        // Si no se encuentra ningún operador válido, puedes manejarlo de manera adecuada
                        // res[keys.join('')] = combinedValue;
                    }
                }
            })
            // console.log('res: ', res);
            items.push(res)
        }
    
        this.items_table = items        
        this.original_items_table = [...this.items_table]
    }

    startResize(event: MouseEvent, data: any, index: number) {
        this.tooltipDisabled = true;
        this.resizing = true
        this.resizingColumn = data
        this.nextColumn = this.data_table[index + 1] // Obtén la columna contigua

        // Guardamos la posición inicial y el ancho inicial
        this.initialX = event.clientX
        this.initialWidth = (event.target as HTMLElement).parentElement.offsetWidth

        if (this.items_table.length > 0) {
            document.addEventListener('mousemove', this.doResize.bind(this))
            document.addEventListener('mouseup', this.stopResize.bind(this))
        }
    }

    doResize(event: MouseEvent) {

        
        if (this.resizing && this.resizingColumn) {
            const movementX = event.clientX - this.initialX
            let maxWidth = this.initialWidth + movementX

            // Si el nuevo ancho excede 400px, limitarlo a 400px
            if (maxWidth > 400) {
                maxWidth = 400
            }

            // Si el nuevo ancho es menos de 100px, ajustarlo a 100px
            if (maxWidth < 100) {
                maxWidth = 100
            }

            // Establece el nuevo ancho
            if (!this.resizingColumn.styles) {
                this.resizingColumn.styles = {}
            }

            this.resizingColumn.styles.width = `${maxWidth}px`

            if (!this.resizingColumn.style_header) {
                this.resizingColumn.style_header = {}
            }

            this.resizingColumn.style_header.width = `${maxWidth}px`
        }
    }

    stopResize(event: MouseEvent) {
        
        this.tooltipDisabled = false;
        this.resizing = false
        this.resizingColumn = null
        this.nextColumn = null
        this.initialX = null // reseteamos la posición inicial
        this.initialWidth = null // reseteamos el ancho inicial
        document.removeEventListener('mousemove', this.doResize.bind(this))
        document.removeEventListener('mouseup', this.stopResize.bind(this))
    }

    doubleClickResize(event: MouseEvent, data: any) {

        if (this.items_table.length > 0) {

            this.resizingColumn = data

            let targetElement = (event.target as HTMLElement).parentElement

            // Definir un maxWidth específico
            let maxWidth = 400  // Ajusta según tus necesidades
            let minWidth = 150  // Ajusta según tus necesidades
            let widthValue = parseInt(targetElement.style.width.replace("px", ""), 10)

            // Ajustar el ancho del elemento al valor maxWidth
            targetElement.style.width = `${widthValue < maxWidth ? maxWidth : minWidth}px`


            // Actualizar tus datos
            if (!this.resizingColumn.styles) {
                this.resizingColumn.styles = {}
            }

            this.resizingColumn.styles.width = `${widthValue < maxWidth ? maxWidth : minWidth}px`

            // Asumiendo que también quisiste revisar la propiedad style_header
            if (!this.resizingColumn.style_header) {
                this.resizingColumn.style_header = {}
            }

            this.resizingColumn.style_header.width = `${widthValue < maxWidth ? maxWidth : minWidth}px`
        }
    }

    cambiarPagina(pagina: number): void {
        this.paginaActual = pagina
        this.mostrarResultados()
        // console.log('Pagina: ', this.paginaActual)
    }
    actualizarElementosPorPagina() {
        this.cambiarPagina(0)
    }

    mostrarResultados() {
        const inicio = this.inicio()
        const fin = this.fin()
        let items_data_table
        if(this.value_filter === ''){
            items_data_table = this.data_items.slice(inicio, fin)
            
        }else{            
            items_data_table = this.items_table_filtrado.slice(inicio, fin)
        }
        this.mostrar_datos(items_data_table)
        // console.log('Inici: ', this.inicio(), 'Fin: ', this.fin())
    }

    inicio(): number {
        return this.paginaActual * this.elementosPorPagina
    }

    fin(): number {
        if(this.value_filter != ''){
            return Math.min(this.inicio() + (this.elementosPorPagina), this.items_table_filtrado.length)
        }else{
            return Math.min(this.inicio() + (this.elementosPorPagina), this.data_items.length)
        }
    }

    esUltimaPagina(): boolean {
        if(this.value_filter != ''){
            return this.fin() >= this.items_table_filtrado.length
        }else{
            return this.fin() >= this.data_items.length
        }
    }

    sortData(column: string, column_id): void {
        if (this.column_selected !== column_id) {
            // Si es una nueva columna, reinicia todo.
            this.column_selected = column_id
            this.currentSortColumn = column
            this.sortOrder = 'asc'
            this.icon_order = 1
        } else {
            // Si es la misma columna, cambia el orden y el ícono o reinicia el ícono.
            if (this.sortOrder === 'asc' && this.icon_order === 1) {
                this.sortOrder = 'desc'
                this.icon_order = 2
            } else if (this.sortOrder === 'desc' && this.icon_order === 2) {
                this.sortOrder = ''  // No hay un orden específico
                this.icon_order = 0  // Desactivar el ícono
                this.items_table = [...this.original_items_table]  // Restablecer a orden original
            } else if (this.icon_order === 0) {
                this.sortOrder = 'asc'
                this.icon_order = 1
            }
        }

        this.items_table.sort((a, b) => {
            const valA = a[column]
            const valB = b[column]

            let numA = Number(valA)
            let numB = Number(valB)

            // Comprobamos si valA y valB son números o representaciones de números
            if (!isNaN(numA) && !isNaN(numB)) {
                return this.sortOrder === 'asc' ? numA - numB : numB - numA
            } else if (typeof valA === 'string' && typeof valB === 'string') {
                return this.sortOrder === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA)
            }
            return 0  // En caso de que no sean comparables, se consideran iguales
        })
        // console.log('icon_order: ', this.icon_order, 'column_selected: ', this.column_selected)
        this.cdr.detectChanges()
    }

    // scroll_up() {
        
    //     const container = document.querySelector('.eco_simple_table:focus');
    //     if (container) {
    //       if (container.scrollTop > 150) {
    //         container.scrollTo({
    //           top: 0,
    //           behavior: 'smooth'
    //         });
    //       } else {
    //         container.scrollTo({
    //           top: container.scrollHeight,
    //           behavior: 'smooth'
    //         });
    //       }
    //     }
        
    // }

    // scroll_show_btn() {
    //     let btn_scroll_up = document.getElementById('btn_scroll_up')

    //     const container = document.querySelector('.eco_simple_table');
    //     if (container.scrollTop > 150) {
    //         btn_scroll_up.style.transform = "rotate(180deg)";
    //         btn_scroll_up.style.transition = "transform 0.5s ease";
    //       } else {
    //         btn_scroll_up.style.transform = "rotate(0deg)";
    //       }
          
    // }
  

}