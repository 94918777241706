<div class="container-fluid mt-4">
    <div class="row justify-content-center">
        <div class="col-12 text-center mt-2">
            <h1 class="fw-bold text-decoration-underline">Tablero <span class="tittle">Leads</span></h1>
        </div>
        <div class="col-12 col-md-11">
            <div class="row bg-white bordertop borderbott shadow pb-2 justify-content-center">
                <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
                    <span class="material-symbols-outlined">
                        content_paste_search
                    </span>
                    <span class="fw-bold">
                        Filtros
                    </span>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
                        <mat-select [(ngModel)]="strGerente" class="">
                            <mat-option value=""> --Todos --</mat-option>
                            <mat-option *ngFor="let x of arrayGerentes"
                                [value]="x.strGerente">{{x.strNombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                    [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                        <mat-select [(ngModel)]="strSucursal" class="">
                            <mat-option value=""> --Todas --</mat-option>
                            <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente "
                                [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                    [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
                        <mat-select [(ngModel)]="strAgente" class="">
                            <mat-option value=""> --Todos --</mat-option>
                            <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
                                [value]="x.strAgente">{{x.strNombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
                    [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
                        <mat-select [(ngModel)]="intPeriodo" class="">
                            <mat-option *ngFor="let x of arrayPeriodo"
                                [value]="x.strPeriodo">{{x.strNombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"
                    [ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }">
                    <mat-form-field appearance="outline" class="bg-white rounded w-100">
                        <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                        <mat-select [(ngModel)]="intEjercicio">
                            <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                                {{e.ejercicio}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
                    [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }">
                    <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true"
                        (mouseleave)="isHoveredButton = false"> <!-- (click)="fnGetPorContactar()" -->
                        <i class="bi  p-1 fs-4"
                            [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2 col-sm-4 text-left d-flex align-items-center">
            <!-- <div class="rectangulo">
                {{strTipo}}
            </div> -->
            <button class="btn bgcircleadd  ms-2 px-2 py-1 shadow-sm d-flex justify-content-center align-items-center"
                (click)="limpiarform();" data-bs-toggle="modal" data-bs-target="#nuevoLead">
                <span class="material-symbols-outlined">
                    add
                </span> <span>Agregar</span>
            </button>
        </div>
        <!--  <div class="col-md-4 col-sm-8 d-flex align-items-center">
            <h3>
                <span class=" fw-bold">OBJETIVO ANUAL:</span>
                <span class="badge bg-success mx-2 fw-bold">2,000,000 &nbsp;/&nbsp;{{ intGlobal | currency}}</span>
            </h3>
        </div> -->
        <div class="col-md-6 col-sm-12 d-flex align-items-center ms-auto">
            <div class="ms-auto d-flex align-items-center">
                <div class="dropdown">
                    <button class="btn btn-outline-secondary  dropdown-toggle justify-content-center" type="button"
                        id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-filter"></i> Filtros
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <form>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox1" name="radioGroup"
                                    [checked]="checkboxes['conDemora']" (change)="onCheckboxChange('conDemora')">
                                <label for="checkbox1" class="m-auto">Con demora</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox2" name="radioGroup" [checked]="checkboxes['hoy']"
                                    (change)="onCheckboxChange('hoy')">
                                <label for="checkbox2" class="m-auto">Hoy</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox3" name="radioGroup" [checked]="checkboxes['manana']"
                                    (change)="onCheckboxChange('manana')">
                                <label for="checkbox3" class="m-auto">Mañana</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox4" name="radioGroup"
                                    [checked]="checkboxes['estaSemana']" (change)="onCheckboxChange('estaSemana')">
                                <label for="checkbox4" class="m-auto">Esta semana</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox5" name="radioGroup"
                                    [checked]="checkboxes['esteMes']" (change)="onCheckboxChange('esteMes')">
                                <label for="checkbox5" class="m-auto">Este mes</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox6" name="radioGroup"
                                    [checked]="checkboxes['futuros']" (change)="onCheckboxChange('futuros')">
                                <label for="checkbox6" class="m-auto">Futuros</label>
                            </div>
                        </form>
                    </div>
                </div>
                <input type="text" class="form-control w-100  mx-2" placeholder="Buscar" [(ngModel)]="filterPost">
                <div class="btn-group ms-2 me-3" role="group" aria-label="Basic example">
                    <button type="button" class="btn  btn-sm d-flex flex-column no-btn p-0"
                        [ngClass]="{'active ': activeButton === 'calendario'}" (click)="cambiarVista('calendario')">
                        <i class="m-auto fs-3 bi"
                            [ngClass]="{'bi-calendar-date-fill active text-primary': activeButton === 'calendario',' bi-calendar-date': activeButton != 'calendario'}"></i>
                        <span class="mx-auto fw-lighter"
                            [ngClass]="{'fw-bold': activeButton === 'calendario'}">Calendario</span>
                    </button>
                    <button type="button" class="btn  btn-sm d-flex flex-column no-btn p-0 mx-3"
                        [ngClass]="{'active ': activeButton === 'lista'}" (click)="cambiarVista('lista')">
                        <i class="m-auto fs-3 bi"
                            [ngClass]="{'bi-list-check active text-primary': activeButton === 'lista','bi-list': activeButton != 'lista'}"></i>
                        <span class="mx-auto fw-lighter" [ngClass]="{'fw-bold': activeButton === 'lista'}">Lista</span>
                    </button>
                    <button type="button" class="btn  btn-sm d-flex flex-column active no-btn p-0"
                        [ngClass]="{'active ': activeButton === 'cards'}" (click)="cambiarVista('cards')">
                        <i class="m-auto fs-3 bi"
                            [ngClass]="{'bi-credit-card-2-front-fill active text-primary': activeButton === 'cards','bi-credit-card-2-front': activeButton != 'cards'}"></i>
                        <span class="mx-auto fw-lighter" [ngClass]="{'fw-bold': activeButton === 'cards'}">Cards</span>
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="row mt-4" *ngIf=" activeButton== 'cards'">
        <div class="col-lg-3 col-md-6 col-sm-12 border-end " *ngIf="strTipo == 'lead'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <div class="circleCantidadLeads mx-0">
                                <span class="textCantidad">{{intTotal_por_contactar}}</span>
                            </div>
                            <span class=" text-sm text-bold ms-2 fw-bold text-nowrap" style="font-size: 11.5px;">POR
                                CONTACTAR 2</span>
                        </div>
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <span class="ms-auto">{{dblMontoPorContactar |currency:'':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado w-100 m-auto"></div>
                </div>
                <!-- <div class="row mt-1">
                    <div class="col-6 col-lg d-flex  ope-0">
                        <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                            <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                            <b>{{intTotalServiciosPC | currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                    <div class="col-6 col-lg d-flex  order-xxl-3 pe-0">
                        <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                            <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i> <b>{{intTotalMaterialesPC |
                                currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                </div> -->
            </div>
            <div class="row columna-con-scroll px-2 pt-1 pb-2 mt-2">
                <div class="card card-sin-datos mx-auto px-0"
                    *ngIf="((array_por_contactar | filtersearch: filterPost : ['strAgente', 'strNombreEmpresa']).length === 0) ">
                    <div class="card-header d-flex justify-content-center pt-0">
                        <span class="text-bold text-sm ">SIN DATOS</span>
                    </div>
                </div>
                <!-- <div class="col-12 d-flex justify-content-center py-3"
                    *ngIf="array_por_contactar.length > 0 && !spinnerPC">
                    <mat-spinner strokeWidth="6" diameter="40"></mat-spinner>
                </div> -->
                <div class="card mx-auto mt-3 px-0 py-0"
                    *ngFor="let item of array_por_contactar|filtersearch:filterPost:['strAgente','strNombreEmpresa']; let i = index"
                    (click)="fnDetalleLead(item)">
                    <div class="card-header d-flex ps-2 pe-2">
                        <div class="me-auto">
                            <span>Folio:</span><span class="fw-bold mx-1">{{item.intIdLeads}}</span>
                        </div>
                        <div class="ms-auto">
                            <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                        </div>
                    </div>
                    <div class="card-body container-fluid">
                        <div class="row">
                            <div class="col-4 d-flex px-1">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center d-flex px-1">
                                <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                            </div>
                            <!-- <div class="col-2 d-flex">
                                <i class="far fa-star mx-auto mt-0"></i> 
                                 <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span> 
                            </div> -->
                            <div class="col-12 d-flex mt-1 px-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <!--  <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> <span class="m-auto text-bold" style="font-size: 10px;"> &nbsp;0</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <i class="far fa-star me-auto mx-3" *ngIf="item.strTipo == 'GANADO'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0">
                                <i class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0">
                                <i class="fas fa-bomb text-danger mx-1"></i>ATRASADO
                            </span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 border-end" *ngIf="strTipo == 'lead'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <div class="circleCantidadLeads mx-0">
                                <span class="textCantidad">{{intTotal_presentacion}}</span>
                            </div>
                            <span class=" text-sm text-bold ms-2 fw-bold" style="font-size: 11.5px;">PRESENTACIÓN</span>
                        </div>
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <span class="ms-auto">{{dblMontoPresentacion |currency:'':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado2 w-100 m-auto"></div>
                </div>
                <!--  <div class="row mt-1">
                    <div class="col-6 col-lg d-flex  ope-0">
                        <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                            <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i> <b>{{intTotalServiciosP
                                | currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                    <div class="col-6 col-lg d-flex  order-xxl-3 pe-0">
                        <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                            <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i> <b>{{intTotalMaterialesP |
                                currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                </div> -->
            </div>
            <div class="row columna-con-scroll px-2 pb-2 pt-1 mt-2">
                <div class="card card-sin-datos mx-auto px-0" *ngIf="arrayPresentacion.length == 0">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center py-3" *ngIf="arrayPresentacion.lenght > 0 && spinnerP">
                    <mat-spinner strokeWidth="6" diameter="40"></mat-spinner>
                </div>
                <div class="card mx-auto mt-3 px-0 py-0"
                    *ngFor="let item of arrayPresentacion| pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item)">
                    <div class="card-header d-flex ps-2 pe-2">
                        <div class="me-auto">
                            <span>Folio:</span><span class="fw-bold mx-1">{{item.intIdLeads}}</span>
                        </div>
                        <div class="ms-auto">
                            <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                        </div>
                    </div>
                    <div class="card-body container-fluid">
                        <div class="row">
                            <div class="col-4 d-flex px-1">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center d-flex px-1">
                                <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                            </div>
                            <!-- <div class="col-2 d-flex">
                                <i class="far fa-star mx-auto mt-0"></i> 
                                <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                            </div> -->
                            <div class="col-12 d-flex mt-1 px-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <!--  <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> <span class="m-auto text-bold" style="font-size: 10px;"> &nbsp;0</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <i class="far fa-star me-auto mx-3" *ngIf="item.strTipo == 'GANADO'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0">
                                <i class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0">
                                <i class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 border-end" *ngIf="strTipo == 'lead'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-7 col-lg d-flex align-items-center ">
                            <div class="circleCantidadLeads mx-0">
                                <span class="textCantidad">{{intTotal_recorrido_lead}}</span>
                            </div>
                            <span class="text-sm text-bold ms-2 fw-bold"
                                style="font-size: 11.5px;">RECORRIDO/COTIZACIÓN</span>
                        </div>
                        <div class="col-5 col-lg d-flex align-items-center ">
                            <span class="ms-auto">{{dblMontoRecorridoLead |currency:'':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado3 w-100 m-auto"></div>
                </div>
                <!-- <div class="row mt-1">
                    <div class="col-6 col-lg d-flex  ope-0">
                        <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                            <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i> <b>{{intTotalServiciosR
                                | currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                    <div class="col-6 col-lg d-flex  order-xxl-3 pe-0">
                        <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                            <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i> <b>{{intTotalMaterialesR |
                                currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                </div> -->
            </div>
            <div class="row columna-con-scroll px-2 pb-2 pt-1 mt-2">
                <div class="card card-sin-datos mx-auto px-0" *ngIf="arrayRecorridoLead.length == 0"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center py-3"
                    *ngIf="arrayRecorridoLead.lenght > 0 && spinnerR">
                    <mat-spinner strokeWidth="6" diameter="40"></mat-spinner>
                </div>
                <div class="card mx-auto mt-3 px-0 py-0" style="width: 95%;"
                    *ngFor="let item of arrayRecorridoLead | pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item)">
                    <div class="card-header d-flex ps-2 pe-2">
                        <div class="me-auto">
                            <span>Folio:</span><span class="fw-bold mx-1">{{item.intIdLeads}}</span>
                        </div>
                        <div class="ms-auto">
                            <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                        </div>
                    </div>
                    <div class="card-body container-fluid">
                        <div class="row">
                            <div class="col-4 d-flex px-1">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center d-flex px-1">
                                <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                            </div>
                            <!-- <div class="col-2 d-flex">
                                 <i class="far fa-star mx-auto mt-0"></i>
                                <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                            </div> -->
                            <div class="col-12 d-flex mt-1 px-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <!--  <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> <span class="m-auto text-bold" style="font-size: 10px;"> &nbsp;0</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex p-0"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <i class="far fa-star me-auto mx-3" *ngIf="item.strTipo == 'GANADO'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0">
                                <i class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0">
                                <i class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 border-end" *ngIf="strTipo == 'lead'">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <div class="circleCantidadLeads mx-0">
                                <span class="textCantidad">{{intTotal_Perdido}}</span>
                            </div>
                            <span class=" text-sm text-bold ms-2 fw-bold" style="font-size: 11.5px;">PERDIDOS</span>
                        </div>
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <span class="ms-auto">{{dblMontoPerdidos |currency:'':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado w-100 m-auto"></div>
                </div>
                <!-- <div class="row mt-1">
                    <div class="col-6 col-lg d-flex  ope-0">
                        <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                            <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                            <b>{{intTotalServiciosPER | currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                    <div class="col-6 col-lg d-flex  order-xxl-3 pe-0">
                        <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                            <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i> <b>{{intTotalMaterialesPER |
                                currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                </div> -->
            </div>
            <div class="row columna-con-scroll px-2 pb-2 pt-1 mt-2">
                <div class="card card-sin-datos mx-auto" *ngIf="arrayPerdidos.length == 0" style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="text-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center py-3" *ngIf="arrayPerdidos.lenght > 0 && spinnerPER">
                    <mat-spinner strokeWidth="6" diameter="40"></mat-spinner>
                </div>
                <div class="card mx-auto mt-3 px-0 py-0" data-bs-toggle="modal" data-bs-target="#detalleLead"
                    style="width: 95%;"
                    *ngFor="let item of arrayPerdidos.slice(0, elementosPerdido)| pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item)">
                    <div class="card-header d-flex ps-2 pe-2">
                        <div class="me-auto">
                            <span>Folio:</span><span class="fw-bold mx-1">{{item.intIdLeads}}</span>
                        </div>
                        <div class="ms-auto">
                            <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                        </div>
                    </div>
                    <div class="card-body container-fluid">
                        <div class="row">
                            <div class="col-4 d-flex px-1">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center d-flex px-1">
                                <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                            </div>
                            <!--    <div class="col-2 d-flex">
                         <i class="far fa-star mx-auto mt-0"></i> 
                                <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                            </div> -->
                            <div class="col-12 d-flex mt-1 px-1">
                                <div class="me-auto">
                                    <span class=" text-minutos me-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <!--  <div class="ms-auto d-flex">
                                    <i class="far fa-comment-dots m-auto"></i> <span class="m-auto text-bold" style="font-size: 10px;"> &nbsp;0</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex"
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <i class="far fa-star me-auto mx-3" *ngIf="item.strTipo == 'GANADO'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0">
                                <i class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0">
                                <i class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf=" activeButton== 'lista'">
        <div class="col-12">
            <ul class="list-group" *ngIf="strTipo == 'lead'">
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row align-items-center">
                                <div class="col d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_por_contactar}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Por contactar</span>
                                </div>
                                <!--  <div class="col d-flex text-center">
                                    <span class="me-auto" style="font-size: 12px;" matTooltip="Servicios">
                                        <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                                        <b class="ms-2">{{intTotalServiciosPC | currency:'':'symbol':'1.2-2'}}</b>
                                    </span>
                                    <span class="ms-auto" style="font-size: 12px;" matTooltip="Materiales">
                                        <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i>
                                        <b class="ms-2">{{intTotalMaterialesPC | currency:'':'symbol':'1.2-2'}}</b>
                                    </span>
                                </div> -->
                                <div class="col d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoPorContactar
                                        |currency:'':'symbol':'1.2-2' }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of array_por_contactar; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;" (click)="fnDetalleLead(item)"
                                            data-bs-toggle="modal" data-bs-target="#detalleLead">
                                            <strong>{{item.strNombreEmpresa}}</strong>
                                        </td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Presentacion-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row align-items-center">
                                <div class="col d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_presentacion}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Presentación</span>
                                </div>
                                <!-- <div class="col d-flex text-center">
                                    <span class="me-auto" style="font-size: 12px;" matTooltip="Servicios">
                                        <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                                        <b class="ms-2">{{intTotalServiciosP | currency:'':'symbol':'1.2-2'}}</b>
                                    </span>
                                    <span class="ms-auto" style="font-size: 12px;" matTooltip="Materiales">
                                        <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i>
                                        <b class="ms-2">{{intTotalMaterialesP | currency:'':'symbol':'1.2-2'}}</b>
                                    </span>
                                </div> -->
                                <div class="col d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoPresentacion |
                                        currency:'':'symbol':'1.2-2' }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado2 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayPresentacion | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;" (click)="fnDetalleLead(item)"
                                            data-bs-toggle="modal" data-bs-target="#detalleLead">
                                            <strong>{{item.strNombreEmpresa}}</strong>
                                        </td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Recorrido-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row align-items-center">
                                <div class="col d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_recorrido_lead}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Recorrido</span>
                                </div>
                                <!-- <div class="col d-flex text-center">
                                    <span class="me-auto" style="font-size: 12px;" matTooltip="Servicios">
                                        <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                                        <b class="ms-2">{{intTotalServiciosR | currency:'':'symbol':'1.2-2'}}</b>
                                    </span>
                                    <span class="ms-auto" style="font-size: 12px;" matTooltip="Materiales">
                                        <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i>
                                        <b class="ms-2">{{intTotalMaterialesR | currency:'':'symbol':'1.2-2'}}</b>
                                    </span>
                                </div> -->
                                <div class="col d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoRecorridoLead |
                                        currency:'':'symbol':'1.2-2' }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado3 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of arrayRecorridoLead | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;" (click)="fnDetalleLead(item)"
                                            data-bs-toggle="modal" data-bs-target="#detalleLead">
                                            <strong>{{item.strNombreEmpresa}}</strong>
                                        </td>

                                        <td>{{item.dblMonto | currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
                <!--Perdidos-->
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12">
                            <div class="row align-items-center">
                                <div class="col d-flex ">
                                    <div class="circleCantidadLeads mx-2">
                                        <span class="textCantidad">{{intTotal_Perdido}}</span>
                                    </div>
                                    <span class="p-2 text-sm text-bold me-auto mt-auto">Perdido</span>
                                </div>
                                <!-- <div class="col d-flex text-center">
                                    <span class="me-auto" style="font-size: 12px;" matTooltip="Servicios">
                                        <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                                        <b class="ms-2">{{intTotalServiciosPER | currency:'':'symbol':'1.2-2'}}</b> <br>
                                    </span>
                                    <span class="ms-auto" style="font-size: 12px;" matTooltip="Materiales">
                                        <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i>
                                        <b class="ms-2">{{intTotalMaterialesPER | currency:'':'symbol':'1.2-2'}}</b>
                                        <br>
                                    </span>
                                </div> -->
                                <div class="col d-flex">
                                    <span class="ms-auto text-sm px-3">{{ dblMontoPerdidos |currency:'':'symbol':'1.2-2'
                                        }}</span>
                                </div>
                                <div class="col-12 d-flex mt-2">
                                    <div class="degradado4 w-100 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 table-responsive">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th scope="col">Agente</th>
                                        <th scope="col" class="colorColumna">Empresa</th>

                                        <th scope="col">Monto</th>
                                        <th scope="col">Actividad</th>
                                        <th scope="col">Sector</th>
                                        <th scope="col">Ultimo Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayPerdidos | pipeTablero: filterPost; let i = index">
                                        <th>{{i+1}}</th>
                                        <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                        <td class="colorColumna" style="cursor: pointer;" (click)="fnDetalleLead(item)"
                                            data-bs-toggle="modal" data-bs-target="#detalleLead">
                                            <strong>{{item.strNombreEmpresa}}</strong>
                                        </td>

                                        <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                        <td
                                            [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                            <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                            <i class="fas fa-phone-alt m-auto mx-2"
                                                *ngIf="item.strTipo == 'llamada'"></i>
                                            <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                            <i class="far fa-star m-auto mx-1 text-warning"
                                                *ngIf="item.strTipo == 'GANADO'"></i>

                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                                        </td>
                                        <td>{{item.strSector}}</td>
                                        <td>{{item.strUltimoCambio}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mt-3 justify-content-center" *ngIf=" activeButton== 'calendario'">
        <div class="col-12 d-flex" style="height: 50vh!important;">
            <full-calendar class="m-auto custom-calendar" id="calendar" #calendar
                [options]="calendarOptions"></full-calendar>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="detalleLead" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered   modal-xl" role="document"><!--modal-dialog-scrollable-->
        <div class="modal-content">
            <div class="modal-header d-flex img-fondo">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <span class="fw-bold fs-4 text-success text-nowrap">{{strClasificacion}}</span>
                        </div>
                        <div class="col-8 d-flex text-center">
                            <h1 class="modal-title m-auto fw-bold" id="exampleModalLongTitle">{{strNombreEmpresa}}</h1>
                        </div>
                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <span class="fs-5 mx-4">Folio: &nbsp; {{intIDLeads}}</span>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body color-body-modal hmodal">
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" [selectedIndex]="0">
                    <mat-tab label="Principal" class="mat-tab-label-active">
                        <ng-template mat-tab-label>
                            <i class="fas fa-star-of-life mx-1"></i>
                            <span>PRINCIPAL</span>
                        </ng-template>
                        <div class="container-fluid mt-1">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row d-flex mt-2" style="background: rgb(250, 250, 250);">
                                        <div class="col-md-6 col-12 mt-2 m-auto p-2">
                                            <ol class="lista-elegante">
                                                <li class="d-flex">
                                                    <span class="me-auto"><i class="bi bi-buildings mx-1"></i>Nombre
                                                        Empresa:</span>
                                                    <span class="ms-auto text-dark">{{strNombreEmpresa}}</span>
                                                </li>
                                                <li class="d-flex">
                                                    <span class="me-auto"><i class="bi bi-person mx-1"></i>Nombre
                                                        Contacto</span>
                                                    <span
                                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strNombreContacto}}</span>
                                                </li>
                                                <li class="d-flex">
                                                    <span class="me-auto"><i
                                                            class="bi bi-person-check-fill mx-1"></i>Puesto</span>
                                                    <span
                                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoPuesto
                                                        }}</span>
                                                </li>
                                                <li class="d-flex">
                                                    <span class="me-auto"><i
                                                            class="bi bi-telephone-fill mx-1"></i>Telefono:</span>
                                                    <span
                                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoTelefono}}</span>
                                                </li>

                                            </ol>
                                        </div>
                                        <div class="col-md-6 col-12 mt-2 m-auto p-2">
                                            <ol class="lista-elegante">
                                                <li class="d-flex">
                                                    <span class="me-auto"><i
                                                            class="bi bi-envelope mx-1"></i>E-mail:</span>
                                                    <span
                                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoEmail}}</span>
                                                </li>
                                                <li class="d-flex align-items-center">
                                                    <span class="me-auto"><i
                                                            class="bi bi-person-fill-check mx-1"></i>Agente:</span>
                                                    <span class="ms-auto text-dark w-75">
                                                        <select [disabled]="!boolAgente" name="agente" id="agente"
                                                            class="form-select m-auto" [(ngModel)]="strAgenteLD">
                                                            <option value="undefined">-Selecciona un Agente-
                                                            </option>
                                                            <option *ngFor="let item of arrayAgentes; let i = index"
                                                                value="{{item.strAgente}}">{{item.strNombre}}</option>
                                                        </select>
                                                    </span>
                                                </li>
                                                <li class="d-flex align-items-center">
                                                    <span class="me-auto"><span
                                                            class="material-symbols-outlined fs-6 mx-1">
                                                            family_restroom
                                                        </span>Familia:</span>
                                                    <span class="ms-auto text-dark w-75">
                                                        <select name="agente" id="agente" class="form-select m-auto"
                                                            [disabled]="true" [(ngModel)]="intID_Familia">

                                                            <option *ngFor="let item of arrayFamilias; let i = index"
                                                                value="{{item.intID_Familia}}">{{item.strFamilia}}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </li>

                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 f-flex mt-3">
                                    <div class="row mt-2 justify-content-between">
                                        <div class=" px-0 p-3 tablasResposivas"
                                            [ngClass]="{'tablasResposivas': strEtapa === 'RECORRIDO', 'tablas100': strEtapa !== 'RECORRIDO'}">

                                            <div class="col-12 d-flex">
                                                <button [disabled]="intFlagActividad === 0"
                                                    class="text-white dropdown-toggle text-sm btn btn-success d-flex justify-content-center align-items-center "
                                                    data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                                                    aria-expanded="false"
                                                    [ngClass]="{'btn-selected-hoy': intTiempoDias == 0 && intTiempoHoras > 0, 'btn-selected-dias': intTiempoDias > 0 }">
                                                    <i class="fa-solid fa-plus"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh"
                                                    id="menuActividades" (click)="stopPropagation2($event)">
                                                    <div class="container-fluid">
                                                        <div class="row py-2">
                                                            <div class="col-12 col-md-3">
                                                                <!-- <div class="btn-group d-flex " id="group-buton" role="group"
                                                                aria-label="Basic example">
                                                                <label
                                                                    class="btn btn-outline-info btn-sm text-menu text-center m-auto d-flex"
                                                                    (click)="stopPropagation($event, 'Espera')"
                                                                    [ngClass]="{ 'btn-selected': opcionSeleccionada === 'espera' }">
                                                                    <input type="radio" name="estado" value="espera"
                                                                        [(ngModel)]="opcionSeleccionada">
                                                                    <i class="far fa-stop-circle m-auto"></i>Espera
                                                                </label>
                                                                <label
                                                                    class="btn btn-outline-info btn-sm text-menu text-center m-auto d-flex"
                                                                    (click)="stopPropagation($event, 'Ganado')"
                                                                    [ngClass]="{ 'btn-selected': opcionSeleccionada === 'ganado' }">
                                                                    <input type="radio" name="estado" value="ganado"
                                                                        [(ngModel)]="opcionSeleccionada">
                                                                    <i class="fas fa-trophy m-auto"></i>Ganado
                                                                </label>
                                                                <label
                                                                    class="btn btn-outline-info btn-sm text-menu m-auto d-flex"
                                                                    (click)="stopPropagation($event, 'perdido')"
                                                                    [ngClass]="{ 'btn-selected': opcionSeleccionada === 'perdido' }">
                                                                    <input type="radio" name="estado" value="perdido"
                                                                        [(ngModel)]="opcionSeleccionada">
                                                                    <i class="fas fa-hand-point-down m-auto"></i>Perdido
                                                                </label>
                                                                <label
                                                                    class="btn btn-outline-info btn-sm text-menu m-auto d-flex"
                                                                    (click)="stopPropagation($event, 'cancelado')"
                                                                    [ngClass]="{ 'btn-selected': opcionSeleccionada === 'cancelado' }">
                                                                    <input type="radio" name="estado" value="cancelado"
                                                                        [(ngModel)]="opcionSeleccionada">
                                                                    <i class="fas fa-window-close m-auto"></i>
                                                                    Cancelado
                                                                </label>
                                                            </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="row mdalCard" *ngIf="banderaEspera">
                                                            <div class="col-8">
                                                                <span class="texto-Modal">Recordatorio</span>
                                                            </div>
                                                            <div class="col-4">
                                                                <span class="texto-Modal">Duración(min.)</span>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-8">
                                                                        <input type="datetime-local"
                                                                            class="form-control"
                                                                            [(ngModel)]="strFechaActividad">
                                                                    </div>
                                                                    <div class="col-4 d-block">
                                                                        <input type="number" class="form-control"
                                                                            [(ngModel)]="intDuracionActividad">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <span class="texto-Modal">Se planea hacer
                                                                    (opcional)</span>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-8">
                                                                        <textarea [(ngModel)]="strResultado"
                                                                            name="planea" id="planea" cols="5" rows="1"
                                                                            class="form-control"></textarea>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <select class="form-select"
                                                                            [(ngModel)]="strTipoActividad"
                                                                            (click)="stopPropagation2($event)">
                                                                            <option value="" disabled selected>
                                                                                Selecciona
                                                                                una opción</option>
                                                                            <!-- *ngFor="let opcion of opciones" -->
                                                                            <option value="llamada"
                                                                                *ngIf="strEtapa != 'POR CONTACTAR'">
                                                                                llamada
                                                                            </option>
                                                                            <option value="correo">
                                                                                <!--*ngIf="strEtapa == 'POR CONTACTAR'"-->
                                                                                Correo
                                                                            </option>
                                                                            <option value="correo">
                                                                                <!--*ngIf="strEtapa != 'POR CONTACTAR'"-->
                                                                                Llamada
                                                                            </option>
                                                                            <option value="visita">
                                                                                <!--*ngIf="strEtapa != 'POR CONTACTAR'"-->
                                                                                Visita
                                                                            </option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                            <div class="col-12 d-flex mt-2 p-2">
                                                                <button class="btn btn-info text-white ms-auto btn-sm"
                                                                    (click)="fnInsertActividades()">Guardar</button>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <h5 class="fs-5 fw-bold me-auto d-flex align-items-center mx-2">
                                                    Nueva Actividad
                                                </h5>
                                            </div>
                                            <div class="col-12 table-responsive mb-3 mt-1"
                                                style="max-height: 290px; overflow: auto;">
                                                <table class="table text-center table-bordered"
                                                    style="background: white;">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Agente</th>
                                                            <th scope="col">Tipo</th>
                                                            <th scope="col">Fecha</th>
                                                            <th scope="col">Comentario</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="arrayActividades.length == 0">
                                                            <td colspan="8">
                                                                <div class="card-header text-center">
                                                                    <span style="font-weight: 600;">SIN DATOS</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr *ngFor="let item of arrayActividades; let i = index">
                                                            <th scope="row">{{ i + 1 }}

                                                            </th>
                                                            <td>{{item.strAgente}}</td>
                                                            <td>
                                                                <i class="fas fa-envelope mx-2 correo"
                                                                    *ngIf="item.strTipo == 'correo'"></i>
                                                                <i class="fas fa-phone-alt m-auto mx-2"
                                                                    *ngIf="item.strTipo == 'llamada'"></i>
                                                                <i class="fas fa-walking m-auto mx-2"
                                                                    *ngIf="item.strTipo == 'visita'"></i>
                                                                <i class="far fa-star m-auto mx-1 text-warning"
                                                                    *ngIf="item.strTipo == 'GANADO'"></i>
                                                            </td>
                                                            <td>
                                                                <!--  {{item.strEstatus}} -->
                                                                {{item.strFechaCierre_Estimada}}
                                                            </td>
                                                            <td>{{item.strAsunto}}</td>
                                                            <td>
                                                                <button
                                                                    [ngClass]="{'btn-warning': item.strEstatus == 'PENDIENTE', 'btn-info':item.strEstatus =='CONCLUIDO'}"
                                                                    class=" text-white dropdown-toggle text-sm btn btn-sm  justify-content-center align-items-center  "
                                                                    data-bs-toggle="dropdown" role="button"
                                                                    aria-haspopup="true" aria-expanded="false"
                                                                    (click)="fnInitSummer(item)">
                                                                    <i class="bi bi-patch-question fs-6"
                                                                        *ngIf="item.strEstatus == 'CONCLUIDO'"></i>

                                                                    <i class="bi bi-exclamation-diamond fs-6"
                                                                        *ngIf="item.strEstatus == 'PENDIENTE'"></i>
                                                                </button><!--[disabled]="item.strEstatus == 'CONCLUIDO'"-->
                                                                <div (click)="stopPropagation2($event)"
                                                                    class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh"
                                                                    id="dropInsert">
                                                                    <div class="container-fluid">

                                                                        <div class="row mt-2">
                                                                            <!-- <div class="col-12">
                                                                                <div class="form-group h-100">
                                                                                <textarea #summernote class="custom-summernote shadow h-100"></textarea>
                                                                                </div>
                                                                            </div> -->
                                                                            <div class="col-12 mt-1">

                                                                                <div class="row">
                                                                                    <div class="col-12 mb-2">
                                                                                        <input type="text"
                                                                                            placeholder="Asunto"
                                                                                            class="form-control">
                                                                                    </div>
                                                                                    <div class="col-12 h-100">
                                                                                        <div class="form-group h-100">
                                                                                            <textarea
                                                                                                [(ngModel)]="strComentario"
                                                                                                class="custom-summernote h-100 summernote"
                                                                                                id="summernote"
                                                                                                #summernote></textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-8 d-flex">
                                                                                        <select name="accioness"
                                                                                            id="acciones"
                                                                                            class="form-select m-auto"
                                                                                            [(ngModel)]="strResultado">
                                                                                            <option value="">
                                                                                                --Selecciona
                                                                                                una opción--</option>
                                                                                            <option value="1">
                                                                                                EXITOSO</option>
                                                                                            <option value="2">
                                                                                                SIN EXITO
                                                                                            </option>
                                                                                            <!-- <option value="correo Enviado">
                                                                                            Mail Enviado</option>
                                                                                        <option value="visita">Visita
                                                                                            realizada</option> -->
                                                                                        </select>
                                                                                    </div>
                                                                                    <div class="col-4 d-flex">
                                                                                        <button
                                                                                            class="btn btn-primary  text-white ms-auto mt-auto"
                                                                                            (click)="fnupdate_Actividad(item)">Guardar</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class=" px-0 p-3 tablasResposivas" *ngIf="strEtapa == 'RECORRIDO'">
                                            <!-- <div class="col-11 p-2 mx-auto " style="background: rgb(250, 250, 250);"> -->
                                            <div class="col-12  d-flex">
                                                <span (click)="fnCotizador()" data-bs-dismiss="modal"
                                                    class="text-white  text-sm btn btn-success d-flex justify-content-center align-items-center mx-3">
                                                    <i class="fa-solid fa-plus"></i>
                                                </span>
                                                <h5 class="fs-5 fw-bold">
                                                    Nueva Cotización
                                                </h5>
                                            </div>

                                            <div class="col-12 d-flex table-responsive mb-3"
                                                style="max-height: 300px; overflow: auto;">
                                                <table class="table ms-auto  table-bordered text-center"
                                                    style="background: white;">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">FECHA</th>
                                                            <th scope="col">MONTO</th>
                                                            <th scope="col">ACCIONES</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="arrayCotizaciones.length == 0">
                                                            <td colspan="5">
                                                                <span class="fw-bold">SIN DATOS</span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngFor="let item of arrayCotizaciones; let i = index">
                                                            <th scope="row">{{ i + 1 }}</th>
                                                            <td>{{item.intIDMovimientoVenta}}</td>
                                                            <td>{{item.strFecha}}</td>
                                                            <td>{{item.dblImporte | currency}}</td>
                                                            <td><button class="btn btn-danger btn-sm"><i
                                                                        class="fa-solid fa-trash"></i></button></td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                        <!--  <div class="col-12">
                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <div class="circlePropietario">
                                                        <span class="textPropietario" *ngIf="!strAgente">S/N</span>
                                                        <span class="textPropietario"
                                                            *ngIf="strAgente">{{strAgente}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-9 d-flex">

                                                    <select name="agente" id="agente" class="form-select m-auto"
                                                        [(ngModel)]="strAgenteLD">
                                                        <option value="undefined">-Selecciona un Agente-
                                                        </option>
                                                        <option *ngFor="let item of arrayAgentes; let i = index"
                                                            value="{{item.strAgente}}">{{item.strNombre}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-12 d-flex" *ngIf="strEtapa == 'Recorrido'">
                                            <h5><i class="fas fa-user-alt mx-4"></i> Coordinador Operativo</h5>
                                            <div *ngIf="intIDPeritaje" (click)="fnEnviarEdificios()" class="ms-auto"
                                                style="padding: 5px 15px 5px 15px; background: rgb(218, 247, 230); border-radius: 10px;cursor: pointer;">
                                                <i class="fas fa-building mx-1  text-md"></i> <span
                                                    style="font-size: 11px; font-weight: 500;">Levantamiento
                                                    Iniciado</span>
                                            </div>
                                        </div>
                                        <div class="col-12" *ngIf="strEtapa == 'Recorrido'">
                                            <div class="row mt-1">
                                                <div class="col-3 d-flex">
                                                    <div class="circlePropietario">
                                                        <span class="textPropietario"
                                                            *ngIf="!strCoordinadorOperativo">S/A</span>
                                                        <span class="textPropietario"
                                                            *ngIf="strCoordinadorOperativo">{{getInitials(strCoordinadorOperativo)}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-9 d-flex">
                                                    <input readonly class="m-auto text-md text form-control"
                                                        [(ngModel)]="strCoordinadorOperativo"
                                                        *ngIf="strCoordinadorOperativo">
                                                    <input readonly class="m-auto text-md text form-control"
                                                        value="SIN ASIGNAR" *ngIf="!strCoordinadorOperativo">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fas fa-exclamation-triangle fs-5 mx-1 text-danger iconoDanger"
                                *ngIf="!formLead.valid && strEtapa == 'PRESENTACION'"></i> <i
                                class="fas fa-book-reader mx-1"></i> <span>LEAD</span>
                        </ng-template>
                        <div class="container-fluid">
                            <form [formGroup]="formLead">
                                <div class="row mt-3">
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="text-sm">Nombre: <span class="text-danger">*</span></span>
                                        <input type="text" placeholder="Ingresa Nombre empresa" class="form-control"
                                            [(ngModel)]="strNombreEmpresa" formControlName="strNombreEmpresa">
                                        <mat-error
                                            *ngIf="formLead.get('strNombreEmpresa').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>

                                    <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                        <span class="text-sm m-auto">Sector : <span class="text-danger">*</span></span>
                                        <select name="sector" id="sector" class="form-select" [(ngModel)]="strSector"
                                            formControlName="strSector">
                                            <option value="" selected>-Selecciona una opcion-</option>
                                            <option value="Publico">Publico</option>
                                            <option value="Privado">Privado</option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('strSector').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                        <span class="text-sm m-auto">Giro: <span class="text-danger">*</span></span>
                                        <select name="giro" id="giro" class="form-select" [(ngModel)]="intGiro"
                                            formControlName="intGiro" (change)="onGiroChange($event, 'giro')">
                                            <option value="">-Selecciona una opcion-</option>
                                            <option *ngFor="let item of arrayGiros; let i = index"
                                                [value]="item.intId_Giro + ',' + item.strClasificacion">
                                                {{item.strGiro}}</option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('intGiro').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                        <span class="text-sm m-auto">Presencia en el pais: <span
                                                class="text-danger">*</span></span>
                                        <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                            formControlName="intIDPresencia"
                                            (change)="onGiroChange($event, 'presencia')" [(ngModel)]="intIDPresencia">
                                            <option value="" selected>-Selecciona una opcion-</option>
                                            <option [value]="item.intIDPresencia + ',' + item.strClasificacion"
                                                *ngFor="let item of arrayPresencia; let i = index">
                                                {{item.strPresencia}}
                                            </option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('intIDPresencia').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                        <span class="text-sm m-auto">Ubicaciones: <span
                                                class="text-danger">*</span></span>
                                        <select name="probabilidad" id="probabilidad" class="form-select"
                                            (change)="onGiroChange($event, 'ubicaciones')" [(ngModel)]="intIDUbicacion"
                                            formControlName="intIDUbicacion">
                                            <option value="" selected>-Selecciona una opcion-</option>
                                            <option [value]="item.intid_Ubicacion + ',' + item.strClasificacion"
                                                *ngFor="let item of arrayUbicaciones; let i = index">
                                                {{item.strUbicaciones}}
                                            </option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('intIDUbicacion').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>


                                    <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                        <span class=" m-auto text-sm">Familia</span>
                                        <div class="d-flex align-items-center">
                                            <select [attr.disabled]="intValidacionFamilia === 2 ? true : null"
                                                name="familia" id="familia" class="form-select"
                                                [(ngModel)]="intID_Familia" formControlName="intID_Familia">
                                                <option value="0">--Selecciona una opcion--</option>
                                                <option *ngFor="let item of arrayFamilias; let i = index"
                                                    value="{{item.intID_Familia}}">{{item.strFamilia}}</option>
                                            </select>
                                            <!-- <span _ngcontent-ykc-c289="" class="material-symbols-outlined text-muted ng-tns-c289-22 ng-star-inserted text-warning" >bolt </span> -->
                                            <button type="button" class="btn" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Pendiente"
                                                *ngIf="intValidacionFamilia == 1">
                                                <i class="bi bi-exclamation-octagon-fill fs-5 text-warning mx-1"></i>
                                            </button>
                                            <button type="button" class="btn" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Aceptado"
                                                *ngIf="intValidacionFamilia == 2">
                                                <i class="bi bi-check-circle-fill fs-5 text-success mx-1"></i>
                                            </button>
                                            <button type="button" class="btn" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Cancelado"
                                                *ngIf="intValidacionFamilia == 3">
                                                <i class="bi bi-x-circle-fill fs-5 text-danger mx-1"></i>
                                            </button>


                                        </div>

                                        <mat-error
                                            *ngIf="formLead.get('intID_Familia').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                        <span class="text-sm m-auto">Rango Empleados:</span>
                                        <select name="probabilidad" id="probabilidad" class="form-select"
                                            formControlName="strNumeroEmpleados"
                                            [(ngModel)]="strNumeroEmpleados"><!-- (change)="cambiarPrioridad($event)" -->
                                            <option value="">-Selecciona una opcion-</option>
                                            <option value="{{item.intIDNo_Empleados}}"
                                                *ngFor="let item of arrayTamanoEmpresa; let i = index">
                                                {{item.strNo_Empleados}}</option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('strNumeroEmpleados').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <!-- <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                    <span class="text-sm">Importe</span>
                                    <input type="text" placeholder="Ingresa Importe" class="form-control"
                                        [(ngModel)]="dblImporte">
                                </div> -->
                                    <!-- <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                    <span class="text-sm m-auto"># Elementos:</span>
                                    <input type="number" min="0" value="0" class="form-control"
                                        [(ngModel)]="intCantidadElementos">
                                </div> -->
                                    <!-- <div class="col-md-6 col-lg-4 col-12 p-2">
                                    <span class="text-sm">Prioridad</span>
                                    <select name="probabilidad" id="probabilidad" class="form-select"
                                        [(ngModel)]="strPrioridad" disabled="true">
                                        <option value="-Selecciona una opcion-">-Selecciona una opcion-</option>
                                        <option value="Alta">Alta</option>
                                        <option value="Media">Media</option>
                                        <option value="Baja">Baja</option>
                                        <option value="URGENTE">URGENTE</option>
                                    </select>
                                </div> -->
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                        <span class="text-sm">Fecha Estimada Cierre</span>
                                        <input type="date" class="form-control" min="{{strFechaEstimadaCierreInput}}"
                                            value="{{strFechaEstimadaCierreInput}}" (change)="cambiarFecha($event)">

                                    </div>

                                    <!-- <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                    <span class="m-auto text-sm">Total Sucursales:</span>
                                    <input type="text" placeholder="Total Sucursales" class="form-control ms-auto"
                                        [(ngModel)]="intTotalSucursales">
                                </div> -->
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-1">
                                        <span class="m-auto">Razon Social:</span> <br>
                                        <input type="text" placeholder="Razon Social" class="form-control  m-auto"
                                            [(ngModel)]="strRazonSocial" formControlName="strRazonSocial">
                                        <mat-error
                                            *ngIf="formLead.get('strRazonSocial').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <!-- <div class="col-md-6 col-lg-4 col-12 d-block p-1">
                                    <span class="m-auto">Sitio Web:</span>
                                    <input type="text" placeholder="Sitio Web" class="form-control ms-auto"
                                        [(ngModel)]="strSitioWeb">
                                </div> -->
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-1">
                                        <span class="m-auto">RFC:</span>
                                        <input type="text" placeholder="RFC" class="form-control  ms-auto"
                                            [(ngModel)]="strRFC" formControlName="strRFC" [maxlength]="13">
                                        <mat-error
                                            *ngIf="formLead.get('strRFC').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-1">
                                        <span class="m-auto">Tipo Cotización:</span>
                                        <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            Seleccione:
                                        </button>
                                        <ul class="dropdown-menu heitdrop w-25">
                                            <li *ngFor="let j of arrayTipoCotizacion">
                                                <div class="form-check ms-1">
                                                    <input class="form-check-input" [checked]="j.Seleccionado"
                                                        type="checkbox" [value]="j.Seleccionado"
                                                        (change)="toggleCotizacion(j.strTipoCotizacion)"
                                                        id="flexCheckDefault">
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        {{j.strTipoCotizacion}}
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        <ng-container *ngFor="let j of arrayTipoCotizacion; let i = index"
                                            class="d-flex ">
                                            <span *ngIf="j.Seleccionado"
                                                class="rounded colorSeleccion text-white p-1 mb-2 mx-1 me-1 ms-1 mt-1">{{j.strTipoCotizacion}}</span>
                                        </ng-container>

                                        <mat-error
                                            *ngIf="formLead.get('strTipoCotizacion').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-1">
                                        <span class=" m-auto text-sm">¿Por cual medio nos conocio?</span>
                                        <input type="text" placeholder="VENDEDOR" class="form-control  ms-auto" readonly
                                            [(ngModel)]="strMedioConocio" formControlName="strMedioConocio">
                                        <mat-error
                                            *ngIf="formLead.get('strMedioConocio').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block p-1">
                                        <span class=" m-auto text-sm">¿Cuentan con una empresa de servicio de limpieza
                                            actualmente?</span>
                                        <select name="servicio" id="servicio" class="form-select"
                                            [(ngModel)]="strTieneServicio" formControlName="strTieneServicio">
                                            <option value="">-Selecciona una opción-</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('strTieneServicio').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                        <!-- <input type="text" placeholder="Recomendado por" class="form-control"
                                        [(ngModel)]="strRecomendacion"> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="strEtapa != 'POR CONTACTAR' && strEtapa != 'PRESENTACION'">
                        <ng-template mat-tab-label>
                            <i class="fas fa-id-card-alt mx-1"></i> <span>DATOS CONTACTO</span>
                        </ng-template>
                        <div class="container-fluid">
                            <!-- <div class="col-12 d-flex mt-2">
                                <button class="btn btn-info ms-auto" (click)="fnAgregarContacto()"><i
                                        class="fas fa-plus-circle mx-1"></i>Agregar</button>
                            </div> -->
                            <div class="row mt-4 card-contacto p-3"
                                *ngFor="let item of arrayContactosDetalle; let i = index">
                                <div class="col-12 d-flex " *ngIf="arrayContactosDetalle.length > 1">
                                    <button class="btn btn-danger ms-auto btn-sm" (click)="fnEliminarContacto(i)"><i
                                            class="fas fa-trash-alt"></i></button>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">Nombre:</span>
                                    <input type="text" placeholder="Nombre Contacto" class="form-control ms-auto"
                                        [(ngModel)]="item.strNombreContacto" (input)="validarCampos(item)">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">Puesto:</span> <br>
                                    <input type="text" placeholder="Puesto Contacto"
                                        [(ngModel)]="item.strContactoPuesto" class="form-control  m-auto"
                                        (input)="validarCampos(item)">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">Telefono:</span>
                                    <input type="text" placeholder="Telefono Contacto" class="form-control ms-auto"
                                        [(ngModel)]="item.strContactoTelefono" (input)="validarCampos(item)"
                                        [maxlength]="10">
                                    <div *ngIf="strTelefonoContacto && strTelefonoContacto.length >= 3 && !validarTelefono(strTelefonoContacto)"
                                        class="error-message text-sm text-danger">
                                        El número de teléfono no es válido.
                                    </div>

                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">E-mail :</span> <br>
                                    <input type="text" placeholder="Email Contacto" class="form-control  m-auto"
                                        [(ngModel)]="item.strContactoEmail"
                                        [ngClass]="{'campo-obligatorio': item.emailEsObligatorio}"
                                        (input)="validarEmailContacto(item)">
                                    <div *ngIf="item.emailEsObligatorio" class="error-message text-sm text-danger">
                                        El correo electrónico es obligatorio si se ha ingresado nombre, puesto o
                                        teléfono.
                                    </div>

                                </div>

                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="DATOS FISCALES" *ngIf="strEtapa != 'POR CONTACTAR' && strEtapa != 'PRESENTACION'">
                        <ng-template mat-tab-label>
                            <i class="fas fa-route mx-1"></i> <span>DATOS FISCALES</span>
                        </ng-template>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Codigo Postal:</span> <br>
                                    <input type="text" placeholder="codigo postal" class="form-control m-auto"
                                        [(ngModel)]="strCodigoPostal" (keyup.enter)="fnGetDatosFiscales()">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Calle <span class="text-danger">*</span></span>
                                    <br>
                                    <input type="text" placeholder="Calle" class="form-control  m-auto"
                                        [(ngModel)]="strCalle">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Estado <span class="text-danger">*</span></span>
                                    <br>
                                    <input readonly type="text" placeholder="Estado" class="form-control  m-auto"
                                        [(ngModel)]="strEstado">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">No. Exterior <span
                                            class="text-danger">*</span></span> <br>
                                    <input type="text" placeholder="No. Exterior " class="form-control  m-auto"
                                        [(ngModel)]="strNumeroExterior">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Delegación <span
                                            class="text-danger">*</span></span> <br>
                                    <input readonly [(ngModel)]="strDelegacion" type="text" placeholder="Delegación"
                                        class="form-control  m-auto">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Entre Calles <span
                                            class="text-danger">*</span></span> <br>
                                    <input [(ngModel)]="strEntreCalles" type="text" placeholder="Entre Calles"
                                        class="form-control  m-auto">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-2">
                                    <span class="datosEmpresa m-auto">Colonia:</span>
                                    <select name="probabilidad" id="probabilidad" class="form-select"
                                        [(ngModel)]="strColonia">
                                        <option *ngFor="let item of arrayColonias; let i = index"
                                            value="{{item.strColonia}}">{{item.strColonia}}
                                        </option>
                                        <option value="otraColonia">Otra</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-12 ">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="datosEmpresa m-auto">Telefono <span
                                                    class="text-danger">*</span></span>
                                        </div>
                                        <div class="col-3 p-1">
                                            <input type="text" placeholder="Lada" class="form-control"
                                                [(ngModel)]="strLadaEmpresa">
                                        </div>
                                        <div class="col-9 p-1">
                                            <input type="text" placeholder="Telefono" class="form-control"
                                                [(ngModel)]="strTelefonoEmpresa">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-2">
                                    <span class="datosEmpresa m-auto">Zona:</span>
                                    <select name="zona" id="zona" class="form-select" [(ngModel)]="strZona">
                                        <option *ngFor="let item of arrayZonas; let i = index" value="{{item.strZona}}">
                                            {{item.strZona}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-12" *ngIf="banderaColonia">
                                    <span class="datosEmpresa m-auto">Escriba Colonia <span
                                            class="text-danger">*</span></span> <br>
                                    <input type="text" placeholder="Otra colonia" class="form-control  m-auto"
                                        [(ngModel)]="strOtraColonia">
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="strEtapa != 'POR CONTACTAR' && strEtapa != 'PRESENTACION'">
                        <ng-template mat-tab-label>
                            <i class="fas fa-paperclip mx-1"></i> <span>ANEXOS</span>
                        </ng-template>
                        <div class="container-fluid">
                            <div class="row mt-3">

                                <div class="col-10 d-flex justify-content-end mt-3">
                                    <div class="input-group mb-3 w-50 ms-auto">
                                        <label class="input-group-text" for="inputGroupSelect01">Tipo Documento</label>
                                        <select name="tipo" id="tipo" class="form-select" [(ngModel)]="strTipoAnexo">
                                            <option value="Contrato">Contrato</option>
                                            <option value="Materiales">Materiales</option>
                                            <option value="Cotización">Cotización</option>
                                            <option value="evidencia">Evidencia foto</option>
                                            <option value="Servicios especiales">Servicios especiales</option>
                                            <option value="Cotizacion Rapida">Cotizacion Rapida</option>
                                            <option value="Cotizacion por TYM">Cotizacion por TYM</option>
                                            <option value="Licitaciones">Licitaciones</option>
                                            <option value="InfoCotizacion">Información para cotizar</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2 d-flex justify-content-center align-items-center">
                                    <label class="btn btn-secondary btn-sm btn-file text-sm">
                                        <i class="fas fa-plus"></i> Agregar Anexo
                                        <input (change)="agregarAnexo($event)" #file type="file" style="display: none;"
                                            required accept=".xlsx, .pdf, .png, .jpg, .jpeg">
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4 col-12 d-block mb-3 text-center"
                                    *ngFor="let item of arrayAnexosUpdate; let i = index">
                                    <div *ngIf="item.strTipo == 'xlsx'" class="d-flex justify-content-center">
                                        <div class="card w-75">
                                            <div class="card-body">
                                                <a>
                                                    <svg class="svg_excel" xmsns="http://www.w3.org/2000/svg"
                                                        height="1em"
                                                        viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                        <path
                                                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="card-footer">
                                                <label class="text-sm">{{item.strNombre}}</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div *ngIf="item.strTipo == 'pdf'" class="d-flex justify-content-center">
                                        <div class="card w-75">
                                            <div class="card-body">
                                                <a>
                                                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                                        viewBox="0 0 512 512" xmsns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                    fill="#f9f8f9" />
                                                                <path
                                                                    d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                    fill="#dc4955" />
                                                                <path
                                                                    d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                    fill="#dc4955" />
                                                                <path
                                                                    d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                    fill="#c42430" />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                <path
                                                                    d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                <path
                                                                    d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                <path
                                                                    d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="card-footer">
                                                <label class="text-sm">{{item.strNombre}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.strTipo == 'jpg' || item.strTipo == 'png' || item.strTipo == 'jpeg' || item.strTipo == 'jpg'"
                                        class="d-flex justify-content-center">
                                        <div class="card w-75">
                                            <div class="card-body">
                                                <a>
                                                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                                        viewBox="0 0 512 512" width="512"
                                                        xmsns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                    fill="#f9f8f9" />
                                                                <path
                                                                    d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                    fill="#a29aa5" />
                                                                <path
                                                                    d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                                                                    fill="#9ec8fc" />
                                                                <path
                                                                    d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                                                                    fill="#62a4fb" />
                                                                <path
                                                                    d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                    fill="#9ec8fc" />
                                                                <path
                                                                    d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                                                                    fill="#b9a1d3" />
                                                                <path
                                                                    d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                                                                    fill="#9573bb" />
                                                                <path
                                                                    d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                    fill="#b9a1d3" />
                                                                <circle cx="219.587" cy="285.995" fill="#faf063"
                                                                    r="21.578" />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                                                                <path
                                                                    d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                                                                <path
                                                                    d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="card-footer">
                                                <label class="text-sm">{{item.strNombre}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                            </div>
                            <div class="row mt-3">
                                <div class="col-12 d-flex "
                                    *ngIf="arrayAnexos.length==0 && arrayAnexosUpdate.length==0">
                                    <div class="card m-auto">
                                        <div class="card-body d-flex">
                                            <span class="m-auto text-bold">
                                                Sin Anexos
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <mat-accordion class="col-12">
                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = false"
                                        *ngFor="let item of arrayAnexos; let i = index" class="m-1" id="bordeCard">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title class="d-flex">
                                                <i class="fas fa-trash-alt mx-3 text-danger text-md"
                                                    (click)="fnBorrarAnexo(item.intIdAnexos)"></i>
                                                {{item.strUrl.split('/')[1]}}
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12"
                                                    *ngIf="item.strTipo != 'pdf' && item.strTipo != 'xlsx'">
                                                    <img src="https://ecodeli.com/{{item.strUrl}}" alt=""
                                                        class="img-fluid">

                                                </div>
                                                <div class="col-12 d-flex" *ngIf="item.strTipo == 'pdf'">
                                                    <a href="https://ecodeli.com/{{item.strUrl}}" class="m-auto"
                                                        target="_blank">
                                                        <svg id="Capa_1" enable-background="new 0 0 512 512"
                                                            height="512" viewBox="0 0 512 512"
                                                            xmsns="http://www.w3.org/2000/svg">
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                        fill="#f9f8f9" />
                                                                    <path
                                                                        d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                        fill="#e3e0e4" />
                                                                    <path
                                                                        d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                        fill="#e3e0e4" />
                                                                    <path
                                                                        d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                        fill="#dc4955" />
                                                                    <path
                                                                        d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                        fill="#dc4955" />
                                                                    <path
                                                                        d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                        fill="#c42430" />
                                                                </g>
                                                                <g>
                                                                    <path
                                                                        d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                    <path
                                                                        d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                    <path
                                                                        d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                    <path
                                                                        d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </a>

                                                </div>
                                                <div class="col-12 d-flex" *ngIf="item.strTipo == 'xlsx'">
                                                    <a href="https://ecodeli.com/{{item.strUrl}}" class="m-auto"
                                                        download>
                                                        <svg class="svg_excel" xmsns="http://www.w3.org/2000/svg"
                                                            height="1em"
                                                            viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                            <path
                                                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="modal-footer d-flex img-fondo">
                <div class="ms-auto me-2">
                    <!-- Botón para avanzar -->
                    <button (click)="fnAvanzarPresentacion()" id="boton" type="button"
                        *ngIf="strEtapa == 'POR CONTACTAR'" [disabled]="intFlagAvanza1 == 0"
                        class="btn btn-success  justify-content-center align-items-center mx-2" data-bs-dismiss="modal">
                        <i class="bi bi-arrow-right-circle-fill fs-5 me-1"></i> <span>Avanzar</span>
                    </button>

                    <button (click)="fnAvanzarRecorrido()" id="boton" type="button" *ngIf="strEtapa == 'PRESENTACION'"
                        [disabled]="!formLead.valid || intFlagAvanza2 == 0"
                        class="btn btn-success  justify-content-center align-items-center mx-2" data-bs-dismiss="modal">
                        <i class="bi bi-arrow-right-circle-fill fs-5 me-1"></i> <span>Avanzar</span>
                    </button>

                    <button (click)="fnAvanzarProspecto()" id="boton" type="button" *ngIf="strEtapa == 'RECORRIDO'"
                        [disabled]="intFlagAvanza3 == 0"
                        class="btn btn-success  justify-content-center align-items-center mx-2" data-bs-dismiss="modal">
                        <i class="bi bi-arrow-right-circle-fill fs-5 me-1"></i> <span>Avanzar</span>
                    </button>

                    <!-- Botón para guardar -->
                    <button type="button" class="btn btn-primary  justify-content-center align-items-center mx-2"
                        (click)="SubirAnexos()" [disabled]="banderaContacto">
                        <span class="material-icons-outlined me-1">save</span> <span>Guardar</span>
                    </button>
                    <button type="button" id="botonCerrar" class="btn btn-secondary d-none"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="nuevoLead" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header d-flex img-fondo">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-10 d-flex">
                            <h2 class="m-auto fw-bold">NUEVO LEAD</h2>

                        </div>
                        <div class="col-2">

                            <button type="button" class="close" data-bs-dismiss="modal" #btncerrar aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body pt-0 color-body-modal">
                <div class="container-fluid">
                    <form [formGroup]="myForm" (ngSubmit)="onSave()">
                        <div class="row mt-3">
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="fw-bold fs11">
                                    <span class="text-danger">*</span> Nombre Empresa:
                                </span>
                                <input type="text" placeholder="Ingresa Nombre empresa" class="form-control"
                                    formControlName="strNombreEmpresa">
                                <mat-error
                                    *ngIf="myForm.get('strNombreEmpresa').hasError('required') && myForm.get('strNombreEmpresa').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                                <mat-error *ngIf="myForm.get('strNombreEmpresa').hasError('maxlength')" class="fs10">
                                    son como maximo 100 caracteres</mat-error>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Sector :
                                </span>
                                <select name="sector" id="sector" class="form-select" formControlName="strSector">
                                    <option selected value="">-Selecciona una opcion-</option>
                                    <option value="Publico">Publico</option>
                                    <option value="Privado">Privado</option>
                                </select>
                                <mat-error
                                    *ngIf="myForm.get('strSector').hasError('required') && myForm.get('strSector').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                                <mat-error *ngIf="myForm.get('strSector').hasError('maxlength')" class="fs10"> son como
                                    maximo 20 caracteres</mat-error>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="m-auto fw-bold fs11"><span class="text-danger">*</span> Giro: </span>
                                <select name="giro" id="giro" class="form-select" formControlName="intId_Giro"
                                    (change)="onGiroChange($event, 'giro')">
                                    <option value="" selected>-Selecciona una opcion-</option>
                                    <option *ngFor="let item of arrayGiros; let i = index"
                                        [value]="item.intId_Giro + ',' + item.strClasificacion">{{item.strGiro}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="myForm.get('intId_Giro').hasError('required') && myForm.get('intId_Giro').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="fw-bold fs11"><span class="text-danger">*</span> C. P.</span>
                                <input type="text" placeholder="ejemp:36213, 40660, etc.." class="form-control"
                                    (keydown)="onKeyDown($event)" (keypress)="validarNumeros($event)"
                                    formControlName="strCodigoPostal"><!-- (keyup.enter)="fnGetDatosFiscales()" -->
                                <mat-error
                                    *ngIf="myForm.get('strCodigoPostal').hasError('required') && myForm.get('strCodigoPostal').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                                <mat-error *ngIf="myForm.get('strCodigoPostal').hasError('maxlength')" class="fs10"> son
                                    como maximo 5 caracteres</mat-error>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class=" m-auto fw-bold fs11"><span class="text-danger">*</span> Estado</span>
                                <input type="text" placeholder="Ejemp:Guanajuato" class="form-control"
                                    formControlName="strEstado" readonly>
                                <mat-error *ngIf="myForm.get('strEstado').hasError('required')" class="fs10">Inserte
                                    c.p. y presione enter</mat-error>
                                <mat-error *ngIf="myForm.get('strEstado').hasError('maxlength')" class="fs10"> son como
                                    maximo 100 caracteres</mat-error>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="fw-bold fs11"><span class="text-danger">*</span> Ciudad </span>
                                <input type="text" placeholder="ejemp:Leon, Cd. Victoria, Guadalajara, etc."
                                    class="form-control" formControlName="strDelegacion" readonly>
                                <mat-error *ngIf="myForm.get('strDelegacion').hasError('required')" class="fs10">Inserte
                                    c.p. y presione enter</mat-error>
                                <mat-error *ngIf="myForm.get('strDelegacion').hasError('maxlength')" class="fs10"> son
                                    como maximo 100 caracteres</mat-error>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Presencia en el
                                    pais: </span>
                                <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                    formControlName="intIDPresencia" (change)="onGiroChange($event, 'presencia')">
                                    <option value="" selected>-Selecciona una opcion-</option>
                                    <option [value]="item.intIDPresencia + ',' + item.strClasificacion"
                                        *ngFor="let item of arrayPresencia; let i = index">
                                        {{item.strPresencia}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="myForm.get('intIDPresencia').hasError('required') && myForm.get('intIDPresencia').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> Rango
                                    Empleados:</span>
                                <select name="probabilidad" id="probabilidad" class="form-select"
                                    formControlName="intIDNo_Empleados">
                                    <option value="" selected>-Selecciona una opcion-</option>
                                    <option value="{{item.intIDNo_Empleados}}"
                                        *ngFor="let item of arrayTamanoEmpresa; let i = index">
                                        {{item.strNo_Empleados}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="myForm.get('intIDNo_Empleados').hasError('required') && myForm.get('intIDNo_Empleados').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                                <mat-error *ngIf="myForm.get('intIDNo_Empleados').hasError('maxlength')" class="fs10">
                                    son
                                    como maximo 50 caracteres</mat-error>
                            </div>
                            <div class="col-md-4 col-sm-12 d-block p-2">
                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span> No.
                                    Ubicaciones:</span>
                                <select name="probabilidad" id="probabilidad" class="form-select"
                                    formControlName="intid_Ubicacion" (change)="onGiroChange($event, 'ubicaciones')">
                                    <option value="" selected>-Selecciona una opcion-</option>
                                    <option [value]="item.intid_Ubicacion + ',' + item.strClasificacion"
                                        *ngFor="let item of arrayUbicaciones; let i = index">
                                        {{item.strUbicaciones}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="myForm.get('intid_Ubicacion').hasError('required') && myForm.get('intid_Ubicacion').touched"
                                    class="fs10">Campo Obligatorio</mat-error>

                            </div>

                        </div>
                        <div class="row mt-4 card-contacto p-3 justify-content-center ">
                            <div class="col-12">
                                <h2 class="mb-0 fw-bold"> Información de Contacto</h2>
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto text-sm"><span class="text-danger">*</span>
                                    Nombre:</span>
                                <input type="text" placeholder="Nombre Contacto" class="form-control ms-auto"
                                    formControlName="strContacto_Nombre">
                                <mat-error
                                    *ngIf="myForm.get('strContacto_Nombre').hasError('required') && myForm.get('strContacto_Nombre').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                                <mat-error *ngIf="myForm.get('strContacto_Nombre').hasError('maxlength')" class="fs10">
                                    son como maximo 50 caracteres</mat-error>
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto text-sm"><span class="text-danger">*</span>
                                    Puesto:</span> <br>
                                <input type="text" placeholder="Puesto Contacto" class="form-control  m-auto"
                                    formControlName="strContacto_Puesto">
                                <mat-error
                                    *ngIf="myForm.get('strContacto_Puesto').hasError('required') && myForm.get('strContacto_Puesto').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                                <mat-error *ngIf="myForm.get('strContacto_Puesto').hasError('maxlength')" class="fs10">
                                    son como maximo 50 caracteres</mat-error>
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto text-sm"><span class="text-danger">*</span>
                                    Telefono:</span>
                                <input type="text" placeholder="Telefono Contacto" class="form-control ms-auto"
                                    formControlName="strContacto_Telefono" [maxlength]="10">
                                <mat-error
                                    *ngIf="myForm.get('strContacto_Telefono').hasError('required') && myForm.get('strContacto_Telefono').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                                <mat-error *ngIf="myForm.get('strContacto_Telefono').hasError('maxlength')"
                                    class="fs10"> son como maximo 10 caracteres</mat-error>
                            </div>
                            <div class="col-md-6 col-sm-12 d-block p-1">
                                <span class="datosEmpresa m-auto text-sm"><span class="text-danger">*</span> E-mail
                                </span> <br>
                                <input type="email" placeholder="Email Contacto" class="form-control  m-auto"
                                    formControlName="strContacto_Email">
                                <mat-error
                                    *ngIf="myForm.get('strContacto_Email').hasError('required') && myForm.get('strContacto_Email').touched"
                                    class="fs10">Campo Obligatorio</mat-error>
                                <mat-error *ngIf="myForm.get('strContacto_Email').hasError('maxlength')" class="fs10">
                                    son como maximo 50 caracteres</mat-error>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer img-fondo d-flex">
                <button class="btn btn-primary me-auto" (click)="onSave()" type="submit"
                    [disabled]="!isFormValid()">Enviar</button>
            </div>
        </div>
    </div>
</div>