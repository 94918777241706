import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ctaCte, data, UserData } from '../detalle-familia-cliente/detalle-familia-cliente.component';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataDBService } from '../servicios/data-db.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import { FiltrosService } from '../servicios/filtros.service';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import * as html2pdf from 'html2pdf.js';
declare var $: any;
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-detalle-contacto',
  templateUrl: './detalle-contacto.component.html',
  styleUrls: ['./detalle-contacto.component.css']
})
export class DetalleContactoComponent implements OnInit {
  displayedColumns: string[] = ['intId_Direccion', 'strSucursal', 'strCalle', 'strNo_exterior', 'strColonia', 'strDelegacion', 'strEstado', 'strCodigoPostal', 'strAgente'];
  displayedColumnsCotizacion: string[] = ['indice', 'img', 'strArticulo', 'strDescripcion', 'strUnidad', 'dblPrecio', 'strTipo', 'strLista'];
  displayedColumnsProductosFavoritos: string[] = ['strArticulo', 'strOpcion', 'strDescripcion', 'strLinea', 'dblCantidad'];
  displayedColumnsProductosFavoritosDetalle: string[] = ['strArticulo', 'strDescripcion', 'strOpcion', 'dblCantidad', 'dblPrecio', 'dblImporte', 'strFechaCreacion'];
  displayedColumnsCtaCte: string[] = ['indice', 'cliente', 'movimiento', 'fechaEmision', 'fechavencimiento', 'dias', 'saldoCorriente', 'a15dias', 'a30dias', 'a60dias', 'a90dias', 'mas90dias'];
  dataSource: MatTableDataSource<UserData>;
  dataSourceC: MatTableDataSource<data>;
  public intCantidadPedidos: any = 0
  public intCantidadRemisiones: any = 0
  public intCantidadVentasConsigna: any = 0
  public intCantidadComodato: any = 0
  dataSourceFavoritos: MatTableDataSource<data>;
  dataSourceFavoritosDetalle: MatTableDataSource<data>;
  TableCtaCte: MatTableDataSource<ctaCte>;
  public arrayPedidos: any = []
  public arrayRemisiones: any = []
  public arrayVentasConsigna: any = []
  public arrayComodatos: any = []
  public objCliente: any = {};
  public intPrecioLista: number = 0;
  public intPrecio: number = 0;
  public intPrecio2: number = 0;
  public intPrecio3: number = 0;
  public intPrecio4: number = 0;
  public intPrecioPlantilla: number = 0;
  public intPrecioPolitica: number = 0;
  public arrayExistentes: any = [];
  public imagenUrl1: string;
  public imagenUrl2: string;
  public imagenUrl3: string;
  public imagenUrl4: string;
  public imagenUrl5: string;
  public spinerCarrito: boolean = false;
  public intIDFamilia: number;
  public strClasificacion: string = "";
  public strNombreEmpresa: string = "";
  public arrayContactosDetalle: any = [];
  public boolAgente: boolean = true;
  public strAgenteLD: string = "";
  public banderaFormulario: boolean;
  public arrayAgentes: any = [];
  public arrayFamilias: any = [];
  public intValidacionFamilia: number;
  public strTipoLead: string = "";
  public inthov: boolean = false;
  public strEstatus: string = "RECORRIDO"
  public IntObjetivo: number = 0;
  public IntVenta: number = 0;
  public IntDiferencia: number = 0;
  public IntPorcentajeAvance: number = 0;
  public intCteValida: boolean = false;
  public strAgente: any;
  public arrayOportunidades: any = [];
  public time1: string = "am";
  public time2: string = "am";
  public mdlHoraInicio: string = "07:00";
  public mdlHoraFin: string = "07:05";
  public arrayAm: any = []
  public arraypm: any = []
  public mdlNombre: string = "";
  public mdlComentarios: string = "";
  public strfecha: Date;
  public arrayTime: any = [{ time: "am" }, { time: "pm" }]
  public objModal: any = { titulo: "" };
  public objModalDatos: any = { titulo: "" };
  public arrayHora: any = [];
  public arrayInfo: any = [];
  public objInfo: any = {};
  public strSucursal: string = "";
  public arraySucursales: any = [];
  public arrayActividades: any = [];
  public arrayOport: any = [];
  public arrayListSucursales: any = [];
  public strConexion: string = "";
  public searchInput: string = "";
  public objAct: any;
  public objObjetivos: any;
  public objDetActividad: any;
  public intID_Familia: string;
  public intID_Leads: number;
  public strFamilia: string = ""
  public contenido: string = '';
  public strResultado: string = "";
  public intIDMovimientoVenta: number = 0
  public strNombreAgente: any = ''
  public strNombreOportunidad: string = ''
  public dblMonto: any = ''
  public strFechaCierre: any = ''
  public intID_linea: any = ''
  public strLinea: string = "";
  public arrayLineas2: any = []
  public strResponsable: string = ''
  public arrayAsigAgentes: any = [];
  public strBuscarArticulo: any = ''
  public arrayArts: any = [];
  public arrayArticuloAgregados: any = []
  public arraySelect_suc_tareas: any = []
  public strDescripcionArticulo: any = ''
  public intCantidadArticulos: number = 0;
  public arrayHistVenta: any = [];
  public arrayFiltradoHistClientes: any = [];
  public arrayCompletoHisVta: any = [];
  public boolSuc: boolean = false;
  public arrayLineas: any = [];
  public spinerCte: boolean = false
  public strBuscarHistVta: string = "";
  public spinerBool: boolean = false;
  public arrayFlujoCliente: any = [];
  public arrayFlujoClienteFiltrado: any = [];
  public searchFlujoCte: string = "";
  public strRutaLogo: any = ''
  public strDBRutaLogo: any = ''
  public selectedRenovFile: File;
  public objLinea: any;
  public strRazonSocial: any = '';
  public idContacto: any = 0
  public myForm: FormGroup;
  public intTotalPreguntas: number = 0
  public formLead: FormGroup;
  public strSector: string = "";
  public intGiro: any = '';
  public arrayGiros: any = [];
  public intIDPresencia: any = ''
  public arrayPresencia: any = [];
  public arrayUbicaciones: any = [];
  public arrayTamanoEmpresa: any = [];
  public intIDUbicacion: any;
  public strNumeroEmpleados: any = '';
  public strFechaEstimadaCierreInput: any = '';
  public strFechaEstimadaCierre: any = '';
  public strRFC: any = '';
  public banderaCotizaciones: any = false;
  public arrayTipoCotizacion: any = []
  public strAgenteSP: any;
  public strMedioConocio: any = 'VENDEDOR';
  public strTieneServicio: any
  public banderaContacto: any;
  public ListaAnexos: any = [];
  public strTipoCotizacion: string = "";
  public arrayNombresSecciones: any = []
  public strCodigoPostal: any;
  public strCalle: any = '';
  public strEstado: any = '';
  public strNumeroExterior: any = '';
  public strDelegacion: any = '';
  public strEntreCalles: any = '';
  public strColonia: any = '';
  public strLadaEmpresa: any = '';
  public strTelefonoEmpresa: any = '';
  public strOtraColonia: any = '';
  public strZona: any;
  public intId_Contacto: any = 0
  public intIDLeads: any = 0;
  public dblImporte: string = "";
  public intTotalContestadas: any = 0
  public strPrioridad: string = "";
  public strSitioWeb: any = '';
  public intId_Direccion: any = 0;
  public intTotalSucursales: any = '';
  public strFase: any;
  public fechaInicioServicio: any = null;
  public intCantidadElementos: any = 0;
  public arrayAnexosUpdate: any = [];
  public strRecomendacion: any;
  public intid_Cliente: any = 0
  public panelOpenState = false;
  public arrayAnexos: any = [];
  public strTipoAnexo: any = 'Contrato';
  public itemD: any = 0;
  public strFechaInicioServicio: any;
  public strTituloActividad: string = ''
  public intIDActividad: any;
  public arraySecciones: any = []
  public urlIframe: SafeResourceUrl;
  public arrayListadoSucursales: any = [];
  public excelData: any = []
  public excelDataAux: any = [];
  public strCliente: any = 'Contrato';
  public nombreResponsable: any = "";
  public iframeVisible: boolean = false;
  public arrayCteCuenta: any = [];
  public strNombre_ruta: string = "";
  public strRuta_Origen: string = "";
  public objRuta_Parametros: any = {};
  public objRuta_Anterior: any = {};
  public arrayColonias: any = [];
  public objArt: any = {};
  public objVtasXlinea: any = {};
  public objvtaXarticulo: any = {};
  public arrayDetCliente: any = [];
  public strBuscar: any = ''
  public spinerVtaXlin: boolean = false;
  public spinerVtaXart: boolean = false;
  public arrayCabeceraXart: any = [];
  public arrayVtaXart: any = [];
  
  public formSucursal: FormGroup;
  public spinnerHistVta: boolean = false;
  public arrayHistLineas: any = [];
  public strBuscarCte: string = "";
  public vartri: boolean = false;
  public objContacto: any = []
  public objHistMargen: any = {
    Accesorios: 0,
    Kimberly: 0,
    Rubbermaid: 0,
    BlueTissue: 0,
    Quimicos: 0,
    Despachadores: 0,
    Tork: 0,
    Degrapack: 0,
    Foaom: 0,
    Dove: 0,
    Tapetes: 0,
    total: 0,
  };

  constructor(private indexedDBService: DataDBService, private sanitizer: DomSanitizer, private formbuilder: FormBuilder, private paginatorIntl: MatPaginatorIntl, private rutaActiva: ActivatedRoute, private _httpService: PeticionesService, private carroservice: CarroService, public _alertify: AlertifyService, private _filtroServ: FiltrosService, public router: Router, elem: ElementRef, public route: ActivatedRoute, private cdr: ChangeDetectorRef, public _serviciosService: PeticionesService, public localSt: LocalStorageService, private elementRef: ElementRef) {
    this.strConexion = this.localSt.retrieve('strConexion');

    this.indexedDBService.getItem('objRuta_Anterior', (value) => {
      this.objRuta_Anterior = value;
      this.strNombre_ruta = value && value.ruta_destino == '/detalle_contacto' ? value.nombre : 'Directorio';
      this.strRuta_Origen = value && value.ruta_destino == '/detalle_contacto' ? value.ruta_origen : '/directorio';
      this.objRuta_Parametros = value && value.parametros ? value.parametros : {};
    });
    let hora = 0, minutos = 0, strH = "", strM = "";
    while (hora < 12) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayHora.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 55) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 5
      }
    }
    hora = 7
    while (hora < 12) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arrayAm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 55) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 5
      }
    }
    hora = 0
    while (hora < 8) {
      strH = hora < 10 ? "0" : "";
      strM = minutos < 10 ? "0" : "";
      this.arraypm.push({ hora: strH + hora + ":" + strM + minutos })
      if (minutos == 55) {
        minutos = 0
        hora = hora + 1;
      } else {
        minutos = minutos + 5
      }
    }

    this.route.queryParamMap.subscribe(params => {
      this.idContacto = params.get('idContacto');
    });
  
    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }

  ngOnInit(): void {
    this.fngetinfoContacto();
    this.fnGetTimeLine();
    this.fngetLineas();
    this.fnListadoOoprtunidad();
  }

  fngetinfoContacto() {
    let obj = {
      strAccion: 'getinfoContacto',
      intID_Contacto: this.idContacto,
      strConexion: this.strConexion
    }
    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        console.log(result)
        this.objContacto = result[0]
      }, error => {
        console.error('Error al consumir la API:', error);
      });
  }

  fnRedirige() {
    let objRuta_Anterior = this.objRuta_Anterior.ruta_anterior_1 ? this.objRuta_Anterior.ruta_anterior_1 : {};
    objRuta_Anterior.ruta_destino = this.strRuta_Origen;


    this.indexedDBService.removeItem('objRuta_Anterior');
    this.indexedDBService.setItem('objRuta_Anterior', objRuta_Anterior);

    this.router.navigate([this.strRuta_Origen], { queryParams: this.objRuta_Parametros });
  }

  fnGetTimeLine() {
    let obj = {
      strAccion: 'get_TimeLine_Contacto',
      intID_Contacto: this.idContacto,
      strConexion: this.strConexion
    }

    this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        console.log(result)
        this.arrayOport = result
      }, error => {
        console.error('Error al consumir la API:', error);
      });
  }

  openModal_oportunidad() {
    this.arrayArts = [];
  }

  fngetLineas() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getLineas',
      strConexion: this.strConexion
    }, 'sp_modVentas_Leads').subscribe(
      result => {
        this.arrayLineas2 = result
       
       
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }


  fnInsertOportunidad() {
    let idLinea = this.arrayLineas2.filter((b: any) => b.strLinea == this.strLinea)
    let objenv = {
      strAccion: 'setOportunidad_Contacto',
      strTitulo: this.strNombreOportunidad,
      dblImporte: this.dblMonto,
      strFecha_Cierre: this.strFechaCierre,
      strAgente: this.strResponsable,
      intId_Contacto: this.idContacto,
      intID_Linea: (idLinea[0]) ? idLinea[0].intID_linea : '',
      xmlArticulos: this.arrayArticuloAgregados,
      strConexion: this.strConexion
    }
    console.log(objenv)
    this._serviciosService.mostrarCotizaciones(objenv, 'sp_modVentas_Leads').subscribe(
      result => {

        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Oportunidad Registrada con exito",
            showConfirmButton: false,
            timer: 1500
          });
          this.fnListadoOoprtunidad()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al Agregar!"
          });
        }

        this.strNombreOportunidad = ''
        this.dblMonto = ''
        this.strFechaCierre = ''
        this.intID_linea = ''
        this.strResponsable = ''
        this.arrayArts = []
      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnListadoOoprtunidad() {
    this._serviciosService.mostrarCotizaciones({
      strAccion: 'getListado_oportunidad_Contacto',
      intID_Contacto: this.idContacto,
      strConexion: this.strConexion
    }, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.arrayOportunidades = result
        console.log("this.arrayOportunidades",this.arrayOportunidades)
      }, error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  fnEiminarProductos(indice: number) {
    this.arrayArticuloAgregados.splice(indice, 1);
  }

  fnBuscarCte(cte: string) {
    if (cte.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getAgentes_OP',
        strBusqueda: cte,
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads').subscribe(
        result => {
          this.nombreResponsable = ""
          this.arrayAsigAgentes = result

        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayAsigAgentes = [];
    }
  }

  fnselecCliente(objClient: any) {
    this.strResponsable = objClient.strAgente
    this.nombreResponsable = objClient.strNombreAgente
    this.arrayAsigAgentes = [];
  }

  funConsultarArt(art: any) {
    if (art.length > 2) {
      this._serviciosService.mostrarCotizaciones({
        strAccion: 'getArt_Linea',
        strLinea: this.strLinea,
        strBusqueda: art,
        strCliente: '',
        strConexion: this.strConexion
      }, 'sp_modVentas_Leads').subscribe(
        result => {
          this.arrayArts = result

        },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    } else {
      this.arrayArts = []
    }
  }

  fnselecArticulo(obj: any) {
    this.strBuscarArticulo = obj.strArticulo
    this.strDescripcionArticulo = obj.strDescripcion
    this.arrayArts = [];
    this.fnAgregarArticulo();
  }

  fnAgregarArticulo() {
    let data = {
      strArticulo: this.strBuscarArticulo
    }
    this.arrayArticuloAgregados.push(data)
    this.strBuscarArticulo = ''
    this.intCantidadArticulos = 0
  }

  fnDividirEnClientes() {
    const clientesUnicos = new Set<string>();
    this.arrayFiltradoHistClientes = this.arrayHistVenta.filter(obj => {
      if (!clientesUnicos.has(obj.strCliente)) {
        clientesUnicos.add(obj.strCliente);
        return true;
      }
      return false;
    });
    this.fnLlenarArrayCompleto()
  }

  fnLlenarArrayCompleto() {
    this.spinerBool = true
    const deepCopyArray = JSON.parse(JSON.stringify(this.arrayHistVenta));
    this.arrayFiltradoHistClientes.forEach(cte => {
      cte.seeLineas = false
      if (!cte.arrayLineasTodo) {
        cte.arrayLineasTodo = [];
      }
      deepCopyArray.forEach(obj => {
        if (cte.strCliente == obj.strCliente) {
          cte.arrayLineasTodo.push(obj);
        }
      });
      if (!cte.arrayLineas) {
        cte.arrayLineas = [];
      }
      const LinCopy = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
      LinCopy.forEach(object => {
        const objetoEncontrado = cte.arrayLineas.find(obj => obj.strLinea === object.strLinea);
        const resultado = objetoEncontrado ? 1 : 0;
        if (resultado == 0) {
          cte.arrayLineas.push(object);
        }
      });
      cte.arrayLineas.forEach(lineas => {
        lineas.seeArts = false;
        if (!lineas.arrayArticulos) {
          lineas.arrayArticulos = [];
        }
        const LinCopy2 = JSON.parse(JSON.stringify(cte.arrayLineasTodo));
        LinCopy2.forEach(lin => {
          if (lin.strLinea == lineas.strLinea) {
            lineas.arrayArticulos.push(lin)
          }
        });
      });
    });
    this.spinerBool = false
  
    /*console.log( this.arrayFiltradoHistClientes); */
  }
 
  formatoFecha(fecha: Date): string {
    const year = fecha.getFullYear();
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  fnReturnMes(num: number): string {
    let Mes = ""
    Mes = num == 1 ? "Enero" : num == 2 ? "Febrero" : num == 3 ? "Marzo" : num == 4 ? "Abril" :
      num == 5 ? "Mayo" : num == 6 ? "Junio" : num == 7 ? "Julio" : num == 8 ? "Agosto" :
        num == 9 ? "Septiembre" : num == 10 ? "Octubre" : num == 11 ? "Noviembre" : "Diciembre"
    return Mes
  }

  fnmDLConAct(obj) {
    this.objAct = obj
    let agente = this.arrayAgentes.find(x => x.strAgente == obj.strAgente_Crea)
    this.objAct.nombreCrea = agente.strNombre
    if (obj.strEstatus == "CONCLUIDO" || this.objAct?.strTipo == 'Update_Lead_Tarea') {
      this.fnGetDetActi(obj);
    }
  }

  fnGetDetActi(objAct: any) {
    this.objDetActividad = {};
    let objEnv = {
      strAccion: 'getDetalleActividad',
      intID_Actividad: objAct.intID_Actividad,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        this.objDetActividad = result[0];
        this.objAct.strObservaciones =  this.objDetActividad.strObservaciones ==""?"--campo vacio--":  this.objDetActividad.strObservaciones 
        this.objAct.strResultado =this.objDetActividad.strResultado =="" ?"0":this.objDetActividad.strResultado
      }, error => {
        var error = <any>error; console.log(error);
      });
  }

  fnllenarArrayTareas() {
    this.arrayActividades = this.arrayOport.filter(o => o.strTipo == "Visita" || o.strTipo == "Llamada" || o.strTipo == "Correo")
  }

  fnModalRegistrar(tipo: string) {
    this.mdlHoraInicio = "07:00";
    this.mdlHoraFin = "07:00";
    this.mdlNombre = "";
    this.time1 = "am";
    this.time2 = "am";
    this.mdlComentarios = "";
    this.strfecha = null;
    this.objModal.titulo = tipo

  }

  toggleCotizacion(cotizacion: any) {
    this.arrayTipoCotizacion.forEach(e => {
      if (cotizacion == e.strTipoCotizacion) {
        if (e.Seleccionado) {
          e.Seleccionado = false;
        } else {
          e.Seleccionado = true;
        }
      }
    });
  }

  transform(value: string): string {
    if (!value) return value;
    const datePart = value.split(' ')[0];
    return datePart;
  }

  cambiarFecha(event: any) {
    let fecha = (event.target.value).split('-')
    this.strFechaEstimadaCierre = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
    this.strFechaEstimadaCierreInput = fecha[0] + '-' + fecha[1] + '-' + fecha[2]
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterArt(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceC.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceC.paginator) {
      this.dataSourceC.paginator.firstPage();
    }
  }

  fnAbrirModal(articulo: any) {
    this.fnBuscarCte_Carrito(this.strCliente);

    //obtener toda la informacion del articulo
    this.intPrecioPolitica = articulo.dblPrecio;
    this.objArt = articulo;
    this.objArt.dblCantidad = 1;
    this.objArt.dblPrecioLista = articulo.dblPrecio;
    if (this.objArt.strTipoOpcion == 'Si') { this.fnExtraerOpciones(articulo) }
    let url = 'https://www.cleanclean.mx/imagenes/'
    /*  console.log('abre modal'); */
    this.imagenUrl1 = url + this.objArt.strArticulo + '.JPG';
    this.imagenUrl2 = url + this.objArt.strArticulo + '-PO-2.JPG';
    this.imagenUrl3 = url + this.objArt.strArticulo + '-PO-3.JPG';
    this.imagenUrl4 = url + this.objArt.strArticulo + '-PO-4.JPG';
    this.imagenUrl5 = url + this.objArt.strArticulo + '-PO-5.JPG';
  }

  //funciones para agregar al carrito
  fnBuscarCte_Carrito(strNumCte: string) {
    //verificar si ya esta dado de alta el cliente actual
    let clienteSesision = this.localSt.retrieve('ObjCliente') ? this.localSt.retrieve('ObjCliente') : {};

    if ((clienteSesision.ClienteSeleccionado && clienteSesision.strCliente != strNumCte) || !clienteSesision.ClienteSeleccionado) {
      //borramos carrito actual 
      this.carroservice.deleteCarrito();

      this.objCliente = {
        strCliente: "",
        strNombre: "",
        strDireccion: "",
        ClienteSeleccionado: false,
        strNombreSucursal: "",
        intIDSucursal: "",
        strTipo: "",
        strAgente: "",
        strAlmacen: "",
        strClaveUsoCFDI: "",
        strCondicion: "",
        strDescripcionUsoCFDI: "",
        strInfopago: "",
        strInfopagoDescripcion: ""
      };

      let cliente = {
        strAccion: "getCte_unique",
        strCliente: strNumCte,
        strGerente: this.localSt.retrieve('strUsuario'), //strUsuario
        strTipoAcceso: this.localSt.retrieve('intIDRolAcceso'),//intIDRolAcceso
        strSucursal: this.localSt.retrieve('strSucursal'),//strSucursal
        strAgente: this.localSt.retrieve('strAgente'), //strAgente
        strConexion: this.strConexion
      };
      /*  console.log(cliente) */
      this._httpService.getCotizador(cliente).subscribe(
        result => {

          let arrayClientes = result;
          this.objCliente.strDireccion = arrayClientes[0].strDireccion;
          this.objCliente.strNombre = arrayClientes[0].strNombre;
          this.objCliente.strCliente = arrayClientes[0].strCliente;
          this.objCliente.strNombreSucursal = arrayClientes[0].strNombreSucursal;
          this.objCliente.intIDSucursal = arrayClientes[0].intIDSucursal;
          this.objCliente.strAgente = arrayClientes[0].strAgente;
          this.objCliente.ClienteSeleccionado = true;
          this.objCliente.strTipo = arrayClientes[0].strTipo;
          this.objCliente.strAlmacen = arrayClientes[0].strAlmacen;
          this.objCliente.strClaveUsoCFDI = arrayClientes[0].strClaveUsoCFDI;
          this.objCliente.strCondicion = arrayClientes[0].strCondicion;
          this.objCliente.strDescripcionUsoCFDI = arrayClientes[0].strDescripcionUsoCFDI;
          this.objCliente.strInfopago = arrayClientes[0].strInfopago;
          this.objCliente.strInfopagoDescripcion = arrayClientes[0].strInfopagoDescripcion;
          this.objCliente.strSucursal = arrayClientes[0].strSucursal;

          let sucursales = []
          console.log(arrayClientes);
      /*  arrayClientes.forEach(x => {
          if (x.strNombreSucursal != "") {
            sucursales.push(x);
          }
        });

        let objPlantilla = { boolActive: false, intIDMovimientoVenta: 0 }
        this.servPlantilla.setPlantilla(objPlantilla);
        
        this.card1[0].rows = 2;
        this.card2[0].rows = 3;
  */     this.carroservice.setCte(this.objCliente);
          this.carroservice.setModoPlantilla(false);
        });
    }
  }

  fnExtraerOpciones(objArt: any) {
    let articulo = {
      strAccion: 'getOpcion',
      strArticuloBusqueda: objArt.strArticulo,
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(articulo).subscribe(
      result => {
        this.objArt.arrayOpciones = result;
        this.objArt.strNombreOpcion = this.objArt.arrayOpciones[0].strNombreOpcion ? this.objArt.arrayOpciones[0].strNombreOpcion : '';
        this.objArt.strOpcion = this.objArt.arrayOpciones[0].strOpcion ? this.objArt.arrayOpciones[0].strOpcion : '';

      });
  }
 
  contieneSoloNumeros(cadena: string): boolean {
    // Iterar sobre cada carácter de la cadena
    for (let i = 0; i < cadena.length; i++) {
      // Verificar si el carácter actual no es un dígito numérico
      if (!this.esDigitoNumerico(cadena[i])) {
        return false; // Se encontró un carácter que no es un número
      }
    }
    return true; // Todos los caracteres son numéricos
  }

  private esDigitoNumerico(caracter: string): boolean {
    return /^[0-9]$/.test(caracter); // Utiliza una expresión regular para verificar si el carácter es un número del 0 al 9
  }
 
  fnConcluirActividad(objAct: any) {
    let objEnv = {
      strAccion: 'update_Actividad',
      intID_Actividad: objAct.intID_Actividad,
      intId_Contacto: this.idContacto,
      strComentario: this.contenido,
      strResultado: this.strResultado,
      strConexion: this.strConexion
    }

    this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
      result => {
        if (result[0].code == 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: result[0].strMensaje,
            showConfirmButton: false,
            timer: 1500
          });
          this.fnGetTimeLine();
         // this.btnCerrarAct.nativeElement.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result[0].strMensaje,
          });
        }

      },
      error => {
        var error = <any>error;
        console.log(error);
      }
    );
  }

  onTimeChange(event: any, obj: string) {
    if (obj == "fin") {
      this.fncambiarHoraFin()
    } else if (obj == "inicio") {
      this.fncambiarHoraInicio()
    }
  }

  fncambiarHoraInicio() {
    if (this.time1 == "am") {
      this.mdlHoraInicio = "07:00";
    } else if (this.time1 == "pm") {
      this.mdlHoraInicio = "00:00";
    }
  }

  fncambiarHoraFin() {
    if (this.time2 == "am") {
      this.mdlHoraFin = "07:00";
    } else if (this.time2 == "pm") {
      this.mdlHoraFin = "00:00";
    }
  }

  fnEnviarActividad() {
    if (this.strfecha == null || this.strfecha == undefined) {
      Swal.fire("Error", "seleccione una fecha correcta", "error");
    } else {
      let objEnv = {
        strAccion: 'setActividad_Leads_Contacto',
        intID_TIPO: this.objModal.titulo == "Correo" ? 1 : this.objModal.titulo == "Llamada" ? 3 : 2,
        strTitulo: this.mdlNombre,
        strAgente: this.strAgente,
        strFechaInicioServicio: this.convertDateToString(this.strfecha),
        strHoraInicio: this.mdlHoraInicio + this.time1,
        strHoraFin: this.mdlHoraFin + this.time2,
        strConexion: this.strConexion,
        strComentario: this.mdlComentarios,
        intId_Contacto: this.idContacto
      }
      this._serviciosService.mostrarCotizaciones(objEnv, 'sp_ModVentas_Leads_Familia').subscribe(
        result => {
          Swal.fire(result[0].code == "200" ? 'Guardado' : "Error", result[0].strMensaje, result[0].code == "200" ? 'success' : "error");
          if (result[0].code == "200") {
            this.fnGetTimeLine();
           // this.btnCerrar.nativeElement.click();
          }
        }, error => {
          var error = <any>error; console.log(error);
        }
      );
    }
  }

  convertDateToString(dateString: Date): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  onImageError(event: any): void {
    event.target.src = 'https://www.cleanclean.mx/imagenes/NO-DISPONIBLE.JPG';
  }

  fnAdd_Rest_Amount(objArt: any, tipo: string) {
    if (tipo == 'add') {
      objArt.dblCantidad = objArt.dblCantidad + 1;
    } else {
      objArt.dblCantidad = objArt.dblCantidad - 1;
    }
  }

  fnAddToCarr(objArticulo: any) { //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado
    objArticulo.strComentario = "";
    this.spinerCarrito = true;
    const deepCopy = JSON.parse(JSON.stringify(objArticulo));
    /*   console.log(deepCopy); */
    this.carroservice.setArticulo(deepCopy);
    setTimeout(() => {
      this.spinerCarrito = false;
      this._alertify.success("Articulo agregado correctamente");
    }, 400);
  }

  enviarDetalleOporturnidad(item: any) {
    this.router.navigate(['/detoport'], { queryParams: { idLead: item.intID_Leads_Oportunidad, sts: item.strEstatus } });
  }
}
