<!--<app-menu></app-menu>-->


  
<div class="container">
    <div class="cien section_fondo">
        <h1>Reto <span style="color: #154261;">Tork</span> </h1>
 
        <div class="row">
            <div class="col-12 col-md-6 col-lg-3 mt-3 mt-lg-0 " *ngIf="selectGte">
                <mat-form-field appearance="outline" class="">
                    <mat-label>Seleccione Gerente</mat-label>
                    <mat-select [(ngModel)]="gteSelected" (selectionChange)="fnGetSucursales(gteSelected)">
                        <mat-option *ngFor="let item of arrayGerentes" [value]="item">
                            {{item.strEstatus}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--*{{gteSelected | json}}*-->
            </div>

            <div class="col-12 col-md-6 col-lg-3 mt-3 mt-lg-0  " *ngIf="selectSuc">
                <mat-form-field appearance="outline" class="">
                    <mat-label>Seleccione sucursal</mat-label>
                    <mat-select [(ngModel)]="sucursalSelected" (selectionChange)="fnGetAgentes(sucursalSelected)">
                        <mat-option *ngFor="let item of arraySucursales" [value]="item">
                            {{item.strEstatus}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--*{{sucursalSelected | json}}*-->
            </div>

            <div class="col-12 col-md-6 col-lg-3 mt-3 mt-lg-0 " *ngIf="selectAgt">
                <mat-form-field appearance="outline" class="" >
                    <mat-label>Seleccione Agente</mat-label>
                    <mat-select [(ngModel)]="agenteSelected">
                        <mat-option *ngFor="let item of arrayAgentes" [value]="item">
                            {{item.strLinea}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--*{{agenteSelected | json}}* -->
            </div>
            <div class="col-12 col-md-6 col-lg-3 mt-3 mt-lg-0 ">
                <mat-form-field appearance="outline" >
                    <mat-label>Seleccione Estatus</mat-label>
                    <mat-select [(ngModel)]="estatusSelected">
                        <mat-option *ngFor="let item of arrayEstatus" [value]="item.value">
                            {{item.view}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--*{{agenteSelected | json}}* -->
            </div>
            <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center align-items-center justify-content-lg-end mt-3 mt-lg-0 ">
                <!--*ngIf="selectAgt"-->
                <button class="btn float-md-end btn-primary"  (click)="fnGetListaRetos()">
                    <span class="material-symbols-outlined align-middle" >filter_alt</span>Filtrar</button>
                <!--[disabled]="agenteSelected.strEstatus==''||  !agenteSelected.strEstatus "
                <span *ngIf="agenteSelected.strEstatus =='' || !agenteSelected.strEstatus" class="text-danger" style="margin: 0 0 0 10px;display: inline-block;">Seleccione un Agente</span>-->
            </div>
           
        </div>

        <div class="cien ">
            <hr>
            <div class="row my-4">
                <div class="col-12 col-md-6 col-lg-3 d-flex align-items-center">
                    <mat-form-field appearance="outline">
                        <mat-label> Buscar</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-12 mt-3 mt-md-0 col-md-6 col-lg-9 d-flex justify-content-lg-end align-items-center justify-content-center  mb-4 mb-md-0">
                    <button class="btn bg-success text-white  d-flex float-md-end" (click)="fnNuevoReto()" *ngIf="btnAgregar">
                        <!--  <span class="material-icons">hiking</span> -->
                         <span class="material-symbols-outlined ">
                             tab_new_right
                             </span>
                         <span class="ps-2"><!-- Agregar --> Reto</span>
                     </button>
                </div>
            </div>

            <div class="contenedor_resp_tabla" *ngIf="listadoGeneral">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table table-striped ">
                    <ng-container matColumnDef="intIDReto" >
                        <th mat-header-cell *matHeaderCellDef class="tabred pe-3 ">ID Reto</th>
                        <td mat-cell *matCellDef="let element" class="pe-3 ">{{element.intIDReto}}</td>
                    </ng-container>
                   <!--  <ng-container matColumnDef="strTipoReto">
                        <th mat-header-cell *matHeaderCellDef>Tipo Reto</th>
                        <td mat-cell *matCellDef="let element">{{element.strTipoReto}}</td>
                    </ng-container> -->

                    <ng-container matColumnDef="strFechaInicial">
                        <th mat-header-cell *matHeaderCellDef class="w110 tabred ">Fecha Inicial</th>
                        <td mat-cell *matCellDef="let element" class="w110 "> {{element.strFechaInicial}} </td>
                    </ng-container>

                    <ng-container matColumnDef="strTipo">
                        <th mat-header-cell *matHeaderCellDef class="w110 tabred ">Tipo</th>
                        <td mat-cell *matCellDef="let element ">{{element.strTipo}}</td>
                    </ng-container>

                    <ng-container matColumnDef="strCliente">
                        <th mat-header-cell *matHeaderCellDef class="w110 tabred " colspan="2">Cliente</th>
                        <td mat-cell *matCellDef="let element" class="aLeft " colspan="2"><a (click)="fnDetalle(element)" class="text-info pointer">{{element.strCliente}}</a> - {{element.strNombre}}
                            <span *ngIf="element.intIDSucursal>0">, <b>Sucursal:</b> {{element.intIDSucursal}} - <b>{{element.strNombreSucursal}}</b></span>
                    </td>
                    </ng-container>

                    <!--<ng-container matColumnDef="strContacto">
                        <th mat-header-cell *matHeaderCellDef>Contacto</th>
                        <td mat-cell *matCellDef="let element"> {{element.strContacto}}</td>
                    </ng-container>-->

                    <!--<ng-container matColumnDef="intIDSucursal">
                        <th mat-header-cell *matHeaderCellDef>Sucursal</th>
                        <td mat-cell *matCellDef="let element"><span *ngIf="element.intIDSucursal==0">NO</span><span *ngIf="element.intIDSucursal">{{element.intIDSucursal}} - {{element.strNombreSucursal}}</span></td>
                    </ng-container>-->

                    <ng-container matColumnDef="strAgente">
                        <th mat-header-cell *matHeaderCellDef class="tabred">Agente</th>
                        <td mat-cell *matCellDef="let element">({{element.strAgente}}) {{element.strNombreAgente}}</td>
                    </ng-container>

                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef class="tabred">Estatus</th>
                        <td mat-cell *matCellDef="let element; let i = index;">
                            <a (click)="fnDetalle(element)" class="pointer">
                                <b [ngClass]="{'text-info': element.strEstatus=='PENDIENTE', 
                                'text-aceptado': element.strEstatus=='AUTORIZADO',
                                'text-danger': element.strEstatus=='RECHAZADO'}">
                                {{element.strEstatus}}
                                </b>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="strSeguimiento">
                        <th mat-header-cell *matHeaderCellDef>Seguimiento</th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="fnSeguimiento(element)" class="pointer" title="Estatus Visitas: {{element.strEstatusSeguimiento}}"
                                [ngClass]="{'text-info': element.strEstatusSeguimiento=='PENDIENTE', 
                                    'text-aceptado': element.strEstatusSeguimiento=='CONCLUIDO', 
                                    'text-warning': element.strEstatusSeguimiento=='NO EXISTE',
                                    'text-azul': element.strEstatusSeguimiento=='ACEPTADO',
                                    'text-danger': element.strEstatusSeguimiento=='RECHAZADO'}"
                                *ngIf="element.strEstatus=='AUTORIZADO'">
                                    <b><i class="fa-solid fa-2x fa-flag-checkered"></i></b>
                            </a>
                            <!--*{{element.strEstatusSeguimiento}}*-->
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="strMovIDCom">
                        <th mat-header-cell *matHeaderCellDef>ID Comodato</th>
                        <td mat-cell *matCellDef="let element">
                            <label [title]="element.strEstatusC" [ngClass]="
                            {'text-info': element.strEstatusC=='PENDIENTE', 
                            'text-aceptado': element.strEstatusC=='CONCLUIDO', 
                            'text-warning': element.strEstatusC=='SINAFECTAR',
                             'text-danger': element.strEstatusC=='CANCELADO'}">{{element.strMovIDC}}</label> 
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="strMovIDSa">
                        <th mat-header-cell *matHeaderCellDef> Salida de Inventario</th>
                        <td mat-cell *matCellDef="let element">
                            <label [title]="element.strEstatusS" [ngClass]="
                            {'text-info': element.strEstatusS=='PENDIENTE', 
                            'text-aceptado': element.strEstatusS=='CONCLUIDO', 
                            'text-warning': element.strEstatusS=='SINAFECTAR',
                             'text-danger': element.strEstatusS=='CANCELADO'}">{{element.strMovIDS}}</label> 
                        </td>
                    </ng-container>



                    <ng-container matColumnDef="strAnalisis">
                        <th mat-header-cell *matHeaderCellDef>Solicitud de Comodato</th>
                        <td mat-cell *matCellDef="let element">


                            <!--VISUALIZAR EL ID PARA COMODATOS ENCUESTA-->
                            <!--<a (click)="fnAnalisis(element)" class="pointer" title="Estatus Solicitud: {{element.strEstatusAnalisis}}"
                                [ngClass]="{'text-info': element.strEstatusAnalisis=='PENDIENTE', 
                                    'text-azul': element.strEstatusAnalisis=='AVANZADO', 
                                    'text-warning': element.strEstatusAnalisis=='BORRADOR',
                                    'text-aceptado': element.strEstatusAnalisis=='AUTORIZADO',
                                    'text-danger': element.strEstatusAnalisis=='RECHAZADO'}"
                                >
                                <b><span class="material-icons fs30">description</span></b>
                            </a>
                            
                            <a (click)="fnAnalisis(element)" class="pointer" title="Estatus Solicitud: {{element.strEstatusAnalisis}}"
                                [ngClass]="{'text-info': element.strEstatusAnalisis=='PENDIENTE', 
                                    'text-azul': element.strEstatusAnalisis=='AVANZADO', 
                                    'text-warning': element.strEstatusAnalisis=='BORRADOR',
                                    'text-aceptado': element.strEstatusAnalisis=='AUTORIZADO',
                                    'text-danger': element.strEstatusAnalisis=='RECHAZADO'}"
                                *ngIf="element.strEstatusAnalisis!=''">
                                <b><span class="material-icons fs30">description</span></b>
                            </a>-->
                            <label  [title]="element.strEstatusAnalisis"
                            [ngClass]="{'text-info': element.strEstatusAnalisis=='PENDIENTE', 
                        
                            'text-warning': element.strEstatusAnalisis=='BORRADOR',
                            'text-aceptado': element.strEstatusAnalisis=='AUTORIZADO',
                            'text-danger': element.strEstatusAnalisis=='RECHAZADO'}"
                     
                            >  {{element.strFolio}}</label>
                          
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator class="bg-transparent" [pageSizeOptions]="[5, 10, 20, 30, 60, 120]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>

<br>
<br>
<app-footer></app-footer>