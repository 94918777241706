import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concurso-inscripcion',
  templateUrl: './concurso-inscripcion.component.html',
  styleUrls: ['./concurso-inscripcion.component.css']
})
export class ConcursoInscripcionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
