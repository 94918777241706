import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-comodatos-cliente',
  templateUrl: './comodatos-cliente.component.html',
  styleUrls: ['./comodatos-cliente.component.css']
})


export class ComodatosClienteComponent implements OnInit {

  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public isHoveredButton: boolean = false;

  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public arraySucursales:any=[];
  public arrayGerentes:any=[]; 
  public arrayAccesos:any=[];
  public arrayEjercicio:any=[];

  public strAgente:string="";
  public strSucursal:string="";
  public strGerente: string="";
  public strConexion:string="";
  public inputSearch:string="";

  public intEjercicio:number=0;
  public intPeriodo:number; 
  public dataSource: MatTableDataSource<PeriodicElement>;
  public tagName:any;
  public ELEMENT_DATA: any[] = [
    {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
    {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
    {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
    {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
    {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
    {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
    {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
    {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
    {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
    {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
    {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
    {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
    {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
    {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
    {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
    {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
  ];

  public displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

 

  constructor( private localSt:LocalStorageService, private router: Router,elem:ElementRef,   private _httpService:PeticionesService, private _filtroServ :FiltrosService) {
   
    this.dataSource = new MatTableDataSource<PeriodicElement>([]);
    let fecha = new Date(); 
    
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 

    this.strConexion = this.localSt.retrieve('strConexion');
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];

    this.tagName=elem.nativeElement.tagName.toLowerCase();
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      }else{
        this.boolGerente=objAcceso.find((o:any)=>o.strAccion=='lstDivisionales')?true:false;
        this.strGerente = this.boolGerente?'':this.localSt.retrieve('strUsuario');
        this.boolSucursal=objAcceso.find((o:any)=>o.strAccion=='lstSucursales')?true:false;
        this.strSucursal =this.boolSucursal?'':this.localSt.retrieve('strSucursal');
        this.boolAgente=objAcceso.find((o:any)=>o.strAccion=='lstAgentes')?true:false;
        this.strAgente= this.boolAgente?'':this.localSt.retrieve('strAgente');
      }
    }
  }


  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.fnGetFiltros();
    this.fnGetComodatos();
  }

  fnGetFiltros(){

    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
  
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
  
    //agentes
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
    });
  
    //sucursales
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  fnGetComodatos(){
    this.dataSource.data = this.ELEMENT_DATA;
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    console.log(event);
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
