<div class="container">

    <div class="row">
            <div class="col-12 text-center">
                <h1 class="my-3 fw-bold titulo text-decoration-underline">Solicitud de Presupuesto</h1>
            </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
            <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
                <span class="material-symbols-outlined">
                    content_paste_search
                </span>
                <span class="fw-bold">
                    Filtros
                </span> 
            </div>
            <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
                <mat-form-field appearance="outline" class="bg-white rounded w-100">
                  <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
                  <mat-select [(ngModel)]="strGerente" class="" >
                    <mat-option   value=""> --Todos --</mat-option> 
                   <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
                <mat-form-field appearance="outline" class="bg-white rounded w-100">
                  <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                  <mat-select [(ngModel)]="strSucursal" class="" >
                    <mat-option value=""> --Todas --</mat-option> 
                   <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
                <mat-form-field appearance="outline" class="bg-white rounded w-100">
                  <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
                  <mat-select [(ngModel)]="strAgente" class="" >
                    <mat-option value=""> --Todos --</mat-option> 
                   <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
                <mat-form-field appearance="outline" class="bg-white rounded w-100">
                  <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
                  <mat-select [(ngModel)]="intPeriodo" class="" >
                   <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
                <mat-form-field appearance="outline" class="bg-white rounded w-100">
                    <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                    <mat-select [(ngModel)]="intEjercicio">
                        <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                            {{e.ejercicio}}
                        </mat-option> 
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
                <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                    <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
                </button>
            </div>
    </div>

</div>