import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';

declare var $: any

@Component({
  selector: 'app-costos-detalle-articulo',
  templateUrl: './costos-detalle-articulo.component.html',
  styleUrls: ['./costos-detalle-articulo.component.css']
})
export class CostosDetalleArticuloComponent implements OnInit {
  public banderaRegistros: boolean = false;
  public arrayDetalleExistencias: any = []
  public spArticulo: string = ''
  public ArrayArticulo: any = []
  public arrayCostosArticulo: any = []
  public spOpcion: any = ''
  public ArrayArticuloProv: any = []
  public listaAlmacen: any = []
  public AlmacenHide: any = []
  public arrayDetalleOpciones: any = []
  public arrayDetalleReservados: any = []
  public arrayDetalleLotes: any = []
  public arrayDetalleVentas: any = []
  public SelectAlmacen: any = "";
  public TotalCantidad: number = 0
  public TotalImporte: number = 0
  public UrlrepositorioImagenes: any = ''
  public registros: any = 0
  public registros2: any = 0
  public registros5: any = 0
  public registros3: any = 0
  public registros4: any = 0
  public bandera: boolean = false

  constructor(private _httpService: PeticionesService) {
    this.UrlrepositorioImagenes = 'https://www.cleanclean.mx/imagenes/';
    
  }

  ngOnInit(): void {
    $('#divCostos').hide();
    $('#divProv').hide();
    $('#divInfoArt').hide();
    $('#divInfoArt2').hide();
    $('#divTablas').hide();
  }

  incrementarRegistros(): void {
    this.registros += 1;
  }

  incrementarRegistros2(): void {
    this.registros2 += 1;
  }

  incrementarRegistros3(): void {
    this.registros5 += 1;
  }

  incrementarRegistros4(): void {
    this.registros3 += 1;
  }
  incrementarRegistros5(): void {
    this.registros4 += 1;
  }


  VerExistencias = function () {
    this.arrayDetalleExistencias = [];

    let objA = {
      'strAccion': 7,
      'strArticulo': this.spArticulo
    }
    this._httpService.detalleArticulo(objA, 'getconsultarReportesCompras').subscribe(
      result => {
        this.arrayDetalleExistencias = result;
        if (this.arrayDetalleExistencias.length > 0) {
          $('#DetalleExistencias').modal('show')// cierra el modal despues de hacer una acción
        } else {
          //alertify.log('Sin existencias a mostrar..');
        }
      });

  }

  ValidarArticulo() {
    this.ArrayArticulo = [];
    if (this.spArticulo != "") {
      let objA = {
        strAccion: '3',
        strArticulo: this.spArticulo
      }
      this._httpService.detalleArticulo(objA, 'getconsultarReportesCompras').subscribe(
        result => {
          this.ArrayArticulo = result;
          console.log(this.ArrayArticulo)
          if (this.ArrayArticulo.length > 0) {
            this.ValidarCostos();
            this.ProvXArt();
            this.banderaRegistros = false;
            this.bandera = true

            $('#divInfoArt').show();
            $('#divInfoArt2').show();
            $('#divCostos').show();
            $('#divProv').show();
            $('#divTablas').show();
            this.getAlmacenes();
            if (this.ArrayArticulo[0].strTipoOpcion == 'SI') {
              this.VerOpciones(1);
            }
          } else {
            $('#divCostos').hide();
            $('#divProv').hide();
            $('#divInfoArt').hide();
            $('#divInfoArt2').hide();
            $('#divTablas').hide();
            this.banderaRegistros = true;

          }
        }, function myError(response) {

        });
    } else {
      this.banderaRegistros = true;
    }
  }

  ValidarCostos() {
    this.arrayCostosArticulo = [];

    if (this.spOpcion == '') {
      this.arrayCostosArticulo = this.ArrayArticulo;
    } else {
      this.arrayCostosArticulo = [];


      let objA = {
        'strAccion': 10,
        'strArticulo': this.spArticulo,
        'strProveedor': this.spOpcion
      }
      this._httpService.detalleArticulo(objA, 'getconsultarReportesCompras').subscribe(
        result => {
          this.arrayCostosArticulo = result;
          if (this.arrayCostosArticulo.length > 0) {
            this.arrayCostosArticulo = result;
          } else {
            // alertify.log('Sin costos por subcuenta a mostrar..');
            this.arrayCostosArticulo = this.ArrayArticulo;
          }
        });

    }
  }

  ProvXArt() {
    this.ArrayArticuloProv = "";
    if (this.spArticulo != "") {

      let objA = {
        "strAccion": "4",
        "strArticulo": this.spArticulo
      }
      this._httpService.detalleArticulo(objA, 'getconsultarReportesCompras').subscribe(
        result => {
          this.ArrayArticuloProv = result;
          if (this.ArrayArticuloProv.length > 0) {

            $('#divProv').show();

          } else {
            $('#divProv').hide();
          }
        });
    } else {
      this.banderaRegistros = true;
    }
  }

  getAlmacenes() {
    this.listaAlmacen = [];
    this.AlmacenHide = false;

    this._httpService.getAlmacenes().subscribe(
      result => {
       
        this.listaAlmacen = result;
      });
  }

  VerOpciones(tem) {

    if (this.arrayDetalleOpciones.length == 0) {

      let objA = {
        'strAccion': 8,
        'strArticulo': this.spArticulo
      }
      this._httpService.detalleArticulo(objA, 'getconsultarReportesCompras').subscribe(
        result => {
          this.arrayDetalleOpciones = result;
          if (this.arrayDetalleOpciones.length > 0) {
            if (tem == 2) {
              $('#DetalleOpciones').modal('show')// cierra el modal despues de hacer una acción
            }
          } else {
            // alertify.log('Sin opciones a mostrar..');
          }
        });
    } else {
      if (tem == 2) {
        $('#DetalleOpciones').modal('show')// cierra el modal despues de hacer una acción
      }
    }
  }

  VerDetalle() {
    this.arrayDetalleReservados = [];
    let objA = {
      strAccion: '5',
      strArticulo: this.spArticulo
    }
    this._httpService.detalleArticulo(objA, 'getconsultarReportesCompras').subscribe(
      result => {
        this.arrayDetalleReservados = result;
        if (this.arrayDetalleReservados.length > 0) {
          $('#DetalleReservados').modal('show')// cierra el modal despues de hacer una acción
        } else {
          //alertify.log('Sin reservados a mostrar..');
        }
      });
  }

  VerLotes = function () {
    this.arrayDetalleLotes = [];
    let objA = {
      strAccion: '6',
      strArticulo: this.spArticulo
    }
    this._httpService.detalleArticulo(objA, 'getconsultarReportesCompras').subscribe(
      result => {
        this.arrayDetalleLotes = result;
        if (this.arrayDetalleLotes.length > 0) {
          $('#DetalleLotes').modal('show')// cierra el modal despues de hacer una acción
        } else {
          // alertify.log('Sin lotes a mostrar..');
        }
      });
  }

  VerVenta(accion: any) {
    this.arrayDetalleVentas = [];
    this.TotalCantidad = 0;
    this.TotalImporte = 0;

    if (accion == 1) {
      this.SelectAlmacen = "";
    }

    let objA = {
      strAccion: 9,
      strArticulo: this.spArticulo,
      strProveedor: this.SelectAlmacen
    }
    this._httpService.detalleArticulo(objA, 'getconsultarReportesCompras').subscribe(
      result => {
        this.arrayDetalleVentas = result;
        if (this.arrayDetalleVentas.length > 0) {
          for (let i = 0; i < this.arrayDetalleVentas.length; i++) {

            this.TotalCantidad = this.TotalCantidad + this.arrayDetalleVentas[i].intCantidad;
            this.TotalImporte = this.TotalImporte + this.arrayDetalleVentas[i].dblImportePendiente;

          }



          $('#DetalleVentas').modal('show')// cierra el modal despues de hacer una acción
        } else {
          // alertify.log('Sin Ventas a mostrar..');
        }
      });

  }

}
