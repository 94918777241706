import { Component, OnInit,ViewChild,AfterViewInit } from '@angular/core';
import {PeticionesService}from '../servicios/httpservice';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import Swal from 'sweetalert2';  
import { AlertifyService } from '../alertify.service'; 
import { CanActivate } from '@angular/router';
export interface inventariosData{
  intskuID:any;
  Foto:any;
  strArtEcodeli: any;
  strDescripcion:any;
  dblInventario:any;
  Accion:any;
 
 
}


@Component({
  selector: 'app-inventarios',
  templateUrl: './inventarios.component.html',
  styleUrls: ['./inventarios.component.css']
})





export class InventariosComponent implements OnInit {
 
  dataSource: MatTableDataSource<inventariosData>;
  public contAlertify:number;
  public contErrores:number=0;
  public arrayErrores:any[]=[];
  public ArrayStrInvent: any[];
  public arrayInventario:any[]=[];
  public arraySelect:any[]=[];
  public selected = 0;
  public numinici:number=0;
  public numfin:number=0;
  public strCantActualizar:string="0";
  displayedColumns: string[] = ['intskuID','Foto','strArtEcodeli','strArtVtex','strDescripcion', 'dblInventario', 'Accion'];
  constructor( private _httpService:PeticionesService,public _alertify: AlertifyService) { }


  ngOnInit(): void {
    this.fnGetInventarioEcodeli();

  }

  fnGetInventarioEcodeli(){
    this._httpService.getVtexEcodeli({strAccion:'getInventario'}).subscribe(
       result => {
          this.arrayInventario= result;
          this.dataSource = new MatTableDataSource(this.arrayInventario);
          console.log(this.dataSource) 
        /*   this.fnllenarselect(this.arrayInventario.length); */
        });
        }
 /*  fnllenarselect(array:number){
    let result: number = Math.ceil( array /180 ); 
    for(let i=0;i<=result;i++){
      this.arraySelect.push(i);
      }
  }
  fnchangeSelect(){
   let cantarti = this.arrayInventario.length  ;
   let select = this.selected*50;
   let result;
   this.numinici= 0;
   this.numfin=0
   if(select>cantarti){
    result = select - cantarti;
    this.numfin = select - result;
    this.numinici= ((this.selected-1)*50)+1;
   }else if(select<=cantarti && select>0){
    this.numinici= ((this.selected-1)*50)+1;
    this.numfin = select;
   }
   this.strCantActualizar = this.numinici+" - "+this.numfin;
  } */
  
  fnGetSkuID(objArt:any){
    let reference_code=objArt.strArtVtex;
    let api='/api/catalog_system/pvt/sku/stockkeepingunitidbyrefid/'+reference_code
    console.log(api)
   // this._httpService.getVtex(api).subscribe(
      this._httpService.putVtex_php(api,{'quantity' :0,'api':api,'tipo':'Get'}).subscribe(
     
      (val) => {
        console.log('respuesta:')
        console.log(val);
        console.log(objArt);
        this.fnUpdateInventory(val,objArt.dblInventario,'Input')
        console.log("POST call successful value returned in body", 
                    val);
    },
    response => {
      console.log(response);
      this._alertify.error('No se encontró coincidencia de esta clave en vtex '); 
           
      //  console.log("POST call in error", response);
    },
    () => {
        console.log("The POST observable is now completed.");
    });
  } 
  fnUpdateInventory(skuID:number,cantidad:number,strclick:string){
    let api='/api/logistics/pvt/inventory/skus/'+skuID+'/warehouses/1_1' 
    this._httpService.putVtex_php(api,{'quantity' :cantidad,'api':api,'tipo':'PUT'}).subscribe(
      result => {
        if(result === true){
          if(strclick==="Input"){
            Swal.fire({
              icon: 'success',
              title: 'Correcto',
              text:   "Enviado Correctamente",
            });
          }else if(strclick==="fnTodo"){
            this._alertify.success('SKU ID: '+ skuID +"<br> cantidad: "+cantidad + " <br>Actualizado Correctamente");   
          }
        }else if(result === false){
          if(strclick==="Input"){
            Swal.fire({
              icon:   'error',
              title:'Error',
              text: 'Error al enviar datos SKU ID: '+ skuID +" y cantidad: "+cantidad,
            });
          }else if(strclick==="fnTodo"){
            this.contErrores = this.contErrores+1
            this.arrayErrores.push(skuID);
           this._alertify.error('Error al Actualizar datos <br> SKU ID: '+ skuID +" <br> cantidad: "+cantidad); 
          }
        }
        console.log(result)
      });
    }

  fnUpdateInventory_r(skuID:number,cantidad:number,strclick:string){
      let api='/api/logistics/pvt/inventory/skus/'+skuID+'/warehouses/1_1' 
      this._httpService.putVtex(api,{'quantity' :cantidad}).subscribe(
        result => {
          if(result === true){
            if(strclick==="Input"){
              Swal.fire({
                icon: 'success',
                title: 'Correcto',
                text:   "Enviado Correctamente",
              });
            }else if(strclick==="fnTodo"){
              this._alertify.success('SKU ID: '+ skuID +"<br> cantidad: "+cantidad + " <br>Actualizado Correctamente");   
            }
          }else if(result === false){
            if(strclick==="Input"){
              Swal.fire({
                icon:   'error',
                title:'Error',
                text: 'Error al enviar datos SKU ID: '+ skuID +" y cantidad: "+cantidad,
              });
            }else if(strclick==="fnTodo"){
              this.contErrores = this.contErrores+1
              this.arrayErrores.push(skuID);
             this._alertify.error('Error al Actualizar datos <br> SKU ID: '+ skuID +" <br> cantidad: "+cantidad); 
            }
          }
          console.log(result)
        }
        );
      }

fnActualizarTodo(){
  this.contErrores=0;
  this.arrayErrores=[]
  this.arraySelect=[]
  this.numinici=0;
  this.numfin=0;
  let cantarti = this.arrayInventario.length  ;
  let result;  
  let time=0;
    let result1: number = Math.ceil( this.arrayInventario.length /50 ); 
    for(let i=1;i<=result1;i++){

        setTimeout(() => {
          this.numinici=0;
          this.numfin=0;
          let select = i*50;
         if(select>cantarti){
          result = select - cantarti;
          this.numfin = select - result;
          this.numinici= select - 50;
         }else {
          this.numinici= ((i-1)*50);
          this.numfin = select;
         }
        this.fnActualizarInve();
        }, time);
      
      
       time = time + 25000;
  

      }
      let time1:number=0;
      time1 =500 * this.arrayInventario.length ;
      setTimeout(() => {
        
        if(this.contErrores>0){
          let miArrayTexto = this.arrayErrores.join(', ');
          Swal.fire({
            icon: 'error',
            title: 'SKU ID ERRORES',
            text:   miArrayTexto,
          });
         }
      }, time1);
    

   /*    this.arraySelect.push(i);
      let miArrayTexto = this.arraySelect.join(', ');
      Swal.fire({
        icon: 'success',
        title: 'Correcto',
        text:   miArrayTexto,
      }); */
      
 
 

  /*  for(let i = 0; i<this.arrayInventario.length;i++){
   this.fnUpdateInventory(this.arrayInventario[i].intskuID,this.arrayInventario[i].dblInventario,"fnTodo")   
  }  */
}
fnActualizarInve(){
  this.contAlertify=0;
  let t= 500;
for(let i= this.numinici;i<this.numfin;i++){
  this.contAlertify=this.contAlertify+t;
/*   setTimeout(() => { this._alertify.success(" "+i); }, this.contAlertify);     */
setTimeout(() => { this.fnUpdateInventory(this.arrayInventario[i].intskuID,this.arrayInventario[i].dblInventario,"fnTodo"); }, this.contAlertify); 
      
   }
}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    }

  fnclick(strSkuID:string,numstock:number){
    let result :boolean;
    result = true;
    if(result){
      Swal.fire({
        icon: 'success',
        title: 'Correcto',
        text:   "Enviado Correctamente",
      });
    }else {
      Swal.fire({
        icon:   'error',
        title:'Error',
        text: 'Error al enviar datos al enviar SKU ID: '+ strSkuID +" y cantidad: "+numstock,
      });
    }
    }
}
