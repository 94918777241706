import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { FiltrosService } from '../servicios/filtros.service';
import { CarroService } from '../servicios/carroservice';
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-ecommerce-detalle-categoria',
  templateUrl: './ecommerce-detalle-categoria.component.html',
  styleUrls: ['./ecommerce-detalle-categoria.component.css'],
  animations: [
    trigger('menuAnimation', [
      transition('hidden => visible', [
        style({ opacity: 0 }), // La imagen empieza oculta
        animate('0.3s', style({ opacity: 1 })) // La imagen aparece con animación
      ]),
      transition('visible => hidden', [
        animate('0.3s', style({ opacity: 0 })) // La imagen desaparece con animación
      ])
    ])

    
  ]
})
export class EcommerceDetalleCategoriaComponent implements OnInit {
  public strCategoria:string=""; 
  public strConexion:string = "COMERCIAL"
  public arraySubCategoria: any=[];
  public arrayArticulos: any=[];
  public arraySubcategorias: any=[];
  public spiner: boolean = false;
  public hovered: boolean = false;
  public strBuscar: string = "";
  public inputPrecioMin:number = 0;
  public inputPrecioMax:number =0;
  public precioMin: number = 0; // Precio mínimo inicial
  public precioMax: number = 0; // Precio máximo inicial
  public precioMaximo: number = 33704; 
  public articulosFiltrados: any = [];
  private secretKey: string = 'ec0d3li2o2e';
  precioMid:number = 0
  constructor(private route: ActivatedRoute,public _alertify: AlertifyService, private _filtroServ: FiltrosService, public router: Router, public _serviciosService: PeticionesService, public localSt: LocalStorageService) { 
    this.route.queryParams.subscribe(params => {
      this.strCategoria = params['strCategoria'];
    });
  }

  ngOnInit(): void {
    this.fnExtaerSubCategoria();
    this.fnGetArticulos();
   
  }

  fnExtaerSubCategoria() {
    let objenv = {
        strAccion: 'getGrupos',
        strLinea: this.strCategoria,
        strConexion: this.strConexion
      }
      this._serviciosService.mostrarCotizaciones(objenv, 'sp_ECommerce').subscribe(
        result => {
          
          let algo=result
          algo.forEach(element => {
            element.imageUrl='https://www.cleanclean.mx/imagenes/Sublinea/'+element.strLinea + '.jpg'
          });
          this.arraySubCategoria = algo;
         },
        error => {
          var error = <any>error;
          console.log(error);
        }
      );
    }
    
    onCardClick(categoria:string,subLinea:string){
      this.router.navigate(['/tienda-sub-categoria'], { 
        queryParams: {strLinea:categoria,subLinea:subLinea,tipoBusqueda:"",strArticulo:""}
      });
    }

    fnGetArticulos() {
      this.spiner =true
      this.arraySubcategorias=[];
      this.arrayArticulos = [];
      let articulo = {
        strAccion: "getArticulo_Cat",
        strCategoria: this.strCategoria,
        strConexion: this.strConexion
      };
      this._serviciosService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
        result => {
          console.log(result)
          this.arrayArticulos = result
          this.arrayArticulos.forEach(item => {
            let descuento = 100 - ((item.dblPrecioOferta / item.dblPrecioLista) * 100);
            let decimal = descuento - Math.floor(descuento);
            let resultado = decimal > 0.001 ? Math.ceil(descuento) : Math.floor(descuento);
            item.oferta = resultado;
            item.hovered=false;
          });
          let arrayPrecios = this.arrayArticulos
          .filter(producto => typeof producto.dblPrecioLista === 'number' && !isNaN(producto.dblPrecioLista))
          .map(producto => producto.dblPrecioLista);
          this.inputPrecioMin = Math.min(...arrayPrecios);
          this.inputPrecioMax = Math.max(...arrayPrecios);
          this.precioMax = this.inputPrecioMax;
          this.precioMid = this.precioMax / 2 
          this.spiner =false;
          this.resetFilter();
        },error=>{ 
          this.spiner =false;
        });
    }
    
  addToCart(obj:any)  {
    const objString = JSON.stringify(obj); // Convertir el objeto a string
    const encryptedData = this.encryptData(objString);
    this.router.navigate(['/tienda-art'], { 
      queryParams: { data: encryptedData } // Enviar el string como parámetro
    }); 
  }

  onImageError(event: any) {
    event.target.src = 'assets/Fondos/image.png';
  }
  onImageLoad(event: any) {
    console.log('Imagen cargada:', event.target.src);
  }
  onImageErrorBanner(event: any) {
    event.target.src = './assets/ecommerce/banners/BCOMPLEMENTOS PARA BANIO.jpg';
  }
  fnCambiarHover(){
    this.hovered =  this.hovered ==true ?false:true
  }
  resetFilter() {
    this.applyFilter();
  }

  applyFilter() {
    this.articulosFiltrados  = this.arrayArticulos.filter(
      articulo => articulo.dblPrecioLista >= this.precioMin && articulo.dblPrecioLista <= this.precioMax
    );
    
  }
   encryptData(data: any): string {
      try {
        const stringifiedData = JSON.stringify(data); // Convertir objeto a string
        const encrypted = CryptoJS.AES.encrypt(stringifiedData, this.secretKey).toString();
        return encodeURIComponent(encrypted); // Codificar para URL
      } catch (error) {
        console.error('Error al encriptar datos:', error);
        return '';
      }
    }
  
}
