import { Component, OnInit,ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { PeticionesService } from '../servicios/httpservice';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

export interface UserData {
  id: string;
  customer: any;
  created_at: string;
  updated_at: string;
  totalItems: any;
  current_total_price: number;
  name: string;
  pedido:string;
}

@Component({
  selector: 'app-pedidos-ecodeli',
  templateUrl: './pedidos-ecodeli.component.html',
  styleUrls: ['./pedidos-ecodeli.component.css']
})


export class PedidosEcodeliComponent implements OnInit {
  public strConexion:string="";
  public urlImagen :string=""
  displayedColumns: string[] = ['id', 'name', 'customer','created_at','updated_at','totalItems' , 'current_total_price','pedido'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public arrayListadoEcodeli:any = [];
  public dataCte : any = {firstName:'',lastName:'',phone:'',};
  public arrayArtsModal:any=[];
  public spinner:boolean=false;
  public boolDetalle:boolean=false;
  public CobroEnvio:number=0;
  public mustraEnvioIntel:number=0;
  public objDatosEnvio:any={
    address:{ street:'', number:'', complement:'', neighborhood:'', city:'', state:'', country:'', postalCode:''}
  }
  public porcentImpuesto:number=0; 
  public subtotalModal:number=0;
  public EnvioModal:number=0;
  public IvaModal:number=0;
  public totalModal:number=0;
  public arrayTotales:any=[];
  public arrayArticulos:any=[];
  public filtroFecha: any;
  public arrayOrdenesDia :any=[];
  public search:string="";
  public descuentos:number=0;
  public porcentajeDescuento:number=0;
  public impuestos:any=[];
  public pasarelaPago:string='';
  public strReferencia:string=''; 
  constructor(private _httpService: PeticionesService,private customPaginatorIntl: MatPaginatorIntl) {
    this.filtroFecha = moment();
    this.fnlstarPedidos()
   }

  ngOnInit(): void {
  
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }
  
  fnlstarPedidos(){ 
    this.spinner = true;
    let fechaInicio: moment.Moment = moment();
    let fechaFin: moment.Moment = moment();
    if (moment.isMoment(this.filtroFecha)) {
      fechaInicio = this.filtroFecha.clone().startOf('day');
      fechaFin = this.filtroFecha.clone().endOf('day');
    }else{
      console.log("Error en input fecha");
    }
    const fechaFormateadaInicio = fechaInicio.format('YYYY-MM-DDT00:00:00-06:00');
    const fechaFormateadaFin = fechaFin.format('YYYY-MM-DDT23:59:59-06:00');

    let obj: any = {
      data: {},
      tipo: 'GET',
      api:"orders.json?any&created_at_min="+fechaFormateadaInicio+"&created_at_max="+fechaFormateadaFin+"&status=open&financial_status=paid",
  
    };
  
      this._httpService.getOrdersEcodeli(obj).subscribe((data) => {
      this.arrayListadoEcodeli = data.orders
      console.log(this.arrayListadoEcodeli)
      /*this.arrayListadoEcodeli.push({id:5091458023459,name:"#7276",created_at:"Mar 5, 2024",updated_at:"Mar 5, 2024",totalItems:2,current_total_price:13334,pedido:"",customer:{first_name:"Ana",last_name:"Perez"},
      shipping_address: {
        address1: "Av Centenario 1713",
        address2: "Bosques de Tarango",
        city: "Ciudad de México",
        company: null,
        country: "Mexico",
        country_code: "MX",
        first_name: "Andrea",
        last_name: "Larrañaga Ibarrola",
        latitude: 19.3503751,
        longitude: -99.2499476,
        name: "Andrea Larrañaga Ibarrola",
        phone: "+525549297821",
        province: "Ciudad de México",
        province_code: "DF",
        zip: "01580"
      },payment_gateway_names
      : 
      ['paypal'],shipping_lines:[{code:"Envío terrestre"}]});*/

      /* ['id', 'name', 'customer','created_at','updated_at','totalItems' , 'current_total_price','pedido']; */

      this.dataSource = new MatTableDataSource(this.arrayListadoEcodeli);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.customPaginatorIntl.itemsPerPageLabel = 'Elementos por página:';
    this.spinner=false;
      this.spinner=false;

      //OBTENEMOS LOS IDS DE INTELISIS
      },(error) =>  {
        this.spinner=false;
        console.error('Error al obtener los pedidos:', error);
      } ); 
  }

  fnGetEstatusPedido(){
  } 

  fnMosatrarModal(obj:any){
    this.mustraEnvioIntel=0;
    this.pasarelaPago='';
    this.strReferencia='';
    this.porcentImpuesto=0;
    this.pasarelaPago=obj.payment_gateway_names[0];
    this.strReferencia=obj.shipping_address.name;
    this.mustraEnvioIntel = ( obj.strPedidoMovID && obj.strPedidoMovID!='')?1:0;
   
    console.log(obj)
    this.dataCte = {
      firstName:obj.customer.first_name,
      lastName:obj.customer.last_name,
      phone:obj.shipping_address.phone
    };
    this.objDatosEnvio={
      address:{
        street:obj.shipping_address.address1,
        city:obj.shipping_address.city,
        state:obj.shipping_address.province,
        country:obj.shipping_address.country,
        postalCode:obj.shipping_address.zip,
        iva:obj.total_tax,
        codeEnvio:obj.shipping_lines[0].code,
        order_name:obj.name,
        descuentos:parseInt(obj.total_discounts),
        totalPrecio:obj.total_price,
        impuestos:obj.tax_lines
      }
    } 
    console.log(this.objDatosEnvio)
    if (obj && obj.discount_applications && obj.discount_applications[0] && obj.discount_applications[0].value) {
      this.porcentajeDescuento = parseInt(obj.discount_applications[0].value) / 100;
    } else {
      this.porcentajeDescuento = 0;
    }
    this.CobroEnvio=0;
    this.porcentImpuesto = (obj && obj.tax_lines && obj.tax_lines[0] && obj.tax_lines[0].rate  ?obj.tax_lines[0].rate:0)+(1);
    if (obj.shipping_lines && obj.shipping_lines.length > 0) {
      obj.shipping_lines.forEach(x => {
        this.CobroEnvio += x.discounted_price ? x.discounted_price : 0;
      });
    } else {
      this.CobroEnvio = 0;
    }
    this.arrayArtsModal = obj.line_items
    this.fntraerImagen(); 
    this.fnreturntotal();
  }
  fntraerImagen(){ 
    this.arrayArtsModal.forEach(x => {
      this.boolDetalle=true;
      let obj:any={
        tipo:"GET",
        api:"products/"+x.product_id+"/images.json"};
        this._httpService.getOrders(obj).subscribe((data) => {
        let datos:any = [];
        datos = data;
         x.urlImagen= datos.images[0].src;
          });
    });
    this.boolDetalle=false;
  }
  fnreturntotal(){
    this.descuentos=0;
    this.subtotalModal=0;
    this.IvaModal = 0;
    this.totalModal = 0;
    this.arrayArtsModal.forEach(x => {
    this.subtotalModal = this.subtotalModal+(((x.price-(x.price*this.porcentajeDescuento))/this.porcentImpuesto)*x.quantity)
    });

    this.IvaModal = this.objDatosEnvio.address.iva;
    this.descuentos = this.objDatosEnvio.address.descuentos;
    this.totalModal = this.objDatosEnvio.address.totalPrecio;
    console.log(this.subtotalModal )
    console.log(this.descuentos)
  }
  fnSetPedido_Ecodeli(objDatosEnvio:any,arrayArtsModal:any,strOrderID:any,arrayTotales:any){
  }

}
