<div class="container">




    <div class="row">
        <div class="col-12">
            <h1 class="fw-bold text-center my-4"><i class="bi bi-info-circle fs-5 text-primary" (click)="iniciarTour()"></i> Cotizador</h1>
        </div>
      </div>
    <div class="row">
        <div class="col-12">





          <mat-tab-group [(selectedIndex)]="selectedTabIndex">
            <mat-tab >
              <ng-template mat-tab-label >
           
                  <mat-icon>account_circle</mat-icon>
                  Clientes
            
              </ng-template>
          

                <div class="col-12 py-3 d-flex align-items-center justify-content-between bg-white">
                  <mat-form-field id="inputBuscar" appearance="outline" class="rounded bg-white ms-2">
                    <mat-label>Buscar:</mat-label>
                    <input matInput (keyup)="applyFilter($event)"  placeholder="Ex. Mia" #input>
                  </mat-form-field>
      
                  <button id="btnAgregarCliente" class="btn btn-outline-success px-2 d-flex align-items-center me-2" data-bs-toggle="modal" data-bs-target="#modalAddCte" (click)="fnAbrirModalAgregar()">
                    <i class="bi bi-person-fill-add"></i>
                   <span class="fs13 ms-2">Agregar Cliente</span> 
                  </button>
                </div>
                  
                    <div class="table-responsive mb-0 bordertop">
                      <div class="mat-elevation-z8 rounded shadow bordertop">
                          <table mat-table [dataSource]="dataSource" matSort #sortClientes="matSort" class="table w-100 table-bordered">
          
                            <ng-container matColumnDef="id">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla px-2" > # </th>
                              <td mat-cell *matCellDef="let row;let ind = index" class="align-middle text-center fs12 px-2">
                                <span class="rounded px-2 py-1 fw-bold" [ngClass]="{'bg1':ind % 2== 0,'bg2':ind % 2!= 0}">
                                  {{ind+1}} 
                                </span>
                              </td>
                            </ng-container>
              
                            <ng-container matColumnDef="strRazonSocial">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla"> Razon Social </th>
                              <td mat-cell *matCellDef="let row" class="align-middle text-center fs12"> {{row.strRazonSocial}}</td>
                            </ng-container>
                        
                            <ng-container matColumnDef="strNombreComercial">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla"> Nombre </th>
                              <td mat-cell *matCellDef="let row" class="align-middle text-center fs12"> {{row.strNombreComercial}} </td>
                            </ng-container>
                        
                            <ng-container matColumnDef="strEstado">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla"> Estatus </th>
                              <td mat-cell *matCellDef="let element" class="align-middle  fs12">
                                <div class="d-flex align-items-center px-2 col-12 justify-content-center">
      
                                
                                  <select class="form-select fs11 fw-bold " [(ngModel)]="element.strEstado"  aria-label="Default select example">
                                    <option value="ALTA">ALTA</option>
                                    <option value="BAJA">BAJA</option>
                                  </select>
                                  <button class="btn btn-success ms-1 py-0 px-2" (click)="fnActualizar(element)">
                                    <i class="bi bi-floppy-fill fs13"></i>
                                  </button>
                                  
                               
                              </div>
                              </td>
                            </ng-container>
      
                            <ng-container matColumnDef="strTelefono">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla"> Telefono </th>
                              <td mat-cell *matCellDef="let row" class="align-middle text-center fs12"> {{row.strTelefono}} </td>
                            </ng-container>
      
                            <ng-container matColumnDef="strCorreo">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla"> Correo </th>
                              <td mat-cell *matCellDef="let row" class="align-middle text-center fs12"> {{row.strCorreo}} </td>
                            </ng-container>
      
                            <ng-container matColumnDef="strContacto1">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla"> Contacto </th>
                              <td mat-cell *matCellDef="let row" class="align-middle text-center fs12"> {{row.strContacto1}} </td>
                            </ng-container>
      
                            <ng-container matColumnDef="strCliente">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla"> Partner </th>
                              <td mat-cell *matCellDef="let row" class="align-middle text-center fs12 fw-bold">  {{row.strCliente}} </td>
                            </ng-container>
                            <ng-container matColumnDef="strAccion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="bgtabla px-2">  </th>
                              <td mat-cell *matCellDef="let row" class="align-middle text-center fs12 px-2"> 
                                <button id="btnASeleccionarCliente" class="btn btn-primary py-0 px-2 fs12" (click)="seleccionarCte(row)">
                                  seleccionar
                                </button>
                              </td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="9">No se encontradon datos de"{{input.value}}"</td>
                            </tr>
                          </table>
                        </div>
                    </div>
                  
                    <mat-paginator class="paginator borderbott" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users" #pagClientes></mat-paginator>


              
            </mat-tab>
          
            <mat-tab >
              <ng-template mat-tab-label >
                <mat-icon class="example-tab-icon">list</mat-icon>
                Listado Cotizaciones
              </ng-template>

                <div class="col-12 mt-3 bgtabla ps-2" >
                    <div class="col-6 col-md-4 py-2">
                        <input type="text" class="form-control" [(ngModel)]="inputSearch" placeholder="Buscar: Cliente,Nombre">
                    </div>
                </div>
                <div class="col-12 text-center">
                    <div class="spinner-border text-secondary my-4" role="status" *ngIf="spinerTab">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="alert alert-danger my-3" role="alert" *ngIf="!spinerTab && arrayCotizaciones.length==0">
                        Agregue nueva cotizacíon.
                    </div>
                </div>
                <div class="col-12 table-responsive mx-0 px-0 rounded-bottom border mb-3" *ngIf="!spinerTab && arrayCotizaciones.length>0">
                    <table class="table table-bordered table-hover bg-white">
                        <thead class="" >
                            <tr class="thcenter bgtable ">
                                <th>
                                    <span>#</span>
                                </th>
                                <th>
                                    <span>Fecha</span>
                                </th>
                                <th>
                                    <span>Tipo</span>
                                </th>
                                <th>

                                    <span>Cliente</span>
                                </th>
                                <th>
                                    <span>Importe</span>
                                </th>
                                <th>
                              
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let cot of arrayCotizaciones | filtersearch: inputSearch : ['strNombreCliente','strCliente']; let ind = index;">
                            <tr class="tdcenter" [class.table-hover]="!cot.mostrarDetalle">
                                <td>
                                    <span class="fs12 fw-bold">
                                        {{ind+1}}
                                    </span>
                                </td>
                                <td>
                                    <span class="text-nowrap fs12">
                                        {{fechasComoDate(cot)|date}}
                                    </span>
                                </td>
                                <td>
                                    <span class="fs12">
                                  
                                        {{cot.strTipoMov}}
                                    </span>
                                </td>
                                
                                <td>
                                    <span class="fs12">
                                      <span class="fw-bold">({{cot.strCliente}})</span> 
                                        {{cot.strNombreCliente}}
                                    </span>
                                </td>
                                <td class="text-end">
                                    <span class="fw-bold fs12">
                                        {{cot.dblPrecio|currency}}
                                    </span>
                                </td>
                                
                                <td> <!-- (mouseenter)="cot.isHovered = true" (mouseleave)="cot.isHovered = false" [ngClass]="{'bi-trash3': !cot.isHovered, 'bi-trash3-fill': cot.isHovered}"-->
                                    <i class="bi  mouseHov text-warning fs-5" (mouseenter)="cot.mostrarEye = true" (mouseleave)="cot.mostrarEye = false" [ngClass]="{'bi-eye': !cot.mostrarDetalle && !cot.mostrarEye, 'bi-eye-slash': cot.mostrarDetalle  && !cot.mostrarEye,'bi-eye-fill': !cot.mostrarDetalle && cot.mostrarEye, 'bi-eye-slash-fill': cot.mostrarDetalle  &&  cot.mostrarEye}" (click)="fnMostrarDetalleCotizacion(cot)"  ></i>
                                </td>
                                <td (mouseenter)="cot.isHovered = true" (mouseleave)="cot.isHovered = false">
                                    <i class="bi   text-danger fs-5 mouseHov" (click)="fnEliminarCotizacion(cot)"  [ngClass]="{'bi-trash3': !cot.isHovered, 'bi-trash3-fill': cot.isHovered}"></i>
                                </td>
                            </tr>
                            <tr *ngIf="cot.mostrarDetalle" class="hovtable fadeInOut">
                                <td [attr.colspan]="9">
                                <div class="table-responsive theig">
                                  <table class="table  table-bordered">
                                    <thead class="tableInt">
                                        <tr >
                                            <th class="text-center text-white fs12 bg-secondary">Articulo</th>
                                            <th class="text-center text-white fs12 bg-secondary">Descripción</th>
                                            <th class="text-center text-white fs12 bg-secondary">Unidad</th>
                                            <th class="text-center text-white fs12 bg-secondary">Cantidad</th>
                                            <th class="text-center text-white fs12 bg-secondary">Precio Sugerido</th>
                                            <th class="text-center text-white fs12 bg-secondary">Importe</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                      <tr class="tdtext" *ngFor="let x of arrayDetalleArts; let ind = index;">
                                        <td class="text-center fs12">{{x.strArticulo}}</td>
                                        <td class="text-center fs12 descripcion-truncada">{{x.strDescripcion}}</td>
                                        <td class="text-center fs12">{{x.strUnidad}}</td>
                                        <td class="text-center fs12">{{x.intCantidad}}</td>
                                        <td class="text-center fs12 fw-bold">{{x.dblPrecioSugerido|currency}}</td>
                                        <td class="text-center fs12 fw-bold">{{x.dblImporte|currency}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                </td>
                              </tr>
                        </ng-container>
                        </tbody>
                        <tfoot>
        
                        </tfoot>
                    </table>
                </div>
     














            </mat-tab>
        
          </mat-tab-group>













        </div>
    </div>
</div>

<!-- Modal Agregar Cliente-->
<div class="modal fade" id="modalAddCte" tabindex="-1" aria-labelledby="modalAddCteLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-0 fw-bold mx-auto" id="modalAddCteLabel">Agregar Nuevo Cliente</h2>
        <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formRegistrar" class="row" >
          <div class="col-12 col-md-6  mt-3"  >
            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                <mat-label>Partner</mat-label>
                <input readonly matInput type="text" class="fs12 fw-bold" formControlName="strCliente" >
                <i matSuffix class="bi bi-person-fill-lock fs-4" [ngClass]="{'text-warning': formRegistrar.get('strCliente').hasError('required'),'text-success': !formRegistrar.get('strCliente').hasError('required')}"></i>
            </mat-form-field>
           <mat-error *ngIf="formRegistrar.get('strCliente').hasError('required')" class="fs10">
               Campo Obligatorio
           </mat-error>
        </div>
        <div class="col-12 col-md-6  mt-3" >
             <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
               <mat-label>Estatus</mat-label>
               <input readonly matInput type="text" formControlName="strEstatus">
               <i matSuffix class="bi bi-file-earmark-arrow-up-fill fs-4" [ngClass]="{'text-warning': formRegistrar.get('strEstatus').hasError('required'),'text-success': !formRegistrar.get('strCliente').hasError('required')}"></i>
           </mat-form-field>
           <mat-error *ngIf="formRegistrar.get('strEstatus').hasError('required') && formRegistrar.get('strEstatus').touched" class="fs10">
               Campo Obligatorio
           </mat-error>
       </div>
          <div class="col-12 col-md-6  mt-3">
              <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                  <mat-label>Razon Social</mat-label>
                  <input matInput placeholder="Placeholder" formControlName="strRazonSocial"  >
                  <i matSuffix class="bi bi-buildings-fill fs-4" [ngClass]="{'text-warning': formRegistrar.get('strRazonSocial').hasError('required'),'text-success': !formRegistrar.get('strRazonSocial').hasError('required')}"></i>
              </mat-form-field>
              <mat-error *ngIf="formRegistrar.get('strRazonSocial').hasError('required') && formRegistrar.get('strRazonSocial').touched" class="fs10">
                  Campo Obligatorio
              </mat-error>
          </div>
          <div class="col-12 col-md-6  mt-3">
              <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                  <mat-label>Nombre Comercial</mat-label>
                  <input matInput placeholder="Placeholder" formControlName="strNombreComercial"  >
                  <i matSuffix class="bi bi-building-fill-gear fs-4" [ngClass]="{'text-warning': formRegistrar.get('strNombreComercial').hasError('required'),'text-success': !formRegistrar.get('strNombreComercial').hasError('required')}"></i>
              </mat-form-field>
              <mat-error *ngIf="formRegistrar.get('strNombreComercial').hasError('required') && formRegistrar.get('strNombreComercial').touched" class="fs10">
                  Campo Obligatorio
              </mat-error>
          </div> 
          <div class="col-12 col-md-6  mt-3">
            <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                <mat-label>Contacto</mat-label>
                <input matInput placeholder="Placeholder" formControlName="strContacto1" >
                <i matSuffix class="bi bi-person-circle fs-4" [ngClass]="{'text-warning': formRegistrar.get('strContacto1').hasError('required'),'text-success': !formRegistrar.get('strContacto1').hasError('required')}"></i>
            </mat-form-field>
            <mat-error *ngIf="formRegistrar.get('strContacto1').hasError('required') && formRegistrar.get('strContacto1').touched" class="fs10">
                Campo Obligatorio
            </mat-error>
        </div>
          <div class="col-12 col-md-6  mt-3">
              <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                  <mat-label>Telefono</mat-label>
                  <input matInput placeholder="Placeholder" formControlName="strTelefono" type="text" (keypress)="validarNumeros($event)"  [maxlength]="10">
                  <i matSuffix class="bi bi-telephone-fill fs-4" [ngClass]="{'text-warning': formRegistrar.get('strTelefono').invalid ,'text-success': !formRegistrar.get('strTelefono').invalid }"></i>
              </mat-form-field>
              <mat-error *ngIf="formRegistrar.get('strTelefono').hasError('required') && formRegistrar.get('strTelefono').touched" class="fs10">
                  Campo Obligatorio
              </mat-error>
              <mat-error *ngIf="formRegistrar.get('strTelefono').hasError('minlength')" class="fs10">
                  Se requieren exactamente 10 caracteres.
              </mat-error>
          </div>  
          <div class="col-12 col-md-6  mt-3">
              <mat-form-field appearance="outline" class="w-100 bg-white shadow-sm rounded">
                  <mat-label>Correo</mat-label>
                  <input matInput placeholder="Placeholder" formControlName="strCorreo" >
                  <i matSuffix class="bi bi-envelope-at-fill fs-4" [ngClass]="{'text-warning': formRegistrar.get('strCorreo').hasError('required') || formRegistrar.get('strCorreo').hasError('pattern'),'text-success': !formRegistrar.get('strCorreo').hasError('required')}"></i>
              </mat-form-field>
              <mat-error *ngIf="formRegistrar.get('strCorreo').hasError('required') && formRegistrar.get('strCorreo').touched" class="fs10">
                  Campo Obligatorio
              </mat-error>
              <mat-error *ngIf="formRegistrar.get('strCorreo').hasError('pattern')" class="fs10">
                Ingrese un correo valido
            </mat-error>
          </div> 
          <div class="col-6">
            <mat-form-field appearance="outline" class="w-100" >
              <mat-label>Fecha de nacimiento</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="fechaNacimiento">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>         
      </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" #btnCerrarCteNuevo data-bs-dismiss="modal">Cerrar</button>
        <button type="button"(click)="fnGuardarNuevoCliente()" class="btn btn-primary" [disabled]="formRegistrar.invalid">Guardar</button>
      </div>
    </div>
  </div>
</div>
