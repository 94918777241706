<div class="container">
  <div class="row my-3">
    <div class="col-12 text-center">
        <h1 class="fw-bold"> Carrito Comodatos</h1>
    </div>
  </div>

  <!-- Cliente -->
  <div class="row shadow rounded1 bg-white">
    <div class="col-12 titulos d-flex align-items-center py-2 roundedcrd">
      <span class="fs-5 fw-bold"> Información del cliente</span>
    </div>
    <div class="col-12 my-2">
      <div class="col-12 col-sm-4 col-xl-2 text-center mb-3">
        <mat-form-field appearance="outline" class="w-100 bgwhite rounded">
          <mat-label>Cliente</mat-label>
          <input matInput class="" readonly   [(ngModel)]="objCliente.strCliente" placeholder="Ingrese número de cliente" aria-label="Recipient's username" aria-describedby="button-addon2" autocomplete="off" matInput/>
          <i class="bi bi-person fs-4 iconoColor" matSuffix [ngClass]="{'text-success': cteSelect,'text-secondary': !cteSelect}"></i>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-xl-4 text-center mb-3">
        <mat-form-field appearance="outline" class="w-100 bgwhite rounded">
          <mat-label>Nombre</mat-label>
          <input matInput [(ngModel)]="objCliente.strNombre" class="" readonly />
          <i class="bi bi-person-vcard fs-4 iconoColor" matSuffix [ngClass]="{ 'text-success': cteSelect, 'text-secondary': !cteSelect }" ></i>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3 d-flex justify-content-center align-items-center">
       <!-- <mat-form-field appearance="outline" class="w-100 bgwhite rounded">
          <mat-label>Sucursal</mat-label>
          <input matInput [(ngModel)]="objCliente.intIDSucursal" class="" readonly />
          <i class="bi bi-building-gear fs-4 iconoColor" matSuffix [ngClass]="{ 'text-success': cteSelect, 'text-secondary': !cteSelect }" ></i>
        </mat-form-field>-->

        <mat-form-field  class="col-10" appearance="outline">
            <mat-label>Sucursal  </mat-label>
            <mat-select [(ngModel)]="objCliente.strNombreSucursal" name="sucursal" >
                <mat-option class="py-3" [value]="''"[disabled]="true"  > 
                    <input type="search" [(ngModel)]="strBuscar" class="form-control" placeholder="Buscar Sucursal" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                </mat-option>
                <mat-option  selected [value]="''" (click)="fnllenarInputs(objCliente,{},2);" > <!--(selectionChange)="onSelectionChange($event,objCliente)""-->
                    --Seleccione Sucursal--
                </mat-option>
            
              <mat-option class="fs11" matTooltip="{{food.strNombreSucursal}}" [ngClass]="{'d-none':food.strNombreSucursal == '' }"   *ngFor="let food of arraySucursalesCte | filtersearch: strBuscar: ['strNombreSucursal']" [value]="food.strNombreSucursal" (click)="fnllenarInputs(objCliente,food,2);"> <!-- .strNombreSucursal | slice:0:20-->
              <b>({{food.intIDSucursal}} ) </b>  {{food.strNombreSucursal}}
              </mat-option>
            </mat-select>
            <span matSuffix class="material-symbols-outlined">
                location_city
            </span>
        </mat-form-field>

      </div>
      <!--<div class="col-12 col-sm-6 col-xl-4 text-center mb-3">
        <mat-form-field appearance="outline" class="w-100 bgwhite rounded">
          <mat-label>Nombre Sucursal</mat-label>
          <input matInput [(ngModel)]="objCliente.strNombreSucursal" class="" readonly/>
          <i class="bi bi-buildings fs-4 iconoColor" matSuffix [ngClass]="{ 'text-success': cteSelect, 'text-secondary': !cteSelect }" ></i>
        </mat-form-field>
      </div>-->
    </div>
  </div>
  <!--Fin Cliente  -->

  <div class="row my-3">
    <div class="col-12 col-md-8 roundedcrd mx-0">
        <div class="row ">
            <div class="col-12 titulos roundedcrd  align-middle d-flex justify-content-between align-items-center">
                <h2 class="mb-0 me-aunto">Solicitud</h2>
                <h4 class="mb-0 fw-bold ">Productos: <span class="clsprod iconoColor">{{totalProductos}}</span></h4>
            </div>
            <div class="col-12  py-3 navBg">
                <div class="row">
                    <div class="col-12 col-md-6 text-center mb-3" >
                        <mat-form-field appearance="outline" class="w-100 bg-white rounded">
                            <mat-label>Requiere Instalación</mat-label>
                            <mat-select [(ngModel)]="strInstalacion" (selectionChange)="fnGetComodatos({strTipoSolicitud:strTipoSolicitud, strInstalacion:strInstalacion, objMOVID :{}})"><!--  -->
                                <mat-option *ngFor="let a of arrayInstalacion" [value]="a.strRespuesta">
                                    {{a.strRespuesta}}
                                </mat-option>
                            </mat-select>
                            <i class="bi bi-wrench-adjustable-circle fs-4 iconoColor" matSuffix></i>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 text-center mb-3" >
                        <mat-form-field appearance="outline" class="w-100 bg-white rounded">
                            <mat-label>Tipo de Solicitud</mat-label>
                            <mat-select [(ngModel)]="strTipoSolicitud" (selectionChange)="fnGetComodatos({strTipoSolicitud:strTipoSolicitud, strInstalacion:strInstalacion, objMOVID :{}})" ><!--  -->
                                <mat-option *ngFor="let a of arraySolicitud" [value]="a.strRespuesta">
                                    {{a.strRespuesta}}
                                </mat-option>
                            </mat-select>
                            <i class="bi bi-file-earmark-text fs-4 iconoColor" matSuffix></i>
                        </mat-form-field>
                    </div>
                </div> 
                <div class="row" *ngIf="strTipoSolicitud=='REPOSICIÓN'">
                    <div class="col-12 col-md-6 text-center mb-3" >
                        <mat-form-field appearance="outline" class="w-100 bg-white rounded">
                            <mat-label>MovID Intelisis                            
                            </mat-label>
                            <mat-select [(ngModel)]="strMOVID" (selectionChange)="fnGetComodatosDetalle(strMOVID)"  [compareWith]="compareWithFn" ><!--  -->
                                <mat-option [value]="{}">Selecciona un Comodato</mat-option>
                                <mat-option *ngFor="let age of arrayComodatosM" [value]="age">
                                    
                                    {{age.strMovID  }}
                                </mat-option>
                            </mat-select>
                            <i class="bi bi-person-vcard fs-4 iconoColor" matSuffix></i>
                        </mat-form-field>
                    </div>
             
                </div>
            </div>
        </div>
        <div class="row h80">
            <div class="col-12 my-md-0 roundedcrdbottom px-0 mx-0 ">
                <div class="row py-3 bg-white" *ngFor="let obj of arrayCarritoArts; index as ind;"> <!---->
                    <div class="col-12">
                        <div  class="row d-flex justify-content-center "> <!---->
                            <hr>
                            <div class="col-6 col-lg-3  "><!--d-flex justify-content-center-->
                               <div class="row justify-content-center">
                                    <div class="col-10">
                                        <div class="col-12">
                                            <span class="fs18 fw-bold oxxfordColor" style="text-align: center;"> <i class="bi bi-caret-right-fill"></i>
                                                {{obj.strArticulo}} 
                                            </span> 
                                        </div>
                                        <div class="col-12 text-center">
                                            <img [attr.src]="'https://www.cleanclean.mx/imagenes/' + obj.strArticulo + '.JPG'"  class="w-75 " alt=""> 
                                        </div>
                                        <div class="col-12">
                                            <span class="fs10 oxxfordColor"> {{obj.strDescripcion1}}</span>
                                        </div>
                                    </div> 
                                </div>
                                <div class="row"> 
                                    <div class="col-11 mx-auto">
                                        <div class="row">

                                      
                                    <div class="col-6  border-end"> <!--obj.arrayUbicacionesDesp-->
                                        <button matTooltip="Responder Cuestionario"  data-bs-toggle="modal" data-bs-target="#modalCuestionario"
                                         class="btn  fs-16 p-2 shadow-sm d-flex align-items-center" 
                                         [ngClass]="{'greenBackColor': obj.preguntasCompletas === 1, 'oxxfordBackColor': (!obj.preguntasCompletas || obj.preguntasCompletas==0) }"
                                         
                                         (click)="fnAbreModalCuestionario(obj.dblCantidad,obj.strArticulo, obj)">
                                            <i class="bi bi-question-lg"></i>
                                        </button>
                                    </div>
                                  <!--  <div class="col-4 px-3 border-start   border-end"> 
                                        <button matTooltip="Artículos Relacionados" data-bs-toggle="modal" data-bs-target="#modalArticulo" (click)="fnmodal(obj,ind)" class="btn purpleBackColor  fs-16 shadow  p-2 d-flex align-items-center">
                                            <i class="bi bi-link-45deg"></i>
                                        </button>
                                    </div>-->
                                    <div class="col-6 px-3 border-start "> <!---->
                                        <button matTooltip="Eliminar Articulo" (click)="fnDeleteCar(ind,obj)" class="btn btn-danger fs-16 p-2 shadow-sm d-flex align-items-center">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="col-6 col-lg-9 ">
                                <div class="row  d-flex justify-content-between align-items-center " >
                                    <div class=" col-12 col-lg-6 d-flex align-items-center justify-content-center my-3 my-lg-0 "> 
                                        <div class="px-3 d-flex flex-column rounded bg-light shadow-sm">
                                            <span class="mx-auto fs-12 greenColor text-nowrap"><i class="bi bi-dot"></i> Precio Unitario</span> 
                                            <span class="mx-auto fw-bold fs-16">{{(obj.dblPrecioLista)|currency}}</span>
                                         </div>
                                    </div>
                                    <div class="col-12 col-lg-6  d-flex align-items-center justify-content-center"> 
                                        <div class="px-3 d-flex flex-column rounded bg-light shadow-sm">
                                            <span class="mx-auto fs-12 greenColor"><i class="bi bi-dot"></i> Importe Total</span> 
                                            <span class="mx-auto fw-bold fs-16">{{(obj.dblPrecioLista * obj.dblCantidad)|currency}}</span>
                                         </div>
                                    </div>
                                </div>

                                <div class="row my-2 my-lg-4 flex-column-reverse flex-lg-row">
                                    <div class="col-12 col-lg-6 d-flex flex-column align-items-center mt-3 mt-lg-0">
                                        <div class="col-12">
                                            <span class="fw-bolder mb-0 fs-7 me-2 purpleColor text-nowrap">  
                                                <i class="bi bi-dot"></i> Cantidad:
                                            </span>
                                        </div>
                                        <div class="btn-group " role="group" aria-label="Basic example">
                                            <button type="button" [ngClass]="{'text-primary':obj.dblCantidad>1,'text-black-50':obj.dblCantidad<=1}" (click)="fnbuttomGroup(obj,'-')" class="btn border border-end-0 fw-bolder  py-0 px-2 btn1"><span><i class="bi bi-dash-circle-fill blueDColor"></i></span></button>
                                                <input readonly type="text" value="{{obj.dblCantidad}}" class="form-control text-center rounded-0 border-start-0 border-end-0 p-0 fs-16">
                                            <button  type="button" (click)="fnbuttomGroup(obj,'+')" class="btn border border-start-0 fw-bolder text-primary py-0 px-2 btn1 "><i class="bi bi-plus-circle-fill blueDColor"></i></button>
                                        </div>
                                    </div>    
                                    <div class="col-12 col-lg-6">
                                        <div class="" role="group" aria-label="Basic example" >
                                            <span class="fw-bolder mb-0 fs-7 me-2 purpleColor text-nowrap" [hidden]="obj.strTipoOpcion == 'No'">  
                                                <i class="bi bi-dot"></i> Opción:
                                            </span>
                                            <div class="me-2" [hidden]="obj.strTipoOpcion == 'No'">
                                                <select class="form-select shadow-sm fs-14" [(ngModel)]="obj.strOpcion" aria-label=".form-select-lg example" (change)="onOpcionSeleccionada(obj.strOpcion)">
                                                    <option *ngFor="let item of obj.arrayOpciones; let i = index;"  [ngValue]="item.strOpcion"> 
                                                        <span class="me-2"> <b> {{item.strOpcion}}</b></span>&nbsp;&nbsp;
                                                        <span>
                                                            {{item.strNombreOpcion}}
                                                        </span> 
                                                    </option>
                                                </select>
                                            </div>  
                                        </div> 
                                    </div>
                                </div>

                                <div class="row ">
                                    <div class="col-12 col-lg-6 mt-2 mt-lg-0 mb-3 mb-lg-0">
                                        <div class="w-15 " role="group" aria-label="Basic example" >
                                                <span class="fw-bolder mb-0 fs-7 me-2 purpleColor text-nowrap" ><i class="bi bi-dot"></i>
                                                    Consumible:
                                                </span>
                                                <div class=" me-2">
                                                    <select class="form-select shadow-sm fs-14" aria-label=".form-select-lg example"  
                                                    [compareWith]="compareObjects"
                                                    [(ngModel)]="obj.objConsumible" (change)="fnCambiaConsumible(obj.objConsumible,obj)">
                                                        <option *ngFor="let c of obj.arrayConsumible" [ngValue]="c ">
                                                           ({{c.strConsumible }})  {{c.strNombreConsumible}}
                                                          
                                                        </option>
                                                    </select>
                                                </div>
                                        </div>
                                    </div>


                                    <div class="col-12 col-lg-6  mb-3 mb-lg-0" [hidden]="obj.objConsumible.strTipoOpcion == 'No'">
                                        <!--{{obj.objOpcionConsumible | json}}  -->
                                        <div class="" role="group" aria-label="Basic example" >
                                            <span class="fw-bolder mb-0 fs-7 me-2 purpleColor text-nowrap"  ><i class="bi bi-dot"></i>
                                                Opción Consumible:
                                            </span>
                                            <div class=" me-2" >
                                                <select class="form-select shadow-sm fs-14"  [compareWith]="compareObjects2" [(ngModel)]="obj.objOpcionConsumible " aria-label=".form-select-lg example" (change)="fnUpdateAmountCar(obj )">
                                                    <option *ngFor="let item of obj.arrayOpcionConsumible  ; let i = index;"  [ngValue]="item"> 
                                                        <span class="me-2"> <b> {{item.strOpcion}}</b></span>&nbsp;&nbsp;
                                                        <span>
                                                            {{item.strNombreOpcion}}
                                                        </span> 
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div> 
                            
                           
                               
                             
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div class="row roundedcrdbottom shadow-sm titulos">
        <div class="col-12 d-flex justify-content-between py-3">
            <button class="btn btnreg  shadow-sm" [routerLink]="['/comodatos_lineas']">
                <i class="bi bi-reply-all"></i>   Continuar Comprando
            </button>
            <button class="btn rose text-white shadow-sm" (click)="fnBorrarCarrito()">
                <i class="bi bi-trash3"></i> Borrar Carrito
            </button>
        </div>
    </div>
    </div>
    <div class="col-12 col-md-4 my-3 my-md-0 ">
        <div class="col-12 col-md-11 ms-md-auto">
            <div class="row roundedcrd titulos text-center">
                <h3 class="mb-0 fw-bold">Resumen de la Solicitud</h3>
            </div>
            <div class="row  justify-content-center bg-white ">
                <div class="col-6 shadow-sm bg-light my-3 fs16 fw-bold">
                    SUB TOTAL:
                 </div>
                 <div class="col-5 shadow-sm bg-light my-3 fs18 fw-bold">
                      {{ dblSubTotal|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span *ngIf="strConexion=='DOMINICANA'">DOP</span>  
                  </div>
            </div>
            <div class="row  justify-content-center bg-white ">
                <div class="col-6  shadow-sm bg-light my-3 fs16 fw-bold">
                    <span *ngIf="strConexion=='COMERCIAL'">IVA 16%:</span><span *ngIf="strConexion=='DOMINICANA'">ITBIS 18%:</span> 
                </div>
                <div class="col-5  shadow-sm bg-light my-3 fs18 fw-bold">
                    {{ fnSubiva()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span *ngIf="strConexion=='DOMINICANA'">DOP</span> 
                </div>
            </div>
            <div class="row  justify-content-center bg-white ">
                <div class="col-6  shadow-sm bg-light my-3 fs16 fw-bold">
                    Total
                </div>
                <div class="col-5  shadow-sm bg-light my-3 fs18 fw-bold">
                    {{fnTotal()|currency}} <span *ngIf="strConexion=='COMERCIAL'">MXN</span><span *ngIf="strConexion=='DOMINICANA'">DOP</span> 
                </div>
            </div>
            <div class="row bg-white">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class=" row bg-white pb-3 justify-content-center">
                <div class="col-10">
                    <label for="" class="fw-bold">Fecha de Instalación</label>
                    <input type="date" class="form-control" [min]="fechaMinima"   (keydown)="false" [(ngModel)]="strFechaInstalacion" (blur)="onFechaInstalacionBlur()"/>
                </div>
            </div>

            <div class=" row bg-white pb-3 justify-content-center">
                <div class="col-10">
                    <label for="" class="fw-bold d-flex align-items-center "><i class="bi bi-people me-lg-2 fs-6 iconoColor"></i>Se dirigira con </label>
                    <input type="text" class="form-control" [(ngModel)]="strCteDirige"/>
                </div>
            </div>

            <!--<div class=" row bg-white pb-3 justify-content-center">
                <div class="col-10">
                    <label for="" class="fw-bold d-flex align-items-center "><i class="bi bi-telephone-forward me-lg-2 fs-6"></i> Telefono de contacto </label>
                    <input type="text" class="form-control" [(ngModel)]="strTelContacto"/>
                </div>
            </div>-->

            <div class=" row bg-white pb-3 justify-content-center ">
                <div class="col-10">
                    <label for="" class="fw-bold d-flex align-items-center "><i class="bi bi-chat-text me-lg-2 fs-6 iconoColor"></i> Observaciónes </label>
                    <input type="text" class="form-control" [(ngModel)]="strObservaciones"/>
                </div>
            </div>



            <div class="row bg-white">
                <div class="col-12">
                    <hr>
                </div>
            </div>



            <div class="row bg-white justify-content-center pb-3">
                <div class="col-10">
                    <span class="fw-bold fs-6">Carta Solicitud Comodato</span>
                </div>
                
                <div class="col-10">
                    <button [disabled]="!fnvalText() || fileContr" class="btn  fw-normal text-white w-100 text-nowrap" [ngClass]="{'btn-success':fileContr,'btnEnvDoc':!fileContr}" (click)="cliclkInputFile();">{{textFile}} <i class="align-middle fs-5 bi " [ngClass]="{'bi-send-check ':fileContr,'bi-cloud-arrow-up ':!fileContr}"></i></button>
                    <input type="file" class="form-control d-none"  (change)="onFileSelected($event)" name="" #inpContrato accept="image/*, .pdf">
                </div>
            </div>
            <div class="row bg-white justify-content-center pb-3" *ngIf="strTipoSolicitud == 'REPOSICIÓN'" >
                <div class="col-10">
                    <span class="fw-bold fs-6">Reposición Comodato</span>
                </div>
               
                <div class="col-10">
                    <button [disabled]="!fnvalText()||fileRenov" class="btn  fw-normal text-white w-100 text-nowrap" [ngClass]="{'btn-success':fileRenov,'btnEnvDoc ':!fileRenov}" (click)="cliclkInputFileRenov();">{{textRenov}} <i class="align-middle fs-5 bi " [ngClass]="{'bi-send-check ':fileRenov,'bi-cloud-arrow-up ':!fileRenov}"></i></button>
                    <input type="file" class="form-control d-none"  (change)="onFileRenov($event)" name="" #inpRenovacion accept="image/*, .pdf">
                </div>
                    </div>
            <div class="roundedcrdbottom row bg-white pb-3 justify-content-center">
                <div class="col-10"> <!-- || !this.fileContr-->
                    <button  [disabled]="!fnvalText()  || arrayCarritoArts.length < 1  || fileContr == false || !fileRenov " *ngIf="objCliente.strNombre != ''"  class="btn btn-primary w-100" (click)="fnFormarRespuestas()">
                        Enviar Comodato
                    </button>
                </div>
            </div>
        </div>
       
    </div>
   
  </div>
</div>
  <!-- Inicia modal -->
  <div class="modal fade" id="modalArticulo" tabindex="-1" aria-labelledby="modalArticuloLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header py-2 bg-whitees">
          <h1 class="modal-title mb-0 fw-bold" id="modalArticuloLabel">Artículos Relacionados</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body py-0">
            <div class="row"> 
                   
            </div>
        </div>
        <div class="modal-footer py-2 bg-whitees">
            
            <button type="button" class="btn btn-primary me-auto" >Guardar cambios</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarModal>Cerrar</button>
          
        </div>
      </div>
    </div>
  </div>
<!-- Fin modal   style="visibility: hidden;"  [hidden]="!vartri"-->
  <!-- Inicia modal -->
  <div class="modal fade" id="modalCuestionario" tabindex="-1" aria-labelledby="modalCuestionarioLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header py-2 navBg blueDColor">
            <h1 class="modal-title mb-0 fw-bold" id="modalCuestionarioLabel">Preguntas Para {{strDespachador_titulo}}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body ">
            <div class="row"> 

                <div [ngClass]="{'col-4': strRTipoComodato === 'Venta', 'col-6': strRTipoComodato !== 'Venta'}" >
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-dot iconoColor2"></i>
                           Tipo Comodatos 
                        </span>
                        <div class="col-7 me-2" >
                          <select class="form-select shadow-sm fs-14"   [(ngModel)]="strRTipoComodato" aria-label=".form-select-lg example" (change)="fnCambiaTipoCom()" [disabled]="muestraarraytipo==0">
                                <option *ngFor="let tipo of arrayTipoComodato ; let i = index;"  [ngValue]="tipo.strRespuesta"> 
                                     <span class="me-2"> <b> {{tipo.strRespuesta}}</b></span>&nbsp;&nbsp;
                                       
                                </option>
                            </select>
                        </div> 
                    </div>
                </div>
                <div [ngClass]="{'col-4': strRTipoComodato === 'Venta', 'col-6': strRTipoComodato !== 'Venta'}" >
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" *ngIf="strRTipoComodato=='Renta'">
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-coin iconoColor2"></i>
                           Renta Mensual
                        </span>
                        <div class="col-7 me-2" > 
                            <input class="form-control"  [(ngModel)]="dblRRentaMensual" type="number" placeholder="Ingresa Valor"aria-label="default input example">
                        </div> 
                    </div>
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" *ngIf="strRTipoComodato=='Venta'">
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-coin iconoColor2"></i>
                           Precio Consumible
                        </span>
                        <div class="col-7 me-2" > 
                            <input class="form-control"  [(ngModel)]="dblRPrecioConsumible" type="number" placeholder="Ingresa Valor" aria-label="default input example">
                        </div> 
                    </div>
                </div>
                <div class="col-4  " *ngIf="strRTipoComodato=='Venta'" >
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-dot iconoColor2"></i>
                           Cantidad Consumible
                        </span>
                        <div class="col-6 me-2" > 
                            <input class="form-control"  [(ngModel)]="dblRCantidadMensual" type="number" placeholder="Ingresa Valor"aria-label="default input example">
                        </div> 
                    </div>
                     
                </div>
            </div>
            <div class="row mt-4">  
                <hr>
                <div class="col-4  ">
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-alarm iconoColor2"></i>
                           Inicio Actividad
                        </span>
                        <div class="col-8" > 
                            <input class="form-control fs-14"  [(ngModel)]="dteHrInicioAct" type="time" placeholder="Ingresa Valor"aria-label="default input example"
                             (ngModelChange)="fnCambiaHrInicioGlobal(dteHrInicioAct)"> 
                        </div> 
                    </div>
                     
                </div>
                <div class="col-4  ">
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-alarm iconoColor2"></i>
                           Fin Actividad
                        </span>
                        <div class="col-8" > 
                            <input class="form-control fs-14 "  [(ngModel)]="dteHrFinAct" type="time" placeholder="Ingresa Valor"aria-label="default input example" (ngModelChange)="fnCambiaHrFinGlobal(dteHrFinAct)">
                        </div> 
                    </div>                     
                </div>
                <div class="col-4  ">
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-calendar-date iconoColor2"></i>
                           Días Actividad
                        </span>
                        <div class="col-8" >
                          <select class="form-select shadow-sm fs-14"   [(ngModel)]="intDiasAct" aria-label=".form-select-lg example" (ngModelChange)="fnCambiaDias(intDiasAct)" >
                                <option *ngFor="let tipo of arrayDiasActividad ;"  [ngValue]="tipo.intDia"> 
                                     <span class="me-2"> <b> {{tipo.intDia}}</b></span>                                       
                                </option>
                            </select>
                        </div> 
                    </div>                   
                </div>
            </div>
            <div class="row mt-4">  
                
                <div class="col-4  ">
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-alarm iconoColor2"></i>
                           Inicio Actividad
                        </span>
                        <div class="col-8" > 
                            <input class="form-control fs-14"  [(ngModel)]="dteHrInicioAct_2" type="time" placeholder="Ingresa Valor"aria-label="default input example"  (ngModelChange)="fnCambiaHrInicioGlobal_2(dteHrInicioAct_2)">
                        </div> 
                    </div>
                     
                </div>
                <div class="col-4  ">
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-alarm iconoColor2"></i>
                           Fin Actividad
                        </span>
                        <div class="col-8 " > 
                            <input class="form-control fs-14 "  [(ngModel)]="dteHrFinAct_2" type="time"
                             placeholder="Ingresa Valor"aria-label="default input example" (ngModelChange)="fnCambiaHrFinGlobal_2(dteHrFinAct_2)">
                        </div> 
                    </div>                     
                </div>
                <div class="col-4  ">
                    <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                        <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-calendar-date iconoColor2"></i>
                           Días Actividad
                        </span>
                        <div class="col-8" >
                          <select class="form-select shadow-sm fs-14"   [(ngModel)]="intDiasAct_2" aria-label=".form-select-lg example"   (ngModelChange)="fnCambiaDias_2(intDiasAct_2)">
                                <option *ngFor="let tipo of arrayDiasActividad ;"  [ngValue]="tipo.intDia"> 
                                     <span class="me-2"> <b> {{tipo.intDia}}</b></span>                                       
                                </option>
                            </select>
                        </div> 
                    </div>                   
                </div>
            </div>
            <div class="row mt-4"> 
                <hr>
                <!--inicia captura de ubición-->
                <div class="accordion" id="accordionExample" >
                       
                    <div class="accordion-item" *ngFor="let despachador of displayedItems; let i = index">
                        <h1 class="accordion-header" [id]="'heading' + i">
                         <button class="accordion-button collapsed  " type="button" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                            <i *ngIf="despachador.checkCompleto==0" class="bi bi-circle-fill oxxfordColor me-1"></i>
                            <i *ngIf="despachador.checkCompleto==1" class="bi bi-check-circle-fill greenColor me-1"></i>
                            Ubicación Para {{despachador.despachador}} #{{despachador.id}}
                          </button>
                        </h1>
                           <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" [attr.data-bs-parent]="'#accordionExample'">
                          <div class="accordion-body">
                           <!--inicia cuerpo de acordeon-->
                           <div class="row">
                                <div class="col-6" >
                                    <div class="row">
                                         <div class="col-4">
                                            <span class="fw-bolder  fs-14  blueDColor"  ><i class="bi bi-building-fill iconoColor2"></i>
                                                Edificio
                                            </span>
                                         </div>
                                         <div class="col-8  " >
                                            <div class="btn-group" role="group" aria-label="Basic example" >
                                                
                                                    <div class="input-group mb-3">
                                                        <input type="text" class="form-control" aria-label="Text input with dropdown button" [(ngModel)]="despachador.edificio" (change)="fnValidarCompleto(despachador.id)">
                                                    
                                                        <select class="form-select dropdown-toggle fs8 oxxfordBackColor" aria-label="Default select example"  [(ngModel)]="despachador.intRepetirEdificio" (change)="fnRepetirEdificio(despachador.edificio,despachador.intRepetirEdificio, despachador.id)">
                                                            <option *ngFor="let desp of arrayUbicacionesDesp " [ngValue]="desp.id">Aplicar a {{desp.id}} Despachadores</option>
                                                        
                                                        </select>
                                                    </div>
                                                
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <!---->
                                <div class="col-6" >
                                    <div class="row">
                                        <div class="col-4" >
                                            <span class="fw-bolder mb-0 fs-14 me-2 blueDColor"  ><i class="bi bi-building-fill-up iconoColor2"></i>
                                                Nivel
                                            </span>
                                        </div>
                                        <div class="col-8" >
                                            <div class="btn-group " role="group" aria-label="Basic example" >
                                            
                                            
                                                <div class="input-group mb-3">
                                                <!--<input type="text" class="form-control" aria-label="Text input with dropdown button" [(ngModel)]="despachador.nivel">-->
                                                    <select class="form-select dropdown-toggle " aria-label="Default select example"  [(ngModel)]="despachador.nivel" (change)="fnValidarCompleto(despachador.id)"><!-- (change)="fnRepetirNivel(despachador.nivel,despachador.intRepetirNivel)"-->
                                                        <option *ngFor="let nivel of arrayNiveles" [ngValue]="nivel.strNivel"> {{nivel.strNivel}}</option>
                                                
                                                </select>
                                                
                                                    <select class="form-select dropdown-toggle fs8 oxxfordBackColor" aria-label="Default select example"  [(ngModel)]="despachador.intRepetirNivel" (change)="fnRepetirNivel(despachador.nivel,despachador.intRepetirNivel, despachador.id)">
                                                        <option *ngFor="let desp of arrayUbicacionesDesp " [ngValue]="desp.id">Aplicar a {{desp.id}} Despachadores</option>
                                                    
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                              
                                

                           </div>
                           <div class="row ">
                            <div class="col-6" >
                                <div class="row">
                                    <div class="col-4">
                                        <span class="fw-bolder fs-14 blueDColor"  ><i class="bi bi-app iconoColor2"></i>
                                           Nombre Área
                                        </span>
                                    </div>
                                    <div class="col-8" >
                                        <div class="btn-group" role="group" aria-label="Basic example" >
                                            <div class="input-group mb-3">
                                                    <input type="text" class="form-control" aria-label="Text input with dropdown button" [(ngModel)]="despachador.nombreArea"(change)="fnValidarCompleto(despachador.id)" > <!--(change)="fnRepetirNombreArea(despachador.nombreArea,despachador.intRepetirArea)"-->
                                                
                                                    <select class="form-select dropdown-toggle fs8 oxxfordBackColor" aria-label="Default select example"  [(ngModel)]="despachador.intRepetirArea" (change)="fnRepetirNombreArea(despachador.nombreArea,despachador.intRepetirArea, despachador.id)">
                                                        <option *ngFor="let desp of arrayUbicacionesDesp " [ngValue]="desp.id">Aplicar a {{desp.id}} Despachadores</option>
                                                    
                                                    </select>
                                            </div>
                                            
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                            <!---->
                                <div class="col-6" >
                                    <div class="row">
                                        <div class="col-4" >
                                            <span class="fw-bolder fs-14  blueDColor"  ><i class="bi bi-app-indicator iconoColor2"></i>
                                                Tipo Área
                                            </span>
                                        </div>
                                        <div class="col-8  " >
                                            <div class="btn-group  " role="group" aria-label="Basic example" >
                                                <div class="input-group mb-3">
                                                    <select class="form-select dropdown-toggle  " aria-label="Default select example"  [(ngModel)]="despachador.tipoArea" ><!--(change)="fnRepetirTipoArea(despachador.tipoArea,despachador.intRepetirTipoArea, despachador.id)"-->
                                                        <option *ngFor="let area of arrayTipoArea " [ngValue]="area.strArea"> {{area.strArea}}</option>
                                                
                                                    </select>
                                                
                                                    <select class="form-select dropdown-toggle fs8 oxxfordBackColor" aria-label="Default select example"  [(ngModel)]="despachador.intRepetirTipoArea" (change)="fnRepetirTipoArea(despachador.tipoArea,despachador.intRepetirTipoArea, despachador.id)">
                                                        <option *ngFor="let desp of arrayUbicacionesDesp " [ngValue]="desp.id">Aplicar a {{desp.id}} Despachadores</option>
                                                    
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                           </div>
                           <div class="row">
                            <div class="col-6" >
                                <div class="row">
                                    <div class="col-4">
                                        <span class="fw-bolder fs-14 blueDColor"  ><i class="bi bi-people iconoColor2"></i>
                                          Afluencia 
                                        </span>
                                    </div>
                                    <div class="col-8 " > 
                                       <div class="btn-group " role="group" aria-label="Basic example" >
                                            <div class="input-group mb-3">
                                                <select class="form-select dropdown-toggle " aria-label="Default select example"  [(ngModel)]="despachador.afluencia"  >
                                                    <option *ngFor="let area of arrayAfluencia " [ngValue]="area.strAfluencia"> {{area.strAfluencia}}</option>
                                            
                                                </select>
                                            
                                                <select class="form-select dropdown-toggle fs8 oxxfordBackColor" aria-label="Default select example"  [(ngModel)]="despachador.intRepetirAfluencia" (change)="fnRepetirAfluencia(despachador.afluencia,despachador.intRepetirAfluencia, despachador.id)">
                                                    <option *ngFor="let desp of arrayUbicacionesDesp " [ngValue]="desp.id">Aplicar a {{desp.id}} Despachadores</option>
                                                
                                                </select>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                           </div>
                           <div class="row mt-4">  
                            <hr class="orangeColor">
                            <div class="col-4  ">
                                <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                                    <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-alarm iconoColor2"></i>
                                       Inicio de Actividad
                                    </span>
                                    <div class="col-7 me-2" > 
                                        <input class="form-control fs-14"  [(ngModel)]="despachador.dteHrInicioAct" type="time" placeholder="Ingresa Valor"aria-label="default input example">
                                    </div> 
                                </div>
                                 
                            </div>
                            <div class="col-4  ">
                                <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                                    <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-alarm iconoColor2"></i>
                                       Fin de Actividad
                                    </span>
                                    <div class="col-7 me-2" > 
                                        <input class="form-control fs-14 "  [(ngModel)]="despachador.dteHrFinAct" type="time" placeholder="Ingresa Valor"aria-label="default input example">
                                    </div> 
                                </div>                     
                            </div>
                            <div class="col-4  ">
                                <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                                    <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-calendar-date iconoColor2"></i>
                                       Días Actividad
                                    </span>
                                    <div class="col-7 me-2" >
                                      <select class="form-select shadow-sm fs-14"   [(ngModel)]="despachador.intDiasAct" aria-label=".form-select-lg example"  >
                                            <option *ngFor="let tipo of arrayDiasActividad ;"  [ngValue]="tipo.intDia"> 
                                                 <span class="me-2"> <b> {{tipo.intDia}}</b></span>                                       
                                            </option>
                                        </select>
                                    </div> 
                                </div>                   
                            </div>
                        </div>
                        <div class="row mt-4">  
                            
                            <div class="col-4  ">
                                <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                                    <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-alarm iconoColor2"></i>
                                       Inicio de Actividad
                                    </span>
                                    <div class="col-7 me-2" > 
                                        <input class="form-control fs-14"  [(ngModel)]="despachador.dteHrInicioAct_2" type="time" placeholder="Ingresa Valor"aria-label="default input example">
                                    </div> 
                                </div>
                                 
                            </div>
                            <div class="col-4  ">
                                <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                                    <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-alarm iconoColor2"></i>
                                       Fin de Actividad
                                    </span>
                                    <div class="col-7 me-2" > 
                                        <input class="form-control fs-14 "  [(ngModel)]="despachador.dteHrFinAct_2" type="time" placeholder="Ingresa Valor"aria-label="default input example">
                                    </div> 
                                </div>                     
                            </div>
                            <div class="col-4  ">
                                <div class="btn-group w-15 mx-3" role="group" aria-label="Basic example" >
                                    <span class="fw-bolder mb-0 fs-7 me-2 blueDColor"  ><i class="bi bi-calendar-date iconoColor2"></i>
                                       Días Actividad
                                    </span>
                                    <div class="col-7 me-2" >
                                      <select class="form-select shadow-sm fs-14"   [(ngModel)]="despachador.intDiasAct_2" aria-label=".form-select-lg example"  >
                                            <option *ngFor="let tipo of arrayDiasActividad ;"  [ngValue]="tipo.intDia"> 
                                                 <span class="me-2"> <b> {{tipo.intDia}}</b></span>                                       
                                            </option>
                                        </select>
                                    </div> 
                                </div>                   
                            </div>
                        </div>
                             <!--finaliza cuerpo de acordeon-->
                        </div>
                        </div>
                      </div>
                      <mat-paginator [length]="arrayUbicacionesDesp?.length" [pageSizeOptions]="[5,10, 50, 100]" aria-label="Select page">
                    </mat-paginator>
                </div>
                
                <!--fin captura de ubicacion--> 
            </div>  

        </div>
        <div class="modal-footer py-2 bg-whitees">
            
            <button type="button" class="btn btn-primary me-auto greenBackColor"  (click)="fnGuardarPreguntas()" >Guardar cambios</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarModal2>Cerrar</button>
          
        </div>
      </div>
    </div>
  </div>
<!-- Fin modal   style="visibility: hidden;"  [hidden]="!vartri"-->