import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AccesosServiceService } from '../servicios/accesos-service.service';
import { DataDBService } from '../servicios/data-db.service';

@Component({
  selector: 'app-iconos-login',
  templateUrl: './iconos-login.component.html',
  styleUrls: ['./iconos-login.component.css'],
  animations: [
    trigger('rotateIcon', [
      state('initial', style({ transform: 'rotate(0deg)',padding:'15px 0 0 0'  })),
      state('rotatedLeft', style({ transform: 'rotate(45deg)',padding:'0 0 0 0'})),/*  */
      state('rotatedRight', style({ transform: 'rotate(-45deg)'  })), /*  */
      transition('initial => rotatedLeft', [animate('0.3s ease-in-out')]),
      transition('initial => rotatedRight', [animate('0.3s ease-in-out')]),
      transition('rotatedLeft => initial', [animate('0.3s ease-in-out')]),
      transition('rotatedRight => initial', [animate('0.3s ease-in-out')])
    ]),
    trigger('disappearIcon', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', [animate('0.5s ease-in-out')]),
      transition('hidden => visible', [animate('0.5s ease-in-out')])
    ]),trigger('menuAnimation', [
      state('hidden', style({
        transform: 'scale(0)', 
        opacity: 0,
        display: 'none' 
      })),
      state('visible', style({
        transform: 'scale(1)', 
        opacity: 1,
        display: 'block'
      })),state('visible1', style({
       opacity: 1,
        transform: 'rotate(0deg)'
      })),
      state('hidden2', style({
        transform: 'scale(0)', 
        opacity: 0,
      })),
      transition('hidden => visible', [
        animate('0.3s ease-out')
      ]),
      transition('visible => hidden', [
        animate('0.3s ease-in') 
      ]),
      transition('hidden2 => visible1', [
        animate('0.3s ease-out')
      ]),
      transition('visible1 => hidden2', [
        animate('0.3s ease-in') 
      ])
    ])
  ]
})
export class IconosLoginComponent implements OnInit {
  public boolMenu:boolean = false;
  public boolLogin:boolean = false;
  public arrayAccesos: any;
  public strUsuario: any; 
  constructor(private ServAccesos:AccesosServiceService, private indexedDBService: DataDBService,public localSt: LocalStorageService,public router: Router, public route: ActivatedRoute) { 
    this.arrayAccesos = this.localSt.retrieve('arrayAccesos'); 
    this.indexedDBService.usuario_$.subscribe((nuevoValor) => {
        this.strUsuario=nuevoValor?nuevoValor==0?'':nuevoValor  :'';
      }
      );
        
 /*    this.boolLogin =this.arrayAccesos && this.arrayAccesos.length>0?true:false; */
  }

  ngOnInit(): void {
    this.ServAccesos.accesoss$.subscribe((accesos)=>{
 
      this.arrayAccesos = accesos;
      this.boolLogin =this.arrayAccesos && this.arrayAccesos.length>0?true:false;
    }) 
  }


  fnCambiarMenu(){
    if(this.boolMenu){
      this.boolMenu = false;
    }else{
      this.boolMenu = true;
    }
  }
}
