import { Component, OnInit, AfterViewInit, ElementRef, ViewChildren,ViewChild,HostListener } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService} from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { AlertifyService } from '../alertify.service';
import { ComodatoService } from '../servicios/comodato.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insertar-comodatos',
  templateUrl: './comodatos.component.html',
  styleUrls: ['./comodatos.component.css']
})
export class InsertarComodatosComponent implements AfterViewInit {
  car:any={
    strTipoOpcion:"Si",
    strArticulo:"",
    strNombreOpcion:"",
    dblPrecioLista:10,
    dblCantidad:20,
  };
  dblSubTotal:number=0
  public strBuscar:string="";
  public strBuscarSuc:string="";
  public arrayLineas:any=[];
  public arrayArtsporlinea:any=[];
  public objLinea:any;
  public strlinea:string="";
  public cteSelect:boolean=false;
  public lststrDivisional:boolean=false;
  public lstSucursal:boolean=false;
  public lstVendedor:boolean=false;
  public lstEstatus:boolean=false;
  public strDivisional:string="";
  public strSucursal:string="";
  public strVendedor:string="";
  public strEstatus:string="";
  public bloques: any[][] = [];
  public strConexion:string="";
  public arrayClientes:any;
  public strGerente:string= "";
  public strAgente: string = "";
public arraySucursalesCte:any=[];
  public objCliente:any = {
    strCliente:"",
    strNombre:"",
    strDireccion:"",
    ClienteSeleccionado:false,
    strSucursal:"",
    NombreSucursal:"",
    tipoSucursal:""
  };
  public strNumCte:string;
  public strNombreCliente:string="";
  public strNombreSucursal:string="";
  public elementosPorPagina:number=0;
  public arrayExistentes:any;
  public objArt:any = {
    strArticulo:'', 
    strDescripcion1:'', 
    dblCantidad:1,
    strOpcion:'', 
    strNombreOpcion:'', 
    dblPrecio:0,
    objConsumible:{strTipoOpcion:'No', strConsumible:''} 
  };
  public objPrueba:any={}
  public spinerCarrito:boolean;
  public imagenUrl1: string;
  public imagenUrl2: string;
  public imagenUrl3: string;
  public imagenUrl4: string;
  public imagenUrl5: string ;
  public intPrecioPolitica:number =0;
  public intPrecioPlantilla:number =0;
  public intPrecioLista:number =0;
  public intPrecio:number=0;
  public intPrecio2:number=0;
  public intPrecio3:number=0;
  public intPrecio4:number=0;
  public Accesop4:any;
  public arrayCarritoArts:any;
  public arrayPreciosArt = [];
  public intTotalItems:number;
  public arrayConsumible:any;
  public arrayConsumibleOpcion:any;
  public tagName:string='';
  public strCorreoUser: string = "";
  public strUsuario: string = "";
  public arrayAccesos:any=[];

  public boolAgente:boolean= false;
  public boolSucursal:boolean= false;
  public boolGerente:boolean= false;
  constructor(  elem:ElementRef,public commodatoservice:ComodatoService,private router: Router,public _alertify: AlertifyService,private _httpService:PeticionesService,private localSt:LocalStorageService) { 
    this.strConexion = this.localSt.retrieve('strConexion');
    this.strAgente = this.localSt.retrieve('stragente');
  
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      }
      else{
        console.log("ingresa")
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
        this.strUsuario = this.arrayAccesos[0].strNombre + ' ' + this.arrayAccesos[0].strApellido;
        this.strCorreoUser = this.localSt.retrieve('strEmail');
        this.strGerente = "";
        this.strSucursal = "";
        this.strAgente = "";
      }
    }else{
      console.log("entra al init")
    }
  
   }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectarTamanioPantalla();
    this.fnpaginarcarousel();
  }
  ngAfterViewInit(){ 
  }


  ngOnInit(): void {

    this.lststrDivisional = false
    this.lstSucursal = true
    this.lstVendedor = true
    this.lstEstatus = true
    this.commodatoservice.intTotalItems_C$.subscribe((nuevoValor) => {
      this.intTotalItems = nuevoValor;
    });
    this.commodatoservice.arrayItems_C$.subscribe((nuevoValor) => {
      this.arrayCarritoArts = nuevoValor;
    });
    this.commodatoservice.dblSubTotalItems_C$.subscribe((nuevoValor) => {
      this.dblSubTotal = nuevoValor;
    });

    this.commodatoservice.ObjCliente_C$.subscribe((nuevoValor) => {
      this.objCliente = nuevoValor;
      if(nuevoValor.strCliente){
        this.strNumCte=nuevoValor.strCliente; 
        this.strNombreCliente=nuevoValor.strNombre;
        /* this.strSucursal = nuevoValor.intIDSucursal; */
        this.strNombreSucursal=nuevoValor.strNombreSucursal;
        this.cteSelect = true;
     
      }
    });
    if(this.objCliente.strCliente){
      this.fnGetSucursal_CTe()
    }
    this.detectarTamanioPantalla();
    this.fnExtraerlineas();
  }

  onKeyDown(event: KeyboardEvent): void { 
    console.log('elegirá otro cliente')
    this.commodatoservice.setCte({});

  }
  fnExtraerlineas(){
    this.arrayLineas=[];
    this._httpService.getLineasComodatos({
      strAccion:'getCategoriasComodatos',
      strConexion:this.strConexion
    },"sp_ModVentas_Comodato").subscribe(
      result => {
        this.arrayLineas = result;
       this.fnpaginarcarousel();
      });
  }

  redirectToCarrito() {
    this.router.navigate(['/carcmv2']);
  }

  fnBuscarCte(strNumCte:string){
    this.commodatoservice.setCte(this.objCliente);
    this.strNombreCliente = "";
    this.strNombreSucursal = "";
/*     this.strSucursal= ""; */
    this.cteSelect = false
    let cliente = {
      strAccion:"getCte",
      strCliente:strNumCte,
      strGerente:this.localSt.retrieve('strUsuario'), //strUsuario
      strTipoAcceso:this.localSt.retrieve('intIDRolAcceso'),//intIDRolAcceso
      strSucursal:this.localSt.retrieve('strSucursal'),//strSucursal
      strAgente:this.localSt.retrieve('strAgente'), //strAgente
      strConexion:this.strConexion
    };
    console.log(cliente)
    this._httpService.getCotizador(cliente).subscribe(
      result => {
        //devuelve password encriptado 
        this.arrayClientes = result; 
      
        const distinctArray = Array.from(new Map(this.arrayClientes.map(item => [item['strCliente'], item])).values());
        this.arrayClientes = distinctArray
      
      /*    let sucursales=[]
         this.arrayClientes.forEach(x => {
          if(x.strNombreSucursal!= ""){
            sucursales.push(x);
          }
         });  */
   
      });
  }
  
  getImageUrl(strArticulo: string): string {
    if (strArticulo) {
      return `https://www.cleanclean.mx/imagenes/${strArticulo}.jpg`;
    }
    return 'https://www.cleanclean.mx/imagenes/NO-DISPONIBLE.JPG';
  }

  onImageError(event: any): void {
    event.target.src = 'https://www.cleanclean.mx/imagenes/NO-DISPONIBLE.JPG';
  }

  fnllenarInputs(cte:any, sucursal:any, opcion:number){ /* , option:any */
/*     let cli = option;//this.arrayClientes[i]
    this.strNombreCliente = cli.strNombre
    this.strSucursal = cli.intIDSucursal ==0?'':cli.intIDSucursal;
    this.strNombreSucursal = cli.strNombreSucursal
    this.cteSelect = true
    this.objCliente =option// this.arrayClientes[i]
    this.commodatoservice.setCte(this.objCliente); */

/* 
console.log(cte)
    console.log(sucursal) */
    console.log('llenar inputs')
   /*  this.strSucursal = ''  */
    
    this.objCliente.strDireccion_cliente = !sucursal ? cte.strDireccion:this.objCliente.strDireccion_cliente;
    this.objCliente.strDireccion =  sucursal && sucursal.intIDSucursal ? sucursal.strDireccion : this.objCliente.strDireccion_cliente 

    this.objCliente.strNombreSucursal = sucursal && sucursal.intIDSucursal ? sucursal.strNombreSucursal :'';
    this.objCliente.intIDSucursal = sucursal && sucursal.intIDSucursal ?sucursal.intIDSucursal:null;

    this.objCliente.strAgente_cliente = !sucursal ? cte.strAgente:this.objCliente.strAgente_cliente;
    this.objCliente.strAgente = sucursal && sucursal.intIDSucursal? sucursal.strAgente: this.objCliente.strAgente_cliente ;

    this.objCliente.strAlmacen_cliente = !sucursal ? cte.strAlmacen:this.objCliente.strAlmacen_cliente;
    this.objCliente.strAlmacen = sucursal && sucursal.intIDSucursal ?sucursal.strAlmacen:  this.objCliente.strAlmacen_cliente;

    this.objCliente.strSucursal_cliente = !sucursal ? cte.strSucursal:this.objCliente.strSucursal_cliente;
    this.objCliente.strSucursal = sucursal && sucursal.intIDSucursal?sucursal.strSucursal :this.objCliente.strSucursal_cliente;


    this.objCliente.strNombre  = cte.strNombre
    this.objCliente.strCliente = cte.strCliente;
    this.objCliente.strClaveUsoCFDI = cte.strClaveUsoCFDI;
    this.objCliente.strCondicion = cte.strCondicion;
    this.objCliente.strDescripcionUsoCFDI = cte.strDescripcionUsoCFDI;
    this.objCliente.strInfopago = cte.strInfopago;
    this.objCliente.strInfopagoDescripcion = cte.strInfopagoDescripcion;

    this.objCliente.ClienteSeleccionado = true;
    this.objCliente.strTipo = cte.strTipo;
    console.log( this.objCliente)
    this.commodatoservice.setCte(this.objCliente); 
      if(opcion!=2){
        this.fnGetSucursal_CTe()
      }
  } 


fnpaginarcarousel(){
  this.bloques=[];
  for (let i = 0; i < this.arrayLineas.length; i += this.elementosPorPagina) {
    this.bloques.push(this.arrayLineas.slice(i, i + this.elementosPorPagina));
  } 
}

private detectarTamanioPantalla() {
  this.elementosPorPagina=0;
  const anchoPantalla = window.innerWidth;
  if (anchoPantalla <= 768) {
    this.elementosPorPagina = 3;
  } else {
    this.elementosPorPagina = 6;
  }
}

fnseleclinea(obj:any){
  this.objLinea ={};
  this.objLinea = obj;
  this.strlinea = obj.strGrupo;
  this.fnExtraerXlinea();
}

fnExtraerXlinea(){
  this.arrayArtsporlinea=[];
  let myobject1 = {strAccion:'getArt-Linea','strCategoria': this.objLinea.strCategoria.replace(/^\s+|\s+$/g, ""),'strGrupo': this.objLinea.strGrupo.replace(/^\s+|\s+$/g, ""),strConexion:this.strConexion};
  this._httpService.getLineasComodatos(myobject1,"sp_ModVentas_Comodato").subscribe(
    result => {
      this.arrayArtsporlinea = result;
      this.arrayArtsporlinea.forEach(x => {
        x.mosDescrip = false;
      }); 
    });
}

mostrarDescripcion(art: any) {
  art.mosDescrip = true;
}

ocultarDescripcion(art: any) {
  // Agregar un pequeño retraso antes de ocultar el elemento
    art.mosDescrip = false;
// Puedes ajustar el tiempo según tus necesidades
}

fnAbrirModal(objArt:any){
  objArt.objConsumible ={strTipoOpcion:'No', strConsumible:''};
  this.fnGetConsumible(objArt);
  if(objArt.strTipoOpcion != "No"){
    this.objArt.arrayOpciones =[];
    this.fnExtraerOpciones(objArt);
    
  } else {
     objArt.strNombreOpcion ='';
     objArt.strOpcion ='';
  }
  let url ='https://www.cleanclean.mx/imagenes/'
  console.log('abre modal');
  this.imagenUrl1=url+objArt.strArticulo+'.JPG';
  this.imagenUrl2= url+objArt.strArticulo+'-PO-2.JPG';
  this.imagenUrl3=url+objArt.strArticulo+'-PO-3.JPG';
  this.imagenUrl4=url+objArt.strArticulo+'-PO-4.JPG';
  this.imagenUrl5=url+objArt.strArticulo+'-PO-5.JPG';
  objArt.dblCantidad=1; //inicializamos la cantidad
  this.objArt=objArt;
  this.fnArtSinLp(objArt);
  this.fntraerExistencias(objArt);
}
fnGetConsumible(objArt:any){
 this.arrayConsumible=[];
  this._httpService.getLineasComodatos({
    strAccion:'getOpcionArticuloConsumible',
    strConexion:this.strConexion,
    strArticulo:objArt.strArticulo
  },"sp_ModVentas_Comodato").subscribe(
    result => {
     let arrayC =result;
     
     arrayC.forEach(element => {
      this.arrayConsumible.push({strTipoOpcion:element.strTipoOpcion, strConsumible:element.strConsumible, strNombreConsumible: element.strNombreConsumible});
     });
     this.objArt.arrayConsumible=this.arrayConsumible;
    
      this.objArt.objConsumible= this.arrayConsumible.length >0 ?   this.arrayConsumible[0] :{strTipoOpcion:'No', strConsumible:'', strNombreConsumible:''}; 

      if ( this.objArt.objConsumible &&  this.objArt.objConsumible.strTipoOpcion=='Si')
      {
        
        this.fnGetOpcionConsumible(this.objArt.objConsumible.strConsumible)
      }
     
   
    });
}
fnGetOpcionConsumible(articulo:string) {
  this.arrayConsumibleOpcion=[];
  this._httpService.getLineasComodatos({
    strAccion:'GetArticuloOpciones',
    strConexion:this.strConexion,
    strArticulo:articulo
  },"sp_ModVentas_Comodato").subscribe(
    result => {
      let arrayC=result;
      arrayC.forEach(element => {
        this.arrayConsumibleOpcion.push({strOpcion:element.strOpcion, strNombreOpcion:element.strNombreOpcion });
       });

    
     // this.objArt.objConsumible.objOpcion=this.arrayConsumibleOpcion.length>0? this.arrayConsumibleOpcion[0]:{};
      this.objArt.objOpcionConsumible=this.arrayConsumibleOpcion.length>0? this.arrayConsumibleOpcion[0]:{};
      this.objArt.arrayOpcionConsumible=this.arrayConsumibleOpcion; 
   
    });
}
fnCambiaConsumible(objArt:any){
  if ( objArt &&  objArt.strTipoOpcion=='Si')
  {
    this.fnGetOpcionConsumible(this.objArt.objConsumible.strConsumible)
  }
}
fnExtraerOpciones(objArt:any){
  let articulo = {
    strAccion:'getOpcion',
    strArticuloBusqueda:objArt.strArticulo,
    strConexion:this.strConexion
  };
  this._httpService.getCotizador(articulo).subscribe(
    result => { 
      this.objArt.arrayOpciones = result;
      this.objArt.strNombreOpcion = this.objArt.arrayOpciones[0].strNombreOpcion;
      this.objArt.strOpcion = this.objArt.arrayOpciones[0].strOpcion; 
    });
}
fnArtSinLp(arrayArt:any){ 
  this.intPrecioPolitica =0;
  this.intPrecioPlantilla =0;
  this.intPrecioLista =0;
  this.intPrecio=0;
  this.intPrecio2=0;
  this.intPrecio3=0;
  this.intPrecio4=0;
  this.arrayPreciosArt = [];
  this._httpService.getListaPrecios({
    strAccion: 'get_PreciosArticulos',
    xmlArticulos:[arrayArt],
    strCliente:this.objCliente.strCliente,
    strConexion:this.strConexion
  }).subscribe(
    result => {
      this.arrayPreciosArt = result; 
      if(arrayArt.strTipoOpcion == "Si"){
        let rest = this.arrayPreciosArt.find(obj => obj.strOpcion.trim() == arrayArt.strOpcion.trim());
        this.intPrecioLista = rest.intPrecioLista
        this.intPrecio=rest.intPrecio;
        this.intPrecio2=rest.intPrecio2;
        this.intPrecio3=rest.intPrecio3;
        this.intPrecio4= this.Accesop4 ? rest.intPrecio4:0;
        this.intPrecioPlantilla= rest.intPrecioPlantilla;
        this.intPrecioPolitica= rest.intPrecioPolitica; 
      }else{
        this.intPrecioLista = this.arrayPreciosArt[0].intPrecioLista;
        this.intPrecio=this.arrayPreciosArt[0].intPrecio;
        this.intPrecio2=this.arrayPreciosArt[0].intPrecio2;
        this.intPrecio3=this.arrayPreciosArt[0].intPrecio3;
        this.intPrecio4=this.Accesop4 ? this.arrayPreciosArt[0].intPrecio4:0;
        this.intPrecioPlantilla= this.arrayPreciosArt[0].intPrecioPlantilla;
        this.intPrecioPolitica= this.arrayPreciosArt[0].intPrecioPolitica; 
      }
      this.objArt.dblPrecioLista =this.intPrecioPolitica>0?this.intPrecioPolitica:this.intPrecioPlantilla>0?this.intPrecioPlantilla:this.intPrecioLista;
    },
    error => {
      var error = <any>error;
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Uuups...',
        text: 'Error en conexion al extraer articulos sin precio lista, intente de nuevo.'
      });});
 }

 fntraerExistencias(object:any){ 
  let opcion = "";
  if(object.strTipoOpcion == "Si"){
    opcion=object.strOpcion;
  }
  let articulo = {
    strArticulo:object.strArticulo,
    strSubcuenta:opcion,
    strConexion: this.strConexion,
    strAccion:'getExistencias'
  }; 
  this._httpService.getListaPrecios(articulo).subscribe(
    result => {
     this.arrayExistentes = result;
     this.ordenarArray() ;
    }); 
 }

 compareAlphanumeric(a: { strAlmacen: string }, b: { strAlmacen: string }): number {
  return a.strAlmacen.localeCompare(b.strAlmacen, undefined, { numeric: true, sensitivity: 'base' });
}

// Llamar a esta función cuando sea necesario ordenar el array por strAlmacen
ordenarArray(): void {
  this.arrayExistentes.sort(this.compareAlphanumeric);
}




 fnAdd_Rest_Amount(objArt:any, tipo:string){
  if( tipo == 'add' ) {
      objArt.dblCantidad=objArt.dblCantidad + 1;
  }else{
      objArt.dblCantidad=objArt.dblCantidad - 1;
  }
}
fnAddToCarr( objArticulo:any){ //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado

  objArticulo.strComentario = "";
  this.spinerCarrito = true;
   objArticulo.dblCantidad = parseInt(objArticulo.dblCantidad);
 const deepCopy = JSON.parse(JSON.stringify(objArticulo)); 
  this.commodatoservice.setArticulo( deepCopy); 
  setTimeout(() => {
    this.spinerCarrito = false;
    this._alertify.success("Articulo agregado correctamente");
  }, 400);
}
onOpcionSeleccionada(opcion:string,objArt:any){
  this.objArt.arrayOpciones.forEach(o => {
    if(opcion == o.strOpcion ){
      this.objArt.strNombreOpcion = o.strNombreOpcion
    }
  });
  this.fntraerExistencias(objArt);
}
fnUpdateAmountCar(objArt:any){
  this.commodatoservice.deleteArt( objArt);
}
fnDeleteCar(intId:number, objArt:any){
  this.arrayCarritoArts.splice(intId, 1);
  this.commodatoservice.deleteArt( objArt);
}

fnGetSucursal_CTe()
{

  this.arraySucursalesCte = [];
  let cliente = {
    strAccion: "getCte_Sucursal",
    strCliente: this.strNumCte,
    strGerente: this.strGerente,
    strSucursal: this.strSucursal,
    strAgente: this.strAgente,
    strConexion: this.strConexion
  };
  console.log( cliente);
  this._httpService.getCotizador(cliente).subscribe(
    result => {
      this.arraySucursalesCte = result   
   
    });
}

}
