import { Component, OnInit,ElementRef,ViewChild  } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
export interface data {
  intIDPresupuesto: string;
  intSucursalCte: string;
  strFechaRegistro: string;
  dblPresupuesto: string;
  strAccion: string;
}
@Component({
  selector: 'app-cliente-presupuesto-sucursal',
  templateUrl: './cliente-presupuesto-sucursal.component.html',
  styleUrls: ['./cliente-presupuesto-sucursal.component.css']
})
export class ClientePresupuestoSucursalComponent implements OnInit {
 intEjercicio:number=0;
  intPeriodo:number; 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('btnCerrar') btnCerrar!: ElementRef;
  
  displayedColumns: string[] = ['intIDPresupuesto',
  'intSucursalCte',
  'strFechaRegistro',
  'dblPresupuesto',
  'strAccion',
];
  isHoveredButton: boolean = false;
  isHoveredButton1: boolean = false;
  dataSource: MatTableDataSource<data>;
  public strConexion:string="";
  public arrayAccesos:any=[];
  public arrayTotal:any=[];
  public arrayArts:any=[];
  public arrayEjercicio:any=[];
  public arrayPresupuestos:any =[];
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayArtsDistinct: any=[];
  public TotalEnero:number=0;
  public TotalFebrero:number=0;
  public TotalMarzo:number=0;
  public TotalAbril:number=0;
  public TotalMayo:number=0;
  public TotalJunio:number=0;
  public TotalJulio:number=0;
  public TotalAgosto:number=0;
  public strFecha:Date;
  public dblPresupuesto:number=0;
  public TotalSeptiembre:number=0;
  public TotalOctubre:number=0;
  public TotalNoviembre:number=0;
  public TotalDiciembre:number=0;
  public objModal:any={};
  public arrayExistentes: any = [];
  public spinerArts:boolean = false;
  public strAgente:string="";
  public activeProduct:boolean=false;
  public strAgente_Crea: string;
  public strCliente:string="";
  public strFamilia:string="";
  public strUsuario: string;
  public arrayPeriodo:any=[];
  public objArt: any = {};
  public strFechaInput:string;
  public strSucursalModal:string="";
  public intPrecioPolitica: number = 0;
  public boolEditaAgente: boolean = true;
  public objCliente:any ={};
  public tagName:any;
  constructor(private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, 
    elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
    private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
    this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
    let fecha = new Date();
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth() + 1;
    this.strConexion = this.localSt.retrieve('strConexion');
    this.tagName = elem.nativeElement.tagName.toLowerCase();
   }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0){
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        if(objAcceso[0].strComponente == this.tagName){
          this.objCliente = this.arrayAccesos[0]; 
          this.strFamilia = this.objCliente.strFamiliaCte;
          this.strCliente = this.objCliente.strCliente;
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgente_Crea = this.localSt.retrieve('strAgente');
          this.strAgente = this.localSt.retrieve('strAgente');
          this.strUsuario = this.localSt.retrieve('strUsuario');
          this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
          this.fnGetPresupuestos();
          this.fnGetFiltros();
          this.fnGetSucursales();
        }else{
          let urlHome = this.arrayAccesos[0].strUrlHome;
          this.router.navigate(['/'+urlHome]);
        }
      }
      else {
        this.router.navigate(['/login']);
      }
    });
  }
  fntabla(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
   
  }

 

  fnEliminarPresupuesto(pre:any){
    Swal.fire({
      icon:'question',
      title: "¿Estas seguro de eliminar presupuesto de" + pre.strNombreSucursal+"?",
      showDenyButton: true,
      confirmButtonText: "Eliminar",
      denyButtonText:"Cancelar",
      customClass: {
        confirmButton: 'custom-confirm-button-presupuesto',
        denyButton: 'custom-deny-button-presupuesto'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinerArts = true; 
        let objEnv: any ={}
        objEnv = {
          intIDPresupuesto:pre.intIDPresupuesto,
          strConexion:this.strConexion
        }
        this._httpService.getClienteDatos(objEnv, 'setEliminarPresupuestoCliente').subscribe(
          result => {
         
            Swal.fire("","<b> El Presupuesto se ha Elimino Correctamente</b>","success");
            this.fnGetPresupuestos();
            this.spinerArts = false;
          },error =>{
            this.spinerArts = false;
          });
      } });
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Agrega 0 si es necesario
    const day = date.getDate().toString().padStart(2, '0'); // Agrega 0 si es necesario
    return `${year}-${month}-${day}`;
  }



  fnGetPresupuestos(){
    this.spinerArts = true;
    this.arrayTotal = [];
    this.arrayPresupuestos = [];
    let objCliente = {
      "strCliente": this.strCliente,
      "intEjercicio": this.intEjercicio,
      "intPeriodo": this.intPeriodo,
      "strConexion":this.strConexion
    }

    this._httpService.getClienteDatos(objCliente,"getListaPresupuestoCliente").subscribe(
    result => {
      this.arrayTotal = result
      if(this.strSucursal == ""){
        this.arrayPresupuestos = this.arrayTotal
      }else{
        this.arrayPresupuestos = this.arrayTotal.filter(o => o.strNombreSucursal == this.strSucursal);
      }
      this.dataSource = new MatTableDataSource(this.arrayPresupuestos);
      this.fntabla();
      this.spinerArts = false;

    }, error=>{
      this.spinerArts= false;
    });
  }

  fnGetSucursales(){
    let objCliente = {
     "strCliente": this.strCliente,
     "strConexion":this.strConexion
   }

   this._httpService.getClienteDatos(objCliente,"GetCteEnviarA").subscribe(
     result => {
      this.arraySucursales = result
    
     }, error=>{
       this.spinerArts= false;
     });
   }

   fnAbrirModal(){
    let fecha = new Date();
    this.strFecha = new Date();
    this.strFechaInput = this.formatDate(fecha);
    this.strSucursalModal = "";
    this.dblPresupuesto = 0;
    
  } 
  fnGuardar(){
    if(this.strSucursalModal != "" && this.dblPresupuesto>0){
      this.spinerArts = true;
      let ejercicio:any,periodo:any;
      ejercicio = this.strFecha.getFullYear();
      periodo = this.strFecha.getMonth() +1;
     let objAgregar = {
        'strUsuario': this.strUsuario,
        'strCliente': this.strCliente, 
        'intSucursalCte': this.strSucursalModal,
        'strFechaRegistro':this.formatFechaRegistro(this.strFechaInput),
        'dblPresupuesto': this.dblPresupuesto,
        'intPeriodo': periodo,
        'intEjercicio': ejercicio,
        'strConexion': this.strConexion
      };   
      this._httpService.getClienteDatos(objAgregar,"setInsertarPresupuestoCliente").subscribe(
        result => {
          Swal.fire({
            text: "GUARDADO CORRECTAMENTE.",
            icon: "success",
            timer: 2500, // 2 segundos (2000 ms)
            timerProgressBar: true, // Muestra la barra de tiempo
            showConfirmButton: false, // Oculta el botón de confirmar
          });
          this.fnGetPresupuestos();
          this.btnCerrar.nativeElement.click()
          this.spinerArts = false;
        }, error=>{
          Swal.fire({
            title: "Error",
            text: "Error al guardar presupuesto.",
            icon: "error",
            timer: 2500, // 2 segundos (2000 ms)
            timerProgressBar: true, // Muestra la barra de tiempo
            showConfirmButton: false, // Oculta el botón de confirmar
          });
          this.spinerArts= false;
        });
     
    }else{
      Swal.fire({
        title: "Error",
        text: "Debe seleccionar una sucursal o colocar un presupuesto mayor a 0.",
        icon: "error",
        timer: 2500, // 2 segundos (2000 ms)
        timerProgressBar: true, // Muestra la barra de tiempo
        showConfirmButton: false, // Oculta el botón de confirmar
      });

    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'e' || event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
  }

  // Limpia ceros a la izquierda cuando el usuario escribe
  onInputChange(event: any) {
    let value = event.target.value;
    
    // Evita valores negativos o caracteres no válidos
    value = value.replace(/^0+(\d)/, '$1'); // Elimina ceros a la izquierda
    event.target.value = value; // Actualiza el input visualmente

    // Guarda el valor limpio en la variable
    this.dblPresupuesto = value ? parseFloat(value) : null;
  }
  fnSucursal(){
    if(this.strSucursal == "" ){
      this.arrayPresupuestos = this.arrayTotal
    }else{
      this.arrayPresupuestos = this.arrayTotal.filter(o => o.strNombreSucursal == this.strSucursal);
    }
    this.dataSource = new MatTableDataSource(this.arrayPresupuestos);
    this.fntabla();

  }
  formatFechaRegistro(fechaISO: string): string {
    const fecha = new Date(fechaISO);
    const dia = fecha.getDate()+1; // Obtiene el día sin ceros iniciales
    const mes = fecha.getMonth() + 1; // Obtiene el mes (0-indexado, por eso +1)
    const anio = fecha.getFullYear(); // Obtiene el año
  
    return `${dia}/${mes}/${anio}`;
  }
}

