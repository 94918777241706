import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { FormGroup,FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { PartnercteService } from '../servicios/partnercte.service';
import { DataDBService } from '../servicios/data-db.service';
import {MatPaginator,MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Subject } from 'rxjs';

declare var Shepherd: any; 
export interface datosCliente {
  id: string;
  strRazonSocial: string;
  strNombreComercial: string;
  strTelefono: string;
  strCorreo:string;
  strContacto1:string;
  strCliente:string;
  strEstado: string;
  strAccion:string;
}

@Component({
  selector: 'app-partner-clientes',
  templateUrl: './partner-clientes.component.html',
  styleUrls: ['./partner-clientes.component.css']
})
export class PartnerClientesComponent implements OnInit {
  displayedColumns: string[] = ['id', 'strRazonSocial', 'strNombreComercial', 'strEstado', 'strTelefono','strCorreo','strContacto1','strCliente','strAccion'];
  dataSource: MatTableDataSource<datosCliente>;

  @ViewChild('pagClientes') pagClientes: MatPaginator;
  @ViewChild('sortClientes') sortClientes: MatSort;
  public formRegistrar: FormGroup;
  public passwordMismatchError: boolean = false;
  public arrayRegimenFiscal: any = [];
  public arrayColonias:any = [];
  public arrayAgregarAnexosActividades: any = []
  public arrayCfdi:any = [];
  public inputSearch:string ="";
  public user:any = [];
  public boolCteExiste:boolean=false;
  public selectedTabIndex:number =0;
  public arrayEnviarAnexosActividades: any = []
  public strCte:string="";
  public  spinerTab:boolean = false;
  public intEjercicio: number;
  public intPeriodo:number;
  public strPais:string="";
  public strSucursal: string = "";
public arrayDetalleArts:any = [];
  public objAgenSuc:any;
  public strConexion:string ='COMERCIAL';
  public passwordVisible: boolean = false; // Controla la visibilidad de la contraseña
  public confirmPasswordVisible: boolean = false;
  public editarParner:boolean = false;
  public boolDireccion:boolean = false;
  public arrayAccesos: any = [];
  formDomFiscal: FormGroup;
  public tagName: string = '';
  public strNombreAgente:string = ""
  public arrayDirecciones :any = []
  public strAgenteLS: any = '' 
  public objClienteValid:any;
  public strAgente: string = "";
  public strAgente_Crea: string;
  public strGerente: string = "";
  public strUsuario:string;
  form: FormGroup;
  public strNombre: string = '';
  public strSSN:string;
  public boolEditar :boolean =false
  public objPartner:any = {};
  public strCliente:string="";
  public strUrlLogo:string="";
  @ViewChild('btnCerrarCteNuevo') btnCerrarCteNuevo!: ElementRef;
  public id_monedero:number = 0 ; 
  public objMonedero:any={};
  public arrayClientes:any =[];
  public arrayCotizaciones:any = []
  private unsubscribe$ = new Subject<void>();

  constructor( private servicePartner:PartnercteService,private paginatorIntl: MatPaginatorIntl,private route: ActivatedRoute,elem: ElementRef,public localSt: LocalStorageService,public router: Router, private _httpService: PeticionesService,private indexedDBService: DataDBService,private fb: FormBuilder) { 

    this.strNombre = localSt.retrieve('strNombre')
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.strPais = this.localSt.retrieve('strPais');
    this.indexedDBService.getItem('arrayResult', (value) => {
    console.log(value);
    this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) {
        this.objPartner = this.arrayAccesos[0];
       
        this.strCliente = this.objPartner.strCliente;
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        if (objAcceso == undefined || objAcceso.length == 0) {
          this.router.navigate(['/partner-principal']);
        } else {
          let objData = this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
          this.id_monedero = objData.intID_Monedero?objData.intID_Monedero:0;
          this._httpService.setMostrarMenu(true);
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgenteLS = objData.strAgente ;
          this.strAgente_Crea = objData.strAgente; 
          this.strUsuario = objData.strUsuario;
          this.strNombreAgente = objData.strNombre + " " + objData.strApellido 
          this.strSSN =  objData.strSSN;
          //------------conexi+on-------------------
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
            this.fnGetClientes()
          });
        }
 
   
       
          this.paginatorIntl.itemsPerPageLabel = 'Registros por página';
      } else {
        this.router.navigate(['/login']);
      }
    });  

  }

  ngOnInit(): void {
    this.fnInitForm();
    this.fnextraercotizaciones();
  }
  seleccionarCte(valor: any) {
    this.servicePartner.setCte(valor);
    console.log(valor)
  }
  
  ngAfterViewInit() {

    this.fnIniciarTabla();
  }

  fnIniciarTabla(){
    this.dataSource.paginator = this.pagClientes;
    this.dataSource.sort = this.sortClientes;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
   
  get f() {
    return this.formRegistrar.controls; 
  }
  fnAbrirModalAgregar(){
   

    this.formRegistrar.patchValue({
      strCliente: `(${this.objPartner.strCliente}) ${this.objPartner.strNombre} ${this.objPartner.strApellido}`,
      strEstatus: 'Alta',
      strRazonSocial: '',
      strNombreComercial: '',
      strTelefono: '',
      strCorreo: '',
      strContacto1: '',
    });
  }

  fnInitForm(){
    console.log(this.objPartner.strCliente)
    this.formRegistrar = this.fb.group({
      strRazonSocial: new FormControl('', [Validators.required]),
      strNombreComercial: new FormControl('', [Validators.required]),
      strTelefono: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^\d{10}$/)]),
      strCorreo: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})?$'), Validators.required]),
      strContacto1: new FormControl('', [ Validators.required]),
      strCliente: new FormControl("("+this.objPartner.strCliente+") "+this.objPartner.strNombre+" "+this.objPartner.strApellido, [Validators.required]),
      strEstatus: new FormControl('Alta', [Validators.required]),
      fechaNacimiento: new FormControl(new Date('2/18/2025'), [Validators.required])
      
    });
  }

  validarNumeros(event: KeyboardEvent) {
    const codigoTecla = event.keyCode;
    // Permite los números (0-9) y la tecla de retroceso (Backspace)
    if (codigoTecla < 48 || codigoTecla > 57) {
      event.preventDefault();
    }
  }
  fnGuardarNuevoCliente(){
    let form:any = { strCliente:""}
    if(this.formRegistrar.valid){
      form = this.formRegistrar.value
      form.strCliente = this.objPartner.strCliente
      form.strAccion = "insertCtePartner"
      form.strConexion = this.strConexion
      console.log(form)
      this._httpService.mostrarCotizaciones(form, 'sp_PartNer').subscribe(
        result => {
          let res = result[0]
          if(res && res.code == "200"){
            Swal.fire({
              title: 'Guardado correctamente!',
              text: res.strMensaje,
              icon: 'success',
              timer: 2000, // tiempo en milisegundos (3 segundos)
              timerProgressBar: true,
              showConfirmButton: false
            });
            this.btnCerrarCteNuevo.nativeElement.click();
            this.fnGetClientes();
          }else{
            Swal.fire("Error!",res.strMensaje,"success")
          }
        },error=>{
          Swal.fire("Error!",error.message,"success")
        })
    }else{
      Swal.fire("Error","Debe llenar todos los campos del formulario","error")
    }
  }
  fnGetClientes(){
    let objSend = {
      strConexion:this.strConexion,
      strAccion:"getCte",
      strCliente:this.objPartner.strCliente
    }
    this._httpService.mostrarCotizaciones(objSend, 'sp_PartNer').subscribe(
      result => {
        this.arrayClientes = result;
        this.dataSource = new MatTableDataSource(this.arrayClientes);
        this.fnIniciarTabla();
      },error => {
        Swal.fire("Error!",error.message,"success")
      }
    )
  }
  fnCambiarEstatus(obj:any){
    console.log(obj)
    let objEnv = {
      strConexion:this.strConexion,
      strAccion:"updateCtePartner",
      strCliente:obj.intID,
      strEstado:obj.strEstado
    }

    this._httpService.mostrarCotizaciones(objEnv, 'sp_PartNer').subscribe(
      result => {
        let res = result[0]
        if(res && res.code == "200"){
          Swal.fire({
            title: 'Guardado correctamente!',
            text: res.strMensaje,
            icon: 'success',
            timer: 2000, // tiempo en milisegundos (3 segundos)
            timerProgressBar: true,
            showConfirmButton: false
          });
          this.btnCerrarCteNuevo.nativeElement.click();
          this.fnGetClientes();
        }else{
          Swal.fire("Error!",res.strMensaje,"success")
        }
      },error=>{
        Swal.fire("Error!",error.message,"success")
      })
  }

  fnActualizar(obj:any){
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Deseas cambiar el estatus del cliente?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, cambiar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#198754', // Verde (Bootstrap success)
      cancelButtonColor: '#dc3545'   // Rojo (Bootstrap danger)
    }).then((result) => {
      if (result.isConfirmed) {
        // Aquí haces el cambio de estatus
        this.fnCambiarEstatus(obj); // o lo que necesites ejecutar
      
      }
    });
  }


  iniciarTour() {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: { enabled: true },
        classes: 'shadow-md bg-white rounded custom-tooltip',
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 8,
       
      },
      useModalOverlay: true  // 👈 Esto activa el fondo oscuro
    });
  
    // Paso 1 - Bienvenida
    tour.addStep({
      id: 'bienvenida',
      text: '¡Hola! Te damos la bienvenida. Vamos a explicarte cómo usar esta sección.',
      buttons: [
        {
          text: 'Siguiente',
          action: tour.next
        }
      ]
    });
  
    // Paso 2 - Input de búsqueda
    tour.addStep({
      id: 'buscarCliente',
      text: 'Aquí puedes buscar clientes por nombre o razón social.',
      attachTo: {
        element: '#inputBuscar',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Anterior',
          action: tour.back
        },
        {
          text: 'Siguiente',
          action: tour.next
        }
      ]
    });
  
    // Paso 3 - Botón Agregar Cliente
    tour.addStep({
      id: 'agregarCliente',
      text: 'Este botón abre un formulario para registrar nuevos clientes.',
      attachTo: {
        element: '#btnAgregarCliente',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Anterior',
          action: tour.back
        },
        {
          text: 'Siguiente',
          action: tour.next
        }
      ]
    });
    tour.addStep({
      id: 'btnASeleccionarCliente',
      text: 'Este botón es para seleccionar un cliente para realizar una cotización.',
      attachTo: {
        element: '#btnASeleccionarCliente',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Anterior',
          action: tour.back
        },
        {
          text: 'Siguiente',
          action: tour.next
        }
      ]
    }); 
    tour.addStep({
      id: 'cotizadorPartner',
      text: 'Este botón es para acceder al cotizador del partner.',
      attachTo: {
        element: '#cotizadorPartner',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Ir al cotizador',
          action: () => {
            tour.hide(); 
            this.router.navigate(['/partner-cotizador'], {
              queryParams: { addstep:"introCotizador" }
            });
          }
        }
      ]
    });
  
    // Iniciar el tour
    tour.start();
  }

  
    fnEliminarCotizacion(item:any){
      Swal.fire({
        title: '¿Estás seguro?',
        text: "Esta acción no se puede deshacer.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(item)
      let obj={
        strAccion:"setEliminarCotizacion" ,
        intID:item.intID,
        strConexion:this.strConexion
     }
     console.log(obj)
     this._httpService.mostrarCotizaciones(obj, 'sp_PartNer').subscribe(
        result => { 
          let res = result[0]
          if(res && res.code == 200){
            Swal.fire({
              icon: 'success',
              title: 'Correcto',
              text: res.strMensaje
              });
              this.fnextraercotizaciones()
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: res.code == 409 ?res.strMensaje:"Error en api"
              });
          }
      
       },
       error => {
         console.error('Error al consumir la API:', error);
       });
        }
      });


     
    }
    fnMostrarDetalleCotizacion(item:any){
      this.arrayDetalleArts = []
      if(item.mostrarDetalle == true){
        item.mostrarDetalle = false
      }
      else{
      let obj={
        strAccion:"getDetCotPartner",
        intID: item.intID,
        strConexion:this.strConexion
     }
     console.log(obj)
     this._httpService.mostrarCotizaciones(obj, 'sp_PartNer').subscribe(
      result => {
        this.arrayDetalleArts = result
        console.log(result)
       this.arrayCotizaciones.forEach(x => {
        if(item == x){
         item.mostrarDetalle = true;
        }else{
         x.mostrarDetalle=false;
        }});
      },error=>{
        Swal.fire("Error!",error.message,"success")
      })
    }
  }
  
    fnextraercotizaciones(obj:any={}){
      this.spinerTab = true;

      setTimeout(() => {
        console.log(this.objPartner);
        let objEnv = {
          strConexion:this.strConexion,
          strAccion:"getCotizacionPartner",
          strUsuario:this.strCliente,
        }
        this._httpService.mostrarCotizaciones(objEnv, 'sp_PartNer').subscribe(
          result => {
            let res = result 
            console.log(res)
            this.arrayCotizaciones = res
            this.spinerTab = false;
          },error=>{
            Swal.fire("Error!",error.message,"success");
            this.spinerTab = false;
          });
      }, 2000);



      }
        
        fechasComoDate(cotizacion): Date {
          const fecha = moment(cotizacion.strFecha, 'MM/DD/YYYY hh:mm:ss');
          // Verificar si la fecha es válida antes de convertirla a Date
          if (fecha.isValid()) {
            return fecha.toDate();
          } else {
            console.warn('Fecha inválida:', cotizacion.strFecha);
            // Puedes devolver null o una fecha por defecto, según tus necesidades
            return cotizacion.strFecha;
          }
        }
      

}

