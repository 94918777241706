import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-detallevtasvsobj',
  templateUrl: './detallevtasvsobj.component.html',
  styleUrls: ['./detallevtasvsobj.component.css']
})
export class DetallevtasvsobjComponent implements OnInit {
  public arrayVentaVsObjetivo: any;
  public arrayDetalleLineas: any;
  public strNombre: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data);
    this.arrayVentaVsObjetivo=this.data[0]; //console.log(this.arrayVentaVsObjetivo);
    this.arrayDetalleLineas=this.data[1]; //console.log(this.arrayDetalleLineas);
    this.strNombre=this.data[2];
    console.log(this.strNombre);

  }

  ngOnInit(): void {
  }

}
