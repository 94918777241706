<div class="container"> 
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold titulo text-decoration-underline">Facturas y Devoluciones</h1>
        </div> 
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetFacturas()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <!--fin de filtros-->
    <div class="row my-4">

        <div class="col-12 ">
            <div class="col-12 headTable bordertop d-flex align-items-center justify-content-between">
                <mat-form-field appearance="outline" class="my-2 ms-3 bg-white rounded ">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Cliente, movId, Agente, etcc."   style="color: #1a1919!important " #input>
                </mat-form-field>
               
            </div>
            <div [hidden]="!boolTable">
              <div class="col-12 d-flex justify-content-center py-4">
                <mat-spinner [diameter]="60"  class="spinner-container"></mat-spinner> 
              </div>
            </div>
            <div class="mat-elevation-z8 table-responsive mb-0" [hidden]="boolTable">
                <table mat-table [dataSource]="datosVentasNetas" class="table table-bordered" matSort>
                  <!-- movimiento Column -->
                  <ng-container matColumnDef="strMov">
                    <th mat-header-cell *matHeaderCellDef class="ps-0 headTable text-center align-middle" mat-sort-header > Movimiento </th>
                    <td mat-cell *matCellDef="let row" class="ps-0 fs13"> {{row.strMov}} {{row.strMovID}} </td>
                  </ng-container>
              
                  <!-- fecha Column -->
                  <ng-container matColumnDef="strFechaEmision">
                    <th mat-header-cell *matHeaderCellDef class="headTable text-center align-middle"  mat-sort-header> Fecha </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strFechaEmision}} </td>
                  </ng-container>
              
                    <!-- cliente Column -->
                    <ng-container matColumnDef="strSucursal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Sucursal </th>
                        <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strSucursal}} </td>
                      </ng-container>
                      
                  <!-- cliente Column -->
                  <ng-container matColumnDef="strCliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Cliente </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strCliente}} </td>
                  </ng-container>
                       <!-- cliente Column -->
                       <ng-container matColumnDef="strDireccionOrdenCompra">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Evidencia Orden Compra</th>
                        <td mat-cell *matCellDef="let row" class="text-center fs13"> 
                         
                            <a  *ngIf = "row.strDireccionOrdenCompra!=''" href="{{row.strDireccionOrdenCompra}}" download="nombre-de-descarga.pdf" target="_blank" title="{{row.strDireccionOrdenCompra}}">Ver Evidencia Orden Compra</a>
                             </td>
                      </ng-container>
                  
                  <!-- cliente Column -->
                  <ng-container matColumnDef="strDireccionEvidencia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle" > Evidencia Entrega </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> 
                    
                        <a  *ngIf = "row.strDireccionEvidencia!=''" href="{{row.strDireccionEvidencia}}" download="nombre-de-descarga.pdf" target="_blank" title="{{row.strDireccionEvidencia}}">Ver Evidencia de Entrega</a>
              

                    </td>
                  </ng-container>
                     <!-- cliente Column -->
                     <ng-container matColumnDef="pdf">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> PDF</th>
                        <td mat-cell *matCellDef="let row" class="text-center fs13"> 
                            <a href="http://35.202.0.97/CFD/{{intEjercicio}}/{{intPeriodo}}/{{row.strCliente}}/{{row.strMov}} {{row.strMovID}}.pdf" target="_blank" id="step5" class="ng-binding">
                                {{row.strMov}} {{row.strMovID}}
                            </a>
                            
                     </td>
                      </ng-container>
                         <!-- cliente Column -->
                  <ng-container matColumnDef="xml">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> XML </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> 
                        <a href="http://35.202.0.97/CFD/{{intEjercicio}}/{{intPeriodo}}/{{row.strCliente}}/{{row.strMov}} {{row.strMovID}}.xml" target="_blank" id="step5" class="ng-binding">
                        {{row.strMov}} {{row.strMovID}}
                    </a>

                    </td>
                  </ng-container>
              
                  <!-- nombre Column -->
                  <ng-container matColumnDef="strClienteNombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Nombre </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strClienteNombre}} </td>
                  </ng-container>

                  <!-- almacen Column -->
                  <ng-container matColumnDef="strAlmacenNombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Almacen </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strAlmacenNombre}} </td>
                  </ng-container>

                  <!-- referencia Column -->
                  <ng-container matColumnDef="strReferencia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Referencia </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strReferencia}} </td>
                  </ng-container>

                  <!-- ordencompra Column -->
                  <ng-container matColumnDef="strOrdenCompra">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Orden Compra </th>
                    <td mat-cell *matCellDef="let row" class="text-center fs13"> {{row.strOrdenCompra}} </td>
                  </ng-container>

                  <!--   importefactor Column -->
                  <ng-container matColumnDef="dblImporte">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="headTable text-center align-middle"> Importe con Factor </th>
                    <td mat-cell *matCellDef="let row" class="fw-bold text-end fs13 pe-2"> {{row.dblImporte|currency}} </td>
                  </ng-container>

                  <!-- agente Column -->
                  <ng-container matColumnDef="strNombreAgente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pe-0 headTable text-center align-middle"> Agente </th>
                    <td mat-cell *matCellDef="let row" class="pe-0 text-center fs13"> {{row.strNombreAgente}} </td>
                  </ng-container>
              
                  <tr mat-header-row *matHeaderRowDef="columnsVtasNetas" class="sticky-top"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsVtasNetas;"></tr>
              
                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9">No se encuentra el dato: "{{input.value}}"</td>
                  </tr>
                </table>
            </div>
            <div class="col-12  ">
                <mat-paginator class="headTable borderbott" [pageSizeOptions]="[5, 10, 25,50, 100]" aria-label="Select page of users"></mat-paginator>
            </div>



        </div>
    </div>
</div>
