import { Component, OnInit,ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService, } from 'ngx-webstorage';
import { DataDBService } from '../servicios/data-db.service';

@Component({
  selector: 'app-partner-principal',
  templateUrl: './partner-principal.component.html',
  styleUrls: ['./partner-principal.component.css']
})
export class PartnerPrincipalComponent implements OnInit {
  public arrayAccesos: any = [];
  public tagName: string = '';
  public strConexion: string = "";
  public strAgenteLS: any = '' 
  public strAgente_Crea: string;
  public strUsuario:string;
  public strNombre: string = '';
  public strSSN:string;
  public objPartner:any = {};

  constructor(elem: ElementRef,public localSt: LocalStorageService,public router: Router, private _httpService: PeticionesService,private indexedDBService: DataDBService) { 
    this.strNombre = localSt.retrieve('strNombre')

    
    //array de accesos
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      //obtener niveles de acceso 

      if (this.arrayAccesos.length > 0) {  
        this.objPartner = this.arrayAccesos[0]; 
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
     
        if (objAcceso == undefined || objAcceso.length == 0) {
          this.router.navigate(['/login']);
        }
        else {
          let objData = this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
          this._httpService.setMostrarMenu(true);
          // this.localSt.retrieve('strAgente'); //this.boolAgente ? '' : 
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgenteLS = objData.strAgente ; //this.localSt.retrieve('strAgente');
          this.strAgente_Crea = objData.strAgente; // this.localSt.retrieve('strAgente');
         
          this.strUsuario = objData.strUsuario;
          this.strSSN =  objData.strSSN;
          //------------conexi+on-------------------
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
        }
      }
      else {
        this.router.navigate(['/login']);
      }
    });



  }

  ngOnInit(): void {
  
  }

ir(ruta:string){
  let rutan = "'/"+ ruta+"'"; 
  this.router.navigate([ruta]);
}
}
