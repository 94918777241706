import { Component, OnInit,ViewChild
,AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { PeticionesService } from '../servicios/httpservice';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import Swal from 'sweetalert2';
export interface UserData {
  id: string;
  customer: any;
created_at: string;
updated_at: string;
totalItems: any;
current_total_price: number;
name: string;
pedido:string;

}

@Component({
  selector: 'app-aromia',
  templateUrl: './aromia.component.html',
  styleUrls: ['./aromia.component.css']
})
export class AromiaComponent implements OnInit {
  public strConexion:string="";
  public urlImagen :string=""
  displayedColumns: string[] = ['id', 'name', 'customer','created_at','updated_at','totalItems' , 'current_total_price','pedido'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public arrayListadoAromia:any = [];
  public dataCte : any = {firstName:'',lastName:'',phone:'',};
  public arrayArtsModal:any=[];
  public spinner:boolean=false;
  public boolDetalle:boolean=false;
  public CobroEnvio:number=0;
  public mustraEnvioIntel:number=0;
  public objDatosEnvio:any={
    address:{ street:'', number:'', complement:'', neighborhood:'', city:'', state:'', country:'', postalCode:''}
  }
  public porcentImpuesto:number=0; 
  public subtotalModal:number=0;
  public EnvioModal:number=0;
  public IvaModal:number=0;
  public totalModal:number=0;
  public arrayTotales:any=[];
  public arrayArticulos:any=[];
  public filtroFecha: any;
  public arrayOrdenesDia :any=[];
  public search:string="";
  public descuentos:number=0;
  public porcentajeDescuento:number=0;
  public impuestos:any=[];
  public pasarelaPago:string='';
  public strReferencia:string='';
  constructor(  private customPaginatorIntl: MatPaginatorIntl,private _httpService:PeticionesService,private datePipe: DatePipe,private localSt:LocalStorageService) {
    this.strConexion = this.localSt.retrieve('strConexion');
    this.filtroFecha = moment();
    
   // this.fnlstarPedidos();
    this.fnGetEstatusPedido();
   }
  ngOnInit(): void {
    //servicio para ocultar el menu
   // this._httpService.setMostrarMenu(false);
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  } 

  fnGetEstatusPedido(){
    
    let arrayPedidosIntelisis = [];
    const fecha = this.filtroFecha;

    // Obtenemos los componentes de la fecha
    const año = fecha.year();
    const mes = fecha.month() + 1; // Meses son de 0 a 11
    const dia = fecha.date();

    // Creamos la cadena de texto con el formato deseado (YYYY-MM-DD)
    const cadenaDeTexto = `${año}-${mes}-${dia}`;
    let objParams= {strAccion:'getSeguimientoAromia', strFechaEmision:cadenaDeTexto};
  
    console.log(objParams)
    this._httpService.getVtexEcodeli(objParams).subscribe(
      result => {
          console.log(result);
          arrayPedidosIntelisis=result;
         // this.fnApi(arrayPedidosIntelisis);
          this.fnlstarPedidos(arrayPedidosIntelisis);
        });
    
  } 

  fnlstarPedidos(arrayPedidosIntelisis:any){ 
    this.spinner = true;
    let fechaInicio: moment.Moment = moment();
    let fechaFin: moment.Moment = moment();
    if (moment.isMoment(this.filtroFecha)) {
      fechaInicio = this.filtroFecha.clone().startOf('day');
      fechaFin = this.filtroFecha.clone().endOf('day');
    }else{
      console.log("Error en input fecha");
    }
    const fechaFormateadaInicio = fechaInicio.format('YYYY-MM-DDT00:00:00-06:00');
    const fechaFormateadaFin = fechaFin.format('YYYY-MM-DDT23:59:59-06:00');
    let obj:any={
      tipo:"GET",/* paid +"&created_at_max="+fechaFormateadaFin*/
      api:"orders.json?status=any&financial_status=paid&created_at_min="+fechaFormateadaInicio+"&created_at_max="+fechaFormateadaFin,
    /*   api:"orders.json?status=any&financial_status=paid&created_at_min=2023-12-07T00:00:00-04:00&created_at_max=2023-12-07T23:59:00-04:00",*/
  };
      console.log(obj)
      this._httpService.getOrders(obj).subscribe((data) => {
      this.arrayListadoAromia= data.orders
      console.log(this.arrayListadoAromia)
      //OBTENEMOS LOS IDS DE INTELISIS
      this.arrayListadoAromia.forEach(x => {
        let pedido=arrayPedidosIntelisis.find(o=>o.strAsignadoID==x.name);
        x.strPedidoMovID =pedido? pedido.strMovID:'';
        x.strEstatusPedido=pedido? pedido.strEstatus:'';
        //obtenemos datos de factura
        x.strMovIDRem=pedido? pedido.strMovIDRem:'';
        x.strEstatusRem=pedido? pedido.strEstatusRem:'';
        x.totalItems =x.line_items.reduce((total, objeto) => total + objeto.quantity, 0);
        //console.log(x.line_items)
    
    });
      

    this.dataSource = new MatTableDataSource(this.arrayListadoAromia);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.customPaginatorIntl.itemsPerPageLabel = 'Elementos por página:';
    this.spinner=false;


      },(error) => {
        this.spinner=false;
        console.error('Error al obtener los pedidos:', error);
      } ); 
  }
  fnMosatrarModal(obj:any){
    this.mustraEnvioIntel=0;
    this.pasarelaPago='';
    this.strReferencia='';
    this.porcentImpuesto=0;
    this.pasarelaPago=obj.payment_gateway_names[0];
    this.strReferencia=obj.shipping_address.name;
    this.mustraEnvioIntel = ( obj.strPedidoMovID && obj.strPedidoMovID!='')?1:0;
   
    console.log(obj)
    this.dataCte = {
      firstName:obj.customer.first_name,
      lastName:obj.customer.last_name,
      phone:obj.shipping_address.phone
    };
    this.objDatosEnvio={
      address:{
        street:obj.shipping_address.address1,
        city:obj.shipping_address.city,
        state:obj.shipping_address.province,
        country:obj.shipping_address.country,
        postalCode:obj.shipping_address.zip,
        iva:obj.total_tax,
        codeEnvio:obj.shipping_lines[0].code,
        order_name:obj.name,
        descuentos:parseInt(obj.total_discounts),
        totalPrecio:obj.total_price,
        impuestos:obj.tax_lines
      }
    } 
    console.log(this.objDatosEnvio)
    if (obj && obj.discount_applications && obj.discount_applications[0] && obj.discount_applications[0].value) {
      this.porcentajeDescuento = parseInt(obj.discount_applications[0].value) / 100;
    } else {
      this.porcentajeDescuento = 0;
    }
    this.CobroEnvio=0;
    this.porcentImpuesto = (obj && obj.tax_lines && obj.tax_lines[0] && obj.tax_lines[0].rate  ?obj.tax_lines[0].rate:0)+(1);
    if (obj.shipping_lines && obj.shipping_lines.length > 0) {
      obj.shipping_lines.forEach(x => {
        this.CobroEnvio += x.discounted_price ? x.discounted_price : 0;
      });
    } else {
      this.CobroEnvio = 0;
    }
    this.arrayArtsModal = obj.line_items
    this.fntraerImagen(); 
    this.fnreturntotal();
  }


  fntraerImagen(){ 
    this.arrayArtsModal.forEach(x => {
      this.boolDetalle=true;
      let obj:any={
        tipo:"GET",
        api:"products/"+x.product_id+"/images.json"};
        this._httpService.getOrders(obj).subscribe((data) => {
        let datos:any = [];
        datos = data;
         x.urlImagen= datos.images[0].src;
          });
    });
    this.boolDetalle=false;
  }

  fnreturntotal(){
    this.descuentos=0;
    this.subtotalModal=0;
    this.IvaModal = 0;
    this.totalModal = 0;
    this.arrayArtsModal.forEach(x => {
    this.subtotalModal = this.subtotalModal+(((x.price-(x.price*this.porcentajeDescuento))/this.porcentImpuesto)*x.quantity)
    });

    this.IvaModal = this.objDatosEnvio.address.iva;
    this.descuentos = this.objDatosEnvio.address.descuentos;
    this.totalModal = this.objDatosEnvio.address.totalPrecio;
    console.log(this.subtotalModal )
    console.log(this.descuentos)
  }

  fnGetInfoPedido (){
    console.log(this.arrayListadoAromia)
    this.arrayListadoAromia.forEach(x => {
      let Arts = 0;
      console.log(x)
      x.line_items.forEach(o => {
        Arts =Arts + o.quantity;

      });
      x.totalItems=Arts;
    });
      

    this.dataSource = new MatTableDataSource(this.arrayListadoAromia);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.customPaginatorIntl.itemsPerPageLabel = 'Elementos por página:';
    this.spinner=false;
  }
  fnSetPedido_Ecodeli(objDatosEnvio:any,arrayArtsModal:any,strOrderID:any,arrayTotales:any){
    console.log(objDatosEnvio);
    console.log(arrayArtsModal);
    console.log(strOrderID);
    console.log(arrayTotales);
    let flag=0;
    //art.price-(art.price*porcentajeDescuento))/porcentImpuesto
    console.log(this.porcentajeDescuento);
    console.log(this.porcentImpuesto);
    let arrayArticulo=[];
    let artMsj=''
    this.arrayArtsModal.forEach(element=>{
      if(element.sku ==''){
        flag=flag + 1;
        artMsj =artMsj +', '+element.title;
      }
      else {
      let price =(element.price-(element.price*this.porcentajeDescuento))/this.porcentImpuesto;
      let artCompleto=element.sku;
      
        // Separar las dos cadenas a partir del guion "-"
       // let partes: string[] = artCompleto.split('-');

            // partes contendrá un array con las dos partes separadas
            //obtener el articulo
          //  let primeraParte: string = partes[0];

            //obtener la sucuenta despues del guion
           // let segundaParte: string = partes[1];

            let articulo={strArticulo:artCompleto, strOpcion:'', intCantidad:element.quantity , dblPrecio:price };
            arrayArticulo.push(articulo);
      }
    
    }) 
    //anexar el envio
    if(this.CobroEnvio && this.CobroEnvio>0){
      let articulo={strArticulo:'ENVIO', strOpcion:'', intCantidad:1 , dblPrecio:this.CobroEnvio /1.16 };
      arrayArticulo.push(articulo);
    }
    console.log(flag);
    
    if(flag>0){
      //lanzar alerta de que no están completos los skus
      Swal.fire({
        icon:  'warning' ,
        title: 'skus faltantes en 1 o más artículos',
        text: artMsj,
        confirmButtonText: 'Cerrar'
      });
    }
    else{ //si todos los skus están completos
    
    
    let strCliente ='ARO01';
    let strSucursal ='74';
    let agente ='563';
    let strCondicion ='PContado';
    let alm ='AROMIA';
    let strOrdenCompra= strOrderID.replace('#', "") + ' '+ this.pasarelaPago;
    let strComentarios='';
    let strObservaciones=strOrderID;


   const fecha: Date = new Date();
    fecha.setDate(fecha.getDate() + 1); 
    const dia = fecha.getDate().toString().padStart(2, '0'); // Obtiene el día y lo ajusta al formato 'dd'
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Obtiene el mes (se suma 1 porque los meses son base 0) y lo ajusta al formato 'mm'
    const anio = fecha.getFullYear(); 
    let strFechaRequerida=dia+'/'+mes+'/'+anio
    console.log("Esta es la fecha requerida que se esta enviando: "+strFechaRequerida); 
    console.log("Este es el agente enviando: "+agente); 
    console.log("Este es el cliente al que se esta enviando: "+strCliente); 
     
    console.log("Esta es a la sucursal que se envia: "+strSucursal); 
    console.log("referencia que se envia: "+this.strReferencia); 
    console.log("ordencompra que se envia: -"+strOrdenCompra+"-"); 
    console.log("comentarios que se envia: "+ strComentarios); 
    console.log("observaciones que se envia: "+strObservaciones); 
    console.log("strCondicion que se envia: "+strCondicion); 
    console.log("almacen que se envia: "+alm);
    console.log("articulos que se envia: " );  
    console.log(arrayArticulo); 
    
    this._httpService.setPedido({
      strAccion: 'setPedido',
      strAgente:agente,
      strCliente:strCliente,
      strSucursal:strSucursal,
      xmlArticulos:arrayArticulo,
      
      strFechaRequerida:strFechaRequerida,
      strOrdenCompra:strOrdenCompra,
      strReferencia:this.strReferencia,
      strObservaciones:strObservaciones,
      strComentarios:'prueba-sistemas_aromia',
      strSistema:'COTIZADOR',
      strCondicion:strCondicion,
      strAlmacen:alm,
      strConexion:this.strConexion,
      strAtencion:'AROMIA TIENDA'
    }).subscribe(
      result => {
        let pruebas=[];
        pruebas = result; 
        let code =pruebas.length==0?410:pruebas[0]?pruebas[0].code:410;
        let msj =pruebas.length==0?'Ocurrió un error al intentar generar el pedido':pruebas[0]?pruebas[0].strMensaje:'Ocurrió un error al intentar generar el pedido';
        let strMensaje = msj;
        let titulo = code==409?'Pedido Generado SINAFECTAR':code==200?'Pedido Generado Correctamente':'Error al Intentar Generar Pedido';
        Swal.fire({
          icon: code==409?'warning':code==200?'success':'error',
          title: titulo,
          text: msj,
          confirmButtonText: 'Cerrar'
        });
        console.log(pruebas);
        //this.carroservice.deleteCarrito();
       // this.btnCerrarModalPedido.nativeElement.click();
      },
      error => {
        var error = <any>error;
        console.log(error);
       // object.spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'Uuups...',
          text: 'Error en conexion al insertar pedido'
        });
      }
    );   

    }




  }
  
}


