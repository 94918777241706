import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-detalle-remision',
  templateUrl: './detalle-remision.component.html',
  styleUrls: ['./detalle-remision.component.css']
})
export class DetalleRemisionComponent implements OnInit {
  public strtipo: any = ''
  public strID: any = ''
  public strSituacion: any = ''
  public InfoEncabezado: any = []
  public listaDetalle: any = []
  public ArrayCondicion: any = []
  public selectedCondicion: any = ''
  constructor(private _httpService: PeticionesService, public route: ActivatedRoute) {
    this.route.queryParamMap.subscribe(params => {
      this.strID = params.get('strID');
      this.strSituacion = params.get('strSituacion')
      this.strtipo = params.get('tipo')
    });
   }

  ngOnInit(): void {
  }

  

  InfoCabecera() {
    let obj: any;
    obj = {
      strID: this.strID,
    }
    this._httpService.getInfoCabecera(obj).subscribe(
      result => {
        console.log(result)
        this.InfoEncabezado = result[0]
      });
  }

  getVentasMovimientosDetalle() {
    let obj: any;
    obj = {
      strID: this.strID,
    }
    this._httpService.getVentasMovimientosDetalle(obj).subscribe(
      result => {
        console.log(result)
        this.listaDetalle = result
      });
  }


  //Funcion para obtener la condicion de Contado
  CondicionContado() {
    let obj: any;
    obj = {

    }
    this._httpService.getCondicionContado(obj).subscribe(
      result => {
        console.log(result)
        this.ArrayCondicion = result
      });
  }

  ActualizacionDeCondicion() {
    let obj: any;
    obj = {
      strNuevaCondicion: this.selectedCondicion,
      strID: this.strID
    }
    this._httpService.setCambioCondicionPedido(obj).subscribe(
      result => {
        console.log(result)
        this.ArrayCondicion = result
      });
  }
}
