import { Component, ElementRef, OnInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router,ActivatedRoute } from '@angular/router';
import { DataDBService } from '../servicios/data-db.service';
declare var Shepherd: any; 
@Component({
  selector: 'app-cotizador-linea',
  templateUrl: './cotizador-linea.component.html',
  styleUrls: ['./cotizador-linea.component.css']
})
export class CotizadorLineaComponent implements OnInit {
  categories = [  
    // Añade más categorías según sea necesario
  ];
  public strConexion: string = "";
  public tagName:any;
  public arrayAccesos:any=[];
  constructor(private routEs : ActivatedRoute,private indexedDBService: DataDBService, elem: ElementRef,private router: Router,private localSt: LocalStorageService,private _httpService: PeticionesService) {
    this.strConexion = this.localSt.retrieve('strConexion');

    
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.indexedDBService.getItem('arrayResult', (value) => {
    this.arrayAccesos=value?value:[];
      
    if (this.arrayAccesos.length > 0) {
      var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
      if (objAcceso == undefined || objAcceso.length == 0) {
        this.router.navigate(['/login']);
      }
    
     }
     else {
      this.router.navigate(['/login']);
     }
    });
   }

  ngOnInit(): void { 
    this.fnGetData()
  }

  fnGetData(){
    let listado = {
      strAccion: 'getCategorias',
      strConexion: this.strConexion
    };
    this._httpService.getCotizador(listado).subscribe(
      result => {
        console.log(result);
        let algo=result
        algo.forEach(element => {
          element.imageUrl='https://www.ecodeli.com/img/lineas/'+element.strCategoria + '.jpg'
        });
        this.categories = algo;
        this.routEs.queryParams.subscribe(params => {
          let step = params['addstep']; // 'tour'
          if(step == "linea"){
            setTimeout(() => {
              this.iniciarTour();
            }, 300)
          
          }
          console.log('Viene del tour:', step);
        });
      });
  }
  onCardClick(categoria:string){
    this.localSt.store('strLinea_Seleccionada',categoria);
    this.router.navigate(['/cotizador_SubLinea']);
    console.log(categoria)
  }
  iniciarTour() {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: { enabled: true },
        classes: 'shadow-md bg-white rounded custom-tooltip',
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 8,
       
      },
      useModalOverlay: true  // 👈 Esto activa el fondo oscuro
    });
  
 
    tour.addStep({
      id: 'AMENIDADES',
      text: 'seleccionamos la categoria.',
      attachTo: {
        element: '#AMENIDADES',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Siguiente',
          action: () => {
            tour.hide(); 
            this.localSt.store('strLinea_Seleccionada',"AMENIDADES");
            this.router.navigate(['/cotizador_SubLinea'], {
              queryParams: { addstep:"linea" }
            });
          }
        }
      ]
    });
  
    // Iniciar el tour
    tour.start();
  }
}
