import {  Component, OnInit , OnDestroy, Renderer2,NgZone, AfterViewInit , HostListener, ElementRef } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { ActivatedRoute } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
// SDK de Mercado Pago
import { loadMercadoPago } from "@mercadopago/sdk-js";
import Swal from 'sweetalert2';  


import { CarroService } from '../servicios/carroservice';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {FormasPagoService} from '../servicios/formas-pago.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataDBService } from '../servicios/data-db.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AlertifyService } from '../alertify.service';

declare global {
  interface Window { MercadoPago: any; cardPaymentBrickController:any;paymentBrickController:any}
}


interface enviar{
  intTipo_Promocion:any;
  strConexion:any;
  strCorreo:any;
  intNovedades:any;
  strNombre:any;
  strApellidos:any;
  strPais:any;
  strDireccion:any;
  strColonia:any;
  strCodigoPostal:any;
  strCiudad:any;
  strEstado:any;
  strTelefono:any;
  strEntreCalles:any;
  intCOLLECTION_ID:any;
  strAccion:any;
  intID_Pedido:any;
  strPais_f?:any;
  strRazonSocial_f?:any
  strRFC_f?:any
  strMetodoPago_f?:any
  strUsoCfdi_f?:any
  strNombre_f?:any
  strApellidos_f?:any
  strColonia_f?:any
  strCodigoPostal_f?:any
  strCiudad_f?:any
  strEstado_f?:any
  strDireccion_f?:any
  strRegimenFiscal_f?:any;
  strTipoPago?:any;
  intID_Monedero?:number;
  dblSaldoMonedero:number;
}
@Component({
  selector: 'app-partner-pasarela',
  templateUrl: './partner-pasarela.component.html',
  styleUrls: ['./partner-pasarela.component.css']
})
export class PartnerPasarelaComponent implements OnInit,OnDestroy {
  public objCliente:any = {
    strCliente:"",
    strNombre:"",
    strDireccion:"",
    ClienteSeleccionado:false,
    strSucursal:"",
    NombreSucursal:"",
    tipoSucursal:""
  };
  private cartSubscription: Subscription;
  private envioSubscription: Subscription;
  public dblEnvio:number=0;//99;
  public boolMonedero:boolean=false;
  public selectedPromotion: string = '';
  public objPromocionDescuento : any ={strNombre:'% DE DESCUENTO ' , dblImporte:0};
  public objPromocionMonedero : any ={strNombre:' EN MONEDERO ECODELI ' , dblImporte:0};
  //codigo martin
  public formEntrega: FormGroup;
  private intID_Monedero:number;
 
  // fin condigo martin
  private idEncriptado:string;
  private secretKey ='ece1122';
  private public_key:string=  'TEST-f4e63389-14f8-41e4-8fd7-d504a72392ea';
  //'TEST-f4e63389-14f8-41e4-8fd7-d504a72392ea';
  //  APP_USR-d3ff7373-60e1-4a9a-bfb0-f020315210d0
 
  public  panels = [
    { title: 'Panel 1', content: 'Contenido del panel 1' },
    { title: 'Panel 2', content: 'Contenido del panel 2' }, 
  ];
  mp: any; // Variable para almacenar la instancia de MercadoPago
  private id:string
  public  strCorreo :string  ;
  public intNovedades:number;
  public strNombre:string;
  public strApellidos:string;
  public strPais :string;
  public strDireccion :string;
  public strColonia :string;
  public spinnerCol: boolean=false;
  public strCodigoPostal :string;
  public strCiudad :string;
  public strEstado:string;
  public strTelefono :string;
  public strEntreCalles:string;
  private intCOLLECTION_ID:Number;
  public dblSubTotal: number;
  public intIva:number;
  public dblTotal:number;
  public arrayColonias:any =[];
  public cartItems:any =[];
  public arrayCfdi: any = [];
  public arrayFormaPago: any = [];
  public discountCode:string;
  public facturaForm: FormGroup;
  public boolDireccion:boolean =false;
  public mostrarFormulario: boolean = true;
  public cartItems_mp:any =[];
  public cartItems_aplz:any=[];
  public ArrayFormasPago:any =[];
  public arrayDescuentos:any=[];
  public strConexion:string = "";
  public arrayFact:any;
  public formData:any;
  public formData_f:any;
  public arrayRegimenFiscal: any = [];
  public userData_tienda:any=[];
  public valoresFormulario:any={};
  public dblDescuento:number=0;
  public objCupon_descuento:any={};
  public tagName:string;
  public arrayAccesos:any=[];
  public objEnvioPartner:any ={};
  public strTipo_envio_partner:string='';
  public strTitulo_envio:string='';
  public strNombre_envio_partner:string='';
  public strAlmacen:string;
  public strCteenviar:string;
  public objPartner:any;
  public objDatosFiscales:any={};
  public strAgenteLS:string;
  public strAgente_Crea:string;
  public strUsuario:string;
  public intDisponible:number = 0
  public strSSN:string;
  public arraySaldou:any=[];  
  public objDisponible_almacen:number = 0;
  public dblDisponible_nacional:number = 0;
  public dblReservado_nacional:number = 0;
  public strComentarioEnvio:string="";
  public boolComentario:boolean=false;
  private total_items:number=0;
  isDisabled: boolean = true; 
  public objClienteDatos:any;
  public strCliente:string="";
  public strNumCte:string;
  public objMonedero:any={};
  public dblSaldo_Monedero:number=0;
  constructor(public alertify: AlertifyService,elem: ElementRef,private carroservice: CarroService, public localSt: LocalStorageService, private indexedDBService: DataDBService, private ngZone: NgZone,private router: Router,private formPago:FormasPagoService, private renderer: Renderer2,private fb: FormBuilder, private _httpService:PeticionesService, private route: ActivatedRoute) { 
    this.tagName = elem.nativeElement.tagName.toLowerCase();
    this.fnInitForm();
    this.indexedDBService.getItem('arrayResult', (value) => {
    
      this.arrayAccesos = value ? value : [];
        if (this.arrayAccesos.length > 0) {
            this.objEnvioPartner=this.arrayAccesos[0].detalle_envio_partner?this.arrayAccesos[0].detalle_envio_partner:{}
            this.strTipo_envio_partner=this.arrayAccesos[0].strTipo_envio_partner?this.arrayAccesos[0].strTipo_envio_partner:'PICKUP';
            this.strNombre_envio_partner = this.arrayAccesos[0].nombre_envioPartner ?  this.arrayAccesos[0].nombre_envioPartner:'';
            this.strTitulo_envio = this.strTipo_envio_partner == 'PICKUP' ? 'Pick Up'  :'Envío Estándar';
            this.strCteenviar =  this.objEnvioPartner.intId_Direccion ?  this.objEnvioPartner.intId_Direccion:null;
           
       
         // this.fnGetInfo(this.objPartner.strCliente);
          var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
       
          if (objAcceso == undefined || objAcceso.length == 0) {
            
            this.router.navigate(['/login']);
          } else {
           
            let objData = this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
            this.intID_Monedero = objData.intID_Monedero ? objData.intID_Monedero : 0;
            this.objClienteDatos =  this.arrayAccesos.length >0? this.arrayAccesos[0]:{}
            this.strConexion = this.localSt.retrieve('strConexion');
           
             this.fnGetSaldo_monedero();
            this.getFormasPago();
/*             this.fnGetCfdi(); */
            this.fnGetFormaPago();
    /*         this.fnGetRegimenFiscal(); */
            this.strAgenteLS = objData.strAgente ; //this.localSt.retrieve('strAgente');
            this.strAgente_Crea = objData.strAgente; // this.localSt.retrieve('strAgente');
            this.strUsuario = objData.strUsuario;
            this.strCliente = objData.strCliente
            this.strSSN =  objData.strSSN;
            //------------conexi+on-------------------
            this.indexedDBService.getItem('strConexion', (value) => {
              this.strConexion = value;
            });
  
             this.fnGetInfo(this.objClienteDatos.strCliente).then(() => {
              this.fnGetRegimenFiscal();
              this.fnGetCfdi();
              
             
              
            });
          }
          
         

   
           //servicio de cliente
     
          this.carroservice.ObjCliente$.subscribe((nuevoValor) => {
            this.objCliente = nuevoValor;
            if(nuevoValor.ClienteSeleccionado){
              this.strNumCte=nuevoValor.strCliente;
              this.strAlmacen = this.objEnvioPartner.strAlmacen ?  this.objEnvioPartner.strAlmacen:this.objCliente.strAlmacen;
            }else{
              this.strNumCte = ""
            }
          });

        } else {
          this.router.navigate(['/login']);
        }
      });  
  }
   

  ngOnInit() { 
    //this.fnFormFacturacion();
    this.indexedDBService.arraySucursales_partner_$.subscribe((nuevoValor) => { 
        //funciones que se ejecutan solo para partners
        this.arrayAccesos = nuevoValor
          this.objEnvioPartner=this.arrayAccesos[0].detalle_envio_partner?this.arrayAccesos[0].detalle_envio_partner:{}
          this.strTipo_envio_partner=this.arrayAccesos[0].strTipo_envio_partner?this.arrayAccesos[0].strTipo_envio_partner:'PICKUP';
          this.strNombre_envio_partner = this.arrayAccesos[0].nombre_envioPartner ?  this.arrayAccesos[0].nombre_envioPartner:'';
          this.strTitulo_envio = this.strTipo_envio_partner == 'PICKUP' ? 'Pick Up'  :'Envío Estándar';
         
          this.strCteenviar =  this.objEnvioPartner.intId_Direccion ?  this.objEnvioPartner.intId_Direccion:null;
       
    });




    
          //buscamos si el usuario esta logueedo
 /*      this.cartService.getUsr().subscribe((data) =>{
        this.userData_tienda = data?data:[]; // Actualizamos la variable cuando los datos cambian
      
         
        this.formEntrega.setValue({
          strPais: 'México', // Valor actual, no se modifica porque es disabled
          strNombre: this.userData_tienda[0]?this.userData_tienda[0].strNombre_D:'',
          strApellidos: this.userData_tienda[0]?this.userData_tienda[0].strApellidos_D:'',
          strDireccion: this.userData_tienda[0]?this.userData_tienda[0].strDireccion:'', 
          strColonia: this.userData_tienda[0]?this.userData_tienda[0].strColonia:'', 
          strEntreCalles: this.userData_tienda[0]?this.userData_tienda[0].strEntreCalles:'', 
          strCodigoPostal: this.userData_tienda[0]?this.userData_tienda[0].strCodigoPostal:'', 
          strCiudad: this.userData_tienda[0]?this.userData_tienda[0].strCiudad:'', 
          strEstado: this.userData_tienda[0]?this.userData_tienda[0].strEstado:'', 
          strEnvioNovedades: this.formEntrega.get('strEnvioNovedades').value, // Mantenemos el valor actual
          email: this.userData_tienda[0]?this.userData_tienda[0].strCorreo:'',
          strTelefono: this.userData_tienda[0]?this.userData_tienda[0].strTelefono:''// Mantenemos el valor actual
        });
    
       // this.toggleFormulario();
        // Marcamos el campo 'email' como tocado
        this.formEntrega.get('email')?.markAsTouched();
        this.formEntrega.get('strNombre')?.markAsTouched();
        this.formEntrega.get('strApellidos')?.markAsTouched();
        this.formEntrega.get('strColonia')?.markAsTouched();
        this.formEntrega.get('strEntreCalles')?.markAsTouched();
        this.formEntrega.get('strCodigoPostal')?.markAsTouched();
        this.formEntrega.get('strDireccion')?.markAsTouched();
        this.formEntrega.get('strTelefono')?.markAsTouched();
        this.simulateEnter();
        //asignamos los valores de la facturacion
        this.valoresFormulario = {
          strPais: 'México',
          strNombre: this.userData_tienda[0] && this.userData_tienda[0].strNombre_f ?this.userData_tienda[0].strNombre_f:'',
          strApellidos: this.userData_tienda[0] && this.userData_tienda[0].strApellidos_f?this.userData_tienda[0].strApellidos_f:'',
          strRazonSocial: this.userData_tienda[0] && this.userData_tienda[0].strRazonSocial_f?this.userData_tienda[0].strRazonSocial_f:'',          
          strRFC: this.userData_tienda[0] && this.userData_tienda[0].strRFC_f?this.userData_tienda[0].strRFC_f:'',
          strRegimenFiscal: this.userData_tienda[0] && this.userData_tienda[0].strRegimenFiscal_f?this.userData_tienda[0].strRegimenFiscal_f:'',
          strMetodoPago: this.userData_tienda[0] && this.userData_tienda[0].strMetodoPago_f?this.userData_tienda[0].strMetodoPago_f:'',
          strUsoCfdi: this.userData_tienda[0] && this.userData_tienda[0].strUsoCfdi_f?this.userData_tienda[0].strUsoCfdi_f:'',
          strCodigoPostal: this.userData_tienda[0] && this.userData_tienda[0].strCodigoPostal_f?this.userData_tienda[0].strCodigoPostal_f:'',
          strEstado: this.userData_tienda[0] && this.userData_tienda[0].strEstado_f?this.userData_tienda[0].strEstado_f:'',
          strCiudad: this.userData_tienda[0] && this.userData_tienda[0].strCiudad_f?this.userData_tienda[0].strCiudad_f:'',
          strColonia: this.userData_tienda[0] && this.userData_tienda[0].strColonia_f?this.userData_tienda[0].strColonia_f:'',
          strDireccion:  this.userData_tienda[0] && this.userData_tienda[0].strDireccion_f?this.userData_tienda[0].strDireccion_f:'',
        };
      
      
        
      })
 */
 
  this.route.queryParams.subscribe(params => {
    this.idEncriptado = params['id'];
    if (this.idEncriptado) {
      this.id = this.desencriptarParametro(this.idEncriptado);
    }
 
  });
  
  let objC =localStorage.getItem('coupon')
  this.objCupon_descuento = objC?  JSON.parse(objC) : {} ;

  //this.fnGetDescuentos();
  this.loadCart(); 
  }

  loadCart() { 
    // this.cartService.applyShippment();
     let objC2 =localStorage.getItem('coupon')
     this.objCupon_descuento = objC2?  JSON.parse(objC2) : {} ;

     //this.cartService.applyCoupon(this.objCupon_descuento);
     // Suscribirse al carrito para recibir actualizaciones
     this.cartSubscription = this.carroservice.getCartItems().subscribe((items) => {

         this.cartItems = items;
         this.cartItems.forEach(prod => {
           this.total_items=this.total_items + prod.dblCantidad;
           let opcion:any;
           if(prod.strTipoOpcion=='Si'){
             opcion = prod.arrayOpciones.find(o => o.strOpcion == prod.strOpcion)
             prod.strNombreOpcion = opcion.strNombreOpcion
           }
         
           if(prod.strEstatus == "DESCONTINUADO"){
             this.intDisponible = 0
             this.fnGetDisponible(prod)
           }else{
             prod.intDisponibleNacional = ""
           }
         
        });
          //obtenemo el nivel de la sesion
        let objC =localStorage.getItem('objDescuento_partner');
        let descuentoAplicable = objC  ? JSON.parse(objC) : {} ;    
        let opcion_promocion = localStorage.getItem('opcion_promocion')?parseInt(localStorage.getItem('opcion_promocion')):1  ;

        this.fnSumasItems();//lugar provisional
     });
    }
  ngAfterViewInit(): void {
    // Asegúrate de que el DOM esté completamente cargado
    this.loadMercadoPagoSecurityScript();
  }

  ngOnDestroy() {
    //destruimos el brick
    window.cardPaymentBrickController.unmount();
  }
   
  // Función que verifica si el correo es válido
  checkEmailValidity() {
    if (this.f.email.valid) {
     // this.fnUpdate_contacto();  // Llamar a tu función si es válido
    }
  }
  checknameValidity() {
    if (this.f.strNombre.valid) {
     // this.fnUpdate_contacto();  // Llamar a tu función si es válido
    }
  }
  checklnameValidity() {
    if (this.f.strApellidos.valid) {
      //this.fnUpdate_contacto();  // Llamar a tu función si es válido
    }
  }
  checktelefonoValidity() {
    if (this.f.strTelefono.valid) {
      //this.fnUpdate_contacto();  // Llamar a tu función si es válido
    }
  }
 
  simulateEnter() {
    // Creamos un objeto KeyboardEvent simulado sin la propiedad "which"
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',      // Definimos que la tecla presionada es 'Enter'
      code: 'Enter',     // Establecemos el código de la tecla
      keyCode: 13,       // El código de la tecla "Enter" es 13 (aunque es deprecated, sigue siendo válido)
      bubbles: true,     // Propagación del evento (si es necesario)
    });
  
    // Obtenemos el elemento de input
    const inputElement = document.getElementById('strCodigoPostal') as HTMLInputElement;
    let   strCodigoPostal = this.formEntrega.get("strCodigoPostal")?.value
    inputElement.value = strCodigoPostal; 
   // Establecemos el target del evento como el input correspondiente
    Object.defineProperty(event, 'target', { value: inputElement });
    // Llamamos a la función onEnter y le pasamos el evento simulado
    this.onEnter(event);
  }
  simulateEnter2() {
    // Creamos un objeto KeyboardEvent simulado sin la propiedad "which"
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',      // Definimos que la tecla presionada es 'Enter'
      code: 'Enter',     // Establecemos el código de la tecla
      keyCode: 13,       // El código de la tecla "Enter" es 13 (aunque es deprecated, sigue siendo válido)
      bubbles: true,     // Propagación del evento (si es necesario)
    });
  
    // Obtenemos el elemento de input
    const inputElement2 = document.getElementById('strCodigoPostalF') as HTMLInputElement;

    let   strCodigoPostal = this.userData_tienda[0]?this.userData_tienda[0].strCodigoPostal_f:''
    inputElement2.value = strCodigoPostal; 

   // Establecemos el target del evento como el input correspondiente
    Object.defineProperty(event, 'target', { value: inputElement2 });
    // Llamamos a la función onEnter y le pasamos el evento simulado
    this.onEnterFact(event);
  }
  
  
  fnSumasItems(){
    let pMonedero =0;
    let pDescuento=0;
    this.dblDescuento=0;
    this.dblSubTotal = 0;
    this.intIva = 0;
    this.dblTotal = 0;
    let currentDate = this.getFormattedDate();
    this.cartItems.forEach(art => {
      this.dblSubTotal = this.dblSubTotal + (art.dblCantidad * art.dblPrecioLista);
      this.dblDescuento = this.dblDescuento + (art.dblCantidad * (art.dblPrecioLista - art.dblPrecioConDescuento));
      //configuramos array para enviar a pago
      this.cartItems_mp.push(
        {
          "id": art.strArticulo,
          "title": art.strDescripcion1,
          "description": art.strDescripcion,
          //"picture_url": "https://http2.mlstatic.com/resources/frontend/statics/growth-sellers-landings/device-mlb-point-i_medium2x.png",
          "category_id": art.strGrupo,
          "quantity": art.dblCantidad,
          "unit_price": art.dblPrecioConDescuento >0? art.dblPrecioConDescuento:art.dblPrecioLista,//art.intPrecioLista,
          "type": art.strCategoria,
          "event_date": currentDate,//"2023-12-31T09:37:52.000-04:00",
          "warranty": false,
          "category_descriptor": {
            "passenger": {},
            "route": {}
          }
        }
      )


      this.cartItems_aplz.push ({
        "count": art.dblCantidad,
        "description":  art.strDescripcion,
        "id": art.strArticulo,
        "imageUrl": "",
        "price": art.dblPrecioConDescuento >0? art.dblPrecioConDescuento:art.dblPrecioLista,
        "title": art.strDescripcion1
      })

      /**inicializamos siempre la promocion en tipo descuento */
      this.selectedPromotion = localStorage.getItem('opcion_promocion')? localStorage.getItem('opcion_promocion'):'1'  ; 
      pMonedero = art.pMonedero;
      pDescuento = art.pDescuento;
    });
 
    this.intIva = 0;//this.dblSubTotal * .16
  
   
    this.dblTotal = this.dblDescuento > 0 ? this.dblSubTotal - this.dblDescuento : this.dblSubTotal;//calculamos el total tomando en cuenta el prcio de descuento this.intIva+
    this.dblTotal= this.dblTotal - this.dblSaldo_Monedero;
    this.dblTotal = parseFloat(this.dblTotal.toFixed(2));

     /**inicializamos las promociones */
    //descuento
    this.objPromocionDescuento.dblImporte =  this.dblDescuento > 0 ? this.dblDescuento:  this.dblTotal * pDescuento;
    this.objPromocionDescuento.strNombre =  pDescuento * 100 + '% DE DESCUENTO'
    //monedero
    this.objPromocionMonedero.dblImporte =  ((this.dblSubTotal - this.dblSaldo_Monedero) * pMonedero);
   }
   getFormattedDate(): string {
    const date = new Date();
    const offset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
    const offsetMinutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
    const timezone = (offset >= 0 ? '+' : '-') + `${offsetHours}:${offsetMinutes}`;

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}.${date.getMilliseconds().toString().padStart(3, '0')}${timezone}`;
  }
   fnapplyDiscount(){

   }
  async fnIniciar_mp(){
    try {
      await this.loadMercadoPago(); // Esperar a que MercadoPago se cargue
  
      // Ahora que MercadoPago está cargado, puedes crear una instancia de MercadoPago
      this.createMercadoPagoInstance(this.public_key);
      
    } catch (error) {
      console.error('Error al cargar MercadoPago:', error);
    }
  }

  async loadMercadoPago() {
    return await loadMercadoPago();
  }
  desencriptarParametro(idEncriptado: string): string {
    const bytes = CryptoJS.AES.decrypt(idEncriptado, this.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  fnResetBrick(bricksBuilder:any){
    //destruimos el brick
    window.cardPaymentBrickController.unmount();

    //construimos un nuevo brick
    
   this.renderCardPaymentBrick(bricksBuilder);
  }
  createMercadoPagoInstance(publicKey: string) {
    this.mp = new window.MercadoPago(publicKey, {
      locale: 'es-MX',
    });
    const bricksBuilder = this.mp.bricks();
 
   this.renderCardPaymentBrick(bricksBuilder);
  // this.renderPaymentBrick(bricksBuilder);
    // Aquí puedes utilizar this.mp para acceder a la instancia de MercadoPago
  }
 


  //para pago con tarjeta de debito, credito nivel facil
  async renderCardPaymentBrick(bricksBuilder:any){
    const settings = {
      initialization: {
        amount: this.dblTotal, // monto a ser pago        
        payer: {
          email: "",
          first_name: "",
          last_name: "",
        }, 
        shipping_amount:this.dblEnvio      
      },
      customization: {
        visual: {
          style: {
            customVariables: {
              theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
            }
          }
        },
        paymentMethods: {
          maxInstallments: 1,
        }
      },
      callbacks: {
        onReady: () => {
          const botonPagar = document.querySelector('#cardPaymentBrick_container button') as HTMLButtonElement;
      
          botonPagar.disabled = false;
         
          // callback llamado cuando Brick esté listo
        },
        onSubmit: (cardFormData) => { 
       
            cardFormData.additional_info ={ items: this.cartItems_mp}
            let  valoresForm = this.formEntrega.value; 
            let strNombre= valoresForm.strNombre;
            let  strApellidos= valoresForm.strApellidos;

           // cardFormData.external_reference=
            //obtenemos el devide id
            const deviceIdInput = document.getElementById('deviceId') as HTMLInputElement;
            let device_id= deviceIdInput.value ;
             
            cardFormData.device_id=device_id ? device_id : 0;
            cardFormData.shipping_amount =this.dblEnvio; 
            cardFormData.payer.first_name = strNombre;
            cardFormData.payer.last_name = strApellidos;
            cardFormData.external_reference='EPartner_'+this.id;
            cardFormData.description='COMPRA EN ECODELI PARTNER';
            cardFormData.transaction_amount = this.dblTotal ;
            console.log(cardFormData)
            //  callback llamado cuando el usuario haga clic en el botón enviar los datos
            //  ejemplo de envío de los datos recolectados por el Brick a su servidor
           return new Promise<void>((resolve, reject) => {
            fetch("https://clean.ecodeli-negocio.com/ApiRest/Api_negocio/api_mp_partner.php", {
              method: "POST",
              headers: {   "Content-Type": "application/json",   },
              //  mode: "no-cors", <-- Esto evita el error de CORS pero no permite leer la respuesta
              body: JSON.stringify(cardFormData)
            })
            .then((response) => {
              console.log(response);
              // Retorna una Promesa que resuelve al cuerpo de la respuesta como JSON 
             
              return response.json();
            })
            .then((respuestaJson) => { 
    
              console.log(respuestaJson);
                // recibir el resultado del pago
                let estatusPago= respuestaJson.status;
                let estatusDetalle= respuestaJson.status_detail;

                if(estatusPago!= 'approved'){
                  //resetamos el brick para que puedan enviarla de nuevo
                 
                  const descripcion = this.getPaymentDescription(estatusPago, estatusDetalle);
                  this.fnResetBrick(bricksBuilder)
                  this.ngZone.run(() => {
                    this.router.navigate(['/partner-error-compra'], {
                      queryParams: { id: this.idEncriptado},
                    });
                  });
               
                } else {
                  this.fnGenerarPedido(respuestaJson,'MERCADO_PAGO','',0)
                }
                resolve();
              })
              .catch((error) => {
                console.log(error);  
                
                //resetamos el brick para que puedan enviarla de nuevo
                this.fnResetBrick(bricksBuilder)
               
                // Manejo de error si la solicitud al servidor falla
                Swal.fire({
                  icon: "error",
                  title: "Error de conexión",
                  text: "Hubo un problema al intentar procesar el pago. Por favor, intente de nuevo más tarde.",
                });
                // tratar respuesta de error al intentar crear el pago
                reject();
              })
          });  
         // this.fnSetPay(cardFormData);
        },
        onError: (error) => {
          console.error("Error en el Brick: ", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Ocurrió un error con el proceso de pago.",
          });
          // callback llamado para todos los casos de error de Brick
        },
      },
    };


    //-------------------------
    window.cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
  }

 
  fnGenerarPedido(respuestaJson:any, origen:string, urlaplz:string, loanId:number){
    
     let authorization_code = origen =='MERCADO_PAGO'?respuestaJson.authorization_code:'';
    let  valoresForm = this.formEntrega.value;
    let valores_f = this.facturaForm.value;
   
     
    this.intCOLLECTION_ID =  origen =='MERCADO_PAGO'?respuestaJson.id >0?respuestaJson.id:respuestaJson.collector_id:0; //collector_id

    let objEnviar:enviar={
        strConexion:this.strConexion,        
        intNovedades: valoresForm.strEnvioNovedades ? 1 : 0,
        strCorreo:  valoresForm.email,//this.strCorreo,
        strNombre: valoresForm.strNombre,
        strApellidos: valoresForm.strApellidos,
        strPais: valoresForm.strPais?valoresForm.strPais:'México',
        strDireccion: valoresForm.strDireccion,
        strColonia: valoresForm.strColonia,
        strCodigoPostal: valoresForm.strCodigoPostal,
        strCiudad: valoresForm.strCiudad,
        strEstado: valoresForm.strEstado,
        strTelefono: valoresForm.strTelefono,
        strEntreCalles: valoresForm.strEntreCalles,
        intCOLLECTION_ID: origen =='MERCADO_PAGO'? this.intCOLLECTION_ID:loanId,
        strAccion:'UPDATE_ORDEN_partner',
        intID_Pedido:this.id,
        strTipoPago:origen,
        intID_Monedero: this.intID_Monedero,
        dblSaldoMonedero : this.dblSaldo_Monedero,
        intTipo_Promocion :this.selectedPromotion
    }

    if(this.mostrarFormulario){
      objEnviar.strPais_f = valores_f.strPais;
      objEnviar.strRazonSocial_f = valores_f.strRazonSocial;
      objEnviar.strRFC_f = valores_f.strRFC;
      objEnviar.strRegimenFiscal_f = valores_f.strRegimenFiscal;
      objEnviar.strMetodoPago_f = valores_f.strMetodoPago;
      objEnviar.strUsoCfdi_f = valores_f.strUsoCfdi;
      objEnviar.strNombre_f = valores_f.strNombre;
      objEnviar.strApellidos_f = valores_f.strApellidos;
      objEnviar.strColonia_f = valores_f.strColonia;
      objEnviar.strCodigoPostal_f = valores_f.strCodigoPostal;
      objEnviar.strCiudad_f = valores_f.strCiudad;
      objEnviar.strEstado_f = valores_f.strEstado;
      objEnviar.strDireccion_f = valores_f.strDireccion;
    } 

    
    this._httpService.getEcodeli(objEnviar,'sp_eCommerce').subscribe(
      result => {   
        if(result && result[0].code){
          let code = result[0].code
          let icono = code==200? 'success':'error'
          let title = code==200? 'operación exitosa':'error'
          let msj = result[0].strMensaje;
          
      
         this.fnDeleteCart();
         this.clearCart();
          if(origen =='MERCADO_PAGO'){          
            this.ngZone.run(() => {
              this.router.navigate(['/partner-finpedido'], {
                queryParams: { id: this.idEncriptado },
              });
            });
          }
          else{ //redireccionar a aplazo
            
            window.location.href = urlaplz;
          }
 
          
          
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error con el proceso de pago.",
        });       }
        
      },
      error => { 
        console.error('Error al consumir la API:', error);
        // Puedes manejar el error de alguna manera específica aquí
      }); 
 
  }
  fnDeleteCart(){
   // this.cartService.clearCart();
   this.carroservice.deleteCarrito_partner();
    localStorage.removeItem('id_pedido');
  }

  removeItem(itemId: any) {
    this.carroservice.removeFromCart(itemId);
    this.loadCart();
  }
 //codigo martin

  fnInitForm(){
    this.formEntrega = this.fb.group({
      strPais:[{ value: 'México', disabled: true }, Validators.required],
      strNombre:['', Validators.required],  
      strApellidos:['',[Validators.required,Validators.minLength(2)]],
      strDireccion:['', Validators.required],
      strColonia:['',[Validators.required,Validators.minLength(1)]],
      strEntreCalles: [''],
      strCodigoPostal: ['', [Validators.required, Validators.pattern('^\\d{5}$')]],
      strCiudad: ['', Validators.required],
      strEstado: ['', Validators.required],
      strEnvioNovedades: [false],
      email: ['', [Validators.required, Validators.email, Validators.minLength(5)]],
      strTelefono: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$') // Exactamente 10 dígitos numéricos
      ]]
    });
    
      // Detecta los cambios en el formulario
      this.formEntrega.statusChanges.subscribe(status => {
        // Si el formulario es válido, ejecutar onSubmit
        if (this.formEntrega.valid) {
          this.onSubmit();
        }
      });
  }
  get f() {
    return this.formEntrega.controls;
  }
  onSubmit(): void {
    if (this.formEntrega.valid) {
       const botonPagar = document.querySelector('#cardPaymentBrick_container button') as HTMLButtonElement;
      if (botonPagar &&  !this.formEntrega.valid) {
        // Deshabilitar el botón para evitar múltiples clics
        botonPagar.disabled = true;
      }
      else if (botonPagar &&   this.formEntrega.valid){
        botonPagar.disabled = false;
      }   
    } else { 
    }
  }
 
  // fin condigo martin

  //seguridad de mercadolibre
  loadMercadoPagoSecurityScript(): void {
    // Verificar si el script ya ha sido agregado
    if (!document.querySelector('script[src="https://www.mercadopago.com/v2/security.js"]')) {
      const script = this.renderer.createElement('script');
      script.src = 'https://www.mercadopago.com/v2/security.js';
      script.setAttribute('view', 'pasarela'); // Set attribute "view"
      script.setAttribute('output', 'deviceId');
      this.renderer.appendChild(document.body, script);
      this.fnIniciar_mp();
    }
    else {
      this.fnIniciar_mp();
    }
  }
  getCp(codigoPostal:string,boolFact:boolean=false){
    this.spinnerCol=true; 
  this._httpService.getCp(codigoPostal).subscribe({
    next: (data) => { 
      if(data.zip_codes.length>0){
        let rest =data.zip_codes;
        rest.forEach(col => {
            this.arrayColonias.push({strColonia:col.d_asenta})
          });
         
          if(!boolFact){
            this.formEntrega.patchValue({ strEstado: rest[0].d_estado });
        this.formEntrega.patchValue({ strCiudad: rest[0].d_mnpio});
        this.formEntrega.controls['strCiudad'].markAsTouched();
        this.formEntrega.controls['strCiudad'].updateValueAndValidity();
        this.formEntrega.controls['strEstado'].markAsTouched();
        this.formEntrega.controls['strEstado'].updateValueAndValidity();
          }else{
            this.facturaForm.patchValue({ strEstado: rest[0].d_estado });
        this.facturaForm.patchValue({ strCiudad: rest[0].d_mnpio});
        this.facturaForm.controls['strCiudad'].markAsTouched();
        this.facturaForm.controls['strCiudad'].updateValueAndValidity();
        this.facturaForm.controls['strEstado'].markAsTouched();
        this.facturaForm.controls['strEstado'].updateValueAndValidity();
          }
      }else{
        Swal.fire("Error","Ingreseun codigo postal valido","error")

        if(!boolFact){
          this.formEntrega.patchValue({ strEstado: "" });
          this.formEntrega.patchValue({ strCiudad: ""});
  
          this.formEntrega.controls['strCiudad'].markAsTouched();
          this.formEntrega.controls['strCiudad'].updateValueAndValidity();
          this.formEntrega.controls['strEstado'].markAsTouched();
          this.formEntrega.controls['strEstado'].updateValueAndValidity();
        }else{
          this.facturaForm.patchValue({ strEstado: "" });
          this.facturaForm.patchValue({ strCiudad: ""});
          this.facturaForm.controls['strCiudad'].markAsTouched();
          this.facturaForm.controls['strCiudad'].updateValueAndValidity();
          this.facturaForm.controls['strEstado'].markAsTouched();
          this.facturaForm.controls['strEstado'].updateValueAndValidity();
        }
       
      }
      this.formEntrega.patchValue({
        strColonia: this.objEnvioPartner?this.objEnvioPartner.strColonia:'', 
      });
      this.spinnerCol=false;
    },
    error: (error) => {  
      this.spinnerCol=false;
    }
  });
}

updateCantidad(event: Event): void {
  this.arrayColonias=[];
  let inputElement = event.target as HTMLInputElement;
  let valor = inputElement.value;

  // Expresión regular que permite solo números (enteros)
  const soloNumeros = /^\d*$/;

  // Validar que el valor solo contenga números (no letras, 'e', ni espacios)
  if (!soloNumeros.test(valor)) {
    // Remover caracteres no válidos manteniendo solo los números
    inputElement.value = valor.replace(/[^0-9]/g, '');
  }

  // Actualizar el valor en el formulario como una cadena para mantener ceros iniciales
  this.formEntrega.patchValue({ strCodigoPostal: inputElement.value });
  if(inputElement.value.length==5){
    this.getCp(inputElement.value)
  }else{
    this.formEntrega.patchValue({ strEstado: "" });
    this.formEntrega.patchValue({ strCiudad: "" });
    this.formEntrega.patchValue({ strColonia: "" });
  }
}
onEnter(event: KeyboardEvent) {
  if (event.key === "Enter") {
    let inputElement = event.target as HTMLInputElement;
    this.getCp(inputElement.value)
  }
}
toggleFormulario() {
  this.mostrarFormulario = !this.mostrarFormulario;
  if(this.mostrarFormulario){ 
     this.fnFormFacturacion();
  }
}

toggleMismaDireccion(){
  this.boolDireccion = !this.boolDireccion;
  if(this.boolDireccion){
        this.facturaForm.patchValue({ strNombre: this.formEntrega.get('strNombre').value });
        this.facturaForm.patchValue({ strApellidos: this.formEntrega.get('strApellidos').value });
        this.facturaForm.patchValue({ strDireccion: this.formEntrega.get('strDireccion').value });
        this.facturaForm.patchValue({ strCodigoPostal: this.formEntrega.get('strCodigoPostal').value });
        this.facturaForm.patchValue({ strCiudad: this.formEntrega.get('strCiudad').value });
        this.facturaForm.patchValue({ strEstado: this.formEntrega.get('strEstado').value });
        this.facturaForm.patchValue({ strColonia: this.formEntrega.get('strColonia').value });
        this.facturaForm.controls['strNombre'].markAsTouched();
        this.facturaForm.controls['strNombre'].updateValueAndValidity();
        this.facturaForm.controls['strApellidos'].markAsTouched();
        this.facturaForm.controls['strApellidos'].updateValueAndValidity();
        this.facturaForm.controls['strDireccion'].markAsTouched();
        this.facturaForm.controls['strDireccion'].updateValueAndValidity();

        this.facturaForm.controls['strCodigoPostal'].markAsTouched();
        this.facturaForm.controls['strCodigoPostal'].updateValueAndValidity();
        this.facturaForm.controls['strCiudad'].markAsTouched();
        this.facturaForm.controls['strCiudad'].updateValueAndValidity();
        this.facturaForm.controls['strEstado'].markAsTouched();
        this.facturaForm.controls['strEstado'].updateValueAndValidity();
        this.facturaForm.controls['strColonia'].markAsTouched();
        this.facturaForm.controls['strColonia'].updateValueAndValidity();

      } else{
        this.facturaForm.patchValue({ strNombre: ""});
        this.facturaForm.patchValue({ strApellidos: "" });
        this.facturaForm.patchValue({ strDireccion: "" });
        this.facturaForm.patchValue({ strCodigoPostal: "" });
        this.facturaForm.patchValue({ strCiudad: "" });
        this.facturaForm.patchValue({ strEstado: "" });
        this.facturaForm.patchValue({ strColonia: "" });
     
      }
 
  
}
fnFormFacturacion(){
  this.facturaForm = this.fb.group({
    strPais: [this.strConexion == "COMERCIAL"?"México":"REPUBLICA DOMINICANA", Validators.required],
    strRazonSocial: ['', Validators.required],
    strRFC: ['', [Validators.required, Validators.pattern('^[A-ZÑ&]{3,4}\\d{6}[A-Z0-9]{2,3}$')]],
    strRegimenFiscal: ['', Validators.required],
    strMetodoPago: ['', Validators.required],
    strUsoCfdi: ['', Validators.required],
    strNombre: ['', Validators.required],
    strApellidos: ['', Validators.required],
    strColonia:['',[Validators.required,Validators.minLength(1)]],
    strCodigoPostal: ['', [Validators.required, Validators.pattern('^\\d{5}$')]],
    strCiudad: ['', Validators.required],
    strEstado: ['', Validators.required],
    strDireccion: ['', Validators.required]
  }); 
  
  // Asignar de uno en uno con patchValue
  this.facturaForm.get('strPais')?.patchValue(this.strConexion == "COMERCIAL" ? "México" : "REPUBLICA DOMINICANA");
  this.facturaForm.get('strRFC')?.patchValue(this.objDatosFiscales.strRFC?.toUpperCase() || '');
  this.facturaForm.get('strRegimenFiscal')?.patchValue(this.objDatosFiscales.strFiscalRegimen);
  this.facturaForm.get('strRazonSocial')?.patchValue(this.objDatosFiscales.strRazonSocial);
  this.facturaForm.get('strMetodoPago')?.patchValue(""); // Aquí asignas el valor directamente
  this.facturaForm.get('strUsoCfdi')?.patchValue(this.objDatosFiscales.strUsoCFDI);
  this.facturaForm.get('strNombre')?.patchValue(this.objDatosFiscales.strNombre);
  this.facturaForm.get('strApellidos')?.patchValue(this.objDatosFiscales.strApellidos);
  this.facturaForm.get('strColonia')?.patchValue(this.objDatosFiscales.strColonia);
  this.facturaForm.get('strCodigoPostal')?.patchValue(this.objDatosFiscales.strCodigoPostal);
  this.facturaForm.get('strCiudad')?.patchValue(this.objDatosFiscales.strDelegacion);
  this.facturaForm.get('strEstado')?.patchValue(this.objDatosFiscales.strEstado);
  this.facturaForm.get('strDireccion')?.patchValue(this.objDatosFiscales.strDireccion);
  
    //this.getCp(this.objDatosFiscales.strCodigoPostal+"",true)
  
  setTimeout(() => {
    
    this.facturaForm.get('strPais')?.markAsTouched();
    this.facturaForm.get('strRazonSocial')?.markAsTouched();
    this.facturaForm.get('strRFC')?.markAsTouched();
    this.facturaForm.get('strRegimenFiscal')?.markAsTouched();
    this.facturaForm.get('strMetodoPago')?.markAsTouched();
    this.facturaForm.get('strUsoCfdi')?.markAsTouched();
    this.facturaForm.get('strNombre')?.markAsTouched();
    this.facturaForm.get('strApellidos')?.markAsTouched();
    this.facturaForm.get('strColonia')?.markAsTouched();
    this.facturaForm.get('strCodigoPostal')?.markAsTouched();
    this.facturaForm.get('strCiudad')?.markAsTouched();
    this.facturaForm.get('strEstado')?.markAsTouched();
    this.facturaForm.get('strDireccion')?.markAsTouched();
  }, 1000);

  
  
/*    
  if(this.valoresFormulario && this.valoresFormulario.strPais   ){
   
     this.facturaForm.setValue(this.valoresFormulario);
     this.simulateEnter2(); //simulamos el enter del codigo postal
     this.facturaForm.get('strPais')?.markAsTouched();
     this.facturaForm.get('strRazonSocial')?.markAsTouched();
     this.facturaForm.get('strRFC')?.markAsTouched();
     this.facturaForm.get('strRegimenFiscal')?.markAsTouched();
     this.facturaForm.get('strMetodoPago')?.markAsTouched();
     this.facturaForm.get('strUsoCfdi')?.markAsTouched();
     this.facturaForm.get('strNombre')?.markAsTouched();
     this.facturaForm.get('strApellidos')?.markAsTouched();
     this.facturaForm.get('strColonia')?.markAsTouched();
     this.facturaForm.get('strCodigoPostal')?.markAsTouched();
     this.facturaForm.get('strCiudad')?.markAsTouched();
     this.facturaForm.get('strEstado')?.markAsTouched();
     this.facturaForm.get('strDireccion')?.markAsTouched();
     
  } */
  
/* 
  this.facturaForm.statusChanges.subscribe(status => {
    // Si el formulario es válido, ejecutar onSubmit
    if (this.facturaForm.valid) {
      this.onSubmitFacturacion();
    }
  }); */
}
onSubmitFacturacion() {

  if (this.facturaForm.valid) {
    const botonPagar = document.querySelector('#cardPaymentBrick_container button') as HTMLButtonElement;
   if (botonPagar &&  !this.facturaForm.valid) {
     // Deshabilitar el botón para evitar múltiples clics
     botonPagar.disabled = true;
   }
   else if (botonPagar &&   this.facturaForm.valid){
     botonPagar.disabled = false;
   }  
 
 } else {
 
 }
}

//errores en el pago de mercado pago
getPaymentDescription(estado: string, status_detail: string): string {
  const descriptions: { [key: string]: { [key: string]: string } } = {
    approved: {
      accredited: "¡Listo! Se acreditó tu pago. En tu resumen verás el cargo de amount como statement_descriptor.",
      partially_refunded: "El pago se realizó con al menos un reembolso parcial.",
    },
    authorized: {
      pending_capture: "El pago fue autorizado y está a la espera de ser capturado.",
    },
    in_process: {
      offline_process: "Por una falta de procesamiento online, el pago está siendo procesado de manera offline.",
      pending_contingency: "Estamos procesando tu pago. No te preocupes, en menos de 2 días hábiles te avisaremos por e-mail si se acreditó.",
      pending_review_manual: "Estamos procesando tu pago. No te preocupes, en menos de 2 días hábiles te avisaremos por e-mail si se acreditó o si necesitamos más información.",
    },
    pending: {
      pending_waiting_transfer: "Para los casos de transferencia bancaria, este status_detail se obtiene al esperar a que el usuario termine el proceso de pago en su banco.",
      pending_waiting_payment: "Para los casos de pagos offline, el mismo queda pendiente hasta que el usuario realice el pago.",
      pending_challenge: "Para los casos de pagos con tarjeta de crédito, hay una confirmación pendiente a causa de un challenge.",
    },
    rejected: {
      bank_error: "Si el medio de pago es transferencia bancaria, el pago fue rechazado por un error con el banco.",
      cc_rejected_3ds_mandatory: "Pago rechazado por no tener el challenge 3DS cuando es obligatorio.",
      cc_rejected_bad_filled_card_number: "Revisa el número de tarjeta.",
      cc_rejected_bad_filled_date: "Revisa la fecha de vencimiento.",
      cc_rejected_bad_filled_other: "Revisa los datos.",
      cc_rejected_bad_filled_security_code: "Revisa el código de seguridad de la tarjeta.",
      cc_rejected_blacklist: "No pudimos procesar tu pago.",
      cc_rejected_call_for_authorize: "Debes autorizar ante payment_method_id el pago de amount.",
      cc_rejected_card_disabled: "Llama a payment_method_id para activar tu tarjeta o usa otro medio de pago. El teléfono está al dorso de tu tarjeta.",
      cc_rejected_card_error: "No pudimos procesar tu pago.",
      cc_rejected_duplicated_payment: "Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.",
      cc_rejected_high_risk: "Tu pago fue rechazado. Elige otro de los medios de pago, te recomendamos con medios en efectivo.",
      cc_rejected_insufficient_amount: "Tu payment_method_id no tiene fondos suficientes.",
      cc_rejected_invalid_installments: "payment_method_id no procesa pagos en installments (cuotas/meses).",
      cc_rejected_max_attempts: "Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.",
      cc_rejected_other_reason: "payment_method_id no procesó el pago.",
      cc_amount_rate_limit_exceeded: "El pago fue rechazado porque superó el límite (CAP - Capacidad Máxima Permitida) del medio de pago.",
      rejected_insufficient_data: "El pago fue rechazado debido a falta de toda la información obligatoria requerida.",
      rejected_by_bank: "Operación rechazada por el banco.",
      rejected_by_regulations: "Pago rechazado por regulaciones.",
      insufficient_amount: "Pago rechazado por montos insuficientes.",
    },
  };

  return descriptions[estado]?.[status_detail] || "Estado o detalle desconocido.";
}
getFormasPago(){
  this.ArrayFormasPago =this.formPago.getFormasPago()
 }
 
get fact() {

  return this.facturaForm.controls;
}
updateCantidadFact(event: Event): void {
  this.arrayColonias=[];
  let inputElement = event.target as HTMLInputElement;
  let valor = inputElement.value;

  // Expresión regular que permite solo números (enteros)
  const soloNumeros = /^\d*$/;

  // Validar que el valor solo contenga números (no letras, 'e', ni espacios)
  if (!soloNumeros.test(valor)) {
    // Remover caracteres no válidos manteniendo solo los números
    inputElement.value = valor.replace(/[^0-9]/g, '');
  }

  // Actualizar el valor en el formulario como una cadena para mantener ceros iniciales
  this.facturaForm.patchValue({ strCodigoPostal: inputElement.value });
  if(inputElement.value.length==5){
    this.getCp(inputElement.value,true)
  }else{
    this.facturaForm.patchValue({ strEstado: "" });
    this.facturaForm.patchValue({ strCiudad: "" });
    this.facturaForm.patchValue({ strColonia: "" });
  }
}
onEnterFact(event: KeyboardEvent) {
  if (event.key === "Enter") {
    let inputElement = event.target as HTMLInputElement;
   
    this.getCp(inputElement.value,true)

  }
}
fnGetCfdi() {
  this._httpService.mostrarCotizaciones({
    strAccion: 'getUsoCfdi',
    strConexion: this.strConexion
  }, 'sp_modventas_Directorio').subscribe(
    result => {
      this.arrayCfdi = result
    }, error => {
      var error = <any>error; 
    }
  );
}
fnGetFormaPago() {
  this._httpService.mostrarCotizaciones({
    strAccion: 'getFormaPago',
    strConexion: this.strConexion
  }, 'sp_modventas_Directorio').subscribe(
    result => {
      this.arrayFormaPago = result
    }, error => {
      var error = <any>error;
      console.log(error);
    }
  );
}
fnGetToken_aplazo(){
  this._httpService.getTokenApz_partner({} ).subscribe(
    result => { 
      let tok = result.Authorization?result.Authorization:'';
      if(tok !=''){
        this.fnGetUrl_aplazo(tok);
      }
       
    }, error => {
      var error = <any>error;
      console.log(error);
    }
  );
}
fnGetUrl_aplazo(token:string){
  let  valoresForm = this.formEntrega.value; 
  const encodedValue = encodeURIComponent(this.idEncriptado);
 
   let MerchantURL ="https://www.ecodeli.com/";
   let url_sucess = "https://www.ecodeli.com/partner-finpedido?id="+encodedValue;
   let url_error = "https://www.ecodeli.com/partner-error-compra?id="+encodedValue;
   let url_webHook ="https://www.ecodeli.com/webHook_aplazo_partner.php";//"https://www.cleanclean.mx/ApiRest/APIToken/v1/EcodeliPremia/secure-data"
  
  let data={
    "totalPrice": this.dblTotal,
    "shopId": "Tienda Ecodeli",
    "cartId": this.id,
    "successUrl": url_sucess,
    "errorUrl": url_error,
    "cartUrl": "https://www.ecodeli.com/partner-carrito",
    "webHookUrl": url_webHook,
    "MerchantURL":MerchantURL,
    "buyer": {
      "addressLine":  valoresForm.strDireccion,
      "email": valoresForm.email,
      "firstName": valoresForm.strNombre,
      "lastName": valoresForm.strApellidos,
      "loan_id": 1,
      "phone": valoresForm.strTelefono,
      "postalCode": valoresForm.strCodigoPostal
    },
    "products": this.cartItems_aplz,
    "discount": {
      "price": 0,
      "title": ""
    },
    "shipping": {
      "price":  this.dblEnvio,
      "title": "Envio"
    },
    "taxes": {
      "price": 0,
      "title": ""
    },
    "bearerToken":token
  } 
  console.log(data);
  this._httpService.setLoanRequestApz_partner(data ).subscribe(
    result => { 
      let urlR=result.url?result.url:''
      if(urlR!='')
      {
        let loanId=result.loanId;
        let loanToken=result.loanToken;
        //ACTUALIZAMOS LA INFORMACION
        this.fnGenerarPedido({},'APLAZO', urlR,loanId )

        //REDIRECCIONAMOS
       // window.location.href = urlR;
      }
      else {
        console.log('ocurrio un error al intentar generar url aplazo')
       
      }
       
    }, error => {
      var error = <any>error;
      console.log(error);
    }
  );
}
fnGetRegimenFiscal() {
  this._httpService.mostrarCotizaciones({
    strAccion: 'getFiscalRegimen',
    strConexion: this.strConexion
  }, 'sp_modventas_Directorio').subscribe(
    result => { 
      this.arrayRegimenFiscal = result
    }, error => {
      var error = <any>error;
      console.log(error);
    }
  );
}
convertirAMayusculas(event: Event) {
  const input = event.target as HTMLInputElement;
  input.value = input.value.toUpperCase();
  this.facturaForm.get('strRFC')?.setValue(input.value, { emitEvent: false });
}

fnCambioDireccion(){

  this.formEntrega.setValue({
    strPais: this.objEnvioPartner.strPais, // Valor actual, no se modifica porque es disabled
    strNombre: this.objClienteDatos.strNombre?this.objClienteDatos.strNombre:'',
    strApellidos: this.objClienteDatos.strApellido?this.objClienteDatos.strApellido:'',
    strDireccion: this.objEnvioPartner.strDireccion?this.objEnvioPartner.strDireccion:'', 
    strEntreCalles: this.objEnvioPartner.strEntreCalles?this.objEnvioPartner.strEntreCalles:'', 
    strCodigoPostal: this.objEnvioPartner?this.objEnvioPartner.strCodigoPostal:'', 
    strEstado: this.objEnvioPartner.strEstado?this.objEnvioPartner.strEstado:'', 
    strCiudad: this.objEnvioPartner.strDelegacion?this.objEnvioPartner.strDelegacion:'',
    strColonia: this.objEnvioPartner?this.objEnvioPartner.strColonia:'', 
    strEnvioNovedades: this.formEntrega.get('strEnvioNovedades').value, // Mantenemos el valor actual
    email: this.objClienteDatos.strEmail?this.objClienteDatos.strEmail:'',
    strTelefono :this.objEnvioPartner.strTelefonos ? (() => {
        // Elimina cualquier carácter que no sea un número
        const numeros = this.objEnvioPartner.strTelefonos.replace(/\D/g, '');
        
        // Si comienza con "01", toma los 10 números siguientes
        if (numeros.startsWith('01')) {
          return numeros.substring(2, 12); // Toma los 10 números después del "01"
        } 
  
        // Si no tiene "01" al inicio, toma los primeros 10 números
        return numeros.substring(0, 10);
      })()
    : ''
  }); 
 



  this.formEntrega.get('email')?.markAsTouched();
  this.formEntrega.get('strNombre')?.markAsTouched();
  this.formEntrega.get('strApellidos')?.markAsTouched();
  this.formEntrega.get('strColonia')?.markAsTouched();
  this.formEntrega.get('strEntreCalles')?.markAsTouched();
  this.formEntrega.get('strCodigoPostal')?.markAsTouched();
  this.formEntrega.get('strDireccion')?.markAsTouched();
  this.formEntrega.get('strTelefono')?.markAsTouched();
  this.simulateEnter();
}

  fnAgregarComentario(){
    if(this.boolComentario){
      this.boolComentario = false
    }else{
      this.boolComentario = true
    }
  }
  fnGetDatosFacturacion(){
    this.objDatosFiscales = {}

  }
 fnGetDatosFiscalesAddSuc(numb:number=0) {
                this._httpService.mostrarCotizaciones({
                  strCodigoPostal:this.objDatosFiscales.strRFC,
                  strAccion:'getCodigoPostal',
                  strConexion:this.strConexion
                },"sp_ModVentas_Directorio").subscribe(
                  result => {
                    if (result.length > 0) {
                    
                      if(numb == 1){
                        this.facturaForm.controls['estado'].setValue(result[0].strEstado);
                        this.facturaForm.controls['delegacion'].setValue(result[0].strDelegacion);
                        this.facturaForm.controls['poblacion'].setValue(result[0].strPoblacion);
                        this.facturaForm.controls['almacen'].setValue(result[0].strNombre);
                        this.facturaForm.controls['intAlmacen'].setValue(result[0].strAlmacen);
                      
                      }
                       
            
                        this.arrayColonias = result; 
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: '¡Codigo Postal Incorrecto!',
                      });
                      if(numb == 1){
                        this.facturaForm.controls['estado'].setValue("");
                        this.facturaForm.controls['delegacion'].setValue("");
                        this.facturaForm.controls['poblacion'].setValue("");
                        this.facturaForm.controls['almacen'].setValue("");
                        this.facturaForm.controls['intAlmacen'].setValue("");
                      }
                       
                      this.arrayColonias = []
                    }
                  },
                  error => {
                    var error = <any>error;
                    console.log(error);
                  }
                );
  }

   async  fnGetInfo(cte:string): Promise<void>{
                return new Promise((resolve) => {
                  this._httpService.mostrarCotizaciones({
                    strAccion: 'getCliente', 
                    strUsuario:cte,
                    strConexion: this.strConexion
                  }, 'sp_PartNer').subscribe(
                    result => {
                      this.objDatosFiscales = result[0] 
                      this.fnFormFacturacion();
                      resolve();
                    }
                  );
                });
  }

  fnGetDisponible(art:any){
                  this.arraySaldou =[] 
                  let articulo = {
                    strAccion: "getDisponible",
                    strConexion: this.strConexion,
                    strArticulo:art.strArticulo
                  };
                  this._httpService.mostrarCotizaciones(articulo, 'sp_ECommerce').subscribe(
                    result => { 
                        this.arraySaldou = result; 
                        this.objDisponible_almacen = art.strOpcion !=''? this.arraySaldou.find(o=>o.strAlmacen==art.strOpcion && o.strOpcion==art.strOpcion) :this.arraySaldou.find(o=>o.strAlmacen==1);
                        this.dblDisponible_nacional=0
                        this.dblReservado_nacional=0
                        let nacional_opcion =[]
                        
                        nacional_opcion = art.strOpcion !=''? this.arraySaldou.filter(o=> o.strOpcion==art.strOpcion) :this.arraySaldou;
                        nacional_opcion.forEach(element => {
                          let disponible=element && element.dblCantidad ?element.dblCantidad :0;
                          let reservado =element && element.dblReservado ?element.dblReservado :0;
                          this.dblDisponible_nacional=this.dblDisponible_nacional + disponible;
                          this.dblReservado_nacional=this.dblReservado_nacional +reservado;
                        }); 
                       this.intDisponible = this.dblDisponible_nacional - this.dblReservado_nacional
                       art.intDisponibleNacional = this.intDisponible
                      
                    });
  }

  fnGetDescuentos(){
                  let objParam = {
                    strAccion: "getDescuentos",
                    strConexion: this.strConexion  
                  };
                  this._httpService.mostrarCotizaciones(objParam, 'sp_ECommerce').subscribe(
                    result => {  
                        this.arrayDescuentos=result;
                    });
  }
  clearCart() {
     this.carroservice.deleteCarrito_partner();
      this.loadCart();
  }
   onMetodoPagoChange(event: Event) {
                  const selectElement = event.target as HTMLSelectElement;
                  const tipoPago = selectElement.value;
                  this.fnMetodoPago(tipoPago);
  }
   fnMetodoPago(tipoPago){
                  let objEnviar = {
                    strAccion:'update_formapago',
                    strFormaPagoTipo:tipoPago,
                    strCliente:this.strCliente,
                    strConexion:this.strConexion
                  }
                  this._httpService.mostrarCotizaciones(objEnviar, 'sp_PartNer').subscribe(
                    result => {   
                      if(result[0] && result[0].code == 200){
                        this.alertify.success("Metodo de pago Guardado Correctamente");
                      }else{
                        this.alertify.success("Error Al seleccionar Metodo de Pago");
                      }
                    });
  }

  fnEditardatosFiscales(){
    this.router.navigate(['/partner-cuenta'], { 
        queryParams: { editar: true}
    });
  }
  fnGetSaldo_monedero(){
    let objEnv = {
      strAccion: 'getMonederoPartner', 
      intID_Monedero:this.intID_Monedero,
      strConexion: this.strConexion
      }
     this._httpService.mostrarCotizaciones(objEnv, 'sp_ECommerce')
     .subscribe(
       result => {
        this.objMonedero= result[0]?result[0]:{};
       });
   }
   fnSaldo_Monedero(boolMonedero:any){
    if(boolMonedero == true){
      this.dblSaldo_Monedero = this.objMonedero.dblSaldo ? this.objMonedero.dblSaldo:0;
    }
    else {
      this.dblSaldo_Monedero = 0;
    }
    
    this.fnSumasItems();
   }
} 
