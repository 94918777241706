
<div class="container">
    <div class="row">
        <div class="col-12">
            <h2 class="text-center my-3 text-decoration-underline fw-bold"> CATALOGO DE <span class="titulo">ARTICULOS</span></h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12 shadow d-flex align-items-center rounded py-3">

        
        <div class="col-10 col-md-5">
            <mat-form-field appearance="outline" class="bg-white">
                <mat-label>Cliente</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
            
        </div>
        <div class="col-10 col-md-5">
            <mat-form-field appearance="outline" class="bg-white mt-3 mt-md-0 ms-md-3">
                <mat-label>Familia</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>supervisor_account</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-2">
            <button class="btn btnFilter shadow-sm"><i class="bi bi-funnel-fill fs-5"></i></button>
        </div>
      
    </div>
    </div>

    <div class="row" #pdfTableFiniquito>
        <div class="col-12 bg-white py-4 shadow-sm mt-3">
            <div class="col-12">
                <button  class="btn btn-outline-danger"  (click)=" fnimprimirpdf()">
                    Descargar <i class="bi bi-filetype-pdf"></i>
                </button>
            </div>
            <ng-container *ngFor="let cat of arrayCategorias">
                <ng-container  *ngFor="let grup of cat.arrayGrupos">
                    <div class="col-12 d-flex my-2 avoid-break"  >
                        <div class="col-9 d-flex ">
                            <div class="col-1 bgclsAzul">
                            </div>
                            <div class="col-4 d-flex py-1">
                                <img src="assets/img/logo/ecodeli.png" class="w-75 mx-auto" alt="">
                            </div>
                            <div class="col-7 bgclsAzul nunito  fw-bold d-flex align-items-center justify-content-end ">
                               <h2 class="mb-0 text-end me-2 fw-bold">{{cat.strCategoriaCatalogo}}</h2> 
                            </div>
                        </div>
                        <div class="col-3 bgRed nunito fw-bold d-flex align-items-center">
                            <h2 class="mb-0 ms-2 fw-bold">{{grup.strGrupo}}</h2> 
                        </div>
                    </div>
                        <div class="row avoid-break" >
                            <div class="col-6 py-5 " *ngFor="let art of grup.arrayArts">
                                <div class="col-12 d-flex justify-content-evenly">
                                    <div class="col-4">
                                        <img src="{{art.strImagen}}" class="w-100" alt="">
                                    </div>
                                    <div class="col-7">
                                        <div class="col-12 py-2">
                                            <h2 class=" text-center mb-0 fw-bold subTittle">{{art.strDescripcion}}</h2>
                                        </div>
                                        <div class="col-12 nunito fs15" [innerHTML]="formatDescription(art.strDescripcionLarga)">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 py-2">
                                    <table class="table w-100 table-bordered">
                                        <thead>
                                            <tr >
                                                <th class="bgclsAzul ">CLAVE</th>
                                                <th class="bgclsAzul">PRESENTACIÓN</th>
                                                <th class="bgclsAzul">CAPACIDAD</th>
                                                <th class="bgclsAzul">USO RECOMENDADO</th>
                                                <th class="bgclsAzul">PRECIO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{art.strArticulo}}</td>
                                                <td>{{art.strPresentacion}}</td>
                                                <td></td>
                                                <td>{{art.strUsoRecomendado}}</td>
                                                <td>{{art.dblPrecioLista|currency}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<!-- <button class="btn btn-primary"  (click)="createPDF()">generar pdf</button> -->