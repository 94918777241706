<div class="container">
  <div class="col-12 position-relative">
    <h1 class="text-center my-3 fw-bold nunito">
      CUENTA
    </h1>

    <button #btnEditar class="btn bg-white position-absolute top-0 end-0 border-0  fw-bold  border-2 border-bottom "[ngClass]="{'clsEdit border-warning hvsha':!editarParner,'hvsha1 text-primary border-primary':editarParner}" (click)="fnEditarUser()">
     <span *ngIf="!editarParner">
      <i class="fa-solid fa-user-pen borderRad px-1 pb-1 "></i> Editar
    </span>
    <span *ngIf="editarParner">
      <i class="bi bi-arrow-left borderRad px-1 pb-1"></i> Regresar
    </span>
  </button>
  </div>
  <div class="row justify-content-center" *ngIf="!editarParner" @fadeInOut>
    <div class="col-11 col-lg-10 bg-white borderRad shadow py-3">
      <div class="row">
        <div class="col-12 col-md-3 text-center d-flex flex-column align-items-center">
          <div class="rounded-circle borderImg  bg-secondary shadow d-flex mt-auto " style="width: 150px; height: 150px; overflow: hidden;">
            <img class="w-100 rounded-circle" src="{{strUrlLogo}}" alt="">
          </div>
          <button class="btn btn-primary my-2 fw-bold nunito mb-auto fs12" (click)="fnClickInput()">
            <i class="bi bi-arrow-repeat"></i>  Actualizar Logo
          </button>
          <input type="file" accept="image/*" #inputFile class="d-none" name="" id=""  (change)="onFileSelected($event)"> 
        </div>

        <div class="col-12 col-md-9">
          <div class="col-12">
            <h2 class="nunito fw-bold text-uppercase text-center">
              {{user.strNombre}} {{user.strApellidos}}
            </h2>
          </div>
        <div class="row mt-2 ">
          <div class="col-md-4 col-6 ">
            <div class=" borderRad shadow-sm ">
              <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
                <i class="fa-regular fa-envelope-open clsIcon me-2"></i> Correo:
              </div>
              <div class="col-12 text-center py-2">
                <span>
                  {{user.streMail1}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-6">
            <div class=" borderRad shadow-sm ">
            <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
              <i class="fa-solid fa-phone clsIcon me-2"></i> Telefono:
            </div>
            <div class="col-12 text-center py-2">
              <span>
                {{user.strTelefono}}
              </span>
            </div>
          </div>
          </div>
          <div class="col-md-4 col-6 mt-2 mt-md-0">
            <div class=" borderRad shadow-sm ">
            <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
              <i class="fa-regular fa-id-card clsIcon me-2"></i> Usuario:
            </div>
            <div class="col-12 text-center py-2">
              <span>
                {{user.streMail1}}
              </span>
            </div>
          </div>
          </div>
        </div>
        <div class="row mt-3 ">
          <div class="col-md-4 col-6 ">
            <div class=" borderRad shadow-sm ">
            <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
              <i class="fa-solid fa-location-dot clsIcon me-2"></i> Direccion:
            </div>
            <div class="col-12 text-center ">
              <span>
                {{user.strDireccion}}, {{user.strDireccionNumero}}, {{user.strColonia}}, {{user.strDelegacion}}, {{user.strEstado}}
              </span>
            </div>
          </div>
          </div>
          <div class="col-md-4 col-6">
            <div class=" borderRad shadow-sm ">
            <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
              <i class="bi bi-buildings clsIcon me-2"></i> Nombre Comercial:
            </div>
            <div class="col-12 text-center py-2">
              <span>
                {{user?.strNombreComercial}}
              </span>
            </div>
          </div>
          </div>
          <div class="col-md-4 col-6 mt-2 mt-md-0">
            <div class=" borderRad shadow-sm ">
              <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
                <i class="bi bi-person-rolodex clsIcon me-2"></i>  Razon Social: 
              </div>
              <div class="col-12 text-center py-2">
                <span>
                  {{user?.strRazonSocial}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4 col-6">
            <div class=" borderRad shadow-sm ">
            <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
              <i class="bi bi-bank2 clsIcon me-2"></i> RFC:
            </div>
            <div class="col-12 text-center py-2">
              <span>
                {{user?.strRFC}}
              </span>
            </div>
          </div>
          </div>
          <div class="col-md-4 col-6 ">
            <div class=" borderRad shadow-sm ">
            <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
              <i class="bi bi-bank clsIcon me-2"></i> REGIMEN FISCAL:
            </div>
            <div class="col-12 text-center py-2">
              <span>
                {{user?.regimen?.strDescripcion}}
              </span>
            </div>
          </div>
          </div>
          <div class="col-md-4 col-6 mt-2 mt-md-0">
            <div class=" borderRad shadow-sm ">
            <div class="col-12 d-flex align-items-center  fw-bold bg-light borderEsqui justify-content-center">
              <i class="bi bi-cash-coin clsIcon me-2"></i> USO CFDI: 
            </div>
            <div class="col-12 text-center py-2">
              <span>
                {{user.cfdiNombre?.strDescripcion}}
              </span>
            </div>
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center my-3" *ngIf="!editarParner" @fadeInOut>
    <div class="col-11 col-lg-10 bg-white borderRad shadow py-3" [hidden]="boolDireccion">
      <div class="row ">
        <div class="col-12 position-relative">
          <h3 class="mb-0 fw-bold"><i class="fa-solid fa-wallet"></i> MONEDERO ECODELI</h3> <br>
          <span>Número de monedero:</span> <b>{{id_monedero}}</b><br>
          <span>Saldo:</span> <span class="saldo"><b>{{objMonedero.dblSaldo | currency}}</b> </span> <span class="ms-2">MXN</span>
        </div>
        
      </div>
    </div>
  </div>
  <div class="row justify-content-center my-3" *ngIf="!editarParner" @fadeInOut>
    <div class="col-12 my-4" [hidden]="!boolDireccion">
      <div class="row justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
      
    </div>

    <div class="col-11 col-lg-10 bg-white borderRad shadow py-3" [hidden]="boolDireccion">
     
      <div class="row ">
        <div class="col-12 position-relative">
          <h3 class="mb-0 fw-bold"> DIRECCIONES DE ENVÍO</h3>
          <a class="py-0 px-1 position-absolute top-50 end-0 translate-middle-y btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#ModalAltaSuc" (click)="fnMdlAltaSuc()">
            <i class="bi bi-house-add"></i>
            <span class="fw-bold fs12 ms-1">Nueva Dirección</span>
          </a>
        </div>
        
      </div>

      <div class="row my-2 justify-content-center">
        <div class="col-9 my-2 border rounded" *ngFor="let dir of arrayDirecciones ">
          <div class="row justify-content-center">
            <div class="col-1 d-flex justify-content-center">
              <i class="bi bi-geo-alt fs-6 my-auto"></i>
            </div>
            <div class="col-10 d-flex flex-column my-2">
              <span class="fs14 fw-bold">
              {{dir.strSucursal}}
              </span>
              <span class="fs13">
                <span class="text-secondary">Contacto:</span> {{dir.strContacto_Nombre}}
              </span>
              <span class="fw-normal fs13">
                <span class="text-secondary">Dirección:</span>  {{dir.strCalle}} #<span>{{dir.strNo_exterior?dir.strNo_exterior:"S/N"}}</span>
              </span>
              <span>
                {{dir.strColonia}}
              </span>
              <span>
                {{dir.strDelegacion}} - {{dir.strEstado}} 
              </span>
              <span class="fs12">
               <span class="text-secondary">Cp:</span>  {{dir.strCodigoPostal}}
              </span>
              <span class="fs12">
               <span class="text-secondary">Teléfono:</span>  {{dir.strContacto_Telefono}}
              </span>
            </div>
            <div class="col-1 d-flex">
              <button class="my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                <i class="bi bi-three-dots-vertical"></i>
              </button>
              
              <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                <button mat-menu-item (click)="fnDeleteDireccion(dir)">
                  <mat-icon color="warn">delete</mat-icon>
                  <span class="text-danger">Eliminar</span>
                </button>
              </mat-menu>
              
            </div>
          </div>
       
        </div>
      </div>
      
    </div>
  </div>
  


</div>

<div class="container-fluid" *ngIf="editarParner" @fadeInOut1>
    <div class="row justify-content-center">
        <div class="col-10">
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
            <div class="shadow-sm p-2 bg-white mb-4 rounded bgcard">
              <h2 class="fw-bold mb-0 ">
                <i class="bi bi-info-circle" *ngIf="f.name.invalid || f.apellido.invalid || f.telefono.invalid " matTooltip="Faltan campos por llenar"></i>
                <i class="bi bi-check-circle" *ngIf="f.name.valid && f.apellido.valid && f.telefono.valid " matTooltip="Campos completos"></i>
                Información General
              </h2>
              <div class="form-group mt-3" >
                <label for="name">
                  <i class="bi bi-info-circle" *ngIf="f.name.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.name.valid" matTooltip="campo obligatorio"></i>
                  Nombre 
                </label>
                <input id="name" type="text" class="form-control" formControlName="name" placeholder="Ingresa tu nombre" />
                <div *ngIf="f.name.touched && f.name.invalid" class="text-danger">El nombre es obligatorio.</div>
              </div>
              <div class="form-group mt-3">
                <label for="apellido">
                  <i class="bi bi-info-circle" *ngIf="f.apellido.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.apellido.valid" matTooltip="campo obligatorio"></i>
                  Apellidos
                </label>
                <input id="apellido" type="text" class="form-control" formControlName="apellido" placeholder="Ingresa tus Apellidos" />
                <div *ngIf="f.apellido?.touched && f.apellido?.invalid" class="text-danger">El apellido es obligatorio.</div>
              </div>
              <div class="form-group mt-3">
                <label for="telefono">
                  <i class="bi bi-info-circle" *ngIf="f.telefono.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.telefono.valid" matTooltip="campo obligatorio"></i>
                  Teléfono 
                </label>
                <input id="telefono" type="number" class="form-control" formControlName="telefono" placeholder="Ingresa tu número de teléfono"  (input)="limitarTelefono($event)"  [maxlength]="10" />
                <div *ngIf="f.telefono?.touched && f.telefono?.invalid" class="text-danger">Ingrese un número de teléfono válido</div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <h2 class="fw-bold mb-0 ">
                    <i class="bi bi-info-circle" *ngIf="f.nombreComercial.invalid || f.razonSocial.invalid || f.rfc.invalid || f.regimenFiscal.invalid || f.usoCfdi.invalid || f.codigoPostal.invalid || f.estado.invalid || f.ciudad.invalid || f.colonia.invalid || f.calle.invalid || f.numeroExterior.invalid " matTooltip="Faltan campos por llenar"></i>
                    <i class="bi bi-check-circle" *ngIf="f.nombreComercial.valid && f.razonSocial.valid && f.rfc.valid && f.regimenFiscal.valid && f.usoCfdi.valid && f.codigoPostal.valid && f.estado.valid && f.ciudad.valid && f.colonia.valid && f.calle.valid && f.numeroExterior.valid " matTooltip="Campos completos"></i>
                    Información Fiscal
                  </h2>
                </div>
              </div>
              <div class="form-group mt-2">
                <label for="nombreComercial">
                  <i class="bi bi-info-circle" *ngIf="f.nombreComercial.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.nombreComercial.valid" matTooltip="campo obligatorio"></i>
                  Nombre Comercial 
                </label>
                <input id="nombreComercial" type="text" class="form-control" formControlName="nombreComercial" placeholder="Ingresa Nombre Comercial"  />
                <div *ngIf="f.nombreComercial.touched && f.nombreComercial.invalid" class="text-danger">El Nombre Comercial es obligatorio.</div>
               
              </div>
              <div class="form-group mt-3">
                <label for="razonSocial">
                  <i class="bi bi-info-circle" *ngIf="f.razonSocial.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.razonSocial.valid" matTooltip="campo obligatorio"></i>
                  Razón Social
                </label>
                <input id="razonSocial" type="text" class="form-control" formControlName="razonSocial" placeholder="Ingresa tu razón social" />
                <div *ngIf="f.razonSocial.touched && f.razonSocial.invalid" class="text-danger">La razón social es obligatoria.</div>
              </div>
              <div class="form-group mt-3">
                <label for="rfc">
                  <i class="bi bi-info-circle" *ngIf="f.rfc.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.rfc.valid" matTooltip="campo obligatorio"></i>
                  RFC
                </label>
                <input id="rfc" type="text" class="form-control" formControlName="rfc"  placeholder="Ingresa RFC"/>
                <div *ngIf="f.rfc.touched && f.rfc.invalid" class="text-danger">El RFC es obligatorio.</div>
              </div>
              <div class="form-group mt-3">
                <label for="regimenFiscal">
                  <i class="bi bi-info-circle" *ngIf="f.regimenFiscal.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.regimenFiscal.valid" matTooltip="campo obligatorio"></i>
                  Régimen Fiscal
                </label>
                <select id="regimenFiscal" class="form-select" aria-label="Default select example" formControlName="regimenFiscal">
                  <option value ="">--Seleccione--</option>
                  <option *ngFor = "let col of arrayRegimenFiscal" [value]="col.strFiscalRegimen">{{col.strDescripcion}}</option> 
                </select>
                <div *ngIf="f.regimenFiscal.touched && f.regimenFiscal.invalid" class="text-danger">El Régimen Fiscal es obligatorio.</div>
              </div>
              <div class="form-group mt-3">
                <label for="usoCfdi">
                  <i class="bi bi-info-circle" *ngIf="f.usoCfdi.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.usoCfdi.valid" matTooltip="campo obligatorio"></i>
                  Uso de CFDI  
                </label>
                <select id="usoCfdi" class="form-select" aria-label="Default select example" formControlName="usoCfdi">
                  <option value="">--Seleccione--</option>
                  <option *ngFor = "let col of arrayCfdi" [value]="col.strClaveUsoCFDI">{{col.strDescripcion}}</option> 
                </select>
                <div *ngIf="f.usoCfdi.touched && f.usoCfdi.invalid" class="text-danger">El Uso de CFDI es obligatorio.</div>
              </div>
              <div class="row">
                <div class="col-md-6 mt-3">
                  <label for="codigoPostal">
                    <i class="bi bi-info-circle" *ngIf="f.codigoPostal.invalid" matTooltip="campo obligatorio"></i>
                    <i class="bi bi-check-circle" *ngIf="f.codigoPostal.valid" matTooltip="campo obligatorio"></i>
                    Código Postal
                  </label>
                  <input id="codigoPostal" type="text" class="form-control" formControlName="codigoPostal" placeholder="Ingresa Código Postal" (input)="onInputChange($event)"  (keydown)="onKeyDown($event)" [maxlength]="15"/>
                  <div *ngIf="f.codigoPostal.touched && f.codigoPostal.invalid" class="text-danger">El Código Postal es obligatorio.</div>
               
                </div>
                <div class="col-md-6 mt-3">
                  <label for="estado">
                    <i class="bi bi-info-circle" *ngIf="f.estado.invalid" matTooltip="campo obligatorio"></i>
                    <i class="bi bi-check-circle" *ngIf="f.estado.valid" matTooltip="campo obligatorio"></i>
                    Estado  
                    
                  </label>
                  <input id="estado" readonly type="text" class="form-control" formControlName="estado" placeholder="Ingresa Estado"/>
                  <div *ngIf="f.estado.touched && f.estado.invalid" class="text-danger">El estado es obligatorio.</div>
               
                </div>
              </div>
        
              <div class="row">
                <div class="col-md-6 mt-3">
                  <label for="ciudad">
                    <i class="bi bi-info-circle" *ngIf="f.ciudad.invalid" matTooltip="campo obligatorio"></i>
                    <i class="bi bi-check-circle" *ngIf="f.ciudad.valid" matTooltip="campo obligatorio"></i>
                    Ciudad 
                    
                  </label>
                  <input id="ciudad" type="text" class="form-control" readonly formControlName="ciudad" placeholder="Ingresa Ciudad" />
                  <div *ngIf="f.ciudad.touched && f.ciudad.invalid" class="text-danger">La Ciudad es obligatoria.</div>
               
                </div>
                <div class="col-md-6 mt-3">
                  <label for="colonia">
                    <i class="bi bi-info-circle" *ngIf="f.colonia.invalid" matTooltip="campo obligatorio"></i>
                    <i class="bi bi-check-circle" *ngIf="f.colonia.valid" matTooltip="campo obligatorio"></i>
                    Colonia  
                  </label> 
      
                  <select id="colonia" class="form-select" aria-label="Default select example" formControlName="colonia">
                    <option value ="">--Seleccione Colonia--</option>
                    <option *ngFor = "let col of arrayColonias" [value]="col.strColonia">{{col.strColonia}}</option> 
                  </select>
      
                  <div *ngIf="f.colonia.touched && f.colonia.invalid" class="text-danger">La Colonia es obligatoria.</div>
               
                </div>
              </div>
        
              <div class="form-group mt-3">
                <label for="calle">
                  <i class="bi bi-info-circle" *ngIf="f.calle.invalid" matTooltip="campo obligatorio"></i>
                  <i class="bi bi-check-circle" *ngIf="f.calle.valid" matTooltip="campo obligatorio"></i>
                  Calle  
                </label>
                <input id="calle" type="text" class="form-control" formControlName="calle" placeholder="Ingresa Calle"/>
                <div *ngIf="f.calle.touched && f.calle.invalid" class="text-danger">La Calle es obligatoria.</div>
               
              </div>
        
              <div class="row">
                <div class="col-md-6 mt-3">
                  <label for="numeroExterior">
                    <i class="bi bi-info-circle" *ngIf="f.numeroExterior.invalid" matTooltip="campo obligatorio"></i>
                    <i class="bi bi-check-circle" *ngIf="f.numeroExterior.valid" matTooltip="campo obligatorio"></i>
                    Número Exterior  
                  </label>
                  <input id="numeroExterior" type="text" class="form-control" formControlName="numeroExterior" placeholder="Ingresa Número"/>
                  <div *ngIf="f.numeroExterior?.touched && f.numeroExterior?.invalid" class="text-danger">Número Exterior es Obligatorio.</div>
                </div>
               
              </div>
              <div class="col-12 my-2">
                <button type="submit" class="btn btn-primary "  [disabled]="registerForm.invalid"><i class="fa-solid fa-cloud-arrow-up"></i> Guardar</button>
              </div>
            </div>
          </form>
        </div>
    </div>
</div>


<div class="modal fade" id="ModalAltaSuc" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header clsModal">
        <h3 class="modal-title mb-0 fw-bold" id="exampleModalLabel">Alta Sucursal de Cliente</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="max-height: 60vh;overflow-y: auto;">
          <div class="row">
              <div class="col-12 col-md-6 d-flex align-items-center mb-2">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Clave del Cliente</mat-label>
                    <input matInput [(ngModel)]="strCte" ><!-- (input)="onInput2($event)" -->
                    <mat-icon matSuffix [ngClass]="{'text-success':boolCteExiste}">account_circle</mat-icon>
                  </mat-form-field>
              </div>
              <div class="col-12 col-md-6 my-2">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Agente</mat-label>
                    <input matInput  [value]="objPartner?.strAgente" readonly>
                    <mat-icon matSuffix [ngClass]="{'text-success': objPartner?.strAgente}">person</mat-icon>
                  </mat-form-field>
                </div>
          </div>
          
        <form [formGroup]="form" >

          <div class="row">
              <div class="col-12 col-md-6 my-2">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Nombre Direccion</mat-label>
                    <input matInput formControlName="sucursal">
                    <mat-icon matSuffix [ngClass]="{'text-success': !form.get('sucursal').hasError('required')}">location_on</mat-icon>
                  </mat-form-field>
              </div>
              <div class="col-12 col-md-6 my-2">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Cliente</mat-label>
                    <input class="fw-bold" matInput formControlName="nombreCte" readonly>
                    <mat-icon matSuffix [ngClass]="{'text-success':boolCteExiste}">verified_user</mat-icon>
                  </mat-form-field>
              </div>
            
            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>C.P.</mat-label>
                <input matInput formControlName="cp" (input)="onInput($event)"  (keydown.enter)="fnGetDatosFiscalesAddSuc()">
                <mat-icon matSuffix class="mouseHov" [ngClass]="{'text-success': !form.get('cp').hasError('required')}" (click)="fnGetDatosFiscales()">mail</mat-icon>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Estado</mat-label>
                <input matInput formControlName="estado" readonly>
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('estado').hasError('required')}">place</mat-icon>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Almacen</mat-label>
                <input matInput formControlName="almacen" >
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('almacen').hasError('required')}">streetview</mat-icon>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Delegación</mat-label>
                <input matInput formControlName="delegacion" readonly>
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('delegacion').hasError('required')}">location_city</mat-icon>
              </mat-form-field>
            </div>

          <!--  <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Poblacion</mat-label>
                <input matInput formControlName="poblacion" >
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('poblacion').hasError('required')}">home</mat-icon>
              </mat-form-field>
            </div> -->

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Colonia</mat-label>
                <select matNativeControl formControlName="colonia" (change)="onSelectColonias()">
                  <option value="" selected>-Seleccione Colonia-</option>
                  <option value="{{col.strColonia}}" *ngFor="let col of arrayColonias">{{col.strColonia}}</option>
                  <option value="otra" selected>Otra</option>
                </select>
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('colonia').hasError('required')}">home</mat-icon>

              </mat-form-field> 
              <input type="text" formControlName="strColoniaOtra" placeholder="Escriba otra colonia" class="form-control mt-2" *ngIf="form.get('colonia').value =='otra'">
              <mat-error *ngIf="form.get('strColoniaOtra').hasError('required')" class="fs10">Campo Obligatorio</mat-error>
            </div>

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>No. Exterior</mat-label>
                <input matInput formControlName="noExterior" class="no-spinners" type="text" maxlength="20">
                <mat-icon matSuffix  [ngClass]="{'text-success': !form.get('noExterior').hasError('required')}">format_list_numbered</mat-icon>
              </mat-form-field>
            </div> 
            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>No. Interior</mat-label>
                <input matInput formControlName="noInterior" class="no-spinners" type="text" maxlength="20">
                <mat-icon matSuffix  [ngClass]="{'text-success': !form.get('noInterior').hasError('required')}">format_list_numbered</mat-icon>
              </mat-form-field>
            </div> 

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Calle</mat-label>
                <input matInput formControlName="calle" >
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('calle').hasError('required')}">streetview</mat-icon>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Entre Calles</mat-label>
                <input matInput formControlName="entreCalles" >
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('entreCalles').hasError('required')}">compare_arrows</mat-icon>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Encargado</mat-label>
                <input matInput formControlName="encargado" >
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('encargado').hasError('required')}">supervisor_account</mat-icon>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Teléfono</mat-label>
                <input type="number" matInput formControlName="telefono" class="no-spinners" >
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('telefono').invalid }">call</mat-icon>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 my-2">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Correo Electrónico</mat-label>
                <input matInput formControlName="correo" >
                <mat-icon matSuffix [ngClass]="{'text-success': !form.get('correo').invalid}">email</mat-icon>
              </mat-form-field>
            </div>

          </div>
        </form>
      </div>
      <div class="modal-footer clsModal">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarAltaSuc>Cancelar</button>
        <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="onSubmitAddress()">Guardar</button>
      </div>
    </div>
  </div>
</div>
