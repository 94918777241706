import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import Swal from 'sweetalert2';  
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { filter, startWith } from 'rxjs/operators';
import { FiltrosService } from '../servicios/filtros.service';
import { DataDBService } from '../servicios/data-db.service';
import { PartnercteService } from '../servicios/partnercte.service';
import { ActivatedRoute } from '@angular/router';
import { AccesosServiceService } from '../servicios/accesos-service.service';
import { CarroService } from '../servicios/carroservice'; 
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public strPass:string='';
  public strUsuario:string='';
  strPassEncriptado:string='';
              hide = true;
  public selectEmpresa:string="COMERCIAL";
  public strTipo:string="";
  @ViewChild('miInput') miInputElement!: ElementRef;
  @ViewChild('inputUser') inputUser!: ElementRef;
  public arrayDescuentos:any =[];
  public montoCompra:number=0;
  //Crear variable para almacenar los accesos de las opciones del menu principal
  public arrayAccesosMenu={
    Aromia:false,
    Clientes: false,
    Ventas: false,
    Reportes: false,
    Otros: false, 
    Rubbermaid:false,
    ObjetivosMix:false,
    Leads:false,
    TiendaEcodeli:false,
    Directorio:false,
    Logisitica:false,
    Financiero:false,
  }
  //Crear variable para almacenar los accesos del submenu
  public arrayAccesoSubMenu={
    DirectorioClientes: false,
    ObjetivosMixNacional: false,
    ObjetivosMixDivisional:false,
    ObjetivosMixAgregar:false,
    ReporteVtasDetalle:false,
    ReporteVtasNetas:false,
    MiObjetivosMixDivisional:false,
    ObjetivosMixSucursal:false,
    ReporteComodatos:false,
    ReportePrincipal:false,
    ReporteVtasVsObjCtes: false,
    ReporteRetoTork: false,
    ReportePrecioMin: false,
    Pedidos:false,
    Inventarios:false,
    PedidosVtas:false,
    PedidosAromia:false,
    Comodatos:false,
    Cartera:false,
    Prospecto:false,
    Cliente:false,
    TEPedidos:false,
    TEInventario:false,
    Plantillas:false,
    ecoPremia:false,
    AltaClientes:false,
    Familias:false,
    Leads:false,
    Prospectos:false,
    ListadoComodatos:false,
    ComodatosCliente: false,
    ComprasIndirectas:false,
    ReporteRemisiones:false,
    ReporteEvidenciaComodatos:false,
    ReporteComplementoPago:false,
    ReporteEmbarques:false,
    SolicitudPresupuesto:false
  }
  constructor(private servicioPartner:PartnercteService,private carroservice: CarroService,private ServAccesos:AccesosServiceService,private route: ActivatedRoute, private indexedDBService: DataDBService,private _peticionesService: PeticionesService,public _alertify: AlertifyService, private localSt:LocalStorageService, private router: Router,private _filtroServ:FiltrosService) { 
    this.route.queryParams.subscribe(params => {
      this.strUsuario = params['uhttp'];
      this.strPassEncriptado= params['phttp'];
      if(this.strUsuario && this.strUsuario  !='' ){
        this.fnGetLogin();
      }
    });
    let strUrlHome = this.localSt.retrieve('strUrlHome');
    let strAgente = this.localSt.retrieve('strAgente');
    if(strUrlHome&& strUrlHome  !='' && strAgente  && strAgente !=""){
      this.router.navigate(["/"+strUrlHome]); 
    }

          
  }

  ngOnInit(): void {
  
  
    this.localSt.store('strUsuario','');

    this.localSt.store('arrayAccesos',[]);
    this.localSt.store('arrayAccesosMenu',[]);
    this.localSt.store('arrayAccesoSubMenu',this.arrayAccesoSubMenu);
    this.localSt.store('strConexion','');
    this.localSt.store('tipoUsuario','');
    this.localSt.store('ObjPlantillaEdit', {});
    this.localSt.store('arrayItemsComodato', []);
    this.localSt.store('ObjClienteComodato', []);
    this.localSt.store('arrayCotizador', []);
    this.localSt.store('arrayTotalesPrecio', []);
    this.localSt.store('cteParner', {});
    this.localSt.store('arrayArticulos', []);
    this.localSt.store('dblTotal', 0);
    this.localSt.store('array_Agentes', []);
    this.localSt.store('array_Sucursales', []);
    this.localSt.store('array_Gerentes', []);



  }

  ngAfterViewInit(): void {
    this.miInputElement.nativeElement.click();

 
    setTimeout(() => {
      this.inputUser.nativeElement.click();
    }, 1000);
 
  }

  fnSelectEmpresa(empresa:string){
    this.selectEmpresa = empresa;  
  }

 
  encrypt(string: string, key: string): string {
    let result = '';
    for (let i = 0; i < string.length; i++) {
        const char = string[i]; // Obtener el   carácter
        let lk = key.length;
        let mod = i%lk;
        let position=  mod - 1;
       

        if (position < 0) {
          position = lk - 1; // Ajustar a la última posición si es negativo
      }
  
        const keychar =  key.charAt(position);  // Obtener el código ASCII del carácter de la clave
        
        const asciiValue = char.charCodeAt(0) + keychar.charCodeAt(0) ; // Devuelve 97

        const char2 = String.fromCharCode(asciiValue); 

       
        result += char2;
       
        //const encryptedChar = String.fromCharCode(char + keychar); // Sumar los códigos ASCII
        //result += encryptedChar; // Agregar el carácter cifrado al resultado
    }
    return btoa(result); // Codificar el resultado en Base64
}


 
  fnGetEncriptado(){ 
    const encrypted = this.encrypt(this.strPass, 'eco');
    if(encrypted){
      this.strPassEncriptado=encrypted; 
      this.fnGetLogin();
    }
    
  }
  fnGetLogin( ){
    this._peticionesService.getEcodeli({
      strUsuario: this.strUsuario,
      strClave:this.strPassEncriptado,
      strConexion:this.selectEmpresa,
      strAccion:'NuevoLogin'
    },'sp_Login_NRoles').subscribe(
      result => {  
         
        if(result.length>0 && result[0] && result[0].strUrlHome){

          
          this.localSt.clear(); //limpiamos el storage   
       
          this.localSt.store('arrayAccesos',result);
          this.localSt.store('strUrlHome',result[0].strUrlHome);
          this.localSt.store('intIDRolAcceso',result[0].strTipoAcceso);
          this.localSt.store('strConexion',this.selectEmpresa);    
          this.localSt.store('strPais',this.selectEmpresa=="COMERCIAL"?"México":"Republica Dominicana");  
         
     
          
          /**Redireccionar a su página principal**/
          var strUrlHome = '/'+this.localSt.retrieve('strUrlHome');

         //verificamos si se trata de un cliente
         if(result[0].strTipoAcceso == 1013 ||result[0].strTipoAcceso == 1014 ||result[0].strTipoAcceso == 1016 ||result[0].strTipoAcceso == 1017 ||result[0].strTipoAcceso == 1015 ) {
          this.strTipo = result[0].strTipoAcceso
          this.fnGetData_Cte(result[0]);
          this.indexedDBService.setItem('strtipoLogin',   this.strTipo);
         }else{
          this.strTipo = ""

         }

         if(result[0].strTipoAcceso == 1015){ //si es partner obtenemos el importe de venta para obtener su descuento
            this.fn_delete_data_partnert();
            //this.montoCompra = result[0].dblImporte ?result[0].dblImporte :0;
            this.montoCompra =  result[0].nAcceso? result[0].nAcceso:0; // ahora me da el id del nivel
            this.fnGetCupones();
         }

          //guardamos todo en la base da datos
          this.indexedDBService.setItem('strConexion',  this.selectEmpresa );
          this.indexedDBService.setItem('arrayResult',  result );
        
          this.ServAccesos.updateAccesos(result);
          this.servicioPartner.setCte({});
          this.router.navigate([strUrlHome]);
        }else{
          Swal.fire("ERROR","¡Usuario o contraseña incorrectos!","error")
        }     
       
       }, error => {
        Swal.fire("ERROR","¡Usuario o contraseña incorrectos!","error")
       });
  }
  fnGetData_Cte(data){
    this._peticionesService.getEcodeli({
      strCliente: data.strCliente,
      strConexion:this.selectEmpresa,
      offset:0,
      pageSize:1,
      strAccion:'getCte'
    },'sp_ModVentas_Directorio').subscribe(
      result => {   
        let cte = result[0]?result[0]:{};
        cte.ClienteSeleccionado =true; 
        this.carroservice.setCte(cte);
      });
  }

  fnGetCupones(){
    let objEnviar = {
      strAccion:"getDescuento_partner",
      strConexion:this.selectEmpresa
    } 
    this._peticionesService.getEcodeli(objEnviar,'sp_eCommerce').subscribe(
      result =>{
        this.arrayDescuentos = result;
        this.getNivelActual(); 
      }
    )
  }

  getNivelActual()  {
    //buscamos el nivel en el array
    let objNivel = this.arrayDescuentos.find(o=> o.intID_Cupon == this.montoCompra) //aqui monto compra trae un id, ya no el importe

    let nivelActual = objNivel?objNivel.strNombre :'';    this.arrayDescuentos[0].strNombre; // Nivel por defecto
    let objNivelActual =objNivel?  objNivel: {}; //inicializamos en vacio por si no ha tenido compras o es su primer vez comprando

    /*
    for (let nivel of this.arrayDescuentos) {
      if (this.montoCompra >= nivel.dblMontoMin_Cupon) {
        nivelActual = nivel.strNombre;
        objNivelActual =nivel;

      }
    }
    */
    objNivelActual.montoCompra =this.montoCompra ;
    //avisar el nivel donde está
    this.carroservice.fnDescuento_partner(objNivelActual, 1); //seleccionamos por default la promocion DESCUENTO
   // return nivelActual;
  }
  fn_delete_data_partnert()
  {
    let objC ={};
    localStorage.setItem('objDescuento_partner', JSON.stringify(objC));
  }
}
