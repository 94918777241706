<div class="container-fluid">
    <div class="row bg-white pt-3">
        <div class="col-12 text-center ">
            <h1 class="fw-bold text-decoration-underline">Tablero <span class="tittle">Prospectos</span></h1>
        </div>
    </div>
    <div class="row bordertop borderbott shadow-sm bg-white pb-3">
        <div class="col-12 bgtable py-2 mb-3 d-flex bordertop  alight-items-center">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold mx-2">
                Filtros
            </span>
        </div>
        <div class="col-12 mt-2 pt-1 col-md col-12  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
                <mat-select [(ngModel)]="strGerente" class="">
                    <mat-option value=""> --Todos --</mat-option>
                    <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md  d-flex justify-content-center" *ngIf="boolSucursal"
            [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                <mat-select [(ngModel)]="strSucursal" class="">
                    <mat-option value=""> --Todas --</mat-option>
                    <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente "
                        [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md  d-flex justify-content-center" *ngIf="boolAgente"
            [ngClass]="{'col-xl-3':boolGerente||boolSucursal}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
                <mat-select [(ngModel)]="strAgente" class="">
                    <mat-option value=""> --Todos --</mat-option>
                    <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
                        [value]="x.strAgente">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md  d-flex justify-content-center"
            [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
                <mat-select [(ngModel)]="intPeriodo" class="">
                    <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md   d-flex justify-content-center"
            [ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
            [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }">
            <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true"
                (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"
                    [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>

    <div class="row">
        <button id="modalDetalle" class="d-none" data-bs-toggle="modal" data-bs-target="#detalleLead">b</button>
        <div class="col-md-3 col-4 text-left">
            <!--  <div class="rectangulo">
                {{strTipo}}
            </div> -->
            <div class="mx-3">
                <input type="text" class="form-control w-100  mx-2" placeholder="Buscar" [(ngModel)]="filterPost">
            </div>
        </div>
        <div class="col-md-9 col-8 d-inline-flex">
            <div class="ms-auto d-flex align-items-center justify-content-end">

                <div class="dropdown mx-2">
                    <button class="btn btn-outline-secondary  dropdown-toggle justify-content-center" type="button"
                        id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-filter"></i> Filtros
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <form>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox1" name="radioGroup"
                                    [checked]="checkboxes['conDemora']" (change)="onCheckboxChange('conDemora')">
                                <label for="checkbox1" class="m-auto">Con demora</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox2" name="radioGroup" [checked]="checkboxes['hoy']"
                                    (change)="onCheckboxChange('hoy')">
                                <label for="checkbox2" class="m-auto">Hoy</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox3" name="radioGroup" [checked]="checkboxes['manana']"
                                    (change)="onCheckboxChange('manana')">
                                <label for="checkbox3" class="m-auto">Mañana</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox4" name="radioGroup"
                                    [checked]="checkboxes['estaSemana']" (change)="onCheckboxChange('estaSemana')">
                                <label for="checkbox4" class="m-auto">Esta semana</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox5" name="radioGroup"
                                    [checked]="checkboxes['esteMes']" (change)="onCheckboxChange('esteMes')">
                                <label for="checkbox5" class="m-auto">Este mes</label>
                            </div>
                            <div class="dropdown-item d-flex">
                                <input type="checkbox" id="checkbox6" name="radioGroup"
                                    [checked]="checkboxes['futuros']" (change)="onCheckboxChange('futuros')">
                                <label for="checkbox6" class="m-auto">Futuros</label>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="btn-group ms-2 me-3" role="group" aria-label="Basic example">
                    <button type="button" class="btn  btn-sm d-flex flex-column no-btn p-0"
                        [ngClass]="{'active ': activeButton === 'calendario'}" (click)="cambiarVista('calendario')">
                        <i class="m-auto fs-3 bi"
                            [ngClass]="{'bi-calendar-date-fill active text-primary': activeButton === 'calendario',' bi-calendar-date': activeButton != 'calendario'}"></i>
                        <span class="mx-auto fw-lighter"
                            [ngClass]="{'fw-bold': activeButton === 'calendario'}">Calendario</span>
                    </button>
                    <button type="button" class="btn  btn-sm d-flex flex-column no-btn p-0 mx-3"
                        [ngClass]="{'active ': activeButton === 'lista'}" (click)="cambiarVista('lista')">
                        <i class="m-auto fs-3 bi"
                            [ngClass]="{'bi-list-check active text-primary': activeButton === 'lista','bi-list': activeButton != 'lista'}"></i>
                        <span class="mx-auto fw-lighter" [ngClass]="{'fw-bold': activeButton === 'lista'}">Lista</span>
                    </button>
                    <button type="button" class="btn  btn-sm d-flex flex-column active no-btn p-0"
                        [ngClass]="{'active ': activeButton === 'cards'}" (click)="cambiarVista('cards')">
                        <i class="m-auto fs-3 bi"
                            [ngClass]="{'bi-credit-card-2-front-fill active text-primary': activeButton === 'cards','bi-credit-card-2-front': activeButton != 'cards'}"></i>
                        <span class="mx-auto fw-lighter" [ngClass]="{'fw-bold': activeButton === 'cards'}">Cards</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="banderaCards">
        <div class="col border-end">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <div class="circleCantidadLeads mx-0">
                                <span class="textCantidad">{{intTotal_propuesta}}</span>
                            </div>
                            <span class=" text-sm fw-bold ms-2 fw-bold" style="font-size: 12px;">Propuesta
                                Economica</span>
                        </div>
                        <div class="col-6 col-lg d-flex align-items-center columna-cell">
                            <span class="ms-auto">{{dblMontoPropuesta |currency:'':'symbol':'1.2-2'}}</span>
                            <i class="fas fa-sort-amount-down-alt mx-1 iconoFiltro"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado4 w-100 m-auto"></div>
                </div>
                <!-- <div class="row mt-1 ">
                    <div class="col-6 col-lg d-flex  ope-0">
                        <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                            <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                            <b>{{intTotalServiciosPRO | currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                    <div class="col-6 col-lg d-flex  order-xxl-3 pe-0 ">
                        <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                            <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i> <b>{{intTotalMaterialesPRO |
                                currency:'':'symbol':'1.2-2'}}</b>
                        </span>

                    </div>
                </div> -->
            </div>
            <div class="row columna-con-scroll p-2">
                <div class="card card-sin-datos mx-auto mt-1" *ngIf="arrayPropuesta.length == 0 && !spinner"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="fw-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center py-3" *ngIf="spinner">
                    <mat-spinner strokeWidth="6" diameter="40"></mat-spinner>
                </div>
                <div class="card mx-auto mt-2 px-0 py-0"
                    *ngFor="let item of arrayPropuesta| pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item)">
                    <div class="card-header d-flex ps-2 pe-2">
                        <div class="me-auto">
                            <span>Folio:</span><span class="fw-bold mx-1">{{item.intIdLeads}}</span>
                        </div>
                        <div class="ms-auto">
                            <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4 d-flex">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center">
                                <div class="row">
                                    <!--  <div class="col-12 d-flex justify-content-end">
                                        <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                                    </div> -->
                                    <div class="col-12 d-flex justify-content-center mt-2">
                                        <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos mr-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <!--  <div class="ms-auto d-flex">
                                    <i class="fa-solid fa-a mx-1"></i>  <i class="fa-solid fa-a mx-1"></i><i class="fa-solid fa-a mx-1"></i>
                                </div>  -->
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex "
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 || item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>
                <button *ngIf="esDispositivoPequeno && datosFiltradosPropuesta.length > 3"
                    class="btn btn-outline-success btn-sm m-auto">Ver
                    más</button><!--(click)="mostrarMas('propuestaProspecto')"-->
            </div>

        </div>
        <div class="col-md 4 col-sm-12 border-end">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <div class="circleCantidadLeads mx-0">
                                <span class="textCantidad">{{intTotal_negociacion}}</span>
                            </div>
                            <span class=" text-sm fw-bold ms-2 fw-bold" style="font-size: 12px;">Negociación</span>
                        </div>
                        <div class="col-6 col-lg d-flex align-items-center columna-cell">
                            <span class="ms-auto">{{dblMontoNegociacion |currency:'':'symbol':'1.2-2'}}</span>
                            <i class="fas fa-sort-amount-down-alt mx-1 iconoFiltro"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado5 w-100 m-auto"></div>
                </div>
                <!-- <div class="row mt-1">
                    <div class="col-6 col-lg d-flex  ope-0">
                        <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                            <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                            <b>{{intTotalServiciosNEG | currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                    <div class="col-6 col-lg d-flex  order-xxl-3 pe-0">
                        <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                            <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i> <b>{{intTotalMaterialesNEG |
                                currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                </div> -->
            </div>
            <div class="row columna-con-scroll p-2">

                <div class="card card-sin-datos mx-auto mt-1" *ngIf="arrayNegociacion.length == 0 && !spinnerN"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="fw-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center py-3" *ngIf="spinnerN">
                    <mat-spinner strokeWidth="6" diameter="40"></mat-spinner>
                </div>
                <div class="card mx-auto mt-2 px-0 "
                    *ngFor="let item of arrayNegociacion | pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item)">
                    <div class="card-header d-flex ps-2 pe-2">
                        <div class="me-auto">
                            <span>Folio:</span><span class="fw-bold mx-1">{{item.intIdLeads}}</span>
                        </div>
                        <div class="ms-auto">
                            <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4 d-flex">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center">
                                <div class="row">
                                    <!--  <div class="col-12 d-flex justify-content-end">
                                        <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                                    </div> -->
                                    <div class="col-12 d-flex justify-content-center mt-2">
                                        <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos mr-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <!--  <div class="ms-auto d-flex">
                                    <i class="fa-solid fa-a mx-1"></i>  <i class="fa-solid fa-a mx-1"></i><i class="fa-solid fa-a mx-1"></i>
                                </div>  -->
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex "
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 || item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>
                <!-- <div class="container-fluid d-flex justify-content-center mt-5">
                    <div class="card columna-con-scroll" style="max-height: 600px;">
                        <div class="card-header d-flex p-3 header-card">
                            <span class="badge bg-success d-flex justify-content-center alight-items-center"
                                style="border-radius: 50%; font-size: 10px;">25</span> <span class="">MORIROKU
                                TECHNOLOGY</span> <span class="ms-auto"><i class="fa-solid fa-a mx-1"></i> <i
                                    class="fa-solid fa-a mx-1"></i><i class="fa-solid fa-a mx-1"></i></span>
                        </div>
                        <div class="card-body  ">
                            <div class="d-flex">
                                <h5 class="card-title">19 Días 21 Hrs. 19 Min.</h5> <span
                                    class="ms-auto btn btn-success btn-sm"
                                    (click)="mostrarMas('propuestaProspecto')">Ver Mas...</span>
                            </div>
                            <div class="row columna-con-scroll">
                                <div class="card mx-auto mt-4 px-0 py-1" data-bs-toggle="modal"
                                    data-bs-target="#detalleLead"
                                    *ngFor="let item of datosFiltradosPropuesta.slice(0, elementosPEProspecto)| pipeTablero: filterPost; let i = index">
                                    <div class="card-body" style="border-left: 1px solid dotten;">
                                        <div class="row">
                                            <div class="col-3 d-flex">
                                                <div class="circle">
                                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                                    <span class="text"
                                                        *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                                </div>
                                            </div>
                                            <div class="col-9 text-center">
                                                <div class="row">
                                                    <div class="col-12 d-flex justify-content-end">
                                                        <i class="fa-solid fa-a mx-1"></i> <i
                                                            class="fa-solid fa-a mx-1"></i><i
                                                            class="fa-solid fa-a mx-1"></i>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-center mt-2">
                                                        <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-12 d-flex mt-1">
                                                <div class="me-auto">
                                                    <span class=" text-minutos mr-auto mt-1"><i
                                                            class="far fa-clock mx-1"></i>
                                                        <span>{{item.strUltimoCambio}}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer d-flex "
                                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                                        <i class="fas fa-envelope me-auto mx-3 correo"
                                            *ngIf="item.strTipo == 'correo'"></i>
                                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                                        <div class="ms-auto d-flex">
                                            <span class="text-sinAct text-success"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                                Actividad</span>
                                            <span class="text-footer text-danger"
                                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                            <span class="text-footer text-success mx-1"
                                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                                dias</span>
                                            <span class="text-footer text-danger mx-1"
                                                *ngIf="item.intTiempoTranscurridoHoras < 0 || item.intTiempoTranscurridoDias < 0"><i
                                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                                            <i class="fas fa-chevron-right mx-1"></i>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div> -->

                <button *ngIf="esDispositivoPequeno && datosFiltradosNegociacion.length > 3"
                    class="btn btn-outline-success btn-sm m-auto">Ver
                    más</button>
            </div>

        </div>
        <div class="col border-end columna-con-scroll">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6 col-lg d-flex align-items-center ">
                            <div class="circleCantidadLeads mx-0">
                                <span class="textCantidad">{{intTotal_cierre}}</span>
                            </div>
                            <span class=" text-sm fw-bold ms-2 fw-bold" style="font-size: 12px;">Cierre</span>
                        </div>
                        <div class="col-6 col-lg d-flex align-items-center columna-cell">
                            <span class="ms-auto">{{dblMontoCierre |currency:'':'symbol':'1.2-2'}}</span>
                            <i class="fas fa-sort-amount-down-alt mx-1 iconoFiltro"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex mt-2">
                    <div class="degradado6 w-100 m-auto"></div>
                </div>
                <!-- <div class="row mt-1">
                    <div class="col-6 col-lg d-flex  ope-0">
                        <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                            <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                            <b>{{intTotalServiciosCIERRE | currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                    <div class="col-6 col-lg d-flex  order-xxl-3 pe-0">
                        <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                            <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i> <b>{{intTotalMaterialesCIERRE |
                                currency:'':'symbol':'1.2-2'}}</b>
                        </span>
                    </div>
                </div> -->
            </div>

            <div class="row columna-con-scroll p-2">
                <div class="card card-sin-datos mx-auto mt-1" *ngIf="arrayCierre.length == 0 && !spinnerC"
                    style="width: 95%;">
                    <div class="card-header d-flex">
                        <span class="fw-bold text-sm m-auto">SIN DATOS</span>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center py-3" *ngIf="spinnerC">
                    <mat-spinner strokeWidth="6" diameter="40"></mat-spinner>
                </div>
                <div class="card mx-auto mt-2 px-0 py-0"
                    *ngFor="let item of arrayCierre | pipeTablero: filterPost; let i = index"
                    (click)="fnDetalleLead(item)">
                    <div class="card-header d-flex ps-2 pe-2">
                        <div class="me-auto">
                            <span>Folio:</span><span class="fw-bold mx-1">{{item.intIdLeads}}</span>
                        </div>
                        <div class="ms-auto">
                            <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4 d-flex">
                                <div class="circle">
                                    <span class="text" *ngIf="item.strAgente == ''">S/N</span>
                                    <span class="text" *ngIf="item.strAgente != ''">{{item.strAgente}}</span>
                                </div>
                            </div>
                            <div class="col-8 text-center">
                                <div class="row">
                                    <!-- <div class="col-12 d-flex justify-content-end">
                                        <span class="fw-bold text-success text-nowrap">{{item.strClasificacion}}</span>
                                    </div> -->
                                    <div class="col-12 d-flex justify-content-center mt-2">
                                        <p class="textoCards m-auto">{{item.strNombreEmpresa}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex mt-1">
                                <div class="me-auto">
                                    <span class=" text-minutos mr-auto mt-1"><i class="far fa-clock mx-1"></i>
                                        <span>{{item.strUltimoCambio}}</span>
                                    </span>
                                </div>
                                <!--  <div class="ms-auto d-flex">
                                <i class="fa-solid fa-a mx-1"></i>  <i class="fa-solid fa-a mx-1"></i><i class="fa-solid fa-a mx-1"></i>
                            </div>  -->
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex "
                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">

                        <i class="fas fa-envelope me-auto mx-3 correo" *ngIf="item.strTipo == 'correo'"></i>
                        <i class="fas fa-phone-alt me-auto mx-3" *ngIf="item.strTipo == 'llamada'"></i>
                        <i class="fas fa-walking me-auto mx-3" *ngIf="item.strTipo == 'visita'"></i>
                        <div class="ms-auto d-flex">
                            <span class="text-sinAct text-success"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">Sin
                                Actividad</span>
                            <span class="text-footer text-danger"
                                *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                    class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                            <span class="text-footer text-success mx-1"
                                *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                dias</span>
                            <span class="text-footer text-danger mx-1"
                                *ngIf="item.intTiempoTranscurridoHoras < 0 || item.intTiempoTranscurridoDias < 0"><i
                                    class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                            <i class="fas fa-chevron-right mx-1"></i>
                        </div>
                    </div>
                </div>

                <button *ngIf="esDispositivoPequeno && datosFiltradosCierre.length > 3"
                    class="btn btn-outline-success btn-sm m-auto">Ver
                    más</button><!--(click)="mostrarMas('cierreProspecto')"-->
            </div>

        </div>
    </div>
    <div class="row mt-3" *ngIf="banderaLista">
        <ul class="list-group" *ngIf="strTipo == 'prospecto'">
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-4 col-12 d-flex ">
                                <div class="circleCantidadLeads mx-2">
                                    <span class="textCantidad">{{intTotal_propuesta}}</span>
                                </div>
                                <span class="p-2 text-sm fw-bold mr-auto mt-auto">Propuesta Economica</span>
                            </div>
                            <!-- <div class="col-md-5 col-12 d-flex justify-content-center">
                                <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                                    <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                                    <b>{{intTotalServiciosPRO | currency:'':'symbol':'1.2-2'}}</b>
                                </span>
                                <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                                    <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i>
                                    <b>{{intTotalMaterialesPRO |
                                        currency:'':'symbol':'1.2-2'}}</b>
                                </span>
                            </div> -->
                            <div class="col-md-3 col-12 d-flex justify-content-center">
                                <span class="ms-auto text-sm px-3">{{ dblMontoPropuesta |
                                    currency:'':'symbol':'1.2-2'
                                    }}</span>
                            </div>
                            <div class="col-12 d-flex mt-2">
                                <div class="degradado4 w-100 m-auto"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2 table-responsive">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th scope="col">Agente</th>
                                    <th scope="col" class="colorColumna">Empresa</th>

                                    <th scope="col">Monto</th>
                                    <th scope="col">Actividad</th>
                                    <th scope="col">Sector</th>
                                    <th scope="col">Ultimo Cambio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of arrayPropuesta | pipeTablero: filterPost; let i = index">
                                    <th>{{i+1}}</th>
                                    <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                    <td class="colorColumna" style="cursor: pointer;">
                                        <strong>{{item.strNombreEmpresa}}</strong>
                                    </td><!--(click)="fnDetalleLead(item.intIdLeads)" data-toggle="modal"
                                            data-target="#detalleLead"-->

                                    <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                    <td
                                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                        <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                        <i class="fas fa-phone-alt m-auto mx-2" *ngIf="item.strTipo == 'llamada'"></i>
                                        <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                        <i class="far fa-star m-auto mx-1 text-warning"
                                            *ngIf="item.strTipo == 'GANADO'"></i>

                                        <span class="text-sinAct text-success"
                                            *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                        <span class="text-footer text-danger"
                                            *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                        <span class="text-footer text-success mx-1"
                                            *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                            dias</span>
                                        <span class="text-footer text-danger mx-1"
                                            *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>


                                    </td>
                                    <td>{{item.strSector}}</td>
                                    <td>{{item.strUltimoCambio}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </li>
            <!--Negociacion-->
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-4 col-12 d-flex ">
                                <div class="circleCantidadLeads mx-2">
                                    <span class="textCantidad">{{intTotal_negociacion}}</span>
                                </div>
                                <span class="p-2 text-sm fw-bold mr-auto mt-auto">Negociación</span>
                            </div>
                            <!-- <div class="col-md-5 col-12 d-flex text-center">
                                <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                                    <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                                    <b>{{intTotalServiciosNEG | currency:'':'symbol':'1.2-2'}}</b>
                                </span>
                                <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                                    <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i>
                                    <b>{{intTotalMaterialesNEG |
                                        currency:'':'symbol':'1.2-2'}}</b>
                                </span>
                            </div> -->
                            <div class="col-md-2 col-12 d-flex">
                                <span class="ms-auto text-sm px-3">{{ dblMontoNegociacion |
                                    currency:'':'symbol':'1.2-2'
                                    }}</span>
                            </div>
                            <div class="col-12 d-flex mt-2">
                                <div class="degradado5 w-100 m-auto"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2 table-responsive">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th scope="col">Agente</th>
                                    <th scope="col" class="colorColumna">Empresa</th>

                                    <th scope="col">Monto</th>
                                    <th scope="col">Actividad</th>
                                    <th scope="col">Sector</th>
                                    <th scope="col">Ultimo Cambio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of arrayNegociacion | pipeTablero: filterPost; let i = index">
                                    <th>{{i+1}}</th>
                                    <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                    <td class="colorColumna" style="cursor: pointer;">
                                        <strong>{{item.strNombreEmpresa}}</strong>
                                    </td> <!-- (click)="fnDetalleLead(item.intIdLeads)" data-toggle="modal"
                                        data-target="#detalleLead"-->

                                    <td>{{item.dblMonto| currency:'':'symbol':'1.2-2'}}</td>
                                    <td
                                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                        <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                        <i class="fas fa-phone-alt m-auto mx-2" *ngIf="item.strTipo == 'llamada'"></i>
                                        <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                        <i class="far fa-star m-auto mx-1 text-warning"
                                            *ngIf="item.strTipo == 'GANADO'"></i>

                                        <span class="text-sinAct text-success"
                                            *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                        <span class="text-footer text-danger"
                                            *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                        <span class="text-footer text-success mx-1"
                                            *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                            dias</span>
                                        <span class="text-footer text-danger mx-1"
                                            *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                                    </td>
                                    <td>{{item.strSector}}</td>
                                    <td>{{item.strUltimoCambio}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </li>
            <!--Cierre-->
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col d-flex ">
                                <div class="circleCantidadLeads mx-2">
                                    <span class="textCantidad">{{intTotal_cierre}}</span>
                                </div>
                                <span class="p-2 text-sm fw-bold mr-auto mt-auto">Cierre</span>
                            </div>
                            <!-- <div class="col d-flex text-center">
                                <span class="me-auto d-flex" style="font-size: 12px;" matTooltip="Servicios">
                                    <i class="bi bi-person-fill-gear me-1" style="font-size: 15px;"></i>
                                    <b>{{intTotalServiciosCIERRE | currency:'':'symbol':'1.2-2'}}</b>
                                </span>
                                <span class="ms-auto d-flex" style="font-size: 12px;" matTooltip="Materiales">
                                    <i class="bi bi-box-fill me-1" style="font-size: 15px;"></i>
                                    <b>{{intTotalMaterialesCIERRE |
                                        currency:'':'symbol':'1.2-2'}}</b>
                                </span>
                            </div> -->
                            <div class="col d-flex">
                                <span class="ms-auto text-sm px-3">{{ dblMontoCierre | currency:'':'symbol':'1.2-2'
                                    }}</span>
                            </div>
                            <div class="col-12 d-flex mt-2">
                                <div class="degradado6 w-100 m-auto"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2 table-responsive">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th scope="col">Agente</th>
                                    <th scope="col" class="colorColumna">Empresa</th>

                                    <th scope="col">Monto</th>
                                    <th scope="col">Actividad</th>
                                    <th scope="col">Sector</th>
                                    <th scope="col">Ultimo Cambio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of arrayCierre | pipeTablero: filterPost; let i = index">
                                    <th>{{i+1}}</th>
                                    <th scope="row"><span *ngIf="!item.strAgente">S/N</span>{{item.strAgente}}</th>
                                    <td class="colorColumna" style="cursor: pointer;">
                                        <strong>{{item.strNombreEmpresa}}</strong>
                                    </td> <!--(click)="fnDetalleLead(item.intIdLeads)" data-toggle="modal"
                                            data-target="#detalleLead"-->

                                    <td>{{item.dblMonto | currency:'':'symbol':'1.2-2'}}</td>
                                    <td
                                        [ngClass]="{'footerRojo': (item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0) || (item.intTiempoTranscurridoDias < 0 && item.intTiempoTranscurridoHoras < 0) }">
                                        <i class="fas fa-envelope mx-2 correo" *ngIf="item.strTipo == 'correo'"></i>
                                        <i class="fas fa-phone-alt m-auto mx-2" *ngIf="item.strTipo == 'llamada'"></i>
                                        <i class="fas fa-walking m-auto mx-2" *ngIf="item.strTipo == 'visita'"></i>
                                        <i class="far fa-star m-auto mx-1 text-warning"
                                            *ngIf="item.strTipo == 'GANADO'"></i>

                                        <span class="text-sinAct text-success"
                                            *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras == 0">S/A</span>
                                        <span class="text-footer text-danger"
                                            *ngIf="item.intTiempoTranscurridoDias == 0 && item.intTiempoTranscurridoHoras > 0"><i
                                                class="fas fa-exclamation-triangle text-sm text-danger mx-1"></i>Hoy</span>
                                        <span class="text-footer text-success mx-1"
                                            *ngIf="item.intTiempoTranscurridoDias > 0">{{item.intTiempoTranscurridoDias}}
                                            dias</span>
                                        <span class="text-footer text-danger mx-1"
                                            *ngIf="item.intTiempoTranscurridoHoras < 0 && item.intTiempoTranscurridoDias < 0"><i
                                                class="fas fa-bomb text-danger mx-1"></i>ATRASADO</span>
                                    </td>
                                    <td>{{item.strSector}}</td>
                                    <td>{{item.strUltimoCambio}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="row d-flex mt-3" *ngIf="banderaCalendario">
        <div class="col-12 m-auto d-flex">
            <full-calendar class="m-auto" id="calendar" #calendar [options]="calendarOptions"></full-calendar>
        </div>
    </div>
</div>

<!-- Modal -->
<div (click)="stopPropagation2($event)" class="modal fade" id="detalleLead" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header d-flex img-fondo">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <span class="fw-bold fs-4 text-success text-nowrap">{{strClasificacion}}</span>
                        </div>
                        <div class="col-7 d-flex text-center">
                            <h1 class="modal-title m-auto fw-bold" id="exampleModalLongTitle">{{strNombreEmpresa}}</h1>
                        </div>
                        <div class="col-3 d-flex justify-content-center align-items-center">
                            <span class="fs-5 mx-4">Folio: &nbsp; {{intIDLeads}}</span>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body color-body-modal hmodal">
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
                    <mat-tab label="Principal">
                        <ng-template mat-tab-label>
                            <i class="fas fa-star-of-life mx-1"></i>
                            <span>PRINCIPAL</span>
                        </ng-template>
                        <div class="container-fluid mt-2">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row d-flex mt-2" style="background: rgb(250, 250, 250);">
                                        <div class="col-12 col-md-6  m-auto p-2">
                                            <ol class="lista-elegante">
                                                <li class="d-flex">
                                                    <span class="me-auto"><i class="bi bi-buildings mx-1"></i>Nombre
                                                        Empresa:</span>
                                                    <span class="ms-auto text-dark">{{strNombreEmpresa}}</span>
                                                </li>
                                                <li class="d-flex">
                                                    <span class="me-auto"><i class="bi bi-person mx-1"></i>Nombre
                                                        Contacto</span>
                                                    <span
                                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strNombreContacto}}</span>
                                                </li>
                                                <li class="d-flex">
                                                    <span class="me-auto"><i
                                                            class="bi bi-person-check-fill mx-1"></i>Puesto</span>
                                                    <span
                                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoPuesto
                                                        }}</span>
                                                </li>
                                                <li class="d-flex">
                                                    <span class="me-auto"><i
                                                            class="bi bi-telephone-fill mx-1"></i>Telefono:</span>
                                                    <span
                                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoTelefono}}</span>
                                                </li>

                                            </ol>
                                        </div>
                                        <div class="col-md-6 col-12 p-2">
                                            <ol class="lista-elegante">
                                                <li class="d-flex">
                                                    <span class="me-auto"><i
                                                            class="bi bi-envelope mx-1"></i>E-mail:</span>
                                                    <span
                                                        class="ms-auto text-dark">{{arrayContactosDetalle[0].strContactoEmail}}</span>
                                                </li>
                                                <li class="d-flex align-items-center">
                                                    <span class="me-auto"><i
                                                            class="bi bi-person-fill-check mx-1"></i>Agente:</span>
                                                    <span class="ms-auto text-dark w-75">
                                                        <select [disabled]="!boolAgente" name="agente" id="agente"
                                                            class="form-select m-auto" [(ngModel)]="strAgenteLD">
                                                            <option value="undefined">-Selecciona un Agente-
                                                            </option>
                                                            <option *ngFor="let item of arrayAgentes; let i = index"
                                                                value="{{item.strAgente}}">{{item.strNombre}}</option>
                                                        </select>
                                                    </span>
                                                </li>
                                                <li class="d-flex align-items-center">
                                                    <span class="me-auto"><span
                                                            class="material-symbols-outlined fs-6 mx-1">
                                                            family_restroom
                                                        </span>Familia:</span>
                                                    <span class="ms-auto text-dark w-75">
                                                        <select name="agente" id="agente" class="form-select m-auto"
                                                            [disabled]="true" [(ngModel)]="intID_Familia">

                                                            <option *ngFor="let item of arrayFamilias; let i = index"
                                                                value="{{item.intID_Familia}}">{{item.strFamilia}}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </li>

                                            </ol>
                                        </div>
                                    </div>
                                    <div class="row mt-2 justify-content-between">


                                        <div class=" px-0 p-3" class="tablasResposivas">
                                            <!--  <div class="col-11 p-2 mx-auto shadow-sm" style="background: rgb(250, 250, 250);"> -->
                                            <div class="col-12 d-flex">

                                                <button [disabled]="intFlagActividad === 0"
                                                    class="text-white dropdown-toggle text-sm btn btn-success d-flex justify-content-center align-items-center "
                                                    data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                                                    aria-expanded="false"
                                                    [ngClass]="{'btn-selected-hoy': intTiempoDias == 0 && intTiempoHoras > 0, 'btn-selected-dias': intTiempoDias > 0 }">
                                                    <i class="fa-solid fa-plus"></i>
                                                    <!-- <span *ngIf="intTiempoDias == 0 && intTiempoHoras == 0">S/A</span>
                                                <i class="fas fa-exclamation-circle mx-1"
                                                    *ngIf="diferenciaDias <= 0 && diferenciaHoras <= 0 && diferenciaMinutos <= 0"></i>
                                                <span *ngIf="intTiempoDias > 0">{{intTiempoDias}} dias</span>
                                                <span
                                                    *ngIf="intTiempoDias == 0 && intTiempoHoras > 0">{{intTiempoHoras}}
                                                    hrs</span>
                                                <span *ngIf="intTiempoDias < 0 && intTiempoHoras < 0"
                                                    class="text-danger"><i
                                                        class="fas fa-exclamation-circle"></i>Atrasado</span> -->
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh"
                                                    id="dropInsert">
                                                    <div class="container-fluid">
                                                        <div class="row py-2">
                                                            <div class="col-12 col-md-3">
                                                                <!--  <div class="btn-group d-flex " id="group-buton"
                                                                    role="group" aria-label="Basic example">
                                                                    <label
                                                                        class="btn btn-outline-info btn-sm text-menu text-center m-auto d-flex"
                                                                        (click)="stopPropagation($event, 'Espera')"
                                                                        [ngClass]="{ 'btn-selected': opcionSeleccionada === 'espera' }">
                                                                        <input type="radio" name="estado" value="espera"
                                                                            [(ngModel)]="opcionSeleccionada">
                                                                        <i class="far fa-stop-circle m-auto"></i>Espera
                                                                    </label>
                                                                    <label
                                                                        class="btn btn-outline-info btn-sm text-menu text-center m-auto d-flex"
                                                                        (click)="stopPropagation($event, 'Ganado')"
                                                                        [ngClass]="{ 'btn-selected': opcionSeleccionada === 'ganado' }">
                                                                        <input type="radio" name="estado" value="ganado"
                                                                            [(ngModel)]="opcionSeleccionada">
                                                                        <i class="fas fa-trophy m-auto"></i>Ganado
                                                                    </label>
                                                                    <label
                                                                        class="btn btn-outline-info btn-sm text-menu m-auto d-flex"
                                                                        (click)="stopPropagation($event, 'perdido')"
                                                                        [ngClass]="{ 'btn-selected': opcionSeleccionada === 'perdido' }">
                                                                        <input type="radio" name="estado"
                                                                            value="perdido"
                                                                            [(ngModel)]="opcionSeleccionada">
                                                                        <i
                                                                            class="fas fa-hand-point-down m-auto"></i>Perdido
                                                                    </label>
                                                                    <label
                                                                        class="btn btn-outline-info btn-sm text-menu m-auto d-flex"
                                                                        (click)="stopPropagation($event, 'cancelado')"
                                                                        [ngClass]="{ 'btn-selected': opcionSeleccionada === 'cancelado' }">
                                                                        <input type="radio" name="estado"
                                                                            value="cancelado"
                                                                            [(ngModel)]="opcionSeleccionada">
                                                                        <i class="fas fa-window-close m-auto"></i>
                                                                        Cancelado
                                                                    </label>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="row mdalCard" *ngIf="banderaEspera">
                                                            <div class="col-8">
                                                                <span class="texto-Modal">Recordatorio</span>
                                                            </div>
                                                            <div class="col-4">
                                                                <span class="texto-Modal">Duración(min.)</span>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-8">
                                                                        <input type="datetime-local"
                                                                            class="form-control"
                                                                            [(ngModel)]="strFechaActividad">
                                                                    </div>
                                                                    <div class="col-4 d-block">
                                                                        <input type="number" class="form-control"
                                                                            [(ngModel)]="intDuracionActividad">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <span class="texto-Modal">Se planea hacer
                                                                    (opcional)</span>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-8">
                                                                        <textarea [(ngModel)]="strResultado"
                                                                            name="planea" id="planea" cols="5" rows="1"
                                                                            class="form-control"></textarea>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <select class="form-control"
                                                                            [(ngModel)]="strTipoActividad"
                                                                            (click)="stopPropagation2($event)">
                                                                            <option value="" disabled selected>
                                                                                Selecciona
                                                                                una opción</option>
                                                                            <option *ngFor="let opcion of opciones"
                                                                                [value]="opcion.valor">
                                                                                <span class="icon"><i
                                                                                        class="fas {{ opcion.icono }}"></i></span>
                                                                                {{ opcion.valor }}
                                                                            </option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-12">
                                                                <span class="texto-Modal">Lo que se hizo
                                                                    (opcional)</span>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <textarea [(ngModel)]="strResultado"
                                                                            name="planea" id="planea" cols="5" rows="1"
                                                                            class="form-control"></textarea>
                                                                    </div>

                                                                </div>
                                                            </div> -->
                                                            <div class="col-12 d-flex mt-2 p-2">
                                                                <button class="btn btn-info text-white ms-auto btn-sm"
                                                                    (click)="fnInsertActividades($event)">Guardar</button>
                                                            </div>
                                                        </div>
                                                        <div class="row mdalCard" *ngIf="banderaGanado">
                                                            <div class="col-12 d-block text-center">
                                                                <span class="texto-Modal">Fecha Inicio de
                                                                    Servicios:</span>
                                                                <br>
                                                                <input type="date" class="form-control"
                                                                    value="{{strFechaInicioServicio}}"
                                                                    (change)="cambiarFechaInicioServicio($event)">
                                                            </div>
                                                            <div class="col-12 d-flex align-items-center p-2">
                                                                <button class="btn btn-info text-white btn-sm ms-auto"
                                                                    [disabled]="!fechaInicioServicio"
                                                                    (click)="fnInsertFechaInicioServicio()"
                                                                    data-bs-dismiss="modal">Guardar</button>
                                                            </div>
                                                        </div>
                                                        <div class="row mdalCard" *ngIf="banderaPerdido">
                                                            <div class="col-12 d-block text-center">
                                                                <span class="texto-Modal">Motivo</span> <br>
                                                                <textarea name="motivo" id="motivo" cols="10" rows="2"
                                                                    class="form-control"
                                                                    [(ngModel)]="strMotivoPerdido"></textarea>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-8 text-center">
                                                                        <span class="texto-Modal text-bold">¿Podemos
                                                                            contactarnos en un futuro?</span>
                                                                    </div>
                                                                    <div class="col-4 d-flex">
                                                                        <div class="radio-group m-auto d-flex">
                                                                            <label
                                                                                class="radio-button m-auto text-center d-flex">
                                                                                <input type="radio" name="option"
                                                                                    value="SI" class="m-auto"
                                                                                    (change)="fnCambiarFuturo($event)">
                                                                                <span class="mx-1">SI</span>
                                                                            </label>
                                                                            <label
                                                                                class="radio-button m-auto text-center d-flex mx-1">
                                                                                <input type="radio" name="option"
                                                                                    value="NO" class="m-auto"
                                                                                    (change)="fnCambiarFuturo($event)">
                                                                                <span class="mx-1">NO</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 p-2" *ngIf="banderaFuturo">
                                                                <div class="row">
                                                                    <div class="col-12 d-block text-center">
                                                                        <span class="texto-Modal">Fecha Estimada:</span>
                                                                        <input type="date" class="form-control"
                                                                            [(ngModel)]="strFechaEstimadaCierreInput"
                                                                            (change)="cambiarFechaPerdido($event)">
                                                                    </div>
                                                                    <div class="col-12 p-2">
                                                                        <textarea name="observaciones"
                                                                            id="observaciones" cols="10" rows="3"
                                                                            class="form-control"
                                                                            placeholder="Escribe observaciones"
                                                                            [(ngModel)]="strComentarioPerdido"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 d-flex align-items-center p-2">
                                                                <button class="btn btn-info text-white btn-sm ms-auto"
                                                                    (click)="fnCancelado_Perdido()"
                                                                    data-bs-dismiss="modal">Guardar</button>
                                                            </div>
                                                        </div>
                                                        <div class="row mdalCard" *ngIf="banderaCancelado">
                                                            <div class="col-12 d-block text-center">
                                                                <span class="texto-Modal">Motivo</span> <br>
                                                                <textarea name="motivo" id="motivo" cols="10" rows="2"
                                                                    class="form-control"
                                                                    [(ngModel)]="strMotivoCancelado"></textarea>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="row">
                                                                    <div class="col-8 text-center">
                                                                        <span class="texto-Modal text-bold">¿Podemos
                                                                            contactarnos en un futuro?</span>
                                                                    </div>
                                                                    <div class="col-4 d-flex">
                                                                        <div class="radio-group m-auto d-flex">
                                                                            <label
                                                                                class="radio-button m-auto text-center d-flex">
                                                                                <input type="radio" name="option"
                                                                                    value="SI" class="m-auto"
                                                                                    (change)="fnCambiarFuturo($event)">
                                                                                <span class="mx-1">SI</span>
                                                                            </label>
                                                                            <label
                                                                                class="radio-button m-auto text-center d-flex mx-1">
                                                                                <input type="radio" name="option"
                                                                                    value="NO" class="m-auto"
                                                                                    (change)="fnCambiarFuturo($event)">
                                                                                <span class="mx-1">NO</span>
                                                                            </label>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 p-2" *ngIf="banderaFuturo">
                                                                <div class="row">
                                                                    <div class="col-12 d-block text-center">
                                                                        <span class="texto-Modal">Fecha Estimada:</span>
                                                                        <input type="date" class="form-control"
                                                                            [(ngModel)]="strFechaEstimadaCierreInput"
                                                                            value="{{strFechaEstimadaCierreInput}}"
                                                                            (change)="cambiarFechaCancelado($event)">
                                                                    </div>
                                                                    <div class="col-12 p-2">
                                                                        <textarea name="observaciones"
                                                                            id="observaciones" cols="10" rows="3"
                                                                            class="form-control"
                                                                            placeholder="Escribe observaciones"
                                                                            [(ngModel)]="strComentarioCancelado"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 d-flex align-items-center p-2">
                                                                <button class="btn btn-info text-white btn-sm ms-auto"
                                                                    (click)="fnCancelado_Perdido()"
                                                                    data-bs-dismiss="modal">Guardar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 class="fs-5 fw-bold mx-1">
                                                    Nueva Actividad
                                                </h5>
                                            </div>
                                            <div class="col-12 table-responsive mb-3"
                                                style="max-height: 300px; overflow: auto;">
                                                <table class="table text-center table-bordered"
                                                    style="background: white;">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Agente</th>
                                                            <th scope="col">Tipo</th>
                                                            <th scope="col">Fecha</th>
                                                            <th scope="col">Comentario</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="arrayActividades.length == 0">
                                                            <td colspan="6">
                                                                <span class="fw-bold">SIN DATOS</span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngFor="let item of arrayActividades; let i = index">
                                                            <th scope="row">{{ i + 1 }}</th>
                                                            <td>{{item.strAgente}}</td>
                                                            <td>
                                                                <i class="fas fa-envelope mx-2 correo"
                                                                    *ngIf="item.strTipo == 'correo'"></i>
                                                                <i class="fas fa-phone-alt m-auto mx-2"
                                                                    *ngIf="item.strTipo == 'llamada'"></i>
                                                                <i class="fas fa-walking m-auto mx-2"
                                                                    *ngIf="item.strTipo == 'visita'"></i>
                                                                <i class="far fa-star m-auto mx-1 text-warning"
                                                                    *ngIf="item.strTipo == 'GANADO'"></i>
                                                            </td>
                                                            <td>{{item.strFechaCierre_Estimada}}</td>
                                                            <td>{{item.strAsunto}}</td>
                                                            <td>
                                                                <button [disabled]="item.strEstatus == 'CONCLUIDO'"
                                                                    [ngClass]="{'btn-warning': item.strEstatus == 'PENDIENTE', 'btn-info':item.strEstatus =='CONCLUIDO'}"
                                                                    class="text-white dropdown-toggle text-sm btn btn-sm  justify-content-center align-items-center  "
                                                                    data-bs-toggle="dropdown" role="button"
                                                                    aria-haspopup="true" aria-expanded="false"
                                                                    (click)="stopPropagation4($event)">
                                                                    <i class="bi bi-patch-question fs-6"
                                                                        *ngIf="item.strEstatus == 'CONCLUIDO'"></i>

                                                                    <i class="bi bi-exclamation-diamond fs-6"
                                                                        *ngIf="item.strEstatus == 'PENDIENTE'"></i>
                                                                </button>
                                                                <div #dropdownMenu (click)="stopPropagation2($event)"
                                                                    id="miDropdownMenu2{{i}}"
                                                                    class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh">
                                                                    <div class="container-fluid">
                                                                        <div class="row mt-2">

                                                                            <div class="col-12 mt-2">
                                                                                <textarea name="nuevaAccion"
                                                                                    id="acciones" cols="10" rows="3"
                                                                                    [maxlength]="maxLengthComentario"
                                                                                    class="form-control shadow"
                                                                                    placeholder="Escribe comentario actividad"
                                                                                    [(ngModel)]="strComentario"></textarea>
                                                                            </div>
                                                                            <div class="col-12 mt-1">
                                                                                <div class="row">
                                                                                    <div class="col-8 d-flex">
                                                                                        <select name="accioness"
                                                                                            id="acciones"
                                                                                            class="form-select m-auto"
                                                                                            [(ngModel)]="strResultado">
                                                                                            <option value="">
                                                                                                --Selecciona
                                                                                                una opción--</option>
                                                                                            <option value="1">
                                                                                                EXITOSO</option>
                                                                                            <option value="2">
                                                                                                SIN EXITO
                                                                                            </option>
                                                                                            <!-- <option value="correo Enviado">
                                                                                        Mail Enviado</option>
                                                                                    <option value="visita">Visita
                                                                                        realizada</option> -->
                                                                                        </select>
                                                                                    </div>
                                                                                    <div class="col-4 d-flex">
                                                                                        <button
                                                                                            class="btn btn-primary  text-white ms-auto mt-auto d-flex"
                                                                                            (click)="fnupdate_Actividad(item)"><span
                                                                                                class="material-symbols-outlined mx-1">
                                                                                                save
                                                                                            </span><span>Guardar</span></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                        <div class="tablasResposivas px-0 p-3">
                                            <!-- <div class="col-11 p-2 mx-auto " style="background: rgb(250, 250, 250);"> -->
                                            <div class="col-12  d-flex ">
                                                <span (click)="fnCotizador()" data-bs-dismiss="modal"
                                                    *ngIf="strEtapa != 'NEGOCIACION' && arrayCotizacionesAvanzar.length == 0"
                                                    class="text-white  text-sm btn btn-success d-flex justify-content-center align-items-center mx-3">
                                                    <i class="fa-solid fa-plus"></i>
                                                </span>
                                                <span (click)="fnCotizador_Negociacion()" data-bs-dismiss="modal"
                                                    *ngIf="strEtapa == 'NEGOCIACION'"
                                                    class="text-white  text-sm btn btn-success d-flex justify-content-center align-items-center mx-3">
                                                    <i class="fa-solid fa-plus"></i>
                                                </span>
                                                <h5 class="fs-5 fw-bold">
                                                    Nueva Cotización
                                                </h5>
                                            </div>

                                            <div class="col-12 d-flex table-responsive mb-3"
                                                style="max-height: 300px; overflow: auto;">
                                                <table class="table ms-auto  table-bordered text-center"
                                                    style="background: white;">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">FECHA</th>
                                                            <th scope="col">MONTO</th>
                                                            <th scope="col">ACCIONES</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="arrayCotizaciones.length == 0">
                                                            <td colspan="6">
                                                                <span class="fw-bold">SIN DATOS</span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngFor="let item of arrayCotizaciones; let i = index">
                                                            <th scope="row">{{ i + 1 }}</th>
                                                            <td>{{item.intIDMovimientoVenta}}</td>
                                                            <td>{{item.strFecha}}</td>
                                                            <td>{{item.dblImporte | currency}}</td>
                                                            <td class="d-flex">
                                                                <!--[ngClass]="{'btn-warning': item.strEstatus == 'PENDIENTE', 'btn-info':item.strEstatus =='CONCLUIDO'}"-->
                                                                <button
                                                                    [disabled]="item.strEstatusCliente == 'ACEPTADO' || item.strEstatusCliente == 'RECHAZADO'"
                                                                    [ngClass]="{'btn-success': item.strEstatusCliente == 'ACEPTADO', 'btn-danger': item.strEstatusCliente == 'RECHAZADO', 'btn-primary': (!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')}"
                                                                    class="text-white dropdown-toggle text-sm btn btn-sm  m-auto "
                                                                    data-bs-toggle="dropdown" role="button"
                                                                    aria-haspopup="true" aria-expanded="false">
                                                                    <i class="bi bi-x-circle"
                                                                        *ngIf="item.strEstatusCliente == 'RECHAZADO'"></i>
                                                                    <i class="bi bi-check-circle"
                                                                        *ngIf="item.strEstatusCliente == 'ACEPTADO'"></i>
                                                                    <span
                                                                        *ngIf="(!item.strEstatusCliente || item.strEstatusCliente == 'POR AUTORIZAR')"
                                                                        class="material-symbols-outlined fs-6">
                                                                        action_key
                                                                    </span>

                                                                </button>
                                                                <!--(click)="stopPropagation4(i, 'miDropdownMenu')"-->
                                                                <div #dropdownMenu (click)="stopPropagation2($event)"
                                                                    id="miDropdownMenu{{i}}"
                                                                    class="dropdown-menu dropdown-menu-xl menu-drop color-body-modal wvh">
                                                                    <div class="container-fluid">
                                                                        <div class="row mt-2">
                                                                            <div class="col-12 mt-1">
                                                                                <div class="row">
                                                                                    <div
                                                                                        class="col-8 d-flex justify-content-between">

                                                                                        <select name="accioness"
                                                                                            [(ngModel)]="strEstatusCotizacion"
                                                                                            id="acciones"
                                                                                            class="form-select"
                                                                                            [(ngModel)]="strResultado">
                                                                                            <option value="">
                                                                                                --Selecciona
                                                                                                una opción--</option>
                                                                                            <option value="ACEPTADO">
                                                                                                ACEPTADO</option>
                                                                                            <option value="RECHAZADO">
                                                                                                RECHAZADO
                                                                                            </option>
                                                                                            <!-- <option value="correo Enviado">
                                                                                        Mail Enviado</option>
                                                                                    <option value="visita">Visita
                                                                                        realizada</option> -->
                                                                                        </select>
                                                                                    </div>
                                                                                    <div class="col-3 d-flex">
                                                                                        <button
                                                                                            class="btn btn-primary btn-sm  text-white ms-auto mt-auto fs-6 d-flex justify-content-center alight-items-center "
                                                                                            (click)="fnsetEstatus_Cotizacion(item.intIDMovimientoVenta, $event)">
                                                                                            <span
                                                                                                class="material-symbols-outlined mt-1 fs-6">
                                                                                                save </span>Guardar
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <!-- <td><button class="btn btn-danger btn-sm"><i
                                                                        class="fa-solid fa-trash"></i></button></td> -->
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 d-flex" *ngIf="strEtapa == 'Recorrido'">
                                            <h5><i class="fas fa-user-alt mx-4"></i> Coordinador Operativo</h5>
                                            <div *ngIf="intIDPeritaje" (click)="fnEnviarEdificios()" class="ms-auto"
                                                style="padding: 5px 15px 5px 15px; background: rgb(218, 247, 230); border-radius: 10px;cursor: pointer;">
                                                <i class="fas fa-building mx-1  text-md"></i> <span
                                                    style="font-size: 11px; font-weight: 500;">Levantamiento
                                                    Iniciado</span>
                                            </div>
                                        </div>
                                        <div class="col-12" *ngIf="strEtapa == 'Recorrido'">
                                            <div class="row mt-4">
                                                <div class="col-3 d-flex">
                                                    <div class="circlePropietario">
                                                        <span class="textPropietario"
                                                            *ngIf="!strCoordinadorOperativo">S/A</span>
                                                        <span class="textPropietario"
                                                            *ngIf="strCoordinadorOperativo">{{getInitials(strCoordinadorOperativo)}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-9 d-flex">
                                                    <input readonly class="m-auto text-md text form-control"
                                                        [(ngModel)]="strCoordinadorOperativo"
                                                        *ngIf="strCoordinadorOperativo">
                                                    <input readonly class="m-auto text-md text form-control"
                                                        value="SIN ASIGNAR" *ngIf="!strCoordinadorOperativo">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row mt-2 d-flex">


                                                <!-- <div class="col-12 d-flex">
                                                    <h3 (click)="fnObtenerHistorial()" class="m-auto"><i
                                                            class="fas fa-file-excel m-auto text-success fs-4"></i>
                                                        <span class="mx-2 fs-6 fw-bold"
                                                            style="font-size: 15px;">Historial</span>
                                                    </h3>
                                                </div> -->
                                            </div>
                                            <div class="row">

                                                <!-- <div class="col-12 d-flex">
                                                    <h3 (click)="fnObtenerHistorial()" class="m-auto"><i
                                                            class="fas fa-file-excel m-auto text-success fs-4"></i>
                                                        <span class="mx-2 fs-6 fw-bold"
                                                            style="font-size: 15px;">Historial</span>
                                                    </h3>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fas fa-book-reader mx-1"></i> <span>LEAD</span>
                        </ng-template>
                        <div class="container-fluid">
                            <form [formGroup]="formLead">
                                <div class="row mt-3">
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="text-sm">Nombre: <span class="text-danger">*</span></span>
                                        <input type="text" placeholder="Ingresa Nombre empresa" class="form-control"
                                            [(ngModel)]="strNombreEmpresa" formControlName="strNombreEmpresa">
                                        <mat-error
                                            *ngIf="formLead.get('strNombreEmpresa').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>

                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="text-sm m-auto">Sector : <span class="text-danger">*</span></span>
                                        <select name="sector" id="sector" class="form-select" [(ngModel)]="strSector"
                                            formControlName="strSector">
                                            <option value="" selected>-Selecciona una opcion-</option>
                                            <option value="Publico">Publico</option>
                                            <option value="Privado">Privado</option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('strSector').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="text-sm m-auto">Giro: <span class="text-danger">*</span></span>
                                        <select name="giro" id="giro" class="form-select" [(ngModel)]="intGiro"
                                            formControlName="intGiro" (change)="onGiroChange($event, 'giro')">
                                            <option value="">-Selecciona una opcion-</option>
                                            <option *ngFor="let item of arrayGiros; let i = index"
                                                [value]="item.intId_Giro + ',' + item.strClasificacion">
                                                {{item.strGiro}}</option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('intGiro').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="text-sm m-auto">Presencia en el pais: <span
                                                class="text-danger">*</span></span>
                                        <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                            formControlName="intIDPresencia"
                                            (change)="onGiroChange($event, 'presencia')" [(ngModel)]="intIDPresencia">
                                            <option value="" selected>-Selecciona una opcion-</option>
                                            <option [value]="item.intIDPresencia + ',' + item.strClasificacion"
                                                *ngFor="let item of arrayPresencia; let i = index">
                                                {{item.strPresencia}}
                                            </option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('intIDPresencia').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="text-sm m-auto">Ubicaciones: <span
                                                class="text-danger">*</span></span>
                                        <select name="probabilidad" id="probabilidad" class="form-select"
                                            (change)="onGiroChange($event, 'ubicaciones')" [(ngModel)]="intIDUbicacion"
                                            formControlName="intIDUbicacion">
                                            <option value="" selected>-Selecciona una opcion-</option>
                                            <option [value]="item.intid_Ubicacion + ',' + item.strClasificacion"
                                                *ngFor="let item of arrayUbicaciones; let i = index">
                                                {{item.strUbicaciones}}
                                            </option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('intIDUbicacion').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>


                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class=" m-auto text-sm">Familia</span>
                                        <div class="d-flex align-items-center">
                                            <select [attr.disabled]="intValidacionFamilia === 2 ? true : null"
                                                name="familia" id="familia" class="form-select"
                                                [(ngModel)]="intID_Familia" formControlName="intID_Familia">
                                                <option value="0">--Selecciona una opcion--</option>
                                                <option *ngFor="let item of arrayFamilias; let i = index"
                                                    value="{{item.intID_Familia}}">{{item.strFamilia}}</option>
                                            </select>
                                            <!-- <span _ngcontent-ykc-c289="" class="material-symbols-outlined text-muted ng-tns-c289-22 ng-star-inserted text-warning" >bolt </span> -->
                                            <!-- <button type="button" class="btn" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Pendiente"
                                                *ngIf="intValidacionFamilia == 1">
                                                <i class="bi bi-exclamation-octagon-fill fs-5 text-warning mx-1"></i>
                                            </button> -->
                                            <button type="button" class="btn" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Aceptado"
                                                *ngIf="intValidacionFamilia == 2">
                                                <i class="bi bi-check-circle-fill fs-5 text-success mx-1"></i>
                                            </button>
                                            <!-- <button type="button" class="btn" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Cancelado"
                                                *ngIf="intValidacionFamilia == 3">
                                                <i class="bi bi-x-circle-fill fs-5 text-danger mx-1"></i>
                                            </button> -->
                                        </div>

                                        <mat-error
                                            *ngIf="formLead.get('intID_Familia').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block">
                                        <span class="text-sm m-auto">Rango Empleados:</span>
                                        <select name="probabilidad" id="probabilidad" class="form-select"
                                            formControlName="strNumeroEmpleados"
                                            [(ngModel)]="strNumeroEmpleados"><!-- (change)="cambiarPrioridad($event)" -->
                                            <option value="">-Selecciona una opcion-</option>
                                            <option value="{{item.intIDNo_Empleados}}"
                                                *ngFor="let item of arrayTamanoEmpresa; let i = index">
                                                {{item.strNo_Empleados}}</option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('strNumeroEmpleados').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <!-- <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                    <span class="text-sm">Importe</span>
                                    <input type="text" placeholder="Ingresa Importe" class="form-control"
                                        [(ngModel)]="dblImporte">
                                </div> -->
                                    <!-- <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                    <span class="text-sm m-auto"># Elementos:</span>
                                    <input type="number" min="0" value="0" class="form-control"
                                        [(ngModel)]="intCantidadElementos">
                                </div> -->
                                    <!-- <div class="col-md-6 col-lg-4 col-12 p-2">
                                    <span class="text-sm">Prioridad</span>
                                    <select name="probabilidad" id="probabilidad" class="form-select"
                                        [(ngModel)]="strPrioridad" disabled="true">
                                        <option value="-Selecciona una opcion-">-Selecciona una opcion-</option>
                                        <option value="Alta">Alta</option>
                                        <option value="Media">Media</option>
                                        <option value="Baja">Baja</option>
                                        <option value="URGENTE">URGENTE</option>
                                    </select>
                                </div> -->
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="text-sm">Fecha Estimada Cierre</span>
                                        <input type="date" class="form-control" min="{{strFechaEstimadaCierreInput}}"
                                            value="{{strFechaEstimadaCierreInput}}" (change)="cambiarFecha($event)">

                                    </div>

                                    <!-- <div class="col-md-6 col-lg-4 col-12 d-block p-2">
                                    <span class="m-auto text-sm">Total Sucursales:</span>
                                    <input type="text" placeholder="Total Sucursales" class="form-control ms-auto"
                                        [(ngModel)]="intTotalSucursales">
                                </div> -->
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="m-auto">Razon Social:</span> <br>
                                        <input type="text" placeholder="Razon Social" class="form-control  m-auto"
                                            [(ngModel)]="strRazonSocial" formControlName="strRazonSocial">
                                        <mat-error
                                            *ngIf="formLead.get('strRazonSocial').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <!-- <div class="col-md-6 col-lg-4 col-12 d-block p-1">
                                    <span class="m-auto">Sitio Web:</span>
                                    <input type="text" placeholder="Sitio Web" class="form-control ms-auto"
                                        [(ngModel)]="strSitioWeb">
                                </div> -->
                                    <div class="col-md-6 col-lg-4 col-12 d-block">
                                        <span class="m-auto">RFC:</span>
                                        <input type="text" placeholder="RFC" class="form-control  ms-auto"
                                            [(ngModel)]="strRFC" formControlName="strRFC" [maxlength]="13">
                                        <mat-error
                                            *ngIf="formLead.get('strRFC').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class="m-auto">Tipo Cotización:</span>
                                        <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            Seleccione:
                                        </button>
                                        <ul class="dropdown-menu heitdrop w-25">
                                            <li *ngFor="let j of arrayTipoCotizacion">
                                                <div class="form-check ms-1">
                                                    <input class="form-check-input" [checked]="j.Seleccionado"
                                                        type="checkbox" [value]="j.Seleccionado"
                                                        (change)="toggleCotizacion(j.strTipoCotizacion)"
                                                        id="flexCheckDefault">
                                                    <label class="form-check-label mb-3" for="flexCheckDefault">
                                                        {{j.strTipoCotizacion}}
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        <ng-container *ngFor="let j of arrayTipoCotizacion; let i = index"
                                            class="d-flex ">
                                            <span *ngIf="j.Seleccionado"
                                                class="rounded colorSeleccion text-white p-1 mb-2 mx-1 me-1 ms-1 mt-1">{{j.strTipoCotizacion}}</span>
                                        </ng-container>
                                        <mat-error
                                            *ngIf="formLead.get('strTipoCotizacion').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class=" m-auto text-sm">¿Por cual medio nos conocio?</span>
                                        <input type="text" placeholder="VENDEDOR" class="form-control  ms-auto" readonly
                                            [(ngModel)]="strMedioConocio" formControlName="strMedioConocio">
                                        <mat-error
                                            *ngIf="formLead.get('strMedioConocio').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-12 d-block ">
                                        <span class=" m-auto text-sm">¿Cuentan con una empresa de servicio de limpieza
                                            actualmente?</span>
                                        <select name="servicio" id="servicio" class="form-select"
                                            [(ngModel)]="strTieneServicio" formControlName="strTieneServicio">
                                            <option value="">-Selecciona una opción-</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                        </select>
                                        <mat-error
                                            *ngIf="formLead.get('strTieneServicio').hasError('required') && strEtapa == 'PRESENTACION'"
                                            class="fs10">
                                            <i class="fas fa-exclamation-circle text-danger"></i> Campo Obligatorio
                                        </mat-error>
                                        <!-- <input type="text" placeholder="Recomendado por" class="form-control"
                                        [(ngModel)]="strRecomendacion"> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fas fa-id-card-alt mx-1"></i> <span>DATOS CONTACTO</span>
                        </ng-template>
                        <div class="container-fluid">
                            <!-- <div class="col-12 d-flex mt-2">
                                <button class="btn btn-info ms-auto" (click)="fnAgregarContacto()"><i
                                        class="fas fa-plus-circle mx-1"></i>Agregar</button>
                            </div> -->
                            <div class="row mt-4 card-contacto p-3"
                                *ngFor="let item of arrayContactosDetalle; let i = index">
                                <div class="col-12 d-flex " *ngIf="arrayContactosDetalle.length > 1">
                                    <button class="btn btn-danger ms-auto btn-sm" (click)="fnEliminarContacto(i)"><i
                                            class="fas fa-trash-alt"></i></button>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">Nombre:</span>
                                    <input type="text" placeholder="Nombre Contacto" class="form-control ms-auto"
                                        [(ngModel)]="item.strNombreContacto" (input)="validarCampos(item)">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">Puesto:</span> <br>
                                    <input type="text" placeholder="Puesto Contacto"
                                        [(ngModel)]="item.strContactoPuesto" class="form-control  m-auto"
                                        (input)="validarCampos(item)">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">Telefono:</span>
                                    <input type="text" placeholder="Telefono Contacto" class="form-control ms-auto"
                                        [(ngModel)]="item.strContactoTelefono" (input)="validarCampos(item)"
                                        [maxlength]="maxTelefono">
                                    <div *ngIf="strTelefonoContacto && strTelefonoContacto.length >= 3 && !validarTelefono(strTelefonoContacto)"
                                        class="error-message text-sm text-danger">
                                        El número de teléfono no es válido.
                                    </div>

                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto text-sm">E-mail :</span> <br>
                                    <input type="text" placeholder="Email Contacto" class="form-control  m-auto"
                                        [(ngModel)]="item.strContactoEmail"
                                        [ngClass]="{'campo-obligatorio': item.emailEsObligatorio}"
                                        (input)="validarEmailContacto(item)">
                                    <div *ngIf="item.emailEsObligatorio" class="error-message text-sm text-danger">
                                        El correo electrónico es obligatorio si se ha ingresado nombre, puesto o
                                        teléfono.
                                    </div>

                                </div>

                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="DATOS FISCALES">
                        <ng-template mat-tab-label>
                            <i class="fas fa-route mx-1"></i> <span>DATOS FISCALES</span>
                        </ng-template>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Codigo Postal:</span> <br>
                                    <input type="text" placeholder="codigo postal" class="form-control m-auto"
                                        [(ngModel)]="strCodigoPostal" (keyup.enter)="fnGetDatosFiscales()">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Calle <span class="text-danger">*</span></span>
                                    <br>
                                    <input type="text" placeholder="Calle" class="form-control  m-auto"
                                        [(ngModel)]="strCalle">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Estado <span class="text-danger">*</span></span>
                                    <br>
                                    <input readonly type="text" placeholder="Estado" class="form-control  m-auto"
                                        [(ngModel)]="strEstado">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">No. Exterior <span
                                            class="text-danger">*</span></span> <br>
                                    <input type="text" placeholder="No. Exterior " class="form-control  m-auto"
                                        [(ngModel)]="strNumeroExterior">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Delegación <span
                                            class="text-danger">*</span></span> <br>
                                    <input readonly [(ngModel)]="strDelegacion" type="text" placeholder="Delegación"
                                        class="form-control  m-auto">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-1">
                                    <span class="datosEmpresa m-auto">Entre Calles <span
                                            class="text-danger">*</span></span> <br>
                                    <input [(ngModel)]="strEntreCalles" type="text" placeholder="Entre Calles"
                                        class="form-control  m-auto">
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-2">
                                    <span class="datosEmpresa m-auto">Colonia:</span>
                                    <select name="probabilidad" id="probabilidad" class="form-select"
                                        [(ngModel)]="strColonia">
                                        <option *ngFor="let item of arrayColonias; let i = index"
                                            value="{{item.strColonia}}">{{item.strColonia}}
                                        </option>
                                        <option value="otraColonia">Otra</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-12 ">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="datosEmpresa m-auto">Telefono <span
                                                    class="text-danger">*</span></span>
                                        </div>
                                        <div class="col-3 p-1">
                                            <input type="text" placeholder="Lada" class="form-control"
                                                [(ngModel)]="strLadaEmpresa">
                                        </div>
                                        <div class="col-9 p-1">
                                            <input type="text" placeholder="Telefono" class="form-control"
                                                [(ngModel)]="strTelefonoEmpresa">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 d-block p-2">
                                    <span class="datosEmpresa m-auto">Zona:</span>
                                    <select name="zona" id="zona" class="form-select" [(ngModel)]="strZona">
                                        <option *ngFor="let item of arrayZonas; let i = index" value="{{item.strZona}}">
                                            {{item.strZona}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-12" *ngIf="banderaColonia">
                                    <span class="datosEmpresa m-auto">Escriba Colonia <span
                                            class="text-danger">*</span></span> <br>
                                    <input type="text" placeholder="Otra colonia" class="form-control  m-auto"
                                        [(ngModel)]="strOtraColonia">
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fa-solid fa-heart mx-2"></i> <span>ART. FAVORITOS</span>
                        </ng-template>
                        <div class="container-fluid">
                            <div class="row mt-3">
                                <!-- <div class="col-12 mt-2 d-flex justify-content-end alight-items-center">
                                    <button class="btn btn-success"><i
                                            class="bi bi-file-earmark-excel mx-1"></i>Plantilla Articulos</button>
                                </div> -->
                                <div class="col-12 d-flex">
                                    <span class="text-danger ms-auto fw-bold" style="font-size: 12px;">En este listado
                                        se muestran solo un top 5 de los articulos*</span>
                                </div>
                                <div class="col-12 mt-3">
                                    <table mat-table [dataSource]="dataSource" matSort class="table">
                                        <ng-container matColumnDef="#">
                                            <th mat-header-cell *matHeaderCellDef class="px-3">
                                                <span class="mx-auto">#</span>
                                            </th>
                                            <td mat-cell *matCellDef="let i = index" class="gastoCell px-3 py-4"><b
                                                    class="resalta">{{ i + 1 }}</b></td>
                                        </ng-container>

                                        <ng-container matColumnDef="Imagen">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Imagen</th>
                                            <td mat-cell *matCellDef="let element" class="gastoCell ">
                                                <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArticulo + '.jpg'"
                                                    alt="" class="p-3" width="100">
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="strArticulo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                                Articulo</th>
                                            <td mat-cell *matCellDef="let element" class="gastoCell fw-bold py-4">
                                                {{element.strArticulo}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="strDescripcion">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                                Descripción</th>
                                            <td mat-cell *matCellDef="let element" class="gastoCell text-start py-4">
                                                {{element.strDescripcion}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="intCotizaciones">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                                Cotizaciónes </th>
                                            <td mat-cell *matCellDef="let element" class="gastoCell py-4">
                                                <span class="cotizacionesC">{{element.intCotizaciones}}</span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="dblCantidad">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-3"> Cantidad
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="gastoCell px-3 py-4">
                                                <span class="cantidadC">{{element.dblCantidad}}</span>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fas fa-sort-numeric-up-alt mx-2"></i> <span>SUCURSALES</span>
                        </ng-template>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6 col-12 mt-4  ">
                                    <button class="btn btn-primary my-2 form-control hob-text w-50"
                                        *ngIf="excelData.length > 0" (click)="fileInput.click()">
                                        <i class="fas fa-cloud-upload-alt mx-1"></i>
                                        <span class="fw-bolder">Cargar Archivo..</span>
                                    </button>
                                    <input (change)="handleFileInput($event)" type="file" class="d-none"
                                        accept=".xlsx, .xls, .xml" #fileInput />

                                </div>
                                <div class="col-md-6 col-12 mt-4 d-flex ">
                                    <div class="ms-auto">
                                        <button class="btn btn-success ms-auto" (click)="fnDescargarFormato()">
                                            <i class="fas fa-cloud-download-alt mx-1"></i> Layout Sucursales</button>
                                    </div>

                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-5" *ngIf="excelData.length == 0">
                                <div id="drop-area" (drop)="handleDrop($event)" (dragover)="handleDragOver($event)"
                                    (dragleave)="handleDragLeave($event)" class="col-7 bordeslin text-center"
                                    style="background: rgb(240, 240, 240);">
                                    <span class="material-symbols-outlined fCloud text-success"
                                        [ngClass]="{ 'd-none': excelData?.length < 1 }">
                                        done_outline
                                    </span>
                                    <span class="material-symbols-outlined fCloud text-secondary"
                                        *ngIf="nameArchivo == ''">
                                        cloud_upload
                                    </span>
                                    <div class="col-12 d-flex justify-content-center">
                                        <div class="col-6 p-2">
                                            <label for="" *ngIf="nameArchivo != ''">{{nameArchivo}}</label>
                                            <button class="btn btn-outline-primary my-2 form-control hob-text"
                                                (click)="fileInput.click()">
                                                <span class="fw-bolder">Cargar Archivo..</span>
                                            </button>
                                            <input (change)="handleFileInput($event)" type="file" class="d-none"
                                                accept=".xlsx, .xls, .xml" #fileInput />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4" *ngIf="excelData.length > 0">
                                <div class="col-12 table-responsive">
                                    <table class="table table-bordered" style="background: white; font-size: 15px;">
                                        <thead style="background: #071C34; color: white">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Nombre_Sucursal</th>
                                                <th scope="col">Dirección</th>
                                                <th scope="col">Dirección_Numero</th>
                                                <th scope="col">Codigo_Postal</th>
                                                <th scope="col">Colonia</th>
                                                <th scope="col">Rango_Empleados</th>
                                                <th scope="col">Contacto_Nombre</th>
                                                <th scope="col">Contacto_Puesto</th>
                                                <th scope="col">Contacto_Email</th>
                                                <th scope="col">Contacto_Telefono</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of excelData; let i = index">
                                                <th scope="row">{{item.No}}</th>
                                                <td>{{item.Nombre_Sucursal}}</td>
                                                <td>{{item.Direccion}}</td>
                                                <td>{{item.Direccion_Numero}}</td>
                                                <td>{{item.Codigo_Postal}}</td>
                                                <td>{{item.Colonia}}</td>
                                                <td>{{item.Rango_Empleados}}</td>
                                                <td>{{item.Contacto_Nombre}}</td>
                                                <td>{{item.Contacto_Puesto}}</td>
                                                <td>{{item.Contacto_Email}}</td>
                                                <td>{{item.Contacto_Telefono}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fas fa-paperclip mx-1"></i> <span>ANEXOS</span>
                        </ng-template>
                        <div class="container-fluid">
                            <div class="row mt-3">

                                <div class="col-10 d-flex justify-content-end mt-3">
                                    <div class="input-group mb-3 w-50 ms-auto">
                                        <label class="input-group-text" for="inputGroupSelect01">Tipo Documento</label>
                                        <select name="tipo" id="tipo" class="form-select" [(ngModel)]="strTipoAnexo">
                                            <option value="Contrato">Contrato</option>
                                            <option value="Materiales">Materiales</option>
                                            <option value="Cotización">Cotización</option>
                                            <option value="evidencia">Evidencia foto</option>
                                            <option value="Servicios especiales">Servicios especiales</option>
                                            <option value="Cotizacion Rapida">Cotizacion Rapida</option>
                                            <option value="Cotizacion por TYM">Cotizacion por TYM</option>
                                            <option value="Licitaciones">Licitaciones</option>
                                            <option value="InfoCotizacion">Información para cotizar</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2 d-flex justify-content-center align-items-center">
                                    <label class="btn btn-secondary btn-sm btn-file text-sm">
                                        <i class="fas fa-plus"></i> Agregar Anexo
                                        <input (change)="agregarAnexo($event)" #file type="file" style="display: none;"
                                            required accept=".xlsx, .pdf, .png, .jpg, .jpeg">
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4 col-12 d-block mb-3 text-center"
                                    *ngFor="let item of arrayAnexosUpdate; let i = index">
                                    <div *ngIf="item.strTipo == 'xlsx'" class="d-flex justify-content-center">
                                        <div class="card w-75">
                                            <div class="card-body">
                                                <a>
                                                    <svg class="svg_excel" xmsns="http://www.w3.org/2000/svg"
                                                        height="1em"
                                                        viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                        <path
                                                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="card-footer">
                                                <label class="text-sm">{{item.strNombre}}</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div *ngIf="item.strTipo == 'pdf'" class="d-flex justify-content-center">
                                        <div class="card w-75">
                                            <div class="card-body">
                                                <a>
                                                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                                        viewBox="0 0 512 512" xmsns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                    fill="#f9f8f9" />
                                                                <path
                                                                    d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                    fill="#dc4955" />
                                                                <path
                                                                    d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                    fill="#dc4955" />
                                                                <path
                                                                    d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                    fill="#c42430" />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                <path
                                                                    d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                <path
                                                                    d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                <path
                                                                    d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="card-footer">
                                                <label class="text-sm">{{item.strNombre}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.strTipo == 'jpg' || item.strTipo == 'png' || item.strTipo == 'jpeg' || item.strTipo == 'jpg'"
                                        class="d-flex justify-content-center">
                                        <div class="card w-75">
                                            <div class="card-body">
                                                <a>
                                                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="512"
                                                        viewBox="0 0 512 512" width="512"
                                                        xmsns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                    fill="#f9f8f9" />
                                                                <path
                                                                    d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                    fill="#e3e0e4" />
                                                                <path
                                                                    d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                    fill="#a29aa5" />
                                                                <path
                                                                    d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                                                                    fill="#9ec8fc" />
                                                                <path
                                                                    d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                                                                    fill="#62a4fb" />
                                                                <path
                                                                    d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                    fill="#9ec8fc" />
                                                                <path
                                                                    d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                                                                    fill="#b9a1d3" />
                                                                <path
                                                                    d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                                                                    fill="#9573bb" />
                                                                <path
                                                                    d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                    fill="#b9a1d3" />
                                                                <circle cx="219.587" cy="285.995" fill="#faf063"
                                                                    r="21.578" />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                                                                <path
                                                                    d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                                                                <path
                                                                    d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="card-footer">
                                                <label class="text-sm">{{item.strNombre}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12" *ngIf="arrayAnexos.length==0 && arrayAnexosUpdate.length==0">
                                    <div class="card">
                                        <div class="card-body d-flex">
                                            <span class="m-auto text-bold">
                                                Sin Anexos
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <mat-accordion class="col-12">
                                    <mat-expansion-panel (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = false"
                                        *ngFor="let item of arrayAnexos; let i = index" class="m-1" id="bordeCard">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title class="d-flex">
                                                {{item.strUrl.split('/')[1]}}
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12"
                                                    *ngIf="item.strTipo != 'pdf' && item.strTipo !== 'xlsx'">
                                                    {{item.strUrl}}
                                                    <img src="https://ecodeli.com/{{item.strUrl}}" alt=""
                                                        class="img-fluid">
                                                </div>
                                                <div class="col-12 d-flex" *ngIf="item.strTipo == 'pdf'">
                                                    <a href="https://ecodeli.com/{{item.strUrl}}" class="m-auto"
                                                        target="_blank">
                                                        <svg id="Capa_1" enable-background="new 0 0 512 512"
                                                            height="512" viewBox="0 0 512 512"
                                                            xmsns="http://www.w3.org/2000/svg">
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                        fill="#f9f8f9" />
                                                                    <path
                                                                        d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                        fill="#e3e0e4" />
                                                                    <path
                                                                        d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                        fill="#e3e0e4" />
                                                                    <path
                                                                        d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                        fill="#dc4955" />
                                                                    <path
                                                                        d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                        fill="#dc4955" />
                                                                    <path
                                                                        d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                        fill="#c42430" />
                                                                </g>
                                                                <g>
                                                                    <path
                                                                        d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                    <path
                                                                        d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                    <path
                                                                        d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                    <path
                                                                        d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </a>

                                                </div>
                                                <div class="col-12 d-flex" *ngIf="item.strTipo == 'xlsx'">
                                                    <a href="https://ecodeli.com/{{item.strUrl}}" class="m-auto"
                                                        download>
                                                        <svg class="svg_excel" xmsns="http://www.w3.org/2000/svg"
                                                            height="1em"
                                                            viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                            <path
                                                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="modal-footer d-flex img-fondo">
                <div class="ms-auto d-flex">
                    <button id="boton" type="button" class="btn btn-success ms-auto mx-1"
                        *ngIf="intFlagAvanza4 == 1 && strEtapa !== 'CIERRE'" (click)="fnAvanzarCierre()"
                        data-bs-dismiss="modal">
                        <i class="bi bi-arrow-right-circle-fill fs-5 me-1"></i>Avanzar Cierre</button>
                    <button id="boton" type="button" class="btn btn-success ms-auto mx-1"
                        *ngIf="intFlagAvanza5 == 1 && (strEtapa != 'NEGOCIACION' && strEtapa != 'CIERRE')"
                        (click)="fnAvanzarNegociacion()" data-bs-dismiss="modal">
                        <i class="bi bi-arrow-right-circle-fill fs-5 me-1"></i>Avanzar Negociación</button>
                    <button id="boton" type="button" class="btn btn-success ms-auto mx-1" *ngIf="strEtapa == 'CIERRE'"
                        data-bs-dismiss="modal" (click)="SubirAnexos('alta')"> <!--fnEnviarAltaCliente()-->
                        <i class="bi bi-arrow-right-circle-fill fs-5 me-1"></i>Alta Cliente</button>
                    <button type="button"
                        class="btn btn-primary ms-auto mx-3 d-flex justify-content-center alight-items-center"
                        (click)="SubirAnexos('')" [disabled]="banderaContacto"><span class="material-symbols-outlined">
                            save
                        </span> <span class="mx-2">Guardar</span> </button><!--data-bs-dismiss="modal"-->
                    <button type="button" id="botonCerrar" class="btn btn-secondary d-none"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>