import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';


import {PeticionesService} from '../servicios/httpservice';
import Swal from 'sweetalert2';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-config-mercados',
  templateUrl: './config-mercados.component.html',
  styleUrls: ['./config-mercados.component.css']
})

export class ConfigMercadosComponent implements OnInit {
  @ViewChild('cerrarBtnMercado') cerrarBtnMercado: ElementRef;
  @ViewChild('cerrarBtnArea') cerrarBtnArea: ElementRef;
public selectCard:string|null=null;
public strAccion:string|null=null;
public arrayDatos:any;
public arrayAreas:any;
public arrayArticulos:any;
public arrayAreasLibres:any;
public intMercado:number;
public intArea:number=0;
public strNombreArea:string="";
public strNombreMercado:string="";
public contadorArtarea:number= 0;
public contadorArtsBuscar:number=0;
public strbuscar:string=""; 
public arrayArticulosAgregar:any;
public arrayArticulosMostrar:any;
public strbuscarArts:string="";
public strbuscarLista:string="";
filteredArray: any[];
  constructor(private _httpService:PeticionesService) { }

  ngOnInit(): void {
  }

  fnselectsrting(card:string){
    this.selectCard = card;
    console.log(this.selectCard)
    this.fngetConsulta(this.selectCard)
  }
  fniciarproceso(){
    this.selectCard = null;
    this.strAccion = null;
    this.arrayDatos = [];
    this.arrayAreas = [];
    this.arrayAreasLibres = [];
    this.arrayArticulos = [];
    this.strNombreArea="";
    this.contadorArtarea = 0;
    this.contadorArtsBuscar = 0;
    this.strNombreMercado="";

  }
  fnselectAccion(accion:string){
  this.strAccion = accion;
  console.log(this.strAccion)
  if(accion=='consultar'){
   
  }
  }
  fngetConsulta(strCard:string){
    let accion="";
    if(strCard=="Mercado"){
      accion="getMercados";
    }else if(strCard=="Area"){
      accion="getAreasTodas";
    }
    let data={
      strAccion:accion
    };
    this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
      result =>{
        this.arrayDatos= result;
        this.arrayDatos.forEach(element => {
          element.check = false
        });
        console.log(this.arrayDatos);
      }
    );
  }
  fngetareas(intMercado:number){
    this.arrayDatos.forEach(a => {
      if(a.intIDMercado==intMercado){
      this.strNombreMercado = a.strNombre;
      }
    });
    let data={
      strAccion:'getAreas',
      intIDMercado:intMercado
    };
    this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
      result =>{
        this.arrayAreas= result;
        console.log(this.arrayAreas);
      }
    );
  }
  selectMercado(id: number,check:boolean) {
    if(check){
      this.intMercado = id;
      this.arrayDatos.forEach(element => {
        if(element.intIDMercado != id){
          element.check = false
        }
      });
      this.fngetareas(id)
    }else{
      this.arrayAreas = []
    }
   
   
  }

  fngetareasSinMercado(intMercado:number){
  
    let data={
      strAccion:'getAreasLibres',
      intIDMercado:intMercado
    };
    this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
      result =>{
        this.arrayAreasLibres= result;
        this.arrayAreasLibres.forEach(element => {
          element.check = false
        });
        console.log(this.arrayAreasLibres);
      }
    );
  }
  fnAgregarArea(intMercado:number){
    let bandera=0;
    this.arrayAreasLibres.forEach(element => {
     if(element.check == true){
      bandera +=1;
     } 
    });

    if(bandera>0){
      this.arrayAreasLibres.forEach(element => {
        if(element.check == true){
          let data={
            strAccion:'setRelacionMercados',
            intIDMercado:intMercado,
            intIDArea:element.intIDArea
          };
          this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
            result =>{
              let respuesta:any;
              respuesta= result;
              console.log(respuesta);
            }
          );
        } 
       }); 
       this.fngetareas(intMercado);
    Swal.fire({showConfirmButton: false,title:'Guardada Correctamente!',text: 'Se agregaron correctamente las areas', icon:'success',timer: 2500});
  
    this.arrayAreasLibres=[];
    this.cerrarBtnMercado.nativeElement.click();
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Sin Seleccion de Areas',
        text:'Seleccione areas'
      });
    }
    console.log(intMercado)
  }
  
  fnEliminar(intMercado:number,intArea:number){

    Swal.fire({
      title: 'Estas seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        let data={
          strAccion:'deleteRelacionMercado',
          intIDMercado:intMercado,
          intIDArea:intArea
        };
        this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
          result =>{
            this.arrayAreas= result;
            console.log(this.arrayAreas);
            if(this.arrayAreas[0].strMensaje=="200"){

              this.fngetareas(intMercado);
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Sin Seleccion de Areas',
                text:this.arrayAreas[0].strMensaje
              });
            }
          }
        );


        Swal.fire(
          'Eliminado!',
          'La relacion ha sido eliminada.',
          'success'
        )
      }
    })
  } 
  fngetArticulosPorArea(intIdArea:number){
    
    let data={
      strAccion:'getArticulos',
      intIDArea:intIdArea
    };
    this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
      result =>{
        this.arrayArticulos= result;
        this.arrayArticulos.forEach(element => {
          element.check = false;
          element.img = element.strArticulo;
        });
        console.log(this.arrayArticulos);
      }
    );
    this.fngetArticulosSinArea(intIdArea)
  }

  selectMercadoAreas(id: number,check:boolean) {
    if(check){
      this.intArea = id;
      this.arrayDatos.forEach(element => {
        if(element.intIDArea != id){
          element.check = false
        }else if(element.intIDArea == id){
          this.strNombreArea = element.strNombre
        }
      });
      this.fngetArticulosPorArea(id)
    }else{
      this.intArea = 0;
      this.arrayArticulos = [];
      this.strNombreArea="";
      this.contadorArtarea = 0;
      this.contadorArtsBuscar = 0;
    }
   
  }
  handleImageError(item: any){
    item.img = 'NO-DISPONIBLE';
  }

  fngetArticulosSinArea(intArea:number){
    let data={
      strAccion:'getArticulosSinArea',
      intIDArea:intArea
    };
    this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
      result =>{
        this.arrayArticulosAgregar= result;
        this.arrayArticulosAgregar.forEach(element => {
          element.check = false;
          element.img= element.strArticulo;
        });
        console.log(this.arrayArticulosAgregar);
      }
    );
  }

  fnmostrarart(strstring:string){
    
    if (strstring === "") {
      this.arrayArticulosMostrar = [];
    } else {
      this.arrayArticulosMostrar = this.arrayArticulosAgregar.filter(o =>
        o.strArticulo.includes(strstring)
      );
    }
    this.arrayArticulosMostrar.forEach(element => {
      element.check = false;
    });
  }


  fninsertarArticulos(intArea:number){
    let arrayObj = [];
    let resultado =[];

    if(this.arrayArticulosMostrar && this.arrayArticulosMostrar.length > 0 ){
      this.arrayArticulosMostrar.forEach(o => {
        if(o.check){
          arrayObj.push({strArticulo:o.strArticulo,checks:o.check})
        }
      });
      if(arrayObj.length > 0){
        let data={
          strAccion:'insertRelacionArticuloArea',
          intIDArea:intArea,
          xmlArticulos:arrayObj
        };
        this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
          result =>{
            resultado= result;
            if(resultado[0].strMensaje=="200"){
              this.selectMercadoAreas(this.intArea,true);
              this.cerrarBtnArea.nativeElement.click();
              this.arrayArticulosMostrar=[];
              this.strbuscarArts="";
              Swal.fire(
                'Agregados!',
                'Se agregaron correctamente los articulos.',
                'success'
              );
            }else{
              Swal.fire(
                'Error!',
                'Error al agregar articulos.',
                'error'
              );
            }
            console.log(resultado);
          }
        ); 
      }else{
        Swal.fire(
          'Error!',
          'Seleccione Articulos para agregar a esta area.',
          'error'
        );
      }
    }else{
      Swal.fire(
        'Error!',
        'Busque articulos y seleccione los que se van a agregar.',
        'error'
      );
    }


  
  
  }
  fnEliminarporArt(intArea:number,articulo:string){

    Swal.fire({
      title: 'Estas seguro de eliminar el articulo:'+articulo+'?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        let data={
          strAccion:'deleteRelacionArticuloArea',
          intIDArea:intArea,
          strArticulo:articulo
        };
        this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
          result =>{
            let resultados:any;
            resultados= result;
            console.log(resultados);
            if(resultados[0].strMensaje=="200"){

              this.selectMercadoAreas(this.intArea,true);
              this.strbuscar ="";
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Sin Seleccion de Areas',
                text:this.arrayAreas[0].strMensaje
              });
            }
          }
        );
        Swal.fire(
          'Eliminado!',
          'La relacion ha sido eliminada.',
          'success'
        )
      }
    })
  } 
  fncerrarmodalareas(){
    this.strbuscarArts="";
    this.arrayArticulosMostrar=[];
  }
  fncontadorarts(strCard:string):number{
    let resultado = 0;
    if(strCard == "MERCADO"){
      if(this.arrayAreasLibres && this.arrayAreasLibres.length > 0){
        this.arrayAreasLibres.forEach(o => {
          if(o.check == true){
            resultado = resultado + 1
          }else{resultado= resultado}
        });}
    } else if(strCard == "AREA"){
      if(this.arrayArticulosMostrar && this.arrayArticulosMostrar.length > 0){
        this.arrayArticulosMostrar.forEach(o => {
          if(o.check == true){
            resultado = resultado + 1
          }else{resultado= resultado}
        });}
    }
    

return resultado;
  }
  fnEliminarTodo(intArea:number){
    Swal.fire({
      title: 'Estas seguro de eliminar todos los articulos del area: '+this.strNombreArea+'?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        let data={
          strAccion:'deleteTodosArticulosArea',
          intIDArea:intArea
        };
        this._httpService.getApiComprasGral(data,"sp_Mercados").subscribe(
          result =>{
            let resultados:any;
            resultados= result;
            console.log(resultados);
            if(resultados[0].strMensaje=="200"){

              this.selectMercadoAreas(this.intArea,true);
              this.strbuscar ="";
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Sin Seleccion de Areas',
                text:this.arrayAreas[0].strMensaje
              });
            }
          }
        );
        Swal.fire(
          'Eliminado!',
          'La relacion ha sido eliminada.',
          'success'
        )
      }
    })

  }
}
