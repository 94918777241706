import { Component, OnInit,ViewChild,NgZone,HostListener } from '@angular/core';
import {PeticionesService} from '../servicios/httpservice';
import {NfcService} from '../servicios/nfc.service';
import { AlertifyService } from '../alertify.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import Swal from 'sweetalert2';
import * as archivo from '../../assets/nfc.js'; 

export interface dataTable {
  intIDComodato:number;
  intIDArticulo:number;
  strArticulo: string;
  intDiasActividad:number;
  strEdificio:string;
  strNivel:string;
  strTipoArea:string;
  strTamArea:string;
  strArea: string;
  strHrInicio: string;
  strHrFin:string;
/*   strNumSerie:string; */
  intEstatusDespachador:number;
  EscrituraNfc:string;
  serietarjeta:string;
}

@Component({
  selector: 'app-detallescomodapro',
  templateUrl: './detallescomodapro.component.html',
  styleUrls: ['./detallescomodapro.component.css'],
})

export class DetallescomodaproComponent implements OnInit {
public isReadOnly = true;
public form: FormGroup;
private _subscription
public spinnerImg:boolean=false;
public btnDis:boolean=true;
public strEstatus:string;
public strDireccion:string;
public strcliente:string;
public intIdComodato:number;
public strNombreCte:string;
public strSerie:string;
public checkStatus:boolean;
public arrayDetalle:any[]=[];
displayedColumns: string[] = [  
  'intIDComodato',
  'intIDArticulo',
  'strArticulo',
  'strDescripcion',
  'EscrituraNfc',
  'strEdificio',
  'strNivel',
  'strTipoArea', 
  'strArea',
  'strHrInicio',
  'strHrFin',
  'intDiasActividad',
  'strHoraInicioFS',
  'strHoraFinFS',
  'intDiasActividadFS',
/*   'strNumSerie', */
  'serietarjeta',
  'intEstatusDespachador'
];
dataSource: MatTableDataSource<dataTable>;constructor(private rutaActiva: ActivatedRoute,private ngZone: NgZone,private _httpService:PeticionesService,
  public _alertify: AlertifyService, private router: Router) { 
}
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit(): void {
   this.rutaActiva.queryParams.subscribe(Params => { 
     this.strDireccion= Params['direccion'];
     this.intIdComodato = Number(Params['idcomodato']);
     this.strcliente= Params['cliente'];
     this.strNombreCte= Params['nombre'];
     this.strEstatus = Params['status'];
   });
  this.fngetDetallesComodato();
  }
  fnResultado(result:any){
  console.log(result);
  this._subscription.unsubscribe();
  }
  fngetDetallesComodato(){
    this.spinnerImg = true;
    let comodato={
      strAccion:'getDetalle', 
      intIDComodato:this.intIdComodato
    };
    this._httpService.getApiComprasGral(comodato,"sp_AppComodatos_Programador").subscribe(
      result => {
        console.log(result);
        let cont = 0;
        this.arrayDetalle= result;
        this.fnAgregarFil();
        for(let i=0;i < this.arrayDetalle.length;i++){
          cont= cont+ this.arrayDetalle[i].intEstatusDespachador; 
        }
        if(this.arrayDetalle.length==cont && this.strEstatus=="PORPROGRAMAR"){
          this.btnDis=false;
        }else{
          this.btnDis=true;
        }
        this.dataSource = new MatTableDataSource(this.arrayDetalle);
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator._intl.itemsPerPageLabel='Dispensadores por página'; 
        console.log(this.dataSource);
        console.log(this.arrayDetalle);
        this.spinnerImg = false;
      }
    );
  }
  fnAgregarFil(){
   let cont;
   cont =  this.arrayDetalle.length;
   for(let i = 0; i < cont;i++){
    this.arrayDetalle[i].lblboolean = 0;
   }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  fnvalid(intart:number,art:string,idsertarj:string){
     const value = (<HTMLInputElement>document.getElementById(idsertarj+"t")).value;
     let elemento = document.getElementById(idsertarj+"l");
    let nArray = this.arrayDetalle.length;
    if(value!= "0" && value != ""){
      const tieneClase = elemento.classList.contains('d-none');
      if (tieneClase) {
        for(let i=0;i < nArray;i++){
          if(this.arrayDetalle[i].intIDArticulo==intart && this.arrayDetalle[i].strArticulo==art){
              this.fnenviarDatos(this.intIdComodato,intart,"",this.arrayDetalle[i].strEdificio,this.arrayDetalle[i].strNivel,this.arrayDetalle[i].strTipoArea,this.arrayDetalle[i].strTamArea,this.arrayDetalle[i].strHrInicio,this.arrayDetalle[i].strHrFin,this.arrayDetalle[i].intDiasActividad,10,20,1,this.arrayDetalle[i].strArticulo,value);
         }
        }
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error en NFC',
          text:'Insertar numero de serie de tajeta'
        });
      const tieneClase = elemento.classList.contains('d-none');
      archivo.cleaninput(idsertarj);
      if (tieneClase) {
        elemento.classList.remove('d-none');
        elemento.classList.add('d-block');
      } 
      } 
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error en NFC',
        text:'Insertar numero de serie de tajeta'
      });
    const tieneClase = elemento.classList.contains('d-none');
    archivo.cleaninput(idsertarj);
    if (tieneClase) {
      elemento.classList.remove('d-none');
      elemento.classList.add('d-block');
    } 
    }
    
  }
 /*  fnvalid(intart:number,art:string,serie:string,idsertarj:string){
    const value = (<HTMLInputElement>document.getElementById(idsertarj+"t")).value;
    let elemento = document.getElementById(idsertarj+"l");
    if(value.length>1){
    let nArray = this.arrayDetalle.length, cont=0, ban=0, ban2=0;
    for(let i=0;i < this.arrayDetalle.length;i++){
        if(this.arrayDetalle[i].intIDArticulo==intart && this.arrayDetalle[i].strArticulo==art){
          this.arrayDetalle[i].strNumSerie=serie;
          ban2=i;
          if(serie.length<1){
            this.arrayDetalle[i].intEstatusDespachador=0;
            this.arrayDetalle[i].lblboolean=1;
            this.btnDis = true;
          }
        }
    }
    for(let i=0;i < this.arrayDetalle.length;i++){
      if(this.arrayDetalle[i].strNumSerie==serie){
        ban= ban + 1;
      }
    }
    if(ban > 1){ 
      if(serie==="0" || serie ===""){
        this.arrayDetalle[ban2].intEstatusDespachador=0;
        this.arrayDetalle[ban2].lblboolean=1;
        this.btnDis = true;
      }else{
        this.arrayDetalle[ban2].intEstatusDespachador=0;
          this.arrayDetalle[ban2].lblboolean=1;
          this.btnDis = true;
        Swal.fire({title:'Cambios no realizados!',text:'Error serie clonada!',icon:'error',});
      }
    }else{
      for(let i=0;i < nArray;i++){
        if(this.arrayDetalle[i].intIDArticulo==intart && this.arrayDetalle[i].strArticulo==art){
           if(this.arrayDetalle[i].strNumSerie.length >1){
            this.fnenviarDatos(this.intIdComodato,intart,serie,this.arrayDetalle[i].strEdificio,this.arrayDetalle[i].strNivel,this.arrayDetalle[i].strTipoArea,this.arrayDetalle[i].strTamArea,this.arrayDetalle[i].strHrInicio,this.arrayDetalle[i].strHrFin,this.arrayDetalle[i].intDiasActividad,10,20,1,this.arrayDetalle[i].strArticulo,value);
           }else{
            this.arrayDetalle[i].intEstatusDespachador=0;
            this.arrayDetalle[i].lblboolean=1;
            this._alertify.error("Favor de ingresar numero de serie");
           } 
        }
      }
    } 
   }else{
    Swal.fire({
      icon: 'error',
      title: 'Error en NFC',
      text:'Insertar numero de serie de tajeta'
    });
    const tieneClase = elemento.classList.contains('d-none');
    if (tieneClase) {
      elemento.classList.remove('d-none');
      elemento.classList.add('d-block');
    } 
    }
  }  */
/*   fniNgresarSerie(intart:number,art:string,serie:string){
    let ban=0,ban2=0;
    for(let i=0;i < this.arrayDetalle.length;i++){
        if(this.arrayDetalle[i].intIDArticulo==intart && this.arrayDetalle[i].strArticulo==art){
          this.arrayDetalle[i].strNumSerie=serie;
          ban2=i;
          if(serie.length<2){
            this.arrayDetalle[i].intEstatusDespachador=0;
            this.arrayDetalle[i].lblboolean=1;
            this.btnDis = true;
          }
        }
    }
    for(let i=0;i < this.arrayDetalle.length;i++){
      if(this.arrayDetalle[i].strNumSerie==serie){
        ban= ban + 1;
      }
    }
    if(ban > 1){
      if(serie==="0" || serie ===""){
        this.arrayDetalle[ban2].intEstatusDespachador=0;
        this.arrayDetalle[ban2].lblboolean=1;
        this.btnDis = true;
      }else{
        console.log(this.arrayDetalle[ban2]);
        this.arrayDetalle[ban2].intEstatusDespachador=0;
          this.arrayDetalle[ban2].lblboolean=1;
          this.btnDis = true;
          this.fngetDetallesComodato();
        Swal.fire({title:'Cambios no realizados!',text:'Error serie clonada!',icon:'error',});
      }  
    }
    this.dataSource = new MatTableDataSource(this.arrayDetalle);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator._intl.itemsPerPageLabel='Dispensadores por página';
  } */
  fnenviarDatos( intIDComodato:number,intIDArticulo:number,strSerie:string,strEdiFicio:string,
    strNivel:string,strArea:string,strAreaTam:string,strHoraInicio:string,strHoraFin:string,
    intDiasActividad:number,intIntervalo:number,dblDisparos:number,strEstatusDespachador:number,strArticulo:string,strNumSerieNFC:string)
     {
    let Datos = {strAccion:'setEstatusDespachador',intIDComodato:intIDComodato,intIDArticulo:intIDArticulo,
    strSerie:strSerie,strEdiFicio:strEdiFicio,strNivel:strNivel,strArea:strArea,strAreaTam:strAreaTam,
    strHoraInicio:strHoraInicio,strHoraFin:strHoraFin,intDiasActividad:intDiasActividad,intIntervalo:intIntervalo,
    dblDisparos:dblDisparos,strEstatusDespachador:strEstatusDespachador,strArticulo:strArticulo,strNumSerieNFC:strNumSerieNFC}
    this.spinnerImg = true;
    this._httpService.getApiComprasGral(Datos,"sp_AppComodatos_Programador").subscribe(
        result => {
          if(result[0].code==200){
            console.log(result);
            this.fngetDetallesComodato();
            Swal.fire({showConfirmButton: false,title:'Guardada Correctamente!',text: 'Serie: '+strNumSerieNFC, icon:'success',timer: 2500});
          }else{
            Swal.fire({showConfirmButton: false,title:'Cambios No realizados!',text:result[0].strMensaje,icon:'error',timer: 2500});
            console.log(result);
          }
          this.spinnerImg = false;
        });
  }
  fnwritenfc(id:number,serietarj:string,idsertarj:string,intart:number,art:string){
   /*  serial= (<HTMLInputElement>document.getElementById(id+"noserie")).value;
        if(serial.length>2 ){  */
          if ("NDEFReader" in window) {
            if(serietarj == "0"){
              Swal.fire({
                title: 'Leer tarjeta',
                icon:'question',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'No',
                html:'¿Desea leer tarjeta <br><br> para validar la escritura ?',
                customClass: {
                actions: 'my-actions',
                confirmButton: 'order-2',
                denyButton: 'order-3',}
              }).then(async (result) => {
                if (result.isConfirmed){
                   archivo.readTag(id); 
                    Swal.fire({
                      icon: 'warning',
                      title: 'leyendo tarjeta',
                      showConfirmButton: false,
                      timer: 4000,
                      timerProgressBar: true,
                    });     
             setTimeout(() => {  
                                    const value = (<HTMLInputElement>document.getElementById(idsertarj)).value;
                                    let objCom ={
                                    strAccion:'getSerieNFC',
                                    strNumSerieNFC:value,
                                    strSerie:''
                                    };
                                    if(value!= "error" && value != "0" &&value != ""){
                                      this._httpService.getApiComprasGral(objCom,"sp_AppComodatos_Programador").subscribe(
                                        result =>{
                                          let elemento = document.getElementById(id+"l");
                                          if(result[0].code==200){
                                            Swal.fire({
                                              title: 'Escribir  tarjeta',
                                              icon:'question',
                                              showDenyButton: true,
                                              confirmButtonText: 'Si',
                                              denyButtonText: 'No',
                                              html:'¿Desea Escribir en tarjeta <br><br> el numero de serie: '+ value +' ?',
                                               customClass: {
                                              actions: 'my-actions',
                                              denyButton: 'order-3',}
                                            }).then(async (result) => {
                                              if (result.isConfirmed){
                                             
                                                const tieneClase = elemento.classList.contains('d-block');
                                                if (tieneClase){
                                                  elemento.classList.remove('d-block');
                                                  elemento.classList.add('d-none'); 
                                                } 
                                                archivo.writeTag(id,value); 
                                                }else{
                                                  Swal.fire({
                                                    icon: 'error',
                                                    title: 'Error al escribir',
                                                    text:"Eligio no escribir en la tarjeta"
                                                    });
                                                    archivo.cleaninput(id);
                                                   const tieneClase = elemento.classList.contains('d-none');
                                                    if (tieneClase) {
                                                          elemento.classList.remove('d-none');
                                                          elemento.classList.add('d-block');
                                                        }
                                                }
                                              });

                                          }else{
                                            const textoJSON = JSON.stringify(result, null, 2);
                                            Swal.fire({
                                              title: 'JSON',
                                              text: textoJSON,
                                              icon: 'info',
                                              confirmButtonText: 'Ok'
                                            });
                                            Swal.fire({
                                              icon: 'error',
                                              title: 'Error de validación',
                                              text:result[0].strMensaje
                                              });
                                                 /*  archivo.cleanIntser(id); */
                                                 archivo.cleaninput(id);
                                                  const tieneClase = elemento.classList.contains('d-none');
                                                  if (tieneClase) {
                                                    elemento.classList.remove('d-none');
                                                    elemento.classList.add('d-block');
                                                  }
                                                }


                                              }); 
                                    }else{
                                     /*  this.fniNgresarSerie(intart,art,serial); */
                                      Swal.fire({
                                        icon: 'error',
                                        title: 'Error al leer',
                                        text:'inicie el proceso de escritura, lea una tarjeta porfavor'
                                      });
                                    }

                                    }, 5000);  
                  }else{
                  }
                });

            }else{// aqui va que hace si es mayor de 0 el numero de serie de la tarjeta
              Swal.fire({
                icon: 'error',
                title: 'Error al escribir',
                text:'la tarjeta ya tiene escrita la url'
              });
                  }
          }else{
            this._alertify.error("Dispositivo no cuenta con NFC")
          }  
     /*    }else{ */
          /* this.fniNgresarSerie(intart,art,serial); */
         /*    Swal.fire({
              icon: 'error',
              title: 'Error al escribir',
              text:'Colocar un numero de serie correcta del despachador'
            });
          
      } */
  }  
  fnEnviarComodato(){
    let objCom ={
      strAccion:'setFinalizarProgramacion',
      intIDComodato:this.intIdComodato
    };
    this._httpService.getApiComprasGral(objCom,"sp_AppComodatos_Programador").subscribe(
      result =>{
        if(result[0].code==200){
          console.log(result);
          this.strEstatus="PROGRAMADO";
          this.fngetDetallesComodato();
          Swal.fire({showConfirmButton: false,title:'Guardada Correctamente!', icon:'success',timer: 3500});
        }else{
          this.fngetDetallesComodato();
          Swal.fire({showConfirmButton: false,title:'Cambios No realizados!',icon:'info',timer: 3500});
          console.log(result);
        }
      });
  }

}

