import {AfterViewInit, Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { FiltrosService } from '../servicios/filtros.service';
import { AlertifyService } from '../alertify.service';
import {LocalStorageService} from 'ngx-webstorage';
import { PeticionesService } from '../servicios/httpservice';
import { Router } from '@angular/router';
import {MatPaginator,MatPaginatorIntl} from '@angular/material/paginator';


import {MatTableDataSource} from '@angular/material/table';

export interface PeriodicElement {
  strSucursal: string;
  strCliente: string;
  strMovID: string;
  strFechaFechaEmision: string;
  strReferencia: string;
  strOrdenCompra: string;
  dblImporte: string;
  intCteEnviarA: string;
  pdf:string

}
@Component({
  selector: 'app-listado-evidencias-comodatos',
  templateUrl: './listado-evidencias-comodatos.component.html',
  styleUrls: ['./listado-evidencias-comodatos.component.css']
})
export class ListadoEvidenciasComodatosComponent implements OnInit {
  displayedColumns: string[]  = ['strSucursal','strCliente','strMovID','strFechaFechaEmision','strReferencia','strOrdenCompra','dblImporte','intCteEnviarA','pdf'];
  dataSource : MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  arrayEjercicio:any=[];
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arraySucursales:any=[];
  public strSucursal:string="";
  public arrayGerentes:any=[];
  public strGerente: string="";
  public arrayAccesos:any=[];
  public strConexion:string="";
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public spiner:boolean=false;
  public arrayClientes:any=[];
  public tagName:any;
  public arrayEvidenciaComodatos:any=[];
  constructor(private localSt:LocalStorageService,elem:ElementRef,private paginatorIntl: MatPaginatorIntl,public _alertify: AlertifyService, private router: Router,   private _httpService:PeticionesService, private _filtroServ :FiltrosService) {
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1; 
    this.strConexion = this.localSt.retrieve('strConexion');
  
    //array de accesos
    //obtener niveles de acceso
    this.tagName=elem.nativeElement.tagName.toLowerCase();
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      } else {
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
      }
    }
    this.dataSource = new MatTableDataSource(this.arrayEvidenciaComodatos);
    this.customizeItemsPerPageLabel();
   }
  ngOnInit(): void {
    this.fnGetFiltros();
    this.fnGetComodatos();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  customizeItemsPerPageLabel() {
    this.paginatorIntl.itemsPerPageLabel = 'items por página';
  }

  ngAfterViewInit() {
    this.fnTable();
  }
  fnTable(){
    this.paginator.pageSize = 50; 
    this.dataSource.paginator = this.paginator;
  }
  fnGetFiltros(){
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {
      this.arrayAgentes = nuevoValor;
      this.arrayAgentes.sort((a, b) => {
        if (a.strNombre < b.strNombre) {
          return -1;
        }
        if (a.strNombre > b.strNombre) {
          return 1;
        }
        return 0;
      });
    //  this.strAgente=this.localSt.retrieve('strAgente').toString();
      //console.log(this.strAgente)
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
      this.arraySucursales.sort((a, b) => {
        if (a.strNombreSucursal < b.strNombreSucursal) {
          return -1;
        }
        if (a.strNombreSucursal > b.strNombreSucursal) {
          return 1;
        }
        return 0;
      });
    });
  
    //gerentes
    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
    // console.log(nuevoValor);
      this.arrayGerentes = nuevoValor;
      this.arrayGerentes.sort((a, b) => {
        if (a.strNombre < b.strNombre) {
          return -1;
        }
        if (a.strNombre > b.strNombre) {
          return 1;
        }
        return 0;
      });
    });
  }
  
  fnFiltrar(){
    this.fnGetComodatos()
  }

  
  fnGetComodatos(){
    this.spiner= true;
    let obj = {
       strAgente: this.strAgente.replace(/^\s+|\s+$/g, ""),
       intEjercicio: this.intEjercicio,
       intPeriodo:this.intPeriodo ,
       strSucursal:this.strSucursal,
       strGerente:this.strGerente,
       strConexion:this.strConexion,
       strAccion:'getReporte_Evidencia_Comodatos',
    };
    if(this.strAgente!="" && this.strAgente != null){
      obj.strAgente= this.strAgente;
      obj.strSucursal=""
      obj.strGerente=""
    }else if(this.strSucursal!=""&& this.strSucursal != null){
      obj.strAgente= ""
      obj.strSucursal=this.strSucursal;
      obj.strGerente=""
    }else if(this.strGerente!=""&& this.strGerente != null){
      obj.strAgente= ""
      obj.strSucursal=""
      obj.strGerente=this.strGerente;
    }
    console.log(obj);
    this._httpService.mostrarCotizaciones(obj,'sp_ModVentas_Reportes').subscribe(
      result => {
        this.arrayEvidenciaComodatos = result;
/*         this.totalImporte = this.arrayVentasReportes.map(t => t.dblImporte).reduce((acc, value) => acc + value, 0); */
        this.dataSource = new MatTableDataSource(this.arrayEvidenciaComodatos);
        this.fnTable();
        console.log(this.arrayEvidenciaComodatos);
      });
    }
    
  splitTextIntoTwoLines(text: string): string {
    const mid = Math.floor(text.length / 2);
    const firstPart = text.slice(0, mid);
    const secondPart = text.slice(mid);
    return `${firstPart}<br>${secondPart}`;
  }

  openUrlInNewTab(url: string): void {
    console.log(url); // Para verificar la URL en la consola
    window.open(url, '_blank');
  }
}
