import { Component, OnInit,AfterViewInit,ViewChild,ElementRef} from '@angular/core';
import {PeticionesService} from '../servicios/httpservice';
import {LocalStorageService} from 'ngx-webstorage';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTableDataSource,MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { DataDBService } from '../servicios/data-db.service';
export interface PeriodicElement {
  Cliente:string;
  Nombre:string;
  Movimiento:string;
  FechaEmision:Date;
  FechaRequerida:Date;
  Situacion: string;
  cambiarSituacion:string;

}
@Component({
  selector: 'app-pedidos-ventas',
  templateUrl: './pedidos-ventas.component.html',
  styleUrls: ['./pedidos-ventas.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PedidosVentasComponent implements OnInit {
  itemsPerPageLabel = 'Elementos por página';
  dataSource = new MatTableDataSource<PeriodicElement>();
  columnsToDisplay = ['ID','Movimiento','Cliente', 'Nombre',  'FechaEmision','FechaRequerida','Importe', 'Situacion', 'cambiarSituacion'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any | null = null;
  selectedValue: number=0;
 public arrayDetalle:any;
 public spinerTab:number = 0;
 public arraySubcuenta:any=[];
 public isHoveredButton: boolean;
 
  arrayDivicionales:any;
  arrayAgentes:any = [];
  arraySucursales:any = [];
  arrayPedidos:any= [];
  strGerente:string="";
  strSucursal:string="";
  strConexion:string="";
  strAgente:string="";
  intEjercicio:number=0;
  strStatus:number=0;
  intPeriodo:number; 
  
  spinerSubTab:number =0; 
  arrayAccesos:any;
  public tagName:any;
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public arrayGerentes:any;
  arrayFiltros:any={lstDivicionales:false,lstSucursales:false,lstAgentes:false};
  arrayPeriodo:any=[
    {strNombre:'Enero',strPeriodo:1},
    {strNombre:'Febrero',strPeriodo:2},
    {strNombre:'Marzo',strPeriodo:3},
    {strNombre:'Abril',strPeriodo:4},
    {strNombre:'Mayo',strPeriodo:5},
    {strNombre:'Junio',strPeriodo:6},
    {strNombre:'Julio',strPeriodo:7},
    {strNombre:'Agosto',strPeriodo:8},
    {strNombre:'Septiembre',strPeriodo:9},
    {strNombre:'Octubre',strPeriodo:10},
    {strNombre:'Noviembre',strPeriodo:11},
    {strNombre:'Diciembre',strPeriodo:12},
  ];
  objModal:any={intID:0,strArticulo:"",strDescripcion:"",intCantidad:0,strRenglon:"",strSubCuenta:"",strMov:""};
  arrayEjercicio:any=[2021,2022,2023,2024,2025];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('modalCls', { static: false }) modalCls!: ElementRef;


  constructor(private indexedDBService: DataDBService,private _filtroServ: FiltrosService,private _httpService:PeticionesService,private localSt:LocalStorageService,elem:ElementRef,private router: Router){ 
    this.strConexion = this.localSt.retrieve('strConexion');

    this.tagName=elem.nativeElement.tagName.toLowerCase();
   /* this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    
    if(this.arrayAccesos.length>0){
      var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
      if(objAcceso==undefined||objAcceso.length==0){
        this.router.navigate(['/login']);
      }
      else{
        this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
        this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
        this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
        this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
        this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
        this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';
     
      } 
    }*/
  }
  ngOnInit(): void { 
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1;
   
  
   
    this.indexedDBService.getItem('arrayResult', (value) => {

      //this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
      this.arrayAccesos=value?value:[];
      
      if(this.arrayAccesos.length>0){
        var objAcceso = this.arrayAccesos.filter((o:any)=>o.strComponente == this.tagName);
        
        if(objAcceso==undefined||objAcceso.length==0){
          this.router.navigate(['/login']);
        }
        else{  

          this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
          this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
          this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
          this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
          this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');  
          this.strGerente = (!this.boolGerente && this.boolSucursal) ? this.localSt.retrieve('strUsuario') : '';

             //------------conexi+on-------------------
             this.indexedDBService.getItem('strConexion', (value) => {
              this.strConexion =value;
              console.log(' CARGA conexion reporte principal')
              this.fnGetFiltros();
              this.fnMostrarPedidos();
            });
        }
      }
      else {
        this.router.navigate(['/login']);
      }

    });

  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Pedidos por página';
  }
  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });

    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });

    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {

      this.arrayAgentes = nuevoValor;
    });

    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }


  fnAsignarDatos(){
    let fecha = new Date(); 
    this.intEjercicio = fecha.getFullYear();
    this.intPeriodo = fecha.getMonth()+ 1;
    this.arrayAccesos=this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):[];
    console.log(this.arrayAccesos);
    this.arrayAccesos = this.arrayAccesos.filter(o => o.strComponente === 'app-pedidos-ventas');
 
    console.log(this.arrayAccesos);
  }

  setSelectedValue(value: number) {
    this.selectedValue = value;
    let datos = "",arraytrans = [];
   datos= value == 1 ? 'CONCLUIDO' :
    value == 2 ? 'PENDIENTE' :
    value == 3 ? 'SINAFECTAR' :
    value == 4? 'CANCELADO':'';
    if(datos != "" ){
      arraytrans = this.arrayPedidos.filter(o => o.strEstatus == datos );
    }else{
      arraytrans = this.arrayPedidos;
    } 
    const transformedData: PeriodicElement[] = arraytrans.map(item => ({
      Cliente: item.strCliente,
      Nombre: item.strNombreCliente,
      Movimiento: item.strMovID,
      FechaEmision: item.strFechaEmision,
      FechaRequerida: item.strFechaRequerida,
      Importe: item.dblImporte,
      intID:item.intID,
      ID:item.intID,
      cambiarSituacion: '',
      Situacion: item.strSituacion,
      Estatus:item.strEstatus,
    }));
    this.dataSource.data = transformedData;
    console.log(this.dataSource.data)
  }
 fnGetDivisionales(){
    /*this.strGerente = this.boolSucursal && !this.boolGerente  ?this.arrayAccesos[0].strUsuario:"";
    this.strSucursal = this.boolAgente && !this.boolSucursal ? this.arrayAccesos[0].sucursal:"";
 
    this.strAgente = this.boolAgente?this.arrayAccesos[0].strAgente :"";*/
    if(!this.boolAgente){
      this.fnMostrarPedidos();
    }else if(this.boolAgente){
 // si es gerente de sucursal extrae los agentes
      this.fnSelect("Agente");
    }else if(this.boolSucursal){// si es gerente divicional extrae las sucursales
      this.fnSelect("Sucursal");
    } else  if(this.boolGerente){
  
      this._httpService.getLineasComodatos({strConexion:this.strConexion,strAccion:"getGerentes"},'sp_ModVentas_ReportePrincipal').subscribe(
        result => {
          this.arrayDivicionales = result;
          this.arrayDivicionales.unshift({strGerente: '', strNombreProveedor: '--Todos--'})
          console.log(this.arrayDivicionales)
         // this.strAgente = '';
       //   this.strSucursal = '';
          let objSucTodo ={ strGerente:'',strNombre:'--Todos---' };
          this.arrayDivicionales.push(objSucTodo);
          this.fnSelect("Sucursal");
          this.fnMostrarPedidos();
        });
      } 
      this.fnMostrarPedidos(); 
 }

 fnGetSucursales(){
  this._httpService.getLineasComodatos({strGerente:this.strGerente,strConexion:this.strConexion,strAccion:"getSucursales"},'sp_ModVentas_ReportePrincipal').subscribe(
    result => { 
      this.arraySucursales = result; 
      let objSucTodo ={ strSucursal:'',strNombreSucursal:'--Todas--',strGerente:this.arraySucursales[0].strGerente};
      this.arraySucursales.push(objSucTodo);
     // this.strAgente = '';
      this.strSucursal = '';
      this.fnGetAgentes();
      console.log(this.arraySucursales)
    });
 }

 fnGetAgentes(){
console.log("IngresaAgentes")
  this._httpService.getLineasComodatos({strConexion:this.strConexion,strGerente:this.strGerente,strSucursal:this.strSucursal,strAccion :"getAgentes"},'sp_ModVentas_ReportePrincipal').subscribe(
    result => { 
      this.arrayAgentes = result;
      let objSucTodo ={strAgente:'',strSucursal:'',strNombreProveedor:'--Todos---'};
      this.arrayAgentes.push(objSucTodo);
     // this.strAgente = '';
      this.fnMostrarPedidos();
      console.log(this.arrayAgentes)
    });
 }

 fnSelect(strAccion:string){
  if(strAccion == 'Sucursal'){
    this.fnGetSucursales();
  }else if(strAccion == 'Agente'){
    this.fnGetAgentes();
  }
 }
 
 onGerenteChange(srtCam:string){
  if(srtCam=="Ger"){
    this.strSucursal =""
    this.strAgente =""
  }else if(srtCam=="Suc"){
    this.strAgente =""
  }
}
fnMostrarPedidos(){
  this.spinerTab = 2;
  let obj:any;
  obj ={
    strAccion:'getMovPedidos',
    strAgente:this.strAgente,
    strGerenteDivisional:this.strGerente,
    strSucursal:this.strSucursal,
    intperiodo:this.intPeriodo,
    intEjercicio:this.intEjercicio,
    strConexion:this.strConexion
  }
  this._httpService.getApiComprasPedidos(obj).subscribe(
    result =>{
      console.log(result)
      this.arrayPedidos = result; 
      const transformedData: PeriodicElement[] = this.arrayPedidos.map(item => ({
        Cliente: item.strCliente,
        Nombre: item.strNombreCliente,
        Movimiento: item.strMovID,
        FechaEmision: item.strFechaEmision,
        FechaRequerida: item.strFechaRequerida,
        Importe: item.dblImporte,
        intID:item.intID,
        Situacion: item.strSituacion,
        Estatus:item.strEstatus,
      }));
      this.dataSource.data = transformedData; // Asigna los datos al MatTableDataSource
      this.spinerTab = 1; 
      this.strStatus = 3; ///////////////////////////////////
      this.setSelectedValue(3);
  });
}

applyFilter(event: Event) {
  let inputValue: string;
  if (event instanceof KeyboardEvent) {
    inputValue = (event.target as HTMLInputElement).value;
  } else {
    inputValue = event.toString();
    this.strStatus = Number(inputValue);
  } 
  const filterValue = inputValue === '0' ? '' :inputValue === '1' ? 'CONCLUIDO' :
                    inputValue === '2' ? 'PENDIENTE' :
                    inputValue === '3' ? 'SINAFECTAR' :
                    inputValue === '4' ? 'CANCELADO' : (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  if (this.dataSource.paginator){
    this.dataSource.paginator.firstPage();
  }
}

fnDetallePedido(obj:any){
  this.spinerSubTab = 2
  let objA={
    strAccion:'getDetalleVenta',
   idVenta:obj.intID,
   strConexion:this.strConexion
  }
  this._httpService.getApiComprasPedidos(objA).subscribe(
    result =>{
      this.arrayDetalle = result; 
      this.spinerSubTab = 1;
  });
}

fnActualizarDetallePedido(x:any,strMov:string){
  this.arraySubcuenta=[];
  this.objModal.intID=x.intID;
  this.objModal.strArticulo=x.strArticulo;
  this.objModal.strDescripcion=x.strDescripcion;
  this.objModal.intCantidad=x.intCantidad;
  this.objModal.strRenglon=x.strRenglon;
  this.objModal.strSubCuenta=x.strSubCuenta;
  this.objModal.strNombreOpcion=x.strNombreOpcion;
  this.objModal.strMov=strMov;
  this.fnGetOpcionArticulo(x.strArticulo);
}

fnGetOpcionArticulo(art:string){
  let objA={
    strAccion:'getOpcionArt',
    strArticulo:art,
    strConexion:this.strConexion
  }
  this._httpService.getApiComprasPedidos(objA).subscribe(
    result =>{
      this.arraySubcuenta = result; 
  });
}

fneliminarDetallePedido(obj:any,strMov:string){
  let resultado = [];
  Swal.fire({
    title: 'Estas seguro de eliminar los articulos del pedido?',
    text: "¡No podrás revertir esto!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '¡Sí, Eliminar!',
    cancelButtonText: 'No, mantener en el pedido'
  }).then((result) => {
    if (result.isConfirmed) { 
      this.spinerSubTab = 2
      let objA={
        strAccion:'deleteDetalleVenta',
        intID:obj.intID,
        intCantidad:obj.intCantidad,
        strArticulo:obj.strArticulo,
        strRenglon:obj.strRenglon,
        strSubCuenta:obj.strSubCuenta,
        strMovID:strMov,
        strConexion:this.strConexion
      }
      this._httpService.getApiComprasPedidos(objA).subscribe(
        result =>{
          resultado = result; 
          this.spinerSubTab = 1;
          if(resultado[0].code == "200"){
            this.fnMostrarPedidos();
            Swal.fire(
              '¡Eliminada!',
              'Sus articulos han sido Eliminado.',
              'success'
            );
          } else{
            Swal.fire(
              'Error',
              resultado[0].code+' Mensaje:'+resultado[0].strMensaje,
              'error'
            );
          }
      });
    }
  })
}

  async fnCancelarPedido(obj:any){
 
  let resultado = []; 
  const { value: inputValue } = await Swal.fire({
    title: '¿Estás seguro de cancelar el pedido?',    
     icon: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#3085d6',
     cancelButtonColor: '#d33',
     confirmButtonText: '¡Sí, Cancelar!',
     cancelButtonText: 'No, mantener pedido',
    inputLabel: 'Indica la cantidad a cancelar',
    input: 'select',
    inputOptions: {       
      'Todo': 'Cancelar Todo',
      'Pendiente': 'Cancelar Cantidad Pendiente'
    },inputPlaceholder: 'Elige una opción',
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (!value) {
              resolve('Debes seleccionar una opción')
        }
        else{
          
          resolve('');
        }
      })
    }
  })
  if (inputValue) {
   // Swal.fire(`You selected: ${inputValue}`)
    this.spinerTab = 2;
      let objA={
        strAccion:'SetCancelarPedido',
       intID:obj.intID,
       strMovID:obj.Movimiento,
       strSubCuenta:inputValue, //cancelar lo indicado
       strConexion:this.strConexion
      }
      this._httpService.getApiComprasPedidos(objA).subscribe(
        result =>{
          resultado = result; 
          if(resultado[0].code == "200"){
            Swal.fire(
              '¡Cancelado!',
              'Su Pedido ha sido Cancelado.',
              'success'
            );
            this.fnMostrarPedidos();
          }else{this.spinerTab = 1;
            Swal.fire(
              'Error',
              resultado[0].strMensaje ,
              'error'
            );
          }
      });
  }
 //----------------------------------------------
}

fnEliminarPedido(obj:any){
  let resultado = [];
  Swal.fire({
    title: 'Estas seguro de cancelar el pedido?',
    text: "¡No podrás revertir esto!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '¡Sí, Eliminar!',
    cancelButtonText: 'No, mantener pedido'
  }).then((result)=>{
    this.spinerTab = 2;
    if (result.isConfirmed){
      let objA={
        strAccion:'SetEliminarPedido',
       intID:obj.intID,
       strMovID:obj.Movimiento,
       strConexion:this.strConexion
      }
      this._httpService.getApiComprasPedidos(objA).subscribe(
        result =>{
          resultado = result; 
          if(resultado[0].code == "200"){
            Swal.fire(
              'Eliminado!',
              'Su Pedido ha sido Eliminado.',
              'success'
            );
            this.fnMostrarPedidos();
          }else{
            Swal.fire(
              'Error',
              resultado[0].code,
              'error'
            );
            this.spinerTab = 1;
          }
      });
    }
    else if(result.isDismissed){  
      this.spinerTab = 1; 
    }
  } );
}

fnGuardarActualizados(obj:any){
  Swal.fire({
    title: 'Actualizar pedido?',
    text: "¡Estas seguro de modificar el pedido!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '¡Sí, Modificar!',
    cancelButtonText: 'No, mantener pedido'
  }).then((result)=>{
    if (result.isConfirmed){
      let objA={
        strAccion:'setActualizarDetVenta',
        intID:obj.intID,
        intCantidad:obj.intCantidad,
        strArticulo:obj.strArticulo,
        strRenglon:obj.strRenglon,
        strSubCuenta:obj.strSubCuenta,
        strMovID:obj.strMov,
        strConexion:this.strConexion
      }
      this._httpService.getApiComprasPedidos(objA).subscribe(
        result =>{
          let rest=[];
          rest = result; 
          if(rest[0].code == "200"){
            this.modalCls.nativeElement.click();
            this.fnMostrarPedidos();
            Swal.fire(
              '¡Pedido Modificado!',
              '',
              'success'
            );
          }else{
            Swal.fire(
              'Error',
              rest[0].code+' Mensaje:'+rest[0].strMensaje,
              'error'
            );
          }
      });
    }
  });
}

fnsp_Set_Pedido(id: any){
  console.log(id)
  let objA={
    strAccion:'set_PORAUTORIZAR',
    intID: id,
    strConexion:this.strConexion
  }
  console.log(objA)
   this._httpService.getApiComprasPedidos(objA).subscribe(
    result =>{
      let rest=[];
      console.log(result)
      rest = result; 
      if(rest[0].code == "200"){
        Swal.fire(
          '¡Pedido Modificado!',
          '',
          'success'
        );
      }else{
        Swal.fire(
          'Error',
          rest[0].code+' Mensaje:'+rest[0].strMensaje,
          'error'
        );
      }
  }); 
}

toggleExpand(id: any, event: MouseEvent): void {
  event.stopPropagation(); // Evita que el clic en el ícono se propague a la fila
  this.fnsp_Set_Pedido(id)
}
}



