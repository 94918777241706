import { Component, OnInit,ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table'
import {Router} from '@angular/router'
import {PeticionesService} from '../servicios/httpservice'
import { filter } from 'rxjs/operators';
import { faL } from '@fortawesome/free-solid-svg-icons';

export interface UserData {
  intIDComodato: number;
  strFechaEmision: string;
  strCliente: string;
  strNombre:string;
  strDireccion:string;
  intCantidad:number;
  strEstatusProgramador:string;
}

/** Constants used to fill up our data base. */

@Component({
  selector: 'app-comodatospro',
  templateUrl: './comodatospro.component.html',
  styleUrls: ['./comodatospro.component.css']
})
export class ComodatosproComponent implements OnInit {
  public spinnerImg:boolean = false;
  public datosImg:boolean = false;
  public agenteSelected:string= "";
  public arrayComodatos:any[];
  public arrayEstatus:any[]=[
    {id:"PROGRAMADO",strestatus:"PROGRAMADO"},
    {id:"PORPROGRAMAR",strestatus:"POR PROGRAMAR"}
  ];
  public arrayejercicio:any[]=[
    {id:2021,strestatus:"2021"},
    {id:2022,strestatus:"2022"},
    {id:2023,strestatus:"2023"},
    {id:2024,strestatus:"2024"},
    {id:2025,strestatus:"2025"}
  ];
  public intPeriodo:number;
  public intEjercicio:number;
  public strStatus:string;
  public arrayperiodo:any[]=[
    {id:1,strestatus:"Enero"},
    {id:2,strestatus:"Febrero"},
    {id:3,strestatus:"Marzo"},
    {id:4,strestatus:"Abril"},
    {id:5,strestatus:"Mayo"},
    {id:6,strestatus:"Junio"},
    {id:7,strestatus:"Julio"},
    {id:8,strestatus:"Agosto"},
    {id:9,strestatus:"Septiembre"},
    {id:10,strestatus:"Octubre"},
    {id:11,strestatus:"Noviembre"},
    {id:12,strestatus:"Diciembre"}
  ];
  displayedColumns: string[] = ['intIDComodato','strCliente', 'strNombre','strEstatusProgramador', 'intCantidad','accion'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
 /*  @ViewChild(MatSort) sort: MatSort; */
  constructor(private router: Router,private _httpService:PeticionesService){
   }
  ngOnInit(): void {
    let date = new Date();
    this.intEjercicio= date.getFullYear();
    this.intPeriodo = date.getMonth()+1 ;
    this.strStatus=  "PORPROGRAMAR" ;
    this.fngetComodatos();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  fngetComodatos(){
    this.datosImg = false;
    this.spinnerImg = true;
    let comodato={
      strAccion:'getSolicitud', 
      intPeriodo:this.intPeriodo, 
      intEjercicio:this.intEjercicio,
    };
    
    this._httpService.getApiComprasGral(comodato,"sp_AppComodatos_Programador").subscribe(
      result =>{
        this.arrayComodatos= result;
        console.log(this.arrayComodatos);
        this.arrayComodatos =  this.arrayComodatos.filter(e=>e.strEstatusProgramador ==  this.strStatus);
        let tamArray=this.arrayComodatos.length;
        if(tamArray<1){
          this.datosImg = true;
        }else{
          this.datosImg = false;
        }
        this.dataSource = new MatTableDataSource(this.arrayComodatos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator._intl.itemsPerPageLabel='Comodatos por página'; 
        console.log(this.dataSource);
        this.spinnerImg = false;
      }
    );
  }
  fnEditar(idComodato:string,strDireccion:string,Cliente:string,nombre:string,strStatus:string){
   /*  this.router.navigate(['/detallesComodatosProgramador']); */
    /* this.router.navigate(['/detallesComodatosProgramador',idComodato,strDireccion,Cliente,nombre,strStatus]); */
    this.router.navigate(['/detallesComodatosProgramador'],{queryParams:{idcomodato:idComodato,direccion:strDireccion,cliente:Cliente,nombre:nombre,status:strStatus}});
  }
}



