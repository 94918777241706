<div class="container mt-2">
    <h2>Dirección</h2>
    <form [formGroup]="formAdress" (ngSubmit)="onSubmit(formAdress.value)">
        <div class="form-group">
            <label for="name">Nombre</label>
            <input    [ngClass]="{'is-valid': f.strNombre.touched && f.strNombre.valid,
              'is-invalid': f.strNombre.touched && f.strNombre.invalid}"  id="name" type="text" class="form-control " formControlName="strNombre" placeholder="Ingresa tu nombre" />
            <div *ngIf="f.strNombre.touched && f.strNombre.invalid" class="text-danger">
                <div *ngIf="f.strNombre.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strNombre.errors?.minlength">Debe tener al menos 2 caracteres.</div>
              </div>
        </div>
        <div class="form-group">
            <label for="apellido">Apellidos</label>
            <input [ngClass]="{'is-valid': f.strApellido.touched && f.strApellido.valid,
              'is-invalid': f.strApellido.touched && f.strApellido.invalid}" id="apellido" type="text" class="form-control fs13"
            formControlName="strApellido"
            placeholder="Ingresa tus Apellidos"
            />
            <div *ngIf="f.strApellido.touched && f.strApellido.invalid" class="text-danger">
                <div *ngIf="f.strApellido.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strApellido.errors?.minlength">Debe tener al menos 2 caracteres.</div>
              </div>
        </div>
        <div class="col-12">
            <div class="form-group mb-3">
                <label for="apellido">Código Postal</label>
              <input   placeholder="Ingrese Codigo Postal" type="text"  id="apellido" (keydown)="onEnter($event)" class="form-control valid fs13" (input)="updateCantidad($event)"  formControlName="strCodigoPostal"
              [ngClass]="{'is-valid': f.strCodigoPostal.touched && f.strCodigoPostal.valid,'is-invalid': f.strCodigoPostal.touched && f.strCodigoPostal.invalid}"
               [maxlength]="5" autocomplete="new-password" autocorrect="off" autocapitalize="off" spellcheck="false" id="strCodigoPostal"><!--[(ngModel)]="strCodigoPostal" -->
                <div *ngIf="f.strCodigoPostal.touched && f.strCodigoPostal.invalid" class="text-danger">
                <div *ngIf="f.strCodigoPostal.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strCodigoPostal.errors?.pattern">Debe ingresar al menos 5 dígitos </div>
              </div>
            </div>
          </div>
          <div class="col-12 position-relative">
            <div class="form-group  mb-3">
                <label for="floatingInput">Estado</label>
              <input type="text"   placeholder="Ingrese Codigo Postal"  class="form-control valid fs13" readonly id="strEstado" formControlName="strEstado"
              [ngClass]="{'is-valid': f.strEstado.touched && f.strEstado.valid,'is-invalid': f.strEstado.touched && f.strEstado.invalid}" ><!--[(ngModel)]="strCodigoPostal" -->
              <div *ngIf="f.strEstado.touched && f.strEstado.invalid" class="text-danger">
                Ingrese un codigo postal correcto.
              </div>
            </div>
            <div *ngIf="spinnerCol" class="mb-2 col-12 d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
              <mat-spinner [diameter]="23"></mat-spinner>
            </div>
          </div>
          <div class="col-12 position-relative">
            <div class="form-group  mb-3">
                <label for="floatingInput">Ciudad</label>
              <input type="text"   placeholder="Ingrese Codigo Postal"  readonly class="form-control fs13" id="Ciudad" formControlName="strCiudad" 
              [ngClass]="{'is-valid': f.strCiudad.touched && f.strCiudad.valid,
              'is-invalid': f.strCiudad.touched && f.strCiudad.invalid}"><!--[(ngModel)]="strCiudad" -->
              
              <div *ngIf="f.strCiudad.touched && f.strCiudad.invalid" class="text-danger">
                Ingrese un codigo postal correcto.
              </div>
            </div>
            <div *ngIf="spinnerCol" class="mb-2 col-12 d-flex justify-content-center position-absolute top-50 start-50 translate-middle">
              <mat-spinner [diameter]="23"></mat-spinner>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group  mb-3">
                <label for="floatingSelect">Colonia</label>
              <select class="form-select fs13" id="floatingSelect" aria-label="Floating label select example" [ngClass]="{'is-valid': f.strColonia.touched && f.strColonia.valid, 'is-invalid': f.strColonia.touched && f.strColonia.invalid}" formControlName="strColonia">
                <option class="fs13" value="" selected>--Seleccione--</option>
                <option class="fs13" [value]="col.strColonia" *ngFor="let col of arrayColonias">{{col.strColonia}}</option>
              </select>
             
              <div *ngIf="f.strColonia.touched && f.strColonia.invalid" class="text-danger">
                El campo es obligatorio.
              </div>
          
            </div>
          </div>
          <div class="col-12">
            <div class="form-group  mb-3">
                <label for="floatingInput">Dirección (Calle y número)</label>
              <input type="text"   placeholder="Ingrese Calle y Numero"  class="form-control fs13" id="Direccion" formControlName="strDireccion"
              [ngClass]="{'is-valid': f.strDireccion.touched && f.strDireccion.valid,
              'is-invalid': f.strDireccion.touched && f.strDireccion.invalid}"><!--[(ngModel)]="strDireccion" -->
            
              <div *ngIf="f.strDireccion.touched && f.strDireccion.invalid" class="text-danger">
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="floatingInput">Entre Calles</label>
            <div class="form-group  mb-3">
              <input type="text" placeholder="Ingrese Entre Calles" class="form-control fs13" id="Casa" formControlName="strEntreCalles"
              [ngClass]="{'is-valid': f.strEntreCalles.touched && f.strEntreCalles.valid,
              'is-invalid': f.strEntreCalles.touched && f.strEntreCalles.invalid}"><!--[(ngModel)]="strEntreCalles"  -->
            </div>
          </div>
         
          <div class="col-12">
            <div class="form-group  mb-3">
                <label for="floatingInput">Teléfono</label>
              <input type="number" placeholder="Ingrese Telefono a 10 Digitos " [maxlength]="10"  class="form-control no-spin fs13" id="floatingInput" formControlName="strTelefono"
              [ngClass]="{'is-valid': f.strTelefono.touched && f.strTelefono.valid,
              'is-invalid': f.strTelefono.touched && f.strTelefono.invalid}"> <!-- [(ngModel)]="strTelefono" -->
              
              <div *ngIf="f.strTelefono.touched && f.strTelefono.invalid" class="text-danger">
                <div *ngIf="f.strTelefono.errors?.required">El campo es obligatorio.</div>
                <div *ngIf="f.strTelefono.errors?.pattern">Debe ser un número.</div>
              </div>
            </div>
          </div>





      <button type="submit" class="btn btn-primary" [disabled]="formAdress.invalid">
        Guardar
      </button>
  
      
    </form>
  </div>
 