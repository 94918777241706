import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EcommerceCarrService } from '../servicios/ecommerce-carr.service';
import { PeticionesService } from '../servicios/httpservice';
@Component({
  selector: 'app-ecommerce-perfil',
  templateUrl: './ecommerce-perfil.component.html',
  styleUrls: ['./ecommerce-perfil.component.css']
})
export class EcommercePerfilComponent implements OnInit {

  public userData_tienda:any=[];
  public user:any={};
  constructor(private cartService: EcommerceCarrService,private _httpService: PeticionesService, private router: Router) { }

  ngOnInit(): void {
    this.cartService.getUsr().subscribe((data) =>{
      this.userData_tienda = data?data:[]; 
      console.log(this.userData_tienda);
          if(!this.userData_tienda && this.userData_tienda.length==0){
            this.router.navigate(['/tlogin']);
          }else{
            this.user = this.userData_tienda[0];
          }
    })

  }

}
