<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="my-3 text-center">Pruebas</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 ">
            <div class="table-responsive mb-0 shadow borderbott bordertop">
                <table class="table table-bordered">
                    <thead class="">
                        <tr class="sticky-container bghead">
                            <th colspan="3" class="text-center border-end border-start  " >                            </th>
                            <th *ngFor="let i of arrayPeriodo;let ind = index" class="text-center border-end border-start  bghead" >
                                {{i.strMes}}
                            </th>
                        </tr>
                        <tr class="sticky-top2">
                            <th #articuloTD  class="text-center border-end border-start bghead py-0 sticky-left" >Articulo</th>
                            <th #descripcionTD class="text-center border-end border-start bghead py-0" [ngStyle]="{'position': 'sticky', 'left.px': anchoArticulo}"> <span class="px-2 ">Descripcion</span> </th>
                            <th class="text-center border-end border-start bghead py-0" [ngStyle]="{'position': 'sticky', 'left.px': anchodescripcion}">Linea</th>
                            <th *ngFor="let i of arrayPeriodo" class="text-center border-end border-start align-middle bghead">
                                <div class="col-12 d-flex">
                                    <div class="col px-2">Cantidad</div>
                                    <div class="col px-2">Importe</div>
                                    <div class="col px-2">SucuCte</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let x of arrayArticulos;let ind = index" [ngClass]="{'bgrow':ind %2 ==0,'bgrow2':ind %2 !=0}">
                            <td class="align-middle fs12 fw-bold  sticky-left py-0" style="position: sticky;left:0;">{{x.strArticulo}}</td>
                            <td  class="fs10 text-nowrap align-middle py-0"[ngStyle]="{'position': 'sticky', 'left.px': anchoArticulo}">
                                {{x.strDescripcion}}
                            </td>
                            <td class="align-middle fs12 text-center  py-0 fw-bold nunito" [ngStyle]="{'position': 'sticky', 'left.px': anchodescripcion}">{{x.strLinea}}</td>
                            <td class=" p-0 ">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold h-100 cls1">
                                        <span  [ngClass]="{'bgGreen':x.Enero && x.Enero.intCantidad >0,'bg-secondary':!x.Enero || x.Enero.intCantidad ==0}"> {{x.Enero ? x.Enero.intCantidad:0}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold border-start cls2">
                                        <span [ngClass]="{'bgGreen':x.Enero && x.Enero.dblImporte >0,'bg-secondary':!x.Enero || x.Enero.dblImporte ==0}"> {{(x.Enero ? x.Enero.dblImporte:0) | currency}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold border-start cls3">
                                        <span> {{x.Enero ? x.Enero.intSucursalesCte:0}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class=" col text-center fs12 fw-bold cls1" >
                                        <span [ngClass]="{'bgGreen':x.Febrero && x.Febrero.intCantidad >0,'bg-secondary':!x.Febrero || x.Febrero.intCantidad ==0}">
                                            {{x.Febrero ? x.Febrero.intCantidad:0}}
                                        </span>
                                    </div>
                                    <div class=" col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Febrero && x.Febrero.dblImporte >0,'bg-secondary':!x.Febrero || x.Febrero.dblImporte ==0}"> {{(x.Febrero ? x.Febrero.dblImporte:0) | currency}}</span>
                                    </div>
                                    <div class=" col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Febrero ? x.Febrero.intSucursalesCte:0}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class=" col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Marzo && x.Marzo.intCantidad >0,'bg-secondary':!x.Marzo || x.Marzo.intCantidad ==0}"> {{x.Marzo ? x.Marzo.intCantidad:0}}</span>  
                                    </div>
                                    <div class=" col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Marzo && x.Marzo.dblImporte >0,'bg-secondary':!x.Marzo || x.Marzo.dblImporte ==0}"> {{(x.Marzo ? x.Marzo.dblImporte:0) | currency}}</span> 
                                    </div>
                                    <div class=" col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Marzo ? x.Marzo.intSucursalesCte:0}}</span> 
                                    </div>
                                </div>
                            </td>
                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Abril && x.Abril.intCantidad >0,'bg-secondary':!x.Abril || x.Abril.intCantidad ==0}"> {{x.Abril ? x.Abril.intCantidad:0}}</span>
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Abril && x.Abril.dblImporte >0,'bg-secondary':!x.Abril || x.Abril.dblImporte ==0}"> {{(x.Abril ? x.Abril.dblImporte:0) | currency}}</span>
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Abril ? x.Abril.intSucursalesCte:0}}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Mayo && x.Mayo.intCantidad >0,'bg-secondary':!x.Mayo || x.Mayo.intCantidad ==0}"> {{x.Mayo ? x.Mayo.intCantidad:0}}</span>
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Mayo && x.Mayo.dblImporte >0,'bg-secondary':!x.Mayo || x.Mayo.dblImporte ==0}"> {{(x.Mayo ? x.Mayo.dblImporte:0 )| currency}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Mayo ? x.Mayo.intSucursalesCte:0}}</span> 
                                    </div>
                                </div>
                            </td>

                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Junio && x.Junio.intCantidad >0,'bg-secondary':!x.Junio || x.Junio.intCantidad ==0}"> {{x.Junio ? x.Junio.intCantidad:0}}</span>
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Junio && x.Junio.dblImporte >0,'bg-secondary':!x.Junio || x.Junio.dblImporte ==0}"> {{(x.Junio ? x.Junio.dblImporte:0) | currency}}</span>
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Junio ? x.Junio.intSucursalesCte:0}}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Julio && x.Julio.intCantidad >0,'bg-secondary':!x.Julio || x.Julio.intCantidad ==0}"> {{x.Julio ? x.Julio.intCantidad:0}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Julio && x.Julio.dblImporte >0,'bg-secondary':!x.Julio || x.Julio.dblImporte ==0}"> {{(x.Julio ? x.Julio.dblImporte:0) | currency}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Julio ? x.Julio.intSucursalesCte:0}}</span> 
                                    </div>
                                </div>
                            </td>

                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Agosto && x.Agosto.intCantidad >0,'bg-secondary':!x.Agosto || x.Agosto.intCantidad ==0}"> {{x.Agosto ? x.Agosto.intCantidad:0}}</span>
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Agosto && x.Agosto.dblImporte >0,'bg-secondary':!x.Agosto || x.Agosto.dblImporte ==0}"> {{(x.Agosto ? x.Agosto.dblImporte:0 )| currency}}</span>
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Agosto ? x.Agosto.intSucursalesCte:0}}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Septiembre && x.Septiembre.intCantidad >0,'bg-secondary':!x.Septiembre || x.Septiembre.intCantidad ==0}"> {{x.Septiembre ? x.Septiembre.intCantidad:0}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Septiembre && x.Septiembre.dblImporte >0,'bg-secondary':!x.Septiembre || x.Septiembre.dblImporte ==0}"> {{(x.Septiembre ? x.Septiembre.dblImporte:0) | currency}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Septiembre ? x.Septiembre.intSucursalesCte:0}}</span> 
                                    </div>
                                </div>
                            </td>

                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Octubre && x.Octubre.intCantidad >0,'bg-secondary':!x.Octubre || x.Octubre.intCantidad ==0}"> {{x.Octubre ? x.Octubre.intCantidad:0}}</span>
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Octubre && x.Octubre.dblImporte >0,'bg-secondary':!x.Octubre || x.Octubre.dblImporte ==0}"> {{(x.Octubre ? x.Octubre.dblImporte:0) | currency}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Octubre ? x.Octubre.intSucursalesCte:0}}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Noviembre && x.Noviembre.intCantidad >0,'bg-secondary':!x.Noviembre || x.Noviembre.intCantidad ==0}"> {{x.Noviembre ? x.Noviembre.intCantidad:0}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Noviembre && x.Noviembre.dblImporte >0,'bg-secondary':!x.Noviembre || x.Noviembre.dblImporte ==0}"> {{(x.Noviembre ? x.Noviembre.dblImporte:0) | currency}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Noviembre ? x.Noviembre.intSucursalesCte:0}}</span> 
                                    </div>
                                </div>
                            </td>

                            <td class="p-0">
                                <div class="col-12 d-flex my-1">
                                    <div class="col text-center fs12 fw-bold cls1">
                                        <span [ngClass]="{'bgGreen':x.Diciembre && x.Diciembre.intCantidad >0,'bg-secondary':!x.Diciembre || x.Diciembre.intCantidad ==0}"> {{x.Diciembre ? x.Diciembre.intCantidad:0}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls2 border-start">
                                        <span [ngClass]="{'bgGreen':x.Diciembre && x.Diciembre.dblImporte >0,'bg-secondary':!x.Diciembre || x.Diciembre.dblImporte ==0}"> {{(x.Diciembre ? x.Diciembre.dblImporte:0) | currency}}</span> 
                                    </div>
                                    <div class="col text-center fs12 fw-bold cls3 border-start">
                                        <span> {{x.Diciembre ? x.Diciembre.intSucursalesCte:0}}</span>
                                    </div>
                                </div>
                            </td>

                            
                        </tr>
                    </tbody>
                    
                </table>
            </div>
          
        </div>
    </div>
   
</div>