<div class="container">
    <div class="row mb-4">
        <div class="col-12 my-4">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active fw-bold nunito" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">OFERTAS</button>
                </li>
                <li class="nav-item mx-3" role="presentation">
                  <button class="nav-link fw-bold nunito" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">DESTACADOS</button>
                </li>
                <li class="nav-item " role="presentation">
                    <button class="nav-link fw-bold nunito" id="pills-linea-tab" data-bs-toggle="pill" data-bs-target="#pills-linea" type="button" role="tab" aria-controls="pills-linea" aria-selected="false" (click)="fntab()">POR LINEA</button>
                </li>
                <li class="nav-item mx-3" role="presentation">
                    <button class="nav-link fw-bold nunito" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-Porlinea" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" (click)="fnarrowtab()">PRECIO EXCLUSIVO EN LINEA</button>
                  </li>
            </ul>
            <div class="tab-content bg-white rounded" id="pills-tabContent">
                <div class="tab-pane fade show active py-3" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="col-12 pb-3">
                        <div class="col-12 text-center position-relative">
                            <h1 class="fw-bold my-2 text-center nunito">ADMINISTRAR OFERTAS</h1>
                        </div>
                        <div class="col-12 d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <mat-form-field appearance="outline" class="ps-3" >
                                    <mat-label>Linea</mat-label>
                                    <mat-select class="fs13 fw-bold" [(ngModel)]="strLinea" (selectionChange)="fnSeleccionarLinea()">
                                      <mat-option value="" class="fs13 fw-bold">TODAS</mat-option>
                                      <mat-option [value]="lin.strLinea" class="fs13 fw-bold" *ngFor="let lin of arrayLineasOfertas ">{{lin.strLinea}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button class="btn py-1 px-2 btn-outline-danger ms-2" (click)="fnEliminarLinea()">
                                    <i class="fa-solid fa-trash-can shadow-sm fs-4"></i>
                                </button>
                            </div>
                            <button type="button" class="btn btn-success  rounded fs12 me-2" (click)="fnLimpiarModal()" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i class="fa-solid fa-plus me-1"></i> Agregar
                            </button>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-11 px-0">
                            <div *ngIf="spinnerDest" >
                                <div class="col-12 d-flex justify-content-center my-3" >
                                    <mat-spinner></mat-spinner>
                                </div>
                            </div>
                            <div class="alert alert-danger text-center fw-bold" *ngIf="!spinnerDest && arrayProductosDestacados.length==0" role="alert">
                                No hay productos disponibles en este momento.
                            </div>
                            <div *ngIf="!spinnerDest && arrayProductosDestacados.length>0" class="table-responsive mb-0  shadow-sm px-0 roundedtop">
                                <table class="table table-striped table-bordered rounded">
                                    <thead>
                                        <tr class="th-center sticky-top">
                                            <th>Orden</th>
                                            <th>Articulo </th>
                                            <th>Descripcion </th>
                                            <th>Precio 4</th>
                                            <th>Precio Lista</th>
                                            <th>Tipo</th>
                                            <th>Precio Promocion</th>
                                            <th>Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let nov of arrayItemsDestacados " class="fs13"><!-- arrayNovedades -->
                                            <td class="align-middle">
                                                <div class="input-group mb-3">
                                                    <span class="input-group-text py-0 px-2 pointer" id="basic-addon1" (click)="fnUpdateOrder(nov,'OFERTA')">
                                                        <i class="bi bi-floppy-fill fs14 text-primary"></i>
                                                    </span>
                                                    <input type="number" [(ngModel)]="nov.dblCantidad" class="form-control text-center fs14">
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                              <div class="col-12 d-flex flex-column">                                              
                                                <img class="rounded shadow-sm mx-auto" style="width: 60px;height: 60px;"  [src]="nov.imageUrl" alt="{{ nov.strArticulo}}" >
                                                <span class="mx-auto">{{nov.strArticulo}}</span>
                                              </div>
                                            </td>
                                            <td class="align-middle fs12">{{nov.strDescripcion}}</td>
                                            <td class="text-end align-middle">
                                                {{nov.dblSaldo|currency}}
                                            </td>
                                            <td class="text-end align-middle">{{nov.dblPrecioLista|currency}}</td>
                                            <td class="align-middle text-center fw-bold" ><span class="rounded px-2 nunito" [ngClass]="{'bgOferta':nov.strTipo=='OFERTA','bgLiquidacion':nov.strTipo=='LIQUIDACION'}"> {{nov.strTipo}}</span></td>
                                            <td class="text-end align-middle fw-bold">{{nov.dblPrecioOferta  |currency}}</td>
                                            <td class="text-center align-middle">
                                                <button class="btn p-0" (click)="fnEliminarArtsOferta(nov)">
                                                    <i class="fa-solid fa-trash-can text-danger shadow-sm fs-4"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="" *ngIf="!spinnerDest && arrayProductosDestacados.length>0"><!--   -->
                                <div class="col-12 py-2 d-flex align-items-center paginator roundedBott" >
                                    <span class="ms-2 fw-bold">Total: {{arrayProductosDestacados?arrayProductosDestacados.length:0}}</span>
                                    <button class="btn btn-secondary me-2 py-0 px-1 ms-auto" [disabled]="currentPageDest === 1" (click)="changePageDest(currentPageDest - 1)"><i class="fa-solid fa-circle-chevron-left"></i></button>
                                    <span class="fs13">Página <span class="fw-bold">{{ currentPageDest }}</span> de <span class="fw-bold">{{ pagesDestacados }}</span> </span>
                                    <button class="btn btn-secondary ms-2 py-0 px-1" [disabled]="currentPageDest === pagesDestacados" (click)="changePageDest(currentPageDest + 1)"><i class="fa-solid fa-circle-chevron-right"></i></button>
                                    <span class="fs12 ms-3 me-2">Articulos por página </span>
                                    <div class="dropdown me-3">
                                        <button class="btn btn-secondary dropdown-toggle fs12" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                            {{itemsPerPageDest}}
                                        </button>
                                        <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenu2">
                                            <li *ngFor="let pag of arrayPages">
                                                <button class="dropdown-item" (click)="fnItemsPerPagedest(pag)" type="button">{{pag}}</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade py-3" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="col-12 d-flex pb-3">
                        <div class="col-12 text-center " >
                            <h1 class="fw-bold my-3 text-center nunito">ADMINISTRAR DESTACADOS</h1>
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <mat-form-field appearance="outline" class="ps-3" >
                                        <mat-label>Linea</mat-label>
                                        <mat-select class="fs13 fw-bold" [(ngModel)]="strLinea" (selectionChange)="fnSeleccionarLineaDest()">
                                          <mat-option value="" class="fs13 fw-bold">TODAS</mat-option>
                                          <mat-option [value]="lin.strLinea" class="fs13 fw-bold" *ngFor="let lin of arrayLineasDest ">{{lin.strLinea}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <button class="btn py-1 px-2 btn-outline-danger ms-2" (click)="fnEliminarLineaDest()">
                                        <i class="fa-solid fa-trash-can shadow-sm fs-4"></i>
                                    </button>
                                </div>
                                <button type="button" class="btn btn-success ms-auto rounded fs12 me-2" (click)="fnLimpiarModal()" data-bs-toggle="modal" data-bs-target="#ModalAddNuevos">
                                    <i class="fa-solid fa-plus me-1"></i> Agregar
                                </button>
                            </div>
                        </div>



                        



                    </div>
                    <div class="row justify-content-center">
                        <div class="col-11 px-0">
                            <div *ngIf="spinnerNuevo">
                                <div class="col-12 d-flex justify-content-center my-3" >
                                    <mat-spinner></mat-spinner>
                                </div>
                            </div>
                            <div class="alert alert-danger text-center fw-bold" *ngIf="!spinnerNuevo && arrayItemsNuevos.length==0" role="alert">
                                No hay productos disponibles en este momento.
                            </div>
                            <div *ngIf="!spinnerNuevo && arrayItemsNuevos.length>0" class="table-responsive mb-0  shadow-sm px-0 roundedtop">
                                <table class="table table-striped table-bordered rounded">
                                    <thead>
                                        <tr class="th-centerNew sticky-top ">
                                            <th>ORDEN</th>
                                            <th>Articulo </th>
                                            <th>Descripcion </th>
                                            <th>Precio 4</th>
                                            <th>Precio Lista </th>
                                            <th>Tipo</th>
                                            <th>Precio Promocion</th>
                                            <th>Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let nov of arrayItemsNuevos" class="fs13">
                                            <td class="align-middle">
                                                <div class="input-group mb-3">
                                                    <span class="input-group-text py-0 px-2 pointer" id="basic-addon1" (click)="fnUpdateOrder(nov,'DESTACADO')">
                                                        <i class="bi bi-floppy-fill fs14 text-primary"></i>
                                                    </span>
                                                    <input type="number" [(ngModel)]="nov.dblCantidad" class="form-control text-center fs14">
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                              <div class="col-12 d-flex flex-column">
                                                <img class="rounded shadow-sm mx-auto" style="width: 60px;height: 60px;"  [src]="nov.imageUrl" alt="{{ nov.strArticulo}}" (error)="onImageError($event)">
                                                <span class="mx-auto">{{nov.strArticulo}}</span>
                                              </div>
                                            </td>
                                            <td class="align-middle fs12">{{nov.strDescripcion}}</td>
                                            <td class="text-end align-middle">{{nov.dblSaldo|currency}}</td>
                                            <td class="text-end align-middle">{{nov.dblPrecioLista|currency}}</td>
                                            <td class="align-middle text-center">
                                                <span class="rounded fw-bold px-2 nunito" [ngClass]="{'bgOferta':nov.strTipo=='OFERTA','bgLiquidacion':nov.strTipo=='LIQUIDACION'}"> {{nov.strTipo}}</span>
                                            </td>
                                            <td class="text-end align-middle fw-bold">{{nov.dblPrecioOferta  |currency}}</td>
                                            <td class="text-center align-middle">
                                                <button class="btn p-0" (click)="fnEliminarArtsNuevos(nov)">
                                                    <i class="fa-solid fa-trash-can text-danger shadow-sm fs-4"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="" *ngIf="!spinnerNuevo && arrayItemsNuevos.length > 0">
                                <div class="col-12 py-2 d-flex align-items-center bgNuevos roundedBott">
                                    <span class="ms-2 fw-bold">Total: {{arrayNovedades?arrayNovedades.length:0}}</span>
                                    <button class="btn btn-secondary me-2 py-0 px-1 ms-auto" [disabled]="currentPage === 1" (click)="changePage(currentPage - 1)"><i class="fa-solid fa-circle-chevron-left"></i></button>
                                    <span class="fs13">Página <span class="fw-bold">{{ currentPage }}</span> de <span class="fw-bold">{{ totalPages }}</span> </span>
                                    <button class="btn btn-secondary ms-2 py-0 px-1" [disabled]="currentPage === totalPages" (click)="changePage(currentPage + 1)"><i class="fa-solid fa-circle-chevron-right"></i></button>
                                    <span class="fs12 ms-3 me-2">Articulos por página </span>
                                    <div class="dropdown me-3">
                                        <button class="btn btn-secondary dropdown-toggle fs12" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                            {{itemsPerPage}}
                                        </button>
                                        <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenu2">
                                            <li *ngFor="let pag of arrayPages">
                                                <button class="dropdown-item" (click)="fnItemsPerPage(pag)" type="button">{{pag}}</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade py-3" id="pills-linea" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="col-12 d-flex pb-3">
                        <div class="col-12 text-center position-relative">
                            <h1 class="fw-bold my-3 text-center nunito">ADMINISTRAR POR LINEA</h1>
                        <!--     <button type="button" class="btn btn-success ms-auto rounded fs12 me-2  position-absolute top-50 end-0 translate-middle-y" (click)="fnLimpiarModal()" data-bs-toggle="modal" data-bs-target="#ModalAddLineas">
                                <i class="fa-solid fa-plus me-1"></i> Agregar
                            </button> -->
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="d-flex align-items-center">
                                    <button class="btn btn-outline-secondary" (click)="scrollLeft()" [disabled]="!canScrollLeft" > &lt; </button>
                                    <div class="overflow-hidden flex-grow-1 px-2">
                                      <div class="d-flex" id="scrollContainer" #scrollContainer style="scroll-behavior: smooth; white-space: nowrap;" >
                                        <div *ngFor="let item of arrayLineas" (click)="fnActivelin(item.strLinea )" [ngClass]="{'btn-light':!item.active,'btn-primary':item.active}" class="btn  mx-2 fs12 fw-bold text-nowrap" >
                                          {{ item.strLinea }}
                                        </div>
                                      </div>
                                    </div>
                                    <button class="btn btn-outline-secondary" (click)="scrollRight()" [disabled]="!canScrollRight" >
                                      &gt;
                                    </button>
                                  </div>
                            </div>
                          <div class="col-12">
                              <div class="col-12 d-flex justify-content-between align-items-center">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Buscar:</mat-label>
                                      <input [(ngModel)]="inputSearch" (input)="fnFiltrarArtsAdd()" matInput placeholder="Articulo. Descripcion, etcc."><!-- (keydown.enter)="fnBuscarArticulo()"  -->
                                      <mat-icon matSuffix class="pointer" >search</mat-icon><!-- (click)="fnBuscarArticulo()" -->
                                  </mat-form-field>
                                  <mat-form-field appearance="outline">
                                    <mat-label>Asignar Descuento:</mat-label>
                                    <input  (input)="validarDescuento($event)"  [(ngModel)]="porcentajeDesc" type="number" (input)="fnFiltrarArtsAdd()" matInput placeholder="100">
                                    <mat-icon matSuffix class="pointer" >%</mat-icon>
                                </mat-form-field>
                              </div>
                          </div>
                         
                          <div class="col-12">
                              <div class="col-12 mt-2 py-2 text-center bgaddArt shadow-sm roundedtop">
                                  <h4 class="mb-0 text-center fw-bold">Agregados: {{arrayArtsInsertar?arrayArtsInsertar.length:0}}</h4>
                              </div>
                              <div class="h30 col-12 bg-light shadow-sm roundedBott">
                                  <div class="row w-100 sticky-top  bg-light">
                                      <div class="col-2">
                                      </div>
                                      <div class="col-2 fs12 d-flex align-items-center justify-content-center">
                                          <span class="fw-bold">Image</span>
                                      </div>
                                      <div class="col-2 fs12  d-flex align-items-center justify-content-center">
                                          <span class="fw-bold">Articulo</span>  
                                      </div>
                                      <div class="col-3 fs12 d-flex align-items-center justify-content-center">
                                          <span class="fw-bold">Tipo</span> 
                                      </div>
                                      <div class="col-3  d-flex align-items-center fs12 justify-content-center">
                                          <span class="fw-bold">Precio Oferta</span>
                                      </div>
                                  </div>
                                  <div class="row w-100"  *ngFor="let art of arrayArtsInsertar|filtersearch:inputSearch:['strArticulo'];let ind = index">
                                      <div class="col-2 d-flex justify-content-center align-items-center" [ngClass]="{'bgArt':art?.boolbg}">
                                          <button class="btn btn-danger" (click)="fneliminarBusqueda(art)"> 
                                              <i class="fa-regular fa-trash-can fs14" ></i>
                                          </button>
                                      </div>
                                      <div class="col-2 d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                                          <img class="rounded shadow-sm w-75" [src]="'https://www.cleanclean.mx/imagenes/'+art?.strArticulo+'.JPG'" alt="{{ art.strArticulo}}" (error)="onImageError($event)">
                                      </div>
                                      <div class="col-2 fs12  d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                                        <span>{{art.strArticulo}}</span>
                                      </div>
                                      <div class="col-3 fs10 d-flex py-2 flex-column" [ngClass]="{'bgArt':art?.boolbg}">
                                          <select name="" class="form-select fs11 mx-auto" (change)="onChangeTipo($event,art)" [(ngModel)]="art.strTipo" id="">
                                            <option value="OFERTA">Oferta</option>
                                            <option value="LIQUIDACION">Liquidación </option>
                                          </select>
                                          <span class="fs10  ms-auto">Precio Lista:</span>
                                      </div>
                                      <div class="col-3 d-flex flex-column py-2" [ngClass]="{'bgArt':art?.boolbg}">
                                          <input type="number" [disabled]="art.strTipo==''"  (input)="removeLeadingZeros($event,ind)"  [(ngModel)]="art.dblPrecioOferta" class="form-control fs11  mx-auto">
                                          <span class="fs10 me-auto fw-bold">{{(art.dblPrecioLista?art.dblPrecioLista:0)|currency}}</span>
                                      </div>
                                      <div class="col-12">
                                        <hr>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex my-2">
                            <button type="button" class="btn btn-primary fs14 ms-auto" [disabled]="!arrayArtsInsertar ||arrayArtsInsertar.length==0" (click)="fnGuardarArtsDest()">Guardar</button>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="tab-pane fade py-3" id="pills-Porlinea" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="col-12 d-flex pb-3">
                        <div class="col-12 text-center position-relative">
                            <h1 class="fw-bold mt-3 mb-2 text-center nunito">PRECIO EXCLUSIVOS EN LINEA</h1>
                            <!-- <button type="button" class="btn btn-success ms-auto rounded fs12 me-2  position-absolute top-50 end-0 translate-middle-y" (click)="fnLimpiarModal()" data-bs-toggle="modal" data-bs-target="#ModalAddLineas">
                                <i class="fa-solid fa-plus me-1"></i> Agregar
                            </button> -->
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="row">
                            <!-- <div class="col-12 mb-3">
                                <div class="d-flex align-items-center">
                                    <button class="btn btn-outline-secondary"  [disabled]="!canArrowLeft" (click)="fnarrowLeft()">
                                        &lt;
                                    </button>
                                    <div class="overflow-hidden flex-grow-1 px-2">
                                        <div class="d-flex" id="arrowContainer" #arrowContainer style="scroll-behavior: smooth; white-space: nowrap;" >
                                            <div *ngFor="let item of arrayLineas" (click)="fnActivelin(item.strLinea )" [ngClass]="{'btn-light':!item.active,'btn-primary':item.active}" class="btn  mx-2 fs12 fw-bold text-nowrap" >
                                                {{ item.strLinea }}
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-secondary"  [disabled]="!canArrowRight"  (click)="fnarrowRight()">
                                        &gt;
                                    </button>
                                  </div>
                            </div> -->
                            <div class="col-12">
                                <div class="col-12 d-flex justify-content-between align-items-center mb-3">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Buscar:</mat-label>
                                        <input [(ngModel)]="inputSearch" (input)="fnFiltrarArtsAdd()" (keydown.enter)="fnBuscarArticulo()" matInput placeholder="Articulo. Descripcion, etcc."><!--   -->
                                        <mat-icon matSuffix class="pointer" >search</mat-icon>
                                    </mat-form-field>
                                    <button type="button" class="btn btn-success ms-auto rounded fs12 me-2" (click)="fnModalExclusivos()" data-bs-toggle="modal" data-bs-target="#ModalExclusivoLinea">
                                        <i class="fa-solid fa-plus me-1"></i> Agregar
                                    </button>
                                <!--<mat-form-field appearance="outline">
                                        <mat-label>Asignar Descuento:</mat-label>
                                        <input  (input)="validarDescuento($event)"  [(ngModel)]="porcentajeDesc" type="number" (input)="fnFiltrarArtsAdd()" matInput placeholder="100">
                                        <mat-icon matSuffix class="pointer" >%</mat-icon>
                                    </mat-form-field> -->
                                </div>
                            </div>
                         
                            <div class="row justify-content-center">
                                <div class="col-11 px-0">
                                    <div *ngIf="spinnerExclusivo">
                                        <div class="col-12 d-flex justify-content-center my-3" >
                                            <mat-spinner></mat-spinner>
                                        </div>
                                    </div>
                                    <div class="alert alert-danger text-center fw-bold" *ngIf="!spinnerExclusivo && arrayItemsExclusivos.length==0" role="alert">
                                        No hay productos disponibles en este momento.
                                    </div>
                                    <div *ngIf="!spinnerExclusivo && arrayItemsExclusivos.length>0" class="table-responsive mb-0  shadow-sm px-0 roundedtop">
                                        <table class="table table-striped table-bordered rounded">
                                            <thead>
                                                <tr class="th-centerNew sticky-top ">
                                                    <th>ORDEN</th>
                                                    <th>Articulo </th>
                                                    <th>Descripcion </th>
                                                    <th>Precio 4</th>
                                                    <th>Precio Lista </th>
                                                    <th>Tipo</th>
                                                    <th>Precio Promocion</th>
                                                    <th>Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let nov of arrayItemsExclusivos" class="fs13">
                                                    <td class="align-middle">
                                                        <div class="input-group mb-3">
                                                            <span class="input-group-text py-0 px-2 pointer" id="basic-addon1" (click)="fnUpdateOrder(nov,'EXCLUSIVO')">
                                                                <i class="bi bi-floppy-fill fs14 text-primary"></i>
                                                            </span>
                                                            <input type="number" [(ngModel)]="nov.dblCantidad" class="form-control text-center fs14">
                                                        </div>
                                                    </td>
                                                    <td class="align-middle">
                                                      <div class="col-12 d-flex flex-column">
                                                        <img class="rounded shadow-sm mx-auto" style="width: 60px;height: 60px;" [src]="'https://www.cleanclean.mx/imagenes/'+nov?.strArticulo+'.JPG'"  alt="{{ nov.strArticulo}}" (error)="onImageError($event)"><!-- [src]="nov.imageUrl"  -->
                                                        <span class="mx-auto">{{nov.strArticulo}}</span>
                                                      </div>
                                                    </td>
                                                    <td class="align-middle fs12">{{nov.strDescripcion}}</td>
                                                    <td class="text-end align-middle">{{nov.dblSaldo|currency}}</td>
                                                    <td class="text-end align-middle">{{nov.dblPrecioLista|currency}}</td>
                                                    <td class="align-middle text-center">
                                                        <span class="rounded fw-bold px-2 nunito" [ngClass]="{'bgOferta':nov.strTipo=='OFERTA','bgLiquidacion':nov.strTipo=='LIQUIDACION'}"> {{nov.strTipo}}</span>
                                                    </td>
                                                    <td class="text-end align-middle fw-bold">{{nov.dblPrecioOferta  |currency}}</td>
                                                    <td class="text-center align-middle">
                                                        <button class="btn p-0" (click)="fnEliminarArtsExclusivos(nov)">
                                                            <i class="fa-solid fa-trash-can text-danger shadow-sm fs-4"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="" *ngIf="!spinnerExclusivo && arrayItemsExclusivos.length > 0">
                                        <div class="col-12 py-2 d-flex align-items-center bgNuevos roundedBott">
                                            <span class="ms-2 fw-bold">Total: {{arrayItemsExclusivos?arrayItemsExclusivos.length:0}}</span>
                                            <button class="btn btn-secondary me-2 py-0 px-1 ms-auto" [disabled]="currentPage === 1" (click)="changePage(currentPage - 1)"><i class="fa-solid fa-circle-chevron-left"></i></button>
                                            <span class="fs13">Página <span class="fw-bold">{{ currentPage }}</span> de <span class="fw-bold">{{ totalPages }}</span> </span>
                                            <button class="btn btn-secondary ms-2 py-0 px-1" [disabled]="currentPage === totalPages" (click)="changePage(currentPage + 1)"><i class="fa-solid fa-circle-chevron-right"></i></button>
                                            <span class="fs12 ms-3 me-2">Articulos por página </span>
                                            <div class="dropdown me-3">
                                                <button class="btn btn-secondary dropdown-toggle fs12" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {{itemsPerPage}}
                                                </button>
                                                <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenu2">
                                                    <li *ngFor="let pag of arrayPages">
                                                        <button class="dropdown-item" (click)="fnItemsPerPage(pag)" type="button">{{pag}}</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header py-2">
          <h2 class="modal-title mb-0 mx-auto fw-bold " id="exampleModalLabel">Agregar Productos en Oferta</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
                <div class="col-12 d-flex align-items-center">
                    <mat-form-field appearance="outline">
                        <mat-label>Buscar:</mat-label>
                        <input [(ngModel)]="inputSearch" (input)="fnFiltrarArtsAdd()" matInput placeholder="Articulo. Descripcion, etcc."><!-- (keydown.enter)="fnBuscarArticulo()"  -->
                        <mat-icon matSuffix class="pointer" >search</mat-icon><!-- (click)="fnBuscarArticulo()" -->
                    </mat-form-field>
               <!-- <div>
                        <button class="ms-3 btn btn-primary rounded-circle shadow-sm py-1 px-2" (click)="fnBuscarArticulo()">
                            <i class="bi bi-arrow-right-circle fs-5"></i>
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 mt-2 py-2 text-center bgSeleccionarArt shadow-sm roundedtop">
                    <h4 class="mb-0 text-center">Resultado de Busqueda: <span class="fw-bold">{{artsFiltrados?artsFiltrados.length:0}}</span></h4>
                </div>
                <div class="h30 col-12 bg-light shadow-sm roundedBott">
                    <div class="row w-100" *ngFor="let art of artsFiltrados">
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <img class="rounded shadow-sm w-75"  [src]="'https://www.cleanclean.mx/imagenes/'+art?.strArticulo.trim()+'.JPG'" alt="{{ art.strArticulo}}" (error)="onImageError($event)">
                        </div>
                        <div class="col-3 fs12  d-flex align-items-center justify-content-center">
                          <span>{{art.strArticulo}}</span>  
                        </div>
                        <div class="col-3 fs10 ">
                            {{art.strDescripcion}}
                        </div>
                        <div class="col-3  d-flex align-items-center justify-content-end">
                            <button class="btn btn-success fs12 py-0 px-1 fw-bold" (click)="fnInsertarArts(art)">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 mt-2 py-2 text-center bgaddArt shadow-sm roundedtop">
                    <h4 class="mb-0 text-center fw-bold">Agregados: {{arrayArtsInsertar?arrayArtsInsertar.length:0}}</h4>
                </div>
                <div class="h30 col-12 bg-light shadow-sm roundedBott">
                    <div class="row w-100 sticky-top  bg-light">
                        <div class="col-2">
                        </div>
                        <div class="col-2 fs12 d-flex align-items-center justify-content-center">
                            <span class="fw-bold">Image</span>
                        </div>
                        <div class="col-2 fs12  d-flex align-items-center justify-content-center">
                            <span class="fw-bold">Articulo</span>  
                        </div>
                        <div class="col-3 fs12 d-flex align-items-center justify-content-center">
                            <span class="fw-bold">Tipo</span> 
                        </div>
                        <div class="col-3  d-flex align-items-center fs12 justify-content-center">
                            <span class="fw-bold">Precio Oferta</span>
                        </div>
                    </div>
                    <div class="row w-100"  *ngFor="let art of arrayArtsInsertar;let ind = index">
                       
                        <div class="col-2 d-flex justify-content-center align-items-center" [ngClass]="{'bgArt':art?.boolbg}">
                            <button class="btn btn-danger" (click)="fneliminarBusqueda(art)"> 
                                <i class="fa-regular fa-trash-can fs14" ></i>
                            </button>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                            <img class="rounded shadow-sm w-75" [src]="'https://www.cleanclean.mx/imagenes/'+art?.strArticulo+'.JPG'" alt="{{ art.strArticulo}}" (error)="onImageError($event)">
                        </div>
                        <div class="col-2 fs12  d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                          <span>{{art.strArticulo}}</span>
                        </div>
                        <div class="col-3 fs10 d-flex py-2 flex-column" [ngClass]="{'bgArt':art?.boolbg}">
                            <select name="" class="form-select fs11 mx-auto" (change)="onChangeTipo($event,art)" [(ngModel)]="art.strTipo" id="">
                                <option value="">No Aplica</option>
                                <option value="LIQUIDACION">Liquidación </option>
                                <option value="OFERTA">Oferta</option>
                            </select>
                            <span class="fs10  ms-auto">Precio Lista:</span>
                        </div>
                        <div class="col-3 d-flex flex-column py-2" [ngClass]="{'bgArt':art?.boolbg}">
                            <input type="number" [disabled]="art.strTipo==''"  (input)="removeLeadingZeros($event,ind)"  [(ngModel)]="art.dblPrecioOferta" class="form-control fs11  mx-auto">
                            <span class="fs10 me-auto fw-bold">{{(art.dblPrecioLista?art.dblPrecioLista:0)|currency}}</span>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="modal-footer py-2">
          <button type="button" class="btn btn-secondary me-auto fs14" #btnCerrarModal data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary fs14" [disabled]="!arrayArtsInsertar ||arrayArtsInsertar.length==0" (click)="fnGuardarArtsDest()">Guardar</button>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="ModalAddNuevos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header py-2">
          <h2 class="modal-title mb-0 mx-auto fw-bold " id="exampleModalLabel">Agregar Productos Destacados</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
                <div class="col-12 d-flex align-items-center">
                    <mat-form-field appearance="outline">
                        <mat-label>Buscar:</mat-label>
                        <input [(ngModel)]="inputSearch" (input)="fnFiltrarNuevos()" matInput placeholder="Articulo. Descripcion, etcc."><!-- (keydown.enter)="fnBuscarArticulo()"  -->
                        <mat-icon matSuffix class="pointer" >search</mat-icon><!-- (click)="fnBuscarArticulo()" -->
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 mt-2 py-2 text-center bgSeleccionarArt shadow-sm roundedtop">
                    <h4 class="mb-0 text-center">Resultado de Busqueda: <span class="fw-bold">{{artsFiltrados?artsFiltrados.length:0}}</span></h4>
                </div>
                <div class="h30 col-12 bg-light shadow-sm roundedBott">
                    <div class="row w-100" *ngFor="let art of artsFiltrados">
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <img class="rounded shadow-sm w-75"  [src]="'https://www.cleanclean.mx/imagenes/'+art?.strArticulo+'.JPG'" alt="{{ art.strArticulo}}" (error)="onImageError($event)">
                        </div>
                        <div class="col-3 fs12  d-flex align-items-center justify-content-center">
                          <span>{{art.strArticulo}}</span>  
                        </div>
                        <div class="col-3 fs10 ">
                            {{art.strDescripcion}}
                        </div>
                        <div class="col-3  d-flex align-items-center justify-content-end">
                            <button class="btn btn-success fs12 py-0 px-1 fw-bold" (click)="fnInsertarArtsNuevos(art)">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 mt-2 py-2 text-center bgaddArt shadow-sm roundedtop">
                    <h4 class="mb-0 text-center fw-bold">Agregados: {{arrayArtsInsertar?arrayArtsInsertar.length:0}}</h4>
                </div>
                <div class="h30 col-12 bg-light shadow-sm roundedBott">
                    <div class="row w-100 sticky-top  bg-light">
                        <div class="col-2">
                        </div>
                        <div class="col-2 fs12 d-flex align-items-center justify-content-center">
                            <span class="fw-bold">Image</span>
                        </div>
                        <div class="col-2 fs12  d-flex align-items-center justify-content-center">
                            <span class="fw-bold">Articulo</span>  
                        </div>
                        <div class="col-3 fs12 d-flex align-items-center justify-content-center">
                            <span class="fw-bold">Tipo</span> 
                        </div>
                        <div class="col-3  d-flex align-items-center fs12 justify-content-center">
                            <span class="fw-bold">Precio Oferta</span>
                        </div>
                    </div>
                    <div class="row w-100"  *ngFor="let art of arrayArtsInsertar;let ind = index">
                       
                        <div class="col-2 d-flex justify-content-center align-items-center" [ngClass]="{'bgArt':art?.boolbg}">
                            <button class="btn btn-danger" (click)="fneliminarBusqueda(art)"> 
                                <i class="fa-regular fa-trash-can fs14" ></i>
                            </button>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                            <img class="rounded shadow-sm w-75" [src]="'https://www.cleanclean.mx/imagenes/'+art?.strArticulo+'.JPG'" alt="{{ art.strArticulo}}" (error)="onImageError($event)">
                        </div>
                        <div class="col-2 fs12  d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                          <span>{{art.strArticulo}}</span>
                        </div>
                        <div class="col-3 fs10 d-flex py-2 flex-column" [ngClass]="{'bgArt':art?.boolbg}">
                            <select name="" class="form-select fs11 mx-auto" [disabled]="true" (change)="onChangeTipo($event,art)" [(ngModel)]="art.strTipo" id="">
                                <option value="">NUEVO</option>
                               <!--  <option value="OFERTA">Oferta</option> -->
                            </select>
                            <span class="fs10  ms-auto">Precio Lista:</span>
                        </div>
                        <div class="col-3 d-flex flex-column py-2" [ngClass]="{'bgArt':art?.boolbg}">
                        <!--     <input type="number" [disabled]="art.strTipo==''" (input)="removeLeadingZeros($event,ind)"  [(ngModel)]="art.dblPrecioLista" class="form-control fs11  mx-auto"> -->
                            <span class="fs10 me-auto fw-bold">{{(art.dblPrecioLista?art.dblPrecioLista:0)|currency}}</span>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table">

                    </table>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer py-2">
          <button type="button" class="btn btn-secondary me-auto fs14" #btnCerrarModalNuevos data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary fs14" [disabled]="!arrayArtsInsertar ||arrayArtsInsertar.length==0" (click)="fnGuardarArtsNuevos()">Guardar</button>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="ModalAddLineas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header py-2">
            <h2 class="modal-title mb-0 mx-auto fw-bold " id="exampleModalLabel">Agregar Productos por Linea</h2>
            <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-12 my-3">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-secondary" (click)="scrollLeft()" [disabled]="!canScrollLeft" > &lt; </button>
                        <div class="overflow-hidden flex-grow-1 px-2">
                          <div class="d-flex" id="scrollContainer" #scrollContainer style="scroll-behavior: smooth; white-space: nowrap;" >
                            <div *ngFor="let item of arrayLineas" (click)="fnActivelin(item.strLinea )" [ngClass]="{'btn-light':!item.active,'btn-primary':item.active}" class="btn  mx-2 fs12 fw-bold text-nowrap" >
                              {{ item.strLinea }}
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-outline-secondary" (click)="scrollRight()" [disabled]="!canScrollRight" >
                          &gt;
                        </button>
                      </div>
                </div>
              <div class="col-12">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                        <mat-form-field appearance="outline">
                            <mat-label>Buscar:</mat-label>
                          <input [(ngModel)]="inputSearch" (input)="fnFiltrarArtsAdd()" matInput placeholder="Articulo. Descripcion, etcc."><!-- (keydown.enter)="fnBuscarArticulo()"  -->
                          <mat-icon matSuffix class="pointer" >search</mat-icon><!-- (click)="fnBuscarArticulo()" -->
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Asinar Descuento:</mat-label>
                        <input  (input)="validarDescuento($event)"  [(ngModel)]="porcentajeDesc" type="number" (input)="fnFiltrarArtsAdd()" matInput placeholder="100">
                        <mat-icon matSuffix class="pointer" >%</mat-icon>
                    </mat-form-field>
                  </div>
              </div>
             
              <div class="col-12">
                  <div class="col-12 mt-2 py-2 text-center bgaddArt shadow-sm roundedtop">
                      <h4 class="mb-0 text-center fw-bold">Agregados: {{arrayArtsInsertar?arrayArtsInsertar.length:0}}</h4>
                  </div>
                  <div class="h30 col-12 bg-light shadow-sm roundedBott">
                      <div class="row w-100 sticky-top  bg-light">
                          <div class="col-2">
                          </div>
                          <div class="col-2 fs12 d-flex align-items-center justify-content-center">
                              <span class="fw-bold">Image</span>
                          </div>
                          <div class="col-2 fs12  d-flex align-items-center justify-content-center">
                              <span class="fw-bold">Articulo</span>  
                          </div>
                          <div class="col-3 fs12 d-flex align-items-center justify-content-center">
                              <span class="fw-bold">Tipo</span> 
                          </div>
                          <div class="col-3  d-flex align-items-center fs12 justify-content-center">
                              <span class="fw-bold">Precio Oferta</span>
                          </div>
                      </div>
                      <div class="row w-100"  *ngFor="let art of arrayArtsInsertar|filtersearch:inputSearch:['strArticulo'];let ind = index">
                         
                          <div class="col-2 d-flex justify-content-center align-items-center" [ngClass]="{'bgArt':art?.boolbg}">
                              <button class="btn btn-danger" (click)="fneliminarBusqueda(art)"> 
                                  <i class="fa-regular fa-trash-can fs14" ></i>
                              </button>
                          </div>
                          <div class="col-2 d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                              <img class="rounded shadow-sm w-75" [src]="'https://www.cleanclean.mx/imagenes/'+art?.strArticulo+'.JPG'" alt="{{ art.strArticulo}}" (error)="onImageError($event)">
                          </div>
                          <div class="col-2 fs12  d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                            <span>{{art.strArticulo}}</span>
                          </div>
                          <div class="col-3 fs10 d-flex py-2 flex-column" [ngClass]="{'bgArt':art?.boolbg}">
                              <select name="" class="form-select fs11 mx-auto" (change)="onChangeTipo($event,art)" [(ngModel)]="art.strTipo" id="">
                                <option value="OFERTA">Oferta</option>  
                                <option value="LIQUIDACION">Liquidación </option>
                              </select>
                              <span class="fs10  ms-auto">Precio Lista:</span>
                          </div>
                          <div class="col-3 d-flex flex-column py-2" [ngClass]="{'bgArt':art?.boolbg}">
                              <input type="number" [disabled]="art.strTipo==''"  (input)="removeLeadingZeros($event,ind)"  [(ngModel)]="art.dblPrecioOferta" class="form-control fs11  mx-auto">
                              <span class="fs10 me-auto fw-bold">{{(art.dblPrecioLista?art.dblPrecioLista:0)|currency}}</span>
                          </div>
                          <div class="col-12">
                              <hr>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          <div class="modal-footer py-2">
            <!-- <button type="button" class="btn btn-secondary me-auto fs14" #btnCerrarModal data-bs-dismiss="modal">Cerrar</button> -->
            <button type="button" class="btn btn-primary fs14" [disabled]="!arrayArtsInsertar ||arrayArtsInsertar.length==0" (click)="fnGuardarArtExclusivos()">Guardar</button>
          </div>
      </div>
    </div>
</div>

<div class="modal fade" id="ModalExclusivoLinea" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header py-2">
          <h2 class="modal-title mb-0 mx-auto fw-bold " id="exampleModalLabel">PRECIO EXCLUSIVOS EN LINEA</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="col-12 d-flex align-items-center">
                        <mat-form-field appearance="outline">
                            <mat-label>Buscar:</mat-label>
                            <input [(ngModel)]="inputSearch" (input)="fnFiltrarNuevos()" matInput placeholder="Articulo. Descripcion, etcc."><!-- (keydown.enter)="fnBuscarArticulo()"  -->
                            <mat-icon matSuffix class="pointer" >search</mat-icon><!-- (click)="fnBuscarArticulo()" -->
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-6">
                    <div class="col-12 mt-2 py-2 text-center bgSeleccionarArt shadow-sm roundedtop">
                        <h4 class="mb-0 text-center">Resultado de Busqueda: <span class="fw-bold">{{artsFiltrados?artsFiltrados.length:0}}</span></h4>
                    </div>
                    <div class="h30 col-12 bg-light shadow-sm roundedBott">
                        <div class="row w-100" *ngFor="let art of artsFiltrados">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-3 d-flex align-items-center justify-content-center">
                                <img class="rounded shadow-sm w-75"  [src]="'https://www.cleanclean.mx/imagenes/'+art?.strArticulo+'.JPG'" alt="{{ art.strArticulo}}" (error)="onImageError($event)">
                            </div>
                            <div class="col-3 fs12  d-flex align-items-center justify-content-center">
                              <span>{{art.strArticulo}}</span>  
                            </div>
                            <div class="col-3 fs10 ">
                                {{art.strDescripcion}}
                            </div>
                            <div class="col-3  d-flex align-items-center justify-content-end">
                                <button class="btn btn-success fs12 py-0 px-1 fw-bold" (click)="fnInsertarArtsExclusivos(art)">Agregar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="col-12 mt-2 py-2 text-center bgaddArt shadow-sm roundedtop">
                        <h4 class="mb-0 text-center fw-bold">Agregados: {{arrayArtsInsertar?arrayArtsInsertar.length:0}}</h4>
                    </div>
                    <div class="h30 col-12 bg-light shadow-sm roundedBott">
                        <div class="row w-100 sticky-top  bg-light">
                            <div class="col-2">
                            </div>
                            <div class="col-2 fs12 d-flex align-items-center justify-content-center">
                                <span class="fw-bold">Image</span>
                            </div>
                            <div class="col-2 fs12  d-flex align-items-center justify-content-center">
                                <span class="fw-bold">Articulo</span>  
                            </div>
                            <div class="col-3 fs12 d-flex align-items-center justify-content-center">
                                <span class="fw-bold">Tipo</span> 
                            </div>
                            <div class="col-3  d-flex align-items-center fs12 justify-content-center">
                                <span class="fw-bold">Precio Oferta</span>
                            </div>
                        </div>
                        <div class="row w-100"  *ngFor="let art of arrayArtsInsertar;let ind = index">
                           
                            <div class="col-2 d-flex justify-content-center align-items-center" [ngClass]="{'bgArt':art?.boolbg}">
                                <button class="btn btn-danger" (click)="fneliminarBusqueda(art)"> 
                                    <i class="fa-regular fa-trash-can fs14" ></i>
                                </button>
                            </div>
                            <div class="col-2 d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                                <img class="rounded shadow-sm w-75" [src]="'https://www.cleanclean.mx/imagenes/'+art?.strArticulo+'.JPG'" alt="{{ art.strArticulo}}" (error)="onImageError($event)">
                            </div>
                            <div class="col-2 fs12  d-flex align-items-center justify-content-center" [ngClass]="{'bgArt':art?.boolbg}">
                              <span>{{art.strArticulo}}</span>
                            </div>
                            <div class="col-3 fs10 d-flex py-2 flex-column" [ngClass]="{'bgArt':art?.boolbg}">
                                <select name="" class="form-select fs11 mx-auto" [disabled]="true" (change)="onChangeTipo($event,art)" [(ngModel)]="art.strTipo" id="">
                                    <option value="EXCLUSIVO">EXCLUSIVO</option>
                                   <!--  <option value="OFERTA">Oferta</option> -->
                                </select>
                                <span class="fs10  ms-auto">Precio Lista:</span>
                            </div>
                            <div class="col-3 d-flex flex-column py-2" [ngClass]="{'bgArt':art?.boolbg}">
                                <input type="number"  (input)="removeLeadingZeros($event,ind)"  [(ngModel)]="art.dblPrecioOferta" class="form-control fs11  mx-auto">
                                <span class="fs10 me-auto fw-bold">{{(art.dblPrecioLista?art.dblPrecioLista:0)|currency}}</span>
                            </div>
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer py-2">
          <button type="button" class="btn btn-secondary me-auto fs14" #btnModalExclusi data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary fs14" [disabled]="!arrayArtsInsertar ||arrayArtsInsertar.length==0" (click)="fnGuardarArtExclusivos()">Guardar</button>
        </div>
      </div>
    </div>
</div>