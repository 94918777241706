<!--<app-menu></app-menu>-->
<br><br>
<div class='container'>
    <h1 class="title">Objetivo Mix<span class="title2">Vendedor </span></h1>
    <br>
    <div class="cien">
        <div class="col-sm-3">
            <div class="widget widget-rojo" *ngIf="strEstatus=='RECHAZADO'">
                <span class="material-icons">
                    assignment_late
                </span>
                <p>RECHAZADO</p>
            </div>
            <div class="widget widget-verde" *ngIf="strEstatus=='AUTORIZADO'">
                <span class="material-icons">
                    assignment_turned_in
                </span>
                <p>AUTORIZADO</p>
            </div>
            <div class="widget widget-azul" *ngIf="strEstatus=='ASIGNADO'">
                <span class="material-icons">
                    assignment_returned
                </span>
                <p>ASIGNADO</p>
            </div>
        </div>

        <div class="col-sm-5 ">
            <div class="widget widget-observaciones">
                <span class="material-icons">
                    description
                </span>
                <p>Observaciones: {{strObservaciones}} </p>
            </div>
        </div>
    </div>
    <br><br>
    <div class="row">
        <mat-form-field appearance="fill" class="matcell col-3">
            <mat-label>Objetivo Asignado </mat-label>
            <input matInput placeholder="0" value="{{dblObjetivoA | currency}}" disabled="true" name="first">
        </mat-form-field>
        <mat-form-field appearance="fill" class="matcell col-3">
            <mat-label>Objetivo Cubierto</mat-label>
            <input matInput placeholder="0" value="{{dblObjetivoG | currency}}" disabled="true" name="first">
        </mat-form-field>
        <mat-form-field appearance="fill" class="matcell col-3">
            <mat-label>Diferencia de Objetivos</mat-label>
            <input matInput placeholder="0" value="{{dblObjetivoD | currency}}" disabled="true" name="first">
        </mat-form-field>

        <button class="col-3" mat-raised-button style="  background-color: #3e6294;  color: white;height: 50px;    margin-top: -7px;
        " (click)="fnGuardar()" [disabled]="btnGuardarD && strEstatus=='AUTORIZADO'">Guardar</button>
    </div>
    <br><br>
    <div class="row seccion">
        <br><br><br>
        <div class="col-2"></div>
        <div class="col-8">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Linea</th>

                        <th scope="col">{{strNombreM13}}</th>
                        <th scope="col">{{strNombreM2}}</th>
                        <th scope="col">{{strNombreM1}}</th>
                        <th scope="col">%Objetivo</th>
                        <th scope="col">Objetivo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of arrayObjetivosAux; index as i; ">
                        <th scope="row">{{item.strLinea}}</th>
                        <td>{{item.mes3|currency}}</td>
                        <td>{{item.mes2|currency}}</td>
                        <td>{{item.mes1|currency}}</td>
                        <td>{{item.dblPsugerido|percent}}</td>
                        <td>
                            <div class="input-group mb-3">
                                <span class="input-group-text">$</span>
                                <!--<input (change)="fnSumar(item)" mvndrMatCurrencyFormat [currencyCode]="'USD'" [value]="item.dblObjetivo" [(ngModel)]="item.dblObjetivo" type="text" class="form-control" aria-label="Amount (to the nearest dollar)">-->
                                <input *ngIf="item.dblObjetivo >= 1" (change)="fnSumar($event,item)" mvndrMatCurrencyFormat [currencyCode]="'USD'" [value]="item.dblObjetivo" type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                                <input *ngIf="item.dblObjetivo <= 0 || item.dblObjetivo == undefined" (change)="fnSumar($event,item)" mvndrMatCurrencyFormat [currencyCode]="'USD'" [value]="0" type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                            </div>
                        </td>
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                        <td>Total venta mes anterior</td>
                        <td>{{dblTotalVenta | currency}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot> -->
            </table>
        </div>
    </div>
    <br><br><br>
</div>