import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';

export interface data {
  strIDPedido: string;
  strCliente: string;
  strClienteNombreCorto: string;
  strCteEnviarANombre: string;
  strFechaSolicitud: string;
  strAgenteNombre: string;
}

@Component({
  selector: 'app-cliente-solicitud-pedido',
  templateUrl: './cliente-solicitud-pedido.component.html',
  styleUrls: ['./cliente-solicitud-pedido.component.css']
})


export class ClienteSolicitudPedidoComponent implements OnInit {
  arrayEjercicio:any=[];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
  intEjercicio:number=0;
  intPeriodo:number; 
  inputSearch:string="";
  isHoveredButton: boolean = false;
  public btnRechazo:boolean = false;
  spinerPed: boolean = false;
  displayedColumns: string[] = ['strIDPedido','strCliente','strClienteNombreCorto','strCteEnviarANombre','strFechaSolicitud','strAgenteNombre'];
  dataSource: MatTableDataSource<data>;
  public strConexion:string="";
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public strAgente:string="";
  public arrayAccesos:any=[];
  public arrayPedidos:any =[]
  public strUsuario: string;
  public strObservaciones:string="";
  public boolRechazarPro:boolean=false;
  public strEstatus:string="POR AUTORIZAR"
  public strAgente_Crea: string;
  public strCliente:string="";
  public strFamilia:string="";
  public objModal:any={};
  public objCliente:any ={};
  public spinerModal:boolean=false;
  @ViewChild('btnCerrar') btnCerrar!: ElementRef;
  public objEncabezado:any ={};
  public boolEditaAgente: boolean = true;
  public tagName:any;
  public strCteEnviarA:string;
  public arrayPedidos_filter :any = [];
  public btnAutorizar:boolean=false;
  constructor(private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, 
      elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
       private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
        
        this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
        let fecha = new Date();
        this.intEjercicio = fecha.getFullYear();
        this.intPeriodo = fecha.getMonth() + 1;
        this.strConexion = this.localSt.retrieve('strConexion');
        this.tagName = elem.nativeElement.tagName.toLowerCase();

}

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) { 
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        if(objAcceso[0].strComponente == this.tagName){
          
          this.strCteEnviarA = this.arrayAccesos[0].strCteEnviarA ? this.arrayAccesos[0].strCteEnviarA:'';
          this.objCliente = this.arrayAccesos[0]; 
          this.strFamilia = this.objCliente.strFamiliaCte;
          this.strCliente = this.objCliente.strCliente;
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgente_Crea = this.localSt.retrieve('strAgente');
          this.strAgente = this.localSt.retrieve('strAgente');
          this.strUsuario = this.localSt.retrieve('strUsuario');
          this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;
          this.btnAutorizar = objAcceso.find((o: any) => o.strAccion == 'btnAutorizar') ? true : false;
          //------------conexi+on-------------------
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
          this.fnGetPedidos();
        }else{
          let urlHome = this.arrayAccesos[0].strUrlHome;
          this.router.navigate(['/'+urlHome]);
        }
      }
      else {
        this.router.navigate(['/login']);
      }
    });
  this.fnGetFiltros(); 
  }

  fntabla(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {

      this.arrayAgentes = nuevoValor;
    });
  }


  fnGetPedidos(){
    this.spinerPed= true;
    this.arrayPedidos = [];
    this.arrayPedidos_filter = [];
    let objEnv = {
      strEjercicio: this.intEjercicio,
      strPeriodo: this.intPeriodo,
      strCliente:this.strCliente,
      strFamilia:this.strFamilia,
      strConexion:this.strConexion
    };
 
    this._httpService.getClienteDatos(objEnv,"getSolicitudesPedidosCliente").subscribe(
      result => {
        this.arrayPedidos = result;
        
        var arrayInsert = [];
        if(this.strCteEnviarA !='' && this.strCteEnviarA !='0'){ //si ingresa con el perfil de una sucursal de cliente
          arrayInsert = this.arrayPedidos.filter(o => o.strEstatus == this.strEstatus && o.strCteEnviarA ==this.strCteEnviarA );
        }
        else {
            arrayInsert = this.arrayPedidos.filter(o => o.strEstatus == this.strEstatus);
        }
       this.arrayPedidos_filter=arrayInsert;
        this.spinerPed= false;
        this.dataSource = new MatTableDataSource(arrayInsert);
        this.fntabla()
      }, error=>{
        this.spinerPed= false;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  fnFiltrar(){ 

    var arrayInsert = [];
    if(this.strCteEnviarA !='' && this.strCteEnviarA !='0'){ //si ingresa con el perfil de una sucursal de cliente
      arrayInsert = this.arrayPedidos.filter(o => o.strEstatus == this.strEstatus && o.strCteEnviarA ==this.strCteEnviarA );
    }
    else {
        arrayInsert = this.arrayPedidos.filter(o => o.strEstatus == this.strEstatus);
    }

    this.spinerPed= false;
    this.dataSource = new MatTableDataSource(arrayInsert);
    this.fntabla()
  }

  fnAbrirModal(obj:any){
 
    this.objModal = {};
    this.boolRechazarPro = false;
    this.objModal.strEstatus = obj.strEstatus;
    this.objModal.pedido = obj.strIDPedido;
    this.spinerModal = true;
    this.objModal.objAny = obj;
    this.objEncabezado = {};
    let objEnv = {
      strIDPedido: obj.strIDPedido,
      strConexion:this.strConexion
    };


    this._httpService.getClienteDatos(objEnv,"getSolicitudPedidosClienteDetalle").subscribe(
      result => {

        this.objEncabezado = result[0];
        this.spinerModal= false;
      }, error=>{
        this.spinerModal= false;
      });


      let objEnv2 = {
        strIDPedido: obj.strIDPedido,
        strConexion:this.strConexion
      };
  
      this._httpService.getClienteDatos(objEnv2,"getPedidoClienteArticulos").subscribe(
        result => {
   
          this.objModal.arrayPedido = result;
          if(this.objModal.arrayPedido.length>0){
            this.objModal.totalPrecio = 0
            this.objModal.arrayPedido.forEach(obj => {
              this.objModal.totalPrecio = (this.objModal.totalPrecio + (obj.dblPrecio * obj.dblCantidad)) 
            });
            this.objModal.iva = this.objModal.totalPrecio * .16
          }
          this.spinerModal= false;
        }, error=>{
          this.spinerModal= false;
        });
  }

  fnRechazarProd(accion:string,obj:any={}){
    this.objModal.objCancel = {}
    this.objModal.tituloRechazo  = ""
    if(accion == "cancelProd"){
      this.objModal.objCancel = obj
      this.btnRechazo = false;
      this.objModal.tituloRechazo = obj.strArticulo ? "Rechazo de articulo: "+obj.strArticulo:"";
      this.boolRechazarPro = true
    }else{
      this.boolRechazarPro = false
    }
  }

  fnguardarRechazoArtSolicitud(){ 
   if(this.strObservaciones !=""){
    let objEnv2 = {
      strIDPedido: this.objModal.pedido,
      strConexion:this.strConexion,
      strEstatus:this.objModal.strEstatus,
      strMotivoRechazo:this.strObservaciones
    };

    this._httpService.getClienteDatos(objEnv2,"setRechazarPedidoD").subscribe(
      result => {
        Swal.fire("Enviado","Se guardo correctamente","success")

        this.fnAbrirModal(this.objModal.objAny);
      }, error =>{
        Swal.fire("ERROR","Error al guardar","error")
      }) 
   }else{
    Swal.fire("Enviado","Debe escribir el motivo de rechazo en el campo observaciones","error")
   }
  }


  fnguardarRechazoSolicitud(tipo:string){
    let objEnv2 = {}
    if((tipo=="autorizado") || (tipo=="rechazado" && this.strObservaciones != "")){
      objEnv2 = {
        strCliente:this.objEncabezado.strCliente,
        strCteEnviarA:this.objEncabezado.strCteEnviarA,
        strFechaRequerida:this.objEncabezado.strFechaRequerida.substring(0, 10),
        dblImporte:this.objModal.totalPrecio,
        dblImpuestos:this.objModal.iva,
        strListaPreciosEsp:'LP'+this.objEncabezado.strCliente,
        strReferencia:this.objEncabezado.strReferencia,
        strOrdenCompra:this.objEncabezado.strOrdenCompra,
        strObservaciones:this.objEncabezado.strObservaciones,
        strAtencion:'WEB '+this.objModal.pedido,
        strOrdenCompraAdjunto:this.objEncabezado.strordenCompraDocumento,
        strIDPedido:this.objModal.pedido,
        strEstatus:tipo=="autorizado"?"AUTORIZADO":"RECHAZADO",
        strMotivoRechazo:this.strObservaciones,
        strAutorizo:this.strUsuario
      };


      this._httpService.getClienteDatos(objEnv2,"setInsertarPedidoCliente").subscribe(
        result => {

          Swal.fire("Enviado","Se guardo correctamente","success")
          this.strEstatus = "POR AUTORIZAR"
          this.fnGetPedidos()
          this.btnCerrar.nativeElement.click();

        }, error =>{
          Swal.fire("ERROR","Error al guardar","error")
        }) 
    }else{
      Swal.fire("Error al guardar","Favor de llenar observaciones de rechazo","error")
    }

  


    


/*     'strCliente': arrayEncabezado.strCliente, 
    'strCteEnviarA': arrayEncabezado.strCteEnviarA, 
    'strFechaRequerida': fecha, 
    'dblImporte':$scope.Total, 
    'dblImpuestos':$scope.IVA, 
    'strListaPreciosEsp':'LP'+arrayEncabezado.strCliente, 
    'strReferencia': arrayEncabezado.strReferencia, 
    'strOrdenCompra':arrayEncabezado.strOrdenCompra, 
    'strObservaciones':arrayEncabezado.strObservaciones, 
    'strAtencion': 'WEB '+$scope.strIDPedido, 
    'strOrdenCompraAdjunto':arrayEncabezado.strordenCompraDocumento, 
    'strIDPedido':$scope.strIDPedido,
    'strEstatus':status,
    'strMotivoRechazo':$scope.ngMotivoRechazo, 
    'strAutorizo':$scope.$storage.Usuario}; */



/*     if(this.strObservaciones !=""){
     let objEnv2 = {
       strIDPedido: this.objModal.pedido,
       strConexion:this.strConexion,
       strEstatus:this.objModal.strEstatus,
       strMotivoRechazo:this.strObservaciones
     };
     console.log(objEnv2)
     this._httpService.getClienteDatos(objEnv2,"setRechazarPedidoD").subscribe(
       result => {
         Swal.fire("Enviado","Se guardo correctamente","success")
         console.log(result)
         this.fnAbrirModal(this.objModal.objAny);
       }, error =>{
         Swal.fire("ERROR","Error al guardar","error")
       }) 
    }else{
     Swal.fire("Enviado","Debe escribir el motivo de rechazo en el campo observaciones","error")
    } */
   }

   fnRechazarSol(accion:string,obj:any={}){
    this.objModal.objCancel = {}

    this.strObservaciones 
    this.objModal.tituloRechazo  = ""
    if(accion == "cancelSol"){
      this.objModal.objCancel = obj
      this.btnRechazo = true;
      this.objModal.tituloRechazo = obj.strArticulo ? "Rechazo de solicitud: "+obj.strArticulo:"";
      this.boolRechazarPro = true;
    }else{
      this.boolRechazarPro = false;
    }
  }
}
