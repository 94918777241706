<div class="container">
    <button class="btn btn-primary d-none" id="btnModalInicio" data-bs-toggle="modal"
        data-bs-target="#MConcluirActividadesInicio">fd</button>
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold titulo text-decoration-underline">Dashboard</h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-3 d-none d-md-flex">
        <div class="col-12 bgtable py-1 mb-2 d-flex bordertop">

            <i class="fa-solid fa-arrow-up-wide-short"></i>
            <span class="fw-bold">
                Filtros
            </span>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
                <mat-select [(ngModel)]="strGerente" class="" (selectionChange)="onGerenteChange('Ger')">
                    <mat-option value=""> --Todos --</mat-option>
                    <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
            [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                <mat-select [(ngModel)]="strSucursal" class="" (selectionChange)="onGerenteChange('Suc')">
                    <mat-option value=""> --Todas --</mat-option>
                    <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente "
                        [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
            [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon> Agente </mat-label>
                <mat-select [(ngModel)]="strAgente" class="">
                    <mat-option value=""> --Todos --</mat-option>
                    <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
                        [value]="x.strAgente">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center"
            [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
                <mat-select [(ngModel)]="intPeriodo" class="">
                    <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"
            [ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center"
            [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }">
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGet_todo()"
                (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"
                    [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div class="row d-md-none pb-3">
        <div class="col-12 text-end">
            <button class="btn btn-outline-secondary  dropdown-toggle justify-content-center " type="button"
                data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" matTooltip="Opciones de filtrado">
                <i class="fas fa-filter"></i> Filtros
            </button>
        </div>
        <div class="offcanvas offcanvas-bottom offcanvas-custom-height" tabindex="-1" id="offcanvasTop"
            aria-labelledby="offcanvasTopLabel">
            <div class="offcanvas-body large">
                <div class="row">
                    <div class="col-12 d-flex">
                        <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                            #btncerrarFiltros aria-label="Close"></button>
                    </div>
                    <div class="col-12 d-flex flex-column" *ngIf="boolGerente">
                        <label for="">Gerente</label>
                        <select class="form-select form-select-lg mb-3" [(ngModel)]="strGerente"
                            aria-label=".form-select-lg example">
                            <option value="" selected>--Seleccione--</option>
                            <option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</option>
                        </select>
                    </div>
                    <div class="col-12" *ngIf="boolSucursal">
                        <label for="">Sucursal</label>
                        <select [(ngModel)]="strSucursal" class="form-select form-select-lg mb-3"
                            aria-label=".form-select-lg example">
                            <option value="" selected>--Seleccione--</option>
                            <option *ngFor="let x of arraySucursales | sucursalFilter:strGerente"
                                [value]="x.strSucursal">{{x.strNombreSucursal}}</option>
                        </select>
                    </div>
                    <div class="col-12" *ngIf="boolAgente">
                        <label for="">Agente </label>
                        <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                            [(ngModel)]="strAgente">
                            <option value="" selected>--Seleccione--</option>
                            <option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal"
                                [value]="x.strAgente">{{x.strNombre}}</option>
                        </select>
                    </div>
                    <div class="col-12">
                        <label for="">Periodo</label>
                        <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                            [(ngModel)]="intPeriodo">
                            <option value="" selected>--Seleccione--</option>
                            <option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</option>
                        </select>
                    </div>
                    <div class="col-12">
                        <label for="">Ejercicio</label>
                        <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                            [(ngModel)]="intEjercicio">
                            <option value="" selected>--Seleccione--</option>
                            <option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">{{e.ejercicio}}</option>
                        </select>
                    </div>
                    <div class="col-12 mt-2 pt-1   d-flex justify-content-center align-items-center">
                        <button class="btn btnFilter shadow-sm px-2 py-1" (mouseenter)="isHoveredButton = true"
                            (mouseleave)="isHoveredButton = false" (click)="fnGet_todo()">
                            <i class="bi  p-1 fs-4"
                                [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-info-square iconoTabs mx-2"></i> <span class="m-auto">Leads</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row p-2 my-3 d-flex nunito">
                            <div
                                class="col-12 col-md-6 col-lg-3 d-flex justify-content-center mb-4 justify-content-md-start ps-md-0 aling-items-center">
                                <div class="col-12 borderbott bordertop bg-white shadow d-flex flex-column">
                                    <div class="col-12 text-end mt-1 position-relative">
                                        <span class="position-absolute top-50 start-0 translate-middle-y ms-3 fs13">
                                            Total:
                                            <span class="fw-bolder">
                                                {{arrayActAtrasadas.length}}
                                            </span>
                                        </span>
                                        <span class="ps-3 pe-2 py-2 clsVencido fs11 fw-bold btE bbl">Actividades
                                            vencidas
                                        </span>
                                    </div>
                                    <div class="col-12 m-auto" *ngIf="loader">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 m-auto d-flex justify-content-center"
                                        *ngIf="arrayActAtrasadas.length<1 && !loader">
                                        <div class="col-10 bg-light shadow-sm rounded text-center py-1">
                                            <div class="col-12 text-center my-4 position-relative"
                                                *ngIf="arrayActAtrasadas.length<1">
                                                <i class="fa-solid fa-calendar-xmark clsIconVencido fs-2"></i>
                                            </div>
                                            <span class="fw-bold text-dark fs13">No cuenta con actividades
                                                vencidas</span>
                                        </div>
                                    </div>
                                    <!-- scrollY -->
                                    <div class="col-12  my-2 mt-2" *ngIf="arrayActAtrasadas.length>0 && !loader">
                                        <div class="col-12 d-flex justify-content-center my-2 "
                                            *ngFor="let x of arrayTotalAtrasdas">
                                            <div class="col-9 rounded shadow-sm text-danger bgCards d-flex align-items-center py-3 mb-1"
                                                data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                (click)="fnmDLConAct(x,'Vencidas')">
                                                <div class="col-3 text-center">
                                                    <i class="bi  fs-5"
                                                        [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada','bi-person-walking':x.strTipo=='Visita','bi-envelope-fill':x.strTipo=='Correo','bi-list-task':x.strTipo=='Tarea'}"></i>
                                                </div>
                                                <div class="col-9 d-flex ">
                                                    <span class="fs14 fw-bold ">{{x.intContador}}</span>
                                                    <span
                                                        class="fs13 ms-2">{{x.strTipo=='Llamada'?'Llamadas':x.strTipo=='Visita'?'Visitas':x.strTipo=='Tarea'?'Tareas':'Correos'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center mb-4 mt-3 mt-md-0 justify-content-lg-center justify-content-md-start ps-md-0 ps-lg-2">
                                <div class="col-12 borderbott bordertop bg-white shadow text-center d-flex flex-column">
                                    <div class="col-12 text-end mt-1 position-relative">
                                        <span class="position-absolute top-50 start-0 translate-middle-y ms-3 fs13">
                                            Total:
                                            <span class="fw-bolder">
                                                {{arrayActHoy.length}}
                                            </span>
                                        </span>
                                        <span class="ps-3 pe-2 py-2 clsHoy fs11 fw-bold btE bbl">
                                            Actividades para hoy
                                        </span>
                                    </div>
                                    <div class="col-12 m-auto" *ngIf="loader">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 m-auto d-flex justify-content-center align-items-center"
                                        *ngIf="arrayActHoy.length < 1 && !loader">
                                        <div class="col-10 bg-light shadow-sm rounded text-center py-1">
                                            <div class="col-12 m-auto text-center my-4" *ngIf="arrayActHoy.length < 1">
                                                <i class="bi bi-calendar-check-fill fs-2 text-success"></i>
                                            </div>
                                            <span class="fw-bold text-dark fs13">No cuenta con actividades para
                                                hoy</span>
                                        </div>
                                    </div>
                                    <!--  scrollY  -->
                                    <div class="col-12 mt-2 my-2" *ngIf="arrayActHoy.length > 0 && !loader">
                                        <div class="col-12 d-flex justify-content-center my-2"
                                            *ngFor="let x of arrayTotalHoy">
                                            <div class="col-10 rounded shadow-sm text-success bgCards d-flex align-items-center justify-content-center py-3 mb-1"
                                                data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                (click)="fnmDLConAct(x,'Para Hoy')">
                                                <div class="col-3 text-center">
                                                    <i class="bi fs-5"
                                                        [ngClass]="{'bi-telephone-fill': x.strTipo == 'Llamada', 'bi-person-walking': x.strTipo == 'Visita', 'bi-envelope-fill': x.strTipo == 'Correo','bi-list-task':x.strTipo=='Tarea'}"></i>
                                                </div>
                                                <div class="col-9 d-flex align-items-center justify-content-center">
                                                    <span class="fs14 fw-bold">{{x.intContador}}</span>
                                                    <span class="fs13 ms-2">{{x.strTipo == 'Llamada' ? 'Llamadas' :
                                                        x.strTipo == 'Visita' ? 'Visitas' :x.strTipo=='Tarea'?'Tareas':
                                                        'Correos'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-12 col-md-6 col-lg-3 d-flex justify-content-center mb-4 justify-content-lg-center justify-content-md-start  mt-3 mt-md-0  ps-0">
                                <div class="col-12 borderbott bordertop bg-white shadow d-flex flex-column"
                                    #infoGralDiv>
                                    <div class="col-12 text-end mt-1 position-relative">
                                        <span class="position-absolute top-50 start-0 translate-middle-y ms-2 fs13">
                                            Total:
                                            <span class="fw-bolder">
                                                {{arrayActProximas.length}}
                                            </span>
                                        </span>
                                        <span class="ps-3 pe-2 py-2 clsProxima fs11 fw-bold btE bbl">
                                            Actividades próximas
                                        </span>
                                    </div>
                                    <div class="col-12 m-auto" *ngIf="loader">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 d-flex justify-content-center my-auto"
                                        *ngIf="arrayActProximas.length<1 && !loader">
                                        <div class="col-10 bg-light shadow-sm rounded text-center py-1">
                                            <div class="col-12 text-center my-4">
                                                <i class="bi bi-calendar-week-fill clsIconProxima fs-2"></i>
                                            </div>
                                            <span class="fw-bold text-dark fs13">
                                                No cuenta con actividades próximas
                                            </span>
                                        </div>
                                    </div>
                                    <!-- scrollY -->
                                    <div class="col-12  mt-2 my-2" *ngIf="arrayActProximas.length>0 && !loader">
                                        <div class="col-12 d-flex justify-content-center my-2"
                                            *ngFor="let x of arrayTotalProximas">
                                            <div class="col-9  rounded shadow-sm  bgCards clsProxima  d-flex align-items-center py-3 mb-1"
                                                data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                (click)="fnmDLConAct(x,'Proximas')">
                                                <div class="col-3 text-center">
                                                    <i class="bi  fs-5"
                                                        [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada','bi-person-walking':x.strTipo=='Visita','bi-envelope-fill':x.strTipo=='Correo','bi-list-task':x.strTipo=='Tarea'}"></i>
                                                </div>
                                                <div class="col-9 d-flex">
                                                    <span class="fs14 fw-bold ">{{x.intContador}}</span>
                                                    <span
                                                        class="fs13 ms-2">{{x.strTipo=='Llamada'?'Llamadas':x.strTipo=='Visita'?'Visitas':x.strTipo=='Tarea'?'Tareas':'Correos'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 col-md-6  col-lg-3 d-flex justify-content-center pe-lg-0 mt-3 mt-lg-0 mb-4 justify-content-md-end pe-md-0">
                                <div class="col-12  borderbott bordertop bg-white shadow d-flex flex-column">
                                    <div class="col-12 text-end  mb-1 d-flex">
                                        <span class=" me-auto mt-1 ms-2 fs13">
                                            Total:
                                            <span class="fw-bolder">
                                                {{arrayMinutas.length}}
                                            </span>
                                        </span>
                                        <span class="ps-3 pe-2 py-2 clsMinutas fw-bold btE bbl"
                                            style="font-size: 10px;">Minutas</span>
                                    </div>
                                    <div class="col-12 m-auto" *ngIf="loader">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    <div [style.max-height.px]="altInfo" style="overflow-y: auto;">
                                        <div class="col-12 d-flex justify-content-center my-2 "
                                            *ngFor="let x of arrayMinutas; let i = index">
                                            <div (click)="enviarMinuta(x)"
                                                class="col-11 rounded shadow-sm  bgCards clsMinuta  d-flex align-items-center py-1">
                                                <div class="col-12 d-flex text-start">
                                                    <div class="col-2 d-flex justify-content-center align-items-center">
                                                        {{ i + 1 }}
                                                    </div>
                                                    <span
                                                        class="fs14 fw-bold  col-10 row d-flex justify-content-start aling-items-center">
                                                        <span class="mb-1 fw-bold">{{x?.strNombre}}</span>
                                                        <span class="mb-1 fs10">{{x?.strTitulo}}</span>
                                                        <span class="fs11 12"><span
                                                                class="badge text-bg-info text-white">{{x?.strEstatus}}</span></span>
                                                        <span
                                                            class="col-12 fs11 ms-auto textFechaMinuta">{{x?.strFechaRequerida
                                                            |date:'dd/MM/yyyy'}}</span>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 m-auto d-flex justify-content-center"
                                        *ngIf="arrayMinutas.length<1 && !loader">
                                        <div class="col-10 bg-light shadow-sm rounded text-center py-1">
                                            <div class="col-12 text-center my-3">

                                                <i class="bi bi-file-earmark-medical clsIconMinuta fs-1"></i>
                                            </div>
                                            <span class="fw-bold text-dark fs13">No cuenta con minutas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 col-md-6 col-lg-3 d-flex justify-content-center mx-auto justify-content-md-start ps-md-0 mt-3 mt-md-0 mb-4">
                                <div class="col-12 borderbott bordertop bg-white shadow ">
                                    <div class="col-12 text-end mt-1 position-relative">
                                        <span class="position-absolute top-50 start-0 translate-middle-y ms-3 fs13">
                                            Sueños de ventas
                                        </span>
                                        <span class="ps-3 pe-2 py-2 clsVenta fs12 fw-bold btE bbl"><i
                                                class="bi bi-currency-dollar mx-1"></i>Ventas</span>
                                    </div>
                                    <div class="col-12">
                                        <div class="sales-container">
                                            <p></p>
                                            <div class="chart">
                                                <div class="donut-chart"
                                                    [ngStyle]="{ 'background': conicGradientStyle }"></div>
                                                <div class="chart-center">
                                                    <span>$</span>
                                                </div> 
                                            </div>
                                            <div class="sales-status">
                                                <button class="btn status open" data-bs-toggle="modal"
                                                    data-bs-target="#modalSueñosVentas"
                                                    (click)="fnOpenModal_Suenios_ventas('ABIERTOS')">
                                                    {{arrayVentas[0]?.dblAvance | currency:'':'symbol':'1.0-0'}}
                                                    ABIERTOS</button>
                                                <button class="btn status won" data-bs-toggle="modal"
                                                    data-bs-target="#modalSueñosVentas"
                                                    (click)="fnOpenModal_Suenios_ventas('GANADOS')">
                                                    {{arrayVentas[1]?.dblAvance | currency:'':'symbol':'1.0-0'}}
                                                    GANADOS</button>
                                                <button class="btn status lost" data-bs-toggle="modal"
                                                    data-bs-target="#modalSueñosVentas"
                                                    (click)="fnOpenModal_Suenios_ventas('PERDIDOS')">
                                                    {{arrayVentas[2]?.dblAvance | currency:'':'symbol':'1.0-0'}}
                                                    PERDIDOS</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 col-md-6 col-lg-3 d-flex justify-content-center justify-content-md-start ps-md-0 mt-3 mt-lg-0 mb-4">
                                <!--  <div class="col-12 borderbott bordertop bg-white shadow d-flex flex-column"
                                    style="height: 100%; display: inline-grid;">
                                    <div class="col-12 text-end mt-1 position-relative">
                                        <span class="ps-3 pe-2 py-2 clsVencido fs11 fw-bold btE bbl">Leads por
                                            Categoria</span>
                                    </div>
                                    <div
                                        class="col-12 table-responsive scrollY my-2 d-flex justify-content-center flex-grow-1">
                                        <div class="col-10  h-100 bg-light shadow-sm rounded text-center py-1 d-flex ">
                                            <div class="table-responsive m-auto d-flex justify-content-center">
                                                <table class=" table align-middle table-sm table-bordered h-100">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th scope="col" class="colorColumna">Categoria</th>
                                                            <th scope="col" class="colorColumna">Abiertos</th>
                                                            <th scope="col" class="colorColumna">Ganados</th>
                                                            <th scope="col" class="colorColumna">Perdidos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                        <tr *ngFor="let x of arrayLeadsCategoria; let i = index">
                                                            <th scope="row" class="colorColumna"><span
                                                                    class="fw-bold">{{x.strDescripcion}}</span></th>
                                                            <td>{{x.intAbierto}}</td>
                                                            <td>{{x.intGanado}}</td>
                                                            <td>{{x.intPerdido}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-12 table-responsive scrollY my-2 d-flex justify-content-center mb-2 mt-2">
                                        <button class="btn btn-outline-primary btn-sm"> Ver Acuerdos </button>
                                    </div>
                                </div> -->
                                <div class="col-12  borderbott bordertop bg-white shadow d-flex flex-column">
                                    <div class="col-12 text-end  mb-1 d-flex">
                                        <span class=" me-auto mt-1 ms-2 fs13">
                                            Total:
                                            <span class="fw-bolder">
                                                {{arrayActOport.length}}
                                            </span>
                                        </span>
                                        <span class="ps-3 pe-2 py-2 clsOportunidades fw-bold btE bbl"
                                            style="font-size: 10px;">Sueños</span>
                                    </div>
                                    <div class="col-12 m-auto" *ngIf="loader">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    <div [style.max-height.px]="altInfo" style="overflow-y: auto;">
                                        <div class="col-12 d-flex justify-content-center my-2 "
                                            *ngFor="let x of arrayActOport; let i = index">
                                            <div (click)="enviarOportunidad(x)"
                                                class="col-11 rounded shadow-sm  bgCards clsProxima  d-flex align-items-center py-3">
                                                <div class="col-12 d-flex text-start">
                                                    <div class="col-2 d-flex justify-content-center align-items-center">
                                                        {{ i + 1 }}
                                                    </div>
                                                    <span
                                                        class="fs14 fw-bold  col-10 row d-flex justify-content-start aling-items-center">
                                                        <span class="mb-1 fw-bold">{{x?.strNombre}}</span>
                                                        <span class="mb-1 fs10">{{x?.strTitulo}}</span>
                                                        <span class="fs11 d-flex">
                                                            <span
                                                                class="badge text-bg-info text-white text-center d-flex justify-content-center aling-items-center">
                                                                <p class="m-auto">{{x?.strEstatus}}</p>
                                                            </span>
                                                            <span class=" fs11 ms-auto textFecha">{{x?.strFechaRequerida
                                                                |date:'dd/MM/yyyy'}}</span>
                                                        </span>

                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 m-auto d-flex justify-content-center"
                                        *ngIf="arrayActOport.length<1 && !loader">
                                        <div class="col-10 bg-light shadow-sm rounded text-center py-1">
                                            <div class="col-12 text-center my-3">
                                                <i class="bi bi-cash-coin clsIconOport fs-1"></i>
                                            </div>
                                            <span class="fw-bold text-dark fs13">No cuenta con oportunidades</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 col-md-6 col-lg-3 d-flex justify-content-center justify-content-md-start ps-md-0 mt-3 mt-lg-0 mb-4">
                                <div class="col-12 borderbott bordertop bg-white shadow d-flex flex-column"
                                    style="height: 100%; display: inline-grid;">
                                    <div class="col-12 text-end mt-1 position-relative">
                                        <span class="ps-3 pe-2 py-2 clsVencido fs11 fw-bold btE bbl">Leads por
                                            Categoria</span>
                                    </div>
                                    <div
                                        class="col-12 table-responsive scrollY my-2 d-flex justify-content-center flex-grow-1">
                                        <div class="col-10  h-100 bg-light shadow-sm rounded text-center py-1 d-flex ">
                                            <div class="table-responsive m-auto d-flex justify-content-center">
                                                <table class=" table align-middle table-sm table-bordered">
                                                    <thead class="table-light">
                                                        <tr>
                                                            <th scope="col" class="colorColumna">Categoria</th>
                                                            <th scope="col" class="colorColumna">Abiertos</th>
                                                            <th scope="col" class="colorColumna">Ganados</th>
                                                            <th scope="col" class="colorColumna">Perdidos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                        <tr *ngFor="let x of arrayLeadsCategoria; let i = index">
                                                            <th scope="row" class="colorColumna"><span
                                                                    class="fw-bold">{{x.strDescripcion}}</span></th>
                                                            <td>{{x.intAbierto}}</td>
                                                            <td>{{x.intGanado}}</td>
                                                            <td>{{x.intPerdido}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-12 table-responsive scrollY my-2 d-flex justify-content-center mb-2 mt-2">
                                        <button class="btn btn-outline-primary btn-sm"> Ver Acuerdos </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 col-md-6 col-lg-3 d-flex justify-content-center justify-content-md-start ps-md-0 mt-3 mt-lg-0 mb-4">
                                <div class="col-12 borderbott bordertop bg-white shadow ">
                                    <div class="col-12 text-end mt-1 position-relative">
                                        <!-- <span class="position-absolute top-50 start-0 translate-middle-y ms-3 fs13">
                                            
                                        </span> -->
                                        <span class="position-absolute top-50 start-0 translate-middle-y ms-3 fs13">
                                            Sueños de Prospectos
                                        </span>
                                        <span class="ps-3 pe-2 py-2 clsVenta fs12 fw-bold btE bbl">Prospectos</span>
                                    </div>
                                    <div class="col-12">
                                        <div class="sales-container">
                                            <p></p>
                                            <div class="chart">
                                                <div class="donut-chart"
                                                    [ngStyle]="{ 'background': conicGradientStyleProspectos }"></div>
                                                <div class="chart-center">
                                                    <span><i class="fas fa-hands-helping"></i></span>
                                                </div>
                                            </div>
                                            <div class="sales-status">
                                                <button class="btn status open" data-bs-toggle="modal"
                                                data-bs-target="#modalSueñosVentas"  (click)="fnOpenModal_Suenios_prospectos('ABIERTOS')">
                                                 {{arrayProspecto[0]?.dblAvance |
                                                    currency:'':'symbol':'1.0-0'}} ABIERTOS</button>

                                                <button class="btn status won"  data-bs-toggle="modal"
                                                data-bs-target="#modalSueñosVentas"  (click)="fnOpenModal_Suenios_prospectos('GANADOS')"> {{arrayProspecto[1]?.dblAvance |
                                                    currency:'':'symbol':'1.0-0'}} GANADOS</button>
                                                <button class="btn status lost"  data-bs-toggle="modal"
                                                data-bs-target="#modalSueñosVentas"  (click)="fnOpenModal_Suenios_prospectos('PERDIDOS')"> {{arrayProspecto[2]?.dblAvance |
                                                    currency:'':'symbol':'1.0-0'}} PERDIDOS</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 shadow  text-center rounded bg-white py-2 my-3">
                                <h2 class="fw-bold mb-0">Cuenta Corporativa</h2>
                            </div>

                            <div *ngIf="oportunidadesClientesActuales.length > 0"
                                class="col-12 col-md-6 col-lg-4 d-flex justify-content-center pe-lg-0 mt-3 mt-lg-0 mb-4 justify-content-md-end pe-md-0 mt-4 m-auto">
                                <div class="col-12 borderbott bordertop bg-white shadow d-flex flex-column">
                                    <div class="col-12 text-end mt-1">
                                        <span class="ps-3 pe-2 py-2 clsOportunidades fs13 fw-bold btE bbl">Sueños
                                            de clientes</span>
                                    </div>
                                    <div class="pe-3 py-3 col-12">
                                        <div class="funnel-container">
                                            <div class="funnel">
                                                <div class="funnel-stage-wrapper m-auto">
                                                    <div class="funnel-stage" id="porcontactar">
                                                        <p>POR CONTACTAR</p>
                                                    </div>
                                                    <button
                                                        (click)="fnModalCtaCorporativa(0,'Sueños de Clientes Actuales')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeC': oportunidadesClientesActuales[0]?.dblAvance > 0, 'badgeCCero': oportunidadesClientesActuales[0]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[0]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeC': oportunidadesClientesActuales[0]?.dblAvance > 0, 'badgeCCero': oportunidadesClientesActuales[0]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[0]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper m-auto">
                                                    <div class="funnel-stage" id="presentacion">
                                                        <p>PRESENTACIÓN</p>
                                                    </div>
                                                    <button
                                                        (click)="fnModalCtaCorporativa(1,'Sueños de Clientes Actuales')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCPresentacion': oportunidadesClientesActuales[1]?.dblAvance > 0, 'badgeCPresentacionCero': oportunidadesClientesActuales[1]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[1]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCPresentacion': oportunidadesClientesActuales[1]?.dblAvance > 0, 'badgeCPresentacionCero': oportunidadesClientesActuales[1]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[1]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="recorrido">
                                                        <p>RECORRIDO/COTIZACIÓN</p>
                                                    </div>
                                                    <button
                                                        (click)="fnModalCtaCorporativa(2,'Sueños de Clientes Actuales')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCRecorrido': oportunidadesClientesActuales[2]?.dblAvance > 0, 'badgeCRecorridoCero': oportunidadesClientesActuales[2]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[2]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCRecorrido': oportunidadesClientesActuales[2]?.dblAvance > 0, 'badgeCRecorridoCero': oportunidadesClientesActuales[2]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[2]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="propuesta">
                                                        <p>PROPUESTA ECONOMICA</p>
                                                    </div>
                                                    <button
                                                        (click)="fnModalCtaCorporativa(3,'Sueños de Clientes Actuales')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCPropuesta': oportunidadesClientesActuales[3]?.dblAvance > 0, 'badgeCPropuestaCero': oportunidadesClientesActuales[3]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[3]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCPropuesta': oportunidadesClientesActuales[3]?.dblAvance > 0, 'badgeCPropuestaCero': oportunidadesClientesActuales[3]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[3]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="negociacion">
                                                        <p>NEGOCIACIÓN</p>
                                                    </div>
                                                    <button
                                                        (click)="fnModalCtaCorporativa(4,'Sueños de Clientes Actuales')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCNeg': oportunidadesClientesActuales[4]?.dblAvance > 0, 'badgeCNegCero': oportunidadesClientesActuales[4]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[4]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCNeg': oportunidadesClientesActuales[4]?.dblAvance > 0, 'badgeCNegCero': oportunidadesClientesActuales[4]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[4]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="ganadosC">
                                                        <p>GANADOS</p>
                                                    </div>
                                                    <button
                                                        (click)="fnModalCtaCorporativa(5,'Sueños de Clientes Actuales')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCGanados': oportunidadesClientesActuales[5]?.dblAvance > 0, 'badgeCGanadosCero': oportunidadesClientesActuales[5]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[5]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCGanados': oportunidadesClientesActuales[5]?.dblAvance > 0, 'badgeCGanadosCero': oportunidadesClientesActuales[5]?.dblAvance == 0}">
                                                        {{oportunidadesClientesActuales[5]?.dblAvance}}</div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="cuentasCorporativas.length > 0"
                                class="col-12 col-md-6 col-lg-4 d-flex justify-content-center pe-lg-0 mt-3 mt-lg-0 mb-4 justify-content-md-end pe-md-0 mt-4 m-auto">
                                <div class="col-12 borderbott bordertop bg-white shadow d-flex flex-column">
                                    <div class="col-12 text-end mt-1">
                                        <span class="ps-3 pe-2 py-2 clsOportunidades fs13 fw-bold btE bbl">Cuentas
                                            Corporativas</span>
                                    </div>
                                    <div class="pe-3 py-3 col-12">
                                        <div class="funnel-container">
                                            <div class="funnel">
                                                <div class="funnel-stage-wrapper m-auto">
                                                    <div class="funnel-stage" id="porcontactar1">
                                                        <p>POR CONTACTAR</p>
                                                    </div>

                                                    <button (click)="fnModalCtaCorporativa(0,'Cuentas Corporativas')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        type="button" class="btn btn-light" [ngClass]="{'badgeC': cuentasCorporativas[0]?.dblAvance > 0, 
                                                        'badgeCCero': cuentasCorporativas[0]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[0]?.dblAvance}}</button>
                                                </div>
                                                <div class="funnel-stage-wrapper m-auto">
                                                    <div class="funnel-stage" id="presentacion1">
                                                        <p>PRESENTACIÓN</p>
                                                    </div>
                                                    <!-- <div    [ngClass]="{'badgeCPresentacion': cuentasCorporativas[1]?.dblAvance > 0, 'badgeCPresentacionCero': cuentasCorporativas[1]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[1]?.dblAvance}}</div>-->

                                                    <button (click)="fnModalCtaCorporativa(1,'Cuentas Corporativas')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        type="button" class="btn btn-light"
                                                        [ngClass]="{'badgeCPresentacion': cuentasCorporativas[1]?.dblAvance > 0, 'badgeCPresentacionCero': cuentasCorporativas[1]?.dblAvance == 0}">{{cuentasCorporativas[1]?.dblAvance}}</button>
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="recorrido1">
                                                        <p>RECORRIDO/COTIZACIÓN</p>
                                                    </div>
                                                    <!--<div [ngClass]="{'badgeCRecorrido': cuentasCorporativas[2]?.dblAvance > 0, 'badgeCRecorridoCero': cuentasCorporativas[2]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[2]?.dblAvance}}</div>-->

                                                    <button (click)="fnModalCtaCorporativa(2,'Cuentas Corporativas')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        type="button" class="btn btn-light"
                                                        [ngClass]="{'badgeCRecorrido': cuentasCorporativas[2]?.dblAvance > 0, 'badgeCRecorridoCero': cuentasCorporativas[2]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[2]?.dblAvance}}</button>
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="propuesta">
                                                        <p>PROPUESTA ECONOMICA</p>
                                                    </div>
                                                    <!--<div  [ngClass]="{'badgeCPropuesta': cuentasCorporativas[3]?.dblAvance > 0, 'badgeCPropuestaCero': cuentasCorporativas[3]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[3]?.dblAvance}}</div>-->
                                                    <button (click)="fnModalCtaCorporativa(3,'Cuentas Corporativas')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        type="button" class="btn btn-light"
                                                        [ngClass]="{'badgeCPropuesta': cuentasCorporativas[3]?.dblAvance > 0, 'badgeCPropuestaCero': cuentasCorporativas[3]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[3]?.dblAvance}}</button>
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="negociacion">
                                                        <p>NEGOCIACIÓN</p>
                                                    </div>
                                                    <!--<div  [ngClass]="{'badgeCNeg': cuentasCorporativas[4]?.dblAvance > 0, 'badgeCNegCero': cuentasCorporativas[4]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[4]?.dblAvance}}</div>-->

                                                    <button (click)="fnModalCtaCorporativa(4,'Cuentas Corporativas')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        type="button" class="btn btn-light"
                                                        [ngClass]="{'badgeCNeg': cuentasCorporativas[4]?.dblAvance > 0, 'badgeCNegCero': cuentasCorporativas[4]?.dblAvance == 0}">{{cuentasCorporativas[4]?.dblAvance}}</button>
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="ganadosC">
                                                        <p>GANADOS</p>
                                                    </div>
                                                    <!--<div   [ngClass]="{'badgeCGanados': cuentasCorporativas[5]?.dblAvance > 0, 'badgeCGanadosCero': cuentasCorporativas[5]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[5]?.dblAvance}}</div>-->
                                                    <button (click)="fnModalCtaCorporativa(5,'Cuentas Corporativas')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        type="button" class="btn btn-light"
                                                        [ngClass]="{'badgeCGanados': cuentasCorporativas[5]?.dblAvance > 0, 'badgeCGanadosCero': cuentasCorporativas[5]?.dblAvance == 0}">
                                                        {{cuentasCorporativas[5]?.dblAvance}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="leadsProspectos.length > 0"
                                class="col-12 col-md-6 col-lg-4 d-flex justify-content-center pe-lg-0 mt-3 mt-lg-0 mb-4 justify-content-md-end pe-md-0 mt-4 m-auto">
                                <div class="col-12 borderbott bordertop bg-white shadow d-flex flex-column">
                                    <div class="col-12 text-end mt-1">
                                        <span class="ps-3 pe-2 py-2 clsOportunidades fs13 fw-bold btE bbl">Prospectos</span>
                                    </div>
                                    <div class="pe-3 py-3 col-12">
                                        <div class="funnel-container">
                                            <div class="funnel">
                                                <div class="funnel-stage-wrapper m-auto">
                                                    <div class="funnel-stage" id="porcontactar">
                                                        <p>POR CONTACTAR</p>
                                                    </div>
                                                    <button (click)="fnModalCtaCorporativa(0,'Leads/Prospectos')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeC': leadsProspectos[0]?.dblAvance > 0, 'badgeCCero': leadsProspectos[0]?.dblAvance == 0}">
                                                        {{leadsProspectos[0]?.dblAvance}}</button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeC': leadsProspectos[0]?.dblAvance > 0, 'badgeCCero': leadsProspectos[0]?.dblAvance == 0}">
                                                        {{leadsProspectos[0]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper m-auto">
                                                    <div class="funnel-stage" id="presentacion">
                                                        <p>PRESENTACIÓN</p>
                                                    </div>
                                                    <button (click)="fnModalCtaCorporativa(1,'Leads/Prospectos')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCPresentacion': leadsProspectos[1]?.dblAvance > 0, 'badgeCPresentacionCero': leadsProspectos[1]?.dblAvance == 0}">
                                                        {{leadsProspectos[1]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCPresentacion': leadsProspectos[1]?.dblAvance > 0, 'badgeCPresentacionCero': leadsProspectos[1]?.dblAvance == 0}">
                                                        {{leadsProspectos[1]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="recorrido">
                                                        <p>RECORRIDO/COTIZACIÓN</p>
                                                    </div>
                                                    <button (click)="fnModalCtaCorporativa(2,'Leads/Prospectos')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCRecorrido': leadsProspectos[2]?.dblAvance > 0, 'badgeCRecorridoCero': leadsProspectos[2]?.dblAvance == 0}">
                                                        {{leadsProspectos[2]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCRecorrido': leadsProspectos[2]?.dblAvance > 0, 'badgeCRecorridoCero': leadsProspectos[2]?.dblAvance == 0}">
                                                        {{leadsProspectos[2]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="propuesta">
                                                        <p>PROPUESTA ECONOMICA</p>
                                                    </div>
                                                    <button (click)="fnModalCtaCorporativa(3,'Leads/Prospectos')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCPropuesta': leadsProspectos[3]?.dblAvance > 0, 'badgeCPropuestaCero': leadsProspectos[3]?.dblAvance == 0}">
                                                        {{leadsProspectos[3]?.dblAvance}}
                                                    </button>
                                                    <!--  <div
                                                        [ngClass]="{'badgeCPropuesta': leadsProspectos[3]?.dblAvance > 0, 'badgeCPropuestaCero': leadsProspectos[3]?.dblAvance == 0}">
                                                        {{leadsProspectos[3]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="negociacion">
                                                        <p>NEGOCIACIÓN</p>
                                                    </div>
                                                    <button (click)="fnModalCtaCorporativa(4,'Leads/Prospectos')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCNeg': leadsProspectos[4]?.dblAvance > 0, 'badgeCNegCero': leadsProspectos[4]?.dblAvance == 0}">
                                                        {{leadsProspectos[4]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCNeg': leadsProspectos[4]?.dblAvance > 0, 'badgeCNegCero': leadsProspectos[4]?.dblAvance == 0}">
                                                        {{leadsProspectos[4]?.dblAvance}}</div> -->
                                                </div>
                                                <div class="funnel-stage-wrapper">
                                                    <div class="funnel-stage" id="ganadosC">
                                                        <p>GANADOS</p>
                                                    </div>
                                                    <button (click)="fnModalCtaCorporativa(5,'Leads/Prospectos')"
                                                        data-bs-toggle="modal" data-bs-target="#modalCuentaCorporativa"
                                                        class="btn btn-light"
                                                        [ngClass]="{'badgeCGanados': leadsProspectos[5]?.dblAvance > 0, 'badgeCGanadosCero': leadsProspectos[5]?.dblAvance == 0}">
                                                        {{leadsProspectos[5]?.dblAvance}}
                                                    </button>
                                                    <!-- <div
                                                        [ngClass]="{'badgeCGanados': leadsProspectos[5]?.dblAvance > 0, 'badgeCGanadosCero': leadsProspectos[5]?.dblAvance == 0}">
                                                        {{leadsProspectos[5]?.dblAvance}}</div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-person-check iconoTabs mx-2"></i>
                                <span class="m-auto">Cliente</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-2">
                            <div class="col-12">
                                <h1 class="mb-0 text-center">
                                    AVANCE DE VENTA
                                </h1>
                            </div>
                            <div class="col-12 d-flex d-lg-none my-3">
                                <div class="col d-flex  align-items-center justify-content-between">
                                    <button [routerLink]="['/EcodeliPremia']"
                                        class="btn rounded-circle my-auto bg-success d-flex shadow-sm shad">
                                        <i class="bi bi-star-fill text-white"></i>
                                    </button>
                                    <button [routerLink]="['/objMixAdd']"
                                        class="btn rounded-circle my-auto bg-warning d-flex shadow-sm shad">
                                        <i class="bi bi-graph-up-arrow text-white fs-6"></i>
                                    </button>
                                    <button [routerLink]="['/reportevtaobj']"
                                        class="btn rounded-circle my-auto iconvtavsobj d-flex shadow-sm shad ">
                                        <i class="bi bi-list-ul text-white"></i>
                                    </button>
                                </div>
                                <div class="col-1">
                                </div>
                                <div class="col">
                                    <mat-accordion>
                                        <!-- Panel de Movimientos Pendientes -->
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header class="ps-2">
                                                <mat-panel-title class="fs11 fw-bold">
                                                    Movimientos Pendientes
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <!-- Contenido del panel -->
                                            <button class="btn  btn-link fs11 p-0"
                                                [routerLink]="['/pedidos_pendientes']"> <i
                                                    class="bi bi-box-seam-fill"></i> Pedidos <span
                                                    class="badge text-bg-danger ms-1">{{intPedidos_P}}</span></button>
                                            <br>
                                            <button class="btn  btn-link fs11 p-0 mt-3"
                                                [routerLink]="['/remisiones_pendientes']"> <i
                                                    class="bi bi-box-seam-fill"></i> Remisiones</button> <span
                                                class="badge text-bg-danger ms-1">{{intRemisiones_P}}</span>
                                            <br>
                                            <button class="btn  btn-link fs11 p-0 mt-3"
                                                [routerLink]="['/venta_consigna']"> <i class="bi bi-box-seam-fill"></i>
                                                Venta Consigna</button> <span
                                                class="badge text-bg-danger ms-1">{{intConsignas_P}}</span>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                            <div class="col-12 d-flex justyfi-content-end position-relative">
                                <div class="d-none d-lg-flex flex-column  col-1 align-items-center">
                                    <button [routerLink]="['/EcodeliPremia']"
                                        class="btn rounded-circle my-auto bg-success d-flex shadow-sm shad">
                                        <i class="bi bi-star-fill text-white"></i>
                                    </button>
                                    <button [routerLink]="['/objMixAdd']"
                                        class="btn rounded-circle my-auto bg-warning d-flex shadow-sm shad">
                                        <i class="bi bi-graph-up-arrow text-white fs-6"></i>
                                    </button>
                                    <button [routerLink]="['/reportevtaobj']"
                                        class="btn rounded-circle my-auto iconvtavsobj d-flex shadow-sm shad ">
                                        <i class="bi bi-list-ul text-white"></i>
                                    </button>
                                    <!--      <button class="btn rounded-circle iconReport  my-auto  d-flex shadow-sm shad">
                                        <i class="bi bi-stack text-white"></i>
                                    </button> -->
                                </div>

                                <div class="col-12 col-lg-9 d-flex justify-content-start">
                                    <div class="col9 shadow borderbott bordertop bg-white">
                                        <div class="row d-flex">
                                            <div class="col-11 m-auto d-flex justify-content-between ">

                                                <button class="btn btn-primary btn-sm ms-auto me-2 mt-2"
                                                    (click)="fnEnviarNuevoPedido()">
                                                    <i class="bi bi-pencil-square mx-1"></i>Nuevo Pedido</button>
                                                <!--   <button class="btn btn-success btn-sm mx-2"><i class="bi bi-bookmark mx-1"></i>Ir a Tienda</button> -->
                                            </div>
                                            <!-- <div class="col-12"> -->
                                            <!--  <div class="d-flex justify-content-center align-items-center mb-3 p-2"> -->
                                            <div class="col-md-4 col-12 m-auto text-center p-1">
                                                <h2 class="mb-1 text-primary">⭐ Objetivo de venta x Mix</h2>
                                                <h3 class="mb-0 fw-bold">{{objTotal.totalObjetivo|currency}}</h3>
                                            </div>
                                            <div class="col-md-4 col-12 m-auto text-center  p-1">
                                                <h2 class="mb-1 text-primary">💲 Venta</h2>
                                                <h3 class="mb-0 mx-2 fw-bold">{{objTotal.totalventa|currency}}</h3>
                                            </div>
                                            <!--  </div> -->
                                            <div class=" col-12 d-flex flex-column mt-1">
                                                <span class="mx-auto">Progreso: </span>
                                                <div class="progress mb-3 d-flex w-50 mt-2 mx-auto">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                        role="progressbar"
                                                        [style.width.%]="(objTotal.totalventa*100)/objTotal.totalObjetivo"
                                                        [attr.aria-valuenow]="(objTotal.totalventa*100)/objTotal.totalObjetivo | number:'1.2-2'"
                                                        aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center align-items-center mb-3">
                                                <p class="mb-0 mx-auto">Tu objetivo de venta va en
                                                    <strong>{{((objTotal.totalventa*100)/objTotal.totalObjetivo)|number:'1.2-2'}}
                                                        %</strong>.
                                                </p>
                                            </div>
                                            <!--    </div> -->
                                        </div>
                                    </div>

                                </div>
                                <!--movimientos pendientes-->
                                <div class="col-2 d-none d-lg-block ">
                                    <mat-accordion>
                                        <!-- Panel de Movimientos Pendientes -->
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header class="ps-2">
                                                <mat-panel-title class="fs11 fw-bold">
                                                    Movimientos Pendientes
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <!-- Contenido del panel -->
                                            <button class="btn  btn-link fs11 p-0"
                                                [routerLink]="['/pedidos_pendientes']"> <i
                                                    class="bi bi-box-seam-fill"></i> Pedidos <span
                                                    class="badge text-bg-danger ms-1">{{intPedidos_P}}</span></button>
                                            <br>
                                            <button class="btn  btn-link fs11 p-0 mt-3"
                                                [routerLink]="['/remisiones_pendientes']"> <i
                                                    class="bi bi-box-seam-fill"></i> Remisiones</button> <span
                                                class="badge text-bg-danger ms-1">{{intRemisiones_P}}</span>
                                            <br>
                                            <button class="btn  btn-link fs11 p-0 mt-3"
                                                [routerLink]="['/venta_consigna']"> <i class="bi bi-box-seam-fill"></i>
                                                Venta Consigna</button> <span
                                                class="badge text-bg-danger ms-1">{{intConsignas_P}}</span>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                        </div>
                        <div class="row my-4 ">
                            <div class="col-md-3 col-12 text-center mb-1">

                                <a type="button" class="btn w-100 btn-light btnColor1"
                                    href="https://www.ecodelinegocio.com/Intranet/logeoNegocio/{{strUsuario}}/COMPRASINDIRECTAS"
                                    target="_NEW">
                                    Compras Indirectas <span
                                        class="badge text-bg-primary bgColor">{{intComprasIndirectas}}</span>
                                </a>

                            </div>
                            <div class="col-md-2 col-12 mb-1">
                                <a type="button" class="btn w-100 btn-light btnColor2"
                                    href="https://www.ecodelinegocio.com/Intranet/logeoNegocio/{{strUsuario}}/PRESUPUESTOS"
                                    target="_NEW">
                                    Presupuestos <span
                                        class="badge text-bg-secondary bgColor2">{{intPresupuestosPendientes}}</span>
                                </a>
                            </div>
                            <div class="col-md-2 col-12 mb-1">
                                <a type="button" class="btn w-100 btn-light btnColor3"
                                    href="https://www.ecodelinegocio.com/Intranet/logeoNegocio/{{strUsuario}}/GASTOSANTICIPOS"
                                    target="_NEW">
                                    Anticipos <span class="badge text-bg-secondary bgColor3">{{intAnticipos}}</span>
                                </a>
                            </div>
                            <div class="col-md-2 col-12 mb-1">
                                <a type="button" class="btn w-100 btn-light btnColor4"
                                    href="https://www.ecodelinegocio.com/Intranet/logeoNegocio/{{strUsuario}}/GASTOS"
                                    target="_NEW">
                                    Gastos <span class="badge text-bg-secondary bgColor4">{{intGastos}}</span>
                                </a>
                            </div>
                            <div class="col-md-2 col-12 mb-1">
                                <a type="button" class="btn w-100 btn-light btnColor5"
                                    href="https://www.ecodelinegocio.com/Intranet/logeoNegocio/{{strUsuario}}/PRESTAMOS"
                                    target="_NEW">
                                    Préstamos <span class="badge text-bg-secondary bgColor5"> </span>
                                </a>
                            </div>
                        </div>
                        <div class="row my-4">
                            <div class="col-12 d-flex justify-content-center" *ngIf="spinnerTab">
                                <mat-spinner></mat-spinner>
                            </div>
                            <div class="col-12 mb-4" *ngIf="!spinnerTab">
                                <div class="col-12 bordertop   border-light py-1"
                                    style="background: #D8DEF4; color: #62718f;">
                                    <span class="ms-3 fw-bold">Objetivo MIX</span>
                                </div>
                                <div class="table-responsive mb-0 borderbott  shadow border border"
                                    style="background: #D8DEF4; color: #62718f;">

                                    <table class="table ">
                                        <thead class="btnFilter">
                                            <tr class="thcenter  nunito fs15">
                                                <th class="border border-end">Linea</th>
                                                <th class="">Objetivo %</th>
                                                <th class="border border-end">Objetivo</th>
                                                <th class="border border-end">Venta</th>
                                                <th class="border border-end">Margen</th>
                                                <th class="border border-end">Avance</th>
                                                <th class="">Avance al día de hoy</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let x of arrayLineas">
                                                <td class="border border-end">
                                                    <div class="d-flex align-items-center fw-bold">
                                                        <i class="bi bi-bag-check-fill text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='ACCESORIOS'"></i>
                                                        <i class="bi bi-globe-americas text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='DEGRAPACK'"></i>
                                                        <i class="bi bi-receipt text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='BLUE TISSUE'||x.strLinea=='KIMBERLY'"></i>
                                                        <i class="bi bi-droplet text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='FOAOM'"></i>
                                                        <i class="bi bi-device-hdd-fill text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='TORK'"></i>
                                                        <i class="bi bi-list-ul text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='OTROS'"></i>
                                                        <i class="bi bi-droplet-half text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='QUIMICOS'"></i>
                                                        <i class="bi bi-trash fs-5 text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='RUBBERMAID'"></i>
                                                        <i class="bi bi-twitter text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='DOVE'"></i>
                                                        <i class="bi bi-phone-fill text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='DESPACHADORES ECODELI'"></i>
                                                        <i class=" bi-card-text text-secondary me-2 fs-5"
                                                            *ngIf="x.strLinea=='TAPETES'"></i>
                                                        {{x.strLinea}}
                                                    </div>
                                                </td>
                                                <td class="text-center border border-end fs13">
                                                    <span class="rounded objetivo px-2 shadow-sm fw-bold py-1">
                                                        {{((x.dblObjetivo /
                                                        objTotal.totalObjetivo)*100)|number:'1.2-2'}}%
                                                    </span>
                                                </td>
                                                <td class="text-end border border-end fs13">
                                                    <span class=" fw-bold py-1">
                                                        {{x.dblObjetivo|currency}}
                                                    </span>
                                                </td>

                                                <td class="text-end border border-end fs13">
                                                    <span class=" fw-bold py-1">
                                                        {{x.dblVenta|currency}}
                                                    </span>
                                                </td>
                                                <td class="text-end border border-end fs13">
                                                    <span class=" fw-bold py-1">
                                                        {{x.dblMargen|currency}}
                                                    </span>
                                                </td>
                                                <td class="text-center border border-end fs13">
                                                    <span class="rounded margen px-2 shadow-sm fw-bold py-1">
                                                        {{x.dblAvance *100|number:'1.2-2'}}%
                                                    </span>
                                                </td>
                                                <td class="text-center">
                                                    <div class="d-flex flex-column ">
                                                        <span class="mx-auto fs12 fw-bold">
                                                            {{(x.dblHoy*100)|number:'1.2-2'}}%
                                                        </span>
                                                        <div class="progress my-progress">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                [ngClass]="{'bg-danger':(x.dblHoy*100) >0 && (x.dblHoy*100) <=69 ,'bg-warning':(x.dblHoy*100) >69 && (x.dblHoy*100) <= 90,'bg-success':(x.dblHoy*100) >90}"
                                                                role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                                                aria-valuemax="100" [style.width.%]="(x.dblHoy*100)">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>
                                                    Total
                                                </th>
                                                <th class="text-center fw-bold">
                                                    100 %
                                                </th>
                                                <th class="text-end">
                                                    {{objTotal.totalObjetivo|currency}}
                                                </th>
                                                <th class="text-end">
                                                    {{objTotal.totalventa|currency}}
                                                </th>
                                                <th class="text-end">
                                                    {{objTotal.totalMargen|currency}}
                                                </th>
                                                <th>

                                                </th>
                                                <th class="text-end">
                                                    {{dblTotalPorcentajeAvance | number:'1.2-2' }}%
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="row my-4">
                            <div class="row" *ngIf="spinerNavTabs"><!-- *ngIf="spinerInfVentas" -->
                                <div class="col-12 ">
                                    <mat-spinner class="mx-auto " style="font-size: 10px !important;"></mat-spinner>
                                </div>
                            </div>
                            <div class="col-12  px-0 table-responsive mb-0 bordertop borderbott" *ngIf="!spinerNavTabs">
                                <table class="table  table-hover">
                                    <thead>
                                        <tr class="bg-gris">
                                            <th class="fs14 bgtable border-end text-center border-dark">LINEA</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Año</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Ene</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Feb</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Mar</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Abr</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">May</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Jun</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Jul</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Ago</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Sep</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Oct</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Nov</th>
                                            <th class="fs14 bgtable border-end text-center border-dark">Dic</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let x of arrayClientesPorLinea;let last = last;">
                                            <td class="bg-gris border-dark border-top-0 fs14 border-start-0 border-end fw-bold"
                                                [ngClass]="{'borderbottL border-bottom-0': last }">{{x.strLinea}}</td>
                                            <td class="fs12 border-end text-center">{{currentYear}}</td>
                                            <td class="fs12 border-end text-center">
                                                <span data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,1)"
                                                    [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">
                                                    {{x.intEnero}}
                                                </span>
                                            </td>
                                            <td class="fs12 border-end text-center">
                                                <span
                                                    [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,2)">
                                                    {{x.intFebrero}}
                                                </span>
                                            </td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,3)">
                                                    {{x.intMarzo}}</span></td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,4)">
                                                    {{x.intAbril}}</span></td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,5)">
                                                    {{x.intMayo}}</span></td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,6)">
                                                    {{x.intJunio}}</span></td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,7)">
                                                    {{x.intJulio}}</span></td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,8)">
                                                    {{x.intAgosto}}</span></td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,9)">
                                                    {{x.intSeptiembre}}</span></td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,10)">
                                                    {{x.intOctubre}}</span></td>
                                            <td class="fs12 border-end text-center"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,11)">
                                                    {{x.intNoviembre}}</span></td>
                                            <td class="fs12"><span
                                                    [ngClass]="{'text-primary fw-bold':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}"
                                                    data-bs-toggle="modal" data-bs-target="#modalCtesclinea"
                                                    (click)="fngetctesXlinea(x.strLinea,12)">
                                                    {{x.intDiciembre}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-11  px-0">
                                <div class="row borderbott shadow bordertop ">
                                    <div class="col-12 text-center bgEscl1 bordertop ">
                                        <h2 class="mb-0 my-1 text-white"><i class="bi bi-person-plus"></i>Tipo Clientes
                                        </h2>
                                    </div>
                                    <div class="row" *ngIf="spinerTipoClientes"><!-- *ngIf="spinerInfVentas" -->
                                        <div class="col-12 ">
                                            <mat-spinner class="mx-auto "
                                                style="font-size: 10px !important;"></mat-spinner>
                                        </div>
                                    </div>
                                    <div class="col-12  px-0 table-responsive mb-0 borderbott"
                                        *ngIf="!spinerTipoClientes">
                                        <table class="table w-100">
                                            <thead>
                                                <tr class=" ">
                                                    <th class="text-center border-dark border-end fs14 bg-gris">Clientes
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Año
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Ene
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Feb
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Mar
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Abr
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">May
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Jun
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Jul
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Ago
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Sep
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Oct
                                                    </th>
                                                    <th class="text-center border-dark border-end fs14 bg-amarillo">Nov
                                                    </th>
                                                    <th class="text-center border-dark border-end bg-amarillo">Dic</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let x of arraycte4500;let last = last;">
                                                    <td
                                                        class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12 text-nowrap">
                                                        + $7,500.00</td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,'7500');"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,'7500'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,'7500')"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let x of arraycte10000;let last = last;">
                                                    <td
                                                        class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12 text-nowrap">
                                                        0 a $10,000.00</td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,'10');"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,'10'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,'10')"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let x of arraycte50000;let last = last;">
                                                    <td
                                                        class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12 text-nowrap">
                                                        $10,000.00 a $50,000.00</td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,'50');"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,'50'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,'7500')"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let x of arraycte100000;let last = last;">
                                                    <td
                                                        class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12 text-nowrap">
                                                        $50,000.00 a $100,000.00</td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,'100');"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,'100'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,'100')"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let x of arraycte150000;let last = last;">
                                                    <td
                                                        class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12 text-nowrap">
                                                        $100,000.00 a $150,000.00</td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,'150');"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,'150'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,'150')"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let x of arraycte250000;let last = last;">
                                                    <td
                                                        class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12 text-nowrap">
                                                        $150,000.00 a $250,000.00</td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,'250');"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,'250'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,'250')"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let x of arraycte250000mayor;let last = last;">
                                                    <td
                                                        class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12 text-nowrap">
                                                        + $250,000.00</td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,'250+');"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,'250+'); "
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,'250+')"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let x of arrayTipoCliente;let last = last;">
                                                    <td
                                                        class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12">
                                                        {{x.strTipo}}</td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,x.strTipo)"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let x of arrayCteNuevos;let last = last;">
                                                    <td class="bg-gris border-dark border-top-0  border-start-0 border-end fw-bold fs12"
                                                        [ngClass]="{'borderbottL border-bottom-0': last }">{{x.strTipo}}
                                                    </td>
                                                    <td class="border-end text-center fs12">{{intEjercicio}}</td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,1,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intEnero>0,'text-secondary':x.intEnero==0}">{{x.intEnero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,2,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intFebrero>0,'text-secondary':x.intFebrero==0}">{{x.intFebrero}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,3,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intMarzo>0,'text-secondary':x.intMarzo==0}">{{x.intMarzo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,4,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intAbril>0,'text-secondary':x.intAbril==0}">{{x.intAbril}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,5,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intMayo>0,'text-secondary':x.intMayo==0}">{{x.intMayo}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,6,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intJunio>0,'text-secondary':x.intJunio==0}">{{x.intJunio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,7,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intJulio>0,'text-secondary':x.intJulio==0}">{{x.intJulio}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,8,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intAgosto>0,'text-secondary':x.intAgosto==0}">{{x.intAgosto}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,9,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intSeptiembre>0,'text-secondary':x.intSeptiembre==0}">{{x.intSeptiembre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,10,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intOctubre>0,'text-secondary':x.intOctubre==0}">{{x.intOctubre}}</span>
                                                    </td>
                                                    <td class="border-end text-center fs12"><span data-bs-toggle="modal"
                                                            data-bs-target="#modalCtesclinea"
                                                            (click)="fnabrirModal('tipoCte',x,11,x.strTipo)"
                                                            [ngClass]="{'text-primary fw-bold':x.intNoviembre>0,'text-secondary':x.intNoviembre==0}">{{x.intNoviembre}}</span>
                                                    </td>
                                                    <td class=" text-center fs12" data-bs-toggle="modal"
                                                        data-bs-target="#modalCtesclinea"
                                                        (click)="fnabrirModal('tipoCte',x,12,x.strTipo)"
                                                        [ngClass]="{'text-primary':x.intDiciembre>0,'text-secondary':x.intDiciembre==0}">
                                                        <span>{{x.intDiciembre}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalCuentaCorporativa" tabindex="-1" aria-labelledby="exampleModalLabel"
    >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title mx-auto" id="exampleModalLabel">{{objCtaCorporativa?.titulo}}</h1>
                <button type="button" class=" ms-0 btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="text-center mb-2">
                                {{objCtaCorporativa?.tipo }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary">Guardad</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalSueñosVentas" tabindex="-1" aria-labelledby="exampleModalLabel" >
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">{{strTitle_SV}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div style="height:400px; overflow-y: auto;">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Cliente </th>
                                <th scope="col">Nombre</th>
                                
                                
                                <th scope="col"  >Responsable </th>
                                

                                <th scope="col">Fecha Creación</th>

                                <th scope="col">Fecha Vencimiento</th>
                                <th scope="col">Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let det of arrayDetalleSuenio">
                                <th scope="row" >
                                    <button type="button" class="btn btn-info" (click)="irSuenio(det.intID_oportunidad, det.strEstatus)">{{det.intID_oportunidad}}</button>

                             
                                 </th>
                                <td style="font-size: 12px;"><b *ngIf="det.strCliente !=''"> {{det.strCliente}} </b> 
                                    <b *ngIf="det.strCliente ==''"> FAMILIA </b>
                                     {{det.strNombre_Leads}} 
                                </td>
                                
                                <td style="font-size: 12px;">{{det.strNombreOportunidad}}</td>
                                
                               
                                <td style=" font-size: 12px;"><b>({{det.strAgente }}) </b>  {{det.strNombre }}</td>
                                
                                <td style="font-size: 12px;">{{det.strFechaCreacion  | date: 'dd/MM/yyyy'}}</td>
                                <td style="font-size: 12px;">{{det.strFechaCierre_Estimada | date: 'dd/MM/yyyy' }}</td>
                                <td style="font-size: 12px; text-align: right;">{{det.dblImporte | currency}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

            </div>
        </div>
    </div>
</div>

<!-- modal concluirAct -->
<div class="modal fade" #MConcluirActividades id="MConcluirActividades" tabindex="-1" aria-labelledby="exampleModalLabel" >
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5 mb-0 mx-auto fw-bold" id="exampleModalLabel">* Actividades
                    {{strTituloModal}} de
                    {{objAct?.strTipo}}s </h1>
                <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <!-- Botón para abrir el menú lateral en móviles -->
                    <div class="col-12 d-lg-none">
                        <button class="btn btn-primary w-100 my-2" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#mobileSidebar2" aria-controls="mobileSidebar">
                            <i class="bi bi-list"></i> Ver Actividades Pendientes
                        </button>
                    </div>

                    <!-- Menú lateral en móviles (usando offcanvas) -->
                    <div class="offcanvas offcanvas-start d-lg-none px-0" tabindex="-1" id="mobileSidebar2"
                        aria-labelledby="mobileSidebarLabel">
                        <div class="offcanvas-header">
                            <h3 class="offcanvas-title fw-bold mb-0" id="mobileSidebarLabel">Tareas Pendientes</h3>
                            <button #btnCanvas type="button" id="botonCerrarOffcanva2" class="btn-close"
                                data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <nav>
                                <ul class="nav flex-column">
                                   
                                    <ng-container *ngFor="let x of  objAct?.arrayAct ;let ind = index">
                                        <div  *ngIf="x.strTipo ==  objAct?.strTipo"
                                            class=" col-10 mx-auto bordertop borderbott  mt-3 bgCards"
                                            [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true,'text-danger':strTituloModal=='Vencidas','clsProxima':strTituloModal=='Proximas','text-success':strTituloModal=='Para Hoy'}">
                                            <div (click)="fnSeleccionarAct(x,ind)" class="col-12 bgcardModal bordertop text-center position-relative"
                                                [ngClass]="{'py-2':objAct.strTipo=='Tarea'}">




                                                <div class="col-12 d-flex align-items-center position-absolute top-50 start-0 translate-middle-y"
                                                    matTooltip="{{x.strEstatus}}" *ngIf="objAct.strTipo=='Tarea'">
                                                    <div class=" "
                                                        *ngIf="x.strEstatus != 'POR AUTORIZAR' && x.strEstatus != 'PROCESO'">
                                                        <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                            disabled [checked]="x.strEstatus == 'CONCLUIDO'"
                                                            type="checkbox" id="customRadioCheckbox">
                                                    </div>
                                                    <div class=" text-center ps-2"
                                                        *ngIf="x.strEstatus == 'POR AUTORIZAR'">
                                                        <button type="button"
                                                            class="btn btn-outline-warning px-1  py-0   shadow-sm"><i
                                                                class="fa-solid fa-bell"></i></button>
                                                    </div>
                                                    <div class=" text-center ps-2" *ngIf="x.strEstatus == 'PROCESO'">
                                                        <button type="button"
                                                            class="btn btn-outline-info px-1 py-0 shadow-sm"
                                                            title="En Proceso">
                                                            <i class="fa-solid fa-circle-half-stroke"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <span
                                                    class="col-12 ps-2 fw-bold text-white">{{x.strCliente}}<!-- {{x.strTitulo}} --></span>
                                            </div>
                                            <div (click)="fnSeleccionarAct(x,ind)" class="col-12 d-flex justify-content-evenly align-items-center  py-2">
                                                <div class="col-2">
                                                    <i class="bi fs-6"
                                                        [ngClass]="{'bi-telephone-fill':objAct.strTipo=='Llamada','bi-person-walking':objAct.strTipo=='Visita','bi-envelope-fill':objAct.strTipo=='Correo','bi-list-task':objAct.strTipo=='Tarea'}"></i>
                                                </div>
                                                <div class="col-9 d-flex flex-column">
                                                    <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                                    <span class="fs12 fw-normal">{{x.strTitulo}}</span>
                                                </div>
                                            </div>
                                            <div class="col-12 bgcardModal borderbott text-end  d-flex align-items-center justify-content-between"><!--  strTitulo -->
                                                <button (click)="fnSelectTarea(x,true)" type="button" class="btn btn-primary mt-1 mb-1 ms-2" style="--bs-btn-padding-y: .16rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                                                    <i class="fas fa-stream"></i> <span class="badge rounded-pill text-bg-light mx-1">{{x.dblCantidad?x.dblCantidad:0}}</span>
                                                </button>
                                                <span (click)="fnSelectTarea(x,true)" class="btn  shadow-sm  btn-warning py-0 px-1" *ngIf="x.strAgente != strAgente && strAgente != ''" matTooltip="Existencia de subActividades">
                                                    <i class="bi bi-question-diamond-fill"></i>
                                                </span>
                                                <span
                                                    class="fs13 text-end text-white  pe-2 ">{{x.strFechaRequerida
                                                    |date:'dd/MM/yyyy'}}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="col-md-4 d-none d-md-block" #mdlBody>
                        <div class="colo-12 justify-content-center d-flex" *ngIf="spiner">
                            <mat-spinner class="m-auto"></mat-spinner>
                        </div>
                        <div class="col-12 d-flex flex-column" [style.height]="altoModal + 'px'"
                            style="overflow-y: auto;">
                            <div class="col-12 d-flex justify-content-center" *ngIf="!spiner && objAct.intContador==0">
                                <div class="col-10 bg-light shadow-sm rounded text-center py-1">
                                    <span class="fw-bold text-dark fs15">No cuenta con actividades</span>
                                </div>
                            </div>
                            <ng-container *ngFor="let x of  objAct?.arrayAct ;let ind = index">
                                
                                <div  *ngIf="x.strTipo ==  objAct?.strTipo"
                                    class=" col-10 mx-auto bordertop borderbott  mt-3 bgCards"
                                    [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true,'text-danger':strTituloModal=='Vencidas','clsProxima':strTituloModal=='Proximas','text-success':strTituloModal=='Para Hoy'}">
                                    <div (click)="fnSeleccionarAct(x,ind)" class="col-12 bgcardModal bordertop text-center position-relative"
                                        [ngClass]="{'py-2':objAct.strTipo=='Tarea'}">

                                        <div class="col-12 d-flex align-items-center position-absolute top-50 start-0 translate-middle-y"
                                            matTooltip="{{x.strEstatus}}" *ngIf="objAct.strTipo=='Tarea'">
                                            <div class=" "
                                                *ngIf="x.strEstatus != 'POR AUTORIZAR' && x.strEstatus != 'PROCESO'">
                                                <input class="form-check-input custom-checkbox fs-6 mx-2" disabled
                                                    [checked]="x.strEstatus == 'CONCLUIDO'" type="checkbox"
                                                    id="customRadioCheckbox">
                                            </div>
                                            <div class=" text-center ps-2" *ngIf="x.strEstatus == 'POR AUTORIZAR'">
                                                <button type="button"
                                                    class="btn btn-outline-warning px-1  py-0   shadow-sm"><i
                                                        class="fa-solid fa-bell"></i></button>
                                            </div>
                                            <div class=" text-center ps-2" *ngIf="x.strEstatus == 'PROCESO'">
                                                <button type="button" class="btn btn-outline-info px-1 py-0 shadow-sm"
                                                    title="En Proceso">
                                                    <i class="fa-solid fa-circle-half-stroke"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <span
                                            class="col-12 ps-2 fw-bold text-white">{{x.strCliente}}<!-- {{x.strTitulo}} --></span>
                                    </div>
                                    <div (click)="fnSeleccionarAct(x,ind)" class="col-12 d-flex justify-content-evenly align-items-center  py-2">
                                        <div class="col-2">
                                            <i class="bi fs-6"
                                                [ngClass]="{'bi-telephone-fill':objAct.strTipo=='Llamada','bi-person-walking':objAct.strTipo=='Visita','bi-envelope-fill':objAct.strTipo=='Correo','bi-list-task':objAct.strTipo=='Tarea'}"></i>
                                        </div>
                                        <div class="col-9 d-flex flex-column">
                                            <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                            <span class="fs12 fw-normal">{{x.strTitulo}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 bgcardModal borderbott d-flex justify-content-between align-items-center"><!--  strTitulo -->
                                        <button (click)="fnSelectTarea(x,true)" type="button" class="d-flex btn btn-primary mt-1 mb-1 mx-2" style="--bs-btn-padding-y: .16rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                                            <i class="fas fa-stream"></i> <span class="badge rounded-pill text-bg-light mx-1">{{x.dblCantidad?x.dblCantidad:0}}</span>
                                          </button>
                                            <span (click)="fnSelectTarea(x,true)" class="btn  shadow-sm  btn-warning py-0 px-1" *ngIf="x.strAgente != strAgente && strAgente != ''" matTooltip="Existencia de subActividades">
                                                <i class="bi bi-question-diamond-fill"></i>
                                            </span>
                                        <span class="fs13 text-end text-white  pe-2 ">{{x.strFechaRequerida
                                            |date:'dd/MM/yyyy'}}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-8 col-12">
                        <div class="row ">
                            <div class="col-12 d-flex flex-column flex-md-row justify-content-between align-items-md-center mt-2 mb-2  text-center text-md-center"
                                [ngClass]="{'visually-hidden': objAct.objAct?.strEstatus == 'CONCLUIDO'}">

                                <!-- Título centrado en md+ y centrado en sm -->
                                <h3 class="titulo fw-bold mb-0 fs-5 py-3">
                                    {{ objAct.objAct?.strTitulo ? objAct.objAct.strTitulo : '--Seleccione opción--' }}
                                </h3>

                                <!-- Estatus alineado a la derecha en md+ y centrado en sm -->
                                <span class=" text-white shadow-sm borderbott bordertop px-2 py-1 d-flex flex-column text-center" [ngClass]="{'bg-secondary':objAct?.objAct?.strEstatus!= 'CONCLUIDO' && objAct?.objAct?.strEstatus!= 'PROCESO' && objAct?.objAct?.strEstatus!= 'PENDIENTE','bg-success':objAct?.objAct?.strEstatus== 'CONCLUIDO','bgInfo':objAct?.objAct?.strEstatus== 'PROCESO','bg-warning':objAct?.objAct?.strEstatus== 'PENDIENTE'}">
                                    <span class="fs12">Estatus</span>
                                    <span class="fw-bold fs12">
                                        {{ objAct.objAct?.strEstatus ? objAct.objAct.strEstatus : '--Seleccione opción--' }}
                                    </span>
                                </span>

                            </div>
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-person-fill-gear clsIconModal "></i>
                                        Responsable:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strNombreAgente?objAct.objAct.strNombreAgente:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-person-circle clsIconModal"></i>  Asignado
                                        por:
                                    </span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.nombreCrea?objAct.objAct.nombreCrea:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Inicio:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strHoraInicio?objAct.objAct.strHoraInicio:'00'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Fin:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strHoraFin?objAct.objAct.strHoraFin:'00'}}</span>
                                </div>
                            </div>
                            <!--  <div class="col-12">
                                <div class="col-12 d-flex flex-column mt-3 mb-3" [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-chat-right-dots-fill clsIconModal"></i> Asunto</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strAsunto? objAct.objAct?.strAsunto:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                            </div> -->
                            <div class="col-12 mt-3">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i>
                                        ASUNTO</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objAct.objAct&&objAct.objAct.strAsunto?objAct.objAct.strAsunto:'--Seleccione '+objAct?.strTipo+'--'"></span>
                                </div>
                            </div>
                            <div class="col-12 mt-3" *ngIf="objAct.objAct&&objAct.objAct.strDescripcion">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i>
                                        OBSERVACIONES </span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objAct.objAct&&objAct.objAct.strDescripcion?objAct.objAct.strDescripcion:'--Seleccione --'"></span>
                                </div>
                            </div>


                            <div class="col-12 mb-2 mt-3"
                                [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'||objAct.objAct&&objAct.objAct.strDescripcion}">
                                <div class="form-group ">
                                    <textarea [(ngModel)]="contenido" class="custom-summernotesummernote"
                                        id="summernote" #summernote></textarea>
                                </div>
                            </div>
                            <div class="col-12 d-flex mt-2">
                                <select name="accioness" id="acciones" class="form-select m-auto"
                                    [ngClass]="{'visually-hidden':objAct.objAct&&objAct.objAct.strDescripcion}"
                                    [(ngModel)]="strResultado"
                                    [disabled]="objAct.objAct?.strEstatus=='CONCLUIDO'||objAct?.intContador==0">
                                    <option value="">
                                        --Selecciona
                                        una opción--</option>
                                    <option value="1">
                                        EXITOSO</option>
                                    <option value="2">
                                        SIN EXITO
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 colorComentarios d-flex justify-content-center align-items-center      ">
                                <h2 class="m-auto">Comentarios</h2>
                            </div>
                            <div class="col-10 m-auto d-flex flex-column">
                                <textarea [(ngModel)]="strComentarioD" class="form-control mt-3" name="nombre" rows="3"
                                    cols="5" placeholder="Escribe un comentario"></textarea>
                                <button class="btn btn-primary w-md-25 mt-2 ms-auto" (click)="fnInsertComentario()">
                                    <i class="fas fa-plus"></i> Agregar </button>
                            </div>
                            <div class="col-12">
                                <div class="notification-container">
                                    <div class="notification" *ngFor="let item of arrayComentarios; let i = index">
                                        <div class="circle-icon">{{item.strAgente}}</div>
                                        <div class="notification-content">
                                            <div class="notification-header">{{item.strNombreAgente}}</div>
                                            <div class="notification-text">{{item.strComentario}}</div>
                                        </div>
                                        <div class="notification-time">{{item.strFechaRequerida}}</div>
                                    </div>
                                </div>
                            </div>

                            <!-- 
                            <div class="col-12 d-flex align-items-center justify-content-md-between    mb-md-3">
                                
                                <div class="col-12 col-md-4 d-flex flex-column "
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span>Estatus</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct?.strEstatus}}</span>
                                </div>
                            </div>

                            <div class="col-12 mb-2"
                                [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                <div class="form-group ">
                                    <textarea [(ngModel)]="contenido" class="custom-summernotesummernote"
                                        id="summernote" #summernote></textarea>
                                </div>
                            </div>
                            <div class="col-12 d-flex mt-2">
                                <select name="accioness" id="acciones" class="form-select m-auto"
                                    [(ngModel)]="strResultado"
                                    [disabled]="objAct.objAct?.strEstatus=='CONCLUIDO'||objAct?.intContador==0">
                                    <option value="">
                                        --Selecciona
                                        una opción--</option>
                                    <option value="1">
                                        EXITOSO</option>
                                    <option value="2">
                                        SIN EXITO
                                    </option>
                                </select>
                            </div> -->
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer p-0">
                <div class="col-12 d-flex my-2 ">
                    <button type="button" class="btn btn-secondary ms-3" data-bs-dismiss="modal"
                        #btnCerrarAct>{{objAct.objAct?.strEstatus=='PENDIENTE' ||
                        objAct.objAct?.strEstatus=='PROCESO'?'Cancelar':'Cerrar'}} </button>
                    <button class="btn btn-success  text-white ms-auto mt-auto"
                        (click)="fnConcluirActividad(objAct?.objAct)"
                        *ngIf="(objAct?.objAct?.strEstatus=='PENDIENTE' || objAct.objAct?.strEstatus=='PROCESO') && (strAgente == objAct?.objAct?.strAgente || strAgente == objAct?.objAct?.strAgente_Crea )">Concluir</button>
                    <button type="button" class="btn btn-primary  ms-auto"
                        *ngIf="(objAct.objAct?.strEstatus=='PENDIENTE' ) && (strAgente == objAct?.objAct?.strAgente || strAgente == objAct?.objAct?.strAgente_Crea )" (click)="fnEnProceso(objAct?.objAct)">En
                        Proceso</button>
                    <button type="button" class="btn btn-success  ms-auto"
                        *ngIf="objAct.objAct?.strEstatus=='POR AUTORIZAR' && objAct.objAct?.strAgente_Crea == strAgente_Crea "
                        (click)="fnAutorizar(objAct?.objAct)">Autorizar</button>
                    <button type="button" class="btn btn-danger  ms-auto"
                        *ngIf="objAct.objAct?.strEstatus=='POR AUTORIZAR' && objAct.objAct?.strAgente_Crea == strAgente_Crea "
                        (click)="fnRechazar(objAct?.objAct)">Rechazar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalCtesclinea" tabindex="-1" aria-labelledby="exampleModalLabel" >
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
            <div class="modal-header titulos py-1 px-2">
                <!--    *ngIf="spinerModal"    -->
                <h2 class="modal-title mb-0 mx-auto text-center" id="exampleModalLabel" *ngIf="spinerModal">Cargando....
                </h2><br>
                <h2 class="modal-title mb-0 mx-auto" id="exampleModalLabel" *ngIf="!spinerModal">{{ObjModal.Titulo}}
                </h2>
                <button type="button" class="btn-close ms-0 me-2" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <h4 class="fw-bold mb-2">
                            {{ObjModal.Subtitulo}}
                        </h4>
                    </div>
                </div>
                <div class="row" *ngIf="spinerModal">
                    <div class="col-12 ">
                        <mat-spinner class="mx-auto"></mat-spinner>
                    </div>
                </div>

                <div class="row"
                    *ngIf="(ObjModal.strMdal=='tipoCte'|| ObjModal.strMdal=='ctePorLinea')  && !spinerModal">
                    <div class="row " [ngClass]="{'justify-content-evenly':strGerente != '' || strSucursal != ''}">
                        <div class="col-12 col-md-6 col-xl-3 mb-3" *ngIf="strGerente != '' || strSucursal != '' ">
                            <select class="form-select" aria-label="Default select example" [(ngModel)]="strSucursal"
                                (change)="fngetfiltrar4500(strSucursal, ObjModal.intPeriodo,ObjModal.strMdal)">
                                <option selected value=""> --Todas--</option>
                                <option *ngFor="let suc of arraySucursales" [value]="suc.strSucursal">{{suc.strEstatus}}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-md-6 col-xl-3 mb-3">
                            <input type="text" placeholder="buscar por cliente" [(ngModel)]="filtroCliente"
                                class="form-control">
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between mb-2">
                        <span>
                            Total de movimientos:<span class="fw-bold">{{ObjModal.totalmov}}</span>
                        </span>
                        <span>
                            Importe total: <span class="fw-bold">{{fnImporteTotalListCte()|currency}}</span>
                        </span>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive mb-2 borderbott bordertop shadow">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr class="csldark1 sticky-top">
                                        <th class="fs12">Cliente</th>
                                        <th class="fs12">Nombre</th>
                                        <th class="fs12" *ngIf="strGerente != '' || strSucursal != '' ">Sucursal</th>
                                        <th class="fs12">Ejercicio</th>
                                        <th class="fs12">Margen</th>
                                        <th class="fs12">Importe</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let o of ObjModal.datos |  filtersearch: filtroCliente: ['strCliente', 'strClienteNombre']">
                                        <td class="text-center fs12">{{o.strCliente}}</td>
                                        <td class="text-center fs10">{{o.strClienteNombre}}</td>
                                        <td class="fs12" *ngIf="strGerente != '' || strSucursal != '' ">
                                            {{o.strSucursal}}</td>
                                        <td class="text-center fs10">{{o.intEjercicio}}</td>
                                        <td class="text-end fs10">{{o.dblMargen|number:'1.2-2'}}</td>
                                        <td class="text-end fs10">{{o.dblImporteConFactor|currency}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer titulos py-1 px-2">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div #MConcluirActividadesInicio class="modal fade" id="MConcluirActividadesInicio" aria-hidden="true" aria-labelledby="MConcluirActividadesInicio" tabindex="-1" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5 mb-0 mx-auto fw-bold">
                    HOLA <span class="uppercase"> {{strNombre}}</span>, TIENES <b class="text-danger">TAREAS PENDIENTES</b> REVISALAS AQUÍ
                </h1>
                <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row" #mdlBody2>
                    <!-- Botón para abrir el menú lateral en móviles -->
                    <div class="col-12 d-lg-none">
                        <button class="btn btn-primary w-100 my-2" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#mobileSidebar" aria-controls="mobileSidebar">
                            <i class="bi bi-list"></i> Ver Actividades Pendientes
                        </button>
                    </div>

                    <!-- Menú lateral en móviles (usando offcanvas) -->
                    <div class="offcanvas offcanvas-start d-lg-none px-0" tabindex="-1" id="mobileSidebar"
                        aria-labelledby="mobileSidebarLabel">
                        <div class="offcanvas-header">
                            <h3 class="offcanvas-title fw-bold mb-0"  id="mobileSidebarLabel">Actividades Pendientes</h3>
                            <button type="button" #btnCanvasInicio id="botonCerrarOffcanva" class="btn-close" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <nav>
                                <ul class="nav flex-column">
                                    <ng-container *ngFor="let x of arrayActHoy; let ind = index">
                                        <li class="nav-item">
                                            <div 
                                                class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger"
                                                [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                                <div class="col-12  bordertop text-center clsHoy" (click)="fnSeleccionarAct(x,ind)">
                                                    <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                                </div>
                                                <div
                                                    class="col-12 d-flex justify-content-evenly align-items-center py-2" (click)="fnSeleccionarAct(x,ind)">
                                                    <div class="col-2">
                                                        <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                                        'bi-person-walking':x.strTipo=='Visita',
                                                        'bi-envelope-fill':x.strTipo=='Correo',
                                                        'bi-list-task':x.strTipo=='Tarea'}"></i>
                                                    </div>
                                                    <div class="col-9 d-flex flex-column">
                                                        <span
                                                            class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                                        <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12  clsHoy borderbott d-flex align-items-center justify-content-between">
                                                    <button (click)="fnSelectTarea(x,false)" class="btn btn-primary me-auto mt-1 mb-1 mx-2" style="--bs-btn-padding-y: .16rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                                                        <i class="fas fa-stream"></i>
                                                        <span class="badge rounded-pill text-bg-light mx-1">{{x.dblCantidad?x.dblCantidad:0}}</span></button>
                                                        <span (click)="fnSelectTarea(x,false)" class="btn  shadow-sm  btn-warning py-0 px-1" *ngIf="x.strAgente != strAgente && strAgente != ''" matTooltip="Existencia de subActividades">
                                                            <i class="bi bi-question-diamond-fill"></i>
                                                        </span><span class="fs13 ms-auto ">
                                                        {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>





                                    <ng-container *ngFor="let x of arrayActAtrasadas; let ind = index">
                                        <li class="nav-item">
                                            <div 
                                                class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger"
                                                [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                                <div class="col-12 clsVencido bordertop text-center " (click)="fnSeleccionarAct(x,ind)">
                                                    <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                                </div>
                                                <div (click)="fnSeleccionarAct(x,ind)"
                                                    class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                                    <div class="col-2">
                                                        <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                                        'bi-person-walking':x.strTipo=='Visita',
                                                        'bi-envelope-fill':x.strTipo=='Correo',
                                                        'bi-list-task':x.strTipo=='Tarea'}"></i>
                                                    </div>
                                                    <div class="col-9 d-flex flex-column">
                                                        <span
                                                            class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                                        <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 clsVencido borderbott d-flex align-items-center justify-content-between">
                                                    <button (click)="fnSelectTarea(x,false)" type="button" class="btn btn-primary me-auto mt-1 mb-1 mx-2" style="--bs-btn-padding-y: .16rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                                                        <i class="fas fa-stream"></i> <span class="badge rounded-pill text-bg-light mx-1">{{x.dblCantidad?x.dblCantidad:0}}</span>
                                                      </button>
                                                      <span (click)="fnSelectTarea(x,false)" class="btn  shadow-sm  btn-warning py-0 px-1" *ngIf="x.strAgente != strAgente && strAgente != ''" matTooltip="Existencia de subActividades">
                                                        <i class="bi bi-question-diamond-fill"></i>
                                                    </span>
                                                    <!-- <button class=" btn btn-sm btn-primary "><span class="bagde bg- text-dark mx-1 p-1">0</span></button> -->
                                                    <span class="fs13 pe-2 ">
                                                        {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>




                                    <ng-container *ngFor="let x of arrayActProximas; let ind = index">
                                        <li class="nav-item">
                                            <div 
                                                class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger"
                                                [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                                <div (click)="fnSeleccionarAct(x,ind)" class="col-12 clsProxima bordertop text-center ">
                                                    <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                                </div>
                                                <div (click)="fnSeleccionarAct(x,ind)"
                                                    class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                                    <div class="col-2">
                                                        <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                                        'bi-person-walking':x.strTipo=='Visita',
                                                        'bi-envelope-fill':x.strTipo=='Correo',
                                                        'bi-list-task':x.strTipo=='Tarea'}"></i>
                                                    </div>
                                                    <div class="col-9 d-flex flex-column">
                                                        <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                                        <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 clsProxima borderbott d-flex align-items-center justify-content-between">
                                                    <button (click)="fnSelectTarea(x,false)" type="button" class="btn btn-primary mt-1 mb-1 ms-2" style="--bs-btn-padding-y: .16rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                                                        <i class="fas fa-stream"></i> <span class="badge rounded-pill text-bg-light mx-1">{{x.dblCantidad?x.dblCantidad:0}}</span>
                                                    </button>
                                                    <span (click)="fnSelectTarea(x,false)" class="btn  shadow-sm  btn-warning py-0 px-1" *ngIf="x.strAgente != strAgente && strAgente != ''" matTooltip="Existencia de subActividades">
                                                        <i class="bi bi-question-diamond-fill"></i>
                                                    </span>
                                                    <span class="fs13  pe-2 ">
                                                        {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <!-- Panel lateral en escritorio -->
                    <div class="col-md-4 d-none d-md-block">
                        <div class="colo-12 justify-content-center d-flex" *ngIf="spiner">
                            <mat-spinner class="m-auto"></mat-spinner>
                        </div>
                        <div class="col-12 d-flex flex-column" [style.height]="altoModal2 + 'px'" style="overflow-y: auto;">
                            <ng-container *ngFor="let x of arrayActHoy; let ind = index">
                                <div  class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger" [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                    <div class=" clsHoy col-12  bordertop text-center d-flex">
                                        <span class="m-auto ps-2 fw-bold ">{{x.strTitulo}}</span>
                                    </div>
                                    <div (click)="fnSeleccionarAct(x,ind)" class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                        <div class="col-2">
                                            <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                            'bi-person-walking':x.strTipo=='Visita',
                                            'bi-envelope-fill':x.strTipo=='Correo',
                                            'bi-list-task':x.strTipo=='Tarea'}"></i>
                                        </div>
                                        <div class="col-9 d-flex flex-column">
                                            <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                            <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12  clsHoy borderbott d-flex align-items-center justify-content-between">
                                        <button (click)="fnSelectTarea(x,false)" class="btn btn-primary me-auto mt-1 mb-1 mx-2" style="--bs-btn-padding-y: .16rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                                            <i class="fas fa-stream"></i>
                                            <span class="badge rounded-pill text-bg-light mx-1">{{x.dblCantidad?x.dblCantidad:0}}</span></button>
                                            <span (click)="fnSelectTarea(x,false)" class="btn  shadow-sm  btn-warning py-0 px-1" *ngIf="x.strAgente != strAgente && strAgente != ''" matTooltip="Existencia de subActividades">
                                                <i class="bi bi-question-diamond-fill"></i>
                                            </span>
                                            <span class="fs13 pe-2 ">
                                            {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let x of arrayActAtrasadas; let ind = index">
                                <div  class=" col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger"
                                    [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                    <div (click)="fnSeleccionarAct(x,ind)" class="col-12 clsVencido bordertop text-center ">
                                        <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                    </div>
                                    <div (click)="fnSeleccionarAct(x,ind)" class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                        <div class="col-2">
                                            <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                            'bi-person-walking':x.strTipo=='Visita',
                                            'bi-envelope-fill':x.strTipo=='Correo',
                                            'bi-list-task':x.strTipo=='Tarea'}"></i>
                                        </div>
                                        <div class="col-9 d-flex flex-column">
                                            <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                            <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 clsVencido borderbott d-flex align-items-center justify-content-between">
                                        <button (click)="fnSelectTarea(x,false)" type="button" class="btn btn-primary me-auto mt-1 mb-1 mx-2" style="--bs-btn-padding-y: .16rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                                            <i class="fas fa-stream"></i> <span class="badge rounded-pill text-bg-light mx-1">{{x.dblCantidad?x.dblCantidad:0}}</span>
                                          </button>
                                          <span (click)="fnSelectTarea(x,false)" class="btn  shadow-sm  btn-warning py-0 px-1" *ngIf="x.strAgente != strAgente && strAgente != ''" matTooltip="Existencia de subActividades">
                                            <i class="bi bi-question-diamond-fill"></i>
                                        </span>
                                        <!-- <button class=" btn btn-sm btn-primary "><span class="bagde bg- text-dark mx-1 p-1">0</span></button> -->
                                        <span class="fs13 pe-2 ">
                                            {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngFor="let x of arrayActProximas; let ind = index">
                                <div 
                                    class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger"
                                    [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                    <div (click)="fnSeleccionarAct(x,ind)" class="col-12 clsProxima bordertop text-center ">

                                        <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                    </div>
                                    <div (click)="fnSeleccionarAct(x,ind)" class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                        <div class="col-2">
                                            <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                            'bi-person-walking':x.strTipo=='Visita',
                                            'bi-envelope-fill':x.strTipo=='Correo',
                                            'bi-list-task':x.strTipo=='Tarea'}"></i>
                                        </div>
                                        <div class="col-9 d-flex flex-column">
                                            <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                            <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 clsProxima borderbott d-flex align-items-center justify-content-between">
                                        <button (click)="fnSelectTarea(x,false)" type="button" class="btn btn-primary mt-1 mb-1 ms-2" style="--bs-btn-padding-y: .16rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                                            <i class="fas fa-stream"></i> <span class="badge rounded-pill text-bg-light mx-1">{{x.dblCantidad?x.dblCantidad:0}}</span>
                                        </button>
                                        <span (click)="fnSelectTarea(x,false)" class="btn  shadow-sm  btn-warning py-0 px-1" *ngIf="x.strAgente != strAgente && strAgente != ''" matTooltip="Existencia de subActividades">
                                            <i class="bi bi-question-diamond-fill"></i>
                                        </span>
                                        <span class="fs13  pe-2 ">
                                            {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <!-- Contenido principal -->
                    <div class="col-md-8 col-12">
                        <div class="row ">
                            <div class="col-12 d-flex flex-column flex-md-row justify-content-between align-items-md-center mt-2 mb-2  text-center text-md-center"
                                [ngClass]="{'visually-hidden': objAct.objAct?.strEstatus == 'CONCLUIDO'}">

                                <!-- Título centrado en md+ y centrado en sm -->
                                <h3 class="titulo fw-bold mb-0 fs-5 py-3 d-flex flex-column justify-content-start">
                                    <span>
                                        {{ objAct.objAct?.strTitulo ? objAct.objAct.strTitulo : '--Seleccione opción--' }}
                                    </span> 
                                    <span class="fs12 me-auto" *ngIf="objAct.objAct?.strClasificacion_Actividad" [ngClass]="{'text-danger':objAct.objAct?.strClasificacion_Actividad == 'ATRASADO','text-primary':objAct.objAct?.strClasificacion_Actividad == 'PROXIMO','text-success':objAct.objAct?.strClasificacion_Actividad == 'HOY'}">
                                        (ACTIVIDAD: {{objAct.objAct?.strClasificacion_Actividad == 'ATRASADO' ? 'VENCIDA':objAct.objAct?.strClasificacion_Actividad == 'PROXIMO'?'PROXIMA':'HOY'}})
                                    </span>
                                </h3>

                                <!-- Estatus alineado a la derecha en md+ y centrado en sm -->
                                 <div class="d-flex">
                                    <span class=" shadow-sm borderbott bordertop px-2 py-1 d-flex flex-column text-center"  [ngClass]="{'bg-secondary':objAct?.objAct?.strEstatus!= 'CONCLUIDO' && objAct?.objAct?.strEstatus!= 'PROCESO' && objAct?.objAct?.strEstatus!= 'PENDIENTE','bg-success':objAct?.objAct?.strEstatus== 'CONCLUIDO','bgInfo':objAct?.objAct?.strEstatus== 'PROCESO','bg-warning':objAct?.objAct?.strEstatus== 'PENDIENTE'}">
                                        <span class="fs12">Estatus</span>
                                        <span class="fw-bold fs12">
                                            {{ objAct.objAct?.strEstatus ? objAct.objAct.strEstatus : '--Seleccione opción--' }}
                                        </span>
                                    </span>
                                 </div>
                                
                            </div>



                    


                            <div class="col-6">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-person-fill-gear clsIconModal "></i>
                                        Responsable:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strNombreAgente?objAct.objAct.strNombreAgente:'--Seleccione--'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-person-circle clsIconModal"></i> Asignado por:
                                    </span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.nombreCrea?objAct.objAct.nombreCrea:'--Seleccione--'}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Inicio:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strHoraInicio?objAct.objAct.strHoraInicio:'00'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Fin:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strHoraFin?objAct.objAct.strHoraFin:'00'}}</span>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i>
                                        ASUNTO</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objAct.objAct&&objAct.objAct.strAsunto?objAct.objAct.strAsunto:'--Seleccione --'"></span>
                                </div>
                            </div>
                            <div class="col-12 mt-3" *ngIf="objAct.objAct&&objAct.objAct.strDescripcion">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i>
                                        OBSERVACIONES</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objAct.objAct&&objAct.objAct.strDescripcion?objAct.objAct.strDescripcion:'--Seleccione --'"></span>
                                </div>
                            </div>

                            <div class="col-12 mb-2 mt-3"
                                [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'||objAct.objAct&&objAct.objAct.strDescripcion}">
                                <div class="form-group " [hidden]="strAgente != objAct?.objAct?.strAgente ">
                                    <textarea  [(ngModel)]="contenido" class="custom-summernotesummernote"
                                        id="summernoteInicio" #summernote></textarea>
                                </div>
                            </div>
                            <div class="col-12 d-flex mt-2"
                                [ngClass]="{'visually-hidden':objAct.objAct&&objAct.objAct.strDescripcion}">
                                <select  name="accioness" id="acciones" class="form-select m-auto"
                                    [(ngModel)]="strResultado"
                                    [disabled]="objAct.objAct?.strEstatus=='CONCLUIDO'||objAct?.intContador==0 || strAgente != objAct?.objAct?.strAgente ">
                                    <option value="">
                                        --Selecciona
                                        una opción--</option>
                                    <option value="1">
                                        EXITOSO</option>
                                    <option value="2">
                                        SIN EXITO
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 d-flex my-2 mt-3">
                                <button  type="button" class="btn btn-secondary ms-3" data-bs-dismiss="modal"
                                    #btnCerrarAct>{{objAct.objAct?.strEstatus=='PENDIENTE' ||
                                    objAct.objAct?.strEstatus=='PROCESO'?'Cancelar':'Cerrar'}} </button>
                                <button class="btn btn-success  text-white ms-auto mt-auto"
                                    (click)="fnConcluirActividad(objAct?.objAct)"
                                    *ngIf="(objAct?.objAct?.strEstatus=='PENDIENTE' || objAct.objAct?.strEstatus=='PROCESO' ) && (strAgente == objAct?.objAct?.strAgente || strAgente == objAct?.objAct?.strAgente_Crea )">Concluir</button>
                                <button type="button" class="btn btn-primary  ms-auto"
                                    *ngIf="(objAct.objAct?.strEstatus=='PENDIENTE'  ) && (strAgente == objAct?.objAct?.strAgente || strAgente == objAct?.objAct?.strAgente_Crea )"
                                    (click)="fnEnProceso(objAct?.objAct)">En
                                    Proceso</button>
                                <button type="button" class="btn btn-success  ms-auto"
                                    *ngIf="objAct.objAct?.strEstatus=='POR AUTORIZAR' && objAct.objAct?.strAgente_Crea == strAgente_Crea  "
                                    (click)="fnAutorizar(objAct?.objAct)">Autorizar</button>
                                <button type="button" class="btn btn-danger  ms-auto"
                                    *ngIf="objAct.objAct?.strEstatus=='POR AUTORIZAR' && objAct.objAct?.strAgente_Crea == strAgente_Crea "
                                    (click)="fnRechazar(objAct?.objAct)">Rechazar</button>
                            </div>

                        </div>
                        <div class="row mt-2 colorFondo" style="border-top: 2px dashed #b3abab;">
                            <div
                                class=" mt-4 col-12 colorComentarios d-flex justify-content-center align-items-center      ">
                                <h2 class="m-auto">Comentarios</h2>
                            </div>
                            <div class="col-10 m-auto d-flex flex-column ">
                                <textarea [(ngModel)]="strComentarioD" class="form-control mt-3" name="nombre" rows="3"
                                    cols="5" placeholder="Escribe un comentario"></textarea>
                                <button class="btn btn-primary w-md-25 mt-2 ms-auto" (click)="fnInsertComentario()">
                                    <i class="fas fa-plus"></i> Agregar </button>
                            </div>
                            <div class="col-12 ">
                                <div class="notification-container">
                                    <div class="notification" *ngFor="let item of arrayComentarios; let i = index">
                                        <div class="circle-icon">{{item.strAgente}}</div>
                                        <div class="notification-content">
                                            <div class="notification-header">{{item.strNombreAgente}}</div>
                                            <div class="notification-text">{{item.strComentario}}</div>
                                        </div>
                                        <div class="notification-time">{{item.strFechaRequerida}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div #MConcluirSubTareas class="modal fade" id="MConcluirSubTareas" aria-hidden="true" aria-labelledby="MConcluirSubTareas" tabindex="-1" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex">
                <button (click)="fncerraract()" *ngIf="boolModal" class="btn btn-primary btn-sm me-auto" ><i class="fas fa-chevron-left"></i></button>
                <button (click)="fnCerrar()" *ngIf="!boolModal"class="btn btn-secondary btn-sm me-auto" ><i class="fas fa-chevron-left"></i></button>
                <h1 class="modal-title fs-5 mb-0 mx-auto fw-bold">
                    <span class="uppercase"> {{objSubTarea.strTitulo}}</span>
                </h1>
                <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" >
                <div class="row" #mdlBody3>
                    <!-- Botón para abrir el menú lateral en móviles -->
                    <div class="col-12 d-lg-none">
                        <button class="btn btn-primary w-100 my-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileSidebarSubTareas" aria-controls="mobileSidebarSubTareas">
                            <i class="bi bi-list"></i> Ver Sub Actividades Pendientes
                        </button>
                    </div>

                    <!-- Menú lateral en móviles (usando offcanvas) -->
                    <div class="offcanvas offcanvas-start d-lg-none px-0" tabindex="-1" id="mobileSidebarSubTareas"
                        aria-labelledby="mobileSidebarSubTareasLabel">
                        <div class="offcanvas-header ">
                            <h3 class="offcanvas-title fw-bold pb-0" id="mobileSidebarSubTareasLabel">Sub Tareas Pendientes</h3>
                            <button type="button" #btnCanvasSubAct id="botonCerrarOffcanva" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <nav>
                                <ul class="nav flex-column">

                                    <div class="colo-12 justify-content-center d-flex" *ngIf="spiner">
                                        <mat-spinner class="m-auto"></mat-spinner>
                                    </div>
                                    <div  class="col-12 d-flex flex-column"  style="overflow-y: auto;height: 100% !important">
                                        <div class="col-10 mx-auto text-center">
                                            <button (click)="fnAgregarSubTarea()" [ngClass]="{'shadow-sm':boolAddSubTarea==false,'borderSelect':boolAddSubTarea==true}" class="btn btnSubTarea w-100 d-flex align-items-center justify-content-center py-0 px-2">
                                               <span class="fs13 me-2 fw-bold">Agregar Sub-Tarea </span>  <i class="bi bi-plus-square-fill text-primary btn-primary"></i>
                                            </button>
                                        </div>
                                        <ng-container *ngFor="let x of arraySubTareas; let ind = index">
                                            <div (click)="fnSeleccionarAct(x,ind,true)" class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger" [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                                <div class="clsSubTarea  col-12  bordertop text-center ">
                                                    <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                                </div>
                                                <div class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                                    <div class="col-2">
                                                        <div class="d-flex justify-content-center">
                                                            <div class=" "
                                                                *ngIf="x.strEstatus != 'POR AUTORIZAR' && x.strEstatus != 'PROCESO'">
                                                                <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                                    disabled [checked]="x.strEstatus == 'CONCLUIDO'"
                                                                    type="checkbox" id="customRadioCheckbox">
                                                            </div>
                                                            <div class=" text-center" *ngIf="x.strEstatus == 'POR AUTORIZAR'">
                                                                <button type="button"
                                                                    class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                        class="fa-solid fa-bell"></i></button>
                                                            </div>
                                                            <div class=" text-center" *ngIf="x.strEstatus == 'PROCESO'">
                                                                <button type="button"
                                                                    class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                                    title="En Proceso"><i
                                                                        class="fa-solid fa-circle-half-stroke"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-9 d-flex flex-column">
                                                        <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                                        <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 clsSubTarea borderbott text-end">
                                                    <span class="fs13 text-end  col-12 pe-2 ">
                                                        {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

<!-- 
                                    <ng-container *ngFor="let x of arrayActHoy; let ind = index">
                                        <li class="nav-item">
                                            <div (click)="fnSeleccionarAct(x,ind)"
                                                class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger"
                                                [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                                <div class="col-12  bordertop text-center clsHoy">
                                                    <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                                </div>
                                                <div
                                                    class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                                    <div class="col-2">
                                                        <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                                        'bi-person-walking':x.strTipo=='Visita',
                                                        'bi-envelope-fill':x.strTipo=='Correo',
                                                        'bi-list-task':x.strTipo=='Tarea'}"></i>
                                                    </div>
                                                    <div class="col-9 d-flex flex-column">
                                                        <span
                                                            class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                                        <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 clsHoy borderbott text-end">
                                                    <span class="fs13 text-end  col-12 pe-2 ">
                                                        {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>





                                    <ng-container *ngFor="let x of arrayActAtrasadas; let ind = index">
                                        <li class="nav-item">
                                            <div (click)="fnSeleccionarAct(x,ind)"
                                                class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger"
                                                [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                                <div class="col-12 clsVencido bordertop text-center ">
                                                    <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                                </div>
                                                <div
                                                    class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                                    <div class="col-2">
                                                        <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                                        'bi-person-walking':x.strTipo=='Visita',
                                                        'bi-envelope-fill':x.strTipo=='Correo',
                                                        'bi-list-task':x.strTipo=='Tarea'}"></i>
                                                    </div>
                                                    <div class="col-9 d-flex flex-column">
                                                        <span
                                                            class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                                        <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 clsVencido borderbott text-end">
                                                    <span class="fs13 text-end col-12 pe-2 ">
                                                        {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>




                                    <ng-container *ngFor="let x of arrayActProximas; let ind = index">
                                        <li class="nav-item">
                                            <div (click)="fnSeleccionarAct(x,ind)"
                                                class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger"
                                                [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                                <div class="col-12 clsProxima bordertop text-center ">
                                                    <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                                </div>
                                                <div
                                                    class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                                    <div class="col-2">
                                                        <i class="bi fs-6 text-dark" [ngClass]="{'bi-telephone-fill':x.strTipo=='Llamada',
                                                        'bi-person-walking':x.strTipo=='Visita',
                                                        'bi-envelope-fill':x.strTipo=='Correo',
                                                        'bi-list-task':x.strTipo=='Tarea'}"></i>
                                                    </div>
                                                    <div class="col-9 d-flex flex-column">
                                                        <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                                        <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 clsProxima borderbott text-end">
                                                    <span class="fs13 text-end col-12 pe-2 ">
                                                        {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>
 -->






                                </ul>
                            </nav>
                        </div>
                    </div>

                    <!-- Panel lateral en escritorio -->
                    <div class="col-md-4 d-none d-md-block">
                        <div class="colo-12 justify-content-center d-flex" *ngIf="spiner">
                            <mat-spinner class="m-auto"></mat-spinner>
                        </div>
                        <div  class="col-12 d-flex flex-column"  style="overflow-y: auto;height: 100% !important">
                            <div class="col-10 mx-auto text-center">
                                <button (click)="fnAgregarSubTarea()" [ngClass]="{'shadow-sm':boolAddSubTarea==false,'borderSelect':boolAddSubTarea==true}" class="btn btnSubTarea w-100 d-flex align-items-center justify-content-center py-0 px-2">
                                   <span class="fs13 me-2 fw-bold">Agregar Sub-Tarea </span>  <i class="bi bi-plus-square-fill text-primary btn-primary"></i>
                                </button>
                            </div>
                            <ng-container *ngFor="let x of arraySubTareas; let ind = index">
                                <div (click)="fnSeleccionarAct(x,ind,true)" class="borderSelect col-10 mx-auto bordertop borderbott mt-3 bgCards text-danger" [ngClass]="{'shadow-sm':x.select==false,'borderSelect':x.select==true}">
                                    <div class="clsSubTarea  col-12  bordertop text-center ">
                                        <span class="col-12 ps-2 fw-bold ">{{x.strCliente}}</span>
                                    </div>
                                    <div class="col-12 d-flex justify-content-evenly align-items-center py-2">
                                        <div class="col-2">
                                            <div class="d-flex justify-content-center">
                                                <div class=" "
                                                    *ngIf="x.strEstatus != 'POR AUTORIZAR' && x.strEstatus != 'PROCESO'">
                                                    <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                        disabled [checked]="x.strEstatus == 'CONCLUIDO'"
                                                        type="checkbox" id="customRadioCheckbox">
                                                </div>
                                                <div class=" text-center" *ngIf="x.strEstatus == 'POR AUTORIZAR'">
                                                    <button type="button"
                                                        class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                            class="fa-solid fa-bell"></i></button>
                                                </div>
                                                <div class=" text-center" *ngIf="x.strEstatus == 'PROCESO'">
                                                    <button type="button"
                                                        class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                        title="En Proceso"><i
                                                            class="fa-solid fa-circle-half-stroke"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-9 d-flex flex-column">
                                            <span class="fs12 fw-bold text-dark">{{x.strNombreEmpresa}}</span>
                                            <span class="fs12 fw-normal text-dark">{{x.strTitulo}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12 clsSubTarea borderbott text-end">
                                        <span class="fs13 text-end  col-12 pe-2 ">
                                            {{x.strFechaRequerida | date:'dd/MM/yyyy'}}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <!-- Contenido principal -->
                    <div [hidden]="boolAddSubTarea" class="col-md-8 col-12">
                        <div class="row ">
                            <div class="col-12 d-flex flex-column flex-md-row justify-content-between align-items-md-center mt-2 mb-2  text-center text-md-center"
                                [ngClass]="{'visually-hidden': objAct.objAct?.strEstatus == 'CONCLUIDO'}">

                                <!-- Título centrado en md+ y centrado en sm -->
                                <h3 class="titulo fw-bold mb-0 fs-5 py-3 d-flex flex-column justify-content-start">
                                    <span>
                                        {{ objAct.objAct?.strTitulo ? objAct.objAct.strTitulo : '--Seleccione opción--' }}
                                    </span> 
                                    <span class="fs12 me-auto" *ngIf="objAct.objAct?.strClasificacion_Actividad" [ngClass]="{'text-danger':objAct.objAct?.strClasificacion_Actividad == 'ATRASADO','text-primary':objAct.objAct?.strClasificacion_Actividad == 'PROXIMO','text-success':objAct.objAct?.strClasificacion_Actividad == 'HOY'}">
                                        (ACTIVIDAD: {{objAct.objAct?.strClasificacion_Actividad == 'ATRASADO' ? 'VENCIDA':objAct.objAct?.strClasificacion_Actividad == 'PROXIMO'?'PROXIMA':'HOY'}})
                                    </span>
                                </h3>
                                <!-- Estatus alineado a la derecha en md+ y centrado en sm -->
                                <span
                                    class=" text-white shadow-sm borderbott bordertop px-2 py-1 d-flex flex-column text-center" [ngClass]="{'bg-secondary':objAct?.objAct?.strEstatus!= 'CONCLUIDO' && objAct?.objAct?.strEstatus!= 'PROCESO' && objAct?.objAct?.strEstatus!= 'PENDIENTE','bg-success':objAct?.objAct?.strEstatus== 'CONCLUIDO','bgInfo':objAct?.objAct?.strEstatus== 'PROCESO','bg-warning':objAct?.objAct?.strEstatus== 'PENDIENTE'}">
                                    <span class="fs12">Estatus</span>
                                    <span class="fw-bold fs12">
                                        {{ objAct.objAct?.strEstatus ? objAct.objAct.strEstatus : '--Seleccione opción--' }}
                                    </span>
                                </span>
                            </div>

                            <div class="col-6">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-person-fill-gear clsIconModal "></i>
                                        Responsable:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strNombreAgente?objAct.objAct.strNombreAgente:'--Seleccione--'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-person-circle clsIconModal"></i> Asignado por:
                                    </span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.nombreCrea?objAct.objAct.nombreCrea:'--Seleccione--'}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Inicio:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strHoraInicio?objAct.objAct.strHoraInicio:'00'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Fin:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct.objAct&&objAct.objAct.strHoraFin?objAct.objAct.strHoraFin:'00'}}</span>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i>
                                        ASUNTO</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objAct.objAct&&objAct.objAct.strAsunto?objAct.objAct.strAsunto:'--Seleccione --'"></span>
                                </div>
                            </div>
                            <div class="col-12 mt-3" *ngIf="objAct.objAct&&objAct.objAct.strDescripcion">
                                <div class="col-12 d-flex flex-column"
                                    [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'}">
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i>
                                        OBSERVACIONES</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objAct.objAct&&objAct.objAct.strDescripcion?objAct.objAct.strDescripcion:'--Seleccione --'"></span>
                                </div>
                            </div>

                            <div class="col-12 mb-2 mt-3"
                                [ngClass]="{'visually-hidden':objAct.objAct?.strEstatus == 'CONCLUIDO'||objAct.objAct&&objAct.objAct.strDescripcion}">
                                <div class="form-group ">
                                    <textarea [(ngModel)]="contenido" class="custom-summernotesummernote"
                                        id="summernoteInicio2" #summernoteInicio></textarea>
                                </div>
                            </div>
                            <div class="col-12 d-flex mt-2"
                                [ngClass]="{'visually-hidden':objAct.objAct&&objAct.objAct.strDescripcion}">
                                <select name="accioness" id="acciones" class="form-select m-auto"
                                    [(ngModel)]="strResultado"
                                    [disabled]="objAct.objAct?.strEstatus=='CONCLUIDO'||objAct?.intContador==0">
                                    <option value="">
                                        --Selecciona
                                        una opción--</option>
                                    <option value="1">
                                        EXITOSO</option>
                                    <option value="2">
                                        SIN EXITO
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 d-flex my-2 mt-3">
                                <button type="button" class="btn btn-secondary ms-3" data-bs-dismiss="modal"
                                    #btnCerrarAct>{{objAct.objAct?.strEstatus=='PENDIENTE' ||
                                    objAct.objAct?.strEstatus=='PROCESO'?'Cancelar':'Cerrar'}} </button>
                                <button class="btn btn-success  text-white ms-auto mt-auto"
                                    (click)="fnConcluirActividad(objAct?.objAct)"
                                    *ngIf="(objAct?.objAct?.strEstatus=='PENDIENTE' || objAct.objAct?.strEstatus=='PROCESO' ) && (strAgente == objAct?.objAct?.strAgente || strAgente == objAct?.objAct?.strAgente_Crea )" >Concluir</button>
                                <button type="button" class="btn btn-primary  ms-auto"
                                    *ngIf="(objAct.objAct?.strEstatus=='PENDIENTE'  ) && (strAgente == objAct?.objAct?.strAgente || strAgente == objAct?.objAct?.strAgente_Crea ) "
                                    (click)="fnEnProceso(objAct?.objAct)">En
                                    Proceso</button>
                                <button type="button" class="btn btn-success  ms-auto"
                                    *ngIf="objAct.objAct?.strEstatus=='POR AUTORIZAR' && objAct.objAct?.strAgente_Crea == strAgente_Crea "
                                    (click)="fnAutorizar(objAct?.objAct)">Autorizar</button>
                                <button type="button" class="btn btn-danger  ms-auto"
                                    *ngIf="objAct.objAct?.strEstatus=='POR AUTORIZAR' && objAct.objAct?.strAgente_Crea == strAgente_Crea "
                                    (click)="fnRechazar(objAct?.objAct)">Rechazar</button>
                            </div>

                        </div>
                        <div class="row mt-2 colorFondo" style="border-top: 2px dashed #b3abab;">
                            <div
                                class=" mt-4 col-12 colorComentarios d-flex justify-content-center align-items-center      ">
                                <h2 class="m-auto">Comentarios</h2>
                            </div>
                            <div class="col-10 m-auto d-flex flex-column ">
                                <textarea [(ngModel)]="strComentarioD" class="form-control mt-3" name="nombre" rows="3" cols="5" placeholder="Escribe un comentario"></textarea>
                                <button class="btn btn-primary w-md-25 mt-2 ms-auto" (click)="fnInsertComentario()">
                                    <i class="fas fa-plus"></i> 
                                    Agregar 
                                </button>
                            </div>
                            <div class="col-12 ">
                                <div class="notification-container">
                                    <div class="notification" *ngFor="let item of arrayComentarios; let i = index">
                                        <div class="circle-icon">{{item.strAgente}}</div>
                                        <div class="notification-content">
                                            <div class="notification-header">{{item.strNombreAgente}}</div>
                                            <div class="notification-text">{{item.strComentario}}</div>
                                        </div>
                                        <div class="notification-time">{{item.strFechaRequerida}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="!boolAddSubTarea" class="col-md-8 col-12">
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <div class="col-12 mb-3">
                                    <div class="col-12">
                                        <h3 class="mb-1 fw-bold text-center">
                                            RESPONSABLES
                                        </h3>
                                    </div>
                                    <mat-form-field class="example-full-width w-100">
                                        <span matPrefix> </span>
                                        <input type="text" class="px-3" matInput placeholder="Nombre agente" autocomplete="off"
                                            (keyup)="fnBusquedaCte(strBuscarAgente);" name="search"
                                            [(ngModel)]="strBuscarAgente">
                                        <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                                        <li class="list-group-item cursorPoint hver py-1 fs10"
                                            *ngFor="let agt of arrayAgente_Filter" (click)="fnSeleccionaAgente(agt)">
                                            {{agt.strNombre}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12" *ngFor="let agt of arrayAgentesActividades">
                                    <button class="btn btn-secondary position-relative fs12 shadow-sm col-12 mb-2">
                                        ({{agt.strAgente}}) - {{agt.strNombre}}
                                        <i (click)="fnEliminarAgenteAct(agt)"
                                            class="fa-solid fa-x position-absolute top-0 end-0 mt-1 me-1 fs11"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <div class="col-5">
                                        <div class="col-12">
                                            Asunto
                                        </div>
                                        <div class="col-12">
                                            <input type="text" placeholder="Agregue asunto" class="form-control"
                                                [(ngModel)]="mdlNombre">
                                        </div>
                                    </div>
                                    <div class="col-2">
                                    </div>
                                    <div class="col-5">
                                        <div class="col-12">
                                            Fecha Compromiso
                                        </div>
                                        <div class="col-12">
                                            <input type="date" class="form-control" [(ngModel)]="strfecha">
                                        </div>
                                    </div>
                                </div>
                                <div class="row my-3">
                                    <div class="col-12 col-md-5" *ngIf="objModal.titulo != 'Tarea'">
                                        <div class="col-12">
                                            Hora Inicio
                                        </div>
                                        <div class="col-12 d-flex">
                                            <select class="form-select w-100" *ngIf="time1=='am'"
                                                aria-label="Default select example" [(ngModel)]="mdlHoraInicio">
                                                <option *ngFor="let x of arrayAm">
                                                    {{x.hora}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2" *ngIf="objModal.titulo != 'Tarea'">
                                    </div>
                                    <div class="col-12 col-md-5 mt-3 mt-md-0" *ngIf="objModal.titulo != 'Tarea'">
                                        <div class="col-12">
                                            Hora Fin
                                        </div>
                                        <div class="col-12 d-flex">
                                            <select class="form-select w-100" *ngIf="time2=='am'"
                                                aria-label="Default select example" [(ngModel)]="mdlHoraFin">
                                                <option *ngFor="let x of arrayAm">
                                                    {{x.hora}}
                                                </option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        Comentario
                                    </div>
                                    <div class="col-12">
                                        <div  #editableDiv2 contenteditable="true" (input)="onInput($event)" class="editable-area" i 
                                            style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;">
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                            <div class="col-12 col-md-3 d-flex mt-3 my-0">
                                <div class="col-12 d-flex">
                                    <div class="col-12 border">
                                        <div class="col-12 px-0 position-relative">
                                            <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                               <span class="ms-2 fs13">ADJUNTO</span>
                                            </h3>
                                            <button class="me-2 btn btnAdd position-absolute top-50 end-0 translate-middle-y py-0 px-2 shadow-sm" (click)="fnAdjuntarArchivosActividad()">
                                                <i class="bi bi-file-earmark-plus"></i>
                                            </button>
                                        </div>
                                        <div class="col-12 px-0">
                                            <input #fileInputActividades type="file" hidden  (change)="onFileSelectedActividad($event)">
                                        </div>
                                        <div class="col-11 mx-auto" *ngIf="arrayAgregarAnexosActividades.length > 0">
                                            <div class="row">
                                                <div class="col-6 d-flex" *ngFor="let anex of arrayAgregarAnexosActividades">
                                                        <div class="col-12 px-0 shadow-sm border text-center">
                                                            <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}" target="_blank">
                                                            <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'" class="bi bi-file-image fs-4 text-primary"></i>
                                                            <i *ngIf="anex.extencion == 'pdf'" class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                            <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'" class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                            <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'" class="bi bi-file-zip fs-4"></i>
                                                            <span class="fs10 text-ellipsi">
                                                                {{anex.nombreArchivo}}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <button class="btn btn-danger fs12 p-1" (click)="fnEliminarAnexosAct()">
                                                        <i class="bi bi-trash3 fs14"></i> Limpiar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn-primary " (click)="fnAnexosActividad()"> Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
</div>
