import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  /*transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }*/
  
  transform(value:any, args: any): any {
    const resultPost=[];
    for(const post of value){
      if(post.strModulo.indexOf(args) > -1){
        resultPost.push(post);
      };
    };
    return resultPost;
    
    //return null;
  }

}


