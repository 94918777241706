import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

@Component({
  selector: 'app-poconexion',
  templateUrl: './poconexion.component.html',
  styleUrls: ['./poconexion.component.css']
})
export class PoconexionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
