import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF,CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms'; //necesario para trabajr con two way data-binding
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatRadioModule} from '@angular/material/radio';
import { MatCurrencyFormatModule } from 'mat-currency-format';
import { MatInputCommifiedDirective } from './mat-input-commified.directive';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule} from '@angular/material/table';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatTabsModule} from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import {MatTooltipModule} from '@angular/material/tooltip';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterPipeCategoria } from './pipes/filterCategoria.pipe';
import { FilterPipeDespachador } from './pipes/filterDespachador.pipe';
import { ClientePedidoPipe } from './pipes/cliente-pedido.pipe';
import { AppRoutingModule } from './app-routing.module';
import { appRoutingProviders } from '../app/app-routing.module';
import { AppComponent } from './app.component';

import { DetalleComponent } from './detalle/detalle.component';
import { ListadoComponent } from './listado/listado.component';

import { PeticionesService } from './servicios/httpservice';
import { CarroService } from './servicios/carroservice';
import { FooterComponent } from './footer/footer.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CarritoresumenComponent } from './carritoresumen/carritoresumen.component';
import { DirectorioComponent } from './directorio/directorio.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BusquedaComponent } from './busqueda/busqueda.component'; 
import { ObjmixnComponent } from './objmixn/objmixn.component';
import { ObjmixgsComponent } from './objmixgs/objmixgs.component';
import { ObjMixAgenteComponent } from './obj-mix-agente/obj-mix-agente.component';
import { ObjmixagenteComponent } from './objmixagente/objmixagente.component';
import { ScordcardgsComponent } from './scordcardgs/scordcardgs.component';
import { ReportecomodatosComponent } from './reportecomodatos/reportecomodatos.component'; 
import { ReportevtaobjcteComponent } from './reportevtaobjcte/reportevtaobjcte.component';
import { DetallevtasvsobjComponent } from './detallevtasvsobj/detallevtasvsobj.component';
import { ObjmixdivisionalComponent } from './objmixdivisional/objmixdivisional.component';
import { ObjmixsucursalComponent } from './objmixsucursal/objmixsucursal.component';
import { LoginComponent } from './login/login.component';  
import { RetoTorkComponent } from './reto-tork/reto-tork.component';
import { RetoTorkBitacoraInicialComponent } from './reto-tork-bitacora-inicial/reto-tork-bitacora-inicial.component';
import { FirmaComponent } from './firma/firma.component';
import { SeguimientotorkComponent } from './seguimientotork/seguimientotork.component';
import { RetoTorkBitacoraSeguimientoComponent } from './reto-tork-bitacora-seguimiento/reto-tork-bitacora-seguimiento.component';
import { DialogAutorizarRetoComponent } from './dialog-autorizar-reto/dialog-autorizar-reto.component';
import { RetoTorkListaDespachadoresComponent, DialogPreguntasContent, DialogPreguntasContentCons } from './reto-tork-lista-despachadores/reto-tork-lista-despachadores.component';
import { PoconexionComponent } from './poconexion/poconexion.component';
import { AutpreciominComponent } from './autpreciomin/autpreciomin.component';
import { DialogPreciosMinimos } from './autpreciomin/autpreciomin.component';
import { NewmenuComponent } from './newmenu/newmenu.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { LoginnComponent } from './loginn/loginn.component'; 
import { CotizadornewComponent } from './cotizadornew/cotizadornew.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { VtexComponent } from './vtex/vtex.component';
import { MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatDatepickerModule} from '@angular/material/datepicker'; 
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { InventariosComponent } from './inventarios/inventarios.component';
import { ComodatosproComponent } from './comodatospro/comodatospro.component';
import { DetallescomodaproComponent } from './detallescomodapro/detallescomodapro.component';
import { ObjmixGteSucursalComponent } from './objmix-gte-sucursal/objmix-gte-sucursal.component';
import { ObjmixGteDivisionalComponent } from './objmix-gte-divisional/objmix-gte-divisional.component';
import { FilterprecioPipe } from './pipes/filterprecio.pipe';
import { ObjmixNacionalComponent } from './objmix-nacional/objmix-nacional.component';
import { CapturadorComponent } from './capturador/capturador.component';
import { ConfigMercadosComponent } from './config-mercados/config-mercados.component';
import { FiltersearchPipe } from './pipes/filtersearch.pipe';
import { PedidosVentasComponent } from './pedidos-ventas/pedidos-ventas.component';
import { LeadsCarteraComponent } from './leads-cartera/leads-cartera.component';
import { PipeTableroPipe } from './pipes/pipe-tablero.pipe';
 import { FullCalendarModule } from '@fullcalendar/angular';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { AromiaComponent } from './aromia/aromia.component'; 
import {InsertarComodatosComponent}from './comodatos/comodatos.component'
import { DatePipe } from '@angular/common';
import { ListadoComodatosComponent } from './listado-comodatos/listado-comodatos.component';
import { CarroComodatosComponent } from './carro-comodatos/carro-comodatos.component';
import { ListadoComodatosTodosComponent } from './listado-comodatos-todos/listado-comodatos-todos.component';
import { ReportePrincipalComponent } from './reporte-principal/reporte-principal.component';
import { PedidosEcodeliComponent } from './pedidos-ecodeli/pedidos-ecodeli.component';
import { InventariosEcodeliComponent } from './inventarios-ecodeli/inventarios-ecodeli.component';
import { PlantillasComponent } from './plantillas/plantillas.component';

import { AgenteFilterPipe } from './pipes/agente-filter.pipe';
import { PruebasComponent } from './pruebas/pruebas.component';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { DetalleOportunidadComponent } from './detalle-oportunidad/detalle-oportunidad.component';
import { SucursalFilterPipe } from './pipes/sucursal-filter.pipe';
import { EcodeliPremiaComponent } from './ecodeli-premia/ecodeli-premia.component';
import { LeadsFamiliasComponent } from './leads-familias/leads-familias.component';
import { LeadComponent } from './lead/lead.component';
import { ProspectoComponent } from './prospecto/prospecto.component';
import { PasarelaPagoComponent } from './pasarela-pago/pasarela-pago.component';
import { AltaclienteComponent } from './altacliente/altacliente.component';
import { FamiliasComponent } from './familias/familias.component';
import { FiltergrupoPipe } from './pipes/filtergrupo.pipe';
import { SolicitudesFamiliasComponent } from './solicitudes-familias/solicitudes-familias.component';
import { DetalleLeadsComponent } from './detalle-leads/detalle-leads.component';
import { FormularioLeadComponent } from './formulario-lead/formulario-lead.component';
import { ComodatosClienteComponent } from './comodatos-cliente/comodatos-cliente.component';
import { DetalleFamiliaComponent } from './detalle-familia/detalle-familia.component';
import { TableroLeadsOportunidadesComponent } from './tablero-leads-oportunidades/tablero-leads-oportunidades.component';
import { DetalleFamiliaClienteComponent } from './detalle-familia-cliente/detalle-familia-cliente.component';
import { DetalleFamiliaSucursalComponent } from './detalle-familia-sucursal/detalle-familia-sucursal.component';
import { DashboardVendedorComponent } from './dashboard-vendedor/dashboard-vendedor.component';
import { SolicitudAltaClienteComponent } from './solicitud-alta-cliente/solicitud-alta-cliente.component';
import { SolicitudActualizacionCreditoComponent } from './solicitud-actualizacion-credito/solicitud-actualizacion-credito.component';
import { ActualizacionDatosGeneralesComponent } from './actualizacion-datos-generales/actualizacion-datos-generales.component';
import { AltaSucursalClienteComponent } from './alta-sucursal-cliente/alta-sucursal-cliente.component';
import { AltaReglaNegocioComponent } from './alta-regla-negocio/alta-regla-negocio.component';
import { SolicitudIvaFronterizoComponent } from './solicitud-iva-fronterizo/solicitud-iva-fronterizo.component';
import { ConvenioFamiliaComponent } from './convenio-familia/convenio-familia.component';
import { CambioDomicilioFiscalComponent } from './cambio-domicilio-fiscal/cambio-domicilio-fiscal.component';
import { ObjMixComponent } from './obj-mix/obj-mix.component';
import { RelojchecadorComponent } from './relojchecador/relojchecador.component';
import { VacantesComponent } from './vacantes/vacantes.component';
import { RecursosHumanosService } from './servicios/recursos-humanos.service';
import { VentasNetasComponent } from './ventas-netas/ventas-netas.component';
import { VentasDetalleComponent } from './ventas-detalle/ventas-detalle.component';
import { ReporteFactvsDevolucionesComponent } from './reporte-factvs-devoluciones/reporte-factvs-devoluciones.component';
import { ScoreCardComponent } from './score-card/score-card.component';
import { ListRemisionesComponent } from './list-remisiones/list-remisiones.component';
import { ListadoEvidenciasComodatosComponent } from './listado-evidencias-comodatos/listado-evidencias-comodatos.component';
import { ListadoComplementoPagoComponent } from './listado-complemento-pago/listado-complemento-pago.component';
import { ListadoReporteEmbarquesComponent } from './listado-reporte-embarques/listado-reporte-embarques.component';


import {dataTable} from './table/dataTable.component'
import {loader} from './widgets/loader/loader.component';
import { CotizadorLineaComponent } from './cotizador-linea/cotizador-linea.component';
import { CotizadorSubLineaComponent } from './cotizador-sub-linea/cotizador-sub-linea.component';
import { CotizadorArticulosComponent } from './cotizador-articulos/cotizador-articulos.component'
import { DetalleArticuloComponent } from './detalle-articulo/detalle-articulo.component';
import { DetalleContactoComponent } from './detalle-contacto/detalle-contacto.component'
import { ComprasindirectasComponent } from './comprasindirectas/comprasindirectas.component';
import { SolicitudpresupuestosComponent } from './solicitudpresupuestos/solicitudpresupuestos.component';
import { CatalogoComponent } from './catalogo/catalogo.component'
import { MatSelectFilterModule } from 'mat-select-filter';
import { ComodatoDetalleComponent } from './comodato-detalle/comodato-detalle.component';
import { ComodatosLineasComponent } from './comodatos-lineas/comodatos-lineas.component';
import { ComodatosArticulosComponent } from './comodatos-articulos/comodatos-articulos.component';
import { FilterAllPipe } from './pipes/filter-all.pipe';
import { PedidosPendientesComponent } from './pedidos-pendientes/pedidos-pendientes.component';
import { DetallePedidosPComponent } from './detalle-pedidos-p/detalle-pedidos-p.component';
import { RemisionesPendientesComponent } from './remisiones-pendientes/remisiones-pendientes.component';
import { DetalleRemisionComponent } from './detalle-remision/detalle-remision.component';
import { ReporteAvanceGerenteComponent } from './reporte-avance-gerente/reporte-avance-gerente.component';
import { CostosDetalleArticuloComponent } from './costos-detalle-articulo/costos-detalle-articulo.component';
import { ReporteVentadiariaComponent } from './reporte-ventadiaria/reporte-ventadiaria.component';
import { VentaConsignaComponent } from './venta-consigna/venta-consigna.component';
import { CancelarPedidoComponent } from './cancelar-pedido/cancelar-pedido.component';
import { ClientesFaltantesComponent } from './clientes-faltantes/clientes-faltantes.component';
import { PlantillaFiltrosComponent } from './plantilla-filtros/plantilla-filtros.component';
import { MinutaLeadComponent } from './minuta-lead/minuta-lead.component';
import {EcommerceComponent} from './ecommerce/ecommerce.component'
import { EcommerceDetallearticuloComponent } from './ecommerce-detallearticulo/ecommerce-detallearticulo.component';
import { EcommerceDetalleCategoriaComponent } from './ecommerce-detalle-categoria/ecommerce-detalle-categoria.component';
import { EcommerceDetalleArtCategoriaComponent } from './ecommerce-detalle-art-categoria/ecommerce-detalle-art-categoria.component';
import { EcommerceCarritoComponent } from './ecommerce-carrito/ecommerce-carrito.component';
import { IconosLoginComponent } from './iconos-login/iconos-login.component';
import { ConcursoInscripcionComponent } from './concurso-inscripcion/concurso-inscripcion.component';
import { ReporteComodatosClienteComponent } from './reporte-comodatos-cliente/reporte-comodatos-cliente.component';
import { TiendaFinpedidoComponent } from './tienda-finpedido/tienda-finpedido.component';
import { EcommerceFinpedidoComponent } from './ecommerce-finpedido/ecommerce-finpedido.component';
import { PagoErrorComponent } from './pago-error/pago-error.component';
import { EcommercePedidosComponent } from './ecommerce-pedidos/ecommerce-pedidos.component';
import { AdminCuponesComponent } from './admin-cupones/admin-cupones.component';
import { EcommerceAdminArtComponent } from './ecommerce-admin-art/ecommerce-admin-art.component';
import { EcommerceProductoComponent } from './ecommerce-producto/ecommerce-producto.component';
import { EcommerceAyudaComponent } from './ecommerce-ayuda/ecommerce-ayuda.component';
import { EcommerceLoginComponent } from './ecommerce-login/ecommerce-login.component';
import { EcommerceRegisterComponent } from './ecommerce-register/ecommerce-register.component';
import { EcommerceUserInicioComponent } from './ecommerce-user-inicio/ecommerce-user-inicio.component';
import { EcommerceUserDireccionComponent } from './ecommerce-user-direccion/ecommerce-user-direccion.component';
 
import { EcommercePedidosCteComponent } from './ecommerce-pedidos-cte/ecommerce-pedidos-cte.component';
import { EcommerceRastrearPedidoComponent } from './ecommerce-rastrear-pedido/ecommerce-rastrear-pedido.component';
import { EcommerceFacturaDataComponent } from './ecommerce-factura-data/ecommerce-factura-data.component';
import { EcommercePerfilComponent } from './ecommerce-perfil/ecommerce-perfil.component';
import { EcommercePrivacidadComponent } from './ecommerce-privacidad/ecommerce-privacidad.component';
import { EcommerceTerminoenvioComponent } from './ecommerce-terminoenvio/ecommerce-terminoenvio.component';
import { ClienteReportePrincipalComponent } from './cliente-reporte-principal/cliente-reporte-principal.component';
import { PartnerPrincipalComponent } from './partner-principal/partner-principal.component';
import { PartnerDesempenioComponent } from './partner-desempenio/partner-desempenio.component';
import { PartnerBeneficiosComponent } from './partner-beneficios/partner-beneficios.component';
import { PartnerRecompensasComponent } from './partner-recompensas/partner-recompensas.component';
import { PartnerMicuentaComponent } from './partner-micuenta/partner-micuenta.component';
import { PartnerMispedidosComponent } from './partner-mispedidos/partner-mispedidos.component';
import { ClienteSolicitudPedidoComponent } from './cliente-solicitud-pedido/cliente-solicitud-pedido.component';
import { PartnerRegistroComponent } from './partner-registro/partner-registro.component';
import { PartnerInicioSesionComponent } from './partner-inicio-sesion/partner-inicio-sesion.component';
import { ClientePreciosEspecialesComponent } from './cliente-precios-especiales/cliente-precios-especiales.component';
import { ClienteFacturasComponent } from './cliente-facturas/cliente-facturas.component';
import { ClienteComplementopagoComponent } from './cliente-complementopago/cliente-complementopago.component';
import { ClienteSeguimientoPedidosComponent } from './cliente-seguimiento-pedidos/cliente-seguimiento-pedidos.component';
import { ClienteVentaArticuloComponent } from './cliente-venta-articulo/cliente-venta-articulo.component';
import { ClienteVentaMontoComponent } from './cliente-venta-monto/cliente-venta-monto.component';
import { ClienteVentaLineaComponent } from './cliente-venta-linea/cliente-venta-linea.component';
import { ClientePresupuestoSucursalComponent } from './cliente-presupuesto-sucursal/cliente-presupuesto-sucursal.component';
import { PoliticaComponent } from './politica/politica.component';
import { FilterEcommercePipe } from './pipes/filter-ecommerce.pipe';
import { PartnerCarritoComponent } from './partner-carrito/partner-carrito.component';
import { ClienteReporteExcedidosComponent } from './cliente-reporte-excedidos/cliente-reporte-excedidos.component';
import { PartnerEntregaComponent } from './partner-entrega/partner-entrega.component';
import { PartnerPasarelaComponent } from './partner-pasarela/partner-pasarela.component';
import { PartnerErrorCompraComponent } from './partner-error-compra/partner-error-compra.component';
import { PartnerFinpedidoComponent } from './partner-finpedido/partner-finpedido.component';
import { PartnerPedidosComponent } from './partner-pedidos/partner-pedidos.component';
import { PartnerInfoComponent } from './partner-info/partner-info.component';
import { PartnerOfertasComponent } from './partner-ofertas/partner-ofertas.component';
import { PartnerClientesComponent } from './partner-clientes/partner-clientes.component';
import { CotizadorPartnerComponent } from './cotizador-partner/cotizador-partner.component';


@NgModule({
  declarations: [
    AppComponent,
    DetalleComponent,ClientePedidoPipe,
    FooterComponent,ListadoComponent,
    CarritoresumenComponent,DirectorioComponent,
    HomeComponent,BusquedaComponent,
    ObjmixnComponent,ObjmixdivisionalComponent,
    ObjmixsucursalComponent,LoginComponent,
    ObjmixgsComponent,ObjMixAgenteComponent,
    ObjmixagenteComponent,MatInputCommifiedDirective,
    ScordcardgsComponent,FilterPipe, 
    FilterPipeCategoria, FilterPipeDespachador,
    ReportecomodatosComponent,ReportevtaobjcteComponent,
    DetallevtasvsobjComponent,RetoTorkComponent,
    RetoTorkBitacoraInicialComponent,FirmaComponent,
    SeguimientotorkComponent,RetoTorkBitacoraSeguimientoComponent,
    DialogAutorizarRetoComponent,RetoTorkListaDespachadoresComponent,
    DialogPreguntasContent,DialogPreguntasContentCons,
    PoconexionComponent, AutpreciominComponent, 
    DialogPreciosMinimos, NewmenuComponent, 
    LoginnComponent, CotizadornewComponent, 
    VtexComponent, InventariosComponent, 
    ComodatosproComponent, DetallescomodaproComponent, 
    ObjmixGteSucursalComponent, ObjmixGteDivisionalComponent, 
    FilterprecioPipe, ObjmixNacionalComponent, 
    CapturadorComponent, ConfigMercadosComponent, 
    FiltersearchPipe, PedidosVentasComponent, 
    LeadsCarteraComponent,
    PipeTableroPipe,
    CotizacionesComponent,
    AromiaComponent,InsertarComodatosComponent, ListadoComodatosComponent, CarroComodatosComponent, ListadoComodatosTodosComponent, ReportePrincipalComponent, PedidosEcodeliComponent, InventariosEcodeliComponent,
     PlantillasComponent, AgenteFilterPipe , SucursalFilterPipe, EcodeliPremiaComponent, LeadsFamiliasComponent, LeadComponent, ProspectoComponent, PasarelaPagoComponent, AltaclienteComponent, FamiliasComponent,
      FiltergrupoPipe, SolicitudesFamiliasComponent, DetalleLeadsComponent, FormularioLeadComponent, ComodatosClienteComponent, DetalleFamiliaComponent, TableroLeadsOportunidadesComponent, PruebasComponent,
       CotizadorComponent, DetalleOportunidadComponent, DetalleFamiliaClienteComponent, DetalleFamiliaSucursalComponent, DashboardVendedorComponent, SolicitudAltaClienteComponent, 
       SolicitudActualizacionCreditoComponent, ActualizacionDatosGeneralesComponent, AltaSucursalClienteComponent, AltaReglaNegocioComponent, SolicitudIvaFronterizoComponent, ConvenioFamiliaComponent, 
       CambioDomicilioFiscalComponent, ObjMixComponent, RelojchecadorComponent, VacantesComponent,
    dataTable,
    loader, 
  VentasNetasComponent, VentasDetalleComponent, ReporteFactvsDevolucionesComponent, ScoreCardComponent, ListRemisionesComponent, ListadoEvidenciasComodatosComponent, ListadoComplementoPagoComponent, 
  ListadoReporteEmbarquesComponent, CotizadorLineaComponent, CotizadorSubLineaComponent, CotizadorArticulosComponent,
      DetalleArticuloComponent, DetalleContactoComponent,
    ComprasindirectasComponent, SolicitudpresupuestosComponent, CatalogoComponent, ComodatoDetalleComponent, ComodatosLineasComponent, ComodatosArticulosComponent, FilterAllPipe, PedidosPendientesComponent, 
    DetallePedidosPComponent, RemisionesPendientesComponent, DetalleRemisionComponent, ReporteAvanceGerenteComponent, CostosDetalleArticuloComponent, ReporteVentadiariaComponent, VentaConsignaComponent,
     CancelarPedidoComponent, ClientesFaltantesComponent, PlantillaFiltrosComponent, MinutaLeadComponent, EcommerceDetallearticuloComponent,EcommerceComponent, EcommerceDetalleCategoriaComponent,
      EcommerceDetalleArtCategoriaComponent, EcommerceCarritoComponent, IconosLoginComponent,
    ConcursoInscripcionComponent, ReporteComodatosClienteComponent, TiendaFinpedidoComponent, EcommerceFinpedidoComponent, PagoErrorComponent, EcommercePedidosComponent,
      AdminCuponesComponent, EcommerceAdminArtComponent, EcommerceProductoComponent, EcommerceAyudaComponent, EcommerceLoginComponent, EcommerceRegisterComponent, EcommerceUserInicioComponent,
       EcommerceUserDireccionComponent,  EcommercePedidosCteComponent, EcommerceRastrearPedidoComponent, EcommerceFacturaDataComponent, EcommercePerfilComponent, EcommercePrivacidadComponent,
        EcommerceTerminoenvioComponent, ClienteReportePrincipalComponent, PartnerPrincipalComponent, PartnerDesempenioComponent, PartnerBeneficiosComponent, PartnerRecompensasComponent, PartnerMicuentaComponent, PartnerMispedidosComponent,
        
        ClienteSolicitudPedidoComponent, PartnerRegistroComponent, PartnerInicioSesionComponent, ClientePreciosEspecialesComponent,
       
        ClienteFacturasComponent, ClienteComplementopagoComponent, ClienteSeguimientoPedidosComponent, ClienteVentaArticuloComponent, ClienteVentaMontoComponent, ClienteVentaLineaComponent, ClientePresupuestoSucursalComponent, PoliticaComponent, FilterEcommercePipe, PartnerCarritoComponent, ClienteReporteExcedidosComponent, PartnerEntregaComponent, PartnerPasarelaComponent, PartnerErrorCompraComponent, PartnerFinpedidoComponent, PartnerPedidosComponent, PartnerInfoComponent, PartnerOfertasComponent, PartnerClientesComponent,CotizadorPartnerComponent,
 
  ],
  imports: [
    NgxWebstorageModule.forRoot(
    
    ),
    BrowserModule,
    FullCalendarModule,
    MatRadioModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatTableModule,MatFormFieldModule,MatTabsModule,
    MatSortModule ,
    MatCurrencyFormatModule,
    MatSlideToggleModule,
    MatProgressBarModule,   
    MatStepperModule, 
    LayoutModule, 
    MatToolbarModule, 
    MatSidenavModule,
    MatListModule, 
    MatGridListModule, MatCardModule, MatMenuModule,MatAutocompleteModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSelectFilterModule,
    MatNativeDateModule,
  ],
  entryComponents: [
    DetallevtasvsobjComponent
  ],
  
  providers: [appRoutingProviders,  { provide: MAT_DATE_LOCALE, useValue: 'es-ES' } , PeticionesService, CarroService,CurrencyPipe,DatePipe, RecursosHumanosService],
  //providers: [{ provide: APP_BASE_HREF, useValue : '/' }, PeticionesService, CarroService,CurrencyPipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
