<div class="container">
    <div class="row my-4">
        <div class="col-12 ">
            <h1 class="text-center">Configuración <span class="colortittle">Mercados y Areas</span></h1>
        </div>
    </div>
    <!-- cards -->
    <div class="row d-flex justify-content-evenly">
        <div class="col-12 col-md-4 d-flex justify-content-center">
            <div class="card col-8" *ngIf="selectCard == 'Mercado'" [ngClass]="{'hov':selectCard == null,'activado':selectCard == 'Mercado'}"> 
                <div class="card-body">
                    <h2 class="card-title text-center text-muted titulocard">Mercado</h2>
                    <div class="row">
                        <div class="col-12 text-center">
                          <span class="text-muted icono material-symbols-outlined">bookmark</span>
                        </div>
                    </div>
                  </div>
            </div>
            <div class="card col-8" *ngIf="selectCard == 'Area'|| selectCard == null " (click)="fnselectsrting('Mercado')"  [ngClass]="{'hov':selectCard == null||selectCard == 'Area','activado':selectCard == 'Mercado'}">
                <div class="card-body">
                  <h2 class="card-title text-center text-muted titulocard">Mercado</h2>
                  <div class="row">
                      <div class="col-12 text-center">
                        <span class="text-muted icono material-symbols-outlined">bookmark</span>
                      </div>
                  </div>
                  
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-center">
             <div class="card col-8" *ngIf="selectCard == 'Area'"  [ngClass]="{'hov':selectCard == null,'activado':selectCard == 'Area'}" > 
                <div class="card-body ">
                    <h2 class="card-title text-center text-muted titulocard">Area</h2>
                  <div class="row">
                      <div class="col-12 text-center">
                          <span class="text-muted icono material-symbols-outlined">location_on</span>
                      </div>
                  </div>
                  </div>
            </div>
            <div class="card col-8" *ngIf="selectCard == null || selectCard == 'Mercado'" (click)="fnselectsrting('Area')"  [ngClass]="{'hov':selectCard == null||selectCard == 'Mercado','activado':selectCard == 'Area'}" >
                <div class="card-body ">
                  <h2 class="card-title text-center text-muted titulocard">Area</h2>
                <div class="row">
                    <div class="col-12 text-center">
                        <span class="text-muted icono material-symbols-outlined">location_on</span>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Mercados -->
    <div class="row py-4"  *ngIf="selectCard  == 'Mercado'" >
        <h1 class="text-center">{{selectCard}}s</h1>
        <div class="row">
            <div class="col-12 col-md-4 my-3">
                <div class="card ">
                    <div class="card-header d-flex justify-content-between">
                        <h3>Lista</h3>
                        <button (click)="fniciarproceso();" class="btn "><span class="material-symbols-outlined">close</span></button>  
                    </div>
                    <div class="card-body h100over vh20cards pt-0">
                        <div class="row d-flex justify-content-center my-1 fijetop bg-white mt-0 py-2 zindex1">
                            <div class="col-10 col-md-8" *ngIf="intMercado != 0">
                                <div class="input-group">
                                    <input type="search" class="form-control" [(ngModel)]="strbuscarLista" placeholder="Buscar por Mercados ">
                                    <span class="input-group-text" id="addon-wrapping"><i class="bi bi-search"></i></span>
                                </div>
                            </div>
                            <div class="d-none col-md-4 d-md-flex align-items-center justify-content-center" *ngIf="intMercado != 0">
                               TOTAL:  <span style="font-weight: bold; padding-left: 5px;">  {{ arrayDatos ? (arrayDatos.length > 0 ? arrayDatos.length : 0) : 0}}</span>
                            </div>
                        </div>

                        <div class="py-2 d-flex" *ngFor="let item of arrayDatos | filtersearch: strbuscarLista:['strNombre'] ; index as i;">
                            <div class="col-10 ">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="item.check" [checked]="intMercado === item.intIDMercado" (change)="selectMercado(item.intIDMercado,item.check)"  [value]="item.intIDMercado" id="flexCheckDefault{{i}}">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        {{item.strNombre}}
                                    </label>
                                </div>
                            </div>
                            <div class="col-2 ">
                                <button type="button" (click)="fngetareasSinMercado(item.intIDMercado)" [ngClass]="{'text-info':item.check,'text-muted':!item.check}"  [disabled]="!item.check" class="p-0 shadow btn btn-light d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                                    <span class="material-symbols-outlined">
                                        add
                                        </span>
                                </button>
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
            <div class="col-12 col-md-8 my-3 pb-2 ">
                <div class="card">
                    <div class="card-header align-items-center d-flex justify-content-between">
                        <h3 class="mb-0">Areas</h3>
                        <h2 class="mb-0">     <span *ngIf="strNombreMercado != ''">   <span class="fw-lighter">Mecardo: </span>  <span class="fw-bold">{{strNombreMercado | uppercase}}</span></span> </h2>
                        <button (click)="fniciarproceso();" class="btn "><span class="material-symbols-outlined">close</span></button>  
                    </div>
                    <div class="card-body h100over vh30cards pt-0">

                        <div class="row d-flex justify-content-center my-1 fijetop bg-white mt-0 py-2 zindex1">
                            <div class="col-10 col-md-8" *ngIf="intMercado != 0">
                                <div class="input-group">
                                    <input type="search" class="form-control" [(ngModel)]="strbuscar" placeholder="Buscar por Area">
                                    <span class="input-group-text" id="addon-wrapping"><i class="bi bi-search"></i></span>
                                </div>
                            </div>
                            <div class="d-none col-md-4 d-md-flex align-items-center justify-content-center" *ngIf="intMercado != 0">
                               TOTAL:  <span style="font-weight: bold; padding-left: 5px;">  {{ arrayAreas ? (arrayAreas.length > 0 ? arrayAreas.length : 0) : 0}}</span>
                            </div>
                        </div>
                            <div class="row">
                                <div class="col-12 col-lg-6  d-flex my-3 justify-content-center px-0" *ngFor="let item of arrayAreas | filtersearch: strbuscar: ['strNombre'] ; index as i;">
                                    <div class="col-10 text-end d-flex">
                                        <div class="input-group">
                                        <button type="button" (click)="fnEliminar(intMercado,item.intIDArea)" id="basic-addon1"  class="input-group-text p-0 px-1 shadow btn btnElimin d-flex align-items-center col-2 justify-content-center zindex2">
                                            <span class="material-symbols-outlined">
                                                delete
                                                </span>
                                        </button>
                                        <label aria-describedby="basic-addon1" for="" class=" fw-bold text-light p-2 rounded-end  bglbl col-10" for=""> <span class="float-start text-nowrap">{{i+1}}.-  {{item.strNombre}}</span> </label>
                                    </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- AREAS -->
    <div class="row d-flex justify-content-center" *ngIf="selectCard  == 'Area'">
        <div class="col-12 px-0 pt-4">
            <h1 class="text-center">AREAS</h1>
        </div>

        <div class="row px-0">
            <div class="col-12 col-md-4 my-3">
                <div class="card ">
                    <div class="card-header d-flex justify-content-between">
                        <h3>Lista</h3>
                        <button (click)="fniciarproceso();" class="btn "><span class="material-symbols-outlined">close</span></button>  
                    </div>
                    <div class="card-body h100over vh20cards pt-0">
                        <div class="row d-flex justify-content-center my-1 fijetop bg-white mt-0 py-2 zindex1">
                            <div class="col-10 col-md-8" *ngIf="intMercado != 0">
                                <div class="input-group">
                                    <input type="search" class="form-control" [(ngModel)]="strbuscarLista" placeholder="Buscar por Area ">
                                    <span class="input-group-text" id="addon-wrapping"><i class="bi bi-search"></i></span>
                                </div>
                            </div>
                            <div class="d-none col-md-4 d-md-flex align-items-center justify-content-center" *ngIf="intMercado != 0">
                               TOTAL:  <span style="font-weight: bold; padding-left: 5px;">  {{ arrayDatos ? (arrayDatos.length > 0 ? arrayDatos.length : 0) : 0}}</span>
                            </div>
                        </div>
                        <div class="py-2 d-flex" *ngFor="let item of arrayDatos | filtersearch: strbuscarLista: ['strNombre'] ; index as i;">
                            <div class="col-10 ">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="item.check" [checked]="intArea === item.intIDArea" (change)="selectMercadoAreas(item.intIDArea,item.check)"  [value]="item.intIDMercado" id="flexCheckDefault{{i}}">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        {{item.strNombre}}
                                    </label>
                                  </div>
                            </div>
                            <div class="col-2">
                                <!-- (click)="fngetareasSinMercado(item.intIDArea)" -->
                                <button type="button"  [ngClass]="{'text-info':item.check,'text-muted':!item.check}"  [disabled]="!item.check" class="p-0 shadow btn btn-light d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#exampleModal1" data-bs-whatever="@mdo">
                                    <span class="material-symbols-outlined">
                                        add
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
            <div class="col-12 col-md-8 my-3 pb-2 ">
                <div class="card ">
                    <div class="card-header d-flex align-items-center justify-content-between ">
                        <h3 class="mb-0">Articulos</h3>
                       <h2 class="mb-0"><span *ngIf="strNombreArea != ''"> <span class="fw-lighter">Area:  </span>  <span class="fw-bold">{{strNombreArea | uppercase}}</span></span></h2> 
                        <button (click)="fniciarproceso();" class="btn "><span class="material-symbols-outlined">close</span></button>  
                    </div>
                    <div class="card-body h100over vh30cards pt-0">
                        <div class="row my-1 fijetop bg-white py-2">
                            <div class="col-10 col-md-6" *ngIf="intArea != 0">
                                <div class="input-group">
                                    <input type="search" class="form-control" [(ngModel)]="strbuscar" placeholder="Buscar por articulo">
                                    <span class="input-group-text" id="addon-wrapping"><i class="bi bi-search"></i></span>
                                </div>
                            </div>
                            <div class="d-none col-md-3 d-md-flex align-items-center justify-content-center" *ngIf="intArea != 0">
                               TOTAL:  <span style="font-weight: bold; padding-left: 5px;">  {{ arrayArticulos ? (arrayArticulos.length > 0 ? arrayArticulos.length : 0) : 0}}</span>
                            </div>
                            <div class="col-2 col-md-3 ps-0 d-flex align-items-center justify-content-end" *ngIf="intArea != 0">
                               <button class="btn btn-danger p-1 fw-bold d-flex align-items-center" (click)="fnEliminarTodo(intArea);">
                                <span class="material-symbols-outlined">
                                    delete_forever
                                    </span>
                                   <span class="d-none d-md-flex fs-10">Quitar todo</span> 
                               </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex my-3" *ngFor="let item of arrayArticulos| filtersearch: strbuscar: ['strArticulo']; index as i;">
                                <div class="w-100 himg border rounded position-relative d-flex justify-content-center align-items-center">
                                    <img class="w-100 h-100 float-center" [src]="'https://www.cleanclean.mx/imagenes/' + item.img +'.JPG'" (error)="handleImageError(item)"   alt="">
                                    <div class="h-30 w-100 bgimages position-absolute bottom-0 ">
                                        <p class="text-center fs-12 p-1">{{item.strDescripcion}}</p>
                                    </div>
                                    <div class="position-absolute top-0 start-0 p-1">
                                        <span class="border rounded bg-info text-white p-1 fs-10 ">{{item.strArticulo}}</span>
                                    </div>
                                    <div class="position-absolute top-0 end-0 p-1">
                                        <button (click)="fnEliminarporArt(intArea,item.strArticulo)" class="btn btn-danger text-white p-0 d-flex ">
                                            <span class="material-symbols-outlined ">
                                                delete
                                                </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    <!-- modal Mercados-->
    <div class="modal fade" id="exampleModal" #miModal tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
              <h3 class="modal-title m-0" id="exampleModalLabel">AGREGAR AREAS A {{strNombreMercado | uppercase}}</h3>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body h100over pt-0">

                <div class="row fijetop bg-white pt-3 border-bottom">
                    <div class="col-12 col-md-8 d-flex align-items-center">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" [(ngModel)]="strbuscarArts"  placeholder="buscar Areas" aria-label="Recipient's username" aria-describedby="basic-addon2">
                            <span class="input-group-text" id="basic-addon2"><span class="material-symbols-outlined">
                                search
                                </span></span>
                          </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <span class="fw-lighter">Seleccionados</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <span class="fw-bold fs-6">{{fncontadorarts('MERCADO')}}</span>
                            </div>
                        </div>
                    </div>
                </div>

               <div class="row">
                <div class="py-2 col-12 col-md-6" *ngFor="let item of arrayAreasLibres | filtersearch: strbuscarArts: ['strNombre'] ; index as i;">
                    <div class=" d-flex  border rounded ps-2 " [ngClass]="{'cmercadosdisabled':!item.check,'cmercadosActive':item.check}" >
                        <div class="form-check d-flex align-items-center mb-0 "> <!-- (change)="selectMercado(item.intIDMercado,item.check)" -->
                            <input class="form-check-input mb-1 me-2"  type="checkbox" [(ngModel)]="item.check"    [value]="item.intIDArea" id="flexCheckDefault{{i}}">
                            <label class="form-check-label" for="flexCheckDefault" >
                                {{item.strNombre}}
                            </label>
                          </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="modal-footer">
              <button #cerrarBtnMercado type="button" class="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-success" (click)="fnAgregarArea(intMercado);">Agregar</button>
            </div>
          </div>
        </div>
      </div>

        <!-- modal Areas -->
    <div class="modal fade" id="exampleModal1" #miModal1 tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
              <h3 class="modal-title m-0" id="exampleModalLabel">AGREGAR ARTICULOS A {{strNombreArea | uppercase}}</h3>
              <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="fncerrarmodalareas();" aria-label="Close"></button>
            </div>
            <div class="modal-body h100over pt-0">
                <div class="row fijetop bg-white pt-3 border-bottom">
                    <div class="col-12 col-md-8 d-flex align-items-center">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" [(ngModel)]="strbuscarArts" (input)="fnmostrarart(strbuscarArts)" placeholder="buscar Articulo" aria-label="Recipient's username" aria-describedby="basic-addon2">
                            <span class="input-group-text" id="basic-addon2"><span class="material-symbols-outlined">
                                search
                                </span></span>
                          </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <span class="fw-lighter">Seleccionados</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <span class="fw-bold fs-6">{{fncontadorarts('AREA')}}</span>
                            </div>
                        </div>
                        
                    </div>
                </div>
               <div class="row pt-3">
                <!-- <div class="py-2 col-6" *ngFor="let item of arrayAreasLibres; index as i;"> -->
                    <div class="py-2 col-12 col-md-6 " *ngFor="let A of arrayArticulosMostrar; index as i;">
                    <div class=" d-flex">
                        <div class="h20vh  border rounded p-3 w-100 position-relative" > <!-- (change)="selectMercado(item.intIDMercado,item.check) [(ngModel)]="item.check""  [value]="item.intIDArea" id="flexCheckDefault{{i}}"-->
                            <img class=" " [src]="'https://www.cleanclean.mx/imagenes/' + A.img +'.JPG'" (error)="handleImageError(A)"  [ngClass]="{'black-and-white':!A.check}" style="height: 100%; width: 100%;" alt="">
                            <span class=" p-1 fw-bold  position-absolute top-0 end-0 rounded"  [ngClass]="{'bgseconArt':!A.check,'selectoptArt':A.check}">{{A.strArticulo}}</span>
                            <input class="m-1 position-absolute top-0 start-0" type="checkbox" [(ngModel)]="A.check">
                                <span [ngClass]="{'bgsecon2':!A.check,'selectopt3':A.check}" class="fw-bold text-center position-absolute bottom-0 start-0 w-100 h-25">
                                   {{A.strDescripcion}}
                                </span> 
                          </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="modal-footer">
              <button #cerrarBtnArea type="button" (click)="fncerrarmodalareas();" class="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-success" (click)="fninsertarArticulos(intArea);">Agregar</button>
            </div>
          </div>
        </div>
      </div>
</div>