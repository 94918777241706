<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold d-flex justify-content-center nunito"><span>Captura tus objetivos </span><span class="ms-2 titulo text-decoration-underline"> por MIX</span> </h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow-sm pb-3">
        <div class="col-12 bgtable py-1 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="">
                <mat-option value="">-- Todos --</mat-option>
                <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option>
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente || boolSucursal || boolAgente }">
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnFiltrar ()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div class="row my-3 justify-content-between">
        <div class="col-3 d-flex px-0 ">
            <span class="shadow-sm borderbott bordertop bg-white w-100">
                <div class="col-12 text-center">
                    <span class="nunito fw-bold px-3 shadow-sm cardStatus text-white" [ngClass]="{'bgGray':objCaptura.intEstatus==0,'bg-success':objCaptura.intEstatus==2,'bg-danger':objCaptura.intEstatus==3,'bg-warning':objCaptura.intEstatus==1}">
                        ESTATUS
                    </span>
                </div>
                <div class="col-12 text-center py-3">
                    <span class="fw-bold fs-4 nunito">{{objCaptura.strEstatus}}</span>
                    <div class="border border-bottom border-secondary border-1 w-25 mx-auto nunito mt-1">
                    </div>
                 </div>
            </span>
        </div>
        <div class="col-3 d-flex px-0">
            <span class="shadow-sm borderbott bordertop bg-white w-100">
                <div class="col-12 text-center">
                    <span class="cardCapt nunito fw-bold px-3 shadow-sm">
                        CAPTURADO
                    </span>
                </div>
                <div class="col-12 text-center py-3">
                    <span class="fw-bold fs-4 nunito">{{arrayMontosLineas[0]?.dblTotal|currency}}</span>
                    <div class="border border-bottom border-secondary border-1 w-25 mx-auto nunito mt-1">
                    </div>
                 </div>
            </span>
        </div>
        <div class="col-3 d-flex px-0 ">
            <span class="shadow-sm borderbott bordertop  bg-white w-100">
                <div class="col-12 text-center">
                    <span class="cardObj nunito fw-bold px-3 shadow-sm">
                        OBJETIVO
                    </span>
                </div>
                <div class="col-12 text-center py-3">
                   <span class="fw-bold fs-4 nunito">{{arrayMontosLineas[0]?.dblTotal|currency}}</span>
                   <div class="border border-bottom border-secondary border-1 w-25 mx-auto nunito mt-1">
                   </div>
                </div>
            </span>
        </div>
    </div>
    <div class="row justify-content-between mb-3" >
        <div class="col-sm-3 d-flex">
           <button class="btn btn-primary text-white" (click)="fnGetHistLineas('0')">Cargar tabla</button>
        </div>
        <div class="col-sm-3 pull-right text-right "  [hidden]="spOpcionSeleccionada=='ACTUAL'||objCaptura.strEstatus =='AUTORIZADO' ||objCaptura.strEstatus =='PENDIENTE'">
           <button class="btn btn-success d-flex align-items-center" (click)="fnGetHistLineas('1')"> 
              <i class="fas fa-2x fa-save"></i> <span class="ms-2">Avanzar a autorización</span>
           </button>
        </div>
     </div>
    <div class="row">
        <div class="col-12 px-0">
            <div class="col-12 table-responsive borderbott bordertop shadow-sm">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-center thLin">LINEA</th>
                            <th class="text-center thIdeal">% IDEAL</th>
                            <th class="text-center thMontoIdeal">MONTO IDEAL</th>
                            <th class="text-center th3meses">% ÚLTIMOS 3 MESES</th>
                            <th class="text-center thCapObj">CAPTURA DE OBJETIVO</th>
                            <th class="text-center thPorCapObj">% CAPTURA DE OBJETIVO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="trHov" *ngFor="let x of arrayMontosLineas;let ind =index">
                            <td class=" fw-bold fs13" [ngClass]="{'tdLin':ind % 2 !== 0,'tdLin2':ind % 2 === 0}">{{x.strLinea}}</td>
                            <td class="tdIdeal text-center fs13 fw-bold" [ngClass]="{'tdIdeal':ind % 2 !== 0,'tdIdeal2':ind % 2 === 0}">{{(x.dblPIdeal*100)|number:'1.2-2'}} %</td>
                            <td class="text-end fs13 fw-bold" [ngClass]="{'tdMontoIdeal':ind % 2 !== 0,'tdMontoIdeal2':ind % 2 === 0}">{{x.dblMonto_Ideal|currency}}</td>
                            <td class="text-center fs13 fw-bold" [ngClass]="{'td3meses':ind % 2 !== 0,'td3meses2':ind % 2 === 0,'text-danger':x.dblPUltimo_3_Meses<x.dblPIdeal,'text-success':x.dblPUltimo_3_Meses>=x.dblPIdeal}">{{(x.dblPUltimo_3_Meses*100)|number:'1.2-2'}} %</td>
                            <td class="text-end fs13 fw-bold" [ngClass]="{'tdCapObj':ind % 2 !== 0,'tdCapObj1':ind % 2 === 0}">{{x.dblObjetivo|currency}}</td>
                            <td class="text-center fs13 fw-bold" [ngClass]="{'tdPorCapObj':ind % 2 !== 0,'tdPorCapObj2':ind % 2 === 0,'text-danger':x.dblPCaptura_Objetivo<x.dblPUltimo_3_Meses && x.dblPCaptura_Objetivo<(x.dblPIdeal/2),'text-success':x.dblPCaptura_Objetivo>=x.dblPIdeal || x.dblPCaptura_Objetivo>=(x.dblPIdeal/2)}">{{(x.dblPCaptura_Objetivo*100)|number:'1.2-2'}} %</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link tabPane  me-2 " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" (click)="fnGetClientes('Objetivo','ACTUAL');fnGetHistLineas('0');">Objetivo Actual Clientes</button>
            </li>
            <li class="nav-item " role="presentation">
              <button class="nav-link tabPane active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" (click)="fnGetClientes('Captura','SIGUIENTE');fnGetHistLineas('0');" #btnCaptura>Capturar Objetivos Clientes</button>
            </li>
        </ul>
        <div class="tab-content bg-white shadow-sm borderbott" id="pills-tabContent">
            <div class="tab-pane fade " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="row">
                    <div class="col-12 ">
                        <h1 class="text-center mb-0 mt-3">Objetivo actual de Clientes</h1>
                    </div>
                </div>
                <div class="row my-4" [hidden]="!spiner">
                    <div class="col-12 d-flex justify-content-center">
                        <mat-spinner  [strokeWidth]="6" [diameter]="70"  class=" rounded-circle custom-spinner"></mat-spinner>
                    </div>
                </div>
                <div class="row justify-content-center" [hidden]="spiner">
                    <div class="col-12 py-3 d-flex align-items-center justify-content-between">
                        <mat-form-field appearance="outline">
                            <mat-label>Buscar</mat-label>
                            <input [(ngModel)]="strSearch" matInput placeholder="Cliente">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                        <span class="shadow-sm rounded p-2 fw-bold fs13 " style="background: hsl(175, 100%, 95%);color:hsl(175, 100%, 26%)" >
                            <i class="bi bi-calendar-week-fill me-2"></i>  {{periodoMostrar.nombre}}<br>
                        </span>
                    </div>
                    <div class="col-12 py-3">
                        <div class="table-responsive mb-0 px-0 borderbott bordertop shadow-sm">
                            <table class="table w-100">
                                <thead>
                                    <tr class="">
                                        <th class="border-end bg-secondary text-white sticky-col border-top border-secondary">
                                            Ultimo trimestre 2024
                                        </th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objAccesorios|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDespachadores|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objKimberly|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objQuimicos|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objRubbermaid|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objBlueTissue|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objTork|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDegrapack|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objFoaom|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDove|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objTapetes|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.dblTotal|currency}}</th>
                                      
                                    </tr>
                                    <tr >
                                        <th class="border-end bg-secondary text-white sticky-col">
                                            Total Por Linea
                                        </th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objAccesorios|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objDespachadores|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objKimberly|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objQuimicos|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objRubbermaid|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objBlueTissue|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objTork|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objDegrapack|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objFoaom|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objDove|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objTapetes|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.dblTotal|currency}}</th>
                                     
                                    </tr>
                                    <tr class=" sticky-top">
                                        <th class="text-light bg-secondary">Cliente</th>
                                        <th class="text-light bg-secondary">Acesorios</th>
                                        <th class="text-light bg-secondary">Despachadores</th>
                                        <th class="text-light bg-secondary">Kimberly</th>
                                        <th class="text-light bg-secondary">Quimicos</th>
                                        <th class="text-light bg-secondary">Rubbermaid</th>
                                        <th class="text-light bg-secondary">Blue Tissue</th>
                                        <th class="text-light bg-secondary">Tork</th>
                                        <th class="text-light bg-secondary">Degrapack</th>
                                        <th class="text-light bg-secondary">Foaom</th>
                                        <th class="text-light bg-secondary">Dove</th>
                                        <th class="text-light bg-secondary">Tapetes</th>
                                        <th class="text-light bg-secondary">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let x of arrayClientes|filtersearch: strSearch : ['strNombreCliente','strCliente'];">
                                        <td class="border-end">(<span class="fw-bold fs13">{{x.strCliente}}</span>)
                                            <span class="fs13 fw-bold">{{x.strNombre}}</span>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objAccesorios<=0,'text-success fw-bold':x.objAccesorios>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Accesorios')"></i>
                                                <span>
                                                    {{x.objAccesorios|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objDespachadores<=0,'text-success fw-bold':x.objDespachadores>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DESPACHADORES ECODELI')"></i>
                                                <span>
                                                    {{x.objDespachadores|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objKimberly<=0,'text-success fw-bold':x.objKimberly>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Kimberly')"></i>
                                                <span>
                                                    {{x.objKimberly|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objQuimicos<=0,'text-success fw-bold':x.objQuimicos>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Quimicos')"></i>
                                                <span>
                                                    {{x.objQuimicos|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objRubbermaid<=0,'text-success fw-bold':x.objRubbermaid>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Rubbermaid')"></i>
                                                <span>
                                                    {{x.objRubbermaid|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objBlueTissue<=0,'text-success fw-bold':x.objBlueTissue>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'BLUE TISSUE')"></i>
                                                <span>
                                                    {{x.objBlueTissue|currency}}
                                                </span>
                                            </div></td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objTork<=0,'text-success fw-bold':x.objTork>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'TORK')"></i>
                                                <span>
                                                    {{x.objTork|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objDegrapack<=0,'text-success fw-bold':x.objDegrapack>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DEGRAPACK')"></i>
                                                <span>
                                                    {{x.objDegrapack|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objFoaom<=0,'text-success fw-bold':x.objFoaom>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'FOAOM')"></i>
                                                <span>
                                                    {{x.objFoaom|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objDove<=0,'text-success fw-bold':x.objDove>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DOVE')"></i>
                                                <span>
                                                    {{x.objDove|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.objTapetes<=0,'text-success fw-bold':x.objTapetes>0}">
                                            <div class="d-flex flex-column">
                                                 <i class="bi bi-file-bar-graph fs-5" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'TAPETES')"></i>
                                                <span>
                                                    {{x.objTapetes|currency}}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end" [ngClass]="{'text-secondary':x.dblTotal<=0,'text-success fw-bold':x.dblTotal>0}">
                                            <div class="d-flex flex-column">

                                                <span>
                                                    {{x.dblTotal|currency}}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="row">
                    <div class="col-12 position-relative">
                        <h1 class="text-center my-3">Captura objetivo de Clientes próximo mes</h1>

                        <span class="ms-2 shadow-sm rounded p-2 fw-bold fs13 position-absolute top-50 start-0 translate-middle-y" style="background: #e2ecff;color:hsl(219, 100%, 26%)" *ngIf="intPerIni != intPeriodo">
                            <i class="bi bi-info-circle-fill me-2"></i> Para capturar los objetivos del siguiente <br>
                             mes debe seleccionar el periodo actual
                        </span>
                        <span class="ms-2 shadow-sm rounded p-2 fw-bold fs13 position-absolute top-50 start-0 translate-middle-y" style="background: hsl(175, 100%, 95%);color:hsl(175, 100%, 26%)" *ngIf="intPerIni == intPeriodo">
                            <i class="bi bi-calendar-week-fill me-2"></i>  Captura para {{periodoMostrar.nombre}}<br>
                        </span>
                    </div>

                    <!-- <div class="col-12 py-3 d-flex align-items-center justify-content-between">
                           <mat-form-field appearance="outline">
                              <mat-label>Buscar</mat-label>
                              <input [(ngModel)]="strSearch" matInput placeholder="Cliente">
                              <mat-icon matSuffix>search</mat-icon>
                          </mat-form-field> 
                          <span class="shadow-sm rounded p-2 fw-bold fs13 " style="background: #e2ecff;color:hsl(219, 100%, 26%)" *ngIf="intPerIni != intPeriodo">
                              <i class="bi bi-info-circle-fill me-2"></i> Para capturar los objetivos del siguiente <br>
                               mes debe seleccionar el periodo actual
                          </span>
                          <span class="shadow-sm rounded p-2 fw-bold fs13 " style="background: hsl(175, 100%, 95%);color:hsl(175, 100%, 26%)" *ngIf="intPerIni == intPeriodo">
                              <i class="bi bi-calendar-week-fill me-2"></i>  Captura para {{periodoMostrar.nombre}}<br>
                          </span>
                      </div> -->
                </div>
                <div class="row my-4"  *ngIf="spiner">
                    <div class="col-12 d-flex justify-content-center ">
                        <mat-spinner [strokeWidth]="6" [diameter]="70"  class=" rounded-circle custom-spinner"></mat-spinner>
                    </div>
                </div>
                <div class="row justify-content-center"  *ngIf="!spiner">
                    <div class="col-12 py-3">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Buscar</mat-label>
                                <input matInput (keyup)="applyFilterObjx($event)"
                                    placeholder="ID,Tarea,etc..." #input>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="mb-0 table-responsive  borderbott bordertop">
                            <div class="mat-elevation-z8 mb-0 mt-3 shadow rounded  borderbott bordertop">
                                <table class="table ">
                                    <thead>
                                        <tr class="sticky-top  borderbott bordertop">
                                            <th class="text-light bg-secondary text-center sticky-col" [ngStyle]="{'width.px': thwidt}"></th>
                                            <th class="text-light bg-secondary text-center">Acesorios</th>
                                            <th class="text-light bg-secondary text-center">Despachadores</th>
                                            <th class="text-light bg-secondary text-center">Kimberly</th>
                                            <th class="text-light bg-secondary text-center">Quimicos</th>
                                            <th class="text-light bg-secondary text-center">Rubbermaid</th>
                                            <th class="text-light bg-secondary text-center">Blue Tissue</th>
                                            <th class="text-light bg-secondary text-center">Tork</th>
                                            <th class="text-light bg-secondary text-center">Degrapack</th>
                                            <th class="text-light bg-secondary text-center">Foaom</th>
                                            <th class="text-light bg-secondary text-center">Dove</th>
                                            <th class="text-light bg-secondary text-center">Tapetes</th>
                                            <th class="text-light bg-secondary text-center">Total</th>
                                            <th class="text-light bg-secondary text-center">Promedio</th>
                                            <th class="text-light bg-secondary text-center"  [ngClass]="{'d-none':intPerIni != intPeriodo}">Guardar</th>
                                        </tr>
                                        <tr >
                                            <th class="border-end bg-secondary text-white sticky-col">
                                                Ultimo trimestre 2024
                                            </th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objAccesorios|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDespachadores|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objKimberly|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objQuimicos|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objRubbermaid|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objBlueTissue|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objTork|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDegrapack|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objFoaom|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDove|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objTapetes|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.dblTotal|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end"></th>
                                            <th class="fs13 bg-light text-end border-end"  [ngClass]="{'d-none':intPerIni != intPeriodo}"></th>
                                        </tr>
                                        <tr>
                                            <th class="border-end bg-secondary text-white sticky-col">
                                                Total Por Linea
                                            </th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objAccesorios|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objDespachadores|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objKimberly|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objQuimicos|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objRubbermaid|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objBlueTissue|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objTork|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objDegrapack|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objFoaom|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objDove|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objTapetes|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.dblTotal|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end"></th>
                                            <th class="fs13 bg-light text-end border-end"  [ngClass]="{'d-none':intPerIni != intPeriodo}"></th>
                                        </tr>
                                    </thead>
                                </table>
                                <table mat-table [dataSource]="datosObjx" class="table  table-bordered"
                                    matSort #sortObjx="matSort">
                                    <!-- strCliente Column -->
                                   <!--  <ng-container  matColumnDef="strCliente"> -->
                                        <div matColumnDef="strCliente">
                                        <th #thmed mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bg-secondary text-white sticky-top">Cliente</th>
                                        <td mat-cell *matCellDef="let x" class="border-end sticky-col bg-light pt-2">
                                            <div class="position-relative">
                                                (<span class="fw-bold fs13">{{x.strCliente}}</span>)
                                                <span class="fs13 fw-bold">{{x.strNombre}}</span>
                                            </div>
                                        </td>
                                    </div>
                                   <!--  </ng-container> -->

                                    <!-- objAccesorios Column -->
                                    <ng-container matColumnDef="objAccesorios">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bg-secondary text-white sticky-top"> Accesorios </th>
                                        <td mat-cell *matCellDef="let x" class="mostDet fw-bold ps-0 fs13 text-start align-middle py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Accesorios')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number"  class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'acce')"  [ngClass]="{ 'is-valid': x.objAccesoriosAnt > 0,'is-yellow':x.objAccesorios> 0 && x.objAccesoriosAnt == 0  }" [(ngModel)]="x.objAccesorios" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objAccesorios> 0 && x.objAccesoriosAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- objDespachadores Column -->
                                    <ng-container matColumnDef="objDespachadores">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bg-secondary text-white sticky-top">
                                            Despachadores </th>
                                        <td mat-cell *matCellDef="let x" class="fs11 text-center align-middle py-2"> 
                                            <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DESPACHADORES ECODELI')"></i>
                                            <div class=" position-relative mx-2">
                                                <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'despa')" [ngClass]="{ 'is-valid': x.objDespachadoresAnt > 0,'is-yellow':x.objDespachadores> 0 && x.objDespachadoresAnt == 0}"  [(ngModel)]="x.objDespachadores" (keypress)="onlyNumbers($event)">
                                                <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDespachadores> 0  && x.objDespachadoresAnt == 0"></i>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- objKimberly Column -->
                                    <ng-container matColumnDef="objKimberly">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bg-secondary text-white sticky-top"> Kimberly </th>
                                        <td mat-cell *matCellDef="let x;let ind=index" class="fs12 text-start align-middle py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Kimberly')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'kim')" [ngClass]="{ 'is-valid': x.objKimberlyAnt > 0,'is-yellow':x.objKimberly> 0  && x.objKimberlyAnt == 0}"  [(ngModel)]="x.objKimberly" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objKimberly> 0 && x.objKimberlyAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- objQuimicos Column -->
                                    <ng-container matColumnDef="objQuimicos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bg-secondary text-white sticky-top"> Quimicos </th>
                                        <td mat-cell *matCellDef="let x" class="fs12 text-center align-middle pe-0  py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Quimicos')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'qui')"[ngClass]="{ 'is-valid': x.objQuimicosAnt > 0 ,'is-yellow':x.objQuimicos> 0  && x.objQuimicosAnt == 0}"  [(ngModel)]="x.objQuimicos" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objQuimicos> 0 && x.objQuimicosAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                      <!-- objRubbermaid Column -->
                                      <ng-container matColumnDef="objRubbermaid">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bg-secondary text-white sticky-top">Rubbermaid</th>
                                        <td mat-cell *matCellDef="let x" class="px-2 fs11 text-center align-middle  py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Rubbermaid')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'rubb')" [ngClass]="{ 'is-valid': x.objRubbermaidAnt > 0 ,'is-yellow':x.objRubbermaid> 0  && x.objRubbermaidAnt == 0   }"  [(ngModel)]="x.objRubbermaid" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objRubbermaid> 0  && x.objRubbermaidAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- objBlueTissue Column -->
                                    <ng-container matColumnDef="objBlueTissue">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bg-secondary text-white sticky-top"> Blue Tissue </th>
                                        <td mat-cell *matCellDef="let x" class="mostDet fw-bold ps-0 fs13 text-start align-middle  py-2">
                                            <div class="input-group inputw50 ">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'BLUE TISSUE')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'blue')" [ngClass]="{ 'is-valid': x.objBlueTissueAnt > 0 ,'is-yellow':x.objBlueTissue> 0  && x.objBlueTissueAnt == 0   }"  [(ngModel)]="x.objBlueTissue" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objBlueTissue> 0  && x.objBlueTissueAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- objTork Column -->
                                    <ng-container matColumnDef="objTork">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bg-secondary text-white sticky-top">
                                            Tork </th>
                                        <td mat-cell *matCellDef="let x" class="fs11 text-center align-middle  py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'TORK')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'tork')" [ngClass]="{ 'is-valid': x.objTorkAnt > 0 ,'is-yellow':x.objTork> 0  && x.objTorkAnt == 0  }"  [(ngModel)]="x.objTork" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objTork> 0 && x.objTorkAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- objDegrapack Column -->
                                    <ng-container matColumnDef="objDegrapack">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bg-secondary text-white sticky-top"> Degrapack </th>
                                        <td mat-cell *matCellDef="let x;let ind=index" class="fs12 text-start align-middle  py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DEGRAPACK')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'degra')" [ngClass]="{ 'is-valid': x.objDegrapackAnt > 0 ,'is-yellow':x.objDegrapack> 0 && x.objDegrapackAnt == 0  }"  [(ngModel)]="x.objDegrapack" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDegrapack> 0 && x.objDegrapackAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- objFoaom Column -->
                                    <ng-container matColumnDef="objFoaom">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bg-secondary text-white sticky-top"> Foaom </th>
                                        <td mat-cell *matCellDef="let x" class="fs12 text-center align-middle pe-0  py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'FOAOM')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'foa')" [ngClass]="{ 'is-valid': x.objFoaomAnt > 0 ,'is-yellow':x.objFoaom> 0 && x.objFoaomAnt == 0  }"  [(ngModel)]="x.objFoaom" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objFoaom> 0  && x.objFoaomAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                      <!-- objDove Column -->
                                      <ng-container matColumnDef="objDove">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bg-secondary text-white sticky-top">Dove</th>
                                        <td mat-cell *matCellDef="let x" class="px-2 fs11 text-center align-middle  py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DOVE')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'dove')" [ngClass]="{ 'is-valid': x.objDoveAnt > 0 ,'is-yellow':x.objDove> 0 && x.objDoveAnt == 0  }"  [(ngModel)]="x.objDove" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDove> 0 && x.objDoveAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- objTapetes Column -->
                                    <ng-container matColumnDef="objTapetes">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 bg-secondary text-white sticky-top"> Tapetes </th>
                                        <td mat-cell *matCellDef="let x" class="mostDet fw-bold ps-0 fs13 text-start align-middle  py-2">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-primary pointer" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'TAPETES')"></i>
                                                <div class=" position-relative mx-2">
                                                    <input type="number" class="form-control fs11 p-1 bgInput" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'tap')" [ngClass]="{ 'is-valid': x.objTapetesAnt > 0,'is-yellow':x.objTapetes> 0 && x.objTapetesAnt == 0 }"  [(ngModel)]="x.objTapetes" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objTapetes> 0 && x.objTapetesAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- total Column -->
                                    <ng-container matColumnDef="total">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bg-secondary text-white sticky-top">
                                            Total </th>
                                        <td mat-cell *matCellDef="let x" class="fs11 text-center align-middle  py-2">
                                            {{((x.objAccesorios)+(x.objDespachadores)+(x.objKimberly)+(x.objQuimicos)+
                                                (x.objRubbermaid)+(x.objBlueTissue)+(x.objTork)+(x.objDegrapack)+
                                                (x.objFoaom)+(x.objDove)+(x.objTapetes))|currency}}
                                        </td>
                                    </ng-container>

                                    <!-- promedio Column -->
                                    <ng-container matColumnDef="promedio">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 bg-secondary text-white sticky-top"> Promedio </th>
                                        <td mat-cell *matCellDef="let x;let ind=index" class="fs12 text-start align-middle  py-2">
                                        </td>
                                    </ng-container>

                                    <!-- guardar Column -->
                                    <ng-container matColumnDef="guardar">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 pe-0 bg-secondary text-white sticky-top"> Guardar</th>
                                        <td mat-cell *matCellDef="let x" class="fs12 text-center align-middle pe-0  py-2">
                                            <button class="btn  py-0 px-1  border border-primary shadow-sm" (click)="fnGuardarObjetivos(x)">
                                                <i class="bi bi-floppy2-fill fs-6 text-primary "></i>
                                            </button>
                                        </td>
                                    </ng-container>

                                    
                                    <tr mat-header-row class="rounded" *matHeaderRowDef="columnsCapObj" class="">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: columnsCapObj;" >
                                    </tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="6">
                                            No se encontraron datos de:
                                            "{{input.value}}"
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <mat-paginator class="paginator" [pageSizeOptions]="[10, 25, 50, 100]"
                            aria-label="Select page of users" #paginatorObjx></mat-paginator>
                    </div>








              <!--       <div class="col-12 py-3 ">
                        <div class="table-responsive mb-0 px-0 borderbott bordertop shadow-sm">
                            <table class="table ">
                                <thead>
                                    <tr >
                                        <th class="border-end bg-secondary text-white sticky-col">
                                            Ultimo trimestre 2024
                                        </th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objAccesorios|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDespachadores|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objKimberly|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objQuimicos|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objRubbermaid|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objBlueTissue|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objTork|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDegrapack|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objFoaom|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDove|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.objTapetes|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineasTri.dblTotal|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end"></th>
                                        <th class="fs13 bg-light text-end border-end"  [ngClass]="{'d-none':intPerIni != intPeriodo}"></th>
                                    </tr>
                                    <tr>
                                        <th class="border-end bg-secondary text-white sticky-col">
                                            Total Por Linea
                                        </th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objAccesorios|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objDespachadores|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objKimberly|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objQuimicos|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objRubbermaid|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objBlueTissue|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objTork|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objDegrapack|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objFoaom|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objDove|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.objTapetes|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end">{{objLineas.dblTotal|currency}}</th>
                                        <th class="fs13 bg-light text-end border-end"></th>
                                        <th class="fs13 bg-light text-end border-end"  [ngClass]="{'d-none':intPerIni != intPeriodo}"></th>
                                    </tr>
                                    <tr class="sticky-top">
                                        <th class="text-light bg-secondary text-center sticky-col">Cliente</th>
                                        <th class="text-light bg-secondary text-center">Acesorios</th>
                                        <th class="text-light bg-secondary text-center">Despachadores</th>
                                        <th class="text-light bg-secondary text-center">Kimberly</th>
                                        <th class="text-light bg-secondary text-center">Quimicos</th>
                                        <th class="text-light bg-secondary text-center">Rubbermaid</th>
                                        <th class="text-light bg-secondary text-center">Blue Tissue</th>
                                        <th class="text-light bg-secondary text-center">Tork</th>
                                        <th class="text-light bg-secondary text-center">Degrapack</th>
                                        <th class="text-light bg-secondary text-center">Foaom</th>
                                        <th class="text-light bg-secondary text-center">Dove</th>
                                        <th class="text-light bg-secondary text-center">Tapetes</th>
                                        <th class="text-light bg-secondary text-center">Total</th>
                                        <th class="text-light bg-secondary text-center">Promedio</th>
                                        <th class="text-light bg-secondary text-center"  [ngClass]="{'d-none':intPerIni != intPeriodo}">Guardar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let x of arrayClientes|filtersearch: strSearch : ['strNombreCliente','strCliente'];">
                                        <td class="border-end sticky-col bg-light">
                                            <div class="position-relative">
                                                (<span class="fw-bold fs13">{{x.strCliente}}</span>)
                                                <span class="fs13 fw-bold">{{x.strNombre}}</span>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Accesorios')"></i>
                                                <div class=" position-relative">
                                                    <input type="number"  class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'acce')"  [ngClass]="{ 'is-valid': x.objAccesoriosAnt > 0,'is-yellow':x.objAccesorios> 0 && x.objAccesoriosAnt == 0  }" [(ngModel)]="x.objAccesorios" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objAccesorios> 0 && x.objAccesoriosAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DESPACHADORES ECODELI')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'despa')" [ngClass]="{ 'is-valid': x.objDespachadoresAnt > 0,'is-yellow':x.objDespachadores> 0 && x.objDespachadoresAnt == 0}"  [(ngModel)]="x.objDespachadores" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDespachadores> 0  && x.objDespachadoresAnt == 0"></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Kimberly')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'kim')" [ngClass]="{ 'is-valid': x.objKimberlyAnt > 0,'is-yellow':x.objKimberly> 0  && x.objKimberlyAnt == 0}"  [(ngModel)]="x.objKimberly" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objKimberly> 0 && x.objKimberlyAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Quimicos')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'qui')"[ngClass]="{ 'is-valid': x.objQuimicosAnt > 0 ,'is-yellow':x.objQuimicos> 0  && x.objQuimicosAnt == 0}"  [(ngModel)]="x.objQuimicos" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objQuimicos> 0 && x.objQuimicosAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'Rubbermaid')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'rubb')" [ngClass]="{ 'is-valid': x.objRubbermaidAnt > 0 ,'is-yellow':x.objRubbermaid> 0  && x.objRubbermaidAnt == 0   }"  [(ngModel)]="x.objRubbermaid" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objRubbermaid> 0  && x.objRubbermaidAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'BLUE TISSUE')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'blue')" [ngClass]="{ 'is-valid': x.objBlueTissueAnt > 0 ,'is-yellow':x.objBlueTissue> 0  && x.objBlueTissueAnt == 0   }"  [(ngModel)]="x.objBlueTissue" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objBlueTissue> 0  && x.objBlueTissueAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'TORK')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'tork')" [ngClass]="{ 'is-valid': x.objTorkAnt > 0 ,'is-yellow':x.objTork> 0  && x.objTorkAnt == 0  }"  [(ngModel)]="x.objTork" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objTork> 0 && x.objTorkAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DEGRAPACK')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'degra')" [ngClass]="{ 'is-valid': x.objDegrapackAnt > 0 ,'is-yellow':x.objDegrapack> 0 && x.objDegrapackAnt == 0  }"  [(ngModel)]="x.objDegrapack" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDegrapack> 0 && x.objDegrapackAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5  mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'FOAOM')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'foa')" [ngClass]="{ 'is-valid': x.objFoaomAnt > 0 ,'is-yellow':x.objFoaom> 0 && x.objFoaomAnt == 0  }"  [(ngModel)]="x.objFoaom" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objFoaom> 0  && x.objFoaomAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'DOVE')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'dove')" [ngClass]="{ 'is-valid': x.objDoveAnt > 0 ,'is-yellow':x.objDove> 0 && x.objDoveAnt == 0  }"  [(ngModel)]="x.objDove" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objDove> 0 && x.objDoveAnt == 0  "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs11 text-end border-end align-middle">
                                            <div class="input-group inputw50">
                                                <i class="bi bi-file-bar-graph fs-5 mx-auto mb-1 text-muted" data-bs-toggle="modal" data-bs-target="#modalHistVenta" (click)="fnGetHistVenta(x,'TAPETES')"></i>
                                                <div class=" position-relative">
                                                    <input type="number" class="form-control fs11 p-1" (input)="handleInput($event)" (ngModelChange)="onValueChange($event,x,'tap')" [ngClass]="{ 'is-valid': x.objTapetesAnt > 0,'is-yellow':x.objTapetes> 0 && x.objTapetesAnt == 0 }"  [(ngModel)]="x.objTapetes" (keypress)="onlyNumbers($event)">
                                                    <i class="bi bi-exclamation-triangle-fill position-absolute top-50 end-0 translate-middle-y text-warning me-2" matTooltip="Pendiente de guardar" *ngIf="x.objTapetes> 0 && x.objTapetesAnt == 0 "></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="fs13 text-end border-end align-middle">
                                            {{((x.objAccesorios)+(x.objDespachadores)+(x.objKimberly)+(x.objQuimicos)+
                                                (x.objRubbermaid)+(x.objBlueTissue)+(x.objTork)+(x.objDegrapack)+
                                                (x.objFoaom)+(x.objDove)+(x.objTapetes))|currency}}
                                        </td>
                                        <td class="fs13 text-end border-end align-middle"></td>
                                        <td class="text-center border-end align-middle"  [ngClass]="{'d-none':intPerIni != intPeriodo}">
                                            <button class="btn  py-0 px-1  border border-primary shadow-sm" (click)="fnGuardarObjetivos(x)">
                                                <i class="bi bi-floppy2-fill fs-6 text-primary "></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
   
</div>
  <!-- Modal -->
  <div class="modal fade" id="modalHistVenta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
            <div class="col-12 d-flex">
                <h2 class="modal-title mb-0 mx-auto" id="exampleModalLabel">Histórico de Venta {{tituloart}}</h2>
                <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
           
         
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 text-center mb-3">
                <span><span class="fw-bold me-2">({{cteModal}})</span> {{cteNombreModal}}</span>
            </div>

            <div class="col-12 d-flex justify-content-center" *ngIf="spinerHistVenta">
                <mat-spinner class="rounded-circle"></mat-spinner>
            </div>
            <div class="col-12" *ngIf="!spinerHistVenta && arrayHistVenta.length==0">
                <div class="alert alert-danger text-center fw-bold" role="alert">
                    No se encontro historico de venta
                  </div>
            </div>

            <div class="col-12"  *ngIf="!spinerHistVenta && arrayHistVenta.length>0">
                <div class="table-responsive mb-0 boder shadow borderbott bordertop">
                    <table class="table table-striped table-bordered">
                        <thead class="turclaro">
                           <tr>
                              <th class="fs13 fw-bold bgtableper2" colspan="9">Total de lineas: {{totalMov}}</th>
                              <th class="text-center fs13 bgtableper2">{{totalMes1 + totalMes2 + totalMes3 + totalMesA  | currency }}</th>
                           </tr>
                        </thead>
                        <thead class="tabla_gris">
                           <tr>
                              <th class="text-center fs13 fw-bold align-middle bgtable" rowspan="2"><br>LINEA</th>
                              <th class="text-center fs13 bgtableper" colspan="2">{{nombreMes1}}</th>
                              <th class="text-center fs13 bgtableper" colspan="2">{{nombreMes2}}</th>
                              <th class="text-center fs13 bgtableper" colspan="2">{{nombreMes3}}</th>
                              <th class="text-center fs13 bgtableper" colspan="2">{{nombreMesA}}</th>
                              <th class="text-center fs13 fw-bold bgtable" rowspan="2"><br>TOTAL</th>
                           </tr>
                           <tr>
                              <th class="text-center fs13 bgtable">Cantidad</th>
                              <th class="text-center fs13 bgtable">Importe</th>
                              <th class="text-center fs13 bgtable">Cantidad</th>
                              <th class="text-center fs13 bgtable">Importe</th>
                              <th class="text-center fs13 bgtable">Cantidad</th>
                              <th class="text-center fs13 bgtable">Importe</th>
                              <th class="text-center fs13 bgtable">Cantidad</th>
                              <th class="text-center fs13 bgtable">Importe</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr class="text-center" *ngFor="let HL of arrayHistVenta">
                              <td class="gris bordeTable fs12" > {{HL.strLinea}}</td>
                              <td class="text-center fs12 bordeTable" >{{HL.intCantidadConFactor1 |number:'1.0-0'}}</td>
                              <td class="text-center fs12 bordeTable" >{{HL.dblMes1 | currency}}</td>
                              <td class="text-center fs12 bordeTable" >{{HL.intCantidadConFactor2 | number:'1.0-0'}}</td>
                              <td class="text-center fs12 bordeTable" >{{HL.dblMes2 | currency}}</td>
                              <td class="text-center fs12 bordeTable" >{{HL.intCantidadConFactor3 | number:'1.0-0'}}</td>
                              <td class="text-center fs12 bordeTable" >{{HL.dblMes3 | currency}}</td>
                              <td class="text-center fs12 bordeTable" >{{HL.intCantidadConFactorA | number:'1.2-2'}}</td>
                              <td class="text-center fs12 bordeTable">{{HL.dblMesA | currency}}</td>
                              <td class="text-center fs12 bordeTable">{{HL.dblMes1 + HL.dblMes2 + HL.dblMes3 + HL.dblMesA | currency}}</td>
                           </tr>
                        </tbody>
                        <tfoot>
                            <tr class="  text-center" >
                                <th class="text-center fs13 fw-bold">TOTAL:</th>
                                <th class="text-center fs13">{{totalCan1 | number:'1.0-0'}}</th>
                                <th class="text-center fs13">{{totalMes1 | currency}}</th>
                                <th class="text-center fs13">{{totalCan2 | number:'1.0-0'}}</th>
                                <th class="text-center fs13">{{totalMes2 | currency}}</th>
                                <th class="text-center fs13">{{totalCan3 | number:'1.0-0'}}</th>
                                <th class="text-center fs13">{{totalMes3 | currency}}</th>
                                <th class="text-center fs13">{{totalCanA | number:'1.0-0'}}</th>
                                <th class="text-center fs13">{{totalMesA | currency}}</th>
                                <th class="text-center fs13">{{totalMes1 + totalMes2 + totalMes3 + totalMesA  | currency}}</th>
                             </tr>
                        </tfoot>
                  
                     </table>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
         
        </div>
      </div>
    </div>
  </div>










  <!-- <thead>
                                        <tr >
                                        
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objAccesorios|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDespachadores|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objKimberly|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objQuimicos|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objRubbermaid|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objBlueTissue|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objTork|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDegrapack|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objFoaom|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objDove|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.objTapetes|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineasTri.dblTotal|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end"></th>
                                            <th class="fs13 bg-light text-end border-end"  [ngClass]="{'d-none':intPerIni != intPeriodo}"></th>
                                        </tr>
                                        <tr>
                                            
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objAccesorios|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objDespachadores|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objKimberly|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objQuimicos|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objRubbermaid|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objBlueTissue|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objTork|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objDegrapack|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objFoaom|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objDove|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.objTapetes|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end">{{objLineas.dblTotal|currency}}</th>
                                            <th class="fs13 bg-light text-end border-end"></th>
                                            <th class="fs13 bg-light text-end border-end"  [ngClass]="{'d-none':intPerIni != intPeriodo}"></th>
                                        </tr>
                                    </thead> -->