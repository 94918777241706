import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
@Component({
  selector: 'app-loginn',
  templateUrl: './loginn.component.html',
  styleUrls: ['./loginn.component.css']
})
export class LoginnComponent implements OnInit {

  constructor(private _peticionesService: PeticionesService) {
    
   }



  public arrayListadoAromia:any=[];
  ngOnInit(): void {
    this.fnlstarPedidos();
  }

fnlstarPedidos(){
  const updatedAtMin = '2023-07-31T15:57:11-04:00';
  const financialStatus = 'paid';
let obj:any={
  tipo:"GET",
  api:"products.json?financial_status=paid&updated_at_min=2023-12-03T15:57:11-04:00"
};
  // Llamar al método del servicio para obtener los datos
  this._peticionesService.getOrders(obj)
    .subscribe((data) => {
  this.arrayListadoAromia= data
      console.log(this.arrayListadoAromia.products); // Aquí puedes manejar los datos obtenidos
      console.log(this.arrayListadoAromia);
    });
}

}
