<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold  text-decoration-underline">Remisiones <span class="titulo">Pendientes</span></h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4 col-xl-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-2':boolGerente,'col-xl-3':boolSucursal && !boolGerente}">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4   d-flex justify-content-center"[ngClass]="{'col-xl-1':boolGerente,'col-xl-2':boolSucursal && !boolGerente,'col-xl-3':boolAgente &&!boolSucursal && !boolGerente }" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-4  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-1':boolGerente ||boolSucursal||boolAgente  }" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetRemisiones()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>


    <div class="row mt-3">
        <div class="col-12 clsIcon px-0 bordertop borderbott shadow">
            <mat-form-field appearance="outline" class="bg-white text-dark rounded ms-3 my-2">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilterTareasCte($event)" placeholder="mov,cliente,etc..." #input>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <div class="mb-0 table-responsive">
                <div class="mat-elevation-z8 mb-0  shadow rounded">
                    <table mat-table [dataSource]="datosTareasCte" class="table table-hover table-bordered"  matSort #sortTareasCte="matSort">
                         <!-- movimiento Column -->
                      <ng-container matColumnDef="movimiento">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-auto fs13 align-middle clsIcon">Movimiento</th>
                        <td mat-cell *matCellDef="let row" class="fs12"> 
                            {{row.strMovID}}
                        </td>
                        <td class="clsIcon" mat-footer-cell *matFooterCellDef>Total movimientos: </td>
                      </ng-container>

                      <!-- cliente Column -->
                      <ng-container matColumnDef="cliente">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class=" fs13  align-middle text-center clsIcon"> Cliente </th>
                        <td mat-cell *matCellDef="let row" class="mostDet fw-bold ps-0 fs12 text-center align-middle"> 
                            {{row.strCliente}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="clsIcon text-center fw-bold"> {{totalMov|number:'1.0-0'}}</td>
                      </ng-container>

                      <!-- nombre Column -->
                      <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 px-auto  align-middle clsIcon"> Nombre </th>
                        <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle"> <button class="btn btn-link fs13 p-0" (click)="fnDesgloceRemisiones(row)">{{row.strNombre}} </button> </td>
                        <td mat-footer-cell *matFooterCellDef class="clsIcon">  </td>
                      </ng-container>

                      <!-- fecharequerida Column -->
                      <ng-container matColumnDef="fecharequerida">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="fs13 px-auto  align-middle clsIcon"> Fecha Requerida </th>
                        <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-center align-middle">
                          {{row.strFechaRequerida}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="clsIcon">  </td>
                      </ng-container>
                      <!-- importe Column -->
                        <ng-container matColumnDef="importe">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 px-auto   align-middle clsIcon"> Importe </th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-end align-middle pe-2 fw-bold ">
                                {{row.dblImporte|currency}}
                            </td>
                            <td mat-footer-cell class="clsIcon text-end fw-bold pe-2" *matFooterCellDef> {{totalImporte| currency}} </td>
                        </ng-container>

                        <!-- semaforo Column -->
                        <ng-container matColumnDef="semaforo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 px-auto   align-middle clsIcon"> semaforo </th>
                            <td mat-cell *matCellDef="let row" class="fs12 text-center align-middle px-auto">
                                <span class="rounded text-white px-2 py-1 fw-bold" [ngClass]="{'bg-success':row.strDiasDif>=0,'bg-danger':row.strDiasDif<0}">{{row.strDiasDif}}</span>
                            </td>
                            <td class="clsIcon" mat-footer-cell *matFooterCellDef>  </td>
                        </ng-container>
    
                      <tr mat-header-row class="rounded sticky-top" *matHeaderRowDef="columnsTareasCte"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnsTareasCte;" ></tr>
                      <tr mat-footer-row *matFooterRowDef="columnsTareasCte; sticky: true"></tr>
                  
                     
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="6">No se encontraron datos de: "{{input.value}}"</td>
                      </tr>
                    </table>
                  </div>
            </div>
            <mat-paginator class="paginator borderbott" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorTareasCte></mat-paginator>
        </div>
    </div>
 




</div>