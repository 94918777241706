import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { LocalStorageService } from 'ngx-webstorage';
import { DataDBService } from '../servicios/data-db.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { AlertifyService } from '../alertify.service';
import { FiltrosService } from '../servicios/filtros.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as XLSX from 'xlsx';

export interface tareasCte {
  strCliente: string;
  strCteEnviarA:string;
  strAgente:string;
  strDespachador:string;
  dblCantidad_Articulo:string;
  strNombreDespachador:string;
  strNombreConsumible:string;
  strArticulo:string;
  strSubCuenta:string;
  dblCantidadPrometida:string;
  dblCantidad_Comprada:string;
}

@Component({
  selector: 'app-reporte-comodatos-cliente',
  templateUrl: './reporte-comodatos-cliente.component.html',
  styleUrls: ['./reporte-comodatos-cliente.component.css'],  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReporteComodatosClienteComponent implements OnInit {
  public strGerente: string="";
  public inputSearch:string="";
  public strAgente:string="";
  public strSucursal:string="";
  public strConexion:string
  public intEjercicio:number=0;
  public intPeriodo:number; 
  public arrayDatosTabla:any=[];
  public arrayEjercicio:any=[];
  public arrayAgentes:any=[];
  public arrayPeriodo:any= [];
  public arrayAccesos:any=[];
  public arraySucursales:any=[];
  public arrayGerentes:any=[]; 

  public boolTable:boolean=false;
  public boolGerente:boolean = false;
  public boolSucursal:boolean=false;
  public boolAgente:boolean=false;
  public load:boolean=false;
  public isHoveredButton: boolean = false;
  public tagName: any;
  public datosFiltrados:any=[];
  public urlImagen:string ="";
  public artImagen :string="";
  public objSumas:any={
    dblCantidadPrometida :0,
    dblprecioPrometida:0,
    dblCantidadComprada :0,
    dblprecioComprada :0,
  }

  title = 'export-excel';
  fileName = 'ReporteComodatosClientes.xlsx';

  columnsToDisplay = ['name', 'weight', 'symbol', 'position'];

  columnsTareasCte: string[] = ['strCliente','strCteEnviarA','strAgente','strDespachador','strNombreDespachador','dblCantidad_Articulo','strSubCuenta','strArticulo','strNombreConsumible','dblCantidadPrometida','dblPrecio','dblCantidad_Comprada','dblPrecioPlantilla'];
  @ViewChild('paginadorTareasCte') paginadorTareasCte: MatPaginator;
  @ViewChild('sortTareasCte') sortTareasCte: MatSort;

  datosTareasCte: MatTableDataSource<tareasCte>;

  constructor(private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl,
    public _alertify: AlertifyService, private router: Router, private _filtroServ: FiltrosService,
    private _httpService:PeticionesService,private elem: ElementRef, private localSt: LocalStorageService){
        this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
        let fecha = new Date();
        this.intEjercicio = fecha.getFullYear();
        this.intPeriodo = fecha.getMonth() + 1;
        this.strConexion = this.localSt.retrieve('strConexion');
        this.tagName = elem.nativeElement.tagName.toLowerCase();
        this._httpService.setMostrarMenu(true);
        this.indexedDBService.getItem('arrayResult', (value) => {
        this.arrayAccesos=value?value:[];
          
        if (this.arrayAccesos.length > 0) {
          var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
          if (objAcceso == undefined || objAcceso.length == 0) {
            this.router.navigate(['/login']);
          } else {
            this.boolAgente = objAcceso.find((o: any) => o.strAccion == 'lstAgentes') ? true : false;
            this.boolSucursal = objAcceso.find((o: any) => o.strAccion == 'lstSucursales') ? true : false;
            this.strSucursal = !this.boolSucursal ? this.localSt.retrieve('strSucursal') : '';
            this.boolGerente = objAcceso.find((o: any) => o.strAccion == 'lstDivisionales') ? true : false;
            this.strAgente = this.boolAgente ? '' : this.localSt.retrieve('strAgente');//!this.boolAgente?'':this.localSt.retrieve('strAgente');
            this.strGerente = (!this.boolGerente && this.boolSucursal) ? objAcceso[0].strUsuario : ''; //!this.boolGerente?'':this.localSt.retrieve('strUsuario');
          }
         }
         else {
          this.router.navigate(['/login']);
         }
        });
  }

  ngOnInit(): void {
    this.fnGetFiltros()
    this.getDatosTabla()
  }

  getDatosTabla(){
    this.datosFiltrados = [];
    this.arrayDatosTabla = [];
    try{
      this.load = true;
    let objBono = {
      strAccion: 'getReporte_Comodato_cliente',
      strSucursal:this.strSucursal,
      strAgente:this.strAgente,
      strGerente:this.strGerente,
      intEjercicio:this.intEjercicio,
      intPeriodo:this.intPeriodo,
      strConexion: this.strConexion};
      if(this.strAgente!="" && this.strAgente != null){
        objBono.strAgente= this.strAgente 
        objBono.strSucursal=""
        objBono.strGerente=""
      }else if(this.strSucursal!=""&& this.strSucursal != null){
        objBono.strAgente= ""
        objBono.strSucursal=this.strSucursal
        objBono.strGerente=""
      }else if(this.strGerente!=""&& this.strGerente != null){
        objBono.strAgente= ""
        objBono.strSucursal=""
        objBono.strGerente=this.strGerente
      } 
 
    this._httpService.mostrarCotizaciones(objBono,'sp_ModVentas_Comodato').subscribe(
      result =>{
      
        this.arrayDatosTabla = result;
        this.objSumas.dblCantidadPrometida = 0;
        this.objSumas.dblprecioPrometida = 0;
        this.objSumas.dblCantidadComprada = 0;
        this.objSumas.dblprecioComprada = 0;

        this.arrayDatosTabla.forEach(obj => {
          this.objSumas.dblCantidadPrometida = this.objSumas.dblCantidadPrometida + obj.dblCantidadPrometida;
          this.objSumas.dblprecioPrometida = this.objSumas.dblprecioPrometida + obj.dblPrecio;
          this.objSumas.dblCantidadComprada = this.objSumas.dblCantidadComprada + obj.dblCantidad_Comprada;
          this.objSumas.dblprecioComprada =this.objSumas.dblprecioComprada + obj.dblPrecioPlantilla;
        });
      
        
      /*   this.arrayDatosTabla.forEach(cte => {
          let cteDistinct = {}
          cteDistinct = this.datosFiltrados.find(o=> o.strCliente == cte.strCliente && o.strCteEnviarA == cte.strCteEnviarA)
          if(!cteDistinct){
            this.datosFiltrados.push(cte)
          }
        });
        let arrayProf = JSON.parse(JSON.stringify(this.arrayDatosTabla));
        this.datosFiltrados.forEach(cte => {
          cte.arrayArts = [];
          cte.arrayArts = arrayProf.filter(o => o.strCliente ==  cte.strCliente && o.strCteEnviarA == cte.strCteEnviarA)
        }); */
        this.datosTareasCte = new MatTableDataSource(this.arrayDatosTabla);
        this.fnTabletareasCte();
  
        this.load = false;
      },(error) => {
        console.log("error: ", error);
        this.load = false;
      });
    }catch(error){
      this.load = false;
    }
  }
  fnGetFiltros() {
    this._filtroServ.array_Periodos_$.subscribe((nuevoValor) => {
      this.arrayPeriodo = nuevoValor;
    });
    this._filtroServ.array_Ejercicios_$.subscribe((nuevoValor) => {
      this.arrayEjercicio = nuevoValor;
    });
    this._filtroServ.array_Agentes_$.subscribe((nuevoValor) => {

      this.arrayAgentes = nuevoValor;
    });
    this._filtroServ.array_Sucursales_$.subscribe((nuevoValor) => {
      this.arraySucursales = nuevoValor;
    });

    this._filtroServ.array_Divisionales_$.subscribe((nuevoValor) => {
      this.arrayGerentes = nuevoValor;
    });
  }

  fnTabletareasCte() {
    this.datosTareasCte.paginator = this.paginadorTareasCte;
    this.datosTareasCte.sort = this.sortTareasCte;
  }

  fnFiltrarPorAcceso(){

  }
  applyFilterTareasCte(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datosTareasCte.filter = filterValue.trim().toLowerCase();
    if (this.datosTareasCte.paginator) {
      this.datosTareasCte.paginator.firstPage();
    }
  }

  fnAbrirImagen(row:any,tipo:string){
    this.artImagen = tipo=="Despachador"?row.strDespachador:row.strConsumible;
    this.urlImagen = 'https://www.cleanclean.mx/imagenes/'+this.artImagen+'.JPG'
  }

  exportexcel(): void {


    const splitText = (text: string, limit: number) => {
      // Divide el texto en la primera posición de espacio después del límite de caracteres
      const index = text.lastIndexOf(" ", limit);
      return index !== -1 ? text.slice(0, index) + "\n" + text.slice(index + 1) : text;
    };
 
    let arrayExportExcel:any = [];
    this.arrayDatosTabla.forEach(ex => {
      const consumible = ex.strArticulo && ex.strArticulo != "" 
        ? `(${ex.strArticulo}) - ${splitText(ex.strNombreConsumible, 40)}` // Ajusta el límite según sea necesario
        : "";
      arrayExportExcel.push(
        {
          Cliente:"("+ex.strCliente+")"+"-"+ex.strNombreCte,
          Sucursal:ex.strCteEnviarA!=0?"("+ex.strCteEnviarA+")"+"-"+ex.strSucursal:"",
          Agente:ex.strAgente,
          Nombre_Agente : ex.strAgenteNombre,
          Despachador:ex.strDespachador!=0?"("+ex.strDespachador+")"+"-"+ex.strNombreDespachador:"",
          Sub_Cuenta:ex.strNombreOpcion && ex.strNombreOpcion!=""?"("+ex.strSubCuenta+")"+"-"+ex.strNombreOpcion:"",
          Consumible:consumible/* ex.strArticulo && ex.strArticulo!=""?"("+ex.strArticulo+")"+"-"+ex.strNombreConsumible:"" */,
          Cantidad_Prometida:ex.dblCantidadPrometida,
          Importe_Cantidad_Prometida:ex.dblPrecio,
          Cantidad_Comprada:ex.dblCantidad_Comprada,
          Importe_Cantidad_Comprada:ex.dblPrecioPlantilla,
        }
      )
    }); 
    
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(arrayExportExcel);
    Object.keys(ws).forEach(cell => {
      if (cell.startsWith("G") || cell.startsWith("I")) { // Columnas G y I corresponden a los importes
        ws[cell].z = '"$"#,##0.00'; // Formato contable en Excel
      }
      if (ws[cell] && cell.startsWith("E")) { // Columna E es "Consumible"
        ws[cell].s = { alignment: { wrapText: true } }; // Aplicar ajuste de texto
      }
    });
    
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Clientes');
    XLSX.writeFile(wb, this.fileName);
  }

  
  onGerenteChange(srtCam:string){
    if(srtCam=="Ger"){
      this.strSucursal =""
      this.strAgente =""
    }else if(srtCam=="Suc"){
      this.strAgente =""
    }
  }
}

