<div class="container-fluid px-md-5">
    <div class="row">
        <div class="col-12 product-line">
            <h1 class="text-center fw-bold nunito my-3">
                {{strGrupo}}
            </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-3  text-center">
            <div class="col-12 shadow py-3 rounded d-none d-md-block">
                <div class="row product-line ">
                    <h1 class="text-center fs-5 fw-bold mb-0 txt2">
                        FILTRAR POR LINEA
                    </h1>
                </div>
              <div class="row py-3">
                <div class="col-12 px-2" *ngIf="arrayLineas.length==0">
                    <div class="alert alert-danger text-center fw-bold mx-3" role="alert">
                        NO SE ENCONTRARON LINEAS EN: {{strGrupo}}
                    </div>
                </div>
                <ng-container *ngIf="arrayLineas.length>0">
                    <div class="col-12 d-flex justify-content-center" *ngFor="let lin of arrayLineas">
                        <div (click)="fnSelectLinea(lin)" class="col-11 fs14 nunito fw-bold text-center mt-3 clsSec rounded shadow-sm py-2 pointer" [ngClass]="{'selectProd':lin.strGrupo== strLinea}">
                            {{lin.strGrupo  }}
                        </div>
                    </div>
                </ng-container>
              </div>
            </div>
        </div>
        <div class="col-12 col-md-9">
            <div class="col-12 rounded shadow bg-white py-2 ps-2 d-flex align-items-center">
                <mat-form-field appearance="outline">
                    <mat-label>Buscar</mat-label>
                    <input matInput placeholder="articulo" [(ngModel)]="strBuscar">
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>

                  <button class="btn btn-primary  d-md-none  mx-auto" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"><i class="bi bi-funnel-fill"></i> por linea</button>
            </div>
            <div class="col-12 mt-3">
                <div class="row">
                    <ng-container *ngIf="arrayArtsporlinea.length>0">
                        <div class=" col-6 col-lg-4 col-xxl-3  d-flex " data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal(art)"
                         *ngFor="let art of arrayArtsporlinea| filtersearch: strBuscar: ['strArticulo']">
                            <div class="col-12 mb-3 pointer px-0 position-relative shadow-sm rounded mt-2 bg-white" >
                             
                                <div class="d-flex flex-column h-100 conte">

                                  <div class="rounded position-relative flex-grow-1 img-container " >
                                    <a  >
                                       <img [src]="getImageUrl(art.strArticulo)" (error)="onImageError($event)" class="w-100 zoom-image" alt="..."> 
                                    </a>
                                    <span class=" ps-2 pe-3 position-absolute top-0 start-0 fs15 fw-bold nunito artCom">{{art.strArticulo}}</span>
                                    <div class="precioAdd position-absolute bottom-0 start-50 translate-middle-x">
                                      <a id="boton" class="btn btnadd"  ><i class="bi bi-bag-plus"></i> <span class="fw-bold ms-2">Agregar</span></a>
                                    </div>
                                  </div>
                                  <div class=" d-flex flex-column py-2">
                                    <!-- <span class=" text-center fw-bolder txt2 nunito fs-5">{{art.dblPrecioLista|currency}}</span> -->
                                    </div>
                                  <div class="col-12 mb-2 my-auto px-2">
                                      <span class="precio card-text  fw-bold fs13 text-secondary  descripcion-truncada">{{art.strDescripcion1}}</span>
                                  </div>
                                </div>
                              
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="offcanvas offcanvas-bottom" style="height: 60vh;" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
  <div class="offcanvas-header">
        <h1 class="text-center fs-5 fw-bold mb-0 txt2">
            FILTRAR POR LINEA
        </h1>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body small">
    <div class="row py-3">
        <div class="col-12 px-2" *ngIf="arrayLineas.length==0">
            <div class="alert alert-danger text-center fw-bold mx-3" role="alert">
                NO SE ENCONTRARON LINEAS EN: {{strGrupo}}
            </div>
        </div>
        <ng-container *ngIf="arrayLineas.length>0">
            <div class="col-12 d-flex justify-content-center" *ngFor="let lin of arrayLineas">
                <div (click)="fnSelectLinea(lin)" class="col-11 fs14 nunito fw-bold text-center mt-3 clsSec rounded shadow-sm py-2 pointer" [ngClass]="{'selectProd':lin.strLinea == strLinea}">
                    {{lin.strLinea}}
                </div>
            </div>
        </ng-container>
      </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade  h-modal modalheigth" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg ">
      <div class="modal-content ">
        <div class="modal-header titulos">
          <h1 class="modal-title fs-5 mb-0 mx-auto" id="exampleModalLabel">Articulo {{objArt && objArt.strArticulo ? objArt.strArticulo : ""  }}</h1>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body ">
          <div class="col-12 d-flex justify-content-center my-5" *ngIf="spinnerModal">
            <mat-spinner [diameter]="60"  class="spinner-container"></mat-spinner> 
          </div>
        <div class="row align-items-center"*ngIf="!spinnerModal">
          
          <div class="col-12 col-lg-6" *ngIf="!spinnerModal">
            <h3 class="d-inline-block d-sm-none">{{objArt && objArt.strDescripcion1 ? objArt.strDescripcion1 : ""}}</h3>
            <div id="carouselExampleIndicators" class="col-12 h-75 carousel slide d-flex align-items-center" data-bs-touch="false" data-bs-interval="false">
              
              <div class="carousel-inner imgCar " >
                <div class="carousel-item active text-center " >
                  <img [src]="imagenUrl1" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                </div>
                <div class="carousel-item text-center">
                  <img [src]="imagenUrl2" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                </div>
                <div class="carousel-item text-center">
                  <img [src]="imagenUrl3" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                </div>
                <div class="carousel-item text-center">
                  <img [src]="imagenUrl4" (error)="onImageError($event)"  class="zoom1" alt="Product Image">
                </div>
                <div class="carousel-item text-center">
                  <img [src]="imagenUrl5" (error)="onImageError($event)"  class="zoom1" alt="Product Image">
                </div>
              </div>
              <button class="carousel-control-prev bg-transparent" style="color: black !important;" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="material-symbols-outlined">
                  arrow_back_ios
                  </span>
              </button>
              <button class="carousel-control-next bg-transparent" style="color: black !important;" type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next">
                <span class="material-symbols-outlined">
                  arrow_forward_ios
                  </span>
              </button>
            </div>
            <div class="col-12 product-image-thumbs mt-0">
              <div class="product-image-thumb active col" aria-current="true" aria-label="Slide 1" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"  >
                <img [src]="imagenUrl1" (error)="onImageError($event)" alt="Product Image">
              </div>
              <div class="product-image-thumb col" *ngIf="imagenUrl2!='False'" aria-current="true" aria-label="Slide 2" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"  >
                <img [src]="imagenUrl2" (error)="onImageError($event)" alt="Product Image">
              </div>
              <div class="product-image-thumb col" *ngIf="imagenUrl3!='False'" aria-current="true" aria-label="Slide 3" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"  >
                <img [src]="imagenUrl3" (error)="onImageError($event)"  alt="Product Image">
              </div>
              <div class="product-image-thumb col" *ngIf="imagenUrl4!='False'" aria-current="true" aria-label="Slide 4" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"  >
                <img [src]="imagenUrl4" (error)="onImageError($event)"  alt="Product Image">
              </div>
              <div class="product-image-thumb col" *ngIf="imagenUrl5!='False'" aria-current="true" aria-label="Slide 5" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"  >
                <img [src]="imagenUrl5" (error)="onImageError($event)"  alt="Product Image">
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 modalheigth1">
            <div class="row">
              <div class="col-12">
                <h2 class="my-3 fw-bolder">{{objArt && objArt.strArticulo ? objArt.strArticulo : ""  }}</h2>
                <p>{{objArt && objArt.strDescripcion1 ? objArt.strDescripcion1 : ""}}</p>
              </div>
           
            </div>
            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-12 col-md-6" [hidden]="objArt.strTipoOpcion == 'No'">
                <div class="col-12">
                  <h3 class="fw-bolder mb-1">
                    Opcion
                  </h3>
                </div>
                <div class="col-12">
                  <select class="form-select  shadow-sm" [(ngModel)]="objArt.strOpcion" aria-label=".form-select-lg example" (change)="onOpcionSeleccionada(objArt.strOpcion,objArt)">
                    <option *ngFor="let item of objArt.arrayOpciones; let i = index;"  [ngValue]="item.strOpcion"> 
                     <span class="me-2">
                      <b> {{item.strOpcion}}</b>
                     </span>
                     &nbsp;
                     &nbsp;
                     <span>
                      {{item.strNombreOpcion}}
                     </span> 
                    </option>
                  </select>
                </div>
                
              </div> 
              <div class=""  [ngClass]="{'col-12 col-md-6':objArt.strTipoOpcion != 'No','col-10':objArt.strTipoOpcion == 'No'}">
                <div class="ms-auto col-10">
                  <h3 class="fw-bolder mb-1">
                    Precio
                  </h3>
                </div> 
                <div class="col-12">
                  <select [(ngModel)]="objArt.dblPrecioLista" class="form-select fs-8" aria-label="Default select example">
                    <option *ngIf="intPrecioPolitica>0"  value="{{intPrecioPolitica}}">PP {{intPrecioPolitica|currency}}</option>
                    <option *ngIf="intPrecioPlantilla>0"  value="{{intPrecioPlantilla}}">PLA {{intPrecioPlantilla|currency}}</option>
                    <option *ngIf="intPrecioLista>0"  value="{{intPrecioLista}}">PL {{intPrecioLista|currency}}</option>
                    <option *ngIf="intPrecio>0"  value="{{intPrecio}}">P1 {{intPrecio|currency}}</option>
                    <option *ngIf="intPrecio2>0" value="{{intPrecio2}}">P2 {{intPrecio2|currency}}</option>
                    <option *ngIf="intPrecio3>0" value="{{intPrecio3}}">P3 {{intPrecio3|currency}}</option>
                    <option *ngIf="intPrecio4>0" value="{{intPrecio4}}">P4 {{intPrecio4|currency}}</option>
                  </select>
               <!--  <label for="" class="fw-bolder fs-4 w-100 text-dark text-center dblprecio">
                 {{ objArt.dblPrecioLista|currency}}
                </label> -->
              </div>
              </div>
              <!-------Consumible-------->
              <div class=""  [ngClass]="{'col-12 col-md-6':objArt.objConsumible.strTipoOpcion != 'No','col-10':objArt.objConsumible.strTipoOpcion == 'No'}">
                <div class="ms-auto col-10">
                  <h3 class="fw-bolder mb-1">
                    Consumible
                  </h3>
                </div> 
                <div class="col-12">
                  <select class="form-select fs-8" aria-label="Default select example" [(ngModel)]="objArt.objConsumible" (change)="fnCambiaConsumible(objArt.objConsumible)">
                    <option *ngFor="let consumible of arrayConsumible" [ngValue]="consumible">({{consumible.strConsumible }})  {{consumible.strNombreConsumible}}</option>
                  </select>
          <!--****{{objArt.objConsumible   | json }}**   {{objArt.arrayConsumible | json}}  -->  
              </div>
              </div>
               <!--opcion consumible-->
               <div class="col-12 col-md-6" [hidden]="objArt.objConsumible.strTipoOpcion== 'No'">
                <div class="col-12">
                  <h3 class="fw-bolder mb-1">
                    Opcion Consumible
                  </h3>
                </div>
                <div class="col-12">
                  <select class="form-select  shadow-sm" [(ngModel)]="objArt.objOpcionConsumible" aria-label=".form-select-lg example" > <!--(change)="onOpcionSeleccionada(objArt.strOpcion,objArt)"-->
                    <option *ngFor="let item of arrayConsumibleOpcion; let i = index;"  [ngValue]="item"> 
                     <span class="me-2">
                      <b> ({{item.strOpcion}})</b>
                     </span>
                  
                     <span>
                      {{item.strNombreOpcion}}
                     </span> 
                    </option>
                  </select>
                </div>
                
              </div> 
            </div>
            <div class="row mt-4 d-flex align-items-center">
              <div class="col-12 col-sm-6">
                <div class="col-10 mx-auto">
                  <div class="input-group rounded-pill">
                    <button class="btn  btnCirs" (click)="fnAdd_Rest_Amount(objArt,'rest');" type="button">-</button>
                      <input type="text" value="0" [(ngModel)]="objArt.dblCantidad" class="form-control text-center" placeholder="" aria-label="Recipient's username with two button addons">
                    <button class="btn  btnCire" (click)="fnAdd_Rest_Amount(objArt,'add');" type="button">+</button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 ">
                  <div class="col-10 mx-auto" >
                    <button [disabled]="spinerCarrito || !objArt.dblPrecioLista || objArt.dblPrecioLista < 1" (click)="fnAddToCarr(objArt);" class="w-100 position-relative btn btnCar py-2 fs13 rounded-pill d-flex align-items-center justify-content-center shadow-sm">
                      <span *ngIf="!spinerCarrito" class="fs10">Agregar a Comodato</span> 
                     <span style="color:#FFEF92" *ngIf="spinerCarrito">.</span> 
                     <div class="pt-1 position-absolute top-50 start-50 translate-middle" *ngIf="spinerCarrito">
                      <div class="spinner-border text-primary " role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                     </div>
                    </button>
                  </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button class="btn btn-link" type="button" (click)="toggleAccordion(objArt)" [attr.aria-expanded]="isExpanded ? 'true' : 'false'" aria-controls="collapseOne">
                          Existencias
                        </button>
                      </h5>
                    </div>
                
                    <div id="collapseOne" class="collapse" [ngClass]="{ 'show': isExpanded }" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12 table-responsive mx-0 px-0 mb-2 border rounded">
                            <table class="table table-bordered bg-white fixed-header-table">
                              <thead>
                                <tr class="">
                                  <th class="text-center  text-white">Almacen</th>
                                  <th class="text-center  text-white">Nombre</th>
                                  <th class="text-center text-white">Disponibles</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let x of arrayExistentes; let ind = index">
                                  <td>{{x.strAlmacen}}</td>
                                  <td>{{x.strAlmacenNombre}}</td>
                                  <td><b>{{x.dblDisponible}}</b></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Repeat for other accordion items -->
                </div>
                
                <!---------------------------------- 
                <div class="accordion accordion-flush " id="accordionFlushExample">
                  <div class="accordion-item py-1">
                    <h2 class="accordion-header mb-0" id="flush-headingOne">
                      <button (click)="fntraerExistencias(objArt);" class="fw-bold acordeon accordion-button collapsed border rounded px-3 py-1 fs12" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Existencias
                      </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse"    aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
             
                      <div class="accordion-body pt-2 pb-0 bgbodyacordeon">
                        <div class="row">
                          <div class="col-12 table-responsive mx-0 px-0 mb-2 border rounded">
                            <table class="table table-bordered bg-white fixed-header-table">
                              <thead>
                                <tr class="">
                                  <th class="text-center  text-white">Almacen</th>
                                  <th class="text-center  text-white">Nombre</th>
                                  <th class="text-center text-white">Disponibles</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let x of arrayExistentes; let ind = index">
                                  <td>{{x.strAlmacen}}</td>
                                  <td>{{x.strAlmacenNombre}}</td>
                                  <td><b>{{x.dblDisponible}}</b></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->

              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="modal-footer titulos py-1">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  