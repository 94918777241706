<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold  text-decoration-underline">
                Presupuesto <span class="titulo">Por Sucursal</span>
            </h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-2">
        <div class="col-12 mt-2 pt-1 col-md  d-flex justify-content-center">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
                <mat-label class="fw-bold"><mat-icon>event_note</mat-icon>Ejercicio</mat-label>
                <mat-select [(ngModel)]="intEjercicio">
                    <mat-option *ngFor="let e of arrayEjercicio" [value]="e.ejercicio">
                        {{e.ejercicio}}
                    </mat-option> 
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md  d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Periodo</mat-label>
              <mat-select [(ngModel)]="intPeriodo" class="" >
               <mat-option *ngFor="let x of arrayPeriodo" [value]="x.strPeriodo">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md  d-flex justify-content-center" >
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" (selectionChange)="fnSucursal()">
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales" [value]="x.nombre">{{x.nombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-2 d-flex justify-content-center align-items-center" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetPresupuestos()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>
    <div >
        <div class="my-3 col-12 d-flex px-2 bg-white justify-content-between align-items-center">
            <mat-form-field appearance="outline" class="bg-white rounded my-2">
              <mat-label>Buscar:</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
            </mat-form-field>
            <span class="d-flex align-items-center">
              <button class="btn btn-success me-3 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal()">
                <i class="bi bi-plus"></i> <span class="fs12">Agregar</span> 
              </button> 
              <span class="fw-bold">
                Total Presupuestos:
              </span>  
              {{arrayPresupuestos.length}}
            </span>
          </div>
      </div>

    <div class="row">
        <div class="col-12 my-2">
            <div *ngIf="spinerArts" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
              </div>
              <div class="alert alert-danger text-center fw-bold my-3" *ngIf="!spinerArts && arrayPresupuestos.length==0" role="alert">
                No se han encontrado datos de presupuestos
              </div>
            <div class="table-responsive mb-0 " [hidden]="arrayPresupuestos.length==0">
                  <div class="mat-elevation-z8">
                    <table class="table w-100 table-bordered" mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="intIDPresupuesto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2"> ID Presupuesto </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> {{row.intIDPresupuesto}} </td>
                          </ng-container>
                        <ng-container matColumnDef="intSucursalCte">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fw-bold fs12 text-primary pointer"> ({{row.intSucursalCte}}) {{row.strNombreSucursal}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strFechaRegistro">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Registro </th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle fs12">
                                {{row.strFechaRegistro}}
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="dblPresupuesto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Presupuesto</th>
                            <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs12"> 
                                <span class="text-nowrap">
                                    {{row.dblPresupuesto|currency}}
                                </span>
                            </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="strAccion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-0"  >Eliminar</th>
                            <td mat-cell *matCellDef="let row" (click)="fnEliminarPresupuesto(row)" class="text-center align-middle fs12 px-0">
                                <button class="btn btn-danger p-1">
                                    <i class="bi bi-x-circle-fill"></i>
                                </button>
                            </td>
                          </ng-container>
                          
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  
                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                      </tr>
                    </table>
                  
                    
                  </div>
            </div>
            <div class="col-12" [hidden]="arrayPresupuestos.length==0">
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title fw-bold mb-0 mx-auto" id="exampleModalLabel">PEDIDO: {{objModal.pedido}}</h2>
          <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close" #btnCerrar></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <mat-form-field appearance="outline" class="bg-white rounded ">
                        <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
                        <mat-select [(ngModel)]="strSucursalModal" class="" >
                          <mat-option value="">--Seleccione sucursal--</mat-option>
                         <mat-option *ngFor="let x of arraySucursales" [value]="x.id">{{x.nombre}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 my-4 d-flex justify-content-center" >
                    <mat-form-field appearance="outline" class="bg-white rounded ">
                        <mat-label>Fecha</mat-label>
                        <input [readonly]="true" [(ngModel)]="strFechaInput" matInput placeholder="Placeholder" type="date">
                      </mat-form-field>
                </div>
                <div class="col-12 d-flex justify-content-center" >
                  <mat-form-field appearance="outline" class="bg-white rounded ">
                      <mat-label>Presupuesto</mat-label>
                      <input [(ngModel)]="dblPresupuesto"  (input)="onInputChange($event)"
                      (keydown)="onKeyDown($event)" matInput placeholder="Placeholder" type="number">
                    </mat-form-field>
              </div>
            </div>
            
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" #btnCerrar class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cerrar</button>
          <button class="btn btn-primary" (click)="fnGuardar()">Guardar</button>
        </div>
      </div>
    </div>
</div>
