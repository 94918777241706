<!--<app-menu></app-menu>-->

<div class="container-fluid">
    <div class="container">
        <div class="row ">
            <div class="mt-2 col-md-12 linea_p">
                <p class="linea"></p>
                <ol class="breadcrumb">
                    <li class="active">
                        <strong>Reporte Scordcard</strong>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <div class="col-4">
        <h4>Seleccione Agente</h4>
        <div class="input-group mb-3">
            <mat-form-field appearance="fill">
                <mat-label>Agente</mat-label>
                <mat-select [(ngModel)]="agenteSelected">
                    <!--(change)="fnFiltrar()"-->
                    <mat-option *ngFor="let item of arrayAgentes" [value]="item">
                        {{item.strLinea}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button style="  background-color: #3e6294;  color: white;height: 50px; margin-left: 5px;" (click)="fnFiltrar()">Filtrar</button>
        </div>
    </div>
    <div class="cien contenido_margen">
        <section class="container2 text-left">
            <div class="row" *ngIf="ListaModulos==undefined || ListaModulos=='' || ListaModulos==[]">
                <div class="col-sm-12 text-center">
                    <div class="bo">
                        <mat-spinner></mat-spinner>
                    </div>
                </div>
            </div>
            <div class="mb30" *ngFor='let M of ListaModulos'>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="">
                            <tr>
                                <th class="text-center fondo_tablas" colspan="14">
                                    <strong style="font-weight: 500;">{{M}}</strong>
                                </th>
                            </tr>
                            <tr class="grisUnidad">
                                <th class="font-bold" style="width:12%;font-weight: 500;">Concepto</th>
                                <th class="font-bold" style="width:8%;font-weight: 500;">Objetivo Ppto</th>
                                <!--   <th class="font-bold" style="width:4%">Objetivo Ppto</th>-->
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Ene</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Feb</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Mar</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Abr</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">May</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Jun</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Jul</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Ago</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Sep</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Oct</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Nov</th>
                                <th class="text-center font-bold" style="width:7%;font-weight: 500;">Dic</th>
                                <!--<th class="text-center font-bold" style="width:7%;font-weight: 500;">Totales</th>-->
                            </tr>
                        </thead>
                        <tbody style="background:#FFF">
                            <tr *ngFor='let C of ListaEstadoResultados | filter : M'>
                                <td class="bordeTable LetraT text-left font-bold fondo_fijas_gris">
                                    <span *ngIf="C.strConcepto!='Días Cartera'">{{C.strConcepto}}</span>
                                    <a *ngIf="C.strConcepto =='Días Cartera' " target="_blank" ng-Click="fnDCartera()">{{C.strConcepto}}</a>
                                </td>

                                <td class="bordeTable LetraT text-center font-bold fondo_fijas_gris2">
                                    <!--<span>{{C.strParametro | number}}</span>-->
                                    <label *ngIf="C.strTipoDato =='$'">{{C.strParametro |currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.strParametro | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.strParametro | number }}</label>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleEnero}}">
                                    <div *ngIf="C.dblEnero!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblEnero |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblEnero | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblEnero | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleEnero=='TextoRojo', 'bi bi-arrow-up':C.strStyleEnero=='TextoVerde'} "></i>
                                        <!--<label>{{C.dblEnero | number }}</label>-->
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleFebrero}}">
                                    <div *ngIf="C.dblFebrero!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblFebrero |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblFebrero | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblFebrero | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleFebrero=='TextoRojo', 'bi bi-arrow-up':C.strStyleFebrero=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleMarzo}}">
                                    <div *ngIf="C.dblMarzo!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblMarzo |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblMarzo | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblMarzo | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleMarzo=='TextoRojo', 'bi bi-arrow-up':C.strStyleMarzo=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleAbril}}">
                                    <div *ngIf="C.dblAbril!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblAbril |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblAbril | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblAbril | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleAbril=='TextoRojo', 'bi bi-arrow-up':C.strStyleAbril=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleMayo}}">
                                    <div *ngIf="C.dblMayo!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblMayo |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblMayo | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblMayo | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleMayo=='TextoRojo', 'bi bi-arrow-up':C.strStyleMayo=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleJunio}}">
                                    <div *ngIf="C.dblJunio!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblJunio |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblJunio | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblJunio | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleJunio=='TextoRojo', 'bi bi-arrow-up':C.strStyleJunio=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleJulio}}">
                                    <div *ngIf="C.dblJulio!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblJulio |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblJulio | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblJulio | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleJulio=='TextoRojo', 'bi bi-arrow-up':C.strStyleJulio=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleAgosto}}">
                                    <div *ngIf="C.dblAgosto!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblAgosto |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblAgosto | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblAgosto | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleAgosto=='TextoRojo', 'bi bi-arrow-up':C.strStyleAgosto=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleSeptiembre}}">
                                    <div *ngIf="C.dblSeptiembre!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblSeptiembre |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblSeptiembre | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblSeptiembre | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleSeptiembre=='TextoRojo', 'bi bi-arrow-up':C.strStyleSeptiembre=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleOctubre}}">
                                    <div *ngIf="C.dblOctubre!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblOctubre |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblOctubre | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblOctubre | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleOctubre=='TextoRojo', 'bi bi-arrow-up':C.strStyleOctubre=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleNoviembre}}">
                                    <div *ngIf="C.dblNoviembre!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblNoviembre |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblNoviembre | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblNoviembre | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleNoviembre=='TextoRojo', 'bi bi-arrow-up':C.strStyleNoviembre=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <td class="bordeTable LetraT text-center fuente {{C.strStyle}} {{C.strStyleDiciembre}}">
                                    <div *ngIf="C.dblDiciembre!=0 ">
                                        <label *ngIf="C.strTipoDato =='$'">{{C.dblDiciembre |currency }}</label>
                                        <label *ngIf="C.strTipoDato =='%'">{{C.dblDiciembre | number }}%</label>
                                        <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblDiciembre | number }}</label>
                                        <i [ngClass]="{'bi bi-arrow-down': C.strStyleDiciembre=='TextoRojo', 'bi bi-arrow-up':C.strStyleDiciembre=='TextoVerde'} "></i>
                                    </div>
                                </td>
                                <!--<td class="bordeTable LetraT text-center fuente {{C.strStyle}} ">                                 
                                    <label *ngIf="C.strTipoDato =='$'">{{C.dblTotal | currency }}</label>
                                    <label *ngIf="C.strTipoDato =='%'">{{C.dblTotal | number }}%</label>
                                    <label *ngIf="C.strTipoDato =='' || C.strTipoDato==null || C.strTipoDato==undefined">{{C.dblTotal | number}}</label>
                                </td>-->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
    <br>
</div>