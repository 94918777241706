<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="text-center my-3 fw-bold nunito">Administración de Cupones</h1>
        </div>
    </div>
    <div class="row ">
        <div class="col-12 mb-4">
            <mat-tab-group >
                <mat-tab>
                  <ng-template mat-tab-label >
                    <mat-icon class="example-tab-icon">list</mat-icon>
                    Listado
                  </ng-template>
                    <div class="col-12 py-3 ps-4">
                        <mat-form-field appearance="outline" class="rounded bg-white">
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
                          </mat-form-field>
                    </div>
                    <div class="col-12 px-0 mb-4">
                      <div class="table-responsive mb-0">
                          <div class="mat-elevation-z8">
                              <table mat-table [dataSource]="dataSource" matSort class="table">

                                <!-- Nombre -->
                                <ng-container matColumnDef="intID_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab ps-0 "> ID Descuento </th>
                                  <td mat-cell *matCellDef="let element" class="ps-0 align-middle text-center"> {{element.intID_Cupon}} </td>
                                </ng-container>
                              
                                <!-- Nombre -->
                                <ng-container matColumnDef="strNombre_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab mx-3"> Nombre </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center mx-3">
                                     <span class="">
                                      {{element.strNombre_Cupon}}
                                     </span> 
                                  </td>
                                </ng-container>
                              
                                <!-- Tipo -->
                                <ng-container matColumnDef="strTipo_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab"> Tipo </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center"> 
                                    <span class="px-2">
                                      {{element.strTipo_Cupon}}
                                    </span>  
                                  </td>
                                </ng-container>
                              
                                <!-- Valor -->
                                <ng-container matColumnDef="dblValor_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab"> Valor </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center">
                                     <span class="d-flex" *ngIf="element.strTipo_Cupon == 'Porcentaje'">
                                    <span>{{element.dblValor_Cupon}}</span> <span>%</span>  
                                     </span>
                                    <span class="d-flex" *ngIf="element.strTipo_Cupon == 'Monto Fijo'">
                                      {{element.dblValor_Cupon|currency}}
                                     </span>
                                    </td>
                                </ng-container>
                              
                                <!-- Monto Mínimo -->
                                <ng-container matColumnDef="dblMontoMin_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab"> Monto Mínimo </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center"> {{element.dblMontoMin_Cupon|currency}} </td>
                                </ng-container>
                              
                                <!-- Cantidad Mínima -->
                                <ng-container matColumnDef="dblCantidadMin_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab"> Cantidad Mínima </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center"> {{element.dblCantidadMin_Cupon}} </td>
                                </ng-container>
                              
                                <!-- Método de Pago -->
                               <!--  <ng-container matColumnDef="strMetodoPago_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab"> Método de Pago </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center"> {{element.strMetodoPago_Cupon}} </td>
                                </ng-container> -->
                              
                                <!-- Código Promocional -->
                                <ng-container matColumnDef="strCodigo_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab text-nowrap"> Código Promocional </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center"> 
                                      <div class="col-12 d-flex justify-content-center py-2" *ngIf="element.strCodigo_Cupon">
                                          <div class="col-11 rounded cupon">
                                              <span class=" fs14 fw-bold nunito">
                                                  {{element.strCodigo_Cupon}}
                                              </span>
                                          </div>
                                      </div>
                                  </td>
                                </ng-container>
                              
                                <!-- Fecha de Inicio -->
                                <ng-container matColumnDef="strFechaInicio_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab text-nowrap"> Fecha de Inicio </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center">
                                      <div class="col-12 d-flex justify-content-center">
                                              <label for="">{{element.strFechaInicio_Cupon}}</label>
                                     </div> 
                                  </td>
                                </ng-container>
                              
                                <!-- Fecha de Fin -->
                                <ng-container matColumnDef="strFechaFin_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab text-nowrap"> Fecha de Fin </th>
                                  <td mat-cell *matCellDef="let element" class="align-middle text-center">
                                     <div class="col-12 d-flex justify-content-center">
                                         <label for="">{{element.strFechaFin_Cupon}}</label>
                                     </div> 
                                  </td>
                                </ng-container>
                              
                                <!-- Activo -->
                                <ng-container matColumnDef="boolActivo_Cupon">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab pe-0"> Activo </th>
                                  <td mat-cell *matCellDef="let element;let i = index"  class="pe-0 align-middle text-center">
                                        <div class="col-12 d-flex justify-content-center">
                                          <span class="p-1 fw-bold fs12 nunito me-2"  [ngClass]="{'text-danger':!element.boolActivo_Cupon,'text-success':element.boolActivo_Cupon}">
                                            <i class="fs-4 fa-solid " [ngClass]="{'fa-circle-check':element.boolActivo_Cupon,'fa-xmark':!element.boolActivo_Cupon}"></i> 
                                          </span>
                                        </div>
                                      </td>
                                </ng-container>
                                <ng-container matColumnDef="accion">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="thTab pe-0"> Accion </th>
                                  <td mat-cell *matCellDef="let element;let i = index"  class="pe-0 align-middle text-center">
                                        <div class="col-12 d-flex justify-content-center">
                                          <button class="btn btnEdit py-0 px-1 shadow-sm" data-bs-toggle="modal" data-bs-target="#modal_editar" (click)="fncbBox(element)">
                                            <i class="bi bi-pencil-square"></i>
                                          </button>
                                        </div>
                                      </td>                  
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                  <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                                </tr>
                              </table>
                            </div>
                      </div>
                      <mat-paginator class="col-12 paginator" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
                  </div>
                </mat-tab>
              
                <mat-tab>
                  <ng-template mat-tab-label >
                    <mat-icon class="example-tab-icon">note_add</mat-icon>
                    Agregar Nuevo
                  </ng-template>
                
                  <div class="col-12 my-4 d-flex justify-content-center">
                    <form [formGroup]="cuponForm" class="col-11" #formDirective="ngForm" (ngSubmit)="onSubmit()">
                      <div class="row justify-content-center">
                        <div class="col-11">
                          <div class="row">

                            <div class="col-12 col-md-6  mb-3 d-flex justify-content-start">
                              <mat-form-field appearance="outline" class="rounded bg-white col-11">
                                <mat-label for="strTipo">Tipo</mat-label>
                                <mat-select id="strTipo" formControlName="strTipo" matInput placeholder="Tipo Cupon">
                                  <mat-option value="">--Seleccione Opcion--</mat-option>
                                  <mat-option value="Porcentaje">Porcentaje</mat-option>
                                  <mat-option value="Monto Fijo" >Monto Fijo</mat-option>
                                </mat-select>
                                <i class="fa-solid fa-list-check fs-5" matSuffix [ngClass]="{'valid-input': cuponForm.get('strTipo').valid,'text-secondary': !cuponForm.get('strTipo').valid}"></i>
                                <mat-error *ngIf="cuponForm.get('strTipo').hasError('required')">
                                  El campo "Tipo" es obligatorio.
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div class="col-12 col-md-6 mb-3 d-flex ">
                              <div class="col-11 d-flex">
                                <div class="col-12 pe-0">
                                  <mat-form-field appearance="outline" class="rounded bg-white w-100">
                                    <mat-label for="strNombre">Nombre</mat-label>
                                    <input id="strNombre" formControlName="strNombre" matInput placeholder="nombre Cupon">
                                    <i class="fa-solid fa-note-sticky fs-5" matSuffix [ngClass]="{'valid-input': cuponForm.get('strNombre').valid,'text-secondary': !cuponForm.get('strNombre').valid}"></i>
                                    <mat-error *ngIf="cuponForm.get('strNombre').hasError('required')">
                                      El campo "Nombre" es obligatorio.
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary px-2" type="button" (click)="fnArrayChange(cuponForm.get('strTipo').value)"  data-bs-toggle="modal" data-bs-target="#exampleModal">
                                  <i class="bi bi-info-circle-fill fs14"></i>
                                </button>
                              </div>
                            </div>
                           
                         

                            <div class="col-12 col-md-6  mb-3 d-flex justify-content-start">
                              <mat-form-field appearance="outline" class="rounded bg-white  col-11">
                                <mat-label for="strValor">Valor</mat-label>
                                <input type="number" class="no-spinner" id="strValor" formControlName="strValor" matInput placeholder="Valor Cupon">
                                <i class="fa-solid fa-hand-holding-dollar fs-5 " matSuffix [ngClass]="{'valid-input': cuponForm.get('strValor').valid,'text-secondary': !cuponForm.get('strValor').valid}" ></i>
                                <mat-error *ngIf="cuponForm.get('strValor').hasError('required')">
                                  El campo "Valor" es obligatorio.
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div class="col-12 col-md-6  mb-3 d-flex justify-content-start">
                              <mat-form-field appearance="outline" class="rounded bg-white  col-11">
                                <mat-label for="dblMontoMinimo">Monto Mínimo</mat-label>
                                <input  [disabled]="cuponForm.get('intCantidadMinima').value!=''" type="number" id="dblMontoMinimo" class="no-spinner" formControlName="dblMontoMinimo" matInput placeholder="Monto Mínimo Cupon">
                                <i class="fa-solid fa-cart-flatbed fs-5" matSuffix [ngClass]="{'valid-input': cuponForm.get('dblMontoMinimo').valid,'text-secondary': !cuponForm.get('dblMontoMinimo').valid}"></i>
                                <mat-error *ngIf="cuponForm.get('dblMontoMinimo').hasError('required')">
                                  El campo "Monto Mínimo" es obligatorio.
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div class="col-12 col-md-6 mb-3 d-flex justify-content-start">
                              <mat-form-field appearance="outline" class="rounded bg-white  col-11">
                                <mat-label for="intCantidadMinima">Cantidad Mínima</mat-label>
                                <input type="number" [disabled]="cuponForm.get('dblMontoMinimo')?.value==''" id="intCantidadMinima" class="no-spinner" formControlName="intCantidadMinima" matInput placeholder="Cantidad Mínima cupon">
                                <i class="fa-solid fa-list-ol fs-5" matSuffix [ngClass]="{'valid-input': cuponForm.get('intCantidadMinima').valid,'text-secondary': !cuponForm.get('intCantidadMinima').valid}"></i>
                                <mat-error *ngIf="cuponForm.get('intCantidadMinima').hasError('required')">
                                  El campo "Cantidad Mínima" es obligatorio.
                                </mat-error>
                              </mat-form-field>
                            </div>

                           <!--  <div class="col-6  mb-3">
                              <mat-form-field appearance="outline" class="rounded bg-white col-10">
                                <mat-label for="strMetodoPago">Método de Pago</mat-label>
                                <input id="strMetodoPago" formControlName="strMetodoPago" matInput placeholder="Método de Pago Cupon">
                                <i class="fa-solid fa-money-check-dollar fs-5" matSuffix [ngClass]="{'valid-input': cuponForm.get('strMetodoPago').valid,'text-secondary': !cuponForm.get('strMetodoPago').valid}"></i>
                                <mat-error *ngIf="cuponForm.get('strMetodoPago').hasError('required')">
                                  El campo "Método de Pago" es obligatorio.
                                </mat-error>
                              </mat-form-field>
                            </div> -->

                            <div class="col-12 col-md-6 mb-3 d-flex justify-content-start">
                              <mat-form-field appearance="outline" class="rounded bg-white  col-11">
                                <mat-label for="strCodigoPromocional">Código Promocional</mat-label>
                                <input [style.text-transform]="'uppercase'"  (input)="toUpperCase($event)" id="strCodigoPromocional" formControlName="strCodigoPromocional" matInput placeholder="Código Promocional Cupon">
                                <i class="fa-solid fa-barcode fs-5" matSuffix [ngClass]="{'valid-input': cuponForm.get('strCodigoPromocional').valid,'text-secondary': !cuponForm.get('strCodigoPromocional').valid}"                    ></i>
                                <mat-error *ngIf="cuponForm.get('strCodigoPromocional').hasError('required')">
                                  El campo "Código Promocional" es obligatorio.
                                </mat-error>
                                <mat-error *ngIf="cuponForm.get('strCodigoPromocional')?.hasError('minlength')">
                                  El código debe tener al menos 3 caracteres.
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div class="col-12 col-md-6 mb-3 d-flex justify-content-start">
                              <mat-form-field appearance="outline" class="rounded bg-white  col-11" (click)="picker4.open()">
                                <mat-label for="strFechaInicio">Fecha Inicio</mat-label>
                                <input id="strFechaInicio" formControlName="strFechaInicio" matInput placeholder="Fecha Inicio Cupon" readonly  [min]="minDate" [matDatepicker]="picker4" matInput class="d-flex align-items-center justify-content-center" >
                         
                                <mat-datepicker-toggle  matSuffix [for]="picker4" [ngClass]="{'valid-input': cuponForm.get('strFechaInicio').valid,'text-secondary': !cuponForm.get('strFechaInicio').valid}"></mat-datepicker-toggle>
                                <mat-datepicker #picker4></mat-datepicker>
                               <!--  <i class="fa-solid fa-calendar-days fs-5" matSuffix [ngClass]="{'valid-input': cuponForm.get('strFechaInicio').valid,'text-secondary': !cuponForm.get('strFechaInicio').valid}"></i> -->
                                <mat-error *ngIf="cuponForm.get('strFechaInicio').hasError('required')">
                                  El campo "Fecha Inicio" es obligatorio.
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div class="col-12 col-md-6  mb-3 d-flex justify-content-start">
                              <mat-form-field appearance="outline" class="rounded bg-white  col-11" (click)="picker3.open()">
                                <mat-label for="strFechaFin">Fecha Fin</mat-label>
                               <!--  <input id="strFechaFin" formControlName="strFechaFin" matInput placeholder="Fecha Fin Cupon"> -->
                            <!--     <i class="fa-regular fa-calendar-days fs-5" matSuffix [ngClass]="{'valid-input': cuponForm.get('strFechaFin').valid,'text-secondary': !cuponForm.get('strFechaFin').valid}"></i> -->
                                <input id="strFechaFin" formControlName="strFechaFin" readonly  [min]="minDate" [matDatepicker]="picker3" matInput class="d-flex align-items-center justify-content-center" >
                                <mat-datepicker-toggle  matSuffix [for]="picker3" [ngClass]="{'valid-input': cuponForm.get('strFechaFin').valid,'text-secondary': !cuponForm.get('strFechaFin').valid}"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>

                                <mat-error *ngIf="cuponForm.get('strFechaFin').hasError('required')">
                                  El campo "Fecha Fin" es obligatorio.
                                </mat-error>
                              </mat-form-field>
                            </div>

                           
                            
                            <div class="col-12">
                              <button type="submit" class="btn btn-primary mt-3" [disabled]="!cuponForm.valid">Guardar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                     </form>
                  </div>
                </mat-tab>
              </mat-tab-group>
        </div>
    </div>
</div>

<div class="modal fade" id="modal_editar" tabindex="-1" aria-labelledby="modal_editarLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-0 fw-bold mx-auto" id="modal_editarLabel">Editar Cupon {{objCupon?.strCodigo_Cupon}}</h2>
        <button type="button" class="ms-0 btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6 mb-3">
            <mat-form-field appearance="outline" class="col-12 custom-date-icon" (click)="picker.open()">
              <mat-label>Fecha Inicio</mat-label>
              <input readonly (dateChange)="onFechaInicioChange($event,'inicio')" [value]="strFechaInicio_Cupon"  [min]="minDate" [matDatepicker]="picker" matInput class="d-flex align-items-center justify-content-center" >
              <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6 mb-3">
            <mat-form-field appearance="outline" class="col-12 custom-date-icon" (click)="picker1.open()">
              <mat-label>Fecha Fin</mat-label>
              <input readonly (dateChange)="onFechaInicioChange($event,'fin')" [value]="strFechaFin_Cupon"  [min]="minDate" [matDatepicker]="picker1" matInput class="d-flex align-items-center justify-content-center" >
              <mat-datepicker-toggle  matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
       <!--    <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>Cantidad Minima</mat-label>
              <input matInput [(ngModel)]="objCupon.dblCantidadMin_Cupon" type="number" [min]="1">
              <i matSuffix class="bi bi-stickies"></i>
            </mat-form-field>
          </div> -->
          <div class="mx-auto col-6 justify-content-center d-flex">
            <div class="col-11  shadow-sm rounded d-flex flex-column">
            <div class="col-10 mx-auto justify-content-center d-flex border-bottom">
              <span>
                ACTIVO
              </span>
            </div>
            <div class="col-12 justify-content-center mt-2 d-flex align-items-center">
              <!-- <input  type="checkbox" [checked]="objCupon?.boolActivo_Cupon" [(ngModel)]="cupCheck" >
              <span class="ms-2 text-secondary">
                {{cupCheck?"SI":"NO"}}
              </span> -->
              <mat-radio-group aria-label="Select an option" color="primary" [(ngModel)]="cupCheck">
                <mat-radio-button [value]="1">SI</mat-radio-button>
                <mat-radio-button class="ms-4" [value]="0">NO</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" #btnCerrarMdl class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary ms-auto" (click)="fnActualizar(objCupon);">Guardar</button>
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title mx-auto mb-0 fw-bold" id="exampleModalLabel">Descuentos {{cuponForm.get("strTipo").value}}</h3>
        <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close" #btnCerrarEdit></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">

            <div class="col-12" *ngIf="errorDesc">
              <div class="alert alert-danger text-center fw-bold" role="alert">
                SELECCIONE UN TIPO DE DESCUENTO PARA MOSTRAR EJEMPLOS
              </div>
            </div>



            <div class="col-12 my-2" *ngFor="let ox of arrayDescrip">
              <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ox.categoria}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngFor="let des  of ox.descuentos" class="bg-light">
                    <div class="col-12">
                      <hr class="my-2">
                    </div>
                    <div class="col-12 d-flex" >
                      <div class="col-2">
                        <button class="btn btn-primary" (click)="fnSetNombre(des)">
                          <i class="bi bi-check-circle-fill"></i>
                        </button>
                      </div>
                      <div class="col-10">
                        <span >
                          {{des}}
                        </span>
                      </div>
                    </div>
                    <div class="col-12">
                      <hr class="my-2">
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>






            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>