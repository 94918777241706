import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alta-regla-negocio',
  templateUrl: './alta-regla-negocio.component.html',
  styleUrls: ['./alta-regla-negocio.component.css']
})
export class AltaReglaNegocioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
