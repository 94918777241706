import { Injectable } from '@angular/core'; 
import { Observable, Subject, Subscription } from 'rxjs';
 
//import {PeticionesService} from '../servicios/httpservice.service'
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
@Injectable({
  providedIn: 'root'
})
export class CarritoNService {
  arrayArticulos: any[] = [];
  arrayArticulosStorage: any[] = [];
  arrayCotizador: any[] = [];
  dblSubTotal: number = 0;
  dblTotal: number = 0;	//total en cantidad
  dblTotalCotizador: number = 0;
  arrayTotalesPrecio: any[] = []; //total en monto del carrito
  arrayTotalesPrecioCotizador: any[] = []; //total en monto del cotizador
  dblIva: number = 0;
  objDescuentos: any;
  private aux: any; 
  private articulos$ = new Subject<any>(); //sirve  para generar el stream de eventos
  private articulosCotizador$ = new Subject<any>(); //total de articulos en el cotizador
  private arrayArticulos$ = new Subject<any>();
  private arrayArticulosCotizador$ = new Subject<any>();
  private totalesPrecio$ = new Subject<any>();
  private totalesPrecioCotizador$ = new Subject<any>();//totales para el cotizador
  private descuentos$ = new Subject<any>();
  private arrayCotizador$ = new Subject<any>(); //observable de cotizaciones
  constructor(private localSt: LocalStorageService) //private _peticionesService:PeticionesService,
  {
    this.dblTotal = 0;
    //this.localSt.store('arrayArticulos', this.arrayArticulos);
    //this.localSt.store('arrayCotizador', this.arrayCotizador);
  }

  setArtToCotizacion(objArticulo: any) { //añadir articulos al array de cotizador
    //Obtener el valor de sesion del array de Cotizador
    this.arrayCotizador = this.localSt.retrieve('arrayCotizador') ? this.localSt.retrieve('arrayCotizador') : [];
    console.log("Articulos de sesion cotizador: ");
    console.log(this.arrayCotizador);

    let strArticulo = '';
    let intCantidad = 0;
    let dblMonto = 0;
    objArticulo.dblPrecioVenta = objArticulo.dblPrecio;
    //revisar que el articulo no se encuentre en el arrayArticulos
    if (objArticulo.strOpcion) {
      this.aux = this.arrayCotizador.find(o => o.strArticulo == objArticulo.strArticulo && o.strOpcion == objArticulo.strOpcion);
    }
    else {
      this.aux = this.arrayCotizador.find(o => o.strArticulo == objArticulo.strArticulo);
    }
    //si no se encuentra se hace push
    if (!this.aux) {
      //revisar si se excede a la cantidad disponible en su sucursal, para hacer cobro de flete
      objArticulo.intExcede = objArticulo.dblCantidad > objArticulo.intExistenciasSucursal ? 1 : 0;
      this.arrayCotizador.push(objArticulo);
      strArticulo = objArticulo.strArticulo;
      intCantidad = objArticulo.dblCantidad;
      dblMonto = objArticulo.dblCantidad * objArticulo.dblPrecio;
    }
    else { //si se encuentra, se suman cantidades
      this.aux.dblCantidad = this.aux.dblCantidad + objArticulo.dblCantidad;
      this.aux.intExcede = this.aux.dblCantidad > objArticulo.intExistenciasSucursal ? 1 : 0;
      strArticulo = this.aux.strArticulo;
      intCantidad = this.aux.dblCantidad;
      dblMonto = this.aux.dblCantidad * this.aux.dblPrecio;
    }
    console.log(this.arrayCotizador)
    this.localSt.store('arrayCotizador', this.arrayCotizador);
    
    this.fnTotalesCotizador();
  }

  setArticulo(objArticulo: any) {
    //Obtener el valor de sesion del array de articulos
    this.arrayArticulos = this.localSt.retrieve('arrayArticulos') ? this.localSt.retrieve('arrayArticulos') : [];
    console.log("Articulos de sesion: ");
    console.log(this.arrayArticulos);

    let strArticulo = '';
    let intCantidad = 0;
    let dblMonto = 0;
    //revisar que el articulo no se encuentre en el arrayArticulos
    if (objArticulo.strOpcion) {
      this.aux = this.arrayArticulos.find(o => o.strArticulo == objArticulo.strArticulo && o.strOpcion == objArticulo.strOpcion);
    }
    else {
      this.aux = this.arrayArticulos.find(o => o.strArticulo == objArticulo.strArticulo);
    }
    //si no se encuentra se hace push
    if (!this.aux) {
      //revisar si se excede a la cantidad disponible en su sucursal, para hacer cobro de flete
      objArticulo.intExcede = objArticulo.dblCantidad > objArticulo.intExistenciasSucursal ? 1 : 0;
      this.arrayArticulos.push(objArticulo);
      strArticulo = objArticulo.strArticulo;
      intCantidad = objArticulo.dblCantidad;
      dblMonto = objArticulo.dblCantidad * objArticulo.dblPrecio;
    }
    else { //si se encuentra, se suman cantidades
      this.aux.dblCantidad = this.aux.dblCantidad + objArticulo.dblCantidad;
      this.aux.intExcede = this.aux.dblCantidad > objArticulo.intExistenciasSucursal ? 1 : 0;
      strArticulo = this.aux.strArticulo;
      intCantidad = this.aux.dblCantidad;
      dblMonto = this.aux.dblCantidad * this.aux.dblPrecio;
    }
    // this.fnGetDescuento(strArticulo,intCantidad,dblMonto,1); 

    this.localSt.store('arrayArticulos', this.arrayArticulos);
    console.log(this.arrayArticulos);
    this.fnTotales();
  }


  fnTotales() {
    this.dblTotal = 0;
    this.arrayTotalesPrecio[0] = 0;
    this.arrayTotalesPrecio[1] = 0;
    this.arrayTotalesPrecio[2] = 0;
    this.arrayTotalesPrecio[3] = 0; //determinará si se cobra o no flete
    let flete = 0;
    for (var i = 0; i < this.arrayArticulos.length; i++) {
      this.dblTotal = this.dblTotal + this.arrayArticulos[i].dblCantidad;
      let precio = this.arrayArticulos[i].dblPrecioSugerido > 0 ? this.arrayArticulos[i].dblPrecioSugerido : this.arrayArticulos[i].dblPrecio;
      this.arrayTotalesPrecio[0] = this.arrayTotalesPrecio[0] + (this.arrayArticulos[i].dblCantidad * precio);
      this.arrayTotalesPrecio[1] = this.arrayTotalesPrecio[0] * 0.16;
      this.arrayTotalesPrecio[2] = this.arrayTotalesPrecio[0] + this.arrayTotalesPrecio[1];
      flete = flete + this.arrayArticulos[i].intExcede;
    }
    this.arrayTotalesPrecio[3] = 0; //flete>0?400:0;
    this.arrayTotalesPrecio[2] = this.arrayTotalesPrecio[3] + this.arrayTotalesPrecio[2];
    this.arrayTotalesPrecio[2] = this.arrayTotalesPrecio[2].toFixed(2);
    this.totalesPrecio$.next(this.arrayTotalesPrecio);
    this.articulos$.next(this.dblTotal);//se genera un evento sobre la variable dblTotal
    this.arrayArticulos$.next(this.arrayArticulos); //se genera evento sobre arrayArticulos


    //Guardar en el storage los totales
    this.localSt.store('arrayTotalesPrecio', this.arrayTotalesPrecio);
    this.localSt.store('dblTotal', this.dblTotal);
    this.localSt.store('arrayArticulos', this.arrayArticulos);

  }

  fnTotalesCotizador() {
    console.log("totales cotizadorrrrrrr");
    this.dblTotalCotizador = 0;
    this.arrayTotalesPrecioCotizador[0] = 0;
    this.arrayTotalesPrecioCotizador[1] = 0;
    this.arrayTotalesPrecioCotizador[2] = 0;
    this.arrayTotalesPrecioCotizador[3] = 0; //determinará si se cobra o no flete
    let flete = 0;
    for (var i = 0; i < this.arrayCotizador.length; i++) {
      this.dblTotalCotizador = this.dblTotalCotizador + this.arrayCotizador[i].dblCantidad;
      let precio = this.arrayCotizador[i].dblPrecioSugerido > 0 ? this.arrayCotizador[i].dblPrecioSugerido : this.arrayCotizador[i].dblPrecioVenta;
      this.arrayTotalesPrecioCotizador[0] = this.arrayTotalesPrecioCotizador[0] + (this.arrayCotizador[i].dblCantidad * precio);
      this.arrayTotalesPrecioCotizador[1] = this.arrayTotalesPrecioCotizador[0] * 0.16;
      this.arrayTotalesPrecioCotizador[2] = this.arrayTotalesPrecioCotizador[0] + this.arrayTotalesPrecioCotizador[1];
      flete = flete + this.arrayCotizador[i].intExcede;
    }
    this.arrayTotalesPrecioCotizador[3] = 0; //flete>0?400:0;
    this.arrayTotalesPrecioCotizador[2] = this.arrayTotalesPrecioCotizador[3] + this.arrayTotalesPrecioCotizador[2];
    this.arrayTotalesPrecioCotizador[2] = this.arrayTotalesPrecioCotizador[2].toFixed(2);
    this.totalesPrecioCotizador$.next(this.arrayTotalesPrecioCotizador);
    this.articulosCotizador$.next(this.dblTotalCotizador);//se genera un evento sobre la variable dblTotalCotizador
    this.arrayArticulosCotizador$.next(this.arrayCotizador); //se genera evento sobre arrayCotizador

    //Guardar en el storage los totales del cotizador
    this.localSt.store('arrayTotalesPrecioCotizador', this.arrayTotalesPrecioCotizador);
    this.localSt.store('dblTotalCotizador', this.dblTotalCotizador);
    this.localSt.store('arrayCotizador', this.arrayCotizador);
  }



  getTotal() {
    this.dblTotal = this.localSt.retrieve('dblTotal');
    return this.dblTotal;
  }
  getTotalCotizador() {
    this.dblTotalCotizador = this.localSt.retrieve('dblTotalCotizador');
    return this.dblTotalCotizador;
  }

  getTotalArticulos$(): Observable<any> {
    return this.articulos$.asObservable(); //se crea el observable sobre el subject,	
  }
  getTotalArrayArticulos$(): Observable<any> {
    return this.arrayArticulos$.asObservable(); //se crea el observable sobre el subject,	
  }

  getTotalArticulosCotizador$(): Observable<any>{
     return this.articulosCotizador$.asObservable(); //se crea el observable sobre el subject,  
  }

  getArrayArticulosCotizador$(): Observable<any>{
    return this.arrayArticulosCotizador$.asObservable(); //se crea el observable sobre el subject,  
  }


}
