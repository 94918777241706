<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="my-3 fw-bold  text-decoration-underline nunito">VENTA <span class="titulo">CONSIGNA</span></h1>
        </div>
    </div>
    <div class="row bg-white bordertop borderbott shadow pb-4" *ngIf="boolAgente||boolGerente||boolSucursal">
        <div class="col-12 bgtable py-1 mb-3 d-flex bordertop">
            <span class="material-symbols-outlined">
                content_paste_search
            </span>
            <span class="fw-bold">
                Filtros
            </span> 
        </div>
        <div class="col-12 mt-2 pt-1 col-md-3  d-flex justify-content-center" *ngIf="boolGerente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Gerente</mat-label>
              <mat-select [(ngModel)]="strGerente" class="" >
                <mat-option   value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayGerentes" [value]="x.strGerente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-3  d-flex justify-content-center" [ngClass]="{'col-xl-3':boolGerente,'col-lg-5':boolSucursal && !boolGerente}" *ngIf="boolSucursal">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Sucursal</mat-label>
              <mat-select [(ngModel)]="strSucursal" class="" >
                <mat-option value=""> --Todas --</mat-option> 
               <mat-option *ngFor="let x of arraySucursales | sucursalFilter:strGerente " [value]="x.strSucursal">{{x.strNombreSucursal}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1 col-md-4  d-flex justify-content-center" [ngClass]="{'col-xl-4':boolGerente||boolSucursal}" *ngIf="boolAgente">
            <mat-form-field appearance="outline" class="bg-white rounded w-100">
              <mat-label class="fw-bold py-0 my-0"><mat-icon>date_range</mat-icon>Agente</mat-label>
              <mat-select [(ngModel)]="strAgente" class="" >
                <mat-option value=""> --Todos --</mat-option> 
               <mat-option *ngFor="let x of arrayAgentes |agenteFilter:strGerente:strSucursal" [value]="x.strAgente">{{x.strNombre}}</mat-option> 
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 mt-2 pt-1  col-md-2  d-flex justify-content-center align-items-center" [ngClass]="{'col-xl-2':boolGerente ||boolSucursal||boolAgente  }" >
            <button class="btn btnFilter shadow-sm px-2 py-1" (click)="fnGetVentasConsina()" (mouseenter)="isHoveredButton = true" (mouseleave)="isHoveredButton = false">
                <i class="bi  p-1 fs-4"  [ngClass]="{'bi-filter': !isHoveredButton, 'bi-filter-circle-fill': isHoveredButton}"></i>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12  justify-content-center mt-3" [ngClass]="{'d-flex':spinnerVtaConsigna}" [hidden]="  !spinnerVtaConsigna">
            <mat-spinner></mat-spinner>
        </div>
        <div class="col-12 my-3 px-0 shadow bordertop borderbott clsIcon" [hidden]="spinnerVtaConsigna">
            <div class="col-12 ">
                <mat-form-field appearance="outline" class="my-2 ms-3 rounded bg-white text-dark">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilterVentasConsigna($event)" placeholder="ID,Tarea,etc..." #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
          
            </div>

            <div class="mb-0 table-responsive ">
                <div class="mat-elevation-z8 mb-0  shadow">
                    <table mat-table [dataSource]="datosTareasCte" class="table table-hover table-bordered"  matSort #sortTareasCte="matSort">
                   

                      <!-- cliente Column -->
                      <ng-container matColumnDef="cliente">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ps-0 fs13 nunito fw-bold clsIcon"> CLIENTE </th>
                        <td mat-cell *matCellDef="let row" class="mostDet fw-bold ps-0 fs13 text-start align-middle"> 
                          <span class="ms-2 btn btn-link p-0 fs13 text-decoration-none" (click)="desgloceConsignacion(row)">
                            <span class="fw-bold ">({{row.strCliente}})</span>
                            {{row.strNombre}}
                          </span>  
                        </td>
                      </ng-container>

                      <!-- fecha Column -->
                      <ng-container matColumnDef="fecha">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs13 nunito fw-bold clsIcon"> FECHA </th>
                        <td mat-cell *matCellDef="let row" class="fs11 text-center align-middle fw-bold"> 
                          <span class="mx-2">{{row.strFechaRequerida}} </span>  
                        </td>
                      </ng-container>

                      <!-- importe Column -->
                      <ng-container matColumnDef="importe">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="fs13 nunito fw-bold clsIcon"> IMPORTE </th>
                        <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-end align-middle">
                         <span class="me-2">
                            {{row.dblImporte|currency}}
                         </span>  
                        </td>
                      </ng-container>

                        <!-- diasinstalacion Column -->
                        <ng-container matColumnDef="diasinstalacion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  class="fs13 nunito fw-bold clsIcon"> DIAS DESDE INSTALACION </th>
                            <td mat-cell *matCellDef="let row;let ind=index" class="fs12 text-center align-middle">
                             <span class="text-white rounded fs13 fw-bold py-1 px-2" [ngClass]="{'bg-success':row.strDiasDif >= -365,'bg-danger':row.strDiasDif < -365}">
                                {{row.strDiasDif|number:'1.0-0'}}
                             </span>  
                            </td>
                          </ng-container>
                
                      <tr mat-header-row class="rounded" class="sticky-top" *matHeaderRowDef="columnsTareasCte"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnsTareasCte;" ></tr>
                  
                     
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="6">
                            <div class="col-12 mt-3">
                                <div class="alert alert-danger text-center fw-bold" role="alert">
                                    No se encontraron datos de: "{{input.value}}"
                                </div>
                            </div>
                           </td>
                      </tr>
                    </table>
                  </div>
            </div>
            <mat-paginator class="paginator borderbott" [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users" #paginadorTareasCte></mat-paginator>
        </div>
    </div>

    
</div>




