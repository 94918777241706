import { Component, OnInit,ElementRef,ViewChild  } from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import { AlertifyService } from '../alertify.service';
import { LocalStorage, LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { FiltrosService } from '../servicios/filtros.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataDBService } from '../servicios/data-db.service';
import Swal from 'sweetalert2';
import { MatSort, Sort } from '@angular/material/sort';

import { CarroService } from '../servicios/carroservice';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface data {
  imagen: string;
  descripcion: string;
  precio: string;
  accion: string;
}

@Component({
  selector: 'app-cliente-precios-especiales',
  templateUrl: './cliente-precios-especiales.component.html',
  styleUrls: ['./cliente-precios-especiales.component.css']
})
export class ClientePreciosEspecialesComponent implements OnInit {
  intEjercicio:number=0;
  intPeriodo:number; 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['imagen','descripcion','precio','accion'];
  dataSource: MatTableDataSource<data>;
  public strConexion:string="";
  public arrayAccesos:any=[];
  public arrayArts:any=[];
  
  public arrayExistentes: any = [];
  public spinerArts:boolean = false;
  public strAgente:string="";
  public activeProduct:boolean=false;
  public strAgente_Crea: string;
  public strCliente:string="";
  public strFamilia:string="";
  public strUsuario: string;
  
  public spinerCarrito: boolean = false;
  public objArt: any = {};
  public intPrecioPolitica: number = 0;
  public imagenUrl1: string;
  public imagenUrl2: string;
  public imagenUrl3: string;
  public imagenUrl4: string;
  public imagenUrl5: string;
  public arrayArtsPromocion:any=[];
  public arrayArtsConvenio:any=[];
  private unsubscribe$ = new Subject<void>();
  public boolEditaAgente: boolean = true;
  public objCliente:any ={};
  public tagName:any;
  constructor(private carroservice: CarroService,private indexedDBService: DataDBService,private customPaginatorIntl: MatPaginatorIntl, 
          elem: ElementRef, private _httpService: PeticionesService, public _alertify: AlertifyService,
          private localSt: LocalStorageService, private router: Router, private _filtroServ: FiltrosService) {
        this.customPaginatorIntl.itemsPerPageLabel = 'Registros por página:';
        let fecha = new Date();
        this.intEjercicio = fecha.getFullYear();
        this.intPeriodo = fecha.getMonth() + 1;
        this.strConexion = this.localSt.retrieve('strConexion');
        this.tagName = elem.nativeElement.tagName.toLowerCase();
   }

  ngOnInit(): void {
    this.indexedDBService.getItem('arrayResult', (value) => {
      this.arrayAccesos = value ? value : [];
      if (this.arrayAccesos.length > 0) {
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente == this.tagName);
        if(objAcceso[0].strComponente == this.tagName){
          this.objCliente = this.arrayAccesos[0]; 
          this.strFamilia = this.objCliente.strFamiliaCte;
          this.strCliente = this.objCliente.strCliente;
          this.strConexion = this.localSt.retrieve('strConexion');
          this.strAgente_Crea = this.localSt.retrieve('strAgente');
          this.strAgente = this.localSt.retrieve('strAgente');
          this.strUsuario = this.localSt.retrieve('strUsuario');
          this.boolEditaAgente = objAcceso.find((o: any) => o.strAccion == 'btnCambioAgente') ? false : true;
          this.indexedDBService.getItem('strConexion', (value) => {
            this.strConexion = value;
          });
          this.getArtsPromocion();
        }else{
          let urlHome = this.arrayAccesos[0].strUrlHome;
          this.router.navigate(['/'+urlHome]);
        }
      }
      else {
        this.router.navigate(['/login']);
      }
    });
  }

  fntabla(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



    getArtsPromocion() {
      let obj = {
        strAccion: 'GetArticulos_politica_CTE',
        strCliente: this.strCliente,
        strConexion: this.strConexion
      }
      this._httpService.mostrarCotizaciones(obj, 'sp_ModVentas_Leads_Familia').subscribe(
          result => {
            this.arrayArts = result;
            console.log(result)
            let arrayInsert = []
            this.arrayArts.forEach(art => {
              if(art.strTipo == "PLANTILLA" ){
                this.arrayArtsConvenio.push({
                  imagen:"https://www.cleanclean.mx/imagenes/"+art.strArticulo+".JPG",
                  descripcion:art.strDescripcion,
                  precio:art.dblPrecio,
                  accion:"",
                  objArt:art
                })
              }else if(art.strTipo == "POLITICA"){
                this.arrayArtsPromocion.push({
                  imagen:"https://www.cleanclean.mx/imagenes/"+art.strArticulo+".JPG",
                  descripcion:art.strDescripcion,
                  precio:art.dblPrecio,
                  accion:"",
                  objArt:art
                })
              }
            });
            console.log(arrayInsert);
            this.dataSource = new MatTableDataSource(this.arrayArtsPromocion);
            this.fntabla();
          }, error => {
            var error = <any>error; console.log(error);
          });
    }



    fnSelectArts(tipo:string){
      if(tipo=="PLANTILLA"){
        this.activeProduct = true;
        this.dataSource = new MatTableDataSource(this.arrayArtsConvenio);
        this.fntabla();
      }else{
        this.activeProduct = false;
        this.dataSource = new MatTableDataSource(this.arrayArtsPromocion);
        this.fntabla();
      }
    }
    fnAbrirModal(art: any) {
      let articulo = art.objArt
      console.log(articulo)
      console.log(this.objCliente)
      this.objCliente.strDireccion = this.objCliente.strDireccion;
      this.objCliente.strNombre = this.objCliente.strNombre;
      this.objCliente.strCliente = this.objCliente.strCliente;
      this.objCliente.strNombreSucursal = this.objCliente.strNombreSucursal;
      this.objCliente.intIDSucursal = this.objCliente.intIDSucursal;
      this.objCliente.strAgente = this.objCliente.strAgente;
      this.objCliente.ClienteSeleccionado = true;
      this.objCliente.strTipo = this.objCliente.strTipo;
      this.objCliente.strAlmacen = this.objCliente.strAlmacen;
      this.objCliente.strClaveUsoCFDI = this.objCliente.strClaveUsoCFDI;
      this.objCliente.strCondicion = this.objCliente.strCondicion;
      this.objCliente.strDescripcionUsoCFDI = this.objCliente.strDescripcionUsoCFDI;
      this.objCliente.strInfopago = this.objCliente.strInfopago;
      this.objCliente.strInfopagoDescripcion = this.objCliente.strInfopagoDescripcion;
      this.objCliente.strSucursal = this.objCliente.strSucursal;
      console.log(this.objCliente);
      //  this.fnBuscarCte_Carrito(this.strCliente);
  
      //obtener toda la informacion del articulo
      this.intPrecioPolitica = articulo.dblPrecio;
      this.objArt = articulo;
      this.objArt.dblCantidad = 1;
      this.objArt.dblPrecioLista = articulo.dblPrecio;
      if (this.objArt.strTipoOpcion == 'Si'){ 
        this.fnExtraerOpciones(articulo) 
      }
      let url = 'https://www.cleanclean.mx/imagenes/'
      /*  console.log('abre modal'); */
      this.imagenUrl1 = url + this.objArt.strArticulo + '.JPG';
      this.imagenUrl2 = url + this.objArt.strArticulo + '-PO-2.JPG';
      this.imagenUrl3 = url + this.objArt.strArticulo + '-PO-3.JPG';
      this.imagenUrl4 = url + this.objArt.strArticulo + '-PO-4.JPG';
      this.imagenUrl5 = url + this.objArt.strArticulo + '-PO-5.JPG';
    }
    onImageError(event: any): void {
      event.target.src = 'https://www.cleanclean.mx/imagenes/NO-DISPONIBLE.JPG';
    }

      fnExtraerOpciones(objArt: any) {
        let articulo = {
          strAccion: 'getOpcion',
          strArticuloBusqueda: objArt.strArticulo,
          strConexion: this.strConexion
        };
        this._httpService.getCotizador(articulo)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              this.objArt.arrayOpciones = result;
              this.objArt.strNombreOpcion = this.objArt.arrayOpciones[0].strNombreOpcion ? this.objArt.arrayOpciones[0].strNombreOpcion : '';
              this.objArt.strOpcion = this.objArt.arrayOpciones[0].strOpcion ? this.objArt.arrayOpciones[0].strOpcion : '';
    
            });
      }
      onOpcionSeleccionada(opcion: string, objArt: any) {
        this.objArt.arrayOpciones.forEach(o => {
          if (opcion == o.strOpcion) {
            this.objArt.strNombreOpcion = o.strNombreOpcion
          }
        });
        this.fntraerExistencias(objArt);
      }
      fnAdd_Rest_Amount(objArt: any, tipo: string) {
        if (tipo == 'add') {
          objArt.dblCantidad = objArt.dblCantidad + 1;
        } else {
          objArt.dblCantidad = objArt.dblCantidad - 1;
        }
      }
      fnAddToCarr(objArticulo: any) { //recibe la cantidad seleccionada en el input, y toda la información del artículo seleccionado
        console.log(objArticulo)
        objArticulo.dblCantidad = parseFloat(objArticulo.dblCantidad);
        objArticulo.strComentario = "";
        this.spinerCarrito = true;
        const deepCopy = JSON.parse(JSON.stringify(objArticulo));
        /*   console.log(deepCopy); */
        this.carroservice.setArticulo(deepCopy);
        setTimeout(() => {
          this.spinerCarrito = false;
          this._alertify.success("Articulo agregado correctamente");
        }, 400);
      }
      fntraerExistencias(object: any) {
    
        let opcion = "";
        if (object.strTipoOpcion == "Si") {
          opcion = object.strOpcion;
        }
        let articulo = {
          strArticulo: object.strArticulo,
          strSubcuenta: opcion,
          strConexion: this.strConexion,
          strAccion: 'getExistencias'
        };
    
        this._httpService.getListaPrecios(articulo)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              this.arrayExistentes = result;
    
            });
      }
    
}
