import { Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { PeticionesService } from '../servicios/httpservice';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
  

  @Component({
    selector: 'app-scordcardgs',
    templateUrl: './scordcardgs.component.html',
    styleUrls: ['./scordcardgs.component.css']
  })

  export class ScordcardgsComponent implements OnInit {
    public arrayAccesos:any;
    public boolAutoriza:any;
    public intEstatusSelected?:number;
    public strFechaActual:any;
    public intPeriodo:string;
    public intEjercicio:string;
    public tagName:any;
    public ListaModulos: any;
    public ListaEstadoResultados: any;
    public agenteSelected:any={};
    public arrayAgentes:any=[];
    public strSucursal:string;
    constructor(elem: ElementRef, private _httpService: PeticionesService, private localSt:LocalStorageService, private router: Router) {
      this.tagName = elem.nativeElement.tagName.toLowerCase(); //obtenemos el nombre del componente actual
      this.arrayAccesos = {};
      this.strFechaActual="";
      this.intEjercicio="";
      this.intPeriodo="";
      this.ListaModulos=[];
      this.ListaEstadoResultados=[];
  
    }
  
    ngOnInit(): void {
      //Validar si tiene acceso a la pantalla
      this.arrayAccesos = this.localSt.retrieve('arrayAccesos')?this.localSt.retrieve('arrayAccesos'):{};
      
      if(this.arrayAccesos.length>0){
        var objAcceso = this.arrayAccesos.filter((o: any) => o.strComponente==this.tagName);
        this.strSucursal= this.arrayAccesos[0].sucursal;
      }
  
      /*if (objAcceso.length>0) {
        //Permisos a los que tiene accesoe este perfil:     
        this.boolAutoriza = objAcceso.find((o: any) => o.strComponente==this.tagName && o.strAccion=='Autorizar') ?true:false;
  
        this.fnGetResultados();
      }else {
        //this.router.navigate(['/login']);
  
        console.log("no tiene acceso a esta pantalla");
      }*/
      this.fnGetAgentes();
      this.fnGetResultados('');      
    }
  
    fnGetAgentes(){
      this._httpService.getApiCompras({
        strAccion: 'getAgentes' ,
        strSucursal:this.strSucursal 
      }).subscribe(
      result => {  
        this.arrayAgentes=result;
        console.log(this.arrayAgentes);
      });  
    }
    fnFiltrar(){
      /*.fnGetObjAgt();
      this.fnGetTotalObjetivos();
      this.fnGetTotalEstatus();
      this.fnGetHistAgt();*/
      this.fnGetResultados(this.agenteSelected.strEstatus   )
    }
    fnGetResultados(agt){
      this.ListaModulos=[];
      /*this._httpService.getApiComprasScordCardReportes({
        strAccion: 'getScordCardJefe', */
        let objPraram ={
          strAccion: 'ScordCard', 
          strJefe:agt &&agt!=''?'': '493',//si hay un agente seleccionado, mandar jefe vacio
          strAgente:agt &&agt!=''?agt:''
        }
        if (agt  &&agt!='') {
          
          this._httpService.getApiComprasScordCardVendedor(objPraram).subscribe(
            result => {
              this.ListaEstadoResultados=result;
              console.log(this.ListaEstadoResultados);

              this.ListaEstadoResultados.forEach((element:any) => {
                let AuxModulo = this.ListaModulos.filter((b:any) => b == element.strModulo);
                if (AuxModulo.length == 0) {
                  this.ListaModulos.push(element.strModulo);
                }
                element.dblTotal = 0;
                let enero=element.dblEnero?parseFloat(element.dblEnero):0;
                let febrero = element.dblFebrero?parseFloat(element.dblFebrero):0;
                let marzo = element.dblMarzo?parseFloat(element.dblMarzo):0;  
                let abril = element.dblAbril?parseFloat(element.dblAbril):0;
                let mayo = element.dblMayo?parseFloat(element.dblMayo):0;
                let junio = element.dblJunio?parseFloat(element.dblJunio):0;  
                let julio = element.dblJulio?parseFloat(element.dblJulio):0;
                let agosto = element.dblAgosto?parseFloat(element.dblAgosto):0;
                let septiembre = element.dblSeptiembre?parseFloat(element.dblSeptiembre):0;  
                let octubre = element.dblOctubre?parseFloat(element.dblOctubre):0;
                let noviembre = element.dblNoviembre?parseFloat(element.dblNoviembre):0;
                let diciembre = element.dblDiciembre?parseFloat(element.dblDiciembre):0;
                element.dblTotal =enero+febrero+marzo+abril+mayo+junio+julio+agosto+septiembre+octubre+noviembre+diciembre;
              });
          
      
              console.log(this.ListaModulos);
            },
            error => {
              var error = <any>error;
              console.log(error);
            }
          );
        }
        else {

       
            this._httpService.getApiComprasScordCardJefe(objPraram).subscribe(
              result => {
                this.ListaEstadoResultados=result;
                console.log(this.ListaEstadoResultados);

                this.ListaEstadoResultados.forEach((element:any) => {
                  let AuxModulo = this.ListaModulos.filter((b:any) => b == element.strModulo);
                  if (AuxModulo.length == 0) {
                    this.ListaModulos.push(element.strModulo);
                  }
                  element.dblTotal = 0;
                  let enero=element.dblEnero?parseFloat(element.dblEnero):0;
                  let febrero = element.dblFebrero?parseFloat(element.dblFebrero):0;
                  let marzo = element.dblMarzo?parseFloat(element.dblMarzo):0;  
                  let abril = element.dblAbril?parseFloat(element.dblAbril):0;
                  let mayo = element.dblMayo?parseFloat(element.dblMayo):0;
                  let junio = element.dblJunio?parseFloat(element.dblJunio):0;  
                  let julio = element.dblJulio?parseFloat(element.dblJulio):0;
                  let agosto = element.dblAgosto?parseFloat(element.dblAgosto):0;
                  let septiembre = element.dblSeptiembre?parseFloat(element.dblSeptiembre):0;  
                  let octubre = element.dblOctubre?parseFloat(element.dblOctubre):0;
                  let noviembre = element.dblNoviembre?parseFloat(element.dblNoviembre):0;
                  let diciembre = element.dblDiciembre?parseFloat(element.dblDiciembre):0;
                  element.dblTotal =enero+febrero+marzo+abril+mayo+junio+julio+agosto+septiembre+octubre+noviembre+diciembre;
                });
            
        
                console.log(this.ListaModulos);
              },
              error => {
                var error = <any>error;
                console.log(error);
              }
            );
    } 
    }
  
  
  
  }
  

