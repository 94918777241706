<div class="container-fluid bgBlue"> <!-- grid-container-->
  <mat-grid-list cols="4" [rowHeight]="isAnchoMayorQue1400px() ? '93px' : '80px'">
    <mat-grid-tile *ngFor="let card of cards | async" [colspan]="card.cols" [rowspan]="card.rows">
      <mat-card class="dashboard-card" [ngClass]="{'back1': card.id==1 ,'back2':card.id==4 }">
        <mat-card-header *ngIf="card.id!=4">
          <mat-card-title>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">

          <!--SECCION CLIENTES-->
          <div *ngIf="card.id==1" class="row p-2">
            <div class="col-12 mb-2 mb-md-4 p-2">
              <h3 class="titulos">Información del Cliente</h3>
            </div>
            <!-- //////////////////////////////////////////////////NumeroCliente/////////////////////// -->
            <div class="col-12 p-2 col-sm-6 col-md-4 p-2">
              <mat-form-field class="col-12 " appearance="outline">
                <mat-label>Cliente</mat-label>
                <input matInput placeholder="Placeholder" id="form1" [(ngModel)]="strNumCte"
                  placeholder="Ingrese número de cliente" (keydown)="onKeyDown($event)"
                  (keyup)="fnBuscarCte(strNumCte);" aria-label="Recipient's username" aria-describedby="button-addon2"
                  autocomplete="off" matInput [matAutocomplete]="auto">
                <span matSuffix class="material-symbols-outlined fs-3 clicon"
                  [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                  check_circle
                </span>
              </mat-form-field>
              <mat-autocomplete #auto="matAutocomplete" class="w-100">
                <mat-option class="fs12 hoption" *ngFor="let option of arrayClientes; let i = index;"
                  [value]="option.strCliente" (click)="fnllenarInputs(i);">
                  {{option.strNombreMostrar}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <!--///////////////////////////////////////////NOmbreCliente//////////////////////////// -->
            <div class="col-12 py-md-2 col-sm-6 mx-auto col-md-4">
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Ejemplo: Ecodeli Restauradores" class="border-0"
                  [ngModel]="objCliente.strNombre" readonly>
                <span matSuffix class="material-symbols-outlined fs-3 clicon"
                  [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                  check_circle
                </span>
              </mat-form-field>
            </div>
            <!--///////////////////////////////////////////DireccionCliente//////////////////////////// -->
            <div class="col-12 py-2 py-sm-0 py-md-2 col-sm-6 col-md-4 ">
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>Direccion</mat-label>
                <input class="border-0" matInput placeholder="Ejemplo: Ecodeli, Leon, Gto."
                  [ngModel]="objCliente.strDireccion" readonly>
                <span matSuffix class="material-symbols-outlined fs-3 clicon"
                  [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                  check_circle
                </span>
              </mat-form-field>
            </div>
            <!--///////////////////////////////////////////DireccionCliente//////////////////////////// -->
            <div class="row px-0 mx-auto mt-3" *ngIf="objCliente.strTipo == 'SUCURSAL'">
              <div class="col-12 py-2 py-sm-0 py-md-2 col-sm-6 col-md-4">
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>Sucursal</mat-label>
                  <input class="border-0" matInput placeholder="Placeholder" [ngModel]="objCliente.intIDSucursal"
                    readonly>
                  <span matSuffix class="material-symbols-outlined fs-3 clicon"
                    [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                    check_circle
                  </span>
                </mat-form-field>
              </div>
              <!--///////////////////////////////////////////DireccionCliente//////////////////////////// -->
              <div class="col-12 py-2 py-sm-0 py-md-2 col-sm-6 col-md-4 ">
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>Sucursal</mat-label>
                  <input class="border-0" matInput placeholder="Placeholder" [ngModel]="objCliente.strNombreSucursal"
                    readonly>
                  <span matSuffix class="material-symbols-outlined fs-3 clicon"
                    [ngClass]="{'clicon':objCliente.ClienteSeleccionado,'cliconInfo':!objCliente.ClienteSeleccionado}">
                    check_circle
                  </span>
                </mat-form-field>
              </div>
              <div class="col-12 py-2 py-sm-0 py-md-2 col-sm-6 col-md-3"></div>
            </div>
          </div>
          <!--FIN --SECCION --CLIENTES-->
          <!--SECCION CATEGORIAS-->
          <div *ngIf="card.id == 8" class="row">
            <div class="col-12 ">
              <h3 class="titulos">Categorias</h3>
            </div>
            <div class="col-12 d-flex bglineas ">
              <div id="carouselExampleControls" class="carousel slide " data-bs-ride="carousel"><!-- m-auto -->
                <div class="carousel-inner d-flex">
                  <div class="carousel-item  mt-1" [ngClass]="{'active':i==0}" *ngFor="let bloq of bloques; let i = index;">
                    <div class="row justify-content-evenly ">
                      <div class="mt-4 text-center  px-0 d-flex flex-column  roundedcrdbottom" [ngClass]="{'imgitemcar':x!=objLinea,'imgselect':x==objLinea,'col2':elementosPorPagina == 6, 'col-2': elementosPorPagina == 5,'col3': elementosPorPagina == 4,'col-3': elementosPorPagina == 3,'col-5': elementosPorPagina == 2}"  *ngFor="let x of bloq" (click)="fnGetGrupos(x)">
                       
                        <img  class="w-100 imgitemcar"  [src]="'https://www.ecodeli.com/img/lineas/'+x.strCategoria+'.jpg'" alt="">
                        <span class="text-secondary p-1 fw-bold fs4 categoriass h-100 d-flex align-items-center justify-content-center roundedcrdbottom ">{{x.strCategoria}}</span>
                      </div>
                     <!--  <div class="col" *ngIf="bloq.length < 6 && elementosPorPagina == 6">
                      </div>
                      <div class="col"  *ngIf="bloq.length < 5 && elementosPorPagina == 5">
                      </div>
                      <div class="col"  *ngIf="bloq.length < 4  && elementosPorPagina == 4">
                      </div>
                      <div class="col"  *ngIf="bloq.length < 3  && elementosPorPagina == 3">
                      </div>
                      <div class="col"  *ngIf="bloq.length < 2  && elementosPorPagina == 2">
                      </div> -->
                    </div>
                  </div>
                </div>
                <button class="carousel-control-prev clsIconCarousel" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon " aria-hidden="true"></span>
                  <span class="visually-hidden text-secondary">Previous</span>
                </button>
                <button class="carousel-control-next clsIconCarousel" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden ">Next</span>
                </button>
              </div>
            </div>
          </div>
          <!--FIN CATEGORIAS-->
          <!--SECCION FILTROS-->
          <div *ngIf="card.id==5" class="p-0">
            <div class="col-12 " style=" margin-bottom: 2%; background: #c7daff;">
              <div class="col-12 d-flex position relative pb-2">
                <h3 class="titulos text-start ps-2">Filtrar por:</h3>
                <div class="btn-group position-absolute top-0 end-0 mt-1 me-1 d-flex rounded-pill btnCar px-1" role="group" aria-label="Basic example">
                  <span mat-icon-button  type="button" (click)="redirectToCarrito();" class="btn   position-relative  p-0 d-flex">
                    <span class="material-symbols-outlined fs16">
                      shopping_cart
                    </span>
                  </span>
                  <span mat-icon-button type="button" [matMenuTriggerFor]="menu" class="btn btn-sm  p-0 mx-0 d-flex" aria-label="Example icon-button with a menu">
                    <span class="material-symbols-outlined fs16">
                      expand_more
                    </span>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill badgCar">
                      {{intTotalItems}}
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </span>
                </div>
              </div>
              
              <mat-form-field  class="example-full-width w-100 p-2 pt-3">
                <span matPrefix> </span>
                <input type="text" class="px-3" matInput placeholder="Buscar Por Articulo" (keyup)="funConsultarArt(strBuscarArticulo);" [matAutocomplete]="BusArt" name="search" [(ngModel)]="strBuscarArticulo">
                <button matSuffix class="btn btn-darkky d-flex align-items-center "
                  (click)="funArticulos(strBuscarArticulo);">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
              <mat-autocomplete #BusArt="matAutocomplete" class="w-100">
                <mat-option (click)="funArticulos(strBuscarArticulo);" class="fs12 hoption d-flex align-items-center"
                  *ngFor="let articulo of arrayBusquedaArticulo; let i = index;" [value]="articulo.strArticulo">
                  {{articulo.strArticulo}} {{articulo.strDescripcion1}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div >
           
              <mat-menu #menu="matMenu" class="pttop">
                <div readonly class=" pt-0 col-12 text-center px-0 carritoCmp">
                  <div class="row px-0 mx-0">
                    <div class="row px-0 mx-0 sticky-top bg-white ">
                      <div class="col-12 px-0 my-2">
                        <span class="h6 ">Sub total</span>
                      </div>
                      <div class="col-12 p-0 m-0 d-flex justify-content-between align-items-center mb-2">
                        <div class="col-7">
                          <span class=" badgeCarrito p-2 mb-3 mt-0 fw-bold shadow-sm">{{dblSubTotal|currency}} <span
                              *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                              *ngIf="strConexion=='DOMINICANA'">DOP</span> </span>
                        </div>
                        <div class="col">
                          <button (click)="redirectToCarrito();"
                            class="btn shadow-sm d-flex align-items-center float-end me-2 rounded-pill border fs12">Ir
                            al carrito</button>
                        </div>
                      </div>
                      <div class="col-12 p-0 m-0 d-flex justify-content-center">
                        <div class="col-11">
                          <hr class="mt-1 mb-0">
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 bg-light px-0 mtArt{{i}}" *ngFor="let car of arrayCarritoArts;index as i "
                      (click)="$event.stopPropagation()">
                      <div class="col-6">
                        <img [src]="getImageUrl(car.strArticulo)" (error)="onImageError($event)" class="img-fluid"
                          alt="">
                      </div>
                      <div class="col-6 ">
                        <div class="row ">
                          <div class="px-1"
                            [ngClass]="{'col-5':car.strTipoOpcion=='Si','col-12':car.strTipoOpcion=='No'}">
                            <span class="fs12">{{car.strArticulo}}</span>
                          </div>
                          <div class="col-7 px-1 text-center" *ngIf="car.strTipoOpcion=='Si'">
                            <span class="fs12 "><b>Opc:</b> {{car.strNombreOpcion}}</span>
                          </div>
                          <div class="col-12 text-center">
                            <span class="fs13 fw-bold">{{car.dblPrecioLista|currency}}</span>
                          </div>
                        </div>
                        <div class="row d-flex align-items-center mt-3">
                          <div class="col-7 px-0">
                            <input [(ngModel)]="car.dblCantidad" (click)="fnUpdateAmountCar(car)" class="form-control"
                              type="number">
                          </div>
                          <div class="col-4 d-flex">
                            <button class="btn btn-danger p-0 m-0 d-flex align-items-center rounded-circle"
                              (click)="fnDeleteCar(i, car);$event.stopPropagation();">
                              <span class="material-symbols-outlined p-1">
                                delete
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0 m-0 d-flex justify-content-center">
                        <div class="col-11">
                          <hr class="my-2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
            <div class="row categ"><br>
              <b class="titulo1 mt-0">Grupos</b>
              <span class="fw-bold text-secondary" *ngIf="arrayGrupos == 0">Selecciona una Categoria</span>
              <ul class="list-group mt-3" style="max-height: 490px; overflow: auto;">
                <!--style="max-height: 100%; overflow: auto;"-->
                <!-- <li class="list-group-item inMarca align-self-start" *ngFor="let item of arrayCategoria;"><a
                    style="font-size: 13px;" (click)="funllenarArtiPorCategoria(item.strCategoria);">
                    {{item.strCategoria}} </a></li> -->
                <li class="list-group-item inMarca align-self-start" *ngFor="let item of arrayGrupos;"><a
                    style="font-size: 13px;"
                    (click)="fnSelectGroup(item.strGrupo);"><!--(click)="funllenarArtiPorCategoria(item.strCategoria);"-->
                    {{item.strGrupo}} </a></li>
              </ul>
            </div>
            <!-- <hr> -->
            <!-- <div class="row marca">
              <b class="titulo1">Marca</b>
              <div class="list-group" *ngFor="let item of arrayMarca; ">
                <label class="list-group-item inMarca" style="font-size: 14px;">
                  <input class="form-check-input me-1" [(ngModel)]="item.checkbx" type="checkbox"
                    (change)="funllenarPorMarca();" value="">
                  {{item.strLinea}}
                </label>
              </div>
            </div> -->
          </div>
          <!--FIN---SECCION FILTROS-->

          <!--SECCION BUSCAR- CARRITO-->
          <div *ngIf="card.id==6" class="row ">
            <div class="" style="width: 60%; position: absolute;   top: 10px;">
              <mat-form-field class="example-full-width w-100 ">
                <span matPrefix> </span>
                <input type="text" class="px-3" matInput placeholder="Buscar Por Articulo"
                  (keyup)="funConsultarArt(strBuscarArticulo);" [matAutocomplete]="BusArt" name="search"
                  [(ngModel)]="strBuscarArticulo">
                <button matSuffix class="btn btn-darkky d-flex align-items-center "
                  (click)="funArticulos(strBuscarArticulo);">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
              <mat-autocomplete #BusArt="matAutocomplete" class="w-100">
                <mat-option (click)="funArticulos(strBuscarArticulo);" class="fs12 hoption d-flex align-items-center"
                  *ngFor="let articulo of arrayBusquedaArticulo; let i = index;" [value]="articulo.strArticulo">
                  {{articulo.strArticulo}} {{articulo.strDescripcion1}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="v-line">
            </div>
            <div style="width: 40%;   position: absolute;   top: 3px;left: 65%; font-size: 25px;">
              <mat-form-field class="example-full-width w-100 ">
                <span matPrefix> </span>
                <input type="text" class="px-3" matInput placeholder="Buscar Por Articulo"
                  (keyup)="funConsultarArt(strBuscarArticulo);" [matAutocomplete]="BusArt" name="search"
                  [(ngModel)]="strBuscarArticulo">
                <button matSuffix class="btn btn-darkky d-flex align-items-center "
                  (click)="funArticulos(strBuscarArticulo);">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
              <mat-autocomplete #BusArt="matAutocomplete" class="w-100">
                <mat-option (click)="funArticulos(strBuscarArticulo);" class="fs12 hoption d-flex align-items-center"
                  *ngFor="let articulo of arrayBusquedaArticulo; let i = index;" [value]="articulo.strArticulo">
                  {{articulo.strArticulo}} {{articulo.strDescripcion1}}
                </mat-option>
              </mat-autocomplete>
              <div class="btn-group" role="group" aria-label="Basic example">
                <button mat-icon-button type="button" (click)="redirectToCarrito();"
                  class="btn btn-primary  position-relative btnCar">
                  <span class="material-symbols-outlined">
                    shopping_cart
                  </span>
                </button>
                <button mat-icon-button type="button" [matMenuTriggerFor]="menu" class="btn  btnCar px-0 mx-0"
                  aria-label="Example icon-button with a menu">
                  <span class="material-symbols-outlined">
                    expand_more
                  </span>
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill badgCar">
                    {{intTotalItems}}
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </button>
              </div>
              <mat-menu #menu="matMenu" class="pttop">
                <div readonly class=" pt-0 col-12 text-center px-0 carritoCmp">
                  <div class="row px-0 mx-0">
                    <div class="row px-0 mx-0 sticky-top bg-white ">
                      <div class="col-12 px-0 my-2">
                        <span class="h6 ">Sub total</span>
                      </div>
                      <div class="col-12 p-0 m-0 d-flex justify-content-between align-items-center mb-2">
                        <div class="col-7">
                          <span class=" badgeCarrito p-2 mb-3 mt-0 fw-bold shadow-sm">{{dblSubTotal|currency}} <span
                              *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                              *ngIf="strConexion=='DOMINICANA'">DOP</span> </span>
                        </div>
                        <div class="col">
                          <button (click)="redirectToCarrito();"
                            class="btn shadow-sm d-flex align-items-center float-end me-2 rounded-pill border fs12">Ir
                            al carrito</button>
                        </div>
                      </div>
                      <div class="col-12 p-0 m-0 d-flex justify-content-center">
                        <div class="col-11">
                          <hr class="mt-1 mb-0">
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 bg-light px-0 mtArt{{i}}" *ngFor="let car of arrayCarritoArts;index as i "
                      (click)="$event.stopPropagation()">
                      <div class="col-6">
                        <img [src]="getImageUrl(car.strArticulo)" (error)="onImageError($event)" class="img-fluid"
                          alt="">
                      </div>
                      <div class="col-6 ">
                        <div class="row ">
                          <div class="px-1"
                            [ngClass]="{'col-5':car.strTipoOpcion=='Si','col-12':car.strTipoOpcion=='No'}">
                            <span class="fs12">{{car.strArticulo}}</span>
                          </div>
                          <div class="col-7 px-1 text-center" *ngIf="car.strTipoOpcion=='Si'">
                            <span class="fs12 "><b>Opc:</b> {{car.strNombreOpcion}}</span>
                          </div>
                          <div class="col-12 text-center">
                            <span class="fs13 fw-bold">{{car.dblPrecioLista|currency}}</span>
                          </div>
                        </div>
                        <div class="row d-flex align-items-center mt-3">
                          <div class="col-7 px-0">
                            <input [(ngModel)]="car.dblCantidad" (click)="fnUpdateAmountCar(car)" class="form-control"
                              type="number">
                          </div>
                          <div class="col-4 d-flex">
                            <button class="btn btn-danger p-0 m-0 d-flex align-items-center rounded-circle"
                              (click)="fnDeleteCar(i, car);$event.stopPropagation();">
                              <span class="material-symbols-outlined p-1">
                                delete
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0 m-0 d-flex justify-content-center">
                        <div class="col-11">
                          <hr class="my-2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div>
          <!--FIN --- SECCION BUSCAR- CARRITO-->

          <!--SECCION RESULTADOS-->
          <div *ngIf="card.id==4" class="row bodyCuerp">

            <div [hidden]="arrayFilter.length >= 1" class="col-12 w-100 h-50 bg-white">
              <img src="assets/carrito.jpg" class="w-25 h-25" alt="">
            </div>
            <div *ngFor="let item of arrayFilter " [hidden]="item.pagina!=currentPage" class="col-4 card1 mb-3">
              <a class="">
                <div class="card position-relative" id="contenedor">
                  <img [src]="getImageUrl(item.strArticulo)" (error)="onImageError($event)" class="img1 zoom" alt="...">
                  <div class="card-body" id="contenedor2">
                    <h5 class="card-title">{{item.strArticulo}}</h5>
                    <p class="card-text">{{item.strDescripcion1}}</p>
                    <p class="precio">{{item.dblPrecioLista|currency}}</p>
                    <a id="boton" class="btn btn-primary mostrar" data-bs-toggle="modal" data-bs-target="#exampleModal"
                      (click)="fnAbrirModal(item)">Agregar</a>
                    <span data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="fnAbrirModal(item)"
                      class="material-symbols-outlined position-absolute bottom-0 end-0 fs-3 me-3 mb-3 textcar d-lg-none">
                      add_shopping_cart
                    </span>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-12 " [ngClass]="{'px-0 mt-3':this.arrayFilter.length<1}">
              <mat-paginator [length]="this.arrayFilter.length" [pageSize]="items" aria-label="Select page"
                (page)="fnPagin($event.pageIndex, $event.previousPageIndex)">
              </mat-paginator>
            </div>
          </div>
          <!--FIN SECCION RESULTADOS-->

          <!--SECCION--FILTROS-->
          <div *ngIf="card.id==3" >
            <div class="row justify-content-center" >
              <div class="col-10">
                <mat-form-field class="example-full-width w-100">
                  <span matPrefix> </span>
                  <input type="text" class="px-3" matInput placeholder="Buscar Por Articulo"
                    (keyup)="funConsultarArt(strBuscarArticulo);" [matAutocomplete]="BusArt" name="search"
                    [(ngModel)]="strBuscarArticulo">
                  <button matSuffix mat-button
                    (click)="funArticulos(strBuscarArticulo);"><mat-icon>search</mat-icon></button>
                </mat-form-field>
                <mat-autocomplete #BusArt="matAutocomplete" class="w-100">
                  <mat-option (click)="funArticulos(strBuscarArticulo);" class="fs12 hoption d-flex align-items-center"
                    *ngFor="let articulo of arrayBusquedaArticulo; let i = index;" [value]="articulo.strArticulo">
                    {{articulo.strArticulo}} {{articulo.strDescripcion1}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <button class="btn  btn-primary  rounded-circle d-flex align-items-center p-1 shadow mx-auto"
                  type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop"
                  matTooltip="Opciones de filtrado" aria-controls="offcanvasTop">
                  <span class="material-symbols-outlined" style="font-size: 30px;">
                    filter_list
                  </span>
                </button>
                <div class="offcanvas offcanvas-bottom " tabindex="-1" id="offcanvasTop"
                  aria-labelledby="offcanvasTopLabel">
                  <div class="offcanvas-header">
                    <div class="row" style="background: #c7daff;">
                      <h5 id="offcanvasTopLabel" class="titulos">Filtar por: <button type="button"
                          class="btn-close text-reset btn-outline-primary" data-bs-dismiss="offcanvas"
                          aria-label="Close"></button>
                      </h5>
                    </div>

                  </div>
                  <div class="offcanvas-body large">
                    <div class="row categ he12 p-0">
                      <b class="">Grupos</b>
                      <span class="fw-bold text-secondary" *ngIf="arrayGrupos == 0">Selecciona una Categoria</span>
                      <ul class="list-group mt-3" >
                        <li class="list-group-item inMarca align-self-start" *ngFor="let item of grupos | keyvalue;"><a style="font-size: 13px;" (click)="fnSelectGroup(item.key);"><!--(click)="funllenarArtiPorCategoria(item.strCategoria);"--> {{item.key}} </a></li>
                      </ul>
                    </div>




                  <!--   <div class="row categ">

                      <b class="titulo1">Categoría</b>
                      <ul class="list-group" style="max-height: 100px; overflow: auto;">
                        <li class="list-group-item  inMarca" *ngFor="let item of arrayCategoria;"><a
                            style="font-size: 13px;"
                            (click)="funllenarArtiPorCategoria(item.strCategoria);">{{item.strCategoria}}</a></li>
                      </ul>
                    </div> -->
                    <hr>
                    <div class="row marca">
                      <div class="col-12">
                        <mat-form-field class="example-full-width w-100">
                          <span matPrefix> </span>
                          <input type="text" class="px-3" matInput placeholder="Buscar Por Articulo"
                            (keyup)="funConsultarArt(strBuscarArticulo);" [matAutocomplete]="BusArt" name="search"
                            [(ngModel)]="strBuscarArticulo">
                          <button matSuffix mat-button
                            (click)="funArticulos(strBuscarArticulo);"><mat-icon>search</mat-icon></button>
                        </mat-form-field>
                        <mat-autocomplete #BusArt="matAutocomplete" class="w-100">
                          <mat-option (click)="funArticulos(strBuscarArticulo);"
                            class="fs12 hoption d-flex align-items-center"
                            *ngFor="let articulo of arrayBusquedaArticulo; let i = index;"
                            [value]="articulo.strArticulo">
                            {{articulo.strArticulo}} {{articulo.strDescripcion1}}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                      <!-- <b class="titulo1">Marca</b>
                      <div class="list-group" *ngFor="let item of arrayMarca; ">
                        <label class="list-group-item" style="font-size: 14px;">
                          <input class="form-check-input me-1" type="checkbox" value="item.check">
                          {{item.strLinea}}
                        </label>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div >
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button mat-icon-button type="button" (click)="redirectToCarrito();"
                      class="btn btn-primary  position-relative btnCar">
                      <span class="material-symbols-outlined">
                        shopping_cart
                      </span>
                    </button>
                    <button mat-icon-button type="button" [matMenuTriggerFor]="menu" class="btn  btnCar px-0 mx-0"
                      aria-label="Example icon-button with a menu">
                      <span class="material-symbols-outlined">
                        expand_more
                      </span>
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill badgCar">
                        {{intTotalItems}}
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    </button>
                  </div>
                  <mat-menu #menu="matMenu" class="pttop">
                    <div readonly class=" pt-0 col-12 text-center px-0 carritoCmp">
                      <div class="row px-0 mx-0">
                        <div class="row px-0 mx-0 sticky-top bg-white ">
                          <div class="col-12 px-0 my-2">
                            <span class="h6 ">Sub total</span>
                          </div>
                          <div class="col-12 p-0 m-0 d-flex justify-content-between align-items-center mb-2">
                            <div class="col-7">
                              <span class=" badgeCarrito p-2 mb-3 mt-0 fw-bold shadow-sm">{{dblSubTotal|currency}} <span
                                  *ngIf="strConexion=='COMERCIAL'">MXN</span><span
                                  *ngIf="strConexion=='DOMINICANA'">DOP</span> </span>
                            </div>
                            <div class="col">
                              <button (click)="redirectToCarrito();"
                                class="btn shadow-sm d-flex align-items-center float-end me-2 rounded-pill border fs12">Ir
                                al carrito</button>
                            </div>
                          </div>
                          <div class="col-12 p-0 m-0 d-flex justify-content-center">
                            <div class="col-11">
                              <hr class="mt-1 mb-0">
                            </div>
                          </div>
                        </div>
                        <div class="row mx-0 bg-light px-0 mtArt{{i}}" *ngFor="let car of arrayCarritoArts;index as i "
                          (click)="$event.stopPropagation()">
                          <div class="col-6">
                            <img [src]="getImageUrl(car.strArticulo)" (error)="onImageError($event)" class="img-fluid"
                              alt="">
                          </div>
                          <div class="col-6 ">
                            <div class="row ">
                              <div class="px-1"
                                [ngClass]="{'col-5':car.strTipoOpcion=='Si','col-12':car.strTipoOpcion=='No'}">
                                <span class="fs12">{{car.strArticulo}}</span>
                              </div>
                              <div class="col-7 px-1 text-center" *ngIf="car.strTipoOpcion=='Si'">
                                <span class="fs12 "><b>Opc:</b> {{car.strNombreOpcion}}</span>
                              </div>
                              <div class="col-12 text-center">
                                <span class="fs13 fw-bold">{{car.dblPrecioLista|currency}}</span>
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mt-3">
                              <div class="col-7 px-0">
                                <input [(ngModel)]="car.dblCantidad" (click)="fnUpdateAmountCar(car)"
                                  class="form-control" type="number">
                              </div>
                              <div class="col-4 d-flex">
                                <button class="btn btn-danger p-0 m-0 d-flex align-items-center rounded-circle"
                                  (click)="fnDeleteCar(i, car);$event.stopPropagation();">
                                  <span class="material-symbols-outlined p-1">
                                    delete
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 p-0 m-0 d-flex justify-content-center">
                            <div class="col-11">
                              <hr class="my-2">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <!--FIN --SECCION--FILTROS-->
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  <!-- Vertically centered scrollable modal -->
  <!-- Modal -->
  <div class="modal fade bd-example-modal-lg h-modal modalheigth" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-lg   ">
      <div class="modal-content h-100 ">
        <div style="background:#c7daff;color:#001b52;" class=" modal-header d-flex position-relative">
          <h1 class="modal-title position-absolute top-50 start-50 translate-middle fw-bold" id="exampleModalLabel">
            Detalle De Artículo</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body ">
          <div class="row align-items-start">
            <div class="col-12 col-lg-6">
              <h3 class="d-inline-block d-sm-none">{{objArt.strDescripcion1}}</h3>
              <div id="carouselExampleIndicators" class="col-12 h-75 carousel slide d-flex align-items-center"
                data-bs-touch="false" data-bs-interval="false">
                <div class="carousel-inner imgCar ">
                  <div class="carousel-item active text-center ">
                    <img [src]="imagenUrl1" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                  </div>
                  <div class="carousel-item text-center">
                    <img [src]="imagenUrl2" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                  </div>
                  <div class="carousel-item text-center">
                    <img [src]="imagenUrl3" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                  </div>
                  <div class="carousel-item text-center">
                    <img [src]="imagenUrl4" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                  </div>
                  <div class="carousel-item text-center">
                    <img [src]="imagenUrl5" (error)="onImageError($event)" class="zoom1" alt="Product Image">
                  </div>
                </div>
                <button class="carousel-control-prev bg-transparent" style="color: black !important;" type="button"
                  data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="material-symbols-outlined">
                    arrow_back_ios
                  </span>
                </button>
                <button class="carousel-control-next bg-transparent" style="color: black !important;" type="button"
                  data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="material-symbols-outlined">
                    arrow_forward_ios
                  </span>
                </button>
              </div>
              <div class="col-12 product-image-thumbs mt-0">
                <div class="product-image-thumb active col" aria-current="true" aria-label="Slide 1"
                  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0">
                  <img [src]="imagenUrl1" (error)="onImageError($event)" alt="Product Image">
                </div>
                <div class="product-image-thumb col" *ngIf="imagenUrl2!='False'" aria-current="true"
                  aria-label="Slide 2" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1">
                  <img [src]="imagenUrl2" (error)="onImageError($event)" alt="Product Image">
                </div>
                <div class="product-image-thumb col" *ngIf="imagenUrl3!='False'" aria-current="true"
                  aria-label="Slide 3" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2">
                  <img [src]="imagenUrl3" (error)="onImageError($event)" alt="Product Image">
                </div>
                <div class="product-image-thumb col" *ngIf="imagenUrl4!='False'" aria-current="true"
                  aria-label="Slide 4" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3">
                  <img [src]="imagenUrl4" (error)="onImageError($event)" alt="Product Image">
                </div>
                <div class="product-image-thumb col" *ngIf="imagenUrl5!='False'" aria-current="true"
                  aria-label="Slide 5" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4">
                  <img [src]="imagenUrl5" (error)="onImageError($event)" alt="Product Image">
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="row">
                <div class="col-9">
                  <h2 class="my-3 fw-bolder">{{objArt.strArticulo}}</h2>
                  <p>{{objArt.strDescripcion1}}</p>
                </div>
                <div class="col-3">
                  <div class="row">
                    <div class="col-12 rounded text-center border shadow-sm flex flex-column pb-1 px-0">
                      <div class="col-12 bgcard rounded-top">
                        <span class="tficha text-white">Ficha Tecnica:</span>
                      </div>
                      <a href="https://ecodeli.com/catalogos/FichasTecnicas/{{objArt.strArticulo}}.pdf" target="_blank">
                        <span class="material-symbols-outlined">
                          download_for_offline
                        </span>
                      </a>
                    </div>
                  </div>
                  <div class="row mt-2 ">
                    <div class="col-12 border rounded text-center shadow-sm d-flex flex-column pb-1 px-0">
                      <div class="col-12 bgcard rounded-top">
                        <span class="tficha text-white">Hoja Seguridad:</span>
                      </div>
                      <a href="https://ecodeli.com/catalogos/HojasSeguridad/{{objArt.strArticulo}}.pdf" target="_blank">
                        <span class="material-symbols-outlined">
                          download_for_offline
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr>
                </div>
              </div>
              <div class="row d-flex align-items-center justify-content-center">
                <div class="col-12 col-md-6" [hidden]="objArt.strTipoOpcion == 'No'">
                  <div class="col-12">
                    <h3 class="fw-bolder mb-1">
                      Opcion
                    </h3>
                  </div>
                  <div class="col-12">
                    <select class="form-select  shadow-sm" [(ngModel)]="objArt.strOpcion"
                      aria-label=".form-select-lg example" (change)="onOpcionSeleccionada(objArt.strOpcion,objArt)">
                      <option *ngFor="let item of objArt.arrayOpciones; let i = index;" [ngValue]="item.strOpcion">
                        <span class="me-2">
                          <b> {{item.strOpcion}}</b>
                        </span>
                        &nbsp;
                        &nbsp;
                        <span>
                          {{item.strNombreOpcion}}
                        </span>
                      </option>
                    </select>
                  </div>

                </div>
                <div class=""
                  [ngClass]="{'col-12 col-md-6':objArt.strTipoOpcion != 'No','col-10':objArt.strTipoOpcion == 'No'}">
                  <div class="ms-auto col-10">
                    <h3 class="fw-bolder mb-1">
                      Precio
                    </h3>
                  </div>
                  <div class="col-12">
                    <select [(ngModel)]="objArt.dblPrecioLista" class="form-select fs-8"
                      aria-label="Default select example">
                      <option *ngIf="intPrecioPolitica>0" value="{{intPrecioPolitica}}">PP
                        {{intPrecioPolitica|currency}}</option>
                      <option *ngIf="intPrecioPlantilla>0" value="{{intPrecioPlantilla}}">PLA
                        {{intPrecioPlantilla|currency}}</option>
                      <option *ngIf="intPrecioLista>0" value="{{intPrecioLista}}">PL {{intPrecioLista|currency}}
                      </option>
                      <option *ngIf="intPrecio>0" value="{{intPrecio}}">P1 {{intPrecio|currency}}</option>
                      <option *ngIf="intPrecio2>0" value="{{intPrecio2}}">P2 {{intPrecio2|currency}}</option>
                      <option *ngIf="intPrecio3>0" value="{{intPrecio3}}">P3 {{intPrecio3|currency}}</option>
                      <option *ngIf="intPrecio4>0" value="{{intPrecio4}}">P4 {{intPrecio4|currency}}</option>
                    </select>
                    <!--  <label for="" class="fw-bolder fs-4 w-100 text-dark text-center dblprecio">
                   {{ objArt.dblPrecioLista|currency}}
                  </label> -->
                  </div>
                </div>
              </div>
              <div class="row mt-4 d-flex align-items-center">
                <div class="col-12 col-sm-6">
                  <div class="col-10 mx-auto">
                    <div class="input-group rounded-pill">
                      <button class="btn  btnCirs" (click)="fnAdd_Rest_Amount(objArt,'rest');" type="button">-</button>
                      <input type="text" value="0" [(ngModel)]="objArt.dblCantidad" class="form-control text-center"
                        placeholder="" aria-label="Recipient's username with two button addons">
                      <button class="btn  btnCire" (click)="fnAdd_Rest_Amount(objArt,'add');" type="button">+</button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 ">
                  <div class="col-10 mx-auto">
                    <button [disabled]="spinerCarrito || !objArt.dblPrecioLista || objArt.dblPrecioLista < 1"
                      (click)="fnAddToCarr(objArt);" 
                      class="w-100 position-relative btn btnCar py-2 fs13 rounded-pill d-flex align-items-center justify-content-center shadow-sm">
                      <span *ngIf="!spinerCarrito">Agregar al carrito</span>
                      <span style="color:#FFEF92" *ngIf="spinerCarrito">.</span>
                      <div class="pt-1 position-absolute top-50 start-50 translate-middle" *ngIf="spinerCarrito">
                        <div class="spinner-border text-primary " role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="accordion accordion-flush " id="accordionFlushExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header mb-0" id="flush-headingOne">
                        <button (click)="fntraerExistencias(objArt);"
                          class="fw-bold acordeon accordion-button collapsed border rounded px-3 py-1 fs12"
                          type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                          aria-expanded="false" aria-controls="flush-collapseOne">
                          Existencias
                        </button>
                      </h2>
                      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body pt-2 pb-0 bgbodyacordeon">
                          <div class="row">
                            <div class="col-12 table-responsive mx-0 px-0 mb-2 border rounded">
                              <table class="table table-bordered bg-white fixed-header-table">
                                <thead>
                                  <tr class="">

                                    <th class="text-center  text-white">Almacen</th>
                                    <th class="text-center  text-white">Nombre</th>
                                    <th class="text-center text-white">Disponibles</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let x of arrayExistentes; let ind = index">

                                    <td>{{x.strAlmacen}}</td>
                                    <td>{{x.strAlmacenNombre}}</td>
                                    <td><b>{{x.dblDisponible}}</b></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>