<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="px-0 ">
                <ol class="breadcrumb d-flex align-items-center mb-0">
                    <li class="breadcrumb-item"><a (click)="fnRedirige()">{{strNombre_ruta}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Detalle Cliente</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-3 py-1 my-1 d-flex flex-column justify-content-between">
            <div class="text-center d-flex bg-white shadow-sm justify-content-center flex-grow-1">
                <div class="text-center d-flex align-items-center justify-content-center w-100">
                    <img *ngIf="strDBRutaLogo == ''" src="assets/detoportunidad/noDisponible.jpg"
                        alt="Logotipo del Lead" class="m-auto" style="object-fit: cover; border-radius: 15px;"
                        width="150px">
                    <img *ngIf="strRutaLogo != ''" [src]="strRutaLogo" alt="Logotipo del Lead" class="m-auto"
                        style="object-fit: cover; border-radius: 15px;" width="150px">
                    <img *ngIf="strDBRutaLogo != '' && strRutaLogo == ''"
                        src="https://www.ecodeli.com/{{strDBRutaLogo}}" alt="Logotipo del Lead" width="150px"
                        style="object-fit: cover; border-radius: 15px;" class="m-auto">
                </div>
            </div>
            <div class="col-12 d-flex bg-white align-items-center mb-2 mt-2">
                <label class="btn btn-outline-secondary btn-sm btn-file text-sm w-25 m-auto">
                    <i class="fas fa-cloud-upload-alt mx-1 fs-5"></i>
                    <input (change)="cambiarImgLogo($event)" #fileLogo type="file" style="display: none;" required
                        accept="application/pdf,image/*">
                </label>
                <button (click)="fnAnexoMov()" class="btn btn-primary w-50 m-auto btn-sm" *ngIf="strRutaLogo != ''">
                    <span class="material-symbols-outlined mt-1 mx-1 fs-6">save</span> Guardar
                </button>
            </div>
        </div>

        <div class="col-12 col-md-9">
            <div class="row py-3 my-2 fs10 justify-content-center bg-white shadow-sm">
                <div class="col-11">
                    <div class="row ">
                        <div class="col-2 borderleft  py-2  border-end-0 clsTerminado cardis">
                            <div class="col-12 text-center ">Por Contactar</div>
                            <div class="col- text-center fw-bold">{{objLinea &&
                                objLinea.intPOR_CONTACTAR?objLinea.intPOR_CONTACTAR:0 }}</div>
                        </div>

                        <div class="col-2  py-2 border-start-0 border-end-0 clsTerminado2 cardisTerminado2">
                            <div class="col-12 text-center ">Presentación</div>
                            <div class="col text-center fw-bold">{{objLinea &&
                                objLinea.intPRESENTACION?objLinea.intPRESENTACION:0}}</div>
                        </div>
                        <div class="col-2  py-2 border-start-0 border-end-0 clsTerminado cardis">
                            <div class="col-12 text-center ">Cotización/Recorrido</div>
                            <div class="col text-center fw-bold">{{objLinea &&
                                objLinea.intRECORRIDO?objLinea.intRECORRIDO:0}}</div>
                        </div>
                        <div class="col-2  py-2  border-start-0 bornd-0 clsTerminado2 cardisTerminado2">
                            <div class="col-12 text-center ">Propuesta Económica</div>
                            <div class="col text-center fw-bold">{{objLinea &&
                                objLinea.intPROPUESTA?objLinea.intPROPUESTA:0 }}</div>
                        </div>
                        <div class="col-2 py-2 border-start-0 border-end-0 clsTerminado cardis">
                            <div class="col-12 text-center ">Negociación</div>
                            <div class="col text-center">{{objLinea &&
                                objLinea.intNEGOCIACION?objLinea.intNEGOCIACION:0}}</div>
                        </div>
                        <div
                            class="col-2    py-2  border-start-0 border-end-0 position-relative clsTerminado2 cardisTerminado2">
                            <div class="col-12 text-center ">Cierre</div>
                            <div class="col text-center fw-bold">{{((objLinea &&
                                objLinea.intGANADOS?objLinea.intGANADOS:0)+(objLinea &&
                                objLinea.intPERDIDOS?objLinea.intPERDIDOS:0))}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row py-2 my-2  justify-content-between">
                <div class="col-3x shadow-sm py-3 rounded bg-white d-flex align-items-center justify-content-center">
                    <div class="d-flex flex-column">

                        <span class="fs14 mb-3"><i class="bi bi-calendar2-plus text-primary"></i> Actividades</span>
                        <span class="fw-bold text-primary mx-auto fs30">{{objIndicadores?.intTotal_Actividades}}</span>
                    </div>

                </div>
                <div class="col-3x shadow-sm rounded bg-white d-flex align-items-center justify-content-center">
                    <div class="d-flex flex-column">
                        <span class="fs14 mb-3"><i class="bi bi-bookmark-plus text-success"></i> Sueños</span>
                        <span class="fw-bold text-success mx-auto fs30"> <span
                                class="fw-bold text-success mx-auto fs30">{{objIndicadores?.intTotal_Oportunidades}}</span></span>
                    </div>
                </div>
                <div class="col-3x  shadow-sm rounded bg-white d-flex align-items-center justify-content-center">
                    <div class="d-flex flex-column">

                        <span class="fs14 mb-3"><i class="bi bi-card-list text-danger"></i>
                            Leads</span>
                        <span class="fw-bold text-danger mx-auto fs30">{{objIndicadores?.intTotal_Leads}}</span>
                    </div>
                </div>

                <div class="col-3xx py-2 shadow-sm rounded bg-white d-flex align-items-center justify-content-center">
                    <div class="d-flex flex-column ">
                        <div class="container m-auto d-flex flex-column">
                            <div class="gauge-container">
                                <div class="gauge" [ngStyle]="{ transform: 'rotate(' + gaugeAngle + 'deg)' }"></div>
                                <div class="gauge-cover"></div>
                                <!-- <div class="gauge-indicator" [ngStyle]="{ transform: 'rotate(' + indicatorAngle + 'deg)' }"></div> -->
                                <div class="gauge-value">
                                    {{((objObjetivos?.dblImporte/objObjetivos?.dblObjetivo)*100)|number:'1.2-2' }}%
                                </div>

                            </div>
                            <div class="d-flex w-100">
                                <div class="me-auto text-center izquierda">
                                    {{objObjetivos?.dblImporte|currency}} <br>
                                    venta
                                </div>
                                <div class="ms-auto text-center derecha">
                                    {{objObjetivos?.dblObjetivo|currency}}<br>
                                    Objetivo
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="list-group col-12 col-md-3 mx-auto">
            <div class="card h-100">
                <div class="card-header fw-bold">
                    Información General
                </div>
                <div class="card-body bg-white" #ingoGralDiv>
                    <div class="row">
                        <ol class="list-unstyled">
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-clipboard mx-1"></i><strong
                                        class="text-primary">Folio:</strong></span>
                                <span class="ms-auto text-dark fw-bold">{{objInfo?.intID_Leads}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-tag mx-1"></i><strong
                                        class="text-success">Clasificación:</strong></span>
                                <span class="ms-auto text-dark fw-bold">{{strClasificacion}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"> <i class="bi bi-circle-square mx-1"></i>Número de Cliente:</span>
                                <span class="ms-auto text-dark"><b>{{objInfo?.strCliente}}</b></span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-buildings mx-1"></i>Nombre Cliente:</span>
                                <span class="ms-auto text-dark text-end">{{objInfo?.strNombreEmpresa}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <div class="col-12 d-flex align-items-center">
                                    <span class="me-auto d-flex">
                                        <span class="text-danger">*</span> Familia
                                    </span>
                                    <div class="d-flex align-items-center position-relative col-6">
                                        <select [attr.disabled]="intValidacionFamilia === 2 ? true : null"
                                            class="form-select py-1 fs12 fw-bold" [(ngModel)]="intID_Familia">
                                            <option value="0">--Selecciona una opción--</option>
                                            <option *ngFor="let item of arrayFamilias; let i = index"
                                                [value]="item.intID_Familia">{{item.strFamilia}}</option>
                                        </select>
                                        <button type="button"
                                            class="btn btn-light p-0 position-absolute top-0 end-0 me-3"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Pendiente"
                                            *ngIf="intValidacionFamilia == 1">
                                            <i class="bi bi-exclamation-octagon-fill fs13 text-warning"></i>
                                        </button>
                                        <button type="button"
                                            class="btn btn-light  p-0 position-absolute top-0 end-0 me-3"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Aceptado"
                                            *ngIf="intValidacionFamilia == 2">
                                            <i class="bi bi-check-circle-fill fs13 text-success"></i>
                                        </button>
                                        <button type="button"
                                            class="btn btn-light  p-0 position-absolute top-0 end-0 me-3"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Cancelado"
                                            *ngIf="intValidacionFamilia == 3">
                                            <i class="bi bi-x-circle-fill text-danger fs13"></i>
                                        </button>
                                    </div>
                                    <mat-error *ngIf="formLead.get('intID_Familia').hasError('required') "
                                        class="text-danger small">
                                        <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                    </mat-error>
                                </div>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-person mx-1"></i>Nombre Contacto:</span>
                                <span class="ms-auto text-dark">{{objInfo?.strContacto_Nombre}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-person-check-fill mx-1"></i>Puesto:</span>
                                <span class="ms-auto text-dark">{{objInfo?.strContacto_Puesto}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-telephone-fill mx-1"></i>Teléfono:</span>
                                <span class="ms-auto text-dark">{{objInfo?.strContacto_Telefono}}</span>
                            </li>
                            <li class="d-flex mb-3">
                                <span class="me-auto"><i class="bi bi-envelope mx-1"></i>E-mail:</span>
                                <span class="ms-auto text-dark">{{objInfo?.strContacto_Email}}</span>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                                <span class="me-auto"><i class="bi bi-person-fill-check mx-1"></i>Agente:</span>
                                <span class="ms-auto float-end text-end text-dark w-75">
                                    {{strNombreAgente}}

                                </span>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                                <span class="me-auto"><i class="bi bi-person-fill-check mx-1"></i>Tipo:</span>
                                <span class="ms-auto float-end text-end text-dark w-75">
                                    Cliente
                                </span>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                                <button class="btn btn-primary ms-auto w-100" data-bs-toggle="modal"
                                    data-bs-target="#formModal" (click)="fnGetFormulario()">
                                    Llenar Formulario
                                </button>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- Time line -->
        <div class="col-12 col-md-6">
            <div class="mt-3 mt-md-0 col-12 h-100  rounded bg-white shadow-sm px-0">
                <ul class="nav nav-pills mb-3 clsNavBg ps-2 py-2" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active custom-inactive fs13 " id="pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="true">Timeline</button>
                    </li>
                    <li class="nav-item " role="presentation">
                        <button class="nav-link custom-inactive fs13 " id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                            aria-selected="false" (click)="fnllenarArrayTareas()">Tareas</button>
                    </li>
                    <li class="nav-item " role="presentation">
                        <button class="nav-link custom-inactive fs13 " id="pills-minuta-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-minuta" type="button" role="tab" aria-controls="pills-minuta"
                            aria-selected="false">Minutas</button>
                    </li>
                    <!--  <li class="nav-item" role="presentation">
                      <button class="nav-link custom-inactive fs13" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Control de cambios</button>
                    </li> -->
                </ul>
                <div class="tab-content px-3" id="pills-tabContent">
                    <div class="tab-pane fade show active py-2" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-12 col-lg-9 d-flex align-items-center">
                                <span class="clsInact pe-2">Registrar</span>
                                <span class="pe-1">
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Correo');">
                                        <i class="bi bi-envelope pe-2"></i>
                                        Correo
                                    </button>
                                </span>
                                <span class="pe-1">
                                    <button class="btn d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Llamada');">
                                        <i class="bi bi-telephone-fill pe-2"></i>
                                        Llamada
                                    </button>
                                </span>
                                <span class="pe-1">
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Visita');">
                                        <span class="material-symbols-outlined fs13">
                                            handshake
                                        </span>
                                        Reunion
                                    </button>
                                </span>
                                <span>
                                    <button class="btn  d-flex align-items-center fs12 btnOutline py-1 px-2"
                                        [disabled]="strEstatus=='GANADO' || strEstatus== 'PERDIDO'"
                                        data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                        (click)="fnModalRegistrar('Tarea');">
                                        <span class="material-symbols-outlined fs13">
                                            edit_note
                                        </span>
                                        Tarea
                                    </button>
                                </span>

                            </div>
                            <span class="ms-auto col-12 col-lg-3 mt-2 mt-lg-0 position-relative">
                                <input type="text" class="form-control ps-4 fs12" placeholder="Filtrar Timeline"
                                    [(ngModel)]="searchInput">
                                <i
                                    class="bi bi-funnel-fill position-absolute top-50 start-0 translate-middle-y ms-lg-3 ms-4 "></i>
                            </span>
                        </div>
                        <div class="timelinescroll w-100">
                            <div class="col-12 d-flex"
                                *ngFor="let obj of arrayOport| filtersearch: searchInput: ['strTitulo', 'strNombre_timeline']; let ind = index;"
                                [ngClass]="{'mb-3':ind==(arrayOport.length - 1),'mt-3':ind == 0 }">
                                <div class="col-12 d-flex" data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                    *ngIf="obj.strTipo=='VISITA'||obj.strTipo=='Visita'||obj.strTipo=='CORREO'||obj.strTipo=='Correo'  
                                    ||obj.strTipo=='LLAMADA'||obj.strTipo=='Llamada'||obj.strTipo=='TAREA_UPDATE'||obj.strTipo=='Update_Lead_Tarea'||obj.strTipo=='MINUTA'">
                                    <!-- (click)="fnmDLConAct(obj)"-->
                                    <div class="col-2">
                                        <div class="d-flex justify-content-center">
                                            <span class=""
                                                [ngClass]="{'horizontal-line':ind==0,'horizontal-line2':ind>0}"></span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="border border-3 rounded-circle  d-flex justify-content-center "
                                                [ngClass]="{'clsbordeTarea':(obj.strTipo=='VISITA'||obj.strTipo=='Visita'||obj.strTipo=='CORREO'||obj.strTipo=='Correo'||obj.strTipo=='LLAMADA'
                                                ||obj.strTipo=='Llamada')&&obj.strEstatus != 'POR AUTORIZAR',
                                                'clsbordeUpdate_Lead_Tarea':(obj.strTipo=='TAREA_UPDATE'||obj.strTipo=='Update_Lead_Tarea')&&obj.strEstatus != 'POR AUTORIZAR',
                                                'border-warning':obj.strEstatus == 'POR AUTORIZAR' }">

                                                <i *ngIf="obj.strTipo !='MINUTA'" class="bi px-2 py-1"
                                                    [ngClass]="{'bi-bell text-warning': obj.strEstatus == 'POR AUTORIZAR',
                                                    'bi-check-square':obj.strEstatus != 'POR AUTORIZAR',
                                                    ' clsIconTarea':(obj.strTipo=='VISITA'||obj.strTipo=='Visita'||obj.strTipo=='CORREO'||obj.strTipo=='Correo'||obj.strTipo=='LLAMADA'||obj.strTipo=='Llamada') 
                                                    && obj.strEstatus != 'POR AUTORIZAR',
                                                    'clsIconUpdate_Lead_Tarea':(obj.strTipo=='TAREA_UPDATE'||obj.strTipo=='Update_Lead_Tarea')&& obj.strEstatus != 'POR AUTORIZAR'}"></i>

                                                <i *ngIf="obj.strTipo =='MINUTA'"
                                                    class="bi px-2 py-1 bi bi-journal-bookmark-fill text-info-emphasis">
                                                </i>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="horizontal-line2"></span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-flex align-items-center">
                                        <span class="fs12 pe-2">
                                            {{obj.strFechaCreacion}}
                                        </span>
                                    </div>
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="ps-3">
                                            <div class="col-12 fs13 fw-bold">
                                                <span>
                                                    <i class="bi "
                                                        [ngClass]="{' bi-exclamation-triangle text-warning ':obj.strEstatus =='PENDIENTE',' bi-check-circle text-success':obj.strEstatus !='PENDIENTE'}"></i>

                                                    {{obj.strTitulo}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="clsInact fs12 me-2">{{obj.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs12" *ngIf="obj.strTipo!=='Oportunidad'">Fecha:
                                                    {{obj.strFechaCreacion}}</span>
                                                <span class="fs12 me-2" *ngIf="obj.strTipo=='Oportunidad'">Importe:
                                                    {{obj.dblImporte|currency}}</span>
                                                <span class="fs12" *ngIf="obj.strTipo=='Oportunidad'">Fecha
                                                    Cierre: {{obj.strFechaCierre_Estimada}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex"
                                    *ngIf=" !['VISITA','Visita', 'CORREO','Correo', 'LLAMADA','Llamada', 'TAREA_UPDATE','Update_Lead_Tarea','MINUTA'].includes(obj.strTipo) ">
                                    <div class="col-2">
                                        <div class="d-flex justify-content-center">
                                            <span class=""
                                                [ngClass]="{'horizontal-line':ind==0,'horizontal-line2':ind>0}"></span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="border border-3 rounded-circle  d-flex justify-content-center "
                                                [ngClass]="{'clsbordeOport':obj.strTipo=='Oportunidad','clsbordeFamilia':obj.strTipo=='Familia','clsbordeOportunidad_Tarea':obj.strTipo=='Oportunidad_Tarea'}">
                                                <i class="bi px-2 py-1"
                                                    [ngClass]="{'bi-cash clsIconOport': obj.strTipo =='Oportunidad','clsIconFamilia bi-people-fill ':obj.strTipo=='Familia','clsIconOportunidad_Tarea bi-cash-coin':obj.strTipo=='Oportunidad_Tarea'}"></i>
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <span class="horizontal-line2"></span>
                                        </div>
                                    </div>
                                    <div class="col-2 d-flex align-items-center">
                                        <span class="fs12 pe-2">
                                            {{obj.strFechaCreacion}}
                                        </span>
                                    </div>
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="ps-3">
                                            <div class="col-12 fs13 fw-bold">
                                                <span><i class="bi bi-lightbulb clsIconOport "></i>
                                                    {{obj.strTitulo}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="clsInact fs12 me-2">{{obj.strNombre_timeline}}</span>
                                            </div>
                                            <div class="col-12">
                                                <span class="fs12" *ngIf="obj.strTipo!=='Oportunidad'">Fecha:
                                                    {{obj.strFechaCreacion}}</span>
                                                <span class="fs12 me-2" *ngIf="obj.strTipo=='Oportunidad'">Importe:
                                                    {{obj.dblImporte|currency}}</span>
                                                <span class="fs12" *ngIf="obj.strTipo=='Oportunidad'">Fecha
                                                    Cierre: {{obj.strFechaCierre_Estimada}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade " id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <!--     <div class="row justify-content-center w-100 pb-4"> -->
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Buscar</mat-label>
                                <input matInput [(ngModel)]="strBuscarAct" (keyup)="applyFilterTareasCte($event)" placeholder="ID,Tarea,etc..."
                                    #input>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                            <div class="table-responsive mt-3">
                                <table class="table">
                                    <thead>
                                        <tr class="encabezado">
                                            <th></th>
                                            <th></th>
                                            <th>Título</th>
                                            <th>Responsable</th>
                                            <th>Origen</th>
                                            <th>Fecha</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="arrayActividades.length == 0">
                                            <tr>
                                                <td colspan="6">
                                                    <div class="card"
                                                        style="padding: 8px;text-align: center; font-weight: 600;">
                                                        <span>SIN DATOS</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngFor="let actividad of arrayActividades | filtersearch: strBuscarAct: ['strEstatus','strTitulo','strAgente','strNombreAgente','strOrigen']; let ind = index">
                                            <!-- Fila principal -->
                                            <tr>
                                                <td>
                                                    <button class="btn btn-secondary py-0 px-2 ms-auto"
                                                        (click)="fnSeeRow(ind,actividad)">
                                                        <i class="bi bi-caret-down-fill fs13"
                                                            *ngIf="!actividad.seeSubAct"></i>
                                                        <i class="bi bi-caret-up-fill fs13"
                                                            *ngIf="actividad.seeSubAct"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <div class="d-flex justify-content-center">
                                                        <div class=" "
                                                            *ngIf="actividad.strEstatus != 'POR AUTORIZAR' && actividad.strEstatus != 'PROCESO'">
                                                            <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                                disabled [checked]="actividad.strEstatus == 'CONCLUIDO'"
                                                                type="checkbox" id="customRadioCheckbox">
                                                        </div>
                                                        <div class=" text-center" *ngIf="actividad.strEstatus == 'POR AUTORIZAR'">
                                                            <button type="button"
                                                                class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                    class="fa-solid fa-bell"></i></button>
                                                        </div>
                                                        <div class=" text-center" *ngIf="actividad.strEstatus == 'PROCESO'">
                                                            <button type="button"
                                                                class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                                title="En Proceso"><i
                                                                    class="fa-solid fa-circle-half-stroke"></i></button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                    (click)="fnmDLConAct(actividad)">
                                                    <span class="mx-auto flex-grow-1 text-center fw-bold">{{ actividad.strTitulo
                                                        }}</span>
                                                </td>
                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                    (click)="fnmDLConAct(actividad)">
                                                    <span class="rounded-circle p-2 fs11 fw-bold text-center"
                                                        [ngClass]="{'res1':ind % 2 ==0,'res2':ind % 2 !=0}">
                                                        {{actividad.strAgente}}

                                                    </span>
                                                    <span class="ps-1 fs11 fw-bold">
                                                        {{actividad.strNombreAgente}}
                                                    </span>
                                                </td>
                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                    (click)="fnmDLConAct(actividad)" class="fs12 fw-bold">{{ actividad.strOrigen }}</td>
                                                <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades"
                                                    (click)="fnmDLConAct(actividad)">
                                                    <span class="fw-bold px-2 d-flex fw-bold"
                                                        matTooltip="{{actividad.strEstatus}}"
                                                        [ngClass]="{'badFecha':actividad.diasDiferencia>-1 || (actividad.diasDiferencia<0 && actividad.strEstatus != 'PENDIENTE'),'badFechamenor':actividad.diasDiferencia<0 && actividad.strEstatus == 'PENDIENTE'}">
                                                        <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                        {{actividad.strFechaRequerida|date:'dd/MM/yyyy' }}
                                                    </span>
                                                </td>
                                                <td>

                                                    <button class="btn  my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                                        <i class="bi bi-three-dots-vertical"></i>
                                                      </button>
                                                      <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                                                        <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                                                        <button mat-menu-item data-bs-toggle="modal" data-bs-target="#mdlRegistrar"
                                                        (click)="fnModalRegistrarSubtarea('Subtarea', actividad);">
                                                          <mat-icon class="text-success">note_add</mat-icon>
                                                          <span class="text-success">SubTarea </span>
                                                        </button>
                                                        <button mat-menu-item  data-bs-toggle="modal" data-bs-target="#mdlEditar"
                                                        (click)="fnEditar_Actividad(actividad)">
                                                          <mat-icon class="txtInfo">edit</mat-icon>
                                                          <span class="txtInfo">Editar</span>
                                                        </button>
                                                        <button mat-menu-item *ngIf="actividad.strEstatus == 'PENDIENTE'"
                                                        (click)="fnDelete_Tarea(actividad)">
                                                    
                                                            <mat-icon color="warn">delete</mat-icon>
                                                            <span class="text-danger">Eliminar</span>
                                                          </button>
                                                      </mat-menu>
                                                </td>
                                            </tr>

                                            <!-- Tabla secundaria cuando seeSubAct es true -->
                                            <tr *ngIf="actividad.seeSubAct">
                                                <td colspan="7" class="p-0">
                                                    <div class="container-fluid bg-light">
                                                        <div class="row justify-content-end">
                                                            <div class="col-11 py-2 ">
                                                                <div class="table-responsive mb-0">
                                                                    <table class="table text-center table-striped">
                                                                        <thead>
                                                                            <tr class="subActivid">
                                                                           
                                                                                <th></th>
                                                                                <th>Título</th>
                                                                                <th>Responsable</th>
                                                                                <th>Origen</th>
                                                                                <th>Fecha</th>
                                                                                <th>Acciones</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container *ngFor="let subActividad of arraySubTareas; let inds= index">
                                                                                <tr>
                                                                                  <!--   <td data-bs-toggle="modal"
                                                                                        data-bs-target="#MConcluirActividades"
                                                                                        (click)="fnmDLConAct(subActividad)">
                                                                                        <button
                                                                                            class="btn btn-warning text-white py-0 px-2">
                                                                                            <i class="bi bi-dash"></i>
                                                                                        </button>
                                                                                    </td> -->
                
                                                                                    <td>
                                                                                        <div class="d-flex justify-content-center">
                                                                                            <div class=" "
                                                                                                *ngIf="subActividad.strEstatus != 'POR AUTORIZAR' && subActividad.strEstatus != 'PROCESO'">
                                                                                                <input class="form-check-input custom-checkbox fs-6 mx-2"
                                                                                                    disabled [checked]="subActividad.strEstatus == 'CONCLUIDO'"
                                                                                                    type="checkbox" id="customRadioCheckbox">
                                                                                            </div>
                                                                                            <div class=" text-center" *ngIf="subActividad.strEstatus == 'POR AUTORIZAR'">
                                                                                                <button type="button"
                                                                                                    class="btn btn-outline-warning px-2 py-1 shadow-sm"><i
                                                                                                        class="fa-solid fa-bell"></i></button>
                                                                                            </div>
                                                                                            <div class=" text-center" *ngIf="subActividad.strEstatus == 'PROCESO'">
                                                                                                <button type="button"
                                                                                                    class="btn btn-outline-info px-2 py-1 shadow-sm"
                                                                                                    title="En Proceso"><i
                                                                                                        class="fa-solid fa-circle-half-stroke"></i></button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                
                                                                                    
                                                                                    <td data-bs-toggle="modal"
                                                                                        data-bs-target="#MConcluirActividades"
                                                                                        (click)="fnmDLConAct(subActividad)">{{
                                                                                        subActividad.strTitulo }}</td>
                                                                                    <td data-bs-toggle="modal"
                                                                                        data-bs-target="#MConcluirActividades"
                                                                                        (click)="fnmDLConAct(subActividad)">
                                                                                        <span
                                                                                            class="rounded-circle p-2 fs11 fw-bold text-center"
                                                                                            [ngClass]="{'res1':inds % 2 ==0,'res2':inds % 2 !=0}">
                                                                                            {{subActividad.strAgente}}
                                                                                        </span>
                                                                                        <span class="ps-1 fs11">
                                                                                            {{subActividad.strNombreAgente}}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td data-bs-toggle="modal" data-bs-target="#MConcluirActividades" class="fs11" (click)="fnmDLConAct(subActividad)">{{subActividad.strOrigen }}</td>
                                                                                    <td data-bs-toggle="modal"
                                                                                        data-bs-target="#MConcluirActividades"
                                                                                        (click)="fnmDLConAct(subActividad)">
                                                                                        <span class="fw-bold px-2 d-flex fs11"
                                                                                            matTooltip="{{subActividad.strEstatus}}"
                                                                                            [ngClass]="{'badFecha':subActividad.diasDiferencia>-1 || (subActividad.diasDiferencia<0 && subActividad.strEstatus != 'PENDIENTE'),'badFechamenor':subActividad.diasDiferencia<0 && subActividad.strEstatus == 'PENDIENTE'}">
                                                                                            <i class="bi bi-circle-fill fs4 pe-1"></i>
                                                                                            {{subActividad.strFechaRequerida|date:'dd/MM/yyyy'}}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button class="btn my-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                                                                            <i class="bi bi-three-dots-vertical"></i>
                                                                                          </button>
                                                                                          <mat-menu #menu="matMenu" class="custom-menu bg-ligth">
                                                                                            <div class="menu-arrow"></div> <!-- Aquí va el piquito -->
                                                                                            <button mat-menu-item  data-bs-toggle="modal"
                                                                                            data-bs-target="#mdlEditar"
                                                                                            (click)="fnEditar_Actividad(subActividad)">
                                                                                              <mat-icon class="txtInfo">edit</mat-icon>
                                                                                              <span class="txtInfo">Editar</span>
                                                                                            </button>
                                                                                            <button mat-menu-item *ngIf="subActividad.strEstatus == 'PENDIENTE'"
                                                                                            (click)="fnDelete_Tarea(subActividad)">
                                                                                        
                                                                                                <mat-icon color="warn">delete</mat-icon>
                                                                                                <span class="text-danger">Eliminar</span>
                                                                                              </button>
                                                                                          </mat-menu>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <!--   </div> -->
                    </div>
                    <!-- inicia minuta -->
                    <div class="tab-pane fade" id="pills-minuta" role="tabpanel" aria-labelledby="pills-minuta-tab">
                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <button type="button" class="btn btn-minuta col-12 mb-3" data-bs-toggle="modal"
                                    data-bs-target="#modalAddMinuta" (click)="fnAddMinuta()">
                                    <i class="fa-solid fa-plus"></i>
                                    Crear Nueva Minuta
                                </button>

                                <div class="mt-3 fs14 d-flex flex-column">
                                    <b>Bienvenido</b>
                                    <span class="mt-3">
                                        Minutas Ecodeli, permite documentar los temas más relevantes de las reuniones.
                                    </span>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="row">
                                    <div *ngFor="let minuta of arrayMinuta;let ind = index" class="col-12 col-xxl-6">
                                        <div class="bordertop borderbott mb-2 shadow-sm p-3 border borderMinBlue pointer"
                                            (click)="fnDetalleMinuta(minuta)"
                                            [ngClass]="{'mt-2':ind>1,'borderMinBlue': ind % 2 === 0, 'borderMinYellow': ind % 2 !== 0}">
                                            <!-- [ngClass]="{ 'par-class': i % 2 === 0, 'impar-class': i % 2 !== 0 }" style="width: 18rem;"  -->
                                            <div class="">
                                                <div class="col-12 text-center">
                                                    <p class="card-text fw-bold text-dark text-center fs14">
                                                        {{minuta.strTitulo}}</p>
                                                </div>
                                                <div class="col-12 d-flex justify-content-between my-3">
                                                    <div class="col-6 d-flex flex-column">
                                                        <h4 class="mb-0">Fecha Inicio</h4>
                                                        <h4 class="card-title fw-bold mb-0">
                                                            {{minuta.strFechaInicioServicio }}</h4>
                                                    </div>
                                                    <div class="col-6 d-flex flex-column text-end">
                                                        <h4 class="mb-0">Hora Inicio</h4>
                                                        <h4 class="mb-0 text-secondary fw-bold">{{minuta.strHoraInicio}}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-flex justify-content-between">
                                                    <button class="btn btn-outline-info py-1 px-2">
                                                        <i class="bi bi-pencil-square"></i>
                                                    </button>
                                                    <button class="btn btn-outline-danger py-1 px-2"
                                                        (click)="deleteMinuta(minuta, $event)">
                                                        <i class="fa-solid fa-trash-can "></i>
                                                    </button>
                                                </div>
                                                <!--  <a href="#" class="card-link me-2">Card link</a>
                                                <a href="#" class="card-link bg-danger btn"></a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- finaliza minuta -->
                </div>
            </div>
        </div>
        <!-- Oportunidades -->
        <div class="col-12 col-md-3 mt-4 mt-lg-0">
            <div class="col-11 d-flex align-items-center justify-content-center mb-3">
                <div class="row justify-content-between w-100 align-items-center">
                    <div class="col">
                        <span class="clsInact fw-bold ">SUEÑOS</span>
                    </div>
                    <div class="col">
                        <button class=" ms-auto d-flex align-items-center btn btn-primary py-1 px-2 fw-bold"
                            data-bs-toggle="modal" data-bs-target="#nuevaOportunidad"
                            (click)="openModal_oportunidad();fnFormOportunidades();">
                            <i class="bi bi-plus"></i>
                            <span class="fs11">Agregar</span>
                        </button>
                    </div>

                </div>

            </div>
            <div class="card" *ngIf="arrayOportunidades.length == 0" style="background: white;">
                <div class="card-header text-center">
                    <span class="fw-bold">SIN DATOS</span>
                </div>
            </div>
            <!--    (click)="enviarDetalleOporturnidad(item.strEstatus)"  -->
            <div class="col-12 " [style.max-height.px]="altInfo" style="overflow-y: auto;"
                *ngIf="arrayOportunidades.length > 0">
                <div class="card mb-2 shadow-sm cursor" *ngFor="let item of arrayOportunidades; let i = index"
                    (click)="enviarDetalleOporturnidad(item)">
                    <div class="card-header">
                        <span class="fw-bold fs13">{{item.strTitulo}}</span>
                    </div>
                    <div class="card-body fs13">
                        <div class="row">
                            <div class="col-12 d-flex">
                                <span class="badge bg-primary ms-auto">{{item.strEstatus}}</span>
                            </div>
                            <div class="col-3 d-flex align-items-center">
                                <div class="circle p-2">
                                    <span class="text">{{item.strABNombre}}</span><!-- *ngIf="item.strAgente == ''" -->
                                </div>
                            </div>
                            <div class="col-9 text-center row d-flex">
                                <h5 class="fw-bold text-secondary m-auto" style="white-space: initial; font-size: 11px">
                                    {{item.strNombreAgente}}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-12 d-flex fs11">
                                <div class="me-auto">
                                    <span>{{item.dblImporte | currency:'':'symbol':'1.2-2' }}</span>
                                </div>
                                <div class="ms-auto">
                                    <span><i class="fas fa-calendar-alt"></i>
                                        {{(item.strFechaCierre_Estimada).split(' ')[0]}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- comienzan taps -->
    <div class="row justify-content-center py-2">
        <div class="col-12 ">
            <mat-tab-group>
                <!-- Datos fiscales -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-person-rolodex iconoTabs mx-1"></i> <span class="m-auto">Datos
                                    Fiscales</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container">
                        <div class="row my-4">
                            <div *ngIf="!spinerCte" class="row justify-content-evenly">
                                <div class="col-12 col-md-5  shadow borderbott bordertop bg-white">
                                    <div class="col-12 table-responsive borderbott bordertop">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="text-center">
                                                        DATOS PERSONALES
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-geo-alt"></i> Direccion:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strDireccion}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-geo"></i>Colonia:</td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strColonia}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-globe-americas"></i>Poblacion:</td>
                                                    <td class="ps-0 fs12 fw-bold ">
                                                        {{objModalDatos.strPoblacion}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-map"></i> Estado:</td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strEstado}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-telephone"></i> Telefono:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strTelefonos}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-bag"></i> Contacto:</td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strContacto1}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-envelope-at"></i> Email:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.streMail2}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-house-door"></i> Almacen:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strAlmacen}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-people"></i> Familia:</td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strFamilia}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start">

                                                        <i class="fa-regular fa-calendar"></i>
                                                        Última Fecha de Compra:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strUltimaFecha}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start d-flex">
                                                        <span class="material-symbols-outlined fs17">
                                                            post_add
                                                        </span>
                                                        Datos Importantes:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strComentarios}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-12 col-md-5  shadow borderbott bordertop  bg-white">
                                    <div class="col-12 table-responsive borderbott bordertop">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="text-center">
                                                        DATOS FISCALES
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-bank"></i> Clave uso CFDI:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strClaveUsoCFDI}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-bank"></i> Descrip. uso
                                                        CFDI:</td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strDescripcionUso}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-cash-coin"></i> Forma Pago:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strDescripcionFP}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-arrow-repeat"></i> Giro:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strDescripcion12}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start"><i
                                                            class="bi bi-safe2"></i> Clave Banco:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.intClaveBanco}}</td>
                                                </tr>
                                                <tr class="border-bottom align-middle">
                                                    <td
                                                        class="fw-bold bg1 text-nowrap text-start d-flex border-bottom-0 ">
                                                        <span
                                                            class="material-symbols-outlined fs17">account_balance_wallet</span>
                                                        Nombre Banco:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold border-bottom-0">
                                                        {{objModalDatos.strNombreBanco}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start d-flex"><span
                                                            class="material-symbols-outlined fs17">
                                                            support_agent
                                                        </span> Nombre agente:</td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strAgenteNombre}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1  text-nowrap text-start"> <i
                                                            class="bi bi-credit-card-2-back"></i>Pagos:</td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strContacto2}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start d-flex"><span
                                                            class="material-symbols-outlined fs17">handshake</span>
                                                        Condición de pago:</td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strCondicionPago}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start d-flex">

                                                        <i class="fa-regular fa-credit-card fs17"></i>
                                                        Límite Crédito:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.dblCreditoLimite}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start d-flex">
                                                        <span
                                                            class="material-symbols-outlined fs17">checkbook</span>Número
                                                        de cuenta:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">{{objModalDatos.strNumeroCuenta}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold bg1 text-nowrap text-start d-flex">
                                                        <span class="material-symbols-outlined fs17">vpn_key</span>
                                                        Clabe Interbancaria:
                                                    </td>
                                                    <td class="ps-0 fs12 fw-bold ">
                                                        {{objModalDatos.strClaveInterbancaria}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- Informacion -->

                <!-- Sucursal -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-building  iconoTabs mx-1"></i> <span class="m-auto">Sucursal</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="col-12 pb-3">
                                    <mat-form-field appearance="outline" class="rounded bg-white">
                                        <mat-label>Buscar</mat-label>
                                        <input matInput placeholder="Cliente, Sucursal, Contacto, etcc." #input
                                            (keyup)="applyFilter($event)">
                                        <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="table-responsive mb-0">
                                    <div class="mat-elevation-z8">
                                        <table mat-table [dataSource]="dataSource" matSort #sort1="matSort"
                                            class="w-100 table table-hover">

                                            <ng-container matColumnDef="intId_Direccion">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="text-center ps-0"> Id Dirección</th>
                                                <td mat-cell *matCellDef="let row"
                                                    class=" ps-0 align-middle text-center fs12">
                                                    <span class="fw-bold">{{row.intId_Direccion}}</span>
                                                </td>
                                            </ng-container>


                                            <ng-container matColumnDef="strSucursal">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-items-start  fs12 text-primary cursorPoint">

                                                    <button type="button"
                                                        class="shadow-sm text-secondary btn btn-outline-warning p-1 px-2 rounded-circle"
                                                        data-bs-toggle="modal" data-bs-target="#editarSucursalModal"
                                                        (click)="fnEditGetsSucursal(row)">

                                                        <i class="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                    <span (click)="enviarDetalleSucursal(row)" class="me-2">
                                                        {{row.strSucursal}}
                                                    </span>
                                                </td>
                                            </ng-container>


                                            <ng-container matColumnDef="strCalle">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Calle </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12"> {{row.strCalle}} </td>
                                            </ng-container>


                                            <ng-container matColumnDef="strNo_exterior">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> No. Exterior
                                                </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12">
                                                    <span class="">{{row.strNo_exterior}}</span>
                                                </td>
                                            </ng-container>


                                            <ng-container matColumnDef="strColonia">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Colonia </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12"> {{row.strColonia}} </td>
                                            </ng-container>


                                            <ng-container matColumnDef="strDelegacion">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Delegación </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12"> {{row.strDelegacion}} </td>
                                            </ng-container>


                                            <ng-container matColumnDef="strEstado">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12">
                                                    <span class=""> {{row.strEstado}}</span>
                                                </td>
                                            </ng-container>


                                            <ng-container matColumnDef="strCodigoPostal">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo Postal
                                                </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12">
                                                    <span class=""> {{row.strCodigoPostal}}</span>
                                                </td>
                                            </ng-container>


                                            <ng-container matColumnDef="strAgente">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="text-center pe-0"> Agente </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="pe-0 align-middle text-center fs12">
                                                    <!--  <span class="fw-bold ">({{row.strAgente}})</span>   {{row.strNombreAgente}} -->


                                                    <div class="row">
                                                        <div class="col-8">
                                                            <select class="form-select form-select-sm mb-1 small-font"
                                                                aria-label="Large select example"
                                                                [(ngModel)]="row.strAgente"
                                                                [disabled]="boolEditaAgente">
                                                                <option *ngFor="let agente of arrayAgentes"
                                                                    [value]="agente.strAgente"> <span class="fw-bold ">
                                                                        {{agente.strNombre}}-({{agente.strAgente}})</span>
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-4">
                                                            <button type="button" class="btn btn-outline-primary"
                                                                title="Cambiar Agente" [disabled]="boolEditaAgente"
                                                                (click)="fnUpdateAgente(row.strAgente,row.strCliente,row.intId_Direccion)"><i
                                                                    class="fa-solid fa-pen-to-square"></i></button>
                                                            <!---->
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"
                                                class="btncerrarModal"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="9">Sin resultados de la busqueda:
                                                    "{{input.value}}"</td>
                                            </tr>
                                        </table>
                                        <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 25, 100]"
                                            aria-label="Select page of users" #paginator1></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- reporte de ventas -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <span _ngcontent-ncg-c201="" class="material-symbols-outlined fs15 ng-tns-c201-2">
                                    bar_chart </span> <span class="m-auto">Reporte de Vtas</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 text-center mt-5">
                                <h3>VENTA POR ARTICULO <span class="bg4 mx-auto"></span>
                                </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-md-flex justify-content-evenly">
                                <div class="col-12 col-md-5">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Linea</mat-label>
                                        <select matNativeControl [(ngModel)]="strLinea" name="car">
                                            <option *ngFor="let lin of arrayLineasReporte" [value]="lin.value">
                                                {{lin.viewValue}}
                                            </option>
                                        </select>
                                        <mat-icon matSuffix class="text-dark">search</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-5 mt-4 mt-md-0">
                                    <mat-form-field class="bg-white rounded" appearance="outline">
                                        <mat-label>Buscar Articulo</mat-label>
                                        <input class="text-dark" matInput [(ngModel)]="strBuscar"
                                            placeholder="Ingrese el articulo">
                                        <mat-icon matSuffix class="text-dark">search</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="spinerVtaXart">
                            <div class="col-12 d-flex justify-content-center">
                                <mat-spinner></mat-spinner>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="!spinerVtaXart">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-bodered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th colspan="12" *ngFor="let i of arrayCabeceraXart;let ind = index"
                                                    class="text-center"
                                                    [ngClass]="{'bgedocta1': ind % 2 === 0, 'bgst': ind % 2 !== 0}">
                                                    {{i.strEjercicio}}
                                                </th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th>Articulo</th>
                                                <th>Descripcion</th>
                                                <ng-container *ngFor="let i of arrayCabeceraXart; let ind = index">
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Ene</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Feb</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Mar</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Abr</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        May</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Jun</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Jul</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Ago</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Sep</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Oct</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Nov</th>
                                                    <th [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        Dic</th>
                                                </ng-container>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let f of arrayArts | filtersearch: strBuscar: ['strArticulo', 'strDescripcion']|filtersearch: strLinea: ['strLinea']; let j = index">
                                                <td class="fs12 fw-bold  text-center align-middle "><span
                                                        class="p-1 bgtabHistory rounded">{{f.strArticulo}}</span></td>
                                                <td class="fs10">{{f.strDescripcion}}</td>
                                                <ng-container *ngFor="let i of arrayCabeceraXart; let ind = index">
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('ene' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(1, i.strEjercicio,f.strArticulo)">
                                                                {{ f['ene' + i.strEjercicio] }}</a>
                                                        </span>
                                                        <ng-template #elseBlock>
                                                            <span class="text-secondary fs13 fw-bold">
                                                                0
                                                            </span>
                                                        </ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('feb' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(2, i.strEjercicio,f.strArticulo)">
                                                                {{ f['feb' + i.strEjercicio] }}</a>

                                                        </span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('mar' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(3, i.strEjercicio,f.strArticulo)">{{
                                                                f['mar' + i.strEjercicio] }}</a>
                                                        </span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('abr' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(4, i.strEjercicio,f.strArticulo)">{{
                                                                f['abr' + i.strEjercicio] }}</a>

                                                        </span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('may' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(5, i.strEjercicio,f.strArticulo)">{{
                                                                f['may' +
                                                                i.strEjercicio] }}</a>
                                                        </span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('jun' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(6, i.strEjercicio,f.strArticulo)">{{
                                                                f['jun' +
                                                                i.strEjercicio] }} </a>
                                                        </span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('jul' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(7, i.strEjercicio,f.strArticulo)">
                                                                {{ f['jul' +
                                                                i.strEjercicio] }}</a></span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('ago' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(8, i.strEjercicio,f.strArticulo)">
                                                                {{ f['ago' +
                                                                i.strEjercicio] }} </a></span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center"
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('sep' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(9, i.strEjercicio,f.strArticulo)">
                                                                {{ f['sep' +
                                                                i.strEjercicio] }} </a></span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('oct' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(10, i.strEjercicio,f.strArticulo)">
                                                                {{ f['oct' +
                                                                i.strEjercicio] }} </a> </span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('nov' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(11, i.strEjercicio,f.strArticulo)">
                                                                {{ f['nov' +
                                                                i.strEjercicio] }} </a> </span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                    <td class="align-middle text-center "
                                                        [ngClass]="{'bgflujocte': ind % 2 === 0, 'bg5': ind % 2 !== 0}">
                                                        <span
                                                            *ngIf="f.hasOwnProperty('dic' + i.strEjercicio); else elseBlock"
                                                            class="text-primary fs13 fw-bold">
                                                            <a data-bs-toggle="modal" data-bs-target="#detArtModal"
                                                                (click)="fnDetalleReporteVtas(12, i.strEjercicio,f.strArticulo)">{{
                                                                f['dic' +
                                                                i.strEjercicio] }} </a> </span>
                                                        <ng-template #elseBlock><span
                                                                class="text-secondary fs13 fw-bold">0</span></ng-template>
                                                    </td>
                                                </ng-container>
                                                <td class="align-middle text-center fw-bold">{{f.intTotal}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- articulos politica -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-bag iconoTabs mx-1"></i> <span class="m-auto">Articulos Asignados</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 py-3 d-flex align-items-center justify-content-around">
                                <mat-form-field appearance="outline" class="rounded bg-white">
                                    <mat-label>Buscar</mat-label>
                                    <input matInput placeholder="Articulo, Descripcion, unidad, etcc." #input
                                        (keyup)="applyFilterArt($event)">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <div class=" d-flex align-items-center">
                                    <button class="btn btn-outline-danger fs12 fw-bold" (click)="fnPdfArtsPolitica()">
                                        <i class="bi bi-file-earmark-pdf fs-6"></i> Descargar PDF
                                    </button>
                                    <div class="spinner-border text-primary ms-2" role="status" *ngIf="boolPolitica">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 table-responsive px-0 mb-0">
                                <table mat-table [dataSource]="dataSourceC" matSort #sort2="matSort" class="table">
                                    <ng-container matColumnDef="indice">
                                        <th mat-header-cell *matHeaderCellDef class="ps-0">
                                            <span class="mx-auto">#</span>
                                        </th>
                                        <td mat-cell *matCellDef="let i = index"
                                            class="gastoCell ps-0 text-center align-middle">
                                            <b class="resalta">{{ i + 1 }}</b>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="img">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Imagen</th>
                                        <td mat-cell *matCellDef="let element" class="gastoCell ">
                                            <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArticulo + '.jpg'"
                                                alt="" class="p-2" width="100">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strArticulo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                            Articulo</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell fw-bold  align-middle text-center fs12">
                                            {{element.strArticulo}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strDescripcion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center">
                                            Descripción</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center fs10">
                                            {{element.strDescripcion}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strUnidad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="px-3 align-middle text-center">
                                            Unidad
                                        </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center fs12">
                                            {{element.strUnidad}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dblPrecio">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            Precio </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12 fw-bold">
                                            {{element.dblPrecio|currency}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strTipo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            Tipo </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12">
                                            <span class="fw-bold "
                                                [ngClass]="{'clsPlan':element.strTipo=='PLANTILLA','clsPolitica':element.strTipo=='POLITICA'}">{{element.strTipo}}</span>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strLista">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            Agregar a Carrito
                                        </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12">
                                            <!--{{element.strLista}}-->
                                            <button type="button" class="btn btn-outline-success" data-bs-toggle="modal"
                                                data-bs-target="#exampleModal" (click)="fnAbrirModal(element)">
                                                <i class="bi bi-cart3"></i> Agregar </button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCotizacion"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsCotizacion;"></tr>
                                </table>
                            </div>
                            <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 25, 100]"
                                aria-label="Select page of users" #paginator2></mat-paginator>
                        </div>
                    </div>
                </mat-tab>
                <!-- HistVenta -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-graph-up iconoTabs mx-1"></i> <span class="m-auto">Histórico de
                                    Venta</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mb-4 pb-4">
                            <div class="col-12 mt-3 mb-2 d-flex">
                                <ul class="nav nav-pills ms-auto d-flex justify-content-end" id="HistVta"
                                    role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="pills-SucLineasHist-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-SucLineasHist" type="button"
                                            role="tab" aria-controls="pills-SucLineasHist" aria-selected="false">
                                            <i class="bi bi-card-list"></i> Sucursal
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="ms-2 nav-link " id="pills-ctesLineasHist-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-ctesLineasHist" type="button"
                                            role="tab" aria-controls="pills-ctesLineasHist" aria-selected="false">
                                            <i class="bi bi-card-list"></i> Lineas
                                        </button>
                                    </li>

                                    <li class="nav-item" role="presentation">
                                        <button class="ms-2 nav-link " id="pills-margenLineasHist-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-margenLineasHist" type="button"
                                            role="tab" aria-controls="pills-margenLineasHist" aria-selected="false">
                                            <i class="bi bi-percent"></i> Margen
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade " id="pills-ctesLineasHist" role="tabpanel"
                                    aria-labelledby="pills-ctesLineasHist-tab">
                                    <div class="row mb-4">
                                        <div class="col-6 pb-1 d-flex " *ngIf="!spinerBool">
                                            <mat-form-field appearance="outline" class="rounded bg-white">
                                                <mat-label>Buscar </mat-label>
                                                <input matInput [(ngModel)]="strBuscarHistVta"
                                                    placeholder="Articulo, Descripcion, unidad, etcc."
                                                    [disabled]=" boolSuc">
                                                <mat-icon matSuffix>search</mat-icon>
                                            </mat-form-field>

                                        </div>
                                        <div class="col-3 pb-1 d-flex " *ngIf="!spinerBool">
                                            <select class="form-select w-30" aria-label="Default select example"
                                                [(ngModel)]="intEjercicio">
                                                <option *ngFor="let x of arrayEjercicio">
                                                    {{x.ejercicio}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-3 pb-1 d-flex " *ngIf="!spinerBool">
                                            <button type="button" class="btn btn-primary"
                                                (click)="fnFiltrarHistorico_vta()"><i
                                                    class="fa-solid fa-filter"></i></button>
                                        </div>
                                        <div class="col-12 ">
                                            <div class="col-12 d-flex justify-content-center my-4" *ngIf="spinerBool">
                                                <mat-spinner></mat-spinner>
                                            </div>
                                            <div class="table-responsive mb-0" *ngIf="!spinerBool">
                                                <table class="table table-hover ">
                                                    <thead>
                                                        <tr class="sticky-top bg-light">
                                                            <th class="bgTable_histVta sticky-col"></th>
                                                            <th class="text-center bgTable_histVta sticky-col2">Cliente
                                                            </th>
                                                            <th class="text-center bgTable_histVta">Enero</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Febrero</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Marzo</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Abril</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Mayo</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Junio</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Julio</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Agosto</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Septiembre</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Octubre</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Noviembre</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Diciembre</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Total</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container
                                                            *ngFor="let ob of arrayFiltradoHistClientes|filtersearch: strBuscarHistVta : ['strNombreCliente','strCliente'];let ind = index"><!-- |filtersearch: strSucursalHistVta : ['strSucursal']  -->
                                                            <tr>
                                                                <td class=" sticky-col">
                                                                    <span class="rounded bg-primary text-white">
                                                                        <i class="bi "
                                                                            [ngClass]="{'bi-dash-lg':ob.seeLineas == true,'bi-plus':ob.seeLineas == false}"
                                                                            (click)="fnCambiarEstatusVista(ind,ob)"></i>
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="text-start border-end align-middle sticky-col2">
                                                                    <div class="d-flex">
                                                                        <span class="fs14 fw-bold">
                                                                            <!--     <a (click)="enviarDetalleCliente(ob)"> -->
                                                                            <b>({{ob.strCliente}})
                                                                            </b>{{ob.strNombreCliente}}
                                                                            <!-- </a> -->
                                                                        </span>
                                                                        <span
                                                                            class="ms-auto fw-bold text-secondary fs13">
                                                                            {{ob.strNombreEmpresa}}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold "
                                                                    [ngClass]="{'clsPlan':ob.sumEnero>0,'clsInact':ob.sumEnero==0}">
                                                                    {{ob.sumEnero|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenEnero !== 0) ? (ob.dblMargenEnero |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenEnero < 20, 
                                                                                       'bg-warning': ob.dblMargenEnero >= 20 && ob.dblMargenEnero < 26, 
                                                                                       'bg-success': ob.dblMargenEnero >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenEnero">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumFebrero>0,'clsInact':ob.sumFebrero==0}">
                                                                    {{ob.sumFebrero|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenFebrero !== 0) ?
                                                                    (ob.dblMargenFebrero | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenFebrero < 20, 
                                                                                             'bg-warning': ob.dblMargenFebrero >= 20 && ob.dblMargenFebrero < 26, 
                                                                                             'bg-success': ob.dblMargenFebrero >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenFebrero">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumMarzo>0,'clsInact':ob.sumMarzo==0}">
                                                                    {{ob.sumMarzo|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenMarzo !== 0) ? (ob.dblMargenMarzo |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenMarzo < 20, 
                                                                                             'bg-warning': ob.dblMargenMarzo >= 20 && ob.dblMargenMarzo < 26, 
                                                                                             'bg-success': ob.dblMargenMarzo >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenMarzo">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumAbril>0,'clsInact':ob.sumAbril==0}">
                                                                    {{ob.sumAbril|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenAbril !== 0) ? (ob.dblMargenAbril |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenAbril < 20, 
                                                                                             'bg-warning': ob.dblMargenAbril >= 20 && ob.dblMargenAbril < 26, 
                                                                                             'bg-success': ob.dblMargenAbril >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenAbril">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumMayo>0,'clsInact':ob.sumMayo==0}">
                                                                    {{ob.sumMayo|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenMayo !== 0) ? (ob.dblMargenMayo |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenMayo < 20, 
                                                                                           'bg-warning': ob.dblMargenMayo >= 20 && ob.dblMargenMayo < 26, 
                                                                                           'bg-success': ob.dblMargenMayo >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenMayo">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumJunio>0,'clsInact':ob.sumJunio==0}">
                                                                    {{ob.sumJunio|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenJunio !== 0) ? (ob.dblMargenJunio |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenJunio < 20, 
                                                                                             'bg-warning': ob.dblMargenJunio >= 20 && ob.dblMargenJunio < 26, 
                                                                                             'bg-success': ob.dblMargenJunio >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenJunio">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumJulio>0,'clsInact':ob.sumJulio==0}">
                                                                    {{ob.sumJulio|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenJulio !== 0) ? (ob.dblMargenJulio |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenJulio < 20, 
                                                                                             'bg-warning': ob.dblMargenJulio >= 20 && ob.dblMargenJulio < 26, 
                                                                                             'bg-success': ob.dblMargenJulio >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenJulio">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumAgosto>0,'clsInact':ob.sumAgosto==0}">
                                                                    {{ob.sumAgosto|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenAgosto !== 0) ? (ob.dblMargenAgosto
                                                                    | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenAgosto < 20, 
                                                                                           'bg-warning': ob.dblMargenAgosto >= 20 && ob.dblMargenAgosto < 26, 
                                                                                           'bg-success': ob.dblMargenAgosto >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenAgosto">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumSeptiembre>0,'clsInact':ob.sumSeptiembre==0}">
                                                                    {{ob.sumSeptiembre|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenSeptiembre !== 0) ?
                                                                    (ob.dblMargenSeptiembre | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenSeptiembre < 20, 
                                                                                             'bg-warning': ob.dblMargenSeptiembre >= 20 && ob.dblMargenSeptiembre < 26, 
                                                                                             'bg-success': ob.dblMargenSeptiembre >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenSeptiembre">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumOctubre>0,'clsInact':ob.sumOctubre==0}">
                                                                    {{ob.sumOctubre|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenOctubre !== 0) ?
                                                                    (ob.dblMargenOctubre | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenOctubre < 20, 
                                                                                             'bg-warning': ob.dblMargenOctubre >= 20 && ob.dblMargenOctubre < 26, 
                                                                                             'bg-success': ob.dblMargenOctubre >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenOctubre">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumNoviembre>0,'clsInact':ob.sumNoviembre==0}">
                                                                    {{ob.sumNoviembre|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenNoviembre !== 0) ?
                                                                    (ob.dblMargenNoviembre | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenNoviembre < 20, 
                                                                                             'bg-warning': ob.dblMargenNoviembre >= 20 && ob.dblMargenNoviembre < 26, 
                                                                                             'bg-success': ob.dblMargenNoviembre >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenNoviembre">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumDiciembre>0,'clsInact':ob.sumDiciembre==0}">
                                                                    {{ob.sumDiciembre|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenDiciembre !== 0) ?
                                                                    (ob.dblMargenDiciembre | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenDiciembre < 20, 
                                                                                             'bg-warning': ob.dblMargenDiciembre >= 20 && ob.dblMargenDiciembre < 26, 
                                                                                             'bg-success': ob.dblMargenDiciembre >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenDiciembre">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.total>0,'clsInact':ob.total==0}">
                                                                    {{ob.total|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.totalMargen !== 0) ? (ob.totalMargen |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.totalMargen < 20, 
                                                                                             'bg-warning': ob.totalMargen >= 20 && ob.totalMargen < 26, 
                                                                                             'bg-success': ob.totalMargen >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.totalMargen">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <ng-container *ngIf="ob.seeLineas == true ">
                                                                <ng-container *ngFor="let lin of ob.arrayLineas">
                                                                    <tr>
                                                                        <td class="sticky-col">
                                                                        </td>
                                                                        <td
                                                                            class="text-start fs12  border-end sticky-col2">
                                                                            <span
                                                                                class="rounded bg-warning text-white me-3">
                                                                                <i class="bi "
                                                                                    [ngClass]="{'bi-dash-lg':lin.seeArts == true,'bi-plus':lin.seeArts == false}"
                                                                                    (click)="fnCambiarEstatusVistaLineas(ind,lin,ob)"></i>
                                                                            </span>
                                                                            <span
                                                                                class="fs14 fw-bold">{{lin.strLinea}}</span>
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumEnero>0,'clsInact':lin.sumEnero==0}">
                                                                            {{lin.sumEnero|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumEnero>0,'clsInact':lin.sumEnero==0}">
                                                                            {{ (lin.dblMargenEnero !== 0) ?
                                                                            (lin.dblMargenEnero | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenEnero < 20, 
                                                                                                 'bg-warning': lin.dblMargenEnero >= 20 && lin.dblMargenEnero < 26, 
                                                                                                 'bg-success': lin.dblMargenEnero >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenEnero">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumFebrero>0,'clsInact':lin.sumFebrero==0}">
                                                                            {{lin.sumFebrero|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumFebrero>0,'clsInact':lin.sumFebrero==0}">
                                                                            {{ (lin.dblMargenFebrero !== 0) ?
                                                                            (lin.dblMargenFebrero | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenFebrero < 20, 
                                                                                                 'bg-warning': lin.dblMargenFebrero >= 20 && lin.dblMargenFebrero < 26, 
                                                                                                 'bg-success': lin.dblMargenFebrero >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenFebrero">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumMarzo>0,'clsInact':lin.sumMarzo==0}">
                                                                            {{lin.sumMarzo|currency}}</td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumMarzo>0,'clsInact':lin.sumMarzo==0}">
                                                                            {{ (lin.dblMargenMarzo !== 0) ?
                                                                            (lin.dblMargenMarzo | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenMarzo < 20, 
                                                                                                 'bg-warning': lin.dblMargenMarzo >= 20 && lin.dblMargenMarzo < 26, 
                                                                                                 'bg-success': lin.dblMargenMarzo >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenMarzo">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumAbril>0,'clsInact':lin.sumAbril==0}">
                                                                            {{lin.sumAbril|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumAbril>0,'clsInact':lin.sumAbril==0}">
                                                                            {{ (lin.dblMargenAbril !== 0) ?
                                                                            (lin.dblMargenAbril | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenAbril < 20, 
                                                                                               'bg-warning': lin.dblMargenAbril >= 20 && lin.dblMargenAbril < 26, 
                                                                                               'bg-success': lin.dblMargenAbril >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenAbril">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumMayo>0,'clsInact':lin.sumMayo==0}">
                                                                            {{lin.sumMayo|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumMayo>0,'clsInact':lin.sumMayo==0}">
                                                                            {{ (lin.dblMargenMayo !== 0) ?
                                                                            (lin.dblMargenMayo | number:'1.2-2') : 0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenMayo < 20, 
                                                                                                 'bg-warning': lin.dblMargenMayo >= 20 && lin.dblMargenMayo < 26, 
                                                                                                 'bg-success': lin.dblMargenMayo >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenMayo">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumJunio>0,'clsInact':lin.sumJunio==0}">
                                                                            {{lin.sumJunio|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumJunio>0,'clsInact':lin.sumJunio==0}">
                                                                            {{ (lin.dblMargenJunio !== 0) ?
                                                                            (lin.dblMargenJunio | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenJunio < 20, 
                                                                                                 'bg-warning': lin.dblMargenJunio >= 20 && lin.dblMargenJunio < 26, 
                                                                                                 'bg-success': lin.dblMargenJunio >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenJunio">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumJulio>0,'clsInact':lin.sumJulio==0}">
                                                                            {{lin.sumJulio|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumJulio>0,'clsInact':lin.sumJulio==0}">
                                                                            {{ (lin.dblMargenJulio !== 0) ?
                                                                            (lin.dblMargenJulio | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenJulio < 20, 
                                                                                                 'bg-warning': lin.dblMargenJulio >= 20 && lin.dblMargenJulio < 26, 
                                                                                                 'bg-success': lin.dblMargenJulio >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenJulio">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumAgosto>0,'clsInact':lin.sumAgosto==0}">
                                                                            {{lin.sumAgosto|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumAgosto>0,'clsInact':lin.sumAgosto==0}">
                                                                            {{ (lin.dblMargenAgosto !== 0) ?
                                                                            (lin.dblMargenAgosto | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenAgosto < 20, 
                                                                                                 'bg-warning': lin.dblMargenAgosto >= 20 && lin.dblMargenAgosto < 26, 
                                                                                                 'bg-success': lin.dblMargenAgosto >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenAgosto">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumSeptiembre>0,'clsInact':lin.sumSeptiembre==0}">
                                                                            {{lin.sumSeptiembre|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumSeptiembre>0,'clsInact':lin.sumSeptiembre==0}">
                                                                            {{ (lin.dblMargenSeptiembre !== 0) ?
                                                                            (lin.dblMargenSeptiembre | number:'1.2-2') :
                                                                            0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenSeptiembre < 20, 
                                                                                               'bg-warning': lin.dblMargenSeptiembre >= 20 && lin.dblMargenSeptiembre < 26, 
                                                                                               'bg-success': lin.dblMargenSeptiembre >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenSeptiembre">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumOctubre>0,'clsInact':lin.sumOctubre==0}">
                                                                            {{lin.sumOctubre|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumOctubre>0,'clsInact':lin.sumOctubre==0}">
                                                                            {{ (lin.dblMargenOctubre !== 0) ?
                                                                            (lin.dblMargenOctubre | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenOctubre < 20, 
                                                                                                 'bg-warning': lin.dblMargenOctubre >= 20 && lin.dblMargenOctubre < 26, 
                                                                                                 'bg-success': lin.dblMargenOctubre >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenOctubre">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumNoviembre>0,'clsInact':lin.sumNoviembre==0}">
                                                                            {{lin.sumNoviembre|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumNoviembre>0,'clsInact':lin.sumNoviembre==0}">
                                                                            {{ (lin.dblMargenNoviembre !== 0) ?
                                                                            (lin.dblMargenNoviembre | number:'1.2-2') :
                                                                            0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenNoviembre < 20, 
                                                                                               'bg-warning': lin.dblMargenNoviembre >= 20 && lin.dblMargenNoviembre < 26, 
                                                                                               'bg-success': lin.dblMargenNoviembre >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenNoviembre">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumDiciembre>0,'clsInact':lin.sumDiciembre==0}">
                                                                            {{lin.sumDiciembre|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumDiciembre>0,'clsInact':lin.sumDiciembre==0}">
                                                                            {{ (lin.dblMargenDiciembre !== 0) ?
                                                                            (lin.dblMargenDiciembre | number:'1.2-2') :
                                                                            0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenDiciembre < 20, 
                                                                                                 'bg-warning': lin.dblMargenDiciembre >= 20 && lin.dblMargenDiciembre < 26, 
                                                                                                 'bg-success': lin.dblMargenDiciembre >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenDiciembre">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.total>0,'clsInact':lin.total==0}">
                                                                            {{lin.total|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.totalMargen>0,'clsInact':lin.totalMargen==0}">
                                                                            {{ (lin.totalMargen !== 0) ?
                                                                            (lin.totalMargen | number:'1.2-2') : 0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.totalMargen < 20, 
                                                                                                 'bg-warning': lin.totalMargen >= 20 && lin.totalMargen < 26, 
                                                                                                 'bg-success': lin.totalMargen >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.totalMargen">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                    <ng-container *ngIf="lin.seeArts == true ">
                                                                        <tr *ngFor="let arts of lin.arrayArticulos"
                                                                            class="bg-light">
                                                                            <td colspan="2"
                                                                                class="text-end fs12  border-end fw-bold me-2"
                                                                                matTooltip="{{arts.strDescripcion}}">
                                                                                {{arts.strArticulo}}
                                                                                <span class="text-secondary">
                                                                                    {{arts.strDescripcion}}
                                                                                </span>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblEnero > 0 , 'text-negative': arts.dblEnero === 0}">
                                                                                {{arts.dblEnero|currency}}
                                                                            </td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblEnero > 0 , 'text-negative': arts.dblEnero === 0}">
                                                                                {{(arts.dblMargenEnero !== 0)
                                                                                ?(arts.dblMargenEnero |number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger':arts.dblMargenEnero<20,'bg-warning':arts.dblMargenEnero >=20 && arts.dblMargenEnero<26,'bg-success':arts.dblMargenEnero>=26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenEnero">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblFebrero > 0 , 'text-negative': arts.dblFebrero === 0}">
                                                                                {{arts.dblFebrero|currency}}
                                                                            </td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblFebrero > 0 , 'text-negative': arts.dblFebrero === 0}">
                                                                                {{ (arts.dblMargenFebrero !== 0) ?
                                                                                (arts.dblMargenFebrero | number:'1.2-2')
                                                                                : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenFebrero < 20, 
                                                                                                     'bg-warning': arts.dblMargenFebrero >= 20 && arts.dblMargenFebrero < 26, 
                                                                                                     'bg-success': arts.dblMargenFebrero >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenFebrero">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblMarzo > 0 , 'text-negative': arts.dblMarzo === 0}">
                                                                                {{arts.dblMarzo|currency}}
                                                                            </td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblMarzo > 0 , 'text-negative': arts.dblMarzo === 0}">
                                                                                {{ (arts.dblMargenMarzo !== 0) ?
                                                                                (arts.dblMargenMarzo | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenMarzo < 20, 
                                                                                                     'bg-warning': arts.dblMargenMarzo >= 20 && arts.dblMargenMarzo < 26, 
                                                                                                     'bg-success': arts.dblMargenMarzo >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenMarzo">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblAbril > 0 , 'text-negative': arts.dblAbril === 0}">
                                                                                {{arts.dblAbril|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblAbril > 0 , 'text-negative': arts.dblAbril === 0}">
                                                                                {{ (arts.dblMargenAbril !== 0) ?
                                                                                (arts.dblMargenAbril | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenAbril < 20, 
                                                                                                     'bg-warning': arts.dblMargenAbril >= 20 && arts.dblMargenAbril < 26, 
                                                                                                     'bg-success': arts.dblMargenAbril >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenAbril">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblMayo > 0 , 'text-negative': arts.dblMayo === 0}">
                                                                                {{arts.dblMayo|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblMayo > 0 , 'text-negative': arts.dblMayo === 0}">
                                                                                {{ (arts.dblMargenMayo !== 0) ?
                                                                                (arts.dblMargenMayo | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenMayo < 20, 
                                                                                                     'bg-warning': arts.dblMargenMayo >= 20 && arts.dblMargenMayo < 26, 
                                                                                                     'bg-success': arts.dblMargenMayo >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenMayo">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblJunio > 0 , 'text-negative': arts.dblJunio === 0}">
                                                                                {{arts.dblJunio|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblJunio > 0 , 'text-negative': arts.dblJunio === 0}">
                                                                                {{ (arts.dblMargenJunio !== 0) ?
                                                                                (arts.dblMargenJunio | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenJunio < 20, 
                                                                                                   'bg-warning': arts.dblMargenJunio >= 20 && arts.dblMargenJunio < 26, 
                                                                                                   'bg-success': arts.dblMargenJunio >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenJunio">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblJulio > 0 , 'text-negative': arts.dblJulio === 0}">
                                                                                {{arts.dblJulio|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblJulio > 0 , 'text-negative': arts.dblJulio === 0}">
                                                                                {{ (arts.dblMargenJulio !== 0) ?
                                                                                (arts.dblMargenJulio | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenJulio < 20, 
                                                                                                     'bg-warning': arts.dblMargenJulio >= 20 && arts.dblMargenJulio < 26, 
                                                                                                     'bg-success': arts.dblMargenJulio >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenJulio">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblAgosto > 0 , 'text-negative': arts.dblAgosto === 0}">
                                                                                {{arts.dblAgosto|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblAgosto > 0 , 'text-negative': arts.dblAgosto === 0}">
                                                                                {{ (arts.dblMargenAgosto !== 0) ?
                                                                                (arts.dblMargenAgosto | number:'1.2-2')
                                                                                : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenAgosto < 20, 
                                                                                                     'bg-warning': arts.dblMargenAgosto >= 20 && arts.dblMargenAgosto < 26, 
                                                                                                     'bg-success': arts.dblMargenAgosto >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenAgosto">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblSeptiembre > 0 , 'text-negative': arts.dblSeptiembre === 0}">
                                                                                {{arts.dblSeptiembre|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblSeptiembre > 0 , 'text-negative': arts.dblSeptiembre === 0}">
                                                                                {{ (arts.dblMargenSeptiembre !== 0) ?
                                                                                (arts.dblMargenSeptiembre |
                                                                                number:'1.2-2') : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenSeptiembre < 20, 
                                                                                                     'bg-warning': arts.dblMargenSeptiembre >= 20 && arts.dblMargenSeptiembre < 26, 
                                                                                                     'bg-success': arts.dblMargenSeptiembre >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenSeptiembre">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblOctubre > 0 , 'text-negative': arts.dblOctubre === 0}">
                                                                                {{arts.dblOctubre|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblOctubre > 0 , 'text-negative': arts.dblOctubre === 0}">
                                                                                {{ (arts.dblMargenOctubre !== 0) ?
                                                                                (arts.dblMargenOctubre | number:'1.2-2')
                                                                                : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenOctubre < 20, 
                                                                                                     'bg-warning': arts.dblMargenOctubre >= 20 && arts.dblMargenOctubre < 26, 
                                                                                                     'bg-success': arts.dblMargenOctubre >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenOctubre">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblNoviembre > 0 , 'text-negative': arts.dblNoviembre === 0}">
                                                                                {{arts.dblNoviembre|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblNoviembre > 0 , 'text-negative': arts.dblNoviembre === 0}">
                                                                                {{ (arts.dblMargenNoviembre !== 0) ?
                                                                                (arts.dblMargenNoviembre |
                                                                                number:'1.2-2') : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenNoviembre < 20, 
                                                                                                   'bg-warning': arts.dblMargenNoviembre >= 20 && arts.dblMargenNoviembre < 26, 
                                                                                                   'bg-success': arts.dblMargenNoviembre >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenNoviembre">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblDiciembre > 0 , 'text-negative': arts.dblDiciembre === 0}">
                                                                                {{arts.dblDiciembre|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblDiciembre > 0 , 'text-negative': arts.dblDiciembre === 0}">
                                                                                {{ (arts.dblMargenDiciembre !== 0) ?
                                                                                (arts.dblMargenDiciembre |
                                                                                number:'1.2-2') : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenDiciembre < 20, 
                                                                                                     'bg-warning': arts.dblMargenDiciembre >= 20 && arts.dblMargenDiciembre < 26, 
                                                                                                     'bg-success': arts.dblMargenDiciembre >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenDiciembre">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.total > 0 , 'text-negative': arts.total === 0}">
                                                                                <b>{{arts.total|currency}}</b>
                                                                            </td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.totalMargen > 0 , 'text-negative': arts.totalMargen === 0}">
                                                                                {{ (arts.totalMargen !== 0) ?
                                                                                (arts.totalMargen | number:'1.2-2') : 0
                                                                                }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.totalMargen < 20, 
                                                                                                     'bg-warning': arts.totalMargen >= 20 && arts.totalMargen < 26, 
                                                                                                     'bg-success': arts.totalMargen >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.totalMargen">
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-margenLineasHist" role="tabpanel"
                                    aria-labelledby="pills-ctesLineasHist-tab">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="table-responsive shadow-sm ">
                                                <table class="table table-hover table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th class="fs12 bgTable_histVta">Ejercicio</th>
                                                            <th class="fs12 bgTable_histVta">Periodo</th>
                                                            <th class="fs12 bgTable_histVta">Accesorios</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Kimberly</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Rubbermaid</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">BlueTissue</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Quimicos</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Despachadores</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Tork</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Degrapack</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Foaom</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Dove</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Tapetes</th>
                                                            <th class="fs12 bgTable_histVta">Margen</th>
                                                            <th class="fs12 bgTable_histVta">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container
                                                            *ngFor="let x of arrayHistLineas|filtersearch:strBuscarCte:['strCliente','strNombre'] ;let ind = index">
                                                            <tr>
                                                                <td>
                                                                    <div class="d-flex align-items-center ">
                                                                        <div class="d-flex align-items-center">
                                                                            <span
                                                                                class="rounded bg-primary text-white d-flex align-items-center">
                                                                                <i class="bi "
                                                                                    [ngClass]="{'bi-dash-lg':x.seeCte == true,'bi-plus':x.seeCte == false}"
                                                                                    (click)="fnVerHistLineas(x)"></i>
                                                                            </span>
                                                                            <span
                                                                                class=" fs12 clsPlan ">{{x.strEjercicio}}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td></td>
                                                                <td class="fw-bold text-start clsPlan fs12 bgMargen">
                                                                    {{x.totalAcc|currency}}</td>
                                                                <td class="bgMargen"></td>
                                                                <td class="fw-bold text-start clsPlan fs12">
                                                                    {{x.totalKimb|currency}}</td>
                                                                <td></td>
                                                                <td class="fw-bold text-start clsPlan fs12 bgMargen">
                                                                    {{x.totalRubberMa|currency}}</td>
                                                                <td class="bgMargen"></td>
                                                                <td class="fw-bold text-start clsPlan fs12">
                                                                    {{x.totalBlueTisue|currency}}</td>
                                                                <td></td>
                                                                <td class="fw-bold text-start clsPlan fs12 bgMargen">
                                                                    {{x.totalQuimicos|currency}}</td>
                                                                <td class="bgMargen"></td>
                                                                <td class="fw-bold text-start clsPlan fs12">
                                                                    {{x.totalDespa|currency}}</td>
                                                                <td></td>
                                                                <td class="fw-bold text-start clsPlan fs12 bgMargen">
                                                                    {{x.totalTork|currency}}</td>
                                                                <td class="bgMargen"></td>
                                                                <td class="fw-bold text-start clsPlan fs12">
                                                                    {{x.totalDegrapack|currency}}</td>
                                                                <td></td>
                                                                <td class="fw-bold text-start clsPlan fs12 bgMargen">
                                                                    {{x.totalFoaom|currency}}</td>
                                                                <td class="bgMargen"></td>
                                                                <td class="fw-bold text-start clsPlan fs12">
                                                                    {{x.totalDove |currency}}</td>
                                                                <td></td>
                                                                <td class="fw-bold text-start clsPlan fs12 bgMargen">
                                                                    {{x.totalTapetes|currency}}</td>
                                                                <td class="bgMargen"></td>
                                                                <td class="fw-bold text-end clsPlan fs12">
                                                                    {{x.totalImporte|currency}}</td>
                                                            </tr>
                                                            <ng-container *ngIf="x.seeCte">
                                                                <tr *ngFor="let per of x.arrayPeriodo;let i = index">

                                                                    <td>
                                                                    </td>
                                                                    <td class="fw-bold fs11 ">{{per.strPeriodoNombre}}
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11 bgMargen">
                                                                        {{per.dblAccesorios|currency}}</td>
                                                                    <td
                                                                        class="text-center align-middle fs11 fw-bold bgMargen">
                                                                        {{(per.dblAccesoriosMargen !== 0) ?
                                                                        (per.dblAccesoriosMargen | number:'1.2-2') : 0
                                                                        }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblAccesoriosMargen<20,'bg-warning':per.dblAccesoriosMargen >=20 && per.dblAccesoriosMargen<26,'bg-success':per.dblAccesoriosMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblAccesoriosMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11">
                                                                        {{per.dblKimberly|currency}}</td>
                                                                    <td class="text-center align-middle fs11 fw-bold">
                                                                        {{(per.dblKimberlyMargen !== 0) ?
                                                                        (per.dblKimberlyMargen | number:'1.2-2') : 0 }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblKimberlyMargen<20,'bg-warning':per.dblKimberlyMargen >=20 && per.dblKimberlyMargen<26,'bg-success':per.dblKimberlyMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblKimberlyMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11 bgMargen">
                                                                        {{per.dblRubbermaid|currency}}</td>
                                                                    <td
                                                                        class="text-center align-middle fs11 fw-bold bgMargen">
                                                                        {{(per.dblRubbermaidMargen !== 0) ?
                                                                        (per.dblRubbermaidMargen | number:'1.2-2') : 0
                                                                        }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblRubbermaidMargen<20,'bg-warning':per.dblRubbermaidMargen >=20 && per.dblRubbermaidMargen<26,'bg-success':per.dblRubbermaidMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblRubbermaidMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11">
                                                                        {{per.dblBlueTissue|currency}}</td>
                                                                    <td class="text-center align-middle fs11 fw-bold">
                                                                        {{(per.dblBlueTissueMargen !== 0) ?
                                                                        (per.dblBlueTissueMargen | number:'1.2-2') : 0
                                                                        }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblBlueTissueMargen<20,'bg-warning':per.dblBlueTissueMargen >=20 && per.dblBlueTissueMargen<26,'bg-success':per.dblBlueTissueMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblBlueTissueMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11 bgMargen">
                                                                        {{per.dblQuimicos|currency}}</td>
                                                                    <td
                                                                        class="text-center align-middle fs11 fw-bold bgMargen">
                                                                        {{(per.dblQuimicosMargen !== 0) ?
                                                                        (per.dblQuimicosMargen | number:'1.2-2') : 0 }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblQuimicosMargen<20,'bg-warning':per.dblQuimicosMargen >=20 && per.dblQuimicosMargen<26,'bg-success':per.dblQuimicosMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblQuimicosMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11">
                                                                        {{per.dblDespachadores|currency}}</td>
                                                                    <td class="text-center align-middle fs11 fw-bold">
                                                                        {{(per.dblDespachadoresMargen !== 0) ?
                                                                        (per.dblDespachadoresMargen | number:'1.2-2') :
                                                                        0 }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblDespachadoresMargen<20,'bg-warning':per.dblDespachadoresMargen >=20 && per.dblDespachadoresMargen<26,'bg-success':per.dblDespachadoresMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblDespachadoresMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11 bgMargen">
                                                                        {{per.dblTork|currency}}</td>
                                                                    <td
                                                                        class="text-center align-middle fs11 fw-bold bgMargen">
                                                                        {{(per.dblTorkMargen !== 0) ? (per.dblTorkMargen
                                                                        | number:'1.2-2') : 0 }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblTorkMargen<20,'bg-warning':per.dblTorkMargen >=20 && per.dblTorkMargen<26,'bg-success':per.dblTorkMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblTorkMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11">
                                                                        {{per.dblDegrapack|currency}}</td>
                                                                    <td class="text-center align-middle fs11 fw-bold">
                                                                        {{(per.dblDegrapackMargen !== 0) ?
                                                                        (per.dblDegrapackMargen
                                                                        | number:'1.2-2') : 0 }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblDegrapackMargen<20,'bg-warning':per.dblDegrapackMargen >=20 && per.dblDegrapackMargen<26,'bg-success':per.dblDegrapackMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblDegrapackMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11 bgMargen">
                                                                        {{per.dblFoaom|currency}}</td>
                                                                    <td
                                                                        class="text-center align-middle fs11 fw-bold bgMargen">
                                                                        {{(per.dblFoaomMargen !== 0) ?
                                                                        (per.dblFoaomMargen | number:'1.2-2') : 0 }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblFoaomMargen<20,'bg-warning':per.dblFoaomMargen >=20 && per.dblFoaomMargen<26,'bg-success':per.dblFoaomMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblFoaomMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11">{{per.dblDove
                                                                        |currency}}</td>
                                                                    <td class="text-center align-middle fs11 fw-bold">
                                                                        {{(per.dblDoveMargen !== 0) ? (per.dblDoveMargen
                                                                        | number:'1.2-2') : 0 }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblDoveMargen<20,'bg-warning':per.dblDoveMargen >=20 && per.dblDoveMargen<26,'bg-success':per.dblDoveMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblDoveMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11 bgMargen">
                                                                        {{per.dblTapetes|currency}}</td>
                                                                    <td
                                                                        class="text-center align-middle fs11 fw-bold bgMargen">
                                                                        {{(per.dblTapetesMargen !== 0) ?
                                                                        (per.dblTapetesMargen | number:'1.2-2') : 0 }}%
                                                                        <div class="progress" style="height:6px">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                [ngClass]="{'bg-danger':per.dblTapetesMargen<20,'bg-warning':per.dblTapetesMargen >=20 && per.dblTapetesMargen<26,'bg-success':per.dblTapetesMargen>=26}"
                                                                                role="progressbar" aria-valuenow="75"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [style.width.%]="per.dblTapetesMargen">
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs11">
                                                                        {{per.total|currency}}</td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-container>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th class="fs12">Total</th>
                                                            <!-- class="fs12"  <th>Cliente</th> -->
                                                            <th class="fs12"></th>
                                                            <th class="fs12 bgMargen">
                                                                {{objHistMargen.Accesorios|currency}}</th>
                                                            <th class="fs12 bgMargen"></th>
                                                            <th class="fs12">{{objHistMargen.Kimberly|currency}}</th>
                                                            <th class="fs12"></th>
                                                            <th class="fs12 bgMargen">
                                                                {{objHistMargen.Rubbermaid|currency}}</th>
                                                            <th class="fs12 bgMargen"></th>
                                                            <th class="fs12">{{objHistMargen.BlueTissue|currency}}</th>
                                                            <th class="fs12"></th>
                                                            <th class="fs12 bgMargen">
                                                                {{objHistMargen.Quimicos|currency}}</th>
                                                            <th class="fs12 bgMargen"></th>
                                                            <th class="fs12">{{objHistMargen.Despachadores|currency}}
                                                            </th>
                                                            <th class="fs12"></th>
                                                            <th class="fs12 bgMargen">{{objHistMargen.Tork|currency}}
                                                            </th>
                                                            <th class="fs12 bgMargen"></th>
                                                            <th class="fs12">{{objHistMargen.Degrapack|currency}}</th>
                                                            <th class="fs12"></th>
                                                            <th class="fs12 bgMargen">{{objHistMargen.Foaom|currency}}
                                                            </th>
                                                            <th class="fs12 bgMargen"></th>
                                                            <th class="fs12">{{objHistMargen.Dove|currency}}</th>
                                                            <th class="fs12"></th>
                                                            <th class="fs12 bgMargen">{{objHistMargen.Tapetes|currency}}
                                                            </th>
                                                            <th class="fs12 bgMargen"></th>
                                                            <th class="fs12">{{objHistMargen.total|currency}}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>




                                <div class="tab-pane fade show active" id="pills-SucLineasHist" role="tabpanel"
                                    aria-labelledby="pills-SucLineasHist-tab">
                                    <div class="row mb-4">

                                        <div class="col-6 pb-1 d-flex " *ngIf="!spinerBool">
                                            <mat-form-field appearance="outline" class="rounded bg-white">
                                                <mat-label>Buscar</mat-label>
                                                <input matInput [(ngModel)]="strBuscarHistVta"
                                                    placeholder="Articulo, Descripcion, unidad, etcc."
                                                    [disabled]=" boolSuc">
                                                <mat-icon matSuffix>search</mat-icon>
                                            </mat-form-field>

                                        </div>
                                        <div class="col-3 pb-1 d-flex " *ngIf="!spinerBool">
                                            <select class="form-select w-30" aria-label="Default select example"
                                                [(ngModel)]="intEjercicio">
                                                <option *ngFor="let x of arrayEjercicio">
                                                    {{x.ejercicio}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-3 pb-1 d-flex " *ngIf="!spinerBool">
                                            <button type="button" class="btn btn-primary"
                                                (click)="fnFiltrarHistorico_vta()"><i
                                                    class="fa-solid fa-filter"></i></button>
                                        </div>
                                        <div class="col-12 ">
                                            <div class="col-12 d-flex justify-content-center my-4" *ngIf="spinerBool">
                                                <mat-spinner></mat-spinner>
                                            </div>
                                            <div class="table-responsive mb-0" *ngIf="!spinerBool">
                                                <table class="table table-hover ">
                                                    <thead>
                                                        <tr class="sticky-top bg-light">
                                                            <th class="bgTable_histVta sticky-col"></th>
                                                            <th class="text-center bgTable_histVta sticky-col2">Sucursal
                                                            </th>
                                                            <th class="text-center bgTable_histVta">Enero</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Febrero</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Marzo</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Abril</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Mayo</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Junio</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Julio</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Agosto</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Septiembre</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Octubre</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Noviembre</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Diciembre</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                            <th class="text-center bgTable_histVta">Total</th>
                                                            <th class="text-center bgTable_histVta">%</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container
                                                            *ngFor="let ob of arrayHistSursal|filtersearch: strBuscarHistVta : ['strNombreCliente','strCliente'];let ind = index"><!-- |filtersearch: strSucursalHistVta : ['strSucursal']  -->
                                                            <tr>
                                                                <td class=" sticky-col">
                                                                    <span class="rounded bg-primary text-white">
                                                                        <i class="bi "
                                                                            [ngClass]="{'bi-dash-lg':ob.seeLineas == true,'bi-plus':ob.seeLineas == false}"
                                                                            (click)="fnCambiarEstatusVistaSucursal(ind,ob)"></i>
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    class="text-start border-end align-middle sticky-col2">
                                                                    <div class="d-flex">
                                                                        <span class="fs14 fw-bold">
                                                                            <!--     <a (click)="enviarDetalleCliente(ob)"> -->
                                                                            <!-- <b>({{ob.strCliente}})
                                                                            </b> --><b> {{ob.strCliente}}</b>
                                                                            <!-- </a> -->
                                                                        </span>
                                                                        <span
                                                                            class="ms-auto fw-bold text-secondary fs13">
                                                                            {{ob.strNombreEmpresa}}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold "
                                                                    [ngClass]="{'clsPlan':ob.sumEnero>0,'clsInact':ob.sumEnero==0}">
                                                                    {{ob.sumEnero|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenEnero !== 0) ? (ob.dblMargenEnero |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenEnero < 20, 
                                                                                       'bg-warning': ob.dblMargenEnero >= 20 && ob.dblMargenEnero < 26, 
                                                                                       'bg-success': ob.dblMargenEnero >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenEnero">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumFebrero>0,'clsInact':ob.sumFebrero==0}">
                                                                    {{ob.sumFebrero|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenFebrero !== 0) ?
                                                                    (ob.dblMargenFebrero | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenFebrero < 20, 
                                                                                             'bg-warning': ob.dblMargenFebrero >= 20 && ob.dblMargenFebrero < 26, 
                                                                                             'bg-success': ob.dblMargenFebrero >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenFebrero">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumMarzo>0,'clsInact':ob.sumMarzo==0}">
                                                                    {{ob.sumMarzo|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenMarzo !== 0) ? (ob.dblMargenMarzo |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenMarzo < 20, 
                                                                                             'bg-warning': ob.dblMargenMarzo >= 20 && ob.dblMargenMarzo < 26, 
                                                                                             'bg-success': ob.dblMargenMarzo >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenMarzo">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumAbril>0,'clsInact':ob.sumAbril==0}">
                                                                    {{ob.sumAbril|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenAbril !== 0) ? (ob.dblMargenAbril |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenAbril < 20, 
                                                                                             'bg-warning': ob.dblMargenAbril >= 20 && ob.dblMargenAbril < 26, 
                                                                                             'bg-success': ob.dblMargenAbril >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenAbril">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumMayo>0,'clsInact':ob.sumMayo==0}">
                                                                    {{ob.sumMayo|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenMayo !== 0) ? (ob.dblMargenMayo |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenMayo < 20, 
                                                                                           'bg-warning': ob.dblMargenMayo >= 20 && ob.dblMargenMayo < 26, 
                                                                                           'bg-success': ob.dblMargenMayo >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenMayo">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumJunio>0,'clsInact':ob.sumJunio==0}">
                                                                    {{ob.sumJunio|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenJunio !== 0) ? (ob.dblMargenJunio |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenJunio < 20, 
                                                                                             'bg-warning': ob.dblMargenJunio >= 20 && ob.dblMargenJunio < 26, 
                                                                                             'bg-success': ob.dblMargenJunio >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenJunio">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumJulio>0,'clsInact':ob.sumJulio==0}">
                                                                    {{ob.sumJulio|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenJulio !== 0) ? (ob.dblMargenJulio |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenJulio < 20, 
                                                                                             'bg-warning': ob.dblMargenJulio >= 20 && ob.dblMargenJulio < 26, 
                                                                                             'bg-success': ob.dblMargenJulio >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenJulio">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumAgosto>0,'clsInact':ob.sumAgosto==0}">
                                                                    {{ob.sumAgosto|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenAgosto !== 0) ? (ob.dblMargenAgosto
                                                                    | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenAgosto < 20, 
                                                                                           'bg-warning': ob.dblMargenAgosto >= 20 && ob.dblMargenAgosto < 26, 
                                                                                           'bg-success': ob.dblMargenAgosto >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenAgosto">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumSeptiembre>0,'clsInact':ob.sumSeptiembre==0}">
                                                                    {{ob.sumSeptiembre|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenSeptiembre !== 0) ?
                                                                    (ob.dblMargenSeptiembre | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenSeptiembre < 20, 
                                                                                             'bg-warning': ob.dblMargenSeptiembre >= 20 && ob.dblMargenSeptiembre < 26, 
                                                                                             'bg-success': ob.dblMargenSeptiembre >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenSeptiembre">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumOctubre>0,'clsInact':ob.sumOctubre==0}">
                                                                    {{ob.sumOctubre|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenOctubre !== 0) ?
                                                                    (ob.dblMargenOctubre | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenOctubre < 20, 
                                                                                             'bg-warning': ob.dblMargenOctubre >= 20 && ob.dblMargenOctubre < 26, 
                                                                                             'bg-success': ob.dblMargenOctubre >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenOctubre">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumNoviembre>0,'clsInact':ob.sumNoviembre==0}">
                                                                    {{ob.sumNoviembre|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenNoviembre !== 0) ?
                                                                    (ob.dblMargenNoviembre | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenNoviembre < 20, 
                                                                                             'bg-warning': ob.dblMargenNoviembre >= 20 && ob.dblMargenNoviembre < 26, 
                                                                                             'bg-success': ob.dblMargenNoviembre >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenNoviembre">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.sumDiciembre>0,'clsInact':ob.sumDiciembre==0}">
                                                                    {{ob.sumDiciembre|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.dblMargenDiciembre !== 0) ?
                                                                    (ob.dblMargenDiciembre | number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.dblMargenDiciembre < 20, 
                                                                                             'bg-warning': ob.dblMargenDiciembre >= 20 && ob.dblMargenDiciembre < 26, 
                                                                                             'bg-success': ob.dblMargenDiciembre >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.dblMargenDiciembre">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end border-end fs13 fw-bold"
                                                                    [ngClass]="{'clsPlan':ob.total>0,'clsInact':ob.total==0}">
                                                                    {{ob.total|currency}}</td>
                                                                <td class="text-end border-end fs13 fw-bold ">
                                                                    {{ (ob.totalMargen !== 0) ? (ob.totalMargen |
                                                                    number:'1.2-2') : 0 }}%
                                                                    <div class="progress" style="height:6px">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                            [ngClass]="{'bg-danger': ob.totalMargen < 20, 
                                                                                             'bg-warning': ob.totalMargen >= 20 && ob.totalMargen < 26, 
                                                                                             'bg-success': ob.totalMargen >= 26}"
                                                                            role="progressbar" aria-valuenow="75"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [style.width.%]="ob.totalMargen">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <ng-container *ngIf="ob.seeLineas == true ">
                                                                <ng-container *ngFor="let lin of ob.arrayLineas">
                                                                    <tr>
                                                                        <td class="sticky-col">
                                                                        </td>
                                                                        <td
                                                                            class="text-start fs12  border-end sticky-col2">
                                                                            <span
                                                                                class="rounded bg-warning text-white me-3">
                                                                                <i class="bi "
                                                                                    [ngClass]="{'bi-dash-lg':lin.seeArts == true,'bi-plus':lin.seeArts == false}"
                                                                                    (click)="fnCambiarEstatusVistaLineasSucursal(ind,lin,ob)"></i>
                                                                            </span>
                                                                            <span
                                                                                class="fs14 fw-bold">{{lin.strLinea}}</span>
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumEnero>0,'clsInact':lin.sumEnero==0}">
                                                                            {{lin.sumEnero|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumEnero>0,'clsInact':lin.sumEnero==0}">
                                                                            {{ (lin.dblMargenEnero !== 0) ?
                                                                            (lin.dblMargenEnero | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenEnero < 20, 
                                                                                                 'bg-warning': lin.dblMargenEnero >= 20 && lin.dblMargenEnero < 26, 
                                                                                                 'bg-success': lin.dblMargenEnero >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenEnero">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumFebrero>0,'clsInact':lin.sumFebrero==0}">
                                                                            {{lin.sumFebrero|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumFebrero>0,'clsInact':lin.sumFebrero==0}">
                                                                            {{ (lin.dblMargenFebrero !== 0) ?
                                                                            (lin.dblMargenFebrero | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenFebrero < 20, 
                                                                                                 'bg-warning': lin.dblMargenFebrero >= 20 && lin.dblMargenFebrero < 26, 
                                                                                                 'bg-success': lin.dblMargenFebrero >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenFebrero">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumMarzo>0,'clsInact':lin.sumMarzo==0}">
                                                                            {{lin.sumMarzo|currency}}</td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumMarzo>0,'clsInact':lin.sumMarzo==0}">
                                                                            {{ (lin.dblMargenMarzo !== 0) ?
                                                                            (lin.dblMargenMarzo | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenMarzo < 20, 
                                                                                                 'bg-warning': lin.dblMargenMarzo >= 20 && lin.dblMargenMarzo < 26, 
                                                                                                 'bg-success': lin.dblMargenMarzo >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenMarzo">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumAbril>0,'clsInact':lin.sumAbril==0}">
                                                                            {{lin.sumAbril|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumAbril>0,'clsInact':lin.sumAbril==0}">
                                                                            {{ (lin.dblMargenAbril !== 0) ?
                                                                            (lin.dblMargenAbril | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenAbril < 20, 
                                                                                               'bg-warning': lin.dblMargenAbril >= 20 && lin.dblMargenAbril < 26, 
                                                                                               'bg-success': lin.dblMargenAbril >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenAbril">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumMayo>0,'clsInact':lin.sumMayo==0}">
                                                                            {{lin.sumMayo|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumMayo>0,'clsInact':lin.sumMayo==0}">
                                                                            {{ (lin.dblMargenMayo !== 0) ?
                                                                            (lin.dblMargenMayo | number:'1.2-2') : 0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenMayo < 20, 
                                                                                                 'bg-warning': lin.dblMargenMayo >= 20 && lin.dblMargenMayo < 26, 
                                                                                                 'bg-success': lin.dblMargenMayo >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenMayo">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumJunio>0,'clsInact':lin.sumJunio==0}">
                                                                            {{lin.sumJunio|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumJunio>0,'clsInact':lin.sumJunio==0}">
                                                                            {{ (lin.dblMargenJunio !== 0) ?
                                                                            (lin.dblMargenJunio | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenJunio < 20, 
                                                                                                 'bg-warning': lin.dblMargenJunio >= 20 && lin.dblMargenJunio < 26, 
                                                                                                 'bg-success': lin.dblMargenJunio >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenJunio">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumJulio>0,'clsInact':lin.sumJulio==0}">
                                                                            {{lin.sumJulio|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumJulio>0,'clsInact':lin.sumJulio==0}">
                                                                            {{ (lin.dblMargenJulio !== 0) ?
                                                                            (lin.dblMargenJulio | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenJulio < 20, 
                                                                                                 'bg-warning': lin.dblMargenJulio >= 20 && lin.dblMargenJulio < 26, 
                                                                                                 'bg-success': lin.dblMargenJulio >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenJulio">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumAgosto>0,'clsInact':lin.sumAgosto==0}">
                                                                            {{lin.sumAgosto|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumAgosto>0,'clsInact':lin.sumAgosto==0}">
                                                                            {{ (lin.dblMargenAgosto !== 0) ?
                                                                            (lin.dblMargenAgosto | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenAgosto < 20, 
                                                                                                 'bg-warning': lin.dblMargenAgosto >= 20 && lin.dblMargenAgosto < 26, 
                                                                                                 'bg-success': lin.dblMargenAgosto >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenAgosto">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumSeptiembre>0,'clsInact':lin.sumSeptiembre==0}">
                                                                            {{lin.sumSeptiembre|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumSeptiembre>0,'clsInact':lin.sumSeptiembre==0}">
                                                                            {{ (lin.dblMargenSeptiembre !== 0) ?
                                                                            (lin.dblMargenSeptiembre | number:'1.2-2') :
                                                                            0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenSeptiembre < 20, 
                                                                                               'bg-warning': lin.dblMargenSeptiembre >= 20 && lin.dblMargenSeptiembre < 26, 
                                                                                               'bg-success': lin.dblMargenSeptiembre >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenSeptiembre">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumOctubre>0,'clsInact':lin.sumOctubre==0}">
                                                                            {{lin.sumOctubre|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumOctubre>0,'clsInact':lin.sumOctubre==0}">
                                                                            {{ (lin.dblMargenOctubre !== 0) ?
                                                                            (lin.dblMargenOctubre | number:'1.2-2') : 0
                                                                            }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenOctubre < 20, 
                                                                                                 'bg-warning': lin.dblMargenOctubre >= 20 && lin.dblMargenOctubre < 26, 
                                                                                                 'bg-success': lin.dblMargenOctubre >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenOctubre">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumNoviembre>0,'clsInact':lin.sumNoviembre==0}">
                                                                            {{lin.sumNoviembre|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumNoviembre>0,'clsInact':lin.sumNoviembre==0}">
                                                                            {{ (lin.dblMargenNoviembre !== 0) ?
                                                                            (lin.dblMargenNoviembre | number:'1.2-2') :
                                                                            0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenNoviembre < 20, 
                                                                                               'bg-warning': lin.dblMargenNoviembre >= 20 && lin.dblMargenNoviembre < 26, 
                                                                                               'bg-success': lin.dblMargenNoviembre >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenNoviembre">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumDiciembre>0,'clsInact':lin.sumDiciembre==0}">
                                                                            {{lin.sumDiciembre|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.sumDiciembre>0,'clsInact':lin.sumDiciembre==0}">
                                                                            {{ (lin.dblMargenDiciembre !== 0) ?
                                                                            (lin.dblMargenDiciembre | number:'1.2-2') :
                                                                            0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.dblMargenDiciembre < 20, 
                                                                                                 'bg-warning': lin.dblMargenDiciembre >= 20 && lin.dblMargenDiciembre < 26, 
                                                                                                 'bg-success': lin.dblMargenDiciembre >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.dblMargenDiciembre">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.total>0,'clsInact':lin.total==0}">
                                                                            {{lin.total|currency}}
                                                                        </td>
                                                                        <td class="text-end border-end fs12 fw-bold clsPolitica1"
                                                                            [ngClass]="{'clsPolitica1':lin.totalMargen>0,'clsInact':lin.totalMargen==0}">
                                                                            {{ (lin.totalMargen !== 0) ?
                                                                            (lin.totalMargen | number:'1.2-2') : 0 }}%
                                                                            <div class="progress" style="height:6px">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                    [ngClass]="{'bg-danger': lin.totalMargen < 20, 
                                                                                                 'bg-warning': lin.totalMargen >= 20 && lin.totalMargen < 26, 
                                                                                                 'bg-success': lin.totalMargen >= 26}"
                                                                                    role="progressbar"
                                                                                    aria-valuenow="75" aria-valuemin="0"
                                                                                    aria-valuemax="100"
                                                                                    [style.width.%]="lin.totalMargen">
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                    <ng-container *ngIf="lin.seeArts == true ">
                                                                        <tr *ngFor="let arts of lin.arrayArticulos"
                                                                            class="bg-light">
                                                                            <td colspan="2"
                                                                                class="text-end fs12  border-end fw-bold me-2 sticky-col"
                                                                                matTooltip="{{arts.strDescripcion}}">
                                                                                {{arts.strArticulo}}
                                                                                <span class="text-secondary">
                                                                                    {{arts.strDescripcion}}
                                                                                </span>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblEnero > 0 , 'text-negative': arts.dblEnero === 0}">
                                                                                {{arts.dblEnero|currency}}
                                                                            </td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblEnero > 0 , 'text-negative': arts.dblEnero === 0}">
                                                                                {{(arts.dblMargenEnero !== 0)
                                                                                ?(arts.dblMargenEnero |number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger':arts.dblMargenEnero<20,'bg-warning':arts.dblMargenEnero >=20 && arts.dblMargenEnero<26,'bg-success':arts.dblMargenEnero>=26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenEnero">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblFebrero > 0 , 'text-negative': arts.dblFebrero === 0}">
                                                                                {{arts.dblFebrero|currency}}
                                                                            </td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblFebrero > 0 , 'text-negative': arts.dblFebrero === 0}">
                                                                                {{ (arts.dblMargenFebrero !== 0) ?
                                                                                (arts.dblMargenFebrero | number:'1.2-2')
                                                                                : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenFebrero < 20, 
                                                                                                     'bg-warning': arts.dblMargenFebrero >= 20 && arts.dblMargenFebrero < 26, 
                                                                                                     'bg-success': arts.dblMargenFebrero >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenFebrero">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblMarzo > 0 , 'text-negative': arts.dblMarzo === 0}">
                                                                                {{arts.dblMarzo|currency}}
                                                                            </td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblMarzo > 0 , 'text-negative': arts.dblMarzo === 0}">
                                                                                {{ (arts.dblMargenMarzo !== 0) ?
                                                                                (arts.dblMargenMarzo | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenMarzo < 20, 
                                                                                                     'bg-warning': arts.dblMargenMarzo >= 20 && arts.dblMargenMarzo < 26, 
                                                                                                     'bg-success': arts.dblMargenMarzo >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenMarzo">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblAbril > 0 , 'text-negative': arts.dblAbril === 0}">
                                                                                {{arts.dblAbril|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblAbril > 0 , 'text-negative': arts.dblAbril === 0}">
                                                                                {{ (arts.dblMargenAbril !== 0) ?
                                                                                (arts.dblMargenAbril | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenAbril < 20, 
                                                                                                     'bg-warning': arts.dblMargenAbril >= 20 && arts.dblMargenAbril < 26, 
                                                                                                     'bg-success': arts.dblMargenAbril >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenAbril">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblMayo > 0 , 'text-negative': arts.dblMayo === 0}">
                                                                                {{arts.dblMayo|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblMayo > 0 , 'text-negative': arts.dblMayo === 0}">
                                                                                {{ (arts.dblMargenMayo !== 0) ?
                                                                                (arts.dblMargenMayo | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenMayo < 20, 
                                                                                                     'bg-warning': arts.dblMargenMayo >= 20 && arts.dblMargenMayo < 26, 
                                                                                                     'bg-success': arts.dblMargenMayo >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenMayo">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblJunio > 0 , 'text-negative': arts.dblJunio === 0}">
                                                                                {{arts.dblJunio|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblJunio > 0 , 'text-negative': arts.dblJunio === 0}">
                                                                                {{ (arts.dblMargenJunio !== 0) ?
                                                                                (arts.dblMargenJunio | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenJunio < 20, 
                                                                                                   'bg-warning': arts.dblMargenJunio >= 20 && arts.dblMargenJunio < 26, 
                                                                                                   'bg-success': arts.dblMargenJunio >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenJunio">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblJulio > 0 , 'text-negative': arts.dblJulio === 0}">
                                                                                {{arts.dblJulio|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblJulio > 0 , 'text-negative': arts.dblJulio === 0}">
                                                                                {{ (arts.dblMargenJulio !== 0) ?
                                                                                (arts.dblMargenJulio | number:'1.2-2') :
                                                                                0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenJulio < 20, 
                                                                                                     'bg-warning': arts.dblMargenJulio >= 20 && arts.dblMargenJulio < 26, 
                                                                                                     'bg-success': arts.dblMargenJulio >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenJulio">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblAgosto > 0 , 'text-negative': arts.dblAgosto === 0}">
                                                                                {{arts.dblAgosto|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblAgosto > 0 , 'text-negative': arts.dblAgosto === 0}">
                                                                                {{ (arts.dblMargenAgosto !== 0) ?
                                                                                (arts.dblMargenAgosto | number:'1.2-2')
                                                                                : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenAgosto < 20, 
                                                                                                     'bg-warning': arts.dblMargenAgosto >= 20 && arts.dblMargenAgosto < 26, 
                                                                                                     'bg-success': arts.dblMargenAgosto >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenAgosto">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblSeptiembre > 0 , 'text-negative': arts.dblSeptiembre === 0}">
                                                                                {{arts.dblSeptiembre|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblSeptiembre > 0 , 'text-negative': arts.dblSeptiembre === 0}">
                                                                                {{ (arts.dblMargenSeptiembre !== 0) ?
                                                                                (arts.dblMargenSeptiembre |
                                                                                number:'1.2-2') : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenSeptiembre < 20, 
                                                                                                     'bg-warning': arts.dblMargenSeptiembre >= 20 && arts.dblMargenSeptiembre < 26, 
                                                                                                     'bg-success': arts.dblMargenSeptiembre >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenSeptiembre">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblOctubre > 0 , 'text-negative': arts.dblOctubre === 0}">
                                                                                {{arts.dblOctubre|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblOctubre > 0 , 'text-negative': arts.dblOctubre === 0}">
                                                                                {{ (arts.dblMargenOctubre !== 0) ?
                                                                                (arts.dblMargenOctubre | number:'1.2-2')
                                                                                : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenOctubre < 20, 
                                                                                                     'bg-warning': arts.dblMargenOctubre >= 20 && arts.dblMargenOctubre < 26, 
                                                                                                     'bg-success': arts.dblMargenOctubre >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenOctubre">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblNoviembre > 0 , 'text-negative': arts.dblNoviembre === 0}">
                                                                                {{arts.dblNoviembre|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblNoviembre > 0 , 'text-negative': arts.dblNoviembre === 0}">
                                                                                {{ (arts.dblMargenNoviembre !== 0) ?
                                                                                (arts.dblMargenNoviembre |
                                                                                number:'1.2-2') : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenNoviembre < 20, 
                                                                                                   'bg-warning': arts.dblMargenNoviembre >= 20 && arts.dblMargenNoviembre < 26, 
                                                                                                   'bg-success': arts.dblMargenNoviembre >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenNoviembre">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblDiciembre > 0 , 'text-negative': arts.dblDiciembre === 0}">
                                                                                {{arts.dblDiciembre|currency}}</td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.dblDiciembre > 0 , 'text-negative': arts.dblDiciembre === 0}">
                                                                                {{ (arts.dblMargenDiciembre !== 0) ?
                                                                                (arts.dblMargenDiciembre |
                                                                                number:'1.2-2') : 0 }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.dblMargenDiciembre < 20, 
                                                                                                     'bg-warning': arts.dblMargenDiciembre >= 20 && arts.dblMargenDiciembre < 26, 
                                                                                                     'bg-success': arts.dblMargenDiciembre >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.dblMargenDiciembre">
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-end border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.total > 0 , 'text-negative': arts.total === 0}">
                                                                                <b>{{arts.total|currency}}</b>
                                                                            </td>
                                                                            <td class="text-center border-end fs12"
                                                                                [ngClass]="{'text-positive': arts.totalMargen > 0 , 'text-negative': arts.totalMargen === 0}">
                                                                                {{ (arts.totalMargen !== 0) ?
                                                                                (arts.totalMargen | number:'1.2-2') : 0
                                                                                }}%
                                                                                <div class="progress"
                                                                                    style="height:6px">
                                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                                        [ngClass]="{'bg-danger': arts.totalMargen < 20, 
                                                                                                     'bg-warning': arts.totalMargen >= 20 && arts.totalMargen < 26, 
                                                                                                     'bg-success': arts.totalMargen >= 26}"
                                                                                        role="progressbar"
                                                                                        aria-valuenow="75"
                                                                                        aria-valuemin="0"
                                                                                        aria-valuemax="100"
                                                                                        [style.width.%]="arts.totalMargen">
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>

                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th class="sticky-col"></th>
                                                            <th class="text-center sticky-col2">Total</th>
                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblEnero|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenEnero !== 0) ?
                                                                (objHistCliente.dblMargenEnero | number:'1.2-2') : 0 }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenEnero < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenEnero >= 20 && objHistCliente.dblMargenEnero < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenEnero >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenEnero">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblFebrero|currency}}
                                                            </th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenFebrero !== 0) ?
                                                                (objHistCliente.dblMargenFebrero | number:'1.2-2') : 0
                                                                }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenFebrero < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenFebrero >= 20 && objHistCliente.dblMargenFebrero < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenFebrero >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenFebrero">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblMarzo|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenMarzo !== 0) ?
                                                                (objHistCliente.dblMargenMarzo | number:'1.2-2') : 0 }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenMarzo < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenMarzo >= 20 && objHistCliente.dblMargenMarzo < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenMarzo >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenMarzo">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblAbril|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenAbril !== 0) ?
                                                                (objHistCliente.dblMargenAbril | number:'1.2-2') : 0 }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenAbril < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenAbril >= 20 && objHistCliente.dblMargenAbril < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenAbril >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenAbril">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblMayo|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenMayo !== 0) ?
                                                                (objHistCliente.dblMargenMayo | number:'1.2-2') : 0 }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenMayo < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenMayo >= 20 && objHistCliente.dblMargenMayo < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenMayo >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenMayo">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblJunio|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenJunio !== 0) ?
                                                                (objHistCliente.dblMargenJunio | number:'1.2-2') : 0 }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenJunio < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenJunio >= 20 && objHistCliente.dblMargenJunio < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenJunio >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenJunio">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblJulio|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenJulio !== 0) ?
                                                                (objHistCliente.dblMargenJulio | number:'1.2-2') : 0 }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenJulio < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenJulio >= 20 && objHistCliente.dblMargenJulio < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenJulio >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenJulio">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblAgosto|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenAgosto !== 0) ?
                                                                (objHistCliente.dblMargenAgosto | number:'1.2-2') : 0
                                                                }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenAgosto < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenAgosto >= 20 && objHistCliente.dblMargenAgosto < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenAgosto >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenAgosto">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblSeptiembre|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenSeptiembre !== 0) ?
                                                                (objHistCliente.dblMargenSeptiembre | number:'1.2-2') :
                                                                0 }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenSeptiembre < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenSeptiembre >= 20 && objHistCliente.dblMargenSeptiembre < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenSeptiembre >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenSeptiembre">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblOctubre|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenOctubre !== 0) ?
                                                                (objHistCliente.dblMargenOctubre | number:'1.2-2') : 0
                                                                }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenOctubre < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenOctubre >= 20 && objHistCliente.dblMargenOctubre < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenOctubre >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenOctubre">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblNoviembre|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenNoviembre !== 0) ?
                                                                (objHistCliente.dblMargenNoviembre | number:'1.2-2') : 0
                                                                }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenNoviembre < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenNoviembre >= 20 && objHistCliente.dblMargenNoviembre < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenNoviembre >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenNoviembre">
                                                                    </div>
                                                                </div>
                                                            </th>

                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblDiciembre|currency}}</th>
                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblMargenDiciembre !== 0) ?
                                                                (objHistCliente.dblMargenDiciembre | number:'1.2-2') : 0
                                                                }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblMargenDiciembre < 20, 
                                                                                     'bg-warning': objHistCliente.dblMargenDiciembre >= 20 && objHistCliente.dblMargenDiciembre < 26, 
                                                                                     'bg-success': objHistCliente.dblMargenDiciembre >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblMargenDiciembre">
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th class="text-center fw-bold">
                                                                {{objHistCliente?.dblTotal|currency}}</th>

                                                            <th class="text-center fw-bold">
                                                                {{ (objHistCliente.dblTotalMargen !== 0) ?
                                                                (objHistCliente.dblTotalMargen | number:'1.2-2') : 0 }}%
                                                                <div class="progress" style="height:6px">
                                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                                        [ngClass]="{'bg-danger': objHistCliente.dblTotalMargen < 20, 
                                                                                         'bg-warning': objHistCliente.dblTotalMargen >= 20 && objHistCliente.dblTotalMargen < 26, 
                                                                                         'bg-success': objHistCliente.dblTotalMargen >= 26}"
                                                                        role="progressbar" aria-valuenow="75"
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                        [style.width.%]="objHistCliente.dblTotalMargen">
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- flujo cliente -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-cash-coin iconoTabs mx-1"></i> <span class="m-auto">Flujo Cliente</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mb-4 pb-4">
                            <div class="col-12 d-flex justify-content-between py-3">
                                <mat-form-field appearance="outline" class="rounded bg-white">
                                    <mat-label>Buscar</mat-label>
                                    <input matInput [(ngModel)]="searchFlujoCte"
                                        placeholder="cliente, Nombre Cliente, etc..">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <button
                                    class="btn border border-danger bg-white py-0  px-2 text-danger d-flex align-items-center shadow-sm"
                                    (click)="fncreatepdf()">
                                    <i class="bi bi-file-earmark-pdf fs-5 text-danger"></i> <span class="fs13">Descargar
                                        PDF</span>
                                </button>
                            </div>
                            <div class="col-12 ">
                                <div class="col-12 d-flex justify-content-center my-4" *ngIf="spinerBool">
                                    <mat-spinner></mat-spinner>
                                </div>
                                <div class="table-responsive mb-0" *ngIf="!spinerBool">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr class="sticky-top bg-light">
                                                <th class="bgTable_edo_cta"></th>
                                                <th class="bgTable_edo_cta">Cliente</th>
                                                <th class="bgTable_edo_cta">Importe</th>
                                                <th class="bgTable_edo_cta">Cobrado</th>
                                                <th class="bgTable_edo_cta">Saldo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let ob of arrayFlujoClienteFiltrado|filtersearch: searchFlujoCte : ['strNombreCliente','strCliente'];let ind = index">
                                                <tr>
                                                    <td>
                                                        <span class="rounded bg-primary text-white">
                                                            <i class="bi "
                                                                [ngClass]="{'bi-dash-lg':ob.seeLineas == true,'bi-plus':ob.seeLineas == false}"
                                                                (click)="fnCambiarEstatusVistaFlujoCte(ind,ob)"></i>
                                                        </span>
                                                    </td>
                                                    <td class="text-start border-end">
                                                        <span class="fs14 fw-bold">{{ob.strNombreCliente}}</span>
                                                    </td>
                                                    <td class="text-end border-end fs13 fw-bold clsPlan">
                                                        {{ob.sumImporte|currency}}</td>
                                                    <td class="text-end border-end fs13 fw-bold clsPlan">
                                                        {{ob.sumCobrado|currency}}</td>
                                                    <td class="text-end border-end fs13 fw-bold clsPlan">
                                                        {{ob.sumSaldo|currency}}</td>
                                                </tr>
                                                <ng-container *ngIf="ob.seeLineas == true ">
                                                    <ng-container *ngFor="let lin of ob.arrayEjercicio">
                                                        <tr>
                                                            <td>
                                                            </td>
                                                            <td class="text-start fs12  border-end">
                                                                <span class="rounded bg-warning text-white me-3">
                                                                    <i class="bi "
                                                                        [ngClass]="{'bi-dash-lg':lin.seePer == true,'bi-plus':lin.seePer == false}"
                                                                        (click)="fnCambiarEstatusVistaLineasCteFlujo(ind,lin,ob)"></i>
                                                                </span>
                                                                <span class="fs14 fw-bold">{{lin.intEjercicio}}</span>
                                                            </td>
                                                            <td class="text-end border-end fs12 fw-bold clsPolitica1">
                                                                {{lin.sumImporte|currency}}</td>
                                                            <td class="text-end border-end fs12 fw-bold clsPolitica1">
                                                                {{lin.sumCobrado|currency}}</td>
                                                            <td class="text-end border-end fs12 fw-bold clsPolitica1">
                                                                {{lin.sumSaldo|currency}}</td>
                                                        </tr>
                                                        <ng-container *ngIf="lin.seePer == true ">
                                                            <tr *ngFor="let arts of lin.arrayPeriodo" class="bg-light">
                                                                <td colspan="2"
                                                                    class="text-end fs12  border-end fw-bold me-2">
                                                                    {{arts.intPeriodo=='1'?'Enero':arts.intPeriodo=='2'?'Febrero':arts.intPeriodo=='3'?'Marzo':arts.intPeriodo=='4'?'Abril':arts.intPeriodo=='5'?'Mayo':arts.intPeriodo=='6'?'Junio':arts.intPeriodo=='7'?'Julio':arts.intPeriodo=='8'?'Agosto':arts.intPeriodo=='9'?'Septiembre':arts.intPeriodo=='10'?'Octubre':arts.intPeriodo=='11'?'Noviembre':'Diciembre'}}
                                                                </td>
                                                                <td class="text-end border-end fs12">
                                                                    {{arts.dblImporte|currency}}</td>
                                                                <td class="text-end border-end fs12">
                                                                    {{arts.dblCobrado|currency}}</td>
                                                                <td class="text-end border-end fs12">
                                                                    {{arts.dblSaldo|currency}}</td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- Anexos -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-file-earmark-plus mx-1 iconoTabs"></i> <span
                                    class="m-auto">Anexos</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-3 mb-3">

                            <div class="col-2 d-flex">
                                <button class="btn btn-warning m-auto text-white" data-bs-toggle="modal"
                                    data-bs-target="#nuevaCarpeta"><i class="fas fa-plus mx-2"></i>Nueva
                                    Carpeta</button>
                            </div>
                            <div class="col-2">

                            </div>
                            <div class="col-2">
                                <a href="https://www.ilovepdf.com/es/comprimir_pdf" target="_blank"
                                    rel="noopener noreferrer"
                                    class="btn btn-warning fs11 rounded-0  fw-bold me-2 shadow-sm">Comprimir PDF</a>
                            </div>
                        </div>
                        <div class="row">
                            <ul class="nav nav-tabs me-3" role="tablist">
                                <li class="nav-item" *ngFor="let c of arrayCarpetas; let i = index"
                                    (click)="seleccionarTab(i)">
                                    <a class="nav-link" [class.active]="i === tabSeleccionada">
                                        <i class="fas fa-folder mx-2 folder" [hidden]="i === tabSeleccionada"></i> <i
                                            class="fas fa-folder-open mx-2 folder" [hidden]="i !== tabSeleccionada"></i>
                                        {{ c.nombreCarpeta }}
                                    </a>
                                </li>
                            </ul>
                            <!-- Contenido de las pestañas -->
                            <div class="tab-content">
                                <div *ngFor="let c of arrayCarpetas; let i = index" class="tab-pane fade"
                                    [class.show]="i === tabSeleccionada" [class.active]="i === tabSeleccionada">
                                    <div class="row d-flex">
                                        <div class="col-md-6 col-12 h-100">
                                            <div class="card mt-3 card-anexos m-auto">
                                                <div class="card-body">
                                                    <div class="row mt-2">
                                                        <div class="col-8 d-flex justify-content-end ">
                                                            <div
                                                                class="input-group w-75 justify-content-center align-items-center">
                                                                <label class="input-group-text"
                                                                    for="inputGroupSelect01">Tipo Documento</label>
                                                                <select name="tipo" id="tipo" class="form-select"
                                                                    [(ngModel)]="strTipoAnexo">
                                                                    <option value="Contrato">Contrato</option>
                                                                    <option value="Materiales">Materiales</option>
                                                                    <option value="Cotización">Cotización</option>
                                                                    <option value="evidencia">Evidencia foto</option>
                                                                    <option value="Servicios especiales">Servicios
                                                                        especiales</option>
                                                                    <option value="Cotizacion Rapida">Cotizacion Rapida
                                                                    </option>
                                                                    <option value="Cotizacion por TYM">Cotizacion por
                                                                        TYM</option>
                                                                    <option value="Licitaciones">Licitaciones</option>
                                                                    <option value="InfoCotizacion">Información para
                                                                        cotizar</option>
                                                                    <option value="Convenio_Precios">Convenio Precios
                                                                    </option>
                                                                    <option value="Catalogo">Catalogo</option>
                                                                    <option value="Formatos">Formatos</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-3 d-flex">
                                                            <div
                                                                class="m-auto d-flex justify-content-center align-items-center">
                                                                <label
                                                                    class="btn btn-secondary btn-sm btn-file text-sm">
                                                                    <i class="fas fa-plus"></i> Agregar Anexo
                                                                    <input
                                                                        (change)="agregarAnexo($event, c.nombreCarpeta)"
                                                                        #file type="file" style="display: none;"
                                                                        required
                                                                        accept=".xlsx, .pdf, .png, .jpg, .jpeg">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex mt-3 mb-3"
                                                            *ngIf="c.xmlDocumentos.length == 0">
                                                            <div class="card w-100  m-auto">
                                                                <div class="card-body text-center">
                                                                    <span>SIN DOCUMENTOS</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-4 d-block mb-3 mt-2 text-center"
                                                            *ngFor="let item of c.xmlDocumentos; let i = index">
                                                            <div *ngIf="item?.strTipo == 'xlsx'"
                                                                class="d-flex justify-content-center">
                                                                <div class="card ">
                                                                    <div class="card-body">
                                                                        <a>
                                                                            <svg class="svg_excel"
                                                                                xmsns="http://www.w3.org/2000/svg"
                                                                                class="w-75" viewBox="0 0 384 512">
                                                                                <path
                                                                                    d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div class="card-footer">
                                                                        <label class=" fs11">{{item.strNombre}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="item.strTipo == 'pdf'"
                                                                class="d-flex justify-content-center">
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <a>
                                                                            <svg id="Capa_1"
                                                                                enable-background="new 0 0 512 512"
                                                                                viewBox="0 0 512 512"
                                                                                xmsns="http://www.w3.org/2000/svg">
                                                                                <g>
                                                                                    <g>
                                                                                        <path
                                                                                            d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                                            fill="#f9f8f9" />
                                                                                        <path
                                                                                            d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                                            fill="#e3e0e4" />
                                                                                        <path
                                                                                            d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                                            fill="#e3e0e4" />
                                                                                        <path
                                                                                            d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                                            fill="#dc4955" />
                                                                                        <path
                                                                                            d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                                            fill="#dc4955" />
                                                                                        <path
                                                                                            d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                                            fill="#c42430" />
                                                                                    </g>
                                                                                    <g>
                                                                                        <path
                                                                                            d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                                        <path
                                                                                            d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                                        <path
                                                                                            d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                                        <path
                                                                                            d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div class="card-footer">
                                                                        <label class="fs11">{{item?.strNombre}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="item?.strTipo == 'jpg' || item?.strTipo == 'png' || item?.strTipo == 'jpeg' || item?.strTipo == 'jpg'"
                                                                class="d-flex justify-content-center">
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <a>
                                                                            <svg id="Capa_1" class="w-100"
                                                                                enable-background="new 0 0 512 512"
                                                                                viewBox="0 0 512 512"
                                                                                xmsns="http://www.w3.org/2000/svg">
                                                                                <g>
                                                                                    <g>
                                                                                        <path
                                                                                            d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                                            fill="#f9f8f9" />
                                                                                        <path
                                                                                            d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                                            fill="#e3e0e4" />
                                                                                        <path
                                                                                            d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                                            fill="#e3e0e4" />
                                                                                        <path
                                                                                            d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                                            fill="#a29aa5" />
                                                                                        <path
                                                                                            d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                                                                                            fill="#9ec8fc" />
                                                                                        <path
                                                                                            d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                                                                                            fill="#62a4fb" />
                                                                                        <path
                                                                                            d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                                            fill="#9ec8fc" />
                                                                                        <path
                                                                                            d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                                                                                            fill="#b9a1d3" />
                                                                                        <path
                                                                                            d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                                                                                            fill="#9573bb" />
                                                                                        <path
                                                                                            d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                                            fill="#b9a1d3" />
                                                                                        <circle cx="219.587"
                                                                                            cy="285.995" fill="#faf063"
                                                                                            r="21.578" />
                                                                                    </g>
                                                                                    <g>
                                                                                        <path
                                                                                            d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                                                                                        <path
                                                                                            d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                                                                                        <path
                                                                                            d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div class="card-footer">
                                                                        <label class="fs11">{{item?.strNombre}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 mt-2 d-flex">
                                                            <button type="button"
                                                                class="btn btn-primary d-flex  ms-auto justify-content-center align-items-center mx-2"
                                                                (click)="SubirAnexos()">
                                                                <span class="material-icons-outlined me-1">save</span>
                                                                <span>Guardar</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 m-auto h-100 ">
                                            <div class="card card-anexos mt-3 text-center">
                                                <div class="row">
                                                    <div class="col-11 text-center p-2">
                                                        <h2 class="fw-bold">Anexos Registrados</h2>
                                                    </div>
                                                    <div class="col-12" *ngIf="c.xmlDocumentosBD.length == 0">
                                                        <span class="fw-bold">SIN DATOS</span>
                                                    </div>
                                                    <div class="col-md-4 col-lg-3 col-12 d-block mb-3 text-center"
                                                        *ngFor="let item of c.xmlDocumentosBD; let i = index">
                                                        <div *ngIf="item?.strTipo == 'xlsx'"
                                                            class="d-flex justify-content-center">
                                                            <div class="card m-auto" style="width: 97% !important;">
                                                                <div class="card-body">
                                                                    <a href="https://www.ecodeli.com/{{item.strUrl}}"
                                                                        target="_blank" rel="noopener noreferrer">
                                                                        <svg class="svg_excel"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            class="w-75" viewBox="0 0 384 512">
                                                                            <path
                                                                                d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                                <div class="card-footer">
                                                                    <label
                                                                        class="text-sm">{{item.strTipo_Documento}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.strTipo == 'pdf'"
                                                            class="d-flex justify-content-center">
                                                            <div class="card m-auto" style="width: 95% !important;">
                                                                <div class="card-body">
                                                                    <a href="https://www.ecodeli.com/{{item.strUrl}}"
                                                                        target="_blank" rel="noopener noreferrer">
                                                                        <svg id="Capa_1"
                                                                            enable-background="new 0 0 512 512"
                                                                            viewBox="0 0 512 512"
                                                                            xmsns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <g>
                                                                                    <path
                                                                                        d="m453.31 460.125c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                                        fill="#f9f8f9" />
                                                                                    <path
                                                                                        d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                                        fill="#e3e0e4" />
                                                                                    <path
                                                                                        d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                                        fill="#e3e0e4" />
                                                                                    <path
                                                                                        d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                                        fill="#dc4955" />
                                                                                    <path
                                                                                        d="m435 438.5h-358c-22.091 0-40-17.909-40-40v-146c0-22.091 17.909-40 40-40h358c22.091 0 40 17.909 40 40v146c0 22.091-17.909 40-40 40z"
                                                                                        fill="#dc4955" />
                                                                                    <path
                                                                                        d="m102 438.5h-25c-22.09 0-40-17.91-40-40v-146c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40v146c0 22.09 17.91 40 40 40z"
                                                                                        fill="#c42430" />
                                                                                </g>
                                                                                <g>
                                                                                    <path
                                                                                        d="m460.81 212.656v-45.354c0-10.88-4.24-21.111-11.938-28.811l-126.505-126.52c-7.719-7.72-17.985-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v90.259c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-90.259c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v66.939c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-57.128l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-5.733c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.733c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v38.954c-3.475-.813-7.091-1.256-10.81-1.256h-26.787c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h26.787c17.92 0 32.5 14.579 32.5 32.5v146c0 17.921-14.58 32.5-32.5 32.5h-184.582c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h184.582c3.719 0 7.335-.443 10.81-1.256v15.381c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-15.381c3.475.813 7.091 1.256 10.81 1.256h138.418c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-138.418c-17.92 0-32.5-14.579-32.5-32.5v-146c0-17.921 14.58-32.5 32.5-32.5h296.213c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-296.213c-3.719 0-7.335.443-10.81 1.256v-29.122c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v35.522c-13.041 8.476-21.69 23.165-21.69 39.844v146c0 16.679 8.649 31.368 21.69 39.845v21.78c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-21.78c13.041-8.477 21.69-23.166 21.69-39.845v-146c0-16.679-8.649-31.368-21.69-39.844z" />
                                                                                    <path
                                                                                        d="m384.203 276.421c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-45.863c-4.142 0-7.5 3.357-7.5 7.5v113.158c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-50.15h34.72c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-34.72v-40.508z" />
                                                                                    <path
                                                                                        d="m219.837 379.014c-.195 4.598 2.708 9.431 7.752 9.297.945-.004 23.242-.09 32.126-.244 15.06-.264 28.471-7.089 37.764-19.218 8.615-11.244 13.36-26.639 13.36-43.349 0-37.569-20.966-62.811-52.172-62.811h-31.33c-4.073-.081-7.642 3.545-7.5 7.615zm38.83-101.325c27.407 0 37.172 24.697 37.172 47.811 0 27.633-14.963 47.193-36.386 47.568-5.582.097-16.873.168-24.418.207-.042-12.37-.118-36.767-.118-47.897 0-9.303-.043-34.88-.066-47.688h23.816z" />
                                                                                    <path
                                                                                        d="m127.797 261.004c-4.032-.081-7.569 3.477-7.5 7.5v113.992c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-42.056c7.433-.042 18.678-.1 24.241-.1 22.221 0 40.299-17.795 40.299-39.668s-18.078-39.669-40.299-39.669h-31.741zm57.04 39.669c0 13.371-11.585 24.668-25.299 24.668-5.528 0-16.612.057-24.063.099-.042-7.637-.099-19.13-.099-24.767 0-4.742-.033-16.735-.056-24.669h24.218c13.713 0 25.299 11.297 25.299 24.669z" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                                <div class="card-footer">
                                                                    <label
                                                                        class="text-sm">{{item.strTipo_Documento}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item?.strTipo == 'jpg' || item?.strTipo == 'png' || item?.strTipo == 'jpeg' || item?.strTipo == 'jpg'"
                                                            class="d-flex justify-content-center">
                                                            <div class="card m-auto" style="width: 95% !important;">
                                                                <div class="card-body">
                                                                    <a href="https://www.ecodeli.com/{{item.strUrl}}"
                                                                        target="_blank" rel="noopener noreferrer">
                                                                        <svg id="Capa_1" class="w-100"
                                                                            enable-background="new 0 0 512 512"
                                                                            viewBox="0 0 512 512"
                                                                            xmsns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <g>
                                                                                    <path
                                                                                        d="m453.31 167.302v292.823c0 24.508-19.907 44.375-44.464 44.375h-305.692c-24.557 0-44.464-19.867-44.464-44.375v-408.25c0-24.508 19.907-44.375 44.464-44.375h190.303c8.857 0 17.35 3.516 23.606 9.773l126.505 126.521c6.239 6.239 9.742 14.694 9.742 23.508z"
                                                                                        fill="#f9f8f9" />
                                                                                    <path
                                                                                        d="m453.31 167.3v16.24h-115.96c-33.12 0-60.06-26.95-60.06-60.07v-115.97h16.17c8.85 0 17.35 3.52 23.6 9.77l126.51 126.52c6.24 6.24 9.74 14.7 9.74 23.51z"
                                                                                        fill="#e3e0e4" />
                                                                                    <path
                                                                                        d="m133.15 504.5h-30c-24.55 0-44.46-19.87-44.46-44.38v-408.24c0-24.51 19.91-44.38 44.46-44.38h30c-24.55 0-44.46 19.87-44.46 44.38v408.24c0 24.51 19.91 44.38 44.46 44.38z"
                                                                                        fill="#e3e0e4" />
                                                                                    <path
                                                                                        d="m450.32 153.54h-112.97c-16.6 0-30.06-13.46-30.06-30.07v-112.96c3.61 1.63 6.92 3.91 9.77 6.76l126.51 126.52c2.85 2.85 5.12 6.15 6.75 9.75z"
                                                                                        fill="#a29aa5" />
                                                                                    <path
                                                                                        d="m363 453.646h-214c-22.091 0-40-17.909-40-40v-154.292c0-22.091 17.909-40 40-40h214c22.091 0 40 17.909 40 40v154.293c0 22.091-17.909 39.999-40 39.999z"
                                                                                        fill="#9ec8fc" />
                                                                                    <path
                                                                                        d="m134 413.65c0 22.09 17.91 40 40 40h-25c-22.09 0-40-17.91-40-40v-154.3c0-22.09 17.91-40 40-40h25c-22.09 0-40 17.91-40 40z"
                                                                                        fill="#62a4fb" />
                                                                                    <path
                                                                                        d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                                        fill="#9ec8fc" />
                                                                                    <path
                                                                                        d="m402.82 417.43c-1.9 20.32-19 36.22-39.82 36.22h-214c-22.09 0-40-17.91-40-40v-.94l60.65-68.73c6.38-4.77 15.17-4.63 21.4.32l27.11 21.54c8.35 6.64 20.44 5.56 27.48-2.47l49.99-57c7.36-8.4 20.6-7.86 27.26 1.11z"
                                                                                        fill="#b9a1d3" />
                                                                                    <path
                                                                                        d="m174 453.65h-25c-22.09 0-40-17.91-40-40v-.94l25-28.33v29.27c0 22.09 17.91 40 40 40z"
                                                                                        fill="#9573bb" />
                                                                                    <path
                                                                                        d="m376.75 451.22c.91-.33 1.8-.7 2.68-1.09-.88.4-1.77.76-2.68 1.09z"
                                                                                        fill="#b9a1d3" />
                                                                                    <circle cx="219.587" cy="285.995"
                                                                                        fill="#faf063" r="21.578" />
                                                                                </g>
                                                                                <g>
                                                                                    <path
                                                                                        d="m448.872 138.491-126.505-126.52c-7.719-7.72-17.986-11.971-28.91-11.971h-190.303c-28.653 0-51.964 23.271-51.964 51.875v310.554c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-310.554c0-20.333 16.582-36.875 36.964-36.875h190.303c2.144 0 4.273.274 6.334.798v49.439c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-39.628l120.413 120.428h-97.853c-12.439 0-22.56-10.123-22.56-22.566v-23.233c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v23.233c0 20.714 16.85 37.566 37.56 37.566h107.694c.505 2.025.765 4.125.765 6.265v292.823c0 20.333-16.582 36.875-36.964 36.875h-305.692c-20.382 0-36.964-16.542-36.964-36.875v-62.696c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v62.696c0 28.604 23.311 51.875 51.964 51.875h305.692c28.653 0 51.964-23.271 51.964-51.875v-292.823c0-10.88-4.24-21.112-11.938-28.811z" />
                                                                                    <path
                                                                                        d="m219.587 315.073c16.034 0 29.078-13.044 29.078-29.078s-13.044-29.078-29.078-29.078-29.078 13.044-29.078 29.078 13.044 29.078 29.078 29.078zm0-43.156c7.763 0 14.078 6.315 14.078 14.078s-6.315 14.078-14.078 14.078c-7.762 0-14.078-6.315-14.078-14.078s6.316-14.078 14.078-14.078z" />
                                                                                    <path
                                                                                        d="m149 226.854h16.127c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-16.127c-26.191 0-47.5 21.309-47.5 47.5v154.293c0 26.191 21.309 47.5 47.5 47.5h214c26.191 0 47.5-21.309 47.5-47.5v-154.293c0-26.191-21.309-47.5-47.5-47.5h-162.873c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h162.873c17.92 0 32.5 14.579 32.5 32.5v136.515l-66.518-92.761c-.024-.033-.048-.066-.072-.099-4.531-6.104-11.486-9.783-19.082-10.092-7.587-.326-14.827 2.791-19.84 8.506l-11.868 13.533c-2.731 3.114-2.42 7.854.694 10.584 3.114 2.731 7.852 2.421 10.584-.693l11.867-13.533c3.958-4.76 11.983-4.445 15.567.593l77.918 108.658c-3.18 14.607-16.205 25.582-31.751 25.582h-213.999c-16.89 0-30.807-12.952-32.352-29.445l58.07-67.116c3.587-2.307 8.289-2.099 11.667.586l27.104 21.544c11.5 9.145 28.1 7.651 37.788-3.396l15.038-17.148c2.731-3.114 2.42-7.854-.694-10.584-3.114-2.732-7.853-2.421-10.584.693l-15.038 17.148c-4.402 5.022-11.947 5.701-17.176 1.544l-27.104-21.544c-8.89-7.068-21.454-7.257-30.552-.463-1.319.765-47.501 54.888-48.667 55.981v-134.593c0-17.921 14.58-32.5 32.5-32.5z" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                                <div class="card-footer">
                                                                    <label
                                                                        class="text-sm">{{item.strTipo_Documento}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- sucursales -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-building-add mx-1 iconoTabs"></i> <span class="m-auto">Sucursales</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid mb-5">
                        <div class="row">
                            <div class="col-12 d-flex mt-2 mb-0 ">
                                <ul class="nav nav-pills align-items-center d-flex col-12" id="pills-tab"
                                    role="tablist">
                                    <li class="nav-item " role="presentation">
                                        <button class="nav-link active me-3 shadow-sm" id="pills-home-tab"
                                            data-bs-toggle="pill" data-bs-target="#ctes" type="button" role="tab"
                                            aria-controls="pills-home" aria-selected="false" aria-selected="true">
                                            Listado Sucursales
                                        </button>
                                    </li>
                                    <li class="nav-item " role="presentation">
                                        <button class="nav-link shadow-sm" id="prospectos-tab" data-bs-toggle="pill"
                                            data-bs-target="#prospectos" type="button" role="tab"
                                            aria-controls="pills-profile" aria-selected="false">Subir
                                            Sucursales
                                        </button>
                                    </li>
                                    <li class="nav-item ms-auto" role="presentation">
                                        <button class="btn shadow-sm btn-outline-primary" type="button"
                                            (click)="fnlimpiarmdlSuc()" data-bs-toggle="modal"
                                            data-bs-target="#mdlManualSuc">
                                            <i class="bi bi-plus-circle-fill"></i>
                                            LEAD - Sucursal
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 px-0 mx-0 ">
                                <div class="tab-content" id="pills-tabContent">

                                    <div class="tab-pane fade show active" id="ctes" role="tabpanel"
                                        aria-labelledby="pills-home-tab">
                                        <div class="row mt-4">
                                            <div class="col-12 table-responsive">
                                                <table class="table table-bordered"
                                                    style="background: white; font-size: 14px;">
                                                    <thead style="background: #071C34; color: white">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Nombre Sucursal</th>
                                                            <th scope="col">Agente Sucursal</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="arrayListadoSucursales.length == 0">
                                                            <td colspan="3">
                                                                <div class="card" style="background: white;">
                                                                    <div class="card-body d-flex">
                                                                        <span class="m-auto">SIN DATOS</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr *ngFor="let item of arrayListadoSucursales; let i = index">
                                                            <th>
                                                                {{ i + 1 }}

                                                            </th>
                                                            <td>
                                                                <span class="text-primary"
                                                                    (click)="fnIrDetalleLead(item)">{{
                                                                    item.strNombreEmpresa_Sucursal }}</span>
                                                            </td>
                                                            <td>
                                                                ({{ item.strAgente_sucursal }}) {{
                                                                item.strNombreAgente_sucursal }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="prospectos" role="tabpanel"
                                        aria-labelledby="prospectos-tab">
                                        <div class="row" *ngIf="strTipoLead === ''">
                                            <div class="col-12">
                                                <div class="card-header d-flex justify-content-center pt-0">
                                                    <span class="text-bold text-sm text-danger">Es necesario
                                                        seleccionar un tipo de lead</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="this.strTipoLead !== ''">
                                            <div class="col-md-6 col-12 mt-4  ">
                                                <button class="btn btn-primary my-2 form-control hob-text w-50"
                                                    *ngIf="excelData.length > 0" (click)="fileInput.click()">
                                                    <i class="fas fa-cloud-upload-alt mx-1"></i>
                                                    <span class="fw-bolder">Cargar Archivo..</span>
                                                </button>
                                                <input (change)="handleFileInput($event)" type="file" class="d-none"
                                                    accept=".xlsx, .xls, .xml" #fileInput />
                                            </div>
                                            <div class="col-md-6 col-12 mt-4 d-flex ">

                                                <div class="ms-auto">
                                                    <button class="btn btn-success ms-auto"
                                                        (click)="fnDescargarFormatoCliente()">
                                                        <i class="fas fa-cloud-download-alt mx-1"></i> Layout
                                                        Sucursales</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex justify-content-center mt-5"
                                            *ngIf="excelData.length == 0">
                                            <div id="drop-area" (drop)="handleDrop($event)"
                                                (dragover)="handleDragOver($event)"
                                                (dragleave)="handleDragLeave($event)"
                                                class="col-7 bordeslin text-center"
                                                style="background: #f8f9fa; border-radius: 10px; padding: 30px;">
                                                <span class="material-symbols-outlined fCloud text-success"
                                                    [ngClass]="{ 'd-none': excelData?.length < 1 }">
                                                    done_outline
                                                </span>
                                                <span class="material-symbols-outlined fCloud text-secondary">
                                                    cloud_upload
                                                </span>
                                                <div class="col-12 d-flex justify-content-center">
                                                    <div class="col-6 p-2">

                                                        <button
                                                            class="btn btn-outline-primary my-2 form-control hob-text"
                                                            (click)="fileInput2.click()">
                                                            <span class="fw-bolder">Cargar Archivo..</span>
                                                        </button>
                                                        <input (change)="handleFileInput($event)" type="file"
                                                            class="d-none" accept=".xlsx, .xls, .xml" #fileInput2 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4" *ngIf="excelData.length > 0">
                                            <div class="col-12 table-responsive">
                                                <table class="table table-bordered"
                                                    style="background: white; font-size: 14px;">
                                                    <thead style="background: #071C34; color: white">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th *ngIf="strTipoLead == 'FAMILIA'" scope="col">Razon
                                                                Social</th>
                                                            <th *ngIf="strTipoLead == 'FAMILIA'" scope="col">RFC
                                                            </th>
                                                            <th scope="col">Nombre_Sucursal</th>
                                                            <th scope="col">Dirección</th>
                                                            <th scope="col">Dirección_Numero</th>
                                                            <th scope="col">Codigo_Postal</th>
                                                            <th scope="col">Colonia</th>

                                                            <th scope="col">Contacto_Nombre</th>
                                                            <th scope="col">Contacto_Puesto</th>
                                                            <th scope="col">Contacto_Email</th>
                                                            <th scope="col">Contacto_Telefono</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of excelData; let i = index">
                                                            <th scope="row">{{i + 1}}</th>
                                                            <td *ngIf="strTipoLead == 'FAMILIA'">
                                                                {{item.RAZON_SOCIAL}}
                                                            </td>
                                                            <td *ngIf="strTipoLead == 'FAMILIA'">{{item.RFC}}</td>
                                                            <td>{{item.NOMBRE_SUCURSAL}}</td>
                                                            <td>{{item.DIRECCION}}</td>
                                                            <td>{{item.DIRECCION_NUMERO}}</td>
                                                            <td>{{item.CODIGO_POSTAL}}</td>
                                                            <td>{{item.COLONIA}}</td>
                                                            <td>{{item.CONTACTO_NOMBRE}}</td>
                                                            <td>{{item.CONTACTO_PUESTO}}</td>
                                                            <td>{{item.CONTACTO_EMAIL}}</td>
                                                            <td>{{item.CONTACTO_TELEFONO}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-12 d-flex">

                                                <button class="btn btn-primary ms-auto"
                                                    (click)="fnInsertSucursalesSucursales()">Guardar C</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- catalogo -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex" (click)="fnCatalogoFamilia();">
                                <i class="bi bi-file-earmark-text mx-1 iconoTabs"></i> <span
                                    class="m-auto">Catalogo</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="text-center" *ngIf="iframeVisible">
                        <img [src]="'/assets/loading-25.gif'" style="width: 15% !important;" />
                    </div>
                    <div class="col-12" *ngIf="!iframeVisible">
                        <iframe [src]="urlIframe" frameborder="0" width="100%" style="height:63vh;"></iframe>
                    </div>
                </mat-tab>
                <!-- EstadoCuenta -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-currency-dollar mx-1 iconoTabs"></i> <span class="m-auto">Estado de
                                    Cuenta</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="col-12 py-3 d-flex justify-content-between">
                                    <mat-form-field appearance="outline" class="rounded bg-white">
                                        <mat-label>Buscar</mat-label>
                                        <input matInput placeholder="Cliente, Sucursal, Contacto, etcc." #input
                                            (keyup)="applyFilterEdoCuenta($event)">
                                        <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>
                                    <button
                                        class="btn border bg-white border-danger py-0  px-2 text-danger d-flex align-items-center shadow-sm"
                                        (click)="fncreateEdoCta()">
                                        <i class="bi bi-file-earmark-pdf fs-5 text-danger"></i> <span
                                            class="fs13">Descargar PDF</span>
                                    </button>
                                </div>
                                <div class="table-responsive mb-0">
                                    <div class="mat-elevation-z8">
                                        <table mat-table [dataSource]="TableCtaCte" matSort #sort3="matSort"
                                            class="w-100 table table-hover">

                                            <ng-container matColumnDef="indice">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="text-center ps-0">#</th>
                                                <td mat-cell *matCellDef="let row;let ind = index"
                                                    class=" ps-0 align-middle text-center fs12">
                                                    <span class="fw-bold">{{ind+1}}</span>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="fw-bold"> Total </td>
                                            </ng-container>


                                            <ng-container matColumnDef="cliente">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12 ">
                                                    <span class="fw-bold">({{row.strCliente}}) </span>
                                                    {{row.strNombreCliente}}
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef> </td>
                                            </ng-container>


                                            <ng-container matColumnDef="movimiento">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Movimiento </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12"> {{row.strMovID}} </td>
                                                <td mat-footer-cell *matFooterCellDef> </td>
                                            </ng-container>


                                            <ng-container matColumnDef="fechaEmision">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Fecha Emisión
                                                </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12">
                                                    <span class="">{{row.strFechaCreacion}}</span>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef> </td>
                                            </ng-container>


                                            <ng-container matColumnDef="fechavencimiento">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Vencimiento
                                                </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12"> {{row.strFecha_Cierre}} </td>
                                                <td mat-footer-cell *matFooterCellDef> </td>
                                            </ng-container>


                                            <ng-container matColumnDef="dias">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Dias</th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="align-middle text-center fs12"> {{row.intDias}} </td>
                                                <td mat-footer-cell *matFooterCellDef> </td>
                                            </ng-container>


                                            <ng-container matColumnDef="saldoCorriente">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Saldo Corriente
                                                </th>
                                                <td mat-cell *matCellDef="let row" class="align-middle text-end fs12">
                                                    <span class="">{{row.dblSaldoCorriente|currency}}</span>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="text-end fw-bold">
                                                    {{fngetT()|currency}}</td>
                                            </ng-container>


                                            <ng-container matColumnDef="a15dias">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>De 1 a 15</th>
                                                <td mat-cell *matCellDef="let row" class="align-middle text-end fs12">
                                                    {{row.dblSaldo15|currency}}
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="text-end fw-bold">
                                                    {{fngetT15()|currency}}</td>
                                            </ng-container>


                                            <ng-container matColumnDef="a30dias">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="text-center pe-0"> De 15 a 30 </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="pe-0 align-middle text-end fs12">
                                                    {{row.dblSaldo30|currency}}
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="text-end fw-bold">
                                                    {{fngetT30()|currency}}</td>
                                            </ng-container>


                                            <ng-container matColumnDef="a60dias">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="text-center pe-0">De 30 a 60 </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="pe-0 align-middle text-end fs12">
                                                    {{row.dblSaldo60|currency}}
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="text-end fw-bold">
                                                    {{fngetT60()|currency}}</td>
                                            </ng-container>


                                            <ng-container matColumnDef="a90dias">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="text-center pe-0"> De 60 a 90 </th>
                                                <td mat-cell *matCellDef="let row"
                                                    class="pe-0 align-middle text-end fs12">
                                                    {{row.dblSaldo90|currency}}
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="text-end fw-bold">
                                                    {{fngetT90()|currency}}</td>
                                            </ng-container>


                                            <ng-container matColumnDef="mas90dias">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    class="text-center pe-0"> Mas de 90 </th>
                                                <td mat-cell *matCellDef="let row" class="calign-middle text-end fs12">
                                                    {{row.dblSaldoA|currency}}
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef class="text-end fw-bold pe-0 ">
                                                    {{fngetTa90()|currency}}</td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsCtaCte"
                                                class="btncerrarModal"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsCtaCte;"></tr>
                                            <tr mat-footer-row *matFooterRowDef="displayedColumnsCtaCte"></tr>


                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="4">Sin resultados de la busqueda:
                                                    "{{input.value}}"</td>
                                            </tr>
                                        </table>
                                        <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 25, 100]"
                                            aria-label="Select page of users" #paginator3></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- productos favorito -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex">
                                <i class="bi bi-clipboard2-heart mx-1 iconoTabs"></i>
                                <span class="m-auto">Productos Favoritos</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-3">
                            <div class="col-12 table-responsive px-0 mb-0">
                                <table mat-table [dataSource]="dataSourceFavoritos" matSort #sort2="matSort"
                                    class="table table-bordered">

                                    <ng-container matColumnDef="strArticulo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                            ARTICULO</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell fw-bold  align-middle text-center fs12">
                                            {{element.strArticulo}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="strOpcion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center">
                                            OPCION</th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center fs12">
                                            <span class="fw-bold"
                                                *ngIf="element.strOpcion">({{element.strOpcion}})</span> <span
                                                class="mx-2"
                                                *ngIf="element.strNombreOpcion">{{element.strNombreOpcion}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="strDescripcion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            DESCRIPCION </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12 fw-bold">
                                            {{element.strDescripcion}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="strLinea">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            LINEA
                                        </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12">
                                            {{element.strLinea}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="dblCantidad">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            class="align-middle text-center pe-0">
                                            NUMERO DE COMPRAS
                                        </th>
                                        <td mat-cell *matCellDef="let element"
                                            class="gastoCell align-middle text-center pe-0 fs12">
                                            <span class="cantidadC cursorPointer" data-bs-toggle="modal"
                                                data-bs-target="#detalle_favoritos"
                                                (click)="fngetCompras_Favoritas_Detalle(element.strArticulo)">{{element.dblCantidad}}
                                            </span>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsProductosFavoritos"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsProductosFavoritos;"></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- movimientos pendiene -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="row">
                            <div class="col-12 labels d-flex" (click)="fngetMovimientos_pendientes_cte()">
                                <i class="bi bi-lightning-fill iconoTabs mx-1"></i> <span class="m-auto">Movimientos
                                    Pendientes</span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="container-fluid">
                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-center">
                                <ul class="nav col-12 nav-pills d-flex justify-content-center mb-3 clsNavBg2 pt-2"
                                    id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button
                                            class="nav-link active custom-inactive fs13 d-flex justify-content-center aling-items-center"
                                            id="pills-pedidos-tab" data-bs-toggle="pill" data-bs-target="#pills-pedidos"
                                            type="button" role="tab" aria-controls="pills-home"
                                            aria-selected="true">PEDIDOS <span
                                                class="badge text-bg-light fw-bold mx-2">{{intCantidadPedidos}}</span></button>
                                    </li>
                                    <li class="nav-item " role="presentation">
                                        <button
                                            class="nav-link custom-inactive fs13 d-flex justify-content-center aling-items-center"
                                            id="pills-remisiones-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-remisiones" type="button" role="tab"
                                            aria-controls="pills-profile" aria-selected="false">REMISIONES
                                            <span
                                                class="badge text-bg-light fw-bold mx-2">{{intCantidadRemisiones}}</span></button>
                                    </li>
                                    <li class="nav-item " role="presentation">
                                        <button
                                            class="nav-link custom-inactive fs13 d-flex justify-content-center aling-items-center"
                                            id="pills-consigna-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-consigna" type="button" role="tab"
                                            aria-controls="pills-profile" aria-selected="false">VENTAS
                                            CONSIGNA <span
                                                class="badge text-bg-light fw-bold mx-2">{{intCantidadVentasConsigna}}</span></button>
                                    </li>
                                    <li class="nav-item " role="presentation">
                                        <button
                                            class="nav-link custom-inactive fs13 d-flex justify-content-center aling-items-center"
                                            id="pills-comodato-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-comodato" type="button" role="tab"
                                            aria-controls="pills-profile" aria-selected="false">COMODATOS
                                            <span
                                                class="badge text-bg-light fw-bold mx-2">{{intCantidadComodato}}</span></button>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 px-0 mx-0 ">
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-pedidos" role="tabpanel"
                                        aria-labelledby="pills-pedidos-tab">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12 table-responsive d-flex">

                                                    <table class="table m-auto text-center"
                                                        style="width: 95% !important;">
                                                        <thead>
                                                            <tr style="background-color: rgb(76, 76, 109) !important">
                                                                <th scope="col">ID</th>
                                                                <th scope="col">Cliente</th>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">SucursalCte</th>
                                                                <th scope="col">MovID</th>
                                                                <th scope="col">Estatus</th>
                                                                <th scope="col">Situación</th>
                                                                <th scope="col">Fecha Requerida</th>
                                                                <th scope="col">Importe</th>
                                                                <th scope="col">Semaforo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of arrayPedidos; let i =index">
                                                                <th scope="row">{{item.intID}}</th>
                                                                <th>{{item.strCliente}}</th>
                                                                <td>{{item.strNombre}}</td>
                                                                <td>{{item.strSucursalCte}}</td>
                                                                <td>{{item.strMovID}}</td>
                                                                <td>
                                                                    <i class="bi bi-lightning-fill text-warning fs-6"
                                                                        *ngIf="item.strEstatus == 'PENDIENTE'"></i>
                                                                    <i class="bi bi-x-circle-fill text-danger fs-6"
                                                                        *ngIf="item.strEstatus == 'CANCELADO'"></i>
                                                                    <i class="bi bi-check-circle-fill text-success fs-6"
                                                                        *ngIf="item.strEstatus == 'AUTORIZADO'"></i>
                                                                </td>
                                                                <td>{{item.strSituacion}}</td>
                                                                <td>{{item.strFechaRequerida}}</td>
                                                                <td>{{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                                </td>
                                                                <td><span
                                                                        [ngClass]="{'badge text-bg-success p-2': item.strDiasDif >= 0, 'badge text-bg-danger p-2': item.strDiasDif <= 0}">{{item.strDiasDif}}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="tab-pane fade" id="pills-remisiones" role="tabpanel"
                                        aria-labelledby="pills-remisiones-tab">
                                        <div class="container-fluid">
                                            <div class="row">

                                                <div class="col-12 table-responsive d-flex">
                                                    <table class="table m-auto text-center"
                                                        style="width: 95% !important;">
                                                        <thead style="background: rgb(76, 76, 109) !important">
                                                            <tr>
                                                                <th scope="col">ID</th>
                                                                <th scope="col">Cliente</th>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">SucursalCte</th>
                                                                <th scope="col">MovID</th>
                                                                <th scope="col">Estatus</th>
                                                                <th scope="col">Situación</th>
                                                                <th scope="col">Fecha Requerida</th>
                                                                <th scope="col">Importe</th>
                                                                <th scope="col">Semaforo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="text-center">
                                                            <tr *ngFor="let item of arrayRemisiones; let i =index">
                                                                <th scope="row">{{item.intID}}</th>
                                                                <th>{{item.strCliente}}</th>
                                                                <td>{{item.strNombre}}</td>
                                                                <td>{{item.strSucursalCte}}</td>
                                                                <td>{{item.strMovID}}</td>
                                                                <td>
                                                                    <i class="bi bi-lightning-fill text-warning fs-6"
                                                                        *ngIf="item.strEstatus == 'PENDIENTE'"></i>
                                                                    <i class="bi bi-x-circle-fill text-danger fs-6"
                                                                        *ngIf="item.strEstatus == 'CANCELADO'"></i>
                                                                    <i class="bi bi-check-circle-fill text-success fs-6"
                                                                        *ngIf="item.strEstatus == 'AUTORIZADO'"></i>
                                                                </td>
                                                                <td>{{item.strSituacion}}</td>
                                                                <td>{{item.strFechaRequerida}}</td>
                                                                <td>{{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        [ngClass]="{'badge text-bg-success p-2': item.strDiasDif >= 0, 'badge text-bg-danger p-2': item.strDiasDif <= 0}">{{item.strDiasDif}}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="tab-pane fade " id="pills-consigna" role="tabpanel"
                                        aria-labelledby="pills-consigna-tab">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12 table-responsive d-flex">
                                                    <table class="table m-auto" style="width: 95% !important;">
                                                        <thead style="background: rgb(76, 76, 109) !important">
                                                            <tr>
                                                                <th scope="col">ID</th>
                                                                <th scope="col">Cliente</th>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">SucursalCte</th>
                                                                <th scope="col">MovID</th>
                                                                <th scope="col">Estatus</th>
                                                                <th scope="col">Situación</th>
                                                                <th scope="col">Fecha Requerida</th>
                                                                <th scope="col">Importe</th>
                                                                <th scope="col">Semaforo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of arrayVentasConsigna; let i =index">
                                                                <th scope="row">{{item.intID}}</th>
                                                                <th>{{item.strCliente}}</th>
                                                                <td>{{item.strNombre}}</td>
                                                                <td>{{item.strSucursalCte}}</td>
                                                                <td>{{item.strMovID}}</td>
                                                                <td>
                                                                    <i class="bi bi-lightning-fill text-warning fs-6"
                                                                        *ngIf="item.strEstatus == 'PENDIENTE'"></i>
                                                                    <i class="bi bi-x-circle-fill text-danger fs-6"
                                                                        *ngIf="item.strEstatus == 'CANCELADO'"></i>
                                                                    <i class="bi bi-check-circle-fill text-success fs-6"
                                                                        *ngIf="item.strEstatus == 'AUTORIZADO'"></i>
                                                                </td>
                                                                <td>{{item.strSituacion}}</td>
                                                                <td>{{item.strFechaRequerida}}</td>
                                                                <td>{{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                                </td>
                                                                <td><span
                                                                        [ngClass]="{'badge text-bg-success p-2': item.strDiasDif >= 0, 'badge text-bg-danger p-2': item.strDiasDif <= 0}">{{item.strDiasDif}}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="tab-pane fade " id="pills-comodato" role="tabpanel"
                                        aria-labelledby="pills-comodato-tab">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12 table-responsive d-flex">
                                                    <table class="table m-auto" style="width: 95% !important;">
                                                        <thead style="background: rgb(76, 76, 109) !important">
                                                            <tr>
                                                                <th scope="col">ID</th>
                                                                <th scope="col">Cliente</th>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">SucursalCte</th>
                                                                <th scope="col">MovID</th>
                                                                <th scope="col">Estatus</th>
                                                                <th scope="col">Situación</th>
                                                                <th scope="col">Fecha Requerida</th>
                                                                <th scope="col">Importe</th>
                                                                <th scope="col">Semaforo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of arrayComodatos; let i =index">
                                                                <th scope="row">{{item.intID}}</th>
                                                                <th>{{item.strCliente}}</th>
                                                                <td>{{item.strNombre}}</td>
                                                                <td>{{item.strSucursalCte}}</td>
                                                                <td>{{item.strMovID}}</td>
                                                                <td>
                                                                    <i class="bi bi-lightning-fill text-warning fs-6"
                                                                        *ngIf="item.strEstatus == 'PENDIENTE'"></i>
                                                                    <i class="bi bi-x-circle-fill text-danger fs-6"
                                                                        *ngIf="item.strEstatus == 'CANCELADO'"></i>
                                                                    <i class="bi bi-check-circle-fill text-success fs-6"
                                                                        *ngIf="item.strEstatus == 'AUTORIZADO'"></i>
                                                                </td>
                                                                <td>
                                                                    {{item.strSituacion}}
                                                                </td>
                                                                <td>
                                                                    {{item.strFechaRequerida}}</td>
                                                                <td>
                                                                    {{item.dblImporte | currency:'':'symbol':'1.2-2'}}
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        [ngClass]="{'badge text-bg-success p-2': item.strDiasDif >= 0, 'badge text-bg-danger p-2': item.strDiasDif <= 0}">{{item.strDiasDif}}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<!-- Modal actividades -->
<div class="modal fade" id="MConcluirActividades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <!--   <h1 class="modal-title fs-5 m-auto" id="exampleModalLabel">{{objAct?.strEstatus=='CONCLUIDO'?'Actividad Concluida':objAct?.strEstatus == 'POR AUTORIZAR'?'AUTORIZAR ACTIVIDAD':'Concluye Actividad'}} : 
                    <span class="fw-bold">{{objAct?.strTitulo?objAct?.strTitulo:""}}</span> 
                    <span *ngIf="objAct?.strEstatus=='PENDIENTE'"> para poder avanzar</span> 
                </h1>
               -->
                <span class="  shadow-sm borderbott bordertop px-2 py-1 d-flex flex-column  " [ngClass]="{'bg-secondary':objAct?.strEstatus!= 'CONCLUIDO' && objAct?.strEstatus!= 'PROCESO' && objAct?.strEstatus!= 'PENDIENTE','bg-success':objAct?.strEstatus== 'CONCLUIDO','bgInfo':objAct?.strEstatus== 'PROCESO','bg-warning':objAct?.strEstatus== 'PENDIENTE'}">
                    <span class="mx-auto fs12">Estatus</span>
                    <span class="fw-bold mx-auto fs12">{{ objAct && objAct.strEstatus ? objAct.strEstatus:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                </span>
                <h1 class="modal-title fs-5 m-auto" id="exampleModalLabel">{{objAct?.strEstatus=='CONCLUIDO'?'Actividad Concluida':objAct?.strEstatus == 'POR AUTORIZAR'?'AUTORIZAR ACTIVIDAD':''}}
                    <span class="fw-bold">{{objAct?.strTitulo ? objAct?.strTitulo:objAct?.strOrigen}}</span>
                    <!-- <span *ngIf="objAct?.strEstatus=='PENDIENTE'"> para poder avanzar</span> -->
                </h1>
                <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center mb-3">
                    <div class="col-11">
                        <!-- *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strEstatus == 'POR AUTORIZAR'||objAct?.strTipo=='TAREA_UPDATE'" -->
                        <div class="row">
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon">
                                        <i class="bi bi-person-fill-gear clsIconModal "></i>
                                        Responsable:
                                    </span>
                                        <span class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strNombreAgente?objAct.strNombreAgente:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3">
                                    <span class="titIcon"><i class="bi bi-person-circle clsIconModal"></i> Asignado por:
                                    </span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.nombreCrea?objAct.nombreCrea:'--Seleccione'+objAct?.strTipo+'--'}}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Inicio:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraInicio?objAct.strHoraInicio:'00'}}</span>
                                </div>
                                <div class="col-12 d-flex flex-column mt-3">
                                    <span class="titIcon"><i class="bi bi-stopwatch-fill clsIconModal"></i> Hora
                                        Fin:</span>
                                    <span
                                        class="py-1 px-2 bg-light rounded shadow-sm">{{objAct&&objAct.strHoraFin?objAct.strHoraFin:'00'}}</span>
                                </div>
                            </div>

                            <div class="col-12 mt-3">
                                <div class="col-12 d-flex flex-column">
                                    <span class="titIcon"><i class="bi bi-chat-square-text-fill clsIconModal"></i>
                                        ASUNTO</span>
                                    <span class="py-1 px-2 bg-light rounded shadow-sm"
                                        [innerHTML]="objAct&&objAct.strOpcion?objAct.strOpcion:'--Seleccione '+objAct?.strTipo+'--'"></span>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3"
                                *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="titIcon"><i
                                        class="bi bi-chat-dots-fill clsIconModal"></i>Observaciones</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm"
                                    [innerHTML]="objAct?.strObservaciones"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-2"
                                *ngIf="objAct?.strEstatus=='CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'|| objAct?.strEstatus == 'POR AUTORIZAR' ">
                                <span class="float-end titIcon"><i class="clsIconModal bi "
                                        [ngClass]="{'bi-hand-thumbs-up-fill':objAct?.strResultado == '1','bi-hand-thumbs-down-fill':objAct?.strResultado == '2','bi-info-circle-fill':objAct?.strResultado == '0'}"></i>
                                    Resultado</span>
                                <span *ngIf="objAct?.strResultado == '1'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">EXITOSO</span>
                                <span *ngIf="objAct?.strResultado == '2'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN EXITO</span>
                                <span *ngIf="objAct?.strResultado == '0'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN RESPUESTA</span>
                            </div>
                            <!--      <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                <span>Titulo</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strTitulo}}</span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-2">
                                <span class="float-end">Resultado</span>
                                <span *ngIf="objDetActividad.strResultado == '1'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">EXITOSO</span>
                                <span *ngIf="objDetActividad.strResultado == '2'"
                                    class="py-1 px-2 bg-light float-end rounded shadow-sm">SIN EXITO</span>
                            </div>
                            <div class="col-12 d-flex flex-column mt-3">
                                <span>Obervaciones</span>
                                <span class="py-1 px-2 bg-light rounded shadow-sm"
                                    [innerHTML]="objDetActividad?.strObservaciones"></span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                <span>Hora Inicio</span>
                                <span
                                    class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strHoraInicio}}</span>
                            </div>
                            <div class="col-12 col-md-6 d-flex flex-column mt-3">
                                <span>Hora Fin</span>
                                <span
                                    class="py-1 px-2 bg-light rounded shadow-sm">{{objDetActividad?.strHoraFin}}</span>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12 h-100 mb-2 mt-3"
                        [ngClass]="{'visually-hidden':objAct?.strEstatus == 'POR AUTORIZAR'||objAct?.strEstatus == 'CONCLUIDO'||objAct?.strTipo=='Update_Lead_Tarea'||objAct?.strTipo=='TAREA_UPDATE'}">
                        <!-- ||objAct.strTipo=='Update_Lead_Tarea' -->
                        <div class="form-group h-100">
                            <textarea [(ngModel)]="contenido" class="custom-summernote h-100 summernote" id="summernote"
                                #summernote></textarea>
                        </div>
                    </div>
                    <div class="col-12 d-flex mt-2"
                        *ngIf="objAct?.strEstatus=='PENDIENTE' || objAct?.length == 0 || objAct?.strEstatus=='PROCESO' ">
                        <select name="accioness" id="acciones" class="form-select m-auto" [(ngModel)]="strResultado"
                            [disabled]="objAct?.strEstatus=='CONCLUIDO'">
                            <option value="">
                                --Selecciona
                                una opción--</option>
                            <option value="1">
                                EXITOSO</option>
                            <option value="2">
                                SIN EXITO
                            </option>
                        </select>
                    </div>
                    <div class="col-12 d-flex mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            #btnCerrarAct>{{objAct?.strEstatus=='PENDIENTE'?'Cancelar':'Cerrar'}} </button>
                        <button [disabled]="strResultado == '' || contenido == ''"
                            class="btn btn-success text-white ms-auto mt-auto" (click)="fnConcluirActividad(objAct)"
                            *ngIf="objAct?.strEstatus=='PENDIENTE' || objAct?.length == 0 || objAct?.strEstatus=='PROCESO'">Concluir</button>

                        <button type="button" class="btn btn-primary  ms-auto" *ngIf="objAct?.strEstatus=='PENDIENTE'"
                            (click)="fnEnProceso(objAct)">En Proceso</button>
                        <button type="button" class="btn btn-secondary  ms-auto" *ngIf=" objAct?.strEstatus=='PROCESO' "
                            (click)="fnEnProceso(objAct)">Actualizar</button>

                        <button type="button" class="btn btn-success  ms-auto"
                            *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea "
                            (click)="fnAutorizar(objAct)">Autorizar</button>

                        <button type="button" class="btn btn-danger  ms-auto"
                            *ngIf="objAct?.strEstatus=='POR AUTORIZAR' && objAct?.strAgente_Crea == strAgente_Crea "
                            (click)="fnRechazar(objAct)">Rechazar</button>
                    </div>
                </div>
                <hr>
                <div class="col-12 py-3">
                    <div class="col-12 border">
                        <div class="col-12 px-0 ">
                            <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                <span class=" fs13 mx-auto">ADJUNTOS</span>
                            </h3>

                        </div>
                        <div hidden class="col-12 px-0">
                            <input #fileModal type="file" (change)="onFileInsert($event)">
                        </div>

                        <div class="col-11 mx-auto">
                            <div class="row ">
                                <div class="col-6 bg-light pb-2">
                                    <div class="col-12 d-flex py-2 justify-content-between">
                                        <span>Agregar</span>
                                        <button class="me-2 btn btnAdd py-0 px-2 shadow-sm"
                                            (click)="fnAjuntarAnexsModal()">
                                            <i class="bi bi-file-earmark-plus"></i>
                                        </button>
                                    </div>
                                    <div class="row" *ngIf="arrayAnexMdlAdd.length > 0">
                                        <div class="col-12 d-flex my-2" *ngFor="let anex of arrayAnexMdlAdd">
                                            <div class="col-6 px-0 shadow-sm border text-center">
                                                <a class="d-flex flex-column p-2" target="_blank">
                                                    <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'"
                                                        class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.extencion == 'pdf'"
                                                        class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'"
                                                        class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'"
                                                        class="bi bi-file-zip fs-4"></i>
                                                    <span class="fs10 text-ellipsi">
                                                        {{anex.nombreArchivo}}
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="col-6 d-flex justify-content-center align-items-center px-2">
                                                <button class="btn btn-primary fs12 p-1 me-3"
                                                    (click)="fnAgregarArchivo()"><i class="bi bi-floppy"></i> <span
                                                        class="fs11">Guardar</span></button>
                                                <button class="btn btn-danger p-1 fs12"
                                                    (click)="fnElinimarArchivos()"><i class="bi bi-trash3"></i> <span
                                                        class="fs11">Eliminar</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center"
                                        *ngIf="arrayAnexMdlAdd.length == 0">
                                        <div class="col-11 bg-secondary text-center rounded text-white">
                                            Seleccione archivos para agregar.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="col-12 py-2">
                                        <span class="text-center mb-0">Existentes</span>
                                    </div>
                                    <div class="row" *ngIf="arrayAnexosModal.length > 0">
                                        <div class="col-4 d-flex" *ngFor="let anex of arrayAnexosModal">
                                            <div class="col-12 px-0 shadow-sm border text-center">
                                                <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}"
                                                    target="_blank">
                                                    <i *ngIf="anex.strTipo == 'jpg' || anex.strTipo == 'png'|| anex.strTipo == 'gif'"
                                                        class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.strTipo == 'pdf'"
                                                        class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.strTipo == 'xls' || anex.strTipo == 'xlsx'|| anex.strTipo == 'csv'"
                                                        class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.strTipo == 'zip' || anex.strTipo == 'rar'"
                                                        class="bi bi-file-zip fs-4"></i>
                                                    <span class="fs10 text-ellipsi">
                                                        {{anex.strNombreCorto}}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center"
                                        *ngIf="arrayAnexosModal.length == 0">
                                        <div class="col-11 bg-secondary text-center rounded text-white">
                                            Sin archivos agregados.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex" style="border-top: 2px dashed #b3abab;">
                    <div class="col-12 mt-3 colorComentarios d-flex justify-content-center align-items-center      ">
                        <h2 class="m-auto">Comentarios</h2>
                    </div>
                    <div class="col-10 m-auto d-flex flex-column">
                        <textarea [(ngModel)]="strComentarioD" class="form-control mt-3" name="nombre" rows="3" cols="5"
                            placeholder="Escribe un comentario"></textarea>
                        <button class="btn btn-primary w-25 mt-2 ms-auto" (click)="fnInsertComentario()">
                            <i class="fas fa-plus"></i> Agregar </button>
                    </div>
                    <div class="col-12">
                        <div class="notification-container">
                            <div class="notification" *ngFor="let item of arrayComentarios; let i = index">
                                <div class="circle-icon">{{item.strAgente}}</div>
                                <div class="notification-content">
                                    <div class="notification-header">{{item.strNombreAgente}}</div>
                                    <div class="notification-text">{{item.strComentario}}</div>
                                </div>
                                <div class="notification-time">{{item.strFechaRequerida}}</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<!-- Modal RegistrarActividad -->
<div class="modal fade" id="mdlRegistrar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal d-flex">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Registrar {{objModal &&
                    objModal.titulo?objModal.titulo:""}}</h3>
                <h3 class="modal-title ms-auto mb-0 text-white" id="exampleModalLabel "> {{objModal &&
                    objModal.subTitulo?objModal.subTitulo:""}}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-12 col-md-3">
                        <div class="col-12 mb-3">
                            <div class="col-12">
                                <h3 class="mb-1 fw-bold text-center">
                                    RESPONSABLES
                                </h3>
                            </div>
                            <mat-form-field class="example-full-width w-100">
                                <span matPrefix> </span>
                                <input type="text" class="px-3" matInput placeholder="Nombre agente" autocomplete="off"
                                    (keyup)="fnBusquedaCte(strBuscarAgente);" name="search"
                                    [(ngModel)]="strBuscarAgente">
                                <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-form-field>
                            <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                                <li class="list-group-item cursorPoint hver py-1 fs10"
                                    *ngFor="let agt of arrayAgente_Filter" (click)="fnSeleccionaAgente(agt)">
                                    {{agt.strNombre}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-12" *ngFor="let agt of arrayAgentesActividades">
                            <button class="btn btn-secondary position-relative fs12 shadow-sm col-12 mb-2">
                                ({{agt.strAgente}}) - {{agt.strNombre}}
                                <i (click)="fnEliminarAgenteAct(agt)"
                                    class="fa-solid fa-x position-absolute top-0 end-0 mt-1 me-1 fs11"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col-5">
                                <div class="col-12">
                                    Asunto
                                </div>
                                <div class="col-12">
                                    <input type="text" placeholder="Agregue asunto" class="form-control"
                                        [(ngModel)]="mdlNombre">
                                </div>
                            </div>
                            <div class="col-2">
                            </div>
                            <div class="col-5">
                                <div class="col-12">
                                    Fecha Compromiso
                                </div>
                                <div class="col-12">
                                    <input type="date" class="form-control" [(ngModel)]="strfecha">
                                </div>
                            </div>
                        </div>



                        <div class="row my-3">
                            <div class="col-12 col-md-5" *ngIf="objModal.titulo != 'Tarea'">
                                <div class="col-12">
                                    Hora Inicio
                                </div>
                                <div class="col-12 d-flex">
                                    <select class="form-select w-100" *ngIf="time1=='am'"
                                        aria-label="Default select example" [(ngModel)]="mdlHoraInicio">
                                        <option *ngFor="let x of arrayAm">
                                            {{x.hora}}
                                        </option>
                                    </select>
                                    <!--  <select class="form-select w-50" *ngIf="time1=='pm'" aria-label="Default select example"
                                        [(ngModel)]="mdlHoraInicio">
                                        <option *ngFor="let x of arraypm">
                                            {{x.hora}}
                                        </option>
                                    </select> -->
                                    <!-- <select class="form-select w-50" aria-label="Default select example" [(ngModel)]="time1"
                                        (change)="onTimeChange($event,'inicio')">
                                        <option *ngFor="let x of arrayTime">
                                            {{x.time}}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-2" *ngIf="objModal.titulo != 'Tarea'">
                            </div>
                            <div class="col-12 col-md-5 mt-3 mt-md-0" *ngIf="objModal.titulo != 'Tarea'">
                                <div class="col-12">
                                    Hora Fin
                                </div>
                                <div class="col-12 d-flex">
                                    <select class="form-select w-100" *ngIf="time2=='am'"
                                        aria-label="Default select example" [(ngModel)]="mdlHoraFin">
                                        <option *ngFor="let x of arrayAm">
                                            {{x.hora}}
                                        </option>
                                    </select>
                                    <!--   <select class="form-select w-50" *ngIf="time2=='pm'" aria-label="Default select example"
                                        [(ngModel)]="mdlHoraFin">
                                        <option *ngFor="let x of arraypm">
                                            {{x.hora}}
                                        </option>
                                    </select>
                                    <select class="form-select w-50" aria-label="Default select example" [(ngModel)]="time2"
                                        (change)="onTimeChange($event,'fin')">
                                        <option *ngFor="let x of arrayTime">
                                            {{x.time}}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                            <!--<div class="col-12 mt-3 mb-2 d-block">
                                <span>Agente</span> <br>
                                <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                                    [(ngModel)]="strAgente">
                                    <option value="undefined">--Selecciona un Agente--</option>
                                    <option *ngFor="let item of arrayAgentes_tareas; let i = index" value="{{item.strAgente}}">
                                        {{item.strNombre}} <b *ngIf="item.strTipo !='Vendedor'">{{item.strTipo}}</b></option>
                                </select>
                            </div>-->

                            <!--  <div class="col-12 mb-3">
                                <mat-form-field class="example-full-width w-100">
                                    <span matPrefix> </span>
                                    <input type="text" class="px-3" matInput placeholder="Responsable"
                                        (keyup)="fnBusquedaCte(strBuscarAgente);" name="search" [(ngModel)]="strBuscarAgente">
                                    <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </mat-form-field>
                                <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                                    <li class="list-group-item cursorPoint hver py-1 fs10"
                                        *ngFor="let agt of arrayAgente_Filter" (click)="fnSeleccionaAgente(agt)">
                                        {{agt.strNombre}}
                                    </li>
                                </ul>
                            </div> -->

                        </div>
                        <div class="row">
                            <div class="col-12">
                                Comentario
                            </div>
                            <!--<div class="col-12">
                                <textarea class="form-control" name="" id="" [(ngModel)]="mdlComentarios"></textarea>
                            </div>-->
                            <div class="col-12">
                                <div #editableDiv2 contenteditable="true" (input)="onInput($event)"
                                    class="editable-area" i
                                    style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-md-3 d-flex mt-3 my-0">
                        <div class="col-12 d-flex">
                            <div class="col-12 border">
                                <div class="col-12 px-0 position-relative">
                                    <h3 class="mb-1 fw-bold text-center d-flex col-12">
                                        <span class="ms-2 fs13">ADJUNTO</span>
                                    </h3>
                                    <button
                                        class="me-2 btn btnAdd position-absolute top-50 end-0 translate-middle-y py-0 px-2 shadow-sm"
                                        (click)="fnAdjuntarArchivosActividad()">
                                        <i class="bi bi-file-earmark-plus"></i>
                                    </button>
                                </div>
                                <div class="col-12 px-0">
                                    <input #fileInputActividades type="file" hidden
                                        (change)="onFileSelectedActividad($event)">
                                </div>
                                <div class="col-11 mx-auto" *ngIf="arrayAgregarAnexosActividades.length > 0">
                                    <div class="row">
                                        <div class="col-6 d-flex" *ngFor="let anex of arrayAgregarAnexosActividades">
                                            <div class="col-12 px-0 shadow-sm border text-center">
                                                <a class="d-flex flex-column p-2" href="{{anex.urlImagen}}"
                                                    target="_blank">
                                                    <i *ngIf="anex.extencion == 'jpg' || anex.extencion == 'png'|| anex.extencion == 'gif'"
                                                        class="bi bi-file-image fs-4 text-primary"></i>
                                                    <i *ngIf="anex.extencion == 'pdf'"
                                                        class="bi bi-file-earmark-pdf fs-4 text-danger"></i>
                                                    <i *ngIf="anex.extencion == 'xls' || anex.extencion == 'xlsx'|| anex.extencion == 'csv'"
                                                        class="bi bi-file-earmark-excel fs-4 text-success"></i>
                                                    <i *ngIf="anex.extencion == 'zip' || anex.extencion == 'rar'"
                                                        class="bi bi-file-zip fs-4"></i>
                                                    <span class="fs10 text-ellipsi">
                                                        {{anex.nombreArchivo}}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <button class="btn btn-danger fs12 p-1" (click)="fnEliminarAnexosAct()">
                                                <i class="bi bi-trash3 fs14"></i> Limpiar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrar>Cancelar</button>
                <button type="button" class="btn btn-primary " (click)="fnAnexosActividad()"> Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal nueva Oportunidad -->
<div class="modal fade" id="nuevaOportunidad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="formOportunidades" (ngSubmit)="onSubmitOportunidades()">
                <div class="modal-header bgtitModal">
                    <h1 class="modal-title fs-5 mb-0" id="exampleModalLabel">Nuevo Sueño</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <!--   <div class="col-12 mt-2 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Nombre *:</label>
                            <input type="text" class="form-control" [(ngModel)]="strNombreOportunidad">
                        </div>
                    </div>

                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Monto *:</label>
                           
                            <input type="number" class="form-control" [(ngModel)]="dblMonto">
                            <span class="fs10 text-secondary">Se sumará según el monto añadido por línea.</span>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Fecha Estimada Cierre *:</label>
                            <input type="date" class="form-control" [(ngModel)]="strFechaCierre">
                        </div>
                    </div> -->
                        <div class="col-12 mt-2 mb-2">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Nombre *:</label>
                                <input type="text" class="form-control" formControlName="strTitulo">
                                <mat-error *ngIf="formOportunidades.get('strTitulo').hasError('required')"
                                    class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Monto *: </label><!-- totalImporte -->
                                <input type="number" class="form-control" formControlName="intMonto"
                                    readonly><!-- [(ngModel)]="dblMonto" -->
                                <span class="fs10 text-secondary">Se sumará según el monto añadido por línea.</span>
                                <mat-error *ngIf="formOportunidades.get('intMonto').hasError('required')"
                                    class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <div class="input-with-label">
                                <label for="nombre" class="corner-label">Fecha Estimada Cierre *:</label>
                                <input type="date" formControlName="strFecha"
                                    class="form-control"><!-- [(ngModel)]="strFechaCierre" -->
                                <mat-error *ngIf="formOportunidades.get('strFecha').hasError('required')"
                                    class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                                </mat-error>
                            </div>
                        </div>
                        <!--  <div class="col-12 mb-2 mb-1">
                        <div class="input-with-label">
                            <label for="nombre" class="corner-label">Lineas *:</label>
                            <select class="form-select" [(ngModel)]="strLinea">
                                <option value="">-Selecciona una opcion--</option>
                                <option [value]="linea.strLinea" *ngFor="let linea of arrayLineas2">
                                    {{linea.strLinea}}</option>
                            </select>
                        </div>
                    </div> -->

                        <!-- <div class="col-12 mb-3">
                        <mat-form-field class="example-full-width w-100">
                            <span matPrefix> </span>
                            <input type="text" class="px-3" matInput placeholder="Responsable"
                                (keyup)="fnBuscarCte(strResponsable);" name="search"
                                [(ngModel)]="strResponsable">

                            <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                <mat-icon>search</mat-icon>
                            </button>
                            <mat-hint class="fw-bold">{{nombreResponsable}}</mat-hint>
                        </mat-form-field>
                        <ul *ngIf="arrayAsigAgentes.length > 0" class="list-group mt-1 overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayAsigAgentes"
                                (click)="fnselecCliente(age)">
                                {{age.strNombreAgente}}
                            </li>
                        </ul>
                    </div> -->





                        <div class="col-12 ">
                            <mat-form-field class="example-full-width w-100">
                                <span matPrefix> </span>
                                <input type="text" class="px-3" matInput placeholder="Nombre del Responsable"
                                    formControlName="strResponsable"
                                    (keyup)="fnBuscarCte(formOportunidades.get('strResponsable')?.value)">

                                <button type="button" matSuffix class="btn btn-darkky d-flex align-items-center ">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-error *ngIf="formOportunidades.get('strResponsable2').hasError('required') "
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                            </mat-error>
                            <ul *ngIf="arrayAsigAgentes.length > 0" class="list-group mt-1 overflowy">
                                <li class="list-group-item cursorPoint hver py-1 fs10"
                                    *ngFor="let age of arrayAsigAgentes" (click)="fnselecCliente(age)">
                                    {{age.strNombreAgente}}
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="col-12">
                        <mat-form-field class="example-full-width w-100">
                            <span matPrefix> </span>
                            <input type="text" class="px-3" matInput placeholder="Buscar Articulo"
                                (keyup)="funConsultarArt(strBuscarArticulo);" name="search"
                                [(ngModel)]="strBuscarArticulo">
                            <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>    --> <!-- funArticulos -->
                        <!-- <div class="col-12 mb-2">
                        <ul *ngIf="arrayArts.length > 0" class="list-group mt-1 overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10" *ngFor="let age of arrayArts"
                                (click)="fnselecArticulo(age)">
                                ({{age.strArticulo}}) {{age.strDescripcion}}
                            </li>
                        </ul>
                    </div> -->
                        <!-- <div class="col-12 table-responsive mt-4">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Producto</th>
                                    <th scope="col">Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="arrayArticuloAgregados.length == 0">
                                    <td colspan="4" class="text-center">
                                        <span>SIN DATOS</span>
                                    </td>
                                </tr>
                                <tr *ngFor="let art of arrayArticuloAgregados; let i = index">
                                    <td class="text-center fs12 fw-bold">
                                        {{ i + 1 }}
                                    </td>
                                    <td class="fs12">
                                        ({{ art.strArticulo }}) {{ art.strDescripcion }}
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-danger fs-6" (click)="fnEiminarProductos(i)"><i
                                                class="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                        <div class="col-12 d-flex bg-light py-2">
                            <div class="col-2 my-auto">
                                <h3 class="mb-2 fw-bold text-center">LINEAS</h3>
                            </div>
                            <div class="col-10">
                                <button type="button" class="btn py-0 px-1 text-white  col me-2 fs12 mb-1 shadow-sm"
                                    [ngClass]="{'bg-secondary':x.select==false,'bg-success':x.select==true}"
                                    (click)="fnAddLinea(x)" *ngFor="let x of arrayLineas2">
                                    {{x.strLinea}}
                                </button>
                            </div>

                        </div>
                        <div class="col-12" [hidden]="arrayLineasSelect.length>0">
                            <div class="alert alert-danger text-center fw-bold col-10 mx-auto py-2" role="alert">
                                ¡Seleccione líneas para continuar!
                            </div>
                        </div>
                        <div class="scrollAuto" [hidden]="arrayLineasSelect.length==0">
                            <div class="col-12  d-flex">
                                <span class="col-6 text-center bgSec fw-bold">
                                    LINEA
                                </span>
                                <span class="col-6 text-center bgSec fw-bold">
                                    IMPORTE
                                </span>
                            </div>


                            <div class="col-12 d-flex justify-content-between align-items-center border-bottom py-2"
                                *ngFor="let cet of arrayLineasSelect">
                                <div class="col-6">
                                    <label for="">{{cet.strLinea}}</label>
                                </div>
                                <div class="col-1 text-end">
                                    <span class="text-end">
                                        $
                                    </span>
                                </div>
                                <div class="col-5">
                                    <input type="number" class="form-control fs12 no-spin" [value]="cet.dblCantidad"
                                        (input)="updateCantidad($event, cet)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!--(click)="fnInsertOportunidad()" <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                    <button type="submit" class="btn btn-primary w-100" data-bs-dismiss="modal"
                        [disabled]="!formOportunidades || formOportunidades.invalid || arrayLineasSelect.length==0">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="mdlManualSuc" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title mb-0 fw-bold fs-5" id="mdlManualSucLabel">Agregar Nueva Sucursal</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formSucursal">
                    <div class="row">
                        <div class="col-12  mt-3 mt-md-0">
                            <label for="">Nombre Sucursal</label>
                            <input type="text" name="strNombreEmpresa" [(ngModel)]="objSucManual.NOMBRE_SUCURSAL"
                                class="form-control">
                            <mat-error *ngIf="formSucursal.get('strNombreEmpresa').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <!--   
                <div class="col-12 col-md-4 mt-3 mt-md-0">
                    <label for="">RFC</label>
                    <input type="text" name="strRFC" class="form-control" [(ngModel)]="objSucManual.RFC">
                    <mat-error *ngIf="formSucursal.get('strRFC').hasError('required')" class="text-danger small">
                        <i class="fas fa-exclamation-circle"></i> 
                        Campo Obligatorio
                    </mat-error>
                    <mat-error *ngIf="formSucursal.get('strRFC').hasError('minlength') && !formSucursal.get('strRFC').hasError('required')" class="text-danger small">
                        <i class="fas fa-exclamation-circle"></i> 
                        La longitud mínima es 13 caracteres
                      </mat-error>
                      <mat-error *ngIf="formSucursal.get('strRFC').hasError('maxlength') && !formSucursal.get('strRFC').hasError('required')" class="text-danger small">
                        <i class="fas fa-exclamation-circle"></i> 
                        La longitud máxima es 13 caracteres
                      </mat-error>
                </div> -->
                        <!--  
                <div class="col-12  mt-3">
                    <label for="">Razon Social</label>
                    <input type="text"  name="strRazonSocial"  class="form-control" [(ngModel)]="objSucManual.RAZON_SOCIAL">
                    <mat-error *ngIf="formSucursal.get('strRazonSocial').hasError('required')" class="text-danger small">
                        <i class="fas fa-exclamation-circle"></i>
                        Campo Obligatorio
                    </mat-error>
                </div> -->
                        <div class="col-12 col-md-4 mt-3">
                            <label for="">Codigo Postal</label>
                            <input type="text" name="strCodigoPostal" placeholder="ejemp:36213, 40660, etc.."
                                class="form-control" [(ngModel)]="objSucManual.CODIGO_POSTAL"
                                (keydown)="onKeyDown($event)" (keypress)="validarNumeros($event)" (blur)="blurevent()">
                            <mat-error *ngIf="formSucursal.get('strCodigoPostal').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="formSucursal.get('strCodigoPostal').hasError('minlength') && !formSucursal.get('strRFC').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud mínima es 5 caracteres
                            </mat-error>
                            <mat-error
                                *ngIf="formSucursal.get('strCodigoPostal').hasError('maxlength') && !formSucursal.get('strRFC').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud máxima es 5 caracteres
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-8 mt-3">
                            <label for=""> Colonia</label>
                            <select name="" id="" name="strColonia" class="form-select"
                                [(ngModel)]="objSucManual.COLONIA">
                                <option value="">--Seleccione Colonia--</option>
                                <option value="{{col.strColonia}}" *ngFor="let col of arrayColonias">{{col.strColonia}}
                                </option>
                            </select>
                            <mat-error *ngIf="formSucursal.get('strColonia').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>

                        <div class="col-12 col-md-8 mt-3">
                            <label for=""> Dirección</label>
                            <input type="text" name="direccion" class="form-control"
                                [(ngModel)]="objSucManual.DIRECCION">
                            <mat-error *ngIf="formSucursal.get('direccion').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-4 mt-3">
                            <label for="">Numero Dirección</label>
                            <input type="text" name="numeroDireccion" class="form-control"
                                [(ngModel)]="objSucManual.DIRECCION_NUMERO">
                            <mat-error *ngIf="formSucursal.get('numeroDireccion').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 text-center mt-3">
                            <span class="fw-bold fs-6">Contacto</span>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Nombre</label>
                            <input type="text" class="form-control" name="strContacto_Nombre"
                                [(ngModel)]="objSucManual.CONTACTO_NOMBRE">
                            <mat-error *ngIf="formSucursal.get('strContacto_Nombre').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Puesto</label>
                            <input type="text" class="form-control" name="strContacto_Puesto"
                                [(ngModel)]="objSucManual.CONTACTO_PUESTO">
                            <mat-error *ngIf="formSucursal.get('strContacto_Puesto').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Email</label>
                            <input type="text" class="form-control" name="strContacto_Email"
                                [(ngModel)]="objSucManual.CONTACTO_EMAIL">
                            <mat-error *ngIf="formSucursal.get('strContacto_Email').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>

                        </div>
                        <div class="col-12 col-md-6 mt-3">
                            <label for="">Telefono</label>
                            <input type="text" class="form-control" name="strContacto_Telefono"
                                [(ngModel)]="objSucManual.CONTACTO_TELEFONO">
                            <mat-error *ngIf="formSucursal.get('strContacto_Email').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Campo Obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="formSucursal.get('strContacto_Telefono').hasError('pattern') && !formSucursal.get('strContacto_Telefono').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                Debe ser un número de 10 dígitos
                            </mat-error>
                            <mat-error
                                *ngIf="formSucursal.get('strContacto_Telefono').hasError('maxlength') && !formSucursal.get('strContacto_Telefono').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i>
                                La longitud máxima es 10 caracteres
                            </mat-error>
                        </div>
                    </div>
                </form>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #mdlSuc>Cerrar</button>
                <button type="button" class="btn btn-primary" [disabled]="formSucursal.invalid"
                    (click)="fnGuardarSuc()">Guardar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="formModal" tabindex="-1" aria-labelledby="formModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal d-flex">
                <h2 class="modal-title m-auto" id="formModalLabel">Formulario de Cliente</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row mt-4">
                    <div class="col-12">
                        <mat-accordion>
                            <mat-expansion-panel class="mb-1" expanded="true" (opened)="panelOpenState = true"
                                style="background: rgba(253, 253, 253, 0.815);" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class=" container-fluid row ">
                                            <div class="col-12">
                                                Información Lead
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="container-fluid">
                                    <form [formGroup]="formLead" *ngIf="+intID_Familia == 0">
                                        <!--(intGiro) && strSector != '' && (intIDPresencia != 0 )-->
                                        <div class="row mt-3">
                                            <div class="col-12 d-block p-2" *ngIf="+intID_Familia == 0">
                                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span>
                                                    Sector :
                                                </span>
                                                <select name="sector" id="sector" class="form-select"
                                                    formControlName="strSector" [(ngModel)]="strSector">
                                                    <option selected value="">-Selecciona una opcion-</option>
                                                    <option [value]="s.strSector"
                                                        *ngFor="let s of arraySectores; let i = index">
                                                        {{s.strSector}}</option>
                                                </select>
                                                <mat-error
                                                    *ngIf="formLead.get('strSector').hasError('required') && formLead.get('strSector').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                                <mat-error *ngIf="formLead.get('strSector').hasError('maxlength')"
                                                    class="fs10">
                                                    son
                                                    como maximo 20 caracteres</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2" *ngIf="+intID_Familia === 0">
                                                <span class="m-auto fw-bold fs11"><span class="text-danger">*</span>
                                                    Giro: </span>
                                                <select name="giro" id="giro" class="form-select"
                                                    formControlName="intId_Giro" [(ngModel)]="intGiro"
                                                    (change)="fnFiltrarSegundaLetra()">
                                                    <option value="0" selected>-Selecciona una opcion-</option>
                                                    <option *ngFor="let item of arrayGiros; let i = index"
                                                        [value]="item.intId_Giro">
                                                        {{item.strGiro}}
                                                    </option>
                                                </select><!--[value]="item.intId_Giro + ',' + item.strClasificacion"-->
                                                <mat-error
                                                    *ngIf="formLead.get('intId_Giro').hasError('required') && formLead.get('intId_Giro').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2"
                                                *ngIf="(+intID_Familia === 0) && arraySubGiro.length > 1">
                                                <span class="m-auto fw-bold fs11"><span class="text-danger">*</span>
                                                    SubGiro:
                                                </span>
                                                <select name="giro" id="giro" class="form-select"
                                                    formControlName="intId_subGiro"
                                                    [(ngModel)]="intSubGiro"><!--  [(ngModel)]="intSubGiro" -->
                                                    <option value="0" selected>-Selecciona una opcion-</option>
                                                    <option *ngFor="let item of arraySubGiro; let i = index"
                                                        [value]="item.intId_SubGiro">
                                                        {{item.strTitulo}}{{item.intId_subGiro}}
                                                    </option>
                                                </select><!--[value]="item.intId_Giro + ',' + item.strClasificacion"-->
                                                <mat-error
                                                    *ngIf="formLead.get('intId_subGiro').hasError('required') && formLead.get('intId_subGiro').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2" *ngIf="(+intID_Familia === 0)">
                                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span>
                                                    Presencia en el pais: </span>
                                                <select name="tipoCotizacion" id="tipoCotizacion" class="form-select"
                                                    (change)="fnFiltrarSegundaLetra()" [(ngModel)]="intIDPresencia"
                                                    formControlName="intIDPresencia">
                                                    <option value="0" selected>-Selecciona una opcion-</option>
                                                    <option [value]="item.intIDPresencia"
                                                        *ngFor="let item of arrayPresencia; let i = index">
                                                        {{item.strPresencia}}
                                                    </option>
                                                </select>
                                                <mat-error
                                                    *ngIf="formLead.get('intIDPresencia').hasError('required') && formLead.get('intIDPresencia').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>
                                            <div class="col-12 d-block p-2"
                                                *ngIf="(+intID_Familia === 0) && arrayFitroSegunda.length > 0 && (intId_segunda !== null && intId_segunda !== undefined)">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span>
                                                    {{arrayFitroSegunda[0].strTitulo}}
                                                </span>
                                                <input type="text" class="form-control" formControlName="intId_segunda"
                                                    placeholder="Ingresa {{arrayFitroSegunda[0].strTitulo}}"
                                                    [(ngModel)]="intId_segunda">
                                                <mat-error
                                                    *ngIf="formLead.get('intId_segunda').hasError('required') && formLead.get('intId_segunda').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>

                                            <div class="col-12 d-block p-2"
                                                *ngIf="arrayFiltroTercera.length > 0 && (intId_tercera !== null && intId_tercera !== undefined)">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span>
                                                    {{arrayFiltroTercera[0].strTitulo}}
                                                </span>
                                                <input type="text" class="form-control" formControlName="intId_tercera"
                                                    placeholder="Ingresa {{arrayFiltroTercera[0].strTitulo}} "
                                                    [(ngModel)]="intId_tercera">
                                                <mat-error
                                                    *ngIf="formLead.get('intId_tercera').hasError('required') && formLead.get('intId_tercera').touched"
                                                    class="fs10">Campo Obligatorio</mat-error>
                                            </div>

                                            <div class="col-12 d-block p-2 btn-group dropup"
                                                *ngIf="(+intID_Familia === 0) && (intPotenciador == 1 || strPotencializador != '')">
                                                <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span>
                                                    Lineas:</span>
                                                <button type="button" class="btn btn-light dropdown-toggle w-100 mb-2"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    Seleccione:
                                                </button>
                                                <ul class="dropdown-menu w-25"
                                                    style="max-height: 47vh; overflow-y: auto;">
                                                    <li *ngFor="let j of arrayPotenciador">
                                                        <div class="form-check ms-1">
                                                            <input class="form-check-input" [checked]="j.Seleccionado"
                                                                type="checkbox" [value]="j.intID_linea"
                                                                (change)="toggleMultiple(j.strLinea)"
                                                                id="flexCheckDefault">
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                {{j.strLinea}}
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ng-container *ngFor="let select of arrayPotenciador ; let i = index">
                                                    <span *ngIf="select.Seleccionado"
                                                        class="badge bg-primary text-white p-1 mb-2 mx-1">{{select.strLinea}}</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </form>
                                    <!-- 
                                    <ng-container *ngFor="let item of arraySecciones; let i = index">
                                        <div class="row   mb-1" style="background: white"
                                            *ngFor="let pregunta of item.preguntas; let m = index">

                                            <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span>
                                                {{pregunta.strPregunta}}:</span>

                                            <div class="col-12" *ngIf="pregunta.strTipoRespuesta == 'LISTA'">

                                                <select name="s{{m}}" id="s{{m}}" class="form-select w-100"
                                                    [(ngModel)]="pregunta.strRespuesta"
                                                    (change)="fnNumberPregunta($event, pregunta)">
                                                    <option value="">-Selecciona una opcion--</option>
                                                    <option *ngFor="let resp of pregunta.arrayRespuestas"
                                                        value="{{resp}}">
                                                        {{resp}}
                                                    </option>
                                                </select>
                                            </div>


                                            <div class="col-12" *ngIf="pregunta.strTipoRespuesta == 'INPUT'">
                                                <input type="text" name="ir{{m}}" id="i{{m}}" class="form-control w-100"
                                                    (input)="fnNumberPregunta($event, pregunta)"
                                                    placeholder="Introduce Respuesta"
                                                    [(ngModel)]="pregunta.strRespuesta">
                                            </div>
                                        </div>
                                    </ng-container> -->

                                    <div *ngIf="+intID_Familia >0">
                                        <div class="row"
                                            *ngIf=" strSector.length == 0 || intGiro ==0 || intIDPresencia==0">
                                            <div class="col-12">
                                                <div class="alert alert-danger text-center" role="alert">
                                                    Por favor, complete el formulario correspondiente a la información
                                                    de la familia primero.
                                                </div>
                                            </div>
                                        </div>
                                        <!--fin de mensaje-->
                                        <div *ngIf="  strSector.length > 0 && intGiro >0 && intIDPresencia >0">

                                            <div class="col-12 d-block mb-2"
                                                *ngIf="arrayFiltroTercera.length > 0 && (intId_tercera !== null && intId_tercera !== undefined)">
                                                <span class="fw-bold fs11 m-auto">
                                                    <span class="text-danger">*</span>
                                                    {{arrayFiltroTercera[0].strTitulo}}
                                                </span>
                                                <input type="text" class="form-control"
                                                    placeholder="Ingresa {{arrayFiltroTercera[0].strTitulo}} "
                                                    [(ngModel)]="intId_tercera">

                                            </div>
                                            <ng-container *ngFor="let item of arraySecciones; let i = index">
                                                <div class="row   mb-1" style="background: white"
                                                    *ngFor="let pregunta of item.preguntas; let m = index">

                                                    <span class="fw-bold fs11 m-auto"><span class="text-danger">*</span>
                                                        {{pregunta.strPregunta}}:</span>

                                                    <div class="col-12" *ngIf="pregunta.strTipoRespuesta == 'LISTA'">

                                                        <select name="s{{m}}" id="s{{m}}" class="form-select w-100"
                                                            [(ngModel)]="pregunta.strRespuesta"
                                                            (change)="fnNumberPregunta($event, pregunta)">
                                                            <option value="">-Selecciona una opcion--</option>
                                                            <option *ngFor="let resp of pregunta.arrayRespuestas"
                                                                value="{{resp}}">
                                                                {{resp}}
                                                            </option>
                                                        </select>
                                                    </div>


                                                    <div class="col-12" *ngIf="pregunta.strTipoRespuesta == 'INPUT'">
                                                        <input type="text" name="ir{{m}}" id="i{{m}}"
                                                            class="form-control w-100"
                                                            (input)="fnNumberPregunta($event, pregunta)"
                                                            placeholder="Introduce Respuesta"
                                                            [(ngModel)]="pregunta.strRespuesta">
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>


                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel class="mb-1" (opened)="true" (closed)="false" [expanded]="true"
                                style="background: rgba(253, 253, 253, 0.815);">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class=" container-fluid row ">
                                            <div class="col-12">
                                                Llenar siguiente
                                            </div>

                                        </div>
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Sucursal</th>
                                            <th scope="col">Formulario</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let suc of arraySucursales_next">
                                            <th scope="row">{{suc.strCliente}}</th>
                                            <td><a class="link"
                                                    (click)="fnNextSucursal(suc)">{{suc.strNombreCliente}}</a></td>
                                            <td>
                                                <i class="fa-solid fa-xmark  text-secondary "
                                                    *ngIf="suc.intID_Linea ==0"></i>

                                                <i class="fa-solid fa-check  text-success"
                                                    *ngIf="suc.intID_Linea ==1"></i>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>



                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button data-bs-dismiss="modal" class="btn btn-info ml-auto mx-2"
                    (click)="fnSETFORM_RESPUESTA()">Guardar</button>
                <!--[disabled]="intTotalPreguntas != intTotalContestadas"-->
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade bd-example-modal-lg h-modal modalheigth" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-dialog-centered modal-lg   ">
        <div class="modal-content h-100 ">
            <div style="background:#c7daff;color:#001b52;" class=" modal-header d-flex position-relative">
                <h1 class="modal-title position-absolute top-50 start-50 translate-middle fw-bold"
                    id="exampleModalLabel">
                    Detalle De Artículo</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body ">
                <div class="row align-items-start">
                    <div class="col-12 col-lg-6">
                        <h3 class="d-inline-block d-sm-none"> {{objArt.strDescripcion }}</h3>
                        <div id="carouselExampleIndicators" class="col-12 h-75 carousel slide d-flex align-items-center"
                            data-bs-touch="false" data-bs-interval="false">
                            <div class="carousel-inner imgCar ">
                                <div class="carousel-item active text-center ">
                                    <img [src]="imagenUrl1" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                                <div class="carousel-item text-center">
                                    <img [src]="imagenUrl2" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                                <div class="carousel-item text-center">
                                    <img [src]="imagenUrl3" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                                <div class="carousel-item text-center">
                                    <img [src]="imagenUrl4" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                                <div class="carousel-item text-center">
                                    <img [src]="imagenUrl5" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                            </div>
                            <button class="carousel-control-prev bg-transparent" style="color: black !important;"
                                type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span class="material-symbols-outlined">
                                    arrow_back_ios
                                </span>
                            </button>
                            <button class="carousel-control-next bg-transparent" style="color: black !important;"
                                type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span class="material-symbols-outlined">
                                    arrow_forward_ios
                                </span>
                            </button>
                        </div>
                        <div class="col-12 product-image-thumbs mt-0">
                            <div class="product-image-thumb active col" aria-current="true" aria-label="Slide 1"
                                data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0">
                                <img [src]="imagenUrl1" (error)="onImageError($event)" alt="Product Image">
                            </div>
                            <div class="product-image-thumb col" *ngIf="imagenUrl2!='False'" aria-current="true"
                                aria-label="Slide 2" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1">
                                <img [src]="imagenUrl2" (error)="onImageError($event)" alt="Product Image">
                            </div>
                            <div class="product-image-thumb col" *ngIf="imagenUrl3!='False'" aria-current="true"
                                aria-label="Slide 3" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2">
                                <img [src]="imagenUrl3" (error)="onImageError($event)" alt="Product Image">
                            </div>
                            <div class="product-image-thumb col" *ngIf="imagenUrl4!='False'" aria-current="true"
                                aria-label="Slide 4" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3">
                                <img [src]="imagenUrl4" (error)="onImageError($event)" alt="Product Image">
                            </div>
                            <div class="product-image-thumb col" *ngIf="imagenUrl5!='False'" aria-current="true"
                                aria-label="Slide 5" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4">
                                <img [src]="imagenUrl5" (error)="onImageError($event)" alt="Product Image">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row">
                            <div class="col-9">
                                <h2 class="my-3 fw-bolder">{{objArt.strArticulo}}</h2>
                                <p>{{objArt.strDescripcion}}</p>
                            </div>
                            <div class="col-3">
                                <div class="row">
                                    <div class="col-12 rounded text-center border shadow-sm flex flex-column pb-1 px-0">
                                        <div class="col-12 bgcard rounded-top">
                                            <span class="tficha text-white">Ficha Tecnica:</span>
                                        </div>
                                        <a href="https://ecodeli.com/catalogos/FichasTecnicas/{{objArt.strArticulo}}.pdf"
                                            target="_blank">
                                            <span class="material-symbols-outlined">
                                                download_for_offline
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div class="row mt-2 ">
                                    <div
                                        class="col-12 border rounded text-center shadow-sm d-flex flex-column pb-1 px-0">
                                        <div class="col-12 bgcard rounded-top">
                                            <span class="tficha text-white">Hoja Seguridad:</span>
                                        </div>
                                        <a href="https://ecodeli.com/catalogos/HojasSeguridad/{{objArt.strArticulo}}.pdf"
                                            target="_blank">
                                            <span class="material-symbols-outlined">
                                                download_for_offline
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-12 col-md-6" [hidden]="objArt.strTipoOpcion == 'No'">
                                <div class="col-12">
                                    <h3 class="fw-bolder mb-1">
                                        Opcion
                                    </h3>
                                </div>
                                <div class="col-12">
                                    <select class="form-select  shadow-sm" [(ngModel)]="objArt.strOpcion"
                                        aria-label=".form-select-lg example"
                                        (change)="onOpcionSeleccionada(objArt.strOpcion,objArt)">
                                        <option *ngFor="let item of objArt.arrayOpciones; let i = index;"
                                            [ngValue]="item.strOpcion">
                                            <span class="me-2">
                                                <b> {{item.strOpcion}}</b>
                                            </span>
                                            &nbsp;
                                            &nbsp;
                                            <span>
                                                {{item.strNombreOpcion}}
                                            </span>
                                        </option>
                                    </select>
                                </div>

                            </div>
                            <div class=""
                                [ngClass]="{'col-12 col-md-6':objArt.strTipoOpcion != 'No','col-10':objArt.strTipoOpcion == 'No'}">
                                <div class="ms-auto col-10">
                                    <h3 class="fw-bolder mb-1">
                                        Precio
                                    </h3>
                                </div>
                                <div class="col-12">
                                    <select [(ngModel)]="objArt.dblPrecio" class="form-select fs-8"
                                        aria-label="Default select example">
                                        <option *ngIf="intPrecioPolitica>0" value="{{intPrecioPolitica}}">
                                            {{intPrecioPolitica|currency}}</option>
                                        <!-- <option *ngIf="intPrecioPlantilla>0" value="{{intPrecioPlantilla}}">PLA
                        {{intPrecioPlantilla|currency}}</option>
                      <option *ngIf="intPrecioLista>0" value="{{intPrecioLista}}">PL {{intPrecioLista|currency}}
                      </option>
                      <option *ngIf="intPrecio>0" value="{{intPrecio}}">P1 {{intPrecio|currency}}</option>
                      <option *ngIf="intPrecio2>0" value="{{intPrecio2}}">P2 {{intPrecio2|currency}}</option>
                      <option *ngIf="intPrecio3>0" value="{{intPrecio3}}">P3 {{intPrecio3|currency}}</option>
                      <option *ngIf="intPrecio4>0" value="{{intPrecio4}}">P4 {{intPrecio4|currency}}</option>-->
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 d-flex align-items-center">
                            <div class="col-12 col-sm-6">
                                <div class="col-10 mx-auto">
                                    <div class="input-group rounded-pill">
                                        <button class="btn  btnCirs" (click)="fnAdd_Rest_Amount(objArt,'rest');"
                                            type="button">-</button>
                                        <input type="text" value="0" [(ngModel)]="objArt.dblCantidad"
                                            class="form-control text-center" placeholder=""
                                            aria-label="Recipient's username with two button addons">
                                        <button class="btn  btnCire" (click)="fnAdd_Rest_Amount(objArt,'add');"
                                            type="button">+</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 ">
                                <div class="col-10 mx-auto">
                                    <button
                                        [disabled]="spinerCarrito || !objArt.dblPrecioLista || objArt.dblPrecioLista < 1"
                                        (click)="fnAddToCarr(objArt);"
                                        class="w-100 position-relative btn btnCar btn-outline-primary py-2 fs13 rounded-pill d-flex align-items-center justify-content-center shadow-sm">
                                        <span *ngIf="!spinerCarrito">Agregar al carrito</span>

                                        <span style="color:#FFEF92" *ngIf="spinerCarrito">.</span>
                                        <div class="pt-1 position-absolute top-50 start-50 translate-middle"
                                            *ngIf="spinerCarrito">
                                            <div class="spinner-border text-primary " role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="accordion accordion-flush " id="accordionFlushExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header mb-0" id="flush-headingOne">
                                            <button (click)="fntraerExistencias(objArt);"
                                                class="fw-bold acordeon accordion-button collapsed border rounded px-3 py-1 fs12"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseOne" aria-expanded="false"
                                                aria-controls="flush-collapseOne">
                                                Existencias
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body pt-2 pb-0 bgbodyacordeon">
                                                <div class="row">
                                                    <div class="col-12 table-responsive mx-0 px-0 mb-2 border rounded">
                                                        <table class="table table-bordered bg-white fixed-header-table">
                                                            <thead>
                                                                <tr class="">

                                                                    <th class="text-center  text-white">Almacen</th>
                                                                    <th class="text-center  text-white">Nombre</th>
                                                                    <th class="text-center text-white">Disponibles</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let x of arrayExistentes; let ind = index">

                                                                    <td>{{x.strAlmacen}}</td>
                                                                    <td>{{x.strAlmacenNombre}}</td>
                                                                    <td><b>{{x.dblDisponible}}</b></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="detalle_favoritos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bgtitModal d-flex">
                <h1 class="modal-title fs-5 m-auto" id="exampleModalLabel">PRODUCTOS FAVORITOS DETALLE</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 table-responsive px-0 mb-0">
                            <table mat-table [dataSource]="dataSourceFavoritosDetalle" matSort #sort2="matSort"
                                class="table table-bordered">

                                <ng-container matColumnDef="strArticulo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                                        ARTICULO</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="gastoCell fw-bold  align-middle text-center fs13">
                                        {{element.strArticulo}} </td>
                                </ng-container>

                                <ng-container matColumnDef="strDescripcion">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="align-middle text-center">
                                        DESCRICPION</th>
                                    <td mat-cell *matCellDef="let element"
                                        class="gastoCell align-middle text-center fs13">
                                        <span class="fw-bold">({{element.strDescripcion}})</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="strOpcion">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="align-middle text-center pe-0">
                                        OPCION
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        class="gastoCell align-middle text-center pe-0 fs13">
                                        {{element.strOpcion}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="dblCantidad">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="align-middle text-center pe-0">
                                        CANTIDAD
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        class="gastoCell align-middle text-center pe-0 fs13">
                                        <span class="cantidadC">{{element.dblCantidad}} </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="dblPrecio">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="align-middle text-center pe-0">
                                        PRECIO
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        class="gastoCell align-middle text-center pe-0 fs13">
                                        <span>{{element.dblPrecio |currency:'':'symbol':'1.2-2'}} </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="dblImporte">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="align-middle text-center pe-0">
                                        IMPORTE
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        class="gastoCell align-middle text-center pe-0 fs13">
                                        <span>{{element.dblImporte |currency:'':'symbol':'1.2-2'}} </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="strFechaCreacion">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="align-middle text-center pe-0">
                                        FECHA EMISION
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        class="gastoCell align-middle text-center pe-0 fs13">
                                        <span>{{element.strFechaCreacion}} </span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsProductosFavoritosDetalle"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsProductosFavoritosDetalle;">
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<div class="row" [hidden]="!vartri">
    <div class="col-12" #pdfHeaderCtaCte>
        <h3 class="text-center">ESTADO DE CUENTA {{objInfo?.strNombreEmpresa}}</h3>
    </div>
    <div class="col-12" #pdfTableCte>
        <table class="table table-bordered  table-striped table-sm">
            <thead>
                <tr>
                    <!--  <th class="fs10 bg-dark text-white">Cliente</th> -->
                    <th class="fs10 bg-dark text-white">Movimiento</th>
                    <th class="fs10 bg-dark text-white">Fecha Emision </th>
                    <th class="fs10 bg-dark text-white">Fecha Vencimiento</th>
                    <th class="fs10 bg-dark text-white">Dias</th>
                    <th class="fs10 bg-dark text-white">Saldo Corriente</th>
                    <th class="fs10 bg-dark text-white">De 1 a 15</th>
                    <th class="fs10 bg-dark text-white">De 15 a 30</th>
                    <th class="fs10 bg-dark text-white">De 30 a 60</th>
                    <th class="fs10 bg-dark text-white">De 60 a 90</th>
                    <th class="fs10 bg-dark text-white">De 90 a mas</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let x of arrayCteCuenta ;let ind = index ">
                    <!--                     <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.strNombreCliente}}</td> -->
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.strMovID}}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.strFechaCreacion }}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.strFecha_Cierre}}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.intDias}}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.dblSaldoCorriente|currency}}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.dblSaldo15|currency}}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.dblSaldo30|currency}}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.dblSaldo60|currency}}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.dblSaldo90|currency}}</td>
                    <td class="fs10" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.dblSaldoA|currency}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th class="fs10 bg-dark text-white">Total</th>
                    <th class="bg-dark text-white"></th>
                    <th class="bg-dark text-white"></th>
                    <th class="bg-dark text-white"></th>
                    <th class="fs10 bg-dark text-white">{{fngetT()|currency}}</th>
                    <th class="fs10 bg-dark text-white">{{fngetT15()|currency}}</th>
                    <th class="fs10 bg-dark text-white">{{fngetT30()|currency}}</th>
                    <th class="fs10 bg-dark text-white">{{fngetT60()|currency}}</th>
                    <th class="fs10 bg-dark text-white">{{fngetT90()|currency}}</th>
                    <th class="fs10 bg-dark text-white">{{fngetTa90()|currency}}</th>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
<div class="row" [hidden]="!vartri">
    <div class="col-12" #pdfHeaderFlujoCte>
        <h1 class="text-center">FLUJO DE CLIENTE {{objInfo?.strNombreEmpresa}}</h1>
    </div>
    <div class="col-12" #pdfTableFlujoCte>

        <table class="table table-bordered w-100 table-sm">
            <thead>
                <tr>
                    <th class="fs10 bg-dark text-white">Ejercicio</th>
                    <th class="fs10 bg-dark text-white">Periodo</th>
                    <th class="fs10 bg-dark text-white">Importe</th>
                    <th class="fs10 bg-dark text-white">Cobrado</th>
                    <th class="fs10 bg-dark text-white">Saldo</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let x of arrayFlujoClienteFiltrado[0]?.arrayEjercicio ;let ind = index ">

                    <tr class="" *ngFor="let per of x.arrayPeriodo">
                        <td class="fs10  border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.intEjercicio}}</td>
                        <td class="fs10 border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">{{per.intPeriodo}}</td>
                        <td class="fs10 border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">
                            {{per.dblImporte|currency}}</td>
                        <td class="fs10 border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">
                            {{per.dblCobrado|currency}}</td>
                        <td class="fs10 border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">{{per.dblSaldo|currency}}
                        </td>
                    </tr>
                    <tr class="">
                        <td class="fs12 fw-bold  border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">TOTAL EJERCICIO
                            {{x.intEjercicio}}</td>
                        <td class="fs12 fw-bold border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}"></td>
                        <td class="fs12 fw-bold border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">{{x.sumImporte
                            |currency}}</td>
                        <td class="fs12 fw-bold border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">
                            {{x.sumCobrado|currency}}</td>
                        <td class="fs12 fw-bold border-bottom" [ngClass]="{'clsMute': ind % 2 === 0}">
                            {{x.sumSaldo|currency}}</td>
                    </tr>

                </ng-container>

            </tbody>
            <tfoot>
                <tr>
                    <th class="fs12 bg-dark text-white">Total</th>
                    <th class="bg-dark text-white"></th>
                    <th class="fs12 bg-dark text-white">{{arrayFlujoClienteFiltrado[0]?.sumImporte|currency}}</th>
                    <th class="fs12 bg-dark text-white">{{arrayFlujoClienteFiltrado[0]?.sumCobrado|currency}}</th>
                    <th class="fs12 bg-dark text-white">{{arrayFlujoClienteFiltrado[0]?.sumSaldo|currency}}</th>

                </tr>
            </tfoot>
        </table>
    </div>
</div>
<div class="modal fade" id="detArtModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Precio por Cantidad</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Linea</th>
                            <th scope="col">Factura</th>
                            <th scope="col">Artículo</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of arrayDetalleReporteVtas">
                            <th scope="row">{{d.strLinea}}</th>
                            <td>{{d.strMovID}}</td>
                            <td>{{d.strArticulo}}</td>
                            <td>{{d.dblCantidad}}</td>
                            <td>{{d.dblImporte | currency}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

            </div>
        </div>
    </div>
</div>

<!-- Modal Editar Actividad -->
<div class="modal fade" id="mdlEditar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header p-2 bgtitModal">
                <h3 class="modal-title mb-0 text-white" id="exampleModalLabel ">Editar {{ objEditarActividad.strTipo }}
                    id: {{ objEditarActividad.intID_Actividad }}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-5">
                        <div class="col-12">
                            Asunto
                        </div>
                        <div class="col-12">
                            <input type="text" placeholder="Agregue asunto" class="form-control"
                                [(ngModel)]="mdlNombre">
                        </div>
                    </div>
                    <div class="col-2">

                    </div>
                    <div class="col-5">
                        <div class="col-12">
                            Fecha
                        </div>
                        <div class="col-12">
                            <input type="date" class="form-control" [(ngModel)]="strfecha_editar">
                        </div>
                    </div>

                </div>
                <div class="row my-3">
                    <div class="col-12 col-md-5" *ngIf="objEditarActividad.strTipo  != 'Tarea'">
                        <div class="col-12">
                            Hora Inicio
                        </div>
                        <div class="col-12 d-flex">
                            <select class="form-select w-100" *ngIf="time1=='am'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraInicio">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>

                        </div>
                    </div>
                    <div class="col-12 col-md-2" *ngIf="objEditarActividad.strTipo != 'Tarea'">
                    </div>
                    <div class="col-12 col-md-5 mt-3 mt-md-0" *ngIf="objEditarActividad.strTipo != 'Tarea'">
                        <div class="col-12">
                            Hora Fin
                        </div>
                        <div class="col-12 d-flex">
                            <select class="form-select w-100" *ngIf="time2=='am'" aria-label="Default select example"
                                [(ngModel)]="mdlHoraFin">
                                <option *ngFor="let x of arrayAm">
                                    {{x.hora}}
                                </option>
                            </select>

                        </div>
                    </div>
                    <!--<div class="col-12 mt-3 mb-2 d-block">
                        <span>Agente</span> <br>
                        <select [disabled]="!boolAgente" name="agente" id="agente" class="form-select m-auto"
                            [(ngModel)]="strAgente">
                            <option value="undefined">-Selecciona un Agente- </option>
                            <option *ngFor="let item of  arrayAgentes_tareas; let i = index" value="{{item.strAgente}}">
                                {{item.strNombre}} <b *ngIf="item.strTipo !='Vendedor'">{{item.strTipo}}</b></option>
                        </select>
                    </div>-->
                    <div class="col-12 mb-3">
                        <mat-form-field class="example-full-width w-100">
                            <span matPrefix> </span>
                            <input type="text" class="px-3" matInput placeholder="Responsable"
                                (keyup)="fnBusquedaCte(strBuscarAgente);" name="search" [(ngModel)]="strBuscarAgente">
                            <button matSuffix class="btn btn-darkky d-flex align-items-center ">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                        <ul *ngIf="arrayAgente_Filter.length > 0" class="list-group mt-1 overflowy">
                            <li class="list-group-item cursorPoint hver py-1 fs10"
                                *ngFor="let agt of arrayAgente_Filter" (click)="fnSeleccionaAgente(agt)">
                                {{agt.strNombre}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        Comentario
                    </div>
                    <div #editableDiv contenteditable="true" (input)="onInput($event)" class="editable-area"
                        style="border: 1px solid #ccc; padding: 10px; min-height: 150px; font-family: Arial; font-size: 14px; max-width: 100%;">
                    </div>

                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" #btnCerrarEdit>Cancelar</button>
                <button type="button" class="btn btn-primary"
                    (click)="fnEditarActividad( objEditarActividad.intID_Actividad )">Editar</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalAddMinuta" tabindex="-1" aria-labelledby="modalAddMinutaLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header bgtitModal py-2">
                <h2 class="modal-title mx-auto fw-bold mb-0 " id="modalAddMinutaLabel">Minuta</h2>
                <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"
                    #btnCerrarMinuta></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <h3 class="mb-0">
                            INFORMACIÓN MINUTA
                        </h3>
                    </div>
                </div>
                <form [formGroup]="formMinuta" (ngSubmit)="onSubmit()">
                    <div class="row mt-4 justify-content-center">
                        <!-- Título -->
                        <div class="col-10 fw-bold">
                            <span class="text-danger">*</span> Título
                        </div>
                        <div class="col-10">
                            <input type="text" class="form-control" placeholder="Ingrese nombre de la minuta"
                                formControlName="strTitulo">
                            <mat-error *ngIf="formMinuta.get('strTitulo').hasError('required')"
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                            </mat-error>
                        </div>

                        <!-- Fecha -->
                        <div class="col-10 mt-3 fw-bold">
                            <span class="text-danger">*</span> Fecha
                        </div>
                        <div class="col-10">
                            <input type="date" class="form-control" formControlName="strFecha">
                            <mat-error *ngIf="formMinuta.get('strFecha').hasError('required') "
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                            </mat-error>
                        </div>

                        <!-- Hora -->
                        <div class="col-10 mt-3 fw-bold">
                            <span class="text-danger">*</span> Hora Inicio
                        </div>
                        <div class="col-10">
                            <select class="form-select w-100" *ngIf="time1 == 'am'" formControlName="strHora"
                                aria-label="Default select example">
                                <option *ngFor="let x of arrayAm">{{ x.hora }}</option>
                            </select>
                            <!-- Error para Hora -->
                            <mat-error *ngIf="formMinuta.get('strHora').hasError('required') "
                                class="text-danger small">
                                <i class="fas fa-exclamation-circle"></i> Campo Obligatorio
                            </mat-error>
                        </div>
                    </div>

                    <!-- Botón de enviar -->
                    <div class="row mt-3 justify-content-center">
                        <div class="col-10">
                            <button type="submit" class="btn btn-primary w-100"
                                [disabled]="!formMinuta || formMinuta.invalid">Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary ms-auto">Guardar</button>
        </div> -->
        </div>
    </div>
</div>
<!-- Modal editar sucursales-->
<div class="modal fade" id="editarSucursalModal" tabindex="-1" aria-labelledby="editarSucursalModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="formEditSucursal" (ngSubmit)="fnEditarSucursal()">
                <div class="modal-header bgtitModal">
                    <h2 class="modal-title mb-0 mx-auto" id="editarSucursalModalLabel">Sucursal
                        {{formEditSucursal.get('enviarA').value}}</h2>
                    <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row hMdalEditar">
                        <div class="col-md-6">
                            <!-- Enviar a -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('enviarA').invalid , 
                        'text-success': formEditSucursal.get('enviarA').valid 
                      }">*</span> Enviar a</label>
                                <input type="text" class="form-control" placeholder="Enviar a"
                                    formControlName="enviarA">
                                <div *ngIf="formEditSucursal.get('enviarA').invalid" class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo obligatorio, máx. 100 caracteres.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <!-- Código Postal -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('strCodigoPostal').invalid , 
                        'text-success': formEditSucursal.get('strCodigoPostal').valid 
                      }">*</span> C.P.</label>
                                <input (keydown)="onKeyDownEditSuc($event)" (keypress)="validarNumeros($event)"
                                    (blur)="blureventEditSuc()" type="text" class="form-control"
                                    placeholder="Código Postal" formControlName="strCodigoPostal">
                                <div *ngIf=" formEditSucursal.get('strCodigoPostal').invalid" class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo obligatorio, debe tener 5 dígitos.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <!-- Número Exterior -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('intNumExterior').invalid , 
                        'text-success': formEditSucursal.get('intNumExterior').valid 
                      }">*</span> No. Exterior</label>
                                <input type="text" class="form-control" placeholder="No. Exterior"
                                    formControlName="intNumExterior">
                                <div *ngIf="formEditSucursal.get('intNumExterior').touched && formEditSucursal.get('intNumExterior').invalid"
                                    class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo obligatorio.
                                </div>
                            </div>
                        </div>





                        <div class="col-md-4">
                            <!-- Estado -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('strEstado').invalid , 
                        'text-success': formEditSucursal.get('strEstado').valid 
                      }">*</span> Estado</label>
                                <input type="text" class="form-control" placeholder="Estado" formControlName="strEstado"
                                    readonly>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <!-- Delegación -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('strDelegacion').invalid , 
                        'text-success': formEditSucursal.get('strDelegacion').valid
                      }">*</span> Delegación</label>
                                <input type="text" class="form-control" placeholder="Delegación"
                                    formControlName="strDelegacion" readonly>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <!-- Calle -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('strCalle').invalid , 
                        'text-success': formEditSucursal.get('strCalle').valid 
                      }">*</span> Calle</label>
                                <input type="text" class="form-control" placeholder="Calle" formControlName="strCalle">
                                <div *ngIf="formEditSucursal.get('strCalle').invalid" class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo obligatorio.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <!-- Entre Calles -->
                            <div class="mb-3">
                                <label class="form-label mb-0">Entre Calles</label>
                                <input type="text" class="form-control" placeholder="Entre Calles"
                                    formControlName="entreCalles">
                            </div>
                        </div>
                        <div class=" col-md-6 d-flex justify-content-center" *ngIf="spinerBool">
                            <mat-spinner></mat-spinner>
                        </div>
                        <div class="col-md-6" *ngIf="!spinerBool">
                            <!-- Colonia -->
                            <div class="mb-3">

                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('strColonia').invalid , 
                        'text-success': formEditSucursal.get('strColonia').valid 
                      }">*</span> Colonia</label>
                                <select class="form-select" formControlName="strColonia"
                                    (change)="fnSelectColEditSucursal()">
                                    <option value="">--Seleccione--</option>
                                    <option value="{{col.strColonia}}" *ngFor="let col of arrayColonias">
                                        {{col.strColonia}}</option>
                                    <option value="otra">Otra</option>
                                    <!-- Opciones de colonia -->
                                </select>
                                <div *ngIf="formEditSucursal.get('strColonia').invalid" class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo obligatorio.
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <!-- Otra Colonia -->
                            <div class="mb-3">
                                <label class="form-label mb-0">Escriba la colonia</label>
                                <input type="text" class="form-control"
                                    [disabled]="formEditSucursal.get('strColonia')?.value !== 'otra'"
                                    placeholder="Ingresa otra colonia" formControlName="strOtraColonia">
                                <div *ngIf="formEditSucursal.get('strOtraColonia').invalid" class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo obligatorio.
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <!-- Vendedor -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('strVendedor').invalid , 
                        'text-success': formEditSucursal.get('strVendedor').valid 
                      }">*</span> Vendedor</label>
                                <input type="text" class="form-control" placeholder="Vendedor"
                                    formControlName="strVendedor" readonly>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <!-- Encargado de Sucursal -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('strEncargadoSucursal').invalid , 
                        'text-success': formEditSucursal.get('strEncargadoSucursal').valid 
                      }">*</span> Encargado de Sucursal</label>
                                <input type="text" class="form-control" placeholder="Encargado de Sucursal"
                                    formControlName="strEncargadoSucursal">
                                <div *ngIf="formEditSucursal.get('strEncargadoSucursal').touched && formEditSucursal.get('strEncargadoSucursal').invalid"
                                    class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Campo obligatorio, máx. 50 caracteres.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <!-- Teléfono -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('telefono').invalid , 
                        'text-success': formEditSucursal.get('telefono').valid 
                      }">*</span> Teléfono</label>
                                <input type="number" class="form-control" maxlength="10" placeholder="Teléfono"
                                    formControlName="telefono">
                                <div *ngIf=" formEditSucursal.get('telefono').invalid" class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Debe ser un número de 10 dígitos.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <!-- Correo Electrónico -->
                            <div class="mb-3">
                                <label class="form-label mb-0"><span [ngClass]="{
                        'text-danger': formEditSucursal.get('strEmail').invalid , 
                        'text-success': formEditSucursal.get('strEmail').valid 
                      }">*</span> Correo Electrónico</label>
                                <input type="email" class="form-control" placeholder="Correo Electrónico"
                                    formControlName="strEmail">
                                <div *ngIf=" formEditSucursal.get('strEmail').invalid" class="text-danger small">
                                    <i class="fas fa-exclamation-circle"></i> Ingrese un correo electrónico válido.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" #btnEditSucursal class="btn btn-secondary"
                        data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary ms-auto" [disabled]="formEditSucursal.invalid"
                        (click)="fnEditarSucursal()">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="nuevaCarpeta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Nombre Carpeta</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <label for="">Escribe el nombre de una carpeta:</label>
                            <input type="text" placeholder="Escribe el nombre" class="form-control"
                                [(ngModel)]="strNombreCarpeta">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button> -->
                <button type="button" class="btn btn-primary" (click)="agregarCarpeta()"
                    data-bs-dismiss="modal">Crear</button>
            </div>
        </div>
    </div>
</div>



<div class="row" [hidden]="!vartri">
    <div class="col-12" #headerArtsAsing>
        <h1 class="text-center">ARTICULOS ASIGNADOS {{objInfo?.strNombreEmpresa}}</h1>
    </div>
    <div class="col-12" #pdfArtsPolitica>


        <table class="table table-striped table-bordered text-center align-middle">
            <thead class="table-dark">
                <tr>
                    <th>#</th>
                    <th>Imagen</th>
                    <th>Artículo</th>
                    <th>Descripción</th>
                    <th>Unidad</th>
                    <th>Precio</th>
                    <th>Tipo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let element of arrayArtsPolitica; let i = index">
                    <td><b>{{ i + 1 }}</b></td>
                    <td>
                        <img [src]="'https://www.cleanclean.mx/imagenes/' + element.strArticulo + '.jpg'"
                            alt="Imagen de {{element.strArticulo}}" class="p-2" width="100">
                    </td>
                    <td class="fw-bold">{{ element.strArticulo }}</td>
                    <td class="fs10">{{ element.strDescripcion }}</td>
                    <td class="fs12">{{ element.strUnidad }}</td>
                    <td class="fs12 fw-bold">{{ element.dblPrecio | currency }}</td>
                    <td class="fs12">
                        <span class="fw-bold"
                            [ngClass]="{'clsPlan': element.strTipo == 'PLANTILLA', 'clsPolitica': element.strTipo == 'POLITICA'}">
                            {{ element.strTipo }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>