<!--<app-menu></app-menu>-->
<div class="container">
    <div class="row section_fondo">
        <h1>Autorización de Precios Mínimos</h1>

        <div class="row">
            <div class="col-3 select-left mb-3"><!-- *ngIf="selectGte"-->
                <mat-form-field appearance="fill">
                    <mat-label>Seleccione Gerente</mat-label>
                    <mat-select [(ngModel)]="gteSelected" (selectionChange)="fnGetSucursales(gteSelected)">
                        <mat-option *ngFor="let item of arrayGerentes" [value]="item">
                            {{item.strEstatus}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!--<div class="col-3 select-left mb-3" *ngIf="selectSuc">
                <mat-form-field appearance="fill">
                    <mat-label>Seleccione sucursal</mat-label>
                    <mat-select [(ngModel)]="sucursalSelected" (selectionChange)="fnGetAgentes(sucursalSelected)">
                        <mat-option *ngFor="let item of arraySucursales" [value]="item">
                            {{item.strEstatus}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-3 select-left mb-3" *ngIf="selectAgt">
                <mat-form-field appearance="fill">
                    <mat-label>Seleccione Agente</mat-label>
                    <mat-select [(ngModel)]="agenteSelected">
                        <mat-option *ngFor="let item of arrayAgentes" [value]="item">
                            {{item.strLinea}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div class="col-3 select-left mb-3">
                <mat-form-field appearance="fill">
                    <mat-label>Seleccione Estatus</mat-label>
                    <mat-select [(ngModel)]="estatusSelected">
                        <mat-option *ngFor="let item of arrayEstatus" [value]="item.value">
                            {{item.view}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>-->

            <div class="col-md-3 float-left form-group">
                <mat-form-field appearance="fill">
                   <mat-label>Periodo</mat-label>
                   <mat-select [(ngModel)]="intPeriodo" name="food">
                      <mat-option *ngFor="let food of periodo" [value]="food.value">
                            {{food.viewValue}}
                      </mat-option>
                   </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3 float-left form-group">
                <mat-form-field appearance="fill">
                   <mat-label>Ejercicio</mat-label>
                   <mat-select [(value)]="intEjercicio" name="food">
                      <mat-option *ngFor="let food of ejercicio" [value]="food.value">
                            {{food.viewValue}}
                      </mat-option>
                   </mat-select>
                </mat-form-field>
            </div>

            <div class="col-3 select-left mb-3">
                <button mat-raised-button style="background-color: #144361;color: white;height: 50px;margin-left: 15px;" (click)="fnGetListado()">Filtrar</button>
            </div>
        </div>

        <div class="cien">
            <div class="cien fLeft">
                <mat-form-field appearance="standard" class="buscar">
                    <mat-label><i class="fa-solid fa-magnifying-glass"></i> Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
                </mat-form-field>
            </div>

            <div class="contenedor_resp_tabla" *ngIf="listadoGeneral">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 ">
                    <ng-container matColumnDef="intID">
                        <th mat-header-cell *matHeaderCellDef>ID</th>
                        <td mat-cell *matCellDef="let element">
                            <a class="detalle_hip" (click)="fnAbrirDetalle(element)">{{element.intID}}</a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="strMovID">
                        <th mat-header-cell *matHeaderCellDef>MovID</th>
                        <td mat-cell *matCellDef="let element">{{element.strMovID}}</td>
                    </ng-container>

                    <ng-container matColumnDef="strUsuario">
                        <th mat-header-cell *matHeaderCellDef>Usuario</th>
                        <td mat-cell *matCellDef="let element">{{element.strUsuario}}</td>
                    </ng-container>

                    <ng-container matColumnDef="strAgente">
                        <th mat-header-cell *matHeaderCellDef>Agente</th>
                        <td mat-cell *matCellDef="let element">{{element.strAgente}} - {{element.strNombreAgente}}</td>
                    </ng-container>
                    
                    <ng-container matColumnDef="strCliente">
                        <th mat-header-cell *matHeaderCellDef>Cliente</th>
                        <td mat-cell *matCellDef="let element">{{element.strCliente}} - {{element.strNombreCliente}}</td>
                    </ng-container>
                    
                    <ng-container matColumnDef="strEstatus">
                        <th mat-header-cell *matHeaderCellDef>Estatus</th>
                        <td mat-cell *matCellDef="let element">{{element.strEstatus}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20, 30, 60, 120]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>

<br>
<br>
<app-footer></app-footer>
