import { Component, OnInit } from '@angular/core'; 

@Component({
  selector: 'app-partner-beneficios',
  templateUrl: './partner-beneficios.component.html',
  styleUrls: ['./partner-beneficios.component.css']
})
export class PartnerBeneficiosComponent implements OnInit {
  // Progreso de la barra
  public progressBarValue = 60; // Progreso actual del partner (porcentaje de avance)
  public nextLevelProgress = 70; // El porcentaje necesario para alcanzar el siguiente nivel

  constructor() { }

  ngOnInit(): void {
  }

}
