 <div class="container mt-4">
    <h2>Regístrate</h2>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
      
        <div class="form-group">
            <label for="name">Nombre</label>
            <input
            id="name"
            type="text"
            class="form-control"
            formControlName="name"
            placeholder="Ingresa tu nombre"
            />
            <div *ngIf="f.name.touched && f.name.invalid" class="text-danger">
            El nombre es obligatorio.
            </div>
        </div>
        <div class="form-group">
            <label for="apellido">Apellidos</label>
            <input
            id="apellido"
            type="text"
            class="form-control"
            formControlName="apellido"
            placeholder="Ingresa tus Apellidos"
            />
            <div *ngIf="f.apellido.touched && f.apellido.invalid" class="text-danger">
            El apellido es obligatorio.
            </div>
        </div>
      
       <div class="form-group">
        <label for="email">Correo Electrónico</label>
        <input
          id="email"
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="Ingresa tu correo"
        />
        <div *ngIf="f.email.touched && f.email.invalid" class="text-danger">
           
          <div *ngIf="f.email.errors && f.email.errors.required">El correo electrónico es obligatorio.</div>
          <div *ngIf="f.email.errors &&f.email.errors.email">Ingresa un correo electrónico válido.</div> 
        </div>
      </div>
      
    

      <div class="form-group">
        <label for="password">Contraseña</label>
          
          <!-- Icono para mostrar/ocultar la contraseña a la derecha -->
          <div class="input-group">
            <input
            id="password"
            [type]="passwordVisible ? 'text' : 'password'"
            class="form-control"
            formControlName="password"
            placeholder="Ingresa tu contraseña"
          />
            <span class="input-group-text pointer" (click)="togglePasswordVisibility()">
              <i [class]="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
            </span>
          </div>
    
        <div *ngIf="f.password.touched && f.password.invalid" class="text-danger">
          <div *ngIf="f.password.errors?.required">La contraseña es obligatoria.</div>
          <div *ngIf="f.password.errors?.minlength">La contraseña debe tener al menos 6 caracteres.</div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="registerForm.invalid">
        Registrarse
      </button>
  
      <div class="login-link">
        <p>¿Ya tienes una cuenta? <a routerLink="/tlogin">Inicia sesión</a></p>
      </div>
    </form>
  </div>
 