<footer class="bgFooter container-fluid py-4">
  <div class="row justify-content-center">
    <div class="col-1 d-none d-lg-flex"></div>
    <div class="col-10 col-lg">
        <div class="col-12 d-flex flex-column">
          <span class="col-12 text-center fw-bold cota fs-6 mb-3">
            Contáctanos
          </span>
          <ul class="mx-auto">
            <li class="d-flex mb-3">
              <i class="fa-solid fa-phone clsText fs-3  me-2"></i>
              <span class="d-flex flex-column">
                <span class="text-white">477 788 98 00 </span>
                <span class="text-white">Ext. 257 y 256</span>
              </span>
            </li>
            <li class="d-flex mb-3">
              <i class="fa-solid fa-mobile-screen-button clsText fs-3 me-2"></i>
              <span class="d-flex flex-column">
                <span class="text-white">477 729 63 02</span> 
              </span>
            </li>
            <li class="d-flex mb-3">
              <i class="bi bi-envelope-at-fill clsText fs-3 me-2"></i>
            
            <span class="d-flex flex-column"> 
              <span class="text-white">atencion&#64;ecodeli.com</span>
            </span>
            </li>
          </ul>
        </div>
    </div>

    <div class="col-10 col-lg my-3 my-lg-0">
      <div class="col-12 d-flex flex-column mb-3">
        <span class="col-12 text-center fw-bold cota fs-6 mb-3">
          Redes Sociales
        </span>
        <div class="col-12 d-flex justify-content-center">
          <div class="col-2 text-center">
            <a href="https://www.linkedin.com/jobs/grupo-ecodeli-empleos/?currentJobId=4104221019&originalSubdomain=mx" target="_blank">
              <img [src]="'assets/foot/link.png'"   class="w-50" alt="">
            </a>
          </div>
          <div class="col-2 text-center">
            <a href="https://www.facebook.com/GrupoEcodeli" target="_blank">
              <img [src]="'assets/foot/Facebook.png'"  class="w-50"  alt="">
            </a>
          </div>
          <div class="col-2 text-center ">
            <a href="https://www.instagram.com/ecodelioficial/" target="_blank">
              <img [src]="'assets/foot/inst.png'"  class="w-50" alt="">
            </a>
          </div> 
        </div>
      </div>
        
     
    </div>
    <div class="col-10 col-lg">
      <div class="col-12 d-flex flex-column">
        <span class="fw-bold cota fs-6 text-center mb-3">
          Más información
        </span>
        <ul class="mx-auto">
          <li class="d-flex mb-3 ">
            <i class="fa-solid  fa-file-lines  clsText fs-3  me-2"></i>
            <a [routerLink]="['/declaracion-privacidad']" class=" ms-2 text-decoration-none">
              <span class="d-flex flex-column">
              <span class="text-white">Declaración </span>
              <span class="text-white">de privacidad </span>
              </span>
            </a>
          </li>
          <li class="d-flex mb-3">
            <i class="fa-solid fa-truck-fast  clsText fs-3 me-2"></i>
            <a [routerLink]="['/terminos-envio-servicio']" class="text-decoration-none"> 
              <span class="d-flex flex-column">
              <span class="text-white">Términos de envío </span> 
              <span class="text-white">  y servicio</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-1 d-none d-lg-flex"></div>
  </div>


  
</footer>


<!-- <footer class="bgFooter container-fluid py-4">
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="row">
        <div class="mb-3 mb-lg-0 col-12 col-lg-4 text-center d-flex align-items-center justify-content-center">
          <span class="fw-bold cota fs-6">
            Contáctanos
          </span>
        </div>
        <div class="mb-3 mb-lg-0 col-12 col-lg-4 d-flex align-items-center justify-content-center">
         
          <i class="fa-solid fa-phone clsText fs-3  me-2"></i>
          <span class="d-flex flex-column">
            <span class="text-white">477 788 98 00 </span>
            <span class="text-white">Ext. 257 y 256</span>
          </span>
        </div>
        <div class="mb-3 mb-lg-0 col-12 col-lg-4 d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-mobile-screen-button clsText fs-3 me-2"></i>
          <span class="d-flex flex-column">
            <span class="text-white">477 729 63 02</span> 
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="row">
        <div class="mb-3 mb-lg-0 col-12 col-lg-4 d-flex align-items-center justify-content-center">
          <i class="bi bi-envelope-at-fill clsText fs-3 me-2"></i>
          
          <span class="d-flex flex-column"> 
            <span class="text-white">atencion&#64;ecodeli.com</span>
          </span>
        </div>
        <div class="mb-3 mb-lg-0 col-12 col-lg-8 d-flex justify-content-center">
          <div class="col-2 text-center">
            <a href="https://www.linkedin.com/jobs/grupo-ecodeli-empleos/?currentJobId=4104221019&originalSubdomain=mx" target="_blank">
              <img [src]="'assets/foot/link.png'"   class="w-50" alt="">
            </a>
          </div>
          <div class="col-2 text-center">
            <a href="https://www.facebook.com/GrupoEcodeli" target="_blank">
              <img [src]="'assets/foot/Facebook.png'"  class="w-50"  alt="">
            </a>
          </div>
          <div class="col-2 text-center">
            <a href="https://www.instagram.com/ecodelioficial/" target="_blank">
              <img [src]="'assets/foot/inst.png'"  class="w-50" alt="">
            </a>
          </div>  
        </div>
      </div>
    </div>
  </div>

  
  <div class="row mt-2">
    <div class="col-12 col-lg-6">
      <div class="row">
        <div class="mb-3 mb-lg-0  col-12 col-lg-4 text-center d-flex align-items-center justify-content-center">
          <span class="fw-bold cota fs-6">
            Más información
          </span>
        </div>
        <div class="mb-3 mb-lg-0 col-12 col-lg-4 d-flex align-items-center justify-content-center">
         <i class="fa-solid  fa-file-lines  clsText fs-3  me-2"></i>
         <a [routerLink]="['/declaracion-privacidad']" class="text-decoration-none">
          <span class="d-flex flex-column">
            <span class="text-white">Declaración </span>
            <span class="text-white">de privacidad </span>
          </span>
        </a>
        </div>
        <div class="mb-3 mb-lg-0 col-12 col-lg-4 d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-truck-fast  clsText fs-3 me-2"></i>
          <a [routerLink]="['/terminos-envio-servicio']" class="text-decoration-none"> 
            <span class="d-flex flex-column">
           <span class="text-white">Términos de envío </span> 
            <span class="text-white">  y servicio</span>
          </span> </a>
        </div>
      </div>
    </div>
  </div>
</footer> -->