<div class="container">
    <div class="row my-3">
        <div class="col-12 bg-white  py-3">
            <div class="col-12">
                <button class="fw-bold fs12 btn ms-3 " (click)="fnSelectArts('promocion')" [ngClass]="{'btncan':activeProduct,'btn-primary':!activeProduct}">
                    Promociones
                </button>
                <button (click)="fnSelectArts('PLANTILLA')" class="fw-bold fs12 btn ms-3" [ngClass]="{'btncan':!activeProduct,'btn-primary':activeProduct}">
                    Precio Convenio
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 px-0">
            <div *ngIf="spinerArts" class=" col-12 text-center d-flex justify-content-center">
                <mat-spinner></mat-spinner>
            </div>
            <div class="alert alert-danger text-center fw-bold my-3" *ngIf=" !spinerArts && arrayArts.length==0" role="alert">
                No se han encontrado datos de pedidos
            </div>
            <div  [hidden]="arrayArts.length==0">
                <div class="col-12 d-flex px-2 bg-white justify-content-between align-items-center">
                    <mat-form-field appearance="outline" class="bg-white rounded my-2">
                        <mat-label>Buscar:</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                      </mat-form-field>
                      <span > 
                       <span class="fw-bold">Total Articulos:</span>  {{arrayArts.length}}
                      </span>
                </div>
            </div>
            <div class="col-12">
                <div class="table-responsive mb-0" [hidden]="arrayArts.length==0">
                    <div class="mat-elevation-z8">
                      <table class="table w-100 table-bordered" mat-table [dataSource]="dataSource" matSort>
                          
                          <ng-container matColumnDef="imagen">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-2 fs-text sticky-top"> IMAGEN </th>
                              <td mat-cell *matCellDef="let row" class="text-center align-middle px-2 fs-txt-table">
                                <div class="col-12 d-flex flex-column tex-center">
                                  <img [src]="row.imagen" class="fs-img mx-auto" alt="">
                                  <span class="text-center fw-bold fs12">
                                    {{row.objArt.strArticulo}}
                                  </span>
                                </div>  
                              </td>
                            </ng-container>
                          <ng-container matColumnDef="descripcion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> DESCRIPCION </th>
                              <td mat-cell *matCellDef="let row" class="text-center align-middle fs-txt-table  ">{{row.descripcion}}</td>
                            </ng-container>
                            
                            <ng-container matColumnDef="precio">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="fs-text sticky-top"> PRECIO </th>
                              <td mat-cell *matCellDef="let row" class="text-end fw-bold pe-3 align-middle fs-txt-table "> {{row.precio|currency}}</td>
                            </ng-container>
                            
                            <ng-container matColumnDef="accion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header class="sticky-top"></th>
                              <td mat-cell *matCellDef="let row" class=" align-middle px-2 fs12"> 
                                 
                                <div class="col-12 d-flex justify-content-center">
                                  <button class="btn btn-primary py-1 px-2 fs-txt-table d-flex text-center" data-bs-toggle="modal"
                                  data-bs-target="#exampleModal" (click)="fnAbrirModal(row)">
                                     <span class="me-2 me-lg-0">Agregar </span> 
                                     <span class="d-none d-lg-block"> al  carrito</span>
                                     <span class="d-lg-none"><i class="bi bi-cart-plus-fill"></i></span>  
                                  </button>
                                </div>
                                
                              </td>
                            </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="4">Nose encuentran datos al filtrar "{{input.value}}"</td>
                        </tr>
                      </table>
                    </div>
              </div>
              <div class="col-12" [hidden]="arrayArts.length==0">
                  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
              </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-lg h-modal modalheigth" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-dialog-centered modal-lg   ">
        <div class="modal-content h-100 ">
            <div style="background:#c7daff;color:#001b52;" class=" modal-header d-flex position-relative">
                <h1 class="modal-title position-absolute top-50 start-50 translate-middle fw-bold"
                    id="exampleModalLabel">
                    Detalle De Artículo</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body ">
                <div class="row align-items-start">
                    <div class="col-12 col-lg-6">
                        <h3 class="d-inline-block d-sm-none"> {{objArt.strDescripcion }}</h3>
                        <div id="carouselExampleIndicators" class="col-12 h-75 carousel slide d-flex align-items-center"
                            data-bs-touch="false" data-bs-interval="false">
                            <div class="carousel-inner imgCar ">
                                <div class="carousel-item active text-center ">
                                    <img [src]="imagenUrl1" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                                <div class="carousel-item text-center">
                                    <img [src]="imagenUrl2" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                                <div class="carousel-item text-center">
                                    <img [src]="imagenUrl3" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                                <div class="carousel-item text-center">
                                    <img [src]="imagenUrl4" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                                <div class="carousel-item text-center">
                                    <img [src]="imagenUrl5" (error)="onImageError($event)" class="zoom1"
                                        alt="Product Image">
                                </div>
                            </div>
                            <button class="carousel-control-prev bg-transparent" style="color: black !important;"
                                type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span class="material-symbols-outlined">
                                    arrow_back_ios
                                </span>
                            </button>
                            <button class="carousel-control-next bg-transparent" style="color: black !important;"
                                type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span class="material-symbols-outlined">
                                    arrow_forward_ios
                                </span>
                            </button>
                        </div>
                        <div class="col-12 product-image-thumbs mt-0">
                            <div class="product-image-thumb active col" aria-current="true" aria-label="Slide 1"
                                data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0">
                                <img [src]="imagenUrl1" (error)="onImageError($event)" alt="Product Image">
                            </div>
                            <div class="product-image-thumb col" *ngIf="imagenUrl2!='False'" aria-current="true"
                                aria-label="Slide 2" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1">
                                <img [src]="imagenUrl2" (error)="onImageError($event)" alt="Product Image">
                            </div>
                            <div class="product-image-thumb col" *ngIf="imagenUrl3!='False'" aria-current="true"
                                aria-label="Slide 3" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2">
                                <img [src]="imagenUrl3" (error)="onImageError($event)" alt="Product Image">
                            </div>
                            <div class="product-image-thumb col" *ngIf="imagenUrl4!='False'" aria-current="true"
                                aria-label="Slide 4" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3">
                                <img [src]="imagenUrl4" (error)="onImageError($event)" alt="Product Image">
                            </div>
                            <div class="product-image-thumb col" *ngIf="imagenUrl5!='False'" aria-current="true"
                                aria-label="Slide 5" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4">
                                <img [src]="imagenUrl5" (error)="onImageError($event)" alt="Product Image">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row">
                            <div class="col-9">
                                <h2 class="my-3 fw-bolder">{{objArt.strArticulo}}</h2>
                                <p>{{objArt.strDescripcion}}</p>
                            </div>
                            <div class="col-3">
                                <div class="row">
                                    <div class="col-12 rounded text-center border shadow-sm flex flex-column pb-1 px-0">
                                        <div class="col-12 bgcard rounded-top">
                                            <span class="tficha text-white fs11">Ficha Tecnica:</span>
                                        </div>
                                        <a href="https://ecodeli.com/catalogos/FichasTecnicas/{{objArt.strArticulo}}.pdf"
                                            target="_blank">
                                            <span class="material-symbols-outlined">
                                                download_for_offline
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div class="row mt-2 ">
                                    <div
                                        class="col-12 border rounded text-center shadow-sm d-flex flex-column pb-1 px-0">
                                        <div class="col-12 bgcard rounded-top">
                                            <span class="tficha text-white fs11">Hoja Seguridad:</span>
                                        </div>
                                        <a href="https://ecodeli.com/catalogos/HojasSeguridad/{{objArt.strArticulo}}.pdf"
                                            target="_blank">
                                            <span class="material-symbols-outlined">
                                                download_for_offline
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-12 col-md-6" [hidden]="objArt.strTipoOpcion == 'No'">
                                <div class="col-12">
                                    <h3 class="fw-bolder mb-1">
                                        Opcion
                                    </h3>
                                </div>
                                <div class="col-12">
                                    <select class="form-select  shadow-sm" [(ngModel)]="objArt.strOpcion"
                                        aria-label=".form-select-lg example"
                                        (change)="onOpcionSeleccionada(objArt.strOpcion,objArt)">
                                        <option *ngFor="let item of objArt.arrayOpciones; let i = index;"
                                            [ngValue]="item.strOpcion">
                                            <span class="me-2">
                                                <b> {{item.strOpcion}}</b>
                                            </span>
                                            &nbsp;
                                            &nbsp;
                                            <span>
                                                {{item.strNombreOpcion}}
                                            </span>
                                        </option>
                                    </select>
                                </div>

                            </div>
                            <div class=""
                                [ngClass]="{'col-12 col-md-6':objArt.strTipoOpcion != 'No','col-10':objArt.strTipoOpcion == 'No'}">
                                <div class="ms-auto col-10">
                                    <h3 class="fw-bolder mb-1">
                                        Precio
                                    </h3>
                                </div>
                                <div class="col-12">
                                    <select [(ngModel)]="objArt.dblPrecio" class="form-select fs-8"
                                        aria-label="Default select example">
                                        <option *ngIf="intPrecioPolitica>0" value="{{intPrecioPolitica}}">
                                            {{intPrecioPolitica|currency}}</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 d-flex align-items-center">
                            <div class="col-12 col-sm-6">
                                <div class="col-10 mx-auto">
                                    <div class="input-group rounded-pill">
                                        <button class="btn  btnCirs" (click)="fnAdd_Rest_Amount(objArt,'rest');"
                                            type="button">-</button>
                                        <input type="text" value="0" [(ngModel)]="objArt.dblCantidad"
                                            class="form-control text-center" placeholder=""
                                            aria-label="Recipient's username with two button addons">
                                        <button class="btn  btnCire" (click)="fnAdd_Rest_Amount(objArt,'add');"
                                            type="button">+</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 ">
                                <div class="col-10 mx-auto">
                                    <button
                                        [disabled]="spinerCarrito || !objArt.dblPrecioLista || objArt.dblPrecioLista < 1"
                                        (click)="fnAddToCarr(objArt);"
                                        class="w-100 position-relative btn btnCar btn-outline-primary py-2 fs13 rounded-pill d-flex align-items-center justify-content-center shadow-sm">
                                        <span *ngIf="!spinerCarrito">Agregar al carrito</span>

                                        <span style="color:#FFEF92" *ngIf="spinerCarrito">.</span>
                                        <div class="pt-1 position-absolute top-50 start-50 translate-middle"
                                            *ngIf="spinerCarrito">
                                            <div class="spinner-border text-primary " role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="accordion accordion-flush " id="accordionFlushExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header mb-0" id="flush-headingOne">
                                            <button (click)="fntraerExistencias(objArt);"
                                                class="fw-bold acordeon accordion-button collapsed border rounded px-3 py-1 fs12"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseOne" aria-expanded="false"
                                                aria-controls="flush-collapseOne">
                                                Existencias
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body pt-2 pb-0 bgbodyacordeon">
                                                <div class="row">
                                                    <div class="col-12 table-responsive mx-0 px-0 mb-2 border rounded">
                                                        <table class="table table-bordered bg-white fixed-header-table">
                                                            <thead>
                                                                <tr class="">

                                                                    <th class="text-center  text-white">Almacen</th>
                                                                    <th class="text-center  text-white">Nombre</th>
                                                                    <th class="text-center text-white">Disponibles</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let x of arrayExistentes; let ind = index">

                                                                    <td>{{x.strAlmacen}}</td>
                                                                    <td>{{x.strAlmacenNombre}}</td>
                                                                    <td><b>{{x.dblDisponible}}</b></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>