<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="text-center mx-2 fw-bold nunito">Regístrate</h1>
    </div>
  </div>
    
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
      <div class="shadow-sm p-2 bg-ligth mb-4 rounded bgcard">
        <h2 class="fw-bold mb-0 ">
          <i class="bi bi-info-circle" *ngIf="f.name.invalid || f.apellido.invalid || f.telefono.invalid || f.email.invalid || f.password.invalid" matTooltip="Faltan campos por llenar"></i>
          <i class="bi bi-check-circle" *ngIf="f.name.valid && f.apellido.valid && f.telefono.valid && f.email.valid && f.password.valid" matTooltip="Campos completos"></i>
          Información General
        </h2>
        <div class="form-group mt-3" >
          <label for="name">
            <i class="bi bi-info-circle" *ngIf="f.name.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.name.valid" matTooltip="campo obligatorio"></i>
            Nombre 
          </label>
          <input id="name" type="text" class="form-control" formControlName="name" placeholder="Ingresa tu nombre" />
          <div *ngIf="f.name.touched && f.name.invalid" class="text-danger">El nombre es obligatorio.</div>
        </div>
        <div class="form-group">
          <label for="apellido">
            <i class="bi bi-info-circle" *ngIf="f.apellido.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.apellido.valid" matTooltip="campo obligatorio"></i>
            Apellidos
          </label>
          <input id="apellido" type="text" class="form-control" formControlName="apellido" placeholder="Ingresa tus Apellidos" />
          <div *ngIf="f.apellido?.touched && f.apellido?.invalid" class="text-danger">El apellido es obligatorio.</div>
        </div>
        <div class="form-group">
          <label for="telefono">
            <i class="bi bi-info-circle" *ngIf="f.telefono.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.telefono.valid" matTooltip="campo obligatorio"></i>
            Teléfono 
          </label>
          <input id="telefono" type="number" class="form-control" formControlName="telefono" placeholder="Ingresa tu número de teléfono"  (input)="limitarTelefono($event)"  [maxlength]="10" />
          <div *ngIf="f.telefono?.touched && f.telefono?.invalid" class="text-danger">Ingrese un número de teléfono válido</div>
        </div>
        <div class="form-group">
          <label for="email">
            <i class="bi bi-info-circle" *ngIf="f.email.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.email.valid" matTooltip="campo obligatorio"></i>
            Correo Electrónico 
          </label>
          <input id="email" type="email" class="form-control" formControlName="email" placeholder="Ingresa tu correo" />
          <div *ngIf="f.email.touched && f.email.invalid" class="text-danger">
            <div *ngIf="f.email.errors?.required">El correo electrónico es obligatorio.</div>
            <div *ngIf="f.email.errors?.email">Ingresa un correo electrónico válido.</div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">
            <i class="bi bi-info-circle" *ngIf="f.password.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.password.valid" matTooltip="campo obligatorio"></i>
            Contraseña
            </label>
            
            <!-- Icono para mostrar/ocultar la contraseña a la derecha -->
            <div class="input-group">
              <input
              id="password"
              [type]="passwordVisible ? 'text' : 'password'"
              class="form-control"
              formControlName="password"
              placeholder="Ingresa tu contraseña"  />
              <span class="input-group-text pointer" (click)="togglePasswordVisibility()">
                <i [class]="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
              </span>
            </div>
      
          <div *ngIf="f.password.touched && f.password.invalid" class="text-danger">
            <div *ngIf="f.password.errors?.required">La contraseña es obligatoria.</div>
            <div *ngIf="f.password.errors?.minlength">La contraseña debe tener al menos 6 caracteres.</div>
          </div>
        </div>
       
      </div>
  
      <div class="shadow-sm p-2 bg-ligth mb-4 rounded bgcard">
        <h2 class="fw-bold mb-0">
                  <i class="bi bi-info-circle" *ngIf="f.nombreComercial.invalid || f.razonSocial.invalid || f.rfc.invalid || f.regimenFiscal.invalid || f.usoCfdi.invalid || f.codigoPostal.invalid || f.estado.invalid || f.ciudad.invalid || f.colonia.invalid || f.calle.invalid || f.numeroExterior.invalid" matTooltip="Faltan Campos por llenar"></i>
                  <i class="bi bi-check-circle" *ngIf="f.nombreComercial.valid && f.razonSocial.valid && f.rfc.valid && f.regimenFiscal.valid && f.usoCfdi.valid && f.codigoPostal.valid && f.estado.valid && f.ciudad.valid && f.colonia.valid && f.calle.valid && f.numeroExterior.valid" matTooltip="Campos completos"></i> 
                  Información Fiscal  
        </h2>
        <div class="form-group mt-3">
          <label for="nombreComercial">
            <i class="bi bi-info-circle" *ngIf="f.nombreComercial.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.nombreComercial.valid" matTooltip="campo obligatorio"></i>
            Nombre Comercial 
            
          </label>
          <input id="nombreComercial" type="text" class="form-control" formControlName="nombreComercial" placeholder="Ingresa Nombre Comercial"  />
          <div *ngIf="f.nombreComercial.touched && f.nombreComercial.invalid" class="text-danger">El Nombre Comercial es obligatorio.</div>
         
        </div>
        <div class="form-group">
          <label for="razonSocial">
            <i class="bi bi-info-circle" *ngIf="f.razonSocial.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.razonSocial.valid" matTooltip="campo obligatorio"></i>
            Razón Social 
            
          </label>
          <input id="razonSocial" type="text" class="form-control" formControlName="razonSocial" placeholder="Ingresa tu razón social" />
          <div *ngIf="f.razonSocial.touched && f.razonSocial.invalid" class="text-danger">La razón social es obligatoria.</div>
         
        </div>
        <div class="form-group">
          <label for="rfc">
            <i class="bi bi-info-circle" *ngIf="f.rfc.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.rfc.valid" matTooltip="campo obligatorio"></i>
            RFC
          </label>
          <input id="rfc" type="text" class="form-control" formControlName="rfc"  placeholder="Ingresa RFC"/>
          <div *ngIf="f.rfc.touched && f.rfc.invalid" class="text-danger">El RFC es obligatorio.</div>
         
        </div>
        <div class="form-group">
          <label for="regimenFiscal">
            <i class="bi bi-info-circle" *ngIf="f.regimenFiscal.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.regimenFiscal.valid" matTooltip="campo obligatorio"></i>
            Régimen Fiscal  
            
          </label>
         
          <select id="regimenFiscal" class="form-select" aria-label="Default select example" formControlName="regimenFiscal">
            <option value ="">--Seleccione--</option>
            <option *ngFor = "let col of arrayRegimenFiscal" [value]="col.strFiscalRegimen">{{col.strDescripcion}}</option> 
          </select>


          <div *ngIf="f.regimenFiscal.touched && f.regimenFiscal.invalid" class="text-danger">El Régimen Fiscal es obligatorio.</div>
         
        </div>
        <div class="form-group">
          <label for="usoCfdi">
            <i class="bi bi-info-circle" *ngIf="f.usoCfdi.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.usoCfdi.valid" matTooltip="campo obligatorio"></i>
            Uso de CFDI  
            
          </label>
          
          <select id="usoCfdi" class="form-select" aria-label="Default select example" formControlName="usoCfdi">
            <option value="">--Seleccione--</option>
            <option *ngFor = "let col of arrayCfdi" [value]="col.strClaveUsoCFDI">{{col.strDescripcion}}</option> 
          </select>
          <div *ngIf="f.usoCfdi.touched && f.usoCfdi.invalid" class="text-danger">El Uso de CFDI es obligatorio.</div>
         
        </div>
  
        <div class="row">
          <div class="col-md-6">
            <label for="codigoPostal">
              <i class="bi bi-info-circle" *ngIf="f.codigoPostal.invalid" matTooltip="campo obligatorio"></i>
              <i class="bi bi-check-circle" *ngIf="f.codigoPostal.valid" matTooltip="campo obligatorio"></i>
              Código Postal  
              
            </label>
            <input id="codigoPostal" type="text" class="form-control" formControlName="codigoPostal" placeholder="Ingresa Código Postal" (input)="onInputChange($event)"  (keydown)="onKeyDown($event)" [maxlength]="15"/>
            <div *ngIf="f.codigoPostal.touched && f.codigoPostal.invalid" class="text-danger">El Código Postal es obligatorio.</div>
         
          </div>
          <div class="col-md-6">
            <label for="estado">
              <i class="bi bi-info-circle" *ngIf="f.estado.invalid" matTooltip="campo obligatorio"></i>
              <i class="bi bi-check-circle" *ngIf="f.estado.valid" matTooltip="campo obligatorio"></i>
              Estado  
              
            </label>
            <input id="estado" readonly type="text" class="form-control" formControlName="estado" placeholder="Ingresa Estado"/>
            <div *ngIf="f.estado.touched && f.estado.invalid" class="text-danger">El estado es obligatorio.</div>
         
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6">
            <label for="ciudad">
              <i class="bi bi-info-circle" *ngIf="f.ciudad.invalid" matTooltip="campo obligatorio"></i>
              <i class="bi bi-check-circle" *ngIf="f.ciudad.valid" matTooltip="campo obligatorio"></i>
              Ciudad 
              
            </label>
            <input id="ciudad" type="text" class="form-control" readonly formControlName="ciudad" placeholder="Ingresa Ciudad" />
            <div *ngIf="f.ciudad.touched && f.ciudad.invalid" class="text-danger">La Ciudad es obligatoria.</div>
         
          </div>
          <div class="col-md-6">
            <label for="colonia">
              <i class="bi bi-info-circle" *ngIf="f.colonia.invalid" matTooltip="campo obligatorio"></i>
              <i class="bi bi-check-circle" *ngIf="f.colonia.valid" matTooltip="campo obligatorio"></i>
              Colonia  
            </label> 

            <select id="colonia" class="form-select" aria-label="Default select example" formControlName="colonia">
              <option value ="">--Seleccione Colonia--</option>
              <option *ngFor = "let col of arrayColonias" [value]="col.strColonia">{{col.strColonia}}</option> 
            </select>

            <div *ngIf="f.colonia.touched && f.colonia.invalid" class="text-danger">La Colonia es obligatoria.</div>
         
          </div>
        </div>
  
        <div class="form-group">
          <label for="calle">
            <i class="bi bi-info-circle" *ngIf="f.calle.invalid" matTooltip="campo obligatorio"></i>
            <i class="bi bi-check-circle" *ngIf="f.calle.valid" matTooltip="campo obligatorio"></i>
            Calle  
          </label>
          <input id="calle" type="text" class="form-control" formControlName="calle" placeholder="Ingresa Calle"/>
          <div *ngIf="f.calle.touched && f.calle.invalid" class="text-danger">La Calle es obligatoria.</div>
         
        </div>
  
        <div class="row">
          <div class="col-md-6">
            <label for="numeroExterior">
              <i class="bi bi-info-circle" *ngIf="f.numeroExterior.invalid" matTooltip="campo obligatorio"></i>
              <i class="bi bi-check-circle" *ngIf="f.numeroExterior.valid" matTooltip="campo obligatorio"></i>
              Número Exterior  
            </label>
            <input id="numeroExterior" type="text" class="form-control" formControlName="numeroExterior" placeholder="Ingresa Número"/>
            <div *ngIf="f.numeroExterior?.touched && f.numeroExterior?.invalid" class="text-danger">Número Exterior es Obligatorio.</div>
         
          </div>
          <div class="col-md-6">
            <label for="numeroInterior">Número Interior <span class="fs9">(Opcional)</span></label>
            <input id="numeroInterior" type="text" class="form-control" formControlName="numeroInterior"  placeholder="Ingresa Número"/>
            
          </div>
        </div>
      </div>
   
      <button type="submit" class="btn btn-primary mt-3"  [disabled]="registerForm.invalid">Registrarse </button>
      <div class="login-link">
        <p>¿Ya tienes una cuenta? <a routerLink="/login">Inicia sesión</a></p>
      </div>
    </form>
  </div>
  