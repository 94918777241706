<h1 mat-dialog-title>Detalle del Pedido</h1>
<mat-dialog-content class="mat-typography text-center">
    <div class="tabla_dialog">
        <table class="table">
            <thead class="titulo">
                <tr style="background:#e5eff1;vertical-align: middle;" class="text-center">
                <th>Artículo</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Precio Mínimo</th>
                <th>Diferencia</th>
                </tr>
            </thead>
            <tbody>           
                <tr *ngFor="let element of arrayDetalle">
                    <td>{{element.strArticulo}}</td>
                    <td>{{element.strDescripcion1}}</td>
                    <td>{{element.intCantidad}}</td>
                    <td>{{element.dblPrecio | currency}}</td>
                    <td>{{element.dblPrecioMinimo | currency}}</td>
                    <td><p [ngClass]="{'text-aceptado': element.dblTotal>=0,
                        'text-danger': element.dblTotal<0}">{{element.dblTotal | currency}}</p></td>
                </tr>
            </tbody>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn btn-secondary">Cancelar</button>
    <button mat-button class="btn btn-danger" (click)="fnRechazarPreciosMinimos()">Rechazar</button>
    <button mat-button class="btn-success" (click)="fnAutorizarPreciosMinimos()" cdkFocusInitial>Autorizar</button>
</mat-dialog-actions>
<!--[mat-dialog-close]="true"--><!--(click)="fnGuardarPreguntas();" -->
