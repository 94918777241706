import { Injectable, Inject } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';


@Injectable()
export class RecursosHumanosService{
    public url: string;

    constructor(
        public _http: HttpClient

    ){

        this.url = 'https://www.cleanclean.mx/ApiRest/ApiEcodeliComercial/v2/PaginaEcodeli/';
    }
    
    get_RelojCte(strAccion: string,strUsuario: string, strSSN:string, strPeriodo: number, strEjercicio:number, strQuincena:string, strConexion:string, strCoordinador:string, strSemana:string): Observable<any>{
        
        let params = new HttpParams();
        params = params.append('strAccion', strAccion);
        params = params.append('strUsuario', strUsuario);
        params = params.append('strSSN', strSSN);
        params = params.append('strPeriodo', String(strPeriodo));
        params = params.append('strEjercicio', String(strEjercicio));
        params = params.append('strQuincena', String(strQuincena));
        params = params.append('strConexion', strConexion);
        params = params.append('strCoordinador', strCoordinador);
        params = params.append('strSemana', strSemana);
        
        let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
        console.log(params)
        return this._http.post(this.url+'get_RelojCte', params);
    }


    get_MovimientosRH(strAccion: string, strSSN:string, strPeriodo: number, strEjercicio:number, strConexion:string): Observable<any>{
        
        let params = new HttpParams();
        params = params.append('strAccion', strAccion);
        params = params.append('strSSN', strSSN);
        params = params.append('strPeriodo', String(strPeriodo));
        params = params.append('strEjercicio', String(strEjercicio));
        params = params.append('strConexion', strConexion);
        
        let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
        //console.log(params)
        return this._http.post(this.url+'get_MovimientosRH', params);
    }

    set_MovimientosRH(strAccion: string, strIDMov:string, strUsuario:string, strEstatus:string, strGoceSueldo:string, strMotivoAutorizacion:string, strConexion:string): Observable<any>{
        
        let params = new HttpParams();
        params = params.append('strAccion', strAccion);
        params = params.append('strIDMov', strIDMov);
        params = params.append('strUsuario', strUsuario);
        params = params.append('strEstatus', strEstatus);
        params = params.append('strMotivoAutorizacion', strMotivoAutorizacion);
        params = params.append('strGoceSueldo', strGoceSueldo);
        params = params.append('strConexion', strConexion);
        
        let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
        console.log(params);
        return this._http.post(this.url+'get_MovimientosRH', params);
    }

    set_Vacaciones(strAccion: string, strUsuario:string, strSSN:string, strFecha1:string, strFecha2:string, strDiasCorrespondientes:string, strDiasPendientes:string, strConexion:string): Observable<any>{
        
        let params = new HttpParams();
        params = params.append('strAccion', strAccion);
        params = params.append('strUsuario', strUsuario);
        params = params.append('strSSN', strSSN);
        params = params.append('strFecha1', strFecha1);
        params = params.append('strFecha2', strFecha2);
        params = params.append('strDiasCorrespondientes', strDiasCorrespondientes);
        params = params.append('strDiasPendientes', strDiasPendientes);
        params = params.append('strConexion', strConexion);
        

        let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');

        return this._http.post(this.url+'get_MovimientosRH', params);
    }

    set_Permiso(strAccion: string, strUsuario:string, strSSN:string, strFecha1:string, strMotivoAutorizacion:string, strHoraInicio:string, strHoraFin:string, strGoceSueldo:string, strConexion:string): Observable<any>{
        
        let params = new HttpParams();
        params = params.append('strAccion', strAccion);
        params = params.append('strUsuario', strUsuario);
        params = params.append('strSSN', strSSN);
        params = params.append('strFecha1', strFecha1);
        params = params.append('strMotivoAutorizacion', strMotivoAutorizacion);
        params = params.append('strHoraInicio', strHoraInicio);
        params = params.append('strHoraFin', strHoraFin);
        params = params.append('strGoceSueldo', strGoceSueldo);
        params = params.append('strConexion', strConexion);

        let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');

        return this._http.post(this.url+'get_MovimientosRH', params);
    }


}