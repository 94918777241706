import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormasPagoService {
  private formasPago = [
    { clave: '01', tipo: 'Efectivo', claveTexto: 'efectivo' },
    { clave: '02', tipo: 'Cheque nominativo', claveTexto: 'cheque' },
    { clave: '03', tipo: 'Transferencia electrónica de fondos', claveTexto: 'transferencia' },
    { clave: '04', tipo: 'Tarjeta de crédito', claveTexto: 'tarjeta_credito' },
    { clave: '05', tipo: 'Monedero electrónico', claveTexto: 'monedero' },
    { clave: '06', tipo: 'Dinero electrónico', claveTexto: 'dinero_electronico' },
    { clave: '08', tipo: 'Vales de despensa', claveTexto: 'vales' },
    { clave: '12', tipo: 'Dación en pago', claveTexto: 'dacion' },
    { clave: '13', tipo: 'Pago por subrogación', claveTexto: 'pago_subrogacion' },
    { clave: '14', tipo: 'Pago por consignación', claveTexto: 'pago_consignacion' },
    { clave: '15', tipo: 'Condonación', claveTexto: 'condonacion' },
    { clave: '17', tipo: 'Compensación', claveTexto: 'compensacion' },
    { clave: '23', tipo: 'Novación', claveTexto: 'novacion' },
    { clave: '24', tipo: 'Confusión', claveTexto: 'confusion' },
    { clave: '25', tipo: 'Remisión de deuda', claveTexto: 'remision' },
    { clave: '26', tipo: 'Prescripción o caducidad', claveTexto: 'prescripcion' },
    { clave: '27', tipo: 'A satisfacción del acreedor', claveTexto: 'satisfaccion_acreedor' },
    { clave: '28', tipo: 'Tarjeta de débito', claveTexto: 'tarjeta_debito' },
    { clave: '29', tipo: 'Tarjeta de servicios', claveTexto: 'tarjeta_servicio' },
    { clave: '30', tipo: 'Aplicación de anticipos', claveTexto: 'aplicacion_anticipos' },
    { clave: '99', tipo: 'Por definir', claveTexto: 'otro' }
  ];
  constructor() { }
  getFormasPago() {
    return this.formasPago;
  }
}
